import React,{useEffect, useState} from 'react';
import {useProductFeedEditionContext} from '../../context/ProductFeedEditionContext';
import editionStyles from '../ProductFeedEdition.module.css';
import Confirm from '../../../../Components/Confirm';
import Btn from '../../../../Components/Btn';
import InputCustom from '../../../../Components/InputCustom';
import IconBtn from '../../../../Components/IconBtn';
import EmptyState from '../../../../Components/EmptyState';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ModalHierarchieCat from '../../Feed/ModalHierarchieCat';
import Accordion from './Accordion';

export default function CategoryHierarchie() {
	const {
		productFeedObject,
		HandleSubmitHierarchie,
		handleChangeHierarchyNameExist,
	} = useProductFeedEditionContext();
	const [selectedCatModalIsOpen, setSelectedCatModalIsOpen] = useState(false);
	const [catSelected, setcatSelected] = useState('');
	const [oldObject, setoldObject] = useState();
	const [showDelConfirm, setshowDelConfirm] = useState(false);
	const [isExistingObj, setisExistingObj] = useState(false);
	const [deleteCat, setDeletedCat] = useState();
	const [seeInputNameHierarchieForEdit, setSeeInputNameHierarchieForEdit] = useState();
	const [deleteAHierarchie, setdeleteAHierarchie] = useState();
	const [showDeleteHierarchie, setshowDeleteHierarchie] = useState(false);
	const [hasSubCategories, setHasSubCategories] = useState(false);

	useEffect(() => {
		if (!productFeedObject) return;
		const array = [];
		for (const [key, value] of Object.entries(productFeedObject.categoryHierarchies)) {
			array.push({hierarchyName: key, categories: value});
		}
		const hasSub = array.some(h => h.categories && h.categories.length > 1);
		setHasSubCategories(hasSub);
	}, [productFeedObject]);

	function HierarchieEditInput(key){
		setSeeInputNameHierarchieForEdit(key);
	}
	function handleChangeHierarchyName (e) {
		const oldName = e.target.name;
		const newName = e.target.value;
		const copyOfHierarchieArray = Object.keys({...productFeedObject.categoryHierarchies});
		const copyOfHierarchie = {...productFeedObject.categoryHierarchies};
		const newHierarchieObject = copyOfHierarchieArray.reduce((acc, curr) => {
			if (curr === oldName ) {
				return acc = {...acc,[newName]  : [...copyOfHierarchie[curr]]};
			}
			else {
				return acc = {...acc, [curr] : [...copyOfHierarchie[curr]]};
			}
		}, {});
		handleChangeHierarchyNameExist(newHierarchieObject);
	}
	function handleChangeCategorie(e){
		const valueOfField = e.target.value;
		setcatSelected(valueOfField);
	}

	function submitNewCat(){
		if(isExistingObj){
			const copyOfHierarchie = {...productFeedObject.categoryHierarchies};
			copyOfHierarchie[oldObject.hierarchie].splice(oldObject.key,1,catSelected);
			HandleSubmitHierarchie(copyOfHierarchie);
			setcatSelected('');
			setisExistingObj(false);
			setSelectedCatModalIsOpen(false);
		}
		else{
			const copyOfHierarchie = productFeedObject.hasOwnProperty('categoryHierarchies') ?  {...productFeedObject.categoryHierarchies} : {};
			if(copyOfHierarchie.hasOwnProperty([oldObject.hierarchie])){
				copyOfHierarchie[oldObject.hierarchie] = [...copyOfHierarchie[oldObject.hierarchie],catSelected];
			}else{
				copyOfHierarchie[oldObject.hierarchie] = [catSelected];
			}
			HandleSubmitHierarchie(copyOfHierarchie);
			setcatSelected('');
			setisExistingObj(false);
			setSelectedCatModalIsOpen(false);
		}
		
	}
	function deletenameCatConfirm(){
		const copyOfHierarchieArray = Object.keys({...productFeedObject.categoryHierarchies});
		const copyOfHierarchie = {...productFeedObject.categoryHierarchies};

		const array = [];
		for (const [key, value] of Object.entries(productFeedObject.categoryHierarchies)) {
			array.push({hierarchyName: key, categories: value});
		}
		const isLastCategory = array.some(h => h.hierarchyName === deleteCat.hierarchie && h.categories.length == 1);
		if (isLastCategory) {
			deletenameHierarchieConfirm(deleteCat.hierarchie);
			return;
		}

		const newHierarchieObject = copyOfHierarchieArray.reduce((acc, curr) => {
			if (curr === deleteCat.hierarchie ) {
				return acc = {...acc,
					[curr]  : [...copyOfHierarchie[curr].filter(el => el !== deleteCat.nameCategory )]};
			}
			else {
				return acc = {...acc, [curr] : [...copyOfHierarchie[curr]]};
			}
		}, {});

		setshowDelConfirm(false);
		setisExistingObj(false);
		setDeletedCat();
		HandleSubmitHierarchie(newHierarchieObject);
	}
	function deletenameHierarchieConfirm(h){
		const copyOfHierarchieArray = Object.keys({...productFeedObject.categoryHierarchies});
		const copyOfHierarchie = {...productFeedObject.categoryHierarchies};

		const newHierarchieObject = copyOfHierarchieArray.reduce((acc, curr) => {
			if (curr !== h ) {
				return acc = {...acc,
					[curr]  : [...copyOfHierarchie[curr]]};
			}
			return acc;
		}, {});

		setshowDeleteHierarchie(false);
		setdeleteAHierarchie();
		setisExistingObj(false);
		HandleSubmitHierarchie(newHierarchieObject);
	}
	function deleteCatFn(hierarchie,cat,key){
		setDeletedCat({
			hierarchie : hierarchie,
			nameCategory : cat,
			key : key
		});
		setshowDelConfirm(true);
	}
	function deleteHierarchieFn(hierarchie){
		setdeleteAHierarchie(hierarchie);
		setshowDeleteHierarchie(true);
	}
	function handleCreateEmptyCat(hierarchie){
		setSelectedCatModalIsOpen(true);
		setisExistingObj(false);
		setcatSelected('');
		setoldObject({
			hierarchie : hierarchie,
		});
	}
	function handleSelectCat(hierarchie,cat,key){
		setSelectedCatModalIsOpen(true);
		setoldObject({
			hierarchie : hierarchie,
			nameCategory : cat,
			key : key
		});
		setcatSelected(cat);
		setisExistingObj(true);
	}
	function close(){
		setshowDelConfirm(false);
		setisExistingObj(false);
		setcatSelected('');
	}
	function closeDeleteHierarchie(){
		setshowDeleteHierarchie(false);
		setisExistingObj(false);
		setdeleteAHierarchie();
	}
	function getItemStyle (isDragging, draggableStyle) {
		return {
			gridTemplateColumns: '2fr 4fr 2fr',
			...draggableStyle 	};
	}
	const arrayOfHierarchies = productFeedObject?.categoryHierarchies ? Object.keys(productFeedObject.categoryHierarchies) : [];

	const handleOnDragEnd = (result, el) => {
		if (!result.destination) return;
		const copyOfHierarchie = {...productFeedObject.categoryHierarchies};
		const items = Array.from(copyOfHierarchie[el]);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		copyOfHierarchie[el] = items;
		HandleSubmitHierarchie(copyOfHierarchie);
	};
	function handleCreateEmptyHierarchie(){
		const copyOfHierarchie =  productFeedObject?.categoryHierarchies ? productFeedObject.categoryHierarchies : [];
		const copyOfHierarchieArray = Object.keys({...copyOfHierarchie});
		copyOfHierarchie[copyOfHierarchieArray.length === 0 ? 'cat_categories' : 'cat_categories' + (parseInt(copyOfHierarchieArray.length) +1) ] = [];
		HandleSubmitHierarchie(copyOfHierarchie);
	}
	function handleCreateEmptyHierarchieAndCat(){
		const copyOfHierarchie =  productFeedObject?.categoryHierarchies ? productFeedObject.categoryHierarchies : [];
		const length = Object.keys(copyOfHierarchie).length;
		const name = 'cat_categories' + (length + 1);
		copyOfHierarchie[name];
		setSelectedCatModalIsOpen(true);
		setoldObject({
			hierarchie : name,
			nameCategory : '',
			key : 0
		});
		setcatSelected('');
		HandleSubmitHierarchie(copyOfHierarchie);
	}
	return (
		<>
			<Accordion
				label={'Categories'}
				value={''}
				placeholder="Not set"
				fieldIndex={3}
				isEmpty={!arrayOfHierarchies || arrayOfHierarchies.length == 0}
				showDoneButton={arrayOfHierarchies.length > 0}
				headerIntro={<>Name your feed <strong>Categories</strong></>}
			>
				{arrayOfHierarchies.length > 0 &&
					<>
						<div className={editionStyles.table}>
							<div className={editionStyles.table_head_row}>
								<div className={editionStyles.table_col}>Name</div>
								<div className={editionStyles.table_col}></div>
							</div>
							{arrayOfHierarchies.map((el,i) =>
								<div key={i}>
									{i > 0 &&
										<div className={hasSubCategories ? editionStyles.table_sep_l : editionStyles.table_sep}></div>
									}
									<DragDropContext onDragEnd={(e)=>handleOnDragEnd(e,el)}>
										<Droppable droppableId={el.toString()} direction="vertical">
											{(provided, snapshot) => (
												<div
													{...provided.droppableProps}
													ref={provided.innerRef}
												>
													{productFeedObject.categoryHierarchies[el].map((cat, id)=>
														<Draggable key={id} draggableId={id.toString()} index={parseInt(id)}>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	className={editionStyles.table_body_row}
																>
																	<div className={editionStyles.table_col}>
																		{id > 0 ?
																			<span className={editionStyles.table_sub_label} style={{'--sub_label': id}}>
																				{cat}
																			</span>
																			:
																			<span>{cat}</span>
																		}
																	</div>
																	<div className={editionStyles.table_col}>
																		{id == 0 &&
																			<IconBtn 
																				tooltip="Add sub category"
																				icon='fas fa-plus'
																				onClick={() => handleCreateEmptyCat(el)}
																			/>
																		}
																		<IconBtn
																			tooltip="Edit category"
																			icon="fas fa-pen"
																			onClick={() => handleSelectCat(el,cat, id)}
																		/>
																		<IconBtn
																			tooltip="Delete category"
																			icon="fas fa-trash"
																			onClick={() => deleteCatFn(el,cat, id)}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													)}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								</div>
							)}
						</div>
						<div className='mt_10'>
							<Btn
								style="reverse"
								icon="fas fa-plus fa-sm"
								message="Add main category"
								size="s"
								horizontalSize='xs'
								onClick={() =>
									handleCreateEmptyHierarchieAndCat()
								}
							/>
						</div>
					</>
				}
				{arrayOfHierarchies.length == 0 &&
					<EmptyState
						text="No category yet"
						verticalSize='s'
						primaryAction={
							<Btn
								onClick={() => handleCreateEmptyHierarchieAndCat()}
								message="Add category"
								icon="fas fa-plus fa-sm"
								style="reverse"
								horizontalSize="xs"
							/>
						}
					/>
				}

				{/* {arrayOfHierarchies.length > 0 &&
					<div className='flex flex_justify_end'>
						<Btn
							onClick={() => {
								handleCreateEmptyHierarchie();
							}}
						>
							{'Add hierarchie'}
						</Btn>
					</div>
				}
				{arrayOfHierarchies.length === 0 && 
					<div className="flex flex_v flex_align_center">
						<div>
							<strong>No category hierarchie yet</strong>
						</div>
						<div className='flex flex_justify_end  mb_5 mt_5'>
							<Btn
								onClick={() => {
									handleCreateEmptyHierarchieAndCat();
								}}
							>
								Add category
							</Btn>
						</div>
					</div>
				}
				{arrayOfHierarchies.map((el,i) =>
					<div key={i}>
						<DragDropContext onDragEnd={(e)=>handleOnDragEnd(e,el)}>
							<Droppable droppableId={el.toString()} direction="vertical">
								{(provided) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
										className="mb_5 mt_5"
									>
										{arrayOfHierarchies.length > 1 &&
											<>
												{seeInputNameHierarchieForEdit !== i && 
													<div className='flex flex_justify_around'>
														<div>
															<span>{el}</span>
														</div>
														<div>
															<a
																onClick={()=>HierarchieEditInput(i)}
																className='icon_btn m'>
																<i 
																	className='fas fa-pen'></i>
															</a>
															<a
																onClick={()=>deleteHierarchieFn(el)}
																className='icon_btn m'>
																<i className='fas fa-trash'
																></i>
															</a>
														</div>
													</div>
												}
												
												{seeInputNameHierarchieForEdit === i &&
													<div className='flex  flex_justify_around'>
														<InputCustom
															value={el}
															type="text"
															label={'Name hierarchy'}
															name={el}
															placeholder=""
															onChange={(e) => handleChangeHierarchyName(e)}
															fullWidth={true}
														/>
														<div>
															<a
																className='icon_btn m'
																onClick={()=>deleteHierarchieFn(el)}
															>
																<i className='fas fa-trash'
																></i>
															</a>
														</div>
													</div>
												}
											</>
										}
										{productFeedObject.categoryHierarchies[el].length !== 0 &&
											<div className='flex flex_justify_end  mb_5 mt_5'>
												<Btn
													onClick={() => {
														handleCreateEmptyCat(el);
													}}
													message="Add category"
												/>
											</div>
										}
										{productFeedObject.categoryHierarchies[el].map((cat, id)=>
											<div key={id}>
												<Draggable draggableId={id.toString()} index={parseInt(id)}>
													{(provided, snapshot) => (
														<div
															style={getItemStyle(
																snapshot.isDragging,
																provided.draggableProps.style)}
															key={id}
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={'table_row table_body_row'}  >
															<div className={'table_col'}>
																{cat}
															</div>
															<div className={'table_col'}>
															</div>
															<div className={'table_col'}>
																<a
																	className='icon_btn m'
																	data-tip="Edit category"
																	onClick={() => {
																		handleSelectCat(el,cat, id);
																	}}
																>
																	<i className='fas fa-pen'></i>
																</a>
																<a
																	className='icon_btn m'
																	data-tip="Delete category"
																	onClick={() => {deleteCatFn(el,cat, id);
																	}}
																>
																	<i className='fas fa-trash'></i>
																</a>
															</div>
														</div>)}
												</Draggable>
											</div>
										)}
										{productFeedObject.categoryHierarchies[el].length === 0 &&
											<div className="flex flex_v flex_align_center">
												<div>
													<strong>No category yet</strong>
												</div>
												<div className='flex flex_justify_end  mb_5 mt_5'>
													<Btn
														onClick={() => {
															handleCreateEmptyCat(el);
														}}
														message="Add category"
													/>
												</div>
											</div>
										}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>
				)} */}
			</Accordion>
		
			<ModalHierarchieCat
				selectedCatModalIsOpen={selectedCatModalIsOpen}
				setSelectedCatModalIsOpen={setSelectedCatModalIsOpen}
				catSelected={catSelected}
				isExistingObj={isExistingObj}
				handleChangeCategorie={handleChangeCategorie}
				submitNewCat={submitNewCat}
				setisExistingObj={setisExistingObj}
			/>
	
			<Confirm
				title="Do you really want to delete this categorie ?"
				text="This action is irreversible"
				confirmText="Delete"
				confirmColor="alert"
				cancelText="Cancel"
				isOpen={showDelConfirm}
				confirmCallback={()=>deletenameCatConfirm()}
				onClose={close}
			/>
			<Confirm
				title="Do you really want to delete this hierarchie ?"
				text="This action is irreversible"
				confirmText="Delete"
				confirmColor="alert"
				cancelText="Cancel"
				isOpen={showDeleteHierarchie}
				confirmCallback={()=>deletenameHierarchieConfirm(deleteAHierarchie)}
				onClose={closeDeleteHierarchie}
			/>
		</>
	);
}
