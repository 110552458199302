/* eslint-disable react/display-name */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import styles from './Board.module.css';

import { usePageContext } from '../../context/PageContext';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import PaginationBoard from './PaginationBoard';
import AnalitycsBoardPage from './AnalyticsBoardPage';
import AnalyticsBoardPageCustomItem from './AnalyticsBoardPageCustomItem';
// eslint-disable-next-line react/display-name

export default function ({ pageService }) {
	const { selectedTenant } = useAnalyticsContext();
	const {
		handleSelectUrl,
		reportTypeSelected,
		navFilter,
		searchValue,
		sourceType,
		orderBy,
		setOrderBy,
		orderByDesc,
		setOrderByDesc,
		filterUrl,
		offset, 
		setOffset,
		currentPage,
		setCurrentPage,
		utmDimensionsSelected,
		needRefreshForUtmFilter,
		deviceSelected,
		excludeFilter,
		isCustomDimensionMode,
		handleSelectTagCustom,
		selectedCustomTag
	
	} = usePageContext();

	const [afterRender,setAfterRender] = useState(false);
	const [currentMetrix, setCurrentMetrix] = useState({
		data: null,
		isLoading: true,
		isError: false,
	});
	const [numberOfData, setNumberOfData] = useState(null);
	const [tableToRender, setTableToRender] = useState(<></>);
	const [pageSize, setpageSize] = useState(10);
	const [urlSelected, setUrlSelected] = useState();
    
	useEffect(() => {
		setAfterRender(true);
		if(offset !== 0){
			setOffset(0);
		}      
		else if (afterRender) {
			refreshBoard();
		}   
	}, [selectedTenant]);

	useEffect(() => {
		if (!filterUrl) {
			setUrlSelected();
		}
	}, [filterUrl]);

	useEffect(() => {
		if (urlSelected && urlSelected !== filterUrl) {
			handleSelectUrl(urlSelected);
		}
	}, [urlSelected]);

	const refreshBoard = () => {
		setCurrentMetrix((current) => ({ ...current,data:null, isLoading: true }));
		if(!selectedCustomTag){
			pageService.getBoardMetrics(
				deviceSelected,
				navFilter,
				selectedTenant,
				reportTypeSelected,
				sourceType,
				offset,
				pageSize,
				orderBy,
				orderByDesc === 'desc' ? true : false,
				searchValue ? searchValue : null,
				utmDimensionsSelected,
				excludeFilter,
				
				(data, headers) => {
					const dataFormatted = data.map(field => {
						field['BounceRate'] = parseInt((field['BounceRate'] * 100).toFixed(2));
						return field;
					});
					if(numberOfData!==parseInt(headers('x-total-count'))){
						setNumberOfData(parseInt(headers('x-total-count')));}
					setCurrentMetrix(() => ({
						data: dataFormatted,
						isLoding: false,
						isError: false,
					}));
					renderTable(dataFormatted);
				},
			
				(err) => {
					console.log(err);
					setCurrentMetrix((current) => ({
						...current,
						isLoding: false,
						isError: false,
					}));
				});

		}else{
			pageService.getBoardMetricsCustomDimensions(
				deviceSelected,
				navFilter,
				selectedTenant,
				reportTypeSelected,
				sourceType,
				offset,
				pageSize,
				orderBy,
				orderByDesc === 'desc' ? true : false,
				selectedCustomTag ? selectedCustomTag : null,
				utmDimensionsSelected,
				false,
				searchValue,
				searchValue.length === '' ? true : false,
				excludeFilter,
				
				(data, headers) => {
					const dataFormatted = data.map(field => {
						field['BounceRate'] = parseInt((field['BounceRate'] * 100).toFixed(2));
						return field;
					});
					if(numberOfData!==parseInt(headers('x-total-count'))){
						setNumberOfData(parseInt(headers('x-total-count')));}
					setCurrentMetrix(() => ({
						data: dataFormatted,
						isLoding: false,
						isError: false,
					}));
					renderTable(dataFormatted);
				},
			
				(err) => {
					console.log(err);
					setCurrentMetrix((current) => ({
						...current,
						isLoding: false,
						isError: false,
					}));
				});
		}
		
	};
	useEffect(() => {
		refreshBoard();
	}, [
		navFilter,
		reportTypeSelected,
		orderBy,
		orderByDesc,
		offset,
		pageSize,
		searchValue,
		sourceType,
		needRefreshForUtmFilter,
		deviceSelected,
		excludeFilter,
		isCustomDimensionMode,
		selectedCustomTag
	]);


	const handleSort = (orderField, order) => {
		setOrderByDesc(order);
		setOrderBy(orderField);
		setOffset(0);
		setCurrentPage(1);
	};


	function renderTable(data) {
		if(isCustomDimensionMode){
			if(!selectedCustomTag){
				setTableToRender(
					<AnalyticsBoardPageCustomItem
						resultName="Value"
						uniqueViews="Unique Views"
						averageTimeOnPage="Average time spent on page (s)"
						bounceRate="Bounce rate"
						landings="Landings"
						exits="Exits"
						data={data}
						orderBy={orderBy}
						orderByDesc={orderByDesc}
						handleSort={handleSort}
						hanleSelectUrl={(e)=>handleSelectTagCustom(e)}
					/>
				);
			}else{
				setTableToRender(
					<AnalitycsBoardPage
						resultName="URL"
						uniqueViews="Unique Views"
						averageTimeOnPage="Average time spent on page (s)"
						bounceRate="Bounce rate"
						landings="Landings"
						exits="Exits"
						data={data}
						orderBy={orderBy}
						orderByDesc={orderByDesc}
						handleSort={handleSort}
						hanleSelectUrl={(e)=>{}}
					/>
				);
			}
		}else{
			setTableToRender(
				<AnalitycsBoardPage
					resultName="URL"
					uniqueViews="Unique Views"
					averageTimeOnPage="Average time spent on page (s)"
					bounceRate="Bounce rate"
					landings="Landings"
					exits="Exits"
					data={data}
					orderBy={orderBy}
					orderByDesc={orderByDesc}
					handleSort={handleSort}
					hanleSelectUrl={(e)=>setUrlSelected(e)}
				/>
			);
		}
	}

	return (
		<section className={styles.section}>
			{currentMetrix.isLoading && !currentMetrix.isError && (
				<div className="mask">
					<span className="wheel"></span>
				</div>
			)}
			{!currentMetrix.isLoading && currentMetrix.isError && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_error.svg"
					/>
					<div className="empty_state_title">Cannot fetch data</div>
					<div className="empty_state_text">Please retry later.</div>
				</div>
			)}
			{!currentMetrix.isLoading &&
                !currentMetrix.isError &&
                currentMetrix.data.length == 0 && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_empty.svg"
					/>
					<div className="empty_state_title">
                            No data for this period
					</div>
					<div className="empty_state_text">
                            Maybe try with another period or other filters
					</div>
				</div>
			)}

			{!currentMetrix.isLoading &&
                !currentMetrix.isError &&
                currentMetrix.data &&
                currentMetrix.data.length > 0 && (
				<>
					<div className="analytics_table_wrapper">
						{tableToRender}
					</div>
					<PaginationBoard
						totalCount={numberOfData}
						pageSize={pageSize}
						handleChangePageSize={(e) => {
							setpageSize(e);
							setOffset(0);
						}}
						paginate={(e) => setOffset(e >= 0 ? e : 0)}
						skipIdNumber={offset}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				</>
			)}
		</section>
	);
}
