import React from 'react';
import { useLocation } from 'react-router-dom';
import ContextTrafficProvider from '../context/ContextEcommerce';
import EcommerceSection from './EcommerceSection';
export default function TrafficContainer(props) {


	const location = useLocation();
	if(location.pathname !== '/Insight/ecommerce'){
		return <></>;
	}

	return (
		<ContextTrafficProvider
			$http={props.props.$http}
			$rootScope={props.props.$rootScope}
			$routeParams={props.props.$routeParams}
			AuthServices={props.props.AuthServices}
		>
			<EcommerceSection />
		</ContextTrafficProvider>
	);
}
