import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { PanelSelect, PanelFieldLabel, PanelInitTypeSelection, PanelActions, PanelHeader, PanelFooter } from './FormCustomPanelSubComponents';
import FormCustomMultipleControls from './FormCustomMultipleControls';
import styles from './FormCustomPanel.module.css';
import PanelArticle from '../../../Components/PanelArticle';
import IconBtn from '../../../Components/IconBtn';
import InputCustom from '../../../Components/InputCustom';
import SelectCustom from '../../../Components/SelectCustom';
import Checkbox from '../../../Components/Checkbox';
import controlTypes from './controlTypes';

const getPanelStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	boxShadow: isDragging ? '0 3px 5px #000' : 'none',
	...draggableStyle
});

export default class FormCustomPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: props.type ? props.type : '',
			question: props.question ? props.question : '',
			placeholder: props.placeholder ? props.placeholder : '',
			controls: props.controls ? props.controls : '',
			required: props.required ? props.required : false,
			buttonType: props.buttonType ? props.buttonType : 'Submit',
			name: props.name ? props.name : '',
			value: props.value ? props.value: '', 
		};
	}
  
	remove() {
		this.props.onRemove(this.props.id, this.props.sections.current);
	}
  
	duplicate() {
		this.props.onDuplicate(this.props.id, this.props.sections.current);
	}

	setName(e) {
		const state = {name: e.target.value};

		this.setState(state, () => {
			this.props.onContentChange(this.props.id, this.props.sections.current, state);
		});
	}
  
	setAsRequired(e) {
		const state = {required: e.target.checked};

		this.setState(state, () => {
			this.props.onContentChange(this.props.id, this.props.sections.current, state);
		});
	}

	handleFieldBlur(e) {
		const state = {[e.target.name]: e.target.value};

		this.setState(state, () => {
			this.props.onContentChange(this.props.id, this.props.sections.current, state);
		});
	}
  
	setControls(controls) {
		const state = {controls};
    
		this.setState(state, () => {
			this.props.onContentChange(this.props.id, this.props.sections.current, state);
		});
	}

	selectControlType(e) {
		const state = {type: this.getType(e.target.value).id};

		this.setState(state, () => {
			this.props.onContentChange(this.props.id, this.props.sections.current, state);
		});
	}

	getType(id) {
		const identifier = parseInt(id, 10);

		return this.props.controlTypes.find(controlType => controlType.id === identifier);
	}

	handleButtons(e) {
		e.persist();
		const btnValue = e.target.value;
		const state = {buttonType: btnValue};

		if(btnValue === 'next section') {
			if (!this.props.sections.next){
				this.props.onSectionAdd();
			}
		} else {
			if(this.props.sections.next) {
				this.props.onSectionRemove(this.props.sections.next);
			}
		}

		this.setState(state, () => {
			this.props.onContentChange(this.props.id, this.props.sections.current, state);
		});
	}

	getPlaceholderField() {
		return ( 
			<div className={styles.field_col_m}>
				<InputCustom
					label="Placeholder"
					placeHolder='Help text present when field is empty'
					name="placeholder"
					value={this.state.placeholder}
					onChange={(e) => this.handleFieldBlur(e)}
					fullWidth={true}
				/>
			</div>
		);
	}
	getQuestionField() {
		return ( 
			<div className={styles.field_col_l}>
				<InputCustom
					label={this.state.type === 3 ? 'Text': 'Question'}
					placeHolder={this.state.type === 3 ? '' : 'Question asked to the visitor'}
					name="question"
					value={this.state.question}
					onChange={(e) => this.handleFieldBlur(e)}
					fullWidth={true}
				/>
			</div>
		);
	}
	getValueField() {
		return ( 
			<div className={styles.field_col_m}>
				<InputCustom
					label="Value"
					name="value"
					value={this.state.value}
					onChange={(e) => this.handleFieldBlur(e)}
					fullWidth={true}
				/>
			</div>
		);
	}
	getNameField() {
		return ( 
			<div className={styles.field_col_s}>
				<InputCustom
					label="Name"
					placeHolder='Technical name'
					value={this.state.name}
					onChange={this.setName.bind(this)}
					fullWidth={true}
				/>
			</div>
		);
	}
	getparagraphControls() {
		return this.getPlaceholderField();
	}
	gettextControls() {
		return this.getPlaceholderField();
	}
	getemailControls() {
		return this.getPlaceholderField();
	}
	getphoneControls() {
		return this.getPlaceholderField();
	}
	gethiddenControls() {
		return this.getValueField();
	}

	getbuttonControls() {
		return (
			<SelectCustom
				label="Action"
				value={this.state.buttonType}
				onChange={(e) => this.handleButtons(e)}
				optionsList={[
					{value: 'submit', label: 'Submit'},
					{value: 'next section', label: 'Next section'}
				]}
			/>
		);
	}

	componentDidUpdate(prevProps) {
		const propsToRefresh = ['type', 'question', 'placeholder', 'controls', 'required', 'buttonType'];

		for(const prop of propsToRefresh) {
			if(prevProps[prop] !== this.props[prop]) {
				this.setState({[prop]: this.props[prop]});
			}
		}
	}
  
	render() {
		const buttonType = {
			id: 3,
			name: 'button',
			multiple: false,
			icon: 'square',
			color: 4,
			section: 'Various fields'
		};
		const typeObj = this.state.type === 3 ? buttonType : this.getType(this.state.type);
		const panelActions = {
			duplicate: this.state.type !== 3 ? this.duplicate.bind(this) : null,
			remove: this.remove.bind(this)
		};

		return (
			<>
				{this.state.type ? (
					<Draggable draggableId={`panel-${this.props.id}`} index={this.props.index}>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								style={getPanelStyle(
									snapshot.isDragging,
									provided.draggableProps.style
								)}>
								<PanelArticle size="l">
									<div className={styles.panelForm}>
										<PanelHeader styles={styles}>
											<div className={styles.panelHeaderName}>
												{this.state.type === 3 ? 
													<PanelFieldLabel
														styles={styles}
														name={buttonType.name}
														icon={buttonType.icon}
														color={buttonType.color}
													/>
													:
													<PanelSelect
														styles={styles}
														options={controlTypes}
														typeObj={typeObj}
														type={this.state.type}
														onSelectChange={this.selectControlType.bind(this)}
													/>
												}
											</div>
											<div className={styles.panelHeaderAction}>
												<PanelActions
													styles={styles}
													actions={panelActions}
												/>
												<span {...provided.dragHandleProps}>
													<IconBtn
														icon="fas fa-sort"
														tooltip="Sort field"
													/>
												</span>
											</div>
										</PanelHeader>

										<div>
											<div className={styles.field_row}>
												{this.state.type !== 3 && this.getNameField()}
												{this.state.type !== 8 && this.getQuestionField()}
												{!typeObj.multiple && this[`get${typeObj.name}Controls`]()}
											</div>

											{typeObj.multiple &&
												<FormCustomMultipleControls
													styles={styles}
													type={typeObj.name}
													controls={this.state.controls}
													onControlsChange={this.setControls.bind(this)}
												/>
											}
										</div>
						
										{this.props.displayRequired &&
											<div>
												<Checkbox
													label="mandatory field"
													onChange={this.setAsRequired.bind(this)}
													checked={this.state.required}
												/>
											</div>
										}
									</div>
								</PanelArticle>
							</div>
						)}
					</Draggable>
				) : (
					<PanelInitTypeSelection
						styles={styles}
						controlTypes={this.props.controlTypes}
						onSelect={this.selectControlType.bind(this)}
						remove={panelActions.remove}
					/>
				)}
			</>
		);
	}
}