// Visitor engagement
import './Auth/VisitorEngagement/VisitorEngagementContainer';
import './Auth/VisitorEngagementReports/VisitorEngagementReportsContainer';

// Form custom
import './Auth/Campaigns/FormCustom/FormCustomContainer';
import './Auth/Dashboard/FormCustomReports/FormCustomReportsContainer';

// Promo code
import './Auth/PromoCodes/PromoCodeCreateContainer';
import './Auth/PromoCodes/PromoCodesListContainer';


// Campaign Reporting
import './Auth/ReportingCampaign/ReportingCampaignContainer';
import './Auth/ReportingCampaign/ChartPerformCampaign';

// Campaign Create
import './Auth/Campaigns/SkuList/SkuContainer';
import './Auth/Campaigns/Variants/VariantsContainer';
import './Auth/Campaigns/Triggers/TriggersContainer';
import './Auth/Campaigns/Triggers/TriggersContainerBridge';

//Product Tag
import './Auth/ProductSettings/Tags/ProductTagContainer';

//Business Operation
import './Auth/BusinessOperations/Create/BusinessOperationCreateContainerWithContext';
import './Auth/BusinessOperations/Dashboard/Dashboard';
import './Auth/BusinessOperations/Statistics/StatisticsContainer';
// import planning container for business operations
import './Auth/BusinessOperations/Planning/PlanningContainer';
// import orchestration container for business operations
import './Auth/BusinessOperations/Orchestration/OrchestrationContainerWithContext';

//Cross Sell Up Sell
import './Auth/ProductSettings/CrossSellUpSell/CrossSellUpSellContainer';
import './Auth/ProductSettings/ProductSettingsContainer';
import './Auth/ProductSettings/RecoSelectors/RecoSelector';

// Product Settings
import './Auth/ProductSettings/Recommendations/Edition/RecommendationsBridge';
import './Auth/ProductSettings/Ranking/Edition/RankingBridge';
import './Auth/ProductSettings/Tags/Edition/TaggingBridge';
import './Auth/ProductSettings/RecommendationRuleCompositions/Edition/RuleCompositionBridge.js';


//Ui 
import './Components/UiContainer';

// SALES DEMO
import './Auth/Demo/DemoDashboard';

//ACCOUNT HOME 
import './Auth/Account/AccountHomeContainer';
import './Auth/Tooling/EditAccountTooling';
import './Auth/Account/AccountHome';
// Account Parameters
import './Auth/Tooling/AccountConfigurations/AccountParametersContainer';

// Account Parameters EMR 
import './Auth/Tooling/EmailRemarketingConfiguration/EmailRemarketingConfiguration';
import './Auth/EmailTemplate/List/ModuleWrapperRouter'
// Admin Account Settings section Setup 
import './Auth/AccountSetup/SetupContainer';
import './Auth/Account/AnalyticsConfiguration/WebAnalyticsSetupContainer';

// Account Settings section Users Administration 
import './Auth/UsersAdministration/UsersAdministrationContainer';

// Account management Admin
import './Auth/Admin/AdminAdministration/AccountAdministrationContainer';

// import orchestration container
import './Auth/Dashboard/Orchestration/OrchestrationContainer';

// import emailing performances container
import './Auth/Dashboard/EmailRemarketing/EmailRemarketingContainer';

// import autoComplete container
import './Auth/ReferenceData/AutoCompleteContainer';

// import Components Bridges
import './ComponentsBridges/TimezoneSelectBridge';

// import campaigns dashboards
import './Auth/Dashboard/OnSite/OnsiteBridge';

// import server-side campaigns dashoards
import './Auth/ServerSide/ServerSideDashboardContainer';
import './Auth/ServerSide/ServerSideCampaignReportingContainer';


// import ab tests 
import './Auth/ABTests/Dashboard/ABTestsDashboardPendingContainer';
import './Auth/ABTests/Dashboard/ABTestsDashboardCompletedContainer';
import './Auth/ABTests/Reporting/ABTestsReportingContainer';

// import segmentation
import './Auth/SegmentGroups/List/SegmentationListContainer';
import './Auth/SegmentGroups/Edition/SegmentationEditorContainer';


// import Insights 
import './Auth/Dashboard/AiInsights/AiInsightsContainer';
import './Auth/Insights/InsightContainer';
import './Auth/Insights/ConfigurationDashboardInsights';

//import shepherd
import './Auth/Campaigns/Inspirations/ShepherdContainer';

// import Insights 
import './Auth/Insights/ConfigurationDashboardInsights';

// import inspirations pannel
import './Auth/Campaigns/Inspirations/InspirationPanel';
// import React app
import './Auth/ReactApp/ReactApp';

// import Campaigns for React Version
import './Auth/Campaigns_React';
// import onboarding
import './Auth/Onboarding/OnboardingContainer';

//import globalDashboard
import './Auth/Dashboard/GlobalDashboard/GlobalDashboard';

// import web analytics
import './Auth/WebAnalytics/WebAnalyticsContainer';

// import email template
import './Auth/EmailTemplate/List/EmailTemplateListContainer';
import './Auth/EmailTemplate/Editor/EmailTemplateEditorContainer';

// import features flags pannel
import '../flags/component/FlagPannel';
// import admin part 
import './Auth/Admin/History/HistoryBridge';
import './Auth/Admin/CreateAccount/CreateAccount';
import './Auth/Admin/IpAdresses/IpAdressesAdminContainer';
import './Auth/Admin/MainInformations/MainInformations';
import './Auth/Admin/ConfigTriggers/ConfigTriggersContainer';

// Import for translation 
import './Translation/TranslationContext';

import './Auth/ProductFeedConfig/Edition/ProductFeedEditorBridge';
import './Auth/ProductFeedConfig/Feed/ProductFeedConfigBridge';
import './Auth/ProductFeedConfig/Products/ProductFeedProductBridge';
// Internal
import './Auth/Internal/AccountList/AccountListBridge';
import './Auth/Menu/Menu.js';
import './Auth/Menu/AppHeader.js';
import './Auth/Menu/Notifications';
import { initFeatureFlags } from '../flags';

import './Auth/Chat/ChatBotContainer';
initFeatureFlags();