import React, { Fragment, useState, useEffect } from 'react';
import Btn from '../../../Components/Btn';
import ScheduleItem from './ScheduleItem';
import { v4 as uuidv4 } from 'uuid';

export default function TriggerSchedule ({
    triggerValue,
    setTriggerValue
}) {

    const [schedules, setSchedules] = useState((triggerValue || []).map(t => ({value:t, uniqueId:uuidv4() })))

    useEffect(() => {
        let newTriggerValue = schedules.map(t => t.value)
        setTriggerValue(newTriggerValue)
    }, [schedules])

    const onChangeScheduleTrigger = (newValue, uniqueId) => {
        let newSchedules = schedules.map(s => s.uniqueId != uniqueId ? s : {...s, value:newValue})
        setSchedules(newSchedules)
    }

    const addNewSchedule = () => {
        const defaultValue = "* * * * *"        
        let newSchedules = [...schedules]
        newSchedules.push({value:defaultValue, uniqueId:uuidv4()})
        setSchedules(newSchedules)
    }

    const deleteSchedule = (uniqueId) => {
        let newSchedules = schedules.filter(s => s.uniqueId != uniqueId)
        setSchedules(newSchedules)
    }

    return (
        <>
            <Btn
                onClickFunction={() => addNewSchedule()}
                icon="fas fa-plus"
                size="l"
                message="Add "
                color="primary" 
            />
            {schedules.map(v =>
                <Fragment
                    key={v.uniqueId}
                >
                    <ScheduleItem
                        defaultValue={v.value}
                        onChange={value => onChangeScheduleTrigger(value, v.uniqueId)}
                        children={
                            <Btn
                                onClickFunction={() => deleteSchedule(v.uniqueId)}
                                icon="fas fa-trash"
                                size="l"
                                color="alert" 
                            />
                        }>
                    </ScheduleItem>
                </Fragment>
            )}        
        </>
    )
}