import React,{useState} from 'react';
import { useEcommerceAnalyticsContext } from '../../../context/EcommerceAnalyticsContext';
import NavPerfomance from '../../Performance/NavPerfomance';

import { ChipContainer } from '../ChipContainer';
import {
	useEcommercePerformanceContext
} from '../../../context/EcommercePerformanceContext';

import ReportTypeFilter from '../../Performance/ReportTypeFilter';
import PerformanceChartContainer from '../../ChartContainer/PerformanceChartContainer';
import PerformanceBoardContainer from '../../Performance/PerformanceBoardContainer';
import SelectDeviceSimple from '../../Devices/SelectDeviceSimple';
import UtmFiltersPerformanceContainer from './UtmFiltersPerformanceContainer';
import SourceTypeFilter from '../../SourceType/SourceTypeFilter';
import SelectDevice from '../../Devices/SelectDevice';
const getDimensions = () => {
	return [
		{ label: 'Product', key: 'Product', checked: true },
		{ label: 'Categories', key: 'Category', checked: true },
		{ label: 'Brand', key: 'Brand', checked: true },
		
	];
};
export default function PerformanceContainer({
	services,
	systemServices,
	sessionService
}) {

	const {
		// Tab state & utils
		activeSubDimensionTab,
		changeActiveSubDimensionTab,
		handleChangeReportType,
		reportTypePerformanceSelected,
		setdimensionIdPerformance,
		deviceSelected,
		devices,
		handleChangeDeviceSelected,
		resetDevices
	} = useEcommerceAnalyticsContext();

	const {	initiNavDate,
		accountId,
		navFilter,
		setNavFilter,
		handleSelectComparaisonPeriodHandler,
		sourceType,
		handleChangeSourceType,
		sourceTypes,
		chips,
		onDeleteChip,
		reportTypes,
		useTimeDisabled,
		setUseTimeDisabled,
		selectedTimeGranularity,
		setSelectedTimeGranularity,
		setisCustomSelected,
		isCustomSelected,
		customDateRange,
		setCustomDateRange,
		resetSource,
	} = useEcommercePerformanceContext();
	let initialDates = initiNavDate(navFilter);
	
	const dimensions = getDimensions();
	const localConfig = [
		{ label: 'None', value: 'None' },
		...dimensions.map((d) => ({ label: d.label, value: d.key })),
	];
	const [selectedDimension, setSelectedDimension] = useState({
		splitDimension: localConfig[0],
		useTime: true,
	});
	if (activeSubDimensionTab !== 'performance') {
		return <></>;
	}
	function onDeleteChipsFunction() {
		onDeleteChip();
		setdimensionIdPerformance();
	}
	return (
		<div className="flex_item_full">
			<div className="analytics_main_line">
				<NavPerfomance
				// ComparisonSelected={ComparisonSelected}
					setNavFilter={setNavFilter}
					initialDates={initialDates}
					handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
					setisCustomSelected={setisCustomSelected}
					isCustomSelected={isCustomSelected}
					customDateRange={customDateRange}
					setCustomDateRange={setCustomDateRange}
				/>
				<ReportTypeFilter
					reportTypeSelected={reportTypePerformanceSelected}
					handleChangeReportType={handleChangeReportType}
					initialValue={reportTypes}
				/>
				<SourceTypeFilter
					sourceTypeSelected={sourceType}
					handleChangeSourceType={handleChangeSourceType}
					initialValue={sourceTypes}
					resetSource={resetSource}
				/>
				<SelectDevice
					resetSource={resetDevices}
					initialValue={devices}
					devices={devices}
					deviceSelected={deviceSelected}
					handleChangeDeviceSelected={handleChangeDeviceSelected}
				/>
				<UtmFiltersPerformanceContainer
					performancesServices={sessionService}
				/>

				{chips && <ChipContainer
					chips={[chips]}
					onDelete={onDeleteChipsFunction}
				/>}
			</div>
			<div className="analytics_body">
				<div className="analytics_body_main">
					<PerformanceChartContainer
						systemServices={systemServices}
						navFilter={navFilter}
						service={services}
						accountId={accountId}
						selectedDimension={selectedDimension}
						setSelectedDimension={setSelectedDimension}
						useTimeDisabled={useTimeDisabled}
						setUseTimeDisabled={setUseTimeDisabled}
						selectedTimeGranularity={selectedTimeGranularity}
						setSelectedTimeGranularity={setSelectedTimeGranularity}
					/>
				</div>
				<div>
					<PerformanceBoardContainer
						services={services}
						systemServices={systemServices}
					/>
				</div>
		
			</div>
		</div>
	);
}
