import React from 'react';
import ReactTooltip from 'react-tooltip';

export default function Fieldset({
	children,
	label = '',
	description = '',
	labelClassName = '',
	labelTooltip = '',
	blockClassName = '',
	blockSize = 'm',
	inputID = null,
	append
}) {

	const labelClass = ['form_block_label'];
	const blockClass = ['form_block'];

	if( labelClassName ){
		labelClass.push(labelClassName);
	}
	if( blockClassName ){
		blockClass.push(blockClassName);
	}
	if (blockSize) {
		blockClass.push(blockSize);
	}

	return (
		<div className={blockClass.join(' ')}>
			{label &&
				<div className="flex">
					<div className="flex_item_full">
						<div className={labelClass.join(' ')}>
							<label htmlFor={inputID}>{label}</label>
							{labelTooltip &&
								<>
									<a className="icon_btn s v_al_bl" data-tip={labelTooltip}>
										<i className="fas fa-info-circle"></i>
									</a>
									<ReactTooltip
										backgroundColor="black"
										effect="solid"
										place="bottom"
										eventOff="click"
										delayShow={600}
									/>
								</>
							}
						</div>
						{description &&
							<div className={children ? 'form_block_description' : 'form_block_description is_last'}>{description}</div>
						}
					</div>
					{append &&
						<div className="flex_item_fix ml_20">{append}</div>
					}
				</div>
			}
			{children}
		</div>
	);
}
