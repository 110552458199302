import React from 'react';
import { useLocation } from 'react-router-dom';

import ContextTrafficProvider from '../context/ContextTraffic';
import TrafficSections from './TrafficSections';

export default function TrafficContainer(props) {


	// const [t] = useTranslation('kpi');
	const location = useLocation();
	if(location.pathname !== '/Insight/traffic'){
		return <></>;
	}

	return (
		<><ContextTrafficProvider
			$http={props.props.$http}
			$rootScope={props.props.$rootScope}
			$routeParams={props.props.$routeParams}
			AuthServices={props.props.AuthServices}
		>
			<TrafficSections />
		</ContextTrafficProvider></>
	);
}
