import React, {useEffect, useRef} from 'react';
import Btn from '../../Components/Btn';
import CampaignItem from './CampaignItem';
import { Trans, useTranslation } from 'react-i18next';
import renderJsxTag from '../../Translation/translation-utils';
import SearchBar from '../../Components/SearchBar';

export default function SearchCampaign({
	filteredType,
	setFilteredType,
	noData,
	valueInSearchBar,
	dataFiltered,
	sortByLastUpdate,
	handleChangeCampaigns,
	campaignId,
	handleOnClickCampaignIdToLoad,
	setTestModeIpAdressesOfCurrentCampaign,
	navReporting,
	shouldDisplayStatus
}) {

	const [t] = useTranslation(['common']);
	const refs = useRef({});

	useEffect(() => {
		if (refs.current && refs.current.resizer && refs.current.sidebar) {
			const resizer = refs.current.resizer;
			const sidebar = refs.current.sidebar;
			let startWidth = 0;
			let newWidth = 0;
			let startPageX = 0;
			const maxWidth = 450;
			const minWidth = 270;
			const defaultWidth = 270;

			const startResizing = (e) => {
				startWidth = sidebar.clientWidth;
				startPageX = e.pageX;
				document.addEventListener('mousemove', doResizing);
				document.addEventListener('mouseup', stopResizing);
			};
			const doResizing = (e) => {
				newWidth = startWidth + (e.pageX - startPageX);
				if (newWidth > maxWidth) newWidth = maxWidth;
				if (newWidth < minWidth) newWidth = minWidth;
				sidebar.style.width = newWidth + 'px';
			};
			const stopResizing = (e) => {
				document.removeEventListener('mousemove', doResizing);
				document.removeEventListener('mouseup', stopResizing);
				localStorage.setItem('campaign_reporting_sidebar_width', newWidth);
			};
			const resetResizing = (e) => {
				newWidth = defaultWidth;
				sidebar.style.width = newWidth + 'px';
				localStorage.setItem('campaign_reporting_sidebar_width', newWidth);
			};

			const storage = localStorage.getItem('campaign_reporting_sidebar_width');
			if (storage) {
				const storageNum = parseInt(storage, 10);
				if (storageNum > minWidth) {
					sidebar.style.width = storageNum + 'px';
				} else {
					localStorage.removeItem('campaign_reporting_sidebar_width');
				}
			}

			resizer.addEventListener('mousedown', startResizing);
			resizer.addEventListener('dblclick', resetResizing);
		}
	}, []);
	

	return (
		<div className="flex_item_fix page_sidebar flex flex_v" ref={el => refs.current['sidebar'] = el}>
			<div className='flex_item_fix'>
				{navReporting()}
			</div>
			<div className="flex_item_fix page_sidebar_search">
				<SearchBar
					placeholder={t('ReportingCampaignContainer.search')}
					onChange={(v) => handleChangeCampaigns(v)}
					value={valueInSearchBar}
					color='white'
				/>
			</div>
			{shouldDisplayStatus &&
				<div className="page_sidebar_status_switch btn_switch w_full">
					<Btn
						style="outline"
						size="xxs"
						message={t('ReportingCampaignContainer.Ongoing')}
						color="secondary"
						className={filteredType && filteredType === 'ongoing' ? 'active' : ''}
						onClickFunction={filteredType !== 'ongoing' ? () => setFilteredType('ongoing') : () => setFilteredType()}
					/>
					<Btn
						style="outline"
						size="xxs"
						message={t('ReportingCampaignContainer.Stopped')}
						color="secondary"
						className={filteredType && filteredType === 'stopped' ? 'active' : ''}
						onClickFunction={filteredType !== 'stopped' ? () => setFilteredType('stopped') : () => setFilteredType()}
					/>
					<Btn
						style="outline"
						size="xxs"
						message={t('ReportingCampaignContainer.Past')}
						color="secondary"
						className={filteredType && filteredType === 'past' ? 'active' : ''}
						onClickFunction={filteredType !== 'past' ? () => setFilteredType('past') : () => setFilteredType()}
					/>
				</div>
			}
			{noData &&
				<div className="flex_item_full page_sidebar_empty">
					{renderJsxTag(t('ReportingCampaignContainer.noCampaign'))}
				</div>
			}
			{!noData && dataFiltered &&
				<ul className="flex_item_full vscroll page_sidebar_list">
					{dataFiltered.sort(sortByLastUpdate).map(campaign =>
						<CampaignItem
							key={campaign.Key}
							campaignId={campaignId}
							campaign={campaign}
							handleOnClickCampaignIdToLoad={() => { handleOnClickCampaignIdToLoad(campaign.Key); setTestModeIpAdressesOfCurrentCampaign(campaign.TestModeIpAddresses); }}
							shouldDisplayStatus={shouldDisplayStatus}
						/>
					)}
				</ul>
			}
			<div className='page_sidebar_resizer' ref={el => refs.current['resizer'] = el}></div>
		</div>
	);
}
