import HttpServices from './HttpServices';

export default class NavigationServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}
	getAccountModules(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}navigation/${accountId}`, callbackSuccess, callbackError);
	}
	getAccountFavorites( callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}account/favorites`, callbackSuccess, callbackError);
	}
	postAccountFavorites(accountId, isFavorite, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}account/${accountId}/favorites?isfavorite=${isFavorite}`, callbackSuccess, callbackError);
	}
}