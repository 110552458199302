import React, { useState, useEffect } from 'react'
import Btn from './../../../Components/Btn';
import './FilesAttachmentContainer.css'
export default function FilesAttachmentContainer({ fileAttachments, addFileAttachment, selectFile, userClickOnDeleteFile }) {

    function sortArray(a, b) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
    }
    return (
        <div>
            < Btn
                icon="fas fa-plus"
                style="outline"
                color="secondary"
                message="Add a file"
                onClickFunction={(e) => addFileAttachment(e)}
            />
            {fileAttachments.sort(sortArray).map((file, i) =>
                <div key={i} className="files-attachment-container-item">
                    <span onClick={(e) => userClickOnDeleteFile(file)} className="files-attachment-delete-item" ><i className="fas fa-times"></i></span>
                    <div className="files-attachment-title" onClick={(e) => selectFile(file)}>
                        <i className="fas fa-file"></i>
                        <p>{file.name}</p>
                        <span title="Edit the list"><i className="fas fa-pen "></i></span>
                    </div>
                </div>

            )}
        </div>
    )
}
