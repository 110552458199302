import React, { useState, useEffect, useLayoutEffect } from 'react'
import { react2angular } from 'react2angular';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import '../../../../Styles/shepherd.css'
import Shepherd from './Shepherd';
import '../../../../Styles/btn.css';
import { TargeredPromotions, StopAbandonment } from './InspirationsModules/Tours';



export default function ShepherdContainer(props) {
    const [root, setroot] = useState({});
    const [modalIsOppen, setmodalIsOpen] = useState(false);
    props.$rootScope.oppenModal = (e) => setmodalIsOpen(e);
    let RouteParamsReady = props.$routeParams.tour;

    useEffect(() => {
        setroot(props.$rootScope)
    }, [props.$rootScope])

    if (!RouteParamsReady) {
        return (<></>)
    }

    const listOfInspirationsModules = { TargeredPromotions: TargeredPromotions, StopAbandonment: StopAbandonment };
    const typeOfInInspirationModule = props.$routeParams['tour'] ? props.$routeParams['tour'] : '';
    const typeOfInspirationScenario = props.$routeParams['id'] ? props.$routeParams['id'] : '';
    const tourModule = listOfInspirationsModules[typeOfInInspirationModule](props.$rootScope);
    const tourOptions = tourModule.tourOptions;
    let steps = tourModule.scenarios[typeOfInspirationScenario].steps;
    const tourDescription = tourModule.scenarios[typeOfInspirationScenario].description;
    const tourLabel = tourModule.scenarios[typeOfInspirationScenario].label;
    const moduleLabel = tourModule.label;

    return (
        <ShepherdTour steps={steps} tourOptions={tourOptions}>
            <Shepherd
                root={root}
                params={props.$location}
                tourDescription={tourDescription}
                tourLabel={tourLabel}
                moduleLabel={moduleLabel}
                modalIsOppen={modalIsOppen}
                setmodalIsOpen={(e) => setmodalIsOpen(e)}
            />
        </ShepherdTour>
    )
}
angular
    .module('beyableSaasApp.Shepherd', [])
    .component('shepherd', react2angular(ShepherdContainer, [], ['$http', '$rootScope', '$routeParams', '$location', '$scope']));