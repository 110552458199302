
export default function HelpDeskServices() {

	const language = () => {
		if (navigator.language == 'fr' || navigator.language.indexOf('fr-') == 0) return 'fr';
		return 'en';
	};

	const messages = {
		'InPage': {
			'fr': 'Pourriez-vous m\'aider à choisir où insérer ma campagne ?',
			'en': 'Could you help me to setup where to insert my campaign?',
		},
		'wantAnalytics': {
			'fr': 'Je suis intéressé par BEYABLE Analytics.',
			'en': 'I am interested in BEYABLE Analytics.',
		},
		'wantReady': {
			'fr': 'Je suis intéressé par BEYABLE Ready.',
			'en': 'I am interested in BEYABLE Ready.',
		},
		'wantEmerch': {
			'fr': 'Je suis intéressé par BEYABLE E-merchandising.',
			'en': 'I am interested in BEYABLE E-merchandising.',
		},
		'MoreTriggers': {
			'fr': 'Pourriez-vous activer plus de conditions de ciblage sur mon compte ?',
			'en': 'Could you enable more targeting conditions in my account?',
		}
	};
	
	const showNewMessage = (message) => message && window.Intercom('showNewMessage', message);

	const askHelpAbout = (subject) => {
		window.Intercom && showNewMessage(messages[subject][language()]);
	};

	const showArticle = (articleId) => {
		window.Intercom && window.Intercom('showArticle', articleId);
	};

	return {
		askHelpAbout,
		showArticle
	};
}