import React from 'react';
import {iconThumbUp, iconThumbDown, iconProgressDown, iconProgressUp, iconScales } from '../../../../../Components/CustomIcon/CustomIcon.js';
import styles from './Card.module.css';
const CardIcon = ({favorableness, findingType}) => {
	const Icon = () => {
		if(findingType === 'BigUplift') return iconProgressUp;
		if(findingType === 'SmallUplift') return iconProgressUp;
		if(findingType === 'SmallFall') return iconProgressDown;
		if(findingType === 'BigFall') return iconProgressDown;
		if(findingType === 'ProductPositiveSpotlight') return iconThumbUp;
		if(findingType === 'ProductTopList') return iconThumbUp;
		if(findingType === 'ProductNegativeSpotlight') return iconThumbDown;
		if(findingType === 'ProductFlopList') return iconThumbDown;
		if(findingType === 'PositiveBusinessComparison' || findingType === 'NegativeBusinessComparison') return iconScales;
	};
	const getIconClass = () =>  (favorableness !== -1) ? `${styles.icon} ${styles.success}` : `${styles.icon} ${styles.danger}`;

	return <div className={getIconClass()}>{Icon()}</div>;
};

export default CardIcon;