
import {
	OnGoingScheduledStopped,
	ScheduledStoppedPast,
	OnGoingScheduled,
	OnGoingScheduledPast,
	OnGoingPast,
	OnGoingStoppedPast,
	OnGoingStopped,
	OnGoing,
	past,
	Scheduled,
	Stopped,
	All,
	StoppedPast,
	ScheduledPast,
	ScheduledStopped
} from '../../../../Services/Queries/DefinitionFilters';
export default function ChangeFilterStatusMode(statusmode) {
	if (statusmode) {
		if (statusmode.length === 4) {
			// For all selected
			return { status: All, mode: 4 }
		} else if (statusmode.length === 3) {
			/// for 3 status selected
			if (statusmode.includes('onGoing') && statusmode.includes('scheduled') && statusmode.includes('stopped')) {
				return { status: OnGoingScheduledStopped, mode: 3 }
			} else if (statusmode.includes('onGoing') && statusmode.includes('scheduled') && statusmode.includes('past')) {
				return { status: OnGoingScheduledPast, mode: 3 }
			} else if (statusmode.includes('onGoing') && statusmode.includes('stopped') && statusmode.includes('past')) {
				return { status: OnGoingStoppedPast, mode: 3 }
			} else if (statusmode.includes('scheduled') && statusmode.includes('stopped') && statusmode.includes('past')) {
				return { status: ScheduledStoppedPast, mode: 3 }
			}
		} else if (statusmode.length === 2) {
			/// for 2 status selected
			if (statusmode.includes('onGoing') && statusmode.includes('scheduled')) {
				return { status: OnGoingScheduled, mode: 2 }
			} else if (statusmode.includes('onGoing') && statusmode.includes('past')) {
				return { status: OnGoingPast, mode: 2 }
			} else if (statusmode.includes('onGoing') && statusmode.includes('stopped')) {
				return { status: OnGoingStopped, mode: 2 }
			} else if (statusmode.includes('past') && statusmode.includes('stopped')) {
				return { status: StoppedPast, mode: 2 }
			} else if (statusmode.includes('past') && statusmode.includes('scheduled')) {
				return { status: ScheduledPast, mode: 2 }
			} else if (statusmode.includes('stopped') && statusmode.includes('scheduled')) {
				return { status: ScheduledStopped, mode: 2 }
			}
		} else if (statusmode.length === 1) {
			/// for 1 status selected
			if (statusmode.includes('onGoing')) {
				return { status: OnGoing, mode: 1 }
			} else if (statusmode.includes('past')) {
				return { status: past, mode: 1 }
			} else if (statusmode.includes('scheduled')) {
				return { status: Scheduled, mode: 1 }
			} else if (statusmode.includes('stopped')) {
				return { status: Stopped, mode: 1 }
			}
		}
	}

}
