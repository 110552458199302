import { SegmentGroupApiType, SegmentGroupStatus } from '../../../../Services/SegmentGroupServicesTypes';
export type RankingUsed = {
	id:string;
	lastUpdatedUtc:string;
	segmentsType:string;
}
export type SegmentGroupType = {
	id: string;
	name: string;
	devices: string[];
	status: SegmentGroupStatus;
	segmentedRankingUsages:RankingUsed
	publicationDateUtc: (Date | null);
	isUpdating: boolean
}

export function convertSegmentGroupTypeFromApi(apiType: SegmentGroupApiType): SegmentGroupType {
	return {
		id: apiType.id,
		name: apiType.name,
		devices: apiType.devices,
		status: apiType.status,
		publicationDateUtc: apiType.publicationDateUtc,
		isUpdating: false,
		segmentedRankingUsages:apiType.segmentedRankingUsages
	};
}