import { react2angular } from 'react2angular';
import React, { useState } from 'react';
import FormCreateAccount from './FormCreateAccount';
import SystemServices from '../../../Services/SystemServices';
import AdminServices from '../../../Services/AdminServices';
import accountUtil from './createAccountUtil';
import './CreateAccount.css';
export default function CreateAccount(props) {
	const { checkErrors } = accountUtil();

	const $http = props.$http;
	const adminServices = new AdminServices($http);
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);

	const isAnAdmin = props.$rootScope.$$childHead.IsAnAdmin();
	const isAnOrganizationAdmin = props.$rootScope.$$childHead.IsAnOrganizationAdmin();

	const [errors, setErrors] = useState([]);
	const onResetForm = () => {
		window.location.href = '/Administration/AdminHome';
	};
	const createAccountHandler = (account, accountModules, updateAccountAccordingToModules, callback) => {
		account = updateAccountAccordingToModules ? updateAccountAccordingToModules(account) : account;
		const arrayOfErrors = checkErrors(account);
		if (arrayOfErrors.length !== 0) {
			setErrors(arrayOfErrors);
			callback();
		} else {
			const handleSuccess = () => {
				systemServices.showSuccess('The account has been successfully created');
				setTimeout(() => {
					callback();
					onResetForm();
				}, 1500);
			};

			adminServices.CreateAccount(account, success => {
				if (accountModules.length > 0){
					adminServices.UpdateAccountModules(success.Key, accountModules, () => {
						handleSuccess();
					}, (err) => {
						console.log('err', err);
						systemServices.showError('An error occured while creating the account');
						callback();
					});
				} else {
					handleSuccess();
				}
			}, (error, errMsg) => {
				systemServices.showError(errMsg.data.Message);
				callback();
			});
		}
	};

	return (
		<div className="create_account_inner">
			<h1>Create a new account</h1>
			<FormCreateAccount
				onSubmitForm={createAccountHandler}
				errors={errors}
				mainRole={{isAnAdmin, isAnOrganizationAdmin}}
			/>
		</div>
	);
}

angular
	.module('beyableSaasApp.CreateAccount', ['beyableSaasApp.Services'])
	.component('createaccount', react2angular(CreateAccount, [],
		['$rootScope', '$scope', '$http', '$routeParams', '$timeout']));