import React, { useContext, useEffect } from 'react';
import CustomizeSelectPagesType from './CustomizeSelectPagesType';
import './CustomizeContainer.css';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import CustomizeBannersContainer from './CustomizeBannersContainer';
import CustomizeScreenWebSite from './CustomizeScreenWebSite';
import CropModal from './CropModal';

export default function CustomizeContainer({ accountId, getZones, isAdmin, businessOperationServices, systemServices }) {
	const {
		devices,
		pageNameSelected,
		arrayOfTypePage,
		operation,
		isexistant,
		setSelectedZone,
		deviceSelected,
		setDeviceSelected,
		isCustomize
	} = useContext(CreateBusinessOperationContext);

	const handleChangeSelecteDevice = (e) => {
		setDeviceSelected(e);
	};


	return (
		<div>
			{isCustomize || isAdmin ?
				<>
					{
						isAdmin && !isCustomize &&
						<div className="drafMode">
							<h2>Draft Mode</h2>
						</div>
					}

					{!pageNameSelected &&
						<CustomizeSelectPagesType
							getZones={getZones}
							accountId={accountId}
						/>}
					<div>
						{pageNameSelected &&
							<>
								{!isexistant ? <h1>Customize - {arrayOfTypePage.filter(x => x.pageType == pageNameSelected)[0].name}</h1>
									:
									<h1>Customize - {arrayOfTypePage.filter(x => x.pageType == pageNameSelected)[0].name}
										<span> : {operation.operation.name}</span>
									</h1>

								}
								<div className="customizeTitleDesc">Please select zones for your campaign</div>

								<CropModal
									accountId={accountId}
									businessOperationServices={businessOperationServices}
									systemServices={systemServices}></CropModal>

								<div className="customizeContainer">
									<div className="customizePartBanner">
										<CustomizeBannersContainer
											deviceSelected={deviceSelected}
										/>
									</div>
									<div className="customizePartPreview">
										<div className="customizeDevices">
											{devices.includes('mobile') &&
												<a className={deviceSelected === 'Mobile' ? 'customizeDevicesBtn selected' : 'customizeDevicesBtn'}
													onClick={(e) => { handleChangeSelecteDevice('Mobile'); setSelectedZone(); }}>
													<i className="fas fa-sm fa-mobile-alt"></i>
													<span>Mobile</span>
												</a>
											}
											{devices.includes('desktop') &&
												<a className={deviceSelected === 'Desktop' ? 'customizeDevicesBtn selected' : 'customizeDevicesBtn'}
													onClick={(e) => { handleChangeSelecteDevice('Desktop'); setSelectedZone(); }}
												>
													<i className="fas fa-sm fa-desktop"></i>
													<span>Desktop</span>
												</a>
											}
										</div>
										<CustomizeScreenWebSite
											deviceSelected={deviceSelected}
											accountId={accountId}
											businessOperationServices={businessOperationServices}
											systemServices={systemServices}
										/>
									</div>
								</div>
							</>
						}
					</div>
				</> :
				<>
					<div className="nodata"> <p className="title">Please contact BEYABLE support team, Simplify setup is not done yet</p></div>
				</>
			}
		</div>
	);
}
