import React, {useState,useEffect} from 'react';
import CreateTriggerContextProvider from '../../Triggers_React/TriggerContext';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SystemServices from '../../../Services/SystemServices';
import TargetingRulesContainer from '../../Triggers_React/TargetingRulesContainer';

export default function TriggerGroupEditorContainer({ $http, $routeParams, $rootScope, UtilCampaignsServices, triggersChangedCallback,initialTriggers }) {

	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const systemServices = new SystemServices($rootScope);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	//const isAdmin = impersonatingServices.isAnAdmin();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const [triggersToPush, setTriggersToPush] = useState({ isInitialized: false, triggers: [], shouldLoadDefault: false });

	const mode = !$routeParams.id ? 'creating' : 'edition';

	const referenceDataTriggers = UtilCampaignsServices.GetTriggers();
	const referenceDataTriggerOperands = UtilCampaignsServices.GetTriggerOperands();
	const setUpSpecificationsForSaving = function (dataSpecifications) {
		let specificationsForSaving = [];
		for (let f = 0; f < dataSpecifications.length; f++) {
			const trigger = dataSpecifications[f];
			var spec = {};
			spec.name = trigger.Name;
			spec.OperatorName = trigger.OperatorName;
			spec.ValueToCompare = trigger.ValueToCompare;
			spec.Group = trigger.Group;
			if (trigger.Type === '' && !trigger.ValueToCompare) {
				spec.ValueToCompare = trigger.ValueToCompare;
			}
			if (trigger.Type === 'number' && typeof (trigger.ValueToCompare) !== 'number') {
				spec.ValueToCompare = 0;
			}
			if (trigger.categorie !== 'Hidden' && spec.ValueToCompare !== '' && trigger.ValueToCompare !== 'all') {
				// Format textarea content to be send
				if (trigger.Type === 'textarea') {
					spec.ValueToCompare = trigger.ValueToCompare.split('\n').join(';');
				}
				if (trigger.Type === 'list') {
					spec.ValueToCompare = trigger.ValueToCompare.toString();
				}
				if ((trigger.Type === 'multiplelist' || trigger.Type === 'multiplelistsearch') && trigger.Name !== 'TRGCHECKDEVICE') {
					var separator = trigger.Separator ? trigger.Separator : ',';
					spec.ValueToCompare = trigger.ValueToCompare.join(separator);
				}
				if (spec.Name === 'TRGCHECKDEVICE') {
					spec.ValueToCompare = trigger.ValueToCompare.map(x => x === 'desktop' ? 0 : x === 'mobile' ? 2 : 1);
				}
				if (trigger.Type === 'geoloc') {
					for (let t = 0; t < trigger.ValueToCompare.length; t++) {
						const perimeter = trigger.ValueToCompare[t];
						if (perimeter.LocationRange && perimeter.LocationRange !== 0) {
							perimeter.Position = {};
							perimeter.Position.Latitude = perimeter.Latitude;
							perimeter.Position.Longitude = perimeter.Longitude;
							perimeter.Position.City = perimeter.Address;
							perimeter.Distance = perimeter.LocationRange;
						}
					}
				}
				if (trigger.Type === 'schedule') {
					var s = '';
					for (let index = 0; index < (trigger.ValueToCompare || []).length; index++) {
						var inp = document.getElementById('scheduleinput' + index);
						if (inp) {
							s = s + ';' + inp.value;
						}else{
							s = s + ';' + trigger.ValueToCompare[index];
						}
					}
					spec.ValueToCompare = s.substring(1);
				}
				specificationsForSaving.push(spec);
			}
		}
		return specificationsForSaving;
	};
	
	useEffect(() => {
		if (initialTriggers && !triggersToPush.isInitialized) {
			const triggers = setUpSpecificationsForLoading(initialTriggers.triggerGroups);
			setTriggersToPush({ isInitialized: true, triggers: triggers, shouldLoadDefault: false });
		}
	}, [initialTriggers]);



	const setUpSpecificationsForLoading = (campaignSpecifications) => {
		
		const dataTriggerOperands = referenceDataTriggerOperands;
		const dataTriggers = referenceDataTriggers;
		let specificationsForLoading = [];
		const tranformToTrigger = campaignSpecifications.reduce((prev,curr) => {
			const triggerArrCurr = curr.triggers.map(trigger => {
				return {
					Group: {
						Id : curr.groupId,
						Label : '',
					},
					Name : trigger.name,
					OperatorName: trigger.operatorName,
					ValueToCompare: trigger.value
				};
			});
			return prev = [...prev,...triggerArrCurr ];
		},[]);

		for (let c = 0; c < tranformToTrigger.length; c++) {
			let spec = { ...tranformToTrigger[c] };
			if (spec.Name == 'TRGGEOLOC' || spec.Name == 'TRGEXCLUDEDGEOLOC') {
				if (spec.ValueToCompare) {
					var perimeters = spec.ValueToCompare;
					if (Array.isArray(perimeters)) {
						for (let p = 0; p < perimeters.length; p++) {
							const perimeter = perimeters[p];
							//perimeter.Guid = guid();
							perimeter.LocationRange = perimeter.Distance;
							if (perimeter.Position) {
								perimeter.Latitude = perimeter.Position.Latitude;
								perimeter.Longitude = perimeter.Position.Longitude;
								perimeter.Address = perimeter.Position.city;
							} else {
								perimeter.Latitude = 0;
								perimeter.Longitude = 0;
								perimeter.Address = '';
							}
						}
					}
				}
			}

			for (let a = 0; a < dataTriggers.length; a++) {
				const trigger = dataTriggers[a];
				if (trigger.value == spec.Name) {
					if ((trigger.type != '' && spec.ValueToCompare !== '' && spec.ValueToCompare != 'all') || (trigger.type == '' && spec.ValueToCompare == trigger.defaultlabel)) {
						spec.DataTriggerOperands = dataTriggerOperands[spec.Name];
						spec.DefaultLabel = trigger.defaultlabel;
						spec.Label = trigger.label;
						spec.Tooltip = trigger.tooltip;
						spec.Type = trigger.type;
						spec.Categorie = trigger.categorie;
						spec.Separator = trigger.separator;
						spec.NeedConfig = trigger.needconfig;
						spec.Saas = trigger.saas;

						if (spec.Type == 'textarea') {
							spec.ValueToCompare = spec.ValueToCompare.replace(/;/gi, '\n');
						}
						if (spec.Type == 'list') {
							spec.ValueToCompare = spec.ValueToCompare.toString();
						}
						if (spec.Name == 'TRGCHECKDEVICE') {
							const uniqueVal = [...new Set(spec.ValueToCompare)];
							const valueDevices = uniqueVal.map(element => {
								switch (element) {
									case 0:
										return 'desktop';
									case 1:
										return 'tablet';
									case 2:
										return 'mobile';
									default: return element;
								}
							});
							spec.ValueToCompare = valueDevices;
						}
						if ((spec.Type == 'multiplelist' || spec.Type == 'multiplelistsearch' || spec.Type == 'schedule') && spec.Name != 'TRGCHECKDEVICE') {
							var separator = spec.Separator ? spec.Separator : ',';
							if (typeof spec.ValueToCompare === 'string') {
								spec.ValueToCompare = spec.ValueToCompare.split(separator);
							}
						}
						specificationsForLoading.push(spec);
					}
				}
			}
		}
		return specificationsForLoading;
	};

	if (!triggersToPush.isInitialized) return <></>;

	return (
		<CreateTriggerContextProvider
			accountId={accountId}
			$http={$http}
			initialTriggers={triggersToPush.triggers}
			mode={mode}
			canSwitchToNoTracking={false}
			impersonatingServices={impersonatingServices}
			saveChangeOnTrigger={triggersChangedCallback}
			setUpSpecificationsForSaving={setUpSpecificationsForSaving}
			referenceDataTriggerOperands={referenceDataTriggerOperands}
			trackingMode={2}
			handleTrackingModeChange={(e) => { console.log(e); }}
			isModuleEditorMode={false}
			isAbTestMode={false}
			getModuleFormats={(callbackSuccess) => callbackSuccess([])}
			isNativeApp={false}
			$routeParams={$routeParams}
			useModuleOnCreation={false}
		>
			<TargetingRulesContainer
				isABTestingActive={false}
				systemServices={systemServices}
				needWrapper={true}
			/>
		</CreateTriggerContextProvider>
	);
}
