import React, { useState } from 'react'
import ColorPicker from './ColorPicker';
import Btn from '../../Components/Btn';
import InputCustom from '../../Components/InputCustom';
import { v4 as uuidv4 } from 'uuid';
import { Trans, useTranslation } from 'react-i18next';
export default function CreateLabel({
	setrefetch,
	setisLoading,
	onAddLabel,
	systemServices,
	labelService,
	accountId,
	objectType
}) {
	const [t, i18n] = useTranslation('common');
	const [labelEdition, setlabelEdition] = useState({ name: '', color: 'blue', id: uuidv4() });
	const handleChangeName = (e) => {
		e.preventDefault()
		const value = e.target.value;
		setlabelEdition(l => ({ ...l, name: value }))
	}
	const changeColor = (e) => {
		const valueColor = e;
		setlabelEdition(l => ({ ...l, color: valueColor }))
	}
	const CreateLabel = (e) => {
		setisLoading(true);
		e.preventDefault();
		const newValueForLabel = {
			Name: labelEdition.name,
			Color: labelEdition.color,
			objectType: objectType
		}
		labelService.postLabel(accountId, newValueForLabel,
			newData => {
				setisLoading(false);
				setrefetch(true);
				setlabelEdition({ name: '', color: 'blue', id: uuidv4() });
				if (typeof onAddLabel === 'function') {
					onAddLabel(newData);
				}
			}, err => {
				setisLoading(false);
				systemServices.showError(t('labels.errorModif'));
			})
	}
	return (
		<div className="modal_section_s modal_section_grey flex_item_fix popin_labels_new">
			<div className="popin_labels_category">{t('labels.CreateNew')}</div>
			<div className="flex" >
				<div className="flex_item_fix">
					<div>
						<ColorPicker
							isNew={true}
							changeColor={(e) => changeColor(e)}
							label={labelEdition}
							systemServices={systemServices}
							labelService={labelService}
							accountId={accountId}
							setrefetch={(e) => setrefetch(e)}
						/>
					</div>
				</div>
				<div className="flex_item_full" style={{ margin: "0 10px" }}>
					<InputCustom
						type="text"
						name="newLabelName"
						color="white"
						value={labelEdition.name}
						placeholder={t('labels.labelName')}
						onChange={(e) => handleChangeName(e)}
						fullWidth={true}
					/>
				</div>
				<div className="flex_item_fix">
					<Btn
						message={t('labels.Create')}
						onClick={(e) => CreateLabel(e)}
					/>
				</div>
			</div>
		</div>
	)
}
