import React, {} from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import styles from './ABTestDashboard.module.css';
import { ratioToPercent } from '../utils';

export default function ABTestsStatCard({
	number,
	variationName,
	forceSign = false,
	successThreshold = 0,
	alertThreshold = 0
}) {

	const tooltipID = uuidv4();
	//const cartClass = [styles.card];
	const cartClass = [styles.value_wrapper];

	if (number > successThreshold) {
		cartClass.push(styles.value_up);
	} else if (number <= alertThreshold) {
		cartClass.push(styles.value_down);
	}

	const getDisplayValue = (number) => {
		let value = '';
		if (forceSign && number > 0) {
			value += '+';
		}
		value += ratioToPercent(number) + '%';
		return value;
	};

	return (
		<div className={cartClass.join(' ')}>
			<div className={styles.value}>
				{getDisplayValue(number)}
			</div>
			<div className={styles.value_desc} data-tip={variationName} data-for={tooltipID}>
				{variationName}
			</div>
			<ReactTooltip
				id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={600}
			/>
		</div>
	);
}