import React from 'react';
import DownloadStatsEmail from './DonwloadStatsEmails';
import { Trans, useTranslation } from 'react-i18next';
export default function MainInformationsContainer({ data, mainInformation, dateRange }) {
	const [t, i18n] = useTranslation('common');
	const arrayOfLabels = [
		{ key: 'numberOfEmailsClicked', label: 'Number od emails clicked' },
		{ key: 'numberOfEmailsDelivered', label: 'Number od emails delivred' },
		{ key: 'numberOfEmailsOpened', label: 'Number od emails opened' },
		{ key: 'numberOfEmailsSent', label: 'Number od emails sent' },
	];
	return (
		<>
			<div className="reporting_part">
				<div className="flex">
					<div className="reporting_part_title flex_item_full">{t('MainInformationsContainer.totalPeriod')}
					</div>
					<div className="flex_item_fix">
						<DownloadStatsEmail stats={data} mainInformation={mainInformation} dateRange={dateRange} />
					</div>
				</div>
				<div className="kpi_list">
					<div className="kpi_item" >
						<p className="kpi_label" style={{ color: '#4278F0' }}> {t('MainInformationsContainer.numberEmails')}</p>
						<p className="kpi_value" style={{ margin: '20px auto' }} >  {data && data.numberOfEmailsSent}</p>
					</div>
					<div className="kpi_item" >
						<p className="kpi_label" style={{ color: '#FCAF45' }}>
						{t('MainInformationsContainer.delivrered')}
							</p>
						<p className="kpi_value" style={{ margin: '20px auto' }} >  {data && data.numberOfEmailsDelivered}</p>
					</div>
					<div className="kpi_item" >
						<p className="kpi_label" style={{ color: '#E85C8D' }}> 
						{t('MainInformationsContainer.opened')}
						</p>
						<p className="kpi_value" style={{ margin: '20px auto' }} >  {data && data.numberOfEmailsOpened}</p>
					</div>
					<div className="kpi_item" >
						<p className="kpi_label" style={{ color: '#69B472' }}> {t('MainInformationsContainer.clicked')}</p>
						<p className="kpi_value" style={{ margin: '20px auto' }}>  {data && data.numberOfEmailsClicked}</p>
					</div>
				</div>
			</div>

		</>
	);
}
