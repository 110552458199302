import React, { useState, createContext } from 'react'

import SystemServices from '../../../Services/SystemServices';
import AdminServices from '../../../Services/AdminServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices'

export const HistoryContext = createContext()

const HistoryContextProvider = (props) => {
    const $http = props.$http
    const $rootScope = props.$rootScope
    const $routeParams = props.$routeParams

    const adminServices = new AdminServices(props.$http);
    const systemServices = new SystemServices(props.$rootScope, props.$timeout);
    const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
    const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
    const currentAccount = impersonatedAccount || $rootScope && $rootScope.User.Account.Key;

    const [skipIdNumber, setskipIdNumber] = useState(0);
    const [topHistory, settopHistory] = useState(20);

    return (
        <HistoryContext.Provider value={{
            $http,
            $rootScope,
            $routeParams,
            adminServices,
            systemServices,
            currentAccount,
            topHistory,
            skipIdNumber,
            setskipIdNumber,
            settopHistory
        }}>
            {props.children}
        </HistoryContext.Provider>
    )
}


export default HistoryContextProvider