import React, { useState,useEffect } from 'react';
import Dropdown from '../../../Components/Dropdown';
import Btn from '../../../Components/Btn';
import { v4 as uuidv4 } from 'uuid';
import { DateRangePicker, DayPickerSingleDateController } from 'react-dates';
import ReactTooltip from 'react-tooltip';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import InputCustom from '../../../Components/InputCustom';
const MonthSelector = ({ dateSelected,checkTheDayOfWeek, dateOfTheSingleDatePicker,
	handleOnDateChange }) => {
	const idOfTodayDate = uuidv4();
	const idOfPreviousWeek = uuidv4();
	const idOfNextWeek = uuidv4();
	const today = (moment().startOf('day'));
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [focusedInput, setFocusedInput] = useState(null);
	const [dateCopyState, setdateCopyState] = useState('');
	const [dateIsValid, setdateIsValid] = useState(true);

	const handleChangeMonth = (direction, currentDateSelected) => {
		const tmpStartSelected = { ...currentDateSelected };
		if (direction === 'right') {
			checkTheDayOfWeek(tmpStartSelected.startDate, 'after');
		} else {
			checkTheDayOfWeek(tmpStartSelected.startDate, 'before');
		}
	};
	const goBackToTodayDate = () => {
		if (moment(dateSelected.startDate).isSame(today)) {
			return;
		} else {
			checkTheDayOfWeek(today, 'today');
		}
	};
	function handleChangeDate(date) {
		const value = date.target.value;
		setdateCopyState(value);
	}
	function handleValidCustomDate () {
		const newDate = moment(dateCopyState);
		const valueIsValidDate = newDate.isValid();
		if(valueIsValidDate && newDate.year() > 2000){
			handleOnDateChange(newDate);
			setDropdownIsOpen(false);
			setdateIsValid(true);
		}else {
			setdateIsValid(false);
		}
	}
	useEffect(()=>{
		if(dropdownIsOpen){
			setdateCopyState(dateOfTheSingleDatePicker.format('YYYY-MM-DD'));
		}
	},[dropdownIsOpen,dateOfTheSingleDatePicker]);
	return (
		<>
			<ReactTooltip id={idOfPreviousWeek} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
			<ReactTooltip id={idOfNextWeek} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
			<ReactTooltip id={idOfTodayDate} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
			<div className="btn_group_l">
				<div className="btn_switch">
					<Btn
						style="outline"
						size="s"
						color="secondary"
						icon="fas fa-chevron-left"
						className="btn_w_xs"
						dataFor={idOfPreviousWeek}
						dataTip={'Previous week'}
						onClickFunction={() => handleChangeMonth('left', dateSelected)}
					></Btn>
					<Btn
						style="outline"
						size="s"
						color="secondary"
						icon="fas fa-chevron-right"
						className="btn_w_xs"
						dataFor={idOfTodayDate}
						dataTip={'Next week'}
						onClickFunction={() => handleChangeMonth('right', dateSelected)}
					></Btn>
				</div>
				<Dropdown
					isOpen={dropdownIsOpen}
					setIsOpen={(e) => setDropdownIsOpen(true)}
					onHide={(e) => setDropdownIsOpen(false)}
					icon="fas fa-chevron-down"
					button={
						<Btn style="outline" color="secondary" arrow="true" size="s">
							{dateOfTheSingleDatePicker.format('DD MMMM YYYY')}
						</Btn>
					}
				>

					<div className="dropdown_section">
						<div className='flex'>
							<div className='flex_item_full'>
								<InputCustom
									type="date"
									value={dateCopyState}
									onChange={(e) => handleChangeDate(e)}
									onEnter={() => handleValidCustomDate()}
									fullWidth={true}
									hasAnError={dateIsValid ? '' : 'The date is invalid'}
									className='custom_input_no_date_picker'
								/>
							</div>
							<div className='flex_item_fix ml_10'>
								<Btn
									style="outline"
									color="secondary"
									horizontalSize="s"
									message="Ok"
									onClick={() => handleValidCustomDate()}
									className="btn_no_min_width"
								/>
							</div>
						</div>
					</div>
					<DayPickerSingleDateController
						date={dateOfTheSingleDatePicker}
						showInput
						onDateChange={(date) => {
							handleOnDateChange(date);
							setDropdownIsOpen(false);
						}}
						focused={true}
						onFocusChange={({ focused }) => setFocusedInput(focused)}
						numberOfMonths={1}
						isOutsideRange={() => false}
					/>
				</Dropdown>
				<Btn
					style="outline"
					size="s"
					color="secondary"
					dataFor={idOfTodayDate}
					dataTip={`${today.format('DD MMMM YYYY')}`}
					onClickFunction={() => goBackToTodayDate()}
				>Today</Btn>
			</div>
		</>
	);
};
export default MonthSelector;
