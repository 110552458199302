import React, { useState, useEffect } from 'react';
import Insight from '../Insight/Insight';
import { toReadableDuration } from '../../util';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';

import { FORMAT } from '../../config';
import {
	Revenue,
	SessionToSaleRatio,
	TransactionCount,
	TransactionAverageAmount
} from './config';
import OverviewBavWithCamparison from './OverviewBavWithCamparison';
import OverviewBavWithoutCamparison from './SessionBavWithoutComparison';
import { useEcommerceOverviewContext } from '../../context/EcommerceOverviewContext';
export default function OverviewBavContainer({
	setNumberOfSessions,
	setNumberOfSessions2,
	setTotalsPeriode1,
	setTotalsPeriode2,
	navFilter,
	service,
	ComparisonSelected,
	deviceSelected,
	styles }) {

	const [bav, setBav] = useState({});
	const [uniqueVisitors, setUniqueVisitors] = useState({ loading: true,
		isError: false });

	const { selectedTenant
	} = useAnalyticsContext();
	const {
		sourceType,
		dimension,
		dimensionId,
		utmDimensionsSelected
	} = useEcommerceOverviewContext();
	useEffect(() => {
		if (navFilter.fromDate && navFilter.toDate) {
			getBav(navFilter, setBav);
		}
	}, [navFilter,
		selectedTenant,
		ComparisonSelected,
		sourceType,
		dimensionId,
		deviceSelected,
		utmDimensionsSelected
	]);


	function getBav(filters = {
		fromDate: '',
		toDate: '',

	}, fn) {

		fn({
			Revenue: { loading: true, isError: false },
			SessionToSaleRatio: { loading: true, isError: false },
			TransactionCount: { loading: true, isError: false },
			TransactionAverageAmount: { loading: true, isError: false }
		});
		const options = {
			...filters,
			metrics: [
				Revenue,
				SessionToSaleRatio,
				TransactionCount,
				TransactionAverageAmount
			],
			timeGranularity: 'None',
			trafficSource : sourceType,
			dimension : dimension,
			dimensionId :dimensionId,
			device: deviceSelected,
			utmDimensionsSelected : utmDimensionsSelected
		};
		function getMetricsSums() {
			service.getMetricsSums(options, (data) => {
				const rates = data.metrics;
				const Revenuer = rates.find(m => m.metric === Revenue).value;
				const SessionToSaleRatior = rates.find(m => m.metric === SessionToSaleRatio).value * 100;
				const TransactionCountr = rates.find(m => m.metric === TransactionCount).value;
				const TransactionAverageAmountr = rates.find(m => m.metric === TransactionAverageAmount).value;
				
				setTotalsPeriode1({
					Revenue: Revenuer,
					SessionToSaleRatio:SessionToSaleRatior,
					TransactionCount: TransactionCountr,
					TransactionAverageAmount: TransactionAverageAmountr
				});
				const formatData = {
					Revenue: {
						value: Revenuer,
						tendance: null,
						loading: false,
						isError: false,
						sign: '€'
					},
					SessionToSaleRatio: {
						value: SessionToSaleRatior,
						tendance: null,
						loading: false,
						isError: false,
						sign: '%'
					},
					TransactionCount: {
						value: TransactionCountr,
						tendance: null,
						loading: false,
						isError: false,
						sign: null
					},
					TransactionAverageAmount: {
						value: TransactionAverageAmountr,
						tendance: null,
						loading: false,
						isError: false,
						sign: '€'
					}
				};

				fn({ ...formatData });
			}, (error) => {
				console.log('bav', error);
		
				fn({
					Revenue: { loading: false, isError: true },
					SessionToSaleRatio: { loading: false, isError: true },
					TransactionCount: { loading: false, isError: true },
					TransactionAverageAmount: { loading: false, isError: true }
				});
			});
		}
		if (!ComparisonSelected) {
			getMetricsSums();
		}
	}



	return (
		<div className="analytics_insight_group">
			{ComparisonSelected ?
				<OverviewBavWithCamparison
					bav={bav}
					uniqueVisitors={uniqueVisitors}
				/> :
				<OverviewBavWithoutCamparison
					bav={bav}
					uniqueVisitors={uniqueVisitors}
				/>
			}
		</div>
	);
}
