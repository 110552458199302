import HttpServices from './HttpServices';


export default class ChatBotServices {
	constructor($http, $routeParams) {
		this.campaignId = $routeParams ? $routeParams.key : '';
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}
	sendFeedback(accountId,chatHistory,callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}${accountId}/copilot-chat-feedback`, chatHistory, callbackSuccess, callbackError);
	}
	sendRequestStream(accountId,chatHistory,callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}${accountId}/copilot-streaming-chat`, chatHistory, callbackSuccess, callbackError);
	}

}
