import React, { useState, useEffect } from 'react';
import Panel from '../../../../Components/Panel';
import InputCustom from '../../../../Components/InputCustom';
import Btn from '../../../../Components/Btn';
import SpinnerWheel from '../../../../Components/SpinnerWheel';

export default function SettingsPanelContainer({ panelIsOpen, onClosePanel, settingServices, systemServices }) {

	const [isLoading, setIsLoading] = useState(false);
	const [ipAddressesBlackList, setIpAddressesBlackList] = useState('');

	useEffect(() => {
		if (panelIsOpen) {
			setIsLoading(true);
			settingServices.getIpAddressesBlackList(data => {
				setIsLoading(false);
				setIpAddressesBlackList((data || []).join(';'));
			});
		}
	}, [panelIsOpen]);

	const closePanel = () => {
		setIpAddressesBlackList('');
		onClosePanel();
	};

	const saveSettings = () => {
		const addresses = ipAddressesBlackList.split(';').map(x => x.trim());
		settingServices.updateIpAddressesBlackList(addresses, () => {
			closePanel();
			systemServices.showSuccess();
		},
		err => {
			console.error(err);
			systemServices.showError();
		}
		);
		return;
	};

	const panelContent = () => (
		<>
			<div className='modal_header has_border flex'>
				<div className='flex_item_fix'>
					<a className='panel_close panel_close_left' onClick={closePanel}></a>
				</div>
				<div className='flex_item_full'>
					Exclude IP addresses
				</div>
				<div className='flex_item_fix ml_30'>
					<Btn
						onClick={saveSettings}
						message="Save"
						color="primary"
					/>
				</div>
			</div>
			<div className='modal_body modal_body_grey flex flex_v'>
				<div className='flex_item_fix mb_20'>Type here the IP addresses you want to exclude from your traffic usering ; as a separator. You can use * to match many addresses, eg 91.41.*</div>
				<div className='flex_item_full'>
					<InputCustom
						type="textarea"
						value={ipAddressesBlackList}
						onChange={data => setIpAddressesBlackList(data.target.value)}
						fullWidth={true}
						rows={10}
						color="white"
						className='h_full'
					/>
				</div>
			</div>
		</>
	);

	return (
		<Panel
			isOpen={panelIsOpen}
			onClose={(e) => closePanel()}
			noClose={true}
			side="right"
			width={600}>

			{!isLoading &&
				panelContent()
			}
			{isLoading &&
				<SpinnerWheel />
			}
		</Panel>
	);
}
