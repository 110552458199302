import React from 'react';
import { I18nextProvider } from 'react-i18next';
import newInstanceI18next from '../../../Util/traductionUtils';
import PanelRecommendations from '../../Campaigns_React/components/PanelRecommendations';
import { useServerSideEditorContext } from './context/ServerSideEditorContextProvider';
import { EditorStep } from './context/EditorStateTypes';
import Header from './Components/Header';
import FormatToolBar from './Components/FormatToolBar';
import SideBarContainer from './Components/SideBar/SideBarContainer';
import TemplateDisplay from './Components/FormatEditor/TemplateDisplay';
import Information from './Components/InformationStep/Information';

export default function ServerSideEditor() {

	const {
		editorState
	} = useServerSideEditorContext();

	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<div className='page_full flex flex_v'>
				<Header />
				{editorState.sideBarStep !== EditorStep.Four &&
					<>
						<FormatToolBar />
						<div className={'campaign_editor_body campaign_editor_body_grey flex_item_full flex'}>
							<div className='format_editor flex flex_align_stretch'>
								<SideBarContainer />
								<TemplateDisplay />
							</div>
						</div>
					</>
				}
				{editorState.sideBarStep === EditorStep.Four &&
					<>
						<div className={'campaign_editor_body campaign_editor_body_grey flex_item_full'}>
							<Information />
						</div>
					</>
				}
				<PanelRecommendations mode='server_side' />
			</div>
		</I18nextProvider>
	);
}