import React, { useState } from 'react';
import Btn from '../../../../Components/Btn';
import { useSegmentGroupEditorContext } from '../context/SegmentListRankingContainerProvider';
import Confirm from '../../../../Components/Confirm';

export default function CreationNewSegmentation() {

	const {
		noData,
		segmentType,
		getEditURL,
		getManageURL,
		setaddNewExternalAudience,
		trafficSegmentationRuleSetupPublication,
		publishTrafficSegmentationRuleSetup
	} = useSegmentGroupEditorContext();


	const [publicationConfirmIsOpened, setPublicationConfirmIsOpened] = useState<boolean>(false);

	if (noData) {
		return null;
	}

	return (
		<>
			{trafficSegmentationRuleSetupPublication.showPublish &&
				<>
					{publicationConfirmIsOpened &&
						<Confirm
							isOpen={publicationConfirmIsOpened}
							title={'This segmentation setup will take effect on your website'}
							confirmText={'Confirm'}
							confirmColor="confirm"
							confirmCallback={() => publishTrafficSegmentationRuleSetup()}
							onClose={() => setPublicationConfirmIsOpened(false)}
						/>
					}
					<Btn
						disabled={!trafficSegmentationRuleSetupPublication.enablePublish || trafficSegmentationRuleSetupPublication.isPublishing}
						message={trafficSegmentationRuleSetupPublication.isPublishing ? 'Publishing...' : 'Publish to your website'}
						icon="fas fa-play"
						onClick={() => setPublicationConfirmIsOpened(true)}
					/>
				</>
			}
			{segmentType && segmentType === 'Beyable' &&
				<>
					<Btn
						href={getManageURL()}
						target="_blank"
						message="Manage segments"
						style="ghost"
						icon="fas fa-external-link-square-alt"
						iconPosition="after"
					/>
					<Btn
						href={getEditURL()}
						message="New segment"
					/>
				</>
			}
			{segmentType && segmentType !== 'Beyable' &&
				<Btn
					onClick={() => setaddNewExternalAudience(true)}
					message="New association"
				/>
			}
		</>
	);
}
