import React, {useState,useContext,useEffect} from 'react';
import SelectDropdown from '../../../../Components/SelectDropdown';
import { reportingCampaignContext } from '../../Context/ContextReportingCampaign';

type ValueObjective = {
    value: string,
    label : string,
	isExexpect: boolean
};

export default function SelectObjectiveContainer({
	defaultList,
	datainformation,
	defaultListInteractions
}) {

	const {
		setobjectiveSelected,
		setobjectiveSelected2,
		handleSaveObjectiveAndAttribution,
		setInteractionMode,
		setinteractionSelected,
		settargetSelected,
		objectiveSelectedForDropDown,
		setobjectiveSelectedForDropDown
	} = useContext<any>(reportingCampaignContext);

	const [list, setlist] = useState<ValueObjective[]>(defaultList);

	useEffect(()=>{
		setlist(defaultList);
	},[defaultList]);
	
	function handleSearchIndicator(evt) {
		let newSelectedElement = {attributionMode : []};
		if(evt.istarget){
			newSelectedElement = datainformation.allowedObjectiveAndAttributions.find(e => e.objective === 'CustomTarget');
			handleSaveObjectiveAndAttribution('CustomTarget',newSelectedElement.attributionMode[0], evt.value);
			setobjectiveSelected2(newSelectedElement.attributionMode[0]);
			setobjectiveSelected('CustomTarget');
			setobjectiveSelectedForDropDown(evt.value);
			settargetSelected(evt.value);
			setinteractionSelected();
			setInteractionMode(false);
		}
		else{
			setobjectiveSelected(evt.value);
			setobjectiveSelectedForDropDown(evt.value);
			settargetSelected();
			newSelectedElement = datainformation.allowedObjectiveAndAttributions.find(e => e.objective === evt.value);
			if(evt.value === 'CustomInteraction' ||evt.value === 'PostInteraction' ){
				const el = defaultListInteractions[0];
				setobjectiveSelected2();
				if(el){
					setinteractionSelected(el.value);
					handleSaveObjectiveAndAttribution(evt.value,null, el.value);
				}
				setInteractionMode(true);
			}else{
				setInteractionMode(false);
				setinteractionSelected();
				handleSaveObjectiveAndAttribution(evt.value,newSelectedElement.attributionMode[0], null);
				setobjectiveSelected2(newSelectedElement.attributionMode[0]);
			}
		} 
		//load something
	}


	function onAutoSelect(val:string) {
		const newValue = list.find(el => el.value === val);
		handleSearchIndicator(newValue);
	}
	return (
		<SelectDropdown
			label={'Campaign objective'}
			onChange={(v) => onAutoSelect(v)}
			labelPosition='inner'
			color='white'
			value={objectiveSelectedForDropDown}
			optionsList={list}
		/>
	);
}
