import React, { useState } from 'react';
import Dropdown from './Dropdown';
import Listbox from './Listbox';
import ListboxItem from './ListboxItem';
import { useTranslation } from 'react-i18next';

export default function SelectFilter({
	label,
	onChange,
	onReset,
	value,
	optionsList,
	hasResetButton = false,
}) {

	const [t] = useTranslation(['utils']);
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const isActive = (value.length > 0) ? true : false;

	function getFriendlyValue (){
		if(!value || value.length === 0 || !optionsList || optionsList.length == 0){
			return '';
		}
		const selectedValue = optionsList.find((opt) => {
			return opt.value === value[0];
		});
		if (!selectedValue) return '';

		if(value.length > 1){
			return selectedValue.label + ' +' + (value.length -1);
		}
		if(value.length === 1){
			return selectedValue.label;
		}
	}

	const btnClass = ['custom_input', 'clickable', 'w_auto', 'm', 'bg_white'];
	if (isActive) {
		btnClass.push('highlighted');
	}

	const friendlyValue = getFriendlyValue();
	const button = (
		<button className={btnClass.join(' ')} type="button">
			<span className="custom_input_value has_label">
				<span className="custom_input_value_label">{label}</span>
				{friendlyValue ?
					<>{friendlyValue}</>
					:
					<span className="custom_input_placeholder">{t('common.all')}</span>
				}
			</span>
			{hasResetButton && value && value.length > 0 && 
				<span className="custom_input_reset"
					onMouseDown={(e) => {e.stopPropagation();}}
					onClick={(e) => {e.stopPropagation(); onReset();}}>
					<i className="fas fa-times-circle"></i>
				</span>
			}
			<span className="custom_input_arrow"></span>
		</button>
	);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
			minWidth={180}
		>
			<Listbox>
				<ListboxItem
					onClick={() => {onReset(); setDropdownIsOpen(false);}}
					message={<>{t('common.all')} <span className='grey_3 s_13'>({t('common.noFilter')})</span></>}
					selected={!isActive}
					hasSelectedIcon={true}
				/>
				<li className='hr' />
				{optionsList && optionsList.map((opt) => 
					<ListboxItem
						key={opt.value}
						onClick={() => onChange(opt.value)}
						message={opt.label}
						selected={value.includes(opt.value)}
						hasCheckbox={true}
					/>
				)}
			</Listbox>
		</Dropdown>
	);
}

