import React, { useState, useEffect, Fragment } from 'react';
import CSVReader from 'react-csv-reader';
import { CSVLink } from 'react-csv';
import './VariantsUpload.css';
import Map from './VariantsMapper';

export default function VariantsUpload({slide, variant, refreshSlide, updateVariantsCallback}) {
	const [headers, setHeaders] = useState([]);
	const [data, setData] = useState([]);
	const [fullHeaders, setFullHeaders] = useState([]);
	const [fullData, setFullData] = useState([]);
	const [uploadKey, setUploadKey] = useState(' ');

	const handleUpload = (data, fileInfo) => {
		updateVariantsCallback(data
			.filter(v => v.Identifier !== 'DEFAULT' && v.Identifier)
			.map(v => { 
				let mapped = {};
				mapped.Properties = {};
    
				for (let key in v) {
					if (v.hasOwnProperty(key)){
						var value = v[key];
						if (!value)
							continue;
						if (key === 'FriendlyIdentifier') mapped['FriendlyIdentifier'] = value;
						else if (key === 'Identifier') mapped['Identifier'] = value;
						else if (key === 'ActionText') mapped['ActionText'] = value;
						else if (key === 'ActionLink') mapped['ActionLink'] = value;
						else mapped.Properties[key] = value;
					}
				}
				return mapped;
			}), 
		data.filter(v => !v.Identifier));
        
		allowNewUploadFile();
	};

	const parseOptions = {
		header: true,
		dynamicTyping: true,
		skipEmptyLines: true,
	};

	const allowNewUploadFile = () => {
		const randomString = Math.random().toString(12);
		setUploadKey(randomString);
	};
    
	const getData = (specs) => Map(specs, (h, hText) => {
		return { key: h, label: hText ? hText : h };
	});

	const getFullData = (specs) => {
		if (!slide) return getData(specs);

		var properties = {};
		for (let key in slide.Properties) {
			if (slide.Properties.hasOwnProperty(key)){
				var value = slide.Properties[key];
				if (key === 'FormCustom' && !slide.IsForm)
					continue;
				if (key === 'ShelvingRules' && !slide.HasReco)
					continue;
				if (key === 'ProductReferences' && !slide.HasSku)
					continue;
				if (key === 'AllowsCustomTemplateEdition' || key === 'RecoItemTemplate')
					continue;
				properties[key] = value;
			}
		}

		let slideAsSpec = {
			FriendlyIdentifier:'DEFAULT (no variant)',
			Identifier:'DEFAULT',            
			ActionText:slide.ActionText,
			ActionLink:slide.ActionLink,
			Properties:properties
		};

		return getData([slideAsSpec].concat(specs));
	};

	useEffect(() => {
		let specs = variant && variant.VarianteSpecs.length > 0 ? variant.VarianteSpecs : [];
        
		var [headers, data] = getData(specs);
		setHeaders(headers);
		setData(data);

		var [fullHeaders, fullData] = getFullData(specs);
		setFullHeaders(fullHeaders);
		setFullData(fullData);

	}, [variant, refreshSlide]);


	return (
		<Fragment>
			<div className="dropdown">
				<button className="variants_btn has_arrow" data-toggle="dropdown">
                Download CSV
				</button>
				<ul className="dropdown-menu m has-border">
					<li>
						<CSVLink className="dropdown-item" data={fullData} headers={fullHeaders} filename={'BEYABLE_variants_full.csv'}>
							<span className="dropdown-item-title">Download template</span>
							<span className="dropdown-item-desc">Contains all editable properties</span>
						</CSVLink>
					</li>
					<li>
						<CSVLink className="dropdown-item" data={data} headers={headers} filename={'BEYABLE_variants.csv'}>
							<span className="dropdown-item-title">Download data</span>
							<span className="dropdown-item-desc">Contains only current data</span>
						</CSVLink>
					</li>
				</ul>
			</div>
			<Fragment key={uploadKey}>
				<CSVReader
					cssClass="variants_import"
					label="Import CSV"
					onFileLoaded={handleUpload}
					parserOptions={parseOptions}
				/>
			</Fragment>
		</Fragment>
	);

}
