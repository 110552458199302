import React from "react";
import './InstallationCode.css'


export default function InstallationCode({ beyableKey }){
    let can_copy = true;
    const copyToClipBoard = (key) => {
    	if( ! can_copy ) return;

        let TextCodevalue = `<script type="text/javascript">(function (B, e, y, a, b, l, E) { var beyable = document.createElement('script'); beyable.id = a; beyable.type = 'text/javascript'; beyable.async = true; beyable.src = B; beyable.setAttribute('data-apiKey', y); beyable.setAttribute('data-api', e); b && beyable.setAttribute('data-tenants', b); var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(beyable, s); })( 'https://tag.beyable.com/api/beYableJS-analytics.js', 'https://front.lytics.beyable.com', '${key}', 'beyable_analytics');</script>`
        navigator.clipboard.writeText(TextCodevalue).then(function(){
            let btn = document.querySelector('.ob_installation_copy_text')
            let btn_text = btn.innerHTML;
            btn.innerHTML = "COPIED";
            can_copy = false;
    
            setTimeout(function(){
                btn.innerHTML = btn_text;
                can_copy = true;
            }, 2500);
        })
    }

    return (
    <div className="ob_slide">
        <legend className="ob_title_2">Install BEYABLE Analytics</legend>
        <p className="ob_text mb_30">{`Paste this code into the <head> section of every page you want to analyse.`}</p>
        <div className="ob_installation_code s_13">
            {`<script type="text/javascript">`}<br/>
            <span className="ml_15">{`(function (B, e, y, a, b, l, E) {`}<br/></span>
            <span className="ml_30">{`    var beyable = document.createElement('script');`}<br/></span>
            <span className="ml_30">{`    beyable.id = a;`}<br/></span>
            <span className="ml_30">{`    beyable.type = 'text/javascript';`}<br/></span>
            <span className="ml_30">{`    beyable.async = true;`}<br/></span>
            <span className="ml_30">{`    beyable.src = B;`}<br/></span>
            <span className="ml_30">{`    beyable.setAttribute('data-apiKey', y);`}<br/></span>
            <span className="ml_30">{`    beyable.setAttribute('data-api', e);`}<br/></span>
            <span className="ml_30">{`    b && beyable.setAttribute('data-tenants', b);`}<br/></span>
            <span className="ml_30">{`    var s = document.getElementsByTagName('script')[0];`}<br/></span>
            <span className="ml_30">{`    s.parentNode.insertBefore(beyable, s);`}<br/></span>
            <span className="ml_15">{`})(`}<br/></span>
            <span className="ml_30">{`    'https://tag.beyable.com/api/beYableJS-analytics.js', `}<br/></span>
            <span className="ml_30">{`    'https://front.lytics.beyable.com', `}<br/></span>
            <span className="ml_30">{`    '${beyableKey}', `}<br/></span>
            <span className="ml_30">{`    'beyable_analytics');`}<br/></span>
            {`  </script>`}
            <div className="ob_installation_copy fw_medium" onClick={()=>{copyToClipBoard(beyableKey)}}><i className="fas fa-copy"></i><span className="ob_installation_copy_text">COPY</span></div>
        </div>
    </div>
    );
};