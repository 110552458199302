import React, { useEffect, useState, useRef } from 'react';

export default function Portlet({
	label,
	children,
	defaultOpen = false
}) {

	const [isOpen, setIsOpen] = useState(defaultOpen);
	const bodyRef = useRef(null);

	const toggle = () => {
		if (!bodyRef.current) return;

		const delay = isOpen ? 0 : 300;
		const height = bodyRef.current.scrollHeight;
		bodyRef.current.style.height = height + 'px';
		bodyRef.current.style.overflow = 'hidden';

		setTimeout(function () {
			bodyRef.current.style.height = '';
			bodyRef.current.style.overflow = '';
		}, delay);

		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if( defaultOpen && defaultOpen !== isOpen ){
			setIsOpen(defaultOpen);
		}
	}, [defaultOpen]);

	return (
		<div className={isOpen ? 'customizePortlet open' : 'customizePortlet'}>
			<div className="customizePortletHead" onClick={() => toggle()}>
				<span className="customizePortletHeadArrow">
					<i className="fas fa-sm fa-chevron-down"></i>
				</span>
				{label}
			</div>
			<div className="customizePortletBody" ref={bodyRef}>
				{children}
			</div>
		</div>
	);
}
