import React, {useState} from 'react';
import InputCustom from '../../../../Components/InputCustom';
import SectionMessage from '../../../../Components/SectionMessage';
import Btn from '../../../../Components/Btn';
export const UrlSpreadsheet = ({sortingElment,setEditingParams,
	ErrorMessageUrl,
	urlIsInvalid,
	setUrlIsInvalid,
	validUrl
}) => {

	const [isAnError, setIsAnError] = useState({ isError: false, message: '' });

	const [currentValue, setCurrentValue] = useState();
	const updateValue = (value) => {
		setUrlIsInvalid(false);
		if (!value) {
			setIsAnError({ isError: true, message: 'Input cannot be empty' });
		} else if (!value.match(/https:\/\/docs.google.com\/spreadsheets\/[^\s]*/i)) {
			setIsAnError({ isError: true, message: 'Input must be a Google Spreadsheet URL' });
		}
		else {
			setIsAnError({ isError: false, message: '' });
		}
		setEditingParams(params => params.map(x => x.name === sortingElment.name ? x.onUpdateValue(x, value, !!value) : x));
		setCurrentValue(value);
	};
	return (
		<>
			<InputCustom
				onChange={e => {
					const value = e.target?.value;
					e.preventDefault();
					updateValue(value);
				}}
				value={sortingElment.paramValue != null ? sortingElment.paramValue : ''}
				//isAnError={!p.paramValue}
				descriptionBottom={!sortingElment.paramValue ? 'input cannot be empty' : null}
				fullWidth={true}
				isAnError={isAnError.isError ? isAnError.message : null}
				append={
					<Btn
						onClick={() => validUrl(currentValue)}
						size="s"
						message="Test url"
						color="primary"
						disabled={isAnError.isError || !currentValue}
						inlineStyle={{whiteSpace: 'nowrap'}}
					/>
				}
				label="Google Spreadsheet URL"
				placeholder='https://docs.google.com/spreadsheets/d/aBcdEFgHiJKl987654321023SfdGFoFpTeM73ywaH4eU'
			>
			</InputCustom>
			{urlIsInvalid &&
				<SectionMessage
					fontSize='s'
					type={'success'}
					text={'Everything is fine, the spreadsheet is accessible, you can click on the OK button'}
					verticalSize='s'
				/>
			}

			{!urlIsInvalid && ErrorMessageUrl &&
				<SectionMessage
					fontSize='s'
					type={'alert'}
					text={ErrorMessageUrl}
					verticalSize='s'
				/>
			}

			<SectionMessage fontSize='s' verticalSize='s'>
				You must give read permissions on your spreadsheet to this account:<br/>
				<em>beyable-external@beyable-prod-global.iam.gserviceaccount.com</em><br/>
				And test the spreadsheet URL.
			</SectionMessage>
		</>
	);
};


