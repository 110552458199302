import React, { useState } from 'react';
import Dropdown from '../../../../Components/Dropdown';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';
import './Nav.css';

export default function SelectDevice({
	deviceSelected,
	handleChangeDeviceSelected,
	initialValue,
	resetSource
}) {

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const isActive = (deviceSelected.length > 0) ? true : false;
	function getLabel (){
		if(!deviceSelected){
			return;
		}
		if(deviceSelected.length === 0){
			return 'All';
		}
		if(deviceSelected.length > 1){
			return deviceSelected[0] + ' +' + (deviceSelected.length -1);
		}
		if(deviceSelected.length === 1){
			return deviceSelected[0] ;
		}

	}
	const activeLabel =getLabel();
	const button = (
		<div className={deviceSelected && deviceSelected.length >= 1 ? 'analytics_range active' : 'analytics_range'}>
			<div className="flex">
				<div className="flex_item_full">
					<div className="analytics_range_sup">Device Type</div>
					<div className="analytics_range_value">
						{deviceSelected && <>{activeLabel}</>}
					</div>
				</div>
				<div className="flex_item_fix analytics_range_drop_arrow"></div>
			</div>
		</div>
	);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
			minWidth={180}
		>
			<Listbox>
				<ListboxItem
					onClick={() => {resetSource(false); setDropdownIsOpen(false);}}
					message={<>All <span className='grey_3 s_13'>(no filter)</span></>}
					selected={!isActive}
					hasSelectedIcon={true}
				/>
				<li className='hr' />
				{initialValue && initialValue.map((value) => 
					<ListboxItem
						key={value.value}
						onClick={() => handleChangeDeviceSelected(value.value)}
						message={value.value}
						selected={deviceSelected.includes(value.value)}
						hasCheckbox={true}
					/>
				)}
			</Listbox>
		</Dropdown>
	);
}

