import clone from 'rfdc';
import React, { useState, Fragment } from 'react';
import { react2angular } from 'react2angular';
import './VariantsContainer.css';
import VariantsUpload from './VariantsUpload';
import VariantsTable from './VariantsTable';

export default function VariantsContainer(props) 
{ 
	const [variant, setVariant] = useState();
	const [slide, setSlide] = useState();
	const [refreshSlide, setRefreshSlide] = useState(false);
	const [preview, setPreview] = useState();
	const [update, setUpdate] = useState();
	const [restore, setRestore] = useState();
	const [resetSelect, setResetSelect] = useState(false);
	const [isOnPreview, setIsOnPreview] = useState(false);

	props.onLoad(function(slide, variant, previewFunction, updateFunction) {
		setSlide(slide);
		setVariant(variant);
		setPreview({function:previewFunction ? previewFunction : function(){}});
		setUpdate({function:updateFunction ? updateFunction : function(){}});
		setRestore({function:function(){}});
		setRefreshSlide(s => !s);
		setIsOnPreview(false);
	});

	props.onExitPreview(function() {
		if (isOnPreview){
			setResetSelect(c => !c);
			setRefreshSlide(s => !s);
			unSelectVariantCallback();
		}
		setIsOnPreview(false);
	});

	const previewVariant = (variant) => {
		const restoreFunction = initialState => s => {
			s.ActionText = initialState.ActionText;
			s.ActionLink = initialState.ActionLink;
			s.Properties = initialState.Properties;
			s.BaseProperties = initialState.BaseProperties;
		};
		setRestore({function:restoreFunction(clone({circles:true})(slide))});
       
		let previewSlide = clone({circles:true})(slide);
		previewSlide.ActionText = variant.ActionText ? variant.ActionText : slide.ActionText;
		previewSlide.ActionLink = variant.ActionLink ? variant.ActionLink : slide.ActionLink;
        
		for (let key in variant.Properties) {
			if (variant.Properties.hasOwnProperty(key)) {
				var prop = variant.Properties[key];
				previewSlide.Properties[key] = prop;
				previewSlide.BaseProperties[key] = prop; 
			}
		}

		preview.function(previewSlide, true, variant.Properties);
		setIsOnPreview(true);
	};

	const selectVariantCallback = (variant) => {
		exitPreviewVariant();
		previewVariant(variant);
	};

	const exitPreviewVariant = () => {
		if (isOnPreview){
			restore.function(slide);
			setIsOnPreview(false);
		}
	};

	const unSelectVariantCallback = () => {
		exitPreviewVariant();
		preview.function(slide, false, []);
	};

	const updateVariantsCallback = (data, errorData) => {
		if (errorData.length > 0){
			// Display error message
			return;
		}
		setResetSelect(c => !c);
		unSelectVariantCallback();
		update.function(data);
		variant.VarianteSpecs = data;
		setVariant({...variant});
	};

	const updateDataCallback = (data, variant, isSelectedRow) => {
		update.function(data);
		if (isSelectedRow){
			previewVariant(variant);
		}
	};

	if( ! variant ) return '';
	if( ! variant.VarianteSpecs ) return '';

	return (
		<Fragment>
			<div className="variants_toolbar">
				<VariantsUpload 
					slide={slide} 
					refreshSlide={refreshSlide}
					variant={variant}
					updateVariantsCallback={updateVariantsCallback}
				></VariantsUpload>
			</div>
			<div className="clear">
				{variant.VarianteType === 2 &&
                    <div className="variants_message">
                        A Geolocation variant is based on a latitude, longitude, radius (in km).
                    	<br/> The form of variant Identifier column must be: [latitude];[longitude];[radius], eg. 48.8534;2.3488;50 for a radius of 50km around Paris.
                    </div>
				}
                {variant.VarianteType === 5 &&
                    <div className="variants_message">
                        A Nearest Geolocation variant is based on a latitude, longitude.
                    	<br/> The form of variant Identifier column must be: [latitude];[longitude], eg. 48.8534;2.3488 for Paris.
                    </div>
				}
				<div className="variants_body">
					<VariantsTable 
						variant={variant}
						selectVariantCallback={selectVariantCallback}
						unSelectVariantCallback={unSelectVariantCallback}
						updateDataCallback={updateDataCallback}
						resetSelect={resetSelect}
					></VariantsTable>
				</div>
			</div>
		</Fragment>
	);
}


angular
	.module('beyableSaasApp.Campaigns.Create.Controllers',)
	.component('variants', react2angular(VariantsContainer, ['onLoad', 'onExitPreview'], ['$rootScope', '$scope']));