import React, { } from "react";
import { ReportGeneratorContext } from '../Context'
import Btn from "../../../Components/Btn";

export default function LaunchButton({
    onLaunched
}) {
    const {
        handleLaunch,
        isReadyToLaunch,
        notReadyToLaunchInfo
    } = ReportGeneratorContext();


    return (
        <Btn
            onClickFunction={() => handleLaunch(onLaunched)}
            disabled={!isReadyToLaunch}
            message="Send report"
            color="primary"
        />
    )
}


