import React from 'react';
import { react2angular } from 'react2angular';
import CreateSegmentRankingContextProviderType from '../context/SegmentListRankingContainerProvider';

import angular from 'angular';
import RankingSegmentationContainer from './RankingSegmentationContainer';
export default function ListRankingSegmentationBridge(props) {

	return (
		<CreateSegmentRankingContextProviderType
			$http={props.$http}
			$timeout={props.$timeout}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
			$location={props.$location}
		>
			<><RankingSegmentationContainer /></>
		</CreateSegmentRankingContextProviderType>
	);
}
angular
	.module('beyableSaasApp.ListRankingSegmentationBridge', [])
	.component('rankingsegment', react2angular(ListRankingSegmentationBridge, [], ['$http',
		'$rootScope', '$timeout', '$location',
		'$routeParams', 'AuthServices']));