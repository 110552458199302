import getVideoId from 'get-video-id';

interface YouTubeVideo {
	type: string;
	youtubeId: string;
	url: string;
}

type VideoError = {
	type: string;
	key: string;
	message: string;
}

/// Youtube video format
export default function videosUtils() {
	function sendErrorId(): VideoError {
		return {
			type: 'error',
			key: 'url',
			message: 'Please enter a valid youtube URL'

		};
	}
	function getDataYoutubeForIdVideo(youtubeId: string, url: string): Promise<YouTubeVideo | VideoError> {
		return fetch(`https://www.googleapis.com/youtube/v3/videos?id=${youtubeId}&key=${(window as any).BEYABLE_env.YOUTUBE_API_KEY}&part=snippet,contentDetails,statistics`)
			.then(resJson => resJson.json())
			.then(res => {
				if (res.items.length !== 0) {
					let videoData = {};
					videoData = res.items[0].snippet;
					videoData['type'] = 'success';
					videoData['youtubeId'] = youtubeId;
					videoData['url'] = url;
					return videoData as YouTubeVideo;
				} else {
					return sendErrorId();
				}
			}).catch(error => {
				console.error('error e', error);
				const err: VideoError = {
					key: 'fetch',
					message: error,
					type: 'error'
				};
				return err;
			});
	}

	const getId = async (url: string): Promise<YouTubeVideo | VideoError> => {
		const id = getVideoId(url);
		if (id.id === undefined || id.id === null) {
			return sendErrorId();
		} else {
			const res = await getDataYoutubeForIdVideo(id.id, url);
			return res;
		}
	};

	function isCorrectUrlYoutube(elem: string): boolean {
		if (elem.includes('www.youtube.com')) {
			return true;
		} else {
			return false;
		}
	}

	function checkErrors(url: string): VideoError[] {
		const arrayOfErrors: VideoError[] = [];
		if (!isCorrectUrlYoutube(url)) {
			arrayOfErrors.push({
				type: 'error',
				key: 'url',
				message: 'Please enter a valid youtube URL'
			});
		}
		return arrayOfErrors;
	}

	return {
		checkErrors,
		getId,
		isCorrectUrlYoutube,
		sendErrorId
	};
}
