import React from 'react';

import SelectDropdown from '../../../Components/SelectDropdown';
export default function BasedOnSelect({
	list,
	value,
	handleSelect,
}) {
	return (<>
		<SelectDropdown
			optionsList={list}
			value={value}
			onChange={handleSelect}
			size="s"
			color="none"
		/>
	</>
	);
}
