const tplIcon = '<span data-gjs-type="popin_close_icon"></span>';
const tplText = '<span data-gjs-type="popin_close_text"></span>';

export const traitCloseLayout = {
	noLabel: false,
	createInput({ trait }) {
		const that = this;
		const selectOptions = [
			{
				label: 'Text',
				value: 'text',
			},
			{
				label: 'Icon',
				value: 'icon'
			},
			{
				label: 'Text + Icon',
				value: 'text_icon'
			},
			{
				label: 'Icon + Text',
				value: 'icon_text'
			}
		];

		// Create select
		const select = document.createElement('select');
		selectOptions.forEach(opt => {
			const optionsEl = document.createElement('option');
			optionsEl.setAttribute('value', opt.value);
			optionsEl.innerText = opt.label;
			select.appendChild(optionsEl);
		});
		select.addEventListener('change', ev => this.onChange(ev));

		const label = document.createElement('label');
		label.classList.add('custom_input');
		label.classList.add('custom_select');
		label.classList.add('w_auto');
		label.appendChild(select);

		const wrapper = document.createElement('div');
		wrapper.appendChild(label);

		this.wrapper = wrapper;
		this.select = select;
		this.name = trait.id;
		return wrapper;
	},
	onEvent({ component }) {
		const value = this.select.value;
		const el = component.view.$el[0];
		const models = component.attributes.components.models;
		const assoc = {
			'icon': {
				'icon': true,
				'text': false,
			},
			'icon_text': {
				'icon': true,
				'text': true,
			},
			'text_icon': {
				'icon': true,
				'text': true,
			},
			'text': {
				'icon': false,
				'text': true,
			}
		};
		const needs = assoc[value] || assoc.text_icon;
		let currIcon;
		let currIconIndex;
		let currText;

		for (let i = 0; i < models.length; i++) {
			const m = models[i];
			if (m.attributes.type == 'popin_close_icon') {
				currIcon = m;
				currIconIndex = i;
				continue;
			}
			if (m.attributes.type == 'popin_close_text') {
				currText = m;
				continue;
			}
		}

		if (!needs.icon && currIcon) currIcon.remove();
		if (!needs.text && currText) currText.remove();
		if (needs.icon && !currIcon) {
			currIcon = component.append(tplIcon, {at: value == 'text_icon' ? 1 : 0})[0];
		}
		if (needs.text && !currText) {
			currText = component.append(tplText, {at: value == 'icon_text' ? 1 : 0})[0];
		}
		if (needs.icon && needs.text) {
			if (value == 'icon_text' && currIconIndex != 0) {
				currIcon.remove();
				component.append(tplIcon, {at: 0});
			}
			if (value == 'text_icon' && currIconIndex == 0) {
				currIcon.remove();
				component.append(tplIcon, {at: 1});
			}
		}

		component.set(this.name, value);
	},
	onUpdate({ component }) {
		const value = component.get(this.name);
		if (value) {
			this.select.value = value;
		}
	},
};
