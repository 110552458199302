import HttpServices from './HttpServices';

export default class TriggerAccountServices {
	constructor($http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
	}

	getAccountTrigger(accountId, callbackSuccess, callbackError, activationStatus = false, contextIdentifier = 'OnSite') {
		const contextIdentifierId= '&contextIdentifier=' + contextIdentifier;
		this.HttpServices.get(`${this.BO_API_URL}/${accountId}/trigger?activationStatus=${activationStatus}${contextIdentifierId}`, callbackSuccess, callbackError);
	}

	postAccountTrigger(accountId, arrayTriggerId, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}/${accountId}/trigger`, arrayTriggerId, callbackSuccess, callbackError);
	}
}
