import React, { useState } from "react";

export default function UseInput(initialState) {
    const [value, setValue] = useState(initialState);
    const resetValue = (newValue) => {
        setValue(newValue)
    }
    const handleChange = event => {
        event.persist()
        setValue(prevValues => ({
            ...prevValues,
            [event.target.name]: event.target.value
        })
        )
    }
    return { handleChange, value, resetValue }
}