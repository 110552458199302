import React, { useEffect, useState } from 'react';
import Spinner from '../../../Components/Spinner';

const ratioParametersDefault = { width: 1000, height: 1000, locked: false };

export default function CropContainer({ banner, ratioParameters = ratioParametersDefault, closeCropModal, handleImageCropped}) {
	ratioParameters = ratioParameters || ratioParametersDefault;
	
	const [cloudinaryWidget, setCloudinaryWidget] = useState();
	const [idOfBanner, setidOfBanner] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [exportedImage, setExportedImage] = useState();
	const [exportedImageConfirm, setExportedImageConfirm] = useState(false);


	useEffect(() => {
		setCloudinaryWidget(window.cloudinary.mediaEditor({ appendTo: '#cropSize' }));
	}, []);

	useEffect(() => {
		if (!exportedImage || !exportedImageConfirm)
			return;
			
		const exportImage = async (image) => {
			const response = await fetch(image.url);			
			const blob = await response.blob();
			const bytes = blob.size;

			setIsLoading(false);
			handleImageCropped({ ...image, bytes });
			closeCropModal();
		};

		exportImage(exportedImage);
	}, [exportedImageConfirm]);

	useEffect(() => {
		if (!exportedImage)
			return;

		setExportedImageConfirm(true);
	}, [exportedImage]);

	useEffect(() => {
		if (banner) {
			setidOfBanner();
			if (typeof banner.metaData.sourceId === 'string') {
				setidOfBanner(banner.metaData.sourceId);
			} else if (typeof banner.metaData.transformationSourceId === 'string') {
				setidOfBanner(banner.metaData.transformationSourceId);
			}
		}
	}, [banner]);
	

	const widgetStart = (id) => {
		const customRatio = {
			label: 'Selected Zone',
			width: ratioParameters.width,
			height: ratioParameters.height,
		};
		cloudinaryWidget.update({
			publicIds: [id],
			cloudName: 'beyable',
			mode: 'inline',
			image: {
				steps: [
					'resizeAndCrop',
					'export'
				],
				resizeAndCrop: {
					//toggleAspectRatio: false,
					interactiveCrop: true,
					aspectRatioLock: ratioParameters.locked,
					flip: true,
					rotate: true,
					presets: [
						customRatio
					]
				},
				export: {
					quality: [
						'auto',
						'best',
						'good',
						'eco',
					],
					formats: [
						'jpg',
						'png',
						'webp'
					],
					download: false,
					share: false
				}
			},
		});
	};

	useEffect(() => {
		if (cloudinaryWidget && idOfBanner) {
			widgetStart(idOfBanner);
			cloudinaryWidget.show();
			cloudinaryWidget.on('export', function (data) {
				if (!data) {
					return;
				}
				setIsLoading(true);
				const url = data.assets[0].secureUrl;
				var img = new Image();
				img.onload = function () {
					setExportedImage({ data: data, url: url, height: this.height, width: this.width });
				};
				img.src = url;
				cloudinaryWidget.destroy();
			});

		}
	}, [idOfBanner]);

	return (
		<div>
			{isLoading &&
                <Spinner />
			}
		</div>
	);
}
