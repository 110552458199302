import { ServerSideSaaSFormatApiType, UpdateServerSideCampaignApiType } from '../../../../Services/ServerSideCampaignServicesTypes';
import { Template, TemplateProperty } from '../../../WysiwygEditor/Types/BespokeTypes';
import { ServerSideCampaignObject, ServerSideSaaSFormat } from './ServerSideCampaignTypes';
import { CreateServerSideCampaignApiType, TemplatePropertiesValue, RecommendationConfigurationApiType } from '../../../../Services/ServerSideCampaignServicesTypes';

function buildTemplateFrom(saasFormat: ServerSideSaaSFormatApiType): Template {
	return {
		Key: saasFormat.id,
		Description: saasFormat.description,
		ThumbnailUrl: saasFormat.thumbnailUrl,
		Name: saasFormat.name,
		Properties: saasFormat.properties as TemplateProperty[],
		TemplateDescriptorDesktop: { Css: '' },
		TemplateDescriptorMobile: { Css: '' },
		TemplateName: saasFormat.templateName,
		WysiwygDesktop: { Template: saasFormat.wysiwyg.template },
		WysiwygMobile: { Template: saasFormat.wysiwyg.template }
	};
}
export function convertSaaSFormatFromApiType(saasFormat: ServerSideSaaSFormatApiType): ServerSideSaaSFormat {
	return {
		template: buildTemplateFrom(saasFormat)
	};
}

export function convertSaaSFormatsFromApiType(saasFormats: ServerSideSaaSFormatApiType[]): ServerSideSaaSFormat[] {
	return saasFormats.map(convertSaaSFormatFromApiType);
}
export const convertRecommendationCfg = (recoCfg: TemplateProperty[]) => {
	function isJsonString(str: any) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
	const hasReco = recoCfg.find(editor => editor.Editor.Type === "ProductRecommendations");
	if (hasReco) {
		const recoValue = hasReco.Value;
		return isJsonString(recoValue) ? recoValue : JSON.stringify(recoValue);

	} else {
		return null;
	}

}

export function convertServerSideCampaignForSaving(campaign: ServerSideCampaignObject): CreateServerSideCampaignApiType {
	const reco = convertRecommendationCfg(campaign.Properties);
	const elementsReco = reco ? JSON.parse(reco) : null;
	const recommendationConfig: RecommendationConfigurationApiType = {
		tenant: null,
		ruleId: elementsReco ? elementsReco[0].Id : '',
		numberOfProducts: elementsReco ? elementsReco[0].NumberOfProducts : null
	}
	return {
		baseTemplateId: campaign.Id,
		name: campaign.Name,
		recommendationConfiguration: reco ? recommendationConfig : null,
		templateProperties: convertToTemplatePropertyValue(campaign.Properties)
	};
}
function convertToTemplatePropertyValue(arrayOfProperties: TemplateProperty[]) {
	let newListProperties: TemplatePropertiesValue[] = [];
	newListProperties = arrayOfProperties.map((propertie: TemplateProperty) => {
		const newObjectValue: TemplatePropertiesValue = {
			name: propertie.Name,
			value: propertie.Value,
			isGroup: propertie.IsGroup,
			isCollection: propertie.IsCollection
		}
		return newObjectValue;
	});
	return newListProperties;
}

export function convertServerSideCampaignForUpdate(campaign: ServerSideCampaignObject): UpdateServerSideCampaignApiType {
	const reco = convertRecommendationCfg(campaign.Properties);
	const elementsReco = reco ? JSON.parse(reco) : null;
	const recommendationConfig: RecommendationConfigurationApiType = {
		tenant: null,
		ruleId: elementsReco ? elementsReco[0].Id : '',
		numberOfProducts: elementsReco ? elementsReco[0].NumberOfProducts : null
	}
	return {
		status: campaign.Status,
		name: campaign.Name,
		recommendationConfiguration: reco ? recommendationConfig : null,
		templateProperties: convertToTemplatePropertyValue(campaign.Properties)
	};
}

