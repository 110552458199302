import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { propertiesProcessing } from './util';
import { ProductRecommendationsValue, ProductReferencesValue } from '../WysiwygEditor/Utils/TemplateOperations';
import {  withZoneToUtc, appendTimezoneToDate } from '../../Util/TimezoneUtil';
import {htmlTypeVariationAbTestRedirection} from './components/GraphEditor/utils';
import {dataUritoBlob,imageMimeToExtension} from '../WysiwygEditor/Utils/ImageUtils';
import {getFinalIconValue} from '../WysiwygEditor/Utils/IconUtils';

export function campaignImageServices(cloudImageServices) {
	const uploadImageFromDataUri = (dataUri) => {
		const blob = dataUritoBlob(dataUri);
		const ext = imageMimeToExtension(blob.mime);
		const fileName = (Math.random() + 1).toString(36).substring(7) + ext;
		var fd = new FormData();
		fd.append('file', blob.blob, fileName);
		return cloudImageServices.uploadImage(fd);
	};

	return {
		uploadImageFromDataUri
	};
}

export function services(campaignCreatingServices, accountId, userID, campaignImageServices, externalAppServices) {
	const { addPropertyKeys, formatPropertiesToStyleProperties } = propertiesProcessing();

	function saveStyle(templateId, name, properties, successCallback, errorCallback) {
		let newSavedStyleObj = {
			'name': name,
			'properties': properties
		};
		campaignCreatingServices.putNewSavedStyle(accountId, templateId, newSavedStyleObj, successCallback, errorCallback);
	}
	function deleteSavedStyle(templateId, styleId, successCallback, errorCallback) {
		campaignCreatingServices.deleteSavedStyle(accountId, templateId, styleId, successCallback, errorCallback);
	}
	function publishSavedStyle(templateId, styleId, isPublished, successCallback, errorCallback) {
		campaignCreatingServices.publishSavedStyle(accountId, templateId, styleId, isPublished, successCallback, errorCallback);
	}
	function getCampaignRendering(actualCampaign, callbackSuccess, callbackError) {
		let newCampaign = { ...actualCampaign };
		delete newCampaign.Template;
		delete newCampaign.SavedStyle;
		delete newCampaign.Features;
		campaignCreatingServices.getCampaignRendering(accountId, newCampaign,
			data => {
				callbackSuccess(data);
			},
			error => {
				console.error('error', error);
				callbackError(error);
			});
	}

	function launchPreview(actualCampaign, callbackSuccess, callbackError) {
		let newCampaign = { ...actualCampaign };
		delete newCampaign.Template;
		delete newCampaign.SavedStyle;
		delete newCampaign.Features;

		campaignCreatingServices.putCampaignToGetPreviewUrl(accountId, userID, newCampaign,
			data => {
				//const currentDebugLink = data.DebugLinks.find(link => link.Variation.slice(-1) == activeVariationIndex)
				const currentDebugLink = data.DebugLinks.length > 0 && data.DebugLinks[0];
				if (!currentDebugLink) {
					console.error('link is missing');
					callbackError('link is missing');
					return;
				}
				const win = window.open(currentDebugLink.DebugLink, '_blank', 'noopener,noreferrer');
				if (win) {
					win.opener = null;
					win.focus();
				}
				callbackSuccess({ winOpened: !!win, url: currentDebugLink.DebugLink });
			},
			error => {
				console.error('error', error);
				callbackError(error);
			});
	}

	
	const setupPropertiesFromTemplate = (properties, template) => {
		const setupOnePropertyValue = (value, itemInTemplate) => {
			if (itemInTemplate.Editor.Type === 'CountDown') {
				const newValue = new String(value);
				newValue.isCountDown = true;
				return newValue;
			}
			if (itemInTemplate.Editor.Type === 'Font') {
				const newValue = new String(value);
				newValue.isFont = true;
				return newValue;
			}
			if (itemInTemplate.Editor.Type === 'DatePicker') {
				return new String(value);
			}
			if (itemInTemplate.Editor.Type === 'ProductReferences') {
				const newValue = new ProductReferencesValue(value || '');
				return newValue;
			}
			if (itemInTemplate.Editor.Type === 'ProductRecommendations') {
				const newValue = new ProductRecommendationsValue(value || '');
				return newValue;
			}
			return value;
		};
		const getSetupPropertyValue = (prop, propInTemplate) => {
			const applySetupToObject = (obj) => {
				return Object.entries(obj).reduce((p, c) => {
					const objKey = c[0];
					const objValue = c[1];
					const itemInTemplate = propInTemplate.Content.find(c => c.Name === objKey);
					return { ...p, [objKey]: itemInTemplate ? setupOnePropertyValue(objValue, itemInTemplate) : objValue };
				}, {});
			};
			if (prop.IsCollection) {
				return (prop.Value || []).map(applySetupToObject);
			} else if (prop.IsGroup) {
				return applySetupToObject(prop.Value || {});
			} else {
				return setupOnePropertyValue(prop.Value, propInTemplate);
			}
		};
		const setupProperties = properties.map(p => {
			var propInTemplate = template.Properties.find(tp => tp.Name === p.Name);
			return propInTemplate ? { ...p, Value: getSetupPropertyValue(p, propInTemplate) } : p;
		});
		return setupProperties;
	};

	function getCampaignAndFormats(id, accountIpAddresses, successCallback, errorCallback) {
		const handleError = err => { console.log('error', err); errorCallback && errorCallback(err); };
		const setupCampaign = (data, formats) => {
			const mergeIpAddresses = (accountAddresses, campaignAddresses) => {
				let merge = [...accountAddresses];
				for (let i = 0; i < campaignAddresses.length; i++) {
					const campaignAddress = campaignAddresses[i];

					let addresses = merge.filter(x => x.ipAddress === campaignAddress.IpAddress);
					if (addresses && addresses.length > 0) {
						for (let a = 0; a < addresses.length; a++) {
							addresses[a].isDisabled = campaignAddress.IsDisabled;
						}
					} else {
						merge.push({
							ipAddress: campaignAddress.IpAddress,
							label: campaignAddress.Label,
							isDisabled: campaignAddress.IsDisabled
						});
					}
				}
				return merge;
			};

			const ipAddresses = mergeIpAddresses(accountIpAddresses, data.TestModeIpAddresses).map(x => ({ ...x, id: uuidv4() }));

			const withSetupProperties = (slideOrVariation) => {
				const setupProperties = setupPropertiesFromTemplate(slideOrVariation.Properties, slideOrVariation.Template);
				return { ...slideOrVariation, Properties: setupProperties };
			};

			const __wsP = withSetupProperties;

			const getTemplate = (identifier) => formats.find(x => x.Identifier === identifier) || {};
			const getTemplateWithKeys = (template) => ({ ...template, Properties: (template.Properties || []).map(addPropertyKeys) });
			const templateName = data.Slide.TemplateName;
			const initializeCampaign = (campaign) => {
				const variations = campaign.Slide.Variations.map((v, i) => __wsP({
					...v,
					UId: uuidv4(),
					Template: getTemplateWithKeys(getTemplate(v.TemplateName)),
					SavedStyle: 'default',
					NameIdentifier: v.NameOrTag,
					Tag: v.NameOrTag,
				}));
				const slide = __wsP({
					...campaign.Slide,
					UId: uuidv4(),
					TemplateName: templateName,
					Template: getTemplateWithKeys(getTemplate(templateName)),
					SavedStyle: 'default',
					Variations: variations
				});
				return {
					...campaign,
					Slide: slide,
					TestModeIpAddresses: ipAddresses,
					StartDateUtc: data.StartDateUtc,
					StartDateInTimezone: data.StartDateInTimezone,
					EndDateUtc: data.EndDateUtc, 
					EndDateInTimezone: data.EndDateInTimezone,
					HasEndDate: !!data.EndDateUtc
				};
			};

			const putCampaignRigth = (campaign) => {
				// campaign.CampaignType = "CallToAction"
				// campaign.Slide.SlideType = "CallToAction"
				if (campaign.Slide.Answer === null) {
					campaign.Slide.Answer = {
						// ItemType: "CallToAction",
						CallToAction: {
							Text: null,
							Link: null
						},
						PromoCode: null
					};
				}
				if (campaign.Slide.TemplateDescriptorDesktop.EditableCss === null) {
					campaign.Slide.TemplateDescriptorDesktop.EditableCss = '';
				}
				if (campaign.Slide.TemplateDescriptorMobile.EditableCss === null) {
					campaign.Slide.TemplateDescriptorMobile.EditableCss = '';
				}

				return campaign;
			};

			const initializedCampaign = putCampaignRigth(initializeCampaign(data));
			return initializedCampaign;
		};

		campaignCreatingServices.getCampaignInformations(accountId, id,
			campaignData => {
				if (!campaignData.CampaignId) {
					errorCallback && errorCallback('Cannot load campaign');
					return;
				}

				const loadFormatsForCampaign = (success) => {
					const isModule = campaignData.Editor.split('/')[1] === 'module';
					if (isModule) {
						const moduleId = campaignData.Editor.split('/')[2];
						getModuleFormats(moduleId, modules => {
							success(modules.flatMap(x => x.formats));
						}, handleError);
					} else {
						getAvailableFormats({ editor: campaignData.Editor }, success, handleError);
					}
				};

				loadFormatsForCampaign(formats => {
					const result = setupCampaign(campaignData, formats);
					successCallback(result, formats);
				});

			},
			handleError);
	}

	function saveCampaign(campaign, variations, callbackSuccess, callbackValidationError, callbackError) {
		// Faire les vérifs
		const original = variations.find(x => x.IsOriginalVariation);
		let otherVariations = variations.filter(x => !x.IsOriginalVariation);

		const validateBeforeSave = () => {
			const missingTagOrName = otherVariations.filter(x => !(original.VariationType === 'PageTag' ? x.NameOrTag : x.NameIdentifier));
			if (missingTagOrName.length > 0) {
				return original.VariationType === 'PageTag'
					? { message: 'All A/B Test variations must have a Tag. Please open A/B Tests configuration and add the missing value.' }
					: { message: 'There is an problem with A/B Test variations. Please contact your CSM' };
			}
			return null;
		};
		const validation = validateBeforeSave();
		if (validation) {
			return callbackValidationError(validation);
		}

	
		const variationsToSave = otherVariations.map(x => {
			const newX = {
				...x,
				NameOrTag: original.VariationType === 'PageTag' ? x.Tag : x.NameIdentifier
			};
			delete newX.EditorState;
			delete newX.NameIdentifier;
			delete newX.Tag;
			delete newX.Template;
			delete newX.CssToInitialize;
			if(campaign.Editor === 'abtest/redirection'){
				const javascriptBYUrlVariation = (variationItem) => {
					return variationItem.DesignProperties.reduce((acc, cur)=> {
	
						if(cur.hasOwnProperty('Value')){
							const RedirectionUrl = cur.Value.RedirectionUrl;
							return acc = `window.location.href = ${RedirectionUrl}`;
						}
						return acc;
						
					},'');
				};
	
				const jsValue = javascriptBYUrlVariation(x);
			
				newX.TemplateDescriptorDesktop =  {
					EditableCss : '',
					EditableJavascript: jsValue,
					EditableTemplate : {
						Content : htmlTypeVariationAbTestRedirection,
						ContentTemplateType : 'Fluid'
					},
				},
				newX.TemplateDescriptorMobile = {
					EditableCss : '',
					EditableJavascript: jsValue,
					EditableTemplate : {
						Content : htmlTypeVariationAbTestRedirection,
						ContentTemplateType : 'Fluid'
					}
			
				};
				
			
			}
			return newX;
		});
		const toUtc = (date) => {
			if (!date)
				return date;
			const dateWithOtherZone = appendTimezoneToDate(date, campaign.IanaTimezone);
			const utcDate = withZoneToUtc(dateWithOtherZone);
			return utcDate.format();
		};

		const slide = { ...original, Variations: variationsToSave };
		delete slide.EditorState;
		delete slide.NameIdentifier;
		delete slide.Tag;
		delete slide.Template;
		delete slide.CssToInitialize;
		const campaignToSave = {
			...campaign,
			Slide: slide,
			StartDateUtc: toUtc(campaign.StartDateInTimezone),
			EndDateUtc: campaign.HasEndDate ? toUtc(campaign.EndDateInTimezone) : null,
		};
		delete campaignToSave.HasEndDate;
		delete campaignToSave.Features;

		const create = !campaign.CampaignId;

		if (create) {
			campaignCreatingServices.createNewCampaign(accountId, campaignToSave, (success) => {
				callbackSuccess && callbackSuccess(success);
			}, () => {
				console.log('err');
				callbackError && callbackError();
			});
		}
		else {
			campaignCreatingServices.updateExistingCampaign(accountId, campaignToSave, (success) => {
				callbackSuccess && callbackSuccess(success);
			}, () => {
				console.log('err');
				callbackError && callbackError();
			});
		}
	}

	const setupFormats = (formats) => {
		const addFormatsDefaultStyle = (formats) => formats.map(f => ({
			...f,
			Properties: (f.Properties || []).map(addPropertyKeys),
			defaultStyle: { properties: formatPropertiesToStyleProperties(f.Properties || []) }
		}));

		const addSubProperties = (p) => {
			if (p.Editor.TimezoneSelector) {
				return [{
					...p,
					TimezonePropertyName: p.Name + '_TZ'
				}, {
					Editor: { Type: 'Hidden' },
					FriendlyName: '',
					Key: uuidv4(),
					Name: p.Name + '_TZ',
					Value: p.Editor.Timezone
				}];
			}
			if (p.Editor.Type === 'Video'){
				return [{
					...p
				}, {
					Editor: { Type: 'Hidden' },
					FriendlyName: '',
					Key: uuidv4(),
					Name: p.Editor.VideoTypePropertyName,
					Value: p.Editor.VideoType
				}];
			}
			if (p.Editor.Type === 'ProductReferences') {
				return [{...p, Name: 'ProductReferences' }];
			}
			if (p.Editor.Type === 'ProductRecommendations') {
				return [{...p, Name: 'ShelvingRules' }];
			}
			if (p.Editor.Type === 'Icon') {
				p.Value = getFinalIconValue(p);
				return [p];
			}
			return [p];
		};

		const setFormatProperties = (properties) => {
			return properties.flatMap(p => {
				if (p.Editor.Type === 'Data') {
					return [{...p, Content: []}];
				}
				else if (p.Editor.Type === 'Collection' || p.Editor.Type === 'Group') {
					return [{ ...p, Content: p.Content.flatMap(addSubProperties) }];
				} else {
					return addSubProperties(p);
				}
			});
		};

		const formatsWithSetupProperties = formats.map(f => ({ ...f, Properties: setFormatProperties(f.Properties) }));
		const formatsWithDefaultStyle = addFormatsDefaultStyle(formatsWithSetupProperties);

		return formatsWithDefaultStyle;
	};

	function getAvailableFormats({ developmentMode, editor }, callbackSuccess, callbackError) {
		const onSuccess = (formats) => {
			const newFormats = setupFormats(formats);
			callbackSuccess(newFormats);
		};

		const onError = (error) => {
			console.error('error', error);
			callbackError && callbackError(error);
		};

		campaignCreatingServices.getBespokeTemplates(accountId, onSuccess, onError, { developmentMode, editor });
	}

	function getModuleFormats(moduleId, callbackSuccess, callbackError, developmentMode) {
		const onSuccess = (modules) => {
			const result = modules.map(m => ({
				formats: setupFormats(m.BespokeTemplates || []),
				id: m.Identifier,
				triggers: m.Triggers || []
			}));
			callbackSuccess(result);
		};

		const onError = (error) => {
			console.error('error', error);
			callbackError && callbackError(error);
		};

		campaignCreatingServices.getOneModuleTemplates(accountId, moduleId, onSuccess, onError, developmentMode);
	}

	function getFormatSavedStyles(templateId, template, callbackSuccess, callbackError) {
		const onSuccess = (styles) => {
			const stylesWithKey = (styles || []).map(f => ({
				...f,
				properties: setupPropertiesFromTemplate((f.properties || []).map(addPropertyKeys), template)
			}));
			callbackSuccess(stylesWithKey);
		};
		const onError = (error) => {
			console.error('error', error);
			callbackError && callbackError(error);
		};
		campaignCreatingServices.getFormatSavedStyles(accountId, templateId, onSuccess, onError);
	}

	const processPatches = async (patches) => {
		const processUploadImage = async (p) => {
			const image = p.uploadImage.src;
			if (image.startsWith('data:')) {
				const result = await campaignImageServices.uploadImageFromDataUri(image);
				const imageUrl = result.data.banners[0].imagePath;
				const newUploadImage = { ...p.uploadImage, src: imageUrl };
				return { ...p, uploadImage: newUploadImage };
			}
			else return p;
		};
		const processedData = await Promise.all(patches.data.map(async (d) => {
			if (d.modifications.uploadImage) {
				return await processUploadImage(d);
			}
			return d;
		}));

		return { ...patches, data: processedData };
	};

	const getFontData = async () => {
		const response = await axios.get('https://beyableprod.blob.core.windows.net/fonts/_v1/list.json');
		return response.data;
	};

	const getExternalAppSetupInfo = (module, callbackSuccess, callbackError) => {
		if (module ){
			externalAppServices.getExternalAppSetupInfo(module.replace('module/', ''), 
				data => {
					callbackSuccess({...data, hasExternalAppSetup: true});
				}, 
				callbackError
			);
		} else {
			callbackSuccess({
				hasExternalAppSetup: false,
				installationError: false
			});
		}
	};

	return {
		saveStyle,
		deleteSavedStyle,
		publishSavedStyle,
		launchPreview,
		getCampaignRendering,
		getCampaignAndFormats,
		saveCampaign,
		getAvailableFormats,
		getModuleFormats,
		getFormatSavedStyles,
		processPatches,
		getFontData,
		getExternalAppSetupInfo
	};
}