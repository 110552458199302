import React, {useState} from 'react';
import { onSiteContext } from '../context/index';
import Btn from '../../../../Components/Btn';
import Dropdown from '../../../../Components/Dropdown';
import EmptyState from '../../../../Components/EmptyState';
import { multiChoice, singleChoice } from '../context/Constants.js';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import renderJsxTag from '../../../../Translation/translation-utils';
export default function StasterOnSite({}) {
	const {
		$routeParams,
		isAdmin,
		canCreateCampaigns,
		canCreatePalCampaigns,	
		canCreateExternalAppCampaigns,
		canCreateCodePatchCampaigns,
		canCreateABtest,
		canCreatePatchCampaigns,
		canCreateClassicCampaigns,
		canCreateSaaSCampaigns,
		accountIsLoading,
		checkAccountData,
		openInspirations,
		campaignCreationVersion
	} = onSiteContext();
	const [t, i18n] = useTranslation('common');
	const [createDropdownIsOpen, setCreateDropdownIsOpen] = useState(false);

	let title;
	let text;
	let imgUrl;
	if (campaignCreationVersion === singleChoice && canCreateExternalAppCampaigns) {
		// Shopify users
		title = t('staterOnSite.createYourFirstHeader');
		text = <>{t('staterOnSite.useHeaderBar')}</>;
		imgUrl = '/Assets/empty_campaign_shopify.png';
	} else {
		title = <>{t('staterOnSite.optimize')}</>;
		text = <>{renderJsxTag(t('staterOnSite.findOut'))}</>;
		imgUrl = '/Assets/empty_campaign.svg';
	}

	return (
		<>
			{!accountIsLoading && !checkAccountData && (
				<div className='page_full'>
					<EmptyState
						verticalAlign={true}
						textSize='xl'
						title={title}
						text={text}
						imageUrl={imgUrl}
						primaryAction={
							<>
								{campaignCreationVersion === multiChoice && (
									<>
										<Dropdown
											isOpen={createDropdownIsOpen}
											setIsOpen={setCreateDropdownIsOpen}
											onHide={(ev) => setCreateDropdownIsOpen(false)}
											button={
												<Btn
													message={t('staterOnSite.newCampaign')}
													arrow={true}
													size="l"
													fontSize="l"
												/>
											}
										>
											<ul className="listbox">
												{(isAdmin || canCreateSaaSCampaigns) && (
													<li>
														<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor'}>
															<span className="flex">
																<span className="flex_item_fix listbox_item_icon">
																	<span className="icon_32 icon_layout icon_color_turquoise"></span>
																</span>
																<span className="flex_item_full">
																	<span className="listbox_item_title">
																		{t('nav.CustomTemplate')}
																	</span>
																	<span className="listbox_item_desc">{t('nav.useCustomTemplate')}</span>
																</span>
															</span>
														</a>
													</li>
												)}
												<li className="hr"></li>
												{(isAdmin || canCreatePalCampaigns) && (
													<>
														<li>
															<a className="listbox_item xl flex" onClick={(ev) => { setCreateDropdownIsOpen(false); openInspirations(); }}>
																<span className="flex">
																	<span className="flex_item_fix listbox_item_icon">
																		<span className="icon_32 icon_idea icon_color_turquoise"></span>
																	</span>
																	<span className="flex_item_full">
																		<span className="listbox_item_title">{t('nav.inspiration library')}</span>
																		<span className="listbox_item_desc">
																			{t('nav.Browse our library of ready-to-use templates')}
																		</span>
																	</span>
																</span>
															</a>
														</li>
													</>
												)}
												<li className="hr"></li>
												{(isAdmin || canCreatePatchCampaigns) && (
													<li>
														<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&graph` : '/Campaigns/Editor?graph'}>
															<span className="flex">
																<span className="flex_item_fix listbox_item_icon">
																	<span className="icon_32 icon_color icon_color_turquoise"></span>
																</span>
																<span className="flex_item_full">
																	<span className="listbox_item_title">
																		{t('nav.graphicPatch')}
																	</span>
																	<span className="listbox_item_desc">{t('nav.useGraphic')}</span>	
																</span>
															</span>
														</a>
													</li>
												)}
												<li className="hr"></li>
												{(isAdmin || canCreateCodePatchCampaigns) && (
													<li>
														<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&patch` : '/Campaigns/Editor?patch'}>
															<span className="flex">
																<span className="flex_item_fix listbox_item_icon">
																	<span className="icon_32 icon_code icon_color_turquoise"></span>
																</span>
																<span className="flex_item_full">
																	<span className="listbox_item_title">
																		{t('nav.codePatch')}
																	</span>
																	<span className="listbox_item_desc">
																		{t('nav.patchYourWeb')}
																	</span>
																</span>
															</span>
														</a>
													</li>
												)}
												<li className="hr"></li>
												{(isAdmin) && (
												// {(isAdmin || canCreateABtest) && (
													<li>
														<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&abtest` : '/Campaigns/Editor?abtest'}>
															<span className="flex">
																<span className="flex_item_fix listbox_item_icon">
																	<span className="icon_32 icon_abtest icon_color_turquoise"></span>
																</span>
																<span className="flex_item_full">
																	<span className="listbox_item_title">
																		{t('staterOnSite.abTest')}
																	</span>
																	<span className="listbox_item_desc">
																		{t('staterOnSite.makeAbTests')}
																	</span>
																</span>
															</span>
														</a>
													</li>
												)}
												<li className="hr"></li>
												{/* Remove false to show Shopify link for admins or users with multiple permissons */}
												{(canCreateExternalAppCampaigns && false) && (
													<li>
														<Link className="listbox_item xl flex"
															to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&module=shopify001` : '/Campaigns/Editor?module=shopify001'}>
															<span className="flex">
																<span className="flex_item_fix listbox_item_icon">
																	<span className="icon_32 icon_shopify icon_color_turquoise"></span>
																</span>
																<span className="flex_item_full">
																	<span className="listbox_item_title">
																		{t('nav.ShopifyCampaign')}
																	</span>
																</span>
															</span>
														</Link>
													</li>
												)}
												<li className="hr"></li>
												{isAdmin && (
													<li>
														<Link className="listbox_item xl flex"
															to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&appNative` : '/Campaigns/Editor?appNative'}>
															<span className="flex">
																<span className="flex_item_fix listbox_item_icon">
																	<span className="icon_32 icon_mobile_app icon_color_turquoise"></span>
																</span>
																<span className="flex_item_full">
																	<span className="listbox_item_title">
																		{t('nav.inAppCampaign')}
																	</span>
																	<span className="listbox_item_desc">
																		{t('nav.inAppCampaignDescription')}
																	</span>
																</span>
															</span>
														</Link>
													</li>
												)}
												<li className="hr"></li>
												{(isAdmin || canCreateClassicCampaigns) && (
													<li>
														<a className="listbox_item xl" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Create?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Create'}>
															<span className="flex">
																<span className="flex_item_fix listbox_item_icon listbox_item_icon_32">
																	<span className="icon_20 icon_idea icon_color_grey"></span>
																</span>
																<span className="flex_item_full grey_2">
																	{t('nav.classicCampaign')}
																</span>
															</span>
														</a>
													</li>
												)}
											</ul>
										</Dropdown>
									</>
								)}
								{campaignCreationVersion === singleChoice &&
									<>
										{canCreatePalCampaigns && 
											<Btn
												onClick={(ev) => openInspirations()}
												size="l"
												fontSize="l"
												message={t('staterOnSite.newCampaign')}
											/>
										}
										{canCreateExternalAppCampaigns && 
											<Link className="btnUi btn_primary btn_l btn_fs_l"
												to={$routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&module=shopify001` : '/Campaigns/Editor?module=shopify001'}>
												<span className="btn_txt">{t('staterOnSite.newCampaign')}</span>
											</Link>
										}
									</>
								}
							</>
						}
					/>
				</div>
			)}
		</>
	);
}
