import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import FormAndSurveysServices from '../../../Services/FormSurveysServices';
import ShelvingSourceServices from '../../../Services/ShelvingSourceServices';
import CloudImageServices from '../../../Services/CloudImageServices';
import SystemServices from '../../../Services/SystemServices';
import SetupAccountServices from '../../../Services/SetupAccountServices';

type WysiwygEditorContextType = {
	fontData: Font[];
	isAdmin: boolean,
	accountId: string,
	listOfShelvingRules,
	loadAllProductsShelvingRules,
	cloudImageServices,
	systemServices,
	setupAccountServices,
	$rootScope,
	$routeParams,
	$http,
	authServices,
	$timeout,
	loadConfigurationHttpConnector: (feature: string, campaignId: string, successCallback: ((success: any) => void)) => void;
}

const WysiwygEditorContext = createContext<WysiwygEditorContextType | undefined>(undefined);

function useWysiwygEditorContext() {
	const context = useContext(WysiwygEditorContext);
	if (!context) throw Error('useWysiwygEditorContext can only be used inside a WysiwygEditorContextProvider');
	return context;
}

type Font = {
	displayName: string;
	name: string;
	fallback: string;
}


const WysiwygEditorContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;

	const $timeout = props.$timeout;
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);

	const editorContext = props.context;

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const formAndSurveysServices = new FormAndSurveysServices($http, accountId);
	const shelvingSourceServices = new ShelvingSourceServices($http, accountId);
	const systemServices = new SystemServices($rootScope, $timeout);
	const isAdmin = impersonatingServices.isAnAdmin();
	const authServices = props.AuthServices;
	const getAccessToken = authServices && authServices.getAccessToken;
	const cloudImageServices = new CloudImageServices(accountId, $http, getAccessToken);
	const setupAccountServices = new SetupAccountServices(props.$http, props.$routeParams);

	// Fetch beyable fonts
	const [fontData, setFontData] = useState<Font[] | null>(null);
	const getFontData = async () => {
		const response = await axios.get('https://beyableprod.blob.core.windows.net/fonts/_v1/list.json');
		return response.data;
	};
	useEffect(() => {
		const set_fonts = async () => {
			if (fontData == null) {
				const fonts = await getFontData();
				setFontData(fonts);
			}
		};
		set_fonts();
	}, []);

	// HTTP Connector
	const loadConfigurationHttpConnector = (feature: string, campaignId: string, successCallback: (result: any) => void) => {
		const isFormsAndSurveys = feature === 'FormsAndSurveys';
		if (!isFormsAndSurveys) {
			// actuellement le connecteur http n'est branché que sur la config FormsAndSurveys, pour supporter d'autres features, il faut l'implémenter et faire l'appel ici. 
			console.error('Only the connector for FormsAndSurveys is implemented for now.', feature);
		}
		if (!campaignId) return;
		formAndSurveysServices.getConfigurationHttpConnector(campaignId,
			success => {
				successCallback(success);
			},
			error => { console.error(error); }
		);
	};

	// Shelving Rules
	const [listOfShelvingRules, setListOfShelvingRules] = useState([]);
	const loadAllProductsShelvingRules = () => {
		const getProductsShelving = (callbackSuccess) => shelvingSourceServices.getShelvingSources(editorContext, callbackSuccess,
			error => { 
				systemServices.showError('An error occured while retrieving recommendations'); 
				console.log(error);
			}
		);
		getProductsShelving(
			newData => {
				setListOfShelvingRules(newData);
			});
	};


	const context: WysiwygEditorContextType = {
		fontData: fontData ?? [],
		isAdmin,
		accountId,
		listOfShelvingRules,
		cloudImageServices,
		systemServices,
		setupAccountServices,
		$rootScope,
		$routeParams,
		$http,
		authServices,
		$timeout,
		loadAllProductsShelvingRules,
		loadConfigurationHttpConnector
	};

	return (
		<WysiwygEditorContext.Provider
			value={context}
		>
			{props.children}
		</WysiwygEditorContext.Provider>
	);
};

export default WysiwygEditorContextProvider;

export { useWysiwygEditorContext };
