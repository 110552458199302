import React, { useEffect, useState } from 'react'
import Switch from '../../Components/Switch';
import { useAdminContext } from './userAdministrationContext'

export default function EditBeyableStudioAccess({ bindToToolingAuth0Value, handleToggleBindToToolingAuth0Value }) {
	const {

	} = useAdminContext();

	return (
		<div className="form_block">
			<label className="form_block_label">Studio</label>
			<Switch
				onChange={(e) => handleToggleBindToToolingAuth0Value(e.target.checked)}
				forcedChecked={bindToToolingAuth0Value || false}
				size="s"
			>
				User can use BEYABLE Studio
			</Switch>
		</div>
	)
}
