import React, { createContext, useContext, useState, useEffect } from 'react';
import { AttributeOrScoreFiltering, AttributeOrScoreFilterItem, AttributeOrScoreValueType } from '../../EntityTypes/EntityTypes';
import {
	GroupSorting,
	GroupSortingGroupEntity,
	initializeGroupSorting,
	ProductGroupSorting,
	ExistingProductGroupSorting,
	DataType,
	FilterParam,
	GroupSortingGroupType,
	createDefaultValueGroupSortingGroup,
	createSingleValueGroupSortingGroupWithDefaultValuesFrom,
	createGroupSortingGroupFromValue
} from './GroupSortingEntityTypes';


type CreateGroupSortingContext = {
	availableSortingGroups: AttributeOrScoreFiltering[] | undefined;
	onSetGroupSorting: (groupSorting: (ProductGroupSorting | null)) => void
	onRemoveGroupSorting: (groupSorting: ProductGroupSorting) => void;
	data: DataType,
	children: any
}

const GroupSortingContext = createContext<GroupSortingContextType | undefined>(undefined);

const useGroupSortingContext = () => {
	const context = useContext(GroupSortingContext);
	if (!context) throw Error('useGroupSortingContext can only be used inside a GroupSortingContextProvider');
	return context;
};


const CreateGroupSortingContext = ({ availableSortingGroups, data, children, onSetGroupSorting }: CreateGroupSortingContext) => {

	const [modalIsOpened, setModalIsOpened] = useState<boolean>(false);
	const [canSave, setCanSave] = useState<boolean>(false);
	const [selectedAttribute, setSelectedAttribute] = useState<AttributeOrScoreFilterItem | undefined>(undefined);
	const [existingSortingGroup, setExistingSortingGroup] = useState<ExistingProductGroupSorting | undefined>(undefined);

	const [selectedGroupSorting, setSelectedGroupSorting] = useState<GroupSorting>(new GroupSorting([], [], undefined, undefined));

	const findFilter = (filterId: string): AttributeOrScoreFilterItem | undefined => {
		if (!availableSortingGroups) return undefined;
		const empty: AttributeOrScoreFilterItem[] = [];
		return availableSortingGroups.reduce((r, x) => r.concat(x.itemList), empty).find(x => x.id === filterId);
	};

	useEffect(() => {
		if (data.groupSorting == null || availableSortingGroups == null) {
			setSelectedAttribute(undefined);
			setSelectedGroupSorting(new GroupSorting([], [], undefined, undefined));
		} else {
			const itemToFind = data.groupSorting.filterId;
			const filter = findFilter(itemToFind);
			if (!filter) {
				console.error('Cannot find filter with id', data.groupSorting.filterId);
				return;
			}
			setSelectedAttribute(filter);
			setSelectedGroupSorting(initializeGroupSorting(data.groupSorting, filter));
		}
	}, [modalIsOpened]);

	useEffect(() => {
		if (!availableSortingGroups)
			return;

		if (!data.groupSorting) {
			setSelectedAttribute(undefined);
			setSelectedGroupSorting(new GroupSorting([], [], undefined, undefined));
			setExistingSortingGroup(undefined);
			return;
		}
		const filter = findFilter(data.groupSorting.filterId);
		if (!filter) {
			//err
			console.error('Cannot find filter with id', data.groupSorting.filterId);
			setSelectedAttribute(undefined);
			setSelectedGroupSorting(new GroupSorting([], [], undefined, undefined));
			setExistingSortingGroup(undefined);
			return;
		}
		setSelectedAttribute(filter);
		setExistingSortingGroup({ groups: data.groupSorting.groups, filter: filter, filterParams: data.groupSorting.filterParams });
	}, [data, availableSortingGroups]);

	useEffect(() => {
		setSelectedGroupSorting(new GroupSorting([], [], selectedAttribute, undefined));
	}, [selectedAttribute]);

	useEffect(() => {
		setCanSave(selectedAttribute != undefined && selectedGroupSorting.canSave());
	}, [selectedAttribute, selectedGroupSorting]);

	const handleDeleteGroupSorting = () => {
		onSetGroupSorting(null);
	};
	const handleOpenEditGroupSorting = (notNeededArg: ExistingProductGroupSorting) => {
		notNeededArg;
		setModalIsOpened(true);
	};
	const handleOpenAddGroupSorting = () => {
		setModalIsOpened(true);
	};

	const handleSelectAttribute = (item: AttributeOrScoreFilterItem) => {
		setSelectedAttribute(item);
	};

	const handleResetAttribute = () => {
		setSelectedAttribute(undefined);
	};

	const handleSave = () => {
		const [isValid, groupsAndFilters] = selectedGroupSorting.validateForSaving();
		if (!isValid) {
			console.log('groups are not valid');
			setSelectedGroupSorting(groupsAndFilters as GroupSorting);
			return;
		}
		setModalIsOpened(false);
		onSetGroupSorting({ filterId: groupsAndFilters[2], groups: groupsAndFilters[0], filterParams: groupsAndFilters[1] });
	};

	const handleAddGroupToSelectedItem = () => {
		const newGroup: GroupSortingGroupEntity = createSingleValueGroupSortingGroupWithDefaultValuesFrom(selectedGroupSorting);
		setSelectedGroupSorting(value => value.addGroup(newGroup));
	};

	const handleDeleteGroupFromSelectedItem = (group: GroupSortingGroupEntity) => {
		setSelectedGroupSorting(value => value.deleteGroup(group));
	};

	const handleChangeGroupOfSelectedItem = (group: GroupSortingGroupEntity) => {
		setSelectedGroupSorting(value => value.updateGroup(group));
	};

	const handleChangeFilterParam = (filterParam: FilterParam) => {
		setSelectedGroupSorting(x => x.updateFilterParam(filterParam));
	};

	const handleApplyQuickSetup = (values: string[]) => {
		setSelectedGroupSorting(x => {
			const valueType = x.userChoiceValueType ?? AttributeOrScoreValueType.String;
			const groups = values
				.map(x => createGroupSortingGroupFromValue(x, GroupSortingGroupType.SingleValue, valueType))
				.concat(createDefaultValueGroupSortingGroup(valueType));
			return x.updateAllGroups(groups);
		});
	};

	const handleChangeGroupsOrder = (sourceIndex: number, destinationIndex: number) => {
		const items = Array.from(selectedGroupSorting.groupsData);
		const [reorderedItem] = items.splice(sourceIndex, 1);
		items.splice(destinationIndex, 0, reorderedItem);
		setSelectedGroupSorting(v => v.updateAllGroups(items));
	};

	const handleChangeGroupsValueType = (valueType: AttributeOrScoreValueType) => {
		setSelectedGroupSorting(x => x.updateValueType(valueType));
	};

	const context: GroupSortingContextType = {
		modalIsOpened,
		isEdit: existingSortingGroup != undefined,
		canSave,
		selectedAttribute,
		selectedGroupSorting,
		existingSortingGroup,
		availableSortingGroups: (availableSortingGroups || [])
			.filter(x => x.name === 'Attributes').map(x => ({ ...x, itemList: x.itemList.filter(f => f.valueType != AttributeOrScoreValueType.Url) })),
		handleSelectAttribute,
		handleResetAttribute,
		handleChangeFilterParam,
		handleAddGroupToSelectedItem,
		handleDeleteGroupFromSelectedItem,
		handleChangeGroupOfSelectedItem,
		handleChangeGroupsOrder,
		handleChangeGroupsValueType,
		handleOpenEditGroupSorting,
		handleOpenAddGroupSorting,
		handleDeleteGroupSorting,
		handleApplyQuickSetup,
		handleModalCancel: () => setModalIsOpened(false),
		handleSaveGroupSorting: handleSave
	};

	return (
		<GroupSortingContext.Provider
			value={context}
		>
			{children}
		</GroupSortingContext.Provider>
	);
};

type GroupSortingContextType = {
	modalIsOpened: boolean;
	isEdit: boolean;
	canSave: boolean;
	selectedAttribute: AttributeOrScoreFilterItem | undefined;
	selectedGroupSorting: GroupSorting;
	availableSortingGroups: AttributeOrScoreFiltering[];
	existingSortingGroup: ExistingProductGroupSorting | undefined;
	handleSelectAttribute: (item: AttributeOrScoreFilterItem) => void;
	handleResetAttribute: () => void;
	handleAddGroupToSelectedItem: () => void;
	handleDeleteGroupFromSelectedItem: (group: GroupSortingGroupEntity) => void;
	handleChangeGroupOfSelectedItem: (group: GroupSortingGroupEntity) => void;
	handleOpenEditGroupSorting: (group: ExistingProductGroupSorting) => void;
	handleChangeGroupsOrder: (sourceIndex: number, destinationIndex: number) => void;
	handleChangeGroupsValueType: (valueType: AttributeOrScoreValueType) => void;
	handleDeleteGroupSorting: () => void;
	handleOpenAddGroupSorting: () => void;
	handleChangeFilterParam: (filterParam: FilterParam) => void;
	handleModalCancel: () => void;
	handleSaveGroupSorting: () => void;
	handleApplyQuickSetup: (values: string[]) => void;
}

export default CreateGroupSortingContext;

export { useGroupSortingContext };