import React, { createContext, useContext, useEffect, useState } from 'react';
import ProductFeedConfigurationServices from '../../../Services/ProductFeedConfigurationServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import {transformConfigToList,BO_API_URL} from '../utils';
import SystemServices from '../../../Services/SystemServices';


const ProductFeedConfigContext = createContext();

function useProductFeedConfigContext() {
	return useContext(ProductFeedConfigContext);
}

const ProductFeedConfigContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	const AuthServices = props.AuthServices;
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const services = new ProductFeedConfigurationServices($http);
	const systemServices = new SystemServices($rootScope, $timeout);
	const [crudLink, setCrudLink] = useState('');

	const [listTenant, setlistTenant] = useState();
	const [tenantListKey, setTenantListKey] = useState([]);
	const [isLoading, setisLoading] = useState(true);
	const [isEmpty, setisEmpty] = useState(true);
	const [needToRefreshList, setneedToRefreshList] = useState(true);
	const [isEmptyTenantAndNoFeed, setisEmptyTenantAndNoFeed] = useState(false);
	const [newOrderFeed, setnewOrderFeed] = useState();

	useEffect(() => {
		if(needToRefreshList){
			services.call(`${BO_API_URL}feature/${accountId}/productfeedconfig/summary`,'get',null,
				success => {
					setisLoading(false);
					setneedToRefreshList(false);
					const list = transformConfigToList(success?.tenantConfigs);
					const hasFeeds = list.some(t => t.feeds && t.feeds.length > 0);
					setCrudLink(success._links);
					setlistTenant(list);
					const listKeytenant = list.map(t =>
					{
						if(t.key !== ''){
							return {
								value :t.key,
								label : t.key
							};
						}else{
							return {
								value :t.key,
								label : 'Default'
							};
						}
					});
					setTenantListKey(listKeytenant);
					setisEmpty(!hasFeeds);
				},
				(error ,err)=>{
					console.error(error, 'err :' ,err);
					setneedToRefreshList(false);
					if(err.status === 404)
						services.call(`${window.BEYABLE_env.BO_API_URL}feature/${accountId}/productfeedconfig/`,'put', {  'tenantConfigs': {
							'' : {
						  'tenant': ''
							}
					  }},
						sucess =>{
							setneedToRefreshList(true);
						},
						error =>{

						}
						);
					setisLoading(false);
				}
			);
		}

			
	}, [needToRefreshList]);
	function handleDeteFeed(feedId){
		const apiEndPoint = feedId._links.detail.href;
		setisLoading(true);
		services.call(`${apiEndPoint}`,'delete',null,
			success => {
				setTimeout(()=>{
					setisLoading(false);
					setneedToRefreshList(true);
				},1000);
				systemServices.showSuccess('Successfully removed');
			},
			error =>{
				systemServices.showError('An Error occured');
				console.error(error);
				setisLoading(false);
			}
		);
	}
	function handleCopyFeed(feedId,tenantNameToCreate,tenant){
		setisLoading(true);
		const elementLink = listTenant.reduce((acc, curr) => {
			if(curr.key === tenant){
				const feedData = curr.feeds.find(feed => feed.id === feedId.id);			
				acc = feedData._links.detail.href;
			}
			return acc;
		}, '');
		const linkToCreateFeedToTenant = listTenant.reduce((acc, curr) => {
			if(curr.key === tenantNameToCreate){	
				acc = curr.links.addSource.href;
			}
			return acc;
		}, '');
		
		services.call(elementLink,'get',null,
			successCurrFeed => {
				services.call(`${linkToCreateFeedToTenant}`,'post',successCurrFeed,
					success => {
						systemServices.showSuccess('Successfully copied');
						setTimeout(()=>{
							setisLoading(false);
							setneedToRefreshList(true);
						},1000);
					},
					error =>{
						systemServices.showError('An Error occured');
						console.error(error);
						setisLoading(false);
					}
				);
			},
			error =>{
				systemServices.showError();
				console.error(error);
				setisLoading(false);
			}
		);		
	}
	function handlePauseSource(feed,tenant){
		setisLoading(true);
		const elementLink = listTenant.reduce((acc, curr) => {
			if(curr.key === tenant){
				const feedData = curr.feeds.find(fee => fee.id === feed.id);			
				acc = feedData._links.detail.href;
			}
			return acc;
		}, '');
		services.call(elementLink,'get',null,
			successCurrFeed => {
				const sourceFeedToPause = {...successCurrFeed};
				sourceFeedToPause.isActive = false;
				services.call(`${successCurrFeed._links.self.href}`,'put',sourceFeedToPause,
					success => {
						systemServices.showSuccess('The feed was successfully paused');
						setTimeout(()=>{
							setisLoading(false);
							setneedToRefreshList(true);
						},1000);
					},
					error =>{
						systemServices.showError('An Error occured');
						console.error(error);
						setisLoading(false);
					}
				);
			},
			error =>{
				systemServices.showError();
				console.error(error);
				setisLoading(false);
			}
		);		
	}
	function handlePlaySourceFeed(feed,tenant){
		setisLoading(true);
		const elementLink = listTenant.reduce((acc, curr) => {
			if(curr.key === tenant){
				const feedData = curr.feeds.find(fee => fee.id === feed.id);			
				acc = feedData._links.detail.href;
			}
			return acc;
		}, '');
		services.call(elementLink,'get',null,
			successCurrFeed => {
				const sourceFeedToPause = {...successCurrFeed};
				sourceFeedToPause.isActive = true;
				services.call(`${successCurrFeed._links.self.href}`,'put',sourceFeedToPause,
					success => {
						systemServices.showSuccess('Successfully started source');
						setTimeout(()=>{
							setisLoading(false);
							setneedToRefreshList(true);
						},1000);
					},
					error =>{
						systemServices.showError('An Error occured');
						console.error(error);
						setisLoading(false);
					}
				);
			},
			error =>{
				systemServices.showError();
				console.error(error);
				setisLoading(false);
			}
		);		
	}
	function handleCreateOrEraseTenant(tenantName,fnCallBack ){
		let linkToCreateOrEraseTenant =  crudLink.addTenant.href;
		if(!isEmptyTenantAndNoFeed){
			linkToCreateOrEraseTenant = linkToCreateOrEraseTenant.replace('{tenant}',tenantName );
		}
		// isEmptyTenantAndNoFeed 
		// Alors rename le tenant
		if(isEmptyTenantAndNoFeed){
			let elementLink = listTenant.reduce((acc, curr) => {
				if(curr.key === ''){		
					acc = curr.links.rename.href;
				}
				return acc;
			}, '');
			elementLink = elementLink.replace('{newTenant}',tenantName );
			services.call(`${elementLink}`,'post',null,
				success => {
					systemServices.showSuccess('Successfully create tenant');
					fnCallBack();
					setTimeout(()=>{
						setisLoading(false);
						setneedToRefreshList(true);
					},1000);
				},
				error =>{
					systemServices.showError('An Error occured');
					console.error(error);
					setisLoading(false);
				}
			);
		}else{
			const objTenant = {
				tenant: tenantName,
			};
			services.call(`${linkToCreateOrEraseTenant}`,'post',objTenant,
				success => {
					systemServices.showSuccess('Successfully create tenant');
					fnCallBack();
					setTimeout(()=>{
						setisLoading(false);
						setneedToRefreshList(true);
					},1000);
				},
				error =>{
					systemServices.showError('An Error occured');
					console.error(error);
					setisLoading(false);
				}
			);
		}

		
	
	}
	function handleDeleteTenant(tenantName,fnCallBack ){
		const elementLink = listTenant.reduce((acc, curr) => {
			if(curr.key === tenantName){		
				acc = curr.links.detail.href;
			}
			return acc;
		}, '');
		services.call(`${elementLink}`,'delete',null,
			success => {
				systemServices.showSuccess('Successfully delete tenant');
				fnCallBack();
				setTimeout(()=>{
					setisLoading(false);
					setneedToRefreshList(true);
				},1000);
			},
			error =>{
				systemServices.showError('An Error occured');
				console.error(error);
				setisLoading(false);
			}
		);
	}
	function handlePutTenantName(tenant, tenantName,fnCallBack){
		let elementLink = listTenant.reduce((acc, curr) => {
			if(curr.key === tenant){		
				acc = curr.links.rename.href;
			}
			return acc;
		}, '');
		elementLink = elementLink.replace('{newTenant}',tenantName );
		services.call(`${elementLink}`,'post',null,
			success => {
				systemServices.showSuccess('Successfully rename tenant');
				fnCallBack();
				setTimeout(()=>{
					setisLoading(false);
					setneedToRefreshList(true);
				},1000);
			},
			error =>{
				systemServices.showError('An Error occured');
				console.error(error);
				setisLoading(false);
			}
		);
	}
	function reorderedTenant(tenant,tenantName, currentTenant,id, keyLine){
		const copyTennantObj = JSON.parse(JSON.stringify([...listTenant]));
		const arrayOfTenants = listTenant.map(x => x.key);
		const key = arrayOfTenants.indexOf(tenantName); 
	    function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
   	const newTenant = replaceAt(copyTennantObj, key, tenant);
		setlistTenant(newTenant);
		setnewOrderFeed({
			tenant: currentTenant,
			nameOfTenant : tenantName,
			newId: keyLine,
			id: id
		});
	}
	useEffect(()=>{
		if(newOrderFeed){
			let hrefOrder = newOrderFeed.tenant.links.changeSourcePosition.href;
			hrefOrder = hrefOrder.replace('{sourceId}',newOrderFeed.id );
			hrefOrder = hrefOrder.replace('{newPosition}',newOrderFeed.newId );
			services.call(`${hrefOrder}`,'post',null,
				success => {
					systemServices.showSuccess('Successfully sorted source');
				},
				error =>{
					systemServices.showError('An Error occured');
					console.error(error);
					setisLoading(false);
				}
			);
		}

	},[newOrderFeed]);
	

	return (
		<ProductFeedConfigContext.Provider
			value={{
				// config mode
				props,
				accountId,
				services,
				systemServices,
				crudLink,
				setCrudLink,
				listTenant,
				isLoading,
				isEmpty,
				handleDeteFeed,
				handleCopyFeed,
				tenantListKey,
				handlePauseSource,
				handlePlaySourceFeed,
				setisEmptyTenantAndNoFeed,
				isEmptyTenantAndNoFeed,
				handleCreateOrEraseTenant,
				handleDeleteTenant,
				handlePutTenantName,
				reorderedTenant
			}}
		>
			{props.children}
		</ProductFeedConfigContext.Provider>
	);
};

export default ProductFeedConfigContextProvider;

export { useProductFeedConfigContext };
