import React from 'react';
import styles from './Metric.module.css';

const Metric = ({ label, value, percentage, isFavorable, type }) => {
	const formatNumber = (num:number) => {
		const numberFixed = num.toFixed(2);
		return new Intl.NumberFormat('fr-FR').format(+numberFixed);
	};
	const formattedPercentage = () =>  getSign(percentage * 100) + formatNumber(percentage * 100) + ' %';
	const getSign = (num: number) => num > 0 ? '+' : '';
	const getPercentageClass = () =>  (isFavorable !== -1) ? `${styles.percentage} ${styles.success}` : `${styles.percentage} ${styles.danger}`;

	const formattedValue = () => {
		return type === 'Percentage' ?
			formatNumber(value * 100) + ' %' : type === 'Amount' ?
				formatNumber(value) + ' €' : formatNumber(value);
	};

	return (
		<div className={styles.container}>
			<div className={styles.title}>{label}</div>
			<div className="flex flex_justify_start">
				<div className={styles.value}>{formattedValue()}</div>
				{percentage && (
					<div className={getPercentageClass()}>
						{formattedPercentage()}
					</div>
				)}
			</div>
		</div>
	);
};

export default Metric;
