import React, { useState, useEffect } from 'react';
import {datesOptions, compareDatesOptions} from './config';
import styles from './Nav.module.css';
import { DateRangePicker, isInclusivelyBeforeDay, DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import Dropdown from '../../../../Components/Dropdown';
import AddComparisonPeriod from './AddComparisonPeriod';
import Btn from '../../../../Components/Btn';
import SectionMessage from '../../../../Components/SectionMessage';

const NavComparison = ({
	handleClick,
	initialDates,
	initialLabel = 'initialLabel',
	navLabel = 'Period',
	showComparisonToggler = false,
	showComparisonRemover = false,
	setComparisonIsEnable,
	comparisonSelected,
	setisCustomSelected,
	isCustomSelected,
	customDateRange,
	setCustomDateRange,
}) => {

	const [current, setCurrent] = useState(null);
	const labels =  Object.keys(datesOptions);
	const [focusedInput, setFocusedInput] = useState('startDate');
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [minimumNights, setminimumNights] = useState(null);
	const handleSaveCustomDate = (start, end) => {
		setCustomDateRange({
			startDate: start,
			endDate: end
		});
	};
	const handleOnDateChange = ({ startDate, endDate }) => {
		const oldStartDate = moment(customDateRange.startDate);
		const oldEndDate = moment(customDateRange.endDate);
		if (startDate !== null && minimumNights) {
			const dayDiff = oldEndDate.diff(startDate.clone().startOf('day'), 'days');
			const newEndDate = moment(startDate).add(minimumNights, 'day');
			if (isInclusivelyBeforeDay(newEndDate, moment())) {
				handleSaveCustomDate(startDate,newEndDate);
			} else {
				const datDiffBetweenTodayAndNewEndDate = newEndDate.diff(moment().startOf('day'), 'days');
				const newEndDateWithoutDays = moment(newEndDate).subtract(datDiffBetweenTodayAndNewEndDate, 'day');
				handleSaveCustomDate(startDate,newEndDateWithoutDays);
			}
		}else{
			handleSaveCustomDate(startDate,startDate);
		}
	};
	useEffect(() => {

		const initialFrom = moment(initialDates.initialFrom).startOf('day');
		const initialTo = moment(initialDates.initialTo).startOf('day');
		let diffBetweenToDateToCompare = initialTo.diff(initialFrom, 'days');
		setminimumNights(diffBetweenToDateToCompare);
		const timer = setTimeout(() => {
			handleClick(
				{
					fromDate: moment(initialDates.initialFrom).subtract(diffBetweenToDateToCompare, 'days'),
					toDate: moment(initialFrom)
				});
			setCustomDateRange({
				startDate: moment(initialDates.initialFrom).subtract(diffBetweenToDateToCompare, 'days'),
				endDate: moment(initialFrom)
			});
		}, 1000);
		return () => {
			clearTimeout(timer);
		};
		

	}, [initialDates.initialLabel,
		initialDates.initialFrom,
		initialDates.initialTo]);

	const handleValidDate = () => {
		setDropdownIsOpen(false);
		handleClick(
			{
				fromDate: customDateRange.startDate,
				toDate: customDateRange.endDate
			});
	};
	const onFocused = (focused) => {
		if (focused === null) {
			setDropdownIsOpen(false);
		}
	};
	const onHandleClick = (label) => {
		if (label === '') return;
		setCurrent({ ...datesOptions[label], label: label });
		if (label !== 'Custom') {
			setDropdownIsOpen(false);
			handleClick(datesOptions[label]);
		}
	};
	const DatePicker =
		<>
			<div className='section'>
				<SectionMessage title='Select the start date of the comparison range.' noMargin={true}>
					The duration is automatically set to {minimumNights} days.
				</SectionMessage>
			</div>
			<DayPickerRangeController
				startDate={customDateRange.startDate}
				endDate={customDateRange.endDate}
				// minimumNights={0}
				isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
				focusedInput={focusedInput}
				onFocusChange={(focused) => { onFocused(focused); }}
				onDatesChange={(dates) => { handleOnDateChange(dates); }}
				numberOfMonths={3}
				daySize={32}
				firstDayOfWeek={1}
			/>
			<div className='section has_top_border'>
				<Btn
					onClickFunction={() => handleValidDate()}
					message="Confirm"
					color="primary"
				/>
			</div>
		</>;


	const handlePreventToggleDropdown = (e) => {
		e.stopPropagation();
	};

	const handleRemoveComparison = (e) => {
		e.stopPropagation();
    	e.nativeEvent.stopImmediatePropagation();

    	if (typeof setComparisonIsEnable === 'function') {
    		setComparisonIsEnable(false);
    	}
	};

	const button = (
		<div className="analytics_range">
			<div className="flex">
				<div className="flex_item_full">
					<div className="analytics_range_sup">{navLabel}</div>
					<div className="analytics_range_value">
						<>
							{moment(customDateRange.startDate).format('MMM DD, YYYY')}
							<i className="analytics_range_arrow fas fa-arrow-right"></i>
							{moment(customDateRange.endDate).format('MMM DD, YYYY')}
						</>
					</div>
				</div>
				<div className="flex_item_fix analytics_range_cal">
					<i className="fas fa-calendar-alt"></i>
				</div>
				<div className="flex_item_fix analytics_range_remove"
					onMouseDown={(e) => handlePreventToggleDropdown(e)}
					onClick={(e) => handleRemoveComparison(e)}>
					<i className="fas fa-times"></i>
				</div>
			</div>
		</div>
	);


	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={(e) => setDropdownIsOpen(true)}
			onHide={(e) => setDropdownIsOpen(false)}
			button={button}
			flip={false}
		>
			{DatePicker}
		</Dropdown>
	);
};
NavComparison.displayName = 'Nav';
export default NavComparison;