import React, {useState, useEffect} from 'react';
import Switch from '../../../Components/Switch';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import SystemServices from '../../../Services/SystemServices';
type Tenant = {
	dataStartDateUtc : string;
	tenant: string;
}
type ConfigObject = {
	CreationDateUtc : string;
	DoNotTrackEnabled: boolean;
	dataStartDateUtc: string;
	hasData: boolean;
	hasNoPermissionOnAnyTenant: boolean;
	tenants : Array<Tenant>;
	customDimensionConfigs: Array<string>;
}

export default function AnalyticsConfig(props) {
	const beyableKey = props.$rootScope.User.Account.ClientKey;
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isPending, setIsPending] = useState<boolean>(false);
	const [isSelected, setIsSelected] = useState<boolean>(false);
	const [objectConfiguration, setobjectConfiguration] = useState<ConfigObject>();
	function changeAccountAnalyticsConfig(val){
		setIsPending(true);
		const obj = {
			...objectConfiguration,
			apiKey: beyableKey,
			DoNotTrackEnabled : val
		};
		props.webAnalyticsServices.changeAccountConfig(obj,
			_ => {
				setIsPending(false);
				systemServices.showSuccess('The config has been saved with success');
			},
			_ => {
				setIsPending(false);
				systemServices.showError('An error occured');
			});
	}
	const loadAccountUsers = () => {
		setIsLoading(true);
		props.webAnalyticsServices.getAccountConfig(data => {
			setobjectConfiguration(data);
			const getIfIsSelected = data.DoNotTrackEnabled;
			setIsSelected(getIfIsSelected);
			setIsLoading(false);
		},
		_ => {
			setIsLoading(false);
			return {};
		}
		);
	};

	const handleSelectOrUnSelectAccount =(event) => {
		const value = event.target.checked;
		setIsSelected(value);
		changeAccountAnalyticsConfig(value);
	};
	useEffect(()=>{
		loadAccountUsers();
	},[]);

	return (
		<section className='mt_30'>
			{(isLoading || isPending) && 
				<SpinnerWheel wheelSize="l" />
			}
			<Switch
				name="role"
				forcedChecked={isSelected}
				value={isSelected}
				onChange={(e)=> handleSelectOrUnSelectAccount(e)}
				size='s'
				align='middle'
				label='Respect the "do not track" header'
			/>
		</section>
	);
}
