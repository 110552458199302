import React, { useState, useEffect, useRef } from 'react';
import Switch from '../../../Components/Switch';
import {getLocalisedValue} from '../Utils/LocalisationUtils';
import IconBtn from '../../../Components/IconBtn';

export default function Folder({
	children,
	property,
	handleIsActiveChanged,
	handleIsFoldedChanged,
	isActive
}) {

	const getCssClass = () => {
		const cssClass = ['format_param_group'];
		if (isFoldable) {
			cssClass.push('clickable');
		}
		if (isFolded) {
			cssClass.push('folded');
		}
		return cssClass;
	};
	const listRef = useRef(null);
	const isFoldable = true;
	const [isFolded, setIsFolded] = useState(property.Editor.IsOpen ? false : true);
	const [cssClass, setCssClass] = useState(getCssClass());

	const toggleActiveProperty = (e) => {
		const checked = e.target.checked;
		if (checked === isFolded) {
			toggle();
		}
		handleIsActiveChanged(checked);
	};

	const upldateIsFolded = (value) => {
		setIsFolded(value);
		handleIsFoldedChanged(property, value);
	};

	const toggle = () => {
		if (!isFoldable) return;
		if (!listRef.current) return;

		const delay = isFolded ? 300 : 0;
		const height = listRef.current.scrollHeight;
		listRef.current.style.height = height + 'px';
		listRef.current.style.overflow = 'hidden';

		setTimeout(function () {
			listRef.current.style.height = '';
			listRef.current.style.overflow = '';
		}, delay);

		upldateIsFolded(!isFolded);
	};

	useEffect(() => {
		setCssClass(getCssClass());
	}, [isFoldable, isFolded]);


	const FriendlyName = getLocalisedValue(property.FriendlyName);
	const Tooltip = getLocalisedValue(property.Tooltip);
	
	return (
		<div className={cssClass.join(' ')}>
			<div className="format_param_group_name flex" onClick={() => toggle()}>
				<span className="flex_item_full">
					{FriendlyName}
					{Tooltip &&
						<span className='format_param_group_name_tooltip'>
							<IconBtn
								tooltip={Tooltip}
								icon="fas fa-info-circle"
								className="v_al_bl"
								theme="dark"
							/>
						</span>
					}
				</span>
				{property.IsActiveProperty &&
					<span className="flex_item_fix ml_10" onClick={(e) => e.stopPropagation()}>
						<Switch
							checked={isActive}
							onChange={(e) => toggleActiveProperty(e)}
							size="s"
						/>
					</span>
				}
				{isFoldable &&
					<span className="format_param_group_arrow format_param_group_fold_arrow">
						<i className="fas fa-chevron-up"></i>
					</span>
				}
			</div>
			<div className="format_param_list" ref={listRef}>
				{!isActive &&
					<span className="mask"></span>
				}
				{children}
			</div>
		</div>
	);
}