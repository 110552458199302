import React, { useState, useEffect, useRef } from 'react'

import InputCustom from '../../../../Components/InputCustom'

export default function ReplacementList({
	addReplaceManualy,
	valueOfSelection = {
		toReplaceId: '',
		replaceId: ''
	},
	isLocked = false
}) {

	const [valueToModify, setValueToModify] = useState(valueOfSelection);

	const handleChange = (event) => {
		let textAreaValue = { ...valueToModify }
		textAreaValue[event.target.name] = event.target.value;
		setValueToModify(textAreaValue)
		addReplaceManualy(textAreaValue)
	}

	return (
		<div className="" style={{ width: '100%' }}>
			<InputCustom
				type="text"
				label='First product id'
				placeholder="Example: A0009"
				value={valueToModify.toReplaceId}
				name='toReplaceId'
				onChange={(e) => handleChange(e)}
				fullSize={true}
				width='Full'
				disabled={isLocked}
			/>
			<InputCustom
				type="text"
				label='Second product id'
				placeholder="Example: Z0005"
				value={valueToModify.replaceId}
				name='replaceId'
				onChange={(e) => handleChange(e)}
				fullSize={true}
				width='Full'
				autoFocus={true}
			/>
		</div>
	)
}
