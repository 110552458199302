import React, { useState, useEffect, Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import formatMoment from '../../../Constants/FormatsMoments';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export default function ChartRemarketingContainer({ statistics, isOnCharg }) {
	const { formatDateToLocal } = formatMoment;
	const [numberOfEmailsCliked, setnumberOfEmailsCliked] = useState([]);
	const [numberOfEmailsDelivered, setnumberOfEmailsDelivered] = useState([]);
	const [numberOfEmailsOpened, setnumberOfEmailsOpened] = useState([]);
	const [numberOfEmailsSent, setnumberOfEmailsSent] = useState([]);
	const [titleChart, setTitleChart] = useState('');
	const [dayPeriode, setdayPeriode] = useState([]);
	let valuesOnChart = () => {
		if (statistics.length === 0) {
			setnumberOfEmailsCliked([]);
			setnumberOfEmailsDelivered([]);
			setnumberOfEmailsOpened([]);
			setnumberOfEmailsSent([]);
			return;
		} else {
			const datesRange = statistics.dateRange.map(x => moment(x).format('DD MM YYYY'));
			const dateLocal = statistics.dateRange.map(x => moment(x).format('D MMM'));
			const numberOfEmailsSentData = datesRange.map(element => {
				const elementWithData = statistics.series.find(d => moment(d.date).format('DD MM YYYY') === element);
				if (elementWithData) {
					return elementWithData.numberOfEmailsSent;
				} else {
					return 0;
				}
			});
			const numberOfEmailsDelivred = datesRange.map(element => {
				const elementWithData = statistics.series.find(d => moment(d.date).format('DD MM YYYY') === element);
				if (elementWithData) {
					return elementWithData.numberOfEmailsDelivered;
				} else {
					return 0;
				}
			});
			const numberOfEmailsClick = datesRange.map(element => {
				const elementWithData = statistics.series.find(d => moment(d.date).format('DD MM YYYY') === element);
				if (elementWithData) {
					return elementWithData.numberOfEmailsClicked;
				} else {
					return 0;
				}
			});
			const numberOfEmailsOpen = datesRange.map(element => {
				const elementWithData = statistics.series.find(d => moment(d.date).format('DD MM YYYY') === element);
				if (elementWithData) {
					return elementWithData.numberOfEmailsOpened;
				} else {
					return 0;
				}
			});
			setnumberOfEmailsSent(numberOfEmailsSentData);
			setnumberOfEmailsDelivered(numberOfEmailsDelivred);
			setnumberOfEmailsCliked(numberOfEmailsClick);
			setnumberOfEmailsOpened(numberOfEmailsOpen);
			setdayPeriode(dateLocal);
		}

	};

	useEffect(() => {
		if (statistics) {
			valuesOnChart();
		}
	}, [statistics]);

	const options = {
		exporting: {
			buttons: {
				contextButton: {
					menuItems: [
						'printChart',
						'separator',
						'downloadPNG',
						'downloadJPEG',
						'downloadPDF',
						'downloadSVG',
						'downloadCSV'
					]
				}
			}
		},
		chart: {
			height: 450,
			type: 'spline',
			// zoomType: 'x',
		},
		navigation: {
			buttonOptions: {
				verticalAlign: 'top',
				x: -50,
			}
		},
		title: {
			useHtml: true,
			text: '<b>Performance tracking  </b>',
			align: 'left',
		},
		legend: {
			symbolRadius: 0,
			symbolWidth: 40,
			itemStyle: {
				fontFamily: 'IBM Plex Sans',
				fontWeight: 'Light',
				left: '100px',
				top: '100px',
			},
			borderRadius: 3,
			align: 'center',
			verticalAlign: 'bottom',
		},
		credits: {
			enabled: false
		},
		xAxis: {
			gridLineWidth: 0,
			lineColor: 'black',
			lineWidth: 1,
			categories: dayPeriode,
			tickLength: 10,
			tickWidth: 1,
		},
		yAxis: [{ // Primary yAxis
			gridLineWidth: 0,
			labels: {
				format: '',
				style: {

					color: 'black',
				},
			},
			title: {
				text: '',
				style: {
					color: 'black',
				}
			},
			max: 100,
			alignTicks: false,
			opposite: true,
			crosshair: true

		}, { // Secondary yAxis
			gridLineWidth: 1,
			gridLineColor: '#b5b5b561',
			title: {
				text: 'Number of emails',
				style: {
					color: 'black'
				}
			},
			labels: {
				style: {
					color: '#293133',
					format: '{value} ',
				}
			},
		}],
		plotOptions: {
			column: {
				borderColor: '#9E9E9E',
				grouping: false,
				shadow: false,
				borderWidth: 0,
			},
			line: {
				grouping: true,
				shadow: false,
				dashStyle: 'long',
			}
			,
			series: {
				marker: {
					fillColor: '#FFF',
					lineWidth: 1,
					lineColor: null // inherit from series
				}
			},

		},
		opposite: true,
		tooltip: {
			shared: true,
			backgroundColor: '#38444A',
			color: '#FFF',
			style: {
				color: '#FFFFFF',
			},
		},
		series: [
			{
				name: 'number of emails sent',
				data: numberOfEmailsSent,
				color: '#4278F0',
				type: 'column',
				yAxis: 1,
			},
			{
				name: 'number of emails delivred',
				data: numberOfEmailsDelivered,
				color: '#FCAF45',
				type: 'line',
				yAxis: 1,
			},
			{
				name: 'number of emails opened',
				data: numberOfEmailsOpened,
				color: '#E85C8D',
				type: 'line',
				yAxis: 1,
			},
			{
				name: 'number of emails clicked',
				data: numberOfEmailsCliked,
				color: '#69B472',
				type: 'line',
				yAxis: 1,
			},

		]

	};
	return (
		<Fragment>
			<div className="reporting_chart">
				{isOnCharg ?
					<div style={{ height: '380px' }}>
						{/* when data is reloading after click on new campaign id to keep the size of the graph in the view */}
					</div>
					:
					<HighchartsReact
						className='chart'
						highcharts={Highcharts}
						options={options}
						constructorType={'chart'}
					/>
				}
			</div>

		</Fragment>
	);
}
