import HttpServices from './HttpServices';
import axios from 'axios';

export default class CloudImageServices {
	HttpServices: HttpServices;
	accountId: string;
	BO_API_URL: string;
	SERVER_URL: string;
	config: { headers: any };
	_postUploadToImageCloud: (libraryId: any, files: any, onUploadProgress: any, provider: any) => Promise<any>;
	constructor(accountId, $http, getAccessToken) {
		this.HttpServices = new HttpServices($http);
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = (window as any).BEYABLE_env.SERVER_URL;

		this.config = {
			headers: {}
		};

		const token = getAccessToken();
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}

		this.accountId = accountId;

		this._postUploadToImageCloud = (libraryId, files, onUploadProgress, provider) => {
			return axios.post(`${this.BO_API_URL}imagecloud/${this.accountId}/${libraryId}/upload?provider=${provider}`, files,
				{
					onUploadProgress: onUploadProgress,
					headers: this.config.headers,
					 // @ts-ignore
					'Content-Type': 'multipart/form-data' 
				},
			);
		};
	}

	uploadToCloudinary(libraryId, files, onUploadProgress) {
		return this._postUploadToImageCloud(libraryId, files, onUploadProgress, 'Cloudinary');
	}

	uploadToTwicPics(libraryId, files, onUploadProgress) {
		return this._postUploadToImageCloud(libraryId, files, onUploadProgress, 'TwicPics');
	}

	findImageSource(imageUrl) {
		return axios.get(`${this.BO_API_URL}medialibrary/${this.accountId}/library/source?imageUrl=${imageUrl}`,
			{ headers: this.config.headers });
	}

	transferImageToCloudinary(banner, folderId) {
		return axios.post(`${this.BO_API_URL}imagecloud/${this.accountId}/transfer`, { banner, folderId, targetProvider: 'Cloudinary' },
			{ headers: this.config.headers });
	}

	postAddCloudImagesToLibrary(libraryId, images) {
		return axios.post(`${this.BO_API_URL}medialibrary/${this.accountId}/library/${libraryId}/cloud`, images,
			{ headers: this.config.headers, 
			// @ts-ignore
			'Content-Type': 'multipart/form-data' },
		);
	}


	postCreateLibrary() {
		return axios.post(`${this.BO_API_URL}medialibrary/${this.accountId}/library`, new FormData(),
			{ headers: this.config.headers, 
			// @ts-ignore
			'Content-Type': 'multipart/form-data' },
		);
	}

	uploadImage(file) {
		return axios.post(`${this.BO_API_URL}medialibrary/${this.accountId}/library`, file,
			{ headers: this.config.headers, 
				 // @ts-ignore
				'Content-Type': 'multipart/form-data' },
		);
	}
}
