export default function EventTrackingServices(user) {

	const juneTrackEvent = (event, details) => {
		if (!window.analytics || typeof window.analytics.track !== 'function') {
			console.log('June is not initialized');
			return;
		}
		if (typeof user === 'undefined') {
			console.log('No user for June tracking');
			return;
		}
		if (user.Email.toLocaleLowerCase().includes('beyable') || user.Email.toLocaleLowerCase().includes('allbyweb')) {
			console.log('June is ignored for this user');
			return;
		}
		if (!window.BEYABLE_env.options.juneIsActive) {
			console.log('June is not active');
			return;
		}
		window.analytics.track(event, details);
	};

	const intercomTrackEvent = (event) => {
		if (!window.Intercom) {
			console.log('Intercom is not initialized');
			return;
		}
		window.Intercom('trackEvent', event);
	};

	const trackEvent = (event, details) => {
		juneTrackEvent(event, details);
		intercomTrackEvent(event);
	};

	return {
		trackEvent
	};
}