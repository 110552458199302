import React, { useState } from 'react';
import './ButtonChoice.css';
export default function ButtonChoice({ data }) {

	return (
		<>
			<input type="radio" />
			<div className="radio_boxCrossSell_img">
				{
					data.key === 'TAGS' ||
                        data.key === 'moreAttractivity' ||
                        data.key === 'lessAttractivity'
						?
						<img src={data.icon} alt="" width="30px" />
						:
						<i className={data.icon}></i>
				}
			</div>
			<div className="radio_boxCrossSell_labels">
				<p className="name">{data.label}</p>
				<p className="description">{data.infos}</p>
			</div>
		</>
	);
}
