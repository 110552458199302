import React, { useState, useEffect } from 'react';
import Btn from '../../../../Components/Btn';
import EmptyState from '../../../../Components/EmptyState';
import OperatorBetweenFilters from './OperatorBetweenFilters';
import ParamsFilter from './TypesContainer/ParamsFilter';
import ComponentAccordingToCollationItem  from './TypesContainer/ComponentAccordingToCollationItem';
import {useFiltersContext} from './contextFilters';
export default function FilterSetup({
	onDeleteFilter,
	hasNoFilters,
	handleChangeFilterParamsByUniqueId,
	filterList,
	isExpression,
	operatorMultipleValue,
	elementsFilterListAvailableSelected,
	handleChangeOperatorBetweenFilters,
	// tabFilterSelected,
	handleChangeFilterByUniqueId
}) {

	const { tabFilterSelected } = useFiltersContext();
	const [filtersParams, setFiltersParams] = useState(<></>);
	const getParamsValue = (name) => {
		if (!tabFilterSelected || !tabFilterSelected.params) return '';

		const elementToManipulate = tabFilterSelected.params.find((elem) => elem.name === name);
		return elementToManipulate ? elementToManipulate.value : '';
	};

	const getParamsValueType = (collationId, paramName) => {
		const filterParams = filterList.find((el) => el.id === collationId)?.params;
		const param = filterParams?.find(p => p.name === paramName);
		return param?.valueType;
	};
	useEffect(()=>{
		if(!tabFilterSelected){
			setFiltersParams(<></>);
		}else if(tabFilterSelected.params){
			const args = <>
				{tabFilterSelected.params.map((param, i) => (
					<ParamsFilter
						key={i}
						filter={tabFilterSelected}
						name={param.name}
						value={getParamsValue(param.name)}
						valueType={param.valueType ?? getParamsValueType(tabFilterSelected.collationId, param.name)}
						handleChangeFilterParamsByUniqueId={handleChangeFilterParamsByUniqueId}
						tabFilterSelected={tabFilterSelected}
					/>
				))}</>;
			setFiltersParams(args);
		}

	},[tabFilterSelected]);
	return (
		<>
			{hasNoFilters && (
				<EmptyState title="No selected filter" icon="fas fa-filter" />
			)}

			{!hasNoFilters && tabFilterSelected && (
				<>
					<div className="collection_modal_filter_header flex">
						<div className="collection_modal_filter_title flex_item_full">
							{tabFilterSelected.name}
						</div>
						<div className="flex_item_fix">
							{isExpression && (
								<Btn
									icon="fas fa-trash"
									color="secondary"
									className="tab_btn"
									onClick={(e) => onDeleteFilters(e, tabFilterSelected)}
									dataTip={'Delete this filter'}
								/>
							)}
							{!hasNoFilters && operatorMultipleValue && (
								<OperatorBetweenFilters
									op={operatorMultipleValue}
									userfiltersList={elementsFilterListAvailableSelected}
									handleChangeOperator={handleChangeOperatorBetweenFilters}
								/>
							)}
						</div>
					</div>

					<div className="collection_modal_filter_body">
						{tabFilterSelected.name === 'Discount' && (
							<div className="mb_20">The value to enter is an amount.</div>
						)}
						{filtersParams}
						<ComponentAccordingToCollationItem
							filterList={filterList}
							handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
						/>
					</div>
				</>
			)}
		</>
	);
}