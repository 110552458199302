import { ServerSideCampaignStatus } from '../../../../Services/ServerSideCampaignServicesTypes';
import { Template, TemplateDescriptor, TemplateProperty } from '../../../WysiwygEditor/Types/BespokeTypes';

export type ServerSideCampaignObject = {
	//SlideFormat: string;
	Id: string | null;
	Name: string;
	Description: string | null;
	DesignProperties: [];
	Properties: TemplateProperty[];
	TemplateType: string;
	TemplateName: string;
	TemplateDescriptorDesktop: TemplateDescriptor;
	TemplateDescriptorMobile: TemplateDescriptor;
	SavedStyle: string;
	Template: Template | null;
	Status: ServerSideCampaignStatus;
}


export const emptyTemplate: ServerSideCampaignObject = {
	//SlideFormat: '',
	Id: null,
	Name: 'New campaign',
	Description: '',
	DesignProperties: [],
	Properties: [],
	TemplateType: '',
	TemplateName: '',
	TemplateDescriptorDesktop: {
		Css: '',
		EditableCss: '',
		EditableTemplate: {
			ContentTemplateType: 'Fluid',
			Content: '',
		},
	},
	TemplateDescriptorMobile: {
		Css: '',
		EditableCss: '',
		EditableTemplate: {
			ContentTemplateType: 'Fluid',
			Content: '',
		},
	},
	SavedStyle: '',
	Template: null,
	Status: ServerSideCampaignStatus.Inactive
};




export type ServerSideSaaSFormat = {
	template: Template
}