import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import ToolingServices from '../../../Services/ToolingServices';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import ProductFeedConfigurationServices from '../../../Services/ProductFeedConfigurationServices';

import Btn from '../../../Components/Btn';
import Panel from './../../../Components/Panel';
import NavigationFeedContainer from './Configuration/NavigationFeedContainer';
import CreationFeedContainer from './Configuration/CreationFeedContainer';
import ConfigurationFieldsContainer from './Configuration/ConfigurationFieldsContainer';
import EditSourceProductFeed from './Configuration/EditSourceProductFeed';
import useInputValidation from '../../../Hooks/useInputVal';
import SelectTagsContainer from './Configuration/SelectTagsContainer';
import CategoryProductFeedContainer from './Configuration/CategoryProductFeedContainer';
import CreateTagsContainer from './Configuration/CreateTagsContainer';
import HierarchizationProductFeedContainer from './Configuration/HierarchizationProductFeedContainer';
import PopinConfirm from '../../../Components/PopinConfirm';
import CatalogueProductFeedContainer from './Catalogue/CatalogueProductFeedContainer';
import TenantSelectorContainer from './Configuration/TenantSelectorContainer';
import NewTenantCreationContainer from './Configuration/NewTenantCreationContainer';
import './ProductFeedContainer.css';
export default function ProductFeedContainers(props) {
	const propsDestructuring = props.props ? props.props : props;
	const isNewProductFeedConfig = propsDestructuring?.isNewConfig ? true : false;
	const toolingServices = new ToolingServices(propsDestructuring.$http, propsDestructuring.$routeParams);
	const systemServices = new SystemServices(propsDestructuring.$rootScope);
	const impersonatingServices = new ImpersonatingServices(propsDestructuring.$rootScope, propsDestructuring.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || propsDestructuring.$rootScope && propsDestructuring.$rootScope.User.Account.Key;
	const getAccountConfiguration = (accountId, callbackSuccess, callbackError) => toolingServices.getAccountConfiguration(accountId, success => { callbackSuccess(success); }, error => { callbackError(error); });
	const services = new ProductFeedConfigurationServices(propsDestructuring.$http);
	const getProductFeedConfiguration = (accountId, callbackSuccess, callbackError) => toolingServices.getProductFeedConfiguration(accountId, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const postProductFeedConfig = (accountId, productFeedMetaData, callbackSuccess, callbackError) => toolingServices.postProductFeedConfig(accountId, productFeedMetaData, success => { callbackSuccess(success); }, error => { systemServices.showError('An error occured during the process'); callbackError(error); });

	const putProductFeedConfig = (accountId, productFeedMetaData, callbackSuccess, callbackError) => toolingServices.putProductFeedConfig(accountId, productFeedMetaData, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const [accountConfigurationFeed, setAccountConfigurationFeed] = useState();
	const [tenants, setTenants] = useState();
	const [tenantSelected, setTenantSelected] = useState();
	const [arrayOfErrors, setArrayOfErrors] = useState([]);
	const [isConfigurationMode, setIsConfigurationMode] = useState(false);
	const [oppenCreationTenant, setOppenCreationTenant] = useState(false);
	const [isConfigurationSourceMode, setIsConfigurationSourceMode] = useState(false);
	const [isCreationFeedMode, setIsCreationFeedMode] = useState(false);
	const [activeFeed, setActiveFeed] = useState();
	const [metaDataToMap, setMetaDataToMap] = useState();
	const [etag, setEtag] = useState('');
	const [newProductFeed, setNewProductFeed] = useState({
		type: '',
		source: ''
	});

	const [listTags, setListTags] = useState('');
	const [isReloading, setIsRealoading] = useState(true);
	const [accessValue, setAccessValue] = useState();
	const [importIsWork, setImportIsWork] = useState(false);
	const [productFeedCategory, setProductFeedCategory] = useState([]);
	const [productFeedTags, setProductFeedTags] = useState([]);
	const [isFirstRenderOfExistingFeedConfiguration, setIsFirstRenderOfExistingFeedConfiguration] = useState(false);
	const [dataToChange, setDataToChange] = useState();
	const [isFirstRender, setIsFirstRender] = useState(false);
	const [hierarchizationFeedOpen, setHierarchizationFeedOpen] = useState(false);
	const [saveTheHierarchieInBack, setSaveTheHierarchieInBack] = useState(false);
	const [changesIsNotSaved, setChangesIsNotSaved] = useState(false);
	const [poppinSaveChangeIsOppen, setPoppinSaveChangeIsOppen] = useState(false);
	const [errorsWhenParseCsv, setErrorsWhenParseScv] = useState();
	const { isAnUrl, isNotEmpty } = useInputValidation();

	const closeThePannel = () => {
		if (!changesIsNotSaved) {
			setIsConfigurationMode(false);
			setAccountConfigurationFeed([]);
			setArrayOfErrors([]);
			setIsCreationFeedMode(false);
			setActiveFeed();
			setMetaDataToMap([]);
			setImportIsWork(false);
			setProductFeedTags([]);
			setProductFeedCategory([]);
			setIsFirstRenderOfExistingFeedConfiguration(false);
			setIsConfigurationSourceMode(false);
			setNewProductFeed({
				type: '',
				source: ''
			});
			setHierarchizationFeedOpen(false);
			setErrorsWhenParseScv();
		} else {
			setPoppinSaveChangeIsOppen(true);
		}
	};

	const [dataFields, setDataFields] = useState([
		{ name: 'productId', value: '', label: 'Product Id' },
		{ name: 'productName', value: '', label: 'Product Name' },
		{ name: 'productImg', value: '', label: 'Product Image' },
		{ name: 'productPrice', value: '', label: 'Product Price' },
		{ name: 'productPriceNoRebate', value: '', label: 'Product Price No Rebate' },
		{ name: 'productUrl', value: '', label: 'Product Url' },
	]);

	useEffect(() => {
		if (isConfigurationMode) {
			setIsRealoading(true);
			getProductFeedConfiguration(accountId, data => {
				// if tenant exist for the account
				if (data.config.length !== 0) {
					let dataWithIds = data.config;
					dataWithIds.forEach(elem => {
						elem.config.forEach(conf => {
							if (conf.hierarchies.length > 0) {
								let id = 1;
								conf.hierarchies.forEach(hierarchie => {
									hierarchie.id = id;
									id += 1;
									if (hierarchie.categories.length > 0) {
										let idH = 1;
										hierarchie.categories.forEach(cat => {
											cat.id = idH;
											idH += 1;
										});
									}
								});
							}
						});
						return dataWithIds;
					});
					setAccountConfigurationFeed(dataWithIds[0].config);
					setTenantSelected(dataWithIds[0].tenant);
					setTenants(dataWithIds);
					setIsRealoading(false);
					setIsFirstRenderOfExistingFeedConfiguration(true);
					setEtag(data.eTag);
				} else {
					setIsRealoading(false);
					setOppenCreationTenant(true);
				}
			}
			, error => {
				setIsRealoading(false);
			});
		}
	}, [isConfigurationMode]);
	useEffect(() => {
		if (tenants) {
			const tenantDatasToLoad = tenants.filter(ten => ten.tenant === tenantSelected)[0].config;
			if (tenantDatasToLoad.length === 0) {
				setIsCreationFeedMode(false);
				setActiveFeed();
				setNewProductFeed({
					type: '',
					source: ''
				});
				setAccountConfigurationFeed(tenantDatasToLoad);
			} else {
				setAccountConfigurationFeed(tenantDatasToLoad);
				setActiveFeed({ source: tenantDatasToLoad[0].source.sourceType, format: tenantDatasToLoad[0].source.sourceFormat, index: tenantDatasToLoad[0].index });
			}
		}
	}, [tenantSelected]);

	useEffect(() => {
		if (isFirstRenderOfExistingFeedConfiguration && accountConfigurationFeed.length !== 0) {
			setIsFirstRenderOfExistingFeedConfiguration(false);
			setActiveFeed({ source: accountConfigurationFeed[0].source.sourceType, format: accountConfigurationFeed[0].source.sourceFormat, index: accountConfigurationFeed[0].index });
		}
	}, [isFirstRenderOfExistingFeedConfiguration]);

	const saveTheCurrentValues = (e, isNew) => {
		let valuesToSave = accountConfigurationFeed.filter(feed =>
			feed.index == activeFeed.index)[0];
		if (!valuesToSave) {
			setActiveFeed();
			return;
		}
		if (valuesToSave.source.sourceType == 'OnSiteData') {
			valuesToSave.productTags = listTags.split(/[;,\n]/).filter(x => x.length > 0);
		} else {
			valuesToSave.productTags = productFeedTags;
		}
		valuesToSave.hierarchies = productFeedCategory;
		valuesToSave.productId = dataFields.filter(name => name.name == 'productId')[0].value;
		valuesToSave.productName = dataFields.filter(name => name.name == 'productName')[0].value;
		valuesToSave.productImg = dataFields.filter(name => name.name == 'productImg')[0].value;
		valuesToSave.productPrice = dataFields.filter(name => name.name == 'productPrice')[0].value;
		valuesToSave.productPriceNoRebate = dataFields.filter(name => name.name == 'productPriceNoRebate')[0].value;
		valuesToSave.productUrl = dataFields.filter(name => name.name == 'productUrl')[0].value;
		const withoutOldValues = accountConfigurationFeed.filter(feed => feed.index !== activeFeed.index);
		setAccountConfigurationFeed([...withoutOldValues, { ...valuesToSave }]);
		if (isNew) {
			setActiveFeed();
			setIsCreationFeedMode(true);
		} else {
			setActiveFeed(e);
		}
	};
	useEffect(() => {
		if (activeFeed) {
			setDataToChange(accountConfigurationFeed.filter(x => x.index == activeFeed.index)[0]);
		}
	}, [activeFeed]);
	useEffect(() => {
		if (dataToChange) {
			setDataFields([
				{ name: 'productName', value: dataToChange.productName, label: 'Product Name' },
				{ name: 'productImg', value: dataToChange.productImg, label: 'Product Image' },
				{ name: 'productPrice', value: dataToChange.productPrice, label: 'Product Price' },
				{ name: 'productPriceNoRebate', value: dataToChange.productPriceNoRebate, label: 'Product Price No Rebate' },
				{ name: 'productId', value: dataToChange.productId, label: 'Product Id' },
				{ name: 'productUrl', value: dataToChange.productUrl, label: 'Product Url' },
			]);
			setProductFeedTags(dataToChange.productTags);
			setProductFeedCategory(dataToChange.hierarchies);
			setMetaDataToMap(dataToChange.source.knownSourceFields);
			setNewProductFeed({
				type: '',
				source: ''
			});
			setIsConfigurationSourceMode(false);
		}
	}, [dataToChange]);

	const handleChangeDataFields = (e) => {
		setChangesIsNotSaved(true);
		const newSelectValue = e.target.value;
		let myStateToChange = [...dataFields];
		myStateToChange = myStateToChange.filter(x => x.name == e.target.name)[0];
		let myStateWithoutValue = [...dataFields];
		myStateWithoutValue = myStateWithoutValue.filter(x => x.name !== e.target.name);
		myStateToChange.value = newSelectValue;
		setDataFields([myStateToChange, ...myStateWithoutValue]);
	};

	const putSelectTags = (e) => {
		setChangesIsNotSaved(true);
		const newArraOfTags = e.map(tag => tag.value);
		setProductFeedTags(newArraOfTags);
	};
	function createNewIndex() {
		let ids = accountConfigurationFeed.map(x => x.index);
		let newId = ids.length + 1;
		const getNewId = () => {
			let number;
			if (ids.length !== 0) {
				number = parseInt(Math.max(...ids) + 1);
			} else {
				number = ids.length + 1;
			}
			if (ids.includes(number)) {
				number = array.length + 55;
			}
			else {
				newId = number;
			}
		};
		getNewId();
		return newId;
	}

	const validTheFirstStepOfCreationProductFeed = (arrayOfFeed, activeIndex) => {
		if (newProductFeed.source == 'Web') {
			const isEmpty = isNotEmpty(accessValue.url);
			if (!isEmpty) {
				setArrayOfErrors(['empty']);
				return;
			} else {
				const validation = isAnUrl(accessValue.url);
				if (validation && newProductFeed.type !== '') {
					setIsRealoading(true);
					const productFeedMetaData = {
						source: {
							sourceUrl: accessValue.url,
							sourceType: 'Web',
							sourceFormat: newProductFeed.type
						},
						csvConfig: {
							separator: accessValue.separator
						},
						xmlConfig: {
							root: accessValue.root,
							itemNode: accessValue.itemNode,
							namespaceDeclaration: accessValue.namespaceDeclaration
						},
					};
					const index = activeIndex ? activeIndex : createNewIndex();
					postProductFeedConfig(accountId, productFeedMetaData, data => {
						setIsRealoading(false);
						if (data.errors.length !== 0) {
							console.log('erreur', data.errors);
							data.errors.forEach(elem => {
								if (elem.errorType === 'CannotGuessSourceStructure') {
									if (newProductFeed.type === 'Csv') {
										accessValue.separator = '';
										systemServices.showError('We cannot guess the source structure');
									} else {
										accessValue.root = '';
										accessValue.itemNode = '';
										accessValue.namespaceDeclaration = '';
										systemServices.showError('We cannot guess the source structure');
									}
								}
							});
						} else {
							let boundHeaders = [];
							boundHeaders = data.boundHeaders;
							console.log('data.xmlConfig', data.xmlConfig);
							setAccountConfigurationFeed([...arrayOfFeed,
								{
									source: { ...productFeedMetaData.source, knownSourceFields: data.headers },
									productTags: [],
									hierarchies: [],
									productId: boundHeaders.length !== 0 ? boundHeaders.ProductId : '',
									productName: boundHeaders.length !== 0 ? boundHeaders.ProductName : '',
									index: index,
									productImg: boundHeaders.length !== 0 ? boundHeaders.ProductImg : '',
									productPrice: boundHeaders.length !== 0 ? boundHeaders.ProductPrice : '',
									productPriceNoRebate: boundHeaders.length !== 0 ? boundHeaders.ProductPriceNoRebate : '',
									productUrl: boundHeaders.length !== 0 ? boundHeaders.ProductUrl : '',
									csvConfig: {
										separator: data.csvConfig !== null ? data.csvConfig.separator : accessValue.separator
									},

									xmlConfig: {
										root: data.xmlConfig !== null ? data.xmlConfig.root : accessValue.root,
										itemNode: data.xmlConfig !== null ? data.xmlConfig.itemNode : accessValue.itemNode,
										namespaceDeclaration: data.xmlConfig !== null ? data.xmlConfig.namespaceDeclaration : accessValue.namespaceDeclaration,
									},

								}]);
							setActiveFeed({ source: productFeedMetaData.source.sourceType, format: productFeedMetaData.source.sourceFormat, index: index });
							setIsCreationFeedMode(false);
							// setIsRealoading(false)
							setSaveTheHierarchieInBack(true);
						}
					}, error => {
						console.log('error', error);
						setIsRealoading(false);
					});
				} else {
					setArrayOfErrors(['url']);
					return;
				}
			}
		}
		else if (newProductFeed.source == 'Ftp') {
			const arrayError = [];
			const ulrIsEmpty = isNotEmpty(accessValue.url);
			const loginIsEmpty = isNotEmpty(accessValue.login);
			const passWordIsEmpty = isNotEmpty(accessValue.passWord);
			if (!ulrIsEmpty) {
				arrayError.push('empty');
			}
			if (!loginIsEmpty) {
				arrayError.push('empty');
			}
			if (!passWordIsEmpty) {
				arrayError.push('empty');
			}
			if (ulrIsEmpty) {
				const validation = isAnUrl(accessValue.url);
				if (!validation) {
					arrayError.push('url');
				}
			}
			setArrayOfErrors(arrayError);
			if (arrayError.length == 0 && newProductFeed.type !== '') {
				setIsRealoading(true);
				const productFeedMetaData = {
					source: {
						login: accessValue.login,
						password: accessValue.passWord,
						sourceUrl: accessValue.url,
						sourceType: 'Ftp',
						sourceFormat: newProductFeed.type
					},
					csvConfig: {
						separator: accessValue.separator
					},
					xmlConfig: {
						root: '',
						itemNode: '',
						namespaceDeclaration: ''
					},
				};
				postProductFeedConfig(accountId, productFeedMetaData, data => {
					setIsRealoading(false);
					console.log('data', data);
					if (data.errors.length !== 0) {
						setErrorsWhenParseScv(data.errors);
						data.errors.forEach(elem => {
							if (elem.errorType === 'CannotGuessSourceStructure') {
								if (newProductFeed.type === 'Csv') {
									accessValue.separator = '';
									systemServices.showError('We cannot guess the source structure');
								} else {
									accessValue.root = '';
									accessValue.itemNode = '';
									accessValue.namespaceDeclaration = '';
									systemServices.showError('We cannot guess the source structure');
								}
							}
						});
					} else {
						console.log('data', data);
						let boundHeaders = [];
						boundHeaders = data.boundHeaders;
						const index = activeIndex ? activeIndex : createNewIndex();
						setAccountConfigurationFeed([...arrayOfFeed,
							{
								source: { ...productFeedMetaData.source, knownSourceFields: data.headers },
								productTags: [],
								hierarchies: [],
								productId: boundHeaders.length !== 0 ? boundHeaders.ProductId : '',
								productName: boundHeaders.length !== 0 ? boundHeaders.ProductName : '',
								index: index,
								productImg: boundHeaders.length !== 0 ? boundHeaders.ProductImg : '',
								productPrice: boundHeaders.length !== 0 ? boundHeaders.ProductPrice : '',
								productPriceNoRebate: boundHeaders.length !== 0 ? boundHeaders.ProductPriceNoRebate : '',
								productUrl: boundHeaders.length !== 0 ? boundHeaders.ProductUrl : '',
								csvConfig: {
									separator: data.csvConfig !== null ? data.csvConfig.separator : accessValue.separator
								},

								xmlConfig: {
									root: data.xmlConfig !== null ? data.xmlConfig.root : accessValue.root,
									itemNode: data.xmlConfig !== null ? data.xmlConfig.itemNode : accessValue.itemNode,
									namespaceDeclaration: data.xmlConfig !== null ? data.xmlConfig.namespaceDeclaration : accessValue.namespaceDeclaration,
								},
							}]);
						setActiveFeed({ source: productFeedMetaData.source.sourceType, format: productFeedMetaData.source.sourceFormat, index: index });
						setIsCreationFeedMode(false);
						// setIsRealoading(false)
						setSaveTheHierarchieInBack(true);
					}
				}, error => {
					console.log('error', error);
					setIsRealoading(false);
				});
			}
		}
		else if (newProductFeed.source == 'OnSiteData') {
			setIsRealoading(true);
			const productFeedMetaData = {
				source: {
					sourceType: 'OnSiteData',
					sourceFormat: newProductFeed.type
				},
			};
			const index = activeIndex ? activeIndex : createNewIndex();
			postProductFeedConfig(accountId, productFeedMetaData, data => {
				console.log('data', data);
				setIsRealoading(false);
				setAccountConfigurationFeed([...arrayOfFeed,
					{
						source: { ...productFeedMetaData.source, knownSourceFields: data.headers },
						productTags: [],
						hierarchies: [],
						productId: '',
						productName: '',
						productImg: '',
						productPrice: '',
						index: index,
						productPriceNoRebate: '',
						productUrl: '',
					}]);
				setActiveFeed({ source: productFeedMetaData.source.sourceType, format: 'Csv', index: index });
				setIsCreationFeedMode(false);
				// setIsRealoading(false)
				setSaveTheHierarchieInBack(true);
			}, error => {
				console.log('error', error);
				setIsRealoading(false);
			});
		}
		else if (newProductFeed.source == 'Prestashop') {
			const arrayError = [];
			const ulrIsEmpty = isNotEmpty(accessValue.url);
			const apiKey = isNotEmpty(accessValue.apiKey);
			if (!ulrIsEmpty) {
				arrayError.push('empty');
			}
			if (!apiKey) {
				arrayError.push('empty');
			}
			if (ulrIsEmpty) {
				const validation = isAnUrl(accessValue.url);
				if (!validation) {
					arrayError.push('url');
				}
			}
			setArrayOfErrors(arrayError);
			if (arrayError.length == 0) {
				setIsRealoading(true);
				const productFeedMetaData = {
					source: {
						sourceUrl: accessValue.url,
						sourceType: 'Prestashop',
						sourceFormat: newProductFeed.type,
						apiKey: accessValue.apiKey,
						languageId: accessValue.languageId
					},
				};
				const index = activeIndex ? activeIndex : createNewIndex();
				postProductFeedConfig(accountId, productFeedMetaData, data => {
					console.log('data', data);
					console.log('productFeedMetaData', productFeedMetaData);
					setIsRealoading(false);
					setAccountConfigurationFeed([...arrayOfFeed,
						{
							source: { ...productFeedMetaData.source, knownSourceFields: data.headers },
							productTags: [],
							hierarchies: [],
							productId: '',
							productName: '',
							productImg: '',
							productPrice: '',
							index: index,
							productPriceNoRebate: '',
							productUrl: '',
						}]);
					setActiveFeed({ source: productFeedMetaData.source.sourceType, format: 'Csv', index: index });
					setIsCreationFeedMode(false);
					// setIsRealoading(false)
					setSaveTheHierarchieInBack(true);
				}, error => {
					console.log('error', error);
					setIsRealoading(false);
				});
			}
		}

	};
	const putConfigProductFeed = (object, isCreationTenant) => {
		if (!isCreationTenant) {
			saveTheCurrentValues(activeFeed);
		}
		setIsRealoading(true);
		const ProductFeedConfigUpdate = { config: object, eTag: etag, tenant: isCreationTenant ? isCreationTenant : tenantSelected };
		putProductFeedConfig(accountId, ProductFeedConfigUpdate, data => {
			setEtag(data.eTag);
			setIsRealoading(false);
			setChangesIsNotSaved(false);
			getProductFeedConfiguration(accountId, data => {
				let dataWithIds = data.config;
				dataWithIds.forEach(elem => {
					elem.config.forEach(conf => {
						if (conf.hierarchies.length > 0) {
							let id = 1;
							conf.hierarchies.forEach(hierarchie => {
								hierarchie.id = id;
								id += 1;
								if (hierarchie.categories.length > 0) {
									let idH = 1;
									hierarchie.categories.forEach(cat => {
										cat.id = idH;
										idH += 1;
									});
								}
							});
						}
					});
					setTenants(dataWithIds);
					if (isCreationTenant) {
						setTenantSelected(isCreationTenant);
					}
				});
			}, error => {
				console.log('error', error);
			});
		}, error => {
			console.log('error', error);
			setIsRealoading(false);
		});
	};
	useEffect(() => {
		if (saveTheHierarchieInBack) {
			putConfigProductFeed(accountConfigurationFeed);
			setSaveTheHierarchieInBack(false);
		}
	}, [saveTheHierarchieInBack]);

	const changeTheConfigurationSourceFeed = () => {
		const withoutOldValues = accountConfigurationFeed.filter(feed => feed.index !== activeFeed.index);
		setIsRealoading(true);
		validTheFirstStepOfCreationProductFeed(withoutOldValues);
	};
	const deleteProductFeed = () => {
		setIsConfigurationSourceMode(false);
		const newFeedObj = accountConfigurationFeed.filter(feed => feed.index !== activeFeed.index);
		if (newFeedObj.length !== 0) {
			setActiveFeed({ source: newFeedObj[0].source.sourceType, format: newFeedObj[0].source.sourceFormat, index: newFeedObj[0].index });
			setAccountConfigurationFeed(newFeedObj);
			setSaveTheHierarchieInBack(true);
		} else {
			setActiveFeed();
			setAccountConfigurationFeed(newFeedObj);
			setSaveTheHierarchieInBack(true);
		}
	};
	const refreshDataOfProductFeed = () => {
		let newFeedObj = accountConfigurationFeed.filter(feed => feed.index == activeFeed.index)[0];
		const withoutObj = accountConfigurationFeed.filter(feed => feed.index !== activeFeed.index);
		setIsRealoading(true);
		const productFeedMetaData = {
			source: {
				sourceUrl: accessValue.url ? accessValue.url : null,
				sourceType: newProductFeed.source,
				sourceFormat: newProductFeed.type,
				apiKey: accessValue.apiKey ? accessValue.apiKey : null,
				login: accessValue.login ? accessValue.login : null,
				password: accessValue.passWord ? accessValue.passWord : null
			},
			csvConfig: {
				separator: newFeedObj.csvConfig && newFeedObj.csvConfig.separator
			},
			xmlConfig: {
				root: newFeedObj.xmlConfig && newFeedObj.xmlConfig.root,
				itemNode: newFeedObj.xmlConfig && newFeedObj.xmlConfig.itemNode,
				namespaceDeclaration: newFeedObj.xmlConfig && newFeedObj.xmlConfig.namespaceDeclaration
			},
		};
		setErrorsWhenParseScv();
		postProductFeedConfig(accountId, productFeedMetaData, data => {
			setIsRealoading(false);
			if (data.errors.length !== 0) {
				data.errors.forEach(elem => {
					let message = elem.errorType === 'TagIsMissing' ? `Tag ${elem.message} is missing ` :
						`Category ${elem.message} is missing `;
					systemServices.showError(message);
				});
			} else {
				if (newProductFeed.source === 'OnSiteData') {
					newFeedObj.source.lastSourceSynchroAtUtc = moment();
				} else {
					newFeedObj.source.knownSourceFields = data.headers;
					newFeedObj.source.lastSourceSynchroAtUtc = moment();
					setMetaDataToMap(data.headers);
				}
				setAccountConfigurationFeed([...withoutObj, newFeedObj]);
				saveTheCurrentValues(activeFeed);
			}
		}, error => {
			console.log('error', error);
			setIsRealoading(false);
		});
	};
	return (
		<div>
			{poppinSaveChangeIsOppen &&
                <PopinConfirm
                	cancel={() => setPoppinSaveChangeIsOppen(false)}
                	title={'Are you sure you want to quit without saving ?'}
                	message={''}
                >
                	<Btn
                		onClickFunction={(e) => { setPoppinSaveChangeIsOppen(false); }}
                		message="No"
                		color="alert"
                	/>
                	<Btn
                		onClickFunction={(e) => { setChangesIsNotSaved(false); setPoppinSaveChangeIsOppen(false); setIsConfigurationMode(false); }}
                		message="Yes i'am sure"
                	/>
                </PopinConfirm>
			}
			{
				!isConfigurationMode &&
                <CatalogueProductFeedContainer
                    isNewProductFeedConfig={isNewProductFeedConfig}
                	authServices={propsDestructuring.AuthServices}
                	accountId={accountId}
                	tenantSelected={tenantSelected}
                	systemServices={systemServices}
					services={services}
                	getProductFeedConfiguration={getProductFeedConfiguration}
                	setTenantSelected={(e) => setTenantSelected(e)}
                	setIsConfigurationMode={(e) => setIsConfigurationMode(true)}
                />
			}

			<Panel
				isOpen={isConfigurationMode}
				onClose={() => closeThePannel()}
				side="right"
				width="730"
			>
				<div className="panel_header"> {!isCreationFeedMode ? 'Product feed configuration' : 'New product feed '}
				</div>
				{
					oppenCreationTenant ?
						<>
							<NewTenantCreationContainer
								tenants={tenants}
								setOppenCreationTenant={(e) => setOppenCreationTenant(e)}
								setTenantSelected={(e) => setTenantSelected(e)}
								tenantSelected={tenantSelected}
								putConfigProductFeed={(e, tenantName) => putConfigProductFeed(e, tenantName)}
								accountConfigurationFeed={accountConfigurationFeed}

							/>
						</>
						:
						<>
							{!isCreationFeedMode && !isConfigurationSourceMode && !hierarchizationFeedOpen &&
                                <div className="tenant-container">
                                	{
                                		<TenantSelectorContainer
                                			tenants={tenants}
                                			setOppenCreationTenant={(e) => setOppenCreationTenant(e)}
                                			setTenantSelected={(e) => setTenantSelected(e)}
                                			tenantSelected={tenantSelected}
                                			putConfigProductFeed={(e) => putConfigProductFeed(e)}
                                			changesIsNotSaved={changesIsNotSaved}
                                			setChangesIsNotSaved={(e) => setChangesIsNotSaved(e)}
                                		/>
                                	}
                                </div>
							}
							{!isConfigurationSourceMode && !hierarchizationFeedOpen &&
                                <>
                                	{
                                		accountConfigurationFeed && accountConfigurationFeed.length > 0 && !isCreationFeedMode &&
                                        <NavigationFeedContainer
                                        	accountConfigurationFeed={accountConfigurationFeed}
                                        	setIsCreationFeedMode={(e) => setIsCreationFeedMode(e)}
                                        	activeFeed={activeFeed}
                                        	setActiveFeed={(e) => setActiveFeed(e)}
                                        	saveTheCurrentValues={(e, isNew) => saveTheCurrentValues(e, isNew)}
                                        	setHierarchizationFeedOpen={(e) => setHierarchizationFeedOpen(e)}
                                        />
                                	}
                                	{
                                		accountConfigurationFeed && accountConfigurationFeed.length == 0 && !isCreationFeedMode &&
                                        <article className="tooling_message_empty">
                                        	<h2>No product feed for this tenant yet</h2>
                                        	<Btn
                                        		message="New feed"
                                        		size="xl"
                                        		onClickFunction={(e) => setIsCreationFeedMode(true)}
                                        	/>
                                        </article>
                                	}
                                	{
                                		isCreationFeedMode &&
                                        <CreationFeedContainer
                                        	newProductFeed={newProductFeed}
                                        	setNewProductFeed={(e) => setNewProductFeed(e)}
                                        	accessValue={accessValue}
                                        	setAccessValue={(e) => setAccessValue(e)}
                                        	arrayOfErrors={arrayOfErrors}
                                        	setArrayOfErrors={setArrayOfErrors}
                                        	metaDataToMap={metaDataToMap}
                                        	dataFields={dataFields}
                                        	setDataFields={(e) => setDataFields(e)}
                                        	productFeedTags={productFeedTags}
                                        	putSelectTags={(e) => putSelectTags(e)}
                                        	productFeedCategory={productFeedCategory}
                                        	setProductFeedCategory={(e) => setProductFeedCategory(e)}
                                        	errorsWhenParseCsv={errorsWhenParseCsv}
                                        />
                                	}
                                	{
                                		accountConfigurationFeed && activeFeed !== undefined &&
                                        <>
                                        	<EditSourceProductFeed
                                        		setIsConfigurationSourceMode={(e) => setIsConfigurationSourceMode(e)}
                                        		setNewProductFeed={(e) => setNewProductFeed(e)}
                                        		activeFeed={activeFeed}
                                        		accountConfigurationFeed={accountConfigurationFeed}
                                        		setAccountConfigurationFeed={(e) => setAccountConfigurationFeed(e)}
                                        		deleteProductFeed={() => deleteProductFeed()}
                                        		accessValue={accessValue}
                                        		setAccessValue={(e) => setAccessValue(e)}
                                        		setIsFirstRender={(e) => setIsFirstRender(e)}
                                        		refreshDataOfProductFeed={(e) => refreshDataOfProductFeed(e)}
                                        	/>
                                        	{
                                        		activeFeed && activeFeed.format === 'Custom' &&
                                                <div className="creation_feed_update_container">
                                                	<p className="creation-feed-custom-txt">  Custom source is configured</p>
                                                </div>
                                        	}
                                        	{
                                        		activeFeed && activeFeed.format !== 'Custom' &&
                                                <>
                                                	<div className="creation_feed_update_container">
                                                		{activeFeed && activeFeed.source !== 'OnSiteData' &&
                                                            <ConfigurationFieldsContainer
                                                            	metaDataToMap={metaDataToMap}
                                                            	dataFields={dataFields}
                                                            	activeFeed={activeFeed}
                                                            	accountConfigurationFeed={accountConfigurationFeed}
                                                            	handleChangeDataFields={(e) => handleChangeDataFields(e)}
                                                            />
                                                		}

                                                		{
                                                			activeFeed.source !== 'OnSiteData' &&
                                                            <>
                                                            	{
                                                            		activeFeed.source !== 'Prestashop' &&
                                                                    <SelectTagsContainer
                                                                    	metaDataToMap={metaDataToMap}
                                                                    	productFeedTags={productFeedTags}
                                                                    	putSelectTags={(e) => putSelectTags(e)}
                                                                    	putSelectTagsInput={(e) => putSelectTagsInput(e)}
                                                                    	activeFeed={activeFeed}
                                                                    />
                                                            	}
                                                            	<CategoryProductFeedContainer
                                                            		metaDataToMap={metaDataToMap}
                                                            		productFeedCategoryArr={productFeedCategory}
                                                            		setProductFeedCategory={(e) => setProductFeedCategory(e)}
                                                            		setChangesIsNotSaved={() => setChangesIsNotSaved(true)}
                                                            		activeFeed={activeFeed}
                                                            	/>
                                                            </>
                                                		}
                                                		{
                                                			activeFeed.source == 'OnSiteData' &&
                                                            <CreateTagsContainer
                                                            	activeFeed={activeFeed}
                                                            	productFeedTags={productFeedTags}
                                                            	listTags={listTags}
                                                            	setListTags={(e) => setListTags(e)}
                                                            	setChangesIsNotSaved={(e) => setChangesIsNotSaved(e)}
                                                            />
                                                		}
                                                	</div>
                                                </>

                                        	}</>}

                                	<div className="panel_footer panel_footer_right">
                                		{
                                			isCreationFeedMode &&
                                            <>
                                            	<Btn
                                            		onClickFunction={(e) => {
                                            			setIsCreationFeedMode(false); setIsConfigurationSourceMode(false); setErrorsWhenParseScv();
                                            			accountConfigurationFeed.length !== 0 &&
                                                            setActiveFeed({ source: accountConfigurationFeed[0].source.sourceType, format: accountConfigurationFeed[0].source.sourceFormat, index: accountConfigurationFeed[0].index });
                                            		}}
                                            		message="Cancel"
                                            		color="secondary"
                                            	/>
                                            	{
                                            		!importIsWork &&
                                                    <Btn
                                                    	onClickFunction={(e) => validTheFirstStepOfCreationProductFeed(accountConfigurationFeed)}
                                                    	message="Next Step"
                                                    />
                                            	}
                                            </>}
                                		{
                                			accountConfigurationFeed && accountConfigurationFeed.length !== 0 && !isCreationFeedMode && isConfigurationMode &&
                                            <>
                                            	<Btn
                                            		onClickFunction={(e) => putConfigProductFeed(accountConfigurationFeed)}
                                            		message="Save"
                                            	/>
                                            </>
                                		}

                                	</div>
                                </>
							}
							{isConfigurationSourceMode &&
                                <>
                                	<CreationFeedContainer
                                		newProductFeed={newProductFeed}
                                		isConfigurationSourceMode={isConfigurationSourceMode}
                                		setNewProductFeed={(e) => setNewProductFeed(e)}
                                		accessValue={accessValue}
                                		setAccessValue={(e) => setAccessValue(e)}
                                		arrayOfErrors={arrayOfErrors}
                                		setArrayOfErrors={setArrayOfErrors}
                                		metaDataToMap={metaDataToMap}
                                		dataFields={dataFields}
                                		setDataFields={(e) => setDataFields(e)}
                                		productFeedTags={productFeedTags}
                                		putSelectTags={(e) => putSelectTags(e)}
                                		productFeedCategory={productFeedCategory}
                                		setProductFeedCategory={(e) => setProductFeedCategory(e)}
                                		isFirstRender={isFirstRender}

                                	/>

                                	<div className="panel_footer panel_footer_right">
                                		<>
                                			<Btn
                                				onClickFunction={(e) => setIsConfigurationSourceMode(false)}
                                				message="Cancel"
                                				color="secondary"
                                			/>
                                			<Btn
                                				onClickFunction={(e) => changeTheConfigurationSourceFeed()}
                                				message="Save"
                                			/>
                                		</>
                                	</div>
                                </>}
							{hierarchizationFeedOpen &&
                                <HierarchizationProductFeedContainer
                                	setHierarchizationFeedOpen={(e) => setHierarchizationFeedOpen(e)}
                                	accountConfigurationFeed={accountConfigurationFeed}
                                	setAccountConfigurationFeed={(e) => setAccountConfigurationFeed(e)}
                                	setSaveTheHierarchieInBack={(e) => setSaveTheHierarchieInBack(e)}
                                	setActiveFeed={(e) => setActiveFeed(e)}
                                />
							}
							{
								isReloading &&
                                <div className="spinner_overlay">
                                	<div className="spinner">
                                		<div className="rect1"></div>
                                		<div className="rect2"></div>
                                		<div className="rect3"></div>
                                		<div className="rect4"></div>
                                		<div className="rect5"></div>
                                	</div>
                                </div>
							}

						</>
				}
			</Panel>
		</div >
	);
}
angular
	.module('beyableSaasApp.Dashboard.productFeed', ['beyableSaasApp.Admin.Services'])
	.component('productFeed', react2angular(ProductFeedContainers, [], ['$http', '$rootScope', '$routeParams', 'AuthServices']));