import HttpServices from './HttpServices';
import { EmailTemplateInListApiType, UpdateEmailTemplateApiType, EmailTemplateStatus, EmailTemplateSaaSFormatApiType, payloadExample, CreateEmailTemplateApiType, EmailTemplateDetailApiType } from './EmailTemplateServicesTypes';

export default class EmailTemplateServices {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;

	constructor(accountId: string, $http: any) {
		this.HttpServices = new HttpServices($http);
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	getEmailTemplates(
		callbackSuccess: ((emailTemplates: EmailTemplateInListApiType[]) => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/email-template`, callbackSuccess, callbackError);
	}
	getEmailTemplateById(emailTemplateId: string,
		callbackSuccess: ((success: EmailTemplateDetailApiType) => void),
		callbackError: ((err: any) => void)): void {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/email-template/${emailTemplateId}`, callbackSuccess, callbackError);
	}

	updateEmailTemplateStatus(emailTemplateId: string, emailTemplateStatus: EmailTemplateStatus,
		callbackSuccess: (() => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/email-template/${emailTemplateId}`, { status: emailTemplateStatus }, callbackSuccess, callbackError);
	}

	updateEmailTemplate(emailTemplateId: string, emailTemplate: UpdateEmailTemplateApiType,
		callbackSuccess: (() => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/email-template/${emailTemplateId}`, emailTemplate, callbackSuccess, callbackError);
	}

	deleteEmailTemplate(emailTemplateId: string,
		callbackSuccess: ((success: string) => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.delete(`${this.BO_API_URL}${this.accountId}/email-template/${emailTemplateId}`, callbackSuccess, callbackError);
	}

	createEmailTemplate(emailTemplate: CreateEmailTemplateApiType,
		callbackSuccess: (() => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/email-template`, emailTemplate, callbackSuccess, callbackError);
	}

	getEmailTemplateSaaSFormats(
		callbackSuccess: ((emailTemplateSaaSFormats: EmailTemplateSaaSFormatApiType[]) => void),
		callbackError: ((error: string) => void)): void {

		//const useFake = Math.random() >= 0;
		const useFake = false;
		if (useFake) {
			callbackSuccess(payloadExample);

		} else {
			this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/email-template-saasformat`, callbackSuccess, callbackError);
		}
	}

	// ne pas passer emailTemplateId mais plutôt BaseTemplateId + EmailTemplateRecommendationConfiguration
	generateEmailTemplateOutput(emailTemplateId: string,
		callbackSuccess: ((result: string) => void),
		callbackError: ((error: string) => void)) {

		// const useFake = Math.random() >= 0;
		const useFake = false;
		if (useFake) {
			console.log('here');
			callbackSuccess('fake result');
		}
		else {
			this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/email-template/${emailTemplateId}/generate`, {}, callbackSuccess, callbackError);
		}
	}
}
