import React, { useState, useEffect, Fragment } from 'react';
import Btn from './../../../Components/Btn';
import InputCopyToClipBoard from './../../../Components/InputCopyToClipBoard';
import FilesAttachmentContainer from './FilesAttachmentContainer';
import FileAttachmentForm from './FileAttachmentForm';
import Modal from './../../../Components/Modal';
import ModalHeader from './../../../Components/ModalHeader';
import ModalBody from './../../../Components/ModalBody';
import ModalFooter from './../../../Components/ModalFooter';
import InputCustom from './../../../Components/InputCustom';
import PopinConfirm from './../../../Components/PopinConfirm';
import Switch from './../../../Components/Switch';
export default function EditConfigEmailRemarketing({ isCreationMode, setIsCreationMode, setAccountCurrentTab, postConfigEmailRemarketingById, accountId, campaignId, getConfigEmailRemarketingById, getParameterConfigurationByOperation, putConfigEmailRemarketing, postEmailRemarketingTest, campaignName }) {
	const providersWithSenderInfo = ['Postmark', 'Tipimail'];

	const [formData, setFormData] = useState({
		provider: '',
		SenderName: '',
		SenderEmail: '',
		campaignName: ''
	});
	const [valueOfProvider, setValueOfProvider] = useState('');
	const [statusProvider, setStatusProvider] = useState();
	const [isExistant, setIsExistant] = useState(false);
	const [sendMailtest, setSendMailTest] = useState('');
	const [isReloading, setIsRealoading] = useState(false);
	const [isEditMode, setEditMode] = useState(false);
	const [isTestMode, setTestMode] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isNotValid, setIsNotValid] = useState([]);
	const [clipBoardContain, setClipBoardContain] = useState(false);
	const [fileAttachments, setFileAttachments] = useState([]);
	const [fileAttachmentIsChange, setFileAttachmentIsChange] = useState(false);
	const [fileSelected, setFileSelected] = useState();
	const [fileValueToSet, setFileValueToSet] = useState();
	const [modalIsOppen, setModalIsOppen] = useState(false);
	const [poppinIsOppen, setPoppinIsOppen] = useState(false);
	const [isNewFile, setIsNewFile] = useState(false);
	//SendEmailRemarketingTestMailCommand
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
	};
	const handleChangeForm = (e) => {
		e.persist();
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let prevState = { ...formData };
		prevState[name] = value;
		setFormData(prevState);
	};
	const handleChangeValueSelect = (e) => {
		const value = e.target.value;
		if (providersWithSenderInfo.includes(value)) {
			setValueOfProvider(value);
			setFormData({
				provider: value,
				SenderName: '',
				SenderEmail: ''
			});
		}
		else {
			setValueOfProvider(value);
		}
	};
	useEffect(() => {
		if (valueOfProvider !== '') {
			let prevState = { ...formData };
			prevState.provider = valueOfProvider;
			setFormData(prevState);
		}
	}, [valueOfProvider]);
	useEffect(() => {
		if (!isReloading && !isSubmit) {
			if (isCreationMode) {
				setValueOfProvider('Mandrill');
				setFormData({
					provider: 'Mandrill',
				});
			} else {
				// faire l'appel à l'api 
				setIsRealoading(true);
				getConfigEmailRemarketingById(accountId, campaignId, data => {
					setFormData(data.config);
					setValueOfProvider(data.config.EmailProvider);
					setStatusProvider(data.config.IsActive);
					setIsExistant(true);
					let filesWithIds = [];
					const array = [...data.config.FileAttachments];
					if (array.length !== 0) {
						filesWithIds = array.map((x, i) => ({ ...x, id: i }));
					}
					setFileAttachments(filesWithIds);
					setIsRealoading(false);
				}, error => {
					setIsRealoading(false);
				});
			}
		}
	}, [isSubmit]);
	const submitTheForm = (e) => {
		e.preventDefault();
		setIsRealoading(true);
		setIsSubmit(true);
		if (isCreationMode) {
			let provider = formData;
			delete provider.IsActive;
			JSON.stringify(provider);
			postConfigEmailRemarketingById(accountId, campaignId, provider, data => {
				setIsCreationMode(false);
				getParameterConfigurationByOperation(1);
				setIsRealoading(false);
				setIsSubmit(false);
			}, error => {
				console.log('error', error);
				setIsRealoading(false);
				setIsSubmit(false);
			});
		} else {
			let provider = {
				updateIsActive: formData.IsActive
			};
			if (fileAttachmentIsChange) {
				provider.updateFileAttachments = fileAttachments;
			} else {
				provider.updateFileAttachments = null;
			}
			JSON.stringify(provider);
			putConfigEmailRemarketing(accountId, campaignId, provider, data => {
				setIsRealoading(false);
				setEditMode(false);
				getParameterConfigurationByOperation(1);
				setIsSubmit(false);
			}, error => {
				console.log('error', error);
				setIsRealoading(false);
				setIsSubmit(false);
			});
		}
	};
	function validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	const submitFormWithSenderInfo = (e) => {
		e.preventDefault();
		setIsSubmit(true);
		if (validateEmail(formData.SenderEmail) && formData.SenderName.length > 0) {
			setIsNotValid([]);
			setIsRealoading(true);
			if (isCreationMode) {
				let provider = formData;
				provider.sender = { email: formData.SenderEmail, name: formData.SenderName };
				delete provider.IsActive;
				delete provider.SenderEmail;
				delete provider.SendetName;
				JSON.stringify(provider);
				postConfigEmailRemarketingById(accountId, campaignId, provider, data => {
					getParameterConfigurationByOperation(1);
					setIsCreationMode(false);
					setIsRealoading(false);
					setIsSubmit(false);
				}, error => {
					setIsRealoading(false);
				});
			} else {
				let provider = {
					updateIsActive: formData.IsActive,
					updateSender: { email: formData.SenderEmail, name: formData.SenderName }
				};
				if (fileAttachmentIsChange) {
					provider.updateFileAttachments = fileAttachments;
				} else {
					provider.updateFileAttachments = null;
				}
				JSON.stringify(provider);
				putConfigEmailRemarketing(accountId, campaignId, provider, data => {
					getParameterConfigurationByOperation(1);
					setEditMode(false);
					setIsRealoading(false);
					setIsSubmit(false);
				}, error => {
					setIsSubmit(false);
				});
			}
		} else {
			let newArrayOfErrors = [];
			if (!validateEmail(formData.SenderEmail)) {
				newArrayOfErrors.push('SenderEmail');
			}
			if (!formData.SenderName.length > 0) {
				newArrayOfErrors.push('SenderName');
			}
			setIsNotValid(newArrayOfErrors);
		}
	};
	const sendTestMail = () => {
		if (validateEmail(sendMailtest)) {
			setIsRealoading(true);
			let recipient = { RecipientEmail: sendMailtest };
			JSON.stringify(recipient);
			postEmailRemarketingTest(accountId, campaignId, recipient, data => {
				setIsRealoading(false);
				setEditMode(false);
				setTestMode(false);
			}, error => {
				setIsRealoading(false);
			});
		} else {
			let newArrayOfErrors = [];
			if (!validateEmail(sendMailtest)) {
				newArrayOfErrors.push('sendMailtest');
			}
			setIsNotValid(newArrayOfErrors);
		}
	};
	const handleChangeSendMAil = (e) => {
		e.persist();
		setSendMailTest(e.target.value);
	};

	const addFileAttachment = (e) => {
		e.preventDefault();
		setModalIsOppen(true);
		setFileAttachmentIsChange(true);
		const array = [...fileAttachments];
		let newId = array.length + 1;
		const getNewId = () => {
			let ids = [];
			let number;
			if (array.length !== 0) {
				array.forEach(x =>
					ids.push(x.id));
				number = parseInt(Math.max(...ids) + 1);
			} else {
				number = array.length + 1;
			}
			if (ids.includes(number)) {
				number = array.length;
			}
		};
		getNewId();
		const newFile =
		{
			id: newId,
			name: '',
			type: '',
			filepath: ''
		};
		const newArrayOfFiles = ([...array, newFile]);
		setFileAttachments(newArrayOfFiles);
		setFileSelected(newFile);
		setIsNewFile(true);
	};
	const selectFile = (e) => {
		setFileSelected(e);
		setModalIsOppen(true);
	};
	const saveChangeFilesAttachmentFilesNewArray = (e) => {
		setFileAttachmentIsChange(true);
		const prevArray = [...fileAttachments];
		let itemToChange = prevArray.filter(item => item.id === e.id)[0];
		itemToChange = e;
		const arrayToPut = prevArray.filter(item => item.id !== e.id);
		setFileAttachments([...arrayToPut, itemToChange]);
		setModalIsOppen(false);
		setFileValueToSet();
		setFileSelected();
		setIsNewFile(false);
	};
	const deleteAnFileFromArrayOfFiles = (x) => {
		let array = [...fileAttachments];
		array = array.filter(element => element !== x);
		setFileAttachments(array);
		setPoppinIsOppen(false);
	};
	const userClickOnDeleteFile = (e) => {
		setFileSelected(e);
		setPoppinIsOppen(true);
	};
	const reFreshData = () => {
		setIsRealoading(true);
		getConfigEmailRemarketingById(accountId, campaignId, data => {
			setFormData(data.config);
			setValueOfProvider(data.config.EmailProvider);
			setStatusProvider(data.config.IsActive);
			setIsExistant(true);
			let filesWithIds = [];
			const array = [...data.config.FileAttachments];
			if (array.length !== 0) {
				filesWithIds = array.map((x, i) => ({ ...x, id: i }));
			}
			setFileAttachments(filesWithIds);
			setIsRealoading(false);
		}, error => {
			setIsRealoading(false);
		});
	};
	return (
		<div className="config_form_container">
			{poppinIsOppen &&
				<PopinConfirm
					cancel={() => setPoppinIsOppen(false)}
					title={'Are you sure you want to delete this file ?'}
					message={''}
				>
					<Btn
						onClickFunction={(e) => { setPoppinIsOppen(false); }}
						message="No"
						color="secondary"
						style="outline"
					/>
					<Btn
						onClickFunction={(e) => { deleteAnFileFromArrayOfFiles(fileSelected); }}
						message="Yes"
						color="alert"
					/>
				</PopinConfirm>
			}
			<Modal
				isOpen={modalIsOppen}
				width="600"
				onClose={isNewFile ? () => { setModalIsOppen(false); deleteAnFileFromArrayOfFiles(fileSelected); setIsNewFile(false); } : () => { setModalIsOppen(false); }}
			>
				<div className="modal_header has_border">
					New file
				</div>
				<div className="modal_body">
					<FileAttachmentForm
						file={fileSelected}
						setFileValueToSet={(e) => setFileValueToSet(e)}
					/>
				</div>
				<div className="modal_footer">
					<Btn
						onClickFunction={isNewFile ? () => { setModalIsOppen(false); deleteAnFileFromArrayOfFiles(fileSelected); setIsNewFile(false); } : () => { setModalIsOppen(false); }}
						message="Cancel"
						color="secondary"
						style="outline"
						size="l"
					/>
					<Btn
						onClickFunction={(e) => saveChangeFilesAttachmentFilesNewArray(fileValueToSet)}
						message="Save"
						style="primary"
						size="l"
					/>
				</div>
			</Modal>

			{!isReloading &&
				<Fragment>
					{isCreationMode &&
						<Fragment>
							<div className="section l section_grey has_top_border">
								<div className="email_provider flex">
									<a className="icon_btn icon_btn_color"
										title="Back"
										onClick={
											!formData.SubAccount ? (event) => { event.preventDefault(); setAccountCurrentTab(0); } :
												() => setIsCreationMode(false)
										}><i className="fas fa-arrow-left"></i></a>
									<div className="email_provider_name">Select a provider</div>
								</div>
							</div>
							<div className="section l has_top_border">
								<div className="form_block">
									<label htmlFor="Provider" className="form_block_label">Provider</label>
									<select name="provider" id="" className="select-provider" onChange={(e) => handleChangeValueSelect(e)} value={valueOfProvider} >
										<option value="Mandrill"> Mandrill</option>
										<option value="SparkPost"> SparkPost</option>
										<option value="Postmark"> PostMark</option>
										<option value="Tipimail"> Tipimail</option>
									</select>
								</div>
								{providersWithSenderInfo.includes(valueOfProvider) &&
									<Fragment>
										<div className="form_block">
											<label htmlFor="SenderName" className="form_block_label">Sender Name</label>
											<input type="text"
												name="SenderName"
												onChange={(e) => handleChangeForm(e)}
												value={formData.SenderName ? formData.SenderName : ''} />
											{isNotValid.includes('SenderName') && <p className="information_error_message">The Sender Name is empty</p>}
										</div>
										<div className="form_block">
											<label htmlFor="SenderEmail" className="form_block_label">Sender Email</label>
											<input
												type="text"
												name="SenderEmail"
												onChange={(e) => handleChangeForm(e)}
												value={formData.SenderEmail ? formData.SenderEmail : ''} />
											{isNotValid.includes('SenderEmail') && <p className="information_error_message">The Email is not valid</p>}
										</div>
									</Fragment>
								}
								<Btn
									onClickFunction={
										!isExistant ? (event) => { event.preventDefault(); setAccountCurrentTab(0); } :
											() => { reFreshData(); setIsCreationMode(false); }
									}
									message="Cancel"
									color="secondary"
									style="outline"
								/>
								{
									!providersWithSenderInfo.includes(valueOfProvider) ?
										<Btn
											onClickFunction={(e) => { e.preventDefault(); submitTheForm(e); }}
											message="Submit"
											style="primary"
										/>
										:
										<Btn
											onClickFunction={(e) => { e.preventDefault(); submitFormWithSenderInfo(e); }}
											message="Submit"
											style="primary"
										/>
								}
							</div>
						</Fragment>
					}

					{isEditMode &&
						<Fragment>
							<div className="section l section_grey has_top_border">
								<div className="email_provider flex">
									<a className="icon_btn icon_btn_color"
										title="Back"
										onClick={(event) => { event.preventDefault(); setEditMode(false); }}><i className="fas fa-arrow-left"></i></a>
									<div className="email_provider_name">Provider settings</div>
								</div>
							</div>
							<div className="section l has_top_border">
								<div className="form_block">
									<Switch
										value={formData.IsActive}
										name="IsActive"
										checked={formData.IsActive}
										onChange={(e) => handleChangeForm(e)}
									>
										{formData.IsActive ? 'Active' : 'Disabled'}
									</Switch>
								</div>
								{
									providersWithSenderInfo.includes(valueOfProvider) &&
									<Fragment>
										<div className="form_block">
											<label htmlFor="SenderName" className="form_block_label">Sender Name</label>
											<input type="text"
												name="SenderName"
												onChange={(e) => handleChangeForm(e)}
												value={formData.SenderName ? formData.SenderName : ''} />
											{isNotValid.includes('SenderName') && <p className="information_error_message">The Sender Name is empty</p>}
										</div>
										<div className="form_block">
											<label htmlFor="SenderEmail" className="form_block_label">Sender Email</label>
											<input
												type="text"
												name="SenderEmail"
												onChange={(e) => handleChangeForm(e)}
												value={formData.SenderEmail ? formData.SenderEmail : ''} />
											{isNotValid.includes('SenderEmail') && <p className="information_error_message">The Email is not valid</p>}
										</div>
									</Fragment>
								}
								<div className="form_block">
									<lable className="form_block_label">File Attachments</lable>
									<FilesAttachmentContainer
										fileAttachments={fileAttachments}
										addFileAttachment={(e) => addFileAttachment(e)}
										selectFile={(e) => selectFile(e)}
										userClickOnDeleteFile={(e) => userClickOnDeleteFile(e)}
									/>
								</div>
								<Btn
									onClickFunction={(event) => { event.preventDefault(); setEditMode(false); }}
									message="Cancel"
									color="secondary"
									style="outline"
								/>
								{
									!providersWithSenderInfo.includes(valueOfProvider) ?
										<Btn
											onClickFunction={(e) => { e.preventDefault(); submitTheForm(e); }}
											message="Submit"
											style="primary"
										/>
										:
										<Btn
											onClickFunction={(e) => { e.preventDefault(); submitFormWithSenderInfo(e); }}
											message="Submit"
											style="primary"
										/>
								}
							</div>
						</Fragment>
					}

					{!isCreationMode && !isEditMode &&
						<Fragment>
							<div className="section section_grey l has_top_border">
								<div className="flex">
									<div className="flex_item_full">
										<div className="email_provider">
											<span className="email_provider_name">{formData.EmailProvider}</span>
											{statusProvider ?
												<span className="tooling_status ongoing">Active</span>
												:
												<span className="tooling_status stopped">Disabled</span>
											}
											<div className="email_provider_change">
												<a onClick={(event) => { setIsCreationMode(true); setEditMode(false); }}>
													Change provider
												</a>
											</div>
										</div>
									</div>
									<div className="flex_item_fix">
										<Btn
											onClickFunction={() => setEditMode(true)}
											message="Settings"
											style="outline"
											color="secondary"
										/>
										<Btn
											onClickFunction={() => setTestMode(true)}
											icon="fas fa-paper-plane"
											message="Send test"
										/>
									</div>
								</div>
							</div>
							<div className="section l has_top_border">
								<div className="form_block">
									<label className="form_block_label">Template Name</label>
									<div className="config_input_copy">
										<InputCopyToClipBoard
											clipBoardContain={clipBoardContain}
											onClickFunction={(e) => copyToClipBoard(e)}
											value={formData.TemplateName}
											fullSize={true}
										/>
									</div>
								</div>
								<div className="form_block">
									<label className="form_block_label">Sub account</label>
									<div className="config_input_copy">
										<InputCopyToClipBoard
											clipBoardContain={clipBoardContain}
											onClickFunction={(e) => copyToClipBoard(e)}
											value={formData.SubAccount}
											fullSize={true}
										/>
									</div>
								</div>
								{
									providersWithSenderInfo.includes(valueOfProvider) &&
									<Fragment>
										<div className="form_block">
											<label htmlFor="SenderName" className="form_block_label">Sender Name</label>
											<input type="text"
												name="SenderName"
												readOnly
												value={formData.SenderName ? formData.SenderName : ''} />
										</div>
										<div className="form_block">
											<label htmlFor="SenderEmail" className="form_block_label"> SenderEmail</label>
											<input
												type="text"
												name="SenderEmail"
												readOnly
												value={formData.SenderEmail ? formData.SenderEmail : ''} />
										</div>
									</Fragment>
								}
							</div>
							<Modal
								isOpen={isTestMode}
								onClose={(e) => { setTestMode(false); }}>
								<ModalHeader
									title="Send test"
								/>
								<ModalBody>
									<InputCustom
										label="Email address"
										name="sendMailtest"
										onChange={(e) => handleChangeSendMAil(e)}
										value={sendMailtest ? sendMailtest : ''}
										isAnError={isNotValid.includes('sendMailtest') ? 'This email address is not valid' : ''}
										fullWidth={true}
										placeHolder='email@exemple.com'
										onEnter={sendTestMail}
									/>
								</ModalBody>
								<ModalFooter
									primaryAction={
										<Btn
											onClick={sendTestMail}
											message="Send"
											icon="fas fa-paper-plane"
										/>
									}
									secondaryAction={
										<Btn
											onClick={() => setTestMode(false)}
											message="Cancel"
											style="ghost"
											color="secondary"
										/>
									}
								/>
							</Modal>
						</Fragment>
					}
				</Fragment>
			}
			{
				isReloading &&
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			}
		</div>
	);
}
