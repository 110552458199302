import React, { useState, useEffect } from 'react';

import Nav from '../Nav/Nav';

export default function NavPerfomance({
	ComparisonSelected,
	setNavFilter,
	initialDates,
	handleSelectComparaisonPeriodHandler,
	isCustomSelected,
	setisCustomSelected,
	customDateRange,
	setCustomDateRange,
}) {

	function handleClickOriginal(obj) {
		setNavFilter(stateInit => ({
			...stateInit,
			fromDate: obj.fromDate,
			toDate: obj.toDate
		}
		));
	}
	function handleClickCompared(obj) {
		setNavFilter(stateInit => ({
			...stateInit,
			fromDateToCompare: obj.fromDate,
			toDateToCompare: obj.toDate
		}
		));
	}
	return (<>
		<Nav
			handleClick={handleClickOriginal}
			initialDates={initialDates}
			showComparisonToggler={false}
			setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
			comparisonSelected={ComparisonSelected}
			setisCustomSelected={setisCustomSelected}
			isCustomSelected={isCustomSelected}
			customDateRange={customDateRange}
			setCustomDateRange={setCustomDateRange}
		/>
		{ComparisonSelected &&
			<Nav
				initialLabel={'initialLabel2'}
				handleClick={handleClickCompared}
				initialDates={initialDates}
				navLabel="Compared to"
				showComparisonRemover={true}
				setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
				comparisonSelected={ComparisonSelected}
			/>
		}
	</>
	);
}
