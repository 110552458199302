import React, { useState ,useContext,useEffect} from 'react';
import useEventBroker from '../../../../Hooks/useEventBroker';
import Modal from '../../../../Components/Modal';
import CropContainer from '../Design/WidgetCropResize/CropContainer';
import TwicContainer from '../../../TwicPics/TwicContainer';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';

export default function CropModal({accountId, businessOperationServices, systemServices}) {
	const {
		selectedZone,
		bannerSelected,
		operation
	} = useContext(CreateBusinessOperationContext);
	const { publish } = useEventBroker();

	const [isLoading, setIsLoading] = useState(false);
	const [widgetOpened, setWidgetOpened] = useState(false);
	const [bannerSelectedInComponent, setBannerSelectedInComponent] = useState();
	const [idOfBanner, setidOfBanner] = useState();
	const [widgetType, setwidgetType] = useState('Cloudinary');
	const { subscribe } = useEventBroker();
	
	subscribe('evt/businessoperations/cropRequested', e => {
		const { banner } = e.detail;
		setBannerSelectedInComponent(banner);
		setwidgetType('Cloudinary');
		setWidgetOpened(true);
	});
	subscribe('evt/businessoperations/cropRequestedWithTwicPics', e => {
		const { banner } = e.detail;
		setBannerSelectedInComponent(banner);
		setwidgetType('TwicPics');
		setWidgetOpened(true);
	});
	useEffect(() => {
		if (bannerSelectedInComponent) {
			setidOfBanner();
			if (typeof bannerSelectedInComponent.metaData.sourceId === 'string') {
				setidOfBanner(bannerSelectedInComponent.metaData.sourceId);
			} else if (typeof bannerSelectedInComponent.metaData.transformationSourceId === 'string') {
				setidOfBanner(bannerSelectedInComponent.metaData.transformationSourceId);
			}
		}
	}, [bannerSelectedInComponent]);
	const exportImage = (exportedImage) => {		
		fetch(exportedImage).
			then(function (response) {
				return response.blob();
			})
			.then(function (myBlob) {
				let img = document.createElement('img');
				let blob = URL.createObjectURL(myBlob);
				img.src = blob;
				img.onload = function() {
					var w = img.width;
					var h = img.height;
					const bytes = myBlob.size;
					const imageTransform = {
						url: exportedImage,
						bytes: bytes,
						fileName: bannerSelectedInComponent.metaData.fileName,
						height: h,
						width: w,
						sourceId: null,
						transformationSourceId:idOfBanner
					};
	
					const libraryId = operation.operation.libraryId;
					businessOperationServices.postTransformationsbannerToLibrary(accountId, libraryId, [imageTransform],
						response => {
							const bannerJustCreated = response.createdBanners[0];
							setIsLoading(false);
							if (bannerJustCreated) {
								setTimeout(() => {
									publish('evt/businessoperations/bannerCropped', { newBannerList: response.banners, croppedBanner: bannerJustCreated });
								}, 200);
								setWidgetOpened(false);
							} else {
								systemServices.showError('An error occured while adding your image to the library');
								setWidgetOpened(false);
							}
						},
						error => {
							setIsLoading(false);
							systemServices.showError('An error occured while adding your image to the library');
							setWidgetOpened(false);
						});
				};

			});
	};
	return (
		<Modal
			isOpen={widgetOpened}
			width="1200"
			height={widgetType === 'Cloudinary' ? 900 : 800}
			noClose={false}
			onClose={(e) => setWidgetOpened(false)}
		>
			{widgetType === 'Cloudinary' &&
				<>
					<div className="modal_header has_border">
						Select a new size for the banner (ratio is preserved based on the selected zone)
					</div>
					<div className="modal_body no_vertical_pad" id="cropSize">
						<CropContainer
							businessOperationServices={businessOperationServices}
							systemServices={systemServices}
							accountId={accountId}
							banner={bannerSelectedInComponent}
							onExported={() => setWidgetOpened(false)}
						/>
					</div>
				</>
			}
			{widgetType === 'TwicPics' &&
				<TwicContainer 
					selectedZone={{locked: true,...selectedZone}}
					imgSelected={bannerSelected}
					exportImage={exportImage}
					onClose={(e) => setWidgetOpened(false)}
				/>
			}
			{isLoading &&
				<div className="mask">
					<div className="wheel"></div>
				</div>
			}
		</Modal>
	);

}
