import React, { } from 'react';
import { react2angular } from 'react2angular';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import BusinessOperationServices from '../../../Services/BusinessOperationServices';
import SystemServices from '../../../Services/SystemServices';
import BusinessOperationOrchestrationContextProvider from './Context';
import OrchestrationContainer from './OrchestrationContainer';

export default function OrchestrationContainerWithContext(props) {
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const opService = new BusinessOperationServices(props.$http, props.AuthServices);
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope && props.$rootScope.User.Account.Key;

	const err = (message, callbackError) => error => {
		// console.log(message, error);
		systemServices.showError(message);
		callbackError && callbackError(error);
	};

	const genericErr = (callbackError) => {
		err('an error occurred, please contact your CSM', callbackError);
	};

	const showSuccessAndContinue = (res, callbackSuccess) => {
		systemServices.showSuccess('Your orchestration has been saved');
		callbackSuccess(res);
	};

	const getZones = (pageType, callbackSuccess) => opService.getZones(accountId, pageType, callbackSuccess, genericErr());
	const getPagesTypes = (callbackSuccess) => opService.getPagesTypes(accountId, callbackSuccess, genericErr());
	const getOperationsForZone = (zoneId, callbackSuccess) => opService.getOperationsForZone(accountId, zoneId, callbackSuccess, genericErr());
	const getOrchestrationForZone = (zoneId, callbackSuccess) => opService.getOrchestrationForZone(accountId, zoneId, callbackSuccess, genericErr());
	const createOrUpdateOrchestrationForZone = (zoneId, operations, callbackSuccess, callbackError) =>
		opService.createOrUpdateOrchestrationForZone(accountId, zoneId, { strategies: operations.map(o => ({ operationId: o.operationId })) },
			res => showSuccessAndContinue(res, callbackSuccess),
			(e) => {
				callbackError && callbackError();
				if (operations.length == 1) {
					err('Cannot save because the operation is already in an orchestration')(e);
				} else {
					err('Cannot save because one of the operations is already in an orchestration')(e);
				}
			},
			genericErr(callbackError)
		);
	const recomputeAllOrchestrations = (callbackSuccess) => opService.recomputeAllOrchestrations(accountId, (res) => {
		systemServices.showSuccess('Your orchestrations have been recomputed');
		callbackSuccess(res);
	}, genericErr());

	const wurthAccountId = 'da03b450-0785-4d64-ba79-4bfe61a6d324';
	const shouldAllowRecomputeAllOrchestrations = () => accountId === wurthAccountId;

	return (
		<BusinessOperationOrchestrationContextProvider
			getZones={getZones}
			rootScope={props.$rootScope}
			getPagesTypes={getPagesTypes}
			getOperationsForZone={getOperationsForZone}
			getOrchestrationForZone={getOrchestrationForZone}
			createOrUpdateOrchestrationForZone={createOrUpdateOrchestrationForZone}
			routeParams={props.$routeParams}
			recomputeAllOrchestrations={recomputeAllOrchestrations}
			shouldAllowRecomputeAllOrchestrations={shouldAllowRecomputeAllOrchestrations}
		>
			<OrchestrationContainer>

			</OrchestrationContainer>
		</BusinessOperationOrchestrationContextProvider>
	);
}

angular.module('beyableSaasApp.BusinessOperationsOrchestration', ['beyableSaasApp.Admin.Services'])
	.component('businessoperationorchestration', react2angular(OrchestrationContainerWithContext, [], ['$http', '$rootScope', '$timeout', '$routeParams', 'AuthServices']));











