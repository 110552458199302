import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @flow
import { useEcommerceOverviewContext } from '../../../context/EcommerceOverviewContext';
import {useEcommerceAnalyticsContext} from '../../../context/EcommerceAnalyticsContext';
import UtmFiltersOverwiewContainer from './UtmFiltersOverviewContainer.tsx';
import SourceTypeFilterSimple from '../../SourceType/SourceTypeFilterSimple';
import { ChipContainer } from '../ChipContainer';
import OverviewBavContainer from '../../Overview/OverviewBavContainer';

import NavOverview from '../../Overview/NavOverview';
import OverviewChartContainer from '../../ChartContainer/OverviewChartContainer';
import { OverviewCardContainer } from './OverviewCardContainer';
import SelectDevice from '../../Devices/SelectDevice';
import SourceTypeFilter from '../../SourceType/SourceTypeFilter';
// import {

// } from '../../Overview/config';
const getDimensions = () => {
	return [
		{ label: 'Product', key: 'Product', checked: true },
		{ label: 'Category', key: 'Category', checked: true },
		{ label: 'Brand', key: 'Brand', checked: true },
		
	];
};
export default function OverviewContainer({
	overviewServices,
	systemServices,
	sessionService,
	styles,
}) {
	const {
		// Tab state & utils
		activeSubDimensionTab,
		changeActiveSubDimensionTab,
	} = useEcommerceAnalyticsContext();

	const {
		//utils
		initiNavDate,
		accountId,
		navFilter,
		setNavFilter,
		ComparisonSelected,
		handleSelectComparaisonPeriodHandler,
		onDeleteChip,
		chips,
		sourceType,
		sourceTypes,
		handleChangeSourceType,
		setNumberOfSessions,
		setNumberOfSessions2,
		setTotalsPeriode1,
		setTotalsPeriode2,
		useTimeDisabled,
		setUseTimeDisabled,
		addFilter,
		onCardClick,
		removeFilter,
		selectedTenant,
		metricTypeOverviewSelected,
		setisCustomSelected,
		isCustomSelected,
		customDateRange,
		setCustomDateRange,
		devices,
		deviceSelected,
		handleChangeDeviceSelected,
		resetDevices,
		resetSource,
		updateStorage,
		resetUtmSelected,
		handleSelectUtmDimension,
		hansleSaveUtmDimensionFilter
	} = useEcommerceOverviewContext();
	let initialDates = initiNavDate(navFilter);
	const [dimensionList, setDimensionList] = useState(getDimensions());
	const [selectedTimeGranularity, setSelectedTimeGranularity] =
		useState('Day');

	
	const dimensions = getDimensions();
	const localConfig = [
		{ label: 'None', value: 'None' },
		...dimensions.map((d) => ({ label: d.label, value: d.key })),
	];
	const [selectedDimension, setSelectedDimension] = useState({
		splitDimension: localConfig[0],
		useTime: true,
	});
	
	if (activeSubDimensionTab !== 'overview') {
		return <></>;
	}

	return (<div className="flex_item_full">
		<div className="analytics_main_line">
			<NavOverview
				// ComparisonSelected={ComparisonSelected}
				setNavFilter={setNavFilter}
				initialDates={initialDates}
				handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
				setisCustomSelected={setisCustomSelected}
				isCustomSelected={isCustomSelected}
				customDateRange={customDateRange}
				setCustomDateRange={setCustomDateRange}
				updateStorage={updateStorage}
			/>
			<SourceTypeFilter
				sourceTypeSelected={sourceType}
				handleChangeSourceType={handleChangeSourceType}
				initialValue={sourceTypes}
				resetSource={resetSource}
			/>
			<SelectDevice
				resetSource={resetDevices}
				initialValue={devices}
				devices={devices}
				deviceSelected={deviceSelected}
				handleChangeDeviceSelected={handleChangeDeviceSelected}
			/>
			<UtmFiltersOverwiewContainer
				overviewServices={sessionService}
			/>
			{chips && <ChipContainer
				chips={[chips]}
				onDelete={onDeleteChip}
			/>}
		</div>
		<div className="analytics_body">
			<div className="analytics_body_main">
				<OverviewBavContainer
					navFilter={navFilter}
					service={overviewServices}
					styles={styles}
					setNumberOfSessions={setNumberOfSessions}
					setNumberOfSessions2={setNumberOfSessions2}
					ComparisonSelected={ComparisonSelected}
					setTotalsPeriode1={setTotalsPeriode1}
					setTotalsPeriode2={setTotalsPeriode2}
					deviceSelected={deviceSelected}
				/>
				<OverviewChartContainer
					navFilter={navFilter}
					service={overviewServices}
					accountId={accountId}
					localConfig={localConfig}
					selectedDimension={selectedDimension}
					setSelectedDimension={setSelectedDimension}
					useTimeDisabled={useTimeDisabled}
					setUseTimeDisabled={setUseTimeDisabled}
					selectedTimeGranularity={selectedTimeGranularity}
					setSelectedTimeGranularity={setSelectedTimeGranularity}
					systemServices={systemServices}
				/>
			</div>
			<div className="analytics_body_side">
				<OverviewCardContainer
					service={overviewServices}
					styles={styles}
					dimensions={dimensionList}
					selectedTenant={selectedTenant}
				/>
			</div>
		</div>
	</div>
	);
}
OverviewContainer.propTypes = {
	overviewServices: PropTypes.object.isRequired,
	// example : PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired
};