import React, { useState, useEffect, useContext } from 'react';

import { Context } from './Context/Context';
import './ListOfTagBeyableOnAccount.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export default function ListOfTagBeyableOnAccount(listTagAccount) {
	const animatedComponents = makeAnimated();
	const {
		valueSelectBeyableTag,
		setValueSelectBeyableTag,
	} = useContext(Context);
	const [defaultSelect, setdefaultSelect] = useState();
	const newArray = [];

	listTagAccount && listTagAccount.listTagAccount.forEach(val =>
		newArray.push({ value: val.tagName, label: val.tagName }));

	useEffect(() => {
		const newSelects = valueSelectBeyableTag.map(val =>
			({ value: val, label: val }));
		setdefaultSelect(newSelects);

	}, [valueSelectBeyableTag, listTagAccount]);

	const changeValue = (e) => {
		const value = e.target.value;
		setValueSelectBeyableTag(value);
	};
	const handleSelectChange = (e) => {
		setdefaultSelect(e);
		const newValues = e ? e.map(el => el.value) : [];
		setValueSelectBeyableTag(newValues);
	};

	return (
		<div className="containerProuctTagsOnAccount">
			{/* <label htmlFor="selectOption"> </label>
            <select
                className="selectOptionTags"
                id="selectOption"
                onChange={(e) => changeValue(e)}
                name="selectOption"
                value={valueSelectBeyableTag ? valueSelectBeyableTag : 'choose'}
                // defaultValue='choose'
                style={{ minHeight: '40px', border: '1px solid #ccc', color: 'grey', appearance: 'none' }}
            >
                <option value='choose' disabled hidden>Choose a Beyable Tag</option>

                {listTagAccount ? listTagAccount.listTagAccount.map(x => <option key={x.tagName} value={x.tagName}>{x.tagName}</option>) : <option disabled>'Tags list is loadind'</option>}
            </select> */}
			<label htmlFor="selectOption">Select tags</label>
			<Select
				closeMenuOnSelect={true}
				components={animatedComponents}
				value={defaultSelect && defaultSelect}
				menuPortalTarget={document.body}
				styles={{ menuPortal: base => ({ ...base, zIndex: 10000 }) }}
				menuPosition={'fixed'}
				isMulti
				options={newArray}
				placeholder={'Search and select tags'}
				onChange={handleSelectChange}
			/>
		</div >
	);
}
