import React, { useEffect, useState } from 'react';
import { getCampaigns } from '../../../../Services/Queries/OnSiteDashboardQueries';
import Pagination from '../UI/Pagination';
import CampaignItem from './CampaignItem';
import {
	OrderByModificationDateDesc,
	OrderByModificationDateAsc,
	OrderByNameDesc,
	OrderByNameAsc,
	OrderByImpressionslast30Desc,
	OrderByImpressionslast30Asc,
	OrderByImpressionslastlast30Desc,
	OrderByImpressionslastlast30Asc,
} from '../../../../Services/Queries/OrderByFilter';
import './CampaignsList.css';
import PopinConfirm from '../../../../Components/PopinConfirm';
import Btn from '../../../../Components/Btn';
import Dropdown from '../../../../Components/Dropdown';
import { onSiteContext } from '../context';
import { Trans, useTranslation } from 'react-i18next';
import { renderJsxTag } from '../../../../Translation/translation-utils';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import { deviceNavToGraphFilter } from '../layout/SelectorDevicesModel';

export default function CampaignsList() {
	const {
		systemServices,
		accountId,
		ReportingCampaignServices,
		needFetchList,
		setneedFetchList,
		client,
		labelServices,
		filterStatusMode,
		filterTestMode,
		searchBarvalue,
		filterBylabels,
		devices,
		setLoadingCampaign
	} = onSiteContext();
	const [t, i18n] = useTranslation('common');

	const [campaignIsLoading, setCampaignLoading] = useState(false);
	const [campaignsIsLoading, setCampaignsIsLoading] = useState(true);
	const [skipIdNumber, setskipIdNumber] = useState(0);
	const [totalcount, settotalcount] = useState(0);
	const [pageSize, setpageSize] = useState(20);
	const [campaigns, setcampaigns] = useState([]);
	const [scenarioLabels, setScenarioLabels] = useState([]);
	const [campaignIdToDelete, setcampaignIdToDelete] = useState();
	const [viewsDropdownIsOpen, setviewsDropdownIsOpen] = useState(false);
	const [impressionsMode, setImpressionsMode] = useState('last30');
	const [orderCampaignsBy, setorderCampaignsBy] = useState({
		query: OrderByModificationDateDesc,
		name: 'OrderByModificationDateDesc',
		type: 'date',
		icon: 'desc',
	});
	const onTestExecutionModeFilter = {
		'and': [
			{ executionMode: { eq: 'TEST' } },
			{ isStopped: { 'eq': false } },
		]
	};
	const notOnTestExecutionModeFilter = {
		executionMode: { neq: 'TEST' }
	};

	const loadCampaigns = (skipId) => {
		if (!filterStatusMode) return;
		let filter;
		if (filterStatusMode.length > 0) {
			if (filterTestMode) {
				filter = {
					or: [filterStatusMode[0], onTestExecutionModeFilter],
				};
			} else {
				filter = {
					and: [filterStatusMode[0], notOnTestExecutionModeFilter],
				};
			}
		} else {
			filter = filterTestMode
				? onTestExecutionModeFilter
				: notOnTestExecutionModeFilter;
		}
		setCampaignsIsLoading(true);

		labelServices.getScenarioSpecialLabelsItem(data => {
			setScenarioLabels(data);
		}, (err) => {
			console.log('err', err);
		});
		
		const andFilters = [filter, searchBarvalue, filterBylabels];

		if (devices.length > 0){
			const deviceFilter = deviceNavToGraphFilter(devices);
			if (deviceFilter){
				andFilters.push(deviceFilter);
			}
		}

		client
			.query({
				query: getCampaigns,
				variables: {
					accountId: accountId,
					skip: skipId,
					take: pageSize,
					order: [orderCampaignsBy.query],
					
					where: {
						and: andFilters,
					},
				},
			})
			.then((response) => {
				setCampaignsIsLoading(false);
				settotalcount(response.data.account.onSiteCampaigns.totalCount);
				setcampaigns(response.data.account.onSiteCampaigns.items);

			})
			.catch((e) => {
				setCampaignsIsLoading(false);
				console.log('err', e);
				systemServices.showError(t('campaignsList.errorOccured'));
			});
	};

	useEffect(() => {
		const Debounce = setTimeout(() => {
			if (
				filterStatusMode &&
				orderCampaignsBy &&
				filterBylabels
			) {
				loadCampaigns(0);
				setskipIdNumber(0);
			}
		}, 900);
		return () => clearTimeout(Debounce);
	}, [
		filterTestMode,
		filterStatusMode,
		orderCampaignsBy,
		searchBarvalue,
		filterBylabels,
	]);
	useEffect(() => {
		if (needFetchList) {
			loadCampaigns(skipIdNumber);
			setneedFetchList(false);
		}
	}, [needFetchList]);

	const changeorderOfCampaign = (orderBy) => {
		if (orderCampaignsBy.query === orderBy) {
			return;
		} else {
			if (orderBy === 'OrderByModificationDateDesc') {
				setorderCampaignsBy({
					query: OrderByModificationDateDesc,
					name: 'OrderByModificationDateDesc',
					type: 'date',
					icon: 'desc',
				});
			}
			if (orderBy === 'OrderByModificationDateAsc') {
				setorderCampaignsBy({
					query: OrderByModificationDateAsc,
					name: 'OrderByModificationDateAsc',
					type: 'date',
					icon: 'asc',
				});
			}
			if (orderBy === 'OrderByNameDesc') {
				setorderCampaignsBy({
					query: OrderByNameDesc,
					name: 'OrderByNameDesc',
					type: 'name',
					icon: 'desc',
				});
			}
			if (orderBy === 'OrderByNameAsc') {
				setorderCampaignsBy({
					query: OrderByNameAsc,
					name: 'OrderByNameAsc',
					type: 'name',
					icon: 'asc',
				});
			}
			if (orderBy === 'OrderByImpressionslast30Desc') {
				setorderCampaignsBy({
					query: OrderByImpressionslast30Desc,
					name: 'OrderByImpressionslast30Desc',
					type: 'name',
					icon: 'desc',
				});
			}
			if (orderBy === 'OrderByImpressionslast30Asc') {
				setorderCampaignsBy({
					query: OrderByImpressionslast30Asc,
					name: 'OrderByImpressionslast30Asc',
					type: 'name',
					icon: 'asc',
				});
			}
			if (orderBy === 'OrderByImpressionslastlast30Desc') {
				setorderCampaignsBy({
					query: OrderByImpressionslastlast30Desc,
					name: 'OrderByImpressionslastlast30Desc',
					type: 'name',
					icon: 'desc',
				});
			}
			if (orderBy === 'OrderByImpressionslastlast30Asc') {
				setorderCampaignsBy({
					query: OrderByImpressionslastlast30Asc,
					name: 'OrderByImpressionslastlast30Asc',
					type: 'name',
					icon: 'asc',
				});
			}
		}
	};

	const refreshCampaign = (accountId, campaignId) => {
		setLoadingCampaign(campaignId);
		ReportingCampaignServices.putRefreshCampaign(accountId, campaignId, success => {
			loadCampaigns(skipIdNumber);
			setLoadingCampaign(null);
			systemServices.showSuccess(t('campaignsList.campaignSuccess'));
		},
		(error) => {
			console.log(error);
			setCampaignLoading(false);
			setLoadingCampaign(null);
			systemServices.showError(t('campaignsList.errorRefresh'));
		});
	};

	const deleteCampaign = () => {
		setCampaignLoading(campaignIdToDelete.id);
		setcampaignIdToDelete();
		ReportingCampaignServices.deleteACampaign(
			accountId,
			campaignIdToDelete.id,
			(success) => {
				loadCampaigns(skipIdNumber);
				systemServices.showSuccess(
					t('campaignsList.successDelete')
				);
			},
			(error) => {
				setCampaignLoading(false);
				systemServices.showError(
					t('campaignsList.errorDelete')
				);
			}
		);
	};

	const changeViewColumn = (column) => {
		setImpressionsMode(column);
		if (!orderCampaignsBy) return;
		if (orderCampaignsBy.name.indexOf('OrderByImpressions') == -1) return;

		var sort = orderCampaignsBy.name.indexOf('Desc') >= 0 ? 'Desc' : 'Asc';
		changeorderOfCampaign('OrderByImpressions' + column + sort);
	};
	return (
		<>
			{campaignIdToDelete && (
				<PopinConfirm
					cancel={() => setcampaignIdToDelete()}
					title={t('campaignsList.wantToDelete', { nameCampaign: campaignIdToDelete.name })}
				>
					<Btn
						onClickFunction={(e) => {
							setcampaignIdToDelete();
						}}
						message={t('campaignsList.no')}
						color="alert"
					/>
					<Btn onClickFunction={deleteCampaign} message={t('campaignsList.yes')} color="primary" />
				</PopinConfirm>
			)}
			<div>
				<div className="table_grid table_grid_campaign">
					<div className="table_row table_head_row">
						<div className="table_col">
							<a
								className="table_sort"
								onClick={
									orderCampaignsBy &&
										orderCampaignsBy.name === 'OrderByNameDesc'
										? () => changeorderOfCampaign('OrderByNameAsc')
										: () => changeorderOfCampaign('OrderByNameDesc')
								}
							>
								{t('campaignsList.title')}
								{orderCampaignsBy.name === 'OrderByNameAsc' && (
									<i className="fas fa-arrow-up"></i>
								)}
								{orderCampaignsBy.name === 'OrderByNameDesc' && (
									<i className="fas fa-arrow-down"></i>
								)}
							</a>
						</div>
						<div className="table_col">{t('campaignsList.status')}</div>
						<div className="table_col">{t('campaignsList.devices')}</div>
						<div className="table_col">
							<Dropdown
								isOpen={viewsDropdownIsOpen}
								onHide={(e) => setviewsDropdownIsOpen(false)}
								setIsOpen={(e) => setviewsDropdownIsOpen(true)}
								button={
									<a className="table_sort has_arrow">
										{impressionsMode === 'last30' && (
											<>
												<Trans i18nKey="i18kcustomeJourney" >
													{t('campaignsList.view')} <small>{t('campaignsList.last30')}</small>
												</Trans>
											</>
										)}
										{impressionsMode === 'lastlast30' && (
											<>
												{t('campaignsList.view')} <small>{t('campaignsList.lastlast30')}</small>
											</>
										)}
									</a>
								}
							>
								<ul className="listbox">
									<li>
										<a
											className={
												impressionsMode == 'last30'
													? 'listbox_item selected'
													: 'listbox_item'
											}
											onClick={(e) => {
												changeViewColumn('last30');
												setviewsDropdownIsOpen(false);
											}}
										>
											{t('campaignsList.txtLast30Days')}
										</a>
									</li>
									<li>
										<a
											className={
												impressionsMode == 'lastlast30'
													? 'listbox_item selected'
													: 'listbox_item'
											}
											onClick={(e) => {
												changeViewColumn('lastlast30');
												setviewsDropdownIsOpen(false);
											}}
										>
											{t('campaignsList.txtPrev30Days')}
										</a>
									</li>
									<li className="hr"></li>
									<li>
										<a
											className="listbox_item"
											onClick={() => {
												changeorderOfCampaign(
													'OrderByImpressions' + impressionsMode + 'Desc'
												);
												setviewsDropdownIsOpen(false);
											}}
										>
											<i className="fas fa-arrow-down listbox_item_icon"></i>
											Descending sort
										</a>
									</li>
									<li>
										<a
											className="listbox_item"
											onClick={() => {
												changeorderOfCampaign(
													'OrderByImpressions' + impressionsMode + 'Asc'
												);
												setviewsDropdownIsOpen(false);
											}}
										>
											<i className="fas fa-arrow-up listbox_item_icon"></i>
											Ascending sort
										</a>
									</li>
								</ul>
							</Dropdown>

							<a
								className="table_sort"
								onClick={
									orderCampaignsBy &&
										orderCampaignsBy.name ===
										'OrderByImpressions' + impressionsMode + 'Desc'
										? () =>
											changeorderOfCampaign(
												'OrderByImpressions' + impressionsMode + 'Asc'
											)
										: () =>
											changeorderOfCampaign(
												'OrderByImpressions' + impressionsMode + 'Desc'
											)
								}
							>
								{orderCampaignsBy.name === 'OrderByImpressionslast30Desc' && (
									<i className="fas fa-arrow-down"></i>
								)}
								{orderCampaignsBy.name ===
									'OrderByImpressionslastlast30Desc' && (
									<i className="fas fa-arrow-down"></i>
								)}
								{orderCampaignsBy.name === 'OrderByImpressionslast30Asc' && (
									<i className="fas fa-arrow-up"></i>
								)}
								{orderCampaignsBy.name ===
									'OrderByImpressionslastlast30Asc' && (
									<i className="fas fa-arrow-up"></i>
								)}
							</a>
						</div>
						<div className="table_col">
							<a
								className="table_sort"
								onClick={
									orderCampaignsBy &&
										orderCampaignsBy.name === 'OrderByModificationDateDesc'
										? () => changeorderOfCampaign('OrderByModificationDateAsc')
										: () => changeorderOfCampaign('OrderByModificationDateDesc')
								}
							>
								{t('campaignsList.LastUpdate')}
								{orderCampaignsBy.name === 'OrderByModificationDateAsc' && (
									<i className="fas fa-arrow-up"></i>
								)}
								{orderCampaignsBy.name === 'OrderByModificationDateDesc' && (
									<i className="fas fa-arrow-down"></i>
								)}
							</a>
						</div>
					</div>
					<div>
						{campaigns && campaigns.length === 0 && !campaignsIsLoading && (
							<div className="empty_page">
								<div className="empty_page_title">{t('campaignsList.noMatching')}</div>
							</div>
						)}
						{campaigns &&
							campaigns.map((campaign) => (
								<CampaignItem
									key={campaign.id}
									campaign={campaign}
									refreshCampaign={refreshCampaign}
									impressionsMode={impressionsMode}
									scenarioLabels={scenarioLabels.filter(x => x.itemId == campaign.id)}
								/>
							))}
					</div>
				</div>
				<div>
					{campaigns && campaigns.length !== 0 && (
						<Pagination
							totalCount={totalcount}
							pageSize={pageSize}
							paginate={(pageNumber) => {
								setskipIdNumber(pageNumber);
								loadCampaigns(pageNumber);
							}}
							skipIdNumber={skipIdNumber}
						/>
					)}
				</div>
			</div>

			{campaignsIsLoading && (
				<SpinnerWheel wheelSize='l' />
			)}
		</>
	);
}
