import React, { useState } from 'react'
import Btn from '../../../Components/Btn';
import Dropdown from '../../../Components/Dropdown';

export default function DropdownRanking({
	duplicateActionFunction,
	openModalCampaignDelete,
	element
}) {
	element
	const isUsedInSegment = element.segmentedRankingUsages && element.segmentedRankingUsages.length > 0;
	const [editDropdownIsOpen, setEditDropdownIsOpen] = useState(false);
	return (
		<Dropdown
			isOpen={editDropdownIsOpen}
			setIsOpen={(e) => setEditDropdownIsOpen(true)}
			onHide={(e) => setEditDropdownIsOpen(false)}
			button={
				<Btn
					style="ghost_outline"
					color="secondary"
					size="xs"
					icon="fas fa-ellipsis-v"
				/>
			}
		>
			<ul className="listbox">
				<li>
					<a className="listbox_item" onClick={() => {
						duplicateActionFunction();
						setEditDropdownIsOpen(false)
					}}>
						<i className="listbox_item_icon fas fa-copy"></i>
						<span>Duplicate</span>
					</a>
				</li>
				<li className='hr' />
				{isUsedInSegment ? 'Used in ' + element.segmentedRankingUsages.id : null}
				<li>
					<a className="listbox_item" onClick={isUsedInSegment ? null : () => {
						openModalCampaignDelete();
						setEditDropdownIsOpen(false);
					}}>
						<i className="listbox_item_icon fas fa-trash"></i>
						<span>Delete</span>
					</a>
				</li>
			</ul>
		</Dropdown>
	)
}
