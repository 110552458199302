import React, { useEffect, useState } from 'react';
import InputCustom from '../../../../../Components/InputCustom';
import SelectDropdown from '../../../../../Components/SelectDropdown';

const TypeTNDB = [
	{
		value: 'Text',
		label: 'Text',
	},
	{
		value: 'Numeric',
		label: 'Numeric',
	},
	{
		value: 'Date',
		label: 'Date',
	},
	{
		value: 'Boolean',
		label: 'Boolean',
	},
];

const TypeND = [
	{
		value: 'Numeric',
		label: 'Numeric',
	},
	{
		value: 'Date',
		label: 'Date',
	}
];

export const TypeValueTypes = [ 'TypeTNDB', 'TypeND' ];

export default function ParamsFilter({
	filter,
	name,
	valueType,
	value,
	handleChangeFilterParamsByUniqueId,
}) {

	const [render, setRender] = useState(<></>);

	const getSupportedType = () => {
		if (valueType === 'TypeTNDB') {
			return TypeTNDB;
		}
		if (valueType === 'TypeND') {
			return TypeND;
		}
		return [];
	};

	useEffect(() => {
		if (valueType === 'String' || valueType === 'Tag' || valueType === 'Boolean') {
			setRender(
				<InputString
					idFilter={filter.idFilter}
					name={name}
					valueString={value || { stringValues: '' }}
					handleChangeFilterParamsByUniqueId={
						handleChangeFilterParamsByUniqueId
					}
				/>
			);
		}
		else if (valueType === 'TypeTNDB' || valueType === 'TypeND') {
			setRender(<InputType
				idFilter={filter.idFilter}
				name={name}
				supportedTypes={getSupportedType()}
				valueString={value || { stringValues: '' }}
				handleChangeFilterParamsByUniqueId={
					handleChangeFilterParamsByUniqueId
				}
			/>
			);
		}
		else if (valueType === 'Number') {
			setRender(<InputNumber
				idFilter={filter.idFilter}
				name={name}
				valueNumber={value || { stringValues: '' }}
				handleChangeFilterParamsByUniqueId={
					handleChangeFilterParamsByUniqueId
				} />);
		}
		else {
			setRender(<></>);
		}
	}, [valueType, name, value]);

	return render;
}

const InputType = ({
	name,
	valueString,
	supportedTypes,
	handleChangeFilterParamsByUniqueId,
	idFilter
}) => {
	const [currentValue, setCurrentValue] = useState(valueString ? valueString.stringValues[0] : '');
	useEffect(() => {
		const val = valueString ? valueString.stringValues[0] : '';
		if (val) {
			setCurrentValue(val);
		} else {
			setCurrentValue(supportedTypes.length > 0 ? supportedTypes[0].value : 'Text');
		}
	}, [valueString]);
	useEffect(() => {
		handleChangeFilterParamsByUniqueId(idFilter, {
			name: name,
			value: { stringValues: [currentValue] },
		});
	}, [currentValue]);

	return (<SelectDropdown
		optionsList={supportedTypes}
		value={currentValue}
		onChange={(v) => setCurrentValue(v)}
		label={name}
		blockClassName="inline mr_10"
	/>);
};

const InputString = ({
	name,
	valueString,
	handleChangeFilterParamsByUniqueId,
	idFilter,
}) => {
	const [isAnError, setIsAnError] = useState({ isError: false, message: '' });
	const [currentValue, setCurrentValue] = useState(valueString ? valueString.stringValues[0] : '');
	const handleChange = (e) => {
		let value = e.target.value;
		if (!value) {
			setIsAnError({ isError: true, message: 'input cannot be empty' });
		} else {
			setIsAnError({ isError: false, message: '' });
		}
		setCurrentValue(value);
	};

	useEffect(() => {
		handleChangeFilterParamsByUniqueId(idFilter, {
			name: name,
			value: { stringValues: [currentValue] },
		});
	}, [currentValue]);
	useEffect(() => {
		const val = valueString ? valueString.stringValues[0] : '';
		if (val) {
			setCurrentValue(val);
		} else {
			setCurrentValue('');
		}
	}, [valueString]);

	return (
		<>
			<InputCustom
				label={name}
				type="text"
				isAnError={isAnError.message}
				onChange={handleChange}
				value={currentValue}
				blockClassName="inline mr_10"
			/>
		</>
	);
};
const InputNumber = ({
	name,
	valueNumber,
	handleChangeFilterParamsByUniqueId,
	idFilter,
}) => {
	const [isAnError, setIsAnError] = useState({ isError: false, message: '' });
	const [currentValue, setCurrentValue] = useState(
		valueNumber ? valueNumber.numberValues : ''
	);

	const handleChange = (e) => {
		let value = e.target.value;

		if (!value) {
			setIsAnError({ isError: true, message: 'input cannot be empty' });
		} else {
			let number = Number(value);
			if (!number) {
				setIsAnError({
					isError: true,
					message: 'input should be a number',
				});
			} else {
				setIsAnError({ isError: false, message: '' });
			}
		}
		setCurrentValue(value);
	};

	useEffect(() => {
		if (!currentValue) return;
		handleChangeFilterParamsByUniqueId(idFilter, {
			name: name,
			value: { numberValues: currentValue },
		});
	}, [currentValue]);

	return (
		<>
			<InputCustom
				label={name}
				type="text"
				isAnError={isAnError.message}
				onChange={handleChange}
				value={currentValue}
				blockClassName="inline mr_10"
			/>
		</>
	);
};
