import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Insight from '../Insight/Insight';
import { FORMAT_WITH_HMS } from '../../config';

export const InsightContainer = ({ service, socket, selectedTenant }) => {
	const [todayVisitors, setTodayVisitors] = useState({
		loading: true,
		isError: false,
	});
	const [thisWeekVisitors, setThisWeekVisitors] = useState({
		loading: true,
		isError: false,
	});
	const [thisMonthVisitors, setThisMonthVisitors] = useState({
		loading: true,
		isError: false,
	});

	const days30Ago = moment().subtract(30, 'days');
	const days7Ago = moment().subtract(7, 'days');
	const startOfDay = moment().startOf('day');
	const oneHourAgo = moment().subtract(1, 'hour');

	useEffect(() => {
		getVisitors(
			{
				period1ToDate: moment(oneHourAgo).format(FORMAT_WITH_HMS),
				period1FromDate: moment(days30Ago).format(FORMAT_WITH_HMS),
				period2ToDate: moment(oneHourAgo)
					.subtract(30, 'days')
					.format(FORMAT_WITH_HMS),
				period2FromDate: moment(days30Ago)
					.subtract(30, 'days')
					.format(FORMAT_WITH_HMS),
			},
			setThisMonthVisitors
		);

		getVisitors(
			{
				period1ToDate: moment(oneHourAgo).format(FORMAT_WITH_HMS),
				period1FromDate: moment(days7Ago).format(FORMAT_WITH_HMS),
				period2ToDate: moment(oneHourAgo)
					.subtract(7, 'days')
					.format(FORMAT_WITH_HMS),
				period2FromDate: moment(days7Ago)
					.subtract(7, 'days')
					.format(FORMAT_WITH_HMS),
			},
			setThisWeekVisitors
		);

		getVisitors(
			{
				period1ToDate: moment(oneHourAgo).format(FORMAT_WITH_HMS),
				period1FromDate: moment(startOfDay).format(FORMAT_WITH_HMS),
				period2ToDate: moment(oneHourAgo)
					.subtract(1, 'days')
					.format(FORMAT_WITH_HMS),
				period2FromDate: moment(startOfDay)
					.subtract(1, 'days')
					.format(FORMAT_WITH_HMS),
			},
			setTodayVisitors
		);
	}, [selectedTenant]);

	function getVisitors(
		filter = {
			period1FromDate: '',
			period1ToDate: '',
			period2FromDate: '',
			period2ToDate: '',
		},
		cb
	) {
		cb({ loading: true, isError: false });

		const template = {
			...filter,
			periodsTimezone: Intl
				? Intl.DateTimeFormat().resolvedOptions().timeZone
				: 'Europe/Paris', // get user timezone
			metrics: ['NumberOfSessions'],
			timeGranularity: 'None',
		};

		service.getSessionsCompare(
			template,
			(data) => {
				const [visitors] = data;
				const formattedData = {
					value: visitors.NumberOfSessionsPeriod1,
					tendance: visitors.NumberOfSessionsPercentVariation,
					sign:
						visitors.NumberOfSessionsPercentVariation ||
						visitors.NumberOfSessionsPercentVariation === 0
							? '%'
							: null,
				};
				cb({ ...formattedData, loading: false, isError: false });
			},
			(error) => {
				console.log(error, 'error');
				const formattedData = {
					value: '-',
				};
				cb({ ...formattedData, loading: false, isError: true });
			}
		);
	}
	return (
		<div className="analytics_insight_group">
			<Insight label="Visits today" isGray={false} {...todayVisitors} />
			<Insight
				label="Visits last 7 days"
				isGray={false}
				{...thisWeekVisitors}
			/>
			<Insight
				label="Visits last 30 days"
				isGray={false}
				{...thisMonthVisitors}
			/>
		</div>
	);
};
