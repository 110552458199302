import AiInsightsService from '../../../../Services/AiInsightsServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import { AiInsight } from '../context/AiInsightsApiTypesServices';

const isFake = true;
const i18nL = localStorage.getItem('i18nL');

const examplePayload: AiInsight[] = [
	{
		kpi: 'AbandonedBasketAmount', 
		category: 'AbandonedBasket',
		translatedFindingName: 'Le nombre de paniers abandonnés', 
		findingType: 'BigUplift',
		favorableness: -1,
		period: 'Last7Days',
		dimensions: {
			TrafficSource: 'Paid',
			DeviceType: 'Mobile',
			VisitorEngagement: 'X',
		},
		findingData: {
			AbandonedBasketAmount: {
				translatedDescription: 'Paniers abandonnés', 
				absolute: 14502,
				absoluteType: 'Number',
				relative: 0.207, 
				favorableness: -1,
			},
		},
	},
	{
		kpi: 'Conversion',
		category: 'Product',
		translatedFindingName: 'Le produit 6848652',
		findingType: 'ProductPositiveSpotlight',
		favorableness: 1,
		period: 'LastDay',
		dimensions: {},
		findingData: {
			Conversions: {
				translatedDescription: 'Achats',
				absolute: 0.45,
				absoluteType: 'Percentage',
				favorableness: 1,
			},
			Turnover: {
				translatedDescription: 'Chiffre d\'affaire',
				absolute: 8546,
				absoluteType: 'Amount',
				favorableness: 1,
			},
		},
		findingAdditionalParams: {
			ProductId: '6848652',
		},
	},
	{
		kpi: 'Conversion',
		category: 'Product',
		translatedFindingName: 'Le produit 6848652',
		findingType: 'ProductPositiveSpotlight',
		favorableness: -1,
		period: 'LastDay',
		dimensions: {},
		findingData: {
			Turnover: {
				translatedDescription: 'CA / Achats',
				absolute: 8546,
				absoluteType: 'Amount',
				relative: 0.451,
				favorableness: -1,
			},
		},
		findingAdditionalParams: {
			ProductId: '6848652',
		},
	},
	{
		kpi: 'Conversion',
		category: 'Conversion',
		translatedFindingName: 'Votre taux de réachat',
		findingType: 'NegativeBusinessComparison',
		favorableness: -1,
		period: 'Last7Days',
		dimensions: {},
		findingData: {
			AbandonedBasketAmount: {
				translatedDescription: 'Taux de réachat',
				absolute: 0.012,
				absoluteType: 'Percentage',
				favorableness: -1,
			},
		},
	},
];

export function aiInsightsBridgeCrud(httpAngularJs,$rootScope,$routeParams) {
	const impersonatingServices = new ImpersonatingServices($rootScope,$routeParams );
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const aiInsightsService = new AiInsightsService(accountId,httpAngularJs);
	function getAiInsights(callbackSuccess, callbackError) {
		aiInsightsService.getAiInsights(
			i18nL,
			(data: AiInsight[]) => {
				callbackSuccess(data);
			},
			(err: any) => {
				console.error('Error fetching AI Insights:', err);
				if (isFake) {
					callbackError(examplePayload);
				} else {
					callbackError();
				}
			}
		);
	}
	return {
		getAiInsights,
	};
}



