import React from 'react'
import { react2angular } from 'react2angular';
import CreateRankingContextProvider from '../context/RankingContextProvider';
import RankinSettingsContainer from './RankinSettingsContainer';

export default function RankingBridge(props) {

	return (
		<CreateRankingContextProvider
			$http={props.$http}
			$timeout={props.$timeout}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
			$location={props.$location}
		>
			<RankinSettingsContainer />
		</CreateRankingContextProvider>
	)
}
angular
	.module('beyableSaasApp.RankingBridge', [])
	.component('rankingbridge', react2angular(RankingBridge, [], ['$http',
		'$rootScope', '$timeout', '$location',
		'$routeParams', 'AuthServices']));