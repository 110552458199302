import React, { useState, useEffect, useContext } from 'react';
import './MatchingList.css';
import { Context } from './Context/Context';
export default function MatchingListContainer({ fetchMatchingListItems, lunchaddItemToMatchingList, algorithm }) {
	const {
		openMatchingListEdit,
		toggleMatchingListEdit,
		oppenNewMatchingListEdit,
		listOfMatchingList,
		matchingListValues,
		btnSelect,
		setBtnSelect,
		existantAlgorithm,
	} = useContext(Context);
	// function for update matching list btn selected
	useEffect(() => {
		if (algorithm.config && algorithm.config.matchingLists) {
			setBtnSelect(algorithm.config.matchingLists);
		}
	}, [algorithm]);

	const sortByAsc = (a, b) => {
		if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
		if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
		return 0;
	};
	return (
		<div>
			<ul className="ulMatchingList">
				{
					listOfMatchingList && listOfMatchingList.sort(sortByAsc).map((item, i) =>
						<li className={btnSelect && btnSelect.includes(item.id) ? 'liMatchingList liMatchingListSelect' : 'liMatchingList'} key={item.id}>
							<p onClick={() => lunchaddItemToMatchingList(item.id)}>
								{item.name}
							</p>
							<span className="matchingListPen" onClick={() => fetchMatchingListItems(item)}>
								<i className="fas fa-pen fa-xs"></i>
							</span>
						</li>
					)
				}
				<li className="liAddAList" onClick={oppenNewMatchingListEdit}> Add a list<i className="fas fa-plus iconAddList"></i></li>
			</ul>
		</div>
	);
}
