import React, { useEffect, useState } from "react";
import CampaignsList from "./CampaignsList";
import AccountParametersContainer from "../../../Tooling/AccountConfigurations/AccountParametersContainer";
import { onSiteContext } from '../context';
import SpinnerWheel from '../../../../Components/SpinnerWheel';

export default function OnSiteDashboardListContainer(props) {
	const {

		idOfCampaign,
		showConfiguration,
		closeShowConfiguration,
		$rootScope,
		$http,
		$routeParams,
		accountIsLoading,
		checkAccountData,
	} = onSiteContext();

	return (
		<>
			{!accountIsLoading && (
				<div>
					{checkAccountData && (
						<>
							<CampaignsList />
							{showConfiguration && (
								<AccountParametersContainer
									props={{
										reactRender: true,
										idOfCampaign: idOfCampaign,
										$rootScope: $rootScope,
										$http: $http,
										$routeParams: $routeParams,
										onLoad: (e) => closeShowConfiguration(),
										isOppen: showConfiguration,
									}}
								/>
							)}
						</>
					)}

				</div>
			)}
			{accountIsLoading && (
				<SpinnerWheel wheelSize="l" />
			)}
		</>
	);
}

