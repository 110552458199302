import React from 'react';
import {useAccountListContext} from '../context/AccountListProvider';
import formatMoment from '../../../Constants/FormatsMoments';
import Btn from '../../../Components/Btn';
import LabelsGlobalAccounts from '../../Admin/AdminAdministration/LabelGlobalAccounts';
import LabelAcountItem from '../../Admin/AdminAdministration/LabelsAccounts/LabeAcountItem';
import LabelFilters from '../../Labels/LabelFilters';
import LabelsAccountContainer from '../../Admin/AdminAdministration/LabelsAccounts/LabelsAccountsContainer';
export default function AccountListContainer() {
	const { formatDateToLocal } = formatMoment;
	const {
		accountList,
		orderItems,
		order,
		noData,
		allAccounts,
		modalLabelsIsOpen,
		modalLabelsAccountisOpen,
		isReloading,
		dataFiltered,
		selectedLabelIds,
		filterByLabels,
		setOrder,
		setOrderItems,
		accountLabels,
		closeTheModalOfLabels,
		setModalLabelsIsOpen,
		onLabelSelected,
		labelServices,
		accountId,
		systemServices,
		setModalLabelsAccountisOpen,
		valueInSearchBar,
		handleChange,
		OpenModalLabelAccount,
		accountSelected,
		clipBoardContain,
		copyToClipBoard
	} = useAccountListContext();
	const accountIdForLabels = accountId ;

	let valueToMap;
	if (dataFiltered) {
		if (selectedLabelIds.length > 0) {
			valueToMap = filterByLabels(dataFiltered);
		} else {
			valueToMap = dataFiltered;
		}
	} else if (selectedLabelIds.length > 0) {
		valueToMap = filterByLabels(allAccounts);
	} else {
		valueToMap = allAccounts;
	}
	const orderBy = (id) => {
		let newOrder = order === 'down' ? 'up' : 'down';
		valueToMap.sort((a, b) => {
			if (newOrder === 'down') {
				if (a === undefined) return;
				if (b === undefined) return;
				if (a[id] === null) return 1;
				if (b[id] === null) return -1;
				if (a[id] < b[id]) return -1;
				if (a[id] > b[id]) return 1;
				return 0;
			} else {
				if (a === undefined) return;
				if (b === undefined) return;
				if (a[id] === null) return 1;
				if (b[id] === null) return -1;
				if (a[id] > b[id]) return -1;
				if (a[id] < b[id]) return 1;
				return 0;
			}
		});
		setOrder(newOrder);
		setOrderItems(id);
		return valueToMap;
	};

	return (
		<>
			<div className="mb_20">
				<div className="flex flex_v">
					<div className="flex_item_full">
						<label className="custom_input w_full">
							<input type="text"
								placeholder="Search for an account"
								value={valueInSearchBar}
								onChange={(e) => handleChange(e)}
								autoFocus
							/>
						</label>
					</div>
					<div className="mt_15 flex" >
						<LabelFilters
							selectableLabels={accountLabels || []}
							selectedLabelIds={selectedLabelIds || []}
							onLabelSelected={l => onLabelSelected(l)}
							canEdit={true}
							isSupervisionPage={true}
							onEditRequested={() => setModalLabelsAccountisOpen(true)}
						/>
					</div>
					
				</div>
				{modalLabelsAccountisOpen &&
				<LabelsGlobalAccounts
					labelService={labelServices}
					accountId={accountIdForLabels}
					systemServices={systemServices}
					modalLabelsAccountisOppen={modalLabelsAccountisOpen}
					setmodalLabelsAccountisOppen={(e) => closeTheModalOfLabels(e)}
					objectType={'account'}
				/>
				}
				{modalLabelsIsOpen &&
				<LabelsAccountContainer
					labelService={labelServices}
					accountId={accountIdForLabels}
					systemServices={systemServices}
					modalLabelsIsOpen={modalLabelsIsOpen}
					setModalLabelsIsOpen={(e) => setModalLabelsIsOpen(e)}
					objectType={'account'}
					accountSelected={accountSelected}
				/>
				}
			</div>
			<table className="accounts_list">
				<thead>
					<tr>
						<th
							id="name"
							onClick={(e) => { orderBy('name'); }}
							className={orderItems === 'name' ? 'th_order' : ''}>
									Compte
									({valueToMap && valueToMap.length})
							{orderItems === 'name' && order === 'up' &&
										<i className="fa fa-caret-up"> </i>
							}
							{orderItems === 'name' && order === 'down' &&
										<i className="fa fa-caret-down"></i>}
						</th>
						<th
							onClick={(e) => { orderBy('mainUserEmail'); }}
							id="email"
							className={orderItems === 'mainUserEmail' ? 'th_order' : ''}>
									Email
							{orderItems === 'mainUserEmail' && order === 'up' &&
										<i className="fa fa-caret-up" > </i>}
							{orderItems === 'mainUserEmail' && order === 'down' &&
										<i className="fa fa-caret-down"> </i>}
						</th>

						<th
							onClick={(e) => { orderBy('creationDateUtc'); }}
							id="creationDateUtc"
							className={orderItems === 'creationDateUtc' ? 'th_order' : ''}>
									Registration date
							{orderItems === 'creationDateUtc' && order === 'up' &&
										<i className="fa fa-caret-up"> </i>}
							{orderItems === 'creationDateUtc' && order === 'down' &&
										<i className="fa fa-caret-down"></i>}
						</th>
						<th
							id="lastModificationDateUtc"
							className={orderItems === 'lastModificationDateUtc' ? 'th_order' : ''}	
							onClick={(e) => { orderBy('lastModificationDateUtc'); }}
						>
							{orderItems === 'lastModificationDateUtc' && order === 'up' &&
										<i className="fa fa-caret-up"> </i>}
							{orderItems === 'lastModificationDateUtc' && order === 'down' &&
										<i className="fa fa-caret-down"></i>}
						Last modification date</th>
					
					</tr>
				</thead>
				<tbody>
					{valueToMap && valueToMap.map(account =>
						<React.Fragment key={account.id} >
							<tr>
								<td>
									<span>{account.name}</span>
									<span></span>
									<p className={clipBoardContain && clipBoardContain === account.id ?
										'admin_account_key copy_success' : 'admin_account_key'}>Key : <span
											onClick={(e) => copyToClipBoard(account.id)}
											className="account_key_copy">{account.id}</span><i className="far fa-clipboard"></i></p>

									{account.labels && account.labels.length == 0 &&<div
										onClick={() => OpenModalLabelAccount(true, account)}
										className="campaign_label_action mt_3"
										style={{
											border : '1px solid', 
											borderRadius : '12px',
											margin: '4px 6px',
											width : '90px',
											height : '20px',
											fontWeight: '400',
    										lineHeight:' 16px',
											textTransform: 'none'

										}}
									>
										<div className='flex'
											style={{fontWeight: '400',height : '5px',}}>
											<i
												style={{fontSize: '10px'}}
												className="fas fa-tag"></i> 
											<p className=''>Add labels </p>
										</div></div>}
									{account.labels && account.labels.length > 0 && (
										<div
											className="campaign_label_list mt_3 inline"
											onClick={() => OpenModalLabelAccount(true, account)}
										>
											{
												account.labels.map((label, key) => (
													<LabelAcountItem
														key={key}
														label={label}
													/>
												))
											}
										</div>
									)}
								</td>
								<td className="container-link-mail-account">
									<div>
										<span>{account.email === null ? '' : account.email}</span>
									</div>
									<div>
										<span>{account.mainUserEmail}</span>
									</div>
								</td>
								<td data-th="Registration date">{formatDateToLocal(account.creationDateUtc).format('DD/MM/YYYY')}</td>
								<td data-th="Last modification date">{formatDateToLocal(account.lastModificationDateUtc).format('DD/MM/YYYY')}</td>
								
							</tr>
						</React.Fragment>
					)}
				</tbody>
			</table>
				{!isReloading && noData && <p className="user-search-no-data">{noData}</p>}
		</>
	);
}
