import { VisitorEngagementLevel } from "./context/AiInsightsApiTypesServices";

export type EngagementLevel = {
    value: VisitorEngagementLevel;
    label: string;
}
export const engagementLevels:Array<EngagementLevel> = [
	{
		value : 'X',
		label : 'Lost'
	},
	{
		value : 'C',
		label : 'Consideration'
	},
	{
		value : 'D',
		label : 'Discover'
	},
	{
		value : 'I',
		label : 'Intention'
	},
	{
		value : 'A',
		label : 'Purchase'
	},
	{
		value : 'E',
		label : 'Experience'
	},
	{
		value : 'R',
		label : 'Recurring purchase'
	},
	{
		value : '',
		label : 'none'
	},
];