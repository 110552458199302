
import React, { } from 'react';
import { react2angular } from 'react2angular';
import CodeSnippet from '../../../Components/CodeSnippet';
import InstallationTag from '../../../Constants/InstallationTags';
import AnalyticsConfigurationContainer from './AnalyticsConfigurationContainer';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
export default function WebAnalyticsSetupContainer(props) {
	if (!props.account)
		return null;
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const { analyticsTag } = InstallationTag;
	const analyticsScript = analyticsTag(props.account.ClientKey);

	let settingsURL = '/EditAccount?tab=webanalytics';
	if (props.$routeParams.ka) {
		settingsURL += '&ka=' + props.$routeParams.ka + '&ku=' + props.$routeParams.ku;
	}
	return (
		<>
			<a
				className="c_turquoise"
				href={settingsURL}
				rel="noreferrer"
			>
				Go to analytics settings
			</a>
		</>
		// <AnalyticsConfigurationContainer
		// 	$routeParams={props.$routeParams}
		// 	$http={props.$http}
		// 	accountId={accountId}
		// 	$scope={props.$scope}
		// 	$rootScope={props.$rootScope}
		// 	AuthServices={props.AuthServices}
		// 	tabSelected='tenants'
		// 	analyticsScript={{script : analyticsScript}}
		// />
	);
}

angular
	.module('beyableSaasApp.Admin.EditAccountWebAnalytics', [])
	.component('webAnalyticsSetup', react2angular(WebAnalyticsSetupContainer, ['account'], ['$http', '$rootScope','$scope', 'AuthServices', '$routeParams']));