import React, { useState, useEffect, useContext } from 'react';
import './TargetSkuListContainer.css';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import Btn from '../../../../Components/Btn';
import IconBtn from '../../../../Components/IconBtn';
import ModalBody from '../../../../Components/ModalBody';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalFooter from '../../../../Components/ModalFooter';
import ModalSection from '../../../../Components/ModalSection';
import {List, ListItem} from '../../../../Components/List';
import InputCustom from '../../../../Components/InputCustom';
import SearchBar from '../../../../Components/SearchBar';

export default function TargetSkuListContainer({
	setModalIsOppen,
	setChangeOnList,
	setClickOnSave,
	setIsSaved,
	stateOfModificationSkuList,
	setStateOfModificationSkuList
}) {

	const {
		operation,
		setOperation,
		valueSpecificationToPut,
		targetCondition
	} = useContext(CreateBusinessOperationContext);

	const [skuValue, setSkuList] = useState([]);
	const [skuListArray, setSkuListArray] = useState([]);
	const [skuModifyValue, setSkuModifyValue] = useState('');
	const [valueSearchBar, setValueSearchBar] = useState('');
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();

	useEffect(() => {
		if (valueSpecificationToPut && valueSpecificationToPut.valueToCompare.length !== 0) {
			setSkuList(valueSpecificationToPut.valueToCompare.split(';'));
			setStateOfModificationSkuList(false);
		}
	}, [valueSpecificationToPut]);

	const handleChange = (event) => {
		let textAreaValue = { ...skuModifyValue };
		textAreaValue = event.target.value;
		setSkuModifyValue(textAreaValue);
	};
	useEffect(() => {
		const createListOfSku = () => {
			const skuList = skuValue.map(item => item.trim()).filter(x => x.length > 0);
			setSkuListArray(skuList);
		};
		createListOfSku();
	}, [skuValue]);

	const saveChange = () => {
		setClickOnSave(true);
		let op = { ...operation };
		let specificationToPut = op.operation.specifications.filter(x => x.id === valueSpecificationToPut.id)[0];
		specificationToPut.valueToCompare = skuListArray.join(';');
		let newObjSpecifications = [...op.operation.specifications.filter(x => x.id !== valueSpecificationToPut.id), specificationToPut];
		op.operation.specifications = newObjSpecifications;
		setOperation(op);
	};
	const addItem = () => {
		if (!targetCondition)
			return;
		const skuList = skuModifyValue && skuModifyValue.split(targetCondition.inputSplitter).filter(x => x.length > 0);
		const categoryListTrim = targetCondition.inputProcessingMap ? targetCondition.inputProcessingMap(skuList) : skuList;
		setSkuList([...skuValue, ...categoryListTrim]);
		setSkuModifyValue('');
		setStateOfModificationSkuList(false);
		setChangeOnList(true);
	};
	const deleteProp = (x) => {
		let array = skuValue;
		array = array.filter(element => element !== x);
		setSkuList(array);
		setDataFiltered();
		setChangeOnList(true);
		setValueSearchBar('');
	};

	const search = (table, word) => {
		if (word.length > 0) {
			setNoData();
			setDataFiltered([]);
			let resultName = table.filter(
				line =>
					line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			if (resultName.length === 0) {
				resultName = 'No item found';
				setNoData(resultName);
			}
			else { setDataFiltered(resultName); }

		} else {
			setNoData();
			setDataFiltered();
		}
	};
	const handleChangeSearch = (value) => {
		setValueSearchBar(value);
		if (skuListArray) {
			search(skuListArray, value);
		}
	};

	return (
		<>
			<ModalHeader
				title={<>{targetCondition.label} ({skuListArray && skuListArray.length} items)</>}
				desc={stateOfModificationSkuList ? targetCondition.inputSplitterInstructions : ''}
			/>
			{stateOfModificationSkuList ?
				<>
					<ModalBody>
						<InputCustom
							type="textarea"
							fullWidth={true}
							value={skuModifyValue}
							onChange={handleChange}
							placeholder={`Example: ${targetCondition.inputExample}`}
							autogrow={true}
							rows={10}
							maxHeight={360}
						/>
					</ModalBody>
					<ModalFooter
						hasBorder={true}
						primaryAction={
							<Btn
								icon="fas fa-plus fa-sm"
								message="Add to my list"
								onClick={addItem}
								disabled={skuModifyValue.length === 0}
							/>
						}
						secondaryAction={
							<Btn
								message="Cancel"
								style="ghost"
								color="secondary"
								onClick={() => { setSkuModifyValue(''); setStateOfModificationSkuList(false); }}
							/>
						}
					/>
				</>
				:
				<>
					<ModalSection color="grey" size="s">
						<div className='flex'>
							<div className='flex_item_full'>
								<SearchBar
									value={valueSearchBar}
									onChange={handleChangeSearch}
									placeholder="Search for an item"
									color='white'
									fullWidth={false}
								/>
							</div>
							<div className='flex_item_fix ml_20'>
								<Btn
									message="Add items"
									onClick={() => { setStateOfModificationSkuList(true); setValueSearchBar(''); setDataFiltered(); setNoData(); }}
									icon="fas fa-plus fa-sm"
								/>
							</div>
						</div>
					</ModalSection>
					<ModalBody>
						{noData ?
							<div className="noData">
								{noData}
							</div>
							:
							<List size='xxs'>
								{!dataFiltered && skuValue && skuValue.map((elem, i) =>
									<ListItem
										key={i}
										text={<span className='s_14 break_all'>{elem}</span>}
										actions={
											<IconBtn
												icon="fas fa-trash"
												tooltip="Delete URL"
												onClick={(e) => deleteProp(elem)}
											/>
										}
									/>
								)}
								{dataFiltered && dataFiltered.map((elem, i) =>
									<ListItem
										key={i}
										text={<span className='s_14 break_all'>{elem}</span>}
										actions={
											<IconBtn
												icon="fas fa-trash"
												tooltip="Delete URL"
												onClick={(e) => deleteProp(elem)}
											/>
										}
									/>
								)}
							</List>
						}
					</ModalBody>
					<ModalFooter
						hasBorder={true}
						primaryAction={
							<Btn
								message="Save"
								onClick={() => { setIsSaved(true); saveChange(); setChangeOnList(false); }}
							/>
						}
						secondaryAction={
							<Btn
								message="Cancel"
								style="ghost"
								color="secondary"
								onClick={() => setModalIsOppen(false)}
							/>
						}
					/>
				</>
			}
		</>
	);
}
