import React, { createContext, useState, useContext, useEffect } from 'react';
import { AiInsight } from './AiInsightsApiTypesServices';
import {aiInsightsBridgeCrud} from '../api/CrudInsights';

type AiInsightsContextType = {
  aiInsights: AiInsight[] | null;
  isLoading: boolean;
  error: string | null;
  refreshAiInsights: () => void;
};

interface AiInsightsContextProviderProps {
  $http: any; 
  children?: React.ReactNode;
  $rootScope: any;
  $routeParams: any;
  $timeout: any;
  $location: any;
  AuthServices: any;
}

const AiInsightsContext = createContext<AiInsightsContextType | undefined>(undefined);

// Custom hook to use the AiInsightsContext
export const useAiInsightsContext = () => {
	const contextValue = useContext(AiInsightsContext);
	if (contextValue === undefined) {
		throw new Error('useAiInsightsContext must be used within an AiInsightsContextProvider');
	}
	return contextValue;
};

// Context provider component
export const AiInsightsContextProvider: React.FC<AiInsightsContextProviderProps> = ({ $http, children, $rootScope, $routeParams, }) => {
	const [aiInsights, setAiInsights] = useState<AiInsight[] | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const { getAiInsights } = aiInsightsBridgeCrud($http,$rootScope,$routeParams);

	const fetchAiInsights = () => {
		setIsLoading(true);
		setError(null);
		getAiInsights(
			(data: AiInsight[]) => {
				setAiInsights(data);
				setIsLoading(false);
			},
			(err: any) => {
				console.error('Error fetching AI Insights:', err);
				setAiInsights(
					err
				);
				setError('An error occurred while fetching AI Insights.');
				setIsLoading(false);
			}
		);
	};

	useEffect(() => {
		fetchAiInsights();
	}, []);

	const refreshAiInsights = () => {
		fetchAiInsights();
	};

	return (
		<AiInsightsContext.Provider
			value={{
				aiInsights,
				isLoading,
				error,
				refreshAiInsights,
			}}
		>
			{children}
		</AiInsightsContext.Provider>
	);
};

export default AiInsightsContextProvider;
