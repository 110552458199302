import React from 'react'
import Insight from '../Insight/Insight'

export default function OverviewBavWithCamparison({
	bav, uniqueVisitors
}) {

	function getDataByName(name) {
		if (!bav.hasOwnProperty('periode1')) {
			const obj = {
				loading: true,

			}
			return obj
		}

		function getPercentVariation() {
			switch (name) {
				case 'Revenue':
					return bav.numberOfSessionsPercentVariation || 0
				case 'SessionToSaleRatio':
					return bav.numberOfPageViewsPercentVariation || 0
				case 'TransactionCount':
					return bav.numberOfBouncesPercentVariation || 0
				case 'TransactionAverageAmount':
					return bav.durationSecondsPercentVariation || 0

				default:
					break;
			}
		}
		const propdDataToReturn = {
			tendance: getPercentVariation(),
			periode1: { ...bav.periode1[name] },
			periode2: { ...bav.periode2[name] },
		}

		return propdDataToReturn;
	}
	function getDatasForUniqueVisitor() {
		if (uniqueVisitors.loading) {
			return {
				loading: true,

			}
		}

		return {
			tendance: uniqueVisitors.uniqueVisitorsPercentVariation || 0,
			periode1: {
				value: uniqueVisitors.periode1,
				sign: "",
				isError: false,
				loading: false,
			},
			periode2: {
				value: uniqueVisitors.periode2,
				sign: "",
				isError: false,
				loading: false,
			},
		}
	}
	return (
		<>
			<Insight label="Average page views" {...getDataByName('avViews')} name='avViews' isCompare={true} />
			<Insight label="Visit duration" {...getDataByName('vDuration')} name='vDuration' isCompare={true} />
			<Insight label="Bounce rate" {...getDataByName('bRate')} name='bRate' isCompare={true} />
			<Insight label="Unique visitors" {...getDatasForUniqueVisitor()} isCompare={true} />
		</>
	)
}
