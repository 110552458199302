import React, { useState, useEffect, Fragment } from 'react';
import { CampaignsContext } from '../context';
import { UnControlled as CodeMirror } from 'react-codemirror2';

import 'codemirror/theme/ttcn.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/keymap/sublime';

export default function CSSEditor(props) {

	const {
		step,
		getCurrentVariation,
		editorState,
		currentDevice,
		showCSSEditor,
		setShowCSSEditor,
		handleUpdateCss,
	} = CampaignsContext();

	const cssOptions = {
		mode: 'css',
		theme: 'ttcn',
		lineNumbers: true,
		lineWrapping: true,
		indentUnit: 2,
		autocorrect: true,
		readOnly: false,
		keyMap: 'sublime'
	};

	const htmlOptions = {
		mode: 'html',
		theme: 'ttcn',
		lineNumbers: true,
		lineWrapping: true,
		indentUnit: 2,
		autocorrect: true,
		readOnly: true
		//keyMap: "sublime"
	};


	const activeVariation = getCurrentVariation() || {};
	const descriptor = currentDevice === 'desktop' ? activeVariation.TemplateDescriptorDesktop : activeVariation.TemplateDescriptorMobile;
	const html = (editorState.currentWysiwyg && editorState.currentWysiwyg.Template) || '';
    
	return step === 1 && editorState.sideBarStep == 3 && showCSSEditor && (
		<div className="css_editor flex flex_align_stretch">
			<div className="css_editor_panel flex_item_half flex flex_v">
				<div className="css_editor_panel_header flex_item_fix">HTML (read only)</div>
				<div className="css_editor_panel_body flex_item_full code_mirror_full">
					<CodeMirror
						value={html}
						options={htmlOptions}
					/>
				</div>
			</div>
			<div className="css_editor_panel flex_item_half flex flex_v">
				<div className="css_editor_panel_header flex_item_fix">CSS</div>
				<div className="css_editor_panel_body flex_item_full code_mirror_full">
					<CodeMirror
						value={descriptor && descriptor.EditableCss || ''}
						options={cssOptions}
						autoCursor={false}
						onChange={(editor, data, value) => { handleUpdateCss(value); }}
					/>
				</div>
			</div>
			<a className="css_editor_close" onClick={(e) => setShowCSSEditor(false)}>
				<i className="fas fa-times"></i>
			</a>
		</div>
	);
}
