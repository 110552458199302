import React, { createContext, useState, useEffect, useContext, useReducer, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { serverSideCampaignDefault } from '../context/newCampaignPrototype';

const tooltipID = uuidv4();

export const context = createContext();

export function serverSideCampaignsDashboardContext() {
	return useContext(context);
}


export const ContextProvider = ({
	getCampaignList,
	getCampaignInfos,
	createNewCampaign,
	updateCampaign,
	deleteCampaign,
	systemServices,
	linkToReporting,
	$rootScope,
	children
}) => {

	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [listOfCampaigns, setListOfCampaigns] = useState([]);
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState(false);
	const [loading, setLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [isError, setIsError] = useState(false);
	const [creationModalIsOpened, setCreationModalIsOpened] = useState(false);
	const [campaignSelectedForDetail, setCampaignSelectedForDetail] = useState({});
	const [campaignSelectedForEdit, setCampaignSelectedForEdit] = useState(null);
	const [detailPanelIsOpened, setDetailPanelIsOpened] = useState(false);

	const permissionsAccount = Object.keys(
		$rootScope.$$childHead.permissions
	).map((x) => ({
		Name: x,
		Value: $rootScope.$$childHead.permissions[x],
	}));

	const toggleAddItem = () => {
		setCreationModalIsOpened(true);
	};

	const reload = () => {
		getCampaignList(data => {
			setLoading(false);
			setTotalCount(data.length);
			setListOfCampaigns(data);
			setIsError(false);
			setNoData(data.length == 0);
		}, err => {
			setLoading(false);
			setTotalCount(0);
			setListOfCampaigns([]);
			setIsError(true);
			setNoData(true);
		});
	};

	useEffect(() => {
		reload();
	}, []);

	const handleCampaignSelected = (campaignId) => {
		getCampaignInfos(campaignId, data => {
			setCampaignSelectedForDetail(data);
			setDetailPanelIsOpened(true);
		}, () => {
			systemServices.showError('An error occurred while getting campaign information');
		});
	};

	const handleSearchChange = (event) => {
		const value = event.target.value;
		const str = value.trim();
		setValueInSearchBar(value);
		if (str.length === 0) {
			setDataFiltered([]);
		}
		if (listOfCampaigns) {
			search(listOfCampaigns, str);
		}
	};

	const search = (table, word) => {
		setLoading(true);
		if (word.length > 0) {
			setLoading(false);
			setDataFiltered([]);
			let resultName = table.filter(
				line =>
					line.name && line.name.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			if (resultName.length === 0) {
				setNoData(true);
			}
			else {
				setNoData(false);
				setLoading(false);
				setDataFiltered([...resultName]);
			}

		} else {
			setNoData(false);
			setLoading(false);
			setDataFiltered();
		}
	};

	const handleValidateCampaignEdition = (editionInfo) => {
		if (campaignSelectedForEdit != null){
			updateCampaign(campaignSelectedForEdit.campaignId, { name: editionInfo.name, description: editionInfo.description }, 
				() => {
					reload();
					setCampaignSelectedForEdit(null);
				},
				() => {
					systemServices.showError('An error occurred while updating the campaign');
					setCampaignSelectedForEdit(null);
				});

		} else {
			createNewCampaign({ ...serverSideCampaignDefault(), Name: editionInfo.name, Description: editionInfo.description },
			() => {
				reload();
			},
			() => {
				systemServices.showError('An error occurred while creating the campaign');
			});
		}
	};

	const handleDeleteCampaign = (campaignId) => {
		deleteCampaign(campaignId,
			() => {
				reload();
			},
			() => {
				systemServices.showError('An error occurred while deleting the campaign');
			});
	};

	const openEditCampaign = (campaign) => {
		setCampaignSelectedForEdit(campaign);
		setCreationModalIsOpened(true);
	};

	return (
		<context.Provider
			value={{
				listOfCampaigns,
				toggleAddItem,
				loading,
				totalCount,
				handleSearchChange,
				valueInSearchBar,
				dataFiltered,
				noData,
				isError,
				creationModalIsOpened,
				setCreationModalIsOpened,
				openEditCampaign,
				handleValidateCampaignEdition,
				campaignSelectedForEdit,
				detailPanelIsOpened,
				handleCampaignSelected,
				handleDeleteCampaign,
				setDetailPanelIsOpened,
				campaignSelectedForDetail,
				linkToReporting,
				permissionsAccount
			}}
		>
			<ReactTooltip
				Id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={600}
			/>
			{children}
		</context.Provider>
	);
};