import React ,{useState} from 'react';


import Btn from '../../../Components/Btn';

import InputCustom from '../../../Components/InputCustom';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
import SectionMessage from '../../../Components/SectionMessage';
import { FeedCardHeader } from './FeedCardHeader';
const isAnUrl = (url) => {
	if (url) {
		if (url.match(/(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/)) {
			return true;
		} else {
			return false;
		}
	}
};
export default function CreateMazeberry() {
	const {

		stateOfBackendDetection,
		handleChangeSimpleField,
		productFeedObject,
		handleGetMappingSources,
		messageInfos,
		isEmptyIdentifier,
		handleGoingToMappingMaz
	} = useProductFeedEditionContext();

	type Error ={
		key : string,
		message : string
	};
	const [errors, seterrors] = useState<Array<Error>>([]);
	function handleChangeSimpleFieldFn(e) {
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name, value);
	}

	function isReadyToConnect(productFeedObject){
		const err:Array<Error> = [];
		if(productFeedObject.tokenServiceUrl){
			if(productFeedObject.tokenServiceUrl.length === 0 || !isAnUrl(productFeedObject.tokenServiceUrl) ){
				err.push({
					key : 'tokenServiceUrl',
					message : 'It must be an url'
				});
			}
		}else{
			err.push({
				key : 'tokenServiceUrl',
				message : 'It must be an url'
			});
		}

		if(productFeedObject.resourceIdentifier){
			if(!isAnUrl(productFeedObject.resourceIdentifier) ||productFeedObject.resourceIdentifier.length === 0 ){
				err.push({
					key : 'resourceIdentifier',
					message : 'It must be an url'
				});
			}
		}else{
			err.push({
				key : 'resourceIdentifier',
				message : 'It must be an url'
			});
		}
		if(productFeedObject.productUrlBasePath && productFeedObject.productUrlBasePath.length !== 0){
			if(!isAnUrl(productFeedObject.productUrlBasePath) ){
				err.push({
					key : 'productUrlBasePath',
					message : 'It must be an url'
				});
			}
		}
		if(productFeedObject.productUrlThumbnailBasePath && productFeedObject.productUrlThumbnailBasePath.length !== 0){
			if(!isAnUrl(productFeedObject.productUrlThumbnailBasePath) ){
				err.push({
					key : 'productUrlThumbnailBasePath',
					message : 'It must be an url'
				});
			}
		}
		
		if(err.length === 0){
			seterrors([]);
			handleGoingToMappingMaz('Mazeberry');
		}else{
			seterrors(err);
		}
	}
	function findError(errors,k){
		const err = errors.find(x => x.key === k);
		if(err){
			return err.message;
		}else{
			return;
		}
	}

	return (<>
		{stateOfBackendDetection === 'ResourceIdentifier' &&
			<>
				<FeedCardHeader title="What is your feed URL?" />
				<InputCustom
					value={productFeedObject.tokenServiceUrl ?? ''}
					type={'text'}
					label={'Token url'}
					name={'tokenServiceUrl'}
					placeholder="https://exemple.com/rest/V1/integration/token?username=john&password=aaa"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					onEnter={() => handleGetMappingSources()}
					fullWidth={true}
					size="l"
					autoFocus={true}
					isAnError={findError(errors,'tokenServiceUrl')}
				/>
				<InputCustom
					value={productFeedObject.resourceIdentifier ?? ''}
					type={'text'}
					label={'Ressource url'}
					name={'resourceIdentifier'}
					placeholder="https://exemple.com/rest/V1/mazeberry"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					onEnter={() => handleGetMappingSources()}
					fullWidth={true}
					size="l"
					autoFocus={true}
					isAnError={findError(errors,'resourceIdentifier')}
				/>
				<InputCustom
					value={productFeedObject.productUrlBasePath  ?? ''}
					type={'text'}
					label={'Product base url'}
					name={'productUrlBasePath'}
					placeholder="https://exemple.com/"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					onEnter={() => handleGetMappingSources()}
					fullWidth={true}
					size="l"
					autoFocus={true}
					isAnError={findError(errors,'productUrlBasePath')}
				/>
				<InputCustom
					value={productFeedObject.productUrlThumbnailBasePath ?? ''}
					type={'text'}
					label={'Thumbnail base URL'}
					name={'productUrlThumbnailBasePath'}
					placeholder="https://exemple.com/"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					onEnter={() => handleGetMappingSources()}
					fullWidth={true}
					size="l"
					autoFocus={true}
					isAnError={findError(errors,'productUrlThumbnailBasePath')}
				/>
				
				<div>
					{isEmptyIdentifier && 
				<SectionMessage
					type={messageInfos.type}
					title={messageInfos.title}
					text={messageInfos.text}
					verticalSize="s"
				/>
					}
				</div>
				<div className='mt_30 al_right'>
					<Btn
						onClickFunction={() => isReadyToConnect(productFeedObject)}
						size="l"
						message="Connection"
						color="primary" />
				</div>
			</>
		}

		

	
	</>
	);
}
