import React from 'react';
import NavEcommerce from './NavEcommerce';
import UtmEcommerce from './UtmEcommerce';
import SelectFilter from '../../../Components/SelectFilter';
import { useEcommerceContext } from '../context/ContextEcommerce';
import {
	devices,
	pageTypes,
	engagementLevel
} from '../Traffic/config';
import { useTranslation } from 'react-i18next';
export default function EcommerceFilters() {
	const {
		deviceSelected,
		handleChangeDeviceSelected,
		resetDevices,
		handleChangePageType,
		resetPageType,
		pageTypeSelected,
		handleChangeIntentionSelected,
		intentionSelected,
		resetIntention
	} = useEcommerceContext();
  
	const [t] = useTranslation(['kpi', 'utils']);
	function getTranslatedArray(arr, dimension) {
		return arr.map((l) => {
			const copy = {...l};
			if (copy.translation) {
				copy.label = t(copy.translation.key, copy.translation.vars);
			} else {
				copy.label = t(dimension + '.' + copy.label);
			}
			return copy;
		});
	}
	const engagementLevelTranslated = getTranslatedArray(engagementLevel, 'engagementLevel');
	const pageTypesTranslated = getTranslatedArray(pageTypes, 'pageType');
	const devicesTranslated = getTranslatedArray(devices, 'device');

	return (
		<>
			<div className='btn_group_l'>
				<NavEcommerce />
				<SelectFilter
					label={'device'}
					onChange={handleChangeDeviceSelected}
					onReset={resetDevices}
					value={deviceSelected}
					optionsList={devicesTranslated}
				/>
				<SelectFilter
					label={t('data.pageType')}
					onChange={handleChangePageType}
					onReset={resetPageType}
					value={pageTypeSelected}
					optionsList={pageTypesTranslated}
				/>
				<SelectFilter
					label={t('data.engagementLevel')}
					onChange={handleChangeIntentionSelected}
					onReset={resetIntention}
					value={intentionSelected}
					optionsList={engagementLevelTranslated}
				/>
				<UtmEcommerce />
			</div>
		</>
	);
}
