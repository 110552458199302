import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import { listTz } from 'timezone-select-js';
import moment from 'moment';
import 'moment-timezone';
import SelectDropdown from '../../../Components/SelectDropdown';
import Portal from '../../../Components/Portal';
import Btn from '../../../Components/Btn';
import Fieldset from '../../../Components/Fieldset';

const PopperContainer = ({children}) => {
	return (
		<Portal>
			{children}
		</Portal>
	);
};

const timeSlotList = [];
for (let hour = 0; hour < 24; hour++) {
	for (let minute = 0; minute < 60; minute += 15) {
		let timeSlot = hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
		timeSlotList.push({
			label: timeSlot,
			value: timeSlot
		});
	}
}

export default function DateEditor({ property, data, callBack, propertyContainer, label, isCountDown = false }) {

	// Timezone 
	const getTimeZoneByValue = (list, value) => {
		var lgt = list.length;
		for (var i = 0; i < lgt; i++) {
			var tz = list[i];
			if (tz.value === value) {
				return tz;
			}
		}
		return {};
	};

	const [date, setDate] = useState();
	const [countDownType, setCountDownType] = useState('date');
	const siblingProperties = propertyContainer && propertyContainer.siblingPropertiesAccessor;

	const getTimezoneProperty = () => siblingProperties.getSiblingPropertyData(property.TimezonePropertyName);
	const setTimezoneProperty = (v) => siblingProperties.setSiblingPropertyData(property.TimezonePropertyName, v);

	const timeZoneList = listTz();
	const timeZoneActive = property.Editor.TimezoneSelector ? true : false;
	const timeZoneDefaultValue = getTimezoneProperty() || moment.tz.guess() || 'Europe/Paris';
	const [timeZoneSelected, setTimeZoneSelected] = useState(getTimeZoneByValue(timeZoneList, timeZoneDefaultValue));

	const isCountDownAdvanced = isCountDown && property.Editor.IsAdvanced;
	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	const [selectedDays, setSelectedDays] = useState([false, false, false, false, false, true, false]);
	const [timeSlot, setTimeSlot] = useState('18:00');

	const handleChangeKey = (key, value) => {
		let tmpCountDownType = countDownType;
		let tmpDate = date;
		let tmpSelectedDays = selectedDays;
		let tmpTimeSlot = timeSlot;
		let tmpTimeZoneSelected = timeZoneSelected.value;

		if (key == 'countDownType') tmpCountDownType = value;
		if (key == 'date') tmpDate = value;
		if (key == 'selectedDays') tmpSelectedDays = value;
		if (key == 'timeSlot') tmpTimeSlot = value;
		if (key == 'timeZoneSelected') tmpTimeZoneSelected = value;

		if (tmpCountDownType == 'date') {
			let formatedDate;
			if (timeZoneActive) {
				formatedDate = moment(tmpDate)
					.tz(tmpTimeZoneSelected, true)
					.format('ddd MMM DD YYYY HH:mm [GMT]ZZ');
			} else {
				const dateWithoutZone = moment(tmpDate).format('ddd MMM DD YYYY HH:mm');
				const otherZone = moment.tz(tmpDate, tmpTimeZoneSelected).format('[GMT]ZZ');
				formatedDate = [dateWithoutZone, otherZone].join(' ');
			}
			const newDate = new String(formatedDate);
			callBack(newDate);
			return;
		}

		if (tmpCountDownType == 'hour') {
			// 18:00-Europe/Paris
			const str = new String(tmpTimeSlot + '-' + tmpTimeZoneSelected);
			callBack(str);
			return;
		}

		if (tmpCountDownType == 'hour_and_days') {
			// 18:00-Europe/Paris-0:2:3
			const days = [];
			for (let i = 0; i < tmpSelectedDays.length; i++) {
				const d = tmpSelectedDays[i];
				if (d) days.push(i);
			}
			const daysStr = days.join(':');
			const str = new String(tmpTimeSlot + '-' + tmpTimeZoneSelected + '-' + daysStr);
			callBack(str);
			return;
		}
	};

	const toggleSelectedDay = (day) => {
		const copy = [...selectedDays];
		const nbSelected = copy.filter(d => d == true).length;
		if (nbSelected == 1 && copy[day] == true) return;
		copy[day] = copy[day] ? false : true;
		return copy;
	};

	const handleChangeDate = (v) => {
		setDate(v);
		handleChangeKey('date', v);
	};

	const handleChangeTimeZoneSelected = (v) => {
		setTimeZoneSelected(v);
		setTimezoneProperty(v ? v.value : '');
		handleChangeKey('timeZoneSelected', v ? v.value : '');
	};

	const handleChangeCountDownType = (v) => {
		setCountDownType(v);
		handleChangeKey('countDownType', v);
	};

	const handleChangeSelectedDays = (v) => {
		const days = toggleSelectedDay(v);
		if (!days) return;
		setSelectedDays(days);
		handleChangeKey('selectedDays', days);
	};

	const handleChangeTimeSlot = (v) => {
		setTimeSlot(v);
		handleChangeKey('timeSlot', v);
	};

	useEffect(() => {
		if (!data) return;

		const matchHourFormat = data.match(/^(\d?\d:\d\d)-(.*)/g);

		if (!matchHourFormat) {
			let date;
			const d = moment(moment.parseZone(data.toString()).format('YYYY-MM-DDTHH:mm:ss.SSS'));
			if (d._i === 'Invalid date') {
				date = moment().toDate();
			} else {
				date = d.toDate();
			}
			setCountDownType('date');
			setDate(date);
			return;
		}

		const sp = data.split('-');
		const tmpTimeSlot = sp[0];
		const tmpTimeZoneSelected = sp[1];
		const tmpSelectedDays = sp[2];

		if (tmpTimeSlot) {
			setTimeSlot(tmpTimeSlot);
		}
		if (tmpTimeZoneSelected) {
			setTimeZoneSelected(getTimeZoneByValue(timeZoneList, tmpTimeZoneSelected));
		}
		if (tmpSelectedDays) {
			const daysSplit = tmpSelectedDays.split(':');
			const copy = [...selectedDays];
			for (let i = 0; i < copy.length; i++) {
				copy[i] = daysSplit.includes(i.toString());
			}
			setSelectedDays(copy);
		}
		setCountDownType(tmpSelectedDays ? 'hour_and_days' : 'hour');
	}, []);

	return (
		<>
			{isCountDownAdvanced &&
				<>
					<div className='format_param'>
						<div className='format_param_inner'>
							<SelectDropdown
								label="Countdown type"
								optionsList={[
									{label: 'End date', value: 'date'},
									{label: 'Every day', value: 'hour'},
									{label: 'Every week', value: 'hour_and_days'}
								]}
								value={countDownType}
								onChange={(v) => handleChangeCountDownType(v)}
								ellips={true}
							/>
						</div>
					</div>
					<div className="format_param_break"></div>
				</>
			}
			{countDownType == 'hour_and_days' && 
				<div className='format_param'>
					<div className='format_param_inner'>
						<Fieldset label="End days">
							<div className='btn_switch'>
								{days.map((d, i) => 
									<Btn
										key={i}
										style="outline"
										color="secondary"
										light={true}
										isActive={selectedDays[i] ? true : false}
										message={d}
										size={'s'}
										horizontalSize={'xs'}
										className='btn_no_min_width'
										onClick={() => handleChangeSelectedDays(i)}
									/>
								)}
							</div>
						</Fieldset>
					</div>
				</div>
			}
			{(countDownType == 'hour_and_days' || countDownType == 'hour') && 
				<div className='format_param w_m'>
					<div className='format_param_inner'>
						<SelectDropdown
							label="End hour"
							optionsList={timeSlotList}
							value={timeSlot}
							onChange={(v) => handleChangeTimeSlot(v)}
							ellips={true}
							fullWidth={true}
						/>
					</div>
				</div>
			}
			{countDownType == 'date' && 
				<div className='format_param w_m'>
					<div className='format_param_inner'>
						<Fieldset label={isCountDown ? 'End date' : label}>
							{property.Description && (
								<div className="form_block_description">
									{property.Description}
								</div>
							)}
							
							<label className="custom_input">
								<ReactDatePicker
									selected={date}
									onChange={(d) => handleChangeDate(d)}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="dd MMM yyyy - H:mm"
									showPopperArrow={false}
									popperContainer={PopperContainer}
								/>
								<i className="custom_input_icon fas fa-calendar-alt"></i>
							</label>
						</Fieldset>
					</div>
				</div>
			}
			{timeZoneActive && (
				<div className='format_param w_m'>
					<div className='format_param_inner'>
						<Fieldset label="Timezone">
							<Select
								options={timeZoneList}
								defaultValue={timeZoneSelected}
								onChange={(e) => handleChangeTimeZoneSelected(e)}
								menuPlacement="auto"
								className="react-select"
								classNamePrefix="react-select"
							/>
						</Fieldset>
					</div>
				</div>
			)}
			<div className="format_param_break"></div>
		</>
	);
}


