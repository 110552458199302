import React , {useState, useEffect} from 'react';
import ModalXmlSettings from './ModalXmlSettings';
import Confirm from '../../../Components/Confirm';
import Btn from '../../../Components/Btn';
import IconBtn from '../../../Components/IconBtn';
import Fieldset from '../../../Components/Fieldset';
import {useProductFeedEditionContext} from '../context/ProductFeedEditionContext';
import editionStyles from '../Edition/ProductFeedEdition.module.css';

export default function XmlSettingsContainer({namespaces}) {
	const {handleChangeXmlNameSpace} = useProductFeedEditionContext();
	const [xmlModalIsOpen, setxmlModalIsOpen] = useState(false);
	const [namespaceSelected, setNamespaceSelected] = useState();
	const [showDelConfirm, setshowDelConfirm] = useState(false);
	const [isExistingObj, setisExistingObj] = useState(false);
	const [deleteNameSpace, setdeleteNameSpace] = useState();
	const objectToMap = Object?.keys(namespaces || [])?.map(
		(element, i) => {
			return {
				key : i,
				existingKey : element,
				prefix : element,
				nameSpace : namespaces[element]};
		}
	);

	function deletenameSpace(nameS){
		setdeleteNameSpace(nameS);
		setshowDelConfirm(true);
	}
	function deletenameSpaceConfirm(){
		const newObj = objectToMap.filter(el => el.existingKey !==deleteNameSpace.existingKey );
		setshowDelConfirm(false);
		setisExistingObj(false);
		setNamespaceSelected();
		handleChangeXmlNameSpace(newObj);
	}
	function close(){
		setshowDelConfirm(false);
		setisExistingObj(false);
		setNamespaceSelected();
	}
	function handleSelectNamespace(nameS){
		setxmlModalIsOpen(true);
		setNamespaceSelected(nameS);
		setisExistingObj(true);
	}
	function handleChangeNameSpace(e){
		const nameOfField = e.target.name;
		const valueOfField = e.target.value;
		setNamespaceSelected(
			prevNamespace=>({
				...prevNamespace,
				[nameOfField] :valueOfField,
			}));
	}
	function submitNewNameSpace(){
		if(isExistingObj){
			const namespacesObj = [...objectToMap];
			const oldObject = objectToMap.find(el => el.existingKey ===namespaceSelected.existingKey );
			const newObj = {...namespaceSelected};
			namespacesObj.splice(oldObject.key,1,newObj);
			handleChangeXmlNameSpace(namespacesObj);
		}
		else{
			const newArrayOfNameSpaces = [...objectToMap,namespaceSelected];
			handleChangeXmlNameSpace(newArrayOfNameSpaces);
		}
		
	}
	function handleCreateEmptyNameSpace(){
		setxmlModalIsOpen(true);
		setisExistingObj(false);
		setNamespaceSelected({
			prefix : '',
			nameSpace : ''
		});
	}


	return (
		<Fieldset label="Namespaces">
			{objectToMap.length !== 0 &&
				<div className='mb_10'>
					<div className={editionStyles.table + ' ' +editionStyles.table_namespace}>
						<div className={editionStyles.table_head_row}>
							<div className={editionStyles.table_col}>Prefix</div>
							<div className={editionStyles.table_col}>Namespace</div>
							<div className={editionStyles.table_col}></div>
						</div>
						{objectToMap.map((namespace, i) => {
							return (
								<div className={editionStyles.table_body_row} key={i}>
									<div className={editionStyles.table_col}>
										{namespace.prefix}
									</div>
									<div className={editionStyles.table_col}>
										{namespace.nameSpace}
									</div>
									<div className={editionStyles.table_col}>
										<IconBtn
											tooltip="Edit namespace"
											icon="fas fa-pen"
											onClick={() => handleSelectNamespace(namespace)}
										/>
										<IconBtn
											tooltip="Delete namespace"
											icon="fas fa-trash"
											onClick={() => deletenameSpace(namespace)}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			}
			<Btn
				onClick={() => handleCreateEmptyNameSpace()}
				message="Add namespace"
				icon="fas fa-plus fa-sm"
				style="reverse"
				horizontalSize="xs"
			/>
			<ModalXmlSettings
				xmlModalIsOpen={xmlModalIsOpen}
				setxmlModalIsOpen={setxmlModalIsOpen}
				namespaceSelected={namespaceSelected}
				isExistingObj={isExistingObj}
				handleChangeNameSpace={handleChangeNameSpace}
				submitNewNameSpace={submitNewNameSpace}
			/>
			
			<Confirm
				title={<>Do you really want to delete <br/>this namespace?</>}
				text="This action is irreversible"
				confirmText="Delete"
				confirmColor="alert"
				cancelText="Cancel"
				isOpen={showDelConfirm}
				confirmCallback={deletenameSpaceConfirm}
				onClose={close}
			/>
		</Fieldset>
	);
}
