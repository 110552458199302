import React, { useContext, useEffect, useState } from 'react'
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import Btn from '../../../Components/Btn';
import Modal from '../../../Components/Modal';
import Checkbox from '../../../Components/Checkbox';
export default function Shepherd(props) {
    const tour = useContext(ShepherdTourContext);
    props.root.tour = tour;
    const [steps, setsteps] = useState([]);
    const do_not_show_modal = false; /*localStorage.getItem('hide_tour_intro');*/

    useEffect(() => {
        if( do_not_show_modal ){
            tour.start();
            props.setmodalIsOpen(false);
        }
    }, []);

    useEffect(() => {
        if (props.root.tour) {
            setsteps(props.root.tour.steps);
        }
    }, [props.root.tour])

    useEffect(() => {
        if (!props.modalIsOppen) {
            tour.complete()
            tour.hide()
        }
    }, [props.modalIsOppen]);

    props.root.$on("event:shepherdCloseTheTour", function (event, evt) {
        tour.hide();
    });

    props.root.$on("event:shepherdTourNext", function (event, evt) {
        if (tour.isActive()) {
            tour.next();
        }
    });

    props.root.$on("event:shepherdTourBack", function (event, evt) {
        if (tour.isActive()) {
            tour.back();
        }
    });

    const hide_next_time_change = function(ev){
        if( ev.target.checked ){
            localStorage.setItem('hide_tour_intro', '1');
        }else{
            localStorage.removeItem('hide_tour_intro');
        }
    };

    return (
        <>
            {do_not_show_modal == false &&
                <Modal
                    isOpen={props.modalIsOppen}
                    width="540"
                    noClose={false}
                    onClose={(e) => props.setmodalIsOpen(false)}
                    noClose={true}
                >
                    <div className="modal_body">
                        <div className="modal_title">Welcome to the campaign creation tutorial.</div>
                        <div className="grey_0 mt_20">
                            We will guide you, step by step, to create your campaign.
                        </div>
                        <div className="info_box grey_0 mt_20 s_14 flex">
                            <div className="flex_item_fix mr_10">
                                <span className="icon_32 icon_color_blue icon_idea"></span>
                            </div>
                            <div className="flex_item_full">
                                <div><span className="fw_medium up_case">Objective :</span> {props.moduleLabel}</div>
                                <div className="mt_3"><span className="fw_medium up_case">Scenario :</span> {props.tourLabel}</div>
                            </div>
                        </div>
                        <div className="grey_1 mt_20">
                            <em>You can exit this tutorial and reopen-it whenever you need.</em>
                        </div>
                    </div>
                    <div className="modal_footer has_border flex">
                        <div className="flex_item_full">
                            <Checkbox onChange={(e) => hide_next_time_change(e)}>
                                <span className="grey_1 s_14">Do not show this message again</span>
                            </Checkbox>
                        </div>
                        <div className="flex_item_fix">
                            <Btn
                                onClickFunction={(e) => { tour.start(); props.setmodalIsOpen(false) }}
                                message="Let's start !"
                                size="l"
                            />
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}
