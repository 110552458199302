import React, {useState, useEffect} from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Indicator from './Indicator';
import { ReportGeneratorContext } from './Context/index.js';

export default function KpiDraggable({indicators}) {
    const [indicatorsSelected, setindicatorsSelected] = useState<any[]>([]);
    const {
		handleChangeCpgOptions,
	} = ReportGeneratorContext();
	const onDragEnd = (result) => {
		if (!result.destination || !indicatorsSelected) return;
		const items = [...indicatorsSelected];
		const [moved] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, moved);
		handleCampaignOrderChange(items);
	};
	const handleCampaignOrderChange = (items) => {
		handleChangeCpgOptions('indicators',items);
	
	};
    useEffect(()=>{
        setindicatorsSelected(indicators);
    },[indicators]);
  return (
    <div>
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
        {(provided, _snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
             {provided.placeholder}
        {indicators && indicators.map((indicator,idx) =>  <Draggable
                    key={idx}
                    draggableId={`control-${indicator}`}
                    index={idx}>
                        	{(provided, _snapshot) => (
                          <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Indicator
                            key={indicator}
                            value={indicator}/>

                      </div>)}
                </Draggable>)}
          </div>)}
        </Droppable>
     </DragDropContext>

    </div>
  )
}
