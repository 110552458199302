import React from 'react';
import editionStyles from '../Edition/ProductFeedEdition.module.css';
import commonStyles from '../ProductFeed.module.css';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
import {
	getFeedTypePictoClass,
	getFeedTypeName
} from '../utils';
import Link from '../../../Components/Link';
export default function SourceFeedListType() {
	const {
		handleChooseATypeSource,
		setStep
	} = useProductFeedEditionContext();

	const listOfSourcesFeed = [
		{
			label : 'Generic feed',
			subTile: 'Your feed is accessible via HTTP or FTP',
			key : 'GenericUri'
		},
		{
			label : 'PrestaShop',
			subTile: 'Your feed is generated by Prestashop',
			key : 'PrestaShop'
		},
		{
			label : 'Shopify',
			subTile: 'Your feed is generated by Shopify',
			key : 'Shopify'
		},
		{
			label : 'Easyence',
			subTile: 'Your feed is generated by Easyence (Mazeberry)',
			key : 'Mazeberry'
		},
		{
			label : 'HTTP API',
			subTile: 'Your feed is accessible via an HTTP API',
			key : 'Http'
		},
		{
			label : 'Custom',
			subTile: 'Use a custom feed',
			key : 'Custom'
		},
	];
	function onClickOnSiteData(){
		handleChooseATypeSource('OnSiteData');
		setStep(2);
	}

	return (
		<div className={editionStyles.card}>
			<div className={editionStyles.card_header_l}>
				<div className={editionStyles.card_subtitle}>Connection</div>
				<div className={editionStyles.card_title}>How to connect to your product feed?</div>
			</div>
			<div className={editionStyles.card_body_l}>
				<ul className={editionStyles.choice_card_group}>
					{listOfSourcesFeed.map(connector => 
						<li className={editionStyles.choice_card}
							key={connector.key}
							onClick={()=>handleChooseATypeSource(connector.key)}
						>
							<div className='flex'>
								<div className={editionStyles.card_picto}>
									<div className={commonStyles.picto + ' ' + commonStyles['picto_' + getFeedTypePictoClass(connector.key)]}></div>
								</div>
								<div className='flex_item_full flex flex_v'>
									<div className={editionStyles.choice_card_title}>{getFeedTypeName(connector.key)}</div>
									<div className={editionStyles.choice_card_desc}>{connector.subTile}</div>
								</div>
								<i className="flex_item_fix fa fa-chevron-right grey_4" />
							</div>
						</li>
					)}
				</ul>
				<div className={editionStyles.separator}></div>
				<div className="s_17 fw_medium mb_5">You don’t have a product feed or need to complete it?</div>
				<Link 
					message={<>Use data from <strong>BEYABLE</strong> website exploration</>}
					iconPosition='after'
					icon='fas fa-arrow-right fa-sm'
					onClick={()=>onClickOnSiteData()}
					strong={false}
				/>
			</div>
		</div>
		
	);
}
