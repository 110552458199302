/* eslint-disable @typescript-eslint/no-explicit-any */
import React,{Fragment, useEffect,useState} from 'react';
import { Panel, PanelHeader, PanelBody, PanelSection} from './../../Components/Panel/Panel';
import { ReportGeneratorContext } from './Context/index.js';
import Btn from './../../Components/Btn';
import {getIndicatorByKey} from '../ReportingCampaign/Indicators.js';
import SearchBar from '../../Components/SearchBar';
// import { formatIndicatorDisplayLabel } from '../ReportingCampaign/IndicatorsUtil';
import ReactTooltip from 'react-tooltip';
import Checkbox from './../../Components/Checkbox';
import { useTranslation } from 'react-i18next';

export default function ModalKpi({
	setmodalIsOpen,
	modalIsOpen
}) {
	const [t] = useTranslation('common');

	const [indicatorsToMap, setindicatorsToMap] = useState<any[]>([]);

	// const [targetIndicators, settargetIndicators] = useState<any[]>([]);
	const [categoryConversionData, setcategoryConversionData] = useState<any[]>([]);
	const [categoryOther, setcategoryOther] = useState<any[]>([]);

	const [interactionsIndicators, setinteractionsIndicators] = useState<any[]>([]);
	const [categoryTarget, setcategoryTarget] = useState<any[]>([]);
	const [categoryInteraction, setcategoryInteraction] = useState<any[]>([]);
	const [categoryDisplayData, setcategoryDisplayData] = useState<any[]>([]);

	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [dataFiltered, setDataFiltered] = useState([]);

	const [list, setlist] = useState(<></>);
	const {
		campaignSelected,
		selectedCampaignOptions,
		handleChangeCpgOptions,
		getKpisCampaign
	} = ReportGeneratorContext();



	const cleanInteractionLabel = (label) => {
		if (!label) return '';
		if (label.startsWith('evtcpg:'))
			return label.replace('evtcpg:', '');
		if (label.includes('_evtcpg:')){
			const split = label.split('_evtcpg:');
			const indicator = getIndicatorByKey(split[0]).label;
			return `${split[1]} (${indicator})`;
		}
		return label;
	};

	const formatIndicatorDisplayLabel = (indicator) => {
		const cleanLabel = (label) => {
			return label.includes('evtcpg:') ? cleanInteractionLabel(label) : label.replace('_', ' ');
		};
		return indicator.label ? cleanLabel(indicator.label) : indicator.key;
	};
	useEffect(() => {
		if (selectedCampaignOptions) {
			getKpisCampaign(selectedCampaignOptions.id);
		}
	}, [selectedCampaignOptions]);

	
	useEffect(()=>{
		if(indicatorsToMap){

			const categoryDisplayData2 = indicatorsToMap.filter(item => item.category === 'Display Data').map(el=> {
				const labelN = formatIndicatorDisplayLabel(el);
				return {...el, label :labelN};
			});
			setcategoryDisplayData(categoryDisplayData2);
			const categoryConversionData2 = indicatorsToMap.filter(item => item.category === 'Conversion Data').map(el=> {
				const labelN = formatIndicatorDisplayLabel(el);
				return {...el, label :labelN};
			});;
			setcategoryConversionData(categoryConversionData2);
			
			const categoryOther2 = indicatorsToMap.filter(item => item.category === 'Other' || item.category === '').map(el=> {
				const labelN = formatIndicatorDisplayLabel(el);
				return {...el, label :labelN};
			});
			setcategoryOther(categoryOther2);
			// let categoryTarget2 = targetIndicators.map(el => ({ ...getIndicatorByKey(el.key), key: el.key }));
			// if (campaignSelected.secondaryIndicators.length) {
			// 	categoryTarget2 = campaignSelected.secondaryIndicators && deleteTheKey(campaignSelected.secondaryIndicators, categoryTarget);
			// 	categoryTarget2 = categoryTarget2 && filterByIndicatorOfCampaign(categoryTarget2);
			// } else {
			// 	categoryTarget2 = campaignSelected.primaryIndicators && deleteTheKey(campaignSelected.primaryIndicators, categoryTarget2);
			// 	categoryTarget2 = categoryTarget && filterByIndicatorOfCampaign(categoryTarget2);
			// }
			// setcategoryTarget(categoryTarget2);

			
			setcategoryInteraction(interactionsIndicators);
		}
		
	},[indicatorsToMap, campaignSelected]);
	
	function handleChangeKpi (kpi) {
		const val = kpi.target.checked;
		const name = kpi.target.name;
		const hasIndicatorsarray = 	selectedCampaignOptions.hasOwnProperty('indicators');
		if(hasIndicatorsarray){
			let existantArrayIndicators:any[] = [...selectedCampaignOptions.indicators];
			if(val && !existantArrayIndicators.includes(name) ){
				existantArrayIndicators = [...existantArrayIndicators, name];
				handleChangeCpgOptions('indicators', existantArrayIndicators);
			}else{
				handleChangeCpgOptions('indicators', existantArrayIndicators.filter(x => x !== name));
			}
		}else{
			handleChangeCpgOptions('indicators', [name]);
		}

	}
	useEffect(()=>{
		if(campaignSelected && campaignSelected.indicators){
			setindicatorsToMap(campaignSelected.indicators.map(el => ({ ...getIndicatorByKey( typeof el === 'string' ? el : el.key ), key: typeof el === 'string' ? el : el.key })));
			const indicatorInteractions = campaignSelected?.interactionIndicators.map(el => ({ ...getIndicatorByKey( typeof el === 'string' ? el : el.key ), key: typeof el === 'string' ? el : el.key }));
			searchDataSelectableCampaign(indicatorInteractions, valueInSearchBar, setinteractionsIndicators );
			// setinteractionsIndicators(indicatorInteractions);
			const targetIndicators = campaignSelected.targets.map(el => ({ ...getIndicatorByKey( typeof el === 'string' ? el : el.key ), key: typeof el === 'string' ? el : el.key }));
			searchDataSelectableCampaign(targetIndicators, valueInSearchBar, setcategoryTarget );

		}
	},[campaignSelected, dataFiltered]);

	function findElementkeyInListSetup(elKey){
		if(selectedCampaignOptions?.indicators?.find(kp =>kp === elKey)){
			return true;
		}else{
			return false;
		}
	}

	useEffect(()=>{
		
		setlist(<div className="add_kpi">
			{categoryDisplayData && categoryDisplayData.length > 0 &&
		<div className="add_kpi_group">
			<div className="add_kpi_group_label">{t('AddKpiComponent.Display')} </div>
			<ul className="add_kpi_group_list">
				{categoryDisplayData.map((item, index) =>
					<li key={index}>
						<Checkbox
							name={item.key}
							label={item.label}
							value={()=>findElementkeyInListSetup(item.key)}
							onChange={(e) => handleChangeKpi(e)}
						>
							{item.infos &&
								<Fragment>
									<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
									<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
								</Fragment>}
						</Checkbox>	
					</li>
				)}
			</ul>
		</div>
			}
			{categoryConversionData && categoryConversionData.length > 0 &&
		<div className="add_kpi_group">
			<div className="add_kpi_group_label">{t('AddKpiComponent.ConversionData')}</div>
			<ul className="add_kpi_group_list">
				{categoryConversionData.map((item, index) =>

					<li key={index}>
						<Checkbox
							name={item.key}
							label={item.label}
							checked={findElementkeyInListSetup(item.key)}
							value={findElementkeyInListSetup(item.key)}
							onChange={(e) => handleChangeKpi(e)}
						>
						</Checkbox>	
						{item.infos &&
								<Fragment>
									<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
									<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
								</Fragment>}
					</li>

				)}
			</ul>
		</div>
			}
			{categoryOther && categoryOther.length > 0 &&
		<div className="add_kpi_group">
			<div className="add_kpi_group_label">{t('AddKpiComponent.Other')}</div>
			<ul className="add_kpi_group_list">
				{categoryOther.map((item, index) =>
					<li key={index}>
						<Checkbox
							name={item.key}
							label={item.label}
							checked={findElementkeyInListSetup(item.key)}
							value={findElementkeyInListSetup(item.key)}
							onChange={(e) => handleChangeKpi(e)}
						>
						
						</Checkbox>	{item.infos &&
								<Fragment>
									<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
									<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
								</Fragment>}
					</li>
				)}
			</ul>
		</div>
			}
			{categoryTarget && categoryTarget.length > 0 &&
		<div className="add_kpi_group flex_item_fix">
			<div className="add_kpi_group_label">{t('AddKpiComponent.Target')}</div>
			<ul className="add_kpi_group_list">
				{categoryTarget.map((item, index) =>
					<li key={index}>
						<li key={index}>
							<Checkbox
								name={item.key}
								label={item.label}
								checked={findElementkeyInListSetup(item.key)}
								value={findElementkeyInListSetup(item.key)}
								onChange={(e) => handleChangeKpi(e)}
							>
						
							</Checkbox>	{item.infos &&
								<Fragment>
									<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
									<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
								</Fragment>}
						</li>
					</li>
				)}
			</ul>
		</div>
			}
			{categoryInteraction && categoryInteraction.length > 0 &&
		<div className="add_kpi_group flex_item_fix">
			<div className="add_kpi_group_label">{t('AddKpiComponent.InteractionData')}</div>
			<ul className="add_kpi_group_list">
				{categoryInteraction.map((item, index) =>
					<li key={index}>
						<Checkbox
							name={item.key}
							label={item.label}
							checked={findElementkeyInListSetup(item.key)}
							value={findElementkeyInListSetup(item.key)}
							onChange={(e) => handleChangeKpi(e)}
						>
							
						</Checkbox>	{item.infos &&
								<Fragment>
									<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
									<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
								</Fragment>}
					</li>
					
				)}
			</ul>
		</div>
			}
		</div>);
	},[selectedCampaignOptions,categoryDisplayData, dataFiltered]);

	const handleChangeValueInSearchBar = (event) => {
		setValueInSearchBar(event.target.value);
	};

	type Line = {
		label : string;
		key: string;
	}

	const searchDataSelectableCampaign = (table:Line[], word, seter) => {
		if(word.length === 0){
			seter(table);
		}
		if (word.length > 0) {
		
			const wordSearch = `.*${word.toUpperCase()}.*`;
			const results:Line[] = [];
			for (let i = 0; i < table.length; i++) {
				let line:Line = {label: '', key : ''};
				line = table[i] ;
				if (line.label !== null && line.label !== undefined && line.label.toUpperCase().match(wordSearch)) {
					results.push(line);
					continue;
				}

				// if (line.key !== null && line.key !== undefined && line.key.toUpperCase().match(wordSearch)) {
				// 	results.push(line);
				// 	continue;
				// }


			}
			console.log('search result', results);

			if (results.length === 0) {
				seter([]);
				console.log('search vide');
			} else {
				seter(results);
			}
		}
	};
	useEffect(() => {
		const searchTimeout = setTimeout(() => {
			if(campaignSelected && campaignSelected.indicators){
				const cgKpi = [
					...campaignSelected.indicators.map(x=> x.key),
					...campaignSelected.secondaryIndicators.map(x=> x.key),
					...campaignSelected.primaryIndicators.map(x=> x.key)
				];
				const uniq = cgKpi.reduce(function(a,b){
					if (a.indexOf(b) < 0 ) a.push(b);
					return a;
				},[]);
				const kpiWithDataInfos = uniq.map(el => ({ ...getIndicatorByKey(el), key: el }));
				searchDataSelectableCampaign(kpiWithDataInfos, valueInSearchBar.trim(), setDataFiltered);
			}
		}, 300);
		
		return () => {
			clearTimeout(searchTimeout);
		};

	}, [valueInSearchBar, campaignSelected]);

	return (
		<Panel
			isOpen={modalIsOpen}
			width={500}
			onClose={() => { setmodalIsOpen(false); }}
		>
			<PanelHeader
				title="Add KPIs"
				desc={selectedCampaignOptions ? selectedCampaignOptions.name : ''}
				onClose={() => setmodalIsOpen(false)}
				primaryAction={
					<Btn
						message="Done"
						onClick={() => setmodalIsOpen(false)}
					/>
				}
			/>
			<PanelSection color='grey' size='s'>
				<SearchBar
					onChangeValue={handleChangeValueInSearchBar}
					valueInSearchBar={valueInSearchBar}
					placeholder='Find a KPI'
					fullWidth={true}
					color="white"
				/>
			</PanelSection>
			<PanelBody>
				{list}
			</PanelBody>
		</Panel>
	);
}
