import React from 'react';
import { useGroupSortingContext } from './contextGroupSorting';
import { ExistingProductGroupSorting, FilterParam } from './GroupSortingEntityTypes';

type GroupSortingCardType = {
	groupSorting: ExistingProductGroupSorting
};

export default function GroupSortingCard({ groupSorting }: GroupSortingCardType) {

	const {
		handleOpenEditGroupSorting,
		handleDeleteGroupSorting
	} = useGroupSortingContext();

	const handleRemove = (ev) => {
		ev.stopPropagation();
		handleDeleteGroupSorting();
	};

	const getFilterParamValue = (filterParam: FilterParam): string => {
		if (filterParam.value.stringValues && filterParam.value.stringValues.length > 0){
			return filterParam.value.stringValues[0];
		}		
		return '';
	};

	return (
		<div className='collection_sidebar_item s clickable'
			onClick={() => handleOpenEditGroupSorting(groupSorting)}>
			<div className='flex flex_align_stretch'>
				<div className='flex_item_full overflow_hidden'>
					<div>
						Products are grouped by <span className='fw_medium'>{groupSorting.filter.name}</span>
					</div>
					<div className='s_13 grey_2'>
						{groupSorting.filterParams && groupSorting.filterParams.length > 0 &&
							<>
								<span className='text_flag'>{groupSorting.filterParams.map(getFilterParamValue).filter(x => x).join(', ')}</span>
								{' '}
							</>
						}
						{groupSorting.groups.length} {groupSorting.groups.length > 1 ? 'groups' : 'group'}
					</div>
				</div>
				<div className='flex_item_fix flex flex_v flex_justify_between'>
					<div className="al_right">
						<a className='icon_btn xs collection_sidebar_item_del' onClick={(e) => handleRemove(e)}>
							<i className="fas fa-trash" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
