const initType = [
	{ key: 'Page', label: 'Page' }, 
	{ key: 'PageType', label: 'Page Type' }, 
	{ key: 'Tag1', label: 'Custom Dimension 1' }, 
	{ key: 'Tag2', label: 'Custom Dimension 2' }, 
	{ key: 'Tag3', label: 'Custom Dimension 3' }, 
	{ key: 'Tag4', label: 'Custom Dimension 4' }, 
	{ key: 'Tag5', label: 'Custom Dimension 5' }];
    
export const metricsTypes = [{
	key: 'UniqueViews',
	label: 'Unique Views'
},
{
	key: 'Views',
	label: 'Views'
},
{
	key: 'AverageTimeOnPage',
	label: 'Average time on page'
},
{
	key: 'BounceRate',
	label: 'Bounce rate'
},
// {
//     key: 'TrafficSource',
//     label: 'Traffic source'
// },
// {
//     key: 'Landings',
//     label: 'Landings'
// },
// {
//     key: 'Exits',
//     label: 'Exits'
// }
];

export const sourceTypes = [
	// 'All',
	'Direct',
	'Organic',
	'Referral',
	'Social',
	'Paid',
	'Email',
	'Display',
	'Other',
];
export default initType;

export const initialUtmFilter = {
	source: {
		name: '',
		label: ''
	},
	medium: {
		name: '',
		label: ''
	},
	campaign: {
		name: '',
		label: ''
	},
	content: {
		name: '',
		label: ''
	}
};
export const initialUtmFilterV2 = {
	source: [
		
	],
	medium: [
		
	],
	campaign: [
		
	],
	content: [
	
	]
};
export const cardFetchOptions = {
	'fromDate': '',
	'toDate': '',
	'metrics': [
		'NumberOfSessions'
	],
	'timeGranularity': 'None',
	'dimensions': [],
	'limit': 15,
	'offset': 0,
	'filters': [],
	'search': null
};
export const search = (fnSetter,table, word,selectList) => {
	if (word.length > 0) {
		fnSetter([]);
		let resultName = table.filter(
			line =>
				line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
		);
		const res = resultName.map(i => {
			return {
				value: i,
				label: i
			};
		});
		fnSetter(res);
	}
	else {
		fnSetter(selectList);
	}
};
export const devices = [
// 	{
// 	value : 'all',
// 	label : 'All'
// },
	{
		value : 'Desktop',
		label : 'Desktop'
	},
	{
		value : 'Mobile',
		label : 'Mobile'
	},
	{
		value : 'Tablet',
		label : 'Tablet'
	}
];
export const FORMAT = 'YYYY/MM/DD';
export const today = moment();
export const monday = moment().startOf('isoWeek').format(FORMAT);
export const formatToday = moment().format(FORMAT);

export const dataTest = [
	{
		'lat': 9.90732466978212,
		'lon': -84.06091927995656,
		'name': 'Provincia de San José',
		'iso': 'CR',
		'NumberOfSessions': 25
	},
	{
		'lat': 47.9094,
		'lon': 106.8819,
		'name': 'Ulaanbaatar Hot',
		'iso': 'MN',
		'NumberOfSessions': 6
	},
	{
		'lat': 9.9083,
		'lon': -83.9909,
		'name': 'Cartago Province',
		'iso': 'CR',
		'NumberOfSessions': 1
	},
	{
		'lat': -34.89265510523376,
		'lon': -56.15372253171024,
		'name': 'Montevideo Department',
		'iso': 'UY',
		'NumberOfSessions': 14
	},
	{
		'lat': 10.6698,
		'lon': -61.51420000000001,
		'name': 'Port of Spain',
		'iso': 'TT',
		'NumberOfSessions': 5
	},
	{
		'lat': -18.1301,
		'lon': 178.4098,
		'name': 'Rewa Province',
		'iso': 'FJ',
		'NumberOfSessions': 2
	},
	{
		'lat': -19.5451,
		'lon': 169.2837,
		'name': 'Tafea',
		'iso': 'VU',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.686900000000023,
		'lon': 46.7224,
		'name': 'Riyadh Region',
		'iso': 'SA',
		'NumberOfSessions': 30
	},
	{
		'lat': -17.38169999999999,
		'lon': -66.138,
		'name': 'Departamento de Cochabamba',
		'iso': 'BO',
		'NumberOfSessions': 5
	},
	{
		'lat': 42.3924,
		'lon': 18.924199999999995,
		'name': 'Cetinje',
		'iso': 'ME',
		'NumberOfSessions': 1
	},
	{
		'lat': 13.680599999999991,
		'lon': -89.1803,
		'name': 'San Salvador Department',
		'iso': 'SV',
		'NumberOfSessions': 15
	},
	{
		'lat': -16.5002,
		'lon': -68.1493,
		'name': 'La Paz Department',
		'iso': 'BO',
		'NumberOfSessions': 4
	},
	{
		'lat': 3.4536,
		'lon': 8.555799999999996,
		'name': 'Bioko Sur',
		'iso': 'GQ',
		'NumberOfSessions': 1
	},
	{
		'lat': 29.364500000000007,
		'lon': 47.988900000000015,
		'name': 'Al Asimah',
		'iso': 'KW',
		'NumberOfSessions': 11
	},
	{
		'lat': 32.75661395123266,
		'lon': 35.250448546187094,
		'name': 'Northern District',
		'iso': 'IL',
		'NumberOfSessions': 3
	},
	{
		'lat': 18.6177,
		'lon': -68.704,
		'name': 'La Altagracia Province',
		'iso': 'DO',
		'NumberOfSessions': 2
	},
	{
		'lat': 22.326200188969135,
		'lon': 114.19204944298224,
		'name': 'Kowloon City',
		'iso': 'HK',
		'NumberOfSessions': 2
	},
	{
		'lat': -20.13629999999999,
		'lon': 57.6204,
		'name': 'Pamplemousses District',
		'iso': 'MU',
		'NumberOfSessions': 1
	},
	{
		'lat': 17.2501,
		'lon': -88.7676,
		'name': 'Cayo District',
		'iso': 'BZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 32.069576744984595,
		'lon': 34.78756485800602,
		'name': 'Tel Aviv',
		'iso': 'IL',
		'NumberOfSessions': 40
	},
	{
		'lat': -33.96426441481309,
		'lon': 18.449304471923774,
		'name': 'Western Cape',
		'iso': 'ZA',
		'NumberOfSessions': 21
	},
	{
		'lat': 13.1229,
		'lon': -59.59599999999999,
		'name': 'Saint Michael',
		'iso': 'BB',
		'NumberOfSessions': 2
	},
	{
		'lat': 31.301285971675497,
		'lon': 34.768267613505245,
		'name': 'Southern District',
		'iso': 'IL',
		'NumberOfSessions': 20
	},
	{
		'lat': 10.3275,
		'lon': -84.4089,
		'name': 'Alajuela Province',
		'iso': 'CR',
		'NumberOfSessions': 1
	},
	{
		'lat': 32.9001,
		'lon': 13.187399999999998,
		'name': 'Tripoli',
		'iso': 'LY',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.983604492734244,
		'lon': 9.910753351619427,
		'name': 'North Denmark',
		'iso': 'DK',
		'NumberOfSessions': 8
	},
	{
		'lat': 43.8453,
		'lon': 18.35920000000001,
		'name': 'Sarajevo Canton',
		'iso': 'BA',
		'NumberOfSessions': 3
	},
	{
		'lat': 34.733,
		'lon': 36.72619999999999,
		'name': 'Homs Governorate',
		'iso': 'SY',
		'NumberOfSessions': 1
	},
	{
		'lat': 23.149200000000004,
		'lon': -82.395,
		'name': 'Havana',
		'iso': 'CU',
		'NumberOfSessions': 2
	},
	{
		'lat': 31.5019,
		'lon': 34.46660000000001,
		'name': 'Gaza Governorate',
		'iso': 'PS',
		'NumberOfSessions': 1
	},
	{
		'lat': 22.3157,
		'lon': 114.15900000000002,
		'name': 'Yau Tsim Mong',
		'iso': 'HK',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.81299999999999,
		'lon': 89.3152,
		'name': 'Bogra',
		'iso': 'BD',
		'NumberOfSessions': 1
	},
	{
		'lat': -4.616,
		'lon': 55.4461,
		'name': 'La Rivière Anglaise',
		'iso': 'SC',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.480177048544874,
		'lon': 12.134973085259784,
		'name': 'Zealand',
		'iso': 'DK',
		'NumberOfSessions': 20
	},
	{
		'lat': 46.780000000000015,
		'lon': -56.16810000000001,
		'name': 'Commune de Saint-Pierre',
		'iso': 'PM',
		'NumberOfSessions': 1
	},
	{
		'lat': 7.314553987078685,
		'lon': 80.57805298483451,
		'name': 'Central Province',
		'iso': 'LK',
		'NumberOfSessions': 2
	},
	{
		'lat': -33.9672,
		'lon': 25.5785,
		'name': 'Eastern Cape',
		'iso': 'ZA',
		'NumberOfSessions': 2
	},
	{
		'lat': 33.7233,
		'lon': 73.0435,
		'name': 'Islamabad',
		'iso': 'PK',
		'NumberOfSessions': 3
	},
	{
		'lat': 17.5018,
		'lon': -88.1956,
		'name': 'Belize District',
		'iso': 'BZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 4.8499,
		'lon': 31.5812,
		'name': 'Central Equatoria',
		'iso': 'SS',
		'NumberOfSessions': 1
	},
	{
		'lat': 64.13175556411359,
		'lon': -21.884477297384596,
		'name': 'Reykjavíkurborg',
		'iso': 'IS',
		'NumberOfSessions': 12
	},
	{
		'lat': 65.6826,
		'lon': -18.079700000000006,
		'name': 'Akureyrarkaupstaður',
		'iso': 'IS',
		'NumberOfSessions': 1
	},
	{
		'lat': 0.3809999999999999,
		'lon': 9.448699999999997,
		'name': 'Estuaire',
		'iso': 'GA',
		'NumberOfSessions': 2
	},
	{
		'lat': 56.19895214086045,
		'lon': 10.011860557535433,
		'name': 'Central Jutland',
		'iso': 'DK',
		'NumberOfSessions': 24
	},
	{
		'lat': 35.8359,
		'lon': 51.00250000000001,
		'name': 'Alborz Province',
		'iso': 'IR',
		'NumberOfSessions': 3
	},
	{
		'lat': 55.26413083799406,
		'lon': 9.249720412956817,
		'name': 'South Denmark',
		'iso': 'DK',
		'NumberOfSessions': 19
	},
	{
		'lat': 6.1316000000000015,
		'lon': 1.224,
		'name': 'Maritime',
		'iso': 'TG',
		'NumberOfSessions': 1
	},
	{
		'lat': -19.0459,
		'lon': -65.2769,
		'name': 'Chuquisaca Department',
		'iso': 'BO',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.8232,
		'lon': 10.1701,
		'name': 'Tunis Governorate',
		'iso': 'TN',
		'NumberOfSessions': 1
	},
	{
		'lat': 8.994800000000001,
		'lon': -79.523,
		'name': 'Provincia de Panamá',
		'iso': 'PA',
		'NumberOfSessions': 12
	},
	{
		'lat': 12.049200000000004,
		'lon': -61.7516,
		'name': 'Saint George Parish',
		'iso': 'GD',
		'NumberOfSessions': 1
	},
	{
		'lat': 27.189000000000007,
		'lon': 56.28360000000001,
		'name': 'Hormozgan',
		'iso': 'IR',
		'NumberOfSessions': 1
	},
	{
		'lat': 25.292499999999993,
		'lon': 51.53210000000001,
		'name': 'Baladīyat ad Dawḩah',
		'iso': 'QA',
		'NumberOfSessions': 5
	},
	{
		'lat': 11.592200000000002,
		'lon': 43.1405,
		'name': 'Djibouti',
		'iso': 'DJ',
		'NumberOfSessions': 2
	},
	{
		'lat': 19.444824471312376,
		'lon': -70.6838989198638,
		'name': 'Santiago Province',
		'iso': 'DO',
		'NumberOfSessions': 5
	},
	{
		'lat': 7.220099999999997,
		'lon': 80.1939,
		'name': 'Kegalle District',
		'iso': 'LK',
		'NumberOfSessions': 1
	},
	{
		'lat': 26.44821452245232,
		'lon': 50.10052238876453,
		'name': 'Eastern Province',
		'iso': 'SA',
		'NumberOfSessions': 9
	},
	{
		'lat': 34.741,
		'lon': 10.7648,
		'name': 'Sfax Governorate',
		'iso': 'TN',
		'NumberOfSessions': 1
	},
	{
		'lat': 15.648300000000003,
		'lon': 32.5245,
		'name': 'Khartoum',
		'iso': 'SD',
		'NumberOfSessions': 1
	},
	{
		'lat': 18.474015806739366,
		'lon': -69.90550110982778,
		'name': 'Santo Domingo Province',
		'iso': 'DO',
		'NumberOfSessions': 3
	},
	{
		'lat': 36.1828,
		'lon': 44.01049999999999,
		'name': 'Erbil',
		'iso': 'IQ',
		'NumberOfSessions': 4
	},
	{
		'lat': 29.610599999999998,
		'lon': 52.52449999999999,
		'name': 'Fars',
		'iso': 'IR',
		'NumberOfSessions': 1
	},
	{
		'lat': 22.284900000000007,
		'lon': 114.164,
		'name': 'Central and Western District',
		'iso': 'HK',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.430682301390174,
		'lon': 54.52952523901535,
		'name': 'Abu Dhabi',
		'iso': 'AE',
		'NumberOfSessions': 11
	},
	{
		'lat': 31.767400000000002,
		'lon': 35.2186,
		'name': 'Jerusalem',
		'iso': 'IL',
		'NumberOfSessions': 8
	},
	{
		'lat': 32.81119754975974,
		'lon': 35.027114671759236,
		'name': 'Haifa',
		'iso': 'IL',
		'NumberOfSessions': 7
	},
	{
		'lat': 49.6113,
		'lon': 6.129399999999999,
		'name': 'Luxembourg',
		'iso': 'LU',
		'NumberOfSessions': 7
	},
	{
		'lat': 9.5612,
		'lon': -84.321,
		'name': 'Puntarenas Province',
		'iso': 'CR',
		'NumberOfSessions': 1
	},
	{
		'lat': -22.556200000000004,
		'lon': 17.0759,
		'name': 'Khomas Region',
		'iso': 'NA',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.163799999999995,
		'lon': 33.36390000000001,
		'name': 'Nicosia',
		'iso': 'CY',
		'NumberOfSessions': 6
	},
	{
		'lat': -29.88061027162153,
		'lon': 30.992713086839704,
		'name': 'KwaZulu-Natal',
		'iso': 'ZA',
		'NumberOfSessions': 6
	},
	{
		'lat': 42.0653,
		'lon': 19.51,
		'name': 'Shkodër County',
		'iso': 'AL',
		'NumberOfSessions': 1
	},
	{
		'lat': 66.0715,
		'lon': -23.1301,
		'name': 'Ísafjarðarbær',
		'iso': 'IS',
		'NumberOfSessions': 1
	},
	{
		'lat': -25.4677,
		'lon': -57.0431,
		'name': 'Cordillera Department',
		'iso': 'PY',
		'NumberOfSessions': 1
	},
	{
		'lat': 6.719500000000002,
		'lon': 80.06440000000002,
		'name': 'Kalutara District',
		'iso': 'LK',
		'NumberOfSessions': 1
	},
	{
		'lat': 6.847699999999998,
		'lon': 80.2598,
		'name': 'Ratnapura District',
		'iso': 'LK',
		'NumberOfSessions': 1
	},
	{
		'lat': 25.3412,
		'lon': 55.4224,
		'name': 'Sharjah',
		'iso': 'AE',
		'NumberOfSessions': 3
	},
	{
		'lat': 18.2811,
		'lon': -70.3243,
		'name': 'Provincia de Peravia',
		'iso': 'DO',
		'NumberOfSessions': 1
	},
	{
		'lat': 31.64957137309169,
		'lon': 73.96847045534871,
		'name': 'Punjab',
		'iso': 'PK',
		'NumberOfSessions': 14
	},
	{
		'lat': -26.05907992687233,
		'lon': 28.127223368752492,
		'name': 'Gauteng',
		'iso': 'ZA',
		'NumberOfSessions': 45
	},
	{
		'lat': 27.694442458200896,
		'lon': 85.34052212485099,
		'name': 'Bagmati Province',
		'iso': 'NP',
		'NumberOfSessions': 10
	},
	{
		'lat': 6.447400000000003,
		'lon': 3.3903000000000016,
		'name': 'Lagos',
		'iso': 'NG',
		'NumberOfSessions': 3
	},
	{
		'lat': -6.050000000000002,
		'lon': 21.9041,
		'name': 'Kasai-Central',
		'iso': 'CD',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.2008,
		'lon': -61.403999999999996,
		'name': 'Penal/Debe',
		'iso': 'TT',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.9519,
		'lon': 58.3958,
		'name': 'Ahal',
		'iso': 'TM',
		'NumberOfSessions': 1
	},
	{
		'lat': -24.6437,
		'lon': 25.9112,
		'name': 'Gaborone',
		'iso': 'BW',
		'NumberOfSessions': 3
	},
	{
		'lat': 40.377100000000006,
		'lon': 49.88749999999999,
		'name': 'Baku City',
		'iso': 'AZ',
		'NumberOfSessions': 25
	},
	{
		'lat': 35.5641,
		'lon': 45.4326,
		'name': 'Sulaymaniyah',
		'iso': 'IQ',
		'NumberOfSessions': 4
	},
	{
		'lat': 31.9555,
		'lon': 35.94350000000001,
		'name': 'Amman Governorate',
		'iso': 'JO',
		'NumberOfSessions': 7
	},
	{
		'lat': 41.261499999999984,
		'lon': 69.21770000000001,
		'name': 'Tashkent',
		'iso': 'UZ',
		'NumberOfSessions': 18
	},
	{
		'lat': 6.90543402292412,
		'lon': 79.88118912284492,
		'name': 'Colombo District',
		'iso': 'LK',
		'NumberOfSessions': 9
	},
	{
		'lat': 9.026,
		'lon': 38.7439,
		'name': 'Addis Ababa',
		'iso': 'ET',
		'NumberOfSessions': 1
	},
	{
		'lat': 15.496699999999999,
		'lon': -88.0338,
		'name': 'Cortés Department',
		'iso': 'HN',
		'NumberOfSessions': 12
	},
	{
		'lat': 22.2848,
		'lon': 114.1703,
		'name': 'Wan Chai',
		'iso': 'HK',
		'NumberOfSessions': 2
	},
	{
		'lat': 22.3361,
		'lon': 114.1698,
		'name': 'Sham Shui Po',
		'iso': 'HK',
		'NumberOfSessions': 1
	},
	{
		'lat': 13.962000000000003,
		'lon': 44.1902,
		'name': 'Ibb Governorate',
		'iso': 'YE',
		'NumberOfSessions': 1
	},
	{
		'lat': 26.6264,
		'lon': 87.0802,
		'name': 'Koshi',
		'iso': 'NP',
		'NumberOfSessions': 1
	},
	{
		'lat': -14.834599999999998,
		'lon': -64.9008,
		'name': 'Beni Department',
		'iso': 'BO',
		'NumberOfSessions': 1
	},
	{
		'lat': 14.012800000000002,
		'lon': -60.999300000000005,
		'name': 'Castries',
		'iso': 'LC',
		'NumberOfSessions': 1
	},
	{
		'lat': 5.3536,
		'lon': -4.0012,
		'name': 'Abidjan Autonomous District',
		'iso': 'CI',
		'NumberOfSessions': 4
	},
	{
		'lat': -22.266500000000008,
		'lon': 166.4742,
		'name': 'South Province',
		'iso': 'NC',
		'NumberOfSessions': 1
	},
	{
		'lat': -20.1608,
		'lon': 57.49940000000001,
		'name': 'Port Louis District',
		'iso': 'MU',
		'NumberOfSessions': 1
	},
	{
		'lat': 11.558300000000001,
		'lon': 104.91210000000001,
		'name': 'Phnom Penh',
		'iso': 'KH',
		'NumberOfSessions': 21
	},
	{
		'lat': 47.00420000000001,
		'lon': 28.857400000000005,
		'name': 'Chișinău Municipality',
		'iso': 'MD',
		'NumberOfSessions': 14
	},
	{
		'lat': 33.503,
		'lon': 36.3032,
		'name': 'Damascus Governorate',
		'iso': 'SY',
		'NumberOfSessions': 1
	},
	{
		'lat': -20.12585005774264,
		'lon': -66.37318477062489,
		'name': 'Potosí Department',
		'iso': 'BO',
		'NumberOfSessions': 2
	},
	{
		'lat': 10.007362590735283,
		'lon': -84.11316260847367,
		'name': 'Heredia Province',
		'iso': 'CR',
		'NumberOfSessions': 8
	},
	{
		'lat': -14.274799999999997,
		'lon': -170.6895,
		'name': 'Eastern District',
		'iso': 'AS',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.466200000000004,
		'lon': 39.6168,
		'name': 'Medina Region',
		'iso': 'SA',
		'NumberOfSessions': 8
	},
	{
		'lat': 40.7859,
		'lon': 72.346,
		'name': 'Andijan Region',
		'iso': 'UZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.50668130790268,
		'lon': 18.300435357822078,
		'name': 'Federation of Bosnia and Herzegovina',
		'iso': 'BA',
		'NumberOfSessions': 5
	},
	{
		'lat': 42.5055,
		'lon': 1.5242999999999995,
		'name': 'Andorra la Vella',
		'iso': 'AD',
		'NumberOfSessions': 4
	},
	{
		'lat': 14.640000001938734,
		'lon': -90.5138500142914,
		'name': 'Guatemala',
		'iso': 'GT',
		'NumberOfSessions': 20
	},
	{
		'lat': 21.453579335543164,
		'lon': 39.385481708687045,
		'name': 'Mecca Region',
		'iso': 'SA',
		'NumberOfSessions': 35
	},
	{
		'lat': -15.4183,
		'lon': 28.287000000000003,
		'name': 'Lusaka Province',
		'iso': 'ZM',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.8061,
		'lon': 10.0931,
		'name': 'Manouba',
		'iso': 'TN',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.7493,
		'lon': 72.3529,
		'name': 'Khyber Pakhtunkhwa',
		'iso': 'PK',
		'NumberOfSessions': 1
	},
	{
		'lat': -1.2841,
		'lon': 36.81549999999999,
		'name': 'Nairobi County',
		'iso': 'KE',
		'NumberOfSessions': 10
	},
	{
		'lat': 12.1346,
		'lon': -86.2469,
		'name': 'Managua Department',
		'iso': 'NI',
		'NumberOfSessions': 2
	},
	{
		'lat': 23.75533220105134,
		'lon': 90.39174178845055,
		'name': 'Dhaka',
		'iso': 'BD',
		'NumberOfSessions': 10
	},
	{
		'lat': 35.8251,
		'lon': 10.6446,
		'name': 'Sousse Governorate',
		'iso': 'TN',
		'NumberOfSessions': 1
	},
	{
		'lat': -16.1529,
		'lon': 33.5878,
		'name': 'Tete',
		'iso': 'MZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 4.1772,
		'lon': 73.5107,
		'name': 'Male',
		'iso': 'MV',
		'NumberOfSessions': 2
	},
	{
		'lat': 13.4954,
		'lon': -88.8646,
		'name': 'La Paz Department',
		'iso': 'SV',
		'NumberOfSessions': 1
	},
	{
		'lat': 14.085133453797711,
		'lon': -87.20293322370338,
		'name': 'Francisco Morazán Department',
		'iso': 'HN',
		'NumberOfSessions': 9
	},
	{
		'lat': 17.9641,
		'lon': 102.5987,
		'name': 'Vientiane Prefecture',
		'iso': 'LA',
		'NumberOfSessions': 4
	},
	{
		'lat': 10.511100000000004,
		'lon': -61.4237,
		'name': 'Chaguanas',
		'iso': 'TT',
		'NumberOfSessions': 3
	},
	{
		'lat': -25.37524185736579,
		'lon': -57.400533813998265,
		'name': 'Central Department',
		'iso': 'PY',
		'NumberOfSessions': 2
	},
	{
		'lat': 32.4633,
		'lon': 44.4179,
		'name': 'Muhafazat Babil',
		'iso': 'IQ',
		'NumberOfSessions': 2
	},
	{
		'lat': 14.8345,
		'lon': -91.5183,
		'name': 'Quetzaltenango',
		'iso': 'GT',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.9328,
		'lon': 91.8739,
		'name': 'Sylhet',
		'iso': 'BD',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.97131749679424,
		'lon': 67.29727775959601,
		'name': 'Sindh',
		'iso': 'PK',
		'NumberOfSessions': 5
	},
	{
		'lat': 41.6473,
		'lon': 41.6258,
		'name': 'Adjara',
		'iso': 'GE',
		'NumberOfSessions': 5
	},
	{
		'lat': 25.073100000000014,
		'lon': 55.297999999999995,
		'name': 'Dubai',
		'iso': 'AE',
		'NumberOfSessions': 17
	},
	{
		'lat': 5.866700000000002,
		'lon': -55.1642,
		'name': 'Paramaribo District',
		'iso': 'SR',
		'NumberOfSessions': 2
	},
	{
		'lat': 25.7935,
		'lon': 55.944,
		'name': 'Imarat Ra\'s al Khaymah',
		'iso': 'AE',
		'NumberOfSessions': 1
	},
	{
		'lat': -10.2136,
		'lon': 31.1785,
		'name': 'Northern Province',
		'iso': 'ZM',
		'NumberOfSessions': 1
	},
	{
		'lat': 22.3539,
		'lon': 114.1342,
		'name': 'Kwai Tsing',
		'iso': 'HK',
		'NumberOfSessions': 2
	},
	{
		'lat': 55.70295418085561,
		'lon': 12.518235594607864,
		'name': 'Capital Region',
		'iso': 'DK',
		'NumberOfSessions': 74
	},
	{
		'lat': 42.869599999999984,
		'lon': 74.5932,
		'name': 'Gorod Bishkek',
		'iso': 'KG',
		'NumberOfSessions': 13
	},
	{
		'lat': 25.0565,
		'lon': -77.3524,
		'name': 'New Providence District',
		'iso': 'BS',
		'NumberOfSessions': 3
	},
	{
		'lat': 23.5978690421096,
		'lon': 58.35525822214676,
		'name': 'Muscat',
		'iso': 'OM',
		'NumberOfSessions': 9
	},
	{
		'lat': 18.469,
		'lon': -77.9295,
		'name': 'Saint James Parish',
		'iso': 'JM',
		'NumberOfSessions': 1
	},
	{
		'lat': 64.1634,
		'lon': -21.703000000000003,
		'name': 'Mosfellsbaer',
		'iso': 'IS',
		'NumberOfSessions': 2
	},
	{
		'lat': 16.796400000000002,
		'lon': 96.1667,
		'name': 'Yangon',
		'iso': 'MM',
		'NumberOfSessions': 3
	},
	{
		'lat': 25.440999999999992,
		'lon': 55.532,
		'name': 'Ajman',
		'iso': 'AE',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.341,
		'lon': 43.1155,
		'name': 'Nineveh',
		'iso': 'IQ',
		'NumberOfSessions': 1
	},
	{
		'lat': -4.256799999999998,
		'lon': 15.2872,
		'name': 'Brazzaville',
		'iso': 'CG',
		'NumberOfSessions': 1
	},
	{
		'lat': 14.661099999999992,
		'lon': -86.2208,
		'name': 'Olancho Department',
		'iso': 'HN',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.682399999999994,
		'lon': 51.41580000000002,
		'name': 'Tehran',
		'iso': 'IR',
		'NumberOfSessions': 15
	},
	{
		'lat': 44.88002663855969,
		'lon': 17.9656225668839,
		'name': 'Republika Srpska',
		'iso': 'BA',
		'NumberOfSessions': 2
	},
	{
		'lat': 41.3438,
		'lon': 21.5561,
		'name': 'Prilep',
		'iso': 'MK',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.1322,
		'lon': 9.5115,
		'name': 'Vaduz',
		'iso': 'LI',
		'NumberOfSessions': 1
	},
	{
		'lat': -17.783400000000007,
		'lon': -63.18219999999999,
		'name': 'Santa Cruz Department',
		'iso': 'BO',
		'NumberOfSessions': 11
	},
	{
		'lat': 40.18169999999998,
		'lon': 44.509899999999995,
		'name': 'Yerevan',
		'iso': 'AM',
		'NumberOfSessions': 29
	},
	{
		'lat': 17.996199999999998,
		'lon': -76.8019,
		'name': 'Kingston',
		'iso': 'JM',
		'NumberOfSessions': 2
	},
	{
		'lat': 24.0242,
		'lon': 90.27,
		'name': 'Gazipur',
		'iso': 'BD',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.108999999999998,
		'lon': 90.0988,
		'name': 'Tangail',
		'iso': 'BD',
		'NumberOfSessions': 1
	},
	{
		'lat': 7.696200000000001,
		'lon': -5.0308,
		'name': 'Vallée du Bandama District',
		'iso': 'CI',
		'NumberOfSessions': 1
	},
	{
		'lat': 13.5112,
		'lon': 2.117,
		'name': 'Niamey',
		'iso': 'NE',
		'NumberOfSessions': 1
	},
	{
		'lat': -25.512500000000006,
		'lon': -54.6187,
		'name': 'Departamento del Alto Paraná',
		'iso': 'PY',
		'NumberOfSessions': 1
	},
	{
		'lat': 30.510100000000012,
		'lon': 47.7847,
		'name': 'Basra',
		'iso': 'IQ',
		'NumberOfSessions': 2
	},
	{
		'lat': 42.44109999999999,
		'lon': 19.2632,
		'name': 'Podgorica',
		'iso': 'ME',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.8411,
		'lon': 29.6351,
		'name': 'Administrative-Territorial Units of the Left Bank of the Dniester',
		'iso': 'MD',
		'NumberOfSessions': 1
	},
	{
		'lat': 33.89160000000001,
		'lon': 35.5024,
		'name': 'Beyrouth',
		'iso': 'LB',
		'NumberOfSessions': 1
	},
	{
		'lat': 26.241,
		'lon': 50.577900000000014,
		'name': 'Manama',
		'iso': 'BH',
		'NumberOfSessions': 4
	},
	{
		'lat': 33.3364,
		'lon': 44.4004,
		'name': 'Baghdad',
		'iso': 'IQ',
		'NumberOfSessions': 8
	},
	{
		'lat': 32.01734864875597,
		'lon': 34.83134481723111,
		'name': 'Central District',
		'iso': 'IL',
		'NumberOfSessions': 12
	},
	{
		'lat': 18.466772748488776,
		'lon': -69.8956090757228,
		'name': 'Nacional',
		'iso': 'DO',
		'NumberOfSessions': 11
	},
	{
		'lat': 42.4241,
		'lon': 18.7674,
		'name': 'Kotor',
		'iso': 'ME',
		'NumberOfSessions': 2
	},
	{
		'lat': -20.230000000000008,
		'lon': 57.4915,
		'name': 'Moka District',
		'iso': 'MU',
		'NumberOfSessions': 2
	},
	{
		'lat': -26.791570717810995,
		'lon': 26.88504496855307,
		'name': 'North West',
		'iso': 'ZA',
		'NumberOfSessions': 2
	},
	{
		'lat': 34.69021253453532,
		'lon': 33.037699738316185,
		'name': 'Limassol District',
		'iso': 'CY',
		'NumberOfSessions': 16
	},
	{
		'lat': 5.548600000000002,
		'lon': -0.2012,
		'name': 'Greater Accra Region',
		'iso': 'GH',
		'NumberOfSessions': 4
	},
	{
		'lat': 36.3006,
		'lon': 59.5904,
		'name': 'Razavi Khorasan',
		'iso': 'IR',
		'NumberOfSessions': 2
	},
	{
		'lat': 41.695900000000016,
		'lon': 44.832000000000015,
		'name': 'Tbilisi',
		'iso': 'GE',
		'NumberOfSessions': 41
	},
	{
		'lat': 33.29360000000001,
		'lon': 36.0127,
		'name': 'Rif-dimashq',
		'iso': 'SY',
		'NumberOfSessions': 1
	},
	{
		'lat': 18.4207,
		'lon': -68.9608,
		'name': 'La Romana',
		'iso': 'DO',
		'NumberOfSessions': 1
	},
	{
		'lat': 14.1538,
		'lon': -89.4839,
		'name': 'Santa Ana Department',
		'iso': 'SV',
		'NumberOfSessions': 3
	},
	{
		'lat': 42.0791,
		'lon': 20.420199999999994,
		'name': 'Kukës County',
		'iso': 'AL',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.856200000000015,
		'lon': 10.190600000000002,
		'name': 'Ariana Governorate',
		'iso': 'TN',
		'NumberOfSessions': 1
	},
	{
		'lat': -41.130399999999995,
		'lon': -71.2936,
		'name': 'Rio Negro',
		'iso': 'AR',
		'NumberOfSessions': 2
	},
	{
		'lat': -24.805600000000002,
		'lon': -65.3417,
		'name': 'Salta',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': -26.84255025701768,
		'lon': -65.2322486670993,
		'name': 'Tucuman',
		'iso': 'AR',
		'NumberOfSessions': 2
	},
	{
		'lat': -27.2044316337823,
		'lon': -55.59560558369962,
		'name': 'Misiones',
		'iso': 'AR',
		'NumberOfSessions': 3
	},
	{
		'lat': -28.5,
		'lon': -59.050000000000004,
		'name': 'Corrientes',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': -31.7279,
		'lon': -60.529300000000006,
		'name': 'Entre Rios',
		'iso': 'AR',
		'NumberOfSessions': 2
	},
	{
		'lat': -24.1894,
		'lon': -65.2978,
		'name': 'Jujuy',
		'iso': 'AR',
		'NumberOfSessions': 2
	},
	{
		'lat': -33.339217526895425,
		'lon': -68.69087975263407,
		'name': 'Mendoza',
		'iso': 'AR',
		'NumberOfSessions': 4
	},
	{
		'lat': -35.43655105845582,
		'lon': -59.134608160132,
		'name': 'Buenos Aires',
		'iso': 'AR',
		'NumberOfSessions': 69
	},
	{
		'lat': -44.516696914320285,
		'lon': -66.86421174962416,
		'name': 'Chubut',
		'iso': 'AR',
		'NumberOfSessions': 2
	},
	{
		'lat': -31.437400000000004,
		'lon': -68.5612,
		'name': 'San Juan',
		'iso': 'AR',
		'NumberOfSessions': 2
	},
	{
		'lat': -34.6142,
		'lon': -58.3811,
		'name': 'Buenos Aires F.D.',
		'iso': 'AR',
		'NumberOfSessions': 8
	},
	{
		'lat': -31.84661984068604,
		'lon': -64.0768137665919,
		'name': 'Cordoba',
		'iso': 'AR',
		'NumberOfSessions': 9
	},
	{
		'lat': -53.4993,
		'lon': -68.0451,
		'name': 'Tierra del Fuego',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': -28.4333,
		'lon': -65.75,
		'name': 'Catamarca',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': -36.6192,
		'lon': -64.2797,
		'name': 'La Pampa',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': -39.53032388942754,
		'lon': -70.27207993301906,
		'name': 'Neuquen',
		'iso': 'AR',
		'NumberOfSessions': 2
	},
	{
		'lat': -32.9330052766467,
		'lon': -60.67475156008174,
		'name': 'Santa Fe',
		'iso': 'AR',
		'NumberOfSessions': 4
	},
	{
		'lat': 47.09153143431925,
		'lon': 15.482092115085534,
		'name': 'Styria',
		'iso': 'AT',
		'NumberOfSessions': 7
	},
	{
		'lat': 47.61329966547958,
		'lon': 16.54967189958007,
		'name': 'Burgenland',
		'iso': 'AT',
		'NumberOfSessions': 3
	},
	{
		'lat': 47.60586671558131,
		'lon': 13.035039095527107,
		'name': 'Salzburg',
		'iso': 'AT',
		'NumberOfSessions': 5
	},
	{
		'lat': 47.282082100066326,
		'lon': 11.445146153717584,
		'name': 'Tyrol',
		'iso': 'AT',
		'NumberOfSessions': 8
	},
	{
		'lat': 48.20740993795287,
		'lon': 16.363049155073455,
		'name': 'Vienna',
		'iso': 'AT',
		'NumberOfSessions': 182
	},
	{
		'lat': 48.23315461134786,
		'lon': 14.153542784097427,
		'name': 'Upper Austria',
		'iso': 'AT',
		'NumberOfSessions': 13
	},
	{
		'lat': 48.20588621636892,
		'lon': 15.77391630598984,
		'name': 'Lower Austria',
		'iso': 'AT',
		'NumberOfSessions': 13
	},
	{
		'lat': 46.718767576666714,
		'lon': 13.909087838053585,
		'name': 'Carinthia',
		'iso': 'AT',
		'NumberOfSessions': 2
	},
	{
		'lat': -37.832353898289306,
		'lon': 145.0011226338076,
		'name': 'Victoria',
		'iso': 'AU',
		'NumberOfSessions': 196
	},
	{
		'lat': -42.25253285247557,
		'lon': 147.10870537533728,
		'name': 'Tasmania',
		'iso': 'AU',
		'NumberOfSessions': 15
	},
	{
		'lat': -35.2371784764197,
		'lon': 149.1302854797333,
		'name': 'Australian Capital Territory',
		'iso': 'AU',
		'NumberOfSessions': 10
	},
	{
		'lat': -12.4768,
		'lon': 130.9756,
		'name': 'Northern Territory',
		'iso': 'AU',
		'NumberOfSessions': 1
	},
	{
		'lat': -31.95472250755908,
		'lon': 115.8653406980798,
		'name': 'Western Australia',
		'iso': 'AU',
		'NumberOfSessions': 92
	},
	{
		'lat': -33.848571118497745,
		'lon': 151.09905724701207,
		'name': 'New South Wales',
		'iso': 'AU',
		'NumberOfSessions': 287
	},
	{
		'lat': -34.90839861037362,
		'lon': 138.59699003382863,
		'name': 'South Australia',
		'iso': 'AU',
		'NumberOfSessions': 69
	},
	{
		'lat': -26.824574658269633,
		'lon': 152.50026907697776,
		'name': 'Queensland',
		'iso': 'AU',
		'NumberOfSessions': 218
	},
	{
		'lat': 50.490278013986504,
		'lon': 4.194695112436832,
		'name': 'Hainaut Province',
		'iso': 'BE',
		'NumberOfSessions': 27
	},
	{
		'lat': 51.013287864934725,
		'lon': 3.8008720975878747,
		'name': 'East Flanders Province',
		'iso': 'BE',
		'NumberOfSessions': 17
	},
	{
		'lat': 50.6658,
		'lon': 4.5551,
		'name': 'Walloon Brabant Province',
		'iso': 'BE',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.95970557066008,
		'lon': 5.360342460777804,
		'name': 'Limburg Province',
		'iso': 'BE',
		'NumberOfSessions': 7
	},
	{
		'lat': 50.893891896753665,
		'lon': 4.568279484381838,
		'name': 'Flemish Brabant Province',
		'iso': 'BE',
		'NumberOfSessions': 7
	},
	{
		'lat': 50.85551062951468,
		'lon': 4.3558419282733425,
		'name': 'Brussels Capital',
		'iso': 'BE',
		'NumberOfSessions': 63
	},
	{
		'lat': 51.20795718022994,
		'lon': 4.53989889393,
		'name': 'Antwerp Province',
		'iso': 'BE',
		'NumberOfSessions': 28
	},
	{
		'lat': 49.67993880855986,
		'lon': 5.453499919359876,
		'name': 'Luxembourg Province',
		'iso': 'BE',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.63015127084499,
		'lon': 5.560084016923094,
		'name': 'Liège Province',
		'iso': 'BE',
		'NumberOfSessions': 32
	},
	{
		'lat': 50.49996022097972,
		'lon': 4.744414073475061,
		'name': 'Namur Province',
		'iso': 'BE',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.08605107379705,
		'lon': 3.1669048930805896,
		'name': 'West Flanders Province',
		'iso': 'BE',
		'NumberOfSessions': 10
	},
	{
		'lat': 43.783300000000004,
		'lon': 23.733300000000007,
		'name': 'Vratsa',
		'iso': 'BG',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.0848,
		'lon': 25.660600000000002,
		'name': 'Veliko Tarnovo',
		'iso': 'BG',
		'NumberOfSessions': 2
	},
	{
		'lat': 42.1978,
		'lon': 25.336699999999997,
		'name': 'Stara Zagora',
		'iso': 'BG',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.5606,
		'lon': 27.8299,
		'name': 'Dobrich',
		'iso': 'BG',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.2612,
		'lon': 26.9086,
		'name': 'Shumen',
		'iso': 'BG',
		'NumberOfSessions': 1
	},
	{
		'lat': 42.695100000000004,
		'lon': 23.325000000000014,
		'name': 'Sofia-Capital',
		'iso': 'BG',
		'NumberOfSessions': 46
	},
	{
		'lat': 42.6034,
		'lon': 23.025699999999993,
		'name': 'Pernik',
		'iso': 'BG',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.71001717626021,
		'lon': 23.15935356638338,
		'name': 'Blagoevgrad',
		'iso': 'BG',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.8627,
		'lon': 25.964800000000004,
		'name': 'Ruse',
		'iso': 'BG',
		'NumberOfSessions': 3
	},
	{
		'lat': 42.117862159435006,
		'lon': 24.782373297600657,
		'name': 'Plovdiv',
		'iso': 'BG',
		'NumberOfSessions': 8
	},
	{
		'lat': 43.4126,
		'lon': 24.6089,
		'name': 'Pleven',
		'iso': 'BG',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.2002,
		'lon': 27.942500000000003,
		'name': 'Varna',
		'iso': 'BG',
		'NumberOfSessions': 12
	},
	{
		'lat': 42.4963,
		'lon': 27.464600000000004,
		'name': 'Burgas',
		'iso': 'BG',
		'NumberOfSessions': 5
	},
	{
		'lat': -3.1031999999999993,
		'lon': -60.0288,
		'name': 'Amazonas',
		'iso': 'BR',
		'NumberOfSessions': 2
	},
	{
		'lat': -27.207689152904223,
		'lon': -49.167674627982784,
		'name': 'Santa Catarina',
		'iso': 'BR',
		'NumberOfSessions': 27
	},
	{
		'lat': -24.854370025440126,
		'lon': -50.169285737649545,
		'name': 'Paraná',
		'iso': 'BR',
		'NumberOfSessions': 26
	},
	{
		'lat': -22.823483717352303,
		'lon': -43.17459979501422,
		'name': 'Rio de Janeiro',
		'iso': 'BR',
		'NumberOfSessions': 42
	},
	{
		'lat': -19.97674233220311,
		'lon': -40.41727060600315,
		'name': 'Espírito Santo',
		'iso': 'BR',
		'NumberOfSessions': 8
	},
	{
		'lat': -7.143890506784074,
		'lon': -35.90499591548904,
		'name': 'Paraíba',
		'iso': 'BR',
		'NumberOfSessions': 6
	},
	{
		'lat': -8.120484446835855,
		'lon': -35.297355443441454,
		'name': 'Pernambuco',
		'iso': 'BR',
		'NumberOfSessions': 7
	},
	{
		'lat': -21.033186055383652,
		'lon': -54.211200717522495,
		'name': 'Mato Grosso do Sul',
		'iso': 'BR',
		'NumberOfSessions': 6
	},
	{
		'lat': -19.986804447921287,
		'lon': -44.298134311702654,
		'name': 'Minas Gerais',
		'iso': 'BR',
		'NumberOfSessions': 78
	},
	{
		'lat': -29.76006970729488,
		'lon': -51.45772591100999,
		'name': 'Rio Grande do Sul',
		'iso': 'BR',
		'NumberOfSessions': 31
	},
	{
		'lat': -15.778709093649358,
		'lon': -47.93289999757893,
		'name': 'Federal District',
		'iso': 'BR',
		'NumberOfSessions': 11
	},
	{
		'lat': -3.350337696624993,
		'lon': -44.90728199730271,
		'name': 'Maranhão',
		'iso': 'BR',
		'NumberOfSessions': 7
	},
	{
		'lat': -9.572232772695179,
		'lon': -48.30105963174594,
		'name': 'Tocantins',
		'iso': 'BR',
		'NumberOfSessions': 5
	},
	{
		'lat': -3.729009201737746,
		'lon': -39.6145723465439,
		'name': 'Ceará',
		'iso': 'BR',
		'NumberOfSessions': 8
	},
	{
		'lat': -10.2127,
		'lon': -63.8205,
		'name': 'Rondônia',
		'iso': 'BR',
		'NumberOfSessions': 1
	},
	{
		'lat': 2.823599999999999,
		'lon': -60.6745,
		'name': 'Roraima',
		'iso': 'BR',
		'NumberOfSessions': 1
	},
	{
		'lat': -23.1643652445656,
		'lon': -47.065952482450015,
		'name': 'São Paulo',
		'iso': 'BR',
		'NumberOfSessions': 120
	},
	{
		'lat': -16.612087180723254,
		'lon': -49.22776380916715,
		'name': 'Goiás',
		'iso': 'BR',
		'NumberOfSessions': 7
	},
	{
		'lat': -14.585991085976277,
		'lon': -55.78552417507352,
		'name': 'Mato Grosso',
		'iso': 'BR',
		'NumberOfSessions': 5
	},
	{
		'lat': 0.0331,
		'lon': -51.0634,
		'name': 'Amapá',
		'iso': 'BR',
		'NumberOfSessions': 1
	},
	{
		'lat': -1.6850264389264888,
		'lon': -48.524773688460236,
		'name': 'Pará',
		'iso': 'BR',
		'NumberOfSessions': 9
	},
	{
		'lat': -5.868150075939567,
		'lon': -35.21425094662009,
		'name': 'Rio Grande do Norte',
		'iso': 'BR',
		'NumberOfSessions': 4
	},
	{
		'lat': -9.879857767533093,
		'lon': -36.25624241430391,
		'name': 'Alagoas',
		'iso': 'BR',
		'NumberOfSessions': 3
	},
	{
		'lat': -12.105914606217556,
		'lon': -39.77289739777527,
		'name': 'Bahia',
		'iso': 'BR',
		'NumberOfSessions': 9
	},
	{
		'lat': 53.66452810950665,
		'lon': 29.966865317336023,
		'name': 'Mogilev',
		'iso': 'BY',
		'NumberOfSessions': 3
	},
	{
		'lat': 55.26055317386028,
		'lon': 29.881741962050604,
		'name': 'Vitebsk',
		'iso': 'BY',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.48959019248207,
		'lon': 24.33195550733935,
		'name': 'Grodnenskaya',
		'iso': 'BY',
		'NumberOfSessions': 6
	},
	{
		'lat': 52.11457254251996,
		'lon': 24.88620481852624,
		'name': 'Brest',
		'iso': 'BY',
		'NumberOfSessions': 6
	},
	{
		'lat': 53.9965,
		'lon': 28.002,
		'name': 'Minsk',
		'iso': 'BY',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.90070000000001,
		'lon': 27.570900000000005,
		'name': 'Minsk City',
		'iso': 'BY',
		'NumberOfSessions': 66
	},
	{
		'lat': 52.4319,
		'lon': 30.973100000000002,
		'name': 'Homyel’ Voblasc’',
		'iso': 'BY',
		'NumberOfSessions': 3
	},
	{
		'lat': 62.456399999999995,
		'lon': -114.3368,
		'name': 'Northwest Territories',
		'iso': 'CA',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.92417647331148,
		'lon': -79.38619111808076,
		'name': 'Ontario',
		'iso': 'CA',
		'NumberOfSessions': 556
	},
	{
		'lat': 49.46325752033794,
		'lon': -122.23418465621127,
		'name': 'British Columbia',
		'iso': 'CA',
		'NumberOfSessions': 193
	},
	{
		'lat': 60.718,
		'lon': -135.0475,
		'name': 'Yukon',
		'iso': 'CA',
		'NumberOfSessions': 6
	},
	{
		'lat': 46.2952087463291,
		'lon': -72.54587546642828,
		'name': 'Quebec',
		'iso': 'CA',
		'NumberOfSessions': 429
	},
	{
		'lat': 51.540457974988215,
		'lon': -105.46975240342096,
		'name': 'Saskatchewan',
		'iso': 'CA',
		'NumberOfSessions': 36
	},
	{
		'lat': 45.04099442873736,
		'lon': -62.99288473315179,
		'name': 'Nova Scotia',
		'iso': 'CA',
		'NumberOfSessions': 41
	},
	{
		'lat': 49.920305051995406,
		'lon': -97.37566046656177,
		'name': 'Manitoba',
		'iso': 'CA',
		'NumberOfSessions': 35
	},
	{
		'lat': 52.275313141197316,
		'lon': -113.83919185868835,
		'name': 'Alberta',
		'iso': 'CA',
		'NumberOfSessions': 155
	},
	{
		'lat': 46.2761,
		'lon': -62.9985,
		'name': 'Prince Edward Island',
		'iso': 'CA',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.16239155491461,
		'lon': -54.47479019745352,
		'name': 'Newfoundland and Labrador',
		'iso': 'CA',
		'NumberOfSessions': 7
	},
	{
		'lat': 46.14293899609343,
		'lon': -65.614140894646,
		'name': 'New Brunswick',
		'iso': 'CA',
		'NumberOfSessions': 24
	},
	{
		'lat': 46.9838,
		'lon': 9.0545,
		'name': 'Glarus',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.202378972966194,
		'lon': 6.94051722499183,
		'name': 'Valais',
		'iso': 'CH',
		'NumberOfSessions': 4
	},
	{
		'lat': 47.3996658548159,
		'lon': 8.115145091285202,
		'name': 'Aargau',
		'iso': 'CH',
		'NumberOfSessions': 5
	},
	{
		'lat': 46.9904,
		'lon': 6.9339,
		'name': 'Neuchâtel',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.3883,
		'lon': 9.279199999999998,
		'name': 'Appenzell Ausserrhoden',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.551100793133784,
		'lon': 7.5840787449943,
		'name': 'Basel-City',
		'iso': 'CH',
		'NumberOfSessions': 4
	},
	{
		'lat': 47.38810566562281,
		'lon': 8.53332783516026,
		'name': 'Zurich',
		'iso': 'CH',
		'NumberOfSessions': 51
	},
	{
		'lat': 47.200037848208936,
		'lon': 8.459262702334247,
		'name': 'Zug',
		'iso': 'CH',
		'NumberOfSessions': 3
	},
	{
		'lat': 47.321263227218516,
		'lon': 7.656673144696083,
		'name': 'Solothurn',
		'iso': 'CH',
		'NumberOfSessions': 5
	},
	{
		'lat': 47.50180000000001,
		'lon': 7.587100000000001,
		'name': 'Basel-Landschaft',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.52850485271778,
		'lon': 9.24572602479206,
		'name': 'Thurgau',
		'iso': 'CH',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.19921746076833,
		'lon': 6.151981200845853,
		'name': 'Geneva',
		'iso': 'CH',
		'NumberOfSessions': 6
	},
	{
		'lat': 46.504670883490114,
		'lon': 6.672761329391522,
		'name': 'Vaud',
		'iso': 'CH',
		'NumberOfSessions': 27
	},
	{
		'lat': 47.44070809604952,
		'lon': 9.30380682865027,
		'name': 'Saint Gallen',
		'iso': 'CH',
		'NumberOfSessions': 14
	},
	{
		'lat': 47.05615396732991,
		'lon': 7.521993195476802,
		'name': 'Bern',
		'iso': 'CH',
		'NumberOfSessions': 11
	},
	{
		'lat': 46.07445002947527,
		'lon': 8.95070338979896,
		'name': 'Ticino',
		'iso': 'CH',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.748690715562695,
		'lon': 7.073398093884462,
		'name': 'Fribourg',
		'iso': 'CH',
		'NumberOfSessions': 15
	},
	{
		'lat': 47.7191,
		'lon': 8.624600000000001,
		'name': 'Schaffhausen',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.851000000000006,
		'lon': 9.538900000000003,
		'name': 'Grisons',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.103374318619075,
		'lon': 8.207116567856726,
		'name': 'Lucerne',
		'iso': 'CH',
		'NumberOfSessions': 3
	},
	{
		'lat': -39.8119,
		'lon': -73.2493,
		'name': 'Los Ríos Region',
		'iso': 'CL',
		'NumberOfSessions': 3
	},
	{
		'lat': -22.467600000000008,
		'lon': -68.9335,
		'name': 'Antofagasta',
		'iso': 'CL',
		'NumberOfSessions': 2
	},
	{
		'lat': -20.2701,
		'lon': -70.11500000000001,
		'name': 'Tarapacá',
		'iso': 'CL',
		'NumberOfSessions': 1
	},
	{
		'lat': -34.1703,
		'lon': -70.74060000000001,
		'name': 'O\'Higgins Region',
		'iso': 'CL',
		'NumberOfSessions': 2
	},
	{
		'lat': -33.43631965331805,
		'lon': -70.66615994123283,
		'name': 'Santiago Metropolitan',
		'iso': 'CL',
		'NumberOfSessions': 78
	},
	{
		'lat': -41.14685497137473,
		'lon': -73.01678159064195,
		'name': 'Los Lagos Region',
		'iso': 'CL',
		'NumberOfSessions': 6
	},
	{
		'lat': -30.090531135410192,
		'lon': -71.26453606330911,
		'name': 'Coquimbo Region',
		'iso': 'CL',
		'NumberOfSessions': 4
	},
	{
		'lat': -18.4787,
		'lon': -70.3027,
		'name': 'Arica y Parinacota Region',
		'iso': 'CL',
		'NumberOfSessions': 1
	},
	{
		'lat': -36.82460168897333,
		'lon': -73.05724422065734,
		'name': 'Región del Biobío',
		'iso': 'CL',
		'NumberOfSessions': 13
	},
	{
		'lat': -35.64505319392803,
		'lon': -71.62927138174926,
		'name': 'Maule Region',
		'iso': 'CL',
		'NumberOfSessions': 2
	},
	{
		'lat': -33.0276078088575,
		'lon': -71.52635418013408,
		'name': 'Región de Valparaíso',
		'iso': 'CL',
		'NumberOfSessions': 4
	},
	{
		'lat': -45.5646,
		'lon': -72.0778,
		'name': 'Aysén',
		'iso': 'CL',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.328700000000005,
		'lon': 109.0337,
		'name': 'Shaanxi',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.7599,
		'lon': 113.6459,
		'name': 'Henan',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 30.2994,
		'lon': 120.1612,
		'name': 'Zhejiang',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.911,
		'lon': 116.39499999999998,
		'name': 'Beijing',
		'iso': 'CN',
		'NumberOfSessions': 3
	},
	{
		'lat': 24.479300000000006,
		'lon': 118.06729999999999,
		'name': 'Fujian',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.9384,
		'lon': 121.62239999999998,
		'name': 'Liaoning',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 32.0589,
		'lon': 118.77380000000001,
		'name': 'Jiangsu',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 11.0071,
		'lon': -74.8092,
		'name': 'Atlántico',
		'iso': 'CO',
		'NumberOfSessions': 2
	},
	{
		'lat': 3.412900000000001,
		'lon': -76.51910000000001,
		'name': 'Valle del Cauca Department',
		'iso': 'CO',
		'NumberOfSessions': 5
	},
	{
		'lat': 4.1409,
		'lon': -73.626,
		'name': 'Meta Department',
		'iso': 'CO',
		'NumberOfSessions': 1
	},
	{
		'lat': 4.8093,
		'lon': -75.697,
		'name': 'Risaralda Department',
		'iso': 'CO',
		'NumberOfSessions': 2
	},
	{
		'lat': 7.8946,
		'lon': -72.5018,
		'name': 'Norte de Santander Department',
		'iso': 'CO',
		'NumberOfSessions': 4
	},
	{
		'lat': 10.975800000000003,
		'lon': -74.3109,
		'name': 'Magdalena Department',
		'iso': 'CO',
		'NumberOfSessions': 3
	},
	{
		'lat': 6.246369358128014,
		'lon': -75.5654300716834,
		'name': 'Antioquia',
		'iso': 'CO',
		'NumberOfSessions': 30
	},
	{
		'lat': 4.6214287411624655,
		'lon': -74.0854833532776,
		'name': 'Bogota D.C.',
		'iso': 'CO',
		'NumberOfSessions': 49
	},
	{
		'lat': 10.465317647059685,
		'lon': -73.24979411765241,
		'name': 'Cesar Department',
		'iso': 'CO',
		'NumberOfSessions': 17
	},
	{
		'lat': 7.0679,
		'lon': -73.8536,
		'name': 'Santander Department',
		'iso': 'CO',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.953799999999996,
		'lon': -72.7893,
		'name': 'La Guajira Department',
		'iso': 'CO',
		'NumberOfSessions': 1
	},
	{
		'lat': 2.9208,
		'lon': -75.283,
		'name': 'Huila Department',
		'iso': 'CO',
		'NumberOfSessions': 1
	},
	{
		'lat': 2.5732,
		'lon': -72.64790000000002,
		'name': 'Guaviare Department',
		'iso': 'CO',
		'NumberOfSessions': 2
	},
	{
		'lat': 4.8588,
		'lon': -74.0553,
		'name': 'Cundinamarca',
		'iso': 'CO',
		'NumberOfSessions': 2
	},
	{
		'lat': 10.401299999999999,
		'lon': -75.5267,
		'name': 'Departamento de Bolívar',
		'iso': 'CO',
		'NumberOfSessions': 1
	},
	{
		'lat': 5.017,
		'lon': -72.7566,
		'name': 'Casanare Department',
		'iso': 'CO',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.1436,
		'lon': 14.101,
		'name': 'Kladno District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.97007055088533,
		'lon': 14.333417889894266,
		'name': 'Okres Praha-západ',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.67315118597035,
		'lon': 14.048059169029102,
		'name': 'Okres Ústí nad Labem',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.1192,
		'lon': 16.2899,
		'name': 'Rychnov nad Kněžnou District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.43958674979293,
		'lon': 18.03953195045857,
		'name': 'Vsetín District',
		'iso': 'CZ',
		'NumberOfSessions': 4
	},
	{
		'lat': 50.6347,
		'lon': 13.8242,
		'name': 'Teplice District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.364,
		'lon': 16.6599,
		'name': 'Blansko District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.181,
		'lon': 12.637199999999996,
		'name': 'Sokolov District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.343477218962576,
		'lon': 13.66615874639591,
		'name': 'Louny District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.257842518837315,
		'lon': 14.38728253197877,
		'name': 'Mělník District',
		'iso': 'CZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 50.032662365753936,
		'lon': 15.909183348838843,
		'name': 'Pardubice District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.78045001394076,
		'lon': 16.230596896114523,
		'name': 'Svitavy District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.6809,
		'lon': 18.3456,
		'name': 'Okres Frýdek-Místek',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.0788,
		'lon': 12.353199999999998,
		'name': 'Cheb District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.80291545306576,
		'lon': 18.244206378208276,
		'name': 'Okres Ostrava-město',
		'iso': 'CZ',
		'NumberOfSessions': 8
	},
	{
		'lat': 49.9019,
		'lon': 16.4504,
		'name': 'Ústí nad Orlicí District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.5048,
		'lon': 13.646599999999996,
		'name': 'Most District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.40230025411509,
		'lon': 14.94350560498484,
		'name': 'Mladá Boleslav District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.912826872193804,
		'lon': 14.017116437987092,
		'name': 'Beroun District',
		'iso': 'CZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 50.20487054877331,
		'lon': 15.805130542115599,
		'name': 'Hradec Králové District',
		'iso': 'CZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 49.433600000000006,
		'lon': 12.713300000000004,
		'name': 'Domažlice District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.74168791710001,
		'lon': 13.38432129546205,
		'name': 'Okres Plzeň-město',
		'iso': 'CZ',
		'NumberOfSessions': 10
	},
	{
		'lat': 50.367,
		'lon': 15.627699999999994,
		'name': 'Jičín District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.087469802430206,
		'lon': 14.433104767115342,
		'name': 'Prague',
		'iso': 'CZ',
		'NumberOfSessions': 120
	},
	{
		'lat': 49.860179734493784,
		'lon': 17.837500533221668,
		'name': 'Opava District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.718,
		'lon': 13.229900000000002,
		'name': 'Okres Plzeň-sever',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.7139,
		'lon': 17.901,
		'name': 'Nový Jičín District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.8032,
		'lon': 15.0007,
		'name': 'Liberec District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.97573750023161,
		'lon': 14.467287501996633,
		'name': 'České Budějovice District',
		'iso': 'CZ',
		'NumberOfSessions': 8
	},
	{
		'lat': 49.634662667398366,
		'lon': 13.449718249849687,
		'name': 'Okres Plzeň-jih',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.09440039806805,
		'lon': 17.457006165344964,
		'name': 'Uherské Hradiště District',
		'iso': 'CZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 49.17764733160079,
		'lon': 16.58311910265243,
		'name': 'Město Brno',
		'iso': 'CZ',
		'NumberOfSessions': 28
	},
	{
		'lat': 50.4144,
		'lon': 16.1682,
		'name': 'Náchod District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.14810319709517,
		'lon': 16.038114362259495,
		'name': 'Třebíč District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.87293508327395,
		'lon': 17.13052085105821,
		'name': 'Hodonín District',
		'iso': 'CZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 50.04085242316244,
		'lon': 14.701221115644245,
		'name': 'Okres Praha-východ',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.20277443060005,
		'lon': 14.961584696119587,
		'name': 'Nymburk District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.82538667608557,
		'lon': 18.48263047407852,
		'name': 'Okres Karviná',
		'iso': 'CZ',
		'NumberOfSessions': 4
	},
	{
		'lat': 49.675742760894344,
		'lon': 17.28462209177807,
		'name': 'Olomouc District',
		'iso': 'CZ',
		'NumberOfSessions': 5
	},
	{
		'lat': 49.58610000000001,
		'lon': 17.70070000000001,
		'name': 'Přerov District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.3503,
		'lon': 16.0186,
		'name': 'Žďár nad Sázavou District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.1458,
		'lon': 16.866000000000003,
		'name': 'Vyškov District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.48048387925905,
		'lon': 17.11963287921971,
		'name': 'Prostějov District',
		'iso': 'CZ',
		'NumberOfSessions': 6
	},
	{
		'lat': 52.51084743680913,
		'lon': 13.40483206588247,
		'name': 'Land Berlin',
		'iso': 'DE',
		'NumberOfSessions': 246
	},
	{
		'lat': 51.064023651913814,
		'lon': 12.93289850288883,
		'name': 'Saxony',
		'iso': 'DE',
		'NumberOfSessions': 196
	},
	{
		'lat': 49.78907997293932,
		'lon': 7.947434746260239,
		'name': 'Rheinland-Pfalz',
		'iso': 'DE',
		'NumberOfSessions': 98
	},
	{
		'lat': 51.379179374442785,
		'lon': 7.196876920243219,
		'name': 'North Rhine-Westphalia',
		'iso': 'DE',
		'NumberOfSessions': 634
	},
	{
		'lat': 50.21514319988586,
		'lon': 8.67350204099152,
		'name': 'Hesse',
		'iso': 'DE',
		'NumberOfSessions': 422
	},
	{
		'lat': 50.97899507883483,
		'lon': 10.975083566339043,
		'name': 'Thuringia',
		'iso': 'DE',
		'NumberOfSessions': 47
	},
	{
		'lat': 53.119782439827574,
		'lon': 8.777526697061205,
		'name': 'Bremen',
		'iso': 'DE',
		'NumberOfSessions': 45
	},
	{
		'lat': 48.7259304213226,
		'lon': 11.391404596105989,
		'name': 'Bavaria',
		'iso': 'DE',
		'NumberOfSessions': 460
	},
	{
		'lat': 53.56743543699098,
		'lon': 10.001261916662703,
		'name': 'Free and Hanseatic City of Hamburg',
		'iso': 'DE',
		'NumberOfSessions': 174
	},
	{
		'lat': 54.078370425969425,
		'lon': 10.115831658247995,
		'name': 'Schleswig-Holstein',
		'iso': 'DE',
		'NumberOfSessions': 72
	},
	{
		'lat': 51.711542754460545,
		'lon': 11.876417341390315,
		'name': 'Saxony-Anhalt',
		'iso': 'DE',
		'NumberOfSessions': 59
	},
	{
		'lat': 52.346899118061735,
		'lon': 13.47771135090175,
		'name': 'Brandenburg',
		'iso': 'DE',
		'NumberOfSessions': 52
	},
	{
		'lat': 52.51483635195027,
		'lon': 9.529433298482468,
		'name': 'Lower Saxony',
		'iso': 'DE',
		'NumberOfSessions': 249
	},
	{
		'lat': 49.33205147583493,
		'lon': 6.961528789925835,
		'name': 'Saarland',
		'iso': 'DE',
		'NumberOfSessions': 27
	},
	{
		'lat': 48.728532876735244,
		'lon': 8.907704250898313,
		'name': 'Baden-Wurttemberg',
		'iso': 'DE',
		'NumberOfSessions': 378
	},
	{
		'lat': 53.83168728332173,
		'lon': 12.299375690694232,
		'name': 'Mecklenburg-Vorpommern',
		'iso': 'DE',
		'NumberOfSessions': 25
	},
	{
		'lat': 36.3758,
		'lon': 6.608300000000001,
		'name': 'Constantine',
		'iso': 'DZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 30.905799999999996,
		'lon': -2.3049999999999997,
		'name': 'Béchar',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.8866,
		'lon': 6.905,
		'name': 'Skikda',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.2394,
		'lon': 1.3342000000000003,
		'name': 'Chlef',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.4682,
		'lon': 4.7891,
		'name': 'Béjaïa',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.7204,
		'lon': -0.6659000000000002,
		'name': 'Oran',
		'iso': 'DZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.5064,
		'lon': 6.0707,
		'name': 'Batna',
		'iso': 'DZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 36.74003853452565,
		'lon': 3.0909663650600177,
		'name': 'Algiers',
		'iso': 'DZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 32.6027,
		'lon': 5.913499999999999,
		'name': 'Ouargla',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.5312,
		'lon': 3.0698,
		'name': 'Blida',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 33.9164,
		'lon': 2.5379,
		'name': 'Laghouat',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.0718,
		'lon': 4.7602,
		'name': 'Bordj Bou Arréridj',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.8992,
		'lon': -1.2874,
		'name': 'Tlemcen',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': -0.23654166834280874,
		'lon': -78.52369998688917,
		'name': 'Pichincha',
		'iso': 'EC',
		'NumberOfSessions': 12
	},
	{
		'lat': -0.93,
		'lon': -75.401,
		'name': 'Orellana Province',
		'iso': 'EC',
		'NumberOfSessions': 1
	},
	{
		'lat': -3.9908999999999986,
		'lon': -79.202,
		'name': 'Loja',
		'iso': 'EC',
		'NumberOfSessions': 1
	},
	{
		'lat': -0.9336000000000004,
		'lon': -78.62,
		'name': 'Cotopaxi',
		'iso': 'EC',
		'NumberOfSessions': 2
	},
	{
		'lat': -2.1978000000000004,
		'lon': -79.8764,
		'name': 'Guayas',
		'iso': 'EC',
		'NumberOfSessions': 8
	},
	{
		'lat': -0.9443999999999999,
		'lon': -80.7356,
		'name': 'Manabí',
		'iso': 'EC',
		'NumberOfSessions': 1
	},
	{
		'lat': 0.3512,
		'lon': -78.1178,
		'name': 'Imbabura',
		'iso': 'EC',
		'NumberOfSessions': 1
	},
	{
		'lat': 58.3891,
		'lon': 24.498300000000004,
		'name': 'Pärnu linn',
		'iso': 'EE',
		'NumberOfSessions': 1
	},
	{
		'lat': 59.3465,
		'lon': 26.356600000000007,
		'name': 'Rakvere linn',
		'iso': 'EE',
		'NumberOfSessions': 1
	},
	{
		'lat': 58.361900000000006,
		'lon': 25.589699999999997,
		'name': 'Viljandi linn',
		'iso': 'EE',
		'NumberOfSessions': 2
	},
	{
		'lat': 59.46589999999998,
		'lon': 24.975,
		'name': 'Maardu linn',
		'iso': 'EE',
		'NumberOfSessions': 3
	},
	{
		'lat': 59.29820000000001,
		'lon': 24.4106,
		'name': 'Keila linn',
		'iso': 'EE',
		'NumberOfSessions': 1
	},
	{
		'lat': 59.3755,
		'lon': 28.2032,
		'name': 'Narva linn',
		'iso': 'EE',
		'NumberOfSessions': 1
	},
	{
		'lat': 59.43810000000001,
		'lon': 24.736899999999995,
		'name': 'Tallinn',
		'iso': 'EE',
		'NumberOfSessions': 48
	},
	{
		'lat': 59.398300000000006,
		'lon': 27.268000000000015,
		'name': 'Kohtla-Järve linn',
		'iso': 'EE',
		'NumberOfSessions': 5
	},
	{
		'lat': 58.379400000000004,
		'lon': 26.732200000000002,
		'name': 'Tartu linn',
		'iso': 'EE',
		'NumberOfSessions': 2
	},
	{
		'lat': 30.6173,
		'lon': 31.72919999999999,
		'name': 'Sharqia',
		'iso': 'EG',
		'NumberOfSessions': 1
	},
	{
		'lat': 30.061560261929145,
		'lon': 31.230638657926782,
		'name': 'Cairo Governorate',
		'iso': 'EG',
		'NumberOfSessions': 22
	},
	{
		'lat': 29.0776,
		'lon': 31.098600000000005,
		'name': 'Beni Suweif',
		'iso': 'EG',
		'NumberOfSessions': 1
	},
	{
		'lat': 30.008000000000003,
		'lon': 31.219400000000004,
		'name': 'Giza',
		'iso': 'EG',
		'NumberOfSessions': 7
	},
	{
		'lat': 31.0371,
		'lon': 31.38439999999999,
		'name': 'Dakahlia',
		'iso': 'EG',
		'NumberOfSessions': 1
	},
	{
		'lat': 30.997700000000002,
		'lon': 29.7432,
		'name': 'Alexandria',
		'iso': 'EG',
		'NumberOfSessions': 2
	},
	{
		'lat': 31.41660000000001,
		'lon': 31.81050000000001,
		'name': 'Damietta Governorate',
		'iso': 'EG',
		'NumberOfSessions': 3
	},
	{
		'lat': 39.44951216079782,
		'lon': -0.41543649394695736,
		'name': 'Valencia',
		'iso': 'ES',
		'NumberOfSessions': 55
	},
	{
		'lat': 43.24361137601476,
		'lon': -8.413243712156065,
		'name': 'A Coruña',
		'iso': 'ES',
		'NumberOfSessions': 35
	},
	{
		'lat': 42.16713663832082,
		'lon': -3.581290910314323,
		'name': 'Burgos',
		'iso': 'ES',
		'NumberOfSessions': 6
	},
	{
		'lat': 36.491200711417505,
		'lon': -6.063438499275021,
		'name': 'Cadiz',
		'iso': 'ES',
		'NumberOfSessions': 14
	},
	{
		'lat': 41.63970630935579,
		'lon': -0.9149205618530865,
		'name': 'Saragossa',
		'iso': 'ES',
		'NumberOfSessions': 14
	},
	{
		'lat': 37.06759081804714,
		'lon': -3.60645502923006,
		'name': 'Granada',
		'iso': 'ES',
		'NumberOfSessions': 18
	},
	{
		'lat': 35.877500209092176,
		'lon': -5.3280005711185705,
		'name': 'Ceuta',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 40.0098,
		'lon': -3.0009999999999994,
		'name': 'Cuenca',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.90043080154673,
		'lon': -1.1464109821159048,
		'name': 'Murcia',
		'iso': 'ES',
		'NumberOfSessions': 17
	},
	{
		'lat': 42.61216035489624,
		'lon': -5.581282311302478,
		'name': 'Leon',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 40.956700000000005,
		'lon': -5.693200000000001,
		'name': 'Salamanca',
		'iso': 'ES',
		'NumberOfSessions': 3
	},
	{
		'lat': 42.33661250000475,
		'lon': -7.869087500017391,
		'name': 'Ourense',
		'iso': 'ES',
		'NumberOfSessions': 8
	},
	{
		'lat': 37.37777518918835,
		'lon': -5.90451523523926,
		'name': 'Seville',
		'iso': 'ES',
		'NumberOfSessions': 22
	},
	{
		'lat': 36.92122047933408,
		'lon': -2.442878250480166,
		'name': 'Almeria',
		'iso': 'ES',
		'NumberOfSessions': 9
	},
	{
		'lat': 37.873306240515056,
		'lon': -3.6522472347174264,
		'name': 'Jaen',
		'iso': 'ES',
		'NumberOfSessions': 3
	},
	{
		'lat': 41.45429836258541,
		'lon': 2.1556895653313215,
		'name': 'Barcelona',
		'iso': 'ES',
		'NumberOfSessions': 159
	},
	{
		'lat': 37.83164683509111,
		'lon': -4.734971226085078,
		'name': 'Cordoba',
		'iso': 'ES',
		'NumberOfSessions': 9
	},
	{
		'lat': 38.996620023599604,
		'lon': -1.854179996744329,
		'name': 'Albacete',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 40.40937364483748,
		'lon': -3.6975895616158514,
		'name': 'Madrid',
		'iso': 'ES',
		'NumberOfSessions': 301
	},
	{
		'lat': 28.27898144869335,
		'lon': -14.98113142254705,
		'name': 'Las Palmas',
		'iso': 'ES',
		'NumberOfSessions': 11
	},
	{
		'lat': 41.63828685321858,
		'lon': -4.7451653750431335,
		'name': 'Valladolid',
		'iso': 'ES',
		'NumberOfSessions': 13
	},
	{
		'lat': 43.01290000009795,
		'lon': -7.552249992184251,
		'name': 'Lugo',
		'iso': 'ES',
		'NumberOfSessions': 4
	},
	{
		'lat': 43.443456877715626,
		'lon': -5.759235253098627,
		'name': 'Asturias',
		'iso': 'ES',
		'NumberOfSessions': 17
	},
	{
		'lat': 40.191285248491035,
		'lon': 0.15890678440723013,
		'name': 'Castellon',
		'iso': 'ES',
		'NumberOfSessions': 4
	},
	{
		'lat': 28.23546805281668,
		'lon': -16.53648800187574,
		'name': 'Santa Cruz de Tenerife',
		'iso': 'ES',
		'NumberOfSessions': 8
	},
	{
		'lat': 41.85130956242612,
		'lon': 2.7102139354735266,
		'name': 'Girona',
		'iso': 'ES',
		'NumberOfSessions': 3
	},
	{
		'lat': 39.62946778761194,
		'lon': 2.8552671232165334,
		'name': 'Balearic Islands',
		'iso': 'ES',
		'NumberOfSessions': 17
	},
	{
		'lat': 43.3699,
		'lon': -8.4096,
		'name': 'Galicia',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.6251779104599,
		'lon': -6.754284051645754,
		'name': 'Badajoz',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 38.35105617637388,
		'lon': -0.4865826337824802,
		'name': 'Alicante',
		'iso': 'ES',
		'NumberOfSessions': 37
	},
	{
		'lat': 36.64847653230894,
		'lon': -4.60172584462088,
		'name': 'Malaga',
		'iso': 'ES',
		'NumberOfSessions': 41
	},
	{
		'lat': 39.702757367811714,
		'lon': -6.318173129423119,
		'name': 'Caceres',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 41.5,
		'lon': -5.7438,
		'name': 'Zamora',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 42.84435001828498,
		'lon': -2.674650456272155,
		'name': 'Araba / Álava',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.28929424620877,
		'lon': -2.96658722408364,
		'name': 'Biscay',
		'iso': 'ES',
		'NumberOfSessions': 31
	},
	{
		'lat': 41.06169816356209,
		'lon': 1.0958081271294733,
		'name': 'Tarragona',
		'iso': 'ES',
		'NumberOfSessions': 18
	},
	{
		'lat': 28.1,
		'lon': -15.4098,
		'name': 'Canary Islands',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 41.6547,
		'lon': -4.7344,
		'name': 'Castille and León',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 42.79883228916064,
		'lon': -1.6714299989543295,
		'name': 'Navarre',
		'iso': 'ES',
		'NumberOfSessions': 19
	},
	{
		'lat': 41.39490000000001,
		'lon': 2.1756000000000006,
		'name': 'Catalonia',
		'iso': 'ES',
		'NumberOfSessions': 15
	},
	{
		'lat': 39.9424817675793,
		'lon': -3.8698590018681616,
		'name': 'Toledo',
		'iso': 'ES',
		'NumberOfSessions': 14
	},
	{
		'lat': 41.99928573384476,
		'lon': -0.3281090396246539,
		'name': 'Huesca',
		'iso': 'ES',
		'NumberOfSessions': 11
	},
	{
		'lat': 41.6674,
		'lon': 1.2689999999999997,
		'name': 'Lleida',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 36.72610000000002,
		'lon': -4.426000000000002,
		'name': 'Andalusia',
		'iso': 'ES',
		'NumberOfSessions': 18
	},
	{
		'lat': 42.28270752372384,
		'lon': -8.700971462472689,
		'name': 'Pontevedra',
		'iso': 'ES',
		'NumberOfSessions': 12
	},
	{
		'lat': 42.4701,
		'lon': -2.4523999999999986,
		'name': 'La Rioja',
		'iso': 'ES',
		'NumberOfSessions': 6
	},
	{
		'lat': 43.303187270880976,
		'lon': -1.993929364975358,
		'name': 'Gipuzkoa',
		'iso': 'ES',
		'NumberOfSessions': 35
	},
	{
		'lat': 38.99419361808046,
		'lon': -3.6466806727078422,
		'name': 'Ciudad Real',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.39329892118285,
		'lon': -3.8385401913852704,
		'name': 'Cantabria',
		'iso': 'ES',
		'NumberOfSessions': 10
	},
	{
		'lat': 40.6089437415216,
		'lon': -3.192553456752147,
		'name': 'Guadalajara',
		'iso': 'ES',
		'NumberOfSessions': 3
	},
	{
		'lat': 37.2416007384583,
		'lon': -6.946439056648689,
		'name': 'Huelva',
		'iso': 'ES',
		'NumberOfSessions': 3
	},
	{
		'lat': 60.98839999999999,
		'lon': 24.4634,
		'name': 'Kanta-Häme',
		'iso': 'FI',
		'NumberOfSessions': 1
	},
	{
		'lat': 61.50673061090964,
		'lon': 23.74259772415854,
		'name': 'Pirkanmaa',
		'iso': 'FI',
		'NumberOfSessions': 11
	},
	{
		'lat': 66.4822,
		'lon': 25.6835,
		'name': 'Lapland',
		'iso': 'FI',
		'NumberOfSessions': 1
	},
	{
		'lat': 61.05995129918555,
		'lon': 28.207752160189845,
		'name': 'South Karelia Region',
		'iso': 'FI',
		'NumberOfSessions': 2
	},
	{
		'lat': 62.61339999999999,
		'lon': 29.8242,
		'name': 'North Karelia',
		'iso': 'FI',
		'NumberOfSessions': 3
	},
	{
		'lat': 60.18423568590394,
		'lon': 24.929175596111424,
		'name': 'Uusimaa',
		'iso': 'FI',
		'NumberOfSessions': 131
	},
	{
		'lat': 63.8413,
		'lon': 23.1317,
		'name': 'Central Ostrobothnia',
		'iso': 'FI',
		'NumberOfSessions': 1
	},
	{
		'lat': 62.22800879879775,
		'lon': 25.709073847268016,
		'name': 'Central Finland',
		'iso': 'FI',
		'NumberOfSessions': 3
	},
	{
		'lat': 60.55821543312508,
		'lon': 22.135924890697424,
		'name': 'Southwest Finland',
		'iso': 'FI',
		'NumberOfSessions': 4
	},
	{
		'lat': 62.893802507747324,
		'lon': 27.64196357092432,
		'name': 'North Savo',
		'iso': 'FI',
		'NumberOfSessions': 3
	},
	{
		'lat': 65.0013,
		'lon': 25.451500000000003,
		'name': 'North Ostrobothnia',
		'iso': 'FI',
		'NumberOfSessions': 3
	},
	{
		'lat': 60.98170211876854,
		'lon': 25.66370221901604,
		'name': 'Paijat-Hame Region',
		'iso': 'FI',
		'NumberOfSessions': 4
	},
	{
		'lat': 50.55537781392623,
		'lon': 3.1920471084083077,
		'name': 'North',
		'iso': 'FR',
		'NumberOfSessions': 149
	},
	{
		'lat': 48.86051720451928,
		'lon': 2.068783376575057,
		'name': 'Yvelines',
		'iso': 'FR',
		'NumberOfSessions': 65
	},
	{
		'lat': 45.61215959693438,
		'lon': 6.008660784893203,
		'name': 'Savoy',
		'iso': 'FR',
		'NumberOfSessions': 14
	},
	{
		'lat': 45.666642655144734,
		'lon': 0.0511051684714316,
		'name': 'Charente',
		'iso': 'FR',
		'NumberOfSessions': 20
	},
	{
		'lat': 48.73203036018226,
		'lon': 5.294890446179379,
		'name': 'Meuse',
		'iso': 'FR',
		'NumberOfSessions': 4
	},
	{
		'lat': 43.941610810982326,
		'lon': 1.2878233348940786,
		'name': 'Tarn-et-Garonne',
		'iso': 'FR',
		'NumberOfSessions': 7
	},
	{
		'lat': 43.81876774438052,
		'lon': 2.129354622069475,
		'name': 'Tarn',
		'iso': 'FR',
		'NumberOfSessions': 10
	},
	{
		'lat': 45.222579697572186,
		'lon': 3.7690994407565372,
		'name': 'Haute-Loire',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.94770975507076,
		'lon': 4.9933736692961626,
		'name': 'Drôme',
		'iso': 'FR',
		'NumberOfSessions': 30
	},
	{
		'lat': 48.67268573283912,
		'lon': 2.3329406456442,
		'name': 'Essonne',
		'iso': 'FR',
		'NumberOfSessions': 34
	},
	{
		'lat': 48.83304892956798,
		'lon': 2.4065966355342585,
		'name': 'Paris',
		'iso': 'FR',
		'NumberOfSessions': 466
	},
	{
		'lat': 48.07100271870196,
		'lon': -0.8277217370768786,
		'name': 'Mayenne',
		'iso': 'FR',
		'NumberOfSessions': 5
	},
	{
		'lat': 48.51468944392818,
		'lon': -2.7678307894914522,
		'name': 'Côtes-d\'Armor',
		'iso': 'FR',
		'NumberOfSessions': 14
	},
	{
		'lat': 43.21109753871314,
		'lon': 6.08567385296573,
		'name': 'Var',
		'iso': 'FR',
		'NumberOfSessions': 46
	},
	{
		'lat': 44.786693028931545,
		'lon': 1.7566311144253817,
		'name': 'Lot',
		'iso': 'FR',
		'NumberOfSessions': 9
	},
	{
		'lat': 44.80646271462092,
		'lon': -0.6187508537063415,
		'name': 'Gironde',
		'iso': 'FR',
		'NumberOfSessions': 65
	},
	{
		'lat': 42.99322562726629,
		'lon': 1.581082374475454,
		'name': 'Ariège',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.2471834374733,
		'lon': 4.140586926470591,
		'name': 'Aube',
		'iso': 'FR',
		'NumberOfSessions': 4
	},
	{
		'lat': 43.13705507834778,
		'lon': 2.9461007860909603,
		'name': 'Aude',
		'iso': 'FR',
		'NumberOfSessions': 58
	},
	{
		'lat': 44.40025221983646,
		'lon': 0.40665240109677564,
		'name': 'Lot-et-Garonne',
		'iso': 'FR',
		'NumberOfSessions': 4
	},
	{
		'lat': 49.368782326113205,
		'lon': 3.3501707850742433,
		'name': 'Aisne',
		'iso': 'FR',
		'NumberOfSessions': 18
	},
	{
		'lat': 46.1742,
		'lon': 1.8727999999999998,
		'name': 'Creuse',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.0509902185663,
		'lon': 2.347942828559821,
		'name': 'Cher',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.85422619421856,
		'lon': 1.2421317119942923,
		'name': 'Haute-Vienne',
		'iso': 'FR',
		'NumberOfSessions': 26
	},
	{
		'lat': 46.379757975479315,
		'lon': -0.36133122244327354,
		'name': 'Deux-Sèvres',
		'iso': 'FR',
		'NumberOfSessions': 29
	},
	{
		'lat': 50.475434975467124,
		'lon': 2.6284049313672004,
		'name': 'Pas-de-Calais',
		'iso': 'FR',
		'NumberOfSessions': 50
	},
	{
		'lat': 47.89760700342532,
		'lon': 3.5363237967747487,
		'name': 'Yonne',
		'iso': 'FR',
		'NumberOfSessions': 7
	},
	{
		'lat': 47.846046511949886,
		'lon': 7.352845333457969,
		'name': 'Haut-Rhin',
		'iso': 'FR',
		'NumberOfSessions': 37
	},
	{
		'lat': 43.8623257830921,
		'lon': 4.292856164289463,
		'name': 'Gard',
		'iso': 'FR',
		'NumberOfSessions': 19
	},
	{
		'lat': 49.93628457036442,
		'lon': 2.2710071853794846,
		'name': 'Somme',
		'iso': 'FR',
		'NumberOfSessions': 37
	},
	{
		'lat': 42.56447756920774,
		'lon': 9.350683253318174,
		'name': 'Upper Corsica',
		'iso': 'FR',
		'NumberOfSessions': 5
	},
	{
		'lat': 45.83264699227543,
		'lon': -0.7716109627198608,
		'name': 'Charente-Maritime',
		'iso': 'FR',
		'NumberOfSessions': 32
	},
	{
		'lat': 47.78699692923015,
		'lon': -3.132359445075121,
		'name': 'Morbihan',
		'iso': 'FR',
		'NumberOfSessions': 36
	},
	{
		'lat': 48.7875620849954,
		'lon': 2.4294927420298045,
		'name': 'Val-de-Marne',
		'iso': 'FR',
		'NumberOfSessions': 62
	},
	{
		'lat': 47.98272515951927,
		'lon': 0.2043653811527321,
		'name': 'Sarthe',
		'iso': 'FR',
		'NumberOfSessions': 41
	},
	{
		'lat': 46.80366145342498,
		'lon': 5.615561855177569,
		'name': 'Jura',
		'iso': 'FR',
		'NumberOfSessions': 14
	},
	{
		'lat': 45.07890667472076,
		'lon': 0.9389631192114996,
		'name': 'Dordogne',
		'iso': 'FR',
		'NumberOfSessions': 11
	},
	{
		'lat': 43.63187239047434,
		'lon': 0.5711443747783436,
		'name': 'Gers',
		'iso': 'FR',
		'NumberOfSessions': 4
	},
	{
		'lat': 47.6242424552795,
		'lon': 6.480646898908234,
		'name': 'Haute-Saône',
		'iso': 'FR',
		'NumberOfSessions': 22
	},
	{
		'lat': 49.494082782547416,
		'lon': 0.8448034911575273,
		'name': 'Seine-Maritime',
		'iso': 'FR',
		'NumberOfSessions': 30
	},
	{
		'lat': 46.47209278388106,
		'lon': 0.2736214631281701,
		'name': 'Vienne',
		'iso': 'FR',
		'NumberOfSessions': 7
	},
	{
		'lat': 47.546892125964874,
		'lon': 1.3216652066001418,
		'name': 'Loir-et-Cher',
		'iso': 'FR',
		'NumberOfSessions': 11
	},
	{
		'lat': 49.15107309017956,
		'lon': 6.297545813958301,
		'name': 'Moselle',
		'iso': 'FR',
		'NumberOfSessions': 52
	},
	{
		'lat': 43.391464163374,
		'lon': -0.7495192121105556,
		'name': 'Pyrénées-Atlantiques',
		'iso': 'FR',
		'NumberOfSessions': 23
	},
	{
		'lat': 45.67742457267652,
		'lon': 4.267982868536483,
		'name': 'Loire',
		'iso': 'FR',
		'NumberOfSessions': 24
	},
	{
		'lat': 47.4606071729101,
		'lon': -0.5612613735611051,
		'name': 'Maine-et-Loire',
		'iso': 'FR',
		'NumberOfSessions': 27
	},
	{
		'lat': 47.643,
		'lon': 6.855699999999999,
		'name': 'Territoire de Belfort',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 49.364457102819735,
		'lon': 2.4510283961593475,
		'name': 'Oise',
		'iso': 'FR',
		'NumberOfSessions': 24
	},
	{
		'lat': 49.64799966906847,
		'lon': 4.8132621204279475,
		'name': 'Ardennes',
		'iso': 'FR',
		'NumberOfSessions': 5
	},
	{
		'lat': 48.90032881699285,
		'lon': 2.4511565746551818,
		'name': 'Seine-Saint-Denis',
		'iso': 'FR',
		'NumberOfSessions': 52
	},
	{
		'lat': 43.197942548589296,
		'lon': 0.12096343850542762,
		'name': 'Hautes-Pyrénées',
		'iso': 'FR',
		'NumberOfSessions': 8
	},
	{
		'lat': 48.3710905596568,
		'lon': 5.050847705869144,
		'name': 'Haute-Marne',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.46294635606091,
		'lon': 1.4695160927676147,
		'name': 'Eure-et-Loir',
		'iso': 'FR',
		'NumberOfSessions': 10
	},
	{
		'lat': 44.9192,
		'lon': 2.4429,
		'name': 'Cantal',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.75648931703504,
		'lon': 4.843918682140846,
		'name': 'Rhône',
		'iso': 'FR',
		'NumberOfSessions': 109
	},
	{
		'lat': 42.69427246205239,
		'lon': 2.890964099835739,
		'name': 'Pyrénées-Orientales',
		'iso': 'FR',
		'NumberOfSessions': 59
	},
	{
		'lat': 48.75340545973846,
		'lon': 2.707506630173365,
		'name': 'Seine-et-Marne',
		'iso': 'FR',
		'NumberOfSessions': 48
	},
	{
		'lat': 49.24871879473928,
		'lon': -1.2271112779508957,
		'name': 'Manche',
		'iso': 'FR',
		'NumberOfSessions': 4
	},
	{
		'lat': 48.15879863317307,
		'lon': -1.700932036600919,
		'name': 'Ille-et-Vilaine',
		'iso': 'FR',
		'NumberOfSessions': 53
	},
	{
		'lat': 48.62034476335469,
		'lon': 7.64929499485644,
		'name': 'Bas-Rhin',
		'iso': 'FR',
		'NumberOfSessions': 51
	},
	{
		'lat': 43.57250935903542,
		'lon': 3.7314776667114433,
		'name': 'Hérault',
		'iso': 'FR',
		'NumberOfSessions': 53
	},
	{
		'lat': 45.322730728488466,
		'lon': 5.501264877935904,
		'name': 'Isère',
		'iso': 'FR',
		'NumberOfSessions': 41
	},
	{
		'lat': 45.75953433043061,
		'lon': 3.1444271706789864,
		'name': 'Puy-de-Dôme',
		'iso': 'FR',
		'NumberOfSessions': 40
	},
	{
		'lat': 43.596680479777234,
		'lon': 1.3904674101093695,
		'name': 'Upper Garonne',
		'iso': 'FR',
		'NumberOfSessions': 81
	},
	{
		'lat': 49.012477251997105,
		'lon': 2.2144113105021264,
		'name': 'Val d\'Oise',
		'iso': 'FR',
		'NumberOfSessions': 82
	},
	{
		'lat': 49.19376467488922,
		'lon': -0.31967088003561994,
		'name': 'Calvados',
		'iso': 'FR',
		'NumberOfSessions': 38
	},
	{
		'lat': 47.10104972861613,
		'lon': 3.241307538528017,
		'name': 'Nièvre',
		'iso': 'FR',
		'NumberOfSessions': 6
	},
	{
		'lat': 43.40349476816724,
		'lon': 5.2983722403151,
		'name': 'Bouches-du-Rhône',
		'iso': 'FR',
		'NumberOfSessions': 51
	},
	{
		'lat': 48.87002256680999,
		'lon': 2.268834694846946,
		'name': 'Hauts-de-Seine',
		'iso': 'FR',
		'NumberOfSessions': 82
	},
	{
		'lat': 41.9199,
		'lon': 8.7424,
		'name': 'South Corsica',
		'iso': 'FR',
		'NumberOfSessions': 5
	},
	{
		'lat': 48.43964545399349,
		'lon': -4.132589931820406,
		'name': 'Finistère',
		'iso': 'FR',
		'NumberOfSessions': 36
	},
	{
		'lat': 46.81519999999999,
		'lon': 1.6943,
		'name': 'Indre',
		'iso': 'FR',
		'NumberOfSessions': 6
	},
	{
		'lat': 49.12940873838364,
		'lon': 4.127071058557213,
		'name': 'Marne',
		'iso': 'FR',
		'NumberOfSessions': 14
	},
	{
		'lat': 48.73348701086392,
		'lon': -0.005795614373591444,
		'name': 'Orne',
		'iso': 'FR',
		'NumberOfSessions': 4
	},
	{
		'lat': 43.799955510900325,
		'lon': -0.8555519174888025,
		'name': 'Landes',
		'iso': 'FR',
		'NumberOfSessions': 14
	},
	{
		'lat': 48.21389906359695,
		'lon': 6.540476908616139,
		'name': 'Vosges',
		'iso': 'FR',
		'NumberOfSessions': 9
	},
	{
		'lat': 47.23000955301374,
		'lon': 6.235636840969198,
		'name': 'Doubs',
		'iso': 'FR',
		'NumberOfSessions': 31
	},
	{
		'lat': 47.91285336236676,
		'lon': 2.021650448712372,
		'name': 'Loiret',
		'iso': 'FR',
		'NumberOfSessions': 26
	},
	{
		'lat': 46.70905823166168,
		'lon': -1.6169112985874097,
		'name': 'Vendée',
		'iso': 'FR',
		'NumberOfSessions': 12
	},
	{
		'lat': 43.97770054868499,
		'lon': 4.991015091304049,
		'name': 'Vaucluse',
		'iso': 'FR',
		'NumberOfSessions': 11
	},
	{
		'lat': 48.67186402984038,
		'lon': 6.2249731496530565,
		'name': 'Meurthe et Moselle',
		'iso': 'FR',
		'NumberOfSessions': 23
	},
	{
		'lat': 44.88823525310873,
		'lon': 4.59696471855954,
		'name': 'Ardèche',
		'iso': 'FR',
		'NumberOfSessions': 12
	},
	{
		'lat': 46.11347448434291,
		'lon': 6.410596755259531,
		'name': 'Upper Savoy',
		'iso': 'FR',
		'NumberOfSessions': 36
	},
	{
		'lat': 49.226489369985075,
		'lon': 1.1637803466067504,
		'name': 'Eure',
		'iso': 'FR',
		'NumberOfSessions': 31
	},
	{
		'lat': 46.19962528141687,
		'lon': 5.70936275730179,
		'name': 'Ain',
		'iso': 'FR',
		'NumberOfSessions': 21
	},
	{
		'lat': 47.35544264106593,
		'lon': 4.9184325677630945,
		'name': 'Cote d\'Or',
		'iso': 'FR',
		'NumberOfSessions': 13
	},
	{
		'lat': 44.5489,
		'lon': 6.282,
		'name': 'Hautes-Alpes',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.66091587434121,
		'lon': 7.163652808008755,
		'name': 'Alpes-Maritimes',
		'iso': 'FR',
		'NumberOfSessions': 35
	},
	{
		'lat': 48.86110000000003,
		'lon': 2.3269000000000064,
		'name': 'Île-de-France',
		'iso': 'FR',
		'NumberOfSessions': 88
	},
	{
		'lat': 44.385270709504326,
		'lon': 2.2295329846703096,
		'name': 'Aveyron',
		'iso': 'FR',
		'NumberOfSessions': 5
	},
	{
		'lat': 47.21983253598131,
		'lon': -1.5874516780184404,
		'name': 'Loire-Atlantique',
		'iso': 'FR',
		'NumberOfSessions': 105
	},
	{
		'lat': 46.72498366166289,
		'lon': 4.7499484720409395,
		'name': 'Saône-et-Loire',
		'iso': 'FR',
		'NumberOfSessions': 24
	},
	{
		'lat': 45.28298493633912,
		'lon': 1.514206858522451,
		'name': 'Corrèze',
		'iso': 'FR',
		'NumberOfSessions': 7
	},
	{
		'lat': 46.18707531756262,
		'lon': 3.2104229357762097,
		'name': 'Allier',
		'iso': 'FR',
		'NumberOfSessions': 7
	},
	{
		'lat': 47.36678931169224,
		'lon': 0.669894798815532,
		'name': 'Indre-et-Loire',
		'iso': 'FR',
		'NumberOfSessions': 23
	},
	{
		'lat': 51.54213288386925,
		'lon': -0.11100919283912557,
		'name': 'Islington',
		'iso': 'GB',
		'NumberOfSessions': 47
	},
	{
		'lat': 52.64685668507746,
		'lon': -1.098873664232128,
		'name': 'Leicester',
		'iso': 'GB',
		'NumberOfSessions': 179
	},
	{
		'lat': 53.40866007016164,
		'lon': -2.589122115913638,
		'name': 'Warrington',
		'iso': 'GB',
		'NumberOfSessions': 10
	},
	{
		'lat': 54.9705009092563,
		'lon': -1.4101957114908317,
		'name': 'South Tyneside',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 50.81743024320716,
		'lon': -1.0784691288408752,
		'name': 'Portsmouth',
		'iso': 'GB',
		'NumberOfSessions': 12
	},
	{
		'lat': 51.53062520959372,
		'lon': 0.027208438081276452,
		'name': 'Newham',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 55.74226643077297,
		'lon': -3.987456049521366,
		'name': 'South Lanarkshire',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 52.47698795088311,
		'lon': -2.089113084785416,
		'name': 'Dudley',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 51.9634,
		'lon': -5.1263,
		'name': 'Pembrokeshire',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.809,
		'lon': -3.2075,
		'name': 'Midlothian',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.95177663975048,
		'lon': -1.172839508919386,
		'name': 'Nottingham',
		'iso': 'GB',
		'NumberOfSessions': 41
	},
	{
		'lat': 53.00714658669597,
		'lon': -2.985101819704952,
		'name': 'Wrexham',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 51.42144669587793,
		'lon': -3.3612740238786802,
		'name': 'Vale of Glamorgan',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 56.47166081423731,
		'lon': -3.7797941309773107,
		'name': 'Perth and Kinross',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.20356666636675,
		'lon': -3.165065850250484,
		'name': 'Flintshire',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 55.54095317504578,
		'lon': -4.371531838441938,
		'name': 'East Ayrshire',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.5232379606436,
		'lon': -2.6063096023547283,
		'name': 'Wigan',
		'iso': 'GB',
		'NumberOfSessions': 20
	},
	{
		'lat': 51.50779260640383,
		'lon': -0.11215216486133292,
		'name': 'England',
		'iso': 'GB',
		'NumberOfSessions': 100
	},
	{
		'lat': 51.55288480373172,
		'lon': -0.2553086700796278,
		'name': 'Brent',
		'iso': 'GB',
		'NumberOfSessions': 19
	},
	{
		'lat': 57.143964237570145,
		'lon': -2.1108223612388755,
		'name': 'Aberdeen City',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.592879191089104,
		'lon': -0.1158682170589923,
		'name': 'Haringey',
		'iso': 'GB',
		'NumberOfSessions': 12
	},
	{
		'lat': 55.943116280238065,
		'lon': -3.205660736364582,
		'name': 'Edinburgh',
		'iso': 'GB',
		'NumberOfSessions': 23
	},
	{
		'lat': 51.64866390850472,
		'lon': -0.07834664235015984,
		'name': 'Enfield',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 51.65248185286478,
		'lon': -3.2331089954840277,
		'name': 'Caerphilly County Borough',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 58.9705,
		'lon': -2.9655,
		'name': 'Orkney Islands',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.759898224259985,
		'lon': -2.8202906281313216,
		'name': 'Shropshire',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.315959950246054,
		'lon': -2.0177803414324527,
		'name': 'Wiltshire',
		'iso': 'GB',
		'NumberOfSessions': 19
	},
	{
		'lat': 53.12426705060867,
		'lon': -0.3657679423382455,
		'name': 'Lincolnshire',
		'iso': 'GB',
		'NumberOfSessions': 33
	},
	{
		'lat': 52.57878743037606,
		'lon': -0.25681836070554853,
		'name': 'Peterborough',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 53.85095081503418,
		'lon': -2.6110253182007184,
		'name': 'Lancashire',
		'iso': 'GB',
		'NumberOfSessions': 34
	},
	{
		'lat': 53.38309357262486,
		'lon': -1.4587456424782743,
		'name': 'Sheffield',
		'iso': 'GB',
		'NumberOfSessions': 42
	},
	{
		'lat': 51.52206808527533,
		'lon': -0.2999729351813608,
		'name': 'Ealing',
		'iso': 'GB',
		'NumberOfSessions': 20
	},
	{
		'lat': 51.22164419957808,
		'lon': 0.6557606565192858,
		'name': 'Kent',
		'iso': 'GB',
		'NumberOfSessions': 52
	},
	{
		'lat': 53.48603210487215,
		'lon': -2.238544028509757,
		'name': 'Manchester',
		'iso': 'GB',
		'NumberOfSessions': 106
	},
	{
		'lat': 51.538733740559856,
		'lon': 0.7111743398170665,
		'name': 'Southend-on-Sea',
		'iso': 'GB',
		'NumberOfSessions': 15
	},
	{
		'lat': 53.60284451995357,
		'lon': -0.5643211495938238,
		'name': 'North Lincolnshire',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 57.22439078737604,
		'lon': -2.4117729532566927,
		'name': 'Aberdeenshire',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 54.9912,
		'lon': -1.5336,
		'name': 'North Tyneside',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.76377449119914,
		'lon': -0.36096486546922363,
		'name': 'Kingston upon Hull',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 55.091800882906284,
		'lon': -3.4313201663207455,
		'name': 'Dumfries and Galloway',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.92283446999948,
		'lon': -0.4868464369174484,
		'name': 'Luton',
		'iso': 'GB',
		'NumberOfSessions': 28
	},
	{
		'lat': 51.70271101749839,
		'lon': -1.2560829889315606,
		'name': 'Oxfordshire',
		'iso': 'GB',
		'NumberOfSessions': 25
	},
	{
		'lat': 50.735603980624006,
		'lon': -2.4321716635044677,
		'name': 'Dorset',
		'iso': 'GB',
		'NumberOfSessions': 11
	},
	{
		'lat': 54.28391644833325,
		'lon': -6.186494272324145,
		'name': 'Newry Mourne and Down',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 54.88294034847637,
		'lon': -1.4468955519866162,
		'name': 'Sunderland',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 51.46326713978469,
		'lon': -0.023109778386906137,
		'name': 'Lewisham',
		'iso': 'GB',
		'NumberOfSessions': 23
	},
	{
		'lat': 52.70157147993215,
		'lon': -2.428180155003778,
		'name': 'Telford and Wrekin',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 51.364912741181556,
		'lon': -0.06377193447770935,
		'name': 'Croydon',
		'iso': 'GB',
		'NumberOfSessions': 22
	},
	{
		'lat': 53.83234169518644,
		'lon': -1.8382955838819248,
		'name': 'Bradford',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 53.78251094458315,
		'lon': -2.539221411449162,
		'name': 'Blackburn with Darwen',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 54.98935249331569,
		'lon': -1.640570737296789,
		'name': 'Newcastle upon Tyne',
		'iso': 'GB',
		'NumberOfSessions': 25
	},
	{
		'lat': 51.706750296206344,
		'lon': -0.7745662073519064,
		'name': 'Buckinghamshire',
		'iso': 'GB',
		'NumberOfSessions': 22
	},
	{
		'lat': 51.51130469468152,
		'lon': -0.03252655069705667,
		'name': 'Tower Hamlets',
		'iso': 'GB',
		'NumberOfSessions': 60
	},
	{
		'lat': 54.68878462003791,
		'lon': -1.240014800907877,
		'name': 'Hartlepool',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 51.43233284137251,
		'lon': -1.0173159630109234,
		'name': 'Reading',
		'iso': 'GB',
		'NumberOfSessions': 22
	},
	{
		'lat': 54.0771,
		'lon': -0.2306,
		'name': 'East Riding of Yorkshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.449912886996806,
		'lon': -0.18040052162983553,
		'name': 'Wandsworth',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 57.65183564050779,
		'lon': -3.415407818452831,
		'name': 'Moray',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.53940137523434,
		'lon': -2.1081038719786145,
		'name': 'Oldham',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 53.226578425046,
		'lon': -3.407691394298824,
		'name': 'Denbighshire',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.58242341636379,
		'lon': -0.3764745710702738,
		'name': 'Harrow',
		'iso': 'GB',
		'NumberOfSessions': 15
	},
	{
		'lat': 52.151240843847994,
		'lon': 1.0319148172049641,
		'name': 'Suffolk',
		'iso': 'GB',
		'NumberOfSessions': 24
	},
	{
		'lat': 56.50462826868058,
		'lon': -3.014592397901483,
		'name': 'Dundee City',
		'iso': 'GB',
		'NumberOfSessions': 11
	},
	{
		'lat': 55.958794845661295,
		'lon': -4.525793079457798,
		'name': 'West Dunbartonshire',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 52.41382005005337,
		'lon': -1.508178511787144,
		'name': 'Coventry',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 51.658798741087274,
		'lon': -3.4528063537079605,
		'name': 'Rhondda Cynon Taf',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.961529401633456,
		'lon': -1.3967498233426283,
		'name': 'North Yorkshire',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 51.51138000740215,
		'lon': -0.09484010895036864,
		'name': 'City of London',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 55.84378882896044,
		'lon': -4.4435077797656195,
		'name': 'Renfrewshire',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 51.804298490284104,
		'lon': -0.23903072943354223,
		'name': 'Hertfordshire',
		'iso': 'GB',
		'NumberOfSessions': 64
	},
	{
		'lat': 52.59105115621546,
		'lon': -2.1421431709556025,
		'name': 'Wolverhampton',
		'iso': 'GB',
		'NumberOfSessions': 16
	},
	{
		'lat': 54.450834249803655,
		'lon': -6.308031777851684,
		'name': 'Armagh City Banbridge and Craigavon',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 52.04608518404615,
		'lon': -2.662435144683412,
		'name': 'Herefordshire',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 54.714495219769375,
		'lon': -1.6636475740926977,
		'name': 'Durham',
		'iso': 'GB',
		'NumberOfSessions': 23
	},
	{
		'lat': 52.26666932342189,
		'lon': -0.8120061479293226,
		'name': 'Northamptonshire',
		'iso': 'GB',
		'NumberOfSessions': 57
	},
	{
		'lat': 50.39429036981794,
		'lon': -4.1225978148589215,
		'name': 'Plymouth',
		'iso': 'GB',
		'NumberOfSessions': 15
	},
	{
		'lat': 51.52907404300422,
		'lon': -0.4488938234075329,
		'name': 'Hillingdon',
		'iso': 'GB',
		'NumberOfSessions': 10
	},
	{
		'lat': 55.64405347742018,
		'lon': -4.721563270546512,
		'name': 'North Ayrshire',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 55.883082350650135,
		'lon': -3.5182457243351686,
		'name': 'West Lothian',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 54.54466669395813,
		'lon': -5.9742604094836445,
		'name': 'Belfast',
		'iso': 'GB',
		'NumberOfSessions': 27
	},
	{
		'lat': 55.1181,
		'lon': -6.334800000000001,
		'name': 'Causeway Coast and Glens',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.72720124812629,
		'lon': -2.693818663187092,
		'name': 'Monmouthshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.706787341605775,
		'lon': -3.3395190372851062,
		'name': 'Merthyr Tydfil',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 52.77285565698058,
		'lon': -1.8180328200090934,
		'name': 'Staffordshire',
		'iso': 'GB',
		'NumberOfSessions': 19
	},
	{
		'lat': 53.588162529998705,
		'lon': -2.1767761314580563,
		'name': 'Rochdale',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 55.82060449248899,
		'lon': -3.965781661845273,
		'name': 'North Lanarkshire',
		'iso': 'GB',
		'NumberOfSessions': 16
	},
	{
		'lat': 53.68582318792333,
		'lon': -1.418474264643476,
		'name': 'Wakefield',
		'iso': 'GB',
		'NumberOfSessions': 10
	},
	{
		'lat': 53.62463625751453,
		'lon': -3.0016158362314656,
		'name': 'Sefton',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 53.67238423915785,
		'lon': -1.724088351285279,
		'name': 'Kirklees',
		'iso': 'GB',
		'NumberOfSessions': 11
	},
	{
		'lat': 51.83625885615724,
		'lon': -4.188335993912887,
		'name': 'Carmarthenshire',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.843039914938714,
		'lon': -2.122170730821266,
		'name': 'Gloucestershire',
		'iso': 'GB',
		'NumberOfSessions': 25
	},
	{
		'lat': 51.329553405747475,
		'lon': -2.406945662289723,
		'name': 'Bath and North East Somerset',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 51.503321598252455,
		'lon': -3.17578028182112,
		'name': 'Cardiff',
		'iso': 'GB',
		'NumberOfSessions': 64
	},
	{
		'lat': 55.1205898647679,
		'lon': -1.6788909156448706,
		'name': 'Northumberland',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 53.94193337097206,
		'lon': -1.0192505012782638,
		'name': 'York',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 53.41102607884888,
		'lon': -2.938054968947336,
		'name': 'Liverpool',
		'iso': 'GB',
		'NumberOfSessions': 28
	},
	{
		'lat': 51.40110118936912,
		'lon': -0.2934213339844313,
		'name': 'Kingston upon Thames',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 51.53751963304785,
		'lon': -0.6698546153199753,
		'name': 'Slough',
		'iso': 'GB',
		'NumberOfSessions': 26
	},
	{
		'lat': 50.67437954969012,
		'lon': -3.5999545792221483,
		'name': 'Devon',
		'iso': 'GB',
		'NumberOfSessions': 19
	},
	{
		'lat': 51.661244027447424,
		'lon': -3.929339598624733,
		'name': 'City and County of Swansea',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 56.748099999999994,
		'lon': -2.4272999999999985,
		'name': 'Angus',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 51.36602031137684,
		'lon': -0.8563770012056824,
		'name': 'Wokingham',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 50.95300346193167,
		'lon': -0.3369966866185249,
		'name': 'West Sussex',
		'iso': 'GB',
		'NumberOfSessions': 32
	},
	{
		'lat': 52.32967570631776,
		'lon': 0.09888278542039436,
		'name': 'Cambridgeshire',
		'iso': 'GB',
		'NumberOfSessions': 32
	},
	{
		'lat': 51.13285210983632,
		'lon': -1.131560103198161,
		'name': 'Hampshire',
		'iso': 'GB',
		'NumberOfSessions': 45
	},
	{
		'lat': 51.49912361590612,
		'lon': 0.3674307290072453,
		'name': 'Thurrock',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 53.48952268757039,
		'lon': -2.2836915414830274,
		'name': 'Salford',
		'iso': 'GB',
		'NumberOfSessions': 15
	},
	{
		'lat': 52.688434269178046,
		'lon': -1.2018800084049444,
		'name': 'Leicestershire',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 51.398525022241635,
		'lon': 0.044424290053531185,
		'name': 'Bromley',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 55.03490000000001,
		'lon': -7.2718,
		'name': 'Derry City and Strabane',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.978595846886336,
		'lon': -0.4927542641827903,
		'name': 'Central Bedfordshire',
		'iso': 'GB',
		'NumberOfSessions': 16
	},
	{
		'lat': 50.71134573223227,
		'lon': -1.1607113073855329,
		'name': 'Isle of Wight',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 54.684672846659566,
		'lon': -6.0350747793080375,
		'name': 'Antrim and Newtownabbey',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 53.38631561148299,
		'lon': -2.1733250347242676,
		'name': 'Stockport',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 51.32350674398407,
		'lon': -2.9390993925518543,
		'name': 'North Somerset',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.465350926216594,
		'lon': -4.444684243714657,
		'name': 'Cornwall',
		'iso': 'GB',
		'NumberOfSessions': 49
	},
	{
		'lat': 55.856763531620025,
		'lon': -4.268956889206264,
		'name': 'Glasgow City',
		'iso': 'GB',
		'NumberOfSessions': 79
	},
	{
		'lat': 53.25462971981168,
		'lon': -2.790108559874419,
		'name': 'Cheshire',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 51.475205977602,
		'lon': 0.041777889975918796,
		'name': 'Greenwich',
		'iso': 'GB',
		'NumberOfSessions': 14
	},
	{
		'lat': 51.67884069302734,
		'lon': -3.0251303714393956,
		'name': 'Torfaen County Borough',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.2069,
		'lon': -4.1184,
		'name': 'Gwynedd',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.84387234565151,
		'lon': 0.3426879748766622,
		'name': 'East Sussex',
		'iso': 'GB',
		'NumberOfSessions': 20
	},
	{
		'lat': 54.56422530942975,
		'lon': -1.3262113191040004,
		'name': 'Stockton-on-Tees',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 53.53472867544864,
		'lon': -1.4575621099713718,
		'name': 'Barnsley',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.731608432983734,
		'lon': 0.5011073604433671,
		'name': 'Essex',
		'iso': 'GB',
		'NumberOfSessions': 60
	},
	{
		'lat': 54.770426808219085,
		'lon': -3.0766114995625453,
		'name': 'Cumbria',
		'iso': 'GB',
		'NumberOfSessions': 23
	},
	{
		'lat': 52.594860216158786,
		'lon': -1.975715451513192,
		'name': 'Walsall',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 51.56881581235861,
		'lon': -1.7768311713929028,
		'name': 'Swindon',
		'iso': 'GB',
		'NumberOfSessions': 20
	},
	{
		'lat': 53.556029120359646,
		'lon': -0.057831158553178025,
		'name': 'North East Lincolnshire',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 53.225,
		'lon': -4.2735,
		'name': 'Anglesey',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.46937813319774,
		'lon': 0.15232860062581785,
		'name': 'Bexley',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 52.627346146324435,
		'lon': 1.1692928635315527,
		'name': 'Norfolk',
		'iso': 'GB',
		'NumberOfSessions': 31
	},
	{
		'lat': 53.148936583513226,
		'lon': -2.3372233015777435,
		'name': 'Cheshire East',
		'iso': 'GB',
		'NumberOfSessions': 10
	},
	{
		'lat': 54.94890508794419,
		'lon': -1.6027404050352156,
		'name': 'Gateshead',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 54.53870276498703,
		'lon': -1.5605646944043303,
		'name': 'Darlington',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 52.72039999999999,
		'lon': -0.6845999999999998,
		'name': 'Rutland',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.7106,
		'lon': -3.6988000000000003,
		'name': 'Neath Port Talbot',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.4036,
		'lon': -0.7618,
		'name': 'Bracknell Forest',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.45900369251754,
		'lon': -2.5631576862594194,
		'name': 'Bristol',
		'iso': 'GB',
		'NumberOfSessions': 88
	},
	{
		'lat': 53.048438562809615,
		'lon': -1.5035819188673727,
		'name': 'Derbyshire',
		'iso': 'GB',
		'NumberOfSessions': 39
	},
	{
		'lat': 51.60289895930846,
		'lon': -0.2135142351290916,
		'name': 'Barnet',
		'iso': 'GB',
		'NumberOfSessions': 14
	},
	{
		'lat': 51.558986550203464,
		'lon': 0.06964862341256857,
		'name': 'Redbridge',
		'iso': 'GB',
		'NumberOfSessions': 56
	},
	{
		'lat': 54.57890365093002,
		'lon': -1.0453423510092712,
		'name': 'Redcar and Cleveland',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.50062633218145,
		'lon': -0.1930082899278798,
		'name': 'Royal Kensington and Chelsea',
		'iso': 'GB',
		'NumberOfSessions': 23
	},
	{
		'lat': 52.03531244620399,
		'lon': -0.7419921605125859,
		'name': 'Milton Keynes',
		'iso': 'GB',
		'NumberOfSessions': 30
	},
	{
		'lat': 51.36261004311785,
		'lon': -0.17237586986883013,
		'name': 'Sutton',
		'iso': 'GB',
		'NumberOfSessions': 23
	},
	{
		'lat': 50.84213243830171,
		'lon': -0.16697920823481646,
		'name': 'Brighton and Hove',
		'iso': 'GB',
		'NumberOfSessions': 14
	},
	{
		'lat': 53.57493586488329,
		'lon': -2.3939865187004736,
		'name': 'Bolton',
		'iso': 'GB',
		'NumberOfSessions': 12
	},
	{
		'lat': 53.385939020959775,
		'lon': -3.0348544233304025,
		'name': 'Wirral',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 51.51387830498067,
		'lon': -3.6186938757465077,
		'name': 'County Borough of Bridgend',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.018345836448496,
		'lon': -2.7466551210454555,
		'name': 'Somerset',
		'iso': 'GB',
		'NumberOfSessions': 28
	},
	{
		'lat': 51.474648077932855,
		'lon': -0.3775496129884493,
		'name': 'Hounslow',
		'iso': 'GB',
		'NumberOfSessions': 17
	},
	{
		'lat': 51.60096816867769,
		'lon': 0.18323651480193015,
		'name': 'Havering',
		'iso': 'GB',
		'NumberOfSessions': 14
	},
	{
		'lat': 51.780300000000004,
		'lon': -3.204300000000001,
		'name': 'Blaenau Gwent',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.25095296049107,
		'lon': -4.357751853438202,
		'name': 'Stirling',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.189654189118805,
		'lon': -1.1545638667158602,
		'name': 'Nottinghamshire',
		'iso': 'GB',
		'NumberOfSessions': 31
	},
	{
		'lat': 51.527121902909194,
		'lon': -0.15630406670654642,
		'name': 'Camden',
		'iso': 'GB',
		'NumberOfSessions': 74
	},
	{
		'lat': 50.456516601182024,
		'lon': -3.549353527090327,
		'name': 'Torbay',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 52.1012590323018,
		'lon': -0.4875612466340593,
		'name': 'Bedford',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 54.60376495276189,
		'lon': -6.882783123926266,
		'name': 'Mid Ulster',
		'iso': 'GB',
		'NumberOfSessions': 15
	},
	{
		'lat': 53.78672364544904,
		'lon': -1.5566206921612924,
		'name': 'Leeds',
		'iso': 'GB',
		'NumberOfSessions': 50
	},
	{
		'lat': 51.49538839329868,
		'lon': -0.0798602755110039,
		'name': 'Southwark',
		'iso': 'GB',
		'NumberOfSessions': 73
	},
	{
		'lat': 51.484200372307924,
		'lon': -0.20735879374571503,
		'name': 'Hammersmith and Fulham',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.61870075081192,
		'lon': -3.0696611288748787,
		'name': 'Newport',
		'iso': 'GB',
		'NumberOfSessions': 16
	},
	{
		'lat': 51.36542827574921,
		'lon': 0.5326237330855733,
		'name': 'Medway',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 53.41954290920135,
		'lon': -2.7813412794598764,
		'name': 'Knowsley',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.57356116014537,
		'lon': -2.47340676093865,
		'name': 'South Gloucestershire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.407766896917316,
		'lon': -0.16999767268836738,
		'name': 'Merton',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 57.5908007461516,
		'lon': -4.207381977112387,
		'name': 'Highland',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 53.438178050060436,
		'lon': -2.7133758446428917,
		'name': 'St. Helens',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 52.28208907875062,
		'lon': -1.9885021140112733,
		'name': 'Worcestershire',
		'iso': 'GB',
		'NumberOfSessions': 29
	},
	{
		'lat': 53.02616684758045,
		'lon': -2.175582297125588,
		'name': 'Stoke-on-Trent',
		'iso': 'GB',
		'NumberOfSessions': 27
	},
	{
		'lat': 52.412200949069764,
		'lon': -1.7991007822374145,
		'name': 'Solihull',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.91178959471868,
		'lon': -1.3970488558852714,
		'name': 'Southampton',
		'iso': 'GB',
		'NumberOfSessions': 45
	},
	{
		'lat': 51.47861563984475,
		'lon': -0.12127537398600088,
		'name': 'Lambeth',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 51.41162409011156,
		'lon': -1.3157927073115951,
		'name': 'West Berkshire',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.446402401538414,
		'lon': -0.3154050285020535,
		'name': 'Richmond upon Thames',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 56.109450814208984,
		'lon': -3.3215162351019725,
		'name': 'Fife',
		'iso': 'GB',
		'NumberOfSessions': 32
	},
	{
		'lat': 55.4754,
		'lon': -4.6062,
		'name': 'South Ayrshire',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 53.3006,
		'lon': -2.6604,
		'name': 'Halton',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.54573190108688,
		'lon': -0.059052540753732424,
		'name': 'Hackney',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 52.90652313781747,
		'lon': -1.4757165298837691,
		'name': 'Derby',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 52.50956782657,
		'lon': -2.0005183960912083,
		'name': 'Sandwell',
		'iso': 'GB',
		'NumberOfSessions': 23
	},
	{
		'lat': 52.30779627177206,
		'lon': -1.5137065247754866,
		'name': 'Warwickshire',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 51.57954812798609,
		'lon': -0.019644392080076435,
		'name': 'Waltham Forest',
		'iso': 'GB',
		'NumberOfSessions': 19
	},
	{
		'lat': 51.48214074873219,
		'lon': -0.6798567820034126,
		'name': 'Windsor and Maidenhead',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 56.20147725809465,
		'lon': -5.243819734146144,
		'name': 'Argyll and Bute',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 56.1185,
		'lon': -3.7941,
		'name': 'Clackmannanshire',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 53.479102847554664,
		'lon': -2.0924226279948788,
		'name': 'Tameside',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.428629367238955,
		'lon': -1.309002822922596,
		'name': 'Rotherham',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 51.51784635533079,
		'lon': -0.15942453145013147,
		'name': 'City of Westminster',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 53.518630329628536,
		'lon': -1.0973156297410067,
		'name': 'Doncaster',
		'iso': 'GB',
		'NumberOfSessions': 14
	},
	{
		'lat': 54.559206569283205,
		'lon': -1.2078754724645988,
		'name': 'Middlesbrough',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.289,
		'lon': -3.7684000000000006,
		'name': 'Conwy',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.49771068796677,
		'lon': -1.880332569289632,
		'name': 'Birmingham',
		'iso': 'GB',
		'NumberOfSessions': 79
	},
	{
		'lat': 55.919,
		'lon': -2.7551999999999994,
		'name': 'East Lothian',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 54.847608016773066,
		'lon': -6.1058204695213725,
		'name': 'Mid and East Antrim',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.53472309467846,
		'lon': 0.0934642418888441,
		'name': 'Barking and Dagenham',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 53.81791874709358,
		'lon': -3.014930215410307,
		'name': 'Blackpool',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 53.73630000000001,
		'lon': -2.003899999999999,
		'name': 'Calderdale',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.304489975993825,
		'lon': -0.5042613359648693,
		'name': 'Surrey',
		'iso': 'GB',
		'NumberOfSessions': 31
	},
	{
		'lat': 50.73834989043233,
		'lon': -1.9106504318418247,
		'name': 'Bournemouth, Christchurch and Poole Council',
		'iso': 'GB',
		'NumberOfSessions': 24
	},
	{
		'lat': 53.57435092563529,
		'lon': -2.3162080523027404,
		'name': 'Borough of Bury',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 40.84089999999999,
		'lon': 25.873699999999992,
		'name': 'Evros',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.0799,
		'lon': 22.4282,
		'name': 'Laconia',
		'iso': 'GR',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.2475,
		'lon': 21.7311,
		'name': 'Achaea',
		'iso': 'GR',
		'NumberOfSessions': 3
	},
	{
		'lat': 37.7557,
		'lon': 26.9724,
		'name': 'Samos',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.51740000000001,
		'lon': 24.0169,
		'name': 'Chania',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.9007,
		'lon': 22.426000000000002,
		'name': 'Phthiotis',
		'iso': 'GR',
		'NumberOfSessions': 2
	},
	{
		'lat': 37.98093839871411,
		'lon': 23.73233976829108,
		'name': 'Attica',
		'iso': 'GR',
		'NumberOfSessions': 107
	},
	{
		'lat': 39.667200000000015,
		'lon': 20.859900000000003,
		'name': 'Ioannina',
		'iso': 'GR',
		'NumberOfSessions': 2
	},
	{
		'lat': 37.79189999999999,
		'lon': 20.900399999999994,
		'name': 'Nomós Zakýnthou',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.03880000000001,
		'lon': 22.1078,
		'name': 'Messenia',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.2969,
		'lon': 21.785500000000003,
		'name': 'Kozani',
		'iso': 'GR',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.369,
		'lon': 26.139899999999997,
		'name': 'Chios',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.3679,
		'lon': 24.467000000000006,
		'name': 'Rethymno',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.923,
		'lon': 21.798999999999996,
		'name': 'Grevena',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.3272,
		'lon': 25.1371,
		'name': 'Heraklion Regional Unit',
		'iso': 'GR',
		'NumberOfSessions': 2
	},
	{
		'lat': 40.6439,
		'lon': 22.93580000000001,
		'name': 'Thessaloniki',
		'iso': 'GR',
		'NumberOfSessions': 27
	},
	{
		'lat': 45.757440381837874,
		'lon': 15.889736638171497,
		'name': 'Zagreb County',
		'iso': 'HR',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.8194,
		'lon': 13.9312,
		'name': 'Istria County',
		'iso': 'HR',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.29275975208212,
		'lon': 16.29098872923146,
		'name': 'Varaždin County',
		'iso': 'HR',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.1622,
		'lon': 16.830699999999997,
		'name': 'Koprivnica-Križevci County',
		'iso': 'HR',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.3862,
		'lon': 16.4308,
		'name': 'Međimurje County',
		'iso': 'HR',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.4675,
		'lon': 16.3868,
		'name': 'Sisak-Moslavina County',
		'iso': 'HR',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.622410088394375,
		'lon': 18.66109141886735,
		'name': 'County of Osijek-Baranja',
		'iso': 'HR',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.82876080677008,
		'lon': 15.980679794617236,
		'name': 'City of Zagreb',
		'iso': 'HR',
		'NumberOfSessions': 48
	},
	{
		'lat': 43.5098,
		'lon': 16.441099999999995,
		'name': 'Split-Dalmatia County',
		'iso': 'HR',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.707779192116334,
		'lon': 16.92827792283918,
		'name': 'Zala County',
		'iso': 'HU',
		'NumberOfSessions': 5
	},
	{
		'lat': 47.569087212517424,
		'lon': 21.6304150943794,
		'name': 'Hajdú-Bihar',
		'iso': 'HU',
		'NumberOfSessions': 10
	},
	{
		'lat': 47.07106140192764,
		'lon': 17.90306070669181,
		'name': 'Veszprém megye',
		'iso': 'HU',
		'NumberOfSessions': 5
	},
	{
		'lat': 46.58040978135987,
		'lon': 18.737476825721682,
		'name': 'Tolna County',
		'iso': 'HU',
		'NumberOfSessions': 5
	},
	{
		'lat': 46.715787390074546,
		'lon': 19.57866919922176,
		'name': 'Bács-Kiskun',
		'iso': 'HU',
		'NumberOfSessions': 6
	},
	{
		'lat': 46.55122951322579,
		'lon': 20.749837487992508,
		'name': 'Bekes County',
		'iso': 'HU',
		'NumberOfSessions': 3
	},
	{
		'lat': 47.563599999999994,
		'lon': 19.09470000000005,
		'name': 'Budapest',
		'iso': 'HU',
		'NumberOfSessions': 107
	},
	{
		'lat': 47.8008662312472,
		'lon': 20.145039379825274,
		'name': 'Heves County',
		'iso': 'HU',
		'NumberOfSessions': 4
	},
	{
		'lat': 48.101039404017314,
		'lon': 20.981524613868974,
		'name': 'Borsod-Abaúj-Zemplén',
		'iso': 'HU',
		'NumberOfSessions': 9
	},
	{
		'lat': 46.48819073130044,
		'lon': 20.16289021907804,
		'name': 'Csongrád megye',
		'iso': 'HU',
		'NumberOfSessions': 24
	},
	{
		'lat': 46.3682,
		'lon': 17.794499999999996,
		'name': 'Somogy County',
		'iso': 'HU',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.00016922193651,
		'lon': 22.029705767032702,
		'name': 'Szabolcs-Szatmár-Bereg',
		'iso': 'HU',
		'NumberOfSessions': 4
	},
	{
		'lat': 47.7223942878007,
		'lon': 17.350936500232077,
		'name': 'Győr-Moson-Sopron',
		'iso': 'HU',
		'NumberOfSessions': 5
	},
	{
		'lat': 47.242771792979866,
		'lon': 20.29959387293545,
		'name': 'Jász-Nagykun-Szolnok',
		'iso': 'HU',
		'NumberOfSessions': 8
	},
	{
		'lat': 47.22001404428139,
		'lon': 18.589389989068014,
		'name': 'Fejér',
		'iso': 'HU',
		'NumberOfSessions': 9
	},
	{
		'lat': 47.23797527825802,
		'lon': 16.669318189701954,
		'name': 'Vas County',
		'iso': 'HU',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.515215969168565,
		'lon': 19.147165998451104,
		'name': 'Pest County',
		'iso': 'HU',
		'NumberOfSessions': 17
	},
	{
		'lat': 46.072068744602625,
		'lon': 18.325027763202193,
		'name': 'Baranya',
		'iso': 'HU',
		'NumberOfSessions': 5
	},
	{
		'lat': -6.213868489614379,
		'lon': 106.65959561237585,
		'name': 'Banten',
		'iso': 'ID',
		'NumberOfSessions': 3
	},
	{
		'lat': -0.9497000000000001,
		'lon': 100.3505,
		'name': 'West Sumatra',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': -8.650699999999999,
		'lon': 115.2124,
		'name': 'Bali',
		'iso': 'ID',
		'NumberOfSessions': 6
	},
	{
		'lat': -2.2074000000000003,
		'lon': 113.9164,
		'name': 'Central Kalimantan',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': -3.321899999999999,
		'lon': 114.5871,
		'name': 'South Kalimantan',
		'iso': 'ID',
		'NumberOfSessions': 4
	},
	{
		'lat': -5.151999999999999,
		'lon': 119.437,
		'name': 'South Sulawesi',
		'iso': 'ID',
		'NumberOfSessions': 5
	},
	{
		'lat': 0.5395,
		'lon': 101.4457,
		'name': 'Riau',
		'iso': 'ID',
		'NumberOfSessions': 2
	},
	{
		'lat': 3.5847000000000007,
		'lon': 98.6629,
		'name': 'North Sumatra',
		'iso': 'ID',
		'NumberOfSessions': 4
	},
	{
		'lat': 5.5418,
		'lon': 95.34130000000002,
		'name': 'Aceh',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': -7.2484,
		'lon': 112.7419,
		'name': 'East Java',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': -2.9146,
		'lon': 104.7535,
		'name': 'South Sumatra',
		'iso': 'ID',
		'NumberOfSessions': 4
	},
	{
		'lat': 1.481,
		'lon': 124.85179999999998,
		'name': 'North Sulawesi',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': -5.214563502836859,
		'lon': 105.13070531662346,
		'name': 'Lampung',
		'iso': 'ID',
		'NumberOfSessions': 2
	},
	{
		'lat': -0.030999999999999986,
		'lon': 109.3257,
		'name': 'West Kalimantan',
		'iso': 'ID',
		'NumberOfSessions': 3
	},
	{
		'lat': -6.217061659742524,
		'lon': 106.84360763915862,
		'name': 'Jakarta',
		'iso': 'ID',
		'NumberOfSessions': 26
	},
	{
		'lat': -10.1676,
		'lon': 123.6107,
		'name': 'East Nusa Tenggara',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': -6.769338264991126,
		'lon': 107.41220320915704,
		'name': 'West Java',
		'iso': 'ID',
		'NumberOfSessions': 34
	},
	{
		'lat': -7.203641419086717,
		'lon': 110.08109283243967,
		'name': 'Central Java',
		'iso': 'ID',
		'NumberOfSessions': 7
	},
	{
		'lat': 53.858508249073985,
		'lon': -6.386397647016352,
		'name': 'Louth',
		'iso': 'IE',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.268900000000016,
		'lon': -9.053300000000004,
		'name': 'County Galway',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.89572989145673,
		'lon': -8.45780956339372,
		'name': 'County Cork',
		'iso': 'IE',
		'NumberOfSessions': 4
	},
	{
		'lat': 53.4219,
		'lon': -7.9384999999999994,
		'name': 'County Westmeath',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.42743231930981,
		'lon': -6.471126754207568,
		'name': 'Meath',
		'iso': 'IE',
		'NumberOfSessions': 20
	},
	{
		'lat': 54.1162,
		'lon': -9.1656,
		'name': 'County Mayo',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.6669,
		'lon': -8.627400000000002,
		'name': 'Limerick',
		'iso': 'IE',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.726600000000005,
		'lon': -7.796499999999999,
		'name': 'Co Longford',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.1032,
		'lon': -7.447600000000002,
		'name': 'Cavan',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.047599999999996,
		'lon': -7.299400000000003,
		'name': 'Laois',
		'iso': 'IE',
		'NumberOfSessions': 4
	},
	{
		'lat': 52.8501,
		'lon': -6.2123,
		'name': 'County Wicklow',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.33798454238767,
		'lon': -6.259229291031364,
		'name': 'Leinster',
		'iso': 'IE',
		'NumberOfSessions': 9757
	},
	{
		'lat': 9.974249669417294,
		'lon': 76.28174400531162,
		'name': 'Kerala',
		'iso': 'IN',
		'NumberOfSessions': 21
	},
	{
		'lat': 28.83353519529381,
		'lon': 76.9628365526379,
		'name': 'Haryana',
		'iso': 'IN',
		'NumberOfSessions': 6
	},
	{
		'lat': 27.19644461682357,
		'lon': 80.1207052953128,
		'name': 'Uttar Pradesh',
		'iso': 'IN',
		'NumberOfSessions': 22
	},
	{
		'lat': 21.2129,
		'lon': 81.4294,
		'name': 'Chhattisgarh',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 30.327500000000004,
		'lon': 78.03250000000001,
		'name': 'Uttarakhand',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 19.002891495090907,
		'lon': 73.47466621386728,
		'name': 'Maharashtra',
		'iso': 'IN',
		'NumberOfSessions': 54
	},
	{
		'lat': 17.372400000000003,
		'lon': 78.4378,
		'name': 'Telangana',
		'iso': 'IN',
		'NumberOfSessions': 23
	},
	{
		'lat': 31.103399999999997,
		'lon': 77.1659,
		'name': 'Himachal Pradesh',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 11.878500000000004,
		'lon': 79.81910000000002,
		'name': 'Union Territory of Puducherry',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 22.324589014193077,
		'lon': 72.31583797305939,
		'name': 'Gujarat',
		'iso': 'IN',
		'NumberOfSessions': 26
	},
	{
		'lat': 32.732400000000005,
		'lon': 74.8799,
		'name': 'Jammu and Kashmir',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 23.728500000000004,
		'lon': 92.71880000000002,
		'name': 'Mizoram',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 26.902089841669945,
		'lon': 75.52347538945159,
		'name': 'Rajasthan',
		'iso': 'IN',
		'NumberOfSessions': 14
	},
	{
		'lat': 26.120600000000003,
		'lon': 91.6523,
		'name': 'Assam',
		'iso': 'IN',
		'NumberOfSessions': 7
	},
	{
		'lat': 23.035061693240788,
		'lon': 76.76191211498495,
		'name': 'Madhya Pradesh',
		'iso': 'IN',
		'NumberOfSessions': 27
	},
	{
		'lat': 25.590800000000005,
		'lon': 85.1348,
		'name': 'Bihar',
		'iso': 'IN',
		'NumberOfSessions': 5
	},
	{
		'lat': 13.045235337168831,
		'lon': 77.40809443430695,
		'name': 'Karnataka',
		'iso': 'IN',
		'NumberOfSessions': 29
	},
	{
		'lat': 30.7339,
		'lon': 76.7889,
		'name': 'Chandigarh',
		'iso': 'IN',
		'NumberOfSessions': 3
	},
	{
		'lat': 24.8103,
		'lon': 93.939,
		'name': 'Manipur',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 16.993354539900846,
		'lon': 81.83322537815229,
		'name': 'Andhra Pradesh',
		'iso': 'IN',
		'NumberOfSessions': 10
	},
	{
		'lat': 28.65113169589502,
		'lon': 77.19190667572605,
		'name': 'National Capital Territory of Delhi',
		'iso': 'IN',
		'NumberOfSessions': 29
	},
	{
		'lat': 22.720235789103576,
		'lon': 88.3310387786304,
		'name': 'West Bengal',
		'iso': 'IN',
		'NumberOfSessions': 27
	},
	{
		'lat': 31.144435114906535,
		'lon': 75.88913770187071,
		'name': 'Punjab',
		'iso': 'IN',
		'NumberOfSessions': 7
	},
	{
		'lat': 20.27060000000001,
		'lon': 85.8334,
		'name': 'Odisha',
		'iso': 'IN',
		'NumberOfSessions': 3
	},
	{
		'lat': 23.01899623185545,
		'lon': 85.83572486077595,
		'name': 'Jharkhand',
		'iso': 'IN',
		'NumberOfSessions': 5
	},
	{
		'lat': 12.19856962282447,
		'lon': 79.22682011318975,
		'name': 'Tamil Nadu',
		'iso': 'IN',
		'NumberOfSessions': 14
	},
	{
		'lat': 45.40189151262484,
		'lon': 11.008569467317225,
		'name': 'Province of Verona',
		'iso': 'IT',
		'NumberOfSessions': 7
	},
	{
		'lat': 37.50298708588157,
		'lon': 15.083440620618314,
		'name': 'Catania',
		'iso': 'IT',
		'NumberOfSessions': 6
	},
	{
		'lat': 39.299200000000006,
		'lon': 16.2493,
		'name': 'Province of Cosenza',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.20040403133771,
		'lon': 10.836051781561164,
		'name': 'Province of Mantua',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 41.07261071038175,
		'lon': 16.870281390722674,
		'name': 'Bari',
		'iso': 'IT',
		'NumberOfSessions': 11
	},
	{
		'lat': 45.471497006807766,
		'lon': 9.188466828876283,
		'name': 'Milan',
		'iso': 'IT',
		'NumberOfSessions': 190
	},
	{
		'lat': 38.7541,
		'lon': 16.1822,
		'name': 'Province of Vibo Valentia',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.723000000000006,
		'lon': 11.285399999999997,
		'name': 'Province of Ferrara',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.47419999999998,
		'lon': 9.199399999999992,
		'name': 'Lombardy',
		'iso': 'IT',
		'NumberOfSessions': 38
	},
	{
		'lat': 45.64695738292325,
		'lon': 8.917940908092808,
		'name': 'Province of Varese',
		'iso': 'IT',
		'NumberOfSessions': 6
	},
	{
		'lat': 42.4172,
		'lon': 12.101799999999995,
		'name': 'Province of Viterbo',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.3126,
		'lon': 16.2838,
		'name': 'Province of Barletta-Andria-Trani',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.5283530147622,
		'lon': 10.172195796990776,
		'name': 'Province of Brescia',
		'iso': 'IT',
		'NumberOfSessions': 14
	},
	{
		'lat': 43.78213988014162,
		'lon': 11.230392739948543,
		'name': 'Province of Florence',
		'iso': 'IT',
		'NumberOfSessions': 13
	},
	{
		'lat': 45.74823310002774,
		'lon': 12.326398127373709,
		'name': 'Province of Treviso',
		'iso': 'IT',
		'NumberOfSessions': 6
	},
	{
		'lat': 38.16598095420471,
		'lon': 15.404371176969281,
		'name': 'Province of Messina',
		'iso': 'IT',
		'NumberOfSessions': 11
	},
	{
		'lat': 43.07079211498343,
		'lon': 12.508319551750997,
		'name': 'Province of Perugia',
		'iso': 'IT',
		'NumberOfSessions': 4
	},
	{
		'lat': 46.0674,
		'lon': 13.236400000000001,
		'name': 'Udine',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.636702941424524,
		'lon': 10.931742298563059,
		'name': 'Province of Modena',
		'iso': 'IT',
		'NumberOfSessions': 10
	},
	{
		'lat': 37.073899999999995,
		'lon': 14.237399999999996,
		'name': 'Province of Caltanissetta',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.86874370377967,
		'lon': 14.264549644603047,
		'name': 'Naples',
		'iso': 'IT',
		'NumberOfSessions': 50
	},
	{
		'lat': 43.05084341082967,
		'lon': 13.595396906386766,
		'name': 'Province of Fermo',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 44.38920830473279,
		'lon': 8.95584619664196,
		'name': 'Province of Genoa',
		'iso': 'IT',
		'NumberOfSessions': 25
	},
	{
		'lat': 43.84700000000001,
		'lon': 10.5018,
		'name': 'Province of Lucca',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.1479,
		'lon': 12.6476,
		'name': 'Province of Pordenone',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.091457221684976,
		'lon': 7.663213214006125,
		'name': 'Turin',
		'iso': 'IT',
		'NumberOfSessions': 40
	},
	{
		'lat': 42.598961762082546,
		'lon': 14.081467513922101,
		'name': 'Province of Teramo',
		'iso': 'IT',
		'NumberOfSessions': 5
	},
	{
		'lat': 40.704828727510005,
		'lon': 14.723653541422467,
		'name': 'Province of Salerno',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.60125690030288,
		'lon': 11.600241939521656,
		'name': 'Province of Vicenza',
		'iso': 'IT',
		'NumberOfSessions': 6
	},
	{
		'lat': 43.591163327244494,
		'lon': 11.823117656663797,
		'name': 'Province of Arezzo',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.12083536465685,
		'lon': 13.340863668436565,
		'name': 'Province of Palermo',
		'iso': 'IT',
		'NumberOfSessions': 10
	},
	{
		'lat': 44.799,
		'lon': 10.3234,
		'name': 'Province of Parma',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.70194729157743,
		'lon': 10.610259638030302,
		'name': 'Province of Reggio Emilia',
		'iso': 'IT',
		'NumberOfSessions': 5
	},
	{
		'lat': 45.42326309039764,
		'lon': 11.883630170774127,
		'name': 'Padua',
		'iso': 'IT',
		'NumberOfSessions': 11
	},
	{
		'lat': 45.219950658534835,
		'lon': 8.943433543099403,
		'name': 'Province of Pavia',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 41.13440000000001,
		'lon': 14.7746,
		'name': 'Province of Benevento',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.69675000436204,
		'lon': 8.631399544877015,
		'name': 'Province of Novara',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.74560000000002,
		'lon': 16.5145,
		'name': 'Province of Catanzaro',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.09676231689715,
		'lon': 14.990699607955381,
		'name': 'Syracuse',
		'iso': 'IT',
		'NumberOfSessions': 4
	},
	{
		'lat': 44.0591015992655,
		'lon': 10.12115886197246,
		'name': 'Province of Massa-Carrara',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.415307994367396,
		'lon': 12.09810187311997,
		'name': 'Province of Ravenna',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 41.88911053254637,
		'lon': 12.522641146221602,
		'name': 'Rome',
		'iso': 'IT',
		'NumberOfSessions': 69
	},
	{
		'lat': 44.583864301609644,
		'lon': 7.743286493865397,
		'name': 'Province of Cuneo',
		'iso': 'IT',
		'NumberOfSessions': 5
	},
	{
		'lat': 44.48940477856209,
		'lon': 11.383985076814044,
		'name': 'Bologna',
		'iso': 'IT',
		'NumberOfSessions': 21
	},
	{
		'lat': 41.021022677727814,
		'lon': 14.22564134423598,
		'name': 'Province of Caserta',
		'iso': 'IT',
		'NumberOfSessions': 9
	},
	{
		'lat': 45.7522527152405,
		'lon': 9.062224764189656,
		'name': 'Province of Como',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.69811865354872,
		'lon': 10.663023174092723,
		'name': 'Province of Pisa',
		'iso': 'IT',
		'NumberOfSessions': 4
	},
	{
		'lat': 44.105699999999985,
		'lon': 9.8311,
		'name': 'Province of La Spezia',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.597607120576235,
		'lon': 9.240128924796164,
		'name': 'Monza Brianza',
		'iso': 'IT',
		'NumberOfSessions': 13
	},
	{
		'lat': 44.22509999999999,
		'lon': 12.046999999999999,
		'name': 'Province of Forlì-Cesena',
		'iso': 'IT',
		'NumberOfSessions': 5
	},
	{
		'lat': 36.87876342129274,
		'lon': 14.787769696226283,
		'name': 'Province of Ragusa',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.4031,
		'lon': 11.8747,
		'name': 'Veneto',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.8397,
		'lon': 13.0125,
		'name': 'Pesaro and Urbino',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.570299999999996,
		'lon': 8.184699999999998,
		'name': 'Province of Biella',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.45130252681887,
		'lon': 12.406531768980942,
		'name': 'Venice',
		'iso': 'IT',
		'NumberOfSessions': 11
	},
	{
		'lat': 44.48469999999999,
		'lon': 11.327999999999998,
		'name': 'Emilia-Romagna',
		'iso': 'IT',
		'NumberOfSessions': 7
	},
	{
		'lat': 39.2074,
		'lon': 9.1095,
		'name': 'South Sardinia',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.8828,
		'lon': 11.1008,
		'name': 'Provincia di Prato',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.289,
		'lon': 18.2991,
		'name': 'Provincia di Lecce',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.58719817288051,
		'lon': 9.672004775488727,
		'name': 'Province of Bergamo',
		'iso': 'IT',
		'NumberOfSessions': 14
	},
	{
		'lat': 40.52945031509359,
		'lon': 17.235058643783304,
		'name': 'Province of Taranto',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.260613488940415,
		'lon': 13.444868058179367,
		'name': 'Province of Macerata',
		'iso': 'IT',
		'NumberOfSessions': 9
	},
	{
		'lat': 41.933,
		'lon': 13.841999999999999,
		'name': 'Province of L\'Aquila',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 44.0545,
		'lon': 12.5686,
		'name': 'Province of Rimini',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 42.4545432168842,
		'lon': 14.173531125210925,
		'name': 'Province of Pescara',
		'iso': 'IT',
		'NumberOfSessions': 6
	},
	{
		'lat': 45.6482,
		'lon': 13.7747,
		'name': 'Trieste',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.876099999999994,
		'lon': 7.9942,
		'name': 'Province of Asti',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.8325,
		'lon': 14.2548,
		'name': 'Campania',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.27019978960267,
		'lon': 11.507743775752871,
		'name': 'Province of Trente',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.118694865761036,
		'lon': 15.673487070911788,
		'name': 'Province of Reggio Calabria',
		'iso': 'IT',
		'NumberOfSessions': 58
	},
	{
		'lat': 43.54280000000001,
		'lon': 10.3298,
		'name': 'Province of Livorno',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 41.900800000000004,
		'lon': 12.4874,
		'name': 'Lazio',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.673286494639754,
		'lon': 139.6765520948505,
		'name': 'Tokyo',
		'iso': 'JP',
		'NumberOfSessions': 75
	},
	{
		'lat': 43.07504006968595,
		'lon': 141.36282075980102,
		'name': 'Hokkaido',
		'iso': 'JP',
		'NumberOfSessions': 5
	},
	{
		'lat': 33.2337,
		'lon': 131.5928,
		'name': 'Oita',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 31.5649,
		'lon': 130.5601,
		'name': 'Kagoshima',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.1466,
		'lon': 132.5932,
		'name': 'Shimane',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.968390968816,
		'lon': 139.57700728061263,
		'name': 'Saitama',
		'iso': 'JP',
		'NumberOfSessions': 5
	},
	{
		'lat': 34.33160000000001,
		'lon': 134.0452,
		'name': 'Kagawa',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.0203,
		'lon': 135.7602,
		'name': 'Kyoto',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 33.699545568030935,
		'lon': 130.59570662868725,
		'name': 'Fukuoka',
		'iso': 'JP',
		'NumberOfSessions': 5
	},
	{
		'lat': 35.179871458277155,
		'lon': 136.965047557969,
		'name': 'Aichi',
		'iso': 'JP',
		'NumberOfSessions': 6
	},
	{
		'lat': 36.568476824686826,
		'lon': 139.66362978328917,
		'name': 'Tochigi',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 34.6922,
		'lon': 135.1816,
		'name': 'Hyōgo',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.59012320324822,
		'lon': 140.29802947120615,
		'name': 'Akita',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 32.7968,
		'lon': 130.7687,
		'name': 'Kumamoto',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.6595,
		'lon': 133.9342,
		'name': 'Okayama',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.111,
		'lon': 134.5724,
		'name': 'Tokushima',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.67642705127432,
		'lon': 138.9378063805861,
		'name': 'Niigata',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 26.3297,
		'lon': 127.8047,
		'name': 'Okinawa',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.8853,
		'lon': 136.5796,
		'name': 'Mie',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 36.35019999999999,
		'lon': 140.4258,
		'name': 'Ibaraki',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.29420180112356,
		'lon': 140.86818756575633,
		'name': 'Miyagi',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.563915203373355,
		'lon': 137.02971211869306,
		'name': 'Gifu',
		'iso': 'JP',
		'NumberOfSessions': 3
	},
	{
		'lat': 35.46283429605195,
		'lon': 139.55162614542297,
		'name': 'Kanagawa',
		'iso': 'JP',
		'NumberOfSessions': 18
	},
	{
		'lat': 36.35926996680318,
		'lon': 139.18741330477633,
		'name': 'Gunma',
		'iso': 'JP',
		'NumberOfSessions': 3
	},
	{
		'lat': 36.5968,
		'lon': 136.5998,
		'name': 'Ishikawa',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.296800000000005,
		'lon': 135.3876,
		'name': 'Wakayama',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.216800000000006,
		'lon': 138.4859,
		'name': 'Nagano',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 33.836499999999994,
		'lon': 132.742,
		'name': 'Ehime',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 34.69778530001235,
		'lon': 135.52223354696423,
		'name': 'Osaka',
		'iso': 'JP',
		'NumberOfSessions': 21
	},
	{
		'lat': 35.72328739085787,
		'lon': 140.0266240206205,
		'name': 'Chiba',
		'iso': 'JP',
		'NumberOfSessions': 9
	},
	{
		'lat': 37.513883548761584,
		'lon': 126.68205594789057,
		'name': 'Incheon',
		'iso': 'KR',
		'NumberOfSessions': 10
	},
	{
		'lat': 35.87424793389102,
		'lon': 128.55489369488416,
		'name': 'Daegu',
		'iso': 'KR',
		'NumberOfSessions': 3
	},
	{
		'lat': 37.54926216606838,
		'lon': 126.97088387553657,
		'name': 'Seoul',
		'iso': 'KR',
		'NumberOfSessions': 51
	},
	{
		'lat': 37.574315752584646,
		'lon': 127.01250254430599,
		'name': 'Gyeonggi-do',
		'iso': 'KR',
		'NumberOfSessions': 51
	},
	{
		'lat': 36.807253428739244,
		'lon': 127.67940371244683,
		'name': 'North Chungcheong',
		'iso': 'KR',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.58250000000001,
		'lon': 126.8676,
		'name': 'Jeollabuk-do',
		'iso': 'KR',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.834304413598176,
		'lon': 126.99674205500447,
		'name': 'Chungcheongnam-do',
		'iso': 'KR',
		'NumberOfSessions': 7
	},
	{
		'lat': 36.449200490949586,
		'lon': 127.37724535646635,
		'name': 'Daejeon',
		'iso': 'KR',
		'NumberOfSessions': 2
	},
	{
		'lat': 37.612604541956266,
		'lon': 127.84109943275664,
		'name': 'Gangwon-do',
		'iso': 'KR',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.5457,
		'lon': 128.499,
		'name': 'Gyeongsangnam-do',
		'iso': 'KR',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.198059391611245,
		'lon': 129.03520091212621,
		'name': 'Busan',
		'iso': 'KR',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.89433140641953,
		'lon': 73.03339234327119,
		'name': 'Karaganda',
		'iso': 'KZ',
		'NumberOfSessions': 8
	},
	{
		'lat': 43.642199999999995,
		'lon': 51.1668,
		'name': 'Mangistauskaya Oblast\'',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.97130000000001,
		'lon': 82.6103,
		'name': 'East Kazakhstan',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 44,
		'lon': 76.2833,
		'name': 'Almaty Oblysy',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.78979999999999,
		'lon': 67.7162,
		'name': 'Ulytau Region',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.064892803943884,
		'lon': 76.28236966012564,
		'name': 'Pavlodar Region',
		'iso': 'KZ',
		'NumberOfSessions': 5
	},
	{
		'lat': 47.1169,
		'lon': 51.885299999999994,
		'name': 'Atyrau Oblisi',
		'iso': 'KZ',
		'NumberOfSessions': 4
	},
	{
		'lat': 54.8735,
		'lon': 69.1491,
		'name': 'North Kazakhstan',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.1876,
		'lon': 71.4491,
		'name': 'Astana',
		'iso': 'KZ',
		'NumberOfSessions': 6
	},
	{
		'lat': 43.243300000000005,
		'lon': 76.8646,
		'name': 'Almaty',
		'iso': 'KZ',
		'NumberOfSessions': 12
	},
	{
		'lat': 51.229000000000006,
		'lon': 51.35769999999999,
		'name': 'West Kazakhstan',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.29569999999999,
		'lon': 57.23790000000001,
		'name': 'Aktyubinskaya Oblast\'',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.9038,
		'lon': 23.892400000000006,
		'name': 'Kupiškis District Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.7385,
		'lon': 24.3506,
		'name': 'Panevėžys City',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.6466,
		'lon': 25.045099999999998,
		'name': 'Trakai District Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.7158,
		'lon': 21.110999999999997,
		'name': 'Klaipėda City Municipality',
		'iso': 'LT',
		'NumberOfSessions': 7
	},
	{
		'lat': 55.248300000000015,
		'lon': 23.9688,
		'name': 'Kėdainiai District Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.5608,
		'lon': 23.3462,
		'name': 'Marijampolė Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.90395790104663,
		'lon': 23.892936830876707,
		'name': 'Kaunas',
		'iso': 'LT',
		'NumberOfSessions': 19
	},
	{
		'lat': 55.9781,
		'lon': 22.2491,
		'name': 'Telšiai District Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.83330000000001,
		'lon': 25.33330000000001,
		'name': 'Vilnius',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.9318,
		'lon': 23.3289,
		'name': 'Šiauliai City Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.71399999999999,
		'lon': 21.408099999999997,
		'name': 'Klaipėda District Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.695915323386735,
		'lon': 25.29998483544354,
		'name': 'Vilnius City Municipality',
		'iso': 'LT',
		'NumberOfSessions': 36
	},
	{
		'lat': 54.3065,
		'lon': 25.3905,
		'name': 'Šalčininkai District Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.687200000000004,
		'lon': 23.799600000000005,
		'name': 'Jelgava Municipality',
		'iso': 'LV',
		'NumberOfSessions': 3
	},
	{
		'lat': 55.8822,
		'lon': 26.526800000000005,
		'name': 'Daugavpils',
		'iso': 'LV',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.9658,
		'lon': 23.7757,
		'name': 'Jūrmala',
		'iso': 'LV',
		'NumberOfSessions': 2
	},
	{
		'lat': 56.1654,
		'lon': 21.1558,
		'name': 'South Kurzeme Municipality',
		'iso': 'LV',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.5128,
		'lon': 21.015400000000007,
		'name': 'Liepāja',
		'iso': 'LV',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.5085,
		'lon': 27.348100000000002,
		'name': 'Rēzekne',
		'iso': 'LV',
		'NumberOfSessions': 2
	},
	{
		'lat': 56.8351,
		'lon': 24.5058,
		'name': 'Ogre',
		'iso': 'LV',
		'NumberOfSessions': 2
	},
	{
		'lat': 57.4344,
		'lon': 25.9,
		'name': 'Smiltene Municipality',
		'iso': 'LV',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.6001,
		'lon': 25.251299999999997,
		'name': 'Aizkraukle Municipality',
		'iso': 'LV',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.949600000000004,
		'lon': 24.09779999999995,
		'name': 'Rīga',
		'iso': 'LV',
		'NumberOfSessions': 57
	},
	{
		'lat': 33.5792,
		'lon': -7.613300000000001,
		'name': 'Casablanca',
		'iso': 'MA',
		'NumberOfSessions': 3
	},
	{
		'lat': 35.7758,
		'lon': -5.790899999999998,
		'name': 'Tanger-Assilah',
		'iso': 'MA',
		'NumberOfSessions': 2
	},
	{
		'lat': 33.262,
		'lon': -7.593600000000001,
		'name': 'Settat Province',
		'iso': 'MA',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.2619,
		'lon': -6.5821,
		'name': 'Kenitra Province',
		'iso': 'MA',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.17170000000001,
		'lon': -2.9320000000000004,
		'name': 'Nador',
		'iso': 'MA',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.0072,
		'lon': -6.8407,
		'name': 'Rabat',
		'iso': 'MA',
		'NumberOfSessions': 1
	},
	{
		'lat': 33.8916,
		'lon': -5.5396,
		'name': 'Meknès Prefecture',
		'iso': 'MA',
		'NumberOfSessions': 2
	},
	{
		'lat': 30.420100000000005,
		'lon': -9.5912,
		'name': 'Agadir-Ida-ou-Tnan',
		'iso': 'MA',
		'NumberOfSessions': 1
	},
	{
		'lat': 33.663,
		'lon': -7.066600000000001,
		'name': 'Benslimane Province',
		'iso': 'MA',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.0508,
		'lon': -5.0099,
		'name': 'Fes',
		'iso': 'MA',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.8973,
		'lon': 14.4813,
		'name': 'L-Imsida',
		'iso': 'MT',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.8762,
		'lon': 14.5341,
		'name': 'Ħaż-Żabbar',
		'iso': 'MT',
		'NumberOfSessions': 5
	},
	{
		'lat': 35.9142,
		'lon': 14.425300000000004,
		'name': 'Il-Mosta',
		'iso': 'MT',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.89529999999999,
		'lon': 14.4589,
		'name': 'Birkirkara',
		'iso': 'MT',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.8956,
		'lon': 14.516099999999998,
		'name': 'Valletta',
		'iso': 'MT',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.9546,
		'lon': 14.410300000000001,
		'name': 'Saint Paul’s Bay',
		'iso': 'MT',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.89529999999999,
		'lon': 14.478499999999997,
		'name': 'Saint Venera',
		'iso': 'MT',
		'NumberOfSessions': 1
	},
	{
		'lat': 16.909435152536812,
		'lon': -96.31276297540315,
		'name': 'Oaxaca',
		'iso': 'MX',
		'NumberOfSessions': 4
	},
	{
		'lat': 19.222049755768904,
		'lon': -91.18218855564155,
		'name': 'Campeche',
		'iso': 'MX',
		'NumberOfSessions': 2
	},
	{
		'lat': 20.9895018896556,
		'lon': -89.64574132886311,
		'name': 'Yucatán',
		'iso': 'MX',
		'NumberOfSessions': 9
	},
	{
		'lat': 26.057280697164252,
		'lon': -99.04913305908327,
		'name': 'Tamaulipas',
		'iso': 'MX',
		'NumberOfSessions': 5
	},
	{
		'lat': 25.72333093602284,
		'lon': -100.32369944217744,
		'name': 'Nuevo León',
		'iso': 'MX',
		'NumberOfSessions': 33
	},
	{
		'lat': 23.225702905159256,
		'lon': -110.03742302058123,
		'name': 'Baja California Sur',
		'iso': 'MX',
		'NumberOfSessions': 4
	},
	{
		'lat': 16.740850476708253,
		'lon': -93.13327527532033,
		'name': 'Chiapas',
		'iso': 'MX',
		'NumberOfSessions': 4
	},
	{
		'lat': 19.2561,
		'lon': -103.7486,
		'name': 'Colima',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 20.10945133896009,
		'lon': -98.76210087067331,
		'name': 'Hidalgo',
		'iso': 'MX',
		'NumberOfSessions': 2
	},
	{
		'lat': 18.777525781282804,
		'lon': -99.21555263357773,
		'name': 'Morelos',
		'iso': 'MX',
		'NumberOfSessions': 4
	},
	{
		'lat': 24.70315336958456,
		'lon': -107.62151065280645,
		'name': 'Sinaloa',
		'iso': 'MX',
		'NumberOfSessions': 4
	},
	{
		'lat': 19.028854304572757,
		'lon': -98.18732529197898,
		'name': 'Puebla',
		'iso': 'MX',
		'NumberOfSessions': 9
	},
	{
		'lat': 19.3244,
		'lon': -98.1821,
		'name': 'Tlaxcala',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 20.756100000000004,
		'lon': -105.3313,
		'name': 'Nayarit',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 19.446706177316734,
		'lon': -99.21355416939821,
		'name': 'México',
		'iso': 'MX',
		'NumberOfSessions': 36
	},
	{
		'lat': 25.415484352703125,
		'lon': -102.21128790469915,
		'name': 'Coahuila',
		'iso': 'MX',
		'NumberOfSessions': 4
	},
	{
		'lat': 20.61713822848847,
		'lon': -100.43058396040642,
		'name': 'Querétaro',
		'iso': 'MX',
		'NumberOfSessions': 12
	},
	{
		'lat': 29.9412407516718,
		'lon': -106.23692066564023,
		'name': 'Chihuahua',
		'iso': 'MX',
		'NumberOfSessions': 19
	},
	{
		'lat': 19.399726542242664,
		'lon': -99.14002013259446,
		'name': 'Mexico City',
		'iso': 'MX',
		'NumberOfSessions': 102
	},
	{
		'lat': 20.67478045294689,
		'lon': -103.43898953765088,
		'name': 'Jalisco',
		'iso': 'MX',
		'NumberOfSessions': 36
	},
	{
		'lat': 29.520455517545475,
		'lon': -110.98212646766733,
		'name': 'Sonora',
		'iso': 'MX',
		'NumberOfSessions': 5
	},
	{
		'lat': 19.726979749348633,
		'lon': -96.84622066010598,
		'name': 'Veracruz',
		'iso': 'MX',
		'NumberOfSessions': 6
	},
	{
		'lat': 22.135568076609747,
		'lon': -100.95580022844197,
		'name': 'San Luis Potosí',
		'iso': 'MX',
		'NumberOfSessions': 3
	},
	{
		'lat': 23.90467808128034,
		'lon': -104.526728297547,
		'name': 'Durango',
		'iso': 'MX',
		'NumberOfSessions': 2
	},
	{
		'lat': 21.849704479871424,
		'lon': -102.25896122317616,
		'name': 'Aguascalientes',
		'iso': 'MX',
		'NumberOfSessions': 2
	},
	{
		'lat': 19.413051543287438,
		'lon': -101.73615139771229,
		'name': 'Michoacán',
		'iso': 'MX',
		'NumberOfSessions': 9
	},
	{
		'lat': 20.903604593352924,
		'lon': -86.91980464120233,
		'name': 'Quintana Roo',
		'iso': 'MX',
		'NumberOfSessions': 9
	},
	{
		'lat': 20.967651548353107,
		'lon': -101.40151848224764,
		'name': 'Guanajuato',
		'iso': 'MX',
		'NumberOfSessions': 8
	},
	{
		'lat': 32.499876186426086,
		'lon': -116.41566969976493,
		'name': 'Baja California',
		'iso': 'MX',
		'NumberOfSessions': 17
	},
	{
		'lat': 16.910299999999996,
		'lon': -99.7727,
		'name': 'Guerrero',
		'iso': 'MX',
		'NumberOfSessions': 2
	},
	{
		'lat': 17.98476853990489,
		'lon': -92.91590128359319,
		'name': 'Tabasco',
		'iso': 'MX',
		'NumberOfSessions': 6
	},
	{
		'lat': 1.5608,
		'lon': 110.3071,
		'name': 'Sarawak',
		'iso': 'MY',
		'NumberOfSessions': 1
	},
	{
		'lat': 5.3758800311564,
		'lon': 100.38595572501553,
		'name': 'Penang',
		'iso': 'MY',
		'NumberOfSessions': 4
	},
	{
		'lat': 3.1353971754815237,
		'lon': 101.68575746428645,
		'name': 'Kuala Lumpur',
		'iso': 'MY',
		'NumberOfSessions': 33
	},
	{
		'lat': 4.577700000000002,
		'lon': 101.1113,
		'name': 'Perak',
		'iso': 'MY',
		'NumberOfSessions': 1
	},
	{
		'lat': 5.951850474038183,
		'lon': 116.05975016495012,
		'name': 'Sabah',
		'iso': 'MY',
		'NumberOfSessions': 2
	},
	{
		'lat': 2.2446,
		'lon': 102.2411,
		'name': 'Melaka',
		'iso': 'MY',
		'NumberOfSessions': 1
	},
	{
		'lat': 3.0272926696091793,
		'lon': 101.64437769015164,
		'name': 'Selangor',
		'iso': 'MY',
		'NumberOfSessions': 33
	},
	{
		'lat': 1.549520000118704,
		'lon': 103.69676000232973,
		'name': 'Johor',
		'iso': 'MY',
		'NumberOfSessions': 5
	},
	{
		'lat': 52.06704556968166,
		'lon': 5.8448901481945095,
		'name': 'Gelderland',
		'iso': 'NL',
		'NumberOfSessions': 26
	},
	{
		'lat': 52.39465666175603,
		'lon': 4.8777895098182364,
		'name': 'North Holland',
		'iso': 'NL',
		'NumberOfSessions': 130
	},
	{
		'lat': 51.576431486603674,
		'lon': 5.185242791323926,
		'name': 'North Brabant',
		'iso': 'NL',
		'NumberOfSessions': 34
	},
	{
		'lat': 50.996949658169,
		'lon': 5.88975066972031,
		'name': 'Limburg',
		'iso': 'NL',
		'NumberOfSessions': 6
	},
	{
		'lat': 52.35043894565605,
		'lon': 6.484303294703664,
		'name': 'Overijssel',
		'iso': 'NL',
		'NumberOfSessions': 12
	},
	{
		'lat': 51.4451,
		'lon': 3.5765000000000002,
		'name': 'Zeeland',
		'iso': 'NL',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.45744598021756,
		'lon': 5.388597382661372,
		'name': 'Flevoland',
		'iso': 'NL',
		'NumberOfSessions': 17
	},
	{
		'lat': 53.18882880648667,
		'lon': 5.800837429454334,
		'name': 'Friesland',
		'iso': 'NL',
		'NumberOfSessions': 6
	},
	{
		'lat': 51.971765912220526,
		'lon': 4.41454054113411,
		'name': 'South Holland',
		'iso': 'NL',
		'NumberOfSessions': 66
	},
	{
		'lat': 53.19484309307797,
		'lon': 6.634775248160555,
		'name': 'Groningen',
		'iso': 'NL',
		'NumberOfSessions': 13
	},
	{
		'lat': 52.738972956322264,
		'lon': 6.547904926219169,
		'name': 'Drenthe',
		'iso': 'NL',
		'NumberOfSessions': 2
	},
	{
		'lat': 52.11042494161522,
		'lon': 5.136059017135437,
		'name': 'Utrecht',
		'iso': 'NL',
		'NumberOfSessions': 28
	},
	{
		'lat': 59.93021046135815,
		'lon': 11.19569543403003,
		'name': 'Akershus',
		'iso': 'NO',
		'NumberOfSessions': 3
	},
	{
		'lat': 59.304591735999246,
		'lon': 10.31493486702868,
		'name': 'Vestfold',
		'iso': 'NO',
		'NumberOfSessions': 5
	},
	{
		'lat': 63.30258293268842,
		'lon': 10.2391462892215,
		'name': 'Trøndelag',
		'iso': 'NO',
		'NumberOfSessions': 9
	},
	{
		'lat': 58.84758730892467,
		'lon': 5.687568323270347,
		'name': 'Rogaland',
		'iso': 'NO',
		'NumberOfSessions': 4
	},
	{
		'lat': 69.54388593711924,
		'lon': 23.374309976330075,
		'name': 'Finnmark',
		'iso': 'NO',
		'NumberOfSessions': 2
	},
	{
		'lat': 59.89768655043239,
		'lon': 10.737265818327915,
		'name': 'Oslo County',
		'iso': 'NO',
		'NumberOfSessions': 44
	},
	{
		'lat': 58.157211203691915,
		'lon': 7.986905835012232,
		'name': 'Agder',
		'iso': 'NO',
		'NumberOfSessions': 9
	},
	{
		'lat': 59.75338152588693,
		'lon': 10.002124241380452,
		'name': 'Buskerud',
		'iso': 'NO',
		'NumberOfSessions': 5
	},
	{
		'lat': 60.417144310715635,
		'lon': 5.279485869452175,
		'name': 'Vestland',
		'iso': 'NO',
		'NumberOfSessions': 7
	},
	{
		'lat': 69.35418336005748,
		'lon': 18.597417212449983,
		'name': 'Troms',
		'iso': 'NO',
		'NumberOfSessions': 4
	},
	{
		'lat': 66.32110117554373,
		'lon': 13.535030280814924,
		'name': 'Nordland',
		'iso': 'NO',
		'NumberOfSessions': 3
	},
	{
		'lat': 60.764921000664565,
		'lon': 10.678666574359601,
		'name': 'Innlandet',
		'iso': 'NO',
		'NumberOfSessions': 6
	},
	{
		'lat': 62.47250422663439,
		'lon': 6.4724288694034735,
		'name': 'Møre og Romsdal',
		'iso': 'NO',
		'NumberOfSessions': 3
	},
	{
		'lat': -43.55248336406984,
		'lon': 172.53288308758331,
		'name': 'Canterbury',
		'iso': 'NZ',
		'NumberOfSessions': 17
	},
	{
		'lat': -42.5622,
		'lon': 171.2183,
		'name': 'West Coast',
		'iso': 'NZ',
		'NumberOfSessions': 2
	},
	{
		'lat': -37.798103565715635,
		'lon': 175.30313015111997,
		'name': 'Waikato Region',
		'iso': 'NZ',
		'NumberOfSessions': 9
	},
	{
		'lat': -45.88875079483298,
		'lon': 170.47739784905258,
		'name': 'Otago',
		'iso': 'NZ',
		'NumberOfSessions': 2
	},
	{
		'lat': -38.030157143316835,
		'lon': 176.22677906264565,
		'name': 'Bay of Plenty',
		'iso': 'NZ',
		'NumberOfSessions': 4
	},
	{
		'lat': -41.496,
		'lon': 173.8352,
		'name': 'Marlborough',
		'iso': 'NZ',
		'NumberOfSessions': 1
	},
	{
		'lat': -36.852230988438414,
		'lon': 174.76461626401283,
		'name': 'Auckland',
		'iso': 'NZ',
		'NumberOfSessions': 47
	},
	{
		'lat': -40.3761,
		'lon': 175.5809,
		'name': 'Manawatu-Wanganui',
		'iso': 'NZ',
		'NumberOfSessions': 1
	},
	{
		'lat': -41.24052184570419,
		'lon': 174.871929827156,
		'name': 'Wellington Region',
		'iso': 'NZ',
		'NumberOfSessions': 11
	},
	{
		'lat': -13.1603,
		'lon': -74.2257,
		'name': 'Ayacucho',
		'iso': 'PE',
		'NumberOfSessions': 1
	},
	{
		'lat': -18.0052,
		'lon': -70.2489,
		'name': 'Tacna',
		'iso': 'PE',
		'NumberOfSessions': 1
	},
	{
		'lat': -13.640899999999998,
		'lon': -72.8793,
		'name': 'Apurímac Department',
		'iso': 'PE',
		'NumberOfSessions': 1
	},
	{
		'lat': -12.043200000000008,
		'lon': -77.02819999999998,
		'name': 'Lima Province',
		'iso': 'PE',
		'NumberOfSessions': 48
	},
	{
		'lat': -11.943902004038755,
		'lon': -76.79587654073482,
		'name': 'Lima region',
		'iso': 'PE',
		'NumberOfSessions': 7
	},
	{
		'lat': -4.9017,
		'lon': -80.6887,
		'name': 'Piura',
		'iso': 'PE',
		'NumberOfSessions': 1
	},
	{
		'lat': -16.4014,
		'lon': -71.5343,
		'name': 'Arequipa',
		'iso': 'PE',
		'NumberOfSessions': 4
	},
	{
		'lat': -8.1191,
		'lon': -79.0355,
		'name': 'La Libertad',
		'iso': 'PE',
		'NumberOfSessions': 1
	},
	{
		'lat': -7.163,
		'lon': -78.5008,
		'name': 'Cajamarca Department',
		'iso': 'PE',
		'NumberOfSessions': 1
	},
	{
		'lat': -12.063699999999999,
		'lon': -75.21720000000002,
		'name': 'Junin',
		'iso': 'PE',
		'NumberOfSessions': 1
	},
	{
		'lat': 15.080934315960965,
		'lon': 120.6337198474462,
		'name': 'Province of Pampanga',
		'iso': 'PH',
		'NumberOfSessions': 6
	},
	{
		'lat': 16.4844,
		'lon': 121.14419999999998,
		'name': 'Province of Nueva Vizcaya',
		'iso': 'PH',
		'NumberOfSessions': 2
	},
	{
		'lat': 8.9449,
		'lon': 125.54679999999999,
		'name': 'Province of Agusan del Norte',
		'iso': 'PH',
		'NumberOfSessions': 1
	},
	{
		'lat': 7.071199999999998,
		'lon': 125.6089,
		'name': 'Province of Davao del Sur',
		'iso': 'PH',
		'NumberOfSessions': 4
	},
	{
		'lat': 16.412399999999998,
		'lon': 120.5933,
		'name': 'Province of Benguet',
		'iso': 'PH',
		'NumberOfSessions': 3
	},
	{
		'lat': 6.690900000000002,
		'lon': 124.6762,
		'name': 'Province of Sultan Kudarat',
		'iso': 'PH',
		'NumberOfSessions': 1
	},
	{
		'lat': 14.443587362132831,
		'lon': 121.03983729730278,
		'name': 'Province of Rizal',
		'iso': 'PH',
		'NumberOfSessions': 7
	},
	{
		'lat': 17.608,
		'lon': 121.72190000000002,
		'name': 'Province of Cagayan',
		'iso': 'PH',
		'NumberOfSessions': 2
	},
	{
		'lat': 10.6689,
		'lon': 122.9466,
		'name': 'Province of Negros Occidental',
		'iso': 'PH',
		'NumberOfSessions': 3
	},
	{
		'lat': 10.7019,
		'lon': 122.5622,
		'name': 'Province of Iloilo',
		'iso': 'PH',
		'NumberOfSessions': 3
	},
	{
		'lat': 14.294435884814414,
		'lon': 121.11734287656795,
		'name': 'Province of Laguna',
		'iso': 'PH',
		'NumberOfSessions': 6
	},
	{
		'lat': 14.431213305032767,
		'lon': 120.90832071710588,
		'name': 'Province of Cavite',
		'iso': 'PH',
		'NumberOfSessions': 9
	},
	{
		'lat': 10.317100013216999,
		'lon': 123.89469988325185,
		'name': 'Province of Cebu',
		'iso': 'PH',
		'NumberOfSessions': 12
	},
	{
		'lat': 13.783569691091627,
		'lon': 121.07988833352397,
		'name': 'Province of Batangas',
		'iso': 'PH',
		'NumberOfSessions': 6
	},
	{
		'lat': 14.59237371781693,
		'lon': 121.02487174183537,
		'name': 'Metro Manila',
		'iso': 'PH',
		'NumberOfSessions': 49
	},
	{
		'lat': 8.485,
		'lon': 124.64799999999998,
		'name': 'Province of Misamis Oriental',
		'iso': 'PH',
		'NumberOfSessions': 10
	},
	{
		'lat': 50.7135682395104,
		'lon': 17.745546198317832,
		'name': 'Opole Voivodeship',
		'iso': 'PL',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.88675602864047,
		'lon': 20.460869806404496,
		'name': 'Warmia-Masuria',
		'iso': 'PL',
		'NumberOfSessions': 6
	},
	{
		'lat': 53.59083008443704,
		'lon': 14.982438868346357,
		'name': 'West Pomerania',
		'iso': 'PL',
		'NumberOfSessions': 15
	},
	{
		'lat': 52.226378435847565,
		'lon': 21.02247529077148,
		'name': 'Mazovia',
		'iso': 'PL',
		'NumberOfSessions': 159
	},
	{
		'lat': 50.0344071312803,
		'lon': 19.909657325011384,
		'name': 'Lesser Poland',
		'iso': 'PL',
		'NumberOfSessions': 57
	},
	{
		'lat': 51.30322283854553,
		'lon': 22.714147863799816,
		'name': 'Lublin',
		'iso': 'PL',
		'NumberOfSessions': 16
	},
	{
		'lat': 51.687759266209945,
		'lon': 19.58098844741331,
		'name': 'Łódź Voivodeship',
		'iso': 'PL',
		'NumberOfSessions': 66
	},
	{
		'lat': 51.93355006609455,
		'lon': 15.50424983714599,
		'name': 'Lubusz',
		'iso': 'PL',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.10804244339728,
		'lon': 16.903140435632007,
		'name': 'Lower Silesia',
		'iso': 'PL',
		'NumberOfSessions': 48
	},
	{
		'lat': 50.06666427995742,
		'lon': 22.070087224030107,
		'name': 'Subcarpathia',
		'iso': 'PL',
		'NumberOfSessions': 6
	},
	{
		'lat': 54.39055596414224,
		'lon': 18.51811746817465,
		'name': 'Pomerania',
		'iso': 'PL',
		'NumberOfSessions': 35
	},
	{
		'lat': 53.086644358034235,
		'lon': 18.39271273041498,
		'name': 'Kujawsko-Pomorskie',
		'iso': 'PL',
		'NumberOfSessions': 23
	},
	{
		'lat': 50.19684059641845,
		'lon': 18.978639436918414,
		'name': 'Silesia',
		'iso': 'PL',
		'NumberOfSessions': 53
	},
	{
		'lat': 52.29070601856253,
		'lon': 16.9594628744188,
		'name': 'Greater Poland',
		'iso': 'PL',
		'NumberOfSessions': 50
	},
	{
		'lat': 50.844357131134316,
		'lon': 20.92112489469909,
		'name': 'Świętokrzyskie',
		'iso': 'PL',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.37212240758668,
		'lon': 23.06742196190814,
		'name': 'Podlasie',
		'iso': 'PL',
		'NumberOfSessions': 4
	},
	{
		'lat': 40.82865000786795,
		'lon': -8.648853635056643,
		'name': 'Aveiro',
		'iso': 'PT',
		'NumberOfSessions': 4
	},
	{
		'lat': 39.574942020906256,
		'lon': -8.421236754924488,
		'name': 'Santarém',
		'iso': 'PT',
		'NumberOfSessions': 5
	},
	{
		'lat': 38.64315779693121,
		'lon': -7.935953982264435,
		'name': 'Évora',
		'iso': 'PT',
		'NumberOfSessions': 2
	},
	{
		'lat': 32.63330000000001,
		'lon': -16.900000000000002,
		'name': 'Madeira',
		'iso': 'PT',
		'NumberOfSessions': 3
	},
	{
		'lat': 40.28329999999999,
		'lon': -7.508000000000001,
		'name': 'Castelo Branco',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.6929,
		'lon': -8.839100000000002,
		'name': 'Viana do Castelo',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.80469999999999,
		'lon': -6.7577,
		'name': 'Bragança',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.09625017428258,
		'lon': -8.24120431370063,
		'name': 'Faro',
		'iso': 'PT',
		'NumberOfSessions': 3
	},
	{
		'lat': 38.7503388544082,
		'lon': -9.169135489694977,
		'name': 'Lisbon',
		'iso': 'PT',
		'NumberOfSessions': 42
	},
	{
		'lat': 38.62070838428653,
		'lon': -9.053726665446266,
		'name': 'Setúbal',
		'iso': 'PT',
		'NumberOfSessions': 8
	},
	{
		'lat': 41.19333679313691,
		'lon': -8.644892012477609,
		'name': 'Porto',
		'iso': 'PT',
		'NumberOfSessions': 27
	},
	{
		'lat': 40.2057,
		'lon': -8.4213,
		'name': 'Coimbra',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.55150000000001,
		'lon': -8.4204,
		'name': 'Braga',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.249,
		'lon': -8.005500000000001,
		'name': 'Portalegre',
		'iso': 'PT',
		'NumberOfSessions': 2
	},
	{
		'lat': 39.578156412996606,
		'lon': -9.023145932495492,
		'name': 'Leiria',
		'iso': 'PT',
		'NumberOfSessions': 6
	},
	{
		'lat': 37.7392,
		'lon': -25.661700000000003,
		'name': 'Azores',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.98930000000001,
		'lon': -7.616100000000003,
		'name': 'Viseu',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.64039011755835,
		'lon': 25.472696191462497,
		'name': 'Brașov County',
		'iso': 'RO',
		'NumberOfSessions': 13
	},
	{
		'lat': 45.750600000000006,
		'lon': 21.233399999999993,
		'name': 'Timiș County',
		'iso': 'RO',
		'NumberOfSessions': 11
	},
	{
		'lat': 44.41501215608656,
		'lon': 26.11715837302914,
		'name': 'București',
		'iso': 'RO',
		'NumberOfSessions': 49
	},
	{
		'lat': 46.43655624305543,
		'lon': 27.70041250484285,
		'name': 'Vaslui County',
		'iso': 'RO',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.83271797383053,
		'lon': 25.695373882862803,
		'name': 'Covasna County',
		'iso': 'RO',
		'NumberOfSessions': 3
	},
	{
		'lat': 47.75020000000001,
		'lon': 26.671299999999995,
		'name': 'Botoșani County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.167199999999994,
		'lon': 27.608300000000007,
		'name': 'Iași County',
		'iso': 'RO',
		'NumberOfSessions': 15
	},
	{
		'lat': 45.57227382763456,
		'lon': 27.84528492190743,
		'name': 'Galați County',
		'iso': 'RO',
		'NumberOfSessions': 4
	},
	{
		'lat': 45.8017,
		'lon': 24.1455,
		'name': 'Sibiu County',
		'iso': 'RO',
		'NumberOfSessions': 4
	},
	{
		'lat': 47.67756009656236,
		'lon': 23.855558682303403,
		'name': 'Maramureş',
		'iso': 'RO',
		'NumberOfSessions': 3
	},
	{
		'lat': 44.318,
		'lon': 23.7967,
		'name': 'Dolj',
		'iso': 'RO',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.184,
		'lon': 21.3225,
		'name': 'Arad County',
		'iso': 'RO',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.57010000000001,
		'lon': 26.912400000000005,
		'name': 'Bacău County',
		'iso': 'RO',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.001696290281295,
		'lon': 26.41147442891798,
		'name': 'Neamț County',
		'iso': 'RO',
		'NumberOfSessions': 3
	},
	{
		'lat': 44.944269378846926,
		'lon': 24.798113197944105,
		'name': 'Arges',
		'iso': 'RO',
		'NumberOfSessions': 3
	},
	{
		'lat': 44.6319,
		'lon': 22.656100000000002,
		'name': 'Mehedinți County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.15871334198045,
		'lon': 24.3428856615657,
		'name': 'Bistrița-Năsăud County',
		'iso': 'RO',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.2662,
		'lon': 27.9804,
		'name': 'Brăila County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.218570386334235,
		'lon': 22.928534785539753,
		'name': 'Sălaj County',
		'iso': 'RO',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.1494,
		'lon': 26.824399999999997,
		'name': 'Buzău County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.0489,
		'lon': 23.290400000000005,
		'name': 'Gorj County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.0653,
		'lon': 23.5917,
		'name': 'Alba County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.4348,
		'lon': 24.356000000000005,
		'name': 'Olt',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.1637,
		'lon': 28.8023,
		'name': 'Tulcea County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.43568314930332,
		'lon': 24.644929716911786,
		'name': 'Mureș County',
		'iso': 'RO',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.1002,
		'lon': 24.364399999999993,
		'name': 'Vâlcea County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.6998,
		'lon': 27.1875,
		'name': 'Vrancea',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.9316,
		'lon': 25.462199999999992,
		'name': 'Dâmbovița County',
		'iso': 'RO',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.7525,
		'lon': 22.903299999999998,
		'name': 'Hunedoara County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.16130011070821,
		'lon': 28.630903191051335,
		'name': 'Constanța County',
		'iso': 'RO',
		'NumberOfSessions': 7
	},
	{
		'lat': 44.462833739056364,
		'lon': 26.046988205668345,
		'name': 'Ilfov',
		'iso': 'RO',
		'NumberOfSessions': 9
	},
	{
		'lat': 46.30440000000001,
		'lon': 25.290900000000004,
		'name': 'Harghita County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.0479,
		'lon': 21.918900000000004,
		'name': 'Bihor County',
		'iso': 'RO',
		'NumberOfSessions': 5
	},
	{
		'lat': 46.76300664466159,
		'lon': 23.576828962252137,
		'name': 'Cluj County',
		'iso': 'RO',
		'NumberOfSessions': 6
	},
	{
		'lat': 44.9467,
		'lon': 26.013499999999997,
		'name': 'Prahova',
		'iso': 'RO',
		'NumberOfSessions': 4
	},
	{
		'lat': 44.7476,
		'lon': 19.686600000000006,
		'name': 'Mačva',
		'iso': 'RS',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.0177,
		'lon': 20.9222,
		'name': 'Šumadija',
		'iso': 'RS',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.5839,
		'lon': 21.335,
		'name': 'Rasina',
		'iso': 'RS',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.618,
		'lon': 21.172299999999996,
		'name': 'Branicevo',
		'iso': 'RS',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.80459999999996,
		'lon': 20.463699999999992,
		'name': 'Belgrade',
		'iso': 'RS',
		'NumberOfSessions': 45
	},
	{
		'lat': 45.7972,
		'lon': 20.1329,
		'name': 'North Banat',
		'iso': 'RS',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.32610000000001,
		'lon': 21.901400000000006,
		'name': 'Nišava',
		'iso': 'RS',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.37210437128119,
		'lon': 19.901881583436126,
		'name': 'South Bačka',
		'iso': 'RS',
		'NumberOfSessions': 3
	},
	{
		'lat': 44.7539,
		'lon': 20.974599999999995,
		'name': 'South Banat',
		'iso': 'RS',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.955,
		'lon': 85.9532,
		'name': 'Altai',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.62645510892273,
		'lon': 104.30153209337473,
		'name': 'Irkutsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 16
	},
	{
		'lat': 59.1743584469412,
		'lon': 38.9022571800125,
		'name': 'Vologda Oblast',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 57.05851497160592,
		'lon': 41.00735866437858,
		'name': 'Ivanovo Oblast',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 54.7609,
		'lon': 32.12539999999999,
		'name': 'Smolensk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 8
	},
	{
		'lat': 51.82570000179143,
		'lon': 107.61824995815363,
		'name': 'Buryatiya Republic',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 59.56860000000001,
		'lon': 150.8133,
		'name': 'Magadan Oblast',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.276320390698174,
		'lon': 45.40141759322311,
		'name': 'Mordoviya Republic',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 55.017262076376845,
		'lon': 73.38054964254214,
		'name': 'Omsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 13
	},
	{
		'lat': 55.61724483357884,
		'lon': 65.59306008775062,
		'name': 'Kurgan Oblast',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 43.09453310986101,
		'lon': 132.20288743769373,
		'name': 'Primorye',
		'iso': 'RU',
		'NumberOfSessions': 19
	},
	{
		'lat': 54.14953504370425,
		'lon': 37.708149516321456,
		'name': 'Tula Oblast',
		'iso': 'RU',
		'NumberOfSessions': 14
	},
	{
		'lat': 59.93985123809456,
		'lon': 30.298589685108478,
		'name': 'St.-Petersburg',
		'iso': 'RU',
		'NumberOfSessions': 202
	},
	{
		'lat': 55.66226963388151,
		'lon': 49.94480602198263,
		'name': 'Tatarstan Republic',
		'iso': 'RU',
		'NumberOfSessions': 24
	},
	{
		'lat': 61.16010901981025,
		'lon': 74.31989222693804,
		'name': 'Khanty-Mansia',
		'iso': 'RU',
		'NumberOfSessions': 14
	},
	{
		'lat': 56.68824711180927,
		'lon': 35.436020309782634,
		'name': 'Tver Oblast',
		'iso': 'RU',
		'NumberOfSessions': 7
	},
	{
		'lat': 51.75748700232966,
		'lon': 55.35111950361891,
		'name': 'Orenburg Oblast',
		'iso': 'RU',
		'NumberOfSessions': 9
	},
	{
		'lat': 56.33693092525899,
		'lon': 43.98967994653164,
		'name': 'Nizhny Novgorod Oblast',
		'iso': 'RU',
		'NumberOfSessions': 40
	},
	{
		'lat': 53.246745063540715,
		'lon': 50.011147193586766,
		'name': 'Samara Oblast',
		'iso': 'RU',
		'NumberOfSessions': 39
	},
	{
		'lat': 47.27836197331663,
		'lon': 39.59516285948311,
		'name': 'Rostov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 28
	},
	{
		'lat': 58.51100000000001,
		'lon': 31.3251,
		'name': 'Novgorod Oblast',
		'iso': 'RU',
		'NumberOfSessions': 6
	},
	{
		'lat': 53.75906198325209,
		'lon': 91.39038846726214,
		'name': 'Khakasiya Republic',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 54.72148711939433,
		'lon': 55.739402477881235,
		'name': 'Bashkortostan Republic',
		'iso': 'RU',
		'NumberOfSessions': 27
	},
	{
		'lat': 52.7324,
		'lon': 41.4313,
		'name': 'Tambov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.67249334036371,
		'lon': 36.09453839184436,
		'name': 'Kursk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 9
	},
	{
		'lat': 58.599688212112724,
		'lon': 49.74544253576601,
		'name': 'Kirov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 7
	},
	{
		'lat': 44.96130374492449,
		'lon': 42.06175089628453,
		'name': 'Stavropol Kray',
		'iso': 'RU',
		'NumberOfSessions': 14
	},
	{
		'lat': 54.93346431289667,
		'lon': 83.01270038034112,
		'name': 'Novosibirsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 48
	},
	{
		'lat': 61.901925240427104,
		'lon': 126.68674026728323,
		'name': 'Sakha',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 57.718286287318065,
		'lon': 39.36577642162651,
		'name': 'Yaroslavl Oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 61.7788,
		'lon': 34.3606,
		'name': 'Karelia',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.56206925158089,
		'lon': 46.017985221201656,
		'name': 'Saratov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 18
	},
	{
		'lat': 46.94840000000001,
		'lon': 142.7465,
		'name': 'Sakhalin Oblast',
		'iso': 'RU',
		'NumberOfSessions': 8
	},
	{
		'lat': 46.307500000000005,
		'lon': 44.248099999999994,
		'name': 'Kalmykiya Republic',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 65.35670722145925,
		'lon': 76.32976078242048,
		'name': 'Yamalo-Nenets',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 56.9207059777157,
		'lon': 60.573118805914405,
		'name': 'Sverdlovsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 61
	},
	{
		'lat': 56.1713,
		'lon': 47.28350000000001,
		'name': 'Chuvash Republic',
		'iso': 'RU',
		'NumberOfSessions': 11
	},
	{
		'lat': 69.04910749242528,
		'lon': 33.5238144221196,
		'name': 'Murmansk',
		'iso': 'RU',
		'NumberOfSessions': 11
	},
	{
		'lat': 50.2828,
		'lon': 127.5299,
		'name': 'Amur Oblast',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 52.89278575496057,
		'lon': 36.15938209123654,
		'name': 'Oryol oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 48.7844482182296,
		'lon': 44.616048347201804,
		'name': 'Volgograd Oblast',
		'iso': 'RU',
		'NumberOfSessions': 19
	},
	{
		'lat': 54.27839074025766,
		'lon': 34.972562882093705,
		'name': 'Kaluga Oblast',
		'iso': 'RU',
		'NumberOfSessions': 12
	},
	{
		'lat': 54.76301834380671,
		'lon': 86.48448705900628,
		'name': 'Kemerovo Oblast',
		'iso': 'RU',
		'NumberOfSessions': 27
	},
	{
		'lat': 54.741476665908294,
		'lon': 20.791605257156924,
		'name': 'Kaliningrad Oblast',
		'iso': 'RU',
		'NumberOfSessions': 7
	},
	{
		'lat': 45.03453251991617,
		'lon': 38.83641770438981,
		'name': 'Krasnodar Krai',
		'iso': 'RU',
		'NumberOfSessions': 53
	},
	{
		'lat': 54.32049999999999,
		'lon': 48.44679999999999,
		'name': 'Ulyanovsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 15
	},
	{
		'lat': 55.081326253766846,
		'lon': 61.170644551560684,
		'name': 'Chelyabinsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 52
	},
	{
		'lat': 52.590661113008224,
		'lon': 39.50297814510861,
		'name': 'Lipetsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 11
	},
	{
		'lat': 56.18489838212485,
		'lon': 40.52210374586706,
		'name': 'Vladimir Oblast',
		'iso': 'RU',
		'NumberOfSessions': 14
	},
	{
		'lat': 50.555851127046026,
		'lon': 36.789579791442286,
		'name': 'Belgorod Oblast',
		'iso': 'RU',
		'NumberOfSessions': 11
	},
	{
		'lat': 58.11020620649544,
		'lon': 56.24346648913765,
		'name': 'Perm Krai',
		'iso': 'RU',
		'NumberOfSessions': 31
	},
	{
		'lat': 57.30879589790667,
		'lon': 92.25700167885302,
		'name': 'Krasnoyarsk Krai',
		'iso': 'RU',
		'NumberOfSessions': 31
	},
	{
		'lat': 44.936585572807346,
		'lon': 39.57914611748001,
		'name': 'Adygeya Republic',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.7214,
		'lon': 94.4553,
		'name': 'Republic of Tyva',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 55.74774801623765,
		'lon': 37.61743898569897,
		'name': 'Moscow',
		'iso': 'RU',
		'NumberOfSessions': 432
	},
	{
		'lat': 51.648008664675835,
		'lon': 39.20195756253494,
		'name': 'Voronezh Oblast',
		'iso': 'RU',
		'NumberOfSessions': 22
	},
	{
		'lat': 46.346471437983,
		'lon': 48.04029992943585,
		'name': 'Astrakhan Oblast',
		'iso': 'RU',
		'NumberOfSessions': 7
	},
	{
		'lat': 64.55210032167531,
		'lon': 40.277735222884615,
		'name': 'Arkhangelskaya',
		'iso': 'RU',
		'NumberOfSessions': 17
	},
	{
		'lat': 56.503329466782496,
		'lon': 84.97873705809023,
		'name': 'Tomsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 10
	},
	{
		'lat': 66.36873596669821,
		'lon': 60.66943070007291,
		'name': 'Komi',
		'iso': 'RU',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.161782565982136,
		'lon': 34.09787006569096,
		'name': 'Bryansk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 9
	},
	{
		'lat': 53.22149356327991,
		'lon': 84.03958470400184,
		'name': 'Altai Krai',
		'iso': 'RU',
		'NumberOfSessions': 12
	},
	{
		'lat': 55.715951321144516,
		'lon': 37.772228898634836,
		'name': 'Moscow Oblast',
		'iso': 'RU',
		'NumberOfSessions': 76
	},
	{
		'lat': 57.768600000000006,
		'lon': 40.9352,
		'name': 'Kostroma Oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.19500000000001,
		'lon': 44.9945,
		'name': 'Penza Oblast',
		'iso': 'RU',
		'NumberOfSessions': 6
	},
	{
		'lat': 56.6376,
		'lon': 47.905,
		'name': 'Mariy-El Republic',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 43.034400000000005,
		'lon': 44.67340000000001,
		'name': 'North Ossetia–Alania',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 57.00989066792161,
		'lon': 53.1627376816741,
		'name': 'Udmurtiya Republic',
		'iso': 'RU',
		'NumberOfSessions': 16
	},
	{
		'lat': 54.6161,
		'lon': 39.73760000000001,
		'name': 'Ryazan Oblast',
		'iso': 'RU',
		'NumberOfSessions': 7
	},
	{
		'lat': 57.90903170031484,
		'lon': 28.357734927827398,
		'name': 'Pskov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 57.1615,
		'lon': 65.5346,
		'name': 'Tyumen Oblast',
		'iso': 'RU',
		'NumberOfSessions': 15
	},
	{
		'lat': 52.035453100381446,
		'lon': 113.54387148889305,
		'name': 'Transbaikal Territory',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 48.46269999999999,
		'lon': 135.0551,
		'name': 'Khabarovsk',
		'iso': 'RU',
		'NumberOfSessions': 10
	},
	{
		'lat': 59.78777189243518,
		'lon': 30.370014808360278,
		'name': 'Leningrad Oblast',
		'iso': 'RU',
		'NumberOfSessions': 14
	},
	{
		'lat': 55.78842341607882,
		'lon': 13.067272386605023,
		'name': 'Skåne County',
		'iso': 'SE',
		'NumberOfSessions': 16
	},
	{
		'lat': 58.498086267577115,
		'lon': 15.916806588671239,
		'name': 'Östergötland County',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 60.67410000000001,
		'lon': 17.1483,
		'name': 'Gävleborg County',
		'iso': 'SE',
		'NumberOfSessions': 3
	},
	{
		'lat': 63.893930495515384,
		'lon': 19.09070278988085,
		'name': 'Västerbotten County',
		'iso': 'SE',
		'NumberOfSessions': 3
	},
	{
		'lat': 59.499430435464895,
		'lon': 13.369697219341663,
		'name': 'Värmland County',
		'iso': 'SE',
		'NumberOfSessions': 3
	},
	{
		'lat': 59.218441816602386,
		'lon': 15.174062231710021,
		'name': 'Örebro County',
		'iso': 'SE',
		'NumberOfSessions': 5
	},
	{
		'lat': 59.2808088854133,
		'lon': 16.694126044537786,
		'name': 'Södermanland County',
		'iso': 'SE',
		'NumberOfSessions': 4
	},
	{
		'lat': 57.07816373731843,
		'lon': 12.47065415742498,
		'name': 'Halland County',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 59.644886435640956,
		'lon': 16.565591976323383,
		'name': 'Västmanland County',
		'iso': 'SE',
		'NumberOfSessions': 6
	},
	{
		'lat': 60.49326122718366,
		'lon': 15.39987423194123,
		'name': 'Dalarna County',
		'iso': 'SE',
		'NumberOfSessions': 4
	},
	{
		'lat': 57.82695006293134,
		'lon': 14.141596598549638,
		'name': 'Jönköping',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 62.544271553699325,
		'lon': 17.60182686558014,
		'name': 'Västernorrland County',
		'iso': 'SE',
		'NumberOfSessions': 7
	},
	{
		'lat': 65.77802463870377,
		'lon': 21.98763900702448,
		'name': 'Norrbotten County',
		'iso': 'SE',
		'NumberOfSessions': 20
	},
	{
		'lat': 57.97857203319977,
		'lon': 12.48769298543126,
		'name': 'Västra Götaland County',
		'iso': 'SE',
		'NumberOfSessions': 34
	},
	{
		'lat': 59.32513363929746,
		'lon': 18.011365072340766,
		'name': 'Stockholm County',
		'iso': 'SE',
		'NumberOfSessions': 65
	},
	{
		'lat': 56.85275982436885,
		'lon': 14.376036603634047,
		'name': 'Kronoberg County',
		'iso': 'SE',
		'NumberOfSessions': 6
	},
	{
		'lat': 56.1666,
		'lon': 14.862200000000001,
		'name': 'Blekinge County',
		'iso': 'SE',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.6591,
		'lon': 16.3569,
		'name': 'Kalmar',
		'iso': 'SE',
		'NumberOfSessions': 1
	},
	{
		'lat': 60.03632686830263,
		'lon': 17.734901423243596,
		'name': 'Uppsala County',
		'iso': 'SE',
		'NumberOfSessions': 7
	},
	{
		'lat': 46.374599999999994,
		'lon': 15.084199999999996,
		'name': 'Velenje',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.228600000000014,
		'lon': 15.257700000000002,
		'name': 'Celje',
		'iso': 'SI',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.2285,
		'lon': 14.6132,
		'name': 'Kamnik',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.181300000000014,
		'lon': 13.737100000000003,
		'name': 'Občina Tolmin',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.555400000000006,
		'lon': 15.6465,
		'name': 'Maribor City Municipality',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.3932,
		'lon': 15.566000000000003,
		'name': 'Slovenska Bistrica',
		'iso': 'SI',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.05029999999999,
		'lon': 14.504599999999988,
		'name': 'Ljubljana',
		'iso': 'SI',
		'NumberOfSessions': 24
	},
	{
		'lat': 46.2383,
		'lon': 14.3524,
		'name': 'Kranj',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.368,
		'lon': 14.1074,
		'name': 'Občina Bled',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.65059999999998,
		'lon': 16.291999999999994,
		'name': 'Lendava',
		'iso': 'SI',
		'NumberOfSessions': 15
	},
	{
		'lat': 46.1948,
		'lon': 14.5573,
		'name': 'Komenda',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.4171,
		'lon': 15.8684,
		'name': 'Ptuj',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.82003328310319,
		'lon': 18.316675284507987,
		'name': 'Trenčín Region',
		'iso': 'SK',
		'NumberOfSessions': 4
	},
	{
		'lat': 48.33801468453646,
		'lon': 17.646642527403852,
		'name': 'Trnava Region',
		'iso': 'SK',
		'NumberOfSessions': 13
	},
	{
		'lat': 48.25955978232294,
		'lon': 18.211437092607685,
		'name': 'Nitra Region',
		'iso': 'SK',
		'NumberOfSessions': 3
	},
	{
		'lat': 49.09425666222494,
		'lon': 21.128117639158717,
		'name': 'Prešov Region',
		'iso': 'SK',
		'NumberOfSessions': 4
	},
	{
		'lat': 48.7315334473546,
		'lon': 21.25986840594669,
		'name': 'Košice Region',
		'iso': 'SK',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.15720627324039,
		'lon': 17.102651189758685,
		'name': 'Bratislava Region',
		'iso': 'SK',
		'NumberOfSessions': 61
	},
	{
		'lat': 48.738600000000005,
		'lon': 19.1477,
		'name': 'Banská Bystrica Region',
		'iso': 'SK',
		'NumberOfSessions': 3
	},
	{
		'lat': 49.20987528103702,
		'lon': 18.89025846399519,
		'name': 'Žilina Region',
		'iso': 'SK',
		'NumberOfSessions': 15
	},
	{
		'lat': 14.368504605148427,
		'lon': 100.56308601815226,
		'name': 'Phra Nakhon Si Ayutthaya',
		'iso': 'TH',
		'NumberOfSessions': 3
	},
	{
		'lat': 13.745757684948092,
		'lon': 100.50387279813158,
		'name': 'Bangkok',
		'iso': 'TH',
		'NumberOfSessions': 69
	},
	{
		'lat': 13.0508,
		'lon': 100.9367,
		'name': 'Chon Buri',
		'iso': 'TH',
		'NumberOfSessions': 5
	},
	{
		'lat': 7.95,
		'lon': 98.3564,
		'name': 'Phuket',
		'iso': 'TH',
		'NumberOfSessions': 2
	},
	{
		'lat': 14.802500000000007,
		'lon': 100.6181,
		'name': 'Lopburi',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 13.919499999999996,
		'lon': 102.018,
		'name': 'Sa Kaeo',
		'iso': 'TH',
		'NumberOfSessions': 2
	},
	{
		'lat': 15.116300000000003,
		'lon': 104.1456,
		'name': 'Si Sa Ket',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 16.0147,
		'lon': 103.215,
		'name': 'Maha Sarakham',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 18.792899999999996,
		'lon': 99.0004,
		'name': 'Chiang Mai',
		'iso': 'TH',
		'NumberOfSessions': 8
	},
	{
		'lat': 16.2912,
		'lon': 102.6162,
		'name': 'Khon Kaen',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 14.879799999999994,
		'lon': 103.4925,
		'name': 'Surin',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 13.866700000000002,
		'lon': 100.1917,
		'name': 'Nakhon Pathom',
		'iso': 'TH',
		'NumberOfSessions': 2
	},
	{
		'lat': 12.6774,
		'lon': 101.2576,
		'name': 'Rayong',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 15.0598,
		'lon': 102.2215,
		'name': 'Nakhon Ratchasima',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 7.020200000000002,
		'lon': 100.4718,
		'name': 'Songkhla',
		'iso': 'TH',
		'NumberOfSessions': 2
	},
	{
		'lat': 13.599999999999996,
		'lon': 100.7164,
		'name': 'Samut Prakan',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 14.071200000000003,
		'lon': 100.6549,
		'name': 'Pathum Thani',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.21871804425083,
		'lon': 28.953649277433804,
		'name': 'Bursa Province',
		'iso': 'TR',
		'NumberOfSessions': 9
	},
	{
		'lat': 40.7782,
		'lon': 30.4017,
		'name': 'Sakarya',
		'iso': 'TR',
		'NumberOfSessions': 5
	},
	{
		'lat': 37.2588,
		'lon': 36.3837,
		'name': 'Osmaniye',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.266,
		'lon': 31.728500000000004,
		'name': 'Zonguldak Province',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.89421084780652,
		'lon': 30.683272014275296,
		'name': 'Antalya',
		'iso': 'TR',
		'NumberOfSessions': 9
	},
	{
		'lat': 40.09190000000001,
		'lon': 41.35520000000002,
		'name': 'Erzurum',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.35017829200943,
		'lon': 36.287491044003936,
		'name': 'Hatay',
		'iso': 'TR',
		'NumberOfSessions': 3
	},
	{
		'lat': 38.44201334190006,
		'lon': 27.131127930830047,
		'name': 'İzmir Province',
		'iso': 'TR',
		'NumberOfSessions': 17
	},
	{
		'lat': 37.91575002033139,
		'lon': 32.42185059885925,
		'name': 'Konya',
		'iso': 'TR',
		'NumberOfSessions': 4
	},
	{
		'lat': 38.6645,
		'lon': 27.3294,
		'name': 'Manisa',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.6992,
		'lon': 30.692100000000007,
		'name': 'Afyonkarahisar Province',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.6338,
		'lon': 39.312200000000004,
		'name': 'Elazığ',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.642400000000016,
		'lon': 27.8106,
		'name': 'Balıkesir',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.93048620702745,
		'lon': 32.83159502515678,
		'name': 'Ankara',
		'iso': 'TR',
		'NumberOfSessions': 15
	},
	{
		'lat': 40.77108217349578,
		'lon': 30.100393545138466,
		'name': 'Kocaeli',
		'iso': 'TR',
		'NumberOfSessions': 4
	},
	{
		'lat': 39.7302,
		'lon': 30.534500000000005,
		'name': 'Eskişehir',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.78157401749773,
		'lon': 34.619647672105096,
		'name': 'Mersin',
		'iso': 'TR',
		'NumberOfSessions': 3
	},
	{
		'lat': 41.424099999999996,
		'lon': 33.7322,
		'name': 'Kastamonu',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.2508,
		'lon': 32.6991,
		'name': 'Karabük Province',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.2477,
		'lon': 36.2395,
		'name': 'Samsun',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.4207,
		'lon': 43.2328,
		'name': 'Van',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.2167,
		'lon': 28.3674,
		'name': 'Muğla',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.32025163025689,
		'lon': 38.37675227821965,
		'name': 'Malatya',
		'iso': 'TR',
		'NumberOfSessions': 3
	},
	{
		'lat': 40.8049,
		'lon': 31.606199999999987,
		'name': 'Bolu',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.6767,
		'lon': 26.594400000000004,
		'name': 'Edirne',
		'iso': 'TR',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.6167,
		'lon': 34.6333,
		'name': 'Nevşehir Province',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.04940000000001,
		'lon': 27.3014,
		'name': 'Tekirdağ',
		'iso': 'TR',
		'NumberOfSessions': 2
	},
	{
		'lat': 37.61740000000001,
		'lon': 30.28220000000001,
		'name': 'Burdur',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.831900000000005,
		'lon': 37.9918,
		'name': 'Gaziantep',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.7483,
		'lon': 35.5301,
		'name': 'Kayseri',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.028800308944554,
		'lon': 28.95133618499281,
		'name': 'Istanbul',
		'iso': 'TR',
		'NumberOfSessions': 72
	},
	{
		'lat': 36.96586289589832,
		'lon': 35.29204761065242,
		'name': 'Adana',
		'iso': 'TR',
		'NumberOfSessions': 5
	},
	{
		'lat': 37.86215846800737,
		'lon': 27.79386053476315,
		'name': 'Aydın',
		'iso': 'TR',
		'NumberOfSessions': 2
	},
	{
		'lat': 24.98167322832047,
		'lon': 121.5582137529134,
		'name': 'New Taipei City',
		'iso': 'TW',
		'NumberOfSessions': 9
	},
	{
		'lat': 23.5748,
		'lon': 119.6098,
		'name': 'Penghu County',
		'iso': 'TW',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.14399999999999,
		'lon': 120.6844,
		'name': 'Taichung City',
		'iso': 'TW',
		'NumberOfSessions': 12
	},
	{
		'lat': 24.0759,
		'lon': 120.5657,
		'name': 'Changhua',
		'iso': 'TW',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.754070136793516,
		'lon': 121.0835508107977,
		'name': 'Hsinchu County',
		'iso': 'TW',
		'NumberOfSessions': 2
	},
	{
		'lat': 22.9917,
		'lon': 120.2148,
		'name': 'Tainan',
		'iso': 'TW',
		'NumberOfSessions': 3
	},
	{
		'lat': 24.965985865620773,
		'lon': 121.25601437036372,
		'name': 'Taoyuan',
		'iso': 'TW',
		'NumberOfSessions': 6
	},
	{
		'lat': 22.614799999999995,
		'lon': 120.3139,
		'name': 'Kaohsiung',
		'iso': 'TW',
		'NumberOfSessions': 4
	},
	{
		'lat': 24.5641,
		'lon': 120.8275,
		'name': 'Miaoli',
		'iso': 'TW',
		'NumberOfSessions': 1
	},
	{
		'lat': 25.049410527066996,
		'lon': 121.5325105423679,
		'name': 'Taipei City',
		'iso': 'TW',
		'NumberOfSessions': 19
	},
	{
		'lat': 51.43803590382417,
		'lon': 33.85385369363123,
		'name': 'Sumy',
		'iso': 'UA',
		'NumberOfSessions': 7
	},
	{
		'lat': 50.46057700358238,
		'lon': 30.52938231838247,
		'name': 'Kyiv City',
		'iso': 'UA',
		'NumberOfSessions': 117
	},
	{
		'lat': 48.45443274198181,
		'lon': 22.801037729283177,
		'name': 'Zakarpattia Oblast',
		'iso': 'UA',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.3532861707752,
		'lon': 34.723648818526215,
		'name': 'Dnipropetrovsk Oblast',
		'iso': 'UA',
		'NumberOfSessions': 38
	},
	{
		'lat': 49.4729130703244,
		'lon': 25.435459453446036,
		'name': 'Ternopil Oblast',
		'iso': 'UA',
		'NumberOfSessions': 8
	},
	{
		'lat': 48.42684779259503,
		'lon': 39.34233997137633,
		'name': 'Luhansk',
		'iso': 'UA',
		'NumberOfSessions': 5
	},
	{
		'lat': 48.56952893050078,
		'lon': 32.354960104829885,
		'name': 'Kirovohrad Oblast',
		'iso': 'UA',
		'NumberOfSessions': 10
	},
	{
		'lat': 47.80223583372273,
		'lon': 35.05738034319209,
		'name': 'Zaporizhzhia',
		'iso': 'UA',
		'NumberOfSessions': 9
	},
	{
		'lat': 49.943576280881736,
		'lon': 36.21886250747083,
		'name': 'Kharkivs’ka Oblast’',
		'iso': 'UA',
		'NumberOfSessions': 23
	},
	{
		'lat': 50.29107514786729,
		'lon': 30.37846797103171,
		'name': 'Kyiv Oblast',
		'iso': 'UA',
		'NumberOfSessions': 23
	},
	{
		'lat': 48.99225049342772,
		'lon': 24.72949506867418,
		'name': 'Ivano-Frankivsk Oblast',
		'iso': 'UA',
		'NumberOfSessions': 30
	},
	{
		'lat': 44.9301267745934,
		'lon': 34.1023179246142,
		'name': 'Crimea',
		'iso': 'UA',
		'NumberOfSessions': 14
	},
	{
		'lat': 51.203212135766464,
		'lon': 31.663059929111355,
		'name': 'Chernihiv',
		'iso': 'UA',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.0789617906381,
		'lon': 37.709162976078,
		'name': 'Donetsk',
		'iso': 'UA',
		'NumberOfSessions': 6
	},
	{
		'lat': 44.608900000000006,
		'lon': 33.53,
		'name': 'Sebastopol City',
		'iso': 'UA',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.32554374033725,
		'lon': 31.62408347155256,
		'name': 'Cherkasy Oblast',
		'iso': 'UA',
		'NumberOfSessions': 10
	},
	{
		'lat': 49.80701247206708,
		'lon': 23.963431940689404,
		'name': 'Lviv',
		'iso': 'UA',
		'NumberOfSessions': 22
	},
	{
		'lat': 49.58484462037169,
		'lon': 34.55916217484361,
		'name': 'Poltava Oblast',
		'iso': 'UA',
		'NumberOfSessions': 14
	},
	{
		'lat': 49.409321398980524,
		'lon': 27.02248358288357,
		'name': 'Khmelnytskyi Oblast',
		'iso': 'UA',
		'NumberOfSessions': 3
	},
	{
		'lat': 50.98686456335559,
		'lon': 25.02086095256466,
		'name': 'Volyn',
		'iso': 'UA',
		'NumberOfSessions': 4
	},
	{
		'lat': 49.15495191334174,
		'lon': 28.356854820455244,
		'name': 'Vinnytsia',
		'iso': 'UA',
		'NumberOfSessions': 16
	},
	{
		'lat': 50.6223,
		'lon': 26.239599999999996,
		'name': 'Rivne',
		'iso': 'UA',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.2932,
		'lon': 25.9448,
		'name': 'Chernivtsi',
		'iso': 'UA',
		'NumberOfSessions': 3
	},
	{
		'lat': 50.226195445768646,
		'lon': 28.676462508544127,
		'name': 'Zhytomyr',
		'iso': 'UA',
		'NumberOfSessions': 8
	},
	{
		'lat': 46.308311388068894,
		'lon': 30.429238748387036,
		'name': 'Odessa',
		'iso': 'UA',
		'NumberOfSessions': 33
	},
	{
		'lat': 47.042336625328126,
		'lon': 31.920651595333627,
		'name': 'Mykolaiv',
		'iso': 'UA',
		'NumberOfSessions': 8
	},
	{
		'lat': 44.06395872322824,
		'lon': -98.49957532734561,
		'name': 'South Dakota',
		'iso': 'US',
		'NumberOfSessions': 17
	},
	{
		'lat': 39.65818058882808,
		'lon': -105.10491150507312,
		'name': 'Colorado',
		'iso': 'US',
		'NumberOfSessions': 235
	},
	{
		'lat': 35.41045307237272,
		'lon': -119.31875351836077,
		'name': 'California',
		'iso': 'US',
		'NumberOfSessions': 1264
	},
	{
		'lat': 21.333649279337394,
		'lon': -157.83311212294618,
		'name': 'Hawaii',
		'iso': 'US',
		'NumberOfSessions': 47
	},
	{
		'lat': 44.84511125122225,
		'lon': -122.6274551451756,
		'name': 'Oregon',
		'iso': 'US',
		'NumberOfSessions': 213
	},
	{
		'lat': 43.23840733197438,
		'lon': -71.47127191811394,
		'name': 'New Hampshire',
		'iso': 'US',
		'NumberOfSessions': 34
	},
	{
		'lat': 41.81415637497574,
		'lon': -93.34649803789652,
		'name': 'Iowa',
		'iso': 'US',
		'NumberOfSessions': 95
	},
	{
		'lat': 42.76670890717817,
		'lon': -83.9185571931377,
		'name': 'Michigan',
		'iso': 'US',
		'NumberOfSessions': 286
	},
	{
		'lat': 39.40828478658399,
		'lon': -75.56890239043294,
		'name': 'Delaware',
		'iso': 'US',
		'NumberOfSessions': 28
	},
	{
		'lat': 35.86570007835757,
		'lon': -86.253431258276,
		'name': 'Tennessee',
		'iso': 'US',
		'NumberOfSessions': 194
	},
	{
		'lat': 39.02200134042906,
		'lon': -80.65890307958959,
		'name': 'West Virginia',
		'iso': 'US',
		'NumberOfSessions': 45
	},
	{
		'lat': 37.79877452607709,
		'lon': -85.28970998519722,
		'name': 'Kentucky',
		'iso': 'US',
		'NumberOfSessions': 122
	},
	{
		'lat': 33.96384482738797,
		'lon': -81.09690711026,
		'name': 'South Carolina',
		'iso': 'US',
		'NumberOfSessions': 114
	},
	{
		'lat': 46.69882664324519,
		'lon': -112.01936764607312,
		'name': 'Montana',
		'iso': 'US',
		'NumberOfSessions': 24
	},
	{
		'lat': 40.36211239773909,
		'lon': -82.93043691775998,
		'name': 'Ohio',
		'iso': 'US',
		'NumberOfSessions': 341
	},
	{
		'lat': 41.75415302848186,
		'lon': -71.42416482648188,
		'name': 'Rhode Island',
		'iso': 'US',
		'NumberOfSessions': 30
	},
	{
		'lat': 33.37562412473,
		'lon': -111.90383661175754,
		'name': 'Arizona',
		'iso': 'US',
		'NumberOfSessions': 257
	},
	{
		'lat': 40.460373986726516,
		'lon': -74.42501441606585,
		'name': 'New Jersey',
		'iso': 'US',
		'NumberOfSessions': 214
	},
	{
		'lat': 40.3200897777419,
		'lon': -76.54412319177248,
		'name': 'Pennsylvania',
		'iso': 'US',
		'NumberOfSessions': 350
	},
	{
		'lat': 61.44402098868348,
		'lon': -148.8034543967182,
		'name': 'Alaska',
		'iso': 'US',
		'NumberOfSessions': 21
	},
	{
		'lat': 44.16454304375542,
		'lon': -72.96901439753732,
		'name': 'Vermont',
		'iso': 'US',
		'NumberOfSessions': 14
	},
	{
		'lat': 41.517284190903894,
		'lon': -88.16938204156244,
		'name': 'Illinois',
		'iso': 'US',
		'NumberOfSessions': 388
	},
	{
		'lat': 31.122628989826634,
		'lon': -97.43077078802528,
		'name': 'Texas',
		'iso': 'US',
		'NumberOfSessions': 907
	},
	{
		'lat': 33.26163202274637,
		'lon': -86.79533792335536,
		'name': 'Alabama',
		'iso': 'US',
		'NumberOfSessions': 119
	},
	{
		'lat': 33.61998936286658,
		'lon': -84.07504052427637,
		'name': 'Georgia',
		'iso': 'US',
		'NumberOfSessions': 340
	},
	{
		'lat': 35.46431999868137,
		'lon': -80.02047152214278,
		'name': 'North Carolina',
		'iso': 'US',
		'NumberOfSessions': 317
	},
	{
		'lat': 40.17588874946195,
		'lon': -86.28374580400764,
		'name': 'Indiana',
		'iso': 'US',
		'NumberOfSessions': 165
	},
	{
		'lat': 45.07076212536029,
		'lon': -93.31576117742046,
		'name': 'Minnesota',
		'iso': 'US',
		'NumberOfSessions': 181
	},
	{
		'lat': 41.51032749626177,
		'lon': -72.87522365126432,
		'name': 'Connecticut',
		'iso': 'US',
		'NumberOfSessions': 70
	},
	{
		'lat': 35.72344173599592,
		'lon': -96.96099527623196,
		'name': 'Oklahoma',
		'iso': 'US',
		'NumberOfSessions': 119
	},
	{
		'lat': 40.54741717227441,
		'lon': -111.9185402208643,
		'name': 'Utah',
		'iso': 'US',
		'NumberOfSessions': 192
	},
	{
		'lat': 34.83305217828515,
		'lon': -106.45587515005519,
		'name': 'New Mexico',
		'iso': 'US',
		'NumberOfSessions': 88
	},
	{
		'lat': 47.40591018652648,
		'lon': -121.97625706997574,
		'name': 'Washington',
		'iso': 'US',
		'NumberOfSessions': 331
	},
	{
		'lat': 41.4415046142844,
		'lon': -97.81553727145669,
		'name': 'Nebraska',
		'iso': 'US',
		'NumberOfSessions': 103
	},
	{
		'lat': 38.91205000328338,
		'lon': -77.01453358044196,
		'name': 'District of Columbia',
		'iso': 'US',
		'NumberOfSessions': 28
	},
	{
		'lat': 36.57733296850935,
		'lon': -115.64915341055502,
		'name': 'Nevada',
		'iso': 'US',
		'NumberOfSessions': 100
	},
	{
		'lat': 38.716788331007955,
		'lon': -77.53414426624673,
		'name': 'Virginia',
		'iso': 'US',
		'NumberOfSessions': 965
	},
	{
		'lat': 44.13526428243267,
		'lon': -69.87029834300697,
		'name': 'Maine',
		'iso': 'US',
		'NumberOfSessions': 56
	},
	{
		'lat': 43.853081480478565,
		'lon': -89.06598745633313,
		'name': 'Wisconsin',
		'iso': 'US',
		'NumberOfSessions': 204
	},
	{
		'lat': 30.637935507519543,
		'lon': -91.18692435615834,
		'name': 'Louisiana',
		'iso': 'US',
		'NumberOfSessions': 94
	},
	{
		'lat': 28.048511233083357,
		'lon': -81.83363865738066,
		'name': 'Florida',
		'iso': 'US',
		'NumberOfSessions': 542
	},
	{
		'lat': 39.15264487003356,
		'lon': -76.78256889909787,
		'name': 'Maryland',
		'iso': 'US',
		'NumberOfSessions': 183
	},
	{
		'lat': 35.49244800482643,
		'lon': -93.23693100656504,
		'name': 'Arkansas',
		'iso': 'US',
		'NumberOfSessions': 44
	},
	{
		'lat': 38.613874075398805,
		'lon': -96.21026538564935,
		'name': 'Kansas',
		'iso': 'US',
		'NumberOfSessions': 72
	},
	{
		'lat': 42.250143873134625,
		'lon': -106.81185308690992,
		'name': 'Wyoming',
		'iso': 'US',
		'NumberOfSessions': 23
	},
	{
		'lat': 38.625851156746165,
		'lon': -92.31767343077422,
		'name': 'Missouri',
		'iso': 'US',
		'NumberOfSessions': 175
	},
	{
		'lat': 41.46836761566885,
		'lon': -74.60927399683068,
		'name': 'New York',
		'iso': 'US',
		'NumberOfSessions': 555
	},
	{
		'lat': 32.952924507843406,
		'lon': -89.9775170704258,
		'name': 'Mississippi',
		'iso': 'US',
		'NumberOfSessions': 44
	},
	{
		'lat': 47.66188157425223,
		'lon': -99.22822281892824,
		'name': 'North Dakota',
		'iso': 'US',
		'NumberOfSessions': 23
	},
	{
		'lat': 42.27762539305323,
		'lon': -71.28608649044052,
		'name': 'Massachusetts',
		'iso': 'US',
		'NumberOfSessions': 166
	},
	{
		'lat': 43.60579548132621,
		'lon': -114.90950682068735,
		'name': 'Idaho',
		'iso': 'US',
		'NumberOfSessions': 43
	},
	{
		'lat': 9.3148,
		'lon': -70.6081,
		'name': 'Estado Trujillo',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 9.560600130354178,
		'lon': -69.21044989331975,
		'name': 'Portuguesa',
		'iso': 'VE',
		'NumberOfSessions': 2
	},
	{
		'lat': 10.1825,
		'lon': -68.0172,
		'name': 'Carabobo',
		'iso': 'VE',
		'NumberOfSessions': 7
	},
	{
		'lat': 9.7477,
		'lon': -63.18189999999999,
		'name': 'Monagas',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.180953425468202,
		'lon': -67.49260122827468,
		'name': 'Aragua',
		'iso': 'VE',
		'NumberOfSessions': 2
	},
	{
		'lat': 10.487299999999998,
		'lon': -66.8738,
		'name': 'Distrito Federal',
		'iso': 'VE',
		'NumberOfSessions': 6
	},
	{
		'lat': 10.1369,
		'lon': -64.6864,
		'name': 'Anzoátegui',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 8.6311,
		'lon': -70.2079,
		'name': 'Barinas',
		'iso': 'VE',
		'NumberOfSessions': 2
	},
	{
		'lat': 10.246199999999996,
		'lon': -66.4165,
		'name': 'Miranda',
		'iso': 'VE',
		'NumberOfSessions': 2
	},
	{
		'lat': 10.080799999999996,
		'lon': -69.1297,
		'name': 'Yaracuy',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.066399999999998,
		'lon': -69.3586,
		'name': 'Lara',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.95,
		'lon': -63.849999999999994,
		'name': 'Nueva Esparta',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.54705741299952,
		'lon': -71.53757959197314,
		'name': 'Zulia',
		'iso': 'VE',
		'NumberOfSessions': 6
	},
	{
		'lat': 10.5998,
		'lon': -66.9373,
		'name': 'Vargas',
		'iso': 'VE',
		'NumberOfSessions': 4
	},
	{
		'lat': 8.5997,
		'lon': -71.1445,
		'name': 'Mérida',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 16.0685,
		'lon': 108.22150000000002,
		'name': 'Da Nang',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.822000000000003,
		'lon': 106.62570000000001,
		'name': 'Ho Chi Minh',
		'iso': 'VN',
		'NumberOfSessions': 46
	},
	{
		'lat': 10.344400000000004,
		'lon': 107.0875,
		'name': 'Bà Rịa–Vũng Tàu Province',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.362800000000002,
		'lon': 105.5174,
		'name': 'Đồng Tháp Province',
		'iso': 'VN',
		'NumberOfSessions': 2
	},
	{
		'lat': 12.253900000000003,
		'lon': 109.1832,
		'name': 'Khánh Hòa Province',
		'iso': 'VN',
		'NumberOfSessions': 2
	},
	{
		'lat': 11.170099999999996,
		'lon': 106.6687,
		'name': 'Bình Dương Province',
		'iso': 'VN',
		'NumberOfSessions': 8
	},
	{
		'lat': 12.007300000000003,
		'lon': 107.6903,
		'name': 'Đăk Nông Province',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 20.8559,
		'lon': 106.6697,
		'name': 'Haiphong',
		'iso': 'VN',
		'NumberOfSessions': 2
	},
	{
		'lat': 21.029200000000017,
		'lon': 105.85260000000001,
		'name': 'Hanoi',
		'iso': 'VN',
		'NumberOfSessions': 55
	},
	{
		'lat': 21.5941,
		'lon': 105.8432,
		'name': 'Thái Nguyên Province',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.0359,
		'lon': 105.7808,
		'name': 'Can Tho',
		'iso': 'VN',
		'NumberOfSessions': 5
	},
	{
		'lat': 19.797,
		'lon': 105.7674,
		'name': 'Thanh Hóa Province',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.941300000000004,
		'lon': 106.8221,
		'name': 'Đồng Nai Province',
		'iso': 'VN',
		'NumberOfSessions': 2
	},
	{
		'lat': 22.6652,
		'lon': 106.2541,
		'name': 'Cao Bằng Province',
		'iso': 'VN',
		'NumberOfSessions': 1
	}
];