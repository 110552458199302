import { v4 as uuidv4 } from 'uuid';
import { PersoRuleType } from '../../../../Services/RecommendationRuleCompositionServices';

export class CompositionGroup {
	id: string;
	size: (number | null);
	rules: ComposableGroupItem[];
	error: (ValidationError | null);

	hasFillUpRule() {
		return this.rules.some(x => x.isFillUp);
	}

	updateRule(rule: ComposableGroupItem, property: string, value: boolean | number | string): CompositionGroup {
		return new CompositionGroup(this.id, this.rules.map(r => r.internalId === rule.internalId ? { ...r, [property]: value } : r), this.size, this.error);
	}

	addRule(rule: ComposableGroupItem): CompositionGroup {
		return new CompositionGroup(this.id, [...this.rules, rule], this.size, this.error);
	}

	deleteRule(rule: ComposableGroupItem): CompositionGroup {
		return new CompositionGroup(this.id, this.rules.filter(r => r.internalId !== rule.internalId), this.size, this.error);
	}

	setRules(rules: ComposableGroupItem[]): CompositionGroup {
		return new CompositionGroup(this.id, rules, this.size, this.error);
	}

	setSize(size: (number | null)): CompositionGroup {
		return new CompositionGroup(this.id, this.rules, size, this.error);
	}

	setIsFillUp(rule: ComposableGroupItem, isFillUp: boolean) {
		if (isFillUp) {
			return new CompositionGroup(this.id,
				this.rules.map(r => r.internalId === rule.internalId ? ({ ...r, isFillUp: true }) : ({ ...r, isFillUp: false })), this.size, this.error);
		} else {
			return new CompositionGroup(this.id,
				this.rules.map(r => ({ ...r, isFillUp: false })), this.size, this.error);
		}
	}

	withError(error: ValidationError): CompositionGroup {
		return new CompositionGroup(this.id, this.rules, this.size, error);
	}

	resetError(): CompositionGroup {
		return new CompositionGroup(this.id, this.rules, this.size, null);
	}

	constructor(id: string, rules: ComposableGroupItem[], size: (number | null), error: (ValidationError | null)) {
		this.id = id;
		this.rules = rules;
		this.size = size;
		this.error = error;
	}
}



export type ComposableGroupItem = {
	actualRuleId: (string | null);
	persoRuleType: (PersoRuleType | null);
	internalId: string;
	name: string;
	ruleLimit: (number | null);
	isFillUp: boolean;
	isPersoRule: boolean;
}

export class Composition {

	id: string;
	name: string;
	description: string;
	groups: CompositionGroup[];
	error: ValidationError | null;

	setName(name: string): Composition {
		return new Composition(this.id, name, this.description, this.groups, this.error);
	}
	withError(error: ValidationError): Composition {
		return new Composition(this.id, this.name, this.description, this.groups, error);
	}

	resetError(): Composition {
		return new Composition(this.id, this.name, this.description, this.groups, null);
	}

	withGroups(groups: CompositionGroup[]): Composition {
		return new Composition(this.id, this.name, this.description, groups, this.error);
	}

	updateGroup(groupId: string, update: (group: CompositionGroup) => CompositionGroup): Composition {
		return this.withGroups(this.groups.map(g => g.id == groupId ? update(g) : g));
	}


	constructor(id: string, name: string, description: string, groups: CompositionGroup[], error: ValidationError | null) {
		this.id = id;
		this.name = name;
		this.description = description;
		this.groups = groups;
		this.error = error;
	}
}

export function createDefaultComposition() {
	return new Composition(uuidv4(), 'New Composition', '', [new CompositionGroup(uuidv4(), [], null, null)], null);
}



enum ValidationErrorType {
	GroupSizeIsMissing = 'GroupSizeIsMissing',
	GroupSizeIsSmallerThanRuleSizeMin = 'GroupSizeIsSmallerThanRuleSizeMin',
	CompositionNameIsMissing = 'CompositionNameIsMissing'
}

export type ValidationError = {
	error: ValidationErrorType;
	message: string;
}

function validateGroupForSaving(group: CompositionGroup): CompositionGroup {
	if (group.rules.length === 0) {
		return group.resetError();
	}
	const allRulesHaveLimit = group.rules.every(x => x.ruleLimit !== null);
	if (!allRulesHaveLimit) {
		if (group.size === null) {
			return group.withError({ error: ValidationErrorType.GroupSizeIsMissing, message: 'Group size must be set when a fillup rule is used.' });
		}

		const minSize = group.rules.filter(x => x.ruleLimit).map(x => x.ruleLimit!).reduce((a, b) => a + b);
		if (group.size < minSize) {
			return group.withError({ error: ValidationErrorType.GroupSizeIsSmallerThanRuleSizeMin, message: 'Group size must be higher than the sum of rules sizes.' });
		}

		return group.resetError();
	
	} else {
		const size = group.rules.map(x => x.ruleLimit!).reduce((a, b) => a + b);
		return group.resetError().setSize(size);	
	}
}

export function validateForSaving(composition: Composition): { hasError: boolean, composition: Composition } {

	const validateName = (composition: Composition): { hasError: boolean, composition: Composition } => {
		const hasName = composition.name && composition.name.length > 0;
		if (!hasName)
			return { hasError: true, composition: composition.withError({ error: ValidationErrorType.CompositionNameIsMissing, message: 'Composition name is missing.' }) };
		return { hasError: false, composition };
	};

	const validateGroups = (composition: Composition): { hasError: boolean, composition: Composition } => {
		const validatedGroups = composition.groups.map(validateGroupForSaving);
		const nonEmptyValidatedGroups = validatedGroups.filter(x => x.rules.length > 0);
		const validatedComposition = composition.withGroups(nonEmptyValidatedGroups);
		const hasGroupError = nonEmptyValidatedGroups.some(x => x.error !== null);
		return { hasError: hasGroupError, composition: validatedComposition };
	};

	const step0 = composition.resetError();
	const step1 = validateName(step0);
	const step2 = validateGroups(step1.composition);

	return { hasError: step1.hasError || step2.hasError, composition: step2.composition };
}
