import React,{useState} from 'react';
import InputCustom from '../../../Components/InputCustom';
import Checkbox from '../../../Components/Checkbox';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
import Btn from '../../../Components/Btn';
import {FeedCardHeader} from './FeedCardHeader';
import SectionMessage from '../../../Components/SectionMessage';
export default function CreatePrestashopFeed() {
	const {
		handleChangeSimpleField,
		productFeedObject,
		handleChangeConnexion,
		isEmptyIdentifier,
		handleGoingToMapping,
		messageInfos
	} = useProductFeedEditionContext();

	function handleChangeField(e){
		const value = e.target.value;
		const name = e.target.name;
		handleChangeConnexion(name,value);
	}
	function handleChangeSimpleFieldFn(e){
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name,value);
	}
	return (
		<>
			<FeedCardHeader />
			<InputCustom
				value={productFeedObject?.resourceIdentifier ?? ''}
				type={'text'}
				label={'URL'}
				name={'resourceIdentifier'}
				placeholder="https://exemple.com/feed"
				onChange={(e) => handleChangeSimpleFieldFn(e)}
				fullWidth={true}
				autoFocus={true}
				size="l"
				autoComplete={false}
			/>
			<InputCustom
				value={productFeedObject?.auth?.apiKey ?? ''}
				type={'password'}
				label={'Api key'}
				name={'apiKey'}
				placeholder="4585yw5-54523cx-2125kj"
				onChange={handleChangeField}
				fullWidth={true}
				size="l"
				autoComplete={false}
			/>
			<InputCustom
				value={productFeedObject?.languageId  ?? ''}
				type={'number'}
				label={'Language id'}
				name={'languageId'}
				placeholder="2"
				onChange={handleChangeSimpleFieldFn}
				fullWidth={true}
				size="l"
				autoComplete={false}
			/>
			<div>
				{isEmptyIdentifier && 
				<SectionMessage
					type={messageInfos.type}
					title={messageInfos.title}
					text={messageInfos.text}
					verticalSize="s"
				/>
				}
			</div>
			<div className='mt_30 al_right'>
				<Btn
					onClickFunction={() => handleGoingToMapping()}
					size="l"
					message="Connection"
					color="primary" />
			</div>
		</>	
			
	);
}
