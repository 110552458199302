import React, { createContext, useState, useEffect } from 'react';

export const reportingCampaignContext = createContext();

const reportingCampaignProvider = (props) => {
	const reportingCampagneServices = props.reportingCampagneServices;
	const systemServices = props.systemServices;
	

	const [listAllowedObjectiveAndAttributions, setlistAllowedObjectiveAndAttributions] = useState([]);
	const [secondaryIndicatorToCompareExport, setSecondaryIndicatorToCompareExport] = useState();
	const [indicatorsComput, setIndicatorsComput] = useState();
	const [primaryIndicatorSummaryExport, setPrimaryIndicatorSummaryExport] = useState();
	const [secondaryIndicatorSummaryExport, setSecondaryIndicatorSummaryExport] = useState();
	const [rangeDate, setRangeDate] = useState();
	const [objectiveSelected, setobjectiveSelected] = useState();
	const [objectiveSelectedForDropDown, setobjectiveSelectedForDropDown] = useState();
	const [objectiveSelected2, setobjectiveSelected2] = useState();
	const [interactionSelected, setinteractionSelected ] = useState();
	const [targetSelected, settargetSelected] = useState();
	const [isInteractionMode, setInteractionMode] = useState(false);
	const campaignId = props.campaignId;
	const accountId = props.accountId;

		
	function handleSaveObjectiveAndAttribution(objective, attribution, interactions){
		const newObjectiveAttribution = {
			campaignObjective: objective,
			attributionMode:attribution,
			targetOrInteraction: interactions
			
		};

		reportingCampagneServices.putCampaignObjectifAttribution(accountId, campaignId,newObjectiveAttribution,
			data => {console.log('d', data);
			},
			error => {
				console.error('error', error);
				// setIsRealoading(false);
				systemServices.showError('An error occured while fetching data');
			});
			
	}
	function searchInteractions(label,offset, limit,  callbackSuccess, callbackError) {
		reportingCampagneServices.searchInteractions(accountId, campaignId, label, offset, limit, (data)=>callbackSuccess(data), ()=>callbackError());
	}

	return (
		<reportingCampaignContext.Provider value={{
			secondaryIndicatorToCompareExport,
			setSecondaryIndicatorToCompareExport,
			indicatorsComput,
			setIndicatorsComput,
			primaryIndicatorSummaryExport,
			setPrimaryIndicatorSummaryExport,
			secondaryIndicatorSummaryExport,
			setSecondaryIndicatorSummaryExport,
			rangeDate,
			setRangeDate,
			reportingCampagneServices,
			campaignId,
			accountId,
			setobjectiveSelected,
			objectiveSelected,
			setobjectiveSelected2,
			objectiveSelected2,
			systemServices,
			setlistAllowedObjectiveAndAttributions,
			listAllowedObjectiveAndAttributions,
			handleSaveObjectiveAndAttribution,
			interactionSelected,
			setinteractionSelected,
			isInteractionMode,
			setInteractionMode,
			targetSelected,
			settargetSelected,
			objectiveSelectedForDropDown,
			setobjectiveSelectedForDropDown,
			searchInteractions
		}}>
			{props.children}
		</reportingCampaignContext.Provider>
	);

};
export default reportingCampaignProvider;