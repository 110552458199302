import React from 'react'
import Collapse from '../../../../Components/Collapse';
import DraggableContainer from './DraggableContainer'
import PaginationProductList from './PaginationProductList';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider';

export default function PromotedProducts({
	typeDimensions,
	screenTypeSelected,
}) {

	const {
		promotedProducts,
		changeOrderOfPromotedProduct,
		paginationOptions,
		handleChangePaginationOptions,
		currentPageProductList,
		setCurrentPageProductList,
		setNewPaginationNeed,
		typeCategoriesSecondLevelSelected,
		categorySelected
	} = useRankingContext();

	if (!promotedProducts.pageItems || promotedProducts.pageItems.length === 0) {
		return <></>;
	}

	return <>
		<Collapse label="Promoted products" defaultOpen={true}>
			<DraggableContainer
				dimension="promoted"
				type={screenTypeSelected}
				elementToMap={promotedProducts.pageItems ? promotedProducts.pageItems : []}
				handleOnDragEnd={changeOrderOfPromotedProduct}
			/>
			<PaginationProductList
				categorySelected={categorySelected}
				typeCategoriesSecondLevelSelected={typeCategoriesSecondLevelSelected}
				totalCount={promotedProducts.totalCount || 0}
				pageSize={40}
				paginate={(e) => {
					typeDimensions === "ranking" ?
						handleChangePaginationOptions('ppoffset', e >= 0 ? e : 0) :
						handleChangePagination(e >= 0 ? e : 0);
					setNewPaginationNeed(true);
				}}
				skipIdNumber={paginationOptions.ppoffset}
				currentPage={currentPageProductList}
				setCurrentPage={(e) => setCurrentPageProductList(e)}
			/>
		</Collapse>
	</>
}
