import React, { useContext, useState, useEffect } from 'react';
import './DesignBusinessOperationContainer.css';
import '../../BusinessOperationBanner.css';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import DesignBanner from './DesignBanner';
import UploadContainerWithCloudinary from './UploadContainerWithCloudinary';
import Btn from './../../../../Components/Btn';
import GalleryContainer from './../Customize/GalleryContainer';
import useSort from '../../../../Hooks/useSort';
import Spinner from './../../../../Components/Spinner';
import PopinTransformation from './Transformation/PopinTransformation';
import ResizeBannerWorkflowInstancesList from './ResizeBannerWorkflow/ResizeBannerWorkflowInstancesList';
import VideosContainer from './Videos/VideosContainer';
import AddVideo from './Videos/AddVideo';

export default function DesignBusinessOperationContainer({ accountId, deleteABanner, getOperationById, getAccessToken, businessOperationServices, systemServices
}) {
	const {
		operation,
		isexistant,
		setStepBusinessOperationCreate,
		listBanners,
		isTransformExistingBanner,
		transformationActivate,
		bannerResizeWorkflows,
		navigationDimension,
		addVideoModalIsOpen
	} = useContext(CreateBusinessOperationContext);

	const [modalIsOppen, setModalIsOppen] = useState(false);
	const [cloudinaryFiles, setcloudinaryFiles] = useState();
	const [newBanners, setnewBanners] = useState();
	const [listOfZones, setlistOfZones] = useState([]);
	const [zonesAccountForCreateTransformations, setzonesAccountForCreateTransformations] = useState([]);
	const [currentIndex, setCurrentIndex] = useState();
	const [galleryIsOppen, setGalleryIsOppen] = useState(false);
	const { sortByHeightHook } = useSort();
	const [bannerListSortBySources, setbannerListSortBySources] = useState([]);
	const [listOfBannersForGallery, setlistOfBannersForGallery] = useState([]);

	const [listBanners_HH, setlistBanners_HH] = useState([]);
	const [listBanners_H, setlistBanners_H] = useState([]);
	const [listBanners_S, setlistBanners_S] = useState([]);
	const [listBanners_V, setlistBanners_V] = useState([]);


	function replaceAt(array, index, value) {
		const ret = array.slice(0);
		ret[index] = value;
		return ret;
	}
	const sortBySource = (a, b) => {
		if (a.metaData.sourceId !== null) return -1;
		else return 1;
	};
	useEffect(() => {
		if (listBanners) {
			const newArrayOfSources = listBanners.reduce((acc, curr) => {
				if (curr.metaData && !curr.metaData.hasOwnProperty('sourceId')) {
					let findImageSourceObject = acc.find(source => source.sourceId === 'isNotCloudinaryImage');
					if (!findImageSourceObject) {
						const currentImageSource = {
							sourceId: 'isNotCloudinaryImage',
							images: [curr]
						};
						acc.push(currentImageSource);
					} else {
						const indexOfItem = acc.indexOf(findImageSourceObject);
						findImageSourceObject.images.push(curr);
						findImageSourceObject.images.sort(sortBySource);
						let newArrayOfSrc = replaceAt(acc, indexOfItem, findImageSourceObject);
						acc = newArrayOfSrc;
					}
				}
				else if (curr.metaData && curr.metaData.sourceId !== null && !curr.metaData.transformationSourceId) {
					let findImageSourceObject = acc.find(source => source.sourceId === curr.metaData.sourceId);
					if (!findImageSourceObject) {
						const currentImageSource = {
							sourceId: curr.metaData.sourceId,
							images: [curr]
						};
						acc.push(currentImageSource);
					} else {
						const indexOfItem = acc.indexOf(findImageSourceObject);
						findImageSourceObject.images.push(curr);
						findImageSourceObject.images.sort(sortBySource);
						let newArrayOfSrc = replaceAt(acc, indexOfItem, findImageSourceObject);
						acc = newArrayOfSrc;
					}
				}
				else if (curr.metaData && curr.metaData.sourceId === null && curr.metaData.transformationSourceId) {
					let findImageSourceObject = acc.find(source => source.sourceId === curr.metaData.transformationSourceId);
					if (!findImageSourceObject) {
						const currentImageSource = {
							sourceId: curr.metaData.transformationSourceId,
							images: [curr]
						};
						acc.push(currentImageSource);
					} else {
						const indexOfItem = acc.indexOf(findImageSourceObject);
						findImageSourceObject.images.push(curr);
						findImageSourceObject.images.sort(sortBySource);
						let newArrayOfSrc = replaceAt(acc, indexOfItem, findImageSourceObject);
						acc = newArrayOfSrc;
					}
				}
				return acc;
			}, []);
			const sortByAsc = (a, b) => {
				if (a.sourceId.toUpperCase() < b.sourceId.toUpperCase()) return -1;
				if (a.sourceId.toUpperCase() > b.sourceId.toUpperCase()) return 1;
				return 0;
			};
			setbannerListSortBySources(newArrayOfSources.sort(sortByAsc));
			// const newArrayofImages = newArrayOfSources.sort(sortByAsc).reduce((acc, curr) => {
			// 	acc.push(...curr.images);
			// 	return acc
			// }, [])
			// setlistOfBannersForGallery(newArrayofImages)


			let listBanners_HH1 = [];
			let listBanners_H1 = [];
			let listBanners_S1 = [];
			let listBanners_V1 = [];
			listBanners.forEach(item => {
				if (item.bannerType === 'Slider' || item.bannerType === 'VideoPlayer') {
					return;
				}
				let ratio = item.metaData.size.width / item.metaData.size.height;
				if (ratio > 4) {
					listBanners_HH1.push(item);
					return;
				}
				if (ratio > 2) {
					listBanners_H1.push(item);
					return;
				}
				if (ratio < 0.5) {
					listBanners_V1.push(item);
					return;
				}
				else {
					listBanners_S1.push(item);
				}
			});
			setlistBanners_HH(listBanners_HH1);
			setlistBanners_H(listBanners_H1);
			setlistBanners_S(listBanners_V1);
			setlistBanners_V(listBanners_S1);

			const tmpImagesForGallery = [];
			listBanners_HH1.forEach(item => {
				tmpImagesForGallery.push(item);
			});
			listBanners_H1.forEach(item => {
				tmpImagesForGallery.push(item);
			});
			listBanners_V1.forEach(item => {
				tmpImagesForGallery.push(item);
			});
			listBanners_S1.forEach(item => {
				tmpImagesForGallery.push(item);
			});
			setlistOfBannersForGallery(tmpImagesForGallery);
		}

	}, [listBanners]);

	// listBanners && listBanners.sort(sortByHeightHook);

	const findPrev = (e) => {
		if (e != undefined) {
			e.preventDefault();
		}
		let current = currentIndex - 1;
		current = current !== -1 ? current : listOfBannersForGallery.length - 1;
		setCurrentIndex(current);
	};
	const findNext = (e) => {
		if (e != undefined) {
			e.preventDefault();
		}
		let current = currentIndex + 1 < listOfBannersForGallery.length ? current = currentIndex + 1 : current = 0;
		setCurrentIndex(current);
	};
	const transformIndex = (e) => {
		listOfBannersForGallery.forEach(item => {
			if (item.bannerId === e) {
				const arrayOfIndex = listOfBannersForGallery.map(x => x.bannerId);
				setCurrentIndex(arrayOfIndex.indexOf(e));
				setGalleryIsOppen(true);
			}
		});
	};
	const handleKeyDown = (e) => {
		e.preventDefault();
		if (e.keyCode === 27) {
			setCurrentIndex();
			setGalleryIsOppen(false);
			return;
		}
		if (e.keyCode === 37) {
			findPrev();
		}
		if (e.keyCode === 39)
			findNext();
	};
	// useEffect(() => {
	// 	let arrayPagesType = []
	// 	businessOperationServices.getPagesTypes(accountId,
	// 		zoneData => {
	// 			zoneData.forEach(item => arrayPagesType.push({ pageType: item.pageType, screenShoot: item.thumbnailPath, name: item.name, devices: item.device }))
	// 			arrayPagesType.forEach(item => {
	// 				businessOperationServices.getZones(accountId, item.pageType,
	// 					newData => {
	// 						setlistOfZones((list => [...list, ...newData.zones]))
	// 					}),
	// 					err => {
	// 						console.log(err);
	// 					}
	// 			})
	// 		},
	// 		error => systemServices.showError("The simplify setup is not finalized, please contact your administrator"))
	// }, []);
	useEffect(() => {
		if (listOfZones) {
			const newArrayOfZones = listOfZones.reduce((acc, curr) => {
				if (curr.zones && curr.zones.length !== 0) {
					curr.zones.forEach(zone => {
						if (zone.zoneExtensions.Coordinates.height != 0 && zone.zoneExtensions.Coordinates.width != 0) {
							acc.push(zone);
						}
					});
				}
				return acc;
			}, []);
			setzonesAccountForCreateTransformations(newArrayOfZones);
		}
	}, [listOfZones]);



	return (
		<>
			<div className="new-module enabledFocus" onKeyDown={(e) => handleKeyDown(e)} tabIndex="0">
				{isexistant ? <h1>Design<span> : {operation.operation.name}</span></h1> : <h1>Design</h1>}
				<PopinTransformation
					modalIsOppen={modalIsOppen}
					setModalIsOppen={(e) => setModalIsOppen(e)}
					businessOperationServices={businessOperationServices}
					systemServices={systemServices}
					cloudinaryFiles={cloudinaryFiles}
					newBanners={newBanners}
					libraryId={operation.operation.libraryId}
					accountId={accountId}
				/>
				<div>
					{isTransformExistingBanner ?
						<div className="messageProcessingTransformations">
							<p>Please wait, we are processing to the transformation of the banner.</p>
							<div className="">
								<Spinner />
							</div>
						</div>
						:
						<UploadContainerWithCloudinary
							accountId={accountId}
							getAccessToken={getAccessToken}
							getOperationById={getOperationById}
							businessOperationServices={businessOperationServices}
							systemServices={systemServices}
							zonesAccountForCreateTransformations={zonesAccountForCreateTransformations}
						/>
					}
				</div>

				{(listOfBannersForGallery && listOfBannersForGallery.length > 0) &&
					<>
						<div className="designSection">
							{
								galleryIsOppen &&
								<GalleryContainer
									closeModal={() => setGalleryIsOppen(false)}
									findPrev={() => findPrev()}
									findNext={() => findNext()}
									src={listOfBannersForGallery[currentIndex] && listOfBannersForGallery[currentIndex].imagePath || null}
									infos={listOfBannersForGallery[currentIndex] || null}
								/>
							}
							{!transformationActivate ? <>
								<h5 className="designSectionTitle">Available banners</h5>
								<div className="designSectionBody">
									<div className="bannersListWrapper">
										<div className="bannersList" >

											{listBanners_HH &&
												<div className="bannersList">
													{listBanners_HH.map((item, index) =>
														<DesignBanner
															banner={item}
															key={item.bannerId}
															deleteABanner={deleteABanner}
															setCurrentIndex={(e) => transformIndex(e)}
															accountId={accountId}
															businessOperationServices={businessOperationServices}
															systemServices={systemServices}
															zonesAccountForCreateTransformations={zonesAccountForCreateTransformations}
														/>
													)}
												</div>
											}
											{listBanners_H &&
												<div className="bannersList">
													{listBanners_H.map((item, index) =>
														<DesignBanner
															banner={item}
															key={item.bannerId}
															deleteABanner={deleteABanner}
															setCurrentIndex={(e) => transformIndex(e)}
															accountId={accountId}
															businessOperationServices={businessOperationServices}
															systemServices={systemServices}
															zonesAccountForCreateTransformations={zonesAccountForCreateTransformations}
														/>
													)}
												</div>
											}
											{listBanners_S &&
												<div className="bannersList">
													{listBanners_S.map((item, index) =>
														<DesignBanner
															banner={item}
															key={item.bannerId}
															deleteABanner={deleteABanner}
															setCurrentIndex={(e) => transformIndex(e)}
															accountId={accountId}
															businessOperationServices={businessOperationServices}
															systemServices={systemServices}
															zonesAccountForCreateTransformations={zonesAccountForCreateTransformations}
														/>
													)}
												</div>
											}
											{listBanners_V &&
												<div className="bannersList">
													{listBanners_V.map((item, index) =>
														<DesignBanner
															banner={item}
															key={item.bannerId}
															deleteABanner={deleteABanner}
															setCurrentIndex={(e) => transformIndex(e)}
															accountId={accountId}
															businessOperationServices={businessOperationServices}
															systemServices={systemServices}
															zonesAccountForCreateTransformations={zonesAccountForCreateTransformations}
														/>
													)}
												</div>
											}

											{/*{bannerListSortBySources.map((item, index) =>
										<div className="containerSourceImage" key={item.sourceId}>
											<DesignGroupDeclineBanner
												groupImages={item}
												deleteABanner={deleteABanner}
												setCurrentIndex={(e) => transformIndex(e)}
												accountId={accountId}
												businessOperationServices={businessOperationServices}
												systemServices={systemServices}
												zonesAccountForCreateTransformations={zonesAccountForCreateTransformations}
												getTransformationsForbanners={(e) => getTransformationsForbanners(e)}
											/>
										</div>
									)}*/}
										</div>
									</div>
								</div> </> :
								null
							}
						</div>
					</>
				}

				<ResizeBannerWorkflowInstancesList
					accountId={accountId}
					businessOperationServices={businessOperationServices}
					systemServices={systemServices}
				></ResizeBannerWorkflowInstancesList>

				<VideosContainer
					accountId={accountId}
					deleteABanner={deleteABanner}
					getAccessToken={getAccessToken}
					getOperationById={getOperationById}
					businessOperationServices={businessOperationServices}
					systemServices={systemServices}
				/>

				{(listBanners && bannerResizeWorkflows && (listBanners.length > 0 || bannerResizeWorkflows.length > 0)) &&
					<>
						{!transformationActivate && !isTransformExistingBanner &&
							<Btn
								onClickFunction={(e) => { setStepBusinessOperationCreate(4); }}
								message="Next step"
								icon="fas fa-chevron-right"
								iconPosition="after"
								size="l"
							/>
						}
					</>
				}
			</div>

			{addVideoModalIsOpen &&
				<AddVideo
					accountId={accountId}
					businessOperationServices={businessOperationServices}
					systemServices={systemServices}
				/>
			}
		</>
	);
}
