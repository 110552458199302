import React from 'react';
import styles from './BarChart.module.css';
import {IBarChart} from './types';

function BarChart({
	title,
	data,
	height = 100,
	thickness = 16,
	nbDecimal = 0,
	showValue = true
}: IBarChart): JSX.Element {

	function getBarHeightCoef(source) {
		let max = 0;
		for (let index = 0; index < source.length; index++) {
			const bar = source[index];
			if (bar.value > max) {
				max = bar.value;
			}
		}
		return height / max;
	}

	function getRoundedValue(v) {
		let round = 0;
		if (nbDecimal === 0){
			return Math.round(v).toString();
		} else {
			const pow = Math.pow(10, nbDecimal);
			round = Math.round(v * pow) / pow;
			return round.toFixed(nbDecimal).replace('.', ',');
		}
	}

	function getData(source) {
		const data = source.map((s) => {
			return {
				label: s.label,
				value: getRoundedValue(s.value),
				style: {
					height: (s.value * barHeightCoef) + 'px',
					width: thickness + 'px',
					'--barTop': height + 'px'
				}
			};
		});
		return data;
	}

	const barHeightCoef = getBarHeightCoef(data);
	const finalData = getData(data);

	return (
		<>
			{title &&
				<div className={styles.title}>{title}</div>
			}
			<div className={styles.table}>
				{finalData.map(((bar, i) => 
					<div key={i} className={styles.table_col}>
						<div className={styles.bar} style={{height: height}}>
							<div className={styles.bar_inner} style={bar.style} />
						</div>
						{showValue &&
							<div className={styles.value}>{bar.value}</div>
						}
						<div className={styles.label}>{bar.label}</div>
					</div>
				))}
			</div>
		</>
	);
}

export {
	BarChart
};
