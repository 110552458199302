
import { getIndicatorByKey } from './Indicators';

export const formatIndicatorDisplayLabel = (indicator) => {
	const cleanLabel = (label) => {
		return label.includes('evtcpg:') ? cleanInteractionLabel(label) : label.replace('_', ' ');
	};
	return indicator.label ? cleanLabel(indicator.label) : indicator.key;
};


export const cleanInteractionLabel = (label) => {
	if (!label) return '';
	if (label.startsWith('evtcpg:'))
		return label.replace('evtcpg:', '');
	if (label.includes('_evtcpg:')){
		const split = label.split('_evtcpg:');
		const indicator = getIndicatorByKey(split[0]).label;
		return `${split[1]} (${indicator})`;	
	}
	return label;
};