import React, { useEffect, useState } from 'react';
import formatMoment from '../../Constants/FormatMoment';
import useStatusOperationReport from '../../Hooks/useStatusOperationReporting';
import Btn from '../../Components/Btn';
import Dropdown from '../../Components/Dropdown';
import { Trans, useTranslation } from 'react-i18next';

import classes from './EditCampaignTestMode.module.css';
import IpAdressesServices from '../../Services/IpAdressesServices';
import { v4 as uuidv4 } from 'uuid';
import Modal from '../../Components/Modal';
import CampaignStatus from './CampaignStatus';
import PopinConfirm from '../../Components//PopinConfirm';

export default function InformationsOnSiteCampaign(props) {


	const [t, i18n] = useTranslation('common');
	const { formatDateToLocal, getTimeZone } = formatMoment;

	const getEditor = props.getEditor;
	const shouldDisplayEditorLink = props.shouldDisplayEditorLink;
	const shouldDisplayStatus = props.shouldDisplayStatus;
	const shouldDisplayDates = props.shouldDisplayDates;

	let data = props.datainformation && props.datainformation;
	let startDate = data && formatDateToLocal(data.startDate).format('DD/MM/YYYY');
	let endDate = data && data.endDate && formatDateToLocal(data.endDate).format('DD/MM/YYYY');
	let statusOperation = useStatusOperationReport(data);
	const [debugDropdownIsOpen, setDebugDropdownIsOpen] = useState(false);
	const [editDropdownIsOpen, setEditDropdownIsOpen] = useState(false);
	const [clipBoard, setClipBoard] = useState('');

	const [editorLink, setEditorLink] = useState('');
	
	const campaignSelected = props.datainformation && props.datainformation;

	const [ipAddresses, setIpAddresses] = useState([]);
	const [executionModeCampaign, setexecutionModeCampaign] = useState(1);

	const [addNewIpAdress, setAddnewIpAdress] = useState(false);
	const [isLoading, setisLoading] = useState(true);

	const [adressToDelete, setAdressToDelete] = useState();
	const [isEditionMode, setisEditionMode] = useState(false);
	const [currentIpMessage, setcurrentIpMessage] = useState(<></>);
	const [currentIP, setcurrentIP] = useState();

	const [errorRegisterChange, seterrorRegisterChange] = useState(false);
	const [stateOfOperationToValidate, setStateOfOperationToValidate] = useState();
	const [modalTestModeIsOpen, setModalTestModeIsOpen] = useState(false);
	const [editingAdress, setEditingAdress] = useState({
		key: '',
		ipAddress: '',
		label: ''
	});
	const [needRefetch, setneedRefetch] = useState(false);
	useEffect(() => {
		shouldDisplayEditorLink && campaignSelected && getEditor(campaignSelected.id, editor => {
			setEditorLink(goToEdit(editor));
		}, (err) => { });
	}, [campaignSelected, editDropdownIsOpen]);


	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoard(value);
		setTimeout(() => setClipBoard(''), 1500);
	};
	const changeStatusOfCampaign = (bool) => {
		props.setIsLoadingInformationsCampaign(true);
		props.reportingCampagneServices.putStatusCampaign(props.accountId, campaignSelected.id, bool,
			success => {
				props.loadInformationForCampaignWhenChangeId();
				props.systemServices.showSuccess(t('ReportingCampaignContainer.campaignSuccessfully', {
					verb: !bool ? 'activated' : 'desactivated',
					verbFr: !bool ? 'activé' : 'désactivé',
				}));
			},
			error => {
				props.setIsLoadingInformationsCampaign(false);
				props.systemServices.showError(
					t('ReportingCampaignContainer.errorWhile', {
						sentence: !bool ? 'activation of ' : 'desactivation of ',
						sentenceFr: !bool ? 'activation de' : 'désactivation de',
					}
					));
			});
	};

	const goToEdit = (editor) => {
		const hasKa = props.routeParams && props.routeParams.ka;
		if(campaignSelected){
			const getClassic = () => {
				const query = hasKa ? `?ka=${props.routeParams.ka}&ku=${props.routeParams.ku}` : '';
				const url = `/Campaigns/Create/${campaignSelected.id}${query}`;
				return <Btn
					message={t('ReportingCampaignContainer.Edit')}
					href={url}
				/>;
			};
			const getEditor = () => {
				const query = [];
				if (hasKa) {
					query.push('ka=' + props.routeParams.ka);
					query.push('ku=' + props.routeParams.ku);
				}
				const toQueryString = (moreQuery) => '?' + [...query, ...moreQuery].join('&');
				const url = `/Campaigns/Editor/${campaignSelected.id}`;
				
				const isCodePatch = editor && editor == 'patch/code';
				const isGraphPatch = editor && editor == 'patch/graph';
				
				
				
				return <>
					<Dropdown
						isOpen={editDropdownIsOpen}
						setIsOpen={(e) => setEditDropdownIsOpen(true)}
						onHide={(e) => setEditDropdownIsOpen(false)}
						button={
							<Btn
								arrow="true"
								message={t('ReportingCampaignContainer.Edit')}
							/>
						}
					>
						<ul className="listbox">
							<li>
								<a className="listbox_item" href={url + toQueryString(['edit=format'])}>
									{isCodePatch &&
									<>
										<i className="fas fa-code listbox_item_icon"></i>
										{t('ReportingCampaignContainer.editPatch')}
									</>
									}
									{isGraphPatch &&
									<>
										<i className="fas fa-fill-drip listbox_item_icon"></i>
										{t('ReportingCampaignContainer.editGraphic')}
									</>
									}
									{!isCodePatch && !isGraphPatch &&
									<>
										<i className="fas fa-palette listbox_item_icon"></i>
										{t('ReportingCampaignContainer.editDesign')}
									</>
									}
								</a>
							</li>
							<li className="hr"></li>
							<li>
								<a className="listbox_item" href={url + toQueryString(['edit=triggers'])}>
									<i className="fas fa-bolt listbox_item_icon"></i>

									{t('ReportingCampaignContainer.displayRules')}
								</a>
							</li>
							<li className="hr"></li>
							<li>
								<a className="listbox_item" href={url + toQueryString(['edit=info'])}>
									<i className="fas fa-info-circle listbox_item_icon"></i>
									{t('ReportingCampaignContainer.editCampaign')}
								</a>
							</li>
						</ul>
					</Dropdown>
				</>;
			};
			const isClassicEditor = !editor || editor === 'classic';
			return isClassicEditor ? getClassic() : getEditor();
		}
	};
	const ipAdressesServices = new IpAdressesServices(props.$http, props.$routeParams);
	
	const checkMessageForCurrentIpAdress = (ipCurrent) => {
		const ipInList =ipAddresses &&  ipAddresses.find(ip => ip.ipAddress === ipCurrent);
		if (!ipInList) {
			setcurrentIpMessage(
				<p>{t('executionModeCampaign.currentIpNotInList', {ipCurrent: ipCurrent})}</p>
			);
		} else if (ipInList.isDisabled) {
			setcurrentIpMessage(
				<p>{t('executionModeCampaign.ipNotSelected')}</p>
			);
		} else if (!ipInList.isDisabled) {
			setcurrentIpMessage(
				<p>{t('executionModeCampaign.currentIpSelected')}</p>
			);
		}
	};
	const editIpAdress = (ipAdress) => {
		setEditingAdress({...ipAdress});
		setisEditionMode(true);
	};
    
	const handleChangeIpAdresse = (e) => {
		let newObj = { ...editingAdress };
		newObj[e.target.name] = e.target.value;
		setEditingAdress(newObj);
	};
	const createNewAdresse = () => {
		setAddnewIpAdress(true);
		setisEditionMode(true);
		setEditingAdress({
			ipAddress: '',
			label: '',
			isDisabled: false,
			key: uuidv4()
		});
	};
	const cancel = () => {
		setAddnewIpAdress(false);
		setisEditionMode(false);
		setEditingAdress({
			ipAddress: '',
			label: '',
			isDisabled: false,
			key: uuidv4()
		});
	};
	const ValidNewIpAdress = () => {
		setIpAddresses([editingAdress, ...ipAddresses]);
		setAddnewIpAdress(false);
		setisEditionMode(false);
		setEditingAdress({
			ipAddress: '',
			label: '',
			isDisabled: false,
			key: uuidv4()
		});
	};
	const ValidExistantIpAdress = () => {
		const newIpAddresses = ipAddresses.map(x => x.key === editingAdress.key ? editingAdress : x); 
		setIpAddresses(newIpAddresses);
		setisEditionMode(false);
		setEditingAdress({
			ipAddress: '',
			label: '',
			isDisabled: false,
			key: uuidv4()
		});
	};
	const handleChangeAdressIpToList = (e, ip) => {
		const target = e.target;
		const value = target.checked;

		const newIpAddresses = ipAddresses.map(x => x.key === ip.key ? ({...x, isDisabled:!value}) : x); 
		setIpAddresses(newIpAddresses);
	};
	const handleChangeTestMode = (e) => {
		if (typeof e === 'object') {
			setexecutionModeCampaign(e.target.checked ? 2 : 1);
		} else {
			setexecutionModeCampaign(e ? 2 : 1);
		}
	};
	const register = () => {
		setisLoading(true);
		seterrorRegisterChange(false);
		const newIpForAccount =
        {TestModeIpAddresses: ipAddresses.map(ip => ({IpAddress: ip.ipAddress,
        	Label: ip.label,
        		IsDisabled: ip.isDisabled
        	})),
        	key: props.accountId
        };
		ipAdressesServices.putIpAdresses(props.accountId, newIpForAccount,
			success => {
				const newObjectOfTestModeForCampaign = {
					ExecutionMode: executionModeCampaign,
					TestModeIpAddresses: ipAddresses
				};
				ipAdressesServices.putTestModeofCampaignAndSaveIpAdress(props.accountId, campaignSelected.id, newObjectOfTestModeForCampaign,
					success => {
						props.systemServices.showSuccess(t('executionModeCampaign.campagneHasBeenSaved'));
						setTimeout(()=>{
							setisLoading(false);
							setneedRefetch(!needRefetch);
						},1000);
						setModalTestModeIsOpen(false);
					},
					err => {
						props.systemServices.showError(t('executionModeCampaign.errorOccured'));
						setisLoading(false);
					}
				);
			}, err => {
				props.systemServices.showError(t('executionModeCampaign.errorOccured'));
				setisLoading(false);
			});
	};
	const registerWithoutIp = () => {
		const bool = stateOfOperationToValidate == 'stopped' ? true : false;
		const newIpForAccount =
        {TestModeIpAddresses: ipAddresses.map(ip => ({IpAddress: ip.ipAddress,Label: ip.label,IsDisabled: ip.isDisabled})),
        };
		setisLoading(true);
		ipAdressesServices.putIpAdressesAccount(props.accountId, newIpForAccount,
			success => {
				const newObjectOfTestModeForCampaign = {
					ExecutionMode: 1,
					TestModeIpAddresses: ipAddresses
				};
				ipAdressesServices.putTestModeofCampaignAndSaveIpAdress(props.accountId, campaignSelected.id, newObjectOfTestModeForCampaign,
					success => {
						props.reportingCampagneServices.putStatusCampaign(props.accountId, campaignSelected.id, bool,
							success => {
								props.systemServices.showSuccess(t('executionModeCampaign.campagneHasBeenSaved'));
								props.loadInformationForCampaignWhenChangeId();
								setisLoading(false);
								setneedRefetch(!needRefetch);
								
								setModalTestModeIsOpen(false);
							},
							error => {
								props.systemServices.showError(t('executionModeCampaign.errorOccured'));
								setisLoading(false);
							});
					},
					err => {
						props.systemServices.showError(t('executionModeCampaign.errorOccured'));
						setisLoading(false);
					}
				);
			}, err => {
				props.systemServices.showError(t('executionModeCampaign.errorOccured'));
				setisLoading(false);
			});

	};
	const registerTheNewIpAdresses = () => {
		if (isEditionMode) {
			seterrorRegisterChange(true);
		} else {
			register();
		}
	};
	const deleteTheAdress = () => {
		const newIpAddresses = ipAddresses.filter(ip => !(ip.ipAddress === adressToDelete.ipAddress && ip.label === adressToDelete.label));
		setIpAddresses([...newIpAddresses]);		
		setAdressToDelete();
	};
	const handleChangeIpAdresseByCopy = (e) => {
		setEditingAdress(c => ({ 
			...c, ipAddress: e
		}));
	};

	const resetCampaignState = (cpg) => {
		if(cpg.isStopped){
			setStateOfOperationToValidate('stopped');
		}else{
			if(cpg.isTestMode){
				setStateOfOperationToValidate('testMode');
			}else{
				setStateOfOperationToValidate('ongoing');
			}
		}
	}

	useEffect(() => {
		if(currentIP && campaignSelected){
			resetCampaignState(campaignSelected);
			const timer = setTimeout(() => {
				setexecutionModeCampaign(campaignSelected.isTestMode  ? 2 : 1);
				ipAdressesServices.getAccountData(props.accountId,
					newData => {

						const normalizedNewData = newData.TestModeIpAddresses.map(x => ({ key: uuidv4(), ipAddress: x.IpAddress, label: x.Label, isDisabled: true }));
						const allIps = [];				
						for (let i = 0; i < normalizedNewData.length; i++) {
							const el = normalizedNewData[i];
							const elementInAllIps = allIps.find(ipAdd => ipAdd.ipAddress === el.ipAddress && ipAdd.label === el.label);
							if (!elementInAllIps){
								allIps.push(el);
							}
						}

						// for (let i = 0; i < newData.TestModeIpAddresses.length; i++) {
						// 	const el = newData.TestModeIpAddresses[i];
						// 	let elementInAllIps = allIps.find(ipAdd => ipAdd.ipAddress === el.ipAddress && ipAdd.label === el.label);
						// 	if (!elementInAllIps){
						// 		allIps.push({...el, key: uuidv4()});
						// 	} else {
						// 		elementInAllIps.isDisabled = el.isDisabled;
						// 	}
						// }

						setIpAddresses(allIps);
						setisLoading(false);
					}, err => {
						setisLoading(false);
					});
			}, 800);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [currentIP, campaignSelected]);
	useEffect(() => {
		if(currentIP){
			const timer = setTimeout(() => {
				checkMessageForCurrentIpAdress(currentIP);
			}, 800);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [currentIP]);
	useEffect(()=>{
		fetch('https://backoffice.apiv2.beyable.com/api/ip-lookup').then(response => response.json()).then(function (data) {
			setcurrentIP(data.ipAddress);
		});
	},[]);

	return (
		<div className="flex flex_align_start">
			{campaignSelected && <Modal
				isOpen={modalTestModeIsOpen}
				width="700"
				// height='700'
				onClose={() => {setModalTestModeIsOpen(false); resetCampaignState(campaignSelected);}}
			>
				<div className="modal_header has_border">
					{t('executionModeCampaign.startingOpts')}
					<div className="modal_header_desc">{campaignSelected.Name}</div>
				</div>
				<div className="modal_body">
					{adressToDelete &&
                        <PopinConfirm
                        	cancel={() => setAdressToDelete()}
                        	title={t('executionModeCampaign.wantToDelete',{
                        		label :adressToDelete.label,
                        		ipAddress :adressToDelete.ipAddress
                        	})} >
                        	<Btn
                        		onClickFunction={(e) => { setAdressToDelete(); }}
                        		message={t('executionModeCampaign.no')}
                        		color="alert"
                        	/>
                        	<Btn
                        		onClickFunction={deleteTheAdress}
                        		message={t('executionModeCampaign.yes')}
                        		color="primary"
                        	/>
                        </PopinConfirm>}
					{errorRegisterChange &&
                        <PopinConfirm
                        	cancel={() => seterrorRegisterChange(false)}
                        	title={t('executionModeCampaign.saveCurrentChanges')} >
                        	<Btn
                        		onClickFunction={(e) => { seterrorRegisterChange(false); }}
                        		message={t('executionModeCampaign.no')}
                        		color="alert"
                        	/>
                        	<Btn
                        		onClickFunction={() => register()}
                        		message={t('executionModeCampaign.yes')}
                        		color="primary"
                        	/>
                        </PopinConfirm>}
					<article className="radio_box_inner flex_justify_center">
						<label className={stateOfOperationToValidate === 'testMode' ? 'radio_box s checked' : 'radio_box s '}
							onClick={(e) => { setStateOfOperationToValidate('testMode'); handleChangeTestMode(true); }}
						>
							<input
								type="radio"
								value="TEST_MODE"
								className=""
								name="31"
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fa fa-vial"></i>
							<p>{t('executionModeCampaign.Draft')}</p>
						</label>
						<label
							className={stateOfOperationToValidate === 'ongoing' ? 'radio_box s checked' : 'radio_box s'}
							onClick={(e) => { setStateOfOperationToValidate('ongoing'); handleChangeTestMode(true); }}
						>
							<input type="radio"
								value="ONGOING"
								name="33"
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fa fa-play"></i>
							<p>{t('executionModeCampaign.Started')}</p>
						</label>
						<label
							className={stateOfOperationToValidate === 'stopped' ? 'radio_box s checked' : 'radio_box s'}
							onClick={(e) => { setStateOfOperationToValidate('stopped'); handleChangeTestMode(true); }}
						>
							<input
								type="radio"
								value="STOPPED"
								name="32"
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fa fa-pause"></i>
							<p>{t('executionModeCampaign.Stopped')}</p>
						</label>
					</article>
					{stateOfOperationToValidate !== 'testMode' &&
                        <div className="starting_options_message al_center">
                        	{stateOfOperationToValidate === 'stopped' &&
                                t('executionModeCampaign.thisCampaignWillStopped')
                        	}
                        	{stateOfOperationToValidate === 'ongoing' &&
                                 t('executionModeCampaign.campaignOngoing')
                        	}
                        </div>
					}
					{stateOfOperationToValidate && stateOfOperationToValidate == 'testMode' && <>
						<div className="flex flex_align_end mb_20 mt_20">
							<div className="flex_item_full">
								<p>{t('executionModeCampaign.campaignWillBeVisible')}</p>
								{currentIpMessage}
							</div>
							<div className="flex_item_fix ml_20">
								<button className="starting_options_action" onClick={createNewAdresse}>
									<i className="fas fa-plus"></i>
									{t('executionModeCampaign.addNewAddress')}
								</button>
							</div>
						</div>
						<div className="ip_address_table">
							<div className="table-row table-header">
								<p>{t('executionModeCampaign.ipAddresses')}</p>
								<p>{t('executionModeCampaign.Name')}</p>
								<p></p>
							</div>
							{ipAddresses.length == 0 && < div className="starting_options_no_ip">
								{t('executionModeCampaign.noIp')}
							</div>}
							{addNewIpAdress && <div className="table-row table-body editing" >
								<p style={{ display: 'flex', flexDirection: 'column' }}>
									<input type="text" id="ipAddressName"
										name="ipAddress"
										value={editingAdress.ipAddress}
										onChange={(e) => handleChangeIpAdresse(e)}
										autoFocus="" />
									{editingAdress.ipAddress !== currentIP &&
                                        <span className={classes.containerIP}>
                                        	<span>{t('executionModeCampaign.currentIp')}</span>
                                        	<button onClick={(e) => handleChangeIpAdresseByCopy(currentIP)}>{currentIP}< i className="fas fa-level-up-alt"></i></button>
                                        </span>}
								</p>
								<p><input type="text"
									name="label"
									value={editingAdress.label}
									onChange={(e) => handleChangeIpAdresse(e)}
									autoFocus=""
									id="ipAddressLabel" />
								{editingAdress.ipAddress !== currentIP && <span className={classes.containerIP}></span>}
								</p>
								<p className="al_right">
									<a className="starting_options_icon" title="Save"
										onClick={ValidNewIpAdress}>
										<i className="fas fa-save"></i>
									</a>
									<a className="starting_options_icon" title="Cancel"
										onClick={cancel}>
										<i className="fas fa-times"></i>
									</a>
								</p>
							</div>}
							{ipAddresses && ipAddresses.map(adress => 

							{
								return <div className={adress.ipAddress == currentIP ? 'table-row table-body fw_medium' : 'table-row table-body'} key={adress.key}>
									{editingAdress && editingAdress.key === adress.key ? <>
				
										<p>
											<input type="text" id="ipAddressName"
												name="ipAddress"
												value={editingAdress.ipAddress}
												onChange={(e) => handleChangeIpAdresse(e)}
												autoFocus="" />
										</p>
										<p>
											<input type="text" 
												name="label"
												value={editingAdress.label}
												onChange={(e) => handleChangeIpAdresse(e)}
												autoFocus=""
												id="ipAddressLabel" />
										</p>
										<p className="al_right">
											<a className="starting_options_icon" title={t('executionModeCampaign.Save')} onClick={ValidExistantIpAdress}>
												<i className="fas fa-save"></i>
											</a>
											<a className="starting_options_icon" title={t('executionModeCampaign.Cancel')} onClick={cancel}>
												<i className="fas fa-times"></i>
											</a>
										</p>
									</>
										:
										<>
											<p>
												<label className="cb_label flex_inline">
													<input
														type="checkbox"
														name="ipEnabled[]"
														checked={!adress.isDisabled}
														onChange={(e) => handleChangeAdressIpToList(e, adress)} />
													<span className="cb_label_icon flex_item_fix flex_item_align_start"></span>
													<span className="cb_label_text flex_item_auto">
														{adress.ipAddress}
													</span>
												</label>
											</p>
											<p>
												{adress.label}
											</p>
											<p className="al_right">
												{!isEditionMode && !addNewIpAdress && <>
													<a className="starting_options_icon" 
														onClick={() => editIpAdress(adress)} title={t('executionModeCampaign.Edit')}>
														<i className="fas fa-pen"></i>
													</a>
													<a className="starting_options_icon" 
														onClick={() => setAdressToDelete(adress)} title={t('executionModeCampaign.Cancel')}>
														<i className="fas fa-trash-alt"></i>
													</a> </>
												}
											</p>
										</>
									}
								</div>;
							}
							)}
						</div> </>}
				</div>
				<div className="modal_footer modal_footer_center">
					<Btn
						onClickFunction={() => {setModalTestModeIsOpen(false); resetCampaignState(campaignSelected);}}
						message={t('executionModeCampaign.Cancel')}
						color="secondary"
						size="l"
						style="outline"
					/>
					{isEditionMode ?
						<Btn
							disabled={true}
							message={t('executionModeCampaign.Confirm')}
							size="l"
							color="primary"
						/>
						:
						<Btn
							onClickFunction={stateOfOperationToValidate == 'testMode' ?
								() => registerTheNewIpAdresses() :
								() => registerWithoutIp()
							}
							message={t('executionModeCampaign.Confirm')}
							size="l"
							color="primary"
						/>
					}
				</div>

				{isLoading &&
                    <div className="spinner_overlay">
                    	<div className="spinner">
                    		<div className="rect1"></div>
                    		<div className="rect2"></div>
                    		<div className="rect3"></div>
                    		<div className="rect4"></div>
                    		<div className="rect5"></div>
                    	</div>
                    </div>
				}
			</Modal>}
			<div className="flex_item_full">
				<div className="reporting_header">
					{data && data.name &&
						<div>
							<div className="reporting_header_title">
								{shouldDisplayStatus &&
									<span className={'status_bullet ' + statusOperation.cssClass} title={statusOperation.id}></span>
								}
								{data.name}
							</div>
							{shouldDisplayDates &&
								<div className="reporting_header_date">
									<div>
										{startDate}
										<i className="fas fa-arrow-right"></i>
										{endDate ? endDate : '(no end date)'}
									</div>
								</div>
							}
							<div className="reporting_header_id">
								<a className='reporting_header_id_link text_link_hover' onClick={() => copyToClipBoard(data.id)}>
									{data.id}
									{clipBoard && clipBoard == data.id ?
										<i className='fas fa-check'></i>
										:
										<i className='far fa-clipboard'></i>
									}
								</a>
							</div>
							{data.description &&
								<div className="reporting_header_desc">
									{data.description}
								</div>
							}
						</div>
					}
				</div>
			</div>
			<div className="flex_item_fix">
				<div className="btn_group_l">
					{data && data.name &&
						<>
							{shouldDisplayStatus && campaignSelected &&
								<CampaignStatus 
									campaign={campaignSelected}
									accountId={props.accountId}
									IpAdressesServices={ipAdressesServices}
									setModalTestModeIsOpen={(bool, cpg, newStatus) => {
										if (newStatus === 'started') setStateOfOperationToValidate('ongoing');
										setModalTestModeIsOpen(bool);
									}}
									changeStatusOfCampaign={changeStatusOfCampaign}
									systemServices={props.systemServices}
								/>
							}

							{data.debugLinks && data.debugLinks.length == 1 &&
								<span className="btn_switch">
									<Btn
										message="Test"
										icon="fas fa-external-link-alt"
										href={data.debugLinks[0].urlDebugLink}
										target="_blank"
										style="outline"
										color="secondary"
									/>
								</span>
							}

							{data.debugLinks && data.debugLinks.length > 1 &&
								<Dropdown
									isOpen={debugDropdownIsOpen}
									setIsOpen={(e) => setDebugDropdownIsOpen(true)}
									onHide={(e) => setDebugDropdownIsOpen(false)}
									button={
										<Btn
											style="outline"
											color="secondary"
											arrow="true"
											icon="fas fa-external-link-alt"
											message="Test" />
									}
								>
									<ul className="listbox has_border">
										{data.debugLinks.sort((v1, v2) => v1.variation.localeCompare(v2.variation)).map((debug, i) =>
											<li key={i} className="flex">
												<a href={debug.urlDebugLink} target="_blank" className="listbox_item flex_item_full" rel="noreferrer">
													Variation {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'][i]}
												</a>
												<a className="listbox_item listbox_item_right flex_item_fix" onClick={() => copyToClipBoard(debug.urlDebugLink)}>
													<i className="far fa-clipboard"></i>
												</a>
											</li>
										)}
									</ul>
								</Dropdown>
							}

							{/*{(!data.endDate || !moment(data.endDate).isBefore(moment())) && <>
								{data.isStopped ?
									<Btn
										message={t('ReportingCampaignContainer.start')}
										icon="fas fa-play"
										onClickFunction={() => changeStatusOfCampaign(false)}
										style="outline"
										color="secondary"
									/>
									:
									<Btn
										message={t('ReportingCampaignContainer.pause')}
										icon="fas fa-pause"
										onClickFunction={() => changeStatusOfCampaign(true)}
										style="outline"
										color="secondary"
									/>}
							</>
							} */}
							{/*
							{!data.isStopped && !moment(data.endDate).isBefore(moment()) && <>
								{data.isTestMode ?
									<Btn
									message="Live mode"
									icon="fas fa-vial"
									onClickFunction={() => changeStatusTestModeOfCampaign(1)}
									style="outline"
									color="secondary"
									/>
									:
									<Btn
									message="Test mode"
									icon="fas fa-vial"
									onClickFunction={() => changeStatusTestModeOfCampaign(2)}
									style="outline"
									color="secondary"
									/>
								}
								</>
							}
							*/}
							{editorLink}
							{/* <Btn
							message="Permissions"
							onClickFunction={() => console.log('perm')}
							style="outline"
							color="secondary"
							/> */}
						</>
					}
				</div>
			</div>
		</div>
	);
}
