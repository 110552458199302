import HttpServices from './HttpServices';

export default class AuditServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`; //devonly/
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
		this.accountId = accountId;
	}

	getMostRecentRecos(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/scenario-recommendation-requests/most-recent`, callbackSuccess, callbackError);
	}
	getRecoById(taskId,callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/scenario-recommendation-requests/${taskId}`, callbackSuccess, callbackError);
	}
	getNewReco(callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/scenario-recommendation-requests`,null, callbackSuccess, callbackError);
	}

}