import React from 'react';
import AnalitycsBoardItemEcommerce from '../Containers/Ecommerce/AnalitycsBoardItemEcommerce';
import {useEcommerceBuyingbehaviorContext } from '../../context/EcommerceBuyingBehaviorContext';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
export default function AnalitycsBoardBuyingBehavior({ 
	resultName,
	ListItemViewedCount,
	ItemAddedToCartCount,
	CheckoutStartedCount,
	TransactionCount,
	ListItemSelectedCount,
	ListItemViewedCountTooltip,
	ItemAddedToCartCountTooltip,
	CheckoutStartedCountTooltip,
	TransactionCountTooltip,
	ListItemSelectedCountTooltip,
	Revenue,
	RevenueTooltip,
	ctr,
	ctrTooltip,
	data,
	dataSumsMetrics,
	orderBy,
	orderByDesc,
	handleSort,
	handleSelectElem
}) {

	const {
		dimensionId,
	} = useEcommerceBuyingbehaviorContext();

	function getSum() {
		const name = 'Total';
		const metrics = dataSumsMetrics;
		const Revenue = metrics.find(el => el.metric === 'Revenue');
		const ListItemViewedCount = metrics.find(el => el.metric === 'ListItemViewedCount');
		const ListItemSelectedCount = metrics.find(el => el.metric === 'ListItemSelectedCount');
		const ctrCount = ListItemSelectedCount?.value / ListItemViewedCount?.value * 100;
		const ItemAddedToCartCount = metrics.find(el => el.metric === 'ItemAddedToCartCount');
		const CheckoutStartedCount = metrics.find(el => el.metric === 'CheckoutStartedCount');
		const TransactionCount = metrics.find(el => el.metric === 'TransactionCount');
		return (
			<div className="table_row table_body_row table_body_total_row"
				key={uuidv4()}>
				<AnalitycsBoardItemEcommerce
					data={name}
					isHandleClick={false}
				/>
				<AnalitycsBoardItemEcommerce
					data={ListItemViewedCount?.value}
					isHandleClick={false}
					needDevise={false}
					nowrap={true}
				/>
				<AnalitycsBoardItemEcommerce
					data={ListItemSelectedCount?.value}
					isHandleClick={false}
					needDevise={false}
					nowrap={true}
				/>
						
				<AnalitycsBoardItemEcommerce
					data={parseFloat(ctrCount).toFixed(2)}
					isHandleClick={false}
					needDevise={false}
					needPercent={true}
					nowrap={false}
				/>
							
				<AnalitycsBoardItemEcommerce
					data={parseFloat(ItemAddedToCartCount?.value)}
					isHandleClick={false}
					needDevise={false}
					nowrap={true}
				/>
				<AnalitycsBoardItemEcommerce
					data={parseFloat(CheckoutStartedCount?.value)}
					isHandleClick={false}
					needDevise={false}
					nowrap={false}
				/>
				<AnalitycsBoardItemEcommerce
					data={parseFloat(TransactionCount?.value)}
					isHandleClick={false}
					needDevise={false}
					nowrap={false}
				/>
				<AnalitycsBoardItemEcommerce
					data={parseFloat(Revenue?.value).toFixed(2)}
					isHandleClick={false}
					needDevise={true}
					nowrap={false}
				/>
			</div>
		);
	}
	return (
		<div className="table_grid s table_grid_analytics table_grid_analytics_ecom_buy_behaviour">
			<ReactTooltip backgroundColor='black' effect='solid' />
			<div className="table_row table_head_row">
				<div className="table_col">
					<p className=""
						// onClick={() => {
						// 	orderBy === 'ResultName' && orderByDesc === 'desc' ? handleSort('ResultName', 'asc') :
						// 		handleSort('ResultName', 'desc');
						// }}
					>
						{orderBy === 'ResultName' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ResultName' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{resultName}
					</p>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'ListItemViewedCount' && orderByDesc === 'desc' ? handleSort('ListItemViewedCount', 'asc') :
								handleSort('ListItemViewedCount', 'desc');
						}}
					>
						{orderBy === 'ListItemViewedCount' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ListItemViewedCount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{ListItemViewedCount}
						{ListItemViewedCountTooltip &&
							<i className='fas fa-info-circle' data-tip={ListItemViewedCountTooltip}></i>
						}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'ListItemSelectedCount' && orderByDesc === 'desc' ? handleSort('ListItemSelectedCount', 'asc') :
								handleSort('ListItemSelectedCount', 'desc');
						}}
					>
						{orderBy === 'ListItemSelectedCount' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ListItemSelectedCount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{ListItemSelectedCount}
						{ListItemSelectedCountTooltip &&
							<i className='fas fa-info-circle' data-tip={ListItemSelectedCountTooltip}></i>
						}
					</a>
				</div>
				<div className="table_col">
					<p className="">		
						{ctr}
						{ctrTooltip &&
							<i className='fas fa-info-circle' data-tip={ctrTooltip}></i>
						}
					</p>
				</div>
				<div className="table_col">

					<a className="table_sort"
						onClick={() => {
							orderBy === 'ItemAddedToCartCount' && orderByDesc === 'desc' ? handleSort('ItemAddedToCartCount', 'asc') :
								handleSort('ItemAddedToCartCount', 'desc');
						}}
					>
						{orderBy === 'ItemAddedToCartCount' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ItemAddedToCartCount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{ItemAddedToCartCount}
						{ItemAddedToCartCountTooltip &&
							<i className='fas fa-info-circle' data-tip={ItemAddedToCartCountTooltip}></i>
						}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'CheckoutStartedCount' && orderByDesc === 'desc' ? handleSort('CheckoutStartedCount', 'asc') :
								handleSort('CheckoutStartedCount', 'desc');
						}}
					>
						{orderBy === 'CheckoutStartedCount' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'CheckoutStartedCount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{CheckoutStartedCount}
						{CheckoutStartedCountTooltip &&
							<i className='fas fa-info-circle' data-tip={CheckoutStartedCountTooltip}></i>
						}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'TransactionCount' && orderByDesc === 'desc' ? handleSort('TransactionCount', 'asc') :
								handleSort('TransactionCount', 'desc');
						}}
					>
						{orderBy === 'TransactionCount' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'TransactionCount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{TransactionCount}
						{TransactionCountTooltip &&
							<i className='fas fa-info-circle' data-tip={TransactionCountTooltip}></i>
						}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'Revenue' && orderByDesc === 'desc' ? handleSort('Revenue', 'asc') :
								handleSort('Revenue', 'desc');
						}}
					>
						{orderBy === 'Revenue' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'Revenue' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{Revenue}
						{RevenueTooltip &&
							<i className='fas fa-info-circle' data-tip={RevenueTooltip}></i>
						}
					</a>
				</div>
			</div>
			<div>
				{dataSumsMetrics && getSum()
				}
				{data && data.map((elem) => {
					const name = elem?.dimensionLabel || elem?.dimensionId;
					const isSelected = elem?.dimensionId === dimensionId;
					const id = elem?.dimensionId;
					const metrics = elem.metrics;
					const Revenue = metrics.find(el => el.metric === 'Revenue');
					const revenueAverage = parseFloat((Revenue?.value / Revenue?.total) * 100).toFixed(2);
					
					const ListItemViewedCount = metrics.find(el => el.metric === 'ListItemViewedCount');
					const ListItemViewedCountAverage = parseFloat( (ListItemViewedCount?.value / ListItemViewedCount?.total ) * 100).toFixed(2);
					const ListItemSelectedCount = metrics.find(el => el.metric === 'ListItemSelectedCount');
					const ListItemSelectedCountAverage = parseFloat((ListItemSelectedCount?.value / ListItemSelectedCount?.total) * 100).toFixed(2);
					const ctrCount = ListItemSelectedCount?.value / ListItemViewedCount?.value * 100;
					const ItemAddedToCartCount = metrics.find(el => el.metric === 'ItemAddedToCartCount');
					const ItemAddedToCartCountAverage = parseFloat((ItemAddedToCartCount?.value / ItemAddedToCartCount?.total) * 100).toFixed(2);
					const CheckoutStartedCount = metrics.find(el => el.metric === 'CheckoutStartedCount');
					const TransactionCount = metrics.find(el => el.metric === 'TransactionCount');

					return (
						
						<div className="table_row table_body_row"
							onClick={() => handleSelectElem(id, name)}
							key={uuidv4()}
							style={isSelected ? {
								color: 'var(--turquoise)',
								fontWeight: 'bold'
							} : null}
						>
							<AnalitycsBoardItemEcommerce
								data={name}
							/>
							<AnalitycsBoardItemEcommerce
								data={ListItemViewedCount?.value}
								overhead={ListItemViewedCountAverage}
								needDevise={false}
								nowrap={true}
							/>
							<AnalitycsBoardItemEcommerce
								data={ListItemSelectedCount?.value}
								overhead={ListItemSelectedCountAverage}
								needDevise={false}
								nowrap={true}
							/>
						
							<AnalitycsBoardItemEcommerce
								data={parseFloat(ctrCount).toFixed(2)}
								needDevise={false}
								nowrap={false}
								needPercent={true}
							/>
							
							<AnalitycsBoardItemEcommerce
								data={parseFloat(ItemAddedToCartCount?.value)}
								needDevise={false}
								nowrap={true}
							/>
							<AnalitycsBoardItemEcommerce
								data={parseFloat(CheckoutStartedCount?.value)}
								needDevise={false}
								nowrap={false}
							/>
							<AnalitycsBoardItemEcommerce
								data={parseFloat(TransactionCount?.value)}
								needDevise={false}
								nowrap={false}
							/>
							<AnalitycsBoardItemEcommerce
								data={parseFloat(Revenue?.value).toFixed(2)}
								needDevise={true}
								nowrap={false}
							/>
						</div>
					);
				}
				)}
			
			</div>
		</div>
	);
}
