import React, { useContext } from 'react';
import './OutcomeContainer.css';
import basedOnList from './ParamatersList/ListBasedOnFilter';
import { Context } from './Context/Context';
export default function OutcomeContainer(propertyAlgo) {
	const {
		existantAlgorithm,
	} = useContext(Context);
	let outcomeChoiceProduct = '';
	let outcomeChoose = '';
	let outcomeFiltered = '';
	let outcomeBasedOn = '';
	let outcomeConfig = '';
	const prop = propertyAlgo.propertyAlgo;
	switch (prop.strategy) {
	case 'MostAttractive':
		outcomeChoiceProduct = 'Most attractive';
		break;
	case 'MostAddedToCart':
		outcomeChoiceProduct = 'Most added to cart';
		break;
	case 'OftenPurchasedTogether':
		outcomeChoiceProduct = 'Often bought together';
		break;
	case 'MostConverted':
		outcomeChoiceProduct = 'Most converted';
		break;
	case 'MostViewed':
		outcomeChoiceProduct = 'Most viewed';
		break;
	case 'PreviousViewed':
		outcomeChoiceProduct = 'Often viewed together';
		break;
	case 'CustomAssociation':
		outcomeChoiceProduct = 'From/to associations';
		break;
	case 'PassThrough':
		outcomeChoiceProduct = 'Neutral';
		break;
	default:
		outcomeChoiceProduct = '';
	}
	switch (prop.optionChoice) {
	case 'moreAttractivity':
		outcomeChoose = 'More attractivity';
		break;
	case 'lessAttractivity':
		outcomeChoose = 'Less attractivity';
		break;
	default:
		outcomeChoose = `${prop.optionChoice !== undefined ? prop.optionChoice : ''}`;
	}
	switch (existantAlgorithm ? prop.filters && prop.filters[0] && prop.filters[0].type : prop.filters && prop.filters[0].type) {
	case 'TAGS':
		outcomeFiltered = ` ${prop.filters[0].values}`;
		break;
	case 'CATEGORIES':
		outcomeFiltered = ` ${prop.filters[0].values}`;
		break;
	case 'PRODUCTS':
		prop.filters[0].values[0] ?
			outcomeFiltered = `
                    ${ prop.filters[0].values[0].length} ${prop.filters[0].values[0].length > 1 ? 'items' : 'item'}`
		//  ${JSON.stringify(prop.filter.data)}
			: null;
		break;
	default:
		outcomeFiltered = '';
	}
	let ruleScopeKeyToLabel = prop.ruleScope && prop.ruleScope.scopeIdentifier && basedOnList.filter(x => x.key === prop.ruleScope.scopeIdentifier)[0];
	ruleScopeKeyToLabel = ruleScopeKeyToLabel && ruleScopeKeyToLabel.label;
	prop.ruleScope && prop.ruleScope.scopeIdentifier !== undefined ?
		outcomeBasedOn = ` ${ruleScopeKeyToLabel} ` : '';

	prop.config && prop.config.matchingLists ? outcomeConfig = ` ${prop.config.matchingLists.length}` : '';
	return (
		<div className="containerOutcome">
			<h3 className="titleOutcome">Outcome:</h3>
			<ul>
				{outcomeChoiceProduct !== '' ? <li>{outcomeChoiceProduct}</li> : null}
				<li>{outcomeChoose}</li>
				{prop.filters && prop.filters[0] === undefined ? null :
					prop.filters && prop.filters[0].type === 'TAGS' &&
                    <li><span className="matchingListSpan">With the tag :</span> {outcomeFiltered}</li>}

				{prop.filters && prop.filters[0] === undefined ? null :
					prop.filters && prop.filters[0].type === 'CATEGORIES' &&
                    <li><span className="matchingListSpan">Product Feed :</span> {outcomeFiltered}</li>}

				{prop.filters && prop.filters[0] === undefined ? null :
					prop.filters && prop.filters[0].type === 'PRODUCTS' &&
                    <li><span className="matchingListSpan">Sku List :</span>{outcomeFiltered} </li>}

				{outcomeConfig !== '' ? <li> <span className="matchingListSpan"> Matching List :</span>{outcomeConfig}</li> : null}

				{prop.ruleScope && prop.ruleScope.scopeIdentifier !== '' && prop.ruleScope && prop.ruleScope.scopeIdentifier !== undefined ?
					< li > <span className="matchingListSpan">Based on:</span>{outcomeBasedOn}</li> : null}
			</ul>
		</div >
	);
}
