import React from 'react';
import {Panel as NewPanel} from './Panel/Panel';

export default function Panel(props) {

	const noClose = typeof props.noClose !== 'undefined' ? props.noClose : false;
	const hasClose = !noClose;

	return (
		<NewPanel {...props} hasClose={hasClose}>
			{props.children}
		</NewPanel>
	);
}
