
import React, { useEffect, useState } from 'react';
import Switch from '../../../../../Components/Switch';
import SelectCustom from '../../../../../Components/SelectCustom';

const operators = [
	{
		value: 'Equals',
		label: 'True',
	},
	{
		value: 'NotEquals',
		label: 'False',
	}
];
export default function BooleanContainer({
	data,
	handleChangeFilterByUniqueId,
}) {

	const [currentValue, setcurrenValue] = useState(data.value['booleanValue'] || false);
	const [isAnError, setIsAnError] = useState({ isError: false, message: '' });
	const [valueOperatorSelected, setvalueOperatorSelected] = useState();

	const handleChangeValue = () => {
		setcurrenValue(!currentValue);
	};

	const selectOperator = (operator) => {
		setvalueOperatorSelected(operator);
	};
	function getValueOfStringComponent() {
		let valueSimple = data.value.hasOwnProperty('booleanValue');
		if (valueSimple && data.value['booleanValue'] !== null) {
			setcurrenValue(data.value['booleanValue']);
		}
	}


	function handleChangeValuesById() {
		handleChangeFilterByUniqueId({
			idFilter: data.idFilter,
			op: valueOperatorSelected,
			value: { booleanValue: true},
		});


	}
	useEffect(() => {
		selectOperator(data.op || operators[0].value);
		getValueOfStringComponent();
	}, [data]);

	useEffect(() => {
		handleChangeValuesById();
	}, [data, currentValue, valueOperatorSelected]);

	return (
		<div>
			<SelectCustom
				optionsList={operators}
				name="operator"
				onChangeFunction={(e) => selectOperator(e.target.value)}
				value={valueOperatorSelected}
			/>
			<br />
			{/* <Switch name="true" checked={currentValue} value={currentValue} onChange={handleChangeValue} /> */}
		</div>
	);
}
