import React from 'react';
import { fieldsTypesShopify } from '../../utils';
import { useProductFeedEditionContext } from '../../context/ProductFeedEditionContext';
import FieldContainerSimple from './FieldContainerSimple';
import editionStyles from '../ProductFeedEdition.module.css';

export default function SelectedFieldsShopify() {

	const { productFeedObject, handleChangeSelectionForSelectedFields, step } = useProductFeedEditionContext();

	function getFieldsSelected(obj, key) {
		if (!obj?.selectedFields) {
			return false;
		} else {
			if (obj.selectedFields.includes(key)) {
				return true;
			} else return false;
		}
	}
	if (step !== 2) {
		return null;
	}

	return (
		<div className={editionStyles.card_group}>
			<div className={editionStyles.header}>
				<div className={editionStyles.header_title}>Selected fields</div>
				<div className={editionStyles.header_desc}>Select the fields to use in the catalog.</div>
			</div>
			<section>
				{fieldsTypesShopify?.map((type, i) => {
					const isSelected = getFieldsSelected(productFeedObject, type);
					function onChangeSelection(v) {
						handleChangeSelectionForSelectedFields(type, v);
					}
					return <FieldContainerSimple
						key={i}
						name={type}
						label={type}
						onClick={onChangeSelection}
						isSelected={isSelected}
					>
					</FieldContainerSimple>;
				})}
			</section>
		</div>
	);
}
