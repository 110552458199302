import React, { useContext, useState } from 'react';
import { BusinessOperationOrchestrationContext } from './Context';
import { BrowserRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PageTypeScreenshot from './ZoneSelection/PageTypeScreenshot';
import '../Create/Customize/CustomizeContainer.css';
import DeviceSelector from './ZoneSelection/DeviceSelector';
import SetBusinessOperationPrio from './Priorization/SetBusinessOperationPrio';
import AvailableOperations from './Priorization/AvailableOperations';
import PageTypeSelector from './Nav/PageTypeSelector';
import SavePriorizationControls from './Priorization/SavePriorizationControls';
import Panel from '../../../Components/Panel';
import {Article} from '../../../Components/Article/Article';
import './Orchestration.css';
import RouterWrapper from '../RouterWrapper';

export default function OrchestrationContainer({ _ }) {
	const {
		selectedPageType,
		editionModalIsOpen,
		handleCancelPriorization,
		recomputeAllOrchestrations,
		shouldAllowRecomputeAllOrchestrations,
		isSaving,
		isReady,
		operationsInOrchestration,
		availableOperations,
		routeParams
	} = useContext(BusinessOperationOrchestrationContext);

	const [showRecomputeConfirm, setShowRecomputeConfirm] = useState(false);

	const executeRecomputeAllOrchestrations = () => {
		recomputeAllOrchestrations();
	};

	const recomputeOrchestrationButton = () => <>
		{shouldAllowRecomputeAllOrchestrations() &&
			<Btn onClickFunction={() => setShowRecomputeConfirm(true)}
				message="Recompute all"
				color="secondary" />
		}
	</>;

	return (
		<RouterWrapper props={{$routeParams:routeParams}}>
			<div className="page_full section_root flex flex_v vscroll">
				<section className="section no_bottom_pad flex_item_fix section_primary">
					<div className="h1">Orchestration</div>
				</section>
				<section className='section flex_item_full'>
					<Article size='l' fullHeight={true}>
						<div className='flex flex_v simplify_orch_inner'>
							<div className="flex_item_fix simplify_orch_header">
								<div className="flex">
									<div className="flex_item_full simplify_orch_header_left simplify_title_2">Please select zones to orchestrate</div>
									<div className="flex_item_fix simplify_orch_header_center">
										{selectedPageType &&
											<DeviceSelector />
										}
									</div>
									<div className="flex_item_full simplify_orch_header_right"></div>
								</div>
							</div>
							<div className="flex_item_full flex flex_align_stretch overflow_hidden">
								<div className="flex_item_fix simplify_orch_sidebar vscroll_custom">
									<PageTypeSelector />
								</div>
								<div className="flex_item_full simplify_orch_preview vscroll_custom">
									<PageTypeScreenshot />
								</div>
							</div>
						</div>
					</Article>
				</section>

				<Panel
					isOpen={editionModalIsOpen}
					onClose={() => handleCancelPriorization()}
					width={900}
					closeOnOverlay={true}
				>
					<div className="modal_header has_border">
					Operations priority
						<div className="modal_header_desc">
						Select operations and order them by priority
						</div>
					</div>
					<div className="flex_item_full flex flex_v pos_rel overflow_hidden">
						{isReady &&
						<>
							{(availableOperations || []).length === 0 && (operationsInOrchestration || []).length === 0
								?
								<div className="empty_state abs">
									<img className="empty_state_img" src="/Assets/empty_state.svg" />
									<div className="empty_state_title">There is no operation to orchestrate</div>
									<div className="empty_state_text">You should create operations first</div>
								</div>
								:
								<>
									<div className="simplify_orch_selection_header flex_item_fix flex">
										<div>Unselected operations</div>
										<div>Selected operations</div>
									</div>
									<div className="flex_item_full simplify_orch_selection flex flex_align_stretch">
										<div className="simplify_orch_selection_part vscroll_custom">
											<AvailableOperations></AvailableOperations>
										</div>
										<div className="simplify_orch_selection_sep flex_item_fix"></div>
										<div className="simplify_orch_selection_part vscroll_custom">
											<SetBusinessOperationPrio></SetBusinessOperationPrio>
										</div>
									</div>
								</>
							}
						</>
						}
						{!isReady &&
						<div className="mask">
							<span className="wheel"></span>
						</div>
						}
						{isSaving &&
						<div className="mask">
							<span className="wheel"></span>
						</div>
						}
					</div>
					<div className="modal_footer has_border al_right">
						<SavePriorizationControls
							displaySave={(availableOperations || []).length > 0 || (operationsInOrchestration || []).length > 0}
						/>
					</div>
				</Panel>
			</div>
		</RouterWrapper>
	);
}
