import React, { useEffect, useState, Fragment } from 'react';
import PaginationBoardAssociations from './PaginationBoardAssociations';
import ImageOrFallback from '../../../Components/ImageOrFallback';

export default function BoardAssociations({ title, data, totalCount, offset, setOffset, pageSize, setPageSize, getThumbnailUrl }) {
	const [currentPage, setCurrentPage] = useState(1);

	const productItem = (item, i) => (
		<Fragment key={i}>
			<div className="emerch_asso_thumb">
				<ImageOrFallback imageProps={{ width: 140, title: item.value }} src={getThumbnailUrl(item.value)}></ImageOrFallback>
				<div>{item.value}</div>
			</div>
		</Fragment>
	);

	return (
		<>
			<div className="table_grid table_grid_emerch table_grid_asso">
				<div className="table_row table_head_row">
					<div className="table_col">
						<a className="table_sort">{title}</a>
					</div>

					<div className="table_col">
						<a className="table_sort">From items</a>
					</div>
					<div className="table_col">
						<a className="table_sort">To items</a>
					</div>
				</div>

				<div>
					{
						(data || []).map((elem, i) => (
							<div key={i} className="table_row table_body_row">
								<div className="table_col">
									<div className="table_row_name">{elem.name}</div>
								</div>
								<div className="table_col">
									<div>
										{elem.fromItems.map(productItem)}
									</div>
								</div>
								<div className="table_col">
									<div>
										{elem.toItems.map(productItem)}
									</div>
								</div>
							</div>
						))
					}
				</div>
			</div>
			<PaginationBoardAssociations
				totalCount={totalCount}
				pageSize={pageSize}
				handleChangePageSize={(e) => {
					setPageSize(e), setOffset(0);
				}}
				paginate={(e) => setOffset(e >= 0 ? e : 0)}
				skipIdNumber={offset}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
		</>
	);
}
