import React, { useEffect ,useState} from 'react';
import Btn from '../../Components/Btn';

export default function TransformationContainerList(
	{listToMap,title,handleClick,valueSelected
	}) {

	const [list, setList] = useState(<></>);
	useEffect(()=>{
		setList(listToMap.map(el => 
			<Btn
				key={el.key}
				message={el.label}
				onClick={(e)=>handleClick(el.key)}
				isActive={valueSelected === el.key}
				style="outline"
				color="secondary"
				size="s"
			/>
		));
	},[valueSelected]);
	return (
		<div className='form_block l'>
			<div className='form_block_label'>
				<label>{title}</label>
			</div>
			<div className='btn_switch'>
				{list}
			</div>
		</div>
	);
}
