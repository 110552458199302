import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import Panel from '../../Components/Panel';
import Modal from '../../Components/Modal';
import ModalBody from '../../Components/ModalBody';
import ModalFooter from '../../Components/ModalFooter';
import Link from '../../Components/Link';
import Btn from '../../Components/Btn';
import ConditionPanel from './ConditionPanel';
import Checkbox from '../../Components/Checkbox';
import SelectDropdown from '../../Components/SelectDropdown';
import InputCustom from '../../Components/InputCustom';
import EmptyState from '../../Components/EmptyState';
import { useTriggerContext } from './TriggerContext';
import { v4 as uuidv4 } from 'uuid';
import ConditionTriggerCard from './ConditionTriggerCard';
import TargetingRuleContextItem from './TargetingRuleContextItem';
import { compareAllTriggersUnBuild } from './Utils';
import Confirm from '../../Components/Confirm';
import {useTranslation} from 'react-i18next';
import renderJsxTag from '../../Translation/translation-utils';
import useFeature from '../../../flags/useFeature';

export default function TargetingRulePanel(
	{
		panelIsOpen,
		setPanelIsOpen,
		isEditingExistingRule = false,
		ruleId = null,}){
	const {
		pushNewTargetingRule,
		editExistingRule,
		getTargetingRuleById,
		transformRefTrigger,
		refTriggerSet,
		newTriggerSet,
		updateRefSegment,
		updateRefFavorite,
		compareToBaseTrigger,
		deleteTargetingRule,
		isNativeApp
	} = useTriggerContext();
	
	const [t, i18n] = useTranslation('common');
	const restoreOriginalEditingRule = () => {
		const ruleId = uuidv4();
		const ruleName = '';
		return {
			id: ruleId,
			name: ruleName,
			triggers: newTriggerSet(ruleId, ruleName)
		};
	};

	const [conditionPanelIsOpen, setConditionPanelIsOpen] = useState(false);
	const [nameModalIsOpen, setNameModalIsOpen] = useState(false);
	const [actualEditingRule, setActualEditingRule] = useState(
		isEditingExistingRule ?
			getTargetingRuleById(ruleId)
			:
			restoreOriginalEditingRule()
	);

	const [triggerEventType, setTriggerEventType] = useState();
	const [ruleNameInputValue, setRuleNameInputValue] = useState(actualEditingRule.name);
	const [isBaseTrigger, setIsBaseTrigger] = useState(false);
	const [modalBaseTriggerConfirmIsOpen, setModalBaseTriggerConfirmIsOpen] = useState(false);


	useEffect(() => {
		if (actualEditingRule.triggers) {
			const isTriggerEvent = actualEditingRule.triggers.eventTrigger;
			setTriggerEventType(isTriggerEvent ? isTriggerEvent.Name : 'onpageload');

			setIsBaseTrigger(compareToBaseTrigger(actualEditingRule.triggers));
		}
	}, [actualEditingRule]);


	const optionsEventTriggerDropdown = [
		{
			value: 'onpageload',
			label: t('triggers.onPageLoad')
		},
		{
			value: 'TRGEXITINTENT',
			label: t('triggers.onExit')
		},
		{
			value: 'TRGSCROLL',
			label: t('triggers.onScroll')
		},

	];
	const optionsNbrOfTimeTriggerDropdown = [
		{
			value: '1',
			label: '1'
		},
		{
			value: '2',
			label: '2'
		},
		{
			value: '3',
			label: '3'
		},
		{
			value: '4',
			label: '4'
		},
		{
			value: '5',
			label: '5'
		},
		{
			value: '-1',
			label: t('triggers.Unlimited')
		},

	];


	const addTriggerToActualActualEditingRule = (refTrigger, triggerType, value = null) => {
		let newTrigger = transformRefTrigger(refTrigger);
		newTrigger.Group = { Id: actualEditingRule.id, Label: actualEditingRule.name };

		let prevState = { ...actualEditingRule };
		switch (triggerType) {
			case 'condition':
				prevState.triggers.condition.push(newTrigger);
				break;
			case 'delay':
				newTrigger.ValueToCompare = value;
				prevState.triggers.delayTrigger = newTrigger;
				break;
			case 'event':
				prevState.triggers.eventTrigger = newTrigger;
				break;
		}

		setActualEditingRule(prevState);
	};

	const handleChangeDeviceTrigger = (device) => {
		let prevState = { ...actualEditingRule };
		const deviceArray = [...prevState.triggers.deviceTrigger.ValueToCompare];

		const index = deviceArray.findIndex(e => e === device);
		if (deviceArray.includes(device)) {
			deviceArray.splice(index, 1);
		} else {
			deviceArray.push(device);
		}
		prevState.triggers.deviceTrigger.ValueToCompare = deviceArray;
		setActualEditingRule(prevState);
	};

	const deleteTrigger = (triggerIndex) => {
		let prevState = { ...actualEditingRule };
		prevState.triggers.condition.splice(triggerIndex, 1);
		setActualEditingRule(prevState);
	};

	const handleChangeEventTrigger = (event) => {
		let prevState = { ...actualEditingRule };
		if (event === 'onpageload') {
			delete prevState.triggers.eventTrigger;
			setActualEditingRule(prevState);
			setTriggerEventType('onpageload');
		} else if (event === 'TRGEXITINTENT') {
			addTriggerToActualActualEditingRule(refTriggerSet.eventTriggers.exitTrigger, 'event');
		} else if (event === 'TRGSCROLL') {
			addTriggerToActualActualEditingRule(refTriggerSet.eventTriggers.scrollTrigger, 'event');
		}
	};

	const handleChangeDelayTrigger = (event) => {
		const delayValue = event.target.value ? parseInt(event.target.value) : 0;
		if (delayValue === 0 && actualEditingRule.triggers.delayTrigger) {
			let prevState = { ...actualEditingRule };
			delete prevState.triggers.delayTrigger;
			setActualEditingRule(prevState);
		}
		if (delayValue > 0 && !actualEditingRule.triggers.delayTrigger) {
			addTriggerToActualActualEditingRule(refTriggerSet.delayTrigger, 'delay', delayValue);
		} else if (actualEditingRule.triggers.delayTrigger) {
			let prevState = { ...actualEditingRule };
			prevState.triggers.delayTrigger.ValueToCompare = delayValue;
			setActualEditingRule(prevState);
		}
	};

	const handleChangeNbrOfTimeTrigger = (newValue) => {
		let prevState = { ...actualEditingRule };
		prevState.triggers.numberOfTimeTrigger.ValueToCompare = newValue;
		setActualEditingRule(prevState);
	};

	const handleChangeScrollTrigger = (newValue) => {
		let prevState = { ...actualEditingRule };
		prevState.triggers.eventTrigger.ValueToCompare = parseInt(newValue);
		setActualEditingRule(prevState);
	};

	const changeValueConditionTrigger = (index, newValue) => {
		let prevState = { ...actualEditingRule };
		prevState.triggers.condition[index].ValueToCompare = newValue;
		setActualEditingRule(prevState);
	};

	const resetTargetingRuleName = () => {
		setRuleNameInputValue(actualEditingRule.name);
	};


	const addNewTargetingRule = () => {
		let copyOfActualRule = { ...actualEditingRule };
		if (triggerEventType !== 'onpageload') {
			delete copyOfActualRule.triggers.delayTrigger;
		}
		if (copyOfActualRule.name !== ruleNameInputValue) {
			pushNewTargetingRule(updateNewNameForAllTriggersGroup(copyOfActualRule, ruleNameInputValue));
		} else {
			pushNewTargetingRule(copyOfActualRule);
		}
		setPanelIsOpen(false);
		setActualEditingRule(restoreOriginalEditingRule());
	};

	const updateNewNameForAllTriggersGroup = (rule, newName) => {
		for (const [key, value] of Object.entries(rule.triggers)) {
			if (key === 'condition') {
				value.map(trigger => trigger.Group.Label = newName);
			} else {
				value.Group.Label = newName;
			}
		}
		rule.name = newName;
		return rule;
	};

	const saveTargetingRule = () => {
		let copyOfActualRule = { ...actualEditingRule };
		let changeId = false;
		if (triggerEventType !== 'onpageload') {
			delete copyOfActualRule.triggers.delayTrigger;
		}
		if (copyOfActualRule.refSegment) {
			if (!compareAllTriggersUnBuild(copyOfActualRule.triggers, copyOfActualRule.refSegment.triggers)) {
				updateRefSegment(copyOfActualRule.refSegment);
				changeId = true;
				delete copyOfActualRule.refSegment;
				copyOfActualRule.mostPopular && delete copyOfActualRule.mostPopular;
			}
		}
		if (copyOfActualRule.refFavorite) {
			if (!compareAllTriggersUnBuild(copyOfActualRule.triggers, copyOfActualRule.refFavorite.triggers)) {
				updateRefFavorite(copyOfActualRule.refFavorite);
				changeId = true;
				delete copyOfActualRule.refFavorite;
			}
		}
		if (isBaseTrigger) {
			setModalBaseTriggerConfirmIsOpen(true);
			return;
		}
		if (copyOfActualRule.name !== ruleNameInputValue) {
			editExistingRule(updateNewNameForAllTriggersGroup(copyOfActualRule, ruleNameInputValue), changeId);
		} else {
			editExistingRule(copyOfActualRule, changeId);
		}
		setPanelIsOpen(false);
		setActualEditingRule(restoreOriginalEditingRule());
	};

	const checkLastDevice = (device) => {
		const lastDevice = actualEditingRule.triggers.deviceTrigger.ValueToCompare;
		return lastDevice.length === 1 && lastDevice[0] === device;

	};

	const handleChangeOperatorName = (triggerIndex, value) => {
		let prevState = { ...actualEditingRule };
		prevState.triggers.condition[triggerIndex].OperatorName = value;
		setActualEditingRule(prevState);
	};

	const confirmDeleteRuleBaseTrigger = () => {
		deleteTargetingRule(actualEditingRule.id);
		setPanelIsOpen(false);
		setActualEditingRule(restoreOriginalEditingRule());
		setModalBaseTriggerConfirmIsOpen(false);
	};
   
	return (
		<>
			<Panel
				isOpen={panelIsOpen}
				onClose={(e) => setPanelIsOpen(false)}
				noClose={true}
				side="right"
				offsetWidth={400}
				hasOffset={conditionPanelIsOpen}
				width={780}>
				<ReactTooltip backgroundColor='black' effect='solid' delayShow={500} html={true} place="bottom" />
				<div className="modal_header has_border flex">
					<div className='flex_item_fix'>
						<a className='panel_close panel_close_left' onClick={(e) => setPanelIsOpen(false)}></a>
					</div>
					<div className='flex_item_full'>
						{ruleNameInputValue !== '' ?
							<>
								<div className='segment_panel_subtitle'>
									{t('segment.triggeringAndTargeting')}
								</div>
								<div>
									{ruleNameInputValue}
									<Link
										message={t('triggers.Rename')}
										onClick={() => setNameModalIsOpen(true)}
										className="segment_panel_title_action"
									/>
								</div>
							</>
							:
							<>
								{t('segment.triggeringAndTargeting')}
								<Link
									message={t('triggers.AddName') }
									onClick={() => setNameModalIsOpen(true)}
									className="segment_panel_title_action"
								/>
							</>
						}

					</div>
					<div className='flex_item_fix ml_30'>
						<Btn
							color="primary"
							onClick={() => isEditingExistingRule ? saveTargetingRule() : addNewTargetingRule()}
							message={isEditingExistingRule ? t('triggers.Save') : t('triggers.Create')}
							disabled={!isEditingExistingRule && isBaseTrigger}
							tooltip={(!isEditingExistingRule && isBaseTrigger) ? 
								t('triggers.noEffect')
								: ''}
						/>
					</div>
				</div>
		
				<div className="modal_body modal_body_grey">

					<div className="segment_section_group">
						<div className="segment_section_label">{t('triggers.Context')}</div>
						<div className="segment_section_group">
							
							<div className="segment_section">
								<div className="segment_section_inner">
									{!isNativeApp &&
									<TargetingRuleContextItem title={t('triggers.Which')} icon={'device'}>
										<Checkbox
											checked={actualEditingRule.triggers.deviceTrigger.ValueToCompare.includes('mobile')}
											disabled={checkLastDevice('mobile')}
											onChange={() => handleChangeDeviceTrigger('mobile')}
											label="Mobile"
										/>
										<Checkbox
											checked={actualEditingRule.triggers.deviceTrigger.ValueToCompare.includes('tablet')}
											disabled={checkLastDevice('tablet')}
											onChange={() => handleChangeDeviceTrigger('tablet')}
											label={t('triggers.Tablet')}
										/>
										<Checkbox
											checked={actualEditingRule.triggers.deviceTrigger.ValueToCompare.includes('desktop')}
											disabled={checkLastDevice('desktop')}
											onChange={() => handleChangeDeviceTrigger('desktop')}
											label={t('triggers.Desktop')}
										/>
									</TargetingRuleContextItem>}

									<TargetingRuleContextItem title={t('triggers.When')}
										description={t('triggers.onPageLoadExit')}
										icon={'behavior'}>
										<SelectDropdown
											optionsList={optionsEventTriggerDropdown}
											value={triggerEventType}
											onChange={(e) => handleChangeEventTrigger(e)}
										/>
									</TargetingRuleContextItem>

									{triggerEventType === 'TRGSCROLL' &&
									<TargetingRuleContextItem title={t('triggers.scrollPercent')}
										description={t('triggers.display')}
										icon={'behavior'}>
										<InputCustom
											value={actualEditingRule.triggers.eventTrigger.ValueToCompare}
											name="scroll"
											type="number"
											unit="%"
											width={'xs'}
											onChange={(e) => handleChangeScrollTrigger(e.target.value)}
										/>
									</TargetingRuleContextItem>
									}
									{triggerEventType === 'onpageload' &&
									<TargetingRuleContextItem title={'Delay'} description={t('triggers.timeSpentOnPage')}
										icon={'delay'}>
										<InputCustom
											value={actualEditingRule.triggers.delayTrigger ? actualEditingRule.triggers.delayTrigger.ValueToCompare : 0}
											name="delay"
											type="number"
											unit="Sec"
											width={'xs'}
											onChange={(e) => handleChangeDelayTrigger(e)}
										/>
									</TargetingRuleContextItem>
									}

									<TargetingRuleContextItem title={t('triggers.howManyTimes')}
										description={t('triggers.canBeDisplay')}
										icon={'times'}>
										<SelectDropdown
											optionsList={optionsNbrOfTimeTriggerDropdown}
											value={actualEditingRule.triggers.numberOfTimeTrigger.ValueToCompare}
											onChange={(v) => handleChangeNbrOfTimeTrigger(v)}
										/>
									</TargetingRuleContextItem>
								</div>
							</div>
						</div>

						<div className="segment_section_group">
							<div className="segment_section_label">{t('triggers.targeting')}</div>
							<div className="segment_section">
								{actualEditingRule.triggers.condition.length === 0 &&
								<EmptyState
									title={t('triggers.allPAges')}
									titleBold={false}
									imageUrl={'/Assets/empty_targeting.svg'}
									verticalSize={'s'}
									primaryAction={
										<Btn
											color="primary"
											style="reverse"
											onClick={() => setConditionPanelIsOpen(true)}
											message={t('triggers.addCondition')}
										/>
									}
								/>
								}
								{actualEditingRule.triggers.condition.length > 0 &&
								<div className="segment_section_inner">
									<div className='segment_condition_list'>
										{actualEditingRule.triggers.condition.map((trigger, i) => (
											<ConditionTriggerCard
												key={i}
												index={i}
												trigger={trigger}
												handleChangeOperatorName={handleChangeOperatorName}
												changeValueConditionTrigger={changeValueConditionTrigger}
												deleteTrigger={deleteTrigger}
											/>
										))}
										<div className="segment_condition_item flex">
											<div className="segment_condition_item_and flex_item_fix">
												<span className='segment_condition_item_and_label'>
													{t('triggers.AND')}
												</span>
											</div>
											<div className="flex_item_full trigger_card_info">
												<Btn
													color="primary"
													style="ghost"
													icon="fas fa-plus"
													onClick={() => setConditionPanelIsOpen(true)}
													message={t('triggers.addCondition')}
												/>
											</div>
										</div>
									</div>
								</div>
								}
							</div>

						</div>
						{conditionPanelIsOpen &&
						<ConditionPanel
							conditionPanelIsOpen={conditionPanelIsOpen}
							setConditionPanelIsOpen={setConditionPanelIsOpen}
							actualEditingRule={actualEditingRule}
							addTriggerToActualActualEditingRule={addTriggerToActualActualEditingRule}
						/>
						}

					</div>
				</div>
				<Modal
					isOpen={nameModalIsOpen}
					onClose={() => setNameModalIsOpen(false)}
					noClose={true}
					width={380}
				>
					<ModalBody>
						<InputCustom
							type="text"
							value={ruleNameInputValue}
							placeholder={t('triggers.newSegment')}
							onChange={(e) => setRuleNameInputValue(e.target.value)}
							fullWidth={true}
							label={t('triggers.SegmentName')}
							labelClassName='s_16 mb_8'
							autoFocus={true}
							selectOnFocus={true}
							onEnter={() => {
								setNameModalIsOpen(false);
							}}
						/>
					</ModalBody>
					<ModalFooter
						hasBorder={false}
						primaryAction={
							<Btn
								message="Ok"
								onClick={() => {
									setNameModalIsOpen(false);
								}}
							/>
						}
						secondaryAction={
							<Btn
								message={t('triggers.Cancel')}
								style="ghost"
								color="secondary"
								onClick={() => {
									setNameModalIsOpen(false);
									resetTargetingRuleName();
								}}
							/>
						}
					/>
				</Modal>

				<Confirm
					isOpen={modalBaseTriggerConfirmIsOpen}
					onClose={() => setModalBaseTriggerConfirmIsOpen(false)}
					title='This segment has no effect'
					text={<>
						{renderJsxTag(t('triggers.everyPagesAnd'))}
					</>}
					cancelText="Cancel"
					confirmText="Continue"
					confirmCallback={(e) => { confirmDeleteRuleBaseTrigger(); }}
				/>
			</Panel>
		</>
	);
}