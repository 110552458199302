import React, { useState, useEffect } from 'react';
import useEventBroker from '../../../Hooks/useEventBroker';
import { ProductRecommendationsValue } from '../Utils/TemplateOperations';
import { useWysiwygEditorContext } from '../context/WysiwygEditorContext';

export default function RecommendationEditor({
	label = 'Recommendation',
	data,
	onChange
}) {
	
	const {
		loadAllProductsShelvingRules,
		listOfShelvingRules
	} = useWysiwygEditorContext();

	const { publish } = useEventBroker();

	const [existingRules, setExistingRules] = useState(ProductRecommendationsValue.fromStringInput(data));

	const [variationMessageState, setvariationMessageState] = useState('Unset');

	useEffect(() => {
		loadAllProductsShelvingRules();
	}, []);

	useEffect(() => {
		setvariationMessageState(getMessageFromRecommendationsRules(existingRules));
	}, [existingRules, listOfShelvingRules]);

	const shelvingRulesSetCallback = (result) => {
		setExistingRules(result);
		onChange(ProductRecommendationsValue.fromValue(result));
	};

	const openRecommendationsPanelRequested = () => {
		publish('evt/campaign/setShelvingRulesRequested', {
			callback: shelvingRulesSetCallback, value: existingRules
		});
	};

	function getMessageFromRecommendationsRules(elem) {
		const getRule = (id) =>{
			const rule = listOfShelvingRules.find(x => x.id == id);
			return rule;
		};
		if (elem.length === 0) {
			return 'Unset';
		}
		const firstRule = getRule(elem[0].Id) || {};
		if (elem.length === 1) {
			const labelToPrint = firstRule.name;
			return labelToPrint;
		}
		if (elem.length > 1) {
			let labelToPrint = firstRule.name;
			labelToPrint += ' (+' + (elem.length - 1) + ')';
			return labelToPrint;
		}
	}
	return (
		<div className="format_param_group clickable folded overflow_hidden"
			onClick={() => openRecommendationsPanelRequested()}>
			<div className="format_param_group_name flex">
				<span className="format_param_group_icon flex_item_fix">
					<i className="fas fa-thumbs-up" />
				</span>
				<span className="flex_item_full flex flex_align_baseline overflow_hidden">
					<span className="flex_item_fix">{label}</span>
					<span className="flex_item_full ellips format_param_group_value">
						{existingRules.length > 0 ?
							<span className='fw_medium c_green'>{variationMessageState}</span>
							:
							<span className='fw_medium c_red'>{variationMessageState}</span>
						}
					</span>
				</span>
				<span className="format_param_group_arrow flex_item_fix">
					<i className="fas fa-chevron-right" />
				</span>
			</div>
		</div>
	);
}
