import React, { useState, useEffect, useCallback, useRef } from 'react';
import OperationCard from '../Dashboard/OperationCard';
import useStatusOperation from '../../../Hooks/useStatusOperation';
import formatMoment from '../../../Constants/FormatMoment';
import Dropdown from '../../../Components/Dropdown';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import Modal from '../../../Components/Modal';
import Btn from '../../../Components/Btn';
import PopinConfirm from '../../../Components/PopinConfirm';
export default function MarkerItem({ op, loadOperationStatistics, routeParams, dates, putOperationExecutionStatusModeWithTest, accountId, reloadOperationsForStatusChange, setReloadTheOperationItem, reloadTheOperationItem, deleteOperationById, permissions, userSimplifyRole }) {

	const { formatDateToLocal } = formatMoment;
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [dropdownClickEvent, setDropdownClickEvent] = useState(false);
	const [modalIsClicked, setModalIsClicked] = useState(false);
	const [stateOfOperationToValidate, setStateOfOperationToValidate] = useState();
	const [dataOfOperationToPut, setDataOfOperationToPut] = useState();
	const nbDays = dates.length;
	const style = {};
	const cssClass = ['splanning_operation_inner'];
	const opStartDate = formatDateToLocal(op.operation.startDate);
	const opEndDate = formatDateToLocal(op.operation.endDate);
	const periodStartDate = dates[0];
	const periodeEndDate = dates[dates.length - 1];
	const ref = useRef(null);
	const uid = uuidv4();
	const [poppinIsOppen, setPoppinIsOppen] = useState(false);
	const [idToDelete, setIdToDelete] = useState();
	let tmpStart = moment(opStartDate).startOf('day');
	let tmpEnd = moment(opEndDate).add(1, 'days').startOf('day');

	const wantDropdownIsOpen = function (e, state) {
		e.persist();
		e.stopPropagation();
		setDropdownClickEvent(e);
		setDropdownIsOpen(state);
	};

	if (opStartDate < periodStartDate) {
		tmpStart = moment(periodStartDate);
		cssClass.push('is_trunc_left');
	}
	if (opEndDate > periodeEndDate) {
		tmpEnd = moment(periodeEndDate).add(1, 'days');
		cssClass.push('is_trunc_right');
	}
	let statusOperation = useStatusOperation(op);
	cssClass.push(statusOperation.cssClass);
	style.width = tmpEnd.diff(tmpStart, 'days') / nbDays * 100 + '%';
	style.left = tmpStart.diff(periodStartDate, 'days') / nbDays * 100 + '%';


	const putOperationStatusMode = (x) => {
		setReloadTheOperationItem(true);
		putOperationExecutionStatusModeWithTest(accountId, x.idOp, x.status, x.executionMode, e => {
			setDataOfOperationToPut();
			reloadOperationsForStatusChange();
		},
		e => {
			setReloadTheOperationItem(false);
			console.log('status error', e);
		});
	};

	const changeStateOfTheCurrentOperation = (e) => {
		if (e === 'testMode') {
			putOperationStatusMode({
				idOp: dataOfOperationToPut.operation.operationId,
				status: false,
				executionMode: 2
			});
		}
		if (e === 'stopped') {
			putOperationStatusMode({
				idOp: dataOfOperationToPut.operation.operationId,
				status: true,
				executionMode: 1
			});
		}
		if (e === 'ongoing') {
			putOperationStatusMode({
				idOp: dataOfOperationToPut.operation.operationId,
				status: false,
				executionMode: 1
			});
		}
		setModalIsClicked(false);
	};
	const pauseTheOperation = (op) => {
		setDropdownIsOpen(false);
		putOperationStatusMode({
			idOp: op.operation.operationId,
			status: true,
			executionMode: 1
		});
	};
	useEffect(() => {
		if (modalIsClicked) {
			setDropdownIsOpen(false);
		}
		if (poppinIsOppen) {
			setDropdownIsOpen(false);
		}
	}, [modalIsClicked, poppinIsOppen]);

	const deleteTheOperation = (event) => {
		setReloadTheOperationItem(true);
		deleteOperationById(accountId, event,
			e => {
				setReloadTheOperationItem(false);
				reloadOperationsForStatusChange();
				setIdToDelete();
			},
			e => {
				//.log("fail: ", e)
				setReloadTheOperationItem(false);
			});
	};
	return (
		<div className="splanning_operation">
			{modalIsClicked &&
				<Modal
					isOpen={modalIsClicked}
					width="550"
					height="auto"
					minHeight="196"
					onClose={() => setModalIsClicked(false)}
				>
					<div className="modal_header has_border">
						<div>Starting options for {dataOfOperationToPut.operation.name}</div>
					</div>
					<div className="modal_body">
						<article className="radio_box_inner flex_justify_center">
							<label className={stateOfOperationToValidate === 'testMode' ? 'radio_box s checked' : 'radio_box s '}
								onClick={(e) => setStateOfOperationToValidate('testMode')}
							>
								<input
									type="radio"
									value="TEST_MODE"
									className=""
									name="31"

								/>
								<span><i className="fa fa-check"></i></span>
								<i className="fa fa-vial"></i>
								<p>Test</p>
							</label>
							<label
								className={stateOfOperationToValidate === 'stopped' ? 'radio_box s checked' : 'radio_box s'}
								onClick={(e) => setStateOfOperationToValidate('stopped')}
							>
								<input
									type="radio"
									value="STOPPED"
									name="32"
								/>
								<span><i className="fa fa-check"></i></span>
								<i className="fa fa-pause"></i>
								<p>Stopped</p>
							</label>
							<label
								className={stateOfOperationToValidate === 'ongoing' ? 'radio_box s checked' : 'radio_box s'}
								onClick={(e) => setStateOfOperationToValidate('ongoing')}
							>
								<input type="radio"
									value="ONGOING"
									name="33"
								/>
								<span><i className="fa fa-check"></i></span>
								<i className="fa fa-play"></i>
								<p>On-going</p>
							</label>
						</article>
						<div className="starting_options_message al_center">
							{stateOfOperationToValidate === 'stopped' &&
								'This campaign will be stopped, no one will be able to see it.'
							}
							{stateOfOperationToValidate === 'ongoing' &&
								'This campaign will be ongoing, every one will be able to see it.'
							}
							{stateOfOperationToValidate === 'testMode' &&
								'This campaign will be on test mode'
							}
						</div>
					</div>
					<div className="modal_footer modal_footer_center">
						<Btn
							onClickFunction={(e) => { setModalIsClicked(false); }}
							message="Cancel"
							style="outline"
							color="secondary"
							size="l"
						/>
						<Btn
							onClickFunction={(e) => changeStateOfTheCurrentOperation(stateOfOperationToValidate)}
							message="Confirm"
							icon="fas fa-check"
							size="l"
						/>
					</div>
				</Modal>
			}
			{poppinIsOppen &&
				<PopinConfirm
					cancel={() => setPoppinIsOppen(false)}
					title={'Are you sure you want to delete the operation ?'}
					message={''}
				>
					<Btn
						onClickFunction={(e) => { setPoppinIsOppen(false); }}
						message="Cancel"
						color="secondary"
						style="outline"
					/>
					<Btn
						onClickFunction={(e) => { deleteTheOperation(idToDelete); setPoppinIsOppen(false); }}
						message="Delete"
						color="alert"
						icon="fas fa-trash-alt"
					/>
				</PopinConfirm>
			}
			<ReactTooltip
				id={uid}
				className="labelKpi"
				backgroundColor='black'
				effect='solid'
				delayShow={200}
				place="bottom"
				globalEventOff="click" />
			<div
				onMouseDown={(e) => wantDropdownIsOpen(e, true)}
				className={cssClass.join(' ')}
				style={style}
				ref={ref}
				data-for={uid}
				data-event-off="click"
				data-tip={op.operation.name + ' - ' + statusOperation.label}>
				<div className="splanning_operation_name confidential_data">
					{op.operation.name}
				</div>
				{/*<div className={statusClass.join(' ')}></div>*/}
			</div>
			<Dropdown
				isOpen={dropdownIsOpen}
				clickEvent={dropdownClickEvent}
				posX="mouse"
				setIsOpen={(e) => setDropdownIsOpen(true)}
				onHide={(e) => setDropdownIsOpen(false)}
				buttonRef={ref}
			>
				<OperationCard
					permissions={permissions}
					userSimplifyRole={userSimplifyRole}
					routeParams={routeParams}
					accountId={accountId}
					pauseTheOperation={(e) => pauseTheOperation(e)}
					setStateOfOperationToValidate={(e) => setStateOfOperationToValidate(e)}
					setModalIsClicked={(e) => setModalIsClicked(e)}
					setDataOfOperationToPut={(e) => setDataOfOperationToPut(e)}
					isPlanningMode={true}
					idOfOperationPut={dataOfOperationToPut && dataOfOperationToPut.operation.operationId}
					loadOperationStatistics={loadOperationStatistics}
					op={op}
					setIdToDelete={(e) => setIdToDelete(e)}
					setPoppinIsOppen={(e) => setPoppinIsOppen(e)}
				/>
			</Dropdown>
			{reloadTheOperationItem && dataOfOperationToPut && dataOfOperationToPut.operation.operationId === op.operation.operationId &&
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			}
			{reloadTheOperationItem && idToDelete &&
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			}
		</div>
	);
}
