import React, {Children, cloneElement} from 'react';
import ReactTooltip from 'react-tooltip';
import {List, ListItem} from '../List';
import s from './Checkbox.module.css';


function Checkbox({
	type = 'checkbox',
	children,
	label = '',
	description = '',
	tooltip = '',
	name,
	value,
	checked,
	forcedChecked,
	disabled = false,
	onChange,
	size = 'm',
	checkboxSize = 'm',
	fullWidth = false,
	labelIsStrong = false,
	noMargin = false,
	className
}) {

	const change = (ev) => {
		if (typeof onChange === 'function') {
			onChange(ev);
		}
	};

	const handleCheckboxClick = (ev) => {
		if (forcedChecked !== undefined) {
			// Prevent 2 click propagations because of label
			ev.stopPropagation();
		}
	};

	const cssClass = ['cb_label'];
	if (className) {
		cssClass.push(className);
	}
	if( disabled ){
		cssClass.push('disabled');
	}
	if( size ){
		cssClass.push(size);
	}
	if (checkboxSize) {
		cssClass.push('checkbox_size_' + checkboxSize);
	}
	if (className) {
		cssClass.push(className);
	}

	const childrenClass = ['cb_label_text'];
	const tooltipClass = [];
	if( fullWidth ){
		childrenClass.push('flex_item_full');
		cssClass.push('flex');
		cssClass.push('vertical');
	}else{
		childrenClass.push('flex_item_auto');
		cssClass.push('flex_inline');
	}
	if (tooltip) {
		cssClass.push(fullWidth ? 'flex_item_full' : 'flex_item_auto');
		tooltipClass.push(fullWidth ? 'flex' : 'flex_inline');
	}

	if (noMargin) {
		cssClass.push('no_margin');
	}

	const inner = (
		<label className={cssClass.join(' ')}>
			{forcedChecked !== undefined ?
				<input
					type={type}
					name={name}
					value={value}
					checked={forcedChecked ? true : false}
					onChange={(ev) => change(ev)}
					onClick={(ev) => handleCheckboxClick(ev)}
					disabled={disabled}
					readOnly={true}
				/>
				:
				<input
					type={type}
					name={name}
					value={value}
					onChange={(ev) => change(ev)}
					defaultChecked={checked ? true : false}
					disabled={disabled}
				/>
			}
			<div className="cb_label_icon flex_item_fix flex_item_align_start"></div>
			<div className={childrenClass.join(' ')}>
				<div className={labelIsStrong ? 'fw_medium' : ''}>{label || children}</div>
				{description &&
					<div className='cb_label_description'>{description}</div>
				}
			</div>
		</label>
	);

	if (tooltip) {
		return (
			<span className={tooltipClass.join(' ')}>
				{inner}
				<span className="cb_label_tooltip flex_item_fix">
					<a className="icon_btn s v_al_bl" data-tip={tooltip}>
						<i className="fas fa-info-circle"></i>
					</a>
					<ReactTooltip
						backgroundColor="black"
						effect="solid"
						place="bottom"
						eventOff="click"
						delayShow={600}
					/>
				</span>
			</span>
		);
	} else {
		return inner;
	}
}


function Radio(props) {
	return (
		<Checkbox {...props} type="radio">
			{props.children}
		</Checkbox>
	);
}


function Switch({
	children,
	label,
	description = '',
	name,
	value,
	checked,
	onChange,
	disabled = false,
	forcedChecked,
	position = 'left',
	size = 'm',
	align = 'top',
	verticalSize = 'm',
	labelIsStrong = false,
	noMargin = false
}) {

	const change = (ev) => {
		if (typeof onChange === 'function') {
			onChange(ev);
		}
	};

	const handleCheckboxClick = (ev) => {
		if (forcedChecked !== undefined) {
			// Prevent 2 click propagations because of label
			ev.stopPropagation();
		}
	};

	const labelClass = ['cb_label'];
	const switchClass = ['cb_label_switch', 'flex_item_fix'];

	if( align == 'top' ){
		switchClass.push('flex_item_align_start');
	}

	if( position == 'right' ){
		labelClass.push('flex');
	}else{
		labelClass.push('flex_inline');
	}

	if( disabled ){
		labelClass.push('disabled');
	}

	if( size ){
		switchClass.push(size);
	}

	if( verticalSize ){
		labelClass.push('pad_'+verticalSize);
	}

	if (noMargin) {
		labelClass.push('no_margin');
	}

	const input = (
		<>
			{forcedChecked !== undefined ?
				<input
					type="checkbox"
					name={name}
					value={value}
					checked={forcedChecked ? true : false}
					onChange={(ev) => change(ev)}
					onClick={(ev) => handleCheckboxClick(ev)}
					disabled={disabled}
					readOnly={true}
				/>
				:
				<input
					type="checkbox"
					name={name}
					value={value}
					onChange={(ev) => change(ev)}
					defaultChecked={checked ? true : false}
					disabled={disabled}
				/>
			}
		</>
	);

	return (
		<label className={labelClass.join(' ')}>
			{position == 'right' && (children || label) &&
				<div className="cb_label_text flex_item_full mr_10">
					<div className={labelIsStrong ? 'fw_medium' : ''}>{label || children}</div>
					{description &&
						<div className='cb_label_description'>{description}</div>
					}
				</div>
			}
			{input}
			<span className={switchClass.join(' ')}></span>
			{position == 'left' && (children || label) &&
				<div className="cb_label_text flex_item_auto">
					<div className={labelIsStrong ? 'fw_medium' : ''}>{label || children}</div>
					{description &&
						<div className='cb_label_description'>{description}</div>
					}
				</div>
			}
		</label>
	);
}


function CheckboxList(props) {
	const arrayChildren = Children.toArray(props.children);

	return (
		<List {...props}>
			{Children.map(arrayChildren, (child) => 
				<ListItem>
					{cloneElement(child)}
				</ListItem>
			)}
		</List>
	);
}


export {
	Checkbox,
	Radio,
	Switch,
	CheckboxList
};
