import React from 'react';
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function SelectDropDownWithExampleValues({
	editionStyles,
	metaDataSourcesToMap,
	valueDoesntExistInList,
	hasKeyInObjet,
	mappingObject,
	name,
	handleChangeForm,
	label = '', 
	isCategory = false,
	blockClassName
}) {
	

	const hasSourceField = mappingObject[name];
	return (
		<SelectDropdown
			label={label}
			optionsList={metaDataSourcesToMap}
			value={!isCategory ? (!valueDoesntExistInList && hasKeyInObjet && hasSourceField) ? mappingObject[name][0].sourceField : null :
				(mappingObject && mappingObject.name) ? 
					mappingObject.name : 'choose'
			}
			size='l'
			fullWidth={true}
			placeHolder='Choose a field'
			hasHoverSidebar={true}
			hoverSidebarRender={(label, values) => {
				return (
					<>
						<div className={editionStyles.exemple_values_label}>
                    Some values of the <span className='text_flag'>{label}</span> field:
						</div>
						<ol className={editionStyles.exemple_values_list}>
							{values.map((v, i) =>
								<li key={i} className={editionStyles.exemple_values_item}>
									{v}
								</li>
							)}
						</ol>
					</>
				);
			}}
			hoverSidebarPlaceholder="Hover your mouse over the fields to see some values."
			onChange={(v) => handleChangeForm({target: {name: name, value: v}})}
			blockClassName={blockClassName}
		/>
	);
}
