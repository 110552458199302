import React, {} from 'react';
import styles from '../../WebAnalyticsContainer.module.css';

export default function Breadcrumb({list}) {
	return (
		<ul className={styles.breadcrumb}>
			{list.map((item, i) => 
				<BreadcrumbItem
					key={i}
					label={item.label}
					isFolder={item.isFolder}
					onClick={item.onClick}
					isLast={i == list.length - 1}
				/>
			)}
		</ul>
	);
};

function BreadcrumbItem({
	label,
	isFolder,
	onClick,
	isLast
}) {

	const content = (
		<>
			{isFolder && <i className="fas fa-folder mr_5"/>}
			{label}
		</>
	);

	return (
		<li className={styles.breadcrumbItem}>
			{typeof onClick != 'undefined' && !isLast ?
				<a onClick={onClick} className={styles.breadcrumbLink}>
					{content}
				</a>
				:
				<>{content}</>
			}
		</li>
	);
}