import React, { useState, useContext, useEffect } from 'react';
import YouTube from 'react-youtube';
import Btn from '../../../../../Components/Btn';
import { CreateBusinessOperationContext } from '../../Context/ContextCreateBusinessOperations';
export default function YoutubeWidget({
	resetDataVideo,
	videoData,
	accountId,
	businessOperationServices
}) {
	const {
		operation,
		setOperation,
		isexistant,
		listBanners,
		setListBanners,
		systemServices,
		setAddVideoModalIsOpen
	} = useContext(CreateBusinessOperationContext);

	const opts = {
		height: '300',
		width: '450',
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			frameborder: 1,
			controls: 1
		},
		host: 'https://www.youtube.com',
	};
	function _onReady(event) {
		// access to player in all event handlers via event.target
		event.target.pauseVideo();
	}

	function addVideoToList() {
		uploadHandler();
	}
	const uploadHandler = (e) => {
		if (!operation.operation.libraryId) {
			businessOperationServices.postCreateLibrary(accountId).then(res => {
				const libraryId = res.data.libraryId;
				let operationObject = { ...operation };
				operationObject.operation.libraryId = libraryId;
				setOperation(operationObject);
				postVideoToLibrary(libraryId);
			}).catch(error => {
				console.error('error during creation of the library', error);
			});
		} else {
			postVideoToLibrary(operation.operation.libraryId);
		}
	};
	const postVideoToLibrary = (libraryId) => {
		const saveBanner = {
			BannerType: 'VideoPlayer',
			imagePath: videoData.thumbnails.default.url,
			metadata: {
				...videoData.localized,
				height: videoData.thumbnails.default.height,
				width: videoData.thumbnails.default.width,
				youtubeUrl: videoData.url,
				youtubeId: videoData.youtubeId
			}
		};
		businessOperationServices.postBannerLibrary(accountId, libraryId, saveBanner,
			data => {
				resetDataVideo();
				const bannersList = [...data.banners];
				setListBanners(bannersList);
			},
			error => {
				systemServices.showError('An error occured during the creation of video');
			});
	};


	if (videoData) return (
		<>
			<div className="modal_section_bottom flex_item_full">
				<YouTube
					videoId={videoData.youtubeId}
					opts={opts}
					onReady={_onReady}
					className="simplify_video_full"
				/>
			</div>
			<div className="modal_footer has_border al_right">
				<Btn
					onClickFunction={() => {resetDataVideo(); setAddVideoModalIsOpen(false);}}
					message="Cancel"
					style="ghost"
					color="secondary"
				/>
				<Btn
					onClickFunction={() => {addVideoToList(); setAddVideoModalIsOpen(false);}}
					message="Add video"
					color="primary"
				/>
			</div>
		</>
	);
	return <></>;
}
