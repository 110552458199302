import AiInsightsService from '../../../../Services/AiInsightsServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import { AiInsight } from '../context/AiInsightsApiTypesServices';
import SystemServices from '../../../../Services/SystemServices';
const i18nL = localStorage.getItem('i18nL');

export function aiInsightsBridgeCrud(httpAngularJs,$rootScope,$routeParams) {
	const impersonatingServices = new ImpersonatingServices($rootScope,$routeParams );
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;
	const aiInsightsService = new AiInsightsService(accountId,httpAngularJs);
	const systemServices = new SystemServices($rootScope);

	function getAiInsights(callbackSuccess, callbackError) {
		aiInsightsService.getAiInsights(
			i18nL,
			(data: AiInsight[]) => {
				callbackSuccess(data);
			},
			(err: any, dataError: any) => {
				console.log('error', err);
				console.log('=>(dataError', dataError);
				systemServices.showError('An Error occured while fetching insights data');
				if(dataError.status === 500){
					callbackError(true);
				}else{
					callbackError(false);
				}
			}
		);
	}
	return {
		getAiInsights,
	};
}



