export const styleButtonAlign = {
	create({ props, change }) {
		let wrapper = document.createElement('div');
		wrapper.classList.add('btn_switch');
		wrapper.innerHTML = 'Coucou';

		console.log('styleButtonAlign');
		console.log('props', props);
		console.log('change', change);

		return wrapper;
	},
	emit({ props, updateStyle }, { event, partial }) {

	},
	update({ value, el }) {

	},
	destroy() {}
};