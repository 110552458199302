import React, {useEffect,useState} from 'react';
import classes from './EditImageCampaign.module.css';
import { ReportGeneratorContext } from './Context';
import Dropzone from './Dropzone';

export default function UploadImageContainer() {
	const {
		selectedCampaignsFiltered,
		screenShotServices,
		accountId,
		systemServices,
		displayImage,
		setDisplayImage,
		isUploaded,
		setisUploaded
	} = ReportGeneratorContext();
	
	const [urlImageAccount, seturlImageAccount] = useState();
	const [idScreenshoot, setidScreenshoot] = useState();

	const handleImage = ( uploadElseCancel) => {
	
		if (uploadElseCancel) {
			const idCampaign = selectedCampaignsFiltered[0].campaignId;
			const filesToUpload = displayImage.files;
			if (idCampaign && filesToUpload.length > 0) {
				const formData = new FormData();
				formData.append('image', displayImage.files[0]);
				if(idScreenshoot){
					screenShotServices.putCampaignImageLogo(
						accountId,
						idScreenshoot,
						formData,
					).then(() => {
						setisUploaded(true);
						systemServices.showSuccess(
							'image has been upload'
						);
						
					}).catch(err => {
						console.log(err),
						systemServices.showError('error');
					});
				}else{
					screenShotServices.postCampaignImageLogo(
						accountId,
						formData,
					).then(() => {
						setisUploaded(true);
						systemServices.showSuccess(
							'image has been upload'
						);
						
					}).catch(err => {
						console.log(err),
						systemServices.showError('error');
					});}
			}
		} else {
			setisUploaded(false);
			setDisplayImage({ isActive: false, files: [] });
		}

	};

	function getLogoAccount(){
		screenShotServices.getCampaignImageLogo(
			accountId,
			
		).then(d => {
			seturlImageAccount(d.data.uri);
			setidScreenshoot(d.data.id);
			
		}).catch(err => {
			console.log(err);
			seturlImageAccount(undefined);
			setidScreenshoot(undefined);
		});}
	useEffect(()=>{
		getLogoAccount();
	},[]);
	useEffect(()=>{
		if(displayImage && displayImage.files.length > 0 && !isUploaded){
			handleImage( true);
		}
	},[displayImage,isUploaded]);

	return (
		<div className={classes.editImageCampaign}>
			<div className={classes.dropzone_wrapper}>
				<Dropzone setDisplayImage={setDisplayImage}>
					{displayImage.isActive &&
						<div className={classes.image_wrapper}>
							<img className={classes.image} width={300} src={URL.createObjectURL(displayImage.files[0])} />
						</div>
					}
					{!displayImage.isActive && urlImageAccount &&
						<div className={classes.image_wrapper}>
							<img className={classes.image} width={300} src={urlImageAccount} />
						</div>
					}
				</Dropzone>
			</div>
		</div>
	);
}
