import React, { useState, useEffect } from 'react';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import Confirm from '../../../Components/Confirm';
import { CampaignsContext } from '../context';
import ABSettings from './ABSettings';
import FormatLivePreview from './FormatEditor/FormatLivePreview';
import { useTranslation } from 'react-i18next';
import renderJsxTag from '../../../Translation/translation-utils';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';

export default function FormatToolbar() {
	const { t, i18n } = useTranslation(['common']);
	const {
		step,
		editorState,
		getCurrentVariation,
		currentVariationId,
		handleBackToFormat,
		handleBackToStyle,
		displayBackToFormat,
		displayBackToStyle,
		showCSSEditor,
		setShowCSSEditor,
		matchedExistingStyle,
		currentDevice,
		setCurrentDevice,
		saveStyleModalOpen,
		setSaveStyleModalOpen,
		noAvailableFormat,
		sidebarIsOpen,
		setSidebarIsOpen,
		editorMode
	} = CampaignsContext();

	const idTooltip = uuidv4();
	const activeVariation = getCurrentVariation() || {};
	const descriptor = currentDevice === 'desktop' ? activeVariation.TemplateDescriptorDesktop : activeVariation.TemplateDescriptorMobile;
	const [actionDropdownIsOpen, setActionDropdownIsOpen] = useState(false);
	const [askConfirmBackToStyle, setAskConfirmBackToStyle] = useState(false);
	const [askConfirmBackToFormat, setAskConfirmBackToFormat] = useState(false);

	const [formatReady, setFormatReady] = useState(false);

	useEffect(() => {
		setFormatReady(editorState.currentWysiwyg && !!editorState.currentWysiwyg.Template);
	}, [currentVariationId]);

	useEffect(() => {
		setFormatReady(!!editorState.selectedFormat.Identifier);
	}, [editorState]);

	if (step === 1 && editorMode !== 'custom' && editorMode !== 'appNative/patch/code') {
		return (
			<div className="format_toolbar flex">
				<div className="format_toolbar_left">
					{editorState.sideBarStep === 1 &&
						<div className="format_toolbar_title">
							{t('nav.choosetemplate')}
						</div>
					}
					{editorState.sideBarStep === 2 &&
						<div className="format_toolbar_title">
							{t('nav.chooseStyle')}
						</div>
					}
					{editorState.sideBarStep === 3 &&
						<>
							<button
								className={sidebarIsOpen ? 'format_cmd main_color active' : 'format_cmd main_color'}
								onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
								data-tip="Toggle sidebar"
								data-for={idTooltip}>
								<i className='fas fa-sliders-h format_cmd_icon_l'></i>
							</button>
							<span className="format_toolbar_title">{t('nav.customization')}</span>
							{activeVariation && activeVariation.Template && activeVariation.Template.Name &&
								<>
									<i className='format_toolbar_title_arrow fas fa-chevron-right'></i>
									<span className="format_toolbar_subtitle">{activeVariation.Template.Name}</span>
								</>
							}
							<ReactTooltip
								backgroundColor="black"
								effect="solid"
								place="bottom"
								globalEventOff="click"
								delayShow={600}
								id={idTooltip}
							/>
						</>
					}
				</div>
				<div className="format_toolbar_center">
					{editorState.selectedFormat.Identifier || (editorState.sideBarStep === 2 || editorState.sideBarStep === 3) ?
						(<ul className="format_tabs">
							<li>
								<a className={currentDevice === 'mobile' ? 'active' : ''}
									onClick={() => setCurrentDevice('mobile')}>
									<i className='fas fa-mobile-alt'></i>
									Mobile
								</a>
							</li>
							<li>
								<a className={currentDevice === 'desktop' ? 'active' : ''}
									onClick={() => setCurrentDevice('desktop')}>
									<i className='fas fa-desktop'></i>
									{t('button.desktop')}
								</a>
							</li>
							{formatReady && editorState.sideBarStep === 3 &&
								<li>
									<FormatLivePreview />
								</li>
							}
						</ul>) : null
					}
				</div>
				<div className="format_toolbar_right">
					<div className="format_cmd_group">
						<ABSettings />

						{editorState.sideBarStep === 3 &&
							<>
								{!matchedExistingStyle &&
									<a className="format_cmd"
										onClick={(e) => setSaveStyleModalOpen(!saveStyleModalOpen)}>
										<i className="fas fa-star"></i>
										<span> {t('nav.saveStyle')}</span>
									</a>
								}
								{matchedExistingStyle &&
									<a className="format_cmd active color"
										data-tip={matchedExistingStyle.name}>
										<i className="fas fa-star"></i>
										<span>{t('nav.saveStyle')}</span>
									</a>
								}

								<a className={showCSSEditor ? 'format_cmd active' : 'format_cmd'}
									onClick={(e) => setShowCSSEditor(!showCSSEditor)}>
									<i className="fas fa-code"></i>
									<span>{t('nav.editCss')}</span>
								</a>

								<>
									<Dropdown
										isOpen={actionDropdownIsOpen}
										setIsOpen={() => setActionDropdownIsOpen(true)}
										onHide={() => setActionDropdownIsOpen(false)}
										button={
											<a className={actionDropdownIsOpen ? 'format_cmd active' : 'format_cmd'}>
												<i className="fas fa-ellipsis-v"></i>
											</a>
										}
									>
										<Listbox>
											<ListboxItem
												message={t('nav.restartWithStyle')}
												onClick={(e) => { setActionDropdownIsOpen(false); setAskConfirmBackToStyle(true); }}
												description={displayBackToStyle ? '' : t('nav.noStyles')}
												disabled={!displayBackToStyle}
											/>
											{displayBackToFormat &&
												<ListboxItem
													message={t('nav.restartWithTemplate')}
													onClick={(e) => { setActionDropdownIsOpen(false); setAskConfirmBackToFormat(true); }}
												/>
											}
										</Listbox>
									</Dropdown>
									<Confirm
										isOpen={askConfirmBackToStyle}
										onClose={(e) => setAskConfirmBackToStyle(false)}
										title={t('nav.restartWithAnotherStyle')}
										text={t('nav.youLostStyle')}
										cancelText={t('button.stay')}
										confirmText={t('nav.browsStyle')}
										confirmCallback={(e) => handleBackToStyle()}
										confirmColor="alert"
									/>
									<Confirm
										isOpen={askConfirmBackToFormat}
										onClose={(e) => setAskConfirmBackToFormat(false)}
										title={t('nav.restartWithAnotherTemplate')}
										text={t('nav.youLostStyle')}
										cancelText={t('button.stay')}
										confirmText={t('nav.browsForm')}
										confirmCallback={(e) => handleBackToFormat()}
										confirmColor="alert"
									/>
								</>
							</>
						}
					</div>
				</div>
			</div>
		);
	} else if (step === 1 && editorMode === 'custom') {
		return (
			<div className="format_toolbar flex">
				<div className="format_toolbar_left">
					<div className='format_toolbar_title'>{t('designEditor.customCampaign')}</div>
				</div>
				<div className="format_toolbar_center">
				</div>
				<div className="format_toolbar_right">
					<div className="format_cmd_group">
						<ABSettings />

						{editorState.sideBarStep === 3 &&
							<>
								{!matchedExistingStyle &&
									<a className="format_cmd"
										onClick={(e) => setSaveStyleModalOpen(!saveStyleModalOpen)}>
										<i className="fas fa-star"></i>
										<span> {t('nav.saveStyle')}</span>
									</a>
								}
								{matchedExistingStyle &&
									<a className="format_cmd active color"
										data-tip={matchedExistingStyle.name}>
										<i className="fas fa-star"></i>
										<span>{t('nav.saveStyle')}</span>
									</a>
								}

								<a className={showCSSEditor ? 'format_cmd active' : 'format_cmd'}
									onClick={(e) => setShowCSSEditor(!showCSSEditor)}>
									<i className="fas fa-code"></i>
									<span>{t('nav.editCss')}</span>
								</a>

								<>
									<Dropdown
										isOpen={actionDropdownIsOpen}
										setIsOpen={() => setActionDropdownIsOpen(true)}
										onHide={() => setActionDropdownIsOpen(false)}
										button={
											<a className={actionDropdownIsOpen ? 'format_cmd active' : 'format_cmd'}>
												<i className="fas fa-ellipsis-v"></i>
											</a>
										}
									>
										<Listbox>
											<ListboxItem
												message={t('nav.restartWithStyle')}
												onClick={(e) => { setActionDropdownIsOpen(false); setAskConfirmBackToStyle(true); }}
												description={displayBackToStyle ? '' : t('nav.noStyles')}
												disabled={!displayBackToStyle}
											/>
											{displayBackToFormat &&
												<ListboxItem
													message={t('nav.restartWithTemplate')}
													onClick={(e) => { setActionDropdownIsOpen(false); setAskConfirmBackToFormat(true); }}
												/>
											}
										</Listbox>
									</Dropdown>
									<Confirm
										isOpen={askConfirmBackToStyle}
										onClose={(e) => setAskConfirmBackToStyle(false)}
										title={t('nav.restartWithAnotherStyle')}
										text={t('nav.youLostStyle')}
										cancelText={t('button.stay')}
										confirmText={t('nav.browsStyle')}
										confirmCallback={(e) => handleBackToStyle()}
										confirmColor="alert"
									/>
									<Confirm
										isOpen={askConfirmBackToFormat}
										onClose={(e) => setAskConfirmBackToFormat(false)}
										title={t('nav.restartWithAnotherTemplate')}
										text={t('nav.youLostStyle')}
										cancelText={t('button.stay')}
										confirmText={t('nav.browsForm')}
										confirmCallback={(e) => handleBackToFormat()}
										confirmColor="alert"
									/>
								</>
							</>
						}
					</div>
				</div>
			</div>
		);
	} else if(editorMode === 'appNative/patch/code') {
		return <> </>;
	}else{
		return <></>;
	}
}