import React, { Component } from 'react';
import SignalTypeEnum from './SignalTypeEnum';
import styles from './VisitorEngagementSignalPanel.module.css';

export default class VisitorEngagementSignalPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.signalToEdit ? this.props.signalToEdit.id : "",
            name: this.props.signalToEdit ? this.props.signalToEdit.name : "",
            tag: this.props.signalToEdit ? this.props.signalToEdit.tag : "",
            type: this.props.signalToEdit ? this.props.signalToEdit.type : SignalTypeEnum.Boolean
        }

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeTag = this.handleChangeTag.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
    }

    handleChangeName(event) {
        this.setState({ name: event.target.value });
    }

    handleChangeTag(event) {
        this.setState({ tag: event.target.value });
    }

    handleChangeType() {
        if (this.state.type === SignalTypeEnum.Integer) {
            this.setState({ type: SignalTypeEnum.Boolean });
        }
        else {
            this.setState({ type: SignalTypeEnum.Integer });
        }
    }

    cancel() {
        this.props.hideAndResetSignalPanel();
    }

    createOrUpdateSignal() {
        this.props.createOrUpdateSignal({ id: this.state.id, name: this.state.name, tag: this.state.tag, type: this.state.type });
    }

    deleteSignal() {
        this.props.deleteSignal(this.state.id);
    }

    render() {
        return <div className={styles.content}>
            <div className={styles.labels}>Name</div>
            <input type="text" className={styles.input} value={this.state.name} onChange={this.handleChangeName} />
            <div className={styles.labels}>Key</div>
            <input type="text" className={styles.input} value={this.state.tag} onChange={this.handleChangeTag} />
            <div className={styles.tooltip}>Key used to identify the event</div>
            <div className={styles.labels}>Type</div>
            <div onClick={this.handleChangeType}
                className={`${styles.typeSelection} ${(this.state.type === SignalTypeEnum.Integer) ? styles.numericSelected : ""}`}>Numerical</div>
            <div className={styles.actionButtons}>
                <button onClick={() => this.cancel()} className={styles.cancelButton}>Cancel</button>
                <button onClick={() => this.createOrUpdateSignal()} className={styles.saveButton}>{this.props.actionLabel}</button>
            </div>
            {this.props.signalToEdit ?
                <div onClick={() => this.deleteSignal()} className={styles.deleteLabel}><i className="fas fa-times"></i> Delete</div> : ""}
        </div >
    }
}
