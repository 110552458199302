import React, { useState, useEffect, useRef } from 'react';
import Spinner from '../../../Components/Spinner';
import Btn from '../../../Components/Btn';
import BoardAssociations from './BoardAssociations';

export default function ProductAssociationSetPicked({
	accountId,
	associationSet = {},
	productAssociationServices,
	systemServices,
	switchMode,
}) {
	const getThumbnailUrl = (productId) => window.BEYABLE_env.PRODUCTS_THUMBNAILS_URL + accountId.replace(/-/g, '') + '/' + productId + '.jpg';
	//const getThumbnailUrl = (productId) => "https://beyableprodcrm.blob.core.windows.net/thumbnails/b801f3a25f884510a8bbf18ba5bfb070/1014202235.jpg"

	const handleError = (err) => systemServices.showError('An error occurred');

	const getAllAssociations = (offset, pageSize, callbackSuccess) =>
		associationSet.id && productAssociationServices.getAllAssociations(offset, pageSize, associationSet.id, callbackSuccess, handleError);
	const importAssociation = (file, callbackSuccess) =>
		associationSet.id && productAssociationServices.importAssociation(associationSet.id, file, callbackSuccess, handleError);

	const hiddenFileInput = useRef(null);

	const [loading, setLoading] = useState(true);
	const [associationList, setAssociationList] = useState([]);
	const [offset, setOffset] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState(0);

	const retrieveAssociations = (offset, pageSize) => {
		setLoading(true);
		getAllAssociations(offset, pageSize, (res, headers) => {
			setTotalCount(headers()['x-total-count']);
			setAssociationList(res);
			setLoading(false);
		});
	};

	useEffect(() => retrieveAssociations(offset, pageSize), [offset, pageSize, associationSet]);

	const handleOpenSelectFile = _ => {
		hiddenFileInput.current.click();
	};

	const handleFileSelected = event => {
		const fileUploaded = event.target.files[0];
		const formData = new FormData();
		formData.append('file', fileUploaded);
		importAssociation(formData).then((res) => {
			setOffset(0);
			retrieveAssociations(0, pageSize);
		});
	};

	return (
		<section className="section">
			<div className="page_block pos_rel">
				<div className="flex mb_10">
					<div className="flex_item_full">
						<div className="s_18 fw_medium">
							<a className="text_link" onClick={switchMode}>Bundles</a>
							<i className="fas fa-chevron-right s_12 ml_5 mr_5 grey_4"></i>
							{associationSet.name}
						</div>
					</div>
					<div className="flex_item_fix">
						{/* <div className="search_tag">
							<i className="fa fa-search"></i>
							<input className="searchBarTag" type="text" placeholder="Search for an association" />
						</div> */}

						{/* TODO: Extract to InputFile component */}
						<input ref={hiddenFileInput} style={{ display: 'none' }} type={'file'} accept={'.csv'} onChange={handleFileSelected} />
						<Btn
							onClick={handleOpenSelectFile}
							message="Import a bundle CSV"
							icon="fas fa-upload"
						/>
					</div>
				</div>
				{loading ? (
					<Spinner />
				) : (
					<BoardAssociations
						getThumbnailUrl={getThumbnailUrl}
						totalCount={totalCount}
						title={'Bundle'}
						data={associationList}
						offset={offset}
						setOffset={setOffset}
						pageSize={pageSize}
						setPageSize={setPageSize}
					/>
				)}
			</div>
		</section>
	);
}
