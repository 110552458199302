import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import gql from 'graphql-tag'

const getProducts = gql`
query ($accountId: String, $tenant: String, $cursor: String, $total: Int) {
    account(id: $accountId, tenant: $tenant) {
      productCatalogue {
        products(first: $total, after: $cursor) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          totalCount
          edges {
            cursor
            node {
              id
              name
              description
              categories {
                type
                name
                value
              }
              tags {
                tag
                value
                numValue
                dateValue
              }
              url
              urlThumbnail
              price
              globalStockQuantity
              unitPriceWithRebate
              productVariants {
                stockQuantity
                productVariantId
              }
            }
          }
        }
      }
    }
  }
`;

async function loadAllProducts({authServices, accountId}){
  const token = authServices.getAccessToken();
  const httpLink = createHttpLink({
      uri: window.BEYABLE_env.URI_GRAPHQL
  })

  const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      // return the headers to the context so httpLink can read them
      return {
          headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : "",
          }
      }
  });
  const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
  })

  const load = async (nextCursor) => {
    return client.query({
      query: getProducts,
      variables: {
          cursor: nextCursor,
          total: 100,
          accountId: accountId,
          tenant: ""
      }
    }).then(response => {

      const products = response.data.account.productCatalogue.products.edges.map(elem => elem.node)
      const hasNext = response.data.account.productCatalogue.products.pageInfo.hasNextPage
      const endCursor = response.data.account.productCatalogue.products.pageInfo.endCursor

      return { products, hasNext, endCursor }

    })
  }

  async function fetchData() {
    let allProducts = []
    let cursor = null
    let hasNext = true
    while(hasNext){
      const res = await load(cursor)
      cursor = res.endCursor
      hasNext = res.hasNext

      allProducts = [...allProducts, ...res.products]
    }
    return allProducts
  }

  return fetchData()
}


export default function productLoader(){
    return {
        loadAllProducts:loadAllProducts
    }
}