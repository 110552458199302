import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Fieldset from '../../../Components/Fieldset';
import ReferenceDataAutoComplete from '../../ReferenceData/ReferenceDataAutoComplete';
import { ProductReferencesValue } from '../Utils/TemplateOperations';

export default function ProductReferences({
	label,
	description,
	tooltip,
	inputValue,
	handleChangeInputValue,
	max,
	setupAccountServices,
	accountId
}) {

	const maxMax = 10;
	const defaultMax = 5;
	const actualMax = max ? Math.min(max, maxMax) : defaultMax;

	const toSelectedValuesWithError = (values) => values.map((x, i) => ({ productReference: x, isError: i >= actualMax }));

	const toSelectedValuesWithErrorFromStringValue = (stringValue) => toSelectedValuesWithError(ProductReferencesValue.fromStringInput(stringValue) || []);

	const handleValueChange = (values) => {
		const newValue = values.map(x => x.productReference);
		handleChangeInputValue(new ProductReferencesValue(newValue.join(';')));
	};

	const [currentValueString, setCurrentValueString] = useState(inputValue);
	const [selectedValues, setSelectedValues] = useState(toSelectedValuesWithErrorFromStringValue(inputValue));

	const onChangeAutocomplete = (text) => {
		let txtFormat = text;
		if (txtFormat.length) {
			txtFormat = txtFormat.replace(/(\n)+/g, ';');
			txtFormat = txtFormat.replace(/;+/g, ';');
		}
		setCurrentValueString(txtFormat);
		setSelectedValues(toSelectedValuesWithErrorFromStringValue(txtFormat));
	};

	useEffect(() => {
		handleValueChange(selectedValues.slice(0, actualMax));
	}, [selectedValues]);

	const SelectedProductReference = ({ selectedValue, deleteValue }) => {
		if (!selectedValues || selectedValues.length === 0)
			return <></>;
		const selectedItem = selectedValues.find(x => x.productReference == selectedValue);
		if (!selectedItem)
			return <></>;
		return (
			<>
				<ReactTooltip
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="click"
					delayShow={600}
				/>
				<div className='mt_8 mb_8 flex'>
					{selectedItem.isError ?
						<span className='flex_item_fix mr_5 c_red' data-tip={`Max items reached (${actualMax})`}>
							<i className='fas fa-exclamation-circle'></i>
						</span>
						:
						<span className='flex_item_fix mr_5 grey_3'>
							<i className='fas fa-barcode'></i>
						</span>
					}

					<span className='flex_item_auto ellips s_14'>
						{selectedValue}
					</span>

					<span className='flex_item_fix ml_5'>
						<a className='icon_btn xxs'
							onClick={() => {
								deleteValue();
								setSelectedValues(s => toSelectedValuesWithError(s.filter(x => x.productReference != selectedValue).map(x => x.productReference)));
							}}
						>
							<i className='fas fa-times'></i>
						</a>
					</span>
				</div>
			</>
		);
	};

	return (
		<>
			<Fieldset label={label} description={description} labelTooltip={tooltip}>
				<ReferenceDataAutoComplete
					selectedTenant={''}
					setupAccountServices={setupAccountServices}
					currentAccount={accountId}
					referenceDataType={'product'}
					value={currentValueString}
					onChange={onChangeAutocomplete}
					useChildRendering={true}
				>
					<SelectedProductReference />
				</ReferenceDataAutoComplete>
			</Fieldset>
		</>
	);
}