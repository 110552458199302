import React from 'react';
export default function Grid({ dates, today }) {

	return (
		<div className="splanning_grid">
			{dates.map((date, i) =>
				<div key={i} className={today.isSame(date) ? 'splanning_grid_col is_today' : 'splanning_grid_col'}>
				</div>
			)}
		</div>
	);
}