import React, { useContext } from 'react';
import { BusinessOperationOrchestrationContext } from '../Context';
import { getOperationStatus } from '../Util';
import { sortByPropText } from '../Util';

const AvailableOperations = ({ }) => {
	const {
		availableOperations,
		handleAddOperation
	} = useContext(BusinessOperationOrchestrationContext);


	const campaignItem = (ope) => {
		const status = getOperationStatus(ope);
		return (
			<li className="simplify_orch_list_item clickable flex"
				key={ope.operationId}
				onClick={() => handleAddOperation(ope)}>
				<div className="flex_item_fix mr_10">
					<span className={'status_bullet ' + status.cssClass}></span>
				</div>
				<div className="flex_item_full ellips">
					{ope.name} ({ope.startDate} - {ope.endDate})
				</div>
				<div className="flex_item_fix ml_10 simplify_orch_list_item_arrow">
					<i className="fas fa-chevron-right"></i>
				</div>
			</li>
		);
	};

	return (
		<ul className="simplify_orch_list">
			{availableOperations.sort(sortByPropText(x => x.name)).map(campaignItem)}
		</ul>
	);
};


export default AvailableOperations;