import React, { useState, useEffect } from 'react'
import './EditSourceProductFeed.css'
import Btn from '../../../../Components/Btn'
import PopinConfirm from '../../../../Components/PopinConfirm'
export default function EditSourceProductFeed({ setIsConfigurationSourceMode, accountConfigurationFeed, setAccountConfigurationFeed, setNewProductFeed, activeFeed, accessValue, setAccessValue, setIsFirstRender, deleteProductFeed, refreshDataOfProductFeed }) {
    const [poppinIsOppen, setPoppinIsOppen] = useState(false)
    const [clickSync, setClicSync] = useState(false)

    const data = accountConfigurationFeed.filter(feed =>
        feed.index == activeFeed.index)[0]

    const dataSourceConf =
    {
        url: data.source.sourceUrl,
        passWord: data.source.password,
        login: data.source.login,
        languageId: data.source.languageId,
        apiKey: data.source.apiKey,
        root: data.xmlConfig && data.xmlConfig.root,
        itemNode: data.xmlConfig && data.xmlConfig.itemNode,
        namespaceDeclaration: data.xmlConfig && data.xmlConfig.namespaceDeclaration,
    }

    const productFeedConf = {
        type: data.source.sourceFormat,
        source: data.source.sourceType,
    }
    const clickOnSync = (e) => {
        setNewProductFeed(productFeedConf);
        setAccessValue(dataSourceConf);
        setClicSync(true)
    }
    useEffect(() => {
        if (clickSync) {
            refreshDataOfProductFeed()
            setClicSync(false)
        }
    }, [clickSync])
    return (
        <div className="edit_source_product_feed_container">
            {poppinIsOppen &&
                <PopinConfirm
                    cancel={() => setPoppinIsOppen(false)}
                    title={`Are you sure you want to delete this Feed ?`}
                    message={``}
                >
                    <Btn
                        onClickFunction={(e) => { setPoppinIsOppen(false) }}
                        message="Cancel"
                        color="secondary"
                        style="outline"
                    />
                    <Btn
                        onClickFunction={(e) => { deleteProductFeed(); setPoppinIsOppen(false) }}
                        message="Delete"
                        color="alert"
                        icon="fas fa-trash-alt"
                    />
                </PopinConfirm>
            }
            <div>
                {data.source.sourceUrl && data.source.sourceUrl}
                {data.source.sourceType === "OnSiteData" && "Beyable"}
                <div>
                    {data.source.lastSourceSynchroAtUtc && <>  Last source synchronization : {moment(data.source.lastSourceSynchroAtUtc).format("DD-MM-YYYY HH:mm:ss")} </>}
                </div>
            </div>
            <div className="edit_source_product_feed_menu">
                <i className="fas fa-sync-alt" onClick={(e) => clickOnSync()}></i>
                <i className="fas fa-pen" onClick={(e) => { setIsConfigurationSourceMode(true); setNewProductFeed(productFeedConf); setIsFirstRender(true); setAccessValue(dataSourceConf) }}></i>
                <i className="fas fa-trash" onClick={(e) => setPoppinIsOppen(true)}></i>
            </div>

        </div>
    )
}
