import formatMoment from '../../../../Constants/FormatMoment';

const { formatDateToLocal } = formatMoment;
const today = moment();

export function getOperationStatus(operation) {
	const dateBeginOfOperation = formatDateToLocal(operation.startDate);
	const dateEndOfOperation = formatDateToLocal(operation.endDate);
	const isStoppedBoolOperation = operation.isStopped;
	const isTestModeOperationBool = operation.isTestMode;

	if (isStoppedBoolOperation) {
		return { id: 'Stopped', label: 'Stopped', cssClass: 'status-stopped' };
	}

	if (isTestModeOperationBool) {
		return { id: 'testing', label: 'Draft', cssClass: 'status-testing' };
	}

	if (operation.endDate && dateEndOfOperation < today) {
		return { id: 'past', label: 'Past', cssClass: 'status-past' };
	}

	if (dateBeginOfOperation > today) {
		return { id: 'planned', label: 'Scheduled', cssClass: 'status-planned' };
	}

	return { id: 'ongoing', label: 'Ongoing', cssClass: 'status-ongoing' };
}


export function arraysAreEqual(a, b) {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (var i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	}
	return true;
}

export function sortByPropText(getProp) {
	return (a, b) => {
		if (a && b) {
			const propA = getProp(a);
			const propB = getProp(b);
			if (propA && propB) {
				if (propA.toUpperCase() < propB.toUpperCase()) return -1;
				if (propA.toUpperCase() > propB.toUpperCase()) return 1;
			}
		}
		return 0;
	};
}