import React, {useState, useEffect} from 'react';
import Btn from '../../../../Components/Btn';

import Panel from '../../../../Components/Panel';
import {useGeoContext} from '../../context/GeolocContext';

import {

	initialUtmFilterV2
} from './config';
import UtmContainerPages from './UtmContainerPages';

export default function UtmFiltersPagesContainer({sessionService}) {
	const {

		utmDimensionsSelected,
		hansleSaveUtmDimensionFilter,
		resetUtmSelected,
		setOffset,
		setCurrentPage
	} = useGeoContext();

	const [panelIsOpen, setPanelIsOpen] = useState(false);
	const [utmDimensionDraft, setutmDimensionDraft] = useState(utmDimensionsSelected);
	const [button, setButton] = useState(<></>);

	function getLabelOfUtmFilters() {
		let label = '';

		let nb = 0;

		for (const prop in utmDimensionsSelected) {

			const value = utmDimensionsSelected[prop];
			nb = nb + value.length;
			if (value && value.length > 0) {
				if (label === '') label = 'UTM ' + prop + '=' + value[0].name;
			}
		}

		if (nb == 0) return 'All';
		if (nb == 1) return label;
		return label + ' +' + (nb - 1);
	}

	function hasUTMFilter() {
		for (const prop in utmDimensionsSelected) {
			const value = utmDimensionsSelected[prop];
			if (value && value.length !== 0) {
				return true;
			}
		}
	}


	useEffect(() => {
		const tmpHasUTMFilter = hasUTMFilter();
		setButton(<div
			className={tmpHasUTMFilter ? 'analytics_range active' : 'analytics_range'}
			onClick={() => setPanelIsOpen(true)}>
			<div className="flex">
				<div className="flex_item_full">
					<div className="analytics_range_sup">UTM</div>
					<div className="analytics_range_value">
						{utmDimensionsSelected && <>{getLabelOfUtmFilters()}</>}
					</div>
				</div>
				<div className="flex_item_fix analytics_range_drop_arrow"></div>
			</div>
		</div>);
	}, [utmDimensionsSelected, panelIsOpen]);

	function closePanel() {
		setPanelIsOpen(false);
	}

	function reset() {
		setutmDimensionDraft(initialUtmFilterV2);
		hansleSaveUtmDimensionFilter(initialUtmFilterV2);
		resetUtmSelected();
		setPanelIsOpen(false);
		setOffset(0);
		setCurrentPage(1);
	}

	function saveSettings() {
		hansleSaveUtmDimensionFilter(utmDimensionDraft);
		setPanelIsOpen(false);
	}

	function changeUTMSelected(value, utmType, i) {
		const utmItem = {
			name: value,
			label: value
		};
		const utmToChange = [...utmDimensionDraft[utmType]];
		utmToChange.splice(i, 1, utmItem);
		const newUtm = dimensions => ({
			...dimensions,
			[utmType]: utmToChange
		});
		setutmDimensionDraft(newUtm);
	}

	function deleteUtmGroupFilter(utmType) {
		const newUtm = dimensions => ({
			...dimensions,
			[utmType]: []
		});
		setutmDimensionDraft(newUtm);
	}

	function deleteUtmItem(utmType, i) {
		const utmToChange = [...utmDimensionDraft[utmType]];
		utmToChange.splice(i, 1);
		const newUtm = dimensions => ({
			...dimensions,
			[utmType]: utmToChange
		});
		setutmDimensionDraft(newUtm);
	}

	return (
		<>
			{button}
			<Panel
				isOpen={panelIsOpen}
				onClose={(e) => setPanelIsOpen(false)}
				noClose={true}
				side="right"
				width={420}>

				<>
					<div className="modal_header has_border flex">
						<div className="flex_item_fix">
							<a className="panel_close panel_close_left" onClick={closePanel}></a>
						</div>
						<div className="flex_item_full">
							Filters
						</div>
						<div className="flex_item_fix ml_30">
							<Btn
								onClick={reset}
								message="Reset"
								style="ghost"
								color="secondary"
							/>
							<Btn
								onClick={saveSettings}
								message="Apply"
								color="primary"
							/>
						</div>
					</div>
					<div className="modal_body modal_body_grey">
						<UtmContainerPages
							sessionsServices={sessionService}
							utmDimensionDraft={utmDimensionDraft}
							changeUTMSelected={changeUTMSelected}
							utmKeyName="UtmSource"
							nameUtm={'source'}
							deleteUtmItem={deleteUtmItem}
							deleteUtmGroupFilter={() => deleteUtmGroupFilter('source')}
						/>
						<UtmContainerPages
							sessionsServices={sessionService}
							utmDimensionDraft={utmDimensionDraft}
							changeUTMSelected={changeUTMSelected}
							utmKeyName="UtmMedium"
							nameUtm={'medium'}
							deleteUtmItem={deleteUtmItem}
							deleteUtmGroupFilter={() => deleteUtmGroupFilter('medium')}
						/>
						<UtmContainerPages
							sessionsServices={sessionService}
							utmDimensionDraft={utmDimensionDraft}
							changeUTMSelected={changeUTMSelected}
							utmKeyName="UtmCampaign"
							nameUtm={'campaign'}
							deleteUtmItem={deleteUtmItem}
							deleteUtmGroupFilter={() => deleteUtmGroupFilter('campaign')}
						/>
						<UtmContainerPages
							sessionsServices={sessionService}
							utmDimensionDraft={utmDimensionDraft}
							changeUTMSelected={changeUTMSelected}
							utmKeyName="UtmContent"
							nameUtm={'content'}
							deleteUtmItem={deleteUtmItem}
							deleteUtmGroupFilter={() => deleteUtmGroupFilter('content')}
						/>
					</div>
				</>

			</Panel>
		</>
	);
}
