const styles = `
	.BY_BLD .by_column {
		padding: 10px;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.BY_BLD .by_column[data-by-col="1"] { width: calc(100% * 1 / 12) }
	.BY_BLD .by_column[data-by-col="2"] { width: calc(100% * 2 / 12) }
	.BY_BLD .by_column[data-by-col="3"] { width: calc(100% * 3 / 12) }
	.BY_BLD .by_column[data-by-col="4"] { width: calc(100% * 4 / 12) }
	.BY_BLD .by_column[data-by-col="5"] { width: calc(100% * 5 / 12) }
	.BY_BLD .by_column[data-by-col="6"] { width: calc(100% * 6 / 12) }
	.BY_BLD .by_column[data-by-col="7"] { width: calc(100% * 7 / 12) }
	.BY_BLD .by_column[data-by-col="8"] { width: calc(100% * 8 / 12) }
	.BY_BLD .by_column[data-by-col="9"] { width: calc(100% * 9 / 12) }
	.BY_BLD .by_column[data-by-col="10"] { width: calc(100% * 10 / 12) }
	.BY_BLD .by_column[data-by-col="11"] { width: calc(100% * 11 / 12) }
	.BY_BLD .by_column[data-by-col="12"] { width: calc(100% * 12 / 12) }
`;

const icon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" viewBox="0 0 40 30">
  <defs>
    <clipPath id="clip-ico_column">
      <rect width="40" height="30"/>
    </clipPath>
  </defs>
  <g id="ico_column" clip-path="url(#clip-ico_column)">
    <g id="Rectangle_1169" data-name="Rectangle 1169" transform="translate(7 2)" fill="#fff" stroke="#acafc0" stroke-width="2" stroke-dasharray="3">
      <rect width="26" height="26" rx="2" stroke="none"/>
      <rect x="1" y="1" width="24" height="24" rx="1" fill="none"/>
    </g>
    <g id="Rectangle_1169-2" data-name="Rectangle 1169" transform="translate(7 2)" fill="#e9eaf0" stroke="#acafc0" stroke-width="2">
      <rect width="14" height="26" rx="2" stroke="none"/>
      <rect x="1" y="1" width="12" height="24" rx="1" fill="none"/>
    </g>
  </g>
</svg>
`;

let resizeTimeout;

export const componentColumn = {
	isComponent: el => {
		return el && el.classList && el.classList.contains('by-js-column');
	},
	model: {
		init() {
			this.attributes.resizable.updateTarget = this.defaults.resizable.updateTarget;
		},
		defaults: {
			name: 'Column',
			droppable: true,
			editable: true,
			layerable: true,
			draggable: false,
			removable: true,
			resizable: {
				cl: true,
				cr: true,
				bl: false,
				br: false,
				bc: true,
				tl: false,
				tr: false,
				tc: true,
				step: 1,
				updateTarget: function(element, rect, context) {
					let parentEl = element.parentElement;
					
					if (context.selectedHandler == 'bc' || context.selectedHandler == 'tc') {

						const currentModel = element.__gjsv.model;
						const isMobile = currentModel.em.attributes.device == 'mobile';
						const el = isMobile ? element : parentEl;

						el.style.minHeight = rect.h + 'px';
						
						if (resizeTimeout) clearTimeout(resizeTimeout);
						resizeTimeout = setTimeout(() => {
							const h = el.style.minHeight;
							const model = el.__gjsv.model;
							const css = model.getStyle();
							css['min-height'] = h;
							model.setStyle(css);
							el.style.minHeight = '';
						}, 500);

						return;
					}

					if (context.selectedHandler == 'cr' || context.selectedHandler == 'cl') {
						const parentWidth = context.resizer.parentDim.w;
						let sideEl;
						let otherEls = parentEl.querySelectorAll('.by-js-column');
						let availableCols = 12;

						if (context.selectedHandler == 'cr') {
							sideEl = element.nextElementSibling;
						}
						if (context.selectedHandler == 'cl') {
							sideEl = element.previousElementSibling;
						}
						if (!sideEl) return;

						for (let i = 0; i < otherEls.length; i++) {
							const o = otherEls[i];
							if (o === element) continue;
							if (o === sideEl) continue;
							const col = o.dataset.byCol;
							availableCols -= parseInt(col || 1);
						}

						const elWidth = rect.w;
						const elColNb = Math.round(12 / (parentWidth / elWidth));
						const sideElColNb = availableCols - elColNb;
						if (elColNb < 1) return; 
						if (sideElColNb < 1) return;
						
						element.dataset.byCol = elColNb;
						sideEl.dataset.byCol = sideElColNb;

						if (resizeTimeout) clearTimeout(resizeTimeout);
						resizeTimeout = setTimeout(() => {
							const elModel = element.__gjsv.model;
							const sideModel = sideEl.__gjsv.model;
							elModel.setAttributes({'data-by-col': element.dataset.byCol});
							sideModel.setAttributes({'data-by-col': sideEl.dataset.byCol});
						}, 500);
					}
				}
			},
			tagName: 'div',
			attributes: {
				class: 'by_column by-js-column',
				'data-by-col': '6'
			},
			traits: [
				{
					type: 'responsive',
					name: 'responsive',
					label: 'Responsive',
					changeProp: true,
					tab: 'responsive'
				}
			],
			stylable: ['background-color', 'background-image', 'padding', 'border', 'border-radius', 'justify-content'],
			styles: styles,
			style: {
				'padding': '10px',
				'background-repeat': 'no-repeat',
				'background-position': 'center',
				'background-size': 'cover',
			},
			'script-props': [

			],
			icon: icon
		}
	}
};
