import React, {useState,useRef, useEffect} from 'react';

import Panel from '../../../../Components/Panel';
import SectionMessage from '../../../../Components/SectionMessage';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import Btn from '../../../../Components/Btn';
import { CampaignsContext } from '../../context/index.js';
import FormCustomContainerHook from '../../../Campaigns/FormCustom2/FormCustomContainerHook';

export default function PanelForm() {

	const {
		panelFormIsOpen,
		handleOpenClosePanelForm,
		confirmFormData,
		
	} = CampaignsContext();
	const [formData, setFormData] = useState<string | object>();
	const refElement = useRef<HTMLParagraphElement>(null);

	const [hasErrorsOnQuestion, sethasErrorsOnQuestion] = useState(false);
	function checkAllField(formData){
		const sections = JSON.parse(formData);
		let isErr = false;
		sections.sections.forEach((section) => {
			section.panels.map(element => {
				if(element.type === 3 && element.question !== ''){
					if(!element.hasOwnProperty('buttonType')){
						element['buttonType'] = 'submit';
					}
					
					element.name = element.question;					
				}
				else if(element.type === 8 && element.name !== '' && element.question === ''){
					element.question = element.name;
				}
				else if (element.question === ''){
					isErr = true;
				}
				
			});
		}) ;
		if(!isErr){
			confirmFormData(JSON.stringify(sections));
		}
		return isErr;
		
	}
	function handleChanfeFormFata(){
		const hasErrors = checkAllField(formData);
		if(!hasErrors){
			confirmFormData(formData);
			sethasErrorsOnQuestion(false);
		}else{
			if (refElement.current) {
				window.scrollTo(0, 0);
			}
			sethasErrorsOnQuestion(true);
		}
	}
	useEffect(()=>{
		sethasErrorsOnQuestion(false);
	},[panelFormIsOpen]);
	return (
		<Panel
			width="960"
			isOpen={panelFormIsOpen}
			onClose={() => handleOpenClosePanelForm(false)}
			noClose={true}
		>
			<ModalHeader>
				<div className='flex' >
					<div className="flex_item_fix">
						<a className="panel_close panel_close_left" onClick={() => handleOpenClosePanelForm(false)}></a>
					</div>
					<div className='flex_item_full'>
						Custom form editor
					</div>
					<div className='flex_item_fix ml_30'>
						<Btn
							message="Confirm"
							color="primary"
							onClick={()=>handleChanfeFormFata()}
						/>
					</div>
				</div>
			</ModalHeader>
			<ModalBody color='grey' position='relative'>
				{hasErrorsOnQuestion && 
					<SectionMessage
						type="warning"
						appearance='background'
						noMargin={true}
						text={
							<>
							Each form field must have the question input completed !
							</>
						}
					/>
				}
				<FormCustomContainerHook setFormData={setFormData}/>
			</ModalBody>
		</Panel>
	);
}
