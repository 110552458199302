import React, { createContext, useContext, useEffect, useState,Dispatch,SetStateAction ,useCallback} from 'react';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import PersonaServices from '../../../../Services/PersonaServices';
import SegmentGroupServices from '../../../../Services/SegmentGroupServices';
import {
	EditingTrigger,
	EditingSegmentGroupWithTriggers
} from './EntityTypes';
import {
	CreateSegmentGroupRequest,
	// SegmentGroupActivationStatus,
	UpdateSegmentGroupRequest,
	TriggerGroup,
	SegmentGroupStatus
} from '../../../../Services/SegmentGroupServicesTypes';
import {EditorCreateOrUpdateMode} from './EditorTypes';

const SegmentGroupEditorContext = createContext<SegmentGroupEditorContextType | undefined>(undefined);

function useSegmentGroupEditorContext() {
	const context = useContext(SegmentGroupEditorContext);
	if (!context) throw Error('useSegmentGroupEditorContext can only be used inside a SegmentGroupEditorContextProvider');
	return context;
}

type CreateSegmentGroupEditorContextProviderType = {
	$http: any,
	$rootScope: any,
	$routeParams: any,
	$timeout: any,
	children: any,
	triggers: EditingTrigger[],
	loadedSegmentation: EditingSegmentGroupWithTriggers,
	isLoading: boolean
}

type ErrorType = {
	key: string,
	message: string
}

const CreateSegmentGroupEditorContextProvider = (props: CreateSegmentGroupEditorContextProviderType) => {

	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	// const isAdmin = impersonatingServices.isAnAdmin();
	const $routeParams = props.$routeParams;
	console.log('🚀🐱 😻 --///** ~ file: SegmentGroupEditorContainerProvider.tsx:52 ~ CreateSegmentGroupEditorContextProvider ~ $routeParams:', $routeParams)
	const segmentGroupServices = new SegmentGroupServices(accountId, props.$http);
	const personaServices = new PersonaServices(props.$http);
	const [status, setstatus] = useState<SegmentGroupStatus>('Stopped');
	const [shouldSavePersonaError, setShouldSavePersonaError] = useState<boolean>(false);
	const [shouldSetSaveModeError, setShouldSetSaveModeError] = useState<boolean>(false);
	const [segmentationName, setSegmentationName] = useState<string>('');
	const [segmentGroupId, setSegmentGroupId] = useState<string | null>(null);

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorType[]>([]);
	const [cloneSegmentationEdition, setcloneSegmentationEdition] = useState<string | undefined>();

	const mode: EditorCreateOrUpdateMode = !$routeParams.id ? EditorCreateOrUpdateMode.Create : EditorCreateOrUpdateMode.Update;
	const getArrayOfTriggers = (triggers:EditingTrigger[]): TriggerGroup[] =>{
		const triggerMap = new Map<string, TriggerGroup>();

		triggers.forEach(trigger => {
			const groupId = trigger.Group.Id;
			const triggerData = {
				name: trigger.Name,
				operatorName: trigger.OperatorName,
				value: trigger.ValueToCompare,
			};

			if (triggerMap.has(groupId)) {
				triggerMap.get(groupId)!.triggers.push(triggerData);
			} else {
				triggerMap.set(groupId, {
					groupId: groupId,
					triggers: [triggerData],
				});
			}
		});

		return Array.from(triggerMap.values());

	};
	
	const handleSaveSegmententation = (segmentationName) => {
		setShouldSavePersonaError(false);
		setShouldSetSaveModeError(false);
		setIsSaving(true);

		personaServices.getPersonasList(accountId, 'OnSite', _savedPersonaList => {
			const triggerGroup = getArrayOfTriggers(props.triggers);
			const segmentation: CreateSegmentGroupRequest = {
				triggerGroups: triggerGroup,
				status: status,
				name: segmentationName
			};
		
			segmentGroupServices.setSegmentGroup(segmentation,
				() => {
					systemServices.showSuccess('Segmentation has been saved with success');
					setIsSaving(false);
					getReturnLink();
				},
				() => {
					setShouldSetSaveModeError(true);
					setIsSaving(false);
				},
				err => {
					console.log(err);
					systemServices.showError('Error while saving Segmentation');
					setIsSaving(false);
				});

		}, err => {
			console.log(err);
			systemServices.showError('Error while saving Segmentation');
			setIsSaving(false);
		});
	};
	const handleUpdateSegmententation = (segmentationName) => {
		setShouldSavePersonaError(false);
		setShouldSetSaveModeError(false);
		setIsSaving(true);

		personaServices.getPersonasList(accountId, 'OnSite', _savedPersonaList => {

			const triggerGroup = getArrayOfTriggers(props.triggers);

			const segmentation: UpdateSegmentGroupRequest = {
				triggerGroups: triggerGroup,
				status: null,
				name: segmentationName
			};
		
			segmentGroupServices.updateSegmentGroup(segmentation,segmentGroupId,
				() => {
					systemServices.showSuccess('Segmentation has been saved with success');
					setIsSaving(false);
					getReturnLink();
				},
				() => {
					setShouldSetSaveModeError(true);
					setIsSaving(false);
				},
				err => {
					console.log(err);
					systemServices.showError('Error while saving Segmentation');
					setIsSaving(false);
				});

		}, err => {
			console.log(err);
			systemServices.showError('Error while saving Segmentation');
			setIsSaving(false);
		});
	};

	useEffect(() => {
		if (!props.isLoading && props.loadedSegmentation){
			console.log('🚀🐱 😻 --///** ~ file: SegmentGroupEditorContainerProvider.tsx:165 ~ useEffect ~ loadedSegmentation:', props.loadedSegmentation)
			setSegmentGroupId(props.loadedSegmentation.id);
			
			const newTriggerGroup = props.loadedSegmentation.triggerGroups.map(trigger => {
				return {
					groupId: trigger.groupId,
					triggers: trigger.triggers
				};
			});
			
			const currentSegmentsJSON = JSON.stringify({
				triggerGroups: newTriggerGroup,
				// status:  props.loadedSegmentation.status,
				name:  props.loadedSegmentation.name
			});
			setstatus( props.loadedSegmentation.status ? props.loadedSegmentation.status: 'Stopped');
			const cloneSegments = currentSegmentsJSON;
			setcloneSegmentationEdition(cloneSegments);
			setSegmentationName(props.loadedSegmentation.name);
		}
	}, [props.isLoading, props.loadedSegmentation]);


	const isAnError = (name: string): [boolean, string | null] => {
		const errorsByName = errors.map(er => er.key);
		const isError = errorsByName.includes(name) ? errors.find(er => er.key === name)?.message ?? null : null;
		return [!!isError, isError];
	};

	const putError = (key: string, message: string): void => {
		setErrors(x => x.filter(e => e.key !== key).concat({ key: key, message: message }));
	};

	const resetError = (key: string): void => {
		setErrors(x => x.filter(e => e.key !== key));
	};

	const handleSetSegmentationName = (name: string): void => {
		if (!name) {
			putError('SegmentationName', 'missingName');
		} else {
			resetError('SegmentationName');
		}
		setSegmentationName(name);
	};

	const handleOverwrite = () => {
		handleSaveSegmententation(segmentationName);
	};

	const handleDuplicate = () => {
		handleSaveSegmententation(segmentationName);
	};
	const getReturnLink = () => {
		const params =  props.$routeParams;
		const url = params.provider === 'ranking' ? '/ProductSettings/RankingSegmentation' : '/Insight/segment/settings';
		const ka = props.$routeParams.ka;

		if(ka){
			return window.location.href = `${url}?ka=${ka}`;
		}else{
			return window.location.href = url;
		}
	};
	const isSame  = useCallback(() => {
		if(cloneSegmentationEdition){
			const triggerGroup = getArrayOfTriggers(props.triggers);
			const currentSegments = JSON.stringify({
				triggerGroups: triggerGroup,
				// status: status,
				name: segmentationName
			}) ;

			const cloneAndNewValues = cloneSegmentationEdition === currentSegments;

			return cloneAndNewValues;

		}else{
			return false;
		}
	},[props.triggers,segmentationName] );
	const triggers = props.triggers;
	const context: SegmentGroupEditorContextType = {
		shouldSavePersonaError,
		shouldSetSaveModeError,
		handleOverwrite,
		handleDuplicate,
		handleSaveSegmententation,
		segmentationName,
		handleSetSegmentationName,
		isAnError,
		hasErrors: () => errors.length > 0,
		isSaving,
		mode,
		getReturnLink,
		setShouldSavePersonaError,
		setIsSaving,
		handleUpdateSegmententation,
		isSame,
		triggers
	};

	return (
		<SegmentGroupEditorContext.Provider
			value={context}>
			{props.children}
		</SegmentGroupEditorContext.Provider>
	);

};

type SegmentGroupEditorContextType = {
	isSaving: boolean;
	shouldSavePersonaError: boolean;
	shouldSetSaveModeError: boolean;
	segmentationName: string;
	handleOverwrite: () => void;
	handleDuplicate: () => void;
	handleSaveSegmententation: (name:string) => void;
	handleSetSegmentationName: (name: string) => void;
	handleUpdateSegmententation:  (name: string) => void;
	isAnError: (name: string) => [boolean, string | null];
	hasErrors: () => boolean;
	mode:string;
	getReturnLink: ()=> void;
	setShouldSavePersonaError: Dispatch<SetStateAction<boolean>>;
	setIsSaving:Dispatch<SetStateAction<boolean>>;
	isSame: ()=> boolean;
	triggers: any
}


export default CreateSegmentGroupEditorContextProvider;

export { useSegmentGroupEditorContext };
