import React, { Component } from 'react';
import { react2angular } from 'react2angular';
import styles from './PromoCodesListContainer.module.css';
import PromoCodeServices from '../../Services/PromoCodeServices';
import SystemServices from '../../Services/SystemServices';
import Spinner from '../../Components/Spinner';

const statusCodes = [
    {
        code: 0,
        label: 'waiting activation',
        style: 'primary'
    },
    {
        code: 1,
        label: 'active',
        style: 'success'
    },
    {
        code: 2,
        label: 'failed',
        style: 'warning'
    }
];

function PromoCodesList(props) {
    return <table className={props.styles.table}>
        <thead>
            <tr>
                <th>Campaign key</th>
                <th>Initial number</th>
                <th>Used number</th>
                <th>Status</th>
                <th>Start date</th>
            </tr>
        </thead>
        <tbody>
            {props.promoCodes.map(promoCode => {
                const status = props.statusCodes.find(status => status.code === promoCode.status);
                
                return <tr key={promoCode.campaignKey}>
                    <td>{promoCode.campaignKey}</td>
                    <td>{promoCode.initialNumberOfUniqueCodes}</td>
                    <td>{promoCode.numberOfUsedUniqueCodes}</td>
                    <td>
                        <span className={`label label-${status.style} ${props.styles.statusLabel}`}>
                            {status.label}
                        </span>
                    </td>
                    <td>{moment(promoCode.creationDateUtc).format('YYYY/MM/DD')}</td>
                </tr>
            })}
        </tbody>
    </table>
}

export default class PromoCodesListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promoCodes: [],
            loading: false
        };
        this.promoCodeServices = new PromoCodeServices(this.props.$http);
        this.systemServices = new SystemServices(this.props.$rootScope);
        this.authenticatedUserKey = this.props.$rootScope.User.Account.Key;
    }

    getAllPromoCodes() {
        this.setState({loading: true});
        this.promoCodeServices.getAllPromoCodes(
            this.authenticatedUserKey,
            promoCodes => {
                this.setState({promoCodes});
                this.setState({loading: false});
            },
            error => {
                this.systemServices.showError(error);
            }
        );
    }

    componentDidMount() {
        this.getAllPromoCodes();
    }

    render() {
        return <section className="modules-inner">
            <header className="modules-header">
                <h2>Promo codes</h2>
            </header>

            {this.state.loading ? (
                <Spinner />
            ) : (
                (this.state.promoCodes.length > 0 ? (
                    <PromoCodesList
                        styles={styles}
                        statusCodes={statusCodes}
                        promoCodes={this.state.promoCodes} /> 
                ) : (
                    <a href="/CampaignsPromoCode/Create" className="btt btt-blue">
                        <i className="fa fa-plus"></i> Create a promo codes campaign
                    </a>
                ))
            )}
        </section>
    }
}

angular
    .module('beyableSaasApp.PromoCode')
    .component('promoCodesList', react2angular(PromoCodesListContainer, [], ['$http', '$rootScope']));