import React, { createContext, useState, useEffect, useContext, useReducer,useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { mapCampaign  } from '../../utils';

const tooltipID = uuidv4();

export const context = createContext();

export function abTestsDashboardContext() {
	return useContext(context);
}
const SSE = require('sse.js');
const initialDevices = [];
function reducer ( state, action) {
	switch (action.type) {
		case 'ADD_DEVICE':
			return [...state, action.payload];
		case 'DELETE_DEVICE':
			return [...state.filter(x => x !== action.payload)];
		case 'ITILIAZED_DEVICES':
			return initialDevices;
		default:
			break;
	}
}

export const ContextProvider = ({ ka,ku,deleteCampaignById,changeStatusCampaign,labelServices, accountId,systemServices ,ipAdressesServices,ReportingCampaignServices,campaignCreatingServices,page,$routeParams,authServices,children }) => {
	
	const useFake = false;
	const [loading, setLoading] = useState(true);
	const [pendingCampaigns, setPendingCampaigns] = useState([]);
	const [skipIdNumber, setskipIdNumber] = useState(0);
	const [completedCampaigns, setCompletedCampaigns] = useState([]);
	const [filterLabelId, setfilterLabelId] = useState();
	const [completedCampaignsPaging, setCompletedCampaignsPaging] = useState({ skipIdNumber: 0, totalcount: 0, pageSize: 10 });
	const [pageSize, setpageSize] = useState(10);
	const [canEditCampaigns, setCanEditCampaigns] = useState(false);
	
	const [first, setfirst] = useState(true);
	const [totalCount, settotalCount] = useState(0);
	const [totalCountConpleted, settotalCounCompleted] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [modalLabelsCampaignisOpen, setModalLabelsCampaignIsOpen] = useState(false);
	const [labelCampaignSelected, setlabelCampaignSelected] = useState([]);
	const [campaignSelected, setcampaignSelected] = useState();
	const [filterLabel, setfilterLabel] = useState();
	const [LabelsAccount, setLabelsAccount] = useState([]);
	const [currentIP, setCurrentIP] = useState();
	const [campaignId, setcampaignId] = useState();
	const [modalTestModeIsOpen, setModalTestModeIsOpen] = useState(false);
	const [statusArray, setstatusArray] = useState([
		'OnGoing',
		'Scheduled',
		'Stopped',
		'Draft',
	]);
	// const [devicesSelected, setDeviceSelected] = useState([]);
	const [devicesSelected, handleDispatchDecicesSelected] = useReducer(reducer, initialDevices);

	const [needRefetch, setneedRefetch] = useState(false);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const handleChangeValueInSearchBar = (event) => {
		setskipIdNumber(0);
		setCurrentPage(1);
		setValueInSearchBar(event.target.value);
	};
	const [dataIsNotComplete, setdataIsNotComplete] = useState(true);
	const [dataIsNotCompleteForCompletedCampaign, setdataIsNotCompleteForCompetedCampaign] = useState(true);
	function handleChangeCampaignSelected(labelsS){
		const arrayL = labelsS.reduce((acc, curr) => {
			const idxCurr = LabelsAccount.find(x => x.name === curr.name) ;
			if(idxCurr){
				return acc = [...acc,idxCurr];
			}
		}, []);
		setlabelCampaignSelected(arrayL);
	}
	function changeStatusArray(data){
		setskipIdNumber(0);
		setCurrentPage(1);
		setstatusArray(data);
	}
	const openCloseModalTestMode = (bool, campaign) => {
		
		if (bool) {
			setcampaignId(campaign.id);
		} else {
			setModalTestModeIsOpen(false);
		}
	};
	useEffect(()=>{
		// Get current IP address
		fetch('https://api.ipify.org?format=json')
			.then(response => response.json())
			.then(data => setCurrentIP(data.ip));
			
	},[]);
	useEffect(()=>{
		if(campaignId){
			const timer = setTimeout(() => {
				campaignCreatingServices.getCampaignInformations(accountId,campaignId ,data =>{
					setcampaignSelected(data);
					setModalTestModeIsOpen(true);
					setcampaignId();
				});
			}, 800);
			return () => {
				clearTimeout(timer);
			};
		}
	},[campaignId]);
	const changeStatusOfCampaign = (bool, data) => {

		setLoading(true);
		setneedRefetch(!needRefetch);
		ReportingCampaignServices.putStatusCampaign(accountId, data, bool,
			success => {
				setLoading(false);
				setneedRefetch(!needRefetch);
				systemServices.showSuccess(`The campaign has been successfully ${!bool ? 'activated' : 'desactivated'}`);
			},
			error => {
				setLoading(false);
				setneedRefetch(!needRefetch);
				systemServices.showError(`An error occured while ${!bool ? 'activation of ' : 'desactivation of '} the campaign . `);
			});
	};
	var eventSource;
	const controller = new AbortController();
	const signal = controller.signal;
	const loadPendingCampaigns = () => {
		const filters = {
			textSearch: valueInSearchBar,
			statuses : statusArray,
		};
	
		if(devicesSelected.length !== 0){
			filters['devices'] = devicesSelected;
		}
	
		if(filterLabelId.length !== 0){
			filters['labels']  = filterLabelId;
		}
		const authorizationToken = authServices && authServices.getAccessToken();
		eventSource = new SSE.SSE(`${window.BEYABLE_env.BO_API_URL}${accountId}/abtest-campaign?pending=true&archived=false&offset=${skipIdNumber}&limit=10`, {headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + authorizationToken
		},
		payload: JSON.stringify(filters),
		pollingInterval: 25000,
		});
		
		eventSource.addEventListener('Result', (result => {
			const dataResult = JSON.parse(result.data);
			setLoading(false);
			setPendingCampaigns(dataResult.map(x => mapCampaign(x)));
		}));
		eventSource.addEventListener('TotalCount', (total) => {
			settotalCount(JSON.parse(total.data));
		});
		eventSource.addEventListener('readystatechange',eve => {
			if(eve.readyState === 2) {
				setdataIsNotComplete(false);
			}else{
				setdataIsNotComplete(true);
			}
		});
		eventSource.onerror = function(error) {
			setLoading(false);
			console.error('EventSource failed:', error);
			systemServices.showError('An error occured');
		};
	};

	let eventSourceCompleted;
	const loadCompletedCampaigns = () => {
		const filters = {
			textSearch: valueInSearchBar,
		};
		if(devicesSelected.length !== 0){
			filters['devices'] = devicesSelected;
		}
	
		if(filterLabelId.length !== 0){
			filters['labels']  = filterLabelId;
		}

		const authorizationToken = authServices && authServices.getAccessToken();
		eventSourceCompleted = new SSE.SSE(`${window.BEYABLE_env.BO_API_URL}${accountId}/abtest-campaign?pending=false&archived=true&offset=${skipIdNumber}&limit=10`, {headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + authorizationToken
		},
		payload: JSON.stringify(filters),
		pollingInterval: 25000,
		},signal);
		eventSourceCompleted.addEventListener('Result', (result => {
			const dataResult = JSON.parse(result.data);
			setLoading(false);
			setCompletedCampaigns(dataResult.map(x => mapCampaign(x)));
		}));
		eventSourceCompleted.addEventListener('TotalCount', (total) => {
			settotalCounCompleted(JSON.parse(total.data));
		});
		eventSourceCompleted.addEventListener('readystatechange',eve => {
			if(eve.readyState === 2) {
				setdataIsNotCompleteForCompetedCampaign(false);
			}else{
				setdataIsNotCompleteForCompetedCampaign(true);
			}
		});
		eventSourceCompleted.onerror = function(error) {
			setLoading(false);
			console.error('EventSource failed:', error);
			systemServices.showError('An error occured');
		};
	};

	const getLabels = () => {
		labelServices.getLabels(
			accountId,
			'campaign',
			(data) => {
				const filterLabelsStorage = JSON.parse(
					sessionStorage.getItem('onSitelabelsFilter')
				);
				if (filterLabelsStorage) {
					setLabelsAccount(data);
					let filterLabelSelected = [];
					let filterLabelSelectedByIds = [];
					for (let i = 0; i < data.length; i++) {
						if (filterLabelsStorage.includes(data[i].id)) {
							filterLabelSelected.push(data[i]);
							filterLabelSelectedByIds.push(data[i].id);
						}
					}
					setfilterLabel(filterLabelSelected);
					setfilterLabelId(filterLabelSelectedByIds);
				} else {
					setLabelsAccount(data);
					setfilterLabel([]);
					setfilterLabelId([]);
				}
			},
			(err) => {
				console.log('err', err);
				systemServices.showError(t('labels.Error'));
			}
		);
	};
	const getLabelsFirstRender = () => {
		labelServices.getLabels(
			accountId,
			'campaign',
			(data) => {
				setfirst(false);
				// useFake ? fakeLoadCampaigns() : loadCampaigns();
				const filterLabelsStorage = JSON.parse(
					sessionStorage.getItem('onSitelabelsFilter')
				);
				if (filterLabelsStorage) {
					setLabelsAccount(data);
					let filterLabelSelected = [];
					let filterLabelSelectedByIds = [];
					for (let i = 0; i < data.length; i++) {
						if (filterLabelsStorage.includes(data[i].id)) {
							filterLabelSelected.push(data[i]);
							filterLabelSelectedByIds.push(data[i].id);
						}
					}
					setfilterLabel(filterLabelSelected);
					setfilterLabelId(filterLabelSelectedByIds);
				} else {
					setLabelsAccount(data);
					setfilterLabel([]);
					setfilterLabelId([]);
				}
			},
			(err) => {
				console.log('err', err);
				systemServices.showError(t('labels.Error'));
			}
		);
	};

	const handlePendingCampaignsChangePage = (newObj) => {		
		setskipIdNumber(
			newObj
		);
	};

	const handleCompletedCampaignsChangePage = (pageNumber) => {};

	function handleChangeDevicesSelected(newSelection) {
		setskipIdNumber(0);
		setCurrentPage(1);
		const oldD = [...devicesSelected];
		const indefOfVal = oldD.indexOf(newSelection);
		if(indefOfVal !== -1){
			handleDispatchDecicesSelected({type : 'DELETE_DEVICE', payload : newSelection });			
		}else{
			handleDispatchDecicesSelected({type : 'ADD_DEVICE', payload : newSelection });
		}
	}

	const resetDevices = () => {
		setskipIdNumber(0);
		setCurrentPage(1);
		handleDispatchDecicesSelected({type : 'ITILIAZED_DEVICES', value : [] });
	};
	useState(() => {
		getLabelsFirstRender();
	}, []);

	useEffect(()=>{
		if(!first){
			const timer = setTimeout(() => {
				setLoading(true);
				if(page === 'pending'){
					loadPendingCampaigns();
				}else{
					loadCompletedCampaigns();
				}
			}, 800);
			return () => {
				clearTimeout(timer);
				if(eventSourceCompleted){
					eventSourceCompleted.close();
				}
				if(controller){
					controller.abort();
				}
			};
		}
	},[
		statusArray,
		skipIdNumber,
		filterLabelId,
		needRefetch,
		devicesSelected,
		valueInSearchBar,
		page
	]);

	const resetFilterLabels = useCallback(() => {
		setfilterLabel([]);
		setfilterLabelId([]);
		sessionStorage.removeItem('onSitelabelsFilter');
	},[]);

	const toggleFilterLabels = useCallback((e) => {
		if (filterLabelId.includes(e.id)) {
			if(!first){
				setskipIdNumber(0);
				setCurrentPage(1);
			}
			setfilterLabel([...filterLabel.filter((el) => el.id !== e.id)]);
			setfilterLabelId([...filterLabelId.filter((el) => el !== e.id)]);
			sessionStorage.setItem(
				'onSitelabelsFilter',
				JSON.stringify([...filterLabelId.filter((el) => el !== e.id)])
			);
		} else {
			if(!first){
				setskipIdNumber(0);
				setCurrentPage(1);
			}
			setfilterLabel([...filterLabel, e]);
			setfilterLabelId([...filterLabelId, e.id]);
			sessionStorage.setItem(
				'onSitelabelsFilter',
				JSON.stringify([...filterLabelId, e.id])
			);
		}
	},[filterLabelId, first,filterLabel]);
	return (
		<context.Provider
			value={{
				ka,
				ku,
				$routeParams,
				accountId,
				labelServices,
				systemServices,
				changeStatusCampaign,
				deleteCampaignById,
				pendingCampaigns,
				completedCampaigns,
				completedCampaignsPaging,
				loading,
				handlePendingCampaignsChangePage,
				handleCompletedCampaignsChangePage,
				canEditCampaigns,
				setCanEditCampaigns,
				statusArray,
				setstatusArray,
				changeStatusArray,
				totalCount,
				pageSize,
				setpageSize,
				skipIdNumber,
				setskipIdNumber,
				currentPage,
				setCurrentPage,
				filterLabelId,
				setfilterLabelId,
				getLabels,
				labelCampaignSelected,
				filterLabel,
				LabelsAccount,
				setfilterLabel,
				handleChangeCampaignSelected,
				first,
				modalLabelsCampaignisOpen, 
				setModalLabelsCampaignIsOpen,
				setneedRefetch,
				needRefetch,
				devicesSelected,
				handleChangeDevicesSelected,
				resetDevices,
				valueInSearchBar,
				handleChangeValueInSearchBar,
				setcampaignSelected,
				campaignSelected,
				totalCountConpleted,
				ipAdressesServices,
				changeStatusOfCampaign,
				openCloseModalTestMode,
				modalTestModeIsOpen,
				currentIP,
				ReportingCampaignServices,
				campaignId,
				setcampaignId,
				setLoading,
				dataIsNotComplete,
				dataIsNotCompleteForCompletedCampaign,
				resetFilterLabels,
				toggleFilterLabels
			}}
		>
			<ReactTooltip
				Id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={600}
			/>
			{children}
		</context.Provider>
	);
};