import React ,{useState} from 'react';

import Btn from '../../../Components/Btn';

import InputCustom from '../../../Components/InputCustom';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
import SectionMessage from '../../../Components/SectionMessage';
import { FeedCardHeader } from './FeedCardHeader';

const isAnUrl = (url) => {
	if (url) {
		if (url.match(/(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/)) {
			return true;
		} else {
			return false;
		}
	}
};
export default function CreateShopify() {
	const {
		stateOfBackendDetection,
		handleChangeSimpleField,
		productFeedObject,
		handleGetMappingSources,
		messageInfos,
		isEmptyIdentifier,
		handleGoingToMappingShopify
	} = useProductFeedEditionContext();

	type Error ={
		key : string,
		message : string
	};
	const [errors, seterrors] = useState<Array<Error>>([]);
	function handleChangeSimpleFieldFn(e) {
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name, value);
	}

	function isReadyToConnect(productFeedObject){
		const err:Array<Error> = [];
		if(!productFeedObject.apiToken || productFeedObject.apiToken.length === 0) {
			err.push({
				key : 'apiToken',
				message : 'Must not be empty'
			});
		}

		if(productFeedObject.resourceIdentifier){
			if(!isAnUrl(productFeedObject.resourceIdentifier) || productFeedObject.resourceIdentifier.length === 0 ){
				err.push({
					key : 'resourceIdentifier',
					message : 'It must be an url'
				});
			}
		}else{
			err.push({
				key : 'resourceIdentifier',
				message : 'It must be an url'
			});
		}
		if(productFeedObject.productUrlBasePath && productFeedObject.productUrlBasePath.length !== 0){
			if(!isAnUrl(productFeedObject.productUrlBasePath) ){
				err.push({
					key : 'productUrlBasePath',
					message : 'It must be an url'
				});
			}
		}
		
		if(err.length === 0){
			seterrors([]);
			handleGoingToMappingShopify('Shopify');
		}else{
			seterrors(err);
		}
	}
	function findError(errors,k){
		const err = errors.find(x => x.key === k);
		if(err){
			return err.message;
		}else{
			return;
		}
	}

	return (<>
		{stateOfBackendDetection === 'ResourceIdentifier' &&
			<>
				<FeedCardHeader title="What is your feed URL?" />
				<InputCustom
					value={productFeedObject.resourceIdentifier ?? ''}
					type={'text'}
					label={'Your Shopify store URL'}
					name={'resourceIdentifier'}
					placeholder="https://mystore.myshopify.com"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					onEnter={() => handleGetMappingSources()}
					fullWidth={true}
					size="l"
					autoFocus={true}
					isAnError={findError(errors,'resourceIdentifier')}
				/>
				<InputCustom
					value={productFeedObject.apiToken ?? ''}
					type={'text'}
					label={'Shopify API Token'}
					name={'apiToken'}
					placeholder="shpat_xxxxxxxxxxx02"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					onEnter={() => handleGetMappingSources()}
					fullWidth={true}
					size="l"
					autoFocus={true}
					isAnError={findError(errors,'apiToken')}
				/>
				<InputCustom
					value={productFeedObject.productUrlBasePath  ?? ''}
					type={'text'}
					label={'Product base url (leave empty for default)'}
					name={'productUrlBasePath'}
					placeholder="https://mystore.myshopify.com/products"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					onEnter={() => handleGetMappingSources()}
					fullWidth={true}
					size="l"
					autoFocus={true}
					isAnError={findError(errors,'productUrlBasePath')}
				/>
				
				<div>
					{isEmptyIdentifier && 
				<SectionMessage
					type={messageInfos.type}
					title={messageInfos.title}
					text={messageInfos.text}
					verticalSize="s"
				/>
					}
				</div>
				<div className='mt_30 al_right'>
					<Btn
						onClickFunction={() => isReadyToConnect(productFeedObject)}
						size="l"
						message="Connection"
						color="primary" />
				</div>
			</>
		}
	</>
	);
}
