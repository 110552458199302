import React, { useState, useContext, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import useEventBroker from '../../../../Hooks/useEventBroker';
import ZoneCtaModal from './ZoneCtaModal';
import ZoneDeleteModal from './ZoneDeleteModal';
import ZoneSliderSettingsModal from './ZoneSliderSettingsModal';
import ReactTooltip from 'react-tooltip';

export default function ZoneCarousel({ zone, canMakeAssociation, canDefineCTA, isZoneSelected, selectZoneCallback, businessOperationServices,
	systemServices, accountId }) {
	const {
		operation,
		setOperation,
		zoningCustomizeListValues,
		setZoningCustomizeListValues
	} = useContext(CreateBusinessOperationContext);

	const ttId = uuidv4();
	const { publish, subscribe } = useEventBroker();
	const [currentSlide, setCurrentSlide] = useState();
	const [currentLocation, setCurrentLocation] = useState();
	const [newSlide, setNewSlide] = useState();

	const requestChangeCta = () => {
		let slideToChange = currentLocation.extensions.items.find(slide => slide.id === currentSlide.id);

		publish('evt/businessoperations/editZoneCtaRequested', {
			zone: zone, isCreation: false, cta: {
				cta: slideToChange.cta,
				withcta: slideToChange.withcta,
				target: slideToChange.target,
				alt: slideToChange.alt
			}
		});
	};

	const requestInitializeCta = (previousCta) => {
		publish('evt/businessoperations/editZoneCtaRequested', { zone: zone, isCreation: true, cta: previousCta });
	};

	const requestRemoveBannerFromZone = () => {
		publish('evt/businessoperations/removeBannerFromZoneRequested', { zone: zone });
	};

	const requestRemoveEmptySlideFromZone = () => {
		deleteCurrentSlide();
	};

	const requestUpdateSliderSettings = () => {

		publish('evt/businessoperations/updatedSliderSettingsRequested', {
			zone: zone,
			zoneSliderSettings: currentLocation.extensions.sliderSettings,
			defaultZoneSliderSettings: zone.zoneExtensions.sliderSettings
		});
	};

	subscribe('evt/businessoperations/bannerCropped', e => {
		const { _, croppedBanner } = e.detail;
		if (isZoneSelected) {
			bindBanner(croppedBanner);
		}
	}, [isZoneSelected, currentSlide]);

	subscribe('evt/businessoperations/bannerSelected', (e) => {
		const selectedBanner = e.detail;
		if (isZoneSelected) {
			bindBanner(selectedBanner);
		}
	}, [isZoneSelected, currentSlide]);


	useEffect(() => {
		if (!currentSlide && currentLocation && currentLocation.extensions.items && currentLocation.extensions.items.length > 0) {
			setCurrentSlide(currentLocation.extensions.items[0]);
		}
	}, [currentLocation, currentSlide]);

	useEffect(() => {
		if (operation && operation.operation && !currentLocation) {
			const location = operation.operation.locations.find(elem => elem.zoneId === zone.zoneId);

			if (location) {
				const newLocation = {
					...location,
					extensions: {
						...location.extensions,
						items: (location.extensions.items || []).map((x) => {
							return {
								...x,
								id: uuidv4()
							};
						})
					}
				};
				setCurrentLocation(newLocation);
			}
		}
	}, [operation]);


	const changeCurrentSelectedSlide = (slide, e) => {
		setCurrentSlide(slide);
		if (slide.bannerId !== '') {
			selectZoneCallback(e, slide.bannerId, false);
		} else {
			selectZoneCallback(e, null, false);
		}
	};

	useEffect(() => {
		if (newSlide) {
			addCurrentSlide(newSlide);
		}
	}, [newSlide]);

	const addCurrentSlide = (newSlide) => {
		function add(array, value) {
			array.push(value);
			return array;
		}
		const currentSlides = [...currentLocation.extensions.items];
		const newSlides = add(currentSlides, newSlide);
		setNewState(newSlides, newSlide);
	};

	const updateCurrentSlide = (update) => {
		if (!currentSlide) return;

		function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
		const indexOfItem = currentLocation.extensions.items.indexOf(currentSlide);
		const newSlide = update({ ...currentSlide });
		const currentSlides = [...currentLocation.extensions.items];
		const newSlides = replaceAt(currentSlides, indexOfItem, newSlide);
		setNewState(newSlides, newSlide);
	};

	const deleteCurrentSlide = () => {
		if (!currentSlide) return;

		function removeAt(array, index) {
			if (index > -1) {
				array.splice(index, 1);
			}
			return array;
		}
		const indexOfItem = currentLocation.extensions.items.indexOf(currentSlide);
		const currentSlides = [...currentLocation.extensions.items];
		const newSlides = removeAt(currentSlides, indexOfItem);
		setNewState(newSlides, null);
	};

	const saveCta = function (ctaToSave) {
		updateCurrentSlide(slideToChange => {
			return { ...slideToChange, ...ctaToSave };
		});
	};

	const saveSliderSettings = function (sliderSettings) {
		let newLocation = { ...currentLocation };
		newLocation.extensions.sliderSettings = sliderSettings;
		const otherLocations = operation.operation.locations.filter(elem => elem.zoneId !== zone.zoneId);
		let newOperation = { ...operation };
		newOperation.operation.locations = [...otherLocations, newLocation];
		setOperation(newOperation);
		setCurrentLocation(newLocation);
	};

	const deleteBanner = () => {
		deleteCurrentSlide();
	};

	const bindBanner = (banner) => {
		var previousCta = currentSlide && currentSlide.withcta && {
			cta: currentSlide.cta,
			withcta: currentSlide.withcta,
			target: currentSlide.target
		};
		var alt = currentSlide?.alt ?? { alt: '' };
		updateCurrentSlide(slideToChange => {
			slideToChange.imagePath = banner.imagePath;
			slideToChange.bannerId = banner.bannerId;
			return slideToChange;
		});
		requestInitializeCta({...previousCta, ...alt});
	};

	const setNewState = (newSlides, newSlide) => {
		const newExtensions = { ...currentLocation.extensions, items: [...newSlides] };
		const newLocation = { ...currentLocation, extensions: newExtensions };

		const otherLocations = operation.operation.locations.filter(elem => elem.zoneId !== zone.zoneId);
		let newOperation = { ...operation };
		newOperation.operation.locations = [...otherLocations, newLocation];
		setOperation(newOperation);
		setZoningCustomizeListValues([...zoningCustomizeListValues]);
		if (newSlide) {
			changeCurrentSelectedSlide(newSlide);
		}
		else {
			setCurrentSlide();
		}
		setCurrentLocation(newLocation);
	};

	const addSlideToCurrentSlider = () => {
		const newSlideId = uuidv4();
		const newSlide = {
			cta: '',
			target: '',
			withcta: false,
			alt: '',
			imagePath: '',
			bannerId: '',
			id: newSlideId
		};

		if (currentLocation) {
			addCurrentSlide(newSlide);
		} else {
			// if doesnt exist create unique id for slider
			const createSliderBanner = () => {
				const libraryId = operation.operation.libraryId;
				const saveBanner = {
					BannerType: 'Slider',
				};

				businessOperationServices.postBannerLibrary(accountId, libraryId, saveBanner,
					data => {
						let newLocation = {
							zoneId: zone.zoneId,
							bannerId: data.createdBanners[0].bannerId,
							extensions: { items: [] }
						};

						const otherLocations = operation.operation.locations.filter(elem => elem.zoneId !== zone.zoneId);
						let newOperation = { ...operation };
						newOperation.operation.locations = [...otherLocations, newLocation];
						setOperation(newOperation);
						setCurrentLocation(newLocation);
						setNewSlide(newSlide);
					},
					error => {
						systemServices.showError('An error occured during the creation of slider');
					});
			};

			if (!operation.operation.libraryId) {
				businessOperationServices.postCreateLibrary(accountId).then(res => {
					let newOperation = { ...operation };
					newOperation.operation.libraryId = res.data.libraryId;
					setOperation(newOperation);
					createSliderBanner();
				});
			} else {
				createSliderBanner();
			}
		}

	};

	const toggleCurrentSlide = () => {
		if (!currentSlide) return;
		selectZoneCallback(null, currentSlide.bannerId, true);
	};


	// Carousel Slide Dimensions
	let slideWidth = 0;
	let slideHeight = 0;
	let slideWidthPercent = 0;
	let slideHeightPercent = 0;
	if (zone.zoneExtensions.Coordinates && zone.zoneExtensions.carrouselSettings) {
		slideWidth = zone.zoneExtensions.carrouselSettings.slideWidth;
		slideHeight = zone.zoneExtensions.carrouselSettings.slideHeight;
		slideWidthPercent = slideWidth / zone.zoneExtensions.Coordinates.width * 100;
		slideHeightPercent = slideHeight / zone.zoneExtensions.Coordinates.height * 100;
	}

	const zoneRef = useRef();
	let pos;

	const mouseDownHandler = function (e) {
		if (!zoneRef.current) return;

		pos = {
			left: zoneRef.current.scrollLeft,
			top: zoneRef.current.scrollTop,
			x: e.clientX,
			y: e.clientY,
		};

		document.addEventListener('mousemove', mouseMoveHandler);
		document.addEventListener('mouseup', mouseUpHandler);
	};

	const mouseMoveHandler = function (e) {
		if (!zoneRef.current) return;

		// How far the mouse has been moved
		const dx = e.clientX - pos.x;
		const dy = e.clientY - pos.y;

		// Scroll the element
		zoneRef.current.scrollTop = pos.top - dy;
		zoneRef.current.scrollLeft = pos.left - dx;
	};

	const mouseUpHandler = function () {
		if (!zoneRef.current) return;

		document.removeEventListener('mousemove', mouseMoveHandler);
		document.removeEventListener('mouseup', mouseUpHandler);
	};


	return (
		<>
			<ReactTooltip id={ttId} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
			<span onClick={(e) => { e.stopPropagation(); }}>
				<ZoneDeleteModal deleteCallback={deleteBanner} zone={zone}></ZoneDeleteModal>
				<ZoneCtaModal saveCallback={saveCta} zone={zone}></ZoneCtaModal>
				<ZoneSliderSettingsModal saveCallback={saveSliderSettings} zone={zone}></ZoneSliderSettingsModal>
			</span>
			<div className="customizeCarouselZone" style={zone.style} ref={zoneRef} onMouseDown={(e) => mouseDownHandler(e)}>
				{currentLocation && currentLocation.extensions.items && currentLocation.extensions.items.map((slide, slideKey) =>
					<div key={slideKey}
						onClick={(e) => { e.stopPropagation(); changeCurrentSelectedSlide(slide, e); }}
						style={{ 'width': slideWidthPercent + '%', 'height': slideHeightPercent + '%' }}
						className={isZoneSelected && currentSlide && currentSlide.id === slide.id ? 'customizeCarouselSlide selected' : 'customizeCarouselSlide'}>
						{slide.imagePath &&
							<img src={slide.imagePath} />
						}
						{!zone.imagePath &&
							<div className="customizeZoneText">{slideWidth}x{slideHeight}</div>
						}

						{isZoneSelected && currentSlide && currentSlide.id === slide.id &&
							<>
								{currentSlide.imagePath && canMakeAssociation &&
									<a className="customizeZoneRemove"
										title="Remove banner"
										onClick={(e) => { e.stopPropagation(); requestRemoveBannerFromZone(); }}>
										<i className="fas fa-times"></i>
									</a>
								}
								{!currentSlide.imagePath && canMakeAssociation &&
									<a className="customizeZoneRemove"
										title="Remove banner"
										onClick={(e) => { e.stopPropagation(); requestRemoveBannerFromZone(); }}>
										<i className="fas fa-times"></i>
									</a>
								}
								{currentSlide.imagePath && canDefineCTA &&
									<a className="customizeZoneEdit"
										title="Banner settings"
										onClick={(e) => { e.stopPropagation(); requestChangeCta(); }}>
										<i className="fas fa-cog"></i>
									</a>
								}
							</>
						}

					</div>
				)}

				<a className="customizeZoneSliderAdd icon_btn m icon_btn_black"
					onClick={(e) => { e.stopPropagation(); addSlideToCurrentSlider(); }}
					data-for={ttId}
					data-tip={'Add slide'}>
					<i className="fas fa-plus"></i>
				</a>

			</div>
		</>
	);
}