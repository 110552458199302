import React, { useState, useEffect } from 'react';
import formatMoment from '../Constants/FormatMoment';
export default function useStatusOperation({ operation }) {

    const today = moment();
    const [status, setStatus] = useState({});
    const { formatDateToLocal } = formatMoment;
    useEffect(() => {
        const dateBeginOfOperation = formatDateToLocal(operation.startDate);
        const dateEndOfOperation = formatDateToLocal(operation.endDate);
        const isStoppedBoolOperation = operation.isStopped;
        if (operation) {
            //if dateEnd > today
            if (dateEndOfOperation > today) {
                //if begin > today and notStopped
                if (dateBeginOfOperation > today && !isStoppedBoolOperation) {
                    //if excecutionmode == 2
                    if (operation.executionMode === 2) {
                        setStatus({ id: 'testing', label: 'Testing', cssClass: 'planned testing' })
                    } else {
                        setStatus({ id: 'planned', label: 'Scheduled', cssClass: 'planned' })
                    }
                }
                else if (!isStoppedBoolOperation) {
                    if (operation.executionMode === 2) {
                        setStatus({ id: 'testing', label: 'Testing', cssClass: 'testing ongoing' })
                    } else {
                        setStatus({ id: 'ongoing', label: 'Ongoing', cssClass: 'ongoing' })
                    }
                } else {
                    setStatus({ id: 'stopped', label: 'Stopped', cssClass: 'stopped' })
                }
            } else {
                setStatus({ id: 'past', label: 'Past', cssClass: 'past' })
            }
        }
    }, [operation])
    return status
}
