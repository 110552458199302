import React from 'react';
import { useLocation } from 'react-router-dom';
import angular from 'angular';
import { react2angular } from 'react2angular';
import AiInsightsContextProvider from './context/AiInsightsContext';
import AiInsightListContainer from './List/AiInsightsListContainer';

export default function AiInsightsContainer(props) {
  console.log('props',props.$location)
  const location = useLocation(); 
  console.log('location',location);
  if (location.pathname !== '/Insight/AiInsights') {
    return <></>;
  }

  return (
    <AiInsightsContextProvider {...props}>
       <AiInsightListContainer />
    </AiInsightsContextProvider>
  );
}

angular
  .module('beyableSaasApp.AiInsights', [])
  .component(
    'aiInsightsContainer',
    react2angular(AiInsightsContainer, [], ['$rootScope', '$http', '$routeParams', '$timeout', '$location', 'AuthServices'])
  );
