import React from 'react';
import { react2angular } from 'react2angular';
import CreateRuleCompositionContextProvider from '../context/RuleCompositionContextProvider';
import RuleCompositionSettingsContainer from './RuleCompositionSettingsContainer';

export default function RuleCompositionBridge(props) {
	return (

		<CreateRuleCompositionContextProvider
			$http={props.$http}
			$timeout={props.$timeout}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
		>
			<RuleCompositionSettingsContainer />
		</CreateRuleCompositionContextProvider>
	);
}

angular
	.module('beyableSaasApp.RuleCompositionBridge', [])
	.component('rulecompositionbridge', react2angular(RuleCompositionBridge, [], ['$http', '$rootScope', '$timeout', '$routeParams', 'AuthServices']));