import React, {useState, useEffect} from 'react';
import styles from './ChartContainer.module.css';
import {FORMAT} from '../../config';

import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';

import moment from 'moment';
import _ from 'lodash';
import SingleBarChart from '../ChartSwitch/SingleBarChart';
import {useInteractionContext} from '../../context/InteractionContext';
import InteractionSelectMetricTypeContainer from '../Interactions/InteractionSelectMetricTypeContainer';
import {useAnalyticsContext} from '../../context/AnalyticsContextProvider';

const InteractionChartContainer = ({
	interactionServices,
	selectedTimeGranularity,
	setSelectedTimeGranularity,
}) => {
	const {selectedTenant} = useAnalyticsContext();
	const {
		reportTypeSelected,
		isLoadingData,
		setisLoadingData,
		setNeedRefreshgraph,
		metricTypeSelected,
		dataKeySelected,
		navFilter,
		isExactMatch,
		filterExactValue,
		filter2,
		filter2ExactValue,
		sourceType,
		searchValue,
		utmDimensionsSelected,
		needRefreshForUtmFilter,
		selectedCategory,
		deviceSelected,
		excludeFilter,
		categoryIsExactMatch,
		hasValues
	} = useInteractionContext();

	const [hasErrorData, sethasErrorData] = useState(false);
	const [chartData, setChartData] = useState();

	const [timeGranularityOptions, setTimeGranularityOptions] = useState([
		{key: 'Day'},
	]);
	const setupTimeGranularityOptions = () => {
		if (!navFilter) return;
		const dateRange = moment(navFilter.toDate, FORMAT).diff(
			moment(navFilter.fromDate, FORMAT),
			'days'
		);
		let granularityOptions;
		if (dateRange < 14) {
			granularityOptions = [{key: 'Day'}];
		} else if (dateRange < 32) {
			granularityOptions = [
				{key: 'Day'},
				{key: 'MondayWeek', label: 'Week', isDefault: true},
			];
		} else if (dateRange < 64) {
			granularityOptions = [
				{key: 'MondayWeek', label: 'Week'},
				{key: 'Month'},
			];
		} else if (dateRange < 366) {
			granularityOptions = [{key: 'Month'}];
		} else {
			granularityOptions = [{key: 'Year'}];
		}
		setTimeGranularityOptions(granularityOptions);
		return granularityOptions;
	};
	const onHandleChangeTimeGranularity = (granularity) => {
		setSelectedTimeGranularity(granularity);
	};

	const refreshChart = () => {
		const dateRange = moment(navFilter.toDate, FORMAT).diff(
			moment(navFilter.fromDate, FORMAT),
			'days'
		);
		const granularity = dateRange < 14 ? 'day' : selectedTimeGranularity;
		const categoryName = selectedCategory === 'Uncategorized' ? '' : selectedCategory ? selectedCategory : '';
		const isUncategorized = selectedCategory === 'Uncategorized';
		setisLoadingData(true);
		interactionServices.getPageMetricsOverTime(
			deviceSelected,
			metricTypeSelected.key,
			selectedTenant,
			navFilter,
			granularity,
			reportTypeSelected,
			filterExactValue,
			isExactMatch,
			filter2,
			filter2ExactValue,
			false,
			sourceType,
			utmDimensionsSelected,
			categoryName,
			isUncategorized,
			excludeFilter,
			categoryIsExactMatch,
			hasValues,
			(success) => {
				sethasErrorData(false);
				setisLoadingData(false);
				const dateLocal = success.map((x) =>
					moment(x.Date).format('MMM DD YYYY')
				);
				setChartData({
					data: success.map((data) => data.Value),
					dayPeriode: dateLocal,
					name: metricTypeSelected.label,
					isSelectedFilter: false,
				});
				setupTimeGranularityOptions();
				setNeedRefreshgraph(false);
			},
			(error) => {
				console.log(error);
				sethasErrorData(true);
				setisLoadingData(false);
				setNeedRefreshgraph(false);
			}
		);
	};
	useEffect(() => {
		const timer = setTimeout(() => {
			refreshChart();
		}, 500);
	return () => {
		clearTimeout(timer);
	};
	}, [
		navFilter,
		selectedTimeGranularity,
		reportTypeSelected,
		metricTypeSelected,
		dataKeySelected,
		sourceType,
		selectedTenant,
		filterExactValue,
		selectedCategory,
		needRefreshForUtmFilter,
		filter2ExactValue,
		searchValue,
		deviceSelected,
		excludeFilter,
		isExactMatch,
		categoryIsExactMatch,
		hasValues
	]);
	return (
		<div className={styles.containerMedium}>
			<div className="flex mb_30">
				<div className="flex_item_full">
					<InteractionSelectMetricTypeContainer styles={styles}/>
				</div>
				{timeGranularityOptions.length > 1 && (
					<div className="btn_switch ml_10">
						{timeGranularityOptions.map((o) => {
							const isSelected =
                            o.key === selectedTimeGranularity;
							return (
								<Btn
									key={o.key}
									style="outline"
									color="secondary"
									size="xxs"
									className={isSelected ? 'active' : ''}
									onClick={() =>
										onHandleChangeTimeGranularity(o.key)
									}
									message={o.label || o.key}
								/>
							);
						})}
					</div>
				)}
			</div>
			{!isLoadingData && chartData && chartData.data.length == 0 && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_empty.svg"
					/>
					<div className="empty_state_title">
                    No data for this period
					</div>
					<div className="empty_state_text">
                    Maybe try with another period or other filters
					</div>
				</div>
			)}
			<div className={styles.barGraph}>
				<SingleBarChart
					data={chartData}
					isLoadingData={isLoadingData}
					hasErrorData={hasErrorData}
				/>
			</div>
		</div>
	);

};

InteractionChartContainer.displayName = 'InteractionChartContainer';


export default InteractionChartContainer;
