import React, {useState} from 'react';
import './InputCopyToClipBoard.css';
import InputCustom from './InputCustom';
import IconBtn from './IconBtn';

export default function InputCopyToClipBoard({
	value,
	label,
	clipBoardContain, // Deprecated
	onClickFunction, // Optional
	fullSize = false,
	width = 240,
	color
}) {
	const style = {};
	const [isCopied, setIsCopied] = useState(false);

	const handleCopy = () => {
		if (typeof onClickFunction === 'function') {
			// backwards compatibility
			onClickFunction(value);
		} else {
			navigator.clipboard.writeText(value);
		}
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 2000);
	};

	if( !fullSize ){
		style.width = width + 'px';
	}
	return (
		<div style={style}>
			<InputCustom
				label={label}
				value={value}
				fullWidth={true}
				readonly={true}
				color={color}
				append={
					<IconBtn
						icon={isCopied ? 'far fa-check-circle' : 'far fa-clipboard'}
						tooltip="Copy to clipboard"
						onClick={handleCopy}
					/>
				}
			/>
		</div>
	);
}
