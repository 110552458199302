import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Card from '../../Card/Card';
import CardModal from '../../CardModal/CardModal';
import { cardFetchOptions, FORMAT } from '../../../config';
import { isEmpty } from 'lodash';
import CardOverview from '../../Card/CardOverview';
import {
	Quantity
} from '../../Overview/config';
import { useEcommerceOverviewContext } from '../../../context/EcommerceOverviewContext';
export const OverviewCardContainer = ({ 
	service, styles,
	dimensions, selectedTenant,
}) => {

	const [cardsData, setCardsData] = useState({});
	const [enlarge, setEnlarge] = useState(null);
	const [enlargedCardsData, setEnlargedCardsData] = useState({});
	const monday = moment().startOf('isoWeek').format(FORMAT);
	const formatToday = moment().format(FORMAT);
	const {
		navFilter,
		sourceType,
		dimension,
		dimensionId,
		ComparisonSelected,
		metricTypeOverviewSelected,
		onCardClick,
		chips,
		deviceSelected,
		utmDimensionsSelected
	} = useEcommerceOverviewContext();

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!isEmpty(navFilter)) {
				setEnlargedCardsData({});
				getCardsData({});
				setEnlarge(false);
			}
		}, 1500);
		return () => {
			clearTimeout(timer);
		};
	}, [navFilter,
		selectedTenant,
		metricTypeOverviewSelected,
		dimensions,
		sourceType,
		deviceSelected,
		utmDimensionsSelected
	]);

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		if (!isEmpty(navFilter)) {
	// 			getCardsData(cardsData);
	// 		}
	// 	}, 1500);
	// 	return () => {
	// 		clearTimeout(timer);
	// 	};
	// }, [dimensions]);

	const getCardsData = (data) => {
		const fetchOptions = { 
			metrics : [],
			trafficSource : sourceType,
			orderBy : Quantity,
			orderByDesc: true,
			fromDate: '',
			toDate: '',
			device : deviceSelected,
			utmDimensionsSelected : utmDimensionsSelected
			// dimensionId: dimensionId,
		};
		fetchOptions.fromDate = navFilter.fromDate || monday;
		fetchOptions.toDate = navFilter.toDate || formatToday;
		fetchOptions.metrics = [Quantity];
	
		if (data === {}) {
			const cardDataKeyArray = Object.keys(cardsData);
			if (cardDataKeyArray.length >= 0) {
				setCardsData({});
			}
		}

		const options = dimensions.filter(e => e.checked === true && !data[e.key]).reduce((acc, item) => {
			return [...acc, { ...fetchOptions, dimension: [item.key] }];
		}, []);

		const successCb = (name) => (data) => {
			setCardsData((previousData) => {
				return {
					...previousData, [name]: {
						data: data && data.dimensionValues || [],
						loading: false,
						isError: false
					}
				};

			});
		};

		const errorCb = (error, name) => {
			console.log('--Error--', error);
			setCardsData((previousData) => {
				return {
					...previousData, [name]: {
						data: [

							
						], loading: false, isError: true } };
			});
		};


		Promise.all(options.map(async (option) => {

			setCardsData((previousData) => {
				return {
					...previousData, [option.dimension[0]]: {
						loading: true,
					}
				};
			});
				
			await service.getDimensionsOverview(option, successCb(option.dimension[0]), err => errorCb(err, option.dimension[0]));
		}));
	};




	function onSearch(dimensionKey, value) {
		setEnlargedCardsData((previousData) => {
			return {
				...previousData, [dimensionKey]: {
					...previousData[dimensionKey], loading: true
				}
			};
		});
	}

	function onCloseModal() {
		setEnlarge(false);
	}

	return (
		<div className={enlarge ? styles.cardContainer : styles.cardContainerColumns}>
			{
				<>

					{dimensions.map((dimension) => (
						<CardOverview
						deviceSelected={deviceSelected}
							dimensionKey={dimension.key}
							key={dimension.label}
							title={dimension.label}
							dataCard={cardsData[dimension.key]}
							handleClick={onCardClick}
							// handleEnlarge={() => onEnlarge(dimension)}
							isLarge={['LandingPage'].includes(dimension.key) ? true : false}
							metricTypeSessionsSelected={metricTypeOverviewSelected.key}
							chips={chips}
							dimensionId={dimensionId}
						/>
					))}
			
				</>}
		
		</div>
	);
};
