export const getDimensions = () => {
	return [
		{ label: 'Device', key: 'Device', checked: true },
		{ label: 'Browser', key: 'Browser', checked: false },
		{ label: 'Operating system', key: 'OperatingSystem', checked: false },
		{ label: 'Traffic source', key: 'TrafficSource', checked: true },
		{ label: 'UTM medium', key: 'UtmMedium', checked: false },
		{ label: 'UTM source (or gclid)', key: 'UtmSource', checked: false },
		{ label: 'UTM campaign', key: 'UtmCampaign', checked: false },
		//{ label: 'Referrer Medium', key: 'ReferrerMedium', checked: false },
		{ label: 'Referrer', key: 'ReferrerSource', checked: false },
		{ label: 'Timezone', key: 'Timezone', checked: true },
		{ label: 'Language', key: 'Language', checked: false },
		{ label: 'Landing page', key: 'LandingPage', checked: false },
		{ label: 'Country code', key: 'CountryCode', checked: false },
		{ label: 'Country subdivision', key: 'Subdivision', checked: false },
		{ label: 'Geolocalisation - Map', key: 'geolocWorld', checked: false },
	];
};

// HostName,
// Timezone,
// Language,
// LandingPage,
// LandingPageType,
// ExitPage,
// ExitPageType,
// UtmMedium,
// UtmSource,
// UtmCampaign,
// UtmContent,
// Referrer,
// ReferrerSource,
// ReferrerMedium,
// CountryCode,
// OperatingSystem,
// OperatingSystemVersion,
// Browser,
// BrowserVersion,
// ScreenSize,
// Device,
// TrafficSource