import React, {useState, useEffect, useRef} from 'react';
import GrapesJS from 'grapesjs';
import {grapesConfig} from './GrapejsConfig';
import {useBuildEditorContext} from './BuildEditorContext';
import {isEmpty} from 'lodash';
import {CustomAssetManager} from './CustomAssetManager';
// import './styles/grapes.min.css'
// import './styles/grapesj-fonts.css'

export function BuilderEditor() {

	const {
		moduleTemplate,
		setModuleTemplate,
		buildEditor,
		setBuildEditor,
		addComponentTemplate,
		templateComponents,
		getCurrentVariation,
		setIsComponentInjected,
		currentDevice,
		setCurrentDevice,
		setComponentSelected,
		builderUpdate,
		setBuilderUpdate
	} = useBuildEditorContext();

	const currentVariation = getCurrentVariation() || {};

	const currentVariationId = useRef(currentVariation.UId);
    
	const [eventRegistered, setEventRegistered] = useState(false);
	const isBuilderInit = useRef(false);

	const [variationState, setVariationState] = useState();
	const [customAssetManagerIsOpen, setCustomAssetManagerIsOpen] = useState(false);
	const [targetComponent, setTargetComponent] = useState();

	useEffect(() => {
		currentVariationId.current = currentVariation.UId;
	}, [currentVariation]);

	//Update the state and update all the property needed for saving the campaign
	useEffect(() => {
		buildEditor && !isBuilderInit.current && buildEditor.on('update', (obj) => {
			isBuilderInit.current = true;
			setBuilderUpdate(a => ({
				...a,
				x: !a.x,
				initialized: true,
				projectData: buildEditor.getProjectData(),
				html: buildEditor.getHtml(),
				css: buildEditor.getCss(),
				js: buildEditor.getJs(),
				variationId: currentVariationId.current
			}));
		});
	}, [buildEditor]);

	const options = {
		container: '#gjs',
		fromElement: true,
		height: '100%',
		storageManager: false,
		avoidInlineStyle: true,
		protectedCss: grapesConfig.protectedCss,
		// styles: [
		//     './styles/grapes.min.css'
		// ],
		canvasCss: grapesConfig.canvasCss,
		plugins: [templateComponents],
		canvas: {
			frameStyle: '',
		},
		panels: {
			defaults: grapesConfig.panels
		},
		assetManager: {
			custom: true,
		},
		deviceManager: {
			// There is a bug when we set mobile as default
			//default: 'mobile',
			devices: [
				{
					id: 'desktop',
					name: 'Desktop',
					width: '',
				},
				{
					id: 'mobile',
					name: 'Mobile',
					width: '',
					widthMedia: '576px',
				}
			]
		},
		styleManager: {
			appendTo: '#builder_tab_styles',
			sectors: grapesConfig.sectors,
		},
		traitManager: {
			appendTo: '#builder_tab_traits',
		},
		blockManager: {
			appendTo: '#builder_tab_blocks',
			custom: true,
			blocks: grapesConfig.blocks,
		},
		layerManager: {
			appendTo: '#builder_tab_layers',
			root: '.by-js-builder-root',
			showWrapper: false,
			hideTextnode: true,
			hidable: false,
			sortable: false,
		},
		selectorManager: {
			componentFirst: 1,
		},
		pageManager: {
			pages: []
		}
	};

	useEffect(() => {
		if (buildEditor) {
			setBuildEditor(null);
			buildEditor.destroy();
			setIsComponentInjected(false);
			setEventRegistered(false);
			isBuilderInit.current = false;
			setBuilderUpdate({initialized: false});
		}
		setVariationState(currentVariationId.current);
	}, [currentVariationId.current]);
    
	useEffect(()=> {
		if( buildEditor ){
			if(currentVariation && currentVariation.DesignProperties){
				const elementProperty =  currentVariation.DesignProperties.find(x => x.Name === 'BuilderEditor');
				if(!elementProperty){
					
					setBuilderUpdate(a => ({
						...a,
						x: !a.x,
						initialized: true,
						projectData: buildEditor.getProjectData(),
						html: buildEditor.getHtml(),
						css: buildEditor.getCss(),
						js: buildEditor.getJs(),
						variationId: currentVariationId.current
					}));
				}
			}
		}

	},[buildEditor]);
	useEffect(() => {
		if (!isEmpty(currentVariation) && !buildEditor) {
			const loadedBuilderEditor = currentVariation.DesignProperties.find(e => e.Name === 'BuilderEditor');
			const tmpOptions = {...options};
			if (loadedBuilderEditor) {
				setModuleTemplate(loadedBuilderEditor.Value.frame);
				tmpOptions.projectData = loadedBuilderEditor.Value.projectData;
			}
			const editor = GrapesJS.init(tmpOptions);
			if (!loadedBuilderEditor) {
				const um = editor.UndoManager;
				um.stop();
				addComponentTemplate(editor);
				um.start();
			}
			setBuildEditor(editor);
		}
	}, [variationState]);


	if (buildEditor && !eventRegistered) {
		buildEditor.on('load', (obj) => {

			const cm = buildEditor.Commands;
			cm.run('sw-visibility');

			const wrapper = buildEditor.getWrapper();
			if (wrapper) {
				// Open main component layer
				const openedComponent = wrapper.find('.by-js-builder-layer-open')[0];
				if (openedComponent) {
					buildEditor.Layers.setOpen(openedComponent, true);
				}

				// Prevent drop on wrapper
				wrapper.attributes.droppable = false;
				wrapper.attributes.hoverable = false;
				wrapper.attributes.highlightable = false;
				wrapper.attributes.layerable = false;
				wrapper.attributes.selectable = false;
				wrapper.attributes.badgable = false;

				// Remove body tag from html export
				wrapper.toHTML = function (opts) {
					return this.getInnerHTML(opts);
				};
			}

			buildEditor.setDevice(currentDevice);
		});

		// Update current device when it change in the builder
		buildEditor.on('device:select', (obj) => {
			setCurrentDevice(obj.attributes.id);
		});

		// Improve style panel sliders UI
		buildEditor.on('style:property:update', (obj) => {
			if (obj.property.attributes.type === 'slider') {
				const min = obj.property.attributes.min;
				const max = obj.property.attributes.max;
				let value = obj.value;
				const container = obj.property.view.$el[0];
				if (value === '') {
					value = obj.property.attributes.default;
				}
				const range = container.querySelector('input[type="range"]');
				if (range) {
					range.style.setProperty('--value', value);
					range.style.setProperty('--min', min === '' ? '0' : min);
					range.style.setProperty('--max', max === '' ? '100' : max);
				}
			}
		});

		buildEditor.on('component:selected', (component) => {
			setComponentSelected(component);
		});

		buildEditor.on('run:core:open-layers', () => {
			buildEditor.Layers.setRoot('.by-js-builder-root');

			const wrapper = buildEditor.DomComponents.getWrapper();
			if (wrapper) {
				const openedComponent = wrapper.find('.by-js-builder-layer-open')[0];
				if (openedComponent) {
					buildEditor.Layers.setOpen(openedComponent, true);
				}
			}
		});

		buildEditor.Commands.add('open-assets', {
			run(editor, sender, opts = {}) {
				const selectedComp = buildEditor.getSelected();
				setCustomAssetManagerIsOpen(true);
				setTargetComponent(opts.target || selectedComp);
			}
		});

		setEventRegistered(true);
	}

	return (
		<>
			<div className={'builder_body builder_body_' + currentDevice}>
				<div id="gjs"></div>
			</div>
			<CustomAssetManager
				customAssetManagerIsOpen={customAssetManagerIsOpen}
				setCustomAssetManagerIsOpen={setCustomAssetManagerIsOpen}
				targetComponent={targetComponent}
			/>
		</>
	);
}
