
const analyticsTag = (key) => {
    
    let script = ``;
    script += `<script type="text/javascript">\n`;
    script += `    (function (B, e, y, a, b, l, E) {\n`;
    script += `        var beyable = document.createElement('script');\n`;
    script += `        beyable.id = a;\n`;
    script += `        beyable.type = 'text/javascript';\n`;
    script += `        beyable.async = true;\n`;
    script += `        beyable.src = B;\n`;
    script += `        beyable.setAttribute('data-apiKey', y);\n`;
    script += `        beyable.setAttribute('data-api', e);\n`;
    script += `        b && beyable.setAttribute('data-tenants', b);\n`;
    script += `        var s = document.getElementsByTagName('script')[0];\n`;
    script += `        s.parentNode.insertBefore(beyable, s);\n`;
    script += `    })(\n`;
    script += `    'https://tag.beyable.com/api/beYableJS-analytics.js', \n`;
    script += `    'https://front.lytics.beyable.com', \n`;
    script += `    '${key}', \n`;
    script += `    'beyable_analytics');\n`;
    script += `</script>`;
    return script;
}

const readyTag = (accountId, key) => {
    let script = ``;
    script += `<script type="text/javascript">\n`;
    script += `    var beYableKey = '${key}';\n`;
    script += `    (function () {\n`;
    script += `        var beyable = document.createElement('script');\n`;
    script += `        beyable.type = 'text/javascript';\n`;
    script += `        beyable.async = true;\n`;
    script += `        beyable.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'tag.beyable.com/api/beYableJS-${accountId}.js';\n`;
    script += `        var s = document.getElementsByTagName('script')[0];\n`;
    script += `        s.parentNode.insertBefore(beyable, s);\n`;
    script += `    })();\n`;
    script += `</script>`;
    return script;
}

export default { analyticsTag, readyTag };
