
import React, { useState, useEffect, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { useDropzone } from 'react-dropzone';
import Btn from '../Components/Btn';
import ImpersonatingServices from '../Services/ImpersonatingServices';
import VideosServices from '../Services/VideosServices';
import SystemServices from '../Services/SystemServices';
import ProgressBar from '../Components/ProgressBar';

const getFileExtension = (filename) => filename.split('.').pop().toLowerCase();

export default function UploadVideosContainer({
	$rootScope,
	$routeParams,
	$http,
	authServices,
	$timeout,
	data,
	handleChangeUrlVideo,
	label
}) {
	if(!$rootScope){
		return <></>;
	}
	const impersonatingServices = new ImpersonatingServices(
		$rootScope,
		$routeParams
	);
	const systemServices = new SystemServices($rootScope, $timeout);
	const [uploadPercentage, setUploadPercentage] = useState(0);
	const [isUploading, setisUploading] = useState(false);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope.User.Account.Key);
	const videosServices = new VideosServices($http,accountId,authServices);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [videoSrc, setvideoSrc] = useState();
	const [libraryId, setLibraryId] = useState();
	
	const activeStyle = {
		borderColor: '#2196f3',
		color: '#2196f3'
	};
	const acceptStyle = {
		borderColor: '#006fff',
		color: '#006fff'
	};
	const rejectStyle = {
		borderColor: '#ff1744',
		color: '#ff1744'
	};
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		acceptedFiles,
		open
	} = useDropzone({
		accept: '.mp4,.webm,.ogv',
		noClick: true,
		noKeyboard: true,
		onDrop: acceptedFiles => {
			setSelectedFiles(
				acceptedFiles
			);
		}
	});
	
	const style = useMemo(
		() => ({
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject]
	);

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			selectedFiles.forEach(file => URL.revokeObjectURL(file.preview));
		},
		[selectedFiles]
	);
	useEffect(() => {
		const srcInfos = {
			Src : data,
			Type : getFileExtension(data)
		};
		setvideoSrc(srcInfos);
	}, [data]);

	const uploadHandler = (e) => {
		setisUploading(false);
		videosServices.getLibraryId().then(res =>{
			const idLibraryCreate = res?.data?.libraryId;
			setLibraryId(idLibraryCreate);
			const prepareUpload = () => {

				if (selectedFiles.length > 0){
					let formData = new FormData();
					for (let i = 0; i < selectedFiles.length; i++) {
						let fileToRegister = selectedFiles[i];
						formData.append(`file[${i}]`, fileToRegister);
					}
					return formData; 
				}
			};
			const videos = prepareUpload();

			videosServices.postUploadToImageCloud(idLibraryCreate,videos,
				progressEvent => {
					setisUploading(true);
					const progress = progressEvent.loaded / progressEvent.total * 100;
					setUploadPercentage(Math.round(progress));
				}).then(
				res => {
					setvideoSrc();
					const video = [res?.data[0]];
					const srcInfos = {
						...video,
						Src : video[0].url,
						Type : getFileExtension(video[0].fileName)
					};
					setvideoSrc(srcInfos);
					videosServices.postAddCloudImagesToLibrary(idLibraryCreate,video).then(
						res => {
							handleChangeUrlVideo(srcInfos.Src);
							setisUploading(false);	
						}
					).catch((messageErr) => {
						let messageError;
						if (messageErr.response) {
							// The request was made and the server responded with a status code
							// that falls out of the range of 2xx
							messageError = messageErr.response.data.error;
						}
						systemServices.showError(messageError ? messageError :'An error occured');
						setUploadPercentage();
						setisUploading(false);
						setSelectedFiles([]);
					});
				}
			).catch((messageErr )=> {
				systemServices.showError('An error occured');
				setUploadPercentage();
				setisUploading(false);
				setSelectedFiles([]);
			});
		}).catch((messageErr) => {
			systemServices.showError('An error occured');
			setUploadPercentage();
			setisUploading(false);
			setSelectedFiles([]);
		});
	};
	const saveImages = () => {
		if (selectedFiles.length > 0) {
			uploadHandler();
		}
	};
	useEffect(() => {
		saveImages();
	}, [selectedFiles]);
	return (
		<div className='pos_rel' {...getRootProps({ style })}>
			<ReactTooltip
				backgroundColor="black"
				effect="solid"
				place="bottom"
				eventOff={'click'}
				delayShow={0}
			/>
			<div className='flex'>
				{videoSrc && videoSrc.Src &&
					<div className='flex_item_fix mr_10 format_param_thumbnail'>
						<video>
							<source src={videoSrc.Src} type={videoSrc.type} />
						</video>
						<i className='fas fa-play format_param_thumbnail_video_icon' />
					</div>
				}
				<div className='flex_item_full'>
					<div className='form_block_label no_margin'>
						{label || 'Upload video'}
						<a className="icon_btn s"
							data-tip="Accepted formats : .mp4, .webm, .ogv (max. size: 10 Mo)">
							<i className="fas fa-info-circle"/>
						</a>
						<div className="s_13 grey_2 fw_normal">
							{videoSrc && videoSrc.Src ?
								<>Drag and drop a new video</>
								:
								<>Drag and drop your video here</>
							}
						</div>
					</div>
				</div>
				<div className='flex_item_fix ml_10'>
					<input {...getInputProps()} />
					<Btn
						onClick={open}
						style="outline"
						size="s"
						message={videoSrc && videoSrc.Src ? 'Replace' : 'Browse files'}
						disabled={isUploading}
					/>
				</div>
			</div>
			{isUploading &&
				<div className='mt_10'>
					<ProgressBar
						percent={uploadPercentage}
					/>
				</div>
			}
		</div>
	);
}
