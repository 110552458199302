import React from 'react';
export default function InpageElements({
	targetSelector,
	targetPosition
}) {

	const getPositionName = (code) => {
		let name = null;
		switch (code) {
			case 'Replace':
				name = <>The campaign <strong>replaces</strong> the selected target.</>;
				break;
			case 'AdjacentBeforeBegin':
				name = <>The campaign is inserted <strong>before</strong> the selected target.</>;
				break;
			case 'AdjacentAfterBegin':
				name = <>The campaign is inserted <strong>inside of</strong> the selected target <strong>at first position</strong>.</>;
				break;
			case 'AdjacentBeforeEnd':
				name = <>The campaign is inserted <strong>inside of</strong> the selected target <strong>at last position</strong>.</>;
				break;
			case 'AdjacentAfterEnd':
				name = <>The campaign is inserted <strong>after</strong> the selected target.</>;
				break;
			default:
				name = '';
				break;

		}
		return name;
	};

	const positionIsInside = () => {
		let arrayOfPos = ['Replace', 'AdjacentAfterBegin', 'AdjacentBeforeEnd'];
		let filter = arrayOfPos.filter((pos) => pos === targetPosition);

		if (filter && filter.length > 0) return true;
		else return false;
	};

	return (
		<div className="inpage_selector_outro flex">
			<div className="flex_item_fix">
				<div className="inpage_selector_outro_icon">
					<i className="fas fa-check"></i>
				</div>
			</div>
			<div className="flex_item_full inpage_selector_outro_text">
				{getPositionName(targetPosition)}
			</div>
		</div>
	);
}
