import React, { } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import { getOperationStatus } from '../Util';

const BusinessOperationItem = ({ operation, isDragging, handleRemoveOperation }) => {
	const idTooltipTrash = uuidv4();
    
	const statusOperation = getOperationStatus(operation);

	return (
		<>
			<ReactTooltip id={idTooltipTrash} backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
			<div className="flex_item_fix mr_10"
				data-for={idTooltipTrash}
				data-tip="Drag campaign to sort">
				<i className="fas fa-sort s_18 grey_2"></i>
			</div>
			<div className="flex_item_fix mr_10">
				<span className={'status_bullet ' + statusOperation.cssClass}></span>
			</div>
			<div className="flex_item_full ellips">
				{operation.name}
			</div>
			<a className="flex_item_fix ml_10 icon_btn s"
				data-for={idTooltipTrash}
				data-tip="Remove campaign from this group"
				onClick={(e) => handleRemoveOperation(operation)}>
				<i className="fas fa-times"></i>
			</a>
		</>
	);
};


export default BusinessOperationItem;