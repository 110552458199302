import React, { useState } from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/theme/ttcn.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/display/placeholder';
import { jsonTryParse } from '../Utils/TemplateOperations';
import IconBtn from '../../../Components/IconBtn';
import Panel from '../../../Components/Panel';
import Btn from '../../../Components/Btn';
import ModalHeader from '../../../Components/ModalHeader';

export default function DataEditor({
	property,
	data,
	onChange,
	label
}) {

	let error = '';
	const [panelIsOpen, setPanelIsOpen] = useState(false);

	const handleCodeChanged = (value) => {
		const json = jsonTryParse(value);
		if (json) onChange(json);
	};

	const getDataWithoutKeys = (data) => {
		if (!data) {
			return [];
		}

		if (!Array.isArray(data)) {
			error = 'The value must be an Array';
			return [];
		}

		return data.map(d => {
			const dd = {...d};
			delete dd.Key;
			return dd;
		});
	};

	const dataWithoutKeys = getDataWithoutKeys(data);
	const example = getDataWithoutKeys(property.Example);

	const options = {
		jsonldMode: true,
		theme: 'ttcn',
		lineNumbers: false,
		lineWrapping: true,
		indentUnit: 2,
		autocorrect: true,
		readOnly: false,
		keyMap: 'sublime',
		placeholder: JSON.stringify(example, null, 2)
	};

	const exampleOptions = { ...options };
	exampleOptions.readOnly = true;
	exampleOptions.lineNumbers = true;

	return (
		<div className='form_block'>
			<div className='form_block_label'>
				{label}
				{property.Tooltip &&
					<IconBtn
						icon="fas fa-info-circle"
						size="m"
						tooltip={property.Tooltip}
						className="v_al_bl"
						theme="dark"
					/>
				}
			</div>
			{property.Description &&
				<div className="form_block_description">{property.Description}</div>
			}
			<CodeMirror
				value={JSON.stringify(dataWithoutKeys, null, 2)}
				options={options}
				autoCursor={false}
				onChange={(_, __, value) => { handleCodeChanged(value); }}
			/>
			{error &&
				<div className='custom_input_sub_error'>{error}</div>
			}

			{property.Example &&
				<div className='mt_10'>
					<Btn
						message={panelIsOpen ? 'Hide example' : 'View example'}
						onClick={() => setPanelIsOpen(!panelIsOpen)}
						style="link"
					/>
					<Panel
						isOpen={panelIsOpen}
						onClose={() => setPanelIsOpen(false)}
						overlay={false}
						width={800}
						noClose={true}
					>
						<ModalHeader>
							<div className='flex'>
								<div className="flex_item_fix">
									<a className="panel_close panel_close_left" onClick={() => setPanelIsOpen(false)}></a>
								</div>
								<div className='flex_item_full'>
									Data example
								</div>
							</div>
						</ModalHeader>
						<div className="flex_item_full code_mirror_full">
							<CodeMirror
								value={JSON.stringify(example, null, 2)}
								options={exampleOptions}
								autoCursor={false}
							/>
						</div>
					</Panel>
				</div>
			}
		</div>
	);
}