import HttpServices from './HttpServices';

export default class ProductFeedConfigurationServices {
	constructor($http,accountId) {
		this.HttpServices = new HttpServices($http);
		if (
			!window ||
            !window.BEYABLE_env ||
            !window.BEYABLE_env.ANALYTICS_BO_API_URL
		) {
			throw 'Configuration [window.BEYABLE_env.ANALYTICS_BO_API_URL] missing';
		} 
		this.accountId = accountId;
		this.headers = {'Accept' : 'application/hal+json' };
	}

	call(endpoint,verb,payload,callbackSuccess, callbackError) {
		if(payload){
			this.HttpServices[verb](
				`${endpoint}`,
				payload,
				callbackSuccess,
				callbackError,
				this.headers
			);
		}else{
			if(verb === 'post'){
				this.HttpServices[verb](
					`${endpoint}`,
					null,
					callbackSuccess,
					callbackError,
					this.headers
				);
			}else{
				this.HttpServices[verb](
					`${endpoint}`,
					callbackSuccess,
					callbackError,
					this.headers
				);
			}
			
		}
	}

}