import React, { Component } from 'react';
import styles from './VisitorEngagementReportsKPIs.module.css';
import ReactTable from 'react-table';
import EngagementLevelTitle from './EngagementLevelTitle';
import './VisitorEngagementReportsKPIs.regular.css';
import moment from 'moment';
import {Article} from '../../Components/Article/Article';

const SUMMARY_ROW_TAG = '';

const findOrDefault = function (array, predicate, defaultValue) {
	for (let i = 0; i < array.length; i++) {
		const element = array[i];
		if (predicate(element)) return element;
	}
	return defaultValue;
};

const getHeaderClassName = (state, column) => {
	var sort = state.sorted.find(d => d.id === column.accessor);
	return sort ? (sort.desc ? '-sort-desc-new -cursor-pointer' : '-sort-asc-new -cursor-pointer') : '-cursor-pointer';
};

const getTheadThProps = (state, rowInfo, column, instance) => ({
	onClick: () => {
		if (column.sortable !== false) {

			instance.sortColumn(column.columns[0]);
		}
	}, style: {
		fontWeight: 'bold',
		color: '#444444',
		background: '#ffffff',
		border: 'none',
	}, className: `${getHeaderClassName(state, column)}`
});

const sessionKpi = 'sessionLength';
export const toReadableDuration = (durationInSeconds) => {
	var duration = moment.duration(durationInSeconds / 1000, 'seconds');
	const hours = (h) => moment.duration(h, 'hours');
	const minutes = (m) => moment.duration(m, 'minutes');
	const seconds = (s) => moment.duration(s, 'seconds');
	const format = (f) => duration.format(f);
  
	if (duration >= hours(24)){ return ['many', ' days'];
	} else if (duration >= hours(2)){ return [format('H:m'), ' hours'];
	} else if (duration >= hours(1)){ return [format('H:m'), ' hour'];
	} else if (duration >= minutes(2)) { return [format('m:s'), ' minutes'];
	} else if (duration >= minutes(1)) { return [format('m:s'), ' minute'];
	} else if (duration >= seconds(2)) { return [format('s'), ' seconds'];
	} else { return [format('s'), ' second'];
	}
};
export default class VisitorEngagementReportsKPIsComponent extends Component {
	constructor(props) {
		super(props);
		const data = this.props.data.map(kpi => {
			const sessionKpiCurrent = toReadableDuration(kpi[sessionKpi]);
			return {
				...kpi,
				[sessionKpi] : sessionKpiCurrent
			};
		});
		this.state = {
			data : data
		};
	}
	
	componentDidMount() {
	}
	
	getSummaryRow() {
		return findOrDefault(this.state.data, r => r.levelTag === SUMMARY_ROW_TAG, {});
	}
	
	getColumns() {
		return [{
			Header: '',
			id: 'titleCol',
			accessor: row => (row.levelTag in EngagementLevelTitle) ? EngagementLevelTitle[row.levelTag] : 'Average',
			style: {},
			columns: [{ id: 'titleCol', Header: 'Average', accessor: row => (row.levelTag in EngagementLevelTitle) ? EngagementLevelTitle[row.levelTag] : '' }]
		}, {
			Header: () => (<div style={{ textAlign: 'center' }}>Visit<i className={`fas fa-sort ${styles.icons}`}></i></div>),
			accessor: 'visits',
			style: { textAlign: 'right' },
			columns: [{ Header: this.getSummaryRow().visits, accessor: 'visits' }]
		}, {
			Header: () => (<div style={{ textAlign: 'center' }}>Page view<i className={`fas fa-sort ${styles.icons}`}></i></div>),
			accessor: 'pageViews',
			style: { textAlign: 'right' },
			columns: [{ Header: this.getSummaryRow().pageViews, accessor: 'pageViews' }]
		}, {
			Header: () => (<div style={{ textAlign: 'center' }}>Add to cart<i className={`fas fa-sort ${styles.icons}`}></i></div>),
			accessor: 'cart',
			style: { textAlign: 'right' },
			columns: [{ Header: this.getSummaryRow().cart, accessor: 'cart' }]
		}, {
			Header: () => (<div style={{ textAlign: 'center' }}>Conversion<i className={`fas fa-sort ${styles.icons}`}></i></div>),
			accessor: 'conversion',
			style: { textAlign: 'right' },
			columns: [{ Header: this.getSummaryRow().conversion, accessor: 'conversion' }]
		}, {
			Header: () => (<div style={{ textAlign: 'center' }}>Abandonment<i className={`fas fa-sort ${styles.icons}`}></i></div>),
			accessor: 'abandon',
			style: { textAlign: 'right' },
			columns: [{ Header: this.getSummaryRow().abandon, accessor: 'abandon' }]
		}, {
			Header: () => (<div style={{ textAlign: 'center' }}>Session duration<i className={`fas fa-sort ${styles.icons}`}></i></div>),
			accessor: 'sessionLength',
			style: { textAlign: 'right' },
			columns: [{ Header: this.getSummaryRow().sessionLength, accessor: 'sessionLength' }]
		}];
	}
	
	
	render() {
		return (
			<Article size='l'>
				<h2 className={styles.title}>KPIs</h2>
				<ReactTable
					data={this.state.data.filter(r => r.levelTag !== SUMMARY_ROW_TAG)}
					columns={this.getColumns()}
					showPagination={false}
					pageSize={8}
					resizable={false}
					getTheadGroupThProps={getTheadThProps}
					sortable={true}
					getTrProps={() => ({
						style: {
							background: 'none',
							fontWeight: 'inherit',
							color: 'rgb(112, 112, 112)',
							border: 'none'
						}
					})}
					getProps={() => ({ style: { border: 'none', color: '#707070' } })}
					getTdProps={(state, rowInfo, column, instance) => {
						if (rowInfo && rowInfo.row) {
							return {
								style: {
									border: 'none',
									borderRight: column.id === 'titleCol' ? '1px solid #F3F3F3' : 'none'
								}
							};
						}
						return {};
					}}
					getTrGroupProps={() => ({ style: { border: 'none' } })}
					getTheadThProps={() => ({
						style: {
							background: '#F9F9F9',
							fontWeight: 'bold',
							textAlign: 'left',
							color: '#444444',
							border: 'none'
						}
					})}
				/>
			</Article>
		);
	}
}
