import React, { useState, useEffect } from 'react';
import Btn from '../../../../Components/Btn';
import classes from './Pagination.module.css';
const Pagination = ({ totalCount, pageSize, paginate, skipIdNumber }) => {
	const totalPages =
		Math.floor(totalCount / pageSize) + (totalCount % pageSize == 0 ? 0 : 1);
	let pageNumbers = [];
	let idx = 0;
	for (let i = 1; i <= Math.ceil(totalPages); i++) {
		pageNumbers.push({ nb: i, idx: idx });
		idx += pageSize;
	}
	let lastidx = '';
	if ((skipIdNumber, pageNumbers)) {
		const lastidxCalc = pageNumbers && pageNumbers[pageNumbers.length - 1];
		lastidxCalc ? (lastidx = lastidxCalc.idx) : null;
	}

	if (pageNumbers.length <= 1) {
		return <></>;
	}

	return (
		<nav className="flex flex_justify_center mt_20">
			<div className="btn_group">
				{pageNumbers && skipIdNumber && skipIdNumber !== 0 ? (
					<Btn
						icon="fas fa-chevron-left"
						onClick={() => paginate(skipIdNumber - pageSize)}
						style="ghost"
						color="secondary"
						size="s"
						className="btn_w_xs"
					/>
				) : (
					<Btn
						icon="fas fa-chevron-left"
						style="ghost"
						color="secondary"
						disabled={true}
						size="s"
						className="btn_w_xs"
					/>
				)}
				<div className="btn_switch">
					{pageNumbers &&
						pageNumbers.map((number) => (
							<Btn
								key={number.nb}
								onClick={() => paginate(number.idx)}
								style="outline"
								color="secondary"
								size="s"
								message={number.nb}
								className={skipIdNumber === number.idx ? 'active' : ''}
							/>
						))}
				</div>
				{skipIdNumber != lastidx ? (
					<Btn
						icon="fas fa-chevron-right"
						onClick={() => paginate(skipIdNumber + pageSize)}
						style="ghost"
						color="secondary"
						size="s"
						className="btn_w_xs"
					/>
				) : (
					<Btn
						icon="fas fa-chevron-right"
						style="ghost"
						color="secondary"
						disabled={true}
						size="s"
						className="btn_w_xs"
					/>
				)}
			</div>
		</nav>
	);
};

export default Pagination;
