import React, { useEffect, useState, useContext } from 'react'
import TableHistory from './components/TableHistory';
import { HistoryContext } from './HistoryContext';
import PaginationHistory from './components/PaginationHistory';
import Spinner from '../../../Components/Spinner'
export default function HistoryContainer() {
    const [historyData, setHistoryData] = useState(undefined);
    const {
        $routeParams,
        currentAccount,
        systemServices,
        adminServices,
        topHistory,
        skipIdNumber,
        setskipIdNumber,
    } = useContext(HistoryContext);

    const [totalcount, settotalcount] = useState(0);
    const [pageSize, setpageSize] = useState(20);
    const [accountmail, setaccountmail] = useState()
    const getInformationsUser = () => {
        const ku = $routeParams && $routeParams.ku;
        if (ku) {
            adminServices.GetUserById(ku, callbackSuccess => {
                setaccountmail(callbackSuccess.Email)
            }, errorMessage => {
                systemServices.showError(errorMessage);
            })
        }
    }
    useEffect(() => {
        getInformationsUser();
        let filter = "AccountId eq " + currentAccount;
        let order = "CreatedAtUtc desc";
        adminServices.LoadLogs(filter, order, skipIdNumber, topHistory, callbackSuccess => {
            settotalcount(callbackSuccess['@odata.count']);
            setHistoryData(callbackSuccess.value)
        }, errorMessage => {
            systemServices.showError(errorMessage);
        })
    }, []);
    const loadCampaigns = (skipId) => {
        let filter = "AccountId eq " + currentAccount;
        let order = "CreatedAtUtc desc";
        adminServices.LoadLogs(filter, order, skipId, topHistory, callbackSuccess => {
            settotalcount(callbackSuccess['@odata.count']);
            setHistoryData(callbackSuccess.value)
        }, errorMessage => {
            systemServices.showError(errorMessage);
        })
    }
    return (
        <>
            <h1>Account History : <strong>{accountmail && accountmail}</strong></h1>
            {!historyData && <Spinner />
            }
            {historyData && historyData.length === 0 ?
                <section className="nodata">
                    <p className="title"><i className="fa fa-exclamation-triangle"></i> No data for this account :( </p>
                </section>
                :
                <div className="by_table wbg">
                    <TableHistory
                        historyData={historyData}
                    />
                    <PaginationHistory
                        totalCount={totalcount}
                        pageSize={pageSize}
                        paginate={(e) => { setskipIdNumber(e); loadCampaigns(e) }}
                        skipIdNumber={skipIdNumber}
                    />
                </div>
            }
        </>
    )
}
