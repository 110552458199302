import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditorHeader from '../../../../Components/EditorHeader';
import InputCustom from '../../../../Components/InputCustom';
import Confirm from '../../../../Components/Confirm';
import Btn from '../../../../Components/Btn';
import {useSegmentGroupEditorContext} from '../context/SegmentGroupEditorContainerProvider';
import {EditorCreateOrUpdateMode} from '../../Edition/context/EditorTypes';

export default function Header() {

	const {
		isAnError,
		isSaving,
		mode,
		handleSetSegmentationName,
		handleSaveSegmententation,
		handleUpdateSegmententation,
		segmentationName,
		getReturnLink,
		isSame,
		hasErrors,
		triggers
	} = useSegmentGroupEditorContext();

	const { t } = useTranslation(['segments', 'utils']);

	const [closeConfirmIsOpen, setCloseConfirmIsOpen] = useState<boolean>(false);
	const [btnCancelAndQuit, setbtnCancelAndQuit] = useState(<></>);
	const [isRequireSure, setIsRequireSure] = useState(false);
	const [userCanSave, setuserCanSave] = useState(true);
	const hasAnError = hasErrors();
	
	const checkIfUserCanSave = (tgrs) => {
		if(tgrs){
			const arrayOfTriggersNonDefault:any = [];
			tgrs.forEach(element => {
				if(element.Group.Label !== 'Default'){
					arrayOfTriggersNonDefault.push(element);
				}
			});
			if(arrayOfTriggersNonDefault.length !== 0  ){
				setuserCanSave(false);
			}else{
				setuserCanSave(true);
			}
		}
	};

	useEffect(()=> {
		if(triggers ){
			checkIfUserCanSave(triggers);
		}
	},[triggers]);

	useEffect(() => {
		setbtnCancelAndQuit(<>
			<Btn
				onClick={() => handleClickCancel(true)}
				message={t('actions.cancel', {ns: 'utils'})}
				style="ghost"
				color="secondary"
			/>

			{mode === EditorCreateOrUpdateMode.Create &&
				<Btn
					onClick={() => handleSaveSegmententation(segmentationName)}
					message={t('actions.save', {ns: 'utils'})}
					disabled={userCanSave || hasAnError }
				/>
			}
			{mode === EditorCreateOrUpdateMode.Update &&
				<Btn
					onClick={() => handleUpdateSegmententation(segmentationName)}
					disabled={userCanSave || hasAnError}
					message={t('actions.save', {ns: 'utils'})}
				/>
			}
		</>);
	}, [isSaving, hasAnError, segmentationName, triggers,userCanSave ]);

	useEffect(() => {
		if (segmentationName === '__DEFAULT_NAME__') {
			handleSetSegmentationName(t('newSegment'));
		}
	}, [segmentationName]);

	function handleClickCancel(boll:boolean){
		const needRequire = !isSame();
		if(!needRequire){
			getReturnLink();
		}
		setIsRequireSure(needRequire);
		setCloseConfirmIsOpen(boll);
	}

	function getError(type) {
		const error = isAnError(type);
		if (error && error[1] === 'missingName') {
			error[1] = t('missingName');
		}
		return error;
	}

	const nameError = getError('SegmentationName');

	return (
		<>
			<EditorHeader
				hasBorder={true}
				backTitle={t('actions.close', {ns: 'utils'})}
				backOnClick={() => handleClickCancel(true)}
				left={
					<>
						<div className='flex'>
							<span
								className="campaign_editor_header_name flex_item_auto"
								data-tip={nameError && nameError[0] ? '' : t('editSegmentName')}
							>
								<InputCustom
									onChange={e => handleSetSegmentationName(e.target.value)}
									name="SegmentationName"
									value={segmentationName}
									type="text"
									style="ghost"
									placeholder={'Name'}
									selectOnFocus={true}
									autoFocus={true}
									autogrow={true}
									maxWidth={500}
									className="has_clue"
									hasError={nameError && nameError[0]}
									errorMessage={nameError && nameError[1]}
									errorPosition="tooltip"
								/>
							</span>
						</div>
					</>
				}
				center={
					<></>
				}
				right={
					<>
						{btnCancelAndQuit}
					</>
				}
			/>
			<Confirm
				isOpen={isRequireSure && closeConfirmIsOpen}
				onClose={() => setCloseConfirmIsOpen(false)}
				title={t('closeEditor')}
				text={t('messages.areYouSureToClose', {ns: 'utils'})}
				cancelText={t('messages.Stay', {ns: 'utils'})}
				confirmText={t('messages.quitWithout', {ns: 'utils'})}
				confirmCallback={() => getReturnLink()}
			/>
		</>
	);
}
