import HttpServices from './HttpServices';
import axios from 'axios';

export default class InternalServices {

	constructor($http, authServices, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		this.accountId = accountId;
	}
	getAccountList( callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}/internal/account`,
			callbackSuccess, callbackError);
	}
	
}
