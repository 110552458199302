import React from 'react';
import InputCustom from '../../../Components/InputCustom';
import Modal from '../../../Components/Modal';
import Btn from '../../../Components/Btn';

export default function ModalHierarchieCat({
	selectedCatModalIsOpen,
	setSelectedCatModalIsOpen,
	catSelected,
	isExistingObj,
	handleChangeCategorie,
	submitNewCat,
	setisExistingObj
}) {
	const closeModal = ()=>{
		setisExistingObj(false);
		setSelectedCatModalIsOpen(false);
	};
	return (
		<Modal
			isOpen={selectedCatModalIsOpen}
			onClose={() => closeModal()}
		>
			<div className="modal_header has_border">
				<div>
					{isExistingObj ? 'Edit ' + catSelected : 'New category'}
				</div>
			</div>
			<div className="modal_body">
				<InputCustom
					label="Category name"
					name="prefix"
					value={catSelected}
					fullWidth={true}
					type="text"
					placeholder={'example : Universe'}
					onChange={(e) => handleChangeCategorie(e)}
				/>
			</div>
			<div className="modal_footer has_border al_right">
				<Btn
					message={'Cancel'}
					style={'ghost'}
					color={'secondary'}
					onClick={(e) => closeModal()}
				/>
				<Btn
					message={'Ok'}
					onClick={() => submitNewCat()}
					disabled={catSelected === ''}
				/>
			</div>

		</Modal>
	);
}
