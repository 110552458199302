import React, { useState, useEffect } from 'react';

import Nav from '../Nav/Nav';
import NavComparison from '../Nav/NavComparison';

export default function NavSession({
	ComparisonSelected,
	setNavFilter,
	initialDates,
	handleSelectComparaisonPeriodHandler,
	setisCustomSelected,
	isCustomSelected,
	customDateRange,
	setCustomDateRange,
	isCustomCompareSelected,
	setisCustomCompareSelected,
	customCompareDateRange,
	setCustomCompareDateRange
}) {

	function handleClickOriginal(obj) {
		setNavFilter(stateInit => ({
			...stateInit,
			fromDate: obj.fromDate,
			toDate: obj.toDate
		}
		));
	}
	function handleClickCompared(obj) {
		setNavFilter(stateInit => ({
			...stateInit,
			fromDateToCompare: obj.fromDate,
			toDateToCompare: obj.toDate
		}
		));
	}
	
	return (<>
		<Nav
			handleClick={handleClickOriginal}
			initialDates={initialDates}
			showComparisonToggler={true}
			setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
			comparisonSelected={ComparisonSelected}
			setisCustomSelected={setisCustomSelected}
			isCustomSelected={isCustomSelected}
			customDateRange={customDateRange}
			setCustomDateRange={setCustomDateRange}
		/>
		{/* {ComparisonSelected &&
			<Nav
				initialLabel={'initialLabel2'}
				handleClick={handleClickCompared}
				initialDates={initialDates}
				navLabel="Compared to"
				showComparisonRemover={true}
				setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
				comparisonSelected={ComparisonSelected}
			/>
		} */}
		{ComparisonSelected &&
			<NavComparison
				initialLabel={'initialLabel2'}
				handleClick={handleClickCompared}
				initialDates={initialDates}
				navLabel="Compared to"
				showComparisonRemover={true}
				setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
				comparisonSelected={ComparisonSelected}
				setisCustomSelected={isCustomCompareSelected}
				isCustomSelected={setisCustomCompareSelected}
				customDateRange={customCompareDateRange}
				setCustomDateRange={setCustomCompareDateRange}
			/>
		}
	</>
	);
}
