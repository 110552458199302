/* eslint-disable prefer-const */
/* eslint-disable no-case-declarations */
import React, {useState,useEffect,useRef} from 'react';
import styles from './ChatBot.module.css';
import { v4 as uuidv4 } from 'uuid';
import EmptyState from '../../Components/EmptyState';
import Field from './Field';
import {Messageline, SystemMessage} from './Messageline';
import IconBtn from '../../Components/IconBtn';
import Btn from '../../Components/Btn';

import ImpersonatingServices from '../../Services/ImpersonatingServices';
import ChatBotServices from '../../Services/ChatBotServices';
import SystemServices from '../../Services/SystemServices';
import {useTranslation} from 'react-i18next';
import {modules} from '../Campaigns/Inspirations/InspirationData';
import EventTrackingServices from '../../Services/EventTrackingServices';
const SSE = require('sse.js');
type typeMessages = 'Text' | 'Info' | 'Warning' | 'QueryProposition' | 'ReadyModule' | 'Continuation' | 'ConversationId'  |  'ProductRecommendationId' | 'ProductRankingId' | 'ProductTaggingId' | 'DayDictionary' | 'GenericDictionary'
type Message = {
	type: typeMessages;
	participant : 'System'| 'User' | 'Bot';
	data: string | any;
}

type HistoryMessage = {
	chatHistory : Message[]
	ConversationId: string,
}

export default function PopinChatBot({
	chatIsOpen,
	setchatIsOpen,
	props
}) {

	if(!props.$rootScope.User){
		return <></>;
	}

	//const {t} = useTranslation(['utils', 'chatbot']);
	const [t] = useTranslation(['chatbot', 'utils']);

	const systemServices = new SystemServices(props.$rootScope);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const chatBotServices = new ChatBotServices(props.$http, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const authServices = props.AuthServices;
	const authorizationToken = authServices && authServices.getAccessToken();
	const [accountIsError, setaccountIsError] = useState(false);

	const [accountId, setAccountId] = useState(impersonatedAccount || props.$rootScope.User.Account.Key);

	const [chatHistory, setChatHistory] = useState<HistoryMessage>({
		'chatHistory': [],
		ConversationId: ''
	});

	// const [bug, setbug] = useState(false);

	const [answerChatBot, setAnswerChatBot] = useState<Message>();
	const [currenConversationId, setcurrenConversationId] = useState<string>('');

	const [queryProposition, setQueryProposition] = useState<string[]>([]);
	const [chatIsMaximized, setChatIsMaximized] = useState<boolean>(false);
	const [accountHasBeenChange, setaccountHasBeenChange] = useState(false);
	const [isBotResponding, setBotResponding] = useState(false);
	const { trackEvent } = EventTrackingServices(props.$rootScope.User);
	// eslint-disable-next-line no-var
	var eventSource;
	const chatRef = useRef(null);

	const messageIdeas:Array<Message> = [
		// {
		// 	type: 'Text',
		// 	participant: 'User',
		// 	data: 'Quel est mon taux de conversion ?'
		// },
		// {
		// 	type: 'Text',
		// 	participant: 'User',
		// 	data: 'Qu\'est ce qu\'un segment ?'
		// },
		{
			type: 'Text',
			participant: 'User',
			data: t('chatbot.ideas.mainIndicators')
		},
		{
			type: 'Text',
			participant: 'User',
			data: t('chatbot.ideas.whatScenariosToImprove')
		},
		// {
		// 	type: 'Text',
		// 	participant: 'User',
		// 	data: t('chatbot.ideas.howCanIOptimize')
		// },
		// {
		// 	type: 'Text',
		// 	participant: 'User',
		// 	data: t('chatbot.ideas.howCanIImproveCartAbandonment')
		// }
	];
	function onFocusAction(){
		const currentUser = authServices.GetAuthenticatedUser();
		const isAdminTest = currentUser && currentUser.Role === 'Admin' || currentUser.Role == 'OrganizationAdmin';
		const ka = props.$routeParams && props.$routeParams.ka && isAdminTest ? props.$routeParams.ka : undefined;
		let AccError= false;
		if(isAdminTest && ka && accountId !== ka ){
			setaccountIsError(true);
			getNewAccountAnd('');
			AccError = true;
		}
		return AccError;
	}
	function handleSendMessage(text:string) {
		if (!text) return false;

		const message:Message = {
			type: 'Text',
			participant: 'User',
			data: text,
		};

		const chatHystoryFiltered = chatHistory.chatHistory.filter(chat => chat.type === 'Text');
		const payloadChatBot: any ={ 
			chatHistory: [...chatHystoryFiltered],
			userText : message.data 
		};
		if(currenConversationId !== ''){
			payloadChatBot.conversationId = currenConversationId;
		}
		const isErrorOnAccount = onFocusAction();
		if(!isErrorOnAccount){
			setChatHistory(prevState =>({
				...prevState,
				chatHistory: [...prevState.chatHistory, message,]
			}));
		
			//@ts-ignore
			eventSource = new SSE.SSE(`${window.BEYABLE_env.BO_API_URL}${accountId}/copilot-streaming-chat`, {headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + authorizationToken
			},
			payload: JSON.stringify(payloadChatBot),
			pollingInterval: 25000,
			});
		
			// let timer; 
			setBotResponding(false);
			eventSource.onmessage = function(event) {
				const data = JSON.parse(event.data);

				// if (timer) clearTimeout(timer);
				// if(bug) setbug(false);
				// timer = setTimeout(() => { 
				// 	if(data.type === 'Continuation'){
				// 		setbug(true);
				// 	}
				// } , 3500 );

				// const test: Message = {
				// 	//type: 'ProductTaggingId',
				// 	type: 'ProductRecommendationId',
				// 	//type: 'ProductRankingId',
				// 	participant: 'Bot',
				// 	data: '0c3051e6-20b0-44d4-a60f-a949ad1d3d13'
				// };
				//setAnswerChatBot(test);

				setAnswerChatBot(data);
			};
			eventSource.onerror = function(error) {
				console.error('EventSource failed:', error);
				systemServices.showError('An error occured during conversation');
			};
			setQueryProposition([]);
		}else{
			setaccountIsError(true);
		}
	}

	function newConversation() : void{	
		eventSource && eventSource.close();
		setcurrenConversationId('');
		setChatHistory({'chatHistory': [], ConversationId: ''});
		setaccountHasBeenChange(false);
		setaccountIsError(false);
		const accountSwitch = impersonatingServices.getImpersonatedAccount() || props.$rootScope.User.Account.Key;
		setAccountId(accountSwitch);
	}
	function handleFeedback( feedback, reset) {
		const feedbackPayload = {
			conversationId: currenConversationId,
			feedback : feedback,
			chatHistory : chatHistory.chatHistory
		};
		chatBotServices.sendFeedback(accountId,feedbackPayload, () => {
			systemServices.showSuccess('Feedback sent with success');
			reset();
		}, 
			
		() => {
			systemServices.showError('sending feedback failed');
		});
	}


	const beyableChatHistory = localStorage.getItem('beyablechatbot');
	if(beyableChatHistory){
		// console.log('🚀--** ~ file: PopinChatBot.tsx:75 ~ useEffect ~ beyableChatHistory:', beyableChatHistory);
	}else{
		const newChatHistory = [{
			'chatHistory': [],
			ConversationId: '',
		}];
		localStorage.setItem('beyableChatHistory',JSON.stringify(newChatHistory));
	}


	const getReadyModule = (moduleId) => {
		const module = modules.find(m => m.id === moduleId);
		if (module && module.available) return module;
	};

	const handleScroll = (ref) => {
		if(ref.current){
			const lastChildElement = ref.current;
			lastChildElement?.scrollIntoView();
		}
	};

	useEffect(()=>{ 
		handleScroll(chatRef);
	},[chatHistory, chatIsOpen]);


	useEffect(() => {
		if (answerChatBot) {
			setChatHistory((prevState) => {
				const chatHistoryToModify = [...prevState.chatHistory];
				const accumulatedMessages:any = [];
				switch (answerChatBot.type) {
					case 'Continuation':
						if(answerChatBot.participant === 'Bot'){
							const lastElementLoading = chatHistoryToModify[chatHistoryToModify.length - 1];
							if (lastElementLoading) {
								lastElementLoading.data = lastElementLoading.data + answerChatBot.data;
							}
						}
						break;
					case 'ConversationId':
						// Traitement pour l'identifiant de conversation
						trackEvent('chat/copilot-started');
						setcurrenConversationId(answerChatBot.data);
						sessionStorage.setItem('idChat' + '_' +answerChatBot.data, impersonatedAccount || props.$rootScope.User.Account.Key);
						break;
					case 'Info':
					case 'Warning':
					case 'Text':
						chatHistoryToModify.push(answerChatBot);
						break;
					case 'ReadyModule':
						const readyModule = getReadyModule(answerChatBot.data);
						if (readyModule) {
							answerChatBot.data = readyModule;
							// chatHistoryToModify.push(answerChatBot);
							accumulatedMessages.push(answerChatBot);
						} else {
							// TODO : afficher un message disant que le module n'est pas disponible
						}
						break;
					case 'QueryProposition':
						// Traitement pour QueryProposition
						setQueryProposition((prevQueryProposition) => [...prevQueryProposition, answerChatBot.data]);
						break;
					case 'ProductRecommendationId':
					case 'ProductRankingId':
					case 'ProductTaggingId':
						accumulatedMessages.push(answerChatBot);
						break;
					case 'DayDictionary': 
						const dayChartDictionnary =  {
							id : uuidv4(),
							name : ' dayDictionary',
							url : 'templates/retention_popin_resinsurance.jpg',
							data: answerChatBot.data
						};
						answerChatBot.data = dayChartDictionnary;
						accumulatedMessages.push(answerChatBot);
						break;
					case 'GenericDictionary': 
						const genericChartDictionnary =  {
							id : uuidv4(),
							name : ' GenericDictionary',
							url : 'templates/engagement_toaster_redirection.png',
							data: answerChatBot.data
						};
						answerChatBot.data = genericChartDictionnary;
						accumulatedMessages.push(answerChatBot);
						break;
					default:
				// Cas par défaut
				}
				
				return {
					...prevState,
					chatHistory: [...chatHistoryToModify, ...accumulatedMessages],
				};
			});
		}
	}, [answerChatBot]);

	useEffect(()=>{
		//@ts-ignore
		props.$rootScope.$on('event:routeChangeStart', (f,e) => { 		
			const timer = setTimeout(() => {
				getNewAccountAnd(e);
			},50);
			return () => {
				clearTimeout(timer);
			};
		});
		props.$rootScope.$on('event:auth-loginConfirmed', () => { 
			const timer = setTimeout(() => {
				getNewAccountAnd('/SignIn');
			},50);
			return () => {
				clearTimeout(timer);
			};
		});
		props.$rootScope.$on('event:changeAccount', () => { 
			
			const timer = setTimeout(() => {
				if(currenConversationId === ''){
					const accountSwitch = impersonatingServices.getImpersonatedAccount() || props.$rootScope.User.Account.Key;
					setAccountId(accountSwitch);
				}else{
					setaccountHasBeenChange(true);
				}
			},50);
			return () => {
				clearTimeout(timer);
			};
		});
		
	},[props.$rootScope, props.$location,currenConversationId]);

	function getNewAccountAnd(e){
		const prev = e;
		if( !prev || prev.includes('Administration/AdminHome') || prev.includes('Switch') || prev.includes('Callback') || prev.includes('/Redirect') || prev.includes('AccountHome') || prev.includes('/SignIn')  ){
			const accountSwitch = impersonatingServices.getImpersonatedAccount() || props.$rootScope.User.Account.Key;
			if(accountSwitch !== accountId && currenConversationId !== ''){
				setcurrenConversationId('');
				setAccountId(accountSwitch);
				setaccountHasBeenChange(true);
				setQueryProposition([]);
			}
		}
	}

	useEffect(()=>{
		onFocusAction();
	},[chatIsOpen]);

	if(!chatIsOpen) {
		return <></>;
	}

	return (
		<>
			{chatIsMaximized &&
				<div className={styles.overlay}></div>
			}
			<div className={chatIsMaximized ? styles.container_maximized : styles.container_minimized}>
				<div className={styles.header}>
					<div className='flex'>
						<div className='flex_item_full'>
							<Btn
								message={t('chatbot.newConversation')}
								icon="fas fa-plus-circle fa-sm"
								style="ghost"
								light={true}
								disabled={(!chatHistory && !accountHasBeenChange || !chatHistory.chatHistory && !accountHasBeenChange|| chatHistory.chatHistory.length === 0  && !accountHasBeenChange) }
								horizontalSize="xs"
								onClick={newConversation}
							/>
						</div>
						<div className='flex_item_fix ml_20'>
							<IconBtn
								icon={chatIsMaximized ? 'fas fa-compress' : 'fas fa-expand'}
								onClick={() => setChatIsMaximized(!chatIsMaximized)}
								tooltip={chatIsMaximized ? t('chatbot.exitFullScreen') : t('chatbot.expandToFullScreen')}
								theme="dark"
							/>
							<IconBtn
								icon="fas fa-times"
								onClick={() => setchatIsOpen(false)}
								tooltip={t('actions.close', { ns: 'utils' })}
								theme="dark"
							/>
						</div>
					</div>
				</div>
				<div className={styles.body}>
					<div className={styles.body_inner + ' vscroll_custom'}>
						{!chatHistory || !chatHistory.chatHistory || chatHistory.chatHistory.length === 0 &&
							<>
								<EmptyState
									textSize='l'
									title={t('chatbot.welcomeToBeyableCopilot')}
									width='m'
									imageUrl="/Assets/empty_chatbot.png"
									imageWidth={150}
									text={
										<>
											<div>{t('chatbot.iCanHelpYou')}</div>
											{messageIdeas && messageIdeas.length &&
											<>
												<div className='s_14 mt_10'>{t('chatbot.someIdeas')}</div>
												<div className={styles.message_idea_group}>
													{messageIdeas.map((m) =>
														<button
															key={uuidv4()}
															className={styles.message_idea}
															onClick={() => handleSendMessage(m.data)}
															type='button'
														>
															{m.data}
														</button>
													)}
												</div>
											</>
											}
										</>
									}
								/>
								{accountHasBeenChange && 
								<Messageline
									key={uuidv4()}
									type="Warning"
									participant="Bot"
									data={[t('chatbot.changeAccount')]}
									handleSendMessage={handleSendMessage}
									props={props}
								/>
								}
							</>
						}

						{chatHistory && chatHistory.chatHistory.length > 0 &&
							<div className={styles.conversation}>
								<SystemMessage
									text={t('chatbot.beyableCopilotDisclaimer')}
								/>
								{chatHistory.chatHistory.map((message) => {
									return (
										<Messageline
											key={uuidv4()}
											type={message.type}
											participant={message.participant}
											data={[message.data]}
											handleFeedback={handleFeedback}
											props={props}
										/>
									);
								})}
								{queryProposition.length > 0 &&
									<Messageline
										key={uuidv4()}
										type="QueryProposition"
										participant="Bot"
										data={queryProposition}
										handleSendMessage={!accountIsError && handleSendMessage}
										props={props}
									/>
								}
								{accountHasBeenChange && 
									<Messageline
										key={uuidv4()}
										type="Warning"
										participant="Bot"
										data={[t('chatbot.changeAccount')]}
										handleSendMessage={handleSendMessage}
										props={props}
									/>
								}
								
								<div style={{height : '2px'}} ref={chatRef}>
									{/* Laissez moi vivre car je fais fonctionner le scroll :) */}
								</div>
							</div>
						}
					</div>
					{chatHistory && chatHistory.chatHistory.length > 0 &&
						<div className={styles.body_bottom_gradient}></div>
					}
					{accountIsError && 
								<>
									<Messageline
										key={uuidv4()}
										type="Warning"
										participant="Bot"
										data={[t('chatbot.errorNetwork')]}
										handleSendMessage={handleSendMessage}
										props={props}
									>
									
										<Btn
											size='xs'
											tooltip='Reload navigator'
											icon="fas fa-sync-alt"
											style="outline"
											className="ml_10"
											color="primary"
											message="Reload this page"
											onClickFunction={()=>location.reload()}
										/>
									</Messageline>

								</>
					}
				
				</div>
				<div className={styles.footer}>
					{!chatHistory || !chatHistory.chatHistory || chatHistory.chatHistory.length === 0 &&
						<div className={styles.footer_info}>
							{t('chatbot.beyableCopilotDisclaimer')}
						</div>
					}
					<Field
						onFocus={onFocusAction}
						errorNetwork={accountIsError}
						handleSendMessage={handleSendMessage}
						isLoadingAnswer={isBotResponding}
					/>
				</div>
			</div>
		</>
	);
}







