import React, { useEffect, useState } from 'react';
import { abTestsDashboardContext } from './context';
import listStyles from './ABTestDashboard.module.css';
import commonStyles from '../ABTestCommon.module.css';
import {getDeviceIcon} from '../utils.js';
import CampaignLabel from '../../Dashboard/OnSite/UI/CampaignLabel';

export default function ABTestsItemInfo({
	campaign,
	hasWinner = false,
	openModalCampaignLabels,
	message
}) {
	

	const { ka } = abTestsDashboardContext();

	if (!campaign) return <></>;
	const { id, name, description, devices,  } = campaign;

	return (
		<>
			<a href={'/ABTests/Reporting?Id=' + id + (ka ? '&'+ka : '') } className={listStyles.name}>{name}</a>
			<div className={listStyles.desc}>{description}</div>
			<div className={listStyles.label_list}>
				{devices.map(x => 
					<span key={x} className={commonStyles.label}>
						<i className={commonStyles.label_icon + ' fas fa-fw fa-' + getDeviceIcon(x) }></i>
					</span>
				)}
				<ul
					className="campaign_label_list mt_3 inline"
					onClick={() => openModalCampaignLabels(campaign)}
				>
					{campaign.labels.map((label, key) => (
						<CampaignLabel
							key={key}
							label={label}
						/>
					))}
				</ul>
			</div>
		</>
	);
}