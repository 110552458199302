import React, { useEffect } from "react";
import TabBar from "../../../../Components/TabBar";
import TabItem from "../../../../Components/TabItem";

export default function FilterExpressionTabulation({
	elementsFilterListAvailableSelected,
	handleTabFilterSelected,
	expressionSelectMod,
	setExpressionSelectMod,
	tabFilterSelected
}) {
	useEffect(() => {
		if (!tabFilterSelected) {
			handleTabFilterSelected(elementsFilterListAvailableSelected[0])
		}
		setExpressionSelectMod(false)
	}, [elementsFilterListAvailableSelected])

	return (
		<TabBar hasBorder={false} appearance={'underline'} size="l" className="has_v_pad">
			{elementsFilterListAvailableSelected &&
				elementsFilterListAvailableSelected.map((elem) => {
					const isActive = tabFilterSelected && elem.idFilter === tabFilterSelected.idFilter;
					return (
						<TabItem
							key={elem.idFilter}
							onClick={() => handleTabFilterSelected(elem)}
							selected={isActive}
							message={elem.name}
						/>
					);
				})}
		</TabBar>
	);
}
