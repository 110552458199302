import React, { useState, useEffect, Fragment, useRef } from 'react';
import PropertyEditor from './PropertyEditor';
import Folder from './Folder';
import Btn from '../../../Components/Btn';
import { propertyAccessor } from '../Utils/PropertiesOperations';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


export default function PropertiesCollection({
	property,
	context
}) {
	const {
		getCurrentVariation,
		handleCollectionPropertyValueChange,
		handleDeleteFromCollection,
		handleAddToCollection,
		handleChangeActiveCollectionItem,
		handleIsFoldedChanged,
		currentDevice,
		reorderCollectionItems,
		features,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback
	} = context;

	const propertyEditorContext = {
		features,
		getCurrentVariation,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback,
	};

	const { getCollectionItems } = propertyAccessor();

	const activeVariation = getCurrentVariation() || {};
	const collectionItems = getCollectionItems(activeVariation.Properties, property);
	const canDeleteProperty = collectionItems.length - property.Editor.CollectionMinSize > 0;
	const canAddProperty = collectionItems.length < property.Editor.CollectionMaxSize;
	const [activeItem, setActiveItem] = useState();

	useEffect(() => {
		if (collectionItems && collectionItems.length > 0) {
			if (activeItem === undefined) {
				setActiveItem(collectionItems[0].Key);
			} else {
				setActiveItem(collectionItems[collectionItems.length - 1].Key);
			}
		}
	}, [collectionItems]);

	useEffect(() => {
		const getIndex = () => {
			if (!activeItem)
				return 0;
			const index = collectionItems.findIndex(x => x.Key === activeItem);
			return index >= 0 ? index : 0;
		};
		handleChangeActiveCollectionItem(property, getIndex());
	}, [activeItem]);

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		reorderCollectionItems(collectionItems, result.source.index, result.destination.index, property);
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		userSelect: 'none',
		// background: 'red',

		...draggableStyle,
	});

	const getListStyle = isDraggingOver => ({
		// background: isDraggingOver ? 'lightblue' : 'lightgrey',
		display: 'flex',
		overflow: 'auto',
	});

	return (
		<Folder 
			property={property} 
			handleIsFoldedChanged={handleIsFoldedChanged} 
			handleIsActiveChanged={(checked) => false} 
			isActive={true}
		>
			<div className="format_collection_nav">
				<div className="format_collection_nav_slides">
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable" direction="horizontal">
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
									{...provided.droppableProps}
									className="btn_switch"
								>
									{collectionItems.map((collectionItem, index) =>
										<Draggable key={collectionItem.Key} draggableId={collectionItem.Key} index={index}>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
												>
													<Btn
														htmlTag="a"
														message={index + 1 + ''}
														size="s"
														className="btn_w_s btn_no_min_width"
														style="outline"
														color="secondary"
														isActive={(!activeItem && 0 == index) || activeItem === collectionItem.Key ? true : false}
														onClick={() => setActiveItem(collectionItem.Key)}
													/>
												</div>
											)}
										</Draggable>
									)}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
				<div className="format_collection_nav_add">
					<Btn
						icon="fas fa-plus"
						size="s"
						className="btn_w_xxs"
						onClick={() => handleAddToCollection(property.Name, property)}
						disabled={!canAddProperty}
					/>
				</div>
			</div>

			{collectionItems.map((collectionItem, j) => (
				<Fragment key={collectionItem.Key}>
					{(!activeItem && 0 == j) ||
						(activeItem === collectionItem.Key && (
							<>
								<div className="format_collection_header flex">
									<div className="flex_item_full fw_medium s_14">ITEM #{j + 1}</div>
									<div className="flex_item_fix ml_10">
										{canDeleteProperty && (
											<a
												className="fw_medium s_13 c_turquoise pointer"
												onClick={() => handleDeleteFromCollection(collectionItem.Key, property.Name)}
											>
												Delete this item
											</a>
										)}
									</div>
								</div>
								{property.Content.map((contentItem, k) => {
									if (contentItem.Device && contentItem.Device !== currentDevice) return null;

									return (
										<PropertyEditor
											key={k}
											context={propertyEditorContext}
											data={collectionItem[contentItem.Name]}
											property={contentItem}
											callBack={(v) =>
												handleCollectionPropertyValueChange(v, property.Name, contentItem, j)
											}
											collectionItem={collectionItem}
											propertyContainer={{
												name: property.Name,
												type: property.Editor.Type,
												siblingPropertiesAccessor: {
													getSiblingPropertyData: (subPropertyName) =>
														collectionItem[subPropertyName],
													setSiblingPropertyData: (subPropertyName, v) =>
														handleCollectionPropertyValueChange(
															v,
															property.Name,
															{ Name: subPropertyName },
															j
														),
												},
											}}
										/>
									);
								})}
							</>
						))}
				</Fragment>
			))}
		</Folder>
	);
}