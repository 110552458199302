import React, { useState, useEffect } from 'react';
import '../ReportingCampaignGlobal.css';
import MomentConstants from '../../../Constants/MomentConstants';
import ChartMailContainer from './ChartMailContainer';
import Dropdown from '../../../Components/Dropdown';
import Btn from '../../../Components/Btn';
import { DateRangePicker } from 'react-dates';
import MainInformationsReportContainer from './MainInformationsReportContainer';

export default function MailStatsContainer({
	accountId,
	campaignId,
	momentStartDate,
	momentEndDate,
	granularity,
	setFrequencyDropdownIsOpen,
	frequencyDropdownIsOpen,
	reportingCampagneServices,
	systemServices,
	statsType,
	dataObjectiveCampaign,
	startDate,
	endDate,
	loadDataInformationsCampaign,
	changeRangeDate,
	isOutsideRange,
	handleOnDateChange,
	changeGranularity,
	changeGranularityManually,
	activeLink
}) {

	const [isReloading, setIsRealoading] = useState(true);
	const [series, setseries] = useState([]);
	const [seriesPeriod, setseriesPeriod] = useState([]);
	const [focusedInput, setFocusedInput] = useState(null);
	useEffect(() => {
		if (campaignId && momentStartDate && momentEndDate) {
			getStatsForCampaign();
		}
	}, [campaignId, momentStartDate, momentEndDate]);

	const getStatsForCampaign = () => {
		setIsRealoading(true);
		reportingCampagneServices.getStatstype(accountId, campaignId, momentStartDate.format(MomentConstants.MOMENT_API_FORMAT), momentEndDate.format(MomentConstants.MOMENT_API_FORMAT), granularity, statsType,
			data => {
				setseries(data);
				getStatsForCampaignWithoutGranularity();
			},
			error => {
				console.error('error', error);
				setIsRealoading(false);
				systemServices.showError('An error occured while fetching data');
			});
	};
	const getStatsForCampaignWithoutGranularity = () => {
		reportingCampagneServices.getStockAlertStatsWithoutGranularity(accountId, campaignId, momentStartDate.format(MomentConstants.MOMENT_API_FORMAT), momentEndDate.format(MomentConstants.MOMENT_API_FORMAT), statsType,
			data => {
				setseriesPeriod(data && data.series[0]);
				setIsRealoading(false);
			},
			error => {
				console.error('error', error);
				setIsRealoading(false);
				systemServices.showError('An error occured while fetching data for the selected period');
			});
	};

	return (
		<>
			<div className="reporting_filters flex">
				<div className="flex_item_full">
					<div className="btn_group">
						<span className="date_picker_s">
							<DateRangePicker
								startDate={startDate}
								startDateId="idStartDate"
								endDateId="your_unique_end_date_id"
								displayFormat='DD/MM/YYYY'
								endDate={endDate}
								isOutsideRange={(date) => isOutsideRange(date)}
								focusedInput={focusedInput}
								onFocusChange={(focused) => setFocusedInput(focused)}
								onClose={() => changeGranularity()}
								onDatesChange={(startDate, endDate) => handleOnDateChange(startDate, endDate)}
							/>
						</span>
					</div>
				</div>
				<div className="flex_item_fix">
					<Dropdown
						isOpen={frequencyDropdownIsOpen}
						setIsOpen={(e) => setFrequencyDropdownIsOpen(true)}
						onHide={(e) => setFrequencyDropdownIsOpen(false)}
						button={
							<Btn style="outline" color="secondary" arrow="true" size="s" light="true">
								{granularity == 'day' && 'Day'}
								{granularity == 'week' && 'Week'}
								{granularity == 'month' && 'Month'}
							</Btn>
						}>
						<ul className="listbox">
							<li>
								<a className={granularity == 'day' ? 'listbox_item selected' : 'listbox_item'} onClick={(e) => { changeGranularityManually('day'); }}>Day</a>
							</li>
							<li>
								<a className={granularity == 'week' ? 'listbox_item selected' : 'listbox_item'} onClick={(e) => { changeGranularityManually('week'); }}>Week</a>
							</li>
							<li>
								<a className={granularity == 'month' ? 'listbox_item selected' : 'listbox_item'} onClick={(e) => { changeGranularityManually('month'); }}>Month</a>
							</li>
						</ul>
					</Dropdown>
				</div>
			</div>
			<div className="reporting_body">
				<div className="reporting_part">
					<ChartMailContainer statistics={series} isOnCharg={isReloading} />
				</div>
				<MainInformationsReportContainer
					data={seriesPeriod}
					dateRange={
						{
							startDate: momentStartDate,
							endDate: momentEndDate
						}
					}
					mainInformation={dataObjectiveCampaign}
				/>
			</div>

			{isReloading &&
                <div className="spinner_overlay">
                	<div className="spinner">
                		<div className="rect1"></div>
                		<div className="rect2"></div>
                		<div className="rect3"></div>
                		<div className="rect4"></div>
                		<div className="rect5"></div>
                	</div>
                </div>
			}
		</>
	);
}


