import React, { useContext, useState, useEffect } from 'react';
import Btn from '../../../../../Components/Btn';
import Modal from '../../../../../Components/Modal';
import { CreateBusinessOperationContext } from '../../Context/ContextCreateBusinessOperations';
import ImageTransformContainer from './ImageTransformContainer';
import Spinner from '../../../../../Components/Spinner';
export default function PopinTransformation({ modalIsOppen, setModalIsOppen, businessOperationServices, systemServices, cloudinaryFiles, newBanners, libraryId, accountId
}) {
	const {
		setListBanners,
		setOperation,
		operation
	} = useContext(CreateBusinessOperationContext);
	const [isLoading, setisLoading] = useState(false);
	const [imageSelectedToTransform, setimageSelectedToTransform] = useState();
	const [transformations, settransformations] = useState();
	const [imagesSelectedTotranformation, setimagesSelectedTotranformation] = useState([]);

	useEffect(() => {
		if (modalIsOppen && newBanners) {
			setimageSelectedToTransform(newBanners[0]);
		}
	}, [modalIsOppen, cloudinaryFiles]);

	const handleSelectImage = (imgSelected) => {
		if (imgSelected.sourceId === imageSelectedToTransform.sourceId) {
			return;
		}
		setimageSelectedToTransform(imgSelected);
	};
	useEffect(() => {
		if (imageSelectedToTransform) {
			const newTransformations = cloudinaryFiles.find(banner => banner.sourceImage.sourceId === imageSelectedToTransform.sourceId);
			settransformations(newTransformations);
		}
	}, [imageSelectedToTransform]);
	const handleSelectImageToTransform = (imageToSelect) => {
		let imageTransformationArray = [];
		if (imagesSelectedTotranformation.find(img => img.id === imageToSelect.id)) {
			imageTransformationArray = imagesSelectedTotranformation.filter(img => img.id !== imageToSelect.id);
			setimagesSelectedTotranformation(imageTransformationArray);
		} else {
			imageTransformationArray = [...imagesSelectedTotranformation, imageToSelect];
			setimagesSelectedTotranformation(imageTransformationArray);
		}
	};
	if (!modalIsOppen || !cloudinaryFiles || !imageSelectedToTransform || !newBanners) {
		return null;
	}
	const uploadTransformationToTheLibrary = () => {
		setisLoading(true);
		businessOperationServices.postTransformationsbannerToLibrary(accountId, libraryId, imagesSelectedTotranformation,
			data => {
				const bannersList = [...data.banners];
				let operationObject = { ...operation };
				setisLoading(false);
				setTimeout(() => {
					setListBanners([...bannersList]);
					setOperation(operationObject);
					setModalIsOppen(false);
				}, 200);
			},
			error => {
				systemServices.showError('An error occured during the update of the library');
				setisLoading(true);
			});
	};

	return (
		<Modal
			isOpen={modalIsOppen}
			width="900"
			onClose={() => { setModalIsOppen(false); }}
		>
			<div className="modal_header has_border">
				Select the declinations
			</div>
			<div className="modal_body">
				<div className="flex flex_justify_between ">
					{newBanners.map(img =>
						<div key={img.sourceId}
							style={imageSelectedToTransform.sourceId === img.sourceId ?
								{ height: '100px', width: '200px', margin: 'auto 5px', border: '3px solid rgb(142, 232, 255)' } :
								{ height: '100px', width: '200px', margin: 'auto 5px' }
							} onClick={() => handleSelectImage(img)}>
							<img style={{ display: 'block', width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%', margin: 'auto' }}
								src={img.url} />
						</div>
					)}
				</div>
				<div className="flex flex_v">
					<ImageTransformContainer
						imageData={transformations}
						imageSelectedToTransform={imageSelectedToTransform}
						handleSelectImageToTransform={(imgToSelect) => handleSelectImageToTransform(imgToSelect)}
						imagesSelectedTotranformation={imagesSelectedTotranformation}
					/>
				</div>
			</div>
			<div className="modal_footer modal_footer_center ">
				<div className="flex flex_justify_between">
					<div>
						{imagesSelectedTotranformation.length > 1 ? `${imagesSelectedTotranformation.length} transformations selected` : `${imagesSelectedTotranformation.length} transformation selected`}
					</div>
					<div>
						<Btn
							onClickFunction={() => setModalIsOppen(false)}
							message="Cancel"
							color="secondary"
							size="l"
							style="outline"
						/>
						<Btn
							onClickFunction={(e) => uploadTransformationToTheLibrary()}
							message="Next"
							size="l"
							color="primary"
						/>
					</div>
				</div>
			</div>
			{isLoading &&
				<Spinner overlay={true} />
			}
		</Modal>
	);
}
