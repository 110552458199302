import React, { useState, useEffect, Fragment } from 'react';
import Modal from '../../../../Components/Modal';
import Btn from '../../../../Components/Btn';
import { CampaignsContext } from '../../context';
import './InPageStyle.css';
import DefineManualyInPage from './DefineManualyInPage';
import DefineLiveInPage from './DefineLiveInPage';
import HelpdeskServices from '../../../../Services/HelpdeskServices';
import {useTranslation} from 'react-i18next';
function InPageModalHeader({
	title = '',
	preTitle = '',
	hasBack = false,
	handleBack,
}) {
	const { askHelpAbout } = HelpdeskServices();

	return (
		<div className="modal_header has_border flex">
			{hasBack && (
				<a
					className="modal_header_icon icon_btn icon_btn_color flex_item_fix"
					onClick={() => handleBack()}
				>
					<i className="fas fa-arrow-left"></i>
				</a>
			)}
			<div className="flex_item_full">
				{preTitle && (
					<div className="modal_header_pre_title">{preTitle}</div>
				)}
				{title}
			</div>
		</div>
	);
}

export default function InPageContainer({
	hasBorder = true,
	hasHover = false
}) {
	const { handleUpdateInpage, campaign, getCurrentVariation} = CampaignsContext();
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [inPageStep, setInPageStep] = useState('main');
	const [modalWidth, setModalWidth] = useState(460);

	const [targetIndex, setTargetIndex] = useState(1);
	const [targetSelector, setTargetSelector] = useState();
	const [targetPosition, setTargetPosition] = useState('AdjacentAfterEnd');
	const [targetEditor, setTargetEditor] = useState();

	const [targetIndexEdit, setTargetIndexEdit] = useState();
	const [targetSelectorEdit, setTargetSelectorEdit] = useState();
	const [targetPositionEdit, setTargetPositionEdit] = useState();
	const [targetEditorEdit, setTargetEditorEdit] = useState();

	const [positionSentence, setPositionSentence] = useState(getPositionSentence(targetPosition, targetSelector));
	const [urlDebug, setUrlDebug] = useState(null);

	const cssClass = ['format_param_group', 'clickable', 'folded', 'overflow_hidden'];
	if (hasBorder === false) {
		cssClass.push('no_border');
	}
	if (hasHover === true) {
		cssClass.push('has_hover');
	}

	const cancelModal = () => {
		setModalIsOpen(false);
		resetModal();
	};

	const resetModal = () => {
		setTargetIndexEdit(targetIndex);
		setTargetSelectorEdit(targetSelector);
		setTargetPositionEdit(targetPosition);
		setTargetEditorEdit(targetEditor);
		setInPageStep(targetEditor || 'main');
	};

	const pushConfirm = () => {
		handleUpdateInpage({
			InPageElementSelector: targetSelectorEdit,
			InPagePositionStartIndex: targetIndexEdit -1,
			InPagePosition: targetPositionEdit
		}, urlDebug, targetEditorEdit);

		setModalIsOpen(false);
	};

	const isEmpty = (obj) => {
		return Object.keys(obj).length === 0;
	};
	const activeVariation = getCurrentVariation() || {};
	useEffect(() => {
		if (campaign.Slide.InPage && !isEmpty(campaign.Slide.InPage)) {
			let {
				InPageElementSelector,
				InPagePositionStartIndex,
				InPagePosition,
			} = campaign.Slide.InPage;

			if (InPagePositionStartIndex) {
				setTargetIndex(InPagePositionStartIndex + 1);
			}
			if (InPageElementSelector && InPagePosition) {
				setTargetSelector(InPageElementSelector);
				setTargetPosition(InPagePosition);
			}
		}

		if (campaign.Slide.Properties && campaign.Slide.Properties.length > 0) {
			const editor = campaign.Slide.Properties.find(
				(p) => p.Name === 'InPageEditor'
			);

			if (editor) {

				setTargetEditor(editor.Value);
				setInPageStep(editor.Value);
			}
		}

		if (campaign.Slide.Properties && campaign.Slide.Properties.length > 0) {
			const url = campaign.Slide.Properties.find(
				(p) => p.Name === 'InpageUrl'
			);
			if (url) {
				setUrlDebug(url.Value);
			}
		}
	}, [campaign]);

	useEffect(() => {
		if (modalIsOpen) {
			resetModal();
		}
	}, [modalIsOpen]);

	useEffect(() => {
		if (inPageStep == 'main') {
			setModalWidth(460);
			return;
		}
		setModalWidth(700);
	}, [inPageStep]);

	function getPositionSentence(pos, sel) {
		if (!sel) {
			return '';
		}
		if (pos == 'Replace') {
			return 'Replace '+sel;
		}
		if (pos == 'AdjacentBeforeBegin') {
			return 'Before '+sel;
		}
		if (pos == 'AdjacentAfterBegin') {
			return 'First element of  '+sel;
		}
		if (pos == 'AdjacentBeforeEnd') {
			return 'Last element of '+sel;
		}
		if (pos == 'AdjacentAfterEnd') {
			return 'After '+sel;
		}
		return pos;
	}

	useEffect(() => {
		setPositionSentence(getPositionSentence(targetPosition, targetSelector));
	}, [targetPosition, targetSelector]);
	const [t, i18n] = useTranslation('common');
	return (
		<>
			<div className={cssClass.join(' ')}>
				<div
					className="format_param_group_name flex"
					onClick={() => setModalIsOpen(true)}
				>
					<span className="format_param_group_icon flex_item_fix">
						<i className="fas fa-crosshairs"></i>
					</span>
					<span className="flex_item_full flex flex_align_baseline overflow_hidden">
						<span className="flex_item_fix">
						{t('insert.where')} 
						</span>
						<span className="flex_item_full ellips format_param_group_value">
							{positionSentence ? positionSentence : <strong className="c_red">{t('insert.Unset')} </strong>}
						</span>
					</span>
					<span className="format_param_group_arrow flex_item_fix">
						<i className="fas fa-chevron-right"></i>
					</span>
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
				width={modalWidth}
				onClose={() => {
					cancelModal();
				}}
			>
				{inPageStep === 'main' && (
					<>
						<InPageModalHeader title={t('insert.where')}  />
						<div className="modal_body">
							<ul className="thumb_list">
								<li
									className="thumb_list_item"
									onClick={() => {
										setInPageStep('livePageInspector');
										setTargetEditorEdit('livePageInspector');
									}}
								>
									<div className="thumb_list_item_icon">
										<i className="fas fa-magic"></i>
									</div>
									<div className="thumb_list_item_body">
										<div className="thumb_list_item_name s_16">
											<span className="v_al_middle">
											{t('insert.useLive')} 
											</span>
										</div>
										<div className="thumb_list_item_name_desc ">
										{t('insert.noTechnical')} 
										</div>
									</div>
									<div className="thumb_list_item_arrow">
										<i className="fas fa-chevron-right"></i>
									</div>
								</li>
								<li
									className="thumb_list_item"
									onClick={() => {
										setInPageStep('advancedOptions');
										setTargetEditorEdit('advancedOptions');
									}}
								>
									<div className="thumb_list_item_icon">
										<i className="fas fa-code"></i>
									</div>
									<div className="thumb_list_item_body">
										<div className="thumb_list_item_name s_16">
										{t('insert.Advanced')} 
										</div>
										<div className="thumb_list_item_name_desc">
										{t('insert.define')}  
										</div>
									</div>
									<div className="thumb_list_item_arrow">
										<i className="fas fa-chevron-right"></i>
									</div>
								</li>
							</ul>
						</div>
						<div className="modal_footer has_border mt_10 flex">
							<div className="flex_item_full"></div>
							<div className="flex_item_fix">
								<Btn
									style="ghost"
									color="secondary"
									message={t('actions.cancel')}
									onClick={() => {
										cancelModal();
									}}
								/>
							</div>
						</div>
					</>
				)}
				{inPageStep === 'livePageInspector' && (
					<>
						<InPageModalHeader
							title={t('insert.livePage')}
							preTitle={t('insert.where')} 
							hasBack={true}
							handleBack={() => setInPageStep('main')}
						/>
						<DefineLiveInPage
							targetSelector={targetSelectorEdit}
							setTargetSelector={setTargetSelectorEdit}
							targetPosition={targetPositionEdit}
							setTargetIndexEdit={setTargetIndexEdit}
							setTargetPosition={setTargetPositionEdit}
							setUrlDebug={setUrlDebug}
							urlDebug={urlDebug}
							handleCancel={() => cancelModal()}
							handleConfirm={() => pushConfirm()}
						/>
					</>
				)}
				{inPageStep === 'advancedOptions' && (
					<>
						<InPageModalHeader
							title={t('insert.Advanced')} 
							preTitle={t('insert.where')} 
							hasBack={true}
							handleBack={() => setInPageStep('main')}
						/>
						<DefineManualyInPage
							targetSelector={targetSelectorEdit}
							setTargetSelector={setTargetSelectorEdit}
							targetIndex={targetIndexEdit}
							setTargetIndex={setTargetIndexEdit}
							targetPosition={targetPositionEdit}
							setTargetPosition={setTargetPositionEdit}
							handleCancel={() => cancelModal()}
							handleConfirm={() => pushConfirm()}
						/>
					</>
				)}
				{/*<div className="modal_footer has_border mt_10 flex">
                    <div className="flex_item_full">
                        {inPageStep === "livePageInspector" && targetSelectorEdit && (
                            <Btn
                                message="Update target"
                                icon="fas fa-magic"
                                style="reverse"
                                color="primary"
                                onClick={() => pushConfirm()}
                                disabled={!targetSelectorEdit}
                            />
                        )}
                    </div>
                    <div className="flex_item_fix">
                        <Btn
                            style="ghost"
                            color="secondary"
                            message="Cancel"
                            onClick={() => {
                                cancelModal()
                            }}
                        />
                        {inPageStep !== "main" && (
                            <Btn
                                message="Confirm"
                                onClick={() => pushConfirm()}
                                color="primary"
    							disabled={!targetSelectorEdit}
                            />
                        )}
                    </div>
                </div>*/}
			</Modal>
		</>
	);
}
