import React from 'react';
import commonStyles from '../ProductFeed.module.css';
import editionStyles from '../Edition/ProductFeedEdition.module.css';
import {  getFeedTypePictoClass} from '../utils';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
export default function CreateOnsiteData() {
	const {
		productFeedObject,
		getFeedTypeName
	} = useProductFeedEditionContext();

	const pictoClass = getFeedTypePictoClass(productFeedObject?.type);

	return (
		<div className='flex'>
			<div className='flex_item_fix'>
				<div className={editionStyles.card_picto}>
					<div className={commonStyles.picto + ' ' + commonStyles['picto_' + pictoClass]}></div>
				</div>
			</div>
			<div className='flex_item_full overflow_hidden'>
				<div className={editionStyles.card_subtitle}>{getFeedTypeName(productFeedObject?.type)}</div>
				<div className={editionStyles.card_title}>OnSite feed</div>
			</div>
		</div>
	);
}
