import React from 'react';
import {useTranslation} from 'react-i18next';
export default function TitleInspirations({
	selectedModules
}) {
	const [t, i18n] = useTranslation('common');
	return (
		<div className="inspi_modules_header_nb grey_1">
			{selectedModules.length + ' '} 
			{selectedModules.length > 1 ? t('inspirations.recommendations') : t('inspirations.recommendation')}
		</div>
	);
}
