import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import matchSorter from 'match-sorter';
import Btn from '../../../Components/Btn';
import SearchBar from '../../../Components/SearchBar';
import EmptyState from '../../../Components/EmptyState';
import InputCustom from '../../../Components/InputCustom';

const newRow = (fields, date) => {
	const row = {};
	for (const f in fields) {
		if (fields.hasOwnProperty(f)) {
			const element = fields[f];
			row[element.fieldName] = element.answer;
		}
	}
	row['date'] = date;
	return row;
};

export default function FormCustomReportTableComponentHooks({ slideId, loading, answers,currentPage, paginationArray ,fetchNextPage, fetchPrevPage, allAnswers}) {
	const [data, setdata] = useState();
	const [dataAllAnswers, setdataAllAnswers] = useState();
	const [searchValue, setSearchValue] = useState('');

	const [newDataFields, setnewDataFields] = useState(<></>);
	const [dataFiltered, setDataFiltered] = useState();
	const [orderItems, setOrderItems] = useState();

	const [order, setOrder] = useState('down');

	useEffect(() => {
		if (answers) {
			setdata(makeData(answers));
		}
	}, [answers]);
	useEffect(() => {
		if (allAnswers) {
			setdataAllAnswers(makeData(allAnswers));
		}
	}, [allAnswers]);
	useEffect(()=>{
		if(data){
			handleChange();
		}
	},[data]);
	useEffect(()=>{
		if(dataFiltered){
			setnewDataFields(getRows(dataFiltered.tableColumns, dataFiltered.rows));
			
		}
	}
	,[dataFiltered]);

	const makeData = (answers) => {
		const tableColumns = [];
		const cols = {};
		const allFields = answers.flatMap(x => x.fields);
		for (const field in allFields) {
			if (allFields.hasOwnProperty(field)) {
				const element = allFields[field];
				cols[element.fieldName] = {
					id: element.fieldName,
					Header: element.fieldName,
					accessor: element.fieldName
				};
			}
		}

		const keys = [];
		for (const f in cols) {
			if (cols.hasOwnProperty(f)) {
				tableColumns.push(cols[f]);
				keys.push(cols[f].id);
			}
		}

		const dateCol = {
			id: 'date',
			Header: 'Answer Date',
			accessor: 'date'
		};
		const filterCol = {
			Header: 'All',
			id: 'all',
			width: 0,
			className: 'all-cell',
			style: {
				backgroundColor: 'red',
				padding: 0
			},
			resizable: false,
			sortable: false,
			filterMethod: (filter, rows) => {
				const result = matchSorter(rows, filter.value, {
					keys: keys,
					threshold: matchSorter.rankings.WORD_STARTS_WITH
				});
				return result;
			},
			filterAll: true
		};

		tableColumns.push(dateCol);
		// tableColumns.push(filterCol);
		if(!orderItems){
			setOrderItems(tableColumns[0].Header);
		}
		var rows = answers.map(answer => {
			return {
				...newRow(answer.fields, moment(answer.answerAtUtc, 'YYYY/MM/DD HH:mm').local().format('DD-MM-YYYY HH:mm'))
			};
		});

		return { rows, tableColumns };
	};

	const handleChange = (e) => {
		if(!e){
			setDataFiltered(orderBy(orderItems,data.rows));
			setSearchValue('');
		}else{
			const { value } = e.target;
			setSearchValue(value);
			const search = (table, word) => {
				if (word.length >= 0) {
					setDataFiltered([]);
		
					let resultName = table.rows.filter(
						line =>
							line.name_product !== null && line.name_product !== undefined && line.name_product.toUpperCase().match(`.*${word.toUpperCase()}.*`)
					);
					let resultpage_type = table.rows.filter(
						line =>
							line.page_type !== null && line.page_type !== undefined && line.page_type.toUpperCase().match(`.*${word.toUpperCase()}.*`)
					);
					let resultsku_product = table.rows.filter(
						line =>
							line.sku_product !== null && line.sku_product !== undefined && line.sku_product.toUpperCase().match(`.*${word.toUpperCase()}.*`)
					);
					let resultdate = table.rows.filter(
						line =>
							line.date !== null && line.date !== undefined && line.date.toUpperCase().match(`.*${word.toUpperCase()}.*`)
					);
				
					let finalArray = [...resultName, ...resultpage_type, ...resultsku_product,...resultdate ];
					let uniqueItem = [...new Set(finalArray)];
					if (uniqueItem.length === 0) {
						uniqueItem = 'No account matches with this search';
						
					}
					else {
						setDataFiltered({tableColumns : data.tableColumns,
							rows : uniqueItem
						});
					}
				}
			};
			
			//search(data,value);
			search(orderBy(orderItems,data.rows),value);
		}
	};
	function getRows(tableColumns, rows){
		if (!rows || rows.length === 0) return <></>;
		return (
			<div className="table_flex table_scroll">
				<div className={'table_row table_head_row'}>
					{tableColumns && tableColumns.map((colum,i) => 
						<div key={i} className="table_col table_col_w_l">
							<a className='table_sort ellips' onClick={()=>orderBy(colum.id, rows, true)}>
								{colum.Header}
								{orderItems === colum.Header && order === 'up' &&
									<i className="fas fa-arrow-up"></i>
								}
								{orderItems === colum.Header && order === 'down' &&
									<i className="fas fa-arrow-down"></i>
								}
							</a>
						</div>
					)}
				</div>
				{rows && rows.map((row,i) => 
					<div
						key={i}
						className="table_row table_body_row"
					>
						{Object.values(row).map((rowX,idx) => 
							<div key={idx} className="table_col table_col_w_l">
								<div className='ellips' title={rowX}>{rowX}</div>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}

	const orderBy = (id,arr, needRefresh = false) => {
		let newOrder = needRefresh ? order === 'down' ? 'up' : 'down' : order;
		let arrN = [...arr];

		arrN = arrN.sort((a, b) => {
			if (newOrder === 'down') {
				if (a === undefined) return;
				if (b === undefined) return;
				if (a[id] === null) return 1;
				if (b[id] === null) return -1;
				if (a[id] < b[id]) return -1;
				if (a[id] > b[id]) return 1;
				return 0;
			} else {
				if (a === undefined) return;
				if (b === undefined) return;
				if (a[id] === null) return 1;
				if (b[id] === null) return -1;
				if (a[id] > b[id]) return -1;
				if (a[id] < b[id]) return 1;
				return 0;
			}
		});
		setOrder(newOrder);
		setOrderItems(id);
		if(needRefresh){
			setDataFiltered({tableColumns : data.tableColumns,
				rows : arrN
			});
		}
		return({tableColumns : data.tableColumns,
			rows : arrN
		});
	};

	return (
		<>
			{data &&
				<>
					<div className="reporting_filters flex_item_full">
						{/* Disable search because it search only in the current page */}
						{/*
							<SearchBar
								value={searchValue}
								onChangeValue={(e) => handleChange(e)}
								placeholder="Search"
								fullWidth={false}
								size='s'
							/>
						*/}
					</div>
					<div className="reporting_filters flex_item_fix">
						{dataAllAnswers &&
						<CSVLink
							data={dataAllAnswers.rows}
							filename={`${slideId}.csv`}
							className="reporting_link"
							target="_blank">
						Download CSV
						</CSVLink>
						}
					</div>
					<div className="reporting_body flex_item_full">
						{newDataFields}
						{dataFiltered && dataFiltered.length === 0 && 
							<EmptyState
								icon="fas fa-search"
								title="No matching response"
								text="Please try another search"
							/>
						}
						{dataFiltered && dataFiltered.rows && dataFiltered.rows.length > 0 &&
							<div className="mt_10">
								<Btn
									disabled={currentPage === 0}
									onClick={() => {fetchPrevPage(currentPage); handleChange();}}
									tooltip="Previous"
									color="secondary"
									style="ghost"
									icon="fas fa-chevron-left"
									size="s"
									horizontalSize="s"
								/>
								<Btn
									disabled={!paginationArray[currentPage]?.nextPartitionKey}
									onClick={() => {fetchNextPage(paginationArray[currentPage].nextPartitionKey, paginationArray[currentPage].nextRowKey, currentPage); handleChange();}}
									tooltip="Next"
									color="secondary"
									style="ghost"
									icon="fas fa-chevron-right"
									size="s"
									horizontalSize="s"
								/>
							</div>
						}
					</div>
				</>
			}
		</>
	);
}