
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './VariablesAvailable.module.css';

export default function VariablesAvailable({
	name,
	elementToCopy,
	clipBoardContain,
	copyToClipBoard
}) {

	const [icon, setIcon] = useState('fas fa-copy');

	const handleCopy = () => {
		copyToClipBoard(elementToCopy);

		setIcon('fas fa-check');
		setTimeout(function(){
			setIcon('fas fa-copy');
		}, 3000);
	};

	return (
		<>
			<ReactTooltip
				backgroundColor='black'
				effect='solid'
				place="bottom"
				globalEventOff="click"
				delayShow={600}
			/>
			<div
				onClick={() => handleCopy()}
				className={styles.item}
				data-tip={'Click to copy'}
			>
				{name}
				<i className={icon} />
			</div>
		</ >
	);
}
