import React from 'react'

function TabBar({
    children,
    appearance = 'underline',
    hasBorder = true,
    className = '',
    size = 'm',
    upperCase = false
}) {

    let cssClass = ['flex'];

    if (appearance == 'rounded') {
        cssClass.push('rounded_tabs');
    } else {
        cssClass.push('tabs');
    }

    // className
    if (className) {
        cssClass = cssClass.concat(className);
    }

    // Size
    if (size) {
        cssClass.push(size);
    }

    // Border
    if (!hasBorder) {
        cssClass.push('no_border');
    }

    // upperCase
    if (upperCase) {
        cssClass.push('up_case');
    }

    return (
        <nav className={cssClass.join(' ')}>
            <ul className='tab_list flex_item_auto'>
                {children}
            </ul>
        </nav>
    )
}
export default TabBar;