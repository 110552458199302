import React from 'react'
import Btn from '../../../../../Components/Btn'

export default function AlertInpageDownloadChromeExt({
	hasGoodVersion,
	refresh,
	downloadExtension
}) {
  return (
	<div className="empty_state v_pad_m">
	<div className="empty_state_title">
		{!hasGoodVersion
			? "Please upgrade your BEYABLE Chrome extension"
			: "First step : please download and install BEYABLE Chrome extension"}
	</div>
	<div className="empty_state_text">
		After installing the extension,{" "}
		<a onClick={() => refresh()} className="text_link">
			click here to refresh
		</a>
		.
	</div>
	<div className="empty_state_btn">
		<Btn
			size="l"
			message="Get Chrome extension"
			icon="fas fa-cloud-download-alt"
			onClick={() => downloadExtension()}
		/>
	</div>
</div>  )
}
