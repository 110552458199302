import React, { createContext, useState, useEffect } from 'react';

export const AccountSetupContext = createContext();

const AccountSetupProvider = (props) => {

	const [data, setData] = useState([]);

	return (
		<AccountSetupContext.Provider value={{
			data,
			setData
		}}>
			{props.children}
		</AccountSetupContext.Provider>
	);

};
export default AccountSetupProvider;