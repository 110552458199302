import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import styles from '../Board/Board.module.css';

import { useEcommerceBuyingbehaviorContext } from '../../context/EcommerceBuyingBehaviorContext';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import PaginationBoard from './PaginationBoardPerformance';
import AnalitycsBoardPerformance from './AnalitycsBoardBuyingBehavior';
import {
	Revenue,
	Quantity,
	SessionToSaleRatio,
	TransactionCount,
	TransactionAverageAmount,
	AverageAmount,
	TransactionAmount,
	AverageQuantityInTransactions,
	ListItemViewedCount,
	ListItemSelectedCount,
	ItemAddedToCartCount,
	CheckoutStartedCount,
} from './config';
import AnalitycsBoardBuyingBehavior from './AnalitycsBoardBuyingBehavior';
export default function BuyingBehaviorBoardContainer  ({ services }) {

	const { selectedTenant } = useAnalyticsContext();
	const {
		handleSelectUrl,
		reportTypeSelected,
		navFilter,
		searchValue,
		sourceType,
		orderBy,
		setOrderBy,
		orderByDesc,
		setOrderByDesc,
		filterUrl,
		offset, 
		setOffset,
		currentPage,
		setCurrentPage,
		dimensionId,
		handleSelectElem,
		setChips,
		reportTypeBuyingBehaviorSelected,
		deviceSelected,
		utmDimensionsSelected
	} = useEcommerceBuyingbehaviorContext();

	const [afterRender,setAfterRender] = useState(false);
	const [currentMetrix, setCurrentMetrix] = useState({
		data: null,
		dataSumsMetrics :null,
		isLoading: true,
		isError: false,
		isErrorEcommerce: false
	});
	const [numberOfData, setNumberOfData] = useState(null);
	const [tableToRender, setTableToRender] = useState(<></>);
	const [pageSize, setpageSize] = useState(5);
	const [urlSelected, setUrlSelected] = useState();

	useEffect(() => {
		setOffset(0);
		setChips(null);
	}, [reportTypeBuyingBehaviorSelected]);

	useEffect(() => {
		setAfterRender(true);
		if(offset !== 0){
			setOffset(0);
		}      
		else if (afterRender) {
			refreshBoard();
		}   
	}, [selectedTenant]);

	useEffect(() => {
		if (!dimensionId) {
			setUrlSelected();
		}
	}, [dimensionId]);

	useEffect(() => {
		if (urlSelected && urlSelected !== dimensionId) {
			handleSelectUrl(dimensionId);
		}
	}, [dimensionId]);

	const refreshBoard = () => {
		setCurrentMetrix((current) => ({
			...current,
			data: null,
			dataSumsMetrics :null,
			isLoading: true,
		}));
		const options = {
			...navFilter,
			metrics: [
				Revenue,
				ListItemViewedCount,
				ListItemSelectedCount,
				ItemAddedToCartCount,
				CheckoutStartedCount,
				TransactionCount,
			],
			orderBy: orderBy,
			orderByDesc: orderByDesc === 'desc' ? true : false,
			timeGranularity: 'None',
			trafficSource : sourceType,
			dimension : reportTypeBuyingBehaviorSelected,
			// dimensionId: dimensionId,
			limit: pageSize,
			offset : offset,
			device : deviceSelected,
			utmDimensionsSelected : utmDimensionsSelected
		};
		services.getDimensionsOverview(
			options,
			(data, headers) => {
				services.getMetricsSums(options,
					(dataSumsMetrics) => {
						const dataFormatted = data.dimensionValues;
						if(numberOfData!==parseInt(headers('x-total-count'))){
							setNumberOfData(parseInt(headers('x-total-count')));}
						setCurrentMetrix(() => ({
							data: dataFormatted,
							dataSumsMetrics :dataSumsMetrics,
							isLoading: false,
							isError: false,
							isErrorEcommerce : false
						}));
						renderTable(dataFormatted,dataSumsMetrics?.metrics);
					},
					(err,typeError) => {
						console.log(err);
						const statusErr = typeError.status;
						setCurrentMetrix((current) => ({
							...current,
							isLoding: false,
							isLoading: false,
							isErrorEcommerce : statusErr === 404
						}));
					}
				);
			},

        
			(err, typeError) => {
				console.log(err);
				const statusErr = typeError.status;
				setCurrentMetrix((current) => ({
					...current,
					isLoding: false,
					isLoading: false,
					isErrorEcommerce : statusErr === 404
				}));
			});
	};
	useEffect(() => {
		refreshBoard();
	}, [
		navFilter,
		reportTypeSelected,
		orderBy,
		orderByDesc,
		offset,
		pageSize,
		searchValue,
		sourceType,
		reportTypeBuyingBehaviorSelected,
		currentPage,
		utmDimensionsSelected,
		deviceSelected
	]);

	const handleSort = (orderField, order) => {
		setOffset(0);
		setOrderByDesc(order);
		setOrderBy(orderField);
	};

	function renderTable(data,dataSumsMetrics) {
		setTableToRender(
			<AnalitycsBoardBuyingBehavior
				resultName={reportTypeBuyingBehaviorSelected}
				ListItemViewedCount={'Product list views'}
				ListItemViewedCountTooltip={'Number of times the product appeared in a product list.'}
				ItemAddedToCartCount={'Product adds to cart'}
				ItemAddedToCartCountTooltip={'Number of times the product was added to the shopping cart.'}
				CheckoutStartedCount={'Product checkouts'}
				CheckoutStartedCountTooltip={'Number of times the product was included in the check-out process.'}
				TransactionCount={'Unique transaction'}
				TransactionCountTooltip={'Number of times the product was a part of a transaction.'}
				ListItemSelectedCount="Product list clicks"
				ListItemSelectedCountTooltip="Number of times users clicked the product when it appeared in a product list."
				Revenue={'Revenue'}
				RevenueTooltip={'Revenue from individual product sales.'}
				ctr={'Product list CTR'}
				ctrTooltip={'Product list click rate.'}
				data={data}
				dataSumsMetrics={dataSumsMetrics}
				orderBy={orderBy}
				orderByDesc={orderByDesc}
				handleSort={handleSort}
				handleSelectElem={(e,n)=>handleSelectElem(reportTypeBuyingBehaviorSelected,e,n)}
			/>
		);
	}

	return (
		<section className={styles.section}>
			{currentMetrix.isLoading && (
				<div className="mask">
					<span className="wheel"></span>
				</div>
			)}
			{!currentMetrix.isLoading && currentMetrix.isError && !currentMetrix.isErrorEcommerce && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_error.svg"
					/>
					<div className="empty_state_title">Cannot fetch data</div>
					<div className="empty_state_text">Please retry later.</div>
				</div>
			)}
			{!currentMetrix.isLoading &&
                !currentMetrix.isError &&
                currentMetrix?.data?.length == 0 && !currentMetrix.isErrorEcommerce &&(
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_empty.svg"
					/>
					<div className="empty_state_title">
                            No data for this period
					</div>
					<div className="empty_state_text">
                            Maybe try with another period or other filters
					</div>
				</div>
			)}
			{
				currentMetrix.isErrorEcommerce && (
					<div className="empty_state analytics_empty_state">
						<img
							className="empty_state_img"
							src="/Assets/analytics_data_error.svg"
						/>
						<div className="empty_state_title">
						No ecommerce data 
						</div>
						<div className="empty_state_text">
					Check your setup.
						</div>
					</div>
				)}

			{!currentMetrix.isLoading &&
                !currentMetrix.isError &&
                currentMetrix.data && !currentMetrix.isErrorEcommerce &&
                currentMetrix.data.length > 0 && (
				<>
					<div className="analytics_table_wrapper">
						{tableToRender}
					</div>
					<PaginationBoard
						totalCount={numberOfData}
						pageSize={pageSize}
						handleChangePageSize={(e) => {
							setpageSize(e);
							setOffset(0);
						}}
						paginate={(e) => setOffset(e >= 0 ? e : 0)}
						skipIdNumber={offset}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				</>
			)}
		</section>
	);
}
