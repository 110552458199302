import React, { useState, useEffect } from 'react';
import SessionsBarChart from './SessionsBarChart';
import PieChart from './PieChart';
import { useEcommercePerformanceContext } from '../../context/EcommercePerformanceContext';
import PerformanceBarChart from './PerformanceBarChart';

const ChartSwitchPerformance = ({
	data = {
		// charts: [{ datasets: [], labels: [], type: '', title: '' }],
		isLoading: true, isError: false,
	},
	// typeChart,
}) => {

	const {
		ComparisonSelected,
		chartData,
		typeChartSelected
	} = useEcommercePerformanceContext();
	
	const [selectedChartIndex, setSelectedChartIndex] = useState();
	const [selectedTypeChart, setselectedTypeChart] = useState('bar');


	useEffect(() => {
		if (chartData.charts && chartData.charts.length > 0) {
			if (ComparisonSelected) {
				const findFirstIndex = chartData.charts.find(arr => arr.key === 0);
				setselectedTypeChart(findFirstIndex.type);
				setSelectedChartIndex(0);
			} else {
				setselectedTypeChart(chartData.charts[0].type);
				setSelectedChartIndex(0);
			}
		}

	}, [chartData]);

	const onHandleChangeSelectedChart = (index) => {
		setSelectedChartIndex(index);
		setselectedTypeChart(chartData.charts[index].type);
	};

	const dataSetsAreZero = (dataToCheck) => {
		if (dataToCheck) {
			let val = '';
			val = dataToCheck && dataToCheck.datasets.length > 0 && dataToCheck.datasets.every(s => s.data.every(x => x == 0));
			return val;
		} else {
			return false;
		}
	};

	const SwitchChart = () => {
		if (chartData.charts.length <= 1) {
			return (<></>);
		}
		return (
			<ul className="tabs mb_20">
				{chartData.charts.map((c, i) => {
					const isSelected = c.key === selectedChartIndex;
					return (
						<li key={i}>
							<a className={isSelected ? 'active' : ''}
								onClick={() => onHandleChangeSelectedChart(c.key)}>
								{c.title} ({c.type})
							</a>
						</li>
					);
				})}
			</ul>
		);
	};
	// if (selectedChart.datasets) {console.log("dataSetsAreZero()", dataSetsAreZero())}
	// console.log("chartData.charts", chartData.charts);
	const labelCharts = () => {
		const res = chartData.charts[0]?.listOfMetrics && chartData.charts[0]?.listOfMetrics.reduce((acc, i) => {
			if (!acc.includes(i.key)) {
				acc.push(i.key);
			}
			return acc;
		}, []);
		return res;
	};
	return (
		<>
			<div >
				{chartData.isLoading && !chartData.isError &&
					<div className="mask">
						<span className="wheel"></span>
					</div>
				}
				{!chartData.isLoading && chartData.isError && !chartData.isErrorEcommerce &&
					<div className="empty_state analytics_empty_state">
						<img className="empty_state_img" src="/Assets/analytics_data_error.svg" />
						<div className="empty_state_title">Cannot fetch data</div>
						<div className="empty_state_text">Please retry later.</div>
					</div>
				}
				{!chartData.isLoading && !chartData.isError && !chartData.isErrorEcommerce && chartData.charts.find(arr => arr.key === selectedChartIndex) && (chartData.charts.find(arr => arr.key === selectedChartIndex).datasets.length == 0 || dataSetsAreZero(chartData.charts.find(arr => arr.key === selectedChartIndex))) &&
					<div className="empty_state analytics_empty_state">
						<img className="empty_state_img" src="/Assets/analytics_data_empty.svg" />
						<div className="empty_state_title">No data for this period</div>
						<div className="empty_state_text">Maybe try with another period or other filters</div>
					</div>
				}

				{!chartData.isLoading && !chartData.isError &&!chartData.isErrorEcommerce 
					&&
					!dataSetsAreZero(chartData.charts.find(arr => arr.key === selectedChartIndex)) &&
					<>
						{SwitchChart()}
						<PerformanceBarChart
							typeChart={'areaspline'}
							listOfMetricsLabel={labelCharts()}
							data={chartData.charts[0]}
						/>
					</>
				}
				{
					chartData.isErrorEcommerce && (
						<div className="empty_state analytics_empty_state">
							<img
								className="empty_state_img"
								src="/Assets/analytics_data_error.svg"
							/>
							<div className="empty_state_title">
						No ecommerce data 
							</div>
							<div className="empty_state_text">
					Check your setup.
							</div>
						</div>
					)}
			</div>

		</>);

};

ChartSwitchPerformance.displayName = 'ChartSwitchPerformance';

export default ChartSwitchPerformance;
