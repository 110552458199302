import React from 'react';
import { react2angular } from 'react2angular';
import ProductFeedEditionContextProvider from '../context/ProductFeedEditionContext';
import ProductFeedEditorContainer from './ProductFeedEditorContainer';
export default function ProductFeedEditorBridge(props) {
	return (
		<ProductFeedEditionContextProvider
			$http={props.$http}
			$timeout={props.$timeout}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
		>
			<ProductFeedEditorContainer />
		</ProductFeedEditionContextProvider>
	);
}


angular.module('beyableSaasApp.ProductFeedEditor', []).component('productfeededitor', react2angular(ProductFeedEditorBridge, [], ['$http','$timeout', 'RestServices', '$rootScope', '$scope', '$routeParams', 'AuthServices']));