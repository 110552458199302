

export const getLocalisedValue = (v) => {
	const i18nL = localStorage.getItem('i18nL');
	const currLangCode = i18nL != null && i18nL.includes('fr') ? 'fr' : 'en';
	//const defaultLangCode = 'en';

	if (!v) return '';

	if (typeof v === 'string') return v;

	if (typeof v === 'object') {
		if (currLangCode in v) return v[currLangCode];
		// if (defaultLangCode in v) return v[defaultLangCode];

		const [a, firstValue] = Object.entries(v)[0];
		a;
		return firstValue;
	}
};
