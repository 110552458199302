import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import { check } from '../../../Components/Can';
export default function StatusOperation({ accountId, op, stats, onClickFunction, onStartCapture, setStateOfOperationToValidate, setDataOfOperationToPut, showPreview = true, userSimplifyRole, permissions }) {
	const today = moment();
	const operation = !stats ? op.operation : op;
	const idTooltipTestingUrl = uuidv4();
	const idTooltipTesting = uuidv4();
	const idTooltipStatus = uuidv4();
	const screenCaptureTooltip = uuidv4();
	let canTestOperation, canEditStatusOperation;
	if (userSimplifyRole && permissions) {
		canTestOperation = check(permissions, userSimplifyRole, 'simplifyOperation:canTestOperation', null);
		canEditStatusOperation = check(permissions, userSimplifyRole, 'simplifyOperation:canEditStatusOperation', null);
	}

	const screenCaptureButton = () => (
		<>
			<ReactTooltip id={screenCaptureTooltip} className="labelKpi" backgroundColor='black' effect='solid' />
			<a onClick={onStartCapture} data-for={screenCaptureTooltip} className="simplify_test_link" data-tip="Start capture" ><i className="fas fa-camera"></i>Capture</a>
		</>
	);
	const shouldDisplayScreenCapture = () => {
		const flag = localStorage.getItem('display_capture_simplify');
		const mrBricolage = '9991b27d-f496-43f8-b4ec-f2e39140f1a0';
		const clubBricolage = 'f92b27bb-0f7e-48e5-865c-f6712727fc0f';
		return onStartCapture && (flag || accountId == mrBricolage || accountId == clubBricolage);
	};

	return (
		<div style={{ display: 'flex' }}>
			{
				operation &&
				<>
					{ // For check if the status is planned && if is not stopped
						moment(operation.endDate) > today ?
							moment(operation.startDate) > today && !operation.isStopped ?
								operation.executionMode == 2 ?
									<>
										{showPreview && operation.operationPreviewLink && canTestOperation &&
											<>
												<ReactTooltip id={idTooltipTestingUrl} className="labelKpi" backgroundColor='black' effect='solid' />
												<a data-for={idTooltipTestingUrl} className="simplify_test_link" href={operation.operationPreviewLink} target="_blank" data-tip="Go to testing url" rel="noreferrer" ><i className="fas fa-link"></i>Testing url</a>
											</>
										}
										<ReactTooltip id={idTooltipTesting} className="labelKpi" backgroundColor='black' effect='solid' />
										<p data-for={idTooltipTesting}
											onClick={canEditStatusOperation ? (e) => { onClickFunction(true); setStateOfOperationToValidate('testMode'); setDataOfOperationToPut(op); } : null}
											className={canEditStatusOperation ? 'operation-status testing' : 'operation-status disabled testing'} data-tip="Status operation">
											Testing</p>
									</>
									:
									<>
										<ReactTooltip id={idTooltipStatus} className="labelKpi" backgroundColor='black' effect='solid' />
										<p data-for={idTooltipStatus}
											onClick={canEditStatusOperation ? (e) => { onClickFunction(true); setStateOfOperationToValidate('ongoing'); setDataOfOperationToPut(op); } : null}
											className={canEditStatusOperation ? 'operation-status planned' : 'operation-status disabled planned'} data-tip="Status operation"> Scheduled</p>
									</>
								:
								// Then check if the status is Ongoing 
								!operation.isStopped ?
									// check if the status is test
									(operation.executionMode == 2 ?
										<>
											{showPreview && operation.operationPreviewLink && canTestOperation &&
												<>
													<ReactTooltip id={idTooltipTestingUrl} className="labelKpi" backgroundColor='black' effect='solid' />
													<a data-for={idTooltipTestingUrl} className="simplify_test_link" href={operation.operationPreviewLink} target="_blank" data-tip="Go to testing url" rel="noreferrer" ><i className="fas fa-link"></i>Testing url</a>
												</>}
											<ReactTooltip id={idTooltipTesting} className="labelKpi" backgroundColor='black' effect='solid' />
											<p data-for={idTooltipTesting}
												onClick={canEditStatusOperation ? (e) => { onClickFunction(true); setStateOfOperationToValidate('testMode'); setDataOfOperationToPut(op); } : null}
												className={canEditStatusOperation ? 'operation-status testing' : 'operation-status disabled testing'} data-tip="Status operation">
												Testing</p>
										</>
										:
										<>
											{shouldDisplayScreenCapture() && screenCaptureButton()}

											<ReactTooltip id={idTooltipStatus} className="labelKpi" backgroundColor='black' effect='solid' />
											<p data-for={idTooltipStatus}
												onClick={canEditStatusOperation ? (e) => { onClickFunction(true); setStateOfOperationToValidate('ongoing'); setDataOfOperationToPut(op); } : null}
												className={canEditStatusOperation ? 'operation-status ongoing' : 'operation-status disabled ongoing'} data-tip="Status operation">
												Ongoing</p>
										</>
									)
									:
									(<>
										<ReactTooltip id={idTooltipStatus} className="labelKpi" backgroundColor='black' effect='solid' />
										<p
											data-for={idTooltipStatus}
											onClick={canEditStatusOperation ? (e) => { onClickFunction(true); setStateOfOperationToValidate('stopped'); setDataOfOperationToPut(op); } : null}
											className={canEditStatusOperation ? 'operation-status stopped' : 'operation-status disabled stopped'} data-tip="Status operation"
										>Stopped</p>

									</>
									)
							:
							moment(operation.endDate) < today &&
							<>
								<ReactTooltip id={idTooltipStatus} className="labelKpi" backgroundColor='black' effect='solid' />
								<p data-for={idTooltipStatus}
									className="stopped_by_end_date" data-tip="Status operation">
									Past</p>
							</>
					}
				</>}
		</div>
	);
}
