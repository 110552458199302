import React, { useContext, useEffect, useState } from 'react';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import useEventBroker from '../../../../Hooks/useEventBroker';
import ZoneCtaModal from './ZoneCtaModal';
import ZoneDeleteModal from './ZoneDeleteModal';

export default function ZoneImageOrVideoPlayer({ zone, canMakeAssociation, canDefineCTA, isZoneSelected, selectZoneCallback }) {
	const {
		operation,
		setOperation,
		zoningCustomizeListValues,
		setZoningCustomizeListValues,
		listBanners,
		setSelectedZone
	} = useContext(CreateBusinessOperationContext);

	const { publish, subscribe } = useEventBroker();

	const [isZoneImage, setIsZoneImage] = useState(false);
	const [isZoneVideo, setIsZoneVideo] = useState(false);

	const requestChangeCta = () => {
		const location = operation.operation.locations.filter(x => x.zoneId === zone.zoneId)[0];
		publish('evt/businessoperations/editZoneCtaRequested', { zone: zone, isCreation: false, cta: location.extensions });
	};

	useEffect(() => {
		const location = operation.operation.locations.filter(x => x.zoneId === zone.zoneId)[0];
		if (location) {
			const locationWithBannerInformations = listBanners.find(elem => elem.bannerId === location.bannerId);
			if (locationWithBannerInformations.bannerType === 'VideoPlayer') {
				setIsZoneImage(false);
				setIsZoneVideo(true);
			} else {
				setIsZoneImage(true);
				setIsZoneVideo(false);
			}
		}
	}, [zone, isZoneSelected, operation]);

	const requestInitializeCta = (previousCta) => {
		publish('evt/businessoperations/editZoneCtaRequested', { zone: zone, isCreation: true, cta: previousCta });
	};

	const requestRemoveBannerFromZone = () => {
		publish('evt/businessoperations/removeBannerFromZoneRequested', { zone: zone });
	};

	subscribe('evt/businessoperations/bannerCropped', e => {
		const { _, croppedBanner } = e.detail;
		if (isZoneSelected) {
			bindBanner(croppedBanner);
		}
	}, [isZoneSelected]);

	subscribe('evt/businessoperations/bannerSelected', (e) => {
		const selectedBanner = e.detail;
		if (isZoneSelected) {
			bindBanner(selectedBanner);
		}
	}, [isZoneSelected]);
	const selectZoneToHandleBannerSelect = (bannerId) => {
		const id = zone.zoneId;
		const extensions = zone.zoneExtensions.Coordinates;
		const bannerType = zone.bannerType;

		setSelectedZone({ id: id, extensions: extensions, bannerId: bannerId, bannerType: bannerType });
	};
	const bindBanner = (banner) => {
		//select the item in zoningCustomizeListValues with the same id than the zone selected
		var zoning = zoningCustomizeListValues.flatMap(x => x.zones || []).find(x => x.zoneId === zone.zoneId);
		if (!zoning)
			return;

		const previousLocation = operation.operation.locations.filter(x => x.zoneId === zone.zoneId)[0];
		const previousCta = previousLocation && { ...previousLocation.extensions };

		// insert the good banner imagePath in the zone with the id selected
		zoning.imagePath = banner.imagePath;
		zoning.bannerId = banner.bannerId;
		let locationToAdd = {
			zoneId: `${zoning.zoneId}`,
			bannerId: `${banner.bannerId}`,
		};

		let newOperation = { ...operation };
		const otherLocations = newOperation.operation.locations.filter(elem => elem.zoneId !== zone.zoneId);
		newOperation.operation.locations = [...otherLocations, locationToAdd];
		setOperation(newOperation);
		setZoningCustomizeListValues([...zoningCustomizeListValues]);
		if (banner.bannerType !== 'VideoPlayer') {
			requestInitializeCta(previousCta);
			selectZoneToHandleBannerSelect(banner.bannerId);
		} else {
			selectZoneToHandleBannerSelect(banner.bannerId);
			// console.log('VideoPlayer detected');
		}
	};

	const saveCta = function (ctaToSave) {
		let prev = { ...operation };
		const location = prev.operation.locations.filter(x => x.zoneId === zone.zoneId)[0];
		let newLocation = { ...location };
		newLocation.extensions = ctaToSave;
		const otherLocations = prev.operation.locations.filter(x => x.zoneId !== zone.zoneId);
		prev.operation.locations = [...otherLocations, newLocation];
		setOperation(prev);
	};

	const deleteBanner = () => {
		delete zone.imagePath;
		delete zone.bannerId;
		let newOperation = { ...operation };
		let newLocations = [...newOperation.operation.locations].filter(elem => elem.zoneId !== zone.zoneId);
		newOperation.operation.locations = newLocations;
		setZoningCustomizeListValues([...zoningCustomizeListValues]);
		setOperation(newOperation);
		setSelectedZone({ id: zone.zoneId, extensions: zone.zoneExtensions.Coordinates, bannerId: null, bannerType: zone.bannerType });
	};

	const zoneClick = (e) => {
		e.stopPropagation();
		if (!canMakeAssociation) return;
		if (zone.bannerId) {
			selectZoneCallback(e, zone.bannerId, true);
		} else {
			selectZoneCallback(e, null, true);
		}
	};


	return (
		<>
			<span onClick={(e) => { e.stopPropagation(); }}>
				<ZoneDeleteModal deleteCallback={deleteBanner} zone={zone}></ZoneDeleteModal>
				<ZoneCtaModal saveCallback={saveCta} zone={zone}></ZoneCtaModal>
			</span>

			<div
				className={isZoneSelected ? 'customizeZone selected' : 'customizeZone'}
				style={zone.style}
				onClick={(e) => zoneClick(e)}>
				{zone.imagePath &&
					<>
						<img src={zone.imagePath} className={isZoneVideo ? 'isZoneVideo' : ''} />
						{canMakeAssociation &&
							<a className="customizeZoneRemove"
								title="Remove banner"
								onClick={(e) => { e.stopPropagation(); requestRemoveBannerFromZone(); }}
							><i className="fas fa-times"></i></a>
						}
						{canDefineCTA && isZoneImage &&
							<a className="customizeZoneEdit"
								title="Banner settings"
								onClick={(e) => { e.stopPropagation(); requestChangeCta(); }}
							><i className="fas fa-cog"></i></a>
						}
						{/* {isZoneVideo &&
                            <a className="customizeZoneEdit"
                                title="Edit Video"
                                onClick={(e) => { e.stopPropagation(); }}
                            ><i className="fab fa-youtube">
                                </i></a>
                        } */}
					</>
				}

				{!zone.imagePath &&
					<div className="customizeZoneText">{zone.zoneExtensions.Coordinates.width}x{zone.zoneExtensions.Coordinates.height}</div>
				}
			</div>
		</>
	);
}   