import React from 'react';
import { react2angular } from 'react2angular';
import {  BrowserRouter as Router } from 'react-router-dom';
import ProductFeedPreviewContainer from './ProductFeedPreviewContainer';
import ProductFeedConfigContextProvider from '../context/ProductFeedConfigContext';
import RouterWrapper from '../RouterWrapper';

export default function ProductFeedProductBridge(props) {
	return (
		<RouterWrapper props={props}>
			<div className='page_full_inner'>
				<Router>
					<section className='section no_bottom_pad section_primary'>
						<div className='h1'>Product list</div>
					</section>
					<ProductFeedConfigContextProvider
						$http={props.$http}
						$timeout={props.$timeout}
						$rootScope={props.$rootScope}
						$routeParams={props.$routeParams}
						AuthServices={props.AuthServices}
					>
						<ProductFeedPreviewContainer/>
					</ProductFeedConfigContextProvider>
				</Router>
			</div>
		</RouterWrapper>
	);
}
angular.module('beyableSaasApp.ProductFeedProduct', []).component('productfeedproduct', react2angular(ProductFeedProductBridge, [], ['$http', 'RestServices', '$rootScope', '$scope', '$routeParams', 'AuthServices']));