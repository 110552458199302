import React from 'react';
import AnalitycsBoardItem from './AnalitycsBoardItem';
import {v4 as uuidv4} from 'uuid';
import ReactTooltip from 'react-tooltip';
import styles from './Board.module.css';

export default function AnalitycsBoardLevel2({ 
	sectionTitle,
	name,
	title,
	unique,
	count,
	distinctCount,
	session,
	data,
	orderBy,
	handleFilter,
	handleFilterHasValuesPage,
	step,
	orderByDesc,
	handleSort,
	reportType
}) {
	const tooltipID = uuidv4();
	const isClickable = (elem) => {
		return reportType === 'Page' && elem.HasValues && step !== 4 ;
	}; 
	function handleFilterValue(elem){
		if(elem.HasValues && reportType === 'Interaction'){
			return ()=> handleFilter(elem['Level1'], 'interaction', elem.HasValues);
		}else if(
			elem.HasValues && reportType === 'Page'
		){
			return ()=> handleFilterHasValuesPage(elem['Level2'], 'interaction', elem.HasValues);
		}
	}
	return (
		<>
			{sectionTitle &&
				<div className={styles.sectionHeader}>
					<div className={styles.sectionTitle}>{sectionTitle}</div>
				</div>
			}
			<div className="analytics_table_wrapper">
				<div className="table_grid s table_grid_analytics table_grid_analytics_interaction_1">
					<ReactTooltip backgroundColor='black' effect='solid' id={tooltipID} />
					<div className="table_row table_head_row">
						<div className="table_col">
							<a className="table_sort"
								onClick={() => {
									orderBy === 'Level2' && orderByDesc === 'desc' ? handleSort('Level2', 'asc') :
										handleSort('Level2', 'desc');
								}}
							>
								{orderBy === 'Level2' && orderByDesc !== 'desc' &&
									<i className='fas fa-arrow-up'></i>}
								{orderBy === 'Level2' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
								{name}
							</a>
						</div>
						{/* <div className="table_col">
							<a className="table_sort"
								onClick={() => {
									orderBy === 'Level1' && orderByDesc === 'desc' ? handleSort("Level1", 'asc') :
										handleSort("Level1", 'desc')
								}}
							>
								{orderBy === 'Level1' && orderByDesc !== 'desc' &&
									<i className='fas fa-arrow-up'></i>}
								{orderBy === 'Level1' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
								{title}
							</a>
						</div> */}
						<div className="table_col">
							<a className="table_sort"
								onClick={() => {
									orderBy === 'UniqueCount' && orderByDesc === 'desc' ? handleSort('UniqueCount', 'asc') :
										handleSort('UniqueCount', 'desc');
								}}
							>
								{orderBy === 'UniqueCount' && orderByDesc !== 'desc' &&
									<i className='fas fa-arrow-up'></i>}
								{orderBy === 'UniqueCount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
								{unique}
								<i className='fas fa-info-circle' 
									data-for={tooltipID}
									data-tip={'Number of interactions counted once by session'}></i>
							</a>
						</div>
						<div className="table_col">
							<a className="table_sort"
								onClick={() => {
									orderBy === 'Count' && orderByDesc === 'desc' ? handleSort('Count', 'asc') :
										handleSort('Count', 'desc');
								}}
							>
								{orderBy === 'Count' && orderByDesc !== 'desc' &&
									<i className='fas fa-arrow-up'></i>}
								{orderBy === 'Count' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
								{count}
								<i className='fas fa-info-circle' 
									data-for={tooltipID}
									data-tip={'Total number of interactions'}></i>
							</a>
						</div>
						<div className="table_col">

							<a className="table_sort"
								onClick={() => {
									orderBy === 'DistinctCount' && orderByDesc === 'desc' ? handleSort('DistinctCount', 'asc') :
										handleSort('DistinctCount', 'desc');
								}}
							>
								{orderBy === 'DistinctCount' && orderByDesc !== 'desc' &&
									<i className='fas fa-arrow-up'></i>}
								{orderBy === 'DistinctCount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
								{distinctCount}
								{reportType !== 'Page' && 
								<i className='fas fa-info-circle' 
									data-for={tooltipID}
									data-tip={'Number of distinct pages the interaction occured on'}></i>}
								{reportType === 'Page' && 
								<i className='fas fa-info-circle' 
									data-for={tooltipID}
									data-tip={' Number of distinct interactions on the page'}></i>}
							</a>
						</div>
						<div className="table_col">
							<a className="table_sort"
								onClick={() => {
									orderBy === 'SessionCount' && orderByDesc === 'desc' ? handleSort('SessionCount', 'asc') :
										handleSort('SessionCount', 'desc');
								}}
							>
								{orderBy === 'SessionCount' && orderByDesc !== 'desc' &&
									<i className='fas fa-arrow-up'></i>}
								{orderBy === 'SessionCount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
								{session}
								<i className='fas fa-info-circle' 
									data-for={tooltipID}
									data-tip={' Number of distinct sessions that triggered interactions'}></i>
							</a>
						</div>
						<div className="table_col"></div>
						
					</div>
					<div>
						{data && data.map((elem) => (
							<div  key={uuidv4()} className={'table_row table_body_row'}
								onClick={handleFilterValue(elem)}
								style={{cursor : isClickable(elem) ? 'pointer' : ''}}
							>
								<AnalitycsBoardItem
									data={elem['Level2']}
									isHandleClick={false}
								/>
								{/* <AnalitycsBoardItem
									data={elem["Level1"]}
									isHandleClick={false}
								/> */}
								<AnalitycsBoardItem
									data={elem['UniqueCount']}
									isHandleClick={false}
								/>
								<AnalitycsBoardItem
									data={elem['Count']}
									isHandleClick={false}
								/>
								<AnalitycsBoardItem
									data={elem['DistinctCount']}
									isHandleClick={false}
								/>
								<AnalitycsBoardItem
									data={elem['SessionCount']}
									isHandleClick={false}
								/>
								<div className="table_col">
									{isClickable(elem) &&<i className="fas fa-chevron-right c_turquoise"></i>}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
}
