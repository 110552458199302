import React from 'react'
import styles from './FieldRow.module.css'
export default function FieldRow({
    children,
    label = "",
    labelWidth = "m",
    labelAlign = "middle"
}) {

    const col_label_styles = [styles.col_label];
    col_label_styles.push(styles['width_'+labelWidth]);
    col_label_styles.push(styles['align_'+labelAlign]);

    return (
        <div className={styles.row}>
            <div className={col_label_styles.join(' ')}>
                <label className={styles.label}>{label}</label>
            </div>
            <div className={styles.col_field}>
                {children}
            </div>
        </div>
    )
}
