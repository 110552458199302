import React, {} from 'react';
import styles from './section.module.css';
import {ISection} from './types';

function Section({
	children,
	width = 'l',
	hasMargin = false
} : ISection): JSX.Element {

	const outerClass = [styles.outer];
	const innerClass = [styles.inner];
	const widthClass:any = {
		full: styles.inner_width_full,
		l: styles.inner_width_l,
		m: styles.inner_width_m,
		s: styles.inner_width_s,
		xs: styles.inner_width_xs,
	};

	if (width && width in widthClass) {
		innerClass.push(widthClass[width]);
	}

	if (hasMargin === true || hasMargin === 'horizontal') {
		outerClass.push(styles.outer_has_horizontal_margin);
	}

	if (hasMargin === true || hasMargin === 'vertical') {
		outerClass.push(styles.outer_has_vertical_margin);
	}

	return (
		<section className={outerClass.join(' ')}>
			<div className={innerClass.join(' ')}>
				{children}
			</div>
		</section>
	);
}

export {
	Section
};
