import React from 'react';
import { Skeleton } from '../../Components/Skeleton/Skeleton';
import kpiStyle from './kpi.module.css';
import {getFormattedNumber} from './Utils';

export function SumUp({
	item,
	size = 'm'
}) {

	const multiLineName = item?.name?.split('<br>');

	return (
		<div className={size == 'l' ? kpiStyle.sumup_item_large : kpiStyle.sumup_item}>
			{item.isLoading && 
				<Skeleton appearance="sumup" nb={1}/>
			}
			{!item.isLoading &&
				<>
					<div className={kpiStyle.sumup_value}>{item.value}</div>
					<div className={kpiStyle.sumup_label}>
						{multiLineName.map((line, i) =>
							<span key={i}>{line}</span>
						)}
					</div>
				</>
			}
		</div>
	);
}
export  function Percent({
	item,
	showEvolution = true
}) {
	// let isGreen;
	// if (item.inversedColor) {
	// 	isGreen = item.rate <= 0;
	// } else {
	// 	isGreen = item.rate >= 0;
	// }
	// const evoClass = isGreen ? kpiStyle.item_evolution_up : kpiStyle.item_evolution_down;

	const multiLineName = item?.name?.split('<br>');

	return (
		<>
			{item.isLoading && 
				<Skeleton appearance="kpi" nb={1} />
			}
			{!item.isLoading &&
				<>
					<div className={kpiStyle.item_title}>
						{multiLineName.map((line, i) =>
							<span key={i}>{line}</span>
						)}
					</div>
					<div className={kpiStyle.item_value}>
						{getFormattedNumber(item.value)}
						{item.unit && 
							<>&nbsp;{item.unit}</>
						}
					</div>
					{showEvolution &&
						<div>
							{item.rate >= 0 ?
								<span className={kpiStyle.item_evolution_up}>+&nbsp;{item.rate}&nbsp;%</span>
								:
								<span className={kpiStyle.item_evolution_down}>{item.rate}&nbsp;%</span>
							}
						</div>
					}
					{/* <div>
						<span className={evoClass}>
							{item.rate >= 0 && <>+&nbsp;</>}
							{item.rate}&nbsp;%
						</span>
					</div> */}
				</>
			}
		</>
	);
}
