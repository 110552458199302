import React, { useEffect, useState } from 'react';
import Btn from '../../../../Components/Btn';
import SearchBar from '../../../../Components/SearchBar';
import { onSiteContext } from '../context';
import EditImageCampaign from '../UI/EditImageCampaign';
import FilterListAction from '../List/FilterListAction';
import EditCampaignDelete from '../UI/EditCampaignDelete';
import EditCampaignPermissions from '../UI/EditCampaignPermissions';
import EditCampaignTestMode from '../UI/EditCampaignTestMode';
import EditLabelsCampaign from '../UI/EditLabelsCampaign';
import EditScenarioLabelsCampaign from '../UI/EditScenarioLabelsCampaign';
import EditLabels from '../../../Labels/EditLabels';
import StarterOnSite from '../UI/StarterOnSite';
import CustomGoalPanel from '../UI/CustomGoalPanel';
import Dropdown from '../../../../Components/Dropdown';
import FilterCalendarOnSite from '../Planning/FilterCalendarOnSite';
import LabelFilters from '../../../Labels/LabelFilters';
import LabelSelectDropdown from '../../../Labels/LabelSelectDropdown';
import ReportGeneratorModal from '../../../ReportGenerator/ReportGeneratorModal';
import { multiChoice, singleChoice } from '../context/Constants.js';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import RouterWrapper from '../RouterWrapper';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';
import { Article } from '../../../../Components/Article/Article';
import SectionMessage from '../../../../Components/SectionMessage';
import { Flag } from '../../../../Components/Flag/Flag';
import Fieldset from '../../../../Components/Fieldset.js';
import SelectorDevices from './SelectorDevices';
import { devicesNav } from './SelectorDevicesModel';

const OnSiteLayout = ({ children }) => {
	const {
		accountId,
		isAdmin,
		accountIsLoading,
		$rootScope,
		checkAccountData,
		modalPermissionsIsOppen,
		modalTestModeIsOpen,
		modalLabelsCampaignisOpen,
		modalScenarioLabelsCampaignisOpen,
		modalCampaignToDeleteIsOpen,
		modalCampaignUploadImageisOpen,
		modalLabelsAccountisOpen,
		setmodalLabelsAccountisOpen,
		$routeParams,
		openInspirations,
		canCreateCampaigns,
		canEditCampaigns,
		canCreatePalCampaigns,
		canCreateCodePatchCampaigns,
		canCreatePatchCampaigns,
		canCreateClassicCampaigns,
		canCreateSaaSCampaigns,
		canCreateExternalAppCampaigns,
		canCreateInAppCampaigns,
		canCreateABtest,
		devMode,
		labelServices,
		filterStatusMode,
		setFilterStatusMode,
		filterTestMode,
		setFilterTestMode,
		LabelsAccount,
		setLabelsAccount,
		filterLabel,
		setfilterLabel,
		filterLabelId,
		setfilterLabelId,
		statusArray,
		setstatusArray,
		searchValue,
		setSearchValue,
		dateRange,
		setdateRange,
		setDefaultView,
		dimension,
		setDevMode,
		AuthServices,
		$http,
		systemServices,
		campaignCreationVersion,
		externalAppSetup,
		devices,
		resetSource,
		handleChangeDeviceSelected,
		handleChangeDeviceValidated
	} = onSiteContext();

	const [createDropdownIsOpen, setCreateDropdownIsOpen] = useState(false);
	const [goalDropdownIsOpen, setGoalDropdownIsOpen] = useState(false);
	const [modalReportingIsOpen, setModalReportingIsOpen] = useState(false);
	const [customGoalPanelIsOpen, setCustomGoalPanelIsOpen] = useState(false);
	const [customGoalType, setCustomGoalType] = useState();
	const [dateOfTheSingleDatePicker, setDateOfTheSingleDatePicker] = useState();

	const [t] = useTranslation(['common', 'goals']);

	useEffect(() => {
		const filterStatusStorage = JSON.parse(
			sessionStorage.getItem('onSiteStatusFilter')
		);
		if (filterStatusStorage) {
			setstatusArray(filterStatusStorage);
		}

		const filterTestStorage = sessionStorage.getItem('onSiteTestFilter');
		if (filterTestStorage) {
			setFilterTestMode(filterTestStorage === 'true');
		}

		const filterSearchStorage = JSON.parse(
			sessionStorage.getItem('onSiteSearchFilter')
		);
		if (filterSearchStorage) {
			setSearchValue(filterSearchStorage);
		}
		getLabels();

		let devModeStorage = localStorage.getItem('campaign_list_devMode');
		if (devModeStorage) {
			setDevMode(true);
		}
	}, []);

	useEffect(() => {
		sessionStorage.setItem('dashboardOnSiteType', JSON.stringify(dimension || 'dashboard'));
	}, [dimension]);

	const getLabels = () => {
		labelServices.getLabels(
			accountId,
			'campaign',
			(data) => {
				const filterLabelsStorage = JSON.parse(
					sessionStorage.getItem('onSitelabelsFilter')
				);
				if (filterLabelsStorage) {
					setLabelsAccount(data);
					let filterLabelSelected = [];
					let filterLabelSelectedByIds = [];
					for (let i = 0; i < data.length; i++) {
						if (filterLabelsStorage.includes(data[i].id)) {
							filterLabelSelected.push(data[i]);
							filterLabelSelectedByIds.push(data[i].id);
						}
					}
					setfilterLabel(filterLabelSelected);
					setfilterLabelId(filterLabelSelectedByIds);
				} else {
					setLabelsAccount(data);
					setfilterLabel([]);
					setfilterLabelId([]);
				}
			},
			(err) => {
				console.log('err', err);
				systemServices.showError(t('labels.Error'));
			}
		);
	};

	const toggleFilterLabels = (e) => {
		if (filterLabelId.includes(e.id)) {
			setfilterLabel([...filterLabel.filter((el) => el.id !== e.id)]);
			setfilterLabelId([...filterLabelId.filter((el) => el !== e.id)]);
			sessionStorage.setItem(
				'onSitelabelsFilter',
				JSON.stringify([...filterLabelId.filter((el) => el !== e.id)])
			);
		} else {
			setfilterLabel([...filterLabel, e]);
			setfilterLabelId([...filterLabelId, e.id]);
			sessionStorage.setItem(
				'onSitelabelsFilter',
				JSON.stringify([...filterLabelId, e.id])
			);
		}
	};

	const resetFilterLabels = () => {
		setfilterLabel([]);
		setfilterLabelId([]);
		sessionStorage.removeItem('onSitelabelsFilter');
	};

	const toggleDevMode = (ev) => {
		const newMode = !devMode;
		setDevMode(newMode);
		if (newMode) {
			localStorage.setItem('campaign_list_devMode', newMode);
		} else {
			localStorage.removeItem('campaign_list_devMode');
		}
	};

	// planning date filter

	useEffect(() => {
		const pickerStorage = JSON.parse(
			sessionStorage.getItem('onSitePlanningPicker')
		);
		if (pickerStorage) {
			checkTheDayOfWeek(moment(pickerStorage).startOf('day'));
			setDateOfTheSingleDatePicker(moment(pickerStorage).startOf('day'));
		} else {
			checkTheDayOfWeek(moment().startOf('day'));
			setDateOfTheSingleDatePicker(moment().startOf('day'));
			const newPicker = moment().startOf('day');
			sessionStorage.setItem('onSitePlanningPicker', JSON.stringify(newPicker));
		}
	}, []);

	useEffect(() => {
		if (dateOfTheSingleDatePicker) {
			const pickerStorage = dateOfTheSingleDatePicker;
			sessionStorage.setItem(
				'onSitePlanningPicker',
				JSON.stringify(pickerStorage)
			);
		}
	}, [dateOfTheSingleDatePicker]);
	const checkTheDayOfWeek = (dateToCheck, operation) => {
		let firstDateOfCalendar = dateToCheck;
		let dayWeek = firstDateOfCalendar.weekday();
		if (!operation) {
			if (dayWeek >= 1) {
				firstDateOfCalendar = firstDateOfCalendar.weekday(0);
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			} else {
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			}
		}
		if (operation === 'today') {
			if (dayWeek >= 1) {
				firstDateOfCalendar = firstDateOfCalendar.weekday(0);
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
				setDateOfTheSingleDatePicker(moment().startOf('day'));
			} else {
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
				setDateOfTheSingleDatePicker(moment().startOf('day'));
			}
		}
		if (operation === 'after') {
			firstDateOfCalendar = firstDateOfCalendar.add(1, 'week');
			setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			setDateOfTheSingleDatePicker(firstDateOfCalendar.startOf('day'));
		}
		if (operation === 'before') {
			firstDateOfCalendar = firstDateOfCalendar.subtract(1, 'week');
			setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			setDateOfTheSingleDatePicker(firstDateOfCalendar.startOf('day'));
		}
	};

	return (
		<>
			{modalPermissionsIsOppen && <EditCampaignPermissions />}
			{modalTestModeIsOpen && <EditCampaignTestMode />}
			{modalLabelsCampaignisOpen && <EditLabelsCampaign labelService={labelServices} />}
			{modalScenarioLabelsCampaignisOpen && <EditScenarioLabelsCampaign labelService={labelServices} />}
			{modalCampaignToDeleteIsOpen && <EditCampaignDelete />}
			{modalCampaignUploadImageisOpen && <EditImageCampaign />}
			{modalLabelsAccountisOpen && <EditLabels
				accountId={accountId}
				modalLabelsAccountisOppen={modalLabelsAccountisOpen}
				setmodalLabelsAccountisOppen={setmodalLabelsAccountisOpen}
				systemServices={systemServices}
				labelService={labelServices}
				onClose={getLabels}
				objectType={'campaign'}
			/>

			}
			{modalReportingIsOpen && (
				<ReportGeneratorModal
					onClose={() => setModalReportingIsOpen(false)}
					isOpen={modalReportingIsOpen}
					accountId={accountId}
					AuthServices={AuthServices}
					$http={$http}
					systemServices={systemServices}
					$routeParams={$routeParams}
					$rootScope={$rootScope}
				></ReportGeneratorModal>
			)}

			<RouterWrapper props={{$routeParams: $routeParams, $rootScope: $rootScope}}>
				<StarterOnSite />
				{!accountIsLoading && (
					<>
						{checkAccountData && (
							<OnSitePageWrapper dimension={dimension}>
								<section className="section no_bottom_pad section_primary">
									<div className="flex">
										<div className="flex_item_full h1">
											{dimension == 'planning' ?
												<>Planning</>
												:
												<>Campaigns</>
											}
										</div>
										<div className="flex_item_fix ml_30">
											<span className="btn_group_l">
												<Dropdown
													isOpen={goalDropdownIsOpen}
													toggle={(s) => setGoalDropdownIsOpen(s)}
													button={
														<Btn
															message={t('nav.trackGoals')}
															style="outline"
															color="secondary"
															arrow={true}
														/>
													}
													maxWidth={440}
												>
													<Listbox>
														<ListboxItem
															size="xl"
															message={<span className="listbox_item_title">{t('nav.customGoal')}</span>}
															description={t('nav.customGoalDescription')}
															iconCustom={<span className="icon_32 icon_goal icon_color_turquoise"></span>}
															onClick={() => {setCustomGoalType('goal'); setCustomGoalPanelIsOpen(true); setGoalDropdownIsOpen(false);}}
														/>
														<li className='hr' />
														<ListboxItem
															size="xl"
															message={<span className="listbox_item_title">{t('nav.transactionGoal')}</span>}
															description={t('nav.transactionGoalDescription')}
															iconCustom={<span className="icon_32 icon_transaction icon_color_turquoise"></span>}
															onClick={() => {setCustomGoalType('transactionGoal'); setCustomGoalPanelIsOpen(true); setGoalDropdownIsOpen(false);}}
														/>
													</Listbox>
												</Dropdown>
												<CustomGoalPanel
													isOpen={customGoalPanelIsOpen}
													onClose={() => setCustomGoalPanelIsOpen(false)}
													goalType={customGoalType}
												/>
												{isAdmin &&
													<Btn
														style="outline"
														color="secondary"
														onClick={() => { setModalReportingIsOpen(true); }}
													>
														<span className='flex flex_align_center'>
															<span>{t('nav.report')}</span>
															<span style={{marginTop: '-2px', marginBottom: '-2px'}}>
																<Flag
																	icon="ai"
																	text="AI"
																	tooltip="This feature is powered by BEYABLE AI"
																	className="ml_8"
																	hasBorder={true}
																/>
															</span>
														</span>
													</Btn>
												}
												{campaignCreationVersion === multiChoice && (
													<Dropdown
														isOpen={createDropdownIsOpen}
														setIsOpen={setCreateDropdownIsOpen}
														onHide={(ev) => setCreateDropdownIsOpen(false)}
														button={
															<Btn
																message={t('nav.newCampaign')}
																arrow={true}
																className="btn_w_l"
															/>
														}
													>
														<ul className="listbox">
															{(isAdmin || canCreateSaaSCampaigns) && (
																<li>
																	<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor'}>
																		<span className="flex">
																			<span className="flex_item_fix listbox_item_icon">
																				<span className="icon_32 icon_layout icon_color_turquoise"></span>
																			</span>
																			<span className="flex_item_full">
																				<span className="listbox_item_title">
																					{t('nav.CustomTemplate')}
																				</span>
																				<span className="listbox_item_desc">{t('nav.useCustomTemplate')}</span>
																			</span>
																		</span>
																	</a>
																</li>
															)}
															<li className="hr"></li>
															{(isAdmin || canCreatePalCampaigns) && (
																<>
																	<li>
																		<a className="listbox_item xl flex" onClick={(ev) => { setCreateDropdownIsOpen(false); openInspirations(); }}>
																			<span className="flex">
																				<span className="flex_item_fix listbox_item_icon">
																					<span className="icon_32 icon_idea icon_color_turquoise"></span>
																				</span>
																				<span className="flex_item_full">
																					<span className="listbox_item_title">{t('nav.inspiration library')}</span>
																					<span className="listbox_item_desc">
																						{t('nav.Browse our library of ready-to-use templates')}
																					</span>
																				</span>
																			</span>
																		</a>
																	</li>
																</>
															)}
															<li className="hr"></li>
															{(isAdmin || canCreatePatchCampaigns) && (
																<li>
																	<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&graph` : '/Campaigns/Editor?graph'}>
																		<span className="flex">
																			<span className="flex_item_fix listbox_item_icon">
																				<span className="icon_32 icon_color icon_color_turquoise"></span>
																			</span>
																			<span className="flex_item_full">
																				<span className="listbox_item_title">
																					{t('nav.graphicPatch')}
																				</span>
																				<span className="listbox_item_desc">{t('nav.useGraphic')}</span>	
																			</span>
																		</span>
																	</a>
																</li>
															)}
															<li className="hr"></li>
															{(isAdmin || canCreateCodePatchCampaigns) && (
																<li>
																	<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&patch` : '/Campaigns/Editor?patch'}>
																		<span className="flex">
																			<span className="flex_item_fix listbox_item_icon">
																				<span className="icon_32 icon_code icon_color_turquoise"></span>
																			</span>
																			<span className="flex_item_full">
																				<span className="listbox_item_title">
																					{t('nav.codePatch')}
																				</span>
																				<span className="listbox_item_desc">
																					{t('nav.patchYourWeb')}
																				</span>
																			</span>
																		</span>
																	</a>
																</li>
															)}
															<li className="hr"></li>
															{(isAdmin) && (
															// {(isAdmin || canCreateABtest) && (
																<li>
																	<a className="listbox_item xl flex" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&abtest` : '/Campaigns/Editor?abtest'}>
																		<span className="flex">
																			<span className="flex_item_fix listbox_item_icon">
																				<span className="icon_32 icon_abtest icon_color_turquoise"></span>
																			</span>
																			<span className="flex_item_full">
																				<span className="listbox_item_title">
																					{t('staterOnSite.abTest')}
																				</span>
																				<span className="listbox_item_desc">
																					{t('staterOnSite.makeAbTests')}
																				</span>
																			</span>
																		</span>
																	</a>
																</li>
															)}
															<li className="hr"></li>
															{/* Remove false to show Shopify link for admins or users with multiple permissons */}
															{(canCreateExternalAppCampaigns && false) && (
																<li>
																	<Link className="listbox_item xl flex"
																		to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&module=shopify001` : '/Campaigns/Editor?module=shopify001'}>
																		<span className="flex">
																			<span className="flex_item_fix listbox_item_icon">
																				<span className="icon_32 icon_shopify icon_color_turquoise"></span>
																			</span>
																			<span className="flex_item_full">
																				<span className="listbox_item_title">
																					{t('nav.ShopifyCampaign')}
																				</span>
																			</span>
																		</span>
																	</Link>
																</li>
															)}
															<li className="hr"></li>
															{(canCreateInAppCampaigns) && (
																<li>
																	<Link className="listbox_item xl flex"
																		to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&appNative` : '/Campaigns/Editor?appNative'}>
																		<span className="flex">
																			<span className="flex_item_fix listbox_item_icon">
																				<span className="icon_32 icon_mobile_app icon_color_turquoise"></span>
																			</span>
																			<span className="flex_item_full">
																				<span className="listbox_item_title">
																					{t('nav.inAppCampaign')}
																				</span>
																				<span className="listbox_item_desc">
																					{t('nav.inAppCampaignDescription')}
																				</span>
																			</span>
																		</span>
																	</Link>
																</li>
															)}
															<li className="hr"></li>
															{(isAdmin || canCreateClassicCampaigns) && (
																<li>
																	<a className="listbox_item xl" href={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Create?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Create'}>
																		<span className="flex">
																			<span className="flex_item_fix listbox_item_icon listbox_item_icon_32">
																				<span className="icon_20 icon_idea icon_color_grey"></span>
																			</span>
																			<span className="flex_item_full grey_2">
																				{t('nav.classicCampaign')}
																			</span>
																		</span>
																	</a>
																</li>
															)}
														</ul>
													</Dropdown>
												)}
												{campaignCreationVersion === singleChoice &&
													<>
														{canCreatePalCampaigns &&
															<Btn
																onClick={(ev) => openInspirations()}
															>{t('nav.newCampaign')}</Btn>
														}
														{canCreateSaaSCampaigns &&
															<Link className="btnUi btn_primary btn_l btn_fs_l"
																to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}` : '/Campaigns/Editor'}>
																<div><i className="fa fa-plus"></i><span className="btn_txt">{t('nav.newCampaign')}</span></div>
															</Link>
														}
														{canCreateExternalAppCampaigns &&
															<Link className="btnUi btn_primary btn_l btn_fs_l"
																to={isAdmin && $routeParams && $routeParams.ka ? `/Campaigns/Editor?ka=${$routeParams.ka}&ku=${$routeParams.ku}&module=shopify001` : '/Campaigns/Editor?module=shopify001'}>
																<div><i className="fa fa-plus"></i><span className="btn_txt">{t('nav.newCampaign')}</span></div>
															</Link>
														}
													</>
												}
											</span>
										</div>
									</div>
								</section>
								<section className="section">
									<Article size="l">

										<div className='flex mb_20'>
											<div className='flex_item_full'>
												<div className='btn_group_l'>
													<SearchBar
														onChange={(value) => {
															setSearchValue(value);
														}}
														valueInSearchBar={searchValue}
														placeholder={t('search.searchCampaign')}
														fullWidth={false}
														size="l"
														width="l"
													/>
													{canCreateInAppCampaigns &&
														<SelectorDevices
															resetSource={resetSource}
															deviceSelected={devices || []}
															initialValue={devicesNav}
															handleChangeDeviceValidated={handleChangeDeviceValidated}
															handleChangeDeviceSelected={handleChangeDeviceSelected}
														/>
													}
													<LabelSelectDropdown
														selectableLabels={LabelsAccount || []}
														selectedLabelIds={filterLabelId || []}
														onLabelSelected={l => toggleFilterLabels(l)}
														resetFilterLabels={resetFilterLabels}
														canEdit={(isAdmin || canEditCampaigns)}
														onEditRequested={() => setmodalLabelsAccountisOpen(true)}
														showEditLabels={true}
													/>
												</div>
											</div>
											<div className='flex_item_fix ml_20'>
												{dimension !== 'planning' && (
													<Btn
														icon="fas fa-code"
														style="outline"
														color="secondary"
														size="xs"
														className={devMode ? 'active mr_10' : 'mr_10'}
														onClick={(e) => toggleDevMode()}
														tooltip={devMode ? 'Hide campaign IDs' : 'Show campign IDs'}
													/>
												)}
												<FilterListAction
													filterStatusMode={filterStatusMode}
													setFilterStatusMode={(e) => setFilterStatusMode(e)}
													filterTestMode={filterTestMode}
													setFilterTestMode={(e) => setFilterTestMode(e)}
													statusArray={statusArray}
													setstatusArray={(e) => setstatusArray(e)}
												/>
											</div>
										</div>
										
										{externalAppSetup?.installationStatus == 'Uninstalled' &&  
											<section className='section no_bottom_pad'>
												<SectionMessage
													type="alert"
													title="The BEYABLE subscription is canceled"
													text="To continue using BEYABLE, please subscribe to a plan from your Prestashop back office."
												/>
											</section>
										}
										{dimension === 'planning' && (
											<section className="mt_10">
												{dateRange && (
													<>
														<FilterCalendarOnSite
															setDefaultView={(e) => setDefaultView(e)}
															dateOfTheSingleDatePicker={
																dateOfTheSingleDatePicker
															}
															setDateOfTheSingleDatePicker={(e) =>
																setDateOfTheSingleDatePicker(e)
															}
															dateRange={dateRange}
															setdateRange={(e) => setdateRange(e)}
															checkTheDayOfWeek={(e, op) =>
																checkTheDayOfWeek(e, op)
															}
														/>
													</>
												)}
											</section>
										)}
										{children}
									</Article>
								</section>
							</OnSitePageWrapper>
						)}
					</>
				)}
			</RouterWrapper>

			{accountIsLoading && (
				<SpinnerWheel wheelSize="l" />
			)}
		</>
	);
};

export default OnSiteLayout;

const OnSitePageWrapper = ({ dimension, children }) => {
	if (dimension == 'planning') return (
		<div className="page_full section_root flex flex_v">
			{children}
		</div>
	);

	return (
		<div className='page_full_inner'>
			{children}
		</div>
	);
};
