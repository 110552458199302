const triggerListOption = [
	{
		label: 'contains exactly',
		value: 'CONTAINS'
	},
	{
		label: 'starts with',
		value: 'SW'
	},
	{
		label: 'ends with',
		value: 'EW'
	},
	{
		label: 'does not contain exactly',
		value: 'NOTCONTAINS'
	},
	{
		label: 'is equal to',
		value: 'EQUAL'
	}
];

export default triggerListOption;