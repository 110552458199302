import React, { useState, useCallback, memo} from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';

function AbTestDevicesFilter({
	devicesSelected,
	handleChangeDevicesSelected,
	initialValue,
	resetDevices
}) {
	const [t, i18n] = useTranslation(['common']);
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [btnClass, setbtnClass] = useState(['custom_input', 'clickable', 'w_auto', 'm', 'bg_white']);
	const [isActive, setisActive] = useState(devicesSelected.length > 0 ? true : false);
	
	const getLabel  = useCallback(() =>{
		if(!devicesSelected){
			return;
		}
		if(devicesSelected.length === 0){
			return 'All';
		}
		if(devicesSelected.length > 1){
			return devicesSelected[0] + ' +' + (devicesSelected.length -1);
		}
		if(devicesSelected.length === 1){
			return devicesSelected[0] ;
		}

	},[devicesSelected]);

	useCallback(()=>{
		setisActive(devicesSelected.length > 0 ? true : false);
	},[devicesSelected]);
	useCallback(()=>{
		if (isActive) {
			setbtnClass(classeElement => ([
				...classeElement, 'highlighted'
			]));
		}else{
			setbtnClass(classeElement => ([
				...classeElement.filter(el => el !== 'highlighted')
			]));
		}
	}, [isActive]);
	const button = (
		<button className={btnClass.join(' ')}>
			<span className="custom_input_value has_label">
				<span className="custom_input_value_label">Devices</span>
				{devicesSelected ?
					<>{getLabel()}</>
					:
					<span className="custom_input_placeholder">{t('common.none')}</span>
				}
			</span>
			{devicesSelected && devicesSelected.length > 0 && 
				<span className="custom_input_reset"
					onMouseDown={(e) => {e.stopPropagation();}}
					onClick={(e) => {e.stopPropagation(); resetDevices();}}>
					<i className="fas fa-times-circle"></i>
				</span>
			}
			<span className="custom_input_arrow"></span>
		</button>
	);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
			minWidth={180}
		>
			<Listbox>
				<ListboxItem
					onClick={() => {resetDevices(); setDropdownIsOpen(false);}}
					message={<>All <span className='grey_3 s_13'>(no filter)</span></>}
					selected={!isActive}
					hasSelectedIcon={true}
				/>
				<li className='hr' />
				{initialValue.map((value) => 
					<ListboxItem
						key={value}
						onClick={() => handleChangeDevicesSelected(value)}
						message={value}
						selected={devicesSelected.includes(value)}
						hasCheckbox={true}
					/>
				)}
			</Listbox>
		</Dropdown>
	);
}
const AbTestDevicesFilters = memo(AbTestDevicesFilter);
export default AbTestDevicesFilters;