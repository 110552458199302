import React, { useState, useEffect } from 'react';
import Btn from '../../../../Components/Btn';
import Modal from '../../../../Components/Modal';
import ModalBody from '../../../../Components/ModalBody';
import ModalFooter from '../../../../Components/ModalFooter';
import SearchBar from '../../../../Components/SearchBar';
import Link from '../../../../Components/Link';
import FilterSetup from './FilterSetup';
import ModalFiltersList from './ModalFiltersList';
import { v4 as uuidv4 } from 'uuid';
import OperatorBetweenFilters from './OperatorBetweenFilters';
import FilterExpressionTabulation from './FilterExpressionTabulation';
import {useFiltersContext} from './contextFilters';
export default function ModalFilters({
	ListFilterAvailable,
	onAddFilter,
	onEditFilter,
	tenantSelected,
	previewTenant,
	accountId,
	needTenantForSearch,
	setupAccountServices,
	filterList
}) {
	const {
		modalIsOppen,
		isNewFilterFromScratch,
		userFiltersWithId,
		elementsFilterListAvailableSelected,
		editFilterId,
		filterSelectedExpression,
		handleIsEditFilterCard,
		handleElementsFilterListAvailableSelected,
		onDeleteFilterById,
		upDateFilters,
		handleChangeOperatorBetweenFilters,
		setEditFilterId,
		setModalIsOppen,
		setisNewFilterFromScratch,
		setTabFilterSelected,
		tabFilterSelected,
		urlIsInvalid,
		validUrl,
		setUrlIsInvalid
	} = useFiltersContext();

	const [listToRender, setlistToRender] = useState([]);
	const [noData, setnoData] = useState(false);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [modaleFilterError, setModalFilterError] = useState(false);
	const [modaleFilterParamsError, setModalFilterParamsError] =
		useState(false);
	const [operatorMultipleValue, setoperatorMultipleValue] = useState('And');
	const [hasNoFilters, sethasNoFilters] = useState(true);
	
	const [expressionSelectMod, setExpressionSelectMod] = useState(false);
	useEffect(() => {
		if (isNewFilterFromScratch && ListFilterAvailable) {
			addFiltering(ListFilterAvailable.forFiltering[0].itemList[0]);
		}
	}, [isNewFilterFromScratch]);
	useEffect(() => {
		if (ListFilterAvailable) {
			setlistToRender(ListFilterAvailable.forFiltering);
		}
	}, [ListFilterAvailable]);

	useEffect(() => {
		if (
			elementsFilterListAvailableSelected.length > 1 &&
			filterSelectedExpression
		) {
			sethasNoFilters(false);
			setoperatorMultipleValue(
				filterSelectedExpression && filterSelectedExpression.op
			);
		} else if (elementsFilterListAvailableSelected.length > 1) {
			sethasNoFilters(false);
		} else if (
			elementsFilterListAvailableSelected &&
			elementsFilterListAvailableSelected.length === 1
		) {
			setTabFilterSelected(elementsFilterListAvailableSelected[0]);
			setExpressionSelectMod(false);
			sethasNoFilters(false);
		} else {
			sethasNoFilters(true);
		}
	}, [elementsFilterListAvailableSelected, filterSelectedExpression]);

	function handleInputErrorsInFilters() {

		if (
			!elementsFilterListAvailableSelected ||
			elementsFilterListAvailableSelected.length === 0
		)
			return handleModalFilterError(true);
		// check if params are not empty

		let arrayOfValueParamsIsMissing =
			elementsFilterListAvailableSelected.map((filter) => {
				if (!filter) {
					return false;
				}
				const result = filter.params;
				const isEmpty = (obj) => {
					return Object.keys(obj).length === 0;
				};
				if (!result || result.length === 0 || isEmpty(result[0]))
					return false;

				const isValide = (arrayOfValueIsMissing = result.map((arr) => {
					if (!arr) {
						return true;
					}
					const object = arr.value;


					if (!object || isEmpty(object)) {
						return handleModalFilterParamsError(true);
					}
					// warning in edition mode every value no used return null
					for (const key in object) {
						if (Object.hasOwnProperty.call(object, key)) {
							const filterValue = object[key];
							if (
								!filterValue ||
								filterValue.length === 0 ||
								filterValue === {}
							) {
								return true;
							}
							return false;
						} else {
							return true;
						}
					}
				}));
				return isValide[0];
			});
		// check if one of the params has return true and disable Btn

		const checkParamsArray = arrayOfValueParamsIsMissing.find(
			(value) => value === true
		);

		if (checkParamsArray) {
			handleModalFilterParamsError(true);
		} else {
			handleModalFilterParamsError(false);
		}

		// return array on each filter and true if value is missing

		let arrayOfValueIsMissing = elementsFilterListAvailableSelected.map(
			(arr) => {
				if (!arr) {
					return true;
				}

				if (arr.op !== 'Flop' || arr.op !== 'Top') {
					return false;
				}
				let object = arr.value;
				const isEmpty = (obj) => {
					return Object.keys(obj).length === 0;
				};
				if (!object || isEmpty(object)) {
					return true;
				}
				// warning in edition mode every value no used return null
				for (const key in object) {
					if (Object.hasOwnProperty.call(object, key)) {

						const filterValue = object[key];

						if (
							key === 'numberValues' &&
							filterValue && filterValue.length === 0
						) {
							return true;
						}
						if (
							key === 'booleanValue' &&
							typeof filterValue === 'boolean'
						) {
							return false;
						}
						if (key === 'numberRange') {
							if (!object[key].min && !object[key].max) {
								return true;
							}
						}
						if (key === 'stringValue' || key === 'stringValues') {
							if (filterValue !== null && filterValue.length === 0) {
								return true;
							}
						}
						if (
							filterValue === undefined ||
							// filterValue.length === 0 ||
							filterValue === {}
						) {
							return true;
						}
						return false;
					} else {

						return true;
					}
				}
			}
		);

		// check if one of the filter has return true and disable Btn
		const checkFilterArray = arrayOfValueIsMissing.find(

			(value) => value === true
		);

		if (checkFilterArray) {
			handleModalFilterError(true);
		} else {
			handleModalFilterError(false);
		}
	}

	// handle input error modal
	useEffect(() => {
		handleInputErrorsInFilters();
	}, [elementsFilterListAvailableSelected]);
	useEffect(() => {
		if (expressionSelectMod)
			addFiltering(ListFilterAvailable.forFiltering[0].itemList[0]);
		setExpressionSelectMod(false);
	}, [expressionSelectMod]);
	useEffect(() => {
		if(tabFilterSelected){
			if(tabFilterSelected.id === '50593d1f-20e2-43be-b1d6-5496e77e35dc' ){
				const value = tabFilterSelected.value.hasOwnProperty('stringValues') ? tabFilterSelected.value.stringValues[0] : null;
				if(value){
					validUrl(value);
				}else{
					setUrlIsInvalid(false);
				}
			}else{
				setUrlIsInvalid(true);
			}
		}
	}, [tabFilterSelected]);
	const handleTabFilterSelected = (filter) => {
		setTabFilterSelected(filter);
	};
	const handleModalFilterError = (bool) => {
		setModalFilterError(bool);
	};
	const handleModalFilterParamsError = (bool) => {
		setModalFilterParamsError(bool);
	};
	const handleChange = (event) => {
		event.persist();
		const value = event.target.value;
		const str = value.trim();
		setValueInSearchBar(value);
		search(ListFilterAvailable.forFiltering, str);
	};

	const search = (table, word) => {
		const arrayOfFiltersReduce = table.reduce((acc, curr) => {
			let resultName = curr.itemList.filter(line =>
				line.name !== null && line.name.toUpperCase().indexOf(word.toUpperCase()) > -1

			);
			if (resultName.length > 0) {
				let finalArray = {
					name: curr.name,
					itemList: [...new Set(resultName)]
				};
				return acc = [...acc, finalArray];

			} else {
				return acc;
			}
		}, []);

		if (word.length >= 0) {
			if (arrayOfFiltersReduce.length === 0) {
				setnoData(true);
				setlistToRender([]);
			} else {
				setnoData(false);
				setlistToRender(arrayOfFiltersReduce);
			}
		} else {
			setnoData(false);
			setlistToRender([...ListFilterAvailable['forFiltering']]);
		}
	};

	function addFiltering(elem) {
		// update elementListFilterAvailable with new selection mode
		const copyElementParams = JSON.parse(JSON.stringify(elem.params));
		function getNewValuesParams (){
			if(copyElementParams){
				const copyOfParameters = copyElementParams.map(x=> {
					if (x.hasOwnProperty('value')) {
						Object.keys(x.value).forEach(key => {
							if (key.endsWith('Values') && Array.isArray(x.value[key])) {
								x.value[key] = [];
							}
						});
					}
					return x;

				});
				return copyOfParameters;
			}else return null;

		}
		const params =getNewValuesParams();

		const newFilter = {
			...elem,
			collationId: elem.id,
			value: {},
			op: null,
			idFilter: uuidv4(),
			period: null,
			params: params ? params : null

		};
		// replace active element in list of filter because of selectMod disable
		if (
			!expressionSelectMod &&
			tabFilterSelected &&
			elementsFilterListAvailableSelected.length > 1
		) {
			let newList = [...elementsFilterListAvailableSelected];
			let indexToUpdate = elementsFilterListAvailableSelected.findIndex(
				(elem) => elem.idFilter === tabFilterSelected.idFilter
			);
			newList[indexToUpdate] = newFilter;
			setTabFilterSelected(newFilter);
			handleElementsFilterListAvailableSelected(newList);
		}
		// init fist filter selected
		else if (
			!elementsFilterListAvailableSelected ||
			!elementsFilterListAvailableSelected[0] ||
			(!expressionSelectMod &&
				elementsFilterListAvailableSelected.length === 1)
		) {
			setTabFilterSelected(newFilter);
			handleElementsFilterListAvailableSelected([newFilter]);
		}
		// update list and add element with selectMod active
		else if (
			expressionSelectMod &&
			elementsFilterListAvailableSelected.length > 0
		) {
			handleElementsFilterListAvailableSelected((current) => [
				...current,
				newFilter,
			]);
			setTabFilterSelected(newFilter);
		}
	}
	function onDeleteFilter(elementWithId) {
		onDeleteFilterById(elementWithId);
		let newFilterList = elementsFilterListAvailableSelected.filter(
			(elem) => elem.idFilter !== elementWithId.idFilter
		);
		if (newFilterList) {
			setTabFilterSelected(newFilterList[newFilterList.length - 1]);
		}
	}
	function closeMod() {
		setModalIsOppen(false);
		setEditFilterId();
		handleElementsFilterListAvailableSelected([]);
		setTabFilterSelected();
		setisNewFilterFromScratch(false);
	}
	function replaceAt(array, index, value) {
		const ret = array.slice(0);
		ret[index] = value;
		return ret;
	}


	function handleChangeFilterByUniqueId(object) {
		if (elementsFilterListAvailableSelected && elementsFilterListAvailableSelected.length > 0) {

			const itemToManipulate = elementsFilterListAvailableSelected.find((elem) => elem.idFilter === object.idFilter);
			const indexOfItem = elementsFilterListAvailableSelected.indexOf(itemToManipulate);

			itemToManipulate.op = object.op;
			itemToManipulate.value = object.value;
			itemToManipulate.period = object.period;
			const finalArray = replaceAt(
				[...elementsFilterListAvailableSelected],
				indexOfItem,
				itemToManipulate
			);
			upDateFilters(finalArray);
		}
	}
	function handleChangeFilterParamsByUniqueId(idFilter, params) {
		if (elementsFilterListAvailableSelected && elementsFilterListAvailableSelected.length > 0) {

			const itemToManipulate = elementsFilterListAvailableSelected.find((elem) => elem.idFilter === idFilter);
			const indexOfItem = elementsFilterListAvailableSelected.indexOf(itemToManipulate);

			if (!itemToManipulate.params) {
				itemToManipulate.params = [{ name: params.name, value: params.value }];
			} else {
				let newParams = itemToManipulate.params.map((elem) => {
					if (elem.name === params.name) {
						elem.value = params.value;
					}
					return elem;
				});
				itemToManipulate.params = newParams;
			}
			const finalArray = replaceAt(
				[...elementsFilterListAvailableSelected],
				indexOfItem,
				itemToManipulate
			);
			upDateFilters(finalArray);
		}
	}

	const updateFilterRes = () => {
		if (modaleFilterError.isError) {
			return;
		}
		if (editFilterId) {
			onEditFilter(
				editFilterId,
				userFiltersWithId,
				elementsFilterListAvailableSelected,
				filterSelectedExpression
			);
		} else {
			onAddFilter(
				elementsFilterListAvailableSelected,
				filterSelectedExpression
			);
		}
		handleIsEditFilterCard(false);
		setEditFilterId();
		closeMod();
	};

	const handleActivateFilterExpression = () => {
		setExpressionSelectMod(true);
	};

	return (
		<Modal
			isOpen={modalIsOppen}
			width="860"
			height="700"
			onClose={(e) => {
				closeMod();
			}}
			noClose={true}
		>
			<div className="modal_section modal_section_s has_bottom_border flex_item_fix">
				<div className="flex">
					<div className="flex_item_full s_17">
						{hasNoFilters ? 'Add filter' : 'Edit filter'}
					</div>
					<div className="flex_item_fix">
						{elementsFilterListAvailableSelected.length <= 1 && <Link
							message="Transform to filter group"
							onClick={() => handleActivateFilterExpression()}
							className="s_13"
						/>}
					</div>
				</div>
			</div>

			{!hasNoFilters &&
				operatorMultipleValue &&
				elementsFilterListAvailableSelected.length > 1 && (
				<div className="modal_section modal_section_h has_bottom_border flex_item_fix">
					<div className="flex">
						<div className="flex_item_fix">
							<FilterExpressionTabulation
								elementsFilterListAvailableSelected={
									elementsFilterListAvailableSelected
								}
								tabFilterSelected={tabFilterSelected}
								handleTabFilterSelected={
									handleTabFilterSelected
								}
								expressionSelectMod={expressionSelectMod}
								setExpressionSelectMod={
									setExpressionSelectMod
								}
							/>
						</div>
						<div className="flex_item_fix">
							<Btn
								icon="fas fa-plus"
								className="tab_btn"
								onClick={() => {
									setExpressionSelectMod(true);
								}}
							/>
						</div>
						<div className="flex_item_full al_right">
							<OperatorBetweenFilters
								op={operatorMultipleValue}
								filters={
									elementsFilterListAvailableSelected
								}
								handleChangeOperator={
									handleChangeOperatorBetweenFilters
								}
							/>
						</div>
					</div>
				</div>
			)}

			<div className="flex flex_align_stretch flex_item_full overflow_hidden">
				<aside className="flex_item_fix collection_modal_sidebar flex flex_v">
					<div className="flex_item_fix modal_section">
						<SearchBar
							onChangeValue={(e) => handleChange(e)}
							valueInSearchBar={valueInSearchBar}
							placeholder="Search for an attribute or score"
							color="white"
						/>
					</div>
					<div className="flex_item_full vscroll_custom modal_section modal_section_bottom">
						<ModalFiltersList
							ListFilterAvailable={listToRender}
							addFiltering={addFiltering}
							tabFilterSelected={tabFilterSelected}
							noData={noData}
						/>
					</div>
				</aside>
				<div className="flex flex_v flex_item_full">
					<ModalBody>
						<FilterSetup
							tenantSelected={tenantSelected}
							previewTenant={previewTenant}
							needTenantForSearch={needTenantForSearch}
							setupAccountServices={setupAccountServices}
							accountId={accountId}
							isExpression={elementsFilterListAvailableSelected.length > 1}
							onDeleteFilter={onDeleteFilter}
							filterList={filterList}
							tabFilterSelected={tabFilterSelected}
							handleChangeFilterByUniqueId={
								handleChangeFilterByUniqueId
							}
							handleChangeFilterParamsByUniqueId={
								handleChangeFilterParamsByUniqueId
							}
							hasNoFilters={hasNoFilters}
							operatorMultipleValue={operatorMultipleValue}
							elementsFilterListAvailableSelected={elementsFilterListAvailableSelected}
							handleChangeOperatorBetweenFilters={handleChangeOperatorBetweenFilters}
						/>
					</ModalBody>
					<ModalFooter
						primaryAction={
							<Btn
								message="Ok"
								disabled={modaleFilterError || modaleFilterParamsError || !urlIsInvalid}
								onClick={() => updateFilterRes()}
							/>
						}
						secondaryAction={
							<Btn
								message="Cancel"
								style="ghost"
								color="secondary"
								onClick={() => closeMod()}
							/>
						}
					/>
				</div>
			</div>
		</Modal>
	);
}
