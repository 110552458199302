import { useState, useEffect } from 'react'

export function useSort() {
    const [data, setData] = useState()
    function sortPageTypeHook(a, b) {
        if (a && b) {
            if (a.pageType.toUpperCase() < b.pageType.toUpperCase()) return -1;
            if (a.pageType.toUpperCase() > b.pageType.toUpperCase()) return 1;
        }
        return 0;
    }
    function sortByHeightHook(a, b) {
        let a_ratio = a.metaData.size.width / a.metaData.size.height;
        let b_ratio = b.metaData.size.width / b.metaData.size.height;

        if (a_ratio == b_ratio) {
            if (a.metaData.size.height == b.metaData.size.height) {
                if (a.metaData.size.width == b.metaData.size.width) {
                    return a.bannerId < b.bannerId ? 1 : -1;
                }
                return a.metaData.size.width < b.metaData.size.width ? 1 : -1;
            }
            return a.metaData.size.height < b.metaData.size.height ? 1 : -1;
        }
        return a_ratio < b_ratio ? 1 : -1;
    }
    function sortByStartDate(a, b) {
        if (a && b) {
            if (a.operation.startDate < b.operation.startDate) return -1;
            if (a.operation.startDate > b.operation.startDate) return 1;
        }
        return 0;
    }
    function sortByEndDate(a, b) {
        if (a && b) {
            if (a.operation.endDate < b.operation.endDate) return -1;
            if (a.operation.endDate > b.operation.endDate) return 1;
        }
        return 0;
    }
    function sortOpeByDate(a, b) {
        if (a === undefined) return;
        if (b === undefined) return;

        if (a.operation.startDate < b.operation.startDate) return -1;
        if (a.operation.startDate > b.operation.startDate) return 1;

        if (a.operation.endDate < b.operation.endDate) return 1;
        if (a.operation.endDate > b.operation.endDate) return -1;

    }
    return {
        sortPageTypeHook,
        sortByHeightHook,
        sortByStartDate,
        sortByEndDate,
        sortOpeByDate
    }
}
export default useSort