import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import newInstanceI18next from '../../Util/traductionUtils';
import ContextFormAndSurveyProvider from './ContextFormAndSurvey';
import Overview from './Overview';

export default function OnlyOverview({ editorState }) {

	const [thankSectionValue, setThankSectionValue] = useState();
	const [formSurveyStyles, setFormSurveyStyles] = useState();
	const [stepSurvey, setStepSurvey] = useState(1);
	const [isLast, setisLast] = useState(false);
	const [panelSelected, setpanelSelected] = useState(1);
	const [state, setState] = useState<any>();
	const [formData, setFormData] = useState<string | object>(state && JSON.stringify(state));
	const buildProperty = (s) => ({ Properties: [{ Name: 'FormSurveyCustom', Value: s && JSON.stringify(s) }] });
	const [data, setData] = useState<any>();

	useEffect(() => {
		setState(editorState);
		setData(buildProperty(editorState));
	}, [editorState]);

	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<ContextFormAndSurveyProvider
				setFormData={setFormData}
				formData={formData}
				panelSelected={panelSelected}
				setpanelSelected={setpanelSelected}
				stepSurvey={stepSurvey}
				stepSelected={setStepSurvey}
				formSurveyStyles={formSurveyStyles}
				isLast={isLast}
				setisLast={setisLast}
				setThankSectionValue={setThankSectionValue}
				setFormSurveyStyles={setFormSurveyStyles}
				data={data}
				state={state}
				setState={setState}
			>
				{state && state.sections?.reduce((acc, val) => acc.concat(val.panels), []).length > 0 &&
					<Overview
						state={state}
						thankSectionValue={thankSectionValue}
						showWrapper={false}
					/>
				}
			</ContextFormAndSurveyProvider>
		</I18nextProvider>
	);
}