import React from 'react';
import styles from './EditorHeader.module.css';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export default function EditorHeader({
	left = <></>,
	leftTitle = '',
	center = <></>,
	right = <></>,
	hasBorder = true,
	backTitle = 'Back',
	backOnClick
}) {

	const idTooltip = uuidv4();
	const cssClass = [styles.header];

	if (hasBorder) {
		cssClass.push(styles.has_border);
	}

	const handleClick = (ev) => {
		if (typeof backOnClick !== 'function') return;
		backOnClick(ev);
	};

	return (
		<div className={cssClass.join(' ')}>
			<ReactTooltip id={idTooltip} backgroundColor='black' effect='solid' />
			<div className={styles.col_left}>
				<a className={styles.left_back} data-tip={backTitle} data-for={idTooltip} onClick={handleClick}>
					<i className={styles.left_back_arrow + ' fas fa-chevron-left'}></i>
					<span className={styles.left_back_icon + ' icon_32 icon_beyable icon_color_turquoise'}></span>
				</a>
				<div className={styles.left_content}>
					{leftTitle ?
						<div className={styles.left_title}>{leftTitle}</div>
						:
						<>{left}</>
					}
				</div>
			</div>
			<div className={styles.col_center}>{center}</div>
			<div className={styles.col_right}>{right}</div>
		</div>
	);
}