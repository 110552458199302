import React , {useState, useEffect} from 'react';
import Btn from '../../../../Components/Btn';
import InputCustom from '../../../../Components/InputCustom';
import editionStyles from '../ProductFeedEdition.module.css';
import {useProductFeedEditionContext} from '../../context/ProductFeedEditionContext';
import Accordion from './Accordion';
import Confirm from '../../../../Components/Confirm';
import ReactTooltip from 'react-tooltip';

export default function Mappingcustom() {
	const {toggleMapField, handleDeleteCustomMapping
	} = useProductFeedEditionContext();
	const [confirmResetIsOpen, setConfirmResetIsOpen] = useState(false);
	const {productFeedObject,handleChangeSimpleField,AuthServices} =useProductFeedEditionContext();
	const [isAdmin, setisAdmin] = useState(false);
	const [hasContent, sethasContent] = useState(false);
	
	function handleChangeSimpleFieldFn(e){
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name,value);
	}

	useEffect(()=>{
		let roles = AuthServices.GetAuthenticatedUser();
		if(roles?.roles.includes('Admin')){
			setisAdmin(true);
		}
	},[AuthServices]);

	useEffect(()=>{
		let tmpHasContent = false;
		if (productFeedObject) {
			if (productFeedObject.mapperName || productFeedObject.mapperVersion) {
				tmpHasContent = true;
			}
		}
		sethasContent(tmpHasContent);
	},[productFeedObject]);

	if(!isAdmin){
		return <></>;
	}
	return (
		<>
			<Accordion
				label={'Custom mapping'}
				value={productFeedObject?.mapperName}
				placeholder="Not set"
				fieldIndex={1000}
				isEmpty={!hasContent}
				showDoneButton={true}
				headerTitle={<>Custom mapping</>}
			>
				{						<button
					className={editionStyles.card_map_reset}
					onClick={() => setConfirmResetIsOpen(true)}
					data-tip="Reset field mapping">
					<i className="fas fa-ban"></i>
				</button>
				}
				<InputCustom
					value={productFeedObject?.mapperName}
					type={'text'}
					label={'Mapper name'}
					name={'mapperName'}
					placeholder="Mapper name"
					onChange={(e)=>handleChangeSimpleFieldFn(e)}
					fullWidth={true}
				/>
				<InputCustom
					value={productFeedObject?.mapperVersion}
					type={'text'}
					label={'mapperVersion'}
					name={'mapperVersion'}
					placeholder="1.2"
					onChange={(e)=>handleChangeSimpleFieldFn(e)}
					fullWidth={true}
				/>
				<Confirm
					isOpen={confirmResetIsOpen}
					onClose={() => setConfirmResetIsOpen(false)}
					title='Reset field mapping'
					text='Are you sure to to reset field mapping?'
					confirmText='Reset'
					confirmCallback={() => {handleDeleteCustomMapping(); toggleMapField(1000);}}
				/>
				<ReactTooltip
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="click"
					delayShow={600}
				/>
			</Accordion>
		</>
	);
}
