import React, { useEffect, useState } from 'react';
import Btn from '../../../../Components/Btn';
import Modal from '../../../../Components/Modal';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalSection from '../../../../Components/ModalSection';
import ModalBody from '../../../../Components/ModalBody';
import ModalFooter from '../../../../Components/ModalFooter';
import SearchBar from '../../../../Components/SearchBar';
import EmptyState from '../../../../Components/EmptyState';
import Confirm from '../../../../Components/Confirm';
import { useGroupSortingContext } from './contextGroupSorting';
import { AttributeOrScoreFiltering } from '../../EntityTypes/EntityTypes';
import GroupSortingModalList from './GroupSortingModalList';
import GroupSortingSetup from './GroupSortingSetup';
import styles from './GroupSorting.module.css';

export default function GroupSortingModal() {

	const {
		canSave,
		modalIsOpened,
		selectedGroupSorting,
		availableSortingGroups,
		handleSaveGroupSorting,
		handleModalCancel,
		handleResetAttribute
	} = useGroupSortingContext();

	const getIsFirstTime = () => {
		const storage = localStorage.getItem('emerch-grouping-has-viewed');
		return !storage;
	};

	const getModalWidth = () => {
		if (firstTime) return 660;
		if (selectedGroupSorting.filterItem) return 830;
		return 500;
	};

	const [firstTime, setFirstTime] = useState<boolean>(getIsFirstTime());
	const [confirmChangeAttrIsOpen, setConfirmChangeAttrIsOpen] = useState<boolean>(getIsFirstTime());
	const [valueInSearchBar, setValueInSearchBar] = useState<string>('');
	const [filteredAvailableGroupSorting, setFilteredAvailableGroupSorting] = useState<AttributeOrScoreFiltering[]>(availableSortingGroups || []);

	const initializeFilteredAvailableGroupSorting = () => {
		setFilteredAvailableGroupSorting((availableSortingGroups || []).map(x => ({ ...x, itemList: [...x.itemList] })));
	};

	const search = (searchTerm: string) => {
		if (searchTerm.length === 0) {
			initializeFilteredAvailableGroupSorting();
		} else {
			setFilteredAvailableGroupSorting(value => value
				.map(v => ({ ...v, itemList: v.itemList.filter(x => x.name !== null && x.name.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1) }))
				.filter(v => v.itemList.length > 0));
		}
	};

	const handleSearchChange = (event: { persist: () => void; target: { value: string; }; }) => {
		event.persist();
		const value = event.target.value;
		setValueInSearchBar(value);
		search(value);
	};

	useEffect(() => {
		if (availableSortingGroups !== undefined) initializeFilteredAvailableGroupSorting();
	}, [availableSortingGroups]);

	const [modalStep, setModalStep] = useState<number>(selectedGroupSorting.filterItem ? 2 : 1);
	const [modalWidth, setModalWidth] = useState<number>(getModalWidth());

	useEffect(() => {
		setModalWidth(getModalWidth());
	}, [selectedGroupSorting, firstTime]);

	useEffect(() => {
		setModalStep(selectedGroupSorting.filterItem ? 2 : 1);
		setValueInSearchBar('');
		search('');
	}, [selectedGroupSorting]);

	useEffect(() => {
		if (!firstTime) {
			localStorage.setItem('emerch-grouping-has-viewed', '1');
		}
	}, [firstTime]);

	const handleBeforeResetAttribute = () => {
		const needConfirm = selectedGroupSorting?.groupsData?.length > 0;
		if (needConfirm) {
			setConfirmChangeAttrIsOpen(true);
		} else {
			handleResetAttribute();
		}
	};

	return (
		<Modal
			isOpen={modalIsOpened}
			width={modalWidth}
			height={700}
			onClose={() => { handleModalCancel(); }}>

			{firstTime &&
				<>
					<ModalBody position="relative">
						<EmptyState
							imageUrl="/Assets/empty_emerch_grouping.svg"
							textSize="l"
							title="Product grouping"
							text={
								<>
									<p>Automatically promote or demote product groups based on an attribute.</p>
									<p>Grouping takes priority over sorting.</p>
								</>
							}
							verticalAlign={true}
							primaryAction={
								<Btn
									onClick={() => setFirstTime(false)}
									message="Let start"
								/>
							}
						/>
					</ModalBody>
				</>
			}
			{!firstTime && modalStep === 1 &&
				<>
					<ModalHeader title="Group products by" />
					<ModalSection color="grey" size="s">
						<SearchBar
							onChangeValue={(e) => handleSearchChange(e)}
							valueInSearchBar={valueInSearchBar}
							placeholder="Search for an attribute"
							fullWidth={false}
							color="white"
						/>
					</ModalSection>
					<ModalBody>
						<GroupSortingModalList
							availableGroupings={filteredAvailableGroupSorting}
						/>
					</ModalBody>
				</>
			}
			{!firstTime && modalStep === 2 &&
				<>
					<ModalHeader title="Product grouping" />
					{selectedGroupSorting.filterItem &&
						<ModalSection color="grey" size="s">
							Group products by
							<a className={styles.selected_attr} onClick={handleBeforeResetAttribute}>
								<span className={styles.selected_attr_label}>{selectedGroupSorting.filterItem.name}</span>
								<span className={styles.selected_attr_icon}>
									<i className='fas fa-pen fa-fw'></i>
								</span>
							</a>
						</ModalSection>
					}
					<ModalBody position="relative">
						<GroupSortingSetup />
					</ModalBody>
					<ModalFooter
						hasBorder={true}
						primaryAction={
							<Btn
								message="Ok"
								disabled={!canSave}
								onClick={handleSaveGroupSorting}
							/>
						}
						secondaryAction={
							<Btn
								message="Cancel"
								style="ghost"
								color="secondary"
								onClick={handleModalCancel}
							/>
						}
					/>
				</>
			}
			<Confirm 
				isOpen={confirmChangeAttrIsOpen}
				onClose={() => setConfirmChangeAttrIsOpen(false)}
				confirmCallback={handleResetAttribute}
				title="Use another attribute?"
				text="The current configuration will be reset"
			/>
		</Modal>
	);
}