import React from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { FlagFr, FlagUs, FlagEn, FlagSp, FlagGer, FlagEu } from "../../../Assets/svgr";
export const nFormat = (num, digits) => {
    if (isNaN(num)) return "";
    return num.toLocaleString(undefined, { maximumFractionDigits: digits })
  }

export const nFormatShort = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits || 1).replace(rx, "$1") + item.symbol : "0";
  }
  
export const toReadableDuration = (durationInSeconds) => {
  var duration = moment.duration(durationInSeconds, 'seconds')
  const hours = (h) => moment.duration(h, 'hours')
  const minutes = (m) => moment.duration(m, 'minutes')
  const seconds = (s) => moment.duration(s, 'seconds')
  const format = (f) => duration.format(f)

  if (duration >= hours(24)){ return ["many", "days"]
  } else if (duration >= hours(2)){ return [format('H:m'), "hours"]
  } else if (duration >= hours(1)){ return [format('H:m'), "hour"]
  } else if (duration >= minutes(2)) { return [format('m:s'), "minutes"]
  } else if (duration >= minutes(1)) { return [format('m:s'), "minute"]
  } else if (duration >= seconds(2)) { return [format('s'), "seconds"]
  } else { return [format('s'), "second"]
  }
}


const SimilarString = (query, references) => {

  const getBigrams = (string) =>{
    const s = string.toLowerCase();
    const v = new Array(s.length - 1)
    for (let i = 0; i < v.length; i++) {
      v[i] = s.slice(i, i + 2)      
    }
    return v
  }

  const stringSimilarity = (str1, str2) =>{
    if (str1.length > 0 && str2.length > 0){
      const pairs1 = getBigrams(str1)
      const pairs2 = getBigrams(str2)
      const union = pairs1.length + pairs2.length
      let hit_count = 0
      for (let i = 0; i < pairs1.length; i++) {
        const p1 = pairs1[i];
        for (let j = 0; j < pairs2.length; j++) {
          const p2 = pairs2[j];
          if (p1 == p2){
            hit_count++;
          }
        }
      }
      if (hit_count > 0)
        return ((2.0 * hit_count) / union)
    }
    return 0.0
  }

  let results = []
  for (let i = 0; i < references.length; i++) {
    const element = references[i];
    const relevance = stringSimilarity(query, element)
    const result = {element, relevance}
    results.push(result)
  }
  return results.filter(x => x.relevance > 0.1).sort((v1, v2) => v2.relevance - v1.relevance);
}

export const approximateBrowser = (browser) => {
  const noIcon = () => <></>
  const browsers = {
    Chrome: {icon: () => <i className="fab fa-chrome"></i>},
    Safari: {icon: () => <i className="fab fa-safari"></i>},
    Firefox: {icon: () => <i className="fab fa-firefox"></i>},
    Edge: {icon: () => <i className="fab fa-edge"></i>},
    Opera: {icon: () => <i className="fab fa-opera"></i>},
    Pinterest: {icon: () => <i className="fab fa-pinterest"></i>},
    Facebook: {icon: () => <i className="fab fa-facebook"></i>},
    IE: {icon: () => <i className="fab fa-internet-explorer"></i>},
    ["Internet Explorer"]: {icon: () => <i className="fab fa-internet-explorer"></i>},
  }
  const similars = SimilarString(browser, Object.keys(browsers))
  return similars.length > 0 ? browsers[similars[0].element]  : { None: {icon: noIcon} }
}


  export const approximateDevice = (device) => {
  const noIcon = () => <></>
  const devices = {
    Mobile:{icon: () => <i className="fas fa-mobile-alt"></i>},
    Desktop:{icon:()=> <i className="fas fa-desktop"></i>}
  }
  const similars = SimilarString(device, Object.keys(devices))
  return similars.length > 0 ? devices[similars[0].element]  : { None: {icon: noIcon} }
  }



  export const approximateCountry = (country) => {
    const noIcon = () => <></>
  const countries = {
    America:{icon: () => <FlagUs/>},
    Europe:{icon: () => <FlagEu/>},
  }
const similars = SimilarString(country.split('/')[0], Object.keys(countries))
return similars.length > 0 ? countries[similars[0].element]  : { None: {icon: noIcon} }

}
export const approximateLanguage = (language) => {
  const noIcon = () => <></>
  const languages = {
    Fr:{icon: () => <FlagFr/>},
    En:{icon: () => <FlagEn/>},
    Us:{icon: () => <FlagUs/>},
    Ger:{icon: () => <FlagGer/>},
    Sp:{icon: () => <FlagSp/>},
  }
  const similars = SimilarString(language.split('-')[1], Object.keys(languages))
  return similars.length > 0 ? languages[similars[0].element]  : { None: {icon: noIcon} }
}
export const countries = {
	'AF': { 'en': 'Afghanistan', 'fr': 'Afghanistan' },
	'AL': { 'en': 'Albania', 'fr': 'Albanie' },
	'DZ': { 'en': 'Algeria', 'fr': 'Algérie' },
	'AS': { 'en': 'American Samoa', 'fr': 'Samoa américaines' },
	'AD': { 'en': 'Andorra', 'fr': 'Andorre' },
	'AO': { 'en': 'Angola', 'fr': 'Angola' },
	'AI': { 'en': 'Anguilla', 'fr': 'Anguilla' },
	'AQ': { 'en': 'Antarctica', 'fr': 'Antarctique' },
	'AG': { 'en': 'Antigua and Barbuda', 'fr': 'Antigua-et-Barbuda' },
	'AR': { 'en': 'Argentina', 'fr': 'Argentine' },
	'AM': { 'en': 'Armenia', 'fr': 'Arménie' },
	'AW': { 'en': 'Aruba', 'fr': 'Aruba' },
	'AU': { 'en': 'Australia', 'fr': 'Australie' },
	'AT': { 'en': 'Austria', 'fr': 'Autriche' },
	'AZ': { 'en': 'Azerbaijan', 'fr': 'Azerbaïdjan' },
	'BS': { 'en': 'Bahamas', 'fr': 'Bahamas' },
	'BH': { 'en': 'Bahrain', 'fr': 'Bahreïn' },
	'BD': { 'en': 'Bangladesh', 'fr': 'Bangladesh' },
	'BB': { 'en': 'Barbados', 'fr': 'Barbade' },
	'BY': { 'en': 'Belarus', 'fr': 'Biélorussie' },
	'BE': { 'en': 'Belgium', 'fr': 'Belgique' },
	'BZ': { 'en': 'Belize', 'fr': 'Belize' },
	'BJ': { 'en': 'Benin', 'fr': 'Bénin' },
	'BM': { 'en': 'Bermuda', 'fr': 'Bermudes' },
	'BT': { 'en': 'Bhutan', 'fr': 'Bhoutan' },
	'BO': { 'en': 'Bolivia', 'fr': 'Bolivie' },
	'BA': { 'en': 'Bosnia and Herzegovina', 'fr': 'Bosnie-Herzégovine' },
	'BW': { 'en': 'Botswana', 'fr': 'Botswana' },
	'BR': { 'en': 'Brazil', 'fr': 'Brésil' },
	'BN': { 'en': 'Brunei Darussalam', 'fr': 'Brunéi Darussalam' },
	'BG': { 'en': 'Bulgaria', 'fr': 'Bulgarie' },
	'BF': { 'en': 'Burkina Faso', 'fr': 'Burkina Faso' },
	'BI': { 'en': 'Burundi', 'fr': 'Burundi' },
	'CV': { 'en': 'Cabo Verde', 'fr': 'Cap-Vert' },
	'KH': { 'en': 'Cambodia', 'fr': 'Cambodge' },
	'CM': { 'en': 'Cameroon', 'fr': 'Cameroun' },
	'CA': { 'en': 'Canada', 'fr': 'Canada' },
	'KY': { 'en': 'Cayman Islands', 'fr': 'Îles Caïmans' },
	'CF': { 'en': 'Central African Republic', 'fr': 'République centrafricaine' },
	'TD': { 'en': 'Chad', 'fr': 'Tchad' },
	'CL': { 'en': 'Chile', 'fr': 'Chili' },
	'CN': { 'en': 'China', 'fr': 'Chine' },
	'CO': { 'en': 'Colombia', 'fr': 'Colombie' },
	'KM': { 'en': 'Comoros', 'fr': 'Comores' },
	'CG': { 'en': 'Congo', 'fr': 'Congo' },
	'CD': { 'en': 'Congo (Democratic Republic)', 'fr': 'Congo (République démocratique)' },
	'CR': { 'en': 'Costa Rica', 'fr': 'Costa Rica' },
	'CI': { 'en': 'Côte d\'Ivoire', 'fr': 'Côte d\'Ivoire' },
	'HR': { 'en': 'Croatia', 'fr': 'Croatie' },
	'CU': { 'en': 'Cuba', 'fr': 'Cuba' },
	'CY': { 'en': 'Cyprus', 'fr': 'Chypre' },
	'CZ': { 'en': 'Czech Republic', 'fr': 'République tchèque' },
	'DK': { 'en': 'Denmark', 'fr': 'Danemark' },
	'DJ': { 'en': 'Djibouti', 'fr': 'Djibouti' },
	'DM': { 'en': 'Dominica', 'fr': 'Dominique' },
	'DO': { 'en': 'Dominican Republic', 'fr': 'République dominicaine' },
	'EC': { 'en': 'Ecuador', 'fr': 'Équateur' },
	'EG': { 'en': 'Egypt', 'fr': 'Égypte' },
	'SV': { 'en': 'El Salvador', 'fr': 'El Salvador' },
	'GQ': { 'en': 'Equatorial Guinea', 'fr': 'Guinée équatoriale' },
	'ER': { 'en': 'Eritrea', 'fr': 'Érythrée' },
	'EE': { 'en': 'Estonia', 'fr': 'Estonie' },
	'SZ': { 'en': 'Eswatini', 'fr': 'Eswatini' },
	'ET': { 'en': 'Ethiopia', 'fr': 'Éthiopie' },
	'FJ': { 'en': 'Fiji', 'fr': 'Fidji' },
	'FI': { 'en': 'Finland', 'fr': 'Finlande' },
	'FR': { 'en': 'France', 'fr': 'France' },
	'GA': { 'en': 'Gabon', 'fr': 'Gabon' },
	'GM': { 'en': 'Gambia', 'fr': 'Gambie' },
	'GE': { 'en': 'Georgia', 'fr': 'Géorgie' },
	'DE': { 'en': 'Germany', 'fr': 'Allemagne' },
	'GH': { 'en': 'Ghana', 'fr': 'Ghana' },
	'GR': { 'en': 'Greece', 'fr': 'Grèce' },
	'GD': { 'en': 'Grenada', 'fr': 'Grenade' },
	'GT': { 'en': 'Guatemala', 'fr': 'Guatemala' },
	'GN': { 'en': 'Guinea', 'fr': 'Guinée' },
	'GW': { 'en': 'Guinea-Bissau', 'fr': 'Guinée-Bissau' },
	'GY': { 'en': 'Guyana', 'fr': 'Guyana' },
	'HT': { 'en': 'Haiti', 'fr': 'Haïti' },
	'HN': { 'en': 'Honduras', 'fr': 'Honduras' },
	'HU': { 'en': 'Hungary', 'fr': 'Hongrie' },
	'IS': { 'en': 'Iceland', 'fr': 'Islande' },
	'IN': { 'en': 'India', 'fr': 'Inde' },
	'ID': { 'en': 'Indonesia', 'fr': 'Indonésie' },
	'IR': { 'en': 'Iran', 'fr': 'Iran' },
	'IQ': { 'en': 'Iraq', 'fr': 'Irak' },
	'IE': { 'en': 'Ireland', 'fr': 'Irlande' },
	'IL': { 'en': 'Israel', 'fr': 'Israël' },
	'IT': { 'en': 'Italy', 'fr': 'Italie' },
	'JM': { 'en': 'Jamaica', 'fr': 'Jamaïque' },
	'JP': { 'en': 'Japan', 'fr': 'Japon' },
	'JO': { 'en': 'Jordan', 'fr': 'Jordanie' },
	'KZ': { 'en': 'Kazakhstan', 'fr': 'Kazakhstan' },
	'KE': { 'en': 'Kenya', 'fr': 'Kenya' },
	'KI': { 'en': 'Kiribati', 'fr': 'Kiribati' },
	'KP': { 'en': 'North Korea', 'fr': 'Corée du Nord' },
	'KR': { 'en': 'South Korea', 'fr': 'Corée du Sud' },
	'KW': { 'en': 'Kuwait', 'fr': 'Koweït' },
	'KG': { 'en': 'Kyrgyzstan', 'fr': 'Kirghizistan' },
	'LA': { 'en': 'Laos', 'fr': 'Laos' },
	'LV': { 'en': 'Latvia', 'fr': 'Lettonie' },
	'LB': { 'en': 'Lebanon', 'fr': 'Liban' },
	'LS': { 'en': 'Lesotho', 'fr': 'Lesotho' },
	'LR': { 'en': 'Liberia', 'fr': 'Libéria' },
	'LY': { 'en': 'Libya', 'fr': 'Libye' },
	'LI': { 'en': 'Liechtenstein', 'fr': 'Liechtenstein' },
	'LT': { 'en': 'Lithuania', 'fr': 'Lituanie' },
	'LU': { 'en': 'Luxembourg', 'fr': 'Luxembourg' },
	'MG': { 'en': 'Madagascar', 'fr': 'Madagascar' },
	'MW': { 'en': 'Malawi', 'fr': 'Malawi' },
	'MY': { 'en': 'Malaysia', 'fr': 'Malaisie' },
	'MV': { 'en': 'Maldives', 'fr': 'Maldives' },
	'ML': { 'en': 'Mali', 'fr': 'Mali' },
	'MT': { 'en': 'Malta', 'fr': 'Malte' },
	'MH': { 'en': 'Marshall Islands', 'fr': 'Îles Marshall' },
	'MR': { 'en': 'Mauritania', 'fr': 'Mauritanie' },
	'MU': { 'en': 'Mauritius', 'fr': 'Maurice' },
	'MX': { 'en': 'Mexico', 'fr': 'Mexique' },
	'FM': { 'en': 'Micronesia', 'fr': 'Micronésie' },
	'MD': { 'en': 'Moldova', 'fr': 'Moldavie' },
	'MC': { 'en': 'Monaco', 'fr': 'Monaco' },
	'MN': { 'en': 'Mongolia', 'fr': 'Mongolie' },
	'ME': { 'en': 'Montenegro', 'fr': 'Monténégro' },
	'MA': { 'en': 'Morocco', 'fr': 'Maroc' },
	'MZ': { 'en': 'Mozambique', 'fr': 'Mozambique' },
	'MM': { 'en': 'Myanmar', 'fr': 'Myanmar' },
	'NA': { 'en': 'Namibia', 'fr': 'Namibie' },
	'NR': { 'en': 'Nauru', 'fr': 'Nauru' },
	'NP': { 'en': 'Nepal', 'fr': 'Népal' },
	'NL': { 'en': 'Netherlands', 'fr': 'Pays-Bas' },
	'NZ': { 'en': 'New Zealand', 'fr': 'Nouvelle-Zélande' },
	'NI': { 'en': 'Nicaragua', 'fr': 'Nicaragua' },
	'NE': { 'en': 'Niger', 'fr': 'Niger' },
	'NG': { 'en': 'Nigeria', 'fr': 'Nigéria' },
	'NO': { 'en': 'Norway', 'fr': 'Norvège' },
	'OM': { 'en': 'Oman', 'fr': 'Oman' },
	'PK': { 'en': 'Pakistan', 'fr': 'Pakistan' },
	'PW': { 'en': 'Palau', 'fr': 'Palaos' },
	'PA': { 'en': 'Panama', 'fr': 'Panama' },
	'PG': { 'en': 'Papua New Guinea', 'fr': 'Papouasie-Nouvelle-Guinée' },
	'PY': { 'en': 'Paraguay', 'fr': 'Paraguay' },
	'PE': { 'en': 'Peru', 'fr': 'Pérou' },
	'PH': { 'en': 'Philippines', 'fr': 'Philippines' },
	'PL': { 'en': 'Poland', 'fr': 'Pologne' },
	'PT': { 'en': 'Portugal', 'fr': 'Portugal' },
	'QA': { 'en': 'Qatar', 'fr': 'Qatar' },
	'RO': { 'en': 'Romania', 'fr': 'Roumanie' },
	'RU': { 'en': 'Russia', 'fr': 'Russie' },
	'RW': { 'en': 'Rwanda', 'fr': 'Rwanda' },
	'WS': { 'en': 'Samoa', 'fr': 'Samoa' },
	'SM': { 'en': 'San Marino', 'fr': 'Saint-Marin' },
	'ST': { 'en': 'Sao Tome and Principe', 'fr': 'Sao Tomé-et-Principe' },
	'SA': { 'en': 'Saudi Arabia', 'fr': 'Arabie saoudite' },
	'SN': { 'en': 'Senegal', 'fr': 'Sénégal' },
	'RS': { 'en': 'Serbia', 'fr': 'Serbie' },
	'SC': { 'en': 'Seychelles', 'fr': 'Seychelles' },
	'SL': { 'en': 'Sierra Leone', 'fr': 'Sierra Leone' },
	'SG': { 'en': 'Singapore', 'fr': 'Singapour' },
	'SK': { 'en': 'Slovakia', 'fr': 'Slovaquie' },
	'SI': { 'en': 'Slovenia', 'fr': 'Slovénie' },
	'SB': { 'en': 'Solomon Islands', 'fr': 'Îles Salomon' },
	'SO': { 'en': 'Somalia', 'fr': 'Somalie' },
	'ZA': { 'en': 'South Africa', 'fr': 'Afrique du Sud' },
	'SS': { 'en': 'South Sudan', 'fr': 'Soudan du Sud' },
	'ES': { 'en': 'Spain', 'fr': 'Espagne' },
	'LK': { 'en': 'Sri Lanka', 'fr': 'Sri Lanka' },
	'SD': { 'en': 'Sudan', 'fr': 'Soudan' },
	'SR': { 'en': 'Suriname', 'fr': 'Suriname' },
	'SE': { 'en': 'Sweden', 'fr': 'Suède' },
	'CH': { 'en': 'Switzerland', 'fr': 'Suisse' },
	'SY': { 'en': 'Syria', 'fr': 'Syrie' },
	'TW': { 'en': 'Taiwan', 'fr': 'Taïwan' },
	'TJ': { 'en': 'Tajikistan', 'fr': 'Tadjikistan' },
	'TZ': { 'en': 'Tanzania', 'fr': 'Tanzanie' },
	'TH': { 'en': 'Thailand', 'fr': 'Thaïlande' },
	'TL': { 'en': 'Timor-Leste', 'fr': 'Timor-Leste' },
	'TG': { 'en': 'Togo', 'fr': 'Togo' },
	'TO': { 'en': 'Tonga', 'fr': 'Tonga' },
	'TT': { 'en': 'Trinidad and Tobago', 'fr': 'Trinité-et-Tobago' },
	'TN': { 'en': 'Tunisia', 'fr': 'Tunisie' },
	'TR': { 'en': 'Turkey', 'fr': 'Turquie' },
	'TM': { 'en': 'Turkmenistan', 'fr': 'Turkménistan' },
	'TV': { 'en': 'Tuvalu', 'fr': 'Tuvalu' },
	'UG': { 'en': 'Uganda', 'fr': 'Ouganda' },
	'UA': { 'en': 'Ukraine', 'fr': 'Ukraine' },
	'AE': { 'en': 'United Arab Emirates', 'fr': 'Émirats arabes unis' },
	'GB': { 'en': 'United Kingdom', 'fr': 'Royaume-Uni' },
	'US': { 'en': 'United States', 'fr': 'États-Unis' },
	'UY': { 'en': 'Uruguay', 'fr': 'Uruguay' },
	'UZ': { 'en': 'Uzbekistan', 'fr': 'Ouzbékistan' },
	'VU': { 'en': 'Vanuatu', 'fr': 'Vanuatu' },
	'VE': { 'en': 'Venezuela', 'fr': 'Venezuela' },
	'VN': { 'en': 'Vietnam', 'fr': 'Viêt Nam' },
	'YE': { 'en': 'Yemen', 'fr': 'Yémen' },
	'ZM': { 'en': 'Zambia', 'fr': 'Zambie' },
	'ZW': { 'en': 'Zimbabwe', 'fr': 'Zimbabwe' }
};