import React, { Component } from 'react';
import { react2angular } from 'react2angular';
import { BrowserRouter, NavLink } from 'react-router-dom';
import styles from './VisitorEngagementReports.module.css';
import VisitorEngagementReportsCustomJourneyComponent from './VisitorEngagementReportsCustomJourneyComponent';
import VisitorEngagementReportsUserEngagementComponent from './VisitorEngagementReportsUserEngagementComponent';
import VisitorEngagementReportsKPIsComponent from './VisitorEngagementReportsKPIsComponent';
import VisitorEngagementServices from '../../Services/VisitorEngagementServices';
import SystemServices from '../../Services/SystemServices';
import TinyLoader from '../../Components/TinyLoader';
import ChartFilterEnum from './ChartFilterEnum';
import ChartDisplayEnum from './ChartDisplayEnum';
import VisitorEngagementRightPane from '../VisitorEngagementCommon/VisitorEngagementRightPaneContainer';
import EventTypeEnum from './EventTypeEnum';
import EngagementLevelColors from './EngagementLevelColors';
import EngagementLevelTitle from './EngagementLevelTitle';
import EngagamentLevelOrder from './EngagementLevelOrder';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import MomentConstants from '../../Constants/MomentConstants';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import InAppMenu from '../../Components/InAppMenu';
import VisitorEngagementSelectTenant from './VisitorEngagementSelectTenant';
const getFlagIconFromEventType = (eventType) => {
	let icon;
	switch (eventType) {
	case EventTypeEnum.Operation:
		icon = 'Assets/pin_action2.png';
		break;
	case EventTypeEnum.BeyableScenario:
		icon = 'Assets/pin_beyable2.png';
		break;
	case EventTypeEnum.NationalEvent:
		icon = 'Assets/pin_event2.png';
		break;
	case EventTypeEnum.None:
	default:
		icon = 'Assets/pin_action2.png';
		break;
	}
	return icon;
};

const cSharpDictionaryToHighChartsArrayOfValues = (dict) => {
	let arrayOfValues = new Array();
	for (var key in dict) {
		arrayOfValues.push([Number(key), dict[key]]);
	}
	return arrayOfValues;
};

export default class VisitorEngagementReportsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customerJourneyData: [],
			customerJourneyLoading: false,
			userEngagementData: [],
			userEngagementData2: [],
			userEngagementFilter: ChartFilterEnum.Desktop,
			userEngagementDisplay: ChartDisplayEnum.Values,
			userEngagementLoading: false,
			kpisData: [],
			kpisLoading: false,
			momentStartDate: moment().subtract(1, 'months').startOf('month'),
			momentEndDate: moment().subtract(1, 'months').endOf('month'),
			showEventPanel: false,
			eventToEdit: null,
			tenants: [],
			tenantSelected: 'none',
		};

		this.visitorEngagementServices = new VisitorEngagementServices(this.props.$http);
		this.systemServices = new SystemServices(this.props.$rootScope);
		this.impersonatingServices = new ImpersonatingServices(this.props.$rootScope, this.props.$routeParams);
		this.impersonatedAccount = this.impersonatingServices.getImpersonatedAccount();
		this.currentAccount = this.impersonatedAccount || this.props.$rootScope.User.Account.Key;
		this.currentUser = this.props.$rootScope.User.Key;
		this.isImpersonating = !!this.impersonatedAccount;


		this.showAddEvent = this.showAddEvent.bind(this);
		this.changeFilter = this.changeFilter.bind(this);
		this.changeDisplay = this.changeDisplay.bind(this);
		this.refreshUserEngagementStatistics = this.refreshUserEngagementStatistics.bind(this);
		this.refreshEventMarks = this.refreshEventMarks.bind(this);
		this.onFlagClicked = this.onFlagClicked.bind(this);
		this.selectRangeDate = this.selectRangeDate.bind(this);
		this.changeRangeDate = this.changeRangeDate.bind(this);
		this.applyRangeDate = this.applyRangeDate.bind(this);

		this.urlReporting = '/VisitorEngagementReports/Home';
		this.urlSettings = '/VisitorEngagement/Home';
		if( this.props.$routeParams && this.props.$routeParams.ka ){
			this.urlReporting += '?ka=' + this.props.$routeParams.ka;
			this.urlReporting += '&ku=' + this.props.$routeParams.ku;
			this.urlSettings += '?ka=' + this.props.$routeParams.ka;
			this.urlSettings += '&ku=' + this.props.$routeParams.ku;
		}

		this.permissions = this.props.$rootScope.User.Permissions;
		if( this.impersonatingServices.isAnAdmin() ){
			this.canViewReporting = true;
			this.canViewSettings = true;
		}else{
			this.canViewReporting = this.permissions.some(function(item){
				if( item.Name !== 'VISITOR_ENGAGEMENT_REPORT_CAN_VIEW' ) return false;
				if( item.Value !== true ) return false;
				return true;
			});
			this.canViewSettings = this.permissions.some(function(item){
				if( item.Name !== 'VISITOR_ENGAGEMENT_REPORT_CAN_SET' ) return false;
				if( item.Value !== true ) return false;
				return true;
			});
		}
	}

	componentDidMount() {
		this.getTheListOfTenant();
	}

	getTheListOfTenant() {
		this.visitorEngagementServices.getTenants(this.currentAccount,
			dataTenants => {
				let arrayOfTenantsForThisAccount = dataTenants.map(t => t);
				let tenantToSelectInTheList = 'none';
				if (arrayOfTenantsForThisAccount.length !== 0) {
					if (arrayOfTenantsForThisAccount.includes('')) {
						arrayOfTenantsForThisAccount[arrayOfTenantsForThisAccount.indexOf('')] = 'none';
					} else {
						arrayOfTenantsForThisAccount.push('none');
					}
					arrayOfTenantsForThisAccount.unshift('All');
					tenantToSelectInTheList = 'All';
				}
				this.setState({
					tenants: arrayOfTenantsForThisAccount,
					tenantSelected: tenantToSelectInTheList
				});
				this.refreshCustomerJourney(tenantToSelectInTheList);
				this.refreshUserEngagementStatistics(tenantToSelectInTheList);
				this.refreshKpis(tenantToSelectInTheList);
			}, errorMessage => {
				this.systemServices.showError(errorMessage);
			});
	}
	refreshCustomerJourney(tenant) {
		this.setState({ customerJourneyLoading: true });
		this.visitorEngagementServices.getCustomerJourney(this.currentAccount,
			this.state.momentStartDate,
			this.state.momentEndDate,
			tenant,
			customerJourneyData => {
				this.setState({
					customerJourneyData: customerJourneyData,
					customerJourneyLoading: false
				});
			}, errorMessage => {
				this.systemServices.showError(errorMessage);
				this.setState({ customerJourneyLoading: false });
			});
	}

	changeFilter(filter) {
		if (filter !== this.state.userEngagementFilter) {
			this.setState({ userEngagementFilter: filter }, () => {
				this.refreshUserEngagementStatistics(this.state.tenantSelected);
			});
		}
	}

	changeDisplay(display) {
		if (display !== this.state.userEngagementDisplay) {
			this.setState({ userEngagementDisplay: display }, () => {
				this.refreshUserEngagementStatistics(this.state.tenantSelected);
			});
		}
	}

	refreshUserEngagementStatistics(tenant) {
		this.setState({ userEngagementLoading: true });
		this.visitorEngagementServices.getUserEngagement(this.currentAccount,
			this.state.momentStartDate,
			this.state.momentEndDate,
			this.state.userEngagementFilter,
			this.state.userEngagementDisplay,
			tenant,
			data => {
				const objectMap = (obj, fn, fnk) =>
					Object.fromEntries(
						Object.entries(obj).map(
							([k, v], i) => [fnk(k), fn(v, k, i)]
						)
					);
				let userEngagementData = data
					.sort((a, b) => EngagamentLevelOrder[b.levelTag] - EngagamentLevelOrder[a.levelTag])
					.map(e => {
						const displayData = {};
						displayData.color = EngagementLevelColors[e.levelTag];
						displayData.name = EngagementLevelTitle[e.levelTag];
						displayData.data = objectMap(e.data, v => Math.round(v), k => moment.utc(k, 'YYYY/MM/DD').valueOf());
						return displayData;
					});
				let userEngagementDataWithArrayOfValues = new Array();
				for (var i in userEngagementData) {
					userEngagementDataWithArrayOfValues.push({
						...userEngagementData[i],
						type: 'column',
						data: cSharpDictionaryToHighChartsArrayOfValues(userEngagementData[i].data),

					});
				}

				let userEngagementDataWithArrayOfValues2 = new Array();
				// loop sur nb de tableau de données
				// for (let u = 0; u < userEngagementDataWithArrayOfValues.length; u++) {
				//     console.log('test', ...userEngagementDataWithArrayOfValues[u].data);
				//     // pour chaque tableau map et push vers nouveau tableau la valeur
				//     userEngagementDataWithArrayOfValues[u].data.map(
				//         timestamp => {
				//             console.log('timestamp', timestamp);
				//             userEngagementDataWithArrayOfValues2.push(
				//                 timestamp
				//             )
				//             // timestamp.reduce((x, y) => { return x + y }, 0)
				//         }
				//     )
				// }
				//console.log("userEngagementDataWithArrayOfValues2", userEngagementDataWithArrayOfValues2);
				// var byTimeSlot = {};
				// var res = userEngagementDataWithArrayOfValues.reduce(function (acc, v) {
				//     for (var d in v.data) {
				//         acc[d] += v.data[d];
				//     }
				// }, byTimeSlot);
				// console.log("res", res);

				this.setState({
					userEngagementData: userEngagementDataWithArrayOfValues,
				}, () => this.refreshEventMarks());
			}, errorMessage => {
				this.systemServices.showError(errorMessage);
				this.setState({ userEngagementLoading: false });
			});

	}
	refreshEventMarks() {
		this.visitorEngagementServices.getEventMarks(this.currentAccount,
			this.state.momentStartDate,
			this.state.momentEndDate,
			eventMarksData => {
				let flagsData = eventMarksData.map(mark => {
					let flag = {
						x: moment(mark.date, MomentConstants.MOMENT_API_FORMAT).valueOf(), // date in timestamp millisecond
						title: ' ',
						text: mark.name,
						shape: `url(${getFlagIconFromEventType(mark.type)})`,
						events: { click: e => { this.onFlagClicked(e, mark); } }
					};
					return flag;
				});

				let flags = {
					type: 'flags',
					data: flagsData,
					onSeries: '',
					fillColor: '#43ACD9',
					tooltip: {
						backgroundColor: '#43ACD9',
						borderRadius: 1,
						style: { color: '#FFFFFF' },
						borderWidth: 2,
						pointFormat: '<div style="background: red">{point.text}</div>',
					},
					width: 30
				};

				this.setState({
					userEngagementData: [...this.state.userEngagementData, flags],
					userEngagementLoading: false
				});
			}, errorMessage => {
				this.systemServices.showError(errorMessage);
				this.setState({ userEngagementLoading: false });
			});
	}

	onFlagClicked(e, event) {
		this.showEditEvent(event);
	}

	refreshKpis(tenant) {
		this.setState({ kpisLoading: true });
		this.visitorEngagementServices.getKPIs(this.currentAccount,
			this.state.momentStartDate,
			this.state.momentEndDate,
			tenant,
			kpisData => {
				this.setState({
					kpisData: kpisData,
					kpisLoading: false
				});
			}, errorMessage => {
				this.systemServices.showError(errorMessage);
				this.setState({ kpisLoading: false });
			});
	}

	showAddEvent() {
		this.setState({ showEventPanel: true });
	}

	showEditEvent(event) {
		this.setState({ eventToEdit: event });
		this.setState({ showEventPanel: true });
	}

	hideAndResetPanel() {
		this.setState({ showEventPanel: false });
		this.setState({ eventToEdit: null });
	}

	createOrUpdateEvent(event) {
		this.visitorEngagementServices.createOrUpdateEvent(this.currentAccount,
			event,
			success => {
				if (event.id) {
					this.systemServices.showSuccess('Event successfully updated');
				} else {
					this.systemServices.showSuccess('Event successfully created');
				}
				this.hideAndResetPanel();
				this.refreshUserEngagementStatistics(this.state.tenantSelected);
			},
			error => {
				this.systemServices.showError(error);
			});
	}

	deleteEvent(eventId) {
		this.visitorEngagementServices.deleteEvent(this.currentAccount,
			eventId,
			success => {
				this.systemServices.showSuccess('Event has been deleted');
				this.hideAndResetPanel();
				this.refreshUserEngagementStatistics(this.state.tenantSelected);
			},
			error => {
				this.systemServices.showError(error);
			});
	}

	selectRangeDate(event, picker) {
		this.setState({ momentStartDate: picker.startDate });
		this.setState({ momentEndDate: picker.endDate });
	}

	changeRangeDate(event) {
		let rangeDateString = event.target.value;
		let rangeDateArray = rangeDateString.split(' - ');
		if (rangeDateArray.length === 2) {
			let momentStartDate = moment(rangeDateArray[0], MomentConstants.MOMENT_API_FORMAT);
			if (momentStartDate.isValid()) {
				this.setState({ momentStartDate: momentStartDate });
			}
			let momentEndDate = moment(rangeDateArray[1], MomentConstants.MOMENT_API_FORMAT);
			if (momentEndDate.isValid()) {
				this.setState({ momentEndDate: momentEndDate });
			}
		}
	}

	applyRangeDate() {
		this.refreshCustomerJourney(this.state.tenantSelected);
		this.refreshUserEngagementStatistics(this.state.tenantSelected);
		this.refreshKpis(this.state.tenantSelected);
	}
	handleChangeTheTenant(tenantToSelect) {
		this.setState({ tenantSelected: tenantToSelect });
		this.refreshCustomerJourney(tenantToSelect);
		this.refreshUserEngagementStatistics(tenantToSelect);
		this.refreshKpis(tenantToSelect);
	}
	render() {
		return <div className={styles.content}>
			<div className={styles.intro}>
				<h1 className={styles.title}>Scoring engagement</h1>
			</div>
			<div>
				<BrowserRouter>
					<ul className="tabs mb_30">
						{this.canViewReporting &&
                      <li>
                      	<NavLink to={this.urlReporting}>
                            Reporting
                      	</NavLink>
                      </li>
						}
						{this.canViewSettings && 
                      <li>
                      	<NavLink to={this.urlSettings}>
                            Settings
                      	</NavLink>
                      </li>
						}
					</ul>
				</BrowserRouter>
			</div>
			<div>
				{this.state.customerJourneyLoading ?
					<TinyLoader /> :
					<VisitorEngagementReportsCustomJourneyComponent
						data={this.state.customerJourneyData}
					/>}
			</div>
			<div>
				{this.state.userEngagementLoading ?
					<TinyLoader /> :
					<VisitorEngagementReportsUserEngagementComponent
						showAddEvent={this.showAddEvent}
						series={this.state.userEngagementData}
						filter={this.state.userEngagementFilter}
						display={this.state.userEngagementDisplay}
						changeFilter={this.changeFilter}
						changeDisplay={this.changeDisplay}
					/>}
			</div>
			<div>
				{this.state.kpisLoading ?
					<TinyLoader /> :
					<VisitorEngagementReportsKPIsComponent data={this.state.kpisData} />}
			</div>
			<div className={styles.actionButtons}>
				<VisitorEngagementSelectTenant
					tenants={this.state.tenants}
					tenantSelected={this.state.tenantSelected}
					handleChangeTheTenant={(e) => this.handleChangeTheTenant(e)}
				/>
				<div className={styles.displayedPeriod}>
					<DateRangePicker startDate={`${this.state.momentStartDate.format(MomentConstants.MOMENT_DATEPICKER_FORMAT)}`}
						endDate={`${this.state.momentEndDate.format(MomentConstants.MOMENT_DATEPICKER_FORMAT)}`}
						onApply={this.selectRangeDate}>
						<input type="text"
							value={`${this.state.momentStartDate.format(MomentConstants.MOMENT_API_FORMAT)} - ${this.state.momentEndDate.format(MomentConstants.MOMENT_API_FORMAT)}`}
							onChange={this.changeRangeDate} />
					</DateRangePicker>
				</div>
				<button className={styles.applyButton} onClick={this.applyRangeDate}><i className="fa fa-sync-alt"></i></button>
			</div>
			<VisitorEngagementRightPane
				title={this.state.eventToEdit ? 'Edit an event' : 'Add an event '}
				description={''}
				intro={'Add a pin each time there is an outstanding event which can impact engagement of your website visitors. Whether it is a massive ad campaign on TV or a temporary offer released to your clients by email, pin it to get a clear overview of your marketing efforts and avoid misinterpreting ups and downs.'}
				actionLabel={this.state.eventToEdit ? 'Edit' : 'Create'}
				showEventPanel={this.state.showEventPanel}
				editPayload={this.state.eventToEdit}
				createOrUpdate={this.createOrUpdateEvent.bind(this)}
				delete={this.deleteEvent.bind(this)}
				hideAndResetPanel={this.hideAndResetPanel.bind(this)}
			/>
		</div>;
	}
}

angular.module('beyableSaasApp.VisitorEngagementReports', ['beyableSaasApp.Services'])
	.component('visitorreports', react2angular(VisitorEngagementReportsContainer, [], ['$http', 'RestServices', '$rootScope', '$scope', '$routeParams']));
