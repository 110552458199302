const EngagementLevelEnum = {
    None: "None",
    Discover: "Discover",
    Consideration: "Consideration",
    Intention: "Intention",
    DecisionAndPurchase: "DecisionAndPurchase",
    Experience: "Experience",
    RecurringPurchase: "RecurringPurchase"
}

export default EngagementLevelEnum;
