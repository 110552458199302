import React, {useState} from 'react';
import Btn from './Btn';
import InputCustom from './InputCustom';

export default function CodeSnippet({
	code,
	nbRows = 17
}){
	let can_copy = true;

	const [copyText, setCopyText] = useState('COPY');
	const [copyColor, setCopyColor] = useState('secondary');
	const [copyIcon, setCopyIcon] = useState('far fa-clipboard');

	const copyToClipBoard = (ev) => {
    	if( ! can_copy ) return;

		navigator.clipboard.writeText(code).then(function(){
			can_copy = false;
			setCopyText('Copied !');
			setCopyColor('success');
			setCopyIcon('fas fa-check');
			setTimeout(function(){
				can_copy = true;
				setCopyText('COPY');
				setCopyColor('secondary');
				setCopyIcon('far fa-clipboard');
			}, 2500);
		});
	};

	return (
		<div className="custom_input_copy_wrapper">
			<InputCustom
				type="textarea"
				value={code}
				readOnly={true}
				autofocus={true}
				selectOnFocus={true}
				fullWidth={true}
				rows={nbRows}
				spellCheck={false}
			/>
			<Btn
				onClick={(e)=>{copyToClipBoard(e);}}
				message={copyText}
				color={copyColor}
				icon={copyIcon}
				style="outline"
				className="custom_input_copy"
			/>
		</div>
	);
}


