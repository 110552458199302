import React, { Component } from 'react';
import styles from './GaugeBar.module.css';

export default class GaugeBar extends Component {
    constructor(props) {
        super(props);
    }

    renderGaugeBar() {
        let percentage = this.props.percentageValue.toFixed(2);
        let color = this.props.color;

        let result = [];

        let firstBarAfterColoredBarAdded = false;
        for (let i = 1; i <= 10; i++) {
            let currentPercent = i * 10;
            if (currentPercent < percentage) {
                result.push(<div className={styles.bar} key={i} style={{ backgroundColor: color, height: '16px', marginTop: '1px' }}></div>)
            } else if (!firstBarAfterColoredBarAdded) {
                result.push(<div className={styles.bar} key={i} style={{ color: color, height: '16px', border: '1px solid #F3F3F3' }}>{`${percentage} %`}</div>)
                firstBarAfterColoredBarAdded = true;
            } else {
                result.push(<div className={styles.bar} key={i} style={{ height: '16px', border: '1px solid #F3F3F3' }}></div>)
            }
        }

        return result;
    }

    render() {
        return <div className={`${styles.container} ${this.props.customClassName}`}>
            {this.renderGaugeBar()}
        </div>
    }
}