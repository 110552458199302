import React, {useState, useEffect, useRef} from 'react';
import TransformationContainerList from './TransformationContainerList';
import {listOfFormat,listOfQuality,} from './utils';
import SectionMessage from '../../Components/SectionMessage';
import SpinnerWheel from '../../Components/SpinnerWheel';
import styles from './Twic.module.css';
import InputCustom from '../../Components/InputCustom';

export default function TwicTransformationsImgOptions({twicPics,
	isLoadingImgPreview,
	url,
	imgWithTransformations,
	setimgWithTransformations,
	seturlTransformation,
	urlOrigin,
	refreshIsNeed,
	resize,
	setResize
}) {
	const [format, setformat] = useState(listOfFormat[0].key);
	const imgRef = useRef(null);
	const [naturalImgRatio, setNaturalImgRatio] = useState(1);
	const [isLoadingTranformations, setisLoadingTranformations] = useState(true);
	const [quality, setquality] = useState(listOfQuality[0]);
	const [sizeBlob, setsizeBlob] = useState(null);
	const [imageDataInformations, setimageDataInformations] = useState();

	function handleSelectQuality(ele){
		if(quality !== ele){
			setisLoadingTranformations(true);
			setquality(listOfQuality.find(el => el.key === ele));
		}
	}
	function handleSelectFormat(ele){
		setisLoadingTranformations(true);
		setformat(ele);
	}

	useEffect(()=>{
		setisLoadingTranformations(isLoadingImgPreview);
	},[isLoadingImgPreview]);

	function onTransformUrlToImageWithFormatAndQuality(e) {
		const urlQuality = quality.key === 'auto' ? '' : `/quality=${quality.quality}/quality-max=${quality.qualityMax}`;
		const urlFormat = `/output=${format}`;
		let resizeTwic = '';
		if(resize.height !== '' && resize.width !== '' && resize.height !== 0  && resize.width !== 0){
			resizeTwic =`/resize=${resize.width}x${resize.height}`;
		}
		seturlTransformation(url+urlQuality+urlFormat+resizeTwic);
		fetch(url+urlQuality+urlFormat+resizeTwic)
			.then(function (response) {
				return response.blob();
			}).then(function (myBlob) {
				const bytes = myBlob.size;
				function FileConvertSize(aSize) {
					aSize = Math.abs(parseInt(aSize, 10));
					var def = [[1, 'octets'], [1024, 'ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
					for (var i = 0; i < def.length; i++) {
						if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
					}
				}
				setsizeBlob(FileConvertSize(bytes));
				const reader = new FileReader();
				reader.addEventListener('load', () =>{
					setTimeout(()=>{
						setisLoadingTranformations(false);
					},300);
					setimgWithTransformations(reader.result?.toString() || '');
				});

				reader.readAsDataURL(myBlob);
			}).catch(function (error) {
				console.error(error);
				setisLoadingTranformations(false);
			});			
	}

	useEffect(()=>{
		onTransformUrlToImageWithFormatAndQuality();
	},[twicPics, quality, format]);

	function getDatasInformationsFromCurrentImgPreview(){
		const curr = imgRef.current;
		if (!curr) return;

		const objWithCurrentImgWidthHeight = {
			naturalHeight : curr.naturalHeight,
			naturalWidth : curr.naturalWidth,
			clientHeight : curr.clientHeight,
			clientWidth : curr.clientWidth,
		};
		// setResize({
		// 	height : curr.naturalHeight,
		// 	width : curr.naturalWidth
		// });
		setNaturalImgRatio(curr.naturalWidth / curr.naturalHeight);
		setimageDataInformations(objWithCurrentImgWidthHeight);
	}
	useEffect(()=>{
		if(imgRef){
			getDatasInformationsFromCurrentImgPreview();
		}
	},[isLoadingTranformations, isLoadingImgPreview, refreshIsNeed]);
	
	useEffect(()=>{
		if(urlOrigin.includes('output')){
			let formatIm = urlOrigin.split('output')[1];
			formatIm = formatIm.split('=');
			formatIm[1] === 'jpg' ? formatIm = 'jpeg' : formatIm = formatIm[1];
			setformat(formatIm.replace('/resize','').trim());
		}else{
			let formatIm = urlOrigin.split('images')[1];
			let formatImSplit = formatIm.split('.')[1];
			let formatToFind = '';
			if(formatImSplit === 'jpg' ){
				formatToFind = 'jpeg';
			}else{
				formatToFind = formatImSplit;
			}
			setformat(formatToFind.replace('/resize','').trim());
		}
	},[urlOrigin]);

	function handleChangeValueInput(key, e){
		const val = e.target.value;
		let newWidth;
		let newHeight;
		if (key == 'width') {
			newWidth = val;
			newHeight = Math.round(val / naturalImgRatio);
		} else {
			newWidth = Math.round(val * naturalImgRatio);
			newHeight = val;
		}

		setResize({
			width: newWidth,
			height: newHeight
		});
	}

	useEffect(()=>{
		const timeout = setTimeout(() => {
			onTransformUrlToImageWithFormatAndQuality();
		}, 500);

		return () => clearTimeout(timeout);
	},[resize]);
	return (
		<div className={styles.root}>
			<div className={styles.preview}>
				<div className={styles.preview_inner}>
					<img 
						ref={imgRef}
						src={imgWithTransformations ? imgWithTransformations : ''} 
						alt="Image preview"
						className={styles.img}
					/>
					{isLoadingTranformations &&
						<SpinnerWheel />
					}
				</div>
			</div>
			<div className={styles.panel}>
				<div className={styles.panel_body}>
					<TransformationContainerList
						listToMap={listOfQuality}
						title='Quality'
						valueSelected={quality.key}
						handleClick={handleSelectQuality}
					/>
					<TransformationContainerList
						listToMap={listOfFormat}
						title='Formats'
						valueSelected={format}
						handleClick={handleSelectFormat}
					/>
					<div className='flex'>
						<div className='flex_item_half mr_5'>
							<InputCustom
								label="Width"
								value={resize?.width}
								fullWidth={true}
								unit="px"
								blockClassName='l'
								onChange={(e)=>handleChangeValueInput('width',e)}
							/>
						</div>
						<div className='flex_item_half ml_5'>
							<InputCustom
								label="Height"
								value={resize?.height}
								fullWidth={true}
								unit="px"
								blockClassName='l'
								onChange={(e)=>handleChangeValueInput('height',e)}
							/>
						</div>
					</div>
				</div>
				<div className={styles.panel_footer}>
					<SectionMessage showIcon={false} noMargin={true}>
						<div className='flex'>
							<div className='flex_item_full fw_medium s_16'>
								Final size
							</div>
							<div className='flex_item_full al_right s_16'>
								{sizeBlob && sizeBlob}
							</div>
						</div>
					</SectionMessage>
				</div>
			</div>
		</div>
	);
}
