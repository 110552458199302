
import { react2angular } from 'react2angular';
import React from 'react'
import TimezoneSelect, {} from '../Components/TimezoneSelect'

export default function TimezoneSelectBridge(props) {

	const timeZoneSelected = props.onChange
	const value = props.value

	return (
		<TimezoneSelect
			handleTimeZoneSelected={timeZoneSelected}
			timezoneValue={value}
		></TimezoneSelect>
	)
}


angular
.module('beyableSaasApp.ComponentsBridges',)
.component('timezoneselect', react2angular(TimezoneSelectBridge, ['value', 'onChange'],
	[]));