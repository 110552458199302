import React, { useState, useEffect } from 'react';
import Btn from '../../../../Components/Btn';
import Confirm from '../../../../Components/Confirm';
import SelectDropdown from '../../../../Components/SelectDropdown';
import Checkbox from '../../../../Components/Checkbox';
import {Panel, PanelBody, PanelHeader} from '../../../../Components/Panel/Panel';
import {List, ListItem} from '../../../../Components/List';
import {Article} from '../../../../Components/Article/Article';
import EmptyState from '../../../../Components/EmptyState';

export default function useShopifyCollectionMapping({
	systemServices,
	productRankingServices,
	externalAppServices,
	rankingList
}) {

	const [availableShopifyCollections, setAvailableShopifyCollections] = useState([]);
	const [hasShopifyCollectionRanking, setHasShopifyCollectionRanking] = useState(false);
	const [askConfirmExitEditingShopifyCollectionRanking, setAskConfirmExitEditingShopifyCollectionRanking] = useState(false);
	const [shopifyCollectionMappingsInitialValue, setShopifyCollectionMappingsInitialValue] = useState();
	const [shopifyCollectionsPanelIsOpen, setShopifyCollectionsPanelIsOpen] = useState(false);
	const [shopifyCollectionMappings, setShopifyCollectionMappings] = useState({});
	const [optionsList, setOptionsList] = useState([]);
	const [optionsListForAll, setOptionsListForAll] = useState([]);
	const [selectedCollections, setSelectedCollections] = useState([]);

	useEffect(() => {
		externalAppServices.getExternalAppConfiguration(config => {
			if (config?.externalAppType === 'Shopify' && config.activatedFeatures.includes('ProductRanking')) {
				productRankingServices.getAvailableShopifyCollectionInfos(collections => {
					setHasShopifyCollectionRanking(true);
					setAvailableShopifyCollections(collections);
				}, err => {
					console.error(err);
					systemServices.showError('Cannot synchronize Shopify info');
				});
			}
		}, err => {
			console.error(err);
		});
	}, []);

	useEffect(() => {
		if (rankingList) {
			const defaultOption = [{
				label: 'Default choice',
				value: ''
			}];
			const noneOption = [{
				label: 'No ranking rule',
				value: ''
			}];
			const optionsList = rankingList.map((rule) => {
				return {
					label: rule.name,
					value: rule.id
				};
			});
			setOptionsList(defaultOption.concat(optionsList));
			setOptionsListForAll(noneOption.concat(optionsList));
		}
	}, [rankingList]);

	function shopifyCollectionRankingIsStale() {
		return shopifyCollectionMappingsInitialValue !== JSON.stringify(shopifyCollectionMappings);
	}
	function enterEditingShopifyCollectionRanking() {
		productRankingServices.getShopifyCollectionProductRanking(data => {
			const reverseData = {};
			data.forEach(rule => {
				const collectionIds = rule.collectionIds || [];
				collectionIds.forEach(collectionId => {
					reverseData[collectionId] = rule.rankingRuleId;
				});
			});
			setShopifyCollectionMappings(reverseData);
			setShopifyCollectionMappingsInitialValue(JSON.stringify(reverseData));
			setShopifyCollectionsPanelIsOpen(true);
		}, err => {
			console.error(err);
			systemServices.showError('Cannot get Shopify mapping');
		});
	}
	function exitEditingShopifyCollectionRanking() {
		if (shopifyCollectionRankingIsStale()) {
			setAskConfirmExitEditingShopifyCollectionRanking(true);
		} else {
			setShopifyCollectionsPanelIsOpen(false);
		}
	}
	function confirmExitEditingShopifyCollectionRanking(confirm) {
		if (confirm) {
			setShopifyCollectionsPanelIsOpen(false);
		}
		setAskConfirmExitEditingShopifyCollectionRanking(false);
	}

	function saveMappings() {
		const newCollectionMappings = [];

		for (const [collectionId, ruleId] of Object.entries(shopifyCollectionMappings)) {
			if (!ruleId) continue;
			const existingRule = newCollectionMappings.find((r) => r.rankingRuleId === ruleId);
			if (existingRule) {
				existingRule.collectionIds.push(collectionId);
			} else {
				newCollectionMappings.push({
					collectionIds: [collectionId],
					rankingRuleId: ruleId
				});
			}
		}

		productRankingServices.createOrUpdateShopifyCollectionProductRanking(
			{ mappings: newCollectionMappings },
			() => {
				systemServices.showSuccess('Shopify mapping saved successfully');
				setShopifyCollectionMappingsInitialValue(JSON.stringify(shopifyCollectionMappings));
				setShopifyCollectionsPanelIsOpen(false);
			}, err => {
				console.error(err);
				systemServices.showError('Error while saving Shopify mapping');
			}
		);
	}

	function getMappedRankingRuleId(collectionId) {
		if (collectionId in shopifyCollectionMappings) {
			return shopifyCollectionMappings[collectionId];
		}
		return '';
	}
	function updateMappings(collectionId, ruleId) {
		const tmp = {...shopifyCollectionMappings};
		tmp[collectionId] = ruleId;
		setShopifyCollectionMappings(tmp);
	}
	function updateMultipleMappings(collectionIds, ruleId) {
		const tmp = {...shopifyCollectionMappings};
		collectionIds.forEach((collectionId) => {
			tmp[collectionId] = ruleId;
		});
		setShopifyCollectionMappings(tmp);
		setSelectedCollections([]);
	}
	function updateSelectedCollections(collectionId, selected) {
		const index = selectedCollections.indexOf(collectionId);
		const isAlreadySelected = index >= 0;
		if (selected) {
			if (!isAlreadySelected) {
				const tmp = [...selectedCollections];
				tmp.push(collectionId);
				setSelectedCollections(tmp);
			}
		} else {
			if (isAlreadySelected) {
				const tmp = [...selectedCollections];
				tmp.splice(index, 1);
				setSelectedCollections(tmp);
			}
		}
	}

	const confirmExit = () => <Confirm
		title={'Exit without saving?'}
		text={'Are you sure you want to exit without saving?'}
		confirmCallback={() => confirmExitEditingShopifyCollectionRanking(true)}
		confirmColor='alert'
		confirmText={'Exit'}
		cancelText={'Stay'}
		onClose={() => confirmExitEditingShopifyCollectionRanking(false)}
		isOpen={!!askConfirmExitEditingShopifyCollectionRanking}
		width={470}
	/>;


	const shopifyEditionControls = () => (<>
		{confirmExit()}
		{hasShopifyCollectionRanking &&
			<Btn
				message="Shopify mapping"
				onClick={() => enterEditingShopifyCollectionRanking()}
				style="ghost"
				color="secondary"
				light={true}
				icon="fas fa-cog fa-sm"
			/>
		}
	</>);

	const shopifyCollectionsPanel = () => (
		<Panel isOpen={shopifyCollectionsPanelIsOpen} width={800}>
			{selectedCollections.length > 0 &&
				<PanelHeader
					color='primary'
					onClose={() => setSelectedCollections([])}
					title={selectedCollections.length === 1 ?
						<>{selectedCollections.length} selected collection</>
						:
						<>{selectedCollections.length} selected collections</>
					}
					primaryAction={
						<SelectDropdown
							optionsList={optionsList}
							placeHolder='Apply a ranking rule'
							onChange={(ruleId) => updateMultipleMappings(selectedCollections, ruleId)}
							color='white'
						/>
					}
				/>
			}
			{selectedCollections.length === 0 &&
				<PanelHeader
					onClose={exitEditingShopifyCollectionRanking}
					title="Shopify collection mapping"
					primaryAction={
						<Btn
							message="Save"
							onClick={saveMappings}
						/>
					}
				/>
			}
			<PanelBody color='grey'>
				<Article>
					<List size='s' removeVerticalMargin={true}>
						<ListItem>
							<div className='flex'>
								<div className='flex_item_full'>
									<div className='fw_medium'>Default choice</div>
									<div className='grey_2 s_13'>Applies to all collections without an associated rule.</div>
								</div>
								<div className='flex_item_fix ml_20' style={{width: 330}}>
									<SelectDropdown
										value={getMappedRankingRuleId('*')}
										optionsList={optionsListForAll}
										placeHolder='Apply a ranking rule'
										onChange={(ruleId) => updateMappings('*', ruleId)}
										disabled={selectedCollections.length > 0}
										appearance="ghost"
									/>
								</div>
							</div>
						</ListItem>
					</List>
				</Article>
				<Article title="Shopify collections">
					{availableShopifyCollections.length === 0 &&
						<EmptyState
							title="No collections found"
							titleBold={false}
						/>
					}
					{availableShopifyCollections.length > 0 &&
						<List size='s' removeVerticalMargin={true}>
							{availableShopifyCollections.map((collection) =>
								<ListItem key={collection.id}>
									<div className='flex'>
										<div className='flex_item_fix mr_10'>
											<Checkbox
												forcedChecked={selectedCollections && selectedCollections.indexOf(collection.id) >= 0}
												onChange={(e) => updateSelectedCollections(collection.id, e.target.checked)}
											/>
										</div>
										<div className='flex_item_full fw_medium'>
											{collection.title}
										</div>
										<div className='flex_item_fix ml_20' style={{width: 330}}>
											<SelectDropdown
												value={getMappedRankingRuleId(collection.id)}
												optionsList={optionsList}
												onChange={(ruleId) => updateMappings(collection.id, ruleId)}
												disabled={selectedCollections.length > 0}
												appearance="ghost"
											/>
										</div>
									</div>
								</ListItem>
							)}
						</List>
					}
				</Article>
			</PanelBody>
		</Panel>
	);

	return {
		shopifyEditionControls,
		shopifyCollectionsPanel,
		setShopifyCollectionsPanelIsOpen
	};
}
