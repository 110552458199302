import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import Btn from '../../Components/Btn';
import { useTriggerContext } from './TriggerContext';
import TargetingRulePanel from './TargetingRulePanel';
import SegmentCardCondition from './SegmentCardCondition';
import { getTriggerIcon, getTriggerLabel, getTriggerOperand, getTriggerValue } from './Utils';
import RenameFavoriteRuleModal from './RenameFavoriteRuleModal';
import Confirm from '../../Components/Confirm';
import {useTranslation} from 'react-i18next';
import { Flag } from '../../Components/Flag/Flag';

export default function TargetingRuleCard({
	targetingRule,
}) {
	console.log("=>(TargetingRuleCard.js:15) targetingRule", targetingRule);
	const [t, i18n] = useTranslation('common');
	const {
		deleteTargetingRule,
		saveTargetingRule
	} = useTriggerContext();

	const [targetingRulePanelIsOpen, setTargetingRulePanelIsOpen] = useState(false);
	const [displayDevices, setDisplayDevices] = useState();
	const [displayTriggerEvent, setDisplayTriggerEvent] = useState();
	const [displayTriggerDelay, setDisplayTriggerDelay] = useState();
	const [canAddToFavorite, setCanAddToFavorite] = useState(false);
	const [modalDeleteRuleIsOpen, setModalDeleteRuleIsOpen] = useState(false);
	const [modalRenameFavoriteIsOpen, setModalRenameFavoriteIsOpen] = useState(false);

	const getEventFriendlyName = (triggerEvent) => {
		if (triggerEvent) {
			if (triggerEvent.Label === 'Exit intent') return 'On exit-intent';
			if (triggerEvent.Label === 'Percentage of scroll') return 'After scrolling ' + triggerEvent.ValueToCompare + '%';
		}
		return 'On page load';
	};

	useEffect(() => {
		if (targetingRule) {
			const devices = targetingRule.triggers.deviceTrigger.ValueToCompare;
			setDisplayDevices(devices.length === 3 ? 'all' : devices.join(' and '));

			const triggerEvent = targetingRule.triggers.eventTrigger;
			setDisplayTriggerEvent(getEventFriendlyName(triggerEvent));

			const triggerDelay = targetingRule.triggers.delayTrigger;
			setDisplayTriggerDelay(triggerDelay ? triggerDelay.ValueToCompare : '0');

			setCanAddToFavorite(!targetingRule.refFavorite);
		}
	}, [targetingRule]);

	return (
		<>
			<div className='segment_card_outer'>
				<div className='segment_section segment_card segment_card_active'>
					<ReactTooltip
						backgroundColor="black"
						effect="solid"
						place="bottom"
						globalEventOff="click"
						delayShow={600}
					/>

					{targetingRule.name &&
						<div className='segment_card_header flex'>
							<div className='segment_card_name flex_item_auto ellips'>{targetingRule.name}</div>
							{targetingRule.mostPopular &&
								<Flag
									icon="ai"
									text="AI-powered"
									tooltip="This feature is powered by BEYABLE AI"
									className="ml_8"
								/>
							}
						</div>
					}

					<div className='segment_card_body'>
						<SegmentCardCondition
							label={ t('segment.wichDevices')}
							value={displayDevices}
							icon={'device'}
						/>

						<SegmentCardCondition
							label={ t('segment.When')}
							value={displayTriggerEvent}
							icon={'behavior'}
						/>

						{targetingRule.triggers.delayTrigger && targetingRule.triggers.delayTrigger.ValueToCompare !== 0 &&
							<SegmentCardCondition
								label={t('segment.Delay')}
								value={displayTriggerDelay + ' sec'}
								icon={'delay'}
							/>
						}

						{targetingRule.triggers.condition.length > 0 && targetingRule.triggers.condition.map((trigger, i) => {
							return <SegmentCardCondition
								label={getTriggerLabel(trigger.Type, trigger.Label, trigger.ValueToCompare, trigger.Name)}
								value={getTriggerValue(trigger.Type, trigger.ValueToCompare, trigger.Name)}
								icon={getTriggerIcon(trigger.Categorie)}
								operand={getTriggerOperand(trigger)}
								key={i}
								tooltip={trigger.Tooltip}
							/>;
						})}

						<div className='segment_card_footer'>
							<div className='segment_card_footer_col_left'>
								<Btn
									color="secondary"
									style="ghost"
									light={true}
									onClick={() => setModalRenameFavoriteIsOpen(true)}
									message={t('segment.saveAsFavorite')}
									icon="fas fa-star"
									size="s"
									horizontalSize="xxs"
									disabled={targetingRule.refFavorite}
									dataTip={!canAddToFavorite ? t('segment.alreadySave') : ''}
								/>
							</div>
							<div className='segment_card_footer_col_right'>
								<Btn
									color="primary"
									style="reverse"
									onClick={() => setTargetingRulePanelIsOpen(true)}
									message={t('segment.Edit')}
									icon="fas fa-pen"
									size="s"
								/>
							</div>
						</div>
					</div>
				</div>
				<button className='segment_card_del'
					onClick={() => targetingRule.refFavorite || targetingRule.refSegment ?
						deleteTargetingRule(targetingRule.id) : setModalDeleteRuleIsOpen(true)}
					data-tip={t('segment.removeSegment')}>
					<i className='fas fa-trash'></i>
				</button>
			</div>
			{targetingRulePanelIsOpen &&
				<TargetingRulePanel
					panelIsOpen={targetingRulePanelIsOpen}
					setPanelIsOpen={setTargetingRulePanelIsOpen}
					isEditingExistingRule={true}
					ruleId={targetingRule.id}
				/>
			}

			<Confirm
				isOpen={modalDeleteRuleIsOpen}
				onClose={(e) => setModalDeleteRuleIsOpen(false)}
				title={t('segment.deleteSegment')}
				text={t('segment.sureToDelete')}
				confirmText={t('segment.delete')}
				confirmCallback={(e) => deleteTargetingRule(targetingRule.id)}
			/>

			{modalRenameFavoriteIsOpen &&
				<RenameFavoriteRuleModal
					rule={targetingRule}
					isEditingExistingRule={false}
					modalIsOpen={modalRenameFavoriteIsOpen}
					setModalIsOpen={setModalRenameFavoriteIsOpen}
				/>
			}
		</>
	);
}