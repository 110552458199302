
import React, { Component, useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styles from './FormCustomPanel.module.css';
import controlTypes from './controlTypes';
import { reorderItems } from './FormCustomUtils';
import FormCustomPanel from './FormCustomPanel';
import { CampaignsContext } from '../../Campaigns_React/context/index';
import Btn from '../../../Components/Btn';
const isEmptyForm = function (formValue) {
	var hasNoSection = function (form) { return form.sections.length === 0; };
	var hasSingleEmptySection = function (form) { return form.sections.length === 1 && form.sections[0].panels.length === 0; };
	var hasSingleNotSetSection = function (form) { return form.sections.length === 1 && form.sections[0].panels.length === 1 && form.sections[0].panels[0].type === ''; };

	return (!formValue.sections) || hasNoSection(formValue) || hasSingleEmptySection(formValue) || hasSingleNotSetSection(formValue);
};


export default function FormCustomContainerHook({setFormData,ref}) {
	const {
		variations,
		getCurrentVariation,
	} = CampaignsContext();
	const [data, setData] = useState();
	const [state, setState] = useState();

	function getNextSectionId(sectionId) {
		const currentSectionIndex = state.sections.findIndex(section => section.id === sectionId);
		const nextSection = state.sections[currentSectionIndex + 1];

		return nextSection ? nextSection.id : null;
	}

	function addSection() {
		const id = Math.round(Math.random() * 10000);

		setState(state => {
			const sections = [...state.sections, {
				id,
				canAddPanel: true,
				panels: []
			}];
			return { sections };
		});
	}
	function removeSection(sectionId) {
		setState(state => {
			const sections = state.sections.filter(section => sectionId !== section.id);
			return { sections };
		});
	}

	function canAddButtonType(sectionId) {
		const currentSection = state.sections.find(section => section.id === sectionId);
		const panelsInSection = currentSection.panels.filter(panel => panel.type !== '').length;
		const panelWithButton = currentSection.panels.find(panel =>
			panel.type === 3 ||
			(panel.controlTypes.find(controlType => controlType.id === 3) && !panel.type)
		);

		return panelsInSection && !panelWithButton;
	}
	function addPanel(sectionId) {
		setState(state => {
			const sections = state.sections.map(section => {
				if (section.id === sectionId) {
					const panels = [...section.panels, {
						id: Math.round(Math.random() * 10000),
						name: '',
						type: '',
						question: '',
						placeholder: '',
						controls: [],
						required: false,
						controlTypes: [
							...controlTypes,
							...(canAddButtonType(sectionId) ? [{
								id: 3,
								name: 'button',
								icon: 'square',
								color: 4,
								multiple: false,
								section: 'Various fields'
							}] : [])
						],
						value: '',

					}];

					const hasTypeSelectionOpened = panels.find(panel => panel.type === '');

					return { id: section.id, canAddPanel: !hasTypeSelectionOpened, panels };
				}

				return section;
			});

			return { sections };
		});
	}

	function removePanel(id, sectionId) {
		setState(state => {
			let sections = state.sections.map(section => {
				if (section.id === sectionId) {
					const panels = section.panels.filter(panel => id !== panel.id);
					const hasTypeSelectionOpened = panels.find(panel => panel.type === '');

					return { id: section.id, canAddPanel: !hasTypeSelectionOpened, panels };
				}
				return section;
			});

			return { sections };
		}, () => {
			const currentSection = state.sections.find(section => section.id === sectionId);

			// Remove section if empty
			if (currentSection.panels.length < 1 && state.sections.length > 1) {
				removeSection(sectionId);
			}
		});
	}

	function duplicatePanel(id, sectionId) {
		setState(state => {
			const sections = state.sections.map(section => {
				if (section.id === sectionId) {
					const isDuplicatedPanel = panel => panel.id === id;
					const duplicatedPanel = section.panels.find(isDuplicatedPanel);
					const duplicatedPanelIndex = section.panels.findIndex(isDuplicatedPanel);
					let panels = section.panels;

					panels.splice(duplicatedPanelIndex + 1, 0, {
						id: Math.round(Math.random() * 10000),
						type: duplicatedPanel.type,
						question: duplicatedPanel.question,
						placeholder: duplicatedPanel.placeholder,
						controls: duplicatedPanel.controls,
						required: duplicatedPanel.required,
						controlTypes: duplicatedPanel.controlTypes,
						buttonType: duplicatedPanel.buttonType,
						name: duplicatedPanel.name,
						value: duplicatedPanel.value,

					});

					return { id: section.id, canAddPanel: section.canAddPanel, panels };
				}
				return section;
			});

			return { sections };
		});
	}

	function updatePanel(id, sectionId, stateToUpdate) {
		setState(state => {
			const sections = state.sections.map(section => {
				if (section.id === sectionId) {
					const panels = section.panels.map(panel => {
						if (panel.id === id) {
							if(stateToUpdate.type === 3){
								if(!panel.hasOwnProperty('buttonType')){
									panel['buttonType'] = 'submit';
								}
							}

							return { ...panel, ...stateToUpdate };
						}
						return panel;
					});

					const hasTypeSelectionOpened = panels.find(panel => panel.type === '');
					return { id: section.id, canAddPanel: !hasTypeSelectionOpened, panels };
				}
				return section;
			});

			return { sections };
		});
	}

	function onDragEnd(result, sectionId) {
		if (!result.destination) {
			return;
		}

		const sections = state.sections.map(section => {
			if (section.id === sectionId) {
				const panels = reorderItems(
					section.panels,
					result.source.index,
					result.destination.index
				);
				return { id: section.id, panels, canAddPanel: section.canAddPanel };
			}
			return section;
		});
		setState({ sections });
	}


	function onLoad(dataToCheck) {
		var parsePropertyValue = function (properties) {
			const form = properties.find(el => el.Name === 'FormCustom');
			if (properties && form) {
				return JSON.parse(form.Value);
			}
			else return {};
		};

		const slideFormValue = parsePropertyValue(dataToCheck.Properties);
		var formValue = isEmptyForm(slideFormValue)
			? { sections: [{ id: 1, canAddPanel: true, panels: [] }] }
			: {
				sections: slideFormValue.sections.map(function (section) {
					return {
						id: section.id,
						canAddPanel: true,
						panels: section.panels.map(function (panel) {
							return {
								id: panel.id,
								name: panel.name,
								type: panel.type,
								question: panel.question,
								placeholder: panel.placeholder,
								controls: panel.controls,
								required: panel.required,
								controlTypes: controlTypes,
								buttonType: panel.buttonType,
								value: panel.value
							};
						})
					};
				})
			};

		setState({ slide: dataToCheck });
		setState(formValue);
	}
	useEffect(()=>{
		const currentVariationSaved = getCurrentVariation();
		setData(currentVariationSaved);
	},[variations]);
	
	function updateFormCustomData(){
		const sections = state.sections.map(section => {
			return {
				id: section.id,
				panels: section.panels.filter(function (p) { return p.type !== ''; }).map(function (panel) {
					return {
						id: panel.id,
						name: panel.name,
						type: panel.type,
						question: panel.question,
						placeholder: panel.placeholder,
						controls: panel.controls,
						required: panel.required,
						buttonType: panel.buttonType,
						value: panel.value
					};
				})
			};
		}).filter(function (s) { return s.panels.length > 0; });
		setFormData(JSON.stringify({ sections: sections })
		);
			
	}
	useEffect(()=>{
		if(data){
			onLoad(data);
		}
	},[data]);
	useEffect(()=>{
		if(state){
			updateFormCustomData();
		}
	},[state]);
	

	return (
		<div ref={ref}>
			{state && state.sections.map((section, i) => {
				return <div key={section.id} className={styles.section}>
					{state.sections.length > 1 &&
						<div className="h3 mb_10">Section {i + 1}</div>
					}
					<DragDropContext onDragEnd={(result) => onDragEnd(result, section.id)}>
						<Droppable droppableId={`droppable-${section.id}`}>
							{
								(provided, snapshot) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}>
										{section.panels.map((panel, j) =>
											<FormCustomPanel
												{...panel}
												key={panel.id}
												index={j}
												sectionId={section.id}
												sections={{
													current: section.id,
													next: getNextSectionId(section.id)
												}}
												displayRequired={panel.type !== 3}
												styles={styles}
												onSectionAdd={addSection}
												onSectionRemove={removeSection}
												onContentChange={updatePanel}
												onRemove={removePanel}
												onDuplicate={duplicatePanel}
											/>
										)}
										{provided.placeholder}
									</div>
								)}
						</Droppable>
					</DragDropContext>
					<Btn
						icon="fas fa-plus-circle"
						message="Add a field"
						onClick={() => addPanel(section.id)}
						disabled={!section.canAddPanel}
						style="outline"
						fullWidth={true}
						size="l"
					/>
				</div>}
			)}

		</div>
	);
}

