import React from 'react';
import { react2angular } from 'react2angular';
import newInstanceI18next from '../../Util/traductionUtils';
import { I18nextProvider } from 'react-i18next';
import Navigation from './Navigation';

export default function Menu(props) {
	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<Navigation props={{...props}} />
		</I18nextProvider>
	);
}
angular
	.module('beyableSaasApp.Menu', [])
	.component(
		'menu',
		react2angular(
			Menu,
			[],
			['$http', '$rootScope', '$timeout', '$routeParams', '$location', '$scope', 'AuthServices']
		)
	);
