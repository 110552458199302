import React, { useRef, useState, useEffect } from 'react';
import options from './options';
import { ControlledEditor } from '@monaco-editor/react';
import './PatchIde.css';

export default function PatchIdeEditor(props) {

	const [isEditorReady, setIsEditorReady] = useState(false);
	const valueGetter = useRef();
	let resizeTimeout;

	function handleEditorDidMount(event) {
		valueGetter.current = event;
		setIsEditorReady(true);
	}

	function windowResize() {
		clearTimeout(resizeTimeout);
		resizeTimeout = setTimeout(restartEditor, 200);
	}

	function restartEditor() {
		setIsEditorReady(false);
		setTimeout(() => setIsEditorReady(true), 1);
	}

	useEffect(() => {
		window.addEventListener('resize', windowResize);
		restartEditor();
		return function () {
			window.removeEventListener('resize', windowResize);
		};
	}, []);
    
	return (
		<>
			{isEditorReady &&
                <ControlledEditor
                	width="100%"
                	theme="dark"
                	language={props.language}
                	loading={
                		<span className="mask">
                			<span className="wheel"></span>
                		</span>
                	}
                	value={props.value && props.value}
                	onChange={props.changeValue}
                	editorDidMount={handleEditorDidMount}
                	options={options}
                />
			}
		</>
	);
}
