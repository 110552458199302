import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import styles from '../Board/Board.module.css';

import { useEcommerceTransactionContext } from '../../context/EcommerceTransactionContext';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import PaginationBoard from './PaginationBoardPerformance';
import AnalitycsBoardTransaction from './AnalitycsBoardTransaction';
import {
	Revenue,
	Quantity,
	SessionToSaleRatio,
	TransactionCount,
	TransactionAverageAmount,
	AverageAmount,
	TransactionAmount,
	AverageQuantityInTransactions,
	TaxAmount,
	ShippingAmount,
	EventDate
} from './config';
export default function TransactionBoardContainer  ({ services,dimension,systemServices }) {

	const { selectedTenant } = useAnalyticsContext();
	const {
		navFilter,
		searchValue,
		sourceType,
		orderBy,
		setOrderBy,
		orderByDesc,
		setOrderByDesc,
		filterUrl,
		offset, 
		setOffset,
		currentPage,
		setCurrentPage,
		dimensionId,
		deviceSelected,
		device,
		handleChangeDeviceSelected,
		utmDimensionsSelected
	} = useEcommerceTransactionContext();

	const [afterRender,setAfterRender] = useState(false);
	const [currentMetrix, setCurrentMetrix] = useState({
		data: null,
		isLoading: true,
		isError: false,
		isErrorEcommerce : false
	});
	const [numberOfData, setNumberOfData] = useState(null);
	const [tableToRender, setTableToRender] = useState(<></>);
	const [pageSize, setpageSize] = useState(10);
	const [urlSelected, setUrlSelected] = useState();

	const getSumDate = () => {
		if (navFilter?.fromDate && navFilter?.toDate) {
			return `${navFilter?.fromDate} to ${navFilter?.toDate}`;
		}
		else if (navFilter?.fromDate) {
			return `${navFilter?.fromDate}`;
		}
	};
	useEffect(() => {
		setOffset(0);
	}, [dimension]);

	useEffect(() => {
		setAfterRender(true);
		if(offset !== 0){
			setOffset(0);
		}      
		else if (afterRender) {
			refreshBoard();
		}   
	}, [selectedTenant]);

	useEffect(() => {
		if (!dimensionId) {
			setUrlSelected();
		}
	}, [dimensionId]);


	const refreshBoard = () => {
		setCurrentMetrix((current) => ({
			...current,
			data: null,
			isLoading: true
		}));
		const options = {
			...navFilter,
			metrics: [
				Revenue,
				TaxAmount,
				ShippingAmount,
				Quantity,
				EventDate
			],
			orderBy: orderBy,
			orderByDesc: orderByDesc === 'desc' ? true : false,
			timeGranularity: 'None',
			trafficSource : sourceType,
			dimension : 'transaction',
			// dimensionId: dimensionId,
			limit: pageSize,
			offset : offset,
			device : deviceSelected,
			utmDimensionsSelected: utmDimensionsSelected
		};
		
		services.getDimensionsOverview(
			options,
			(data, headers) => {
				const optionsForSums = {
					...navFilter,
					metrics: [
						Revenue,
						TaxAmount,
						ShippingAmount,
						Quantity,
					],
					utmDimensionsSelected: utmDimensionsSelected,
					timeGranularity: 'None',
					trafficSource : sourceType,
					dimension : 'transaction',
					device : deviceSelected
				};
				let dataSums = [];
				services.getMetricsSums(optionsForSums,
					(dataSumsMetrics, headers) => {
						dataSums = dataSumsMetrics?.metrics;
						renderTable(dataFormatted,dataSums);
					},
					(err,typeError) => {
						const statusErr = typeError.status;
						setCurrentMetrix((current) => ({ 
							...current, 
							isErrorEcommerce : statusErr === 404
						}));
						renderTable(dataFormatted,dataSums);
						if(!statusErr){
							systemServices.showError('An error occured');
						}
					}
				);
				const dataFormatted = data.dimensionValues;
				if(numberOfData!==parseInt(headers('x-total-count'))){
					setNumberOfData(parseInt(headers('x-total-count')));}
				setCurrentMetrix(() => ({
					data: dataFormatted,
					isLoding: false,
					isError: false,
				}));
			},
       
        
			(err,typeError) => {
				console.log(err);
				const statusErr = typeError.status;
				if(!statusErr){
					systemServices.showError('An error occured');
				}
				setCurrentMetrix((current) => ({
					...current,
					isLoding: false,
					isError: true,
					isErrorEcommerce : statusErr === 404
				}));
			});
	};
	useEffect(() => {
		refreshBoard();
	}, [
		navFilter,
		orderBy,
		orderByDesc,
		offset,
		pageSize,
		searchValue,
		sourceType,
		dimension,
		currentPage,
		deviceSelected,
		utmDimensionsSelected
	]);

	const handleSort = (orderField, order) => {
		setOffset(0);
		setOrderByDesc(order);
		setOrderBy(orderField);
		setOffset(0);
		setCurrentPage(1);
	};

	function renderTable(data,dataSumsMetrics) {
		setTableToRender(
			<AnalitycsBoardTransaction
				resultName={'Transaction ID'}
				revuenue="Revenue"
				tax="Tax"
				quantity="Quantity"
				shipping="Shipping"
				refund="Refund"
				date="Date"
				sumOfDate={getSumDate()}
				data={data}
				orderBy={orderBy}
				orderByDesc={orderByDesc}
				handleSort={handleSort}
				dataSumsMetrics={dataSumsMetrics}
			/>
		);
	}

	return (
		<section className={styles.section}>
			{currentMetrix.isLoading && !currentMetrix.isError && !currentMetrix.isErrorEcommerce && (
				<div className="mask">
					<span className="wheel"></span>
				</div>
			)}
			{!currentMetrix.isLoading && currentMetrix.isError && !currentMetrix.isErrorEcommerce && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_error.svg"
					/>
					<div className="empty_state_title">Cannot fetch data</div>
					<div className="empty_state_text">Please retry later.</div>
				</div>
			)}
			{!currentMetrix.isLoading &&
                !currentMetrix.isError && !currentMetrix.isErrorEcommerce &&
                currentMetrix.data.length == 0 && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_empty.svg"
					/>
					<div className="empty_state_title">
                            No data for this period
					</div>
					<div className="empty_state_text">
                            Maybe try with another period or other filters
					</div>
				</div>
			)}
			{
				currentMetrix.isErrorEcommerce && (
					<div className="empty_state analytics_empty_state">
						<img
							className="empty_state_img"
							src="/Assets/analytics_data_error.svg"
						/>
						<div className="empty_state_title">
						No ecommerce data 
						</div>
						<div className="empty_state_text">
					Check your setup.
						</div>
					</div>
				)}

			{!currentMetrix.isLoading && !currentMetrix.isErrorEcommerce &&
                !currentMetrix.isError &&
                currentMetrix.data &&
                currentMetrix.data.length > 0 && (
				<>
					<div className="analytics_table_wrapper">
						{tableToRender}
					</div>
					<PaginationBoard
						totalCount={numberOfData}
						pageSize={pageSize}
						handleChangePageSize={(e) => {
							setpageSize(e);
							setOffset(0);
						}}
						paginate={(e) => setOffset(e >= 0 ? e : 0)}
						skipIdNumber={offset}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				</>
			)}
		</section>
	);
}
