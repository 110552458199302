import { react2angular } from 'react2angular';
import React, { useEffect, useState } from 'react';
import TriggersContainer from './TriggersContainer';
import ImpersonatingServices from '../../../Services/ImpersonatingServices'

export default function TriggersContainerBridge(props) {

    const referenceDataTriggers = props.UtilCampaignsServices.GetTriggers();
    const referenceDataTriggerOperands = props.UtilCampaignsServices.GetTriggerOperands();
    const saveTriggersCallback = props.saveTriggersCallback;
    const saveTrackingModeCallback = props.saveTrackingmodeCallback;
    const setUpSpecificationsForLoading = props.UtilCampaignsServices.SetUpSpecificationsForLoading;
    const setUpSpecificationsForSaving = props.UtilCampaignsServices.SetUpSpecificationsForSaving;
    const userRole = props.$rootScope.User.Role;
    const $http = props.$http;
    const $timeout = props.$timeout;
    const $rootScope = props.$rootScope;
    const campaign = props.campaign;
    const $routeParams = props.$routeParams;
    const isNativeApp = props.isNativeApp ?? false;

    const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
    const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
    const accountId = impersonatedAccount || props.$rootScope && props.$rootScope.User.Account.Key;

    const permissions = props.$rootScope.$$childHead.permissions
    const isAdmin = props.$rootScope.$$childHead.IsAnAdmin();
    const userRoleAndPermissions = { permissions, isAdmin }
    const isCreation = !$routeParams["key"]

    const [initialTriggers, setInitialExistingTriggers] = useState({isInitialized: false, triggers:[], shouldLoadDefault: false})
    const [trackingMode, setTrackingMode] = useState()

    useEffect(() => {
        
        if (campaign != {} && campaign.Specifications) {
            const distinctList = campaign.Specifications.reduce((acc, item) => acc.find(x =>
                (
                    x.Name === 'TRGCHECKDEVICE' ||
                    x.Name === 'TRGMARKPRESSURE' ||
                    x.Name === 'TRGSCROLL' ||
                    x.Name === 'TRGEXITINTENT'
                ) &&
                x.Name === item.Name &&
                ((x.Group || {}).Id === (item.Group || {}).Id)
            ) ? acc : [...acc, item], [])
            setInitialExistingTriggers({isInitialized: true, triggers:distinctList, shouldLoadDefault: campaign.Specifications.length === 0})
            setTrackingMode(campaign.TrackingMode)
        }
    }, [campaign])

    if (campaign.Specifications) {
        return (
            <>
                {((!campaign.EditMode) || (campaign.EditMode === "C")) && // (!campaign.EditMode) -> case where the campaign is in edit mode but has no triggers (campaign.Specifications)
                <>
                        <TriggersContainer
                            saveTriggersCallback={saveTriggersCallback}
                            saveTrackingModeCallback={saveTrackingModeCallback}
                            dataTriggers={referenceDataTriggers}
                            dataTriggerOperands={referenceDataTriggerOperands}
                            accountId={accountId}
                            isNativeApp={isNativeApp}
                            setUpSpecificationsForLoading={setUpSpecificationsForLoading}
                            setUpSpecificationsForSaving={setUpSpecificationsForSaving}
                            userRoleAndPermissions={userRoleAndPermissions}
                            initialTriggers={initialTriggers}
                            initialTrackingMode={trackingMode}
                            $http={$http}
                            $timeout={$timeout}
                            $rootScope={$rootScope}
                            $routeParams={$routeParams}
                            includeDeviceSelector={true}
                            isCreation={isCreation}
                        >
                        </TriggersContainer>
                </>
                }
            </>
        )
    }
    else {
        return null
    }
}


angular
    .module('beyableSaasApp.Campaigns.Create.Controllers',)
    .component('triggers', react2angular(TriggersContainerBridge, ['campaign', 'saveTrackingmodeCallback', 'saveTriggersCallback'],
        ['$rootScope', '$scope', '$http', '$routeParams', '$timeout', 'UtilCampaignsServices']));