import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Dropdown from '../../../../Components/Dropdown';
import InputCustom from '../../../../Components/InputCustom';

export default function AutocompleteFilter({ setupAccountServices, productTagServices = null, currentAccount, referenceDataType, value, onChange, tenanSelected,previewTenant ,needTenantForSearch}) {

	const refInput = useRef();
	const idDropDown = uuidv4();
	const [list, setList] = useState([]);
	const [filteredSuggestions, setfilteredSuggestions] = useState([]);
	const [activeSuggestion, setactiveSuggestions] = useState();
	const [showSuggestions, setshowSuggestions] = useState();
	const [userInput, setuserInput] = useState('');
	const [valueTagSearch, setvalueTagSearch] = useState('');
	const [focused, setFocused] = useState(false);
	const [autocompleteValue, setAutocompleteValue] = useState();

	function checkIfNeedTenantPreviewOrTenantCatalog(){
		// Si tenant context === All alors mettre tenant preview
		// Si c’est un precis c’est lui
		// Si all dans les 2 appeler sans tenant
		// si mono tenant => tenant
		if(needTenantForSearch){
			if(tenanSelected === null ||tenanSelected=== 'All' ){
				if(previewTenant && previewTenant.value === 'all'){
					return null;
				}else if(previewTenant &&previewTenant.value !== 'all' ){
					return previewTenant.value;
				}
			}else{
				return tenanSelected;
			}
		}else{
			return null;
		}
	}

	const [inputValue, setInputValue] = useState();

	function sortArray(a, b) {
		if (a < b) return -1;
		if (a > b) return 1;
		return 0;
	}
	const getListOfSpecificities = () => {
		setupAccountServices.getAccountReferenceData(currentAccount,
			referenceDataType,
			autocompleteValue,
			checkIfNeedTenantPreviewOrTenantCatalog(),
			newData => {
				setList([...newData.sort(sortArray)]);
				handleOnChange(autocompleteValue, [...newData.sort(sortArray)]);
			});
	};


	useEffect(() => {
		// fetch data for suggestions
		const timer = setTimeout(() => {
			if(autocompleteValue && autocompleteValue.length !== 0){
				getListOfSpecificities(referenceDataType);
			}
			
		}, 200);
		return () => {
			clearTimeout(timer);
		};


	}, [autocompleteValue]);



	const handleAutocompleteChange = (value) => {
		setAutocompleteValue(value);
		setuserInput(value);
	};

	const handleOnChangeTags = e => {
		setvalueTagSearch(e.target.value);
	};

	const handleOnChange = (value, newList) => {
		if (!value) return;
		filterSuggestions(value, newList);
	};

	const filterSuggestions = (search, data) => {
		// Filter our suggestions that don't contain the user's input
		let newfilteredSuggestions = data.filter(
			suggestion =>
				suggestion.toLowerCase().indexOf(search) > -1
		);
		if (newfilteredSuggestions.length === 0) {

			newfilteredSuggestions = [search];
		} else {
			newfilteredSuggestions = [search, ...newfilteredSuggestions];
		}
		setactiveSuggestions(0);
		setfilteredSuggestions(newfilteredSuggestions);
		setshowSuggestions(true);
	};


	const handleOnKeyDown = (e, val) => {
		// User pressed the enter key
		if (e.keyCode === 13 && val.length !== 0) {
			if (activeSuggestion && filteredSuggestions[activeSuggestion]) {
				addItemToValue(filteredSuggestions[activeSuggestion]);
				return;
			}

			addItemToValue(e.target.value);
			return;
		}
		// User pressed the up arrow
		else if (e.keyCode === 38) {
			if (activeSuggestion === 0) {
				return;
			}
			setactiveSuggestions(activeSuggestion - 1);
		}
		// User pressed the down arrow
		else if (e.keyCode === 40) {
			if (activeSuggestion - 1 === filteredSuggestions.length) {
				return;
			}
			setactiveSuggestions(activeSuggestion + 1);
		}
	};

	const addItemToValue = (item) => {
		let newValue = inputValue.trim();
		newValue += newValue.length ? '\n' + item : item;
		handleChange(newValue);
		resetAutocomplete();
	};

	const resetAutocomplete = () => {
		setactiveSuggestions(0);
		setshowSuggestions(false);
		setuserInput('');
		setAutocompleteValue();
		setvalueTagSearch('');
	};

	const take = (list, size) => {
		return list.slice(0, size);
	};

	const showDropDown = showSuggestions && userInput || showSuggestions && valueTagSearch;

	useEffect(() => {
		if (focused && refInput) {
			function handleClickOutside(event) {
				if (!event) {
					return;
				}
				if (event.target && event.target.id && event.target.id === idDropDown) {
					return;
				}
				if (refInput.current && !refInput.current.contains(event.target)) {
					event.stopPropagation();
					setFocused(false);
					resetAutocomplete();
				}
			}
			document.addEventListener('click', handleClickOutside);

			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		}
	}, [refInput, focused]);

	useEffect(() => {
		setInputValue(value.replace(/;/g, '\n'));
	}, []);

	const handleChange = (value) => {
		onChange && onChange(value);
		setInputValue(value);
	};

	return (
		<>
			<div className="custom_input w_full">
				<i className="fas fa-search custom_input_icon_before"></i>
				<input
					ref={refInput}
					autoComplete={'off'}
					placeholder="Find a value or manually edit the next field"
					onFocus={() => setFocused(true)}
					type="text"
					value={referenceDataType === 'pagetag' ? valueTagSearch : userInput}
					onChange={referenceDataType === 'pagetag' ? handleOnChangeTags : e => handleAutocompleteChange(e.target.value)}
					onKeyDown={(e) => handleOnKeyDown(e, referenceDataType === 'pagetag' ? valueTagSearch : userInput)}
					name="userValue"
				/>
			</div>

			{showDropDown &&
				<Dropdown isOpen={true} buttonRef={refInput}>
					<ul className="listbox">
						{filteredSuggestions.length ?
							<>
								{take(filteredSuggestions, 20).map((suggestion, index) => {
									let className = ['listbox_item'];
									// Flag the active suggestion with a class
									if (index === activeSuggestion) {
										className.push('selected');
									}
									return (
										<li key={suggestion}>
											<a className={className.join(' ')} onClick={() => addItemToValue(suggestion)}>
												<i className="fas fa-plus listbox_item_icon"></i>
												{suggestion}
											</a>
										</li>
									);
								})}
							</>
							:
							<li>
								<a className="listbox_item disabled">
									This value is already selected
								</a>
							</li>
						}
					</ul>
				</Dropdown>
			}
			<div className="mt_8">
				<InputCustom
					type="textarea"
					value={inputValue}
					fullWidth={true}
					autogrow={true}
					maxHeight={500}
					onChange={(e) => handleChange(e.target.value)}
				/>
			</div>
		</>
	);
}
