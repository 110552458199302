import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
import { nFormat, toReadableDuration } from '../../util';

const SessionsBarChart = ({
	data = { datasets: [], labels: [], type: 'bar', title: '', isMultipleDimensions: false }, typeChart, isTime = false
}) => {

	const arrayOfLabel = data.comparedPeriode && data.comparedPeriode.map(ele =>
		({ ...ele }));

	const columnChartOptions = {
		
		exporting: {
			showTable: false,
			tableCaption: 'Data table',
			csv: {

				columnHeaderFormatter: function(item, key) {
					if (!item || item instanceof Highcharts.Axis) {
						return 'Date';
					} else {
						return item.name;
					}
				},
				dateFormat: '%Y-%m-%d'
			},
			buttons: {
				contextButton: {
					menuItems: [
						'printChart',
						'separator',
						'downloadPNG',
						'downloadJPEG',
						'downloadPDF',
						'downloadSVG',
						'downloadCSV'
					]
				}
			}
		},
		chart: {
			height: 410,
			type: typeChart,
		},
		navigation: {
			buttonOptions: {
				verticalAlign: 'top',
				x: -50,
			}
		},
		title: {
			text: ''
		},
		legend: {
			symbolRadius: 0,
			symbolWidth: 40,
			itemStyle: {
				fontFamily: 'Roboto',
				fontWeight: 'Light',
				left: '100px',
				top: '100px',
			},
			borderRadius: 3,
			align: 'center',
			verticalAlign: 'bottom',
		},
		credits: {
			enabled: false
		},
		xAxis: isTime ? {
			// gridLineWidth: 0,
			lineColor: 'black',
			lineWidth: 1,
			// categories: data.title,
			// tickLength: 10,
			// tickWidth: 1,
		} :
			{
				gridLineWidth: 0,
				lineColor: 'black',
				lineWidth: 1,
				categories: data.labels,
				tickLength: 10,
				tickWidth: 1,
			}
		,
		yAxis: [{ // Primary yAxis
			gridLineWidth: 0,
			labels: {
				format: '',
				style: {

					color: 'black',
				},
			},
			title: {
				text: '',
				style: {
					color: 'black',
				}
			},
			alignTicks: false,
			opposite: false,
			crosshair: true

		}],
		plotOptions: {
			column: {
				borderColor: '#9E9E9E',
				grouping: true,
				shadow: false,
				borderWidth: 0,
				stacking: data.type === 'stackedBar' ? 'normal' : null
			},
			series: {
				marker: {
					fillColor: '#FFF',
					lineWidth: 1,
					lineColor: null // inherit from series
				}
			},

		},
		opposite: true,
		tooltip: data.isCompare ?
			{
				shared: true,
				useHtml: true,
				backgroundColor: '#38444A',
				color: '#FFF',
				style: {
					color: '#FFFFFF',
				},

				formatter: function (tooltip) {
					const valueOfXForComparedPeriodLabels = data.comparedPeriode.find(d => d.labelPeriod1 === this.x);
					const NumberOfSessionsPercentVariation = () => {
						if (valueOfXForComparedPeriodLabels) {
							const _return = ` <strong> Number of page views variation </strong> : ${valueOfXForComparedPeriodLabels && valueOfXForComparedPeriodLabels.NumberOfPageViewsPercentVariation}  % <br>`;
							return _return;
						} else {
							return '';
						}
					};
					const nbSessionVariation = () => {
						if (valueOfXForComparedPeriodLabels) {
							const _return = `<strong> Number of session variation : </strong> ${valueOfXForComparedPeriodLabels.NumberOfSessionsPercentVariation} %`;
							return _return;
						} else {
							return '';
						}
					};
					const comparedVariationHeader = () => {
						if (data.datasets.length > 1) {
							const dataSetMetrics = [...new Set(data.datasets.map(metric => metric.metric))];
							if (dataSetMetrics.length > 1) {
								return NumberOfSessionsPercentVariation() + nbSessionVariation() + '<br>';
							} else {
								const metric = dataSetMetrics[0];

								if (metric === 'NumberOfPageViews') {
									return 'Number of page views variation ' + valueOfXForComparedPeriodLabels.NumberOfPageViewsPercentVariation + ' %' || 0 + ' %';
								}
								if (metric === 'NumberOfSessions') {
									return 'Number of sessions variation ' + valueOfXForComparedPeriodLabels.NumberOfSessionsPercentVariation + ' %' || 0 + ' %';
								}
								if (metric === 'AverageTimeOnPage') {
									// 				const [vDuration, sign] = sessionsNotBounced
									// ? toReadableDuration(Math.ceil(valueOfXForComparedPeriodLabels.DurationSecondsPercentVariation/ sessionsNotBounced))
									// : ["0", "second"]
									return 'Duration seconds variation ' + valueOfXForComparedPeriodLabels.DurationSecondsPercentVariation + ' %' || 0 + ' %';
								}
								if (metric === 'BounceRate') {
									return 'Number of bounces variation ' + valueOfXForComparedPeriodLabels.NumberOfBouncesPercentVariation + ' %' || 0 + ' %';
								}

							}
						}
					};

					var resultLabelComparedPeriod = `<b> Compared period </b> : ${moment(arrayOfLabel.find(label => label.labelPeriod1 === this.x).DatePeriod1).locale('en').format('MMM DD YYYY')} / ${moment(arrayOfLabel.find(label => label.labelPeriod1 === this.x).DatePeriod2).locale('en').format('MMM DD YYYY')}  <br>
					${!data.isMultipleDimensions ? comparedVariationHeader() : comparedVariationHeader()} `;
					const bodyComparedPeriod = (element) => {
						if (element.series.name.includes('2')) {
							return `<strong> ${moment(arrayOfLabel.find(label => label.labelPeriod1 === element.key).DatePeriod2).locale('en').format('MMM DD YYYY')} </strong> ${element.series.name}`;
						} else {
							return `<strong>${moment(arrayOfLabel.find(label => label.labelPeriod1 === element.key).DatePeriod1).locale('en').format('MMM DD YYYY')} </strong> ${element.series.name}`;

						}
					};
					this.points.forEach(element => {
						if (element.series.name.contains('time')) {
							const [vDuration2, sign2] = toReadableDuration(Math.ceil(element.y));
							// : ["0", "second"]
							return resultLabelComparedPeriod += '<br/><span style="color:' + element.color + '">\u25CF</span> ' + ' ' + bodyComparedPeriod(element) + ': ' + vDuration2 + sign2;
						} else {
							return resultLabelComparedPeriod += '<br/><span style="color:' + element.color + '">\u25CF</span> ' + ' ' + bodyComparedPeriod(element) + ': ' + element.y;

						}
					});

					return resultLabelComparedPeriod;
				},

			} :

			data.title.contains('time') ? {
				shared: true,
				backgroundColor: '#38444A',
				color: '#FFF',
				style: {
					color: '#FFFFFF',
				},
				formatter: function (tooltip) {
					const serie = this.points[0];
					return `<b>${serie.key}</b> ` + '<br/><span style="color:' + serie.color + '">\u25CF</span> ' + ' ' + data.title + ': ' + serie.y;
				}

			} : {
				shared: true,
				backgroundColor: '#38444A',
				color: '#FFF',
				style: {
					color: '#FFFFFF',
				},
			},
		series: data.datasets
	};

	return (
		<HighchartsReact
			className='chart'
			highcharts={Highcharts}
			options={columnChartOptions}
			constructorType={'chart'}
		/>);

};

SessionsBarChart.displayName = 'SessionsBarChart';

export default SessionsBarChart;