import React from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Fieldset from '../../Components/Fieldset';
import InputCustom from '../../Components/InputCustom';
import IconBtn from '../../Components/IconBtn';
import Btn from '../../Components/Btn';
import { reorderItems } from './FormCustomUtils';
import { v4 as uuidv4 } from 'uuid';
import styles from './FormCustomMultipleControls.module.css';

function FormCustomMultipleControlsHook(props) {

	const [t] = useTranslation(['survey']);	

	function addControl() {
		const id = uuidv4();
		const controls = [...props.controls, {
			id: id,
			text: '',
			editMode: true
		}];
		props.setControls(controls,{
			controlId : id,
			action: 'add',
			nextQuestion : 'next',
			idPanel: props.idPanel
		});

	}

	function updateControl(id, text) {
		props.onContentChange({
			controlId : id,
			text: text,
		});
	}

	function removeControl(id) {
		props.setControls(props.controls,{
			controlId : id,
			action: 'delete',
			idPanel: props.idPanel
		});
	}

	function handleControlBlur(id, text) {
		updateControl(id, text);
	}

	function onDragEnd(result) {
		if(!result.destination) {
			return;
		}

		const controls = reorderItems(
			props.controls,
			result.source.index,
			result.destination.index
		);
		props.setControls(controls);
		
	}
	return (
		<Fieldset label={t('answers')} labelClassName='s_14 grey_0'>
			<div className={styles.anwser_list}>
				<DragDropContext onDragEnd={(result) => onDragEnd(result)}>
					<Droppable droppableId="droppable-answers">
						{(provided, snapshot) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{props?.controls.map((control, i) =>
									<Draggable
										key={control.id}
										draggableId={`control-${control.id}`}
										index={i}>
										{(provided, snapshot) => (
											<div
												key={control.id}
												className={styles.anwser_row}
												ref={provided.innerRef}
												{...provided.draggableProps}>
												<span className={styles.anwser_col_nb}>
													{i+1}.
												</span>
												<span className={styles.anwser_col_input}>
													<InputCustom
														value={control.text}
														onChange={(e) => handleControlBlur(control.id, e.target.value)}
														fullWidth={true}
													/>
												</span>
												<span className={styles.anwser_col_actions}>
													<IconBtn 
														icon="far fa-trash-alt"
														onClick={()=>removeControl(control.id)}
														tooltip={t('deleteAnswer')}
													/>
													<span  {...provided.dragHandleProps}>
														<IconBtn 
															icon="fas fa-sort"
															tooltip={t('sortAnswer')}
														/>
													</span>
												</span>
											</div>
										)}
									</Draggable>
								)}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				
				<div className={styles.anwser_row}>
					<span className={styles.anwser_col_nb}>
						{props?.controls.length + 1}.
					</span>
					<span className={styles.anwser_col_input}>
						<Btn
							message={t('addAnswer')}
							onClick={()=>addControl()}
							style="reverse"
						/>
					</span>
				</div>
			</div>
		</Fieldset>
	);
}

export default FormCustomMultipleControlsHook;