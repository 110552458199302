import React from 'react';
import { useProductFeedEditionContext } from '../../context/ProductFeedEditionContext';
import ConnectionEdition from './ConnectionEdition';
import UrlProductFeedConnector from './UrlProductFeedConnector';
import editionStyles from '../ProductFeedEdition.module.css';
import ConnectionCreation from './ConnectionCreation';
import CreationContainer from '../../Feed/CreationContainer';

export default function ConnectionContainer() {
	const {
		step,
		productFeedObject,
		mode
	} = useProductFeedEditionContext();

	if(step !== 1){
		return null;
	}
	return (
		<div className={editionStyles.wrapper_outer}>
			<div className={editionStyles.wrapper_inner}>
				{mode === 'creation' &&
				<CreationContainer />
				}
				{mode === 'edition' &&
				<ConnectionEdition />
				}
			</div>
		</div>
	);
}
