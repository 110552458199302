import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const PieChart = ({
	data = { datasets: [], labels: [], type: 'bar', title: '' }
}) => {

	const pieChartOptions = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: 0,
			plotShadow: false,
			type: 'pie'
		},
		title: {
			text: `<b>${data.title} </b>`,
			align: 'center',
			verticalAlign: 'middle',
			y: 60
		},
		tooltip: {
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					style: {
						fontWeight: 'bold',
						color: 'black'
					},
				},
				size: '110%'

			},
			// plotOptions: {
			// 	pie: {
			// 		dataLabels: {
			// 			enabled: true,
			// 			distance: -50,
			// 			style: {
			// 				fontWeight: 'bold',
			// 				color: 'white'
			// 			}
			// 		},
			// 		startAngle: -90,
			// 		endAngle: 90,
			// 		center: ['50%', '75%'],
			// 		size: '110%'
			// 	}
			// },
		},
		credits: {
			enabled: false
		},
		series: [{
			type: 'pie',
			name: '',
			// innerSize: '50%',
			data: data.datasets,
			sliced: true,
		}]
	}

	return (
		<HighchartsReact
			className='chart'
			highcharts={Highcharts}
			options={pieChartOptions}
			constructorType={'chart'}
		/>)

}

PieChart.displayName = 'PieChart';

export default PieChart;