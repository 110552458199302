import { guessTimeZone, getDateInTimeZone } from '../../../../Util/TimezoneUtil';
import { dateUtils } from '../../../../Util/DateUtil';

const { addDays } = dateUtils();

const defaultTimezone = guessTimeZone();
const defaultStartDate = getDateInTimeZone(new Date(), defaultTimezone);
const defaultEndDate = addDays(defaultStartDate, 10);

export const performanceTrackingCampaignDefault = () => ({
	CampaignId: null,
	Name: 'New Performance Tracking campaign',
	Description: null,
	CampaignType: 'NoDisplay',
	StartDateUtc: defaultStartDate,
	StartDateInTimezone: defaultStartDate,
	EndDateUtc: defaultEndDate.setHours(23, 59, 59),
	EndDateInTimezone: defaultEndDate.setHours(23, 59, 59),
	IanaTimezone: defaultTimezone,
	HasEndDate: true,
	IsStopped: false,
	HasControlGroup: false,
	ControlGroupSplitValue: 0,
	IdleDuration: 0,
	Objective: 'ImproveConversion',
	ContributionMode: 'Unknown',
	ExecutionMode: 'Default',
	TrackingMode: 'NoTracking',
	UrlDebug: '',
	Specifications: [
	],
	Slide: {
		UId: null,
		RangeSize: 100,
		SlideId: null,
		SlideType: 'NoDisplay',
		SlideFormat: '',
		DesignProperties: [],
		TemplateType: '',
		TemplateName: '',
		Template: {},
		InPage: null,
		Answer: {},
		Variants: [],
		Variations: [],
		Properties: [],
		TemplateDescriptorDesktop: {
			EditableCss: '',
			HtmlPreview: '',
			EditableJavascript: '',
			EditableTemplate: {
				ContentTemplateType: 'Fluid',
				Content: '',
			},
		},
		TemplateDescriptorMobile: {
			EditableCss: '',
			HtmlPreview: '',
			EditableJavascript: '',
			EditableTemplate: {
				ContentTemplateType: 'Fluid',
				Content: '',
			},
		},
		SavedStyle: '',
	},
	DefaultPermissions: {
		CAMPAIGNS_CAN_VIEW: true,
		CAMPAIGNS_CAN_CREATE: true,
		CAMPAIGNS_CAN_DELETE: true,
		CAMPAIGNS_CAN_SUSPEND: true,
	},
	TestModeIpAddresses: [],
	TechnicalPurpose: 7,
	Editor: 'performanceTracking/simple',
});

