import React, { useEffect, useState, Fragment } from 'react';
import './UsersAdministrationContainer.css';
import { react2angular } from 'react2angular';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import AccountServices from '../../Services/AccountServices';
import SystemServices from '../../Services/SystemServices';
import UsersAdministrationServices from '../../Services/UsersAdministrationServices';
import OrganizationMembershipServices from '../../Services/OrganizationMembershipServices';
import { UserAdminProvider } from './userAdministrationContext';
import UsersAdministration from './UsersAdministration';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import account_home_en from '../../Translation/jsonfiles/fr/account_home_fr.json';
import account_home_fr from '../../Translation/jsonfiles/fr/account_home_fr.json';
const i18nL= localStorage.getItem('i18nL');
const useScopedSystemServices = (systemServices) => {
	const [scope, setScope] = useState(1);
	const [messages, setMessages] = useState([]);
	
	const startScope = (nbOfExpectedMesages) => {
		setMessages([]);
		setScope(nbOfExpectedMesages);
	};

	const updateScope = (updateNbOfExpectedMesages) => {
		const notInf1 = x => x < 1 ? 1 : x;
		setScope(x => notInf1(updateNbOfExpectedMesages(x)));
	};

	const showIfDone = () => {
		const show = () => {
			const err = messages.find(x => x.type === 'error');
			if (err){
				systemServices.showError(err.message);
				return;
			}
			const succ = messages.find(x => x.type === 'success');
			if (succ){
				systemServices.showSuccess(succ.message);
				return;
			}
		};
		if (scope >=1 && scope === messages.length){
			show();
			startScope(1);
		}
	};

	useEffect(() => {
		showIfDone();
	}, [messages, scope]);
	
	const addSilent = () => {
		setMessages(x => [...x, { type:'silent' }]);		
	};

	const showSuccess = (message = 'Success') => {		
		setMessages(x => [...x, { type:'success', message }]);
	};

	const showError = (message = 'An error occured.') => {
		setMessages(x => [...x, { type:'error', message }]);
	};

	return { startScope, updateScope, addSilent, showSuccess, showError };
};


export default function UsersAdministrationContainer(props) {

	const propS = props.reactRender ? props.props : props;
	const systemServices = new SystemServices(propS.$rootScope, propS.$timeout);
	const impersonatingServices = new ImpersonatingServices(propS.$rootScope, propS.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || propS.$rootScope.User.Account.Key;
	const ua = new UsersAdministrationServices(propS.$http, propS.$routeParams);
	const om = new OrganizationMembershipServices(propS.$http, propS.$routeParams);
	const a = new AccountServices(propS.$http);
	const { startScope, updateScope,  addSilent, showSuccess, showError } = useScopedSystemServices(systemServices);

	const isAnAdmin = props.reactRender ? propS.isAnAdmin : props.$rootScope.$$childHead.IsAnAdmin();
	const isAnOrganizationAdmin = props.reactRender ? propS.isAnOrganizationAdmin : props.$rootScope.$$childHead.IsAnOrganizationAdmin();	
	const isOrganizationAdminOnItsMainAccount = props.reactRender ? propS.isOrganizationAdminOnItsMainAccount : props.$rootScope.$$childHead.IsAnOrganizationAdmin() && props.$rootScope.User.Account.Key === accountId;
	const permissions = props.reactRender ? propS.permissions : props.$rootScope.$$childHead.permissions;

	const handleError = (err, callback, message) => {
		showError(message); 
		console.log(err);
		callback && callback(err);
	};
	const handleRetrieveDataError = (callback) => err => handleError(err, callback, 'An error occurred while retrieving data');
	const handleSaveError = (callback) => (err, detail) => {
		if (detail && detail.status === 400){
			handleError(err, callback, detail.data.error || err);
			return; 
		}
		handleError(err, callback, 'An error occurred while saving');
	};
	const handleSaveErrorOrConflict = (callbackError, callbackConflict) => (err, detail) => {
		if (detail && detail.status === 409){
			addSilent();
			return callbackConflict && callbackConflict();
		}
		handleError(err, callbackError, 'An error occurred while saving');
	};
	const handleSuccess = (callback, message) => success => {
		showSuccess(message || 'The user has been successfully saved'); 
		callback && callback(success);
	};

	const handleSilentSuccess = (callback) => success => {
		addSilent();
		callback && callback(success);
	};

	// services calls
	const getAccountUsers = (callbackSuccess, callbackError) => 
		ua.getAccountUsers(accountId, handleSilentSuccess(callbackSuccess), handleRetrieveDataError(callbackError));

	const getRoles = (callbackSuccess, callbackError) => 
		ua.getRoles(accountId, handleSilentSuccess(callbackSuccess), handleRetrieveDataError(callbackError));

	const getAccountData = (callbackSuccess, callbackError) => 
		a.getAccount(accountId, handleSilentSuccess(callbackSuccess), handleRetrieveDataError(callbackError));

	const deleteUser = (userId, callbackSuccess, callbackError) => 
		ua.deleteAccountUser(accountId, userId, handleSuccess(callbackSuccess, 'The user has been successfully deleted'), handleSaveError(callbackError));

	const createUser = (config, callbackSuccess, callbackError) => 
		ua.createUser(accountId, config, handleSuccess(callbackSuccess, 'The user has been successfully created'), handleSaveError(callbackError));

	const updateUserRoles = (user, config, callbackSuccess, callbackError) => 
		ua.putUserRoles(accountId, user.id, config, handleSuccess(callbackSuccess, 'The user has been successfully modified'), handleSaveError(callbackError));

	const updateUser = (user, callbackSuccess, callbackError) => 
		ua.putUser(accountId, user.id, user, handleSuccess(callbackSuccess, 'The user has been successfully modified'), handleSaveError(callbackError));

	const getOrganizationMembership = (organizationId, callbackSuccess, callbackError) =>
		om.getOrganizationMembership(organizationId, handleSilentSuccess(callbackSuccess), handleRetrieveDataError(callbackError));

	const getOrganizationMember = (organizationId, memberId, callbackSuccess, callbackError) => 
		om.getOrganizationMember(organizationId, memberId, handleSilentSuccess(callbackSuccess), handleRetrieveDataError(callbackError));

	const setOrganizationMemberFeature = (organizationId, userId, config, callbackSuccess, callbackError) => 
		om.setOrganizationMemberFeature(organizationId, userId, config, handleSuccess(callbackSuccess, 'The user has been successfully modified'), handleSaveError(callbackError));

	const addUserToOrganization = (organizationId, userId, features, callbackSuccess, callbackError) => 
		om.addMemberToOrganization(organizationId, { userId, features }, handleSuccess(callbackSuccess, 'The user has been successfully modified'), handleSaveErrorOrConflict(callbackError, 
			() => {
				callbackSuccess && callbackSuccess({conflict:true} /* not used but must not be null */); 
			}));

	const [shouldLoad, setShouldLoad] = useState(true);
	useEffect(() => {
		if (propS.accountCurrent === 8) {
			setShouldLoad(s => !s);
		}
	}, [propS.accountCurrent]);

	return (
		<UserAdminProvider
			isAnAdmin={isAnAdmin}
			isAnOrganizationAdmin={isAnOrganizationAdmin}
			isOrganizationAdminOnItsMainAccount={isOrganizationAdminOnItsMainAccount}
			permissions={permissions}
			shouldLoad={shouldLoad}
			systemServicesScope={{startScope, updateScope}}
			organizationServices={{getOrganizationMembership, getOrganizationMember, addUserToOrganization, setOrganizationMemberFeature}}
			userServices={{getAccountUsers, createUser, deleteUser, updateUser, updateUserRoles}}
			getAccountData={getAccountData}
			getRoles={getRoles}
		>
			<UsersAdministration />			
		</UserAdminProvider>
	);

}
angular
	.module('beyableSaasApp.UsersAdministration', [])
	.component('usersAdministration', react2angular(UsersAdministrationContainer, ['accountCurrent'], ['$http', '$rootScope', '$routeParams', '$timeout']));