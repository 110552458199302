import React from 'react';
import Btn from '../../../Components/Btn';
import EditorHeader from '../../../Components/EditorHeader';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
import Breadcrumb from '../../../Components/Breadcrumb';
export default function ProductFeedHeader({
	returnUrl = '',
	subTitle = '',
	title = '',
	children
}) {
	const {checkIfCanQuitWithoutSave,
		canValid,
		step,
		mode,
		handleValidChanges,
		handleCreateSourceFeed,
	} = useProductFeedEditionContext();

	return (
		<div className='flex_item_fix'>
			<EditorHeader
				leftTitle={'Product feed edition'}
				backTitle={'Back to list'}
				backOnClick={checkIfCanQuitWithoutSave}
				center={mode == 'creation' &&
					<Breadcrumb
						itemOfBreadcrumb={[
							{ index: 1, title: 'Connection' },
							{ index: 2, title: 'Mapping' },
						]}
						stepSelected={step}
					/>
				}
				right={
					<>
						<Btn
							onClick={checkIfCanQuitWithoutSave}
							message="Cancel"
							style="ghost"
							color="secondary"
						/>
						<Btn
							disabled={!canValid}
							onClick={mode !== 'creation' ? handleValidChanges : handleCreateSourceFeed }
							message="Save"
						/>
					</>
				}
			/>
		</div>
	);
}
