import React, {useState} from 'react';
import { useEcommerceAnalyticsContext } from '../../../context/EcommerceAnalyticsContext';
import { useEcommerceBuyingbehaviorContext } from '../../../context/EcommerceBuyingBehaviorContext';
import BuyingBehaviorBoardContainer from '../../BuyingBehavior/BuyingBehaviorBoardContainer';
import NavBuyingBehavior from '../../BuyingBehavior/NavBuyingBehavior';
import ReportTypeFilter from '../../BuyingBehavior/ReportTypeFilter';
import BuyingBevahiorChartContainer from '../../ChartContainer/BuyingBevahiorChartContainer';
import SourceTypeFilterSimple from '../../SourceType/SourceTypeFilterSimple';
import { ChipContainer } from '../ChipContainer';
import SelectDeviceSimple from '../../Devices/SelectDeviceSimple';
import UtmFiltersBehaviorContainer from './UtmFiltersBehaviorContainer';
import SourceTypeFilter from '../../SourceType/SourceTypeFilter';
import SelectDevice from '../../Devices/SelectDevice';
const getDimensions = () => {
	return [
		{ label: 'Product', key: 'Product', checked: true },
		{ label: 'Categories', key: 'Category', checked: true },
		{ label: 'Brand', key: 'Brand', checked: true },
		
	];
};
export default function BuyingBehaviourContainer({
	services,
	styles,
	systemServices,
	sessionService
}) {
	const {
		// Tab state & utils
		activeSubDimensionTab,
		changeActiveSubDimensionTab,
	} = useEcommerceAnalyticsContext();
	const {	initiNavDate,
		accountId,
		navFilter,
		setNavFilter,
		handleSelectComparaisonPeriodHandler,
		sourceType,
		handleChangeSourceType,
		sourceTypes,
		chips,
		onDeleteChip,
		reportTypes,
		useTimeDisabled,
		setUseTimeDisabled,
		selectedTimeGranularity,
		setSelectedTimeGranularity,
		metricTypeBuyingBehaviorSelected,
		handleChangeReportType,
		reportTypeBuyingBehaviorSelected,
		isCustomSelected,
		setisCustomSelected,
		customDateRange,
		setCustomDateRange,
		ComparisonSelected,
		devices,
		deviceSelected,
		handleChangeDeviceSelected,
		resetSource,
		resetDevices
	} = useEcommerceBuyingbehaviorContext();
	let initialDates = initiNavDate(navFilter);
	const dimensions = getDimensions();
	const localConfig = [
		{ label: 'None', value: 'None' },
		...dimensions.map((d) => ({ label: d.label, value: d.key })),
	];
	const [selectedDimension, setSelectedDimension] = useState({
		splitDimension: localConfig[0],
		useTime: true,
	});

	if (activeSubDimensionTab !== 'buyingbehaviour') {
		return <></>;
	}
	return (
		<div className="flex_item_full">
			<div className="analytics_main_line">
				<NavBuyingBehavior
				// ComparisonSelected={ComparisonSelected}
					setNavFilter={setNavFilter}
					initialDates={initialDates}
					handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
					comparisonSelected={ComparisonSelected}
					setisCustomSelected={setisCustomSelected}
					isCustomSelected={isCustomSelected}
					customDateRange={customDateRange}
					setCustomDateRange={setCustomDateRange}
				/>
				<ReportTypeFilter
					reportTypeSelected={reportTypeBuyingBehaviorSelected}
					handleChangeReportType={handleChangeReportType}
					initialValue={reportTypes}
				/>
				<SourceTypeFilter
					sourceTypeSelected={sourceType}
					handleChangeSourceType={handleChangeSourceType}
					initialValue={sourceTypes}
					resetSource={resetSource}
				/>
				<SelectDevice
					resetSource={resetDevices}
					initialValue={devices}
					devices={devices}
					deviceSelected={deviceSelected}
					handleChangeDeviceSelected={handleChangeDeviceSelected}
				/>
				<UtmFiltersBehaviorContainer
					behaviorServices={sessionService}
				/>
				{chips && <ChipContainer
					chips={[chips]}
					onDelete={onDeleteChip}
				/>}
			</div>
			<div className="analytics_body">
				<div className="analytics_body_main">
					<BuyingBevahiorChartContainer
						systemServices={systemServices}
						navFilter={navFilter}
						service={services}
						accountId={accountId}
						selectedDimension={selectedDimension}
						setSelectedDimension={setSelectedDimension}
						useTimeDisabled={useTimeDisabled}
						setUseTimeDisabled={setUseTimeDisabled}
						selectedTimeGranularity={selectedTimeGranularity}
						setSelectedTimeGranularity={setSelectedTimeGranularity}
					/>
				</div>
				<div>
					<BuyingBehaviorBoardContainer
						services={services}
					/>
				</div>
		
			</div>
		</div>
	);
}
