import React from 'react';
import styles from './mask.module.css';

import {
	IMask
} from './types';

function Mask({
	children,
	hasDelay = true,
	hasProgress = false
}: IMask) {

	const cssClass = [styles.mask];

	// hasDelay
	if (hasDelay) {
		cssClass.push(styles.has_delay);
	}

	// hasProgress
	if (hasProgress) {
		cssClass.push(styles.has_progress);
	}

	return (
		<div className={cssClass.join(' ')}>{children}</div>
	);
}

export {
	Mask
};
