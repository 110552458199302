import React from 'react';
import Link from '../../Components/Link';

export default function NavReportingServerSide({
	$routeParams,
	isAdmin,
	accountId
}) {
	const back = () => {
		if (isAdmin && $routeParams.ka) {
			return `Performance/Dashboard?ka=${accountId}&ku=${$routeParams.ku}`;
		} else {
			return 'Performance/Dashboard';
		}
	};
	return (
		<div className="page_sidebar_title">
			<Link href={back()} icon="fas fa-arrow-left fa-sm">
				Back to campaign list
			</Link>
		</div>
	);
}
