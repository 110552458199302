import React, { useState, useEffect } from 'react'
import './TemplateDisplay.css'
import { CampaignsContext } from '../../context'
import Btn from '../../../../Components/Btn';
import Modal from '../../../../Components/Modal';
import InputCustom from '../../../../Components/InputCustom';

export default function SaveStyleModal() {

    const {
        handleSaveStylesForFormat,
        saveStyleModalOpen,
        setSaveStyleModalOpen,
        systemServices
    } = CampaignsContext()

    const [newSaveStyleName, setNewSaveStyleName] = useState("New saved style")

    const [confirmSave, setConfirmSave] = useState(false)

    useEffect(() => {
        if (!confirmSave) return

        const timeout = setTimeout(() => {
            setSaveStyleModalOpen(false)
        }, 1500)

        return () => clearTimeout(timeout)

    }, [confirmSave])

    useEffect(() => {
        if (saveStyleModalOpen){
            setConfirmSave(false)
        }
    }, [saveStyleModalOpen])

    function saveStyleSuccess(data) {
        setConfirmSave(true)
    }
    function saveStyleError(error) {
    	console.log(error); systemServices.showError('an error occured')
    }
    function handleSaveStyle() {
        handleSaveStylesForFormat(newSaveStyleName, saveStyleSuccess, saveStyleError)
    }


    return (
        <Modal
            isOpen={saveStyleModalOpen}
            onClose={(e) => setSaveStyleModalOpen(false)}>
            {!confirmSave &&
                <>
                    <div className="modal_header has_border">
                        Save the current style
                        <div className="modal_header_desc">It will be available for new campaigns</div>
                    </div>
                    <div className="modal_body">
                        <InputCustom
                            onChange={(e) => setNewSaveStyleName(e.target.value)}
                            value={newSaveStyleName}
                            type="text"
                            label="Style name"
                            labelClassName="grey_0"
                            fullWidth={true}
                            autoFocus={true}
                            selectOnFocus={true}
                        />
                    </div>
                    <div className="modal_footer al_right">
                        <Btn
                            onClick={() => setSaveStyleModalOpen(false)}
                            style="ghost"
                            color="secondary"
                            message="Cancel"
                        />
                        <Btn
                            onClick={() => handleSaveStyle()}
                            message="OK"
                        />
                    </div>
                </>
            }
            {confirmSave &&
                <>
                    <div className="modal_header has_border">
                        Save the current style
                        <div className="modal_header_desc">It will be available for new campaigns</div>
                    </div>
                    <div className="modal_body">
                        <div>Your style has been saved.</div>
                    </div>

                </>
            }
        </Modal>
    )
}