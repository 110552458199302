import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import './CrossSellUpSellContainer.css';
import SystemServices from '../../../Services/SystemServices';
import ProductTagServices from '../../../Services/ProductTagServices';
import CrossSellUpSellServices from '../../../Services/CrossSellUpSellServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import algorithmDefinition from './ParamatersList/algorithmDefinition';
import AlgoritmListContainer from './AlgoritmUserListContainer';
import CrossSellUpSellAddUpdateAlgorithm from './CrossSellUpSellAddUpdateAlgorithm';
import ContextProvider from './Context/Context';
import OutcomeContainer from './OutcomeContainer';
import ProductTagContainer from './../Tags/ProductTagContainer';

export default function CrossSellUpSellContainer(
	{
		https,
		rootScope,
		$timeout,
		accountId,
	}
) {
	const [listOfShelvingRules, setListOfShelvingRules] = useState();
	// const [ruleIdSelected, setRuleIdSelected] = useState()
	const [isExistantAlgo, setIsExistantAlgo] = useState(false);
	const [toggleFilter, setToggleFilter] = useState(1);
	const [nameOfAlgo, setNameOfAlgo] = useState();

	// Fetch Api Need Services with props
	const productTagServices = new ProductTagServices(https);
	const crossSellUpSellServices = new CrossSellUpSellServices(https);
	const systemServices = new SystemServices(rootScope, $timeout);

	const isAdmin = rootScope.$$childHead.IsAnAdmin();

	// Fetch Api Need Services
	// const productTagServices = new ProductTagServices(props.$http, props.$routeParams);
	// const crossSellUpSellServices = new CrossSellUpSellServices(props.$http, props.$routeParams);
	// const systemServices = new SystemServices(props.$rootScope);
	// const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	// const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const [algorithm, setAlgorithm] = useState({
		name: ''
	});

	const [algorithmName, setAlgorithmName] = useState('');
	const getProductTag = (accountId, callbackSuccess) => productTagServices.getProductTag(accountId, callbackSuccess, error => systemServices.showError());
	const getProductAssociationList = (accountId, callbackSuccess) => crossSellUpSellServices.getProductAssociationList(accountId, callbackSuccess, error => { console.log(error); systemServices.showError('An error occured while retrieving data'); });
	const getProductAssociationDetailsList = (accountId, itemId, callbackSuccess) => crossSellUpSellServices.getProductAssociationDetailsList(accountId, itemId, callbackSuccess, error => { console.log(error); systemServices.showError('An error occured while retrieving data'); });
	const getProductAssociationDetailsListRelations = (accountId, itemId, callbackSuccess) => crossSellUpSellServices.getProductAssociationDetailsListRelations(accountId, itemId, callbackSuccess, error => { console.log(error); systemServices.showError('An error occured while retrieving data'); });
	const putProductAssociationDetailsList = (accountId, itemId, listRef, callbackSuccess) => crossSellUpSellServices.putProductAssociationDetailsList(accountId, itemId, listRef, callbackSuccess, error => systemServices.showError());
	const postProductAssociationDetailsList = (accountId, listRef, callbackSuccess) => crossSellUpSellServices.postProductAssociationDetailsList(accountId, listRef, success => { systemServices.showSuccess('The matching list has been created'); callbackSuccess(success); }, error => systemServices.showError());
	const deleteProductAssociationDetailsList = (accountId, itemId, callbackSuccess) => crossSellUpSellServices.deleteProductAssociationDetailsList(accountId, itemId, success => { systemServices.showSuccess('The matching list has been deleted'); callbackSuccess(success); }, error => systemServices.showError(error));
	const getProductFeedTags = (accountId, callbackSuccess, callbackError) => crossSellUpSellServices.getProductFeedTags(accountId, callbackSuccess, error => systemServices.showError());
	const getProductFeedCategory = (accountId, callbackSuccess, callbackError) => crossSellUpSellServices.getProductFeedCategory(accountId, null,callbackSuccess, error => systemServices.showError());
	const getProductsShelving = (userId, callbackSuccess, callbackError) => crossSellUpSellServices.getProductsShelving(userId, callbackSuccess, error => systemServices.showError());
	const getProductsShelvingRules = (accountId, ruleId, callbackSuccess, callbackError) => crossSellUpSellServices.getProductsShelvingRules(accountId, ruleId, callbackSuccess, error => systemServices.showError());
	const putProductsShelvingRules = (accountId, ruleId, rules) => crossSellUpSellServices.putProductsShelvingRules(accountId, ruleId, rules,
		success => systemServices.showSuccess('The algorithm has been successfully edited '), error => systemServices.showError());
	const postProductsShelvingRules = (accountId, rules) => crossSellUpSellServices.postProductsShelvingRules(accountId, rules, success => systemServices.showSuccess('The algorithm has been created with success'), error => systemServices.showError(' An error occured during the creation'));

	const getAllProductsShelvingRules = () => {
		getProductsShelving(accountId,
			newData => {
				setListOfShelvingRules([...newData]);
			});
	};

	useEffect(() => {
		getAllProductsShelvingRules();
	}, [toggleFilter]);

	const changeAlgorithmName = (event) => {
		const value = event.target.value;
		setAlgorithmName(value);
	};
	useEffect(() => {
		setAlgorithm(prevValues => ({
			...prevValues,
			name: algorithmName
		}));
	}, [algorithmName]);

	const changeAlgorithmType = (event) => {
		let algoConstructor = algorithmDefinition.filter(x => x.strategy === event);
		setAlgorithm(
			...algoConstructor,
		);
		setAlgorithm(prevValues => ({
			...prevValues,
			name: algorithmName
		}));
	};
	const changeAlgorithmOptionChoice = (event) => {
		setAlgorithm(prevValues => ({
			...prevValues,
			optionChoice: event
		}));
	};
	const changeAlgorithmOptionChoiceMatchingList = (matchingListId) => {
		const optionChoice = { ...algorithm };
		if (optionChoice.config.matchingLists) {
			if (optionChoice.config.matchingLists.includes(matchingListId)) {
				deleteItemFromOptionChoice(matchingListId);
			} else {
				optionChoice.config.matchingLists = [...optionChoice.config.matchingLists, matchingListId];
			}
		}
		else {
			optionChoice.config.matchingLists = [matchingListId];
		}
		setAlgorithm(optionChoice);
	};
	const deleteItemFromOptionChoice = (matchingListId) => {
		const optionChoice = { ...algorithm };
		optionChoice.config.matchingLists = optionChoice.config.matchingLists.filter(x => x !== matchingListId);
		setAlgorithm(optionChoice);
	};
	const changeAlgorithmProductBasedOn = (event) => {
		setAlgorithm(prevValues => ({
			...prevValues,
			ruleScope: { scopeIdentifier: event }
		}));
	};

	const changeAlgorithmFilterMethod = (event) => {
		setIsExistantAlgo(false);
		const filter = { ...algorithm };
		if (filter.filters !== undefined) {
			filter.filters[0].type = event;
			setAlgorithm(filter);
		}
	};
	const changeAlgorithmFilterData = (e) => {
		if (e && e !== 'change' && algorithm.filters && algorithm.filters[0].type !== 'PRODUCTS') {
			const value = e;
			const filter = { ...algorithm };
			if (filter.filters) {
				filter.filters[0].values = value;
			}
			setAlgorithm(filter);
		}

		if (e && typeof e === 'object' && isExistantAlgo !== true && algorithm.filters && algorithm.filters[0].type !== 'TAGS') {
			const filter = { ...algorithm };
			filter.filters[0].values = [`${e.type}=${e.value}`];
			setAlgorithm(filter);
		}

		if (!e && isExistantAlgo !== true) {
			setAlgorithm(prevValues => ({
				...prevValues,
				filters: [
					{
						type: 'null',
						values: [
						]
					}
				],
			}));
		}
		if (e === 'change' && isExistantAlgo !== true) {
			const filter = { ...algorithm };
			filter.filters ? filter.filters[0].values = [] : 'null';
			setAlgorithm(filter);
		}
	};

	const changeAlgorithmFilterDataSkuList = (e) => {
		const filter = { ...algorithm };
		filter.filters[0].values = [e.split(/[;,\n]/).map(item => item.trim()).filter(x => x.length > 0)];
		setAlgorithm(filter);
	};

	const toggleSeeList = () => {
		setToggleFilter(1);
		setAlgorithm({
			name: ''
		});
	};
	const toggleAddItem = () => {
		setToggleFilter(2);
	};

	const clickOnAlgo = (e) => {
		setRuleIdSelected(e);
		// setNameOfAlgo(e.name)
	};

	return (
		<ContextProvider>
			<section className='section no_bottom_pad section_primary'>
				<div className="h1">Recommendations</div>
			</section>
			{toggleFilter === 1 &&
				<AlgoritmListContainer
					listOfShelvingRules={listOfShelvingRules}
					toggleAddItem={toggleAddItem}
					clickOnAlgo={clickOnAlgo}
					accountId={accountId}
					getProductsShelvingRules={getProductsShelvingRules}
					toggle={() => setToggleFilter(2)}
					setAlgorithm={setAlgorithm}
					setIsExistantAlgo={() => setIsExistantAlgo(true)}
					setAlgorithmName={setAlgorithmName}
				/>
			}
			{toggleFilter === 2 &&
				<div className="center">
					<div className="containAddAlgo">
						<CrossSellUpSellAddUpdateAlgorithm
							algorithm={algorithm}
							isExistantAlgo={() => setIsExistantAlgo(false)}
							setAlgorithmName={setAlgorithmName}
							changeAlgorithmName={changeAlgorithmName}
							changeAlgorithmOptionChoice={changeAlgorithmOptionChoice}
							changeAlgorithmOptionChoiceMatchingList={changeAlgorithmOptionChoiceMatchingList}
							changeAlgorithmProductBasedOn={changeAlgorithmProductBasedOn}
							changeAlgorithmFilterDataSkuList={changeAlgorithmFilterDataSkuList}
							changeAlgorithmType={changeAlgorithmType}
							changeAlgorithmFilterMethod={changeAlgorithmFilterMethod}
							changeAlgorithmFilterData={changeAlgorithmFilterData}
							toggleSeeList={toggleSeeList}
							accountId={accountId}
							getProductTag={getProductTag}
							getProductAssociationList={getProductAssociationList}
							getProductAssociationDetailsList={getProductAssociationDetailsList}
							getProductAssociationDetailsListRelations={getProductAssociationDetailsListRelations}
							putProductAssociationDetailsList={putProductAssociationDetailsList}
							postProductAssociationDetailsList={postProductAssociationDetailsList}
							deleteItemFromOptionChoice={deleteItemFromOptionChoice}
							deleteProductAssociationDetailsList={deleteProductAssociationDetailsList}
							getProductFeedTags={getProductFeedTags}
							getProductFeedCategory={getProductFeedCategory}
							putProductsShelvingRules={putProductsShelvingRules}
							postProductsShelvingRules={postProductsShelvingRules}
							setToggleFilter={setToggleFilter}
							isAdmin={isAdmin}
						/>

						<OutcomeContainer
							propertyAlgo={algorithm}
						/>
					</div>
				</div>
			}
		</ContextProvider>
	);
}

angular
	.module('beyableSaasApp.CrossSellUpSell', [])
	.component('crosssell', react2angular(CrossSellUpSellContainer, [], ['$http', '$rootScope', '$routeParams']));
