const scriptExport = function(props) {
	if (!cpg) return;

	var popin = this;
	var body_overflow;
	cpg.popinEl = popin;

	cpg.show = function() {
		popin.classList.add('by_is_open');
		body_overflow = document.body.style.overflow;
		document.body.style.overflow = 'hidden';
	};

	cpg.close = function() {
		popin.classList.remove('by_is_open');
		document.body.style.overflow = body_overflow;
		setTimeout(function(){
			BY.by_CloseCampaign(SId);
		}, 500);
	};

	setTimeout(cpg.show, 10);
};

// TODO : déplacer ça dans le css de chaque composant ?
const stylesExport = `
.BY_BLD,
.BY_BLD *{
    all: revert;
}
`;

const styles = `
.BY_BLD{
	line-height: 1.4;
}
.BY_BLD,
.BY_BLD *,
.BY_BLD ::before,
.BY_BLD ::after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
}
.BY_BLD * {
	background: 0 0;
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}
.BY_BLD span {
	display: inline;
	color: inherit;
}
.BY_BLD button {
	display: inline-block;
	cursor: pointer;
}
.BY_BLD ol,
.BY_BLD ul {
	list-style: none;
}
.BY_BLD strong,
.BY_BLD b {
	font-weight: bold;
}
.BY_BLD em,
.BY_BLD i {
	font-style: italic;
}
.BY_BLD u {
	text-decoration: underline;
}
.BY_BLD strike {
	text-decoration: line-through;
}
.BY_BLD a{
	cursor: pointer;
}
.BY_BLD a,
.BY_BLD a:hover,
.BY_BLD a:visited {
	text-decoration: none;
}

@media (min-width: 577px) {
	.BY_BLD .by_hide_desktop {
		display: none !important;
	}
}
@media (max-width: 576px) {
	.BY_BLD .by_hide_mobile {
		display: none !important;
	}
}

.BY_BLD .by_popin{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2147483647;
	overflow-y: auto;
	-webkit-transition: visibility 400ms ease, opacity 400ms ease;
	-o-transition: visibility 400ms ease, opacity 400ms ease;
	transition: visibility 400ms ease, opacity 400ms ease;
	/* before opening */
	visibility: hidden;
	opacity: 0;
}
.BY_BLD.by_is_open .by_popin{
	visibility: visible;
	opacity: 1;
}

.BY_BLD .by_popin_align{
	min-height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 40px 16px;
	position: relative;
}

.BY_BLD .by_popin_outer{
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin: auto;
	position: relative;
	z-index: 2;
	max-width: 100%;
	-webkit-transition: -webkit-transform 400ms ease;
	transition: -webkit-transform 400ms ease;
	-o-transition: transform 400ms ease;
	transition: transform 400ms ease;
	transition: transform 400ms ease, -webkit-transform 400ms ease;
	font-family: 'Noto-sans', sans-serif;
	padding: 0;
	-webkit-transform: translateY(-80px);
	-ms-transform: translateY(-80px);
	transform: translateY(-80px);
}
.BY_BLD.by_is_open .by_popin_outer{
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}
`;

const html = `
<div class="by_popin" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-droppable="false" data-gjs-selectable="false">
	<div class="by_popin_align by-js-builder-root" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-droppable="false" data-gjs-selectable="false">
		<div data-gjs-type="popin_overlay"></div>
		<div class="by_popin_outer" data-gjs-removable="false" data-gjs-draggable="false" data-gjs-droppable="false" data-gjs-selectable="false">
			<a data-gjs-type="popin_close"></a>
			<div data-gjs-type="popin">
				<div data-gjs-type="block">
					<div data-gjs-type="column">
						<div data-gjs-type="text">Insert your content here</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
`;

export const componentPopinWrapper = {
	model: {
		defaults: {
			name: 'Wrapper',
			removable: false,
			draggable: false,
			droppable: false,
			copyable: false,
			badgable: false,
			selectable: false,
			tagName: 'div',
			attributes: {
				class: 'BY_BLD by-js-popin'
			},
			traits: [],
			components: html,
			styles: styles,
			'styles-export': stylesExport,
			'script-export': scriptExport,
			'script-props': [],
		}
	}
};
