import React, { useState, useEffect } from 'react'
import './ProductTags.css'

export default function ProductTagAddTag({ toggleAddTagModal, accountId, putProductReferencesByTag, systemServices }) {
    const [tagName, setTagName] = useState('')
    const [skuValue, setSkuList] = useState('')
    const [skuListArray, setSkuListArray] = useState({ ProductReferences: [] })

    const handleChange = (event) => {
        let textAreaValue = { ...skuValue }
        textAreaValue = event.target.value
        setSkuList(textAreaValue)
    }
    useEffect(() => {
        const createListOfSku = () => {
            const skuList = skuValue.split(/[;,\n]/).map(item => item.trim()).filter(x => x.length > 0)
            setSkuListArray({ ProductReferences: skuList })
        }
        createListOfSku()
    }, [skuValue])

    const handleChangeTagName = (event) => {
        let val = { ...tagName }
        val = event.target.value
        setTagName(val)
    }
    const saveNewTag = () => {
        putProductReferencesByTag(accountId, tagName,
            skuListArray)
        setTimeout(() => {
            toggleAddTagModal()
        }, 2000)
    }
    return (
        <div className="modalBody center" >
            <h2 className="">New tag</h2>
            <input className="new_tag_name" type="text"
                onChange={handleChangeTagName}
                value={tagName}
                placeholder="Tag Name"
                required
            />
            <textarea className='textAreaSkuAddNew' name="" value={skuValue} onChange={handleChange} placeholder="SKU list - Separate SKU with a line break, coma ( , ) or semi-colon ( ; )" />
            <p className="advertTag">Separate SKU with a carriage return, comma ( , ) or semi-colon ( ; )</p>
            <div className="tag_actions">
                <button className="tag_btn btn-cancelDelete" onClick={() => toggleAddTagModal()}>Cancel</button>
                <button className="tag_btn btn-saveTag" disabled={tagName.length === 0 || skuValue.length === 0} onClick={saveNewTag} >Save &nbsp;<i className="fas fa-save"></i></button>
            </div>
        </div>
    )
}
