import React from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import CreateEmailTemplateListContextProvider from './context/EmailTemplateListContextProvider';
import EmailTemplateList from './EmailTemplateList';
import { BrowserRouter } from 'react-router-dom';
import newInstanceI18next from '../../../Util/traductionUtils';
import { I18nextProvider } from 'react-i18next';
import RouterWrapper from './RouterWrapper';

export default function EmailTemplateListContainer(props) {

	return (
		<CreateEmailTemplateListContextProvider
			$http={props.$http}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
			$timeout={props.$timeout}		
		>
			<RouterWrapper props={props}>
				<BrowserRouter>
					<I18nextProvider i18n={newInstanceI18next}>
						<EmailTemplateList />
					</I18nextProvider>
				</BrowserRouter>
			</RouterWrapper>
		</CreateEmailTemplateListContextProvider>
	);
}

angular.module('beyableSaasApp.EmailTemplateList', [])
	.component(
		'emailtemplatelistcontainer',
		react2angular(
			EmailTemplateListContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout']
		)
	);

