import React, {useState, useEffect} from 'react';
import {useProductFeedEditionContext} from '../../../context/ProductFeedEditionContext';
import Confirm from '../../../../../Components/Confirm';
import Btn from '../../../../../Components/Btn';
import IconBtn from '../../../../../Components/IconBtn';
import TransformationModal from './TransformationModal';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {TransformationElement, MappingItem}  from './transformationTypes';
import editionStyles from '../../ProductFeedEdition.module.css';

function replaceAt(array, index, value) {
	const ret = array.slice(0);
	ret[index] = value;
	return ret;
}

export default function TransformationsContainer({
	objectIdenfifier,
	idIdentifier,
	addTransformationToObjectIdIdentifier,
	handleDrag
}) {
	const {
		mappingObject,
		transformations,
		setMappingObject,
	} = useProductFeedEditionContext();

	// Check main object
	const [objectToScan, setobjectToScan] = useState<[MappingItem]>();
	const [hasTranformationInObj, sethasTranformationInObj] = useState(false);
	// Select transformations types
	const [tranformationList, settranformationList] = useState<any>([]);
	const [isExistingKey, setisExistingKey] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);


	// Modal creationedition transformation
	const [transformationTypeSelected, settransformationTypeSelected] = useState<string>('');
	const [objectToEdit, setobjectToEdit] = useState<undefined | any>();
	const [deleteObject, setdeleteObject] = useState<undefined| any>();
	const [closeConfirmIsOpen, setcloseConfirmIsOpen] = useState<boolean>(false);
	const [getListEmptyFormNewForm, setgetListEmptyFormNewForm] = useState(true);
	const hasTransformation  = (obj) => {

		if(!obj) return;
		if(obj[0].transformations !== null){
			sethasTranformationInObj(true);
			settranformationList(obj[0].transformations.list);
		}else{
			sethasTranformationInObj(true);
			settranformationList([]);
		}
	};
	useEffect(()=>{
		const nameObjValue = mappingObject[objectIdenfifier];
		if(idIdentifier){
			setobjectToScan([idIdentifier]);
		}else if(!idIdentifier && nameObjValue){			
			setobjectToScan(nameObjValue);
		}
	},[objectIdenfifier,idIdentifier]);

	useEffect(()=>{
		if(objectToScan){
			
			hasTransformation(objectToScan);
		}
	},[objectToScan]);


	function addTransformationToObject(objToAdd: TransformationElement){		
		if(!idIdentifier){
			if(!objectToScan) return;
			if(objectToScan[0].transformations !== null){
				if(isExistingKey){
					const newObjectToSave: MappingItem = {...objectToScan[0]};
					if(!newObjectToSave.transformations?.list) return;
					const oldItem = newObjectToSave.transformations.list.find(x =>x.uuid === objToAdd.uuid);
					if(oldItem === undefined) return;
					const duplicatedPanelIndex = newObjectToSave.transformations.list.indexOf(oldItem);
						
					const newObject =	replaceAt(newObjectToSave.transformations.list, duplicatedPanelIndex,objToAdd );
					newObjectToSave.transformations.list = newObject;
					const mappingObjectCopy = {...mappingObject};
					mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
					setobjectToScan([newObjectToSave]);
					setMappingObject(mappingObjectCopy);
					setModalIsOpen(false);
				}else{
					const newObjectToSave = {...objectToScan[0]};
					if(newObjectToSave.transformations === null) return;
					const newObject =[...newObjectToSave.transformations.list, objToAdd];
					newObjectToSave.transformations.list = newObject;
					const mappingObjectCopy = {...mappingObject};
					mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
					setobjectToScan([newObjectToSave]);
					setMappingObject(mappingObjectCopy);
					setModalIsOpen(false);
				}
			}else{
				const newObjectToSave = {...objectToScan[0]};
				newObjectToSave.transformations = {
					list : []
				};
				newObjectToSave.transformations['list'] = [objToAdd];
				const mappingObjectCopy = {...mappingObject};
				mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
				setMappingObject(mappingObjectCopy);
				setModalIsOpen(false);
				setobjectToScan([newObjectToSave]);
			}
		}else{
			addTransformationToObjectIdIdentifier(objToAdd,isExistingKey,()=>setModalIsOpen(false));
		}
	}
	function deleteTransformation(objToDelete:TransformationElement){
		setcloseConfirmIsOpen(true);
		setdeleteObject(objToDelete);
	}

	function handleCloseEditor(){
		setcloseConfirmIsOpen(false);
		if(!objectToScan)return;
		const newObjectToSave = {...objectToScan[0]};
		if( newObjectToSave.transformations === null)return;
		const transformationObjAfterDeletion = newObjectToSave.transformations.list.filter(elementTransform => elementTransform.uuid !== deleteObject.uuid );
		if(transformationObjAfterDeletion.length > 0){
			newObjectToSave.transformations['list'] = transformationObjAfterDeletion;
			const mappingObjectCopy = {...mappingObject};
			mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
			setMappingObject(mappingObjectCopy);
			setobjectToScan([newObjectToSave]);
		}else{
			newObjectToSave.transformations=null ;
			const mappingObjectCopy = {...mappingObject};
			mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
			setMappingObject(mappingObjectCopy);
			setobjectToScan([newObjectToSave]);
		}
	}
	const reorder = (list: TransformationElement[], startIndex: number, endIndex:number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};
	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		if(!objectToScan) return;

		const newObjectToSave = {...objectToScan[0]};
		if(newObjectToSave.transformations === null) return;
		const retrieveElem = newObjectToSave.transformations['list'];
		const items: TransformationElement[] = reorder(
			retrieveElem,
			result.source.index,
			result.destination.index
		);
		newObjectToSave.transformations['list'] = items;
		if(!idIdentifier){
			const mappingObjectCopy = {...mappingObject};
			mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
			setobjectToScan([newObjectToSave]);
		}else{
			handleDrag([newObjectToSave]);
		}

	};
	function handleModifyTranformation (transformationId: TransformationElement){
		setModalIsOpen(true);
		setisExistingKey(true);
		setobjectToEdit(transformationId);
		settransformationTypeSelected(transformationId.name);
		setgetListEmptyFormNewForm(true);
	}
	function addTransformation(){
		setModalIsOpen(true);
		setisExistingKey(false);
		setobjectToEdit(undefined);
		settransformationTypeSelected('');
	}

	function removeCamelCase(str) {
		if (!str) return '';
		return str[0] + str.slice(1, str.length).replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`);
	}

	return (
		<>
			{hasTranformationInObj && tranformationList && tranformationList.length > 0 &&
				<div className={editionStyles.transformations_wrapper}>
					<div className={editionStyles.table_label}>Transformations</div>
					<Confirm
						isOpen={closeConfirmIsOpen}
						onClose={() => setcloseConfirmIsOpen(false)}
						title={''}
						text={'Are you sure to delete this transformation'}
						cancelText={'Cancel'}
						confirmText={'Delete'}
						confirmCallback={() => handleCloseEditor()}
					/>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId={'transform'} direction="vertical">
							{(provided) =>
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									className={editionStyles.table}
								>
									{tranformationList.map((transformationId:TransformationElement,id) =>
										<Draggable key={transformationId.uuid} draggableId={`${transformationId.uuid}`} index={id}>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													className={editionStyles.table_body_row}
												>
													<div className={editionStyles.table_col}>{removeCamelCase(transformationId?.name)}</div>
													<div className={editionStyles.table_col}>
														<IconBtn
															icon="fas fa-cog"
															tooltip="Transformation settings"
															onClick={() => handleModifyTranformation(transformationId)}
														/>
														<IconBtn
															icon="fas fa-trash"
															tooltip="Delete"
															onClick={()=>deleteTransformation(transformationId)}
														/>
													</div>
												</div>
											)}
										</Draggable>
									)}
								</div>
							}
						</Droppable>
					</DragDropContext>
				</div>
			}
			<div className='mt_10'>
				<Btn
					onClickFunction={() => addTransformation()}
					message="Add transformation"
					style="reverse"
					icon="fas fa-plus fa-sm"
					size="s"
					horizontalWidth="xs"
				/>
			</div>
			<TransformationModal 
				getListEmptyFormNewForm={getListEmptyFormNewForm}
				setgetListEmptyFormNewForm={setgetListEmptyFormNewForm}
				modalIsOpen={modalIsOpen} 
				setModalIsOpen={setModalIsOpen}
				list={transformations}
				addTransformation={addTransformationToObject}
				isExistingKey={isExistingKey}
				objectTransformationToEdit={objectToEdit}
				transformationTypeSelected={transformationTypeSelected}
				settransformationTypeSelected={settransformationTypeSelected}
			/>
		</>
	);
}
