import React, {useEffect, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import classes from './Dropzone.module.css';
import Btn from '../../Components/Btn';
import Fieldset from '../../Components/Fieldset';

export default function Dropzone(props) {
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragAccept,
		isDragReject,
		open
	} = useDropzone({
		accept: 'image/*',
		noClick: true,
		noKeyboard: true,
		onDrop: acceptedFiles => {
			var files = acceptedFiles.length > 0 ? [acceptedFiles[0]] : [];
			if(files.length > 0){
				props.setDisplayImage({ isActive: true, files: acceptedFiles });
			}
		}
	});

	useEffect(function(){
		// Make sure to revoke the data uris to avoid memory leaks
		acceptedFiles.forEach(file => URL.revokeObjectURL(file.preview));
	},[acceptedFiles]);

	return (
		<div {...getRootProps({})}>
			<input {...getInputProps()} />
			<div className={isDragAccept ? classes.drop_accept : isDragReject ? classes.drop_reject : classes.drop_normal}>
				<Fieldset
					label="Account logo"
					description="The logo appears on the first page of the report">
					{props.children}
					<div className={classes.btn_wrapper}>
						<Btn
							onClick={open}
							message='Browse files'
						/>
					</div>
				</Fieldset>
			</div>
		</div>
	);
}
