const initType = [
	{ key: 'Page', label: 'Page' }, 
	{ key: 'PageType', label: 'Page Type' }, 
	{ key: 'Tag1', label: 'Custom Dimension 1' }, 
	{ key: 'Tag2', label: 'Custom Dimension 2' }, 
	{ key: 'Tag3', label: 'Custom Dimension 3' }, 
	{ key: 'Tag4', label: 'Custom Dimension 4' }, 
	{ key: 'Tag5', label: 'Custom Dimension 5' }];
    
export const metricsTypes = [
	{
		key: 'Revenue',
		label: 'Revenue'
	},
	{
		key: 'ProductQuantitySum',
		label: 'Quantity'
	},
	{
		key: 'ProductPrice',
		label: 'Average amount'
	},
	{
		key: 'ProductQuantity',
		label: 'Average quantity in transactions'
	},
	{
		key: 'TransactionCount',
		label: 'Transaction count'
	},
	{
		key: 'TransactionAmount',
		label: 'Transaction average amount'
	},
	{
		key: 'DetailsToCartRatio',
		label: 'Details to cart ratio'
	},
	{
		key: 'DetailsToSaleRatio',
		label: 'Details to sale ratio'
	},
	{
		key: 'SessionToSaleRatio',
		label: 'Session to sale ratio'
	},

];
// {
// 	Revenue,
// 	SessionToSaleRatio,
// 	TransactionCount,
// 	AverageTransactionAmount,

// 	ProductQuantity,
// 	AverageProductPrice,
// 	AverageProductQuantity,

// 	DetailsToCartRatio,
// 	DetailsToSaleRatio,

// 	Tax,
// 	Shipping,
// 	EventDate
// }
export const Revenue = 'Revenue';
export const Quantity = 'ProductQuantitySum';
export const AverageAmount = 'ProductPrice';
export const AverageQuantityInTransactions = 'ProductQuantity';
export const TransactionCount = 'TransactionCount';

export const TransactionAverageAmount = 'TransactionAmount';
export const DetailsToCartRatio = 'DetailsToCartRatio';
export const DetailsToSaleRatio = 'DetailsToSaleRatio';
export const SessionToSaleRatio = 'SessionToSaleRatio';

export const metricsTypesGRaph = [
	{
		key: 'Revenue',
		label: 'Revenue',
		value : [Revenue]
	},
	{
		key: 'ProductQuantitySum',
		label: 'Quantity',
		labelChart : 'Quantity',
		value : [Quantity]
	},
	{
		key: 'ProductPrice',
		label: 'Average amount',
		labelChart : 'Average',
		value : [AverageAmount]
	},
	{
		key: 'ProductQuantity',
		label: 'Average quantity in transactions',
		labelChart : 'Average',
		value : [AverageQuantityInTransactions]
	},
	{
		key: 'TransactionCount',
		label: 'Transaction count',
		labelChart : 'Transaction',
		value : [TransactionCount]
	},
	{
		key: 'TransactionAmount',
		label: 'Transaction average amount',
		labelChart : 'Transaction average',
		value : [TransactionAverageAmount]
	},
	// {
	// 	key: 'DetailsToCartRatio',
	// 	label: 'Details to cart ratio',
	// 	labelChart : 'Cart ratio',
	// 	value : [DetailsToCartRatio]
	// },
	{
		key: 'SessionToSaleRatio',
		label: 'Session to sale ratio',
		labelChart : 'Session ratio',
		value : [SessionToSaleRatio]
	},
];
export const sourceTypes = [
	// 'All',
	'Direct',
	'Organic',
	'Referral',
	'Social',
	'Paid',
	'Email',
	'Display',
	'Other',
];

export const reportTypes = [
	'Product',
	'Category',
	'Brand'
];



export default initType;