import HttpServices from './HttpServices';

export default class BusinessOperationServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}

	// orchestrations
	getOrchestrations(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}orchestration/${accountId}`, callbackSuccess, callbackError);
	}
	postOrchestration(accountId, orchestrations, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}orchestration/${accountId}/`, orchestrations, callbackSuccess, callbackError);
	}
	getorchestrationGroupInfos(accountId, groupId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}orchestration/${accountId}/${groupId}`, callbackSuccess, callbackError);
	}
	getCampaignsList(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}orchestration/campaigns/${accountId}`, callbackSuccess, callbackError);
	}
	putorchestrationGroup(accountId, groupId, group, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}orchestration/${accountId}/${groupId}`, group, callbackSuccess, callbackError);
	}
	putorchestrationGroupStrategy(accountId, groupId, group, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}orchestration/${accountId}/${groupId}/displaystrategy`, group, callbackSuccess, callbackError);
	}
	deleteOrchestration(accountId, groupId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}orchestration/${accountId}/${groupId}`, callbackSuccess, callbackError);
	}

}
