import {initBlocks} from './block';

export const grapesConfig = {
	sectors: [
	//	{
	// 	name: 'General',
	// 	open: false,
	// 	buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom']
	// },
	// {
	// 	name: 'Flex',
	// 	open: false,
	// 	buildProps: ['flex-direction', 'flex-wrap', 'justify-content', 'align-items', 'align-content', 'order', 'flex-basis', 'flex-grow', 'flex-shrink', 'align-self']
	// },
		{
			name: 'Dimension',
			open: false,
			properties: [
				'width', 'height', 'max-width', 'min-height',
				'margin', 'margin-top', 'margin-right', 'margin-left', 'margin-bottom',
				'padding',
				// {
				// 	id: 'horizontal-align',
				// 	property: 'text-align',
				// 	label: 'Horizontal alignment',
				// 	type: 'select',
				// 	default: 'left',
				// 	options: [
				// 		{id: 'left', label: 'Left'},
				// 		{id: 'center', label: 'Center'},
				// 		{id: 'right', label: 'Right'}
				// 	]
				// },
				{
					id: 'justify-content-vert',
					property: 'justify-content',
					label: 'Vertical alignment',
					type: 'select',
					default: 'center',
					options: [
						{id: 'flex-start', label: 'Top'},
						{id: 'center', label: 'Center'},
						{id: 'flex-end', label: 'Bottom'},
						{id: 'space-between', label: 'Space between'},
						{id: 'space-evenly', label: 'Space evenly'}
					]
				}
			],
		},
		{
			name: 'Typography',
			open: false,
			properties: ['font-family', 'font-size', 'font-weight', 'text-align', 'letter-spacing', 'color', 'line-height', 'text-shadow'],
		},
		{
			name: 'Decorations',
			open: false,
			properties: ['background', 'background-color', 'background-image', 'opacity', 'border', 'border-radius-c', 'border-radius', 'box-shadow'],
		},
		{
			name: 'Extra',
			open: false,
			properties: ['transition', 'perspective', 'transform'],
		}
	],
	blocks: initBlocks,
	panels: {},
	protectedCss: '',
	canvasCss: `
		body {
			padding: 0;
			margin: 0;
		}
		body .BY_BLD .by_popin{
			visibility: visible;
			opacity: 1;
		}
		body .BY_BLD .by_popin_outer{
			-webkit-transform: translateY(0);
			-ms-transform: translateY(0);
			transform: translateY(0);
		}
		.gjs-dashed .BY_BLD .by_block {
			padding: 2px 0;
		}
	`
};