import React from 'react';
import CreateGroupSortingContext from './contextGroupSorting';
import GroupSorting from './GroupSorting';

export default function ProviderGroupSorting({
	availableSortingGroups,
	onSetGroupSorting,
	onRemoveGroupSorting,
	data
}) {

	return (
		<CreateGroupSortingContext
			availableSortingGroups={availableSortingGroups}
			onSetGroupSorting={onSetGroupSorting}
			onRemoveGroupSorting={onRemoveGroupSorting}
			data={data}
		>
			<GroupSorting />
		</CreateGroupSortingContext>);
}