import React from 'react';
export default function NavigationReporting({ dimensions, activeLink, setActiveLink, form }) {
	const i18nL= localStorage.getItem('i18nL');
	let labelListForNavigation = [];
	if(!i18nL.includes('fr')){
		labelListForNavigation = [	{
			key: 'onSite',
			label: 'Display indicators',
		},
		{
			key: 'emailRemarketing',
			label: 'Email remarketing indicators',
		},
		{
			key: 'formsAndSurveys',
			label: 'Form answers',
		},
		{
			key: 'sendPromoCode',
			label: 'Promo code emailing indicators',
		},
		{
			key: 'sendVisitorCart',
			label: 'Visitor cart emailing indicators',
		},
		{
			key: 'stockAlert',
			label: 'Stock alert emailing indicators',
		},
		];
	}else{
		labelListForNavigation = [	{
			key: 'onSite',
			label: 'Indicateurs d\'affichage',
		},
		{
			key: 'emailRemarketing',
			label: 'Indicateurs de remarketing par e-mail',
		},
		{
			key: 'formsAndSurveys',
			label: 'Formulaire de réponse',
		},
		{
			key: 'sendPromoCode',
			label: 'Indicateurs codes promotionnels par email',
		},
		{
			key: 'sendVisitorCart',
			label: 'Indicateurs emails aux visiteurs',
		},
		{
			key: 'stockAlert',
			label: 'Indicateurs alertes par email électronique',
		},
		];
	}

	const changeDimension = (dimension) => {
		setActiveLink(dimension);
	};
	const filterArray = Object.entries(dimensions).reduce((results, item) => {
		if (item[1]) {
			results.push(item[0]);
		}
		return results;
	}, []);
	if (form) {
		filterArray.push('formsAndSurveys');
	}
	const arrayOfLinks = filterArray.map((type, idx) => {
		const obj = labelListForNavigation.find(o => o.key === type);
		return (
			<li key={idx}>
				<a
					className={activeLink === type ? 'active' : ''}
					onClick={() => changeDimension(type)}
				>
					{obj.label}
				</a>
			</li>
		);
	});

	return (
		<>
			{filterArray.length > 1 && arrayOfLinks &&
				<div className="reporting_tabs">
					<ul className="tabs">
						{arrayOfLinks}
					</ul>
				</div>
			}
		</>
	);
}
