import React from 'react';
import Modal from '../../../Components/Modal';
import Btn from '../../../Components/Btn';
import AutocompleteCategoryContainer from '../Edition/Mapping/AutocompleteCategoryContainer';
export default function ModalSelectedCategories({
	selectedCatModalIsOpen,
	setSelectedCatModalIsOpen,
	catSelected,
	isExistingObj,
	handleChangeCategorie,
	submitNewCat,
	setupAccountServices,
	accountId,
	tenant,
}) {


	return (
		<Modal
			isOpen={selectedCatModalIsOpen}
			onClose={() => setSelectedCatModalIsOpen(false)}
		>
			<div className="modal_header has_border">
				<div>
					{isExistingObj ? 'Edit ' + catSelected : 'New category'}
				</div>
			</div>
			<div className="modal_body">
				<AutocompleteCategoryContainer
					label="Category name"
					selectedCatModalIsOpen={selectedCatModalIsOpen}
					setSelectedCatModalIsOpen={setSelectedCatModalIsOpen}
					value={catSelected}
					isExistingObj={isExistingObj}
					submitNewCat={submitNewCat}
					setupAccountServices={setupAccountServices}
					onChange={(e) => handleChangeCategorie(e)}
					accountId={accountId}
					tenant={tenant}
				/>
				{/* <InputCustom
					label="Category name"
					name="prefix"
					value={catSelected}
					fullWidth={true}
					type="text"
					placeholder={'example : g'}
					onChange={(e) => handleChangeCategorie(e)}
				/> */}
			</div>
			<div className="modal_footer has_border al_right">
				<Btn
					message={'Cancel'}
					style={'ghost'}
					color={'secondary'}
					onClick={(e) => setSelectedCatModalIsOpen(false)}
				/>
				<Btn
					message={'Ok'}
					onClick={() => submitNewCat()}
					disabled={catSelected === ''}
				/>
			</div>

		</Modal>
	);
}
