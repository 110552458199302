import React, { useEffect, useState } from 'react';
import Dropdown from '../../Components/Dropdown';
import Btn from '../../Components/Btn';
export default function VisitorEngagementSelectTenant({ tenants, tenantSelected, handleChangeTheTenant }) {

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    if (tenants && tenants.length == 0) {
        return (<></>)
    }
    const sortByAsc = (a, b) => {
        if (a.toUpperCase() < b.toUpperCase()) return -1
        if (a.toUpperCase() > b.toUpperCase()) return 1
        return 0
    }
    return (
        <div style={{ margin: "auto", width: "100px" }}>
            {tenants &&
                <Dropdown
                    isOpen={dropdownIsOpen}
                    setIsOpen={(e) => setDropdownIsOpen(true)}
                    onHide={(e) => setDropdownIsOpen(false)}
                    button={
                        <Btn
                            style="outline"
                            color="secondary"
                            arrow="true"
                            light="true"
                            size="s">
                            {tenantSelected && tenantSelected}
                        </Btn>
                    }
                >
                    <ul className="listbox">
                        {tenants && tenants.sort(sortByAsc).map((tenant, idx) =>
                            <li key={idx}>
                                <a className={tenantSelected == tenant ? "listbox_item selected" : "listbox_item"}
                                    onClick={(e) => { handleChangeTheTenant(tenant); setDropdownIsOpen(false); }}>
                                    {tenant}
                                </a>
                            </li>
                        )}
                    </ul>
                </Dropdown>
            }
        </div>
    )
}
