import React from 'react';
import InputCustom from './InputCustom';

export default function SearchBar({
	onChangeValue, // return event ???
	onChange, // return value ??? Bizare non ?
	onFocus,
	valueInSearchBar, // Deprecated
	value,
	placeholder,
	name,
	color = '',
	fullWidth = true,
	width, // 'Full' || 's' || 'xs' || 'xxs'
	autoFocus = false,
	selectOnFocus = false,
	size = 'm',
	disabled = false,
	clearable = true
}) {

	function handleChange(ev){
		if (disabled) return;

		if( typeof onChange === 'function' ){
			onChange(ev.target.value);
		}
		if( typeof onChangeValue === 'function' ){
			onChangeValue(ev);
		}
	}

	if( !width && fullWidth ){
		width = 'full';
	}

	return (
		<InputCustom
			type="text"
			value={value || valueInSearchBar}
			placeholder={placeholder}
			name={name ? name : ''}
			autoFocus={autoFocus}
			selectOnFocus={selectOnFocus}
			onChange={(ev) => handleChange(ev)}
			onFocus={onFocus}
			icon='fas fa-search'
			width={width}
			color={color}
			size={size}
			disabled={disabled}
			clearable={clearable}
		/>
	);
}