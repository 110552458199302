import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import ReportingGenerationServices from '../../../Services/ReportingGenerationServices';
import GlobalDashboardServices from '../../../Services/GlobalDashboardServices';
import ReportingCampaignServices from '../../../Services/ReportingCampaignServices';
import useInputValidation from '../../../Hooks/useInputVal';
import MomentConstants from '../../../Constants/MomentConstants';
import ScreenShotServices from '../../../Services/ScreenShotServices';
import EventTrackingServices from '../../../Services/EventTrackingServices';

import formatMoment from '../../../Constants/FormatMoment';
import {deviceNavToGraphFilter} from '../../Dashboard/OnSite/layout/SelectorDevicesModel';
import {getCampaigns} from '../../../Services/Queries/OnSiteDashboardQueries';
import {OrderByModificationDateDesc} from '../../../Services/Queries/OrderByFilter';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
const engagementLevels = [
	{ id: 'X', name: 'Egarés', color: 'lightblue' },
	{ id: 'D', name: 'Découverte', color: 'darkblue' },
	{ id: 'C', name: 'Considération', color: 'green' },
	{ id: 'I', name: 'Intention', color: 'yellow' },
	{ id: 'A', name: 'Achat', color: 'orange' },
	{ id: 'E', name: 'Expérience', color: 'red' },
	{ id: 'R', name: 'Réachat', color: 'purple' },
];

const toDictionary = (array, getKey, getValue) => array.reduce((dic, item, i) => {
	dic[getKey(item)] = getValue ? getValue(item, i) : item;
	return dic;
}, {});

export const context = createContext();

export function ReportGeneratorContext() {
	return useContext(context);
}

export const ReportGeneratorContextProvider = ({
	accountId,
	systemServices,
	$http,
	AuthServices,
	children,
	$routeParams,
	$rootScope,
	filterCampaigns,
	needFetchList,
	setneedFetchList,
	client,
	labelServices,
	filterStatusMode,
	filterTestMode,
	searchBarvalue,
	filterBylabels,
	devices,
	setLoadingCampaign,

}) => {
	const { formatDateToLocal } = formatMoment;
	const { trackEvent } = EventTrackingServices($rootScope.User);
	const [campaigns, setcampaigns] = useState([]);
	const [t, i18n] = useTranslation('common');
	const [campaignsIsLoading, setCampaignsIsLoading] = useState(true);
	const [skipIdNumber, setskipIdNumber] = useState(0);
	const [totalcount, settotalcount] = useState(0);
	const [pageSize, setpageSize] = useState(20);
	const [scenarioLabels, setScenarioLabels] = useState([]);
	const [campaignIsLoading, setCampaignLoading] = useState(false);

	const [orderCampaignsBy, setorderCampaignsBy] = useState({
		query: OrderByModificationDateDesc,
		name: 'OrderByModificationDateDesc',
		type: 'date',
		icon: 'desc',
	});
	const onTestExecutionModeFilter = {
		'and': [
			{ executionMode: { eq: 'TEST' } },
			{ isStopped: { 'eq': false } },
		]
	};
	const notOnTestExecutionModeFilter = {
		executionMode: { neq: 'TEST' }
	};
	const reportingCampagneServices = new ReportingCampaignServices($http, $routeParams);
	const globalDashboardServices = new GlobalDashboardServices(
		$http,
		AuthServices
	);
	const reportGeneratorService = new ReportingGenerationServices(
		$http
	);
	const screenShotServices = new ScreenShotServices(
		$http,
		AuthServices
	);
	const loadCampaignsO = (skipId,setter) => {
		if (!filterStatusMode) return;
		let filter;
		if (filterStatusMode.length > 0) {
			if (filterTestMode) {
				filter = {
					or: [filterStatusMode[0], onTestExecutionModeFilter],
				};
			} else {
				filter = {
					and: [filterStatusMode[0], notOnTestExecutionModeFilter],
				};
			}
		} else {
			filter = filterTestMode
				? onTestExecutionModeFilter
				: notOnTestExecutionModeFilter;
		}
		setCampaignsIsLoading(true);

		labelServices.getScenarioSpecialLabelsItem(data => {
			setScenarioLabels(data);
		}, (err) => {
			console.log('err', err);
		});

		const andFilters = [filter, searchBarvalue, filterBylabels];

		if (devices.length > 0){
			const deviceFilter = deviceNavToGraphFilter(devices);
			if (deviceFilter){
				andFilters.push(deviceFilter);
			}
		}

		client
			.query({
				query: getCampaigns,
				variables: {
					accountId: accountId,
					skip: skipId,
					take: pageSize,
					order: [orderCampaignsBy.query],

					where: {
						and: andFilters,
					},
				},
			})
			.then((response) => {
				setCampaignsIsLoading(false);
				settotalcount(response.data.account.onSiteCampaigns.totalCount);
				setcampaigns(response.data.account.onSiteCampaigns.items);
				const today = moment();
				const finalCampaigns = response.data.account.onSiteCampaigns.items.map(campaign => {
					const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
					const dateEndOfOperation = formatDateToLocal(campaign.endDate);
					const isTestModeOperationBool  = campaign.executionMode === 'DEFAULT' ? true : false;
					let statusOperation = {};
					const isStoppedBoolOperation = campaign.isStopped;
					function getStatuCss(){
						if (isStoppedBoolOperation) {
							return statusOperation = { id: 'stopped', label: 'Stopped', cssClass: 'status-stopped' };
						}

						if (isTestModeOperationBool) {
							return statusOperation = { id: 'testing', label: 'Draft', cssClass: 'status-testing' };
						}

						if (campaign.endDate && dateEndOfOperation < today) {
							return statusOperation = { id: 'past', label: 'past', cssClass: 'status-past' };
						}

						if (dateBeginOfOperation > today) {
							return statusOperation = { id: 'planned', label: 'scheduled', cssClass: 'status-planned' };
						}

						return statusOperation = { id: 'ongoing', label: 'Ongoing', cssClass: 'status-ongoing' };
					}
					getStatuCss();
					const elementDevices = campaign.devices.map(d => {
						if(typeof d === 'string'){
							return d;
						}else{
							const labelKeyDevices = [
								{
									key:'D_PC',
									label: 'desktop'
								},
								{
									key:'D_SMARTPHONE',
									label: 'mobile'
								},
								{
									key:'D_TABLET',
									label: 'tablet'
								},
							];
							const label = labelKeyDevices.find(x => x.key === d.deviceId);
							return label?.label;

						}
					});

					return {...campaign, statusOperation : statusOperation, devices:elementDevices };

				});
				// setSelectableCampaigns({ items: finalCampaigns, loaded: true });
				setter({ items: finalCampaigns, loaded: true });

			})
			.catch((e) => {
				setCampaignsIsLoading(false);
				console.log('err', e);
				// systemServices.showError(t('campaignsList.errorOccured'));
			});
	};
	const { isAnEmail } = useInputValidation();
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [valueInSearchBarSelectedCampaigns, setValueInSearchBarSelectedCampaigns] = useState('');

	const [selectedCampaignsFiltered, setselectedCampaignsFiltered] = useState([]);
	const [filteredType, setFilteredType] = useState();
	const [displayImage, setDisplayImage] = useState({
		isActive: false,
		files: [],
	});
	const [isUploaded, setisUploaded] = useState(false);
	const [selectableCampaigns, setSelectableCampaigns] = useState({ items: [], loaded: false });
	const [selectedCampaigns, setSelectedCampaigns] = useState({ items: [], loaded: false });
	const [setup, setSetup] = useState({ campaigns: [], loaded: false });
	const [reportRecipient, setReportRecipient] = useState({ email: '', name: '' });
	const [reportDates, setReportDates] = useState({
		startDate: moment().subtract(1, 'months').startOf('month'),
		endDate: moment().subtract(1, 'months').endOf('month')
	});

	const [selectedCampaignOptions, setselectedCampaignOptions] = useState();
	const [modalIsOpen, setmodalIsOpen] = useState(false);
	const [campaignSelected, setcampaignSelected] = useState();
	const [needTorefresh, setNeedTorefresh] = useState(false);
	const loadCampaign = (cpgId,success, error) => globalDashboardServices.chargeReportCampaignBYiD(accountId, cpgId, success, error);
	const [needToSave, setneedToSave] = useState(false);
	const loadReportSetup = (onSuccess, onError) => {
		reportGeneratorService.getReportingGenerationSetup(accountId,
			result => { onSuccess(result.setup); },
			() => {
				onSuccess({ campaigns: [], });
			},
			error => {
				console.log(error);
				systemServices.showError('An error occured');
				onError && onError(error);
			}
		);
	};
	const updateReportSetup = (cp, onSuccess) => {
		if (cp.length > 0) {
			let data = { campaigns: cp };
			reportGeneratorService.updateReportingGenerationSetup(accountId, data,
				() => {
					setneedToSave(false);
					onSuccess && onSuccess();
					setNeedTorefresh(true);
					initialize();
				},
				err => {
					console.log(err);
					setneedToSave(false);
					systemServices.showError('An error occured');
				}
			);
		}
	};
	const launchReportingGeneration = (onSuccess) => {
		reportGeneratorService.launchReportingGeneration(accountId, {
			email: reportRecipient.email,
			userName: reportRecipient.name,
			startDate: reportDates.startDate.format(MomentConstants.MOMENT_API_FORMAT),
			endDate: reportDates.endDate.format(MomentConstants.MOMENT_API_FORMAT),
			language: 'fr',
			reportType: 'BUSINESS_REVIEW',
		}, onSuccess,
		err => {
			console.log(err);
			systemServices.showError('An error occured');
		});
	};

	const firstUpdate = useRef(true);
	useEffect(() => {
		const Debounce = setTimeout(() => {
			if (
				filterStatusMode &&
				orderCampaignsBy &&
				filterBylabels
			) {
				loadCampaignsO(0,setSelectableCampaigns);
				setskipIdNumber(0);
			}
		}, 900);
		return () => clearTimeout(Debounce);
	}, [
		filterTestMode,
		filterStatusMode,
		orderCampaignsBy,
		searchBarvalue,
		filterBylabels,
		devices,

	]);
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}else{
			if(needToSave){
	
				updateReportSetup(selectedCampaigns.items.map(c => ({ 
					devices : c?.devices ? c.devices: [],
					displayArea: c.displayArea,
					indicators: c.indicators,
					targetAudience: c.targetAudience,
					objectiveDescription: c.objectiveDescription,
					campaignId: c.id, 
					engagementLevelId: c.engagementLevelId })));
			}
		}
	}, [selectedCampaigns, needToSave]);

	async function getCpg(campaignsSetupIds) {

		const fetchPromises = campaignsSetupIds.map(id => {
			return new Promise((resolve, reject) => {
				loadCampaign(
					id,
					(element) => {
						resolve(element);
					},
					(error) => {
						reject(error);
					}
				);
			});
		});

		try {
			const results = await Promise.all(fetchPromises);
			return results;
		} catch (error) {
			console.error('Une erreur s\'est produite dans getCpg:', error);
		}
	}

	useEffect(() => {
		if (needTorefresh || selectableCampaigns.loaded && setup.loaded && !selectedCampaigns.loaded) {
			const campaignsSetupById = toDictionary(setup.campaigns, x => x.campaignId);

			const campaignsSetupIds = Object.keys(campaignsSetupById);
			// eslint-disable-next-line no-inner-declarations
			async function fetchDataCampaign() {

				const dataCampaignSelected = await getCpg(campaignsSetupIds);

				const load = (c) => {
					const found = dataCampaignSelected.find(x => x && x.id === c);
					if (found) {
						const campaignSetup = campaignsSetupById[c];
						return { ...found,...campaignSetup};
					}
				};
				const loadedCampaignsSetup = campaignsSetupIds.map(load).filter(x => !!x);

				setSelectedCampaigns({ items: loadedCampaignsSetup, loaded: true });
				setselectedCampaignsFiltered(loadedCampaignsSetup);

				setSelectableCampaigns(prev => ({ ...prev, items: prev.items.filter(x => !campaignsSetupIds.includes(x.id)) }));

				setNeedTorefresh(false);
			}

			fetchDataCampaign();
		}
	}, [selectableCampaigns, selectedCampaigns, setup, needTorefresh]);


	useEffect(() => {
		initialize();
	}, []);


	const initialize = () => {
		loadCampaignsO(0,selectableCampaigns);
		loadReportSetup(setup => {
			setSetup({ campaigns: setup.campaigns, loaded: true });
		});
	};

	const handleCampaignSelected = (selectedCampaign) => {

		const selectedCpgOptions = {...selectedCampaign, ...selectedCampaignOptions};

		function removeAt(array, index, elToReplace) {
			if (index > -1) {
				const newArray = [...array];
	
				newArray[index] = elToReplace;
				return newArray;
			
			}
			return [...array, elToReplace];
		}
		const currentSelectedCampaign = selectedCampaigns.items.find(x=> x.id === selectedCampaign.id);
		let newItems = [];
		if(currentSelectedCampaign){
			const indexOfItem = selectedCampaigns.items.indexOf(currentSelectedCampaign);
			newItems = removeAt(selectedCampaigns.items,indexOfItem,selectedCpgOptions);
		}else{
			newItems = [...selectedCampaigns.items, selectedCpgOptions];
		}

		setSelectedCampaigns(prev => ({ ...prev, items: [...newItems] }));
		setSelectableCampaigns(prev => ({ ...prev, items: prev.items.filter(c => c.id !== selectedCampaign.id) }));
		setValueInSearchBar('');
		setselectedCampaignOptions();
		setneedToSave(true);
	};

	const handleChangeCpgIdOptions = (selectedCampaign) => {
		setselectedCampaignOptions(prev =>({
			...prev, id: selectedCampaign.id , name : selectedCampaign.name,
			description: selectedCampaign.description, devices: selectedCampaign?.devices ?  selectedCampaign?.devices  : []
		}));
	};

	const handleChangeCpgOptions = (name, value) => {
		setselectedCampaignOptions(prev =>({
			...prev, [name]: value		}));
	};


	const handleCampaignUnSelected = (unSelectedCampaign) => {
		setSelectableCampaigns(prev => ({ ...prev, items: [unSelectedCampaign, ...prev.items] }));
		setSelectedCampaigns(prev => ({ ...prev, items: prev.items.filter(c => c.id !== unSelectedCampaign.id) }));
		setneedToSave(true);
	};

	const handleCampaignOrderChange = (items) => {
		setSelectedCampaigns(prev => ({ ...prev, items }));
		setselectedCampaignsFiltered(items);
		setneedToSave(true);
	};

	const handleSelectEngagementLevel = (campaign, level) => {

		const updateLevel = prev => ({ ...prev, items: prev.items.map(x => x.id === campaign.id ? ({ ...x, engagementLevelId: level.id }) : x) });
		setSelectableCampaigns(prev => updateLevel(prev));
		setSelectedCampaigns(prev => updateLevel(prev));
		setneedToSave(true);
	};

	const handleLaunch = (onSuccess) => {
		launchReportingGeneration(() => {
			trackEvent('reportgenerator/lauch-reporting');
			onSuccess();
		});
	};

	const handleReportRecipientEmailSet = (email) => {
		setReportRecipient(prev => ({ ...prev, email }));
	};
	const handleReportRecipientNameSet = (name) => {
		setReportRecipient(prev => ({ ...prev, name }));
	};
	const handleReportRecipientEmailChange = (email) => {
		console.log('blur');
		setNotReadyToLaunchInfo(isReadyToLaunch ? '' : getNotReadyToLaunchInfo());
	};

	const handleReportDatesSet = ({ startDate, endDate }) => {
		setReportDates({ startDate, endDate });
	};

	const getNotReadyToLaunchInfo = () => {
		if (selectedCampaigns.items.length == 0)
			return 'You must select a campaign';
		if (!reportRecipient.email)
			return 'You must enter an email';
		if (!isAnEmail(reportRecipient.email))
			return 'You must enter a valid email';
		if (!reportDates.startDate || !reportDates.endDate)
			return 'You must choose start date and end date';
	};

	const isReadyToEnterLaunchInfo = selectedCampaigns.items.length > 0;
	const isReadyToLaunch = !!reportRecipient.email && isAnEmail(reportRecipient.email) && selectedCampaigns.items.length > 0 && reportDates.startDate && reportDates.endDate;
	//const notReadyToLaunchInfo = isReadyToLaunch ? "" : getNotReadyToLaunchInfo()

	const [notReadyToLaunchInfo, setNotReadyToLaunchInfo] = useState('');

	const isReady = setup.loaded && selectedCampaigns.loaded && selectableCampaigns.loaded;

	const handleChangeValueInSearchBar = (event) => {
		setValueInSearchBar(event.target.value);
	};

	const handleChangeValueInSearchBarSelectedCampaign = (event) => {
		setValueInSearchBarSelectedCampaigns(event.target.value);
	};

	function getKpisCampaign (campaignId){
		reportingCampagneServices.getCampaignInformations(accountId, campaignId, data=> {
			setcampaignSelected(data);
		}, 
		()=> {});
	}


	function handleChangeDeviceSelected(campaign,device){
		let newDevices = [] ;
		if(campaign.hasOwnProperty('devices')){
			newDevices = [...campaign.devices];
		}
		const newKey = newDevices.find(el => el === device);
		const oldD = [...newDevices];
		const indefOfVal = oldD.indexOf(device);
		if(indefOfVal !== -1){
			newDevices = oldD.filter(x => x !== newKey);
		}else{
			newDevices = ([...oldD,device]);
		}
		const updateLevel = prev => ({ ...prev, items: prev.items.map(x => x.id === campaign.id ? ({ ...x, devices: newDevices }) : x) });
		setSelectableCampaigns(prev => updateLevel(prev));
		setSelectedCampaigns(prev => updateLevel(prev));
		setselectedCampaignOptions(prev =>({
			...prev, devices: newDevices		}));
	}
	const resetSource = (campaign) => {
		const updateLevel = prev => ({ ...prev, items: prev.items.map(x => x.id === campaign.id ? ({ ...x, devices: [] }) : x) });
		setSelectableCampaigns(prev => updateLevel(prev));
		setSelectedCampaigns(prev => updateLevel(prev));
		setselectedCampaignOptions(prev =>({
			...prev, devices: []		}));
	};

	return (
		<context.Provider
			value={{
				isReady,
				selectableCampaigns,
				selectedCampaigns,
				engagementLevels,
				reportRecipient,
				reportDates,
				isReadyToLaunch,
				isReadyToEnterLaunchInfo,
				notReadyToLaunchInfo,
				handleCampaignSelected,
				handleCampaignUnSelected,
				handleCampaignOrderChange,
				handleSelectEngagementLevel,
				handleLaunch,
				handleReportRecipientEmailSet,
				handleReportRecipientNameSet,
				handleReportRecipientEmailChange,
				handleReportDatesSet,
				handleChangeValueInSearchBar,
				valueInSearchBar,
				campaigns,
				selectedCampaignOptions,
				modalIsOpen,
				setmodalIsOpen,
				campaignSelected,
				setcampaignSelected,
				handleChangeCpgIdOptions,
				handleChangeCpgOptions,
				setselectedCampaignOptions,
				getKpisCampaign,
				valueInSearchBarSelectedCampaigns,
				selectedCampaignsFiltered,
				handleChangeValueInSearchBarSelectedCampaign,
				screenShotServices,
				accountId,
				systemServices,
				displayImage,
				setDisplayImage,
				isUploaded,
				setisUploaded,
				setup,
				setFilteredType,
				filteredType,
				handleChangeDeviceSelected,
				resetSource,
				filterStatusMode
			}}
		>
			{children}
		</context.Provider>
	);
};

