/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useEffect, useState} from 'react';
import editionStyles from '../../ProductFeedEdition.module.css';
import InputCustom from '../../../../../Components/InputCustom';
import Link from '../../../../../Components/Link';
import Checkbox from '../../../../../Components/Checkbox';
import Modal from '../../../../../Components/Modal';
import SelectCustom from '../../../../../Components/SelectCustom';
import {useProductFeedEditionContext} from '../../../context/ProductFeedEditionContext';
import Btn from '../../../../../Components/Btn';
import {DayPickerSingleDateController} from 'react-dates';
import {isEmpty} from 'lodash';
import {
	fieldIsXpathCustom,
	keyExistInConfig,
	transformXpathToNormalValue,
	transformXpathToXpathValue
} from '../../../utils';
import SelectDropDownWithExampleValues from '../SelectDropDownWithExampleValues';
import TransformationsContainer from '../Transformations/TransformationsContainer';

export const ModalMappingTags = ({
	tagModalIsOpen,
	setTagModalIsOpen,
	hasKeyInObject,
	isExistingTag,
	tagSelected,
	closeModalTagEdition,
	canUseTranformations
}) => {

	const {
		mappingObject,
		productFeedObject,
		metadatasSources,
		handleAddTagCategoryValue,
	} = useProductFeedEditionContext();

	const hasKeyInObjet = keyExistInConfig('Tags', mappingObject, null);

	const [advancedSettingsIsOpen, setAdvancedSettingsIsOpen] = useState(false);
	const [currentTag, setCurrentTag] = useState({name: '', itemName: '', isExcluded: false, isPrivate: false,transformations : null});

	const [xPathSelected, setXPathSelected] = useState(false);
	const [metaDataSourcesToMap, setMetaDataSourcesToMap] = useState([]);
	const [valueDoesntExistInList, setValueDoesntExistInList] = useState(false);

	useEffect(() => {
		if (tagSelected) {
			setCurrentTag({
				name: tagSelected.tag.sourceField,
				itemName: tagSelected.tag.beyableItemName,
				isExcluded: tagSelected.tag.isExcluded,
				isPrivate: tagSelected.tag.isPrivate,
				transformations: tagSelected.tag.transformations  ?  tagSelected.tag.transformations  : null
			});
		} else {
			setCurrentTag(
				{	
					name: '',
					itemName: '',
					isExcluded: false,
					isPrivate: false,
					transformations: null
				});
		}
	}, [tagSelected]);


	useEffect(() => {
		if (productFeedObject?.type === 'GenericUri' && productFeedObject?.format?.type === 'Xml' && metadatasSources) {
			if (hasKeyInObjet) {
				const valueOfField = mappingObject['Tags'][0].sourceField;
				if (fieldIsXpathCustom(valueOfField)) {
					setValueDoesntExistInList(false);
					transformXpathToNormalValue(valueOfField);
					setXPathSelected(true);
					setMetaDataSourcesToMap([...metadatasSources, {
						value: 'Xpath',
						label: 'Custom value - Xpath'
					}]);
				} else {
					const valueOfField = mappingObject['Tags'][0].sourceField;
					const testIfExist = metadatasSources.find(el => el.value === valueOfField);
					if (!testIfExist) {
						setValueDoesntExistInList(true);
					} else {
						setValueDoesntExistInList(false);
					}
					setMetaDataSourcesToMap([...metadatasSources, {
						value: 'Xpath',
						label: 'Custom value - Xpath'
					}]);
					setXPathSelected(false);
				}

			} else {
				setMetaDataSourcesToMap([...metadatasSources, {
					value: 'Xpath',
					label: 'Custom value - Xpath'
				}]);
			}
		} else if (productFeedObject?.type !== 'Custom' && metadatasSources) {
			if (hasKeyInObjet) {
				const valueOfField = mappingObject['Tags'][0].sourceField;
				const testIfExist = metadatasSources.find(el => el.value === valueOfField);
				if (!testIfExist) {
					setValueDoesntExistInList(true);
				} else {
					setValueDoesntExistInList(false);
				}
			}
			setMetaDataSourcesToMap([...metadatasSources]);
		}
	}, [metadatasSources, productFeedObject?.type, xPathSelected]);


	const closeModal = () => {
		setCurrentTag({name: '', itemName: '', isExcluded: false, isPrivate: false, transformations : null});
		setTagModalIsOpen(false);
		closeModalTagEdition();
	};

	const submit = () => {
		handleAddTagCategoryValue(currentTag, !hasKeyInObject, isExistingTag ? tagSelected : false, 'Tags', true);
		closeModal();
	};

	const handleChangeTag = (e, propName) => {
		e.nativeEvent && e.persist();
		if (propName === 'name' && e.target.value === 'Xpath') {
			setXPathSelected(true);
		} else if (propName === 'name') {
			setXPathSelected(false);
		}
		if (propName === 'xPathValue') {
			const xPathValue = transformXpathToXpathValue(e.target.value);
			setCurrentTag(prev => ({...prev, ['name']: xPathValue}));
		}
		else {
			const useCheckValue = propName === 'isPrivate' || propName === 'isExcluded';
			setCurrentTag(prev => ({...prev, [propName]: useCheckValue ? e.target.checked : e.target.value}));
		}
	};
	function replaceAt(array, index, value) {
		const ret = array.slice(0);
		ret[index] = value;
		return ret;
	}
	function addTransformationToObjectIdIdentifier(objToAdd,isExistingKey,setModalIsOpen) {
		
		if(currentTag.transformations){
			const newObjectToSaveo = {...currentTag};
			if(isExistingKey || newObjectToSaveo.transformations !== null){
				const newObjectToSave = {...currentTag};
				if(!newObjectToSave.transformations?.list) {
	
					const newObjectToSave = {...currentTag.transformations};
					newObjectToSave.transformations = {
						list : []
					};
					newObjectToSave.transformations['list'] = [objToAdd];
					const mappingObjectCopy = {...mappingObject};
					mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
					setModalIsOpen(false);
					setCurrentTag({
						...currentTag,
						transformations: {...newObjectToSave.transformations}
					});
				}else{
					const oldItem = newObjectToSave.transformations.list.find(x =>x.uuid === objToAdd.uuid);
					if(oldItem === undefined){
						const newObject =[...newObjectToSave.transformations.list, objToAdd];
						newObjectToSave.transformations.list = newObject;
						const mappingObjectCopy = {...mappingObject};
						mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
						setCurrentTag({
							...currentTag,
							transformations: {...newObjectToSave.transformations}
						});
						setModalIsOpen(false);
					}else{
	
						const duplicatedPanelIndex = newObjectToSave.transformations.list.indexOf(oldItem);
							
						const newObject =	replaceAt(newObjectToSave.transformations.list, duplicatedPanelIndex,objToAdd );
						newObjectToSave.transformations.list = newObject;
						const mappingObjectCopy = {...mappingObject};
						mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
						setCurrentTag({
							...currentTag,
							transformations: {...newObjectToSave.transformations}
						});
						setModalIsOpen(false);
					}
				}
			}
		}else{
			
			const newObjectToSave = {...currentTag.transformations};
			newObjectToSave.transformations = {
				list : []
			};
			newObjectToSave.transformations['list'] = [objToAdd];
			const mappingObjectCopy = {...mappingObject};
			mappingObjectCopy[newObjectToSave.beyableField] = [newObjectToSave];
			setModalIsOpen(false);
			setCurrentTag({
				...currentTag,
				transformations: {...newObjectToSave.transformations}
			});
		}
	}
	function handleDrag(newObj){
		setCurrentTag(...newObj);
	}
	return (
		<Modal
			isOpen={tagModalIsOpen}
			onClose={() => closeModal()}
		>
			<div className="modal_header has_border">
				{isExistingTag ? tagSelected.tag.sourceField : 'New tag'}
			</div>
			<div className="modal_body">
				<div className={editionStyles.settings_container_tag_mapping_modal}>
					{productFeedObject?.type !== 'Custom' &&
						<SelectDropDownWithExampleValues
							editionStyles={editionStyles}
							metaDataSourcesToMap={metadatasSources}
							isCategory={true}
							valueDoesntExistInList={false}
							hasKeyInObjet={true}
							mappingObject={currentTag}
							name={currentTag.name !== '' ? currentTag.name : 'choose'}
							label={currentTag.name !== '' ? currentTag.name : 'Field'}
							handleChangeForm={(e) => handleChangeTag(e, 'name')}
						/>
					}
					{productFeedObject?.type === 'Custom' &&
                        <InputCustom
                        	value={currentTag.name !== '' ? currentTag.name : ''}
                        	type="text"
                        	label={'Field'}
                        	name={'name'}
                        	placeholder=""
                        	onChange={(e) => handleChangeTag(e, 'name')}
                        	fullWidth={true}
                        	size="l"
                        />
					}

					{xPathSelected &&
                        <InputCustom
                        	value={currentTag.name !== '' ? transformXpathToNormalValue(currentTag.name) : ''}
                        	type="text"
                        	label="Custom value"
                        	name={'name'}
                        	placeholder="Xpath"
                        	onChange={(e) => handleChangeTag(e, 'xPathValue')}
                        	fullWidth={true}
                        	size="l"
                        />
					}

					<InputCustom
						label="Tag name"
						name="name"
						value={currentTag.itemName || ''}
						fullWidth={true}
						type="text"
						placeholder={currentTag.name !== '' ? 'Leave empty to use ' + currentTag.name : ''}
						onChange={(e) => handleChangeTag(e, 'itemName')}
						size="l"
					/>

					{canUseTranformations && currentTag && currentTag.name !== '' &&
						<TransformationsContainer 
							objectIdenfifier={'Tags'} 
							idIdentifier={currentTag}
							addTransformationToObjectIdIdentifier={addTransformationToObjectIdIdentifier}
							handleDrag={handleDrag}
							inTagModal={true}
						/>
					}
				</div>

				<Link
					className='s_14'
					onClick={() => setAdvancedSettingsIsOpen(!advancedSettingsIsOpen)}
					icon={advancedSettingsIsOpen ? 'fas fa-caret-up fa-fw"' : 'fas fa-caret-down fa-fw"'}
					iconPosition={'after'}
					message={advancedSettingsIsOpen ? 'Hide advanced settings' : 'Show advanced settings'}
				/>
				
				{advancedSettingsIsOpen &&
					<div className='mt_20'>
						<Checkbox
							forcedChecked={currentTag.name !== '' ? currentTag.isPrivate : false}
							onChange={(e) => handleChangeTag(e, 'isPrivate')}
							fullWidth={true}
						>
							<div>Confidential data</div>
							<div className='mt_2 grey_2 s_13'>
								This data must not be accessible on client side.
							</div>
						</Checkbox>
						<Checkbox
							forcedChecked={currentTag.name !== '' ? currentTag.isExcluded : false}
							onChange={(e) => handleChangeTag(e, 'isExcluded')}
							fullWidth={true}
						>
							<div>Ignore for product recommendations</div>
							<div className='mt_2 grey_2 s_13'>
								This field is not useful for generating product recommendations.
							</div>
						</Checkbox>
					</div>
				}
			</div>
			<div className="modal_footer has_border al_right">
				<Btn
					message={'Cancel'}
					style={'ghost'}
					color={'secondary'}
					onClick={() => closeModal()}
				/>
				<Btn
					message={'Ok'}
					onClick={() => submit()}
					disabled={currentTag.name === ''}
				/>
			</div>
		</Modal>
	);
};
