import React, { useState, useEffect } from 'react';

import Nav from '../Nav/Nav';

export default function NavBuyingBehavior({
	ComparisonSelected,
	setNavFilter,
	initialDates,
	handleSelectComparaisonPeriodHandler,
	comparisonSelected,
	setisCustomSelected,
	isCustomSelected,
	customDateRange,
	setCustomDateRange
}) {

	function handleClickOriginal(obj) {
		setNavFilter(stateInit => ({
			...stateInit,
			fromDate: obj.fromDate,
			toDate: obj.toDate
		}
		));
	}
	function handleClickCompared(obj) {
		setNavFilter(stateInit => ({
			...stateInit,
			fromDateToCompare: obj.fromDate,
			toDateToCompare: obj.toDate
		}
		));
	}
	return (<>
		<Nav
			handleClick={handleClickOriginal}
			initialDates={initialDates}
			showComparisonToggler={false}
			setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
			comparisonSelected={ComparisonSelected}
			setisCustomSelected={setisCustomSelected}
			isCustomSelected={isCustomSelected}
			customDateRange={customDateRange}
			setCustomDateRange={setCustomDateRange}
		/>
	</>
	);
}
