import React from 'react';
import {Article} from '../../../../../Components/Article/Article';
import CardHeader from './CardHeader';
import Metric from '../Metric';
import Tag from '../Tag';
import CardIcon from './CardIcon';
import SentenceGenerator from '../SentenceGenerator';
// import ProductInfo from './ProductInfo';
// import HelpfulLinks from './HelpfulLinks';
import {EngagementLevel, engagementLevels} from '../../utils';
import {FindingDataItem, AiInsight} from '../../context/AiInsightsApiTypesServices';

interface InsightsCardProps {
	insight: AiInsight;
}

const InsightsCard: React.FC<InsightsCardProps> = ({insight}) => (
	<Article innerSize='l'>
		<CardHeader category={insight.category}/>
		<div className="flex flex_align_start">
			<div className='flex_item_fix'>
				<CardIcon findingType={insight.findingType} favorableness={insight.favorableness}/>
			</div>
			<div className='flex_item_full'>
				<div className='s_16'>
					<SentenceGenerator insight={insight}/>
				</div>
				<div className="flex">
					{/* {insight.category === "Product" && <ProductInfo data={insight.findingAdditionalParams} />} */}
					{insight.findingData && Object.entries<FindingDataItem>(insight.findingData).map(([, value], index) => (
						<Metric
							key={index}
							label={value.translatedDescription}
							value={value.absolute}
							type={value.absoluteType}
							percentage={value.relative}
							isFavorable={value.favorableness}
						/>
					))}
				</div>
				<div className="flex">
					{insight.dimensions &&
						Object.entries(insight.dimensions).map(([key, value], index) => {
							if (key === 'VisitorEngagement') {
								const engagement = engagementLevels.find(
									(level: EngagementLevel) => level.value === value
								);
								value = engagement ? engagement.label : value;
							}
							return <Tag key={index} text={String(value)}/>;
						})}
				</div>
				{/* {helpfulLinks && <HelpfulLinks links={helpfulLinks} />} */}
			</div>
		</div>
	</Article>
);

export default InsightsCard;