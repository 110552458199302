import React, { useState, useEffect } from 'react';
import styles from './ChartContainer.module.css';
import ChartSwitch from '../ChartSwitch/ChartSwitch';
import { FORMAT } from '../../config';

import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';

import moment from 'moment';

import _ from 'lodash';
import { useSessionContext } from '../../context/SessionContext';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import DropDownTypeChart from './DropDownTypeChart';
import SelectMetricTypeSessionsContainer from '../Sessions/SelectMetricTypeSessionsContainer';
import {
	metricsTypes
} from '../Sessions/config';

const SessionsChartContainer = ({
	service,
	localConfig,
	selectedDimension,
	setSelectedDimension,
	useTimeDisabled,
	setUseTimeDisabled,
	selectedTimeGranularity,
	setSelectedTimeGranularity,

}) => {
	// const { features } = useFeature();

	const { navFilter,
		ComparisonSelected,
		chartData,
		setChartData,
		metricTypeSessionsSelected
	} = useSessionContext();

	const { selectedTenant } = useAnalyticsContext();

	const [timeGranularityOptions, setTimeGranularityOptions] = useState([
		{ key: 'Day' },
	]);

	const [currentNavFilter, setCurrentNavFilter] = useState();

	const [splitDropdownIsOpen, setSplitDropdownIsOpen] = useState(false);

	let defaultOption = {
		fromDate: navFilter.fromDate,
		toDate: navFilter.toDate,
		metrics: [...metricTypeSessionsSelected.value],
		timeGranularity: 'Day',
		dimensions: [],
		filters: [],
	};

	useEffect(() => {
		setUseTimeDisabled(selectedDimension.splitDimension.value === 'None');
	}, []);

	const setupTimeGranularityOptions = () => {
		if (!navFilter) return;
		const dateRange = moment(navFilter.toDate, FORMAT).diff(
			moment(navFilter.fromDate, FORMAT),
			'days'
		);
		let granularityOptions;
		if (dateRange < 14) {
			granularityOptions = [{ key: 'Day' }];
		} else if (dateRange < 32) {
			granularityOptions = [
				{ key: 'Day' },
				{ key: 'MondayWeek', label: 'Week', isDefault: true },
			];
		} else if (dateRange < 64) {
			granularityOptions = [
				{ key: 'MondayWeek', label: 'Week' },
				{ key: 'Month' },
			];
		} else if (dateRange < 366) {
			granularityOptions = [{ key: 'Month' }];
		} else {
			granularityOptions = [{ key: 'Year' }];
		}
		setTimeGranularityOptions(granularityOptions);
		return granularityOptions;
	};

	const colors = [
		'#4278F0',
		'#FFB320',
		'#D7AB67',
		'#002453',
		'#7E67D7',
		'#9E9E9E',
	];
	function generateColorCode() {
		var makeColorCode = '0123456789ABCDEF';
		var code = '#';
		for (var count = 0; count < 6; count++) {
			code = code + makeColorCode[Math.floor(Math.random() * 16)];
		}
		return code;
	}
	const colorsByDimensionValues = {};
	function getDimensionColorCode(dimension) {
		if (!colorsByDimensionValues[dimension]) {
			colorsByDimensionValues[dimension] = generateColorCode();
		}
		return colorsByDimensionValues[dimension];
	}

	const refreshChart = (option) => {
		setChartData({ isLoading: true });
		function successNoUseTime(data) {
			if (data.some((x) => !x.Dimensions)) return;

			const barDatasetsWithOneDimensions = () => {
				if (!ComparisonSelected) {
					let series = [];
					if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} (page views)`,
								data: [v.NumberOfPageViews],
								color: getDimensionColorCode(value),
							};
							const series2 = {
								name: `${value} (sessions)`,
								data: [v.NumberOfSessions],
								color: getDimensionColorCode(value),
							};
							return [series1, series2];
						});

					}
					if (metricTypeSessionsSelected.key === 'NumberOfPageViews') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} (Number of page views)`,
								data: [v.NumberOfPageViews],
								color: getDimensionColorCode(value),
							};
							return [series1];
						});
					}
					if (metricTypeSessionsSelected.key === 'NumberOfSessions') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} (number of sessions)`,
								data: [v.NumberOfSessions],
								color: getDimensionColorCode(value),
							};
							return [series1];
						});
					}
					if (metricTypeSessionsSelected.key === 'AverageTimeOnPage') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} (Average Time On Page)`,
								data: [v.DurationSeconds],
								color: getDimensionColorCode(value),
							};
							return [series1];
						});
					}
					if (metricTypeSessionsSelected.key === 'BounceRate') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} Number of bounce)`,
								data: [v.NumberOfBounces],
								color: getDimensionColorCode(value),
							};
							return [series1];
						});
					}
					return series;
				} else {
					let series = [];
					if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} (page views) period 1`,
								data: [v.NumberOfPageViewsPeriod1],
								color: getDimensionColorCode(value),
							};
							const series2 = {
								name: `${value} (sessions) period 1`,
								data: [v.NumberOfSessionsPeriod1],
								color: getDimensionColorCode(value),
							};

							const series1bis = {
								name: `${value} (page views) compare`,
								data: [v.NumberOfPageViewsPeriod2],
								color: getDimensionColorCode(value),
							};
							const series2bis = {
								name: `${value} (sessions) compare`,
								data: [v.NumberOfSessionsPeriod2],
								color: getDimensionColorCode(value),
							};
							return [series1, series1bis, series2, series2bis];
						});
					} if (metricTypeSessionsSelected.key === 'NumberOfPageViews') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} (page views) period 1`,
								data: [v.NumberOfPageViewsPeriod1],
								color: getDimensionColorCode(value),
							};
							const series1bis = {
								name: `${value} (page views) compare`,
								data: [v.NumberOfPageViewsPeriod2],
								color: getDimensionColorCode(value),
							};
							return [series1, series1bis];
						});
					} if (metricTypeSessionsSelected.key === 'NumberOfSessions') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} (Number of sessions) period 1`,
								data: [v.NumberOfSessionsPeriod1],
								color: getDimensionColorCode(value),
							};
							const series1bis = {
								name: `${value} (Number of sessions) compare`,
								data: [v.NumberOfSessionsPeriod2],
								color: getDimensionColorCode(value),
							};
							return [series1, series1bis];
						});
					} if (metricTypeSessionsSelected.key === 'AverageTimeOnPage') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} (Average Time On Page) period 1`,
								data: [v.DurationSecondsPeriod1],
								color: getDimensionColorCode(value),
							};
							const series1bis = {
								name: `${value} (Average Time On Page) compare`,
								data: [v.DurationSecondsPeriod2],
								color: getDimensionColorCode(value),
							};
							return [series1, series1bis];
						});
					}
					if (metricTypeSessionsSelected.key === 'BounceRate') {
						series = data.flatMap((v) => {
							const value = v.Dimensions[option.dimensions[0]];
							const series1 = {
								name: `${value} (Number of bounce) period 1`,
								data: [v.NumberOfBouncesPeriod1],
								color: getDimensionColorCode(value),
							};
							const series1bis = {
								name: `${value} (Number of bounce) compare`,
								data: [v.NumberOfBouncesPeriod2],
								color: getDimensionColorCode(value),
							};
							return [series1, series1bis];
						});
					}
					return series;
				}
			};

			const pieNumberDatasetsWithOneDimensions = (nameOfMetric) => {
				if (!ComparisonSelected) {
					const numberOfViewsSum = data
						.map((v) => v[nameOfMetric])
						.reduce((a, b) => a + b);
					if (!numberOfViewsSum) return [];
					var series = data.flatMap((v) => {
						const value = v.Dimensions[option.dimensions[0]];
						const series2 = {
							name: `${value}`,
							y: (v[nameOfMetric] * 100) / numberOfViewsSum,
							data: [v[nameOfMetric]],
							color: getDimensionColorCode(value),
						};

						return [series2];
					});
					return series;
				} else {
					const numberSum1 = data
						.map((v) => v[nameOfMetric + 'Period1'])
						.reduce((a, b) => a + b);
					const numberOfSum2 = data
						.map((v) => v[nameOfMetric + 'Period2'])
						.reduce((a, b) => a + b);
					if (!numberSum1 || !numberOfSum2) return [];
					let series1 = [];
					let series2 = [];
					series1 = data.flatMap((v) => {
						const value = v.Dimensions[option.dimensions[0]];
						const series2 = {
							name: `${value} `,
							y: (v[nameOfMetric + 'Period1'] * 100) / numberSum1,
							data: [v[nameOfMetric + 'Period1']],
							color: getDimensionColorCode(value),
						};

						return [series2];
					});
					series2 = data.flatMap((v) => {
						const value = v.Dimensions[option.dimensions[0]];
						const series2Bis = {
							name: `${value} `,
							y: (v[nameOfMetric + 'Period2'] * 100) / numberOfSum2,
							data: [v[nameOfMetric + 'Period2']],
							color: getDimensionColorCode(value),
						};

						return [series2Bis];
					});

					return {
						series1: series1,
						series2: series2
					};
				}
			};

			const barDatasets = barDatasetsWithOneDimensions();

			function getChartForPiesTab() {
				let chartObject = [];
				// Pie chart for tab
				if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews' ||
					metricTypeSessionsSelected.key === 'NumberOfSessions') {
					chartObject.push({
						type: 'pie',
						labels: [''],
						datasets: pieNumberDatasetsWithOneDimensions('NumberOfSessions'),
						title: `Number of sessions by ${option.dimensions[0]}`,
						key: 0
					});
				}
				if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews' ||
					metricTypeSessionsSelected.key === 'NumberOfPageViews') {
					chartObject.push({
						type: 'pie',
						labels: [''],
						datasets: pieNumberDatasetsWithOneDimensions('NumberOfPageViews'),
						title: `Number of Page Views by ${option.dimensions[0]}`,
						key: metricTypeSessionsSelected.key === 'sessionsAndPagesViews' ? 1 : 0
					});
				}
				if (metricTypeSessionsSelected.key === 'AverageTimeOnPage') {
					chartObject.push({
						type: 'pie',
						labels: [''],
						datasets: pieNumberDatasetsWithOneDimensions('DurationSeconds'),
						title: `Average Time On Page by ${option.dimensions[0]}`,
						key: 0
					});
				}
				if (metricTypeSessionsSelected.key === 'BounceRate') {
					chartObject.push({
						type: 'pie',
						labels: [''],
						datasets: pieNumberDatasetsWithOneDimensions('NumberOfBounces'),
						title: `Number of bounce by ${option.dimensions[0]}`,
						key: 0
					});
				}
				// Bar chart 
				if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews') {
					chartObject.push({
						type: 'bar',
						labels: barDatasets.map((x) => x.name),
						datasets: barDatasets,
						title: `Sessions And Page Views by ${option.dimensions[0]}`,
						key: 2
					});
				}
				if (metricTypeSessionsSelected.key === 'NumberOfSessions') {
					chartObject.push({
						type: 'bar',
						labels: barDatasets.map((x) => x.name),
						datasets: barDatasets,
						title: `Number of sessions by ${option.dimensions[0]}`,
						key: 1
					});
				}
				if (metricTypeSessionsSelected.key === 'NumberOfPageViews') {
					chartObject.push({
						type: 'bar',
						labels: barDatasets.map((x) => x.name),
						datasets: barDatasets,
						title: `Number of page views by ${option.dimensions[0]}`,
						key: 1
					});
				}
				if (metricTypeSessionsSelected.key === 'AverageTimeOnPage') {
					chartObject.push({
						type: 'bar',
						labels: barDatasets.map((x) => x.name),
						datasets: barDatasets,
						title: `Average Time On Page by ${option.dimensions[0]}`,
						key: 1
					});
				}
				if (metricTypeSessionsSelected.key === 'BounceRate') {
					chartObject.push({
						type: 'bar',
						labels: barDatasets.map((x) => x.name),
						datasets: barDatasets,
						title: `Number of bounce by ${option.dimensions[0]}`,
						key: 1
					});
				}
				return chartObject;
			}
			setChartData({
				charts: getChartForPiesTab(),
				isLoading: false,
				isError: false,
			});
		}

		function getDataWithoutComparison(option) {
			service.getSessions(
				option,
				selectedDimension.useTime
					? successWithUseTime
					: successNoUseTime,
				error
			);
		}
		function getDataWithComparison(option) {
			service.getSessionsCompare(
				option,
				selectedDimension.useTime
					? successWithUseTime
					: successNoUseTime,
				error
			);
		}

		function successWithUseTime(data) {
			function toDateLookup(values) {
				return values.reduce((acc, val) => {
					if (acc.hasOwnProperty(val.Date)) {
						return { ...acc, [val.Date]: [...acc[val.Date], val] };
					}
					return { ...acc, [val.Date]: [val] };
				}, {});
			}
			function toDateLookupComparison(values) {
				return values.reduce((acc, val) => {
					if (acc.hasOwnProperty(val.DatePeriod1)) {
						return {
							...acc,
							[val.DatePeriod1]:
								[...acc[val.DatePeriod1], val]
						};
					}
					return { ...acc, [val.DatePeriod1]: [val] };
				}, {});
			}

			const listOfMetrics = metricTypeSessionsSelected.value.map((metric) => {
				const findMetric = metricsTypes.find((t) => t.key === metric);
				return { ...findMetric };
			});
			const dates = data.reduce((st, d) => st.add(d.Date), new Set());
			const datesWithComparison = data.reduce((st, d) => st.add(d.DatePeriod1), new Set());
			const datesWithComparison2 = (data.map(d => ({
				...GetPercentVariationByMetrics(d),
				DatePeriod2: d.DatePeriod2,
				DatePeriod1: d.DatePeriod1,
				labelPeriod1: moment(d.DatePeriod1).format('MMM DD'),
				labelPeriod2: moment(d.DatePeriod2).format('MMM DD'),
			})));

			function GetPercentVariationByMetrics(d) {
				let objToReturn = {};
				if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews') {
					objToReturn['NumberOfPageViewsPercentVariation'] = d.NumberOfPageViewsPercentVariation || 0;
					objToReturn['NumberOfSessionsPercentVariation'] = d.NumberOfSessionsPercentVariation || 0;
				}
				if (metricTypeSessionsSelected.key === 'NumberOfPageViews') {
					objToReturn['NumberOfPageViewsPercentVariation'] = d.NumberOfPageViewsPercentVariation || 0;
				}
				if (metricTypeSessionsSelected.key === 'NumberOfSessions') {
					objToReturn['NumberOfSessionsPercentVariation'] = d.NumberOfSessionsPercentVariation || 0;
				}
				if (metricTypeSessionsSelected.key === 'AverageTimeOnPage') {
					objToReturn['DurationSecondsPercentVariation'] = d.DurationSecondsPercentVariation || 0;
				}
				if (metricTypeSessionsSelected.key === 'BounceRate') {
					objToReturn['NumberOfBouncesPercentVariation'] = d.NumberOfBouncesPercentVariation || 0;
				}
				return objToReturn;
			}

			var dataLookup = toDateLookup(data);
			var dataLookupComparison = toDateLookupComparison(data);

			var isCompare = false;

			const datasetsNoDimensions = () => {

				let series = [];
				if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews') {
					const series1 = {
						name: 'Number of Pages views',
						data: [...dates].map(
							(date) => dataLookup[date][0].NumberOfPageViews
						),
						color: colors[0],
					};
					const series2 = {
						name: 'Number of Sessions',
						data: [...dates].map(
							(date) => dataLookup[date][0].NumberOfSessions
						),
						color: colors[1],
					};
					series = [series1, series2];
				}
				if (metricTypeSessionsSelected.key === 'NumberOfPageViews') {
					const series1 = {
						name: 'Number of Pages views',
						data: [...dates].map(
							(date) => dataLookup[date][0].NumberOfPageViews
						),
						color: colors[0],
					};
					series = [series1];
				}
				if (metricTypeSessionsSelected.key === 'NumberOfSessions') {
					const series1 = {
						name: 'Number of Sessions',
						data: [...dates].map(
							(date) => dataLookup[date][0].NumberOfSessions
						),
						color: colors[1],
					};
					series = [series1];
				}
				if (metricTypeSessionsSelected.key === 'AverageTimeOnPage') {
					const series1 = {
						name: 'Average Time On Page',
						data: [...dates].map(
							(date) => dataLookup[date][0].DurationSeconds
						),
						color: colors[1],
					};
					series = [series1];
				}
				if (metricTypeSessionsSelected.key === 'BounceRate') {
					const series1 = {
						name: 'Number of bounce',
						data: [...dates].map(
							(date) => dataLookup[date][0].NumberOfBounces
						),
						color: colors[1],
					};
					series = [series1];
				}
				return series;
			};
			const datasetsNoDimensionsWithComparison = () => {
				let series = [];
				if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews') {
					const series1 = {
						name: 'Number of Pages views period 1',
						metric: 'NumberOfPageViews',
						data: [...datesWithComparison2].map(

							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod1 && dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod1 !== 0 &&
								dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod1 || 0
						),

						color: colors[0],
					};
					const series2 = {
						name: 'Number of Sessions period 1',
						metric: 'NumberOfSessions',
						data: [...datesWithComparison2].map(
							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod1 && dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod1 !== 0 && dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod1 || 0
						),
						color: colors[1],
					};
					const series1Bis = {
						name: 'Number of Pages views period 2',
						metric: 'NumberOfPageViews',
						data: [...datesWithComparison2].map(
							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod2 && dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod2 !== 0 && dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod2 || 0
						),
						color: colors[4],
					};
					const series2Bis = {
						name: 'Number of Sessions period 2',
						metric: 'NumberOfSessions',
						data: [...datesWithComparison2].map(
							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod2 && dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod2 !== 0 && dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod2 || 0
						),
						color: colors[2],
					};
					series = [series1, series1Bis, series2, series2Bis];
				}
				if (metricTypeSessionsSelected.key === 'NumberOfPageViews') {
					const series1 = {
						name: 'Number of Pages views period 1',
						metric: 'NumberOfPageViews',
						data: [...datesWithComparison2].map(

							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod1 && dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod1 !== 0 &&
								dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod1 || 0
						),

						color: colors[0],
					};
					const series1Bis = {
						name: 'Number of Pages views period 2',
						metric: 'NumberOfPageViews',
						data: [...datesWithComparison2].map(
							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod2 && dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod2 !== 0 && dataLookupComparison[date.DatePeriod1][0].NumberOfPageViewsPeriod2 || 0
						),
						color: colors[4],
					};

					series = [series1, series1Bis];
				}
				if (metricTypeSessionsSelected.key === 'NumberOfSessions') {
					const series1 = {
						name: 'Number of Sessions period 1',
						metric: 'NumberOfSessions',
						data: [...datesWithComparison2].map(

							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod1 && dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod1 !== 0 &&
								dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod1 || 0
						),

						color: colors[0],
					};
					const series1Bis = {
						name: 'Number of Sessions period 2',
						metric: 'NumberOfSessions',
						data: [...datesWithComparison2].map(
							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod2 && dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod2 !== 0 && dataLookupComparison[date.DatePeriod1][0].NumberOfSessionsPeriod2 || 0
						),
						color: colors[4],
					};

					series = [series1, series1Bis];
				}
				if (metricTypeSessionsSelected.key === 'AverageTimeOnPage') {
					const series1 = {
						name: 'Average time on page period 1',
						metric: 'AverageTimeOnPage',
						data: [...datesWithComparison2].map(

							(date) => dataLookupComparison[date.DatePeriod1][0].DurationSecondsPeriod1 && dataLookupComparison[date.DatePeriod1][0].DurationSecondsPeriod1 !== 0 &&
								dataLookupComparison[date.DatePeriod1][0].DurationSecondsPeriod1 || 0
						),

						color: colors[0],
					};
					const series1Bis = {
						name: 'Average time on page period 2',
						metric: 'AverageTimeOnPage',
						data: [...datesWithComparison2].map(
							(date) => dataLookupComparison[date.DatePeriod1][0].DurationSecondsPeriod2 && dataLookupComparison[date.DatePeriod1][0].DurationSecondsPeriod2 !== 0 && dataLookupComparison[date.DatePeriod1][0].DurationSecondsPeriod2 || 0
						),
						color: colors[4],
					};

					series = [series1, series1Bis];
				}
				if (metricTypeSessionsSelected.key === 'BounceRate') {
					const series1 = {
						name: 'Number of bounce period 1',
						metric: 'BounceRate',
						data: [...datesWithComparison2].map(

							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfBouncesPeriod1 && dataLookupComparison[date.DatePeriod1][0].NumberOfBouncesPeriod1 !== 0 &&
								dataLookupComparison[date.DatePeriod1][0].NumberOfBouncesPeriod1 || 0
						),

						color: colors[0],
					};
					const series1Bis = {
						name: 'Number of bounce  period 2',
						metric: 'BounceRate',
						data: [...datesWithComparison2].map(
							(date) => dataLookupComparison[date.DatePeriod1][0].NumberOfBouncesPeriod2 && dataLookupComparison[date.DatePeriod1][0].NumberOfBouncesPeriod2 !== 0 && dataLookupComparison[date.DatePeriod1][0].NumberOfBouncesPeriod2 || 0
						),
						color: colors[4],
					};

					series = [series1, series1Bis];
				}

				return series;
			};

			const datasetsWithOneDimensions = () => {
				var dimension = option.dimensions[0];
				var dimensionValues = data
					.filter((x) => x.Dimensions)
					.map((x) => x.Dimensions[dimension])
					.filter((v, i, self) => self.indexOf(v) === i);
				var series = dimensionValues.flatMap((v) => {
					const value = (date) => {
						const valuesForDimensionAtDate =
							dataLookup[date].find((x) =>
								_.isEqual(x.Dimensions, { [dimension]: v })
							) || {};
						return valuesForDimensionAtDate;
					};

					let seriesToReturn = [];
					if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews') {
						const series1 = {
							name: `${v} (page views)`,
							data: [...dates].map(
								(date) => value(date).NumberOfPageViews || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfPageViews',
						};
						const series2 = {
							name: `${v} (sessions)`,
							data: [...dates].map(
								(date) => value(date).NumberOfSessions || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfSessions',
						};
						seriesToReturn = [series1, series2];
					}
					if (metricTypeSessionsSelected.key === 'NumberOfPageViews') {
						const series1 = {
							name: `${v} (page views)`,
							data: [...dates].map(
								(date) => value(date).NumberOfPageViews || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfPageViews',
						};
						seriesToReturn = [series1];
					}
					if (metricTypeSessionsSelected.key === 'NumberOfSessions') {
						const series1 = {
							name: `${v} (page views)`,
							data: [...dates].map(
								(date) => value(date).NumberOfSessions || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfSessions',
						};
						seriesToReturn = [series1];
					}
					if (metricTypeSessionsSelected.key === 'AverageTimeOnPage') {
						const series1 = {
							name: `${v} (page views)`,
							data: [...dates].map(
								(date) => value(date).DurationSeconds || 0
							),
							color: getDimensionColorCode(v),
							stack: 'AverageTimeOnPage',
						};
						seriesToReturn = [series1];
					}
					if (metricTypeSessionsSelected.key === 'BounceRate') {
						const series1 = {
							name: `${v} (page views)`,
							data: [...dates].map(
								(date) => value(date).NumberOfBounces || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfBounces',
						};
						seriesToReturn = [series1];
					}
					return seriesToReturn;
				});
				return series;
			};
			const datasetsWithOneDimensionsComparison = () => {
				var dimension = option.dimensions[0];
				var dimensionValues = data
					.filter((x) => x.Dimensions)
					.map((x) => x.Dimensions[dimension])
					.filter((v, i, self) => self.indexOf(v) === i);

				const datesWithComparison2MultipleDimensions = data.reduce((acc, curr) => {
					if (acc.labelsToMaped.indexOf(curr.DatePeriod1) === -1) {
						acc = {
							...acc,
							labelsToMaped: [...acc.labelsToMaped, curr.DatePeriod1],
						};
					}
					return acc;
				}, {
					labelsToMaped: [],
					dataForLabels: {}
				});
				var series = dimensionValues.flatMap((v) => {
					const value = (date) => {
						const valuesForDimensionAtDate =
							dataLookupComparison[date].find((x) =>
								_.isEqual(x.Dimensions, { [dimension]: v })
							) || {};
						return valuesForDimensionAtDate;
					};
					let seriesToReturn = [];
					if (metricTypeSessionsSelected.key === 'sessionsAndPagesViews') {
						const series1 = {
							name: `${v} (page views) period 1`,
							metric: 'NumberOfPageViews',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfPageViewsPeriod1 || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfPageViews',
						};
						const series2 = {
							name: `${v} (sessions) period 1`,
							metric: 'NumberOfSessions',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfSessionsPeriod1 || 0

							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfSessions',
						};
						const series1Bis = {
							name: `${v} (page views) period 2`,
							metric: 'NumberOfPageViews',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfPageViewsPeriod2 || 0

							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfPageViews',
						};
						const series2Bis = {
							name: `${v} (sessions) period 2`,
							metric: 'NumberOfSessions',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfSessionsPeriod2 || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfSessions',
						};

						seriesToReturn = [series1, series1Bis, series2, series2Bis];
					}
					if (metricTypeSessionsSelected.key === 'NumberOfPageViews') {
						const series1 = {
							name: `${v} (number of page views) period 1`,
							metric: 'NumberOfPageViews',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfPageViewsPeriod1 || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfPageViews',
						};

						const series1Bis = {
							name: `${v} (number of page views) period 2`,
							metric: 'NumberOfPageViews',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfPageViewsPeriod2 || 0

							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfPageViews',
						};
						seriesToReturn = [series1, series1Bis,];
					}
					if (metricTypeSessionsSelected.key === 'NumberOfSessions') {
						const series1 = {
							name: `${v} (Number of Sessions) period 1`,
							metric: 'NumberOfSessions',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfSessionsPeriod1 || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfSessions',
						};

						const series1Bis = {
							name: `${v} (Number of Sessions) period 2`,
							metric: 'NumberOfSessions',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfSessionsPeriod2 || 0

							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfSessions',
						};
						seriesToReturn = [series1, series1Bis,];
					}
					if (metricTypeSessionsSelected.key === 'AverageTimeOnPage') {
						const series1 = {
							name: `${v} (Average time on page) period 1`,
							metric: 'AverageTimeOnPage',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).DurationSecondsPeriod1 || 0
							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfSessions',
						};

						const series1Bis = {
							name: `${v} (Average time on page) period 2`,
							metric: 'AverageTimeOnPage',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).DurationSecondsPeriod2 || 0

							),
							color: getDimensionColorCode(v),
							stack: 'NumberOfSessions',
						};
						seriesToReturn = [series1, series1Bis,];
					}
					if (metricTypeSessionsSelected.key === 'BounceRate') {
						const series1 = {
							name: `${v} (Number of bounce) period 1`,
							metric: 'BounceRate',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfBouncesPeriod1 || 0
							),
							color: getDimensionColorCode(v),
							stack: 'BounceRate',
						};

						const series1Bis = {
							name: `${v} (Number of bounce) period 2`,
							metric: 'BounceRate',
							data: [...datesWithComparison2MultipleDimensions.labelsToMaped].map(
								(date) => value(date).NumberOfBouncesPeriod2 || 0

							),
							color: getDimensionColorCode(v),
							stack: 'BounceRate',
						};
						seriesToReturn = [series1, series1Bis,];
					}
					return seriesToReturn;
				});
				return series;
			};
			function getDataSets() {
				if (option.dimensions.length === 1) {
					if (!ComparisonSelected) {
						isCompare = false;
						return datasetsWithOneDimensions();
					} else {
						isCompare = true;
						return datasetsWithOneDimensionsComparison();
					}
				} else {
					if (!ComparisonSelected) {
						isCompare = false;
						return datasetsNoDimensions();
					} else {
						isCompare = true;
						return datasetsNoDimensionsWithComparison();
					}
				}
			}
			const datasets = getDataSets();
			const labels = getLabels();
			function getLabels() {
				if (!ComparisonSelected) {
					return (dates &&
						[...dates].map((d) =>
							moment(d).format('MMM DD YYYY')
						)) ||
						[];
				} else {
					return (datesWithComparison &&
						[...datesWithComparison].map((d) => {
							return moment(d).format('MMM DD YYYY');
						}
						)) ||
						[];
				}
			}
			setChartData({
				charts: [
					{
						type:
							option.dimensions.length > 0 ? 'stackedBar' : 'bar',
						labels:
							labels,
						datasets,
						comparedPeriode: [...new Set(datesWithComparison2)],
						isCompare: isCompare,
						isMultipleDimensions: option.dimensions.length > 0,
						title: metricTypeSessionsSelected.key ?
							metricTypeSessionsSelected.label : '',
						key: 0
					},
				],
				isLoading: false,
				isError: false,
			});
		}

		function error(error) {
			console.log('error', error);
			setChartData({ isError: true });
		}
		if (!ComparisonSelected && option.fromDate &&
			option.toDate) {
			getDataWithoutComparison(option);
		}
		if (ComparisonSelected &&
			option.period1FromDate &&
			option.period1ToDate &&
			option.period2FromDate &&
			option.period2ToDate) {
			getDataWithComparison(option);
		}
	};

	useEffect(() => {
		const fromToChanged =
			!currentNavFilter ||
			!_.isEqual(
				{
					fromDate: currentNavFilter.fromDate,
					toDate: currentNavFilter.toDate,
				},
				{ fromDate: navFilter.fromDate, toDate: navFilter.toDate }
			);

		if (fromToChanged) {
			const options = setupTimeGranularityOptions();
			const defaultGranularity =
				options.find((x) => x.isDefault) || options[0];
			const granularity = defaultGranularity.key;
			setSelectedTimeGranularity(granularity);
		}

		setCurrentNavFilter(navFilter);
	}, [navFilter]);

	useEffect(() => {
		const timer = setTimeout(() => {
			defaultOption.filters = navFilter.filters;
			defaultOption.dimensions =
				selectedDimension.splitDimension &&
					selectedDimension.splitDimension.value !== 'None'
					? [selectedDimension.splitDimension.value]
					: [];
			defaultOption.timeGranularity = selectedDimension.useTime
				? selectedTimeGranularity || 'Day'
				: 'None';
			if (ComparisonSelected) {
				delete defaultOption.toDate;
				delete defaultOption.fromDate;
				defaultOption.period1FromDate = moment(navFilter.fromDate).format(FORMAT);
				defaultOption.period1ToDate = moment(navFilter.toDate).format(FORMAT);
				defaultOption.period2FromDate = moment((navFilter.fromDateToCompare)).format(FORMAT);
				defaultOption.period2ToDate = moment(navFilter.toDateToCompare).format(FORMAT);
			}
			refreshChart(defaultOption);
		}, 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [
		selectedDimension,
		selectedTimeGranularity,
		navFilter,
		selectedTenant,
		ComparisonSelected,
		metricTypeSessionsSelected

	]);

	const onHandleChangeTimeGranularity = (granularity) => {
		setSelectedTimeGranularity(granularity);
	};

	const onHandleChangeDimensions = (e) => {
		if (e.value === 'None') {
			setUseTimeDisabled(true);
			setSelectedDimension({ splitDimension: e, useTime: true });
		} else {
			setUseTimeDisabled(false);
			setSelectedDimension((current) => ({
				...current,
				splitDimension: e,
			}));
		}
	};

	const onHandleChangeUseTime = (e) => {
		setSelectedDimension((current) => ({
			...current,
			useTime: !current.useTime,
		}));
	};

	const dataSetsAreZero = (chart) =>
		chart.datasets.length > 0 &&
		chart.datasets.every((s) => s.data.every((x) => x == 0));
	const chartsAreZero = () =>
		chartData.charts &&
		chartData.charts.length > 0 &&
		chartData.charts.every(dataSetsAreZero);

	return (
		<div className={styles.containerMedium}>
			<div className="flex mb_30">
				<div className="flex_item_full">
					<SelectMetricTypeSessionsContainer styles={styles} />
					{/* <div className={styles.title}>Sessions And Page views</div> */}
				</div>
				<div className="flex_item_fix ml_20">
					{
						selectedDimension.useTime !== false && <>
							<DropDownTypeChart />
						</>}
					<span className="s_13 mr_5">Split by</span>
					<Dropdown
						isOpen={splitDropdownIsOpen}
						setIsOpen={(e) => setSplitDropdownIsOpen(true)}
						onHide={(e) => setSplitDropdownIsOpen(false)}
						button={
							<Btn
								size="xxs"
								style="outline"
								color="secondary"
								arrow={true}
								className="al_left"
							>
								{selectedDimension.useTime == true && <>Time</>}
								{selectedDimension.splitDimension.value !=
									'None' && (
									<>
										{selectedDimension.useTime == true && (
											<> and </>
										)}
										{selectedDimension.splitDimension.label}
									</>
								)}
							</Btn>
						}
					>
						<ul className="listbox">
							<li>
								{useTimeDisabled ? (
									<a className="listbox_item disabled">
										<i className="listbox_item_icon fas fa-check"></i>
										Time
									</a>
								) : (
									<a
										className="listbox_item"
										onClick={(e) => {
											onHandleChangeUseTime();
											setSplitDropdownIsOpen(false);
										}}
									>
										{selectedDimension.useTime == true ? (
											<i className="listbox_item_icon fas fa-check"></i>
										) : (
											<i className="listbox_item_icon fas"></i>
										)}
										Time
									</a>
								)}
							</li>
							<li className="hr" />
							{localConfig.map((item, key) => {
								const isActive =
									item.value ===
									selectedDimension.splitDimension.value;
								return (
									<li key={key}>
										<a
											className={
												isActive
													? 'listbox_item selected'
													: 'listbox_item'
											}
											onClick={(e) => {
												onHandleChangeDimensions(item);
												setSplitDropdownIsOpen(false);
											}}
										>
											<i className="listbox_item_icon fas"></i>
											{item.label}
										</a>
									</li>
								);
							})}
						</ul>
					</Dropdown>

					{selectedDimension.useTime &&
						timeGranularityOptions.length > 1 && (
						<div className="btn_switch ml_10">
							{timeGranularityOptions.map((o) => {
								const isSelected =
										o.key === selectedTimeGranularity;
								return (
									<Btn
										key={o.key}
										style="outline"
										color="secondary"
										size="xxs"
										className={
											isSelected ? 'active' : ''
										}
										onClick={() =>
											onHandleChangeTimeGranularity(
												o.key
											)
										}
										message={o.label || o.key}
									/>
								);
							})}
						</div>
					)}
				</div>
			</div>

			<div className={styles.barGraph}>
				<ChartSwitch
				/>
			</div>
		</div>
	);
};

SessionsChartContainer.displayName = 'SessionsChartContainer';

export default SessionsChartContainer;
