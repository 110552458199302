import React from 'react';
import AnalitycsBoardItem from './AnalitycsBoardItem';
import {v4 as uuidv4} from 'uuid';
import ReactTooltip from 'react-tooltip';
import styles from './Board.module.css';

export default function AnalitycsBoardHasValues({
	sectionTitle,
	title,
	unique,
	count,
	distinctCount,
	session,
	data,
	reportType,
	handleFilter,
	handleFilterHasValuesPage
}) {
	const tooltipID = uuidv4();
	const isClickable = (elem) => {
		return reportType === 'Interaction' && !elem.HasValues ;
	};
	function handleFilterValue(elem){
		if(elem.HasValues && reportType === 'Interaction'){
			return ()=> handleFilter(elem['Level2'], 'page', elem.HasValues);
		}else if(reportType === 'Interaction'){
			return ()=> handleFilterHasValuesPage(elem['Level1'], 'page', elem.HasValues);
		}
	}
	return (
		<>
			{sectionTitle &&
				<div className={styles.sectionHeader}>
					<div className={styles.sectionTitle}>{sectionTitle}</div>
				</div>
			}
			<div className="analytics_table_wrapper">
				<ReactTooltip backgroundColor='black' effect='solid' id={tooltipID} />
				<div className="table_grid s table_grid_analytics table_grid_analytics_interaction_1">
					<div className="table_row table_head_row">
						<div className="table_col">
							<a className="table_sort">
								{title}
							</a>
						</div>
						<div className="table_col">
							<a className="table_sort">
								{unique}
								<i className='fas fa-info-circle' 
									data-for={tooltipID}
									data-tip={'Number of interactions counted once by session'}>
								</i>
							</a>
						</div>
						<div className="table_col">
							<a className="table_sort">
								{count}
								<i className='fas fa-info-circle' 
									data-for={tooltipID}
									data-tip={'Total number of interactions'}>
								</i>
							</a>
						</div>
						<div className="table_col">
							<a className="table_sort">
								{distinctCount}
								{reportType !== 'Page' && 
									<i className='fas fa-info-circle' 
										data-for={tooltipID}
										data-tip={'Number of distinct pages the interaction occured on'}>
									</i>
								}
								{reportType === 'Page' && 
									<i className='fas fa-info-circle' 
										data-for={tooltipID}
										data-tip={' Number of distinct interactions on the page'}>
									</i>
								}
							</a>
						</div>
						<div className="table_col">
							<a className="table_sort">
								{session}
								<i className='fas fa-info-circle' 
									data-for={tooltipID}
									data-tip={' Number of distinct sessions that triggered interactions'}></i>
							</a>
						</div>
						<div className="table_col"></div>
					</div>
					{data && data.map((elem) => (
						<div  key={uuidv4()} className="table_row table_body_row"
							style={{cursor : isClickable(elem) ? 'pointer' : ''}}
							onClick={handleFilterValue(elem)}
						>
							<AnalitycsBoardItem
								data={reportType === 'Interaction' ? elem['Level1']  :elem['Level2']}
								isHandleClick={false}
							/>
							<AnalitycsBoardItem
								data={elem['UniqueCount']}
								isHandleClick={false}
							/>
							<AnalitycsBoardItem
								data={elem['Count']}
								isHandleClick={false}
							/>
							<AnalitycsBoardItem
								data={elem['DistinctCount']}
								isHandleClick={false}
							/>
							<AnalitycsBoardItem
								data={elem['SessionCount']}
								isHandleClick={false}
							/>

							<div className="table_col">
								{isClickable(elem) &&<i className="fas fa-chevron-right c_turquoise"></i>}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
}
