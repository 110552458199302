import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import IconBtn from '../../Components/IconBtn';
import Dropdown from '../../Components/Dropdown';
import Listbox from '../../Components/Listbox';
import ListboxItem from '../../Components/ListboxItem';
import { t } from 'i18next';

export function PanelFieldLabel({
	styles,
	color,
	icon,
	name,
	clickable = false
}) {

	const buttonClass = [styles.type_select];
	buttonClass.push(styles['type_select_color_' + color]);

	if (clickable) {
		buttonClass.push(styles.type_select_clickable);
	}

	return (
		<button className={buttonClass.join(' ')}>
			<i className={'fas fa-fw fa-' + icon}></i>
			<span className={styles.type_select_label}>{name}</span>
		</button>
	);
}

export function PanelSelect(props) {

	const [ddIsOpen, setddIsOpen] = useState(false);
	const button = PanelFieldLabel({
		styles: props.styles,
		color: props.typeObj.color,
		icon: props.typeObj.icon,
		name: props.typeObj.name,
		clickable: true
	});

	return (
		<div>
			<Dropdown
				isOpen={ddIsOpen}
				toggle={(s) => setddIsOpen(s)}
				button={button}
			>
				<Listbox>
					{props.options.map(option => 
						<ListboxItem
							key={option.id}
							message={option.name}
							onClick={(e) => {props.onSelectChange({target: {value: option.id}}); setddIsOpen(false);}}
							selected={option.id === props.type}
							capitalize={true}
							icon={option.icon}
							disabled={!!option.wip}
						/>
					)}
				</Listbox>
			</Dropdown>
		</div>
	);
}
  
export function PanelInitTypeSelection(props) {

	const [t] = useTranslation(['survey', 'utils']);
	const [panelClass, setpanelClass] = useState([props.styles.type_panel]);
	const [overlayClass, setoverlayClass] = useState([props.styles.type_panel_overlay]);
	const [isOpen, setisOpen] = useState(true);
	const [sections, setSections] = useState([]);

	const updateClass = () => {
		const panelClass = [props.styles.type_panel];
		const overlayClass = [props.styles.type_panel_overlay];
		if (!isOpen) {
			panelClass.push(props.styles.anim_hide);
			overlayClass.push(props.styles.anim_hide);
		}
		setpanelClass(panelClass);
		setoverlayClass(overlayClass);
	};

	const updateSections = (types = []) => {
		const sections = [];
		types.forEach((type) => {
			let section = sections.find((sec) => sec.label == type.section);
			if (!section) {
				section = {
					label: type.section,
					types: []
				};
				sections.push(section);
			}
			section.types.push(type);
		});
		setSections(sections);
	};

	const handleClose = () => {
		setisOpen(false);
	};

	const onAnimationEnd = () => {
		if (!isOpen) {
			props.remove();
		}
	};

	useEffect(() => {
		updateClass();
	}, [isOpen]);

	useEffect(() => {
		updateSections(props.controlTypes);
	}, [props.controlTypes]);

	const styles = props.styles;

	return (
		<>
			<div className={overlayClass.join(' ')} onClick={handleClose}></div>
			<div className={panelClass.join(' ')} onAnimationEnd={onAnimationEnd}>
				<div className={styles.type_panel_header}>
					<div className='flex'>
						<div className='flex_item_full'>
							<div className='h3'>{t('chooseQuestionType')}</div>
						</div>
						<div className='flex_item_fix'>
							<IconBtn
								icon="fas fa-times"
								size="l"
								onClick={handleClose}
								tooltip={t('actions.close', {ns: 'utils'})}
							/>
						</div>
					</div>
				</div>
				<div className={styles.type_panel_body}>
					{sections?.map((section, i) => 
						<div className={styles.type_list_outer}  key={i}>
							<div className={styles.type_list_label}>{section.label}</div>
							<div key={i} className={styles.type_list_inner}>
								{section.types.map(type => {
									const isWip = type.wip;
									const cssClass = [styles['type_item_color_' + type.color]];
									cssClass.push(isWip ? styles.type_item_disabled : styles.type_item_enabled);
									return (
										<button
											key={type.id}
											className={cssClass.join(' ')}
											type="button"
											onClick={isWip ? null : () => props.onSelect(type.id)}
											value={type.id}>
											<i className={type.icon}></i>
											<div className={styles.type_item_name}>{type.name}</div>
											{isWip &&
												<div className={styles.type_item_soon}>
													<div className={styles.type_item_soon_text}>
														Soon
													</div>
												</div>
											}
										</button>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
  
export function PanelActions(props) {

	const [t] = useTranslation(['survey']);
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			{props.actions.remove &&
				<IconBtn
					icon="far fa-trash-alt"
					tooltip={t('removeQuestion')}
					onClick={props.actions.remove}
				/>
			}
			<Dropdown
				isOpen={isOpen}
				toggle={(s) => setIsOpen(s)}
				button={
					<IconBtn
						icon="fas fa-ellipsis-v"
					/>
				}
			>
				<Listbox>
					{props.actions.toggleNameField &&
						<ListboxItem
							onClick={() => {props.actions.toggleNameField(); setIsOpen(false);}}
							icon="fas fa-code"
							message={t('customizeNameField')}
							disabled={props.options.showNameField}
						/>
					}
					{props.actions.duplicate &&
						<ListboxItem
							onClick={() => {props.actions.duplicate(); setIsOpen(false);}}
							icon="far fa-clone"
							message={t('duplicateQuestion')}
						/>
					}
				</Listbox>
			</Dropdown>
		</>
	);
}

export function PanelHeader(props) {
	return (
		<header className={props.styles.panelHeader}>
			{props.children}
		</header>
	);
}
  
export function PanelBody(props) {
	return (
		<div className={props.styles}>
			{props.children}
		</div>
	);
}

export function PanelFooter(props) {
	return (
		<footer className={props.styles}>
			{props.children}
		</footer>
	);
}