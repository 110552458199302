import * as React from "react"

function SvgComponent(props) {
  return (
    <svg 
    height={"20px"}
    width={"20px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 473.68 473.68"
      {...props}
    >
      <circle cx={236.85} cy={236.85} r={236.83} fill="#fff" />
      <path
        d="M460.14 157.874H314.218c6.335 50.593 6.376 106.339.12 156.995h146.116a236.407 236.407 0 0013.219-78.026 236.261 236.261 0 00-13.533-78.969z"
        fill="#d32027"
      />
      <path
        d="M314.218 157.874H460.14a6.687 6.687 0 00-.075-.206C429.752 72.2 351.785 9.319 258.105.972c36.252 19.872 46.839 82.832 56.113 156.902z"
        fill="#0b0b0b"
      />
      <path
        d="M258.109 472.697c93.848-8.362 171.927-71.46 202.12-157.156.079-.228.146-.452.228-.673h-146.12c-9.195 74.47-19.827 137.875-56.228 157.829z"
        fill="#f3c515"
      />
      <path
        d="M0 236.842c0 27.348 4.693 53.588 13.219 78.026h313.313c6.256-50.66 6.215-106.402-.12-156.995H13.534C4.798 182.573 0 209.139 0 236.842z"
        fill="#db2727"
      />
      <path
        d="M13.605 157.668a5.85 5.85 0 00-.075.206h312.883C317.139 83.804 294.357 20.845 258.11.973 251.097.352 244.007 0 236.835 0 133.806 0 46.184 65.802 13.605 157.668z"
        fill="#151515"
      />
      <path
        d="M326.532 314.868H13.219c.079.221.15.445.228.673 32.452 92.102 120.19 158.135 223.387 158.135 7.173 0 14.263-.352 21.274-.98 36.402-19.953 59.228-83.358 68.424-157.828z"
        fill="#f8d12e"
      />
    </svg>
  )
}

export default SvgComponent
