import React, { useContext, useState, useEffect, useRef } from 'react';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import './CustomizeScreenWebSite.css';
import { Can, check } from '../../../../Components/Can';
import ZoneContainer from './ZoneContainer';

export default function CustomizeScreenWebSite({ accountId, deviceSelected, businessOperationServices,
	systemServices }) {
	const {
		zoningCustomizeListValues,
		pageNameSelected,
		selectedZone,
		setSelectedZone,
		setZoningCustomizeListValues,
		operation,
		listZones,
		listBanners,
		userSimplifyRole,
		planAccount,
	} = useContext(CreateBusinessOperationContext);

	const [zonesToMapedDesktop, setZonesToMapedDesktop] = useState([]);
	const [zonesToMapedMobile, setZonesToMapedMobile] = useState([]);
	const [scrollMobile, setScrollMobile] = useState(0);
	const [scrollDesktop, setScrollDesktop] = useState(0);

	const canMakeAssociation = check(planAccount, userSimplifyRole, 'simplifyOperation:canCreateAssociationBannerAndZone', null);
	const canDefineCTA = check(planAccount, userSimplifyRole, 'simplifyOperation:canDefineCTA', null);
	const refElement = useRef(null);
	useEffect(() => {
		if (refElement.current) {
			if (deviceSelected === 'Mobile') {
				refElement.current.scrollTop = scrollMobile;
			} else {
				refElement.current.scrollTop = scrollDesktop;
			}
		}
	}, [deviceSelected]);

	const handleScroll = () => {
		if (deviceSelected === 'Desktop') {
			setScrollDesktop(refElement.current.scrollTop);
		} else {
			setScrollMobile(refElement.current.scrollTop);
		}
	};
	useEffect(() => {
		refElement.current.scrollTop = 0;
	}, [pageNameSelected]);

	useEffect(() => {
		customStyleZonesSelect();
	}, [pageNameSelected, deviceSelected]);

	const customStyleZonesSelect = () => {
		const device = deviceSelected.toLowerCase();
		const testIfAlreadyexist = zoningCustomizeListValues.filter(x => x.pageType === pageNameSelected && x.device === device)[0];
		if (testIfAlreadyexist && testIfAlreadyexist.zones.style) {
			return;
		}
		let newZone = listZones.length && listZones.filter(x => x.device === device && x.pageType === pageNameSelected)[0];
		if (newZone && newZone.zones) {
			for (let i = 0; i < newZone.zones.length; i++) {
				let zone = newZone.zones[i];
				const top = zone.zoneExtensions.Coordinates.top / newZone.screenshotExtensions.height * 100 + '%';
				const left = zone.zoneExtensions.Coordinates.left / newZone.screenshotExtensions.width * 100 + '%';
				const width = zone.zoneExtensions.Coordinates.width / newZone.screenshotExtensions.width * 100 + '%';
				const height = zone.zoneExtensions.Coordinates.height / newZone.screenshotExtensions.height * 100 + '%';

				newZone.zones[i].style = {
					top: top,
					left: left,
					width: width,
					height: height
				};
				// check if opExist and charge bannerId if exist
				operation.operation.locations.forEach(item => {
					if (item.zoneId === zone.zoneId) {
						zone.bannerId = item.bannerId;
						if (zone.bannerType !== 'Slider') {
							zone.imagePath = listBanners.filter(x => x.bannerId === item.bannerId)[0].imagePath;
						}
						// for slider
						if (zone.bannerType === 'Slider') {
							//zone.items = item.extensions.items
						}
					}
				});
				let ratio = zone.zoneExtensions.Coordinates.width / zone.zoneExtensions.Coordinates.height;
				zone.zoneExtensions.Coordinates.ratio = ratio;
				newZone.zones[i].device = device;
			}
			const newValues = [...zoningCustomizeListValues];

			let valueToChange = newZone;

			const finalValues = newValues.filter(x => x.pageType !== pageNameSelected);
			setZoningCustomizeListValues([...finalValues, valueToChange]);
		}
	};

	const unselectZone = (e) => {
		setSelectedZone();
	};

	const defineZones = () => {
		if (deviceSelected !== 'Mobile') {
			let newZone = zoningCustomizeListValues.filter(x => x.pageType === pageNameSelected && x.device === deviceSelected.toLowerCase());
			if (newZone[0] && newZone[0].zones) {
				setZonesToMapedDesktop(newZone[0].zones);
			} else {
				setZonesToMapedDesktop([]);
			}
		}
		else {
			let newZone = zoningCustomizeListValues.filter(x => x.pageType === pageNameSelected && x.device === deviceSelected.toLowerCase());
			if (newZone[0] && newZone[0].zones) {
				setZonesToMapedMobile(newZone[0].zones);
			} else {
				setZonesToMapedMobile([]);
			}
		}
	};
	useEffect(() => {
		defineZones();
	}, [zoningCustomizeListValues, operation, deviceSelected, pageNameSelected]);
	let screenShot = listZones && listZones.filter(x => x.device === deviceSelected.toLowerCase() && x.pageType === pageNameSelected)[0];
	screenShot = screenShot && screenShot.imagePath;

	let containerClass = 'customizePreview customizePreview' + deviceSelected;


	return (
		<div ref={refElement} onScroll={handleScroll} className={containerClass} onClick={(e) => unselectZone()}>
			<div className="customizePreviewInner">
				<img className="customizePreviewImg" src={screenShot} />
				<div className="customizeZones">
					<>
						{deviceSelected === 'Mobile' ?
							zonesToMapedMobile && zonesToMapedMobile.length === 0 ?
								<div className="nodata"> <p className="title">There is no zone and screenshot associated with this type of page.</p> </div> :
								zonesToMapedMobile.map((zone, zoneKey) =>
									<ZoneContainer
										key={zoneKey}
										zone={zone}
										selectedZone={selectedZone}
										canDefineCTA={canDefineCTA}
										canMakeAssociation={canMakeAssociation}
										bannerType={zone.bannerType}
										businessOperationServices={businessOperationServices}
										systemServices={systemServices}
										accountId={accountId}
									/>
								) :
							zonesToMapedDesktop &&
								zonesToMapedDesktop.length === 0 ?
								<div className="nodata"> <p className="title">There is no zone and screenshot associated with this type of page.</p> </div> :
								zonesToMapedDesktop.map((zone, zoneKey) =>
									<ZoneContainer
										key={zoneKey}
										zone={zone}
										selectedZone={selectedZone}
										canDefineCTA={canDefineCTA}
										canMakeAssociation={canMakeAssociation}
										bannerType={zone.bannerType}
										businessOperationServices={businessOperationServices}
										systemServices={systemServices}
										accountId={accountId}
									/>
								)
						}
					</>
				</div>
			</div>
		</div >
	);
}
