import React, { useState, useEffect } from 'react'
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function SecondCategorySelect({
	categoryList,
	handleSelectCategory,
	secondCategorySelected
}) {

	const [valuesForSelect, setValuesForSelect] = useState([]);
	const [searchResult, setsearchResult] = useState();
	const [inputSearch, setinputSearch] = useState('');

	useEffect(() => {
		if (categoryList && categoryList.length > 0) {
			const tmpList = categoryList.map(i => {
				return {
					value: i.name,
					label: i.label
				};
			});
			setValuesForSelect(tmpList);
			setsearchResult(tmpList)
		}
	}, [categoryList]);
	const search = (table, word) => {
		if (word.length > 0) {
			setsearchResult([])
			let resultName = table.filter(
				line =>
					line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			const res = resultName.map(i => {
				return {
					value: i,
					label: i
				};
			})
			setsearchResult(res)
		}
		else {
			setsearchResult(valuesForSelect)
		}

	}
	const handleChangeSearch = (event) => {
		setinputSearch(event)
		search(valuesForSelect.map(v => v.value), event)
	}

	return (
		<SelectDropdown
			onChange={(v) => handleSelectCategory(v)}
			autocompleteValue={inputSearch}
			friendlyValue={secondCategorySelected.label}
			autocomplete={true}
			label='Category'
			labelPosition='inner'
			optionsList={searchResult}
			value={secondCategorySelected.name}
			onAutocomplete={(v) => handleChangeSearch(v)}
			color='white'
			autocompletePlaceholder={'Search for a category'}
		/>
	)
}
