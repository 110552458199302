import React from 'react';
import { useEffect, useState } from 'react';
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function AutocompleteCategoryContainer({
	value,
	onChange,
	label,
	labelPosition,
	color,
	emptyState,
	accountId,
	tenant,
	setupAccountServices
}) {
	const [valueOfSelectedCategory, setValueOfSelectedCategory] = useState({
		label: 'Choose',
		value: ''
	});
	const [autocompleteValue, setAutocompleteValue] = useState();
	const [autocompleteListIsLoading, setautocompleteListIsLoading] = useState(false);
	const [list, setlist] = useState([]);
	const [listCategories, setlistCategories] = useState([]);

	useEffect(() => {
		if (listCategories) {
			const newArr = listCategories.map(el => {
				return {
					value: el,
					label: el
				};
			});
			setlist(newArr);
		}
		else {
			setlist([]);
		}
	}, [listCategories]);
	function handleChageAutcomplete(val) {
		setAutocompleteValue(val);
	}
	function onAutoSelect(val) {
		const newValue = list.find(el => el.value === val);
		onChange(newValue.value);
	}
	function onGetCatsByIdList(str) {
		setautocompleteListIsLoading(true);
		var time = 10;
		const intervalForSearch  = setInterval(()=>{
			if(time > 0){
				time = time - 1;
			}else if (time === 0){
				setlistCategories([autocompleteValue]);
			}
		},1000);
		setupAccountServices.getAccountReferenceData(accountId,
			'pagetagkey',
			str,
			tenant,
			newData => {
				const arrayOfDataWithAutoComplete = [autocompleteValue,...newData ];
				setlistCategories(arrayOfDataWithAutoComplete);
				setautocompleteListIsLoading(false);
				clearInterval(intervalForSearch);
			},
			error => {
				setlistCategories([autocompleteValue]);
				setautocompleteListIsLoading(false);
				clearInterval(intervalForSearch);
			}
	
		);
	}
	useEffect(()=>{
		if(autocompleteValue){
			const Debounce = setTimeout(() => {
				onGetCatsByIdList(autocompleteValue);
			}, 1500);
			return () => {
				clearTimeout(Debounce);
			};
		}
	},[autocompleteValue]);
	useEffect(()=>{
		if(value === ''){
			setValueOfSelectedCategory({
				label: 'Choose',
				value: ''
			});
		}else{
			setValueOfSelectedCategory({
				label: value,
				value: value
			});
		}
	},[value]);
	return (
		<>
			<SelectDropdown
				label={label}
				optionsList={list}
				value={valueOfSelectedCategory}
				onChange={(v) => onAutoSelect(v)}
				friendlyValue={valueOfSelectedCategory.label}
				autocomplete={true}
				autocompleteIsLoading={autocompleteListIsLoading}
				autocompleteValue={autocompleteValue}
				autocompletePlaceholder={'Search for a category'}
				onAutocomplete={(v) => handleChageAutcomplete(v)}
				emptyState={emptyState}
				size={'l'}
			/>
		</>
	);
}
