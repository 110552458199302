import React from 'react';

export default function TableColItem({
	specialClasseName,
	onClick = () => { },
	children,
	style = {},
	refs = {
		setNodeRef: React.createRef(),
		props: {

		},
		attributes: {

		},
		listeners: {

		}
	},
	align = 'left'
}) {

	const cssClass = ['table_col'];

	if (specialClasseName) {
		cssClass.push(specialClasseName);
	}

	if (align == 'right') {
		cssClass.push('flex_justify_end al_right');
	} else if (align == 'center') {
		cssClass.push('flex_justify_center al_center');
	} 

	return (
		<div
			ref={refs.setNodeRef}
			{...refs.props}
			{...refs.attributes}
			{...refs.listeners}
			className={cssClass.join(' ')}
			style={{ ...style }}
			onClick={onClick}>
			{children}
		</div>
	);
}
