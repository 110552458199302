import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';

export default class CampaignGraphServices {
	constructor(authServices) {
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.URI_GRAPHQL) {
			throw 'Configuration [window.BEYABLE_env.URI_GRAPHQL] missing';
		}

		const token = authServices.getAccessToken();
		const defaultOptions = {
			watchQuery: {
				fetchPolicy: 'no-cache',
				errorPolicy: 'ignore',
			},
			query: {
				fetchPolicy: 'no-cache',
				errorPolicy: 'all',
			},
		};
		const httpLink = createHttpLink({
			uri: window.BEYABLE_env.URI_GRAPHQL
		});

		const authLink = setContext((_, { headers }) => {
			return {
				headers: {
					...headers,
					authorization: token ? `Bearer ${token}` : '',
				}
			};
		});

		this.graphQlClient = new ApolloClient({
			link: authLink.concat(httpLink),
			cache: new InMemoryCache(),
			defaultOptions: defaultOptions,
		});
	}

	getCampaignEditor(accountId, campaignId, callbackSuccess, callbackError) {
		const getCampaign = gql`
        query($accountId: String, $where:OnSiteCampaignFilterInput){
          account(id: $accountId){
            onSiteCampaigns(where:$where){
               items{
                   id,
                   editor,
               }
            }
          }
        }
        `;

		const where = { 'id': { 'eq': campaignId } };

		this.graphQlClient.query({
			query: getCampaign,
			variables: {
				accountId: accountId,
				where: where
			}
		}
		).then(response => {
			if (response.data.account.onSiteCampaigns.items.length != 1) {
				console.log('Campaign not found', response.data);
				callbackError('Campaign not found');
				return;
			}
			callbackSuccess(response.data.account.onSiteCampaigns.items[0].editor);
		}).catch(error => {
			console.log(error);
			callbackError(error);
		});
	}

	getServerSideCampaignEditor(accountId, campaignId, callbackSuccess, callbackError) {
		const getCampaign = gql`
        query($accountId: String, $where:ServerSideCampaignFilterInput){
          account(id: $accountId){
            serverSideCampaigns(where:$where){
               items{
                   id,
                   editor,
               }
            }
          }
        }
        `;

		const where = { 'id': { 'eq': campaignId } };

		this.graphQlClient.query({
			query: getCampaign,
			variables: {
				accountId: accountId,
				where: where
			}
		}
		).then(response => {
			if (response.data.account.serverSideCampaigns.items.length != 1) {
				console.log('Campaign not found', response.data);
				callbackError('Campaign not found');
				return;
			}
			callbackSuccess(response.data.account.serverSideCampaigns.items[0].editor);
		}).catch(error => {
			console.log(error);
			callbackError(error);
		});
	}
}

