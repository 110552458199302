import React from 'react';

export default function TableRowItem({
	styleObj,
	specialClasseName = '',
	children,
	provided,
	isDraggableItem = false,
	refs = {
		setNodeRef: React.createRef(),
		props: {},
		attributes: {}
	},
	setIsHovered
}) {

	const toggleHover = (state) => {
		if (typeof setIsHovered !== 'function') return;
		setIsHovered(state);
	};

	return (
		<>
			{isDraggableItem ?
				<div
					className={'table_row' + ' ' + specialClasseName}
					style={styleObj}
					ref={refs.setNodeRef}
					{...refs.attributes}
					onMouseEnter={() => toggleHover(true)}
					onMouseLeave={() => toggleHover(false)}
				>
					{children}
				</div>
				:
				<div
					className={'table_row' + ' ' + specialClasseName}
					style={styleObj}
					onMouseEnter={() => toggleHover(true)}
					onMouseLeave={() => toggleHover(false)}
				>
					{children}
				</div>
			}
		</>
	);
}