import React, { useEffect, useState }  from 'react';
import ReactTooltip from 'react-tooltip';
import Btn from '../../../../Components/Btn';
import Confirm from '../../../../Components/Confirm';
import {useProductFeedEditionContext} from '../../context/ProductFeedEditionContext';
import {keyExistInConfig,fieldIsXpathCustom,transformXpathToNormalValue}  from '../../utils';
import editionStyles from '../ProductFeedEdition.module.css';

export default function FieldContainer({
	name,
	label,
	subTitle,
	type = null,
	children,
	valueKey,
	fieldIndex,
	insertElement = <></>,
	valueDoesntExistInList = false,
	hasTransformationBtn = false,
}) {

	const {
		mappingObject,
		productFeedObject,
		deleteValueKeyInMappingObject,
		deletePrices,
		openedFieldIndex,
		toggleMapField,
		metadatasSources
	} = useProductFeedEditionContext();

	const [isOpen, setIsOpen] = useState(false);
	const [confirmResetIsOpen, setConfirmResetIsOpen] = useState(false);
	const [cssClass, setCssClass] = useState('');

	useEffect(()=>{
		const tmpClassClass = [editionStyles.card];
		const isCurrentField = openedFieldIndex === fieldIndex;

		if (isCurrentField) {
			tmpClassClass.push(editionStyles.card_opened);
		} else {
			tmpClassClass.push(editionStyles.card_closed);
			if (openedFieldIndex === fieldIndex + 1) {
				tmpClassClass.push(editionStyles.card_before_open);
			}
		}

		setCssClass(tmpClassClass.join(' '));
		setIsOpen(isCurrentField);

	},[openedFieldIndex]);

	const hasKeyInObjet = keyExistInConfig(name,mappingObject,type);
	function handleDelete(){
		if(type === null){
			deleteValueKeyInMappingObject(name);
		}
		if(type === 'price'){
			deletePrices();
		}
	}
	if(!valueKey && type === 'price'){
		let value = [];
		if(mappingObject.hasOwnProperty('Price1') ){
			fieldIsXpathCustom(mappingObject['Price1'][0].sourceField) ? 
				value.push(transformXpathToNormalValue(mappingObject['Price1'][0].sourceField)) :
				mappingObject.hasOwnProperty('Price1') ? value.push(mappingObject['Price1'][0].sourceField) : null;
		}
		if(mappingObject.hasOwnProperty('Price2') ){
			fieldIsXpathCustom(mappingObject['Price2'][0].sourceField) ? 
				value.push(transformXpathToNormalValue(mappingObject['Price2'][0].sourceField)) : mappingObject.hasOwnProperty('Price2') ? value.push(mappingObject['Price2'][0].sourceField) : null;
		}
		if(value.length === 0){
			valueKey = 'Choose a field';
		}else{
			valueKey = '';
			value.map((el, i) =>
			{
				if(i >0){
					return valueKey += ', ' + el;
				}else{
					return  valueKey += el;
				}
			});
		}
	}

	const getHeaderCssClass = () => {
		if (!hasKeyInObjet) return editionStyles.card_map_header_empty;
		if (valueDoesntExistInList) return editionStyles.card_map_header_error;
		return editionStyles.card_map_header_set;
	};
	return (
		<div className={cssClass}>
			{!isOpen &&
				<>
					<div 
						className={getHeaderCssClass()} onClick={(e) => toggleMapField(fieldIndex)}>
						<div className='flex'>
							<div className='flex_item_fix'>
								<div className={editionStyles.card_map_status_icon}>
									{valueDoesntExistInList && <i className='fas fa-exclamation-triangle fa-fw dis_block'></i>}
									{!valueDoesntExistInList && <i className={hasKeyInObjet ? 'fas fa-check-circle fa-fw dis_block' : 'fas fa-ban fa-fw dis_block'}></i>}
								</div>
							</div>
							<div className='flex_item_fix'>
								<span className={editionStyles.card_map_name}>{label}</span>
								{!hasKeyInObjet && name === 'Id' &&
									<div className={editionStyles.card_map_desc}>This field is mandatory</div>
								}
							</div>
							<div className='flex_item_full al_right ml_20 overflow_hidden'>
								<div className={editionStyles.card_map_value}>
									{hasKeyInObjet ? valueKey : 'Choose a field'}
								</div>
							</div>
							<div className='flex_item_fix'>
								<div className={editionStyles.card_map_status_arrow}>
									<i className={'fas fa-chevron-down dis_block'}></i>
								</div>
							</div>
						</div>
					</div>
				</>
			}
			{isOpen &&
				<>
					<div className={editionStyles.card_map_body}>
						<div className='flex flex_align_start'>
							<div className='flex_item_full'>
								{insertElement}
								<div className={editionStyles.card_map_intro}>
									<div className={editionStyles.card_map_title}>Map <strong>{name}</strong> to </div>
									{subTitle &&
										<div className={editionStyles.card_map_sub_title}>{subTitle}</div>
									}
								</div>
							</div>
							<div className="flex_item_fix ml_20">
								<button className={editionStyles.card_map_status_arrow}
									onClick={(e) => toggleMapField(fieldIndex)}>
									<i className={'fas fa-chevron-up dis_block'}></i>
								</button>
							</div>
						</div>
						{children}
					</div>
					<div className={editionStyles.card_map_footer}>
						<div className='flex'>
							<div className='flex_item_full'>
								
							</div>
							<div className='flex_item_fix ml_20'>
								<Btn
									message="Done"
									onClick={(e) => toggleMapField(fieldIndex)}
								/>
							</div>
						</div>
					</div>
					{hasKeyInObjet &&
						<button
							className={editionStyles.card_map_reset}
							onClick={() => setConfirmResetIsOpen(true)}
							data-tip="Reset field mapping">
							<i className="fas fa-ban"></i>
						</button>
					}
					<Confirm
						isOpen={confirmResetIsOpen}
						onClose={() => setConfirmResetIsOpen(false)}
						title='Reset field mapping'
						text='Are you sure to to reset field mapping?'
						confirmText='Reset'
						confirmCallback={() => {handleDelete(); toggleMapField(fieldIndex);}}
					/>
					<ReactTooltip
						backgroundColor="black"
						effect="solid"
						place="bottom"
						globalEventOff="click"
						delayShow={600}
					/>
				</>
			}
		</div>
	);
}
