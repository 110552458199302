import React, { useState, useEffect } from "react";
import "./ProductCollectionsContainer.css";
import Spinner from "../../Components/Spinner";
import DynamicRuleContainer from "./DynamicRuleContainer";

export default function ProductCollectionsContainer(props) {
	// const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	// const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	// const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	// //const accountId = 'f2ce4a66-bc9c-4463-b5f3-7e330fc88c54';

	// const authServices = props.AuthServices;

	const [loading, setLoading] = useState(true)
	const [dataFiltered, setDataFiltered] = useState();
	const [mode, setMode] = useState("list")
	return (
		<>
			<section className='section no_bottom_pad section_primary'>
				<div className="h1">Product collections</div>
			</section>
			{mode === "list" && (
				<div className="modalBody">
					<h2 className="">Rules list</h2>
					<div className="search_tag">
						<i className="fa fa-search"></i>
						<input
							className="searchBarTag"
							type="text"
							placeholder="Search for an association set"
						/>
					</div>

					<ul className="listOfSkuWithCount">
						{loading ? (
							<Spinner />
						) : (
							<>
								{!dataFiltered ? (
									<>
										{associationSetList.map((elem, i) => (
											<li key={i} className="itemSku" onClick={() => pickAssociation(elem.id)}>
												{elem.name}
											</li>
										))}
									</>
								) : (
									<>
										{noData ? (
											<div className="noData">{noData}</div>
										) : (
											<>
												{dataFiltered &&
													dataFiltered.map((elem, i) => (
														<li key={i} className="itemSku" onClick={() => deleteAssociation(elem.id)}>
															{elem.name}
														</li>
													))}
											</>
										)}
									</>
								)}
							</>
						)}
					</ul>
					<div className="tag_actions">
						<button className="tag_btn buttonAdd" onClick={() => setMode("creation")}>
							Add new dynamic rule<i className="fas fa-plus"></i>
						</button>
					</div>
				</div>
			)}

		</>
	);
}
