import * as React from 'react';

function SvgComponent() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="99"
			height="88"
			viewBox="0 0 595 533"
		>
			<defs>
				<clipPath id="clip-Logo-beyable-vecto">
					<rect width="595" height="533"/>
				</clipPath>
			</defs>
			<g id="Logo-beyable-vecto" clipPath="url(#clip-Logo-beyable-vecto)">
				<g id="Groupe_5" data-name="Groupe 5" transform="translate(-35 -71)">
					<g id="Groupe_4" data-name="Groupe 4" transform="translate(23.126 89.29)">
						<path id="Soustraction_1" data-name="Soustraction 1" d="M314.442,317.358H256.555a58.136,58.136,0,0,1-50.392-29.143l-53.755-93.433a58.136,58.136,0,0,1,0-57.987l53.755-93.433a57.987,57.987,0,0,1,12.6-15.19L314.454,198.32v119.03Z" transform="translate(0 10.914)" fill="#006fff"/>
						<path id="Soustraction_1-2" data-name="Soustraction 1" d="M243.927,144.72h-.868L178.3,24.71h78.687a58.135,58.135,0,0,1,40.554,16.482L243.931,144.715Z" transform="translate(106.057 0)" fill="#00D9FF" style={{isolation: 'isolate'}}/>
						<path id="Soustraction_1-3" data-name="Soustraction 1" d="M201.249,267.266h0V150.475L261.549,43.5l26.4,45.882a58.137,58.137,0,0,1,0,57.987L234.187,240.8a57.981,57.981,0,0,1-32.934,26.464Z" transform="translate(178.393 59.237)" fill="#0079B7" style={{isolation: 'isolate'}}/>
					</g>
					<text id="BEYABLE" transform="translate(77 553)" fill="#006fff" fontSize="118" fontFamily="IBMPlexSans-Bold, IBM Plex Sans" fontWeight="700"><tspan x="0" y="0">BEYABLE</tspan></text>
				</g>
			</g>
		</svg>
	);
}

export default SvgComponent;

// #006fff

//"#77eade"

//"#002453"