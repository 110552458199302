import React, { useCallback, useState } from 'react';
import { abTestsDashboardContext } from './context';
import EmptyState from '../../../Components/EmptyState';
import {listDevices} from '../utils';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import ABTestsItemPending from './ABTestItemPending';
import styles from './ABTestDashboard.module.css';
import Pagination from './Pagination';
import SearchBar from '../../../Components/SearchBar';
import EditLabels from '../../Labels/EditLabels';
import LabelSelectDropdown from '../../Labels/LabelSelectDropdown';
import FiltAbTestFilterActions from './AbTestFilterListActions';
import AbTestDevicesFilters from './AbtestDevicesFilters';
import EditLabelsCampaignAbTest from '../../Dashboard/OnSite/UI/EditLabelsCampaignAbTest';
import EditCampaignTestMode from './EditCampaignTestMode';


export default function ABTestsDashboardPending() {
	const [modalLabelsAccountisOpen, setmodalLabelsAccountisOpen] = useState(false);
	const {
		loading,
		pendingCampaigns,
		canEditCampaigns,
		dataIsNotComplete,
		handlePendingCampaignsChangePage,
		isAdmin,
		accountId,
		statusArray,
		totalCount,
		pageSize,
		setpageSize,
		setskipIdNumber,
		skipIdNumber,
		currentPage,
		setCurrentPage,
		systemServices,
		changeStatusArray ,
		filterLabelId,
		setfilterLabelId,
		getLabels,
		first,
		filterLabel,
		LabelsAccount,
		setfilterLabel,
		devicesSelected,
		resetDevices,
		handleChangeDevicesSelected,
		valueInSearchBar,
		handleChangeValueInSearchBar,
		setModalLabelsCampaignIsOpen,
		modalLabelsCampaignisOpen,
		setneedRefetch,
		needRefetch,
		labelCampaignSelected,
		modalTestModeIsOpen,
		campaignSelected,
		labelServices,
		resetFilterLabels,
		toggleFilterLabels
	} = abTestsDashboardContext();

	function closeTheModalOfLabelsCampaign () {
		setModalLabelsCampaignIsOpen(false);
		setneedRefetch(!needRefetch);
	}


	return (
		<>
			<div className='page_block'>
				{modalTestModeIsOpen &&
					<EditCampaignTestMode />
				}
				{modalLabelsAccountisOpen && 
					<EditLabels
						accountId={accountId}
						modalLabelsAccountisOppen={modalLabelsAccountisOpen}
						setmodalLabelsAccountisOppen={setmodalLabelsAccountisOpen}
						systemServices={systemServices}
						labelService={labelServices}
						onClose={getLabels}
						objectType={'campaign'}
					/>
				}
				{modalLabelsCampaignisOpen &&
					<EditLabelsCampaignAbTest 
						labelService={labelServices}
						systemServices={systemServices}
						accountId={accountId}
						currentCampaign={campaignSelected}
						labelCampaignSelected={labelCampaignSelected}
						modalLabelsCampaignisOpen={modalLabelsCampaignisOpen}
						closeTheModalOfLabelsCampaign={closeTheModalOfLabelsCampaign}
					/>
				}

				<div className='page_block_header flex'>
					<div className='flex_item_full'>
						<div className='btn_group_l'>
							<SearchBar
								onChangeValue={handleChangeValueInSearchBar}
								valueInSearchBar={valueInSearchBar}
								placeholder='Find an experiment'
								size='l'
								fullWidth={false}
							/>
							<AbTestDevicesFilters
								handleChangeDevicesSelected={handleChangeDevicesSelected}
								resetDevices={resetDevices}
								devicesSelected={devicesSelected}
								initialValue={listDevices}
							/>
							<LabelSelectDropdown
								selectableLabels={LabelsAccount || []}
								selectedLabelIds={filterLabelId || []}
								onLabelSelected={l => toggleFilterLabels(l)}
								resetFilterLabels={resetFilterLabels}
								canEdit={(isAdmin || canEditCampaigns)}
								onEditRequested={() => setmodalLabelsAccountisOpen(true)}					
							/>
						</div>
					</div>
					{dataIsNotComplete	&& !loading &&
						<div className="flex_item_fix ml_20 flex">
							<span className='s_13 grey_3 mr_8'>Precision increase in progress</span>
							<SpinnerWheel
								display="inline"
								wheelSize="s"
							/>
						</div>
					}
					<div className="flex_item_fix ml_20">
						<FiltAbTestFilterActions 
							statusArray={statusArray}
							setstatusArray={changeStatusArray}
						/>
					</div>
				</div>

				{!loading && pendingCampaigns.length > 0 &&
					<>
						<div className={'table_grid ' + styles.table}>
							<div className={'table_row table_head_row ' + styles.table_head_row}>
								<div className={'table_col ' + styles.table_col}>Experiment</div>
								<div className={'table_col ' + styles.table_col}>Status</div>
								<div className={'table_col ' + styles.table_col}>Sessions</div>
								<div className={'table_col ' + styles.table_col}>Objectives</div>
								<div className={'table_col ' + styles.table_col}>Improvement</div>
								<div className={'table_col ' + styles.table_col}>Probabiliy to beat baseline</div>
								<div className={'table_col ' + styles.table_col}></div>
							</div>
							{pendingCampaigns.map(campaign =>
								<ABTestsItemPending
									key={campaign.id}
									campaign={campaign}
								/>
							)}
						</div>
						<Pagination
							totalCount={totalCount}
							paginate={(e) => {
								handlePendingCampaignsChangePage( e >= 0 ? e : 0
								);
							}}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							pageSize={pageSize}
							skipIdNumber={skipIdNumber}
						/>
					</>
				}

				{!loading && pendingCampaigns.length === 0 &&
					<EmptyState
						icon="fas fa-search"
						verticalSize={'xl'}
						textSize={'l'}
						title={'No matching experiment'}
						text={'Please try another search'}
					/>
				}

				{loading &&
					<SpinnerWheel wheelSize='l' display='block' verticalSize='xl' />
				}
			</div>
		</>
	);
}