import React, { Component } from 'react';
import VisitorEngagementProfileSignal from './VisitorEngagementProfileSignalComponent';
import styles from './VisitorEngagementProfile.module.css';
import ProfileWeightEnum from './ProfileWeightEnum';

const Weights = [ProfileWeightEnum.High, ProfileWeightEnum.Medium, ProfileWeightEnum.Low];

export default class VisitorEngagementProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditingName: false,
            editingName: ""
        };

        this.onAddNewSignalChange = this.onAddNewSignalChange.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onSignalValueChanged = this.onSignalValueChanged.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onNameBlur = this.onNameBlur.bind(this);
        this.onNameKeyDown = this.onNameKeyDown.bind(this);
        this.startEditingName = this.startEditingName.bind(this);
        this.stopEditingName = this.stopEditingName.bind(this);
        this.onChangeWeight = this.onChangeWeight.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onDuplicateClick = this.onDuplicateClick.bind(this);
        this.raiseOnChange = this.raiseOnChange.bind(this);
    }

    componentDidMount() {
        if (this.state.isEditingName && this.nameInput) {
            this.nameInput.focus();
        }
    }

    componentDidUpdate() {
        if (this.state.isEditingName && this.nameInput) {
            this.nameInput.focus();
        }
    }

    onAddNewSignalChange(event) {
        let signalId = event.target.value;
        let signal = this.props.signals.find(s => s.id === signalId);
        if (signal) {
            // Check if signal already used in profile
            let profileSignal = this.props.profile.signals.find(ps => ps.signalId === signalId);
            if (profileSignal)
                return;

            profileSignal = {
                signalId: signalId,
                value: 0,
                signal: signal
            };

            let newSignals = [...this.props.profile.signals];
            newSignals.push(profileSignal);
            event.target.value = "";
            this.raiseOnChange({ signals: newSignals })
        }
    }

    onRemoveClick(signalId) {
        let profileSignal = this.props.profile.signals.find(ps => ps.signalId === signalId);
        if (!profileSignal)
            return;
        let newSignals = [...this.props.profile.signals];
        let index = newSignals.indexOf(profileSignal);
        newSignals.splice(index, 1);
        this.raiseOnChange({ signals: newSignals });
    }

    onSignalValueChanged(signalId, value) {
        let profileSignal = this.props.profile.signals.find(ps => ps.signalId === signalId);
        if (!profileSignal)
            return;
        let newSignals = [...this.props.profile.signals];
        let index = newSignals.indexOf(profileSignal);
        newSignals[index] = {
            ...profileSignal,
            value: value
        };
        this.raiseOnChange({ signals: newSignals });
    }

    onNameBlur() {
        let newName = this.state.editingName;
        if (newName !== this.props.profile.name) {
            this.stopEditingName(() => this.raiseOnChange({ name: newName }));
        } else {
            this.stopEditingName();
        }
    }

    onNameChange(event) {
        let newName = event.target.value;
        if (newName !== this.state.editingName) {
            this.setState({
                editingName: newName,
            });
        }
    }

    onNameKeyDown(event) {
        if (!this.props.canEdit)
            return;
        if (event.key === 'Enter') {
            this.onNameBlur();
        } else if (event.key == 'Escape') {
            event.preventDefault();
            this.stopEditingName();
        }
    }

    startEditingName() {
        if (!this.props.canEdit)
            return;
        this.setState({
            isEditingName: true,
            editingName: this.props.profile.name
        });
    }

    stopEditingName(callback) {
        this.setState({
            isEditingName: false,
            editingName: ""
        }, callback);
    }

    onChangeWeight(weight) {
        if (!this.props.canEdit)
            return;
        this.raiseOnChange({ weight: weight });
    }

    onDeleteClick() {
        if (!this.props.onDelete) return;
        this.props.onDelete(this.props.profile);
    }

    onDuplicateClick() {
        if (!this.props.onDuplicate) return;
        this.props.onDuplicate(this.props.profile);
    }

    raiseOnChange(changes) {
        if (!this.props.onChange)
            return;

        let newProfile = {
            ...this.props.profile,
            ...changes
        };

        this.props.onChange(newProfile);
    }

    render() {
        return <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.titleRow}>
                    {this.state.isEditingName
                        ? <input type="text" className={styles.nameEditor}
                            ref={(input) => { this.nameInput = input; }}
                            value={this.state.editingName}
                            onChange={this.onNameChange}
                            onBlur={this.onNameBlur}
                            onKeyDown={this.onNameKeyDown} />
                        : <div className={styles.title}>{this.props.profile.name} {this.props.canEdit ? <button className={styles.edit_name}><i className={"far fa-edit"} onClick={this.startEditingName}></i></button> : ''} </div>
                        }
                    <div className={styles.weight}>
                        <ul>
                            {Weights.map(w =>
                                <li key={w} className={this.props.profile.weight === w ? styles.weightSelected : ""} onClick={() => this.onChangeWeight(w)}>{w}</li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className={styles.signalsList}>
                    {
                        this.props.profile.signals.map((signal, index) =>
                            <div key={signal.signalId} className={styles.signalsListItem}>
                                <div className={styles.union_line}></div>
                                <div className={styles.union_inner}>AND</div>
                                <div className={styles.signalItem}>
                                    <VisitorEngagementProfileSignal
                                        signal={signal.signal}
                                        signalId={signal.signalId}
                                        value={signal.value}
                                        onChange={this.onSignalValueChanged}
                                        canEdit={this.props.canEdit} />
                                </div>
                                {this.props.canEdit ?
                                    <button className={styles.removeButton} onClick={() => this.onRemoveClick(signal.signalId)}><i className="fa fa-times"></i></button>
                                : ''}
                            </div>)
                    }
                    <div className={styles.signalsListItem}>
                        <div>
                            <div className={styles.addNewSelectWrapper}>
                                <select disabled={!this.props.canEdit} className={styles.addNewSelect} onChange={this.onAddNewSignalChange} value="">
                                    <option value="">Add...</option>
                                    {this.props.signals.filter(s => !this.props.profile.signals.find(ps => ps.signalId === s.id))
                                        .map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                </select>
                            </div>
                            {this.props.profilesStats 
                            ? <div className={styles.profileStats}>Performance du profil <strong>{Math.round(this.props.profilesStats.ratioOfDisplays * 100)}%</strong> ({this.props.profilesStats.numberOfDisplays} {this.props.profilesStats.numberOfDisplays == 1 ? "affichage" : "affichages"})</div>
                            : <div></div>}
                        </div>
                    </div>
                </div>
            </div>
            {this.props.canEdit ?
            <div className={styles.bottomButtonsRow}>
                <span className={styles.bottomButton, styles.bottomButtonDelete} onClick={this.onDeleteClick}>
                    <i className="fa fa-times"></i>
                    delete
                </span>
                <span className={styles.bottomButton, styles.bottomButtonDuplicate} onClick={this.onDuplicateClick}>
                    <i className="fa fa-copy"></i>
                    duplicate
                </span>
            </div>
            : ''}
        </div>
    }
}