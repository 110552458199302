import { useCallback, useEffect } from 'react';

export default function useEventBroker() {

	const eventBroker = {
		subscribe(event, callback) {
			document.addEventListener(event, callback);
		},
		publish(event, data) {
			document.dispatchEvent(new CustomEvent(event, { detail: data }));
		},
		unsubscribe(event, callback) {
			document.removeEventListener(event, callback);
		},
	};

	function subscribe(event, callBack, dependencies) {
		const callbackHook = useCallback(callBack, dependencies);

		useEffect(() => {
			eventBroker.subscribe(event, callbackHook);
			return () => {
				eventBroker.unsubscribe(event, callbackHook);
			};
		}, [callbackHook]);
	}

	function publish(event, payload) {
		eventBroker.publish(event, payload);
	}

	return {
		subscribe,
		publish
	};
}

