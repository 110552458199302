import React, { useState, useEffect, useContext } from 'react';
import LabelSelector from '../../../Labels/LabelSelector';
import EditLabels from '../../../Labels/EditLabels';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';

export default function OperationLabelSelector({ accountId }) {
	const {
		labelService,
		systemServices,
		operationLabelIds,
		setOperationLabelIds
	} = useContext(CreateBusinessOperationContext);

	const [accountLabels, setAccountLabels] = useState([]);
	const [modalLabelsAccountisOpen, setmodalLabelsAccountisOpen] = useState(false);

	const getLabels = () => {
		labelService.getLabels(
			accountId,
			'businessOperation',
			data => {
				setAccountLabels(data);
			}, err => {
				console.log('An Error occured while fetching labels: ' + err);
			}
		);
	};

	useEffect(() => {
		if (modalLabelsAccountisOpen == false) {
			getLabels();
		}
	}, [modalLabelsAccountisOpen]);

	const allowMultiSelection = true;
	const handleLabelSelected = (label) => {
		allowMultiSelection
			? setOperationLabelIds(ids => ids && ids.includes(label.id) ? ids.filter(i => i !== label.id) : [...(ids || []), label.id])
			: setOperationLabelIds(ids => ids && ids.includes(label.id) ? [] : [label.id]);
	};

	return (
		<>
			<LabelSelector
				selectableLabels={accountLabels || []}
				selectedLabelIds={operationLabelIds || []}
				labelSelected={handleLabelSelected}
				inline={true}
				size="l"
			/>

			<a className="campaign_label_action opertion_campaign_label_action" onClick={() => setmodalLabelsAccountisOpen(true)}>Edit labels</a>

			{modalLabelsAccountisOpen &&
				<EditLabels
					modalLabelsAccountisOppen={modalLabelsAccountisOpen}
					setmodalLabelsAccountisOppen={() => setmodalLabelsAccountisOpen(false)}
					labelService={labelService}
					systemServices={systemServices}
					accountId={accountId}
					objectType={'"businessOperation"'}
				/>
			}
		</>
	);
}