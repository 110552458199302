import React, { useEffect, useState } from 'react';
import InputCustom from '../../../../../Components/InputCustom';
import SelectCustom from '../../../../../Components/SelectCustom';
import SelectDropdown from '../../../../../Components/SelectDropdown';

const operators = [
	{
		value: 'Equals',
		label: 'Is equal to',
	},
	{
		value: 'NotEquals',
		label: 'Is not equal to',
	},
	{
		value: 'Lower',
		label: 'Is lower than',
	},
	{
		value: 'Greater',
		label: 'Is greater than',
	},
	{
		value: 'LowerOrEquals',
		label: 'Is lower or equal to',
	},
	{
		value: 'GreaterOrEquals',
		label: 'Is greater or equal to',
	},

];
export default function NumberContainer({
	data,
	handleChangeFilterByUniqueId,
}) {

	const [currentValueNumber, setcurrenValueNumber] = useState();
	const [currentValues, setCurrentValues] = useState();
	const [isAnError, setIsAnError] = useState({
		isError: false,
		message: ''
	});

	const [valueOperatorSelected, setvalueOperatorSelected] = useState();
	const [multipleValues, setMultipleValues] = useState(false);

	const handleChangeValueNumber = (value) => {
		const number = Number(value);

		if (!value) {
			setIsAnError({ isError: true, message: 'input cannot be empty' });
		}
		else if (value && typeof number !== 'number') {
			setIsAnError({ isError: true, message: 'value should be a number' });
		}
		else if (value && typeof number === 'number') {
			setIsAnError({ isError: false, message: '' });
		}
		setcurrenValueNumber(value);
	};
	const handleChangeValues = (event) => {
		if (!event) {
			setIsAnError({ isError: true, message: 'input cannot be empty' });
		}
		else {
			setIsAnError({ isError: false, message: '' });
		}
		setCurrentValues(event);
	};
	const selectOperator = (operator) => {
		setvalueOperatorSelected(operator);
	};
	function getValueOfNumberComponent() {
		let valueSimple = data.value.hasOwnProperty('numberValues');
		if (valueSimple && data.value['numberValue'] !== null) {
			setcurrenValueNumber(data.value['numberValues'][0]);
		}
		const valueMultiple = data.value.hasOwnProperty('numberValues');
		if (valueMultiple && data.value['numberValues'] !== null) {
			setCurrentValues(data.value['numberValues'].join('\n'));
		}
	}

	function handleChangeValuesById() {
		if (!multipleValues && currentValueNumber) {
			handleChangeFilterByUniqueId({
				idFilter: data.idFilter,
				op: valueOperatorSelected,
				value: { numberValues: [parseInt(currentValueNumber)] },
			});
		} else if (multipleValues && currentValues) {
			const myNewArrayOfcurrentValues = currentValues
				.split(/[;,''\n]/)
				.filter((x) => x.length > 0);
			const isAnError = () => {
				let hasAnError = false;
				myNewArrayOfcurrentValues.forEach((value) => {
					if (value && isNaN(parseInt(value))) {
						setIsAnError({
							isError: true,
							message: 'values should be a numbers',
						});
						return hasAnError = true;
					}
				});
				return hasAnError;
			};
			handleChangeFilterByUniqueId({
				idFilter: data.idFilter,
				op: valueOperatorSelected,
				value: { numberValues: !isAnError() ? myNewArrayOfcurrentValues : [] },
			});

		}
	}
	useEffect(() => {
		selectOperator(data.op || operators[0].value);
		getValueOfNumberComponent();
	}, [data]);

	useEffect(() => {
		handleChangeValuesById();
	}, [currentValueNumber,
		currentValues,
		valueOperatorSelected]);

	return (
		<div className="flex flex_align_start">
			<div className="flex_item_fix mr_10">
				<SelectDropdown
					optionsList={operators}
					value={valueOperatorSelected}
					onChange={(v) => selectOperator(v)}
				/>
			</div>
			<div className="flex_item_full">
				{multipleValues && (
					<InputCustom
						placeHolder="Enter  item per line or use one of the following separators: ; (semi-colon) , (comma)"
						onChange={(e) => handleChangeValues(e.target.value)}
						value={currentValues}
						type="textarea"
						isAnError={isAnError.message}
						fullWidth={true}
						autogrow={true}
					/>
				)}
				{!multipleValues && (
					<InputCustom
						onChange={(e) => handleChangeValueNumber(e.target.value)}
						value={currentValueNumber}
						isAnError={isAnError.message}
						width={'s'}
						type='number'
						placeHolder="0"
					/>
				)}
			</div>
		</div>
	);
}


