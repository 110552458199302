import React, {useState,useEffect} from 'react';
import PageSearchFilter from './PageSearchFilter';
import { usePageContext } from '../../context/PageContext';
import Chip from '../Chip/Chip';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import Nav from '../Nav/Nav';
import ReportTypeFilter from '../ReportType/ReportTypeFilter';
import initialValue from './config';
import {devices} from './config';
import SourceTypeFilter from '../SourceType/SourceTypeFilter';
import UtmFiltersPagesContainer from './UtmFiltersPagesContainer';
import SelectDevice from '../Devices/SelectDevice';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { CsvModal } from '../Csv/CsvModal';
export default function PageFilterContainer({sessionsServices, fetchCsvService, customDimensionConfigsForTenant}) {
	const {
		navFilter,
		setNavFilter,
		chips,
		onDeleteChip,
		reportTypeSelected,
		handleChangeReportSelect,
		sourceType,
		handleChangeSourceType,
		sourceTypes,
		setisCustomSelected,
		isCustomSelected,
		customDateRange,
		setCustomDateRange,
		deviceSelected,
		handleChangeDeviceSelected,
		resetSource,
		resetDevices,
		isCustomDimensionMode,
		selectedCustomTag,
		handleSelectTagCustom
	} = usePageContext();

    
	const { initiNavDate } = useAnalyticsContext();

	let initialDates = initiNavDate(navFilter);
	const [renderBreadCrumb, setRenderBreadCrumb] = useState(<></>);

	useEffect(()=> {
		if(isCustomDimensionMode){
			const bcList = [];
			const n = reportTypeSelected && initialValue.find(el => el.key === reportTypeSelected).label;
			bcList.push({
				label: n,
				onClick: () => handleSelectTagCustom()
			});
			
			bcList.push({
				label: selectedCustomTag,
				onClick: () => handleSelectTagCustom()
			});
		
			setRenderBreadCrumb(
				<Breadcrumb list={bcList} />
			);
		}
	},[isCustomDimensionMode,selectedCustomTag]);

	return (
		<>
			<div className="flex flex_align_end analytics_main_line">
				<div className="flex_item_full">
					<Nav
						initialDates={initialDates}
						handleClick={setNavFilter}
						setisCustomSelected={setisCustomSelected}
						isCustomSelected={isCustomSelected}
						customDateRange={customDateRange}
						setCustomDateRange={setCustomDateRange}
					/>
					<ReportTypeFilter
						reportTypeSelected={reportTypeSelected}
						handleChangeReportSelect={handleChangeReportSelect}
						filterValues={initialValue}
						customDimensionConfigsForTenant={customDimensionConfigsForTenant}
					/>
					<SourceTypeFilter
						sourceTypeSelected={sourceType}
						handleChangeSourceType={handleChangeSourceType}
						initialValue={sourceTypes}
						resetSource={resetSource}
					/>
					<SelectDevice
						resetSource={resetDevices}
						initialValue={devices}
						devices={devices}
						deviceSelected={deviceSelected}
						handleChangeDeviceSelected={handleChangeDeviceSelected}
					/>
					<UtmFiltersPagesContainer
						sessionsServices={sessionsServices}
					/>
					{chips && (
						<Chip
							title={chips.title}
							type={chips.type}
							handleDelete={onDeleteChip}
						/>
					)}
				</div>
				<div className="flex_item_fix">
					<PageSearchFilter />
				</div>
				<div className="flex_item_fix">
					<CsvModal fetchCsvService={fetchCsvService} />
				</div>
			</div>
			{selectedCustomTag &&  renderBreadCrumb}
		</>
	);
}
