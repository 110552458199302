import React, { useState } from 'react';
import SelectDropdown from '../../Components/SelectDropdown';
import NumericUpDown from '../../Components/NumericUpDown';
import InputCustom from '../../Components/InputCustom';
import TriggerSchedule from './TriggerSchedule';
import TriggerGeoloc from './TriggerGeoloc';
import TriggerUtil from '../Campaigns/Triggers/TriggerUtil';
import Select from 'react-select';
import { useTriggerContext } from './TriggerContext';
import ReferenceDataAutoComplete from '../ReferenceData/ReferenceDataAutoComplete';
import ReferenceDataType from '../ReferenceData/ReferenceDataType';
import {getTriggerLabel, getTriggerToolTip} from "./Utils";
import {useTranslation} from 'react-i18next';
export default function ConditionTriggerCard({
	index,
	trigger,
	changeValueConditionTrigger,
	deleteTrigger,
	handleChangeOperatorName
}) {

	const [t, i18n] = useTranslation('common');
	const { getDefaultOperator } = TriggerUtil();
	const { getTypeFromTriggerName } = ReferenceDataType();

	const [triggerValue, setTriggerValue] = useState(trigger.ValueToCompare);
	
	const {
		accountId,
		setupAccountServices,
		productTagServices
	} = useTriggerContext();

	const onChangeAutocomplete = (text) => {
		if (text.length) {
			text = text.replace(/(\n)+/g, ';');
			text = text.replace(/;+/g, ';');
		}
		changeValueConditionTrigger(index, text);
	};

	const operandSelector = (
		<>
			{trigger.DataTriggerOperands && trigger.DataTriggerOperands.length > 0 &&
				<div className="flex_item_fix segment_condition_item_operand">
					<SelectDropdown
						optionsList={trigger.DataTriggerOperands}
						value={getDefaultOperator(trigger)}
						onChange={(v) => handleChangeOperatorName(index, v)}
						autoWidth={true}
						disabled={trigger.DataTriggerOperands.length === 1}
					/>
				</div>
			}
		</>
	);

	const isAutoCompleteHardCodedHereForNow = (trigger) => {
		//return false
		return ['TRGTARGETTENANT', 'TRGCHECKURLTAGS', 'TRGGIVEPRODUCTDETAILS'].includes(trigger.Name);
	};

	const numericHour = (hour) => {
		if (parseInt(hour) > 24) {
			return 24;
		}
		if (parseInt(hour) < 0) {
			return 0;
		}
		return parseInt(hour);
	};

	let optionsDisplay = 'bottom';
	if (!trigger.Type) {
		optionsDisplay = false;
	} else if (trigger.Type === 'number' || trigger.Type === 'price' || trigger.Type === 'numberhour') {
		optionsDisplay = 'right';
	}

	const getTheValueOfMultipleList = (trigger) => {
		if (trigger.ValueToCompare) {
			return trigger.ValueToCompare.map(v => trigger.ChoiceValues.filter(o => o.value === v)[0]);
		}
		return [];
	};

	return (
		<div className="segment_condition_item">
			<div className='flex'>
				<div className="segment_condition_item_and flex_item_fix">
					<span className='segment_condition_item_and_label'>
						{index === 0 ?t('segment.if') :  t('segment.and')}
					</span>
				</div>
				<div className="flex_item_full">
					<div className="segment_condition_item_name">{getTriggerLabel(trigger.Type, trigger.Label, trigger.ValueToCompare, trigger.Name)}</div>
					<div className="segment_condition_item_desc">{getTriggerToolTip(trigger.Tooltip, trigger.ValueToCompare, trigger.Name)}</div>
				</div>
				{operandSelector}
				{optionsDisplay && optionsDisplay === 'right' &&
					<div className="flex_item_fix segment_condition_item_value">
						{trigger.Type === 'number' &&
							<>
								{trigger.Unit}
								<NumericUpDown
									min={0}
									value={trigger.ValueToCompare ? trigger.ValueToCompare : 0}
									onChange={(e) => changeValueConditionTrigger(index, parseInt(e.target.value))}
									canEdit={true}
								/>
							</>
						}
						{trigger.Type === 'price' &&
							<InputCustom
								type="text"
								value={trigger.ValueToCompare ? trigger.ValueToCompare : ''}
								onChange={(e) => changeValueConditionTrigger(index, e.target.value)}
								className="w_xs"
							/>
						}
						{trigger.Type === 'numberhour' &&
							<InputCustom
								type="number"
								value={trigger.ValueToCompare === 0 ? '' : trigger.ValueToCompare}
								onChange={(e) => changeValueConditionTrigger(index, numericHour(e.target.value) || 0)}
								placeHolder="0"
								className="w_xs"
								unit="Hours"
							/>
						}
					</div>
				}
				<div className='flex_item_fix ml_10'>
					<a onClick={() => deleteTrigger(index)} className="icon_btn s">
						<i className='fas fa-trash'></i>
					</a>
				</div>
			</div>
			{optionsDisplay && optionsDisplay === 'bottom' &&
				<div className="flex flex_align_start segment_condition_item_value_line">
					<div className="flex_item_full segment_condition_item_value w_full">
						{trigger.Type === 'multiplelist' && !isAutoCompleteHardCodedHereForNow(trigger) &&
							<Select
								isMulti
								options={trigger.ChoiceValues}
								// defaultValue={trigger.ValueToCompare}
								defaultValue={getTheValueOfMultipleList(trigger)}
								onChange={(e) => changeValueConditionTrigger(index, (e || []).map(v => v.value))}
								menuPlacement="auto"
								className="react-select"
								classNamePrefix="react-select"
							/>
						}
						{trigger.Type === 'list' &&
							<Select
								options={trigger.DataTriggerOperands}
								defaultValue={triggerValue}
								onChange={(e) => setTriggerValue(e.value)}
								menuPlacement="auto"
								className="react-select"
								classNamePrefix="react-select"
							/>
						}
						{trigger.Type === 'text' && !isAutoCompleteHardCodedHereForNow(trigger) &&
							<InputCustom
								type="text"
								value={trigger.ValueToCompare}
								onChange={(e) => changeValueConditionTrigger(index, e.target.value)}
								fullWidth={true}
							/>
						}
						{trigger.Type === 'textarea' &&
							<InputCustom
								type="textarea"
								value={trigger.ValueToCompare}
								onChange={(e) => changeValueConditionTrigger(index, e.target.value)}
								fullWidth={true}
								rows={2}
								maxHeight={300}
								autogrow={true}
							/>
						}
						{isAutoCompleteHardCodedHereForNow(trigger) &&
							<ReferenceDataAutoComplete
								setupAccountServices={setupAccountServices}
								productTagServices={productTagServices}
								currentAccount={accountId}
								referenceDataType={getTypeFromTriggerName(trigger.Name)}
								value={trigger.ValueToCompare}
								onChange={(value) => onChangeAutocomplete(value)}
							/>
						}
						{trigger.Type === 'schedule' &&
							<TriggerSchedule
								triggerValue={trigger.ValueToCompare}
								setTriggerValue={changeValueConditionTrigger}
								index={index}
							/>
						}
						{trigger.Type === 'geoloc' &&
							<TriggerGeoloc
								triggerValue={trigger.ValueToCompare}
								setTriggerValue={changeValueConditionTrigger}
								index={index}
							/>
						}
					</div>
				</div>
			}

		</div>
	);
}
