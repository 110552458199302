import React, { useContext } from 'react'
import { HistoryContext } from './../HistoryContext';
export default function TableBodyItem({ entry }) {
    if (!entry) {
        return null
    }
    const { $routeParams } = useContext(HistoryContext);
    const dateToUTC = moment(entry.CreatedAtUtc).format('DD/MM/YYYY, hh:mm:ss a');
    return (
        <tr>
            <td>{entry.Id}</td>
            <td>{entry.Feature}</td>
            <td>
                <span className="history_admin" ng-if="entry.UserRole == 'Admin'">Admin</span>
                {entry.Message}
                <a className="history_campaign_link"
                    href={`/Campaigns/Create/${entry.CampaignId}?ka=${$routeParams.ka}&ku=${$routeParams.ku}`} ng-if="entry.CampaignId"><i className="fa fa-link"></i></a>
            </td>
            <td>{dateToUTC}</td>
        </tr>
    )
}
