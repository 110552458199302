import React from 'react';
import { useSegmentGroupEditorContext } from '../context/SegmentListRankingContainerProvider';
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function SelectRanking({ rule, mode, name = '' }) {

	const {
		handleChooseRuleIdForUnMappedAudience,
		handleChooseRuleIdForMappedAudience,
		rankingRules
	} = useSegmentGroupEditorContext();

	const listRecoLabels = rankingRules?.map(reco => {
		return { value: reco.id, label: reco.name };
	});

	const value = mode === 'mapped' ? rule : rule.ruleId;
	const handleChooseElement = (ruleId) => {
		if (mode === 'mapped') {
			handleChooseRuleIdForMappedAudience(ruleId, name);
		} else {
			handleChooseRuleIdForUnMappedAudience(ruleId, rule);
		}
	};

	return (
		<SelectDropdown
			value={value}
			onChange={(ruleId) => handleChooseElement(ruleId)}
			optionsList={listRecoLabels}
			placeHolder={'Apply a ranking rule'}
			appearance="ghost"
		/>
	);
}
