import React, {  } from 'react';
import { useTranslation } from 'react-i18next';
import Btn from '../../../Components/Btn';
import { performanceTrackingCampaignsDashboardContext } from './context';
import PerformanceTrackingCampaignsList from './PerformanceTrackingCampaignList';
import PerformanceTrackingCampaignEditionModal from '../Editor/PerformanceTrackingCampaignEditionModal';
import PerformanceTrackingCampaignIntegrationPanel from '../PerformanceTrackingCampaignIntegrationPanel';

export default function PerformanceTrackingDashboardContainerInner() {

	const {
		toggleAddItem,
		totalCount,
		permissionsAccount
	} = performanceTrackingCampaignsDashboardContext();

	const permCreateCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'PERFCAMPAIGN_CAN_CREATE');
	const canCreateCampaign = permCreateCampaign && permCreateCampaign.Value === true;

	const [t] = useTranslation('common');


	return (
		<div className='page_full_inner'>
			<section className='section no_bottom_pad section_primary'>
				<div className='flex'>
					<div className='flex_item_full'>
						<div className='h1'>{t('list.title')}</div>
					</div>
					{totalCount > 0 &&
						<div className='flex_item_fix ml_30'>
							<Btn
								message={t('action.documentation')}
								icon="far fa-sm fa-question-circle"
								href="https://docs.beyable.com/web-app-sdk/performance-tracking"
								target="_blank"
								rel="noreferrer"
								style="ghost_outline"
								color="secondary"
							/>
							{canCreateCampaign &&
								<Btn
									message={t('action.create')}
									icon="fas fa-sm fa-plus"
									onClick={() => toggleAddItem()}
								/>
							}
						</div>
					}
				</div>
			</section>
			<section className='section'>
				<PerformanceTrackingCampaignsList />
				<PerformanceTrackingCampaignEditionModal />
				<PerformanceTrackingCampaignIntegrationPanel />
			</section>
		</div>
	);
}