/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react';
import styles from './ChartContainer.module.css';
import { FORMAT } from '../../config';

import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';

import moment from 'moment';

import _ from 'lodash';
import { useEcommerceBuyingbehaviorContext } from '../../context/EcommerceBuyingBehaviorContext';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import DropDownTypeChart from './DropDownTypeChart';
import { useEcommerceAnalyticsContext } from '../../context/EcommerceAnalyticsContext';
import {
	metricsTypes,
	metricsTypesGRaph,
	Revenue, 
	SessionToSaleRatio
} from '../Performance/config';
import ChartSwitchOverview from '../ChartSwitch/ChartSwitchOverview';
import SelectMetricTypeOverviewContainer from '../Overview/SelectMetricsTypeOverviewContainer';
import ChartSwitchBuyingBehavior from '../ChartSwitch/ChartSwitchBuyingBehavior';
import SelectMetricPerformance from '../Performance/SelectMetricPerformance';
import SelectMetricBuyingBehavior from '../BuyingBehavior/SelectMetricBuyingBehavior';

const BuyingBevahiorChartContainer = ({
	service,
	systemServices,
	localConfig,
	selectedDimension,
	setSelectedDimension,
	useTimeDisabled,
	setUseTimeDisabled,
	selectedTimeGranularity,
	setSelectedTimeGranularity,

}) => {


	const { navFilter,
		ComparisonSelected,
		chartData,
		setChartData,
		metricTypeBuyingBehaviorSelected,
		reportTypeBuyingBehaviorSelected,
		sourceType,
		dimension,
		dimensionId,
		setDimensionId,
		deviceSelected,
		utmDimensionsSelected
	} = useEcommerceBuyingbehaviorContext();

	const { selectedTenant } = useAnalyticsContext();

	const [timeGranularityOptions, setTimeGranularityOptions] = useState([
		{ key: 'Day' },
	]);

	const [currentNavFilter, setCurrentNavFilter] = useState();

	const [splitDropdownIsOpen, setSplitDropdownIsOpen] = useState(false);

	let defaultOption = {
		fromDate: navFilter.fromDate,
		toDate: navFilter.toDate,
		metrics: [...metricTypeBuyingBehaviorSelected.value],
		timeGranularity: 'Day',
		dimensions: [],
		filters: [],
		device : deviceSelected,
		utmDimensionsSelected: utmDimensionsSelected
	};

	useEffect(() => {
		setUseTimeDisabled(selectedDimension.splitDimension.value === 'None');
		setChartData({
			charts: [{ datasets: [], labels: [], title: '' }],
			isLoading: true,
			isError: false,
			isErrorEcommerce : false
		});
		setDimensionId('');
	}, []);

	const setupTimeGranularityOptions = () => {
		if (!navFilter) return;
		const dateRange = moment(navFilter.toDate, FORMAT).diff(
			moment(navFilter.fromDate, FORMAT),
			'days'
		);
		let granularityOptions;
		if (dateRange < 14) {
			granularityOptions = [{ key: 'Day' }];
		} else if (dateRange < 32) {
			granularityOptions = [
				{ key: 'Day' },
				{ key: 'MondayWeek', label: 'Week', isDefault: true },
			];
		} else if (dateRange < 64) {
			granularityOptions = [
				{ key: 'MondayWeek', label: 'Week' },
				{ key: 'Month' },
			];
		} else if (dateRange < 366) {
			granularityOptions = [{ key: 'Month' }];
		} else {
			granularityOptions = [{ key: 'Year' }];
		}
		setTimeGranularityOptions(granularityOptions);
		return granularityOptions;
	};

	const colors = [
		'#4278F0',
		'#FFB320',
		'#D7AB67',
		'#002453',
		'#7E67D7',
		'#9E9E9E',
	];
	function generateColorCode() {
		var makeColorCode = '0123456789ABCDEF';
		var code = '#';
		for (var count = 0; count < 6; count++) {
			code = code + makeColorCode[Math.floor(Math.random() * 16)];
		}
		return code;
	}
	const colorsByDimensionValues = {};
	function getDimensionColorCode(dimension) {
		if (!colorsByDimensionValues[dimension]) {
			colorsByDimensionValues[dimension] = generateColorCode();
		}
		return colorsByDimensionValues[dimension];
	}
	function successNoUseTime(data) { 
	}
	const refreshChart = (option) => {

		setChartData({ isLoading: true });

		function getDataWithoutComparison(option) {
			service.getOverviewMetricsOverTime(
				option,
				selectedDimension.useTime
					?successWithUseTime
					: successNoUseTime,
				// successWithUseTime(option)
				(err, status)=>error(err,status)
			);
			
		}
		function getDataWithComparison(option) {
			service.getSessionsCompare(
				option,
				selectedDimension.useTime
					? successWithUseTime
					: successNoUseTime,
				(err, status)=>error(err,status)
			);

		}

		function successWithUseTime(dataResult) {
			const data = dataResult.dates;

			function toDateLookup(values) {
				return values.reduce((acc, val) => {
					if (acc.hasOwnProperty(val.date)) {
						return { ...acc, [val.date]: [...acc[val.date], val] };
					}
					return { ...acc, [val.date]: [val] };
				}, {});
			}
			

			const listOfMetrics = metricTypeBuyingBehaviorSelected.value.map((metric) => {
				const findMetric = metricsTypesGRaph.find((t) => t.key === metric);
				return { ...findMetric };
			});
			const dates = data.reduce((st, d) => st.add(d.date), new Set());
		
			let dataLookup = toDateLookup(data);
			let isCompare = false;

			const datasetsNoDimensions = () => {
				let series = [];
				function transformValue(elem) {
					if (metricTypeBuyingBehaviorSelected.key.contains('Ratio') ||
					metricTypeBuyingBehaviorSelected.key.contains('Average')
					) {
						return  parseFloat(elem.toFixed(2) * 100); 
					}
					return  parseFloat(elem.toFixed(2));
				}
				function getSymboleForLabels(elem) {
					if (elem.contains('Ratio') ||
						elem.contains('Rate')
						// ||
						// elem.contains('Average')
					)
					{
						return  ' %'; 
					} 
					if (elem.contains('Revenue')
					|| elem.contains('Amount')
					|| elem.contains('ProductPrice')
					) {
						return  ' €'; 
					}
					return '';
				}

				const series1 = {
					name: metricTypeBuyingBehaviorSelected.label,
					tooltip: {
						valueSuffix:  getSymboleForLabels(metricTypeBuyingBehaviorSelected.key)
					},
					data: [...dates].map(
						(date) => transformValue(dataLookup[date][0]['metrics'].find(m => m.metric === metricTypeBuyingBehaviorSelected.key)?.value )
					),
					color: colors[0],
				};
				series = [series1];
				
				
				return series;
			};

			function getDataSets() {
				return datasetsNoDimensions();
			}
			const datasets = getDataSets();
			const labels = getLabels();

			function getLabels() {
				return (dates &&
						[...dates].map((d) =>
							moment(d).format('MMM DD YYYY')
						)) ||
						[];
			}
			setChartData({
				charts: [
					{
						type:
							option.dimensions.length > 0 ? 'stackedBar' : 'bar',
						labels:labels,
						datasets,
						comparedPeriode: [],
						isCompare: isCompare,
						isMultipleDimensions: option.dimensions.length > 0,
						title: metricTypeBuyingBehaviorSelected.key ?
							metricTypeBuyingBehaviorSelected.label : '',
						key: 0,
						listOfMetrics: listOfMetrics
					},
				],
				isLoading: false,
				isError: false,
				isErrorEcommerce : false
			});
		}

		function error(error, typeError) {

			console.log('error', error);
			const statusErr = typeError.status;
			if(!statusErr){
				systemServices.showError('An error occured');
			}
			setChartData({ isError: true ,isLoading: false,
				isErrorEcommerce : statusErr === 404});
		}
		if (!ComparisonSelected && option.fromDate &&
			option.toDate) {
			getDataWithoutComparison(option);
		}
		if (ComparisonSelected &&
			option.period1FromDate &&
			option.period1ToDate &&
			option.period2FromDate &&
			option.period2ToDate) {
			getDataWithComparison(option);
		}
	};

	useEffect(() => {
		const fromToChanged =
			!currentNavFilter ||
			!_.isEqual(
				{
					fromDate: currentNavFilter.fromDate,
					toDate: currentNavFilter.toDate,
				},
				{ fromDate: navFilter.fromDate, toDate: navFilter.toDate }
			);

		if (fromToChanged) {
			const options = setupTimeGranularityOptions();
			const defaultGranularity =
				options.find((x) => x.isDefault) || options[0];
			const granularity = defaultGranularity.key;
			setSelectedTimeGranularity(granularity);
		}

		setCurrentNavFilter(navFilter);
		
	}, [navFilter]);

	useEffect(() => {
		const timer = setTimeout(() => {
			defaultOption.device = deviceSelected;
			defaultOption.filters = navFilter.filters;
			defaultOption.trafficSource = sourceType;
			defaultOption.dimension = reportTypeBuyingBehaviorSelected;
			defaultOption.dimensionId = dimensionId;
			defaultOption.granularity = selectedTimeGranularity;
			defaultOption.fromDate = navFilter.fromDate;
			defaultOption.toDate = navFilter.toDate;
			defaultOption.utmDimensionsSelected = utmDimensionsSelected;
			defaultOption.dimensions =
				selectedDimension.splitDimension &&
					selectedDimension.splitDimension.value !== 'None'
					? [selectedDimension.splitDimension.value]
					: [];
			defaultOption.timeGranularity = selectedDimension.useTime
				? selectedTimeGranularity || 'Day'
				: 'None';
			// if (ComparisonSelected) {
			// 	delete defaultOption.toDate;
			// 	delete defaultOption.fromDate;
			// 	defaultOption.period1FromDate = moment(navFilter.fromDate).format(FORMAT);
			// 	defaultOption.period1ToDate = moment(navFilter.toDate).format(FORMAT);
			// 	defaultOption.period2FromDate = moment((navFilter.fromDateToCompare)).format(FORMAT);
			// 	defaultOption.period2ToDate = moment(navFilter.toDateToCompare).format(FORMAT);
			// }
			setChartData({
				charts: [{ datasets: [], labels: [], title: '' }],
				isLoading: false,
				isError: false,
				isErrorEcommerce : false
			});
			refreshChart(defaultOption);
		}, 1500);
		return () => {
			clearTimeout(timer);
		};
	}, [
		selectedDimension,
		selectedTimeGranularity,
		navFilter,
		selectedTenant,
		ComparisonSelected,
		metricTypeBuyingBehaviorSelected,
		sourceType,
		dimensionId,
		reportTypeBuyingBehaviorSelected,
		deviceSelected,
		utmDimensionsSelected
	]);

	const onHandleChangeTimeGranularity = (granularity) => {
		setSelectedTimeGranularity(granularity);
	};

	const onHandleChangeDimensions = (e) => {
		if (e.value === 'None') {
			setUseTimeDisabled(true);
			setSelectedDimension({ splitDimension: e, useTime: true });
		} else {
			setUseTimeDisabled(false);
			setSelectedDimension((current) => ({
				...current,
				splitDimension: e,
			}));
		}
	};

	const dataSetsAreZero = (chart) =>
		chart.datasets.length > 0 &&
		chart.datasets.every((s) => s.data.every((x) => x == 0));
	const chartsAreZero = () =>
		chartData.charts &&
		chartData.charts.length > 0 &&
		chartData.charts.every(dataSetsAreZero);

	return (
		<div className={styles.containerMedium}>
			<div className="flex mb_30">
				<div className="flex_item_full">
					<SelectMetricBuyingBehavior
						styles={styles} />
					{/* <div className={styles.title}>Sessions And Page views</div> */}
				</div>
				<div className="flex_item_fix ml_20">
					{selectedDimension.useTime &&
						timeGranularityOptions.length > 1 && (
						<div className="btn_switch ml_10">
							{timeGranularityOptions.map((o) => {
								const isSelected =
										o.key === selectedTimeGranularity;
								return (
									<Btn
										key={o.key}
										style="outline"
										color="secondary"
										size="xxs"
										className={
											isSelected ? 'active' : ''
										}
										onClick={() =>
											onHandleChangeTimeGranularity(o.key)
										}
										message={o.label || o.key}
									/>
								);
							})}
						</div>
					)}
				</div>
			</div>

			<div className={styles.barGraph}>
				<ChartSwitchBuyingBehavior	/>
			</div>
		</div>
	);
};

BuyingBevahiorChartContainer.displayName = 'BuyingBevahiorChartContainer';

export default BuyingBevahiorChartContainer;
