import React, { useState, useEffect, useLayoutEffect } from 'react';
import { react2angular } from 'react2angular';
import { BrowserRouter, browserHistory, useHistory, Switch, Route, Prompt, Link, NavLink } from 'react-router-dom';
import CreateBusinessOperationProvider from './Context/ContextCreateBusinessOperations';
import './BusinessOperationCreateContainer.css';
import BusinessOperationContainer from './BusinessOperationContainer';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import BusinessOperationServices from '../../../Services/BusinessOperationServices';
import UsersAdministrationServices from '../../../Services/UsersAdministrationServices';
import SystemServices from '../../../Services/SystemServices';
import Dashboard from './../../BusinessOperations/Dashboard/Dashboard';
import business from './../../BusinessOperations/Create/BusinessOperationCreateContainerWithContext';
import StatisticsContainer from './../Statistics/StatisticsContainer';
import LabelServices from '../../../Services/LabelsServices';

export default function BusinessOperationCreateContainerWithContext(props) {
	const [permissions, setPermissions] = useState({ plan: 'gold', roles: 'simplifyAdmin' });
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const businessOperationServices = new BusinessOperationServices(props.$http, props.AuthServices);
	const usersAdministrationServices = new UsersAdministrationServices(props.$http, props.$routeParams);
	const labelService = new LabelServices(props.$http, props.$routeParams);
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);    
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const isAdmin = impersonatingServices.isAnAdmin();
	const accountId = impersonatedAccount || props.$rootScope && props.$rootScope.User.Account.Key;
	const getZones = (accountId, pageType, callbackSuccess) => businessOperationServices.getZones(accountId, pageType, callbackSuccess, error => systemServices.showError('an error occurred'));
    
	const getOperationById = (accountId, operationId, callbackSuccess) => businessOperationServices.getOperationById(accountId, operationId, callbackSuccess, error => systemServices.showError('an error occurred'));
	const postOperationById = (accountId, operation, callbackSuccess, callbackError) => businessOperationServices.postOperationById(accountId, operation, success => { systemServices.showSuccess('The operation has been created successfully'); callbackSuccess(success); }, error => {
		systemServices.showError('An error occurred during the creation');
		callbackError(error);
	});
	const getUserById = (ku, callbackSuccess, callbackError) => businessOperationServices.getUserById(ku, callbackSuccess, callbackError);
	const getPagesTypes = (accountId, callbackSuccess, callbackError) => businessOperationServices.getPagesTypes(accountId, callbackSuccess, error => systemServices.showError('The simplify setup is not finalized, please contact your administrator'));
    
	const putOperationById = (accountId, operationId, operation, callbackSuccess, callbackError,) => businessOperationServices.putOperationById(accountId, operationId, operation, success => { systemServices.showSuccess('The operation has been updated successfully'); callbackSuccess(success); }, error => {systemServices.showError('an error occurred'); callbackError(error);}
	);
	const deleteOperationById = (accountId, operationId, callbackSuccess, callbackError) => businessOperationServices.deleteOperationById(accountId, operationId, success => { systemServices.showSuccess('The operation has been deleted successfully'); callbackSuccess(success); }, error => systemServices.showError('Deleted failed'));

	const recomputeOrchestration = (operationId, callbackSuccess, callbackError) => businessOperationServices.recomputeOrchestration(accountId, operationId, () => {
		systemServices.showSuccess('Orchestration has been recomputed.');
		callbackSuccess();
	}, (e) => {
		console.log('error', e);
		systemServices.showError('An error occured while computing orchestration.');
		callbackError(e);
	});

	const deleteLibrary = (accountId, libraryId, bannersId, callbackSuccess, callbackError) => businessOperationServices.deleteLibrary(accountId, libraryId, bannersId, success => {
		// systemServices.showSuccess("The Banner was deleted with success");
		callbackSuccess(success);
	}, (errorMsg, error) => { systemServices.showError(error.data.title); callbackError(error); }
	);
	const getAccountUsers = (accountId, callbackSuccess, callbackError) => usersAdministrationServices.getAccountUsers(accountId, success => { callbackSuccess(success); }, error => { systemServices.showError('Error'); callbackError(error); });
	useEffect(() => {
        
		// if (props.$rootScope) {
		//     getAccountUsers(accountId, data => {
		//         const rolesOfUser = data.find(user => user.id === props.$rootScope.User.Key);
		//         if (rolesOfUser) {
		//             if (props.$routeParams.ka) {
		//                 setPermissions({ plan: "bronze", roles: "simplifyAdmin" })
		//             } else {
		//                 const simplifyRoles = rolesOfUser.roles.reduce((acc, curr) => {
		//                     const accToLowerCase = curr.toLowerCase()
		//                     if (accToLowerCase.includes('simplify')) {
		//                         acc.push(curr);
		//                     }
		//                     return acc
		//                 }, [])
		//                 setPermissions({ plan: "bronze", roles: simplifyRoles.toString() })
		//             }
		//         } else {
		//             systemServices.showError('An error occured')
		//         }
		//     }, error => {
		//         systemServices.showError('An error occured')
		//         console.log("error", error);
		//     })
		// }
	}, []);
	const getAccessToken = props.AuthServices && props.AuthServices.getAccessToken;

	return (
		<BrowserRouter>
			<CreateBusinessOperationProvider
				businessOperationServices={businessOperationServices}
				systemServices={systemServices}
				accountId={accountId}
				labelService={labelService}
				$rootScope={props.$rootScope}
			>
				<Switch>
					<Route exact path="/BusinessOperations/Dashboard" component={Dashboard} />
					<Route path="/BusinessOperations/Dashboard/?ka=:ka&ku=:ku" component={Dashboard} />
					<Route path="/BusinessOperations/Create?ka:ka&ku=/:ku" component={business} />
					<Route path="/BusinessOperations/Create?id=:id" component={business} />
					<Route path="/BusinessOperations/statistics" component={StatisticsContainer} />
				</Switch>
				<BusinessOperationContainer
					isAdmin={isAdmin}
					permissions={permissions}
					accountId={accountId}
					getOperationById={getOperationById}
					idOfExistantOp={props.$routeParams.id}
					routeParams={props.$routeParams}
					getZones={getZones}
					postOperationById={postOperationById}
					getUserById={getUserById}
					getPagesTypes={getPagesTypes}
					putOperationById={putOperationById}
					recomputeOrchestration={recomputeOrchestration}
					deleteLibrary={deleteLibrary}
					deleteOperationById={deleteOperationById}
					getAccessToken={getAccessToken}
					businessOperationServices={businessOperationServices}
					systemServices={systemServices}

				/>
			</CreateBusinessOperationProvider>
		</BrowserRouter>
	);
}
angular
	.module('beyableSaasApp.BusinessOperations', ['beyableSaasApp.Admin.Services'])
	.component('businessoperation', react2angular(BusinessOperationCreateContainerWithContext, [], ['$http', '$rootScope', '$routeParams', 'AuthServices', '$timeout']));