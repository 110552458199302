export const objectives = [
	{
		'id': 'stop_abandonment',
		'icon': 'icon_exit'
	},
	{
		'id': 'stop_hesitation',
		'icon': 'icon_reinsurance'
	},
	{
		'id': 'help_engagement',
		'icon': 'icon_engagement'
	},
	{
		'id': 'stress_timer',
		'icon': 'icon_timer'
	},
	{
		'id': 'increase_mobile_conversion',
		'icon': 'icon_mobile_up'
	},
	{
		'id': 'decrease_bounce_rate',
		'icon': 'icon_bounce_rate'
	},
	{
		'id': 'welcome_paid_traffic',
		'icon': 'icon_dollar'
	},
	{
		'id': 'generate_upsell',
		'icon': 'icon_upsell'
	},
	{
		'id': 'increase_customer_base',
		'icon': 'icon_add_user'
	},
	{
		'id': 'patchs',
		'icon': 'icon_patch'
	}
];

export const formats = [
	{
		'id': 'popin',
	},
	{
		'id': 'toaster',
	},
	{
		'id': 'headerbar',
	},
	{
		'id': 'inpage',
	},
	{
		'id': 'patch',
	},
];

export const modules = [
	{
		'id': 'popin_free',
		'label': 'Free popup',
		'formatId': 'popin',
		'img': 'templates/popin_free.png',
		'available': true
	},
	{
		'id': 'toaster_free',
		'label': 'Free toaster',
		'formatId': 'toaster',
		'img': 'templates/toaster_free.png',
		'available': true
	},
	{
		'id': 'popin_empty',
		'editor': 'builder',
		'formatId': 'popin',
		'img': 'templates/popin_builder.png',
		'available': true,
		'adminOnly': true
	}, 
	{
		'id': 'popin_with_image',
		'label': 'Popup with image',
		'formatId': 'popin',
		'img': 'templates/popin_image.png',
		'available': true
	},
	{
		'id': 'retention_popin_resinsurance',
		'objId': 'stop_abandonment',
		'formatId': 'popin',
		'img': 'templates/retention_popin_resinsurance.jpg',
		'available': true
	},
	{
		'id': 'retention_promo',
		'objId': 'stop_abandonment',
		'formatId': 'popin',
		'img': 'templates/retention_promo.jpg',
		'available': true
	},
	{
		'id': 'hesitation_header_reinsurance',
		'objId': 'stop_hesitation',
		'formatId': 'headerbar',
		'img': 'templates/hesitation_header_reinsurance.png',
		'available': true
	},
	{
		'id': 'hesitation_toaster_reinsurance',
		'objId': 'stop_hesitation',
		'formatId': 'toaster',
		'img': 'illu_toaster.svg',
		'available': false
	},
	{
		'id': 'hesitation_toaster_promo',
		'objId': 'stop_hesitation',
		'formatId': 'toaster',
		'img': 'templates/hesitation_toaster_promo.jpg',
		'available': true
	},
	{
		'id': 'hesitation_toaster_social',
		'objId': 'stop_hesitation',
		'formatId': 'toaster',
		'img': 'illu_toaster.svg',
		'available': false
	},
	{
		'id': 'engagement_toaster_redirection',
		'objId': 'help_engagement',
		'formatId': 'toaster',
		'img': 'templates/engagement_toaster_redirection.png',
		'available': true
	},
	{
		'id': 'hesitation_popin_reinsurance',
		'objId': 'stop_hesitation',
		'formatId': 'popin',
		'img': 'templates/hesitation_popin_reinsurance.png',
		'available': true
	},
	{
		'id': 'inpage_banner_free',
		'formatId': 'inpage',
		'img': 'templates/inpage_banner_free.png',
		'available': true
	},
	{
		'id': 'contact_info_inpage_banner',
		'objId': 'help_engagement',
		'formatId': 'inpage',
		'img': 'templates/contact_info_inpage_banner.png',
		'available': true
	},
	{
		'id': 'inpage_image_banner',
		'formatId': 'inpage',
		'img': 'templates/inpage_image_banner.png',
		'available': true
	},
	{
		'id': 'stress_toaster_countdown',
		'objId': 'stress_timer',
		'formatId': 'toaster',
		'img': 'templates/stress_toaster_countdown.png',
		'available': true
	},
	{
		'id': 'stress_popin_countdown',
		'objId': 'stress_timer',
		'formatId': 'popin',
		'img': 'templates/stress_popin_countdown.png',
		'available': true
	},
	{
		'id': 'stress_headerbar_countdown',
		'objId': 'stress_timer',
		'formatId': 'headerbar',
		'img': 'templates/stress_headerbar_countdown.png',
		'available': true
	},
	{
		'id': 'toaster_hour_promo',
		'objId': 'increase_mobile_conversion',
		'formatId': 'toaster',
		'img': 'illu_toaster.svg',
		'available': false
	},
	{
		'id': 'popin_reco',
		'objId': 'decrease_bounce_rate',
		'formatId': 'popin',
		'img': 'illu_popin.svg',
		'available': false
	},
	{
		'id': 'bounce_rate_popin_code',
		'objId': 'decrease_bounce_rate',
		'formatId': 'popin',
		'img': 'templates/bounce_rate_popin_code.jpg',
		'available': true
	},
	{
		'id': 'paid_traffic_headerbar_promo',
		'objId': 'welcome_paid_traffic',
		'formatId': 'headerbar',
		'img': 'templates/paid_traffic_headerbar_promo.png',
		'available': true
	},
	{
		'id': 'headerbar_upsell',
		'objId': 'generate_upsell',
		'formatId': 'headerbar',
		'img': 'illu_headerbar.svg',
		'available': false
	},
	{
		'id': 'lead_popin_newsletter',
		'objId': 'increase_customer_base',
		'formatId': 'popin',
		'img': 'templates/lead_popin_newsletter.png',
		'available': true
	},
	{
		'id': 'graphic_patch',
		'objId': 'patchs',
		'formatId': 'patch',
		'img': 'templates/graphic_patch.png',
		'available': true,
		'permissions': ['PATCH_CAN_CREATE'],
		'url': '/Campaigns/Editor?graph'
	},
	{
		'id': 'code_patch',
		'objId': 'patchs',
		'formatId': 'patch',
		'img': 'templates/code_patch.png',
		'available': true,
		'permissions': ['CODE_PATCH_CAN_CREATE'],
		'url': '/Campaigns/Editor?patch'
	}
];


export const adminObjective = {
	'id': 'admin',
	'label': 'Admin modules',
	'title': 'Admin modules',
	'description': 'Theses modules are for admin only',
	'modules': [
		{
			'id': 'popin_with_image',
			'label': 'Popup with image',
			'description': '',
			'formatId': 'popin',
			'img': 'illu_popin_img.svg',
			'available': true
		}
	]
};
