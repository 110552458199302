import React, { useEffect, useState } from 'react';
import SessionsChartContainer from '../ChartContainer/SessionsChartContainer';
import SessionsBavContainer from '../Sessions/SessionsBavContainer';
import { SessionsCardContainer } from '../Sessions/SessionsCardContainer';
import { ChipContainer } from './ChipContainer';
import { getDimensions } from '../../services/DimensionsService';
import OptionFilter from '../Sessions/OptionFilters';
import { useSessionContext } from '../../context/SessionContext';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import { CsvModal } from '../Csv/CsvModal';
import NavSession from '../Sessions/NavSession';
import { InsightContainer } from './InsightContainer';

export default function SessionsTabContainer({
	styles,
	sessionService,
	trackEvent,
	selectedTenant,
	socket
}) {
	const {
		chips,
		selectedTimeGranularity,
		setSelectedTimeGranularity,
		useTimeDisabled,
		setUseTimeDisabled,
		navFilter,
		setNavFilter,
		onCardClick,
		onDeleteChip,
		numberOfSessions,
		numberOfSessions2,
		setNumberOfSessions,
		setNumberOfSessions2,
		metricTypeSessionsSelected,
		ComparisonSelected,
		handleSelectComparaisonPeriodHandler,
		setTotalsPeriode1,
		setTotalsPeriode2,
		totalsPeriod1,
		totalsPeriode2,
		setisCustomSelected,
		isCustomSelected,
		customDateRange,
		setCustomDateRange,
		isCustomCompareSelected,
		setisCustomCompareSelected,
		customCompareDateRange,
		setCustomCompareDateRange
	} = useSessionContext();

	const { initiNavDate, accountId } = useAnalyticsContext();

	let initialDates = initiNavDate(navFilter);
	const dimensions = getDimensions(accountId);
	const localConfig = [
		{ label: 'None', value: 'None' },
		...dimensions.map((d) => ({ label: d.label, value: d.key })),
	];

	const [selectedDimension, setSelectedDimension] = useState({
		splitDimension: localConfig[0],
		useTime: true,
	});


	useEffect(() => {
		trackEvent('analytics/sessions-viewed');
	}, []);


	const mergeDimensionListWithState = () => {
		const storage = localStorage.getItem('webanalytics.sessionsoptionsfilter');
		if (!storage)
			return getDimensions();
		const state = JSON.parse(storage) || [];
		const dimensions = getDimensions().map(x => {
			const fromState = state.find(s => s.key === x.key);
			return { ...x, checked: fromState && fromState.checked };
		});
		return dimensions;
	};

	const [dimensionList, setDimensionList] = useState(mergeDimensionListWithState());

	const updateDimensionList = (list) => {
		setDimensionList(list);
		localStorage.setItem(
			'webanalytics.sessionsoptionsfilter',
			JSON.stringify(list)
		);
	};

	const csvOptions = {
		fromDate: '',
		toDate: '',
		fromDateToCompare: '',
		toDateToCompare: '',
		metrics: [
			'NumberOfSessions',
			'NumberOfBounces',
			'DurationSeconds',
			'NumberOfPageViews',
		],
		dimensions: dimensions.map(x => x.key),
		timeGranularity: 'Day',
	};

	return (
		<>
			<div className="flex analytics_main_line">
				<div className="flex_item_full">
					<NavSession
						ComparisonSelected={ComparisonSelected}
						setNavFilter={setNavFilter}
						initialDates={initialDates}
						handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
						setisCustomSelected={setisCustomSelected}
						isCustomSelected={isCustomSelected}
						customDateRange={customDateRange}
						setCustomDateRange={setCustomDateRange}
						isCustomCompareSelected={isCustomCompareSelected}
						setisCustomCompareSelected={setisCustomCompareSelected}
						customCompareDateRange={customCompareDateRange}
						setCustomCompareDateRange={setCustomCompareDateRange}
					/>
					<ChipContainer
						styles={styles}
						chips={chips}
						onDelete={onDeleteChip}
					/>
				</div>
				<div className="flex_item_fix ml_30">
					<CsvModal 
						fetchCsvService={(fromDate, toDate, callbackSuccess, callbackError) => {
							const fetchOptions = { ...csvOptions };
							fetchOptions.fromDate = fromDate;
							fetchOptions.toDate = toDate;
							sessionService.getSessionsCsv(fetchOptions, callbackSuccess, callbackError);
						}} />
					<OptionFilter
						optionList={dimensionList}
						setOptionList={updateDimensionList}
					/>
				</div>
			</div>
			<div className="analytics_body">
				<div className="analytics_body_main">
					<SessionsBavContainer
						navFilter={navFilter}
						service={sessionService}
						styles={styles}
						setNumberOfSessions={setNumberOfSessions}
						setNumberOfSessions2={setNumberOfSessions2}
						ComparisonSelected={ComparisonSelected}
						setTotalsPeriode1={setTotalsPeriode1}
						setTotalsPeriode2={setTotalsPeriode2}
					/>
					<InsightContainer
						selectedTenant={selectedTenant}
						service={sessionService}
						socket={socket}
					/>
					<SessionsChartContainer
						navFilter={navFilter}
						service={sessionService}
						accountId={accountId}
						localConfig={localConfig}
						selectedDimension={selectedDimension}
						setSelectedDimension={setSelectedDimension}
						useTimeDisabled={useTimeDisabled}
						setUseTimeDisabled={setUseTimeDisabled}
						selectedTimeGranularity={selectedTimeGranularity}
						setSelectedTimeGranularity={setSelectedTimeGranularity}
					/>
				</div>
				<div className="analytics_body_side">
					<SessionsCardContainer
						navFilter={navFilter}
						service={sessionService}
						styles={styles}
						handleClick={onCardClick}
						accountId={accountId}
						dimensions={dimensionList}
						selectedTenant={selectedTenant}
						numberOfSessions={numberOfSessions}
						numberOfSessions2={numberOfSessions2}
						ComparisonSelected={ComparisonSelected}
						metricTypeSessionsSelected={metricTypeSessionsSelected}
						totalsPeriod1={totalsPeriod1}
						totalsPeriode2={totalsPeriode2}
						chips={chips}
					/>
				</div>
			</div>
		</>
	);
}
