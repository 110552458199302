import React, { useEffect, useState, Fragment } from 'react';
import { react2angular } from 'react2angular';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SystemServices from '../../../Services/SystemServices';
import OrchestrationServices from '../../../Services/OrchestrationServices';
import OrchestrationGroupDashboard from './OrchestrationGroupDashboard';
import EditGroupOrchestrationContainer from './EditGroupOrchestrationContainer';
import Btn from '../../../Components/Btn';
import Confirm from '../../../Components/Confirm';
import Link from '../../../Components/Link';
import IconBtn from '../../../Components/IconBtn';
import RouterWrapper from '../OnSite/RouterWrapper';
import { Article } from '../../../Components/Article/Article';

export default function OrchestrationContainer(props) {
	const systemServices = new SystemServices(props.$rootScope);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const orchestrationServices = new OrchestrationServices(props.$http, props.$routeParams);
	const [currentTab, setCurrentTab] = useState(1);
	const [currentOrchestration, setCurrentOrchestration] = useState();
	const [oppenSettingsForCurrentorchestration, setOppenSettingsForCurrentorchestration] = useState();
	const [clickToSave, setClickToSave] = useState(false);
	const [clickToCancel, setClickToCancel] = useState(false);
	const [hasNotSavedChanges, sethasNotSaveChanges] = useState(false);
	const [modalCOnfirmIsOppen, setModalCOnfirmIsOppen] = useState(false);
	const [modalDeleteConfirmIsOppen, setModalDeleteConfirmIsOppen] = useState(false);
	const returnToDashBoard = (deleting) => {
		if (!deleting && hasNotSavedChanges) {
			setModalCOnfirmIsOppen(true);
		} else {
			setCurrentTab(1);
			setCurrentOrchestration();
		}
	};

	const deleteAnOrchestration = () => {

		orchestrationServices.deleteOrchestration(accountId, currentOrchestration.group.id,
			success => {
				systemServices.showSuccess('The group has been deleted with success');
				setModalDeleteConfirmIsOppen(false);
				sethasNotSaveChanges(false);
				setTimeout(() => {

					returnToDashBoard('deleting');
				}, 500);
			},
			error => {
				console.error('error', error);

				systemServices.showError('An error occured during the delete of the group');
			});
	};

	return (
		<>
			<Confirm
				title={'Do you really want to delete this group?'}
				text={'This action is irreversible'}
				confirmText='Delete'
				confirmCallback={() => deleteAnOrchestration()}
				confirmColor='alert'
				isOpen={modalDeleteConfirmIsOppen}
				onClose={() => setModalDeleteConfirmIsOppen(false)}
			/>;
			<Confirm
				title='Quit orchestration edition'
				text='Are you sure to quit without saving?'
				isOpen={modalCOnfirmIsOppen}
				onClose={() => setModalCOnfirmIsOppen(false)}
				confirmCallback={() => {
					setCurrentTab(1);
					setCurrentOrchestration();
					sethasNotSaveChanges(false);
				}}
				cancelText="Stay"
				confirmText="Quit without saving"
			/>
			{currentTab === 1 &&
				<RouterWrapper props={props}>
					<OrchestrationGroupDashboard
						accountId={accountId}
						setCurrentTab={(e) => setCurrentTab(e)}
						setCurrentOrchestration={(e) => setCurrentOrchestration(e)}
						orchestrationServices={orchestrationServices}
						systemServices={systemServices}
					/>
				</RouterWrapper>
			}
			{currentTab === 2 &&
				<RouterWrapper props={props}>
					<div className='page_full_inner'>
						<section className="section no_bottom_pad section_primary">
							<div className='flex'>
								<div className='flex_item_full flex'>
									<Link
										message="Orchestration"
										onClick={() => returnToDashBoard()}
										className='h1'
									/>
									<i className='fas fa-chevron-right fa-fw grey_5 s_13 ml_10 mr_10'></i>
									<span className='h1'>
										{currentOrchestration && currentOrchestration.group.name}
									</span>
									<span className='ml_5'>
										<IconBtn
											icon="fas fa-pen"
											size="s"
											tooltip="Rename group"
											onClick={() => setOppenSettingsForCurrentorchestration(true)}
										/>
										<IconBtn
											icon="fas fa-trash"
											size="s"
											tooltip="Delete group"
											onClick={() => setModalDeleteConfirmIsOppen(true)}
											hoverColor="alert"
										/>
									</span>
								</div>
								<div className='flex_item_fix'>
									<Btn
										onClickFunction={hasNotSavedChanges ? () => { setClickToCancel(!clickToCancel); sethasNotSaveChanges(false); } : null}
										message="Cancel"
										style="ghost"
										color="secondary"
										disabled={!hasNotSavedChanges}
									/>
									<Btn
										onClickFunction={hasNotSavedChanges ? () => setClickToSave(!clickToSave) : null}
										message="Save changes"
										disabled={!hasNotSavedChanges}
									/>
								</div>
							</div>
							{currentOrchestration && currentOrchestration.group.description &&
								<div className='orchestration_group_desc grey_1 s_14'>
									{currentOrchestration.group.description}
								</div>
							}
						</section>
						{currentOrchestration &&
							<section className="section">
								<Article size='l'>
									<EditGroupOrchestrationContainer
										accountId={accountId}
										clickToSave={clickToSave}
										setClickToSave={(e) => setClickToSave(e)}
										clickToCancel={clickToCancel}
										setClickToCancel={(e) => setClickToCancel(e)}
										setCurrentTab={(e) => setCurrentTab(e)}
										currentOrchestration={currentOrchestration}
										setCurrentOrchestration={(e) => setCurrentOrchestration(e)}
										returnToDashBoard={returnToDashBoard}
										oppenSettingsForCurrentorchestration={oppenSettingsForCurrentorchestration}
										setOppenSettingsForCurrentorchestration={(e) => setOppenSettingsForCurrentorchestration(e)}
										orchestrationServices={orchestrationServices}
										systemServices={systemServices}
										sethasNotSaveChanges={(e) => sethasNotSaveChanges(e)}
									/>
								</Article>
							</section>
						}
					</div>
				</RouterWrapper>
			}
		</>
	);
}
angular
	.module('beyableSaasApp.Orchestration', [])
	.component('orchestrationcontainer', react2angular(OrchestrationContainer, [], ['$http', '$rootScope', '$routeParams']));