import React, { Component } from 'react'
import { react2angular } from 'react2angular';
import './SkuContainer.css'

export default class SkuContainer extends Component {
    constructor(props) {
        super(props);
        const self = this

        this.$scope = props.$scope.$parent.$parent;
        this.state = {
            skuValue: '',
            slide: null,
            slideHasSku:false
        }

        this.props.onLoad(function (data) {
            var skus = data.Properties.ProductReferences ? (data.Properties.ProductReferences + '').split(";").filter(x => x).join('\n') : '';

            self.setState({
                slide: data,
                skuValue: skus,
                slideHasSku:data.HasSku
            })
        })
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        let textAreaValue = { ...this.state.skuValue }
        textAreaValue = e.target.value
        this.setState({ skuValue: textAreaValue })
    }

    componentDidUpdate() {
        if (!this.state.slide) return;

        const skuList = (this.state.skuValue.split(/[;,\n]/)).map(item => item.trim()).filter(x => x).join(";")
        this.state.slide.Properties.ProductReferences = skuList;
    }

    render() {
        return (
            this.state.slideHasSku ?
            <div className="options textArea">
                <h5>Sku list</h5>
                <p>Type here your SKU values separated with a line break, comma ( , ) or semicolon ( ; ).</p>
                <textarea className="skuSection" value={this.state.skuValue} onChange={this.handleChange} placeholder="SKU list" />
            </div>
            :
            <></>
        )
    }
}
angular
    .module('beyableSaasApp.Campaigns.Create.Controllers')
    .component('skuSelector', react2angular(SkuContainer, ['onLoad'], ['$scope']));