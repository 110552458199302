import React, { useState, useCallback, useEffect } from 'react';
import { react2angular } from 'react2angular';
import StatisticsDashboard from './StatisticsDashboard';
import './StatisticsContainer.css';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import BusinessOperationServices from '../../../Services/BusinessOperationServices';
import UsersAdministrationServices from '../../../Services/UsersAdministrationServices';
import SystemServices from '../../../Services/SystemServices';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import StatusOperation from './../UI/StatusOperation';
import StatisticsChartContainer from './StatisticsChartContainer';
import Btn from './../../../Components/Btn';
import Modal from './../../../Components/Modal';
import KpiContainer from './../UI/KpiContainer';
import formatMoment from '../../../Constants/FormatMoment';
import { v4 as uuidv4 } from 'uuid';
import bronze from '../../../Permissions/rules-bronze';
import gold from '../../../Permissions/rules-gold';
import silver from '../../../Permissions/rules-silver';
import { check } from '../../../Components/Can';
import LabelList from '../../Labels/LabelList';
export default function StatisticsContainer(props) {
	const { formatDateToLocal } = formatMoment;
	const [permissions, setPermissions] = useState({ plan: 'gold', roles: 'simplifyAdmin' });
	const [planAccount, setPlanAccount] = useState(gold);
	const [userSimplifyRole, setUserSimplifyRole] = useState('simplifyAdmin');
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const businessOperationServices = new BusinessOperationServices(props.$http, props.AuthServices);
	const usersAdministrationServices = new UsersAdministrationServices(props.$http, props.$routeParams);
	const systemServices = new SystemServices(props.$rootScope);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const operationId = props.$routeParams.id;
	const operationStartDate = props.$routeParams.startDate;
	const operationEndDate = props.$routeParams.endDate;
	const [operationInfos, setOperationInfos] = useState();
	const [secondBtn, setSecondBtn] = useState();
	const getStatisticsByOperationId = (accountId, operationId, startDate, endDate, timegranularity, callbackSuccess, callbackError) =>
		businessOperationServices.getStatisticsByOperationId(accountId, operationId, startDate, endDate, timegranularity, success => {
			callbackSuccess(success);
		}, error => { systemServices.showError('An error has occurred, please contact us.'); callbackError(error); });

	const putOperationExecutionStatusModeWithTest = (accountId, operationId, isStopped, executionMode, callbackSuccess, callbackError) =>
		businessOperationServices.putOperationExecutionStatusModeWithTest(accountId, operationId, isStopped, executionMode,
			success => {
				callbackSuccess(success);
			},
			error => { systemServices.showError('Error during the edit of status mode'); callbackError(error); }
		);
	const getAccountUsers = (accountId, callbackSuccess, callbackError) => usersAdministrationServices.getAccountUsers(accountId, success => { callbackSuccess(success); }, error => { systemServices.showError('Error'); callbackError(error); });
	const today = moment();
	const idTooltipOfReturnBtn = uuidv4();

	const [modalIsClicked, setModalIsClicked] = useState(false);
	const [zoneId, setZoneId] = useState();
	const [loading, setLoading] = useState(true);
	const [reload, setReload] = useState(true);
	const [dataOperationForStats, setDataOperationForStats] = useState();
	const [dataOperationForStatsGlobal, setDataOperationForStatsGlobal] = useState();
	const [impressionsNumb, setImpressionsNumb] = useState();
	const [expositionsNumb, setExpositionsNumb] = useState();
	const [clicksNumb, setClicksNumb] = useState();
	const [convPostViewsNumb, setConvPostViewsNumb] = useState();
	const [convPostClickNumb, setConvPostClickNumb] = useState();
	const [stateOfOperationToValidate, setStateOfOperationToValidate] = useState();
	const [dataOfOperationToPut, setDataOfOperationToPut] = useState();
	const [focusedInput, setFocusedInput] = useState(null);
	const [dateRange, setdateRange] = useState({
		startDate: null,
		endDate: null
	});
	const { startDate, endDate } = dateRange;
	const putOperationExecutionStatusMode = (x) => {
		setLoading(true);
		putOperationExecutionStatusModeWithTest(accountId, x.idOp, x.status, x.executionMode, e => {
			setReload(true);
			setDataOfOperationToPut();
		},
		e => {
			console.log('status error', e);
			setLoading(false);
			setReload(false);
		});
	};

	const pauseTheOperation = () => {
		putOperationExecutionStatusMode({
			idOp: operationInfos.operationId,
			status: true,
			executionMode: 1
		});
	};
	const changeStateOfTheCurrentOperation = (e) => {
		if (e === 'testMode') {
			putOperationExecutionStatusMode({
				idOp: dataOfOperationToPut.operationId,
				status: false,
				executionMode: 2
			});
		}
		if (e === 'stopped') {
			putOperationExecutionStatusMode({
				idOp: dataOfOperationToPut.operationId,
				status: true,
				executionMode: 1
			});
		}
		if (e === 'ongoing') {
			putOperationExecutionStatusMode({
				idOp: dataOfOperationToPut.operationId,
				status: false,
				executionMode: 1
			});
		}
		setModalIsClicked(false);
	};
	// changement de granularité selon nombre de mois séléctionné
	const changeGranularity = (begin, end) => {
		let nbDays = end.diff(begin, 'days');
		if (nbDays >= 90) {
			return 'month';
		}
		else if (nbDays >= 29) {
			return 'week';
		}
		else {
			return 'day';
		}
	};
	const loadData = useCallback(() => {
		const start = moment(operationStartDate.split('T')[0]);
		const end = moment(operationEndDate.split('T')[0]);
		let endToFetch = moment();
		if (end > today) {
			endToFetch = today.format('YYYY-MM-DD');
		} else {
			endToFetch = end.format('YYYY-MM-DD');
		}
		setdateRange({
			startDate: start,
			endDate: moment(endToFetch)
		});
		let granularity = changeGranularity(start, moment(endToFetch));
		getStatisticsByOperationId(accountId, operationId, start.format('YYYY-MM-DD'), endToFetch, granularity, (data) => {
			setDataOperationForStats(data.detailedByWebsitePlacesStatistics);
			setOperationInfos(data.operation);
			setDataOperationForStatsGlobal({
				data: data.aggregatedByGranularityStatistics,
				numberOfClicks: data.numberOfClicks,
				numberOfConversions: data.numberOfConversions,
				numberOfConversionsPostClick: data.numberOfConversionsPostClick,
				numberOfImpressions: data.numberOfImpressions,
				numberOfImpressionsNonUnique: data.numberOfImpressionsNonUnique
			});
			setZoneId('global');
			setLoading(false);
			setReload(false);
		},
		error => {
			setReload(false);
			setLoading(false);
		});
	}, [setdateRange, setOperationInfos, setDataOperationForStats, setDataOperationForStatsGlobal, setZoneId, setLoading, setReload]);

	useEffect(() => {
		if (reload) {
			loadData();
		}
	}, [reload]);
	useEffect(() => {
		if (operationInfos) {
			actionBtn();
		}
	}, [operationInfos]);

	const handleOnDateChange = (startDate, endDate) => {
		setdateRange(startDate, endDate);
	};
	let canTestOperation;
	let canEditStatusOperation;
	let canEditCampaignInformations;

	const loadAnotherPeriodData = useCallback((e) => {
		if (e !== undefined) {
			const start = e.startDate.format('YYYY-MM-DD');
			const end = e.endDate.format('YYYY-MM-DD');
			setLoading(true);
			let granularity = changeGranularity(e.startDate, e.endDate);
			getStatisticsByOperationId(accountId, operationId, start, end, granularity, (data) => {
				setDataOperationForStats(data.detailedByWebsitePlacesStatistics);
				setOperationInfos(data.operation);
				setDataOperationForStatsGlobal({
					data: data.aggregatedByGranularityStatistics,
					numberOfClicks: data.numberOfClicks,
					numberOfConversions: data.numberOfConversions,
					numberOfConversionsPostClick: data.numberOfConversionsPostClick,
					numberOfImpressions: data.numberOfImpressions,
					numberOfImpressionsNonUnique: data.numberOfImpressionsNonUnique
				});
				setZoneId('global');
				setLoading(false);
				setReload(false);
			}, error => {
				console.log('error', error);
				setReload(false);
				setLoading(false);
			});
		}
	}, [setDataOperationForStats, setOperationInfos, setDataOperationForStatsGlobal, setZoneId, setLoading, setReload]);

	const back = () => {
		if (props.$routeParams.prev === 'dashboard') {
			if (props.$routeParams.ka) {
				window.location.href = `BusinessOperations/Dashboard?ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}`;
			} else {
				window.location.href = 'BusinessOperations/Dashboard';
			}
		}
		if (props.$routeParams.prev === 'planning') {
			if (props.$routeParams.ka) {
				window.location.href = `BusinessOperations/Planning?ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}`;
			} else {
				window.location.href = 'BusinessOperations/Planning';
			}
		}
	};

	let displayStartDate, displayEndDate, diff, displayDuration;
	if (operationInfos && operationInfos.startDate && operationInfos.endDate) {
		displayStartDate = formatDateToLocal(operationInfos.startDate).format('DD/MM/YYYY');
		displayEndDate = formatDateToLocal(operationInfos.endDate).format('DD/MM/YYYY');
		diff = formatDateToLocal(operationInfos.endDate).diff(formatDateToLocal(operationInfos.startDate), 'days');
		displayDuration = diff > 1 ? diff + ' days' : diff + ' day';
	}
	const actionBtn = () => {
		if (operationInfos && operationInfos.executionMode === 1 && operationInfos && moment(operationInfos.endDate.split('T')[0]) > today && !operationInfos.isStopped) {
			setSecondBtn(canEditStatusOperation &&
				<Btn
					message="Stop"
					onClickFunction={(e) => pauseTheOperation(dataOfOperationToPut)}
					style="outline"
					icon="fas fa-pause"
				/>);
		}
		else if (operationInfos && operationInfos.executionMode === 1 && operationInfos && moment(operationInfos.endDate.split('T')[0]) > today && operationInfos.isStopped) {
			setSecondBtn(
				canEditStatusOperation &&
				<Btn
					message="Play"
					onClickFunction={(e) => { setModalIsClicked(true); setDataOfOperationToPut(operationInfos); setStateOfOperationToValidate('stopped'); }}
					style="outline"
					icon="fas fa-play"
				/>
			);
		}
		else if (operationInfos && operationInfos.executionMode === 2 && operationInfos && moment(operationInfos.endDate.split('T')[0]) > today) {
			setSecondBtn(
				<>
					{canTestOperation &&
						<Btn
							message="Testing URL"
							href={operationInfos.operationPreviewLink}
							target="_blank"
							style="outline"
							icon="fas fa-link"
						/>
					}
					{canEditStatusOperation &&
						<Btn
							message="Pause"
							onClickFunction={(e) => { setModalIsClicked(true); setDataOfOperationToPut(operationInfos); setStateOfOperationToValidate('testMode'); }}
							style="outline"
							icon="fas fa-pause"
						/>
					}
				</>
			);
		}
		else {
			{
				canEditStatusOperation &&
					setSecondBtn(<Btn
						message="Play"
						color="secondary"
						onClickFunction={() => { }}
						icon="fas fa-play"
						style="disabled"
					/>);
			}
		}
	};

	useEffect(() => {
		if (dataOperationForStats) {
			if (zoneId === 'global') {
				setImpressionsNumb(dataOperationForStatsGlobal.numberOfImpressionsNonUnique);
				setExpositionsNumb(dataOperationForStatsGlobal.numberOfImpressions);
				setClicksNumb(dataOperationForStatsGlobal.numberOfClicks);
				setConvPostViewsNumb(dataOperationForStatsGlobal.numberOfConversions);
				setConvPostClickNumb(dataOperationForStatsGlobal.numberOfConversionsPostClick);
			} else {
				const elementToLoad = dataOperationForStats.find(elem => elem.zoneId === zoneId);
				if (elementToLoad) {
					setImpressionsNumb(elementToLoad.numberOfImpressionsNonUnique);
					setExpositionsNumb(elementToLoad.numberOfImpressions);
					setClicksNumb(elementToLoad.numberOfClicks);
					setConvPostViewsNumb(elementToLoad.numberOfConversions);
					setConvPostClickNumb(elementToLoad.numberOfConversionsPostClick);
				}
			}
		}
	}, [zoneId]);

	// useEffect(() => {
	//     if (props.$rootScope) {
	//         getAccountUsers(accountId, data => {
	//             const rolesOfUser = data.find(user => user.id === props.$rootScope.User.Key);
	//             if (rolesOfUser) {
	//                 if (props.$routeParams.ka) {
	//                     setPermissions({ plan: "bronze", roles: "simplifyAdmin" })
	//                 } else {
	//                     const simplifyRoles = rolesOfUser.roles.reduce((acc, curr) => {
	//                         const accToLowerCase = curr.toLowerCase()
	//                         if (accToLowerCase.includes('simplify')) {
	//                             acc.push(curr);
	//                         }
	//                         return acc
	//                     }, [])
	//                     setPermissions({ plan: "bronze", roles: simplifyRoles.toString() })
	//                 }
	//             } else {
	//                 systemServices.showError('An error occured')
	//             }
	//         }, error => {
	//             systemServices.showError('An error occured')
	//             console.log("error", error);
	//         })
	//     }
	// }, [])
	// useEffect(() => {
	//     if (permissions) {
	//         setUserSimplifyRole(permissions.roles)
	//         if (permissions.plan === "gold") {
	//             setPlanAccount(gold)
	//         }
	//         if (permissions.plan === "bronze") {
	//             setPlanAccount(bronze)
	//         }
	//         if (permissions.plan === "silver") {
	//             setPlanAccount(silver)
	//         }
	//     }
	// }, [permissions])
	// useEffect(() => {
	//     setPlanAccount(gold)
	// }, [])

	if (planAccount && userSimplifyRole && operationInfos) {
		canTestOperation = check(planAccount, userSimplifyRole, 'simplifyOperation:canTestOperation', null);
		canEditStatusOperation = check(planAccount, userSimplifyRole, 'simplifyOperation:canEditStatusOperation', null);
		canEditCampaignInformations = check(planAccount, userSimplifyRole, 'simplifyOperation:canEditCampaignInformations', null);
	}

	return (
		<div className="dashboard-inner">
			<div className="flex flex_align_bottom">
				<div className="flex_item_full">
					<div className="">
						<a className="simpify_report--campaign--name" onClick={() => back()}>
							<i className="fas fa-arrow-left"></i> Back to dashboard
						</a>
					</div>
					<h1 className="no_margin">Reporting Business Operation</h1>
				</div>
				<div className="flex_item_fix flex flex_align_stretch">
					<DateRangePicker
						startDate={startDate} // momentPropTypes.momentObj or null,
						startDateId="idStartDate" // 
						endDateId="your_unique_end_date_id" //
						displayFormat='DD/MM/YYYY'
						endDate={endDate} // momentPropTypes.momentObj or null,
						isOutsideRange={date => date.isBefore(moment(operationStartDate.split('T')[0])) || moment(operationEndDate.split('T')[0]) > today ? date.isAfter(today) : date.isAfter(operationEndDate.split('T')[0])}
						focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
						onFocusChange={(focused) => setFocusedInput(focused)}
						onClose={(dates) => { loadAnotherPeriodData(dates); }}
						onDatesChange={(startDate, endDate) => handleOnDateChange(startDate, endDate)}
						anchorDirection="right"
					/>
					<Btn
						icon="fas fa-sync-alt"
						style="outline"
						className="ml_10"
						color="secondary"
						onClickFunction={(e) => { e.preventDefault(); loadAnotherPeriodData({ startDate, endDate }); }}
					/>
				</div>
			</div>
			<div className="modules-inner">
				<div className="flex flex_align_start">
					<div className="flex_item_full statistics-informations-operation">
						{displayStartDate && displayEndDate &&
							<div className="statistics-informations-operation-dates">
								<p>{displayStartDate} - {displayEndDate} ({displayDuration})</p>
							</div>
						}
						<div className="statistics-information-name-container">
							<div className="stats-name-operation">{operationInfos && operationInfos.name}</div>
							<StatusOperation
								stats={true}
								showPreview={false}
								op={operationInfos && operationInfos}
								onClickFunction={(e) => setModalIsClicked(e)}
								setStateOfOperationToValidate={(e) => setStateOfOperationToValidate(e)}
								setDataOfOperationToPut={(e) => setDataOfOperationToPut(e)}
							/>
						</div>
						<div className="stats-description">
							{operationInfos && operationInfos.description}
						</div>
						<div className="stats-label">
							<LabelList
								labels={operationInfos && operationInfos.operationLabels}
								inline={true}
								size="l"
							/>
						</div>
					</div>
					<div className="flex_item_fix statistics-container-edition-campaign">
						{!loading &&
							<>
								{secondBtn && secondBtn}
								{canEditCampaignInformations &&
									<Btn
										message="Edit"
										onClickFunction={
											props.$routeParams && props.$routeParams.ka ? () => {
												window.location.href = `/BusinessOperations/Create?id=${operationId}&ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}&prev=${props.$routeParams.prev}`;
											}
												:
												() => { window.location.href = `/BusinessOperations/Create?id=${operationId}&prev=${props.$routeParams.prev}`; }
										}
										icon="fas fa-pen"
									/>
								}
							</>
						}
					</div>
				</div>
				<section className="statistics-results">
					<KpiContainer
						title="Impressions"
						color="#006fff"
						value={impressionsNumb && impressionsNumb}
						titleKpi="Total of impressions"
					/>
					<KpiContainer
						title="Unique impressions"
						color="#5CB85C"
						value={expositionsNumb && expositionsNumb}
						titleKpi="Total of unique impressions"
					/>
					<KpiContainer
						title="Clicks"
						color="#FFC618"
						value={clicksNumb && clicksNumb}
						percent={clicksNumb && (clicksNumb / expositionsNumb * 100).toFixed(2)}
						titleKpi="Total of clicks"
					/>
					<KpiContainer
						title="Conversions post view"
						color="#292F4C"
						value={convPostViewsNumb && convPostViewsNumb}
						titleKpi="Total of conversions post view"
					/>
					<KpiContainer
						title="Conversions post click"
						color="#9654A9"
						value={convPostClickNumb && convPostClickNumb}
						titleKpi="Total of conversions post click"
					/>
				</section>
				<div className="simplify-stats-container-chart">
					<StatisticsChartContainer
						values={dataOperationForStats && dataOperationForStats}
						zoneId={zoneId}
						dataOperationForStatsGlobal={dataOperationForStatsGlobal && dataOperationForStatsGlobal}
					/>
				</div>
				<StatisticsDashboard
					dataOperationForStats={dataOperationForStats && dataOperationForStats}
					zoneId={zoneId}
					setZoneId={(e) => setZoneId(e)}
				/>
				{loading &&
					<div className="spinner_overlay">
						<div className="spinner">
							<div className="rect1"></div>
							<div className="rect2"></div>
							<div className="rect3"></div>
							<div className="rect4"></div>
							<div className="rect5"></div>
						</div>
					</div>
				}
			</div>
			<Modal
				isOpen={modalIsClicked}
				width="550"
				height="auto"
				minHeight="196"
				onClose={() => setModalIsClicked(false)}
			>
				<div className="modal_header has_border">
					<div>Starting options</div>
				</div>
				<div className="modal_body">
					<article className="radio_box_inner flex_justify_center">
						<label className={stateOfOperationToValidate === 'testMode' ? 'radio_box s checked' : 'radio_box s '}
							onClick={(e) => setStateOfOperationToValidate('testMode')}
						>
							<input
								type="radio"
								value="TEST_MODE"
								className=""
								name="31"
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fa fa-vial"></i>
							<p>Test</p>
						</label>
						<label
							className={stateOfOperationToValidate === 'stopped' ? 'radio_box s checked' : 'radio_box s'}
							onClick={(e) => setStateOfOperationToValidate('stopped')}
						>
							<input
								type="radio"
								value="STOPPED"
								name="32"
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fa fa-pause"></i>
							<p>Stopped</p>
						</label>
						<label
							className={stateOfOperationToValidate === 'ongoing' ? 'radio_box s checked' : 'radio_box s'}
							onClick={(e) => setStateOfOperationToValidate('ongoing')}
						>
							<input type="radio"
								value="ONGOING"
								name="33"
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fa fa-play"></i>
							<p>On-going</p>
						</label>
					</article>
					<div className="starting_options_message al_center">
						{stateOfOperationToValidate === 'stopped' &&
							'This campaign will be stopped, no one will be able to see it.'
						}
						{stateOfOperationToValidate === 'ongoing' &&
							'This campaign will be ongoing, every one will be able to see it.'
						}
						{stateOfOperationToValidate === 'testMode' &&
							'This campaign will be on test mode'
						}
					</div>
				</div>
				<div className="modal_footer modal_footer_center">
					<Btn
						onClickFunction={(e) => { setModalIsClicked(false); }}
						message="Cancel"
						style="outline"
						color="secondary"
						size="l"
					/>
					<Btn
						onClickFunction={(e) => changeStateOfTheCurrentOperation(stateOfOperationToValidate)}
						message="Confirm"
						icon="fas fa-check"
						size="l"
					/>
				</div>
			</Modal>
		</div>
	);
}
angular
	.module('beyableSaasApp.BusinessOperationsStats', ['beyableSaasApp.Admin.Services'])
	.component('businessoperationstats', react2angular(StatisticsContainer, [], ['$http', '$rootScope', '$routeParams', 'AuthServices']));
