import HttpServices from './HttpServices';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';

export default class CampaignCreatingServices {
	constructor($http, $routeParams, authServices) {
		this.campaignId = $routeParams ? $routeParams.key : '';
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		const token = authServices.getAccessToken();
		const defaultOptions = {
			watchQuery: {
				fetchPolicy: 'no-cache',
				errorPolicy: 'ignore',
			},
			query: {
				fetchPolicy: 'no-cache',
				errorPolicy: 'all',
			},
		};
		const httpLink = createHttpLink({
			uri: window.BEYABLE_env.URI_GRAPHQL
		});

		const authLink = setContext((_, { headers }) => {
			return {
				headers: {
					...headers,
					authorization: token ? `Bearer ${token}` : '',
				}
			};
		});

		this.graphQlClient = new ApolloClient({
			link: authLink.concat(httpLink),
			cache: new InMemoryCache(),
			defaultOptions: defaultOptions,
		});
	}

	getAccountIPAddresses(accountId){
		return this.graphQlClient.query({
			query: gql`
          query($accountId: String) {
            account(id: $accountId){
              settings{
                  testModeIpAddresses{
                      ipAddress,
                      label
                  }
              }
            }
          }`,
			variables: {
				accountId: accountId
			}});
	}

	getBespokeTemplates(accountId, callbackSuccess, callbackError, { developmentMode, editor }) {
		const getQuery = () => {
			if (developmentMode || editor){
				const q = [];
				developmentMode && q.push('developmentMode=true');
				editor && q.push('editor=' + editor);
				return `?${q.join('&')}`;
			}
			return '';
		};
		const query = getQuery();
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/bespoketemplates/${accountId}${query}`, callbackSuccess, callbackError);
	}
	getModuleTemplates(accountId, callbackSuccess, callbackError, developmentMode) {
		const getQuery = () => {
			if (developmentMode){
				const q = [];
				q.push('developmentMode=true');
				return `?${q.join('&')}`;
			}
			return '';
		};
		const query = getQuery();
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/module/${accountId}${query}`, callbackSuccess, callbackError);
	}
	getOneModuleTemplates(accountId, moduleId, callbackSuccess, callbackError, developmentMode) {
		const getQuery = () => {
			if (developmentMode){
				const q = [];
				q.push('developmentMode=true');
				return `?${q.join('&')}`;
			}
			return '';
		};
		const query = getQuery();
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/module/${accountId}/${moduleId}${query}`, callbackSuccess, callbackError);
	}
	getCampaignInformations(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.SERVER_URL}account/${accountId}/campaign/${campaignId}/`, callbackSuccess, callbackError);
	}
	createNewCampaign(accountId, campaign, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.SERVER_URL}account/${accountId}/campaign`, campaign, callbackSuccess, callbackError);
	}
	updateExistingCampaign(accountId, campaign, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}account/${accountId}/campaign`, campaign, callbackSuccess, callbackError);
	}

	putCampaignToGetPreviewUrl(accountId, userId, campaignData, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}account/${accountId}/userpreviewcampaign/${userId}`, campaignData, callbackSuccess, callbackError);
	}
	getFormatSavedStyles(accountId, templateId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}displaylibrary/templatestyle/${accountId}/template/${templateId}`, callbackSuccess, callbackError);
	}
	putNewSavedStyle(accountId, templateId, style, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}displaylibrary/templatestyle/${accountId}/template/${templateId}`, style, callbackSuccess, callbackError);
	}
	deleteSavedStyle(accountId, templateId, styleId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}displaylibrary/templatestyle/${accountId}/template/${templateId}/${styleId}`, callbackSuccess, callbackError);
	}
	publishSavedStyle(accountId, templateId, styleId, isPublished, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}displaylibrary/templatestyle/${accountId}/template/${templateId}/${styleId}/publish`, { isPublished: isPublished }, callbackSuccess, callbackError);
	}
	getCampaignRendering(accountId, campaignData, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.SERVER_URL}account/${accountId}/campaignrendering`, campaignData, callbackSuccess, callbackError);
	}
	putCampaignEndExperiment(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}users/${accountId}/campaigns/${campaignId}/endCampaign`, campaignId, callbackSuccess, callbackError);
	}

}

