
function targetedPromotions(rootscope) {
    return {
        key: "TargetedPromotions",
        id: 'TargetedPromotions',
        available: false,
        label: 'Targered promotions',
        formatLabel: 'Popup',
        formatImg: 'illu_popin.svg',
        description: 'When a user with cart attempt to leave your web-site, attract him with a special offer throught a viewable popup.',
        steps: [
            {
                id: 'intro',
                attachTo: {
                    element: '.shephardTitle',
                    on: 'top'
                },

                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 10);
                            resolve();
                        }, 50);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'cancel'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Next',
                        type: 'next'
                    }
                ],
                title: 'Welcome to the scenario Stop Abandonment',
                text: ['First, you need to add a title for the campaign.'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro"
                    },
                    hide: () => {

                    }
                }
            },
            {
                id: 'intro01',
                attachTo: {
                    element: '.campaign_from_to',
                    on: 'top'
                },
                tetherOptions: {
                    target: '.inner'
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 10);
                            resolve();
                        }, 50);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'hide'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Back',
                        type: 'back'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Next',
                        type: 'next'
                    }
                ],
                title: 'Select a date',
                text: ['Select dates .'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro01"
                    },
                    hide: () => {
                    }
                }
            },
            {
                id: 'intro2',
                attachTo: {
                    element: ".improveConversion",
                    on: "top"
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 10);
                            resolve();
                        }, 30);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'hide'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Back',
                        type: 'back'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Next',
                        type: 'next'
                    }
                ],
                title: 'Select a campaign objective',
                text: ['You can choose an objective'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro2"
                    },
                    hide: () => {

                    }
                }
            },
            {
                id: 'intro3',
                attachTo: {
                    element: ".contributionMode",
                    on: "top"
                },
                // attachTo: '.shephard2',
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 0);
                            resolve();
                        }, 50);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'hide'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Back',
                        type: 'back'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'next',
                        type: 'next'
                    },

                ],
                title: 'Select contribution mode',
                text: ['You need to select a contribution mode'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro3"
                    },
                    hide: () => {
                        console.log('hide step');
                    }
                }
            },
            {
                id: 'intro4',
                attachTo: {
                    element: ".devicesChoice",
                    on: "bottom"
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 10);
                            resolve();
                        }, 50);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'hide'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Back',
                        type: 'back'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'next',
                        action() {
                            rootscope.$broadcast("event:campaignStepChange", 1);
                            this.next()
                        }
                    },
                ],
                title: 'Select devices',
                text: ['Select the devices for the campaign'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro4"
                    },
                    hide: () => {

                    }
                }
            },
            {
                id: 'intro5',
                attachTo: {
                    element: ".chooseTrigger",
                    on: "right"
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 10);
                            resolve();
                        }, 50);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'hide'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Back',
                        action() {
                            rootscope.$broadcast("event:campaignStepChange", 0);
                            this.back()
                        }
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'next',
                        action() {
                            this.next()
                        }
                    },
                ],
                title: 'Select a trigger',
                text: ['You can choose a trigger'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro5"
                    },
                    hide: () => {

                    }
                }
            },
            {
                id: 'intro6',
                attachTo: {
                    element: ".triggerConditionsList",
                    on: "top"
                },
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 10);
                            resolve();
                        }, 50);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'hide'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Back',
                        type: 'back'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'next',
                        action() {
                            rootscope.$broadcast("event:campaignStepChange", 2);
                            this.next()
                        }
                    },
                ],
                title: 'Select a number',
                text: ['We recommand .....'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro6"
                    },
                    hide: () => {

                    }
                }
            },
            {
                id: 'intro7',
                attachTo: {
                    element: ".modelFormatList",
                    on: "bottom"
                },
                // attachTo: '.shephard2',
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 10);
                            resolve();
                        }, 50);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'hide'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Back',
                        action() {
                            rootscope.$broadcast("event:campaignStepChange", 1);
                            this.back()
                        }
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'next',
                        action() {
                            console.log(rootscope.shepherdCurrentSlide);
                            if (rootscope.shepherdCurrentSlide.Format == 2) {
                                console.log("yes");
                                this.next()
                            } else {
                                console.log("non")
                            }

                        }
                    },
                ],
                title: 'Select a format',
                text: ['Select header bar'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro7"
                    },
                    hide: () => {

                    }
                }
            },
            {
                id: 'intro8',
                attachTo: {
                    element: ".chooseTemplateType",
                    on: "bottom"
                },
                // attachTo: '.shephard2',
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 10);
                            resolve();
                        }, 50);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'hide'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Back',
                        type: 'back'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'next',
                        action() {
                            // helpers.myFunction(1);
                            this.next()
                        }
                    },
                    {
                        classes: 'btnUi btn_success',
                        text: 'complete',
                        type: 'complete'
                    }
                ],
                title: 'Select a number',
                text: ['You can choose a nanananana finfin'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro8"
                    },
                    hide: () => {

                    }
                }
            },
            {
                id: 'intro9',
                attachTo: {
                    element: ".btnSaveAndQuit",
                    on: "left"
                },
                // attachTo: '.shephard2',
                beforeShowPromise: function () {
                    return new Promise(function (resolve) {
                        setTimeout(function () {
                            window.scrollTo(0, 10);
                            resolve();
                        }, 50);
                    });
                },
                buttons: [
                    {
                        classes: 'btnUi btn_warning',
                        text: 'Exit',
                        type: 'hide'
                    },
                    {
                        classes: 'btnUi btn_primary',
                        text: 'Back',
                        type: 'back'
                    },
                ],
                title: 'Save',
                text: ['You can save the campaign'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro9"
                    },
                    hide: () => {

                    }
                }
            },
        ],
    }
};
export default targetedPromotions;