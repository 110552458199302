import React from 'react';
import Btn from '../../../../Components/Btn';
import Checkbox from '../../../../Components/Checkbox';

export default function AddComparisonPeriod({
	comparisonSelected,
	closeDropdown,
	selectHandler }) {
	const handleSelecteIsActive = (e) => {
		closeDropdown();
		const val = e.target.checked;
		selectHandler(val);
	};
	return (<span

	>
		{
			<Checkbox
				name="isActive"
				forcedChecked={comparisonSelected}
				value={comparisonSelected}
				onChange={(e) => handleSelecteIsActive(e)}
			>
				Compare periods
			</Checkbox>
		}

	</span>
	)
}
