import React from 'react';
import Insight from '../Insight/Insight';

export default function OverviewBavWithoutCamparison({
	bav, uniqueVisitors
}) {

	return (
		<>
			<Insight label="Revenue" {...bav.Revenue} />
			<Insight label="Conversion rate" {...bav.SessionToSaleRatio} />
			<Insight label="Transaction count" {...bav.TransactionCount} />
			<Insight label="Cart amount" {...bav.TransactionAverageAmount} />
			{/* <Insight label="Unique visitors" {...uniqueVisitors} /> */}
		</>
	);
}
