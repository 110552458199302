import React, { useState, useEffect } from 'react';
import Modal from '../../../../Components/Modal';
import Btn from '../../../../Components/Btn';
import { v4 as uuidv4 } from 'uuid';
import CreateLabel from '../../../Labels/CreateLabel';
import LabelSelector from '../../../Labels/LabelSelector';


export default function LabelsAccountContainer({
	labelService,
	accountId,
	systemServices,
	modalLabelsIsOpen,
	setModalLabelsIsOpen,
	objectType,
	accountSelected
}) {

	const subTitle = '';
	const objectId = accountSelected.id;


	const [isLoading, setisLoading] = useState(true);
	const [accountLabels, setAccountLabels] = useState();
	const [objectLabelIds, setObjectLabelIds] = useState();
	const [refetch, setrefetch] = useState(true);

	const onSave = () => { setModalLabelsIsOpen(false); };
	const onClose = () => { setModalLabelsIsOpen(false); };

	useEffect(() => {
		if (refetch) {
			labelService.getLabels(accountId,
				objectType,
				newData => {
					setisLoading(false);
					setrefetch(false);
					setAccountLabels(newData);
				}, err => {
					setisLoading(false);
					systemServices.showError('An Error occured while fetching labels');
				});
		}
	}, [refetch]);
	useEffect(() => {
		labelService.getLabelsForObject(accountId,
			objectId,
			objectType,
			newData => {
				setisLoading(false);
				setrefetch(false);
				const arrayOfLabelsCampaign = newData.map(label => label.id);
				setObjectLabelIds(arrayOfLabelsCampaign);
			}, err => {
				setisLoading(false);
				systemServices.showError('An Error occured while fetching labels');
			});


	}, [accountLabels]);

	const handleSelectAnewLabelForCampaign = (label) => {
		if (!objectLabelIds.includes(label.id)) {
			setObjectLabelIds([...objectLabelIds, label.id]);
			setisLoading(true);
			labelService.addLabelForObject(accountId, objectId, objectType, label.id,
				newData => {
					setrefetch(true);
					setisLoading(false);
				},
				err => {
					setisLoading(false);
					systemServices.showError('An Error occured during the association of labels');
				});
		} else {
			const withoutId = objectLabelIds.filter(id => id !== label.id);
			setObjectLabelIds(withoutId);
			setisLoading(true);
			labelService.deleteLabelForObject(accountId, objectId, label.id,
				newData => {
					setrefetch(true);
					setisLoading(false);
				},
				err => {
					setisLoading(false);
					systemServices.showError('An Error occured during the delete of labels');
				});
		}
	};
	return (
		<div>
			<Modal
				isOpen={modalLabelsIsOpen}
				width="450"
				onClose={(e) => onClose()}
			>
				<div className="modal_header">
					Accounts labels
					<div className="modal_header_desc"></div>
				</div>
				<CreateLabel
					systemServices={systemServices}
					labelService={labelService}
					accountId={accountId}
					setrefetch={(e) => setrefetch(e)}
					setisLoading={(e) => setisLoading(e)}
					onAddLabel={(e) => handleSelectAnewLabelForCampaign(e)}
					objectType={objectType}
				/>
				<div className="modal_body popin_labels_inner">
					<LabelSelector
						selectableLabels={accountLabels || []}
						selectedLabelIds={objectLabelIds || []}
						labelSelected={l => handleSelectAnewLabelForCampaign(l)}
					/>
					{!isLoading && accountLabels && accountLabels.length == 0 && <div className="popin_labels_empty" >
						<i className="fas fa-tag"></i>
						<div>No label yet</div>
					</div>}

				</div>
				<div className="modal_footer modal_footer_right">
					<Btn
						message="Done"
						style="outline"
						color="secondary"
						onClick={(e) => onSave()}
					/>
				</div>

				{isLoading &&
					<div className="mask">
						<div className="wheel"></div>
					</div>
				}
			</Modal>
		</div>
	);
}
