import React from 'react';
import './ConfirmModal.css';
export default function ConfirmModal({ close, stopPropa, deleteTheTag, totalOfSku, nameOfTagList }) {
	return (
		<div onClick={(e) => close(e)} className="containerConfirmModal">
			<div onClick={(e) => stopPropa(e)} className="contenuModal">
				<div className="btn-closeModal" onClick={(e) => close(e)}>Close <i className="fas fa-times"></i></div>
				<div className="textModal">
                    Do you really want to delete : <br></br>{nameOfTagList} ({totalOfSku})
				</div>
				<div className='btnGroup'>
					<div className="btnCancelModal " onClick={(e) => close(e)} >Cancel</div>
					<div className="btnDeleteModal" onClick={() => deleteTheTag()}>Delete</div>
				</div>
			</div>
		</div>
	);
}
