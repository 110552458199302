import React, {useState} from 'react';
import { abTestsDashboardContext } from './context';
import EmptyState from '../../../Components/EmptyState';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import ABTestsItemCompleted from './ABTestItemCompleted';
import ABTestsItemPending from './ABTestItemPending';

import styles from './ABTestDashboard.module.css';
import Pagination from './Pagination';
import SearchBar from '../../../Components/SearchBar';
import AbTestDevicesFilters from './AbtestDevicesFilters';
import LabelSelectDropdown from '../../Labels/LabelSelectDropdown';
import {listDevices} from '../utils';

export default function ABTestsDashboardCompleted() {
	const [modalLabelsAccountisOpen, setmodalLabelsAccountisOpen] = useState(false);
	const {
		loading,
		completedCampaigns, 
		$routeParams,
		canEditCampaigns,
		handlePendingCampaignsChangePage,
		currentPage,
		totalCountConpleted,
		setCurrentPage,
		pageSize,
		skipIdNumber,
		setskipIdNumber,
		dataIsNotCompleteForCompletedCampaign,
		valueInSearchBar,
		handleChangeValueInSearchBar,
		devicesSelected,
		resetDevices,
		handleChangeDevicesSelected,
		filterLabel,
		LabelsAccount,
		setfilterLabel,
		filterLabelId,
		resetFilterLabels,
		toggleFilterLabels,
		isAdmin
	} = abTestsDashboardContext();



	return (
		<>
			<div className='page_block'>
				<div className='page_block_header flex'>
					<div className='flex_item_full'>
						<div className='btn_group_l'>
							<SearchBar
								onChangeValue={handleChangeValueInSearchBar}
								valueInSearchBar={valueInSearchBar}
								placeholder='Find an experiment'
								size='l'
								fullWidth={false}
							/>
							<AbTestDevicesFilters
								handleChangeDevicesSelected={handleChangeDevicesSelected}
								resetDevices={resetDevices}
								devicesSelected={devicesSelected}
								initialValue={listDevices}
							/>
							<LabelSelectDropdown
								selectableLabels={LabelsAccount || []}
								selectedLabelIds={filterLabelId || []}
								onLabelSelected={l => toggleFilterLabels(l)}
								resetFilterLabels={resetFilterLabels}
								canEdit={(isAdmin || canEditCampaigns)}
								onEditRequested={() => setmodalLabelsAccountisOpen(true)}					
							/>
						</div>
					</div>
					{dataIsNotCompleteForCompletedCampaign	&& !loading &&
						<div className="flex_item_fix ml_20 flex">
							<span className='s_13 grey_3 mr_8'>Precision increase in progress</span>
							<SpinnerWheel
								display="inline"
								wheelSize="s"
							/>
						</div>
					}
				</div>

				{!loading && completedCampaigns.length > 0 &&
					<>
						<div className={'table_grid ' + styles.table}>
							<div className={'table_row table_head_row ' + styles.table_head_row}>
								<div className={'table_col ' + styles.table_col}>Experiment</div>
								<div className={'table_col ' + styles.table_col}>Status</div>
								<div className={'table_col ' + styles.table_col}>Sessions</div>
								<div className={'table_col ' + styles.table_col}>Objectives</div>
								<div className={'table_col ' + styles.table_col}>Improvement</div>
								<div className={'table_col ' + styles.table_col}>Probabiliy to beat baseline</div>
								<div className={'table_col ' + styles.table_col}></div>
							</div>
							{/* {completedCampaigns.map(campaign =>
								<ABTestsItemCompleted
									key={campaign.id}
									campaign={campaign}
								/>
							)} */}
							{completedCampaigns.map(campaign =>
								<ABTestsItemPending
									key={campaign.id}
									campaign={campaign}
								/>
							)}
						</div>
						<Pagination
							totalCount={totalCountConpleted}
							paginate={(e) => {
								handlePendingCampaignsChangePage( e >= 0 ? e : 0
								);
							}}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							pageSize={pageSize}
							skipIdNumber={skipIdNumber}
						/>
					</>
				}
				{loading && (
					<SpinnerWheel wheelSize='l' display='block' verticalSize='xl' />
				)}

				{!loading && completedCampaigns.length === 0 &&
					<EmptyState
						verticalSize={'xl'}
						textSize={'l'}
						title={'No completed experimentations yet'}
						text={'Completed experiments will be listed here'}
					/>
				}
			</div>
		</>);

}