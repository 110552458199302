import React from 'react';
import AnalitycsBoardItemEcommerce from '../Containers/Ecommerce/AnalitycsBoardItemEcommerce';
import { v4 as uuidv4 } from 'uuid';
export default function AnalitycsBoardTransaction({ 
	resultName,
	revuenue,
	tax,
	quantity,
	shipping,
	refund,
	date,
	data,
	orderBy,
	orderByDesc,
	handleSort,
	dataSumsMetrics
}) {

	function getSum() {
		const name = 'Total';
		const metrics = dataSumsMetrics;
		const Revenue = metrics.find(el => el.metric === 'Revenue');
		const TaxAmount = metrics.find(el => el.metric === 'TaxAmount');
		const ShippingAmount = metrics.find(el => el.metric === 'ShippingAmount');
		const Quantity = metrics.find(el => el.metric === 'ProductQuantitySum') ;
		return (
			<div className="table_row table_body_row table_body_total_row"
				key={uuidv4()}
			>
				<AnalitycsBoardItemEcommerce
					data={name}
					isHandleClick={false}
				/>
				<AnalitycsBoardItemEcommerce
					data={parseFloat(Revenue?.value).toFixed(2)}
					overhead={100}
					isHandleClick={false}
					needDevise={true}
					nowrap={true}
				/>
				<AnalitycsBoardItemEcommerce
					data={parseFloat(TaxAmount?.value).toFixed(2)}
					isHandleClick={false}
					needDevise={true}
				/>
				<AnalitycsBoardItemEcommerce
					data={parseFloat(ShippingAmount?.value).toFixed(2)}
					isHandleClick={false}
					needDevise={true}
				/>
				<AnalitycsBoardItemEcommerce
					data={Quantity?.value}
					isHandleClick={false}
				/>
				<div className="table_col"></div>
			</div>
		);
	}
	return (
		<div className="table_grid s table_grid_analytics table_grid_analytics_ecom_transaction">
			<div className="table_row table_head_row">
				<div className="table_col">
					<p className=""
						// onClick={() => {
						// 	orderBy === 'ResultName' && orderByDesc === 'desc' ? handleSort('ResultName', 'asc') :
						// 		handleSort('ResultName', 'desc');
						// }}
					>
						{orderBy === 'ResultName' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ResultName' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{resultName}
					</p>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'Revenue' && orderByDesc === 'desc' ? handleSort('Revenue', 'asc') :
								handleSort('Revenue', 'desc');
						}}
					>
						{orderBy === 'Revenue' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'Revenue' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{revuenue}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'TaxAmount' && orderByDesc === 'desc' ? handleSort('TaxAmount', 'asc') :
								handleSort('TaxAmount', 'desc');
						}}
					>
						{orderBy === 'TaxAmount' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'TaxAmount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{tax}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'ShippingAmount' && orderByDesc === 'desc' ? handleSort('ShippingAmount', 'asc') :
								handleSort('ShippingAmount', 'desc');
						}}
					>
						{orderBy === 'ShippingAmount' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ShippingAmount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{shipping}
					</a>
				</div>
				<div className="table_col">

					<a className="table_sort"
						onClick={() => {
							orderBy === 'ProductQuantitySum' && orderByDesc === 'desc' ? handleSort('ProductQuantitySum', 'asc') :
								handleSort('ProductQuantitySum', 'desc');
						}}
					>
						{orderBy === 'ProductQuantitySum' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ProductQuantitySum' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{quantity}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'EventDate' && orderByDesc === 'desc' ? handleSort('EventDate', 'asc') :
								handleSort('EventDate', 'desc');
						}}
					>
						{orderBy === 'EventDate' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'EventDate' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{date}
					</a>
				</div>
			</div>
			<div>
				{dataSumsMetrics && getSum()
				}
				{data && data.map((elem) => {
					const name = elem?.dimensionLabel || elem?.dimensionId;
					const id = elem?.dimensionId;
					const metrics = elem.metrics;
					const Revenue = metrics.find(el => el.metric === 'Revenue');
					const revenueAverage = parseFloat( (Revenue?.value / Revenue?.total ) * 100).toFixed(2);
					const TaxAmount = metrics.find(el => el.metric === 'TaxAmount');
					const ShippingAmount = metrics.find(el => el.metric === 'ShippingAmount');
					const Quantity = metrics.find(el => el.metric === 'ProductQuantitySum');
					const EventDate = metrics.find(el => el.metric === 'EventDate');
					return (
						<div className="table_row table_body_row"
							key={uuidv4()}
						>
							<AnalitycsBoardItemEcommerce
								data={name}
								isHandleClick={false}
							/>
							<AnalitycsBoardItemEcommerce
								data={parseFloat(Revenue?.value).toFixed(2)}
								overhead={revenueAverage}
								isHandleClick={false}
								needDevise={true}
								nowrap={true}
							/>
							<AnalitycsBoardItemEcommerce
								data={TaxAmount?.value}
								isHandleClick={false}
								needDevise={true}
							/>
							<AnalitycsBoardItemEcommerce
								data={ShippingAmount?.value}
								isHandleClick={false}
								needDevise={true}
							/>
							<AnalitycsBoardItemEcommerce
								data={Quantity?.value}
								isHandleClick={false}
							/>
							<AnalitycsBoardItemEcommerce
								data={moment(EventDate?.value).format('YYYY/MM/DD')}
								isHandleClick={false}
							/>
						</div>
					);
				}
				)}
			</div>
		</div>
	);
}
