import HttpServices from './HttpServices';

export default class PersonaServices {
	constructor($http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
	}

	getPersonasList(accountId, contextIdentifier, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}triggergroup/${accountId}?contextIdentifier=${contextIdentifier}`, callbackSuccess, callbackError);
	}

	createNewPersona(accountId, persona, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}triggergroup/${accountId}`, persona, callbackSuccess, callbackError);
	}

	editPersona(accountId, persona, groupId, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}triggergroup/${accountId}/${groupId}`, persona, callbackSuccess, callbackError);
	}

	deletePersona(accountId, groupId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}triggergroup/${accountId}/${groupId}`, callbackSuccess, callbackError);
	}

}