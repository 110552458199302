import HttpServices from './HttpServices';

export default class ProductTagServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
	}

	// https://backoffice-apiv2-beyable-dev.azurewebsites.net/product/tag/{accountId}
	getProductTag(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}product/tag/${accountId}`, callbackSuccess, callbackError);
	}
	getProductReferencesByTag(accountId, tag, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}product/tag/${accountId}/${tag}`, callbackSuccess, callbackError);
	}
	putProductReferencesByTag(accountId, tag, productRef, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}product/tag/${accountId}/${tag}`, productRef, callbackSuccess, callbackError);
	}
	deleteTag(accountId, tag, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}product/tag/${accountId}/${tag}`, callbackSuccess, callbackError);
	}
	getProductFeedTags(userId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}/productfeed/${userId}/tag`, callbackSuccess, callbackError);
	}

}
