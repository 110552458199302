import React, {useEffect, useState} from 'react';
import Btn from '../../../../Components/Btn';
import SearchBar from '../../../../Components/SearchBar';
import SelectDropdown from '../../../../Components/SelectDropdown';
import {useInteractionContext} from '../../context/InteractionContext';
import styles from '../../WebAnalyticsContainer.module.css';

export default function InteractionSearchFilter() {
	const [valueOnSearchBar, setValueOnSearchBar] = useState('');
	const {
		handleSearchValue,
		handleFilter,
		filterExactValue,
		handleSearchValueFilter2,
		step,
		reportTypeSelected,
		deviceSelected,
		excludeFilter,
		handleChangeExcludeFilter
	} = useInteractionContext();

	const [placeHolderSearchBar, setPlaceHolderSearchBar] = useState();
	const [firstRender, setfirstRender] = useState(true);
	const [isMaximized, setIsMaximized] = useState(valueOnSearchBar.length > 0);

	useEffect(()=>{
		setfirstRender(false);
	},[]);
	useEffect(() => {
		if (reportTypeSelected === 'Interaction') {
			setPlaceHolderSearchBar(step === 1 ? 'Search for a category'
				: step === 2 ? 'Search for an interaction' : 'Search for a page');
		} else if (reportTypeSelected === 'Page') {
			setPlaceHolderSearchBar(step === 1 ? 'Search for a page' : 'Search for an interaction');
		}
		setValueOnSearchBar('');
	}, [reportTypeSelected, step]);


	// send reset request when input is empty 
	useEffect(() => {
		if(!firstRender){
			if (filterExactValue && valueOnSearchBar === '') {
				step === 3 ? handleSearchValueFilter2(valueOnSearchBar) : handleSearchValue(valueOnSearchBar);
			}
		}
	}, [valueOnSearchBar]);


	useEffect(() => {
		if (valueOnSearchBar && valueOnSearchBar > 0 && !filterExactValue) {
			setValueOnSearchBar('');
		}
		
	}, [filterExactValue]);

	const handleClick = (value) => {
		if (value && value.length > 0) {
			if (reportTypeSelected === 'Page') {
				if (step === 1) {
					handleSearchValue(value);
				} else handleSearchValueFilter2(value);
			}
			else if (reportTypeSelected === 'Interaction') {
				step === 3 ? handleSearchValueFilter2(value) : handleSearchValue(value);
			}
		} else {
			handleFilter(null);
		}
	};
	return (
		<div className="ml_20">
			<div className={styles.mainSearchBar}>
				<div className={styles.mainSearchBarInput}>
					<SearchBar
						onChangeValue={(e) => setValueOnSearchBar(e.target.value)}
						valueInSearchBar={valueOnSearchBar}
						placeholder={placeHolderSearchBar}
						name="url"
						color="white"
						clearable={false}
						onFocus={() => setIsMaximized(true)}
					/>
				</div>
				{isMaximized &&
					<>
						<div className={styles.mainSearchBarBtn}>
							<SelectDropdown
								value={excludeFilter}
								optionsList={[
									{value: false, label: 'Include' },
									{value: true, label: 'Exclude' }
								]}
								onChange={(v) => handleChangeExcludeFilter(v)}
								color='white'
							/>
						</div>
						<div className={styles.mainSearchBarBtn}>
							<Btn
								onClick={() => handleClick(valueOnSearchBar)}
								color="secondary"
								style="outline"
								icon="fas fa-search"
							/>
						</div>
					</>
				}
			</div>
		</div>
	);
}
