
import React, { useState, useRef, useEffect } from 'react';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';
import Panel from '../../../Components/Panel';
import PanelArticle from '../../../Components/PanelArticle';
import SectionMessage from '../../../Components/SectionMessage';
import Link from '../../../Components/Link';
import Switch from '../../../Components/Switch';
import { ControlledEditor } from '@monaco-editor/react';
import optionsEditor from './optionsEditor';
import SelectCustom from '../../../Components/SelectCustom';
import VariablesAvailable from './VariablesAvailable';
import TypeAuthorization from './TypeAuthorization';
import { isAnUrl, validateConnector } from '../util';
import FieldRow from '../../../Components/FieldRow';
import useEventBroker from '../../../Hooks/useEventBroker';
import {useTranslation} from 'react-i18next';
const listVerb = [
	{
		value: 'Post',
		label: 'Post',
	},
	// {
	// 	value: 'Get',
	// 	label: 'Get',
	// },
	{
		value: 'Put',
		label: 'Put',
	},
	{
		value: 'Patch',
		label: 'Patch',
	},

];

export default function PanelHttpConnector() {
	const [t, i18n] = useTranslation('common');
	const [panelIsOpen, setPanelIsOpen] = useState(false);
	const [payload, setPayload] = useState('{\n\t"email": "{{Email}}",\n\t"name": "{{Name}}"\n}');
	const [verb, setverb] = useState('Post');
	const [type, setType] = useState('NoAuth');
	const [identifier, setIdentifier] = useState();
	const [hasErrorOnUrl, sethasErrorOnUrl] = useState(false);
	const [hasErrorOnInputs, setHasErrorOnInputs] = useState([]);
	const [switchValue, setswitchValue] = useState(false);
	const [clipBoardContain, setClipBoardContain] = useState(false);
	const valueGetter = useRef();

	const [httpConnector, setHttpConnector] = useState();

	const { subscribe, publish } = useEventBroker();

	subscribe('evt/campaign/setHttpConnectorRequested', (e) => {
		setHttpConnector(e.detail || [{}]);
		setPanelIsOpen(true);
	});

	const handleChangeValueHttpConnector = (value) => {
		publish('evt/campaign/httpConnectorUpdated', value);
	};

	function handleEditorDidMount(event) {
		valueGetter.current = event;
	}
	const handleUpdate = (prevValue, newVal) => {
		setPayload(newVal);
	};

	const handleSelectVerb = (e) => {
		const val = e.target.value;
		setverb(val);
	};
	const handleSelectType = (e) => {
		const val = e.target.value;
		setType(val);
	};

	const handleChangeIdentifier = (e) => {
		const value = e.target.value;
		const name = e.target.name;

		setIdentifier(oldValue => ({
			...oldValue,
			[name]: value.trim()
		}));
	};
	const handleSwitch = (e) => {
		setswitchValue(!switchValue);
	};
	const handleValidateForm = () => {
		const newIdentifier = {
			...identifier,
			method: verb,
			authorizationType: type,
			content: payload,
			isActive: switchValue,
			identifier: 'httpConnector'
		};
		if (switchValue) {
			const hasErrorOnurl = !isAnUrl(identifier.url);
			const errorsOnForm = validateConnector(newIdentifier);
			const hasErrorsOnForm = errorsOnForm.length > 0;
	
			if (hasErrorOnurl) {
				sethasErrorOnUrl(true);
			}
			if (hasErrorsOnForm) {
				setHasErrorOnInputs(errorsOnForm);
			}
			if (!hasErrorOnurl && !hasErrorsOnForm) {
				sethasErrorOnUrl(false);
				handleChangeValueHttpConnector([newIdentifier]);
				setPanelIsOpen(false);
			}
		} else {
			sethasErrorOnUrl(false);
			handleChangeValueHttpConnector([newIdentifier]);
			setPanelIsOpen(false);
		}
	};

	const copyToClipBoard = (elementToCopy) => {
		navigator.clipboard.writeText(elementToCopy);
		setClipBoardContain(elementToCopy);
	};
	useEffect(() => {
		if (!httpConnector)
			return;
		const connector = httpConnector[0];
		setPayload(connector.content);
		setverb(connector.method);
		setType(connector.authorizationType);
		setswitchValue(connector.isActive);
		setIdentifier(connector);
		return () => {
			sethasErrorOnUrl(false);
			setClipBoardContain('');
			setHasErrorOnInputs([]);
		};
	}, [panelIsOpen]);

	return (
		<Panel
			isOpen={panelIsOpen}
			onClose={() => setPanelIsOpen(false)}
			noClose={true}
			side="right"
			width="730"
		>
			<div className="modal_header has_border flex">
				<div className='flex_item_fix'>
					<a className='panel_close panel_close_left' onClick={(e) => setPanelIsOpen(false)}></a>
				</div>
				<div className='flex_item_full'>
					{t('connector.title')}
				</div>
				<div className='flex_item_fix ml_30'>
					<Btn
						onClickFunction={(e) => handleValidateForm()}
						message="OK"
					/>
				</div>
			</div>
			<div className="modal_body modal_body_grey">

				<SectionMessage type="information" marginSize='l'>
				{t('connector.generated')}<br/>
				{t('connector.youCanExport')}<br/>
				{t('connector.better')}
				</SectionMessage>

				<Switch
					value={switchValue ? true : false}
					name="withcta"
					checked={switchValue ? true : false}
					onChange={(e) => handleSwitch(e)}
				>{t('connector.enable')}</Switch>

				<PanelArticle title="Request" hasMask={!switchValue}>
					<FieldRow label="Verb">
						<SelectCustom
							optionsList={listVerb}
							value={verb}
							onChange={handleSelectVerb}
							autoWidth={true}
							disabled={!switchValue}
						/>
					</FieldRow>
					<FieldRow label="URL">
						<InputCustom
							type="text"
							name='url'
							placeholder='https://'
							value={identifier && identifier.url}
							onChange={(e) => handleChangeIdentifier(e)}
							fullWidth={true}
							isAnError={hasErrorOnUrl ? t('connector.enterValidUrl') : ''}
							disabled={!switchValue}
						/>
					</FieldRow>
				</PanelArticle>

				<PanelArticle title="Authorization" hasMask={!switchValue}>
					<p>{t('connector.doesThis')}</p>
					<TypeAuthorization
						type={type}
						handleSelectType={handleSelectType}
						handleChangeIdentifier={handleChangeIdentifier}
						identifier={identifier}
						hasErrorOnInputs={hasErrorOnInputs}
						disabled={!switchValue}
					/>
				</PanelArticle>

				<PanelArticle title="Payload" hasMask={!switchValue}>
					<div className="mb_10">
						<p>{t('connector.thisField')} <Link target="_blank" href="https://shopify.github.io/liquid/">{t('connector.liquidSyntax')}</Link>.</p>
						<p><code>{'{{Email}}'}</code> {t('connector.and')} <code>{'{{Name}}'}</code>{t('connector.variablesWill')} </p>
					</div>
					<ControlledEditor
						height="160px"
						width="100%"
						theme="dark"
						language={'json'}
						loading={
							<>
								<span className="mask">
									<span className="wheel"></span>
								</span>
							</>
						}
						value={payload && payload}
						onChange={handleUpdate}
						editorDidMount={handleEditorDidMount}
						options={optionsEditor}
					/>
					<div className="mt_10 s_13  grey_3">
						<span className="mr_10">{t('connector.availableVar')}</span>
						<VariablesAvailable
							name={'{{Email}}'}
							elementToCopy='{{Email}}'
							copyToClipBoard={copyToClipBoard}
							clipBoardContain={clipBoardContain}
						/>
						<VariablesAvailable
							name={'{{Name}}'}
							elementToCopy='{{Name}}'
							clipBoardContain={clipBoardContain}
							copyToClipBoard={copyToClipBoard}
						/>
					</div>
				</PanelArticle>
			</div>
		</Panel>
	);
}
