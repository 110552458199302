import React, {useEffect, useState } from 'react';
import CheckBoxCard from './CheckBoxCard';
import './PlanSelection.css';


export default function PlanSelection({ state, setState, error, onContactClick }) {
	const [selectedPlans, setSelectedPlans] = useState(state.plans);
	const optionList = [
		{
			label: 'BEYABLE Analytics',
			desc: 'GDPR Friendly web analytics',
			name: 'analytics',
			icon: 'analytics'
		},
		{
			label: 'BEYABLE Ready',
			desc: 'Targeted messages to your audience',
			name: 'ready',
			icon: 'ready',
			disabled: true
		}, 
		{
			label: 'BEYABLE Scoring',
			desc: 'Products and visitors scoring',
			name: 'scoring',
			icon: 'scoring',
			disabled: true
		}
	];

	const changeHandler = (name) => {
		let plansCopy = [...selectedPlans];
		let index = plansCopy.indexOf(name);
		if(index === -1){
			plansCopy.push(name);
		}else{
			plansCopy.splice(index,1);
		}
		setSelectedPlans(plansCopy);
	};
	useEffect(()=>{
		onContactClick(false);
	},[]);

	useEffect(()=>{
		setState({...state, plans : selectedPlans});
	},[selectedPlans]);

	return (
		<div className="ob_slide">
			<legend className="ob_title_2">Which services do you want to activate?</legend>
			<p className="ob_text mb_30">You can choose several services, and find them within the same platform.</p>
			<p className="ob_error_form">{error.plans}</p>
			{
				optionList.map( 
					el => (
						<div className="mb_15" key={el.name}>
							<CheckBoxCard
								checked={selectedPlans.includes(el.name) ? true : false}
								change={changeHandler}
								{...el}
							/>
						</div>
					))
			}
			<p className="ob_plan_contact s_18 al_center mt_25">Something else? 
				<a className="ob_plan_contact_link fw_medium underline" onClick={()=>{onContactClick(true);}}>
          Contact us
				</a>
			</p>
		</div>
	);
}
