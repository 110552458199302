import React , {useState,useEffect} from 'react';
import {  getFeedTypePictoClass,typeConnection,isAnUrlValid} from '../utils';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
import InputCustom from '../../../Components/InputCustom';
import Btn from '../../../Components/Btn';
import SelectCustom from '../../../Components/SelectCustom';
import ConnectionSwitch from '../Edition/Connection/ConnectionSwitch';
import Checkbox from '../../../Components/Checkbox';
import SourceSettingsScreen from './SourceSettingsScreen';
import {FeedCardHeader} from './FeedCardHeader';
import SectionMessage from '../../../Components/SectionMessage';

export default function CreateCustom() {
	const {
		productFeedObject,
		isEmptyIdentifier,
		handleChangeSimpleField,
		setisEmptyIdentifier,
		handleChooseATypeSourceConnection,
		handleChangeConnexion,
		messageInfos,
		setmessageInfos,
	} = useProductFeedEditionContext();

	const [stateOfCreationCustom, setstateOfCreationCustom] = useState(1);
	const pictoClass = getFeedTypePictoClass(productFeedObject?.type);
	const [connexionField, setConnexionFields] = useState(<></>);
	function handleChangeField(e){
		const value = e.target.value;
		const name = e.target.name;
		handleChangeConnexion(name,value);
	}
	function handleChangeSimpleFieldFn(e){
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name,value);
	}
	function handleGoToConnexion(){
		if(!productFeedObject.hasOwnProperty('resourceIdentifier') || productFeedObject.resourceIdentifier === null || productFeedObject?.resourceIdentifier.trim() === ''  ){
			setmessageInfos({
				type : 'warning',
				title : 'URL field is mandatory',
				//text :'We need ressource identifier'
			});
			setisEmptyIdentifier(true);
			return ;
		}
		
		else{
			const urlIsValid = isAnUrlValid(productFeedObject?.resourceIdentifier.trim()) ;
			setisEmptyIdentifier(!urlIsValid);
			if(!urlIsValid){
				setmessageInfos({
					type : 'warning',
					title : 'Invalid url',
					text :'Please check the ressource identifier in invalid url, we accept the urls as https://mylink.com , ftp://myftp.com'
				});
				return ;
			}else{
				setstateOfCreationCustom(2);
			}
		}
	}
	function handleGoToType(){
		setstateOfCreationCustom(3);
	}
	function getFieldConnectionCustom(key){
		switch (key) {
			case 'LoginPassword':
				return <>
				
					<InputCustom
						value={productFeedObject.auth.login}
						type="text"
						label={'Login'}
						name={'login'}
						placeholder="Login"
						onChange={handleChangeField}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.auth.password}
						type={'password'}
						label={'Password'}
						name={'password'}
						placeholder="******"
						onChange={handleChangeField}
						fullWidth={true}
						size="l"
					/>		
				</>;
			case 'ApiKey':
				return <>
				
					<InputCustom
						value={productFeedObject.auth.apiKey}
						type={'password'}
						label={'Api key'}
						name={'apiKey'}
						placeholder="4585yw5-54523cx-2125kj"
						onChange={handleChangeField}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>		
				</>;
			case 'SasToken':
				return <>
				
					<InputCustom
						value={productFeedObject.auth?.sasToken}
						type={'password'}
						label={'Sas token'}
						name={'sasToken'}
						placeholder="4585yw5-54523cx-2125kj"
						onChange={handleChangeField}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>		
				</>;
			case 'AuthorizationHeader':
				return <>

					<InputCustom
						value={productFeedObject.auth.authorizationHeaderName}
						type={'text'}
						label={'Authorization header (leave empty for "Authorization")'}
						name={'authorizationHeaderName'}
						placeholder="Authorization"
						onChange={handleChangeField}
						fullWidth={true}
						autoFocus={false}
						size="l"
					/>			
					<InputCustom
						value={productFeedObject.auth.authorizationHeaderContent}
						type={'text'}
						label={'Authorization header value'}
						name={'authorizationHeaderContent'}
						placeholder="Basic ZGFmeS11YXQ6dWFnZWlaNXk=,Token 1CD70AD30C46DF21879FEDBEE"
						onChange={handleChangeField}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
				
				</>;
		}
	}


	useEffect(()=>{
		if(productFeedObject?.auth?.type){
			setConnexionFields(getFieldConnectionCustom(productFeedObject.auth.type));
		}
	},[productFeedObject]);
	return (
		<>
			{stateOfCreationCustom === 1 &&
				<>
					<FeedCardHeader title="Custom settings" />
					<InputCustom
						value={productFeedObject.connectorName}
						type={'text'}
						label={'Connector name'}
						name={'connectorName'}
						placeholder="Connector name"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						autoFocus={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.connectorVersion }
						type={'text'}
						label={'Version'}
						name={'connectorVersion'}
						placeholder="1.2"
						onChange={handleChangeSimpleFieldFn}
						fullWidth={true}
						size="l"
					/>
					<InputCustom
						value={productFeedObject.resourceIdentifier}
						type={'text'}
						label={'URL'}
						name={'resourceIdentifier'}
						placeholder="https://exemple.com/feed/"
						onChange={(e) => handleChangeSimpleFieldFn(e)}
						fullWidth={true}
						size="l"
					/>
					{isEmptyIdentifier && 
						<SectionMessage
							type={messageInfos.type}
							title={messageInfos.title}
							text={messageInfos.text}
							verticalSize="s"
						/>
					}
					<div className='mt_30 al_right'>
						<Btn
							onClickFunction={() => handleGoToConnexion()}
							size="l"
							message="Next"
							color="primary"
						/>
					</div>
				</>
			}
			{stateOfCreationCustom === 2 && 
				<>
					<FeedCardHeader title="Authentification type" />
					<SelectCustom
						label='Connection type'
						optionsList={typeConnection}
						defaultValueDisabled={{
							value: 'Choose',
							label: 'Choose a connection type',
							isDisabled: true
						}}
						value={productFeedObject?.auth?.type || 'Choose'}
						onChange={(e) => handleChooseATypeSourceConnection(e.target.value)}
						fullWidth={true}
						size="l"
					/>
					{connexionField}
					<div className='mt_30 al_right'>
						<Btn
							onClickFunction={() => handleGoToType()}
							size="l"
							message="Next"
							color="primary"
						/>
					</div>
				</>
			}
			{stateOfCreationCustom === 3 &&
				<SourceSettingsScreen/>
			}
		</>
	);
}
