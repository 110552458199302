import React from 'react';
import { CampaignsContext } from '../../context/index.js';
import { Section } from '../../../../Components/Section/Section';
import { Article } from '../../../../Components/Article/Article';

import SkuContainer from './SkuContainer';
import TemplateJson from './TemplateJson';
export default function InCollection() {

	const {
		getCurrentVariation,
	} = CampaignsContext();

	const activeVariation = getCurrentVariation() || {};

	if(!activeVariation || activeVariation.SlideFormat !== 'InCollection'){
		return null;
	}

	return (
		<>
			<Section width='s' hasMargin={true}>
				<Article title={'Identifiers of InCollection items to target'}>
					<SkuContainer/>
				</Article>
			</Section>
			<Section width='s' hasMargin={true}>
				<Article title={'Display components'}>
					<TemplateJson/>
				</Article>
			</Section>
		</>
	);
}
