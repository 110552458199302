import HttpServices from './HttpServices';
import axios from 'axios';

export default class BusinessOperationServices {
	constructor($http, authServices) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}

	}

	// PagesTypes
	getPagesTypes(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}websitesetup/${accountId}/screenshots/`, callbackSuccess, callbackError);
	}
	//library 
	postCreateLibrary(accountId) {
		return axios.post(`${this.BO_API_URL}businessOperation/${accountId}/library`, new FormData(),
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' },
		);
	}
	postTransformImageCloud(accountId, bannerToTransform, arrayOfEager) {
		return axios.post(`${this.BO_API_URL}imagecloud/${accountId}/transform`, {
			sourceId: bannerToTransform.sourceId,
			fileName: bannerToTransform.fileName,
			transformations: arrayOfEager
		},
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' },
		);
	}
	postAddCloudImagesToLibrary(accountId, libraryId, images) {
		return axios.post(`${this.BO_API_URL}businessOperation/${accountId}/library/${libraryId}/cloud`, images,
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' },
		);
	}
	postUploadToImageCloud(accountId, libraryId, provider, files, onUploadProgress) {
		return axios.post(`${this.BO_API_URL}imagecloud/${accountId}/${libraryId}/upload?provider=${provider}`, files,
			{
				onUploadProgress: onUploadProgress,
				headers: this.config.headers, 'Content-Type': 'multipart/form-data'
			},
		);
	}

	deleteLibrary(accountId, libraryId, banner, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}businessOperation/${accountId}/library/${libraryId}`, callbackSuccess, callbackError, banner, { 'Content-type': 'application/json' });
	}

	// Zones
	getZones(accountId, pageType, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}websitesetup/${accountId}/zones/?pageType=${pageType}`, callbackSuccess, callbackError);
	}
	//Operations 
	getAllOperations(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/operation`, callbackSuccess, callbackError);
	}
	getOperationsForZone(accountId, zoneId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/operation?zoneId=${zoneId}`, callbackSuccess, callbackError);
	}
	getOperationByRangeDate(accountId, startDate, endDate, loadStatistics, callbackSuccess, callbackError) {
		if (!loadStatistics) {
			this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/statistics/operations?startDate=${startDate}&endDate=${endDate}&loadStatistics=false`, callbackSuccess, callbackError);
		} else {
			this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/statistics/operations?startDate=${startDate}&endDate=${endDate}`, callbackSuccess, callbackError);
		}
	}
	getOperationStatsCsvByRangeDate(accountId, startDate, endDate, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/statistics/operations?startDate=${startDate}&endDate=${endDate}&timeGranularity=day`,
			callbackSuccess, callbackError, { Accept: 'text/csv' });
	}
	getOperationById(accountId, operationId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/operation/${operationId}`, callbackSuccess, callbackError);
	}
	postOperationById(accountId, operation, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}businessOperation/${accountId}/operation/`, operation, callbackSuccess, callbackError);
	}
	putOperationById(accountId, operationId, operation, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}businessOperation/${accountId}/operation/${operationId}`, operation, callbackSuccess, callbackError);
	}
	deleteOperationById(accountId, operationId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}businessOperation/${accountId}/operation/${operationId}`, callbackSuccess, callbackError);
	}
	putOperationStopped(accountId, operationId, isStopped, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}businessOperation/${accountId}/operation/${operationId}/isstopped/${isStopped}`, isStopped, callbackSuccess, callbackError);
	}

	// Statistics
	getStatisticsByOperationId(accountId, operationId, startDate, endDate, timegranularity, callbackSuccess, callbackError) {
		if (timegranularity) {
			this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/statistics/operation/${operationId}?startDate=${startDate}&endDate=${endDate}&timegranularity=${timegranularity}`, callbackSuccess, callbackError);
		} else {
			this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/statistics/operation/${operationId}?startDate=${startDate}&endDate=${endDate}`, callbackSuccess, callbackError);
		}
	}

	// Users : Get name and mail for navigation
	getUserById(ku, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.SERVER_URL}users/${ku}`, callbackSuccess, callbackError);
	}
	//  status mode testing
	putOperationExecutionStatusMode(accountId, operationId, executionMode, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}businessOperation/${accountId}/operation/${operationId}/executionmode/${executionMode}`, null, callbackSuccess, callbackError);
	}
	putOperationExecutionStatusModeWithTest(accountId, operationId, isStopped, executionMode, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}businessOperation/${accountId}/operation/${operationId}/isStopped/${isStopped}/executionMode/${executionMode}`, null, callbackSuccess, callbackError);
	}
	postTransformationsbanner(accountId, banner, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}imagecloud/${accountId}/transform`, banner, callbackSuccess, callbackError);
	}
	postTransformationsbannerToLibrary(accountId, libraryId, banner, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}businessOperation/${accountId}/library/${libraryId}/cloud`, banner, callbackSuccess, callbackError);
	}
	getbannersLibrary(accountId, libraryId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/library/${libraryId}`, callbackSuccess, callbackError);
	}
	postBannerLibrary(accountId, libraryId, banner, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}businessOperation/${accountId}/library/${libraryId}/banner`, banner, callbackSuccess, callbackError);
	}

	// Capture
	executeStartCapture(accountId, operationId, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}businessWorkflow/${accountId}`, { identifier: 'simplify-operation-screencapture', extensions: { OperationId: operationId } }, callbackSuccess, callbackError);
	}
	// Workflow
	getBannerResizeWorflows(accountId, libraryId) {
		return axios.get(`${this.BO_API_URL}businessOperation/${accountId}/resizeBannerWorkflow/library/${libraryId}`, {
			headers: this.config.headers
		});
	}
	createBannerResizeWorflow(accountId, libraryId, files, onUploadProgress) {
		return axios.post(`${this.BO_API_URL}businessOperation/${accountId}/resizeBannerWorkflow/library/${libraryId}`, files,
			{
				onUploadProgress: onUploadProgress,
				headers: this.config.headers, 'Content-Type': 'multipart/form-data'
			});
	}
	cancelBannerResizeWorflow(accountId, libraryId, workflowInstanceId) {
		return axios.delete(`${this.BO_API_URL}businessOperation/${accountId}/resizeBannerWorkflow/library/${libraryId}/instance/${workflowInstanceId}`,
			{
				headers: this.config.headers
			});
	}

	// orchestration
	getOrchestrationForZone(accountId, zoneId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}businessOperation/${accountId}/orchestration/${zoneId}`, callbackSuccess, callbackError);
	}
	createOrUpdateOrchestrationForZone(accountId, zoneId, items, callbackSuccess, callbackConflict, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}businessOperation/${accountId}/orchestration/${zoneId}`, items, callbackSuccess, (e, r) => {
			if (r.status === 409) {
				callbackConflict(e, r);
			} else {
				callbackError(e, r);
			}
		});
	}

	recomputeOrchestration(accountId, operationId, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}businessOperation/${accountId}/orchestration/recompute`, { operationId }, callbackSuccess, callbackError);
	}

	recomputeAllOrchestrations(accountId, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}businessOperation/${accountId}/orchestration/recompute`, { }, callbackSuccess, callbackError);
	}
}
