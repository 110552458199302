import React from 'react';
import Modal from '../../../../Components/Modal';
import { CampaignsContext } from '../../context';
// import ImagesAccount from "../../../ImageGallery/ImagesAccount"
import ImagesAccount from '../../../ImageGallery/ImagesAccount.js';
export default function GalleryImgModal(props) {
	const {
		modalUploadPicture,
		setModalUploadPicture,
		accountId,
		authServices,
	} = CampaignsContext();

	return (
		<div>
			{' '}
			<Modal
				isOpen={modalUploadPicture}
				onClose={(e) => setModalUploadPicture(false)}
				width={1000}
			>
				<ImagesAccount
					accountId={accountId}
					AuthServices={authServices}
				/>
			</Modal>
		</div>
	);
}
