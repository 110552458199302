import React, { useEffect, useState } from 'react';
import PatchIdeEditor from './PatchIdeEditor';
import PatchIdeEditorMobile from './PatchIdeEditorMobile';
import { CampaignsContext } from '../../context';
import GalleryImgModal from './GalleryImgModal';
import Link from '../../../../Components/Link';
export default function PatchEditorIde() {
	const [toggleState, setToggleState] = useState(3);

	const seeCSS = () => {
		setToggleState(2);
	};
	const seeJS = () => {
		setToggleState(1);
	};
	const seeDisplayCondition = () => {
		setToggleState(4);
	};
	const seeHtml = () => {
		setToggleState(3);
	};

	const [jsMobileValue, setJsMobileValue] = useState('');
	const [jsDesktopValue, setJsDesktopValue] = useState('');
	const [cssMobileValue, setCssMobileValue] = useState('');
	const [cssDesktopValue, setCssDesktopValue] = useState('');
	const [resetCssMobileValue, setResetCssMobileValue] = useState('');
	const [resetJsMobileValue, setResetJsMobileValue] = useState('');
	const [htmlDektopValue, setHtmlDektopValue] = useState('');
	const [htmlMobileValue, setHtmlMobileValue] = useState('');
	const [resetHtmlMobileValue, setResetHtmlMobileValue] = useState('');
	const [displayConditionsDesktop, setDisplayConditionsDesktop] = useState('');
	const [displayConditionsMobile, setDisplayConditionsMobile] = useState('');
	const {
		step,
		getCurrentVariation,
		currentDevice,
		setCurrentDevice,
		handleStyleSelected,
		handleStyleChosen,
		handleUpdateCss,
		handleUpdateJs,
		handleUpdateHtml,
		handleResetResponsive,
		handleUpdateCssResponsive,
		handleUpdateJsResponsive,
		handleUpdateHtmlResponsive,
		handleUpdateDisplayConditionResponsive,
		handleUpdateDesktopDisplayConditions,
		toggleResponsiveValue,
		isSaving,
		variations,
		currentVariationId
	} = CampaignsContext();
	const activeVariation = getCurrentVariation() || {};

	useEffect(() => {
		handleStyleSelected(
			currentDevice === 'desktop' || toggleResponsiveValue
				? cssDesktopValue
				: cssMobileValue
		);
		handleStyleChosen();
	}, [toggleState]);

	// init reset responsive
	useEffect(() => {
		if (
			!activeVariation ||
			activeVariation === {} ||
			!activeVariation.TemplateDescriptorMobile || toggleResponsiveValue
		)
			return;
		let mobile = activeVariation.TemplateDescriptorMobile;
		if (!resetCssMobileValue && mobile.EditableCss)
			setResetCssMobileValue(mobile.EditableCss);

		if (!resetJsMobileValue && mobile.EditableJavascript)
			setResetJsMobileValue(mobile.EditableJavascript);

		if (!resetHtmlMobileValue && mobile.EditableTemplate)
			setResetHtmlMobileValue(mobile.EditableTemplate.Content);
	}, [activeVariation]);


	useEffect(() => {
		if (!currentVariationId) return;
		if (variations.length < 2) return;
		if (currentVariationId) {
			let current = variations.filter(variation => variation.UId === currentVariationId)[0];
			if (!current) return;
			if (current.TemplateDescriptorMobile && current.TemplateDescriptorMobile.EditableCss) {
				let mobileCss = current.TemplateDescriptorMobile.EditableCss;
				setCssMobileValue(mobileCss);
				setResetCssMobileValue(mobileCss);
			}
			if (current.TemplateDescriptorMobile.EditableJavascript) {
				let mobileJs = current.TemplateDescriptorMobile.EditableJavascript;
				setJsMobileValue(mobileJs);
				setResetJsMobileValue(mobileJs);
			}
			if (current.TemplateDescriptorMobile.EditableTemplate) {
				let mobileHtml = current.TemplateDescriptorMobile.EditableTemplate.Content;
				setHtmlMobileValue(mobileHtml);
				setResetHtmlMobileValue(mobileHtml);
			}
			let mobileDisplayConditions = current.TemplateDescriptorMobile.EditableDisplayCondition;
			if(mobileDisplayConditions){
				setDisplayConditionsMobile((mobileDisplayConditions));
			}
			let desktopCss = current.TemplateDescriptorDesktop.EditableCss;
			let desktopJs = current.TemplateDescriptorDesktop.EditableJavascript;
			let desktopHtml = current.TemplateDescriptorDesktop.EditableTemplate.Content;
			let desktopDisplayConditions = current.TemplateDescriptorDesktop.EditableDisplayCondition;
			if(desktopDisplayConditions){
				setDisplayConditionsDesktop(desktopDisplayConditions);
			}
			setCssDesktopValue(desktopCss);
			setJsDesktopValue(desktopJs);
			setHtmlDektopValue(desktopHtml);
		}
	}, [currentVariationId]);

	// apply reset responsive
	useEffect(() => {
		if (!activeVariation) return;

		if (toggleResponsiveValue === false) {
			if (resetCssMobileValue && resetCssMobileValue !== cssMobileValue) {
				setCssMobileValue(resetCssMobileValue);
			}
			if (resetJsMobileValue && resetJsMobileValue !== jsMobileValue) {
				setJsMobileValue(resetJsMobileValue);
			}
			if(resetHtmlMobileValue && resetHtmlMobileValue !== htmlMobileValue ){
				setHtmlMobileValue(resetHtmlMobileValue);
			}
			handleResetResponsive(resetCssMobileValue, resetJsMobileValue,resetHtmlMobileValue);
		}
	}, [toggleResponsiveValue]);

	if (step !== 1) return <></>;

	return (
		<>
			<GalleryImgModal />
			<div className="patch_editor flex flex_v">
				<div className="patch_editor_head flex_item_fix flex">
					<ul className="patch_editor_tabs flex flex_item_full">
						<li>
							<a
								className={
									toggleState === 3
										? 'is_active is_html'
										: 'is_html'
								}
								onClick={seeHtml}
							>
								HTML
							</a>
						</li>
						<li>
							<a
								className={
									toggleState === 2
										? 'is_active is_css'
										: 'is_css'
								}
								onClick={seeCSS}
							>
								CSS
							</a>
						</li>
						<li>
							<a
								className={
									toggleState === 1
										? 'is_active is_js'
										: 'is_js'
								}
								onClick={seeJS}
							>
								JS
							</a>
						</li><li>
							<a
								className={
									toggleState === 4
										? 'is_active is_js'
										: 'is_js'
								}
								onClick={seeDisplayCondition}
							>
								Display condition (JS)
							</a>
						</li>
					</ul>
					<Link
						message='Documentation'
						icon='fas fa-external-link-square-alt'
						iconPosition='after'
						href='https://black-tree-0130e8b03.4.azurestaticapps.net/'
						target='_blank'
						rel="noreferrer"
						color='white'
					/>
				</div>
				<div className="patch_editor_body flex_item_full">
					{currentDevice === 'desktop' ||
						toggleResponsiveValue ? (
							<>
								{toggleState === 1 && (
									<PatchIdeEditor
										title="JAVASCRIPT"
										language="javascript"
										screenEnvironment="desktop"
										changeValue={(prevValue, e) => {
											if (toggleResponsiveValue) {

												handleUpdateJsResponsive(e);
											} else {
												setJsDesktopValue(e);
												handleUpdateJs(e);
											}
										}}
										value={
											(activeVariation.TemplateDescriptorDesktop &&
											activeVariation
												.TemplateDescriptorDesktop
												.EditableJavascript) ||
										''
										}
									/>
								)}
								{toggleState === 2 && (
									<PatchIdeEditor
										title="CSS"
										language="css"
										screenEnvironment="desktop"
										changeValue={(prevValue, e) => {
											if (toggleResponsiveValue) {

												handleUpdateCssResponsive(e);
											} else {
												setCssDesktopValue(e);
												handleUpdateCss(e);
											}
										}}
										value={
											(activeVariation.TemplateDescriptorDesktop &&
											activeVariation
												.TemplateDescriptorDesktop
												.EditableCss) || ''
										}
									/>
								)}
								{toggleState === 3 && (
									<PatchIdeEditor
										title="HTML"
										language="html"
										screenEnvironment="desktop"
										changeValue={(prevValue, e) => {
											if (toggleResponsiveValue) {
												handleUpdateHtmlResponsive(e);
											} else {
												setHtmlDektopValue(e);
												handleUpdateHtml(e);
											}
										}}
										value={
											(activeVariation.TemplateDescriptorDesktop &&
											activeVariation.TemplateDescriptorDesktop.EditableTemplate?.Content) || ''
										}
									/>
								)}
								{toggleState === 4 && (
									<PatchIdeEditor
										title="DISPLAY"
										language="javascript"
										screenEnvironment="desktop"
										changeValue={(prevValue, e) => {
											if (toggleResponsiveValue) {
												handleUpdateDisplayConditionResponsive(e);
											} else {
												setDisplayConditionsDesktop(e);
												handleUpdateDesktopDisplayConditions(e);
											}
										}}
										value={
											(activeVariation.TemplateDescriptorDesktop.EditableDisplayCondition &&
											activeVariation.TemplateDescriptorDesktop.EditableDisplayCondition) || ''
										}
									/>
								)}
							</>
						) : (
							<>
								{toggleState === 1 && (
									<PatchIdeEditor
										title="JAVASCRIPT"
										language="javascript"
										screenEnvironment="mobile"
										changeValue={(prevValue, e) => {
											setJsMobileValue(e);
											setResetJsMobileValue(e);
											handleUpdateJs(e);
										}}
										value={
											(activeVariation.TemplateDescriptorDesktop &&
												activeVariation
													.TemplateDescriptorMobile
													.EditableJavascript) ||
											''
										}
									/>
								)}
								{toggleState === 2 && (
									<PatchIdeEditor
										title="CSS"
										language="css"
										screenEnvironment="mobile"
										changeValue={(prevValue, e) => {
											setCssMobileValue(e);
											setResetCssMobileValue(e);
											handleUpdateCss(e);
										}}
										value={
											(activeVariation.TemplateDescriptorDesktop &&
											activeVariation
												.TemplateDescriptorMobile
												.EditableCss) || ''
										}
									/>
								)}
								{toggleState === 3 && (
									<PatchIdeEditor
										title="HTML"
										language="html"
										screenEnvironment="mobile"
										changeValue={(prevValue, e) => {
											setHtmlMobileValue(e);
											handleUpdateHtml(e);
											setResetHtmlMobileValue(e);
										}}
										value={
											(activeVariation.TemplateDescriptorDesktop &&
											activeVariation
												.TemplateDescriptorMobile
												.EditableTemplate.Content) || ''
										}
									/>
								)}
								{toggleState === 4 && (
									<PatchIdeEditor
										title="DISPLAY"
										language="javascript"
										screenEnvironment="mobile"
										changeValue={(prevValue, e) => {
											setDisplayConditionsMobile(e);
											handleUpdateDesktopDisplayConditions(e);
											setResetHtmlMobileValue(e);
										}}
										value={
											(activeVariation.TemplateDescriptorMobile.EditableDisplayCondition &&
											activeVariation.TemplateDescriptorMobile.EditableDisplayCondition) || ''
										}
									/>
								)}
							</>
						)}
				</div>
			</div>
			{isSaving && (
				<div className="mask">
					<div className="wheel size_32"></div>
				</div>
			)}
		</>
	);
}
