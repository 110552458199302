const icon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" viewBox="0 0 40 30">
  <defs>
    <clipPath id="clip-ico_block">
      <rect width="40" height="30"/>
    </clipPath>
  </defs>
  <g id="ico_block" clip-path="url(#clip-ico_block)">
    <g id="Rectangle_1169" data-name="Rectangle 1169" transform="translate(7 2)" fill="#e9eaf0" stroke="#acafc0" stroke-width="2">
      <rect width="26" height="26" rx="2" stroke="none"/>
      <rect x="1" y="1" width="24" height="24" rx="1" fill="none"/>
    </g>
  </g>
</svg>
`;

const styles = `
@media (max-width: 576px) {
	.BY_BLD .by_mobile_vertical {
		flex-direction: column;
	}
	.BY_BLD .by_mobile_vertical.by_mobile_reverse {
		flex-direction: column-reverse;
	}
	.BY_BLD .by_mobile_vertical > div {
		width: 100% !important;
	}
}
`;

const html = `
	<div data-gjs-type="column"></div>
`;

export const componentBlock = {
	model: {
		init() {
			this.udpateColumns();
			this.em.on('change:device', (context, device) => {
				this.udpateColumns();
			});

			let maxCols = 12;
			let availableCols = maxCols;
			const models = this.attributes.components.models;
			const colClass = maxCols / models.length;
			models.forEach(model => {
				const col = model.getAttributes()['data-by-col'] || 0;
				availableCols -= col;
			});
			if (availableCols != 0) {
				models.forEach(model => {
					model.addAttributes({'data-by-col': colClass});
				});
			}
		},
		updated(type) {
			if (type == 'components') {
				this.udpateColumns();
			}
			if (type == 'mobileVertical') {
				this.udpateColumns();
			}
		},
		defaults: {
			name: 'Row',
			droppable: false,
			editable: true,
			layerable: true,
			resizable: {
				cl: false,
				cr: false,
				bl: false,
				br: false,
				bc: true,
				tl: false,
				tr: false,
				tc: true,
				step: 1,
				keyHeight: 'min-height'
			},
			traits: [
				{
					type: 'columns_count',
					name: 'columnsCount',
					label: 'Column count',
					tab: 'Columns'
				},
				{
					type: 'responsive',
					name: 'responsive',
					changeProp: true,
					tab: 'responsive'
				},
				{
					type: 'block_responsive',
					name: 'block_responsive',
					changeProp: true,
					tab: 'responsive'
				}
			],
			columnsCount: 1,
			mobileVertical: true,
			mobileReverse: false,
			tagName: 'div',
			components: html,
			stylable: ['background-color', 'background-image', 'padding', 'border', 'border-radius', 'min-height', 'box-shadow'],
			style: {
				'display': 'flex',
				'min-height': '30px',
				'background-repeat': 'no-repeat',
				'background-position': 'center',
				'background-size': 'cover',
			},
			styles: styles,
			'script-props': [

			],
			icon: icon
		},
		udpateColumns() {
			const models = this.attributes.components.models;
			const modelsLength = models.length;
			if (modelsLength == 0) return;

			const isMobile = this.em.attributes.device == 'mobile';
			const mobileVertical = this.get('mobileVertical');
			const canNotResize = isMobile && mobileVertical;

			for (let i = 0; i < modelsLength; i++) {
				const model = models[i];
				const currentValue = { ...model.attributes.resizable };
				const isFirst = i == 0;
				const isLast = i == modelsLength - 1;
				currentValue.cr = (canNotResize || isLast) ? false : true;
				currentValue.cl = (canNotResize || isFirst) ? false : true;
				model.set('resizable', currentValue);
			}
		}
	}
};
