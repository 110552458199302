import React, { useState, useEffect } from 'react';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';
import Modal from '../../../Components/Modal';

export default function RenamePersonaModal({
    modalIsOpen,
    setModalIsOpen,
    label,
    handleRenameSelectedPersona,
	handleSaveRenamePersona
}) {
    const [previousLabel, setPreviousLabel] = useState('')

	useEffect(() => {
		setPreviousLabel(label)
	}, [])

	const cancelAndClose = () => {
		handleRenameSelectedPersona(previousLabel); 
		setModalIsOpen(false)
	}

	const save = () => {
		if (label !== previousLabel) {
			handleSaveRenamePersona()
		}
		setModalIsOpen(false)
	}

    return(
        <Modal
            isOpen={modalIsOpen}
            width="440"
            onClose={cancelAndClose}
        >
            <div className="modal_header has_border">
                Rename this persona 
            </div>
            <div className="modal_body">
                <InputCustom
                    label="Name"
                    type="text"
                    value={label}
                    onChange={(e) => handleRenameSelectedPersona(e.target.value)}
                    autoFocus={true}
                    fullWidth={true}
                />
            </div>
            <div className="modal_footer al_right">
                <Btn
                    onClickFunction={cancelAndClose}
                    message="Cancel"
                    style="ghost"
                    color="secondary"
                />
                <Btn
                    onClickFunction={save}
                    message="OK"
                />
            </div>
        </Modal>
    )
}