import React, { useState, useEffect, useRef, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Dropdown from '../../../../Components/Dropdown';
import Fieldset from '../../../../Components/Fieldset';

export default function AutocompleteData({ 
	setupAccountServices, 
	currentAccount, 
	referenceDataType, 
	value, 
	onChange, 
	selectedTenant, 
	autoCompletePlaceholder,
}) {

	const refInput = useRef();
	const idDropDown = uuidv4();
	const [list, setList] = useState([]);

	const [filteredSuggestions, setfilteredSuggestions] = useState([]);
	const [activeSuggestion, setactiveSuggestions] = useState();
	const [showSuggestions, setshowSuggestions] = useState();
	const [userInput, setuserInput] = useState('');
	const [valueTagSearch, setvalueTagSearch] = useState('');
	const [focused, setFocused] = useState(false);
	const [autocompleteValue, setAutocompleteValue] = useState();

	const [inputValue, setInputValue] = useState();
	const [selectedValues, setSelectedValues] = useState([]);
	const i18nL= localStorage.getItem('i18nL');
	const txtFr = 'Chercher une valeur';
	const txtEng = 'Search a value';
	const txt = i18nL && i18nL.includes('fr') ? txtFr : txtEng;
	const actualAutoCompletePlaceholder = autoCompletePlaceholder || (txt);

	function sortArray(a, b) {
		if (a < b) return -1;
		if (a > b) return 1;
		return 0;
	}
	const getListOfSpecificities = (contextualData) => {
		setupAccountServices.getAccountReferenceData(currentAccount,
			contextualData,
			autocompleteValue,
			selectedTenant,
			newData => {
				const data = [...newData];

				if(data.length > 0){
					const sortData = data.sort(sortArray);
					filterSuggestions(autocompleteValue, sortData);
					setList(sortData);
				}else{
					filterSuggestions(autocompleteValue, []);
					setList([]);
				}
			});
	};


	useEffect(() => {
		// fetch data for suggestions
		if (autocompleteValue !== undefined) {
			getListOfSpecificities(referenceDataType);
		}
	}, [autocompleteValue]);



	const handleAutocompleteChange = (value) => {
		setAutocompleteValue(value);
		setuserInput(value);
	};

	const filterSuggestions = (search, data) => {
		// Filter our suggestions that don't contain the user's input
		let newfilteredSuggestions = data.filter(
			suggestion =>
				suggestion.toLowerCase().indexOf(search) > -1
		);
		if (newfilteredSuggestions.length === 0) {

			newfilteredSuggestions = [search];
		} else {
			newfilteredSuggestions = [search, ...newfilteredSuggestions];
		}
		setactiveSuggestions(0);
		setfilteredSuggestions(newfilteredSuggestions);
		setshowSuggestions(true);
	};


	const handleOnKeyDown = (e, val) => {
		// User pressed the enter key
		if (e.keyCode === 13 && val.length !== 0) {
			if (activeSuggestion && filteredSuggestions[activeSuggestion]) {
				addItemToValue(filteredSuggestions[activeSuggestion]);
				return;
			}

			addItemToValue(e.target.value);
			return;
		}
		// User pressed the up arrow
		else if (e.keyCode === 38) {
			if (activeSuggestion === 0) {
				return;
			}
			setactiveSuggestions(activeSuggestion - 1);
		}
		// User pressed the down arrow
		else if (e.keyCode === 40) {
			if (activeSuggestion - 1 === filteredSuggestions.length) {
				return;
			}
			setactiveSuggestions(activeSuggestion + 1);
		}
	};

	const addItemToValue = (item) => {
		handleChange(item);
		resetAutocomplete();
	};

	const resetAutocomplete = () => {
		setactiveSuggestions(0);
		setshowSuggestions(false);
		setuserInput('');
		setAutocompleteValue('');
		setvalueTagSearch('');
	};

	const take = (list, size) => {
		return list.slice(0, size);
	};

	const showDropDown = showSuggestions && userInput || showSuggestions && valueTagSearch;

	function handleClickOutside(event) {
		if (!event) {
			return;
		}
		if (event.target && event.target.id && event.target.id === idDropDown) {
			return;
		}
		if (refInput.current && !refInput.current.contains(event.target)) {
			event.stopPropagation();
			setFocused(false);
			resetAutocomplete();
		}
	}

	useEffect(() => {
		if (focused && refInput) {
			document.addEventListener('click', handleClickOutside);

			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		}
	}, [refInput, focused]);

	useEffect(() => {
		setInputValue(value.replace(/;/g, '\n'));
		setSelectedValues(value ? value.split(/[;,\n]/) : []);
	}, []);

	const handleChange = (value) => {
		onChange && onChange(value);
		setInputValue(value);
		setSelectedValues(value ? value.split(/[;,\n]/) : []);
	};

	return (
		<Fieldset label="Value">
			<div className="custom_input w_full">
				{inputValue && 
					<span className='field_value'>
						{inputValue}
						<span className='field_value_remove' onClick={() => handleChange('')}>
							<i className='fas fa-times'></i>
						</span>
					</span>
				}
				{!inputValue && 
					<>
						<i className="fas fa-search custom_input_icon_before"></i>
						<input
							ref={refInput}
							autoComplete={'off'}
							placeholder={actualAutoCompletePlaceholder}
							onFocus={() => setFocused(true)}
							type="text"
							value={userInput}
							onChange={e => handleAutocompleteChange(e.target.value)}
							onKeyDown={(e) => handleOnKeyDown(e, userInput)}
							name="userValue"
						/>
					</>
				}
			</div>

			{showDropDown &&
				<Dropdown isOpen={true} buttonRef={refInput}>
					<ul className="listbox">
						{filteredSuggestions.length ?
							<>
								{take(filteredSuggestions, 20).map((suggestion, index) => {
									let className = ['listbox_item'];
									// Flag the active suggestion with a class
									if (index === activeSuggestion) {
										className.push('selected');
									}
									return (
										<li key={suggestion}>
											<a className={className.join(' ')} onClick={() => addItemToValue(suggestion)}>
												<i className="fas fa-plus listbox_item_icon"></i>
												{suggestion}
											</a>
										</li>
									);
								})}
							</>
							:
							<li>
								<a className="listbox_item disabled">
									This value is already selected
								</a>
							</li>
						}
					</ul>
				</Dropdown>
			}
		</Fieldset>
	);
}
