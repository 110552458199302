import React, { Fragment } from "react";
import InputCustom from "../../../../Components/InputCustom";
import Radio from '../../../../Components/Radio';
import "./AboutYourself.css";

export default function AboutYourself({ state, setState, error }) {

  const CompanySizeChoice = (value, label) => (
    <Radio
      name="companySize"
      value={value}
      checked={value === state.additionalInfo.companySize}
      size="l"
      onChange={(e) =>
        setState({
          ...state,
          additionalInfo: {
            ...state.additionalInfo,
            companySize: e.target.value,
          },
        })}
    >
      {label || value}
    </Radio>
  );

  return (
    <div className="ob_slide">
      <legend>Almost Ready!</legend>
      <legend className="ob_title_2 mb_30">Before starting,<br /> tell us a bit about yourself (*optional)</legend>
      <div className="ob_slide_input mb_30">
        <InputCustom
          label="What’s your role?"
          placeHolder="CRO / CMO / Traffic Manager / Tech / Other"
          className="ob_text_input"
          fullWidth="true"
          type="text"
          labelClassName="s_17 mb_8"
          size="l"
          color="white"
          autoFocus={true}
          value={state.additionalInfo.accountCreatorRole}
          isAnError={error.accountCreatorRole}
          handleChangeForm={(e) =>
            setState({
              ...state,
              additionalInfo: {
                ...state.additionalInfo,
                accountCreatorRole: e.target.value,
              },
            })
          }
        />
      </div>
      <div className="ob_slide_radio flex flex_v s_15">
        <p className="ob_slide_radio_title mb_10">
          How big is your company
        </p>
        <span className="ob_error_form ml_5">{error.companySize}</span>
        {[
          { value: "Less than 10" },
          { value: "10 to 100" },
          { value: "100 to 1000" },
          { value: "More than 1000" },
        ].map((x, i) => <div key={i}>{CompanySizeChoice(x.value)}</div>)}
      </div>
    </div>
  );
}
