import React from 'react';
import { CategoryType } from '../../context/AiInsightsApiTypesServices';
import Link from '../../../../../Components/Link';
import {iconFunnel, iconAbandonedCart, iconBarCode } from '../../../../../Components/CustomIcon/CustomIcon.js';

import IconText from './IconText';

// CardHeader Component
interface CardHeaderProps {
  category: CategoryType;
}
const CARD_HEADER_CONFIGS = {
	'Product': {
		linkText: 'Tableau de bord Produits',
		title: 'Produits',
		icon: iconBarCode,
		linkUrl: '/Insight/ecommerce',
	},
	'AbandonedBasket': {
		linkText: 'Tableau de bord E-commerce',
		title: 'Paniers abandonnés',
		icon: iconAbandonedCart,
		linkUrl: '/Insight/ecommerce',
	},
	'Conversion': {
		linkText: 'Tableau de bord E-commerce',
		title: 'Conversions',
		icon: iconFunnel,
		linkUrl: '/Insight/ecommerce',
	},
};

const CardHeader: React.FC<CardHeaderProps> = ({ category }) => {
	const config = CARD_HEADER_CONFIGS[category];
	if (!config) return null;
	return (
		<div className="flex flex_justify_between mb_30">
			<IconText title={config.title} icon={config.icon} />
			<Link href={config.linkUrl} icon="fas fa-arrow-right" iconPosition="after" className="s_14">
				{config.linkText}
			</Link>
		</div>
	);
};

export default CardHeader;
