
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import OperationItems from './OperationItems';
import { CSVLink } from 'react-csv';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import MomentConstants from '../../../Constants/MomentConstants';
import Btn from './../../../Components/Btn';
import SearchBar from './../../../Components/SearchBar';
import Dropdown from './../../../Components/Dropdown';
import OperationLabelFilters from '../UI/OperationLabelFilters';
import bronze from '../../../Permissions/rules-bronze';
import gold from '../../../Permissions/rules-gold';
import silver from '../../../Permissions/rules-silver';
import { check } from '../../../Components/Can';
import './Dashboard.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import onsite_list_view_en from '../../../Translation/jsonfiles/en/onsite_list_view_en.json';
import onsite_list_view_fr from '../../../Translation/jsonfiles/fr/onsite_list_view_fr.json';
import RouterWrapper from '../RouterWrapper';

export default function DashboardList({ isAdmin, accountId, getAllOperations, getOperationByRangeDate, getOperationStatsCsvByRangeDate, putOperationStopped,
	deleteOperationById, putOperationExecutionStatusModeWithTest, routeParams, $rootScope,permissions, userSimplifyRole, executeStartCapture }) {
	const [planAccount, setPlanAccount] = useState(gold);
	const [momentStartDate, setMomentStartDate] = useState();
	const [momentEndDate, setMomentEndDate] = useState();
	const [operationsAccount, setOperationsAccount] = useState();
	const [noData, setNoData] = useState(false);
	const [reload, setReload] = useState(true);
	const [reloadTheOperationItem, setReloadTheOperationItem] = useState(false);
	const [noOperationOnAccount, setNoOperationOnAccount] = useState(false);
	const [arrayOfLabelSearchBar, setArrayOfLabelSearchBar] = useState([]);
	const [dataOfOperationToPut, setDataOfOperationToPut] = useState();
	const [actionsDropdownIsOpen, setActionsDropdownIsOpen] = useState(false);
	const [operationStatisticsData, setOperationStatisticsData] = useState([]);
	const [operationStatisticsFileName, setOperationStatisticsFileName] = useState('operations.csv');
	const csvLink = useRef();

	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [labelFilters, setLabelFilters] = useState([]);

	useEffect(() => {
		const pickerStorage = JSON.parse(sessionStorage.getItem('simplifyDashboardPicker'));
		if (pickerStorage) {
			setMomentStartDate(moment(pickerStorage.start));
			setMomentEndDate(moment(pickerStorage.end));
		} else {
			const newPicker = { start: moment().startOf('month'), end: moment().startOf('month').add(1, 'months') };
			sessionStorage.setItem('simplifyDashboardPicker', JSON.stringify(newPicker));
		}
	}, []);
	useEffect(() => {
		if (momentStartDate && momentEndDate) {
			const pickerStorage = { start: momentStartDate, end: momentEndDate };
			sessionStorage.setItem('simplifyDashboardPicker', JSON.stringify(pickerStorage));
		}
	}, [momentEndDate]);

	const getAllOpByDate = () => {
		getAllOperations(accountId, (newData) => {
			//Check if account has operations
			if (newData.length == 0) {
				setNoOperationOnAccount(true);
			}
		});
		getOperationByRangeDate(accountId, momentStartDate.format('YYYY-MM-DD'), momentEndDate.format('YYYY-MM-DD'),
			(data) => {
				// Check if range date has operation
				if (data.length === 0) {
					setReload(false);
					setNoData(true);
				} else {
					setNoData(false);
					setOperationsAccount(data);
					setReload(false);
				}
			},
			error => {
				console.log('error', error);
				setReload(false);
				setNoData(true);
			});
	};

	useEffect(() => {
		if (momentStartDate && momentEndDate) {
			setOperationsAccount();
			getAllOpByDate();
		}
	}, [momentEndDate]);

	const handleSearchChange = (event) => {
		event.persist();
		setValueInSearchBar(event.target.value);
	};

	const onFilterLabels = (labelIds) => {
		setLabelFilters(labelIds);
	};

	const selectRangeDate = (event, picker) => {
		setMomentStartDate(picker.startDate);
		setMomentEndDate(picker.endDate);
	};
	const applyRangeDate = () => {
		setOperationsAccount();
		getAllOpByDate();
	};
	const changeRangeDate = (event) => {
		event.persist();
		let rangeDateString = event.target.value;
		let rangeDateArray = rangeDateString.split(' - ');
		if (rangeDateArray.length === 2) {
			let momentStartDate = moment(rangeDateArray[0], MomentConstants.MOMENT_API_FORMAT);
			if (momentStartDate.isValid()) {
				setMomentStartDate(momentStartDate);
			}
			let momentEndDate = moment(rangeDateArray[1], MomentConstants.MOMENT_API_FORMAT);
			if (momentEndDate.isValid()) {
				setMomentEndDate(momentEndDate);
			}
		}
	};
	const reloadDashBoard = () => {
		if (reload && momentStartDate && momentEndDate) {
			getAllOpByDate();
		}
	};
	useEffect(() => {
		reloadDashBoard();
	}, [reload]);

	const putOperationStatusMode = (x) => {
		putOperationExecutionStatusModeWithTest(accountId, x.idOp, x.status, x.executionMode, e => {
			setReloadTheOperationItem(false);
			setReload(true);
			setDataOfOperationToPut();
		},
		e => {
			console.log('status error', e);
		});
	};

	const downloadStats = () => {
		setOperationStatisticsFileName('operations' + momentStartDate.format('YYYYMMDD') + '_' + momentEndDate.format('YYYYMMDD') + '.csv');
		getOperationStatsCsvByRangeDate(accountId, momentStartDate.format('YYYY-MM-DD'), momentEndDate.format('YYYY-MM-DD'),
			(data) => {
				setOperationStatisticsData(data);
				csvLink.current.link.click();
			},
			error => {
				console.log('error', error);
			});
	};

	let canCreateCampaign;
	if (planAccount && userSimplifyRole) {
		canCreateCampaign = check(planAccount, userSimplifyRole, 'simplifyOperation:canCreateCampaign', null);
	}
	const i18nL= localStorage.getItem('i18nL');
	const newInstanceI18nextDash = i18next.createInstance();
	newInstanceI18nextDash.init({
		lng: 'en-EN',                             
		resources: {
			'en-US': {
				common: onsite_list_view_en            
			},
			'en-EN': {
				common: onsite_list_view_en            
			},
			'fr-FR': {
				common: onsite_list_view_fr
			},
		}},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextDash.changeLanguage(JSON.parse(i18nL));
	});
	return (
		<I18nextProvider i18n={newInstanceI18nextDash}>
			<RouterWrapper props={{$routeParams: routeParams, $rootScope: $rootScope}}>
				<section className="section no_bottom_pad section_primary">
					<div className="flex">
						<div className="h1 flex_item_full">Operation list</div>
						<div className="flex_item_fix ml_30">
							<CSVLink
								data={operationStatisticsData}
								filename={operationStatisticsFileName}
								className='hidden'
								separator={','}
								ref={csvLink}
								target='_blank'
							/>
							<Btn
								style="ghost"
								color="secondary"
								message="Download data"
								icon="far fa-arrow-alt-circle-down fa-sm"
								onClick={downloadStats}
							/>
							{canCreateCampaign &&
								<Btn
									href={routeParams && routeParams.ka ? `/BusinessOperations/Create?ka=${routeParams.ka}&ku=${routeParams.ku}&prev=dashboard` : '/BusinessOperations/Create?prev=dashboard'}
									message="New operation"
									icon="fas fa-plus fa-sm"
								/>
							}
						</div>
					</div>
				</section>

				<section className="section no_bottom_pad">
					{!noOperationOnAccount &&
						<div className="clearfix">
							<div className="campaign_list_filter">
								<div className="header-fromto simplify_list_fromto">
									{momentStartDate && <>
										<DateRangePicker
											startDate={`${momentStartDate.format('DD/MM/YYYY')}`}
											endDate={`${momentEndDate.format('DD/MM/YYYY')}`}
											onApply={selectRangeDate}
											locale={{ format: 'DD/MM/YYYY' }}
											displayFormat='DD/MM/YYYY'>
											<input type="text" value={`${momentStartDate.format('DD/MM/YYYY')} - ${momentEndDate.format('DD/MM/YYYY')}`} onChange={changeRangeDate} />
										</DateRangePicker>
										<button onClick={() => applyRangeDate()}>
											<i className="fa fa-sync-alt"></i>
										</button> </>
									}
								</div>
							</div>
							<div className="campaign_list_search">
								<SearchBar
									onChangeValue={(e) => handleSearchChange(e)}
									valueInSearchBar={valueInSearchBar}
									placeholder="Search for an operation"
									fullWidth={false}
									color='white'
								/>
							</div>
							<OperationLabelFilters
								canEdit={true}
								onFilter={labelIds => onFilterLabels(labelIds)}
							/>
						</div>
					}
				</section>

				<section className="section no_top_pad pos_rel">
					{noOperationOnAccount ?
						<div className="empty_page has_picto">
							<div className="empty_page_picto empty_page_picto_simplify"></div>
							<div className="empty_page_title">Business operations</div>
							<div className="empty_page_text">
						Simplify the publication of special offers anywhere on your website,
						thanks to our campaign editing and planning tool
							</div>
							<div className="empty_page_btn">
								<Link
									to={routeParams && routeParams.ka ? { pathname: `/BusinessOperations/Create?ka=${routeParams.ka}&ku=${routeParams.ku}`, } : { pathname: '/BusinessOperations/Create?id=' }}>
									<Btn
										message="Create a new Operation"
										icon="fa fa-plus"
										size="l"
										fontSize="l"
									/>
								</Link>
							</div>
						</div>
						:
						<OperationItems
							isAdmin={isAdmin}
							operationsAccount={operationsAccount}
							accountId={accountId}
							routeParams={routeParams}
							noDataForTheSearch={noData}
							reload={reload}
							deleteOperationById={deleteOperationById}
							setArrayOfLabelSearchBar={setArrayOfLabelSearchBar}
							arrayOfLabelSearchBar={arrayOfLabelSearchBar}
							momentStartDate={momentStartDate}
							momentEndDate={momentEndDate}
							putOperationExecutionStatusMode={(e) => putOperationStatusMode(e)}
							setReloadTheOperationItem={(e) => setReloadTheOperationItem(e)}
							setReload={(e) => setReload(e)}
							reloadTheOperationItem={reloadTheOperationItem}
							dataOfOperationToPut={dataOfOperationToPut}
							setDataOfOperationToPut={(e) => setDataOfOperationToPut(e)}
							permissions={permissions}
							userSimplifyRole={userSimplifyRole}
							valueInSearchBar={valueInSearchBar}
							labelFilters={labelFilters}
							executeStartCapture={executeStartCapture}
						/>
					}
				</section>
			</RouterWrapper>
		</I18nextProvider>
	);
}
