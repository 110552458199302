import React, { useState, useEffect } from 'react';
import Btn from '../../../../Components/Btn';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import EmptyState from '../../../../Components/EmptyState';

import CategoryDropDownList from './CategoryDropDownList';
import DeviceDropDownList from './DeviceDropDownList';
import SecondCategorySelect from './SecondCategorySelect';

import ProductListContainerRanking from './ProductListContainerRanking';
import StandardProduct from './StandardProduct';
import TenantpreviewSelected from './TenantpreviewSelected';
import InputProductBasedOn from './InputBasedOnProduct';
import SelectDropdown from '../../../../Components/SelectDropdown';

import AutocompleteContainer from './AutocompleteContainer';
export default function ProductList({
	tenantsCatalog,
	products = [],
	changeCategorySelected,
	categorySelected,
	typeCategories,
	typeCategoriesSecondLevel,
	typeCategoriesSecondLevelSelected,
	changeSecondCategorySelected,
	typeDimensions,
	isLoading,
	idInClipBoard,
	copyToClipBoard,
	paginationOptions,
	currentPageProductList,
	setCurrentPageProductList,
	handleChangePaginationOptions,
	setNewPaginationNeed,
	handleChangeTenantPreview,
	previewTenant,
	listTenantAccount,
	needToHidePreviewTenants,
	deviceType,
	changeDeviceSelected,
	handleChangenemOfProductbasedOn,
	inputProductBasedOn,
	contextBasedOn,
	listIdProducts,
	handleSelectidOfProduct,
	valueOfSelectedproduct,
	autocompleteListIsLoading,
	AccountWithoutCategories,
	listTags,
	valueOfSelectedTag,
	autocompleteTagListIsLoading,
	handleChangenemOfTagbasedOn,
	inputTagBasedOn,
	handleSelectidOfTag,
	filterIsEmpty
}) {

	const [screenTypeSelected, setscreenTypeSelected] = useState('grid');
	const [isEmpty, setisEmpty] = useState(false);
	const [missingOptionMessage, setmissingOptionMessage] = useState();

	function selectOtherScreenType(elem) {
		screenTypeSelected !== elem && setscreenTypeSelected(elem);
	}

	const [isScroll, setIsScroll] = useState();

	function handleOnScroll(ev) {
		var t = ev.currentTarget;
		var tmpIsScroll = t.scrollTop > 0;
		if (tmpIsScroll != isScroll) {
			setIsScroll(tmpIsScroll);
		}
	}
	function setMissingOptionMessage() {
		if (typeDimensions === 'Tag' && filterIsEmpty) {
			return 'Please select a filter';
		}

		if (contextBasedOn == 'ProductGlobal'

		) {
			if (valueOfSelectedproduct.value == '') {
				return 'Please select a product';
			}
		}

		if (contextBasedOn == 'ProductTag') {
			if (valueOfSelectedproduct.value == '') {
				return 'Please select a tag and a product';
			}
		}
		if (contextBasedOn == 'ProductCategory') {
			if (valueOfSelectedproduct.value == '') {
				return 'Please select a category and a product';
			}
		}
		if (contextBasedOn == 'Tag') {
			if (valueOfSelectedTag.value == '') {
				return 'Please select a tag';
			}
		}

		return;
	}
	function seeCategoryDropDown() {
		if (AccountWithoutCategories) {
			return false;
		}
		if (
			// typeDimensions === 'recommendations' &&
			// contextBasedOn === 'ProductCategory' ||
			typeDimensions === 'recommendations' && contextBasedOn === 'Category'
		) {
			return true;
		}

		if (typeDimensions === 'Tag') {
			return false;
		}
		if (typeDimensions !== 'recommendations') {
			return true;
		}
		return false;
	}
	function seeSecondCategoryDropDown() {
		if (AccountWithoutCategories) {
			return false;
		}
		if (!categorySelected || !typeCategoriesSecondLevelSelected) {
			return false;
		}
		if (typeDimensions === 'recommendations'
			&& contextBasedOn === 'Category'
		) {
			return true;
		}

		if (typeDimensions === 'Tag') {
			return false;
		}
		if (typeDimensions !== 'recommendations') {
			return true;
		}
		return false;
	}
	function seeAutocompleteSelect() {
		// if (typeDimensions === 'Tag') {
		// 	return true
		// }
		if (typeDimensions === 'recommendations' && contextBasedOn) {
			if (typeDimensions === 'recommendations' && contextBasedOn === 'ProductGlobal' || contextBasedOn === 'ProductCategory'
			) {
				return true;
			}
			if (typeDimensions === 'recommendations' && contextBasedOn === 'Tag') {
				return true;
			}

		}
		if (!categorySelected || !typeCategoriesSecondLevelSelected) {
			return false;
		}

	}
	function seeAutocompleteSelectProductIdAndTag() {
		if (!categorySelected || !typeCategoriesSecondLevelSelected) {
			return false;
		}
		if (typeDimensions === 'recommendations' && contextBasedOn === 'ProductTag') {
			return true;
		}

		return false;
	}
	function isTagOrProductSearch() {
		if (contextBasedOn === 'ProductGlobal' || contextBasedOn === 'ProductCategory') {
			return 'Product';
		}
		if (contextBasedOn === 'Tag') {
			return 'Tag';
		}
	}

	useEffect(() => {
		if (products) {
			setisEmpty((products.length == 0) || (products.hasOwnProperty('pageItems') && products.pageItems.length == 0));
		}

		setmissingOptionMessage(setMissingOptionMessage());
	}, [products, contextBasedOn, tenantsCatalog, isLoading]);

	return (
		<>
			<div className="flex_item_full collection_preview">
				<section className={isScroll ? 'collection_preview_header flex scroll_shadow scroll_shadow_bottom scrolling' : 'collection_preview_header flex scroll_shadow scroll_shadow_bottom'}>
					<div className='flex_item_full'>
						<div className='btn_group_xl'>
							{seeAutocompleteSelect() &&
								<>
									<AutocompleteContainer
										contextBasedOn={contextBasedOn}
										optionsList={!contextBasedOn.toLowerCase().contains('tag') ? listIdProducts : listTags}
										value={!contextBasedOn.toLowerCase().contains('tag') ? valueOfSelectedproduct : valueOfSelectedTag}
										onChange={!contextBasedOn.toLowerCase().contains('tag') ? (v) => handleSelectidOfProduct(v) :
											(v) => handleSelectidOfTag(v)
										}
										autocompleteValue={!contextBasedOn.toLowerCase().contains('tag') ? inputProductBasedOn : inputTagBasedOn}
										autocompletePlaceholder={`Search for a ${!contextBasedOn.toLowerCase().contains('tag') ? 'product ID' : 'tag name'}`}
										onAutocomplete={!contextBasedOn.toLowerCase().contains('tag') ? (v) => handleChangenemOfProductbasedOn(v) : (v) => handleChangenemOfTagbasedOn(v)}
										label={isTagOrProductSearch()}
										labelPosition="inner"
										color="white"
										emptyState={
											<EmptyState
												icon={!contextBasedOn.toLowerCase().contains('tag') ? 'fas fa-shopping-bag' : 'fas fa-tag'}
												text={`Please start typing a ${!contextBasedOn.toLowerCase().contains('tag') ?
													'product ID' : 'tag name'}`
												}
												textSize="s"
											/>
										}
										autocompleteListIsLoading={!contextBasedOn.toLowerCase().contains('tag') ? autocompleteListIsLoading : autocompleteTagListIsLoading}
									/>
								</>
							}
							{seeAutocompleteSelectProductIdAndTag() &&
								<>
									<AutocompleteContainer
										contextBasedOn={contextBasedOn}
										optionsList={listIdProducts}
										value={valueOfSelectedproduct}
										onChange={(v) => handleSelectidOfProduct(v)}
										autocompleteValue={inputProductBasedOn}
										onAutocomplete={(v) => handleChangenemOfProductbasedOn(v)}
										label={'Product'}
										labelPosition="inner"
										color="white"
										emptyState={
											<EmptyState
												icon={'fas fa-shopping-bag'}
												text={`Please start typing a ${'product ID'}`
												}
												textSize="s"
											/>
										}
										autocompleteListIsLoading={autocompleteListIsLoading}
									/>
								</>
							}
							{seeCategoryDropDown() &&
								<CategoryDropDownList
									changeCategorySelected={changeCategorySelected}
									categorySelected={categorySelected}
									typeCategories={typeCategories}
									name='Categorie'
								/>
							}
							{seeSecondCategoryDropDown() &&
								<SecondCategorySelect
									handleSelectCategory={changeSecondCategorySelected}
									secondCategorySelected={typeCategoriesSecondLevelSelected}
									categoryList={typeCategoriesSecondLevel}
									name='Categorie'
								/>
							}
							
							{typeDimensions !== 'ranking' &&
								<DeviceDropDownList
									deviceType={deviceType}
									changeDeviceSelected={changeDeviceSelected}
								/>}
							
							{!needToHidePreviewTenants &&
								<TenantpreviewSelected
									handleChangeTenantPreview={handleChangeTenantPreview}
									previewTenant={previewTenant}
									listTenantAccount={listTenantAccount}
								/>
							}
						</div>
					</div>
					<div className='flex_item_fix'>
						<div className='btn_switch'>
							<Btn
								icon="fas fa-th-large"
								isActive={screenTypeSelected === 'grid'}
								onClick={() => selectOtherScreenType('grid')}
								style="outline"
								color="secondary"
								size="s"
							/>
							<Btn
								icon='fas fa-list'
								isActive={screenTypeSelected === 'list'}
								onClick={() => selectOtherScreenType('list')}
								style="outline"
								color="secondary"
								size="s"
							/>
						</div>
					</div>
				</section>
				<div className='collection_preview_body scroll_custom' onScroll={(ev) => handleOnScroll(ev)}>
					{typeDimensions === 'ranking' &&
						<ProductListContainerRanking
							products={products}
							typeDimensions={typeDimensions}
							screenTypeSelected={screenTypeSelected}
						/>
					}
					{typeDimensions !== 'ranking' &&
						<StandardProduct
							products={products}
							typeDimensions={typeDimensions}
							screenTypeSelected={screenTypeSelected}
							idInClipBoard={idInClipBoard}
							copyToClipBoard={copyToClipBoard}
							categorySelected={categorySelected}
							typeCategoriesSecondLevelSelected={typeCategoriesSecondLevelSelected}
							paginationOptions={paginationOptions}
							currentPageProductList={currentPageProductList}
							setCurrentPageProductList={setCurrentPageProductList}
							handleChangePaginationOptions={handleChangePaginationOptions}
							setNewPaginationNeed={setNewPaginationNeed}
						/>
					}
					{!isLoading && isEmpty && missingOptionMessage &&
						<EmptyState
							title={missingOptionMessage}
							text='Complete the missing fields to preview the matching products'
							imageUrl='/Assets/empty_settings.svg'
							verticalAlign={true}
							textSize="l"
						/>
					}
					{!isLoading && isEmpty && !missingOptionMessage &&
						<EmptyState
							title='No matching product'
							text='Please try removing some filters'
							imageUrl='/Assets/product_collection_empty.svg'
							verticalAlign={true}
							textSize="l"
						/>
					}
				</div>
				{isLoading &&
					<SpinnerWheel wheelSize='l' />
				}
			</div>
		</>
	);
}
