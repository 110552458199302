import React, { useState, useEffect, Fragment } from 'react'
import './ConfigPointOfSales.css'
import Btn from '../../../Components/Btn';
import Switch from './../../../Components/Switch';
export default function ConfigPointOfSalesContainer({ accountId, isCreationMode, getAccountPointOfSales, putAccountPointOfSales, setAccountCurrentTab, setIsCreationMode, getAccountConfiguration, setClickOnConfiguration }) {
    const [valueOfProvider, setValueOfProvider] = useState("")
    const [etag, setEtag] = useState("")
    const [isChange, setIsChange] = useState(false)
    const [formData, setFormData] = useState({
        IsActive: true,
        Provider: '',
        ApiBaseUrl: '',
        ApiKey: ''
    })
    const [isReloading, setIsRealoading] = useState(false)
    const handleChangeValueSelect = (e) => {
        const value = e.target.value
        setValueOfProvider(value)
        setIsChange(true)
        setFormData(prevState => ({ ...prevState, Provider: value }))
    }

    const getPointOfSalesData = () => {
        getAccountPointOfSales(accountId, success => {
            setValueOfProvider(success.config.Provider)
            setEtag(success.eTag)
            const newFormData = success.config
            setFormData(newFormData)
        },
            error => {
                console.log("error", error);
            }
        )
    }
    useEffect(() => {
        if (isCreationMode) {
            setValueOfProvider('zelty')
            setFormData({
                IsActive: true,
                Provider: 'zelty',
                ApiBaseUrl: '',
                ApiKey: ''
            })

        } else {
            // faire l'appel à l'api 
            getPointOfSalesData()
        }
    }, [])

    const handleChangeForm = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let prevState = { ...formData }
        prevState[name] = value
        setFormData(prevState)
        setIsChange(true)
    }

    const putThePointOfSalesConfiguration = (e) => {
        setIsRealoading(true)
        e.preventDefault()
        let config
        if (isCreationMode) {
            if (formData.Provider == "zelty") {
                config = { config: formData }
            } else {
                config = { config: formData }
            }
        } else {
            if (formData.Provider == "zelty") {
                config = { config: formData, eTag: etag }
            } else {
                config = { config: formData, eTag: etag }
            }
        }
        putAccountPointOfSales(accountId, config, e => {
            setIsRealoading(false)
            setIsCreationMode(false)
            getAccountConfiguration()
            setClickOnConfiguration(3)
            setEtag("")
            getPointOfSalesData()
            setFormData({
                IsActive: true,
                Provider: '',
                ApiBaseUrl: '',
                ApiKey: ''
            })
            setIsChange(false)
        },
            e => {

                setIsRealoading(false)
            })
    }

    return (
        <div className="page_body">
            <div className="page_body_title">Turnover synchronization configuration</div>
            <div className="config_form_container">
                {!isReloading &&
                    <Fragment>
                        <div className="form_block">
                            <div className="activation_tooling">
                                <Switch
                                    value={formData.IsActive}
                                    name="IsActive"
                                    checked={formData.IsActive}
                                    onChange={(e) => handleChangeForm(e)}
                                >
                                    {formData.IsActive ? " Is Active" : "Is Disabled"}
                                </Switch>
                            </div>
                        </div>
                        <div className="form_block">
                            <label htmlFor="Provider" className="form_block_label">Provider</label>
                            <select name="Provider" id="" className="select-provider" onChange={(e) => handleChangeValueSelect(e)} value={valueOfProvider} >
                                <option value="zelty"> Zelty</option>
                                <option value="beyable"> Beyable</option>
                            </select>
                        </div>
                        {
                            valueOfProvider === "zelty" &&
                            <Fragment>
                                <div className="form_block">
                                    <label htmlFor="ApiBaseUrl" className="form_block_label">Api base url</label>
                                    <input type="text"
                                        name="ApiBaseUrl"
                                        placeholder="Api base url"
                                        onChange={(e) => handleChangeForm(e)}
                                        value={formData.ApiBaseUrl} />
                                </div>
                                <div className="form_block">
                                    <label htmlFor="ApiKey" className="form_block_label">Api Key</label>
                                    <input
                                        type="text"
                                        name="ApiKey"
                                        placeholder="Your Api key"
                                        onChange={(e) => handleChangeForm(e)}
                                        value={formData.ApiKey} />
                                </div>
                            </Fragment>
                        }
                        <div className="form_footer">
                            <Fragment>
                                <Btn
                                    onClickFunction={isCreationMode ?
                                        (event) => { event.preventDefault(); setAccountCurrentTab(0) }
                                        :
                                        (event) => {
                                            event.preventDefault(); getPointOfSalesData();
                                        }
                                    }

                                    message="Cancel"
                                    color="secondary"
                                    style="outline"
                                />
                                <Btn
                                    onClickFunction={(e) => putThePointOfSalesConfiguration(e)}
                                    message="Submit"
                                    style="primary"
                                />
                            </Fragment>
                        </div>
                    </Fragment>
                }

                {
                    isReloading &&
                    <div className="spinner_overlay">
                        <div className="spinner">
                            <div className="rect1"></div>
                            <div className="rect2"></div>
                            <div className="rect3"></div>
                            <div className="rect4"></div>
                            <div className="rect5"></div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
