import HttpServices from './HttpServices';

export default class AccountServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}
	getAccount(accountId, callbackSuccess, callbackError) {
		const userId = accountId; // not checked 
		this.HttpServices.get(`${this.SERVER_URL}/users/${userId}/accounts/${accountId}`, callbackSuccess, callbackError);
	}
}