import React, {useEffect, useState} from 'react';
import SelectCustom from '../../../../../Components/SelectCustom';
import {useProductFeedEditionContext} from '../../../context/ProductFeedEditionContext';
import InputCustom from '../../../../../Components/InputCustom';
import editionStyles from '../../ProductFeedEdition.module.css';
import Btn from '../../../../../Components/Btn';
import {reorderObjectsBySplitAndTakePosition, shallowCopy,keyExistInConfig} from '../../../utils';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Confirm from '../../../../../Components/Confirm';
import {ModalMonoFieldCategory} from './ModalMonoFieldCategory';
import TransformationsContainer from '../Transformations/TransformationsContainer';

export const MonoFieldCategoriesMapping = (props) => {

	const {
		mappingObject,
		metadatasSources,
		handleChangeMonoFieldCategories,
		handleChangeCategoryHierarchies,
		separator,
		setSeparator
	} = useProductFeedEditionContext();

	const [categories, setCategories] = useState([]);
	const [isLoadedCategories, setIsLoadedCategories] = useState(false);
	const [selectFieldValue, setSelectFieldValue] = useState();
	const [showDelConfirm, setShowDelConfirm] = useState(false);
	const [categoryIndexToDelete, setCategoryIndexToDelete] = useState();
	const [modalMonoFieldCategoryIsOpen, setModalMonoFieldCategoryIsOpen] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState();

	useEffect(() => {
		if (mappingObject['Categories']) {
			const loadedCategories = reorderObjectsBySplitAndTakePosition(shallowCopy(mappingObject['Categories']));
			if (loadedCategories.length > 0) {
				setCategories(loadedCategories);
				setSeparator(loadedCategories[0].transformations.list.find(e => e.name === 'SplitAndTake').params.Separator);
				const selectedField = 	mappingObject['Categories'][0].sourceField;
				if(!selectedField){
					setSelectFieldValue(metadatasSources[0].value);
				}else{
					setSelectFieldValue(mappingObject['Categories'][0].sourceField);
				}
			} else setSelectFieldValue(metadatasSources[0].value);
		} else setSelectFieldValue(metadatasSources[0].value);
		setIsLoadedCategories(true);
	}, []);

	useEffect(() => {
		if (isLoadedCategories) {
			handleChangeMonoFieldCategories(categories);
		}
	}, [categories]);

	const addCategory = () => {
		const copyCategories = [...categories];
		const newCategory = {
			beyableField: 'Categories',
			sourceField: selectFieldValue,
			beyableItemName: '',
			transformations: {
				list: [
					{name: 'SplitAndTake',
						params: {Position: copyCategories.length + 1,
							Separator: separator}}
				]
			}
		};
		copyCategories.push(newCategory);
		setCategories(copyCategories);
	};

	const deleteCategory = () => {
		const copyCategories = [...categories];
		copyCategories.splice(categoryIndexToDelete, 1);

		for (let i = 0; i < copyCategories.length; i++) {
			copyCategories[i].transformations.list.find(f => f.name === 'SplitAndTake').params.Position = (i + 1).toString();
		}

		handleChangeCategoryHierarchies(copyCategories.map(e => e.beyableItemName));
		setCategories(copyCategories);
	};

	const closeDelConfirm = () => {
		setShowDelConfirm(false);
		setCategoryIndexToDelete(null);
	};

	const handleChangeCategory = (categoryIndex, newName) => {
		const copyCategories = [...categories];
		copyCategories[categoryIndex].beyableItemName = newName;
		setCategories(copyCategories);
	};

	const handleChangeSeparator = (newValue) => {
		const copyCategories = [...categories];
		copyCategories.map(e => e.transformations.list.find(e => e.name === 'SplitAndTake').params.Separator = newValue);
		setCategories(copyCategories);
		setSeparator(newValue);
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		for (let i = 0; i < result.length; i++) {
			result[i].transformations.list.find(f => f.name === 'SplitAndTake').params.Position = (i + 1).toString();
		}

		return result;
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const items = reorder(
			categories,
			result.source.index,
			result.destination.index
		);

		setCategories(items);
		handleChangeCategoryHierarchies(items.map(e => e.beyableItemName));
	};

	const getPlaceholder = (index) => {
		switch (index) {
			case 0: return 'Ex: Category';
			case 1: return 'Ex: Sub-category';
			case 2: return 'Ex: Sub-sub-category';
			default: return 'Ex: Category';
		}
	};

	const handleChangeCategorySource = (val) =>{
		setSelectFieldValue(val);
		const newCategories = categories.map(c => (
			{
				...c,
				sourceField :val
			}));

		setCategories(newCategories);
	};

	return (
		<div>

			<SelectCustom
				defaultValueDisabled={{
					value: 'choose',
					label: 'Choose a name field',
					isDisabled: true,
				}}
				optionsList={metadatasSources}
				value={selectFieldValue}
				fullWidth={true}
				name={name}
				onChangeFunction={(e) => handleChangeCategorySource(e.target.value)}
				size="l"
			/>

			<div className='mt_20'>
				<InputCustom
					label='Separator'
					description='What string is used to seperate the categories ?'
					name="name"
					value={separator}
					type="text"
					onChange={(e) => handleChangeSeparator(e.target.value)}
					fullWidth={true}
					size="l"
					placeHolder='Ex: , or ;'
				/>
			</div>

			<div className='mt_20'>
				<h3 className="fw_medium s_16">Category names</h3>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable-categories">
						{
							(provided) =>
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{categories.length > 0 && categories.map((cat, i) => {
										const placeHolder = getPlaceholder(i);
										return (
											<Draggable key={i} draggableId={'id' + i} index={i}>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<div className='flex mb_15'>
															<div className='mr_10'>{i + 1}.</div>
															<InputCustom
																name="name"
																value={cat.beyableItemName}
																type="text"
																fullWidth={true}
																onChange={(e) => handleChangeCategory(i, e.target.value)}
																placeHolder={placeHolder}
															/>
															<div
																style={{padding: '4px', display: 'flex'}}
															>
																<a
																	className='icon_btn icon_btn_light s'
																	onClick={() => {
																		setSelectedCategory(cat);
																		setModalMonoFieldCategoryIsOpen(true);
																	}}
																>
																	<i className='fas fa-cog'></i>
																</a>
																<a
																	className='icon_btn icon_btn_light s'
																	onClick={() => {
																		setCategoryIndexToDelete(i);
																		setShowDelConfirm(true);
																	}}
																>
																	<i className='fas fa-trash'></i>
																</a>
															</div>
														</div>
														{props.canUseTranformations &&
															<TransformationsContainer
																objectIdenfifier={'Categories'}
															/>
														}
													</div>
												)}

											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
						}

					</Droppable>
				</DragDropContext>

			</div>

			<div>
				<Btn
					style="reverse"
					icon="fas fa-plus fa-sm"
					message="Add category name"
					horizontalSize='xxs'
					onClick={addCategory}
				/>
			</div>
			<Confirm
				title="Do you really want to delete this category ?"
				text="This action is irreversible"
				confirmText="Delete"
				confirmColor="alert"
				cancelText="Cancel"
				isOpen={showDelConfirm}
				confirmCallback={() => deleteCategory(categoryIndexToDelete)}
				onClose={closeDelConfirm}
			/>
			{modalMonoFieldCategoryIsOpen &&
				<ModalMonoFieldCategory
					modalMonoFieldCategoryIsOpen={modalMonoFieldCategoryIsOpen}
					setModalMonoFieldCategoryIsOpen={setModalMonoFieldCategoryIsOpen}
					currentCategory={selectedCategory}
				/>
			}
		</div>
	);
};
