import React, { Fragment } from 'react';
import { Section } from '../../../../Components/Section/Section';
import InsightsCard from '../components/Card/InsightsCard';
import { useAiInsightsContext } from '../context/AiInsightsContext';
import {AiInsight} from '../context/AiInsightsApiTypesServices';
import { v4 as uuidv4 } from 'uuid';
import {
	SkeletonSquare,
	SkeletonBar,
	SkeletonFlex,
	SkeletonBox,
	SkeletonRect
} from '../../../../Components/Skeleton/Skeleton';

import EmptyState from '../../../../Components/EmptyState';
import { Article } from '../../../../Components/Article/Article';

export default function AiInsightListContainer() {
	const {aiInsights, error, isLoading } = useAiInsightsContext();
	const i18nL = localStorage.getItem('i18nL') || 'en-EN';
	const langue = JSON.parse(i18nL).trim();
	const date = (aiInsights && aiInsights.length && aiInsights[0].date) ? aiInsights[0].date : null;
	const content = {
		'fr-FR' : 'Données à partir du',
		'en-EN' : 'Data from'
	};

	const repeat = (jsx:React.ReactNode, nb:number) => {
		const result:React.ReactNode[] = [];
		for (let i = 0; i < nb; i++) {
			result.push(
				<Fragment key={i}>{jsx}</Fragment>
			);
		}
		return result;
	};

	return (
		<Section width={!isLoading && error ? 'm' : 's'}>
			<section className="section no_bottom_pad section_primary">
				<div className='flex'>
					<div className='flex_item_full'>
						<div className="h1">AI insights</div>
					</div>
					{!isLoading && !error && date && (
						<div className='flex_item_fix ml_20'>
							<p className="grey_2 s_14">
								{`${content[langue.trim() || 'en-EN']} : ${date}`}	
							</p>
						</div>
					)}
				</div>
			</section>
			<section className="section">
				{isLoading &&
					<>
						{repeat(
							<Article innerSize='l'>
								<SkeletonFlex alignItems='flex-start'>
									<SkeletonSquare width='l' />
									<SkeletonBox>
										<SkeletonBar height="m" width="l" />
										<SkeletonBar height="m" width="s" />
										<SkeletonRect width={160} height={82} />
									</SkeletonBox>
								</SkeletonFlex>
							</Article>,
							3
						)}
					</>	
				}
				{!isLoading &&
					<>
						{error &&
							<Article>
								<EmptyState
									title='AI insights'
									imageUrl='/Assets/empty_aiinsights.svg'
									textSize='xl'
									verticalSize='xl'
									text='No data yet, please contact an administrator for more information'
								/>
							</Article>
						}
						{!error && aiInsights && aiInsights.map((insight: AiInsight) => (
							<InsightsCard key={uuidv4()} insight={insight} />
						))}
					</>
				}
			</section>
		</Section>
	);
}
