import React, { useState, useEffect, useContext, useRef } from 'react';
import { BusinessOperationOrchestrationContext } from '../Context';
import Zone from './Zone';

const PageTypeScreenshot = () => {
	const {
		selectedPageType,
		handleUnselectZone
	} = useContext(BusinessOperationOrchestrationContext);


	const refElement = useRef(null);
	const [scrollMobile, setScrollMobile] = useState(0);
	const [scrollDesktop, setScrollDesktop] = useState(0);
    
	const [currentDevice, setCurrentDevice] = useState('mobile');
	const [currentPageType, setCurrentPageType] = useState('');

	const restoreScroll = (device) => {
		if (refElement.current) {
			if (device === 'mobile') {
				refElement.current.scrollTop = scrollMobile;
			} else {
				refElement.current.scrollTop = scrollDesktop;
			}
		}
	};

	const handleScroll = () => {
		if (currentDevice === 'mobile') {
			setScrollMobile(refElement.current.scrollTop);
		} else {
			setScrollDesktop(refElement.current.scrollTop);
		}
	};

	useEffect(() => {
		if (!selectedPageType) return;
		if (selectedPageType.pageType !== currentPageType) {
			refElement.current.scrollTop = 0;
			setScrollMobile(0);
			setScrollDesktop(0);
		} else if (selectedPageType.selectedDevice !== currentDevice) {
			restoreScroll(selectedPageType.selectedDevice);
		}
		setCurrentDevice(selectedPageType.selectedDevice);
		setCurrentPageType(selectedPageType.pageType);

	}, [selectedPageType]);


	if (!selectedPageType)
		return null;

	const selectedScreenshot = selectedPageType.devices[selectedPageType.selectedDevice];
	let containerClass = 'simplify_orch_preview_inner ' + selectedPageType.selectedDevice;

	const getZoneStyle = (zone) => {
		const top = zone.zoneExtensions.Coordinates.top / selectedScreenshot.screenshotExtensions.height * 100 + '%';
		const left = zone.zoneExtensions.Coordinates.left / selectedScreenshot.screenshotExtensions.width * 100 + '%';
		const width = zone.zoneExtensions.Coordinates.width / selectedScreenshot.screenshotExtensions.width * 100 + '%';
		const height = zone.zoneExtensions.Coordinates.height / selectedScreenshot.screenshotExtensions.height * 100 + '%';

		return {
			top: top,
			left: left,
			width: width,
			height: height
		};
	};

	const mappedZones = selectedScreenshot.zones.map(x => ({ ...x, style: getZoneStyle(x) }));

	const previewSrc = selectedScreenshot.imagePath;

	return (
		<div
			ref={refElement}
			onScroll={handleScroll}
			className={containerClass}
			onClick={(e) => handleUnselectZone()}
		>
			<div className="customizePreviewInner">
				<img className="customizePreviewImg" src={previewSrc} />
				<div className="customizeZones">
					<>
						{
							mappedZones.length === 0
								? <div className="nodata"> <p className="title">There is no zone and screenshot associated with this type of page.</p> </div>
								: mappedZones.map((zone) =>
									<Zone key={zone.zoneId} zone={zone}></Zone>
								)
						}
					</>
				</div>
			</div>
		</div>
	);
};

export default PageTypeScreenshot;