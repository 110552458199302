import React from 'react';
import styles from "./Spinner.module.css";

export default function Spinner({overlay=false}) {
	return (
		<>
		  	{overlay ?
		  		<div className="spinner_overlay">
					<div className="spinner">
					    <div className="rect1"></div>
					    <div className="rect2"></div>
					    <div className="rect3"></div>
					    <div className="rect4"></div>
					    <div className="rect5"></div>
					</div>
				</div>
				:
				<div className="spinner">
				    <div className="rect1"></div>
				    <div className="rect2"></div>
				    <div className="rect3"></div>
				    <div className="rect4"></div>
				    <div className="rect5"></div>
				</div>
			}
		</>
	);
}