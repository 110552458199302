import React, { useState, useEffect } from 'react';
import InputCustom from '../../../Components/InputCustom';
import Btn from '../../../Components/Btn';
import Checkbox from '../../../Components/Checkbox';

const modules = [
	{ label: 'OnSite Activation (Marketing script)', id: 'ActivationOnSiteMarketing' },
	{ label: 'OnSite Activation (Functional script)', id: 'ActivationOnSiteFunctional' },
	{ label: 'BEYABLE Ready', id: 'PalModules' },
	{ label: 'Graphic patch campaigns', id: 'Patch' },
	{ label: 'Full code campaigns', id: 'CodePatch' },
	{ label: 'Native App campaigns', id: 'ActivationNativeApp' },
	{ label: 'A/B Tests', id: 'AbTests' },
	{ label: 'Shopify App', id: 'ExternalAppStore' },
	{ label: 'Email Remarketing', id: 'EmailRemarketing' },
	{ label: 'Web Analytics', id: 'WebAnalytics' },
	{ label: 'Web Analytics ECommerce', id: 'WebAnalyticsEcommerce' },
	{ label: 'Product Monitoring', id: 'ProductMonitoring' },
	{ label: 'Simplify', id: 'Simplify' },
	{ label: 'Visitor Engagement', id: 'VisitorEngagement' },
	{ label: 'Recommendations', id: 'Recommendations' },
	{ label: 'Server Side', id: 'ActivationServerSide' },
	{ label: 'Inbox', id: 'MyOffers' },
	{ label: 'Shoplist', id: 'WishList' },
];

const organizationAdminModules = ['ActivationOnSiteMarketing', 'ActivationOnSiteFunctional', 'PalModules', 'AbTests'];

export default function FormCreateAccount({ onSubmitForm, errors, mainRole }) {
	const [newAccount, setnewAccount] = useState({
		Email: '',
		AccountName: ''
	});
	const [accountModules, setAccountModules] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleChangeForm = (e) => {
		const val = e.target.value;
		const name = e.target.name;

		setnewAccount(account => ({
			...account,
			[name]: val
		}));
	};

	const submit = (e) => {
		setIsSubmitting(true);
		e.preventDefault();

		onSubmitForm(newAccount, accountModules,
			(account) => {
				if (accountModules.includes('EmailRemarketing')) {
					return { ...account, EnableRemarketing: true };
				}
				return account;
			},
			() => {
				setIsSubmitting(false);
			});
	};
	const isAnError = (name) => {
		const errorsByName = errors.map(er => er.key);
		const isError = errorsByName.includes(name) ? errors.find(er => er.key === name).message : null;
		return isError;
	};

	const selectModule = (id, selected) => {
		if (selected) {
			setAccountModules(m => [...m, id]);
		} else {
			setAccountModules(m => m.filter(x => x != id));
		}
	};

	const shouldAllowModule = (moduleId) => {
		if (mainRole.isAnAdmin) return true;
		if (mainRole.isAnOrganizationAdmin) {
			return organizationAdminModules.includes(moduleId);
		}
		return false;
	};

	return (
		<form onSubmit={submit}>
			<InputCustom
				value={newAccount.AccountName}
				type="text"
				label="Account name"
				name="AccountName"
				placeholder="Account name"
				onChange={handleChangeForm}
				fullWidth={true}
				isAnError={isAnError('AccountName')}
			/>
			<InputCustom
				value={newAccount.Email}
				type="email"
				label="Contact email"
				name="Email"
				placeholder="Email"
				onChange={handleChangeForm}
				fullWidth={true}
				isAnError={isAnError('Email')}
			/>

			<h2>Modules</h2>
			<article>
				<div className="btt-checkbox">
					{modules.map(x =>
						<div key={x.id}>
							<Checkbox
								label={x.label}
								onChange={e => selectModule(x.id, e.target.checked)}
								disabled={!shouldAllowModule(x.id)}
							/>
						</div>
					)}
				</div>
			</article>


			<div className="mt_30 al_right">
				<Btn
					type="submit"
					onClickFunction={null}
					message="Create account"
					color="primary"
					size="l"
					isActive={!isSubmitting}
				/>
			</div>
		</form>
	);
}
