import React, { useState, useEffect } from 'react';
import formatMoment from '../Constants/FormatMoment';
export default function useStatusOperationReport(data) {


	const today = moment();
	const [status, setStatus] = useState({});
	const { formatDateToLocal } = formatMoment;
	//Past, Ongoing, Scheduled, Stopped, Testing
	useEffect(() => {
		if (data) {
			const dateBeginOfOperation = formatDateToLocal(data.startDate);
			const dateEndOfOperation = data.endDate && formatDateToLocal(data.endDate);
			const isStoppedBoolOperation = data.isStopped;
			const isTestModeOperationBool = data.isTestMode;

			if (isStoppedBoolOperation) {
				setStatus({ id: 'Stopped', label: 'Stopped', cssClass: 'status-stopped' });
				return;
			}

			if (isTestModeOperationBool) {
				setStatus({ id: 'testing', label: 'Draft', cssClass: 'status-testing' });
				return;
			}

			if (data.endDate && dateEndOfOperation < today) {
				setStatus({ id: 'past', label: 'Past', cssClass: 'status-past' });
				return;
			}

			if (dateBeginOfOperation > today) {
				setStatus({ id: 'planned', label: 'Scheduled', cssClass: 'status-planned' });
				return;
			}

			setStatus({ id: 'ongoing', label: 'Ongoing', cssClass: 'status-ongoing' });
		}
	}, [data]);
	return status;
}
