const EngagementLevelOrder = 
{ 
    "X": 0,
    "D": 1,
    "C": 2,
    "I": 3,
    "A": 4,
    "E": 5,
    "R": 6
};

export default EngagementLevelOrder;
