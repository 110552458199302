import React, {useEffect, useState} from 'react';
import { CampaignsContext } from '../../context/index.js';
import CodeEditor from './CodeEditor.js';
import {isEmpty} from 'lodash';

export default function TemplateMobileEditor() {

	const {
		variations,
		currentVariationId,
		getCurrentVariation,
		handleUpdateCss,
		handleUpdateJs,
		handleUpdateHtml,
	} = CampaignsContext();
	
	const [resetCssMobileValue, setResetCssMobileValue] = useState('');
	const [resetJsMobileValue, setResetJsMobileValue] = useState('');
	const [resetHtmlMobileValue, setResetHtmlMobileValue] = useState('');
	const activeVariation = getCurrentVariation() || {};
	const [toggleState, setToggleState] = useState(3);

	const seeCSS = () => {
		setToggleState(2);
	};
	const seeJS = () => {
		setToggleState(1);
	};
	const seeHtml = () => {
		setToggleState(3);
	};

	// init reset responsive
	useEffect(() => {
		if (
			!activeVariation ||
			activeVariation === isEmpty(activeVariation) ||
			!activeVariation.TemplateDescriptorMobile
		)
			return;
		const mobile = activeVariation.TemplateDescriptorMobile;
		if (!resetCssMobileValue && mobile.EditableCss)
			setResetCssMobileValue(mobile.EditableCss);

		if (!resetJsMobileValue && mobile.EditableJavascript)
			setResetJsMobileValue(mobile.EditableJavascript);

		if (!resetHtmlMobileValue && mobile.EditableTemplate)
			setResetHtmlMobileValue(mobile.EditableTemplate.Content);
	}, [activeVariation]);

	useEffect(() => {
		if (!currentVariationId) return;
		if (variations.length < 2) return;
		if (currentVariationId) {
			const current = variations.filter(variation => variation.UId === currentVariationId)[0];
			if (!current) return;
			if (current.TemplateDescriptorMobile && current.TemplateDescriptorMobile.EditableCss) {
				const mobileCss = current.TemplateDescriptorMobile.EditableCss;
				setResetCssMobileValue(mobileCss);
			}
			if (current.TemplateDescriptorMobile.EditableJavascript) {
				const mobileJs = current.TemplateDescriptorMobile.EditableJavascript;
				setResetJsMobileValue(mobileJs);
			}

		}
	}, [currentVariationId]);

	if(!activeVariation || activeVariation.SlideFormat === 'InCollection'){
		return null;
	}

	return (
		<div style={{position: 'relative', height: 'min(700px, calc(100vh - 80px))'}}>
			<div className="patch_editor flex flex_v flex flex_v">
				<div className="patch_editor_head flex_item_fix flex">
					<ul className="patch_editor_tabs flex flex_item_full">
						<li>
							<a
								className={
									toggleState === 3
										? 'is_active is_html'
										: 'is_html'
								}
								onClick={seeHtml}
							>
								HTML
							</a>
						</li>
						<li>
							<a
								className={
									toggleState === 2
										? 'is_active is_css'
										: 'is_css'
								}
								onClick={seeCSS}
							>
								CSS
							</a>
						</li>
						<li>
							<a
								className={
									toggleState === 1
										? 'is_active is_js'
										: 'is_js'
								}
								onClick={seeJS}
							>
								JS
							</a>
						</li>
					</ul>

				</div>
				<div className="patch_editor_body flex_item_full">
					{toggleState === 1 && (
						<CodeEditor
							title="JAVASCRIPT"
							language="javascript"
							screenEnvironment="mobile"
							changeValue={(_prevValue, e) => {
								setResetJsMobileValue(e);
								handleUpdateJs(e);
							}}
							value={
								(activeVariation.TemplateDescriptorDesktop &&
									activeVariation.TemplateDescriptorMobile.EditableJavascript) || ''
							}
						/>
					)}
					{toggleState === 2 && (
						<CodeEditor
							title="CSS"
							language="css"
							screenEnvironment="mobile"
							changeValue={(_prevValue, e) => {
								setResetCssMobileValue(e);
								handleUpdateCss(e);
							}}
							value={
								(activeVariation.TemplateDescriptorDesktop &&
									activeVariation.TemplateDescriptorMobile.EditableCss) || ''
							}
						/>
					)}
					{toggleState === 3 && (
						<CodeEditor
							title="HTML"
							language="html"
							screenEnvironment="mobile"
							changeValue={(_prevValue, e) => {
								handleUpdateHtml(e);
								setResetHtmlMobileValue(e);
							}}
							value={
								(activeVariation.TemplateDescriptorDesktop &&
									activeVariation.TemplateDescriptorMobile.EditableTemplate.Content) || ''
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
