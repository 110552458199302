import React, { useState, useEffect } from 'react'

export default function FileAttachmentForm({ file, setFileValueToSet }) {
    const [fileValue, setFileValue] = useState({
        name: "",
        type: "",
        filepath: ""
    })
    useEffect(() => {
        setFileValue(file)
    }, [])
    const handleChangeForm = (e) => {
        let prevState = { ...fileValue }
        prevState[e.target.name] = e.target.value
        setFileValue(prevState)
    }
    useEffect(() => {
        setFileValueToSet(fileValue)
    }, [fileValue])

    return (
        <form>
            <div className="form_block">
                <label htmlFor="type" className="form_block_label">Name</label>
                <input type="text" name="name" value={fileValue ? fileValue.name : ""} onChange={handleChangeForm} />
            </div>
            <div className="form_block">
                <label htmlFor="type" className="form_block_label">File Type</label>
                <input type="text" name="type" value={fileValue ? fileValue.type : ""} onChange={handleChangeForm} />
            </div>
            <div className="form_block">
                <label htmlFor="filepath" className="form_block_label">Filepath</label>
                <input type="text" className="full" name="filepath" value={fileValue ? fileValue.filepath : ""} onChange={handleChangeForm} />
            </div>
        </form>
    )
}
