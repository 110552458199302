import React, { useEffect, useState } from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/theme/ttcn.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/display/placeholder';
import { Panel, PanelHeader, PanelBody } from '../../../../Components/Panel/Panel';
import Btn from '../../../../Components/Btn';
import SectionMessage from '../../../../Components/SectionMessage';
import { CampaignsContext } from '../../context/index.js';


export default function PanelData({ dataPanelIsOpened, handleOpenClosePanel }) {
	const {
		getCurrentVariation,
		handleAddOrUpdateProperty,
		handleDeleteProperty
	} = CampaignsContext();

	const [canConfirm] = useState(true);
	const [data, setData] = useState<any>();
	const [error, setError] = useState<string | null>();

	const validate = () => {
		const getJsonData = () => {
			try {
				return JSON.parse(data ?? '{}');
			} catch {
				return null;
			}
		};
		const jsonData = getJsonData();
		if (!!data && jsonData === null) {
			setError('Input is invalid, it should be a valid JSON');
		} else if (!data) {
			handleDeleteProperty('DATA');
			handleOpenClosePanel(false);
		} else {
			setError(null);
			const isCollection = Array.isArray(jsonData);
			const isGroup = typeof jsonData === 'object' && !isCollection;
			handleAddOrUpdateProperty(jsonData, 'DATA', isCollection, isGroup);
			handleOpenClosePanel(false);
		}
	};

	function getDataPropertyValue() {
		const currentVariation = getCurrentVariation();
		const dataProperty = currentVariation.Properties.find(el => el.Name === 'DATA');
		return dataProperty?.Value;
	}

	const handleCodeChanged = (value) => {
		setData(value);
	};

	const getDataWithoutKeys = (inputData): any => {
		if (!inputData) {
			return null;
		}

		const removeKey = (d) => {
			if (typeof d === 'object' && !Array.isArray(d)) {
				const dd = { ...d };
				delete dd.Key;
				return dd;	
			} else {
				return d;
			}
		};

		if (typeof inputData === 'object' && !Array.isArray(inputData)) {
			return removeKey(inputData);
		} else if (Array.isArray(inputData)){
			return inputData.map(removeKey);
		} else {
			return inputData;
		}
	};

	const setDataAsFormattedJson = (value) => {
		setData(JSON.stringify(value, null, 2));
	};

	const example = {
		title: 'What\'s new this week',
		positionInListing: 4,
		ctaColor: '#FF5566'
	};

	const options = {
		jsonldMode: true,
		theme: 'ttcn',
		lineNumbers: false,
		lineWrapping: true,
		indentUnit: 2,
		autocorrect: true,
		readOnly: false,
		keyMap: 'sublime',
		placeholder: JSON.stringify(example, null, 2)
	};

	const exampleOptions = { ...options };
	exampleOptions.readOnly = true;
	exampleOptions.lineNumbers = true;

	useEffect(() => {
		if (dataPanelIsOpened) {
			const d = getDataWithoutKeys(getDataPropertyValue());
			if (d) setDataAsFormattedJson(d);
		}
	}, [dataPanelIsOpened]);

	const wrapperStyle = {
		border: 'solid 1px var(--main-border-color)',
		borderRadius: '6px',
		overflow: 'hidden'
	};

	return (
		<Panel
			width={680}
			isOpen={dataPanelIsOpened}
		>
			<PanelHeader
				onClose={() => handleOpenClosePanel(false)}
				title="Custom variables"
				primaryAction={
					<Btn
						message="Confirm"
						color="primary"
						onClick={() => validate()}
						disabled={!canConfirm}
					/>
				}
			/>
			<PanelBody color='grey' flexVertical={true}>
				<div className='flex_item_fix mb_15'>
					<SectionMessage
						type='information'
						appearance='background_outline'
						noMargin={true}
						fontSize='s'
						text={
							<>
								You can define data that is accessible by the campaign code.
								This allows you to change the display or behavior of the campaign without going through the code.
								For example, if you want to be able to modify a text at any time, simply add a property here containing this text and modify its value at the desired time.
								The expected format is JSON.
							</>
						}
						verticalSize="s"
					/>
				</div>
				{error &&
					<div className='flex_item_fix mb_15'>
						<SectionMessage
							type='alert'
							appearance='background_outline'
							noMargin={true}
							text={error}
							verticalSize="s"
						/>
					</div>
				}
				<div className='flex_item_full code_mirror_full' style={wrapperStyle}>
					<CodeMirror
						value={data}
						options={options}
						autoCursor={false}
						onChange={(_, __, value) => { handleCodeChanged(value); }}
						className='s_13'
					/>
				</div>
			</PanelBody>
		</Panel>
	);
}