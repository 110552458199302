import {initInheritStyles} from './utils';

const scriptExport = function(props) {
	if (!cpg) return;

	var btn = this;
	btn.addEventListener('click', function(){
		cpg.close();
	});
};

const icon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" viewBox="0 0 40 30">
  <defs>
    <clipPath id="clip-ico_close_1">
      <rect width="40" height="30"/>
    </clipPath>
  </defs>
  <g id="ico_close_1" data-name="ico_close – 1" clip-path="url(#clip-ico_close_1)">
    <g id="Ellipse_143" data-name="Ellipse 143" transform="translate(8 3)" fill="#e9eaf0" stroke="#acafc0" stroke-width="2">
      <circle cx="12" cy="12" r="12" stroke="none"/>
      <circle cx="12" cy="12" r="11" fill="none"/>
    </g>
    <rect id="Rectangle_1240" data-name="Rectangle 1240" width="2" height="12.002" rx="1" transform="translate(15.05 11.464) rotate(-45)" fill="#acafc0"/>
    <rect id="Rectangle_1241" data-name="Rectangle 1241" width="2" height="12" rx="1" transform="translate(23.536 10.05) rotate(45)" fill="#acafc0"/>
  </g>
</svg>
`;

const html = `
	<span data-gjs-type="popin_close_text"></span>
	<span data-gjs-type="popin_close_icon"></span>
`;

export const componentPopinClose = {
	model: {
		defaults: {
			name: 'Close',
			removable: false,
			draggable: false,
			droppable: false,
			copyable: false,
			layerable: true,
			tagName: 'a',
			attributes: {
				class: 'by_close by-js-popin-close'
			},
			components: html,
			traits: [
				{
					type: 'close_layout',
					name: 'closeLayout',
					label: 'Content',
					tab: 'layout'
				}
			],
			closeLayout: 'text_icon',
			stylable: ['color', 'background-color', 'border-radius', 'margin-top', 'margin-right'],
			style: {
				'position': 'absolute',
				'z-index': '2',
				'top': '-36px',
				'right': '-10px',
				'cursor': 'pointer',
				'color': '#fff',
				'padding': '8px',
				'display': 'flex',
				'align-items': 'center',
				'gap': '4px',
				'background-color': 'transparent',
				'border-radius': '3px',
			},
			'script-export': scriptExport,
			'script-props': [],
			icon: icon
		}
	}
};

export const componentPopinCloseText = {
	extend: 'text',
	model: {
		defaults: {
			name: 'Close text',
			removable: false,
			draggable: false,
			droppable: false,
			selectable: false,
			layerable: false,
			hoverable: false,
			highlightable: false,
			editable: true,
			propagate: ['draggable', 'droppable', 'layerable', 'selectable'],
			tagName: 'span',
			traits: [],
			content: 'Close',
			style: {
				'flex': '0 0 auto',
				'font-size': '13px',
				'font-weight': '500',
				'line-height': '20px',
			},
			icon: icon
		}
	}
};

const iconStyles = `
	.BY_BLD .by_close_icon::before,
	.BY_BLD .by_close_icon::after{
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
		-ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
		border-radius: 3px;
		background: currentColor;
	}
	.BY_BLD .by_close_icon::before{
		width: 2px;
		height: 13px;
	}
	.BY_BLD .by_close_icon::after{
		width: 13px;
		height: 2px;
	}
`;

export const componentPopinCloseIcon = {
	model: {
		defaults: {
			name: 'Close icon',
			removable: false,
			draggable: false,
			droppable: false,
			selectable: false,
			layerable: false,
			hoverable: false,
			highlightable: false,
			tagName: 'span',
			attributes: {
				class: 'by_close_icon'
			},
			styles: iconStyles,
			style: {
				'flex': '0 0 auto',
				'width': '20px',
				'height': '20px',
				'position': 'relative',
			},
			icon: icon
		}
	}
};
