import React, { useEffect, useState } from 'react';
import { CampaignsContext } from '../../context/index.js';
import Panel from '../../../../Components/Panel';
import Btn from '../../../../Components/Btn';
import Switch from '../../../../Components/Switch';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import InputCustom from '../../../../Components/InputCustom';
import NumericUpDown from '../../../../Components/NumericUpDown';
import PanelArticle from '../../../../Components/PanelArticle';

interface PromoCode {
	ActualPromoCode: string,
	Text: string,
	GenerateUniqueCode: boolean,
	UniqueCodeGenerationOptions: UniqueCodeGenerationOptions
}
type UniqueCodeGenerationOptions = {
	CodeSize: number,
	Prefix: string,
	ExpirationDelayInDays: number
}
function getDefaultUniqueCodeGenerationOptions() {
	return {
		CodeSize: 8,
		Prefix: 'BY',
		ExpirationDelayInDays: 2
	};
}

function getDefaultPromoCode(code) {
	const defaultPromoCode = {
		ActualPromoCode: code,
		Text: '',
		GenerateUniqueCode: false,
		UniqueCodeGenerationOptions: getDefaultUniqueCodeGenerationOptions()
	};
	return defaultPromoCode;
}
export default function PanelPromoCode({
	panelPromoCodeIsOpen,
	handleOpenClosePanel,
}) {
	const {
		getCurrentVariation,
		handleSetPromoCode,
		handleChangeIsActivePromoCode,
		currentVariationId
	} = CampaignsContext();

	// const handleConfirm = () => {
	// 	const value = promoCode.ActualPromoCode;
	// 	// setPanelIsOpen(false);
	// 	// onChange(value);
	// };
	const currentVariation = getCurrentVariation() || {};
	const [promoCode, setPromoCode] = useState<PromoCode>(currentVariation?.Answer?.ItemType == 'CallToActionWithPromoCode' ? currentVariation?.Answer?.PromoCode : null);

	const initializePromoCode = () => {
		if (currentVariation?.Answer?.ItemType == 'CallToActionWithPromoCode') {
			let answerPromoCode;
			if (currentVariation?.Answer?.PromoCode) {
				answerPromoCode = currentVariation?.Answer?.PromoCode;
			} else {
				answerPromoCode = getDefaultPromoCode('');
			}
			setPromoCode(answerPromoCode);

		}
	};


	useEffect(() => {
		initializePromoCode();
	}, [currentVariationId]);

	useEffect(() => {
		if (promoCode != null) {
			handleSetPromoCode(promoCode);
		}
	}, [promoCode]);

	const setUniqueCodeGenerationOption = (propertyName, value) => {
		setPromoCode(p => ({ ...p, UniqueCodeGenerationOptions: { ...p.UniqueCodeGenerationOptions, [propertyName]: value } }));
	};

	const switchEnableUniqueCodeGeneration = (isActive) => {
		handleChangeIsActivePromoCode(isActive);
		setPromoCode(p => {
			const code = p ?? getDefaultPromoCode('');
			return ({
				...code,
				GenerateUniqueCode: isActive,
				UniqueCodeGenerationOptions: code.hasOwnProperty('UniqueCodeGenerationOptions') ? code.UniqueCodeGenerationOptions : getDefaultUniqueCodeGenerationOptions()
			});
		});
	};

	const setActualPromoCode = (propertyName, value) => {
		setPromoCode(p => ({ ...p, [propertyName]: value }));
	};
	return (
		<Panel
			width="680"
			isOpen={panelPromoCodeIsOpen}
			onClose={() => handleOpenClosePanel(false)}
			noClose={true}
		>
			<ModalHeader>
				<div className='flex'>
					<div className="flex_item_fix">
						<a className="panel_close panel_close_left" onClick={() => handleOpenClosePanel(false)}></a>
					</div>
					<div className='flex_item_full'>
						Promo code
					</div>
					<div className='flex_item_fix ml_30'>
						<Btn
							message="Confirm"
							color="primary"
							onClick={() => handleOpenClosePanel(false)}
						/>
					</div>
				</div>
			</ModalHeader>
			<ModalBody color='grey'>
				<PanelArticle size='xl'>

					<Switch
						forcedChecked={promoCode && promoCode.GenerateUniqueCode}
						onChange={(ev) => switchEnableUniqueCodeGeneration(ev.target.checked)}
						label="Enable unique promo codes"
					/>

					<hr />

					<InputCustom
						value={(promoCode && promoCode.ActualPromoCode) ?? ''}
						label={promoCode && promoCode.GenerateUniqueCode ? 'Matching code' : 'Fixed code'}
						description={promoCode && promoCode.GenerateUniqueCode
							? 'This code will not be visible to visitors, it will be returned by the BEYABLE API'
							: 'This code will be displayed to all targeted visitors'}
						placeholder='Ex: OFFER2023'
						onChange={(ev) => setActualPromoCode('ActualPromoCode', ev.target.value)}
					/>
					{promoCode && promoCode.GenerateUniqueCode &&
						<>
							<InputCustom
								value={promoCode && promoCode.UniqueCodeGenerationOptions?.Prefix || ''}
								label='Generated code prefix'
								placeholder='Optional'
								onChange={(ev) => setUniqueCodeGenerationOption('Prefix', ev.target.value)}
							/>
							<NumericUpDown
								value={promoCode && promoCode.UniqueCodeGenerationOptions?.CodeSize || ''}
								min={5}
								max={16}
								label='Generated code size'
								description='Size of the code excluding prefix'
								placeholder='8'
								canEdit={true}
								onChange={(ev) => setUniqueCodeGenerationOption('CodeSize', ev.target.value)}
							/>
							<NumericUpDown
								value={promoCode && promoCode.UniqueCodeGenerationOptions.ExpirationDelayInDays || 1}
								min={1}
								max={365}
								label='How many day(s) the code should be available?'
								placeholder='7'
								canEdit={true}
								onChange={(ev) => setUniqueCodeGenerationOption('ExpirationDelayInDays', ev.target.value)}
							/>
						</>
					}
				</PanelArticle>
			</ModalBody>
		</Panel>
	);
}
