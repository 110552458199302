const icon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" viewBox="0 0 40 30">
  <defs>
    <clipPath id="clip-ico_text_1">
      <rect width="40" height="30"/>
    </clipPath>
  </defs>
  <g id="ico_text_1" data-name="ico_text – 1" clip-path="url(#clip-ico_text_1)">
    <g id="Rectangle_1209" data-name="Rectangle 1209" transform="translate(7 2)" fill="#e9eaf0" stroke="#acafc0" stroke-width="2" stroke-dasharray="3">
      <rect width="26" height="26" rx="2" stroke="none"/>
      <rect x="1" y="1" width="24" height="24" rx="1" fill="none"/>
    </g>
    <path id="Tracé_2086" data-name="Tracé 2086" d="M5.909.152A1.092,1.092,0,0,1,4.693-1.1v-10.26H.988A.915.915,0,0,1-.057-12.388q0-1.007,1.045-1.007H10.83q1.045,0,1.045,1.007a.915.915,0,0,1-1.045,1.026h-3.7V-1.1A1.092,1.092,0,0,1,5.909.152Z" transform="translate(14 22)" fill="#acafc0"/>
  </g>
</svg>
`;

export const componentText = {
	model: {
		extends: 'text',
		defaults: {
			name: 'Text',
			traits: [
				{
					type: 'responsive',
					name: 'responsive',
					label: 'Responsive',
					changeProp: true,
					tab: 'responsive'
				}
			],
			stylable: [
				'padding',
				'font-family',
				'font-size',
				'font-weight',
				'text-align',
				'letter-spacing',
				'color',
				'line-height',
				'text-shadow',
				'background-color',
				'background-image',
				'border',
				'border-radius',
				'box-shadow'
			],
			style: {
				'background-repeat': 'no-repeat',
				'background-position': 'center',
				'background-size': 'cover',
			},
			icon: icon
		}
	}
};
