import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import formatMoment from '../../../../Constants/FormatMoment';
import Btn from '../../../../Components/Btn';
import Dropdown from '../../../../Components/Dropdown';
import useStatusOperationGraph from '../../../../Hooks/useStatusOperationGraph';
import DropDownAction from '../UI/DropDownAction';
import './OperationCardOnSite.css';
import CampaignStatus from '../UI/CampaignStatus';
import CampaignLabel from '../UI/CampaignLabel';
import { onSiteContext } from '../context';
import CampaignItemExtend from '../UI/CampaignItemExtend';
import useFeature from '../../../../../flags/useFeature';
import { Trans, useTranslation } from 'react-i18next';
export default function OperationCardOnSite({ campaign, setDropdownIsOpen }) {
	const {
		accountId,
		$routeParams,
		ku,
		permissionsAccount,
		openModalCampaignLabels,
		isAdmin,
		changeStatusOfCampaign,
		setCurrentCampaign,
		refreshCampaign
	} = onSiteContext();
	const [t, i18n] = useTranslation('common');
	const { features } = useFeature();
	const { formatDateToLocal, getTimeZone } = formatMoment;
	let statusOperation = useStatusOperationGraph(campaign);
	const [devices, setdevices] = useState([]);
	const idTooltip = uuidv4();
	const idTooltipOfMobile = uuidv4();
	const idTooltipOfDesktop = uuidv4();
	const idTooltipOfPause = uuidv4();
	const idTooltipOfPlayOp = uuidv4();
	// const idTooltipOfEditOPi = uuidv4();
	const idTooltipOfEarth = uuidv4();
	// const idTooltipOfTrackingMode = uuidv4();
	const idTooltipOfLabels = uuidv4();
	const [debugDropdownIsOpen, setDebugDropdownIsOpen] = useState(false);
	const [canAccessToStatistics, setcanAccessToStatistics] = useState('');
	const [statusAction, setstatusAction] = useState(<></>);
	const [canAccessTestMode, setcanAccessTestMode] = useState(false);
	const [refreshButton, setRefreshButton] = useState(<></>);
	const stat_route = {};
	const dateStartOperationToLocal = formatDateToLocal(
		campaign.operation.startDate
	);
	const dateEndOperationToLocal = formatDateToLocal(campaign.operation.endDate);
	const [canAccessCreation, setcanAccessCreation] = useState(<></>);
	const [actionsDropdownIsOppen, setActionsDropdownIsOppen] = useState(false);
	const [editDropdownIsOpen, setEditDropdownIsOpen] = useState(false);
	const [permissionsUser, setPermissionsUser] = useState({});
	const hasAccountPermission = (permission) => 
  	isAdmin || (permissionsAccount && (permissionsAccount.find(perm => perm.Name === permission) || {}).Value == true);
	const hasPermission = (permission) => 
  	isAdmin || (hasAccountPermission(permission) && permissionsUser && permissionsUser[permission]);

	let timeZoneUser;
	timeZoneUser = getTimeZone(campaign.operation.startDate, true,t('getTimeZone.yourTimeZone') );

	stat_route.pathname = `/BusinessOperations/statistics?id=${
		campaign.operation.operationId
	}&startDate=${dateStartOperationToLocal.format(
		'YYYY-MM-DD'
	)}&endDate=${dateEndOperationToLocal.format('YYYY-MM-DD')}`;

	if ($routeParams && $routeParams.ka) {
		stat_route.pathname += `&ka=${$routeParams.ka}&ku=${$routeParams.ku}`;
	}

	stat_route.pathname += '&prev=planning';
	useEffect(() => {
		if (campaign) {
			const campaignDevices = campaign.devices.map((device) => device.deviceId);
			setdevices(campaignDevices);
		}
		setCurrentCampaign(campaign);
	}, [campaign]);
	const canAccessStatistics = () => {
		const statisticsAcess = hasAccountPermission('STATISTICSCAMPAIGNS_CAN_ACCESS');
		if (statisticsAcess) {
			let campaignUrl =
        '/CampaignReporting/Home/?dimension=onSite&id=' + campaign.id;
			if ($routeParams) {
				if (isAdmin) {
					campaignUrl += '&ka=' + accountId + '&ku=' + $routeParams.ku;
				}
				setcanAccessToStatistics(campaignUrl);
			}
		}
	};
	const canAccessTotestMode = () => {
		const statisticsAcess =	hasAccountPermission('CAMPAIGNS_TEST_MODE');
		if (statisticsAcess) {
			setcanAccessTestMode(true);
		}
	};
    

	const canAccessToEditionLabels = () => {
		const canCreate = hasPermission('CAMPAIGNS_CAN_CREATE');
		if (canCreate) {
			setcanAccessCreation(
				<>
					<div
						onClick={(e, bool, id) => {
							openModalCampaignLabels(true, campaign);
						}}
						data-for={idTooltipOfLabels}
						data-tip={t('labels.editCampaignLabels')}
					>
						<ReactTooltip
							id={idTooltipOfLabels}
							backgroundColor="black"
							effect="solid"
						/>
						<i className="fa fa-tag"></i>
					</div>
				</>
			);
		}
	};
	useEffect(() => {
		canAccessStatistics();
		canAccessTotestMode();
		canAccessToEditionLabels();
	}, [permissionsAccount]);

	useEffect(() => {
		if (campaign) {
			const listOfCampaignPermission = (campaign.currentUserPermissions || [])
				.reduce((acc, perm) => ({... acc, [perm.permission]: perm.isAllowed}), {});
			setPermissionsUser(listOfCampaignPermission);
		}
	}, [campaign]);

	const statusPermToRefresh = () => {
		if (isAdmin || (permissionsUser && permissionsUser.CAMPAIGNS_CAN_SUSPEND)) {
			setRefreshButton(
				<Btn
					style="outline"
					color="secondary"
					size="xs"
					icon="fas fa-redo"
					dataFor={idTooltip}
					dataTip={'Refresh campaign'}
					onClick={() => refreshCampaign(accountId, campaign.id)}
				/>
			);
		} else {
			setRefreshButton(<></>);
		}
	};
	const statusPermToRender = () => {
		if (!campaign.isStopped) {
			if (
				hasPermission('CAMPAIGNS_CAN_SUSPEND')
			) {
				setstatusAction(
					<>
						<div
							onClick={(e) => {
								setDropdownIsOpen(false);
								changeStatusOfCampaign(true, campaign);
							}}
						>
							<ReactTooltip
								id={idTooltipOfPause}
								className="labelKpi"
								backgroundColor="black"
								effect="solid"
							/>
							<i
								data-for={idTooltipOfPause}
								className="fas fa-pause"
								data-tip="Pause the operation"
							></i>
						</div>
					</>
				);
			}
		} else {
			if (
				hasPermission('CAMPAIGNS_CAN_SUSPEND')
			) {
				setstatusAction(
					<>
						<div
							onClick={() => {
								setDropdownIsOpen(false);
								changeStatusOfCampaign(false, campaign);
							}}
						>
							<ReactTooltip
								id={idTooltipOfPlayOp}
								className="labelKpi"
								backgroundColor="black"
								effect="solid"
							/>
							<i
								data-for={idTooltipOfPlayOp}
								className="fas fa-play"
								data-tip="Play the operation"
							></i>
						</div>
					</>
				);
			}
		}
	};
	useEffect(() => {
		statusPermToRender();
		statusPermToRefresh();
	}, [permissionsUser]);

	const getGoToEdit = () => {
		const query = [];
		query.push('from=Onsite/Planning');
		if( $routeParams && $routeParams.ka ){
			query.push('ka=' + $routeParams.ka);
			query.push('ku=' + $routeParams.ku);
		}
		const isClassicEditor = !campaign.editor || campaign.editor === 'classic';
		if (isClassicEditor){
			const url = `Campaigns/Create/${campaign.id}`;
			const queryString = '?'+query.join('&');
			return (<Btn
				color="secondary"
				style="outline"
				size="xs"
				icon="fas fa-pen"
				data-for={idTooltip}
				data-tip={t('CampaignItem.EditCampaign')}
				href={url+queryString}
			/>);
		}
		else{
			const url = '/Campaigns/Editor/'+campaign.id;
			const toQueryString = (moreQuery) => '?'+[...query, ...moreQuery].join('&');
			const isCodePatch = campaign.editor && campaign.editor == 'patch/code';
			const isGraphPatch = campaign.editor && campaign.editor == 'patch/graph';
			return (<>
				<Dropdown
					isOpen={editDropdownIsOpen}
					setIsOpen={(e) => setEditDropdownIsOpen(true)}
					onHide={(e) => setEditDropdownIsOpen(false)}
					button={
						<Btn
							style="outline"
							color="secondary"
							arrow="true"
							size="xs"
							icon="fas fa-pen"
						/>
					}
				>
					<ul className="listbox">
						<li>
							<a className="listbox_item" href={url+toQueryString(['edit=format'])}>
								{isCodePatch &&
									<>
										<i className="fas fa-code listbox_item_icon"></i>
										Edit code
									</>
								}
								{isGraphPatch &&
									<>
										<i className="fas fa-fill-drip listbox_item_icon"></i>
										Edit graphic patch
									</>
								}
								{!isCodePatch && !isGraphPatch &&
									<>
										<i className="fas fa-palette listbox_item_icon"></i>
										Edit design &amp; content
									</>
								}
							</a>
						</li>
						<li className="hr"></li>
						<li>
							<a className="listbox_item" href={url+toQueryString(['edit=triggers'])}>
								<i className="fas fa-bolt listbox_item_icon"></i>
								Edit display rules
							</a>
						</li>
						<li className="hr"></li>
						<li>
							<a className="listbox_item" href={url+toQueryString(['edit=info'])}>
								<i className="fas fa-info-circle listbox_item_icon"></i>
								Edit campaign
							</a>
						</li>
					</ul>
				</Dropdown>
			</>);
		}
	};

  
	const getDuplicatedCampaignEditorPath = (campaignId) => { // this function could be moved to context (it is duplicated)
		const isClassicEditor = !campaign.editor || campaign.editor === 'classic';
		if (isClassicEditor) {
			let url = '/Campaigns/Create/' + campaignId;
			if ($routeParams && $routeParams.ka && isAdmin) {
				url += '?ka=' + $routeParams.ka + '&ku=' + $routeParams.ku;
			}
			return url;
		}
		else {
			const url = '/Campaigns/Editor/' + campaignId;
			const query = [];
			query.push('from=Onsite/Dashboard');
			if ($routeParams && $routeParams.ka  && isAdmin) {
				query.push('ka=' + $routeParams.ka);
				query.push('ku=' + $routeParams.ku);
			}
			query.push('edit=info');
			return url + '?' + query.join('&');
		}
	};
	const getTransformedToNativeAppCampaignEditorPath = (campaignId) => {		
		const url = '/Campaigns/Editor/' + campaignId;
		const query = [];
		query.push('from=Onsite/Dashboard');
		if ($routeParams && $routeParams.ka  && isAdmin) {
			query.push('ka=' + $routeParams.ka);
			query.push('ku=' + $routeParams.ku);
		}
		query.push('edit=info');
		return url + '?' + query.join('&');
	};

	return (
		<Fragment>
			<div className="campaign_card">
				<ReactTooltip
					id={idTooltip}
					backgroundColor="black"
					effect="solid"
					className="labelKpi"
				/>
				<div className="flex flex flex_align_baseline">
					<div className="flex_item_full">
						<span className="campaign_card_name">
							{canAccessToStatistics ? (
								<Link to={canAccessToStatistics}>{campaign.name}</Link>
							) : (
								<>{campaign.name}</>
							)}
						</span>
						{devices.includes('D_SMARTPHONE') && (
							<>
								<ReactTooltip
									id={idTooltipOfMobile}
									className="labelKpi"
									backgroundColor="black"
									effect="solid"
								/>
								<i
									data-for={idTooltipOfMobile}
									className="fas fa-mobile-alt grey_2"
									data-tip="Mobile"
								></i>
							</>
						)}
						{devices.includes('D_PC') && (
							<>
								<ReactTooltip
									id={idTooltipOfDesktop}
									className="labelKpi"
									backgroundColor="black"
									effect="solid"
								/>
								<i
									data-for={idTooltipOfDesktop}
									className="fas fa-desktop grey_2"
									data-tip="Desktop"
								></i>
							</>
						)}

						<div className="campaign_card_date">
							{statusOperation.id === 'planned'
								? dateStartOperationToLocal.format('DD/MM/YYYY') +
                  ' at ' +
                  dateStartOperationToLocal.format('HH:mm')
								: dateStartOperationToLocal.format('DD/MM/YYYY')}
							<i className="fas fa-arrow-right"></i>
							{campaign.endDate ? (
								<>
									<>
										{dateEndOperationToLocal.format('DD/MM/YYYY') +
                      ' at ' +
                      dateEndOperationToLocal.format('HH:mm')}
									</>
									<>
										{' '}
                    (
										{dateEndOperationToLocal.diff(
											dateStartOperationToLocal,
											'days'
										) > 1
											? dateEndOperationToLocal.diff(
												dateStartOperationToLocal,
												'days'
											) + ' days'
											: dateEndOperationToLocal.diff(
												dateStartOperationToLocal,
												'days'
											) + ' day'}
                    )
									</>
								</>
							) : (
								<span className="grey_2 fw_normal">{t('CampaignInformations.noEndDate')}</span>
							)}
							<ReactTooltip
								id={idTooltipOfEarth}
								backgroundColor="black"
								effect="solid"
							/>
							<i
								data-for={idTooltipOfEarth}
								className="fas fa-globe s_12 grey_3"
								data-tip={timeZoneUser}
							>
								{' '}
							</i>
						</div>
					</div>

					<div className="flex_item_fix">
						<CampaignStatus campaign={campaign}
							setParentDropdownIsOpen={setDropdownIsOpen} />
						{/*<ReactTooltip id={idTooltipOfTrackingMode} className="labelKpi" backgroundColor='black' effect='solid' />
                        {campaign.trackingMode === "MARKETING" ? <>
                            <i className="fas fa-cookie-bite" data-for={idTooltipOfTrackingMode} data-tip="Tracking mode marketing"></i> </> :
                            <> <i className="fas fa-cookie-bite" data-for={idTooltipOfTrackingMode} data-tip="Tracking mode functional"></i> </>
                        }*/}
					</div>
				</div>

				{campaign.description && (
					<div className="campaign_card_desc">
						{campaign.description.split('\n').map((item, key) => {
							return (
								<Fragment key={key}>
									{item}
									<br />
								</Fragment>
							);
						})}
					</div>
				)}

				<ul
					className="campaign_label_list mt_5 inline"
					onClick={(e, bool, id) => {
						openModalCampaignLabels(true, campaign);
					}}
				>
					{campaign.labels.map((label, key) => (
						<CampaignLabel key={key} label={label} />
					))}
				</ul>
				<div className="campaign_card_footer">
					<div className="btn_group">
						{/*{statusAction}*/}

						{!campaign.campaignDebugLinks || (campaign.campaignDebugLinks.length == 0 && (
							<Btn
								message="Preview"
								target="_blank"
								style="outline"
								color="secondary"
								size="xs"
								disabled={true}
							/>
						))}
						{campaign.campaignDebugLinks && campaign.campaignDebugLinks.length == 1 && (
							<Btn
								message="Preview"
								href={campaign.campaignDebugLinks[0].debugLink}
								target="_blank"
								style="outline"
								color="secondary"
								size="xs"
							/>
						)}
						{campaign.campaignDebugLinks && campaign.campaignDebugLinks.length > 1 && (
							<Dropdown
								isOpen={debugDropdownIsOpen}
								setIsOpen={(e) => setDebugDropdownIsOpen(true)}
								onHide={(e) => setDebugDropdownIsOpen(false)}
								button={
									<Btn
										style="outline"
										color="secondary"
										arrow="true"
										size="xs"
										message="Preview"
									/>
								}
							>
								<ul className="listbox">
									{campaign.campaignDebugLinks
										.sort((v1, v2) =>
											v1.variation.localeCompare(v2.variation)
										)
										.map((debug, i) => (
											<Fragment key={i}>
												<li>
													<a
														href={debug.debugLink}
														target="_blank"
														className="listbox_item" rel="noreferrer"
													>
                              Variation{' '}
														{
															[
																'A',
																'B',
																'C',
																'D',
																'E',
																'F',
																'G',
																'H',
																'I',
																'J',
															][i]
														}
													</a>
												</li>
												<li className="hr"></li>
											</Fragment>
										))}
								</ul>
							</Dropdown>
						)}

						{hasPermission('CAMPAIGNS_CAN_CREATE') &&
              getGoToEdit()}

						{isAdmin && refreshButton}

						<DropDownAction
							campaign={campaign}
							dropdownIsOppen={actionsDropdownIsOppen}
							setDropdownIsOppen={setActionsDropdownIsOppen}
							setParentDropdownIsOpen={setDropdownIsOpen}
							getDuplicatedCampaignEditorPath={getDuplicatedCampaignEditorPath}
							getTransformedToNativeAppCampaignEditorPath={getTransformedToNativeAppCampaignEditorPath}
						/>
					</div>
         
					{campaign.screenShots.length > 0 && (
						<CampaignItemExtend
							screenShots={campaign.screenShots}
							campaign={campaign}
						/>
					)}
        
				</div>
			</div>
		</Fragment>
	);
}
