import React, { useEffect, useState } from 'react';
import './Header.css';
import InputCustom from '../../../Components/InputCustom';
import Btn from '../../../Components/Btn';
import Confirm from '../../../Components/Confirm';
import useCampaignStatus from '../../../Hooks/useCampaignStatus';
import { CampaignsContext } from '../context';
import Breadcrumb from '../../../Components/Breadcrumb';
import EditorHeader from '../../../Components/EditorHeader';
import ReactTooltip from 'react-tooltip';
import {useTranslation} from 'react-i18next';
import renderJsxTag from '../../../Translation/translation-utils';
import Dropdown from '../../../Components/Dropdown';
import SectionMessage from '../../../Components/SectionMessage';
import Link from '../../../Components/Link';

export default function HeaderCampaigns() {
	const { t, i18n } = useTranslation(['common']);
	
	const {
		step,
		mode,
		setStep,
		canSaveEdition,
		canGoNextOrSave,
		isABTestingActive,
		handleSaveCampaign,
		handleCloseEditor,
		saveError,
		isSaving,
		campaign,
		setCampaign,
		systemServices,
		editorMode,
		editorState,
		i18nContext,
		variations,
		externalAppSetup,
		refreshExternalAppSetupInfo
	} = CampaignsContext();
		

	const [closeConfirmIsOpen, setCloseConfirmIsOpen] = useState(false);
	const [btnCancelAndQuit, setbtnCancelAndQuit] = useState(<></>);
	const [AppNotInstalledIsOpen, setAppNotInstalledIsOpen] = useState(false);
	const [AppNotActivatedIsOpen, setAppNotActivatedIsOpen] = useState(false);
	const status = useCampaignStatus(campaign);
	
	const getCampaignType = () => {
		if (editorMode === 'patch/graph') return 'graph_patch';
		if (editorMode === 'patch/code') return 'code_patch';
		if (editorMode === 'abTest') return 'abTest';
		return 'classic';
	};

	const getStepOneTitle = (type) => {
		if (type === 'abTest') return t('nav.experiment');
		if (type === 'code_patch') return 'Code';
		if (type === 'graph_patch') return 'Patch';
		return t('nav.design');
	};
	const campaignType = getCampaignType();
	const stepOneTitle = getStepOneTitle(campaignType);
	

	function getBreadCrumbElements () {
		return [
			{ index: 1, toGo: 1, title: stepOneTitle },
			{ index: 2, toGo : 2, title: t('nav.DisplayRules')},
			{ index: 3, toGo : 3, title: t('nav.Information') },
		];
		// switch (campaignType) {
		// 	case 'classic':
		// 	case 'code_patch':
		// 	case 'graph_patch' :
		// 		return [
		// 			{ index: 1, toGo: 1, title: stepOneTitle },
		// 			{ index: 2, toGo : 2, title: t('nav.DisplayRules')},
		// 			{ index: 3, toGo : 3, title: t('nav.Information') },
		// 		];
		// 	case 'abTest' : 
		// 		return [
		// 			{ index: 1, toGo: 1, title: 'Experiment' },
		// 			{ index: 2, toGo: 3, title: t('nav.Information')},
		// 		];
		// }
	}
	//const stepTwoTitle = features.includes('emerch-demo') ? 'Segmentation' : 'Display rules';

	
	function handleCampaignNameChanged(val) {
		let newCampaign = { ...campaign };
		newCampaign.Name = val;
		setCampaign(newCampaign);
	}


	const ifValid = (onNext) => {
		if (mode !== 'edition' ? canGoNextOrSave : canSaveEdition) {
			onNext();
		} else {
			for (let i = 0; i < saveError.length; i++) {
				const error = saveError[i];
				const message = error.error;
				systemServices.showError(message);
			}
		}
	};

	useEffect(() => {
		setbtnCancelAndQuit(<>
			<Btn
				onClick={() => setCloseConfirmIsOpen(true)}
				message={t('button.Cancel')}
				style="ghost"
				color="secondary"
			/>

			{mode === 'creating' &&
				<Btn
					onClick={() => ifValid(async () => await handleSaveCampaign())}
					disabled={isSaving || !canGoNextOrSave || externalAppSetup?.installationStatus == 'Uninstalled'}
					message={t('button.saveAsDraft')}
				/>
			}
			{mode === 'edition' &&
				<Btn
					onClick={() => ifValid(async () => await handleSaveCampaign()) && externalAppSetup?.installationStatus !== 'Uninstalled'}
					disabled={isSaving || !canSaveEdition || externalAppSetup?.installationStatus == 'Uninstalled'}
					message={t('button.save')}
				/>
			}
		</>);
	}, [step, campaign, editorState, canGoNextOrSave, canSaveEdition, isSaving, variations,externalAppSetup]);
	
	useEffect(() => {
		refreshExternalAppSetupInfo();
	}, [AppNotActivatedIsOpen]);

	return (
		<>
			<ReactTooltip
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={600}
				data-multiline={true}
			/>

			{externalAppSetup?.installationStatus == 'Uninstalled' &&  
				<section className='section no_bottom_pad'>
					<SectionMessage
						type="alert"
						title="The BEYABLE subscription is canceled"
						text="To continue using BEYABLE, please subscribe to a plan from your Prestashop back office."
					/>
				</section>
			}
			<EditorHeader
				hasBorder={!(step === 1 && isABTestingActive && campaignType != 'abTest')}
				backTitle={t('nav.close')}
				backOnClick={(e) => setCloseConfirmIsOpen(true)}
				left={
					<>
						<div className='flex'>
							<span className="campaign_editor_header_name flex_item_auto" data-tip="Edit campaign name">
								<InputCustom
									onChange={(e) => handleCampaignNameChanged(e.target.value)}
									value={campaign.Name}
									type="text"
									style="ghost"
									placeholder={t('nav.campaignName')}
									selectOnFocus={true}
									autogrow={true}
									maxWidth={500}
									className="has_clue"
								/>
							</span>
							{status && status.Key &&
								<span className="campaign_status_tag ml_5" data-tip={t('nav.CampaignStatus')}>
									<span className={'campaign_status_tag_color ' + status.Key}></span>
									<span className="campaign_status_tag_name">{status.Label}</span>
								</span>
							}
						</div>
						{campaign.Name.length > 150 &&
							<div className="s_12 mt_4">
								{t('nav.campaignNameMust')}</div>
						}
					</>
				}
				center={
					<Breadcrumb
						itemOfBreadcrumb={getBreadCrumbElements()}
						onClick={(indexToGo) => setStep(indexToGo)}
						stepSelected={step}
					/>
				}
				right={
					<>
						{externalAppSetup?.actionNeeded == 'InstallApp' &&
							<Dropdown
								isOpen={AppNotInstalledIsOpen}
								toggle={(s) => setAppNotInstalledIsOpen(s)}
								button={
									<Btn
										message="Check app installation"
										color="warning"
										icon="fas fa-exclamation-triangle"
										style="ghost"
										horizontalSize="xxs"
									/>
								}
							>
								<section className='section'>
									<SectionMessage
										type="warning"
										appearance='ghost'
										noMargin={true}
										text={
											<>
												<strong>BEYABLE Header bars</strong> app is not fully installed on Shopify.<br/>
												Please go to your back-office and check the app installation.
											</>
										}
									/>
								</section>
							</Dropdown>
						}
						{externalAppSetup?.actionNeeded == 'ActivateApp' && externalAppSetup.actionLink && 
							<Dropdown
								isOpen={AppNotActivatedIsOpen}
								toggle={(s) => setAppNotActivatedIsOpen(s)}
								button={
									<Btn
										message="Enable app on Shopify"
										color="warning"
										icon="fas fa-exclamation-triangle"
										style="ghost"
										horizontalSize="xxs"
									/>
								}
							>
								<section className='section'>
									<SectionMessage
										type="warning"
										appearance='ghost'
										noMargin={true}
										text={
											<>
												<strong>BEYABLE Header bars</strong> app is not enabled on Shopify.<br/>
												Please go to your <Link href={externalAppSetup.actionLink} target="_blank">back-office</Link> and enable the app as the following picture.
												<div className="mt_15">
													<img width="345" height="212" src="/Assets/shopify-enable-app.png" alt="Shopify back-office" />
												</div>
											</>
										}
									/>
								</section>
								<section className='section_footer al_right'>
									<Btn
										message="Go to Shopify"
										icon="fas fa-external-link-square-alt"
										iconPosition="after"
										href={externalAppSetup.actionLink}
										target="_blank"
									/>
								</section>
							</Dropdown>
						}

						{btnCancelAndQuit}
					</>
				}
			/>
			<Confirm
				isOpen={closeConfirmIsOpen}
				onClose={(e) => setCloseConfirmIsOpen(false)}
				title={t('nav.closeCampaign')}
				text={t('nav.areYouSure')}
				cancelText={t('nav.Stay')}
				confirmText={t('nav.quitWithout')}
				confirmCallback={(e) => handleCloseEditor()}
			/>
		</>
	);
}