import gql from 'graphql-tag'
const queryNext = gql`
query($accountId: String, $tenant: String,$cursor: String, $total: Int){
account(id:$accountId, tenant:$tenant){
  productCatalogue{
      products(first:$total, after: $cursor){
        pageInfo{
            hasNextPage,
            hasPreviousPage,
            startCursor,
            endCursor
        },
        totalCount,
        edges{
          cursor,
          node{
            id
            name
            description
            categories{type, name, value}
            tags{tag,value,numValue,dateValue}
            url
            urlThumbnail
            price
            globalStockQuantity
            unitPriceWithRebate
            productVariants{stockQuantity, productVariantId}
          }
      }
    }
    }
}}
`;

const queryPrevious = gql`
query($accountId:String,$tenant: String, $cursor: String, $total: Int){
account(id: $accountId, tenant:$tenant){
  productCatalogue{
      products(last: $total, before: $cursor){
        pageInfo{
            hasNextPage,
            hasPreviousPage,
            startCursor,
            endCursor
        },
        totalCount,
      edges{
        cursor,
        node{
          id
          name
          description
          categories{type, name, value}
          tags{tag,value,numValue,dateValue}
          url
          urlThumbnail
          price
          globalStockQuantity
          unitPriceWithRebate
          productVariants{stockQuantity, productVariantId}
        }
      }
      }
}
}}
`;
const querySearchProductNext = gql`
query($accountId: String,$tenant: String, $cursor: String,$searchValue: String,$total: Int){
account(id: $accountId, tenant:$tenant){
  productCatalogue{
      products(first:$total, where:{or: [{name:{contains:$searchValue}}, {id:{contains:$searchValue}}, {description:{contains:$searchValue}}] } , after:$cursor){
        pageInfo{
            hasNextPage,
            hasPreviousPage,
            startCursor,
            endCursor
        },
        totalCount,
      edges{
        cursor,
        node{
          id
          name
          description
          tags{tag,value,numValue,dateValue}
          categories{type, name, value}
          url
          urlThumbnail
          price
          globalStockQuantity
          unitPriceWithRebate
          productVariants{stockQuantity, productVariantId}
        }
      }
      }
}
}}
`;
const querySearchProductPrevious = gql`
query($accountId: String, $tenant: String, $cursor: String,$searchValue: String,$total: Int){
account(id: $accountId,tenant:$tenant){
  productCatalogue{
        products(last:$total, where:{or: [{name:{contains:$searchValue}}, {id:{contains:$searchValue}}, {description:{contains:$searchValue}}] } , before:$cursor){
          pageInfo{
              hasNextPage,
              hasPreviousPage,
              startCursor,
              endCursor
          },
          totalCount,
        edges{
          cursor,
          node{
            id
            name
            description
            tags{tag,value,numValue,dateValue}
            categories{type, name, value}
            url
            urlThumbnail
            price
            globalStockQuantity
            unitPriceWithRebate
            productVariants{stockQuantity, productVariantId}
          }
        }
        }
}
}}
`;
const queryAdvancedSearchPreviousProduct = gql`
query($accountId: String, $tenant: String, $cursor: String,$total: Int, $where: ProductFilterInput){
account(id: $accountId,tenant:$tenant){
  productCatalogue{
      products(last:$total, where:$where , before:$cursor){
        pageInfo{
            hasNextPage,
            hasPreviousPage,
            startCursor,
            endCursor
        },
        totalCount,
      edges{
        cursor,
        node{
          id
          name
          description
          tags{tag,value,numValue,dateValue}
          categories{type, name, value}
          url
          urlThumbnail
          price
          globalStockQuantity
          unitPriceWithRebate
          productVariants{stockQuantity, productVariantId}
        }
      }
      }
}
}}
`;
const queryAdvancedSearchNextProduct = gql`
query($accountId: String, $tenant: String, $cursor: String,$total: Int, $where: ProductFilterInput){
account(id: $accountId, tenant:$tenant){
  productCatalogue{
      products(first:$total, where:$where , after:$cursor){
        pageInfo{
            hasNextPage,
            hasPreviousPage,
            startCursor,
            endCursor
        },
        totalCount,
      edges{
        cursor,
        node{
          id
          name
          description
          tags{tag,value,numValue,dateValue}
          categories{type, name, value}
          url
          urlThumbnail
          price
          globalStockQuantity
          unitPriceWithRebate
          productVariants{stockQuantity, productVariantId}
        }
      }
      }
}
}}
`;
export { queryNext, queryPrevious, querySearchProductNext, querySearchProductPrevious, queryAdvancedSearchPreviousProduct, queryAdvancedSearchNextProduct }