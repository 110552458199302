export const traitButtonWidth = {
	noLabel: false,
	manualValue: 200,
	createInput({ trait }) {
		const that = this;
		const componentType = this.target.attributes.type;
		const labelAuto = componentType == 'button' ? 'Automatique (text width)' : 'Automatique (image width)';
		const selectOptions = [
			{
				label: labelAuto,
				value: 'auto',
			},
			{
				label: 'Parent width (100%)',
				value: '100%'
			},
			{
				label: 'Manual',
				value: 'manual'
			}
		];

		// Create select
		const select = document.createElement('select');
		selectOptions.forEach(opt => {
			const optionsEl = document.createElement('option');
			optionsEl.setAttribute('value', opt.value);
			optionsEl.innerText = opt.label;
			select.appendChild(optionsEl);
		});
		select.addEventListener('change', ev => this.onChange(ev));

		const label = document.createElement('label');
		label.classList.add('custom_input');
		label.classList.add('custom_select');
		label.classList.add('w_auto');
		label.appendChild(select);

		// Create input
		const inputTpl = `
			<div class="flex range_slider_wrapper mt_10">
				<div class="flex_item_full">
					<input type="range" min="10" max="400" step="1" class="range_slider">
				</div>
				<div class="flex_item_fix ml_10">
					<label class="custom_input w_xxs m">
						<input type="number" min="10" max="400">
						<span class="custom_input_unit">px</span>
					</label>
				</div>
			</div>
		`;

		const wrapper = document.createElement('div');
		wrapper.appendChild(label);
		wrapper.insertAdjacentHTML('beforeend', inputTpl);

		const inputSlider = wrapper.querySelector('input[type="range"]');
		const inputNumber = wrapper.querySelector('input[type="number"]');
		const inputWrapper = wrapper.querySelector('.range_slider_wrapper');

		inputSlider.setAttribute('value', this.manualValue);
		inputNumber.setAttribute('value', this.manualValue);

		inputNumber.addEventListener('input', function(ev){
			inputSlider.value = ev.target.value;
			that.updateSlider(ev.target.value);
			that.onChange(ev);
		});

		inputSlider.addEventListener('input', function(ev){
			inputNumber.value = ev.target.value;
			that.updateSlider(ev.target.value);
			that.onChange(ev);
		});

		this.wrapper = wrapper;
		this.select = select;
		this.inputNumber = inputNumber;
		this.inputSlider = inputSlider;
		this.inputWrapper = inputWrapper;
		this.name = trait.id;
		return wrapper;
	},
	onEvent({ component }) {
		const selectValue = this.select.value;
		const inputValue = this.inputSlider.value;
		const isManual = selectValue != 'auto' && selectValue != '100%';
		if (isManual) {
			component.set(this.name, inputValue + 'px');
			this.inputWrapper.style.display = '';
			this.updateSlider(inputValue);
		} else {
			component.set(this.name, selectValue);
			this.inputWrapper.style.display = 'none';
		}
	},
	onUpdate({ component }) {
		const value = component.get(this.name);
		const isManual = value != 'auto' && value != '100%';
		if (isManual) {
			const num_value = value.split('px').join('');
			this.select.value = 'manual';
			this.inputNumber.value = num_value;
			this.inputSlider.value = num_value;
			this.updateSlider(num_value);
			this.inputWrapper.style.display = '';
		} else {
			this.select.value = value;
			this.inputWrapper.style.display = 'none';
		}
	},
	updateSlider(value) {
		this.inputSlider.style.setProperty('--value', value);
		this.inputSlider.style.setProperty('--min', 10);
		this.inputSlider.style.setProperty('--max', 400);
	}
};
