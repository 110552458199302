import React, { useState, useEffect } from 'react';
import { getCategoryPicto } from '../Icons';
import { useGroupSortingContext } from './contextGroupSorting';
import { AttributeOrScoreFiltering } from '../../EntityTypes/EntityTypes';
import {List, ListItem} from '../../../../Components/List';
import Picto from '../../../../Components/Picto';
import { Article } from '../../../../Components/Article/Article';

type GroupSortingModalListType = {
	availableGroupings: AttributeOrScoreFiltering[];
}

export default function GroupSortingModalList({ availableGroupings }: GroupSortingModalListType) {

	const [noData, setNoData] = useState(false);

	const {
		handleSelectAttribute,
	} = useGroupSortingContext();

	useEffect(() => {
		setNoData(availableGroupings.length === 0);
	}, [availableGroupings]);

	return (
		<>
			<>
				{availableGroupings &&
					availableGroupings.map((x, i) => {
						const picto = getCategoryPicto(x.name);
						return (
							<Article key={i} hasPadding={false} title={<div className="trigger_store_category_label">{x.name}</div>}>
								<List size="s">
									{x.itemList.map((value, i) =>
										<ListItem
											key={i}
											onClick={() => handleSelectAttribute(value)}
											picto={
												<Picto
													size="s"
													icon={picto.icon}
													color={picto.color}
												/>
											}
											pictoHasMargin={true}
											text={value.name}
											hasArrow={true}
										/>
									)}
								</List>
							</Article>
						);
					})
				}
			</>
			{noData &&
				<p>No attribute available</p>
			}
		</>
	);
}
