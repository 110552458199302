import React ,{useState} from  'react';
import Modal from '../../../Components/Modal';
import ModalBody from '../../../Components/ModalBody';
import ModalFooter from '../../../Components/ModalFooter';
import ModalHeader from '../../../Components/ModalHeader';
import Btn from '../../../Components/Btn';
import SelectCustom from '../../../Components/SelectCustom';
import {useProductFeedConfigContext} from '../context/ProductFeedConfigContext';

export default function ProductFeedCopyFeedModal({modalIsOppen,
	setModalIsOppen,handleCopyFeedByid, tenant}) {
	const {tenantListKey} = useProductFeedConfigContext();
	const [tenanatSelectedTocopy, setTenantSelectedToCopy] = useState();
	function handleSelectATenant(event){
		const value = event.target.value;
		setTenantSelectedToCopy(value);
	}
	function handleCopy(){
		handleCopyFeedByid(tenanatSelectedTocopy);
	}
	return (
		<Modal
			isOpen={modalIsOppen}
			width="500"
			onClose={() => setModalIsOppen(false) }
		>
			<ModalHeader>
				Copy feed to another tenant
			</ModalHeader>
			<ModalBody>
				<SelectCustom
					optionsList={tenantListKey && tenantListKey.filter(t=> t.value !== tenant)}
					name="tenant"
					label='Select a destination tenant'
					onChangeFunction={(e) => handleSelectATenant(e)}
					defaultValueDisabled={{
						value: 'choose',
						label: 'Choose a tenant',
						isDisabled: true
					}}
					value={tenanatSelectedTocopy ? tenanatSelectedTocopy : 'choose'}
					size="l"
					fullWidth={true}
				/>
			</ModalBody>
			<ModalFooter hasBorder={true}
				primaryAction={
					<Btn
						onClickFunction={()=>handleCopy()}
						message="Confirm"
						color="primary"
					/>
				}
				secondaryAction={
					<Btn
						onClickFunction={() => setModalIsOppen(false)}
						message="Cancel"
						color="secondary"
						style="ghost"
					/>
				}
			>
				<Btn
					message="Add tenant"
					icon="fas fa-plus fa-sm"
					style="reverse"
					horizontalSize="xs"
					onClick={() => alert('TODO')}
				/>
			</ModalFooter>
		</Modal>
	);
}
