import { createCheckbox } from './utils';

export const traitResponsive = {
	noLabel: true,
	createInput({ trait }) {
		const [desktopWrapper, desktopCheckbox] = createCheckbox('hide_desktop', 'Hide on desktop');
		const [mobileWrapper, mobileCheckbox] = createCheckbox('hide_mobile', 'Hide on mobile');

		desktopCheckbox.addEventListener('change', ev => this.onChange(ev));
		mobileCheckbox.addEventListener('change', ev => this.onChange(ev));

		const wrapper = document.createElement('div');
		wrapper.appendChild(desktopWrapper);
		wrapper.appendChild(mobileWrapper);
		
		this.wrapper = wrapper;
		this.name = trait.id;
		this.desktopCheckbox = desktopCheckbox;
		this.mobileCheckbox = mobileCheckbox;
		return wrapper;
	},
	onEvent({ component }) {
		if (this.desktopCheckbox.checked) {
			component.addClass('by_hide_desktop');
		} else {
			component.removeClass('by_hide_desktop');
		}
		if (this.mobileCheckbox.checked) {
			component.addClass('by_hide_mobile');
		} else {
			component.removeClass('by_hide_mobile');
		}
	},
	onUpdate({ component }) {
		const classes = component.getClasses();
		if (classes.includes('by_hide_desktop')) {
			this.desktopCheckbox.checked = true;
		}
		if (classes.includes('by_hide_mobile')) {
			this.mobileCheckbox.checked = true;
		}
	},
};
