import React, { useState, useEffect } from 'react';
import InputCustom from '../../../Components/InputCustom';
import SelectCustom from '../../../Components/SelectCustom';
import FieldRow from '../../../Components/FieldRow';

const listType = [
	{
		value: 'NoAuth',// 0
		label: 'No Auth',
	},
	{
		value: 'Token', //2
		label: 'Token',
	},
	{
		value: 'ApiKey', // 1
		label: 'Api Key',
	},
];
const listTypeInput = [
	{
		key: 'NoAuth',
		label: 'No auth',
		inputlist: [
		]
	},
	{
		key: 'Token',
		inputlist: [
			{
				name: 'authorizationScheme',
				label: 'Scheme',
				placeholder: 'Bearer',
				type: 'select',
				value: 'Bearer'
			},
			{
				name: 'authorizationCredentials',
				label: 'Token',
				value: '',
				placeholder: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImhUSk1JeTBqVjJMSVZpMV8tcEdPVSJ9.eyJodHRwOi8vc2NoZW1hc',
				type: 'textarea'
			},
		]
	},
	{
		key: 'ApiKey',
		label: 'Api Key',
		inputlist: [
			{
				name: 'apiKeyHeader',
				label: 'Api Header',
				value: '',
				placeholder: 'Subscription-Key',
				type: 'text'
			},
			{
				name: 'apiKey',
				label: 'Api Key',
				value: '',
				placeholder: '45465465465KL',
				type: 'textarea'
			},
		]
	},
];
const tokenType = [
	{
		value: 'Bearer',
		label: 'Bearer',
	},
	{
		value: 'Basic',
		label: 'Basic',
	},
];
export default function TypeAuthorization({
	type,
	handleSelectType,
	handleChangeIdentifier,
	identifier,
	hasErrorOnInputs,
	disabled = false
}) {

	if (!identifier) {
		return <></>;
	}
	const [inputList, setInputList] = useState(<></>);

	function getInputAuthorization() {
		const typeKey = listTypeInput.find(iterator => iterator.key == type);
		if (!typeKey) {
			return <></>;
		}

		const constructInputList = typeKey.inputlist.map(el => {
			const errorMessage = hasErrorOnInputs.find(err => err.name === el.name);
			if (el.type === 'select') {
				return <FieldRow label={el.label} key={el.name}>
					<SelectCustom
						optionsList={tokenType}
						name={el.name}
						value={identifier[el.name]}
						onChange={(e) => handleChangeIdentifier(e)}
						autoWidth={true}
						disabled={disabled}
					/>
				</FieldRow>;
			}
			if (el.type !== 'select') {
				return (
					<FieldRow label={el.label} key={el.name} labelAlign={el.type=='textarea' ? 'top' : 'middle'}>
						<InputCustom
							type={el.type}
							rows={2}
							autogrow={el.type=='textarea'}
							name={el.name}
							placeholder={el.placeholder}
							value={identifier[el.name]}
							onChange={(e) => handleChangeIdentifier(e)}
							fullWidth={true}
							isAnError={errorMessage ? errorMessage.message : ''}
							disabled={disabled}
						/>
					</FieldRow>
				);
			}	
		});
		return constructInputList;
	}
	useEffect(() => {
		if (type) {
			setInputList(getInputAuthorization());
		}
	}, [type, identifier, hasErrorOnInputs, disabled]);

	return (
		<>
			<FieldRow label="Type">
				<SelectCustom
					optionsList={listType}
					value={type}
					onChange={handleSelectType}
					autoWidth={true}
					disabled={disabled}
				/>
			</FieldRow>
			{inputList}
		</>
	);
}
