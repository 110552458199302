import React from 'react';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import Panel from '../../../../Components/Panel';
import RawFeedContainer from './RawFeedContainer';

export default function PanelRawFeed({rawFeed, panelRawFeedIsOpen, handleOpenClosePanelRawfeed}) {


	return (
		<Panel
			width="1200"
			isOpen={panelRawFeedIsOpen}
			onClose={() => handleOpenClosePanelRawfeed(false)}
			noClose={true}
		>
			<ModalHeader>
				<div className='flex'>
					<div className="flex_item_fix">
						<a className="panel_close panel_close_left" onClick={() => handleOpenClosePanelRawfeed(false)}></a>
					</div>
					<div className='flex_item_full'>Raw feed</div>
				</div>
			</ModalHeader>
			<ModalBody
				className='code_mirror_full'
				hasVerticalPadding={false}
				hasHorizontalPadding={false}>
				<RawFeedContainer rawFeed={rawFeed}/>
			</ModalBody>
		</Panel>
	);
}
