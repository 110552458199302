
const filterOption = [
    {
        key: 'TAGS',
        label: 'Beyable Tag',
        infos: '',
        icon: '../../../../Assets/Favicon_dark.png'
    },
    {
        key: 'CATEGORIES',
        label: 'Product feed',
        infos: '',
        icon: 'fas fa-box-open',
    },
    {
        key: 'PRODUCTS',
        label: 'Skus List',
        infos: '',
        icon: 'fas fa-list',
    },

]
export default filterOption;