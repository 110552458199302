import React, { createContext, useContext, useState, useEffect } from 'react';
import InsightsServices from '../../../Services/InsightsServices';
import SystemServices from '../../../Services/SystemServices';
import AuditServices from '../../../Services/AuditServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';

import {payload2} from '../Audit/fakeData';

const AuditContext = createContext();

function useAuditContext() {
	return useContext(AuditContext);
}

const ContextAuditProvider = (props) => {
	const routeParams = props.$routeParams;
	const AuthServices = props.AuthServices;
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		props.$routeParams
	);
	
	const insightsServices = new InsightsServices(
		props.$http,
		props.$routeParams,
		AuthServices
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const auditServices = new AuditServices(
		props.$http,
		accountId,
		AuthServices
	);
	
	const [stateAudit, setstateAudit] = useState(0);
	const [noTask, setnoTask] = useState(true);
	const [dataValues, setdataValues] = useState();
	const [taskId, settaskId] = useState();
	const [selectedPeriod, setselectedPeriod] = useState('month');
	const [resultTask, setresultTask] = useState();
	const [needToFetch, setNeedToFetch] = useState(false);

	const isDev = window.BEYABLE_env.BO_API_URL.includes('dev');


	function handleChangeStateAudit (newState) {
		setstateAudit(newState);
	}

	function handleGetAudit(){
		setstateAudit(1);
		auditServices.getNewReco(success => {
			settaskId(success.taskId);
			setnoTask(false);
			setstateAudit(1);
		},(err, err2) => {
			setnoTask(false);
			settaskId(err.taskId);
			systemServices.showError('An Error occured');
		});
	}

	function showDevData() {
		setdataValues(payload2);
		setnoTask(false);
		setstateAudit(2);
	}

	useEffect(()=>{
		if(routeParams && routeParams.id){
			handleGetAnalys(routeParams.id);
		}
		else{
			auditServices.getMostRecentRecos(rsp => {
				setnoTask(false);
				settaskId(rsp.taskId);
				if(rsp.status === 'Running'){
					setstateAudit(1);
				}else if (rsp.status === 'Failed'){
					setstateAudit(0);
				}else if (rsp.status === 'Succeded'){
					setstateAudit(2);
					setdataValues(rsp);
				}
				setresultTask(rsp);
			},(err,err2) => {
				if(err2.status === 404){
				// Empty state
					setnoTask(true);
				}else{
					setnoTask(false);
					systemServices.showError('An Error occured');
				}
			});
		}
	},[]);

	useEffect(()=>{
		props.$rootScope.$on('event:newNotification', function () {
			setNeedToFetch(true);
		});
	},[]);

	useEffect(()=>{
		if (needToFetch && taskId) {
			handleGetAnalys(taskId);
		}
	},[needToFetch, taskId]);

	function handleGetAnalys (taskId){
		auditServices.getRecoById(taskId, rsp => {
			setNeedToFetch(false);
			setnoTask(false);
			//setstateAudit(2);
			setresultTask(rsp);
			if(rsp.result){
				setdataValues(rsp);
				setstateAudit(2);
			}else{
				//setdataValues(payload);
				//setnoTask(true);
				setstateAudit(1);
			}
		},(err,err2) => {
			setNeedToFetch(false);
			if(err2.status === 404){
				setnoTask(true);
			}else{
				setnoTask(false);
				systemServices.showError('An Error occured');
			}
		});
	}

	return (
		<AuditContext.Provider
			value={{
				systemServices,
				insightsServices,
				accountId,
				handleChangeStateAudit,
				stateAudit,
				noTask,
				dataValues,
				handleGetAudit,
				taskId,
				//handleGetAnalys,
				selectedPeriod,
				setselectedPeriod,
				resultTask,
				isDev,
				showDevData
			}}
		>
			{props.children}
		</AuditContext.Provider>
	);
};

export default ContextAuditProvider;
export { useAuditContext };
