import React, { useState, useEffect } from 'react';
import Card from './Card';
import { isEmpty } from 'lodash';
import { useTrafficContext } from '../context/ContextTraffic';
import { useTranslation } from 'react-i18next';
import styles from './Card.module.css';

export const CardTrafficContainer = ({ 
	selectedTenant,
	metricTypeSessionsSelected,
}) => {

	const [t] = useTranslation(['kpi']);
	const {
		accountId,
		insightsServices,
		navFilter,
		deviceSelected,
		pageTypeSelected,
		intentionSelected,
		utmDimensionsSelected
	} = useTrafficContext();

	const dimensions= [
		{
			key : 'Device', 
			label: t('data.device')
		},
		{
			key : 'PageType', 
			label: t('data.pageType')
		},
		{
			key : 'EngagementLevel', 
			label: t('data.engagementLevel')
		}
	];
	const [cardsData, setCardsData] = useState({});

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!isEmpty(navFilter)) {
				getCardsData({});
			}}, 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [navFilter, selectedTenant, metricTypeSessionsSelected, deviceSelected, pageTypeSelected, intentionSelected, utmDimensionsSelected]);

	const getCardsData = () => {
		const payloadData = {
			'filters': [
				{dimension: 'Device',
					values: [...deviceSelected]
				},
				{dimension: 'PageType',
					values: [
						...pageTypeSelected
					]  },
				{dimension: 'EngagementLevel',
					values: [
						...intentionSelected
					]} 
			],
			'fromDate': navFilter.fromDate,
			'toDate': navFilter.toDate,
			'utmSource': utmDimensionsSelected.source,
			'utmCampaign': utmDimensionsSelected.campaign,
			'utmContent': utmDimensionsSelected.content,
			'utmMedium':  utmDimensionsSelected.medium,
			'metric': 'SessionsCount',
		};

		const successCb = (name) => (data) => {
			setCardsData((previousData) => {
				return {
					...previousData, [name]: {
						data: data && data.items || [],
						totalSum: data.totalSum,
						loading: false,
						isError: false
					}
				};
	
			});
		};

		const errorCb = (error, name) => {
			console.log('--Error--', error);
			setCardsData((previousData) => {
				return { ...previousData, [name]: { data: [], loading: false, isError: true } };
			});
		};

		const options = dimensions.reduce((acc, item) => {
			return [...acc, {...payloadData,dimension: item.key,loading: true, isError: false }];
		}, []);

		Promise.all(options.map(async (option) => {
			await insightsServices.getWebtrafficMetrics(accountId, option, successCb(option.dimension), err => errorCb(err, option.dimension));
		}));
	};

	return (
		<div className={styles.grid}>
			{dimensions.map((dimensionConfig) => {
				const data = cardsData[dimensionConfig.key];
				return (
					<Card
						dimensionKey={dimensionConfig.key}
						key={dimensionConfig.key}
						title={dimensionConfig.label}
						dataCard={data}
						metricTypeSessionsSelected={metricTypeSessionsSelected}
					/>
				);
			})}
		</div>
	);
};