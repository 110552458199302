
const campaignListParameters = [
    {
        key: 'CONFIGEMR',
        label: 'Email Remarketing',
        compare: 'hasEmailRemarketing',
        id: 1
    },

]
export default campaignListParameters;