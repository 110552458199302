import React, {useEffect, useState} from 'react';

import { react2angular } from 'react2angular';

export default function TranslationContext (props) {

	function checkIfIsAdmin () {
		const user =  props && props.AuthServices.GetAuthenticatedUser
		();
		if (user && user.Role === 'Admin') {
			return true;
		}else{
			return false;
		}
	}
	useEffect(()=>{
		const i18nL= localStorage.getItem('i18nL');
		if(i18nL){
			props.$timeout(function() { 
				props.$rootScope.$broadcast('event:changeLangI18next', i18nL);
			});
			// if(JSON.parse(i18nL) !== userLang ){
			// 	localStorage.setItem('i18nL', JSON.stringify(userLang));
			// }
		}
		if(!i18nL){
			const isAdmin = checkIfIsAdmin();
			// Force language inglish for test
			//let userLang = isAdmin ? (navigator.language || navigator.userLanguage) : 'en-EN'; 
			let userLang = 'en-EN'; 
			const arrayoLanguagesBeyableBo = ['en-US', 'fr-FR', 'en-EN'];
			if(!arrayoLanguagesBeyableBo.includes(userLang)){
				userLang = 'en-EN';
			}
			props.$timeout(function() { 
				props.$rootScope.$broadcast('event:changeLangI18next', userLang);
			});
			localStorage.setItem('i18nL', JSON.stringify(userLang));
		}
		
	},[props]);
	return (
		<>
			{props.children}
		</>
	);
}
angular
	.module('beyableSaasApp.TranslationContext',[])
	.component('translation', react2angular(TranslationContext,
		['i18n',],['$http', '$rootScope', '$timeout', '$routeParams', '$location', '$scope', 'AuthServices']));