import React from 'react';
import commonStyles from '../ProductFeed.module.css';
import editionStyles from '../Edition/ProductFeedEdition.module.css';
import {  getFeedTypePictoClass} from '../utils';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';

export function FeedCardHeader({
	title = 'Feed settings',
	hasMargin = true
}) {
	const {
		productFeedObject,
		getFeedTypeName
	} = useProductFeedEditionContext();
	const pictoClass = getFeedTypePictoClass(productFeedObject?.type);
	return (
		<div className={hasMargin ? 'flex mb_30' : 'flex'}>
			<div className='flex_item_fix'>
				<div className={editionStyles.card_picto}>
					<div className={commonStyles.picto + ' ' + commonStyles['picto_' + pictoClass]}></div>
				</div>
			</div>
			<div className='flex_item_full overflow_hidden'>
				<div className={editionStyles.card_subtitle}>{getFeedTypeName(productFeedObject?.type)}</div>
				<div className={editionStyles.card_title}>{title}</div>
			</div>
		</div>
	);
};

export function FeedCardHeaderMain() {
	const {
		productFeedObject,
		getFeedTypeName
	} = useProductFeedEditionContext();
	const pictoClass = getFeedTypePictoClass(productFeedObject?.type);
	return (
		<div className='flex'>
			<div className='flex_item_fix'>
				<div className={editionStyles.card_picto}>
					<div className={commonStyles.picto + ' ' + commonStyles['picto_' + pictoClass]}></div>
				</div>
			</div>
			{productFeedObject &&
				<div className='flex_item_full overflow_hidden'>
					{productFeedObject.type && 
						<div className={editionStyles.card_title}>{getFeedTypeName(productFeedObject.type)}</div>
					}
					{productFeedObject.resourceIdentifier && 
						<div className={editionStyles.card_subtitle_light}>
							{productFeedObject.resourceIdentifier}
						</div>
					}
				</div>
			}
		</div>
	);
}
