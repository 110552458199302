import i18next from 'i18next';
import account_home_en from '../Translation/jsonfiles/en/account_home_en.json';
import account_home_fr from '../Translation/jsonfiles/fr/account_home_fr.json';
import campaign_react_en from '../Translation/jsonfiles/en/campaign_react_en.json';
import campaign_react_fr from '../Translation/jsonfiles/fr/campaign_react_fr.json';
import inspirations_panel_en from '../Translation/jsonfiles/en/inspirations_panel_en.json';
import inspirations_panel_fr from '../Translation/jsonfiles/fr/inspirations_panel_fr.json';
import onsite_list_view_en from '../Translation/jsonfiles/en/onsite_list_view_en.json';
import onsite_list_view_fr from '../Translation/jsonfiles/fr/onsite_list_view_fr.json';
import utils_en from '../Translation/jsonfiles/en/utils_en.json';
import utils_fr from '../Translation/jsonfiles/fr/utils_fr.json';
import reporting_en from '../Translation/jsonfiles/en/reporting_en.json';
import reporting_fr from '../Translation/jsonfiles/fr/reporting_fr.json';
import settings_en from '../Translation/jsonfiles/en/settings_en.json';
import settings_fr from '../Translation/jsonfiles/fr/settings_fr.json';

import navigation_en from '../Translation/jsonfiles/en/navigation_en.json';
import navigation_fr from '../Translation/jsonfiles/fr/navigation_fr.json';

import kpi_en from '../Translation/jsonfiles/en/kpi_en.json';
import kpi_fr from '../Translation/jsonfiles/fr/kpi_fr.json';

import chatbot_en from '../Translation/jsonfiles/en/chatbot_en.json';
import chatbot_fr from '../Translation/jsonfiles/fr/chatbot_fr.json';

import goals_en from '../Translation/jsonfiles/en/goals_en.json';
import goals_fr from '../Translation/jsonfiles/fr/goals_fr.json';

import notifications_en from '../Translation/jsonfiles/en/notifications_en.json';
import notifications_fr from '../Translation/jsonfiles/fr/notifications_fr.json';

import survey_en from '../Translation/jsonfiles/en/survey_en.json';
import survey_fr from '../Translation/jsonfiles/fr/survey_fr.json';

import segments_en from '../Translation/jsonfiles/en/segments_en.json';
import segments_fr from '../Translation/jsonfiles/fr/segments_fr.json';

import {mergeJson} from '../Translation/translation-utils';

const i18nL= localStorage.getItem('i18nL');
const newInstanceI18next = i18next.createInstance();
newInstanceI18next.init({
	lng: 'en-EN',                             
	resources: {
		'en-US': {
			common: mergeJson(account_home_en,
				campaign_react_en,
				inspirations_panel_en,
				onsite_list_view_en, 
				reporting_en,
				settings_en,
				utils_en
			),
			reporting: mergeJson(
				onsite_list_view_en, 
				reporting_en,settings_en,
				utils_en
			),
			navigation : navigation_en,
			kpi : kpi_en,
			inspiration: inspirations_panel_en,
			chatbot: chatbot_en,
			goals: goals_en,
			utils: utils_en,
			notifications: notifications_en,
			survey: survey_en,
			segments: segments_en
		},
		'en-EN': {
			common: mergeJson(account_home_en,
				campaign_react_en,
				inspirations_panel_en,
				onsite_list_view_en, 
				reporting_en,
				settings_en,
				utils_en
			),
			reporting: mergeJson(
				onsite_list_view_en, 
				reporting_en,settings_en,
				utils_en
			),
			navigation : navigation_en,
			kpi : kpi_en,
			inspiration: inspirations_panel_en,
			chatbot: chatbot_en,
			goals: goals_en,
			utils: utils_en,
			notifications: notifications_en,
			survey: survey_en,
			segments: segments_en
		},
		'fr-FR': {
			common: mergeJson(
				account_home_fr,
				campaign_react_fr,
				inspirations_panel_fr,
				onsite_list_view_fr, 
				reporting_fr,
				settings_fr,
				utils_fr
			),
			reporting: mergeJson(
				onsite_list_view_fr, 
				reporting_fr,
				settings_fr,
				utils_fr
			),
			navigation: navigation_fr,
			kpi: kpi_fr,
			inspiration: inspirations_panel_fr,
			chatbot: chatbot_fr,
			goals: goals_fr,
			utils: utils_fr,
			notifications: notifications_fr,
			survey: survey_fr,
			segments: segments_fr
		},
	}},
(err, t) => {
	if (err) return console.log('something went wrong loading', err);
	newInstanceI18next.changeLanguage(JSON.parse(i18nL));
});
export default newInstanceI18next;