import React, { } from 'react';
import { useTranslation } from 'react-i18next';
import Btn from '../../../Components/Btn';
import { serverSideCampaignsDashboardContext } from './context';
import ServerSideCampaignsList from './ServerSideCampaignList';

export default function ServerSideDashboardContainerInner() {

	const {
		getCreateLink,
		totalCount,
		permissionsAccount
	} = serverSideCampaignsDashboardContext();

	const permCreateCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'SERVERSIDE_CAN_CREATE');
	const canCreateCampaign = permCreateCampaign && permCreateCampaign.Value === true;

	const [t] = useTranslation('common');

	//{props.isAdmin && props.$routeParams && props.$routeParams.ka ? `/ServerSide/Editor?ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}` : '/ServerSide/Editor'}

	return (
		<div className='page_full_inner'>
			<section className='section no_bottom_pad section_primary'>
				<div className='flex'>
					<div className='flex_item_full'>
						<div className='h1'>{t('list.title')}</div>
					</div>
					{totalCount > 0 &&
						<div className='flex_item_fix ml_30'>							
							{canCreateCampaign &&
								<Btn
									message={t('action.create')}
									icon="fas fa-sm fa-plus"
									href={getCreateLink()}
								/>
							}
						</div>
					}
				</div>
			</section>
			<section className='section'>
				<ServerSideCampaignsList />
			</section>
		</div>
	);
}