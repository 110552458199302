
export function dateUtils() {

	const addDays = (date, days) => {

		return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
	};

	return {
		addDays
	};
}

