import React, {useEffect, useState} from 'react';
import {useProductFeedEditionContext} from '../../../context/ProductFeedEditionContext';
import Modal from '../../../../../Components/Modal';
import editionStyles from '../../ProductFeedEdition.module.css';
import SelectCustom from '../../../../../Components/SelectCustom';
import InputCustom from '../../../../../Components/InputCustom';
import Link from '../../../../../Components/Link';
import Checkbox from '../../../../../Components/Checkbox';
import Btn from '../../../../../Components/Btn';
import SelectDropDownWithExampleValues from '../SelectDropDownWithExampleValues';


const defaultCategoryObject = {name: '', itemName: '', isExcluded: false, hierarchy: null};
export const ModalMappingCategories = ({
	modalCategoriesIsOpen,
	setModalCategoriesIsOpen,
	isExistingCategory,
	hasKeyInObject,
	selectedCategory,
	setSelectedCategory,
	hierarchySelected,
	setHierarchySelected,
}) => {
	const {
		productFeedObject,
		metadatasSources,
		handleAddTagCategoryValue,
		tagConfig,
		addHierarchy,
		mappingObject
	} = useProductFeedEditionContext();

	const [advancedSettingsIsOpen, setAdvancedSettingsIsOpen] = useState(false);
	const [currentCategory, setCurrentCategory] = useState(defaultCategoryObject);

	useEffect(() => {
		if (isExistingCategory && selectedCategory) {
			setCurrentCategory(
				{
					name: selectedCategory.category.sourceField,
					itemName: selectedCategory.category.beyableItemName || '',
					isExcluded: tagConfig.excludedCategories.includes(selectedCategory.category.sourceField),
					hierarchy: hierarchySelected
				});
		} else if (hierarchySelected) {
			setCurrentCategory({...currentCategory, hierarchy: hierarchySelected});
		} else {
			setCurrentCategory(defaultCategoryObject);
		}

	}, [selectedCategory, hierarchySelected]);


	const closeModal = () => {
		setCurrentCategory(defaultCategoryObject);
		setModalCategoriesIsOpen(false);
		setHierarchySelected(null);
		setSelectedCategory(null);
	};

	const submit = () => {
		if (!currentCategory.hierarchy) {
			currentCategory.hierarchy = addHierarchy();
		}
		handleAddTagCategoryValue(currentCategory, !hasKeyInObject, isExistingCategory ? selectedCategory : false, 'Categories');
		closeModal();
	};

	const handleChangeCategory = (e, propName) => {
		e.nativeEvent && e.persist();
		const useCheckValue = propName === 'isExcluded';
		setCurrentCategory(prev => ({...prev, [propName]: useCheckValue ? e.target.checked : e.target.value}));
	};

	return (
		<Modal
			isOpen={modalCategoriesIsOpen}
			onClose={() => closeModal()}
		>
			<div className="modal_header has_border">
				<div>
					{isExistingCategory ? currentCategory.name : 'New category'}
				</div>
			</div>
			<div className="modal_body">
				<div className={editionStyles.settings_container_tag_mapping_modal}>
					{productFeedObject?.type !== 'Custom' && <>
						<SelectDropDownWithExampleValues
							editionStyles={editionStyles}
							metaDataSourcesToMap={metadatasSources}
							isCategory={true}
							valueDoesntExistInList={false}
							hasKeyInObjet={true}
							mappingObject={currentCategory}
							name={currentCategory.name !== '' ? currentCategory.name : 'choose'}
							label={currentCategory.name !== '' ? currentCategory.name : 'Field'}
							handleChangeForm={(e) => handleChangeCategory(e, 'name')}
						/>
					</>
					}
					{productFeedObject?.type === 'Custom' &&
						<InputCustom
							value={currentCategory.name !== '' ? currentCategory.name : ''}
							type="text"
							label={'Field'}
							name={'name'}
							placeholder=""
							onChange={(e) => handleChangeCategory(e, 'name')}
							fullWidth={true}
							size="l"
						/>
					}
					<InputCustom
						label="Category name"
						name="name"
						value={currentCategory.itemName}
						fullWidth={true}
						type="text"
						placeholder={currentCategory.name !== '' ? 'Leave empty to use ' + currentCategory.name : ''}
						onChange={(e) => setCurrentCategory({...currentCategory, itemName: e.target.value})}
						size="l"
					/>
				</div>

				<Link
					className='s_14'
					onClick={() => setAdvancedSettingsIsOpen(!advancedSettingsIsOpen)}
					icon={advancedSettingsIsOpen ? 'fas fa-caret-up fa-fw"' : 'fas fa-caret-down fa-fw"'}
					iconPosition={'after'}
					message={advancedSettingsIsOpen ? 'Hide advanced settings' : 'Show advanced settings'}
				/>
				{advancedSettingsIsOpen &&
					<div className='mt_20'>
						<Checkbox
							name={'isExcluded'}
							forcedChecked={currentCategory.isExcluded}
							onChange={(e) => handleChangeCategory(e, 'isExcluded')}
						>
							<div>Ignore for product recommendations</div>
							<div className='mt_2 grey_2 s_13'>
								This field is not useful for generating product recommendations.
							</div>
						</Checkbox>
					</div>
				}
			</div>
			<div className="modal_footer has_border al_right">
				<Btn
					message={'Cancel'}
					style={'ghost'}
					color={'secondary'}
					onClick={() => closeModal()}/>
				<Btn
					message={'Ok'}
					onClick={() => submit()}
					disabled={currentCategory.name === ''}/>
			</div>

		</Modal>
	);
};
