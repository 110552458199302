import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/Modal';
import TwicContainer from '../../TwicPics/TwicContainer';
import CropContainer from './CropContainer';

const ratioParametersDefault = { width: 1000, height: 1000, locked: false };
export default function CropModal({ banner, ratioParameters = {}, openCropModal, closeCropModal, handleImageCropped , widgetType}) {

	// eslint-disable-next-line no-prototype-builtins
	const selectedZoneProps = ratioParameters.hasOwnProperty('height') ? { extensions : ratioParameters} :{ extensions : {...ratioParametersDefault}};
	const exportImage = async (e) => {
	
		fetch(e).
			then(function (response) {
				return response.blob();
			})
			.then(function (myBlob) {
				let img = document.createElement('img');
				let blob = URL.createObjectURL(myBlob);
				img.src = blob;
				img.onload = function() {
					var w = img.width;
					var h = img.height;
					const bytes = myBlob.size;
					const imageTransform = {
						url: e,
						bytes: bytes,
						fileName: banner.metaData.fileName,
						height: h,
						width: w,
						sourceId: null,
						transformationSourceId:banner.metaData.transformationSourceId
					};
					handleImageCropped(imageTransform).then(res => closeCropModal());
				};
			});
	};
	return (
		<Modal
			isOpen={openCropModal}
			width="1200"
			height={widgetType === 'Cloudinary' ? 900 : 800}
			noClose={false}
			onClose={(e) => closeCropModal()}
		>
			{widgetType === 'Cloudinary' &&
				<>
					<div className="modal_header has_border">
						Select a new size for the image
					</div>
					<div className="modal_body no_vertical_pad" id="cropSize">
						<CropContainer
							banner={banner}
							ratioParameters={ratioParameters}
							closeCropModal={closeCropModal}
							handleImageCropped={handleImageCropped}
						/>
					</div>
				</>
			}
			{widgetType === 'TwicPic' &&
				<TwicContainer
					selectedZone={selectedZoneProps}
					imgSelected={{banner: banner}}
					exportImage={exportImage}
					onClose={(e) => closeCropModal()}
				/>
			}
		</Modal>
	);

}