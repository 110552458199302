import React, { useState, useEffect } from 'react'
import './ProductTags.css'
import SearchBar from '../../../Components/SearchBar';
import Btn from '../../../Components/Btn';
import TableRowItem from '../UI/TableRowItem';
import TableColItem from '../UI/TableColItem';
import TableGridContainer from '../UI/TableGridContainer';
import { getRamdomDate } from '../utils/tranformDates';
import useFeature from '../../../../flags/useFeature';
import EmptyState from '../../../Components/EmptyState';
import SpinnerWheel from '../../../Components/SpinnerWheel';

export default function ProductTagList({ getProductTag, toggleAddItem, accountId, handleClick }) {
	const { features } = useFeature()
	const [valueInSearchBar, setValueInSearchBar] = useState('')
	const [listTagAccount, setListTagAccount] = useState([])

	const [dataFiltered, setDataFiltered] = useState()
	const [noData, setNoData] = useState(false)
	const [loading, setLoading] = useState(true)
	const [totalCount, setTotalCount] = useState(0)

	const loadTags = () => {
		getProductTag(accountId,
			newData => {
				setListTagAccount([...newData])
				setLoading(false)
			}
		)
	}

	useEffect(() => {
		loadTags()
	}, [])

	useEffect(() => {
		if (listTagAccount) {
			setTotalCount(listTagAccount.length);
		}
	}, [listTagAccount])

	// fonction de recherche dans le tableau
	const search = (table, word) => {
		if (word.length > 0) {
			setDataFiltered([])
			let resultName = table.filter(
				line =>
					line.tagName.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			if (resultName.length === 0) {
				setNoData(true)
			}
			else {
				setNoData(false)
				setDataFiltered([...resultName])
			}

		} else {
			setDataFiltered()
		}
	}

	const handleChange = (event) => {
		const value = event.target.value;
		const str = value;
		setValueInSearchBar(value);
		if (listTagAccount) {
			search(listTagAccount, str);
		}
	}
	const optionCss = {
		gridTemplateColumns: '4fr 2fr 2fr',
	}
	const elementTags = (i, elem) => {
		return <TableRowItem
			key={i}
			specialClasseName={'table_body_row'}
			styleObj={optionCss}>
			<TableColItem
				specialClasseName={' '}>
				<div className="flex">
					<div className="flex_item_fix">
						<a className="emerch_avatar emerch_avatar_margin" onClick={(e) => { handleClick(elem); }}>
							<i className="fas fa-tag"></i>
						</a>
					</div>
					<div className="flex_item_full">
						<a className="table_row_name text_link_hover" onClick={(e) => { handleClick(elem); }}>
							{elem.tagName}
						</a>
						<div className="s_11 grey_3 mt_3">{elem.id}</div>
					</div>
				</div>
			</TableColItem>
			{/* <TableColItem specialClasseName={''}>
				{Math.floor(Math.random() * 454) + 1}
			</TableColItem>
			<TableColItem specialClasseName={''}>
				{getRamdomDate()}
			</TableColItem> */}
		</TableRowItem>
	}
	return (
		<>
			<section className="section">
				<div className="page_block">
					{loading && totalCount == 0 &&
						<SpinnerWheel wheelSize="l" display="block" verticalSize="xl" />
					}
					{loading && totalCount != 0 &&
						<SpinnerWheel wheelSize="l" />
					}
					{totalCount != 0 &&
						<>
							<div className="flex mb_15">
								<div className="flex_item_full">
									<SearchBar
										onChangeValue={(e) => handleChange(e)}
										valueInSearchBar={valueInSearchBar}
										placeholder="Search"
										fullWidth={false}
									/>
								</div>
								<div className="flex_item_fix">
									<Btn
										message="New tagging rule"
										icon="fas fa-plus"
										onClick={() => toggleAddItem()}
									/>
								</div>
							</div>
							{!noData &&
								<TableGridContainer specialClasseName="table_grid_emerch" >
									<TableRowItem specialClasseName={'table_head_row'} styleObj={optionCss}>
										<TableColItem>
											Rule name
										</TableColItem>
									</TableRowItem>
									{listTagAccount &&
										<>
											{!dataFiltered ?
												(<>
													{listTagAccount.map((elem, i) =>
														elementTags(i, elem)
													)}
												</>) :
												(<>
													{dataFiltered && dataFiltered.map((elem, i) =>
														elementTags(i, elem)
													)}
												</>)
											}
										</>
									}
								</TableGridContainer>
							}
						</>
					}
					{!loading && noData &&
						<EmptyState
							icon="fas fa-search"
							title="No matching rule"
							text="Please try another search"
							verticalSize="l"
							textSize="l"
						/>
					}

					{!loading && totalCount == 0 &&
						<EmptyState
							icon="fas fa-tag"
							title="No tagging rule yet"
							text="Add tags to products to find them more easily in recommendation rules"
							verticalSize="l"
							textSize="l"
							primaryAction={
								<>
									<Btn
										message="New tagging rule"
										onClick={() => toggleAddItem()}
									/>
									{features.includes("product-tagging") &&
										<Btn
											href="/ProductSettings/Tagging/Setting"
											message="New tagging +"
											onClick={() => toggleAddItem()}
										/>
									}
								</>
							}
						/>
					}
				</div>
			</section>
			{/* <div className="modalBody" >
				<h2 className="">Tag list</h2>
				<div className="search_tag">
					<i className="fa fa-search"></i>
					<input className="searchBarTag" type="text"
						onChange={handleChange}
						value={valueInSearchBar}
						placeholder="Search for a tag"
					/>
				</div>

				<ul className="listOfSkuWithCount">
					{loading ?
						<Spinner /> :
						<>
							{!dataFiltered ?
								(<>
									{listTagAccount.map((elem, i) =>
										<li key={i} className="itemSku" onClick={(e) => handleClick(elem)}>
											{elem.tagName}
											<span>({elem.count})</span>
										</li>
									)}
								</>)

								:

								(<>
									{noData ?

										<div className="noData">
											{noData}
										</div>

										:

										(<>
											{dataFiltered && dataFiltered.map((elem, i) =>
												<li key={i} className="itemSku" onClick={(e) => handleClick(elem)}>
													{elem.tagName}
													<span>({elem.count})</span>
												</li>
											)}
										</>)
									}
								</>)
							}
						</>
					}
				</ul>
				<div className="tag_actions">
					<button className="tag_btn buttonAdd" onClick={() => toggleAddItem()}>Add a new tag <i className="fas fa-plus"></i></button>
				</div>
			</div> */}
		</>
	)
}
