import React from 'react';

export default function AnalitycsBoardItemEcommerce({ data,
	isHandleClick,
	handleClickFunction,
	needPercent,
	needDevise,
	overhead,
	nowrap
}) {
	const overheadBody = <>
		<p className="grey_0 s_12 ml_10">
			({!isNaN(overhead) ? overhead : 0 } %)
		</p>
	</>;
	return (
		<div className={`table_col ${nowrap ? 'no_wrap' : ''}`}>
			{isHandleClick ?
				<>
					<p
						style={{cursor : 'pointer'}}
						onClick={() => handleClickFunction(data)}>{data}
					</p>
				</>
				:
				<>
					{needPercent && data + ' %' }
					{needDevise && data + ' €'}
					{!needPercent && !needDevise && data}
					{overhead && overheadBody}
				</>
			}

		</div>
	);
}
