import React, { useState, useContext } from 'react';
import SearchBar from '../../../../Components/SearchBar';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import './TagListContainer.css';
import { check } from '../../../../Components/Can';
import triggerList from './TargetTriggerList';

export default function TargetListContainer() {

	const {
		operation,
		setOperation,
		userSimplifyRole,
		planAccount,
	} = useContext(CreateBusinessOperationContext);

	const createNewTriggerSpecification = (e) => {
		function removeAll(fromSet, setToRemove) {
			[...setToRemove].forEach(function (v) {
				fromSet.delete(v);
			});
		}

		const getNewId = (usedIds) => {
			let possibleIds = new Set([...Array(100).keys()]);
			possibleIds.delete(0);
			usedIds.length > 0 && removeAll(possibleIds, usedIds);
			return Math.min(...possibleIds);
		};

		const array = [...operation.operation.specifications] || [];
		const newId = getNewId(array.map(x => x.id));

		const trigger = triggerList.filter(x => x.id === e)[0];
		const newTrigger = { ...trigger.defaultValueAndOperator, id: newId, name: trigger.name };
		const newOperationSpecifications = ([...operation.operation.specifications, newTrigger]);
		let newOperation = { ...operation };
		newOperation.operation.specifications = newOperationSpecifications;
		setOperation(newOperation);

	};
	const canUseTriggerSkuList = check(planAccount, userSimplifyRole, 'simplifyOperation:canUseTriggerBySkuList');
	const canUseTriggerCategory = check(planAccount, userSimplifyRole, 'simplifyOperation:canUseTriggerByCategoryList');
	const canUseTriggerUrl = check(planAccount, userSimplifyRole, 'simplifyOperation:canUseTriggerByUrlList');
	const canUseTriggerTenant = check(planAccount, userSimplifyRole, 'simplifyOperation:canUseTriggerByTenantList');

	return (
		<section className="simplify_trigger-wrapper">
			<div className="simplify_new_triggers_list_inner">
				<article >
					<p className="simplify_title_target_cat ">Choose your criteria</p>
					<ul >
						<li className={!canUseTriggerSkuList ? 'simplify_disabled_trigger' : ''}
							onClick={canUseTriggerSkuList ? () => createNewTriggerSpecification('productReference') : null}
						>
							<div className="simplify_new_trigger_label">
								<p >Add a list of products</p>&nbsp;
							</div>
							<i className="fa fa-plus simplify_btn_trigger_add"></i>
						</li>
						<li className={!canUseTriggerCategory ? 'simplify_disabled_trigger' : ''}
							onClick={canUseTriggerCategory ? () => createNewTriggerSpecification('category') : null}
						>
							<div className="simplify_new_trigger_label">
								<p >Add a list of categories</p>&nbsp;
							</div>
							<i className="fa fa-plus simplify_btn_trigger_add"></i>
						</li>
						<li className={!canUseTriggerUrl ? 'simplify_disabled_trigger' : ''}
							onClick={canUseTriggerUrl ? () => createNewTriggerSpecification('url') : null}
						>
							<div className="simplify_new_trigger_label">
								<p >Add a list of URLs</p>&nbsp;
							</div>
							<i className="fa fa-plus simplify_btn_trigger_add"></i>
						</li>
						<li className={!canUseTriggerTenant ? 'simplify_disabled_trigger' : ''}
							onClick={canUseTriggerTenant ? () => createNewTriggerSpecification('tenant') : null}
						>
							<div className="simplify_new_trigger_label">
								<p >Add a list of Tenants</p>&nbsp;
							</div>
							<i className="fa fa-plus simplify_btn_trigger_add"></i>
						</li>
						<li
							className="simplify_disabled_trigger"
						// onClick={() => createNewTriggerSpecification("exemple2")}
						>
							<div className="simplify_new_trigger_label">
								<p >Add a traffic source </p>&nbsp;
							</div>
							<i className="fa fa-plus simplify_btn_trigger_add"></i>
						</li>
						<li className="simplify_disabled_trigger"
						// onClick={() => createNewTriggerSpecification("exemple2")}
						>
							<div className="simplify_new_trigger_label">
								<p >Add a scheduling (time and hour)</p>&nbsp;
							</div>
							<i className="fa fa-plus simplify_btn_trigger_add"></i>
						</li>
					</ul>
				</article>
			</div>
		</section>


	);
}
