import React, { useEffect, useState } from 'react';
import Panel from '../../Application/Components/Panel';
import Switch from '../../Application/Components/Switch';
import ImpersonatingServices from '../../Application/Services/ImpersonatingServices';
import style from './flagPannel.module.css';
import { react2angular } from 'react2angular';

export default function FlagPannel(props) {
	const authServices = props.AuthServices;
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const [canSeePanel, setCanSeePanel] = useState(false);
	let store = JSON.parse(localStorage.getItem('ffl'));

	let featureFlagsPlanelIsEnabled = () => {
		const storageFlag = localStorage.getItem('ffl_display');
		return !!(!isNaN(storageFlag) && parseInt(storageFlag));
	};

	if (!store || store[0] === null) return <></>;

	const [flags, setFlags] = useState(
		JSON.parse(localStorage.getItem('ffl'))
	);
	const [state, setState] = useState();
	const [flagPannelIsOpen, setFlagPannelIsOpen] = useState(false);
	const [checkedState, setIsChecked] = useState(
		store.map((flag, i) => ({ active: flag.active, index: i }))
	);

	useEffect(() => {
		props.$rootScope.$on('event:auth-userAuthenticated', function (event, evt) {
			if (authServices) {
				const isAuthenticated = authServices.isAuthenticated();
				setCanSeePanel(isAuthenticated && featureFlagsPlanelIsEnabled() && impersonatingServices.isAnAdmin());
			}
		});
	}, [props]);


	useEffect(() => {
		
		const onPagefeatures = document.querySelectorAll('[data-ffl]');
		// if (!onPagefeatures || !onPagefeatures.length > 0)
		// 	return setState(<span>No feature flag on this module</span>);
		let onPageSingleFeature = [];
		for (var value of onPagefeatures.values()) {
			let isInArray = onPageSingleFeature.find(
				(elem) => elem.dataset.ffl === value.dataset.ffl
			);
			!isInArray && onPageSingleFeature.push(value);
		}
		if (flags && flags.length ) {
			let copyFlags = flags.map((flag, i) => {
				const foundFeature = onPageSingleFeature.find(
					(feature) => feature.dataset.ffl === flag.name
				);
				if (foundFeature) return { ...flag, isOnPage: true };
				else return flag;
			});
			localStorage.setItem('ffl', JSON.stringify(copyFlags));
			setState(
				flags.map((flag, i) => (
					<div
						key={`container_flag${i}`}
						className={`${style.feature_item} ${!copyFlags[i].isOnPage ? style.feature_item_notonpage : ''}`}
					>
						<div className={style.feature_item_inner}>
							<Switch
								forcedChecked={checkedState[i].active}
								onChange={() => handleToggleChange(i)}
								position="right"
							>
								<div className={!copyFlags[i].isOnPage ? style.feature_disabled : ''}>{flag.description}</div>
								<div className={style.feature_id}>
									{flag.name}
								</div>
							</Switch>
						</div>
					</div>
				))
			);
		} else {
			setState(<span>No feature flag is available</span>);
		}
		
	}, [checkedState, flagPannelIsOpen]);

	const handleToggleChange = (i) => {
		const updatedCheckedState = checkedState.map((elem, index) => {
			index === i
				? (elem.active = !elem.active)
				: (elem.active = !!elem.active);
			return elem;
		});

		setIsChecked(updatedCheckedState);
		const updatedFlags = flags.map((elem, index) => {
			index === i
				? (elem.active = !elem.active)
				: (elem.active = !!elem.active);
			return elem;
		});

		setFlags(updatedFlags);

		localStorage.setItem('ffl', JSON.stringify(updatedFlags));
	};

	return (<>
		{canSeePanel &&
			<>
				<button
					id="BY_ffl_button"
					className={style.openButton}
					onClick={() => setFlagPannelIsOpen(true)}
				>
					<i className="far fa-flag"></i>
				</button>

				<Panel
					isOpen={flagPannelIsOpen}
					onClose={() => setFlagPannelIsOpen(false)}
					overlay={true}
					closeOnOverlay={true}
					width={560}
				>
					<div className="modal_header has_border">Feature flags</div>
					<div className="modal_body">{state}</div>
				</Panel>
			</>
		}
	</>
	);
}
angular
	.module('beyableSaasApp.FlagPannel', [])
	.component(
		'flagpannel',
		react2angular(
			FlagPannel,
			[],
			['$rootScope', '$http', '$routeParams', 'AuthServices', '$location']
		)
	);
