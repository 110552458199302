import React from 'react';
import { useRuleCompositionContext } from '../context/RuleCompositionContextProvider';
import NumericUpDown from '../../../../Components/NumericUpDown';
import { Checkbox } from '../../../../Components/Checkbox/Checkbox';
import IconBtn from '../../../../Components/IconBtn';
import { ComposableGroupItem, CompositionGroup } from '../context/EntityTypes';
import styles from './RuleComposition.module.css';

type RuleComponentProps = {
	rule: ComposableGroupItem,
	ruleCompositionGroup: CompositionGroup,
	isDragging: boolean,
	displayMode: string,
	dragProvided?: any
}

export default function RuleComponent({
	rule,
	ruleCompositionGroup,
	dragProvided
}: RuleComponentProps) {
	const {
		handleChangeRuleLimit,
		handleSetRuleAsFillUp,
		handleDeleteRuleFromGroup
	} = useRuleCompositionContext();

	const handleProductCountChanged = (e) => {
		const val = e.target.value;
		const count = parseInt(val);
		if (count) handleChangeRuleLimit(ruleCompositionGroup, rule, val);
	};

	const handleSetRuleAsFillUpChanged = (event) => {
		const value = event.target.checked;
		handleSetRuleAsFillUp(ruleCompositionGroup, rule, value);
	};

	return (
		<div className={styles.rule}>
			<div className='flex'>
				{ruleCompositionGroup.rules.length > 1 &&
					<div className='flex_item_fix mr_10'>
						<IconBtn
							icon="fas fa-equals"
							tooltip="Reorder rules"
							{...dragProvided.dragHandleProps}
						/>
					</div>
				}
				<div className='flex_item_full'>
					<div className='ellips'>{rule.name}</div>
					{ruleCompositionGroup.rules.length > 1 &&
						<div className='mt_3'>
							<Checkbox
								name="isFillUp"
								forcedChecked={rule.isFillUp || false}
								value={rule.isFillUp || false}
								onChange={(e) => handleSetRuleAsFillUpChanged(e)}
								checkboxSize="s"
								noMargin={true}
							><span className='s_12 grey_2'>Use as fillup rule</span></Checkbox>
						</div>
					}
				</div>
				<div className='flex_item_fix ml_10' style={{ width: 100 }}>
					{rule.isFillUp ?
						<em className='grey_3'>Automatic</em>
						:
						<NumericUpDown
							value={rule.ruleLimit || 0}
							onChange={(e) => handleProductCountChanged(e)}
							canEdit={true}
							placeholder="2"
							disabled={rule.isFillUp}
							min={0}
						/>
					}
				</div>
				<div className='flex_item_fix ml_10'>
					<IconBtn
						tooltip="Delete rule"
						onClick={() => handleDeleteRuleFromGroup(ruleCompositionGroup, rule)}
						size="s"
						icon="fas fa-trash"
						hoverColor="alert"
					/>
				</div>
			</div>
		</div>
	);
}