import React, {useState} from 'react';
import Modal from "../../../../../Components/Modal";
import Btn from "../../../../../Components/Btn";
import InputCustom from "../../../../../Components/InputCustom";
import {useProductFeedEditionContext} from "../../../context/ProductFeedEditionContext";

export const ModalHierarchy = ({
    modalHierarchieIsOpen,
    setModalHierarchieIsOpen
}) => {

    const {
        addHierarchy,
    } = useProductFeedEditionContext();


    const [inputHierarchieName, setInputHierarchieName] = useState('');

    return (
        <Modal
            isOpen={modalHierarchieIsOpen}
            onClose={() => setModalHierarchieIsOpen(false)}
        >
            <div className="modal_header has_border">
                <div>
                    New Hierarchy
                </div>
            </div>
            <div className="modal_body">
                <InputCustom
                    name="name"
                    value={inputHierarchieName}
                    fullWidth={true}
                    type="text"
                    // placeholder={currentCategory.name !== '' ? 'Leave empty to use ' + currentCategory.name : ''}
                    onChange={(e) => setInputHierarchieName(e.target.value)}
                />
            </div>
            <div className="modal_footer has_border al_right">
                <Btn
                    message={'Cancel'}
                    style={'ghost'}
                    color={'secondary'}
                    onClick={() => setModalHierarchieIsOpen(false)}/>
                <Btn
                    message={'Ok'}
                    onClick={() => {
                        addHierarchy(inputHierarchieName)
                        setModalHierarchieIsOpen(false)
                    }}
                    // disabled={currentCategory.name === ''}
                />
            </div>
        </Modal>
    );
}