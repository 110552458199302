import React from 'react';

import { Section } from '../../../Components/Section/Section';
import { useAuditContext } from '../context/ContextAudit';

import HeaderAi from './HeaderAi';
import EmptySection from './EmptySection';
import ProgressSection from './AnalysisPart/ProgressSection';
import ScenarioSection from './Scenarios/ScenarioSection';
import ResultNoScenario from './ResultNoScenario';

export default function AuditBody() {

	const {
		handleGetAudit,
		stateAudit,
		noTask,
		resultTask
	} = useAuditContext();
	
	return (
		<Section width='m'>
			{
				<HeaderAi
					handleGetAudit={handleGetAudit}
					stateAudit={stateAudit}
				/>
			}
			{stateAudit === 0 && noTask &&
				<EmptySection />
			}
			{stateAudit === 0 && resultTask && 
				<ResultNoScenario />
			}
			{stateAudit === 1 &&
				<ProgressSection />
			}
			{stateAudit === 2 &&
				<ScenarioSection />
			}
		</Section>
	);
}
