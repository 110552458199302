/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useEffect, useState} from 'react';
import {useProductFeedEditionContext} from '../../../context/ProductFeedEditionContext';
import {checkObjectsForSplitAndTake, keyExistInConfig} from '../../../utils';
import Radio from '../../../../../Components/Radio';
import editionStyles from '../../ProductFeedEdition.module.css';
import Btn from '../../../../../Components/Btn';
import FieldContainer from '../FieldContainer';
import {OnePerCategoryMapping} from './OnePerCategoryMapping';
import {MonoFieldCategoriesMapping} from './MonoFieldCategoriesMapping';
import TransformationsContainer from '../Transformations/TransformationsContainer';

export const MappingCategoriesContainer = ({
	fieldIndex, canUseTranformations
}) => {

	const {
		mappingObject
	} = useProductFeedEditionContext();

	const [categoriesTypesSelected, setCategoriesTypesSelected] = useState('onePerCategory');

	const [categoriesMappingStep, setCategoriesMappingStep] = useState(1);
	const [elementToInsert, setElementToInsert] = useState(<></>);
	const [first, setfirst] = useState(true);


	useEffect(() => {
		if (mappingObject['Categories'] && first){
			const categories = mappingObject['Categories'];
			const isMonoField =  checkObjectsForSplitAndTake(categories);
			isMonoField ? setCategoriesTypesSelected('monoField') : setCategoriesTypesSelected('onePerCategory');
			setCategoriesMappingStep(2);
			setfirst(false);
		}
	}, [mappingObject]);


	useEffect(() => {
		if (categoriesMappingStep === 1) {
			setElementToInsert(<></>);
		} else {
			if (categoriesTypesSelected === 'onePerCategory') {
				setElementToInsert(
					<div className={editionStyles.edit_message_categories_type}>
                        My feed contains one field per category - <a
							onClick={() => setCategoriesMappingStep(1)}
						>
                        Edit
						</a>
					</div>
				);
			}
			if (categoriesTypesSelected === 'monoField') {
				setElementToInsert(
					<div className={editionStyles.edit_message_categories_type}>
                        My feed contains a unique field with all category - <a
							onClick={() => setCategoriesMappingStep(1)}
						>
                        Edit
						</a>
					</div>
				);
			}
		}
	}, [categoriesTypesSelected, categoriesMappingStep]);


	const editCategoryMappingType = () => {
		setCategoriesMappingStep(1);

	};
	return (
		<>
			<FieldContainer
				label={'Categories'}
				name={'Categories'}
				fieldIndex={fieldIndex}
				insertElement={elementToInsert}
			>
				{categoriesMappingStep === 1 &&
					<div>
						<span className='s_13 fw_medium'>My feed contains</span>
						<div className={editionStyles.choose_categories_type_container}>
							<div className='mb_3'>
								<Radio
									name="mapping-categories-type"
									checked={categoriesTypesSelected === 'onePerCategory'}
									onChange={() => {setCategoriesTypesSelected('onePerCategory'); setfirst(false);}}
								>
									One field per category
								</Radio>
							</div>
							<div className='mt_3'>
								<Radio
									name="mapping-categories-type"
									checked={categoriesTypesSelected === 'monoField'}
									onChange={() => {setCategoriesTypesSelected('monoField');setfirst(false);}}
								>
									A unique field with all categories
								</Radio>
							</div>
						</div>
						<div className='al_right mt_20'>
							<Btn
								message={'Next'}
								onClick={() => setCategoriesMappingStep(2)}
							/>
						</div>
					</div>
				}
				{categoriesMappingStep === 2 &&
					<>
						{categoriesTypesSelected === 'onePerCategory' &&
							<OnePerCategoryMapping 
								canUseTranformations={canUseTranformations}
							/>
						}
						{categoriesTypesSelected === 'monoField' &&
							<MonoFieldCategoriesMapping 
								canUseTranformations={canUseTranformations}
							/>
                    	}
					</>
				}
			</FieldContainer>
		</>
	);
};
