import React from 'react';

export const iconSegment = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M15.142,5.483v5.7a2.645,2.645,0,0,1-1.308,2.275l-4.95,2.858a2.636,2.636,0,0,1-2.625,0l-4.95-2.858A2.625,2.625,0,0,1,0,11.183v-5.7A2.645,2.645,0,0,1,1.308,3.208L6.258.35a2.636,2.636,0,0,1,2.625,0l4.95,2.858A2.635,2.635,0,0,1,15.142,5.483Z" transform="translate(2.425 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<g transform="translate(6.667 5.283)">
			<path d="M3.883,1.942A1.942,1.942,0,1,1,1.942,0,1.942,1.942,0,0,1,3.883,1.942Z" transform="translate(1.392)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
			<path d="M6.667,2.717C6.667,1.217,5.175,0,3.333,0S0,1.217,0,2.717" transform="translate(0 5.883)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		</g>
	</svg>
);

export const iconPerformance = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M0,1.725V0" transform="translate(5.733 13.4)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M0,3.45V0" transform="translate(10 11.675)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M0,5.183V0" transform="translate(14.267 9.942)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M8.533,0,8.15.45A15.735,15.735,0,0,1,0,5.483" transform="translate(5.733 4.875)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M0,0H2.442V2.433" transform="translate(11.825 4.875)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M5.833,16.667h5c4.167,0,5.833-1.667,5.833-5.833v-5C16.667,1.667,15,0,10.833,0h-5C1.667,0,0,1.667,0,5.833v5C0,15,1.667,16.667,5.833,16.667Z" transform="translate(1.667 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconEmailTemplate = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M6.35,4.684V1.666C6.35.478,5.872,0,4.684,0H1.666C.478,0,0,.478,0,1.666V4.684C0,5.872.478,6.35,1.666,6.35H4.684C5.872,6.35,6.35,5.872,6.35,4.684Z" transform="translate(11.121 2.53)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M6.35,4.871V1.479C6.35.426,5.872,0,4.684,0H1.666C.478,0,0,.426,0,1.479V4.863C0,5.924.478,6.342,1.666,6.342H4.684C5.872,6.35,6.35,5.924,6.35,4.871Z" transform="translate(2.529 2.53)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M6.35,4.684V1.666C6.35.478,5.872,0,4.684,0H1.666C.478,0,0,.478,0,1.666V4.684C0,5.872.478,6.35,1.666,6.35H4.684C5.872,6.35,6.35,5.872,6.35,4.684Z" transform="translate(2.529 11.121)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0H4.482" transform="translate(11.868 14.109)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
		<path d="M0,4.482V0" transform="translate(14.109 11.868)" fill="none" strokeLinecap="round" strokeWidth="1.5"/>
	</svg>
);

export const iconAbandonedCart = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M3.774,3.757,0,0" transform="translate(8.131 10.653)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M3.774,0,0,3.774" transform="translate(8.095 10.689)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M4.792,11.667h5c3.35,0,3.95-1.342,4.125-2.975l.625-5C14.767,1.658,14.184,0,10.626,0H3.959C.4,0-.183,1.658.042,3.692l.625,5C.842,10.325,1.442,11.667,4.792,11.667Z" transform="translate(2.708 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,4.718V3.91A3.977,3.977,0,0,1,3.383.018,3.75,3.75,0,0,1,7.5,3.751V4.9" transform="translate(6.25 1.674)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconHistory = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M16.667,8.333A8.333,8.333,0,1,1,8.333,0,8.336,8.336,0,0,1,16.667,8.333Z" transform="translate(1.667 1.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M3.4,6.392.817,4.85A1.846,1.846,0,0,1,0,3.417V0" transform="translate(9.692 6.258)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconThumbUp = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M0,13.085l2.583,2a2.564,2.564,0,0,0,1.583.5H7.333a2.5,2.5,0,0,0,2.333-1.75l2-6.083a1.561,1.561,0,0,0-1.583-2.167H6.75a.841.841,0,0,1-.833-1l.417-2.667A1.644,1.644,0,0,0,5.25.085,1.664,1.664,0,0,0,3.417.668L0,5.752" transform="translate(6.233 2.207)" fill="none" strokeWidth="1.5"/>
		<path d="M0,9.75V1.583C0,.417.5,0,1.667,0H2.5C3.667,0,4.167.417,4.167,1.583V9.75c0,1.167-.5,1.583-1.667,1.583H1.667C.5,11.333,0,10.917,0,9.75Z" transform="translate(1.983 5.542)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconRecommendation = iconThumbUp;

export const iconThumbDown = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M0,2.5,2.583.5A2.564,2.564,0,0,1,4.167,0H7.333A2.5,2.5,0,0,1,9.667,1.75l2,6.083A1.561,1.561,0,0,1,10.083,10H6.75a.841.841,0,0,0-.833,1l.417,2.667A1.644,1.644,0,0,1,5.25,15.5a1.664,1.664,0,0,1-1.833-.583L0,9.833" transform="translate(6.233 2.208)" fill="none" strokeWidth="1.5"/>
		<path d="M0,1.583V9.75c0,1.167.5,1.583,1.667,1.583H2.5c1.167,0,1.667-.417,1.667-1.583V1.583C4.167.417,3.667,0,2.5,0H1.667C.5,0,0,.417,0,1.583Z" transform="translate(1.983 3.125)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconProgressUp = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M11.667,0,0,11.667" transform="translate(4.167 4.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M8.558,8.558V0H0" transform="translate(7.275 4.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconProgressDown = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M11.667,11.667,0,0" transform="translate(4.167 4.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M8.558,0V8.558H0" transform="translate(7.275 7.275)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconScales = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<circle cx="1.667" cy="1.667" r="1.667" transform="translate(8.333 2.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M9,5H4m8.333,0h5" transform="translate(-0.667 -0.833)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M15.333,21H7" transform="translate(-1.167 -3.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M12,7V18.667" transform="translate(-2 -1.167)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M21,13a2.5,2.5,0,1,1-5,0m5,0L18.917,8h-.833L16,13m5,0H16" transform="translate(-2.667 -1.333)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M7,13a2.5,2.5,0,0,1-5,0m5,0L4.917,8H4.083L2,13m5,0H2" transform="translate(-0.333 -1.333)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconFunnel = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M1.667,0h11a1.672,1.672,0,0,1,1.667,1.667V3.5A3.111,3.111,0,0,1,13.5,5.417L9.917,8.583A2.713,2.713,0,0,0,9.083,10.5v3.583a1.856,1.856,0,0,1-.75,1.417l-1.167.75a1.7,1.7,0,0,1-2.583-1.417V10.417a3.221,3.221,0,0,0-.667-1.75L.75,5.333A2.762,2.762,0,0,1,0,3.667V1.75A1.691,1.691,0,0,1,1.667,0Z" transform="translate(2.833 1.75)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M4.108,0,0,6.583" transform="translate(5 1.75)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconBarCode = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M12.5,14.167H4.167C1.667,14.167,0,12.917,0,10V4.167C0,1.25,1.667,0,4.167,0H12.5c2.5,0,4.167,1.25,4.167,4.167V10C16.667,12.917,15,14.167,12.5,14.167Z" transform="translate(1.667 2.917)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V6.667" transform="translate(5 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V3.333" transform="translate(7.5 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V.833" transform="translate(7.5 12.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V.833" transform="translate(12.5 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V6.667" transform="translate(10 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V3.333" transform="translate(12.5 10)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M0,0V6.667" transform="translate(15 6.667)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);

export const iconVariable = (
	<svg width="1em" height="1em" viewBox="0 0 20 20" stroke="currentColor">
		<path d="M21,40c-5,0,0,6.875-5,6.875,5,0,0,6.875,5,6.875" transform="translate(-13.75 -36.875)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
		<path d="M176,40c5,0,0,6.875,5,6.875-5,0,0,6.875-5,6.875" transform="translate(-163.25 -36.875)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
	</svg>
);