import React, { useState, useEffect } from 'react';
import Modal from '../../../Components/Modal';
import Btn from '../../../Components/Btn';
import PopinConfirm from '../../../Components/PopinConfirm';
import { v4 as uuidv4 } from 'uuid';
import classes from './EditCampaignTestMode.module.css';
import { abTestsDashboardContext } from './context';
import { Trans, useTranslation } from 'react-i18next';

export default function EditCampaignTestMode({
	forcedStatus
}) {
	const {
		systemServices,
		accountId,
		ipAdressesServices,
		ReportingCampaignServices,
		currentIP,
		campaignSelected,
		setneedRefetch,
		setLoading,
		modalTestModeIsOpen,
		openCloseModalTestMode,
		needRefetch
	} = abTestsDashboardContext();

	const [t, i18n] = useTranslation('common');
	const [ipAddresses, setIpAddresses] = useState([]);
	const [executionModeCampaign, setexecutionModeCampaign] = useState(1);
	const [addNewIpAdress, setAddnewIpAdress] = useState(false);
	const [isLoading, setisLoading] = useState(true);
	const [adressToDelete, setAdressToDelete] = useState();
	const [isEditionMode, setisEditionMode] = useState(false);
	const [currentIpMessage, setcurrentIpMessage] = useState(<></>);
	const [errorRegisterChange, seterrorRegisterChange] = useState(false);
	const [stateOfOperationToValidate, setStateOfOperationToValidate] = useState();

	const [editingAdress, setEditingAdress] = useState({
		key: '',
		ipAddress: '',
		label: ''
	});
	useEffect(() => {

		const timer = setTimeout(() => {

		setexecutionModeCampaign(campaignSelected.executionMode == 'TEST' ? 2 : 1);
		ipAdressesServices.getAccountData(accountId,
			newData => {
				const normalizedNewData = newData.TestModeIpAddresses.map(x => ({ key: uuidv4(), ipAddress: x.IpAddress, label: x.Label, isDisabled: true }));
				const allIps = [];				
				for (let i = 0; i < normalizedNewData.length; i++) {
					const el = normalizedNewData[i];
					const elementInAllIps = allIps.find(ipAdd => ipAdd.ipAddress === el.ipAddress && ipAdd.label === el.label);
					if (!elementInAllIps){
						allIps.push(el);
					}
				}

				for (let i = 0; i < campaignSelected.TestModeIpAddresses.length; i++) {
					const el = campaignSelected.TestModeIpAddresses[i];
					let elementInAllIps = allIps.find(ipAdd => ipAdd.ipAddress === el.ipAddress && ipAdd.label === el.label);
					if (!elementInAllIps){
						allIps.push({...el, key: uuidv4()});
					} else {
						elementInAllIps.isDisabled = el.isDisabled;
					}
				}

				setIpAddresses(allIps);
				setisLoading(false);
			}, err => {
				setisLoading(false);
			});
		}, 800);
		return () => {
			clearTimeout(timer);
		};
	}, [campaignSelected]);

	useEffect(() => {
		const timer = setTimeout(() => {
		checkMessageForCurrentIpAdress(currentIP);
	}, 800);
	return () => {
		clearTimeout(timer);
	};
	}, [ipAddresses, currentIP]);
    
	const editIpAdress = (ipAdress) => {
		setEditingAdress({...ipAdress});
		setisEditionMode(true);
	};
    
	const handleChangeIpAdresse = (e) => {
		let newObj = { ...editingAdress };
		newObj[e.target.name] = e.target.value;
		setEditingAdress(newObj);
	};
	const createNewAdresse = () => {
		setAddnewIpAdress(true);
		setisEditionMode(true);
		setEditingAdress({
			ipAddress: '',
			label: '',
			isDisabled: false,
			key: uuidv4()
		});
	};
	const cancel = () => {
		setAddnewIpAdress(false);
		setisEditionMode(false);
		setEditingAdress({
			ipAddress: '',
			label: '',
			isDisabled: false,
			key: uuidv4()
		});
	};
	const ValidNewIpAdress = () => {
		setIpAddresses([editingAdress, ...ipAddresses]);
		setAddnewIpAdress(false);
		setisEditionMode(false);
		setEditingAdress({
			ipAddress: '',
			label: '',
			isDisabled: false,
			key: uuidv4()
		});
	};
	const ValidExistantIpAdress = () => {
		const newIpAddresses = ipAddresses.map(x => x.key === editingAdress.key ? editingAdress : x); 
		setIpAddresses(newIpAddresses);
		setisEditionMode(false);
		setEditingAdress({
			ipAddress: '',
			label: '',
			isDisabled: false,
			key: uuidv4()
		});
	};
	const handleChangeAdressIpToList = (e, ip) => {
		const target = e.target;
		const value = target.checked;

		const newIpAddresses = ipAddresses.map(x => x.key === ip.key ? ({...x, isDisabled:!value}) : x); 
		setIpAddresses(newIpAddresses);
	};
	const handleChangeTestMode = (e) => {
		if (typeof e === 'object') {
			setexecutionModeCampaign(e.target.checked ? 2 : 1);
		} else {
			setexecutionModeCampaign(e ? 2 : 1);
		}
	};
	const register = () => {
		setisLoading(true);
		seterrorRegisterChange(false);
		const newIpForAccount =
        {TestModeIpAddresses: ipAddresses.map(ip => ({IpAddress: ip.ipAddress,
        	Label: ip.label,
        		IsDisabled: ip.isDisabled
        	})),
        	key: accountId
        };
		ipAdressesServices.putIpAdresses(accountId, newIpForAccount,
			success => {
				const newObjectOfTestModeForCampaign = {
					ExecutionMode: executionModeCampaign,
					TestModeIpAddresses: ipAddresses
				};
				ipAdressesServices.putTestModeofCampaignAndSaveIpAdress(accountId, campaignSelected.CampaignId, newObjectOfTestModeForCampaign,
					success => {
						systemServices.showSuccess(t('executionModeCampaign.campagneHasBeenSaved'));
						setTimeout(()=>{
							setLoading(false);
							setneedRefetch(!needRefetch);
						},1000)
						openCloseModalTestMode(false);
					},
					err => {
						systemServices.showError(t('executionModeCampaign.errorOccured'));
						setisLoading(false);
					}
				);
			}, err => {
				systemServices.showError(t('executionModeCampaign.errorOccured'));
				setisLoading(false);
			});
	};
	const registerWithoutIp = () => {
		const bool = stateOfOperationToValidate == 'stopped' ? true : false;
		const newIpForAccount =
        {
        	TestModeIpAddresses: ipAddresses.map(ip => ({
        		IpAddress: ip.ipAddress,
        		Label: ip.label,
        		IsDisabled: ip.isDisabled
        	})),
        };
		setLoading(true);
		ipAdressesServices.putIpAdressesAccount(accountId, newIpForAccount,
			success => {
				const newObjectOfTestModeForCampaign = {
					ExecutionMode: 1,
					TestModeIpAddresses: ipAddresses
				};
				ipAdressesServices.putTestModeofCampaignAndSaveIpAdress(accountId, campaignSelected.CampaignId, newObjectOfTestModeForCampaign,
					success => {
						ReportingCampaignServices.putStatusCampaign(accountId, campaignSelected.CampaignId, bool,
							success => {
								systemServices.showSuccess(t('executionModeCampaign.campagneHasBeenSaved'));
								setTimeout(()=>{
									setLoading(false);
									setneedRefetch(!needRefetch);
								},1000)
								openCloseModalTestMode(false);
							},
							error => {
								systemServices.showError(t('executionModeCampaign.errorOccured'));
								setLoading(false);
							});
					},
					err => {
						systemServices.showError(t('executionModeCampaign.errorOccured'));
						setLoading(false);
					}
				);
			}, err => {
				systemServices.showError(t('executionModeCampaign.errorOccured'));
				setLoading(false);
			});

	};
	const registerTheNewIpAdresses = () => {
		if (isEditionMode) {
			seterrorRegisterChange(true);
		} else {
			register();
		}
	};
	const deleteTheAdress = () => {
		const newIpAddresses = ipAddresses.filter(ip => !(ip.ipAddress === adressToDelete.ipAddress && ip.label === adressToDelete.label));
		setIpAddresses([...newIpAddresses]);		
		setAdressToDelete();
	};
	const handleChangeIpAdresseByCopy = (e) => {
		setEditingAdress(c => ({ 
			...c, ipAddress: e
		}));
	};
	const createNewAdresseCurrent = () => {
		setAddnewIpAdress(true);
		setisEditionMode(true);
		setEditingAdress({
			ipAddress: currentIP,
			label: '',
			isDisabled: false,
			key: uuidv4()
		});
	};
	const checkMessageForCurrentIpAdress = (ipCurrent) => {
		const ipInList = ipAddresses.find(ip => ip.ipAddress === ipCurrent);
		if (!ipInList) {
			setcurrentIpMessage(
				<p>{t('executionModeCampaign.currentIpNotInList', {ipCurrent: ipCurrent})}</p>
			);
		} else if (ipInList.isDisabled) {
			setcurrentIpMessage(
				<p>{t('executionModeCampaign.ipNotSelected')}</p>
			);
		} else if (!ipInList.isDisabled) {
			setcurrentIpMessage(
				<p>{t('executionModeCampaign.currentIpSelected')}</p>
			);
		}
	};
	useEffect(() => {
		// if (forcedStatus && forcedStatus == 'started') {
		// 	setStateOfOperationToValidate('ongoing');
		// 	return;
		// }
		if (campaignSelected) {
			if (campaignSelected.ExecutionMode == 'DEFAULT') {
				if (campaignSelected.IsStopped) {
					setStateOfOperationToValidate('stopped');
				} else {
					setStateOfOperationToValidate('ongoing');
				}
			} else if (campaignSelected.ExecutionMode == 'Test') {
				setStateOfOperationToValidate('testMode');
			}
		}
	}, [campaignSelected, forcedStatus]);

	return (
		<div>
			<Modal
				isOpen={modalTestModeIsOpen}
				width="700"
				// height='700'
				onClose={() => openCloseModalTestMode(false)}
			>
				<div className="modal_header has_border">
					{t('executionModeCampaign.startingOpts')}
					<div className="modal_header_desc">{campaignSelected.Name}</div>
				</div>
				<div className="modal_body">
					{adressToDelete &&
                        <PopinConfirm
                        	cancel={() => setAdressToDelete()}
                        	title={t('executionModeCampaign.wantToDelete',{
                        		label :adressToDelete.label,
                        		ipAddress :adressToDelete.ipAddress
                        	})} >
                        	<Btn
                        		onClickFunction={(e) => { setAdressToDelete(); }}
                        		message={t('executionModeCampaign.no')}
                        		color="alert"
                        	/>
                        	<Btn
                        		onClickFunction={deleteTheAdress}
                        		message={t('executionModeCampaign.yes')}
                        		color="primary"
                        	/>
                        </PopinConfirm>}
					{errorRegisterChange &&
                        <PopinConfirm
                        	cancel={() => seterrorRegisterChange(false)}
                        	title={t('executionModeCampaign.saveCurrentChanges')} >
                        	<Btn
                        		onClickFunction={(e) => { seterrorRegisterChange(false); }}
                        		message={t('executionModeCampaign.no')}
                        		color="alert"
                        	/>
                        	<Btn
                        		onClickFunction={() => register()}
                        		message={t('executionModeCampaign.yes')}
                        		color="primary"
                        	/>
                        </PopinConfirm>}
					<article className="radio_box_inner flex_justify_center">
						<label className={stateOfOperationToValidate === 'testMode' ? 'radio_box s checked' : 'radio_box s '}
							onClick={(e) => { setStateOfOperationToValidate('testMode'); handleChangeTestMode(true); }}
						>
							<input
								type="radio"
								value="TEST_MODE"
								className=""
								name="31"
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fa fa-vial"></i>
							<p>{t('executionModeCampaign.Draft')}</p>
						</label>
						<label
							className={stateOfOperationToValidate === 'ongoing' ? 'radio_box s checked' : 'radio_box s'}
							onClick={(e) => { setStateOfOperationToValidate('ongoing'); handleChangeTestMode(true); }}
						>
							<input type="radio"
								value="ONGOING"
								name="33"
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fa fa-play"></i>
							<p>{t('executionModeCampaign.Started')}</p>
						</label>
						<label
							className={stateOfOperationToValidate === 'stopped' ? 'radio_box s checked' : 'radio_box s'}
							onClick={(e) => { setStateOfOperationToValidate('stopped'); handleChangeTestMode(true); }}
						>
							<input
								type="radio"
								value="STOPPED"
								name="32"
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fa fa-pause"></i>
							<p>{t('executionModeCampaign.Stopped')}</p>
						</label>
					</article>
					{stateOfOperationToValidate !== 'testMode' &&
                        <div className="starting_options_message al_center">
                        	{stateOfOperationToValidate === 'stopped' &&
                                t('executionModeCampaign.thisCampaignWillStopped')
                        	}
                        	{stateOfOperationToValidate === 'ongoing' &&
                                 t('executionModeCampaign.campaignOngoing')
                        	}
                        </div>
					}
					{stateOfOperationToValidate && stateOfOperationToValidate == 'testMode' && <>
						<div className="flex flex_align_end mb_20 mt_20">
							<div className="flex_item_full">
								<p>{t('executionModeCampaign.campaignWillBeVisible')}</p>
								{currentIpMessage}
							</div>
							<div className="flex_item_fix ml_20">
								<button className="starting_options_action" onClick={createNewAdresse}>
									<i className="fas fa-plus"></i>
									{t('executionModeCampaign.addNewAddress')}
								</button>
							</div>
						</div>
						<div className="ip_address_table">
							<div className="table-row table-header">
								<p>{t('executionModeCampaign.ipAddresses')}</p>
								<p>{t('executionModeCampaign.Name')}</p>
								<p></p>
							</div>
							{ipAddresses.length == 0 && < div className="starting_options_no_ip">
							{t('executionModeCampaign.noIp')}
							</div>}
							{addNewIpAdress && <div className="table-row table-body editing" >
								<p style={{ display: 'flex', flexDirection: 'column' }}>
									<input type="text" id="ipAddressName"
										name="ipAddress"
										value={editingAdress.ipAddress}
										onChange={(e) => handleChangeIpAdresse(e)}
										autoFocus="" />
									{editingAdress.ipAddress !== currentIP &&
                                        <span className={classes.containerIP}>
                                        	<span>{t('executionModeCampaign.currentIp')}</span>
                                        	<button onClick={(e) => handleChangeIpAdresseByCopy(currentIP)}>{currentIP}< i className="fas fa-level-up-alt"></i></button>
                                        </span>}
								</p>
								<p><input type="text"
									name="label"
									value={editingAdress.label}
									onChange={(e) => handleChangeIpAdresse(e)}
									autoFocus=""
									id="ipAddressLabel" />
								{editingAdress.ipAddress !== currentIP && <span className={classes.containerIP}></span>}
								</p>
								<p className="al_right">
									<a className="starting_options_icon" title="Save"
										onClick={ValidNewIpAdress}>
										<i className="fas fa-save"></i>
									</a>
									<a className="starting_options_icon" title="Cancel"
										onClick={cancel}>
										<i className="fas fa-times"></i>
									</a>
								</p>
							</div>}
							{ipAddresses.map(adress =>
								<div className={adress.ipAddress == currentIP ? 'table-row table-body fw_medium' : 'table-row table-body'} key={adress.key}>
									{editingAdress.key === adress.key ? <>
										<p>
											<input type="text" id="ipAddressName"
												name="ipAddress"
												value={editingAdress.ipAddress}
												onChange={(e) => handleChangeIpAdresse(e)}
												autoFocus="" />
										</p>
										<p>
											<input type="text" 
												name="label"
												value={editingAdress.label}
												onChange={(e) => handleChangeIpAdresse(e)}
												autoFocus=""
												id="ipAddressLabel" />
										</p>
										<p className="al_right">
											<a className="starting_options_icon" title={t('executionModeCampaign.Save')} onClick={ValidExistantIpAdress}>
												<i className="fas fa-save"></i>
											</a>
											<a className="starting_options_icon" title={t('executionModeCampaign.Cancel')} onClick={cancel}>
												<i className="fas fa-times"></i>
											</a>
										</p>
									</>
										:
										<>
											<p>
												<label className="cb_label flex_inline">
													<input
														type="checkbox"
														name="ipEnabled[]"
														checked={!adress.isDisabled}
														onChange={(e) => handleChangeAdressIpToList(e, adress)} />
													<span className="cb_label_icon flex_item_fix flex_item_align_start"></span>
													<span className="cb_label_text flex_item_auto">
														{adress.ipAddress}
													</span>
												</label>
											</p>
											<p>
												{adress.label}
											</p>
											<p className="al_right">
												{!isEditionMode && !addNewIpAdress && <>
													<a className="starting_options_icon" ng-show="ipAddress.Source === 'CAMPAIGN'"
														onClick={() => editIpAdress(adress)} title={t('executionModeCampaign.Edit')}>
														<i className="fas fa-pen"></i>
													</a>
													<a className="starting_options_icon" ng-show="ipAddress.Source === 'CAMPAIGN'"
														onClick={() => setAdressToDelete(adress)} title={t('executionModeCampaign.Cancel')}>
														<i className="fas fa-trash-alt"></i>
													</a> </>
												}
											</p>
										</>
									}
								</div>)}
						</div> </>}
				</div>
				<div className="modal_footer modal_footer_center">
					<Btn
						onClickFunction={() => openCloseModalTestMode(false)}
						message={t('executionModeCampaign.Cancel')}
						color="secondary"
						size="l"
						style="outline"
					/>
					{isEditionMode ?
						<Btn
							disabled={true}
							message={t('executionModeCampaign.Confirm')}
							size="l"
							color="primary"
						/>
						:
						<Btn
							onClickFunction={stateOfOperationToValidate == 'testMode' ?
								() => registerTheNewIpAdresses() :
								() => registerWithoutIp()
							}
							message={t('executionModeCampaign.Confirm')}
							size="l"
							color="primary"
						/>
					}
				</div>

				{isLoading &&
                    <div className="spinner_overlay">
                    	<div className="spinner">
                    		<div className="rect1"></div>
                    		<div className="rect2"></div>
                    		<div className="rect3"></div>
                    		<div className="rect4"></div>
                    		<div className="rect5"></div>
                    	</div>
                    </div>
				}
			</Modal>
		</div >
	);
}
