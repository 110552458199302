import React, {useState, useEffect} from 'react';

import SourceFeedListType from './SourceFeedListType';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
import editionStyles from '../Edition/ProductFeedEdition.module.css';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import EmptyState from '../../../Components/EmptyState';
import CreateUriFeed from './CreateUriFeed';
import CreatePrestashopFeed from './CreatePrestashopFeed';
import SourceSettingsScreen from './SourceSettingsScreen';
import CreateCustom from './CreateCustom';
import CreateOnsiteData from './CreateOnsiteData';
import CreateMazeberry  from './CreateMazeberry';
import CreateShopify from './CreateShopify';
import CreateHttp  from './CreateHttp';
export default function CreationContainer() {

	const {
		stateOfConnection,
		setstateOfConnection,
		setstateOfBackendDetection,
		stateOfBackendDetection,
		isLoadingConnection,
		typeOfSourceSelected,
		productFeedObject,
		setisEmptyIdentifier,
		setmessageInfos,
		handleChangeStep,
		mappingHasBeging
	} = useProductFeedEditionContext();

	function headerCreation(){
		function returnTo(){
			setisEmptyIdentifier(false);
			setmessageInfos('');
			if(stateOfBackendDetection === 'ResourceIdentifier'){
				return setstateOfConnection(1);
			}
			if(stateOfBackendDetection === 'Authentication'){
				return setstateOfBackendDetection('ResourceIdentifier');
			}
			if(stateOfBackendDetection === 'ResourceIdentifierAndOrAuthentication'){
				return setstateOfBackendDetection('ResourceIdentifier');
			}
			if(stateOfBackendDetection === 'FeedFormat'){
				//return setstateOfBackendDetection('ResourceIdentifier');
				return handleChangeStep(2);
			}
			if(stateOfBackendDetection === 'FinalParameters'){
				return setstateOfBackendDetection('ResourceIdentifier');
			}
		}

		return (
			<div className={editionStyles.card_back_title} onClick={()=>returnTo()}>
				<div className={editionStyles.card_back_title_icon}>
					<i className='fas fa-arrow-left fa-fw'></i>
				</div>
				<div className={editionStyles.card_back_title_txt}>
					{stateOfBackendDetection == 'ResourceIdentifier'  && !mappingHasBeging ?
						<>How to connect to your product feed?</>
						:
						<> {!mappingHasBeging && productFeedObject.resourceIdentifier}</>
					}
					{mappingHasBeging &&
						<>Back to mapping </>
					}
					
				</div>
				
			</div>
		);
	}

	return (
		<div>
			{stateOfConnection === 1 &&
				<SourceFeedListType />
			}
			{stateOfConnection === 2 &&
				<div className={editionStyles.card}>
					{isLoadingConnection &&
						<div className={editionStyles.card_body_l}>
							<EmptyState
								title='Feed analysis is in progress'
								text='It may take a few seconds'
								spinner={true}
								verticalSize="l"
							/>
						</div>
					}
					{!isLoadingConnection &&
						<div className={editionStyles.card_body_l}>
							{headerCreation()}
							{stateOfBackendDetection === 'FeedFormat' &&
								<SourceSettingsScreen/>
							}
							{typeOfSourceSelected === 'PrestaShop' && 
								<CreatePrestashopFeed/>
							}
							{typeOfSourceSelected === 'GenericUri' && stateOfBackendDetection !== 'FeedFormat' &&
								<CreateUriFeed />
							}
							{typeOfSourceSelected === 'Custom' && stateOfBackendDetection !== 'FeedFormat' &&
								<CreateCustom />
							}
							{typeOfSourceSelected === 'OnSiteData' && stateOfBackendDetection !== 'FeedFormat' &&
								<CreateOnsiteData />
							}
							{typeOfSourceSelected === 'Mazeberry' && stateOfBackendDetection !== 'FeedFormat' &&
								<CreateMazeberry />
							}
							{typeOfSourceSelected === 'Shopify' && stateOfBackendDetection !== 'FeedFormat' &&
								<CreateShopify />
							}
							{typeOfSourceSelected === 'Http' && stateOfBackendDetection !== 'FeedFormat' &&
								<CreateHttp  />
							}
						</div>
					}
				</div>
			}
		</div>
	);
}
