import React, { useState, useEffect, useRef } from 'react';
import { react2angular } from 'react2angular';
import SystemServices from '../../../Services/SystemServices';
import ShelvingSourceServices from '../../../Services/ShelvingSourceServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SelectDropdown from '../../../Components/SelectDropdown.js';
import NumericUpDown from '../../../Components/NumericUpDown';
import Btn from '../../../Components/Btn';
import './RecoSelector.css';
import useFeature from '../../../../flags/useFeature';
 

const isClassicShelving = (rule) => rule.ruleConfiguration.contextType === 'ClassicShelving';

export default function RecoSelector(props) {
	const emptySelectItems = [{ id: 0, label: 'choose', shelving: '', NumberOfProducts: 1, ruleConfiguration: {} }];
	const [listOfShelvingRules, setListOfShelvingRules] = useState([]);
	const [selectedRules, setSelectedRules] = useState(emptySelectItems);
	const [slide, setSlide] = useState([]);
	const systemServices = new SystemServices(props.$rootScope);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const currentAccount = impersonatedAccount || props.$rootScope.User.Account.Key;

	const shelvingSourceServices = new ShelvingSourceServices(props.$http, currentAccount);

	const { features } = useFeature();

	props.onLoad(function (s) {
		setSlide(s);
		if (s.Properties.ShelvingRules) {
			var rules = JSON.parse(s.Properties.ShelvingRules);
			var initialRules = [];
			for (let i = 0; i < rules.length; i++) {
				const slideRule = rules[i];
				const ruleConfiguration = listOfShelvingRules.find(x => x.id === slideRule.Id) || {};
				const label = ruleConfiguration.name || slideRule.Id;
				const ruleWithInfo = addSlidePropertyToRuleInfo({
					id: i + 1,
					label: label,
					shelving: slideRule.Id,
					NumberOfProducts: slideRule.NumberOfProducts,
					ruleConfiguration
				}, slideRule);
				initialRules.push(ruleWithInfo);
			}
			setSelectedRules(initialRules.length > 0 ? initialRules : emptySelectItems);
		} else {
			setSelectedRules(emptySelectItems);
		}
	});

	const getProductsShelving = (callbackSuccess) => shelvingSourceServices.getShelvingSources('OnSiteCampaign', callbackSuccess, error => systemServices.showError('An error occured while retrieving recommendations'));
	const getAllProductsShelvingRules = () => {
		getProductsShelving(
			newData => {
				setListOfShelvingRules([...newData]);
			});
	};
	useEffect(() => {
		getAllProductsShelvingRules();
	}, []);

	function sortArray(a, b) {
		if (a.id < b.id) return -1;
		if (a.id > b.id) return 1;
		return 0;
	}
	const changeValueOfSelect = (value, ruleId) => {
		const rule = listOfShelvingRules.find(x => x.id === value);

		let itemToChange = selectedRules.find(x => x.id === ruleId);
		itemToChange.shelving = value;
		itemToChange.label = rule.name;
		itemToChange.ruleConfiguration = rule;

		let newArray = selectedRules.map(x => x.id === ruleId ? itemToChange : x);
		setSelectedRules(newArray);
		synchronizeSlideShelvingRules();
	};

	const changeValueOfSubContext = (value, ruleId) => {
		let itemToChange = selectedRules.find(x => x.id === ruleId);
		itemToChange.subContext = value;

		let newArray = selectedRules.map(x => x.id === ruleId ? itemToChange : x);
		setSelectedRules(newArray);
		synchronizeSlideShelvingRules();
	};

	const addNewSelect = () => {
		const array = [...selectedRules];
		let ids = [];
		array.forEach(x =>
			ids.push(x.id));
		let newId;
		const getNewId = () => {
			const number = parseInt(Math.max(...ids) + 1);
			if (ids.includes(number)) {
				getNewId();
			}
			else {
				newId = number;
			}
		};
		getNewId();
		array.push({ ...emptySelectItems[0], id: newId });
		setSelectedRules(array);
	};
	const deleteItemFromArrayOfAlgo = (e) => {
		const tabs = [...selectedRules];
		const newTabs = tabs.filter(x => x.id !== e);
		if (newTabs.length < 1) {
			newTabs.push(emptySelectItems[0]);
		}
		setSelectedRules([...newTabs]);
	};

	const addSlidePropertyToRuleInfo = (rule, slideProperty) => {
		const addSubContext = (p) => {
			const context = slideProperty.ContextDetail;
			if (!isClassicShelving(rule) && (context !== 'Global')) {
				return { ...p, subContext: slideProperty.RuleScope };
			}
			return p;
		};
		return addSubContext(rule);
	};

	const addRuleInfoToSlideProperty = (rule, slideProperty) => {
		const addContextDetail = (p) => {
			const context = rule.ruleConfiguration.context;
			if (!isClassicShelving(rule) && (context === 'ProductCategory' || context === 'ProductTag')) {
				return { ...p, ContextDetail: context };
			}
			return p;
		};
		const addTenant = (p) => {
			if (!isClassicShelving(rule) && rule.tenant) {
				return { ...p, Tenant: rule.tenant };
			}
			return p;
		};
		return addTenant(addContextDetail(slideProperty));
	};


	const synchronizeSlideShelvingRules = () => {
		const buildScope = (rule, context) => {
			if (isClassicShelving(rule)) {
				return context;
			} else {
				if (rule.ruleConfiguration.context === 'Global') {
					return 'global';
				}
				return rule.subContext;
			}
		};

		let rulesProperty = [];
		let newSelectedRules = selectedRules.filter(x => x.shelving);
		newSelectedRules && newSelectedRules.forEach(x => {
			const rule = listOfShelvingRules.find(r => r.id === x.shelving);
			if (!rule) return;
			const context = rule.context;
			const scope = buildScope(x, context);
			if (scope && x.NumberOfProducts > 0) {
				const slideProperty = { Id: x.shelving, RuleScope: scope, NumberOfProducts: x.NumberOfProducts };
				const slidePropertyWithAddedInfo = addRuleInfoToSlideProperty(x, slideProperty);
				rulesProperty.push(slidePropertyWithAddedInfo);
			}
		});
		slide.Properties && (slide.Properties.ShelvingRules = JSON.stringify(rulesProperty));
	};

	const upNumberOfProductTarget = (e, id) => {
		let array = [...selectedRules];
		let newItem = array.filter(x => x.id === id)[0];
		newItem.NumberOfProducts = parseInt(e.target.value);
		const newArr = array.filter(x => x.id !== id);
		setSelectedRules([...newArr, newItem]);
	};

	useEffect(() => {
		if (selectedRules.length) {
			synchronizeSlideShelvingRules();
		}
	}, [selectedRules]);


	const displayContextSelector = (algo) => {
		const getOptions = (context) => {
			switch (context) {
			case 'Global':
				return [];
			case 'Category':
				if (features.includes('emerch-demo')){
					return [['categoryCurrentPage', 'Category from current page'], ['categoryFromHistory', 'Category from history (cold)'], ['categoryFromHistory2', 'Category from history (hot)'], ['categoryFromCart', 'Category from cart'], ['categoryFromLastPurchase', 'Category from last purchase']];
				} else {
					return [['categoryCurrentPage', 'Category from current page'], ['categoryFromHistory', 'Category from history'], ['categoryFromCart', 'Category from cart']];
				}
			case 'Tag':
				if (features.includes('emerch-demo')){
					return [['tagCurrentPage', 'Tag from current page'], ['tagFromHistory', 'Tag from history (cold)'], ['tagFromHistory2', 'Tag from history (hot)'], ['tagFromCart', 'Tag from cart'], ['tagFromLastPurchase', 'Tag from last purchase']];
				} else {
					return [['tagCurrentPage', 'Tag from current page'], ['tagFromHistory', 'Tag from history'], ['tagFromCart', 'Tag from cart']];
				}
			case 'ProductGlobal':
			case 'ProductCategory':
			case 'ProductTag':
				if (features.includes('emerch-demo')){
					return [['productCurrentPage', 'Product from current page'], ['productFromHistory', 'Product from history (cold)'], ['productFromHistory', 'Product from history (hot)'], ['productFromCart', 'Product from cart'], ['productFromPurchase', 'Product from last purchase']];
				} else {
					return [['productCurrentPage', 'Product from current page'], ['productFromHistory', 'Product from history'], ['productFromCart', 'Product from cart']];
				}
			default:
				return [];
			}
		};

		const options = getOptions(algo.ruleConfiguration.context);
		if (options.length === 0)
			return <></>;

		return (
			<div className='selectContainerReco_col_context'>
				<SelectDropdown
					label="Context"
					labelClassName="grey_1"
					placeHolder='Choose a context'
					optionsList={options.map(x => { return { 'value': x[0], 'label': x[1] }; })}
					value={algo.subContext}
					onChange={(v) => changeValueOfSubContext(v, algo.id)}
					fullWidth={true}
				/>
			</div>
		);
	};

	return (
		<div className="options RecosettingListContainer">
			<h5>Recommendations settings</h5>
			{selectedRules && selectedRules.sort(sortArray).map((algo, i) =>
				<div className="selectContainerReco" key={i}>
					<div className='selectContainerReco_col_algo'>
						<SelectDropdown
							label="Algorithm"
							labelClassName="grey_1"
							placeHolder='Choose an algorithm'
							optionsList={listOfShelvingRules.map(x => { return { 'value': x.id, 'label': x.name }; })}
							value={algo.shelving}
							onChange={(v) => changeValueOfSelect(v, algo.id)}
							fullWidth={true}
						/>
					</div>
					<div className='selectContainerReco_col_nb'>
						<NumericUpDown
							min={0}
							value={algo.NumberOfProducts}
							onChange={(e) => upNumberOfProductTarget(e, algo.id)}
							canEdit={true}
							label="Nb. of products"
							labelClassName="grey_1"
						/>
					</div>

					{!isClassicShelving(algo) &&
						displayContextSelector(algo)
					}

					<div className="selectContainerReco_col_del align_with_labeled">
						{selectedRules.length > 1 &&
							<Btn
								icon="fas fa-trash"
								style="ghost"
								color='secondary'
								onClick={(e) => deleteItemFromArrayOfAlgo(algo.id)}
							/>
						}
					</div>

				</div>

			)}
			<div className="reco_actions">
				<Btn
					icon="fas fa-plus"
					message="Add an algorithm"
					style="outline"
					color='secondary'
					onClick={addNewSelect}
				/>
			</div>
		</div>
	);
}
angular
	.module('beyableSaasApp.Campaigns.Create.Controllers',)
	.component('recoSelector', react2angular(RecoSelector, ['onLoad'], ['$http', '$routeParams', '$rootScope', '$scope']));