import React, { useContext, useEffect, useState } from 'react';
import '../../BusinessOperationBanner.css';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import CustomizeBanner from './CustomizeBanner';
import useSort from '../../../../Hooks/useSort';
import useEventBroker from '../../../../Hooks/useEventBroker';
import BannerVideoPlayer from './BannerVideoPlayer';
import Portlet from './Portlet';

export default function CustomizeBannersContainer() {

	const {
		listBanners,
		setListBanners
	} = useContext(CreateBusinessOperationContext);

	const { subscribe } = useEventBroker();

	subscribe('evt/businessoperations/bannerCropped', (e) => {
		const { newBannerList, _ } = e.detail;
		setListBanners([...newBannerList]);
	}, []);

	const [listBanners_HH, setListBanners_HH] = useState([]);
	const [listBanners_H, setListBanners_H] = useState([]);
	const [listBanners_S, setListBanners_S] = useState([]);
	const [listBanners_V, setListBanners_V] = useState([]);
	const [listBannersVideoPlayer, setlistBannersVideoPlayer] = useState([]);
	const [portletIdOpen, setportletIdOpen] = useState({
		banner: false,
		custom: false,
		video: false
	});
	const openClosePortlet = (portlet) => {
		const value = portletIdOpen[portlet];
		setportletIdOpen(port => ({
			...port,
			[portlet]: !value
		}));
	};
	const { sortByHeightHook } = useSort();

	useEffect(() => {
		var hh = [];
		var h = [];
		var v = [];
		var s = [];
		let bannerVideoPlayer = [];
		if (listBanners) {
			// banners images
			listBanners.filter(ban => ban.bannerType !== 'Slider' && ban.bannerType !== 'VideoPlayer').sort(sortByHeightHook).forEach(item => {
				let ratio = item.metaData.size.width / item.metaData.size.height;
				if (ratio > 4) {
					hh.push(item);
					return;
				}
				if (ratio > 2) {
					h.push(item);
					return;
				}
				if (ratio < 0.5) {
					v.push(item);
					return;
				}
				s.push(item);
			});
			// banner videoPlayer
			const videoPlayerArray = listBanners.filter(ban => ban.bannerType === 'VideoPlayer');
			bannerVideoPlayer.push(...videoPlayerArray);
		}

		setListBanners_HH(hh);
		setListBanners_H(h);
		setListBanners_V(v);
		setListBanners_S(s);
		setlistBannersVideoPlayer(bannerVideoPlayer);

	}, [listBanners]);

	return (
		<div>
			<Portlet label="Available banners" defaultOpen={true}>
				<div className="customizeBanners">
					{listBanners.length != 0 &&
						<div className="bannersListWrapper bannersListWrapperSmall">
							{listBanners_HH &&
								<div className="bannersList">
									{listBanners_HH.map((banner, bannerKey) =>
										<CustomizeBanner banner={banner} key={banner.bannerId} />
									)}
								</div>
							}
							{listBanners_H &&
								<div className="bannersList">
									{listBanners_H.map((banner, bannerKey) =>
										<CustomizeBanner banner={banner} key={banner.bannerId} />
									)}
								</div>
							}
							{listBanners_S &&
								<div className="bannersList">
									{listBanners_S.map((banner, bannerKey) =>
										<CustomizeBanner banner={banner} key={banner.bannerId} />
									)}
								</div>
							}
							{listBanners_V &&
								<div className="bannersList">
									{listBanners_V.map((banner, bannerKey) =>
										<CustomizeBanner banner={banner}
											key={banner.bannerId} />
									)}
								</div>
							}
						</div>
					}
					{listBanners.length == 0 &&
						<div className="customizeBannersEmpty">
							No banner is available for this operation.<br/>
							Please go to the Design step to upload a banner.
						</div>
					}
				</div>
			</Portlet>

			<Portlet label="Available videos" defaultOpen={listBannersVideoPlayer.length ? true : false}>
				<div className="customizeBanners">
					{listBannersVideoPlayer.length !== 0 ?
						<div className="bannersListWrapper bannersListWrapperSmall">
							<div className="bannersList">
								{listBannersVideoPlayer.map(banner =>
									<BannerVideoPlayer
										key={banner.bannerId}
										banner={banner}
									/>
								)}
							</div>
						</div> :
						<div className="customizeBannersEmpty">
							No video available for this operation.
						</div>
					}
				</div>
			</Portlet>

			<Portlet label="Available custom library" defaultOpen={false}>
				<div className="customizeBannersEmpty">
					No custom library is available for this operation.
				</div>
			</Portlet>
		</div>
	);
}
