export function getFinalUrl(module, props, needKa, user) {
	const typeOfModule = typeof module;
	const url = typeOfModule === 'object' ? module.baseUrl : module;
	if(!url) return;
	let sep = url.includes('?') ? '&' : '?';
	const ka = props.$routeParams.ka;
	const ku = props.$routeParams.ku;
	if(!ka){
		if(needKa){
			return  url + (needKa ? '/' + user.Key : '') + '?' + 'ka='+ user.Key +'&ku='+ ku;
		}
		return url;
	}else{
		return  url + (needKa ? '/' + ka : '') + sep + 'ka='+ ka +'&ku='+ ku;
	}
}
export function getAccountModules(listModules, allModules){
	const exists = [
		'insights',
		'ready',
		'onsite',
		'abtests',
		'simplify',
		'emr',
		'emailing',
		'analytics',
		'scoring',
		'productMonitoring',
		'eMerch',
		'productCatalog'
	];
	const clientModules =[];
	exists.forEach(x => listModules.find(z => z === x) && clientModules.push(x) );
	const modules = [];
	clientModules.forEach(item => {
		const currentModule = allModules.find(element => element.id === item);
		if(currentModule ){
			modules.push(currentModule);
		}
	});
    
	return modules;
}

export function getIsAdminView() {
	const value = sessionStorage.getItem('adminModeView');
	const bool = value === 'client' ? false : !!value;
	return bool;
}

export function setIsAdminView(value) {
	if (value) {
		sessionStorage.setItem('adminModeView', 1);
	} else {
		sessionStorage.removeItem('adminModeView');
	}
}

export function getStorageModulesIsFirstPage() {
	const value = localStorage.getItem('moduleIsFirstPage');
	return !!value;
}

export function setStorageModulesIsFirstPage(value) {
	if (value) {
		localStorage.setItem('moduleIsFirstPage', 1);
	} else {
		localStorage.removeItem('moduleIsFirstPage');
	}
}
