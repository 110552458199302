/* eslint-disable no-undef */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { initialDates } from '../components/Nav/config';
import {initialUtmFilterV2} from '../../WebAnalytics/components/Containers/Ecommerce/config';

import {
	AverageQuantityInTransactions,
	sourceTypes,
	metricsTypesGRaph,
	SessionToSaleRatio,
	Revenue,
	metricsTypes,
	reportTypes
} from '../components/Performance/config';
const devices = [
	{
		value : 'Desktop',
		label : 'Desktop'
	},
	{
		value : 'Mobile',
		label : 'Mobile'
	},
	{
		value : 'Tablet',
		label : 'Tablet'
	}
];
const EcommerceTransactionContext = createContext();

export const useEcommerceTransactionContext = () => {
	return useContext(EcommerceTransactionContext);
};
const EcommerceTransactionContextProvider = (props) => {

	const FORMAT = 'YYYY/MM/DD';
	const currentDate = new Date;
	const [navFilter, setNavFilter] = useState({
		fromDate: moment(currentDate).subtract(7, 'day').format(FORMAT),
		toDate: moment(currentDate).format(FORMAT),
		fromDateToCompare: moment(currentDate).subtract(7, 'day').format(FORMAT),
		toDateToCompare: moment(currentDate).format(FORMAT),
		filters: []
	});
	const [isCustomSelected, setisCustomSelected] = useState(false);
	const [customDateRange, setCustomDateRange] = useState( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const [selectedPeriod, setSelectedPeriod] = useState(initialDates);
	const [needRefreshForUtmFilter, setneedRefreshForUtmFilter] = useState(false);
	// FILTER STATES
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [dimension, setdimension] = useState('');
	const [dimensionId, setDimensionId] = useState('');
	const [sourceType, setSourceType] = useState([]);
	const [utmDimensionsSelected, setUtmDimensionsSelected] = useState(initialUtmFilterV2);
	const [utmSelected, setUtmSelected] = useState({
		source: false,
		medium: false,
		campaign: false,
		content: false
	});
	function resetUtmSelected() {
		setUtmSelected({
			source: false,
			medium: false,
			campaign: false,
			content: false
		});
	}
	function handleSelectUtmDimension(utmDimension, value) {

		setUtmSelected(dimensions => ({
			...dimensions,
			[utmDimension]: value
		})
		);
	}
	function hansleSaveUtmDimensionFilter(newFilterUtm) {
		// for (const key in newFilterUtm) {
		// 	const utmSelectionElementStatus = utmSelected[key];
		// 	if (!utmSelectionElementStatus) {
		// 		newFilterUtm[key].name = '';
		// 		newFilterUtm[key].label = '';
		// 	}
		// }
		setneedRefreshForUtmFilter(!needRefreshForUtmFilter);
		setUtmDimensionsSelected(newFilterUtm);
		setOffset(0);
		setCurrentPage(1);
	}
	const [needRefreshBoard, setNeedRefreshBoard] = useState(false);

	const [orderBy, setOrderBy] = useState('Revenue');
	const [orderByDesc, setOrderByDesc] = useState('desc');
	const [deviceSelected, setdeviceSelected] = useState([]);


	function changeTypeChartSelected(elemSelect) {
		typeChartSelected !== elemSelect && setTypeChartSelected(elemSelect);
	}
	const onSelectMetricType = (val) => {
		if (val.key !== metricTypPerformanceSelected.key) {
			const newTypeFiltered = metricsTypesGRaph.find((t) => t.key === val.key);
			newTypeFiltered && setmetricTypePerformanceSelected(val);
		}
	};
	const initiNavDate = (navState) => {
		if (!navState || !navState.fromDate || !navState.toDate)
			return initialDates;
		return {
			initialLabel: findNavLabel(navState.fromDate, navState.toDate),
			initialLabel2: findNavLabel(navState.fromDateToCompare, navState.toDateToCompare),
			initialFrom: navState.fromDate,
			initialTo: navState.toDate,
			fromDateToCompare: navState.fromDateToCompare,
			toDateToCompare: navState.toDateToCompare
		};
	};

	const onChangeNavFilter = (nav) => {
		setOffset(0);
		setCurrentPage(1);
		setNavFilter((currentNavFilter) => ({ ...currentNavFilter, ...nav }));
	};

	const findNavLabel = (fromDate, toDate) => {
		// format initNavState
		const FORMAT = 'YYYY/MM/DD';
		var last7days = moment(toDate).subtract(7, 'day').format(FORMAT);
		var last30days = moment(toDate).subtract(30, 'day').format(FORMAT);

		if (fromDate == toDate) return 'Today';
		if (last7days == fromDate) return 'Last 7 days';
		if (last30days == fromDate) return 'Last 30 days';
		else return 'Custom';
	};

	function handleSelectComparaisonPeriodHandler(value) {
		setComparisonSelected(value);
	}

	const handleChangeSourceType = (val) => {
		setOffset(0);
		setCurrentPage(1);
		const oldSources = [...sourceType];
		const indefOfVal = oldSources.indexOf(val);
		if(indefOfVal !== -1){
			setSourceType(oldSources.filter(x => x !== val));
		}else{
			setSourceType([...oldSources,val]);
		}
	};
	const resetSource = () => {
		setOffset(0);
		setCurrentPage(1);
		setSourceType([]);
	}

	function handleChangeDeviceSelected(elem){
		const newKey = deviceSelected.find(el => el === elem);
		const oldD = [...deviceSelected];
		const indefOfVal = oldD.indexOf(elem);
		if(indefOfVal !== -1){
			setdeviceSelected(oldD.filter(x => x !== newKey));
		}else{
			setdeviceSelected([...oldD,elem]);
		}
	}
	function resetDevices(){
		setdeviceSelected([]);
	}
	return (
		<EcommerceTransactionContext.Provider value={{


			// DATE
			initiNavDate,
			navFilter,
			setNavFilter,
			handleSelectComparaisonPeriodHandler,
			isCustomSelected,
			setisCustomSelected,
			customDateRange,
			setCustomDateRange,
			// Filters
			sourceType,
			sourceTypes,
			resetSource,
			reportTypes,
			handleChangeSourceType,
			onChangeNavFilter,
			deviceSelected,
			devices,
			handleChangeDeviceSelected,
			
			// TABLE
			orderBy,
			setOrderBy,
			setOrderByDesc,
			orderByDesc,
			setOffset,
			currentPage,
			setCurrentPage,
			offset, 


			// UTMS 
			utmSelected,
			resetUtmSelected,
			handleSelectUtmDimension,
			hansleSaveUtmDimensionFilter,
			utmDimensionsSelected,
			resetDevices
		}}>{props.children}</EcommerceTransactionContext.Provider>
	);
};

export default EcommerceTransactionContextProvider;
