import React from 'react';
import SelectDropdown from '../../../Components/SelectDropdown';
import { useTrafficContext } from '../context/ContextTraffic';
import { metricsTypes } from './config';
import { useTranslation } from 'react-i18next';

export default function SelectSplitDimension() {

	const [t] = useTranslation(['utils', 'kpi']);
	const {
		onSelectMetricType,
		metricTypeSelected
	} = useTrafficContext();

	const metricTypeTranslated = metricsTypes.map((m) => {
		const copy = {...m};
		if (copy.translation) {
			copy.label = t(copy.translation.key, copy.translation.vars);
		}
		return copy;
	});

	return (
		<SelectDropdown
			label={t('common.splitBy')}
			labelPosition='inner'
			optionsList={metricTypeTranslated}
			value={metricTypeSelected.value}
			onChange={(v) => onSelectMetricType(v)}
			color='white'
		/>
	);
}
