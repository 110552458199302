import React, {useState, useEffect} from 'react';
import EmptyState from '../../../../Components/EmptyState';
import {useBuildEditorContext} from './BuildEditorContext';

export default function BuilderEditorStyleContainer() {

	const {
		buildEditor,
		showStylesPanel,
		componentSelected,
	} = useBuildEditorContext();

	const [activeTab, setActiveTab] = useState('style');
	const [isEmpty, setIsEmpty] = useState(true);
	const [componentName, setComponentName] = useState('');
	const [componentIcon, setComponentIcon] = useState('');
	const [dynamicTabs, setDynamicTabs] = useState([]);
	const cssClass = 'builder_sidebar builder_sidebar_right builder_sidebar_styles vscroll_custom';
	const [eventRegistered, setEventRegistered] = useState(false);

	const handleTransitionEnd = () => {
		if (buildEditor) {
			buildEditor.refresh();
		}
	};

	const getComponentIcon = (c) => {
		if (!c.attributes) return '';
		if (!c.attributes.icon) return '';
		return c.attributes.icon;
	};

	const getComponentName = (c) => {
		const name = c.get('name');
		if (name) return name;

		const type = c.get('type');
		if (type) return type;

		const tagName = c.get('tagName');
		if (tagName) return tagName;

		return '';
	};

	const updateTraitsVisibility = () => {
		if (!componentSelected) return;

		const traits = componentSelected.attributes.traits.models;
		traits.forEach(t => {
			const tab = t.attributes.tab || 'settings';
			const el = t.view.el;
			if (!el) return;
			el.classList.toggle('hide_trait', tab != activeTab);
		});
	};

	useEffect(() => {
		if (componentSelected) {
			setIsEmpty(false);
			const icon = getComponentIcon(componentSelected);
			setComponentIcon(icon);

			const name = getComponentName(componentSelected);
			let displayName = name.charAt(0).toUpperCase() + name.slice(1);
			displayName = displayName.split('_').join(' ');
			setComponentName(displayName);

			// Dynamic tabs
			let tabs = [];
			const traits = componentSelected.attributes.traits.models;
			traits.forEach(t => {
				const tab = t.attributes.tab || 'settings';
				if (tab == 'style') return;
				if (tabs.includes(tab)) return;
				tabs.push(tab);
			});
			setDynamicTabs(tabs);

			updateTraitsVisibility();
		} else setIsEmpty(true);
	}, [componentSelected]);

	useEffect(() => {
		updateTraitsVisibility();
	}, [activeTab]);

	useEffect(() => {
		if (dynamicTabs.length == 0) {
			setActiveTab('style');
			return;
		} 
		const activeTabExist = dynamicTabs.some(tab => tab == activeTab);
		if (!activeTabExist) {
			setActiveTab('style');
		}
	}, [dynamicTabs]);

	return (
		<div className={showStylesPanel ? cssClass : cssClass + ' builder_sidebar_closed'}
			onTransitionEnd={handleTransitionEnd}>
			{isEmpty &&
				<EmptyState
					icon={'fas fa-palette'}
					title='Style panel'
					text='Select an element to style it'
					verticalAlign={true}
				/>
			}
			{!isEmpty &&
				<>
					{componentName &&
						<div className='builder_sidebar_section_styles flex'>
							{componentIcon &&
								<div className='builder_sidebar_icon flex_icon_fix'
									dangerouslySetInnerHTML={{__html: componentIcon}}></div>
							}
							<div className='builder_sidebar_title flex_item_auto'>{componentName}</div>
						</div>
					}
					{dynamicTabs.length > 0 &&
						<ul className='tabs builder_sidebar_tabs'>
							<li>
								<a className={activeTab === 'style' ? 'active' : ''}
									onClick={() => setActiveTab('style')}
								>
									Style
								</a>
							</li>
							{dynamicTabs.map(tab =>
								<li key={tab}>
									<a className={activeTab === tab ? 'active' : ''}
										onClick={() => setActiveTab(tab)}
									>
										{tab}
									</a>
								</li>
							)}
						</ul>
					}
					{dynamicTabs.length == 0 &&
						<div className='builder_sidebar_sep'></div>
					}
				</>
			}
			<div id="builder_tab_styles" style={!isEmpty && activeTab === 'style' ? {} : {'display': 'none'}}></div>
			<div id="builder_tab_traits"></div>
		</div>
	);
}