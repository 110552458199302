import React from 'react';
import './Avatar.css';

function Avatar({
	id = '42',
	name = 'A',
	size = 'm',
	fixedColor
}) {
	const cssClass = ['avatar'];
	const colors = ['red', 'orange', 'yellow', 'green_apple', 'green', 'lightblue', 'blue', 'purple', 'pink'];

	const getColor = function () {
		if (fixedColor) return fixedColor;
		const tmpId = parseInt(id.replace(/^\D+/g, ''));
		const nb = colors.length;
		const index = tmpId % nb;
		return colors[index];
	};

	// INITIAL
	const initial = name.toString().slice(0, 1).toUpperCase();
	
	// SIZE
	if (size) {
		cssClass.push('size_' + size);
	}
	
	// COLOR
	const color = getColor();
	if (color) {
		cssClass.push('color_' + color);
	}
	
	return (
		<div className={cssClass.join(' ')}>
			{initial}
		</div>
	);
}
	
export default Avatar;
