import React, { useEffect, useState, useContext, Fragment } from 'react';
import { isEqual } from 'lodash';
import Select from 'react-select';
import '../../../../Styles/custom-antd.css';
import TriggerGeoloc from './TriggerGeoloc';
import InputCustom from '../../../Components/InputCustom';
import SelectCustom from '../../../Components/SelectCustom';
import NumericUpDown from '../../../Components/NumericUpDown';
import NumericInput from 'react-numeric-input';
import TriggerUtil from './TriggerUtil';
import TriggerSchedule from './TriggerSchedule';
import { TriggerContext } from './TriggerContext';
import ReferenceDataAutoComplete from '../../ReferenceData/ReferenceDataAutoComplete';
import ReferenceDataType from '../../ReferenceData/ReferenceDataType';
import CreatableSelect from 'react-select/creatable';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';

export default function TriggerCard({
	trigger,
	deleteTrigger,
	saveTriggerCallBack,
	icon
}) {

	const { 
		setupAccountServices,
		productTagServices,
		currentAccount
	} = useContext(TriggerContext);

	const { getTheOptionsMultipleList, getTheDefaultValueMultipleList, getDefaultValueList, getDefaultOperator } = TriggerUtil();

	const tooltipID = uuidv4();

	const { getTypeFromTriggerName } = ReferenceDataType();
	const [triggerValue, setTriggerValue] = useState(trigger.ValueToCompare);
	const [triggerOperatorName, setTriggerOperatorName] = useState(getDefaultOperator(trigger));

	useEffect(() => {
		if (!isEqual(triggerValue, trigger.ValueToCompare)){
			saveTriggerCallBack({...trigger, ValueToCompare:triggerValue});
		}
	}, [triggerValue]);
    
	useEffect(() => {
		if (!isEqual(triggerOperatorName, trigger.OperatorName)){
			saveTriggerCallBack({...trigger, OperatorName:triggerOperatorName});
		}
	}, [triggerOperatorName]);
    

	const numericHour = (hour) => {
		if(parseInt(hour) > 24){
			return 24;
		}
		if (parseInt(hour) < 0) {
			return 0;
		}
		return parseInt(hour);
	};

	const isAutoCompleteHardCodedHereForNow = (trigger) => {
		//return false
		return ['TRGTARGETTENANT', 'TRGCHECKURLTAGS', 'TRGGIVEPRODUCTDETAILS'].includes(trigger.Name);
	};

	const onChangeTextAreaInput = (text) => {
		setTriggerValue(text ? text.join(';') : []);
	};

	const onChangeAutocomplete = (text) => {
		if (text.length) {
			text = text.replace(/(\n)+/g, ';');
			text = text.replace(/;+/g, ';');
		}
		setTriggerValue(text);
	};


	const operandSelector = (
		<>
			{trigger.DataTriggerOperands && trigger.DataTriggerOperands.length > 1 &&
                <div className="flex_item_fix trigger_card_operand">
                	<SelectCustom
                		optionsList={trigger.DataTriggerOperands}
                		value={triggerOperatorName}
                		onChange={(e) => setTriggerOperatorName(e.target.value)}
                		autoWidth={true}
                	/>
                </div>
			}
			{trigger.DataTriggerOperands && trigger.DataTriggerOperands.length === 1 &&
                <div className="flex_item_fix trigger_card_operand">
                	<SelectCustom
                		optionsList={trigger.DataTriggerOperands}
                		value={getDefaultOperator(trigger)}
                		autoWidth={true}
                		disabled={true}
                	/>
                </div>
			}
		</>
	);

	let optionsDisplay = 'bottom';
	if( ! trigger.Type ){
		optionsDisplay = false;
	}else if( trigger.Type === 'number' || trigger.Type === 'price' || trigger.Type === 'numberhour' ){
		optionsDisplay = 'right';
	}

	return(
		<Fragment>
			<div className="trigger_card">
				<div className="flex">
					<div className="flex_item_fix">
						<span className={'trigger_icon icon_' + icon}></span>
					</div>
					<div className="flex_item_full flex">
						<div className="flex_item_full trigger_card_info">
							<div className="trigger_card_name">{trigger.Label}</div>
							<div className="trigger_card_desc">{trigger.Tooltip}</div>
						</div>
						{operandSelector}
						{optionsDisplay && optionsDisplay == 'right' &&
                            <div className="flex_item_fix trigger_card_value">
                            	{trigger.Type === 'number' &&
                                    <>
                                    	{trigger.Unit}
                                    	<NumericUpDown
                                    		min={0}
                                    		value={triggerValue ? triggerValue : 0}
                                    		onChange={(e) => setTriggerValue(parseInt(e.target.value))}
                                    		canEdit={true}                        
                                    	/>
                                    </>
                            	}
                            	{trigger.Type === 'price' &&
                                    <InputCustom
                                    	type="text"
                                    	value={triggerValue ? triggerValue : ''}
                                    	onChange={(e) => setTriggerValue(e.target.value)}
                                    	className="w_xs"
                                    />
                            	}
                            	{trigger.Type === 'numberhour' &&
                                    <InputCustom
                                    	type="number"
                                    	value={triggerValue === 0 ? '' : triggerValue}
                                    	onChange={(e) => setTriggerValue(numericHour(e.target.value) || 0)}
                                    	placeHolder = "0"
                                    	className="w_xs"
                                    	unit="Hours"
                                    />
                            	}
                            </div>
						}
						{deleteTrigger}
					</div>
				</div>
				{optionsDisplay && optionsDisplay == 'bottom' &&
                    <div className="flex flex_align_start trigger_card_value_line">
                    	<div className="flex_item_full trigger_card_value w_full">
                    		{trigger.Type === 'multiplelist' && !isAutoCompleteHardCodedHereForNow(trigger) &&
                                <Select
                                	isMulti
                                	options={getTheOptionsMultipleList(trigger)}
                                	defaultValue={getTheDefaultValueMultipleList(trigger)}
                                	onChange={(e) => setTriggerValue((e || []).map(v=> v.value))}
                                	menuPlacement="auto"
                                	className="react-select"
                                	classNamePrefix="react-select"
                                />
                    		}
                    		{trigger.Type === 'list' &&
                                <Select
                                	options={getTheOptionsMultipleList(trigger)}
                                	defaultValue={getDefaultValueList(trigger)}
                                	onChange={(e) => setTriggerValue(e.value)}
                                	menuPlacement="auto"
                                	className="react-select"
                                	classNamePrefix="react-select"
                                />
                    		}
                    		{trigger.Type === 'text' && !isAutoCompleteHardCodedHereForNow(trigger) &&
                                <InputCustom
                                	type="text"
                                	value={triggerValue}
                                	onChange={(e) => setTriggerValue(e.target.value)}
                                	fullWidth={true}
                                />
                    		}
                    		{trigger.Type === 'textarea' &&
                                <InputCustom
                                	type="textarea"
                                	value={triggerValue}
                                	onChange={(e) => setTriggerValue(e.target.value)}
                                	fullWidth={true}
                                	rows={2}
                                	maxHeight={300}
                                />
                    		}
                    		{isAutoCompleteHardCodedHereForNow(trigger) &&
                                <ReferenceDataAutoComplete
                                	setupAccountServices={setupAccountServices}
                                	productTagServices={productTagServices}
                                	currentAccount={currentAccount}
                                	referenceDataType={getTypeFromTriggerName(trigger.Name)}
                                	value={triggerValue}
                                	onChange={(value) => onChangeAutocomplete(value)}
                                />
                    		}
                    		{trigger.Type === 'schedule' &&
                                <TriggerSchedule
                                	triggerValue={trigger.ValueToCompare}
                                	setTriggerValue={setTriggerValue}
                                />                        
                    		}
                    		{trigger.Type === 'geoloc' &&
                                <TriggerGeoloc 
                                	triggerValue={trigger.ValueToCompare}
                                	setTriggerValue={setTriggerValue}
                                />
                    		}
                    	</div>
                    </div>
				}
			</div>
		</Fragment>
	);
}

