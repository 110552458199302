import React, { useEffect, useState } from "react";

import Btn from "../../Components/Btn";
import Dropdown from "../../Components/Dropdown";
import Confirm from "../../Components/Confirm";

export default function Item({
    image,
    handleCopy,
    handleDelete
}) {

    const [confirmIsOpen, setConfirmIsOpen] = useState(false);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    return (
        <li className="media_gallery_item">
            <div className="media_gallery_img_wrapper">
                <img
                    className="media_gallery_img"
                    src={image.imagePath}
                    alt={image.metaData.fileName}
                />
            </div>
            <div className="media_gallery_item_info flex">
                <div className="flex_item_full overflow_hidden">
                    <div className="media_gallery_item_name ellips">{image.metaData.fileName}</div>
                </div>
                <div className="media_gallery_item_action flex_item_fix">
                    <Dropdown
                        isOpen={dropdownIsOpen}
                        setIsOpen={() => setDropdownIsOpen(true)}
                        onHide={() => setDropdownIsOpen(false)}
                        button={
                            <Btn
                                icon="fas fa-ellipsis-v"
                                style="ghost"
                                color="secondary"
                                className="btn_w_xxs"
                                size="xs"
                            />
                        }
                    >
                        <ul className="listbox">
                            <li>
                                <a className="listbox_item" onClick={() => {handleCopy(image.imagePath); setDropdownIsOpen(false);}}>
                                    <i className="listbox_item_icon fas fa-copy" />
                                    Copy image path
                                </a>
                            </li>
                            <li>
                                <a className="listbox_item" onClick={() => {setConfirmIsOpen(true); setDropdownIsOpen(false);}}>
                                    <i className="listbox_item_icon fas fa-trash" />
                                    Delete
                                </a>
                            </li>
                        </ul>
                    </Dropdown>
                </div>
            </div>
            <Confirm
                title="Are you sure?"
                text="Do you really want to delete this image?"
                isOpen={confirmIsOpen}
                confirmText="Delete"
                cancelText="Cancel"
                confirmColor="alert"
                confirmCallback={() => {handleDelete(image); setConfirmIsOpen(false);}}
                onClose={() => setConfirmIsOpen(false)}
            />
        </li>
    )
}
