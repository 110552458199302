import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import styles from '../Board/Board.module.css';

import { useEcommercePerformanceContext } from '../../context/EcommercePerformanceContext';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import { useEcommerceAnalyticsContext } from '../../context/EcommerceAnalyticsContext';
import PaginationBoard from './PaginationBoardPerformance';
import AnalitycsBoardPerformance from './AnalitycsBoardPerformance';
import {
	Revenue,
	Quantity,
	SessionToSaleRatio,
	TransactionCount,
	TransactionAverageAmount,
	AverageAmount,
	TransactionAmount,
	AverageQuantityInTransactions
} from './config';
export default function PerformanceBoardContainer  ({ services,	systemServices, }) {
	
	const {
		selectedTenant,
	} = useAnalyticsContext();
	const {
		dimensionIdPerformance,
		setdimensionIdPerformance,
		setlabelDimension,
		labelDimension,
		reportTypePerformanceSelected,
		deviceSelected,
	} = useEcommerceAnalyticsContext();

	const {
		handleSelectUrl,
		reportTypeSelected,
		navFilter,
		searchValue,
		sourceType,
		orderBy,
		setOrderBy,
		orderByDesc,
		setOrderByDesc,
		filterUrl,
		offset, 
		setOffset,
		currentPage,
		setCurrentPage,
		dimensionId,
		handleSelectElem,
		setChips,
		setNeedRefreshgraph,
		setDimensionId,
		utmDimensionsSelected
	} = useEcommercePerformanceContext();
	
	const [afterRender,setAfterRender] = useState(false);
	const [currentMetrix, setCurrentMetrix] = useState({
		data: null,
		dataSumsMetrics :null,
		isLoading: true,
		isError: false,
		isErrorEcommerce : false
	});
	const [numberOfData, setNumberOfData] = useState(null);
	const [tableToRender, setTableToRender] = useState(<></>);
	const [pageSize, setpageSize] = useState(5);
	const [urlSelected, setUrlSelected] = useState();

	useEffect(() => {
		setOffset(0);
		if (!dimensionIdPerformance) {
			setChips(null);
			setDimensionId('');
			setUrlSelected();
		}
	}, [reportTypePerformanceSelected
		, dimensionIdPerformance]);

	useEffect(() => {
		setAfterRender(true);
		if(offset !== 0){
			setOffset(0);
		}      
		else if (afterRender) {
			refreshBoard();
		}   
	}, [selectedTenant]);


	useEffect(() => {
		if (dimensionIdPerformance) {
			setUrlSelected(dimensionIdPerformance);
			setNeedRefreshgraph(true);
			setDimensionId(dimensionIdPerformance);
			setChips({
				type: reportTypePerformanceSelected,
				title: labelDimension,
			});
		}
		
	}, [dimensionIdPerformance]);
	const handleSelectDimensionId = (idElement,labelElement ) => {
		setdimensionIdPerformance(idElement);
		setlabelDimension(labelElement);
	};

	const refreshBoard = () => {
		setCurrentMetrix((current) => ({
			...current,
			data: null,
			dataSumsMetrics :null,
			isLoading: true
		}));
		const options = {
			...navFilter,
			metrics: [
				Revenue,
				TransactionCount,
				Quantity,
				AverageAmount,
				AverageQuantityInTransactions,
			],
			orderBy: orderBy,
			orderByDesc: orderByDesc === 'desc' ? true : false,
			timeGranularity: 'None',
			trafficSource : sourceType,
			dimension : reportTypePerformanceSelected,
			// dimensionId: dimensionId,
			limit: pageSize,
			offset : offset,
			device : deviceSelected,
			utmDimensionsSelected:utmDimensionsSelected
		};
		services.getDimensionsOverview(
			options,
			(data, headers) => {
				services.getMetricsSums(options,
					(dataSumsMetrics) => {
						const dataFormatted = data.dimensionValues;
						if(numberOfData!==parseInt(headers('x-total-count'))){
							setNumberOfData(parseInt(headers('x-total-count')));}
						setCurrentMetrix(() => ({
							data: dataFormatted,
							dataSumsMetrics :dataSumsMetrics,
							isLoding: false,
							isError: false,
							isErrorEcommerce : false
						}));
						renderTable(dataFormatted,dataSumsMetrics?.metrics);
					},
					(err,) => {
						console.log(err);
					}
				);
			},
        
			(err,typeError) => {
				console.log(err);
				const statusErr = typeError.status;
				if(!statusErr){
					systemServices.showError('An error occured');
				}
				setCurrentMetrix((current) => ({
					...current,
					isLoding: false,
					isError: false,
					isErrorEcommerce : statusErr === 404
				}));
			});
	};
	useEffect(() => {
		refreshBoard();
	}, [
		navFilter,
		reportTypeSelected,
		orderBy,
		orderByDesc,
		offset,
		pageSize,
		searchValue,
		sourceType,
		reportTypePerformanceSelected,
		currentPage,
		deviceSelected,
		utmDimensionsSelected
	]);

	const handleSort = (orderField, order) => {
		setOffset(0);
		setOrderByDesc(order);
		setOrderBy(orderField);
	};

	function renderTable(data,dataSumsMetrics) {
		setTableToRender(
			<AnalitycsBoardPerformance
				resultName={reportTypePerformanceSelected}
				revuenue="Revenue by product"
				transactionCount="Single purchase"
				quantity="Quantity"
				averagePrice="Avg Price"
				averageQte="Avg quantity"
				data={data}
				dataSumsMetrics={dataSumsMetrics}
				orderBy={orderBy}
				orderByDesc={orderByDesc}
				handleSort={handleSort}
				handleSelectElem={(e,n)=>handleSelectDimensionId(e,n)}
			/>
		);
	}

	return (
		<section className={styles.section}>
			{currentMetrix.isLoading && !currentMetrix.isError && !	currentMetrix.isErrorEcommerce && (
				<div className="mask">
					<span className="wheel"></span>
				</div>
			)}
			{!currentMetrix.isLoading && !currentMetrix.isErrorEcommerce && currentMetrix.isError && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_error.svg"
					/>
					<div className="empty_state_title">Cannot fetch data</div>
					<div className="empty_state_text">Please retry later.</div>
				</div>
			)}
			{!currentMetrix.isLoading && !	currentMetrix.isErrorEcommerce &&
                !currentMetrix.isError &&
                currentMetrix.data.length == 0 && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_empty.svg"
					/>
					<div className="empty_state_title">
                            No data for this period
					</div>
					<div className="empty_state_text">
                            Maybe try with another period or other filters
					</div>
				</div>
			)}

			{!currentMetrix.isLoading && !currentMetrix.isErrorEcommerce &&
                !currentMetrix.isError &&
                currentMetrix.data &&
                currentMetrix.data.length > 0 && (
				<>
					<div className="analytics_table_wrapper">
						{tableToRender}
					</div>
					<PaginationBoard
						totalCount={numberOfData}
						pageSize={pageSize}
						handleChangePageSize={(e) => {
							setpageSize(e);
							setOffset(0);
						}}
						paginate={(e) => setOffset(e >= 0 ? e : 0)}
						skipIdNumber={offset}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				</>
			)}
			{
				currentMetrix.isErrorEcommerce && (
					<div className="empty_state analytics_empty_state">
						<img
							className="empty_state_img"
							src="/Assets/analytics_data_error.svg"
						/>
						<div className="empty_state_title">
						No ecommerce data 
						</div>
						<div className="empty_state_text">
					Check your setup.
						</div>
					</div>
				)}
		</section>
	);
}
