import React from 'react'
import { react2angular } from 'react2angular';
import CreateTaggingContextProvider from '../context/TaggingContext';
import TaggingSettingsContainer from '../Edition/TaggingSettingsContainer';
export default function TaggingBridge(props) {
	return (
		<CreateTaggingContextProvider
			$http={props.$http}
			$timeout={props.$timeout}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}

		>
			<TaggingSettingsContainer />
		</CreateTaggingContextProvider>
	)
}
angular
	.module('beyableSaasApp.TaggingBridge', [])
	.component('taggingbridge', react2angular(TaggingBridge, [], ['$http', '$rootScope', '$timeout', '$routeParams', 'AuthServices']));