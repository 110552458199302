import React from 'react';
import styles from './Breadcrumb.module.css';

export default function BreadcrumbItem({
	index,
	toGo,
	name,
	onClick,
	stepSelected,
	showIndex = 1,
	isClickable = true,
}) {

	const cssClass = [];
	cssClass.push(stepSelected === toGo ? styles.item_active : styles.item);

	if (isClickable) {
		cssClass.push(styles.item_clickable);
	}

	return (
		<li className={cssClass.join(' ')} onClick={onClick}>
			{showIndex &&
				<span className={styles.item_index}>
					<span className={styles.item_index_inner}>{index}</span>
				</span>
			}
			<span className={styles.item_name}>
				{name}
			</span>
		</li>
	);
}
