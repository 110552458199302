import React, { useState } from 'react';
export default function PatchEditorDeviceButton({
	value,
	displayValue,
	isActive,
	onClick,
	icon,
	isDisabled = false
}) {

	const handleClick = (e) => {
		if (isDisabled) return;
		if (typeof onClick !== 'function') return;

		onClick(value);
	};

	const cssClass = [''];

	if (isDisabled) {
		cssClass.push('disabled');
	}
	
	if (isActive) {
		cssClass.push('active');
	}

	return (
		<a
			className={cssClass.join(' ')}
			onClick={(e) => handleClick(e)}
		>
			{icon &&
				<i className={icon}></i>		
			}
			{displayValue}
		</a>
	);
}