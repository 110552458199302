import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './GraphEditor.css';
import { CampaignsContext } from '../../context';
import { intToLetterVariation } from '../../util';
import GraphPatchElementItem from './GraphPatchElementItem';
import Btn from '../../../../Components/Btn';
import InputCustom from '../../../../Components/InputCustom';
import {List, ListItem} from '../../../../Components/List';
import {Article} from '../../../../Components/Article/Article';
import SectionMessage from '../../../../Components/SectionMessage';
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function GraphPatchElements({
	patch,
	handleClick,
	inputUrl,
	handleUrlInput,
	principalElement
}) {

	const [t] = useTranslation(['common']);
	const {
		campaign,
		currentVariationId,
		isABTestingActive,
		variations,
		handleChangeObjectiveAbTest,
		handleChangePrincipalElementAbtestPatch
	} = CampaignsContext();

	const [mainElement, setMainElement] = useState();
	const [objectiveType, setObjectiveType] = useState();
	const [canSelectMainElement, setCanSelectMainElement] = useState(false);

	function handleChangeObjectiveType (interaction) {
		setObjectiveType(interaction);
		handleChangeObjectiveAbTest(interaction);
	}
	function getCurrentVariationName() {
		const original = variations.find(v => v?.IsOriginalVariation);
		if (original && original.UId === currentVariationId) {
			return intToLetterVariation(0);
		}
		const index = variations.findIndex(v => v.UId === currentVariationId);
		return 'Variation ' + intToLetterVariation(index);
	}

	function handleChangeMainElementSelected(mainEl){
		setMainElement(mainEl);
		handleChangePrincipalElementAbtestPatch(mainEl, true);
	}
	useEffect(()=>{
		if(principalElement){
			setMainElement(principalElement);
		}else{
			setMainElement();
		}
	},[principalElement,currentVariationId]);
	useEffect(()=>{
		setObjectiveType(campaign.Objective ? campaign.Objective  === 'CustomInteraction' ? 'interaction': 'conversion' : 'conversion');
	},[]);

	useEffect(function(){
		if(campaign.UrlDebug){
			handleUrlInput({
				target: {
					value: campaign.UrlDebug
				}
			});
		}
	},[]);

	useEffect(function(){
		setCanSelectMainElement(isABTestingActive && objectiveType == 'interaction' && patch.data.length > 1);
	}, [isABTestingActive, objectiveType, patch, campaign]);


	const translatedNbElements = (
		<>
			{patch.data.length > 1 ?
				t('patch.N selected elements', {n: patch.data.length})
				:
				t('patch.N selected element', {n: patch.data.length})
			}
		</>
	);

	return(
		<div className="graphic_patch_outro_outer">
			<div className="graphic_patch_outro_inner">
				<div className="graphic_patch_outro_header">
					<div className="flex flex_align_end">
						<div className="flex_item_full">
							{isABTestingActive ?
								<>
									<div className="graphic_patch_title">{getCurrentVariationName()}</div>
									<div className="graphic_patch_description">{translatedNbElements}</div>
								</>
								:
								<div className="graphic_patch_title">{translatedNbElements}</div>
							}
						</div>
						<div className="flex_item_fix ml_10">
							<Btn
								disabled={
									!inputUrl ||
									inputUrl.length == 0
								}
								onClick={() => handleClick()}
								message={t('patch.editPatch')}
								icon="fas fa-fill-drip"
							/>
						</div>
					</div>
				</div>

				{isABTestingActive &&
					<SectionMessage
						title={t('common.tips')}
						text={t('patch.compareVariations')}
						canClose={true}
						storageKey='graphic_patch_ab_test_tips_1'
						appearance='background_outline'
						icon='far fa-lightbulb'
						className='mt_15'
					/>
				}

				<Article size='s' innerSize='m'>
					<List removeVerticalMargin={true}>
						{patch.data.map((e,key) => {
							return (
								<GraphPatchElementItem
									patch={patch}
									item={e}
									key={key}
									canSelectMainElement={false}
									setMainElement={handleChangeMainElementSelected}
									isMainElement={canSelectMainElement && mainElement?.uuid === e.uuid}
								/>
							);
						})}
					</List>
				</Article>
				
				<Article size='s' innerSize='m' title={t('common.settings')} className='mt_30'>
					<List removeVerticalMargin={true}>
						<ListItem
							text={t('patch.referencePage')}
							textIsBold={true}
							actions={
								<InputCustom
									value={inputUrl}
									name="url"
									type="text"
									onChange={(e) => handleUrlInput(e)}
									placeholder="www.beyable.com/"
									prepend={
										<span className="custom_input_prepend_text">
											https://
										</span>
									}
									autogrow={true}
									maxWidth={300}
									minWidth={140}
									color='grey'
								/>
							}
						/>
						{isABTestingActive &&
							<ListItem
								text={t('patch.abTestObjective')}
								textIsBold={true}
								actions={
									<SelectDropdown
										value={objectiveType}
										color='grey'
										optionsList={[
											{label: t('patch.increaseConversionsRate'), value: 'conversion'},
											{label: t('patch.increaseClickRate'), value: 'interaction'}
										]}
										onChange={(v) => handleChangeObjectiveType(v)}
									/>
								}
							/>
						}
						{canSelectMainElement && mainElement &&
							<ListItem
								text={t('patch.abTestMainElement')}
								description={t('patch.increaseClickRateHelp')}
								textIsBold={true}
								actions={
									<SelectDropdown
										value={mainElement}
										friendlyValue={mainElement.selectorName}
										color='grey'
										optionsList={patch.data.map((e) => {
											return {
												label: e.selectorName,
												value: e,
												description: e.cssSelector
											};
										})}
										onChange={(v) => handleChangeMainElementSelected(v)}
									/>
								}
							/>
						}
					</List>
				</Article>
			</div>
		</div>
	);
}