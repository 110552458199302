import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import WebAnalyticsServices, { WebAnalyticsConfigServices, WebAnalyticsDashboardSetupServices, WebAnalyticsPageServices, WebAnalyticsInteractionServices, WebAnalyticsSettingsServices } from '../../Services/WebAnalyticsServices';
import './WebAnalytics.css';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import CheckingAccount from './components/Setup/CheckingAccount';
import WebAnalyticsMainDashboard from './WebAnalyticsMainDashboard';
import useSocket from './hooks/useSocket';
import CreateAnalyticsContextProvider from './context/AnalyticsContextProvider';
import { TabContextProvider } from './context/TabContextProvider';
import EventTrackingServices from '../../Services/EventTrackingServices';
import SystemServices from '../../Services/SystemServices';

export default function WebAnalyticsContainer(props) {
	const [accountInfo, setAccountInfo] = useState({ isChecked: false, hasCheckedActiveVisitors: false, hasData: false, receivesActiveVisitors: false, receivesActiveVisitorsCount: 0, tenants: [], hasNoPermissionOnAnyTenant: true, customDimensionConfigs: [] });
	const [selectedTenant, setSelectedTenant] = useState();
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const userId = props.$rootScope.User.Key;

	const sessionService = new WebAnalyticsServices(props.$http, accountId, () => selectedTenant);
	const setupService = new WebAnalyticsDashboardSetupServices(props.$http, accountId, userId);
	const configService = new WebAnalyticsConfigServices(props.$http, accountId);
	const pageService = new WebAnalyticsPageServices(props.$http, accountId);
	const interactionServices = new WebAnalyticsInteractionServices(props.$http, accountId);
	const settingServices = new WebAnalyticsSettingsServices(props.$http, accountId);
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);

	const { trackEvent } = EventTrackingServices(props.$rootScope.User);

	const [customDimensionConfigsForTenant, setCustomDimensionConfigsForTenant] = useState({});

	const checkAccountCallback = (data) => {
		const tenants = data.tenants.map(t => t.tenant);
		const tenant = tenants.length > 0 ? tenants[0] : '';
		setSelectedTenant(tenant);
		setAccountInfo((state) => ({
			...state,
			isChecked: true,
			hasData: data.dataStartDateUtc,
			tenants: tenants,
			hasNoPermissionOnAnyTenant: data.hasNoPermissionOnAnyTenant,
			customDimensionConfigs: data.customDimensionConfigs
		}));
		setCustomDimensionConfigsForTenant(data.customDimensionConfigs.find(x => x.tenant === tenant ?? ''));
	};

	const [socket] = useSocket(accountId, selectedTenant);

	useEffect(() => {
		const interval = setInterval(() => {
			setAccountInfo((state) => ({ ...state, hasCheckedActiveVisitors: true }));
			clearInterval(interval);
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		// receivesActiveVisitorsCount is here to avoid 1st change
		setAccountInfo((state) => ({ ...state, receivesActiveVisitorsCount: state.receivesActiveVisitorsCount + 1, receivesActiveVisitors: state.receivesActiveVisitorsCount > 1 }));
	}, [socket]);

	const customDimensionServices = {
		getCustomDimensionConfiguration: (tenant, callbackSuccess, callbackError) => configService.getCustomDimensionConfiguration(tenant, callbackSuccess, callbackError),
		saveCustomDimensionConfiguration: (config, callbackSuccess) => {
			configService.saveCustomDimensionConfiguration(config, x => {
				setAccountInfo(info => ({ ...info, customDimensionConfigs: info.customDimensionConfigs.map(c => c.tenant == config.tenant ? config : c) }));
				setCustomDimensionConfigsForTenant(config);
				systemServices.showSuccess();
				callbackSuccess(x);
			}, err => {
				systemServices.showError();
				console.error(err);
			});
		}
	};

	const onSwitchTenant = tenant => {
		setSelectedTenant(tenant);
		setCustomDimensionConfigsForTenant(accountInfo.customDimensionConfigs.find(x => x.tenant === tenant ?? ''));
	};

	return (
		<>
			{!accountInfo.isChecked && (
				<CheckingAccount
					configService={configService}
					startCallback={checkAccountCallback}
					accountId={accountId}
					$rootScope={props.$rootScope}
					$http={props.$http}
				></CheckingAccount>
			)}
			{accountInfo.isChecked && !accountInfo.hasNoPermissionOnAnyTenant && (
				<CreateAnalyticsContextProvider
					location={props.$location}
					selectedTenant={selectedTenant}
					accountId={accountId}
				>
					<TabContextProvider timeout={props.$timeout}>
						<WebAnalyticsMainDashboard
							socket={socket}
							setupService={setupService}
							accountId={accountId}
							accountInfo={accountInfo}
							userId={userId}
							$routeParams={props.$routeParams}
							selectedTenant={selectedTenant}
							switchTenant={onSwitchTenant}
							location={props.$location}
							sessionService={sessionService}
							customDimensionConfigsForTenant={customDimensionConfigsForTenant}
							customDimensionServices={customDimensionServices}
							pageService={pageService}
							interactionServices={interactionServices}
							settingServices={settingServices}
							systemServices={systemServices}
							trackEvent={trackEvent}
							timeout={props.$timeout}
							$http={props.$http}
							$rootScope={props.$rootScope}
						/>
					</TabContextProvider>
				</CreateAnalyticsContextProvider>
			)}
			{accountInfo.isChecked && accountInfo.hasNoPermissionOnAnyTenant
				&&
				<div className="empty_page_flex vscroll">
					<section className="section">
						<img src="/Assets/empty_analytics.svg" className="empty_page_img" styles={{ width: '260px' }} />
						<div className="empty_page_title">
							Unfortunately you do not have permission to access the BEYABLE Analytics dashboard, please ask your BEYABLE Analytics administrator
						</div>
					</section>
				</div>
			}
		</>
	);
}

angular.module('beyableSaasApp.WebAnalytics', [])
	.component(
		'webanalyticsdashboard',
		react2angular(
			WebAnalyticsContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout']
		)
	);
