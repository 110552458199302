import React from 'react';
import Btn from '../../../../Components/Btn';
import { useProductFeedEditionContext } from '../../context/ProductFeedEditionContext';
import editionStyles from '../ProductFeedEdition.module.css';
import {FeedCardHeaderMain} from '../../Feed/FeedCardHeader';

export default function ConnectionInfosContainer() {
	const {
		productFeedObject,
		needBreadCrumb,
		setStep,
		setNeedToFetchMetaDatas,
		rawFeed,
		handleOpenClosePanelRawfeed
	} = useProductFeedEditionContext();

	return (
		<div className={editionStyles.card}>
			<div className={editionStyles.card_body}>
				<FeedCardHeaderMain />
			</div>
			<div className={editionStyles.card_footer}>
				<div className='btn_group_xl'>
					<Btn
						onClick={(e) =>setStep(1)}
						message="Feed settings"
						color="grey"
						icon='fa fa-cog'
						style='link'
						size='xs'
						disabled={!needBreadCrumb || productFeedObject?.type  === 'OnSiteData'}
					/>
					<Btn
						onClick={(e) =>setNeedToFetchMetaDatas(true)}
						message="Parse feed"
						color="grey"
						icon='fas fa-sync-alt'
						style='link'
						size='xs'
						disabled={!productFeedObject || productFeedObject?.type  !== 'GenericUri' && productFeedObject?.type  !== 'Http'}
					/>
					<Btn
						onClick={(e) =>handleOpenClosePanelRawfeed(true)}
						message="Raw feed"
						color="grey"
						icon='fas fa-eye'
						style='link'
						size='xs'
						disabled={!rawFeed}
					/>
				</div>
			</div>
		</div>
	);
}
