/* eslint-disable react/no-unescaped-entities */
import React, {useRef,useState,useEffect} from 'react';
import Btn from '../../../Components/Btn';
import SelectCustom from '../../../Components/SelectCustom';
import ConnectionSwitch from '../Edition/Connection/ConnectionSwitch';
import InputCustom from '../../../Components/InputCustom';
import { typeConnectionHttp, listVerb } from '../utils';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
import { ControlledEditor } from '@monaco-editor/react';
import { FeedCardHeader } from './FeedCardHeader';
import SectionMessage from '../../../Components/SectionMessage';
import Fieldset from '../../../Components/Fieldset';
import optionsEditor from './optionsEditor';
import AdditionnalHeaders from './AdditionnalHeaders';
// https://tst.api.royalcanin.com/rc-df-product-systst/v2/products
// Content type: Json
// {
//     "country": "fr",
//     "world_wide_main_item":["2566"]
// }
// client_id=128b75d82e3543eb95e958ba247eaa37
// client_secret=E9Af193741CF46F09C5d665F96EE200d
export default function CreateHttp() {
	const {
		hasErrorsOnCreation,
		stateOfBackendDetection,
		handleChangeSimpleField,
		productFeedObject,
		handleChooseATypeSourceConnection,
		handleGetMappingSources,
		messageInfos,
		isEmptyIdentifier,
		setisEmptyIdentifier
	} = useProductFeedEditionContext();

	const valueGetter = useRef();
	const [elementToSave, setelementToSave] = useState();
	const [tryConnection, settryConnection] = useState(false);
	function handleChangeSimpleFieldFn(e) {
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name, value);
	}

	const shouldChooseAuth = stateOfBackendDetection === 'Authentication' && !productFeedObject?.auth?.type;

	const handleUpdate = (_prevValue, newVal) => {
		let oldV = {...productFeedObject?.httpBody};
		if(productFeedObject.hasOwnProperty('httpBody')){
			oldV.content = newVal;
		}else{
			oldV = {
				contentType : '',
				content :newVal
			};
		}
		handleChangeSimpleField('httpBody', oldV);
	};

	function handleEditorDidMount(event) {
		valueGetter.current = event;
	}

	function handleChangeContentType(evt){
		const value = evt.target.value;
		let oldV = {...productFeedObject?.httpBody};
		if(productFeedObject.hasOwnProperty('httpBody')){
			oldV.contentType = value;
		}else{
			oldV = {
				contentType : value,
				content : ''
			};
		}
		handleChangeSimpleField('httpBody', oldV);
	}

	const example = '{"exe1": "4", "exe2": "25"}';
	function renameKeys(elementToSave) {
		if(!elementToSave) return;
		if(elementToSave && elementToSave.length > 0){
			const transformArrToObj = elementToSave.reduce((o, key2:any) => {
				return Object.assign(o, {[key2.key]: key2.value});
			}, {});
			return transformArrToObj;
		}else{
			const objN = { [elementToSave[0].key]: elementToSave[0].value };
			return objN;
		}
	}

	function saveElementInArrayOfHeaders(){
		const newObjKeys = renameKeys(elementToSave);

		if(!productFeedObject.hasOwnProperty('httpBody')){
			productFeedObject.httpBody = null;
		}
		handleChangeSimpleField('additionalHeaders', newObjKeys);

		settryConnection(true);
	}
	function tryConnectionHandler (){
		saveElementInArrayOfHeaders();
	}
	useEffect(()=>{
		if(tryConnection){
			handleGetMappingSources();
			settryConnection(false);
		}
	},[tryConnection]);
	return (<>

		<FeedCardHeader title="Authentication required" />

		{hasErrorsOnCreation && !hasErrorsOnCreation?.key &&
			<SectionMessage
				type="warning"
				title="Error during process"
				text={hasErrorsOnCreation}
			/>
		}
		{isEmptyIdentifier &&
			<SectionMessage
				type={messageInfos.type}
				title={messageInfos.title}
				text={messageInfos.text}
				verticalSize="s"
			/>
		}

		<div className='flex'>
			<div className='flex_item_fix'>
				<SelectCustom
					label='Method'
					optionsList={listVerb}
					name='httpVerb'
					value={productFeedObject?.httpVerb? productFeedObject?.httpVerb : ''}
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					autoWidth={true}
				/>
			</div>
			<div className='flex_item_full ml_8'>
				<InputCustom
					value={productFeedObject.resourceIdentifier ?? ''}
					type={'text'}
					label={'URL'}
					name={'resourceIdentifier'}
					placeholder="https://exemple.com/feed"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					onEnter={() => handleGetMappingSources()}
					fullWidth={true}
					autoFocus={true}
				/>
			</div>
		</div>
	
		<SelectCustom
			label='Connection type'
			optionsList={typeConnectionHttp}
			value={productFeedObject?.auth?.type ? productFeedObject?.auth?.type : ''}
			onChange={(e) => handleChooseATypeSourceConnection(e.target.value)}
			autoWidth={true}
		/>

		<ConnectionSwitch
			inputSize="m"
			showResetButton={false}
		/>

		<InputCustom
			value={productFeedObject?.httpBody?.contentType ? productFeedObject?.httpBody?.contentType  : ''}
			type={'text'}
			label={'Content type'}
			name={'contentType'}
			placeholder="Json"
			onChange={(e) => handleChangeContentType(e)}
			fullWidth={true}
			autoFocus={true}
		/>

		<Fieldset label="Payload" description={<>Example : <code>{example}</code></>}>
			<ControlledEditor
				height="160px"
				width="100%"
				theme="dark"
				language={'json'}
				loading={
					<>
						<span className="mask">
							<span className="wheel"></span>
						</span>
					</>
				}
				value={productFeedObject?.httpBody?.content? productFeedObject?.httpBody?.content : ''}
				onChange={handleUpdate}
				editorDidMount={handleEditorDidMount}
				options={optionsEditor}
			/>
		</Fieldset>

		<AdditionnalHeaders 
			elementToSave={elementToSave}
			setelementToSave={setelementToSave}
			additionalHeaders={productFeedObject?.additionalHeaders}
		/>

		{!hasErrorsOnCreation &&
			<div className='mt_30 al_right'>
				<Btn
					onClick={() => tryConnectionHandler()}
					size="l"
					message="Connection"
					color="primary"
				/>
			</div>
		}
		{hasErrorsOnCreation && !shouldChooseAuth &&
			<div className='mt_30 al_right'>
				<Btn
					onClick={() => {setisEmptyIdentifier(); tryConnectionHandler();}}
					size="l"
					message="Retry Connection"
					color="primary"
				/>
			</div>
		}
	</>
	);
}
