import React, { createContext, useContext, useEffect, useState } from 'react';
import SystemServices from '../../../../Services/SystemServices';
import CrossSellUpSellServices from '../../../../Services/CrossSellUpSellServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import ProductTaggingServices from '../../../../Services/ProductTaggingServices';
import AccountTenantServices from '../../../../Services/AccountTenantServices';
import {
	duplicateRecoById,
	deleteTagById,
	getProductCollation,
	getTaggingById,
	createTagging,
	putTaggingById,
	getPreview
} from '../services/taggingServices';
import SetupAccountServices from '../../../../Services/SetupAccountServices';
import { CategoryList, CategoryListWithoutCategory } from '../../utils/types';
import EventTrackingServices from '../../../../Services/EventTrackingServices';
const products = [
	{
		description: '',
		globalStockQuantity: 0,
		id: '575868             ,              X',
		name: 'Parure de lit blanc L240X220 cm et 2 taies d\'oreiller',
		price: 27.99,
		url: 'https://livraison.gifi.fr/575868-parure-de-lit-blanc-l240x220-cm-et-2-taies-d-oreiller.html',
		urlThumbnail: 'https://livraison.gifi.fr/media/catalog/product/5/7/575868.jpg'
	}, {
		description: '',
		globalStockQuantity: 0,
		id: 'AOS-01-0016',
		name: 'Fauteuil de jardin Adirondack chaise longue inclinable en bois 97L x 73l x 93H cm blanc',
		price: 102.9,
		url: 'https://livraison.gifi.fr/aos-01-0016-fauteuil-de-jardin-adirondack-chaise-longue-inclinable-en-bois-97l-x-73l-x-93h-cm-blanc.html',
		urlThumbnail: 'https://livraison.gifi.fr/media/catalog/product/9/c/9cdff115326aecfef192c9777d390236-none_2a37db5c5276ce0d9686919dbc317dfa_2a37db5.JPEG'
	},
	{
		description: '',
		globalStockQuantity: 0,
		id: 'HAB-97649',
		name: 'Kit Clôture 8x1.6m composite et aluminium + profilés de finitions - Gris - Kit de fixation offert',
		price: 1048,
		url: 'https://livraison.gifi.fr/hab-97649-kit-cloture-8x1-6m-composite-et-aluminium-profiles-de-finitions-gris-kit-de-fixation-offert.html',
		urlThumbnail: 'https://livraison.gifi.fr/media/catalog/product'
	},
	{
		description: '',
		globalStockQuantity: 0,
		id: '545374                           X',
		name: 'Parasol rond Tiago inclinable bleu émeraude Ø.300 x H.245 cm',
		price: 59.99,
		url: 'https://livraison.gifi.fr/545374-parasol-rond-tiago-inclinable-bleu-emeraude-O-300-x-h-245-cm.html',
		urlThumbnail: 'https://livraison.gifi.fr/media/catalog/product/5/4/545374.jpg'
	},
	{
		description: '',
		globalStockQuantity: 0,
		id: 'HAB-99397',
		name: 'Lit Emmy - 160 x 200 cm - Beige',
		price: 229,
		url: 'https://livraison.gifi.fr/hab-99397-lit-emmy-160-x-200-cm-beige.html',
		urlThumbnail: 'https://livraison.gifi.fr/media/catalog/product'
	},
	{
		description: '',
		globalStockQuantity: 0,
		id: 'HAB-103026',
		name: 'Housse de protection pour barbecue charbon King - Noir',
		price: 26,
		url: 'https://livraison.gifi.fr/hab-103026-housse-de-protection-pour-barbecue-charbon-king-noir.html',
		urlThumbnail: 'https://livraison.gifi.fr/media/catalog/product'
	},
	{
		description: '',
		globalStockQuantity: 0,
		id: 'AOS-01-0016',
		name: 'Fauteuil de jardin Adirondack chaise longue inclinable en bois 97L x 73l x 93H cm blanc',
		price: 102.9,
		url: 'https://livraison.gifi.fr/aos-01-0016-fauteuil-de-jardin-adirondack-chaise-longue-inclinable-en-bois-97l-x-73l-x-93h-cm-blanc.html',
		urlThumbnail: 'https://livraison.gifi.fr/media/catalog/product/9/c/9cdff115326aecfef192c9777d390236-none_2a37db5c5276ce0d9686919dbc317dfa_2a37db5.JPEG'
	},
	{
		description: '',
		globalStockQuantity: 0,
		id: 'AOS-01-0016',
		name: 'Fauteuil de jardin Adirondack chaise longue inclinable en bois 97L x 73l x 93H cm blanc',
		price: 102.9,
		url: 'https://livraison.gifi.fr/aos-01-0016-fauteuil-de-jardin-adirondack-chaise-longue-inclinable-en-bois-97l-x-73l-x-93h-cm-blanc.html',
		urlThumbnail: 'https://livraison.gifi.fr/media/catalog/product/9/c/9cdff115326aecfef192c9777d390236-none_2a37db5c5276ce0d9686919dbc317dfa_2a37db5.JPEG'
	}, {
		description: '',
		globalStockQuantity: 0,
		id: 'AOS-01-0016',
		name: 'Fauteuil de jardin Adirondack chaise longue inclinable en bois 97L x 73l x 93H cm blanc',
		price: 102.9,
		url: 'https://livraison.gifi.fr/aos-01-0016-fauteuil-de-jardin-adirondack-chaise-longue-inclinable-en-bois-97l-x-73l-x-93h-cm-blanc.html',
		urlThumbnail: 'https://livraison.gifi.fr/media/catalog/product/9/c/9cdff115326aecfef192c9777d390236-none_2a37db5c5276ce0d9686919dbc317dfa_2a37db5.JPEG'
	},
];
const TaggingContext = createContext();

function useTaggingContext() {
	return useContext(TaggingContext);
}
function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
const CreateTaggingContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	const crossSellUpSellServices = new CrossSellUpSellServices($http);
	const systemServices = new SystemServices($rootScope, $timeout);
	const AuthServices = props.AuthServices;
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const setupAccountServices = new SetupAccountServices(props.$http);
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const productTaggingServices = new ProductTaggingServices($http,
		AuthServices, accountId);
	const getProductFeedCategory = (accountId,tenant, callbackSuccess, callbackError) => crossSellUpSellServices.getProductFeedCategory(accountId,tenant, callbackSuccess, error => systemServices.showError());
	const accountTenantServices = new AccountTenantServices(props.$http,
		AuthServices, accountId
	);
	const { trackEvent } = EventTrackingServices(props.$rootScope.User);
	const [newPaginationNeed, setNewPaginationNeed] = useState(false);
	const [mode, setMode] = useState('creation');
	const [dataIsEmpty, setDataIsEmpty] = useState(false);
	const [taggingName, setTaggingName] = useState('');
	const [originalTaggingRule, setOriginamTaggingRule] = useState();
	const [modalConfirmIsOpen, setmodalConfirmIsOpen] = useState(false);
	const [modalCancelActions, setmodalCancelActions] = useState(false);
	const [loadingProductList, setloadingProductList] = useState(false);
	const [loadingSaving, setloadingSaving] = useState(false);
	const [loadingSettings, setloadingSettings] = useState(true);
	const [inputProductBasedOn, setinputProductBasedOn] = useState('');
	const [listIdProducts, setlistIdProducts] = useState([]);
	const [inputTagBasedOn, setinputTagBasedOn] = useState('');
	const [listTags, setlistTags] = useState([]);
	const [valueOfSelectedTag, setvalueOfSelectedTag] = useState({
		lable: 'Choose',
		value: ''
	});
	const [autocompleteTagListIsLoading, setautocompleteTagListIsLoading] = useState(false);
	const [productList, setproductList] = useState([]);
	const [categoryBasedOn, setcategoryBasedOn] = useState(CategoryList);
	const [allCategoriesData, setallCategoriesData] = useState();
	const [typeCategories, setTypeCategories] = useState();
	const [categorySelected, setCategorySelected] = useState();
	const [typeCategoriesSecondLevel, settypeCategoriesSecondLevel] = useState();
	const [typeCategoriesSecondLevelSelected, setTypeCategoriesSecondLevelSelected] = useState();
	const [ListFilter, setListFilter] = useState();
	const [previewTenant, setpreviewTenant] = useState({
		label: 'Default',
		value: ''
	});
	const [tenantsCatalog, setTenantsCatalog] = useState([]);
	const [errorNoConfiguration, setErrorNoConfiguration] = useState(false);
	const [listTenantAccount, setlistTenantAccount] = useState([
		{
			label: 'Default',
			value: '',
		},
	]);
	const [deviceType, setdeviceType] = useState('Mobile');
	const [needRefreshPagination, setneedRefreshPagination] = useState(false);
	const [idInClipBoard, setidInClipBoard] = useState('');
	const [taggingObject, setTaggingObject] = useState({
		'name': 'New tagging',
		'description': 'string',
		'id': '3fa85f64-5717-4562-b3fc-2c963f66afa6',
		'lastUpdatedUtc': '2022-09-30T09:35:18.990Z',
		'usedCount': 0,
		tenant: null,
		'context': 'Global',
		'filtering': {
			'op': 'And',
			'filters': [

			]
		}
	});


	const [autocompleteListIsLoading, setautocompleteListIsLoading] = useState(false);
	const [valueOfSelectedproduct, setvalueOfSelectedproduct] = useState({
		lable: 'Choose',
		value: ''
	});
	const [paginationOptions, setpaginationOptions] = useState({
		poffset: 0,
		plimit: 20,
	});
	const [currentPageProductList, setCurrentPageProductList] = useState(1);
	const [descriptionRule, setdescriptionRule] = useState('');
	const [needToRefreshTenantCategory, setneedToRefreshTenantCategory] = useState(true);
	const [isFirstRender, setisFirstRender] = useState(true);
	const GetProductCollation = (cat) => {
		getProductCollation(cat, systemServices, productTaggingServices,
			(newData) => {

				setloadingSettings(false);
				setListFilter(newData);
			},
			(error) => {
				setloadingSettings(false);
			}
		);
	};
	function deleteIdFilterFromFilteringObject(filtergingObjToTranform) {
		return filtergingObjToTranform.map(
			(curr) => {
				const obj = { ...curr };
				if (obj.hasOwnProperty('expression') && obj['expression'] !== null) {
					return {
						expression: {
							op: obj['expression']['op'],
							filters:
								obj.expression.filters.map(function (el) {
									let newObj = { ...el.value };
									Object.keys(newObj).forEach(key => {
										if (newObj[key] === null) {
											delete newObj[key];
										}
									});
									return {
										collationId: el.collationId,
										op: el.op,
										params: el.params,
										value: newObj
									};
								}
								)
						}
					};
				}
				else {
					Object.keys(curr.value).forEach(key => {
						if (curr.value[key] === null) {
							delete curr.value[key];
						}
					});
					delete curr.idFilter;
					return curr;
				}
			}
		);
	}

	const CreateTaggingRule = () => {
		setloadingSaving(true);
		// Get new value for name of tagging
		const newTagging = JSON.parse(JSON.stringify(taggingObject));
		if((taggingObject.tenant == null && tenantsCatalog.length === 1 ) &&
		listTenantAccount.length > 0){
			newTagging.tenant = '';
		}
		newTagging.name = taggingName;
		newTagging.description = descriptionRule;
		newTagging['filtering']['filters'] = deleteIdFilterFromFilteringObject(newTagging['filtering']['filters']);

		createTagging(
			newTagging,
			systemServices,
			productTaggingServices,
			(newData) => {
				trackEvent('tagging/new-rule-saved');
				systemServices.showSuccess(
					'Tagging rule created successfully !'
				);
				let url =
					'/ProductSettings/Tagging/Setting?id=' + newData.id;
				if ($routeParams && $routeParams.ka) {
					url += '&ka=' + $routeParams.ka;
				}
				setTimeout(() => {
					window.location.href = url;
				}, 1000);
			},
			(error) => {
				console.log(
					'🚀 ~ file: RecommendationsContextProvider.js ~ line 214 ~ CreateReco ~ error',
					error
				);
				setloadingSaving(false);
			}
		);
	};
	const PutTaggingById = () => {
		const newTagging = JSON.parse(JSON.stringify(taggingObject));
		newTagging.name = taggingName;
		newTagging['filtering']['filters'] = deleteIdFilterFromFilteringObject(newTagging['filtering']['filters']);
		newTagging.description = descriptionRule;
		// newRanking.Description = descriptionRule;
		putTaggingById(
			newTagging,
			newTagging.id,
			systemServices,
			productTaggingServices,
			(newData) => {
				trackEvent('tagging/existing-rule-saved');
				systemServices.showSuccess('Changes successfully saved !');
				// change original ranking rule with new ranking data for the comparison
				setOriginamTaggingRule(JSON.stringify(newTagging));
			},
			(error) => {
				console.log(
					'🚀 ~ file: RecommendationsContextProvider.js ~ line 234 ~ PutRecoById ~ error',
					error
				);
			}
		);
	};
	function hasErrorNoConfiguration(data){
		if(data.status == '404'){
			// systemServices.showError(data.data);
			setErrorNoConfiguration(true);
			setloadingProductList(false);
		}else{
			systemServices.showError();
			setloadingProductList(false);
		}
	}
	const sortByAsc = (a, b) => {

		if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
		if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
		return 0;
	
	};
	const GetTenant = () => {
		accountTenantServices.getTenantsAccount(
			tenantL => {
				const listOfTenant = tenantL.map((tenant) => {
					if (tenant === '') {
						return {
							label: 'Default',
							value: tenant,
						};
					} else {
						return {
							label: tenant,
							value: tenant,
						};
					}
				});
				if (listOfTenant.length > 1) {
					listOfTenant.unshift({
						label: 'All',
						value: 'all'
					});
				}
				const listOfTenantSort = listOfTenant.sort(sortByAsc) ;
				setpreviewTenant(listOfTenantSort[0]);
				setlistTenantAccount(listOfTenantSort);
			},
			(Error,errMessage) => {
				hasErrorNoConfiguration(errMessage);

			}
		);
	};
	const getTenantsCatalogTenant = () => {
		accountTenantServices.getTenantsCatalogTenant(
			(tenantL) => {
				const listOfTenant = tenantL.map((tenant) => {
					if (tenant === '') {
						return {
							label: 'Default',
							value: tenant,
						};
					} else {
						return {
							label: tenant,
							value: tenant,
						};
					}
				});
				if (listOfTenant.length > 1) {
					listOfTenant.unshift({
						label: 'All',
						value: null
					});
				}
				const listOfTenantSort = listOfTenant.sort(sortByAsc) ;
				setTenantsCatalog(listOfTenantSort);
				handleSelectMode(listOfTenantSort);
			},
			(Error,errMessage) => {
				hasErrorNoConfiguration(errMessage);

			}
		);
	};


	useEffect(() => {
		const tenantObj  = {};
		tenantObj.tenantSelected = taggingObject.tenant;
		tenantObj.listTenant = tenantsCatalog; 
		if(tenantsCatalog.length > 0 && !isFirstRender){
			getProductFeedCategory(accountId,tenantObj, (newData) => {
				setneedToRefreshTenantCategory(false);
				const backendCompleteObject = [...newData];
				if (backendCompleteObject.length === 0) {
					setDataIsEmpty(true);
					settypeCategoriesSecondLevel([{
						name: '',
						label: ''
					}]);
					setTypeCategoriesSecondLevelSelected(
						{
							name: '',
							label: ''
						}
					);
					setTypeCategories([]);
					setCategorySelected({
						name: '',
						label: ''
					});
					setcategoryBasedOn(CategoryListWithoutCategory);
				} else {
					setallCategoriesData(backendCompleteObject);
					setDataIsEmpty(false);
					const arrayOfCategories = backendCompleteObject.map((el) => ({
						name: el.type,
						label: capitalizeFirstLetter(el.type),
					}));
					setTypeCategories(arrayOfCategories);
					setCategorySelected(arrayOfCategories[0]);

					const valueOfSecondCategorySelect = backendCompleteObject
						.find((el) => el.type === arrayOfCategories[0].name)
						.knownNames.map((el) => ({ name: el, label: el }));
					settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
					setTypeCategoriesSecondLevelSelected(
						valueOfSecondCategorySelect[0]
					);
					setcategoryBasedOn(CategoryList);
				}
			}
			);}
	}, [tenantsCatalog,needToRefreshTenantCategory]);

	function handleSelectMode(tenantsList) {
		// Check mode of editing of creating
		if ($routeParams && $routeParams.id !== undefined) {
			setMode('edition');
			setisFirstRender(false);
			trackEvent('tagging/existing-rule-opened');
			getTaggingById(
				$routeParams.id,
				systemServices,
				productTaggingServices,
				(newData) => {

					const ruleObj = { ...newData };
					if (
						!ruleObj.hasOwnProperty('filtering') ||
						ruleObj.filtering === null
					) {
						const filteringObj = {
							op: 'And',
							filters: [],
						};
						ruleObj.filtering = filteringObj;
					}
					let  tenantObj  = {};
					tenantObj.listTenant = tenantsList; 
					if(tenantsList){
						tenantObj.tenantSelected = newData.tenant;
						getProductFeedCategory(accountId,tenantObj, (newData2) => {
							setneedToRefreshTenantCategory(false);
							const backendCompleteObject = [...newData2];
							if (backendCompleteObject.length === 0) {
								setDataIsEmpty(true);
								settypeCategoriesSecondLevel([{
									name: '',
									label: ''
								}]);
								setTypeCategoriesSecondLevelSelected(
									{
										name: '',
										label: ''
									}
								);
								setTypeCategories([]);
								setCategorySelected({
									name: '',
									label: ''
								});
								setcategoryBasedOn(CategoryListWithoutCategory);
							} else {
								setallCategoriesData(backendCompleteObject);
								setDataIsEmpty(false);
								const arrayOfCategories = backendCompleteObject.map((el) => ({
									name: el.type,
									label: capitalizeFirstLetter(el.type),
								}));
								setTypeCategories(arrayOfCategories);
								setCategorySelected(arrayOfCategories[0]);
			
								const valueOfSecondCategorySelect = backendCompleteObject
									.find((el) => el.type === arrayOfCategories[0].name)
									.knownNames.map((el) => ({ name: el, label: el }));
								settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
								setTypeCategoriesSecondLevelSelected(
									valueOfSecondCategorySelect[0]
								);
								setcategoryBasedOn(CategoryList);
							}
						}
						);}
					setTaggingObject(ruleObj);
					setTaggingName(ruleObj.name);
					setdescriptionRule(newData.description !== null ? newData.description : '');
					GetProductCollation(ruleObj.context);
					const originToTransform = { ...ruleObj };
					originToTransform['filtering']['filters'] = deleteIdFilterFromFilteringObject(ruleObj['filtering']['filters']);
					setOriginamTaggingRule(JSON.stringify(originToTransform));
				},
				(error) => { }
			);
		} else if ($routeParams && $routeParams.id === undefined) {
			setMode('creation');
			setisFirstRender(false);
			trackEvent('tagging/new-rule-opened');
			GetProductCollation('Tag');
			let tenant = null;
			let tenantObj  = {};
			tenantObj.listTenant = tenantsList; 
			const tenantsListFilter = tenantsList.filter(x=> x.value !== null);
			if(tenantsListFilter.length !== 0){

				tenantObj.tenantSelected = tenantsListFilter[0].value;
				tenant = tenantsListFilter[0].value;
			}else{
				tenantObj.tenantSelected = '';
				tenant = '';
			}
			getProductFeedCategory(accountId,
				tenantObj,
				newData => {
					setneedToRefreshTenantCategory(false);
					// Get categories of product feed and transform object to list
					const backendCompleteObject = [...newData];
					if (backendCompleteObject.length === 0) {
						setDataIsEmpty(true);
						settypeCategoriesSecondLevel([{
							name: '',
							label: ''
						}]);
						setTypeCategoriesSecondLevelSelected(
							{
								name: '',
								label: ''
							}
						);
						setTypeCategories([]);
						setCategorySelected({
							name: '',
							label: ''
						});
					}
					setallCategoriesData(backendCompleteObject);
					const arrayOfCategories = backendCompleteObject.map(el => ({ name: el.type, label: capitalizeFirstLetter(el.type) }));
					if (arrayOfCategories.length > 0) {
						setTypeCategories(arrayOfCategories);
						setCategorySelected(arrayOfCategories[0]);
						// Transform list of second category
						const valueOfSecondCategorySelect = backendCompleteObject.find(el => el.type === arrayOfCategories[0].name).knownNames.map(el => ({ name: el, label: el }));
						settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
						setTypeCategoriesSecondLevelSelected(valueOfSecondCategorySelect[0]);
	
					}
				});

			const newTaggingObject = {
				'name': 'New tagging',
				'description': '',
				'id': '3fa85f64-5717-4562-b3fc-2c963f66afa6',
				'lastUpdatedUtc': '2022-09-30T09:35:18.990Z',
				'usedCount': 0,
				'context': 'Global',
				tenant: tenant,
				'filtering': {
					'op': 'And',
					'filters': [

					]
				}
			};
			const originToTransform = { ...newTaggingObject };
			originToTransform['filtering']['filters'] = deleteIdFilterFromFilteringObject(newTaggingObject['filtering']['filters']);
			setOriginamTaggingRule(JSON.stringify(originToTransform));
			setTaggingObject(newTaggingObject);
			setTaggingName('New tagging');
		}
	}
	useEffect(() => {
		getTenantsCatalogTenant();
		GetTenant();
	}, []);
	useEffect(() => {

		const timer = setTimeout(() => {
			if (taggingObject.filtering.filters.length === 0) {
				return;
			}
			setloadingProductList(true);
			const optionsObject = {
				deviceType: deviceType,
			};

			const shouldNotUsePreviewTenant = taggingObject.tenant !== null && tenantsCatalog.length > 1;
			if (!shouldNotUsePreviewTenant) {
				if(optionsObject.hasOwnProperty('previewTenant')){
					optionsObject['previewTenant'] = previewTenant.value;
				}
			}
			const newTagging = JSON.parse(JSON.stringify(taggingObject));
			newTagging['filtering']['filters'] = deleteIdFilterFromFilteringObject(newTagging['filtering']['filters']);
			getPreview(
				optionsObject,
				needRefreshPagination ? {
					poffset: 0,
					plimit: 20,

				} : paginationOptions,
				newTagging,
				systemServices,
				productTaggingServices,
				newData => {
					setloadingProductList(false);
					setproductList(newData);
					needRefreshPagination && resetPagination();
					setneedRefreshPagination(false);
					// setidInClipBoard('')
				},
				error => {
					setloadingProductList(false);
					setneedRefreshPagination(false);
					setproductList([]);
				});

		}, 500);
		return () => clearTimeout(timer);
	}, [taggingObject,
		deviceType,
		previewTenant,
	]);
	useEffect(() => {
		if (newPaginationNeed) {
			setTimeout(() => {
				if (taggingObject.filtering.filters.length === 0) {
					setproductList([]);
					return;
				}
				setloadingProductList(true);
				const optionsObject = {
					deviceType: deviceType,
				};
				if (taggingObject.context === 'Tag') {
					optionsObject['tagName'] = valueOfSelectedproduct.value;
				}
				
				const shouldNotUsePreviewTenant = taggingObject.tenant !== null && tenantsCatalog.length > 1;
				if (!shouldNotUsePreviewTenant) {
					optionsObject['previewTenant'] = previewTenant.value;
				}
				const newTagging = JSON.parse(JSON.stringify(taggingObject));
				newTagging['filtering']['filters'] = deleteIdFilterFromFilteringObject(newTagging['filtering']['filters']);
				getPreview(
					optionsObject,
					paginationOptions,
					newTagging,
					systemServices,
					productTaggingServices,
					newData => {
						setloadingProductList(false);
						setproductList(newData);
						needRefreshPagination && resetPagination();
						setneedRefreshPagination(false);
						// setidInClipBoard('')
					},
					error => {
						setloadingProductList(false);
						setneedRefreshPagination(false);
						setproductList([]);
					});
			}, 500);
		}
	}, [paginationOptions]);
	useEffect(() => {
		const timer = setTimeout(() => {
			if (inputProductBasedOn) {
				onGetProductsByIdList(inputProductBasedOn);
			}
		}, 500);

		return () => clearTimeout(timer);
	}, [inputProductBasedOn]);
	useEffect(() => {
		const timer = setTimeout(() => {
			if (inputTagBasedOn) {
				onGetTagsByIdList(inputTagBasedOn);
			}
		}, 500);

		return () => clearTimeout(timer);
	}, [inputTagBasedOn]);
	useEffect(() => {
		if (taggingObject.filtering.filters.length === 0) {
			setproductList([]);
		}
	}, [taggingObject.filtering.filters]);
	//Navigation 
	// quit or save
	let urlReturn = '/ProductSettings/Tagging';
	if ($routeParams && $routeParams.ka) {
		urlReturn += '?ka=' + $routeParams.ka;
	}
	function checkIfCanQuitWithoutSave() {
		const stringifyNewObj = { ...taggingObject };

		stringifyNewObj.name = taggingName;
		stringifyNewObj.description = descriptionRule;
		stringifyNewObj['filtering']['filters'] = deleteIdFilterFromFilteringObject(taggingObject['filtering']['filters']);
		const compareIfIsSave = originalTaggingRule === JSON.stringify(stringifyNewObj);

		if (compareIfIsSave) {
			window.location.href = urlReturn;
		} else {
			setmodalConfirmIsOpen(true);
		}
	}
	function cancelActions() {
		const stringifyNewObj = { ...taggingObject };
		stringifyNewObj.name = recoName;
		stringifyNewObj.description = descriptionRule;
		const compareIfIsSave =
			originalTaggingRule === JSON.stringify(stringifyNewObj);


		if (compareIfIsSave) {
			return;
		} else {
			setmodalCancelActions(true);
		}
	}
	function returnToDashboard() {
		window.location.href = urlReturn;
	}

	function resetPagination() {
		setpaginationOptions({
			poffset: 0,
			plimit: 20
		});
		setCurrentPageProductList(1);
	}
	function handleCloseConfirm() {
		setmodalConfirmIsOpen(false);
	}
	function handleCloseCancelActions() {
		setmodalCancelActions(false);
	}
	function returnToFirstStateOfTaggingRule() {
		setOriginamTaggingRule(JSON.stringify(originalTaggingRule));
		setTaggingName(originalTaggingRule.name);
		setdescriptionRule(originalTaggingRule.description !== null ? originalTaggingRule.description : '');
		const parseOriginalRecoRule = JSON.parse(originalTaggingRule);
		setTaggingObject(parseOriginalRecoRule);
		handleCloseCancelActions();
	}
	function changeDeviceSelected(deviceName) {
		setdeviceType(deviceName);
		setneedRefreshPagination(true);
	}
	// Change tagging object 
	function handleChangeName(target) {
		setTaggingName(target);
	}

	function changeCategorySelected(elem) {
		const nextCategory = typeCategories.find(el => el.name === elem);
		setCategorySelected(nextCategory);
		const valueOfSecondCategorySelect = allCategoriesData.find(el => el.type === nextCategory.name).knownNames.map(el => ({ name: el, label: el }));
		settypeCategoriesSecondLevel(valueOfSecondCategorySelect);
		setTypeCategoriesSecondLevelSelected(valueOfSecondCategorySelect[0]);
	}
	function changeSecondCategorySelected(elem) {
		const newCategory = typeCategoriesSecondLevel.find(el => el.name === elem);
		setTypeCategoriesSecondLevelSelected(newCategory);
	}
	/// Filters
	const formatSelectedFilter = (selectedFilterList, filterSelectedExpression) => {
		if (selectedFilterList.length > 1) {
			let currentFilter;
			const listFilterToAdd = selectedFilterList.map((elem) => {
				const filterToAdd = {
					collationId: elem.id,
					op: elem.op,
					value: elem.value,
					params: elem.params
				};
				return filterToAdd;
			});
			const expressionFilterToAdd = {
				expression: {
					op: filterSelectedExpression.op,
					filters: listFilterToAdd
				},
			};

			return expressionFilterToAdd;
		} else if (selectedFilterList.length === 1) {
			const filterToAdd = {
				collationId: selectedFilterList[0].id,
				op: selectedFilterList[0].op,
				value: selectedFilterList[0].value,
				params: selectedFilterList[0].params
			};

			return filterToAdd;
		}
	};

	function onAddFilter(selectedFilterList, filterSelectedExpression) {

		let newFilter = formatSelectedFilter(selectedFilterList, filterSelectedExpression);
		let updateList = [
			...taggingObject.filtering.filters,
			newFilter,
		];
		setTaggingObject((tagging) => ({
			...tagging,
			filtering: {
				op: tagging.filtering.op,
				filters: updateList
			},
		}));
	}

	function onEditFilter(idFilterEdit, currentListWithId, selectedFilterList, filterSelectedExpression) {

		const newFilter = formatSelectedFilter(selectedFilterList, filterSelectedExpression);
		const itemToManipulate = currentListWithId.find(
			(elem) => elem.idFilter === idFilterEdit
		);
		function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
		const indexOfItem = currentListWithId.indexOf(itemToManipulate);
		const updatedListWithId = replaceAt(
			[...currentListWithId],
			indexOfItem,
			newFilter
		);


		setTaggingObject((tagging) => ({
			...tagging,
			filtering: {
				op: tagging.filtering.op,
				filters: updatedListWithId
			},
		}));
	}

	function onRemoveFilter(filterList, id) {
		const newList = filterList.filter((elem) => elem.idFilter != id);
		setTaggingObject((tagging) => ({
			...tagging,
			filtering: {
				op: tagging.filtering.op,
				filters: newList
			},
		}));
	}

	function handleChangeOperatorOfFilters(elem) {

		setTaggingObject((tagging) => ({
			...tagging,
			filtering: {
				op: elem,
				filters: tagging.filtering.filters
			},
		}));

	}
	function handleChangenemOfProductbasedOn(value) {
		setinputProductBasedOn(value);
		if (value === '') {
			setautocompleteListIsLoading(false);
		} else {
			setautocompleteListIsLoading(true);
		}
		setlistIdProducts([]);
	}
	function handleSelectidOfProduct(value) {
		setvalueOfSelectedproduct(value);
		resetPagination();
	}
	function onGetProductsByIdList(str) {
		const ctx = 'pagetag';
		setupAccountServices.getAccountReferenceData(accountId,
			ctx,
			str,
			taggingObject.tenant,
			newData => {
				setlistIdProducts(newData);
				setautocompleteListIsLoading(false);
			},
			error => {
				setautocompleteListIsLoading(false);
			}

		);
	}
	function handleChangeTenant(target) {

		const targetIsDefault = target === '';
		const targetIsAll = target === 'All' || target ===  null;
		setneedToRefreshTenantCategory(true);
		const newTenant = targetIsDefault ?  '' : targetIsAll ? null : tenantsCatalog.find(el => el.label === target).value;
		setTaggingObject((reco) => ({
			...reco, tenant: newTenant
		}));
	}
	function handleChangeTenantPreview(target) {
		const newTenant = listTenantAccount.find(el => el.value == target);
		setpreviewTenant(newTenant);
	}
	function onGetTagsByIdList(str) {
		setupAccountServices.getAccountReferenceData(accountId,
			'productRawTag',
			str,
			taggingObject.tenant,
			newData => {
				setlistTags(newData);
				setautocompleteTagListIsLoading(false);
			},
			error => {
				setautocompleteTagListIsLoading(false);
			}

		);
	}
	function handleChangenemOfTagbasedOn(value) {
		setinputTagBasedOn(value);
		if (value === '') {
			setautocompleteTagListIsLoading(false);
		} else {
			setautocompleteTagListIsLoading(true);
		}
		setlistTags([]);
	}
	function handleSelectidOfTag(value) {
		setvalueOfSelectedTag(value);
		resetPagination();
	}
	function handleChangePaginationOptions(elemName, elemValue) {
		setpaginationOptions(pagination => ({
			...pagination,
			[elemName]: elemValue
		}));
	}
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setidInClipBoard(value);
	};
	function handleChangeTaggingDescription(target) {
		setdescriptionRule(target);
	}
	return (
		<TaggingContext.Provider
			value={{
				// config mode 
				errorNoConfiguration,
				handleChangeTaggingDescription,
				descriptionRule,
				deviceType,
				changeDeviceSelected,
				mode,
				dataIsEmpty,
				handleChangeTenant,
				previewTenant,
				listTenantAccount,
				tenantsCatalog,
				handleChangeTenantPreview,
				productList,
				modalConfirmIsOpen,
				modalCancelActions,
	
				handleCloseConfirm,
				returnToDashboard,
				checkIfCanQuitWithoutSave,
				returnToFirstStateOfTaggingRule,
				loadingProductList,
				loadingSaving,
				loadingSettings,
				currentPageProductList,
				// tagging object 
				CreateTaggingRule,
				PutTaggingById,
				taggingObject,
				handleChangeName,
				taggingName,
				// filters 
				onAddFilter,
				onEditFilter,
				onRemoveFilter,
				handleChangeOperatorOfFilters,
				ListFilter,
				setupAccountServices,
				accountId,
				paginationOptions,
				currentPageProductList,
				setCurrentPageProductList,
				handleChangePaginationOptions,
				setNewPaginationNeed,
				//
				categorySelected,
				typeCategories,
				changeCategorySelected,
				typeCategoriesSecondLevel,
				typeCategoriesSecondLevelSelected,
				changeSecondCategorySelected,
				newPaginationNeed,
				idInClipBoard,
				// inputIdProduct 
				listIdProducts,
				inputProductBasedOn,
				handleSelectidOfProduct,
				valueOfSelectedproduct,
				autocompleteListIsLoading,
				handleChangenemOfProductbasedOn,
				// taging 
				listTags,
				valueOfSelectedTag,
				autocompleteTagListIsLoading,
				handleChangenemOfTagbasedOn,
				inputTagBasedOn,
				handleSelectidOfTag
			}}
		>
			{props.children}
		</TaggingContext.Provider>
	);
};

export default CreateTaggingContextProvider;

export { useTaggingContext };
