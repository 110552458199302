import React from 'react';

export default function LabelList({
    labels,
    size = "",
    inline = true
}) {

    const cssClass = ['campaign_label_list'];

    if (inline) {
        cssClass.push('inline');
    }

    if (size) {
        cssClass.push(size);
    }

    const getClassName = (label) => `campaign_label campaign_label_color campaign_label_${label.color.toLowerCase()}`

    return (
        <ul className={cssClass.join(' ')}>
            {(labels || []).map(label =>
                <li key={label.id}>
                    <span className={getClassName(label)}>
                        <span className="flex_item_full">{label.name || "No name"}</span>                        
                    </span>
                </li>
            )}
        </ul>
    )
}
