import React, { useState, useEffect } from 'react';
import InputCustom from '../../../../../Components/InputCustom';
import SelectDropdown from '../../../../../Components/SelectDropdown';
import ReferenceDataAutoComplete from '../../../../ReferenceData/ReferenceDataAutoComplete';
import { DayPickerSingleDateController } from 'react-dates';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import EmptyState from '../../../../../Components/EmptyState';
import Fieldset from '../../../../../Components/Fieldset';
import {useFiltersContext} from '../contextFilters';
const operators = [
	{
		value: 'Equals',
		label: 'Is equal to',
	},
	{
		value: 'NotEquals',
		label: 'Is not equal to',
	},
	{
		value: 'Contains',
		label: 'Contains',
	},
	{
		value: 'NotContains',
		label: 'Does not contain',
	},
	{
		value: 'StartsWith',
		label: 'Starts with',
	},
	{
		value: 'NotStartsWith',
		label: 'Does not start with',
	},
	{
		value: 'EndsWith',
		label: 'Ends with',
	},
	{
		value: 'NotEndsWith',
		label: 'Does not ends with',
	},
	{
		value: 'Like',
		label: 'Is like',
	},
	{
		value: 'NotLike',
		label: 'Is not like',
	},
];
const operatorsDateUtc = [
	{
		value: 'Equals',
		label: 'Is equal to',
	},
	{
		value: 'NotEquals',
		label: 'Is not equal to',
	},
	{
		value: 'Lower',
		label: 'Is lower than',
	},
	{
		value: 'Greater',
		label: 'Is greater than',
	},
	{
		value: 'LowerOrEquals',
		label: 'Is lower or equal to',
	},
	{
		value: 'GreaterOrEquals',
		label: 'Is greater or equal to',
	},
];
const operatorsBoolean = [
	{
		value: true,
		label: 'True',
	},
	{
		value: false,
		label: 'False',
	},
];
const dimensionTags = [
	{
		value: 'stringValues',
		label: 'Text',
	},
	{
		value: 'dateUtcValues',
		label: 'Date',
	},
	{
		value: 'numberValues',
		label: 'Numeric',
	},
	{
		value: 'boolean',
		label: 'Boolean',
	},


];
const operatorNumbers = [
	{
		value: 'Equals',
		label: 'Is equal to',
	},
	{
		value: 'NotEquals',
		label: 'Is not equal to',
	},
	{
		value: 'Lower',
		label: 'Is lower than',
	},
	{
		value: 'Greater',
		label: 'Is greater than',
	},
	{
		value: 'LowerOrEquals',
		label: 'Is lower or equal to',
	},
	{
		value: 'GreaterOrEquals',
		label: 'Is greater or equal to',
	},

];
export default function TagContainer({ data,
	handleChangeFilterByUniqueId,
	setupAccountServices,
	selectedTenant,
	accountId }) {
	const {
		tabFilterSelected
	} = useFiltersContext();
	const [currentValueString, setCurrentValueString] = useState('');
	const [currentValues, setCurrentValues] = useState();
	const [dimensionTag, setdimensionTag] = useState('stringValues');

	const [isAnError, setIsAnError] = useState({ isError: false, message: '' });
	const [valueOperatorSelected, setvalueOperatorSelected] = useState(
		data.op || operators[0].value
	);
	const [valueOperatorSelectedDate, setvalueOperatorSelectedDatee] = useState(
		data.op || operatorsDateUtc[0].value
	);
	const [useAutoComplete, setUseAutoComplete] = useState(false);
	const handleSelectDimensionTag = (operator) => {
		setdimensionTag(operator);
	};

	const [dateOfTheSingleDatePicker, setdateOfTheSingleDatePicker] = useState(moment().utc()
		.startOf('day'));

	const [focusedInput, setFocusedInput] = useState('startDate');
	const [componentToReturn, setcomponentToReturn] = useState(<></>);
	const [componentOperatorsToReturn, setcomponentOperatorsToReturn] = useState(<></>);
	const onChangeAutocomplete = (text) => {
		let txtFormat = text;
		if (txtFormat.length) {
			txtFormat = txtFormat.replace(/(\n)+/g, ';');
			txtFormat = txtFormat.replace(/;+/g, ';');
		}
		setCurrentValueString(txtFormat);
		handleChangeValues(text);
	};

	// handle filter operator
	const handleSelectOperator = (operator) => {
		setvalueOperatorSelected(operator);
	};
	const handleSelectOperatorDate = (operator) => {
		setvalueOperatorSelectedDatee(operator);
	};

	// case In/notIn
	const handleChangeValues = (textAreaValue) => {
		if (!textAreaValue) {
			setIsAnError({ isError: true, message: 'input cannot be empty' });
		} else {
			setIsAnError({ isError: false, message: '' });
		}
		setCurrentValues(textAreaValue);
	};

	// init component value/s on edit mod
	function getValueOfStringComponent() {

		const valueMultiple = tabFilterSelected.value.hasOwnProperty('stringValues');
		if (valueMultiple && data.value['stringValues'] !== null) {
			if (valueOperatorSelected === 'Equals' || valueOperatorSelected === 'NotEquals') {
				onChangeAutocomplete(data.value['stringValues'].join('\n'));
				setdimensionTag(dimensionTags[0].value);
			}
			setCurrentValues(data.value['stringValues'].join('\n'));
		}
		const valueMultipleN = data.value.hasOwnProperty('numberValues');
		if (valueMultipleN && data.value['numberValues'] !== null) {
			setCurrentValues(data.value['numberValues'].join('\n'));
			setdimensionTag(dimensionTags[2].value);
		}
		const dateUtcValues = data.value.hasOwnProperty('dateUtcValues');
		if (dateUtcValues && data.value['dateUtcValues'] !== null) {
			setdateOfTheSingleDatePicker(moment(data.value['dateUtcValues'][0]).utc()
				.startOf('day'));
			setdimensionTag(dimensionTags[1].value);
		}
		const booleanValue = data.value.hasOwnProperty('booleanValue');
		if (booleanValue && data.value['booleanValue'] !== null) {
			setvalueOperatorSelected(data.value['booleanValue']);
			setCurrentValues(data.value['booleanValue']);
			setdimensionTag(dimensionTags[3].value);
		}
	}
	// update elementListFilterSelected State
	function handleChangeValuesById() {
		if (dimensionTag === 'stringValues' && currentValues) {
			let myNewArrayOfcurrentValues = [''];

			if (typeof currentValues !== 'boolean') {
				myNewArrayOfcurrentValues = currentValues
					.split(/[;,""\n]/)
					.filter((x) => x.length > 0);
			}
			handleChangeFilterByUniqueId({
				idFilter: data.idFilter,
				op: valueOperatorSelected,
				value: { stringValues: myNewArrayOfcurrentValues },
			});
		}
		if (dimensionTag === 'numberValues' && currentValues) {

			let myNewArrayOfcurrentValues = [''];
			if (typeof currentValues !== 'boolean') {
				myNewArrayOfcurrentValues = currentValues
					.split(/[;,""\n]/)
					.filter((x) => x.length > 0);
			}
			handleChangeFilterByUniqueId({
				idFilter: data.idFilter,
				op: valueOperatorSelected,
				value: { numberValues: myNewArrayOfcurrentValues },
			});
		}
		if (dimensionTag === 'dateUtcValues') {
			handleChangeFilterByUniqueId({
				idFilter: data.idFilter,
				op: valueOperatorSelectedDate,
				value: {
					dateUtcValues: [moment(dateOfTheSingleDatePicker).utc()
						.startOf('day').toISOString()]
				},
			});
		}
		if (dimensionTag === 'boolean') {
			handleChangeFilterByUniqueId({
				idFilter: data.idFilter,
				op: 'Equals',
				value: {
					booleanValue: valueOperatorSelected
				},
			});
		}

	}
	// init component on edit mode
	useEffect(() => {
		handleSelectOperator(tabFilterSelected.op || operators[0].value);
		getValueOfStringComponent();
	}, [data,tabFilterSelected]);

	//update filters state
	useEffect(() => {
		handleChangeValuesById();
		returnComponentValue();
		returnOperators();
	}, [data, currentValueString,
		currentValues,
		valueOperatorSelected,
		valueOperatorSelectedDate,
		dimensionTag,
		dateOfTheSingleDatePicker]);
	useEffect(() => {
		if (data.name === 'Id') {
			if (valueOperatorSelected === 'Equals' || valueOperatorSelected === 'NotEquals') {
				setUseAutoComplete(true);
			}
			else setUseAutoComplete(false);
		}
		else setUseAutoComplete(false);

	}, [valueOperatorSelected, data]);

	const handleOnDateChange = (startDate) => {
		setdateOfTheSingleDatePicker(startDate);
	};

	function returnComponentValue() {
		if (dimensionTag === 'stringValues') {
			if (!useAutoComplete) {
				setcomponentToReturn(<>
					<InputCustom
						placeHolder="Enter one item per line or use one of the following separators: ; (semi-colon) , (comma)"
						onChange={(e) => handleChangeValues(e.target.value)}
						value={currentValues}
						type="textarea"
						isAnError={isAnError.message}
						fullWidth={true}
						autogrow={true}
					/>
				</>);
			}

			if (useAutoComplete) {
				setcomponentToReturn(<>
					<ReferenceDataAutoComplete
						selectedTenant={selectedTenant}
						setupAccountServices={setupAccountServices}
						currentAccount={accountId}
						referenceDataType={'product'}
						value={currentValueString}
						onChange={(value) => onChangeAutocomplete(value)}
					/>
				</>);
			}
		}
		if (dimensionTag === 'dateUtcValues') {
			setcomponentToReturn(<DayPickerSingleDateController
				date={dateOfTheSingleDatePicker}
				onDateChange={(date) => {
					handleOnDateChange(date);
				}}
				focused={focusedInput}
				onFocusChange={focusedInput => {
					return true;
				}}
				numberOfMonths={1}
				isOutsideRange={() => false}
			/>);
		}
		if (dimensionTag === 'numberValues') {
			setcomponentToReturn(
				<InputCustom
					placeHolder="0"
					onChange={(e) => handleChangeValues(e.target.value)}
					value={currentValues}
					type="number"
					isAnError={isAnError.message}
					width="xs"
				/>
			);
		}
		if (dimensionTag === 'boolean') {
			setcomponentToReturn(<></>);
		}
		if (dimensionTag === undefined) {
			setcomponentToReturn(<></>);
		}
	}
	function returnOperators() {
		if (dimensionTag === 'stringValues') {
			setcomponentOperatorsToReturn(<SelectDropdown
				optionsList={operators}
				value={valueOperatorSelected}
				onChange={(v) => handleSelectOperator(v)}
			/>);
		}
		else if (dimensionTag === 'numberValues') {
			setcomponentOperatorsToReturn(<SelectDropdown
				optionsList={operatorNumbers}
				value={valueOperatorSelected}
				onChange={(v) => handleSelectOperator(v)}
			/>);
		}
		else if (dimensionTag === 'dateUtcValues') {
			setcomponentOperatorsToReturn(<SelectDropdown
				optionsList={operatorsDateUtc}
				value={valueOperatorSelectedDate}
				onChange={(v) => handleSelectOperatorDate(v)}
			/>);
		}
		else if (dimensionTag === 'boolean') {
			setcomponentOperatorsToReturn(<SelectDropdown
				optionsList={operatorsBoolean}
				value={valueOperatorSelected}
				onChange={(v) => handleSelectOperator(v)}
			/>);
		}
	}
	return (
		<>
			<SelectDropdown
				optionsList={dimensionTags}
				value={dimensionTag}
				onChange={(v) => handleSelectDimensionTag(v)}
				label="Format"
				blockClassName="inline mr_10"
			/>
			<Fieldset label='Value'>
				<div className="flex flex_align_start">
					<div className="flex_item_fix mr_10">
						{componentOperatorsToReturn}
					</div>
					<div className="flex_item_full">
						{componentToReturn}
					</div>
				</div>
			</Fieldset>
		</>
	);
}
