import React, { createContext, useContext, useState, useEffect } from 'react';
import { initialDates } from '../components/Nav/config';
import { metricsTypes, sourceTypes,
	initialUtmFilter,
	initialUtmFilterV2,
	devices
} from '../components/Pages/config';

const PageContext = createContext();

function usePageContext() {
	return useContext(PageContext);
}

const PageContextProvider = (props) => {
	const [sourceType, setSourceType] = useState([]);
	const { initialFrom, initialTo } = initialDates;
	const [navFilter, setNavFilter] = useState({
		fromDate: initialFrom,
		toDate: initialTo,
		filters: [],
	});

	const [customDateRange, setCustomDateRange] = useState( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const [isCustomSelected, setisCustomSelected] = useState(false);
	const [selectedTimeGranularity, setSelectedTimeGranularity] =
        useState('Day');
	const [selectedPeriod, setSelectedPeriod] = useState(initialDates);
	const [reportTypeSelected, setReportTypeSelected] = useState('Page');
	const [filterUrl, setfilterUrl] = useState(null);
	const [isLoadingData, setisLoadingData] = useState(false);
	const [isLoadingDatatab, setisLoadingDatatab] = useState(false);
	const [chips, setChips] = useState(null);
	const [needRefreshGraph, setNeedRefreshgraph] = useState(false);
	const [needRefreshBoard, setNeedRefreshBoard] = useState(false);
	const [metricTypeSelected, setmetricTypeSelected] = useState({
		key: 'UniqueViews',
		label: 'Unique views',
	});
	const [isCustomDimensionMode, setIsCustomDimensionMode] = useState(false);

	const [orderBy, setOrderBy] = useState('UniqueViews');
	const [orderByDesc, setOrderByDesc] = useState('desc');
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0); 
	const [currentPage, setCurrentPage] = useState(1);
	const [needRefreshForUtmFilter, setneedRefreshForUtmFilter] = useState(false);
	const [utmDimensionsSelected, setUtmDimensionsSelected] = useState(initialUtmFilterV2);
	const [excludeFilter, setExcludeFilter] = useState(false);
	const [utmSelected, setUtmSelected] = useState({
		source: false,
		medium: false,
		campaign: false,
		content: false
	});
	const [deviceSelected, setdeviceSelected] = useState([]);
	const [selectedCustomTag, setselectedCustomTag] = useState();
	function handleChangeExcludeFilter(v){
		setExcludeFilter(v);
	}
	function handleChangeDeviceSelected(elem){
		const newKey = deviceSelected.find(el => el === elem);
		const oldD = [...deviceSelected];
		const indefOfVal = oldD.indexOf(elem);
		if(indefOfVal !== -1){
			setdeviceSelected(oldD.filter(x => x !== newKey));
		}else{
			setdeviceSelected([...oldD,elem]);
		}
	}
	function resetDevices(){
		setdeviceSelected([]);
	}
	const handleChangeReportSelect = (val) => {
		setOffset(0);
		onDeleteChip();
		reportTypeSelected !== val && setReportTypeSelected(val);
		if(val.toLowerCase().includes('tag')){
			setIsCustomDimensionMode(true);
			setselectedCustomTag();
		}else{
			setselectedCustomTag();
			setIsCustomDimensionMode(false);
		}
	};


	const handleSelectTagCustom = (url) => {
		setOffset(0);
		setCurrentPage(1);
		setselectedCustomTag(url);
	};
	const handleSelectUrl = (url) => {
		setOffset(0);
		setCurrentPage(1);
		if (filterUrl !== url) {
			setNeedRefreshgraph(true);
			setfilterUrl(url);
			setChips({
				type: 'url',
				title: url,
			});
		}
	};
   
	const resetSearchPage = () => setSearchValue('');
    

	const handleSearchValue = (url) => {
		setOffset(0);
		setCurrentPage(1);
		setSearchValue(() => url);
	};

	const onDeleteChip = () => {
		setOffset(0);
		setCurrentPage(1);
		setChips(null);
		setfilterUrl(null);
	};
	const onSelectMetricType = (val) => {
		setOffset(0);
		setCurrentPage(1);
			
		if (val.key !== metricTypeSelected.key) {
			const newTypeFiltered = metricsTypes.find((t) => t.key === val.key);
			newTypeFiltered && setmetricTypeSelected(val);
		}
	};
	const handleChangeSourceType = (val) => {
		setOffset(0);
		setCurrentPage(1);
		const oldSources = [...sourceType];
		const indefOfVal = oldSources.indexOf(val);
		if(indefOfVal !== -1){
			setSourceType(oldSources.filter(x => x !== val));
		}else{
			setSourceType([...oldSources,val]);
		}
	};
	const resetSource = () => {
		setOffset(0);
		setCurrentPage(1);
		setSourceType([]);
	};
	function resetUtmSelected() {
		setUtmSelected({
			source: false,
			medium: false,
			campaign: false,
			content: false
		});
	}
	function handleSelectUtmDimension(utmDimension, value) {

		setUtmSelected(dimensions => ({
			...dimensions,
			[utmDimension]: value
		})
		);
	}
	function hansleSaveUtmDimensionFilter(newFilterUtm) {
		// for (const key in newFilterUtm) {
		// 	const utmSelectionElementStatus = utmSelected[key];
		// 	if (!utmSelectionElementStatus) {
		// 		newFilterUtm[key].name = '';
		// 		newFilterUtm[key].label = '';
		// 	}
		// }
		setneedRefreshForUtmFilter(!needRefreshForUtmFilter);
		setUtmDimensionsSelected(newFilterUtm);
		setOffset(0);
		setCurrentPage(1);
	}
	return (
		<PageContext.Provider
			value={{
				reportTypeSelected,
				handleChangeReportSelect,
				navFilter,
				setNavFilter,
				selectedPeriod,
				setSelectedPeriod,
				selectedTimeGranularity,
				setSelectedTimeGranularity,
				initialDates,
				handleSelectUrl,
				filterUrl,
				setfilterUrl,
				isLoadingData,
				setisLoadingData,
				onDeleteChip,
				chips,
				setNeedRefreshgraph,
				needRefreshGraph,
				needRefreshBoard,
				setNeedRefreshBoard,
				onSelectMetricType,
				metricTypeSelected,
				setisLoadingDatatab,
				isLoadingDatatab,
				searchValue,
				setSearchValue,
				handleSearchValue,
				setChips,
				handleChangeSourceType,
				sourceType,
				sourceTypes,
				orderBy,
				setOrderBy,
				orderByDesc,
				setOrderByDesc,
				offset, 
				setOffset,
				resetSearchPage,
				currentPage,
				setCurrentPage,
				handleSelectUtmDimension,
				utmDimensionsSelected,
				utmSelected,
				hansleSaveUtmDimensionFilter,
				needRefreshForUtmFilter,
				resetUtmSelected,
				isCustomSelected,
				setisCustomSelected,
				customDateRange,
				setCustomDateRange,
				deviceSelected,
				handleChangeDeviceSelected,
				excludeFilter,
				handleChangeExcludeFilter,
				resetSource,
				resetDevices,
				isCustomDimensionMode,
				handleSelectTagCustom,
				selectedCustomTag
			}}
		>
			{props.children}
		</PageContext.Provider>
	);
};

export default PageContextProvider;
export { usePageContext };
