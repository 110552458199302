import React, { useEffect, useState, useRef }  from 'react';
import ReactTooltip from 'react-tooltip';
import {useProductFeedEditionContext} from '../../context/ProductFeedEditionContext';
import editionStyles from '../ProductFeedEdition.module.css';
import Btn from '../../../../Components/Btn';

export default function Accordion({
	children,
	label,
	value,
	placeholder = 'Choose a field',
	isEmpty = true,
	hasError = false,
	fieldIndex,
	headerIntro,
	headerTitle,
	headerSubTitle,
	showDoneButton = true,
	showDoneBorder = false
}) {

	const {
		openedFieldIndex,
		toggleMapField,
	} = useProductFeedEditionContext();

	const [isOpen, setIsOpen] = useState(false);
	const [cssClass, setCssClass] = useState('');
	const [iconClass, setIconClass] = useState('');
	const [headerClass, setHeaderClass] = useState('');

	const getHeaderClass = () => {
		if (isEmpty) return editionStyles.card_map_header_empty;
		if (hasError) return editionStyles.card_map_header_error;
		return editionStyles.card_map_header_set;
	};

	const getIconClass = () => {
		const base = 'fas fa-fw dis_block ';
		if (isEmpty) return base + 'fa-ban';
		if (hasError) return base + 'fa-exclamation-triangle';
		return base + 'fa-check-circle';
	};

	useEffect(()=>{
		const tmpClassClass = [editionStyles.card];
		
		const isCurrentField = openedFieldIndex === fieldIndex;

		if (isCurrentField) {
			tmpClassClass.push(editionStyles.card_opened);
		} else {
			tmpClassClass.push(editionStyles.card_closed);
			if (openedFieldIndex === fieldIndex + 1) {
				tmpClassClass.push(editionStyles.card_before_open);
			}
		}

		setCssClass(tmpClassClass.join(' '));
		setIsOpen(isCurrentField);

	},[openedFieldIndex]);

	useEffect(()=>{
		setHeaderClass(getHeaderClass());
		setIconClass(getIconClass());
	},[hasError, isEmpty]);

	return (
		<div className={cssClass}>
			{!isOpen &&
				<>
					<div className={headerClass} onClick={(e) => toggleMapField(fieldIndex)}>
						<div className='flex'>
							<div className='flex_item_fix'>
								<div className={editionStyles.card_map_status_icon}>
									<i className={iconClass}></i>
								</div>
							</div>
							<div className='flex_item_fix'>
								<span className={editionStyles.card_map_name}>{label}</span>
							</div>
							<div className='flex_item_full al_right ml_20 overflow_hidden'>
								<div className={editionStyles.card_map_value}>
									{isEmpty ? placeholder : value }
								</div>
							</div>
							<div className='flex_item_fix'>
								<div className={editionStyles.card_map_status_arrow}>
									<i className={'fas fa-chevron-down dis_block'}></i>
								</div>
							</div>
						</div>
					</div>
				</>
			}
			{isOpen &&
				<>
					<div className={editionStyles.card_map_body}>
						<div className='flex flex_align_start'>
							<div className='flex_item_full'>
								{headerIntro}
								<div className={editionStyles.card_map_intro}>
									<div className={editionStyles.card_map_title}>
										{headerTitle}
									</div>
									{headerSubTitle &&
										<div className={editionStyles.card_map_sub_title}>{headerSubTitle}</div>
									}
								</div>
							</div>
							<div className="flex_item_fix ml_20">
								<button className={editionStyles.card_map_status_arrow}
									onClick={(e) => toggleMapField()}>
									<i className={'fas fa-chevron-up dis_block'}></i>
								</button>
							</div>
						</div>
						{children}
					</div>
					{showDoneButton &&
						<div className={showDoneBorder ? editionStyles.card_map_footer_with_border : editionStyles.card_map_footer}>
							<div className='flex'>
								<div className='flex_item_full'></div>
								<div className='flex_item_fix'>
									<Btn
										message="Done"
										onClick={(e) => toggleMapField()}
									/>
								</div>
							</div>
						</div>
					}
				</>
			}
		</div>
	);
}
