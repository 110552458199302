import React from 'react';
import Btn from '../../../Components/Btn';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
export default function Header({
	returnUrl = '',
	subTitle = '',
	title = '',
	cancel = () => { },
	valid = () => { },
	canValid = true,
	checkIfCanQuitWithoutSave = () => {
	},
	cancelActions = () => {

	}
}) {
	const idTooltip = uuidv4();
	return (
		<div className="collection_header flex_item_fix flex flex_justify_between ">
			<ReactTooltip id={idTooltip} backgroundColor='black' effect='solid' />
			<div>
				<a
					// href={returnUrl}
					data-for={idTooltip} data-tip="Back to list"
					onClick={checkIfCanQuitWithoutSave}
					className="collection_header_back">
					<i className="fas fa-arrow-left"></i>
				</a>
				<span className="collection_header_title">{title}</span>
				{subTitle && subTitle.length &&
					<>: {subTitle}</>
				}
			</div>
			<div>
				<Btn
					onClick={checkIfCanQuitWithoutSave}
					message="Cancel"
					style="ghost"
					color="secondary"
				/>
				<Btn
					disabled={!canValid}
					onClickFunction={valid}
					message="Save"
				/>

			</div>
		</div>
	);
}
