import React, { useState } from 'react';
import styles from './ProgressBar.module.css';
export default function ProgressBar({
	percent = 0
}) {

	return (
		<div className='flex'>
			<div className='flex_item_full'>
				<div className={styles.progress_bar}>
					<div className={styles.progress_bar_cursor} style={{'width': percent+'%'}}></div>
				</div>
			</div>
			<div className="flex_item_fix">
				<div className={styles.value}>
					{percent}%
				</div>
			</div>
		</div>
	)
}
