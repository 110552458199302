import React, {useState} from 'react';
import Btn from '../../../Components/Btn';
import TableGridContainer from '../../../Components/Table/TableGridContainer';
import TableRowItem from '../../../Components/Table/TableRowItem';
import TableColItem from '../../../Components/Table/TableColItem';
import Confirm from '../../../Components/Confirm';
import { useSegmentGroupsListContext } from './context/SegmentGroupListContextProvider';
import DropDownStatus from './DropDownStatus';
import SearchBar from '../../../Components/SearchBar';
import EmptyState from '../../../Components/EmptyState';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import { Section } from '../../../Components/Section/Section';
import { Article } from '../../../Components/Article/Article';
import Picto from '../../../Components/Picto';
import Modal from '../../../Components/Modal';
import ModalBody from '../../../Components/ModalBody';
import ModalFooter from '../../../Components/ModalFooter';
import SectionMessage from '../../../Components/SectionMessage';
import { iconSegment } from '../../../Components/CustomIcon/CustomIcon';
import SelectStatus from './Status/SelectStatus';
import { useTranslation, Trans } from 'react-i18next';

const tableRowStyle = {
	gridTemplateColumns: '2fr 1fr 1fr 130px',
};

export default function SegmentGroupList() {

	const {
		isLoading,
		getCreationLink,
		sureToDelete,
		setSureToDelete,
		onDeleteGroup,
		valueInSearchBar,
		handleChange,
		noData,
		noFilteredData,
		segmentListFilter,
		firstDisplay
	} = useSegmentGroupsListContext();

	const [t] = useTranslation(['segments', 'utils']);
		
	if(location.pathname !== '/Insight/segment/settings'){
		return <></>;
	}
	
	return (
		<>
			<Section hasMargin={false} width="m">
				<section className='section no_bottom_pad section_primary'>
					<div className='flex'>
						<div className='flex_item_full'>
							<div className='flex'>
								<span className='h1'>{t('segments')}</span>
								<i className='fas fa-chevron-right fa-fw grey_5 s_13 ml_10 mr_10' />
								<span className='h1'>{t('manageList')}</span>
							</div>
						</div>
						<div className='flex_item_fix ml_20'>
							{!isLoading && !noData &&
								<Btn
									href={getCreationLink()}
									message={t('newSegment')}
								/>
							}
						</div>
					</div>
				</section>
				<section className='section'>
					<Article innerSize='l'>
						{isLoading && firstDisplay &&
							<SpinnerWheel wheelSize="l" display="block" verticalSize="xl" />
						}
						{isLoading && !firstDisplay &&
							<SpinnerWheel wheelSize="l" />
						}
						{!isLoading && !noData &&
							<>
								<div className='mb_15'>
									<div className='btn_group_l'>
										<SearchBar
											placeholder={t('search')}
											valueInSearchBar={valueInSearchBar}
											onChange={(value) => handleChange(value)}
											size="l"
											width="l"
											fullWidth={false}
										/>
										<SelectStatus />
									</div>
								</div>

								{!noFilteredData &&
									<TableGridContainer>
										<TableRowItem specialClasseName={'table_head_row'} styleObj={tableRowStyle}>
											<TableColItem>
												{t('segmentName')}
											</TableColItem>
											<TableColItem>
												{t('common.devices', {ns: 'utils'})}
											</TableColItem>
											<TableColItem>
												{t('status')}
											</TableColItem>
											<TableColItem align="right">

											</TableColItem>
										</TableRowItem>

										{segmentListFilter.map((s) => <SegmentItem key={s.id} segmentGroup={s} />)}

									</TableGridContainer>
								}
								{noFilteredData &&
									<EmptyState
										icon="fas fa-search"
										title={t('noMatchingSegment')}
										text={t('messages.tryAnotherSearch', {ns: 'utils'})}
										textSize='l'
										verticalSize='l'
									/>
								}
							</>
						}
						{!isLoading && noData &&
							<EmptyState
								imageUrl="/Assets/empty_segment.svg"
								title={t('emptyState.manage.title')}
								text={t('emptyState.manage.text')}
								card={
									<>
										<p>
											{t('emptyState.manage.card_1')}
											{' '}
											{t('emptyState.manage.card_2')}
										</p>
										<ul>
											<li><Trans t={t} i18nKey="emptyState.manage.card_3"><strong>Insights:</strong> Get a more precise analysis of your visitors' behavior</Trans></li>
											<li><Trans t={t} i18nKey="emptyState.manage.card_4"><strong>E-merchandising:</strong> Apply different product ranking based on visitors</Trans></li>
											<li><Trans t={t} i18nKey="emptyState.manage.card_5"><strong>Personalization:</strong> Adapt visitors browsing experiences</Trans></li>
										</ul>
									</>
								}
								verticalSize="l"
								textSize="l"
								primaryAction={
									<Btn
										href={getCreationLink()}
										message={t('newSegment')}
									/>
								}
							/>
						}
					</Article>
				</section>
			</Section>

			<Confirm
				isOpen={sureToDelete}
				title={t('sureToDelete')}
				text={t('messages.thisActionIsIrreversible', {ns: 'utils'})}
				confirmText={t('actions.delete', {ns: 'utils'})}
				cancelText={t('actions.cancel', {ns: 'utils'})}
				confirmColor="alert"
				confirmCallback={() => onDeleteGroup()}
				onClose={() => setSureToDelete(false)}
			/>
		</>
	);
}

function SegmentItem({
	segmentGroup
}:any): JSX.Element {

	const {
		getEditLink,
		onDelete,
	} = useSegmentGroupsListContext();

	const isUsedInRanking:boolean = segmentGroup.segmentedRankingUsages && segmentGroup.segmentedRankingUsages.length > 0;
	const [t] = useTranslation(['utils', 'segments']);
	const [actionDDIsOpen, setActionDDIsOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	function handleDelete() {
		if (isUsedInRanking) {
			setModalIsOpen(true);
		} else {
			onDelete(segmentGroup.id);
		}
		setActionDDIsOpen(false);
	}

	return (
		<TableRowItem key={segmentGroup.id} styleObj={tableRowStyle}>
			<TableColItem>
				<div className='flex'>
					<div className='flex_item_fix mr_15'>
						<Picto
							size="l"
							iconCustom={iconSegment}
							color={segmentGroup.status === 'Live' ? 'green' : 'grey'}
						/>
					</div>
					<div className='flex_item_full'>
						<div className='table_name'>{segmentGroup.name}</div>
					</div>
				</div>
			</TableColItem>
			<TableColItem>
				{segmentGroup?.devices?.includes('Mobile') &&
					<i className='fas fa-mobile-alt grey_2'></i>
				}
				{segmentGroup?.devices?.includes('Tablet') &&
					<i className='fas fa-tablet-alt grey_2'></i>
				}
				{segmentGroup?.devices?.includes('Desktop') &&
					<i className='fas fa-desktop grey_2'></i>
				}
			</TableColItem>
			<TableColItem>
				<DropDownStatus segmentGroup={segmentGroup} />
			</TableColItem>
			<TableColItem align="right">
				<div className='btn_group_s'>
					<Btn
						href={getEditLink(segmentGroup.id)}
						icon="fas fa-pen"
						color="secondary"
						style="ghost_outline"
						size="xs"
						tooltip={t('actions.edit')}
					/>
					<Dropdown
						isOpen={actionDDIsOpen}
						toggle={setActionDDIsOpen}
						button={
							<Btn
								icon="fas fa-ellipsis-v"
								color="secondary"
								style="ghost_outline"
								size="xs"
								tooltip={t('actions.moreActions')}
							/>
						}
					>
						<Listbox>
							<ListboxItem
								icon="fas fa-trash"
								message={t('actions.delete')}
								onClick={handleDelete}
							/>
						</Listbox>
					</Dropdown>
				</div>
			</TableColItem>
			<Modal
				isOpen={modalIsOpen}
				onClose={() => setModalIsOpen(false)}
				width={570}
				animation="slide_down"
			>
				<ModalBody>
					<SectionMessage
						appearance="ghost"
						type="warning"
						title={t('deletionImpossible.message_1', {ns: 'segments'})}
						fontSize="l"
						iconSize="xl"
						text={
							<p>
								{t('deletionImpossible.message_2', {ns: 'segments'})}
								<br/>
								{t('deletionImpossible.message_3', {ns: 'segments'})}
							</p>
						}
					/>
				</ModalBody>
				<ModalFooter
					primaryAction={
						<Btn
							message={t('actions.ok')}
							onClick={() => setModalIsOpen(false)}
						/>
					}
				/>
			</Modal>
		</TableRowItem>
	);
}