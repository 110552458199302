import HttpServices from './HttpServices';

export default class FormCustomServices {
	constructor($http) {
		this.HttpServices = new HttpServices($http);

		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
	}

	getAnswers(accountId, campaignId, startDate, endDate,nextPartitionKey,nextRowKey,  callbackSuccess, callbackError) {
		let nextPartitionK = '';
		let nextRowK = '';
		if(nextPartitionKey){
			nextPartitionK = `&nextPartitionKey=${nextPartitionKey}`;
		}
		if(nextRowKey){
			nextRowK = `&nextRowKey=${nextRowKey}`;
		}
		const formatDate = d => moment(d).format('YYYY-MM-DD');
		this.HttpServices.get(`${this.BO_API_URL}/v2/formsandsurveys/${accountId}/campaign/${campaignId}/answers?fromDate=${formatDate(startDate)}&toDate=${formatDate(endDate)}&limit=${30}${nextPartitionK}${nextRowK}`, callbackSuccess, callbackError);
	}
	getAnswersMax(accountId, campaignId, startDate, endDate,callbackSuccess, callbackError) {

		const formatDate = d => moment(d).format('YYYY-MM-DD');
		this.HttpServices.get(`${this.BO_API_URL}/v2/formsandsurveys/${accountId}/campaign/${campaignId}/answers?fromDate=${formatDate(startDate)}&toDate=${formatDate(endDate)}&limit=${2000}`, callbackSuccess, callbackError);
	}
}