
import getVideoId from 'get-video-id';
export default function videosUtils() {
	function sendErrorId() {
		return {
			type: 'error',
			key: 'url',
			message: 'Please enter a valid youtube URL'
		};
	}
	function getDataYoutubeForIdVideo(youtubeId, url) {
		return fetch(`https://www.googleapis.com/youtube/v3/videos?id=${youtubeId}&key=${window.BEYABLE_env.YOUTUBE_API_KEY}&part=snippet,contentDetails,statistics`
		).then(resJson => resJson.json())
			.then(res => {
				if (res.items.length !== 0) {
					let videoData = {};
					videoData = res.items[0].snippet;
					videoData.type = 'success';
					videoData.youtubeId = youtubeId;
					videoData.url = url;
					return videoData;
				} else {
					return sendErrorId();
				}
			}).catch(error => {
				console.error('error e', error);
			});
	}

	const getId = async (url) => {
		const id = getVideoId(url);
		if (id.id === undefined) {
			return sendErrorId();
		} else {
			const res = await getDataYoutubeForIdVideo(id.id, url);
			return res;
		}
	};

	function isCorrectUrlYoutube(elem) {
		if (elem.contains('www.youtube.com')) {
			return true;
		} else {
			return false;
		}
	}

	function checkErrors(url) {
		const arrayOfErrors = [];
		if (!isCorrectUrlYoutube(url)) {
			arrayOfErrors.push({
				key: 'url',
				message: 'Please enter a valid youtube URL'
			});
		}
		return arrayOfErrors;
	}

	return {
		checkErrors,
		getId,
		isCorrectUrlYoutube,
		sendErrorId,

	};
}