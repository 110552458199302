import React from 'react'
import TableBodyItem from './TableBodyItem'
import TableHeader from './TableHeader'
export default function TableHistory({ historyData }) {
    if (!historyData) {
        return <></>
    }
    return (
        <table>
            <thead>
                <TableHeader />
            </thead>
            <tbody>
                {historyData.map(entrie =>
                    <TableBodyItem
                        key={entrie.Id}
                        entry={entrie}
                    />
                )}
            </tbody>
        </table>
    )
}
