import React from 'react';
import editionStyles from '../ProductFeedEdition.module.css';
import Checkbox from '../../../../Components/Checkbox';
export default function FieldContainerSimple({label,isSelected, onClick}) {


	return (
		<div className={editionStyles.card_s}>
			<div
				className={isSelected ? editionStyles.card_map_header_set : editionStyles.card_map_header_empty}
				onClick={(e) => onClick(!isSelected)}
			>
				<div className='flex'>
					<div className='flex_item_fix'>
						<div className={editionStyles.card_map_header_checkbox}>
							<Checkbox
								forcedChecked={isSelected}
							/>
						</div>
					</div>
					<div className='flex_item_full'>
						<span className={editionStyles.card_map_name}>{label}</span>
					</div>
				</div>
			</div>
		</div>
	);
}
