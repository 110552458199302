const gold = {
    SimplifyOpeManager: {
        static: [
            "simplifyPlanning:canSeeListOperation",
            "simplifyPlanning:canEditDatesOperation",
            "simplifyTasks:canSeeTasksOperation",
            "simplifyTasks:canEditTaskOperation",
            "simplifyTasks:canBeNotifiedTaskStatusOperation",
            "simplifyOperation:canCreateCampaign",
            "simplifyOperation:canEditCampaignInformations",
            "simplifyOperation:canCreateAssociationBannerAndZone",
            "simplifyOperation:canDefineCTA",
            "simplifyOperation:canUseTriggerByTrafficSource",
            "simplifyOperation:canUseTriggerBytriggerByDatetime",
            "simplifyOperation:canUseTriggerByGeography",
            "simplifyOperation:canUseTriggerBySkuList",
            "simplifyOperation:canUseTriggerByCategoryList",
            "simplifyOperation:canUseTriggerByUrlList",
            "simplifyOperation:canUseTriggerByTenantList",
            "simplifyOperation:canUseTriggerByCategoryListWithProductThree",
            "simplifyOperation:canUseAbTesting",
            "simplifyOperation:canCreateCustomsLabels",
            "simplifyOperation:canActivateSEOIndexing",
            "simplifyOperation:canUploadImage",
            "simplifyOperation:canGenerateDeclinationImage",
            "simplifyOperation:canUseTemplateLibrary",
            "simplifyOperation:canDefinASlider",
            "simplifyOperation:canDefinAnOverlay",
            "simplifyOperation:canDefinDCOParameters",
            "simplifyOperation:canEditStatusOperation",
            "simplifyOperation:canTestOperation",
            "simplifyReporting:canAccessToOperationStatistics",
            "simplifyReporting:canDonwloadOperationStatistics",
            "simplifyReporting:canSeeOperationStatisticsByKPI",
            "simplifyReporting:canAddCustomKPIForOperationStatistics",
            "simplifyReporting:canSeeOperationStatisticsByPageType",
            "simplifyReporting:canSeeOperationStatisticsByAbTest",
        ],
        dynamic: {
            // "simplifyOperation:deleteAssets": ({ userId, assetOwnerId }) => {
            //     if (!userId || !assetOwnerId) return false;
            //     return userId === assetOwnerId;
            // }
        }
    },
    SimplifyOpeSupervisor: {
        static: [
            "simplifyPlanning:canSeeListOperation",
            "simplifyTasks:canSeeTasksOperation",
            "simplifyReporting:canAccessToOperationStatistics",
            "simplifyReporting:canDonwloadOperationStatistics",
            "simplifyReporting:canSeeOperationStatisticsByKPI",
            "simplifyReporting:canAddCustomKPIForOperationStatistics",
            "simplifyReporting:canSeeOperationStatisticsByPageType",
            "simplifyReporting:canSeeOperationStatisticsByAbTest",
        ]
    },
    SimplifyopeDesigner: {
        static: [
            "simplifyPlanning:canSeeListOperation",
            "simplifyTasks:canSeeTasksOperation",
            "simplifyTasks:canEditTaskOperation",
            "simplifyTasks:canBeNotifiedTaskStatusOperation",
            "simplifyOperation:canUploadImage",
            "simplifyOperation:canGenerateDeclinationImage",
            "simplifyOperation:canUseTemplateLibrary",
            "simplifyOperation:canDefinASlider",
            "simplifyOperation:canDefinAnOverlay",
            "simplifyOperation:canDefinDCOParameters",
            "simplifyOperation:canTestOperation",
        ]
    },
    accountManager: {
        static: [
            "simplifyAdministration:canInvitUsers",
            "simplifyAdministration:canEditUsers",
        ]
    }

};
gold.simplifyAdmin = gold.SimplifyOpeManager;
export default gold;