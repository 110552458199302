import React, { useState, useEffect } from 'react';
import Modal from '../../../../Components/Modal';
import Btn from '../../../../Components/Btn';
import { v4 as uuidv4 } from 'uuid';
import CreateLabel from '../../../Labels/CreateLabel';
import LabelSelector from '../../../Labels/LabelSelector';
import { onSiteContext } from '../context';
import { Trans, useTranslation } from 'react-i18next';
export default function EditLabelsCampaign({ labelService }) {
	const {
		accountId,
		systemServices,
		modalLabelsCampaignisOpen,
		closeTheModalOfLabelsCampaign,
		currentCampaign
	} = onSiteContext();
	const [t, i18n] = useTranslation('common');
	const subTitle = currentCampaign && currentCampaign.name;
	const objectId = currentCampaign && currentCampaign.id;
	const objectLabels = currentCampaign && currentCampaign.labels;

	const [isLoading, setisLoading] = useState(true);
	const [accountLabels, setAccountLabels] = useState();
	const [objectLabelIds, setObjectLabelIds] = useState();
	const [refetch, setrefetch] = useState(false);

	const onSave = () => { closeTheModalOfLabelsCampaign(true); };
	const onClose = () => { closeTheModalOfLabelsCampaign(false); };

	useEffect(() => {
		labelService.getLabels(accountId,
			'campaign',
			newData => {
				setisLoading(false);
				setAccountLabels(newData);
			}, err => {
				setisLoading(false);
				systemServices.showError(t('labels.Error'));
			});
	}, [refetch]);
	useEffect(() => {
		const arrayOfLabelsCampaign = objectLabels.map(label => label.labelId);
		setObjectLabelIds(arrayOfLabelsCampaign);
	}, [objectLabels]);
	const handleSelectAnewLabelForCampaign = (label) => {
		if (!objectLabelIds.includes(label.id)) {
			setObjectLabelIds([...objectLabelIds, label.id]);
			setisLoading(true);
			labelService.addLabelForObject(accountId, objectId, 'campaign', label.id,
				newData => {
					setrefetch();
					setisLoading(false);
				},
				err => {
					setisLoading(false);
					systemServices.showError(
						t('labels.errorAssociate'));
				});
		} else {
			const withoutId = objectLabelIds.filter(id => id !== label.id);
			setObjectLabelIds(withoutId);
			setisLoading(true);
			labelService.deleteLabelForObject(accountId, objectId, label.id,
				newData => {
					setrefetch();
					setisLoading(false);
				},
				err => {
					setisLoading(false);
					systemServices.showError(t('labels.errorDelete'));
				});
		}
	};
	return (
		<div>
			<Modal
				isOpen={modalLabelsCampaignisOpen}
				width="450"
				onClose={(e) => onClose()}
			>
				<div className="modal_header">
				{t('labels.campaignLabels')}
					<div className="modal_header_desc">{subTitle}</div>
				</div>
				<CreateLabel
					systemServices={systemServices}
					labelService={labelService}
					accountId={accountId}
					setrefetch={(e) => setrefetch(e)}
					setisLoading={(e) => setisLoading(e)}
					onAddLabel={(e) => handleSelectAnewLabelForCampaign(e)}
					objectType='campaign'
				/>
				<div className="modal_body popin_labels_inner">
					<LabelSelector
						selectableLabels={accountLabels || []}
						selectedLabelIds={objectLabelIds || []}
						labelSelected={l => handleSelectAnewLabelForCampaign(l)}
					/>
					{!isLoading && accountLabels && accountLabels.length == 0 && <div className="popin_labels_empty" >
						<i className="fas fa-tag"></i>
						<div>{t('labels.noLabelYet')}</div>
					</div>}

				</div>
				<div className="modal_footer modal_footer_right">
					<Btn
						message={t('labels.Done')}
						style="outline"
						color="secondary"
						onClick={(e) => onSave()}
					/>
				</div>

				{isLoading &&
					<div className="mask">
						<div className="wheel"></div>
					</div>
				}
			</Modal>
		</div>
	);
}
