import React from 'react';
import { ReportGeneratorContext } from '../Context';
import { List, ListItem } from '../../../Components/List';
import IconBtn from '../../../Components/IconBtn';
import useCampaignStatus from '../../../Hooks/useCampaignStatus';
import formatMoment from '../../../Constants/FormatMoment';
export default function CampaignSelector() {

	const {
		setmodalIsOpen,
		selectableCampaigns,
		handleChangeCpgIdOptions,
		setcampaignSelected,
		setup,
	} = ReportGeneratorContext();

	const filteredCpg = setup.campaigns.map(x => x.campaignId);
	const { formatDateToLocal } = formatMoment;

	return (
		<List size='s'>
			{selectableCampaigns &&selectableCampaigns.items.filter(x => !filteredCpg.includes(x.id)).map(campaign => {
				const status = useCampaignStatus(campaign);
				const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
				const dateEndOfOperation = formatDateToLocal(campaign.endDate);
				let statusOperation = {};

				function getStatuCss() {
					// Campagne arrêtée
					if (status.Key === 'stopped' ) {
						return statusOperation = { id: 'stopped', label: 'Stopped', cssClass: 'status-stopped' };
					}

					// Campagne en mode test (Draft)
					if (status.Key === 'testing') {
						return statusOperation = { id: 'testing', label: 'Draft', cssClass: 'status-testing' };
					}

					// Campagne terminée (Past)
					if (status.Key === 'past') {
						return statusOperation = { id: 'past', label: 'Past', cssClass: 'status-past' };
					}

					// Campagne programmée pour le futur (Planned)
					if (status.Key === 'planned') {
						return statusOperation = { id: 'planned', label: 'Scheduled', cssClass: 'status-planned' };
					}

					// Campagne en cours (Ongoing)
					return statusOperation = { id: 'ongoing', label: 'Ongoing', cssClass: 'status-ongoing' };
				}

				getStatuCss();
				getStatuCss();

				return (
					<ListItem
						key={campaign.id}
						actions={
							<>
								<IconBtn
									icon='fas fa-plus-circle fa-sm'
									tooltip='Add campaign to your report'
									isActive={true}
									onClick={() => {
										setcampaignSelected(campaign);
										handleChangeCpgIdOptions(campaign);
										setmodalIsOpen(true);
									}}
								/>
							</>
						}
					>
						<div className='flex'>
							<div className='flex_item_fix mr_10'>
								<span className={'status_bullet ' + statusOperation.cssClass}></span>
							</div>
							<div className='flex_item_full'>
								<div className='s_14 fw_medium'>
									{campaign.name}
								</div>
								<div className='campaign_date'>
									{dateBeginOfOperation.format('DD/MM/YYYY')}
									<i className='fas fa-arrow-right'></i>
									{campaign.endDate !== null ? dateEndOfOperation.format('DD/MM/YYYY') : 'No end date'}
								</div>
								{campaign.description &&
									<div className='s_13 mt_2 grey_2'>
										{campaign.description}
									</div>
								}
							</div>
						</div>
					</ListItem>
				);
			})}
		</List>
	);
}
