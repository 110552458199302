import React from 'react'
import { useEffect, useState } from 'react';
import Btn from '../../../../Components/Btn'

export default function OperatorBetweenFilters({
	op,
	handleChangeOperator,
	filters
}) {

	if (!filters || filters.length <= 1) {
		return <></>
	}

	const [componentTorender, setcomponentToRender] = useState(<></>);


	function handleChangeOp(e, operator) {
		e.stopPropagation();
		handleChangeOperator(operator);
	}

	useEffect(() => {
		// init elementAvailableFilterSelected state 
		handleChangeOperator(op);
	}, []);

	useEffect(() => {
		setcomponentToRender(
			<span className='btn_switch'>
				<Btn
					message='AND'
					size='xxs'
					style='outline'
					color='secondary'
					isActive={op === "And"}
					onClick={(e) => handleChangeOp(e, "And")}
					className={'btn_w_xxs'}
				/>
				<Btn
					message='OR'
					size='xxs'
					style='outline'
					color='secondary'
					isActive={op !== "And"}
					onClick={(e) => handleChangeOp(e, "Or")}
					className={'btn_w_xxs'}
				/>
			</span>
		);
	}, [op]);

	return componentTorender;
}
