import React, { Component } from 'react';
import styles from './VisitorEngagementReportsCustomJourney.module.css';
import GaugeBar from '../../Components/GaugeBar';
import EngagementLevelColors from './EngagementLevelColors';
import EngagementLevelTitle from './EngagementLevelTitle';
import {Article} from '../../Components/Article/Article';

export default class VisitorEngagementReportsCustomJourneyComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Article size='l'>
				<h2 className={styles.title}>Customer journey</h2>
				<div className={styles.journey}>
					{this.props.data.map((level, index) => (
						<div className={styles.column} key={EngagementLevelTitle[level.levelTag]}>
							<div className={`${styles.header} ${index === 0 && styles.firstHeader} ${index === this.props.data.length - 1 && styles.lastHeader}`}>
								{EngagementLevelTitle[level.levelTag]}
							</div>
							<GaugeBar
								percentageValue={level.percentValue}
								color={EngagementLevelColors[level.levelTag]}
								customClassName={styles.gaugebar}
							/>
						</div>
					))}
				</div>
			</Article>
		);
	}
}