import React from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import styles from './ABTestReporting.module.css';
import { ratioToPercent } from '../utils';

export default function ABTestsReportingPlan({
	data,
	minValue,
	maxValue
}) {

	if(!data){
		return;
	}
	const tooltipID = uuidv4();
	const minPercent = 3;
	const maxPercent = 100 - minPercent;
	const magic = (maxPercent - minPercent) / (maxValue - minValue);

	const getScaledValue = (x) => {
		return ((x - minValue) * magic) + 3;
	};

	const left = getScaledValue(data.objectiveStats.rateLowerBound);

	const width = getScaledValue(data.objectiveStats.rateUpperBound) - left;

	return (
		<>
			<ReactTooltip
				id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={600}
				getContent={function() {
					return (
						<div className={styles.plan_tooltip}>
							<div className={styles.plan_tooltip_item}>
								<div className={styles.plan_tooltip_label}>Lower</div>
								<div className={styles.plan_tooltip_value}>{ratioToPercent(data.objectiveStats.rateLowerBound)}%</div>
							</div>
							<div className={styles.plan_tooltip_item}>
								<div className={styles.plan_tooltip_label}>Mean</div>
								<div className={styles.plan_tooltip_value}>{ratioToPercent(data.objectiveStats.rateMean)}%</div>
							</div>
							<div className={styles.plan_tooltip_item}>
								<div className={styles.plan_tooltip_label}>Higher</div>
								<div className={styles.plan_tooltip_value}>{ratioToPercent(data.objectiveStats.rateUpperBound
)}%</div>
							</div>
						</div>
					);
				}}
			/>
			<div className={styles.plan} data-tip="a" data-for={tooltipID}>
				<div className={styles.plan_range} style={{'width': width + '%', 'left': left + '%'}}>
					<div className={styles.plan_mean_value}>{ratioToPercent(data.objectiveStats.rateMean)}%</div>
				</div>
			</div>
		</>
	);
}