import React, {} from 'react';
import { react2angular } from 'react2angular';
import ABTestsDashboardContainer from './ABTestsDashboardContainer';

export default function ABTestsDashboardCompletedContainer(props) {
	return <ABTestsDashboardContainer page="completed" {...props} />;
}

angular
	.module('beyableSaasApp.ABTestsDashboardCompleted', ['beyableSaasApp.Services'])
	.component('abtestsdashboardcompleted', react2angular(ABTestsDashboardCompletedContainer, [],
		['$rootScope', '$http', '$routeParams', '$timeout', '$location', 'AuthServices']));