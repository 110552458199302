import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

export default function DownloadStatsEmail({ stats, mainInformation, dateRange }) {
	const [data, setData] = useState([]);
	const [dataFileName, setDataFileName] = useState('file');

	function getDataSecondaryIndicators() {
		if (!dateRange.startDate) {
			return;
		}
		const dateRangeReadable = dateRange.startDate.format('DD/MM/YYYY') + ' - ' + dateRange.endDate.format('DD/MM/YYYY');
		const statsForCSV = [
			{
				name: 'Number of emails clicked',
				key: 'numberOfEmailsClicked',
				value: stats.numberOfEmailsClicked,
				idCampaign: mainInformation.id.toUpperCase(),
				campaign: mainInformation.name,
				dateRange: dateRangeReadable
			},
			{
				name: 'Number of emails delivred',
				key: 'numberOfEmailsDelivered',
				value: stats.numberOfEmailsDelivered,
				idCampaign: mainInformation.id.toUpperCase(),
				campaign: mainInformation.name,
				dateRange: dateRangeReadable
			},
			{
				name: 'Number of emails opened',
				key: 'numberOfEmailsOpened',
				value: stats.numberOfEmailsOpened,
				idCampaign: mainInformation.id.toUpperCase(),
				campaign: mainInformation.name,
				dateRange: dateRangeReadable
			},
			{
				name: 'Number of emails sent',
				key: 'numberOfEmailsSent',
				value: stats.numberOfEmailsSent,
				idCampaign: mainInformation.id.toUpperCase(),
				campaign: mainInformation.name,
				dateRange: dateRangeReadable
			}
		];
		setData([...statsForCSV]);
		setDataFileName(`${mainInformation && mainInformation.id.toUpperCase()}_${mainInformation && mainInformation.name.toUpperCase()}`);
	}

	const headers = [
		{ label: 'Campaign name', key: 'campaign' },
		{ label: 'Id campaign', key: 'idCampaign' },
		{ label: 'Date range', key: 'dateRange' },
		{ label: 'KPI', key: 'name' },
		{ label: 'Value', key: 'value' },
	];
	useEffect(() => {
		if (stats && mainInformation && dateRange) {
			getDataSecondaryIndicators();
		}
	}, [stats]);
	return (
		<>
			<CSVLink
				asyncOnClick={true}
				data={data}
				className="reporting_link"
				headers={headers}
				separator={';'}
				filename={`${dataFileName}.csv`}
				target="_blank"
				enclosingCharacter={'\''}>
                Download CSV
			</CSVLink>
		</>
	);
}