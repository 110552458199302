import React, { useState, useEffect } from 'react';
import Btn from '../../../Components/Btn';
import CampaignItem from './CampaignItem';
import PopinConfirm from '../../../Components/PopinConfirm';
import Modal from '../../../Components/Modal';
import InputCustom from '../../../Components/InputCustom';
import SelectCustom from '../../../Components/SelectCustom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { v4 as uuidv4 } from 'uuid';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const list = [

	{
		value: 'unlimited',
		label: 'unlimited',
	},
	{
		value: 1,
		label: '1',
	}
];

export default function EditSmartCampaignsGroup({ setCurrentTab, currentOrchestration, oppenSettingsForCurrentorchestration, setOppenSettingsForCurrentorchestration, orchestrationServices, systemServices, accountId, setCurrentOrchestration, returnToDashBoard, clickToSave, setClickToSave, clickToCancel,
	setClickToCancel, sethasNotSaveChanges }) {

	const [orchestrationGroupToPut, setOrchestrationGroupToPut] = useState();

	const [copyOfOrchestrationSettingsToPut, setCopyOfOrchestrationToPut] = useState();
	const [hasToPutTheGroupSettings, setHasToPutTheGroupSettings] = useState(false);
	const [oppenModalToAddCampaign, setOppenModalToAddCampaign] = useState(false);
	const [campaignToAdd, setCampaignToAdd] = useState(null);
	const [listOfCampaigns, setListOfCampaigns] = useState();
	const [isReloading, setIsRealoading] = useState(true);
	const [modalCOnfirmIsOppen, setModalCOnfirmIsOppen] = useState(false);
	const animatedComponents = makeAnimated();
	useEffect(() => {
		if (currentOrchestration) {
			setOrchestrationGroupToPut({ ...currentOrchestration });
		}
	}, [currentOrchestration]);

	const cancelAllChangesToCurrentOrchestration = () => {
		if (clickToCancel) {
			setClickToCancel(false);
			getDataOfOrchestrationGroup();
		}
	};
	useEffect(() => {
		cancelAllChangesToCurrentOrchestration();
	}, [clickToCancel]);
	useEffect(() => {
		const getListCampaign = () => {
			orchestrationServices.getCampaignsList(accountId,
				data => {
					setListOfCampaigns(data.filter(x => x.campaignName.includes('reco')));
					setIsRealoading(false);
				},
				error => {
					console.error('error', error);
					setIsRealoading(false);
					systemServices.showError('An error occured while fetching the campaign list');
				});
		};
		getListCampaign();
	}, []);

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(orchestrationGroupToPut.campaigns);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setOrchestrationGroupToPut({ ...orchestrationGroupToPut, campaigns: items });
		sethasNotSaveChanges(true);
	};

	const handleSelectACampaign = (e) => {
		setCampaignToAdd(e.value);
	};
	const handleClickAddANewCampaignToList = () => {
		const campaignsInTheCurrentOrchestration = orchestrationGroupToPut.campaigns.map(el => el.campaignId);
		if (campaignsInTheCurrentOrchestration.includes(campaignToAdd)) {
			setOppenModalToAddCampaign(false);
			setCampaignToAdd(null);
		} else {
			const campaignSelected = listOfCampaigns.find(campaign => campaign.campaignId === campaignToAdd);
			const newCampaign = {
				...campaignSelected, campaignDisplayStrategy:
					{ limitToOneDisplayBySession: false }
			};
			setOrchestrationGroupToPut({
				...orchestrationGroupToPut,
				campaigns: [...orchestrationGroupToPut.campaigns, newCampaign]
			});
			setOppenModalToAddCampaign(false);
			setCampaignToAdd(null);
		}
		sethasNotSaveChanges(true);
	};
	const handleChangeForm = (e) => {
		const newSelectValue = e.target.value === 'unlimited' ? null : e.target.value;
		let myStateToChange = { ...orchestrationGroupToPut };

		if (myStateToChange.group.campaignGroupDisplayStrategy) {
			myStateToChange.group.campaignGroupDisplayStrategy[e.target.name] = newSelectValue;
		} else {
			myStateToChange.group.campaignGroupDisplayStrategy = {
				maxNumberOfDisplayByPage: '',
				maxNumberOfDisplayBySession: ''
			};
			myStateToChange.group.campaignGroupDisplayStrategy[e.target.name] = newSelectValue;
		}
		setOrchestrationGroupToPut(myStateToChange);
		sethasNotSaveChanges(true);
	};
	const handleChangeFormOfCampaign = (idOfCampaign, e) => {
		const newSelectValue = e.target.value === 'unlimited' ? false : true;
		// const newSelectValue = typeof e === 'boolean' ? e : e.target.checked;
		const actualCampaigns = orchestrationGroupToPut.campaigns;
		let campaignToChange = orchestrationGroupToPut.campaigns.find(el => el.campaignId === idOfCampaign);
		campaignToChange.campaignDisplayStrategy.limitToOneDisplayBySession = newSelectValue;
		const indexOfItem = orchestrationGroupToPut.campaigns.indexOf(campaignToChange);
		function replaceAt(array, index, value) {
			const ret = array.slice(0);
			ret[index] = value;
			return ret;
		}
		const newArrayOfCampaign = replaceAt(actualCampaigns, indexOfItem, campaignToChange);
		setOrchestrationGroupToPut({ ...orchestrationGroupToPut, campaigns: newArrayOfCampaign });
		sethasNotSaveChanges(true);
	};

	const getDataOfOrchestrationGroup = () => {
		setIsRealoading(true);
		orchestrationServices.getorchestrationGroupInfos(accountId, currentOrchestration.group.id,
			data => {
				setIsRealoading(false);
				setCurrentOrchestration(data);
				setOrchestrationGroupToPut(data);
			},
			error => {
				console.error('error', error);
				systemServices.showError('An error occured while fetching the orchestration');
				setIsRealoading(false);
			});
	};
	const putOrchestrationDisplayStrategy = () => {
		if (!orchestrationGroupToPut) return;
		setIsRealoading(true);
		const hasOwnPropertyFn = (obj, property) => {
			// console.log('🚀--** ~ file: EditGroupOrchestrationContainer.js:151 ~ hasOwnPropertyFn ~ obj:', obj)

			if (obj !== undefined) {
				return obj?.hasOwnProperty(property) ? obj[property] : '';
			}
		};
		const arrayOfcampaigns = orchestrationGroupToPut.campaigns.map(campaign =>
		({
			campaignId: campaign.campaignId,
			campaignDisplayStrategy: { limitToOneDisplayBySession: campaign.campaignDisplayStrategy.limitToOneDisplayBySession }
		})
		);
		const updateObjectCampaignGroupDisplayStrategy = {
			campaignGroupDisplayStrategy: {
				maxNumberOfDisplayByPage: orchestrationGroupToPut.group.campaignGroupDisplayStrategy !== null ?
					hasOwnPropertyFn(orchestrationGroupToPut.group.campaignGroupDisplayStrategy, 'maxNumberOfDisplayByPage') : '',

				maxNumberOfDisplayBySession: orchestrationGroupToPut.group.hasOwnProperty('campaignGroupDisplayStrategy') !== null ?
					hasOwnPropertyFn(orchestrationGroupToPut.group.campaignGroupDisplayStrategy, 'maxNumberOfDisplayBySession') : ''
			},
			campaignDisplayStrategies: arrayOfcampaigns
		};

		orchestrationServices.putorchestrationGroupStrategy(accountId, orchestrationGroupToPut.group.id, updateObjectCampaignGroupDisplayStrategy,
			success => {
				if (hasToPutTheGroupSettings) {
					// put the name and desc
					const orchestrationSettings = {
						name: orchestrationGroupToPut.group.name,
						description: orchestrationGroupToPut.group.description,
					};
					orchestrationServices.putorchestrationGroup(accountId, orchestrationGroupToPut.group.id, orchestrationSettings,
						success => {
							systemServices.showSuccess('The group has been updated with success');
							setClickToSave(false);
							setIsRealoading(false);
							sethasNotSaveChanges(false);
						},
						error => {
							console.error('error', error);
							setIsRealoading(false);
							systemServices.showError('An error occured during the edition of the group');
						});
				} else {
					systemServices.showSuccess('The group has been updated with success');
					setClickToSave(false);
					setIsRealoading(false);
					sethasNotSaveChanges(false);
				}
			},
			error => {
				console.error('error', error);
				setIsRealoading(false);
				systemServices.showError('An error occured during the edition of the group');
			});
	};

	useEffect(() => {
		if (oppenSettingsForCurrentorchestration) {
			setCopyOfOrchestrationToPut({
				description: orchestrationGroupToPut.group.description,
				name: orchestrationGroupToPut.group.name
			});
		}
	}, [oppenSettingsForCurrentorchestration]);
	const saveChangeForTheSettingsOfCurrentOrchestration = () => {
		let newGroupOrchestrationSettings = { ...orchestrationGroupToPut.group };
		newGroupOrchestrationSettings.description = copyOfOrchestrationSettingsToPut.description;
		newGroupOrchestrationSettings.name = copyOfOrchestrationSettingsToPut.name;
		setOrchestrationGroupToPut({
			...orchestrationGroupToPut,
			group: newGroupOrchestrationSettings
		});
		setHasToPutTheGroupSettings(true);
		setOppenSettingsForCurrentorchestration(false);
	};
	const handleChangeFormSettings = (e) => {
		const newSelectValue = e.target.value;
		let myStateToChange = { ...copyOfOrchestrationSettingsToPut };
		myStateToChange[e.target.name] = newSelectValue;
		setCopyOfOrchestrationToPut(myStateToChange);
		sethasNotSaveChanges(true);
	};

	useEffect(() => {
		if (clickToSave) {
			// put the orchestration
			putOrchestrationDisplayStrategy();
		}
	}, [clickToSave]);

	const valueSelect = (element) => {
		if (orchestrationGroupToPut && orchestrationGroupToPut.group.campaignGroupDisplayStrategy) {
			if (orchestrationGroupToPut.group.campaignGroupDisplayStrategy[element] !== null) {
				return orchestrationGroupToPut.group.campaignGroupDisplayStrategy[element];
			} else {
				return 'unlimited';
			}
		} else {
			return 'choose';
		}
	};
	const handleDeleteACampaign = (id) => {
		const campaignList = orchestrationGroupToPut.campaigns.filter(campaign => campaign.campaignId !== id);
		setOrchestrationGroupToPut({
			...orchestrationGroupToPut,
			campaigns: campaignList
		});
		sethasNotSaveChanges(true);
	};
	const deleteAnOrchestration = () => {
		setIsRealoading(true);
		orchestrationServices.deleteOrchestration(accountId, orchestrationGroupToPut.group.id,
			success => {
				systemServices.showSuccess('The group has been deleted with success');
				setModalCOnfirmIsOppen(false);
				sethasNotSaveChanges(false);
				setTimeout(() => {
					setIsRealoading(false);
					returnToDashBoard('deleting');
				}, 500);
			},
			error => {
				console.error('error', error);
				setIsRealoading(false);
				systemServices.showError('An error occured during the delete of the group');
			});
	};

	const [clipBoardContain, setClipBoardContain] = useState();
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
		setTimeout(() => {
			setClipBoardContain();
		}, 2000);
	};

	const [groupId, setGroupId] = useState(uuidv4());


	return (
		<>
			{
				modalCOnfirmIsOppen &&
				<PopinConfirm
					cancel={() => setModalCOnfirmIsOppen(false)}
					title={`Do you really want to delete the group ${orchestrationGroupToPut.group.name} ? `}
				>
					<Btn
						onClickFunction={(e) => { setModalCOnfirmIsOppen(false); }}
						message="Cancel"
						color="primary"
					/>
					<Btn
						onClickFunction={(e) => deleteAnOrchestration(orchestrationGroupToPut.group.id)}
						message="Yes delete"
						color="alert"
					/>
				</PopinConfirm>
			}
			<section className="section xxl flex flex_align_start">
				<div className="flex_item_fix">
					<div className="orchestration_picto">
						<i className="fas fa-folder"></i>
					</div>
				</div>
				<div className="flex_item_full">
					{orchestrationGroupToPut &&
						<>
							<div>
								<span className="orchestration_group_title title_1">
									{orchestrationGroupToPut.group.name}
								</span>
								<a className="orchestration_group_link" onClick={() => setOppenSettingsForCurrentorchestration(true)}>
									Rename
								</a>
								<span className="dot"></span>
								<a className="orchestration_group_link" onClick={() => setModalCOnfirmIsOppen(true)}>
									Delete
								</a>
							</div>
							{orchestrationGroupToPut.group.description &&
								<div className="orchestration_group_desc grey_1 s_14">
									{orchestrationGroupToPut.group.description}
								</div>
							}
						</>
					}
				</div>
			</section>
			<section className="section no_top_pad flex flex_align_start orchestration_group_body">
				<div className="orchestration_group_settings flex_item_fix">
					<div className="title_3">Smart Campaign composition</div>
					<div className="orchestration_group_settings_frame">
						<label className="orchestration_group_settings_label grey_1">API identifier</label>

						<div className="flex orchestration_group_settings_line">
							<div className="flex_item_full">
								<InputCustom
									value={groupId}
									onFocus={(e) => copyToClipBoard(groupId)}
									readonly={true}
									fullWidth={true}
								/>

								{/* <p className={clipBoardContain && clipBoardContain === groupId ?
									'admin_account_key copy_success' : 'admin_account_key'}>Key : <span
										onClick={(e) => copyToClipBoard(groupId)}
										className="account_key_copy">{groupId}</span><i className="far fa-clipboard"></i></p> */}
							</div>
							<div className="flex_item_fix">
								<Btn
									icon="far fa-clipboard"
									style="ghost"
									color="secondary"
									horizontalSize="xxs"
									onClick={(e) => copyToClipBoard(groupId)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="orchestration_group_campaigns flex_item_full">
					<div className="flex">
						{orchestrationGroupToPut && orchestrationGroupToPut.campaigns.length > 0 &&
							<>
								<div className="flex_item_full">
									<div className="title_3">Selected campaigns</div>
								</div>
								<div className="flex_item_fix ml_20">
									<Btn
										message="Add a campaign"
										onClick={() => setOppenModalToAddCampaign(true)}
										style="outline"
									/>
								</div>
							</>
						}
					</div>

					{orchestrationGroupToPut && orchestrationGroupToPut.campaigns.length == 0 &&
						<div className="empty_page has_background">
							<div className="empty_page_title">
								No campaign yet
							</div>
							<div className="empty_page_text">
								Add campaigns and prioritize them
							</div>
							<div className="flex_item_fix ml_20">
								<Btn
									message="Add a campaign"
									onClick={() => setOppenModalToAddCampaign(true)}
									style="outline"
								/>
							</div>
						</div>
					}

					{orchestrationGroupToPut && orchestrationGroupToPut.campaigns.length > 0 &&
						<div className="orchestration_grid">
							<div className="orchestration_grid_row orchestration_grid_head_row">
								<div className="orchestration_grid_col">Campaign</div>
								<div className="orchestration_grid_col"></div>
							</div>
							<DragDropContext onDragEnd={handleOnDragEnd}>
								<Droppable droppableId="id" direction="vertical">
									{(provided) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{orchestrationGroupToPut && orchestrationGroupToPut.campaigns.map((campaign, index) =>
												<Draggable key={campaign.campaignId} draggableId={campaign.campaignId.toString()} index={index}>
													{(provided, snapshot) => (
														<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
														>
															<CampaignItem
																accountId={accountId}
																handleChangeForm={(id, e) => handleChangeFormOfCampaign(id, e)}
																isDragging={snapshot.isDragging}
																campaign={campaign}
																handleDeleteACampaign={(e) => handleDeleteACampaign(e)}
																displayMode='drag' />
														</div>)}
												</Draggable>
											)}
											{provided.placeholder}
										</div>)}
								</Droppable>
							</DragDropContext>
						</div>
					}

				</div>
			</section>


			<Modal
				isOpen={oppenSettingsForCurrentorchestration}
				width="600"
				onClose={() => { setOppenSettingsForCurrentorchestration(false); setCopyOfOrchestrationToPut(); }}
			>
				<div className="modal_header has_border">
					Edit {currentOrchestration.group.name}
				</div>
				<div className="modal_section">
					<InputCustom
						value={copyOfOrchestrationSettingsToPut && copyOfOrchestrationSettingsToPut.name}
						name="name"
						type="text"
						label="Name"
						fullWidth='true'
						onChange={(e) => handleChangeFormSettings(e)}
					/>
					<InputCustom
						value={copyOfOrchestrationSettingsToPut && copyOfOrchestrationSettingsToPut.description}
						name="description"
						type="textarea"
						label="Description"
						fullWidth='true'
						onChange={(e) => handleChangeFormSettings(e)}
					/>
				</div>
				<div className="modal_footer al_right">
					<Btn
						onClickFunction={() => { setOppenSettingsForCurrentorchestration(false); setCopyOfOrchestrationToPut(); }}
						message="Cancel"
						color="secondary"
						size="l"
						style="ghost"
					/>
					<Btn
						onClickFunction={copyOfOrchestrationSettingsToPut && copyOfOrchestrationSettingsToPut.name.length !== 0 ? () => saveChangeForTheSettingsOfCurrentOrchestration() : null}
						message="Ok"
						disabled={copyOfOrchestrationSettingsToPut && copyOfOrchestrationSettingsToPut.name.length !== 0 ? false : true}
						size="l"
					/>
				</div>
			</Modal>
			<Modal
				isOpen={oppenModalToAddCampaign}
				width="700"
				onClose={() => setOppenModalToAddCampaign(false)}
			>
				<div className="modal_header has_border">
					Add a campaign to {currentOrchestration.group.name}
				</div>
				<div className="modal_section">
					<label htmlFor="campaign">Choose a campaign</label>
					<Select
						closeMenuOnSelect={true}
						components={animatedComponents}
						defaultValue={{
							value: 'choose',
							label: 'Select an option',
						}}
						menuPortalTarget={document.body}
						styles={{ menuPortal: base => ({ ...base, zIndex: 20000 }) }}
						menuPosition={'fixed'}
						options={listOfCampaigns && listOfCampaigns.map(el => {
							if (orchestrationGroupToPut && orchestrationGroupToPut.campaigns.find(x => x.campaignName === el.campaignName)){
								return {
									value: el.campaignId,
									label: `${el.campaignName} - Already in group`,
									isDisabled: true
								};
							}
							return {
								value: el.campaignId,
								label: el.group ? `${el.campaignName} - This campaign is already part of the ${el.group.name} group` : el.campaignName,
								isDisabled: el.group ? true : false
							};
						})}
						placeholder={'Search a campaign'}
						onChange={(e) => handleSelectACampaign(e)}
					/>
				</div>
				<div className="modal_footer al_right">
					<Btn
						onClickFunction={() => { setOppenModalToAddCampaign(false); setCampaignToAdd(null); }}
						message="Cancel"
						color="secondary"
						size="l"
						style="ghost"
					/>
					<Btn
						onClickFunction={campaignToAdd !== null ? () => handleClickAddANewCampaignToList() : null}
						message="Validate"
						disabled={campaignToAdd !== null ? false : true}
						size="l"
					/>
				</div>
			</Modal>
			{isReloading &&
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			}
		</>
	);
}
