/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable indent */
import React, { useState,useRef,useEffect } from 'react';
import moment from 'moment';
import Highcharts from 'highcharts/highmaps';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
import HighchartsReact from 'highcharts-react-official';

import worldMap from '@highcharts/map-collection/custom/world.geo.json';

import {countries } from '../../util.js';
import {GeolocalisationObject,DatasWorld, OptionsApiGeoloc,Dimensions}  from './GeolocalisationType';
import { useSessionContext } from '../../context/SessionContext';
import {useAnalyticsContext} from '../../context/AnalyticsContextProvider';
// import SelectMetricGeolocalisation from './SelectMetricGeolocalisation';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import styles from '../Card/Card.module.css';

export default function WorldChartContainer({
	service,
}) {
	const {
		navFilter,
		metricGeolocalisationSelected
	} = useSessionContext();
	const {selectedTenant} = useAnalyticsContext();
	const chartRef = useRef(null);
	const [data, setdata] = useState<GeolocalisationObject[]>([]);
	const [finalDataFormatted, setfinalDataFormatted] = useState<DatasWorld[]>([]);
	const [worldMapChart, setWorldMap] = useState();
	const [isLoading, setisLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (navFilter.fromDate && navFilter.toDate) {
				setisLoading(true);
				const options:OptionsApiGeoloc = { 
					'metrics': [
						metricGeolocalisationSelected.key],
					filters : navFilter.filters,
					'dimensions': [
						Dimensions.CountryCode,
						Dimensions.subdivision

			],
				fromDate : moment(navFilter.fromDate),
				toDate : moment(navFilter.toDate),
				};
				service.getSessionsGeolocalisation(options, (d:GeolocalisationObject[]) => {
					setdata(d);
					setisLoading(false);
				},
				(err)=>{
					console.error(err);

					setisLoading(false);
				});

			}}, 200);
		return () => {
			clearTimeout(timer);
		};
	}, [navFilter,metricGeolocalisationSelected]);
	
	useEffect(()=>{
		const dataWithValues = data.map((el:GeolocalisationObject) => {
			return { ...el, value: el[metricGeolocalisationSelected.key] };
		});
		const newArrayOfoCountries:string[] = [];
	
		for (const property in countries) {
			newArrayOfoCountries.push(property);
		}
		const countryDataAggregateValues = dataWithValues.reduce((acc, point) => {
			acc[point.iso] = (acc[point.iso] || 0) + point.value; 
			return acc;
		}, {});
		const dataWithAllCountries:DatasWorld[] = newArrayOfoCountries.map(ctr =>{
			const name = ctr.toLocaleLowerCase();
			const hasKeyInCountryData = countryDataAggregateValues[ctr];
			return  [name, hasKeyInCountryData ? hasKeyInCountryData : 0];
		} );
		setfinalDataFormatted(dataWithAllCountries);

	},[data]);

	useEffect(()=>{
		const data1:any = {
			chart: {
				map: worldMap,
				zooming: {
					mouseWheel: {
						enabled: false,
					}
				}
			},
			title: {
				text: ''
			},
			credits: {
				enabled: false,
			},
			tooltip: {
				backgroundColor: '#FFF',
				borderWidth: 0,
				opacity: 1,
				borderRadius: 10,
				style: {
					color: '#151E39',
					fontWeight: 'normal',
					fontSize: '12px',
					fontFamily: 'Roboto',
				}
			},
			mapNavigation: {
				enabled: true,
				buttonOptions: {
					alignTo: 'spacingBox'
				}
			},
			colorAxis: {
				min: 0,
				//type: 'logarithmic',
				minColor: '#E0E9FD',
				maxColor: '#0032A0',
				stops: [
					[0, '#E0E9FD'],
					[0.5, '#4278F0'],
					[1, '#0032A0']
				]
			},
			series: [
				{
					name: metricGeolocalisationSelected.label,
					states: {
						hover: {
							color: '#FFB320'
						}
					},
					dataLabels: {
						enabled: true,
						format: '{point.name}',
						color: '#151E39',
						style: {
							fontWeight: 'normal',
							fontFamily: 'Roboto',
						}
					},
					allAreas: false,
					data: [
						...finalDataFormatted
					],
					borderColor: '#FFFFFF',
				}
			]
		};
		setWorldMap(data1);
	},[finalDataFormatted,metricGeolocalisationSelected,selectedTenant]);

	return (
		<div className={styles.largeContainer}>
			<div className={styles.titleContainer}>
				<span className={styles.title}>Geolocalisation - Map</span>
			</div>
			<div className={styles.cardContainer}>
				{/*<SelectMetricGeolocalisation />*/}
				{isLoading && 
					<SpinnerWheel />
				}
				{worldMapChart && !isLoading &&
					<HighchartsReact
						className='chart'
						highcharts={Highcharts}
						options={worldMapChart}
						constructorType={'mapChart'}
						updateArgs={[true, true, true]}
						ref={chartRef}
					/>
				}
			</div>
		</div>
	);
}
