import { useEffect } from "react";

export const useDebouncedEffect = (effect, deps, delay) => {
    useEffect(() => {

        let handler;
        if (delay){
            handler = setTimeout(() => effect(), delay)
        }
        else {
            effect()
        }

        return () => handler && clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps || [], delay]);
}