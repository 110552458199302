import { createCheckbox } from './utils';

export const traitBlockResponsive = {
	noLabel: true,
	createInput({ trait }) {
		const [verticalWrapper, verticalCheckbox] = createCheckbox('hide_desktop', 'Display columns vertically on mobile');
		const [reverseWrapper, reverseCheckbox] = createCheckbox('hide_mobile', 'Reverse columns order on mobile');

		verticalCheckbox.addEventListener('change', ev => this.onChange(ev));
		reverseCheckbox.addEventListener('change', ev => this.onChange(ev));

		const wrapper = document.createElement('div');
		wrapper.appendChild(verticalWrapper);
		wrapper.appendChild(reverseWrapper);
		
		this.wrapper = wrapper;
		this.name = trait.id;
		this.verticalWrapper = verticalWrapper;
		this.reverseWrapper = reverseWrapper;
		this.verticalCheckbox = verticalCheckbox;
		this.reverseCheckbox = reverseCheckbox;
		return wrapper;
	},
	onEvent({ component }) {
		const isVertical = this.verticalCheckbox.checked;
		const isReverse = this.reverseCheckbox.checked;

		component.set('mobileVertical', isVertical);
		component.set('mobileReverse', isReverse);
		
		this.reverseWrapper.style.display = isVertical ? '' : 'none';

		this.updateClasses(component, isVertical, isReverse);

	},
	onUpdate({ component }) {
		const isVertical = component.get('mobileVertical');
		const isReverse = component.get('mobileReverse');

		this.verticalCheckbox.checked = isVertical;
		this.reverseCheckbox.checked = isReverse;

		this.updateClasses(component, isVertical, isReverse);
	},
	updateClasses(component, isVertical, isReverse) {
		if (isVertical) {
			component.addClass('by_mobile_vertical');
		} else {
			component.removeClass('by_mobile_vertical');
		}

		if (isReverse) {
			component.addClass('by_mobile_reverse');
		} else {
			component.removeClass('by_mobile_reverse');
		}
	}
};
