import React, { useState, useEffect } from 'react';
import styles from './Card.module.css';
import useCardIcon from '../../hooks/useCardIcon';
import { nFormat, approximateBrowser, approximateDevice, approximateCountry, approximateLanguage } from '../../util';

import {useEcommerceAnalyticsContext} from '../../context/EcommerceAnalyticsContext';
import {useEcommerceOverviewContext} from '../../context/EcommerceOverviewContext';
const CardOverview = ({
	title = 'Devices',
	dataCard = {
		data: [],
		loading: true,
		isError: false,
	},
	handleClick,
	isLarge = false,
	dimensionKey,
	ComparisonSelected,
	metricTypeOverviewSelected,
	chips,
	dimensionId,
	deviceSelected
}) => {
	const {
		// Tab state & utils
		handleChangeReportType,
		changeActiveSubDimensionTab,
		dimensionIdPerformance,
		setdimensionIdPerformance,
		setlabelDimension,
		setreportTypePerformanceSelected,
		
	} = useEcommerceAnalyticsContext();
	const {
	
		changeActiveDimensionTabOver
		
	} = useEcommerceOverviewContext();

	const [state, setState] = useState();

	const maxNumberOfLines = 5;
	var totalAmount;

	const data = dataCard?.data || [];

	const isLoading = dataCard.loading;
	const isError = dataCard.isError;

	const onHandleClick = (id,type ) => () => {
		// handleClick(dimensionKey,id, type);
		// setState(id);
		// handleChangeReportType(dimensionKey);
		changeActiveSubDimensionTab('performance',deviceSelected);
		setdimensionIdPerformance(id);
		setreportTypePerformanceSelected(dimensionKey);
		setlabelDimension(type);
		changeActiveDimensionTabOver('ecommerce', 'overview');
	};
	function handleSwitchToPerformanceWithSpecialCategory() {
		changeActiveDimensionTabOver('ecommerce', 'overview');
		handleChangeReportType(dimensionKey);
		changeActiveSubDimensionTab('performance');
		setdimensionIdPerformance(null);
		setlabelDimension();
	}
	return (
		<div className={isLarge ? styles.largeContainer : styles.container}>
			<div className={styles.titleContainer}>
				<span className={styles.title}>Best sellers - {title}</span>
			</div>
			<div className={styles.cardContainer}>
				{isLoading && (
					<div className="mask">
						<div className="wheel"></div>
					</div>
				)}
				{!isLoading && !isError && data.length === 0 && (
					<div
						className={styles.screenContainer}
					>
						<p className={styles.screen}>No data</p>
						<p className={styles.view}>-</p>
					</div>
				)}
				{!isLoading && isError && (
					<div
						className={styles.screenContainer}
					>
						<p className={styles.screen}>Cannot fetch data</p>
						<p className={styles.view}></p>
					</div>
				)}

				{ data.map((value, index) => {
			
					const name = value?.dimensionLabel || value?.dimensionId;
					const id = value?.dimensionId;
					let isSelected = chips !== null && dimensionId === id ? true : false;
					
					const icon = useCardIcon(dimensionKey, name);

					const total = value.metrics[0]?.total;
					const metric = value.metrics[0]?.value;
					const percentage = metric / total * 100;
					
					return (
						<div
							key={index}
							className={[styles.screenContainer, isSelected ? styles.isSelected : ''].join(' ')}
							onClick={onHandleClick(id,name)}
						>
							<div className={styles.progress}
								style={{ width: percentage.toFixed(1) + '%' }}
							>
								
							</div>
							<p className={styles.colScreen}>
								<span className={styles.number}>{(index + 1) + '. '}</span>
								{icon &&
									<span className={styles.icon}>
										<i className={icon}></i>
									</span>
								}
								{name}
							</p>
							<p className={styles.colPercent}>
								{percentage.toFixed(1) + '%'}
							</p>
							<p className={styles.colView}>
								{metric}
							</p>
						</div>
					);
				})}

			</div>
			{
				<div className={styles.viewMoreButton}>
					<span onClick={()=>handleSwitchToPerformanceWithSpecialCategory()}>{`View performance ${title}`}</span>
				</div>
			}
		</div>
	);
};

CardOverview.displayName = 'CardOverview';

export default CardOverview;