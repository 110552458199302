import React, {useState, useEffect} from 'react';
import InteractionFilterContainer from '../Interactions/InteractionFilterContainer';
import InteractionChartContainer from '../ChartContainer/InteractionChartContainer';
import InteractionTotalsContainer from '../Interactions/InteractionTotalsContainer';
import InteractionBoardContainer from '../Interactions/InteractionBoardContainer';

export default function InteractionTabContainer({
	interactionServices,
	sessionsServices,
	fetchCsvService,
	trackEvent
}) {

	const [selectedTimeGranularity, setSelectedTimeGranularity] = useState('Day');

	useEffect(() => {
		trackEvent('analytics/interactions-viewed');
	}, []);

	return (
		<div>
			<InteractionFilterContainer
				sessionsServices={sessionsServices}
				fetchCsvService={fetchCsvService}
			/>
			<InteractionTotalsContainer
				interactionServices={interactionServices}
			/>
			<InteractionChartContainer
				interactionServices={interactionServices}
				selectedTimeGranularity={selectedTimeGranularity}
				setSelectedTimeGranularity={setSelectedTimeGranularity}
			/>
			<InteractionBoardContainer
				interactionServices={interactionServices}
			/>
		</div>
	);
}
