import {getElementInteractionName} from '../../util';

export const handlePatchJs = (patch) => {
	let JsArray = [];
	const staticJS = `
var debug = !!localStorage.getItem('by_debug');
if (debug) {
	console.log('Start patch', CId);
}

function replaceHTML(el, html) {
	if (!el) return;
	if (!html) return;
	if (el.innerHTML == html) return;
	el.innerHTML = html;
}

function addClass(el, classArray) {
	if (!el) return;
	if (!classArray) return;
	for (var i = 0; i < classArray.length; i++) {
		el.classList.add(classArray[i]);
	}
}

function deleteClass(el, classArray) {
	if (!el) return;
	if (!classArray) return;
	for (var i = 0; i < classArray.length; i++) {
		el.classList.remove(classArray[i]);
	}
}

function setAttribute(el, attr, value) {
	if (!el) return;
	if (!attr) return;
	if (!value) return;
	el.setAttribute(attr, value);
}

function handle_link_interaction(el, interactionName) {
	function __auxclick(ev) {
		if (ev.button && ev.button == 1) {
			BY.by_SendInteractionForCampaign(0, interactionName, SId);
		}
	}
	function __click(ev) {
		if (!url || target == '_blank' || ev.metaKey || ev.ctrlKey || ev.shiftKey || (ev.button && ev.button == 1)) {
			BY.by_SendInteractionForCampaign(0, interactionName, SId);
			return;
		}
		ev.preventDefault();
		BY.by_SendInteractionForCampaignWithRedirection(0, interactionName, url, SId);
	}

	var url = el.getAttribute('href');
	var target = el.getAttribute('target');
	el.addEventListener('auxclick', __auxclick);
	el.addEventListener('click', __click);
}

function handle_click_interaction(el, interactionName) {
	function __click(ev) {
		BY.by_SendInteractionForCampaign(0, interactionName, SId);
	}

	var mainEventName = ('ontouchstart' in window) ? 'click' : 'mouseup';
	el.addEventListener(mainEventName, __click);
}

function getChildrenInteractions(el) {
	var children = el.querySelectorAll('a[href]');
	var childrenLinks = [];
	for (var i = 0; i < children.length; i++) {
		var a = children[i];
		if (a.getAttribute('href')) {
			childrenLinks.push({type: 'link', el: a});
		}
	}
	return childrenLinks;
}

function getElIntercation(el) {
	var parent;
	var type;
	
	var tagName = el.tagName && el.tagName.toLowerCase();
	if (!tagName) return false;

	if (tagName === 'a' && el.getAttribute('href')) {
		return {type: 'link', el: el};
	}

	parent = el.closest('a');
	if (parent && parent.getAttribute('href')) {
		return {type: 'link', el: parent};
	}

	if (tagName === 'button') {
		return {type: 'click', el: el};
	}

	parent = el.closest('button');
	if (parent) {
		return {type: 'click', el: parent};
	}

	if (tagName === 'input') {
		type = el.getAttribute(type);
		if (type === 'submit' || type === 'button') {
			return {type: 'click', el: el};
		}
	}

	parent = el.closest('input');
	if (parent) {
		type = parent.getAttribute(type);
		if (type === 'submit' || type === 'button') {
			return {type: 'click', el: parent};
		}
	}

	return {type: 'click', el: el};
}

function patchElement(sel, opt) {
	if (!sel) return;
	if (!opt) return;

	function _testSelector() {
		var el = document.querySelector(sel);
		if (!el) return;
		
		clearInterval(timer);

		if (opt.html) replaceHTML(el, opt.html);
		if (opt.addedClass && opt.addedClass.length) addClass(el, opt.addedClass);
		if (opt.deletedClass && opt.deletedClass.length) deleteClass(el, opt.deletedClass);
		if (opt.id) setAttribute(el, 'id', opt.id);
		if (opt.placeholder) setAttribute(el, 'placeholder', opt.placeholder);
		if (opt.imgSrc) {
			setAttribute(el, 'src', opt.imgSrc);
			setAttribute(el, 'srcset', opt.imgSrc);
		}
		if (opt.href) {
			var clone = el.cloneNode(true);
			el.insertAdjacentElement('afterend', clone);
			el.remove();
			el = clone;
			setAttribute(el, 'href', opt.href);
		}

		var interaction;
		var childrenInteractions = getChildrenInteractions(el);
		if (childrenInteractions) {
			for (var i = 0; i < childrenInteractions.length; i++) {
				interaction = childrenInteractions[i];
				switch (interaction.type) {
					case 'link': 
						handle_link_interaction(interaction.el, opt.interactionName + '_child_link');
						break;
				}
			}
		}

		interaction = getElIntercation(el);
		if (interaction) {
			switch (interaction.type) {
				case 'link':
					handle_link_interaction(interaction.el, opt.interactionName);
					break;
				case 'click':
					handle_click_interaction(el, opt.interactionName);
					break;
			}
		}
	}

	var timer = setInterval(_testSelector, 200);
	_testSelector();

	if (debug) {
		console.log(sel, opt);
	}
}
	`;

	patch.map((elem) => {
		
		// HTML update
		let html;
		if (elem?.modifications?.text && elem?.textContent?.value) {
			html = elem.textContent.value.replaceAll('\'', '\\\'');
			html = html.replaceAll('"', '\\"');
			html = html.replace(/(?:\r\n|\r|\n)/g, '');
		}

		// Added classes
		let addedClass;
		if (elem?.attributesMod?.addedClass) {
			addedClass = JSON.stringify(elem.attributesMod.addedClass);
		}

		// Deleted classes
		let deletedClass;
		if (elem?.attributesMod?.deletedClass) {
			deletedClass = JSON.stringify(elem.attributesMod.deletedClass);
		}

		// ID
		let id;
		if (elem?.attributesMod?.id) {
			id = elem.attributesMod.id;
		}

		// Placeholder
		let placeholder;
		if (elem?.attributesMod?.placeholder) {
			placeholder = elem.attributesMod.placeholder;
		}

		// Image src
		let imgSrc;
		if (elem.tag == 'IMG' && elem?.modifications?.uploadImage && elem?.uploadImage?.src) {
			imgSrc = elem.uploadImage.src;
		}

		// Href
		let href;
		if (elem?.modifications?.link && elem?.linkMod?.href) {
			href = elem.linkMod.href;
		}

		// Selector name
		let selectorName = '';
		if (elem?.selectorName) {
			selectorName = elem.selectorName;
		}

		let interactionName = getElementInteractionName(elem);

		// JS
		const elemJS = `
			patchElement(
				"${elem.initCssSelector}",
				{
					html: ${html ? '"'+html+'"' : 'null'},
					addedClass: ${addedClass ? addedClass : 'null'},
					deletedClass: ${deletedClass ? deletedClass : 'null'},
					id: ${id ? '"'+id+'"' : 'null'},
					placeholder: ${placeholder ? '"'+placeholder+'"' : 'null'},
					imgSrc: ${imgSrc ? '"'+imgSrc+'"' : 'null'},
					href: ${href ? '"'+href+'"' : 'null'},
					selectorName: '${selectorName}',
					interactionName: '${interactionName}',
				}
			);
		`;
		JsArray.push(elemJS);
	});

	const dynamicJS = JsArray.join('');
	const finalJS = `
(function(SId, CId) {
	${staticJS}
	${dynamicJS}
}(SId, CId));
	`;
	
	return finalJS;
};
export const handlePatchCss = (patch) => {
	const cssArray = patch.map((elem) => {
		const normalStyle = generateClass(elem.editableCss);
		const normalRule = elem.cssSelector + objectToCss(normalStyle);

		let hoverRule = '';
		if (Object.keys(elem.hoverCss).length > 0) {
			const styleHover = generateClass(elem.hoverCss);
			hoverRule = elem.cssSelector + ':hover' + objectToCss(styleHover);
		}
		return normalRule + ' ' + hoverRule;
	});
	return cssArray.join(' ');
};
const generateClass = (editableCss) => {
	let styleProperty = {};

	var propertyName;
	for (const key in editableCss) {
		let style = editableCss[`${key}`];

		switch (key) {
			case 'fontSize':
			case 'lineHeight':
				if (!isNaN(style)) {
					style += 'px';
				}
				break;
		}

		if (!style) continue;

		const importantStyle = style + ' !important';
		let i = 0;

		while (i <= key.length) {
			let character = key.charAt(i);
			if (character == character.toUpperCase()) {
				propertyName = styleFormatTiret(key);
			} else {
				propertyName = key;
			}
			i++;
		}

		// @ts-ignore
		styleProperty[`${propertyName}`] = importantStyle;
	}

	return styleProperty;
};

export const htmlTypeVariationAbTestRedirection = `<div id="by_sld{{SId}}" tags="{{SId}}" tagc="{{CId}}" tagr="{{Key}}" tagv="{{VariationName}}" tagt="{{VarianteName}}" style="display:none;"></div>
<div id="by_cta{{SId}}" tag="{{Key}}" style="display:none;"></div>`;

const objectToCss = (object) => {
	return JSON.stringify(object, null, '\t')
		.replace(/"/g, '')
		.replace(/,\n/g, ';')
		.replace(/\n\}/g, ';\n}');
};

const styleFormatTiret = (nomPropriete) => {
	function majusculesEnTiretMinuscules(correspondance, decalage, chaine) {
		return (decalage > 0 ? '-' : '') + correspondance.toLowerCase();
	}
	return nomPropriete.replace(/[A-Z]/g, majusculesEnTiretMinuscules);
};


const versionIsGreater = (a, b) => {
	const aSplit = a.split('.');
	const bSplit = b.split('.');
	if (aSplit.length != bSplit.length) {
		console.error('The version number of the extension is not valid', a, b);
		return false;
	}
	for (let i = 0; i < aSplit.length; i++) {
		const aValue = aSplit[i];
		const bValue = bSplit[i];
		if (aValue < bValue) {
			return false;
		}
	}
	return true;
};

export const tryExtension = (extensionId,version, setHasExtension, setHasGoodVerision,callbackData) => {
	try {
		
		if (chrome.runtime) {
			chrome.runtime.sendMessage(
				extensionId,
				{
					type: 'version',
				},
				(reply) => {
					if (reply) {
						if (reply.version) {
							callbackData(reply.version);
							if (versionIsGreater(reply.version, version)) {
								setHasGoodVerision(true);
								setHasExtension(true);
							} else {
								setHasGoodVerision(false);
								setHasExtension(true);
							}
						}
					} else {
						setHasExtension(false);
						setHasGoodVerision(true);
					}
					return;
				}
			);
		} else {
			setHasExtension(false);
			setHasGoodVerision(true);
		}
	} catch (error) {
		console.log('error tryExtension', error);
		setHasGoodVerision(true);
		setHasExtension(false);
	}
};