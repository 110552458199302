import React, {useState, useEffect} from 'react';
import FieldContainer from './FieldContainer';
import {useProductFeedEditionContext} from '../../context/ProductFeedEditionContext';
import SimpleField from './SimpleField';
import {fieldIsXpathCustom } from '../../utils';
import MappingPricesContainer from './MappingPricesContainer';
import editionStyles from '../ProductFeedEdition.module.css';
import {MappingTagsContainer} from './Tags/MappingTagsContainer';
import {MappingCategoriesContainer} from './Categories/MappingCategoriesContainer';
import SectionMessage from '../../../../Components/SectionMessage';

export default function MappingFieldsContainer() {
	const {
		mappingObject,
		productFeedObject,
		metadatasSources,
		hasErrorsOnColums,
		columnsValues
	} = useProductFeedEditionContext();

	const [priceContainError, setpriceContainError] = useState(false);
	let fieldIndex = 0;
	function checkPricesContainer(){

		if(productFeedObject?.type === 'GenericUri' && productFeedObject?.format?.type === 'Xml' ){
			if(mappingObject.hasOwnProperty('Price1') && mappingObject.hasOwnProperty('Price2')){
				const valueOfField = mappingObject['Price1'][0].sourceField;
				const testIfExist = metadatasSources.find(el => el.value === valueOfField );
				const valueOfField2 = mappingObject['Price1'][0].sourceField;
				const testIfExist2 = metadatasSources.find(el => el.value === valueOfField2 );
				if(!fieldIsXpathCustom(mappingObject['Price2'][0].sourceField) ||!fieldIsXpathCustom(mappingObject['Price2'][0].sourceField) ){
					if(!testIfExist || !testIfExist2){
						return true;
					}
				}else {
					return false;
				}	
			}
			else if(mappingObject.hasOwnProperty('Price1') && !mappingObject.hasOwnProperty('Price2')){
				const valueOfField = mappingObject['Price1'][0].sourceField;
				const testIfExist = metadatasSources.find(el => el.value === valueOfField );
				if(!fieldIsXpathCustom(mappingObject['Price1'][0].sourceField)){
					if(!testIfExist ){
						return  true;
					}else {
						return false;
					}
				}
			}
			else if(mappingObject.hasOwnProperty('Price2')  && !mappingObject.hasOwnProperty('Price1')){
				const valueOfField2 = mappingObject['Price2'][0].sourceField;
				const testIfExist2 = metadatasSources.find(el => el.value === valueOfField2 );
				if(!fieldIsXpathCustom(mappingObject['Price2'][0].sourceField)){
					if(!testIfExist2 ){
						return  true;
					}
				}else {
					return false;
				}
			}else {
				return false;
			}
		}else{ 
			return false;}
	}
	useEffect(()=>{
		setpriceContainError(checkPricesContainer());
	},[mappingObject,
		productFeedObject,
		metadatasSources]);


	return (
		<>
		{hasErrorsOnColums&& 
				<SectionMessage
					type="warning"
					title={'Please check the feed accessibility'}
					text={'This product feed service cannot be reached, we cannot retrieve the available columns. Please check the feed accessibility'}
				/>}
			<SimpleField
				name='Id'
				label='Product ID'
				fieldIndex={fieldIndex++}
				canUseTranformations={true}
			/>
			<SimpleField
				label='Product name'
				name='Name'
				fieldIndex={fieldIndex++}
				canUseTranformations={true}
			/>
			<SimpleField
				label='Description'
				name='Description'
				fieldIndex={fieldIndex++}
			/>
			<SimpleField
				label='Product URL'
				name='Url'
				fieldIndex={fieldIndex++}
				canUseTranformations={true}
			/>
			<SimpleField
				label='Product image'
				name='UrlThumbnail'
				fieldIndex={fieldIndex++}
				canUseTranformations={true}
			/>
			<FieldContainer
				valueDoesntExistInList={priceContainError}
				label='Product prices'
				subTitle={
					`You can select 2 price fields,
					the system will display the correct price according to the configuration.`
				}
				name='Prices'
				type='price'
				fieldIndex={fieldIndex++}
			>
				<MappingPricesContainer canUseTranformations={true}/>
			</FieldContainer>
			<SimpleField
				label='Stock quantity'
				name='Stock'
				fieldIndex={fieldIndex++}
				canUseTranformations={true}
			/>
			<MappingCategoriesContainer
				fieldIndex={fieldIndex++}
				// canUseTranformations={true}
			/>
			<FieldContainer
				name='Tags'
				label='Tags'
				fieldIndex={fieldIndex++}
			>
				<MappingTagsContainer
					fieldIndex={fieldIndex++}
					canUseTranformations={true}
				/>
			</FieldContainer>
		</>
	);
}
