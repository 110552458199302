import React from 'react';
import { useEffect, useState } from 'react';
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function AutocompleteTagContainer({
	value,
	onChange,
	label,
	labelPosition,
	color,
	emptyState,
	accountId,
	tenant,
	setupAccountServices
}) {
	const [valueOfSelectedTag, setvalueOfSelectedTag] = useState({
		label: 'Choose',
		value: ''
	});
	const [autocompleteValue, setAutocompleteValue] = useState();
	const [autocompleteListIsLoading, setautocompleteListIsLoading] = useState(false);
	const [list, setlist] = useState([]);
	const [listTags, setlistTags] = useState([]);

	useEffect(() => {
		if (listTags) {
			const newArr = listTags.map(el => {
				return {
					value: el,
					label: el
				};
			});
			setlist(newArr);
		}
		else {
			setlist([]);
		}
	}, [listTags]);
	function handleChageAutcomplete(val) {
		setAutocompleteValue(val);
	}
	function onAutoSelect(val) {
		const newValue = list.find(el => el.value === val);
		onChange(newValue.value);
	}
	function onGetTagsByIdList(str) {
		setautocompleteListIsLoading(true);
		setupAccountServices.getAccountReferenceData(accountId,
			'pagetagkey',
			str,
			tenant,
			newData => {
				setlistTags(newData);
				setautocompleteListIsLoading(false);
			},
			error => {
				setautocompleteListIsLoading(false);
			}

		);
	}
	useEffect(()=>{
		if(autocompleteValue){
			const Debounce = setTimeout(() => {
				onGetTagsByIdList(autocompleteValue);
			}, 1500);
			return () => {
				clearTimeout(Debounce);
			};
		}
	},[autocompleteValue]);
	useEffect(()=>{
		if(value === ''){
			setvalueOfSelectedTag({
				label: 'Choose',
				value: ''
			});
		}else{
			setvalueOfSelectedTag({
				label: value,
				value: value
			});
		}
	},[value]);
	return (
		<>
			<SelectDropdown
				label={label}
				optionsList={list}
				value={valueOfSelectedTag}
				onChange={(v) => onAutoSelect(v)}
				friendlyValue={valueOfSelectedTag.label}
				autocomplete={true}
				autocompleteIsLoading={autocompleteListIsLoading}
				autocompleteValue={autocompleteValue}
				autocompletePlaceholder={'Search for a Tag'}
				onAutocomplete={(v) => handleChageAutcomplete(v)}
				emptyState={emptyState}
				size={'l'}
			/>
		</>
	);
}
