import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../BusinessOperationBanner.css';
import useEventBroker from '../../../../Hooks/useEventBroker';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';

export default function BannerVideoPlayer({ banner }) {
	const {
		selectedZone
	} = useContext(CreateBusinessOperationContext);

	const { publish } = useEventBroker();
	const ttId = uuidv4();
	const [isDisabled, setIsDisabled] = useState(true);
	const [elementClass, setElementClass] = useState([]);

	const [title, setTitle] = useState('Select a zone first');

	const refElement = useRef(null);

	useEffect(() => {
		computeStateRegardingSelectedZone();
	}, [selectedZone]);

	useEffect(() => {
		const eClass = ['bannersListItem has_selection'];

		if (isDisabled)
			eClass.push('disabled');

		if (selectedZone && selectedZone.bannerId === banner.bannerId) {
			eClass.push('selected');
			if (refElement.current) {
				refElement.current.scrollIntoView(false); // TODO: to test
			}
		}

		setElementClass(eClass);

	}, [isDisabled, selectedZone]);


	const selectBanner = (event, banner) => {
		event.stopPropagation();
		if (!selectedZone || isDisabled) return;
		publish('evt/businessoperations/bannerSelected', banner);
	};

	const computeStateRegardingSelectedZone = () => {
		if (selectedZone && selectedZone.extensions) {
			if (selectedZone.bannerType === 'Slider' || selectedZone.bannerType === 'Carousel') {
				setTitle('Warning a slider or carousel cannot contain a video');
				setIsDisabled(true);
				return;
			} else if (selectedZone.bannerType === 'Image') {
				setTitle('Video is not availble for image zone');
				setIsDisabled(true);
				return;
			}
			else {
				setTitle('');
			}

			setIsDisabled(false);
		} else {
			setTitle('Select a zone first');
			setIsDisabled(true);
		}

	};


	return (
		<div>
			<ReactTooltip id={ttId} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
			<div ref={refElement}
				className={elementClass.join(' ')}
				onClick={(e) => selectBanner(e, banner)}
				data-for={ttId}
				data-tip={title}>
				<div className="bannersListItemImg" >
					<img src={banner.imagePath} width={banner.metaData.width} />

					{!isDisabled && selectedZone &&
						<>
							{
								<span className="bannersListItemIcon">
									<i className="fa fa-plus"></i>
								</span>
							}
						</>
					}
				</div>

				<div className="bannersListItemName" title={banner.metaData.title && banner.metaData.title}>
					<div className="bannersListItemFloatText">
						{banner.metaData.title && banner.metaData.title}
					</div>
				</div>
			</div>
		</div>
	);
}
