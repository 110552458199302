import React, { useState, useEffect } from 'react'
import listConnexionType from './listConnexionType';
import listFeedType from './listFeedType';
import './CreationFeedContainer.css'
import SelectCustom from '../../../../Components/SelectCustom'
import InputCustom from './../../../../Components/InputCustom';
import useInputValidation from '../../../../Hooks/useInputVal'

export default function CreationFeedContainer({ newProductFeed, setNewProductFeed, accessValue, setAccessValue, arrayOfErrors, setArrayOfErrors, isConfigurationSourceMode, isFirstRender, errorsWhenParseCsv }) {

    const [isChoosentSource, setIsChoosentSource] = useState(false)
    const [errorCsv, setErrorCsv] = useState(false)
    const { errorsMessages } = useInputValidation()
    useEffect(() => {
        setErrorCsv(false)
    }, [])
    useEffect(() => {
        if (errorsWhenParseCsv) {
            errorsWhenParseCsv.forEach(elem => {
                if (elem.errorType === "CannotGuessSourceStructure") {
                    setErrorCsv(true)
                }
            })
        }
    }, [errorsWhenParseCsv])

    const selectChangeAccess = (e) => {
        const newSelectValue = e.target.value
        let myStateToChange = { ...newProductFeed }
        myStateToChange.source = newSelectValue
        const newValues = listConnexionType.filter(x => x.value == newSelectValue)[0].accessVal
        setAccessValue(newValues)
        setNewProductFeed(myStateToChange)
    }
    const handleChangeAccess = (e) => {
        const newSelectValue = e.target.value
        let myStateToChange = { ...accessValue }
        myStateToChange[e.target.name] = newSelectValue
        setAccessValue(myStateToChange)
        setArrayOfErrors([])
    }
    const handleChangeType = (e) => {
        const newSelectValue = e.target.value
        let myStateToChange = { ...newProductFeed }
        myStateToChange[e.target.name] = newSelectValue
        setNewProductFeed(myStateToChange)
    }

    useEffect(() => {
        if (newProductFeed.source && newProductFeed.source !== "") {
            const choosenSourse = listConnexionType.filter(elem => elem.value == newProductFeed.source)[0].chooseFeed
            if (choosenSourse) {
                setIsChoosentSource(true)
            } else {
                setIsChoosentSource(false)
            }
        }
    }, [newProductFeed])

    function cleanObject() {
        if (isConfigurationSourceMode) {
            let newValue = {}
            for (const key in accessValue) {
                if (errorCsv === true) {
                    if (accessValue[key] !== null && accessValue[key] !== undefined) {
                        newValue[key] = accessValue[key]
                    }
                } else {
                    if (accessValue[key] !== null && accessValue[key] !== undefined) {
                        newValue[key] = accessValue[key]
                    }
                    delete newValue["root"]
                    delete newValue["itemNode"]
                    delete newValue["namespaceDeclaration"]
                }
            }
            setAccessValue(newValue)
        }
    }
    useEffect(() => {
        cleanObject()
    }, [isFirstRender])

    function showErrors(elem) {
        if (arrayOfErrors.includes("empty") && accessValue[elem] == undefined ||
            arrayOfErrors.includes("empty") && accessValue[elem].length == 0) {
            for (const error in errorsMessages) {
                if (error == "empty") {
                    return errorsMessages[error]
                }
            }
        }
        else if (arrayOfErrors.includes(elem)) {
            for (const error in errorsMessages) {
                if (error == elem) {
                    return errorsMessages[error]
                }
            }
        }
        return
    }

    const arrayOfLabels = [
        { value: "url", label: "Url", type: "text" },
        { value: "separator", label: "Separator", type: "text" },
        { value: "root", label: "Root", type: "text" },
        { value: "itemNode", label: "Item Node", type: "text" },
        { value: "namespaceDeclaration", label: "Name space declaration", type: "text" },
        { value: "login", label: "Login", type: "text" },
        { value: "passWord", label: "Password", type: "password" },
        { value: "languageId", label: "Language id", type: "text" },
        { value: "apiKey", label: "Api Key", type: "number" },
    ]
    return (
        <div className="creation-feed-container">

            <>
                <div>
                    <SelectCustom
                        optionsList={listConnexionType}
                        name="source"
                        onChangeFunction={(e) => selectChangeAccess(e)}
                        defaultValueDisabled={{
                            value: "choose",
                            label: "Choose a source",
                            isDisabled: true
                        }}
                        label="Connexion type"
                        value={newProductFeed && newProductFeed.source}
                    />
                </div>
                {
                    isChoosentSource &&
                    <>
                        <div>
                            <SelectCustom
                                optionsList={listFeedType}
                                name="type"
                                onChangeFunction={(e) => handleChangeType(e)}
                                defaultValueDisabled={{
                                    value: "choose",
                                    label: "Choose a feed type",
                                    isDisabled: true
                                }}
                                label="Feed Type"
                                value={newProductFeed && newProductFeed.type}
                            />
                        </div>
                        {
                            newProductFeed.source &&
                            <div>
                                <form>
                                    <h3>Access</h3>
                                    {accessValue && Object.keys(accessValue).map((elem, i) =>
                                        <InputCustom
                                            key={i}
                                            label={arrayOfLabels.filter(el => el.value === elem)[0].label}
                                            value={accessValue[elem]}
                                            name={elem}
                                            onChange={(e) => handleChangeAccess(e)}
                                            type={arrayOfLabels.filter(el => el.value === elem)[0].type}
                                            placeholder={elem === "separator" ? "Please enter the separator" : arrayOfLabels.filter(el => el.value === elem)[0].label}
                                            isAnError={showErrors(elem)}
                                        />)}
                                </form>

                            </div>
                        }
                    </>
                }
                {
                    !isChoosentSource && newProductFeed.source == "Prestashop" &&
                    <div>
                        {accessValue && Object.keys(accessValue).map((elem, i) =>
                            <InputCustom
                                key={i}
                                label={arrayOfLabels.filter(el => el.value === elem)[0].label}
                                value={accessValue[elem]}
                                name={elem}
                                onChange={(e) => handleChangeAccess(e)}
                                type={arrayOfLabels.filter(el => el.value === elem)[0].type}
                                placeholder={arrayOfLabels.filter(el => el.value === elem)[0].label}
                                isAnError={showErrors(elem)}
                            />)}
                    </div>
                }

            </>

        </div>
    )
}
