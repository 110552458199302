import React, { useState, useEffect } from 'react';
import { CampaignsContext } from '../../context/index';
import { Trans, useTranslation } from 'react-i18next';
export default function PermissionsContainer() {

	const {
		campaign,
		handleChangePermissions
	} = CampaignsContext();
	
	const [permissions, setpermissions] = useState();

	const handleChangePermissionsCampaign = (type) => {
		if (type == 'CAMPAIGNS_CAN_VIEW') {
			const permissionTypeValue = !permissions[type];
			if (permissionTypeValue) {
				handleChangePermissions({ ...permissions, [type]: permissionTypeValue });
			} else {
				const newPermissions = {
					CAMPAIGNS_CAN_CREATE: false,
					CAMPAIGNS_CAN_DELETE: false,
					CAMPAIGNS_CAN_SUSPEND: false,
					CAMPAIGNS_CAN_VIEW: false,
				};
				handleChangePermissions(newPermissions);
			}
		} else {
			const permissionTypeValue = !permissions[type];
			handleChangePermissions({ ...permissions, [type]: permissionTypeValue });
		}
	};

	const [t, i18n] = useTranslation('common');	useEffect(() => {
		if (campaign) {
			const campaignPermissions = campaign.DefaultPermissions;
			setpermissions(campaignPermissions);
		}
	}, [campaign]);

	if (!campaign) {
		return (<></>);
	}

	return (
		<div>
			<article className="radio_box_inner" >
				<label className={permissions && permissions.CAMPAIGNS_CAN_VIEW ? 'radio_box checked no_bottom_margin' : 'radio_box no_bottom_margin'} >
					<input type="checkbox" name="" className="ng-pristine ng-untouched ng-valid ng-empty" onClick={() => handleChangePermissionsCampaign('CAMPAIGNS_CAN_VIEW')} />
					<span><i className="fa fa-check"></i></span>
					<i className="fas fa-eye"></i>
					<p>{t('actions.see')}</p>
				</label>
				<label className={permissions && permissions.CAMPAIGNS_CAN_VIEW ?
					permissions.CAMPAIGNS_CAN_CREATE ? 'radio_box checked no_bottom_margin' : 'radio_box no_bottom_margin' : 'radio_box disabled no_bottom_margin'} >
					<input type="checkbox" name="" className="ng-pristine ng-untouched ng-valid ng-empty"
						onClick={() => handleChangePermissionsCampaign('CAMPAIGNS_CAN_CREATE')}
					/>
					<span><i className="fa fa-check"></i></span>
					<i className="far fa-edit"></i>
					<p>{t('actions.edit')}</p>
				</label>
				<label className={permissions && permissions.CAMPAIGNS_CAN_VIEW ?
					permissions.CAMPAIGNS_CAN_SUSPEND ? 'radio_box checked no_bottom_margin' : 'radio_box no_bottom_margin' : 'radio_box disabled no_bottom_margin'} >
					<input type="checkbox" name="" className="ng-pristine ng-untouched ng-valid ng-empty"
						onClick={() => handleChangePermissionsCampaign('CAMPAIGNS_CAN_SUSPEND')}
					/>
					<span><i className="fa fa-check"></i></span>
					<i className="far fa-play-circle"></i>
					<p>{t('EditCampaignPermissions.play')}</p>
				</label>
				<label className={permissions && permissions.CAMPAIGNS_CAN_VIEW ?
					permissions.CAMPAIGNS_CAN_DELETE ? 'radio_box checked no_bottom_margin' : 'radio_box no_bottom_margin' : 'radio_box disabled no_bottom_margin'} >
					<input type="checkbox" name="" className="ng-pristine ng-untouched ng-valid ng-empty"
						onClick={() => handleChangePermissionsCampaign('CAMPAIGNS_CAN_DELETE')}
					/>
					<span><i className="fa fa-check"></i></span>
					<i className="fas fa-trash-alt"></i>
					<p>{t('actions.delete')}</p>
				</label>
			</article>
		</div>
	);
}
