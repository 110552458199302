import React from 'react';
import { useLocation } from 'react-router-dom';
import { Section } from '../../../Components/Section/Section';
import { Article } from '../../../Components/Article/Article';
import EmptyState from '../../../Components/EmptyState';
import { useTranslation } from 'react-i18next';

export default function TrafficContainer() {

	const [t] = useTranslation('kpi');
	const location = useLocation();
	if(location.pathname !== '/Insight/segment'){
		return <></>;
	}

	return (
		<Section width='m'>
			<section className="section no_bottom_pad section_primary">
				<div className="h1">{t('segments.segments')}</div>
			</section>
			<section className="section">
				<Article>
					<EmptyState
						title={t('segments.segmentsDashboard')}
						text={t('segments.segmentsDashboardSoon')}
						imageUrl="/Assets/empty_analytics_coming.svg"
						textSize="xl"
						verticalSize="l"
						width="l"
					/>
				</Article>
			</section>
		</Section>
	);
}
