import React, { useState, useEffect, Fragment } from 'react';
import { react2angular } from 'react2angular';
import SystemServices from '../../../Services/SystemServices';
import ToolingServices from '../../../Services/ToolingServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SelectAccParamCreationModeContainer from './SelectAccParamCreationModeContainer';
import EditConfigEmailRemarketing from '../EmailRemarketing/EditConfigEmailRemarketing';
import Btn from '../../../Components/Btn';
import Panel from './../../../Components/Panel';

export default function EmailRemarketingConfiguration(props) {
	const systemServices = new SystemServices(props.$rootScope);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const campaignId = props.idOfCampaign;
	const toolingServices = new ToolingServices(props.$http, props.$routeParams);
	const [selectToMap, setSelectToMap] = useState();
	const [hasProperties, setHasProperties] = useState(false);
	const [AccountCurrentTab, setAccountCurrentTab] = useState();
	const [isCreationMode, setIsCreationMode] = useState(false);
	const [isReloading, setIsRealoading] = useState(true);
	const [accountConfigurationList, setAccountConfigurationList] = useState([]);
	const [clickOnConfiguration, setClickOnConfiguration] = useState();

	const getParameterConfigurationByOperation = (accountId, campaignId, callbackSuccess, callbackError) => toolingServices.getParameterConfigurationByOperation(accountId, campaignId, success => { callbackSuccess(success); }, error => { callbackError(error); });

	const getConfigEmailRemarketingById = (accountId, campaignId, callbackSuccess, callbackError) => toolingServices.getConfigEmailRemarketingById(accountId, campaignId, success => { callbackSuccess(success); }, error => { callbackError(error); });

	const postConfigEmailRemarketingById = (accountId, campaignId, provider, callbackSuccess, callbackError) => toolingServices.postConfigEmailRemarketingById(accountId, campaignId, provider, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const putConfigEmailRemarketing = (accountId, campaignId, provider, callbackSuccess, callbackError) => toolingServices.putConfigEmailRemarketing(accountId, campaignId, provider, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const postEmailRemarketingTest = (accountId, campaignId, recipient, callbackSuccess, callbackError) => toolingServices.postEmailRemarketingTest(accountId, campaignId, recipient, success => { systemServices.showSuccess('An email has been sent'); callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	useEffect(() => {
		if (!props.idOfCampaign) {
			return;
		}
		if (props.idOfCampaign !== '') {
			getParameterConfigurationByOperation(accountId, props.idOfCampaign, data => {
				setAccountConfigurationList(data);

				setIsRealoading(false);
			}, error => {
				console.log('error', error);
				setIsRealoading(false);
			});
		}
	}, [props]);
	const getTheParameterConfigurationByOperation = (e) => {
		getParameterConfigurationByOperation(accountId, props.idOfCampaign, data => {
			setAccountConfigurationList(data);
			setClickOnConfiguration(e);
		}, error => {
			console.log('error', error);
		});
	};
	const checkIfHasExistingConfig = () => {
		if (accountConfigurationList.hasEmailRemarketing) {
			setAccountCurrentTab(1);
		}
	};
	useEffect(() => {
		if (accountConfigurationList) {
			if (!clickOnConfiguration) {
				checkIfHasExistingConfig();
			} else {
				setAccountCurrentTab(clickOnConfiguration);
			}
			const selectToMapArr = [];
			for (const key in accountConfigurationList) {
				if (accountConfigurationList[key] == true) {
					setHasProperties(true);
				} else {
					selectToMapArr.push(key);
				}
				setSelectToMap(selectToMapArr);
			}
		}
	}, [accountConfigurationList]);
	const closeTheModal = () => {
		props.onLoad();
		setAccountConfigurationList([]);
		setClickOnConfiguration();
		setAccountCurrentTab();
		setHasProperties();
		setSelectToMap();
		setHasProperties(false);
		setIsCreationMode(false);
		setIsRealoading(true);

	};
	const ChangeTheNavigationSelection = (e) => {
		setAccountCurrentTab(e);
	};

	return (
		<Panel
			isOpen={props.isOppen}
			onClose={(e) => closeTheModal()}
			width="700"
		>
			<div className="section l flex_item_fix">
				<div className="page_title">Email Remarketing configuration</div>
				<div className="page_sub_title grey_medium">{props.campaignName}</div>
			</div>
			<div className="flex_item_full pos_rel vscroll">
				{!isReloading &&
					<Fragment>
						{!hasProperties && AccountCurrentTab == undefined &&
							<div className="empty_page_flex vscroll">
								<div className="empty_page_sub_title grey_medium">This campaign does not have any configured feature yet</div>
								<div className="empty_page_btn">
									<Btn
										message="Configure new feature"
										size="l"
										icon="fas fa-plus"
										onClickFunction={(e) => setAccountCurrentTab(0)}
									/>
								</div>
							</div>
						}
						{AccountCurrentTab === 0 &&
							<SelectAccParamCreationModeContainer
								listOfConfiguRationAccount={selectToMap}
								setAccountCurrentTab={(e) => setAccountCurrentTab(e)}
								checkIfHasExistingConfig={() => checkIfHasExistingConfig()}
								setIsCreationMode={(e) => setIsCreationMode(e)}
							/>
						}
						{AccountCurrentTab === 1 &&
							<EditConfigEmailRemarketing
								isCreationMode={isCreationMode}
								setIsCreationMode={(e) => setIsCreationMode(e)}
								setAccountCurrentTab={(e) => setAccountCurrentTab(e)}
								postConfigEmailRemarketingById={postConfigEmailRemarketingById}
								accountId={accountId}
								campaignId={campaignId}
								getConfigEmailRemarketingById={getConfigEmailRemarketingById}
								putConfigEmailRemarketing={putConfigEmailRemarketing}
								postEmailRemarketingTest={postEmailRemarketingTest}
								getParameterConfigurationByOperation={(e) => getTheParameterConfigurationByOperation(e)}
							/>
						}
					</Fragment>
				}
				{
					isReloading &&
					<div className="spinner_overlay">
						<div className="spinner">
							<div className="rect1"></div>
							<div className="rect2"></div>
							<div className="rect3"></div>
							<div className="rect4"></div>
							<div className="rect5"></div>
						</div>
					</div>
				}
			</div>
		</Panel>
	);
}
angular.module('beyableSaasApp.Dashboard')
	.component('modalEmailRemarketing', react2angular(EmailRemarketingConfiguration, ['onLoad', 'idOfCampaign', 'isOppen', 'campaignName'], ['$http', '$rootScope', '$routeParams']));
