export const getCSS = (data) => {


	if (!data) return '';
	if (!data.colors) return '';

	const css = `
:host {
	/* Reset all CSS properties */
	all: initial !important;
	font-family: inherit !important;
}
.by_lib_survey,
.by_lib_survey *,
.by_lib_survey *::after,
.by_lib_survey *::before {
	box-sizing: border-box;
}
.by_lib_survey {
	--color-primary: ${data.colors.button.backgroundColor};
	--color-primary-light: ${data.colors.button.backgroundColor}20;

	--color-btn-primary-bg: var(--color-primary);
	--color-btn-primary-text: ${data.colors.button.textColor};
	--color-btn-primary-border: ${data.colors.button.borderColor};

	--color-btn-secondary-bg: ${data.colors.skip.backgroundColor};
	--color-btn-secondary-text: ${data.colors.skip.textColor};
	--color-btn-secondary-border: ${data.colors.skip.borderColor};

	--color-wrapper-bg: ${data.colors.global.backgroundColor};
	--color-wrapper-text: ${data.colors.global.textColor};
	--color-wrapper-error: ${data.colors.global.errorColor};

	--color-field-border: ${data.colors.global.textColor}66;
	--color-field-border-hover: ${data.colors.global.textColor}88;
	--color-field-placeholder: ${data.colors.global.textColor}88;
	--color-field-border-light: ${data.colors.global.textColor}1A;

	--main-font-size: 0.875em;
}
.by_lib_survey {
	background-color: var(--color-wrapper-bg);
	color: var(--color-wrapper-text);
	font-size: 16px;
	font-family: -apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
}
.by_lib_survey_footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: .5em;
	margin-top: 1.125em;
	text-align: right;
}

.by_lib_survey_question {
	display: block;
	font-weight: 700;
	font-size: 1em;
	margin-bottom: 1em;
}

/* Text fields */
.by_lib_survey_textfield {
	width: 100%;
	vertical-align: top;
	background: var(--color-wrapper-bg);
	border: solid 1px var(--color-field-border);
	border-radius: 5px;
	color: var(--color-wrapper-text);
	transition: all 200ms ease-in-out;
	padding: 8px 12px;
	font-size: var(--main-font-size);
	font-weight: 400;
	line-height: 1.355em;
	outline: none;
	font-family: inherit;
	resize: vertical;
}
.by_lib_survey_textfield:hover {
	border: solid 1px var(--color-field-border-hover);
}
.by_lib_survey_textfield:focus {
	border: solid 1px var(--color-primary);
	box-shadow: inset 0 0 0 1px var(--color-primary), 0 0 0 3px var(--color-primary-light);
	background-color: var(--color-wrapper-bg);
}

/* Buttons */
.by_lib_survey_button {
	border: solid 1px;
	outline: none;
	font-weight: 500;
	line-height: 1.355em;
	padding: 8px 16px;
	font-size: var(--main-font-size);
	border-radius: 5px;
	cursor: pointer;
}
.by_lib_survey_button[disabled] {
	cursor: not-allowed;
	opacity: .4;
}
.by_lib_survey_button_primary {
	background-color: var(--color-btn-primary-bg);
	color: var(--color-btn-primary-text);
	${data.colors.button.border
		? 'border-color: var(--color-btn-primary-border);'
		: 'border-color: var(--color-btn-primary-bg);'}
}
.by_lib_survey_button_secondary {
	background-color: var(--color-btn-secondary-bg);
	color: var(--color-btn-secondary-text);
	${data.colors.skip.border
		? 'border-color: var(--color-btn-secondary-border);'
		: 'border-color: var(--color-btn-secondary-bg);'}
}

/* Choice (checkbox and radio) */
.by_lib_survey_choice{
    margin: .5em 0;
}
.by_lib_survey_choice .by_lib_survey_choice_label{
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;
	vertical-align: top;
	padding: 1em;
	border: solid 1px var(--color-field-border-light);
    border-radius: .5em;
}
.by_lib_survey_choice .by_lib_survey_choice_field + .by_lib_survey_choice_button{
    display: none;
}
.by_lib_survey_choice .by_lib_survey_choice_field:checked,
.by_lib_survey_choice .by_lib_survey_choice_field:not(:checked){
    position: absolute;
    visibility: hidden;
}
.by_lib_survey_choice .by_lib_survey_choice_field:checked       + .by_lib_survey_choice_button,
.by_lib_survey_choice .by_lib_survey_choice_field:not(:checked) + .by_lib_survey_choice_button{
    display: flex;
    cursor: pointer;
}
.by_lib_survey_choice .by_lib_survey_choice_field + .by_lib_survey_choice_button{
    width: 16px;
    height: 16px;
	overflow: hidden;
    border: 1px solid var(--color-field-border);
    background: var(--color-wrapper-bg);
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    transition: all 200ms;
}
.by_lib_survey_choice .by_lib_survey_choice_field + .by_lib_survey_choice_button_checkbox{
    border-radius: 3px;
}
.by_lib_survey_choice .by_lib_survey_choice_field + .by_lib_survey_choice_button_radio{
    border-radius: 16px;
}
.by_lib_survey_choice .by_lib_survey_choice_field:checked + .by_lib_survey_choice_button{
    background: var(--color-primary);
    border-width: 0;
}
.by_lib_survey_choice .by_lib_survey_choice_field:checked + .by_lib_survey_choice_button::before{
    content: "";
}
.by_lib_survey_choice .by_lib_survey_choice_field:checked + .by_lib_survey_choice_button_checkbox::before{
	width: 9px;
    height: 5px;
    border-style: solid;
    border-width: 0 0 2px 2px;
    border-color: var(--color-btn-primary-text);
    transform: translateY(-1px) rotateZ(-45deg);
    transform-origin: center;
}
.by_lib_survey_choice .by_lib_survey_choice_field:checked + .by_lib_survey_choice_button_radio::before{
	width: 4px;
	height: 4px;
	border-radius: 4px;
	background: var(--color-btn-primary-text);
}
.by_lib_survey_choice .by_lib_survey_choice_text{
    flex: 1 1 auto;
    align-self: center;
    font-size: var(--main-font-size);
    margin-left: 10px;
    line-height: 1.1428em;
    user-select: none;
}

/* Rating */
.by_lib_survey_rating {
	display: flex;
	align-items: center;
	gap: 4px
}
.by_lib_survey_rating_choice {
	position: relative;
	flex: 1 1 0;
}
.by_lib_survey_rating_choice_label {
	display: block;
	text-align: center;
	font-size: var(--main-font-size);
	line-height: 1.3;
	padding: 1rem 0;
	background: var(--color-field-border-light);
	border-radius: 4px;
	cursor: pointer;
	transition: all 200ms;
}
.by_lib_survey_rating_choice_label.selected {
	background-color: var(--color-primary);
	color: var(--color-btn-primary-text);
}
.by_lib_survey_rating_choice_field {
	position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
}
.by_lib_survey_rating_legend {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 0.8125em;
	margin-top: 0.4375em;
}

/* Placeholder */
.by_lib_survey_textfield::-webkit-input-placeholder {
	color: var(--color-field-placeholder);
}
.by_lib_survey_textfield::-moz-placeholder {
	color: var(--color-field-placeholder);
}
.by_lib_survey_textfield:-ms-input-placeholder {
	color: var(--color-field-placeholder);
}
.by_lib_survey_textfield:-moz-placeholder {
	color: var(--color-field-placeholder);
}

/* Error */
.by_lib_survey_error {
	font-size: var(--main-font-size);
	margin-top: 10px;
	color: var(--color-wrapper-error);
}

/* End */
.by_lib_survey_end {
	line-height: 1.3;
}
.by_lib_survey_end p {
	margin: 0;
}
    `;
	return css;
};
