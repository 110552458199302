import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '../../Components/Avatar';
import Btn from '../../Components/Btn';
import PopinConfirm from '../../Components/PopinConfirm';
import UserEditModal from './UserEditModal';
import formatMoment from '../../Constants/FormatsMoments';
import ReactTooltip from 'react-tooltip';
import { useAdminContext } from './userAdministrationContext'
const i18nL= localStorage.getItem('i18nL');
import {useTranslation} from 'react-i18next';
export default function UserLine({user}) {
	const sure = i18nL && i18nL.includes('fr') ? 'Êtes-vous sûr de vouloir supprimer l\'utilisateur' : 'Are you sure you want to delete the user';
	const Cancel = i18nL && i18nL.includes('fr') ? 'Annuler' : 'Cancel';
	const Confirm = i18nL && i18nL.includes('fr') ? 'Confirmer' : 'Confirm';
	const {
		roleList,
		canEditUser,
		handleDeleteUser,
		shouldDisplayOrganizationInfo
	} = useAdminContext();
	const [t, i18n] = useTranslation('common');
	const { formatDateToLocal } = formatMoment;
	const [editionIsOpen, setEditionIsOpen] = useState(false);
	const [userDisplayRoles, setUserDisplayRoles] = useState([]);
	const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);
	const editionOnClose = function () {
		setEditionIsOpen(false);
	};

	let timeFromlastConnection;
	let lastConnectionDisplayDate;
	const lastConnectionDateLocal = formatDateToLocal(user.lastConnectionDateUtc);
	if (lastConnectionDateLocal.isValid()) {
		timeFromlastConnection = lastConnectionDateLocal.fromNow();
		lastConnectionDisplayDate = lastConnectionDateLocal.format('DD/MM/YYYY - HH:MM');
	}

	useEffect(() => {
		if (roleList) {
			const tmpRoles = user.roles.map(role => {
				const hasRoleExistant = roleList.find(el => el.value === role);
				if (hasRoleExistant) {
					return hasRoleExistant.label
				} else {
					return ""
				}
			});
			setUserDisplayRoles(tmpRoles);
		}
	}, [roleList, user]);

	const showOrgaCol = shouldDisplayOrganizationInfo;
	const ttId = uuidv4();
	return (
		<>
			<ReactTooltip id={ttId} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />

			<div className={showOrgaCol ? "user_grid_row user_grid_body_row has_orga" : "user_grid_row user_grid_body_row"}>
				<div className="user_grid_col" onClick={canEditUser ? (e) => setEditionIsOpen(true) : null}>
					<div className="flex">
						<div className="flex_item_fix">
							<Avatar
								id={user.id}
								name={user.displayName}
								size="l"
							/>
						</div>
						<div className="flex_item_full ml_15">
							<div className="user_grid_name">{user.displayName}</div>
							<div className="user_grid_email grey_2">{user.email}</div>
						</div>
					</div>
				</div>
				<div className="user_grid_col" onClick={canEditUser ? (e) => setEditionIsOpen(true) : null}>
					{userDisplayRoles.length ?
						<span className="user_role_list">
							{userDisplayRoles.map((role, i) => {
								if (role) return (
									<span key={i}>{role}</span>
								)
							})}
						</span>
						:
						<span className="grey_3 s_13">None</span>
					}
				</div>
				{showOrgaCol && 
					<>
						<div className="user_grid_col" onClick={canEditUser ? (e) => setEditionIsOpen(true) : null}>
							<span>
								{user.memberId ? <span><i className="fas fa-check-circle grey_3"></i></span> : <></>}
							</span>
						</div>
						<div className="user_grid_col" onClick={canEditUser ? (e) => setEditionIsOpen(true) : null}>
							<span>
								{user.isBoundToToolingAuth0 ? <span><i className="fas fa-check-circle grey_3"></i></span> : <></>}
							</span>
						</div>
					</>
				}
				<div className="user_grid_col s_14" onClick={canEditUser ? (e) => setEditionIsOpen(true) : null}>
					{timeFromlastConnection && lastConnectionDisplayDate ?
						<>
							<span data-for={ttId} data-tip={lastConnectionDisplayDate}>
								{timeFromlastConnection}
							</span>
						</>
						:
						<span className="grey_3">Never</span>
					}
				</div>
				<div className="user_grid_col">
					{canEditUser ? <>
						<a className="icon_btn"
							onClick={(e) => { setEditionIsOpen(true) }}
							data-for={ttId}
							data-tip="Edit user">
							<i className="fas fa-pen s_18"></i>
						</a>
						<a className="icon_btn"
							onClick={(e) => { setDeleteConfirmIsOpen(true) }}
							data-for={ttId}
							data-tip="Delete user">
							<i className="fas fa-trash-alt s_18"></i>
						</a> </>
						:
						<>
							<a className="icon_btn" style={{ color: '#e3e3e3', cursor: 'not-allowed' }}
								onClick={null}
								data-for={ttId}
								data-tip="Edit user">
								<i className="fas fa-pen s_18"></i>
							</a>
							<a className="icon_btn" style={{ color: '#e3e3e3', cursor: 'not-allowed' }}
								onClick={null}
								data-for={ttId}
								data-tip="Delete user">
								<i className="fas fa-trash-alt s_18"></i>
							</a> </>
					}

					{/*<Dropdown
						buttonText="Actions"
						icon="fa fa-chevron-down"
						isOpen={dropdownIsOpen}
						setIsOpen={() => setDropdownIsOpen(true)}
						onHide={() => setDropdownIsOpen(false)}
					>
						<div className="user-information-list-actions">
							<ul className="listbox">
								<li><a className="listbox_item" onClick={(e) => { setEditionIsOpen(true); setDropdownIsOpen(false) }}>Edit</a></li>
								<li><a className="listbox_item" onClick={(e) => { setDeleteConfirmIsOpen(true); setDropdownIsOpen(false) }}>Delete</a></li>
							</ul>
						</div>
					</Dropdown>*/}

				</div>
			</div>

			{editionIsOpen && // testing editionIsOpen is important to reinitialize modal component
				<UserEditModal
					user={user}
					isOpen={editionIsOpen}
					onClose={editionOnClose}
				/>
			}

			{deleteConfirmIsOpen &&
				<PopinConfirm
					cancel={(e) => setDeleteConfirmIsOpen(false)}
					title={` ${sure} ${user && user.displayName}`}
				>
					<Btn
						message={Confirm}
						size="l"
						color="primary"
						onClickFunction={(e) => { handleDeleteUser(user); }}
					/>
					<Btn
						onClickFunction={() => { setDeleteConfirmIsOpen(false); }}
						message={Cancel}
						color="secondary"
						size="l"
						style="outline"
					/>
				</PopinConfirm>
			}
		</>
	);
};
