import React from 'react'

function ModalFooter({
    children,
    className = '',
    align = 'right',
    primaryAction,
    secondaryAction,
    hasBorder = false,
}) {

    let cssClass = ['modal_footer', 'flex'];

    // className
    if (className) {
        cssClass = cssClass.concat(className);
    }

    // Border
    if (hasBorder) {
        cssClass.push('has_border');
    }

    return (
        <div className={cssClass.join(' ')}>
            {align == 'left' && (primaryAction || secondaryAction) &&
                <div className='flex_item_fix mr_20'>{primaryAction}{secondaryAction}</div>
            }
            <div className='flex_item_full'>{children}</div>
            {align == 'right' && (primaryAction || secondaryAction) &&
                <div className='flex_item_fix ml_20'>{secondaryAction}{primaryAction}</div>
            }
        </div>
    )
}
export default ModalFooter;
