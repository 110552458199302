import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { listTz } from 'timezone-select-js';
import moment from 'moment'
import 'moment-timezone';

export default function TimezoneSelect({
	timezoneValue,
	handleTimeZoneSelected
}) {

	// Timezone 
	const getTimeZoneByValue = (list, value) => {
		var lgt = list.length;
		for (var i = 0; i < lgt; i++) {
			var tz = list[i];
			if (tz.value === value) {
				return tz;
			}
		}
		return {};
	}

	const timeZoneList = listTz()
	const getTimeZoneByValueFromList = (value) => getTimeZoneByValue(timeZoneList, value)
	const defaultValue = moment.tz.guess() || "Europe/Paris"
	
	const [timeZoneSelected, setTimeZoneSelected] = useState(getTimeZoneByValueFromList(defaultValue));

	const localHandleTimeZoneChanged = (e) => {
		setTimeZoneSelected(e)
		handleTimeZoneSelected(e.value)
	}

	useEffect(() => {
		const value = timezoneValue || defaultValue;
		setTimeZoneSelected(getTimeZoneByValueFromList(value))
	}, [timezoneValue])

	return (
		<>
			<Select
				options={timeZoneList}
				defaultValue={timeZoneSelected}
				value={timeZoneSelected}
				onChange={(e) => localHandleTimeZoneChanged(e)}
				menuPlacement="auto"
				className="react-select"
				classNamePrefix="react-select"
			/>
		</>
	)
}