import React from 'react';
import CalendarOnSiteContainer from "./CalendarContainerOnSite";
import AccountParametersContainer from '../../../Tooling/AccountConfigurations/AccountParametersContainer';
import '../../../BusinessOperations/Planning/PlanningContainer.css';
import { onSiteContext } from '../context';


export default function OnSitePlanningContainer(props) {

  const {
    idOfCampaign,
    showConfiguration,
    closeShowConfiguration,
    $rootScope,
    $http,
    $routeParams,
    checkAccountData,
    dateRange,
    defaultView, 
  } = onSiteContext();


  let dateToCalculate;
  switch (defaultView) {
    case 'month':
      dateToCalculate = 28;
      break;
    case 'week':
      dateToCalculate = 6;
      break;
    default:
      dateToCalculate = 28;
  }

  return (
    <>
      {checkAccountData && (
        <>
          {showConfiguration && (
            <AccountParametersContainer
              props={{
                reactRender: true,
                idOfCampaign: idOfCampaign,
                $rootScope: $rootScope,
                $http: $http,
                $routeParams: $routeParams,
                onLoad: (e) => closeShowConfiguration(),
                isOppen: showConfiguration,
              }}
            />
          )}


          <section className="mt_20">
            {dateRange && (
              <CalendarOnSiteContainer
                dateToCalculate={dateToCalculate}
                dateRange={dateRange}
              />
            )}
          </section>
        </>
      )}
    </>
  );
}

