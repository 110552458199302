import React, { Fragment } from 'react';
import Btn from '../../../Components/Btn';
import { Trans, useTranslation } from 'react-i18next';
export default function SelectCreationModeContainer({ listOfConfiguRationAccount, setAccountCurrentTab, setIsCreationMode, checkIfHasExistingConfig }) {
	const [t, i18n] = useTranslation('common');
	const arrOfLabel = [
		{
			key : 'CONFIGALERT' ,
			label :'StockAlert'
		},
		{
			key : 'CONFIGEMR' ,
			label :'EmailMyBasket'
		},
		{
			key : 'CONFIGSCP' ,
			label :'PromoCodeSending'
		}
	];
	function hasKeyInArr(opt) {
		return arrOfLabel.find(e => e.key === opt.key) ? t('AccountParametersContainer.' +arrOfLabel.find(e => e.key === opt.key).label) : opt.label;
	}
	return (
		<Fragment>
			<div className="section section_grey l has_top_border">
				<div className="email_provider flex">
					<a className="icon_btn icon_btn_color"
						title="Back"
						onClick={() => { checkIfHasExistingConfig(); setIsCreationMode(false); }}>
						<i className="fas fa-arrow-left"></i>
					</a>
					<div className="email_provider_name">{t('AccountParametersContainer.whatKind')}</div>
				</div>
			</div>
			<div className="section l has_top_border">
				<div className="select_list_tooling_Container">
					<ul className="select_list_tooling">
						{
							listOfConfiguRationAccount.map((opt, i) =>
								<li key={i} onClick={(e) => { setAccountCurrentTab(opt.id); setIsCreationMode(true); }}>{hasKeyInArr(opt)}</li>
							)
						}
					</ul>
				</div>
			</div>
		</Fragment>
	);
}
