import HttpServices from './HttpServices';

export default class TenantAnalyticsServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.ANALYTICS_BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.ANALYTICS_BO_API_URL] missing';
		}
		else {
			this.ANALYTICS_BO_API_URL = `${window.BEYABLE_env.ANALYTICS_BO_API_URL}webanalytics/`;
		}
		this.accountId = accountId;
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}
	// Get the account Users 
	getTenants(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.ANALYTICS_BO_API_URL}tenantconfiguration/${this.accountId}`, callbackSuccess, callbackError);
	}
	deleteTenant( tenant, callbackSuccess, callbackError) {

		this.HttpServices.delete(`${this.ANALYTICS_BO_API_URL}tenantconfiguration/${this.accountId}/${tenant}`, callbackSuccess, callbackError);
	}
	putTenant(tenant, config, callbackSuccess, callbackError) {
		let tenantValue = null;
		if(tenant !== ''){
			tenantValue = tenant;
		}
		this.HttpServices.put(`${this.ANALYTICS_BO_API_URL}tenantconfiguration/${this.accountId}?tenant=${tenantValue}`, config, callbackSuccess, callbackError);
	}

}