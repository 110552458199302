import React, { useRef, useEffect, useState } from 'react';
import InputCustom from './InputCustom';
import { v4 as uuidv4 } from 'uuid';
import './RangeSlider.css';

export default function Range({
	value,
	onChange,
	disabled = false,
	readonly = false,
	name,
	min = 0,
	max = 100,
	stopMin,
	stopMax,
	legendList = [],
	step = 1,
	label = '',
	labelClassName = '',
	description = '',
	hasInput = true,
	inputUnit = '',
	reverse = false
}) {

	const inputEl = useRef(null);
	const inputId = uuidv4();
	const labelClass = ['form_block_label'];
	const blockClass = ['form_block'];
	const rangeClass = ['range_slider'];
	const [inputValue, setInputValue] = useState(value);

	if (labelClassName) {
		labelClass.push(labelClassName);
	}

	if (reverse) {
		rangeClass.push('reverse');
	}

	const handleChange = (e) => {
		let v = e.target.value;
		let finalMin = (typeof stopMin !== 'undefined') ? stopMin : min;
		let finalMax = (typeof stopMax !== 'undefined') ? stopMax : max;
		
		if (finalMin > v) {
			e.target.value = finalMin;
		}

		if (finalMax < v) {
			e.target.value = finalMax;
		}

		if (typeof onChange === 'function') {
			onChange(e);
		}
	};

	const initStyle = (e) => {
		if (!inputEl.current) return;

		const el = inputEl.current;
		el.style.setProperty('--value', value);
		el.style.setProperty('--min', min == '' ? '0' : min);
		el.style.setProperty('--max', max == '' ? '100' : max);
	};

	const updateStyle = (e) => {
		if (!inputEl.current) return;

		const el = inputEl.current;
		el.style.setProperty('--value', el.value);
	};

	useEffect(() => {
		initStyle();
	}, []);

	useEffect(() => {
		updateStyle();
	}, [value, inputValue]);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const range = (
		<>
			<div className={'flex range_slider_wrapper'}>
				<div className="flex_item_full">
					<input
						id={inputId}
						ref={inputEl}
						type="range"
						name={name}
						min={min}
						max={max}
						step={step}
						onChange={(e) => handleChange(e)}
						value={value}
						disabled={disabled}
						readOnly={readonly}
						className={rangeClass.join(' ')}
					/>
					{(legendList.length > 0) &&
						<div className='flex flex_justify_between range_slider_bottom_desc'>
							{legendList.map((l, i) =>
								<div key={i}>{l}</div>
							)}
						</div>
					}
				</div>
				{hasInput &&
					<div className="flex_item_fix ml_10">
						<InputCustom
							disabled={disabled}
							type="number"
							value={inputValue}
							min={min}
							max={max}
							step={step}
							onChange={(e) => setInputValue(e.target.value)}
							onBlur={(e) => handleChange(e)}
							onEnter={(e) => handleChange(e)}
							unit={inputUnit}
							width="xxs"
						/>
					</div>
				}
			</div>
		</>
	);

	if (label) {
		return (
			<div className={blockClass.join(' ')}>
				<div className={labelClass.join(' ')}>
					<label htmlFor={inputId}>{label}</label>
				</div>
				{description &&
					<div className="form_block_description">{description}</div>
				}
				{range}
			</div>
		);
	}

	return range;
}
