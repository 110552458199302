import React, { useState, useEffect } from 'react';
import Modal from '../../../../Components/Modal';
import Btn from '../../../../Components/Btn';
import LabelSelector from '../../../Labels/LabelSelector';
import { onSiteContext } from '../context';
import { Trans, useTranslation } from 'react-i18next';
export default function EditScenarioLabelsCampaign({ labelService }) {
	const {
		systemServices,
		modalScenarioLabelsCampaignisOpen,
		closeTheModalOfScenarioLabelsCampaign,
		currentCampaign
	} = onSiteContext();
	const [t, i18n] = useTranslation('common');
	const subTitle = currentCampaign && currentCampaign.name;
	const objectId = currentCampaign && currentCampaign.id;

	const [isLoadingCampaign, setisLoadingCampaign] = useState(true);
	const [isLoading, setisLoading] = useState(true);
	const [accountLabels, setAccountLabels] = useState();
	const [objectLabelIds, setObjectLabelIds] = useState();
	const [refetch, setrefetch] = useState(false);

	const onSave = () => { closeTheModalOfScenarioLabelsCampaign(true); };
	const onClose = () => { closeTheModalOfScenarioLabelsCampaign(true); };

	useEffect(() => {
		labelService.getScenarioSpecialLabelsForObject(objectId, data => {
			setisLoadingCampaign(false);
			setObjectLabelIds(data.map(x => x.id));
		}, err => {
			setisLoadingCampaign(false);
			systemServices.showError(t('labels.Error'));
		});
	}, [currentCampaign]);

	useEffect(() => {
		labelService.getScenarioSpecialLabels(
			newData => {
				setisLoading(false);
				setAccountLabels(newData);
			}, err => {
				setisLoading(false);
				systemServices.showError(t('labels.Error'));
			});
	}, [refetch]);

	const handleSelectAnewLabelForCampaign = (label) => {
		if (objectLabelIds.length > 0 && !objectLabelIds.includes(label.id)) {
			return;
		}
		if (!objectLabelIds.includes(label.id)) {
			setObjectLabelIds([...objectLabelIds, label.id]);
			setisLoading(true);
			labelService.addScenarioSpecialLabelForObject(objectId, label.id,
				newData => {
					setrefetch();
					setisLoading(false);
				},
				err => {
					setisLoading(false);
					systemServices.showError(
						t('labels.errorAssociate'));
				});
		} else {
			const withoutId = objectLabelIds.filter(id => id !== label.id);
			setObjectLabelIds(withoutId);
			setisLoading(true);
			labelService.deleteScenarioSpecialLabelForObject(objectId, label.id,
				newData => {
					setrefetch();
					setisLoading(false);
				},
				err => {
					setisLoading(false);
					systemServices.showError(t('labels.errorDelete'));
				});
		}
	};
	return (
		<div>
			<Modal
				isOpen={modalScenarioLabelsCampaignisOpen}
				width="900"
				onClose={(e) => onClose()}
			>
				<div className="modal_header has_border">
					{t('labels.campaignScenarioLabels')}
					<div className="modal_header_desc">{subTitle}</div>
				</div>
				<div className="modal_body">
					<LabelSelector
						selectableLabels={accountLabels || []}
						selectedLabelIds={objectLabelIds || []}
						labelSelected={l => handleSelectAnewLabelForCampaign(l)}
						nbColumn={3}
					/>
					{!isLoading && accountLabels && accountLabels.length == 0 && <div className="popin_labels_empty" >
						<i className="fas fa-tag"></i>
						<div>{t('labels.noLabelYet')}</div>
					</div>}

				</div>
				<div className="modal_footer modal_footer_right has_border">
					<Btn
						message={t('labels.Done')}
						style="outline"
						color="secondary"
						onClick={(e) => onSave()}
					/>
				</div>

				{isLoading || isLoadingCampaign &&
					<div className="mask">
						<div className="wheel"></div>
					</div>
				}
			</Modal>
		</div>
	);
}
