import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Icon from '../Components/Icon';

export const findIconByName = (name) => {
	return iconList.find(i => i.name === name);
};

export const createIconString = (icon, className) => {
	const newIcon = <Icon name={icon.name} className={className}>{icon.jsx}</Icon>;
	return ReactDOMServer.renderToStaticMarkup(newIcon);
};

export function getFinalIconValue(p) {
	const value = p.Value;
	let newValue = value;

	if (!value) {
		newValue = createIconString(iconList[0], p.Editor.ClassName);
	}

	if (value && !value.includes('<svg')) {
		const tmpIcon = findIconByName(value);
		if (tmpIcon) {
			newValue = createIconString(tmpIcon, p.Editor.ClassName);
		} else {
			newValue = createIconString(iconList[0], p.Editor.ClassName);
		}
	}

	return newValue;
}

export const iconList = [
	{
		name: 'smile',
		jsx: (
			<>
				<path fillRule="evenodd" clipRule="evenodd" d="M10 3.25C6.27208 3.25 3.25 6.27208 3.25 10C3.25 13.7279 6.27208 16.75 10 16.75C13.7279 16.75 16.75 13.7279 16.75 10C16.75 6.27208 13.7279 3.25 10 3.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="currentColor"/>
				<path d="M7.1875 9.375C7.70527 9.375 8.125 8.95527 8.125 8.4375C8.125 7.91973 7.70527 7.5 7.1875 7.5C6.66973 7.5 6.25 7.91973 6.25 8.4375C6.25 8.95527 6.66973 9.375 7.1875 9.375Z" fill="currentColor"/>
				<path d="M12.8125 9.375C13.3303 9.375 13.75 8.95527 13.75 8.4375C13.75 7.91973 13.3303 7.5 12.8125 7.5C12.2947 7.5 11.875 7.91973 11.875 8.4375C11.875 8.95527 12.2947 9.375 12.8125 9.375Z" fill="currentColor"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M6.49949 11.2258C6.85805 11.0184 7.31684 11.1409 7.52423 11.4995C8.05923 12.4245 8.91824 13 10 13C11.0818 13 11.9408 12.4245 12.4758 11.4995C12.6832 11.1409 13.142 11.0184 13.5005 11.2258C13.8591 11.4332 13.9816 11.892 13.7742 12.2505C13.0124 13.5677 11.6948 14.5 10 14.5C8.3052 14.5 6.98765 13.5677 6.22578 12.2505C6.01839 11.892 6.14094 11.4332 6.49949 11.2258Z" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'smile2',
		jsx: (
			<>
				<path fillRule="evenodd" clipRule="evenodd" d="M10 3.25C6.27208 3.25 3.25 6.27208 3.25 10C3.25 13.7279 6.27208 16.75 10 16.75C13.7279 16.75 16.75 13.7279 16.75 10C16.75 6.27208 13.7279 3.25 10 3.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="currentColor"/>
				<path d="M7.1875 9.375C7.70527 9.375 8.125 8.95527 8.125 8.4375C8.125 7.91973 7.70527 7.5 7.1875 7.5C6.66973 7.5 6.25 7.91973 6.25 8.4375C6.25 8.95527 6.66973 9.375 7.1875 9.375Z" fill="currentColor"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M11.125 8.4375C11.125 8.02329 11.4608 7.6875 11.875 7.6875H13.75C14.1642 7.6875 14.5 8.02329 14.5 8.4375C14.5 8.85171 14.1642 9.1875 13.75 9.1875H11.875C11.4608 9.1875 11.125 8.85171 11.125 8.4375Z" fill="currentColor"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M6.49949 11.2258C6.85805 11.0184 7.31684 11.1409 7.52423 11.4995C8.05923 12.4245 8.91824 13 10 13C11.0818 13 11.9408 12.4245 12.4758 11.4995C12.6832 11.1409 13.142 11.0184 13.5005 11.2258C13.8591 11.4332 13.9816 11.892 13.7742 12.2505C13.0124 13.5677 11.6948 14.5 10 14.5C8.3052 14.5 6.98765 13.5677 6.22578 12.2505C6.01839 11.892 6.14094 11.4332 6.49949 11.2258Z" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'smile3',
		jsx: (
			<>
				<path fillRule="evenodd" clipRule="evenodd" d="M6.49949 11.2258C6.85805 11.0184 7.31684 11.1409 7.52423 11.4995C8.05923 12.4245 8.91824 13 10 13C11.0818 13 11.9408 12.4245 12.4758 11.4995C12.6832 11.1409 13.142 11.0184 13.5005 11.2258C13.8591 11.4332 13.9816 11.892 13.7742 12.2505C13.0124 13.5677 11.6948 14.5 10 14.5C8.3052 14.5 6.98765 13.5677 6.22578 12.2505C6.01839 11.892 6.14094 11.4332 6.49949 11.2258Z" fill="currentColor"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M13.4822 4.23019C12.1926 3.45474 10.6806 3.13301 9.18703 3.31622C7.69342 3.49943 6.30403 4.17704 5.24006 5.24118C4.17608 6.30532 3.49869 7.69482 3.31572 9.18846C3.13274 10.6821 3.45471 12.194 4.23036 13.4835C5.00601 14.773 6.19076 15.766 7.59598 16.3043C8.94446 16.8209 10.4216 16.8903 11.81 16.5051L16.505 11.8094C16.89 10.4209 16.8203 8.94377 16.3035 7.59536C15.765 6.19023 14.7718 5.00564 13.4822 4.23019ZM9.00441 1.82738C10.8303 1.60341 12.6787 1.99672 14.2552 2.94469C15.8317 3.89266 17.0458 5.34079 17.7042 7.05855C18.3625 8.7763 18.4273 10.6649 17.8882 12.4237C17.8522 12.5409 17.7881 12.6475 17.7015 12.7342L12.7351 17.7014C12.6484 17.7881 12.5418 17.8522 12.4246 17.8882C10.6659 18.4276 8.77724 18.3631 7.05938 17.705C5.34153 17.0469 3.8932 15.8331 2.94498 14.2567C1.99677 12.6803 1.60317 10.832 1.82685 9.00607C2.05053 7.18012 2.87863 5.48149 4.17931 4.1806C5.48 2.87971 7.1785 2.05134 9.00441 1.82738Z" fill="currentColor"/>
				<path d="M7.1875 9.375C7.70527 9.375 8.125 8.95527 8.125 8.4375C8.125 7.91973 7.70527 7.5 7.1875 7.5C6.66973 7.5 6.25 7.91973 6.25 8.4375C6.25 8.95527 6.66973 9.375 7.1875 9.375Z" fill="currentColor"/>
				<path d="M12.8125 9.375C13.3303 9.375 13.75 8.95527 13.75 8.4375C13.75 7.91973 13.3303 7.5 12.8125 7.5C12.2947 7.5 11.875 7.91973 11.875 8.4375C11.875 8.95527 12.2947 9.375 12.8125 9.375Z" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'thumbUp',
		jsx: (
			<>
				<path d="M7.333,16.335H4.167a3.326,3.326,0,0,1-2.074-.681L-.459,13.678l.918-1.186L3.08,14.521l.034.034a1.982,1.982,0,0,0,1.053.28H7.333a1.756,1.756,0,0,0,1.606-1.182l.015-.052L10.96,7.5a.874.874,0,0,0-.056-.812.975.975,0,0,0-.821-.352H6.75A1.594,1.594,0,0,1,5.543,5.79a1.619,1.619,0,0,1-.369-1.311v-.01L5.6,1.756A.9.9,0,0,0,5.013.8L4.987.787a.929.929,0,0,0-.946.3L.622,6.17-.622,5.333,2.794.25A2.411,2.411,0,0,1,5.5-.622,2.4,2.4,0,0,1,7.071,2.055l-.412,2.64a.156.156,0,0,0,.018.113.1.1,0,0,0,.073.028h3.333a2.446,2.446,0,0,1,2.047.988,2.357,2.357,0,0,1,.246,2.171l-1.989,6.051A3.243,3.243,0,0,1,7.333,16.335Z" transform="translate(6.233 2.207)" fill="currentColor"/>
				<path d="M2.5,12.083H1.667a2.568,2.568,0,0,1-1.78-.553A2.288,2.288,0,0,1-.75,9.75V1.583A2.288,2.288,0,0,1-.113-.2,2.568,2.568,0,0,1,1.667-.75H2.5A2.568,2.568,0,0,1,4.28-.2a2.288,2.288,0,0,1,.637,1.78V9.75a2.288,2.288,0,0,1-.637,1.78A2.568,2.568,0,0,1,2.5,12.083ZM1.667.75C1.15.75.967.847.9.905a1.021,1.021,0,0,0-.155.678V9.75a1.021,1.021,0,0,0,.155.678c.063.058.245.155.762.155H2.5c.517,0,.7-.1.762-.155a1.021,1.021,0,0,0,.155-.678V1.583A1.021,1.021,0,0,0,3.262.905C3.2.847,3.017.75,2.5.75Z" transform="translate(1.983 5.542)" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'star',
		jsx: (
			<>
				<path fillRule="evenodd" clipRule="evenodd" d="M9.21746 1.36342C9.44883 1.20796 9.72126 1.12494 10 1.12494C10.2787 1.12494 10.5512 1.20796 10.7825 1.36342C11.0133 1.5185 11.1928 1.73864 11.2983 1.9959C11.2985 1.99653 11.2988 1.99716 11.2991 1.99779L13.0462 6.22716L17.5913 6.59697C17.8657 6.62277 18.1265 6.72841 18.3415 6.90074C18.5566 7.07307 18.7165 7.30461 18.8014 7.56678C18.8864 7.82894 18.8927 8.11024 18.8196 8.37595C18.7466 8.64167 18.5972 8.88017 18.3902 9.062L18.3852 9.0664L14.9255 12.0501L15.9825 16.5104C16.0475 16.7796 16.0316 17.0622 15.9367 17.3225C15.8414 17.5837 15.6708 17.8108 15.4465 17.9751C15.2222 18.1394 14.9542 18.2335 14.6764 18.2455C14.3986 18.2575 14.1235 18.1869 13.8858 18.0427L13.8821 18.0404L10 15.6531L6.11787 18.0404L6.1142 18.0427C5.87652 18.1869 5.6014 18.2575 5.32361 18.2455C5.04582 18.2335 4.77783 18.1394 4.55351 17.9751C4.3292 17.8108 4.15862 17.5837 4.06334 17.3225C3.96838 17.0621 3.95246 16.7795 4.01757 16.5102C4.01778 16.5093 4.018 16.5084 4.01821 16.5075L5.07445 12.0539L1.61487 9.07029C1.40501 8.88902 1.25279 8.64957 1.17802 8.38254C1.10326 8.1155 1.10884 7.83235 1.19408 7.56847C1.27931 7.30459 1.44041 7.07168 1.65725 6.89883C1.8741 6.72598 2.13706 6.62086 2.41331 6.59661L2.41808 6.59619L6.95383 6.22716L8.70093 1.99779C8.70117 1.99721 8.70141 1.99663 8.70165 1.99605C8.80709 1.73873 8.98662 1.51853 9.21746 1.36342ZM10 2.78181L11.8349 7.22383C11.9424 7.48407 12.1867 7.66218 12.4673 7.68501L17.2398 8.07333L13.6008 11.2117C13.3888 11.3945 13.2963 11.6802 13.3608 11.9526L14.4722 16.6424L10.3929 14.1338C10.1519 13.9856 9.84808 13.9856 9.60713 14.1338L5.52783 16.6423L6.63913 11.9567C6.70374 11.6842 6.61123 11.3985 6.39919 11.2156L2.75602 8.07366L7.5327 7.68501C7.81334 7.66218 8.05756 7.48407 8.16506 7.22383L10 2.78181Z" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'discuss',
		jsx: (
			<>
				<path d="M10 10.9375C10.5178 10.9375 10.9375 10.5178 10.9375 10C10.9375 9.48223 10.5178 9.0625 10 9.0625C9.48223 9.0625 9.0625 9.48223 9.0625 10C9.0625 10.5178 9.48223 10.9375 10 10.9375Z" fill="currentColor"/>
				<path d="M6.5625 10.9375C7.08027 10.9375 7.5 10.5178 7.5 10C7.5 9.48223 7.08027 9.0625 6.5625 9.0625C6.04473 9.0625 5.625 9.48223 5.625 10C5.625 10.5178 6.04473 10.9375 6.5625 10.9375Z" fill="currentColor"/>
				<path d="M13.4375 10.9375C13.9553 10.9375 14.375 10.5178 14.375 10C14.375 9.48223 13.9553 9.0625 13.4375 9.0625C12.9197 9.0625 12.5 9.48223 12.5 10C12.5 10.5178 12.9197 10.9375 13.4375 10.9375Z" fill="currentColor"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M3.25 4.125V16.2258L5.75967 14.0575C5.89591 13.9398 6.06995 13.875 6.25 13.875H16.75V4.125H3.25ZM2.15273 3.02773C2.41059 2.76987 2.76033 2.625 3.125 2.625H16.875C17.2397 2.625 17.5894 2.76987 17.8473 3.02773C18.1051 3.28559 18.25 3.63533 18.25 4V14C18.25 14.3647 18.1051 14.7144 17.8473 14.9723C17.5894 15.2301 17.2397 15.375 16.875 15.375H6.52912L4.0102 17.5513C3.80993 17.7198 3.56569 17.8276 3.30627 17.8621C3.04683 17.8966 2.78295 17.8563 2.54561 17.746C2.30827 17.6358 2.10732 17.46 1.96639 17.2395C1.82546 17.019 1.75039 16.7628 1.75 16.5011L1.75 4C1.75 3.63533 1.89487 3.28559 2.15273 3.02773Z" fill="currentColor"/>
			</>
		)
	},
	{
		name: 'discuss1',
		jsx: (
			<>
				<path d="M10 10.3125C10.5178 10.3125 10.9375 9.89277 10.9375 9.375C10.9375 8.85723 10.5178 8.4375 10 8.4375C9.48223 8.4375 9.0625 8.85723 9.0625 9.375C9.0625 9.89277 9.48223 10.3125 10 10.3125Z" fill="currentColor"/>
				<path d="M6.5625 10.3125C7.08027 10.3125 7.5 9.89277 7.5 9.375C7.5 8.85723 7.08027 8.4375 6.5625 8.4375C6.04473 8.4375 5.625 8.85723 5.625 9.375C5.625 9.89277 6.04473 10.3125 6.5625 10.3125Z" fill="currentColor"/>
				<path d="M13.4375 10.3125C13.9553 10.3125 14.375 9.89277 14.375 9.375C14.375 8.85723 13.9553 8.4375 13.4375 8.4375C12.9197 8.4375 12.5 8.85723 12.5 9.375C12.5 9.89277 12.9197 10.3125 13.4375 10.3125Z" fill="currentColor"/>
				<path d="M8.20859 15L9.45859 17.1875C9.51322 17.2833 9.59222 17.363 9.68758 17.4184C9.78294 17.4738 9.89127 17.503 10.0016 17.503C10.1119 17.503 10.2202 17.4738 10.3155 17.4184C10.4109 17.363 10.4899 17.2833 10.5445 17.1875L11.7945 15H16.875C17.0408 15 17.1997 14.9342 17.3169 14.8169C17.4342 14.6997 17.5 14.5408 17.5 14.375V4.375C17.5 4.20924 17.4342 4.05027 17.3169 3.93306C17.1997 3.81585 17.0408 3.75 16.875 3.75H3.125C2.95924 3.75 2.80027 3.81585 2.68306 3.93306C2.56585 4.05027 2.5 4.20924 2.5 4.375V14.375C2.5 14.5408 2.56585 14.6997 2.68306 14.8169C2.80027 14.9342 2.95924 15 3.125 15H8.20859Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</>
		)
	},
	{
		name: 'discuss2',
		jsx: (
			<>
				<path fillRule="evenodd" clipRule="evenodd" d="M6.75 8.75C6.75 8.33579 7.08579 8 7.5 8H12.8125C13.2267 8 13.5625 8.33579 13.5625 8.75C13.5625 9.16421 13.2267 9.5 12.8125 9.5H7.5C7.08579 9.5 6.75 9.16421 6.75 8.75Z" fill="currentColor"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M6.75 11.25C6.75 10.8358 7.08579 10.5 7.5 10.5H12.8125C13.2267 10.5 13.5625 10.8358 13.5625 11.25C13.5625 11.6642 13.2267 12 12.8125 12H7.5C7.08579 12 6.75 11.6642 6.75 11.25Z" fill="currentColor"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M10.3125 3.25C8.60517 3.25 6.96777 3.92823 5.7605 5.1355C4.55323 6.34277 3.875 7.98017 3.875 9.6875V16.125H10.3125C11.1579 16.125 11.995 15.9585 12.776 15.635C13.5571 15.3115 14.2667 14.8373 14.8645 14.2395C15.4623 13.6417 15.9365 12.9321 16.26 12.151C16.5835 11.37 16.75 10.5329 16.75 9.6875C16.75 8.84212 16.5835 8.00501 16.26 7.22398C15.9365 6.44294 15.4623 5.73328 14.8645 5.1355C14.2667 4.53772 13.5571 4.06354 12.776 3.74003C11.995 3.41651 11.1579 3.25 10.3125 3.25ZM4.69984 4.07484C6.18841 2.58627 8.20734 1.75 10.3125 1.75C11.3549 1.75 12.387 1.95531 13.35 2.35421C14.3131 2.7531 15.1881 3.33777 15.9252 4.07484C16.6622 4.8119 17.2469 5.68693 17.6458 6.64995C18.0447 7.61297 18.25 8.64513 18.25 9.6875C18.25 10.7299 18.0447 11.762 17.6458 12.725C17.2469 13.6881 16.6622 14.5631 15.9252 15.3002C15.1881 16.0372 14.3131 16.6219 13.35 17.0208C12.387 17.4197 11.3549 17.625 10.3125 17.625H3.75C3.38533 17.625 3.03559 17.4801 2.77773 17.2223C2.51987 16.9644 2.375 16.6147 2.375 16.25V9.6875C2.375 7.58234 3.21127 5.56341 4.69984 4.07484Z" fill="currentColor"/>
			</>
		)
	}
];
