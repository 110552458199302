import React from 'react'

export default function TableHeader() {
    return (
        <tr>
            <th>#</th>
            <th>Feature</th>
            <th>Action</th>
            <th>Date</th>
        </tr>
    )
}
