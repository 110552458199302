import React from 'react';
import { ReportGeneratorContext } from './Context/index.js';
import {getIndicatorByKey} from '../ReportingCampaign/Indicators';
import IconBtn from './../../Components/IconBtn';
export default function Indicator ({
	value
}) {

	const {
		handleChangeCpgOptions,
		selectedCampaignOptions
	} = ReportGeneratorContext();

	const {label} = getIndicatorByKey(value);

	const handleRemove = () => {
		const selectedIndicators:any[] = [...(selectedCampaignOptions.indicators || [])];
		const newSelectedIndicators = selectedIndicators.filter(k => k !== value);
		handleChangeCpgOptions('indicators', newSelectedIndicators);
	};

	return (
		<span className='report_kpi'>
			<span className='report_kpi_label'>{label}</span>
			<IconBtn
				icon="fas fa-times"
				onClick={handleRemove}
				size="s"
				className="report_kpi_icon"
				hoverColor="alert"
				tooltip="Remove KPI"
			/>
		</span>
	);
}
