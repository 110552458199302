import React from 'react';
import { Section } from '../../../../Components/Section/Section';
import InsightsCard from '../components/Card/InsightsCard';
import { useAiInsightsContext } from '../context/AiInsightsContext';
import {AiInsight} from '../context/AiInsightsApiTypesServices';
import { v4 as uuidv4 } from 'uuid';


export default function AiInsightListContainer() {
	const {aiInsights} = useAiInsightsContext();
	console.log('aiInsights',aiInsights);
	return (
		<Section width='s'>
			<section className="section no_bottom_pad section_primary">
				<div className="mb_20">
					<div className="h1">AI insights</div>
					{/* <p className="grey_1 s_14 mt_8">
						TODO : trouver une phrase d’intro expliquant la fonctionnalité.
						Le nombre de paniers abandonnés du trafic payant est en hausse importante ces 7 derniers jours.
					</p> */}
				</div>
				{aiInsights && aiInsights.map((insight: AiInsight) => (
					<InsightsCard key={uuidv4()} insight={insight} />
				))}
			</section>
		</Section>
	);
}
