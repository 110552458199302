import React from 'react';
import {useSegmentGroupEditorContext} from '../context/SegmentListRankingContainerProvider';
import TableGridContainer from '../../../../Components/Table/TableGridContainer';
import TableRowItem from '../../../../Components/Table/TableRowItem';
import TableColItem from '../../../../Components/Table/TableColItem';
import SelectRanking from './SelectRanking';
import Btn from '../../../../Components/Btn';
import { Article } from '../../../../Components/Article/Article';
import Picto from '../../../../Components/Picto';
import { iconSegment } from '../../../../Components/CustomIcon/CustomIcon';

const tableRowStyle = {
	gridTemplateColumns: '1fr 1.2fr 120px',
};

export default function UnMappedList() {

	const {
		segmentGroups,
		listRankingSegments,
		segmentType,
	} = useSegmentGroupEditorContext();

	const listSegmentUsed = listRankingSegments[0]?.segments.map(segment => segment.segmentId);
	const segmentNonMapped = segmentGroups.filter(el => !listSegmentUsed?.includes(el.id) );

	if (!segmentType || segmentType != 'Beyable') return null;
	if (!segmentGroups || segmentGroups.length === 0) return null;
	if (!segmentNonMapped || segmentNonMapped.length === 0) return null;

	return (
		<Article innerSize='l' title="Unassociated segments">
			<TableGridContainer>
				<TableRowItem specialClasseName={'table_head_row'} styleObj={tableRowStyle}>
					<TableColItem>
						Segment
					</TableColItem>
					<TableColItem></TableColItem>
					<TableColItem></TableColItem>
				</TableRowItem>
				{segmentNonMapped.map((seg, key) =>
					<SegmentRowBeyable segment={seg} key={key} />
				)}
			</TableGridContainer>
		</Article>
	);
}

function SegmentRowBeyable({
	segment
}): JSX.Element {

	const {
		$routeParams,
		accountId
	} = useSegmentGroupEditorContext();

	const getEditURL = (segmentGroupId: string): string => {
		const ka =  $routeParams?.ka ? `&ka=${accountId}` : '';
		const provider = '&provider=ranking';
		return `/Insight/segment/edit?id=${segmentGroupId}${provider}${ka}`;
	};

	return (
		<TableRowItem specialClasseName={'table_body_row'} styleObj={tableRowStyle}>
			<TableColItem>
				<div className='flex flex flex_item_full'>
					<div className='flex_item_fix mr_15'>
						<Picto
							size="l"
							iconCustom={iconSegment}
							color={'grey'}
						/>
					</div>
					<div className='flex_item_full'>
						<div className='table_name'>{segment.name}</div>
						<div className='s_11 grey_3 mt_3'>{segment.id}</div>
					</div>
					<div className='flex_item_fix ml_10 mr_10'>
						<i className='fas fa-arrow-right grey_5'></i>
					</div>
				</div>
			</TableColItem>
			<TableColItem>
				<SelectRanking 
					rule={segment} 
					mode={'unMapped'}
				/>
			</TableColItem>
			<TableColItem align="right">
				<div className='btn_group_xs'>
					<Btn
						icon="fas fa-pen"
						tooltip="Edit segment definition"
						color="secondary"
						style="ghost_outline"
						size="xs"
						light={true}
						href={getEditURL(segment.id)}
					/>
				</div>
			</TableColItem>
		</TableRowItem>
	);
}
