
const listTooling = [
    {
        key: 'CONFIGCP',
        label: 'Code Promo',
        compare: 'hasPromoCodeConfig',
        id: 5
    },
    {
        key: 'CONFIGPOS',
        label: 'Turnover synchronization',
        compare: 'hasPointOfSalesConfig',
        id: 3
    },
    // {
    //     key: 'Import Emr',
    //     label: 'Import Emr',
    //     compare: ''
    // },

]
export default listTooling;