import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import EmptyState from '../../../../../Components/EmptyState';

export default function AlertChromeIsNeeded() {

	const [t] = useTranslation('common');

	return (
		<div className="graphic_patch_intro">
			<EmptyState
				verticalAlign={true}
				icon="fab fa-chrome"
				title={t('patch.patchsWorksOnchromium')}
				text={
					<Trans t={t} i18nKey={'patch.chromiumBasedBrowsers'}>
						Like <strong>Google Chrome</strong> or <strong>Microsoft Edge</strong> ...
					</Trans>
				}
				textSize='l'
			/>
		</div>
	);
}
