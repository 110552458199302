import { react2angular } from 'react2angular';
import React, { useEffect, useMemo, useState } from 'react';

import './ImagesAccount.css';
import Btn from '../../Components/Btn';
import SearchBar from '../../Components/SearchBar';
import ImagesAccountServices from '../../Services/ImagesAccountServices';
import { useDropzone } from 'react-dropzone';
import Item from './Item';
import Progress from './Progress';
import Message from './Message';

export default function ImagesAccount(props) {

	const accountId = props.accountId
		? props.accountId
		: props.$rootScope && props.$rootScope.User.Account.Key;

	const $http = props.$https;
	const $routeParams = props.$routeParams;
	const authServices = props.AuthServices;

	const imagesAccountServices = new ImagesAccountServices(
		$http,
		$routeParams,
		authServices
	);

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [picturesAccount, setPicturesAccount] = useState([]);
	const [picturesFiltered, setPicturesFiltered] = useState([]);
    
	const [valueInSearchBar, setValueInSearchBar] = useState('');

	const [isUploading, setIsUploading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [uploadPercentage, setUploadPercentage] = useState(0);
	const [message, setMessage] = useState();
	const [createdBanner, setCreatedBanner] = useState();
	const [createdBannerCopy, setCreatedBannerCopy] = useState(false);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		acceptedFiles,
		open
	} = useDropzone({
		accept: 'image/*,.psd,.ai',
		noClick: true,
		noKeyboard: true,
		onDrop: acceptedFiles => {
			setSelectedFiles(
				acceptedFiles
			);
		}
	});


	const style = useMemo(
		() => ({
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject]
	);


	// add and upload gallery

	const uploadImage = async (files) => {
		setIsUploading(true);
		return imagesAccountServices.postImagesForAccount(accountId, files, 
			progressEvent => {
				const progress = progressEvent.loaded / progressEvent.total * 100;
				setUploadPercentage(Math.round(progress));
                
			})
			.then(res => {
				const createdBanner = res.data.createdBanners[0];

				setPicturesAccount(current => [...current, createdBanner]);
				setPicturesFiltered(current => [...current, createdBanner]);
				setCreatedBanner(createdBanner);

				setIsUploading(false);

				console.log(createdBanner);

			}).catch(error => {
				console.error('error during upload of image', error);
				showMessage('error', error.response.data.error + ' Please retry');
				setIsUploading(false);
			});
	};

	const prepareFilesToUpload = () => {
		const getFileExtension = (filename) =>
			filename.split('.').pop().toLowerCase();

		const filesToUpload = selectedFiles.filter(
			(x) => !['ai', 'psd'].includes(getFileExtension(x.name))
		);

		const sendUploadImages = () => {
			if (filesToUpload.length > 0) {
				let formData = new FormData();
				for (let i = 0; i < filesToUpload.length; i++) {
					let fileToRegister = filesToUpload[i];
					formData.append(`file[${i}]`, fileToRegister);
				}
				return uploadImage(formData);
			}
			return Promise.resolve();
		};

		sendUploadImages();
	};


	// delete and upload gallery    


	const handleDeleteImage =async (image) => {

		let pictureToDelete = picturesAccount.map((list)=>{if(list.bannerId === image.bannerId) return image.bannerId;}).filter(id=>!!id);

		await imagesAccountServices.deleteImagesForAccount(accountId,pictureToDelete).then(()=>{
			// only to delete one pictures
			let currentPictures = picturesAccount.filter(list=> list.bannerId !== pictureToDelete[0]);
			setPicturesAccount(currentPictures);
			setPicturesFiltered(currentPictures);

			showMessage('success', 'The image has been deleted');

		}).catch((error)=>{
			showMessage('error', error.response.data.error + ' Please retry');
		});
	};

   
	// gallery search 
	const search = (table, word) => {
		setValueInSearchBar(word);
		if (word.length > 0) {
			setPicturesFiltered([]);
			let resultName = table.filter((line) =>
				line.metaData.fileName.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			setPicturesFiltered([...resultName]);
		} else {
			setPicturesFiltered(picturesAccount);
		}
	};

	// get pictures for gallery
	useEffect(() => {
		const fetchPictures = async () => {
			const result = await imagesAccountServices.getImagesFromAccount(accountId);
			setPicturesFiltered(result.data.banners);
			setPicturesAccount(result.data.banners);
			setIsLoading(false);
		};
		setIsLoading(true);
		fetchPictures().catch(err => setIsLoading(false));
	}, []);

	const saveImages = () => {
		if (selectedFiles.length > 0) {
			prepareFilesToUpload();
		}
	};

	const copyBannerUrl = (txt) => {
		let error;
		try{
			navigator.clipboard.writeText(txt);
		} catch (err) {
			error = err;
		} finally {
			if (error) {
				showMessage('error', 'An error occurred while copying');
			} else {
				showMessage('success', 'The URL was successfully copied');
			}
		}
	};

	const showMessage = (type, text) => {
		setMessage({
			type: type,
			text: text
		});
		setTimeout(function(){
			setMessage(null);
		}, 3000);
	};

	useEffect(() => {
		saveImages();
	}, [selectedFiles]);


	return (
		<>           
			<section className="modal_header">Image gallery</section>
			<section className="modal_section modal_section_grey pos_rel">
				{createdBanner && createdBanner.imagePath ?
					<div className="flex">
						<div className="flex_item_fix media_gallery_created_picto">
							<i className="fas fa-check"></i>
						</div>
						<div className="flex_item_full overflow_hidden">
							<div className="media_gallery_created_title">
                                The image was uploaded successfully
							</div>
							<div className="media_gallery_created_desc ellips">
								{createdBanner.imagePath}
							</div>
						</div>
						<div className="flex_item_fix ml_20">
							<Btn
								message="Copy URL"
								icon="fas fa-copy"
								onClick={() => copyBannerUrl(createdBanner.imagePath)}
							/>
							<Btn
								message="Done"
								style="outline"
								color="secondary"
								onClick={() => setCreatedBanner(null)}
							/>
						</div>
					</div>
					:
					<div className="flex">
						<div className="flex_item_full">
							<SearchBar
								onChange={(value)=>search(picturesAccount,value)}
								placeholder="Search for a file name"
								valueInSearchBar={valueInSearchBar}
								color="white"
							/>
						</div>
						<div className="flex_item_fix ml_20" {...getRootProps({ style })}>
							<input {...getInputProps()} />
							<Btn
								icon="fas fa-plus"
								onClick={open}
								message="Upload image"
							/>
						</div>
					</div>
				}
				<Message msg={message} />
			</section>
			<section className="modal_body media_gallery">
				{!isLoading &&
                    <>
                    	{picturesAccount.length > 0 && picturesFiltered.length > 0 &&
                            <ul className="media_gallery_list">
                            	{picturesFiltered && picturesFiltered.map((image, i) => (
                            		<Item
                            			key={i}
                            			image={image}
                            			handleCopy={(url) => copyBannerUrl(url)}
                            			handleDelete={(image) => handleDeleteImage(image)}
                            		/>
                            	))}
                            </ul>
                    	}
                    	{picturesAccount.length > 0 && picturesFiltered.length == 0 &&
                            <div className="empty_state abs">
                            	<img className="empty_state_img" src="/Assets/product_collection_empty.svg" />
                            	<div className="empty_state_title">
                                    No image name matches this search
                            	</div>
                            </div>
                    	}
                    	{picturesAccount.length == 0 &&
                            <div className="empty_state abs">
                            	<img className="empty_state_img" src="/Assets/product_collection_empty.svg" />
                            	<div className="empty_state_title">
                                    No image found
                            	</div>
                            </div>
                    	}
                    </>
				}
				{(isLoading || isUploading) &&
                    <div className="mask">
                    	<span className="wheel size_32"></span>
                    </div>    
				}
			</section>
		</>
	);
}

angular
	.module('beyableSaasApp.Campaigns.Create.Controllers')
	.component(
		'imagesAccount',
		react2angular(
			ImagesAccount,
			[],
			['$rootScope', '$scope', '$http', '$routeParams', 'AuthServices']
		)
	);
