import HttpServices from './HttpServices';

export default class RecommendationRuleCompositionServices {
	HttpServices: any;
	BO_API_URL: string;
	accountId: string;
	constructor($http, accountId: string) {
		this.HttpServices = new HttpServices($http);
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	getPossibleRules(
		rules: string[],
		callbackSuccess: (result: ProductRecommendationRulesComposerResult) => void,
		callbackError: (error: string) => void) {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/product-recommendation-composable-rule`, { rules: rules }, callbackSuccess, callbackError);
	}


	getComposition(
		compositionId: string,
		callbackSuccess: (result: CompositionApiType) => void,
		callbackError: (error: string) => void) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-recommendation-rules-composition/${compositionId}`, callbackSuccess, callbackError);
	}

	updateComposition(
		compositionId: string,
		composition: UpdateOrCreateProductRecommendationRulesComposition,
		callbackSuccess: () => void,
		callbackError: (error: string) => void): void {
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/product-recommendation-rules-composition/${compositionId}`, composition, callbackSuccess, callbackError);
	}

	createComposition(
		composition: UpdateOrCreateProductRecommendationRulesComposition,
		callbackSuccess: () => void,
		callbackError: (error: string) => void): void {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/product-recommendation-rules-composition`, composition, callbackSuccess, callbackError);
	}

	deleteComposition(
		compositionId: string,
		callbackSuccess: () => void,
		callbackError: (error: string) => void): void {
		this.HttpServices.delete(`${this.BO_API_URL}${this.accountId}/product-recommendation-rules-composition/${compositionId}`, callbackSuccess, callbackError);
	}
}

export enum PersoRuleType {
	RecentlyViewedProducts = 'RecentlyViewedProducts',
	AbandonedCartProducts = 'AbandonedCartProducts'
}

export type ProductRecommendationRulesCompositionGroupItem = {
	ruleId: (string | null);
	type: (PersoRuleType | null);
	name?: string;
	ruleLimit: number | null;
}
export type ProductRecommendationRulesCompositionGroup = {
	size: number;
	rules: ProductRecommendationRulesCompositionGroupItem[];
}
type ProductRecommendationRulesComposerResult = {
	composableRules: { id: string, name: string }[];
}


export type CompositionDesc = {
	id: string;
	description: string | null;
	lastUpdatedUtc: string | null;
	usedCount: number | null;
	context: string | null;
}

enum CompositionOutputType {
	None = 'None',
	ShelvingStandard = 'ShelvingStandard',
	ShelvingWithRecoId = 'ShelvingWithRecoId',
	OnlyProductIdsJson = 'OnlyProductIdsJson'
}
export type CompositionOutput = {
	id: string | null;
	type: CompositionOutputType;
	path: string | null;
	size: number | null;
	additionalParams: string | null;
}

export type UpdateOrCreateProductRecommendationRulesComposition = {
	name: string | null;
	description: string | null;
	tenant?: string | null;
	groups?: ProductRecommendationRulesCompositionGroup[];
	outputs?: CompositionOutput[];
}

export type CompositionApiType = {
	id: string;
	name: string;
	description: string | null;
	tenant: string | null;
	groups: ProductRecommendationRulesCompositionGroup[];
	outputs: CompositionOutput[];
}
