import React, { createContext, useState, useEffect, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

const tooltipID = uuidv4();

export const context = createContext();

export function serverSideCampaignsDashboardContext() {
	return useContext(context);
}


export const ContextProvider = ({
	getCampaignList,
	deleteCampaign,
	systemServices,
	linkToReporting,
	linkToEditor,
	$rootScope,
	children
}) => {

	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [listOfCampaigns, setListOfCampaigns] = useState([]);
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState(false);
	const [loading, setLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [isError, setIsError] = useState(false);

	const permissionsAccount = Object.keys(
		$rootScope.$$childHead.permissions
	).map((x) => ({
		Name: x,
		Value: $rootScope.$$childHead.permissions[x],
	}));

	const reload = () => {
		getCampaignList(data => {
			setLoading(false);
			setTotalCount(data.length);
			setListOfCampaigns(data);
			setIsError(false);
			setNoData(data.length == 0);
		}, err => {
			setLoading(false);
			setTotalCount(0);
			setListOfCampaigns([]);
			setIsError(true);
			setNoData(true);
		});
	};

	useEffect(() => {
		reload();
	}, []);


	const handleSearchChange = (event) => {
		const value = event.target.value;
		const str = value.trim();
		setValueInSearchBar(value);
		if (str.length === 0) {
			setDataFiltered([]);
		}
		if (listOfCampaigns) {
			search(listOfCampaigns, str);
		}
	};

	const search = (table, word) => {
		setLoading(true);
		if (word.length > 0) {
			setLoading(false);
			setDataFiltered([]);
			let resultName = table.filter(
				line =>
					line.name && line.name.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			if (resultName.length === 0) {
				setNoData(true);
			}
			else {
				setNoData(false);
				setLoading(false);
				setDataFiltered([...resultName]);
			}

		} else {
			setNoData(false);
			setLoading(false);
			setDataFiltered();
		}
	};

	const handleDeleteCampaign = (campaignId) => {
		deleteCampaign(campaignId,
			() => {
				reload();
			},
			() => {
				systemServices.showError('An error occurred while deleting the campaign');
			});
	};

	const getEditLink = (campaignId) => {

		return linkToEditor(campaignId);
	};

	const getCreateLink = () => {
		return getEditLink();
	};

	return (
		<context.Provider
			value={{
				listOfCampaigns,
				loading,
				totalCount,
				handleSearchChange,
				valueInSearchBar,
				dataFiltered,
				noData,
				isError,
				getEditLink,
				getCreateLink,
				handleDeleteCampaign,
				linkToReporting,
				permissionsAccount
			}}
		>
			<ReactTooltip
				Id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={600}
			/>
			{children}
		</context.Provider>
	);
};