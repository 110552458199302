import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../Components/Dropdown';
import IconBtn from '../../Components/IconBtn';
import EmptyState from '../../Components/EmptyState';
import Link from '../../Components/Link';
import Picto from '../../Components/Picto';

import styles from './Notifications.module.css';

import ImpersonatingServices from '../../Services/ImpersonatingServices';
import NotificationsServices from '../../Services/NotificationsServices';
import moment from 'moment';

const notificationLinks = {
	'ScenarioRecommendation': {
		'transKey': 'types.ScenarioRecommendation',
		'status': {
			'Failed': {
				'icon': 'fas fa-times',
				'color': 'red',
				'linkTransKey': 'links.learnMore',
				'linkUrl': '/Insight/audit'
			},
			'Succeded': {
				'icon': 'fas fa-check',
				'color': 'green',
				'linkTransKey': 'links.viewTheReport',
				'linkUrl': '/Insight/audit'
			},
			'Running': {
				'icon': 'fas fa-hourglass-half',
				'color': 'yellow'
			}
		}
	},
	'ReportGenerator': {
		'transKey': 'types.ReportGenerator',
		'status': {
			'Failed': {
				'icon': 'fas fa-times',
				'color': 'red',
			},
			'Succeded': {
				'icon': 'fas fa-check',
				'color': 'green',
				'linkTransKey': 'links.viewTheReport',
				'paramAsLink': 1
			},
			'Running': {
				'icon': 'fas fa-hourglass-half',
				'color': 'yellow'
			}
		}
	}
};

export function checkExpireToken(token){
	const expDate:Date = new Date(token.exp * 1000);
	const now: Date = new Date();

	if(expDate < now ){
		return true;
	}else{
		return false;
	}
}

function Notify (props){

	const [t] = useTranslation(['notifications', 'utils']);
	const $rootScope = props.props.$rootScope;
	const $routeParams = props.props.$routeParams;
	const $http =props.props.$http;

	const impersonatingServices = new ImpersonatingServices(
		$rootScope,
		$routeParams
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope?.User?.Account?.Key);

	const notificationsServices = new NotificationsServices(
		$http,
		accountId
	);
	const [connection, setConnection] = useState<null | HubConnection>(null);
	const [notifIsOpen, setnotifIsOpen] = useState(false);
	const [notifications, setnotifications] = useState<any>([]);
	const [nbUnreadNotifications, setNbUnreadNotifications] = useState(0);

	const [connexionOptions, setconnexionOptions] = useState<any>();
	function parseJwt (token) {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
	
		return JSON.parse(jsonPayload);
	}

	function getNewConnexion(url,token){
		const connect = new HubConnectionBuilder()
			.withUrl(url, { accessTokenFactory: ()=> token },
			)
			.withAutomaticReconnect()
			.build();

		setConnection(connect);
	}

	function getConnexionInfosAndMessages(needConnect){
		let token;
		let url;
		notificationsServices.getNotifications(data => {
			setconnexionOptions(data?.negotiationInfo);
			token = data?.negotiationInfo?.accessToken;
			url = data?.negotiationInfo?.url;

			const messages:Array<any>= data.messages.sort(function(a, b) { 
				a = new Date(a.sentAtUtc);
				b = new Date(b.sentAtUtc);
				return a >b ? -1 : a < b ? 1 : 0;
			});

			const notifs = messages.map((m) => {
				const notif = {...m};
				const tpl = notificationLinks[notif.sender];
				if (tpl) {
					notif.categoryTransKey = tpl.transKey;
					notif.icon = tpl.icon;
					notif.color = tpl.color;
					const status = tpl.status[notif.status];
					if (status) {
						notif.icon = status.icon;
						notif.color = status.color;
						notif.linkTransKey = status.linkTransKey;

						if (status.paramAsLink && notif.parameters && notif.parameters[status.paramAsLink - 1]) {
							notif.linkUrl = notif.parameters[status.paramAsLink - 1];
							notif.linkTarget = '_blank';
						} else {
							notif.linkUrl = status.linkUrl ? impersonatingServices.getImpersonatedUrl(status.linkUrl) + '&id=' + notif.id : '';
						}

						notif.hasLink = notif.linkTransKey && notif.linkUrl;
					}
				}
				return notif;
			});

			const unread = messages.filter((m) => m.isRead === false);

			setnotifications(notifs);
			setNbUnreadNotifications(unread.length);

			if(needConnect){
				getNewConnexion(url, token);
			}
		}, err => {
			console.log('🚀🐱 😻 --///** ~ file: Notifications.tsx:47 ~ useEffect ~ err:', err);
		}
		);
	}

	useEffect(() => {
		const timeout = setTimeout(() => {
			if(notifIsOpen){
				const unread = notifications.filter((m) => m.isRead === false);

				if (unread.length > 0) {
					unread.forEach(n => {
						handleReadNotif(n.id);
					});
				}
			}
		}, 2000);

		return () => {
			clearTimeout(timeout);
		};
		
	}, [notifIsOpen]);

	useEffect(() => {
		if(accountId){
			getConnexionInfosAndMessages(true);
		}
	}, [accountId]);

	useEffect(()=>{
		if(connexionOptions){
			const parseToken = parseJwt(connexionOptions?.accessToken);
	
			const tokenIsExpire = checkExpireToken(parseToken);
			if(tokenIsExpire){
				getConnexionInfosAndMessages(true);
			}

		}
		
	},[connexionOptions]);
	
	useEffect(() => {
		if (connection) {
			connection
				.start()
				.then(() => {
					connection.on('newNotification', (_message) => {
						getConnexionInfosAndMessages(false);
						$rootScope.$broadcast('event:newNotification', false);
					});
				})
				.catch((error) => console.log(error));
		}
	}, [connection]);

	function handleDeleteNotif(id){
		notificationsServices.deleteNotifications(id , _=> {
			getConnexionInfosAndMessages(false);
		}, err => {
			console.log('🚀🐱 😻 --///** ~ file: Notifications.tsx:127 ~ Notify ~ err:', err);
			return {};
		});
	}

	function handleReadNotif(id) {
		notificationsServices.readNotifications(id , _=> {
			getConnexionInfosAndMessages(false);
		}, err => {
			console.log('🚀🐱 😻 --///** ~ file: Notifications.tsx:127 ~ Notify ~ err:', err);
			return {};
		});
	}

	function getFormatedDate(date) {
		return moment(date).fromNow(true);
	}

	return (
		<>
			<Dropdown
				isOpen={notifIsOpen}
				toggle={(s) => setnotifIsOpen(s)}
				scrollbar={false}
				button={
					<IconBtn
						icon={nbUnreadNotifications > 0 ? 'fas fa-bell' : 'far fa-bell'}
						size="xl"
						theme="dark"
						tooltip={t('notifications')}
						bubble={nbUnreadNotifications > 0 ? nbUnreadNotifications : undefined}
					/>
				}
			>
				<div className={styles.wrapper}>
					<div className={styles.header}>
						<div className={styles.title}>{t('notifications')}</div>
					</div>
					{notifications.length > 0 ?
						<div className={styles.scroll + ' vscroll_custom'}>
							<div className={styles.notif_list}>
								{notifications.map((x:any )=>
									<div key={x.id} className={x.isRead ? styles.notif_read : styles.notif_unread}>
										<div className={styles.notif_col_picto}>
											<Picto
												size="l"
												icon={x.icon || 'fas fa-bell'}
												color={x.color || 'blue'}
											/>
										</div>
										<div className={styles.notif_col_content}>
											<div className={styles.notif_header}>
												<div className={styles.notif_title}>{t(x.categoryTransKey)}</div>
												<div className={styles.notif_date}>
													{getFormatedDate(x.sentAtUtc)}
												</div>
											</div>
											<div className={styles.notif_body}>
												<div className={styles.notif_body_content}>
													<div className={styles.notif_text}>{x.content}</div>
													{x.hasLink &&
														<div className={styles.notif_actions}>
															<Link
																message={t(x.linkTransKey)}
																href={x.linkUrl}
																onClick={() => setnotifIsOpen(false)}
																target={x.linkTarget}
																icon="fas fa-arrow-right fa-sm fa-fw"
																iconPosition="after"
																strong={false}
															/>
														</div>
													}
												</div>
												<div className={styles.notif_overlay_actions}>
													<IconBtn
														icon="fas fa-trash"
														onClick={()=>handleDeleteNotif(x.id)}
														hoverColor="alert"
														size="s"
														tooltip={t('actions.delete', {ns: 'utils'})}
													/>
												</div>
											</div>

											{/*<i className='fas fa-eye' onClick={()=>handleReadNotif(x.id)}></i> */}
										</div>
									</div>
								)}
							</div>
						</div>
						:
						<div className={styles.section}>
							<EmptyState
								title={t('noNotification')}
								titleBold={false}
								textSize="s"
								imageUrl="/Assets/empty_notification.svg"
								imageWidth={150}
								verticalSize="s"
							/>
						</div>
					}
				</div>

			</Dropdown>
		</>
	);
}

export default Notify;
