import React from 'react';
import styles from './Pie.module.css';
import {IPie, IPiece} from './types';

function Pie({
	title,
	data,
	width = 100,
	thickness = 12,
	showTable = true,
	nbDecimal = 0
}: IPie): JSX.Element {

	let lastEnd = 0;

	const colors = [
		{label: 'lightblue', value: '#53AEF0'},
		{label: 'purple', value: '#AD6DD5'},
		{label: 'lightgreen', value: '#8DD376'},
		{label: 'orange', value: '#FA8D20'},
		{label: 'yellow', value: '#FFE23E'},
		{label: 'blue', value: '#546FE5'},
		{label: 'red', value: '#ED522E'},
		{label: 'magenta', value: '#B251A7'},
		{label: 'green', value: '#2BB46F'},
	];

	function getColor(colorName?:string) {
		let colorObj;
		let colorIndex;

		if (colorName) {
			colorObj = colors.find((c, i) => {
				const isSameColor = c.label === colorName;
				if (isSameColor) colorIndex = i;
				return isSameColor;
			});
		}

		if (!colorObj) {
			colorIndex = 0;
			colorObj = colors[colorIndex];
		}

		const colorValue = colorObj.value;
		colors.splice(colorIndex, 1);
		return colorValue;
	}

	function getStyle(el:IPiece, colorCode) {
		const style = {
			'--start': lastEnd,
			'--end': el.value + lastEnd,
			'--thickness': thickness + 'px',
			'--c': colorCode
		};
		lastEnd += el.value;
		return style;
	}

	function getRoundedValue(v) {
		let round = 0;
		if (nbDecimal === 0){
			round = Math.round(v);
		} else {
			const pow = Math.pow(10, nbDecimal);
			round = Math.round(v * pow) / pow;
		}
		return round.toString().replace('.', ',');
	}

	function getData(source) {
		const data = source.map((s) => {
			const colorCode = getColor(s);
			return {
				label: s.label,
				value: getRoundedValue(s.value),
				color: colorCode,
				pieStyle: getStyle(s, colorCode)
			};
		});
		return data;
	}

	const finalData = getData(data);

	const donut = (
		<div className={styles.pie} style={{'width': width + 'px', 'height': width + 'px'}}>
			{finalData.map(((piece, i) => 
// @ts-ignore 
				<div key={i} className={styles.piece} style={piece.pieStyle}></div>
			))}
		</div>
	);

	if (showTable) {
		return (
			<>
				{title &&
					<div className={styles.title}>{title}</div>
				}
				<div className='flex'>
					<div className='flex_item_fix'>
						{donut}
					</div>
					<div className='flex_item_full'>
						<div className={styles.table}>
							{finalData.map(((piece, i) => 
								<div key={i} className={styles.table_row}>
									<div className={styles.table_col_color} style={{'backgroundColor': piece.color}}></div>
									<div className={styles.table_col_label}>{piece.label}</div>
									<div className={styles.table_col_sep}></div>
									<div className={styles.table_col_value}>{piece.value}&nbsp;%</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</>
		);
	}
	return donut;
}

export {
	Pie
};
