import React from 'react';
import { Skeleton } from '../../../Components/Skeleton/Skeleton';
import styles from './Campaigns.module.css';
import {ICampaignList} from './types';
import { useTranslation } from 'react-i18next';

function getDateFromNow(date) {
	// @ts-ignore
	return moment(date).fromNow();
}

function CampaignList({
	isLoading,
	title,
	emptyText,
	list,
	max = 5,
	type
}: ICampaignList): JSX.Element {
	
	if (isLoading) {
		return <Skeleton appearance="paragraphe" />;
	}

	const [t] = useTranslation('kpi');
	const isEmpty = list.length <= 0;
	const bulletClass = type == 'scheduled' ? styles.item_bullet_scheduled : styles.item_bullet_ongoing;
	const filteredList:any = list.slice(0, max);
	const nbFiltered = list.length > max ? list.length - max : 0;
	
	if (isEmpty) {
		return (
			<>
				<div className={styles.title}>{title}</div>
				<div className={styles.empty_text}>{emptyText}</div>
			</>
		);
	}

	return (
		<>
			<div className={styles.title}>{title}</div>
			<div className={styles.list}>
				{filteredList.map(cpg =>
					<div key={cpg.id} className={styles.item}>
						<div className={bulletClass}></div>
						<div className={styles.item_name}><a href={cpg.urlToCampaign}>{cpg.name}</a></div>
						<div className={styles.item_sep}></div>
						<div className={styles.item_date}>
							{getDateFromNow(cpg.startDate)}
						</div>
					</div>
				)}
				{nbFiltered > 0 &&
					<div className='grey_3'>
						{nbFiltered == 1 ?
							<>{t('common.And1Other')}</>
							:
							<>{t('common.AndNOthers', {nb: nbFiltered})}</>
						}
					</div>
				}
			</div>

		</>
	);
}

export {
	CampaignList
};
