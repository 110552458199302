export const getFakeProducts = (nbOfProducts) => {
	return fakeProducts
		.map((x, i) => ({
			...x,
			ThumbnailUrl: `/Assets/reco/${(i% 18)+1}.jpg`,
			DiscountPercent: x.DiscountPercent + i % 6,
			IsDiscounted: i % 3,
			PriceBeforeDiscount: i % 3 ? null : x.PriceBeforeDiscount
		}))
		.slice(0, nbOfProducts);
};

const fakeProducts = [
	{
		'Name': 'Outdoors Component',
		'Url': 'https://www.whatever.com/1',
		'Description': 'Activity',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'SellingPrice': '69.99',
		'PriceBeforeDiscount': '99.95',
		'GlobalStockQuantity': '58',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Party Mechanism',
		'Url': 'https://www.whatever.com/1',
		'Description': 'Activity Pets Entertainment',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'SellingPrice': '156',
		'PriceBeforeDiscount': '174.99',
		'GlobalStockQuantity': '68',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Truck Supplies',
		'Url': 'https://www.whatever.com/1',
		'Description': 'Automotive Activity Truck',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'SellingPrice': '174.95',
		'PriceBeforeDiscount': '194.95',
		'GlobalStockQuantity': '32',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Jewelry Instrument',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Clothing Home',
		'SellingPrice': '100.95',
		'PriceBeforeDiscount': '143.99',
		'GlobalStockQuantity': '32',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Dog Instrument',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Pets Home Mechanism',
		'SellingPrice': '19.95',
		'PriceBeforeDiscount': '22',
		'GlobalStockQuantity': '60',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Phone Implement',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Electronics Home Party Phone',
		'SellingPrice': '8.95',
		'PriceBeforeDiscount': '14.99',
		'GlobalStockQuantity': '22',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Bedding Implement',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Home Nutrition',
		'SellingPrice': '69.99',
		'PriceBeforeDiscount': '87.99',
		'GlobalStockQuantity': '23',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Nutrition Component',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Health',
		'SellingPrice': '116.95',
		'PriceBeforeDiscount': '145',
		'GlobalStockQuantity': '35',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Swimming Widget',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Activity',
		'SellingPrice': '128.99',
		'PriceBeforeDiscount': '184.99',
		'GlobalStockQuantity': '76',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Medicine Contraption',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Health Pets',
		'SellingPrice': '84.99',
		'PriceBeforeDiscount': '106',
		'GlobalStockQuantity': '74',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Kitchen Rig',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Home',
		'SellingPrice': '46.95',
		'PriceBeforeDiscount': '77',
		'GlobalStockQuantity': '59',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Aquarium Tool',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Pets',
		'SellingPrice': '101.99',
		'PriceBeforeDiscount': '145.95',
		'GlobalStockQuantity': '39',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Book Gizmo',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Entertainment',
		'SellingPrice': '86.99',
		'PriceBeforeDiscount': '96',
		'GlobalStockQuantity': '68',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Phone Thingamajig',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Electronics',
		'SellingPrice': '119.95',
		'PriceBeforeDiscount': '199.99',
		'GlobalStockQuantity': '21',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Engine Component',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Automotive',
		'SellingPrice': '118.99',
		'PriceBeforeDiscount': '198.99',
		'GlobalStockQuantity': '69',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Phone Kit',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Electronics',
		'SellingPrice': '76.99',
		'PriceBeforeDiscount': '96.99',
		'GlobalStockQuantity': '24',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Womens Whatchamacallit',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Clothing',
		'SellingPrice': '115',
		'PriceBeforeDiscount': '144.95',
		'GlobalStockQuantity': '18',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Game Gizmo',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Entertainment',
		'SellingPrice': '124.95',
		'PriceBeforeDiscount': '138',
		'GlobalStockQuantity': '34',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Camping Equipment',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Activity',
		'SellingPrice': '142.95',
		'PriceBeforeDiscount': '158.99',
		'GlobalStockQuantity': '51',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Kitchen Whatchamacallit',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Home',
		'SellingPrice': '105.99',
		'PriceBeforeDiscount': '117.95',
		'GlobalStockQuantity': '62',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Womens Attachment',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Clothing',
		'SellingPrice': '14.95',
		'PriceBeforeDiscount': '21.99',
		'GlobalStockQuantity': '43',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Cooking Doodad',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Home',
		'SellingPrice': '137.99',
		'PriceBeforeDiscount': '196.99',
		'GlobalStockQuantity': '30',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Womens Paraphernalia',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Clothing',
		'SellingPrice': '90.95',
		'PriceBeforeDiscount': '101',
		'GlobalStockQuantity': '59',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Hair Care Item',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Beauty',
		'SellingPrice': '88.99',
		'PriceBeforeDiscount': '126.99',
		'GlobalStockQuantity': '69',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Beach Implement',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Activity',
		'SellingPrice': '42',
		'PriceBeforeDiscount': '61.99',
		'GlobalStockQuantity': '36',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Outdoors Supplies',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Activity',
		'SellingPrice': '62.99',
		'PriceBeforeDiscount': '78.99',
		'GlobalStockQuantity': '45',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Kitchen Supplies',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Home',
		'SellingPrice': '40',
		'PriceBeforeDiscount': '45.99',
		'GlobalStockQuantity': '21',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Swimming Thingamajig',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Activity',
		'SellingPrice': '101.95',
		'PriceBeforeDiscount': '127',
		'GlobalStockQuantity': '65',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Gardening Tool',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Home',
		'SellingPrice': '73.95',
		'PriceBeforeDiscount': '105.99',
		'GlobalStockQuantity': '39',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Movie Device',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Entertainment',
		'SellingPrice': '8.99',
		'PriceBeforeDiscount': '12',
		'GlobalStockQuantity': '74',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Personal Equipment',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Beauty',
		'SellingPrice': '66',
		'PriceBeforeDiscount': '95',
		'GlobalStockQuantity': '47',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Book Whatchamacallit',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Entertainment',
		'SellingPrice': '90.99',
		'PriceBeforeDiscount': '151.95',
		'GlobalStockQuantity': '42',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Mens Equipment',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Clothing',
		'SellingPrice': '90',
		'PriceBeforeDiscount': '101',
		'GlobalStockQuantity': '49',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Outdoors Gizmo',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Activity',
		'SellingPrice': '60.95',
		'PriceBeforeDiscount': '76.95',
		'GlobalStockQuantity': '41',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Phone Machine',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Electronics',
		'SellingPrice': '31',
		'PriceBeforeDiscount': '53.99',
		'GlobalStockQuantity': '66',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Car Machine',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Automotive',
		'SellingPrice': '102.99',
		'PriceBeforeDiscount': '114',
		'GlobalStockQuantity': '47',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Aquarium Item',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Pets',
		'SellingPrice': '54.99',
		'PriceBeforeDiscount': '68.95',
		'GlobalStockQuantity': '37',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Outdoors Tool',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Activity',
		'SellingPrice': '133',
		'PriceBeforeDiscount': '148',
		'GlobalStockQuantity': '31',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Car Gadget',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Automotive',
		'SellingPrice': '33.95',
		'PriceBeforeDiscount': '55.99',
		'GlobalStockQuantity': '35',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Baby Widget',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Clothing',
		'SellingPrice': '63',
		'PriceBeforeDiscount': '71.95',
		'GlobalStockQuantity': '46',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	},
	{
		'Name': 'Bath Supplies',
		'Url': 'https://www.whatever.com/1',
		'ThumbnailUrl': '/Assets/reco/1.jpg',
		'Description': 'Beauty',
		'SellingPrice': '138.99',
		'PriceBeforeDiscount': '198',
		'GlobalStockQuantity': '29',
		'IsDiscounted': true,
		'DiscountPercent': 12.2,
		'Tags': ['tag1=1']
	}
];