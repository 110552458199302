import React, { useEffect, useState } from 'react';
import Dropdown from '../../../../Components/Dropdown';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';
import Btn from '../../../../Components/Btn';
import SettingsPanelContainer from './SettingsPanelContainer';
import RouterItems from '../../..//Menu/RouterItems';
import SelectDropdown from '../../../../Components/SelectDropdown';
import CustomDimensionsPanelContainer from './CustomDimensionsPanelContainer';

export default function LayoutAnalytics({
	accountInfo,
	selectedTenant,
	swithTenantAndRefresh,
	settingsURL,
	docURL,
	activeDimensionTab,
	changeActiveDimensionTab,
	activeSubDimensionTab,
	customDimensionServices,
	settingServices,
	systemServices,
	userPermissions,
	children,
	$routeParams,
	forceUpdtate
}) {
	const [actionsDropdownIsOpen, setActionsDropdownIsOpen] = useState(false);
	const [settingsPanelIsOpen, setSettingsPanelIsOpen] = useState(false);
	const [customDimensionsPanelIsOpen, setCustomDimensionsPanelIsOpen] = useState(false);

	const canViewECommerce = userPermissions.WEBANALYTICS_ECOMMERCE_CAN_VIEW || false;
	function getFinalUrl(link) {

		if(!link) return;

		const ka = $routeParams.ka;
		const ku = $routeParams.ku;
		if(!ka){

			return link;
		}else{
			return  link + '&' + 'ka='+ ka +'&ku='+ ku;
		}
	}
	const [routerList,setRouterList] = useState([
		
	]);


	useEffect(()=>{
		let newRouter = [
			{
				label : 'Sessions',
				icon: 'session',
				onClick: () => changeActiveDimensionTab('sessions'),
				link: getFinalUrl('/WebAnalytics/Dashboard/?subdimension=overview&dimension=sessions'),
				activeUrls: ['/WebAnalytics/Dashboard/'],
				activeQueries: {
					dimension: 'sessions'
				}
			},
			{
				label : 'Pages',
				icon: 'page',
				onClick: () => changeActiveDimensionTab('pages'),
				link: getFinalUrl('/WebAnalytics/Dashboard/?subdimension=overview&dimension=pages'),
				activeUrls: ['/WebAnalytics/Dashboard/'],
				activeQueries: {
					dimension: 'pages'
				}
			},
			{
				label : 'Interactions',
				icon: 'interaction',
				onClick: () => changeActiveDimensionTab('interactions'),
				link: getFinalUrl('/WebAnalytics/Dashboard/?subdimension=overview&dimension=interactions'),
				activeUrls: ['/WebAnalytics/Dashboard/'],
				activeQueries: {
					dimension: 'interactions'
				}
			},
			{
				label : 'Geolocation',
				icon: 'ip',
				onClick: () => changeActiveDimensionTab('geolocalisation'),
				link: getFinalUrl('/WebAnalytics/Dashboard/?subdimension=geoloc&dimension=geolocalisation'),
				activeUrls: ['/WebAnalytics/Dashboard/'],
				activeQueries: {
					dimension: 'geolocalisation'
				}
			}
		];
		if (canViewECommerce) {
			newRouter.push({
				label : 'E-commerce',
				icon: 'ecommerce',
				items: [
					{
						label : 'Overview',
						onClick: () => changeActiveDimensionTab('ecommerce', 'overview'),
						link: getFinalUrl('/WebAnalytics/Dashboard/?subdimension=overview&dimension=ecommerce'),
						activeUrls: ['/WebAnalytics/Dashboard/'],
						activeQueries: {
							dimension: 'ecommerce',
							subdimension: 'overview'
						}
					},
					{
						label : 'Performance',
						onClick: () => changeActiveDimensionTab('ecommerce', 'performance'),
						link: getFinalUrl('/WebAnalytics/Dashboard/?subdimension=performance&dimension=ecommerce'),
						activeUrls: ['/WebAnalytics/Dashboard/'],
						activeQueries: {
							dimension: 'ecommerce',
							subdimension: 'performance'
						}
					},
					{
						label : 'Transactions',
						onClick: () => changeActiveDimensionTab('ecommerce', 'transaction'),
						link: getFinalUrl('/WebAnalytics/Dashboard/?subdimension=transaction&dimension=ecommerce'),
						activeUrls: ['/WebAnalytics/Dashboard/'],
						activeQueries: {
							dimension: 'ecommerce',
							subdimension: 'transaction'
						}
					},
					{
						label : 'Buying behaviour',
						onClick: () => changeActiveDimensionTab('ecommerce', 'buyingbehaviour'),
						link: getFinalUrl('/WebAnalytics/Dashboard/?subdimension=buyingbehaviour&dimension=ecommerce'),
						activeUrls: ['/WebAnalytics/Dashboard/'],
						activeQueries: {
							dimension: 'ecommerce',
							subdimension: 'buyingbehaviour'
						}
					}		
				]
			});
		}
		setRouterList(newRouter);
		
	},[activeSubDimensionTab,activeDimensionTab,userPermissions,forceUpdtate]);

	const getPageName = () => {
		const a = activeDimensionTab;
		if (a == 'sessions') return 'Sessions';
		if (a == 'pages') return 'Pages';
		if (a == 'interactions') return 'Interactions';
		if (a == 'ecommerce') return 'E-commerce';
		if (a == 'geolocalisation') return 'Geolocation';
	};
	const pageName = getPageName();

	const getSubPageName = () => {
		const a = activeDimensionTab;
		if (a != 'ecommerce') return '';

		const b = activeSubDimensionTab;
		if (b == 'overview') return 'Overview';
		if (b == 'performance') return 'Performance';
		if (b == 'transaction') return 'Transactions';
		if (b == 'buyingbehaviour') return 'Buying behaviour';
	};
	const subPageName = getSubPageName();

	const tenantSelect = (
		<>
			{accountInfo.tenants.length > 1 &&
				<div className='confidential_data'>
					<SelectDropdown
						value={selectedTenant}
						optionsList={accountInfo.tenants.map((t) => {
							return {
								label: t || 'Default tenant',
								value: t
							};
						})}
						onChange={(v) => swithTenantAndRefresh(v)}
						fullWidth={true}
						color='white'
						label='Tenant'
						labelPosition='inner'
					/>
				</div>
			}
		</>
	);

	return (
		<>
			<RouterItems
				title="Web analytics"
				list={routerList}
				beforeMenuChildren={tenantSelect}>
				<div className="page_full_inner">
					<section className='section no_bottom_pad section_primary'>
						<div className='flex'>
							<span className='h1'>{pageName}</span>
							{subPageName &&
								<>
									<i className='fas fa-chevron-right fa-fw grey_5 s_13 ml_10 mr_10'></i>
									<span className='h1'>{subPageName}</span>
								</>
							}
							<span className="ml_10">
								<Dropdown
									isOpen={actionsDropdownIsOpen}
									setIsOpen={() =>
										setActionsDropdownIsOpen(true)
									}
									onHide={() =>
										setActionsDropdownIsOpen(false)
									}
									button={
										<Btn
											icon="fas fa-ellipsis-v"
											style="ghost"
											color="secondary"
											className="btn_w_xxs"
										/>
									}
								>
									<Listbox>
										<ListboxItem
											message='Exclude IP addresses'
											icon='fas fa-map-marker-alt'
											onClick={() => {
												setSettingsPanelIsOpen(true);
												setActionsDropdownIsOpen(false);
											}}
										/>
										<ListboxItem
											message='Configure custom dimensions'
											icon='fas fa-cube'
											onClick={() => {
												setCustomDimensionsPanelIsOpen(true);
												setActionsDropdownIsOpen(false);
											}}
										/>
										<li className='hr' />

										<ListboxItem
											message='Settings'
											description='Configuration - Tenants - Installation script'
											icon='fas fa-cog'
											href={settingsURL}
											target="_blank"
											rel="noreferrer"
										/>
										<li className='hr' />

										<ListboxItem
											message='Documentation'
											icon='fas fa-question-circle'
											href={docURL}
											target="_blank"
											rel="noreferrer"
										/>
									</Listbox>
								</Dropdown>
							</span>
						</div>
					</section>
					{children}
				</div>
				<SettingsPanelContainer
					panelIsOpen={settingsPanelIsOpen}
					onClosePanel={() => setSettingsPanelIsOpen(false)}
					settingServices={settingServices}
					systemServices={systemServices}
				/>
				<CustomDimensionsPanelContainer
					panelIsOpen={customDimensionsPanelIsOpen}
					onClosePanel={() => setCustomDimensionsPanelIsOpen(false)}
					customDimensionServices={customDimensionServices}
					selectedTenant={selectedTenant}
				></CustomDimensionsPanelContainer>
			</RouterItems>
		</>
	);
}
