import React, { createContext, useContext, useState, useEffect } from 'react';
import InsightsServices from '../../../Services/InsightsServices';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import { initialDates } from '../config';
import { metricsTypes, sourceTypes,
	initialUtmFilter,
	initialUtmFilterV2,
	devices
} from '../Traffic/config';
import {findNavLabel} from '../config';

const EcommerceContext = createContext();

function useEcommerceContext() {
	return useContext(EcommerceContext);
}

const ContextTrafficProvider = (props) => {
	const routeParams  = props.$routeParams;
	const AuthServices = props.AuthServices;
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		props.$routeParams
	);
	
	const insightsServices = new InsightsServices(
		props.$http,
		props.$routeParams,
		AuthServices
	);
	
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const [sourceType, setSourceType] = useState([]);
	const [pageTypeSelected, setPageTypeSelected] = useState([]);
	const [intentionSelected, setIntentionSelected] = useState([]);
	const { initialFrom, initialTo } = initialDates;
	const [navFilter, setNavFilter] = useState({
		fromDate: initialFrom,
		toDate: initialTo,
		filters: [],
	});

	const [customDateRange, setCustomDateRange] = useState( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const [isCustomSelected, setisCustomSelected] = useState(false);
	const [selectedTimeGranularity, setSelectedTimeGranularity] =
        useState('Day');
	const [selectedPeriod, setSelectedPeriod] = useState(initialDates);
	const [reportTypeSelected, setReportTypeSelected] = useState('Page');
	const [filterUrl, setfilterUrl] = useState(null);
	const [isLoadingData, setisLoadingData] = useState(false);
	const [isLoadingDatatab, setisLoadingDatatab] = useState(false);
	const [needRefreshGraph, setNeedRefreshgraph] = useState(false);
	const [needRefreshBoard, setNeedRefreshBoard] = useState(false);
	const [metricTypeSelected, setmetricTypeSelected] = useState({
		value: 'none',
	});
	const [orderBy, setOrderBy] = useState('UniqueViews');
	const [orderByDesc, setOrderByDesc] = useState('desc');


	const [needRefreshForUtmFilter, setneedRefreshForUtmFilter] = useState(false);
	const [utmDimensionsSelected, setUtmDimensionsSelected] = useState(initialUtmFilterV2);
	
	const [utmSelected, setUtmSelected] = useState({
		source: false,
		medium: false,
		campaign: false,
		content: false
	});
	const [deviceSelected, setdeviceSelected] = useState([]);

	function handleChangeDeviceSelected(elem){
		const newKey = deviceSelected.find(el => el === elem);
		const oldD = [...deviceSelected];
		const indefOfVal = oldD.indexOf(elem);
		if(indefOfVal !== -1){
			setdeviceSelected(oldD.filter(x => x !== newKey));
		}else{
			setdeviceSelected([...oldD,elem]);
		}
	}
	function resetDevices(){
		setdeviceSelected([]);
	}
	const handleChangeReportSelect = (val) => {
		reportTypeSelected !== val && setReportTypeSelected(val);
	};


	const handleSelectUrl = (url) => {
		if (filterUrl !== url) {
			setNeedRefreshgraph(true);
			setfilterUrl(url);
		}
	};
   
   

	const onSelectMetricType = (val) => {
		const newTypeFiltered = metricsTypes.find((t) => t.value === val);
		newTypeFiltered && setmetricTypeSelected(newTypeFiltered);
	};
	const handleChangeSourceType = (val) => {
		const oldSources = [...sourceType];
		const indefOfVal = oldSources.indexOf(val);
		if(indefOfVal !== -1){
			setSourceType(oldSources.filter(x => x !== val));
		}else{
			setSourceType([...oldSources,val]);
		}
	};
	const resetSource = () => {
		setSourceType([]);
	};
	const handleChangeIntentionSelected= (val) => {
		const oldIntentions = [...intentionSelected];
		const indefOfVal = oldIntentions.indexOf(val);
		if(indefOfVal !== -1){
			setIntentionSelected(oldIntentions.filter(x => x !== val));
		}else{
			setIntentionSelected([...oldIntentions,val]);
		}
	};
	const resetIntention = () => {
		setIntentionSelected([]);
	};

	const handleChangePageType = (val) => {
		const oldPageType = [...pageTypeSelected];
		const indefOfVal = oldPageType.indexOf(val);
		if(indefOfVal !== -1){
			setPageTypeSelected(oldPageType.filter(x => x !== val));
		}else{
			setPageTypeSelected([...oldPageType,val]);
		}
	};
	const resetPageType = () => {
		setPageTypeSelected([]);
	};
	function resetUtmSelected() {
		setUtmSelected({
			source: false,
			medium: false,
			campaign: false,
			content: false
		});
	}
	function handleSelectUtmDimension(utmDimension, value) {

		setUtmSelected(dimensions => ({
			...dimensions,
			[utmDimension]: value
		})
		);
	}
	function hansleSaveUtmDimensionFilter(newFilterUtm) {
		setneedRefreshForUtmFilter(!needRefreshForUtmFilter);
		setUtmDimensionsSelected(newFilterUtm);
	}

	const initiNavDate = (navState) => {
		// if (!navState || !navState.fromDate || !navState.toDate)
		// 	return initialDates;
		return {
			initialLabel: findNavLabel(navState.fromDate, navState.toDate),
			initialFrom: navState.fromDate,
			initialTo: navState.toDate,
		};
	};
	return (
		<EcommerceContext.Provider
			value={{
				systemServices,
				insightsServices,
				accountId,
				initiNavDate,
				reportTypeSelected,
				handleChangeReportSelect,
				navFilter,
				setNavFilter,
				selectedPeriod,
				setSelectedPeriod,
				selectedTimeGranularity,
				setSelectedTimeGranularity,
				initialDates,
				handleSelectUrl,
				filterUrl,
				setfilterUrl,
				isLoadingData,
				setisLoadingData,
				setNeedRefreshgraph,
				needRefreshGraph,
				needRefreshBoard,
				setNeedRefreshBoard,
				onSelectMetricType,
				metricTypeSelected,
				setisLoadingDatatab,
				isLoadingDatatab,
				handleChangeSourceType,
				sourceType,
				sourceTypes,
				orderBy,
				setOrderBy,
				orderByDesc,
				setOrderByDesc,
				handleSelectUtmDimension,
				utmDimensionsSelected,
				utmSelected,
				hansleSaveUtmDimensionFilter,
				needRefreshForUtmFilter,
				resetUtmSelected,
				isCustomSelected,
				setisCustomSelected,
				customDateRange,
				setCustomDateRange,
				deviceSelected,
				handleChangeDeviceSelected,
				resetSource,
				resetDevices,
				handleChangePageType,
				resetPageType,
				pageTypeSelected,
				handleChangeIntentionSelected,
				intentionSelected,
				resetIntention,
			}}
		>
			{props.children}
		</EcommerceContext.Provider>
	);
};

export default ContextTrafficProvider;
export { useEcommerceContext };
