import React, { useState, useEffect, useCallback, useRef } from 'react';
import OperationCardOnSite from './OperationCardOnSite'
import useStatusOperationGraph from '../../../../Hooks/useStatusOperationGraph'
import formatMoment from '../../../../Constants/FormatMoment';
import Dropdown from '../../../../Components/Dropdown'
import ReactTooltip from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid';
import { onSiteContext } from '../context';
export default function MarkerItem({
    campaign,
    dates,
}) {
    const {
        reloadTheOperationItem
    } = onSiteContext();
    if (!campaign) {
        return <></>
    }
    const { formatDateToLocal } = formatMoment;
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [dropdownClickEvent, setDropdownClickEvent] = useState(false);

	// const [modalIsClicked, setModalIsClicked] = useState(false);
    // const [stateOfOperationToValidate, setStateOfOperationToValidate] = useState();
    // const [dataOfOperationToPut, setDataOfOperationToPut] = useState();
    const nbDays = dates.length;
    const style = {};
    const cssClass = ['splanning_operation_inner'];
    const statusClass = ['splanning_operation_status', 'campaign_status_tag_color'];
    const campaignStartDate = formatDateToLocal(campaign.operation.startDate);
    const campaignEndDate = formatDateToLocal(campaign.operation.endDateForDisplay);
    const periodStartDate = dates[0];
    const periodeEndDate = dates[dates.length - 1];
    const ref = useRef(null);
    const uid = uuidv4();

    let tmpStart = moment(campaignStartDate).startOf('day');
    let tmpEnd = moment(campaignEndDate).add(1, 'days').startOf('day');

    const wantDropdownIsOpen = function (e, state) {
        e.persist();
        setDropdownClickEvent(e);
        setDropdownIsOpen(state);
    }

    if (campaignStartDate < periodStartDate) {
        tmpStart = moment(periodStartDate);
        cssClass.push('is_trunc_left');
    }
    if (campaignEndDate > periodeEndDate) {
        tmpEnd = moment(periodeEndDate).add(1, 'days');
        cssClass.push('is_trunc_right');
    }
    let statusOperation = useStatusOperationGraph(campaign);
    cssClass.push(statusOperation.cssClass);
    statusClass.push(statusOperation.cssClass);
    style.width = tmpEnd.diff(tmpStart, 'days') / nbDays * 100 + '%';
    style.left = tmpStart.diff(periodStartDate, 'days') / nbDays * 100 + '%';

    // let bgColor = campaign.operation.extensions.label ? TagListOperation.find(tag => tag.tagId === campaign.operation.extensions.label).classColor : "simplifyTagGreen"
    // statusClass.push(bgColor);


    return (
        <div className="splanning_operation" id='test'>
            <ReactTooltip
                id={uid}
                className="labelKpi"
                backgroundColor='black'
                effect='solid'
                delayShow={200}
                place="bottom"
                globalEventOff="click" />
            <div
                onClick={(e) => wantDropdownIsOpen(e, true)}
                className={cssClass.join(' ')}
                style={style}
                ref={ref}>

                <div className={statusClass.join(' ')}
                    data-for={uid}
                    data-event-off="click"
                    data-tip={"" + statusOperation.label}></div>

                <div className="splanning_operation_name"
                    data-for={uid}
                    data-event-off="click"
                    data-tip={campaign.name}>
                    {campaign.name} 
                </div>

            </div>
            <Dropdown
                isOpen={dropdownIsOpen}
                clickEvent={dropdownClickEvent}
                posX="mouse"
                setIsOpen={(e) => setDropdownIsOpen(true)}
                onHide={(e) => setDropdownIsOpen(false)}
                buttonRef={ref}
            >
                <OperationCardOnSite
                    isPlanningMode={true}
                    campaign={campaign}
                    setDropdownIsOpen={(e) => setDropdownIsOpen(e)}
                />
            </Dropdown>

            {reloadTheOperationItem &&
                <div className="spinner_overlay">
                    <div className="spinner">
                        <div className="rect1"></div>
                        <div className="rect2"></div>
                        <div className="rect3"></div>
                        <div className="rect4"></div>
                        <div className="rect5"></div>
                    </div>
                </div>
            }
        </div>
    )
}
