import React, { useEffect, useRef, useState } from 'react';
import './ItemFilterCard.css';
import { Flag } from '../../../../Components/Flag/Flag';

export default function ItemFilterCard({
	itemFilter,
	onItemSelected,
	selectedFilterId,
	categoryIcon
}) {

	const refElement = useRef(null);
	const [elementClass, setElementClass] = useState([]);

	useEffect(() => {
		const eClass = ['trigger_store_item dark s flex'];
		if (selectedFilterId === itemFilter.id) {
			eClass.push('selected');
			if (refElement.current) {
				refElement.current.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center'
				});
			}
		}
		setElementClass(eClass);

	}, [itemFilter, selectedFilterId]);

	return (
		<li
			ref={refElement}
			className={elementClass.join(' ')}
			onClick={() =>
				onItemSelected(itemFilter)
			}
		>
			<div className="flex_item_fix">
				<span className={'trigger_icon icon_' + categoryIcon}></span>
			</div>
			<div className="flex_item_full">
				<div className="trigger_store_item_name flex flex_align_center">
					<span>{itemFilter.name}</span>
					{itemFilter.id === '0abb9503-f0a8-4509-9f90-b725f03e1e54' &&
						<Flag
							icon="ai"
							text="AI"
							tooltip="This feature is powered by BEYABLE AI"
							className="ml_8"
						/>
					}
				</div>
			</div>
		</li>
	);
}
