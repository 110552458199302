import React, { useContext } from 'react';
import './StatisticsDashboard.css';
import Btn from './../../../Components/Btn';
import Checkbox from './../../../Components/Checkbox';
export default function StatisticsDashboard({ dataOperationForStats, zoneId, setZoneId }) {

	return (
		<>
			<div>
				<div className="btn_switch">
					<Btn
						message="Total"
						disabled={dataOperationForStats && dataOperationForStats.length === 0 ? true : false}
						onClick={(e) => setZoneId('global')}
						isActive={zoneId && zoneId === 'global'}
						style="outline"
						color="secondary"
					/>
					<Btn
						message="By banner"
						disabled={dataOperationForStats && dataOperationForStats.length === 0 ? true : false}
						onClick={(e) => setZoneId(dataOperationForStats && dataOperationForStats.length !== 0 && dataOperationForStats[0].zoneId)}
						isActive={zoneId && zoneId !== 'global'}
						style="outline"
						color="secondary"
					/>
				</div>
			</div>
			<div className="dashboard-statistics-container-header">
				<div className="dashboard-statistics-head dashboard-statistics-head-checkbox">

				</div>
				<div className="dashboard-statistics-head dashboard-statistics-head-name">
					Zone
				</div>
				<div className="dashboard-statistics-head dashboard-statistics-head-device">
					Device
				</div>
				<div className="dashboard-statistics-head dashboard-statistics-head-banner">
					Image
				</div>
				<div className="dashboard-statistics-head dashboard-statistics-head-stat">
					Impressions
				</div>
				<div className="dashboard-statistics-head dashboard-statistics-head-stat">
					Unique impressions
				</div>
				<div className="dashboard-statistics-head dashboard-statistics-head-stat">
					Clicks
				</div>
				<div className="dashboard-statistics-head dashboard-statistics-head-stat">
					Conversions Post View
				</div>
				<div className="dashboard-statistics-head dashboard-statistics-head-stat">
					Conversions Post Click
				</div>
			</div>
			{dataOperationForStats && dataOperationForStats.length > 0 ? dataOperationForStats.map((zone, i) =>
				<div
					className={zoneId && zoneId === zone.zoneId ? 'dashboard-statistics-container-body is-active-stats-banner' : 'dashboard-statistics-container-body'}
					key={i}
					onClick={(e) => setZoneId(zone.zoneId)}>
					<div className="dashboard-statistics-body dashboard-statistics-body-checkbox">
						<Checkbox name="dashboard-statistics" forcedChecked={zoneId && zoneId === zone.zoneId ? true : false} />
					</div>
					<div className="dashboard-statistics-body dashboard-statistics-body-name">
						<div><strong>{zone.screenshotName}</strong></div>
						<div>{zone.zoneName}</div>
					</div>
					<div className="dashboard-statistics-body dashboard-statistics-body-device">
						{
							zone.device === 'mobile' ? <> <i className="fas fa-mobile-alt" title="Mobile"></i></>
								: <> <i className="fas fa-desktop" title="Desktop"></i></>
						}
					</div>
					<div className="dashboard-statistics-body dashboard-statistics-body-banner" >
						<img src={zone.imagePath ? zone.imagePath : 'https://fakeimg.pl/250x100/ff0000/'} alt="Banner picture" />
					</div>
					<div className="dashboard-statistics-body dashboard-statistics-body-stat">
						{zone.numberOfImpressionsNonUnique}
					</div>
					<div className="dashboard-statistics-body dashboard-statistics-body-stat">
						{zone.numberOfImpressions}
					</div>
					<div className="dashboard-statistics-body dashboard-statistics-body-stat">
						{zone.numberOfClicks}
					</div>
					<div className="dashboard-statistics-body dashboard-statistics-body-stat">
						{zone.numberOfConversions}
					</div>
					<div className="dashboard-statistics-body dashboard-statistics-body-stat">
						{zone.numberOfConversionsPostClick}
					</div>
				</div>
			)
				:
				<div className="nodata">
					<p className="title">No statistics have been found for this operation for the moment, please retry later.</p>
				</div>
			}
		</>
	);
}
