import React, { createContext, useContext, useEffect, useState } from "react";
import { initialDates } from "../components/Nav/config";

const AnalyticsContext = createContext();

function useAnalyticsContext() {
	return useContext(AnalyticsContext);
}

const CreateAnalyticsContextProvider = (props) => {
	const selectedTenant = props.selectedTenant;
	// Tab & utils
	const [activeDimensionTab, setActiveDimensionTab] = useState(
		props.location.$$search.dimension
	);
	const [activeSubDimensionTab, setActiveSubDimensionTab] = useState(
		props.location.$$search.subdimension
	);
	
	const initiNavDate = (navState) => {
		if (!navState || !navState.fromDate || !navState.toDate)
			return initialDates;
		return {
			initialLabel: findNavLabel(navState.fromDate, navState.toDate),
			initialLabel2: findNavLabel(navState.fromDateToCompare, navState.toDateToCompare),
			initialFrom: navState.fromDate,
			initialTo: navState.toDate,
			fromDateToCompare: navState.fromDateToCompare,
			toDateToCompare: navState.toDateToCompare
		};
	};

	const accountId = props.accountId;
	const onChangeNavFilter = (nav) => {
		setNavFilter((currentNavFilter) => ({ ...currentNavFilter, ...nav }));
	};

	const findNavLabel = (fromDate, toDate) => {
		// format initNavState
		const FORMAT = "YYYY/MM/DD";
		var last7days = moment(toDate).subtract(7, "day").format(FORMAT);
		var last30days = moment(toDate).subtract(30, "day").format(FORMAT);

		if (fromDate == toDate) return "Today";
		if (last7days == fromDate) return "Last 7 days";
		if (last30days == fromDate) return "Last 30 days";
		else return "Custom";
	};

	const changeDimensionWithHistory = () => {
		const dim = new URL(location.href).searchParams.get("dimension");
		if (dim) {
			setActiveDimensionTab(dim);
		}
	};

	useEffect(() => {
		window.addEventListener("popstate", changeDimensionWithHistory);
		return () => window.removeEventListener("popstate", changeDimensionWithHistory);
	}, []);

	return (
		<AnalyticsContext.Provider
			value={{
			
				// Tab State
				activeDimensionTab,
				setActiveDimensionTab,
				activeSubDimensionTab,
				setActiveSubDimensionTab,
				initiNavDate,
				onChangeNavFilter,
				selectedTenant,
				
			}}
		>
			{props.children}
		</AnalyticsContext.Provider>
	);
};

export default CreateAnalyticsContextProvider;

export { useAnalyticsContext };
