import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
export default function LabelFilters({
	selectableLabels,
	selectedLabelIds,
	onLabelSelected,
	canEdit,
	onEditRequested,
	isSupervisionPage = false
}) {
	
	const [t, i18n] = useTranslation('common');
	const getClassName = (label) => {
		return selectedLabelIds && !selectedLabelIds.includes(label.id)
			? `campaign_label campaign_label_${label.color.toLowerCase()}`
			: `campaign_label campaign_label_${label.color.toLowerCase()} selected`
	}
	const selectableLabelsRes = selectableLabels && selectableLabels.length > 0 ? t('labels.EditLabels') : t('labels.AddLabels');
	return (
		<ul className="campaign_label_list l">
			{selectableLabels && selectableLabels.map(label =>
				<li key={label.id}>
					<a className={getClassName(label)}
						onClick={() => onLabelSelected(label)}>
						{label.name ? label.name : t('labels.noName')}
						<span className="campaign_label_remove">
							<i className="fas fa-times"></i>
						</span>
					</a>
				</li>
			)}
			{(canEdit) &&
				<li>
					<a className="campaign_label_action" onClick={onEditRequested}>
						{selectableLabelsRes}
					</a>
				</li>
			}
		</ul>
	)
}