import React, {useState} from 'react';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';

export default function HeaderPannelOptions({
	ruleName,
	setRuleName,
	children,
	descriptionRule,
	handleChangeRankingDescription
}) {

	const [showDesc, setShowDesc] = useState(descriptionRule && descriptionRule != '');
	const [autoFocusDesc, setAutoFocusDesc] = useState(false);

	const handleAddDesc = () => {
		setShowDesc(true);
		setAutoFocusDesc(true);
	};

	return (
		<>
			<InputCustom
				label="Name"
				placeHolder="Name"
				value={ruleName}
				fullWidth={true}
				onChange={(e) => {
					setRuleName(e.target.value);
				}}
				labelClassName="s_16"
			/>
			{!showDesc &&
				<div className="collection_sidebar_add_desc">
					<Btn
						onClick={handleAddDesc}
						message="Add description"
						color="grey"
						style="link"
						icon="fas fa-plus-circle"
						size="s"
					/>
				</div>
			}
			{showDesc &&
				<InputCustom
					type="textarea"
					autogrow={true}
					rows={2}
					maxHeight={200}
					label="Description"
					placeHolder="Description"
					value={descriptionRule}
					fullWidth={true}
					onChange={(e) => {
						handleChangeRankingDescription(e.target.value);
					}}
					labelClassName="s_16"
					autoFocus={autoFocusDesc}
				/>
			}
			{children}
		</>
	);
}
