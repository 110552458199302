import React from 'react';
import { useAuditContext } from '../context/ContextAudit';
import { Article } from '../../../Components/Article/Article';
import EmptyState  from '../../../Components/EmptyState';
import Btn from '../../../Components/Btn';
import { useTranslation } from 'react-i18next';

export default function ResultNoScenario() {

	const [t] = useTranslation(['kpi', 'utils']);

	const {
		handleGetAudit
	} = useAuditContext();
   
	return (
		<section className="section">
			<Article>
				<EmptyState
					title={t('audit.analysisProgressError')}
					text={t('audit.analysisProgressErrorDescription')}
					imageUrl="/Assets/empty_scenario_reco.svg"
					textSize="l"
					verticalSize="l"
					width="xl"
					primaryAction={
						<Btn
							message={t('audit.tryAgain')}
							onClick={()=>handleGetAudit()}
							icon="fas fa-sync-alt"
						/>
					}
				/>
			</Article>
		</section>
	);
}
