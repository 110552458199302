
import HttpServices from './HttpServices';
import axios from 'axios';

export default class AccountTenantServices {
	constructor($http, authServices, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
		this.accountId = accountId;
	}

	getTenantsAccount(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/account-tenants`, callbackSuccess, callbackError);
	}
	getTenantsCatalogTenant(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/account-tenants/catalog-tenants`, callbackSuccess, callbackError);
	}
	getTenantsScoreTenant(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/score-tenants`, callbackSuccess, callbackError);
	}

}
