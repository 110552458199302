/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from './Custom.module.css';
import { CampaignsContext } from '../../context/index';
import InPageContainer from '../InPage/InPageContainer';
import {useTranslation} from 'react-i18next';

export default function FormatCampaign({listCampaign}) {

	const { t } = useTranslation(['common']);
	const {
		handleChooseCampaignFormat,
		variations,
		getCurrentVariation,
		isNativeApp
	} = CampaignsContext();


	const [formatSelected, setFormatSelected] = useState<string | null>(null);

	const [formatMessage, setFormatMessage] = useState<string | null>(null);

	const getFormatMessage = (id:string) => {
		const format = listCampaign.find(el => el.id === id);
		return format ? format.message : '';
	};

	useEffect(()=>{
		const currentVariationSaved = getCurrentVariation();
		if(currentVariationSaved && isNativeApp){
			let slideFormatSelected = currentVariationSaved?.SlideFormat;
			if(slideFormatSelected === '' || slideFormatSelected === null){
				slideFormatSelected = 'Overlay';
				handleChooseCampaignFormat(slideFormatSelected);
			}
			setFormatSelected(slideFormatSelected);
		}else{
			const currentVariationSaved = getCurrentVariation();
			if(currentVariationSaved ){
				let slideFormatSelected = currentVariationSaved?.SlideFormat;
				if(slideFormatSelected === '' || slideFormatSelected === null){
					slideFormatSelected = 'Overlay';
					handleChooseCampaignFormat(slideFormatSelected);
				}
				setFormatSelected(slideFormatSelected);
			}
		}
	},[variations,isNativeApp]);

	useEffect(() => {
		if (!formatSelected) return;
		setFormatMessage(getFormatMessage(formatSelected));
	}, [formatSelected]);

	return (
		<>
			<div className='segment_section_inner_l'>
				<ul className={styles.format_list}>
					{listCampaign.map(format => {
						const isSelected: boolean = formatSelected === format.id;
						return (
							<li key={format.id} className={styles.format_item}>
								<a className={isSelected ? styles.format_item_link_active : styles.format_item_link}
									onClick={() => handleChooseCampaignFormat(format.id)}>
									<img src={'/Assets/format/format_'+format.imgId+'.svg'} className={styles.format_item_img} />
									<span className={styles.format_item_label}>{format.label}</span>
								</a>
							</li>
						); 
					})}
				</ul>
				{formatSelected !== 'InPage' && formatMessage &&
					<div className='mt_15 s_13 grey_2'>{t(formatMessage)}</div>
				}
			</div> 
			{formatSelected === 'InPage' && !isNativeApp &&
				<div className={styles.format_inpage_wrapper}>
					<div className='format_param_list no_margin'>
						<InPageContainer
							hasBorder={false}
							hasHover={true}
						/>
					</div>
				</div>
			}
		</>
	);
}
