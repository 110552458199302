import HttpServices from './HttpServices';
import { CreateServerSideCampaignApiType, payloadExample, ServerSideCampaignDetailApiType, ServerSideSaaSFormatApiType, UpdateServerSideCampaignApiType } from './ServerSideCampaignServicesTypes';

export default class ServerSideCampaignServices {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;

	constructor(accountId: string, $http: any) {
		this.HttpServices = new HttpServices($http);
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	getServerSideCampaignList(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/server-side-campaign`, callbackSuccess, callbackError);
	}

	getServerSideCampaignById(campaignId: string,
		callbackSuccess: ((success: ServerSideCampaignDetailApiType) => void),
		callbackError: ((err: any) => void)): void {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/server-side-campaign/${campaignId}`, callbackSuccess, callbackError);
	}

	updateServerSideCampaign(campaignId: string, campaign: UpdateServerSideCampaignApiType,
		callbackSuccess: (() => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/TODO/${campaignId}`, campaign, callbackSuccess, callbackError);
	}

	deleteServerSideCampaign(campaignId: string,
		callbackSuccess: ((success: string) => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.delete(`${this.BO_API_URL}${this.accountId}/TODO/${campaignId}`, callbackSuccess, callbackError);
	}

	createServerSideCampaign(campaign: CreateServerSideCampaignApiType,
		callbackSuccess: (() => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/TODO`, campaign, callbackSuccess, callbackError);
	}

	getServerSideSaaSFormats(
		callbackSuccess: ((serverSideSaaSFormats: ServerSideSaaSFormatApiType[]) => void),
		callbackError: ((error: string) => void)): void {

		//const useFake = Math.random() >= 0;
		const useFake = false;
		if (useFake) {
			callbackSuccess(payloadExample);

		} else {
			this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/server-side-saasformat`, callbackSuccess, callbackError);
		}
	}
}
