import React from 'react'

function TabItem({
    children,
    message = '',
    icon = '',

    onClick,
    href = null,
    target = null,

    selected = false,
    disabled = false,

    hasError = false,
    hasWarning = false,

    className = '',
}) {

    const handleClick = (ev) => {
        if( disabled ) return;

        if (typeof onClick === 'function') {
            onClick(ev);
        }
    };

    let cssClass = ['tab_item'];

    // className
    if (className) {
        cssClass = cssClass.concat(className);
    }

    // Selected
    if (selected) {
        cssClass.push('active');
    }

    // Disabled
    if (disabled) {
        cssClass.push('disabled');
    }

    // Error
    if (hasError) {
        cssClass.push('has_error');
    }

    // Warning
    if (hasWarning) {
        cssClass.push('has_warn');
    }

    return (
        <li>
            <a
                className={cssClass.join(' ')}
                onClick={(e) => handleClick(e)}
                target={target}
                href={href}>
                {icon &&
                    <i className={'tab_item_icon ' + icon}></i>
                }
                {message}
                {children}
            </a>
        </li>
    )
}
export default TabItem;