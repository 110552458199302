import React from 'react';
import {useSegmentGroupEditorContext} from '../context/SegmentListRankingContainerProvider';
import Modal from '../../../../Components/Modal';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import InputCustom from '../../../../Components/InputCustom';
import ModalFooter from '../../../../Components/ModalFooter';
import Btn from '../../../../Components/Btn';
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function ModalExternalAudience() {

	const {
		setaddNewExternalAudience,
		addNewExternalAudience,
		externalAudienceObject,
		handleChangeValueExternalAudience,
		handleSaveExternalAudience,
		setexternalAudienceObject,
		rankingRules,
		handleChooseRuleId,
		hasErrorOnExternalItem
	} = useSegmentGroupEditorContext();

	const listRecoLabels = rankingRules?.map(reco =>{
		return {value: reco.id, label: reco.name};
	});

	const handleCancel = ()=>{
		setaddNewExternalAudience(false);
		setexternalAudienceObject({
			name:'',
			ruleId: ''
		});
	};

	return (
		<>
			{
				<Modal
					isOpen={addNewExternalAudience}
					width={440}
					onClose={()=>setaddNewExternalAudience(false)}
				>
					<ModalHeader title='New association' />
					<ModalBody>
						<InputCustom
							label="Your segment name"
							name="alt"
							placeholder='Ex: Customers in store'
							value={externalAudienceObject.name}
							onChange={(e)=>handleChangeValueExternalAudience('name',e.target.value)}
							fullWidth={true}
							autoFocus={true}
							hasError={hasErrorOnExternalItem}
							errorMessage={'This segment name already exists'}
						/>
						<SelectDropdown
							label="Associated ranking rule"
							value={externalAudienceObject.ruleId}
							onChange={(v) => handleChooseRuleId(v)}
							optionsList={listRecoLabels}
							fullWidth={true}
							placeHolder="Apply a ranking rule"
						/>
					</ModalBody>
					<ModalFooter
						primaryAction={
							<Btn
								onClick={handleSaveExternalAudience}
								message="Ok"
								disabled={!externalAudienceObject || externalAudienceObject.name.length === 0 || externalAudienceObject.ruleId === '' || hasErrorOnExternalItem }
							/>
						}
						secondaryAction={
							<Btn
								onClick={handleCancel}
								message="Cancel"
								color="secondary"
								style="ghost"
							/>
						}
					/>
				</Modal>
			}
		</>
	);
}
