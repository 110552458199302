import React, {useEffect, useState} from 'react';

const Message = ({ msg }) => {

    const [shouldRender, setRender] = useState(!!msg);
    const [tmpMsg, setTmpMsg] = useState();

    const cssClass = ['media_gallery_message'];

    const onAnimationEnd = () => {
        if (!msg) {
            setRender(false);
            setTmpMsg(null);
        }
    };

    useEffect(() => {
        if (!!msg) {
            setRender(true);
            setTmpMsg(msg);
        }
    }, [msg]);

    if (tmpMsg && tmpMsg.type == "success") {
        cssClass.push('is_success');
    }

    if (tmpMsg && tmpMsg.type == "error") {
        cssClass.push('is_error');
    }

    if (!!msg) {
        cssClass.push("anim_show");
    } else {
        cssClass.push("anim_hide");
    }

    if (!shouldRender) return '';

    return (
        <div className={cssClass.join(' ')} onAnimationEnd={onAnimationEnd}>
            {tmpMsg && tmpMsg.text}
        </div>
    );
};

export default Message;