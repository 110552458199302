import React, { useState, useEffect, useContext } from 'react';
import { CreateBusinessOperationContext } from '../../Context/ContextCreateBusinessOperations';
import ThumbnailsVideo from './ThumbnailsVideo';
export default function VideosListContainer({ deleteABanner, openVideoWidget }) {
	const {
		listBanners,
	} = useContext(CreateBusinessOperationContext);

	const [bannerVideo, setBannerVideo] = useState([]);

	useEffect(() => {
		if (listBanners) {
			const newListbanners = listBanners.filter(banner =>
				banner.bannerType === 'VideoPlayer');
			setBannerVideo(newListbanners);
		}
	}, [listBanners]);

	return (
		<>
			{bannerVideo.length > 0 &&
				<div className="designSection">
					<div className="designSectionTitle">Available videos</div>
					<div className="designSectionBody">
						<div className="bannersListWrapper">
							<div className="bannersList">
								{bannerVideo.map(banner =>
									<ThumbnailsVideo
										banner={banner}
										key={banner.bannerId}
										deleteABanner={deleteABanner}
										openVideoWidget={openVideoWidget}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
}
