import React, { useState, useEffect } from 'react';
import Dropdown from '../../../../Components/Dropdown';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';
import { useSegmentGroupsListContext } from '../context/SegmentGroupListContextProvider';
import { useTranslation } from 'react-i18next';
import './Nav.css';

export default function SelectStatus() {

	const {
		statusSelected,
		handleChangeStatus,
		resetStatus,
		statusArray
	} = useSegmentGroupsListContext();
		
	const [t] = useTranslation(['utils', 'segments']);
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const isActive = (statusSelected && statusSelected.length > 0) ? true : false;
	const [btnClass, setbtnClass] = useState(['custom_input', 'clickable', 'w_auto', 'm', 'bg_white']);

	useEffect(()=>{
		if (isActive) {
			setbtnClass(classeElement => ([
				...classeElement, 'highlighted'
			]));
		}else{
			setbtnClass(classeElement => ([
				...classeElement.filter(el => el !== 'highlighted')
			]));
		}
	}, [isActive]);

	function getFriendlyValue (value) {
		if (value === 'Live') {
			return t('active', {ns: 'segments'});
		}
		return t('inactive', {ns: 'segments'});
	}

	function getLabel (){
		if(!statusSelected){
			return t('common.all');
		}
		if(statusSelected.length === 0){
			return t('common.all');
		}
		if(statusSelected.length > 1){
			return getFriendlyValue(statusSelected[0]) + ' +' + (statusSelected.length -1);
		}
		if(statusSelected.length === 1){
			return getFriendlyValue(statusSelected[0]);
		}
	}

	const activeLabel = getLabel();
	const button = (
		<button className={btnClass.join(' ')}>
			<span className="custom_input_value has_label">
				<span className="custom_input_value_label">{t('status', {ns: 'segments'})}</span>
				{statusSelected ?
					<>{activeLabel}</>
					:
					<span className="custom_input_placeholder">{t('common.none')}</span>
				}
			</span>
			{statusSelected && statusSelected.length > 0 && 
				<span className="custom_input_reset"
					onMouseDown={(e) => {e.stopPropagation();}}
					onClick={(e) => {e.stopPropagation(); resetStatus();}}>
					<i className="fas fa-times-circle"></i>
				</span>
			}
			<span className="custom_input_arrow"></span>
		</button>
	);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
			minWidth={180}
		>
			<Listbox>
				<ListboxItem
					onClick={() => {resetStatus(); setDropdownIsOpen(false);}}
					message={<>{t('common.all')} <span className='grey_3 s_13'>({t('common.noFilter')})</span></>}
					selected={!isActive}
					hasSelectedIcon={true}
				/>
				<li className='hr' />
				{statusArray && statusArray.map((value) => 
					<ListboxItem
						key={value.value}
						onClick={() => handleChangeStatus(value.value)}
						message={getFriendlyValue(value.label)}
						selected={statusSelected && statusSelected.includes(value.value)}
						hasRadio={true}
					/>
				)}
			</Listbox>
		</Dropdown>
	);
}

