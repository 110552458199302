import React, { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, flow } from 'lodash';
import { arraysAreEqual } from '../Util';
import EventTrackingServices from '../../../../Services/EventTrackingServices';
export const BusinessOperationOrchestrationContext = createContext();

const BusinessOperationOrchestrationContextProvider = ({
	getZones,
	getPagesTypes,
	getOperationsForZone,
	getOrchestrationForZone,
	createOrUpdateOrchestrationForZone,
	recomputeAllOrchestrations,
	shouldAllowRecomputeAllOrchestrations,
	routeParams,
	children,
	rootScope
}) => {
	const { trackEvent } = EventTrackingServices(rootScope.User);
	const [selectionPagesType, setSelectionPagesType] = useState([]);
	const [selectedPageType, setSelectedPageType] = useState();
	const [editionModalIsOpen, setEditionModalIsOpen] = useState(false);

	const [referenceData, setReferenceData] = useState({ zones: [], pageTypes: [] });

	useEffect(() => {
		getPagesTypes(data => {
			let loadedZones = 0;
			let zonesIndex = {};
			for (let i = 0; i < data.length; i++) {
				const element = data[i];
				getZones(element.pageType, zoneResult => {
					if (zoneResult.status == 'Published') {
						for (let z = 0; z < zoneResult.zones.length; z++) {
							const zone = zoneResult.zones[z];
							zonesIndex[[element.pageType, zone.name, zone.device]] = zone;
						}
						loadedZones++;
						if (loadedZones === data.length) {
							setReferenceData({ zones: zonesIndex, pageTypes: data });
						}
					}
				});
			}
		});
	}, []);

	useEffect(() => {
		if (referenceData.pageTypes.length === 0)
			return;
		const zones = referenceData.zones;
		const getDeviceZone = (d, pageType) => {
			const data = zones[[pageType.pageType, pageType.name, d]];
			return data ? { ...data } : { device: d };
		};

		const selected = referenceData.pageTypes.map((x, i) => ({
			...x,
			id: uuidv4(),
			isSelected: i === 0,
			selectedDevice: x.device[0],
			devices: x.device.reduce((acc, d) => ({ ...acc, [d]: getDeviceZone(d, x) }), {})
		}));
		setSelectionPagesType(selected);
		setSelectedPageType(selected.find(x => x.isSelected));
	}, [referenceData]);

	const handlePageTypeSelected = (pageType) => {
		const selected = selectionPagesType.map(x => ({ ...x, isSelected: pageType.id === x.id }));
		setSelectionPagesType(selected);
		setSelectedPageType(selected.find(x => x.isSelected));
	};

	const handleChangeSelectedDevice = (device) => {
		const change = flow([changeDeviceFunction(device), unselectZoneFunction()]);
		setSelectedPageType(change);
	};

	const changeDeviceFunction = (device) => {
		return p => ({ ...p, selectedDevice: device });
	};
	const changeZoneSelectionFunction = (changeZone) => {
		return p => {
			let pageType = { ...p };
			const selectedScreenshot = pageType.devices[pageType.selectedDevice];
			const zones = selectedScreenshot.zones.map(changeZone);

			const newScreenshot = { ...selectedScreenshot, zones };
			pageType.devices[pageType.selectedDevice] = newScreenshot;

			return pageType;
		};
	};

	const unselectZoneFunction = () => changeZoneSelectionFunction(s => ({ ...s, isSelected: false }));

	const handleZoneClicked = (zone) => {
		const newZone = { ...zone, isSelected: !zone.isSelected };
		const change = changeZoneSelectionFunction(z => zone.zoneId === z.zoneId ? newZone : z);
		setSelectedPageType(change);
		if (newZone.isSelected) {
			handleAfterZoneSelected(newZone);
		} else {
			handleAfterZoneUnselected();
		}
	};

	const handleUnselectZone = () => {
		const change = unselectZoneFunction();
		setSelectedPageType(change);
		handleAfterZoneUnselected();
	};



	// set priorization

	const [operationsInOrchestration, setOperationsInOrchestration] = useState([]);
	const [availableOperations, setAvailableOperations] = useState([]);
	const [orchestrationLoading, setOrchestrationLoading] = useState({
		availableLoaded: false,
		orchestrationLoaded: false
	});
	const [operationsInOrchestrationInitial, setOperationsInOrchestrationInitial] = useState(null);
	const [isReady, setIsReady] = useState(false);
	const [isDirty, setIsDirty] = useState(null);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		refreshIsDirty(operationsInOrchestration);
	}, [operationsInOrchestration]);

	useEffect(() => {
		if (orchestrationLoading.availableLoaded && orchestrationLoading.orchestrationLoaded) {
			const operations = orchestrationLoading.orchestrationLoaded;
			setOperationsInOrchestration(operations);
			const available = orchestrationLoading.availableLoaded;
			const operationIds = operations.map(o => o.operationId);
			setAvailableOperations(available.filter(x => operationIds.indexOf(x.operationId) < 0));
			setOperationsInOrchestrationInitial(cloneDeep(operations));
			setIsReady(true);
		}
	}, [orchestrationLoading]);

	const refreshIsDirty = (newOperations) => {
		const computeIsDirty = (initialOperations, currentOperations) => {
			const initialIds = initialOperations.map(x => x.operationId);
			const currentIds = currentOperations.map(x => x.operationId);
			const sameIds = arraysAreEqual(initialIds, currentIds);
			return !sameIds;
		};
		setIsDirty(computeIsDirty(operationsInOrchestrationInitial || [], newOperations || []));
	};

	const initSavingState = () => {
		setOperationsInOrchestrationInitial(null);
		setIsDirty(null);
		setIsReady(false);
		setOrchestrationLoading({ availableLoaded: false, orchestrationLoaded: false });
	};

	const handleAfterZoneUnselected = () => {
		setOperationsInOrchestration([]);
		setAvailableOperations([]);
	};

	const handleAfterZoneSelected = (selectedZone) => {
		setOrchestrationLoading({ availableLoaded: false, orchestrationLoaded: false });
		setIsReady(false);
		setEditionModalIsOpen(true);
		getOrchestrationForZone(selectedZone.zoneId, data => {
			setOrchestrationLoading(o => ({ ...o, orchestrationLoaded: data.operations }));
		});
		getOperationsForZone(selectedZone.zoneId, data => {
			setOrchestrationLoading(o => ({ ...o, availableLoaded: data }));
		});
	};

	const handlePrioChanged = (newOperations) => {
		setOperationsInOrchestration(newOperations);
	};

	const handleAddOperation = (ope) => {
		setOperationsInOrchestration(operations => [...operations, ope]);
		setAvailableOperations(operations => operations.filter(x => x.operationId != ope.operationId));
	};

	const handleRemoveOperation = (ope) => {
		setOperationsInOrchestration(operations => operations.filter(x => x.operationId != ope.operationId));
		setAvailableOperations(operations => [...operations, ope]);
	};

	const handleCancelPriorization = () => {
		setEditionModalIsOpen(false);
		handleUnselectZone();
		initSavingState();
	};

	const handleSavePriorization = () => {
		setIsSaving(true);
		const selectedZone = selectedPageType.devices[selectedPageType.selectedDevice].zones.find(z => z.isSelected);
		createOrUpdateOrchestrationForZone(selectedZone.zoneId, operationsInOrchestration, res => {
			setEditionModalIsOpen(false);
			trackEvent('businessOperation/save-priorization');
			handleUnselectZone();
			initSavingState();
			setIsSaving(false);
		}, () => {
			setIsSaving(false);
		});
	};


	return (
		<BusinessOperationOrchestrationContext.Provider value={{
			selectionPagesType,
			selectedPageType,
			operationsInOrchestration,
			availableOperations,
			editionModalIsOpen,
			isReady,
			isDirty,
			isSaving,
			handleUnselectZone,
			handleChangeSelectedDevice,
			handlePageTypeSelected,
			handleZoneClicked,
			handlePrioChanged,
			handleCancelPriorization,
			handleSavePriorization,
			handleAddOperation,
			handleRemoveOperation,
			recomputeAllOrchestrations,
			shouldAllowRecomputeAllOrchestrations,
			routeParams
		}}>
			{children}
		</BusinessOperationOrchestrationContext.Provider>
	);

};
export default BusinessOperationOrchestrationContextProvider;