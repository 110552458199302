import React, { useState, useEffect, Fragment } from 'react';
import formatMoment from '../../Constants/FormatMoment';
import Dropdown from '../../Components/Dropdown';
import useCampaignStatus from '../../Hooks/useCampaignStatus';

import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import '../Dashboard/OnSite/UI/CampaignStatus.css';

export default function CampaignStatus({ campaign, setParentDropdownIsOpen, accountId, IpAdressesServices, setModalTestModeIsOpen, changeStatusOfCampaign ,systemServices}) {

	if (!campaign) {
		return <></>;
	}
	const [t, i18n] = useTranslation('reporting');
	const { formatDateToLocal } = formatMoment;
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [waiting, setWaiting] = useState(false);
	const status = useCampaignStatus(campaign,true);



	const getDateLongFormat = (date) => {
		date = moment(date);
		const six_months_ago = moment().subtract(6, 'months');
		const in_six_months = moment().add(6, 'months');

		if (date.isBefore(six_months_ago)) {
			return 'D MMM YYYY';
		}
		if (date.isAfter(in_six_months)) {
			return 'D MMM YYYY';
		}
		return 'D MMM [at] HH:mm';
	};

	const startDateLongFormat = getDateLongFormat(campaign.startDate);
	const startDateLong = formatDateToLocal(campaign.startDate).format(
		startDateLongFormat
	);
	const endDateLongFormat = getDateLongFormat(campaign.endDate);
	const endDateLong = formatDateToLocal(campaign.endDate).format(
		endDateLongFormat
	);

	const changeParentStatus = (newParentStatus) => {
		closeDropdown();

		if( newParentStatus == status.Parent ){
			return;
		}

		if( newParentStatus == 'started' ){
			setModalTestModeIsOpen(true, campaign, 'started');
			return;
		}

		if( newParentStatus == 'testing' ){
			var data = {
				executionMode: 2,
			};
			IpAdressesServices.putTestModeofCampaign(
				accountId,
				campaign.id,
				data,
				(success) => {
					changeStatusOfCampaign(false, campaign);
				},
				(err) => {
					systemServices.showError('An Error occured during the update of campaign ip adresses');
				}
			);
			return;
		}

		if( newParentStatus == 'stopped' ){
			let data = {
				executionMode: 1,
			};
			changeStatusOfCampaign(true, campaign, (successCallback) => {
				IpAdressesServices.putTestModeofCampaign(accountId, campaign.id, data);
			});
			return;
		}
	};

	const closeDropdown = () => {
		setDropdownIsOpen(false);
		if (typeof setParentDropdownIsOpen === 'function') {
			setParentDropdownIsOpen(false);
		}
	};

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={(e) => setDropdownIsOpen(true)}
			onHide={(e) => setDropdownIsOpen(false)}
			button={
				<a className="campaign_status_tag campaign_status_tag_l has_arrow clickable">
					<span className={'campaign_status_tag_color ' + status.Key}></span>
					<span className="campaign_status_tag_name">{t(`${status.Label}`)}</span>
				</a>
			}
		>
			<ul className="listbox">
				<li>
					<a
						className="listbox_item campaign_status_dropdown_header"
						onClick={(e) => {
							closeDropdown();
							setModalTestModeIsOpen(true, campaign);
						}}
					>
						<div className="flex">
							<span
								className={'flex_item_fix campaign_status_tag_color ' + status.Key}
							></span>
							<div className="flex_item_full">
								<div className="s_16 fw_medium">{t(`${status.Label}`)}</div>
								<div className="listbox_item_desc">
									{status.Key == 'testing' && (
										<>
											<div className="c_turquoise mt_3">
												{t('CampaignStatus.setWhoCanSee')}
											</div>
										</>
									)}
									{status.Key == 'ongoing' && (
										<>
											{campaign.endDate ? (
												<>{t('CampaignStatus.willEnd', {dateEnd : endDateLong })}
												</>
											) : (
												<>{t('CampaignStatus.noEnd')}</>
											)}
											{/*<div className="c_turquoise mt_3">Change activation dates</div>*/}
											<div className="c_turquoise mt_3">{t('CampaignStatus.editStartOpt')}
											</div>
										</>
									)}
									{status.Key == 'past' && (
										<>{t('CampaignStatus.hasEnd', {dateEnd : endDateLong })}
											{/*<div className="c_turquoise mt_3">Change activation dates</div>*/}
											<div className="c_turquoise mt_3">{t('CampaignStatus.editStartOpt')}
											</div>
										</>
									)}
									{status.Key == 'planned' && (
										<>{t('CampaignStatus.willStart', {dateEnd : startDateLong })}
											{/*<div className="c_turquoise mt_3">Change activation dates</div>*/}
											<div className="c_turquoise mt_3">{t('CampaignStatus.editStartOpt')}
											</div>
										</>
									)}
									{status.Key == 'stopped' && <>{t('CampaignStatus.isStopped')}</>}
								</div>
							</div>
						</div>
					</a>
				</li>
				<li className="listbox_sep"></li>
				<li>
					<a
						className={
							status.Parent == 'testing' ? 'selected listbox_item' : 'listbox_item'
						}
						onClick={(e) => {
							changeParentStatus('testing');
						}}
					>
						<span className="flex">
							<span className="flex_item_fix campaign_status_tag_color"></span>
							<span className="flex_item_full">{t('CampaignStatus.Draft')}</span>
							{status.Parent == 'testing' && (
								<span className="listbox_item_check">
									<i className="fas fa-check"></i>
								</span>
							)}
						</span>
					</a>
				</li>
				<li>
					<a
						className={
							status.Parent == 'started' ? 'selected listbox_item' : 'listbox_item'
						}
						onClick={(e) => {
							changeParentStatus('started');
						}}
					>
						<span className="flex">
							<span className="flex_item_fix campaign_status_tag_color"></span>
							<span className="flex_item_full">{t('CampaignStatus.StartedVerb')}</span>
							{status.Parent == 'started' && (
								<span className="listbox_item_check">
									<i className="fas fa-check"></i>
								</span>
							)}
						</span>
					</a>
				</li>
				<li>
					<a
						className={
							status.Parent == 'stopped' ? 'selected listbox_item' : 'listbox_item'
						}
						onClick={(e) => {
							changeParentStatus('stopped');
						}}
					>
						<span className="flex">
							<span className="flex_item_fix campaign_status_tag_color"></span>
							<span className="flex_item_full">{t('CampaignStatus.StoppedVerb')}</span>
							{status.Parent == 'stopped' && (
								<span className="listbox_item_check">
									<i className="fas fa-check"></i>
								</span>
							)}
						</span>
					</a>
				</li>
			</ul>
			{waiting && (
				<div className="mask">
					<span className="wheel size_32"></span>
				</div>
			)}
		</Dropdown>
	);
}
