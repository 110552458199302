import React, { useState, useEffect } from 'react'
import Btn from '../../../../Components/Btn';
import Dropdown from '../../../../Components/Dropdown';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';
import Modal from '../../../../Components/Modal';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider'
import ReplacementList from '../ManualSorting/ReplacementList';
export default function ProductItemDropDown({
	idProduct,
	idInClipBoard,
	isATransformationReplace
}) {
	const {
		addPromotedProductWithId,
		addDemotedProductWithId,
		addReplaceProductWithId
	} = useRankingContext();
	const [editDropdownIsOpen, setEditDropdownIsOpen] = useState(false);
	const [modalIsOpen, setmodalIsOpen] = useState(false);
	const [valueOfSelection, setvalueOfSelection] = useState({
		toReplaceId: idProduct,
		replaceId: idInClipBoard
	})

	function openModalWithIdOfProduct() {
		setEditDropdownIsOpen(false);
		setmodalIsOpen(true);
	}
	function handleChangeValueOfSelection(newVal) {
		setvalueOfSelection(newVal)
	};
	function addNewReplaceTranformation() {
		addReplaceProductWithId(valueOfSelection);
		setmodalIsOpen(false);
	}
	useEffect(() => {
		setvalueOfSelection({
			toReplaceId: idProduct,
			replaceId: idInClipBoard
		})
	}, [idInClipBoard]);

	return (<>
		{modalIsOpen &&
			<Modal
				isOpen={modalIsOpen}
				width="700"
				onClose={() => { setmodalIsOpen(false) }}
			>
				<div className="modal_header has_border">
					Choose products to swap
				</div>
				<div className="modal_body">
					<ReplacementList
						valueOfSelection={valueOfSelection}
						addReplaceManualy={handleChangeValueOfSelection}
						isLocked={true}
					/>
				</div>
				<div className="modal_footer">

					<Btn
						onClickFunction={() => setmodalIsOpen(false)}
						message="Cancel"
						color="secondary"
						size="l"
						style="outline"
					/>
					<Btn
						onClickFunction={addNewReplaceTranformation}
						message="Confirm"
						size="l"
						color="primary"
					/>
				</div>
			</Modal>
		}
		<Dropdown
			isOpen={editDropdownIsOpen}
			setIsOpen={(e) => setEditDropdownIsOpen(true)}
			onHide={(e) => setEditDropdownIsOpen(false)}
			button={
				<a className='collection_card_action'>
					<i className="fas fa-ellipsis-v" />
				</a>
			}
		>
			<Listbox>
				<ListboxItem
					message="Promote"
					icon="fas fa-arrow-circle-up"
					onClick={() => {
						addPromotedProductWithId(idProduct);
						setEditDropdownIsOpen(false)
					}}
					disabled={isATransformationReplace}
				/>

				<ListboxItem
					message="Demote"
					icon="fas fa-arrow-circle-down"
					onClick={() => {
						addDemotedProductWithId(idProduct);
						setEditDropdownIsOpen(false)
					}}
					disabled={isATransformationReplace}
				/>

				<ListboxItem
					message="Swap"
					icon="fas fa-retweet"
					onClick={() => openModalWithIdOfProduct()}
				/>
			</Listbox>
		</Dropdown>
	</>
	)
}
