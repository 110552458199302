import React from 'react';
import { InlineWidget } from 'react-calendly';

export default function CalendlySlide() {
	const year = new Date().getFullYear();
	const month = new Date().getMonth() + 1;
	return (
		<>
			<div className="ob_title_2">Let us help!</div>
			<div className="ob_text mb_30">Need a presentation of our solution? Contact us!</div>
			<div className="ob_calendlySlide">
				<InlineWidget
					url={`https://calendly.com/romain-dion-beyable/audit-gratuit?month=${year}-${month}`}
				/>
			</div>
		</>
	);
}
