
const styles = `
	.BY_BLD .by_spacer {
		display: block;
	}
`;

const icon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" viewBox="0 0 40 30">
  <defs>
    <clipPath id="clip-ico_spacer_1">
      <rect width="40" height="30"/>
    </clipPath>
  </defs>
  <g id="ico_spacer_1" data-name="ico_spacer – 1" clip-path="url(#clip-ico_spacer_1)">
    <g id="Polygone_6" data-name="Polygone 6" transform="translate(13 8)" fill="#e9eaf0">
      <path d="M 11.29673957824707 5.000004768371582 L 2.703259944915771 5.000004768371582 L 7 1.317084908485413 L 11.29673957824707 5.000004768371582 Z" stroke="none"/>
      <path d="M 7 2.634154796600342 L 5.406519889831543 3.999994993209839 L 8.593480110168457 3.999994993209839 L 7 2.634154796600342 M 7 0.3170747756958008 C 7.231772422790527 0.3170747756958008 7.463544845581055 0.3973250389099121 7.650790214538574 0.5578250885009766 L 11.94752979278564 4.240744590759277 C 12.65242958068848 4.844944953918457 12.2251501083374 5.99999475479126 11.29673957824707 5.99999475479126 L 2.70326042175293 5.99999475479126 C 1.774849891662598 5.99999475479126 1.347570419311523 4.844944953918457 2.052470207214355 4.240744590759277 L 6.349209785461426 0.5578250885009766 C 6.536455154418945 0.3973250389099121 6.768227577209473 0.3170747756958008 7 0.3170747756958008 Z" stroke="none" fill="#acafc0"/>
    </g>
    <g id="Polygone_7" data-name="Polygone 7" transform="translate(27 22) rotate(180)" fill="#e9eaf0">
      <path d="M 11.29673957824707 5.000004768371582 L 2.703259944915771 5.000004768371582 L 7 1.317084908485413 L 11.29673957824707 5.000004768371582 Z" stroke="none"/>
      <path d="M 7 2.634154796600342 L 5.406519889831543 3.999994993209839 L 8.593480110168457 3.999994993209839 L 7 2.634154796600342 M 7 0.3170747756958008 C 7.231772422790527 0.3170747756958008 7.463544845581055 0.3973250389099121 7.650790214538574 0.5578250885009766 L 11.94752979278564 4.240744590759277 C 12.65242958068848 4.844944953918457 12.2251501083374 5.99999475479126 11.29673957824707 5.99999475479126 L 2.70326042175293 5.99999475479126 C 1.774849891662598 5.99999475479126 1.347570419311523 4.844944953918457 2.052470207214355 4.240744590759277 L 6.349209785461426 0.5578250885009766 C 6.536455154418945 0.3973250389099121 6.768227577209473 0.3170747756958008 7 0.3170747756958008 Z" stroke="none" fill="#acafc0"/>
    </g>
    <path id="Rectangle_1242" data-name="Rectangle 1242" d="M0,0H26a0,0,0,0,1,0,0V3a2,2,0,0,1-2,2H2A2,2,0,0,1,0,3V0A0,0,0,0,1,0,0Z" transform="translate(7 2)" fill="#acafc0"/>
    <rect id="Rectangle_1243" data-name="Rectangle 1243" width="22" height="3" transform="translate(9 2)" fill="#e9eaf0"/>
    <path id="Rectangle_1246" data-name="Rectangle 1246" d="M0,0H26a0,0,0,0,1,0,0V3a2,2,0,0,1-2,2H2A2,2,0,0,1,0,3V0A0,0,0,0,1,0,0Z" transform="translate(33 28) rotate(180)" fill="#acafc0"/>
    <rect id="Rectangle_1247" data-name="Rectangle 1247" width="22" height="3" transform="translate(31 28) rotate(180)" fill="#e9eaf0"/>
  </g>
</svg>
`;

export const componentSpacer = {
	isComponent: el => {
		return el && el.classList && el.classList.contains('by-js-spacer');
	},
	model: {
		defaults: {
			name: 'Spacer',
			droppable: false,
			editable: true,
			layerable: true,
			resizable: {
				cl: false,
				cr: false,
				bl: false,
				br: false,
				bc: true,
				tl: false,
				tr: false,
				tc: true,
				step: 1,
				keyHeight: 'height'
			},
			tagName: 'div',
			attributes: {
				class: 'by_spacer by-js-spacer'
			},
			traits: [
				{
					type: 'responsive',
					name: 'responsive',
					label: 'Responsive',
					changeProp: true,
					tab: 'responsive'
				}
			],
			stylable: ['height'],
			styles: styles,
			style: {
				'height': '16px'
			},
			'script-props': [],
			icon: icon
		},
	}
};
