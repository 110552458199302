import React from 'react'
import SelectCustom from '../../../../Components/SelectCustom'
import InputCustom from '../../../../Components/InputCustom'
export default function ConfigurationFieldsContainer({ metaDataToMap, handleChangeDataFields, dataFields, activeFeed }) {

    const optionList = metaDataToMap && metaDataToMap.map(x => ({ value: x, label: x }))
    optionList && optionList.push({ value: "", label: "" })

    function sortByName(a, b) {
        if (a && b) {
            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
        }
        return 0;
    }

    return (
        <div className="creation_feed_form_container">
            <h2>Help us to map your source fields</h2>
            {activeFeed.source !== "OnSiteData" && activeFeed.source !== "Prestashop" &&
                <>
                    {dataFields && dataFields.sort(sortByName).map((elem, i) =>
                        <SelectCustom
                            key={i}
                            name={elem.name}
                            value={elem.value}
                            label={elem.label}
                            optionsList={optionList}
                            onChangeFunction={(e) => handleChangeDataFields(e)}
                        />
                    )}
                </>
            }
            { activeFeed.source == "OnSiteData" &&
                <>
                    {dataFields && dataFields.sort(sortByName).map((elem, i) =>
                        <SelectCustom
                            key={i}
                            name={elem.name}
                            value={elem.value}
                            label={elem.label}
                            optionsList={[
                                { value: "", label: "" },
                                { value: elem.name, label: elem.name },
                            ]}
                            onChangeFunction={(e) => handleChangeDataFields(e)}
                        />
                    )}
                </>
            }
            { activeFeed.source == "Prestashop" &&
                <>
                    <div>

                        <InputCustom
                            label="Product Image"
                            value={dataFields.filter(name => name.name == "productImg")[0].value}
                            name="productImg"
                            onChange={(e) => handleChangeDataFields(e)}
                            type="text"
                            placeholder="Enter the product Image"
                        />
                        <InputCustom
                            label="Product Url pattern"
                            value={dataFields.filter(name => name.name == "productUrl")[0].value}
                            name="productUrl"
                            onChange={(e) => handleChangeDataFields(e)}
                            type="text"
                            placeholder="Enter the product Url"
                        />
                    </div>
                </>
            }

        </div>
    )
}
