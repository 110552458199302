import HttpServices from './HttpServices';
import {
	SegmentRankingApiType,
	TrafficSegmentationRuleSetup,
	UpdateTrafficSegmentationRuleSetup,
	CreateTrafficSegmentationRuleSetup

} from './SegmentRankingServicesTypes';

export default class SegmentRankingServices {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;

	constructor(accountId, $http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	getSegmentRankingGroups(callbackSuccess: (success: SegmentRankingApiType[]) => void, callbackError: ((error: string) => void)): void {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-segmented-ranking`, callbackSuccess, callbackError);
	}

	getSegmentRankingGroup(rankingGoupId: string, callbackSuccess: ((segmentGroup: SegmentRankingApiType) => void), callbackError: ((error: string) => void)): void {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/${rankingGoupId}/product-segmented-ranking`, callbackSuccess, callbackError);
	}

	createSegmentRankingGroup(rankingSegmentGoup: SegmentRankingApiType, callbackSuccess: ((s: string) => void), callbackError: ((error: string) => void)): void {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/product-segmented-ranking`, rankingSegmentGoup, callbackSuccess, callbackError);
	}
	updateSegmentRankingGroup(rankingSegmentGoup: SegmentRankingApiType, callbackSuccess: ((s: string) => void), callbackError: ((error: string) => void)): void {
		const id = rankingSegmentGoup.id;
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/product-segmented-ranking/${id}`, rankingSegmentGoup, callbackSuccess, callbackError);
	}
	deleteSegmentRankingGroup(rankingGroupId: string, callbackSuccess: (() => void), callbackError: ((error: string) => void)): void {
		this.HttpServices.delete(`${this.BO_API_URL}${this.accountId}/product-segmented-ranking/${rankingGroupId}`, callbackSuccess, callbackError);
	}

	getTrafficSegmentationRuleSetups(callbackSuccess: (rules: TrafficSegmentationRuleSetup[]) => void, callbackError: ((error: string) => void)): void {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/traffic-segmentation-rule`, callbackSuccess, callbackError);
	}
	createTrafficSegmentationRuleSetup(rule: CreateTrafficSegmentationRuleSetup, callbackSuccess: ((s: string) => void), callbackError: ((error: string) => void)): void {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/traffic-segmentation-rule`, rule, callbackSuccess, callbackError);
	}
	updateTrafficSegmentationRuleSetup(ruleId: string, rule: UpdateTrafficSegmentationRuleSetup, callbackSuccess: ((s: string) => void), callbackError: ((error: string) => void)): void {
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/traffic-segmentation-rule/${ruleId}`, rule, callbackSuccess, callbackError);
	}
}

