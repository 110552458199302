import React, { useEffect, useState, useContext, useRef } from 'react';
import { CreateBusinessOperationContext } from '../../Context/ContextCreateBusinessOperations';
import useEventBroker from '../../../../../Hooks/useEventBroker';
import Spinner from '../../../../../Components/Spinner';
import PopinConfirm from '../../../../../Components/PopinConfirm';
import Btn from '../../../../../Components/Btn';

export default function CropContainer({ businessOperationServices, systemServices, accountId, onExported, banner }) {
	const {
		selectedZone,
		operation
	} = useContext(CreateBusinessOperationContext);


	const [cloudinaryWidget, setCloudinaryWidget] = useState();
	const [idOfBanner, setidOfBanner] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [warningPopinIsOpened, setWarningPopinIsOpened] = useState(false);
	const [exportedImage, setExportedImage] = useState();
	const [exportedImageConfirm, setExportedImageConfirm] = useState(false);

	const refElement = useRef(null);
	const { publish } = useEventBroker();

	const zoneWidth = selectedZone.extensions.slideWidth || selectedZone.extensions.width;
	const zoneHeight = selectedZone.extensions.slideHeight || selectedZone.extensions.height;


	useEffect(() => {
		setCloudinaryWidget(window.cloudinary.mediaEditor({ appendTo: '#cropSize' }));
	}, []);

	useEffect(() => {
		if (!exportedImage ||!selectedZone)
			return;
        
		const  { width } = exportedImage;

		if (width < zoneWidth * 0.9){
			setWarningPopinIsOpened(true);
		}
		else{
			setExportedImageConfirm(true);
		}
	}, [exportedImage]);

	useEffect(() => {
		if (!exportedImage || !exportedImageConfirm)
			return;
        
		exportImage(exportedImage);
        
	}, [exportedImageConfirm]);

	const cancelExportedImage = () => {        
		setExportedImage();
		setExportedImageConfirm(false);
		setIsLoading(false);
		setWarningPopinIsOpened(false);
		widgetStart(idOfBanner, zoneWidth, zoneHeight);
		cloudinaryWidget.show();
	};

	useEffect(() => {
		if (banner) {
			setidOfBanner();
			if (typeof banner.metaData.sourceId === 'string') {
				setidOfBanner(banner.metaData.sourceId);
			} else if (typeof banner.metaData.transformationSourceId === 'string') {
				setidOfBanner(banner.metaData.transformationSourceId);
			}
		}
	}, [banner]);

	useEffect(() => {
		if (cloudinaryWidget && idOfBanner) {
			widgetStart(idOfBanner, zoneWidth, zoneHeight);
			cloudinaryWidget.show();
			cloudinaryWidget.on('export', function (data) {
				if (!data) {
					return;
				}
				setIsLoading(true);
				const url = data.assets[0].secureUrl;
				var img = new Image();
				img.onload = function () {
					setExportedImage({ data:data, url:url, height:this.height, width:this.width });
				};
				img.src = url;
			});
		}
	}, [idOfBanner]);

	useEffect(() => {
		if (cloudinaryWidget && idOfBanner) {
			widgetStart(idOfBanner, zoneWidth, zoneHeight);
			cloudinaryWidget.show();
			cloudinaryWidget.on('export', function (data) {
				if (!data) {
					return;
				}
				setIsLoading(true);
				const url = data.assets[0].secureUrl;
				var img = new Image();
				img.onload = function () {
					setExportedImage({ data:data, url:url, height:this.height, width:this.width });
				};
				img.src = url;
			});
		}
	}, [idOfBanner]);

	useEffect(() => {
		if (cloudinaryWidget && idOfBanner) {
			widgetStart(idOfBanner, zoneWidth, zoneHeight);
			cloudinaryWidget.show();
			cloudinaryWidget.on('export', function (data) {
				if (!data) {
					return;
				}
				setIsLoading(true);
				const url = data.assets[0].secureUrl;
				var img = new Image();
				img.onload = function () {
					setExportedImage({ data:data, url:url, height:this.height, width:this.width });
				};
				img.src = url;
			});
		}
	}, [idOfBanner]);

	const widgetStart = (id, width, height) => {
		const customRatio = {
			label: 'Selected Zone',
			width: width,
			height: height,
		};
		cloudinaryWidget.update({
			publicIds: [id],
			cloudName: 'beyable',
			mode: 'inline',
			image: {
				steps: [
					'resizeAndCrop',
					'export'
				],
				resizeAndCrop: {
					toggleAspectRatio: false,
					aspectRatioLock: true,
					flip: false,
					rotate: true,
					presets: [
						customRatio
					]
				},
				export: {
					quality: [
						'auto',
						'best',
						'good',
						'eco',
					],
					formats: [
						'jpg',
						'png',
						'webp'
					],
					download: false,
					share: false
				}
			},
		});
	};

	const exportImage = (exportedImage) => {
		const  { data, url, height, width } = exportedImage;
        
		fetch(url).
			then(function (response) {
				return response.blob();
			})
			.then(function (myBlob) {
				const bytes = myBlob.size;
				const imageTransform = {
					url: data.assets[0].secureUrl,
					bytes: bytes,
					fileName: banner.metaData.fileName,
					height: height,
					width: width,
					sourceId: null,
					transformationSourceId: idOfBanner
				};

				const libraryId = operation.operation.libraryId;
				businessOperationServices.postTransformationsbannerToLibrary(accountId, libraryId, [imageTransform],
					response => {
						const bannerJustCreated = response.createdBanners[0];
						setIsLoading(false);
						if (bannerJustCreated) {
							setTimeout(() => {
								publish('evt/businessoperations/bannerCropped', { newBannerList: response.banners, croppedBanner: bannerJustCreated });
							}, 200);
						} else {
							systemServices.showError('An error occured while adding your image to the library');
						}
						onExported();
						cloudinaryWidget.destroy();
					},
					error => {
						setIsLoading(false);
						systemServices.showError('An error occured while adding your image to the library');
						onExported();
						cloudinaryWidget.destroy();
					});
			});
	};

	return (
		<div ref={refElement}>
			{warningPopinIsOpened &&
				<PopinConfirm
					cancel={cancelExportedImage}
					title={'Warning: the new size of the image is small regarding the selected zone.'}
					message={'This can result in poor rendering. Are you sure you want to use this image?'}
				>
					<Btn
						onClickFunction={cancelExportedImage}
						message="Cancel"
						color="secondary"
						style="outline"
					/>
					<Btn
						onClickFunction={(e) => { setExportedImageConfirm(true); setWarningPopinIsOpened(false); }}
						message="Confirm"
						icon="fas fa-check"
					/>
				</PopinConfirm>
			}

			{isLoading &&
                <Spinner />
			}
		</div>
	);
}
