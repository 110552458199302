import React, { useContext, useState, useEffect } from 'react';
import './OperationItems.css';
import Spinner from './../../../Components/Spinner';
import PopinConfirm from './../../../Components/PopinConfirm';
import Btn from './../../../Components/Btn';
import Modal from './../../../Components/Modal';
import OperationCard from './OperationCard';

export default function OperationItems({
	isAdmin,
	operationsAccount,
	setReload,
	setReloadTheOperationItem,
	reloadTheOperationItem,
	routeParams,
	noDataForTheSearch,
	deleteOperationById,
	executeStartCapture,
	accountId,
	putOperationExecutionStatusMode,
	dataOfOperationToPut,
	setDataOfOperationToPut,
	permissions,
	userSimplifyRole,
	valueInSearchBar,
	labelFilters,
	loadOperationStatistics
}) {

	const [poppinIsOppen, setPoppinIsOppen] = useState(false);
	const [idToDelete, setIdToDelete] = useState();
	const [dataIsUpload, setDataIsUpload] = useState(true);
	const [isRealoading, setIsReloading] = useState(false);
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();
	const today = moment();
	const [modalIsClicked, setModalIsClicked] = useState(false);
	const [stateOfOperationToValidate, setStateOfOperationToValidate] = useState();
	const [dataToMap, setDataToMap] = useState();

	useEffect(() => {
		if (!dataFiltered) {
			setDataToMap(operationsAccount);
		} else {
			setDataToMap(dataFiltered);
		}
	}, [operationsAccount, dataFiltered]);

	const changeStateOfTheCurrentOperation = (e) => {
		if (e === 'testMode') {
			putOperationExecutionStatusMode({
				idOp: dataOfOperationToPut.operation.operationId,
				status: false,
				executionMode: 2
			});
			setReloadTheOperationItem(true);
		}
		if (e === 'stopped') {
			putOperationExecutionStatusMode({
				idOp: dataOfOperationToPut.operation.operationId,
				status: true,
				executionMode: 1
			});
			setReloadTheOperationItem(true);
		}
		if (e === 'ongoing') {
			putOperationExecutionStatusMode({
				idOp: dataOfOperationToPut.operation.operationId,
				status: false,
				executionMode: 1
			});
			setReloadTheOperationItem(true);
		}
		setModalIsClicked(false);
	};
	const pauseTheOperation = (op) => {
		putOperationExecutionStatusMode({
			idOp: op.operation.operationId,
			status: true,
			executionMode: 1
		});
		setReloadTheOperationItem(true);
	};
	useEffect(() => {
		if (operationsAccount) {
			setDataIsUpload(false);
		} else {
			setDataIsUpload(true);
		}
		$('[title]').tooltip();
	}, [operationsAccount]);
	const getInformationForOperations = (e) => {
		window.location.assign(routeParams && routeParams.ka ? `/BusinessOperations/Create?ka=${routeParams.ka}&ku=${routeParams.ku}&id=${e}` : `/BusinessOperations/Create?id=${e}`);
	};

	const search = (table, word, labelFilters) => {
		if (table && (word.length >= 0 || labelFilters.length >= 0)) {
			setDataFiltered([]);
			let results = table.filter(
				line => {
					let wordMatched = true;
					if (word.length > 0) {
						const matchName = line.operation.name.toUpperCase().match(`.*${word.toUpperCase()}.*`);
						const matchDesc = line.operation.description.toUpperCase().match(`.*${word.toUpperCase()}.*`);
						wordMatched = matchName || matchDesc;
					}

					let labelMatched = true;
					if (labelFilters.length > 0) {
						labelMatched = line.operation.operationLabels.some(l => labelFilters.includes(l.id));
					}
					return wordMatched && labelMatched;
				}
			);
			let finalArray = [...results];
			let uniqueItem = finalArray;
			if (uniqueItem.length === 0) {
				uniqueItem = 'No operation found for this search';
				setNoData(uniqueItem);
			}
			else {
				setDataFiltered(uniqueItem);
				setNoData();
			}

		} else {
			setDataFiltered();
		}
	};

	const deleteTheOperation = (event) => {
		setIsReloading(true);
		deleteOperationById(accountId, event,
			e => {
				setIdToDelete();
				setReload(true);
				setIsReloading(false);
			},
			e => {
				//.log("fail: ", e)
			});
	};

	const startCapture = (op) => {
		const id = op.operation.operationId;
		executeStartCapture(accountId, id);
	};

	useEffect(() => {
		if (operationsAccount) {
			search(operationsAccount, valueInSearchBar, labelFilters || []);
		}
	}, [valueInSearchBar, labelFilters, operationsAccount]);

	return (
		<>
			{modalIsClicked &&
				<Modal
					isOpen={modalIsClicked}
					width="550"
					height="auto"
					minHeight="196"
					onClose={() => setModalIsClicked(false)}
				>
					<div className="modal_header has_border">
						<div>Starting options</div>
					</div>
					<div className="modal_body">
						<article className="radio_box_inner flex_justify_center">
							<label className={stateOfOperationToValidate === 'testMode' ? 'radio_box s checked' : 'radio_box s '}
								onClick={(e) => setStateOfOperationToValidate('testMode')}
							>
								<input
									type="radio"
									value="TEST_MODE"
									className=""
									name="31"

								/>
								<span><i className="fa fa-check"></i></span>
								<i className="fa fa-vial"></i>
								<p>Test</p>
							</label>
							<label
								className={stateOfOperationToValidate === 'stopped' ? 'radio_box s checked' : 'radio_box s'}
								onClick={(e) => setStateOfOperationToValidate('stopped')}
							>
								<input
									type="radio"
									value="STOPPED"
									name="32"
								/>
								<span><i className="fa fa-check"></i></span>
								<i className="fa fa-pause"></i>
								<p>Stopped</p>
							</label>
							<label
								className={stateOfOperationToValidate === 'ongoing' ? 'radio_box s checked' : 'radio_box s'}
								onClick={(e) => setStateOfOperationToValidate('ongoing')}
							>
								<input type="radio"
									value="ONGOING"
									name="33"
								/>
								<span><i className="fa fa-check"></i></span>
								<i className="fa fa-play"></i>
								<p>On-going</p>
							</label>
						</article>
						<div className="starting_options_message al_center">
							{stateOfOperationToValidate === 'stopped' &&
								'This campaign will be stopped, no one will be able to see it.'
							}
							{stateOfOperationToValidate === 'ongoing' &&
								'This campaign will be ongoing, every one will be able to see it.'
							}
							{stateOfOperationToValidate === 'testMode' &&
								'This campaign will be on test mode'
							}
						</div>
					</div>
					<div className="modal_footer modal_footer_center">
						<Btn
							onClickFunction={(e) => { setModalIsClicked(false); }}
							message="Cancel"
							style="outline"
							color="secondary"
							size="l"
						/>
						<Btn
							onClickFunction={(e) => changeStateOfTheCurrentOperation(stateOfOperationToValidate)}
							message="Confirm"
							icon="fas fa-check"
							size="l"
						/>
					</div>
				</Modal>
			}

			{poppinIsOppen &&
				<PopinConfirm
					cancel={() => setPoppinIsOppen(false)}
					title={'Are you sure you want to delete the operation ?'}
					message={''}
				>
					<Btn
						onClickFunction={(e) => { setPoppinIsOppen(false); }}
						message="Cancel"
						color="secondary"
						style="outline"
					/>
					<Btn
						onClickFunction={(e) => { deleteTheOperation(idToDelete); setPoppinIsOppen(false); }}
						message="Delete"
						color="alert"
						icon="fas fa-trash-alt"
					/>
				</PopinConfirm>
			}
			{noDataForTheSearch &&
				<div className="empty_page">
					<div className="empty_page_title">
						No operation for the chosen period
					</div>
					<div className="empty_page_text">
						Please try with another period
					</div>
				</div>
			}
			{
				dataIsUpload && !noDataForTheSearch ?
					<Spinner />
					:
					<>
						{!noDataForTheSearch &&
							<>
								{noData ?
									<div className="noData">
										{noData}
									</div>
									:
									(<div className="operations-container">
										{
											dataToMap && dataToMap.map((op, i) =>
												<OperationCard
													key={i}
													isAdmin={isAdmin}
													routeParams={routeParams}
													accountId={accountId}
													op={op}
													reloadTheOperationItem={reloadTheOperationItem}
													onStartCapture={() => startCapture(op)}
													pauseTheOperation={(e) => pauseTheOperation(e)}
													setModalIsClicked={(e) => setModalIsClicked(e)}
													setStateOfOperationToValidate={(e) => setStateOfOperationToValidate(e)}
													setDataOfOperationToPut={(e) => setDataOfOperationToPut(e)}
													setIdToDelete={(e) => setIdToDelete(e)}
													setPoppinIsOppen={(e) => setPoppinIsOppen(e)}
													idOfOperationPut={dataOfOperationToPut && dataOfOperationToPut.operation.operationId}
													permissions={permissions}
													userSimplifyRole={userSimplifyRole}
													loadOperationStatistics={loadOperationStatistics}
												/>
											)
										}
										{
											isRealoading &&
											<div className="spinner_overlay">
												<div className="spinner">
													<div className="rect1"></div>
													<div className="rect2"></div>
													<div className="rect3"></div>
													<div className="rect4"></div>
													<div className="rect5"></div>
												</div>
											</div>
										}
									</div>)
								}
							</>
						}
					</>

			}

		</>
	);
}
