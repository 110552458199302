import HttpServices from './HttpServices';

export default class AbTestsServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`; //devonly/
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
		this.accountId = accountId;
	}

	getAllPendingCampaigns(skipIdNumber,filters,callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/abtest-campaign?pending=true&archived=false&offset=${skipIdNumber}&limit=10`,filters, callbackSuccess, callbackError);
	}

	getAllCompletedCampaigns(skipIdNumber,filters,callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}${this.accountId}/abtest-campaign?pending=false&archived=true&offset=${skipIdNumber}&limit=10`, {},callbackSuccess, callbackError);
	}
	
	getOneCampaign(campaignId,objective, callbackSuccess, callbackError) { 
		let indicator = '';

		if(objective !== undefined){
			indicator = '?indicator=' + objective;
		}
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/abtest-campaign/${campaignId}/${indicator}`, callbackSuccess, callbackError);
	}	
	getCount( callbackSuccess, callbackError,signal) { 
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/abtest-campaign/count/`, callbackSuccess, callbackError,null,signal);
	}	
}