import React from 'react';
import Modal from '../../Components/Modal';
import { ReportGeneratorContextProvider } from './Context';
import ReportGeneratorSteps from './ReportGeneratorSteps';
import './ReportGeneratorModal.css';

export default function ReportGeneratorModal({
	systemServices,
	accountId,
	isOpen,
	onClose,
	$http,
	AuthServices,
	$routeParams,
	$rootScope,
	filterCampaigns,
	needFetchList,
	setneedFetchList,
	client,
	labelServices,
	filterStatusMode,
	filterTestMode,
	searchBarvalue,
	filterBylabels,
	devices,
	setLoadingCampaign,
}) {

	return (
		<ReportGeneratorContextProvider
			accountId={accountId}
			systemServices={systemServices}
			$http={$http}
			AuthServices={AuthServices}
			$routeParams={$routeParams}
			$rootScope={$rootScope}
			needFetchList={needFetchList}
			setneedFetchList={setneedFetchList}
			client={client}
			labelServices={labelServices}
			filterStatusMode={filterStatusMode}
			filterTestMode={filterTestMode}
			searchBarvalue={searchBarvalue}
			filterBylabels={filterBylabels}
			devices={devices}
			setLoadingCampaig={setLoadingCampaign}
		>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				height={900}
				width={1200}
			>
				<ReportGeneratorSteps filterCampaigns={filterCampaigns} />
			</Modal>
		</ReportGeneratorContextProvider>
	);

}