import React, { Fragment, useEffect } from 'react';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';
import NumericUpDown from '../../../Components/NumericUpDown';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

export default function TriggerGeoloc({
    triggerValue,
    setTriggerValue
}) {
    
    const addNewAddressToArray = () => {
      if (triggerValue) {
        let newAddressValue = [...triggerValue]
        newAddressValue.push({Address: ""})
        setTriggerValue(newAddressValue)
      } else {
        let newAddressValue = []
        newAddressValue.push({Address: ""})
        setTriggerValue(newAddressValue)
      }
    }

    useEffect(() => {
      if (!triggerValue) {
        addNewAddressToArray();
      }
    }, []);

    const deleteAddressToTrigger = (i) => {
      let newTriggerValue = [...triggerValue]
      newTriggerValue.splice(i, 1)
      setTriggerValue(newTriggerValue)   
  }

    const onChangeAddress = (newValue, i) => {
        let newAddressValue = [...triggerValue]
        newAddressValue[i] = newValue
        setTriggerValue(newAddressValue)
    }

    const setTheLocationRange = (newValue, i) => {
        let newAddressValue = [...triggerValue]
        newAddressValue[i] = {...newAddressValue[i], LocationRange: newValue}
        setTriggerValue(newAddressValue)

    }

    const handleSelect = (address, i) => {
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => onChangeAddress({Address : address, Latitude: latLng.lat, Longitude: latLng.lng, LocationRange: triggerValue[i].LocationRange}, i))
          .catch(error => console.error('Error', error));
      };

      const handleChange = (address, i) => {
        onChangeAddress({Address : address, Latitude : triggerValue[i].Latitude, Longitude: triggerValue[i].Longitude, LocationRange: triggerValue[i].LocationRange}, i )
      }

      const searchOptions = {
        types: ['(cities)']
      }

    const getSuggestionsPlaces = ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
        <input
          {...getInputProps({
            placeholder: 'Search Places ...',
            className: 'location-search-input',
          })}
        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {suggestions.map((suggestion, index) => {
            const className = suggestion.active
              ? 'suggestion-item--active'
              : 'suggestion-item';
            // inline style for demonstration purpose
            const style = suggestion.active
              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
              : { backgroundColor: '#ffffff', cursor: 'pointer' };

            return (
              <div
                {...getSuggestionItemProps(suggestion, {
                  className,
                  style,
                })}
                key={index}
              >
                <span>{suggestion.description}</span>
              </div>
            );
          })}
        </div>
      </div>
   )

      return(
        
        <Fragment>
            {triggerValue &&
              <ul className="mb_10">
              {triggerValue.map((a, i) =>
                <li key={i} className="flex mt_8">
                  <div className="flex_item_full pos_rel">
                    <PlacesAutocomplete
                        value={a.Address}
                        onChange={(newValue)=> handleChange(newValue, i)}
                        onSelect={(address)=> handleSelect(address, i)}
                        searchOptions={searchOptions}
                        debounce={750}
                        key={i}
                    >
                         {getSuggestionsPlaces}
                    </PlacesAutocomplete>
                  </div>
                  <div className="flex_item_fix ml_10">
                    <InputCustom
                      type="number"
                      value={triggerValue[i].LocationRange ? triggerValue[i].LocationRange : 0}
                      onChange={(e) => setTheLocationRange(e.target.value, i)}
                      min={0}
                      unit="km"
                      className="w_xs"
                      selectOnFocus={true}
                    />
                  </div>
                  <div className="flex_item_fix ml_10">
                    <a className="icon_btn s"
                       onClick={() => deleteAddressToTrigger(i)}>
                      <i className="fas fa-trash-alt"></i>
                    </a>
                  </div>
                </li>
            )}
            </ul>
          }
          <Btn
              onClickFunction={() => addNewAddressToArray()}
              icon="fas fa-plus"
              message="Add place"
              style="outline"
          />
        </Fragment>
      )


}
