import React, { useEffect, useState, Fragment } from 'react';
import { react2angular } from 'react2angular';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import IpAdressesContainer from './IpAdressesContainer';
import IpAdressesServices from '../../Services/IpAdressesServices';
import SystemServices from '../../Services/SystemServices';
import AccountServices from '../../Services/AccountServices';
import InputCopyToClipBoard from './../../Components/InputCopyToClipBoard';
import {Article} from './../../Components/Article/Article';
import EmptyState from './../../Components/EmptyState';
import ProductFeedContainers from './../Tooling/ProductFeed/ProductFeedContainers';
import UsersAdministrationContainer from '../UsersAdministration/UsersAdministrationContainer';
import InstallationTag from '../../Constants/InstallationTags';
import AnalyticsConfigurationContainer from './AnalyticsConfiguration/AnalyticsConfigurationContainer';
import RouterItems from '../Menu/RouterItems';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import account_home_en from '../../Translation/jsonfiles/fr/account_home_fr.json';
import account_home_fr from '../../Translation/jsonfiles/fr/account_home_fr.json';
export default function AccountHomeContainer(props) {
	const i18nL = localStorage.getItem('i18nL');
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const accountName = props.$rootScope.User.Account.Name;
	const IpAdressesService = new IpAdressesServices(props.$http);
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const accountServices = new AccountServices(props.$http);
	const getAccountData = (accountId, callbackSuccess, callbackError) => accountServices.getAccount(accountId, callbackSuccess, error => systemServices.showError('an error occured'));

	const { analyticsTag } = InstallationTag;

	const putIpAdresses = (accountId, arrayOfIPAdresses, callbackSuccess, callbackError) => IpAdressesService.putIpAdressesAccount(accountId, arrayOfIPAdresses, success => {
		systemServices.showSuccess('Changes saved'); callbackSuccess(success);
	}, error => systemServices.showError('An error occured'));
	const [clipBoardContain, setClipBoardContain] = useState(false);
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
	};

	const ipAddressesTab = 4;
	const usersAdminTab = 8;
	const beyableKeyTab = 3;
	const productFeedTab = 9;
	const analyticsTab = 5;

	const [accountCurrentTab, setAccountCurrentTab] = useState(beyableKeyTab);
	const [clientKey, setClientKey] = useState();
	const [testModeIpAddressesArray, setTestModeIpAddressesArray] = useState();
	const [getNewList, setGetNewList] = useState(false);
	const [currentIP, setCurrentIP] = useState();
	const [analyticsScript, setAnalyticsScript] = useState({ loading: true, script: '' });
	const [subTab, setSubTab] = useState('tenants');

	useEffect(() => {
		getAccountData(accountId,
			newData => {
				let arrayIp = newData.TestModeIpAddresses.map((x, i) => ({ ...x, index: i }));
				setTestModeIpAddressesArray(arrayIp);
			});
	}, [getNewList]);

	useEffect(() => {
		getAccountData(accountId,
			newData => {
				setClientKey(newData.ClientKey);
				setAnalyticsScript({ loading: false, script: analyticsTag(newData.ClientKey) });
			});
	}, []);

	// Get current IP address
	fetch('https://backoffice.apiv2.beyable.com/api/ip-lookup')
		.then(response => response.json())
		.then(data => setCurrentIP(data.ipAddress));

	const ChangeTheNavigationSelection = (e) => {
		setAccountCurrentTab(e);
	};
	const permissions = props.$rootScope.$$childHead.permissions;
	const isAnAdmin = props.$rootScope.$$childHead.IsAnAdmin();
	const isAnOrganizationAdmin = props.$rootScope.$$childHead.IsAnOrganizationAdmin();
	const isOrganizationAdminOnItsMainAccount = props.$rootScope.$$childHead.IsAnOrganizationAdmin() && props.$rootScope.User.Account.Key === accountId;
	const canViewUsersTab = isAnAdmin || isOrganizationAdminOnItsMainAccount || permissions.ACCOUNT_USERS_CAN_SET;
	const canManageAnaytics = isAnAdmin || permissions.WEBANALYTICS_CAN_MANAGE;

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const tab = queryParams.get('tab');
		if (!tab) return;
		if (tab === 'webanalytics') {
			setAccountCurrentTab(analyticsTab);
			setSubTab('settings');
		}
	}, []);
	const newInstanceI18nextHome = i18next.createInstance();
	newInstanceI18nextHome.init({
		lng: 'en-EN',
		resources: {
			'en-US': {
				common: account_home_en
			},
			'en-EN': {
				common: account_home_en
			},
			'fr-FR': {
				common: account_home_fr
			},
		}
	},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextHome.changeLanguage(JSON.parse(i18nL));
	});
	const usersTab = () =>
		canViewUsersTab ?
			<UsersAdministrationContainer
				props={{
					...props,
					accountCurrent: accountCurrentTab,
					isAnAdmin,
					isAnOrganizationAdmin,
					isOrganizationAdminOnItsMainAccount,
					permissions
				}}
				reactRender={true}
			/>
			:
			<>
				<EmptyState
					title="You are not allowed to access this page."
					text={
						<>
							User management allows to add or invite users.<br />
							Please contact us (support@beyable.com) or talk with your CSM to access this feature.
						</>
					}
				/>
				<div className="empty_page">
					<div className="empty_page_title">
						You are not allowed to access this page.
					</div>
					<div className="empty_page_text">
						User management allows to add or invite users.<br />
						Please contact us (support@beyable.com) or talk with your CSM to access this feature.
					</div>
				</div>
				
			</>
	;


	const titleIP = i18nL.includes('fr') ? 'Adresses IP' : 'IP addresses';
	const TitleUsers = i18nL.includes('fr') ? 'Gérer la liste des utilisateurs' : 'Manage user list';
	const TabUsers = i18nL.includes('fr') ? 'Utilisateurs' : 'Users';
	const TitleKey = i18nL.includes('fr') ? 'Clé BEYABLE' : 'BEYABLE Key';
	const SubTitleKey = i18nL.includes('fr') ? 'Votre clé BEYABLE' : 'Your BEYABLE key';
	const DescriptionKey = i18nL.includes('fr') ? 'La clé BEYABLE est l\'identifiant unique qui permet d\'authentifier les requêtes associées à votre compte.' : 'The BEYABLE key is the unique identifier that allows you to authenticate requests associated with your account.';
	const TitleYouAreNot = i18nL.includes('fr') ? 'Vous n\'êtes pas autorisé à accéder à cette page.' : 'You are not allowed to access this page.';
	const TitleFeed = i18nL.includes('fr') ? 'Le flux de produits permet de visualiser et de configurer les produits importés.' : 'Product Feed allows to view and configure imported products.';
	const TitleContactUs = i18nL.includes('fr') ? 'Veuillez nous contacter (support@beyable.com) ou vous adresser à votre CSM pour accéder à cette fonction.' : 'Please contact us (support@beyable.com) or talk with your CSM to access this feature.';
	const TitleInstall = i18nL.includes('fr') ? 'Script d\'installation' : 'Installation script';
	const TitleTenants = i18nL.includes('fr') ? 'Tenants' : 'Tenants';
	const TitleAnalyticsSettings = i18nL.includes('fr') ? 'Configuration' : 'Configuration';

	const getPageTitle = () => {
		switch (accountCurrentTab) {
			case beyableKeyTab: return TitleKey;
			case ipAddressesTab: return titleIP;
			case usersAdminTab: return TitleUsers;
			case analyticsTab: return 'Analytics';
		}
	};

	const getPageSubTitle = () => {
		if (accountCurrentTab !== analyticsTab) return '';

		switch (subTab) {
			case 'install': return TitleInstall;
			case 'settings': return TitleAnalyticsSettings;
			case 'tenants': return TitleTenants;
		}
	};

	let routerList = [];
	routerList.push({
		label : TitleKey,
		icon: 'key',
		onClick: () => ChangeTheNavigationSelection(beyableKeyTab),
		isActive: accountCurrentTab === beyableKeyTab
	});
	if (canViewUsersTab) {
		routerList.push({
			label : TabUsers,
			icon: 'user',
			onClick: () => ChangeTheNavigationSelection(usersAdminTab),
			isActive: accountCurrentTab === usersAdminTab
		});
	}
	routerList.push({
		label : titleIP,
		icon: 'ip',
		onClick: () => ChangeTheNavigationSelection(ipAddressesTab),
		isActive: accountCurrentTab === ipAddressesTab
	});

	const analyticsSubList = [];
	analyticsSubList.push({
		label: TitleInstall,
		onClick: () => {ChangeTheNavigationSelection(analyticsTab); setSubTab('install');},
		isActive: accountCurrentTab === analyticsTab && subTab === 'install'
	});
	if (canManageAnaytics) {
		analyticsSubList.push({
			label: TitleAnalyticsSettings,
			onClick: () => {ChangeTheNavigationSelection(analyticsTab); setSubTab('settings');},
			isActive: accountCurrentTab === analyticsTab && subTab === 'settings'
		});
		analyticsSubList.push({
			label: TitleTenants,
			onClick: () => {ChangeTheNavigationSelection(analyticsTab); setSubTab('tenants');},
			isActive: accountCurrentTab === analyticsTab && subTab === 'tenants'
		});
	}

	routerList.push({
		label : 'Analytics',
		icon: 'analytics',
		onClick: () => ChangeTheNavigationSelection(analyticsTab),
		isOpenDefault: accountCurrentTab === analyticsTab,
		items: analyticsSubList
	});

	const pageTitle = getPageTitle();
	const pageSubTitle = getPageSubTitle();

	return (
		<I18nextProvider i18n={newInstanceI18nextHome}>
			<RouterItems
				title="Account settings"
				list={routerList}>
				<div className='page_full_inner'>
					<section className='section no_bottom_pad section_primary'>
						<div className='flex'>
							<span className='h1'>{pageTitle}</span>
							{pageSubTitle &&
								<>
									<i className='fas fa-chevron-right fa-fw grey_5 s_13 ml_10 mr_10'></i>
									<span className='h1'>{pageSubTitle}</span>
								</>
							}
						</div>
					</section>
					<section className='section'>
						<Article size='l' innerSize='xl'>
							{accountCurrentTab === ipAddressesTab &&
								<IpAdressesContainer
									accountId={accountId}
									HasPermission={permissions && permissions}
									testModeIpAddressesArray={testModeIpAddressesArray}
									putIpAdresses={putIpAdresses}
									setGetNewList={() => setGetNewList(!getNewList)}
									currentIP={currentIP}
								/>
							}
							{accountCurrentTab === beyableKeyTab &&
								<>
									<div className='mb_20'>
										<div className='s_18 mb_8'>{SubTitleKey}</div>
										<div className='s_15'>{DescriptionKey}</div>
									</div>
									<InputCopyToClipBoard
										clipBoardContain={clipBoardContain}
										onClickFunction={(e) => copyToClipBoard(e)}
										value={clientKey}
										width="400"
									/>
								</>
							}
							{accountCurrentTab === productFeedTab && isAnAdmin &&
								<>
									<ProductFeedContainers
										props={props} />
								</>
							}
							{accountCurrentTab === productFeedTab && !isAnAdmin &&
								<>
									<div className="empty_page">
										<div className="empty_page_title">
											{TitleYouAreNot}
										</div>
										<div className="empty_page_text">
											{TitleFeed}<br />
											{TitleContactUs}
										</div>
									</div>
								</>
							}

							{accountCurrentTab === usersAdminTab && usersTab()}

							{accountCurrentTab === analyticsTab &&
								<AnalyticsConfigurationContainer
									$http={props.$http}
									$routeParams={props.$routeParams}
									accountId={accountId}
									$rootScope={props.$rootScope}
									analyticsScript={analyticsScript}
									AuthServices={props.AuthServices}
									$timeout={props.$timeout}
									tabSelected={subTab}
									setTabSelected={(tab) => setSubTab(tab)}
								/>
							}
						</Article>
					</section>
				</div>
			</RouterItems>
		</I18nextProvider>
	);
}
angular
	.module('beyableSaasApp.AccountHome', [])
	.component('accounthome', react2angular(AccountHomeContainer, [], ['$http', '$rootScope', '$timeout', '$routeParams', 'AuthServices', '$scope']));
