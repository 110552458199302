import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { ContextProvider } from './context';
import ABTestsDashboardPending from './ABTestsDashboardPending';
import AbTestsServices from '../../../Services/AbTestsServices';
import ReportingCampaignServices from '../../../Services/ReportingCampaignServices';
import CampaignCreatingServices from '../../../Services/CampaignCreatingServices';
import IpAdressesServices from '../../../Services/IpAdressesServices';
import LabelService from '../../../Services/LabelsServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SystemServices from '../../../Services/SystemServices';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

import onsite_list_view_en from '../../../Translation/jsonfiles/en/onsite_list_view_en.json';
import onsite_list_view_fr from '../../../Translation/jsonfiles/fr/onsite_list_view_fr.json';
import utils_en from '../../../Translation/jsonfiles/en/utils_en.json';
import utils_fr from '../../../Translation/jsonfiles/fr/utils_fr.json';
import {mergeJson} from '../../../Translation/translation-utils';

import ABTestsDashboardCompleted from './ABTestsDashboardCompleted';
import RouterWrapper from '../../Dashboard/OnSite/RouterWrapper';


export default function ABTestsDashboardPendingContainer(props) {

	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $http = props.$http;
	const $timeout = props.$timeout;
	const authServices = props.AuthServices;

	const systemServices = new SystemServices($rootScope, $timeout);

	const impersonatingServices = new ImpersonatingServices(
		$rootScope,
		$routeParams
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope.User.Account.Key);
	const labelServices = new LabelService($http, $routeParams);
	const ipAdressesServices = new IpAdressesServices($http, $routeParams);
	const abTestsServices = new AbTestsServices($http, accountId);
	const campaignCreatingServices = new CampaignCreatingServices($http, accountId,authServices);
	const reportingCampaignService = new ReportingCampaignServices(
		$http,
		$routeParams
	);
	const deleteCampaignById = (accountId, idCpg,callbackSuccess ) =>
		reportingCampaignService.deleteACampaign(accountId, idCpg,callbackSuccess, ()=> {
			systemServices.showError('An error occurred while trying to delete campaign');
		});
	const changeStatusCampaign =(accountId, idCpg,bool,callbackSuccess ) =>
		reportingCampaignService.putStatusCampaign(accountId, idCpg,bool,callbackSuccess, ()=> {
			systemServices.showError('An error occurred while trying to change status of campaign');
		});

	const getAllPendingCampaigns = (skipIdNumber,filters,callbackSuccess) => abTestsServices.getAllPendingCampaigns(skipIdNumber,filters,callbackSuccess, () => {
		systemServices.showError('An error occurred while trying to get campaign');
	});
	const getAllCompletedCampaigns  = (skipIdNumber,filters,callbackSuccess) => abTestsServices.getAllCompletedCampaigns(skipIdNumber,filters,callbackSuccess, () => {
		systemServices.showError('An error occurred while trying to get campaign');
	});

	const currentUser = authServices.GetAuthenticatedUser();

	const [ka, setKa] = useState(false);
	const [ku, setKu] = useState(false);

	const [isAdmin, setisAdmin] = useState(false);

	useEffect(() => {
		const isAdminTest = currentUser && currentUser.Role === 'Admin';
		setisAdmin(isAdminTest);
		setKa(props.$routeParams && props.$routeParams.ka && isAdminTest ? 'ka=' + accountId : '');
		setKu(props.$routeParams && props.$routeParams.ku && isAdminTest ? 'ku=' + accountId : '');
	}, []);

	const i18nL= localStorage.getItem('i18nL');
	const newInstanceI18nextOnsite = i18next.createInstance();
	newInstanceI18nextOnsite.init({
		lng: 'en-EN',                             
		resources: {
			'en-US': {
				common: mergeJson(onsite_list_view_en, utils_en)
			},
			'en-EN': {
				common: mergeJson(onsite_list_view_en, utils_en)
			},
			'fr-FR': {
				common: mergeJson(onsite_list_view_fr, utils_fr)
			},
		}},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextOnsite.changeLanguage(JSON.parse(i18nL));
	});

	return (
		<ContextProvider
			getAllPendingCampaigns={getAllPendingCampaigns}
			getAllCompletedCampaigns={getAllCompletedCampaigns}
			deleteCampaignById={deleteCampaignById}
			changeStatusCampaign={changeStatusCampaign}
			ka={ka}
			ku={ku}
			$routeParams={$routeParams}
			labelServices={labelServices}
			accountId={accountId}
			systemServices={systemServices}
			isAdmin={isAdmin}
			page={props.page}
			ipAdressesServices={ipAdressesServices}
			campaignCreatingServices={campaignCreatingServices}
			ReportingCampaignServices={reportingCampaignService}
			authServices={authServices}
		>
			<I18nextProvider i18n={newInstanceI18nextOnsite}>
				<RouterWrapper props={props}>
					<div className='page_full_inner'>
						<section className='section no_bottom_pad section_primary'>
							<div className='h1'>A/B Tests</div>
						</section>
						<section className='section'>
							{props.page == 'pending' && <ABTestsDashboardPending />}
							{props.page == 'completed' && <ABTestsDashboardCompleted />}
						</section>
					</div>
				</RouterWrapper>
			</I18nextProvider>
		</ContextProvider>
	);
}