import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { ReportGeneratorContext } from '../Context';
import InputCustom from '../../../Components/InputCustom';
import Fieldset from '../../../Components/Fieldset';
import SelectorDevices from '../SelectorDevices';
export default function LaunchInfo() {
	const {
		reportRecipient,
		reportDates,
		handleReportRecipientEmailSet,
		handleReportRecipientNameSet,
		handleReportRecipientEmailChange,
		handleReportDatesSet,
		notReadyToLaunchInfo
	} = ReportGeneratorContext();

	const [focusedInput, setFocusedInput] = useState(null);

	const { email, name } = reportRecipient || { email: '', name: '' };

	const handleOnDateChange = ({ startDate, endDate }) => {
		handleReportDatesSet({ startDate, endDate });
	};
	const onFocusChange = (focusedInput) => {
		setFocusedInput(focusedInput);
	};

	return (
		<>

			<div>
				<Fieldset label="Date range">
					<DateRangePicker
						startDate={reportDates.startDate}
						startDateId="startDateId"
						endDate={reportDates.endDate}
						required={true}
						endDateId="EndDateId"
						onDatesChange={handleOnDateChange}
						focusedInput={focusedInput}
						onFocusChange={onFocusChange}
						isOutsideRange={() => false}
						displayFormat={() => 'DD/MM/YYYY'}
					/>
				</Fieldset>
			</div>
			<div>
				<InputCustom
					label="Recipient email *"
					value={email}
					placeholder="Ex: report@exemple.com"
					onChange={(e => handleReportRecipientEmailSet(e.target.value))}
					fullWidth={true}
					onBlur={(e) => handleReportRecipientEmailChange(e.target.value)}
					isAnError={notReadyToLaunchInfo}
				/>
			</div>
			<div>
				<InputCustom
					label="Recipient name"
					value={name}
					onChange={(e) => handleReportRecipientNameSet(e.target.value)}
					fullWidth={true}
				/>
			</div>
		</>
	);
}


