import React from 'react';
import { react2angular } from 'react2angular';
import CreateRecommendationsContextProvider from '../context/RecommendationsContextProvider';
import RecommendationsSettingsContainer from './RecommendationsSettingsContainer';
export default function RecommendationsBridge(props) {
	return (

		<CreateRecommendationsContextProvider
			$http={props.$http}
			$timeout={props.$timeout}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
		>

			<RecommendationsSettingsContainer />
		</CreateRecommendationsContextProvider>
	);
}
angular
	.module('beyableSaasApp.RecommendationBridge', [])
	.component('recommendationbridge', react2angular(RecommendationsBridge, [], ['$http', '$rootScope', '$timeout', '$routeParams', 'AuthServices']));