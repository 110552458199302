import React, { useState, useEffect } from 'react'
import SelectCustom from '../../../../Components/SelectCustom'
import './TenantSelectorContainer.css'
import Btn from './../../../../Components/Btn';
import PopinConfirm from './../../../../Components/PopinConfirm';
export default function TenantSelectorContainer({ tenants, setOppenCreationTenant, setTenantSelected, tenantSelected, changesIsNotSaved, setChangesIsNotSaved }) {
    const [tenantsList, setTenantsList] = useState()
    const [tenantToSelect, setTenantToSelect] = useState()
    const [poppinSaveChangeIsOppen, setPoppinSaveChangeIsOppen] = useState(false)

    const selectNameOfQuery = (e) => {
        if (changesIsNotSaved) {
            setPoppinSaveChangeIsOppen(true)
            setTenantToSelect(e.target.value)
        } else {
            setTenantSelected(e.target.value)
        }
    }

    useEffect(() => {
        if (tenants) {
            setTenantsList(tenants.map(tenant => { return { value: tenant.tenant, label: tenant.tenant } }))
        }
    }, [tenants])

    return (
        <div className="tenant-select-container">
            {poppinSaveChangeIsOppen &&
                <PopinConfirm
                    cancel={() => setPoppinSaveChangeIsOppen(false)}
                    title={`Are you sure you want to change tenant without saving ?`}
                    message={``}
                >
                    <Btn
                        onClickFunction={(e) => { setPoppinSaveChangeIsOppen(false) }}
                        message="No"
                        color="alert"
                    />
                    <Btn
                        onClickFunction={(e) => { setChangesIsNotSaved(false); setPoppinSaveChangeIsOppen(false); setTenantSelected(tenantToSelect) }}
                        message="Yes i'am sure"
                    />
                </PopinConfirm>
            }
            <div className="tenant-select-container-tenant-select">
                <label>Tenant</label>
                <SelectCustom
                    optionsList={tenantsList && tenantsList}
                    name="nameOfQuery"
                    onChangeFunction={(e) => selectNameOfQuery(e)}
                    defaultValueDisabled={{
                        value: "choose",
                        label: "Choose a tenant",
                        isDisabled: true
                    }}
                    value={tenantSelected ? tenantSelected : ""}
                />
            </div>
            <Btn
                message="Create a tenant"
                icon="fas fa-plus"
                style="outline"
                onClickFunction={(e) => setOppenCreationTenant(true)}
            />

        </div>
    )
}
