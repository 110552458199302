import React from 'react';
import {Article} from './Article/Article';

// It is now a shortcut to Article component with titleSize = 's'
export default function PanelArticle(props) {
	return (
		<Article {...props} titleSize={'s'}>
			{props.children}
		</Article>
	);
}
