import React, { useState, useEffect, Fragment, useContext } from 'react';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';
import Modal from '../../../Components/Modal';
import PersonaServices from '../../../Services/PersonaServices';
import { TriggerContext } from './TriggerContext';
import useEventBroker from '../../../Hooks/useEventBroker';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';

export default function SavePersonaModal({
	setupTriggersForSaving,
	selectedPersona,
	handleChangeSelectedPersonaLabel,
	handleChangeSelectedPersonaDescription,
    isNativeApp
}) {

	const {$http, currentAccount, systemServices} = useContext(TriggerContext);
	const personaService = new PersonaServices($http);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [previousLabel, setPreviousLabel] = useState('');
	const [inputDescription, setInputDescription] = useState({value: ''});
	const { publish } = useEventBroker();
	const tooltipID = uuidv4();

	const createPersona = (accountId, persona, callbackSuccess) => personaService.createNewPersona(accountId, {...persona, contextIdentifier: isNativeApp ? 'NativeApp' : 'OnSite'}, callbackSuccess, 
		error => systemServices.showError('An error occured while saving the persona'));
	// const editPersona = (accountId, persona, groupId,  callbackSuccess) => personaService.editPersona(accountId, persona, groupId, callbackSuccess, error => console.log(error))

	useEffect(() => {
		setPreviousLabel(selectedPersona.Label);
	}, []);

	const saveNewPersona = () => {
		const data = {
			groupId: selectedPersona.Id,
			groupName: selectedPersona.Label,
			description: inputDescription.value,
			triggers : setupTriggersForSaving(selectedPersona.Data).map(e=>
				({
					Name: e.Name,
					OperatorName: e.OperatorName,
					Value: e.ValueToCompare
				})
			)
		};
		createPersona(currentAccount, data, () => 
			publish('evt/triggers/personaSaved', selectedPersona));
	};


	const handleChangeDescriptonInput = (e) => {
		e.persist();
		handleChangeSelectedPersonaDescription(e.target.value);
		setInputDescription(prevValues => ({
			...prevValues,
			[e.target.name]: e.target.value
		})
		);
	};

	return(
		<Fragment>
			<ReactTooltip
				id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={600}
			/>
			<Modal
				isOpen={modalIsOpen}
				width="500"
				onClose={() => setModalIsOpen(false)}
			>
				<div className="modal_header has_border">
                    Save as favorite persona
					<div className="modal_header_desc">It will be available for other campaigns</div>
				</div>
				<div className="modal_body">
					<InputCustom
						label="Name"
						type="text"
						value={selectedPersona.Label}
						onChange={(e) => handleChangeSelectedPersonaLabel(e.target.value)}
						autoFocus={true}
					/>
					<InputCustom
						label="Description"
						placeholder="Optional description"
						name="value"
						rows="2"
						type="textarea"
						value={inputDescription.value}
						onChange={(e) => handleChangeDescriptonInput(e)}
						fullWidth={true}
					/>
				</div>
				<div className="modal_footer al_right">
					<Btn
						onClickFunction={() => {handleChangeSelectedPersonaLabel(previousLabel); setModalIsOpen(false);} }
						message="Cancel"
						style="ghost"
						color="secondary"
					/>
					<Btn
						onClickFunction={() => {saveNewPersona(); setModalIsOpen(false);} }
						message="Save"
					/>
				</div>
			</Modal>
			{!selectedPersona.IsSavedPersona &&
                <a className="icon_btn s"
                	onClick={() => setModalIsOpen(true)}
                	data-for={tooltipID}
                	data-tip={'Save as favorite'}>
                	<i className="fas fa-star"></i>
                </a>
			}
		</Fragment>
	);
}