import HttpServices from './HttpServices';

export default class PromoCodeServices {
	constructor($http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.PCODE_API_URL) {
			throw 'Configuration [window.BEYABLE_env.PCODE_API_URL] missing';
		}
		else {
			this.PCODE_API_URL = `${window.BEYABLE_env.PCODE_API_URL}`;
			this.API_CODE = '9nrmyTAIKL0L4F/qyyF9IaSawYy4YOGLt52Zpy0qSxBrUwW6ynKy4g==';
		}
	}

	createPromoCode(accountClientKey, promoCode, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.PCODE_API_URL}account/${accountClientKey}/promocodecampaigns?code=${this.API_CODE}`,
			promoCode, callbackSuccess, callbackError);
	}

	getAllPromoCodes(accountKey, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.PCODE_API_URL}account/id/${accountKey}/promocodecampaigns?code=${this.API_CODE}`, callbackSuccess, callbackError);
	}

	getPromoCode(accountKey, campaignKey, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.PCODE_API_URL}account/id/${accountKey}/promocodecampaigns/${campaignKey}?code=${this.API_CODE}`, callbackSuccess, callbackError);
	}
}