import React, { useState, useEffect, Fragment } from 'react';
import Btn from '../../Components/Btn';
import SelectDropdown from '../../Components/SelectDropdown';
import { cleanInteractionLabel } from './IndicatorsUtil';
import { getIndicatorByKey, impressionIndicator } from '../ReportingCampaign/Indicators';

export default function IndicatorsButtonComponents(props) {
	const [indicatorsToMap, setIndicatorsToMap] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	const setIndicatorsWithLabels = (indicatorsToLoad) => {
		let newArray = [...indicatorsToLoad];
		if (props.activeInteraction && !newArray.map(x => x.key).includes(props.activeInteraction)){
			newArray.push({key: props.activeInteraction});
		}
		
		for (let i = 0; i < newArray.length; i++) {
			let secondLabel = newArray[i];			
			secondLabel.label = getIndicatorByKey(secondLabel.key)?.label ?? secondLabel.key;
			secondLabel.label = cleanInteractionLabel(secondLabel.label);
			secondLabel.value = secondLabel.key;
		}
		setIndicatorsToMap(newArray);
	};

	useEffect(() => {
		if (props.interactionsIndicators) {
			setIndicatorsWithLabels(props.interactionsIndicators);
		}
	}, [props.interactionsIndicators, props.activeInteraction]);

	const updateIndicator = (index, update) => {
		if (index !== props.activeInteraction) {
			props.updateNewIndicators(index);
			update();
		}
	};


	const search = () => {
		props.searchIndicators(searchTerm, 0, 50, success => {
			setIndicatorsWithLabels(success);
		}, error => {
			setIndicatorsWithLabels(props.interactionsIndicators);
		});
	};


	const onAutocomplete = setSearchTerm;

	useEffect(() => {
		if (searchTerm.length == 0) {
			setIndicatorsWithLabels(props.interactionsIndicators);
		}
		const timer = setTimeout(() => {
			if (searchTerm.length > 0) {
				search();
			} else {
				setIndicatorsWithLabels(props.interactionsIndicators);
			}
		}, 500);
		return () => {
			clearTimeout(timer);
		};
	}, [searchTerm]);

	const updateInteractionIndicator = (index) => {
		let interaction = { Keys: [impressionIndicator, index.toString()] };
		props && props.updateInteractionIndicator(interaction);
	};

	return (
		<>
			{((indicatorsToMap && indicatorsToMap.length > 0) || searchTerm.length > 0) &&
				<div className="reporting_indicators">
					<span className="reporting_indicators_label">Interactions</span>

					{indicatorsToMap.length > 3 || searchTerm.length > 0 ?
						<SelectDropdown
							value={props.activeInteraction}
							optionsList={indicatorsToMap}
							onChange={(v) => updateIndicator(v, () => updateInteractionIndicator(v))}
							autocomplete={true}
							autocompleteValue={searchTerm}
							onAutocomplete={onAutocomplete}
							clearAutocompleteOnClose={false}
							color="white"
							size="s"
						/>
						:
						<span className="btn_switch">
							{indicatorsToMap.map((item, index) =>
								<Btn
									color="secondary"
									style="outline"
									className={item.label === props.activeInteraction ? 'active' : ''}
									size="xs"
									key={index}
									onClickFunction={() => { updateIndicator(item.key, () => updateInteractionIndicator(item.key)); }}
								>
									{cleanInteractionLabel(item.label)}
								</Btn>
							)}
						</span>
					}
				</div>
			}
		</>
	);
}

