import React, { useState } from 'react';
import Dropdown from '../../../../Components/Dropdown';
import styles from '../Nav/Nav.module.css';

export default function ReportTypeFilter({
	customDimensionConfigsForTenant,
	reportTypeSelected,
	handleChangeReportSelect,
	filterValues,
	resetSource
}) {
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

	const getFilterLabel = (value) => {
		if (value.key === 'Tag1' && !!customDimensionConfigsForTenant?.customDimension1Name) return customDimensionConfigsForTenant.customDimension1Name;
		if (value.key === 'Tag2' && !!customDimensionConfigsForTenant?.customDimension2Name) return customDimensionConfigsForTenant.customDimension2Name;
		if (value.key === 'Tag3' && !!customDimensionConfigsForTenant?.customDimension3Name) return customDimensionConfigsForTenant.customDimension3Name;
		if (value.key === 'Tag4' && !!customDimensionConfigsForTenant?.customDimension4Name) return customDimensionConfigsForTenant.customDimension4Name;
		if (value.key === 'Tag5' && !!customDimensionConfigsForTenant?.customDimension5Name) return customDimensionConfigsForTenant.customDimension5Name;
		return value.label;
	};

	const button = (
		<div className="analytics_range">
			<div className="flex">
				<div className="flex_item_full">
					<div className="analytics_range_sup">Report Type</div>
					<div className="analytics_range_value">
						{reportTypeSelected && <>{getFilterLabel(filterValues.find(el => el.key === reportTypeSelected))}</>}
					</div>
				</div>
				<div className="flex_item_fix analytics_range_drop_arrow"></div>
			</div>
		</div>
	);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
		>
			<div className="flex flex_align_start">
				<div className="flex_item_full_fix">
					<ul className="listbox">
						{filterValues.map((value) => {
							const isSelected =
                                (reportTypeSelected && reportTypeSelected) ===
                                value.key;
							return (
								<li key={value.key}>
									<a
										className={
											isSelected
												? 'listbox_item selected'
												: 'listbox_item'
										}
										onClick={() => {
											handleChangeReportSelect(value.key),
											setDropdownIsOpen(false);
										}}
									>
										{getFilterLabel(value)}
										{isSelected && (
											<span
												className={styles.indicator}
											></span>
										)}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</Dropdown>
	);
}
