import React from 'react';
import Header from '../../UI/Header';
import { useRuleCompositionContext } from '../context/RuleCompositionContextProvider';
import Confirm from '../../../../Components/Confirm';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Btn from '../../../../Components/Btn';
import { Section } from '../../../../Components/Section/Section';
import InputCustom from '../../../../Components/InputCustom';
import RuleCompositionGroup from './RuleCompositionGroup';
import { Article } from '../../../../Components/Article/Article';


export default function RuleCompositionSettingsContainer() {
	const {
		compositionGroups,
		handleAddGroup,
		handleGroupsOrderChanged,
		handleSetCompositionName,
		compositionName,

		modalConfirmIsOpen,
		handleCloseConfirm,
		returnToDashboard,
		checkIfCanQuitWithoutSave,

		loadingSettings,
		loadingSaving,

		canSave,
		handleValidate,
		cancelActions,

	} = useRuleCompositionContext();

	if (loadingSettings) {
		return <SpinnerWheel wheelSize='l' />;
	}

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(compositionGroups);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		handleGroupsOrderChanged(items);
	};

	const handleCompositionNameChanged = (e) => {
		const val = e.target.value;
		handleSetCompositionName(val);
	};

	return (<>
		<div className="collection_container page_full flex flex_v">
			{loadingSaving
				&& <div className="mask">
					<span className="wheel"></span>
				</div>
			}

			<Confirm
				isOpen={modalConfirmIsOpen}
				onClose={() => handleCloseConfirm()}
				title="Close editor"
				text="Are you sure to close without saving?"
				cancelText="Stay"
				confirmText="Quit without saving"
				confirmCallback={() => returnToDashboard()}
			/>

			<Header
				title="New rule composition"
				subTitle={compositionName}
				returnUrl='/ProductSettings/Reco'
				valid={handleValidate}
				canValid={canSave}
				checkIfCanQuitWithoutSave={checkIfCanQuitWithoutSave}
				cancelActions={cancelActions}
			/>

			<div className="collection_body_grey flex_item_full vscroll">

				<Section width='xs' hasMargin={true}>
					<Article innerSize='l'>
						<div className='collection_sidebar_block'>
							<InputCustom
								value={compositionName || ''}
								type="text"
								label="Name"
								labelClassName="s_16"
								name="CompositionName"
								placeholder="Composition name"
								onChange={handleCompositionNameChanged}
								fullWidth={true}
							/>
						</div>

						<div className="collection_sidebar_block_last">
							<div className="collection_sidebar_block_title flex">
								<div className="flex_item_full">
									Composition
								</div>
								<div className="flex_item_fix ml_20">
									{compositionGroups.length < 2 ?
										<Btn
											message="Use groups"
											onClick={() => handleAddGroup()}
											style="ghost"
											color="secondary"
											icon="far fa-folder fa-sm"
											size="s"
											horizontalSize="xxs"
										/>
										:
										<Btn
											message="Add group"
											onClick={() => handleAddGroup()}
											style="ghost"
											icon="fas fa-plus fa-sm"
											horizontalSize="xxs"
										/>
									}
								</div>
							</div>
							{compositionGroups && compositionGroups.length === 1 &&
								compositionGroups.map((group, index) =>
									<RuleCompositionGroup
										key={group.id}
										ruleCompositionGroup={group}
										nbCompositionGroups={compositionGroups.length}
										groupIndex={index}
									/>
								)
							}
							{compositionGroups && compositionGroups.length > 1 &&
								<DragDropContext onDragEnd={handleOnDragEnd}>
									<Droppable droppableId="id" direction="vertical">
										{(provided) => (
											<div {...provided.droppableProps} ref={provided.innerRef}>
												{compositionGroups.map((group, index) =>
													<Draggable key={group.id} draggableId={group.id} index={index}>
														{(provided, snapshot) => (
															<div ref={provided.innerRef} {...provided.draggableProps}>
																<RuleCompositionGroup
																	isDragging={snapshot.isDragging}
																	ruleCompositionGroup={group}
																	displayMode='drag'
																	nbCompositionGroups={compositionGroups.length}
																	groupIndex={index}
																	dragProvided={provided}
																/>
															</div>
														)}
													</Draggable>
												)}
												{provided.placeholder}
											</div>)}
									</Droppable>
								</DragDropContext>
							}
						</div>
					</Article>
				</Section>
			</div>
		</div>
	</>
	);
}
