import React, { Fragment } from 'react';
import styles from './Skeleton.module.css';
import {
	ISkeleton,
	IBar,
	IBox,
	IInline,
	IFlex,
	ISquare
} from './types';

function Skeleton({
	appearance = 'paragraphe',
	nb = 1
}: ISkeleton): JSX.Element {

	const repeat = (jsx:React.ReactNode, nb:number) => {
		const result:React.ReactNode[] = [];
		for (let i = 0; i < nb; i++) {
			result.push(
				<Fragment key={i}>{jsx}</Fragment>
			);
		}
		return result;
	};

	switch (appearance) {
		case 'paragraphe':
			return (
				<>
					<Bar />
					<Bar />
					<Bar width='s' />
				</>
			);
		case 'title':
			return (
				<>
					<Bar width='s' height='l' />
					<Bar width='xs' height='s' />
				</>
			);
		case 'entity':
			return (
				<>
					TODO
				</>
			);
		case 'emptyState':
			return (
				<>
					TODO
				</>
			);
		case 'kpi':
			return (
				<>
					<Inline>
						{repeat(
							<Box>
								<Bar height='s' width='m' />
								<Bar height="l" width="l" />
							</Box>,
							nb
						)}
					</Inline>
				</>
			);
		case 'sumup':
			return (
				<>
					<Inline>
						{repeat(
							<Flex>
								<Square width='l' />
								<Box>
									<Bar height="m" width="l" />
									<Bar height="m" width="s" />
								</Box>
							</Flex>,
							nb
						)}
					</Inline>
				</>
			);
		case 'table':
			return (
				<>
					TODO
				</>
			);
		default: 
			return <></>;
	}
}

function Inline({
	children
}: IInline): JSX.Element {

	return (
		<div className={styles.inline}>
			{children}
		</div>
	);
}

function Flex({
	children
}: IFlex): JSX.Element {

	return (
		<div className={styles.flex}>
			{children}
		</div>
	);
}

function Box({
	children
}: IBox): JSX.Element {

	return (
		<div className={styles.box}>
			{children}
		</div>
	);
}

function Bar({
	width,
	height = 'm'
}: IBar): JSX.Element {

	const cssClass = [styles.bar];

	if (height) {
		const heightClass = 'bar_height_' + height;
		if (heightClass in styles) {
			cssClass.push(styles[heightClass]);
		}
	}

	if (width) {
		const widthClass = 'bar_width_' + width;
		if (widthClass in styles) {
			cssClass.push(styles[widthClass]);
		}
	}

	return (
		<div className={cssClass.join(' ')}>
			<div className={styles.item_inner}></div>
		</div>
	);
}

function Square({
	width = 'm',
}: ISquare): JSX.Element {

	const cssClass = [styles.square];

	if (width) {
		const widthClass = 'square_width_' + width;
		if (widthClass in styles) {
			cssClass.push(styles[widthClass]);
		}
	}

	return (
		<div className={cssClass.join(' ')}>
			<div className={styles.item_inner}></div>
		</div>
	);
}

export {
	Skeleton
};
