import { react2angular } from 'react2angular';
import React, { useState, useEffect, useRef } from 'react';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import AdminServices from '../../../Services/AdminServices';
import InputCustom from '../../../Components/InputCustom';
import SelectCustom from '../../../Components/SelectCustom';
import Checkbox from '../../../Components/Checkbox';
import InputCopyToClipBoard from '../../../Components/InputCopyToClipBoard';

export default function MainInformations(props) {
	const [account, setaccount] = useState({
		ClientKey: '',
		Key: '',
		Name: '',
		Platform: '',
		IsActive: false,
		PrivacyLink: '',
		AuthorizedUrls: '',
		CartUrls: '',
		AccountManagers: []
	});
	const [clipBoardContain, setClipBoardContain] = useState(false);
	const refIsActiveAccount = null;
	const [inputManager, setInputManager] = useState(<></>);
	const [managersArray, setmanagersArray] = useState([]);
	const [isActive, setIsActive] = useState(<></>);
	const $http = props.$http;
	const adminServices = new AdminServices($http);
	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const userKey = props.$routeParams['key'];
	var accountId = props.$routeParams['ka'] ? props.$routeParams['ka'] : $rootScope.User.Account.Key;

	const listPlatform = [
		{
			value: null,
			label: 'Choose a Platform',
		},
		{
			value: 'custom',
			label: 'Custom',
		},
		{
			value: 'prestashop',
			label: 'Prestashop',
		},
		{
			value: 'magento',
			label: 'Magento',
		},
		{
			value: 'wizishop',
			label: 'Wizishop',
		},
	];
	function checkManager(array, manager) {
		const arrayOfKeysManagers = array.map(m => m.Key);
		if (arrayOfKeysManagers.includes(manager.Key)) {
			return true;
		} else { return false; }
	}

	function upDateMainInformations() {
		adminServices.EditAccount(account, success => {
			var responseAlert = {};
			responseAlert.type = 'success';
			responseAlert.msg = 'Changes saved.';
			props.$rootScope.$broadcast('event:alert-messageSent', responseAlert);
		}, (error, errMsg) => {
			systemServices.showError(error);
		});
	}
	const handleChangeForm = (e) => {
		const val = e.target.value;
		const name = e.target.name;
		setaccount(acc => ({
			...acc,
			[name]: val
		}));
	};
	const handleSelecteCsmAccountForAccount = (e, manager) => {
		const val = e.target.checked;
		const actualSelectionCSM = [...account.AccountManagers];
		if (val) {
			actualSelectionCSM.push(manager);
		} else {
			actualSelectionCSM.filter(m => m.Key === manager.key);
		}
		setaccount(acc => ({
			...acc,
			AccountManagers: actualSelectionCSM
		}));
	};
	const handleSelecteIsActive = (e) => {
		const val = e.target.checked;
		setaccount(acc => ({
			...acc,
			IsActive: val
		}));
	};
	const handleSelectPlatform = (e) => {
		const val = e.target.value;
		setaccount(acc => ({
			...acc,
			Platform: val
		}));
	};
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
	};
	useEffect(() => {
		adminServices.GetAccountInfo(accountId, infosUser => {
			setaccount(infosUser);
			adminServices.LoadAllAccountManager(managersInfos => {
				const managers = managersInfos.sort(function (a, b) {
					if (a.LastLoginDate > b.LastLoginDate)
						return -1;
					if (a.LastLoginDate < b.LastLoginDate)
						return 1;
					return 0;
				});
				setmanagersArray(managers);

			}, (error, errMsg) => {
				systemServices.showError(error);
			});
		}, (error, errMsg) => {
			systemServices.showError(error);
		});
	}, []);
	useEffect(() => {
		setIsActive(
			<div
				key={account.IsActive}
				className="account_manager" >
				<p style={{ fontWeight: 'bold', fontWeight: '500', marginTop: '3px' }}>Active website ?</p>
				<Checkbox
					ref={refIsActiveAccount}
					name="isActive"
					checked={account.IsActive}
					value={account.IsActive}
					onChange={(e) => handleSelecteIsActive(e)}
				>
                    Yes
				</Checkbox>
			</div>
		);
	}, [managersArray, refIsActiveAccount]);
	useEffect(() => {
		if (managersArray.length !== 0) {
			setInputManager(managersArray.map(manager =>
				<div
					key={manager.Key}
					className="account_manager" >
					<Checkbox
						name="AccountManagers"
						checked={checkManager(account.AccountManagers, manager)}
						value={manager.Key}
						onChange={(e) => handleSelecteCsmAccountForAccount(e, manager)}
					>
						{manager.FirstName} {manager.LastName}
					</Checkbox>
				</div>
			));
		}
	}, [managersArray]);

	return (
		<div >
			<section className="page_body" style={{ width: '750px' }}>
				<h2 className="page_body_title">Main information</h2>
				<InputCopyToClipBoard
					label='Customer key'
					clipBoardContain={clipBoardContain}
					onClickFunction={(e) => copyToClipBoard(e)}
					value={account.ClientKey}
					width="400"
					color="white"
				/>
				<InputCopyToClipBoard
					label='Account ID'
					clipBoardContain={clipBoardContain}
					onClickFunction={(e) => copyToClipBoard(e)}
					value={account.Key}
					width="400"
					color="white"
				/>
				<div className="btt-radio input-inline">
					<label className="label-top">
						<InputCustom
							value={account.Name}
							type="text"
							label="Name"
							name="Name"
							placeholder="Name"
							onChange={handleChangeForm}
							fullWidth={true}
							color="white"
							blockClassName="no_margin"
							labelClassName="s_16"
							max={60}
						/>
					</label>
				</div>
				<div className="flex-2">
					{/* <div>
						<SelectCustom
							label="Platform"
							optionsList={listPlatform}
							value={account.Platform}
							onChange={handleSelectPlatform}
							autoWidth={true}
						/>
					</div> */}
					<div className="btt-checkbox input-inline">
						{isActive}
					</div>
				</div>
			</section>
			<section className="page_body" style={{ width: '750px' }}>
				{/* <h2 className="page_body_title">Assigned CSM</h2>
				<div className="btt-checkbox account_manager_wrapper" id="accountManager">
					{inputManager}
				</div> */}
				{/* <div className="input-inline">
					<InputCustom
						value={account.CartUrls}
						type="text"
						label="Cart urls"
						name="CartUrls"
						placeholder="Cart urls"
						onChange={handleChangeForm}
						fullWidth={true}
						color="white"
						blockClassName="no_margin"
						labelClassName="s_16"
					/>
				</div>
				<div className="input-inline">
					<InputCustom
						value={account.PrivacyLink}
						type="text"
						label="Privacy Link"
						name="PrivacyLink"
						placeholder="Privacy Link"
						onChange={handleChangeForm}
						fullWidth={true}
						color="white"
						blockClassName="no_margin"
						labelClassName="s_16"
					/>
				</div> */}
				<div className="input-inline">
					<InputCustom
						value={account.AuthorizedUrls}
						type="text"
						label="Authorized Urls"
						name="AuthorizedUrls"
						placeholder="Authorized Urls"
						onChange={handleChangeForm}
						fullWidth={true}
						color="white"
						blockClassName="no_margin"
						labelClassName="s_16"
					/>
				</div>
			</section>
			<button
				style={{ position: 'relative' }}
				className="btt-primary btt-green save_btn"
				onClick={upDateMainInformations}><i className="fa fa-save"></i>Save
                changes</button>
		</div>
	);
}

angular
	.module('beyableSaasApp.MainInformations', ['beyableSaasApp.Services'])
	.component('maininformations', react2angular(MainInformations, [],
		['$rootScope', '$scope', '$http', '$routeParams', '$timeout', '$routeParams']));