import React, { useState, useEffect } from 'react'
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function CategoryDropDownList({
	changeCategorySelected,
	categorySelected = { name: '', label: '' },
	typeCategories,
	name,
	labelPosition = 'inner',
	color = 'white',
	label = 'Category type',
	size = 'm'
}) {

	const [valuesForSelect, setValuesForSelect] = useState([]);
	const [searchResult, setsearchResult] = useState();
	const [inputSearch, setinputSearch] = useState('');
	useEffect(() => {
		if (typeCategories && typeCategories.length > 0) {
			const tmpList = typeCategories.map(i => {
				return {
					value: i.name,
					label: i.label
				};
			});
			setValuesForSelect(tmpList);
			setsearchResult(tmpList)
		}
	}, [typeCategories])
	const search = (table, word) => {
		if (word.length > 0) {
			setsearchResult([])
			let resultName = table.filter(
				line =>
					line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			const res = resultName.map(i => {
				return {
					value: i,
					label: i
				};
			})
			setsearchResult(res)
		}
		else {
			setsearchResult(valuesForSelect)
		}

	}
	const handleChangeSearch = (event) => {
		setinputSearch(event)
		search(valuesForSelect.map(v => v.value), event)
	}
	if (!typeCategories) {
		return null
	}

	return (
		<SelectDropdown
			label={label}
			labelPosition={labelPosition}
			size={size}
			optionsList={searchResult}
			value={categorySelected.name}
			onChange={(v) => changeCategorySelected(v)}
			color={color}
			friendlyValue={categorySelected.label}
			autocomplete={true}
			onAutocomplete={(v) => handleChangeSearch(v)}
			autocompleteValue={inputSearch}
			autocompletePlaceholder={'Search for a category type'}
		/>
	);
}
