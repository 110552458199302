export function transformConfigToList(obj) {
	let arrayOfTenantsAndConfigs = [];
	for (const property in obj) {
		const currentObj = {
			key: property,
			feeds: [],
			links: obj[property]['_links']
		};
		const objToWork = obj[property].sources;
		for (const property in objToWork) {
			const objLinkFeed = {
				...objToWork[property],
				label: objToWork[property].type,
				id: objToWork[property].id,
				resourceIdentifier: objToWork[property].resourceIdentifier,
				// link : objToWork[property]._links
			};
			currentObj.feeds.push(objLinkFeed);
		}
		arrayOfTenantsAndConfigs.push(currentObj);
	}
	return arrayOfTenantsAndConfigs;
}
export const BO_API_URL = window.BEYABLE_env.BO_API_URL;