import React from 'react'
import DownloadStatsEmail from './DonwloadStatsEmails';
export default function MainInformationsContainer({ data, mainInformation, dateRange }) {
    const arrayOfLabels = [
        { key: 'numberOfEmailsClicked', label: 'Number od emails clicked', color: '#69B472', },
        { key: 'numberOfEmailsDelivered', label: 'Number od emails delivred', color: '#FCAF45', },
        { key: 'numberOfEmailsOpened', label: 'Number od emails opened', color: '#E85C8D', },
        { key: 'numberOfEmailsSent', label: 'Number od emails sent', color: '#4278F0', },
    ]
    return (
        <>
            <div className="reporting_part">
                <div className="flex">
                    <div className="reporting_part_title flex_item_full" style={{ textAlign: 'center' }}>
                        Total for the selected period</div>
                </div>
                <div className="flex flex_justify_end">
                    <DownloadStatsEmail stats={data} mainInformation={mainInformation} dateRange={dateRange} />
                </div>
                <div className="kpi_list">
                    <div className="kpi_item" >
                        <p className="kpi_label" style={{ color: `#4278F0` }}> Number of emails sent</p>
                        <p className="kpi_value" style={{ margin: '20px auto' }} >  {data && data.numberOfEmailsSent}</p>
                    </div>
                    <div className="kpi_item" >
                        <p className="kpi_label" style={{ color: `#FCAF45` }}> Number of emails delivered</p>
                        <p className="kpi_value" style={{ margin: '20px auto' }} >  {data && data.numberOfEmailsDelivered}</p>
                    </div>
                    <div className="kpi_item">
                        <p className="kpi_label" style={{ color: `#E85C8D` }}> Number of emails opened</p>
                        <p className="kpi_value" style={{ margin: '20px auto' }} >  {data && data.numberOfEmailsOpened}</p>
                    </div>
                    <div className="kpi_item" >
                        <p className="kpi_label" style={{ color: `#69B472` }}> Number of emails clicked</p>
                        <p className="kpi_value" style={{ margin: '20px auto' }}>  {data && data.numberOfEmailsClicked}</p>
                    </div>
                </div>
            </div>

        </>
    )
}
