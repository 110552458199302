import HttpServices from './HttpServices';


export default class EmailingPerformancesServices {
	constructor($http, $routeParams) {
		this.campaignId = $routeParams ? $routeParams.key : '';
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}
	getStatsEmailRemarketing(accountId, campaignId, startDate, endDate, granularity, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}emailingstatistics/${accountId}/campaign/${campaignId}/emailremarketing?startDate=${startDate}&endDate=${endDate}&timeGranularity=${granularity}`, callbackSuccess, callbackError);
	}
	getStatsEmailRemarketingPeriod(accountId, campaignId, startDate, endDate, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}emailingstatistics/${accountId}/campaign/${campaignId}/emailremarketing?startDate=${startDate}&endDate=${endDate}`, callbackSuccess, callbackError);
	}

	getCampaignsInfoEmailRemarketing(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}performance/${accountId}/emailremarketingcampaign/${campaignId}`, callbackSuccess, callbackError);
	}
	getAllCampaignsEmailRemarketing(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}performance/${accountId}/emailremarketingcampaign`, callbackSuccess, callbackError);
	}
}

