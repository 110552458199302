export type CampaignFormat = {
	id: string;
	label: string;
	imgId: string;
	message: string;
  };

export type Feature = {
	id: string;
	label: string;
	icon?: string;
	iconCustom?: string;
	color: string;
	value?: string;
	disabled? :boolean;
	adminOnly? :boolean;
  }

export const listCampaignFormat: Array<CampaignFormat> = [
	{
		id:	'Overlay',
		label : 'Overlay',
		imgId: 'popin',
		message: 'customCampaign.overlayDesc'
	},
	{
		id:	'InPage',
		label : 'In-page',
		imgId: 'inpage',
		message: ''
	},
	{
		id:	'HeaderBar',
		label : 'Header bar',
		imgId: 'headerbar',
		message: 'customCampaign.headerDesc'
	},
	{
		id:	'Custom',
		label : 'Custom',
		imgId: 'custom',
		message: 'customCampaign.customDesc'
	},
];
export const listCampaignMobileFormat: Array<CampaignFormat> = [
	{
		id:	'Overlay',
		label : 'Overlay',
		imgId: 'popin',
		message: 'customCampaign.overlayDesc'
	},
	{
		id:	'InPage',
		label : 'In-page',
		imgId: 'inpage',
		message: ''
	},
	{
		id:	'HeaderBar',
		label : 'Header bar',
		imgId: 'headerbar',
		message: 'customCampaign.headerDesc'
	},
];

export const ListFeature: Array<Feature> = [
	{
		id: 'reco',
		label : 'Product recommendations',
		icon : 'fas fa-thumbs-up',
		color : 'orange',
	},
	{
		id: 'redirection',
		label : 'Redirection',
		icon : 'fas fa-external-link-square-alt',
		color : 'blue',
	},
	{
		id: 'promoCode',
		label : 'Promo code',
		icon : 'fas fa-percentage',
		color : 'green',
	},
	{
		id: 'productList',
		label : 'Product list (SKU)',
		icon : 'fas fa-barcode',
		color : 'purple',
	},
	{
		id: 'form',
		label : 'Form',
		icon : 'fas fa-question-circle',
		color : 'yellow',
	},
	{
		id: 'formSurvey',
		label : 'Survey',
		icon : 'fas fa-poll',
		color : 'red',
	},
	{
		id: 'data',
		label : 'Custom variables',
		iconCustom: 'iconVariable',
		color : 'pink',
	},
];

function isJsonString(str: string) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export function checkFeaturesSelectedOnCurrentVariation (objVariation,listOfShelvingRules){
	const featuresSelectedOnCurrentVariation: Array<Feature> = [];
	if(!objVariation) return [];
	if(objVariation.Properties){
		// Check if has "ShelvingRules"
		const shelvingRules = objVariation.Properties.find(el => el.Name === 'ShelvingRules' );
		if(shelvingRules){
			const listFeatureReco = ListFeature.find(el => el.id === 'reco');

			if(listFeatureReco){
				const isValid = isJsonString(shelvingRules.Value);
				if(isValid){
					const rules = JSON.parse(shelvingRules.Value);
				
					const listValueShelving = rules.map(el => {
						const label = listOfShelvingRules.find(x => x.id === el.Id);
						return {
							...el,
							label : label ? label.name : ''
						};
					});
					let valueToStr = '';
					if(listValueShelving.length === 1){
						valueToStr = listValueShelving[0].label;
					}else{
						valueToStr = `${listValueShelving[0].label}  + ${listValueShelving.length - 1}` ;
					}
					listFeatureReco['value'] = valueToStr;
					featuresSelectedOnCurrentVariation.push(listFeatureReco);
				}
			}
		}
		// Check if has "Products"
		const ProductsList = objVariation.Properties.find(el => el.Name === 'PRODUCTS' );
		if(ProductsList){
			const listSku = ListFeature.find(el => el.id === 'productList');
			if(listSku){
				if(ProductsList.Value ){
					const rules = Array.from(ProductsList.Value.split(/[;,\n]/).filter(x => x));
					if(rules.length > 0){
						let valueToStr;
						if(rules.length === 1){
							valueToStr = rules[0];
						}else{
							valueToStr = `${rules[0]}  + ${rules.length - 1}` ;
						}
		
						listSku['value'] = valueToStr;
						featuresSelectedOnCurrentVariation.push(listSku);
					}
				}
			}
		}
		// Check if has "Custom Variables"
		const customData = objVariation.Properties.find(el => el.Name === 'DATA' );
		if (customData) {
			const listData = ListFeature.find(el => el.id === 'data');
			if(listData){
				featuresSelectedOnCurrentVariation.push(listData);
			}
		}
		// Check if has "Form"
		const formCustom = objVariation.Properties.find(el => el.Name === 'FormCustom' );
		if(formCustom){
			const listForm = ListFeature.find(el => el.id === 'form');
			if(listForm){
				featuresSelectedOnCurrentVariation.push(listForm);
			}
		}
		const formSurveyCustom = objVariation.Properties.find(el => el.Name === 'FormSurveyCustom' );
		if(formSurveyCustom){
			const listForm = ListFeature.find(el => el.id === 'formSurvey');
			if(listForm){
				featuresSelectedOnCurrentVariation.push(listForm);
			}
		}
	}
	if(objVariation.Answer){
		// Check if has Promo Code 
		if(objVariation.Answer.hasOwnProperty('PromoCode')){
			const isPromoCodeItemType = objVariation.Answer.ItemType === 'CallToActionWithPromoCode';
			const listFeaturePromo = ListFeature.find(el => el.id === 'promoCode');
			if(listFeaturePromo && isPromoCodeItemType){
				listFeaturePromo['value'] = objVariation.Answer.PromoCode?.ActualPromoCode ?? '';
				featuresSelectedOnCurrentVariation.push(listFeaturePromo);
			}
		}
		// Check if has Redirection 
		if(objVariation.Answer.hasOwnProperty('CallToAction')){
			if(objVariation.Answer.CallToAction !== null){
				const isEmpty = objVariation.Answer.CallToAction.Link === null || objVariation.Answer.CallToAction.Link === '' ;
				const listFeatureRedirection = ListFeature.find(el => el.id === 'redirection');
				if(listFeatureRedirection && !isEmpty){
					listFeatureRedirection['value'] = objVariation.Answer.CallToAction.Link;
					featuresSelectedOnCurrentVariation.push(listFeatureRedirection);
				}
			}
		}
	}
	return featuresSelectedOnCurrentVariation;
}
