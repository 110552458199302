import { react2angular } from 'react2angular';
import React, { useState, useEffect, Fragment } from 'react';
import PannelIpAdresses from './PannelIpAdresses';
import IpAdressesServices from '../../../Services/IpAdressesServices';
import AccountServices from '../../../Services/AccountServices';
import InputCopyToClipBoard from './../../../Components/InputCopyToClipBoard';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
export default function IpAdressesAdminContainer(props) {
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const IpAdressesService = new IpAdressesServices(props.$http);
	const accountServices = new AccountServices(props.$http);
	const systemServices = new SystemServices(props.$rootScope);
	const getAccountData = (accountId, callbackSuccess, callbackError) => accountServices.getAccount(accountId, callbackSuccess, error => systemServices.showError('an error occured'));
	const putIpAdresses = (accountId, arrayOfIPAdresses, callbackSuccess, callbackError) => IpAdressesService.putIpAdressesAccount(accountId, arrayOfIPAdresses, success => {
		systemServices.showSuccess('Changes saved'); callbackSuccess(success);
	}, error => systemServices.showError('An error occured'));
	const [clipBoardContain, setClipBoardContain] = useState(false);
	const [testModeIpAddressesArray, setTestModeIpAddressesArray] = useState();
	const [getNewList, setGetNewList] = useState(false);
	const [currentIP, setCurrentIP] = useState();

	useEffect(() => {
		getAccountData(accountId,
			newData => {
				let arrayIp = newData.TestModeIpAddresses.map((x, i) => ({ ...x, index: i }));
				setTestModeIpAddressesArray(arrayIp);
			});
	}, [getNewList]);

	// Get current IP address
	fetch('https://backoffice.apiv2.beyable.com/api/ip-lookup')
		.then(response => response.json())
		.then(data => setCurrentIP(data.ipAddress));

	const [showPanelIpAddress, setShowPanelIpAddress] = useState(false);
	const [newIpToCreate, setNewIpToCreate] = useState({
		IpAddress: '',
		Label: '',
		IsDisabled: false
	});
	const addNewIpAdresses = () => {
		let arrayOfIp = [...testModeIpAddressesArray, newIpToCreate];
		const finalObject = {
			TestModeIpAddresses: arrayOfIp,
			// key: accountId
		};
		putIpAdresses(accountId, finalObject,
			e => {
				setGetNewList();
				setShowPanelIpAddress(false);
				setNewIpToCreate({
					IpAddress: '',
					Label: '',
					IsDisabled: false
				});
			},
			e => {
				//.log("fail: ", e)
			});
	};
	const editAdresseIp = (ip, index) => {
		let itemToPut = testModeIpAddressesArray.filter(x => x.index === index)[0];
		itemToPut.exist = true;
		setNewIpToCreate(itemToPut);
		setShowPanelIpAddress(true);
	};
	const putAdressesIp = (e) => {
		let newArrayOfIp = [...testModeIpAddressesArray.filter(x => x.index !== newIpToCreate.index), newIpToCreate];
		const finalObject = {
			TestModeIpAddresses: newArrayOfIp,
			// key: accountId
		};
		putIpAdresses(accountId, finalObject,
			e => {
				setGetNewList();
				setShowPanelIpAddress(false);
				setNewIpToCreate({
					IpAddress: '',
					Label: '',
					IsDisabled: false
				});
			},
			e => {
				//.log("fail: ", e)
			});
	};
	const deleteTheAdresseIp = (e) => {
		let newArrayOfIp = testModeIpAddressesArray.filter(x => x.index !== newIpToCreate.index);
		const finalObject = {
			TestModeIpAddresses: newArrayOfIp,
			key: accountId
		};
		putIpAdresses(accountId, finalObject,
			e => {
				setGetNewList();
				setShowPanelIpAddress(false);
				setNewIpToCreate({
					IpAddress: '',
					Label: '',
					IsDisabled: false
				});
			},
			e => {
				//.log("fail: ", e)
			});
	};

	return (
		<div className="page_body">
			<section>
				<Fragment>
					<h2 className="page_body_title">IP addresses</h2>
					<p className="page_txt">
						List of all IP addresses available for test mode on on-site campaign.
						<br />You can add new ones, edit and delete them.
					</p>
					{
						testModeIpAddressesArray && testModeIpAddressesArray.length === 0 &&
						<div className="ip_address_empty">
							<p className="msg">This account does not have any IP address yet</p>
							<button onClick={() => setShowPanelIpAddress(true)}>Add new IP address <i className="fas fa-plus"></i></button>
						</div>}
					{
						testModeIpAddressesArray && testModeIpAddressesArray.length > 0 &&
						<div className="ip_address_table">
							<div className="table-row table-header">
								<p>IP Address</p>
								<p>Name</p>
								<button onClick={() => setShowPanelIpAddress(true)}>Add new address <i className="fas fa-plus"></i></button>
							</div>
							{
								testModeIpAddressesArray && testModeIpAddressesArray.map((ip, i) =>
									<div className="table-row table-body" key={i}>
										<p className="ip_address">{ip.IpAddress}</p>
										<p>{ip.Label}</p>
										<div className="table-action">
											<button onClick={(e) => editAdresseIp(ip, i)} className="btn-edit-ip"><i className="fas fa-edit"></i> Edit</button>
										</div>
									</div>
								)
							}
						</div>
					}
					<PannelIpAdresses
						showPanelIpAddress={showPanelIpAddress}
						closeThePannel={() => setShowPanelIpAddress(false)}
						newIpToCreate={newIpToCreate}
						setNewIpToCreate={(e) => setNewIpToCreate(e)}
						addNewIpAdresses={() => addNewIpAdresses()}
						putAdressesIp={() => putAdressesIp()}
						deleteTheAdresseIp={() => deleteTheAdresseIp()}
						existIp={newIpToCreate.exist}
						currentIP={currentIP}
					/>
				</Fragment>
			</section>
		</div>
	);
}
angular
	.module('beyableSaasApp.IpAdresses', ['beyableSaasApp.Services'])
	.component('ipadresses', react2angular(IpAdressesAdminContainer, [],
		['$rootScope', '$scope', '$http', '$routeParams', '$timeout']));