import React, { useState } from 'react';
import Cron from 'react-js-cron';
import './ScheduleItem.css';

export default function ScheduleItem ({
	defaultValue,
	onChange,
	children
}) {
	
	const [value, setValue] = useState(defaultValue || '* * * * *');
	
	const onChangeScheduleTrigger = (newValue) => {
		setValue(newValue);
		onChange && onChange(newValue);
	};
	
	return (
		<>
			<Cron 
				value={value} 
				setValue={(newValue) => onChangeScheduleTrigger(newValue)}
				clearButton={false}
			/>
			{children}
		</>
	);
}