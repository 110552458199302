import React, { useEffect, useState } from 'react';
import Btn from '../../../../Components/Btn';
import SearchBar from '../../../../Components/SearchBar';
import SelectDropdown from '../../../../Components/SelectDropdown';
import UseInput from '../../../../Hooks/UseInput';
import Checkbox from '../../../../Components/Checkbox';
import { usePageContext } from '../../context/PageContext';
import styles from '../../WebAnalyticsContainer.module.css';

export default function PageSearchFilter() {
	const {
		filterUrl,
		setfilterUrl,
		setChips,
		handleSearchValue,
		setNeedRefreshgraph,
		searchValue,
		excludeFilter,
		handleChangeExcludeFilter,
		selectedCustomTag,
		isCustomDimensionMode
	} = usePageContext();

	const { value, handleChange, resetValue } = UseInput({ url: '' });
	const [isMaximized, setIsMaximized] = useState(value.length > 0);

	// send reset request when input is empty 
	useEffect(()=>{

		if(searchValue && value.url === ''){
			handleSearchValue(value.url);
		}
	},[value]);


	useEffect(()=>{
		if(value.url &&  value.url.length > 0 && filterUrl){
			value.url = '';
		}
	},[filterUrl]);

    
	const handleClick = (value) => {
		if (value.url.length !== 0 && filterUrl) {
			setfilterUrl(null);
			setChips(null);
			setNeedRefreshgraph(true);
		}
		handleSearchValue(value.url);
		return setNeedRefreshgraph(false);
	};

	return (
		<div className="ml_20">
			<div className={styles.mainSearchBar}>
				<div className={styles.mainSearchBarInput}>
					<SearchBar
						onChangeValue={(e) => handleChange(e)}
						valueInSearchBar={value.url}
						placeholder={isCustomDimensionMode && !selectedCustomTag ? 'Search for a value' : 'Search for an URL'}
						name="url"
						color="white"
						onFocus={() => setIsMaximized(true)}
					/>
				</div>
				{isMaximized &&
					<>
						<div className={styles.mainSearchBarBtn}>
							<SelectDropdown
								value={excludeFilter}
								optionsList={[
									{value: false, label: 'Include' },
									{value: true, label: 'Exclude' }
								]}
								onChange={(v) => handleChangeExcludeFilter(v)}
								color='white'
							/>
						</div>
						<div className={styles.mainSearchBarBtn}>
							<Btn
								onClick={() => handleClick(value)}
								color="secondary"
								style="outline"
								icon="fas fa-search"
							/>
						</div>
					</>
				}
			</div>
		</div>
	);
}
