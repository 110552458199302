import React, { useContext, useState, useEffect } from 'react';
import './CustomizeSelectPagesType.css';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import Message from './../../../BusinessOperations/Create/Design/Message';
import Btn from './../../../../Components/Btn';
export default function CustomizeSelectPagesType({ }) {
	const {
		isexistant,
		operation,
		selectionPagesType,
		setSelectionPagesType,
		setIsVisitedLink,
		zoningCustomizeListValues,
		setZoningCustomizeListValues,
		setPageNameSelected,
		arrayOfTypePage,
		listZones,
		deviceSelected
	} = useContext(CreateBusinessOperationContext);
	const [message, setMessage] = useState();
	const [type, setType] = useState('');
	const nextStep = () => {
		setPageNameSelected(selectionPagesType[0]);
		setIsVisitedLink(selectionPagesType[0]);
	};

	const selectPageType = (e) => {
		let zone = [...selectionPagesType];
		if (zone.includes(e)) {
			// For unselect Zone Check if it's an existant op if a PageType is associate to an op
			const pageTypeAssociation = operation.operation.locations.map(x => {
				const pageTypeByZone = listZones.find(zoneByDesktopSelected => zoneByDesktopSelected.device === deviceSelected.toLowerCase() && zoneByDesktopSelected.pageType === e);
				let valueToReturn;
				const extensionObject = x.extensions;
				if (pageTypeByZone && extensionObject !== undefined) {
					// si extensions est un object et ne contient pas item []
					if (typeof extensionObject === null) {
						valueToReturn = pageTypeByZone.pageType;
					}
					else if (typeof (extensionObject === 'object') && extensionObject !== null && !extensionObject.hasOwnProperty('items')) {
						valueToReturn = pageTypeByZone.pageType;
					} else if (typeof (extensionObject === 'object') && extensionObject !== null && extensionObject.hasOwnProperty('items')) {
						if (extensionObject.items.length > 0) {
							valueToReturn = pageTypeByZone.pageType;
						}
					}
					return valueToReturn;
				} else {
					if (pageTypeByZone) {
						valueToReturn = pageTypeByZone.pageType;
						return valueToReturn;
					}
				}
			});

			if (pageTypeAssociation.find(element => element === e)) {
				setMessage('To unselect this page type you need to delete the operation that use it before');
				setType('error');
				setTimeout(() => {
					setMessage();
				}, 5000);

			} else {
				const newSelection = zone.filter(x => x !== e);
				setSelectionPagesType([...newSelection]);
				putZoningCustomizeListValues(e);
			}

		}
		else {
			setSelectionPagesType([...zone, e]);
			addZoningCustomizeListValues(e);
		}
	};
	// Create new object for customize differents Pages Type
	const addZoningCustomizeListValues = (e) => {
		setZoningCustomizeListValues(prevValues => ([
			...prevValues, { pageType: e }
		]));
	};
	const putZoningCustomizeListValues = (e) => {
		let newList = [...zoningCustomizeListValues];
		newList = newList.filter(x => x.pageType !== e);
		setZoningCustomizeListValues([
			...newList
		]);
	};


	return (
		<div className="new-module">
			<h1>Customize - Pages type</h1>
			<div className="customizeTitleDesc">Please select the pages where you want to display your banners</div>
			<div className="listPagesType">
				{arrayOfTypePage && arrayOfTypePage.map((item, i) =>
					<div key={i} className={selectionPagesType.includes(item.pageType) ? 'elementPageType selected' : 'elementPageType'} onClick={(e) => selectPageType(item.pageType)} >
						<div className="elementPageTypeImg">
							<img src={item.screenShoot} />
						</div>
						{/* <div className="elementPageTypeTxt">{ListTypesPages.filter(x => x.key == item.pageType)[0].label}</div> */}
						<div className="elementPageTypeTxt">{item.name}</div>
						<div className="elementPagesType-devices">
							{item.devices.includes('mobile') && <i className="fas fa-mobile-alt" ></i>}
							{item.devices.includes('desktop') && <i className="fas fa-desktop" ></i>}
						</div>
					</div>
				)}
			</div>
			{message &&
				<Message
					msg={message}
					type={type}
				/>

			}
			<div className="navPagesType">
				<Btn size="l"
					disabled={!selectionPagesType.length}
					onClickFunction={nextStep}
					icon="fas fa-chevron-right"
					iconPosition="after">Next Step</Btn>
			</div>
		</div>
	);
}
