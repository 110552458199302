import React, { useEffect, useState } from 'react';

import SpinnerWheel from '../../../Components/SpinnerWheel';
import EmptyState from '../../../Components/EmptyState';
import FormCustomServices from '../../../Services/FormCustomServices';
import FormCustomReportTableComponentHooks from './FormCustomReportTableComponentHooks';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import moment from 'moment';

export default function FormCustomReportContainerHooks({ campaignId, accountId, startD, endD, isLoading, propS }) {

	const systemServices = new SystemServices(propS.$rootScope);
	const impersonatingServices = new ImpersonatingServices(propS.$rootScope, propS.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const formCustomServices = new FormCustomServices(propS.$http, propS.$routeParams);
	const authenticatedUserAccount = propS.$rootScope.User.Account.Key;
	const [focusedInput, setFocusedInput] = useState(null);
	const [firstFetch, setFirstfetch] = useState(true);
	const today = moment();
	const [paginationArray, setPaginationArray] = useState();
	const [currentPage, setcurrentPage] = useState(0);
	const [allAnswers, setallAnswers] = useState();
	const [state, setstate] = useState({
		slides: [],
		includeToday: false,
		canIncludeToday: false,
		campaignId: '',
		accountId: '',
	});
	const isOutsideRange = (date) => {
		if (date.startOf('d').isBefore(moment(startD).startOf('d'))) {
			return true;
		}
		if (date.endOf('d').isAfter(today.endOf('d'))) {
			return true;
		}
		if (date.endOf('d').isAfter(moment(endD).endOf('d'))) {
			return true;
		}
	};

	const [dateRange, setdateRange] = useState({
		startDate: isOutsideRange(moment().subtract(7, 'd')) ? moment(startD) : moment().subtract(7, 'd'),
		endDate: moment(endD)
	});
	const { startDate, endDate } = dateRange;

	const refreshDates = () => {
		const newDates = {
			startDate: isOutsideRange(moment().subtract(7, 'd')) ? moment(startD) : moment().subtract(7, 'd'),
			endDate: moment(endD)
		};
		setdateRange(newDates);
	};
	const handleOnDateChange = (startDate, endDate) => {
		setdateRange(startDate, endDate);
	};

	const getAnswers = () => {
		formCustomServices.getAnswers(accountId, campaignId, startDate, endDate,null, null,
			function (data) {
				setcurrentPage(0);
				setPaginationArray([{
					index: 0,
					nextPartitionKey: data.nextPartitionKey,
					nextRowKey : data.nextRowKey,
					prevnextPartitionKey : null,
					prevnextRowKey : null

				}]);
				setstate({
					...state, slides: data.result
				});
			}
		);
		formCustomServices.getAnswersMax(accountId, campaignId, startDate, endDate,
			function (data) {
				setallAnswers(data.result[0]);
			}
		);
	};
	const loadDataForm = () => {
		if (startD && endD && firstFetch) {
			setFirstfetch(false);
			setstate({
				...state,
				campaignId: campaignId,
				accountId: accountId,
			});
			getAnswers();
			refreshDates();
		}
	};
	useEffect(() => {
		loadDataForm();
	}, [startD]);
	useEffect(() => {
		setFirstfetch(true);
	}, [campaignId]);

	const reFetchData = () => {
		const startDat = moment(startDate).startOf('day');
		const endDat = moment(endDate).startOf('day');
		if (!endDat.isValid() || !startDat.isValid()) {
			return;
		} else {
			getAnswers();
		}
	};
	useEffect(() => {
		reFetchData();
	}, [endDate]);

	const fetchNextPage = (nextPartitionKey,nextRowKey, curr) => {
		formCustomServices.getAnswers(accountId, campaignId, startDate, endDate,
			nextPartitionKey,nextRowKey,
			 function (data) {
				if(paginationArray.indexOf(paginationArray[curr + 1]) === -1){
					setPaginationArray([...paginationArray,{
						index: curr + 1,
						nextPartitionKey: data.nextPartitionKey,
						nextRowKey : data.nextRowKey,
						prevnextPartitionKey : curr > 0 ? paginationArray[curr - 1].nextPartitionKey : null,
						prevnextRowKey :  curr > 0 ? paginationArray[curr - 1].nextRowKey : null
					}]);
				}
				setcurrentPage(curr + 1);
				setstate({
					...state, slides: data.result
				});
			},
			function(error){
			    console.log('🚀--** ~ file: FormCustomReportContainerHooks.js:126 ~ fetchNextPage ~ error:', error);
				
			}
		);
	};
	const fetchPrevPage = (curr) => {
		const nextCurr = curr;
		const nextPartitionKey = paginationArray[nextCurr].prevnextPartitionKey ;
		const nextRowKey =  paginationArray[nextCurr].prevnextRowKey ;
		formCustomServices.getAnswers(accountId, campaignId, startDate, endDate,
			nextPartitionKey,nextRowKey,
			function (data) {
				setcurrentPage(nextCurr - 1);
				setstate({
					...state, slides: data.result
				});
			},
			function(error){
				console.log('🚀--** ~ file: FormCustomReportContainerHooks.js:126 ~ fetchNextPage ~ error:', error);
				
			}
		);
	};

	return (
		<>
			<div className="flex flex_wrap">
				{!isLoading ?
					<>
						<div className="reporting_filters flex_item_fix mr_10">
							<span className="date_picker_s">
								<DateRangePicker
									displayFormat='DD/MM/YYYY'
									startDate={startDate}
									endDate={endDate}
									startDateId="idStartDate"
									endDateId="endDId"
									isOutsideRange={(date) => isOutsideRange(date)}
									focusedInput={focusedInput}
									onFocusChange={(focused) => setFocusedInput(focused)}
									onDatesChange={(startDate, endDate) => handleOnDateChange(startDate, endDate)}
									minimumNights={0}
								/>
							</span>
						</div>
						{!state.slides.length ?
							<div className="reporting_body flex_item_full">
								<EmptyState
									icon="fas fa-poll-h"
									title="No response for this period"
									text="Please try another period"
								/>
							</div>
							:
							<>
								
								{state.slides.map(slide =>
									<FormCustomReportTableComponentHooks
										fetchNextPage={(un, deux, trois)=>fetchNextPage(un, deux, trois)}
										fetchPrevPage={(u)=>fetchPrevPage(u)}
										paginationArray={paginationArray}
										currentPage={currentPage}
										loadDataForm={() => loadDataForm()}
										answers={slide.answers}
										allAnswers={allAnswers && allAnswers.answers}
										key={slide.slideId}
										slideId={slide.slideId} />
								)
								}
							</>}
					</>
					:
					<SpinnerWheel wheelSize="l" />
				}
			</div>
		</>
	);
}
