import React from 'react';
import { useTrafficContext } from '../context/ContextTraffic';
import {
	devices,
	pageTypes,
	engagementLevel
} from '../Traffic/config';
import NavTraffic from '../Traffic/NavTraffic';
import UtmTraffic from './UtmTraffic';
import SelectFilter from '../../../Components/SelectFilter';
import { useTranslation } from 'react-i18next';

export default function TrafficFilters() {

	const [t] = useTranslation(['kpi', 'utils']);
	const {
		deviceSelected,
		handleChangeDeviceSelected,
		resetDevices,
		handleChangePageType,
		resetPageType,
		pageTypeSelected,
		handleChangeIntentionSelected,
		intentionSelected,
		resetIntention
	} = useTrafficContext();

	function getTranslatedArray(arr, dimension) {
		return arr.map((l) => {
			const copy = {...l};
			if (copy.translation) {
				copy.label = t(copy.translation.key, copy.translation.vars);
			} else {
				copy.label = t(dimension + '.' + copy.label);
			}
			return copy;
		});
	}

	const engagementLevelTranslated = getTranslatedArray(engagementLevel, 'engagementLevel');
	const pageTypesTranslated = getTranslatedArray(pageTypes, 'pageType');
	const devicesTranslated = getTranslatedArray(devices, 'device');

	return (
		<>
			<div className='btn_group_l'>
				<NavTraffic />
				{/*
					<SelectFilter 
						label="Traffic source"
						onChange={handleChangeSourceType}
						onReset={resetSource}
						value={sourceType}
						optionsList={sourceTypes}
					/>
				*/}
				<SelectFilter
					label={t('data.device')}
					onChange={handleChangeDeviceSelected}
					onReset={resetDevices}
					value={deviceSelected}
					optionsList={devicesTranslated}
				/>
				<SelectFilter
					label={t('data.pageType')}
					onChange={handleChangePageType}
					onReset={resetPageType}
					value={pageTypeSelected}
					optionsList={pageTypesTranslated}
				/>
				<SelectFilter
					label={t('data.engagementLevel')}
					onChange={handleChangeIntentionSelected}
					onReset={resetIntention}
					value={intentionSelected}
					optionsList={engagementLevelTranslated}
				/>
				<UtmTraffic />
			</div>
		</>
	);
}
