import React from 'react';
import EmptyState from '../../../Components/EmptyState';

export default function MessageErrorNoConfiguration() {
	return (
		<div className='center'>
			<EmptyState
				icon="fas fa-phone"
				title="No configuration yet"
				text="Please contact an administrator to finalize your installation and take advantage of all features"
				verticalSize="l"
				textSize="l"
			/>
		</div>
	);
}
