import React, { useState } from "react";
import Dropdown from "../../../../Components/Dropdown";
import Btn from "../../../../Components/Btn";

import { useSessionContext } from "../../context/SessionContext";
import { metricsTypes } from "./config";
export default function SelectMetricTypeSessionsContainer({ styles }) {
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const {
		onSelectMetricType,
		metricTypeSessionsSelected
	} = useSessionContext();
	const button = (
		<Btn
			message={metricTypeSessionsSelected ? metricTypeSessionsSelected.label : 'Undefined metric'}
			style="ghost"
			color="black"
			arrow={true}
			fontSize="l"
			className="btn_reset_margin"
		/>
	);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
		>
			<div className="flex flex_align_start">
				<div className="flex_item_full_fix">
					<ul className="listbox">
						{metricsTypes.map((value) => {
							const isSelected =
								(metricTypeSessionsSelected &&
									metricTypeSessionsSelected.key) === value.key;
							return (
								<li key={value.key}>
									<a
										className={
											isSelected
												? "listbox_item selected"
												: "listbox_item"
										}
										onClick={() => {
											onSelectMetricType(value),
												setDropdownIsOpen(false);
										}}
									>
										{value.label}
										{isSelected && (
											<span
												className={styles.indicator}
											></span>
										)}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</Dropdown>
	);
}
