import React, { useState } from 'react';
import Dropdown from '../../../../Components/Dropdown';
import styles from '../Nav/Nav.module.css';

export default function ReportTypeFilter({
	reportTypeSelected,
	handleChangeReportType,
	initialValue,
}) {
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	let isActive = (reportTypeSelected && reportTypeSelected != 'All') ? true : false;
	const button = (
		<div className={'analytics_range'}>
			<div className="flex">
				<div className="flex_item_full">
					<div className="analytics_range_sup">Report Type</div>
					<div className="analytics_range_value">
						{reportTypeSelected && <>{reportTypeSelected}</>}
					</div>
				</div>
				<div className="flex_item_fix analytics_range_drop_arrow"></div>
			</div>
		</div>
	);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
		>
			<div className="flex flex_align_start">
				<div className="flex_item_full_fix">
					<ul className="listbox">
						{initialValue.map((value) => {
							const isSelected =
                                (reportTypeSelected && reportTypeSelected) ===
                                value;
							return (
								<li key={value}>
									<a
										className={
											isSelected
												? 'listbox_item selected'
												: 'listbox_item'
										}
										onClick={() => {
											handleChangeReportType(value),
											setDropdownIsOpen(false);
										}}
									>
										{value}
										{isSelected && (
											<span
												className={styles.indicator}
											></span>
										)}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</Dropdown>
	);
}
