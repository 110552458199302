import React, {createContext, useContext, useState, useEffect} from 'react';
import {initialDates} from '../components/Nav/config';
import {metricsTypes, sourceTypes,initialUtmFilter,initialUtmFilterV2, devices} from '../components/Interactions/config';

const InteractionContext = createContext();

function useInteractionContext() {
	return useContext(InteractionContext);
}

const CreateInteractionContextProvider = (props) => {
	const {initialFrom, initialTo} = initialDates;
	const [navFilter, setNavFilter] = useState({
		fromDate: initialFrom,
		toDate: initialTo,
		filters: [],
	});
	const [isCustomSelected, setisCustomSelected] = useState(false);
	const [customDateRange, setCustomDateRange] = useState( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const [reportTypeSelected, setReportTypeSelected] = useState('Interaction');
	const [sourceType, setSourceType] = useState([]);
	const [isLoadingData, setisLoadingData] = useState(false);
	const [metricTypeSelected, setmetricTypeSelected] = useState({
		key: 'UniqueCount',
		label: 'Unique count',
	});
	const [needRefreshGraph, setNeedRefreshgraph] = useState(false);
	const [dataLevelGraphFilter, setDataLevelGraphFilter] = useState([]);
	const [dataBoardFilter, setDataBoardFilter] = useState([]);
	const [dataLevelBoardFilter, setDataLevelBoardFilter] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [filterExactValue, setFilterExactValue] = useState(null);
	const [isExactMatch, setIsExactMatch] = useState(true);

	const [filter2, setFilter2] = useState(null);
	const [filter2ExactValue, setFilter2ExactValue] = useState(null);
	const [offset, setOffset] = useState(0);
	const [offset2, setOffset2] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPage2, setCurrentPage2] = useState(1);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [filterExactValueSavedForFilterHasvaluePages, setfilterExactValueSavedForFilterHasvaluePages] = useState();
	const [needRefreshForUtmFilter, setneedRefreshForUtmFilter] = useState(false);
	const [utmDimensionsSelected, setUtmDimensionsSelected] = useState(initialUtmFilterV2);
	const [utmSelected, setUtmSelected] = useState({
		source: false,
		medium: false,
		campaign: false,
		content: false
	});
	const [step, setStep] = useState(1);
	const [isLoadingHasCategories, setIsLoadingHasCategories] = useState(true);
	const [hasCategories, setHasCategories] = useState(false);

	const [deviceSelected, setdeviceSelected] = useState([]);
	const [excludeFilter, setExcludeFilter] = useState(false);
	const [categoryIsExactMatch, setCategoryIsExactMatch] = useState(false);
	const [hasValues, setHasValues] = useState(false);

	function handleChangeExcludeFilter(v){
		setExcludeFilter(v);
	}
	function handleChangeDeviceSelected(elem){
		const newKey = deviceSelected.find(el => el === elem);
		const oldD = [...deviceSelected];
		const indefOfVal = oldD.indexOf(elem);
		if(indefOfVal !== -1){
			setdeviceSelected(oldD.filter(x => x !== newKey));
		}else{
			setdeviceSelected([...oldD,elem]);
		}
	}
	function resetDevices(){
			setdeviceSelected([]);
	}
	const handleChangeSourceType = (val) => {
		setOffset(0);
		setCurrentPage(1);
		setOffset2(0);
		setCurrentPage2(1);
		const oldSources = [...sourceType];
		const indefOfVal = oldSources.indexOf(val);
		if(indefOfVal !== -1){
			setSourceType(oldSources.filter(x => x !== val));
		}else{
			setSourceType([...oldSources,val]);
		}
	};
	const resetSource = () => {
		setOffset(0);
		setCurrentPage(1);
		setOffset2(0);
		setCurrentPage2(1);
		setSourceType([]);
	}

	function onChangeNavFilter(nav) {
		setOffset(0);
		setCurrentPage(1);
		setOffset2(0);
		setCurrentPage2(1);
		setNavFilter((currentNavFilter) => ({...currentNavFilter, ...nav}));
	}

	const handleChangeReportSelect = (filterReport) => {
		setHasValues(false);
		setFilter2(null);
		setFilter2ExactValue(null);
		setReportTypeSelected(filterReport);
		setOffset(0);
		setCurrentPage(1);
		setOffset2(0);
		setCurrentPage2(1);
		setFilterExactValue(null);
		setSearchValue('');
		setStep(1);
		setSelectedCategory(null);
		setfilterExactValueSavedForFilterHasvaluePages();
		if (reportTypeSelected !== filterReport) {
			setIsLoadingHasCategories(true);
		}
	};
	const onSelectMetricType = (val) => {
		if (val.key !== metricTypeSelected.key) {
			const newTypeFiltered = metricsTypes.find((t) => t.key === val.key);
			newTypeFiltered && setmetricTypeSelected(val);
		}
	};
	const handleGraphDataWhenLevelIsSelect = (data) => {
		setDataLevelGraphFilter(data);
	};

	const handleGraphData = (data) => {
		setDataLevelGraphFilter(data);
	};

	const handleBoardDataWhenLevelIsSelect = (data) => {
		setDataLevelBoardFilter(data);
	};

	const handleBoardData = (data) => {
		setDataBoardFilter(data);
	};
	const handleFilterHasValuesPage = (filterValue, level, HasValues) => {
		const hasV = HasValues !== undefined ? HasValues : false;
		if(reportTypeSelected === 'Page' ){
			setOffset2(0);
			setCurrentPage2(1);
			setCategoryIsExactMatch(true)
			setIsExactMatch(true);
			setFilter2(reportTypeSelected === 'Page' ? 'Interaction' : 'Page');
			setFilter2ExactValue(filterValue);
			setStep(4);
			setHasValues(hasV);
			if(HasValues){
				setfilterExactValueSavedForFilterHasvaluePages(filterValue);
			}else{
				setfilterExactValueSavedForFilterHasvaluePages();
			}
		}else{
			setOffset2(0);
			setCurrentPage2(1);
			setCategoryIsExactMatch(true);
			setIsExactMatch(true);
			setFilter2('Page');
			setFilterExactValue(filterValue);
			setfilterExactValueSavedForFilterHasvaluePages(filterExactValue);
			setStep(4);
			setHasValues(hasV);
		}
	}
	const handleFilter = (filterValue, level, HasValues,refreshValueSave = false) => {
		const hasV = HasValues !== undefined ? HasValues : false;
		setOffset(0);
		setCurrentPage(1);
		setOffset2(0);
		setCurrentPage2(1);
		setFilter2ExactValue(null);
		if (level === 'interaction') {
			setCategoryIsExactMatch(false);
			if (reportTypeSelected === 'Page') {
				setSelectedCategory(null);
			}
			setHasValues(hasV);
			setFilter2(reportTypeSelected === 'Page' ? 'Interaction' : 'Page');
			setFilterExactValue(filterValue);
			setIsExactMatch(true);
			setStep(3);
		} else if (level === 'category') {
			setHasValues(hasV);
			setFilter2(reportTypeSelected === 'Page' ? 'Interaction' : null);
			if (reportTypeSelected === 'Interaction' 
			) {
				setFilterExactValue(null);
				setfilterExactValueSavedForFilterHasvaluePages();
				setIsExactMatch(true);
				setSelectedCategory(filterValue);
				setCategoryIsExactMatch(true);
				setStep(2);
			}
			if( reportTypeSelected === 'Page'){
				setSelectedCategory(filterValue);
				setCategoryIsExactMatch(true);
				setIsExactMatch(true);
				setStep(3);
			}
		} else if(level === 'page'){
			if( !hasCategories){
				setFilter2ExactValue(null);
				setFilterExactValue(filterValue);
				setStep(2);
				setHasValues(hasV);
				setFilter2('Interaction');
				setIsExactMatch(true);
			}
		}
		else {
			setHasValues(hasV);
			setCategoryIsExactMatch(false);
			setStep(1);
			setSearchValue('');
			setFilterExactValue(null);
			setFilter2(null);
			setSelectedCategory(null);
		}
	};
	
	const handleSelectPage = (page) => {
		setStep(2);
		setSearchValue('');
		setFilterExactValue(page);
		setIsExactMatch(true);
		setSelectedCategory(null);
		setIsLoadingHasCategories(true);
		setOffset(0);
		setOffset2(0);
		setCurrentPage2(1);

		if (!hasCategories) {
			setFilter2('Interaction');
			setSelectedCategory(null);
		}
	};
	

	const handleSearchValue = (url) => {
		if(step !== 4){
			if (!filter2 ) {
				setOffset(0);
				setOffset2(0);
				setCurrentPage2(1);
				setFilterExactValue(() => url);
				setSearchValue(() => url);
			}else{
				setSearchValue('');
				setOffset(0);
				setFilter2('')
				setOffset2(0);
				setCurrentPage2(1);
				setFilter2ExactValue('')
				if(reportTypeSelected === 'Page' && step === 2 && hasCategories){
					setIsExactMatch(true);
				}
			}
		}else{
			setOffset(0);
			setOffset2(0);
			setCurrentPage2(1);
			if(url.length > 0){
				setFilter2ExactValue( url);
			}else{
				const filterToAddToSearchBar = filterExactValueSavedForFilterHasvaluePages;
				setFilter2ExactValue(reportTypeSelected === 'Page'  ?filterToAddToSearchBar : '')
			}
			setSearchValue(() => url);
		}
		
		
	};
	
	const handleSearchValueFilter2 = (url) => {
		setOffset(0);
		setOffset2(0);
		setCurrentPage2(1);
		if (hasCategories) {
			setIsExactMatch(true);
			setCategoryIsExactMatch(true);
		}
		if(step !== 4){
			setFilter2ExactValue(() => url);
			setSearchValue(() => url);
		}else{
			const filterToAddToSearchBar = filterExactValueSavedForFilterHasvaluePages;
			if(url.length > 0){
				setFilter2ExactValue(() => filterToAddToSearchBar + '/'+ url);
			}else{
				setFilter2ExactValue(filterToAddToSearchBar ? filterToAddToSearchBar :'')
			}
			setSearchValue(() => url);
		}
	};
	
	
	const resetSearchInteraction = () => {
		setFilterExactValue(null);
	};
	function resetUtmSelected() {
		setUtmSelected({
			source: false,
			medium: false,
			campaign: false,
			content: false
		});
	}
	function handleSelectUtmDimension(utmDimension, value) {
		setUtmSelected(dimensions => ({
			...dimensions,
			[utmDimension]: value
		})
		);
	}
	function hansleSaveUtmDimensionFilter(newFilterUtm) {
		for (const key in newFilterUtm) {
			const utmSelectionElementStatus = utmSelected[key];
			if (!utmSelectionElementStatus) {
				newFilterUtm[key].name = '';
				newFilterUtm[key].label = '';
			}
		}
		setneedRefreshForUtmFilter(!needRefreshForUtmFilter);
		setUtmDimensionsSelected(newFilterUtm);
		setOffset(0);
		setCurrentPage(1);
		setOffset2(0);
		setCurrentPage2(1);
	}
	return (
		<InteractionContext.Provider
			value={{
				navFilter,
				onChangeNavFilter,
				reportTypeSelected,
				handleChangeReportSelect,
				handleChangeSourceType,
				sourceType,
				onSelectMetricType,
				metricTypeSelected,
				isLoadingData,
				setisLoadingData,
				handleGraphDataWhenLevelIsSelect,
				handleGraphData,
				handleBoardDataWhenLevelIsSelect,
				handleBoardData,
				dataLevelGraphFilter,
				dataBoardFilter,
				dataLevelBoardFilter,
				filter2,
				handleFilter,
				filterExactValue,
				filter2ExactValue,
				handleSearchValue,
				searchValue,
				isExactMatch,
				metricsTypes,
				sourceTypes,
				needRefreshGraph,
				setNeedRefreshgraph,
				setIsExactMatch,
				offset,
				setOffset,
				resetSearchInteraction,
				currentPage,
				setCurrentPage,
				selectedCategory,
				handleSelectUtmDimension,
				utmDimensionsSelected,
				utmSelected,
				hansleSaveUtmDimensionFilter,
				needRefreshForUtmFilter,
				step,
				handleSearchValueFilter2,
				resetUtmSelected,
				handleSelectPage,
				isLoadingHasCategories,
				setIsLoadingHasCategories,
				hasCategories,
				setHasCategories,
				isCustomSelected,
				setisCustomSelected,
				customDateRange,
				setCustomDateRange,
				deviceSelected,
				handleChangeDeviceSelected,
				excludeFilter,
				handleChangeExcludeFilter,
				resetSource,
				resetDevices,
				categoryIsExactMatch,
				hasValues,
				setHasValues,
				handleFilterHasValuesPage,
				offset2,
				currentPage2,
				setOffset2,
				setCurrentPage2,
				filterExactValueSavedForFilterHasvaluePages
			}}
		>
			{props.children}
		</InteractionContext.Provider>
	);
};

export default CreateInteractionContextProvider;
export {useInteractionContext};
