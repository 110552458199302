import React, { useState, useEffect, useContext } from 'react';
import { reportingCampaignContext } from '../Context/ContextReportingCampaign';
import ResultsHeaderComponent from './ResultsHeaderComponent';
import FormResultsCampaignComponent from './FormResultsCampaignComponent';
import Download from './Donwload';
import AnalysisSection from './Analysis/AnalysisSection';
import { Trans, useTranslation } from 'react-i18next';
import useFeature from '../../../../flags/useFeature';
import Btn from '../../../Components/Btn';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';

export default function ResultsCampaignContainer(props) {
	const { features } = useFeature();
	const {
		setRangeDate
	} = useContext(reportingCampaignContext);
	const [t, i18n] = useTranslation('common');
	const [indicatorsCompare, setIndicatorsCompare] = useState([]);
	const [indicatorsCompute, setIndicatorsCompute] = useState([]);
	const [downloadDDIsOpen, setDownloadDDIsOpen] = useState(false);
	const [showOldTable, setShowOldTable] = useState(false);
	const getCompareIndicatorsSecondary = () => {
		if (props.DataSecondaryIndicators) {
			let array = [];
			for (let i = 0; i < props.DataSecondaryIndicators.length; i++) {
				if (props.DataSecondaryIndicators[i].displayModes.includes('Compare')) {
					array.push(props.DataSecondaryIndicators[i]);
				}
			}
			setIndicatorsCompare(array);
		}
	};
	const getComputeIndicators = () => {
		if (props.DataSecondaryIndicators) {
			let array = [];
			for (let i = 0; i < props.DataSecondaryIndicators.length; i++) {
				if (props.DataSecondaryIndicators[i].displayModes.includes('Compute')) {
					array.push(props.DataSecondaryIndicators[i]);
				}
			}
			setIndicatorsCompute(array);
		}
	};

	useEffect(() => {
		getCompareIndicatorsSecondary();
		getComputeIndicators();
	}, [props.DataSecondaryIndicators]);

	const removeArray = () => {
		setIndicatorsCompare([]);
	};
	// useEffect(() => {
	//   removeArray()
	// }, [props.datainformation])
	useEffect(() => {
		if (props.momentStartDate && props.momentEndDate) {
			const range = `${moment(props.momentStartDate).format('DD/MM/YYYY')} - ${moment(props.momentEndDate).format('DD/MM/YYYY')}`;
			setRangeDate(range);
		}
	}, [props.momentStartDate, props.momentEndDate]);

	return (
		<>
			{!props.isLoadingInformationsCampaign ? <>
				<div className="reporting_part">
					<div className="flex">
						<div className="reporting_part_title flex_item_full">
							{t('resultsCampaign.MainKPI')}
						</div>
						<div>
							<Dropdown
								isOpen={downloadDDIsOpen}
								toggle={(s) => setDownloadDDIsOpen(s)}
								button={
									<Btn
										style="link"
										message='Download data'
										arrow={true}
									/>
								}
							>
								<Listbox>
									<Download
										datainformation={props.datainformation && props.datainformation}
										loadAllInformationCampaign={props.loadAllInformationCampaign && props.loadAllInformationCampaign}
										loadIndicator={props.loadSecondIndicator}
										device={props.device}
									/>
									<ListboxItem
										message={t('ChartPerformCampaign.TransactionsData')}
										onClick={() => props.handleGetCampaignConversion()}
									/>
								</Listbox>
							</Dropdown>
						</div>
					</div>
					<ResultsHeaderComponent
						loadSecondIndicator={props.loadSecondIndicator}
						getAllSecondaryIndicators={props.getAllSecondaryIndicators}
						searchIndicators={props.searchIndicators}
						device={props.device && props.device}
						systemServices={props.systemServices && props.systemServices}
						updateSummaryIndicators={props.updateSummaryIndicators}
						SummaryFirstIndicators={props.SummaryFirstIndicators && props.SummaryFirstIndicators}
						DataSecondaryIndicators={props.DataSecondaryIndicators && props.DataSecondaryIndicators}
						datainformation={props.datainformation && props.datainformation}
						allIndicatorsofCampaign={props.allIndicatorsofCampaign && props.allIndicatorsofCampaign}
						momentStartDate={props.momentStartDate}
						momentEndDate={props.momentEndDate}
						hasVariations={props.hasVariations}
						hasControlGroup={props.datainformation && props.datainformation.hasControlGroup}
						variationSelected={props.variationSelected}
						targetIndicators={props.targetIndicators}
						interactionsIndicators={props.interactionsIndicators}
					/>
				</div>
				<AnalysisSection
					datainformation={props.datainformation}
					device={props.device && props.device}
					systemServices={props.systemServices && props.systemServices}
					hasVariations={props.hasVariations}
					hasControlGroup={props.datainformation && props.datainformation.hasControlGroup}
					momentStartDate={props.momentStartDate}
					momentEndDate={props.momentEndDate}
				/>
				{props.isAdmin &&
					<>
						<Btn
							message={showOldTable ? 'Hide old table' : 'View old table'}
							style="link"
							icon={showOldTable ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'}
							iconPosition="after"
							onClick={() => setShowOldTable(!showOldTable)}
						/>
						{showOldTable &&
							<div className="reporting_part">
								<div className="reporting_part_title">{t('resultsCampaign.Incremental')}</div>
								<FormResultsCampaignComponent
									device={props.device && props.device}
									systemServices={props.systemServices && props.systemServices}
									loadSecondIndicator={props.loadSecondIndicator}
									getAllSecondaryIndicators={props.getAllSecondaryIndicators}
									DataSecondaryIndicators={props.DataSecondaryIndicators && props.DataSecondaryIndicators}
									indicatorsCompare={indicatorsCompare && indicatorsCompare}
									indicatorsCompute={indicatorsCompute && indicatorsCompute}
									accountId={props.accountId}
									campaignId={props.campaignId}
									momentStartDate={props.momentStartDate}
									momentEndDate={props.momentEndDate}
									hasVariations={props.hasVariations}
									hasControlGroup={props.datainformation && props.datainformation.hasControlGroup}
									variationSelected={props.variationSelected}
									activeLink={props.activeLink}
								/>
							</div>
						}
					</>
				}
				
			</> :
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			}
		</>
	);
}