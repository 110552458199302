import { useState, useEffect } from 'react';
import SegmentGroupServices from '../../../../Services/SegmentGroupServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import { EditingSegmentGroupWithTriggers, convertSegmentGroupApiTypeToEditingType } from './EntityTypes';

type InfrastructureType = {
	$rootScope: any;
	$routeParams: any;
	$http: any;
}

type SegmentationLoaderResultType = {
	isLoading: boolean;
	cannotLoad: boolean;
	loadedSegmentation: EditingSegmentGroupWithTriggers | null
}

export function useSegmentationLoader({ $rootScope, $routeParams, $http }: InfrastructureType, segmentGroupId: string | null): SegmentationLoaderResultType {
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;

	const segmentGroupServices = new SegmentGroupServices(accountId, $http);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [cannotLoad, setCannotLoad] = useState<boolean>(false);
	const [loadedSegmentation, setLoadedSegmentation] = useState<EditingSegmentGroupWithTriggers | null>(null);

	const DefaultSegmentGroup: EditingSegmentGroupWithTriggers = {
		id: null,
		name: '__DEFAULT_NAME__',
		triggerGroups: []
	};

	useEffect(() => {
		setIsLoading(true);
		setCannotLoad(false);
		if (segmentGroupId == null) {
			setIsLoading(false);
			setLoadedSegmentation(DefaultSegmentGroup);
		} else {
			segmentGroupServices?.getSegmentGroup(segmentGroupId, segmentGroup => {
				setIsLoading(false);
				setLoadedSegmentation(convertSegmentGroupApiTypeToEditingType(segmentGroup));
			}, err => {
				console.error(err);
				setCannotLoad(true);
				setIsLoading(false);
			});
		}
	}, []);

	return {
		isLoading,
		cannotLoad,
		loadedSegmentation
	};
}

