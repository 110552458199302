import React, { useState, useEffect } from 'react';
import Btn from '../../../../Components/Btn';
import Confirm from '../../../../Components/Confirm';
import { onSiteContext } from '../context';
import { Trans, useTranslation } from 'react-i18next';
export default function EditCampaignDelete() {
	const {
		systemServices,
		accountId,
		ReportingCampaignServices,
		modalCampaignToDeleteIsOpen,
		openModalCampaignDelete,
		currentCampaign, setreloadTheOperationItem, setneedFetchList
	} = onSiteContext();
	const [t, i18n] = useTranslation('common');
	const deleteCampaign = () => {
		setreloadTheOperationItem(true);

		ReportingCampaignServices.deleteACampaign(accountId, currentCampaign.id,
			success => {
				setreloadTheOperationItem(false);
				setneedFetchList(true);
				systemServices.showSuccess(t('EditCampaignDelete.success'));
				openModalCampaignDelete(false, currentCampaign.id);
			},
			(error) => {
				setreloadTheOperationItem(false);
				systemServices.showError(t('EditCampaignDelete.error'));
			});
	};

	return (
		<Confirm
			title={t('EditCampaignDelete.doYouWant')}
			text={currentCampaign.name}
			isOpen={modalCampaignToDeleteIsOpen}
			onClose={() => openModalCampaignDelete(false, currentCampaign.id)}
			confirmCallback={() => deleteCampaign()}
			confirmText={t('EditCampaignDelete.delete')}
			cancelText={t('EditCampaignDelete.cancel')}
			confirmColor = "alert"
		/>
	);
}
