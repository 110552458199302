import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
import { nFormat, toReadableDuration } from '../../util';

const BuyingBevaviorBarChart = ({
	data = { datasets: [], labels: [], type: 'bar', title: '', isMultipleDimensions: false },
	typeChart,
	listOfMetricsLabel = '',
}) => {
	const columnChartOptions = {
		exporting: {
			showTable: false,
				tableCaption: 'Data table',
				csv: {

					columnHeaderFormatter: function(item, key) {
						if (!item || item instanceof Highcharts.Axis) {
							return 'Date'
						} else {
							return item.name;
						}
					},
					dateFormat: '%Y-%m-%d'
				},
			buttons: {
				contextButton: {
					menuItems: [
						'printChart',
						'separator',
						'downloadPNG',
						'downloadJPEG',
						'downloadPDF',
						'downloadSVG',
						'downloadCSV'
					]
				}
			}
		},
		chart: {
			height: 300,
			type: typeChart,
		},
		navigation: {
			buttonOptions: {
				verticalAlign: 'top',
				x: -50,
			}
		},
		title: {
			text: '',
		},
		legend: {
			symbolRadius: 0,
			symbolWidth: 40,
			itemStyle: {
				fontFamily: 'Roboto',
				fontWeight: 'Light',
				left: '100px',
				top: '100px',
			},
			borderRadius: 3,
			align: 'center',
			verticalAlign: 'bottom',
		},
		credits: {
			enabled: false
		},
		xAxis: 
			{
				gridLineWidth: 0,
				lineColor: 'black',
				lineWidth: 1,
				categories: data.labels,
				tickLength: 10,
				tickWidth: 1,
			}
		,
		yAxis: [
			{
				// Primary yAxis
				gridLineWidth: 1,
				gridLineColor: '#b5b5b561',
				labels: {
					format: listOfMetricsLabel.includes('ratio') ||listOfMetricsLabel.includes('ProductQuantity')  ? '{value}%' : '',
					style: {
						color: '#293133',
					},
				},
				title: {
					text:''
				},
				alignTicks: false,
				opposite: false,
				crosshair: true
			}
		],
		plotOptions: {
			column: {
				borderColor: '#9E9E9E',
				grouping: false,
				shadow: false,
				borderWidth: 0,
			},
			line: {
				grouping: true,
				shadow: false,
				dashStyle: 'long',
			},
			series: {
				marker: {
					fillColor: '#FFF',
					lineWidth: 1,
					lineColor: null // inherit from series
				}
			},
			areaspline: {
				fillOpacity: 0.3
			}
		},
		opposite: true,
		tooltip: 
		// data.title.contains('Revenue') ? {
		// 	shared: true,
		// 	backgroundColor: '#38444A',
		// 	color: '#FFF',
		// 	style: {
		// 		color: "#FFFFFF",
		// 	},
		// 	formatter: function (tooltip) {
		// 		if (data.title.contains('rate')) {
		// 			const serie = this.points[0];
		// 			const serie2 = this.points[1];
		// 			return	`<strong> ${serie.key} </strong><br>
		// 			<b>Revenue</b><span style='color:${serie.color}'> \u25CF</span> ${serie.y} €<br>
		// 			<b>Conversion rate</b><span style='color:${serie2.color}'>\u25CF</span>${serie2.y }%`;
		// 		} else {
		// 			const serie = this.points[0];
		// 			return	`<strong> ${serie.key} </strong><br>
		// 			<b>Revenue</b><span style='color:${serie.color}'> \u25CF</span> ${serie.y} €<br>`;
		// 		}
		// 	}

		// }:
		// data.title.contains('ratio') ? {
		// 	shared: true,
		// 	backgroundColor: '#38444A',
		// 	color: '#FFF',
		// 	style: {
		// 		color: "#FFFFFF",
		// 	},
		// 	formatter: function (tooltip) {
		// 			const serie = this.points[0];
		// 			return	`<strong> ${serie.key} </strong><br>
		// 			<b>${data.title}</b><span style='color:${serie.color}'> \u25CF</span> ${serie.y} %<br>`;
		// 		}
		// }
		// 	:
				{
					shared: true,
					backgroundColor: '#38444A',
					color: '#FFF',
					style: {
						color: '#FFFFFF',
					},
				},
		series: data.datasets
	};


	return (
		<HighchartsReact
			className='chart'
			highcharts={Highcharts}
			options={columnChartOptions}
			constructorType={'chart'}
		/>);

};

BuyingBevaviorBarChart.displayName = 'PerformanceBarChart';

export default BuyingBevaviorBarChart;