import React, { useState, useEffect, useCallback } from 'react';
import './TemplateDisplay.css';
import { CampaignsContext } from '../../context';
import { ReactLiquid } from 'react-liquid';
import { propertiesProcessing } from '../../util';
import SaveStyleModal from './SaveStyleModal';
import Iframe from '../../../../Components/Iframe';

import { useTranslation } from 'react-i18next';
export default function TemplateDisplay() {
	const [t] = useTranslation('common');

	const { getLiquidModel, hasCountDowns } = propertiesProcessing();

	const {
		getCurrentVariation,
		currentVariationId,
		editorState,
		currentDevice,
		editorMode,
		setWysiwygSurveyTarget
	} = CampaignsContext();

	const [formatReady, setFormatReady] = useState(false);
	const [otherProperties, setOtherProperties] = useState({});
	const [modelProperties, setModelProperties] = useState([]);
	const [liquidModel, setLiquidModel] = useState({});
	const [modelHasCountDowns, setModelHasCountDowns] = useState(false);

	const currentVariation = getCurrentVariation() || {};

	useEffect(() => {
		setFormatReady(editorState.currentWysiwyg && !!editorState.currentWysiwyg.Template);
	}, [currentVariationId]);

	useEffect(() => {
		setFormatReady(!!editorState.selectedFormat.Identifier);
		if (editorState.sideBarStep === 1) {
			editorState.selectedFormat.defaultStyle && setModelProperties(editorState.selectedFormat.defaultStyle.properties || []);
		} else if (editorState.sideBarStep === 2) {
			setModelProperties([...editorState.selectedStyle.properties || []]);
		}
	}, [editorState]);

	useEffect(() => {
		if (editorState.sideBarStep === 3) {
			setModelProperties([...currentVariation.Properties || []]);
		}
	}, [currentVariation, currentVariationId]);

	const refreshTemplateDisplay = () => {
		const values = getLiquidModel(modelProperties);
		const result = {
			...values,
			...otherProperties,
		};
		setLiquidModel(result);
	};

	useEffect(() => {
		refreshTemplateDisplay();
		setModelHasCountDowns(hasCountDowns(modelProperties));
	}, [modelProperties, otherProperties]);

	useEffect(() => {
		const getCssFromDescriptor = () => {
			const descriptor = currentDevice === 'desktop' ? currentVariation.TemplateDescriptorDesktop : currentVariation.TemplateDescriptorMobile;
			return descriptor && descriptor.EditableCss;
		};
		const getCssFromSelectedFormat = () => {
			if (editorState.selectedFormat) {
				const descriptor = currentDevice === 'desktop' ? editorState.selectedFormat.SlideTemplateDescriptorDesktop : editorState.selectedFormat.SlideTemplateDescriptorMobile;
				return descriptor && descriptor.Css;
			}
			return null;
		};
		const currentVariationTemplate = currentVariation && currentVariation.Template;
		const currentVariationAndFormatHaveSameIdentifier = () => (!currentVariationTemplate.Identifier || currentVariationTemplate.Identifier !== editorState.selectedFormat.Identifier);
		const templateIsNotChosen = editorState.sideBarStep === 1 && currentVariationTemplate && currentVariationAndFormatHaveSameIdentifier();

		const css = templateIsNotChosen ? getCssFromSelectedFormat() : getCssFromDescriptor();
		css && setOtherProperties(x => ({ ...x, Css: css }));
	}, [currentDevice, currentVariation]);

	// periodically refresh countdowns
	const [seconds, setSeconds] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds(seconds => seconds + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (modelHasCountDowns)
			refreshTemplateDisplay();
	}, [seconds]);

	const searchSurveyTarget = (node) => {
		const target = node.querySelector('#formSurveyCustom');
		setWysiwygSurveyTarget(target);
	};

	const handleRenderWysiwyg = useCallback((node) => {
		if (node) {
			const obs = new MutationObserver(() => {
				searchSurveyTarget(node);
			});
			obs.observe(node, {childList: true});
			searchSurveyTarget(node);
		}
	}, []);

	return (
		<>
			{editorMode !== 'appNative/patch/code' &&
				<div className={'format_preview flex_item_full scroll_custom ' + currentDevice}>
					{formatReady &&
						<>
							<div className="format_mockup" style={{}}>
								<ReactLiquid
									template={editorState.currentWysiwyg.Template || ''}
									data={liquidModel}
									render={(renderedTemplate) => {
										return (
											<Iframe className="format_mockup_inner">
												<style>
													@import url('https://beyableprod.blob.core.windows.net/fonts/_v1/all.min.css');
												</style>
												<span className={currentDevice} dangerouslySetInnerHTML={renderedTemplate} ref={handleRenderWysiwyg} />
											</Iframe>
										);
									}}
								/>
							</div>
						</>
					}
					{!formatReady && editorMode !== 'appNative/patch/code' && 
						<div className="format_preview_empty">
							<img className="format_preview_empty_img" src={'/Assets/format_editor_empty.svg'} />
							<div className="format_preview_empty_title">{t('designEditor.welcomeToDesign')}</div>
							<div className="format_preview_empty_text">
								{t('designEditor.objective')}
							</div>
							<div className="format_preview_empty_sub">
								{t('designEditor.needHelp')}
							</div>
						</div>
					}
				</div>
			}
			{formatReady && editorState.sideBarStep === 3 &&
				<SaveStyleModal></SaveStyleModal>
			}
			{ editorMode === 'appNative/patch/code' && <>
				<div className="format_preview_empty">
					Format mobile
				</div>
			</>}
		</>
	);
}