import React, { useState, useEffect } from 'react'
import './HierarchizationProductFeedContainer.css';
import Btn from '../../../../Components/Btn'
export default function HierarchizationProductFeedContainer({ setHierarchizationFeedOpen, accountConfigurationFeed,
    setAccountConfigurationFeed, setSaveTheHierarchieInBack, setActiveFeed }) {

    function sortByIndex(a, b) {
        if (a && b) {
            if (a.index < b.index) return -1;
            if (a.index > b.index) return 1;
        }
        return 0;
    }
    const [arrayOfNewOrderFeed, setArrayOfNewOrderFeed] = useState()
    const listFeed = [
        {
            value: 'Csv',
            label: 'CSV',
        },
        {
            value: 'Xml',
            label: 'XML',
        },
        {
            value: 'Custom',
            label: 'CUSTOM',
        },
        {
            value: 'OnSiteData',
            label: 'BEYABLE',
        },
        {
            value: 'Prestashop',
            label: 'PRESTASHOP',
        },
        {
            value: 'Web',
            label: 'HTTP',
        },
        {
            value: 'Ftp',
            label: 'FTP',
        },
    ]
    useEffect(() => {
        if (accountConfigurationFeed) {
            setArrayOfNewOrderFeed(accountConfigurationFeed)
        }
    }, [accountConfigurationFeed])
    const upTheProductFeedInHierarchie = (e) => {
        // 1   je recupère l'index du feed en question  
        let productToChange = arrayOfNewOrderFeed.filter(elem => elem.index === e)[0]
        //2 index à prendre 
        const indexToGet = productToChange.index - 1
        // 3 je récupère celui qui était à sa place 
        let productFeedToReplace = arrayOfNewOrderFeed.filter(elem => elem.index === indexToGet)[0]
        //4 je change l'index de celui qui se fait piquer la place
        productFeedToReplace.index = e
        //5 je change l'index de mon nouvel élément
        productToChange.index = indexToGet
        //6 je créé un nouveau tableau sans ces 2 éléments 
        let arrayWithoutElements = arrayOfNewOrderFeed.filter(elem => elem.index !== e && elem.index !== indexToGet)
        // 7 je créé un nouveau tableau 
        setArrayOfNewOrderFeed([productToChange, productFeedToReplace, ...arrayWithoutElements])
        setActiveFeed({ source: productToChange.source.sourceType, format: productToChange.source.sourceFormat, index: productToChange.index })
    }
    const downTheProductFeedInHierarchie = (e) => {
        let productToChange = arrayOfNewOrderFeed.filter(elem => elem.index === e)[0]
        const indexToGet = productToChange.index + 1
        let productFeedToReplace = arrayOfNewOrderFeed.filter(elem => elem.index === indexToGet)[0]
        productFeedToReplace.index = e
        productToChange.index = indexToGet
        let arrayWithoutElements = arrayOfNewOrderFeed.filter(elem => elem.index !== e && elem.index !== indexToGet)
        setArrayOfNewOrderFeed([productToChange, productFeedToReplace, ...arrayWithoutElements])
        setActiveFeed({ source: productToChange.source.sourceType, format: productToChange.source.sourceFormat, index: productToChange.index })
    }

    const saveTheHierarchie = () => {
        if (arrayOfNewOrderFeed) {
            setAccountConfigurationFeed(arrayOfNewOrderFeed)
            setSaveTheHierarchieInBack(true)
        }
        setHierarchizationFeedOpen(false)
    }

    return (

        <div className="Hierarchization_container">
            <h1>Order your feed</h1>
            <p>In this section, you can classify your different product feed source by the importance of the information. </p>
            <div className="hierarchization_return_btn" onClick={(e) => saveTheHierarchie(false)}>
                <i className="fas fa-long-arrow-alt-left"></i>
            </div>
            <ul>
                {arrayOfNewOrderFeed && arrayOfNewOrderFeed.sort(sortByIndex).map((productFeed, i) =>
                    <li key={i}>
                        <span onClick={(e) => downTheProductFeedInHierarchie(productFeed.index)}>
                            {
                                i !== arrayOfNewOrderFeed.length - 1 &&
                                <i className="fas fa-sort-down"></i>
                            }
                        </span>
                        <div>
                            <h2> {productFeed.index + 1} : {productFeed.source.sourceFormat && productFeed.source.sourceFormat + " / "}  {listFeed.filter(x => x.value === productFeed.source.sourceType)[0].label}</h2>
                            <p>
                                {productFeed.source.sourceUrl && productFeed.source.sourceUrl}
                                {productFeed.source.sourceType === "OnSiteData" && "Beyable"}
                            </p>
                        </div >
                        <span onClick={(e) => upTheProductFeedInHierarchie(productFeed.index)}>
                            {
                                i !== 0 &&
                                <i className="fas fa-sort-up"></i>
                            }
                        </span>
                    </li>
                )
                }
            </ul>
            {/* <div className="btn_hierarchization_container">
                <Btn
                    onClickFunction={(e) => setHierarchizationFeedOpen(false)}
                    message="Cancel"
                    color="secondary"
                />
               
            </div> */}
        </div>

    )
}
