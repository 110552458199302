import React, { useState } from 'react';
import Btn from '../../../Components/Btn';
import SelectCustom from '../../../Components/SelectCustom';
import ConnectionSwitch from '../Edition/Connection/ConnectionSwitch';
import InputCustom from '../../../Components/InputCustom';
import { typeConnection, getFeedTypePictoClass } from '../utils';
import { useProductFeedEditionContext } from '../context/ProductFeedEditionContext';
import { FeedCardHeader } from './FeedCardHeader';
import SectionMessage from '../../../Components/SectionMessage';
import ConnectionChooseAuth from '../Edition/Connection/ConnectionChooseAuth';
export default function CreateUriFeed() {
	const {
		hasErrorsOnCreation,
		stateOfBackendDetection,
		handleChangeSimpleField,
		productFeedObject,
		handleChooseATypeSourceConnection,
		handleGetMappingSources,
		messageInfos,
		isEmptyIdentifier,
		setisEmptyIdentifier
	} = useProductFeedEditionContext();
	const [seePasswordField, setseePasswordField] = useState(false);
	const pictoClass = getFeedTypePictoClass(productFeedObject?.type);
	function handleChangeSimpleFieldFn(e) {
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name, value);
	}

	const shouldChooseAuth = stateOfBackendDetection === 'Authentication' && !productFeedObject?.auth?.type;

	return (<>
		{stateOfBackendDetection === 'ResourceIdentifier' &&
			<>
				<FeedCardHeader title="What is your feed URL?" />
				<InputCustom
					value={productFeedObject.resourceIdentifier ?? ''}
					type={'text'}
					label={'URL'}
					name={'resourceIdentifier'}
					placeholder="https://exemple.com/feed"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					onEnter={() => handleGetMappingSources()}
					fullWidth={true}
					size="l"
					autoFocus={true}
				/>
			</>
		}
		{stateOfBackendDetection === 'Authentication' && productFeedObject?.auth?.type &&
			<>
				<FeedCardHeader title="Authentication required" />
				<ConnectionSwitch />
			</>
		}
		{shouldChooseAuth &&
			<>
				<FeedCardHeader title="Authentication required" />
				<ConnectionChooseAuth />
			</>
		}
		{stateOfBackendDetection === 'ResourceIdentifierAndOrAuthentication' &&
			<>
				<FeedCardHeader title="Authentication required" />
				<InputCustom
					value={productFeedObject.resourceIdentifier ?? ''}
					type={'text'}
					label={'URL'}
					name={'resourceIdentifier'}
					placeholder="https://exemple.com/feed"
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					fullWidth={true}
					size="l"
					autoFocus={true}
				/>
				<SelectCustom
					label='Connection type'
					optionsList={typeConnection}
					defaultValueDisabled={{
						value: 'None',
						label: 'Choose a connection type',
						isDisabled: true
					}}
					value={productFeedObject.auth.type}
					onChange={(e) => handleChooseATypeSourceConnection(e.target.value)}
					autoWidth={true}
					size="l"
				/>
				<ConnectionSwitch />
			</>
		}
		<div>
			{hasErrorsOnCreation && !hasErrorsOnCreation?.key &&
				<SectionMessage
					type="warning"
					title="Error during process"
					text={hasErrorsOnCreation}
				/>
			}
			{isEmptyIdentifier &&
				<SectionMessage
					type={messageInfos.type}
					title={messageInfos.title}
					text={messageInfos.text}
					verticalSize="s"
				/>
			}
		</div>
		{!hasErrorsOnCreation &&
			<div className='mt_30 al_right'>
				<Btn
					onClickFunction={() => handleGetMappingSources()}
					size="l"
					message="Connection"
					color="primary" />
			</div>
		}
		{hasErrorsOnCreation && !shouldChooseAuth &&
			<div className='mt_30 al_right'>
				<Btn
					onClickFunction={() => {setisEmptyIdentifier(); handleGetMappingSources();}}
					size="l"
					message="Retry Connection"
					color="primary" />
			</div>
		}
	</>
	);
}
