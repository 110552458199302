import React, {useEffect, useState} from 'react';
import { cardFetchOptions } from './config';
import { useGeoContext } from '../../context/GeolocContext';
import { v4 as uuidv4 } from 'uuid';
import {
	monday,
	formatToday,
	search
} from './config';
import PanelArticle from '../../../../Components/PanelArticle';
import SelectDropdown from '../../../../Components/SelectDropdown';
import IconBtn from '../../../../Components/IconBtn';
import ReactTooltip from 'react-tooltip';
export default function UtmContainerPages({ sessionsServices,
	utmDimensionDraft,
	changeUTMSelected,
	utmKeyName,
	nameUtm,
	deleteUtmItem
}) {

	const {
		navFilter,
	} = useGeoContext();
	const tooltipID = uuidv4();
	const tooltipID2 = uuidv4();
	const [valuesForSelect, setValuesForSelect] = useState([]);
	const [searchResult, setsearchResult] = useState();
	const [inputSearch, setinputSearch] = useState('');
	const [list, setList] = useState([]);
	const [autocompleteIsLoading, setautocompleteIsLoading] = useState(false);

	function handleValidSelection(v, src,i) {
		changeUTMSelected(v, src,i);
		setinputSearch('');
		setList([]);
	}
	useEffect(() => {
		if (list && list.length > 0) {
			const tmpList = list.map(i => {
				return {
					value: i.name,
					label: i.label
				};
			});
			setValuesForSelect(tmpList);
			setsearchResult(tmpList);
		} else {
			setsearchResult();
		}
	}, [list]);

	if (!list) {
		return null;
	}
	const handleChangeSearch = (event) => {
		setinputSearch(event);
		search(setsearchResult,valuesForSelect.map(v => v.value), event,valuesForSelect);
	};

	useEffect(() => {
		cardFetchOptions.fromDate = navFilter.fromDate || monday;
		cardFetchOptions.toDate = navFilter.toDate || formatToday;
		cardFetchOptions.filters = navFilter.filters;
		cardFetchOptions.dimensions = [utmKeyName];
		cardFetchOptions.search = {
			dimension: utmKeyName,
			value: inputSearch
		};	
		const timer = setTimeout(() => {
			if (inputSearch.length > 0) {
				setautocompleteIsLoading(true);
				sessionsServices.getSessions(
					cardFetchOptions,
					success => {
						const newArray = success.Items.map(el => {
							return {
								name: el.Dimensions[utmKeyName],
								label: el.Dimensions[utmKeyName]
							};
						});
						setList(newArray);
						setautocompleteIsLoading(false);
					},
					err => {
						setautocompleteIsLoading(false);
					}
				);
			}
		}, 500);
		return () => {
			clearTimeout(timer);
		};
	}, [inputSearch]);

	function handleAddUtmItem(nameUtm) {
		changeUTMSelected('', nameUtm, utmDimensionDraft[nameUtm].length );
	}

	return (
		<PanelArticle size="s">
			<div className="flex">
				<div className="flex_item_full fw_medium">
					UTM {nameUtm}
				</div>
				<ReactTooltip
					id={tooltipID}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="click"
					delayShow={600}
				/>
				<div className='flex_item_fix ml_20'>
					<IconBtn
						icon="fas fa-plus"
						tooltip="Add UTM filter"
						onClick={() => handleAddUtmItem(nameUtm)}
					/>
				</div>
			</div>
			{utmDimensionDraft[nameUtm].map((el, i) => 
				<div className='flex flex_justify_between mt_15 mb_5' key={i}>
					<ReactTooltip
						id={tooltipID2}
						backgroundColor="black"
						effect="solid"
						place="bottom"
						globalEventOff="click"
						delayShow={600}
					/>
					<SelectDropdown
						optionsList={searchResult}
						value={el.name}
						onChange={(v, utmType) => handleValidSelection(v,nameUtm,i)}
						friendlyValue={el.label}
						autocomplete={true}
						onAutocomplete={(v) => handleChangeSearch(v)}
						autocompleteValue={inputSearch}
						autocompletePlaceholder={'Search for a UTM' + ' ' + nameUtm}
						autocompleteIsLoading={autocompleteIsLoading}
						fullWidth={true}
					/>
					<div className="flex_item_fix ml_10">
						<IconBtn
							icon="fas fa-trash"
							tooltip="Remove UTM filter"
							onClick={() => deleteUtmItem(nameUtm,i)}
							hoverColor="alert"
						/>
					</div>
				</div>
			)}					
		</PanelArticle>
	);
}
