import React from 'react';
import { useEcommerceTransactionContext } from '../../../context/EcommerceTransactionContext';
import { useEcommerceAnalyticsContext } from '../../../context/EcommerceAnalyticsContext';
import NavTransaction from '../../Transaction/NavTransaction';

import TransactionBoardContainer from '../../Transaction/TransactionBoardContainer';


import UtmFiltersTransactionsContainer from './UtmFiltersTransactionsContainer';
import SelectDevice from '../../Devices/SelectDevice';
import SourceTypeFilter from '../../SourceType/SourceTypeFilter';

export default function TransactionContainer({
	styles,
	services,
	systemServices,
	sessionService
}) {
	const {
		// Tab state & utils
		activeSubDimensionTab,
		changeActiveSubDimensionTab,
		handleChangeReportType,
		reportTypePerformanceSelected
	} = useEcommerceAnalyticsContext();

	const {
		// Tab state & utils
		initiNavDate,
		accountId,
		navFilter,
		setNavFilter,
		handleSelectComparaisonPeriodHandler,
		sourceType,
		handleChangeSourceType,
		sourceTypes,
		resetSource,
		isCustomSelected,
		setisCustomSelected,
		customDateRange,
		setCustomDateRange,
		devices,
		deviceSelected,
		handleChangeDeviceSelected,
		resetDevices,
	} = useEcommerceTransactionContext();
	let initialDates = initiNavDate(navFilter);
	if (activeSubDimensionTab !== 'transaction') {
		return <></>;
	}
	return (
		<div className="flex_item_full">
			<div className="analytics_main_line">
				<NavTransaction
					// ComparisonSelected={ComparisonSelected}
					setNavFilter={setNavFilter}
					initialDates={initialDates}
					handleSelectComparaisonPeriodHandler={handleSelectComparaisonPeriodHandler}
					setisCustomSelected={setisCustomSelected}
					isCustomSelected={isCustomSelected}
					customDateRange={customDateRange}
					setCustomDateRange={setCustomDateRange}
				/>
				<SourceTypeFilter
					sourceTypeSelected={sourceType}
					handleChangeSourceType={handleChangeSourceType}
					initialValue={sourceTypes}
					resetSource={resetSource}
				/>
				<SelectDevice
					resetSource={resetDevices}
					initialValue={devices}
					devices={devices}
					deviceSelected={deviceSelected}
					handleChangeDeviceSelected={handleChangeDeviceSelected}
				/>
				<UtmFiltersTransactionsContainer 
					transactionServices={sessionService}
				/>
			</div>
			<div className="analytics_body">
				<div className="analytics_body_main">
					<TransactionBoardContainer
						services={services}
						systemServices={systemServices}
						dimension={reportTypePerformanceSelected}
					/>
				</div>
	
			</div>
		</div>
	);
}
