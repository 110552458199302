
const listFeedType = [
    {
        value: 'Csv',
        label: 'CSV',
    },
    {
        value: 'Xml',
        label: 'XML',
    },
    // {
    //     value: 'Custom',
    //     label: 'CUSTOM',
    // },
]
export default listFeedType;