import React from 'react'
import SelectCustom from '../../../../Components/SelectCustom'
import InputCustom from './../../../../Components/InputCustom';
import FilterTypeList from './FilterTypeList'
import ListOFCategoriesField from './ListOfCategoriesFields'
import Btn from '../../../../Components/Btn'
import useSort from '../../../../Hooks/useSort'
import DatePicker, { registerLocale } from "react-datepicker";
export default function GroupOperatorItem({ fieldList, operator, selectNameOfQuery, selectOperatorOfQuery, handleChangeValueOfQuery, createNewField, deleteAField, handleChangeFieldNameOfQueryTags, selectTypeTags }) {
    const { sortById } = useSort()

    return (
        <>
            <div className="filter-search-product-feed-container">
                <div className="operator-selector-value">
                    <p>{operator}</p>
                </div>
                <div className="container-operator-selector">
                    <div>
                        {fieldList.sort(sortById).map((elem, index) =>
                            <div className="container-operator-fields" key={index}>
                                <div className="selector-operator-fields">
                                    <SelectCustom
                                        optionsList={ListOFCategoriesField}
                                        name="nameOfQuery"
                                        onChangeFunction={(e) => selectNameOfQuery(operator, e, elem.id)}
                                        defaultValueDisabled={{
                                            value: "choose",
                                            label: "Choose a name field",
                                            isDisabled: true
                                        }}
                                        label=""
                                        value={elem.nameOfQuery}
                                    />
                                    {elem.nameOfQuery !== "tags" ?
                                        <>
                                            <SelectCustom
                                                optionsList={FilterTypeList.filter(filterType => filterType.type === elem.type)[0].filter}
                                                name="operatorOfQuery"
                                                onChangeFunction={(e) => selectOperatorOfQuery(operator, e, elem.id)}
                                                defaultValueDisabled={{
                                                    value: "choose",
                                                    label: "Choose an operator",
                                                    isDisabled: true
                                                }}
                                                label=""
                                                value={elem.operatorOfQuery}
                                            />
                                        </>
                                        :
                                        <>
                                            <SelectCustom
                                                optionsList={FilterTypeList.filter(filterType => filterType.type === elem.type)[0].filter}
                                                name="nameOfQuery"
                                                onChangeFunction={(e) => selectTypeTags(operator, e, elem.id)}
                                                defaultValueDisabled={{
                                                    value: "choose",
                                                    label: "Choose a type of search",
                                                    isDisabled: true
                                                }}
                                                label=""
                                                value={elem.searchTypeTags}
                                            />

                                        </>
                                    }

                                    <div className="trash-field-operator" title="Delete the field" onClick={(e) => deleteAField(operator, elem.id)}><i className="fas fa-trash"></i> </div>
                                </div>
                                {elem.nameOfQuery === "tags" &&
                                    <div className="container-search-tag-feed">
                                        <InputCustom
                                            label=""
                                            type="text"
                                            placeholder={`Tag name`}
                                            value={elem.fieldNameOfQueryTags}
                                            name="valueOfQuery"
                                            onChange={(e) => handleChangeFieldNameOfQueryTags(operator, e, elem.id)}
                                        />
                                        <SelectCustom
                                            optionsList={elem.searchTypeTags === "value" ?
                                                FilterTypeList.filter(filterType => filterType.type === "string")[0].filter
                                                :
                                                FilterTypeList.filter(filterType => filterType.type === "number")[0].filter
                                            }
                                            name="operatorOfQuery"
                                            onChangeFunction={(e) => selectOperatorOfQuery(operator, e, elem.id)}
                                            defaultValueDisabled={{
                                                value: "choose",
                                                label: "Choose an operator",
                                                isDisabled: true
                                            }}
                                            label=""
                                            value={elem.operatorOfQuery}
                                        />
                                    </div>
                                }
                                {
                                    elem.searchTypeTags === "dateValue" ?
                                        <>
                                            <DatePicker
                                                selected={elem.valueOfQuery !== null && moment(elem.valueOfQuery).toDate()}
                                                onChange={(e) => handleChangeValueOfQuery(operator, e, elem.id, "date")}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </>
                                        :
                                        <div className="container-operator-value">
                                            <InputCustom
                                                label=""
                                                type={elem.nameOfQuery !== "tags" ? elem.type : elem.typeOfTagInput}
                                                placeholder={`Search ${elem.nameOfQuery}`}
                                                value={elem.valueOfQuery}
                                                name="valueOfQuery"
                                                onChange={elem.nameOfQuery !== "tags" ?
                                                    (e) => handleChangeValueOfQuery(operator, e, elem.id, elem.type) :
                                                    (e) => handleChangeValueOfQuery(operator, e, elem.id, elem.typeOfTagInput)
                                                }
                                            />
                                        </div>
                                }
                            </div>
                        )}
                    </div>
                    <div className="container-add-field">
                        <Btn
                            onClickFunction={(e) => createNewField(operator)}
                            message="Add Field"
                            icon="fas fa-plus"
                            style="outline"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
