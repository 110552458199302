import HttpServices from './HttpServices';

export default class SetupAccountServices {
	constructor($http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}

	// Get the account configuration 
	getAccountApi(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}/feature/${accountId}/accountapi`, callbackSuccess, callbackError);
	}
	putAccountApi(accountId, listScript, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}/feature/${accountId}/accountapi`, listScript, callbackSuccess, callbackError);
	}

	getAccountSetup(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}/feature/${accountId}/accountsetup`, callbackSuccess, callbackError);
	}
	putAccountSetup(accountId, reportUrl, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}/feature/${accountId}/accountsetup`, reportUrl, callbackSuccess, callbackError);
	}
	getAccountReferenceData(accountId,
		contextualdata,
		search,
		tenantSelected = null,
		callbackSuccess, callbackError) {

		let tenant = '';
		if (tenantSelected) {
			tenant = `&tenant=${tenantSelected}`;
		}
		this.HttpServices.get(`${this.BO_API_URL}referencedata/${accountId}/contextualdata?type=${contextualdata}&search=${search}` + tenant, callbackSuccess, callbackError);
	}

}