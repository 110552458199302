import {_hop} from '../../../Util/index';
export function dataUritoBlob(dataurl) {
	const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return { blob: new Blob([u8arr], { type: mime }), mime };
}


const mimeToExtensions = {
	'image/avif': '.avif',
	'image/bmp': '.bmp',
	'image/gif': '.gif',
	'image/vnd.microsoft.icon': '.ico',
	'image/jpeg': '.jpg',
	'image/png': '.png',
	'image/apng': '.apng',
	'image/svg+xml': '.svg',
	'image/tiff': '.tiff',
	'image/webp': '.webp'
};
export function imageMimeToExtension(mime) {
	if (_hop(mimeToExtensions,mime)) {
		return mimeToExtensions[mime];
	}
	return '';
}