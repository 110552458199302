import HttpServices from './HttpServices';
import MomentConstants from '../Constants/MomentConstants';

export default class ReportingCampaignServices {
	constructor($http, $routeParams) {
		this.campaignId = $routeParams.key;
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;//devonly/
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}

	getCampaignIndicator(accountId, campaignId, indicator, momentStartDate, momentEndDate, granularity, device, expandVariations, callbackSuccess, callbackError) {
		const indicatorEncoded = encodeURIComponent(indicator);
		this.HttpServices.get(`${this.BO_API_URL}performance/${accountId}/campaign/${campaignId}/${indicatorEncoded}?startDate=${momentStartDate.format(MomentConstants.MOMENT_API_FORMAT)}&endDate=${momentEndDate.format(MomentConstants.MOMENT_API_FORMAT)}&timeGranularity=${granularity}&device=${device}&expandVariations=${expandVariations}`, callbackSuccess, callbackError);
	}
	getSecondaryCampaignIndicator(accountId, campaignId, indicator, momentStartDate, momentEndDate, device, expandVariations, callbackSuccess, callbackError) {
		const indicatorEncoded = encodeURIComponent(indicator);
		this.HttpServices.get(`${this.BO_API_URL}performance/${accountId}/campaign/${campaignId}/${indicatorEncoded}?startDate=${momentStartDate.format(MomentConstants.MOMENT_API_FORMAT)}&endDate=${momentEndDate.format(MomentConstants.MOMENT_API_FORMAT)}&device=${device}&expandVariations=${expandVariations}`, callbackSuccess, callbackError);
	}
	getIndicators(accountId, campaignId, indicators, momentStartDate, momentEndDate, granularity, device, expandVariations, callbackSuccess, callbackError) {

		const arrayOfIndicators = (indicators || '').split(',');
		const indicatorsEncoded = arrayOfIndicators.map(indicator => encodeURIComponent(indicator));
		this.HttpServices.get(`${this.BO_API_URL}performance/${accountId}/campaign/${campaignId}/indicators?device=${device}&startDate=${momentStartDate.format(MomentConstants.MOMENT_API_FORMAT)}&endDate=${momentEndDate.format(MomentConstants.MOMENT_API_FORMAT)}&indicators=${indicatorsEncoded}&expandVariations=${expandVariations}&timeGranularity=${granularity}`, callbackSuccess, callbackError);
	}
	searchIndicators(accountId, campaignId, searchTerm, offset, limit, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}/performance/${accountId}/campaign/${campaignId}/search?indicator=${searchTerm}&offset=${offset}&limit=${limit}`, callbackSuccess, callbackError);
	}
	getAllSecondaryIndicators(accountId, campaignId, indicators, momentStartDate, momentEndDate, device, expandVariations, callbackSuccess, callbackError) {
		const arrayOfIndicators = (indicators || '').split(',');
		const indicatorsEncoded = arrayOfIndicators.map(indicator => encodeURIComponent(indicator));
		this.HttpServices.get(`${this.BO_API_URL}performance/${accountId}/campaign/${campaignId}/indicators?device=${device}&startDate=${momentStartDate.format(MomentConstants.MOMENT_API_FORMAT)}&endDate=${momentEndDate.format(MomentConstants.MOMENT_API_FORMAT)}&indicators=${indicatorsEncoded}&expandVariations=${expandVariations}`, callbackSuccess, callbackError);
	}
	updateSummaryIndicators(accountId, campaignId, summaryIndicators) {
		this.HttpServices.put(`${this.BO_API_URL}/performance/${accountId}/campaign/${campaignId}/summary`, summaryIndicators);
	}
	updateInteractionIndicators(accountId, campaignId, interaction, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}/performance/${accountId}/campaign/${campaignId}/interaction`, interaction, callbackSuccess, callbackError);
	}
	getCampaignInformations(accountId, campaignId, callbackSuccess, callbackError,signal) {
		this.HttpServices.get(`${this.BO_API_URL}performance/${accountId}/campaign/${campaignId}`, callbackSuccess, callbackError,null,signal);
	}
	getActualCampaignsForAccount(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.SERVER_URL}users/${accountId}/campaigns`, callbackSuccess, callbackError);
	}
	getServerSideCampaignsForAccount(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.SERVER_URL}users/${accountId}/campaigns?technicalPurpose=ServerSideCampaign`, callbackSuccess, callbackError);
	}
	getCampaignInformationsType(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}feature/${accountId}/configuration/campaign/${campaignId}`, callbackSuccess, callbackError);
	}
	getCampaignHasForm(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}v2/formsandsurveys/${accountId}/campaign/${campaignId}`, callbackSuccess, callbackError);
	}
	putStatusCampaign(accountId, campaignId, bool, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}/users/${accountId}/campaigns/${campaignId}?stoporgo=${bool}`, null, callbackSuccess, callbackError);
	}
	putStatusTestModeCampaign(accountId, campaignId, testMode, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}/users/${accountId}/campaigns/${campaignId}/testmode`, testMode, callbackSuccess, callbackError);
	}
	getStatstype(accountId, campaignId, startDate, endDate, granularity, statsType, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}emailingstatistics/${accountId}/campaign/${campaignId}/${statsType}?startDate=${startDate}&endDate=${endDate}&timeGranularity=${granularity}`, callbackSuccess, callbackError);
	}
	getStockAlertStatsWithoutGranularity(accountId, campaignId, startDate, endDate, statsType, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}emailingstatistics/${accountId}/campaign/${campaignId}/${statsType}?startDate=${startDate}&endDate=${endDate}`, callbackSuccess, callbackError);
	}
	putUserPermissionsForCampaign(accountId, campaignId, permissions, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}/users/${accountId}/campaigns/${campaignId}/permissions`, permissions, callbackSuccess, callbackError);
	}
	deleteACampaign(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.SERVER_URL}/users/${accountId}/campaigns/${campaignId}`, callbackSuccess, callbackError);
	}
	postACopyOfCampaign(accountId, data, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.SERVER_URL}users/${accountId}/campaigns/${campaignId}`, data, callbackSuccess, callbackError);
	}
	postTransformToNativeApp(accountId, data, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.SERVER_URL}users/${accountId}/native-app-campaign?campaignId=${campaignId}`, data, callbackSuccess, callbackError);
	}
	putRefreshCampaign(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}users/${accountId}/campaigns/${campaignId}/refresh`, null, callbackSuccess, callbackError);
	}
	getCampaignPerformance(accountId, campaignId,startDate,endDate,objective,attributionMode,interactionSelected, device,test,callbackSuccess, callbackError,signal){
		let testApi = '';
		if(test){
			testApi = `&test=${test}`;
		}
		let targetOrInteraction = '';
		if(interactionSelected){
			targetOrInteraction = `&targetOrInteraction=${interactionSelected}`;
		}
		let attribution = '';
		if(attributionMode){
			attribution = `&attributionMode=${attributionMode}`;
		}
		this.HttpServices.get(`${this.BO_API_URL}${accountId}/campaign-performance/${campaignId}/synthesis?startDate=${startDate}&endDate=${endDate}&objective=${objective}&device=${device}` +attribution + targetOrInteraction + testApi,  callbackSuccess, callbackError,null,signal);
	}
	putCampaignObjectifAttribution(accountId, campaignId,objectiveAttribution,callbackSuccess, callbackError){
		this.HttpServices.put(`${this.BO_API_URL}performance/${accountId}/campaign/${campaignId}/objective-attribution`,objectiveAttribution,  callbackSuccess, callbackError);
	}
	searchInteractions(accountId, campaignId,label,offset,limit,callbackSuccess, callbackError){
		this.HttpServices.get(`${this.BO_API_URL}performance/${accountId}/campaign/${campaignId}/search-interactions?label=${label}&offest=${offset}&limit=${limit}`,  callbackSuccess, callbackError);
	}
	getCampaignConversion(accountId, campaignId,fromDate,toDate, callbackSuccess, callbackError,signal){
		this.HttpServices.get(`${this.BO_API_URL}${accountId}/campaign-conversion/${campaignId}?fromDate=${fromDate.format(MomentConstants.MOMENT_API_FORMAT)}&toDate=${toDate.format(MomentConstants.MOMENT_API_FORMAT)}`,
			callbackSuccess,
			callbackError,
			{Accept: 'text/csv'}, signal);
	}


}

