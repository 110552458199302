/* eslint-disable no-inner-declarations */
import React, {useState,useEffect} from 'react';

import {
	insightsKpis
} from '../KPI/typesKpiContainer.js';
import {CardTrafficContainer} from './CardTrafficContainer';
import { Article } from '../../../Components/Article/Article';
import kpiStyle from '../kpi.module.css';
import { Percent } from '../SumUp';
import Chart from '../Chart';
import { v4 as uuidv4 } from 'uuid';
import TrafficFilters from '../Traffic/TrafficFilters';
import { Section } from '../../../Components/Section/Section';
import SelectSplitDimension from './SelectSplitDimension';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import EmptyState from '../../../Components/EmptyState';
import { useTranslation } from 'react-i18next';
import {FORMAT} from '../config';
import { useTrafficContext } from '../context/ContextTraffic';
import moment from 'moment';
import {
	devices,
	pageTypes,
	engagementLevel
} from '../Traffic/config';

const colors = [
	'#4278F0',
	'#FFB320',
	'#D7AB67',
	'#002453',
	'#7E67D7',
	'#9E9E9E',
	'#3FAA58',
	'#0068EF',
	'#E89700'
];
export default function TrafficSections() {
	const {
		accountId,
		systemServices,
		insightsServices,
		navFilter,
		deviceSelected,
		pageTypeSelected,
		intentionSelected,
		metricTypeSelected,
		utmDimensionsSelected
	} = useTrafficContext();

	// const [selectedTimeGranularity, setSelectedTimeGranularity] =
	// useState('Day');
	const [t] = useTranslation(['kpi']);
	const [hasErrorOnTraffic, sethasErrorOnTraffic] = useState<boolean>(false);
	const [isLoadingData, setisLoadingData] = useState(false);
	const [noData, setnoData] = useState(false);
	const [firstLoading, setfirstLoading] = useState(true);

	const [selectedTab, setSelectedTab] = useState<string>('sessions');
	const [webSiteTraffic, setwebSiteTraffic] = useState<insightsKpis>(
		{
			sessions : {
				isLoading : true,
				value: 0,
			},
			visitors : {
				isLoading : true,
				value: 0,
			},
			pageView : {
				isLoading : true,
				value: 0,
			},
		}
	);

	const [chartData, setChartData] = useState<any>({
		charts: [{ datasets: [], labels: [], title: '' }],
		isLoading: true,
		isError: false,
	});

	function getTranslatedValue(config, value, translationParentKey) {
		const valueConfig = config.find(el => el.value.toLowerCase() === value.toLowerCase());
		if (!valueConfig) return value; 

		if (valueConfig.translation) {
			return t(valueConfig.translation.key, valueConfig.translation.vars);
		} else {
			return t(translationParentKey + '.' + valueConfig.label, {ns: 'kpi'});
		}
	}

	function getLabelByType (type, element) {
		if(type[0] === 'Device'){
			return getTranslatedValue(devices, element, 'device');
		}
		if(type[0] == 'PageType'){
			return getTranslatedValue(pageTypes, element, 'pageType');
		}
		if(type[0] == 'EngagementLevel'){
			return getTranslatedValue(engagementLevel, element, 'engagementLevel');
		}
	}

	useEffect(()=>{
		// Get KPIS datas
		const payloadData = {
			'filters': [
				{dimension: 'Device',
					values: [...deviceSelected]
				},
				{dimension: 'PageType',
					values: [
						...pageTypeSelected
					]  },
				{dimension: 'EngagementLevel',
					values: [
						...intentionSelected
					]} 
			],
			'fromDate': navFilter.fromDate,
			'toDate': navFilter.toDate,
			'utmSource': utmDimensionsSelected.source,
			'utmCampaign': utmDimensionsSelected.campaign,
			'utmContent': utmDimensionsSelected.content,
			'utmMedium':  utmDimensionsSelected.medium,
			'metrics': [
				'SessionsCount', 'visitorsCount', 'pagesCount'
			]
		};
		insightsServices.getWebtrafficSumOvertime(accountId,payloadData,(success)=> {
			const result  = {
				sessions : {
					name : t('traffic.sessions'),
					isLoading : false,
					value: success.sessionsCount,
				},
				visitors : {
					name : t('traffic.visitors'),
					isLoading : false,
					value: success.visitorsCount,
				},
				pageView : {
					name : t('traffic.pageViews'),
					isLoading : false,
					value: success.pagesCount,
				},
			};

			setwebSiteTraffic(result);


		}, (err,errData) => {
			console.log('🚀--** ~ file: KpiContainer.tsx:396 ~ insightsServices.getInsightsKpis ~ err:', err);
			if(errData.status === 404){
				sethasErrorOnTraffic(true);
				setwebSiteTraffic( {
					sessions : {
						name : t('traffic.sessions'),
						isLoading : false,
						value: 0,
					},
					visitors : {
						name : t('traffic.visitors'),
						isLoading : false,
						value: 0,
					},
					pageView : {
						name : t('traffic.pageViews'),
						isLoading : false,
						value: 0,
					},
				});
			}
			systemServices.showError('An error occurred while trying to get datas');
		});
	},[navFilter,deviceSelected,pageTypeSelected,intentionSelected,utmDimensionsSelected]);
	useEffect(()=>{
		let selectedTabValue;
		switch (selectedTab) {
			case 'sessions':
				selectedTabValue = 'SessionsCount';
				break;
			case 'pageView':
				selectedTabValue = 'PagesCount';
				break;
			case 'visitors':
				selectedTabValue = 'VisitorsCount';
				break;
			default:
				break;
		}
		
		const setupTimeGranularityOptions = () => {
			if (!navFilter) return;
			const dateRange = moment(navFilter.toDate, FORMAT).diff(
				moment(navFilter.fromDate, FORMAT),
				'days'
			);
			let granularityOptions;
		
			if (dateRange < 14) {
				granularityOptions = 'Day';
			} else if (dateRange < 32) {
				granularityOptions = 'MondayWeek';
			} else if (dateRange < 64) {
				granularityOptions = 'Month';
			} else if (dateRange < 366) {
				granularityOptions = 'Month';
			} else {
				granularityOptions = 'Year';
			}
			
			return granularityOptions;
		};
		const selectedTimeGranularity = setupTimeGranularityOptions();
		const dimensionSelected = metricTypeSelected.value !== 'none' ? metricTypeSelected.value : '';
		const payloadData = {
			'filters': [
				{dimension: 'Device',
					values: [...deviceSelected]
				},
				{dimension: 'PageType',
					values: [
						...pageTypeSelected
					] },
				{dimension: 'EngagementLevel',
					values: [
						...intentionSelected
					]
				}
			],
			granularity : selectedTimeGranularity,
			'fromDate': navFilter.fromDate,
			'toDate': navFilter.toDate,
			'utmSource': utmDimensionsSelected.source,
			'utmCampaign': utmDimensionsSelected.campaign,
			'utmContent': utmDimensionsSelected.content,
			'utmMedium':  utmDimensionsSelected.medium,
			dimension : metricTypeSelected.value !== 'none' ? metricTypeSelected.value : '' ,
			'metric': selectedTabValue};
		setisLoadingData(true);
		setChartData(d => ({
			... d,
			isLoading: true,
		}));
		insightsServices.getWebtrafficMetricMetricovertime(accountId,payloadData,(success)=> {
			setisLoadingData(false);
			setfirstLoading(false);
			function toDateLookup(values) {
				return values.reduce((acc, val) => {
					if (acc.hasOwnProperty(val.date)) {
						return { ...acc, [moment(val.date).format('MM DD YYYY')]: [...acc[moment(val.date).format('MM DD YYYY')], val] };
					}
					return { ...acc, [moment(val.date).format('MM DD YYYY')]: [val] };
				}, {});
			}
			function getNameVal (){
				const n:string = selectedTab || '';
				switch (n) {
					case 'sessions':
						return  'sessionsCount';
							
					case 'visitors':
						return 'visitorsCount';
								
					case 'pageView':
						return 'pagesCount';		
					default : return '';
				}
			}
			function getDimensionName (selectedTab){
				const n:string = selectedTab || '';
				switch (n) {
					case 'sessions':
						return t('traffic.sessions');
							
					case 'visitors':
						return t('traffic.visitors');
								
					case 'pageView':
						return t('traffic.pageViews');		
					default : return '';
				}
			}
			function transformValue(elem:number) {
				if(!elem) return;
				return  parseFloat(elem.toFixed(2));
			}
	
			if(dimensionSelected === ''){
				function getDataSets() {
					return datasetsNoDimensions();
				}
				if(success.length === 0){
					setChartData({
						charts: [{ datasets: [], labels: [], title: '' }],
						isLoading: true,
						isError: false,
					});
					return;
				}
				const data = success[0].dates;
				const dataLookup = toDateLookup(data);
				const datasetsNoDimensions = () => {
					let series:any= [];
					const series1 = {
						name: getDimensionName(selectedTab),
						data: [...dates].map(
							(date:any) => {
								const dateData = dataLookup[date];
								const name = getNameVal();
								if(dateData && dateData[0].hasOwnProperty(name)){
									const value = transformValue(dateData[0][name]);
									return value;
								}
								else{
									return 0;
								}
							}
						),
						color: colors[0],
						type: 'areaspline'
					};
					series = [series1];
					return series;
				};
				
				const dates = data.reduce((st, d) => st.add(moment(d.date).format('MM DD YYYY')), new Set());		
		
				const datasets = getDataSets();
				const labels = getLabels();
		
				// eslint-disable-next-line no-inner-declarations
				function getLabels() {
					return (dates &&
							[...dates].map((d) =>
								moment(d).format('MMM DD YYYY')
							)) ||
							[];
				}
				setChartData({
					charts: [
						{
							labels:labels,
							datasets,
							comparedPeriode: [],
							title: '',
							key: 0,
						},
					],
					isLoading: false,
					isError: false,
					isErrorEcommerce : false
				});

			}else{
				if(success.length === 0){
					setChartData({
						charts: [{ datasets: [], labels: [], title: '' }],
						isLoading: true,
						isError: false,
					});
					return;
				}
				let labels:any[] = [];
				const arrayOfDates = () => success.reduce((acc,curr) => {
			
					const dates = curr.dates.reduce((st, d) => st.add(moment(d.date).format('MM DD YYYY')), new Set());		
					const dataLookup = toDateLookup( curr.dates);
					const elementFormated = {
						name : getLabelByType(Object.keys(curr.dimension), curr.dimension[metricTypeSelected.value]),
						data : [...dates].map(
							(date:any) => {
								const dateData = dataLookup[date];
								const name = getNameVal();
								if(dateData && dateData[0].hasOwnProperty(name)){
									const value = transformValue(dateData[0][name]);
									return value;
								}
								else{
									return 0;
								}
							}
						),
						color: colors[acc.length],
						type: 'spline'
					};
				
					labels = getLabels();
					function getLabels() {
						return (dates &&
								[...dates].map((d) =>
									moment(d).format('MMM DD YYYY')
								)) ||
								[];
					}
					return acc = [...acc, elementFormated];

				}, []);
				const datasets = arrayOfDates();
				setChartData({
					charts: [
						{
							labels:labels,
							datasets,
							title: '',
							key: 0,
						},
					],
					isLoading: false,
					isError: false,
					isErrorEcommerce : false
				});
			}
		}, (err,errData) => {
			console.log('🚀--** ~ file: KpiContainer.tsx:396 ~ insightsServices.getInsightsKpis ~ err:', err);
			setisLoadingData(false);
			setfirstLoading(false);
			if(errData.status === 404){
				sethasErrorOnTraffic(true);
				setChartData({
					charts: [{ datasets: [], labels: [], title: '' }],
					isLoading: true,
					isError: false,
				});				
			}
			systemServices.showError('An error occurred while trying to get datas');
		});
	},[selectedTab, navFilter,deviceSelected,pageTypeSelected,intentionSelected,metricTypeSelected,utmDimensionsSelected]);

	useEffect(()=>{
		setnoData(chartData.charts.length == 0 || chartData.charts[0].datasets.length == 0);
	}, [chartData]);

	const objwebSiteTraffic = Object.keys(webSiteTraffic);

	return (
		<Section width='m'>
			<section className="section no_bottom_pad section_primary">
				<div className="h1">{t('traffic.traffic')}</div>
			</section>
			<section className="section">
				<div className={kpiStyle.section_header}>
					<TrafficFilters />
				</div>
				<div className={kpiStyle.section_part}>
					<Article innerSize='l' hasMargin={false}>
						<div className={kpiStyle.tabs_border}>
							<div className='flex'>
								<div className='flex_item_full'>
									<div className={kpiStyle.tabs}>
										{webSiteTraffic && !hasErrorOnTraffic &&
											<>
												{objwebSiteTraffic.map(item =>
													<div
														key={uuidv4()}
														className={selectedTab == item ? kpiStyle.tab_active : kpiStyle.tab}
														onClick={() => setSelectedTab(item)}
													>
														<Percent
															item={webSiteTraffic[item]}
															showEvolution={false}
														/>
													</div>
												)}
											</>
										}
									</div>
								</div>
								<div className='flex_item_fix ml_30'>
									<SelectSplitDimension />
								</div>
							</div>
						</div>
						{firstLoading &&
							<EmptyState
								spinner={true}
								title={t('common.loadingData')}
							/>
						}
						{!firstLoading && isLoadingData &&
							<SpinnerWheel />
						}
						{!firstLoading && noData && (
							<EmptyState
								imageUrl="/Assets/empty_no_data.svg"
								title={t('common.noDataForThisPeriod')}
								text={t('common.tryWithAnotherPeriod')}
							/>
						)}
						{!noData &&
							<Chart
								data={chartData.charts.find(arr => arr.key === 0)}
							/>
						}
					</Article>
				</div>
				<div className={kpiStyle.section_part}>
					<CardTrafficContainer
						metricTypeSessionsSelected={selectedTab}
					/>
				</div>
			</section>

		</Section>
	);
}
