import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditorHeader from '../../../../Components/EditorHeader';
import InputCustom from '../../../../Components/InputCustom';
import Breadcrumb from '../../../../Components/Breadcrumb';
import Confirm from '../../../../Components/Confirm';
import Btn from '../../../../Components/Btn';
import { useServerSideEditorContext } from '../context/ServerSideEditorContextProvider';
import { EditorCreateOrUpdateMode, EditorStep } from '../context/EditorStateTypes';

export default function Header() {
	const {
		editorState,
		mode,
		handleChangeStep,
		handleSaveCampaign,
		serverSideCampaign,
		handleCloseEditor,
		handleChangeName,
	} = useServerSideEditorContext();

	const { t } = useTranslation(['common']);

	const setStep = (value: EditorStep) => {
		handleChangeStep(value);
	};
	const step = editorState.sideBarStep;

	const [closeConfirmIsOpen, setCloseConfirmIsOpen] = useState<boolean>(false);
	const [btnCancelAndQuit, setbtnCancelAndQuit] = useState(<></>);

	function getBreadCrumbElements () {
		return [
			{ index: 1, toGo : EditorStep.One, title: t('designEditor.Design')},
			{ index: 2, toGo : EditorStep.Four, title: 'Information' },
		];
	}

	useEffect(() => {
		setbtnCancelAndQuit(<>
			<Btn
				onClick={() => setCloseConfirmIsOpen(true)}
				message={t('button.Cancel')}
				style="ghost"
				color="secondary"
			/>

			{mode === EditorCreateOrUpdateMode.Create &&
				<Btn
					onClick={() => handleSaveCampaign(serverSideCampaign)}
					disabled={false}
					message={t('button.saveAsDraft')}
				/>
			}
			{mode === EditorCreateOrUpdateMode.Update &&
				<Btn
					onClick={()=>handleSaveCampaign(serverSideCampaign)}
					disabled={false}
					message={t('button.save')}
				/>
			}
		</>);
	}, [editorState, serverSideCampaign]);

	return (
		<>
			<EditorHeader
				hasBorder={true}
				backTitle={t('actions.close')}
				backOnClick={() => setCloseConfirmIsOpen(true)}
				left={
					<>
						<div className='flex'>
							<span className="campaign_editor_header_name flex_item_auto" data-tip="Edit campaign name">

								<InputCustom
									onChange={e => handleChangeName(e.target.value)}
									value={serverSideCampaign?.Name}
									type="text"
									style="ghost"
									placeholder={'Name'}
									selectOnFocus={true}
									autogrow={true}
									maxWidth={500}
									className="has_clue"
								/>
							</span>
						
						</div>
			
					</>
				}
				center={
					<Breadcrumb
						itemOfBreadcrumb={getBreadCrumbElements()}
						onClick={(indexToGo: EditorStep) => setStep(indexToGo)}
						stepSelected={step}
					/>
				}
				right={
					<>
						{btnCancelAndQuit}
					</>
				}
			/>
			<Confirm
				isOpen={closeConfirmIsOpen}
				onClose={() => setCloseConfirmIsOpen(false)}
				title={t('messages.closeCampaign')}
				text={t('messages.areYouSureToClose')}
				cancelText={t('messages.Stay')}
				confirmText={t('messages.quitWithout')}
				confirmCallback={() => handleCloseEditor()}
			/>
		</>
	);
}
