import React from 'react';
import './BuilderEditor.css';
import './BuilderStyle.css';
import './BuilderStyleRange.css';
import './BuilderTrait.css';
import './BuilderLayers.css';
import './BuilderColor.css';
import {useBuildEditorContext} from './BuildEditorContext';
import BuilderEditorStyleContainer from './BuilderEditorStyleContainer';
import SideBarOptions from "./SideBar/SideBarOptions";
import {BuilderEditor} from "./BuilderEditor";

export default function BuilderEditorContainer() {


	const {
		campaignStep
	} = useBuildEditorContext();

	return (
		<>
			{campaignStep === 1 &&
				<div className="format_editor flex flex_align_stretch">
					<SideBarOptions/>
					<BuilderEditor/>
					<BuilderEditorStyleContainer/>
				</div>
			}
		</>
	);
}
