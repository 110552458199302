import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import EventTrackingServices from '../../../../Services/EventTrackingServices';
import RecommendationRuleCompositionServices from '../../../../Services/RecommendationRuleCompositionServices';
import { ComposableGroupItem, CompositionGroup, Composition, createDefaultComposition } from './EntityTypes';
import { apiAdapter } from './ApiTypeConverters';

const RuleCompositionContext = createContext<RuleCompositionContextType | undefined>(undefined);

function useRuleCompositionContext() {
	const context = useContext(RuleCompositionContext);
	if (!context) throw Error('useRuleCompositionContext can only be used inside an RuleCompositionContextProvider');
	return context;
}

const CreateRuleCompositionContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	//const AuthServices = props.AuthServices;

	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;

	const crud = new RecommendationRuleCompositionServices($http, accountId);

	const { trackEvent } = EventTrackingServices(props.$rootScope.User);

	const [loadingSaving, setLoadingSaving] = useState<boolean>(false);
	const [loadingSettings, setLoadingSettings] = useState(true);
	const [canSave, setCanSave] = useState<boolean>(false);

	const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState<boolean>(false);

	const [initialState, setInitialState] = useState<string>();
	const [composition, setComposition] = useState<Composition>(createDefaultComposition());

	const urlReturn = impersonatingServices.getImpersonatedUrl('/ProductSettings/Reco');

	const { updateComposition, createComposition, getPossibleRules, getComposition } = apiAdapter(crud);

	useEffect(() => {
		const compositionId = $routeParams.id;
		setLoadingSettings(true);
		trackEvent('reco-composition/started');
		if (compositionId) {
			getComposition(compositionId, loadedComposition => {
				setInitialState(JSON.stringify(loadedComposition));
				setComposition(loadedComposition);
				setLoadingSettings(false);
			}, () => {
				setLoadingSettings(false);
				systemServices.showError('Cannot get composition');
			});
		} else {
			setInitialState(JSON.stringify([composition]));
			setLoadingSettings(false);
		}

	}, []);

	useEffect(() => {
		setCanSave(composition.groups.length > 0 && composition.groups.some(x => x.rules.length > 0))
	}, [composition]);

	const handleValidate = () => {
		setLoadingSaving(true);
		//TODO
		if ($routeParams.id) {
			updateComposition(composition,
				() => {
					trackEvent('reco-composition/rule-updated');
					systemServices.showSuccess('The composition has been saved');
					setLoadingSaving(false);
					returnToDashboard();
				},
				(errorComposition) => {
					setComposition(errorComposition);
					setLoadingSaving(false);
				},
				(err) => {
					console.error(err);
					systemServices.showError('Error while saving');
					setLoadingSaving(false);
				}
			);
		} else {
			createComposition(composition,
				() => {
					trackEvent('reco-composition/rule-created');
					systemServices.showSuccess('The composition has been saved');
					setLoadingSaving(false);
					returnToDashboard();
				},
				(errorComposition) => {
					setComposition(errorComposition);
					setLoadingSaving(false);
				},
				(err) => {
					console.error(err);
					systemServices.showError('Error while saving');
					setLoadingSaving(false);
				}
			);
		}
	};

	const loadPossibleRules = (callback: ((rules: ComposableGroupItem[]) => void)) => {
		getPossibleRules(composition, possibleRules => {
			if (possibleRules && possibleRules.length > 0) {
				const rules : ComposableGroupItem[] = possibleRules.map((r) => {
					return {
						actualRuleId: r.id,
						name: r.name,
						internalId: uuidv4(),
						ruleLimit: 0,
						isFillUp: false,
						isPersoRule: false,
						persoRuleType: null
					};
				});
				callback(rules);
			}
		}, (err) => {
			console.error(err);
			systemServices.showError('Cannot get possible rules');
		});
	};

	const handleSetCompositionName = (name: string) => {
		setComposition(c => c.setName(name));
	};

	const setCompositionGroupsWithRuleUpdate = (group: CompositionGroup, rule: ComposableGroupItem, property: string, value: boolean | number | string) => {
		setComposition(x => x.updateGroup(group.id, g => g.updateRule(rule, property, value)));
	};

	const handleAddGroup = () => {
		setComposition(x => x.withGroups([...x.groups, new CompositionGroup(uuidv4(), [], null, null)]));
	};

	const handleDeleteGroup = (group: CompositionGroup) => {
		setComposition(x => x.withGroups(x.groups.filter(g => g.id !== group.id)));
	};

	const handleAddRuleToGroup = (group: CompositionGroup, rule: ComposableGroupItem) => {
		setComposition(x => x.updateGroup(group.id, g => g.addRule(rule)));
	};

	const handleDeleteRuleFromGroup = (group: CompositionGroup, rule: ComposableGroupItem) => {
		setComposition(x => x.updateGroup(group.id, g => g.deleteRule(rule)));
	};

	const handleRulesOrderChanged = (group: CompositionGroup, rules: ComposableGroupItem[]) => {
		setComposition(x => x.updateGroup(group.id, g => g.setRules(rules)));
	};

	const handleGroupsOrderChanged = (groups: CompositionGroup[]) => {
		setComposition(x => x.withGroups(groups));
	};

	const handleChangeGroupSize = (group: CompositionGroup, value: number) => {
		setComposition(x => x.updateGroup(group.id, g => g.setSize(value)));
	};

	const handleChangeRuleLimit = (group: CompositionGroup, rule: ComposableGroupItem, value: number) => {
		setCompositionGroupsWithRuleUpdate(group, rule, 'ruleLimit', value);
	};

	const handleSetRuleAsFillUp = (group: CompositionGroup, rule: ComposableGroupItem, value: boolean) => {
		setComposition(x => x.updateGroup(group.id, g => g.setIsFillUp(rule, value)));
	};

	const returnToDashboard = () => {
		window.location.href = urlReturn;
	};

	const checkIfCanQuitWithoutSave = () => {
		const newState = JSON.stringify(composition);
		if (newState === initialState) {
			returnToDashboard();
		} else {
			setModalConfirmIsOpen(true);
		}
	};

	const context: RuleCompositionContextType = {
		loadPossibleRules,
		handleAddGroup,
		handleDeleteGroup,
		handleAddRuleToGroup,
		handleDeleteRuleFromGroup,
		handleRulesOrderChanged,
		handleGroupsOrderChanged,
		handleChangeGroupSize,
		handleChangeRuleLimit,
		handleSetRuleAsFillUp,
		handleSetCompositionName,
		compositionName: composition.name,
		compositionGroups: composition.groups,

		modalConfirmIsOpen,
		handleCloseConfirm: () => setModalConfirmIsOpen(false),
		checkIfCanQuitWithoutSave,
		returnToDashboard,

		handleValidate,
		loadingSaving,
		loadingSettings,
		canSave,

		cancelActions: () => { console.log('ok'); },
	};

	return (
		<RuleCompositionContext.Provider
			value={context}
		>
			{props.children}
		</RuleCompositionContext.Provider>
	);
};

export default CreateRuleCompositionContextProvider;

export { useRuleCompositionContext };

type RuleCompositionContextType = {
	compositionGroups: CompositionGroup[],
	loadPossibleRules: (callback: ((rules: ComposableGroupItem[]) => void)) => void,
	handleSetCompositionName: (name: string) => void,
	handleAddGroup: () => void,
	handleDeleteGroup: (group: CompositionGroup) => void,
	handleAddRuleToGroup: (group: CompositionGroup, rule: ComposableGroupItem) => void,
	handleDeleteRuleFromGroup: (group: CompositionGroup, rule: ComposableGroupItem) => void,
	handleRulesOrderChanged: (group: CompositionGroup, rules: ComposableGroupItem[]) => void,
	handleGroupsOrderChanged: (groups: CompositionGroup[]) => void,
	handleChangeGroupSize: (group: CompositionGroup, value: number) => void,
	handleChangeRuleLimit: (group: CompositionGroup, rule: ComposableGroupItem, value: number) => void,
	handleSetRuleAsFillUp: (group: CompositionGroup, rule: ComposableGroupItem, value: boolean) => void,
	compositionName: string,

	modalConfirmIsOpen: boolean,
	handleCloseConfirm: () => void,
	returnToDashboard: () => void,
	checkIfCanQuitWithoutSave: () => void,

	handleValidate: () => void,
	loadingSaving: boolean,
	loadingSettings: boolean,

	canSave: boolean,
	cancelActions: any
}