import React, { useState, useEffect, Fragment } from 'react';
import Btn from './../../../Components/Btn';
import Modal from './../../../Components/Modal';
import Switch from '../../../Components/Switch';
import InputCopyToClipBoard from './../../../Components/InputCopyToClipBoard';
import { Trans, useTranslation } from 'react-i18next';
export default function EditConfigSendCodePromo({ isCreationMode, setIsCreationMode, accountId, campaignId, setAccountCurrentTab, getParameterConfigurationByOperation, getCodePromoById, postConfigPostCodePromoById, putCodePromoById, postCodePromoById }) {
	const providersWithSenderInfo = ['Postmark', 'Tipimail'];

	const [formData, setFormData] = useState({
		provider: '',
		SenderName: '',
		SenderEmail: ''
	});
	const [t, i18n] = useTranslation('common');
	const [valueOfProvider, setValueOfProvider] = useState('');
	const [statusProvider, setStatusProvider] = useState();
	const [sendMailtest, setSendMailTest] = useState('');
	const [isReloading, setIsRealoading] = useState(false);
	const [isEditMode, setEditMode] = useState(false);
	const [isTestMode, setTestMode] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isNotValid, setIsNotValid] = useState([]);
	const [isExistant, setIsExistant] = useState(false);

	const [clipBoardContain, setClipBoardContain] = useState(false);
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
	};
	const handleChangeForm = (e) => {
		e.persist();
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let prevState = { ...formData };
		prevState[name] = value;
		setFormData(prevState);
	};
	const handleChangeValueSelect = (e) => {
		const value = e.target.value;
		if (providersWithSenderInfo.includes(value)) {
			setValueOfProvider(value);
			setFormData({
				provider: value,
				SenderName: '',
				SenderEmail: ''
			});
		} else {
			setValueOfProvider(value);
			setFormData({ provider: value });
		}
	};
	useEffect(() => {
		if (!isReloading && !isSubmit) {
			if (isCreationMode) {
				setValueOfProvider('Mandrill');
				setFormData({
					provider: 'Mandrill',
				});

			} else {
				// faire l'appel à l'api 
				setIsRealoading(true);
				getCodePromoById(accountId, campaignId, data => {
					setFormData(data.config);
					setValueOfProvider(data.config.EmailProvider);
					setStatusProvider(data.config.IsActive);
					setIsRealoading(false);
					setIsExistant(true);
				}, error => {
					setIsRealoading(false);
				});

			}
		}
	}, [isSubmit]);
	const submitTheForm = (e) => {
		e.preventDefault();
		setIsRealoading(true);
		setIsSubmit(true);
		if (isCreationMode) {
			let provider = formData;
			delete provider.IsActive;
			JSON.stringify(provider);
			postConfigPostCodePromoById(accountId, campaignId, provider, data => {
				setIsCreationMode(false);
				getParameterConfigurationByOperation(2);
				setIsRealoading(false);
				setIsSubmit(false);
			}, error => {
				setIsRealoading(false);
				setIsSubmit(false);
			});
		} else {
			let provider = {
				updateIsActive: formData.IsActive
			};
			JSON.stringify(provider);
			putCodePromoById(accountId, campaignId, provider, data => {
				setIsRealoading(false);
				setEditMode(false);
				getParameterConfigurationByOperation(2);
				setIsSubmit(false);
			}, error => {
				setIsRealoading(false);
				setIsSubmit(false);
			});
		}
	};
	function validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	const submitFormWithSenderInfo = (e) => {
		e.preventDefault();
		setIsSubmit(true);
		if (validateEmail(formData.SenderEmail) && formData.SenderName.length > 0) {
			setIsNotValid([]);
			setIsRealoading(true);
			if (isCreationMode) {
				let provider = formData;
				provider.sender = { email: formData.SenderEmail, name: formData.SenderName };
				delete provider.IsActive;
				delete provider.SenderEmail;
				delete provider.SendetName;
				JSON.stringify(provider);
				postConfigPostCodePromoById(accountId, campaignId, provider, data => {
					getParameterConfigurationByOperation(2);
					setIsCreationMode(false);
					setIsRealoading(false);
					setIsSubmit(false);
				}, error => {
					setIsRealoading(false);
				});
			} else {
				let provider = {
					updateIsActive: formData.IsActive,
					updateSender: { email: formData.SenderEmail, name: formData.SenderName }
				};
				JSON.stringify(provider);
				putCodePromoById(accountId, campaignId, provider, data => {
					getParameterConfigurationByOperation(2);
					setEditMode(false);
					setIsRealoading(false);
					setIsSubmit(false);
				}, error => {
					setIsSubmit(false);
				});
			}
		} else {
			let newArrayOfErrors = [];
			if (!validateEmail(formData.SenderEmail)) {
				newArrayOfErrors.push('SenderEmail');
			}
			if (!formData.SenderName.length > 0) {
				newArrayOfErrors.push('SenderName');
			}
			setIsNotValid(newArrayOfErrors);
		}
	};
	const sendTestMail = () => {
		if (validateEmail(sendMailtest)) {
			setIsRealoading(true);
			let recipient = { RecipientEmail: sendMailtest };
			JSON.stringify(recipient);
			postCodePromoById(accountId, campaignId, recipient, data => {
				setIsRealoading(false);
				getParameterConfigurationByOperation(2);
				setEditMode(false);
				setTestMode(false);
			}, error => {
				setIsRealoading(false);
			});
		} else {
			let newArrayOfErrors = [];
			if (!validateEmail(sendMailtest)) {
				newArrayOfErrors.push('sendMailtest');
			}
			setIsNotValid(newArrayOfErrors);
		}
	};
	const handleChangeSendMAil = (e) => {
		e.persist();
		setSendMailTest(e.target.value);
	};
	const reFreshData = () => {
		setIsRealoading(true);
		getCodePromoById(accountId, campaignId, data => {
			setFormData(data.config);
			setValueOfProvider(data.config.EmailProvider);
			setStatusProvider(data.config.IsActive);
			setIsRealoading(false);
			setIsExistant(true);
		}, error => {
			setIsRealoading(false);
		});
	};

	return (
		<div className="config_form_container">
			{!isReloading &&
				<Fragment>


					{isCreationMode &&
						<Fragment>
							<div className="section l section_grey has_top_border">
								<div className="email_provider flex">
									<a className="icon_btn icon_btn_color"
										title="Back"
										onClick={
											!isExistant ? (event) => { event.preventDefault(); setAccountCurrentTab(0); } :
												() => { reFreshData(); setIsCreationMode(false); }
										}><i className="fas fa-arrow-left"></i></a>
									<div className="email_provider_name">{t('AccountParametersContainer.PromoCode')}</div>
								</div>
							</div>
							<div className="section l has_top_border">
								<div className="form_block">
									<label htmlFor="Provider" className="form_block_label">{t('AccountParametersContainer.Provider')}</label>
									<select name="provider" id="" className="select-provider" onChange={(e) => handleChangeValueSelect(e)} value={valueOfProvider} >
										<option value="Mandrill"> Mandrill</option>
										<option value="SparkPost"> SparkPost</option>
										<option value="Postmark"> PostMark</option>
										<option value="Tipimail"> Tipimail</option>
									</select>
								</div>
								{
									providersWithSenderInfo.includes(valueOfProvider) &&
									<Fragment>
										<div className="form_block">
											<label htmlFor="SenderName" className="form_block_label">{t('AccountParametersContainer.SenderName')}</label>
											<input type="text"
												name="SenderName"
												onChange={(e) => handleChangeForm(e)}
												value={formData.SenderName ? formData.SenderName : ''} />
											{isNotValid.includes('SenderName') && <p className="information_error_message">{t('AccountParametersContainer.senderNameEmpty')}</p>}
										</div>
										<div className="form_block">
											<label htmlFor="SenderEmail" className="form_block_label">{t('AccountParametersContainer.SenderEmail')}</label>
											<input
												type="text"
												name="SenderEmail"
												onChange={(e) => handleChangeForm(e)}
												value={formData.SenderEmail ? formData.SenderEmail : ''} />
											{isNotValid.includes('SenderEmail') && <p className="information_error_message">{t('AccountParametersContainer.EmailNonValid')}</p>}
										</div>
									</Fragment>
								}
								<Btn
									onClickFunction={
										!isExistant ? (event) => { event.preventDefault(); setAccountCurrentTab(0); } :
											() => { reFreshData(); setIsCreationMode(false); }
									}
									message={t('AccountParametersContainer.cancel')}
									color="secondary"
									style="outline"
								/>
								{
									!providersWithSenderInfo.includes(valueOfProvider) ?
										<Btn
											onClickFunction={(e) => { e.preventDefault(); submitTheForm(e); }}
											message={t('AccountParametersContainer.Submit')}
										/>
										:
										<Btn
											onClickFunction={(e) => { e.preventDefault(); submitFormWithSenderInfo(e); }}
											message={t('AccountParametersContainer.Submit')}
										/>
								}
							</div>
						</Fragment>
					}


					{isEditMode &&
						<Fragment>
							<div className="section l section_grey has_top_border">
								<div className="email_provider flex">
									<a className="icon_btn icon_btn_color"
										title="Back"
										onClick={(event) => { event.preventDefault(); setEditMode(false); }}><i className="fas fa-arrow-left"></i></a>
									<div className="email_provider_name">{t('AccountParametersContainer.PromoCode')}</div>
								</div>
							</div>
							<div className="section l has_top_border">
								<div className="form_block">
									<Switch
										value={formData.IsActive}
										name="IsActive"
										checked={formData.IsActive}
										onChange={(e) => handleChangeForm(e)}
									>
										{formData.IsActive ?
											t('AccountParametersContainer.Active') :
											t('AccountParametersContainer.Disabled')
										}
									</Switch>
								</div>
								{
									providersWithSenderInfo.includes(valueOfProvider) &&
									<Fragment>
										<div className="form_block">
											<label htmlFor="SenderName" className="form_block_label">{t('AccountParametersContainer.SenderName')}</label>
											<input type="text"
												name="SenderName"
												onChange={(e) => handleChangeForm(e)}
												value={formData.SenderName ? formData.SenderName : ''} />
											{isNotValid.includes('SenderName') && <p className="information_error_message">{t('AccountParametersContainer.senderNameEmpty')}</p>}
										</div>
										<div className="form_block">
											<label htmlFor="SenderEmail" className="form_block_label">{t('AccountParametersContainer.SenderEmail')}</label>
											<input
												type="text"
												name="SenderEmail"
												onChange={(e) => handleChangeForm(e)}
												value={formData.SenderEmail ? formData.SenderEmail : ''} />
											{isNotValid.includes('SenderEmail') && <p className="information_error_message">{t('AccountParametersContainer.EmailNonValid')}</p>}
										</div>
									</Fragment>
								}
								<Btn
									onClickFunction={(event) => { event.preventDefault(); setEditMode(false); }}
									message={t('AccountParametersContainer.cancel')}
									color="secondary"
									style="outline"
								/>
								{
									!providersWithSenderInfo.includes(valueOfProvider) ?
										<Btn
											onClickFunction={(e) => { e.preventDefault(); submitTheForm(e); }}
											message={t('AccountParametersContainer.Submit')}
											style="primary"
										/>
										:
										<Btn
											onClickFunction={(e) => { e.preventDefault(); submitFormWithSenderInfo(e); }}
											message={t('AccountParametersContainer.Submit')}
											style="primary"
										/>
								}
							</div>
						</Fragment>
					}


					{!isCreationMode && !isEditMode &&
						<Fragment>
							<div className="section section_grey l has_top_border">
								<div className="flex">
									<div className="flex_item_full">
										<div className="email_provider">
											<span className="email_provider_name">{formData.EmailProvider}</span>
											{statusProvider ?
												<span className="tooling_status ongoing">{t('AccountParametersContainer.Active')}</span>
												:
												<span className="tooling_status stopped">{t('AccountParametersContainer.Disabled')}</span>
											}
											<div className="email_provider_change">
												<a onClick={(event) => { setIsCreationMode(true); setEditMode(false); }}>
													{t('AccountParametersContainer.change')}
												</a>
											</div>
										</div>
									</div>
									<div className="flex_item_fix">
										<Btn
											onClickFunction={() => setEditMode(true)}
											message={t('AccountParametersContainer.settings')}
											style="outline"
											color="secondary"
										/>
										<Btn
											onClickFunction={() => setTestMode(true)}
											icon="fas fa-paper-plane"
											message={t('AccountParametersContainer.sendTest')}
										/>
									</div>
								</div>
							</div>
							<div className="section l has_top_border">
								<div className="form_block">
									<label className="form_block_label">{t('AccountParametersContainer.templateName')}</label>
									<div className="config_input_copy">
										<InputCopyToClipBoard
											clipBoardContain={clipBoardContain}
											onClickFunction={(e) => copyToClipBoard(e)}
											value={formData.TemplateName}
											fullSize={true}
										/>
									</div>
								</div>
								<div className="form_block">
									<label className="form_block_label">{t('AccountParametersContainer.subAcc')}</label>
									<div className="config_input_copy">
										<InputCopyToClipBoard
											clipBoardContain={clipBoardContain}
											onClickFunction={(e) => copyToClipBoard(e)}
											value={formData.SubAccount}
											fullSize={true}
										/>
									</div>
								</div>
								{
									providersWithSenderInfo.includes(valueOfProvider) &&
									<Fragment>
										<div className="form_block">
											<label htmlFor="SenderName" className="form_block_label">{t('AccountParametersContainer.SenderName')}</label>
											<input type="text"
												name="SenderName"
												readOnly
												value={formData.SenderName ? formData.SenderName : ''} />
										</div>
										<div className="form_block">
											<label htmlFor="SenderEmail" className="form_block_label"> {t('AccountParametersContainer.SenderEmail')}</label>
											<input
												type="text"
												name="SenderEmail"
												readOnly
												value={formData.SenderEmail ? formData.SenderEmail : ''} />
										</div>
									</Fragment>
								}
							</div>
							<Modal
								isOpen={isTestMode}
								onClose={(e) => { setTestMode(false); }}>
								<div className="modal_header has_border">
									{t('AccountParametersContainer.sendTest')}
									<div className="modal_header_desc">{t('AccountParametersContainer.testProvider')}</div>
								</div>
								<div className="modal_body">
									<div className="form_block">
										<label htmlFor="sendMailtest" className="form_block_label">{t('AccountParametersContainer.RecipientEmail')}</label>
										<input
											type="text"
											className="full"
											name="sendMailtest"
											onChange={(e) => handleChangeSendMAil(e)}
											value={sendMailtest ? sendMailtest : ''} />
										{isNotValid.includes('sendMailtest') && <p className="information_error_message">{t('AccountParametersContainer.emailNonValid')}</p>}
									</div>
								</div>
								<div className="modal_footer">
									<Btn
										onClickFunction={(event) => { event.preventDefault(); sendTestMail(); }}
										message={t('AccountParametersContainer.send')}
										icon="fas fa-paper-plane"
									/>
									<Btn
										onClickFunction={() => setTestMode(false)}
										message={t('AccountParametersContainer.cancel')}
										style="outline"
										color="secondary"
									/>
								</div>
							</Modal>
						</Fragment>
					}
				</Fragment>
			}
			{
				isReloading &&
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			}
		</div>
	);
}
