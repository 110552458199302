
import React, { useEffect, useState } from 'react';
import InputCustom from '../../../../../Components/InputCustom';
import SectionMessage from '../../../../../Components/SectionMessage';
import { useFiltersContext } from '../contextFilters';
import Btn from '../../../../../Components/Btn';

export default function UrlContainer({
	data,
	handleChangeFilterByUniqueId,
}) {

	const [currentValue, setCurrentValue] = useState();
	const [isAnError, setIsAnError] = useState({ isError: false, message: '' });

	const {
		tabFilterSelected,
		validUrl,
		urlIsInvalid,
		setUrlIsInvalid,
		errorMessageUrl
	} = useFiltersContext();
	const supportedOperators = data.hasOwnProperty('supportedOperators') ? data.supportedOperators : data.specificData.supportedOperators;

	const updateValue = (value) => {
		setUrlIsInvalid(false);
		if (!value) {
			setIsAnError({ isError: true, message: 'Input cannot be empty' });
		} else if (!value.match(/https:\/\/docs.google.com\/spreadsheets\/[^\s]*/i)) {
			setIsAnError({ isError: true, message: 'Input must be a Google Spreadsheet URL' });
		}
		else {
			setIsAnError({ isError: false, message: '' });
		}

		setCurrentValue(value);
	};

	function getValueOfStringComponent() {
		if (data.value['stringValues'] !== null && tabFilterSelected.value.stringValues && tabFilterSelected.value.stringValues.length > 0) {

			setCurrentValue(tabFilterSelected.value.stringValues[0]);
		} else {
			setCurrentValue('');
		}
	}

	function handleChangeValuesById() {
		if (currentValue) {
			handleChangeFilterByUniqueId({
				idFilter: tabFilterSelected.idFilter,
				op: supportedOperators[0],
				value: { stringValues: [currentValue] },
			});
		} else if (!currentValue) {
			handleChangeFilterByUniqueId({
				idFilter: tabFilterSelected.idFilter,
				op: supportedOperators[0],
				value: { stringValues: [] },
			});
		}
	}

	useEffect(() => {
		getValueOfStringComponent();
	}, [tabFilterSelected]);


	useEffect(() => {
		handleChangeValuesById();
	}, [data, currentValue]);

	return (
		<>
			<InputCustom
				onChange={e => updateValue(e.target.value)}
				value={currentValue ?? ''}
				isAnError={isAnError.isError ? isAnError.message : null}
				placeholder='https://docs.google.com/spreadsheets/d/aBcdEFgHiJKl987654321023SfdGFoFpTeM73ywaH4eU'
				fullWidth={true}
				label="Google Spreadsheet URL"
				append={
					<Btn
						onClick={() => validUrl(currentValue)}
						size="s"
						message="Test url"
						color="primary"
						disabled={isAnError.isError || !currentValue}
						inlineStyle={{whiteSpace: 'nowrap'}}
					/>
				}
			/>

			{urlIsInvalid &&
				<SectionMessage
					fontSize='s'
					type={'success'}
					text={'Everything is fine, the spreadsheet is accessible, you can click on the OK button'}
					verticalSize='s'
				/>
			}

			{!urlIsInvalid && errorMessageUrl &&
				<SectionMessage
					fontSize='s'
					type={'alert'}
					text={errorMessageUrl}
					verticalSize='s'
				/>
			}

			<SectionMessage fontSize='s' verticalSize='s'>
				You must give read permissions on your spreadsheet to this account:<br/>
				<em>beyable-external@beyable-prod-global.iam.gserviceaccount.com</em><br/>
				And test the spreadsheet URL.
			</SectionMessage>
		</>
	);
}
