import React from 'react';

export function PanelSelect(props) {
    return <div className={props.styles.panelTitle}>
        <label>
            <span>{props.label}</span>
            <select className={props.styles.panelSelect} onChange={(e) => props.onSelectChange(e)} value={props.type}>
                {props.options.map(option => 
                    <option key={option.id} value={option.id}>{option.name}</option>
                )}
            </select>
        </label>
    </div>
}
  
export function PanelInitTypeSelection(props) {
    return <div className={props.styles.panelContent} {...props.dragHandleProps}>
        {props.controlTypes.map(controlType =>
            <button
                key={controlType.id}
                className={`radio_box ${props.styles.radioBox}`}
                type="button"
                onClick={(e) => props.onSelect(e)}
                value={controlType.id}>
                <i className={`fa fa-${controlType.icon}`}></i>
                <div>{controlType.name}</div>
            </button>
        )}
    </div>
}
  
export function PanelActions(props) {
    return <div className={props.styles.panelActions}>
        {props.actions.remove &&
          <button type="button" onClick={props.actions.remove}>
            <i className="fa fa-trash"></i>
          </button>
        }
        {props.actions.duplicate &&
            <button type="button" onClick={props.actions.duplicate}>
              <i className="fa fa-copy"></i>
            </button>
        }
    </div>
}

export function PanelHeader(props) {
    return <header className={props.styles.panelHeader} {...props.dragHandleProps}>
        {props.children}
    </header>
}
  
export function PanelBody(props) {
    return <div className={props.styles}>
        {props.children}
    </div>
}

export function PanelFooter(props) {
    return <footer className={props.styles}>
        {props.children}
    </footer>
}