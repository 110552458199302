import React, { useEffect, useState } from 'react';
import { onSiteContext } from '../context';
import classes from './CampaignItemExtend.module.css';
import CampaignVignette from './CampaignVignette';

export default function CampaignItemExtend({ screenShots, campaign }) {
	const {
		needFetchList,
		isAdmin
	} = onSiteContext();
	const [images, setImages] = useState('');

	useEffect(() => {
		if (screenShots && screenShots.length !== 0) {
			setImages(screenShots);
		}
	}, [needFetchList, screenShots]);

	return (
		<div className={`${classes.container} flex`}>
			{
				isAdmin && images && images.map(image => (
					<CampaignVignette
						image={image}
						campaign={campaign}
						setImages={setImages}
						key={image.id}
					/>
				))
			}
		</div>
	);
}
