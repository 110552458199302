/* eslint-disable no-mixed-spaces-and-tabs */
export const payload = {
	'taskId': 'bd79d663-8eca-4ec7-a0d1-0a62423996ba',
	'status': 'Succeded',
	'requestTimeUtc': '2024-05-31T11:19:47.7643416Z',
	'evaluationDate': '2024-05-31',
	'result': [
		{
			'scenarioType': 'PromotionsAndSpecialOffersHighlighting',
			'probability': 0.3740000128746033,
			'upliftPrediction': {
				'turnover': {
					'standardDeviation': {
						'min': -6034.307770881,
						'max': 7378.262634716999
					},
					'confianceInterval': {
						'min': -1053.924493576524,
						'max': 2397.8793574125243
					}
				},
				'conversionRate': {
					'standardDeviation': {
						'min': -0.00022688664059946194,
						'max': 0.00027279019713847473
					},
					'confianceInterval': {
						'min': -0.00004134560771230235,
						'max': 0.00008724916425131515
					}
				},
				'basketAmount': {
					'standardDeviation': {
						'min': -17.646637815529413,
						'max': 26.23815710788236
					},
					'confianceInterval': {
						'min': -1.3512453474850536,
						'max': 9.942764639837995
					}
				}
			}
		},
		{
			'scenarioType': 'Gamification',
			'probability': 0.2256999969482422,
			'upliftPrediction': {
				'pageViews': {
					'standardDeviation': {
						'min': 14.506154661,
						'max': 21.256066463
					},
					'confianceInterval': {
						'min': 4.0988939620884,
						'max': 6.848805764089
					}
				}
			}
		},
		{
			'scenarioType': 'Information',
			'probability': 0.1193000003695488,
			'upliftPrediction': null
		},
		{
			'scenarioType': 'ProductsViewedReminder',
			'probability': 0.11159999668598175,
			'upliftPrediction': {
				'turnover': {
					'standardDeviation': {
						'min': -13458.58887906,
						'max': -5978.356437202
					},
					'confianceInterval': {
						'min': -1673.269811787361,
						'max': -846.497746354638
					}
				},
				'conversionRate': {
					'standardDeviation': {
						'min': -0.00037338150846136437,
						'max': 0.0006898277902386282
					},
					'confianceInterval': {
						'min': 0.00003456927823633439,
						'max': 0.0002818770035409293
					}
				},
				'basketAmount': {
					'standardDeviation': {
						'min': -29.75947160468067,
						'max': 43.14519367676471
					},
					'confianceInterval': {
						'min': -1.7861312737941724,
						'max': 15.17185334587821
					}
				}
			}
		},
		{
			'scenarioType': 'Reinsurance',
			'probability': 0.10909999907016754,
			'upliftPrediction': {
				'turnover': {
					'standardDeviation': {
						'min': -10538.954162334001,
						'max': -203.833266280002
					},
					'confianceInterval': {
						'min': -692.2274619457243,
						'max': -257.106565891726
					}
				},
				'conversionRate': {
					'standardDeviation': {
						'min': -0.0006404722193165531,
						'max': -0.0002733161148130455
					},
					'confianceInterval': {
						'min': -0.000018752530693196323,
						'max': -0.0000015964261896886
					}
				},
				'basketAmount': {
					'standardDeviation': {
						'min': -21.920188709495797,
						'max': -6.73510629989916
					},
					'confianceInterval': {
						'min': -4.269260493508149,
						'max': -2.084178083911512
					}
				}
			}
		}
	],
	'error': null,
	'executionDuration': '00:02:09.3876837'
};
export const payload2 ={
	'taskId': '13c25862-3e71-43fc-95ed-45d857d23d54',
	'status': 'Succeded',
	'requestTimeUtc': '2024-06-20T19:41:39.8374676Z',
	'evaluationDate': '2024-06-20',
	'result': [
		{
			'scenarioType': 'PromotionsAndSpecialOffersHighlighting',
			'probability': 0.21230000257492065,
			'upliftPrediction': {
				'turnover': {
					'standardDeviation': {
						'min': -44677.08035742211,
						'max': 54627.514065509706
					},
					'confianceInterval': {
						'min': -7803.093756236971,
						'max': 17753.527464324576
					}
				},
				'conversionRate': {
					'standardDeviation': {
						'min': -0.0007197496635586522,
						'max': 0.0008653689441289264
					},
					'confianceInterval': {
						'min': -0.0001311601562874399,
						'max': 0.0002767794368577142
					}
				},
				'basketAmount': {
					'standardDeviation': {
						'min': -3.9958118464859433,
						'max': 5.941230284069856
					},
					'confianceInterval': {
						'min': -0.30596888899925595,
						'max': 2.2513873265831683
					}
				}
			}
		},
		{
			'scenarioType': 'UxImprovement',
			'probability': 0.14489999413490295,
			'upliftPrediction': null
		},
		{
			'scenarioType': 'ToDestockProductsHighlighting',
			'probability': 0.08349999785423279,
			'upliftPrediction': {
				'turnover': {
					'standardDeviation': {
						'min': -47003.984447583105,
						'max': 164382.66470590516
					},
					'confianceInterval': {
						'min': -19607.931693172915,
						'max': 136986.61195149494
					}
				},
				'conversionRate': {
					'standardDeviation': {
						'min': -0.0004001192222401125,
						'max': 0.0016449929900611848
					},
					'confianceInterval': {
						'min': -0.00013506936085452972,
						'max': 0.0013799431286756017
					}
				},
				'basketAmount': {
					'standardDeviation': {
						'min': -7.159514961086485,
						'max': 5.098639534544001
					},
					'confianceInterval': {
						'min': -5.570838246739401,
						'max': 3.5099628201969173
					}
				}
			}
		},
		{
			'scenarioType': 'LeavingCustomerRetention',
			'probability': 0.07819999754428864,
			'upliftPrediction': {
				'turnover': {
					'standardDeviation': {
						'min': -108566.32900872012,
						'max': 156911.58626112834
					},
					'confianceInterval': {
						'min': -3251.020218933123,
						'max': 51596.27747134134
					}
				},
				'conversionRate': {
					'standardDeviation': {
						'min': -0.0009987573958546796,
						'max': 0.001339638604467344
					},
					'confianceInterval': {
						'min': -0.0000711137664537422,
						'max': 0.0004119949750664065
					}
				},
				'basketAmount': {
					'standardDeviation': {
						'min': -5.655122578383554,
						'max': 5.966225659298794
					},
					'confianceInterval': {
						'min': -1.044924110608528,
						'max': 1.3560271915237687
					}
				}
			}
		},
		{
			'scenarioType': 'LeadCollection',
			'probability': 0.05950000137090683,
			'upliftPrediction': null
		}
	],
	'error': null,
	'executionDuration': '00:02:37.4458122'
};