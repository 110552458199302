import React, { useEffect, useState } from 'react';
import SelectDropdown from '../../../Components/SelectDropdown';
import { iconList, findIconByName, createIconString } from '../Utils/IconUtils';
import Icon from './Icon';
import styles from './IconSelector.module.css';

export default function IconSelector({
	property,
	data,
	onChange,
	label,
	fullWidth
}) {

	const [selectedIcon, setSelectedIcon] = useState(iconList[0]);

	const optionsList = iconList.map(i => {
		return {
			label: <span className={styles.iconWrapper}><Icon name={i.name}>{i.jsx}</Icon></span>,
			value: i.name,
			tooltip: i.name
		};
	});

	const handleChange = (name) => {
		const icon = findIconByName(name);
		if (icon) {
			const iconString = createIconString(icon, property.Editor.ClassName);
			onChange(iconString);
		}
	};

	const handleDataChange = () => {
		let selectedIcon = iconList[0];

		if (data && data.includes('<svg')) {
			const regex = / name="([^"]*)"/;
			const found = data.match(regex);
			if (found && found[1]) {
				const icon = findIconByName(found[1]);
				if (icon) {
					selectedIcon = icon;
				}
			}
		}

		setSelectedIcon(selectedIcon);
	};

	useEffect(() => {
		handleDataChange();
	}, [data]);

	return (
		<>
			<SelectDropdown
				label={label}
				optionsList={optionsList}
				value={selectedIcon && selectedIcon.name}
				onChange={handleChange}
				fullWidth={fullWidth}
			/>
		</>
	);
}
