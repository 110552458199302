import React, { useState, useEffect, useContext } from 'react';
import { BusinessOperationContext } from '../Context/ContextBusinessOperations';
import MarkerItem from './MarkerItem';
import './OperationsTimeline.css';
export default function OperationsTimeline({ operations, loadOperationStatistics, routeParams, today, dates, putOperationExecutionStatusModeWithTest, accountId, reloadOperationsForStatusChange,
	deleteOperationById, permissions, userSimplifyRole, reloadTheOperationItem, setReloadTheOperationItem }) {
	const {
		selectedLabelIds
	} = useContext(BusinessOperationContext);

	const [operationsToMapInTimeline, setOperationsToMapInTimeline] = useState();
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();
	const [dataToMap, setDataToMap] = useState();
	const operationInTheRangeDates = () => {
		if (operations) {
			const beginingOfTimeline = moment(dates[0]).startOf('day');
			const endOfTimeline = moment(dates[dates.length - 1]).startOf('day');
			const operationsToShowOnTimeline = operations.filter(op => moment(op.operation.startDate).startOf('day') <= endOfTimeline && moment(op.operation.endDate).startOf('day') > beginingOfTimeline);

			setOperationsToMapInTimeline(operationsToShowOnTimeline);
		}
	};

	useEffect(() => {
		if (!dataFiltered) {
			setDataToMap(operationsToMapInTimeline);
		} else {
			setDataToMap(dataFiltered);
		}
	}, [operationsToMapInTimeline, dataFiltered]);

	useEffect(() => {
		operationInTheRangeDates();
	}, [operations, dates]);

	useEffect(() => {
		search(operationsToMapInTimeline, selectedLabelIds);
	}, [selectedLabelIds, operationsToMapInTimeline]);

	const search = (currentOperationsTimeline, idLabelFilters) => {
		if (currentOperationsTimeline && idLabelFilters.length >= 0) {
			setDataFiltered([]);
			let results = currentOperationsTimeline.filter(
				line => {


					let labelMatched = true;
					if (idLabelFilters.length > 0) {
						labelMatched = line.operation.operationLabels.some(l => idLabelFilters.includes(l.id));
					}
					return labelMatched;
				}
			);
			let finalArray = [...results];
			let uniqueItem = finalArray;
			if (uniqueItem.length === 0) {
				uniqueItem = 'No operation found for this search';
				setNoData(uniqueItem);
			}
			else {
				setDataFiltered(uniqueItem);
				setNoData();
			}

		} else {
			setDataFiltered();
		}
	};


	return (
		<div className="splanning_operations_list">
			{
				!noData &&
				dataToMap &&
				dataToMap.map((op) => (
					<MarkerItem
						permissions={permissions}
						userSimplifyRole={userSimplifyRole}
						reloadTheOperationItem={reloadTheOperationItem}
						setReloadTheOperationItem={(e) => setReloadTheOperationItem(e)}
						accountId={accountId}
						key={op.operation.operationId}
						loadOperationStatistics={loadOperationStatistics}
						op={op}
						routeParams={routeParams}
						dates={dates}
						putOperationExecutionStatusModeWithTest={
							putOperationExecutionStatusModeWithTest
						}
						reloadOperationsForStatusChange={() =>
							reloadOperationsForStatusChange()
						}
						deleteOperationById={deleteOperationById}
					/>
				))
			}
		</div>
	);
}
