import React, { useState, Fragment, useContext } from 'react';
import { TriggerContext } from './TriggerContext';
import useEventBroker from '../../../Hooks/useEventBroker';
import Modal from '../../../Components/Modal';
import Btn from '../../../Components/Btn';
import Confirm from '../../../Components/Confirm';
import InputCustom from '../../../Components/InputCustom';
import PersonaServices from '../../../Services/PersonaServices';

export default function PersonaBoard({
	setupTriggersForLoading,
	campaignPersonas,
	isNativeApp
}) {
	const { publish, subscribe } = useEventBroker();
	const {$http, currentAccount, systemServices} = useContext(TriggerContext);

	const personaService = new PersonaServices($http);

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [newPersona, setNewPersona] = useState({ name : '', data: []});
	const [savedPersonas, setSavedPersonas] = useState([]);
	const [loaderSavedPersona, setLoaderSavedPersona] = useState(true);

	const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);
	const [personaIDToDelete, setPersonaIDToDelete] = useState('');


	const getPersonasList = (accountId, callbackSuccess) => personaService.getPersonasList(accountId, isNativeApp ? 'NativeApp' : 'OnSite', callbackSuccess, error => console.log(error));
	const deletePersona = (accountId, groupId, callbackSuccess) => personaService.deletePersona(accountId, groupId, callbackSuccess, 
		error => systemServices.showError('An error occured while deleting the persona'));


	const loadExistingPersonas = () => {
		getPersonasList(currentAccount, e => {
			let existingPersona = e.map(x => ({
				Description: x.description,
				GroupName: x.groupName, 
				GroupId: x.groupId,
				Triggers: setupTriggersForLoading(x.triggers.map(t => ({ Name:t.name, OperatorName:t.operatorName, ValueToCompare:t.value})))
			}));
			setSavedPersonas(existingPersona);
			setLoaderSavedPersona(false);
		});
	};

	subscribe('evt/triggers/openPersonaBoardRequested', _ => {
		setModalIsOpen(true);
		loadExistingPersonas();
	});

	const addNewPersona = (newPersonaToAdd) => {
		publish('evt/triggers/addCustomPersona', newPersonaToAdd);
	};

	const addSavedPersona = (newPersonaToAdd) => {
		const groupIds = campaignPersonas.map(x=> x.Id);
		if (!groupIds.includes(newPersonaToAdd.id)) {
			publish('evt/triggers/addSavedPersona', newPersonaToAdd);
			closePersonaBoard();
		}
	};

	const deleteThisPersona = (personaId) => {
		deletePersona(currentAccount, personaId, e => {loadExistingPersonas(), setLoaderSavedPersona(true);});
	};

	const closePersonaBoard = () => {
		setModalIsOpen(false);
		setNewPersona({name : '', data: []});
	};

	const handleChangeInputNewPersona = (e) => {
		e.persist();
		setNewPersona(prevValues => ({
			...prevValues,
			[e.target.name]: e.target.value
		})
		);
	};

	const wantToDeletePersona = (id) => {
		setPersonaIDToDelete(id);
		setDeleteConfirmIsOpen(true);
	};

	return(
		<Modal
			isOpen={modalIsOpen}
			width="420"
			onClose={() => closePersonaBoard()}
		>
			<div className="modal_header has_border">
                Add a persona to this campaign
			</div>
			<div className="modal_section modal_section_grey flex_item_fix">
				<div className="flex flex_align_start">
					<div className="flex_item_full">
						<InputCustom
							value={newPersona.name}
							type="text"
							label="New persona"
							name="name"
							placeholder="Name"
							onChange={(e) => handleChangeInputNewPersona(e)}
							fullWidth={true}
							autoFocus={true}
							color="white"
							blockClassName="no_margin"
							labelClassName="s_16"
						/>
					</div>
					<div className="flex_item_fix ml_10">
						<Btn
							onClick={() => {addNewPersona({name : newPersona.name, data: []}), closePersonaBoard();} }
							message="Add"
							className="align_with_labeled"
						/>
					</div>
				</div>
			</div>
			<div className="modal_body pos_rel vscroll" style={{'maxHeight': 400}}>
				{loaderSavedPersona ?
					<div className="flex flex_justify_center" style={{'minHeight': 100}}>
						<span className="wheel size_32 flex_item_fix"></span>
					</div>
					:
					<>
						{savedPersonas.length < 1 ?
							<>
								<div className="empty_state">
									<img className="empty_state_img" src="/Assets/persona_empty.svg"/>
									<div className="empty_state_title">No favorite persona yet</div>
									<div className="empty_state_text">Here you will find all the favorite personas</div>
								</div>
							</>
							:
							<>
								<div className="fw_medium s_16">Favorites personas</div>
								{savedPersonas.map(pers => {
									const data = {
										name : pers.GroupName,
										data: pers.Triggers.map(x => ({...x, Group : {Id: pers.GroupId, Label: pers.GroupName}})),
										id: pers.GroupId,
										description: pers.Description
									};
									const isDisabled = campaignPersonas.map(x=> x.Id).includes(pers.GroupId);
									const cssClass = ['persona_item', 'flex'];
									if( isDisabled ){
										cssClass.push('disabled');
									}
									return(
										<div
											key={pers.GroupId}
											className={cssClass.join(' ')}
											onClick={() => addSavedPersona(data)}
											disabled={isDisabled}
										>
											<div className="persona_item_icon flex_item_fix">
												<i className="fas fa-star"></i>
											</div>
											<div className="persona_item_body flex_item_full">
												<div className="persona_item_name">
													{pers.GroupName}
												</div>
												{pers.Description &&
                                                    <div className="persona_item_desc">
                                                    	{pers.Description}
                                                    </div>
												}
											</div>
											<a className="persona_item_del flex_item_fix"
												onClick={(e) => {e.stopPropagation(); wantToDeletePersona(pers.GroupId);}}>
												<i className="fas fa-trash-alt"></i>
											</a>
										</div>
									);
								})}
								<Confirm
									isOpen={deleteConfirmIsOpen}
									onClose={(e) => setDeleteConfirmIsOpen(false)}
									title="Delete persona"
									text="Are you sure to delete this persona?"
									confirmText="Delete"
									confirmCallback={(e) => deleteThisPersona(personaIDToDelete) }
								/>
							</>
						}
					</>
				}
			</div>
		</Modal>
	);
}