import React, { useState, Fragment } from 'react';
import Btn from '../../../../Components/Btn';
import Modal from './../../../../Components/Modal';
import Switch from './../../../../Components/Switch';
import NumericUpDown from './../../../../Components/NumericUpDown';
import useEventBroker from '../../../../Hooks/useEventBroker';

export default function ZoneSliderSettingsModal({ zone, saveCallback }) {

	const { subscribe } = useEventBroker();

	const [modalIsOpened, setModalIsOpened] = useState(false);
	const [overwriteDefaultSettings, setOverwriteDefaultSettings] = useState(true);
	const [sliderSettings, setSliderSettings] = useState({});
	const [sliderDefaultSettings, setSliderDefaultSettings] = useState({});

	subscribe('evt/businessoperations/updatedSliderSettingsRequested', e => {
		const data = e.detail;
		if (zone.zoneId === data.zone.zoneId) {
			const { zoneSliderSettings, defaultZoneSliderSettings } = data;

			let settings = zoneSliderSettings || defaultZoneSliderSettings;
			setSliderSettings({ ...settings, autoplaySpeed: parseInt(settings.autoplaySpeed) });
			setSliderDefaultSettings(defaultZoneSliderSettings);
			setOverwriteDefaultSettings(!!zoneSliderSettings);
			setModalIsOpened(true);
		}
	}, []);

	const save = () => {
		saveCallback(overwriteDefaultSettings ? sliderSettings : null);
	};

	const handleSave = () => {
		save();
		setModalIsOpened(false);
	};

	return (
		<>
			{
				<Modal
					isOpen={modalIsOpened}
					width="500"
					onClose={() => setModalIsOpened(false)}
				>
					<div className="modal_header has_border">
						Slider Settings
						<div className="modal_header_desc">
							Use default settings or overwrite with your own.
						</div>
					</div>
					<div className="modal_body grey_0">
						<div className="form_line has_border flex mb_10">
							<span className="flex_item_full fw_medium">Overwrite default settings</span>
							<span className="flex_item_fix ml_10">
								<Switch
									value={overwriteDefaultSettings}
									name="overwriteDefaultSettings"
									checked={overwriteDefaultSettings}
									onChange={() => setOverwriteDefaultSettings(!overwriteDefaultSettings)}
								></Switch>
							</span>
						</div>
						<div className="form_line flex s_15">
							<span className="flex_item_full">Use infinite slide</span>
							<span className="flex_item_fix ml_10">
								<Switch
									value={overwriteDefaultSettings ? sliderSettings.infinite : sliderDefaultSettings.infinite}
									name="infinite"
									disabled={!overwriteDefaultSettings}
									checked={overwriteDefaultSettings ? sliderSettings.infinite : sliderDefaultSettings.infinite}
									onChange={() => overwriteDefaultSettings && setSliderSettings({ ...sliderSettings, infinite: !sliderSettings.infinite })}
								></Switch>
							</span>
						</div>
						<div className="form_line flex s_15">
							<span className="flex_item_full">Autoplay</span>
							<span className="flex_item_fix ml_10">
								<Switch
									value={overwriteDefaultSettings ? sliderSettings.autoplay : sliderDefaultSettings.autoplay}
									name="autoplay"
									disabled={!overwriteDefaultSettings}
									checked={overwriteDefaultSettings ? sliderSettings.autoplay : sliderDefaultSettings.autoplay}
									onChange={() => overwriteDefaultSettings && setSliderSettings({ ...sliderSettings, autoplay: !sliderSettings.autoplay })}
								></Switch>
							</span>
						</div>
						<div className="form_line flex s_15">
							<span className="flex_item_full">Autoplay speed (milliseconds)</span>
							<span className="flex_item_fix ml_10">
								<NumericUpDown width="200"
									min={0}
									canEdit={overwriteDefaultSettings}
									value={overwriteDefaultSettings ? sliderSettings.autoplaySpeed : sliderDefaultSettings.autoplaySpeed}
									onChange={(e) => overwriteDefaultSettings && setSliderSettings({ ...sliderSettings, autoplaySpeed: e.target.value })}
								/>
							</span>
						</div>
					</div>

					<div className="modal_footer modal_footer_right">
						<Btn
							onClickFunction={(e) => { setModalIsOpened(false); }}
							message="Cancel"
							color="secondary"
							style="outline"
						/>
						<Btn
							onClickFunction={handleSave}
							message="Ok"
						/>
					</div>
				</Modal>
			}
		</>
	);
}   