
import { react2angular } from 'react2angular';
import React, { useEffect, useState } from 'react';
import HistoryContextProvider from './HistoryContext';
import TableHistory from './components/TableHistory';
import HistoryContainer from './HistoryContainer';

export default function HistoryBridge(props) {

	const $http = props.$http;
	const $timeout = props.$timeout;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;

	return (
		<HistoryContextProvider
			$http={$http}
			$timeout={$timeout}
			$rootScope={$rootScope}
			$routeParams={$routeParams}
		>
			<HistoryContainer />
		</HistoryContextProvider>
	)
}

angular
	.module('beyableSaasApp.Admin',)
	.component('historybridge', react2angular(HistoryBridge, [],
		['$rootScope', '$scope', '$http', '$routeParams', '$timeout']));