import React ,{useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Btn from '../../Components/Btn';

export default function AccountHomeUsers(props) {
	const [t, i18n] = useTranslation('common');
	const [accountCurrentTab, setAccountCurrentTab] = useState(1);
	const [loadIsInOperationingProcess, setLoadIsInOperationingProcess] = useState(false);
	const [languageSelection, setLanguageSelection] = useState('');
	const [isAdmin, setisAdmin] = useState(false);
	useEffect(()=>{
		const i18nL= localStorage.getItem('i18nL');
		setLanguageSelection(JSON.parse(i18nL));
		const isAdminTest = currentUser && currentUser.Role === 'Admin' ? true : false;
		setisAdmin(isAdminTest);
	},[]);
	function signOut () {
		props.AuthServices.logout();
	}

	function ChangePassword(user) {
		props.AuthServices.changePassword(user.Key);
	}

	const currentUser = props.AuthServices.GetAuthenticatedUser();
	function handleChangeLanguage(choice)
	{
		i18n.changeLanguage(choice);
		setLanguageSelection(choice);
		props.$timeout(function() { props.$rootScope.$broadcast('event:changeLangI18next', choice);});
		localStorage.setItem('i18nL',JSON.stringify(choice));
	}
	return (
		<div className="page_root section_root my_account_inner">
			{loadIsInOperationingProcess && <div className="spinner">
				<div className="rect1"></div>
				<div className="rect2"></div>
				<div className="rect3"></div>
				<div className="rect4"></div>
				<div className="rect5"></div>
			</div>}
			{!loadIsInOperationingProcess && <>
				<div className="page_head flex flex_align_end">
					<div className="flex_item_full">
						<h1 className="page_title">{t('password.userPreferences')}</h1>
						<h2 className="page_sub_title grey_medium"></h2>
					</div>
					<div className="flex_item_fix">
						<a className="btnUi btn_outline_secondary" onClick={()=>signOut()} >
							<span className="btn_txt">{t('password.signOut')}</span>
						</a>
					</div>
				</div>
				<nav className="page_tabs">
					<ul className="tabs">
						<li>
							<a onClick={()=>setAccountCurrentTab(1)} className={accountCurrentTab === 1 ? 'active' : ''}>{t('password.AccountCurrentTabPassword')}</a>
						</li>
						{isAdmin && <li>
							<a  onClick={()=>setAccountCurrentTab(2)} className={accountCurrentTab === 2 ? 'active' : ''}>{t('password.AccountCurrentTabOption')}</a>
						</li>}
					</ul>
				</nav>

				{accountCurrentTab === 1 && 
			<section className="page_body">
				<a className="btnUi btn_outline_secondary" onClick={()=>ChangePassword(currentUser)}>
					<span className="btn_txt">{t('password.changePassword')}</span>
				</a>
			</section>}
				{accountCurrentTab === 2 && 
			<section className="page_body">
				<h2>{t('lang.choose')}</h2>
				<Btn
					onClickFunction={() => handleChangeLanguage('en-EN')}
					message="English"
					color={languageSelection.includes('en') ?  'primary':'secondary'}
				/>
				<Btn
					message="Français"
					onClickFunction={() => handleChangeLanguage('fr-FR')}
					color={languageSelection === 'fr-FR' ?  'primary':'secondary'}
				/>
			</section>}
			</>}
		</div>
	);
}
