import React, { useEffect, useState } from 'react';
import Panel from '../../Application/Components/Panel';
import Switch from '../../Application/Components/Switch';
import style from './flagPannel.module.css';

export default function FlagPannel(props) {

	let store = JSON.parse(localStorage.getItem('ffl'));
	if (!store || store[0] === null) return <></>;

	const [flags, setFlags] = useState(
		JSON.parse(localStorage.getItem('ffl'))
	);
	const [state, setState] = useState();
	const [checkedState, setIsChecked] = useState(
		store.map((flag, i) => ({ active: flag.active, index: i }))
	);

	useEffect(() => {
		
		const onPagefeatures = document.querySelectorAll('[data-ffl]');
		let onPageSingleFeature = [];
		for (var value of onPagefeatures.values()) {
			let isInArray = onPageSingleFeature.find(
				(elem) => elem.dataset.ffl === value.dataset.ffl
			);
			!isInArray && onPageSingleFeature.push(value);
		}
		if (flags && flags.length ) {
			let copyFlags = flags.map((flag, i) => {
				const foundFeature = onPageSingleFeature.find(
					(feature) => feature.dataset.ffl === flag.name
				);
				if (foundFeature) return { ...flag, isOnPage: true };
				else return flag;
			});
			localStorage.setItem('ffl', JSON.stringify(copyFlags));
			setState(
				flags.map((flag, i) => (
					<div
						key={`container_flag${i}`}
						className={`${style.feature_item} ${!copyFlags[i].isOnPage ? style.feature_item_notonpage : ''}`}
					>
						<div className={style.feature_item_inner}>
							<Switch
								forcedChecked={checkedState[i].active}
								onChange={() => handleToggleChange(i)}
								position="right"
							>
								<div className={!copyFlags[i].isOnPage ? style.feature_disabled : ''}>{flag.description}</div>
								<div className={style.feature_id}>
									{flag.name}
								</div>
							</Switch>
						</div>
					</div>
				))
			);
		} else {
			setState(<span>No feature flag is available</span>);
		}
		
	}, [checkedState, props.isOpen]);

	const handleToggleChange = (i) => {
		const updatedCheckedState = checkedState.map((elem, index) => {
			index === i
				? (elem.active = !elem.active)
				: (elem.active = !!elem.active);
			return elem;
		});

		setIsChecked(updatedCheckedState);
		const updatedFlags = flags.map((elem, index) => {
			index === i
				? (elem.active = !elem.active)
				: (elem.active = !!elem.active);
			return elem;
		});

		setFlags(updatedFlags);

		localStorage.setItem('ffl', JSON.stringify(updatedFlags));
	};

	return (
		<Panel
			isOpen={props.isOpen}
			onClose={props.onClose}
			overlay={true}
			closeOnOverlay={true}
			width={560}
		>
			<div className="modal_header has_border">Feature flags</div>
			<div className="modal_body">{state}</div>
		</Panel>
	);
}
