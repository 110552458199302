import React, { useState, useEffect, Fragment } from 'react';
import { react2angular } from 'react2angular';
import SystemServices from '../../Services/SystemServices';
import SetupAccountServices from '../../Services/SetupAccountServices';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import AccountSetupProvider from './Context/ContextAccountSetup';
import ViewSetupCode from './ViewSetupCode';
import SelectFileContainer from './SelectFileContainer';
import Btn from '../../Components/Btn';
import Modal from './../../Components/Modal';
import './SetupContainer.css';

export default function SetupContainer(props) {

	const systemServices = new SystemServices(props.$rootScope);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const setupAccountServices = new SetupAccountServices(props.$http, props.$routeParams);

	const getAccountApi = (accountId, callbackSuccess, callbackError) => setupAccountServices.getAccountApi(accountId, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const getAccountSetup = (accountId, callbackSuccess, callbackError) => setupAccountServices.getAccountSetup(accountId, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const putAccountSetup = (accountId, reportUrl, callbackSuccess, callbackError) => setupAccountServices.putAccountSetup(accountId, reportUrl, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const putAccountApi = (accountId, listScript, callbackSuccess, callbackError) => setupAccountServices.putAccountApi(accountId, listScript, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const [listOfScripts, setListOfScripts] = useState([]);
	const [etag, setEtag] = useState('');
	const [reportEtag, setReportEtag] = useState('');
	const [isReloading, setIsRealoading] = useState(true);
	const [isRegister, setIsRegister] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [newSelectedFiles, setNewSelectedFiles] = useState([]);
	const [modalIsOppen, setModalIsOppen] = useState(false);
	const [modalReportIsOppen, setModalReportIsOppen] = useState(false);
	const [urlReport, setUrlReport] = useState({ url: '' });
	const [hasUrlReport, setHasUrlReport] = useState();

	useEffect(() => {
		if (props.accountCurrent === 7) {
			setIsRealoading(true);
			getAccountApi(accountId, data => {
				setListOfScripts(data.allApiFileNames);
				setEtag(data.etag && data.etag);
				setSelectedFiles(data.accountApis && data.accountApis);
				setIsRealoading(false);
			}, error => {
				console.log('error', error);
				setIsRealoading(false);
			});
		}
		setHasUrlReport();
	}, [isRegister, props.accountCurrent]);

	useEffect(() => {
		if (props.accountCurrent === 7) {
			getTheReportAccountSetup();
		}
	}, [isRegister, props.accountCurrent]);

	const getTheReportAccountSetup = () => {
		setIsRealoading(true);
		getAccountSetup(accountId, data => {
			setHasUrlReport(data.accountSetup.accountSetupFileNames);
			setUrlReport({ url: data.accountSetup.accountSetupFileNames });
			setReportEtag(data.etag && data.etag);
			setIsRealoading(false);
		}, error => {
			console.log('error', error);
			setIsRealoading(false);
		});
	};

	const putTheAccountApis = (e) => {
		e.preventDefault();
		setIsRealoading(true);
		const selectFiles = newSelectedFiles !== null ? newSelectedFiles.map(x => x.value) : [];
		let accountApiCOnfig = {
			etag: etag,
			accountApiFileNames: selectFiles
		};
		putAccountApi(accountId, accountApiCOnfig, data => {
			setIsRealoading(false);
			setIsRegister(!isRegister);
		}, error => {
			console.log('error', error);
			setIsRealoading(false);
		});
		setNewSelectedFiles([]);
	};

	const handleChangeInput = (e) => {
		e.persist();
		setUrlReport(prevValues => ({
			...prevValues,
			[e.target.name]: e.target.value
		})
		);
	};

	const setupAccount = (e) => {
		const url = { accountSetupFileName: urlReport.url };
		setIsRealoading(true);
		let accountSetupConfig = {
			etag: reportEtag,
			accountSetupFileName: urlReport.url
		};
		putAccountSetup(accountId, accountSetupConfig, data => {
			getTheReportAccountSetup();
		}, error => {
			console.log('error', error);
			setIsRealoading(false);
		});
	};

	const isAnUrl = (url) => {
		if (url) {
			if (url.match(/(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/)) {
				return true;
			} else {
				return false;
			}
		}
	};

	return (

		<AccountSetupProvider>
			<div className="page_body">
				{
					!isReloading &&
					<Fragment>
						{modalReportIsOppen &&
							<Modal
								isOpen={modalReportIsOppen}
								width="700"
								onClose={() => { setModalReportIsOppen(false); setUrlReport({ url: hasUrlReport }); }}
							>


								<div className="modal_header has_border">
									Paste the setup report url for this account
								</div>

								<div className="modal_body">
									<label htmlFor='url'>setup report url :</label>
									<input value={urlReport.url} type='text' placeholder='Add url' name='url'
										onChange={(e) => handleChangeInput(e)}
									/>

								</div>
								<div className="modal_footer">
									<Btn
										onClickFunction={isAnUrl(urlReport.url) ? (e) => { setupAccount(); setModalReportIsOppen(false); } : false}
										message="Confirm"
										size="l"
										color="primary"
										disabled={isAnUrl(urlReport.url) ? false : true}
									/>
									<Btn
										onClickFunction={() => { setModalReportIsOppen(false); setUrlReport({ url: hasUrlReport }); }}
										message="Cancel"
										color="secondary"
										size="l"
										style="outline"
									/>
								</div>
							</Modal>
						}

						{modalIsOppen &&
							<Modal
								isOpen={modalIsOppen}
								width="700"
								onClose={() => { setModalIsOppen(false); }}
							>
								<div className="modal_header has_border">
									Select scripts for this account
								</div>
								<div className="modal_body">
									<SelectFileContainer
										listOfScripts={listOfScripts}
										selectedFiles={selectedFiles}
										setNewSelectedFiles={setNewSelectedFiles}
									/>
								</div>
								<div className="modal_footer">
									<Btn
										onClickFunction={(e) => { putTheAccountApis(e); setModalIsOppen(false); }}
										message="Confirm"
										size="l"
										color="primary"
									/>
									<Btn
										onClickFunction={() => setModalIsOppen(false)}
										message="Cancel"
										color="secondary"
										size="l"
										style="outline"
									/>
								</div>
							</Modal>
						}

						<div className="setup-report-container">
							{
								hasUrlReport && hasUrlReport.length !== 0 &&
								<>
									<a href={hasUrlReport} target='_blank' rel="noreferrer">Link to setup report</a>
									<Btn
										onClickFunction={() => setModalReportIsOppen(true)}
										icon="fas fa-pen"
										size="l"
										message="Edit"
										color="primary" />
								</>
							}

							{
								!hasUrlReport &&
								<Btn
									onClickFunction={() => setModalReportIsOppen(true)}
									icon="fas fa-plus"
									size="l"
									message="Add setup report"
									color="primary" />
							}
						</div>

						{
							!selectedFiles &&
							<div className="empty_page">
								<div className="empty_page_title">
									The script for this account is not configured yet
								</div>
								<div className="empty_page_btn">
									<Btn
										onClickFunction={() => setModalIsOppen(true)}
										icon="fas fa-plus"
										size="l"
										message="Add script for this account"
										color="primary" />
								</div>
							</div>
						}


						{
							selectedFiles && selectedFiles.length == 0 &&
							<div className="empty_page">
								<div className="empty_page_title">The script for this account is not configured yet</div>
								<div className="empty_page_btn">
									<Btn
										onClickFunction={() => setModalIsOppen(true)}
										icon="fas fa-plus"
										size="l"
										message="Add script for this account"
										color="primary" />
								</div>
							</div>
						}
						{
							selectedFiles && selectedFiles.length !== 0 &&
							<ViewSetupCode
								selectedFiles={selectedFiles}
								setModalIsOppen={(e) => setModalIsOppen(e)}
								accountCurrent={props.accountCurrent}
							/>
						}
					</Fragment>
				}
				{
					isReloading &&
					<div className="spinner_overlay">
						<div className="spinner">
							<div className="rect1"></div>
							<div className="rect2"></div>
							<div className="rect3"></div>
							<div className="rect4"></div>
							<div className="rect5"></div>
						</div>
					</div>
				}
			</div>
		</AccountSetupProvider>
	);
}
angular
	.module('beyableSaasApp.Admin.EditAccount2', [])
	.component('setupContainer', react2angular(SetupContainer, ['accountCurrent'], ['$http', '$rootScope', '$routeParams']));