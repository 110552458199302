import React from 'react';
import Btn from '../../../../Components/Btn';
import { useGroupSortingContext } from './contextGroupSorting';
import GroupSortingModal from './GroupSortingModal';
import GroupSortingCard from './GroupSortingCard';

export default function GroupSorting(_: any) {

	const {
		handleOpenAddGroupSorting,
		existingSortingGroup
	} = useGroupSortingContext();

	const addGroupingRequested = () => {
		handleOpenAddGroupSorting();
	};

	return (<>
		<div className="collection_sidebar_block_title">Grouping</div>

		{existingSortingGroup &&
			<GroupSortingCard
				groupSorting={existingSortingGroup}
			/>
		}

		{!existingSortingGroup &&
			<Btn
				onClickFunction={() => addGroupingRequested()}
				icon="fas fa-plus-circle"
				message="Add grouping rule"
				color="primary"
				style="outline"
				fullWidth={true}
			/>
		}

		<GroupSortingModal />
	</>);
}