import React from 'react';
import ReactTooltip from 'react-tooltip';
import {Checkbox, Radio} from './Checkbox/Checkbox';

function ListboxItem({
	children,
	message = '',
	description = '',
	imageUrl = '',
	tooltip = null,

	onClick,
	onMouseEnter,
	href = null,
	target = null,
	rel = null,

	selected = false,
	hasSelectedIcon = false,
	hasCheckbox = false,
	hasRadio = false,
	disabled = false,
	size = 'm',
	className = '',
	style,
	icon = '',
	iconCustom = '',
	capitalize = false
}) {

	const handleClick = (ev) => {
		if( disabled ) return;

		if (typeof onClick === 'function') {
			onClick(ev);
		}
	};

	let cssClass = ['listbox_item'];

	// className
	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Size
	if (size) {
		cssClass.push(size);
	}

	// Selected
	if (selected) {
		cssClass.push('selected');
	}

	// Disabled
	if (disabled) {
		cssClass.push('disabled');
	}

	// Capitalize
	if (capitalize) {
		cssClass.push('capitalize');
	}

	return (
		<li>
			<a
				className={cssClass.join(' ')}
				style={style}
				onClick={(e) => handleClick(e)}
				onMouseEnter={onMouseEnter}
				target={target}
				rel={rel}
				data-tip={tooltip}
				href={href}>
				<span className='listbox_item_flex'>
					{hasCheckbox && 
						<span className='listbox_item_left'>
							<Checkbox
								forcedChecked={selected}
							/>
						</span>
					}
					{hasRadio && 
						<span className='listbox_item_left'>
							<Radio
								forcedChecked={selected}
							/>
						</span>
					}
					{icon &&
						<span className='listbox_item_left'>
							<i className={'listbox_item_icon ' + icon}></i>
						</span>
					}
					{iconCustom &&
						<span className='listbox_item_left listbox_item_icon'>
							{iconCustom}
						</span>
					}
					<span className='listbox_item_center'>
						{message}
						{children}
						{description &&
							<div className='listbox_item_desc'>{description}</div>
						}
						{imageUrl &&
							<img className='listbox_item_img' src={imageUrl} />
						}
					</span>
					{selected && hasSelectedIcon &&
						<span className='listbox_item_check'>
							<i className='fas fa-check'></i>
						</span>
					}
				</span>
			</a>
			{tooltip &&
				<ReactTooltip
					backgroundColor="black"
					effect="solid"
					place="bottom"
					eventOff="click"
					delayShow={600}
				/>
			}
		</li>
	);
}
export default React.memo(ListboxItem);

function ListboxItemExternal({
	render,
	children,
	message = '',
	description = '',
	imageUrl = '',
	tooltip = null,

	selected = false,
	hasSelectedIcon = false,
	hasCheckbox = false,
	disabled = false,
	size = 'm',
	className = '',
	icon = '',
	iconCustom = '',
	capitalize = false
}) {

	//const [content, setContent] = useState(<></>);

	let cssClass = ['listbox_item'];

	// className
	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Size
	if (size) {
		cssClass.push(size);
	}

	// Selected
	if (selected) {
		cssClass.push('selected');
	}

	// Disabled
	if (disabled) {
		cssClass.push('disabled');
	}

	// Capitalize
	if (capitalize) {
		cssClass.push('capitalize');
	}

	const content = (
		<span className='listbox_item_flex'>
			{hasCheckbox && 
				<span className='listbox_item_left'>
					<Checkbox
						forcedChecked={selected}
					/>
				</span>
			}
			{icon &&
				<span className='listbox_item_left'>
					<i className={'listbox_item_icon ' + icon}></i>
				</span>
			}
			{iconCustom &&
				<span className='listbox_item_left listbox_item_icon'>
					{iconCustom}
				</span>
			}
			<span className='listbox_item_center'>
				{message}
				{children}
				{description &&
					<div className='listbox_item_desc'>{description}</div>
				}
				{imageUrl &&
					<img className='listbox_item_img' src={imageUrl} />
				}
			</span>
			{selected && hasSelectedIcon &&
				<span className='listbox_item_check'>
					<i className='fas fa-check'></i>
				</span>
			}
		</span>
	);

	const inner = render(cssClass.join(' '), content);

	return (
		<li data-tip={tooltip}>
			{inner}
			{tooltip &&
				<ReactTooltip
					backgroundColor="black"
					effect="solid"
					place="bottom"
					eventOff="click"
					delayShow={600}
				/>
			}
		</li>
	);
}

export {
	ListboxItemExternal
};
