import React from 'react';

function ModalHeader({
	children,
	className = '',
	title = '',
	intro = '',
	desc = '',
	hasBorder = true,
	hasBottomPadding = true
}) {

	let cssClass = ['modal_header'];

	// className
	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Border
	if (hasBorder) {
		cssClass.push('has_border');
	}

	// Bottom margin
	if (!hasBottomPadding) {
		cssClass.push('has_no_bottom_padding');
	}

	return (
		<div className={cssClass.join(' ')}>
			{intro && <div className="modal_header_pre_title">{intro}</div>}
			{title && <div className="modal_title">{title}</div>}
			{desc && <div className="modal_header_desc">{desc}</div>}
			{children}
		</div>
	);
}
export default ModalHeader;
