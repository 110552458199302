import HttpServices from './HttpServices';

export default class OnboardingServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
	}

	getOnboardingAccountData(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}onboarding/${accountId}`, callbackSuccess, callbackError);
	}
	putOnboardingAccountData(accountId, accountData, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}onboarding/${accountId}`,accountData, callbackSuccess, callbackError);
	}

	addModules(accountId, modules, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}account/${accountId}/module`, { moduleIds: modules }, callbackSuccess, callbackError);
	}
	getModules(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}account/${accountId}/module`, callbackSuccess, callbackError);
	}
}