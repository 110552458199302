import React, { useContext, useState, useEffect } from 'react';
import './DesignBusinessOperationContainer.css';
import '../../BusinessOperationBanner.css';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import PopinConfirm from './../../../../Components/PopinConfirm';
import Btn from './../../../../Components/Btn';
import { check } from '../../../../Components/Can';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
export default function DesignBanner({ banner, deleteABanner, setCurrentIndex }) {
	const {
		operation,
		userSimplifyRole,
		planAccount,
		isTransformExistingBanner
	} = useContext(CreateBusinessOperationContext);

	if (banner.bannerType === 'Slider') {
		return null;
	}
	const [bannersWithOperation, setBannersWithOperation] = useState([]);
	const [idToDelete, setIdToDelete] = useState();
	const [poppinIsOppen, setPoppinIsOppen] = useState(false);
	const [poppinCantDelete, setPoppinCantDelete] = useState(false);
	const idTooltipODelete = uuidv4();
	function FileConvertSize(aSize) {
		aSize = Math.abs(parseInt(aSize, 10));
		var def = [[1, 'octets'], [1024, 'ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
		for (var i = 0; i < def.length; i++) {
			if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
		}
	}
	const sortBannersWithOperation = () => {
		let operations = [];
		operation.operation.locations.forEach(x => operations.push(x.bannerId));
		setBannersWithOperation(operations);
	};

	useEffect(() => sortBannersWithOperation(), []);
	const canDeleteABanner = check(planAccount, userSimplifyRole, 'simplifyOperation:canUploadImage', null);

	return (
		<div className="bannersListItem has_preview">
			{poppinIsOppen &&
				<PopinConfirm
					cancel={() => setPoppinIsOppen(false)}
					title={'Are you sure you want to delete this banner ?'}
					message={''}
				>
					<Btn
						onClickFunction={(e) => { setPoppinIsOppen(false); }}
						message="Cancel"
						color="secondary"
						style="outline"
					/>
					<Btn
						onClickFunction={(e) => { deleteABanner(idToDelete); setPoppinIsOppen(false); }}
						message="Delete"
						color="alert"
						icon="fas fa-trash-alt"
					/>
				</PopinConfirm>
			}
			{poppinCantDelete &&
				<PopinConfirm
					cancel={() => setPoppinCantDelete(false)}
					title={'The banner is use on campaign'}
					message={'This banner could not be removed because it is used in the context of a campaign.'}
				>
					<Btn
						onClickFunction={(e) => { setPoppinCantDelete(false); }}
						message="Ok"
						color="secondary"
						style="outline"
					/>
				</PopinConfirm>
			}
			<div className="bannersListItemImg" onClick={() => setCurrentIndex(banner.bannerId)} >
				<img src={banner.imagePath} width={banner.metaData.size.width} height={banner.metaData.size.height} />
			</div>
			<div className="bannersListItemName" title={banner.metaData.fileName && banner.metaData.fileName}>
				<div className="bannersListItemFloatText">
					{banner.metaData.fileName && banner.metaData.fileName}
				</div>
			</div>
			<div className="bannersListItemInfo">
				<div className="flex">
					<div className="flex_item_auto">
						{FileConvertSize(banner.metaData.weight)}
						<span className="bannersListItemInfoSep">|</span>
						{banner.metaData.size.width}x{banner.metaData.size.height}
						<span className="bannersListItemInfoSep">|</span>
						{banner.metaData.extension && banner.metaData.extension.toUpperCase()}
					</div>
					{!isTransformExistingBanner && <>
						{canDeleteABanner && <>
							<ReactTooltip id={idTooltipODelete} backgroundColor='black' effect='solid' />
							<div className="flex_item_fix">
								<a className="bannersListItemAction"
									data-for={idTooltipODelete} data-tip={'Delete the image'}
									onClick={bannersWithOperation.includes(banner.bannerId) ?
										() => setPoppinCantDelete(true) :
										() => { setIdToDelete(banner.bannerId); setPoppinIsOppen(true); }}>
									<i className="fas fa-trash-alt"></i>
								</a>
							</div></>
						}
					</>
					}
				</div>
			</div>
		</div>
	);
}