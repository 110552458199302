import { useEffect, useState } from 'react';

export default function useFeature() {
	const [features, setFeatures] = useState([]);

	const refresh = () => {
		const store = localStorage.getItem('ffl');
		if (store) {
			const storeContent = JSON.parse(store);
			setFeatures(storeContent.filter(x => x.active).map(x => x.name));
		} else {
			setFeatures([]);
		}
	};


	useEffect(() => {
		// setInterval(() => {
		// 	refresh();
		// }, 3000);
		refresh();
	}, []);

	return {
		features
	};
}

