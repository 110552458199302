
const i18nL= localStorage.getItem('i18nL');
const enTriggerCat = {
	'TIME': { 			Label: 'Specific time period', 		Order: 1, 	Icon: 'time' },
	'BEHAVIOR': { 		Label: 'Visitor behavior', 			Order: 2, 	Icon: 'behavior' },
	'PAGES': { 			Label: 'Visited pages', 			Order: 3, 	Icon: 'pages' },
	'VISITORCHAR': { 	Label: 'Visitor characteristics', 	Order: 4, 	Icon: 'visitor' },
	'JOURNEY': { 		Label: 'Cross-session journey', 	Order: 5, 	Icon: 'journey' },
	'PRODUCT': { 		Label: 'Product characteristics', 	Order: 6, 	Icon: 'product' },
	'CART': { 			Label: 'Cart characteristics', 		Order: 7, 	Icon: 'cart' },
	'PREDICTIVE': { 	Label: 'Predictive targeting', 		Order: 8, 	Icon: 'predictive' },
	'ACQUI': { 			Label: 'Acquisition', 				Order: 9, 	Icon: 'acquisition' },
	'BUSINESSCHAR': { 	Label: 'Business characteristics', 	Order: 10,	Icon: 'buisiness' },
	'CUSTOM': { 		Label: 'Custom trigger', 			Order: 11,	Icon: 'custom' },
	'Hidden': { 		Label: 'Hidden', 					Order: 0, 	Icon: '' },
};
const frTriggerCat = {
	'TIME': { 			Label: 'Période spécifique', 		Order: 1, 	Icon: 'time' },
	'BEHAVIOR': { 		Label: 'Comportement des visiteurs', 			Order: 2, 	Icon: 'behavior' },
	'PAGES': { 			Label: 'Pages visitées', 			Order: 3, 	Icon: 'pages' },
	'VISITORCHAR': { 	Label: 'Caractéristiques des visiteurs', 	Order: 4, 	Icon: 'visitor' },
	'JOURNEY': { 		Label: 'Parcours intersessions', 	Order: 5, 	Icon: 'journey' },
	'PRODUCT': { 		Label: 'Caractéristiques du produit', 	Order: 6, 	Icon: 'product' },
	'CART': { 			Label: 'Caractéristiques du panier', 		Order: 7, 	Icon: 'cart' },
	'PREDICTIVE': { 	Label: 'Ciblage prédictif', 		Order: 8, 	Icon: 'predictive' },
	'ACQUI': { 			Label: 'Acquisition', 				Order: 9, 	Icon: 'acquisition' },
	'BUSINESSCHAR': { 	Label: 'Caractéristiques de l\'activité', 	Order: 10,	Icon: 'buisiness' },
	'CUSTOM': { 		Label: 'Déclencheur personnalisé', 			Order: 11,	Icon: 'custom' },
	'Hidden': { 		Label: 'Cachés', 					Order: 0, 	Icon: '' },
};

export const triggerCategories = i18nL && i18nL.includes('fr') ?  frTriggerCat : enTriggerCat ;
