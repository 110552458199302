import HttpServices from './HttpServices';

export default class NotificationsServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`; 
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
		this.accountId = accountId;
	}

	getNotifications(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/notifications`, callbackSuccess, callbackError);
	}
	deleteNotifications(id, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}${this.accountId}/notifications/${id}`, callbackSuccess, callbackError);
	}
	readNotifications(id, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/notifications/${id}`, null, callbackSuccess, callbackError);
	}
}