import React from 'react'
import Insight from '../Insight/Insight'

export default function SessionBavWithoutCamparison({
	bav, uniqueVisitors
}) {
	return (
		<>
			<Insight label="Sessions" {...bav.nbSession} />
			<Insight label="Average page views" {...bav.avViews} />
			<Insight label="Visit duration" {...bav.vDuration} />
			<Insight label="Bounce rate" {...bav.bRate} />
			<Insight label="Unique visitors" {...uniqueVisitors} />
		</>
	)
}
