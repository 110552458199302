import React, { useState, useEffect, useContext } from 'react';
import './AlgoritmUserListContainer.css';
import Spinner from '../../../Components/Spinner';
import { Context } from './Context/Context';
import Btn from '../../../Components/Btn';
import EmptyState from '../../../Components/EmptyState';
import SearchBar from '../../../Components/SearchBar';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import TableGridContainer from '../UI/TableGridContainer';
import TableRowItem from '../UI/TableRowItem';
import TableColItem from '../UI/TableColItem';
export default function AlgoritmList({ clickOnAlgo,
	toggleAddItem,
	listOfShelvingRules,
	accountId,
	getProductsShelvingRules,
	toggle,
	setAlgorithm,
	setIsExistantAlgo,
	setAlgorithmName
}) {

	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [listTagAccount, setListTagAccount] = useState([]);
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState(false);
	const [loading, setLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [ruleIdSelected, setRuleIdSelected] = useState();

	const {
		existantAlgorithm,
		setExistantAlgorithm,
		setValueSelectBasedOn,
		setBtnSelect
	} = useContext(Context);

	const getProductsShelvingRulesbyId = () => {
		if (ruleIdSelected !== undefined) {
			getProductsShelvingRules(accountId, ruleIdSelected,
				newData => {
					setIsExistantAlgo();
					if (!newData.filters.length) {
						let data = { ...newData };
						data.filters = [
							{
								type: 'null',
								values: [
								]
							}
						],
						setExistantAlgorithm({ ...data });
						setAlgorithm({
							...data
						});
						setAlgorithmName(data.name);

					} else {
						setExistantAlgorithm({ ...newData });
						setAlgorithm({ ...newData });
						setAlgorithmName(newData.name);
					}
				}
			);
			setTimeout(() => toggle(), 500);
		}
	};

	useEffect(() => {
		getProductsShelvingRulesbyId();
	}, [ruleIdSelected]);

	useEffect(() => {
		if (listOfShelvingRules) {
			setLoading(false);
			setTotalCount(listOfShelvingRules.length);
		}
	}, [listOfShelvingRules]);


	// fonction de recherche dans le tableau
	const search = (table, word) => {
		setLoading(true);
		if (word.length > 0) {
			setLoading(false);
			setDataFiltered([]);
			let resultName = table.filter(
				line =>
					line.name.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			if (resultName.length === 0) {
				setNoData(true);
			}
			else {
				setNoData(false);
				setLoading(false);
				setDataFiltered([...resultName]);
			}

		} else {
			setNoData(false);
			setLoading(false);
			setDataFiltered();
		}
	};

	const getScopeDisplayName = (scope) => {
		switch (scope) {
		case 'global': return 'Global';
		case 'tagCurrentPage': return 'Tag from current page';
		case 'tagFromCart': return 'Tag from products in cart';
		case 'tagFromHistory': return 'Tag from history';

		case 'productCurrentPage': return 'Product from current page';
		case 'productFromHistory': return 'Product from history'; 
		case 'productFromCart': return 'Product from cart';

		case 'categoryCurrentPage': return 'Category from current page';
		case 'categoryFromHistory': return 'Category from history'; 
		case 'categoryFromCart': return 'Category from products in cart';

		default: return scope;
		}
	};

	const handleChange = (event) => {
		const value = event.target.value;
		const str = value;
		setValueInSearchBar(value);
		if(str.length === 0){
			setDataFiltered([]);
		}
		if (listOfShelvingRules) {
			search(listOfShelvingRules, str);
		}
	};
	const optionCss = {
		gridTemplateColumns: '4fr 2fr',
	};

	const elementReco = (i, elem) => {
		return <TableRowItem
			key={i}
			specialClasseName={'table_body_row'}
			styleObj={optionCss}>
			<TableColItem>
				<div className='flex'>
					<div className='flex_item_fix'>
						<a onClick={(e) => { setRuleIdSelected(elem.id); }} className="emerch_avatar emerch_avatar_margin">
							<i className='fas fa-shopping-bag'></i>
						</a>
					</div>
					<div className='flex_item_full'>
						<a className="table_row_name text_link_hover" onClick={(e) => { setRuleIdSelected(elem.id); }}>{elem.name}</a>
						<div className="s_11 grey_3 mt_3">{elem.id}</div>
					</div>
				</div>
			</TableColItem>
			<TableColItem>
				{getScopeDisplayName(elem.ruleScope.scopeIdentifier)}
			</TableColItem>
		</TableRowItem>;
	};


	return (
		<>
			<section className="section">
				<div className="page_block">
					{loading && totalCount == 0 &&
						<SpinnerWheel wheelSize="l" display="block" verticalSize="xl" />
					}
					{loading && totalCount != 0 &&
						<SpinnerWheel wheelSize="l" />
					}
					{totalCount != 0 &&
						<>
							<div className="flex mb_15">
								<div className="flex_item_full">
									<SearchBar
										onChangeValue={(e) => handleChange(e)}
										valueInSearchBar={valueInSearchBar}
										placeholder="Search"
										fullWidth={false}
									/>
								</div>
								<div className="flex_item_fix">
									<Btn
										message="New recommendations rule"
										icon="fas fa-plus"
										onClick={() => toggleAddItem()}
									/>
								</div>
							</div>
							{!noData &&
								<TableGridContainer specialClasseName="table_grid_emerch" >
									<TableRowItem specialClasseName={'table_head_row'} styleObj={optionCss}>
										<TableColItem specialClasseName={' '}>
											Rule name
										</TableColItem>
										<TableColItem specialClasseName={''}>
											Reference
										</TableColItem>

									</TableRowItem>
									{listOfShelvingRules &&
										<>
											{!dataFiltered && listOfShelvingRules.map((elem, i) =>
												elementReco(i, elem)
											)}
											{dataFiltered && dataFiltered.map((elem, i) =>
												elementReco(i, elem)
											)}
										</>
									}
								</TableGridContainer>
							}
						</>
					}

					{!loading && noData &&
						<EmptyState
							icon="fas fa-search"
							title="No matching rule"
							text="Please try another search"
							verticalSize="l"
							textSize="l"
						/>
					}

					{!loading && totalCount == 0 &&
						<EmptyState
							icon="fas fa-shopping-bag"
							title="No recommendations rule yet"
							text="Configure and preview optimal recommendations for your visitors"
							verticalSize="l"
							textSize="l"
							primaryAction={
								<Btn
									onClick={() => toggleAddItem()}
									message="New recommendations rule"
								/>
							}
						/>
					}
				</div>
			</section>

			{/* <div className="modalBody" >
			<h2 className="">Algorithms</h2>
			<div className="search_tag">
				<i className="fa fa-search"></i>
				<input className="searchBarTag" type="text"
					onChange={handleChange}
					value={valueInSearchBar}
					placeholder="Search for an algorithm "
				/>
			</div>

			<ul className="listOfShelvingRule">
				{!listOfShelvingRules ?
					<Spinner /> :
					<>
						{!dataFiltered ?
							(<>
								{listOfShelvingRules.map((elem, i) =>
									<li key={i} className="itemShelvingRule"
										onClick={(e) => { setRuleIdSelected(elem.id); }}>
										{elem.name}
									</li>
								)}
							</>)

							:

							(<>
								{noData ?

									<div className="noData">
										{noData}
									</div>

									:

									(<>
										{dataFiltered && dataFiltered.map((elem, i) =>
											<li key={i} className="itemShelvingRule" onClick={(e) => { setRuleIdSelected(elem.id); }}>
												{elem.name}
											</li>
										)}
									</>)
								}
							</>)
						}
					</>
				}
			</ul>
			<div className="tag_actions">
				<button className="tag_btn buttonAdd"
					onClick={() => toggleAddItem()}>New algorithm
					<i className="fas fa-plus"></i>
				</button>
			</div>
		</div> */}

		</>
	);
}
