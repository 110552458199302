import React from 'react';
import styles from './Link.module.css';
import ReactTooltip from 'react-tooltip';

function Link({
	children,
	message = '',
	tooltip,
	tooltipHTML,
	icon = '',
	iconPosition = 'before',
	iconVisibility = 'always', // always, hover, controlledHover

	onClick,
	href = null,
	target = null,
	rel = null,

	color = 'primary',
	strong = true,
	underline = false,
	arrow = false,
	disabled = false,
	controlledHover,
	className = '',

	forceSpaceBefore = true,
	forceSpaceAfter = true,
}) {

	const handleClick = (ev) => {
		if( disabled ) return;

		if (typeof onClick === 'function') {
			onClick(ev);
		}
	};

	let cssClass = [styles.link];
	let iconClass = [];

	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Disabled
	if (disabled) {
		cssClass.push(styles.is_disabled);
	}

	// Color
	if (color) {
		cssClass.push(styles['color_' + color]);
	}

	// Strong
	if (strong) {
		cssClass.push(styles.is_strong);
	}

	// Underline
	if (underline) {
		cssClass.push(styles.is_underline);
	}

	// Arrow
	if (arrow) {
		cssClass.push('has_arrow');
	}

	if (icon) {
		iconClass.push(icon);

		if (iconPosition === 'before') {
			iconClass.push(styles.first_icon);
		} else {
			iconClass.push(styles.last_icon);
		}

		if (iconVisibility === 'controlledHover') {
			iconClass.push(styles.icon_on_hover);
			if (controlledHover === true) {
				iconClass.push(styles.is_hovered);
			}
		} else if (iconVisibility === 'hover') {
			iconClass.push(styles.icon_on_hover);
		}
	}

	return (
		<>
			{tooltip &&
				<ReactTooltip
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="click"
					delayShow={600}
				/>
			}
			{tooltipHTML &&
				<ReactTooltip
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="click"
					delayShow={600}
					getContent={function() {
						return tooltipHTML;
					}}
				/>
			}
			{forceSpaceBefore && ' '}
			<a
				className={cssClass.join(' ')}
				onClick={(e) => handleClick(e)}
				data-tip={tooltipHTML ? 'a' : tooltip}
				target={target}
				rel={rel}
				href={disabled ? null : href}>
				{icon && iconPosition == 'before' &&
                    <i className={iconClass.join(' ')}></i>
				}
				{message}
				{children}
				{icon && iconPosition == 'after' &&
                    <i className={iconClass.join(' ')}></i>
				}
			</a>
			{forceSpaceAfter && ' '}
		</>
	);
}
export default React.memo(Link);