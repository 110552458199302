import React, { useEffect, useState } from 'react';
import ChangeFilterStatusMode from './ChangeFilterStatusMode';
import Btn from '../../../../Components/Btn';
import { onSiteContext } from "../context/index";
import { Trans, useTranslation } from 'react-i18next';
export default function FilterListAction({
	filterStatusMode,
	setFilterStatusMode,
	filterTestMode,
	setFilterTestMode,
	statusArray,
	setstatusArray
}) {
	const [t, i18n] = useTranslation('common');
	const {
		isSimpleModeFilterStatus,
		setisSimpleModeFilterStatus,
	} = onSiteContext();

	useEffect(() => {
		const testStatusMode = ChangeFilterStatusMode(statusArray)
		let status = testStatusMode && testStatusMode.status;
		if (testStatusMode && testStatusMode.mode === 1) {
			setisSimpleModeFilterStatus(true);
		} else {
			setisSimpleModeFilterStatus(false);
		}
		if (!status) {
			setisSimpleModeFilterStatus(false);
		}
		setFilterStatusMode(status ? [status] : []);
	}, []);

	const canRemoveFilter = (type, status) => {
		let filter_lenght = statusArray.length;

		if (filterTestMode) {
			filter_lenght++;
		}

		if (filter_lenght > 1) {
			return true;
		}

		if (type == "test") {
			return !filterTestMode;
		}

		return !statusArray.includes(status);
	}

	const updateTestMode = () => {
		if (!canRemoveFilter("test")) return;

		const newState = !filterTestMode;
		setFilterTestMode(newState);
		sessionStorage.setItem("onSiteTestFilter", newState);
	}

	const updateStatusArray = (filter) => {
		if (!canRemoveFilter("status", filter)) return;
		let actualStatusArray = [...statusArray];
		if (actualStatusArray.includes(filter)) {
			actualStatusArray = actualStatusArray.filter(status => status !== filter);
			setstatusArray(actualStatusArray);
			const testStatusMode = ChangeFilterStatusMode(actualStatusArray)
			let status = testStatusMode && testStatusMode.status;
			if (testStatusMode && testStatusMode.mode === 1) {
				setisSimpleModeFilterStatus(true);
			} else {
				setisSimpleModeFilterStatus(false);
			}
			if (!status) {
				setisSimpleModeFilterStatus(false);
			}
			setFilterStatusMode(status ? [status] : []);
			sessionStorage.setItem("onSiteStatusFilter", JSON.stringify(actualStatusArray));
		} else {
			actualStatusArray.push(filter);
			setstatusArray(actualStatusArray);
			const testStatusMode = ChangeFilterStatusMode(actualStatusArray)
			let status = testStatusMode && testStatusMode.status;
			if (testStatusMode && testStatusMode.mode === 1) {
				setisSimpleModeFilterStatus(true);
			} else {
				setisSimpleModeFilterStatus(false);
			}
			if (!status) {
				setisSimpleModeFilterStatus(false);
			}
			setFilterStatusMode(status ? [status] : []);
			sessionStorage.setItem("onSiteStatusFilter", JSON.stringify(actualStatusArray));
		}
	}
	return (
		<div className="btn_switch">
			<Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Draft')}
				size="xs"
				light={true}
				className={filterTestMode == true ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateTestMode()}
			/>
			<Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Ongoing')}
				size="xs"
				light={true}
				className={statusArray.includes('onGoing') ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateStatusArray('onGoing')}
			/>
			<Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Scheduled')}
				size="xs"
				light={true}
				className={statusArray.includes('scheduled') ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateStatusArray('scheduled')}
			/>
			<Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Stopped')}
				size="xs"
				light={true}
				className={statusArray.includes('stopped') ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateStatusArray('stopped')}
			/>
			<Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Past')}
				size="xs"
				light={true}
				className={statusArray.includes('past') ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateStatusArray('past')}
			/>
		</div>
	)
}
