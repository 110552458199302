import React, {Fragment, useEffect, useState} from 'react';
import {react2angular} from 'react2angular';
import TriggerAccountServices from '../../../Services/TriggerAccountServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import {groupBy} from 'lodash';
import Checkbox from '../../../Components/Checkbox';
import Btn from '../../../Components/Btn';
import SystemServices from '../../../Services/SystemServices';

export default function ConfigTriggersContainer(props) {

	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const systemServices = new SystemServices(props.$rootScope);

	const triggerService = new TriggerAccountServices(props.$http);

	const [accountTriggers, setAccountTriggers] = useState([]);

	const [categories, setCategories] = useState([]);


	const getAccountTriggers = (callbackSuccess) => {
		triggerService.getAccountTrigger(accountId, callbackSuccess, err => console.log(err), true, 'OnSite');
	};
	const postAccountTriggers = (arrayTriggerId, callbackSuccess) => {
		triggerService.postAccountTrigger(accountId, arrayTriggerId, callbackSuccess, err => systemServices.showError('An error occured'));
	};
	const enTriggerCat = {
		'TIME': { 			Label: 'Specific time period', 		Order: 1, 	Icon: 'time' },
		'BEHAVIOR': { 		Label: 'Visitor behavior', 			Order: 2, 	Icon: 'behavior' },
		'PAGES': { 			Label: 'Visited pages', 			Order: 3, 	Icon: 'pages' },
		'VISITORCHAR': { 	Label: 'Visitor characteristics', 	Order: 4, 	Icon: 'visitor' },
		'JOURNEY': { 		Label: 'Cross-session journey', 	Order: 5, 	Icon: 'journey' },
		'PRODUCT': { 		Label: 'Product characteristics', 	Order: 6, 	Icon: 'product' },
		'CART': { 			Label: 'Cart characteristics', 		Order: 7, 	Icon: 'cart' },
		'PREDICTIVE': { 	Label: 'Predictive targeting', 		Order: 8, 	Icon: 'predictive' },
		'ACQUI': { 			Label: 'Acquisition', 				Order: 9, 	Icon: 'acquisition' },
		'BUSINESSCHAR': { 	Label: 'Business characteristics', 	Order: 10,	Icon: 'buisiness' },
		'CUSTOM': { 		Label: 'Custom trigger', 			Order: 11,	Icon: 'custom' },
		'Hidden': { 		Label: 'Hidden', 					Order: 0, 	Icon: '' },
	};
	const frTriggerCat = {
		'TIME': { 			Label: 'Période spécifique', 		Order: 1, 	Icon: 'time' },
		'BEHAVIOR': { 		Label: 'Comportement des visiteurs', 			Order: 2, 	Icon: 'behavior' },
		'PAGES': { 			Label: 'Pages visitées', 			Order: 3, 	Icon: 'pages' },
		'VISITORCHAR': { 	Label: 'Caractéristiques des visiteurs', 	Order: 4, 	Icon: 'visitor' },
		'JOURNEY': { 		Label: 'Parcours intersessions', 	Order: 5, 	Icon: 'journey' },
		'PRODUCT': { 		Label: 'Caractéristiques du produit', 	Order: 6, 	Icon: 'product' },
		'CART': { 			Label: 'Caractéristiques du panier', 		Order: 7, 	Icon: 'cart' },
		'PREDICTIVE': { 	Label: 'Ciblage prédictif', 		Order: 8, 	Icon: 'predictive' },
		'ACQUI': { 			Label: 'Acquisition', 				Order: 9, 	Icon: 'acquisition' },
		'BUSINESSCHAR': { 	Label: 'Caractéristiques de l\'activité', 	Order: 10,	Icon: 'buisiness' },
		'CUSTOM': { 		Label: 'Déclencheur personnalisé', 			Order: 11,	Icon: 'custom' },
		'Hidden': { 		Label: 'Cachés', 					Order: 0, 	Icon: '' },
	};

	let triggerCategories = {	};
	const i18nL= localStorage.getItem('i18nL');
	i18nL.includes('fr') ? triggerCategories = frTriggerCat : triggerCategories = enTriggerCat ;
	const getTriggersWithCategories = (triggerSet) => {
		return triggerSet.map(t => ({
			...t,
			config: {
				type: t.config.type,
				operators: t.config.operators,
				needConfig: t.config.needConfig,
				saaS: t.config.saaS,
				separator: t.config.separator,
				trackingMode: t.config.trackingMode,
				category: triggerCategories[t.config.category].Label,
				categoryOrder: triggerCategories[t.config.category].Order,
				categoryIcon: triggerCategories[t.config.category].Icon
			}
		}));
	};

	const groupByTriggersCategory = (triggers) => {
		const categoriesGrouped = groupBy(triggers, d => [d.config.category, d.config.categoryOrder, d.config.categoryIcon]);
		return Object.entries(categoriesGrouped)
			.map(entry => {
				const [label, order, icon] = entry[0].split(',');
				return {label, order, icon, triggers: entry[1]};
			})
			.filter(t => t.order > 0)
			.sort((t1, t2) => t1.order - t2.order);
	};

	useEffect(() => {
		getAccountTriggers(data => {
			const triggerCategory = getTriggersWithCategories(data);
			setAccountTriggers(triggerCategory);
			setCategories(groupByTriggersCategory(triggerCategory));
		});
	}, []);

	const changeIsActiveTrigger = (trigger) => {
		let prevState = [...accountTriggers];
		const index = prevState.findIndex(e => e.id === trigger.id);
		prevState[index].isActive = !trigger.isActive;
		setAccountTriggers(prevState);
	};

	const submitChanges = () => {
		const isActiveTrigger = accountTriggers.filter(trigger => trigger.isActive);
		const arrayTriggerId = isActiveTrigger.map(e => e.id);
		postAccountTriggers(arrayTriggerId, (e) => systemServices.showSuccess('changes saved'));
	};

	const handleChangeForCategory = (category) => {
		let prevState = [...accountTriggers];
		const isTriggersAllSelected = category.triggers.every(trigger => trigger.isActive);

		for (let i = 0; i < prevState.length; i++) {
			if (isTriggersAllSelected){
				if (prevState[i].config.category === category.label){
					prevState[i].isActive = false;
				}

			} else {
				if (prevState[i].config.category === category.label){
					prevState[i].isActive = true;
				}
			}
		}

		setAccountTriggers(prevState);
	};

	const catIsActive = (cat) => {
		const triggers = cat.triggers;
		if (triggers) {
			return triggers.every(t => t.isActive === true);
		}
		return false;
	};

	return (
		<div className="page_body">
			<div className="trigger_store_ext_wrapper">
				<div className="trigger_store_page_title flex">
					<div className="trigger_store_page_title_txt flex_item_full">Select active triggers for this account</div>
					<div className="flex_item_fix ml_20">
						<Btn color="primary" onClick={() => submitChanges()}>
							Save
						</Btn>
					</div>
				</div>
				<div className="trigger_store">
					{categories.map((cat, key) => (
						<div className="trigger_store_category" key={key}>
							<div className="trigger_store_category_name flex">
								<div className="flex_item_full">{cat.label}</div>
								<div className="flex_item_fix ml_20 trigger_store_category_info">
									All category triggers
								</div>
								<div className="flex_item_fix ml_10">
									<Checkbox forcedChecked={catIsActive(cat)} onChange={() => handleChangeForCategory(cat)}></Checkbox>
								</div>
							</div>
							<ul className="trigger_store_list">
								{accountTriggers && accountTriggers.map((trigger, i) =>
									trigger.config.category === cat.label &&
										<li key={i}
											className="trigger_store_item flex"
											onClick={() => changeIsActiveTrigger(trigger)}>
											<div className="flex_item_fix">
												<span className={'trigger_icon icon_' + cat.icon}></span>
											</div>
											<div className="flex_item_full">
												<div className="trigger_store_item_name">{trigger.data.en.label}</div>
											</div>
											<div>
												<Checkbox forcedChecked={trigger.isActive}></Checkbox>
											</div>
										</li>
								)}
							</ul>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

angular
	.module('beyableSaasApp.ConfigTriggers', ['beyableSaasApp.Services'])
	.component('configtriggers', react2angular(ConfigTriggersContainer, [],
		['$rootScope', '$scope', '$http', '$routeParams', '$timeout']));