import React from 'react';
import UploadVideosContainer from '../../../UploadVideos/UploadVideosContainer';

export default function VideoBeyable({
	label,
	data,
	onChange,
	$rootScope,
	$routeParams,
	$http,
	authServices,
	$timeout
}) {

	const handleChangeUrlVideo = (evt) =>{
		onChange(evt);
	};
	
	return (
		<UploadVideosContainer
			data={data}
			$rootScope={$rootScope}
			$routeParams={$routeParams}
			$http={$http}
			authServices={authServices}
			$timeout={$timeout}
			handleChangeUrlVideo={handleChangeUrlVideo}
			label={label}
		/>
	);
}
