import React from 'react';
import CampaignItem from './CampaignItem';
import Btn from '../../../Components/Btn';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
export default function OrchestrationGroupItem({ group, loadDataOfOrchestrationgroup, deleteAnOrchestration }) {
	const idTooltipDelete = uuidv4();
	const idTooltipEdit = uuidv4();
	return (
		<div className="orchestration_list_item flex flex_v">
			<ReactTooltip id={idTooltipDelete} backgroundColor='black' effect='solid' place="bottom" />
			<ReactTooltip id={idTooltipEdit} backgroundColor='black' effect='solid' place="bottom" />

			<div className="orchestration_list_item_head flex_item_fix flex" onClick={loadDataOfOrchestrationgroup}>
				<div className="flex_item_fix">
					<div className="orchestration_picto">
						<i className="fas fa-folder"></i>
					</div>
				</div>
				<div className="flex_item_full">
					<div className="orchestration_list_item_name">{group.group.name}</div>
					<div className="grey_1 s_12">
						{group.campaigns.length}&nbsp;
						{group.campaigns.length <= 1 ? 'campaign' : 'campaigns'}
					</div>
				</div>
				<div className="flex_item_fix ml_20 flex_item_align_start">
					<a className="icon_btn s"
						onClick={(ev) => {ev.stopPropagation(); deleteAnOrchestration(group);}}
						data-for={idTooltipDelete}
						data-tip={'Delete group'}>
						<i className="fas fa-trash"></i>
					</a>
				</div>
			</div>
			<div className="orchestration_list_item_body flex_item_full">
				{group.group.description &&
                    <div className="grey_1 s_13">{group.group.description}</div>
				}
			</div>
			<div className="orchestration_list_item_footer flex_item_fix">
				<Btn message="View"
					size="s"
					style="outline"
					onClickFunction={loadDataOfOrchestrationgroup}
				/>
			</div>
		</div>
	);
}
