import React, { } from 'react';
import useFeature from '../../../../flags/useFeature';
import {useTranslation} from 'react-i18next';

export default function InspirationModule({
	module,
	showFormat = true,
	showObjective = false,
	$routeParams,
	$rootScope
}) {

	const { features } = useFeature();
	const [t] = useTranslation('common');

	const getModuleURL = (module, url) => {
		if (module.available === false) return;

		if (!url || url == '') {
			let editor = module.editor == 'builder' ? 'builder' : 'module';
			url = '/Campaigns/Editor?' + editor + '=' + module.id;
		}

		const sep = url.includes('?') ? '&' : '?';

		if( $routeParams.ka ){
			url += sep + 'ka=' + $routeParams.ka;
			url += sep + 'ku=' + $routeParams.ku;
		}
		return url;
	};

	const handleClick = (ev) => {
		if (module.available === false) return;
		
		$rootScope.$broadcast('event:changeStateOfInspirationPanel', false);
	};

	const moduleUrl = getModuleURL(module, module.url);

	if (module.feature && !features.includes(module.feature)) return '';

	return (
		<a href={moduleUrl}
			onClick={(e) => { handleClick(e); }}
			className="inspi_item flex flex_align_start">
			<div className="inspi_item_thumb flex_item_fix">
				{module.img &&
					<img src={'/Assets/' + module.img} />
				}
			</div>
			<div className="inspi_item_content flex_item_full">
				<div className="flex flex_align_start">
					<div className="flex_item_full">
						<div className="inspi_item_title">{t('modules.'+module.id+'.label')}</div>
						{showFormat && module.format &&
							<div className="inspi_item_format grey_2">{t('formats.'+module.format.id+'.label')}</div>
						}
						{showObjective && module.obj &&
							<div className="inspi_item_obj grey_2">{t('objectives.'+module.obj.id+'.label')}</div>
						}
						<div className="inspi_item_desc grey_1">{t('modules.'+module.id+'.description')}</div>
					</div>
					{module.available === false &&
						<div className="flex_item_fix">
							<span className="inspi_item_tag">Available soon</span>
						</div>
					}
				</div>
			</div>
			{module.available !== false &&
				<div className="inspi_item_arrow flex_item_fix flex_item_align_center">
					<i className="icon_16 icon_right_arrow flex_item_fix"></i>
				</div>
			}
		</a>
	);
}