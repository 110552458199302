import React, { useState, useEffect, useContext } from 'react';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';

import Btn from './../../../../Components/Btn';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CreateBusinessOperationContainer.css';
import '../../../../Components/react-datepicker-custom.css';
import TinyLoader from './../../../../Components/TinyLoader';
import { Can, check } from '../../../../Components/Can';
import OperationLabelSelector from './OperationLabelSelector';

export default function CreateBusinessOperationContainer({ accountId }) {
	const {
		isexistant,
		isFirstRenderOfExistantOp,
		setStepBusinessOperationCreate,
		operation,
		setOperation,
		devices,
		setDevices,
		setDeviceSelected,
		userSimplifyRole,
		planAccount
	} = useContext(CreateBusinessOperationContext);
	const [nameLength, setNameLength] = useState(operation.operation.name.length);
	const [isClickOnChangeDate, setIsClickOnChangeDate] = useState(false);

	const changeAccountId = () => {
		let operationNew = { ...operation };
		operationNew.operation.accountId = accountId;
	};
	useEffect(() => {
		changeAccountId();
	}, []);


	const addDevice = (e) => {
		let arrayOfDevices = [...devices];
		if (devices.includes(e)) {
			const newArray = arrayOfDevices.filter(x => x !== e);
			if (newArray.length > 0) {
				setDevices([...newArray]);
				if (newArray[0] === 'mobile') {
					setDeviceSelected('Mobile');
				} else {
					setDeviceSelected('Desktop');
				}
				return;
			}
		}
		setDevices([...arrayOfDevices, e]);
	};
	useEffect(() => {
		let operationNew = { ...operation };
		operationNew.operation.extensions.devices = devices;
	}, [devices]);

	// Function to Add day + 24 h
	const addDays = (theDate, days) => {
		let date = new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
		return date;
	};

	// To set dates if is not an existant operation create a startDate and endDate +1 day of startDate
	useEffect(() => {
		if (!isexistant && operation.operation.startDate == '') {
			const startDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
			const endDate = moment.utc(addDays(new Date(), 1).setHours(23, 59, 59, 999));
			let operationNew = { ...operation };
			operationNew.operation.startDate = startDate;
			operationNew.operation.endDate = endDate;
			setOperation(operationNew);
		}
	}, [isexistant]);

	// Check if the endDate is > to startDate 
	const checkEndDate = (date) => {
		if (!isFirstRenderOfExistantOp) {
			if (date < moment.utc((operation.operation.startDate))) {
				let newDate = moment.utc((operation.operation.startDate)).toDate();
				newDate.setMinutes(newDate.getMinutes() + 30);
				changeEndDates(newDate);
			}
			else {
				return true;
			}
		} else {
			return true;
		}
	};
	//Change the StartDate
	const changeStartDates = (e) => {
		let newDateFormat = moment.utc(e).format('YYYY-MM-DDTHH:mm:ss');
		let operationNew = { ...operation };
		operationNew.operation.startDate = newDateFormat;
		setOperation(operationNew);
		setIsClickOnChangeDate(!isClickOnChangeDate);
	};

	// Change the EndDate
	const changeEndDates = (e) => {
		if (checkEndDate(e) == true) {
			let newDateFormat = moment.utc(e).format('YYYY-MM-DDTHH:mm:ss');
			let operationNew = { ...operation };
			operationNew.operation.endDate = newDateFormat;
			setOperation(operationNew);
		} else {
			checkEndDate(moment.utc(operation.operation.endDate));
		}
	};
	useEffect(() => {
		changeEndDates(moment.utc((operation.operation.endDate)));
	}, [isClickOnChangeDate]);

	const handleChangeForm = (e) => {
		e.persist();
		if (e.target.name === 'cta') {
			let prevState = { ...operation };
			prevState.operation.extensions.cta = e.target.value;
			setOperation(prevState);
		} else {
			let prevState = { ...operation };
			prevState.operation[e.target.name] = e.target.value;
			setOperation(prevState);
		}
	};
	useEffect(() => {
		setNameLength(operation.operation.name.length);
	}, [operation]);
	const [readOnly, setReadOnly] = useState(true);
	useEffect(() => {
		if (planAccount && userSimplifyRole) {
			const value = check(planAccount, userSimplifyRole, 'simplifyOperation:canEditCampaignInformations', null);
			if (value) {
				setReadOnly(!value);
			} else {
				setReadOnly(true);
			}
		}
	}, [planAccount, userSimplifyRole]);

	return (
		<div className="new-module">
			{isFirstRenderOfExistantOp ?
				<TinyLoader />
				:
				<>
					{isexistant ? <h1 className="simplify_title_create">Edit  <span> : {operation.operation.name}</span></h1> : <h1 className="simplify_title_create">Create</h1>}
					<div className="simplify_subtitles">
						<p>Indicate here general details about the campaign.</p>
					</div>
					<form name="creationBusinessOp">
						<section className="step_row">
							<p className="title">Title</p>
							<label className="label-top">
								<input
									type="text"
									placeholder="140 characters max"
									maxLength="140"
									name="name"
									required
									value={operation.operation.name}
									readOnly={readOnly}
									onChange={!readOnly ? (e) => handleChangeForm(e) : null} />
								<p className="lengthOfTitle"> {nameLength} / 140</p>
							</label>
						</section>
						<section className="step_row">
							<p className="title">Description</p>
							<label className="label-textarea label-top">
								<textarea
									className="disabled"
									name="description"
									placeholder="Description"
									id=""
									value={operation.operation.description}
									readOnly={readOnly}
									onChange={!readOnly ? (e) => handleChangeForm(e) : null}
								/>
							</label>
						</section>

						<section className="step_row">
							<p className="title">Activate the campaign</p>
							<div className="datePickerBOp">
								<div>
									<span>From</span>
									<DatePicker
										selected={operation.operation.startDate && moment(moment.utc(operation.operation.startDate).toDate()).local().toDate()}
										minDate={new Date()}
										onChange={!readOnly ? date => { changeStartDates(date); } : null}
										timeInputLabel="Time:"
										dateFormat="dd/MM/yyyy - HH:mm "
										showTimeSelect
										timeIntervals={30}
										readOnly={readOnly}
									/>
								</div>
								<div>
									<span>to</span>
									<DatePicker
										selected={operation.operation.startDate && moment(moment.utc(operation.operation.endDate).toDate()).local().toDate()}
										onChange={!readOnly ? date => changeEndDates(date) : null}
										minDate={operation.operation.startDate && moment(operation.operation.startDate).add(30, 'minutes').toDate()}
										timeInputLabel="Time:"
										dateFormat="dd/MM/yyyy - HH:mm "
										showTimeSelect
										timeIntervals={30}
										readOnly={readOnly}
									/>
								</div>
							</div>
						</section>

						<section className="step_row">
							<p className="title">Labels</p>
							<OperationLabelSelector accountId={accountId} />
						</section>
						<section className="step_row">
							<p className="title">Devices</p>
							<article className="radio_box_inner ">
								<label className={devices && devices.includes('mobile') ?
									readOnly ? 'radio_box checked disabled' : 'radio_box checked'
									: readOnly ? 'radio_box disabled ' : 'radio_box'} >
									<input type="checkbox" onChange={!readOnly ? (e) => addDevice('mobile') : null} />
									<span><i className="fa fa-check"></i></span>
									<i className="fa fa-mobile-alt"></i>
									<p>Mobile</p>
								</label>
								<label className={devices && devices.includes('desktop') ?
									readOnly ? 'radio_box checked disabled' : 'radio_box checked '
									: readOnly ? 'radio_box disabled' : 'radio_box'} >
									<input type="checkbox" onChange={!readOnly ? (e) => addDevice('desktop') : null} />
									<span><i className="fa fa-check"></i></span>
									<i className="fa fa-desktop"></i>
									<p>Desktop</p>
								</label>
							</article>
						</section>
					</form>

					<Btn
						onClickFunction={(e) => {
							setStepBusinessOperationCreate(3);
						}}
						message="Next step"
						icon="fas fa-chevron-right"
						iconPosition="after"
						size="l"
					/>

				</>}
		</div>
	);
}
