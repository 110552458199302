import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import Card from '../Card/Card';
import CardModal from '../CardModal/CardModal';
import WorldChartContainer from '../Geolocalisation/WorldChartContainer';
import { cardFetchOptions, FORMAT } from '../../config';
import { isEmpty } from 'lodash';

export const SessionsCardContainer = ({
	navFilter,
	service,
	styles,
	handleClick,
	dimensions,
	selectedTenant,
	ComparisonSelected,
	metricTypeSessionsSelected,
	totalsPeriod1,
	totalsPeriode2,
	chips
}) => {

	const [cardsData, setCardsData] = useState({});
	const [enlarge, setEnlarge] = useState(null);
	const [enlargedCardsData, setEnlargedCardsData] = useState({});
	const monday = moment().startOf('isoWeek').format(FORMAT);
	const formatToday = moment().format(FORMAT);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!isEmpty(navFilter)) {
				setEnlargedCardsData({});
				getCardsData({});
				setEnlarge(false);
			}}, 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [navFilter, selectedTenant, ComparisonSelected, metricTypeSessionsSelected]);

	useEffect(() => {
		if (!isEmpty(cardsData) && !isEmpty(navFilter)) {
			getCardsData(cardsData);
		}
	}, [dimensions]);

	const getCardsData = (data) => {
		const fetchOptions = { ...cardFetchOptions };
		fetchOptions.fromDate = navFilter.fromDate || monday;
		fetchOptions.toDate = navFilter.toDate || formatToday;
		fetchOptions.filters = navFilter.filters;
		fetchOptions.metrics = metricTypeSessionsSelected.key ===
			'sessionsAndPagesViews' ? ['NumberOfSessions'] :
			[...metricTypeSessionsSelected.value, 'NumberOfSessions'];

		if (ComparisonSelected) {
			delete fetchOptions.toDate;
			delete fetchOptions.fromDate;
			delete fetchOptions.timeGranularity;

			fetchOptions.period1FromDate = moment(navFilter.fromDate).format(FORMAT);
			fetchOptions.period1ToDate = moment(navFilter.toDate).format(FORMAT);
			fetchOptions.period2FromDate = moment((navFilter.fromDateToCompare)).format(FORMAT);
			fetchOptions.period2ToDate = moment(navFilter.toDateToCompare).format(FORMAT);
		}
		if (data === {}) {
			const cardDataKeyArray = Object.keys(cardsData);
			if (cardDataKeyArray.length >= 0) {
				setCardsData({});
			}
		}

		const options = dimensions.filter(e => e.checked === true && !data[e.key]).filter(x=>x.key !== 'geolocWorld').reduce((acc, item) => {
			return [...acc, { ...fetchOptions, dimensions: [item.key] }];
		}, []);

		const successCb = (name) => (data) => {
			setCardsData((previousData) => {
				if (ComparisonSelected) {
					return {
						...previousData, [name]: {
							data: data && data.Items || [],
							totalCount: data.TotalCount || 0,
							loading: false,
							isError: false
						}
					};
				} else {
					return {
						...previousData, [name]: {
							data: data && data.Items || [],
							totalCount: data.TotalCount,
							loading: false,
							isError: false
						}
					};

				}
			});
		};

		const errorCb = (error, name) => {
			console.log('--Error--', error);
			setCardsData((previousData) => {
				return { ...previousData, [name]: { data: [], loading: false, isError: true } };
			});
		};

		Promise.all(options.map(async (option) => {

			setCardsData((previousData) => {
				return {
					...previousData, [option.dimensions[0]]: {

						loading: true,
					}
				};
			});

			if (!ComparisonSelected) {
				await service.getSessions(option, successCb(option.dimensions[0]), err => errorCb(err, option.dimensions[0]));
			} else {

				await service.getSessionsCompare(option, successCb(option.dimensions[0]), err => errorCb(err, option.dimensions[0]));
			}
		}));

	};

	function fetchMore(dimensionKey, search) {
		const isNewSearch = (search !== undefined && search !== null);
		const cardData = enlargedCardsData && enlargedCardsData[dimensionKey] ? enlargedCardsData[dimensionKey] : { offset: 0 };
		const limit = 15;
		const fetchOptions = { ...cardFetchOptions };
		fetchOptions.fromDate = navFilter.fromDate || monday;
		fetchOptions.toDate = navFilter.toDate || formatToday;
		fetchOptions.filters = navFilter.filters;
		fetchOptions.dimensions = [dimensionKey];
		fetchOptions.limit = limit;
		fetchOptions.offset = isNewSearch ? 0 : (cardData.offset || 0);
		fetchOptions.search = isNewSearch ? search : cardData.search;
		fetchOptions.metrics = metricTypeSessionsSelected.key ===
			'sessionsAndPagesViews' ? ['NumberOfSessions'] :
			[...metricTypeSessionsSelected.value, 'NumberOfSessions'];
		if (ComparisonSelected) {
			delete fetchOptions.toDate;
			delete fetchOptions.fromDate;
			delete fetchOptions.timeGranularity;

			fetchOptions.period1FromDate = moment(navFilter.fromDate).format(FORMAT);
			fetchOptions.period1ToDate = moment(navFilter.toDate).format(FORMAT);
			fetchOptions.period2FromDate = moment((navFilter.fromDateToCompare)).format(FORMAT);
			fetchOptions.period2ToDate = moment(navFilter.toDateToCompare).format(FORMAT);

			// console.log("fetchOptions", fetchOptions);
		}
		const successCb = (name) => (data) => {
			setEnlargedCardsData((previousData) => {
				const items = (data && data.Items || []);
				return {
					...previousData, [name]: {
						data: isNewSearch ? items : [...(previousData[name] && previousData[name].data || []), ...items],
						totalCount: data.TotalCount,
						loading: false,
						isError: false,
						offset: fetchOptions.offset + limit,
						search: fetchOptions.search
					}
				};
			});
		};

		const errorCb = (error, name) => {
			console.log('--Error--', error);
			setEnlargedCardsData((previousData) => {
				return { ...previousData, [name]: { loading: false, isError: true } };
			});
		};
		if (!ComparisonSelected) {
			service.getSessions(fetchOptions, successCb(dimensionKey), err => errorCb(err, dimensionKey));
		} else {

			service.getSessionsCompare(fetchOptions, successCb(dimensionKey), err => errorCb(err, dimensionKey));
		}

		// service.getSessions(fetchOptions, successCb(dimensionKey), err => errorCb(err, dimensionKey))
	}

	function onEnlarge(dimension) {
		setEnlarge(dimension);
		if (enlargedCardsData[dimension.key]) {
			return;
		}
		fetchMore(dimension.key);
	}

	function handleClickFromEnlarged(e, t) {
		setEnlarge(false);
		handleClick(e, t);
	}

	function onViewMore(dimensionKey) {
		setEnlargedCardsData((previousData) => {
			return {
				...previousData, [dimensionKey]: {
					...previousData[dimensionKey], loading: true
				}
			};
		});

		fetchMore(dimensionKey);
	}

	function onSearch(dimensionKey, value) {
		setEnlargedCardsData((previousData) => {
			return {
				...previousData, [dimensionKey]: {
					...previousData[dimensionKey], loading: true
				}
			};
		});
		fetchMore(dimensionKey, { dimension: dimensionKey, value: value });
	}

	function onCloseModal() {
		setEnlarge(false);
	}

	return (
		<div className={enlarge ? styles.cardContainer : styles.cardContainerColumns}>
			{!ComparisonSelected && <>
				{enlarge ?
					<CardModal
						dimensionKey={enlarge.key}
						title={enlarge.label}
						dataCard={enlargedCardsData[enlarge.key]}
						handleClick={handleClickFromEnlarged}
						handleClose={onCloseModal}
						handleViewMore={onViewMore}
						handleSearch={onSearch}
						metricTypeSessionsSelected={metricTypeSessionsSelected.key}
						ComparisonSelected={ComparisonSelected}
						totalsPeriod1={totalsPeriod1}
						totalsPeriode2={totalsPeriode2}
						chips={chips}
					/>
					:
					<>
						{dimensions.filter(e => e.checked === true).map((dimension) => (
							<Fragment key={dimension.label}>
								{dimension.key === 'geolocWorld' ?
									<WorldChartContainer
										navFilter={navFilter}
										service={service}
										dimensions={dimensions}
										selectedTenant={selectedTenant}
									/>
									:
									<Card
										dimensionKey={dimension.key}
										title={dimension.label}
										dataCard={cardsData[dimension.key]}
										handleClick={handleClick}
										handleEnlarge={() => onEnlarge(dimension)}
										isLarge={['LandingPage'].includes(dimension.key) ? true : false}
										metricTypeSessionsSelected={metricTypeSessionsSelected.key}
										totalsPeriod1={totalsPeriod1}
										totalsPeriode2={totalsPeriode2}
										chips={chips}
									/>
								}
							</Fragment>
						))}
					</>
				}
			</>}
			{ComparisonSelected && <>
				{enlarge ?
					<CardModal
						dimensionKey={enlarge.key}
						title={enlarge.label}
						dataCard={enlargedCardsData[enlarge.key]}
						handleClick={handleClickFromEnlarged}
						handleClose={onCloseModal}
						handleViewMore={onViewMore}
						handleSearch={onSearch}
						metricTypeSessionsSelected={metricTypeSessionsSelected.key}
						ComparisonSelected={ComparisonSelected}
						totalsPeriod1={totalsPeriod1}
						totalsPeriode2={totalsPeriode2}
						chips={chips}
					/>
					:
					<>
						{dimensions.filter(e => e.checked === true).map((dimension) => (
							<Card
								ComparisonSelected={ComparisonSelected}
								dimensionKey={dimension.key}
								key={dimension.label}
								title={dimension.label}
								dataCard={cardsData[dimension.key]}
								handleClick={handleClick}
								handleEnlarge={() => onEnlarge(dimension)}
								isLarge={['LandingPage'].includes(dimension.key) ? true : false}
								metricTypeSessionsSelected={metricTypeSessionsSelected.key}
								totalsPeriod1={totalsPeriod1}
								totalsPeriode2={totalsPeriode2}
								chips={chips}
							/>
						))}
					</>
				}
			</>}
		</div>
	);
};
