import HttpServices from './HttpServices';

const scenarioSpecialLabelAccountId = '00000000-0000-0000-0000-000000000000';

export default class LabelServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}

	getLabelsItem(accountId, objectType, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}workspace/${accountId}/item?objectType=${objectType}`, callbackSuccess, callbackError);
	}
	getLabels(accountId, objectType, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}workspace/${accountId}/label?objectType=${objectType}`, callbackSuccess, callbackError);
	}
	postLabel(accountId, label, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}workspace/${accountId}/label/`, label, callbackSuccess, callbackError);
	}
	deleteLabel(accountId, labelId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}workspace/${accountId}/label/${labelId}`, callbackSuccess, callbackError);
	}
	putLabel(accountId, labelId, label, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}workspace/${accountId}/label/${labelId}`, label, callbackSuccess, callbackError);
	}
	getLabelsForObject(accountId, objectId, objectType, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}workspace/${accountId}/item/${objectId}?objectType=${objectType}`, callbackSuccess, callbackError);
	}
	addLabelForObject(accountId, objectId, objectType, labelId, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}workspace/${accountId}/item/${objectId}/label`, { objectType, labelId }, callbackSuccess, callbackError);
	}
	putAllLabelsForObject(accountId, objectId, objectType, labelIds, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}workspace/${accountId}/item/${objectId}`, { objectType, labelIds }, callbackSuccess, callbackError);
	}
	deleteLabelForObject(accountId, objectId, labelId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}workspace/${accountId}/item/${objectId}/label/${labelId}`, callbackSuccess, callbackError);
	}

	getSpecialLabels(accountId, objectType, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}special-workspace/label?accountId=${accountId}&objectType=${objectType}`, callbackSuccess, callbackError);
	}
	getSpecialLabelsItem(accountId, objectType, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}special-workspace/item?accountId=${accountId}&objectType=${objectType}`, callbackSuccess, callbackError);
	}
	getSpecialLabelsForObject(accountId, objectId, objectType, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}special-workspace/item/${objectId}?accountId=${accountId}&objectType=${objectType}`, callbackSuccess, callbackError);
	}
	addSpecialLabelForObject(accountId, objectId, objectType, labelId, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}special-workspace/item/${objectId}/label?accountId=${accountId}`, { objectType, labelId }, callbackSuccess, callbackError);
	}
	putAllSpecialLabelsForObject(accountId, objectId, objectType, labelIds, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}special-workspace/item/${objectId}?accountId=${accountId}`, { objectType, labelIds }, callbackSuccess, callbackError);
	}
	deleteSpecialLabelForObject(accountId, objectId, labelId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}special-workspace/item/${objectId}/label/${labelId}?accountId=${accountId}`, callbackSuccess, callbackError);
	}

	getScenarioSpecialLabels(callbackSuccess, callbackError) {
		this.getSpecialLabels(scenarioSpecialLabelAccountId, 'scenario', callbackSuccess, callbackError);
	}
	getScenarioSpecialLabelsItem(callbackSuccess, callbackError) {
		this.getSpecialLabelsItem(scenarioSpecialLabelAccountId, 'scenario', callbackSuccess, callbackError);
	}
	getScenarioSpecialLabelsForObject(objectId, callbackSuccess, callbackError) {
		this.getSpecialLabelsForObject(scenarioSpecialLabelAccountId, objectId, 'scenario', callbackSuccess, callbackError);
	}
	addScenarioSpecialLabelForObject(objectId, labelId, callbackSuccess, callbackError) {
		this.addSpecialLabelForObject(scenarioSpecialLabelAccountId, objectId, 'scenario', labelId, callbackSuccess, callbackError);
	}
	putAllScenarioSpecialLabelForObject(objectId, labelId, callbackSuccess, callbackError) {
		this.putAllSpecialLabelsForObject(scenarioSpecialLabelAccountId, objectId, 'scenario', [labelId], callbackSuccess, callbackError);
	}
	deleteScenarioSpecialLabelForObject(objectId, labelId, callbackSuccess, callbackError) {
		this.deleteSpecialLabelForObject(scenarioSpecialLabelAccountId, objectId, labelId, callbackSuccess, callbackError);
	}
}
