


export function dateUtils() {

	const addDays = (date, days) => {
		return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
	};

	return {
		addDays
	};
}


export function getNextEndDate(input_string) {
	// input_string : 18:00-Europe/Paris-0:2:3

	function __get_tz_offset(tz) {
		const today = new Date();
		today.setHours(0, 0, 0, 0);

		let date_string;
		try {
			date_string = today.toLocaleString('fr-FR', { timeZone: tz });
		} catch (error) { /* empty */ }

		if (!date_string) return false;

		const date = __local_string_to_date(date_string);
		let rest = today - date;
		const diff = {};

		diff.milli = rest;
		rest = Math.floor(rest / 1000);
		diff.sec = rest % 60;
		rest = Math.floor((rest - diff.sec) / 60);
		diff.min = rest % 60, 0;
		rest = Math.floor((rest - diff.min) / 60);
		diff.hour = rest;

		return diff;
	}

	function __local_string_to_date(date_str) {
		const sp = date_str.split(' ');
		const datePart = sp[0].split('/');
		const timePart = sp[1].split(':');
		const date = new Date(datePart[2], parseInt(datePart[1], 10) - 1, datePart[0], timePart[0], timePart[1], timePart[2]);
		return new Date(date);
	}

	function __next_day_of_week(day_of_week, hour, min) {
		day_of_week = parseInt(day_of_week, 10);
		const result = new Date(
			now.getFullYear(),
			now.getMonth(),
			now.getDate() + (7 + day_of_week - now.getDay()) % 7,
			hour,
			min
		);
		if (result < now) result.setDate(result.getDate() + 7);
		return result;
	}

	function __get_modulo(value, mod) {
		return ((value % mod) + mod) % mod;
	}

	const now = new Date();
	const sp = input_string.split(/:|-/);
	let hour = parseInt(sp.shift(), 10);
	let min = parseInt(sp.shift(), 10);
	const tz = sp.shift();
	const days_of_week = sp;
	const tz_offset = __get_tz_offset(tz);
	if (!tz_offset) {
		return now;
	}

	const all_min = min + tz_offset.min;
	min = __get_modulo(all_min, 60);

	const all_hour = hour + tz_offset.hour + Math.floor(all_min / 60);
	hour = __get_modulo(all_hour, 24);

	const day_offset = Math.floor(all_hour / 24);
	let end_date = null;
	if (days_of_week.length) {
		for (let i = 0; i < days_of_week.length; i++) {
			const d = parseInt(days_of_week[i], 10) + day_offset;
			const tmp_end = __next_day_of_week(d, hour, min);
			if (tmp_end > now && (!end_date || tmp_end < end_date)) {
				end_date = tmp_end;
			}
		}
	} else {
		end_date = new Date(now.getTime());
		end_date.setHours(hour, min, 0, 0);
		if (end_date < now) end_date.setDate(end_date.getDate() + 1);
	}
	return end_date;
}
