import React, { useState } from 'react';
import './ABToolbar.css';
import { CampaignsContext } from '../context';
import { intToLetterVariation } from '../util';

export default function ABToolbar() {
	const {
		step,
		variations,
		currentVariationId,
		handleChangeVariation,
		isABTestingActive,
		handleDeleteVariation,
		editorMode
	} = CampaignsContext();
		

	const handleChangeCurrentVariation = (id) => {
		handleChangeVariation(id);
	};

	const original = (variations || []).find(x => x?.IsOriginalVariation);

	return (step === 1 && original && isABTestingActive && editorMode !== 'abTest') ? (
		<div className="ab_toolbar">
			<ul className="ab_toolbar_tabs">
				<li className="pos_rel">
					<a onClick={() => handleChangeCurrentVariation(original.UId)}
						className={currentVariationId === original?.UId ? 'active' : ''}>
                        Original (A)
					</a>
				</li>
				{
					variations.filter(x => !x.IsOriginalVariation).map((item, i) =>
						<li key={i}>
							<a onClick={() => handleChangeCurrentVariation(item.UId)}
								className={currentVariationId === item.UId ? 'active' : ''}>
								<div className="flex flex_justify_around">
									{'Variation ' + intToLetterVariation(i + 1)}
									{
										<div className="ab_toolbar_delete_tab" onClick={(e) =>{ e.stopPropagation(); handleDeleteVariation(item.UId); } }>
											<i className="fas fa-times"></i>
										</div>
									}
								</div>
							</a>
						</li>
					)
				}
			</ul>
		</div>
	) : null;
}