import React, { useEffect, useState } from 'react';
import SelectDropdown from '../../../Components/SelectDropdown';
import SectionMessage from '../../../Components/SectionMessage';
import { useWysiwygEditorContext } from '../context/WysiwygEditorContext';

export default function FontSelector({
	property,
	data,
	onChange,
	label,
	fullWidth
}) {

	const { fontData } = useWysiwygEditorContext();
	const [fontForSelect, setFontForSelect] = useState([]);
	const [isWebsiteFont, setIsWebsiteFont] = useState(false);

	const getFontFamily = (font) => {
		let res = `"${font.name}", ${font.fallback}`;
		return res;
	};

	const handelChange = (value) => {
		const v = new String(value);
		v.isFont = true;
		onChange(v);
	};

	useEffect(() => {
		const defaultFonts = [
			{label: 'Arial', value: 'arial,helvetica,sans-serif', style: {'fontFamily': 'arial,helvetica,sans-serif'}},
			{label: 'Helvetica', value: 'helvetica,arial,sans-serif', style: {'fontFamily': 'helvetica,arial,sans-serif'}},
			{label: 'Verdana', value: 'verdana,helvetica,sans-serif', style: {'fontFamily': 'verdana,helvetica,sans-serif'}},
			{label: 'Courier', value: 'courier new,courier,monospace', style: {'fontFamily': 'courier new,courier,monospace'}},
		];
		const beyableFonts = fontData.map(f => {
			return {
				label: f.displayName,
				value: getFontFamily(f),
				style: {'fontFamily': getFontFamily(f)}
			};
		});
		const inheritOption = {
			value: 'inherit'
		};

		if (!property.Editor.MainFont) {
			inheritOption.label = 'Main font';
		} else {
			inheritOption.label = 'Inherited from my website';
		}
		setFontForSelect([inheritOption, ...defaultFonts, ...beyableFonts]);
		
	}, [fontData]);

	useEffect(() => {
		setIsWebsiteFont(data && data.toString() === 'inherit' && property.Editor.MainFont);
	}, [data]);

	return (
		<>
			<SelectDropdown
				label={label}
				optionsList={fontForSelect}
				value={data && data.toString()}
				onChange={handelChange}
				fullWidth={fullWidth}
			/>
			{isWebsiteFont &&
				<SectionMessage
					title='Your website main font is used'
					text='The rendering of the text may be different here, use the Preview button to see the final rendering.'
					className='mt_10'
					verticalSize='xs'
					fontSize='s'
					noMargin={true}
				/>
			}
		</>
	);
}