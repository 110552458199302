import React, { createContext, useContext,useState, Dispatch, SetStateAction,useEffect} from 'react';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import {SegmentGroupType,  convertSegmentGroupTypeFromApi } from '../../../SegmentGroups/List/context/EntityTypes';
import SegmentRankingServices from '../../../../Services/SegmentRankingServices';
import ProductRankingServices from '../../../../Services/ProductRankingServices';
import SegmentGroupServices from '../../../../Services/SegmentGroupServices';
const SegmentGroupEditorContext = createContext<SegmentListContextType | undefined>(undefined);
import {Segment} from '../../../../Services/SegmentRankingServicesTypes';

function useSegmentGroupEditorContext() {
	const context = useContext(SegmentGroupEditorContext);
	if (!context) throw Error('useSegmentGroupEditorContext can only be used inside a SegmentGroupEditorContextProvider');
	return context;
}
import {SegmentRankingApiType} from '../../../../Services/SegmentRankingServicesTypes';

type CreateSegmentRankingContextProviderType = {
	$http: any,
	$rootScope: any,
	$routeParams: any,
	$timeout: any,
	children: any,
	AuthServices:any,
	$location:any
}
type ExternalAudienceType = {
	name:string;
	ruleId: string;
}
const defaultPagin = {
	offset: 0,
	limit: 10,
};
type TypeRecommendationsObject = {
	description : string;
	id:string;
	lastUpdatedUtc:string;
	name:string;
	tenant:string | null;
	usedCount: number;
}
const emptyExternalAudience: ExternalAudienceType = {
	name:'',
	ruleId: ''
};
const CreateSegmentGroupEditorContextProvider = (props: CreateSegmentRankingContextProviderType) => {

	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const $routeParams =  props.$routeParams;
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	// const isAdmin = impersonatingServices.isAnAdmin();
	// const $routeParams = props.$routeParams;
	const segmentRankingServices = new SegmentRankingServices(accountId, props.$http);
	const productRankingServices = new ProductRankingServices( props.$http,props.AuthServices,accountId,);
	const segmentGroupServices = new SegmentGroupServices(accountId, props.$http);
	const [listRankingSegments, setlistRankingSegments] = useState<SegmentRankingApiType[]>([]);

	const [segmentGroups, setSegmentGroups] = useState<SegmentGroupType[]>([]);

	const [isLoading, setisLoading] = useState<boolean>(false);
	const [noData, setnoData] = useState<boolean>(true);
	const [dropDownisOpen, setdropDownisOpen] = useState<boolean>(false);
	const [addNewExternalAudience, setaddNewExternalAudience] = useState<boolean>(false);
	const [externalAudienceObject, setexternalAudienceObject] = useState<ExternalAudienceType>(emptyExternalAudience);
	const [rankingRules, setRankingRules] = useState<TypeRecommendationsObject[] | null>(null);
	const [sureToDelete, setSureToDelete] = useState<boolean>(false);
	const [idToDelete, setIdToDelete] = useState<string>();
	const [segmentType, setsegmentType] = useState<string | undefined  >();
	const [hasErrorOnExternalItem, setHasErrorOnExternalItem] = useState<boolean>(false);
	const getRankingList = () => {
		return productRankingServices.getRankingList('', 'Name', false, defaultPagin, callbackSuccess => {
			setRankingRules(callbackSuccess);
		}, _error => {
			systemServices.showError();
		});
	};
	const handleChooseSegmentationType = (typeSegmentation) => {
		setsegmentType(typeSegmentation);
	} ;
	const handleCreateEmptySegmentation = (typeSegmentation:string) => {
		const newSegmentGroup ={
			id: '',
			segmentsType: typeSegmentation,
			segments : [
				
			]};
		segmentRankingServices.postSegmentRankingGroup(newSegmentGroup,_data => {
			getListRankingSegments();
		}, err => {
			console.error(err);
			systemServices.showError('Cannot create segment groups');
		});
	};
	const refreshList = () =>{
		segmentGroupServices.getSegmentGroups(data => {
			setSegmentGroups(data.map(convertSegmentGroupTypeFromApi));
		
		}, err => {
			console.error(err);
			systemServices.showError('Cannot get segment groups');
		});
	};

	const getEditURL = ():string => {
		const ka =  props.$routeParams?.ka ? `&ka=${accountId}` : '';
		const provider = '?provider=ranking';
		const url = `/Insight/segment/edit${provider}${ka}`;
		return url;
	};
	const getManageURL = ():string => {
		const ka =  props.$routeParams?.ka ? `?ka=${accountId}` : '';
		const url = `/Insight/segment/settings${ka}`;
		return url;
	};
	const handleChangeValueExternalAudience = (name:string,value:string) =>{

		const valuesNamesOfSegmentsRancking:string[] = listRankingSegments[0].segments.map((segment:Segment )=> segment.segmentId.toLowerCase());
		if(valuesNamesOfSegmentsRancking.includes(value.toLowerCase())){
			setHasErrorOnExternalItem(true);
		}else{
			setHasErrorOnExternalItem(false);
		}
		setexternalAudienceObject(prev => ({...prev,
			[name]:value
		}));
	};
	const handleChooseRuleIdForUnMappedAudience = (idRule:string, rule) => {
		const segmentNewValue = listRankingSegments[0];
		sessionStorage.removeItem('segmentType');
		if(segmentNewValue){
			segmentNewValue.segments = [...listRankingSegments[0].segments,
				{segmentId:rule.id,
					segmentItems: [{abtestGroupId: 'default', ranking:idRule }]
				}
			];
			setisLoading(true);
			segmentRankingServices.updateSegmentRankingGroup(segmentNewValue,_data => {
				getListRankingSegments();
			}, err => {
				console.error(err);
				systemServices.showError('Cannot create segment groups');
			});
		}else{
			const newSegmentGroup ={
				// id: '',
				segmentsType: 'Beyable',
				segments : [
					{segmentId:rule.id,
						segmentItems: [{abtestGroupId: 'default', ranking:idRule }]
					}
				]};
			segmentRankingServices.postSegmentRankingGroup(newSegmentGroup,_data => {
				getListRankingSegments();
			}, err => {
				console.error(err);
				systemServices.showError('Cannot create segment groups');
			});
		}
		
	};
	const handleChooseRuleIdForMappedAudience = (idRule:string, name) => {
		const segmentNewValue = listRankingSegments[0];
		sessionStorage.removeItem('segmentType');
		const elment = listRankingSegments[0].segments.find(x=> x.segmentId === name);
		if(elment){
			const indexOfItem = segmentNewValue.segments.indexOf(elment);
			segmentNewValue.segments.splice(indexOfItem,1,{segmentId:name,
				segmentItems: [{abtestGroupId: 'default', ranking:idRule }]
			});
			setisLoading(true);
			segmentRankingServices.updateSegmentRankingGroup(segmentNewValue,_data => {
				getListRankingSegments();
			}, err => {
				console.error(err);
				systemServices.showError('Cannot create segment groups');
			});
		}
		
	};
	const handleSaveExternalAudience = () =>{
		const segmentNewValue = listRankingSegments[0];
		if(segmentNewValue){

			if(!hasErrorOnExternalItem){
				segmentNewValue.segments = [...listRankingSegments[0].segments,
					{segmentId:externalAudienceObject.name,
						segmentItems: [{abtestGroupId: 'default', ranking: externalAudienceObject.ruleId}]
					}
				];
				segmentRankingServices.updateSegmentRankingGroup(segmentNewValue,_data => {
					getListRankingSegments();
					setexternalAudienceObject(emptyExternalAudience);
					setaddNewExternalAudience(false);
					handleChooseSegmentationType('External');
				}, err => {
					console.error(err);
					setisLoading(false);
					systemServices.showError('Cannot create segment groups');
				});

			}

		}else{
			
			const newSegmentGroup ={
				// id: '',
				segmentsType: 'External',
				segments : [
					{segmentId:externalAudienceObject.name,
						segmentItems: [{abtestGroupId: 'default', ranking: externalAudienceObject.ruleId}]
					}
				]};
			segmentRankingServices.postSegmentRankingGroup(newSegmentGroup,_data => {
				getListRankingSegments();
				setexternalAudienceObject(emptyExternalAudience);
				setaddNewExternalAudience(false);
				handleChooseSegmentationType('External'); 
			}, err => {
				console.error(err);
				setisLoading(false);
				systemServices.showError('Cannot create segment groups');
			});
		}
		setisLoading(true);

		
	};
	const getListRankingSegments = () => {
		setisLoading(true);
		segmentRankingServices.getProductRankingGroup( (success:any) => {
			if(success && success.length === 0){
				setnoData(true);
				const storage = sessionStorage.getItem('segmentType');
				if (storage){
					setsegmentType(storage);
				}
			}else{
				setnoData(false);
				setsegmentType(success[0].segmentsType);
				setlistRankingSegments(success);
				sessionStorage.removeItem('segmentType');
			}
			setisLoading(false);
		}, err => {
			console.log(err);
			systemServices.showError('Error while getting segmentation');
			setisLoading(false);
			setnoData(true);
		});
	};
	const handleChooseRuleId = (idRule:any) => {
		setexternalAudienceObject(prev =>({...prev, ruleId:idRule}));
	};
	const onDelete = (id:string) => {
		setSureToDelete(true);
		setIdToDelete(id);
	};
	const handleDeleteSegment = () => {
		if(idToDelete){
			const segmentNewValue = listRankingSegments[0];
			segmentNewValue.segments = [...segmentNewValue.segments.filter(x => x.segmentId !== idToDelete),
			];
			segmentRankingServices.updateSegmentRankingGroup(segmentNewValue, () => {
				systemServices.showSuccess('segment deleted with success');
				getListRankingSegments();
				getRankingList();
				refreshList();
			}, (_error) =>{
				systemServices.showError('Error while getting segmentation');
				setisLoading(false);
			});
		}
	};
	const handleReorderSegments = (newSegmentOrder:SegmentRankingApiType) => {
		segmentRankingServices.updateSegmentRankingGroup(newSegmentOrder, () => {
			systemServices.showSuccess('segment order change with success');
		}, (_error) =>{
			systemServices.showError('Error while getting segmentation');
			setisLoading(false);
		});
	};

	const handleDeleteSegmentRankingGroup = (rankingGroupId:string) => {
		segmentRankingServices.deleteSegmentRankingGroup(rankingGroupId, () => {
			systemServices.showSuccess('Segmentation has been deleted');
			setsegmentType(undefined);
			setSegmentGroups([]);
			setlistRankingSegments([]);
			setisLoading(true);
			setTimeout(()=>{
				getListRankingSegments();
				getRankingList();
				refreshList();
			},1000);
		}, (_error) =>{
			systemServices.showError('An error occurred while deleting segmentation');
			setisLoading(false);
		});
	};

	useEffect(()=>{
		getListRankingSegments();
		getRankingList();
		refreshList();
	},[]);
	
	const context: SegmentListContextType = {
		listRankingSegments,
		isLoading,
		setlistRankingSegments,
		noData,
		setdropDownisOpen,
		dropDownisOpen,
		setaddNewExternalAudience,
		addNewExternalAudience,
		externalAudienceObject,
		handleChangeValueExternalAudience,
		handleSaveExternalAudience,
		setexternalAudienceObject,
		rankingRules,
		segmentGroups,
		handleChooseRuleId,
		handleChooseRuleIdForUnMappedAudience,
		handleChooseRuleIdForMappedAudience,
		getEditURL,
		handleDeleteSegment,
		onDelete,
		sureToDelete,
		idToDelete,
		setSureToDelete,
		handleReorderSegments,
		handleChooseSegmentationType,
		handleDeleteSegmentRankingGroup,
		segmentType,
		handleCreateEmptySegmentation,
		getManageURL,
		$routeParams,
		accountId,
		hasErrorOnExternalItem
	};

	return (
		<SegmentGroupEditorContext.Provider
			value={context}>
			{props.children}
		</SegmentGroupEditorContext.Provider>
	);

};

type SegmentListContextType = {
	listRankingSegments:SegmentRankingApiType[];
	isLoading:boolean;
	setlistRankingSegments:Dispatch<SetStateAction<SegmentRankingApiType[]>>;
	noData:boolean;
	setdropDownisOpen:Dispatch<SetStateAction<boolean>>;
	dropDownisOpen:boolean;
	setaddNewExternalAudience:Dispatch<SetStateAction<boolean>>;
	addNewExternalAudience:boolean;
	externalAudienceObject:ExternalAudienceType;
	handleChangeValueExternalAudience: (n:string,v:string)=>void;
	handleSaveExternalAudience:()=>void;
	setexternalAudienceObject: Dispatch<SetStateAction<ExternalAudienceType>>;
	rankingRules:TypeRecommendationsObject[] | null;
	segmentGroups: SegmentGroupType[];
	handleChooseRuleId:(st:string)=>void;
	handleChooseRuleIdForUnMappedAudience:(st:string,rule:any)=>void;
	handleChooseRuleIdForMappedAudience:(st:string,name:string)=>void;
	handleDeleteSegment: ()=>void;
	onDelete: (st:string)=>void;
	sureToDelete:boolean;
	idToDelete:string | undefined;
	setSureToDelete:Dispatch<SetStateAction<boolean>>;
	handleReorderSegments:(element:SegmentRankingApiType)=>void;
	handleChooseSegmentationType: (st:string)=>void;
	handleDeleteSegmentRankingGroup: (st:string)=>void;
	segmentType: string | undefined;
	handleCreateEmptySegmentation:  (st:string)=>void;
	getEditURL: ()=>string;
	getManageURL: ()=>string;
	$routeParams:any;
	accountId:string;
	hasErrorOnExternalItem:boolean;
}


export default CreateSegmentGroupEditorContextProvider;

export { useSegmentGroupEditorContext };
