import React from 'react';
import { useServerSideEditorContext } from '../../context/ServerSideEditorContextProvider';
import InputCustom from '../../../../../Components/InputCustom';
import { Section } from '../../../../../Components/Section/Section';
import { Article } from '../../../../../Components/Article/Article';

export default function Information() {

	const {
		handleChangeName,
		handleChangeDescription,
		serverSideCampaign,
	} = useServerSideEditorContext();


	return (
		<Section width='xs' hasMargin={true}>
			<Article innerSize='l' title="Campaign information">
				<InputCustom
					name='Name'
					label='Name'
					value={serverSideCampaign?.Name ?? ''}
					onChange={e => handleChangeName(e.target.value)}
					fullWidth={true}
				/>
				<InputCustom
					name='Description'
					label='Description'
					placeholder="Optional"
					type="textarea"
					rows="2"
					autogrow={true}
					value={serverSideCampaign?.Description ?? ''}
					onChange={e => handleChangeDescription(e.target.value)}
					fullWidth={true}
				/>
			</Article>
		</Section>
	);
}