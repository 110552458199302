
import  { Moment } from 'moment';
export enum Metrics  {
	sessions = 'NumberOfSessions',
	uniqueVisitor =	'NumberOfUniqueVisitors',
	numberViews = 'NumberOfPageViews'
}
export enum Dimensions  {
	'CountryCode',
	'subdivision'
}
export type Filters = {
	fromDate :string;
	fromDateToCompare: string;
	toDate: string;
	toDateToCompare: string;
} 
export type OptionsApiGeoloc ={
	metrics: Metrics[];
	filters: any;
	dimensions: Dimensions[];
	fromDate: Moment;
	toDate: Moment;
	devices?:[]
} 
export type GeolocalisationObject = {
	lat: number;
	lon: number;
	name: string;
	iso: string;
	NumberOfPageViews: number;
	NumberOfSessions?: number;
	NumberOfUniqueVisitors?: number ;
}
export type DatasWorld = [ string, number];