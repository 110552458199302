import React, { useState, useEffect } from 'react';
import { CampaignsContext } from '../../Campaigns_React/context';
import useEventBroker from '../../../Hooks/useEventBroker';
import OnlyOverview from '../../FormSurvey/OnlyOverview';
import Portal from '../../../Components/Portal';

const Unset = 'Unset';

export default function FormSurveyEditor({
	label = 'Survey',
	onChange,
	getCurrentVariation,
	data
}) {

	const {
		wysiwygSurveyTarget
	} = CampaignsContext();

	const [editorState, setEditorState] = useState();

	const getMessage = () => {
		const nbQuestions = data && JSON.parse(data)?.sections?.map(s => s?.panels.length).reduce((a, b) => a + b, 0);
		if (!nbQuestions) {
			return Unset;
		} else {
			return nbQuestions === 1 ? nbQuestions + ' question' : nbQuestions + ' questions';
		}
	};

	const [variationMessageState, setVariationMessageState] = useState(getMessage());

	useEffect(() => {
		setVariationMessageState(getMessage());
		if (data) {
			const editorState = JSON.parse(data);
			setEditorState(editorState);
		}
	}, [data]);

	const { publish } = useEventBroker();

	const openPanelFormSurveyRequested = () => {
		publish('evt/campaign/setFormSurveyRequested', {
			callback: onChange, data: getCurrentVariation()
		});
	};

	return (
		<>
			<div className="format_param_group clickable folded overflow_hidden"
				onClick={() => openPanelFormSurveyRequested()}>
				<div className="format_param_group_name flex">
					<span className="format_param_group_icon flex_item_fix">
						<i className="fas fa-poll" />
					</span>
					<span className="flex_item_full flex flex_align_baseline overflow_hidden">
						<span className="flex_item_fix">{label}</span>
						<span className="flex_item_full ellips format_param_group_value">
							{variationMessageState !== Unset ?
								<span className='fw_medium c_green'>{variationMessageState}</span>
								:
								<span className='fw_medium c_red'>{variationMessageState}</span>
							}
						</span>
					</span>
					<span className="format_param_group_arrow flex_item_fix">
						<i className="fas fa-chevron-right" />
					</span>
				</div>
			</div>
			{wysiwygSurveyTarget &&
				<Portal target={wysiwygSurveyTarget}>
					<OnlyOverview
						editorState={editorState}
					/>
				</Portal>
			}
		</>
	);
}