const dictionary = [
	{
		name: 'hide-permanent-cp',
		description: 'affiche / cache les campagnes fil rouge',
		active: false,
		isOnPage: false,
	},
	{
		name: 'product-associations',
		description: 'Associations de produits',
		active: false,
		isOnPage: false,
	},	
	{
		name: 'product-tagging',
		description: 'New Tagging',
		active: false,
		isOnPage: false,
	},
	{
		name: 'emerch-demo',
		description: 'Emerch demo',
		active: false,
		isOnPage: false,
	},
	{
		name: 'abtest-demo',
		description: 'AB Tests demo',
		active: false,
		isOnPage: false,
	},
	{
		name: 'internal',
		description: 'Internal section',
		active: false,
		isOnPage: false,
	},
	{
		name: 'chatbot-tooltip',
		description: 'Chatbot tooltip',
		active: false,
		isOnPage: false,
	},
	{
		name: 'reporting-new-section',
		description: 'Reporting Analysis',
		active: false,
		isOnPage: false,
	}
];

export default dictionary;