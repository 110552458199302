import React, { useState, useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import Btn from '../../Components/Btn';
import Confirm from '../../Components/Confirm';
import TargetingRulePanel from './TargetingRulePanel';
import SegmentCardCondition from './SegmentCardCondition';
import { useTriggerContext } from './TriggerContext';
import { getTriggerIcon, getTriggerLabel, getTriggerOperand, getTriggerValue } from './Utils';
import RenameFavoriteRuleModal from './RenameFavoriteRuleModal';
import {useTranslation} from 'react-i18next';

export default function SegmentRule({
	rule,
	type,
	isUsed = false
}) {
	const { t, i18n } = useTranslation(['common']);
	const {
		addSegmentToTargetingRules,
		addFavoriteRuleToTargetingRules,
		deleteAFavoriteRule
	} = useTriggerContext();

	const bodyRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [askConfirmDeleteFavotite, setAskConfirmDeleteFavotite] = useState(false);

	const [displayDevices, setDisplayDevices] = useState();
	const [displayTriggerEvent, setDisplayTriggerEvent] = useState();
	const [displayTriggerDelay, setDisplayTriggerDelay] = useState();
	const [modalRenameFavoriteIsOpen, setModalRenameFavoriteIsOpen] = useState(false);

	const getEventFriendlyName = (triggerEvent) => {
		if (triggerEvent) {
			if (triggerEvent.Label === 'Exit intent') return 'On exit-intent';
			if (triggerEvent.Label === 'Percentage of scroll') return 'After scrolling ' + triggerEvent.ValueToCompare + '%';
		}
		return 'On page load';
	};

	useEffect(() => {
		if (rule) {
			const devices = rule.triggers.deviceTrigger.ValueToCompare;
			setDisplayDevices(devices.length === 3 ? 'all' : devices.join(' and '));

			const triggerEvent = rule.triggers.eventTrigger;
			setDisplayTriggerEvent(getEventFriendlyName(triggerEvent));

			const triggerDelay = rule.triggers.delayTrigger;
			const valueDelay = triggerDelay ? triggerDelay.ValueToCompare : 0;
			setDisplayTriggerDelay(valueDelay);
		}
	}, [rule]);

	const toggle = () => {
		if (!bodyRef.current) return;

		const bodyRefcurrent = bodyRef.current;
		const delay = isOpen ? 0 : 300;
		const height = bodyRefcurrent.scrollHeight;
		bodyRefcurrent.style.height = height + 'px';
		bodyRefcurrent.style.overflow = 'hidden';

		setTimeout(function () {
			bodyRefcurrent.style.height = '';
			bodyRefcurrent.style.overflow = '';
		}, delay);

		setIsOpen(!isOpen);
	};


	const addTheRuleToTargetingRules = (rule) => {
		if (type === 'favorite') {
			addFavoriteRuleToTargetingRules(rule);
		}
		if (type === 'segment') {
			addSegmentToTargetingRules(rule);
		}
	};

	return (
		<>
			<div className={isOpen ? 'segment_section segment_card segment_card_open' : 'segment_section segment_card segment_card_close'}>
				<ReactTooltip backgroundColor='black' effect='solid' delayShow={500} html={true} place="bottom" />
				<div className='segment_card_header clickable s flex' onClick={() => toggle()}>
					{type === 'favorite' &&
						<div className='segment_card_icon segment_card_icon_star flex_item_fix'>
							<i className='fas fa-star'></i>
						</div>
					}
					{type === 'segment' &&
						<div className='segment_card_icon segment_card_icon_magic flex_item_fix'>
							<i className='fas fa-magic'></i>
						</div>
					}
					<div className='segment_card_name flex_item_full ellips'>{rule.name}</div>
					<div className='segment_card_arrow segment_card_toggle_arrow flex_item_fix'>
						<i className='fas fa-chevron-up'></i>
					</div>
				</div>
				<div className='segment_card_body' ref={bodyRef}>
					<SegmentCardCondition
						label={ t('segment.wichDevices')}
						value={displayDevices}
						icon={'device'}
					/>
					<SegmentCardCondition
						label={ t('segment.When')}
						value={displayTriggerEvent}
						icon={'behavior'}
					/>
					{rule.triggers.delayTrigger && rule.triggers.delayTrigger.ValueToCompare !== 0 &&
						<SegmentCardCondition
							label={t('segment.Delay')}
							value={displayTriggerDelay + ' sec'}
							icon={'delay'}
						/>
					}
					{rule.triggers.condition.length > 0 && rule.triggers.condition.map((trigger, i) => (
						<SegmentCardCondition
							label={getTriggerLabel(trigger.Type, trigger.Label, trigger.ValueToCompare, trigger.Name)}
							value={getTriggerValue(trigger.Type, trigger.ValueToCompare, trigger.Name)}
							icon={getTriggerIcon(trigger.Categorie)}
							operand={getTriggerOperand(trigger)}
							key={i}
							tooltip={trigger.Tooltip}
						/>
					))}

					<div className='segment_card_footer'>
						<div className='segment_card_footer_col_left'>
							{type === 'favorite' &&
								<>
									<Btn
										color="secondary"
										style="ghost"
										light={true}
										onClick={() => { setAskConfirmDeleteFavotite(true); }}
										message={t('segment.remove')}
										icon="fas fa-trash"
										size="s"
										horizontalSize="xxs"
									/>
									<Btn
										color="secondary"
										style="ghost"
										light={true}
										onClick={() => setModalRenameFavoriteIsOpen(true)}
										message={t('segment.Rename')}
										icon="fas fa-pen"
										size="s"
										horizontalSize="xxs"
									/>
								</>
							}
						</div>
						<div className='segment_card_footer_col_right'>
							<Btn
								color="primary"
								style="reverse"
								onClick={() => { toggle(); addTheRuleToTargetingRules(rule); }}
								message={t('segment.useSegment')}
								icon="fas fa-plus"
								size="s"
								horizontalSize="xs"
								disabled={isUsed === true}
								dataTip={isUsed === true ? t('segment.segmentAlreadyUsed') : ''}
							/>
						</div>
					</div>
				</div>
				<Confirm
					isOpen={askConfirmDeleteFavotite}
					onClose={(e) => setAskConfirmDeleteFavotite(false)}
					title={t('segment.removeFrom')}
					text={t('segment.thisSegmentWillNoLonger')}
					cancelText={t('segment.Cancel')}
					confirmText={t('segment.Remove')}
					confirmCallback={(e) => { toggle(); deleteAFavoriteRule(rule.id); }}
				/>
			</div>
			{modalRenameFavoriteIsOpen &&
				<RenameFavoriteRuleModal
					rule={rule}
					isEditingExistingRule={true}
					modalIsOpen={modalRenameFavoriteIsOpen}
					setModalIsOpen={setModalRenameFavoriteIsOpen}
				/>
			}
		</>
	);
}