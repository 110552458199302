import React, { useState } from 'react';
import InputCustom from '../../../../Components/InputCustom';
import { useProductFeedEditionContext } from '../../context/ProductFeedEditionContext';
import Accordion from './Accordion';
import { fieldsTypesPrestaShop } from '../../utils';
import editionStyles from '../ProductFeedEdition.module.css';
import FieldContainerSimple from './FieldContainerSimple';
import { Checkbox } from 'antd';

export default function PrestashopContainerMapping() {

	const {
		handleChangeSimpleField,
		productFeedObject,
		handleChangeSelectionForSelectedFields
	} = useProductFeedEditionContext();

	function handleChangeSimpleFieldFn(e) {
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name, value);
	}

	function getFieldsSelected(obj, key) {
		if (!obj?.selectedFields) {
			return false;
		} else {
			if (obj.selectedFields.includes(key)) {
				return true;
			} else return false;
		}
	}


	const checkBox = (type) => {
		const isSelected = getFieldsSelected(productFeedObject, type);
		const onClick = v => handleChangeSelectionForSelectedFields(type, v);
		const label = type;
		return (
			<div
				className={isSelected ? editionStyles.card_map_header_set : editionStyles.card_map_header_empty}
				onClick={(e) => onClick(!isSelected)}
			>
				<div className='flex'>
					<div className='flex_item_fix'>
						<div className={editionStyles.card_map_header_checkbox}>
							<Checkbox
								checked={isSelected}
								forcedChecked={isSelected}
							/>
						</div>
					</div>
					<div className='flex_item_full'>
						<span className={editionStyles.card_map_name}>{label}</span>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			{fieldsTypesPrestaShop?.map((type, i) => {
				const isSelected = getFieldsSelected(productFeedObject, type);
				function onChangeSelection(v) {
					handleChangeSelectionForSelectedFields(type, v);
				}
				return <FieldContainerSimple
					key={i}
					name={type}
					label={type}
					onClick={onChangeSelection}
					isSelected={isSelected}
				>
				</FieldContainerSimple>;
			})}

			<Accordion
				label={'URL'}
				value={''}
				placeholder="Not set"
				fieldIndex={1}
				isEmpty={!getFieldsSelected(productFeedObject, 'Url')}
				headerTitle={<><strong>URL</strong> and url pattern</>}
			>
				{checkBox('Url')}
				<InputCustom
					value={productFeedObject.productUrlPattern}
					type={'text'}
					label={'Product url pattern'}
					name={'productUrlPattern'}
					placeholder={'{category:/}{id}{-:id_product_attribute}-{rewrite}{-:ean13}.html'}
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					fullWidth={true}
					autoFocus={true}
				/>
			</Accordion>

			<Accordion
				label={'Image'}
				value={''}
				placeholder="Not set"
				fieldIndex={2}
				isEmpty={!getFieldsSelected(productFeedObject, 'UrlThumbnail')}
				headerTitle={<><strong>Image</strong> and url pattern</>}
			>
				{checkBox('UrlThumbnail')}
				<InputCustom
					value={productFeedObject.productUrlThumbnailPattern}
					type={'text'}
					label={'Product thumbnail url pattern'}
					name={'productUrlThumbnailPattern'}
					placeholder={'{id}-home_default/{rewrite}.jpg'}
					onChange={(e) => handleChangeSimpleFieldFn(e)}
					fullWidth={true}
					autoFocus={true}
				/>
			</Accordion>
		</>
	);
}
