import React, { useState } from 'react';
import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';

export default function SourceTypeFilter({
	sourceTypeSelected,
	handleChangeSourceType,
	initialValue,
	resetSource
}) {

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const isActive = (sourceTypeSelected.length > 0) ? true : false;
	function getLabel (){
		if(!sourceTypeSelected){
			return;
		}
		if(sourceTypeSelected.length === 0){
			return 'All';
		}
		if(sourceTypeSelected.length > 1){
			return sourceTypeSelected[0] + ' +' + (sourceTypeSelected.length -1);
		}
		if(sourceTypeSelected.length === 1){
			return sourceTypeSelected[0] ;
		}

	}
	const button = (
		<div className={isActive ? 'analytics_range active' : 'analytics_range'}>
			<div className="flex">
				<div className="flex_item_full">
					<div className="analytics_range_sup">Source Type</div>
					<div className="analytics_range_value">
						{sourceTypeSelected && <>{getLabel()}</>}
					</div>
				</div>
				<div className="flex_item_fix analytics_range_drop_arrow"></div>
			</div>
		</div>
	);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
			minWidth={180}
		>
			<Listbox>
				<ListboxItem
					onClick={() => {resetSource(false); setDropdownIsOpen(false);}}
					message={<>All <span className='grey_3 s_13'>(no filter)</span></>}
					selected={!isActive}
					hasSelectedIcon={true}
				/>
				<li className='hr' />
				{initialValue.map((value) => 
					<ListboxItem
						key={value}
						onClick={() => handleChangeSourceType(value)}
						message={value}
						selected={sourceTypeSelected.includes(value)}
						hasCheckbox={true}
					/>
				)}
			</Listbox>
		</Dropdown>
	);
}
