import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { PanelSelect, PanelInitTypeSelection, PanelActions, PanelHeader, PanelBody, PanelFooter } from './FormCustomPanelSubComponents';
import FormCustomMultipleControls from './FormCustomMultipleControls';
import styles from './FormCustomPanel.module.css';
import controlTypes from './controlTypes';

const getPanelStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	boxShadow: isDragging ? '0 3px 5px #000' : 'none',
	margin: '0 0 40px',
	...draggableStyle
});

export default class FormCustomPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ? props.type : '',
      question: props.question ? props.question : '',
      placeholder: props.placeholder ? props.placeholder : '',
      controls: props.controls ? props.controls : '',
      required: props.required ? props.required : false,
      buttonType: props.buttonType ? props.buttonType : '',
      name: props.name ? props.name : '',
      value: props.value ? props.value: '', 
    };
  }
  
  remove() {
    this.props.onRemove(this.props.id, this.props.sections.current);
  }
  
  duplicate() {
    this.props.onDuplicate(this.props.id, this.props.sections.current);
  }

  setName(e) {
    const state = {name: e.target.value};

    this.setState(state, () => {
      this.props.onContentChange(this.props.id, this.props.sections.current, state);
    });
  }
  
  setAsRequired(e) {
    const state = {required: !this.state.required};
    
    this.setState(state, () => {
      this.props.onContentChange(this.props.id, this.props.sections.current, state);
    });
  }

  handleFieldBlur(e) {
    const state = {[e.target.name]: e.target.value};

    this.setState(state, () => {
      this.props.onContentChange(this.props.id, this.props.sections.current, state);
    });
  }
  
  setControls(controls) {
    const state = {controls};
    
    this.setState(state, () => {
      this.props.onContentChange(this.props.id, this.props.sections.current, state);
    });
  }

  selectControlType(e) {
    const state = {type: this.getType(e.target.value).id};

    this.setState(state, () => {
      this.props.onContentChange(this.props.id, this.props.sections.current, state);
    });
  }

  getType(id) {
    const identifier = parseInt(id, 10);

    return this.props.controlTypes.find(controlType => controlType.id === identifier);
  }

  handleButtons(e) {
    const btnValue = e.target.value;
    const state = {buttonType: btnValue};

    if(btnValue === 'next section') {
      if (!this.props.sections.next){
        this.props.onSectionAdd();
      }
    } else {
      if(this.props.sections.next) {
        this.props.onSectionRemove(this.props.sections.next);
      }
    }

    this.setState(state, () => {
      this.props.onContentChange(this.props.id, this.props.sections.current, state);
    });
  }

  getparagraphControls() {
    return <label className={styles.panelLabel}>
      <span>Placeholder</span>
      <input type="text" onBlur={(e) => this.handleFieldBlur(e)} name="placeholder" defaultValue={this.state.placeholder} />
    </label>
  }

  gettextControls() {
    return <label className={styles.panelLabel}>
      <span>Placeholder</span>
      <input type="text" onBlur={(e) => this.handleFieldBlur(e)} name="placeholder" defaultValue={this.state.placeholder} />
    </label>
  }

  getemailControls() {
    return <label className={styles.panelLabel}>
      <span>Placeholder</span>
      <input type="text" onBlur={(e) => this.handleFieldBlur(e)} name="placeholder" defaultValue={this.state.placeholder} />
    </label>
  }

  gethiddenControls() {
    return <label className={styles.panelLabel}>
      <span>Value</span>
      <input type="text" onBlur={(e) => this.handleFieldBlur(e)} name="value" defaultValue={this.state.value} />
    </label>
  }

  
  getphoneControls() {
    return <label className={styles.panelLabel}>
      <span>Placeholder</span>
      <input type="text" onBlur={(e) => this.handleFieldBlur(e)} name="placeholder" defaultValue={this.state.placeholder} />
    </label>
  }
  
  getbuttonControls() {
    return <div className={styles.buttonControls}>
      <div className={styles.controlsTitle}>Action</div>
      <div className={styles.buttonControlsWrapper}>
        <button
          type="button"
          className={`radio_box ${this.state.buttonType === 'submit' ? 'checked': ''}`}
          value="submit"
          onClick={(e) => this.handleButtons(e)}>
          <span>
            <i className="fa fa-check"></i>
          </span>
          <i className="fa fa-check"></i>
          <div>Submit</div>
        </button>
        <button
          type="button"
          className={`radio_box ${this.state.buttonType === 'next section' ? 'checked': ''}`}
          value="next section"
          onClick={(e) => this.handleButtons(e)}>
          <span>
            <i className="fa fa-check"></i>
          </span>
          <i className="fa fa-arrow-right"></i>
          <div>Next section</div>
        </button>
      </div>
    </div>
  }

  componentDidUpdate(prevProps) {
    const propsToRefresh = ['type', 'question', 'placeholder', 'controls', 'required', 'buttonType'];

    for(const prop of propsToRefresh) {
      if(prevProps[prop] !== this.props[prop]) {
        this.setState({[prop]: this.props[prop]});
      }
    }
  }
  
  render() {
    const buttonType = {
      id: 3,
      name: 'button',
      multiple: false
    };
    const inheritedStyles = this.props.styles;
    const typeObj = this.state.type === 3 ? buttonType : this.getType(this.state.type);
    const panelActions = {
      duplicate: this.state.type !== 3 ? this.duplicate.bind(this) : null,
      remove: this.remove.bind(this)
    };

    return <Draggable draggableId={`panel-${this.props.id}`} index={this.props.index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={getPanelStyle(
          snapshot.isDragging,
          provided.draggableProps.style
        )}>

        <article className={`${inheritedStyles.formControl} ${styles.panel}`}>
          {this.state.type ? (
              <div className={styles.panelForm}>
                <PanelHeader dragHandleProps={provided.dragHandleProps} styles={styles}>
                  {this.state.type === 3 ? 
                    "Button" :
                    <PanelSelect
                      styles={styles}
                      label="Type :"
                      options={controlTypes}
                      type={this.state.type}
                      onSelectChange={this.selectControlType.bind(this)} />
                  }

                <PanelActions
                    styles={styles}
                    actions={panelActions} />
                </PanelHeader>
                <PanelBody styles={`${styles.panelContent} ${this.state.type === 3 || typeObj.multiple ? styles.multiple : ''}`}>
                  {this.state.type === 8 ? "" :
                  <label className={styles.panelLabel}>
                    <span>{this.state.type === 3 ? 'Text': 'Question'}</span>
                    <input type="text" onBlur={(e) => this.handleFieldBlur(e)} name="question" defaultValue={this.state.question} />
                  </label>
                  }

                  {typeObj.multiple ? (
                      <FormCustomMultipleControls
                        styles={styles}
                        type={typeObj.name}
                        controls={this.state.controls}
                        onControlsChange={this.setControls.bind(this)} />
                    ) : (
                      this[`get${typeObj.name}Controls`]()
                    )}
                </PanelBody>
                
                {this.props.displayRequired &&
                  <PanelFooter styles={styles.panelFooter}>
                    <label className={styles.panelName}>
                      <span>Name</span>
                      <input type="text" onChange={this.setName.bind(this)} defaultValue={this.state.name} />
                    </label>

                    <div className="btt-checkbox">
                      <label className="label-checkbox">
                        <div>Mandatory field</div>
                        <input type="checkbox" onChange={this.setAsRequired.bind(this)} defaultChecked={this.state.required} />
                        <span className="span-checkbox fa"></span>
                      </label>
                    </div>
                  </PanelFooter>
                }
              </div>
            ) : (
              <PanelInitTypeSelection
                styles={styles}
                controlTypes={this.props.controlTypes}
                dragHandleProps={provided.dragHandleProps}
                onSelect={this.selectControlType.bind(this)} />
            )}
          </article>
        </div>
      )}
    </Draggable>
    }
  }