import React from 'react';
import { Article } from '../../../../Components/Article/Article';
import EmptyState  from '../../../../Components/EmptyState';
import SpinnerWheel  from '../../../../Components/SpinnerWheel';
// import Btn from '../../../../Components/Btn';
// import { useAuditContext } from '../../context/ContextAudit';
import { useTranslation } from 'react-i18next';

export default function ProgressSection() {
	
	// const {
	// 	taskId,
	// 	handleGetAnalys
	// } = useAuditContext();

	const [t] = useTranslation(['kpi', 'utils']);

	return (
		<section className="section">
			<Article>
				<EmptyState
					title={
						<>
							{t('audit.analysisProgress')}
							<SpinnerWheel wheelSize="s" display="inline" className="ml_10" />
						</>
					}
					text={
						<>
							<div>{t('audit.analysisProgressDesc1')}</div>
							<div>{t('audit.analysisProgressDesc2')}</div>
							<div>{t('audit.analysisProgressDesc3')}</div>
						</>
					}
					imageUrl="/Assets/empty_scenario_reco_processing.svg"
					textSize="l"
					verticalSize="l"
					width="xl"
					// primaryAction={taskId &&
					// 	<Btn
					// 		message={t('actions.refresh', { ns: 'utils' })}
					// 		onClick={()=>handleGetAnalys(taskId)}
					// 		icon="fas fa-redo-alt"
					// 	/>
					// }
				/>
			</Article>
		</section>
	);
}
