import React,{useState,useEffect} from 'react';
import CodeEditor from './CodeEditor.js';
import { CampaignsContext } from '../../context/index.js';

export default function TemplateJson() {

	const {
		handleUpdateJsonTemplate,
		getCurrentVariation
	} = CampaignsContext();

	const activeVariation = getCurrentVariation() || {};
	const [isNotValidJSON, setIsNotValidJSON] = useState<any>();
	const [json, setJson] = useState();

	function isJSON(jsonStr) {
		try {
			JSON.parse(jsonStr);
			return true;
		} catch (e) {
			return false;
		}
	}
	
	const handleEditorChange = (value) => {
		setJson(value);
		handleUpdateJsonTemplate(value);
	};

	useEffect(() => {
		setIsNotValidJSON(!isJSON(json));
	}, [json]);

	return (
		<>
			<CodeEditor
				title="JSON"
				language="json"
				screenEnvironment="mobile"
				changeValue={(_prevValue, newValue) => {
					handleEditorChange(newValue);
				}}
				value={
					(activeVariation.TemplateDescriptorDesktop &&
								activeVariation.TemplateDescriptorMobile.EditableTemplate.Content) || ''
				}
			/>
			{json &&
				<div className='mt_8'>
					{isNotValidJSON ?
						<div className="c_red">
							<i className="fas fa-times mr_10"></i>
							Json is not valid
						</div>
						:
						<div className="c_green">
							<i className="fas fa-check mr_10"></i>
							Json is valid
						</div>
					}
				</div>
			}
		</>
	);
}
