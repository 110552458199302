import HttpServices from './HttpServices';

export default class toolingServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}

	// Get the account configuration 
	getAccountConfiguration(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}/feature/${accountId}/configuration`, callbackSuccess, callbackError);
	}
	// Routes for the account promocode
	putTheAccountPromoCode(accountId, subscriber, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}feature/${accountId}/promocode/configuration`, subscriber, callbackSuccess, callbackError);
	}
	getTheAccountPromoCode(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}feature/${accountId}/promocode/configuration`, callbackSuccess, callbackError);
	}
	// Routes for the Point of Sales 
	getAccountPointOfSales(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}feature/${accountId}/pointofsales/configuration`, callbackSuccess, callbackError);
	}
	putAccountPointOfSales(accountId, config, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}feature/${accountId}/pointofsales/configuration`, config, callbackSuccess, callbackError);
	}
	// get all parameters edited by operation
	getParameterConfigurationByOperation(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}feature/${accountId}/configuration/campaign/${campaignId}`, callbackSuccess, callbackError);
	}
	// Routes pour config alert stock 
	getStockAlert(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}feature/${accountId}/stockalert/${campaignId}/configuration`, callbackSuccess, callbackError);
	}
	postStockAlert(accountId, campaignId, provider, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}feature/${accountId}/stockalert/${campaignId}`, provider, callbackSuccess, callbackError);
	}
	postStockAlertTest(accountId, campaignId, recipient, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}feature/${accountId}/stockalert/${campaignId}/test`, recipient, callbackSuccess, callbackError);
	}
	putStockAlert(accountId, campaignId, provider, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}feature/${accountId}/stockalert/${campaignId}/configuration`, provider, callbackSuccess, callbackError);
	}
	// Routes pour config Email remarketing 
	getConfigEmailRemarketingById(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}feature/${accountId}/remarketing/${campaignId}/configuration`, callbackSuccess, callbackError);
	}
	postConfigEmailRemarketingById(accountId, campaignId, provider, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}feature/${accountId}/remarketing/${campaignId}/`, provider, callbackSuccess, callbackError);
	}
	putConfigEmailRemarketing(accountId, campaignId, provider, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}feature/${accountId}/remarketing/${campaignId}/configuration`, provider, callbackSuccess, callbackError);
	}
	postEmailRemarketingTest(accountId, campaignId, recipient, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}feature/${accountId}/remarketing/${campaignId}/test`, recipient, callbackSuccess, callbackError);
	}
	// Routes pour  Email My Basket 
	getConfigVisitorCartById(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}feature/${accountId}/sendvisitorcart/${campaignId}/configuration`, callbackSuccess, callbackError);
	}
	postConfigVisitorCartById(accountId, campaignId, provider, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}feature/${accountId}/sendvisitorcart/${campaignId}/`, provider, callbackSuccess, callbackError);
	}
	putConfigVisitorCartById(accountId, campaignId, provider, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}feature/${accountId}/sendvisitorcart/${campaignId}/configuration`, provider, callbackSuccess, callbackError);
	}
	postConfigVisitorTest(accountId, campaignId, recipient, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}feature/${accountId}/sendvisitorcart/${campaignId}/test`, recipient, callbackSuccess, callbackError);
	}
	// Routes pour Send  Code promo
	getCodePromoById(accountId, campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}feature/${accountId}/sendpromocode/${campaignId}/configuration`, callbackSuccess, callbackError);
	}
	postConfigPostCodePromoById(accountId, campaignId, provider, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}feature/${accountId}/sendpromocode/${campaignId}/`, provider, callbackSuccess, callbackError);
	}
	putCodePromoById(accountId, campaignId, provider, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}feature/${accountId}/sendpromocode/${campaignId}/configuration`, provider, callbackSuccess, callbackError);
	}
	postCodePromoById(accountId, campaignId, recipient, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}feature/${accountId}/sendvisitorcart/${campaignId}/test`, recipient, callbackSuccess, callbackError);
	}

	// Routes for the Product Feed configuration 
	getProductFeedConfiguration(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}feature/${accountId}/productfeed/configuration`, callbackSuccess, callbackError);
	}
	putProductFeedConfig(accountId, config, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}feature/${accountId}/productfeed/configuration`, config, callbackSuccess, callbackError);
	}
	postProductFeedConfig(accountId, productFeedMetaData, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}feature/${accountId}/productfeed/configuration/metadata`, productFeedMetaData, callbackSuccess, callbackError);
	}
}