
import React from 'react';
import { TypeValueTypes } from './TypesContainer/ParamsFilter';
// import { getValueOfCollationItem } from "../../utils/types";

export default function FilterCard({
	filter,
	nameFilter,
	ListFilterAvailable
}) {

	function getValueOfCollationItem(obj, filter) {
		let newObj = { ...obj };

		Object.keys(newObj).forEach(key => {
			if (newObj[key] === null) {
				delete newObj[key];
			}
		});

		const keyofValues = Object.keys(newObj)[0];

		if (keyofValues === 'booleanValue') {
			return <>
				{`${newObj[keyofValues]}`}
			</>;
		}
		if (keyofValues === 'numberRange') {
			const filterTypeName = ListFilterAvailable.forFiltering.flatMap(x => x.itemList).find(x => x.id === filter.collationId)?.params?.find(x => TypeValueTypes.includes(x.valueType))?.name;
			const filterValue = filter.params?.find(x => filterTypeName && x.name === filterTypeName)?.value;
			const typeValue = filterValue?.stringValues != null && filterValue?.stringValues.length > 0 && filterValue?.stringValues[0];


			if (typeValue === 'Date') {
				const getDateValue = (value) => value !== null && -1 < value && value < 1 ? value * 100 : value;
				const min = `${newObj[keyofValues].min !== null ? getDateValue(newObj[keyofValues].min) + ' days' : 'Not set'}`;
				const max = `${newObj[keyofValues].max !== null ? getDateValue(newObj[keyofValues].max) + ' days' : 'Not set'}`;
				return <>
					{`Min: ${min} and Max: ${max} `}
				</>;

			} else {
				const getPercentageValue = (value) => value !== null && !(-100 < value && value < 100) ? value / 100 : value;
				const min = `${newObj[keyofValues].min !== null ? Math.trunc(getPercentageValue(newObj[keyofValues].min * 100)) + ' %' : 'Not set'}`;
				const max = `${newObj[keyofValues].max !== null ? Math.trunc(getPercentageValue(newObj[keyofValues].max * 100)) + ' %' : 'Not set'}`;
				return <>
					{`Min: ${min} and Max: ${max} `}
				</>;
			}
		}
		if (keyofValues === 'dateUtcValues') {
			return <>
				{moment(newObj[keyofValues][0]).format('DD MMM YYYY ')}
			</>;
		}

		const valueMultiples = ['dateUtcValues', 'numberValues', 'stringValues'];
		if (valueMultiples.includes(keyofValues)) {
			return <>
				{newObj[keyofValues].join(', ')}
			</>;
		} else {
			return <>
				{newObj[keyofValues]}
			</>;
		}
	}

	function camelCaseToDisplay(str) {
		if (!str) {
			return;
		}
		const result = str.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
		const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
		return finalResult;
	}

	function getOperatorName(operator, filter) {
		if (filter.value.hasOwnProperty('booleanValue') && filter.value['booleanValue'] !== null) {
			const operators = [
				{
					value: 'Equals',
					label: 'Equals'
				},
				{
					value: 'NotEquals',
					label: 'Not equals'
				}
			];
			const operatorSelected = operators.find(op => op.value === filter.op);
			return 'is' + ' ' + operatorSelected.label + ' to ';
		}
		const op = operator?.toLowerCase();
		switch (op) {
			case 'top': return op + ' decile';
			case 'flop': return op + ' decile';
			case 'greater': return 'is greater than';
			case 'lower': return 'is lower than';
			case 'equals': return 'is equal to';
			case 'notequals': return 'is not equal to';
			case 'lowerorequals': return 'is lower or equal to';
			case 'greaterorequals': return 'is greater or equal to';
			case 'contains': return 'contains';
			case 'notcontains': return 'does not contain';
			case 'startswith': return 'starts with';
			case 'notstartswith': return 'does not start with';
			case 'endswith': return 'ends with';
			case 'notendswith': return 'does not end with';
			case 'like': return 'is like';
			case 'notlike': return 'is not like';
			default: return op;
		}
	}

	return (
		<div className="collection_sidebar_filter_line ellips">
			<span className="collection_sidebar_filter_name">{camelCaseToDisplay(nameFilter)}</span>
			{' '}
			<span className="collection_sidebar_filter_op">{getOperatorName(filter.op, filter)}</span>
			{' '}
			<span className="collection_sidebar_filter_val">{getValueOfCollationItem(filter.value, filter)}</span>
		</div>
	);
}
