import dictionary from './dictionary';

const initFeatureFlags = () => {
	//init storage
	const getInitialState = () => {
		const store = localStorage.getItem('ffl');
		if (!store) {
			return JSON.stringify(dictionary);
		} else {
			const storeContent = JSON.parse(store);
			const newStore = dictionary.map(x => {
				const inStore = storeContent.find(s => s.name === x.name);
				return inStore 
					? {...x, active:inStore.active} 
					: x;
			});
			return JSON.stringify(newStore);
		}		
	};
	localStorage.setItem('ffl', getInitialState());

	const hideOrDisplayFeatures = () => {
		let onPagefeatures = document.querySelectorAll('[data-ffl]');

		let onPageSingleFeature = [];
		for (var value of onPagefeatures.values()) {
			let isInArray = onPageSingleFeature.find(
				(elem) => elem.dataset.ffl === value.dataset.fflname
			);
			!isInArray && onPageSingleFeature.push(value);
		}

		let currentStorage = JSON.parse(localStorage.getItem('ffl'));

		let copyStorage = currentStorage.map((flag) => {
			const foundFeature = onPageSingleFeature.find(
				(feature) => feature.dataset.ffl === flag.name
			);
			return { ...flag, isOnPage: !!foundFeature };
		});
		copyStorage.filter(x => x.isOnPage).forEach((flag) => {
			let oldValue;
			if (!flag.active) {
				onPagefeatures.forEach((tag) => {
					if (tag.dataset.ffl === flag.name) {
						oldValue = tag.style.display;
						tag.style.display = 'none';
					}
				});
			} else {
				onPagefeatures.forEach((tag) => {
					if (tag.dataset.ffl === flag.name) {
						let resetValue = tag.style;
						tag.style = oldValue;
						oldValue = resetValue;
					}
				});
			}
		});
		
		localStorage.setItem('ffl', JSON.stringify(copyStorage));
	};
	//init component
	setInterval(() => {
		hideOrDisplayFeatures();
	}, 2000);
	// TO DO modifier la vriable onPage du store

	hideOrDisplayFeatures();
};

export default initFeatureFlags;
