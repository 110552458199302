import React from 'react';
import styles from "./Insight.module.css";
import { nFormat } from "../../util"

const Insight = ({
	label = '',
	value = "-",
	tendance = null,
	sign = "",
	loading = false,
	isError = false,
	isGray = false,
	isCompare = false,
	periode1,
	periode2,
	percentVariation,
	name = ''
}) => {
	const IconUp = (
		<i className="fas fa-long-arrow-alt-up"></i>
	)

	const IconDown = (
		<i className="fas fa-long-arrow-alt-down"></i>
	)

	const IconFlat = (
		<i className="fas fa-equals"></i>
	)

	let tendanceClass = "tendance_flat";
	let tendanceIcon = IconFlat;
	if (tendance > 0) {
		tendanceIcon = IconUp;
		tendanceClass = "tendance_up";
	} else if (tendance < 0) {
		tendanceIcon = IconDown;
		tendanceClass = "tendance_down";
	}

	const Tendance = (
		<>
			<span className={'analytics_insight_tendance ' + tendanceClass}>
				{tendanceIcon}
				{`${tendance} ${sign}`}
			</span>
		</>
	)
	const TendanceComparison = () => {
		return <>
			<span className={'analytics_insight_tendance l ' + tendanceClass}>
				{`${parseFloat(tendance).toFixed(1)} %`}
			</span>
		</>
	}

	const Sign = (
		<span className="analytics_insight_unit">{`${sign}`}</span>
	)

	const Loading = () => (
		<div className="mask">
			<span className="wheel"></span>
		</div>
	)
	const Dom = () => {

		if (isCompare && periode1) {
			return <>
				<p className={styles.label}>{label}</p>
				<div className={styles.valueContainer}>
					{(TendanceComparison())}
				</div>
				<div className={styles.valueContainer}>
					<p className={styles.value_min}>
						{typeof periode1.value === 'number' ? nFormat(periode1.value, 1) : periode1.value}
						{' / '}
						{typeof periode2.value === 'number' ? nFormat(periode2.value, 1) : periode2.value}
						<span className="analytics_insight_unit"> {periode2.sign}</span>
					</p>
				</div>
			</>
		} else {
			return <>
				<p className={styles.label}>{label}</p>
				<div className={styles.valueContainer}>
					<p className={styles.value}>{typeof value === 'number' ? nFormat(value, 1) : value}</p>
					{((typeof tendance === 'number') && Tendance) || (sign && sign !== "" && Sign)}
				</div>
			</>
		}
	}

	return (
		<div className={(isGray ? 'by_grey' : '') + ' analytics_insight'}>
			{loading && !isError && <Loading />}
			{isCompare && loading && !isError && <Loading />}
			{!loading && !isError && Dom()}
			{isError && <span>-</span>}
		</div>
	)
}

Insight.displayName = "Insight"

export default Insight;