import React,{useState, useEffect} from 'react';
import { CampaignsContext } from '../../context/index.js';
import InputCustom from '../../../../Components/InputCustom';

export default function SkuContainer() {
	const {
		getCurrentVariation,
		handleChangeElementsInCollection
	} = CampaignsContext();

	const activeVariation = getCurrentVariation() || {};
	
	const [skuModifyValue, setSkuModifyValue] = useState('');
	const handleChange = (event) => {
		const textAreaValue =  event.target.value;
		setSkuModifyValue(textAreaValue);
		const elementsArray = textAreaValue.split(',').map((x: string) => x.trim());
		handleChangeElementsInCollection(elementsArray);
	};
	useEffect(()=>{
		if(activeVariation){
			const values = activeVariation.InCollection.TargetedCollectionElementIds;
			if(values){
				const splits = values.join(',') ;
				setSkuModifyValue(splits);
			}
		}

	},[]);
	return (
		<InputCustom
			type="textarea"
			value={skuModifyValue}
			onChange={handleChange}
			placeholder={'Example: sku1, sku2'}
			fullWidth={true}
			rows={10}
		/>
	);
}
