import HttpServices from './HttpServices';

export default class PerformanceTrackingCampaignServices {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;

	constructor(accountId: string, $http: any) {
		this.HttpServices = new HttpServices($http);
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	
	getCampaignInfos(campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/performance-tracking-campaign/${campaignId}`, callbackSuccess, callbackError);
	}

	getCampaignList(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/performance-tracking-campaign`, callbackSuccess, callbackError);
	}

	updateCampaign(campaignId, {name, description}, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/performance-tracking-campaign/${campaignId}`, {name, description}, callbackSuccess, callbackError);
	}
}
