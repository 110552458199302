import React, { useEffect, useState } from 'react';
import { abTestsDashboardContext } from './context';
import {
	getFormattedNumber,
	computeDurationInDaysFromToday,
	getStatusDisplayName,
	getDashboardBaselineComparison,
	getCampaignConfidence,
	getCampaignConfidenceIsBest,
	getCampaignIsKeepTesting,
	getBestVariation,
	getBestVariationWithoutOriginal,
	getObjectiveType
} from '../utils';

import ABTestsItemInfo from './ABTestItemInfo';
import ABTestsStatCard from './ABTestStatCard';
import styles from './ABTestDashboard.module.css';
import Dropdown from '../../../Components/Dropdown';
import IconBtn from '../../../Components/IconBtn';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import Confirm from '../../../Components/Confirm';
import Picto from '../../../Components/Picto';
import listStyles from './ABTestDashboard.module.css';

import { I18nextProvider,Trans,useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import AbtestCampaignStatus from './AbTestCampaignStatus';

export default function ABTestsItemPending({ campaign }) {

	const idTooltip= uuidv4();
	const [t, i18n] = useTranslation('common');
	const {
		modalLabelsCampaignisOpen, 
		setModalLabelsCampaignIsOpen,
		ka ,
		$routeParams
		,setcampaignSelected,
		handleChangeCampaignSelected,
		deleteCampaignById,
		accountId,
		needRefetch,
		setneedRefetch,
		changeStatusCampaign,
		systemServices
	} = abTestsDashboardContext();

	const getObjectiveLabels = (obj) => {
		const type = getObjectiveType(obj);
		let tmp = {value: '', rate: ''};
		if (type == 'conversions') {
			tmp.value = 'Conversions';
			tmp.rate = 'Conversion rate';
		} else if (type == 'interactions') {
			tmp.value = 'Interactions';
			tmp.rate = 'Interaction rate';
		}
		return tmp;
	};

	const objectiveLabels = getObjectiveLabels(campaign.objectiveIndicator);
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [campaignIdToDelete, setcampaignIdToDelete] = useState();
	const isKeepTesting = getDashboardBaselineComparison(campaign.variations).includes(false) ;

	const bestVariation = getBestVariation(campaign.variations);
	const bestVariationWithoutOriginal = getBestVariationWithoutOriginal(campaign?.variations);
	const campaignConfidence = getCampaignConfidence(campaign?.variations);
	const campaignConfidenceIsBest = getCampaignConfidenceIsBest(campaignConfidence);
	const campaignIsKeepTesting = getCampaignIsKeepTesting(campaign?.variations);

	const getCanStopCampaign = (campaign) => {
		if (!campaign) return false;
		if (campaign.status == 'test') return false;
		if (campaign.status == 'stopped') return false;
		return true;
	};
	const getCanStartCampaign = (campaign) => {
		if (!campaign) return false;
		if (campaign.status == 'ongoing') return false;
		return true;
	};
	const getCanArchiveCampaign = (campaign) => {
		if (!campaign) return false;
		if (campaign.status == 'stopped') return true;
		return false;
	};
	const getCanPromoteCampaign = (campaign) => {
		if (!campaign) return false;
		if (campaign.status == 'stopped') return true;
		return false;
	};
	const getCanShowStats = (campaign) => {
		if (!campaign) return false;
		if (!campaign.status == 'test' && !campaign.status == 'stop') return true;
		if (!campaign.data.sessionsCount || campaign.data.sessionsCount <= 0) return false;
		return true;
	};
	const variationOriginalIsWinner = bestVariation.name === campaign.variations.baseline.name;
	const message = !variationOriginalIsWinner ? bestVariation.recommendation.baselineComparison : ''; 

	const threshold = .07;
	const getHasWinner = (campaign) => {
		if (!campaign) return false;
		if (!bestVariation) return false;
		if(campaignIsKeepTesting) return false;
		const probabilityToBeBest = (variationOriginalIsWinner ? bestVariation?.probabilityToBeBest : bestVariation?.recommendation?.comparisonData?.probabilityToBeBest) || 0;
		if (probabilityToBeBest < threshold) return false;
		return true;
	};
	if (!campaign) return <></>;

	const {status, startDate, data} = campaign;
	const canStopCampaign = getCanStopCampaign(campaign);
	const canStartCampaign = getCanStartCampaign(campaign);

	const canArchiveCampaign = getCanArchiveCampaign(campaign);
	const canPromoteCampaign = getCanPromoteCampaign(campaign);
	const canShowStats = getCanShowStats(campaign);
	const hasWinner = getHasWinner(campaign);

	const [modificationCampaign, setmodificationCampaign] = useState(<></>);

	const statusModificationTorender = () => {
		
		const isClassicEditor = !campaign.editor || campaign.editor === 'classic';
		if (isClassicEditor) {
			let url = '/Campaigns/Create/' + campaign.id;
			if (ka) {
				url += '?ka=' + $routeParams.ka + '&ku=' + $routeParams.ku;
			}
			setmodificationCampaign(
				<ListboxItem
					icon="fas fa-pen"
					message="Edit campaign"
					href={url}
				/>
			);
		}
		else {
			const url = '/Campaigns/Editor/' + campaign.id;
			const query = [];
			query.push('from=ABTests/Dashboard/Pending');
			if (ka) {
				query.push('ka=' + $routeParams.ka);
				query.push('ku=' + $routeParams.ku);
			}

			const getEditCampaignLabel = () => {
				const def = t('CampaignItem.editDesign');
				if (!campaign.editor) return def;
				if (campaign.editor == 'patch/code') return t('CampaignItem.editCodePatch');
				if (campaign.editor == 'patch/graph') return t('CampaignItem.editGraphic');
				return def;
			};

			const getEditCampaignIcon = () => {
				const def = 'fas fa-palette';
				if (!campaign.editor) return def;
				if (campaign.editor == 'patch/code') return 'fas fa-code';
				if (campaign.editor == 'patch/graph') return 'fas fa-fill-drip';
				return def;
			};

			const toQueryString = (moreQuery) => '?' + [...query, ...moreQuery].join('&');
			setmodificationCampaign(
				<>
					<ListboxItem
						href={url + toQueryString(['edit=format'])}
						icon={getEditCampaignIcon()}
						message={getEditCampaignLabel()}
					/>
					<ListboxItem
						href={url + toQueryString(['edit=triggers'])}
						icon="fas fa-bolt"
						message={t('CampaignItem.EditDisplayRules')}
					/>
					<ListboxItem
						href={url + toQueryString(['edit=info'])}
						icon="fas fa-info-circle"
						message={t('CampaignItem.EditCampaign')}
					/>
				</>
			);
		}
	};

	function openTheModalOfLabelsCampaign () {
		setModalLabelsCampaignIsOpen(true);
		setDropdownIsOpen(false);
		setcampaignSelected(campaign);
		handleChangeCampaignSelected(campaign.labels);
	}
	function deleteCpg (){
		deleteCampaignById(
			accountId,
			campaignIdToDelete.id,
			(success) => {
				setneedRefetch(!needRefetch);
				systemServices.showSuccess(
					t('campaignsList.successDelete')
				);
			},
			(error) => {
				systemServices.showError(
					t('campaignsList.errorDelete')
				);
			}
		);}
	const changeStatusOfCampaign = (bool) => {
		changeStatusCampaign(accountId, campaign.id, bool,
			success => {
				setneedRefetch(!needRefetch);
				systemServices.showSuccess(t('ReportingCampaignContainer.campaignSuccessfully', {
					verb : !bool ? 'activated' : 'desactivated',
					verbFr : !bool ? 'activé' : 'désactivé',
				}));
			},
			error => {
				
				systemServices.showError(
					t('ReportingCampaignContainer.errorWhile', {
						sentence : !bool ? 'activation of ' : 'desactivation of ',
						sentenceFr : !bool ? 'activation de' : 'désactivation de',
					}
					));
			});
	};
	
	useEffect(()=>{
		statusModificationTorender();
	},[canStartCampaign]);

	return (
		<>
			<Confirm
				title={t('experiment.doYouWantToDelete')}
				text={campaignIdToDelete?.name}
				confirmCallback={deleteCpg}
				confirmColor='alert'
				confirmText={t('actions.delete')}
				cancelText={t('actions.cancel')}
				onClose={() => setcampaignIdToDelete()}
				isOpen={!!campaignIdToDelete}
				width={470}
			/>
			<div className={'table_row table_body_row ' + styles.table_body_row}>
				<div className={'table_col ' + styles.table_col}>
					<div className='flex'>
						<div className='flex_item_fix'>
							<div className={listStyles.picto}>
								{canShowStats &&
									<>
										{!campaignIsKeepTesting && hasWinner && campaignConfidenceIsBest &&
											<Picto
												icon="fas fa-trophy"
												color='success'
												size='l'
												tooltip='We have a clear winner!'
											/>
										}
										{!campaignIsKeepTesting && hasWinner && !campaignConfidenceIsBest &&
											<Picto
												icon="fas fa-trophy"
												color='success'
												size='l'
												tooltip='We have a winner!'
											/>
										}
										{campaignIsKeepTesting &&
											<Picto
												icon="fas fa-clock"
												color='purple'
												size='l'
												tooltip={'We need more time and data to clearly identify the winning variation.'}
											/>
										}
									</>
								}
								{!canShowStats &&
									<Picto
										icon="fas fa-pause"
										color='blue'
										size='l'
										tooltip={'Launch the experiment to start collecting data'}
									/>
								}
							</div>
						</div>
						<div className='flex_item_full'>
							<ABTestsItemInfo
								campaign={campaign}
								hasWinner={hasWinner}
								openModalCampaignLabels={openTheModalOfLabelsCampaign}
								modalLabelsCampaignisOpen={modalLabelsCampaignisOpen}
								message={message}
							/>
						</div>
					</div>
				</div>
				<div className={'table_col ' + styles.table_col}>
					<div>
						<AbtestCampaignStatus campaign={campaign} />
						{/* TODO : si la campagne est scheduled, afficher plutôt la date de début */}
						{status && status == 'ongoing' &&
							<div className={styles.value_desc}>
								{computeDurationInDaysFromToday(startDate)} days
							</div>
						}
					</div>
				</div>
				<div className={'table_col ' + styles.table_col}>
					{canShowStats ?
						<div>
							<div className={styles.value}>
								{getFormattedNumber(data.sessionsCount)}
							</div>
							<div className={styles.value_desc}>
								Sessions
							</div>
						</div>
						:
						<div className={styles.value_wrapper_disabled}>
							<div className={styles.value}>
								0
							</div>
							<div className={styles.value_desc}>
								Sessions
							</div>
						</div>
					}
				</div>
				<div className={'table_col ' + styles.table_col}>
					{canShowStats ?
						<div className={styles.value_wrapper}>
							<div className={styles.value}>
								{getFormattedNumber(data.objectivesCount)}
							</div>
							<div className={styles.value_desc}>
								{objectiveLabels.value}
							</div>
						</div>
						:
						<div className={styles.value_wrapper_disabled}>
							<div className={styles.value}>
								0
							</div>
							<div className={styles.value_desc}>
								{objectiveLabels.value}
							</div>
						</div>
					}
				</div>
				<div className={'table_col ' + styles.table_col}>
					{canShowStats &&
						<ABTestsStatCard
							number={bestVariationWithoutOriginal?.recommendation?.comparisonData?.objectiveVariation}
							forceSign={true}
							variationName={bestVariationWithoutOriginal?.name}
						/>
					}
				</div>
				<div className={'table_col ' + styles.table_col}>
					{canShowStats &&
						<ABTestsStatCard 
							number={bestVariationWithoutOriginal?.recommendation?.comparisonData?.probabilityToPerformBetterThanBaseline} 
							variationName={bestVariationWithoutOriginal?.name}
						/>
					}
				</div>
				
				<div className={'table_col ' + styles.table_col}>
					<Dropdown
						isOpen={dropdownIsOpen}
						toggle={(s) => setDropdownIsOpen(s)}
						button={
							<IconBtn
								icon='fas fa-ellipsis-v'
								tooltip='More actions'
							/>
						}
					>
						<Listbox>
							{modificationCampaign}
							<li className='hr' />
							{campaign.isStopped ?
								<ListboxItem
									message={t('ReportingCampaignContainer.start')}
									icon="fas fa-play"
									onClick={() => changeStatusOfCampaign(false)}
								/>
								:
								<ListboxItem
									message={t('ReportingCampaignContainer.pause')}
									icon="fas fa-pause"
									onClick={() => changeStatusOfCampaign(true)}
								/>
							}
							
							{canPromoteCampaign &&
								<ListboxItem
									message="Promote as personalization"
									icon="fas fa-medal"
									onClick={() => setDropdownIsOpen(false)}
								/>
							}
							{canArchiveCampaign &&
								<ListboxItem
									message="Archive"
									icon="fas fa-archive"
									onClick={() => setDropdownIsOpen(false)}
								/>
							}
							<li className='hr' />
							<ListboxItem
								message="Edit labels"
								icon="fas fa-tag"
								onClick={() => openTheModalOfLabelsCampaign(campaign)}
							/>
							<li className='hr' />
							<ListboxItem
								message="Delete"
								icon="trashfas fa-"
								onClick={() => {
									setcampaignIdToDelete(campaign); 
									setDropdownIsOpen(false);
								}}
							/>
						</Listbox>
					</Dropdown>
				</div>
			</div>
		</>
	);
}