import React, { useState, useContext } from 'react';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import Btn from '../../../../Components/Btn';
import Modal from './../../../../Components/Modal';
import ModalHeader from './../../../../Components/ModalHeader';
import ModalBody from './../../../../Components/ModalBody';
import ModalFooter from './../../../../Components/ModalFooter';
import Switch from './../../../../Components/Switch';
import Checkbox from './../../../../Components/Checkbox';
import InputCustom from './../../../../Components/InputCustom';
import useEventBroker from '../../../../Hooks/useEventBroker';

export default function ZoneCtaModal({ zone, saveCallback }){
	const {
		recordedLastCtaValue,
		setRecordedLastCtaValue
	} = useContext(CreateBusinessOperationContext);
    
	const { subscribe } = useEventBroker();

	const [modalIsOpened, setModalIsOpened] = useState(false);
	const [isErrorCta, setIsErrorCta] = useState(false);	
	const [canCancel, setCanCancel] = useState(false);
	const [canClose, setCanClose] = useState(false);
	const [isCreation, setIsCreation] = useState(false);
	const [ctaToSave, setCtaToSave] = useState({
		cta: '',
		withcta: false,
		target: '',
		alt: ''
	});

	const isAnUrl = (url) => {
		if (url.match(/http?:|https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#/=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
			return true;
		} else {
			return false;
		}
	};
	
	const loadCtaToSave = (inputCta) => {
		if (inputCta) {
			let cta = { ...inputCta };
			if (cta.target == '_blank') {
				cta.targetsBlank = true;
				setCtaToSave(cta);
			}			
			else {
				cta.targetsBlank = false;
				setCtaToSave(cta);
			}
		} else {
			setCtaToSave({
				cta: '',
				withcta: false,
				target: '',
				targetsBlank: false,
				alt: ''
			});
		}
	};

	subscribe('evt/businessoperations/editZoneCtaRequested', e => {
		const data = e.detail;
		if (zone.zoneId === data.zone.zoneId){
			loadCtaToSave(data.cta);
			setIsCreation(data.isCreation);
			setCanClose(!data.isCreation);
			setCanCancel(!data.isCreation);
			setModalIsOpened(true);
		}
	}, []);


	const handleSwitchWithCta = (e) => {
		setIsErrorCta(false);

		let prevState = { ...ctaToSave };
		if (prevState.withcta)
		{
			setRecordedLastCtaValue({ ...prevState });
		}
		prevState.withcta = !prevState.withcta;
		if (prevState.withcta == false) {
			prevState.target = '';
			prevState.cta = '';
		}
		else if (recordedLastCtaValue){
			prevState.target = recordedLastCtaValue.target;
			prevState.cta = recordedLastCtaValue.cta;
		}
		setCtaToSave(prevState);
	};

	const handleChangeAlt = (e) => {		
		const value = e.target.value;
		let prevState = { ...ctaToSave };
		prevState.alt = value;
		setRecordedLastCtaValue(prevState);
		setCtaToSave(prevState);
	};

	const handleChangeCtaTarget = (e) => {
		setIsErrorCta(false);

		const value =  e.target.checked;

		let prevState = { ...ctaToSave };
		if (value == true) {
			prevState.target = '_blank';
			prevState.targetsBlank = true;
		} else {
			prevState.target = '_self';
			prevState.targetsBlank = false;
		}
		setRecordedLastCtaValue(prevState);
		setCtaToSave(prevState);
	};

	const handleChangeCta = (e) => {
		setIsErrorCta(false);
		
		const value = e.target.value;
		let prevState = { ...ctaToSave };
		prevState.cta = value;
		setRecordedLastCtaValue(prevState);
		setCtaToSave(prevState);
	};


	const save = () => {
		if (ctaToSave.withcta == true && !isAnUrl(ctaToSave.cta)) {
			setIsErrorCta(true);
			return false;
		}
		if (ctaToSave.withcta == false) {
			ctaToSave.target = '';
		}
		else{
			if (ctaToSave.targetsBlank == false) {
				ctaToSave.target = '_self';
			} else if (ctaToSave.targetsBlank == true) {
				ctaToSave.target = '_blank';
			}	
		}
		saveCallback(ctaToSave);
		setRecordedLastCtaValue(ctaToSave);
		return true;
	};

	const handleClose = () => {
		if (isCreation){
			//save()
		}
		setModalIsOpened(false);
	};

	const handleSave = () => {
		const res = save();
		if (res) {
			setModalIsOpened(false);
		}
	};

	return ( 
		<>	
			{
				<Modal
					isOpen={modalIsOpened}
					width={500}
					noClose={!canClose}
					onClose={handleClose}
				>
					<ModalHeader title='Banner settings' />
					<ModalBody>
						<div className="form_block">
							<Switch
								value={ctaToSave.withcta}
								name="withcta"
								checked={ctaToSave.withcta}
								onChange={handleSwitchWithCta}
								description='Redirect to a page when you click on this banner.'
								labelIsStrong={true}
								label="Activate redirection"
								size='s'
								position='right'
							/>
						</div>

						{ctaToSave && ctaToSave.withcta &&
							<>
								<div className="form_block">
									<InputCustom
										name="cta"
										value={ctaToSave ? ctaToSave.cta : ''}
										placeholder='https://'
										onChange={handleChangeCta}
										fullWidth={true}
										isAnError={isErrorCta ? 'Please enter a valid url (example: https://domain.com)' : ''}
									/>
								</div>
								<div className="form_block">
									<Checkbox
										name="target"
										checked={ctaToSave.target && ctaToSave.targetsBlank}
										value={ctaToSave.targetsBlank}
										onChange={handleChangeCtaTarget}
										label='Open in a new tab'
									/>
								</div>
							</>
						}

						<hr />

						<InputCustom
							label="Alternative text (alt)"
							description="Alternative text describes the image to web crawlers and screen readers"
							name="alt"
							value={ctaToSave.alt ?? ''}
							onChange={handleChangeAlt}
							fullWidth={true}
						/>
					</ModalBody>
					<ModalFooter
						primaryAction={
							<Btn
								onClick={handleSave}
								message="Ok"
								disabled={!(ctaToSave && ctaToSave.withcta == true && ctaToSave.cta && ctaToSave.cta.length > 0 || !ctaToSave.withcta)}
							/>
						}
						secondaryAction={canCancel &&
							<Btn
								onClick={(e) => { setModalIsOpened(false); }}
								message="Cancel"
								color="secondary"
								style="ghost"
							/>
						}
					/>
				</Modal>
			}
		</>
	);
}   