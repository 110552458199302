import React, {useState} from 'react';
import ModalHeader from '../../Components/ModalHeader';
import ModalBody from '../../Components/ModalBody';
import ModalFooter from '../../Components/ModalFooter';
import Modal from '../../Components/Modal';
import InputCustom from '../../Components/InputCustom';
import Btn from '../../Components/Btn';
import {useTriggerContext} from './TriggerContext';
import {updateNewNameForAllTriggersGroup} from './Utils';
import {useTranslation} from 'react-i18next';
export default function RenameFavoriteRuleModal({
	rule,
	isEditingExistingRule,
	modalIsOpen,
	setModalIsOpen
}) {
	const [t, i18n] = useTranslation('common');
	const {
		changeNameOfFavoriteRule,
		saveTargetingRule,
		editExistingRule
	} = useTriggerContext();

	const [inputName, setInputName] = useState(rule.name);

	const confirmNewName = () => {
		if (isEditingExistingRule) {
			changeNameOfFavoriteRule(inputName, rule);
		}
		if (!isEditingExistingRule) {
			let ruleToSave = {...rule};
			ruleToSave.name = inputName;
			editExistingRule(updateNewNameForAllTriggersGroup(ruleToSave, inputName));
			saveTargetingRule(ruleToSave);
		}
		setModalIsOpen(false);
	};

	return (
		<Modal
			isOpen={modalIsOpen}
			onClose={() => setModalIsOpen(false)}
			noClose={true}
			width={380}
		>
			{isEditingExistingRule ?
				<>
					<ModalBody>
						<InputCustom
							type="text"
							value={inputName}
							placeholder={t('triggers.rename')}
							onChange={(e) => setInputName(e.target.value)}
							fullWidth={true}
							label={t('triggers.segmentName')}
							labelClassName="s_16 mb_8"
							autoFocus={true}
							selectOnFocus={true}
							onEnter={() => {confirmNewName(); setModalIsOpen(false);}}
						/>
					</ModalBody>
					<ModalFooter
						hasBorder={false}
						primaryAction={
							<Btn
								message="OK"
								onClick={() => confirmNewName()}
							/>
						}
						secondaryAction={
							<Btn
								message={t('triggers.Cancel')}
								style="ghost"
								color="secondary"
								onClick={() => setModalIsOpen(false)}
							/>
						}
					/>
				</>
				:
				<>
					<ModalHeader
						title={t('triggers.saveAsFavorite')}
						hasBottomPadding={false}
						hasBorder={false}
					/>
					<ModalBody>
						<InputCustom
							type="text"
							value={inputName}
							placeholder={t('triggers.newName')}
							onChange={(e) => setInputName(e.target.value)}
							fullWidth={true}
							label={t('triggers.segmentName')}
							autoFocus={true}
							selectOnFocus={true}
							onEnter={() => setModalIsOpen(false)}
						/>
					</ModalBody>
					<ModalFooter
						hasBorder={false}
						primaryAction={
							<Btn
								message={t('triggers.Save')}
								onClick={() => confirmNewName()}
							/>
						}
						secondaryAction={
							<Btn
								message={t('triggers.Cancel')}
								style="ghost"
								color="secondary"
								onClick={() => setModalIsOpen(false)}
							/>
						}
					/>
				</>
			}
            
		</Modal>
	);
}