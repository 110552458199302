import React, { useState, useEffect } from 'react';
import Insight from '../Insight/Insight';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import { useInteractionContext } from '../../context/InteractionContext';

export default function InteractionTotalsContainer({ interactionServices }) {

	const { selectedTenant } = useAnalyticsContext();

	const {
		reportTypeSelected,
		navFilter,
		sourceType,
		filterExactValue,
		filter2ExactValue,
		filter2,
		isExactMatch,
		selectedCategory,
		utmDimensionsSelected,
		needRefreshForUtmFilter,
		deviceSelected,
		excludeFilter,
		categoryIsExactMatch,
		hasValues
	} = useInteractionContext();

	const [totals, setTotals] = useState({});

	const refreshTotals = () => {
		setTotals({
			uniqueCount: { loading: true, isError: false },
			count: { loading: true, isError: false },
			distinctCount: { loading: true, isError: false }
		});
		const categoryName = selectedCategory === 'Uncategorized' ? '' : selectedCategory ? selectedCategory : '';
		const isUncategorized = selectedCategory === 'Uncategorized';
		interactionServices.getInteractionMetricsSumsOverTime(
			deviceSelected,
			selectedTenant,
			navFilter,
			reportTypeSelected,
			filterExactValue,
			isExactMatch,
			filter2,
			filter2ExactValue,
			false,
			sourceType,
			categoryName,
			isUncategorized,
			utmDimensionsSelected,
			excludeFilter,
			categoryIsExactMatch,
			hasValues,
			data => {
				const { UniqueCount, Count, DistinctCount } = data;

				const insightData = {
					uniqueCount: {
						value: UniqueCount,
						tendance: null,
						loading: false,
						isError: false,
						sign: null
					},
					count: {
						value: Count,
						tendance: null,
						loading: false,
						isError: false,
						sign: null
					},
					distinctCount: {
						value: DistinctCount,
						tendance: null,
						loading: false,
						isError: false,
						sign: null
					}
				};

				setTotals({ ...insightData });
			},
			(error) => {
				console.log(error);
				setTotals({
					uniqueCount: { loading: false, isError: true },
					count: { loading: false, isError: true },
					distinctCount: { loading: false, isError: true }
				});
			});
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			refreshTotals();
		}, 500);
	return () => {
		clearTimeout(timer);
	};
	}, [
		selectedTenant,
		navFilter,
		reportTypeSelected,
		filterExactValue,
		isExactMatch,
		filter2,
		filter2ExactValue,
		sourceType,
		selectedCategory,
		needRefreshForUtmFilter,
		deviceSelected,
		excludeFilter,
		isExactMatch,
		categoryIsExactMatch,
		hasValues
	]);

	return (
		<div className="analytics_insight_group">
			<Insight label="Unique Count" name='uniqueCount' {...totals.uniqueCount} />
			<Insight label="Count" name='count' {...totals.count} />
			<Insight label="Distinct Count" name='distinctCount' {...totals.distinctCount} />
		</div>
	);

}