import React, {Fragment} from 'react';
import CampaignListParameters from './CampaignListParameters';
import { Trans, useTranslation } from 'react-i18next';
export default function SelectCreationModeContainer({ listOfConfiguRationAccount, setAccountCurrentTab, setIsCreationMode, checkIfHasExistingConfig }) {

	const selectFiltered = listOfConfiguRationAccount && CampaignListParameters.filter(elem => listOfConfiguRationAccount.includes(elem.compare));
	const [t, i18n] = useTranslation('common');
	return (
		<Fragment>
			<div className="section section_grey l has_top_border">
				<div className="email_provider flex">
					<a className="icon_btn icon_btn_color"
						title="Back"
						onClick={() => { setAccountCurrentTab(); checkIfHasExistingConfig(); setIsCreationMode(false); }}>
						<i className="fas fa-arrow-left"></i>
					</a>
					<div className="email_provider_name">{t('AccountParametersContainer.whatKind')}</div>
				</div>
			</div>
			<div className="section l has_top_border">
				<div className="select_list_tooling_Container">
					<ul className="select_list_tooling">
						{
							selectFiltered.map((opt, i) =>
								<li key={i} onClick={(e) => { setAccountCurrentTab(opt.id); setIsCreationMode(true); }}>{opt.label}</li>
							)
						}
					</ul>
				</div>
			</div>
		</Fragment>
	);
}
