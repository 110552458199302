import React, { useState, useEffect } from 'react';
import Btn from '../../../../Components/Btn';
import AccountServices from '../../../../Services/AccountServices';
import InstallationCode from '../InstallationCode/InstallationCode';

const CheckingAccount = ({ configService, startCallback, $rootScope, $http, accountId }) => {

	const [accountIsActive, setAccountIsActive] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [clientKey, setClientKey] = useState();
	const [hasNoPermissionOnAnyTenant, sethasNoPermissionOnAnyTenant] = useState(false);
	const isAnAdmin = $rootScope.$$childHead.IsAnAdmin();
	const accountService = new AccountServices($http);

	useEffect(() => {
		if (accountId == 'dfe30d81-d063-4e5a-a999-2bcf48454dd4') startCallback({
			tenants:[{tenant:'', dataStartDateUtc:moment()}, {tenant:'tenant', dataStartDateUtc:moment()}],            
			dataStartDateUtc: moment()
		});
		else{
			check();
		}
	}, []);

	const check = () => {
		const cb = (active) => 
		{
			setAccountIsActive(active);
			setIsLoading(false); 
		}; 
		configService.getAccountConfig(data => {
			cb(true);
			sethasNoPermissionOnAnyTenant(data.hasNoPermissionOnAnyTenant);
			startCallback(data);
		}, () => cb(false));
	};

	const activate = () => {
		setIsLoading(true);

		accountService.getAccount(accountId, (data) => {
			configService.createAccountConfig(data.ClientKey, () => {
				setClientKey(data.ClientKey);
				setIsLoading(false);
			}, () => setIsError(true));
		}, () => setIsError(true));
       
	};

	const emptyScreen = (message) =>
		(
			<div className="empty_page_flex vscroll">
				<section className="section">
					<img src="/Assets/empty_analytics.svg" className="empty_page_img" styles={{width: '260px'}} />
					<div className="empty_page_title">{message}</div>
				</section>
			</div>
		);

	return (    
		<>
			{isLoading && !isError && 
    <div style={{ height: '380px' }}>
    	<div className="spinner_overlay">
    		<div className="spinner">
    			<div className="rect1"></div>
    			<div className="rect2"></div>
    			<div className="rect3"></div>
    			<div className="rect4"></div>
    			<div className="rect5"></div>
    		</div>
    	</div>
    </div>
			}
			{!isLoading && !accountIsActive && !isError && !clientKey &&
    <>
    	{emptyScreen('Analytics is not active for this account')}
    	{isAnAdmin && <Btn onClick={activate}>Activate account</Btn> }
    </>
			}
			{!isLoading && !isError && (clientKey && clientKey !== '') &&
        <>
        	<InstallationCode beyableKey={clientKey}></InstallationCode>
        	<div><Btn onClick={check}>Open Analytics</Btn></div>
        </>
			}
			{!isLoading && accountIsActive && !isError && !hasNoPermissionOnAnyTenant &&
        <>
        	{emptyScreen('Analytics is active for this account')}
        </>
			}
			{isError &&
        <>
        	{emptyScreen('Cannot activate analytics for this account. Please contact the support.')}
        </>
			}
	{hasNoPermissionOnAnyTenant && !isLoading && accountIsActive && !isError &&<>
				{emptyScreen('Unfortunately you do not have permission to access the BEYABLE Analytics dashboard, please ask your BEYABLE Analytics administrator')}</>
	}

		</>);
};

CheckingAccount.displayName = 'CheckingAccount';
export default CheckingAccount;