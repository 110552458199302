import React, { useState, useEffect } from "react";
import { react2angular } from 'react2angular';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import '../../ReportingCampaign/ReportingCampaignGlobal.css';
import formatMoment from '../../../Constants/FormatsMoments';
import MomentConstants from '../../../Constants/MomentConstants';
import SystemServices from '../../../Services/SystemServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import EmailingPerformancesServices from '../../../Services/EmailingPerformanceServices';
import InformationCampaign from './InformationsCampaign';
import ChartRemarketingContainer from './ChartRemarketingContainer';
import Dropdown from '../../../Components/Dropdown';
import Btn from '../../../Components/Btn';
import {Article} from '../../../Components/Article/Article';
import MainInformationsContainer from './Results/MainInformationsContainer';
import CampaignItem from './Results/CampaignItem';
import Link from "../../../Components/Link";
export default function EmailRemarketingContainer(props) {
    const { formatDateToLocal } = formatMoment;
    const [dateRange, setdateRange] = useState({
        startDate: null,
        endDate: null
    });
    const { startDate, endDate } = dateRange;
    const handleOnDateChange = (startDate, endDate) => {
        setdateRange(startDate, endDate)
    }
    const [isReloading, setIsRealoading] = useState(true);
    const [focusedInput, setFocusedInput] = useState(null);
    const emailingPerformancesServices = new EmailingPerformancesServices(props.$http, props.$routeParams);
    const systemServices = new SystemServices(props.$rootScope);
    const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
    const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
    const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
    const [campaignId, setCampaignId] = useState(props.$location && props.$location.$$search.id);
    const [granularity, setGranularity] = useState();

    const [frequencyDropdownIsOpen, setFrequencyDropdownIsOpen] = useState(false);
    const [dataCampaign, setdataCampaign] = useState()
    const [series, setseries] = useState([])
    const [seriesPeriod, setseriesPeriod] = useState([])
    const today = moment();
    const [momentStartDate, setMomentStartDate] = useState();
    const [momentEndDate, setMomentEndDate] = useState();
    const [listOfCampaignsAccount, setListOfCampaignsAccount] = useState();
    const [dataFiltered, setDataFiltered] = useState()
    const [noData, setNoData] = useState()
    const [valueInSearchBar, setValueInSearchBar] = useState("");
    const getMainInformationsForTheCampaign = () => {
        emailingPerformancesServices.getCampaignsInfoEmailRemarketing(accountId, campaignId,
            data => {
                const endDateFormat = moment(data.endDate).startOf('day').isAfter(today) ?
                    moment().startOf('day') : formatDateToLocal(data.endDate).startOf('day');
                const datesForTheCampaignFetching = {
                    startDate: formatDateToLocal(data.startDate),
                    endDate: formatDateToLocal(endDateFormat)
                }
                setdateRange(datesForTheCampaignFetching)
                setdataCampaign(data)
            },
            error => {
                console.error("error", error);
                setIsRealoading(false)
                systemServices.showError("An error occured while fetching data")
            });
    }

    useEffect(() => {
        if (campaignId) {
            setIsRealoading(true)
            getMainInformationsForTheCampaign();
        }
    }, [campaignId])
    const changeGranularity = () => {
        let nbDays = moment.duration(endDate.diff(startDate)).asDays() + 1
        if (!endDate.isValid() || !startDate.isValid()) {
            return
        } else {
            if (nbDays >= 90) {
                setGranularity("month")
                changeRangeDate()
            }
            else if (nbDays >= 29) {
                setGranularity('week')
                changeRangeDate()
            }
            else {
                setGranularity('day')
                changeRangeDate()
            }
        }
    }
    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            changeGranularity();
        }
    }, [endDate])
    const changeRangeDate = (event) => {
        setMomentStartDate(moment(startDate, MomentConstants.MOMENT_API_FORMAT));
        setMomentEndDate(moment(endDate, MomentConstants.MOMENT_API_FORMAT));
    }
    const getStatsForCampaign = () => {

        emailingPerformancesServices.getStatsEmailRemarketing(accountId, campaignId, startDate.format(MomentConstants.MOMENT_API_FORMAT), endDate.format(MomentConstants.MOMENT_API_FORMAT), granularity,
            data => {
                setseries(data)
                getStatsForCampaignWithoutGranularity()
            },
            error => {
                console.error("error", error);
                setIsRealoading(false)
                systemServices.showError("An error occured while fetching data")
            });
    }
    const getStatsForCampaignWithoutGranularity = () => {
        emailingPerformancesServices.getStatsEmailRemarketingPeriod(accountId, campaignId, startDate.format(MomentConstants.MOMENT_API_FORMAT), endDate.format(MomentConstants.MOMENT_API_FORMAT),
            data => {
                setseriesPeriod(data && data.series[0])
                setIsRealoading(false);
            },
            error => {
                console.error("error", error);
                setIsRealoading(false)
                systemServices.showError("An error occured while fetching data for the selected period")
            });
    }
    const fetchAllCampaignsWithMainData = () => {
        emailingPerformancesServices.getAllCampaignsEmailRemarketing(accountId,
            success => {
                setListOfCampaignsAccount(success)
            },
            error => {
                systemServices.showError("an error occurred")
            });
    }
    useEffect(() => {
        fetchAllCampaignsWithMainData()
    }, [])
    useEffect(() => {
        if (momentEndDate) {
            getStatsForCampaign()
        }
    }, [momentEndDate])

    const search = (table, word) => {
        if (word.length >= 0) {
            let resultName = table.filter(
                line =>
                    line.name !== null && line.name.toUpperCase().match(`.*${word.toUpperCase()}.*`)
            );
            let finalArray = [...resultName];
            let uniqueItem = [...new Set(finalArray)];
            if (uniqueItem.length === 0) {
                uniqueItem = "No campaigns matches with this search"
                setNoData(uniqueItem);
                setDataFiltered();
            }
            else {
                setNoData();
                setDataFiltered(uniqueItem);
            }
        } else {
            setDataFiltered(listOfCampaignsAccount)
        }
    }
    const back = () => {
        if (props.$routeParams.ka ) {
            return `Dashboard/Home?tab=campaigncrm&ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}`;
        } else {
            return 'Dashboard/Home?tab=campaigncrm';
        }
    }
    const isOutsideRange = (date) => {
        if (date.isBefore(moment(dataCampaign.startDate))) {
            return true
        }
        if (moment(dataCampaign.endDate) > today ? date.isAfter(today) : date.isAfter(dataCampaign.endDate)) {
            return true
        }
    }

    const handleChangeCampaigns = (event) => {
        setValueInSearchBar(event.target.value);
    }
    useEffect(() => {
        if (listOfCampaignsAccount) {
            search(listOfCampaignsAccount, valueInSearchBar);
        }
    }, [valueInSearchBar, listOfCampaignsAccount])

    const handleOnClickCampaignIdToLoad = (id) => {
        setCampaignId(id);
        props.$location.search('id', id);
    }

    return (
        <div className="page_full hide_subaccount_nav section_root flex flex_v">
            <div className="flex_item_full flex flex_align_stretch overflow_hidden">
                <div className="flex_item_fix page_sidebar flex flex_v">
                    <div className="flex_item_fix">
                        <div className="page_sidebar_title">
                            <Link href={back()} icon="fas fa-arrow-left fa-sm">
                                Back to campaign list
                            </Link>
                        </div>
                    </div>
                    <div className="flex_item_fix page_sidebar_search">
                        <input type="text" className="page_sidebar_search_input" placeholder="Search for a campaign" onChange={(e) => handleChangeCampaigns(e)} value={valueInSearchBar} />
                    </div>
                    <ul className="flex_item_full vscroll page_sidebar_list">
                        {noData && <div className="noData">{noData}</div>}
                        {!noData && dataFiltered && dataFiltered.map(campaign =>
                            <CampaignItem
                                key={campaign.id}
                                campaignId={campaignId}
                                campaign={campaign}
                                handleOnClickCampaignIdToLoad={() => handleOnClickCampaignIdToLoad(campaign.id)}
                            />
                        )}
                    </ul>
                </div>
                <div className="flex_item_full vscroll">
                    <div className="page_center">
                        <section className="section xl">
                            <InformationCampaign
                                isLoading={isReloading}
                                datainformation={dataCampaign && dataCampaign}
                            />
                            <div className="flex flex_align_end mt_10">
                                <div className="flex_item_full">
                                    <DateRangePicker
                                        startDate={startDate}
                                        startDateId="idStartDate"
                                        endDateId="your_unique_end_date_id"
                                        displayFormat='DD/MM/YYYY'
                                        endDate={endDate}
                                        isOutsideRange={(date) => isOutsideRange(date)}
                                        focusedInput={focusedInput}
                                        onFocusChange={(focused) => setFocusedInput(focused)}
                                        onDatesChange={(startDate, endDate) => handleOnDateChange(startDate, endDate)}
                                        anchorDirection="left"
                                    />
                                    <Btn
                                        icon="fas fa-sync-alt"
                                        style="outline"
                                        className="ml_10"
                                        color="secondary"
                                        onClickFunction={() => changeRangeDate()}
                                    />
                                </div>
                                <div className="flex_item_fix">
                                    <Dropdown
                                        isOpen={frequencyDropdownIsOpen}
                                        setIsOpen={(e) => setFrequencyDropdownIsOpen(true)}
                                        onHide={(e) => setFrequencyDropdownIsOpen(false)}
                                        button={
                                            <Btn style="outline" color="secondary" arrow="true" size="s">
                                                {granularity == "day" && "Day"}
                                                {granularity == "week" && "Week"}
                                                {granularity == "month" && "Month"}
                                            </Btn>
                                        }>
                                        <ul className="listbox">
                                            <li>
                                                <a className={granularity == "day" ? "listbox_item selected" : "listbox_item"} onClick={(e) => { setGranularity('day'); changeRangeDate(); setFrequencyDropdownIsOpen(false) }}>Day</a>
                                            </li>
                                            <li>
                                                <a className={granularity == "week" ? "listbox_item selected" : "listbox_item"} onClick={(e) => { setGranularity('week'); changeRangeDate(); setFrequencyDropdownIsOpen(false) }}>Week</a>
                                            </li>
                                            <li>
                                                <a className={granularity == "month" ? "listbox_item selected" : "listbox_item"} onClick={(e) => { setGranularity('month'); changeRangeDate(); setFrequencyDropdownIsOpen(false) }}>Month</a>
                                            </li>
                                        </ul>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="reporting_body">
                                <Article>
                                    <div className="">
                                        <ChartRemarketingContainer statistics={series} isOnCharg={isReloading} />
                                    </div>
                                    <MainInformationsContainer
                                        data={seriesPeriod}
                                        mainInformation={dataCampaign && dataCampaign}
                                        dateRange={dateRange}
                                    />
                                </Article>
                            </div>

                        </section>
                    </div>
                </div>
            </div>
            {isReloading &&
                <div className="spinner_overlay">
                    <div className="spinner">
                        <div className="rect1"></div>
                        <div className="rect2"></div>
                        <div className="rect3"></div>
                        <div className="rect4"></div>
                        <div className="rect5"></div>
                    </div>
                </div>
            }
        </div >
    )
}


angular.module('beyableSaasApp.ReportingEmailingPerformances', [])
    .component('emailingperformances', react2angular(EmailRemarketingContainer, [], ['$http', '$rootScope', '$routeParams', '$location']));
