export const impressionIndicator = 'numberofimpressions';
export const nbOfClicksIndicator = 'numberofclicks';
const i18nL= localStorage.getItem('i18nL');
let indicators = [];
export let indicatorsInTable = [];

if(i18nL?.includes('fr')){
	indicators = [
		{
			key: '',
			label: ' - ',
			rateLabel: ' - ',
			category: ' - ',
			infos: '',
		},
		{
			key: 'numberofclicks',
			label: 'Clics',
			rateLabel: 'Taux de clics %',
			category: 'Display Data',
			infos: 'Nombre de fois où la campagne a été cliquée.',
			hideUplift: true
		},
	
		{
			key: 'numberofobjectiveswithpromocode',
			label: 'Conversions après l\'affichage d\'un code promotionnel',
			rateLabel: 'Conversions après l\'affichage d\'un code promotionnel%',
			category: 'Conversion Data',
			infos: 'Nombre de fois où la campagne a généré des conversions avec un code promo après l\'affichage dans la session',
			hideUplift: true
		},
		{
			key: 'amountofobjectiveswithpromocode',
			label: 'Montant du code promo',
			rateLabel: 'Montant du code promo (moyenne)',
			rateSign: '(avg)',
			category: 'Conversion Data',
			infos: 'Nombre de conversions avec code promo générées par la campagne.',
		},
		{
			key: 'numberofitemsinobjectiveswithpromocode',
			label: 'Articles convertis avec code promo',
			rateLabel: 'Articles convertis avec code promo %',
			category:  'Conversion Data',
			infos: 'Nombre de fois où la campagne a généré des conversions avec un code promo après l\'affichage dans la session',
			hideUplift: true
		},
		{
			key: impressionIndicator,
			label: 'Impressions',
			rateLabel: 'Taux d\'impressions%',
			category:  'Display Data',
			infos: 'Nombre de fois où la campagne a été affichée.',
			hideRate: true,
			hideUplift: true,
		},
		{
			key: 'numberofimpressionsnonunique',
			label: 'Impressions (Non unique)',
			rateLabel: 'Impressions (Non unique) taux %',
			category: 'Display Data',
			infos: 'Number of times the campaign has been displayed (Non unique).',
			hideUplift: true
		},
		{
			key: 'numberofobjectivespostviewinsession',
			label: 'Conversions après l\'affichage',
			rateLabel: 'Taux de conversion post-vue %',
			category: 'Conversion Data',
			infos: 'Nombre d\'objectifs générés par la campagne après l\'affichage.',
		},
		{
	
			key: 'amountofobjectivespostviewinsession',
			label: 'Conversions après l\'affichage du montant',
			rateLabel: 'Conversions après le nombre de vues (moyenne)',
			rateSign: '(avg)',
			category: 'Conversion Data',
			infos: 'Montant des objectifs générés par la campagne EN SESSION.',
		},
		{
	
			key: 'amountofobjectivespostviewinsessionaverage',
			label: 'Montant des objectifs post-vue (€ moyen)',
			rateLabel: '', // no data for the rate of an average
			category: 'Conversion Data',
			infos: 'Moyenne du nombre d\'objectifs générés POST VIEW IN SESSION',
		},
		{
	
			key: 'numberofobjectivespostviewnotatallinsession',
			label: 'Conversions post-view dans le passé',
			rateLabel: 'Conversions post-vue dans le passé taux %',
			category: 'Conversion Data',
			infos: 'Nombre d\'objectifs générés par la campagne POST VIEW NOT IN SESSION.',
		},
		{
	
			key: 'numberofobjectivespostclickinsession',
			label: 'Conversions post-clic',
			rateLabel: 'Taux de conversion post-clic %',
			category: 'Conversion Data',
			infos: 'Nombre d\'objectifs générés par la campagne POST CLICK.',
		},
		{
	
			key: 'numberofobjectiveslastclickinsession',
			label: 'Conversions après dernier clic',
			rateLabel: 'Conversions last-clic %',
			category: 'Conversion Data',
			infos: 'Nombre d\'objectifs générés par la campagne LAST CLICK.',
		},
		{
	
			key: 'numberofobjectivespostview7days',
			label: 'Conversions post view 7 jours',
			rateLabel: 'Conversions après affichage 7 jours taux %',
			category: 'Conversion Data',
			infos: 'Nombre d\'objectifs générés par la campagne POST VIEW 7 jours.',
		},
		{
	
			key: 'numberofobjectivespostclick7days',
			label: 'Conversions après le clic 7 jours',
			rateLabel: 'Conversions post-clic Taux 7 jours %',
			category: 'Conversion Data',
			infos: 'Nombre d\'objectifs générés par la campagne POST CLICK 7 jours.',
		},
		{
			key: 'numberofobjectivespostinteractioninsession',
			label: 'Conversions après l\'interaction',
			rateLabel: 'Conversions post-interaction %',
			category: 'Conversion Data',
			infos: 'Nombre d\'objectifs générés par la campagne POST INTERACTION.',
		},
		{
			key: 'numberofinteractions',
			label: 'Interactions',
			rateLabel: 'Taux d\'interaction %',
			category: 'Display Data',
			infos: 'Nombre d\'interactions avec la campagne.',
			hideUplift: true
		},
		{
			key: 'numberoftargetspostviewinsession',
			label: 'Cible l\'affichage en session',
			rateLabel: 'Objectifs taux d\'affichage en session %',
			category: 'Target Data',
			infos: 'Nombre de cibles (ex : création de compte) POST VIEW IN SESSION'
		},
		{
			key: 'numberoftargetspostclickinsession',
			label: 'Cibles post-clic en session',
			rateLabel: 'Objectifs taux de clics post session %',
			category: 'Target Data',
			infos: 'Nombre de cibles (par exemple, prospects, création de compte) POST CLICK IN SESSION'
		},
		{
			key: 'amountofobjectivespostinteractioninsession',
			label: 'Montant des objectifs post-interaction en session',
			rateLabel: 'Montant des objectifs post-interaction en session taux %',
			category: 'Conversion Data',
			infos: 'Montant des objectifs POST INTERACTION IN SESSION'
		}
		// taux de conversion, d'ajout au panier, rebond
	];

	// version FR
	indicatorsInTable = [
		{
			key: '',
			label: ' - ',
			rateLabel: ' - ',
			infos: '',
		},
		{
			key: 'numberofclicks',
			label: 'Clics',
			rateLabel: 'Taux de clics',
			infos: 'Number of times the campaign has been clicked.',
		},
	
		{
			key: 'numberofobjectiveswithpromocode',
			label: 'Conversions post view with Promo Code',
			rateLabel: 'Conversions post view with Promo Code %',
			infos: 'Number of times the campaign has generated conversions with promo code POST VIEW IN SESSION.',
		},
		{
			key: 'amountofobjectiveswithpromocode',
			label: 'Promo Code amount',
			rateLabel: 'Promo code amount (average)',
			infos: 'Amount of conversions with promo code generated by the campaign.',
		},
		{
			key: 'numberofitemsinobjectiveswithpromocode',
			label: 'Converted Items with Promo code',
			rateLabel: 'Converted Items with Promo code %',
			infos: 'Number of items for conversions with promo code generated by the campaign POST VIEW IN SESSION.',
		},
		{
			key: impressionIndicator,
			label: 'Impressions',
			rateLabel: 'Taux d\'impressions',
			infos: 'Number of times the campaign has been displayed.',
		},
		{
			key: 'numberofimpressionsnonunique',
			label: 'Impressions (Non unique)',
			rateLabel: 'Impressions (Non unique) rate %',
			infos: 'Number of times the campaign has been displayed (Non unique).',
		},
		{
			key: 'numberofobjectivespostviewinsession',
			label: 'Conversions',
			rateLabel: 'Taux de conversion',
			infos: 'Nombre de transactions post-impression',
			rateInfos: 'Taux de transaction post-impression',
		},
		{
			key: 'amountofobjectivespostviewinsession',
			label: 'Chiffre d\'affaire',
			rateLabel: '',
			infos: 'Chiffre d\'affaire total post-impression',
		},
		{
			key: 'amountofobjectivespostclickinsession',
			label: 'Chiffre d\'affaire',
			rateLabel: '',
			infos: 'Chiffre d\'affaire total post-clic',
		},
		{
			key: 'amountofobjectivespostviewinsessionaverage',
			label: 'Chiffre d\'affaire par conversion',
			rateLabel: '',
			infos: 'Chiffre d\'affaire moyen par transaction post-impression',
		},
		{
			key: 'numberofobjectivespostviewnotatallinsession',
			label: 'Conversions post view in the past',
			rateLabel: 'Conversions post view in the past rate %',
			infos: 'Number of objectives generated by the campaign POST VIEW NOT IN SESSION.',
		},
		{
			key: 'numberofobjectivespostclickinsession',
			label: 'Conversions',
			rateLabel: 'Taux de conversion',
			infos: 'Nombre de transactions post-clic',
			rateInfos: 'Taux de transaction post-clic',
		},
		{
			key: 'numberofobjectiveslastclickinsession',
			label: 'Conversions last click',
			rateLabel: 'Conversions last click rate %',
			infos: 'Number of objectives generated by the campaign LAST CLICK.',
		},
		{
			key: 'numberofobjectivespostview7days',
			label: 'Conversions post view 7 days',
			rateLabel: 'Conversions post view 7 days rate %',
			infos: 'Number of objectives generated by the campaign POST VIEW 7 days.',
		},
		{
			key: 'numberofobjectivespostclick7days',
			label: 'Conversions post click 7 days',
			rateLabel: 'Conversions post click 7 days rate %',
			infos: 'Number of objectives generated by the campaign POST CLICK 7 days.',
		},
		{
			key: 'numberofobjectivespostinteractioninsession',
			label: 'Conversions',
			rateLabel: 'Taux de conversion',
			infos: 'Nombre de transactions post-interaction',
			rateInfos: 'Taux de transaction post-interaction',
		},
		{
			key: 'numberofinteractions',
			label: 'Interactions',
			rateLabel: 'Taux d\'interaction',
		},
		{
			key: 'amountofobjectivespostinteractioninsession',
			label: 'Chiffre d\'affaire',
			rateLabel: '',
			infos: 'Chiffre d\'affaire total post-interaction',
		},
		{
			key: 'numberoftargetspostviewinsession',
			label: 'Conversions',
			rateLabel: 'Taux de conversion',
			infos: 'Nombre d\'objectifs atteints post-impression',
			rateInfos: 'Taux d\'objectifs atteints post-impression'
		},
		{
			key: 'numberoftargetspostclickinsession',
			label: 'Conversions',
			rateLabel: 'Taux de conversion',
			infos: 'Nombre d\'objectifs atteints post-clic',
			rateInfos: 'Taux d\'objectifs atteints post-clic'
		},
		{
			key: 'amountofobjectivespostinteractioninsession',
			label: 'Amount of objectives post-interaction in session',
			rateLabel: 'Amount of objectives post-interaction in session rate %',
			infos: 'Amount of objectives POST INTERACTION IN SESSION'
		},
		{
			key: 'amountofobjectivespostviewinsessionaveragebysession',
			label: 'Chiffre d\'affaire par visiteur',
			infos: 'Chiffre d\'affaire moyen par visiteur post-impression'
		},
		{
			key: 'amountofobjectivespostinteractioninsessionaveragebysession',
			label: 'Chiffre d\'affaire par visiteur',
			infos: 'Chiffre d\'affaire moyen par visiteur post-interaction'
		},
		{
			key: 'amountofobjectivespostinteractioninsessionaverage',
			label: 'Chiffre d\'affaire par conversion',
			rateLabel: '',
			infos: 'Chiffre d\'affaire moyen par transaction post-interaction',
		},
		{
			key: 'numberofobjectivespostinteractioninsession_evtcpg',
			label: 'Interactions',
			rateLabel: 'Taux d\'interaction'
		},
		{
			key: 'interactioncount',
			label: 'Interactions',
			rateLabel: 'Taux d\'interaction'
		},
		{
			key: 'amountofobjectivespostclickinsessionaveragebysession',
			label: 'Chiffre d\'affaire par visiteur',
			infos: 'Chiffre d\'affaire moyen par visiteur post-clic'
		},
		{
			key: 'amountofobjectivespostclickinsessionaverage',
			label: 'Chiffre d\'affaire par conversion',
			rateLabel: '',
			infos: 'Chiffre d\'affaire moyen par transaction post-clic',
		}
	];
}else{
	indicators = [
		{
			key: '',
			label: ' - ',
			rateLabel: ' - ',
			category: ' - ',
			infos: '',
		},
		{
			key: 'numberofclicks',
			label: 'Clicks',
			rateLabel: 'Clicks rate %',
			category: 'Display Data',
			infos: 'Number of times the campaign has been clicked.',
			hideUplift: true
		},
	
		{
			key: 'numberofobjectiveswithpromocode',
			label: 'Conversions post view with Promo Code',
			rateLabel: 'Conversions post view with Promo Code %',
			category: 'Conversion Data',
			infos: 'Number of times the campaign has generated conversions with promo code POST VIEW IN SESSION.',
			hideUplift: true
		},
		{
			key: 'amountofobjectiveswithpromocode',
			label: 'Promo Code amount',
			rateLabel: 'Promo code amount (average)',
			rateSign: '(avg)',
			category: 'Conversion Data',
			infos: 'Amount of conversions with promo code generated by the campaign.',
		},
		{
			key: 'numberofitemsinobjectiveswithpromocode',
			label: 'Converted Items with Promo code',
			rateLabel: 'Converted Items with Promo code %',
			category: 'Conversion Data',
			infos: 'Number of items for conversions with promo code generated by the campaign POST VIEW IN SESSION.',
			hideUplift: true
		},
		{
			key: impressionIndicator,
			label: 'Impressions',
			rateLabel: 'Impressions rate %',
			category: 'Display Data',
			infos: 'Number of times the campaign has been displayed.',
			hideRate: true,
			hideUplift: true,
		},
		{
			key: 'numberofimpressionsnonunique',
			label: 'Impressions (Non unique)',
			rateLabel: 'Impressions (Non unique) rate %',
			category: 'Display Data',
			infos: 'Number of times the campaign has been displayed (Non unique).',
			hideUplift: true
		},
		{
			key: 'numberofobjectivespostviewinsession',
			label: 'Post-view conversions',
			rateLabel: 'Post-view conversions rate %',
			category: 'Conversion Data',
			infos: 'Number of objectives generated by the campaign POST VIEW.',
		},
		{
			key: 'amountofobjectivespostviewinsession',
			label: 'Amount of post-view conversions',
			rateLabel: 'Amount of post-view conversions (average)',
			rateSign: '(avg)',
			category: 'Conversion Data',
			infos: 'Amount of objectives generated by the campaign IN SESSION.',
		},
		{
			key: 'amountofobjectivespostviewinsessionaverage',
			label: 'Average amount of post-view conversions',
			rateLabel: '', // no data for the rate of an average
			category: 'Conversion Data',
			infos: 'Average of amount of objectives generated POST VIEW IN SESSION',
		},
		{
			key: 'numberofobjectivespostviewnotatallinsession',
			label: 'Conversions post view in the past',
			rateLabel: 'Conversions post view in the past rate %',
			category: 'Conversion Data',
			infos: 'Number of objectives generated by the campaign POST VIEW NOT IN SESSION.',
		},
		{
			key: 'numberofobjectivespostclickinsession',
			label: 'Conversions post click',
			rateLabel: 'Conversions post click rate %',
			category: 'Conversion Data',
			infos: 'Number of objectives generated by the campaign POST CLICK.',
		},
		{
			key: 'numberofobjectiveslastclickinsession',
			label: 'Conversions last click',
			rateLabel: 'Conversions last click rate %',
			category: 'Conversion Data',
			infos: 'Number of objectives generated by the campaign LAST CLICK.',
		},
		{
			key: 'numberofobjectivespostview7days',
			label: 'Conversions post view 7 days',
			rateLabel: 'Conversions post view 7 days rate %',
			category: 'Conversion Data',
			infos: 'Number of objectives generated by the campaign POST VIEW 7 days.',
		},
		{
			key: 'numberofobjectivespostclick7days',
			label: 'Conversions post click 7 days',
			rateLabel: 'Conversions post click 7 days rate %',
			category: 'Conversion Data',
			infos: 'Number of objectives generated by the campaign POST CLICK 7 days.',
		},
		{
			key: 'numberofobjectivespostinteractioninsession',
			label: 'Post-interaction conversions',
			rateLabel: 'Conversions postinteraction rate %',
			category: 'Conversion Data',
			infos: 'Number of objectives generated by the campaign POST INTERACTION.',
		},
		{
			key: 'numberofinteractions',
			label: 'Interactions',
			rateLabel: 'Interactions rate %',
			category: 'Display Data',
			infos: 'Number of times the campaign has been interacted with.',
			hideUplift: true
		},
		{
			key: 'amountofobjectivespostinteractioninsession',
			label: 'Amount of post-interaction conversions',
			rateLabel: 'Average amount of post-interaction conversions',
			category: 'Conversion Data',
			infos: 'Amount of objectives generated by the campaign POST INTERACTION.',
		},
		{
			key: 'numberoftargetspostviewinsession',
			label: 'Targets post view in session',
			rateLabel: 'Targets post view in session rate %',
			category: 'Target Data',
			infos: 'Number of Targets (ex account creation) POST VIEW IN SESSION'
		},
		{
			key: 'numberoftargetspostclickinsession',
			label: 'Targets post click in session',
			rateLabel: 'Targets post click in session rate %',
			category: 'Target Data',
			infos: 'Number of Targets (ex lead, account creation) POST CLICK IN SESSION'
		},
		{
			key: 'amountofobjectivespostinteractioninsession',
			label: 'Amount of objectives post-interaction in session',
			rateLabel: 'Amount of objectives post-interaction in session rate %',
			category: 'Conversion Data',
			infos: 'Amount of objectives POST INTERACTION IN SESSION'
		}
		// taux de conversion, d'ajout au panier, rebond
	];

	indicatorsInTable = [
		{
			key: '',
			label: ' - ',
			rateLabel: ' - ',
			infos: '',
		},
		{
			key: 'numberofclicks',
			label: 'Clicks',
			rateLabel: 'Clicks rate %',
			infos: 'Number of times the campaign has been clicked.',
		},
	
		{
			key: 'numberofobjectiveswithpromocode',
			label: 'Conversions post view with Promo Code',
			rateLabel: 'Conversions post view with Promo Code %',
			infos: 'Number of times the campaign has generated conversions with promo code POST VIEW IN SESSION.',
		},
		{
			key: 'amountofobjectiveswithpromocode',
			label: 'Promo Code amount',
			rateLabel: 'Promo code amount (average)',
			infos: 'Amount of conversions with promo code generated by the campaign.',
		},
		{
			key: 'numberofitemsinobjectiveswithpromocode',
			label: 'Converted Items with Promo code',
			rateLabel: 'Converted Items with Promo code %',
			infos: 'Number of items for conversions with promo code generated by the campaign POST VIEW IN SESSION.',
		},
		{
			key: impressionIndicator,
			label: 'Impressions',
			rateLabel: 'Impressions rate %',
			infos: 'Number of times the campaign has been displayed.',
		},
		{
			key: 'numberofimpressionsnonunique',
			label: 'Impressions (Non unique)',
			rateLabel: 'Impressions (Non unique) rate %',
			infos: 'Number of times the campaign has been displayed (Non unique).',
		},
		{
			key: 'numberofobjectivespostviewinsession',
			label: 'Conversions',
			rateLabel: 'Conversions rate',
			infos: 'Number of post-view transactions',
			rateInfos: 'Post-view transactions rate',
		},
		{
			key: 'amountofobjectivespostviewinsession',
			label: 'Turnover',
			rateLabel: '',
			infos: 'Total post-view turnover',
		},
		{
			key: 'amountofobjectivespostclickinsession',
			label: 'Turnover',
			rateLabel: '',
			infos: 'Total post-click turnover',
		},
		{
			key: 'amountofobjectivespostviewinsessionaverage',
			label: 'Turnover by conversion',
			rateLabel: '',
			infos: 'Average turnover by transaction post-view ',
		},
		{
			key: 'numberofobjectivespostviewnotatallinsession',
			label: 'Conversions post view in the past',
			rateLabel: 'Conversions post view in the past rate %',
			infos: 'Number of objectives generated by the campaign POST VIEW NOT IN SESSION.',
		},
		{
			key: 'numberofobjectivespostclickinsession',
			label: 'Conversions',
			rateLabel: 'Conversion rate',
			infos: 'Number of post-click transactions',
			rateInfos: 'Post-click transaction rate',
		},
		{
			key: 'numberofobjectiveslastclickinsession',
			label: 'Conversions last click',
			rateLabel: 'Conversions last click rate %',
			infos: 'Number of objectives generated by the campaign LAST CLICK.',
		},
		{
			key: 'numberofobjectivespostview7days',
			label: 'Conversions post view 7 days',
			rateLabel: 'Conversions post view 7 days rate %',
			infos: 'Number of objectives generated by the campaign POST VIEW 7 days.',
		},
		{
			key: 'numberofobjectivespostclick7days',
			label: 'Conversions post click 7 days',
			rateLabel: 'Conversions post click 7 days rate %',
			infos: 'Number of objectives generated by the campaign POST CLICK 7 days.',
		},
		{
			key: 'numberofobjectivespostinteractioninsession',
			label: 'Conversions',
			rateLabel: 'Conversions rate',
			infos: 'Number of post-interaction transactions',
			rateInfos: 'Post-interaction transaction rate',
		},
		{
			key: 'numberofinteractions',
			label: 'Interactions',
			rateLabel: 'Interactions rate %',
			infos: 'Number of times the campaign has been interacted with.',
		},
		{
			key: 'amountofobjectivespostinteractioninsession',
			label: 'Turnover',
			rateLabel: '',
			infos: 'Total post-interaction turnover',
		},
		{
			key: 'numberoftargetspostviewinsession',
			label: 'Conversions',
			rateLabel: 'Conversion rate',
			infos: 'Number of post-view goals conversion',
			rateInfos: 'Post-view goal conversion rate'
		},
		{
			key: 'numberoftargetspostclickinsession',
			label: 'Conversions',
			rateLabel: 'Conversion rate',
			infos: 'Number of post-click goals conversion',
			rateInfos: 'Post-click goal conversion rate'
		},
		{
			key: 'amountofobjectivespostinteractioninsession',
			label: 'Amount of objectives post-interaction in session',
			rateLabel: 'Amount of objectives post-interaction in session rate %',
			infos: 'Amount of objectives POST INTERACTION IN SESSION'
		},
		{
			key: 'amountofobjectivespostviewinsessionaveragebysession',
			label: 'Turnover by visitor',
			infos: 'Average turnover by visitor post-view'
		},
		{
			key: 'amountofobjectivespostinteractioninsessionaveragebysession',
			label: 'Turnover by visitor',
			infos: 'Average turnover by visitor post-interaction'
		},
		{
			key: 'amountofobjectivespostinteractioninsessionaverage',
			label: 'Turnover by conversion',
			rateLabel: '',
			infos: 'Average turnover by transaction post-interaction',
		},
		{
			key: 'numberofobjectivespostinteractioninsession_evtcpg',
			label: 'Interactions',
			rateLabel: 'Interaction rate'
		},
		{
			key: 'interactioncount',
			label: 'Interactions',
			rateLabel: 'Interaction rate'
		},
		{
			key: 'amountofobjectivespostclickinsessionaveragebysession',
			label: 'Turnover by visitor',
			infos: 'Average turnover by visitor post-click'
		},
		{
			key: 'amountofobjectivespostclickinsessionaverage',
			label: 'Turnover by conversion',
			rateLabel: '',
			infos: 'Average turnover by transaction post-click',
		}
	];

}


let _isTargetIndicator = function (key) {
	return key.startsWith('numberoftargetspostclickinsession_') || key.startsWith('numberoftargetspostviewinsession_');
};

let deconstructTargetIndictor = function (key) {

	let keyToReplace = key;

	if(typeof key !== 'string' ){
		keyToReplace = key.key;
	}

	let tmp_key = keyToReplace.replace('_', '&&&');
	tmp_key = tmp_key.replace('_', '&&&');

	var split = tmp_key.split('&&&');

	if (_isTargetIndicator(keyToReplace) && split.length >= 3) {
		var targetIndicatorKey = split[0];
		var targetValue = `${split[1]}: ${split[2]}`;
		return [targetIndicatorKey, targetValue];
	}
};

export function getIndicatorInTableByKey(key) {

	if (key.indexOf('evtcpg:') === 0) {
		return getIndicatorInTableByKey('interactioncount');
	}

	let targetIndicator = deconstructTargetIndictor(key);
	if (targetIndicator) {
		var targetIndicatorKey = targetIndicator[0];
		return getIndicatorInTableByKey(targetIndicatorKey);
	}

	if (key.includes('_evtcpg:')){
		const split = key.split('_evtcpg:');
		return getIndicatorInTableByKey(split[0]);
	}

	var indicator = indicatorsInTable.find(item => item.key === key.toLowerCase());

	return indicator;
}

export function getIndicatorByKey(key) {
	let targetIndicator = deconstructTargetIndictor(key);

	if (targetIndicator) {
		var targetIndicatorKey = targetIndicator[0];
		var targetValue = targetIndicator[1];
		var i = getIndicatorByKey(targetIndicatorKey);
		return { ...i, label: `${targetValue} (${i.label})`, rateLabel: `${i.rateLabel} (${targetValue})` };
	}

	var indicator = indicators.filter(item => item.key === key);
	if (indicator.length > 0)
		return indicator[0];
	else return {
		id: 0,
		key: key,
		label: key,
		rateLabel: `${key} (rate)`,
		category: '',
		infos: '',
		hideUplift: true
	};	
}

export default indicators;
