import React, { useContext } from 'react';
import { BusinessOperationOrchestrationContext } from '../Context';

const DeviceSelector = ({ }) => {
	const {
		selectedPageType,
		handleChangeSelectedDevice
	} = useContext(BusinessOperationOrchestrationContext);

	const hasMobile = selectedPageType.device.includes('mobile');
	const hasDesktop = selectedPageType.device.includes('desktop');
	const isMobile = selectedPageType && selectedPageType.selectedDevice === 'mobile';
	const isDesktop = selectedPageType && selectedPageType.selectedDevice === 'desktop';
	const mobileClass = [''];
	const desktopClass = [''];

	if (!hasMobile) {
		mobileClass.push('disabled');
	}
	if (!hasDesktop) {
		desktopClass.push('disabled');
	}
	if (isMobile) {
		mobileClass.push('active');
	}
	if (isDesktop) {
		desktopClass.push('active');
	}

	const handleChange = (device) => {
		if (device == 'mobile' && !hasMobile) return;
		if (device == 'desktop' && !hasDesktop) return;
		handleChangeSelectedDevice(device);
	};

	return (
		<ul className="rounded_tabs up_case">
			<li>
				<a className={mobileClass.join(' ')} onClick={(e) => handleChange('mobile') }>
					<i className="fas fa-sm fa-mobile-alt"></i>
					<span>Mobile</span>
				</a>
			</li>
			<li>
				<a className={desktopClass.join(' ')} onClick={(e) => handleChange('desktop') } >
					<i className="fas fa-sm fa-desktop"></i>
					<span>Desktop</span>
				</a>
			</li>
		</ul>
	);
};

export default DeviceSelector;