export const FORMAT = 'YYYY/MM/DD';

export const FORMAT_WITH_HMS = 'YYYY/MM/DD HH:mm:ss'

export const cardFetchOptions = {
    "fromDate": '',
    "toDate": '',
    "metrics": [
        "NumberOfSessions"
    ],
    "timeGranularity": "None",
    "dimensions": [],
    "limit": 5,
    "offset": 0,
    "filters": [],
    "search": null
}

