import { v4 as uuid } from 'uuid';
import { AttributeOrScoreFilterItem, AttributeOrScoreValueType } from '../../EntityTypes/EntityTypes';

export type DataType = {
	groupSorting: ProductGroupSorting | null
}

export enum GroupSortingGroupType {
	SingleValue = 'SingleValue',
	Range = 'Range',
	Default = 'Default'
}
type ProductItemNumberRangeValue = {
	min: number | null;
	max: number | null;
}

type ProductItemValue = {
	booleanValue?: boolean | null;
	stringValues?: string[] | null;
	numberValues?: number[] | null;
	dateUtcValues?: string[] | null;
	numberRange?: ProductItemNumberRangeValue | null
}

type ProductGroupSortingGroupBound = {
	value: ProductItemValue;
	isInclusive: boolean;
}

type GroupSortingGroup = {
	type: GroupSortingGroupType;
	singleValue?: ProductItemValue | null;
	lowerBound?: ProductGroupSortingGroupBound | null;
	higherBound?: ProductGroupSortingGroupBound | null;
};

export type ExistingProductGroupSorting = {
	filter: AttributeOrScoreFilterItem;
	groups: GroupSortingGroup[];
	filterParams: FilterParam[];
}

export type FilterParam = {
	name: string;
	value: ProductItemValue;
	isAnError?: boolean;
	errorMessage?: string;
}

export type ProductGroupSorting = {
	filterId: string;
	groups: GroupSortingGroup[];
	filterParams: FilterParam[];
}

export class GroupSorting {
	validateForSaving(): [boolean, ([GroupSortingGroup[], FilterParam[], string] | GroupSorting)] {
		if (this.filterItem === undefined) {
			return [false, this];
		}

		const validateFilterParamForSaving = (p: FilterParam): FilterParam => {
			if (!p.value.stringValues || p.value.stringValues.length == 0 || !p.value.stringValues[0])
				return { ...p, isAnError: true, errorMessage: 'Value is required' };
			return p;
		};

		const validatedFilterParams = this.filterItem.params?.map(x => {
			const p = this.filterParams.find(f => f.name === x.name);
			if (p !== undefined) {
				return validateFilterParamForSaving(p);
			}
			else {
				return { name: x.name, value: { stringValues: [] }, isAnError: true, errorMessage: 'Value is required' };
			}
		}) ?? [];

		const validatedGroups = this.groupsData.map(x => x.validateForSaving());
		if (validatedGroups.some(x => x.isAnError) || validatedFilterParams.some(x => x.isAnError)) {
			return [false, new GroupSorting(validatedGroups, validatedFilterParams, this.filterItem, this.userChoiceValueType)];
		}

		const hasDefault = this.groupsData.find(x => x.groupData.type === GroupSortingGroupType.Default);
		// add default group at the end if needed
		const groups = this.groupsData.map(x => x.groupData).concat(hasDefault ? [] : [{ type: GroupSortingGroupType.Default }]);

		return [true, [groups, this.filterParams, this.filterItem.id]];
	}
	canSave(): boolean {
		return this.groupsData.length > 0;
	}
	hasDefault(): boolean {
		return this.groupsData.some(x => x.groupData.type === GroupSortingGroupType.Default);
	}
	updateGroup(updatedGroup: GroupSortingGroupEntity): GroupSorting {
		return new GroupSorting(this.groupsData.map(g => g.id === updatedGroup.id ? updatedGroup : g), this.filterParams, this.filterItem, this.userChoiceValueType);
	}
	deleteGroup(group: GroupSortingGroupEntity): GroupSorting {
		return new GroupSorting(this.groupsData.filter(x => x.id !== group.id), this.filterParams, this.filterItem, this.userChoiceValueType);
	}
	addGroup(newGroup: GroupSortingGroupEntity): GroupSorting {
		return new GroupSorting([...this.groupsData, newGroup], this.filterParams, this.filterItem, this.userChoiceValueType);
	}
	getFilterParam(paramName: string): string | null {
		const value = this.filterParams.find(x => x.name === paramName)?.value.stringValues ?? null;
		if (value === null || value.length == 0) return null;
		return value[0];
	}
	updateFilterParam(filterParam: FilterParam): GroupSorting {
		const newFilters = this.filterParams.filter(x => x.name !== filterParam.name).concat(filterParam);
		return new GroupSorting(this.groupsData, newFilters, this.filterItem, this.userChoiceValueType);
	}
	updateAllGroups(groupSortingGroups: GroupSortingGroupEntity[]) {
		return new GroupSorting(groupSortingGroups, this.filterParams, this.filterItem, this.userChoiceValueType);
	}
	updateValueType(valueType: AttributeOrScoreValueType): GroupSorting {
		return new GroupSorting(this.groupsData.map(x => x.updateValueType(valueType)), this.filterParams, this.filterItem, valueType);
	}
	getFilterParamErrorMessage(paramName: string): string | null {
		return this.filterParams.find(x => x.name === paramName)?.errorMessage ?? null;
	}
	getFilterParamIsAnError(paramName: string): boolean {
		return this.filterParams.find(x => x.name === paramName)?.isAnError ?? false;
	}
	groupsData: GroupSortingGroupEntity[];
	filterParams: FilterParam[];
	filterItem: AttributeOrScoreFilterItem | undefined;
	userChoiceValueType: AttributeOrScoreValueType | undefined;
	constructor(groupSortingGroups: GroupSortingGroupEntity[], filterParams: FilterParam[], filterItem: AttributeOrScoreFilterItem | undefined, userChoiceValueType: AttributeOrScoreValueType | undefined) {
		this.groupsData = groupSortingGroups;
		this.filterParams = filterParams;
		this.filterItem = filterItem;
		this.userChoiceValueType = userChoiceValueType;
	}
}


export class GroupSortingGroupEntity {
	getSingleValue(defaultValue: string) {
		return _getItemValueOrDefault(this.groupData.singleValue, this.valueType, defaultValue);
	}
	getLowerBoundValueOrDefault(defaultValue: string) {
		return _getItemValueOrDefault(this.groupData.lowerBound?.value, this.valueType, defaultValue);
	}
	getHigherBoundValueOrDefault(defaultValue: string) {
		return _getItemValueOrDefault(this.groupData.higherBound?.value, this.valueType, defaultValue);
	}
	updateSingleValue(value: any): GroupSortingGroupEntity {
		if (this.groupData.singleValue) {
			const itemValue = _buildItemValue(value, this.valueType);
			const { message } = (itemValue as TypeError);
			if (message) {
				this.isAnError = true;
				this.errorMessage = message;
			} else {
				this.groupData.singleValue = (itemValue as ProductItemValue);
				this.isAnError = false;
				this.errorMessage = null;
			}
		}
		return this;
	}
	changeBoundsFromUserChoice(higherBoundIsInclusive?: boolean, lowerBoundIsInclusive?: boolean): GroupSortingGroupEntity {
		if (typeof lowerBoundIsInclusive !== 'undefined' && this.groupData.lowerBound) {
			this.userChoiceForGroupSortingGroup = { ...this.userChoiceForGroupSortingGroup, lowerBoundInclusive: lowerBoundIsInclusive };
		}
		if (typeof higherBoundIsInclusive !== 'undefined' && this.groupData.higherBound) {
			this.userChoiceForGroupSortingGroup = { ...this.userChoiceForGroupSortingGroup, higherBoundInclusive: higherBoundIsInclusive };
		}
		return this.updateBounds(undefined, higherBoundIsInclusive, undefined, lowerBoundIsInclusive);
	}
	updateBounds(higherBoundValue?: any, higherBoundIsInclusive?: boolean, lowerBoundValue?: any, lowerBoundIsInclusive?: boolean): GroupSortingGroupEntity {
		if (typeof higherBoundValue !== 'undefined' && this.groupData.higherBound) {
			const itemValue = _buildItemValue(higherBoundValue, this.valueType);
			const { message } = itemValue as TypeError;
			if (message) {
				this.isAnError = true;
				this.errorMessage = message;
			} else {
				this.groupData.higherBound.value = (itemValue as ProductItemValue);
				this.isAnError = false;
				this.errorMessage = null;
			}
		}
		if (typeof higherBoundIsInclusive !== 'undefined' && this.groupData.higherBound) {
			this.groupData.higherBound.isInclusive = higherBoundIsInclusive;
		}
		if (typeof lowerBoundValue !== 'undefined' && this.groupData.lowerBound) {
			const itemValue = _buildItemValue(lowerBoundValue, this.valueType);
			const { message } = itemValue as TypeError;
			if (message) {
				this.isAnError = true;
				this.errorMessage = message;
			} else {
				this.groupData.lowerBound.value = (itemValue as ProductItemValue);
				this.isAnError = false;
				this.errorMessage = null;
			}
		}
		if (typeof lowerBoundIsInclusive !== 'undefined' && this.groupData.lowerBound) {
			this.groupData.lowerBound.isInclusive = lowerBoundIsInclusive;
		}
		return this;
	}
	updateValueType(newValueType: AttributeOrScoreValueType): GroupSortingGroupEntity {
		const updateGroupDataValueType = (): [GroupSortingGroup, TypeError | null] => {
			if (this.groupData.type === GroupSortingGroupType.Range) {

				const [newValueH, errorH] = _updateValueType(this.groupData.higherBound?.value, this.valueType, newValueType);
				const [newValueL, errorL] = _updateValueType(this.groupData.lowerBound?.value, this.valueType, newValueType);

				const newLowerBound = !this.groupData.lowerBound ? null : { isInclusive: this.groupData.lowerBound?.isInclusive ?? true, value: newValueL };
				const newHigherBound = !this.groupData.higherBound ? null : { isInclusive: this.groupData.higherBound?.isInclusive ?? true, value: newValueH };

				return [{ ...this.groupData, higherBound: newHigherBound, lowerBound: newLowerBound }, errorH ?? errorL];
			} else if (this.groupData.type === GroupSortingGroupType.SingleValue) {
				const [newValue, error] = _updateValueType(this.groupData.singleValue, this.valueType, newValueType);
				return [{ ...this.groupData, singleValue: newValue }, error];
			}
			return [this.groupData, null];
		};

		const [newValue, error] = updateGroupDataValueType();

		return new GroupSortingGroupEntity(this.id, newValue, this.userChoiceForGroupSortingGroup, newValueType, error !== null, error !== null ? error.message : undefined);
	}
	changeGroupTypeFromUserChoice(userChoice: UserChoiceForGroupSortingGroupType): GroupSortingGroupEntity {
		const isRangeType = [UserChoiceForGroupSortingGroupType.IsBetween, UserChoiceForGroupSortingGroupType.IsGreaterThan, UserChoiceForGroupSortingGroupType.IsLowerThan].includes(userChoice);
		const inclusive = isRangeType ? true : undefined;
		this.userChoiceForGroupSortingGroup = { type: userChoice, higherBoundInclusive: inclusive, lowerBoundInclusive: inclusive };
		this.isAnError = false;
		if (userChoice === UserChoiceForGroupSortingGroupType.Default) {
			return this.updateGroupType(GroupSortingGroupType.Default);
		} else if (isRangeType) {
			return this.updateGroupType(GroupSortingGroupType.Range);
		} else if (userChoice === UserChoiceForGroupSortingGroupType.IsSingleValue) {
			return this.updateGroupType(GroupSortingGroupType.SingleValue);
		}
		throw Error('not supported value: ' + userChoice);
	}
	updateGroupType(newType: GroupSortingGroupType): GroupSortingGroupEntity {
		this.groupData.type = newType;
		if (newType === GroupSortingGroupType.Range) {
			this.groupData.lowerBound = { isInclusive: true, value: _buildItemValue(null, this.valueType) as ProductItemValue };
			this.groupData.higherBound = { isInclusive: true, value: _buildItemValue(null, this.valueType) as ProductItemValue };
			this.groupData.singleValue = null;
		}
		if (newType === GroupSortingGroupType.SingleValue) {
			this.groupData.lowerBound = null;
			this.groupData.higherBound = null;
			this.groupData.singleValue = _buildItemValue(null, this.valueType) as ProductItemValue;
		}
		if (newType === GroupSortingGroupType.Default) {
			this.groupData.lowerBound = null;
			this.groupData.higherBound = null;
			this.groupData.singleValue = null;
		}
		return this;
	}
	validateForSaving(): GroupSortingGroupEntity {
		this.isAnError = false;
		this.errorMessage = null;

		if (this.groupData.type === GroupSortingGroupType.Range) {
			const isEmpty = (g: ProductGroupSortingGroupBound | null | undefined): boolean => g === null || g === undefined ? true : _itemValueIsEmpty(g.value, this.valueType);

			const lowerBoundValueIsEmpty = isEmpty(this.groupData.lowerBound);
			const higherBoundValueIsEmpty = isEmpty(this.groupData.higherBound);
			if (lowerBoundValueIsEmpty && higherBoundValueIsEmpty) {
				this.isAnError = true;
				this.errorMessage = 'The values of the lower and upper bounds cannot both be empty.';
				return this;
			}

			if (this.userChoiceForGroupSortingGroup.type === UserChoiceForGroupSortingGroupType.IsBetween) {
				if (lowerBoundValueIsEmpty || higherBoundValueIsEmpty) {
					this.isAnError = true;
					this.errorMessage = 'Both values should be set.';
					return this;
				}
			} else if (this.userChoiceForGroupSortingGroup.type === UserChoiceForGroupSortingGroupType.IsGreaterThan) {
				if (lowerBoundValueIsEmpty) {
					this.isAnError = true;
					this.errorMessage = 'Value cannot not be empty.';
					return this;
				}
			} else if (this.userChoiceForGroupSortingGroup.type === UserChoiceForGroupSortingGroupType.IsLowerThan) {
				if (higherBoundValueIsEmpty) {
					this.isAnError = true;
					this.errorMessage = 'Value cannot not be empty.';
					return this;
				}
			}

			if (lowerBoundValueIsEmpty) {
				this.groupData.lowerBound = null; // should be sent null to the api
			}

			if (higherBoundValueIsEmpty) {
				this.groupData.higherBound = null;
			}

			return this;
		}
		if (this.groupData.type === GroupSortingGroupType.SingleValue) {
			if (!this.groupData.singleValue) {
				this.isAnError = true;
				this.errorMessage = 'An error occured';
				return this;
			}

			const singleValueIsEmpty = _itemValueIsEmpty(this.groupData.singleValue, this.valueType);
			if (singleValueIsEmpty) {
				this.isAnError = true;
				this.errorMessage = 'Value is required';
				return this;
			}
		}
		return this;
	}

	isAnError: boolean;
	errorMessage: string | null;
	groupData: GroupSortingGroup;
	id: string;
	valueType: AttributeOrScoreValueType;
	userChoiceForGroupSortingGroup: UserChoiceForGroupSortingGroup;
	constructor(id: string, groupData: GroupSortingGroup, userChoiceForGroupSortingGroup: UserChoiceForGroupSortingGroup, valueType: AttributeOrScoreValueType, isAnError?: boolean, errorMessage?: string) {
		this.id = id;
		this.groupData = groupData;
		this.isAnError = isAnError ?? false;
		this.errorMessage = errorMessage ?? '';
		this.valueType = valueType;
		this.userChoiceForGroupSortingGroup = userChoiceForGroupSortingGroup;
	}
}

export function initializeGroupSorting(groupSorting: ProductGroupSorting, filter: AttributeOrScoreFilterItem): GroupSorting {
	const getUserChoiceValueType = (): AttributeOrScoreValueType | null => {
		if (filter.valueType === AttributeOrScoreValueType.Tag && groupSorting.groups.length > 0) {
			const firstGroup = groupSorting.groups[0];
			switch (firstGroup.type) {
				case GroupSortingGroupType.Default:
					return null;
				case GroupSortingGroupType.SingleValue:
					return _getItemValueType(firstGroup.singleValue);
				case GroupSortingGroupType.Range:
					return _getItemValueType(firstGroup.higherBound?.value) || _getItemValueType(firstGroup.lowerBound?.value);
			}
		}
		return null;
	};

	const userChoiceValueType = getUserChoiceValueType();
	const groupEntities = groupSorting.groups.map(x => createGroupSortingGroupFrom(x, userChoiceValueType ?? filter?.valueType));
	return new GroupSorting(groupEntities, groupSorting.filterParams ?? [], filter, userChoiceValueType ?? undefined);
}

export function createDefaultValueGroupSortingGroup(valueType: AttributeOrScoreValueType | undefined): GroupSortingGroupEntity {
	return new GroupSortingGroupEntity(uuid(), {
		type: GroupSortingGroupType.Default
	}, { type: UserChoiceForGroupSortingGroupType.Default }, valueType ?? AttributeOrScoreValueType.String);
}

export function createSingleValueGroupSortingGroupWithDefaultValuesFrom(group: GroupSorting) {
	const valueType = group.userChoiceValueType ?? group.filterItem?.valueType;
	return createSingleValueGroupSortingGroupWithDefaultValues(valueType);
}

function createSingleValueGroupSortingGroupWithDefaultValues(valueType: AttributeOrScoreValueType | undefined): GroupSortingGroupEntity {
	return new GroupSortingGroupEntity(uuid(), {
		type: GroupSortingGroupType.SingleValue, singleValue: _buildItemValue(null, valueType ?? AttributeOrScoreValueType.String) as ProductItemValue
	}, { type: UserChoiceForGroupSortingGroupType.IsSingleValue }, valueType ?? AttributeOrScoreValueType.String);
}

export function createGroupSortingGroupFromValue(value : any, groupType: GroupSortingGroupType, valueType: AttributeOrScoreValueType): GroupSortingGroupEntity {
	return createGroupSortingGroupFrom({ type: groupType, singleValue: _buildItemValue(value, valueType) as ProductItemValue }, valueType);
}

function createGroupSortingGroupFrom(data: GroupSortingGroup, valueType: AttributeOrScoreValueType | undefined): GroupSortingGroupEntity {

	const getUserChoice = (): UserChoiceForGroupSortingGroup => {
		if (data.type === GroupSortingGroupType.Range) {
			const hasLowerBound = data.lowerBound !== null && data.lowerBound !== undefined;
			const hasHigherBound = data.higherBound !== null && data.higherBound !== undefined;

			if (hasLowerBound && hasHigherBound) return {
				type: UserChoiceForGroupSortingGroupType.IsBetween,
				higherBoundInclusive: (data.higherBound?.isInclusive) ?? true,
				lowerBoundInclusive: (data.lowerBound?.isInclusive) ?? true
			};

			if (hasHigherBound) return {
				type: UserChoiceForGroupSortingGroupType.IsLowerThan,
				higherBoundInclusive: (data.higherBound?.isInclusive) ?? true
			};

			return {
				type: UserChoiceForGroupSortingGroupType.IsGreaterThan,
				lowerBoundInclusive: (data.lowerBound?.isInclusive) ?? true
			};
		}
		if (data.type === GroupSortingGroupType.SingleValue) return {
			type: UserChoiceForGroupSortingGroupType.IsSingleValue,
		};

		if (data.type === GroupSortingGroupType.Default) return {
			type: UserChoiceForGroupSortingGroupType.Default
		};

		throw Error('not supported value: ' + data.type);
	};

	return new GroupSortingGroupEntity(uuid(), data, getUserChoice(), valueType ?? AttributeOrScoreValueType.String);
}

export function buildStringValueFilterParam(name: string, value: string): FilterParam {
	return { name: name, value: { stringValues: [value] } };
}



// private Helper functions

const _getFirstValueOrDefault = (values: any[] | null | undefined, defaultValue: any) => {
	return values == null || values.length == 0 ? defaultValue : values[0];
};

const _getItemValueType = (itemValue: (ProductItemValue | null | undefined)): (AttributeOrScoreValueType | null) => {
	if (itemValue === null || itemValue === undefined)
		return null;
	if (itemValue.stringValues !== null && itemValue.stringValues !== undefined && itemValue.stringValues.length > 0)
		return AttributeOrScoreValueType.String;
	if (itemValue.numberValues !== null && itemValue.numberValues !== undefined && itemValue.numberValues.length > 0)
		return AttributeOrScoreValueType.Number;
	if (itemValue.dateUtcValues !== null && itemValue.dateUtcValues !== undefined && itemValue.dateUtcValues.length > 0)
		return AttributeOrScoreValueType.Number;
	if (itemValue.booleanValue !== null && itemValue.dateUtcValues !== undefined)
		return AttributeOrScoreValueType.Boolean;
	return null;
};


const _getItemValueOrDefault = (itemValue: (ProductItemValue | null | undefined), valueType: AttributeOrScoreValueType, defaultValue: any) => {
	switch (valueType) {
		case AttributeOrScoreValueType.Tag:
		case AttributeOrScoreValueType.String:
			return _getFirstValueOrDefault(itemValue?.stringValues, defaultValue);
		case AttributeOrScoreValueType.Number:
			return _getFirstValueOrDefault(itemValue?.numberValues, defaultValue);
		case AttributeOrScoreValueType.Date:
			return _getFirstValueOrDefault(itemValue?.dateUtcValues, defaultValue);
		case AttributeOrScoreValueType.Boolean:
			return itemValue?.booleanValue ?? defaultValue;
	}
};
type TypeError = {
	message: string;
};
const _buildItemValue = (value: any | null, valueType: AttributeOrScoreValueType): (ProductItemValue | TypeError) => {
	const isNumeric = (str: any): boolean => !Number.isNaN(Number(str));
	const checkType = (checkFn: (value: any) => boolean, message: string, f: () => any): (ProductItemValue | TypeError) => checkFn(value) ? f() : { message: message };
	const checkIsNumeric = (f: () => any) => checkType(isNumeric, 'A Numeric value is expected', f);
	switch (valueType) {
		case AttributeOrScoreValueType.Number:
			return value === null ? { numberValues: [] } : checkIsNumeric(() => ({ numberValues: [value] }));
		case AttributeOrScoreValueType.Date:
			return value === null ? { dateUtcValues: [] } : { dateUtcValues: [value] };
		case AttributeOrScoreValueType.Boolean:
			return { booleanValue: value };
		case AttributeOrScoreValueType.Tag:
		case AttributeOrScoreValueType.String:
		default:
			return value === null ? { stringValues: [] } : { stringValues: [value] };
	}
};

function _updateValueType(itemValue: ProductItemValue | null | undefined, valueType: AttributeOrScoreValueType, newValueType: AttributeOrScoreValueType): [ProductItemValue, TypeError | null] {
	const value = _getItemValueOrDefault(itemValue, valueType, null);
	const newValue = _buildItemValue(value, newValueType);
	const { message } = (newValue as TypeError);

	if (message) {
		return [value, newValue as TypeError];
	}
	return [newValue as ProductItemValue, null];
}


const _itemValueIsEmpty = (itemValue: ProductItemValue, valueType: AttributeOrScoreValueType): boolean => {
	const isEmpty = (items: (any[] | undefined | null)) => !items || items.length === 0 || !items[0];
	switch (valueType) {
		case AttributeOrScoreValueType.Number:
			return isEmpty(itemValue.numberValues);
		case AttributeOrScoreValueType.Date:
			return isEmpty(itemValue.dateUtcValues);
		case AttributeOrScoreValueType.Boolean:
			return itemValue?.booleanValue === null || itemValue?.booleanValue === undefined;
		case AttributeOrScoreValueType.Tag:
		case AttributeOrScoreValueType.String:
		default:
			return isEmpty(itemValue.stringValues);
	}
};



export type UserChoiceForGroupSortingGroup = {
	type: UserChoiceForGroupSortingGroupType;
	lowerBoundInclusive?: boolean;
	higherBoundInclusive?: boolean;
}


export enum UserChoiceForGroupSortingGroupType {
	IsSingleValue = 'IsSingleValue',
	IsBetween = 'IsBetween',
	IsGreaterThan = 'IsGreaterThan',
	IsLowerThan = 'IsLowerThan',
	Default = 'Default'
}

export const userChoicesForGroupSortingGroupType: { label: string, value: UserChoiceForGroupSortingGroupType }[] = [
	{ label: 'Is single value', value: UserChoiceForGroupSortingGroupType.IsSingleValue },
	{ label: 'Is between', value: UserChoiceForGroupSortingGroupType.IsBetween },
	{ label: 'Is greater than', value: UserChoiceForGroupSortingGroupType.IsGreaterThan },
	{ label: 'Is lower than', value: UserChoiceForGroupSortingGroupType.IsLowerThan },
	{ label: 'All other values', value: UserChoiceForGroupSortingGroupType.Default },
];


export const userChoicesForValueType: { label: string, value: AttributeOrScoreValueType }[] = [
	{ label: 'String', value: AttributeOrScoreValueType.String },
	{ label: 'Number', value: AttributeOrScoreValueType.Number },
];

