import React, { useState, useEffect } from 'react';
import FormSurveyCustomContainerHook from './FormSurveyCustomContainerHook';
import Overview from './Overview';
import styles from './Survey.module.css';

function QuestionsStep({
	state,
	setState,
	thankSectionValue,
	stepSurvey,
	nbQuestions,
	removePanel
}) {

	const [divStyle, setDivStyle] = useState<React.CSSProperties>({});

	useEffect(() => {
		const style:React.CSSProperties = {};
		if (stepSurvey !== 1) {
			style.display = 'none';
		}
		setDivStyle(style);
	}, [stepSurvey]);
	
	return (
		<>
			<div className={styles.col_editor} style={divStyle}>
				<FormSurveyCustomContainerHook
					removePanel={removePanel}
					state={state}
					setState={setState}
					nbQuestions={nbQuestions}
				/>
			</div>
			{stepSurvey === 1 && nbQuestions > 0 &&
				<div className={styles.col_preview}>
					<Overview
						thankSectionValue={thankSectionValue}
						state={state}
					/>
				</div>
			}
		</>
	);
}

export default QuestionsStep;
