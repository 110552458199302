import React from 'react';
import { DateRangePicker, isInclusivelyBeforeDay, DayPickerRangeController } from 'react-dates';
import Btn from '../../../Components/Btn';
export default function CustomPicker({
	customDateRange,
	onFocused,
	focusedInput,
	handleValidDate,
	handleOnDateChange,
	isCustomSelected
}) {
	if (!isCustomSelected) {
		return <></>;
	}

	return (
		<div className="flex_item_fix dropdown_column">
			<DayPickerRangeController
				startDate={customDateRange.startDate}
				endDate={customDateRange.endDate}
				minimumNights={0}
				isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
				focusedInput={focusedInput}
				onFocusChange={(focused) => { onFocused(focused); }}
				onDatesChange={(dates) => { handleOnDateChange(dates); }}
				// onClose={(dates) => { close(dates); }}
				numberOfMonths={3}
				daySize={32}
				firstDayOfWeek={1}
			/>
			<div className='section has_top_border'>
				<Btn
					onClickFunction={()=>handleValidDate()}
					message="Confirm"
					color="primary"
				/>
			</div>
		</div>
	);
}
