import React, {useEffect, useState, ChangeEvent} from 'react';
import { CampaignsContext } from '../../context/index.js';
// @ts-ignore
import {Article} from '../../../../Components/Article/Article';
import Btn from '../../../../Components/Btn.js';
import IconBtn from '../../../../Components/IconBtn.js';
import Confirm from '../../../../Components/Confirm';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import {List, ListItem} from '../../../../Components/List';
import {Mask} from '../../../../Components/Mask/Mask';
import EmptyState from '../../../../Components/EmptyState';
import SectionMessage from '../../../../Components/SectionMessage';
import DropDownAbTest from './DropDownAbTest';
import { cloneDeep} from 'lodash';
import {designPropertiesAbTestRedirection} from '../../util.js';
import {useTranslation, Trans} from 'react-i18next';
import {PageReference} from './PageReference';
import PanelInformationsVariation from './PanelInformationsVariation';
import PanelRepartition from './PanelRepartition';

export default function AbTestContainer({children}) {
	const { t } = useTranslation(['common']);
	const [modalIsOpen, setmodalIsOpen] = useState(false);
	const [panelInfosIsOpen, setPanelInfosIsOpen] = useState(false);
	const [id, setid] = useState('');
	const [variationPropertyObject, setvariationPropertyObject] = useState(cloneDeep(designPropertiesAbTestRedirection));
	const [confirmIsOpen, setconfirmIsOpen] = useState(false);
	const [variationSelected, setvariationSelected] = useState();
	const [panelRepartionIsOpen, setpanelRepartionIsOpen] = useState(false);
	const [label, setlabel] = useState<string | undefined >('');
	const [needToSave, setneedToSave] = useState(false);
	const {
		mode,
		variations,
		setVariations,
		campaign,
		step,
		isSaving,
		createNewVariationAbTest,
		handleUpdateObjetVariation,
		deleteVariation,
		cloneVariation,
		variationType,
		setvariationType,
		variationRangeIsEqual,
		variationAbTestDraft
	} = CampaignsContext();

	useEffect(()=>{
		if(variationAbTestDraft){
			handleSelectVariation(cloneDeep(variationAbTestDraft.DesignProperties[0]),variationAbTestDraft.Uid);
			setvariationPropertyObject(cloneDeep(variationAbTestDraft.DesignProperties[0]));
		}
	},[variationAbTestDraft]);

	function getLabel (vType : string){
		if(vType === 'Manual'){		
			return t('abTest.ManualDistribution');
		}
		if(vType === 'uniform'){
			return t('abTest.evenDistribution');
		}
		if(vType === 'PageTag'){
			return t('abTest.TagDistribution');
		}
	}

	useEffect(()=>{
		const newLabel = getLabel(variationType);
		setlabel(newLabel);
		
	},[variationRangeIsEqual, variationType]);

	function handleValidForm() {
		setPanelInfosIsOpen(false);
		handleUpdateObjetVariation(id,variationPropertyObject);
	}
	function handleSelectVariation(variationOptions: any, id : string){
		setvariationPropertyObject(variationOptions);
		setid(id);
		setPanelInfosIsOpen(true);
	}
	function handleChangeVariationPropertyObject (e : ChangeEvent<HTMLInputElement>) {
		const value = e.target.value;
		const name = e.target.name;
		const oldOjb = cloneDeep(variationPropertyObject);
		oldOjb.Value[name] = value;
		setvariationPropertyObject(oldOjb);
		if(name === 'RedirectionUrl'){
			setneedToSave(!needToSave);
		}
	}

	// function handleChangeVariationPropertyType (str : string) {
	// 	const newObj = cloneDeep(variationPropertyObject);
	// 	newObj.Value.RedirectionType.value = str;
	// 	setvariationPropertyObject(newObj)	;
	// }

	function openDelete(variation: any){
		setconfirmIsOpen(true);
		setvariationSelected(variation);
	}
	function openDuplicate(variation:any){
		cloneVariation(variation);
	}

	function confirmCallback () {
		deleteVariation(variationSelected);
		setconfirmIsOpen(false);
	}

	const createNewVariation = () => {
		createNewVariationAbTest();
	};

	useEffect(()=>{
		if(needToSave){
			handleValidForm();
			setneedToSave(false);
		}
	},[needToSave]);
	const originalV = variations.find((x) => x.IsOriginalVariation);

	if (step !== 1) return <></>;

	return (
		<div className='target_rules_outer'>
			<div className='target_rules_inner'>
				{mode === 'creating' &&
					<SectionMessage
						title={t('abTest.redirectTest')}
						text={
							<>
								<p>{t('abTest.redirectionHelpMessage1')}</p>
								<p>
									<Trans t={t} i18nKey="abTest.redirectionHelpMessage2">
										<strong>For example:</strong> you have created 3 versions of a landing page, you can A/B test them here.
									</Trans>
								</p>
							</>
						}
						canClose={true}
						closeTooltip={t('messages.dontShowThisAgain')}
						storageKey="ab_test_redirection_help_1"
						appearance="background_outline"
					/>
				}
				<PageReference
					isModal={true}
					modalIsOpen={modalIsOpen}
					setmodalIsOpen={setmodalIsOpen}
				/>
				<PanelInformationsVariation 
					panelIsOpen={panelInfosIsOpen}
					setpanelIsOpen={setPanelInfosIsOpen}
					variationPropertyObject={variationPropertyObject}
					//handleChangeVariationPropertyType={handleChangeVariationPropertyType}
					handleChangeVariationPropertyObject={handleChangeVariationPropertyObject}
					campaign={campaign}
				/>
				<Confirm
					title="Do to delete this variation?"
					text="This action will delete the corresponding variation"
					isOpen={confirmIsOpen}
					confirmColor="alert"
					confirmCallback={() =>confirmCallback() }
					onClose={() => setconfirmIsOpen(false)}
					width={500}
				/>
				<PanelRepartition 
					panelIsOpen={panelRepartionIsOpen}
					setpanelIsOpen={setpanelRepartionIsOpen}
					variations={variations}
					setVariations={setVariations}
					variationType={variationType}
					setvariationType={setvariationType}
				/>

				<Article title={t('abTest.reference')} size='l'>
					{campaign.UrlDebug &&
						<List removeVerticalMargin="true" size="s" horizontalAreaOffset={true}>
							<ListItem
								text={campaign.UrlDebug ? campaign.UrlDebug : t('common.notDefined')}
								description={campaign.UrlDebug ? '' : t('abTest.noReferencePage')}
								textIsItalic={!campaign.UrlDebug}
								textIsBold={false}
								onClick={() => setmodalIsOpen(true)}
								actions={
									<Btn
										style="reverse"
										icon='fas fa-pen'
										message={t('actions.edit')}
										size="s"
										horizontalSize='xxs'
									/>
								}
							/>
						</List>
					}
					{!campaign.UrlDebug &&
						<PageReference
							isModal={false}
						/>
					}
				</Article>

				<Article title={t('abTest.variations')} size='l'>
					{variations.length >= 2 &&
						<>
							<List removeVerticalMargin={true} hasLastBorder={true}>
								{variations.map((variation,i) => {
									const variationOptions = variation.DesignProperties[0];
									const isOriginal = originalV.UId === variation.UId;
									const url = isOriginal ? campaign.UrlDebug : variationOptions.Value.RedirectionUrl;
									return (
										<ListItem
											key={variation.UId}
											text={variationOptions.Value.Name}
											textIsBold={true}
											textIsItalic={isOriginal}
											description={
												<div className='ellips'>
													{isOriginal ? campaign.UrlDebug : variationOptions.Value.RedirectionUrl}
												</div>
											}
											onClick={isOriginal ? null : ()=>handleSelectVariation(variationOptions, variation.UId)}
											actions={
												<>
													{originalV.VariationType === 'Range' &&
														<Btn
															onClick={(ev)=>{ev.stopPropagation(); setpanelRepartionIsOpen(true);}}
															message={variation.RangeSize + ' %'}
															style="ghost"
															horizontalSize="xs"
															icon={'fas fa-chart-pie fa-sm'}
															rounded={true}
														/>
													}
													{originalV.VariationType === 'PageTag' && !isOriginal &&
														<Btn
															onClick={(ev)=>{ev.stopPropagation(); setpanelRepartionIsOpen(true);}}
															message={variation.Tag}
															style="ghost"
															horizontalSize="xs"
															icon={'fas fa-tag fa-sm'}
															rounded={true}
														/>
													}
													<IconBtn
														icon="fas fa-external-link-square-alt"
														href={url}
														target="_blank"
														rel="noreferrer"
														disabled={!url}
														onClick={(e) => e.stopPropagation()}
													/>
													<DropDownAbTest
														index={i}
														openDelete={()=>openDelete(variation)}
														openDuplicate={()=>openDuplicate(variation)}
														isDisabled={isOriginal}
													/>
												</>
											}
										/>
									);
								})}
							</List>
							<div className='flex flex_justify_between mt_30'>
								<Btn
									style="reverse"
									icon="fas fa-plus fa-sm"
									message={t('abTest.createVariation')}
									horizontalSize='xxs'
									onClick={()=>createNewVariation()}
									disabled={variations.length > 5}
									tooltip={variations.length > 5 ? t('abTest.AnABTestCanContain') : ''}
								/>
								<Btn
									style="ghost"
									color="secondary"
									message={label}
									horizontalSize='xxs'
									onClick={()=>setpanelRepartionIsOpen(true)}
								/>
							</div>
						</>
					}
					{variations.length < 2 &&
						<EmptyState
							imageUrl="/Assets/empty_abtest.svg"
							title={t('abTest.startByCreating')}
							titleBold={false}
							primaryAction={
								<Btn
									style="reverse"
									message={t('abTest.createVariation')}
									onClick={()=>createNewVariation()}
									disabled={!campaign.UrlDebug}
								/>
							}
							verticalSize={'xs'}
						/>
					}
					{!campaign.UrlDebug &&
						<Mask />
					}
				</Article>
				
				{children}
				
				{isSaving && (
					<SpinnerWheel wheelSize="l" />
				)}
			</div>
		</div>
	);
}
