import React,{useEffect, useState} from 'react';
import Btn from '../../../Components/Btn';
import Panel from '../../../Components/Panel';
import ModalHeader from '../../../Components/ModalHeader';
import ModalBody from '../../../Components/ModalBody';
import Avatar from '../../../Components/Avatar';
import Checkbox from '../../../Components/Checkbox';
import Switch from '../../../Components/Switch';
import {List, ListItem} from '../../../Components/List';
import {useAnalyticsConfigContext} from './userAnalyticsConfigContext';
export default function TenantConfigModal({
}) {
	const {
		handleOpenCloseModalTenant,
		tenantModalIsOpen,
		tenantSelectedToModify,
		referenceData,
		handleChangeTenant,
		isCreation,
	} = useAnalyticsConfigContext();

	const [arrayOfUserSelected, setarrayOfUserSelected] = useState([]);
	const [nb, setnb] = useState(0);
	const [oldNameTenant, setoldNameTenant] = useState('');

	const handleSelectOrUnSelectAccount =(tenantToChange, user, event) => {
		const value = event.target.checked;
		let newReferenceData  = {...arrayOfUserSelected};
		if(value){
			const arrayOfTenantFromElem = [...newReferenceData[tenantToChange], user.id];
			newReferenceData[tenantToChange] = arrayOfTenantFromElem;
			setarrayOfUserSelected( newReferenceData);
		}else{
			const arrayOfTenantFromElem = [...newReferenceData[tenantToChange]];
			const newArrayOfTenant = arrayOfTenantFromElem.filter(el => el !== user.id);
			newReferenceData[tenantToChange] = newArrayOfTenant;
			setarrayOfUserSelected( newReferenceData);
		}
	};

	useEffect(()=>{
		if(tenantSelectedToModify?.users){
			setarrayOfUserSelected({...referenceData?.tenant});
		}
	},[tenantSelectedToModify]);
	useEffect(()=>{
		if(arrayOfUserSelected){
			getNbUsersInList();
		}
	},[arrayOfUserSelected,tenantSelectedToModify]);
	useEffect(()=>{
		if(isCreation){
			setoldNameTenant(tenantSelectedToModify.name);
		}
	}, [isCreation]);
	function getNbUsersInList(){
		let nbN = 0;
		const users = arrayOfUserSelected[tenantSelectedToModify?.name];
		if (users?.length){
			nbN = users?.length;	
		} 
		setnb(nbN > 1 ? nbN + ' users' : nbN + ' user'); 
	}
	function handleEraseTenant(){
		let newReferenceData  = {...arrayOfUserSelected};
		const arrayOfTenantFromElem = newReferenceData[oldNameTenant];
		delete newReferenceData[oldNameTenant];
		newReferenceData[tenantSelectedToModify.name] = arrayOfTenantFromElem;
		handleChangeTenant(newReferenceData);
	}
	return (
		<Panel
			width="680"
			isOpen={tenantModalIsOpen}
			onClose={() => handleOpenCloseModalTenant(false)}
			noClose={true}
		>
			<ModalHeader>
				<div className='flex'>
					<div className="flex_item_fix">
						<a className="panel_close panel_close_left" onClick={() => handleOpenCloseModalTenant(false)}></a>
					</div>
					<div className='flex_item_full'>
						<div className='mb_8'>
							<span className='text_flag up_case'>{tenantSelectedToModify?.name}</span>
						</div>
						Who has access to this tenant?
					</div>
					<div className=''>
						<Btn
							message="Confirm"
							color="primary"
							onClickFunction={()=>handleChangeTenant(arrayOfUserSelected)}
						/>
					</div>
				</div>
			</ModalHeader>
			<ModalBody>
				{tenantSelectedToModify &&
					<List size='s'>
						{referenceData.users.map((user, i) => {
							const isSelected = tenantSelectedToModify.users.indexOf(user) != -1;
							return (
								<ListItem key={i}>
									<Switch
										name="role"
										checked={isSelected}
										value={isSelected}
										onChange={(e)=> handleSelectOrUnSelectAccount(tenantSelectedToModify?.name,user,e)}
										size='s'
										position='right'
										align='middle'
									>
										<div className='flex'>
											<div className='flex_item_fix mr_15'>
												<Avatar
													id={user.id}
													name={user.displayName}
												/>
											</div>
											<div className="flex_item_full">
												<div className="s_14 fw_medium grey_0">{user.displayName}</div>
												<div className="s_13 grey_2">{user.email}</div>
											</div>
										</div>
									</Switch>
								</ListItem>
							);
						})}
					</List>
				}
			</ModalBody>
		</Panel>
	);
}

