import React from 'react';
import Btn from '../../../Components/Btn';
import { useTranslation } from 'react-i18next';
import { useAuditContext } from '../context/ContextAudit';

export default function HeaderAi({
	handleGetAudit,
	stateAudit,
	//resultTask
}) {

	const [t] = useTranslation('kpi');
	const {
		isDev,
		showDevData
	} = useAuditContext();

	return (
		<section className="section no_bottom_pad section_primary flex flex_justify_between ">
			<div className="h1">
				{t('audit.scenarioRecommendation')}
			</div>
			{/* {resultTask?.status === 'Failed' &&  stateAudit !== 2 &&
				<Btn
					message={t('audit.newAnalyse')}
					onClick={()=>handleGetAudit()}
					icon="fas fa-sync-alt"
				/>
			}
			{resultTask?.status === 'Running' &&  stateAudit !== 2 &&
				<Btn
					message={t('audit.newAnalyse')}
					onClick={()=>handleGetAudit()}
					icon="fas fa-sync-alt"
				/>
			} */}
			{isDev && stateAudit !== 2 &&
				<Btn
					message={'Show dev data'}
					onClick={() => showDevData()}
					style="outline"
					color="secondary"
				/>
			}
			{stateAudit === 2 &&
				<Btn
					message={t('audit.newAnalyse')}
					onClick={()=>handleGetAudit()}
					icon="fas fa-sync-alt"
				/>
			}
		</section>
	);
}
