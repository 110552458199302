const scriptExport = function(props) {
	if (!cpg) return;

	var overlay = this;
	if (props.by_close_on_click) {
		overlay.addEventListener('click', function(){
			cpg.close();
		});
	}
};

export const componentPopinOverlay = {
	model: {
		defaults: {
			name: 'Overlay',
			removable: false,
			draggable: false,
			droppable: false,
			copyable: false,
			layerable: true,
			toolbar: [],
			stylable: ['background-color', 'opacity'],
			tagName: 'div',
			style: {
				'position': 'absolute',
				'top': '0',
				'left': '0',
				'right': '0',
				'height': '100%',
				'background-color': '#000000',
				'opacity': '.5',
			},
			traits: [
				{
					type: 'checkbox',
					name: 'by_close_on_click',
					label: 'Close popin by clicking on overlay',
					changeProp: true,
					tab: 'interaction'
				}
			],
			by_close_on_click: true,
			'script-export': scriptExport,
			'script-props': [
				'by_close_on_click'
			],
		}
	}
};
