import React, {useEffect, useState} from 'react';
import {useProductFeedEditionContext} from '../../../context/ProductFeedEditionContext';
import Btn from '../../../../../Components/Btn';
import {ModalMappingCategories} from './ModalMappingCategories';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {keyExistInConfig, reorderObjectsByPropertyValue} from '../../../utils';
import Confirm from '../../../../../Components/Confirm';
import EmptyState from '../../../../../Components/EmptyState';
import IconBtn from '../../../../../Components/IconBtn';
import {ModalHierarchy} from './ModalHierarchy';
import editionStyles from '../../ProductFeedEdition.module.css';
import TransformationsContainer from '../Transformations/TransformationsContainer';

export const OnePerCategoryMapping = (props) => {

	const {
		mappingObject,
		productFeedObject,
		handleChangeCategoryHierarchies,
		addHierarchy,
		deleteCategoryMapping
	} = useProductFeedEditionContext();

	const [categoriesArray, setCategoriesArray] = useState([]);
	const [modalCategoriesIsOpen, setModalCategoriesIsOpen] = useState(false);
	const [isExistingCategory, setIsExistingCategory] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState();
	const [showDelConfirm, setShowDelConfirm] = useState(false);
	const [modalHierarchieIsOpen, setModalHierarchieIsOpen] = useState(false);
	const [hierarchySelected, setHierarchySelected] = useState();
	const [categoryToDelete, setCategoryToDelete] = useState();
	const [hasSubCategories, setHasSubCategories] = useState(false);

	const hasKeyInObject = keyExistInConfig('Categories', mappingObject, null);

	useEffect(() => {
		if (mappingObject.Categories) {
			const copyArray = JSON.parse(JSON.stringify(mappingObject.Categories));
			const array = [];
			for (const [hierarchyKey, hierarchyCategory] of Object.entries(productFeedObject.categoryHierarchies)) {
				const filterCategories = copyArray.filter(object => hierarchyCategory.includes(object.beyableItemName));
				const reorderedCategories = reorderObjectsByPropertyValue(filterCategories, 'beyableItemName', hierarchyCategory);

				array.push({hierarchyName: hierarchyKey, categories: reorderedCategories});
			}
			setCategoriesArray(array);

			const hasSub = array.some(cat => cat.categories && cat.categories.length > 1);
			setHasSubCategories(hasSub);
		} else {
			setCategoriesArray([]);
		}
	}, [mappingObject, productFeedObject]);

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};
	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const hierarchyName = result.source.droppableId;
		const retrieveHierarchy = categoriesArray.find(e => e.hierarchyName === hierarchyName);
		const items = reorder(
			retrieveHierarchy.categories,
			result.source.index,
			result.destination.index
		);

		handleChangeCategoryHierarchies(items.map(e => e.beyableItemName), hierarchyName);
	};

	const retrieveMappingIndex = (categoryName) => {
		return mappingObject.Categories.findIndex(e => e.beyableItemName === categoryName);
	};

	return (
		<>
			{categoriesArray && categoriesArray.length > 0 &&
				<>
					<div className={editionStyles.table +' '+ editionStyles.table_3}>
						<div className={editionStyles.table_head_row}>
							<div className={editionStyles.table_col}>Field</div>
							<div className={editionStyles.table_col}>Category name</div>
							<div className={editionStyles.table_col}></div>
						</div>
						{categoriesArray.map((cat, i) => (
							<div key={i}>
								{i > 0 &&
									<div className={hasSubCategories ? editionStyles.table_sep_l : editionStyles.table_sep}></div>
								}
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId={cat.hierarchyName}>
										{
											(provided, snapshot) =>
												<div
													{...provided.droppableProps}
													ref={provided.innerRef}
												>
													{cat.categories.map((category, j) => (
														<Draggable key={j} draggableId={'ok' + j} index={j}>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	className={editionStyles.table_body_row}
																>
																	<div className={editionStyles.table_col}>
																		{j > 0 ?
																			<span className={editionStyles.table_sub_label} style={{'--sub_label': j}}>
																				{category.sourceField}
																			</span>
																			:
																			<span>{category.sourceField}</span>
																		}
																	</div>
																	<div className={editionStyles.table_col}>
																		{category.beyableItemName}
																	</div>
																	<div className={editionStyles.table_col}>
																		{j == 0 &&
																			<IconBtn 
																				tooltip="Add sub category"
																				icon='fas fa-plus'
																				onClick={() => {
																					setModalCategoriesIsOpen(true);
																					setHierarchySelected(cat.hierarchyName);
																				}}
																			/>
																		}
																		<IconBtn 
																			tooltip="Edit category"
																			icon='fas fa-pen'
																			onClick={() => {
																				setSelectedCategory({
																					category: category,
																					index: retrieveMappingIndex(category.beyableItemName),
																					hierarchy: cat,
																				});
																				setIsExistingCategory(true);
																				setModalCategoriesIsOpen(true);
																				setHierarchySelected(cat.hierarchyName);
																			}}
																		/>
																		<IconBtn 
																			tooltip="Delete category"
																			icon='fas fa-trash'
																			onClick={() => {
																				setCategoryToDelete(category);
																				setShowDelConfirm(true);
																				setHierarchySelected(cat.hierarchyName);
																			}}
																		/>
																	</div>
																	{props.canUseTranformations &&
																		<TransformationsContainer
																			objectIdenfifier={'Categories'}
																		/>
																	}
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</div>
										}
									</Droppable>
								</DragDropContext>
							</div>
						))}
					</div>
					<div className='mt_10'>
						<Btn
							style="reverse"
							icon="fas fa-plus fa-sm"
							message="Add main category"
							size="s"
							horizontalSize='xs'
							onClick={() => {
								setIsExistingCategory(false);
								setHierarchySelected(null);
								setModalCategoriesIsOpen(true);
							}}
						/>
					</div>
				</>
			}

			{categoriesArray.length === 0 &&
				<EmptyState
					text="No category yet"
					verticalSize='s'
					primaryAction={
						<Btn
							onClick={() => {
								setIsExistingCategory(false);
								setHierarchySelected(null);
								setModalCategoriesIsOpen(true);
							}}
							message="Add category"
							icon="fas fa-plus fa-sm"
							style="reverse"
							horizontalSize="xs"
						/>
					}
				/>
			}
			<ModalMappingCategories
				modalCategoriesIsOpen={modalCategoriesIsOpen}
				setModalCategoriesIsOpen={setModalCategoriesIsOpen}
				isExistingCategory={isExistingCategory}
				selectedCategory={selectedCategory}
				hasKeyInObject={hasKeyInObject}
				hierarchySelected={hierarchySelected}
				setHierarchySelected={setHierarchySelected}
				setSelectedCategory={setSelectedCategory}
			/>
			<Confirm
				title="Do you really want to delete this category ?"
				text="This action is irreversible"
				confirmText="Delete"
				confirmColor="alert"
				cancelText="Cancel"
				isOpen={showDelConfirm}
				confirmCallback={() => {
					deleteCategoryMapping(categoryToDelete, hierarchySelected);
					setHierarchySelected(null);
				}}
				onClose={() => {
					setShowDelConfirm(false);
					setHierarchySelected(null);
				}}
			/>
			<ModalHierarchy
				modalHierarchieIsOpen={modalHierarchieIsOpen}
				setModalHierarchieIsOpen={setModalHierarchieIsOpen}
			/>
		</>
	);
};