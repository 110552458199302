import React, { useState, useEffect, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Map from './VariantsMapper';
import './VariantsTable.css';

export default function VariantsTable({variant, selectVariantCallback, unSelectVariantCallback, resetSelect, updateDataCallback}) {
	const [columns, setColumns] = useState([{ dataField: 'id', text: '' }]);
	const [data, setData] = useState([ { id:0 } ]);
	const [selected, setSelected] = useState([]);
	const [editingRowIndex, setEditingRowIndex] = useState(-1);

	const getData = (specs) => Map(specs, (h, hText) => {
		return { dataField: h, text: hText ? hText : h };
	});

	const previewOverlay = document.createElement('div');
	previewOverlay.classList.add('variant_preview_overlay');
	previewOverlay.innerHTML = 'Variant preview overlay';
	let previewClone;

	const unSelect = function(){
		setSelected([]);
		unSelectVariantCallback && unSelectVariantCallback();
	};
    
	const selectRow = {
		mode: 'checkbox',
		clickToSelect: false,
		style: { backgroundColor: '#006fff' },
		selected: selected,
		hideSelectAll: true,
		onSelect: (row, isSelect, rowIndex, e) => {
			if (isSelect)
			{
				setSelected([rowIndex+1]);
				selectVariantCallback && selectVariantCallback(row);
			}
			else{
				unSelect();
			}
		}
	};

	const paginationOptions = {};
   
	useEffect(() => {
		var [headers, data] = getData(variant && variant.VarianteSpecs.length > 0 ? variant.VarianteSpecs : []);
		setColumns(headers);
		setData(data);
	}, [variant]);

	useEffect(() => {
		setSelected([]);
	}, [resetSelect]);

	return (
		data && data.length > 0 ?
			<Fragment>
				<BootstrapTable keyField='id' data={data} columns={columns} selectRow={selectRow}
					pagination={paginationFactory(paginationOptions)}
					cellEdit={ cellEditFactory({ 
						mode: 'dbclick', 
						blurToSave: true,
						onStartEdit: (row, column, rowIndex, columnIndex) => { setEditingRowIndex(rowIndex); },
						beforeSaveCell: (oldValue, newValue, row, column) => {
							if (column.dataField.startsWith('Properties.')){
								const key = column.dataField.replace('Properties.', '');
								if (!row.Properties.hasOwnProperty(key)) {row.Properties[key] = '';}
							}
							else if (!row.Properties.hasOwnProperty(column.dataField)){
								row[column.dataField] = '';
							}
						},
						afterSaveCell: (oldValue, newValue, row, rowInfo) => { 
							updateDataCallback(data, row, selected.length > 0 && selected[0] === editingRowIndex+1); } 
					}) }
				/>
				{selected.length ?
					<div className="variant_overlay">
						<div className="variant_overlay_message" onClick={() => unSelect([])}>
                            Variant preview mode is active.
                            Click here to leave it.
							<i className="fas fa-times"></i>
						</div>
					</div>
					: ''
				}
			</Fragment>
			:
			<div className="variants_empty">
            There is no variant data for now.
				<br />
            Use <strong>DOWNLOAD CVS</strong> button to download a template file. Edit it, and import it.
			</div>
	);
}
