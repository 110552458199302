import React, { useState, useEffect } from 'react';
import Modal from '../../../../Components/Modal';
import Btn from '../../../../Components/Btn';
import { onSiteContext } from '../context';
import { Trans, useTranslation } from 'react-i18next';
export default function EditCampaignPermissions({ }) {
	const {
		accountId,
		ReportingCampaignServices,
		systemServices,
		setneedFetchList,
		openCloseModalPermissions,
		modalPermissionsIsOppen,
		currentCampaign
	} = onSiteContext();
	const [permissions, setpermissions] = useState();
	const [permissionUser, setpermissionUser] = useState();

	const [isLoading, setisLoading] = useState(false);
	const handleChangePermissionsCampaign = (type) => {
		if (type == 'CAMPAIGNS_CAN_VIEW') {
			const permissionTypeValue = !permissions[type];
			if (permissionTypeValue) {
				setpermissions({ ...permissions, [type]: permissionTypeValue });
			} else {
				const newPermissions = {
					CAMPAIGNS_CAN_CREATE: false,
					CAMPAIGNS_CAN_DELETE: false,
					CAMPAIGNS_CAN_SUSPEND: false,
					CAMPAIGNS_CAN_VIEW: false,
				};
				setpermissions(newPermissions);
			}
		} else {
			const permissionTypeValue = !permissions[type];
			setpermissions({ ...permissions, [type]: permissionTypeValue });
		}
	};
	const [t, i18n] = useTranslation('common');
	// Add fetching to backend need to reload the campaignList
	useEffect(() => {
		if (currentCampaign) {
			const campaignPermissions = currentCampaign.defaultPermissions;
			let listOfCampaignPermission = {};
			if (campaignPermissions) {
				campaignPermissions.forEach(perm =>
					listOfCampaignPermission[perm.permission] = perm.isAllowed
				);
			}
			setpermissions(listOfCampaignPermission);
			const currentUserPermissions = currentCampaign.currentUserPermissions;
			let currentUserPermissionsList = {};
			if (currentUserPermissions) {
				currentUserPermissions.forEach(perm =>
					currentUserPermissionsList[perm.permission] = perm.isAllowed
				);
			}
			setpermissionUser(currentUserPermissionsList);
		}
	}, [currentCampaign]);
	const putCampaignPermissions = () => {
		setisLoading(true);
		const newPermissions = {
			AccountId: accountId,
			Campaignid: currentCampaign.id,
			Permissions: permissions
		};
		ReportingCampaignServices.putUserPermissionsForCampaign(accountId, currentCampaign.id, newPermissions,
			newData => {
				setisLoading(false);
				openCloseModalPermissions(false,currentCampaign);
				setneedFetchList(true);
				systemServices.showSuccess(t('EditCampaignPermissions.msgPermission'));

			}, err => {
				setisLoading(false);
				systemServices.showError(t('EditCampaignPermissions.msgPermissionError'));
			});
	};
	if (!currentCampaign) {
		return (<></>);
	}
	return (
		<div>
			<Modal
				isOpen={modalPermissionsIsOppen}
				width="660"
				onClose={() => openCloseModalPermissions(false)}
			>
				<div className="modal_header has_border">
					{t('EditCampaignPermissions.editCampaign')}
					<div className="modal_header_desc">{currentCampaign.name}</div>
				</div>
				<div className="modal_body" style={{ margin: 'auto' }}>
					<article className="radio_box_inner mt_40" >
						<label className={permissions && permissions.CAMPAIGNS_CAN_VIEW ? 'radio_box checked' : 'radio_box'} >
							<input type="checkbox" name="" className="ng-pristine ng-untouched ng-valid ng-empty" onClick={() => handleChangePermissionsCampaign('CAMPAIGNS_CAN_VIEW')} />
							<span><i className="fa fa-check"></i></span>
							<i className="fas fa-eye"></i>
							<p>{t('actions.see')}</p>
						</label>
						<label className={permissions && permissions.CAMPAIGNS_CAN_VIEW ?
							permissions.CAMPAIGNS_CAN_CREATE ? 'radio_box checked' : 'radio_box' : 'radio_box disabled'} >
							<input type="checkbox" name="" className="ng-pristine ng-untouched ng-valid ng-empty"
								onClick={() => handleChangePermissionsCampaign('CAMPAIGNS_CAN_CREATE')}
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="far fa-edit"></i>
							<p>{t('actions.edit')}</p>
						</label>
						<label className={permissions && permissions.CAMPAIGNS_CAN_VIEW ?
							permissions.CAMPAIGNS_CAN_SUSPEND ? 'radio_box checked' : 'radio_box' : 'radio_box disabled'} >
							<input type="checkbox" name="" className="ng-pristine ng-untouched ng-valid ng-empty"
								onClick={() => handleChangePermissionsCampaign('CAMPAIGNS_CAN_SUSPEND')}
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="far fa-play-circle"></i>
							<p>{t('EditCampaignPermissions.play')}</p>
						</label>
						<label className={permissions && permissions.CAMPAIGNS_CAN_VIEW ?
							permissions.CAMPAIGNS_CAN_DELETE ? 'radio_box checked' : 'radio_box' : 'radio_box disabled'} >
							<input type="checkbox" name="" className="ng-pristine ng-untouched ng-valid ng-empty"
								onClick={() => handleChangePermissionsCampaign('CAMPAIGNS_CAN_DELETE')}
							/>
							<span><i className="fa fa-check"></i></span>
							<i className="fas fa-trash-alt"></i>
							<p>{t('actions.delete')}</p>
						</label>
					</article>
				</div>
				<div className="modal_footer al_right has_border">
					<Btn
						onClickFunction={() => openCloseModalPermissions(false)}
						message={t('actions.cancel')}
						color="secondary"
						size="l"
						style="outline"
					/>
					<Btn
						onClickFunction={() => putCampaignPermissions()}
						message={t('actions.confirm')}
						size="l"
						color="primary"
					/>
				</div>
				{isLoading &&
                    <div className="spinner_overlay">
                    	<div className="spinner">
                    		<div className="rect1"></div>
                    		<div className="rect2"></div>
                    		<div className="rect3"></div>
                    		<div className="rect4"></div>
                    		<div className="rect5"></div>
                    	</div>
                    </div>
				}
			</Modal>
		</div>
	);
}
