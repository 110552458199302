import React, { useState,useEffect } from 'react';
import Dropdown from '../../Components/Dropdown';
import Listbox from '../../Components/Listbox';
import ListboxItem from '../../Components/ListboxItem';
import './Nav.css';

export default function SelectDevice({
	deviceSelected,
	handleChangeDeviceSelected,
	initialValue,
	resetSource,
	cpg
}) {

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [devicesCpg, setDevicesCpg] = useState([]);
	const [button, setButton] = useState(<></>);
	const [isActive, setIsActive] = useState(false);
	useEffect(()=>{
		if(deviceSelected){
			const elementDevices = deviceSelected.map(d => {
				if(typeof d === 'string'){
					return d;
				}else{
					const labelKeyDevices = [
						{
							key:'D_PC',
							label: 'desktop'
						},
						{
							key:'D_SMARTPHONE',
							label: 'desktop'
						},
						{
							key:'D_TABLET',
							label: 'desktop'
						},
					];
					const label = labelKeyDevices.find(x => x.key === d.deviceId);
					return label?.label;
					
				}
			});
			setDevicesCpg(elementDevices);
		}
	},[deviceSelected]);
	useEffect(()=>{
		if(devicesCpg){
			const isActi = (devicesCpg.length > 0) ? true : false;
			setIsActive(isActi);
			// eslint-disable-next-line no-inner-declarations
			function getLabel (){
				if(!devicesCpg){
					return;
				}
				if(devicesCpg.length === 0){
					return 'All';
				}
				if(devicesCpg.length > 1){
					return devicesCpg[0] + ' +' + (devicesCpg.length -1);
				}
				if(devicesCpg.length === 1){
					return devicesCpg[0] ;
				}

			}
			const activeLabel =getLabel();
			const btn = (
				<div className={devicesCpg && devicesCpg.length >= 1 ? 'analytics_range active' : 'analytics_range'}>
					<div className="flex">
						<div className="flex_item_full">
							<div className="analytics_range_sup">Device Type</div>
							<div className="analytics_range_value">
								{devicesCpg && <>{activeLabel}</>}
							</div>
						</div>
						<div className="flex_item_fix analytics_range_drop_arrow"></div>
					</div>
				</div>
			);
			setButton(btn);
		}
	},[devicesCpg]);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
			minWidth={180}
		>
			<Listbox>
				<ListboxItem
					onClick={() => {resetSource(cpg,false); setDropdownIsOpen(false);}}
					message={<>All <span className='grey_3 s_13'>(no filter)</span></>}
					selected={!isActive}
					hasSelectedIcon={true}
				/>
				<li className='hr' />
				{initialValue && initialValue.map((value) => 
					<ListboxItem
						key={value.value}
						onClick={() => handleChangeDeviceSelected(cpg,value.value)}
						message={value.value}
						selected={deviceSelected.includes(value.value)}
						hasCheckbox={true}
					/>
				)}
			</Listbox>
		</Dropdown>
	);
}

