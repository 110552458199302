import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import PanelArticle from '../../../Components/PanelArticle';
import InputCustom from '../../../Components/InputCustom';
import IconBtn from '../../../Components/IconBtn';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

export default function UtmTrafficContainer({ 
	utmDimensionDraft,
	changeUTMSelected,
	nameUtm,
	deleteUtmItem
}) {

	const [t] = useTranslation(['kpi']);
	const tooltipID = uuidv4();
	const tooltipID2 = uuidv4();

	const handleChangeSearch = (event) => {
		changeUTMSelected(nameUtm, event.target.value);
	};

	return (
		<PanelArticle size="s">
			<div className="flex">
				<div className="flex_item_full fw_medium">
					UTM {nameUtm}
				</div>
				<ReactTooltip
					id={tooltipID}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="click"
					delayShow={600}
				/>
			</div>
			<div className='flex flex_justify_between mt_15 mb_5'>
				<ReactTooltip
					id={tooltipID2}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="click"
					delayShow={600}
				/>
				<InputCustom
					value={utmDimensionDraft[nameUtm]}
					type="text"
					name={nameUtm}
					placeholder={t('utm.Search for an UTM')}
					onChange={(v) => handleChangeSearch(v)}
					fullWidth={true}
				/>

				<div className="flex_item_fix ml_10">
					<IconBtn
						icon="fas fa-trash"
						tooltip={t('utm.Remove filter')}
						onClick={(i) => deleteUtmItem(nameUtm,i)}
						hoverColor="alert"
						disabled={!utmDimensionDraft[nameUtm]}
					/>
				</div>
			</div>
		</PanelArticle>
	);
}
