import React from 'react'
import formatMoment from '../../../../Constants/FormatsMoments';
export default function CampaignItem({ campaign, handleOnClickCampaignIdToLoad, campaignId }) {
    const { formatDateToLocal } = formatMoment;

    const today = moment();
    const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
    const dateEndOfOperation = formatDateToLocal(campaign.endDate);
    const isStoppedBoolOperation = campaign.isStopped;
    const isTestModeOperationBool = false;
    let statusOperation = {};
    //if dateEnd > today
    if (dateEndOfOperation > today) {
        //if begin > today and notStopped
        if (dateBeginOfOperation > today && !isStoppedBoolOperation) {
            if (isTestModeOperationBool) {
                statusOperation = { id: 'Testing', label: 'Testing', cssClass: 'status-planned testing' }
            } else {
                statusOperation = { id: 'Scheduled', label: 'Scheduled', cssClass: 'status-planned' }
            }
        }
        else if (!isStoppedBoolOperation) {
            if (isTestModeOperationBool) {
                statusOperation = { id: 'Testing', label: 'Testing', cssClass: 'status-testing' }
            } else {
                statusOperation = { id: 'Ongoing', label: 'Ongoing', cssClass: 'status-ongoing' }
            }
        } else {
            statusOperation = { id: 'Stopped', label: 'Stopped', cssClass: 'status-stopped' }
        }
    } else {
        statusOperation = { id: 'Past', label: 'Past', cssClass: 'status-past' }
    }


    return (
        <li key={campaign.Key} onClick={() => handleOnClickCampaignIdToLoad()}>
            <a className={campaignId === campaign.id ? "page_sidebar_list_item selected" : "page_sidebar_list_item"} >
                <span className={'status_bullet '
                    + statusOperation.cssClass
                }></span>
                {campaign.name}
            </a>
        </li>
    )
}
