import React, { useEffect } from 'react'
import Btn from '../../../../Components/Btn'
import SelectCustom from '../../../../Components/SelectCustom'
import InputCustom from './../../../../Components/InputCustom';
import './CategoryProductFeedContainer.css'
export default function CategoryProductFeedContainer({ productFeedCategoryArr, setProductFeedCategory, metaDataToMap, setChangesIsNotSaved, activeFeed }) {

    const optionList = metaDataToMap && Object.values(metaDataToMap).map(x => ({ value: x, label: x }))
    optionList && optionList.push({ value: "", label: "" })

    const CreateNewHierarchy = (e) => {
        e.preventDefault()
        const array = [...productFeedCategoryArr]
        let newId = array.length + 1
        const getNewId = () => {
            let ids = []
            let number
            if (array.length !== 0) {
                array.forEach(x =>
                    ids.push(x.id))
                number = parseInt(Math.max(...ids) + 1)
            } else {
                number = array.length + 1
            }
            if (ids.includes(number)) {
                number = array.length + 55
            }
            else {
                newId = number
            }
        }
        getNewId()
        const ProductFeedProductHierarchyConfigModel =
        {
            name: "",
            categories: [{ name: `Category ${newId}`, field: "", id: newId }],
            id: newId,
            toggle: true
        }
        const productFeedCategory = ([...productFeedCategoryArr, ProductFeedProductHierarchyConfigModel])
        setProductFeedCategory(productFeedCategory)
        setChangesIsNotSaved(true)
    }
    const CreateNewCategory = (e, id) => {
        e.preventDefault()
        const array = productFeedCategoryArr.filter(x => x.id === id)[0].categories
        let newId = array.length + 1
        const getNewId = () => {
            let ids = []
            let number
            if (array.length !== 0) {
                array.forEach(x =>
                    ids.push(x.id))
                number = parseInt(Math.max(...ids) + 1)
            } else {
                number = array.length + 1
            }
            if (ids.includes(number)) {
                number = array.length + 55
            }
            else {
                newId = number
            }
        }
        getNewId()
        const ProductFeedProductHierarchyConfigModel = [...array, { name: `Category ${newId}`, field: "", id: newId }]
        const categoryPut = productFeedCategoryArr.filter(x => x.id !== id)
        let NewCat = productFeedCategoryArr.filter(x => x.id == id)[0]
        NewCat.categories = ProductFeedProductHierarchyConfigModel
        const productFeedCategory = ([...categoryPut, NewCat])
        setProductFeedCategory(productFeedCategory)
        setChangesIsNotSaved(true)
    }

    const handleChangeHierarchieName = (id, e) => {
        const newSelectValue = e.target.value
        let myStateToChange = productFeedCategoryArr.filter(elem => elem.id === id)[0]
        myStateToChange[e.target.name] = newSelectValue
        const oldState = productFeedCategoryArr.filter(elem => elem.id !== id)
        setProductFeedCategory([...oldState, myStateToChange])
        setChangesIsNotSaved(true)
    }
    const handleChangeCategoryName = (e, hierarchieId, elemId) => {
        const newSelectValue = e.target.value
        let myStateToChange = productFeedCategoryArr.filter(elem => elem.id === hierarchieId)[0]
        myStateToChange.categories.filter(cat => cat.id === elemId)[0].name = newSelectValue
        const oldState = productFeedCategoryArr.filter(elem => elem.id !== hierarchieId)
        setProductFeedCategory([...oldState, myStateToChange])
        setChangesIsNotSaved(true)
    }
    const handleChangeCategoryField = (e, hierarchieId, elemId) => {
        const newSelectValue = e.target.value
        let myStateToChange = productFeedCategoryArr.filter(elem => elem.id === hierarchieId)[0]
        myStateToChange.categories.filter(cat => cat.id === elemId)[0].field = newSelectValue
        const oldState = productFeedCategoryArr.filter(elem => elem.id !== hierarchieId)
        setProductFeedCategory([...oldState, myStateToChange])
        setChangesIsNotSaved(true)
    }
    const deleteACategory = (hierarchieId, elemId) => {
        const myStateToChange = productFeedCategoryArr.filter(elem => elem.id === hierarchieId)[0]
        myStateToChange.categories = myStateToChange.categories.filter(cat => cat.id !== elemId)
        const oldState = productFeedCategoryArr.filter(elem => elem.id !== hierarchieId)
        setProductFeedCategory([...oldState, myStateToChange])
        setChangesIsNotSaved(true)
    }
    const deleteAHierarchie = (hierarchieId) => {
        const myStateToChange = productFeedCategoryArr.filter(elem => elem.id !== hierarchieId)
        setProductFeedCategory([...myStateToChange])
        setChangesIsNotSaved(true)
    }
    function sortById(a, b) {
        if (a && b) {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
        }
        return 0;
    }
    const selectZoneToPut = (e) => {
        let zoneToToggle = productFeedCategoryArr.filter(elem => elem.id === e)[0]
        zoneToToggle.toggle = true
        let newArray = productFeedCategoryArr.filter(x => x.id !== e)
        setProductFeedCategory([...newArray, zoneToToggle])
    }
    const unSelectZoneToPut = (e) => {
        let zoneToToggle = productFeedCategoryArr.filter(elem => elem.id === e)[0]
        delete zoneToToggle.toggle
        let newArray = productFeedCategoryArr.filter(x => x.id !== e)
        setProductFeedCategory([...newArray, zoneToToggle])
    }

    return (
        <div>
            <div className="category_product_header">
                <h2>Category hierarchies</h2>
                <span className="category_feed_add_hierarchie_btn">
                    {
                        <Btn
                            onClickFunction={(e) => CreateNewHierarchy(e)}
                            message="Create a new hierachy"
                            icon="fas fa-plus"
                            style="outline"
                        />
                    }
                </span>
            </div>
            <div className="category_product_feed_category_container">
                {
                    productFeedCategoryArr && productFeedCategoryArr.sort(sortById).map((hierarchies, i) =>
                        <div className="category_product_feed_hierarchie_container" key={i} >
                            {!hierarchies.toggle ?
                                <div className="category_product_zone_actions">
                                    <div className="chevron" onClick={(e) => selectZoneToPut(hierarchies.id)}> <i className="fas fa-chevron-right"></i> <span className="name-zone-chevron">{hierarchies.name} ({hierarchies.categories.length} categories)</span> </div>
                                    <button className="delete-hierarchie"
                                        onClick={(e) => deleteAHierarchie(hierarchies.id)}
                                    ><i className="fa fa-times"></i></button>
                                </div>

                                :
                                <div className="category_product_zone_actions" onClick={(e) => unSelectZoneToPut(hierarchies.id)}>
                                    <div className="chevron"> <i className="fas fa-chevron-up"></i> <span className="name-zone-chevron">{hierarchies.name}</span> </div>
                                    <button className="delete-hierarchie"
                                        onClick={(e) => deleteAHierarchie(hierarchies.id)}
                                    ><i className="fa fa-times"></i></button>
                                </div>}
                            <div className={hierarchies.toggle ? 'container_hierarchie display_container_hierarchie' : 'container_hierarchie'}>
                                <div className="header_hierarchie">
                                    <InputCustom
                                        value={hierarchies.name}
                                        name="name"
                                        // label={`Name hierarchie`}
                                        placeholder="Enter a hierarchy name"
                                        type="text"
                                        onChange={(e) => handleChangeHierarchieName(hierarchies.id, e)}
                                    />

                                    <span className="delete-category" onClick={(e) => CreateNewCategory(e, hierarchies.id)}>
                                        <i className="fas fa-plus"></i>Add a category
                                    </span>
                                </div>
                                <div className="category_hierarchie_container">
                                    <h2>Category hierarchy </h2>
                                    <ul>
                                        {hierarchies.categories && hierarchies.categories.sort(sortById).map((x, i) => < li key={i}>
                                            <div className="header_list_categorie">
                                                <InputCustom
                                                    value={x.name}
                                                    name="name"
                                                    label={activeFeed && activeFeed.source !== "Prestashop" ? "" : `Name`}
                                                    placeholder="Enter a category name"
                                                    type="text"
                                                    onChange={(e) => handleChangeCategoryName(e, hierarchies.id, x.id)}
                                                />
                                                {
                                                    activeFeed && activeFeed.source !== "Prestashop" ?
                                                        <>
                                                            <SelectCustom
                                                                name={x.name}
                                                                optionsList={optionList}
                                                                // label={`Category ${x.name}`}
                                                                defaultValueDisabled={{ value: "", label: "" }}
                                                                value={x.field}
                                                                onChangeFunction={(e) => handleChangeCategoryField(e, hierarchies.id, x.id)}
                                                            />
                                                            <button className="delete-category"
                                                                onClick={(e) => { deleteACategory(hierarchies.id, x.id) }}
                                                            ><i className="fa fa-times"></i></button>
                                                        </>
                                                        :
                                                        <div className="custom_input_prestashop">
                                                            <InputCustom
                                                                value={x.field}
                                                                name="name"
                                                                label={`Value`}
                                                                placeholder="Enter a value"
                                                                type="text"
                                                                onChange={(e) => handleChangeCategoryField(e, hierarchies.id, x.id)}
                                                            />
                                                            <button className="delete-category"
                                                                onClick={(e) => { deleteACategory(hierarchies.id, x.id) }}
                                                            ><i className="fa fa-times"></i></button>
                                                        </div>
                                                }
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>


                        </div>
                    )
                }
            </div>

        </div>
    )
}
