import React, { useState, useContext, useEffect } from 'react';
import { useHistory, Prompt, Route } from 'react-router-dom';

import NavBusinessOperationContainer from './Nav/NavBusinessOperationContainer';
import DesignBusinessOperationContainer from './Design/DesignBusinessOperationContainer';
import CreateBusinessOperationContainer from './Create/CreateBusinessOperationContainer';
import { CreateBusinessOperationContext } from './Context/ContextCreateBusinessOperations';
import CustomizeContainer from './Customize/CustomizeContainer';
import TargetBusinessOperationContainer from './Target/TargetBusinessOperationContainer';
import PopinConfirm from './../../../Components/PopinConfirm';
import Btn from './../../../Components/Btn';
import useEventBroker from '../../../Hooks/useEventBroker';
import TinyLoader from './../../../Components/TinyLoader';
import bronze from '../../../Permissions/rules-bronze';
import gold from '../../../Permissions/rules-gold';
import silver from '../../../Permissions/rules-silver';

export default function BusinessOperationContainer({ accountId, getZones, postOperationById, routeParams, getUserById, getPagesTypes, getOperationById, idOfExistantOp, recomputeOrchestration, putOperationById, deleteLibrary, isAdmin, getAccessToken, deleteOperationById, permissions, businessOperationServices, systemServices
}) {
	const {
		operation,
		setOperation,
		isexistant,
		setIsExistant,
		listBanners,
		setListBanners,
		selectionPagesType,
		setSelectionPagesType,
		pageNameSelected,
		poppinIsCliked,
		setPoppinIsClicked,
		stepBusinessOperationCreate,
		setStepBusinessOperationCreate,
		setZonesDataOfAccount,
		arrayOfTypePage,
		zoningCustomizeListValues,
		setZoningCustomizeListValues,
		setDevices,
		isFirstRenderOfExistantOp,
		setIsFirstRenderOfExistantOp,
		isFakeUpload,
		setListOfOperationExistant,
		setDeviceSelected,
		setUserSimplifyRole,
		planAccount,
		setPlanAccount
	} = useContext(CreateBusinessOperationContext);

	const [returnLink, setReturnLink] = useState();
	const [isClickLink, setIsClickLink] = useState([]);
	const [operationData, setOperationData] = useState();
	const [valueOfStatus, setValueOfStatus] = useState();
	const [isSavingOperation, setIsSavingOperation] = useState(false);
	const { subscribe } = useEventBroker();

	// When operation exist get data at the first render of the component
	const getDataOfOperation = () => {
		if (idOfExistantOp && isFirstRenderOfExistantOp) {
			setListOfOperationExistant();
			getOperationById(accountId, idOfExistantOp,
				newData => {
					setOperationData(newData);
				}
			);
		}
		else if (!idOfExistantOp) {
			getDataZones();
		}
	};
	useEffect(() => {
		getDataOfOperation();
	}, []);

	// Then get data zones 
	const getDataZones = () => {
		getPagesTypes(accountId,
			newData => {
				setZonesDataOfAccount(newData);
			}
		);
	};

	subscribe('evt/businessoperations/resizeBannerWorkflowTerminated', e => {
		const { libraryId } = e.detail;
		getbannersLibrary(libraryId);
	});

	const getbannersLibrary = (libraryId) => {
		const idTrueLibrary = libraryId !== '00000000-0000-0000-0000-000000000000';
		if (libraryId && idTrueLibrary) {
			businessOperationServices.getbannersLibrary(accountId, libraryId,
				bannersOfOperation => {
					setListBanners(bannersOfOperation.banners);
				},
				error => systemServices.showError('An error occured while fetching the operation banners'));
		}
	};

	// Then charge data on operation object
	const chargeDataInformationOnOp = () => {
		if (operationData && isFirstRenderOfExistantOp) {
			const data = { ...operation };
			data.operation.name = operationData.name;
			data.operation.description = operationData.description;
			data.operation.startDate = operationData.startDate;
			data.operation.endDate = operationData.endDate;
			data.operation.accountId = operationData.accountId;
			data.operation.locations = operationData.locations;
			data.operation.isActive = operationData.isActive;
			data.operation.isStopped = operationData.isStopped;
			operationData.libraryId !== '00000000-0000-0000-0000-000000000000' ? data.operation.libraryId = operationData.libraryId : null;
			data.operation.operationId = operationData.operationId;
			data.operation.extensions = operationData.extensions;
			// Get id's for the specifications
			let operationDataSpecificationsWithId = [];
			if (operationData.specifications) {
				const array = [...operationData.specifications];
				operationDataSpecificationsWithId = array.map((x, i) => ({ ...x, id: i }));
				data.operation.specifications = operationDataSpecificationsWithId;
				if (data.operation.accountId) {
					setOperation(data);
					getbannersLibrary(operationData.libraryId);
					setDevices(operationData.extensions.devices);
					if (operationData.extensions.devices.includes('mobile')) {
						setDeviceSelected('Mobile');
					} else {
						setDeviceSelected('Desktop');
					}
					setIsExistant(true);
					getDataZones();
				}
			}
		}
	};
	useEffect(() => {
		chargeDataInformationOnOp();
	}, [operationData]);


	// Then setSelectionPagesType
	const selectSelectionPagesType = () => {
		if (isexistant && isFirstRenderOfExistantOp) {
			let referenceArray = [];
			if (!operation.operation.extensions.pagesTypes) {
				operation.operation.locations.forEach(item => {
					referenceArray.push(item.pageType);
					return referenceArray;
				});
			} else {
				referenceArray = operation.operation.extensions.pagesTypes;
			}
			const finalSet = new Set(referenceArray);
			setSelectionPagesType([...finalSet]);
			let arrayOfClickedLinks = [1];
			if (operation.operation.locations.length > 0 || operation.operation.extensions.pagesTypes && operation.operation.extensions.pagesTypes.length > 0) {
				arrayOfClickedLinks.push(4);
			}
			if (operation.operation.libraryId && listBanners.length > 0) {
				arrayOfClickedLinks.push(2);
			}
			if (operation.operation.specifications && operation.operation.specifications.length > 0) {
				arrayOfClickedLinks.push(3);
			}
			setIsClickLink(arrayOfClickedLinks);
		}
	};
	useEffect(() => {
		selectSelectionPagesType();
	}, [arrayOfTypePage]);
	// Create new object for customize differents Pages Type
	const addZoningCustomizeListValues = () => {
		if (isexistant && isFirstRenderOfExistantOp) {
			const setZoningCustomize = [];
			arrayOfTypePage.forEach(item => setZoningCustomize.push({
				pageType: item.pageType,
				zones: [...operation.operation.locations.filter(x => x.pageType == item.pageType)]
			}));
			let finalArrayOfZoningCustomize = [...zoningCustomizeListValues, ...setZoningCustomize];
			setZoningCustomizeListValues([...finalArrayOfZoningCustomize]);
			setIsFirstRenderOfExistantOp(false);
		}
	};
	useEffect(() => {
		addZoningCustomizeListValues();
	}, [selectionPagesType]);
	useEffect(() => {
		if (!idOfExistantOp)
			setIsFirstRenderOfExistantOp(false);
	}, []);

	// for the navigation operation setup 
	//1=CreateBusinessOperationContainer 2=DesignBusinessOperationContainer
	//3=TargetBusinessOperationContainer 4=CustomizeContainer
	const returnBtnCreationBusinessOp = () => {
		switch (stepBusinessOperationCreate) {
			case 2:
				setStepBusinessOperationCreate(3);
				break;
			case 3:
				setStepBusinessOperationCreate(1);
				break;
			case 4:
				setStepBusinessOperationCreate(2);
				break;
		}
		// }
	};
	const forwardBtnCreationBusinessOp = () => {
		switch (stepBusinessOperationCreate) {
			case 1:
				setStepBusinessOperationCreate(3);
				break;
			case 2:
				setStepBusinessOperationCreate(4);
				break;
			case 3:
				setStepBusinessOperationCreate(2);
				break;
		}
		// }
	};

	useEffect(() => {
		const arrayOfLinkVisited = [...isClickLink];
		if (stepBusinessOperationCreate === 1 && operation.operation.name.length <= 0) {
			const newArray = isClickLink.filter(x => x !== stepBusinessOperationCreate);
			setIsClickLink(newArray);
		}
		if (stepBusinessOperationCreate === 2 && listBanners.length <= 0) {
			const newArray = arrayOfLinkVisited.filter(x => x !== stepBusinessOperationCreate);
			setIsClickLink(newArray);
		}
		if (stepBusinessOperationCreate === 4 && selectionPagesType.length <= 0) {
			const newArray = arrayOfLinkVisited.filter(x => x !== stepBusinessOperationCreate);
			setIsClickLink(newArray);
		}
		if (stepBusinessOperationCreate === 3 && operation.operation.specifications && operation.operation.specifications.length === 0) {
			const newArray = arrayOfLinkVisited.filter(x => x !== stepBusinessOperationCreate);
			setIsClickLink(newArray);
		}
		if (!arrayOfLinkVisited.includes(stepBusinessOperationCreate)) {
			if (stepBusinessOperationCreate === 1 && operation.operation.name.length > 0) {
				setIsClickLink([...arrayOfLinkVisited, stepBusinessOperationCreate]);
			}
			if (stepBusinessOperationCreate === 2 && listBanners.length > 0) {
				setIsClickLink([...arrayOfLinkVisited, stepBusinessOperationCreate]);
			}
			if (stepBusinessOperationCreate === 4 && selectionPagesType.length > 0) {
				setIsClickLink([...arrayOfLinkVisited, stepBusinessOperationCreate]);
			}
			if (stepBusinessOperationCreate === 3 && operation.operation.specifications && operation.operation.specifications.length > 0) {
				setIsClickLink([...arrayOfLinkVisited, stepBusinessOperationCreate]);
			}
		}
	}, [stepBusinessOperationCreate, pageNameSelected, operation, selectionPagesType, listBanners]);

	const changeStepCreation = (e) => {
		setStepBusinessOperationCreate(e);
	};

	const deleteABanner = (banner) => {
		let bannerToDelete = [banner];
		if (!isFakeUpload) {
			deleteLibrary(accountId, operation.operation.libraryId, bannerToDelete,
				e => {
					setValueOfStatus({ e, banner });
				},
				e => {
					//.log("fail: ", e)
				}
			);
		} else {
			setValueOfStatus({ banner });
		}
	};
	useEffect(() => {
		// if status exist delete banner from listBanner frontend
		if (valueOfStatus) {
			const banner = valueOfStatus.banner;
			let newBannerList = listBanners.filter(x => x.bannerId !== banner);
			setListBanners([...newBannerList]);
			setValueOfStatus();
		}
	}, [valueOfStatus]);

	useEffect(() => {
		history.pushState(null, null, location.href);
	}, []);

	useEffect(() => {
		window.onpopstate = (e) => {
			history.go(1);
			setTimeout(() => setPoppinIsClicked(true), 500);
		};
	});
	useEffect(() => {
		if (routeParams.prev === 'dashboard') {
			if (routeParams.ka) {
				setReturnLink(`BusinessOperations/Dashboard?ka=${routeParams.ka}&ku=${routeParams.ku}`);
			} else {
				setReturnLink('BusinessOperations/Dashboard');
			}
		}
		if (routeParams.prev === 'planning') {
			if (routeParams.ka) {
				setReturnLink(`BusinessOperations/Planning?ka=${routeParams.ka}&ku=${routeParams.ku}`);
			} else {
				setReturnLink('BusinessOperations/Planning');
			}
		}

	}, []);
	// useEffect(() => {
	//     if (permissions) {
	//         setUserSimplifyRole(permissions.roles)
	//         if (permissions.plan === "gold") {
	//             setPlanAccount(gold)
	//         }
	//         if (permissions.plan === "bronze") {
	//             setPlanAccount(bronze)
	//         }
	//         if (permissions.plan === "silver") {
	//             setPlanAccount(silver)
	//         }
	//     }
	// }, [permissions])
	useEffect(() => {
		setPlanAccount(gold);
	}, []);
	return (
		<div>
			<div className="simplify_creation_wrapper section_root">
				<NavBusinessOperationContainer
					idOfExistantOp={idOfExistantOp}
					getUserById={getUserById}
					accountId={accountId}
					routeParams={routeParams}
					isAdmin={isAdmin}
					changeStepCreation={changeStepCreation}
					forwardBtnCreationBusinessOp={forwardBtnCreationBusinessOp}
					returnBtnCreationBusinessOp={returnBtnCreationBusinessOp}
					isClickLink={isClickLink}
					postOperationById={postOperationById}
					putOperationById={putOperationById}
					recomputeOrchestration={recomputeOrchestration}
					deleteLibrary={deleteLibrary}
					setIsSavingOperation={(e) => setIsSavingOperation(e)}
				/>
				{poppinIsCliked &&
					<PopinConfirm
						cancel={() => setPoppinIsClicked(false)}
						title={'Are you sure you want to quit without saving?'}
						message={''}
					>
						<Btn
							onClickFunction={() => window.location.href = returnLink}
							message="Yes I'm sure"
						/>
					</PopinConfirm>
				}

				{stepBusinessOperationCreate === 1 &&
					<CreateBusinessOperationContainer
						accountId={accountId}
					/>}

				{stepBusinessOperationCreate === 2 &&
					<DesignBusinessOperationContainer
						getAccessToken={getAccessToken}
						accountId={accountId}
						businessOperationServices={businessOperationServices}
						systemServices={systemServices}
						deleteABanner={deleteABanner}
						getOperationById={getOperationById}
					/>}

				{stepBusinessOperationCreate === 3 &&
					<TargetBusinessOperationContainer />}

				{stepBusinessOperationCreate === 4 &&
					<CustomizeContainer
						isAdmin={isAdmin}
						accountId={accountId}
						isClickLink={isClickLink}
						getZones={getZones}
						getPagesTypes={getPagesTypes}
						businessOperationServices={businessOperationServices}
						systemServices={systemServices}
					/>}
				{isSavingOperation &&
					<div className="spinner_overlay">
						<div className="spinner">
							<div className="rect1"></div>
							<div className="rect2"></div>
							<div className="rect3"></div>
							<div className="rect4"></div>
							<div className="rect5"></div>
						</div>
					</div>}
			</div>
		</div>
	);
}
