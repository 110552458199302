import HttpServices from './HttpServices';

export default class IpAdressesServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}
	getAccountData(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.SERVER_URL}/users/${accountId}/accounts/${accountId}`, callbackSuccess, callbackError);
	}
	putIpAdresses(accountId, arrayOfIPAdresses, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}/users/${accountId}/accounts/${accountId}`, arrayOfIPAdresses, callbackSuccess, callbackError);
	}
	putTestModeofCampaignAndSaveIpAdress(accountId, campaignId, executionModeAndIpAdress, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}/users/${accountId}/campaigns/${campaignId}/testmode`, executionModeAndIpAdress, callbackSuccess, callbackError);
	}
	putTestModeofCampaign(accountId, campaignId, executionMode, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}/users/${accountId}/campaigns/${campaignId}/testmode`, executionMode, callbackSuccess, callbackError);
	}
	putIpAdressesAccount(accountId, ips, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}users/8504aaaa-aaaa-9999-8888-8885d94b896e/accounts/${accountId}/ipaddress`, ips, callbackSuccess, callbackError);
	}

}