import React from 'react';
import styles from './translation.module.css';
export function mergeJson (){

	let final = Object.assign(...arguments); 

	return final;
}
const listOfReturnTags = [
	{
		key : '',
		jsx : null
	},
	{
		key : 'br/',
		jsx : 'br'
	},
	{
		key : 'br /',
		jsx : 'br'
	},
	{
		key : 'strong',
		jsx : 'strong'
	},
	{
		key : '/strong',
		jsx : 'strong'
	},
	{
		key : 'a',
		jsx :null
	},
	{
		key : '/a',
		jsx :'a'
	}
];
export default function renderJsxTag(keys, onClickEvent) {

	const items = keys.split('>');
	return items.map((element,i) =>{
		const returnElement =  element.split('<');
		let elementNewReturn = '';

		function handleClick(event) {
			event.preventDefault(); // Empêche le navigateur de suivre le lien
			onClickEvent(); // Appelle la fonction onClick passée en tant que propriété
		}
		if(returnElement.length > 1){
			const element = listOfReturnTags.find((x) => {
				return x.key === returnElement[1];
			});
			if(!element || !element.hasOwnProperty('key')){
				elementNewReturn =  <React.Fragment key={i}>{returnElement[0]}</React.Fragment>;
			}
			if(element?.key === 'a'){ 
				elementNewReturn = <React.Fragment key={i}>{returnElement[0]}</React.Fragment >;}
			if(element?.key === '/a'){
				elementNewReturn = React.createElement(`${listOfReturnTags.find(x => x.key === returnElement[1].trim()).jsx}`,{onClick : handleClick, className : styles.link , key: i} , returnElement[0]);
			}
			else if(element?.key === '/strong'){
				elementNewReturn = React.createElement(`${listOfReturnTags.find(x => x.key === returnElement[1].trim()).jsx}`,{key : i} , returnElement[0]);
			}
			else{
				elementNewReturn = <React.Fragment key={i}>{returnElement[0]} {React.createElement(`${listOfReturnTags.find(x => x.key === returnElement[1].trim()).jsx}`)}</React.Fragment>;
			}
		}else{
			elementNewReturn =  <React.Fragment key={i}>{returnElement[0]}</React.Fragment>;
		}
		return elementNewReturn;
	});
}
// HOW TO USE ? 
// "formatPopin" : "popine",
// "formatHeaderBar" : "En-tête",
// "keyRedirect" : "lien superbe",
// "message": "Bla <strong>coucou </strong> <br/> <strong>coucou</strong> bla bal bal <br/> choose between format : <strong>{{selected_format1}}</strong> or {{selected_format2}}  <br/> voici un <a>{{keyRedirect}}</a> "
// {renderJsxTag(
// 	t('campaignsList.message',{
// 		selected_format1: t('campaignsList.formatPopin'),
// 		selected_format2:t('campaignsList.formatHeaderBar'),
// 		keyRedirect:t('campaignsList.keyRedirect'),
// 	}), 
// 	function onClickEven(){
// 		console.log('element call');
// 	})}