import React, { Component } from 'react';
import styles from './FormCustomReportTableComponent.module.css';
import ReactTable from 'react-table';
import { CSVLink } from 'react-csv';
import matchSorter from 'match-sorter';
const i18nL= localStorage.getItem('i18nL');

const newRow = (fields, date) => {
    const row = {};
    for (const f in fields) {
        if (fields.hasOwnProperty(f)) {
            const element = fields[f];
            row[element.fieldName] = element.answer;
        }
    }
    row["date"] = date;
    return row;
};


export default class FormCustomReportTable extends Component {

    constructor(props) {
        super(props);

        this.loading = this.props.loading;
        this.slideId = this.props.slideId
        this.state = {
            filtered: [],
            filterAll: '',
        };
    }


    makeData(answers) {
        const tableColumns = [];
        const cols = {};
        const allFields = answers.flatMap(x => x.fields);
        for (const field in allFields) {
            if (allFields.hasOwnProperty(field)) {
                const element = allFields[field];
                cols[element.fieldName] = {
                    id: element.fieldName,
                    Header: element.fieldName,
                    accessor: element.fieldName
                };
            }
        }

        const keys = [];
        for (const f in cols) {
            if (cols.hasOwnProperty(f)) {
                tableColumns.push(cols[f]);
                keys.push(cols[f].id);
            }
        }

        const dateCol = {
            id: "date",
            Header: i18nL.includes('fr') ? "Date de réponse" :"Answer Date",
            accessor: "date"
        };
        const filterCol = {
            Header: 'All',
            id: 'all',
            width: 0,
            className: 'all-cell',
            style: {
                backgroundColor: 'red',
                padding: 0
            },
            resizable: false,
            sortable: false,
            filterMethod: (filter, rows) => {
                const result = matchSorter(rows, filter.value, {
                    keys: keys,
                    threshold: matchSorter.rankings.WORD_STARTS_WITH
                });
                return result;
            },
            filterAll: true
        }

        tableColumns.push(dateCol);
        tableColumns.push(filterCol);

        var rows = answers.map(answer => {
            return {
                ...newRow(answer.fields, moment(answer.answerAtUtc, 'YYYY/MM/DD HH:mm').local().format('DD-MM-YYYY HH:mm'))
            };
        });

        return { rows, tableColumns };
    }

    handleChange(e) {
        const { value } = e.target;
        const filterAll = value;
        const filtered = [{ id: 'all', value: filterAll }];

        this.setState(state => {
            return { filterAll, filtered }
        });
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    render() {
        const data = this.makeData(this.props.answers)
        return (
            <article className={styles.tableContainer}>
                <div className={styles.header}>
                    <h4> </h4>
                    <header className={styles.tableContainerHeader}>
                        {/* <div className={styles.title}>Slide {this.slide.SlideId}</div> */}
                        <div className={styles.actions}>
                            <input type="text" placeholder="Rechercher" onChange={(e) => this.handleChange(e)} />
                            <CSVLink
                                data={data.rows}
                                filename={`${this.slideId}.csv`}
                                className={styles.export_btn}
                                target="_blank">
                                <i className={`fas fa-file-download ${styles.icons}`}></i>
                            </CSVLink>
                        </div>
                    </header>
                </div>

                <ReactTable
                    columns={data.tableColumns}
                    loading={this.loading}
                    data={data.rows}
                    filtered={this.state.filtered}
                    showPageSizeOptions={false}
                    defaultPageSize={30} />
            </article>)
    }
}