import React, { useState } from 'react';
import ProductItemDropDown from './ProductItemDropDown';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { useSortable } from "@dnd-kit/sortable";

const ProductCard = (props) => {
	const idTooltip = uuidv4();
	const idTooltipDrag = uuidv4();
	const idTooltipReplace = uuidv4();
	const {
		product,
		isDraggableItem = false,
		deleteItemFunction = () => { },
		idInClipBoard,
		copyToClipBoard,
		isReplace = {
			isATransformationReplace: false,
			oldProductId: '',
			replaceProductId: ''
		},
		dimension,
		isRanking = true,
	} = props;

	const { id, name, url, urlThumbnail, price, unitPriceWithRebate } = product;

	const [copySuccess, setCopySuccess] = useState(false);

	const {
		isDragging,
		listeners,
	} = useSortable({ id: product.id });

	function handleCopy(id) {
		copyToClipBoard(id);
		setCopySuccess(true);
		setTimeout(() => {
			setCopySuccess(false);
		}, 3000);
	}

	function get_ordered_prices(price1, price2){
		if (price1 == 0) {
			return [price2];
		}
		if (price2 == 0) {
			return [price1];
		}
		if( price1 == price2 ){
			return [price1];
		}
		if( price2 > price1 ){
			return [price1, price2];
		}
		return [price2, price1];
	}

	function format_price(p) {
		const currency = '€';
		return p.toFixed(2) + '\u00A0' + currency;
	}

	const prices = get_ordered_prices(price, unitPriceWithRebate);
	const newPrice = format_price(prices[0]);
	const oldPrice = prices[1] ? format_price(prices[1]) : null;
	const stockQte = product.globalStockQuantity !== null ? product.globalStockQuantity : undefined;

	function getProduct() {
		if (isDraggableItem) {
			return (
				<div className="collection_card">
					<ReactTooltip id={idTooltip} backgroundColor='black' effect='solid' />
					<ReactTooltip
						id={idTooltipDrag}
						backgroundColor='black'
						effect='solid' place="left" globalEventOff="click"
					/>
					<div
						{...listeners}
						style={
							{ cursor: 'move', cursor: 'grab' }
						}
					>
						<div className="collection_card_image_wrapper">
							<div
								style={{ backgroundImage: `url('${urlThumbnail}')` }}
								className="collection_card_image"
							/>
						</div>
					</div>
					<div className='collection_card_info'>
						<div className="collection_card_name ellips" data-for={idTooltip} data-tip={name}>{name}</div>
						<div className="collection_card_price">
							{newPrice}
							{oldPrice && 
								<span className='collection_card_old_price'><s>{oldPrice}</s></span>
							}
						</div>
						<div className="collection_card_id">
							<a data-for={idTooltip}
								data-tip={`Copy id to clipboard`}
								onClick={() => handleCopy(id)}>
								{id}
								<span className='collection_card_hover'>
									{copySuccess ?
										<i className='fas fa-check'></i>
										:
										<i className='fas fa-copy'></i>
									}
								</span>
							</a>
						</div>
					</div>
					<div className='collection_card_overlay flex'>
						<div className='flex_item_full'>
							{dimension == 'promoted' ?
								<div className='collection_card_flag' data-for={idTooltip} data-tip={'Promoted'}>
									<i className='fas fa-arrow-circle-up' />
								</div>
								:
								<div className='collection_card_flag' data-for={idTooltip} data-tip={'Demoted'}>
									<i className='fas fa-arrow-circle-down' />
								</div>
							}
						</div>
						<div className='flex_item_fix'>
							<a className='collection_card_action'
								data-for={idTooltip}
								data-tip={`Drag to sort`}
								{...listeners}>
								<i className="fas fa-arrows-alt" />
							</a>
							<a className='collection_card_action'
								data-for={idTooltip}
								data-tip={dimension == 'promoted' ? `Remove from promoted products` : `Remove from demoted products`}
								onClick={deleteItemFunction}>
								<i className="fas fa-times" />
							</a>
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div className="collection_card">
					<ReactTooltip id={idTooltip} backgroundColor='black' effect='solid' />
					<div className="collection_card_image_wrapper">
						<div
							style={{ backgroundImage: `url('${urlThumbnail}')` }}
							className="collection_card_image"
						/>
					</div>

					<div className='collection_card_info'>
						<div className="collection_card_name ellips" data-for={idTooltip} data-tip={name}>{name}</div>
						<div className="collection_card_price flex flex_wrap">
							<div className='flex_item_full'>
								{newPrice}
								{oldPrice && 
									<span className='collection_card_old_price'><s>{oldPrice}</s></span>
								}
							</div>
							{stockQte !== undefined &&
								<span className='flex_item_fix ml_10'>Stock : {stockQte}</span>
							}
						</div>
						<div className="collection_card_id">
							<a data-for={idTooltip}
								data-tip={'Copy id to clipboard'}
								onClick={() => handleCopy(id)}>
								{id}
								<span className='collection_card_hover'>
									{copySuccess ?
										<i className='fas fa-check'></i>
										:
										<i className='fas fa-copy'></i>
									}
								</span>
							</a>
						</div>
					</div>
					<div className='collection_card_overlay flex'>
						<div className='flex_item_full'>
							{isReplace.isATransformationReplace &&
								<>
									<ReactTooltip id={idTooltipReplace} backgroundColor='black' effect='solid' />
									<div className='collection_card_flag' data-for={idTooltipReplace} data-tip={`Swiped with product: ${isReplace.replaceProductId}`}>
										<i className='fas fa-retweet' />
									</div>
								</>
							}
						</div>
						{isRanking && <div className='flex_item_fix'>
							<ProductItemDropDown
								isATransformationReplace={isReplace.isATransformationReplace}
								idProduct={id}
								idInClipBoard={idInClipBoard}
							/>
						</div>}
					</div>
				</div>
			)
		}
	}
	return (<>
		{getProduct()}
	</>)
}

export default ProductCard;