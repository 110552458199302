import React from 'react';
import i18next from 'i18next';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { react2angular } from 'react2angular';
import { ContextProvider, serverSideCampaignsDashboardContext } from './context';
import RouterWrapper from '../Dashboard/OnSite/RouterWrapper';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import SystemServices from '../../Services/SystemServices';
import CampaignCreatingServices from '../../Services/CampaignCreatingServices';
import ReportingCampaignServices from '../../Services/ReportingCampaignServices';
import ServerSideCampaignsList from './ServerSideCampaignList';
import ServerSideCampaignServices from '../../Services/ServerSideCampaignServices';
import ServerSideCampaignEditionModal from './ServerSideCampaignEditionModal';
import ServerSideCampaignIntegrationPanel from './ServerSideCampaignIntegrationPanel';
import Btn from '../../Components/Btn';
import campaign_serverside_react_en from '../../Translation/jsonfiles/en/campaign_serverside_react_en.json';
import campaign_serverside_react_fr from '../../Translation/jsonfiles/fr/campaign_serverside_react_fr.json';
import utils_en from '../../Translation/jsonfiles/en/utils_en.json';
import utils_fr from '../../Translation/jsonfiles/fr/utils_fr.json';
import { mergeJson } from '../../Translation/translation-utils';


export default function ServerSideDashboardContainer(props) {
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $http = props.$http;
	const $timeout = props.$timeout;
	const authServices = props.AuthServices;

	const campaignServices = new CampaignCreatingServices($http, $routeParams, authServices);
	const systemServices = new SystemServices($rootScope, $timeout);
	const reportingCampaignService = new ReportingCampaignServices($http, $routeParams);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope.User.Account.Key);
	const serverSideCampaignServices = new ServerSideCampaignServices(accountId, $http);

	const isAdmin = impersonatingServices.isAnAdmin();

	const linkToReporting = (campaignId) => {
		if (isAdmin && $routeParams.ka) {
			return `Performance/Reporting?id=${campaignId}&dimension=onSite&ka=${accountId}&ku=${$routeParams.ku}`;
		} else {
			return `Performance/Reporting?id=${campaignId}&dimension=onSite`;
		}
	};

	const i18nL = localStorage.getItem('i18nL');
	const newInstanceI18nextOnsite = i18next.createInstance();
	newInstanceI18nextOnsite.init({
		lng: 'en-EN',
		resources: {
			'en-US': {
				common: mergeJson(campaign_serverside_react_en, utils_en)
			},
			'en-EN': {
				common: mergeJson(campaign_serverside_react_en, utils_en)
			},
			'fr-FR': {
				common: mergeJson(campaign_serverside_react_fr, utils_fr)
			},
		}
	},
		(err, t) => {
			if (err) return console.log('something went wrong loading', err);
			newInstanceI18nextOnsite.changeLanguage(JSON.parse(i18nL));
		});

	return (
		<I18nextProvider i18n={newInstanceI18nextOnsite}>
			<ContextProvider
				getCampaignList={(callbackSuccess, callbackError) => serverSideCampaignServices.getCampaignList(callbackSuccess, callbackError)}
				getCampaignInfos={(campaignId, callbackSuccess, callbackError) => serverSideCampaignServices.getCampaignInfos(campaignId, callbackSuccess, callbackError)}
				createNewCampaign={(campaign, callbackSuccess, callbackError) => campaignServices.createNewCampaign(accountId, campaign, callbackSuccess, callbackError)}
				deleteCampaign={(campaignId, callbackSuccess, callbackError) => reportingCampaignService.deleteACampaign(accountId, campaignId, callbackSuccess, callbackError)}
				updateCampaign={(campaignId, { name, description }, callbackSuccess, callbackError) => serverSideCampaignServices.updateCampaign(campaignId, { name, description }, callbackSuccess, callbackError)}
				systemServices={systemServices}
				linkToReporting={linkToReporting}
				$rootScope={$rootScope}
			>
				<RouterWrapper props={props}>
					<ServerSideDashboardContainerInner props={props} />
				</RouterWrapper>
			</ContextProvider>
		</I18nextProvider>
	);
}

function ServerSideDashboardContainerInner(props) {

	const {
		toggleAddItem,
		totalCount,
		permissionsAccount
	} = serverSideCampaignsDashboardContext();

	const permCreateCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'PERFCAMPAIGN_CAN_CREATE');
	const canCreateCampaign = permCreateCampaign && permCreateCampaign.Value === true;

	const [t] = useTranslation('common');

	return (
		<div className='page_full_inner'>
			<section className='section no_bottom_pad section_primary'>
				<div className='flex'>
					<div className='flex_item_full'>
						<div className='h1'>{t('list.title')}</div>
					</div>
					{totalCount > 0 &&
						<div className='flex_item_fix ml_30'>
							<Btn
								message={t('action.documentation')}
								icon="far fa-sm fa-question-circle"
								href="https://docs.beyable.com/web-app-sdk/performance-tracking"
								target="_blank"
								rel="noreferrer"
								style="ghost_outline"
								color="secondary"
							/>
							{canCreateCampaign &&
								<Btn
									message={t('action.create')}
									icon="fas fa-sm fa-plus"
									onClick={() => toggleAddItem()}
								/>
							}
						</div>
					}
				</div>
			</section>
			<section className='section'>
				<ServerSideCampaignsList />
				<ServerSideCampaignEditionModal />
				<ServerSideCampaignIntegrationPanel />
			</section>
		</div>
	);
}


angular
	.module('beyableSaasApp.ServerSideDashboard', ['beyableSaasApp.Services'])
	.component('serversidedashboard', react2angular(ServerSideDashboardContainer, [],
		['$rootScope', '$http', '$routeParams', '$timeout', '$location', 'AuthServices']));