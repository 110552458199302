import HttpServices from './HttpServices';
import MomentConstants from '../Constants/MomentConstants';

export default class VisitorEngagementServices {
	constructor($http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_ENGAGEMENT_URL = `${window.BEYABLE_env.BO_API_URL}visitorEngagement`;
			this.BO_API_REFERENCEDATA_URL = `${window.BEYABLE_env.BO_API_URL}referencedata`;
		}
	}

	//
	// Signals Management
	//

	getSignals(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_ENGAGEMENT_URL}/${accountId}/signal`, callbackSuccess, callbackError);
	}

	createOrUpdateSignal(accountId, signal, callbackSuccess, callbackError) {
		if (signal.id) {
			this.HttpServices.put(`${this.BO_API_ENGAGEMENT_URL}/${accountId}/signal/${signal.id}`, signal, callbackSuccess, callbackError);
		} else {
			this.HttpServices.post(`${this.BO_API_ENGAGEMENT_URL}/${accountId}/signal`, signal, callbackSuccess, callbackError);
		}
	}

	deleteSignal(accountId, signalId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_ENGAGEMENT_URL}/${accountId}/signal/${signalId}`, callbackSuccess, callbackError);
	}


	//
	// Calculator Management
	//


	getDefaultCalculator(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_ENGAGEMENT_URL}/${accountId}/calculator/`, callbackSuccess, callbackError);
	}

	updateCalculatorProfiles(accountId, calculatorId, newProfiles, modifiedProfiles, deletedProfileIds, callbackSuccess, callbackError) {
		let data = {
			create: newProfiles,
			update: modifiedProfiles.reduce((acc, p) => { acc[p.id] = p; return acc; }, {}),
			delete: deletedProfileIds,
		};

		this.HttpServices.post(`${this.BO_API_ENGAGEMENT_URL}/${accountId}/calculator/${calculatorId}/profile`, data, callbackSuccess, callbackError);
	}


	//
	// Event Marker Management
	//

	createOrUpdateEvent(accountId, event, callbackSuccess, callbackError) {
		if (event.id) {
			this.HttpServices.put(`${this.BO_API_ENGAGEMENT_URL}/${accountId}/eventMarker/${event.id}`, event, callbackSuccess, callbackError);
		} else {
			this.HttpServices.post(`${this.BO_API_ENGAGEMENT_URL}/${accountId}/eventMarker`, event, callbackSuccess, callbackError);
		}
	}

	deleteEvent(accountId, eventId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_ENGAGEMENT_URL}/${accountId}/eventMarker/${eventId}`, callbackSuccess, callbackError);
	}

	getEventMarks(accountId, momentStartDate, momentEndDate, callbackSuccess, callbackError) {
		let url = `${this.BO_API_ENGAGEMENT_URL}/${accountId}/eventMarker?startDate=${momentStartDate.format(MomentConstants.MOMENT_API_FORMAT)}`;
		if (momentEndDate) {
			url += `&endDate=${momentEndDate.format(MomentConstants.MOMENT_API_FORMAT)}`;
		}

		this.HttpServices.get(url, callbackSuccess, callbackError);
	}

	//
	// Statistics
	//

	getCustomerJourney(accountId, momentStartDate, momentEndDate, tenant, callbackSuccess, callbackError) {
		let url = `${this.BO_API_ENGAGEMENT_URL}/${accountId}/statistics/customerJourney?startDate=${momentStartDate.format(MomentConstants.MOMENT_API_FORMAT)}`;
		if (momentEndDate) {
			url += `&endDate=${momentEndDate.format(MomentConstants.MOMENT_API_FORMAT)}`;
		}
		url += `&tenant=${tenant}`;
		this.HttpServices.get(url, callbackSuccess, callbackError);
	}

	getUserEngagement(accountId, momentStartDate, momentEndDate, filter, display, tenant, callbackSuccess, callbackError) {
		let url = `${this.BO_API_ENGAGEMENT_URL}/${accountId}/statistics/userEngagement?startDate=${momentStartDate.format(MomentConstants.MOMENT_API_FORMAT)}`;
		if (momentEndDate) {
			url += `&endDate=${momentEndDate.format(MomentConstants.MOMENT_API_FORMAT)}`;
		}
		if (filter) {
			url += `&filter=${filter}`;
		}
		if (display) {
			url += `&display=${display}`;
		}
		url += `&tenant=${tenant}`;
		this.HttpServices.get(url, callbackSuccess, callbackError);
	}

	getKPIs(accountId, momentStartDate, momentEndDate, tenant, callbackSuccess, callbackError) {
		let url = `${this.BO_API_ENGAGEMENT_URL}/${accountId}/statistics/kpis?startDate=${momentStartDate.format(MomentConstants.MOMENT_API_FORMAT)}`;
		if (momentEndDate) {
			url += `&endDate=${momentEndDate.format(MomentConstants.MOMENT_API_FORMAT)}`;
		}
		url += `&tenant=${tenant}`;
		this.HttpServices.get(url, callbackSuccess, callbackError);
	}

	getProfilesStatistics(accountId, callbackSuccess, callbackError) {
		let url = `${this.BO_API_ENGAGEMENT_URL}/${accountId}/statistics/profiles`;
		this.HttpServices.get(url, callbackSuccess, callbackError);
	}

	getTenants(accountId, callbackSuccess, callbackError) {
		let url = `${this.BO_API_REFERENCEDATA_URL}/${accountId}/contextualdata?type=tenant`;
		this.HttpServices.get(url, callbackSuccess, callbackError);
	}
}