import React from 'react';

export default function TableRowItem({
	styleObj,
	specialClasseName,
	children,
	provided,
	isDraggableItem = false,
	refs = {
		setNodeRef: React.createRef(),
		props: {

		},
		attributes: {

		}
	}
}) {

	return (<> {isDraggableItem ?
		<div className={'table_row' + ' ' + specialClasseName} style={styleObj}
			ref={refs.setNodeRef}
			// {...refs.props}
			{...refs.attributes}
		>
			{children}
		</div> :
		<div className={'table_row' + ' ' + specialClasseName} style={styleObj}
		>
			{children}
		</div>
	}
	</>
	);
}
