import React, { useContext } from 'react';
import { BusinessOperationOrchestrationContext } from '../Context';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import '../../../Dashboard/Orchestration/orchestration.css';
import BusinessOperationItem from './BusinessOperationItem';

const SetBusinessOperationPrio = () => {
	const {
		operationsInOrchestration,
		handlePrioChanged,
		handleRemoveOperation
	} = useContext(BusinessOperationOrchestrationContext);


	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const items = Array.from(operationsInOrchestration);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		handlePrioChanged(items);
	};

	if ((operationsInOrchestration || []).length === 0) {
		return null;
	}

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId="operations" direction="vertical">
				{(provided) => (
					<ul
						{...provided.droppableProps}
						ref={provided.innerRef}
						className="simplify_orch_list"
					>
						{operationsInOrchestration.map((ope, index) =>
							<Draggable key={ope.operationId} draggableId={ope.operationId} index={index}>
								{(provided, snapshot) => (
									<li
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										key={ope.operationId}
										className="simplify_orch_list_item s flex"
										isdragging={snapshot.isDragging ? 'y' : 'n'}
									>
										<BusinessOperationItem
											isDragging={snapshot.isDragging}
											handleRemoveOperation={handleRemoveOperation}
											operation={ope}
											displayMode='drag'
										/>
									</li>)}
							</Draggable>
						)}
						{provided.placeholder}
					</ul>)}
			</Droppable>
		</DragDropContext>
	);
};


export default SetBusinessOperationPrio;