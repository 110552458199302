import React, { useState, useEffect } from 'react';
import Btn from '../../../Components/Btn';
import Panel from '../../../Components/Panel';
import ModalHeader from '../../../Components/ModalHeader';
import ModalBody from '../../../Components/ModalBody';
import Switch from '../../../Components/Switch';
import PanelArticle from '../../../Components/PanelArticle';
import InputCustom from '../../../Components/InputCustom';
import NumericUpDown from '../../../Components/NumericUpDown';
import Fieldset from '../../../Components/Fieldset';

function getDefaultUniqueCodeGenerationOptions() {
	return {
		CodeSize: 8,
		Prefix: 'BY',
		ExpirationDelayInDays: 2
	};
}

function getDefaultPromoCode(code) {
	const defaultPromoCode = {
		ActualPromoCode: code,
		Text: '',
		GenerateUniqueCode: false,
		UniqueCodeGenerationOptions: getDefaultUniqueCodeGenerationOptions()
	};
	return defaultPromoCode;
}

export function InitializePromoCodeEditor({
	data,
	getCurrentVariation,
	handleSetPromoCode
}) {


	const currentVariation = getCurrentVariation() || {};

	useEffect(() => {
		handleSetPromoCode(currentVariation.Answer?.PromoCode ?? getDefaultPromoCode(data));
	}, []);

	return (
		<></>
	);
}


export default function PromoCodeEditor({
	data,
	onChange,
	getCurrentVariation,
	handleSetPromoCode
}) {

	const currentVariation = getCurrentVariation() || {};

	const [panelIsOpen, setPanelIsOpen] = useState(false);
	const [promoCode, setPromoCode] = useState(currentVariation.Answer?.PromoCode ?? getDefaultPromoCode(data));

	const handleConfirm = () => {
		const value = promoCode.ActualPromoCode;
		setPanelIsOpen(false);
		onChange(value);
	};


	const initializePromoCode = () => {
		const answerPromoCode = currentVariation.Answer?.PromoCode ?? getDefaultPromoCode(data);
		setPromoCode(answerPromoCode);
	};


	useEffect(() => {
		initializePromoCode();
	}, []);

	useEffect(() => {
		handleSetPromoCode(promoCode);
	}, [promoCode]);

	const setUniqueCodeGenerationOption = (propertyName, value) => {
		setPromoCode(p => ({ ...p, UniqueCodeGenerationOptions: { ...p.UniqueCodeGenerationOptions, [propertyName]: value } }));
	};

	const switchEnableUniqueCodeGeneration = (isActive) => {
		setPromoCode(p => ({
			...p,
			GenerateUniqueCode: isActive,
			UniqueCodeGenerationOptions: p.UniqueCodeGenerationOptions ?? getDefaultUniqueCodeGenerationOptions()
		}));
	};

	const setActualPromoCode = (propertyName, value) => {
		setPromoCode(p => ({ ...p, [propertyName]: value }));
	};

	return (
		<>
			<Fieldset label='Promo code'>
				<div className='flex' onClick={() => setPanelIsOpen(true)}>
					<div className='flex_item_full'>
						<InputCustom
							readonly={true}
							value={data}
							fullWidth={true}
							doBtnHug={true}
						/>
					</div>
					<div className='flex_item_fix'>
						<Btn
							icon="fas fa-pen"
							horizontalSize="xxs"
							doInputHug={true}
						/>
					</div>
				</div>
			</Fieldset>
			<Panel
				isOpen={panelIsOpen}
				onClose={() => setPanelIsOpen(false)}
				noClose={true}
			>
				<ModalHeader>
					<div className='flex'>
						<div className="flex_item_fix">
							<a className="panel_close panel_close_left" onClick={() => setPanelIsOpen(false)}></a>
						</div>
						<div className='flex_item_full'>
							Promo code
						</div>
						<div className='flex_item_fix ml_30'>
							<Btn
								message="Confirm"
								color="primary"
								onClick={handleConfirm}
							/>
						</div>
					</div>
				</ModalHeader>
				<ModalBody color='grey'>
					<PanelArticle size='xl'>

						<Switch
							forcedChecked={promoCode.GenerateUniqueCode}
							onChange={(ev) => switchEnableUniqueCodeGeneration(ev.target.checked)}
							label="Enable unique promo codes"
						/>

						<hr />

						<div>
							<InputCustom
								value={promoCode.ActualPromoCode}
								label={promoCode.GenerateUniqueCode ? 'Matching code' : 'Fixed code'}
								description={promoCode.GenerateUniqueCode
									? 'This code will not be visible to visitors, it will be returned by the BEYABLE API'
									: 'This code will be displayed to all targeted visitors'}
								placeholder='Ex: OFFER2023'
								onChange={(ev) => setActualPromoCode('ActualPromoCode', ev.target.value)}
							/>
							{promoCode.GenerateUniqueCode &&
								<>
									<InputCustom
										value={promoCode.UniqueCodeGenerationOptions.Prefix}
										label='Generated code prefix'
										placeholder='Optional'
										onChange={(ev) => setUniqueCodeGenerationOption('Prefix', ev.target.value)}
									/>
									<NumericUpDown
										value={promoCode.UniqueCodeGenerationOptions.CodeSize}
										min={5}
										max={16}
										label='Generated code size'
										description='Size of the code excluding prefix'
										placeholder='8'
										canEdit={true}
										onChange={(ev) => setUniqueCodeGenerationOption('CodeSize', ev.target.value)}
									/>
									<NumericUpDown
										value={promoCode.UniqueCodeGenerationOptions.ExpirationDelayInDays}
										min={1}
										max={365}
										label='How many day(s) the code should be available?'
										placeholder='7'
										canEdit={true}
										onChange={(ev) => setUniqueCodeGenerationOption('ExpirationDelayInDays', ev.target.value)}
									/>
								</>
							}
						</div>
					</PanelArticle>


				</ModalBody>
			</Panel>
		</>
	);
}
