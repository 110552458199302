import React, { useState, useEffect } from 'react';
import './PlanningContainer.css';
import DatesHeader from './DatesHeader';
import Grid from './Grid';
import OperationsTimeline from './OperationsTimeline';
import formatMoment from '../../../Constants/FormatMoment';
import useSort from '../../../Hooks/useSort';

export default function CalendarContainer({ dateRange, getOperationByRangeDate, accountId, routeParams, dateToCalculate, loadOperationStatistics, putOperationExecutionStatusModeWithTest, deleteOperationById, permissions, userSimplifyRole }) {
	const { formatDateToLocal } = formatMoment;
	const [datesBeginFetch, setDateBeginFetch] = useState();
	const [datesEndFetch, setDateEndFetch] = useState();
	const [datesArray, setDateArray] = useState();
	const [operations, setOperations] = useState();
	const [reload, setReload] = useState(true);
	const [today, setToday] = useState(moment().startOf('day'));
	const { sortOpeByDate } = useSort();
	const [reloadTheOperationItem, setReloadTheOperationItem] = useState(false);
	function getDates() {
		if (!dateRange) return;
		let dateArray = [];
		const tmp_dateRange = { ...dateRange };
		let currentDate = new Date(moment(tmp_dateRange.startDate));
		let endDate = moment(currentDate).add(dateToCalculate - 1, 'days');
		while (currentDate <= endDate) {
			dateArray.push(moment(currentDate));
			currentDate.setDate(currentDate.getDate() + 1);
		}
		setDateArray(dateArray);
	}

	useEffect(() => {
		getDates();
	}, [dateRange, dateToCalculate]);

	useEffect(() => {
		const tmpStartdate = moment(dateRange.startDate).subtract(28, 'days');
		if (datesArray) {
			if (!operations) {
				setReload(true);
				getOperationByRangeDate(accountId, tmpStartdate.format('YYYY-MM-DD'), moment(tmpStartdate).add(84, 'days').format('YYYY-MM-DD'),
					(data) => {
						let dataWithStyle = data.map(op => ({
							...op,
							operation: ({
								...op.operation,
								startDateUtc: moment(op.operation.startDate),
								endDateUtc: moment(op.operation.endDate),
								startDate: formatDateToLocal(op.operation.startDate),
								endDate: formatDateToLocal(op.operation.endDate),
								lazyLoadStatistics: true
							})
						}));
						setOperations(dataWithStyle.sort(sortOpeByDate));
						setReload(false);
						setDateBeginFetch(tmpStartdate);
						setDateEndFetch((moment(tmpStartdate).startOf('day').add(84, 'days')));
					},
					error => {
						console.log('error is here', error);
						setReload(false);
					}
				);
			}
			else {
				const dateSelectedInPicker = moment(dateRange.startDate);
				const endOfTimeline = moment(datesArray.length - 1);
				if (dateSelectedInPicker <= datesBeginFetch || dateSelectedInPicker >= datesEndFetch || datesEndFetch <= endOfTimeline) {
					setReload(true);
					getOperationByRangeDate(accountId, tmpStartdate.format('YYYY-MM-DD'), moment(tmpStartdate).add(84, 'days').format('YYYY-MM-DD'),
						(data) => {
							let dataWithStyle = data.map(op => ({
								...op,
								operation: ({
									...op.operation,
									startDateUtc: moment(op.operation.startDate),
									endDateUtc: moment(op.operation.endDate),
									startDate: formatDateToLocal(op.operation.startDate),
									endDate: formatDateToLocal(op.operation.endDate),
									lazyLoadStatistics: true
								})
							}));
							setOperations(dataWithStyle.sort(sortOpeByDate));
							setReload(false);
							setDateBeginFetch(moment(tmpStartdate).startOf('day'));
							setDateEndFetch((moment(tmpStartdate).startOf('day').add(84, 'days')));
						},
						error => {
							console.log('error is here', error);
							setReload(false);
						}
					);
				}
			}
		}
	}, [datesArray]);

	const reloadOperationsForStatusChange = () => {
		const tmpStartdate = moment(dateRange.startDate).subtract(28, 'days');
		// setReload(true);
		getOperationByRangeDate(accountId, tmpStartdate.format('YYYY-MM-DD'), moment(tmpStartdate).add(84, 'days').format('YYYY-MM-DD'),
			(data) => {
				let dataWithStyle = data.map(op => ({
					...op,
					operation: ({
						...op.operation,
						startDateUtc: moment(op.operation.startDate),
						endDateUtc: moment(op.operation.endDate),
						startDate: formatDateToLocal(op.operation.startDate),
						endDate: formatDateToLocal(op.operation.endDate),
						lazyLoadStatistics: true
					})
				}));
				setOperations(dataWithStyle.sort(sortOpeByDate));
				setReloadTheOperationItem(false);
				setDateBeginFetch(tmpStartdate);
				setDateEndFetch((moment(tmpStartdate).startOf('day').add(84, 'days')));
			},
			error => {
				console.log('error is here', error);
				setReloadTheOperationItem(false);
			}
		);
	};
	return (
		<div className="splanning flex flex_v">
			{datesArray &&
				<>
					<div className="splanning_header flex_item_fix">
						<DatesHeader
							dates={datesArray}
							today={today}
						/>
					</div>
					<div className="splanning_body flex_item_full flex flex_v">
						<Grid
							dates={datesArray}
							today={today}
						/>
						<div className="splanning_body_scroll vscroll">
							<OperationsTimeline
								accountId={accountId}
								putOperationExecutionStatusModeWithTest={putOperationExecutionStatusModeWithTest}
								dates={datesArray}
								operations={operations}
								loadOperationStatistics={loadOperationStatistics}
								routeParams={routeParams}
								today={today}
								reloadOperationsForStatusChange={() => reloadOperationsForStatusChange()}
								setReloadTheOperationItem={(e) => setReloadTheOperationItem(e)}
								reloadTheOperationItem={reloadTheOperationItem}
								permissions={permissions}
								userSimplifyRole={userSimplifyRole}
								deleteOperationById={deleteOperationById}
							/>
						</div>
					</div>
				</>
			}
			{reload &&
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			}
		</div>
	);
}
