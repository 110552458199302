import React, { useState, useEffect } from 'react';
import formatMoment from '../Constants/FormatMoment';
export default function useStatusOperationGraph(op) {

    const today = moment();
    const [status, setStatus] = useState({});
    const { formatDateToLocal } = formatMoment;
    useEffect(() => {
        if (op) {
            const dateBeginOfOperation = formatDateToLocal(op.operation.startDate);
            const dateEndOfOperation = formatDateToLocal(op.operation.endDate);
            const isStoppedBoolOperation = op.isStopped;

            if (isStoppedBoolOperation) {
                setStatus({ id: 'stopped', label: 'Stopped', cssClass: 'stopped' });
                return;
            }

            if (op.executionMode === 'TEST') {
                setStatus({ id: 'testing', label: 'Draft', cssClass: 'testing' });
                return;
            }

            if (dateEndOfOperation < today) {
                setStatus({ id: 'past', label: 'Past', cssClass: 'past' });
                return;
            }

            if (dateBeginOfOperation > today) {
                setStatus({ id: 'planned', label: 'Scheduled', cssClass: 'planned' });
                return;
            }

            setStatus({ id: 'ongoing', label: 'Ongoing', cssClass: 'ongoing' });
        }
    }, [op])

    return status
}
