import HttpServices from './HttpServices';


export default class ReportingGenerationServices {
	constructor($http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
	}

	getReportingGenerationSetup(accountId, callbackSuccess, callbackNotFound, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}report/${accountId}/setup`, callbackSuccess, (message, error) => {
			if (error.status === 404){
				callbackNotFound();
			} else {
				callbackError(message, error);
			}
		});
	}
	updateReportingGenerationSetup(accountId, data, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}report/${accountId}/setup`, data, callbackSuccess, callbackError);
	}
	launchReportingGeneration(accountId, data, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}report/${accountId}/build`, data, callbackSuccess, callbackError);
	}
}