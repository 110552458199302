import React from 'react';
import InputCustom from '../../../Components/InputCustom';
import Modal from '../../../Components/Modal';
import ModalHeader from '../../../Components/ModalHeader';
import ModalBody from '../../../Components/ModalBody';
import ModalFooter from '../../../Components/ModalFooter';
import Btn from '../../../Components/Btn';

export default function ModalXmlSettings({
	xmlModalIsOpen,
	isExistingObj,
	setxmlModalIsOpen,
	namespaceSelected,
	handleChangeNameSpace,
	submitNewNameSpace
}) {
	return (
		<Modal
			isOpen={xmlModalIsOpen}
			onClose={() => setxmlModalIsOpen(false)}
		>
			<ModalHeader
				title={isExistingObj ? 'Edit ' + namespaceSelected?.prefix : 'New namespace'}
				hasBorder={true}
			/>
			<div className="modal_body">
				<InputCustom
					label='Prefix'
					name="prefix"
					value={namespaceSelected?.prefix}
					fullWidth={true}
					type="text"
					placeholder={'example : g'}
					onChange={(e) => handleChangeNameSpace(e)}
				/>
				<InputCustom
					label='Namespace'
					name="nameSpace"
					value={namespaceSelected?.nameSpace}
					fullWidth={true}
					type="text"
					placeholder={'example : g'}
					onChange={(e) => handleChangeNameSpace(e)}
				/>
			</div>
			<ModalFooter
				secondaryAction={
					<Btn
						message={'Cancel'}
						style={'ghost'}
						color={'secondary'}
						onClick={(e) => setxmlModalIsOpen(false)}
					/>
				}
				primaryAction={
					<Btn
						message={'Ok'}
						onClick={() => submitNewNameSpace()}
						disabled={namespaceSelected?.prefix === '' || namespaceSelected?.nameSpace === '' }
					/>
				}
			/>

		</Modal>
	);
}
