import React from 'react';
import styles from "./TinyLoader.module.css";

export default function TinyLoader() {
  {/*return <div className={styles.tinyLoader}></div>;*/}
  return <div className={`spinner`}>
    <div className="rect1"></div>
    <div className="rect2"></div>
    <div className="rect3"></div>
    <div className="rect4"></div>
    <div className="rect5"></div>
  </div>;
}
