import React from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import RouterWrapper from './RouterWrapper';
export default function ModuleWrapperRouter(props) {
  return (
	<div>
    <RouterWrapper props={props}>
    </RouterWrapper>

	</div>
  )
}
angular.module('beyableSaasApp.EmailTemplateMenu', [])
	.component(
		'modulewrapper',
		react2angular(
			ModuleWrapperRouter,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout']
		)
	);

