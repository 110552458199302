import moment from 'moment';
import 'moment-timezone';
import { listTz } from 'timezone-select-js';


// Timezone 
const getTimeZoneInList = (list, value) => {
	var lgt = list.length;
	for (var i = 0; i < lgt; i++) {
		var tz = list[i];
		if (tz.value === value) {
			return tz;
		}
	}
	return {};
};
const timeZoneList = listTz();

export const guessTimeZone = (defaultValue) => moment.tz.guess() || (defaultValue || 'Europe/Paris');

export const getTimeZoneById = (timezoneId) => getTimeZoneInList(timeZoneList, timezoneId);

export const withZoneToUtc = (date) => {
	const utcDate = moment.parseZone(date).utc();
	return utcDate;
};

export const appendTimezoneToDate = (date, timezone) => {
	const dateWithoutTz = moment(date).format('ddd MMM DD YYYY HH:mm');
	const otherZone = moment.tz(date, timezone).format('[GMT]ZZ');
	const dateWithOtherZone = [dateWithoutTz, otherZone].join(' ');
	return dateWithOtherZone;
};

export const getDateInTimeZone = (date, timezone) => {
	const dateInTz = moment.tz(date, timezone).toDate();
	return dateInTz;
};


