import HttpServices from './HttpServices';
import axios from 'axios';

export default class ProductAssociationServices {
	constructor($http, authServices, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
		this.accountId = accountId;
	}

	getProductAssociationSets(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}product/associationset/${this.accountId}`, callbackSuccess, callbackError);
	}

	getProductAssociationSetById(associationSetId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}product/associationset/${this.accountId}/${associationSetId}`, callbackSuccess, callbackError);
	}
	putProductAssociationSetById(associationSetId,obj ,callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}product/associationset/${this.accountId}/${associationSetId}`, obj, callbackSuccess, callbackError);
	}

	createProductAssociationSet(association, callbackSuccess, callbackError) {
		this.HttpServices.post(
			`${this.BO_API_URL}product/associationset/${this.accountId}`,
			association,
			callbackSuccess,
			callbackError
		);
	}

	deleteProductAssociationSet(associationSetId, callbackSuccess, callbackError) {
		this.HttpServices.delete(
			`${this.BO_API_URL}product/associationset/${this.accountId}/${associationSetId}`,
			callbackSuccess,
			callbackError
		);
	}

	getAllAssociations(offset, limit, associationSetId, callbackSuccess, callbackError) {
		this.HttpServices.get(
			`${this.BO_API_URL}product/association/${this.accountId}?offset=${offset}&limit=${limit}&associationSetId=${associationSetId}`,
			callbackSuccess,
			callbackError
		);
	}

	importAssociation(associationSetId, csvFile) {
		return axios.put(
			`${this.BO_API_URL}product/associationset/${this.accountId}/${associationSetId}/import`,
			csvFile,
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' }
		);
	}
}
