import { v4 as uuidv4 } from 'uuid';
export function checkIfObjectHasAnElementNonNull(objectToCheck, expression) {

	let expressionValueExist = objectToCheck.hasOwnProperty(expression);
	if (expressionValueExist) {
		if (objectToCheck[expression] !== null) {
			return objectToCheck[expression];
		} else {
			return false;
		}
	} else {
		return false;
	}
}
const AlgorithmsList = [
	'SellingPrice',
	'Price',
	'Stock',
	'Discount',
	'InStock',
	'DiscountAmount',
	'DiscountPercent',
	'ConversionsScore',
	'ViewsScore',
	'AddToCartsScore',
	'AbandonScore',
	'AttractionScore'
];
export const booleanOperator = [
	'And',
	'Or',
	// "AndNot",
	// "OrNot",
];
export const comparisonOperator = [
	'In',
	'NotIn',
	'Equals',
	'NotEquals',
	'Lower',
	'Greater',
	'LowerOrEquals',
	'GreaterOrEquals'
];

const ProductDatastring = [
	'SellingPrice',
	'Price',
	'Stock',
	'Discount',
	'InStock',
	'DiscountAmount',
	'DiscountPercent',
	'ConversionsScore',
	'ViewsScore',
	'AddToCartsScore',
	'AbandonScore',
	'AttractionScore'
];
export const ListFilter =
{
	'forFiltering': [
		{
			'name': 'Attributes',
			'itemList': [
				{
					'name': 'Brand',
					'id': '68f8ff2c-e636-4f79-ba79-5288ac4a13a7',
					'valueType': 'String'
				},
				{
					'name': 'Price',
					'id': '23ecafb3-c6eb-4a55-8294-3cc7c46880f0',
					'valueType': 'Number'
				},
				{
					'name': 'Id',
					'id': 'b77306d5-b557-4a37-82eb-3603bb0cfe69',
					'valueType': 'String'
				},
				{
					'name': 'Stock',
					'id': '4da545f0-3e4b-443a-9c3c-39a436138405',
					'valueType': 'Number'
				},
				{
					'name': 'Category',
					'selectorName': 'Category name',
					'id': 'e5d2415a-dbcd-476c-af07-18ce01302ea3',
					'valueType': 'String'
				},
				{
					'name': 'Tag',
					'id': 'e3bf0c9f-bb67-4de2-8ef6-0c1ae344b389',
					'valueType': 'String',
					'params': [
						{
							'name': 'Tag name',
							'valueType': 'String'
						}
					]
				},
				{
					'name': 'In Stock',
					'id': 'ad388e0c-7ecd-4ad2-83cb-e73ff869c046',
					'valueType': 'Boolean'
				}
			]
		},
		{
			'name': 'Scores',
			'itemList': [
				{
					'name': 'Conversions',
					'id': 'b658f8df-fa39-43b3-8b3c-672a61ad4e29',
					'valueType': 'Number'
				}
			]
		}
	],
	'forSorting': [
		{
			'name': 'Attributes',
			'itemList': [
				{
					'name': 'Price',
					'Id': '54c9e8a1-7c7d-44ae-9ebb-aec0549cc844'
				},
				{
					'name': 'Stock',
					'id': '74569936-b08e-4e95-b294-d7ba3cb167b1'
				},
				{
					'name': 'test',
					'id': '74563936-b08e-4e95-b294-d7ba3cb167b1'
				}
			]
		},
		{
			'name': 'Scores',
			'itemList': [
				{
					'name': 'Conversions',
					'id': '64303a1e-4510-4415-ac30-931de9b376fd'
				}
			]
		}
	],
	'transformations': [
		{
			'name': 'Transformations',
			'itemList': [
				{
					'name': 'Shuffle',
					'id': '728cd927-c10f-4110-a156-914756f3d7fc'
				},
				{
					'name': 'Diversify based on tag',
					'id': 'dc628360-5482-440e-8e5d-83c921bccbd7'
				}
			]
		}
	]
};
export const ListFilter2 =
{
	'ForFiltering': [
		{
			'Name': 'Attributes',
			'ItemList': [
				{
					'Name': 'Brand',
					'Id': '68f8ff2c-e636-4f79-ba79-5288ac4a13a7',
					'ValueType': 'String'
				},
				{
					'Name': 'Price',
					'Id': '23ecafb3-c6eb-4a55-8294-3cc7c46880f0',
					'ValueType': 'Number'
				},
				{
					'Name': 'Id',
					'Id': 'b77306d5-b557-4a37-82eb-3603bb0cfe69',
					'ValueType': 'String'
				},
				{
					'Name': 'Stock',
					'Id': '4da545f0-3e4b-443a-9c3c-39a436138405',
					'ValueType': 'Number'
				},
				{
					'Name': 'Category',
					'SelectorName': 'Category name',
					'Id': 'e5d2415a-dbcd-476c-af07-18ce01302ea3',
					'ValueType': 'String'
				},
				{
					'Name': 'Tag',
					'Id': 'e3bf0c9f-bb67-4de2-8ef6-0c1ae344b389',
					'ValueType': 'String',
					'Params': [
						{
							'Name': 'Tag name',
							'ValueType': 'String'
						}
					]
				},
				{
					'Name': 'In Stock',
					'Id': 'ad388e0c-7ecd-4ad2-83cb-e73ff869c046',
					'ValueType': 'Boolean'
				}
			]
		},
		{
			'Name': 'Scores',
			'ItemList': [
				{
					'Name': 'Conversions',
					'Id': 'b658f8df-fa39-43b3-8b3c-672a61ad4e29',
					'ValueType': 'Number'
				}
			]
		}
	],
	'ForSorting': [
		{
			'Name': 'Attributes',
			'ItemList': [
				{
					'Name': 'Price',
					'Id': '54c9e8a1-7c7d-44ae-9ebb-aec0549cc844'
				},
				{
					'Name': 'Stock',
					'Id': '74569936-b08e-4e95-b294-d7ba3cb167b1'
				},
				{
					'Name': 'test',
					'Id': '74563936-b08e-4e95-b294-d7ba3cb167b1'
				}
			]
		},
		{
			'Name': 'Scores',
			'ItemList': [
				{
					'Name': 'Conversions',
					'Id': '64303a1e-4510-4415-ac30-931de9b376fd'
				}
			]
		}
	],
	'Transformations': [
		{
			'Name': 'Transformations',
			'ItemList': [
				{
					'Name': 'Shuffle',
					'Id': '728cd927-c10f-4110-a156-914756f3d7fc'
				},
				{
					'Name': 'Diversify based on tag',
					'Id': 'dc628360-5482-440e-8e5d-83c921bccbd7'
				}
			]
		}
	]
};
export const CategoryList = [
	{
		value: 'Category',
		label: 'Category',
	},
	{
		value: 'Tag',
		label: 'Tag',
	},
	{
		value: 'ProductGlobal',
		label: 'Product (global)'
	},
	{
		value: 'ProductCategory',
		label: 'Product (category)',
	},
	{
		value: 'ProductTag',
		label: 'Product (tag)',
	},
	{
		value: 'Global',
		label: 'Global',
	},
];
export const CategoryListWithoutCategory = [
	{
		value: 'Tag',
		label: 'Tag',
	},
	{
		value: 'ProductGlobal',
		label: 'Product (global)'
	},
	{
		value: 'ProductTag',
		label: 'Product (tag)',
	},
	{
		value: 'Global',
		label: 'Global',
	},
];
const TypeRecommendation = {
	key: 'recommendations',
	Name: 'Recommendations',
	Id: uuidv4(),
	LastUpdate: new Date(),
	Context: 'Category',
	Sorts: [
		{
			Algorithm: '',
			Weight: ''
		}
	],
	FilterExpression: {
		Left: {
			Expression: {
			},
			StringValue: '',
			NumberValue: 0,
			BooleanValue: true,
			ProductData: '',
		},
		Op: '',
		Right: {
			Expression: {
			},
			StringValue: '',
			NumberValue: 0,
			BooleanValue: true,
			ProductData: '',
		}

	}

};
const TypeRanking = {
	Name: 'string',
	Id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
	LastUpdate: '2022-06-21T09:27:24.575Z',
	Sorting: [
		{
			Algorithm: 'SellingPrice',
			Weight: 0.5
		}
	]
};


export const dimensionsTypes = [
	TypeRecommendation,
	TypeRanking,
];