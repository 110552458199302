import React from 'react';
import { useAuditContext } from '../../context/ContextAudit';
import moment from 'moment';
import SelectDropdown from '../../../../Components/SelectDropdown';
import { useTranslation } from 'react-i18next';
import styles from '../Audit.module.css';

type TypeValues = {
  value: string;
  label: string
};

const selectList:Array<TypeValues> = [
	{
		value : 'month',
		label : '1 month'
	},
	{
		value : 'week',
		label : '1 week'
	},
	{
		value : 'day',
		label : '1 day'
	},
];

export default function Header() {

	const {
		dataValues,
		selectedPeriod,
		setselectedPeriod
	} = useAuditContext();

	const [t] = useTranslation('kpi');

	return (
		<div className={[styles.audit_header, 'flex'].join(' ')}>
			<div className='flex_item_full'>
				<div className={styles.audit_data}>{t('audit.analyseCarriedOut', {date: moment(dataValues?.evaluationDate).format('DD MMM YYYY')})}</div>
				{dataValues?.result && <div className={styles.audit_title}>{t('audit.xTypeOfScenario', {n: dataValues?.result?.length - 1})}</div>}
			</div>
			<div className='flex_item_fix'>
				<span className={styles.audit_period_label}>{t('audit.estimateBasedOn')}</span> 
				<SelectDropdown
					value={selectedPeriod}
					optionsList={selectList}
					color="white"
					onChange={(v) => setselectedPeriod(v)}
				/>
			</div>
		</div>
	);
}
