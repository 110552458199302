import React, { useEffect, useState, useRef } from 'react';
import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';
import Listbox from '../../../../Components/Listbox';
import ListboxItem from '../../../../Components/ListboxItem';
import { onSiteContext } from '../context';
import { Trans, useTranslation } from 'react-i18next';

export default function DropDownAction({
	campaign,
	dropdownIsOppen,
	setDropdownIsOppen,
	setParentDropdownIsOpen,
	getDuplicatedCampaignEditorPath,
	getTransformedToNativeAppCampaignEditorPath
}) {
	const {
		openCloseModalPermissions,
		openAccountConfigPannel,
		openCloseModalUploadImage,
		openModalCampaignLabels,
		openModalCampaignDelete,
		openModalCampaignScenarioLabels,
		isAdmin,
		duplicateACampaign,
		transformToNativeApp,
		externalAppSetup
	} = onSiteContext();

	const [canDuplicate, setCanDuplicate] = useState(<></>);
	const [canDelete, setCanDelete] = useState(<></>);
	const [canEditLabels, setCanEditLabels] = useState(<></>);
	const [canEditScenarioLabels, setCanEditScenarioLabels] = useState(<></>);
	const [canTransformToNativeApp, setCanTransformToNativeApp] = useState(<></>);
	const [updateImage, setUpdateImage] = useState(<></>);
	const [permissionsUser, setPermissionsUser] = useState({});

	if (!campaign) {
		return (<> </>);
	}

	const duplicateIsItDisabled = () => {
		if(campaign && campaign.editor && campaign.editor === 'targetedMessage/custom'){
			return false;
		}
		if (campaign && campaign.editor && campaign.editor.indexOf('targetedMessage') === 0 ){
			return true;
		}else{
			return false;
		}
	};

	const [t, i18n] = useTranslation('common');
	const upDateCanTransformToNativeApp = () => {
		//if ((isAdmin || (permissionsUser && permissionsUser.NATIVE_APP_CAMPAIGNS_CAN_CREATE)) && campaign.editor?.startsWith('nativeApp/')) {
		if (isAdmin && !campaign.editor?.startsWith('nativeApp/')) {
			setCanTransformToNativeApp(<ListboxItem
				message={t('DropDownAction.TransformToNativeApp')}
				icon='fas fa-copy'
				onClick={() => { transformToNativeApp(campaign.id, 
					id => {
						const path = getTransformedToNativeAppCampaignEditorPath(id);
						window.location.href = path;
					}); setDropdownIsOppen(false); }
				}
			/>);
		}
	};
	const upDateCanDuplicate = () => {
		const isDisabled = duplicateIsItDisabled();
		if (!isDisabled && (isAdmin || (permissionsUser && permissionsUser.CAMPAIGNS_CAN_CREATE))) {
			setCanDuplicate(
				<ListboxItem
					message='Duplicate'
					icon='fas fa-copy'
					onClick={() => { duplicateACampaign(campaign.id, 
						id => {
							const path = getDuplicatedCampaignEditorPath(id);
							window.location.href = path;
						}); setDropdownIsOppen(false); }
					}
				/>
			);
		}
	};
	const upDateCanDelete = () => {
		if (isAdmin || (permissionsUser && permissionsUser.CAMPAIGNS_CAN_DELETE)) {
			setCanDelete(
				<ListboxItem
					message={t('DropDownAction.Delete')}
					icon='fas fa-trash'
					onClick={() => { openModalCampaignDelete(true, campaign); closeDropdown(false); }}
				/>
			);
		}
	};
	const upDateCanEditLabels = () => {
		if (isAdmin || (permissionsUser && permissionsUser.CAMPAIGNS_CAN_CREATE)) {
			setCanEditLabels(
				<ListboxItem
					message={t('DropDownAction.EditLabels')}
					icon='fas fa-tag'
					onClick={() => { openModalCampaignLabels(true, campaign); closeDropdown(false); }}
				/>
			);
		}
	};
	const upDateCanEditScenarioLabels = () => {
		if (isAdmin) {
			setCanEditScenarioLabels(
				<ListboxItem
					message={t('DropDownAction.EditScenarioLabels')}
					icon='fas fa-tag'
					onClick={() => { openModalCampaignScenarioLabels(true, campaign); closeDropdown(false); }}
				/>
			);
		}
	};
	const uploadImage = () => {
		if (isAdmin || (permissionsUser && permissionsUser.CAMPAIGNS_CAN_CREATE)) {
			setUpdateImage(
				<ListboxItem
					message={t('DropDownAction.Upload')}
					icon='fas fa-image'
					onClick={() => { openCloseModalUploadImage(true, campaign); closeDropdown(false); }}
				/>
			);
		}
	};
	const closeDropdown = () => {
		setDropdownIsOppen(false);
		if (typeof setParentDropdownIsOpen === 'function') {
			setParentDropdownIsOpen(false);
		}
	};

	useEffect(() => {
		upDateCanDuplicate();
		upDateCanTransformToNativeApp();
		upDateCanDelete();
		upDateCanEditLabels();
		upDateCanEditScenarioLabels();
		uploadImage();
	}, [permissionsUser, campaign]);

	useEffect(() => {
		if (campaign) {
			const listOfCampaignPermission = (campaign.currentUserPermissions || [])
				.reduce((acc, perm) => ({... acc, [perm.permission]: perm.isAllowed}), {});
			setPermissionsUser(listOfCampaignPermission);
		}
	}, [campaign]);

	if (!isAdmin && !(permissionsUser || {}).CAMPAIGNS_CAN_CREATE && !(permissionsUser || {}).CAMPAIGNS_CAN_DELETE)
		return <></>;

	return (
		<Dropdown
			isOpen={dropdownIsOppen}
			setIsOpen={(e) => setDropdownIsOppen(true)}
			onHide={(e) => setDropdownIsOppen(false)}
			button={
				<Btn
					disabled={externalAppSetup?.installationStatus == 'Uninstalled'}
					style="outline"
					color="secondary"
					size="xs"
					icon="fas fa-ellipsis-v" />
			}>
			<Listbox>
				{canEditLabels}
				{canEditScenarioLabels}
				{updateImage}
				<li className="hr"></li>
				{isAdmin &&
					<ListboxItem
						message={t('DropDownAction.Emailing')}
						icon='fas fa-envelope'
						onClick={(e) => { openAccountConfigPannel(campaign.id); closeDropdown(false); }}
					/>
				}
				<li className="hr"></li>
				{isAdmin &&
					<ListboxItem
						message={t('DropDownAction.Permissions')}
						icon='fas fa-eye'
						onClick={(bool, id) => { openCloseModalPermissions(true, campaign); closeDropdown(false); }}
					/>
				}
				<li className="hr"></li>
				{canDuplicate}
				{canTransformToNativeApp}
				{canDelete}
			</Listbox>
		</Dropdown>
	);
}
