import React, { useState, useCallback, useEffect } from 'react';
import { react2angular } from 'react2angular';
import InteractionsBtnContainer from './InteractionsBtnContainer';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import BusinessOperationServices from '../../../Services/BusinessOperationServices';
import SystemServices from '../../../Services/SystemServices';
import UsersAdministrationServices from '../../../Services/UsersAdministrationServices';
import { BrowserRouter, Link } from 'react-router-dom';
import './PlanningContainer.css';
import CalendarContainer from './CalendarContainer';
import bronze from '../../../Permissions/rules-bronze';
import gold from '../../../Permissions/rules-gold';
import silver from '../../../Permissions/rules-silver';
import BusinessOperationProvider from '../Context/ContextBusinessOperations';
import { check } from '../../../Components/Can';
import Btn from '../../../Components/Btn';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import onsite_list_view_en from '../../../Translation/jsonfiles/en/onsite_list_view_en.json';
import onsite_list_view_fr from '../../../Translation/jsonfiles/fr/onsite_list_view_fr.json';
import RouterWrapper from '../RouterWrapper';

export default function PlanningContainer(props) {

	const [planAccount, setPlanAccount] = useState(gold);
	const [userSimplifyRole, setUserSimplifyRole] = useState('simplifyAdmin');
	const [dateRange, setdateRange] = useState();
	const [defaultView, setDefaultView] = useState('month');
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const businessOperationServices = new BusinessOperationServices(props.$http, props.AuthServices);
	const usersAdministrationServices = new UsersAdministrationServices(props.$http, props.$routeParams);
	const systemServices = new SystemServices(props.$rootScope);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const [dateOfTheSingleDatePicker, setDateOfTheSingleDatePicker] = useState();
	const [permissions, setPermissions] = useState();
	const getOperationByRangeDate = (accountId, startDate, endDate, callbackSuccess, callbackError) => businessOperationServices.getOperationByRangeDate(accountId, startDate, endDate, false, success => {
		callbackSuccess(success);
	}, error => { callbackError(error); });
	const getAllOperations = (accountId, callbackSuccess) => businessOperationServices.getAllOperations(accountId, callbackSuccess, (error) => systemServices.showError('an error occurred'));

	const loadOperationStatistics = (operationId, startDate, endDate, callbackSuccess, callbackError) => 
		businessOperationServices.getStatisticsByOperationId(accountId, operationId, startDate, endDate, null, callbackSuccess, callbackError);

	const putOperationExecutionStatusModeWithTest = (accountId, operationId, isStopped, executionMode, callbackSuccess, callbackError) =>
		businessOperationServices.putOperationExecutionStatusModeWithTest(accountId, operationId, isStopped, executionMode,
			success => {
				callbackSuccess(success);
			},
			error => { systemServices.showError('Error during the edit of status mode'); callbackError(error); }
		);
	const getAccountUsers = (accountId, callbackSuccess, callbackError) => usersAdministrationServices.getAccountUsers(accountId, success => { callbackSuccess(success); }, error => { systemServices.showError('Error'); callbackError(error); });

	const deleteOperationById = (accountId, operationId, callbackSuccess, callbackError) => businessOperationServices.deleteOperationById(accountId, operationId, success => { systemServices.showSuccess('The operation has been deleted with success'); callbackSuccess(success); }, error => systemServices.showError('Deleted failed'));
	useEffect(() => {
		const pickerStorage = JSON.parse(sessionStorage.getItem('simplifyPlanningPicker'));
		if (pickerStorage) {
			checkTheDayOfWeek(moment(pickerStorage).startOf('day'));
			setDateOfTheSingleDatePicker(moment(pickerStorage).startOf('day'));
		} else {
			checkTheDayOfWeek(moment().startOf('day'));
			setDateOfTheSingleDatePicker(moment().startOf('day'));
			const newPicker = moment().startOf('day');
			sessionStorage.setItem('simplifyPlanningPicker', JSON.stringify(newPicker));
		}
	}, []);

	useEffect(() => {
		if (dateOfTheSingleDatePicker) {
			const pickerStorage = dateOfTheSingleDatePicker;
			sessionStorage.setItem('simplifyPlanningPicker', JSON.stringify(pickerStorage));
		}
	}, [dateOfTheSingleDatePicker]);
	const checkTheDayOfWeek = (dateToCheck, operation) => {
		let firstDateOfCalendar = dateToCheck;
		let dayWeek = firstDateOfCalendar.weekday();
		if (!operation) {
			if (dayWeek >= 1) {
				firstDateOfCalendar = firstDateOfCalendar.weekday(0);
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			} else {
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			}
		}
		if (operation === 'today') {
			if (dayWeek >= 1) {
				firstDateOfCalendar = firstDateOfCalendar.weekday(0);
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
				setDateOfTheSingleDatePicker(moment().startOf('day'));
			} else {
				setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
				setDateOfTheSingleDatePicker(moment().startOf('day'));
			}
		}
		if (operation === 'after') {
			firstDateOfCalendar = firstDateOfCalendar.add(1, 'week');
			setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			setDateOfTheSingleDatePicker(firstDateOfCalendar.startOf('day'));
		}
		if (operation === 'before') {
			firstDateOfCalendar = firstDateOfCalendar.subtract(1, 'week');
			setdateRange({ startDate: firstDateOfCalendar.startOf('day') });
			setDateOfTheSingleDatePicker(firstDateOfCalendar.startOf('day'));
		}
	};

	let dateToCalculate;
	switch (defaultView) {
		case 'month':
			dateToCalculate = 28;
			break;
		case 'week':
			dateToCalculate = 6;
			break;
		default:
			dateToCalculate = 28;
	}

	let canCreateCampaign;
	if (planAccount && userSimplifyRole) {
		canCreateCampaign = check(planAccount, userSimplifyRole, 'simplifyOperation:canCreateCampaign', null);
	}

	// useEffect(() => {
	//     if (props.$rootScope) {
	//         getAccountUsers(accountId, data => {
	//             const rolesOfUser = data.find(user => user.id === props.$rootScope.User.Key);
	//             if (rolesOfUser) {
	//                 if (props.$routeParams.ka) {
	//                     setPermissions({ plan: "bronze", roles: "simplifyAdmin" })
	//                 } else {
	//                     const simplifyRoles = rolesOfUser.roles.reduce((acc, curr) => {
	//                         const accToLowerCase = curr.toLowerCase()
	//                         if (accToLowerCase.includes('simplify')) {
	//                             acc.push(curr);
	//                         }
	//                         return acc
	//                     }, [])
	//                     setPermissions({ plan: "bronze", roles: simplifyRoles.toString() })
	//                 }
	//             } else {
	//                 systemServices.showError('An error occured')
	//             }
	//         }, error => {
	//             systemServices.showError('An error occured')
	//             console.log("error", error);
	//         })
	//     }
	// }, [])

	// useEffect(() => {
	//     if (permissions) {
	//         setUserSimplifyRole(permissions.roles)
	//         if (permissions.plan === "gold") {
	//             setPlanAccount(gold)
	//         }
	//         if (permissions.plan === "bronze") {
	//             setPlanAccount(bronze)
	//         }
	//         if (permissions.plan === "silver") {
	//             setPlanAccount(silver)
	//         }
	//     }
	// }, [permissions])
	// useEffect(() => {
	//     setPlanAccount(gold)
	// }, [])
	const i18nL= localStorage.getItem('i18nL');
	const newInstanceI18nextPlanning = i18next.createInstance();
	newInstanceI18nextPlanning.init({
		lng: 'en-EN',                             
		resources: {
			'en-US': {
				common: onsite_list_view_en            
			},
			'en-EN': {
				common: onsite_list_view_en            
			},
			'fr-FR': {
				common: onsite_list_view_fr
			},
		}},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextPlanning.changeLanguage(JSON.parse(i18nL));
	});
	return (
		<BusinessOperationProvider
			$http={props.$http}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
			accountId={accountId}>
			<I18nextProvider i18n={newInstanceI18nextPlanning}>
				<RouterWrapper props={props}>
					<div className="page_full flex flex_v">
						<section className="section no_bottom_pad section_primary">
							<div className="flex">
								<div className="h1 flex_item_full">Planning</div>
								{canCreateCampaign &&
									<div className="flex_item_fix ml_30">
										<Btn
											href={props.$routeParams && props.$routeParams.ka ? `/BusinessOperations/Create?ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}&prev=planning` : '/BusinessOperations/Create?prev=planning'}
											message="New operation"
											icon="fas fa-plus fa-sm"
										/>
									</div>
								}
							</div>
						</section>
						<section className="section no_bottom_pad mb_10 flex_item_fix">
							{dateRange &&
							<InteractionsBtnContainer
								permissions={planAccount}
								userSimplifyRole={userSimplifyRole}
								setDefaultView={(e) => setDefaultView(e)}
								dateOfTheSingleDatePicker={dateOfTheSingleDatePicker}
								setDateOfTheSingleDatePicker={(e) => setDateOfTheSingleDatePicker(e)}
								routeParams={props.$routeParams}
								accountId={accountId}
								dateRange={dateRange}
								setdateRange={(e) => setdateRange(e)}
								checkTheDayOfWeek={(e, op) => checkTheDayOfWeek(e, op)}
							/>
							}
						</section>
						<section className="section no_top_pad flex_item_full pos_rel flex flex_v overflow_hidden">
							{dateRange &&
							<CalendarContainer
								permissions={planAccount}
								userSimplifyRole={userSimplifyRole}
								accountId={accountId}
								dateToCalculate={dateToCalculate}
								routeParams={props.$routeParams}
								dateRange={dateRange}
								getOperationByRangeDate={getOperationByRangeDate}
								getAllOperations={getAllOperations}
								loadOperationStatistics={loadOperationStatistics}
								putOperationExecutionStatusModeWithTest={putOperationExecutionStatusModeWithTest}
								deleteOperationById={deleteOperationById}
							/>
							}
						</section>
					</div>
				</RouterWrapper>
			</I18nextProvider>
		</BusinessOperationProvider>
	);
}
angular.module('beyableSaasApp.BusinessOperationsPlanning', ['beyableSaasApp.Admin.Services'])
	.component('businessoperationplanning', react2angular(PlanningContainer, [], ['$http', '$rootScope', '$routeParams', 'AuthServices']));