import React, { useCallback, useEffect } from 'react';
import Portal from './Portal';

export default function Modal({
	children,
	isOpen = false,
	onClose,
	noClose = false,
	width,
	height,
	minHeight,
	fullScreen = false,
	animation = '' // slide_down
}) {
	const style = {};
	const hide = (ev) => {
		if (typeof onClose === 'function') {
			onClose(ev);
		}
		
	};
	const cssClass = ['modal_outer', 'section_root'];

	if (width) {
		style.width = width + 'px';
	}
	if (height) {
		style.height = height + 'px';
	}
	if (minHeight) {
		style.minHeight = 'min(100%, ' + minHeight + 'px)';
	}
	if (animation){
		cssClass.push('animation_' + animation);
	}
	if (fullScreen) {
		style.width = "100%";
		style.height = "100%";
		cssClass.push('is_fullscreen');
	}

	if (!isOpen) return '';

	// if you want to close modal on esc 
	// bug to fix : close all modale not only one add uuid

    // const escFunction = useCallback((event) => {
	// 	if(event.keyCode === 27 && typeof onClose === 'function' ) {
	// 		onClose(event)
	// 	}
	//   }, []);

	//   useEffect(() => {
	// 	document.addEventListener("keydown", escFunction, false);
	
	// 	return () => {
	// 	  document.removeEventListener("keydown", escFunction, false);
	// 	};
	//   }, []);


	return (
		<Portal>
			<div className={cssClass.join(' ')} tabIndex="-1">
				<div className="modal_inner" style={style}>
					{!noClose &&
						<a onClick={(ev) => hide(ev)} className="modal_close"></a>
					}
					{children}
				</div>
			</div>
		</Portal>
	)
}
