import { useState, useEffect, useRef} from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

const useSocket = (accountId, tenant) => {
    const [socket, setSocket] = useState(0);
    const [ connection, setConnection ] = useState(null);
    const [ connectionStarted, setConnectionStarted ] = useState(false);
    const [connectedTenant, setConnectedTenant] = useState(tenant)

    const getBaseUrl = () => {
        if (!window || !window.BEYABLE_env || !window.BEYABLE_env.ANALYTICS_BO_SOCKET_URL) {
            throw "Configuration [window.BEYABLE_env.ANALYTICS_BO_SOCKET_URL] missing";
        }
        else {
            return `${window.BEYABLE_env.ANALYTICS_BO_SOCKET_URL}`;
        }
    }

    const stopListen = (tenant_) => {
        if (connection && connection.connectionStarted) {
            try {
                console.log("StopListenActiveUsers", {accountId, tenant_})
                connection.send('StopListenActiveUsers', accountId, tenant_);
            }
            catch(e) {
                console.log(e);
            }
        }
    }

    const startListen = (tenant_) => {
        if (connection && connection.connectionStarted) {
            try {
                console.log("StartListenActiveUsers", {accountId, tenant_})
                connection.send('StartListenActiveUsers', accountId, tenant_);
            }
            catch(e) {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(getBaseUrl()+'activeusers')
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection && !connection.connectionStarted) {
            connection.start({ withCredentials: false })
                .then(() => {
                    connection.on('RefreshActiveUsers', ( { activeUsersCount } ) => {
                        setSocket(activeUsersCount)
                    });
                    setConnectionStarted(true);
                })
                .catch(e => console.log('Connection failed: ', e));
        }

        return () => { stopListen(connectedTenant) }

    }, [connection]);

    useEffect(() => {
        startListen(connectedTenant)
    }, [connectionStarted]);

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        stopListen(connectedTenant)
        setConnectedTenant(tenant)
        startListen(tenant)
    }, [tenant])

    return [socket] 
};

export default useSocket;