
const ListOFCategoriesField = [

    {
        name: "categories",
        value: "categories",
        label: "Categories",
        type: "string",
    },
    {
        name: "description",
        value: "description",
        label: "Description",
        type: "string",
    },
    {
        name: "id",
        value: "id",
        label: "Id",
        type: "string",
    },
    {
        name: "name",
        value: "name",
        label: "Name",
        type: "string",
    },
    {
        name: "price",
        value: "price",
        label: "Price",
        type: "number",
    },

    {
        name: "unitPriceWithRebate",
        value: "unitPriceWithRebate",
        label: "Price With Rebate",
        type: "number",
    },
    {
        name: "globalStockQuantity",
        value: "globalStockQuantity",
        label: "Stock quantity",
        type: "number",
    },
    {
        name: "tags",
        value: "tags",
        label: "Tags",
        type: "tags",
    },
    {
        name: "url",
        value: "url",
        label: "Url",
        type: "string",
    },
    {
        name: "urlThumbnail",
        value: "urlThumbnail",
        label: "Url Thumbnail",
        type: "string",
    },

    // {
    //     name: "productVariants",
    //     value: "productVariants",
    //     label: "Product variants",
    //     type: "string",
    // },


]

export default ListOFCategoriesField