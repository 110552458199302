export default function createUtil() {
	const isCorrectEmail = (mail) => {
		const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (mail.match(regex)) {
			return true;
		} else {
			return false;
		}
	};

	function checkErrors(account) {
		const arrayOfErrors = [];
		if (account.AccountName.trim().length === 0) {
			arrayOfErrors.push({
				key: 'AccountName',
				message: 'Please enter a valid account name'
			})
		}
		if (!isCorrectEmail(account.Email)) {
			arrayOfErrors.push({
				key: 'Email',
				message: 'Please enter a valid email'
			})
		}
		return arrayOfErrors;
	}

	return {
		checkErrors
	}
}