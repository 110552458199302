import React, { useState, useEffect } from 'react';
import {datesOptions,today, compareDatesOptions} from './config';
import styles from './Nav.module.css';
import moment from 'moment';
import 'moment-timezone';
import Dropdown from '../../../Components/Dropdown';

import CustomPicker from './CustomPicker';
// const FORMAT = 'YYYY/MM/DD';
// TODO @Romain : Move into a generic component

const Nav = ({
	handleClick,
	initialDates,
	initialLabel = 'initialLabel',
	navLabel = 'Period',
	setisCustomSelected,
	isCustomSelected = false,
	customDateRange,
	setCustomDateRange,
	datainformation
}) => {
	
	const [current, setCurrent] = useState(null);
	const labels =  Object.keys(datesOptions);
	const [focusedInput, setFocusedInput] = useState('startDate');
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [dateIsValidate, setdateIsValidate] = useState(false);
	const [btn, setbtn] = useState(<></>);
	const [needRefresh, setneedRefresh] = useState(false)

	const handleOnDateChange = ({ startDate, endDate }) => {
		setCustomDateRange({
			startDate: startDate,
			endDate: endDate
		});
	};
	useEffect(()=>{
		if(!dropdownIsOpen){
			if(!dateIsValidate && initialDates.initialLabel === 'Custom'){
				setCustomDateRange({
					startDate: moment(initialDates.fromDate),
					endDate: moment(initialDates.toDate)
				});
			}
		}
	},[dropdownIsOpen]);
	const onFocused = (focused) => {
		if (focused === null) {
			// setDropdownIsOpen(false);
		}
		setFocusedInput(!focused ? 'startDate' : focused);
	};
	const onHandleClick = (label) => {
		if (label === '') return;
		if (label === 'From the beginning'){

			sessionStorage.setItem('fromBeginning', true);
			setisCustomSelected(false);
			setDropdownIsOpen(false);
			const endD = datainformation.endDate ? moment(datainformation.endDate.split('T')[0]) : moment(today);
			handleClick(
				{
					fromDate:moment(datainformation.startDate.split('T')[0]),
					toDate: endD
				});
			setCurrent({ fromDate:moment(datainformation.startDate.split('T')[0]),
				toDate: endD, label: label });
			return;
		}
		setCurrent({ ...datesOptions[label], label: label });
		if (label !== 'Custom') {		
			setisCustomSelected(false);
			setDropdownIsOpen(false);
			handleClick(datesOptions[label]);
			sessionStorage.setItem('fromBeginning', false);
		}
		if (label === 'Custom') {
			setneedRefresh(true);
			setisCustomSelected(true);
			sessionStorage.setItem('fromBeginning', false);
		}
	};
	const handleValidDate = () => {
		setDropdownIsOpen(false);
		setdateIsValidate(true);
		handleClick(
			{
				fromDate: customDateRange.startDate,
				toDate: customDateRange.endDate
			});
	};

	useEffect(()=>{
		if(initialDates ){
		setneedRefresh(true);
			if(initialDates.initialLabel === 'Custom'){
				if(datainformation){
					const fromBegining = JSON.parse(sessionStorage.getItem('fromBeginning'));
				
					if( fromBegining){
						setCurrent(
							{ ...datesOptions[initialDates.initialLabel],
								label: 'From the beginning' });
						setisCustomSelected(false);
						setdateIsValidate(true);
					}else{
						setisCustomSelected(true);
						setCurrent(
							{ ...datesOptions[initialDates.initialLabel],
								label: initialDates.initialLabel });
					}
				}else{
					setCurrent(
						{ ...datesOptions[initialDates.initialLabel],
							label: initialDates.initialLabel });
					setisCustomSelected(true);
					setdateIsValidate(false);
				}
			}else{
				setisCustomSelected(false);
				setCurrent(
					{ ...datesOptions[initialDates.initialLabel],
						label: initialDates.initialLabel });
			}
		}
	},[initialDates,datainformation]);

	useEffect(()=>{
		if(needRefresh){
			const button = (
				<button className='custom_input clickable w_auto m bg_white'>
					<span className="custom_input_value has_label">
						<span className="custom_input_value_label">{navLabel}</span>
						<span className="custom_input_placeholder">
							{isCustomSelected &&
								<>
									{moment(customDateRange.startDate).format('MMM DD, YYYY')}
									<i className="analytics_range_arrow fas fa-arrow-right"></i>
									{moment(customDateRange.endDate).format('MMM DD, YYYY')}
								</>
							}
							{!isCustomSelected&&
								<>{current?.label}</>
							}
						</span>
					</span>
					<i className='custom_input_icon fas fa-calendar-alt'></i>
				</button>
			);
			setbtn(button);
			setneedRefresh(false)
		}
	

	},[needRefresh]);

	

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={(e) => setDropdownIsOpen(true)}
			onHide={(e) => setDropdownIsOpen(false)}
			button={btn}
			flip={false}
		>
			<div className="flex flex_align_start">
				<div className="flex_item_full_fix">
					<ul className="listbox">
						{labels.map((label) => {
							const isSelected = isCustomSelected ? label === 'Custom' : (current?.label) === label;
							return (
								<li key={label}>
									<a className={isSelected ? 'listbox_item selected' : 'listbox_item'}
										onClick={() => onHandleClick(label)}>
										{label}
										{isSelected && (<span className={styles.indicator}></span>)}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
				<CustomPicker 
					isCustomSelected={isCustomSelected}
					customDateRange={customDateRange}
					onFocused={onFocused}
					focusedInput={focusedInput}
					handleValidDate={handleValidDate}
					handleOnDateChange={handleOnDateChange}
				/>
			</div>
		</Dropdown>
	);
};
Nav.displayName = 'Nav';
export default Nav;