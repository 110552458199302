import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Btn from '../../../Components/Btn';
import CropModal from './CropModal';
import ReactTooltip from 'react-tooltip';
import './ImageInputContainer.css';
import { v4 as uuidv4 } from 'uuid';
import { useWysiwygEditorContext } from '../context/WysiwygEditorContext';

const activeStyle = {
	borderColor: '#2196f3',
	color: '#2196f3'
};
const acceptStyle = {
	borderColor: '#006fff',
	color: '#006fff'
};
const rejectStyle = {
	borderColor: '#ff1744',
	color: '#ff1744'
};
import {useTranslation} from 'react-i18next';
function ImageInputContainer({ label, defaultImageUrl, property, removeImageCallBack, setImageCallBack, editorUniqueId }) {
	const {
		cloudImageServices,
		systemServices
	} = useWysiwygEditorContext();

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [thumbnail, setThumbnail] = useState(defaultImageUrl);
	const [currentImageUrl, setCurrentImageUrl] = useState(defaultImageUrl);	
	const [source, setSource] = useState();

	const [cropRatioParameters, setCropRatioParameters] = useState({});
	const [cropRatioLabel, setCropRatioLabel] = useState('');
	const [maxFileSize, setMaxFileSize] = useState(500);
	const [cropableImage, setCropableImage] = useState();
	const [widgetType, setwidgetType] = useState('Cloudinary');
	const [isPreparingCropModal, setIsPreparingCropModal] = useState(false);
	const [openCropModal, setOpenCropModal] = useState(false);
	const [sourceIsSvg, setsourceIsSvg] = useState(false);
	const isOptional = true;//property.Editor.Optional;
	const [t, i18n] = useTranslation('common');
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		open
	} = useDropzone({
		accept: 'image/*',
		noClick: true,
		noKeyboard: true,
		onDrop: acceptedFiles => {
			var files = acceptedFiles.length > 0 ? [acceptedFiles[0]] : [];
			if(files.length > 0){
				var fileSize = files[0].size / 1000;
				if(fileSize <= maxFileSize) {
					setSelectedFiles(files);
				}else{
					console.error('error during the upload', acceptedFiles);
					systemServices.showError("The uploaded image is too large (" + maxFileSize + "kb max is expected).");
				}
			}else{
				setSelectedFiles(files);
			}
		}
	});
	const tooltipId = uuidv4();

	function getCropRatio(prt) {
		if(prt.CropRatio) return `${prt.CropRatio.Width}x${prt.CropRatio.Height}`;
	}
	
	function checkImageIsCloudinary(url) {
		let domain;
		try {
			domain = (new URL(url));
		} catch (_) {
			return false;
		}
		domain = domain.hostname;
		if (domain == 'res.cloudinary.com') {
			return true;
		}
		return false;
	}

	const style = useMemo(
		() => ({
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject]
	);

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			selectedFiles.forEach(file => URL.revokeObjectURL(file.preview));
		},
		[selectedFiles]
	);
	

	useEffect(() => {
		// crop ratio
		property.Editor.CropRatio && setCropRatioParameters({
			width: property.Editor.CropRatio.Width,
			height: property.Editor.CropRatio.Height,
			locked: property.Editor.CropRatio.Locked
		});
		setCropRatioLabel(getCropRatio(property));

		// maximum file size
		if(property.MaxFileSize && property.MaxFileSize < 500) setMaxFileSize(property.MaxFileSize);
	}, [property]);


	useEffect(() => {
		checkImageIsCloudinary(defaultImageUrl);
	}, [defaultImageUrl]);

	useEffect(() => {
		const fetchSource = async () => {
			try {
				var sourceResult = await cloudImageServices.findImageSource(currentImageUrl);
				setSource(sourceResult.data);
			} catch (error) {
				console.log('error', error);
				setSource();
			}
		};
		
		fetchSource();
	}, [currentImageUrl]);

	const handleUploadError = (response) => {
		console.error('error during the upload', response && response.data);
		if (response && response.data && response.data.error) {
			systemServices.showError(response.data.error);
		} else {
			systemServices.showError('An error occured during the upload, please retry');
		}
	};


	const uploadToTwicPics = async (libraryId, files) => {
		const res = await cloudImageServices.uploadToTwicPics(libraryId, files,
			progressEvent => {
				const progress = progressEvent.loaded / progressEvent.total * 100;
				// console.log('uploading twicpics', Math.round(progress));
			});
		const resultCloudData = await cloudImageServices.postAddCloudImagesToLibrary(libraryId, res.data);
		const imageUrl = resultCloudData.data.createdBanners[0].imagePath;
		return imageUrl;
	};

	const uploadImage = async (files, libraryId) => {
		setIsUploading(true);

		try {
			const imageUrl = await uploadToTwicPics(libraryId, files);
			setImageCallBack(imageUrl);
			setThumbnail(imageUrl);
			setCurrentImageUrl(imageUrl);
			setTimeout(() => {
				setIsUploading(false);
			}, 500);
		} catch (error) {
			handleUploadError(error.response);
			setIsUploading(false);
		}
	};

	const prepareFilesToUpload = (libraryId) => {
		if (selectedFiles.length > 0) {
			let formData = new FormData();
			for (let i = 0; i < selectedFiles.length; i++) {
				let fileToRegister = selectedFiles[i];
				formData.append(`file[${i}]`, fileToRegister);
			}
			return uploadImage(formData, libraryId);
		}
		return Promise.resolve();
	};



	const uploadSelectedImages = () => {
		const uploadHandler = async () => {
			const res = await cloudImageServices.postCreateLibrary();
			const libraryId = res.data.libraryId;
			await prepareFilesToUpload(libraryId);
		};

		if (selectedFiles.length > 0) {
			uploadHandler()
				.catch(error => {
					console.error('error during creation of the library', error);
				});
		}
	};
	useEffect(() => {
		uploadSelectedImages();
	}, [selectedFiles]);


	const removeImage = () => {
		setThumbnail();
		setCurrentImageUrl();
		setSelectedFiles([]);
		removeImageCallBack();
	};

	const cropImage = async () => {
		setIsPreparingCropModal(true);
		const libraryId = uuidv4();
		const getCropableImage = async () => {
			try {
				if (checkImageIsCloudinary(source.imagePath)) {
					setwidgetType('Cloudinary');
					return {...source,  libraryId, sourceId: source.metaData.sourceId}; 
				} else {
					setwidgetType('TwicPic');
					return {...source};
					// setwidgetType('Cloudinary');
					// console.log('source', source);
					// const transfered = await cloudImageServices.transferImageToCloudinary(source, libraryId);
					// const resultCloudData = await cloudImageServices.postAddCloudImagesToLibrary(libraryId, [transfered.data]);
					// return {...resultCloudData.data.createdBanners[0], libraryId, sourceId: transfered.data.sourceId};
				}
			} catch (error) {
				console.log('error', error);
				systemServices.showError('Cannot crop image. Try uploading a new image.');
				return null;
			}
		};
		const cropableImage = await getCropableImage();
		if (cropableImage) {
			setCropableImage(cropableImage);
			setOpenCropModal(true);
		}
		setIsPreparingCropModal(false);
	};

	const handleImageCropped = async (cropedImage) => {
		let imageTransform =  {};
		const libraryId = widgetType === 'TwicPic' ?  uuidv4() :cropableImage.libraryId;
		if(widgetType === 'TwicPic' ){
			imageTransform = {libraryId: libraryId, ...cropedImage};
		}else{
			imageTransform = {
				url: cropedImage.data.assets[0].secureUrl,
				bytes: cropedImage.bytes,
				fileName: source.metaData.fileName,
				height: cropedImage.height,
				width: cropedImage.width,
				sourceId: null,
				transformationSourceId: cropableImage.sourceId
			};
		}
		const resultCloudData = await cloudImageServices.postAddCloudImagesToLibrary(libraryId, [ imageTransform]);
		const imageUrl = resultCloudData.data.createdBanners[0].imagePath;
		setThumbnail(imageUrl);
		setImageCallBack(imageUrl);
	};

	const browseFiles = (message) =>
		<Btn
			onClick={open}
			message={message}
			style="outline"
			size="s"
		/>;

	const displayThumbnail = () => {
		if (!thumbnail || !currentImageUrl) return <></>;
		return (
			<div className="flex_item_fix mr_10 format_param_thumbnail">
				<img src={thumbnail} />
			</div>
		);
	};
	useEffect(()=>{
		function sourceImgIsSvg(sourceCheck){
			const res =  sourceCheck?.metaData?.fileName.includes('svg'); 
			setsourceIsSvg(res);
		}
		if(source){
			sourceImgIsSvg(source);
		}

	},[source]);
	const displayThumbnailActions = () => {
		if (!thumbnail || !currentImageUrl) return <></>;
		return (
			<>
				<div className="flex_item_fix ml_10">
					{browseFiles('Replace')}
				</div>
				<div className="flex_item_fix ml_10">
					<div className="btn_switch">
						{source && 
							<Btn onClick={cropImage}
								icon="fas fa-crop-alt"
								style="outline"
								color="secondary"
								size="s"
								className="btn_w_xxs"
								disabled={isPreparingCropModal || sourceIsSvg}
							/>
						}
						{isOptional &&
							<Btn
								onClick={() => removeImage()}
								icon="fas fa-ban"
								style="outline"
								color="secondary"
								size="s"
								className="btn_w_xxs"
							/>
						}
					</div>
				</div>
			</>
		);
	};

	return (
		<div>

			<div className="form_block flex pos_rel" {...getRootProps({ style })}>
				{thumbnail && displayThumbnail()}
				<div className="flex_item_full">
					<div className="form_block_label no_margin">
						{label}
						<a className="icon_btn s" data-tip data-for={tooltipId}>
							<i className="fas fa-info-circle" />
						</a>
						<ReactTooltip id={tooltipId} aria-haspopup='true' >
							<p>{t('img.acceptedFormat') + ' .png, .jpg, .jpeg, .gif, .webp'}</p>
							<p>{t('img.maximumFileSize') + ' ' + maxFileSize + ' Ko'}</p>
							{ cropRatioLabel && <p> {t('img.dimensions') + ' ' + cropRatioLabel}</p> }
						</ReactTooltip>
						<div className="s_13 grey_2 fw_normal">
							{!currentImageUrl ?
							<>{t('img.dragDrop')} </>
							:
							<>{t('img.newImg')}</>
							}
						</div>
					</div>
				</div>
				{
					!currentImageUrl &&
					<div className="flex_item_fix ml_10">
						{browseFiles('Browse files')}
					</div>
				}
				{thumbnail && displayThumbnailActions()}

				<input {...getInputProps()} />
				{cropableImage && 
					<CropModal
						widgetType={widgetType}
						banner={cropableImage}
						ratioParameters={cropRatioParameters}
						openCropModal={openCropModal}
						closeCropModal={() => setOpenCropModal(false)}
						handleImageCropped={handleImageCropped}
					/>
				}
				{isUploading &&
					<div className="mask">
						<span className="wheel"></span>
					</div>
				}
			</div>
		</div>
	);
}

export default ImageInputContainer;
