import React, { createContext, useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
import { EditorCreateOrUpdateMode, EditorState, EditorStep } from './EditorStateTypes';
import { DeviceType, TemplateProperty } from '../../../WysiwygEditor/Types/BespokeTypes';
import { emptyTemplate, ServerSideCampaignObject, ServerSideSaaSFormat } from './ServerSideCampaignTypes';
import { initializeFromFormat, validate, ValidationError } from './EditorOperations';
import { Style } from './StyleTypes';
import { convertSaaSFormatFromApiType, convertSaaSFormatsFromApiType, convertServerSideCampaignForSaving, convertServerSideCampaignForUpdate } from './TypeAdapters';
import ServerSideCampaignServices from '../../../../Services/ServerSideCampaignServices';
import { ProductRecommendationsValue } from '../../../WysiwygEditor/Utils/TemplateOperations';

const ServerSideEditorContext = createContext<ServerSideEditorContextType | undefined>(undefined);

function useServerSideEditorContext() {
	const context = useContext(ServerSideEditorContext);
	if (!context) throw Error('useServerSideEditorContext can only be used inside an ServerSideEditorContextProvider');
	return context;
}

const CreateServerSideEditorContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;

	// const authServices = props.AuthServices;
	// const getAccessToken = authServices && authServices.getAccessToken;
	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();

	const crud = new ServerSideCampaignServices(accountId, $http);

	const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(true);

	const mode: EditorCreateOrUpdateMode = !$routeParams.id ? EditorCreateOrUpdateMode.Create : EditorCreateOrUpdateMode.Update;

	const [currentDevice, setCurrentDevice] = useState<DeviceType>(DeviceType.Desktop);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [serverSideCampaign, setServerSideCampaign] = useState<ServerSideCampaignObject>(emptyTemplate);

	const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

	const [formatSavedStyles] = useState<Style[]>([]);
	const [matchedExistingStyle] = useState<Style | null>(null);

	const [saasFormats, setSaasFormats] = useState<ServerSideSaaSFormat[]>([]);

	const [templateSelected, setTemplateSelected] = useState<ServerSideSaaSFormat>();

	const [editorState, setEditorState] = useState<EditorState>({
		sideBarStep: EditorStep.One,
		formatIsSelected: false,
		selectedFormat: null,
		selectedStyle: { Id: 'default', Properties: [] },
		defaultStyle: null,
		currentStyle: null,
		currentWysiwyg: null,
	});
	const [modelProperties, setModelProperties] = useState<TemplateProperty[]>([]);


	const getCampaignByIdAndTransformToEditor = (id: string) => {
		setIsLoading(true);
		crud.getServerSideCampaignById(id,
			result => {
				const selectedSaaSFormat: ServerSideSaaSFormat = convertSaaSFormatFromApiType(result.saaSFormat);
				setTemplateSelected(selectedSaaSFormat);
				const { updateVariation } = initializeFromFormat(
					currentDevice,
					mode,
					selectedSaaSFormat.template,
					true,
					true,
					editorState,
					serverSideCampaign,
					true,
				);

				const campaignWithEditorId = updateVariation(serverSideCampaign);
				const mergeElementEditor = campaignWithEditorId.Properties.map((property: TemplateProperty, idx) => {
					if (property.Editor.Type === 'ProductRecommendations') {
						const editorValue = result.templateProperties[idx].value;
						return { ...property, Value: ProductRecommendationsValue.fromValue(JSON.parse(editorValue)) };
					}
					else {
						const editorValue = result.templateProperties[idx].value;
						return { ...property, Value: editorValue };
					}
				});

				campaignWithEditorId.Properties = mergeElementEditor;
				const mergeElementTemplate = campaignWithEditorId?.Template?.Properties.map((property: TemplateProperty, idx) => {
					if (property.Editor.Type === 'ProductRecommendations') {
						const editorValue = result.templateProperties[idx].value;
						return { ...property, Value: ProductRecommendationsValue.fromValue(JSON.parse(editorValue)) };
					}
					else {
						const editorValue = result.templateProperties[idx].value;
						return { ...property, Value: editorValue };
					}
				});
				if (campaignWithEditorId?.Template?.Properties && mergeElementTemplate) {
					campaignWithEditorId.Template.Properties = mergeElementTemplate;
				}


				setServerSideCampaign((s): ServerSideCampaignObject => ({
					...s,
					Properties: campaignWithEditorId.Properties,
					// Id: elementFormatted.template.Key,
					Template: campaignWithEditorId.Template,
					TemplateDescriptorDesktop: campaignWithEditorId.TemplateDescriptorDesktop,
					TemplateDescriptorMobile: campaignWithEditorId.TemplateDescriptorMobile,
					//Status: result.status,
					Name: result.name,
					Id: result.id
				}));

				setEditorState((s) => ({
					...s,
					formatIsSelected: true,
					currentWysiwyg: campaignWithEditorId.Template !== null ? campaignWithEditorId.Template.WysiwygDesktop : null
				}));

			}, err => {
				console.log(err);
				systemServices.showError('An error occured while getting templates.');
			});
	};
	const loadSaaSFormats = () => {
		crud.getServerSideSaaSFormats(formats => {
			const sass = convertSaaSFormatsFromApiType(formats);
			setSaasFormats(sass);
		}, err => {
			console.log(err);
			systemServices.showError('An error occured while getting templates.');
		});
	};

	const initializeFromSelectedFormat = (format: ServerSideSaaSFormat): void => {
		const { updateEditorState, updateVariation } = initializeFromFormat(
			currentDevice,
			mode,
			format.template,
			true,
			true,
			editorState,
			serverSideCampaign,
			true
		);

		setServerSideCampaign(updateVariation);
		setEditorState(updateEditorState);
	};

	const checkValidationErrors = (emailTemplate: ServerSideCampaignObject): boolean => {
		const { validateEditorState, validateInformationsData, validateFormatData } = validate();
		const { isValid: isValid0, errors: errors0 } = validateEditorState(editorState, validationErrors);
		const { isValid: isValid1, errors: errors1 } = validateInformationsData(emailTemplate, errors0);
		const { isValid: isValid2, errors: errors2 } = validateFormatData(emailTemplate, errors1);
		setValidationErrors(errors2);
		return isValid0 && isValid1 && isValid2;
	};


	useEffect(() => {

		setEditorState((s) => ({
			...s,
			currentWysiwyg:
				editorState.selectedFormat == null ? s.currentWysiwyg :
					(currentDevice === DeviceType.Desktop
						? editorState.selectedFormat.WysiwygDesktop
						: editorState.selectedFormat.WysiwygMobile),
		}));
	}, [currentDevice]);


	useEffect(() => {
		if (mode !== EditorCreateOrUpdateMode.Create) {
			const idTemplate: string = $routeParams.id;
			getCampaignByIdAndTransformToEditor(idTemplate);
		} else {
			loadSaaSFormats();
			setIsLoading(true);
		}

	}, []);

	// events

	const handleChangeStep = (step: EditorStep) => {
		setEditorState(x => ({ ...x, sideBarStep: step }));
	};

	const handleFormatPreviewClicked = (format: ServerSideSaaSFormat) => {

		setTemplateSelected(format);
		const { updateEditorState, updateVariation } = initializeFromFormat(
			currentDevice,
			mode,
			format.template,
			false,
			false,
			editorState,
			serverSideCampaign,
			false,
		);
		const formWithId = updateVariation(serverSideCampaign);
		setServerSideCampaign(s => ({
			...s,
			...formWithId,
			Id: format.template.Key
		}));
		setEditorState(updateEditorState);
	};

	const handleFormatChosen = () => {
		if (templateSelected) {
			// handleChangeStep(EditorStep.Three);
			initializeFromSelectedFormat(templateSelected);
		}
	};

	function handleCloseEditor() {
		if (isAdmin && props.$routeParams.ka) {
			window.location.href = `${'ServerSide/Dashboard'}?ka=${props.$routeParams.ka}&ku=${props.$routeParams.ku}`;
		} else {
			window.location.href = window.location.href = `${'ServerSide/Dashboard'}`;
		}
	}

	const handleSaveCampaign = (campaign: ServerSideCampaignObject) => {
		const campaignToCheck = { ...campaign };
		campaignToCheck.Properties = [...modelProperties];
		if (!checkValidationErrors(campaignToCheck)) {
			return;
		}
		const onSuccess = () => {
			systemServices.showSuccess('Your campaign has been saved successfully');
			handleCloseEditor();
		};
		const onError = () => systemServices.showError('An error occured while saving your campaign');
		if (mode === EditorCreateOrUpdateMode.Create) {
			crud.createServerSideCampaign(convertServerSideCampaignForSaving(campaignToCheck), onSuccess, onError);
		} else {
			if (campaignToCheck.Id === null) {				
				console.log('Error, campaign id is missing');
				return onError();
			}
			crud.updateServerSideCampaign(campaignToCheck.Id, convertServerSideCampaignForUpdate(campaignToCheck), onSuccess, onError);
		}
	};


	// callbacks

	const applyUpdateProperties = (updateProperties: (p: TemplateProperty[]) => TemplateProperty[]) => {
		setServerSideCampaign(v => ({ ...v, Properties: updateProperties(v.Properties) }));
	};

	const handleChangeName = (name: string) => {
		setServerSideCampaign(v => ({ ...v, Name: name }));
	};

	const handleChangeDescription = (value: string) => {
		setServerSideCampaign(v => ({ ...v, Description: value }));
	};

	const context: ServerSideEditorContextType = {
		isLoading,
		serverSideCampaign: serverSideCampaign,
		saasFormats,
		editorState,
		formatSavedStyles,
		matchedExistingStyle,
		currentDevice,
		mode,
		setCurrentDevice,
		handleFormatPreviewClicked,
		handleFormatChosen,
		handleChangeStep,
		handleSaveCampaign,
		applyUpdateProperties,
		templateSelected,
		initializeFromSelectedFormat,
		handleChangeName,
		handleChangeDescription,
		handleCloseEditor,
		modelProperties,
		setModelProperties,
		sidebarIsOpen,
		setSidebarIsOpen
	};

	return (
		<ServerSideEditorContext.Provider
			value={context}
		>
			{props.children}
		</ServerSideEditorContext.Provider>
	);
};

export default CreateServerSideEditorContextProvider;

export { useServerSideEditorContext };

type ServerSideEditorContextType = {
	isLoading: boolean;
	serverSideCampaign: ServerSideCampaignObject;
	saasFormats: ServerSideSaaSFormat[];
	editorState: EditorState;
	formatSavedStyles: Style[];
	matchedExistingStyle: Style | null;
	currentDevice: DeviceType;
	mode: EditorCreateOrUpdateMode;
	handleFormatPreviewClicked: (format: ServerSideSaaSFormat) => void;
	handleFormatChosen: () => void;
	setCurrentDevice: Dispatch<SetStateAction<DeviceType>>;
	handleChangeStep: (step: EditorStep) => void;
	handleSaveCampaign: (tp: ServerSideCampaignObject) => void;
	applyUpdateProperties: (arg0: (propsToUpdate: TemplateProperty[]) => TemplateProperty[]) => void;
	templateSelected: any,
	initializeFromSelectedFormat: (any) => void;
	handleChangeName: (name: string) => void;
	handleChangeDescription: (value: string) => void;
	handleCloseEditor: () => void;
	modelProperties: TemplateProperty[];
	setModelProperties: Dispatch<SetStateAction<TemplateProperty[]>>;
	sidebarIsOpen: boolean,
	setSidebarIsOpen: (boolean) => void;
}
