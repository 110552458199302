import React,{useEffect,useState} from 'react';
import {useLocation} from 'react-router-dom';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import EmptyState from '../../../Components/EmptyState';
import Btn from '../../../Components/Btn';
import {useProductFeedConfigContext} from '../context/ProductFeedConfigContext';
import TenantFeed from './TenantFeed';

let urlFeedEdition = '/ProductFeed/Edit';
let urlNewFeed = '/ProductFeed/Edit';

export default function ProductFeedContainerList() {

	let location = useLocation();
	const {
		isLoading,
		listTenant,
		reorderedTenant,
		accountId} =
		useProductFeedConfigContext();

	const [listTenantCopy, setlistTenantCopy] = useState([]);

	useEffect(()=>{
		if(listTenant){
			setlistTenantCopy(listTenant);
		}
	},[listTenant]);

	return (
		<>
			{isLoading &&
				<div className='page_block'>
					<SpinnerWheel display='block' wheelSize='l' />
				</div>
			}

			{!isLoading && (!listTenant || listTenant.length == 0) && 
				<div className='page_block'>
					<EmptyState
						icon="fas fa-book"
						title="No product feed yet"
						text={<>Connect your product feed to supply the catalog, <br/>it is used for many features.</>}
						verticalSize="xl"
						textSize="l"
						primaryAction={
							<Btn
								href={`${urlNewFeed}${location.search ||`?id=${accountId}`}&tenant=${(listTenant && listTenant.length >= 1) ? listTenant[0].key : ''}`}
								message="New product feed"
							/>
						}
					/>
				</div>
			}
			{!isLoading && listTenantCopy && listTenantCopy.map(tenant =>
				<TenantFeed
					key={tenant.key}
					tenant={tenant}
					listTenant={listTenant}
					urlNewFeed={urlNewFeed}
					accountId={accountId}
					urlFeedEdition={urlFeedEdition}
					reorderedTenant={reorderedTenant}
				/>
			)}
		</>
	);
}
