import React from 'react';
import './ResizeBannerWorkflowInstancesItem.css';
import formatMoment from '../../../../../Constants/FormatMoment';

export default function ResizeBannerWorkflowInstancesItem({ fileName, id, status, creationDateUtc, modificationDateUtc, onCancelWorkflowRequested }) {
	const { formatDateToLocal } = formatMoment;
	let fileType = 'photoshop';
	if (fileName && fileName.indexOf('.ai') == fileName.length - 3) {
		fileType = 'illustrator';
	}

	let canCancel = false;
	if (status == 'creating' || status == 'created') {
		canCancel = true;
	}

	const modificationDateClass = ['grid-resize'];
	if (!modificationDateUtc) {
		//const creationDate = moment.utc(creationDateUtc).add(16, 'hours');
		//modificationDateUtc = creationDate;
		modificationDateClass.push('grey_3');
	}

	return (
		<div className="resize_row">
			<div className="grid-resize">
				<div className={'file_icon_42 file_icon_' + fileType}></div>
			</div>
			<div className="grid-resize">
				<p className="resize-title">{fileName}</p>
				<div className="resize-id grey_3">ID: {id}</div>
			</div>
			{/* possible values for status: creating / created / submitted / terminated / cancelling / cancelled */}
			<div className="grid-resize grid-resize-status">{status}</div>
			<div className="grid-resize">
				{formatDateToLocal(creationDateUtc).format('DD/MM/YYYY HH:mm')}
			</div>
			<div className={modificationDateClass.join(' ')}>
				{modificationDateUtc ?
					<>{formatDateToLocal(modificationDateUtc).format('DD/MM/YYYY HH:mm')}</>
					: '-'
				}
			</div>
			<div className="grid-resize">
				{canCancel &&
					<a className="icon_btn" title="Cancel request" onClick={() => onCancelWorkflowRequested()}>
						<i className="fas fa-times s_18"></i>
					</a>
				}
			</div>
		</div>
	);
}