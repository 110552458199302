

export default function Map(specs, getHeader) {
    let headers = {}
    let rows = []

    const addHeader = (h, colOrder, hText) => {
        if (!(h in headers)) {
            let header = getHeader(h, hText)
            header.colOrder = colOrder
            headers[h] = header
        }
    }

    for (let i = 0; i < specs.length; i++) {
        const v = specs[i]

        if (v.FriendlyIdentifier) addHeader("FriendlyIdentifier", "__1")
        if (v.Identifier) addHeader("Identifier", "__2")
        if (v.ActionText) addHeader("ActionText", "__3")
        if (v.ActionLink) addHeader("ActionLink", "__4")

        let row = {...v, Properties: v.Properties ? v.Properties : {}}
        row.id = i+1 

        for (let key in v.Properties) {
            if (v.Properties.hasOwnProperty(key)){
                var prop = v.Properties[key]
                if (prop !== undefined) addHeader("Properties."+key, key, key)
            }
        }

        rows.push(row)
    }
    return [Object.keys(headers).map(key => headers[key]).sort((a, b) => a.colOrder.localeCompare(b.colOrder)), rows]
}