const filterTypeList = [
    {
        type: "number",
        filter: [
            {
                value: "eq",
                label: "Equal to",
            },
            {
                value: "neq",
                label: "Not equal to",
            },
            {
                value: "in",
                label: "In",
            },
            {
                value: "nin",
                label: "Not In",
            },
            {
                value: "gt",
                label: "Greater Than",
            },
            {
                value: "ngt",
                label: "Not Greater Than",
            },
            {
                value: "gte",
                label: "Greater Than or equal to",
            },
            {
                value: "ngte",
                label: "Not Greater Than or equal to",
            },
            {
                value: "lt",
                label: "Less than",
            },
            {
                value: "nlt",
                label: "Not Less than",
            },
            {
                value: "lte",
                label: "Less than or equal to",
            },
            {
                value: "nlte",
                label: "Not Less than or equal to",
            },

        ]
    },
    {
        type: "string",
        filter: [
            {
                value: "eq",
                label: "Equal to",
            },
            {
                value: "neq",
                label: "Not equal to",
            },
            {
                value: "contains",
                label: "Contains",
            },
            {
                value: "ncontains",
                label: "Not contains",
            },
            {
                value: "startsWith",
                label: "Starts with",
            },
            {
                value: "nstartsWith",
                label: "Not Start With",
            },
            {
                value: "endsWith",
                label: "Ends with",
            },
            {
                value: "nstartsWith",
                label: "Not Ends With",
            },
            {
                value: "in",
                label: "In",
            },
            {
                value: "nin",
                label: "Not In",
            },
        ]
    },
    {
        type: "tags",
        filter: [
            {
                value: "value",
                label: "Text",
                type: "string",
            },
            {
                value: "dateValue",
                label: "Date",
                type: "date",
            },
            {
                value: "numValue",
                label: "Numeric",
                type: "number",
            },

        ]
    }

]
export default filterTypeList