import React, {useState,useContext,useEffect} from 'react';
import { reportingCampaignContext } from '../../Context/ContextReportingCampaign';
import SelectDropdown from '../../../../Components/SelectDropdown';

type ValueAttribution = {
    value: string,
    label : string
};

export default function SelectAttributionContainer({defaultList,defaultListInteractions}) {
	const [list, setlist] = useState<ValueAttribution[]>(defaultList);
	const {
		setobjectiveSelected2,
		objectiveSelected2,
		objectiveSelected,
		handleSaveObjectiveAndAttribution,
		targetSelected,
		setinteractionSelected
	} = useContext<any>(reportingCampaignContext);

	useEffect(()=>{
		setlist(defaultList);
	},[defaultList]);

	function handleSearchIndicator(evt) {

		setobjectiveSelected2(evt);
		if(targetSelected){
			handleSaveObjectiveAndAttribution(objectiveSelected,evt, targetSelected);
		}
		else if(evt === 'PostInteraction'){
			const el = defaultListInteractions[0];
			if(el){
				setinteractionSelected(el.value);
				handleSaveObjectiveAndAttribution(objectiveSelected,evt, el.value);
			}
		}
		else{
			handleSaveObjectiveAndAttribution(objectiveSelected,evt,null);
		}
		//load something
	}
	function onAutoSelect(val:string) {
		const newValue = list.find(el => el.value === val);
		handleSearchIndicator(newValue?.value);
	}
	return (
		<SelectDropdown
			label={'Attribution'}
			onChange={(v) => onAutoSelect(v)}
			labelPosition='inner'
			color='white'
			value={objectiveSelected2}
			optionsList={list}
		/>
	);
}
