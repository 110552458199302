import React from 'react';
import { react2angular } from 'react2angular';
import { BrowserRouter as Router, NavLink,Switch,Route } from 'react-router-dom';
import ProductFeedContainerList from './ProductFeedContainerList';
import ProductFeedConfigContextProvider from '../context/ProductFeedConfigContext';
import NavProductFeedConfig from './NavProductFeedConfig';
import listStyles from './ProductFeedList.module.css';
import RouterWrapper from '../RouterWrapper';

export default function ProductFeedConfigBridge(props) {

	return (
		<ProductFeedConfigContextProvider
			$http={props.$http}
			$timeout={props.$timeout}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
		>
			<RouterWrapper props={props}>
				<div className='page_full_inner'>
					<Router>
						<section className='section no_bottom_pad section_primary'>
							<div className='flex'>
								<div className='flex_item_full'>
									<div className='h1'>Feed configuration</div>
								</div>
								<div className='flex_item_fix ml_30'>
									<div className={listStyles.tabs_side_btn}>
										<NavProductFeedConfig />
									</div>
								</div>
							</div>
						</section>
						<section className='section'>
							<ProductFeedContainerList />
						</section>
					</Router>
				</div>
			</RouterWrapper>
		</ProductFeedConfigContextProvider>
	);
}
angular.module('beyableSaasApp.ProductFeedConfig', []).component('productfeedconfig', react2angular(ProductFeedConfigBridge, [], ['$http', 'RestServices', '$rootScope', '$scope', '$routeParams']));