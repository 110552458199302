import React, { useState, useEffect } from 'react';
import { BrowserRouter, Prompt, Switch, Route, useHistory } from 'react-router-dom';
import { react2angular } from 'react2angular';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import BusinessOperationServices from '../../../Services/BusinessOperationServices';
import UsersAdministrationServices from '../../../Services/UsersAdministrationServices';
import SystemServices from '../../../Services/SystemServices';
import './Dashboard.css';

import BusinessOperationCreateContainerWithContext from './../../BusinessOperations/Create/BusinessOperationCreateContainerWithContext';
import StatisticsContainer from './../Statistics/StatisticsContainer';
import BusinessOperationProvider from '../Context/ContextBusinessOperations';
import DashboardList from './DashboardList';
import bronze from '../../../Permissions/rules-bronze';
import gold from '../../../Permissions/rules-gold';
import silver from '../../../Permissions/rules-silver';

export default function Dashboard(props) {
	const [permissions, setPermissions] = useState(gold);
	const [userSimplifyRole, setUserSimplifyRole] = useState('simplifyAdmin');
	const [planAccount, setPlanAccount] = useState(gold);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const businessOperationServices = new BusinessOperationServices(props.$http, props.AuthServices);
	const usersAdministrationServices = new UsersAdministrationServices(props.$http, props.$routeParams);
	const systemServices = new SystemServices(props.$rootScope);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const isAdmin = impersonatingServices.isAnAdmin();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const getAllOperations = (accountId, callbackSuccess) => businessOperationServices.getAllOperations(accountId, callbackSuccess, (error) => systemServices.showError('an error occurred'));
	const getOperationByRangeDate = (accountId, startDate, endDate, callbackSuccess, callbackError) => businessOperationServices.getOperationByRangeDate(accountId, startDate, endDate, true, callbackSuccess, callbackError);
	const getOperationStatsCsvByRangeDate = (accountId, startDate, endDate, callbackSuccess, callbackError) => businessOperationServices.getOperationStatsCsvByRangeDate(accountId, startDate, endDate, callbackSuccess, callbackError);

	const putOperationStopped = (accountId, operationId, isStopped, callbackSuccess, callbackError) => businessOperationServices.putOperationStopped(accountId, operationId, isStopped, success => {
		systemServices.showSuccess('The status operation was changed with success'); callbackSuccess(success);
	}, (errorMsg, error) => { systemServices.showError('An error occured during process'); });

	const deleteOperationById = (accountId, operationId, callbackSuccess, callbackError) => businessOperationServices.deleteOperationById(accountId, operationId, success => { systemServices.showSuccess('The operation has been deleted with success'); callbackSuccess(success); }, error => systemServices.showError('Deleted failed'));

	const putOperationExecutionStatusModeWithTest = (accountId, operationId, isStopped, executionMode, callbackSuccess, callbackError) =>
		businessOperationServices.putOperationExecutionStatusModeWithTest(accountId, operationId, isStopped, executionMode,
			success => {
				callbackSuccess(success);
			},
			error => { systemServices.showError('Error during the edit of status mode'); callbackError(error); }
		);
	const executeStartCapture = (accountId, operationId, callbackSuccess, callbackError) => {
		businessOperationServices.executeStartCapture(accountId, operationId,
			success => { systemServices.showSuccess('Capture has been started with success'); callbackSuccess && callbackSuccess(success); },
			error => { systemServices.showError('Starting capture has failed'); callbackError && callbackError(error); }
		);
	};
	const loadOperationStatistics = (operationId, startDate, endDate, callbackSuccess, callbackError) =>
		businessOperationServices.getStatisticsByOperationId(accountId, operationId, startDate, endDate, null, callbackSuccess, callbackError);
	// useEffect(() => {
	// 	if (props.$rootScope) {
	// 		getAccountUsers(accountId, data => {
	// 			const rolesOfUser = data.find(user => user.id === props.$rootScope.User.Key);
	// 			if (rolesOfUser) {
	// 				if (props.$routeParams.ka) {
	// 					setPermissions({ plan: "bronze", roles: "simplifyAdmin" })
	// 				} else {
	// 					const simplifyRoles = rolesOfUser.roles.reduce((acc, curr) => {
	// 						const accToLowerCase = curr.toLowerCase()
	// 						if (accToLowerCase.includes('simplify')) {
	// 							acc.push(curr);
	// 						}
	// 						return acc
	// 					}, [])
	// 					setPermissions({ plan: "bronze", roles: simplifyRoles.toString() })
	// 				}
	// 			} else {
	// 				systemServices.showError('An error occured')
	// 			}
	// 		}, error => {
	// 			systemServices.showError('An error occured')
	// 			console.log("error", error);
	// 		})
	// 	}
	// }, [])

	// useEffect(() => {
	// 	if (permissions) {
	// 		setUserSimplifyRole(permissions.roles)
	// 		if (permissions.plan === "gold") {
	// 			setPlanAccount(gold)
	// 		}
	// 		if (permissions.plan === "bronze") {
	// 			setPlanAccount(bronze)
	// 		}
	// 		if (permissions.plan === "silver") {
	// 			setPlanAccount(silver)
	// 		}
	// 	}
	// }, [permissions])

	return (
		<BusinessOperationProvider
			$http={props.$http}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
			accountId={accountId}>
			<BrowserRouter>
				<Switch>
					<Route exact path="/BusinessOperations/Create?id=:id&ka=:ka&ku=:ku" component={BusinessOperationCreateContainerWithContext} />
					<Route path="/BusinessOperations/Create?id=" component={BusinessOperationCreateContainerWithContext} />
					<Route path="/BusinessOperations/statistics" component={StatisticsContainer} />
				</Switch>
				<DashboardList
					isAdmin={isAdmin}
					accountId={accountId}
					getAllOperations={getAllOperations}
					getOperationByRangeDate={getOperationByRangeDate}
					getOperationStatsCsvByRangeDate={getOperationStatsCsvByRangeDate}
					putOperationStopped={putOperationStopped}
					deleteOperationById={deleteOperationById}
					putOperationExecutionStatusModeWithTest={putOperationExecutionStatusModeWithTest}
					executeStartCapture={executeStartCapture}
					routeParams={props.$routeParams}
					$rootScope={props.$rootScope}
					permissions={planAccount}
					userSimplifyRole={userSimplifyRole}
					loadOperationStatistics={loadOperationStatistics}
				/>
			</BrowserRouter>
		</BusinessOperationProvider>
	);
}
angular.module('beyableSaasApp.BusinessOperationsDashboard', ['beyableSaasApp.Admin.Services'])
	.component('businessoperationdashboard', react2angular(Dashboard, [], ['$http', '$rootScope', '$routeParams', 'AuthServices']));
