import React, { } from 'react';
import './SideBar.css';
import { useServerSideEditorContext } from '../../context/ServerSideEditorContextProvider';
import PropertiesSelector from './PropertiesSelector';
import { EditorStep } from '../../context/EditorStateTypes';
import FormatSelector from '../FormatSelector/FormatSelector';
import { EditorCreateOrUpdateMode } from '../../context/EditorStateTypes';
export default function SideBarContainer() {
	const {
		editorState,
		mode,
		sidebarIsOpen
	} = useServerSideEditorContext();

	const sidebarClass = ['format_sidebar', 'flex_item_fix'];
	if (editorState.sideBarStep === EditorStep.One && editorState.formatIsSelected && !sidebarIsOpen) {
		sidebarClass.push('format_sidebar_closed');
	}

	return (
		<div className={sidebarClass.join(' ')}>
			<div className='format_sidebar_inner flex flex_v'>
				{mode === EditorCreateOrUpdateMode.Create && editorState.sideBarStep === EditorStep.One && !editorState.formatIsSelected &&
					<FormatSelector />
				}
				{editorState.sideBarStep === EditorStep.One && editorState.formatIsSelected &&
					<PropertiesSelector />
				}
			</div>
		</div>
	);
}
