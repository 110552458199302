import React, { useState } from 'react';
import { NavLink, Redirect  } from 'react-router-dom';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import Confirm from '../../../Components/Confirm';
import IconBtn from '../../../Components/IconBtn';
import listStyles from './ProductFeedList.module.css';
import {useProductFeedConfigContext} from '../context/ProductFeedConfigContext';
import ProductFeedCopyFeedModal from './ProductFeedCopyFeedModal';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import {
	getFeedTypePictoClass,
	getFeedTypeName
} from '../utils';
import commonStyles from '../ProductFeed.module.css';
import { v4 as uuidv4 } from 'uuid';
import Btn from '../../../Components/Btn';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function ProductFeedConfigurationModel({
	href,
	feed,
	tenant, index
}) {
	
	if(!feed){
		return <></>;
	}

	const pictoClass = getFeedTypePictoClass(feed?.type);
	const idTooltip = uuidv4();
	const {handleDeteFeed,listTenant,handleCopyFeed,handlePauseSource,handlePlaySourceFeed} = useProductFeedConfigContext();
	const [dropdownIsOpen, setdropdownIsOpen] = useState(false);
	const [showDelConfirm, setshowDelConfirm] = useState(false);
	const [showCopyTenant, setCopyTenant] = useState(false);
	const [showPauseTenant, setShowPauseTenant] = useState(false);
	function handleDeteFeedByid(){
		handleDeteFeed(feed);
	}
	function handleCopyFeedByid(tenantToCopy){
		handleCopyFeed(feed,tenantToCopy,tenant);
	}
	function getLastUpdateDate(date){
		if (date) {
			return moment(date).format('DD/MM/YYYY - hh:mm');
		}
	}
	function getLastEditorName(nameOfEditor){
		if(nameOfEditor && nameOfEditor != 'Unknown'){
			return nameOfEditor;
		}
	}
	function handlePauseSourceFeed(){
		handlePauseSource(feed,tenant);
	}
	function handlePlaySourceFeedFn(){
		handlePlaySourceFeed(feed, tenant);
	}
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: feed.id });

	const style = {
		transition,
		zIndex: isDragging ? '1000' : 'auto',
		backgroundColor: isDragging && 'var(--main-bg-color)',
		transform: transform ? 'translateY('+transform.y+'px)' : 'none'
	};
	
	return (
		
		<div 
			style={{ ...style , opacity : !feed.isActive && 0.6}}
			ref={setNodeRef}
			{...attributes}
			className={'table_row table_body_row ' + listStyles.grid_row}
		>
			<ReactTooltip
				id={idTooltip}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={600}
			/>
			<div className="table_col">
				<NavLink to={href} className={listStyles.grid_main_link}>
					<div className="flex">
						<div className='flex_item_fix'>
							<div className={listStyles.picto + ' ' + commonStyles.picto + ' ' + commonStyles['picto_' + pictoClass]}></div>
						</div>
						<div className='flex_item_auto'>
							<div className={listStyles.feed_name}>{getFeedTypeName(feed.type)}</div>
							{feed.resourceIdentifier &&
								<div className={listStyles.feed_url}>{feed.resourceIdentifier}</div>
							}
						</div>
					</div>
				</NavLink>
			</div>
			<div className="table_col flex_justify_end">
				{!feed.isActive && 
					<Btn
						message="Paused"
						icon="fas fa-pause-circle"
						style="ghost"
						color="secondary"
						horizontalSize="xxs"
						dataFor={idTooltip}
						dataTip={'Click to restart feed'}
						onClick={handlePlaySourceFeedFn}
					/>
				}
			</div>
			<div className="table_col">
				<div>
					<div>
						{feed.lastModificationTimeUtc ? getLastUpdateDate(feed.lastModificationTimeUtc) : '-'}
					</div>
					{feed.lastEditor &&
						<div className='grey_2 s_13 mt_2'>
							{getLastEditorName(feed.lastEditor)}
						</div>
					}
				</div>
			</div>
			<div className="table_col flex_justify_end">
				<IconBtn
					tooltip="Sort feeds by dragging them"
					icon='fas fa-sort'
					theme="dark"
					size="l"
					{...listeners}
				/>
				<IconBtn
					tooltip="Edit feed"
					icon='fas fa-pen'
					theme="dark"
					size="l"
					href={href}
					isNavLink={true}
				/>
				<Dropdown
					isOpen={dropdownIsOpen}
					toggle={(s, e) => setdropdownIsOpen(s)}
					button={
						<IconBtn
							tooltip="More actions"
							icon='fas fa-ellipsis-v'
							theme="dark"
							size="l"
						/>
					}
				>
					<Listbox>
						{feed.isActive &&
							<ListboxItem
								icon='fa fa-pause'
								message='Pause feed'
								onClick={() => {
									setShowPauseTenant(true);
									setdropdownIsOpen(false); 
								}}
							/>
						}
						{!feed.isActive &&
							<ListboxItem
								icon='fa fa-play'
								message='Restart feed'
								onClick={() => {
									handlePlaySourceFeedFn();
									setdropdownIsOpen(false); 
								}}
							/>
						}
						{listTenant && listTenant.length > 0 &&
							<ListboxItem
								icon='fas fas fa-copy'
								message='Copy to another tenant'
								onClick={() => {
									setCopyTenant(true);
									setdropdownIsOpen(false); 
								}}
							/>
						}
						<li className='hr' />
						<ListboxItem
							icon='fas fa-trash'
							message='Delete feed'
							onClick={() => {
								setshowDelConfirm(true);
								setdropdownIsOpen(false); 
							}}
						/>
					</Listbox>
				</Dropdown>
				<Confirm
					title="Do you really want to delete this feed?"
					text="This action is irreversible"
					confirmText="Delete"
					confirmColor="alert"
					cancelText="Cancel"
					isOpen={showDelConfirm}
					confirmCallback={handleDeteFeedByid}
					onClose={() => setshowDelConfirm(false)}
				/>
				<Confirm
					title={<>Do you really want to pause <br/>this feed?</>}
					confirmText="Confirm"
					confirmColor="alert"
					cancelText="Cancel"
					isOpen={showPauseTenant}
					confirmCallback={handlePauseSourceFeed}
					onClose={() => setShowPauseTenant(false)}
				/>
				<ProductFeedCopyFeedModal
					modalIsOppen={showCopyTenant}
					setModalIsOppen={setCopyTenant}
					handleCopyFeedByid={handleCopyFeedByid}
					tenant={tenant}
				/>

			</div>
		</div>
	);
}
