import React from 'react';
import { FindingType, PeriodType, DimensionsType } from '../context/AiInsightsApiTypesServices';
import { engagementLevels } from '../utils';

type Locale = 'en-EN' | 'fr-FR';

export default function SentenceGenerator({ insight }) {
	const { translatedFindingName, findingType, period, dimensions } = insight;
	const locale =  getLocale();
	const periodDescription = mapPeriod(period, locale);
	const context = buildContext(dimensions, locale);

	let sentence = '';

	switch (findingType) {
		case 'BigUplift':
		case 'SmallUplift':
		case 'BigFall':
		case 'SmallFall': {
			const { variationName, variationIntensity } = getVariationDetails(findingType, locale);
			sentence = `${translatedFindingName} ${context} ${variationName} ${variationIntensity} ${locale === 'fr-FR' ? 'sur' : 'in'} ${periodDescription}.`;
			break;
		}
		case 'ProductPositiveSpotlight':
			sentence = `${translatedFindingName} ${locale === 'fr-FR' ? 'a une performance positive' : 'has a positive performance'} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
		case 'ProductNegativeSpotlight':
			sentence = `${translatedFindingName} ${locale === 'fr-FR' ? 'a une performance négative' : 'has a negative performance'} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
		case 'PositiveBusinessComparison':
			sentence = `${translatedFindingName} ${locale === 'fr-FR' ? 'est meilleur que le marché' : 'is better than the market'} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
		case 'NegativeBusinessComparison':
			sentence = `${translatedFindingName} ${locale === 'fr-FR' ? 'est moins bon que le marché' : 'is worse than the market'} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
		default:
			sentence = `${translatedFindingName} ${context} ${locale === 'fr-FR' ? 'sur' : 'over'} ${periodDescription}.`;
			break;
	}

	return <>{sentence}</>;
}

function getLocale(): Locale {
	const localeString = localStorage.getItem('i18nL');
	if (localeString && localeString.startsWith('fr-FR')) {
		return 'fr-FR';
	} else {
		return 'en-EN';
	}
}

function mapPeriod(period: PeriodType, locale: Locale): string {
	const periods = {
		'en-EN': {
			LastDay: 'the last 24 hours',
			Last7Days: 'the last 7 days',
			Last30Days: 'the last 30 days',
			Last365Days: 'the last year',
		},
		'fr-FR': {
			LastDay: 'ces dernières 24 heures',
			Last7Days: 'ces 7 derniers jours',
			Last30Days: 'ces 30 derniers jours',
			Last365Days: 'cette dernière année',
		},
	};

	return periods[locale][period] || '';
}

function getVariationDetails(findingType: FindingType, locale: Locale) {
	const variations = {
		'en-EN': {
			BigUplift: { variationName: 'has increased', variationIntensity: 'significantly' },
			SmallUplift: { variationName: 'has increased', variationIntensity: '' },
			BigFall: { variationName: 'has decreased', variationIntensity: 'significantly' },
			SmallFall: { variationName: 'has decreased', variationIntensity: '' },
		},
		'fr-FR': {
			BigUplift: { variationName: 'est en hausse', variationIntensity: 'importante' },
			SmallUplift: { variationName: 'est en hausse', variationIntensity: '' },
			BigFall: { variationName: 'est en baisse', variationIntensity: 'importante' },
			SmallFall: { variationName: 'est en baisse', variationIntensity: '' },
		},
	};

	return variations[locale][findingType] || { variationName: '', variationIntensity: '' };
}

function buildContext(dimensions: DimensionsType, locale: Locale): string {
	const dimensionPhrases = Object.entries(dimensions).map(([key, value]) =>
		mapDimension(key, value, locale)
	);
	return dimensionPhrases.length > 0 ? dimensionPhrases.join(', ') : '';
}

function mapDimension(key,value,locale): string {
	switch (key) {
		case 'TrafficSource':
			return locale === 'fr-FR' ? `pour la source de trafic ${value}` : `for traffic source ${value}`;
		case 'Device':
			return locale === 'fr-FR' ? `sur les appareils ${value}` : `on ${value} devices`;
		case 'VisitorEngagement': {
			const engagementLabel =
                engagementLevels.find((level) => level.value === value)?.label || value;
			return locale === 'fr-FR'
				? `avec l'engagement visiteur ${engagementLabel}`
				: `with visitor engagement ${engagementLabel}`;
		}
		case 'Tenant':
			return locale === 'fr-FR' ? `pour le tenant ${value}` : `for tenant ${value}`;
		default:
			return `${key} ${value}`;
	}
}
