
function viewedProducts(rootscope) {

    const btn_hide = {
        classes: 'btnUi shepherd-button-hide',
        text: 'Hide',
        action() {
            rootscope.$broadcast("event:campaignPauseTour", true);
            this.hide();
        }
    };
    const btn_back = {
        classes: 'btnUi shepherd-button-back',
        text: 'Back',
        type: 'back'
    };
    const btn_next = {
        classes: 'btnUi shepherd-button-next',
        text: 'Next',
        type: 'next'
    };

    return {
        key: "viewedProducts",
        id: 'viewedProducts',
        available: false,
        label: 'Get quick access on viewed products',
        formatLabel: 'Footer-bar',
        formatImg: 'illu_footer_history.svg',
        description: 'In a viewable footer-bar on desktop facilitate the access to all products that have been seen.',
        steps: [
            {
                id: 'shepherd-target-campaign-title',
                attachTo: {
                    element: '.shepherd-target-campaign-title',
                    on: 'top'
                },
                buttons: [
                    btn_hide,
                    btn_next
                ],
                text: ['<p>Give an easy recognizable <strong>TITLE</strong> on your campaign, and put some details on what is it about on the <strong>DESCRIPTION</strong> below.</p><p>It will help you to easier find it among your other campaigns, listed in your general dashboard.</p>'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "shepherd-target-campaign-title"
                    }
                }
            },
            {
                id: 'campaign_from_to',
                attachTo: {
                    element: '.campaign_from_to',
                    on: 'top'
                },
                tetherOptions: {
                    target: '.inner'
                },
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next
                ],
                text: ['Then, select the period when you want your campaign to be activated, and click on "apply" to confirm. (UTC +2). You can modify it later.'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "campaign_from_to"
                    },
                    hide: () => {
                    }
                }
            },
            {
                id: 'devicesChoice',
                attachTo: {
                    element: ".shepherd-target-campaign-device",
                    on: "top"
                },
                scrollTo: true,
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next,
                ],
                text: ['<p><strong>Select the device on which you want to activate your campaign.</strong></p><p>In the beginning, we recommand you to click on "desktop" only.</p><p>When you will be familiar with the platform, you will understand how to adapt the graphic design in order to be responsive so you can click on the 3 options if you want.</p>'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "devicesChoice"
                    }
                }
            },
            {
                id: 'shepherd-target-campaign-control',
                attachTo: {
                    element: ".shepherd-target-campaign-control",
                    on: "top"
                },
                scrollTo: true,
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next,
                ],
                title: ['OPTIONAL'],
                text: ['<p>Like an AB test, this option means that we will split the target audience in 2 groups : one will see your campaign, the other not. In your reporting dashboard, you will be able to see the impact of your campaign by comparing the result of these 2 groups (KPI : transaction and average basket value)</p><p>If you choose this option, we recommand you to start with a 50/50 distribution. </p>'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "shepherd-target-campaign-control"
                    }
                },
                classes: 'shepherd-element-optional'
            },
            // {
            //     id: 'shepherd-target-campaign-retargeting',
            //     attachTo: {
            //         element: ".shepherd-target-campaign-retargeting",
            //         on: "top"
            //     },
            //     scrollTo: true,
            //     buttons: [
            //         btn_hide,
            //         btn_back,
            //         btn_next,
            //     ],
            //     text: ['<p>With this option, you can decide to block your campaign for users who already have seen it once. This can help you to manage the marketing pressure on all of your users.</p><p>This option on ZERO means it will appears on every time the user match with the targeting rules.</p>'],
            //     when: {
            //         show: () => {
            //             rootscope.shepherdKey = "shepherd-target-campaign-retargeting"
            //         }
            //     }
            // },
            {
                id: 'shepherd-target-campaign-next',
                attachTo: {
                    element: ".shepherd-target-campaign-next",
                    on: "top"
                },
                scrollTo: true,
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next
                ],
                title: ['Two more steps and you\'re done !'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "shepherd-target-campaign-next";
                        rootscope.$broadcast("event:campaignStepChange", 0);
                    }
                }
            },
            {
                id: 'new_triggers_list_wrapper',
                attachTo: {
                    element: ".new_triggers_list_wrapper",
                    on: "right"
                },
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next,
                ],
                text: ['<p>Then, we suggest you to select these 2 conditions (find it easily with the searching bar) : </p><ul><li>- Number of visits</li><li>- Page type</li></ul>'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "new_triggers_list_wrapper";
                        rootscope.$broadcast("event:campaignStepChange", 1);
                    }
                }
            },
            {
                id: 'triggerConditionsList',
                attachTo: {
                    element: ".triggerConditionsList",
                    on: "right"
                },
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next
                ],
                text: ['<p>Then, we suggest you this configuration : </p><ul><li>- Number of visits "equal to" 1</li><li>- Page type "equal to" Home; Category; Product</li></ul><p>That means you will show your message on specific page type (homepage, products lists and product pages) only to users who\'s coming for the first time.</p>'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "triggerConditionsList";
                    }
                }
            },
            {
                id: 'url_debug_wrapper',
                attachTo: {
                    element: ".url_debug_wrapper",
                    on: "bottom"
                },
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next
                ],
                title: ['Tips !'],
                text: ['<p>To preview your campaign on your website, enter here the url on wich page you want to see it.</p><p>After saving this campaign, we will transform this url in a preview link, available from your general dashboard.</p>'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "url_debug_wrapper";
                    }
                }
            },
            {
                id: 'shepherd-target-trigger-next',
                attachTo: {
                    element: ".shepherd-target-trigger-next",
                    on: "top"
                },
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next
                ],
                title: ['Great ! Now let\'s finish with the final step !'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "shepherd-target-trigger-next";
                        rootscope.$broadcast("event:campaignStepChange", 1);
                    }
                }
            },
            {
                id: 'shepherd-target-nav-customize',
                attachTo: {
                    element: ".shepherd-target-nav-customize",
                    on: "bottom"
                },
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next
                ],
                title: 'Almost done',
                text: ['On this section, you can edit your message and see how it will look like.'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "shepherd-target-nav-customize";
                        rootscope.$broadcast("event:campaignStepChange", 2);
                    }
                }
            },
            {
                id: 'modelFormatList',
                attachTo: {
                    element: ".modelFormatList",
                    on: "bottom"
                },
                buttons: [
                    btn_hide,
                    btn_back,
                    {
                        classes: 'btnUi shepherd-button-next',
                        text: 'Next',
                        action() {
                            if (rootscope.shepherdCurrentSlide.Format == 2) {
                                this.next()
                            }
                        }
                    },
                ],
                text: ['<p>Follow these 3 steps :</p><p><strong>TO DO : step 1</strong> First, select the <strong>Headerbar</strong></p><p>Remember : you are about to create a campaign that gives promotion code to users who are coming for the first time</p>'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "modelFormatList";
                    }
                }
            },
            {
                id: 'chooseTemplateType',
                attachTo: {
                    element: ".chooseTemplateType",
                    on: "bottom"
                },
                buttons: [
                    btn_hide,
                    btn_back,
                    {
                        classes: 'btnUi shepherd-button-next',
                        text: 'Next',
                        action() {
                            if (rootscope.shepherdCurrentSlide.TemplateName == "HB_G_0001") {
                                this.next()
                            }
                        }
                    }
                ],
                text: ['<p><strong>TO DO : step 2</strong></p><p>Select the <strong>Headerbar with text</strong></p><p>Tips : if you need a more custom headerbar as a template, we can make it for you! Ask your CSM for more details</p>'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "chooseTemplateType"
                    }
                }
            },
            {
                id: 'shepherd-target-content',
                attachTo: {
                    element: ".shepherd-target-content",
                    on: "bottom"
                },
                popperOptions: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 50],
                            }
                        }
                    ]
                },
                modalOverlayOpeningPadding: 10000,
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next
                ],
                title: 'Final step !',
                text: ['<p><strong>TO DO : step 3</strong></p>Click on the arrows below to edit your message, and select the content option “PROMO CODE” to add this functionality'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "shepherd-target-content"
                    }
                }
            },
            {
                id: 'intro_content_device',
                attachTo: {
                    element: ".code_edition_actions .custom_devices",
                    on: "top"
                },
                modalOverlayOpeningPadding: 10000,
                buttons: [
                    btn_hide,
                    btn_back,
                    btn_next
                ],
                text: ['If needed, click on those button to edit and adapt the content acording to the target device.'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "intro_content_device"
                    }
                }
            },
            {
                id: 'btnSaveAndQuit',
                attachTo: {
                    element: ".btnSaveAndQuit",
                    on: "bottom"
                },
                buttons: [
                    btn_hide,
                    btn_back,
                    {
                        classes: 'btnUi shepherd-button-next',
                        text: 'Next',
                        action() {
                            var el = document.querySelector('.btnSaveAndQuit');
                            el.click();
                        }
                    }
                ],
                advanceOn: {
                    selector: '.btnSaveAndQuit',
                    event: 'click'
                },
                title: ['When it\'s good for you, click here !'],
                text: ['Don\'t worry, you\'ll be able to test it before launching it for real.'],
                when: {
                    show: () => {
                        rootscope.shepherdKey = "btnSaveAndQuit"
                    }
                }
            },
        ],
    }
};
export default viewedProducts;