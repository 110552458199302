
const campaignListParameters = [
    {
        key: 'CONFIGALERT',
        label: 'Stock alert',
        compare: 'hasStockAlert',
        id: 4
    },
    {
        key: 'CONFIGEMR',
        label: 'Email my basket',
        compare: 'hasSendVisitorCart',
        id: 3
    },
    {
        key: 'CONFIGSCP',
        label: 'Sending promo code by email',
        compare: 'hasSendPromoCode',
        id: 2
    },

]
export default campaignListParameters;