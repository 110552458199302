import React from "react";
import formatMoment from "../../../Constants/FormatMoment";
import useStatusOperationReport from "../../../Hooks/useStatusOperationReporting";

export default function InformationCampaign(props) {

  const { formatDateToLocal } = formatMoment;
  let data = props.datainformation && props.datainformation
  let startDate = data && formatDateToLocal(data.startDate).format('DD/MM/YYYY');
  let endDate = data && data.endDate && formatDateToLocal(data.endDate).format('DD/MM/YYYY');
  let statusOperation = useStatusOperationReport(data);

  
  return (
    <div className="flex flex_align_start">
      <div className="flex_item_full">
        <div className="reporting_header">
          {data && data.name &&
            <div>
              <div className="reporting_header_title">
                <span className={'status_bullet ' + statusOperation.cssClass} title={statusOperation.id}></span>
                {data.name}
              </div>
              <div className="reporting_header_date">
                <div>
                  {startDate}
                  <i className="fas fa-arrow-right"></i>
                  {endDate ? endDate :  "(no end date)"}
                </div>
              </div>
              {data.description &&
                <div className="reporting_header_desc">
                  {data.description}
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}
