import React, { Component } from 'react';
import { react2angular } from 'react2angular';
import { BrowserRouter, NavLink } from 'react-router-dom';
import VisitorEngagementSignals from './VisitorEngagementSignalsComponent';
import VisitorEngagementProfiles from './VisitorEngagementProfilesComponent';
import VisitorEngagementRightPane from '../VisitorEngagementCommon/VisitorEngagementRightPaneContainer';
import VisitorEngagementServices from '../../Services/VisitorEngagementServices';
import styles from './VisitorEngagementContainer.module.css';
import SystemServices from '../../Services/SystemServices';
import TinyLoader from '../../Components/TinyLoader';
import EngagementLevelEnum from './EngagementLevelEnum';
import EngagementCalculatorType from './EngagementCalculatorType';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import InAppMenu from '../../Components/InAppMenu';

export default class VisitorEngagementContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSignals: [],
			signalsInUse: [],
			calculator: null,
			currentConsiderationProfiles: [],
			currentIntentionProfiles: [],
			profilesStats: [],
			showSignalPanel: false,
			signalToEdit: null,
			signalsLoading: false,
			profilesLoading: false,
			profilesStatsLoading: false,
			canEdit: false,
			canViewProfiles: false,
			deletedProfileIds: [],
			modifiedProfileIds: [],
			isLoading: false,
		};
		this.visitorEngagementServices = new VisitorEngagementServices(this.props.$http);
		this.systemServices = new SystemServices(this.props.$rootScope);
		this.impersonatingServices = new ImpersonatingServices(this.props.$rootScope, this.props.$routeParams);
		this.impersonatedAccount = this.impersonatingServices.getImpersonatedAccount();
		this.currentAccount = this.impersonatedAccount || this.props.$rootScope.User.Account.Key;
		this.currentUser = this.props.$rootScope.User.Key;
		this.isImpersonating = !!this.impersonatedAccount;

		this.onProfilesChange = this.onProfilesChange.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onSave = this.onSave.bind(this);

		this.urlReporting = '/VisitorEngagementReports/Home';
		this.urlSettings = '/VisitorEngagement/Home';
		if (this.props.$routeParams && this.props.$routeParams.ka) {
			this.urlReporting += '?ka=' + this.props.$routeParams.ka;
			this.urlReporting += '&ku=' + this.props.$routeParams.ku;
			this.urlSettings += '?ka=' + this.props.$routeParams.ka;
			this.urlSettings += '&ku=' + this.props.$routeParams.ku;
		}

		this.permissions = this.props.$rootScope.User.Permissions;
		if (this.impersonatingServices.isAnAdmin()) {
			this.canViewReporting = true;
			this.canViewSettings = true;
		} else {
			this.canViewReporting = this.permissions.some(function (item) {
				if (item.Name !== 'VISITOR_ENGAGEMENT_REPORT_CAN_VIEW') return false;
				if (item.Value !== true) return false;
				return true;
			});
			this.canViewSettings = this.permissions.some(function (item) {
				if (item.Name !== 'VISITOR_ENGAGEMENT_REPORT_CAN_SET') return false;
				if (item.Value !== true) return false;
				return true;
			});
		}
	}

	componentDidMount() {
		this.refreshSignals();
		this.refreshProfiles();

		const refreshStatsEnabled = false; // to enable when fixed
		refreshStatsEnabled && this.refreshStats();
	}

	refreshSignals() {
		this.setState({ signalsLoading: true });
		this.visitorEngagementServices.getSignals(this.currentAccount, signals => {
			this.setState({
				currentSignals: signals,
				signalsLoading: false
			});
		}, errorMessage => {
			this.systemServices.showError(errorMessage);
			this.setState({ signalsLoading: false });
		});
	}

	showAddSignal() {
		this.setState({ showSignalPanel: true });
	}

	showEditSignal(signal) {
		if (!signal.isPredefined) {
			this.setState({ showSignalPanel: true });
			this.setState({ signalToEdit: signal });
		} else {
			console.warn('Can not modify predefined signals');
		}
	}

	hideAndResetSignalPanel() {
		this.setState({ showSignalPanel: false });
		this.setState({ signalToEdit: null });
	}

	createOrUpdateSignal(signal) {
		this.visitorEngagementServices.createOrUpdateSignal(this.currentAccount,
			signal,
			success => {
				if (signal.id) {
					this.systemServices.showSuccess('Modification réussie');
				} else {
					this.systemServices.showSuccess('Création réussie');
				}
				this.hideAndResetSignalPanel();
				this.refreshSignals();
			},
			(errorMessage, error) => {
				if (error.status == 409) {
					this.systemServices.showWarning('Le signal ne peut pas être mis à jour car il est utilisé.');
				} else {
					this.systemServices.showError(error);
				}
			});
	}

	deleteSignal(signalId) {
		this.visitorEngagementServices.deleteSignal(this.currentAccount,
			signalId,
			success => {
				this.systemServices.showSuccess('successful deletion');
				this.hideAndResetSignalPanel();
				this.refreshSignals();
			},
			(errorMessage, error) => {
				if (error.status == 409) {
					this.systemServices.showWarning('Le signal ne peut pas être supprimé car il est utilisé.');
				} else {
					this.systemServices.showError(error);
				}
			});
	}

	refreshProfiles() {
		this.setState({ profilesLoading: true });
		this.visitorEngagementServices.getDefaultCalculator(this.currentAccount, calculator => {

			// TODO: use permissions instead
			let canViewProfiles = this.impersonatingServices.isAnAdmin() || calculator.CalculatorType === EngagementCalculatorType.ManualProfileBased;
			let canEdit = this.impersonatingServices.isAnAdmin() || calculator.CalculatorType === EngagementCalculatorType.ManualProfileBased;

			let displayedProfiles = canViewProfiles ? calculator.profiles : [];
			this.setState({
				calculator: calculator,
				currentConsiderationProfiles: displayedProfiles.filter(p => p.level == EngagementLevelEnum.Consideration),
				currentIntentionProfiles: displayedProfiles.filter(p => p.level == EngagementLevelEnum.Intention),
				profilesLoading: false,
				signalsInUse: calculator.profiles.flatMap(x => x.signals.map(x => x.signalId)),
				canEdit: canEdit,
				canViewProfiles: canViewProfiles
			});
		}, errorMessage => {
			this.systemServices.showError(errorMessage);
			this.setState({ profilesLoading: false });
		});
	}

	refreshStats() {
		this.setState({ profilesStatsLoading: true });
		this.visitorEngagementServices.getProfilesStatistics(this.currentAccount, stats => {
			this.setState({
				profilesStats: stats,
				profilesStatsLoading: false
			});
		}, errorMessage => {
			this.systemServices.showError(errorMessage);
			this.setState({ profilesStatsLoading: false });
		});
	}

	onProfilesChange(level, profiles, changeDetails) {
		if (changeDetails.type === 'change') {
			this.addUnique(this.state.modifiedProfileIds, changeDetails.profileId);
		} else if (changeDetails.type === 'delete') {
			this.addUnique(this.state.deletedProfileIds, changeDetails.profileId);
		}
		if (level == EngagementLevelEnum.Consideration) {
			this.setState({
				currentConsiderationProfiles: profiles
			});
		} else if (level == EngagementLevelEnum.Intention) {
			this.setState({
				currentIntentionProfiles: profiles
			});
		}
	}

	onCancel() {
		this.systemServices.showSuccess('Canceled changes');
		this.refreshProfiles();
	}

	onSave() {

		this.setState({ isLoading: true });
		let allProfiles = this.state.currentConsiderationProfiles.concat(this.state.currentIntentionProfiles);
		let newProfiles = allProfiles
			.filter(p => this.startsWith(p.id, '__temp__'))
			.map(p => {
				p = { ...p };
				delete p.id; return p;
			}); // make a copy with no id

		let modifiedProfiles = allProfiles.filter(p => this.state.modifiedProfileIds.includes(p.id) &&
			!this.state.deletedProfileIds.includes(p.id) // exclude deleted profiles
			&&
			!this.startsWith(p.id, '__temp__')); // exclude new profiles

		let deletedProfileIds = this.state.deletedProfileIds.filter(id => !this.startsWith(id, '__temp__'));

		this.setState({ profilesLoading: true });
		this.visitorEngagementServices.updateCalculatorProfiles(
			this.currentAccount,
			this.state.calculator.id,
			newProfiles,
			modifiedProfiles,
			deletedProfileIds,
			() => {
				this.systemServices.showSuccess('Sauvegarde réussie');
				setTimeout(() => {
					this.setState({
						profilesLoading: false,
						modifiedProfileIds: [],
						deletedProfileIds: [],
						isLoading: false
					});
				}, 800);
				this.refreshProfiles();
			},
			errorMessage => {
				this.systemServices.showError(errorMessage);
				this.setState({ profilesLoading: false, isLoading: false });
			});
	}

	startsWith(string, prefix) {
		return string.substr(0, prefix.length) === prefix;
	}

	addUnique(array, item) {
		if (!array.includes(item)) array.push(item);
	}
	render() {
		return <div className={styles.content}>
			<div className={styles.intro}>
				<h1 className={styles.title}>Scoring engagement</h1>
				<div className={styles.firstLine}></div>
				<div></div>
			</div>

			<div>
				<BrowserRouter>
					<ul className="tabs mb_30">
						{this.canViewReporting &&
							<li>
								<NavLink to={this.urlReporting}>
									Reporting
								</NavLink>
							</li>
						}
						{this.canViewSettings &&
							<li>
								<NavLink to={this.urlSettings}>
									Settings
								</NavLink>
							</li>
						}
					</ul>
				</BrowserRouter>
			</div>

			<div className={styles.signalsShell}>
				{this.state.signalsLoading ?
					<TinyLoader /> :
					<VisitorEngagementSignals
						currentSignals={this.state.currentSignals}
						signalsInUse={this.state.signalsInUse}
						showAddSignal={this.showAddSignal.bind(this)}
						showEditSignal={this.showEditSignal.bind(this)} />}
			</div>
			{this.state.canViewProfiles
				? <div className={styles.profilContainer}>
					<VisitorEngagementProfiles
						level={EngagementLevelEnum.Consideration}
						profiles={this.state.currentConsiderationProfiles}
						profilesStats={this.state.profilesStats}
						signals={this.state.currentSignals}
						profileLabel={<span><strong>Consideration</strong> stage</span>}
						onChange={this.onProfilesChange}
						canEdit={this.state.canEdit} />
					<VisitorEngagementProfiles
						level={EngagementLevelEnum.Intention}
						profiles={this.state.currentIntentionProfiles}
						profilesStats={this.state.profilesStats}
						signals={this.state.currentSignals}
						profileLabel={<span><strong>Intention</strong> stage</span>}
						onChange={this.onProfilesChange}
						canEdit={this.state.canEdit} />
				</div>
				: ''}
			{this.state.canEdit
				? <div className={styles.actionButtons}>
					<button className={styles.cancelButton} onClick={this.onCancel}>Cancel</button>
					{!this.state.isLoading && <button className={styles.saveButton} onClick={this.onSave}>Save</button>}
					{this.state.isLoading && <button className={styles.saveButtonDisable}>Save</button>}
				</div> : ''
			}
			<VisitorEngagementRightPane
				title={this.state.signalToEdit ? 'Edit a signal' : 'Add a signal'}
				description={''}
				intro={''}
				actionLabel={this.state.signalToEdit ? 'Edit' : 'Create'}
				showSignalPanel={this.state.showSignalPanel}
				signalToEdit={this.state.signalToEdit}
				createOrUpdateSignal={this.createOrUpdateSignal.bind(this)}
				deleteSignal={this.deleteSignal.bind(this)}
				hideAndResetPanel={this.hideAndResetSignalPanel.bind(this)} />


		</div>;
	}
}

angular.module('beyableSaasApp.VisitorEngagement', ['beyableSaasApp.Services'])
	.component('visitor', react2angular(VisitorEngagementContainer, [], ['$http', 'RestServices', '$rootScope', '$scope', '$routeParams']));