import React, { useState, useEffect } from 'react';
import formatMoment from '../Constants/FormatMoment';
export default function useCampaignStatus(campaign, reportingMode = false) {
	if( !campaign ) return {};
	const i18nL= localStorage.getItem('i18nL');

	const { formatDateToLocal } = formatMoment;
	const now = moment();
	const startDate = getStartDate();
	const endDate = getEndDate();
	const isTestingMode = getIsTestingMode();

	function getStartDate(){
		const date = campaign.startDate || campaign.StartDateUtc;
		return formatDateToLocal(date);
	}

	function getEndDate(){
		const date = campaign.endDate || campaign.EndDateUtc;
		return formatDateToLocal(date);
	}

	function getIsTestingMode(){
		if(reportingMode){
			if(campaign.isTestMode){
				return true;
			}else{
				return false;
			}
		}
		if (campaign.executionMode && campaign.executionMode.toLowerCase() == 'test') {
			return true;
		}
		if (campaign.ExecutionMode && campaign.ExecutionMode.toLowerCase() == 'test') {
			return true;
		}

		return false;
	}

	function getStatusKey(){
		if( campaign.isStopped || campaign.IsStopped ){
			return 'stopped';
		}
		if( isTestingMode ){
			return 'testing';
		}
		if( endDate < now ){
			return 'past';
		}
		if( startDate > now ){
			return 'planned';
		}
		return 'ongoing';
	}

	const status = {};
	status.Key = getStatusKey();
	const fr = {
		'stopped' : 'Arrêtée',
		'testing' : 'Test',
		'past' : 'Passé',
		'planned' :'Programmée',
		'ongoing' :'En cours'
	};

	const en = {
		'stopped' : 'Stopped',
		'testing' : 'Draft',
		'past' : 'Past',
		'planned' :'Scheduled',
		'ongoing' :'Ongoing'
	};

	const keyLabel = i18nL.includes('fr') ? fr : en;
	
	switch (status.Key) {
		case 'stopped':
			status.Label = keyLabel[status.Key];
			status.Parent = status.Key;
			break;
		case 'testing':
			status.Label =  keyLabel[status.Key];
			status.Parent = status.Key;
			break;
		case 'past':
			status.Label =  keyLabel[status.Key];
			status.Parent = 'started';
			break;
		case 'planned':
			status.Label =  keyLabel[status.Key];
			status.Parent = 'started';
			break;
		case 'ongoing':
			status.Label =  keyLabel[status.Key];
			status.Parent = 'started';
			break;
	}

	return status;
}
