import React, { useState, useEffect } from "react"
import Insight from "../Insight/Insight";
import { useAnalyticsContext } from "../../context/AnalyticsContextProvider";
import { toReadableDuration } from "../../util";
import { usePageContext } from "../../context/PageContext";

export default function PageTotalsContainer({ pageService }) {

	const { selectedTenant } = useAnalyticsContext();
	const {
		navFilter,
		filterUrl,
		reportTypeSelected,
		searchValue,
		sourceType,
		utmDimensionsSelected,
		needRefreshForUtmFilter,
		deviceSelected,
		excludeFilter,
		selectedCustomTag
	} = usePageContext();

	const [totals, setTotals] = useState({})

	const refreshTotals = () => {
		setTotals({
			uniqueViews: { loading: true, isError: false },
			views: { loading: true, isError: false },
			averageTimeOnPage: { loading: true, isError: false },
			bounceRate: { loading: true, isError: false }
		});
		if(!selectedCustomTag){
			pageService.getPageMetricsSumsOverTime(deviceSelected,selectedTenant, navFilter, reportTypeSelected, searchValue, filterUrl, sourceType,utmDimensionsSelected,excludeFilter,
				data => {
					const { UniqueViews, Views, AverageTime, BounceRate } = data
					const [vDuration, sign] = AverageTime ? toReadableDuration(AverageTime) : [undefined, null]
					const insightData = {
						uniqueViews: {
							value: UniqueViews,
							tendance: null,
							loading: false,
							isError: false,
							sign: null
						},
						views: {
							value: Views,
							tendance: null,
							loading: false,
							isError: false,
							sign: null
						},
						averageTimeOnPage: {
							value: vDuration,
							tendance: null,
							loading: false,
							isError: false,
							sign: sign
						},
						bounceRate: {
							value: BounceRate * 100,
							tendance: null,
							loading: false,
							isError: false,
							sign: '%'
						}
					}
	
					setTotals({ ...insightData })
				},
				(error) => {
					console.log(error);
					setTotals({
						uniqueViews: { loading: false, isError: true },
						views: { loading: false, isError: true },
						averageTimeOnPage: { loading: false, isError: true },
						bounceRate: { loading: false, isError: true }
					})
				});
		}else{
			pageService.getPageMetricsSumsOverTimeCustomTag(deviceSelected,selectedTenant, navFilter, reportTypeSelected, searchValue, filterUrl, sourceType,utmDimensionsSelected,excludeFilter,selectedCustomTag,	searchValue,
				searchValue.length === '' ? true : false,
				excludeFilter,
				data => {
					const { UniqueViews, Views, AverageTime, BounceRate } = data
					const [vDuration, sign] = AverageTime ? toReadableDuration(AverageTime) : [undefined, null]
					const insightData = {
						uniqueViews: {
							value: UniqueViews,
							tendance: null,
							loading: false,
							isError: false,
							sign: null
						},
						views: {
							value: Views,
							tendance: null,
							loading: false,
							isError: false,
							sign: null
						},
						averageTimeOnPage: {
							value: vDuration,
							tendance: null,
							loading: false,
							isError: false,
							sign: sign
						},
						bounceRate: {
							value: BounceRate * 100,
							tendance: null,
							loading: false,
							isError: false,
							sign: '%'
						}
					}
	
					setTotals({ ...insightData })
				},
				(error) => {
					console.log(error);
					setTotals({
						uniqueViews: { loading: false, isError: true },
						views: { loading: false, isError: true },
						averageTimeOnPage: { loading: false, isError: true },
						bounceRate: { loading: false, isError: true }
					})
				});
		}
	}

	useEffect(() => {
		refreshTotals();
	}, [
		navFilter,
		filterUrl,
		searchValue,
		sourceType,
		selectedTenant,
		reportTypeSelected,
		needRefreshForUtmFilter,
		deviceSelected,
		excludeFilter,
		selectedCustomTag
	]);

	return (
		<div className="analytics_insight_group">
			<Insight label="UniqueViews" name='uniqueViews' {...totals.uniqueViews} />
			<Insight label="Views" name='views' {...totals.views} />
			<Insight label="Average time on page" name='averageTimeOnPage' {...totals.averageTimeOnPage} />
			<Insight label="Bounce rate" name='bounceRate' {...totals.bounceRate} />
		</div>
	)

}