import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { react2angular } from 'react2angular';
import EventTrackingServices from '../../Services/EventTrackingServices';

export default function ReactApp(props) {
	useEffect(() => {
		const { trackEvent } = EventTrackingServices(props.$rootScope.User);

		var checkIntercom = setInterval(function(){
			if(window.Intercom) {
				window.Intercom('onShow', () => trackEvent('helpdesk/chat-opened'));
				clearInterval(checkIntercom);
			}
		}, 1000);

		return () => {
			checkIntercom && clearInterval(checkIntercom);
		};
	}, []);

	return (
		<></>
	);
}
angular
	.module('beyableSaasApp.ReactApp', [])
	.component('reactapp', react2angular(ReactApp, [], ['$rootScope']));
