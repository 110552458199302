import React, {useEffect, useRef, useState, useMemo} from 'react';
import ReactTooltip from 'react-tooltip';
import CropModal from '../../../WysiwygEditor/Components/CropModal';
import {useDropzone} from 'react-dropzone';
import Btn from '../../../../Components/Btn';
import CloudImageServices from '../../../../Services/CloudImageServices';
import {useBuildEditorContext} from './BuildEditorContext';
import Modal from '../../../../Components/Modal';
import {v4 as uuidv4} from 'uuid';
import ModalFooter from '../../../../Components/ModalFooter';
import {useTranslation} from 'react-i18next';
export const CustomAssetManager = ({
	customAssetManagerIsOpen,
	setCustomAssetManagerIsOpen,
	targetComponent
}) => {

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		open
	} = useDropzone({
		accept: 'image/*',
		noClick: true,
		noKeyboard: true,
		onDrop: acceptedFiles => {
			setSelectedFiles(
				acceptedFiles.length > 0 ? [acceptedFiles[0]] : []
			);
		}
	});

	const {
		authServices,
		accountId,
		$http,
		systemServices
	} = useBuildEditorContext();
	const [t, i18n] = useTranslation('common');
	const [isImg, setIsImg] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [source, setSource] = useState();
	const [isUploading, setIsUploading] = useState(false);
	const [cropableImage, setCropableImage] = useState();
	const [thumbnail, setThumbnail] = useState();
	const [openCropModal, setOpenCropModal] = useState(false);
	const [currentImageUrl, setCurrentImageUrl] = useState();
	const [isPreparingCropModal, setIsPreparingCropModal] = useState(false);
	const [sourceIsSvg, setsourceIsSvg] = useState(false);

	const getAccessToken = authServices && authServices.getAccessToken;
	const cloudImageServices = new CloudImageServices(accountId, $http, getAccessToken);
	const isOptional = true;//property.Editor.Optional;

	useEffect(() => {
		uploadSelectedImages();
	}, [selectedFiles]);

	useEffect(() => {
		if (!targetComponent) return;
		if (!targetComponent.attributes) return;
		setIsImg(targetComponent.attributes.tagName == 'img');
	}, [targetComponent]);

	useEffect(() => {
		const fetchSource = async () => {
			try {
				var sourceResult = await cloudImageServices.findImageSource(currentImageUrl);
				setSource(sourceResult.data);
			} catch (error) {
				console.log('error', error);
				setSource();
			}
		};

		fetchSource();
	}, [currentImageUrl]);

	const closeModal = () => {
		setCustomAssetManagerIsOpen(false);
		setThumbnail(null);
		setCurrentImageUrl(null);
	};

	const uploadSelectedImages = () => {
		const uploadHandler = async () => {
			const res = await cloudImageServices.postCreateLibrary();
			const libraryId = res.data.libraryId;
			await prepareFilesToUpload(libraryId);
		};

		if (selectedFiles.length > 0) {
			uploadHandler()
				.catch(error => {
					console.error('error during creation of the library', error);
				});
		}
	};

	const cropImage = async () => {
		// setCropableImage({...source});
		setOpenCropModal(true);
		const getCropableImage = async () => {
			try {
				return {...source};

			} catch (error) {
				console.log('error', error);
				systemServices.showError('Cannot crop image. Try uploading a new image.');
				return null;
			}
		};
		const image = await getCropableImage();
		if (image) {
			setCropableImage(image);
			setOpenCropModal(true);
		}
		setIsPreparingCropModal(false);
	};
	const prepareFilesToUpload = (libraryId) => {
		if (selectedFiles.length > 0) {
			let formData = new FormData();
			for (let i = 0; i < selectedFiles.length; i++) {
				let fileToRegister = selectedFiles[i];
				formData.append(`file[${i}]`, fileToRegister);
			}
			return uploadImage(formData, libraryId);
		}
		return Promise.resolve();
	};

	const handleUploadError = (response) => {
		if (response && response.data && response.data.error) {
			systemServices.showError(response.data.error);
		} else {
			systemServices.showError('An error occured during the upload, please retry');
		}
	};

	const applyImageToComponentAttribute = (comp, url) => {
		if (isImg) {
			comp.set('src', url);
		} else {
			const css = comp.getStyle();
			css['background-image'] = `url("${url}");`;
			comp.setStyle(css);
		}
	};

	const uploadImage = async (files, libraryId) => {
		setIsUploading(true);

		try {
			const imageUrl = await uploadToTwicPics(libraryId, files);
			// setImageCallBack(imageUrl);
			setCurrentImageUrl(imageUrl);
			setThumbnail(imageUrl);

			applyImageToComponentAttribute(targetComponent, imageUrl);
			
			setTimeout(() => {
				setIsUploading(false);
			}, 500);
		} catch (error) {
			console.log('-> error', error);
			handleUploadError(error.response);
			setIsUploading(false);
		}
	};

	const handleImageCropped = async (cropedImage) => {
		let imageTransform = {};
		const libraryId = uuidv4();
		imageTransform = {libraryId: libraryId, ...cropedImage};

		const resultCloudData = await cloudImageServices.postAddCloudImagesToLibrary(libraryId, [imageTransform]);
		const imageUrl = resultCloudData.data.createdBanners[0].imagePath;
		setThumbnail(imageUrl);

		applyImageToComponentAttribute(targetComponent, imageUrl);
	};

	const uploadToTwicPics = async (libraryId, files) => {
		const res = await cloudImageServices.uploadToTwicPics(libraryId, files,
			progressEvent => {
				const progress = progressEvent.loaded / progressEvent.total * 100;
				// console.log('uploading twicpics', Math.round(progress));
			});
		const resultCloudData = await cloudImageServices.postAddCloudImagesToLibrary(libraryId, res.data);
		const imageUrl = resultCloudData.data.createdBanners[0].imagePath;
		return imageUrl;
	};

	const displayThumbnail = () => {
		if (!thumbnail || !currentImageUrl) return <></>;
		return (
			<div className="flex_item_fix mr_10 format_param_thumbnail">
				<img src={thumbnail}/>
			</div>
		);
	};

	const browseFiles = (message) =>
		<Btn
			onClick={open}
			message={message}
			style="outline"
			size="s"
		/>;

	const style = useMemo(
		() => ({
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject]
	);

	const displayThumbnailActions = () => {
		if (!thumbnail || !currentImageUrl) return <></>;
		return (
			<>
				<div className="flex_item_fix ml_10">
					{browseFiles('Replace')}
				</div>
				<div className="flex_item_fix ml_10">
					<div className="btn_switch">
						<Btn onClick={() => cropImage()}
							icon="fas fa-crop-alt"
							style="outline"
							color="secondary"
							size="s"
							className="btn_w_xxs"
							disabled={isPreparingCropModal || sourceIsSvg}
						/>

						{isOptional &&
                            <Btn
                            	onClick={() => removeImage()}
                            	icon="fas fa-ban"
                            	style="outline"
                            	color="secondary"
                            	size="s"
                            	className="btn_w_xxs"
                            />
						}
					</div>
				</div>
			</>
		);
	};

	return (
		<Modal
			isOpen={customAssetManagerIsOpen}
			onClose={() => setCustomAssetManagerIsOpen(false)}
		>
			<div className="modal_header has_border">
                Add image
			</div>
			<div className='modal_body'>
				<ReactTooltip
					backgroundColor="black"
					effect="solid"
					place="bottom"
					eventOff={'click'}
					delayShow={0}
				/>

				<div className="form_block flex pos_rel" {...getRootProps({style})}>
					{thumbnail && displayThumbnail()}
					<div className="flex_item_full">
						<div className="form_block_label no_margin">
							{t('img.upload')} 
						    
							<a className="icon_btn s"
								data-tip="Accepted formats : .png, .jpg, .jpeg, .gif (max. size: 500 Ko)">
								<i className="fas fa-info-circle"/>
							</a>
							<div className="s_13 grey_2 fw_normal">
								{!currentImageUrl ?
									<>{t('img.dragDrop')} </>
									:
									<>{t('img.newImg')}</>
								}
							</div>
						</div>
					</div>
					{
						!currentImageUrl &&
                        <div className="flex_item_fix ml_10">
                        	{browseFiles('Browse files')}
                        </div>
					}
					{thumbnail && displayThumbnailActions()}

					<input {...getInputProps()} />
					{cropableImage &&
                        <CropModal
                        	widgetType={'TwicPic'}
                        	banner={cropableImage}
                        	openCropModal={openCropModal}
                        	closeCropModal={() => setOpenCropModal(false)}
                        	handleImageCropped={handleImageCropped}
                        />
					}
					{isUploading &&
                        <div className="mask">
                        	<span className="wheel"></span>
                        </div>
					}
				</div>
			</div>
			<ModalFooter
				hasBorder={true}
				primaryAction={
					<Btn
						onClick={(e) => closeModal()}
						message="Done"
						className="btn_w_l"
					/>
				}
			/>
		</Modal>
	);
};