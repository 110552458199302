import React from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import InsightProvider from './context/ContextInsights';
import RouterInsights from './RouterInsights';
import newInstanceI18next from '../../Util/traductionUtils';
import { I18nextProvider } from 'react-i18next';

function InsightContainerInner(props) {

	return (        
		<I18nextProvider i18n={newInstanceI18next}>
			
			<InsightProvider
				$http={props.$http}
				$rootScope={props.$rootScope}
				$routeParams={props.$routeParams}
				AuthServices={props.AuthServices}
				$location={props.$location}
			// accountId={accountId}
			>
				<RouterInsights {...props} ></RouterInsights>
			</InsightProvider>
		</I18nextProvider>
	);
}
angular.module('beyableSaasApp.Insight', [])
	.component(
		'insightcontainer',
		react2angular(
			InsightContainerInner,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', 'AuthServices']
		)
	);
