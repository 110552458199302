import React from 'react';

export default function SkuListComponent({ data, title, onChange, type }) {

	return (
		<div>
			{type === 'matching' &&
                <p className='titleTextareaMatchingList'>{title}</p>
			}
			<textarea className={`${type === 'matching' ? 'textareaMatchingList' : 'textareaAddSkuList'}`}
				value={type === 'matching' ? data.join('\n') : data} onChange={(e) => onChange(e)} />

		</div>
	);
}
