import React, { useState } from 'react';
import Btn from '../../../../Components/Btn';
import Dropdown from '../../../../Components/Dropdown';
import { useSessionContext } from '../../context/SessionContext';
export default function DropDownTypeChart() {
	const { changeTypeChartSelected, typeChartSelected } = useSessionContext();
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const typeChart = [
		{ name: 'column', label: 'Column' },
		{ name: 'spline', label: 'Spline' },
		{ name: 'areaspline', label: 'Area' },
	];

	const button = (
		<Btn
			message={typeChartSelected ? typeChart.find(el => el.name === typeChartSelected).label : 'Undefined metric'}
			size="xxs"
			style="outline"
			color="secondary"
			arrow={true}
			className="al_left"
		/>
	);
	return (
		<span className="mr_20">
			<span className="s_13 mr_5">Chart type</span>
			<Dropdown
				isOpen={dropdownIsOpen}
				setIsOpen={() => setDropdownIsOpen(true)}
				onHide={() => setDropdownIsOpen(false)}
				button={button}
			>
				<div className="flex flex_align_start">
					<div className="flex_item_full_fix">
						<ul className="listbox">
							{typeChart.map((value) => {
								const isSelected =
									(typeChartSelected &&
										typeChartSelected) === value.name;
								return (
									<li key={value.label}>
										<a
											className={
												isSelected
													? 'listbox_item selected'
													: 'listbox_item'
											}
											onClick={() => {
												changeTypeChartSelected(value.name),
												setDropdownIsOpen(false);
											}}
										>
											{value.label}
											{isSelected && (
												<span
												// className={styles.indicator}
												></span>
											)}
										</a>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</Dropdown>
		</span>
	);
}
