import HttpServices from './HttpServices';

export default class FormAndSurveysServices {
	constructor($http, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
		this.accountId = accountId;
		
	}


	getConfigurationHttpConnector(campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(
			`${this.BO_API_URL}feature/${this.accountId}/formsandsurveys/${campaignId}`,
			callbackSuccess,
			callbackError
		);
	}
	configureCampaignHttpConnector(
		campaignId,
		data,
		callbackSuccess,
		callbackError
	) {
		this.HttpServices.post(
			`${this.BO_API_URL}feature/${this.accountId}/formsandsurveys/${campaignId}`,
			data,
			callbackSuccess,
			callbackError
		);
	}
}
