/* eslint-disable no-undef */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { initialDates } from '../components/Nav/config';
import {initialUtmFilterV2} from '../../WebAnalytics/components/Containers/Ecommerce/config';
import {
	AverageQuantityInTransactions,
	sourceTypes,
	metricsTypesGRaph,
	SessionToSaleRatio,
	Revenue,
	metricsTypes
} from '../components/Overview/config';
const EcommerceOverviewContext = createContext();

export const useEcommerceOverviewContext = () => {
	return useContext(EcommerceOverviewContext);
};
const devices = [
// 	{
// 	value : 'all',
// 	label : 'All'
// },
	{
		value : 'Desktop',
		label : 'Desktop'
	},
	{
		value : 'Mobile',
		label : 'Mobile'
	},
	{
		value : 'Tablet',
		label : 'Tablet'
	}
];
const EcommerceOverviewContextProvider = (props) => {
	const [needRefreshForUtmFilter, setneedRefreshForUtmFilter] = useState(false);
	const [utmDimensionsSelected, setUtmDimensionsSelected] = useState(initialUtmFilterV2);
	const [chips, setChips] = useState(null);
	// DATES STATES
	const [selectedTimeGranularity, setSelectedTimeGranularity] =
		useState('Day');
	const [useTimeDisabled, setUseTimeDisabled] = useState(false);
	const FORMAT = 'YYYY/MM/DD';
	const currentDate = new Date;
	let ecommerceOverviewStorage = JSON.parse(sessionStorage.getItem('ecommerceOverview'));
	const updateStorage = (from, to) => {
		const newDates = {
			fromDate : from,
			to:to
		};
		sessionStorage.setItem('ecommerceOverview', JSON.stringify(newDates));
	};

	const [navFilter, setNavFilter] = useState({
		fromDate: ecommerceOverviewStorage ? ecommerceOverviewStorage.fromDate : moment(currentDate).subtract(7, 'day').format(FORMAT),
		toDate: ecommerceOverviewStorage ? ecommerceOverviewStorage.to :  moment(currentDate).format(FORMAT),
		fromDateToCompare: ecommerceOverviewStorage ? ecommerceOverviewStorage.fromDate : moment(currentDate).subtract(7, 'day').format(FORMAT),
		toDateToCompare: ecommerceOverviewStorage ? ecommerceOverviewStorage.to : moment(currentDate).format(FORMAT),
		filters: []
	});
	const [isCustomSelected, setisCustomSelected] = useState(false);
	const [customDateRange, setCustomDateRange] = useState( {
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day')
	});
	const [selectedPeriod, setSelectedPeriod] = useState(initialDates);
	
	// TYPE DATA STATES
	const [typeChartSelected, setTypeChartSelected] = useState('spline');
	const [ComparisonSelected, setComparisonSelected] = useState(false);
	const [chartData, setChartData] = useState({
		charts: [{ datasets: [], labels: [], title: '' }],
		isLoading: true,
		isError: false,
	});
	// FILTER STATES
	const [searchValue, setSearchValue] = useState('');
	const [offset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [dimension, setdimension] = useState('');
	const [dimensionId, setDimensionId] = useState('');
	const [filterUrl, setfilterUrl] = useState(null);
	// Graphique data states
	const [sourceType, setSourceType] = useState([]);

	const [reportTypeSelected, setReportTypeSelected] = useState('Page');
	const [numberOfSessions, setNumberOfSessions] = useState(null);
	const [numberOfSessions2, setNumberOfSessions2] = useState(null);
	const [totalsPeriod1, setTotalsPeriode1] = useState({
		numberOfSessions: 0,
		numberOfPageViews: 0,
		numberOfBounces: 0,
		durationSeconds: 0
	});
	const [totalsPeriode2, setTotalsPeriode2] = useState({
		numberOfSessions: 0,
		numberOfPageViews: 0,
		numberOfBounces: 0,
		durationSeconds: 0
	});

	const [needRefreshGraph, setNeedRefreshgraph] = useState(false);
	const [needRefreshBoard, setNeedRefreshBoard] = useState(false);
	const [metricTypeOverviewSelected, setmetricTypeOverviewSelected] = useState({
		key: 'Revenue',
		label: 'Revenue and conversion rate',
		value : [Revenue,SessionToSaleRatio]
	});

	const [deviceSelected, setdeviceSelected] = useState([]);

	function handleChangeDeviceSelected(elem){
		const newKey = deviceSelected.find(el => el === elem);
		const oldD = [...deviceSelected];
		const indefOfVal = oldD.indexOf(elem);
		if(indefOfVal !== -1){
			setdeviceSelected(oldD.filter(x => x !== newKey));
		}else{
			setdeviceSelected([...oldD,elem]);
		}
	}
	function resetDevices(){
		setdeviceSelected([]);
	}
	function changeTypeChartSelected(elemSelect) {
		typeChartSelected !== elemSelect && setTypeChartSelected(elemSelect);
	}
	const onSelectMetricType = (val) => {
		if (val.key !== metricTypeOverviewSelected.key) {
			const newTypeFiltered = metricsTypesGRaph.find((t) => t.key === val.key);
			newTypeFiltered && setmetricTypeOverviewSelected(val);
		}
	};
	const initiNavDate = (navState) => {
		if (!navState || !navState.fromDate || !navState.toDate)
			return initialDates;
		return {
			initialLabel: findNavLabel(navState.fromDate, navState.toDate),
			initialLabel2: findNavLabel(navState.fromDateToCompare, navState.toDateToCompare),
			initialFrom: navState.fromDate,
			initialTo: navState.toDate,
			fromDateToCompare: navState.fromDateToCompare,
			toDateToCompare: navState.toDateToCompare
		};
	};

	const onChangeNavFilter = (nav) => {
		setOffset(0);
		setCurrentPage(1);
		setNavFilter((currentNavFilter) => ({ ...currentNavFilter, ...nav }));
	};

	const findNavLabel = (fromDate, toDate) => {
		// format initNavState
		const FORMAT = 'YYYY/MM/DD';
		var last7days = moment(toDate).subtract(7, 'day').format(FORMAT);
		var last30days = moment(toDate).subtract(30, 'day').format(FORMAT);

		if (fromDate == toDate) return 'Today';
		if (last7days == fromDate) return 'Last 7 days';
		if (last30days == fromDate) return 'Last 30 days';
		else return 'Custom';
	};

	function handleSelectComparaisonPeriodHandler(value) {
		setComparisonSelected(value);
	}


	// FILTERS FUNCTIONS
	const onDeleteChip = () => {
		setOffset(0);
		setCurrentPage(1);
		setChips(null);
		setfilterUrl(null);
		setDimensionId('');
	};

	const handleChangeSourceType = (val) => {
		setOffset(0);
		setCurrentPage(1);
		const oldSources = [...sourceType];
		const indefOfVal = oldSources.indexOf(val);
		if(indefOfVal !== -1){
			setSourceType(oldSources.filter(x => x !== val));
		}else{
			setSourceType([...oldSources,val]);
		}
	};
	function onCardClick(type,id, title) {
		setOffset(0);
		setCurrentPage(1);
		if (dimensionId !== id) {
			setNeedRefreshgraph(true);
			setdimension(type);
			setDimensionId(id);
			setChips({
				type: type,
				title: title,
			});
		}
	}
	function addFilter(filters, dimension, value) {
		if (filters.find((c) => c.dimension === dimension)) {
			const addValueIfNotExists = (c) =>
				c.values.includes(value)
					? c
					: { ...c, values: c.values.concat(value) };
			return filters.map((c) =>
				c.dimension === dimension ? addValueIfNotExists(c) : c
			);
		}
		return filters.concat({ dimension, values: [value] });
	}

	function removeFilter(filters, dimension, value) {
		// setDimensionId('');
		if (filters.find((c) => c.dimension === dimension)) {
			const removeValueIfExists = (c) =>
				c.values.includes(value)
					? { ...c, values: c.values.filter((v) => v !== value) }
					: c;
			return filters
				.map((c) =>
					c.dimension === dimension ? removeValueIfExists(c) : c
				)
				.filter((c) => c.values.length > 0);
		}
		return filters;
	}
	const [utmSelected, setUtmSelected] = useState({
		source: false,
		medium: false,
		campaign: false,
		content: false
	});
	function resetUtmSelected() {
		setUtmSelected({
			source: false,
			medium: false,
			campaign: false,
			content: false
		});
	}
	function handleSelectUtmDimension(utmDimension, value) {

		setUtmSelected(dimensions => ({
			...dimensions,
			[utmDimension]: value
		})
		);
	}
	function hansleSaveUtmDimensionFilter(newFilterUtm) {
		// for (const key in newFilterUtm) {
		// 	const utmSelectionElementStatus = utmSelected[key];
		// 	if (!utmSelectionElementStatus) {
		// 		newFilterUtm[key].name = '';
		// 		newFilterUtm[key].label = '';
		// 	}
		// }
		setneedRefreshForUtmFilter(!needRefreshForUtmFilter);
		setUtmDimensionsSelected(newFilterUtm);
		setOffset(0);
		setCurrentPage(1);
	}
	const resetSource = () => {
		setOffset(0);
		setCurrentPage(1);
		setSourceType([]);
	};
	const changeActiveDimensionTabOver = props.changeActiveDimensionTabOver;
	return (
		<EcommerceOverviewContext.Provider value={{


			// DATE
			initiNavDate,
			navFilter,
			setNavFilter,
			handleSelectComparaisonPeriodHandler,


			// Filters 
			updateStorage,
			changeActiveDimensionTabOver,
			chips,
			onDeleteChip,
			sourceType,
			sourceTypes,
			handleChangeSourceType,
			onChangeNavFilter,
			setNumberOfSessions,
			setNumberOfSessions2,
			setTotalsPeriode1,
			setTotalsPeriode2,
			ComparisonSelected,
			addFilter,
			removeFilter,
			onCardClick,
			dimension,
			dimensionId,
			// CHARTS
			typeChartSelected,
			chartData,
			setChartData,
			metricTypeOverviewSelected,
			useTimeDisabled,
			setUseTimeDisabled,
			onSelectMetricType,
			changeTypeChartSelected,
			isCustomSelected,
			setisCustomSelected,
			customDateRange,
			setCustomDateRange,


			devices,
			deviceSelected,
			handleChangeDeviceSelected,
			resetDevices,
			resetSource,
			// UTMS 
			utmDimensionsSelected,
			utmSelected,
			resetUtmSelected,
			handleSelectUtmDimension,
			hansleSaveUtmDimensionFilter
		}}>{props.children}</EcommerceOverviewContext.Provider>
	);
};



export default EcommerceOverviewContextProvider;
