import React, { useEffect, useState } from 'react';
import Modal from '../../../../Components/Modal';
import Btn from '../../../../Components/Btn';
import Fieldset from '../../../../Components/Fieldset';
import InputCustom from '../../../../Components/InputCustom';
import { CampaignsContext } from '../../context';
import InpageElements from './InpageElements';
import { tryExtension } from '../GraphEditor/utils';
import AlertInpageDownloadChromeExt from './ErrorMessage/AlertInpageDownloadChromeExt';
import {useTranslation} from 'react-i18next';
import SpinnerWheel from '../../../../Components/SpinnerWheel';

const getTargetPositionIntValue = (targetPosition) => 
	(targetPosition == 'Default' && 0)
	|| (targetPosition == 'Replace' && 1)
	|| (targetPosition == 'AdjacentBeforeBegin' && 2)
	|| (targetPosition == 'AdjacentAfterBegin' && 3)
	|| (targetPosition == 'AdjacentBeforeEnd' && 4)
	|| (targetPosition == 'AdjacentAfterEnd' && 5)
	|| 0;

const getTargetPositionStringValue = (targetPosition) => 
	(targetPosition == 0 && 'Default')
	|| (targetPosition ==  1 && 'Replace')
	|| (targetPosition == 2 && 'AdjacentBeforeBegin')
	|| (targetPosition ==  3 && 'AdjacentAfterBegin')
	|| (targetPosition == 4 && 'AdjacentBeforeEnd')
	|| (targetPosition == 5 && 'AdjacentAfterEnd')
	|| 'Default';	

export default function DefineLiveInPage({
	targetSelector,
	setTargetSelector,
	setTargetIndexEdit,
	targetPosition,
	setTargetPosition,
	urlDebug,
	setUrlDebug,
	handleCancel,
	handleConfirm,
}) {
	const extensionId = window.BEYABLE_env.IN_PAGE_EXTENSION_ID;
	const version = window.BEYABLE_env.PATCH_EXTENSION_VERSION;
	const [t, i18n] = useTranslation('common');
	const [hasExtension, setHasExtension] = useState(false);
	const [hasGoodVersion, setHasGoodVerision] = useState(false);
	const [currentVerison, setCurrentVersion] = useState('');
	const [inpageEdit, setInpageEdit] = useState(null);
	const [urlValue, setUrlValue] = useState(urlDebug || '');
	const {
		getCurrentVariation,
		systemServices,
		getCurrentVariationRendering,
		isLoadingCurrentVariationRendering,
		setisLoadingCurrentVariationRendering
	} = CampaignsContext();


	const downloadExtension = () => {
		window.open(
			'https://chrome.google.com/webstore/detail/beyable-inspector/' +
                extensionId,
			'_blank',
			'noopener,noreferrer'
		);
	};

	const activeVariation = getCurrentVariation() || {};
	const [modalUpdateIsOpen, setModalUpdateIsOpen] = useState(false);
	if (!chrome.runtime)
		return (
			<AlertInpageDownloadChromeExt
				hasGoodVersion={true}
				refresh={() =>
					tryExtension(
						extensionId,
						version,
						setHasExtension,
						setHasGoodVerision,
						setCurrentVersion
					)
				}
				downloadExtension={downloadExtension}
			/>
		);
	let port = chrome.runtime.connect(extensionId, { name: 'initExt' });

	function connect(urlToUse) {

		if (chrome.runtime.lastError) {
			handleCancel();

			return;
		}
		if (!hasExtension || !hasGoodVersion) {
			return handleCancel();
		}
		port.onDisconnect.addListener((e) => {
			port = chrome.runtime.connect(extensionId, { name: 'initExt' });
			connect();
		});
		port.onMessage.addListener((res) => {
			if (res.type === 'responseTag') {
				setModalUpdateIsOpen(false);
				setInpageEdit({ ...res.data });
				setUrlDebug(urlToUse);
				setTargetSelector(res.data.InPageElementSelector);
				setTargetPosition(getTargetPositionStringValue(res.data.InPagePosition));
				setTargetIndexEdit(1);
				return true;
			}
		});
	}

	useEffect(() => {
		tryExtension(
			extensionId,
			version,
			setHasExtension,
			setHasGoodVerision,
			setCurrentVersion
		);
	}, []);

	const launchLivePageInspector = (urlToUse) => {
		if (!hasExtension) return;

		const urlIsValid = (str) => {
			var pattern = new RegExp(
				'^(https:\\/\\/)' +
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                    '((\\d{1,3}\\.){3}\\d{1,3}))' +
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                    '(\\?[;&a-z\\d%_.~+=-]*)?' +
                    '(\\#[-a-z\\d_]*)?$',
				'i'
			);
			return !!pattern.test(str);
		};

		if (
			urlToUse.indexOf('http://') === -1 &&
            urlToUse.indexOf('https://') === -1
		) {
			urlToUse = 'https://' + urlToUse;
		}

		const isValid = urlIsValid(urlToUse);

		if (isValid) {
			getCurrentVariationRendering(
				async (data) => {
					let rendering = data.Desktop; // or else data.Mobile
					if(!rendering){
						rendering = {
							Content : '<div class="by_FAKE_42">HERE</div>',
							Css : `
							.by_FAKE_42 {
								all: revert;
								display: block !important;
								border: dashed 2px #006fff !important;
								padding: 10px 20px !important;
								margin: 0 !important;
								font-size: 16px !important;
								line-height: 1.3 !important;
								text-align: center !important;
								text-transform: uppercase !important;
								color: #006fff;
								background: #fff !important;
							}
							`,
							Javascript : '',
						};
					}
					const TemplateName= activeVariation.TemplateName !== '' ? activeVariation.TemplateName : 'fake';
					const inpage = {
						insertStyle: rendering.Css,
						insertScript: rendering.Javascript,
						insertHtml: rendering.Content,
						TemplateName: TemplateName,
					};
					
					let message = {
						...inpage,
						inpageEdit: {
							InPageElementSelector: targetSelector,
							InPagePosition: getTargetPositionIntValue(targetPosition),
						},
					};
					port.postMessage({
						type: 'ch.activation',
						url: urlToUse,
						inpage: message,
						mode: 'inpage',
					});
					connect(urlToUse);
				},
				() => {
					systemServices.showError(
							'An error occured with the preview, please retry'
						)
				}
			);
		} else {
			systemServices.showError('Please enter a valid URL');
		}
	};

	const handleChange = (e) => {
		let url = e.target.value;
		url = url.replace('http://', '');
		url = url.replace('https://', '');
		url = url.replaceAll(' ', '');
		setUrlValue(url);
	};

	const emptyState = (
		<>
			{!window.chrome && (
				<div className="empty_state v_pad_m">
					<div className="empty_state_icon">
						<i className="fab fa-chrome"></i>
					</div>
					<div className="empty_state_title">
                        Graphic patches only work on Chromium based browsers
					</div>
					<div className="empty_state_text">
                        Like <strong>Google Chrome</strong> or{' '}
						<strong>Microsoft Edge</strong>
					</div>
				</div>
			)}
			{window.chrome && (!hasExtension || !hasGoodVersion) && (
				<div className="empty_state v_pad_m">
					<div className="empty_state_title">
						{!hasGoodVersion
							? 'Please upgrade your BEYABLE Chrome extension'
							: 'First step : please download and install BEYABLE Chrome extension'}
					</div>
					<div className="empty_state_text">
                        After installing the extension,{' '}
						<a onClick={() => tryExtension(
							extensionId,
							version,
							setHasExtension,
							setHasGoodVerision,
							setCurrentVersion
						)} className="text_link">
                            click here to refresh
						</a>
                        .
					</div>
					<div className="empty_state_btn">
						<Btn
							size="l"
							message="Get Chrome extension"
							icon="fas fa-cloud-download-alt"
							onClick={() => downloadExtension()}
						/>
					</div>
				</div>
			)}
		</>
	);

	var hasTargetPosition = (targetPosition && targetPosition != 'Default');

	return (
		<>
			<div className="modal_body">
				{targetSelector && hasTargetPosition && (
					<>
						{emptyState}
						{window.chrome && hasExtension && hasGoodVersion && (
							<InpageElements
								targetSelector={targetSelector}
								targetPosition={targetPosition}
								urlInpage={urlDebug}
								handleClick={() =>
									launchLivePageInspector(urlDebug)
								}
							/>
						)}
					</>
				)}
				{(!targetSelector || !hasTargetPosition) && (
					<>
						{emptyState}
						{window.chrome && hasExtension && hasGoodVersion && (
							<Fieldset
								label={t('insert.enterAPage')} 
								blockClassName="l"
							>
								<div className="flex">
									<div className="flex_item_full">
										
										<InputCustom
											onChange={handleChange}
											value={urlValue}
											placeholder="Page URL"
											fullWidth={true}
											autoFocus={true}
											readonly={!hasExtension}
											prepend={
												<span className="custom_input_prepend_text">
                                                    https://
												</span>
											}
											onEnter={() =>
												launchLivePageInspector(
													urlValue
												)
											}
										/>
									</div>
									<div className="flex_item_fix ml_10">
										<Btn
											message={
												targetSelector
													? t('insert.update')
													: t('actions.launch')
											}
											icon="fas fa-magic"
											onClick={() =>
												launchLivePageInspector(
													urlValue
												)
											}
											disabled={
												!hasExtension || !window.chrome
											}
										/>
									</div>
								</div>
							</Fieldset>
						)}
					</>
				)}
			</div>
			<div className="modal_footer has_border mt_10 flex">
				<div className="flex_item_full">
					{targetSelector && hasTargetPosition && (
						<Btn
							message={t('insert.update')}
							icon="fas fa-magic"
							style="reverse"
							color="primary"
							onClick={() => {
								setModalUpdateIsOpen(true);
							}}
						/>
					)}
				</div>
				<div className="flex_item_fix">
					<Btn
						style="ghost"
						color="secondary"
						message={t('actions.cancel')}
						onClick={() => handleCancel()}
					/>
					<Btn
						message={t('actions.confirm')}
						onClick={() => handleConfirm()}
						color="primary"
						disabled={!targetSelector}
					/>
				</div>
			</div>

			<Modal
				isOpen={modalUpdateIsOpen}
				onClose={() => setModalUpdateIsOpen(false)}
			>
				{(!window.chrome || !hasExtension || !hasGoodVersion) && (
					<div className="modal_body">{emptyState}</div>
				)}
				{window.chrome && hasExtension && hasGoodVersion && (
					<>
						{isLoadingCurrentVariationRendering && <SpinnerWheel wheelSize="l" />}
						<div className="modal_header modal_section_top">
						{t('insert.update')}
						</div>
						<div className="modal_body">
							<InputCustom
								label={t('insert.enterAPage')}
								onChange={handleChange}
								value={urlValue}
								placeholder="Page URL"
								fullWidth={true}
								autoFocus={true}
								readonly={!hasExtension}
								onEnter={() => {
									launchLivePageInspector(urlValue);
									// setModalUpdateIsOpen(false);
								}}
							/>
						</div>
						<div className="modal_footer mt_10 al_right">
							<Btn
								style="ghost"
								color="secondary"
								message={t('actions.cancel')}
								onClick={() => setModalUpdateIsOpen(false)}
							/>
							<Btn
								message={t('actions.launch')}
								icon="fas fa-magic"
								onClick={() => {
									launchLivePageInspector(urlValue);
									// setModalUpdateIsOpen(false);
								}}
								color="primary"
							/>
						</div>
					</>
				)}
			</Modal>
		</>
	);
}
