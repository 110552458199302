
import React from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import ServerSideEditor from './ServerSideEditor';
import WysiwygEditorProvider from '../../WysiwygEditor/context/WysiwygEditorContext';
import CreateServerSideEditorContextProvider from './context/ServerSideEditorContextProvider';


export default function ServerSideCampaignEditorContainer(props) {

	return <WysiwygEditorProvider
		$rootScope={props.$rootScope}
		$http={props.$http}
		$routeParams={props.$routeParams}
		$location={props.$location}
		$timeout={props.$timeout}
		AuthServices={props.AuthServices}
		context={'ServerSideCampaign'}
	>
		<CreateServerSideEditorContextProvider

			$http={props.$http}
			$routeParams={props.$routeParams}
			$rootScope={props.$rootScope}
			$timeout={props.$timeout}
			$location={props.$location}
			AuthServices={props.AuthServices}
		>
			<ServerSideEditor></ServerSideEditor>
		</CreateServerSideEditorContextProvider>
	</WysiwygEditorProvider>;

}

angular.module('beyableSaasApp.ServerSideEditor', [])
	.component(
		'serversideeditorcontainer',
		react2angular(
			ServerSideCampaignEditorContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', 'AuthServices']
		)
	);

