import React, { useEffect, useState } from 'react';
import Avatar from '../../Components/Avatar';
import Modal from '../../Components/Modal';
import Btn from '../../Components/Btn';
import Spinner from '../../Components/Spinner';
import Checkbox from '../../Components/Checkbox';
import InputCustom from '../../Components/InputCustom';
import EditBeyableStudioAccess from './EditBeyableStudioAccess';
import { useAdminContext } from './userAdministrationContext';
import {useTranslation} from 'react-i18next';
const i18nL= localStorage.getItem('i18nL');
export default function UserEditModal({
	isOpen,
	onClose,
	user = {
		lastName: '',
		firstName: '',
		email: '',
		roles: [],
		memberId: '',
		isBoundToToolingAuth0: false
	}
}) {
	const {
		canBindToToolingAuth0,
		handleCreateUser,
		handleUpdateRoles,
		handleUpdateUser,
		handleUpdateMemberInOrganization,
		roleList,
	} = useAdminContext();
	const [t, i18n] = useTranslation('common');
	const [userToChange, setUserToChange] = useState(user);
	const [waiting, setWaiting] = useState(false);
	const [btnIsDisabled, setBtnIsDisabled] = useState(true);
	const [changes, setChanges] = useState({ mainInfoChanged: false, rolesChanged: false, bindToToolingAuth0Changed: false });

	let isNewUser = !('id' in userToChange);
	const add = i18nL && i18nL.includes('fr') ? 'Ajouter un utilisateur' : 'Add User';
	const edit = i18nL && i18nL.includes('fr') ? 'Editer l\'utilisateur' : 'Edit user';
	const firstName = i18nL && i18nL.includes('fr') ? 'Prénom' : 'First name';
	const lastName = i18nL && i18nL.includes('fr') ? 'Nom de famille' : 'Last name';
	const Cancel = i18nL && i18nL.includes('fr') ? 'Annuler' : 'Cancel';
	const Confirm = i18nL && i18nL.includes('fr') ? 'Confirmer' : 'Confirm';
	const sure = i18nL && i18nL.includes('fr') ? 'Êtes-vous sûr de vouloir supprimer l\'utilisateur' : 'Are you sure you want to delete the user';
	const disabledBtnConfirm = () => {
		if (!isOpen) return;
		if (userToChange.firstName.length !== 0 && userToChange.email.length !== 0) {
			const isAnUrl = (url) => {
				if (url.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
					return true;
				} else {
					return false;
				}
			};
			if (isAnUrl(userToChange.email.trim())) {
				setBtnIsDisabled(false);
			} else {
				setBtnIsDisabled(true);
			}
		} else {
			setBtnIsDisabled(true);
		}
	};

	useEffect(() => {
		if (!isNewUser && isOpen) {
			disabledBtnConfirm();
		}
	}, [isOpen]);

	const handleChangeInput = (e) => {
		e.persist();
		setUserToChange(prevValues => ({
			...prevValues,
			[e.target.name]: e.target.value
		}));
		setChanges(c => ({ ...c, mainInfoChanged: true }));
	};

	useEffect(() => {
		disabledBtnConfirm();
	}, [userToChange]);

	const handleSelectRole = (e, role) => {
		let tmpUser = { ...userToChange };
		let tmpRoles = [...userToChange.roles];

		if (tmpRoles.indexOf(role.value) != -1) {
			// Remove role
			tmpRoles = tmpRoles.filter(el => el !== role.value);
		} else {
			// Add role
			tmpRoles.push(role.value);
		}

		tmpUser.roles = tmpRoles;
		setUserToChange(tmpUser);
		setChanges(c => ({ ...c, rolesChanged: true }));
	};

	const handleToggleBindToToolingAuth0Change = (value) => {
		setUserToChange(u => ({ ...u, isBoundToToolingAuth0: value }));
		setChanges(c => ({ ...c, bindToToolingAuth0Changed: true }));
	};

	const afterSubmitSuccess = () => {
		setBtnIsDisabled(false);
		onClose();
		setWaiting(false);
	};
	const afterSubmitFailure = () => {
		setWaiting(false);
	};

	const submit = (e) => {
		setWaiting(true);

		if (!changes.rolesChanged && !changes.mainInfoChanged && !changes.bindToToolingAuth0Changed)
			return afterSubmitSuccess();

		if (isNewUser) {
			handleCreateUser(userToChange, () => {
				afterSubmitSuccess();
				setUserToChange({
					lastName: '',
					firstName: '',
					email: '',
					roles: [],
				});
			},
			afterSubmitFailure);
			return;
		}

		const onlyMemberInOrganizationChanged = !changes.rolesChanged && !changes.mainInfoChanged && changes.bindToToolingAuth0Changed;
		if (onlyMemberInOrganizationChanged) {
			handleUpdateMemberInOrganization(userToChange, 
				afterSubmitSuccess,
				afterSubmitFailure);
			return;
		}

		if (changes.rolesChanged && !changes.mainInfoChanged) {
			handleUpdateRoles(userToChange, userToChange.roles,
				afterSubmitSuccess,
				afterSubmitFailure);
			return;
		}

		if (changes.mainInfoChanged) {
			handleUpdateUser(userToChange,
				afterSubmitSuccess,
				afterSubmitFailure);
			return;
		}
	};


	return (
		<Modal
			width="580"
			isOpen={isOpen}
			onClose={() => onClose(true)}
		>
			{isNewUser ?
				<>
					<div className="modal_header has_border">
					{add}
					</div>
				</>
				:
				<>
					<div className="modal_header">
					{edit}
					</div>
					<div className="modal_section_s modal_section_grey flex_item_fix">
						<div className="flex">
							<div className="flex_item_fix">
								<Avatar
									id={user.id}
									name={user.displayName}
									size="xl"
								/>
							</div>
							<div className="flex_item_full ml_15">
								<div className="user_modal_title">{user.displayName}</div>
								<div className="user_modal_desc grey_2">{user.email}</div>
							</div>
						</div>
					</div>
				</>
			}
			<div className="modal_body">
				<div className="flex">
					<div className="flex_item_half has_gutter">
						<InputCustom
							value={userToChange && userToChange.firstName}
							type="text"
							placeholder={firstName}
							label={firstName}
							name="firstName"
							onChange={handleChangeInput}
							fullWidth={true}
						/>
					</div>
					<div className="flex_item_half has_gutter">
						<InputCustom
							value={userToChange && userToChange.lastName}
							type="text"
							placeholder={lastName}
							label={lastName}
							name="lastName"
							onChange={handleChangeInput}
							fullWidth={true}
						/>
					</div>
				</div>
				{isNewUser &&
					<InputCustom
						value={userToChange && userToChange.email}
						type="text"
						placeholder="Email"
						label="Email"
						name="email"
						onChange={handleChangeInput}
						fullWidth={true}
					/>
				}
				{roleList &&
					<div className="form_block">
						<label className="form_block_label">Roles</label>
						<div className="flex flex_wrap">
							{roleList.map((role, i) =>
								<div key={i} className="flex_item_half has_gutter">
									<Checkbox
										name="role"
										checked={userToChange.roles.indexOf(role.value) != -1}
										value={role.value}
										onChange={(e) => handleSelectRole(e, role)}
									>
										{role.label}
									</Checkbox>
								</div>
							)}
						</div>
					</div>
				}
				{canBindToToolingAuth0 &&
					<EditBeyableStudioAccess
						bindToToolingAuth0Value={userToChange.isBoundToToolingAuth0}
						handleToggleBindToToolingAuth0Value={(e) => handleToggleBindToToolingAuth0Change(e)}
					/>
				}
			</div>
			<div className="modal_footer has_border al_right">
				<Btn
					onClickFunction={() => onClose(true)}
					message={Cancel}
					color="secondary"
					size="l"
					style="outline"
				/>
				<Btn
					message={Confirm}
					size="l"
					color="primary"
					onClickFunction={!btnIsDisabled ? (e) => submit(e) : () => { }}
					disabled={btnIsDisabled}
				/>
			</div>

			{waiting &&
				<Spinner overlay={true} />
			}
		</Modal>
	);
}
