/* eslint-disable no-useless-escape */
import React, {useState,useEffect, ReactElement} from 'react';
import { useTranslation } from 'react-i18next';
import controlTypes from './controlTypes';
import { ShadowDom } from '../../Components/Iframe';
import Btn from '../../Components/Btn';
import {useFormContext} from '../FormSurvey/ContextFormAndSurvey';
import { Article } from '../../Components/Article/Article';
import { getCSS } from './css';

// type TypeOverview ={
//     formData : {
//         IsCollection: 	false,
//         IsGroup	: false,
//         Name: 'FormCustom',
//         Value : any}
// }

export default function Overview({
	state,
	thankSectionValue,
	showWrapper = true
} ) {
	const [t] = useTranslation(['survey']);

	const [baliseCss, setbaliseCss] = useState(<style></style>);
	const {
		updatePanel,
		panelSelected,
		setpanelSelected,
		formData,
		formSurveyStyles,
		deleteAnswers,
		isLast,
		setisLast,
		stepSurvey
	} = useFormContext();

	const [overviewObject, setoverviewObject] = useState<ReactElement>(<></>);
	const [isRequired, setisRequired] = useState<boolean>(false);
	const [hasError, sethasError] = useState<any>();

	function handleGetIsClickable(idCurrPan){
		if(!idCurrPan) return false;
		const id = state?.sections[0].panels.find(x => x.id ===idCurrPan.id);
		const idInList = state?.sections[0].panels.indexOf(id);
		const lastId = state?.sections[0].panels.length - 1;
		if(idInList === (-1)){
			return false;
		}
		else if(idInList !== lastId){
			return true;
		}else{
			return false;
		}
	}
	function getTypeObj(t){
		return controlTypes.find(el=> el.id === t);
	}
	function handleChangeCheck(element){
		const type = typeof panelSelected.valueForTest;

		if(type === 'string'){
			updatePanel(panelSelected.id, null, {valueForTest :[element]});
		}else{
			const findElement = panelSelected.valueForTest.find(o => o === element);
			if(findElement){
				const value = panelSelected.valueForTest.filter(x => x !== element);
				const stateN = {valueForTest: value};
				updatePanel(panelSelected.id, null, stateN);
			}else{
				const stateN = {valueForTest: [...panelSelected.valueForTest,element]};
				updatePanel(panelSelected.id, null, stateN);
			}
		}
	}
	function handleChangeValue (e) {
		const stateN = {valueForTest: e};
		updatePanel(panelSelected.id, null, stateN);
	}
	function GoToNextQuestion(idCurrPan){
		const isCLickable = handleGetIsClickable(idCurrPan);
		const isError = checkError(idCurrPan);
		if(isError){
			return;
		}else if(isCLickable){
			setisLast(false);
			const formattedData = JSON.parse(formData);
			const arrayOfQuestions = formattedData.sections[0].panels;
			const currQuestion = arrayOfQuestions.find(x=>x.id === idCurrPan.id );
			const idxOfCurrentQuestion = arrayOfQuestions.indexOf(currQuestion);
			// For radio or select type
			if(idCurrPan && (idCurrPan.type === 6 || idCurrPan.type === 4 || idCurrPan.type === 10 || idCurrPan.type === 11 || panelSelected.type === 5) ){
				const panelCurrent = state.sections[0].panels.find(p => p.id === idCurrPan.id );

				if(panelCurrent.surveyInfos.nextQuestion === 'basedOn' ){
					const questions =  panelCurrent.surveyInfos.multipleQuestionsBasedOnAnswer.find(x=> x.controlId === panelCurrent.valueForTest);


					if(questions){
						if(questions.nextQuestion === 'next'){
							const newElementSelected = arrayOfQuestions[idxOfCurrentQuestion + 1];
							if(newElementSelected){
								setpanelSelected({...newElementSelected});
							}
						}
						else if(questions.nextQuestion === 'end'){
							setoverviewObject(<></>);
							setpanelSelected(null);
							setisLast(true);
						}
						else{
							const newElementSelected = arrayOfQuestions.find(x => x.id === questions.nextQuestion);
							setpanelSelected({...newElementSelected});
						}
					}else{
						const question =  panelCurrent.surveyInfos.multipleQuestionsBasedOnAnswer[0];
						const newElementSelected = arrayOfQuestions.find(x => x.id === question.nextQuestion);
						setpanelSelected({...newElementSelected});
					}
				}else{
					const question =  panelCurrent.surveyInfos.nextQuestion;
					if(question === 'next'){
						const newElementSelected = arrayOfQuestions[idxOfCurrentQuestion + 1];

						if(newElementSelected){
							setpanelSelected({...newElementSelected});
						}
					}	else if(question === 'end'){
						setoverviewObject(<></>);
						setpanelSelected(null);
						setisLast(true);
					}
					else{
						const newElementSelected = arrayOfQuestions.find(x => x.id === question);
						
						if(newElementSelected){
							setpanelSelected({...newElementSelected});
						}
					}
				}
			}
			// For other cases
			else{
				const panelCurrent = state.sections[0].panels.find(p => p.id === idCurrPan.id );
				const question =  panelCurrent.surveyInfos.nextQuestion;
				if(question === 'next'){
					const newElementSelected = arrayOfQuestions[idxOfCurrentQuestion + 1];
					if(newElementSelected){
						setpanelSelected({...newElementSelected});
					}
				}
				else if(question === 'end'){
					setoverviewObject(<></>);
					setpanelSelected(null);
					setisLast(true);
				}else{
					const newElementSelected = arrayOfQuestions.find(x => x.id === question);
					if(newElementSelected){
						setpanelSelected({...newElementSelected});
					}
				}
			}
		}else{
			setisLast(true);
		}
	}

	function goToSkippedQuestion (idCurrPan){
		const arrayOfQuestions = state.sections[0].panels;
		const id = idCurrPan.surveyInfos.ignoreQuestion;
		
		if(id === 'next'){
			const currQuestion = arrayOfQuestions.find(x=>x.id === idCurrPan.id );
			const idxOfCurrentQuestion = arrayOfQuestions.indexOf(currQuestion);
			const lastId = state?.sections[0].panels.length - 1;
			if(idxOfCurrentQuestion === (-1)){
				setoverviewObject(<></>);
				setpanelSelected(null);
				setisLast(true);
			}
			else if(idxOfCurrentQuestion !== lastId){
				const newElementSelected = arrayOfQuestions[idxOfCurrentQuestion + 1];
				setpanelSelected({...newElementSelected});
			}else{
				setoverviewObject(<></>);
				setpanelSelected(null);
				setisLast(true);
			}
		}else if(id === 'end'){
			setoverviewObject(<></>);
			setpanelSelected(null);
			setisLast(true);
		}else{
			const idQuestion = arrayOfQuestions.find(x => x.id === id);
			setpanelSelected({...idQuestion});
		}
	}

	function getElementForm(id:number, elem){
		switch (id) {
			case 1:
				return <>
					{elem.showQuestion && <QuestionLabel>{elem.question}</QuestionLabel>}
					<input
						type="text"
						placeholder={elem.placeholder}
						value={panelSelected.valueForTest}
						onChange={(e)=>handleChangeValue(e.target.value)}
						className='by_lib_survey_textfield'
					/>
				</>;
			case 2:
				return <>
					{elem.showQuestion && <QuestionLabel>{elem.question}</QuestionLabel>}
					<textarea
						placeholder={elem.placeholder}
						value={panelSelected.valueForTest}
						onChange={(e)=>handleChangeValue(e.target.value)}
						className='by_lib_survey_textfield'
						rows={3}
					/>
				</>;
			case 4:
				return <>
					{elem.showQuestion && <QuestionLabel>{elem.question}</QuestionLabel>}
					{elem.controls.map(o => {
						const isChecked = panelSelected.valueForTest.includes(o.id);
						return (
							<div key={o.id} className='by_lib_survey_choice'>
								<label className={isChecked ? 'by_lib_survey_choice_label selected' : 'by_lib_survey_choice_label'}>
									<input
										type="radio"
										checked={isChecked}
										onChange={() => handleChangeValue(o.id)}
										name={o.id}
										className='by_lib_survey_choice_field'
									/>
									<span className='by_lib_survey_choice_button by_lib_survey_choice_button_radio'></span>
									<span className='by_lib_survey_choice_text'>{o.text}</span>
								</label>
							</div>
						);
					})}
				</>;
			case 5:
				return <>
					{elem.showQuestion && <QuestionLabel>{elem.question}</QuestionLabel>}
					{elem.controls.map(o => {
						const isChecked = panelSelected.valueForTest.includes(o.id);
						return (
							<div key={o.id} className='by_lib_survey_choice'>
								<label className={isChecked ? 'by_lib_survey_choice_label selected' : 'by_lib_survey_choice_label'}>
									<input
										type="checkbox"
										checked={isChecked}
										onChange={() => handleChangeCheck(o.id)}
										className='by_lib_survey_choice_field'
									/>
									<span className='by_lib_survey_choice_button by_lib_survey_choice_button_checkbox'></span>
									<span className='by_lib_survey_choice_text'>{o.text}</span>
								</label>
							</div>
						);
					})}
				</>;
			case 7:
				return <>
					{elem.showQuestion && <QuestionLabel>{elem.question}</QuestionLabel>}
					<input
						type="email"
						placeholder={elem.placeholder || 'Please type here...'}
						value={panelSelected.valueForTest}
						onChange={(e)=>handleChangeValue(e.target.value)}
						className='by_lib_survey_textfield'
					/>
				</>;
			case 9:
				return <>
					{elem.showQuestion && <QuestionLabel>{elem.question}</QuestionLabel>}
					<input
						type="tel"
						placeholder={elem.placeholder}
						value={panelSelected.valueForTest}
						onChange={(e)=>handleChangeValue(e.target.value)}
						className='by_lib_survey_textfield'
						
					/>
				</>;
			case 10:
				return <>
					{elem.showQuestion && <QuestionLabel>{elem.question}</QuestionLabel>}
					<div className='by_lib_survey_rating'>
						{elem.controls.map(o => {
							const isChecked = panelSelected.valueForTest.includes(o.id);
							return (
								<div key={o.id} className='by_lib_survey_rating_choice'>
									<label className={isChecked ? 'by_lib_survey_rating_choice_label selected' : 'by_lib_survey_rating_choice_label'}>
										<input
											type="radio"
											checked={isChecked}
											onChange={() => handleChangeValue(o.id)}
											name={o.id}
											className='by_lib_survey_rating_choice_field'
										/>
										<span className='by_lib_survey_rating_choice_text'>{o.text}</span>
									</label>
								</div>
							);
						})}
					</div>
					<div className='by_lib_survey_rating_legend'>
						<div>{elem.lowScoreLabel}</div>
						<div>{elem.highScoreLabel}</div>
					</div>
				</>;
			case 11:
				return <>
					{elem.showQuestion && <QuestionLabel>{elem.question}</QuestionLabel>}
					<div className='by_lib_survey_rating'>
						{elem.controls.map(o => {
							const isChecked = panelSelected.valueForTest.includes(o.id);
							return (
								<div key={o.id} className='by_lib_survey_rating_choice'>
									<label className={isChecked ? 'by_lib_survey_rating_choice_label selected' : 'by_lib_survey_rating_choice_label'}>
										<input
											type="radio"
											checked={isChecked}
											onChange={() => handleChangeValue(o.id)}
											name={o.id}
											className='by_lib_survey_rating_choice_field'
										/>
										<span className='by_lib_survey_rating_choice_text'>{o.text}</span>
									</label>
								</div>
							);
						})}
					</div>
					<div className='by_lib_survey_rating_legend'>
						<div>{elem.lowScoreLabel}</div>
						<div>{elem.highScoreLabel}</div>
					</div>
				</>;
        
			default:
				return <></>;
		}
	}

	useEffect(()=>{
		if(panelSelected && stepSurvey !== 2){
			const typeElement = getTypeObj(panelSelected.type);
			if(typeElement){
				const elementToRender = getElementForm(typeElement.id, panelSelected);
				setisRequired(panelSelected.required);
				setoverviewObject(elementToRender);
				const isCLickable = handleGetIsClickable(panelSelected);
				if(isCLickable){
					setisLast(false);
				}
			}
		}else{
			if(stepSurvey === 2){
				setoverviewObject(<></>);
				setpanelSelected(null);
				setisLast(true);
			}
		}
	},[panelSelected, formData, stepSurvey]);

	useEffect(()=>{
		if (panelSelected && formSurveyStyles) {
			const currentCss = getCSS(formSurveyStyles);

			const typeElement = getTypeObj(panelSelected.type);
			if(typeElement){
				const elementToRender = getElementForm(typeElement.id,panelSelected);
				setisRequired(panelSelected.required);
				setoverviewObject(elementToRender);
				const isCLickable = handleGetIsClickable(panelSelected);
				if(isCLickable){
					setisLast(false);
				}
			}
			setbaliseCss(<style>{currentCss}</style>);
		}
	},[formData, formSurveyStyles, panelSelected]);

	useEffect(()=>{
		sethasError('');
	},[panelSelected]);

	function isNotClickable(panelSelected){
		if(!panelSelected) return;
		let isCLickableOk  = true;
		if(panelSelected.type === 5 || panelSelected.type === 4 || panelSelected.type === 10 || panelSelected.type === 11){
			const type = typeof panelSelected.valueForTest;
			if(type === 'string'){
				if(panelSelected.valueForTest.length === 0){
					isCLickableOk = false;
				}
			}else{
				const values = panelSelected.valueForTest;
				if(values.length === 0){
					isCLickableOk = false;
				}else{
					isCLickableOk = true;
				}
			}
		}else if (panelSelected.type === 1 || panelSelected.type === 2 || panelSelected.type === 7 || panelSelected.type === 9  ){
			if(panelSelected.valueForTest.length === 0){
				isCLickableOk = false;
			}else{
				isCLickableOk = true;
			}
		} 

		return isCLickableOk;
	}
	function checkError(elementToCheck){
		let error;
		if(elementToCheck.type === 7 ){
			if(panelSelected.valueForTest.length === 0){
				error = formSurveyStyles.wording.errorEmail;
			}else{
				const regex = (/^\S+@\S+\.\S+$/);
				const isAGoodFormatEmail = panelSelected.valueForTest.match(regex);
				if(!isAGoodFormatEmail){
					error = formSurveyStyles.wording.errorEmail;
				}else{
					error = null;
				}
			}

		}
		else if(elementToCheck.type === 9 ){
			if(panelSelected.valueForTest.length === 0){
				error = formSurveyStyles.wording.errorTel;
			}else{
				const regex = (/^(?:(?:\+|00)33[\s.\-]{0,3}(?:\(0\)[\s.\-]{0,3})?|0)[1-9](?:(?:[\s.\-]?\d{2}){4}|\d{2}(?:[\s.\-]?\d{3}){2})$/);
				const isAGoodTel = panelSelected.valueForTest.match(regex);
				if(!isAGoodTel){
					error = formSurveyStyles.wording.errorTel;
				}else{
					error = null;
				}
			}
		}else{
			error = null;
		}
		sethasError(error);
		return error;
	}

	const inner = (
		<ShadowDom>
			{baliseCss}
			<div className='by_lib_survey' style={showWrapper ? {padding: '22px', borderRadius: '6px'} : {}}>
				{stepSurvey !== 2 && !isLast && overviewObject}

				{ isLast && thankSectionValue && 
					<div className="by_lib_survey_end" style={{margin : 'auto'}} dangerouslySetInnerHTML={{__html: thankSectionValue}} />
				}

				{hasError &&
					<div className='by_lib_survey_error'>
						{hasError}
					</div>
				}

				{stepSurvey !== 2 && !isLast &&
					<div className="by_lib_survey_footer">
						{!isRequired &&
							<button type="button" className="by_lib_survey_button by_lib_survey_button_secondary" onClick={() => goToSkippedQuestion(panelSelected)}>
								{formSurveyStyles?.wording.skipButton || 'Skip'}
							</button>
						}
						<button
							disabled={!isNotClickable(panelSelected)}
							type="button"
							className="by_lib_survey_button by_lib_survey_button_primary"
							onClick={() => GoToNextQuestion(panelSelected)}
							// disabled={!handleGetIsClickable(panelSelected)}
						>
							{formSurveyStyles?.wording.nextButton || 'Next'}
						</button>
					</div>
				}
			</div>
		</ShadowDom>
	);

	if (!showWrapper) return (
		<div style={{position: 'relative'}}>
			{inner}
			{!showWrapper &&
				<button
					onClick={() => deleteAnswers()}
					style={{
						position: 'absolute',
						bottom: 0,
						left: 0,
						fontSize: '11px',
						color: '#acafc0'
					}}
				>
					{t('resetAnswers')}
				</button>
			}
		</div>
	);

	return (
		<div>
			<div className='flex'>
				<div className='flex_item_full'>
					<div className='h3'>{t('preview')}</div>
				</div>
				{stepSurvey !== 2 &&
					<div className='flex_item_fix ml_10'>
						<Btn
							color="secondary"
							style="outline"
							message={t('resetAnswers')}
							size="xs"
							light={true}
							onClick={() => deleteAnswers()}
						/>
					</div>
				}
			</div>
			<Article hasShadow={true} size={'s'} innerSize={'l'} hasPadding={false}>
				{inner}
			</Article>
		</div>
	);
}

function QuestionLabel({
	children
}) {

	return (
		<div className='by_lib_survey_question'>
			{children}
		</div>
	);
}
