import HttpServices from './HttpServices';

export default class crossSellUpSellServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
	}
	getProductAssociationList(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}product/association/${accountId}`, callbackSuccess, callbackError);
	}
	getProductAssociationDetailsList(accountId, itemId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}product/association/${accountId}/${itemId}`, callbackSuccess, callbackError);
	}
	getProductAssociationDetailsListRelations(accountId, itemId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}product/association/${accountId}/${itemId}/relations`, callbackSuccess, callbackError);
	}
	putProductAssociationDetailsList(accountId, itemId, listRef, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}product/association/${accountId}/${itemId}`, listRef, callbackSuccess, callbackError);
	}
	postProductAssociationDetailsList(accountId, listRef, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}product/association/${accountId}`, listRef, callbackSuccess, callbackError);
	}
	deleteProductAssociationDetailsList(accountId, itemId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}product/association/${accountId}/${itemId}`, callbackSuccess, callbackError);
	}

	//Crud Matching List
	getProductFeedTags(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}productfeed/${accountId}/tag`, callbackSuccess, callbackError);
	}
	getProductFeedCategory(accountId,tenantObj,callbackSuccess, callbackError) {
		let tenant = '';
		if(tenantObj){
			if(tenantObj.tenantSelected !== null){
				tenant = `?tenant=${tenantObj.tenantSelected}`;
				this.HttpServices.get(`${this.BO_API_URL}productfeed/${accountId}/category${tenant}`, callbackSuccess, callbackError);
			}else{
				this.HttpServices.get(`${this.BO_API_URL}productfeed/${accountId}/all_tenants_category`, callbackSuccess, callbackError);
			}
		}

	}

	/// Crud for algo
	getProductsShelving(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}productshelving/${accountId}/rule`, callbackSuccess, callbackError);
	}
	getProductsShelvingRules(accountId, ruleId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}productshelving/${accountId}/rule/${ruleId}`, callbackSuccess, callbackError);
	}
	putProductsShelvingRules(accountId, ruleId, rules, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}productshelving/${accountId}/rule/${ruleId}`, rules, callbackSuccess, callbackError);
	}
	postProductsShelvingRules(accountId, rules, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}productshelving/${accountId}/rule/`, rules, callbackSuccess, callbackError);
	}
	deleteProductsShelvingRules(accountId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}productshelving/${accountId}/rule/`, callbackSuccess, callbackError);
	}



}
