import {initInheritStyles} from './utils';

const scriptExport = function(props) {
	if (!cpg) return;

	var btn_wrapper = this;
	var btn = btn_wrapper.querySelector('a');
	if (!btn) return;

	var interaction = props.by_interaction_name || 'click_button';
	interaction = interaction.split(' ').join('_');
	interaction = interaction.split('.').join('_');
	interaction = encodeURIComponent(interaction);
	interaction = 'evtcpg:' + interaction;

	if (props.by_onclick == 'redirect' && props.by_redirect_url && props.by_redirect_new_tab) {
		btn.setAttribute('href', props.by_redirect_url);
		btn.setAttribute('target', '_blank');
	}

	btn.addEventListener('click', function(ev){
		if (props.by_onclick == 'redirect' && props.by_redirect_url) {
			if (props.by_redirect_new_tab) {
				BY.by_SendInteractionForCampaign(0, interaction, SId);
			} else {
				BY.by_SendInteractionForCampaignWithRedirection(0, interaction, props.by_redirect_url, SId);
			}
			return;
		}

		cpg.close();
		if (interaction) {
			BY.by_SendInteractionForCampaign(0, interaction, SId);
		}
		
	});
};

const icon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" viewBox="0 0 40 30">
  <defs>
    <clipPath id="clip-ico_button_grey">
      <rect width="40" height="30"/>
    </clipPath>
  </defs>
  <g id="ico_button_grey" clip-path="url(#clip-ico_button_grey)">
    <g id="Groupe_614" data-name="Groupe 614" transform="translate(-24 -16)">
      <g id="Rectangle_1169" data-name="Rectangle 1169" transform="translate(58 24) rotate(90)" fill="#e9eaf0" stroke="#acafc0" stroke-width="2">
        <rect width="14" height="28" rx="5" stroke="none"/>
        <rect x="1" y="1" width="12" height="26" rx="4" fill="none"/>
      </g>
      <rect id="Rectangle_1211" data-name="Rectangle 1211" width="12" height="2" rx="1" transform="translate(38 30)" fill="#acafc0"/>
    </g>
  </g>
</svg>
`;

export const componentButton = {
	model: {
		init() {
			this.listenTo(this, 'change:by_onclick', this.updateTraits);
		},
		updateTraits() {
			const onClick = this.get('by_onclick');
			const depends = ['by_redirect_url', 'by_redirect_new_tab'];
			depends.forEach(name => {
				const trait = this.getTrait(name);
				trait.view.el.style.display = (onClick == 'redirect') ? '' : 'none';
			});
		},
		defaults: {
			name: 'Button',
			droppable: false,
			editable: false,
			layerable: true,
			tagName: 'div',
			components: '<a data-gjs-type="button_inner"></a>',
			traits: [
				{
					type: 'select',
					name: 'by_onclick',
					label: 'On click',
					changeProp: true,
					options: [
						{ id: 'close', name: 'Close popup'},
						{ id: 'redirect', name: 'Redirect'},
					],
					tab: 'Interaction'
				},
				{
					type: 'text',
					name: 'by_interaction_name',
					label: 'Click interaction name',
					changeProp: true,
					placeholder: 'click_button',
					required: true,
					tab: 'Interaction'
				},
				{
					type: 'text',
					name: 'by_redirect_url',
					label: 'Redirect URL',
					changeProp: true,
					placeholder: 'https://www.example.com/',
					attributes: {style: 'display: none'},
					tab: 'Interaction'
				},
				{
					type: 'checkbox',
					name: 'by_redirect_new_tab',
					label: 'Open in new tab',
					changeProp: true,
					attributes: {style: 'display: none'},
					tab: 'Interaction'
				},
				{
					type: 'responsive',
					name: 'responsive',
					label: 'Responsive',
					changeProp: true,
					tab: 'responsive'
				}
			],
			stylable: ['margin'],
			style: {
				'display': 'flex',
				'margin-top': '8px',
				'margin-bottom': '8px',
				'margin-left': '0px',
				'margin-right': '0px',
				'text-align': 'left',
				'font-size': '16px',
			},
			'responsive': '',
			'by_onclick': 'close',
			'by_redirect_url': '',
			'by_redirect_new_tab': false,
			'by_interaction_name': 'click_button',
			'script-export': scriptExport,
			'script-props': [
				'by_onclick',
				'by_redirect_url',
				'by_redirect_new_tab',
				'by_interaction_name',
			],
			icon: icon
		}
	}
};

export const componentButtonInner = {
	model: {
		init() {
			initInheritStyles(this);
		},
		ineritedStyles: [
			{
				key: 'background-color',
				prop: 'background-color',
				type: 'color',
				label: 'Background color',
				value: '#269fb0',
				tab: 'style'
			},
			{
				key: 'color',
				prop: 'color',
				type: 'color',
				label: 'Text color',
				value: '#ffffff',
				tab: 'style'
			},
			{
				key: 'margin',
				prop: 'margin',
				type: 'select',
				label: 'Alignment',
				value: '0 auto 0 0',
				options: [
					{ id: '0 auto 0 0', name: 'Left'},
					{ id: '0 auto', name: 'Center'},
					{ id: '0 0 0 auto', name: 'Right'},
				],
				tab: 'style'
			},
			{
				key: 'height',
				prop: 'height',
				type: 'number',
				unit: 'px',
				label: 'Height',
				value: 50,
				tab: 'style'
			},
			{
				key: 'width',
				prop: 'width',
				type: 'button_width',
				label: 'Width',
				value: 'auto',
				min: 10,
				max: 400,
				tab: 'style'
			},
			{
				key: 'border-radius',
				prop: 'border-radius',
				type: 'number',
				unit: 'px',
				label: 'Radius',
				value: 6,
				tab: 'style'
			},
			{
				key: 'font-size',
				prop: 'font-size',
				type: 'number',
				unit: 'px',
				label: 'Font size',
				value: 16,
				tab: 'style'
			},
			{
				key: 'padding-left',
				prop: 'padding-left',
				type: 'number',
				unit: 'px',
				label: 'horizontal padding',
				value: 16,
				tab: 'style',
				syncProp: 'padding-right'
			},
		],
		defaults: {
			removable: false,
			draggable: false,
			droppable: false,
			selectable: false,
			layerable: false,
			hoverable: false,
			highlightable: false,
			editable: false,
			tagName: 'a',
			attributes: {
				class: 'by_btn by-js-button'
			},
			components: '<span data-gjs-type="button_text"></span>',
			stylable: [],
			traits: [],
			style: {
				'display': 'flex',
				'align-items': 'center',
				'justify-content': 'center',
				'text-decoration': 'none',
				'padding-top': '0',
				'padding-bottom': '0',
				'padding-left': '2em',
				'padding-right': '2em',
			}
		}
	}
};

export const componentButtonText = {
	extend: 'text',
	model: {
		defaults: {
			removable: false,
			draggable: false,
			droppable: false,
			selectable: false,
			layerable: false,
			hoverable: false,
			highlightable: false,
			editable: true,
			propagate: ['draggable', 'droppable', 'layerable', 'selectable'],
			tagName: 'a',
			attributes: {
				class: 'by_btn_txt by-js-button-text'
			},
			content: 'Type here',
			stylable: [],
			traits: [],
			style: {
				'text-align': 'center'
			}
		}
	}
};

