import React, { useState, useEffect, useRef } from 'react';
import { richTextOption, richTextOptionMinimal } from '../Utils/RichTextUtils';
import { Editor } from '@tinymce/tinymce-react';
import { useWysiwygEditorContext } from '../context/WysiwygEditorContext';

export default function RichText({
	property,
	data,
	onChange
}) {
	const [editorOpts, setEditorOpts] = useState(null);
	const { fontData } = useWysiwygEditorContext();
	const editorRef = useRef(null);

	const fontMigration = [
		{ old: 'Bodoni Moda', new: '\'BY Bodoni Moda\', serif'},
		{ old: 'Fira Sans', new: '\'BY Fira Sans\', sans-serif'},
		{ old: 'Inter', new: '\'BY Inter\', sans-serif'},
		{ old: 'Josefin Sans', new: '\'BY Josefin Sans\', sans-serif'},
		{ old: 'lato', new: '\'BY Lato\', sans-serif'},
		{ old: 'Montserrat', new: '\'BY Montserrat\', sans-serif'},
		{ old: 'Nunito Sans', new: '\'BY Nunito Sans\', sans-serif'},
		{ old: 'Open Sans', new: '\'BY Open Sans\', sans-serif'},
		{ old: 'pacifico', new: '\'BY Pacifico\', cursive'},
		{ old: 'Playfair Display', new: '\'BY Playfair Display\', serif'},
		{ old: 'Poppins', new: '\'BY Poppins\', sans-serif'},
		{ old: 'PT sans', new: '\'BY PT Sans\', sans-serif'},
		{ old: 'Raleway', new: '\'BY Raleway\', sans-serif'},
		{ old: 'roboto', new: '\'BY Roboto\', sans-serif'},
		{ old: 'Source Sans Pro', new: '\'BY Source Sans Pro\', sans-serif'},
	];

	const onEditorValueChange = (e) => {
		onChange(e);
	};
	
	useEffect(function(){
		let fonts = fontData.reduce((acc,font) => {
			acc += `${font.displayName}=${font.name},${font.fallback};`;
			return acc;
		},'');

		let tmpOptions = {};

		if (property.Editor.Config && property.Editor.Config == 'minimal') {
			tmpOptions = {...richTextOptionMinimal};
		} else {
			tmpOptions = {...richTextOption};
		}

		if (property.Editor.Toolbar) {
			tmpOptions.toolbar = property.Editor.Toolbar;
		}

		if (tmpOptions.font_formats) {
			tmpOptions.font_formats = 'Main font=inherit;' + tmpOptions.font_formats + fonts;
		}

		// Language : Does not work
		// const i18nL = localStorage.getItem('i18nL');
		// if (i18nL.includes('fr')) {
		// 	tmpOptions.language = 'fr';
		// }

		setEditorOpts(tmpOptions);
	
		if (data) {
			let newData = data;
			fontMigration.forEach(migr => {
				const regex = new RegExp(`font-family: ?'?${migr.old}'?(, sans-serif)?(, serif)?`, 'g');
				newData = newData.replace(regex, `font-family: ${migr.new}`);
			});
			if (newData !== data) {
				onChange(newData);
			}
		}

	},[fontData]);
	
	if (editorOpts && property.Editor.Theme && property.Editor.Theme == 'dark') {
		let content_css = editorOpts.content_css || [];
		content_css.push('dark');
		editorOpts.content_css = content_css;
	}

	if (editorRef.current && property.Editor.DisableShortcuts) {
		editorRef.current.addShortcut('meta+b', '', '');
		editorRef.current.addShortcut('meta+u', '', '');
		editorRef.current.addShortcut('meta+i', '', '');
	}
	return (
		<div>
			{editorOpts &&
				<Editor
					//apiKey="bjl49uswgxt3c5t7pl7dh6d41jyi57tp5icuisogwx80tfw9"
					apiKey='no-api-key'
					licenseKey="gpl"
					tinymceScriptSrc='/Scripts/tinymce/tinymce.min.js'
					init={editorOpts}
					value={data}
					onInit={(ev, editor) => editorRef.current = editor}
					onEditorChange={(value, editor) => onEditorValueChange(value)}
				/>
			}
		</div>
	);
}
