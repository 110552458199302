import React, { useState, useContext, useEffect } from 'react';
import Btn from '../../../../../Components/Btn';
import InputCustom from '../../../../../Components/InputCustom';
import Modal from '../../../../../Components/Modal';
import videosUtils from './videosUtils';
import { CreateBusinessOperationContext } from '../../Context/ContextCreateBusinessOperations';
import YoutubeWidget from './YoutubeWidget';
export default function AddVideo({
	accountId,
	businessOperationServices,
	systemServices,
	isOpen,
	setIsOpen
}) {
	const {
		addVideoModalIsOpen,
		setAddVideoModalIsOpen
	} = useContext(CreateBusinessOperationContext);

	const { checkErrors, getId, isCorrectUrlYoutube } = videosUtils();
	const [errors, setErrors] = useState([]);
	const [newUrl, setNewUrl] = useState('');
	const [videoData, setvideoData] = useState();
	const [isLoading, setisLoading] = useState(false);
	const handleChangeForm = (value) => {
		setErrors([]);
		setNewUrl(value);
	};

	const isAnError = (name) => {
		const errorsByName = errors.map(er => er.key);
		const isError = errorsByName.includes(name) ? errors.find(er => er.key === name).message : null;
		return isError;
	};

	const addUrl = async () => {
		const arrayOfErrors = checkErrors(newUrl);
		if (arrayOfErrors.length !== 0) {
			setErrors(arrayOfErrors);
			setisLoading(false);
		} else {
			const getIdYtb = await getId(newUrl);
			if (getIdYtb.type === 'error') {
				setErrors([getIdYtb]);
				setisLoading(false);
			} else {
				setvideoData(getIdYtb);
				setisLoading(false);
			}
		}
	};
	useEffect(() => {
		if (newUrl.length !== 0) {
			const inputValIsYoutubeVideo = isCorrectUrlYoutube(newUrl);
			if (inputValIsYoutubeVideo) {
				addUrl();
				setisLoading(true);
			} else {
				const arrayOfErrors = checkErrors(newUrl);
				setErrors(arrayOfErrors);
			}
		}
	}, [newUrl]);

	const resetDataVideo = () => {
		setNewUrl('');
		setvideoData();
	};

	return (
		<Modal
			isOpen={addVideoModalIsOpen}
			onClose={() => setAddVideoModalIsOpen(false)}
			width={600}
		>
			<div className="modal_header has_border">
				Enter video url
			</div>
			<div className="modal_section pos_rel flex_item_fix">
				<InputCustom
					value={newUrl}
					label="Accepted formats : youtube url"
					name="url"
					placeholder="Url like https://www.youtube.com/watch?v=Elf25fdfdA"
					onChange={(e) => handleChangeForm(e.target.value)}
					fullWidth={true}
					isAnError={isAnError('url')}
					blockClassName="no_margin"
				/>
				{isLoading &&
					<div className="mask">
						<div className="wheel"></div>
					</div>
				}
			</div>
			<YoutubeWidget
				resetDataVideo={resetDataVideo}
				videoData={videoData}
				accountId={accountId}
				businessOperationServices={businessOperationServices}
				systemServices={systemServices}
			/>
		</Modal>
	);
}
