import React, { useState, Fragment } from 'react';
import Btn from '../../Components/Btn';
import Dropdown from '../../Components/Dropdown';
import Listbox from '../../Components/Listbox';
import ListboxItem from '../../Components/ListboxItem';
import FilterDevices from './FilterDevices';
import { Trans, useTranslation } from 'react-i18next';
export default function ControlPannelContainer(props) {
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const [t, i18n] = useTranslation('reporting');
	return (
		<>
			{props.datainformation &&
				<Dropdown
					isOpen={dropdownIsOpen}
					setIsOpen={(e) => setDropdownIsOpen(true)}
					onHide={(e) => setDropdownIsOpen(false)}
					button={
						<button className='custom_input clickable w_auto m bg_white'>
							<span className="custom_input_value has_label">
								<span className="custom_input_value_label">Device</span>
								<span className="custom_input_placeholder">
									{props.device === FilterDevices.all && t('ChartPerformCampaign.AllDevices')}
									{props.device === FilterDevices.desktop && t('ChartPerformCampaign.Desktop')}
									{props.device === FilterDevices.mobile && t('ChartPerformCampaign.Mobile')}
								</span>
							</span>
							<span className='custom_input_arrow'></span>
						</button>
					}
				>
					<Listbox>
						<ListboxItem
							message={t('ChartPerformCampaign.AllDevices')}
							selected={props.device === FilterDevices.all}
							onClick={() => { props.changeDevice(FilterDevices.all); setDropdownIsOpen(false); }}
							disabled={props.datainformation.devices.length === 1}
						/>
						<ListboxItem
							message={t('ChartPerformCampaign.Desktop')}
							selected={props.device === FilterDevices.desktop}
							onClick={() => { props.changeDevice(FilterDevices.desktop); setDropdownIsOpen(false); }}
							disabled={!props.datainformation.devices.includes('desktop')}
						/>
						<ListboxItem
							message={t('ChartPerformCampaign.Mobile')}
							selected={props.device === FilterDevices.mobile}
							onClick={() => { props.changeDevice(FilterDevices.mobile); setDropdownIsOpen(false); }}
							disabled={!props.datainformation.devices.includes('mobile')}
						/>
					</Listbox>
				</Dropdown>
			}
		</>
	);
}