import React, {useState} from 'react';
import PopinConfirm from '../../../../Components/PopinConfirm';
import { onSiteContext } from '../context';
import Btn from '../../../../Components/Btn';
import classes from './CampaignVignette.module.css';

export default function CampaignVignette({image, campaign, setImages}) {
	const {
		systemServices,
		accountId,
		screenShotServices,
		setneedFetchList,
	} = onSiteContext();

	const [modalImageIsOppen, setModalImageIsOppen] = useState(false);
	const [confirmPopinIsOppen, setConfirmPopinIsOppen] = useState(false);

	const deleteImage = (id) => {
		setConfirmPopinIsOppen(false);
		screenShotServices
			.deleteCampaignImage(accountId, campaign.id, id)
			.then((success) => {
				setneedFetchList(true);
				setImages('');
			})
			.catch((err) => {
				console.log(err),
				systemServices.showError('An error occured during the delete image');
			});
	};

	return(
		<div className={`${classes.image} mr_15`}>
			<img
				width="100px"
				height="auto"
				src={image.screenShotUrl}
				onClick={()=>{setModalImageIsOppen(true);}}
			>
			</img>
			<i className={`${classes.deleteImage} fas fa-times-circle`} onClick={(e) => setConfirmPopinIsOppen(true)}></i>
			{ modalImageIsOppen && 
				(<div className={classes.modal} onClick={()=>{setModalImageIsOppen(false);}}>
					<img src={image.screenShotUrl}></img>
				</div>)
			}
			
			{	confirmPopinIsOppen && 
				(<PopinConfirm
					cancel={(e) => setConfirmPopinIsOppen(false)}
					title="Delete screenshot"
					message="Are you sure to delete the screenshot ?"
				>
					<Btn
						onClickFunction={(e)=> setConfirmPopinIsOppen(false)}
						message="Cancel"
						color="secondary"
						style="outline"
					/>
					<Btn
						onClickFunction={() => deleteImage(image.id)}
						message="Delete"
						color="alert"
						icon="fas fa-trash-alt"
					/>
				</PopinConfirm>)
			}
		</div>
	);
}