import React, { useState, useEffect, Fragment } from 'react';
import './AddKpiComponent.css';
import { formatIndicatorDisplayLabel } from '../IndicatorsUtil';
import Btn from '../../../Components/Btn';
import Radio from '../../../Components/Radio';
import Modal from '../../../Components/Modal';
import indicators, { getIndicatorByKey } from '../Indicators';
import { cleanInteractionLabel } from '../IndicatorsUtil';
import { Trans, useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import InputCustom from '../../../Components/InputCustom';

export default function AddKpiComponent(props) {
	const [t, i18n] = useTranslation('common');
	const [newKpi, setNewKpi] = useState('false');
	const [indicatorsToMap, setIndicatorsToMap] = useState([]);

	const [targetIndicators, setTargetIndicators] = useState([]);
	const [interactionsIndicators, setInteractionsIndicators] = useState([]);

	const [searchTerm, setSearchTerm] = useState('');
	const [pageOffset, setPageOffset] = useState(null);
	const pageSize = 50;

	/**
	 * Function to delete the keys selected on array of summary on list Modale
	 * @param {Array of Summary KPIs} reference 
	 * @param {Array of keys indicators} array 
	 */
	const deleteTheKey = (reference, array) => {
		for (let i = 0; i < reference.length; i++) {
			let newReference = reference && reference[i] && reference[i].key;
			for (let i = 0; i < array.length; i++) {
				array = array.filter(x => x.key != newReference);
			}
		}
		return array;
	};

	const filterByIndicatorOfCampaign = (reference) => {
		let arr = [];
		reference && reference.forEach(item => {
			if (indicatorsToMap.includes(item.key)) {
				arr.push(item);
			}
		});
		return arr;
	};

	useEffect(() => {
		setTargetIndicators(props.targetIndicators);
	}, [props.targetIndicators]);

	useEffect(() => {
		setInteractionsIndicators(props.interactionsIndicators);
	}, [props.interactionsIndicators]);
	
	useEffect(() => {
		setIndicatorsToMap(props.allIndicatorsofCampaign);
	}, [props.allIndicatorsofCampaign]);

	const BuildIndicators = (rawIndicators) => {
		for (let i = 0; i < rawIndicators.length; i++) {
			let rawIndicator = rawIndicators[i];			
			rawIndicator.label = getIndicatorByKey(rawIndicator.key)?.label ?? rawIndicator.key;
			rawIndicator.label = cleanInteractionLabel(rawIndicator.label);
			rawIndicator.value = rawIndicator.key;
		}
		return rawIndicators;
	};

	const search = () => {
		props.searchIndicators(searchTerm, (pageOffset ?? 0) * pageSize, pageSize, success => {
			const newTargetIndicators = BuildIndicators(success.filter(x => x.isTargetIndicator));
			setTargetIndicators(newTargetIndicators);
			const newInteractionIndicators = BuildIndicators(success.filter(x => x.isInteractionIndicator));
			setInteractionsIndicators(newInteractionIndicators);
			setIndicatorsToMap(success.map(x => x.key));
		}, error => {
			setIndicatorsToMap(props.allIndicatorsofCampaign);
		});
	};


	useEffect(() => {
		if (searchTerm.length == 0) {
			setIndicatorsToMap(props.allIndicatorsofCampaign);
		}
		const timer = setTimeout(() => {
			if (searchTerm.length > 0 || pageOffset != null) {
				search();
			} else {
				setIndicatorsToMap(props.allIndicatorsofCampaign);
			}
		}, 500);
		return () => {
			clearTimeout(timer);
		};
	}, [searchTerm]);

	useEffect(() => {
		if (pageOffset != null) {
			search();
		}
	}, [pageOffset]);


	let categoryDisplayData = indicators.filter(item => item.category === 'Display Data');
	if (props.newSecondaryIndicators.length) {
		categoryDisplayData = props.newSecondaryIndicators && deleteTheKey(props.newSecondaryIndicators, categoryDisplayData);
		categoryDisplayData = categoryDisplayData && filterByIndicatorOfCampaign(categoryDisplayData);
	} else {
		categoryDisplayData = props.SummaryIndicatorsArray && deleteTheKey(props.SummaryIndicatorsArray, categoryDisplayData);
		categoryDisplayData = categoryDisplayData && filterByIndicatorOfCampaign(categoryDisplayData);
	}

	let categoryConversionData = indicators.filter(item => item.category === 'Conversion Data');
	if (props.newSecondaryIndicators.length) {
		categoryConversionData = props.newSecondaryIndicators && deleteTheKey(props.newSecondaryIndicators, categoryConversionData);
		categoryConversionData = categoryConversionData && filterByIndicatorOfCampaign(categoryConversionData);
	} else {
		categoryConversionData = props.SummaryIndicatorsArray && deleteTheKey(props.SummaryIndicatorsArray, categoryConversionData);
		categoryConversionData = categoryConversionData && filterByIndicatorOfCampaign(categoryConversionData);
	}

	let categoryOther = indicators.filter(item => item.category === 'Other');
	if (props.newSecondaryIndicators.length) {
		categoryOther = props.newSecondaryIndicators && deleteTheKey(props.newSecondaryIndicators, categoryOther);
		categoryOther = categoryOther && filterByIndicatorOfCampaign(categoryOther);
	} else {
		categoryOther = props.SummaryIndicatorsArray && deleteTheKey(props.SummaryIndicatorsArray, categoryOther);
		categoryOther = categoryOther && filterByIndicatorOfCampaign(categoryOther);
	}

	let categoryTarget = targetIndicators.map(el => ({ ...getIndicatorByKey(el.key), key: el.key }));
	if (props.newSecondaryIndicators.length) {
		categoryTarget = props.newSecondaryIndicators && deleteTheKey(props.newSecondaryIndicators, categoryTarget);
		categoryTarget = categoryTarget && filterByIndicatorOfCampaign(categoryTarget);
	} else {
		categoryTarget = props.SummaryIndicatorsArray && deleteTheKey(props.SummaryIndicatorsArray, categoryTarget);
		categoryTarget = categoryTarget && filterByIndicatorOfCampaign(categoryTarget);
	}
	let categoryInteraction = interactionsIndicators.map(el => ({ ...getIndicatorByKey(el.key), key: el.key }));//.filter(i => !i.label || !i.label.includes('='))
	if (props.newSecondaryIndicators.length) {
		categoryInteraction = props.newSecondaryIndicators && deleteTheKey(props.newSecondaryIndicators, categoryInteraction);
		categoryInteraction = categoryInteraction && filterByIndicatorOfCampaign(categoryInteraction);
	} else {
		categoryInteraction = props.SummaryIndicatorsArray && deleteTheKey(props.SummaryIndicatorsArray, categoryInteraction);
		categoryInteraction = categoryInteraction && filterByIndicatorOfCampaign(categoryInteraction);
	}

	/**
	 * Change value of Kpi to Add
	 * @param {Value of the radio Button} e 
	 */
	const handleChangeKpi = (e) => {
		setNewKpi(e.target.value);
	};

	/**
	 *  Function for add kpi in the array of summary
	 * Conditional if/else for the push in arrayOfKeys
	 * @param {*} closeModal close the modal with change status of boolean / from the component resultheader
	 * @param {*} rerender  rerender function on result header component for refetch the indicztors in the array // from the component resultheader
	 */

	const AddItemOnArrayOfSummaryIndicators = (closeModal, rerender) => {
		let arrayOfKeys = [];
		if (props.newSecondaryIndicators.length) {
			for (let i in props.newSecondaryIndicators) {
				if (props.newSecondaryIndicators[i].key)
					arrayOfKeys.push(props.newSecondaryIndicators[i].key);
			}
		}
		else {
			for (let i in props.SummaryIndicatorsArray) {
				if (props.SummaryIndicatorsArray[i].key)
					arrayOfKeys.push(props.SummaryIndicatorsArray[i].key);
			}
		}
		arrayOfKeys.push(newKpi);
		rerender(arrayOfKeys);
		let summaryIndicators = { Keys: arrayOfKeys };
		summaryIndicators && props.updateSummaryIndicators(summaryIndicators, error =>
			props.systemServices.showError(error));
		closeModal();
		setNewKpi();
		uncheckRadioButtons();
	};

	/**
	 * Function for clear the radio buttons when confirm button is clicked
	 */
	const uncheckRadioButtons = () => {
		let ele = document.getElementsByName('kpi');
		for (var i = 0; i < ele.length; i++)
			ele[i].checked = false;
	};

	const goBackward = () => {
		setPageOffset(x => Math.max(0, x - 1));
	};

	const goForward = () => {
		setPageOffset(x => x + 1);
	};

	return (
		<Modal
			isOpen={props.modaleView}
			onClose={props.hideModal}
			width="650">
			<div className="modal_header has_border">
				{t('AddKpiComponent.addKpi')}
				<div className="modal_header_desc">{t('AddKpiComponent.chooseKpi')}</div>
			</div>
			{props.allIndicatorsofCampaign.length > 40 &&
				<div className='modal_section_s modal_section_grey flex_item_fix flex'>
					<div className='flex_item_full'>
						<InputCustom
							value={searchTerm}
							placeholder="Search"
							color='white'
							autoFocus={true}
							fullWidth={true}
							onChange={(e) => setSearchTerm(e.target.value)}
							clearable={true}></InputCustom>
					</div>
					<div className='flex_item_fix ml_10'>
						<div className='btn_switch'>
							<Btn
								icon="fas fa-chevron-left"
								onClick={goBackward}
								disabled={pageOffset <= 0}
								style="outline"
								color="secondary"
								horizontalSize="xxs"
							/>
							<Btn
								icon="fas fa-chevron-right"
								onClick={goForward}
								disabled={indicatorsToMap.length == 0}
								style="outline"
								color="secondary"
								horizontalSize="xxs"
							/>
						</div>
					</div>
				</div>
			}
			<div className="modal_body">
				<div className="add_kpi">
					{categoryDisplayData && categoryDisplayData.length > 0 &&
						<div className="add_kpi_group">
							<div className="add_kpi_group_label">{t('AddKpiComponent.Display')} </div>
							<ul className="add_kpi_group_list">
								{categoryDisplayData.map((item, index) =>
									<li key={index}>
										<Radio
											name="kpi"
											value={item.key}
											onChange={handleChangeKpi}>
											{item.label}
											{item.infos &&
												<Fragment>
													<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
													<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
												</Fragment>
											}
										</Radio>
									</li>
								)}
							</ul>
						</div>
					}
					{categoryConversionData && categoryConversionData.length > 0 &&
						<div className="add_kpi_group">
							<div className="add_kpi_group_label">{t('AddKpiComponent.ConversionData')}</div>
							<ul className="add_kpi_group_list">
								{categoryConversionData.map((item, index) =>
									<li key={index}>
										<Radio
											name="kpi"
											value={item.key}
											onChange={handleChangeKpi}>
											{item.label}
											{item.infos &&
												<Fragment>
													<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
													<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
												</Fragment>
											}
										</Radio>
									</li>
								)}
							</ul>
						</div>
					}
					{categoryOther && categoryOther.length > 0 &&
						<div className="add_kpi_group">
							<div className="add_kpi_group_label">{t('AddKpiComponent.Other')}</div>
							<ul className="add_kpi_group_list">
								{categoryOther.map((item, index) =>
									<li key={index}>
										<Radio
											name="kpi"
											value={item.key}
											onChange={handleChangeKpi}>
											{item.label}
											{item.infos &&
												<Fragment>
													<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
													<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
												</Fragment>
											}
										</Radio>
									</li>
								)}
							</ul>
						</div>
					}
					{categoryTarget && categoryTarget.length > 0 &&
						<div className="add_kpi_group flex_item_fix">
							<div className="add_kpi_group_label">{t('AddKpiComponent.Target')}</div>
							<ul className="add_kpi_group_list">
								{categoryTarget.map((item, index) =>
									<li key={index}>
										<Radio
											name="kpi"
											value={item.key}
											onChange={handleChangeKpi}>
											{item.label}
											{item.infos &&
												<Fragment>
													<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
													<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
												</Fragment>
											}
										</Radio>
									</li>
								)}
							</ul>
						</div>
					}
					{categoryInteraction && categoryInteraction.length > 0 &&
						<div className="add_kpi_group flex_item_fix">
							<div className="add_kpi_group_label">{t('AddKpiComponent.InteractionData')}</div>
							<ul className="add_kpi_group_list">
								{categoryInteraction.map((item, index) =>
									<li key={index}>
										<Radio
											name="kpi"
											value={item.key}
											onChange={handleChangeKpi}>
											{formatIndicatorDisplayLabel(item)}
											{item.infos &&
												<Fragment>
													<ReactTooltip id='custom-color-no-arrow' backgroundColor='rgba(103, 213, 215, 0.89)' delayHide={100} effect='float' />
													<i data-for='custom-color-no-arrow' className="cb_label_tooltip_target fas fa-info-circle" data-tip={item.infos}></i>
												</Fragment>
											}
										</Radio>
									</li>
								)}
							</ul>
						</div>
					}
				</div>
			</div>
			<div className="modal_footer has_border flex">
				<div className='flex_item_full'></div>
				<div className='flex_item_fix'>
					<Btn
						message={t('AddKpiComponent.Cancel')}
						style="outline"
						color="secondary"
						onClickFunction={() => props.hideModal()}
					/>
					<Btn
						message={t('AddKpiComponent.Confirm')}
						onClickFunction={newKpi !== 'false' ? () => AddItemOnArrayOfSummaryIndicators(props.hideModal, props.reRender) : () => props.hideModal()}
					/>
				</div>
			</div>
		</Modal>
	);
}





