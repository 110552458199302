const MessageLevel = {
	SUCCESS: 'success',
	ERROR: 'danger'
};

export default class SystemServices {
	constructor($rootScope, $timeout) {
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.BASE_URL = 'https://localhost:5001';
		this.showSuccess = this.showSuccess.bind(this);
		this.showError = this.showError.bind(this);
		this.showMessage = this.showMessage.bind(this);
	}

	showSuccess(message = 'Success') {
		this.showMessage(message, MessageLevel.SUCCESS);
	}

	showError(message = 'An error occured. Please contact us.') {
		this.showMessage(message, MessageLevel.ERROR);
	}

	showWarning(message = 'Warning') {
		this.showMessage(message, MessageLevel.ERROR);
	}

	showMessage(message, messageLevel) {

		this.sendMessage = function(msg, level){
			console.log('message', msg, level);

			this.$rootScope.$broadcast('event:alert-messageSent', {
				type: level,
				msg: msg
			});
		};

		if (this.$timeout){
			this.$timeout(() => {
				this.sendMessage(message, messageLevel);
			}, 100);
		}
		else{
			this.sendMessage(message, messageLevel);
		}


		this.$rootScope.$on('event:alert-messageSent', function() {
			console.log('event triggered');
		});
	}
}