import React,{useState, useEffect} from 'react';
import styles from './CreateHttp.module.css';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';
import IconBtn from '../../../Components/IconBtn';
import Fieldset from '../../../Components/Fieldset';
import { v4 as uuidv4 } from 'uuid';

type Header = {
    value: string,
    key: string, 
	id: string
}

export default function AdditionnalHeaders({additionalHeaders,
	elementToSave,
	setelementToSave
}) {

	const [element, setelement] = useState<any>(<></>);

	const [first, setfirst] = useState(true);

	function  handleChangeElement(evt,id){
		evt.persist();
		const name = evt.target.name;
		if(elementToSave){
			const newValues  = [...elementToSave];
			const findElement = elementToSave.find(x => x.id === id);
			const i = newValues.indexOf(findElement);
			newValues[i] = {
				...newValues[i],
				[name]: evt.target.value
			};
			setelementToSave(newValues);
		}
	}
	function transformToObjectReadable(obj){
		const keyObj = Object.keys(obj);
		const arrayOfHeaders = keyObj.map(element => {
			
			const newHeader: Header = {
				key: element,
				value: obj[element],
				id: uuidv4()
			};
			return newHeader;
		});
		return arrayOfHeaders;
	}
	function transformHeaders(headers){
		if(!headers || !additionalHeaders) return;
		else{
			const arrayOfObjects = transformToObjectReadable(additionalHeaders);
			setelementToSave(arrayOfObjects);
		}
	}

	useEffect(()=>{
		if(first && additionalHeaders){
			transformHeaders(additionalHeaders);
			setfirst(false);
		}
	},[first]);
	function handleAddHeader(){
		const newHeader: Header = {
			key: '',
			value: '',
			id: uuidv4()
		};
		let mergeObj:Array<Header> = [];
		if(elementToSave ){
			mergeObj = [...elementToSave,newHeader];
		}else{
			mergeObj = [newHeader];
		}
		setelementToSave(mergeObj);

	}
	function handleDeleteHeader(h){
		let mergeObj:Array<Header> = [];
		if(elementToSave ){
			mergeObj = elementToSave.filter(x=>x.id !== h);
			setelementToSave(mergeObj);
		}
	}
	function constructInputs(arrayOfObjects){
		const newHeaderArray = <> {arrayOfObjects && arrayOfObjects.map((el) => {
			return (
				<div className={styles.row} key={el.id}>
					<div className={styles.col}>
						<InputCustom
							onChange={(ev) => handleChangeElement(ev,el.id)}
							autoFocus={true}
							value={el.key || ''}
							type="text"
							placeholder="Key"
							fullWidth={true}
							rows={2}
							blockClassName="xl"
							name="key"
						/>
					</div>
					<div className={styles.col}>
						<InputCustom
							onChange={(ev) => handleChangeElement(ev,el.id)}
							value={el.value || ''}
							type="text"
							placeholder="Value"
							fullWidth={true}
							rows={2}
							blockClassName="xl"
							name="value"
						/>
					</div>
					<div className={styles.col}>
						<IconBtn 
							icon="fas fa-trash"
							onClick={()=>handleDeleteHeader(el.id)}
							hoverColor='alert'
							size="s"
						/>
					</div>
				</div>
			);
		})}</>;
		setelement(newHeaderArray);
	}
	useEffect(()=>{
		if(elementToSave){
			constructInputs(elementToSave);
		}
	},[elementToSave]);



	return (
		<Fieldset label="Additionnal headers">
			{element}
			<div className='mt_5'>
				<Btn
					onClick={() => handleAddHeader()}
					message="Add header"
					color="primary"
					style="link"
					icon="fas fa-plus-circle fa-sm"
				/>
			</div>
		</Fieldset>
	);
}
