import React, {useState, useEffect} from 'react';
import {useFormContext} from '../FormSurvey/ContextFormAndSurvey';
import { useTranslation } from 'react-i18next';
import Fieldset from '../../Components/Fieldset';
import SelectOpts from './SelectOpts';
import controlTypes from './controlTypes';
import styles from './FormCustomMultipleControls.module.css';

function ChoicesSelect({
	surveyInfos,
	type,
	controls,
	id,
	currentS
}) {

	const [t] = useTranslation(['survey', 'utils']);
	const {updatePanel,listOfIdQuestion} = useFormContext();
	const [selections, setselection] = useState([
		{
			label: 'Question suivante',
			value: 'next'
		}
	]);

	const [isMultiChoiceSelected, setisMultiChoiceSelected] = useState(false);
	const [multipleChoices, setmultipleChoices] = useState(<></>);
	
	function handleChangeValue (choice) {	
		const surveyInfosV = {...surveyInfos, nextQuestion: choice };
		const state = {surveyInfos : {...surveyInfosV}};
		updatePanel(id, null, state);
	}

	function handleChangeValueSkiped (choice) {	
		const surveyInfosV = {...surveyInfos, ignoreQuestion: choice };
		const state = {surveyInfos : {...surveyInfosV}};
		updatePanel(id, null, state);
	}

	function handleChangeValueMultiple (choice,elementControl,s) {	
		const current = {...s};
		const newtControl = current.multipleQuestionsBasedOnAnswer.filter(x=> x.controlId !== elementControl.id);

		const nexCtrl = [...newtControl,  {
			controlId : elementControl.id,
			nextQuestion: choice
		}];

		const surveyInfosV = {...surveyInfos, multipleQuestionsBasedOnAnswer: nexCtrl};
		const state = {surveyInfos : {...surveyInfosV}};
		updatePanel(id, null, state);
	}

	function getTypeIcon(id) {
		const identifier = parseInt(id, 10);
		const ctrl = controlTypes.find(controlType => controlType.id === identifier);
		if(ctrl){
			return ctrl.icon;
		}else{
			return;
		}
	}

	useEffect(()=>{
		if(listOfIdQuestion && surveyInfos){
			const arr = listOfIdQuestion.filter(l=>l.id !== id).map(x => {
				return {
					label: t('questionN+label', {n: x.idInList, label: x.question}),
					value: x.id,
					icon: getTypeIcon(x.type)
				};
			});
			arr.unshift({
				label: t('thankYouMessage'),
				value: 'end'
			});
			if(type === 4 || type === 6 || type === 10 || type === 11){
				arr.unshift({
					label: t('questionBasedOnAnswer'),
					value: 'basedOn'
				});
			}
			arr.unshift({
				label: t('nextQuestion'),
				value: 'next'
			});
			setselection(arr);

		}
	},[listOfIdQuestion, surveyInfos]);

	useEffect(()=>{
		if(controls && controls.length > 0) {
			const arrayOfControls = controls?.map((x,idx) => {
				const nbOpt = idx + 1;
				return (
					<div key={x.id} className={styles.anwser_row}>
						<div className={styles.anwser_col_nb}>
							{t('answerN', {n: nbOpt})}
						</div>
						<div className={styles.anwser_col_select}>
							<SelectOpts
								surveyInfos={surveyInfos}
								selections={selections.filter(x=>x.value !== 'basedOn')}
								isMultiple={true}
								elementControl={x}
								handleChangeValue={(e,x)=>handleChangeValueMultiple(e,x,surveyInfos)}
							
							/>
						</div>
					</div>
				);
			});
			setmultipleChoices(arrayOfControls);
		}
	},[controls, listOfIdQuestion, surveyInfos, isMultiChoiceSelected, selections]);

	useEffect(()=>{
		if(type && (type === 6 || type === 4 || type == 10 || type == 11)){ 
			setisMultiChoiceSelected(true);
		}else{
			setisMultiChoiceSelected(false);
		}
	},[type, surveyInfos]);

	return (
		<div>
			<Fieldset label={t('whatIsNextQuestion')} labelClassName='s_14 grey_0'>
				{
					<SelectOpts
						surveyInfos={surveyInfos}
						selections={selections}
						isMultiple={false}
						elementControl={null}
						handleChangeValue={(e)=>handleChangeValue(e)}
					/>
				}
				{isMultiChoiceSelected && surveyInfos.nextQuestion === 'basedOn' &&
					<div className='mt_15'>
						{multipleChoices}
					</div>
				}
			</Fieldset>

			{!currentS.required &&
				<Fieldset label={t('nextQuestionIgnored')} labelClassName='s_14 grey_0'>
					<SelectOpts
						isIgnoreQuestion={true}
						surveyInfos={surveyInfos}
						selections={selections.filter(x=>x.value !== 'basedOn')}
						isMultiple={false}
						elementControl={null}
						handleChangeValue={(e)=>handleChangeValueSkiped(e)}
					/>
				</Fieldset>
			}
		</div>
	);
}

export default ChoicesSelect;