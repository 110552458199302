import React from 'react';
import Modal from '../../Components/Modal';
import { ReportGeneratorContextProvider } from './Context';
import ReportGeneratorSteps from './ReportGeneratorSteps';
import './ReportGeneratorModal.css';

export default function ReportGeneratorModal({
	systemServices,
	accountId,
	isOpen,
	onClose,
	$http,
	AuthServices,
	$routeParams,
	$rootScope
}) {

	return (
		<ReportGeneratorContextProvider
			accountId={accountId}
			systemServices={systemServices}
			$http={$http}
			AuthServices={AuthServices}
			$routeParams={$routeParams}
			$rootScope={$rootScope}
		>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				height={900}
				width={1200}
			>
				<ReportGeneratorSteps />
			</Modal>
		</ReportGeneratorContextProvider>
	);

}