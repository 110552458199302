import React, { useState, useEffect, Fragment } from 'react';
import formatMoment from '../../../../Constants/FormatMoment';
import CampaignInformations from './CampaignInformations';
import CampaignStatus from '../UI/CampaignStatus';
import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';
import TableRowItem from '../../../../Components/Table/TableRowItem';
import TableColItem from '../../../../Components/Table/TableColItem';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import DropDownAction from '../UI/DropDownAction';
import CampaignLabel from '../UI/CampaignLabel';
import CampaignItemExtend from '../UI/CampaignItemExtend';
import { onSiteContext } from '../context';
import useFeature from '../../../../../flags/useFeature';
import { useTranslation } from 'react-i18next';
import renderJsxTag from '../../../../Translation/translation-utils';

const optionCss = {
	gridTemplateColumns: '2.5fr 1fr .6fr 1.1fr .9fr .5fr',
};

export default function CampaignItem({
	campaign,
	refreshCampaign,
	impressionsMode,
	scenarioLabels
}) {
	const {
		accountId,
		permissionsAccount,
		$routeParams,
		IpAdressesServices,
		isAdmin,
		openCloseModalTestMode,
		openModalCampaignLabels,
		changeStatusOfCampaign,
		loadingCampaign
	} = onSiteContext();
	const [t, i18n] = useTranslation('common');
	const { formatDateToLocal } = formatMoment;
	if (!campaign) {
		return (<></>);
	}

	const { features } = useFeature();
	const [statusAction, setstatusAction] = useState(<></>);
	const [refreshButton, setRefreshButton] = useState(<></>);
	const [modificationCampaign, setmodificationCampaign] = useState(<></>);
	const [debugDropdownIsOpen, setDebugDropdownIsOpen] = useState(false);
	const [editDropdownIsOpen, setEditDropdownIsOpen] = useState(false);
	const [canAccessToStatistics, setcanAccessToStatistics] = useState('');
	const [canAccessTestMode, setcanAccessTestMode] = useState(false);
	const [actionsDropdownIsOppen, setActionsDropdownIsOppen] = useState(false);
	const [permissionsUser, setPermissionsUser] = useState({});
	const [devices, setdevices] = useState([]);
	const idTooltip = uuidv4();
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		if (campaign) {
			const listOfCampaignPermission = (campaign.currentUserPermissions || [])
				.reduce((acc, perm) => ({ ...acc, [perm.permission]: perm.isAllowed }), {});
			setPermissionsUser(listOfCampaignPermission);
		}
	}, [campaign]);
	// const copyToClipBoard = (value) => {
	//   navigator.clipboard.writeText(value);
	// }

	const hasAccountPermission = (permission) =>
		isAdmin || (permissionsAccount && (permissionsAccount.find(perm => perm.Name === permission) || {}).Value == true);
	const hasPermission = (permission) =>
		isAdmin || (hasAccountPermission(permission) && permissionsUser && permissionsUser[permission]);


	const statusPermToRefresh = () => {
		if (isAdmin || (permissionsUser && permissionsUser.CAMPAIGNS_CAN_SUSPEND)) {
			setRefreshButton(
				<Btn
					icon="fas fa-redo"
					color="secondary"
					style="outline"
					size="xs"
					onClick={() => refreshCampaign(accountId, campaign.id)}
					dataFor={idTooltip}
					dataTip={'Refresh campaign'}
				/>
			);
		} else {
			setRefreshButton(<></>);
		}
	};

	const statusPermToRender = () => {
		if (!campaign.isStopped) {
			if (hasPermission('CAMPAIGNS_CAN_SUSPEND')) {
				setstatusAction(
					<a className="icon_btn s"
						onClick={() => changeStatusOfCampaign(true, campaign)}
						data-for={idTooltip}
						data-tip="Pause campaign" >
						<i className="fa fa-pause"></i>
					</a>
				);
			}
		} else {
			if (hasPermission('CAMPAIGNS_CAN_SUSPEND')) {
				setstatusAction(
					<a className="icon_btn s"
						onClick={() => changeStatusOfCampaign(false, campaign)}
						data-for={idTooltip}
						data-tip="Start campaign">
						<i className="fa fa-play"></i>
					</a>
				);
			}
		}
	};


	const getDuplicatedCampaignEditorPath = (campaignId) => {
		const isClassicEditor = !campaign.editor || campaign.editor === 'classic';
		if (isClassicEditor) {
			let url = '/Campaigns/Create/' + campaignId;
			if ($routeParams && $routeParams.ka && isAdmin) {
				url += '?ka=' + $routeParams.ka + '&ku=' + $routeParams.ku;
			}
			return url;
		}
		else {
			const url = '/Campaigns/Editor/' + campaignId;
			const query = [];
			query.push('from=Onsite/Dashboard');
			if ($routeParams && $routeParams.ka && isAdmin) {
				query.push('ka=' + $routeParams.ka);
				query.push('ku=' + $routeParams.ku);
			}
			query.push('edit=info');
			return url + '?' + query.join('&');
		}
	};
	const getTransformedToNativeAppCampaignEditorPath = (campaignId) => {		
		const url = '/Campaigns/Editor/' + campaignId;
		const query = [];
		query.push('from=Onsite/Dashboard');
		if ($routeParams && $routeParams.ka && isAdmin) {
			query.push('ka=' + $routeParams.ka);
			query.push('ku=' + $routeParams.ku);
		}
		query.push('edit=info');
		return url + '?' + query.join('&');	
	};


	const statusModificationTorender = () => {
		if (hasPermission('CAMPAIGNS_CAN_CREATE')) {
			const isClassicEditor = !campaign.editor || campaign.editor === 'classic';
			if (isClassicEditor) {
				let url = '/Campaigns/Create/' + campaign.id;
				if ($routeParams && $routeParams.ka) {
					url += '?ka=' + $routeParams.ka + '&ku=' + $routeParams.ku;
				}
				setmodificationCampaign(
					<Btn
						color="secondary"
						style="outline"
						size="xs"
						icon="fas fa-pen"
						data-for={idTooltip}
						data-tip="Edit campaign"
						href={url}
					/>
				);
			}
			else {
				const url = '/Campaigns/Editor/' + campaign.id;
				const query = [];
				query.push('from=Onsite/Dashboard');
				if ($routeParams && $routeParams.ka) {
					query.push('ka=' + $routeParams.ka);
					query.push('ku=' + $routeParams.ku);
				}

				const isCodePatch = campaign.editor && campaign.editor == 'patch/code';
				const isGraphPatch = campaign.editor && campaign.editor == 'patch/graph';
				const isAbTest = campaign.editor && campaign.editor == 'abtest/redirection';

				const toQueryString = (moreQuery) => '?' + [...query, ...moreQuery].join('&');
				setmodificationCampaign(
					<>
						<Dropdown
							isOpen={editDropdownIsOpen}
							setIsOpen={(e) => setEditDropdownIsOpen(true)}
							onHide={(e) => setEditDropdownIsOpen(false)}
							button={
								<Btn
									style="outline"
									color="secondary"
									arrow="true"
									size="xs"
									icon="fas fa-pen"
								/>
							}
						>
							<ul className="listbox">
								<li>
									<a className="listbox_item" href={url + toQueryString(['edit=format'])}>
										{isAbTest &&
											<>
												<i className="fas fa-code listbox_item_icon"></i>
												{t('CampaignItem.editExperiment')}
											</>
										}
										{isCodePatch &&
											<>
												<i className="fas fa-code listbox_item_icon"></i>
												{t('CampaignItem.editCodePatch')}
											</>
										}
										{isGraphPatch &&
											<>
												<i className="fas fa-fill-drip listbox_item_icon"></i>
												{t('CampaignItem.editGraphic')}
											</>
										}
										{!isCodePatch && !isGraphPatch && !isAbTest &&
											<>
												<i className="fas fa-palette listbox_item_icon"></i>
												{t('CampaignItem.editDesign')}
											</>
										}
									</a>
								</li>
								<li className="hr"></li>
								<li>
									<a className="listbox_item" href={url + toQueryString(['edit=triggers'])}>
										<i className="fas fa-bolt listbox_item_icon"></i>
										{renderJsxTag(t('CampaignItem.EditDisplayRules'))}
									</a>
								</li>
								<li className="hr"></li>
								<li>
									<a className="listbox_item" href={url + toQueryString(['edit=info'])}>
										<i className="fas fa-info-circle listbox_item_icon"></i>
										{t('CampaignItem.EditCampaign')}
									</a>
								</li>
							</ul>
						</Dropdown>
					</>
				);
			}
		}
	};
	const canAccessStatistics = () => {
		const statisticsAcess = hasAccountPermission('STATISTICSCAMPAIGNS_CAN_ACCESS');
		if (statisticsAcess) {
			let url = '/Dashboard/ShowStats/' + campaign.id;
			if ($routeParams && $routeParams.ka) {
				url += '?ka=' + $routeParams.ka + '&ku=' + $routeParams.ku;
			}
			setcanAccessToStatistics(url);
		}
	};
	const canAccessTotestMode = () => {
		const statisticsAcess = hasAccountPermission('CAMPAIGNS_TEST_MODE');
		if (statisticsAcess) {
			setcanAccessTestMode(true);
		}
	};
	useEffect(() => {
		canAccessStatistics();
		canAccessTotestMode();
	}, [permissionsAccount]);
	useEffect(() => {
		statusPermToRender();
		statusModificationTorender();
	}, [permissionsUser, editDropdownIsOpen]);

	useEffect(() => {
		statusPermToRefresh();
	}, [permissionsUser]);

	useEffect(() => {
		if (campaign) {
			const campaignDevices = campaign.devices.map(device => device.deviceId);
			setdevices(campaignDevices);
		}
	}, [campaign]);

	const getDateLongFormat = (date) => {
		date = formatDateToLocal(date);
		var six_month = Date.now();
		six_month = six_month - (182 * 24 * 60 * 60 * 1000);
		if (date < six_month) {
			return 'D MMM YYYY';
		}
		return 'D MMM';
	};

	const modificationDateFormat = getDateLongFormat(campaign.modificationDate);

	return (
		<>
			<ReactTooltip
				id={idTooltip}
				backgroundColor="black"
				effect="solid"
				className="labelKpi"
			/>
			<TableRowItem
				specialClasseName={'table_body_row'}
				styleObj={optionCss}
				setIsHovered={setIsHovered}>
			
				<TableColItem specialClasseName={'table_col_name'}>
					<div>
						<CampaignInformations
							campaign={campaign}
							devices={devices}
							isHovered={isHovered}
						/>
						{isAdmin && scenarioLabels && scenarioLabels.flatMap(x => x.labels).length > 0 &&(
							<ul
								className="campaign_label_list mt_3 inline"
							>
								{scenarioLabels.flatMap(x => x.labels).map((label, key) => (
									<CampaignLabel
										key={key}
										label={label}
										showToolTip={false}
									/>
								))}
							</ul>
						)}
						{campaign.labels && campaign.labels.length > 0 && (
							<ul
								className="campaign_label_list mt_3 inline"
								onClick={() => openModalCampaignLabels(true, campaign)}
							>
								{campaign.labels.map((label, key) => (
									<CampaignLabel
										key={key}
										label={label}
									/>
								))}
							</ul>
						)}
					</div>
				</TableColItem>
				<TableColItem>
					<CampaignStatus campaign={campaign} />
				</TableColItem>
				<TableColItem>
					{devices.includes('D_SMARTPHONE') && (
						<i
							data-for={idTooltip}
							className="fas fa-mobile-alt grey_2"
							data-tip="Mobile"
						></i>
					)}
					{devices.includes('D_PC') && (
						<i
							data-for={idTooltip}
							className="fas fa-desktop grey_2"
							data-tip="Desktop"
						></i>
					)}
				</TableColItem>
				<TableColItem>
					{impressionsMode === 'last30' && (
						<>{campaign.impressionsLast30DaysValueDisplayedAbsolute || '-'}</>
					)}
					{impressionsMode === 'lastlast30' && (
						<>
							{campaign.impressionsLastLast30DaysValueDisplayedAbsolute || '-'}
						</>
					)}
				</TableColItem>
				<TableColItem>
					{formatDateToLocal(campaign.modificationDate).format(
						modificationDateFormat
					)}
				</TableColItem>
				<TableColItem specialClasseName="flex_justify_end pos_rel">
					<div
						className={
							debugDropdownIsOpen ||
								editDropdownIsOpen ||
								actionsDropdownIsOppen
								? 'campaign_float_actions_visible'
								: ''
						}
					>
						<div className="btn_group campaign_float_actions">
							{!campaign.campaignDebugLinks ||
								(campaign.campaignDebugLinks.length == 0 && (
									<Btn
										message={t('CampaignItem.Preview')}
										target="_blank"
										style="outline"
										color="secondary"
										size="xs"
										disabled={true}
									/>
								))}
							{campaign.campaignDebugLinks &&
								campaign.campaignDebugLinks.length == 1 && (
								<Btn
									message={t('CampaignItem.Preview')}
									href={campaign.campaignDebugLinks[0].debugLink}
									target="_blank"
									style="outline"
									color="secondary"
									size="xs"
								/>
							)}
							{campaign.campaignDebugLinks &&
								campaign.campaignDebugLinks.length > 1 && (
								<Dropdown
									isOpen={debugDropdownIsOpen}
									setIsOpen={(e) => setDebugDropdownIsOpen(true)}
									onHide={(e) => setDebugDropdownIsOpen(false)}
									button={
										<Btn
											style="outline"
											color="secondary"
											arrow="true"
											size="xs"
											message={t('CampaignItem.Preview')}
										/>
									}
								>
									<ul className="listbox">
										{campaign.campaignDebugLinks
											.sort((v1, v2) =>
												v1.variation.localeCompare(v2.variation)
											)
											.map((debug, i) => (
												<Fragment key={i}>
													<li>
														<a
															href={debug.debugLink}
															target="_blank"
															className="listbox_item" rel="noreferrer"
														>
															Variation{' '}
															{
																[
																	'A',
																	'B',
																	'C',
																	'D',
																	'E',
																	'F',
																	'G',
																	'H',
																	'I',
																	'J',
																][i]
															}
														</a>
													</li>
													<li className="hr"></li>
												</Fragment>
											))}
									</ul>
								</Dropdown>
							)}

							{/*{statusAction}*/}
							{modificationCampaign}
							{isAdmin && refreshButton}
							<DropDownAction
								campaign={campaign}
								dropdownIsOppen={actionsDropdownIsOppen}
								setDropdownIsOppen={setActionsDropdownIsOppen}
								getDuplicatedCampaignEditorPath={getDuplicatedCampaignEditorPath}
								getTransformedToNativeAppCampaignEditorPath={getTransformedToNativeAppCampaignEditorPath}
							/>
						</div>
					</div>
				</TableColItem>
				{campaign.screenShots.length > 0 && (
					<CampaignItemExtend
						screenShots={campaign.screenShots}
						campaign={campaign}
					/>
				)}
				{loadingCampaign && loadingCampaign == campaign.id && (
					<div className="mask has_progress"></div>
				)}
			</TableRowItem>
		</>
	);
}