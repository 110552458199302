import React, {useEffect, useState} from 'react';
import Panel from '../../../../Components/Panel';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import PanelArticle from '../../../../Components/PanelArticle';
import {List, ListItem} from '../../../../Components/List';
import Picto from '../../../../Components/Picto';
import Btn from '../../../../Components/Btn';
import Confirm from '../../../../Components/Confirm';
import SectionMessage from '../../../../Components/SectionMessage';
import { CampaignsContext } from '../../context/index.js';
import {ListFeature, Feature} from './utils';
import {useTranslation} from 'react-i18next';
import renderJsxTag from '../../../../Translation/translation-utils';
import { iconVariable } from '../../../../Components/CustomIcon/CustomIcon';

export default function PanelListOfFeatures({
	handleOpenPanel,
	featuresOnCurrentVariation
}) {
	const {
		handleOpenClosePanelFeatures,
		pannelFeaturesIsOpen,
		handleDeleteFeature,
		isAdmin
	} = CampaignsContext();

	const { t } = useTranslation(['common']);
	const [currentfeatures, setcurrentfeatures] = useState<Array<Feature> | undefined>([]);
	const [availableFeatures, setAvailableFeatures] = useState<Array<Feature> | undefined>([]);
	const [confirmIsOpen, setConfirmIsOpen] = useState<boolean>(false);
	const [featureToRemove, setFeatureToRemove] = useState<string>('');

	function handleRemoveFeature(feature:string){
		setFeatureToRemove(feature);
		setConfirmIsOpen(true);
	}
	function checkIfFeatureIsDisabled(feature:string){
		const arrayOfCurrentFeatures = currentfeatures?.map(feat => feat.id);
		if(feature === 'redirection' && arrayOfCurrentFeatures?.includes('promoCode')){
			return true;
		}
		if(feature === 'promoCode' && arrayOfCurrentFeatures?.includes('redirection')){
			return true;
		}
		return false;
	}

	useEffect(()=>{
		const currentFeat = featuresOnCurrentVariation.map((feat:Feature )=> feat.id);
		let ListFeatureForUser;
		if (isAdmin) {
			ListFeatureForUser = ListFeature;
		} else {
			ListFeatureForUser = ListFeature.filter(feature => !feature.adminOnly);
		}
		const ListFeatureFinal = ListFeatureForUser.filter(feature => currentFeat.includes(feature.id));
		setcurrentfeatures(ListFeatureFinal);
		const ListAvailableFeatureFinal = ListFeatureForUser.filter(feature => !currentFeat.includes(feature.id));
		setAvailableFeatures(ListAvailableFeatureFinal);
	},[featuresOnCurrentVariation]);

	function getFeatureIconCustom(iconName) {
		if (iconName === 'iconVariable') return iconVariable;
		return null; 
	}

	return (
		<Panel
			width="560"
			isOpen={pannelFeaturesIsOpen}
			onClose={() => handleOpenClosePanelFeatures(false)}
			noClose={true}
		>
			<Confirm
				title={'Do you really want to delete this feature ?'}
				text={renderJsxTag(t('customCampaign.featureWarning'))}
				isOpen={confirmIsOpen}
				confirmText="Delete"
				cancelText="Cancel"
				confirmInput="REMOVE"
				confirmInputLabel='Please write "REMOVE" in the text field.'
				confirmColor="alert"
				confirmCallback={() =>handleDeleteFeature(featureToRemove)}
				onClose={() => setConfirmIsOpen(false)}
				width={500}
			/>
			<ModalHeader>
				<div className='flex'>
					<div className='flex_item_fix'>
						<a className='panel_close panel_close_left' onClick={() => handleOpenClosePanelFeatures(false)}></a>
					</div>
					<div className='flex_item_full'>{t('customCampaign.campaignFeatures')}</div>
				</div>
			</ModalHeader>
			<ModalBody color="grey">
				{currentfeatures && currentfeatures.length >= 1 &&
					<SectionMessage
						type="warning"
						appearance='background'
						noMargin={true}
						text={renderJsxTag(t('customCampaign.featureWarning'))}
					/>
				}
				{currentfeatures && currentfeatures.length === 0 &&
					<SectionMessage
						type="information"
						appearance='background_outline'
						noMargin={true}
						text={renderJsxTag(t('customCampaign.featureInformation'))}
					/>
				}
					
				{currentfeatures && currentfeatures.length > 0 &&
					<PanelArticle title={t('customCampaign.enabledFeatures')}>
						<List removeVerticalMargin={true}>
							{currentfeatures.map(feature => 
								<ListItem
									key={feature.id}
									text={feature.label}
									picto={
										<Picto
											icon={feature.icon || null}
											iconCustom={feature.iconCustom ? getFeatureIconCustom(feature.iconCustom) : null}
											color={feature.color}
										/>
									}
									actions={
										<Btn
											onClick={() => handleRemoveFeature(feature.id)}
											disabled={checkIfFeatureIsDisabled(feature.id)}
											message={t('actions.remove')}
											style="ghost"
											horizontalSize="xxs"
											color="alert"
											icon="fas fa-minus-circle"
										/>
									}
								/>
							)}
						</List>
					</PanelArticle>
				}

				{availableFeatures && availableFeatures.length > 0 &&
					<PanelArticle title={t('customCampaign.availableFeatures')}>
						<List removeVerticalMargin={true}>
							{availableFeatures.map(feature =>
								<ListItem
									key={feature.id}
									text={feature.label}
									picto={
										<Picto
											icon={feature.icon || null}
											iconCustom={feature.iconCustom ? getFeatureIconCustom(feature.iconCustom) : null}
											color={feature.color}
										/>
									}
									actions={
										<Btn
											onClickFunction={() => handleOpenPanel(feature.id)}
											disabled={checkIfFeatureIsDisabled(feature.id)}
											message={t('actions.use')}
											style="ghost"
											horizontalSize="xxs"
											icon="fas fa-plus-circle"
										/>
									}
								/>
							)}
						</List>
					</PanelArticle>
				}
			</ModalBody>
		</Panel>
	);
}
