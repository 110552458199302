const categoryIcon = {
	'Attributes': 'data',
	'Scores': 'score',
	'Product comparison': 'comparison'
};

const categoryPicto = {
	'Attributes': {icon: 'fas fa-database', color: 'green'},
	'Scores': {icon: 'fas fa-tachometer-alt', color: 'orange'},
	'Product comparison': {icon: 'fas fa-balance-scale', color: 'blue'},
};

export const getCategoryIcon = (name: string) => {
	if (!(name in categoryIcon)) {
		name = 'Attributes';
	}
	return categoryIcon[name];
};

export const getCategoryPicto = (name: string) => {
	if (!(name in categoryPicto)) {
		name = 'Attributes';
	}
	return categoryPicto[name];
};
