import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import { useServerSideEditorContext } from '../context/ServerSideEditorContextProvider';
import { EditorStep, EditorCreateOrUpdateMode } from '../context/EditorStateTypes';
import { DeviceType } from '../../../WysiwygEditor/Types/BespokeTypes';

export default function FormatToolBar() {
	
	const { t } = useTranslation(['common']);
	const {
		editorState,
		currentDevice,
		setCurrentDevice,
		serverSideCampaign,
		mode,
		sidebarIsOpen,
		setSidebarIsOpen
	} = useServerSideEditorContext();

	const idTooltip = uuidv4();

	return (
		<div className="format_toolbar flex">
			<div className="format_toolbar_left">

				{editorState.sideBarStep === EditorStep.One && !editorState.formatIsSelected && mode === EditorCreateOrUpdateMode.Create &&
					<div className="format_toolbar_title">
						Choose a template
					</div>
				}
				{editorState.sideBarStep === EditorStep.One && editorState.formatIsSelected &&
					<>
						<button
							className={sidebarIsOpen ? 'format_cmd main_color active' : 'format_cmd main_color'}
							onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
							data-tip="Toggle sidebar"
							data-for={idTooltip}>
							<i className='fas fa-sliders-h format_cmd_icon_l'></i>
						</button>
						<span className="format_toolbar_title">{t('common.customization')}</span>
						{serverSideCampaign && serverSideCampaign.Template && serverSideCampaign.Template.Name &&
							<>
								<i className='format_toolbar_title_arrow fas fa-chevron-right'></i>
								<span className="format_toolbar_subtitle">{serverSideCampaign.Template.Name}</span>
							</>
						}
						<ReactTooltip
							backgroundColor="black"
							effect="solid"
							place="bottom"
							globalEventOff="click"
							delayShow={600}
							id={idTooltip}
						/>
					</>
				}
			</div>
			<div className="format_toolbar_center">
				{editorState.sideBarStep !== EditorStep.Four && (editorState.selectedFormat?.TemplateName || editorState.sideBarStep === EditorStep.Two || editorState.sideBarStep === EditorStep.One && editorState.formatIsSelected ) ?
					(<ul className="format_tabs">
						<li>
							<a className={currentDevice === DeviceType.Mobile ? 'active' : ''}
								onClick={() => setCurrentDevice(DeviceType.Mobile)}>
								<i className='fas fa-mobile-alt'></i>
								Mobile
							</a>
						</li>
						<li>
							<a className={currentDevice === DeviceType.Desktop ? 'active' : ''}
								onClick={() => setCurrentDevice(DeviceType.Desktop)}>
								<i className='fas fa-desktop'></i>
								{t('button.desktop')}
							</a>
						</li>

					</ul>) : null
				}
			</div>
			<div className="format_toolbar_right"></div>
		</div>
	);

}