import React, { useState, useEffect } from 'react';
import VideosListContainer from './VideosListContainer';
import Modal from '../../../../../Components/Modal';
import Btn from '../../../../../Components/Btn';
import YouTube from 'react-youtube';
export default function VideosContainer({
	deleteABanner
}) {
	const [modalVideoWidgetIsOpen, setmodalVideoWidgetIsOpen] = useState(false);
	const [dataYoutube, setdataYoutube] = useState();
	const openVideoWidget = (data) => {
		setdataYoutube(data);
		setmodalVideoWidgetIsOpen(true);
	};

	const opts = {
		host: 'https://www.youtube.com',
	};

	return (
		<>
			{modalVideoWidgetIsOpen &&
				<Modal
					isOpen={modalVideoWidgetIsOpen}
					width="700"
					onClose={() => { setmodalVideoWidgetIsOpen(false); }}
				>
					<div className="modal_header has_border">
						{dataYoutube.metaData.title}
					</div>

					<div className="modal_body">
						<YouTube
							videoId={dataYoutube.metaData.youtubeId}
							opts={opts}
						/>
					</div>
				</Modal>
			}
			<VideosListContainer
				deleteABanner={deleteABanner}
				openVideoWidget={openVideoWidget}
			/>
		</>
	);
}
