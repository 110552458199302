import React, { useEffect, useState } from 'react';
import Btn from '../../../../Components/Btn';
import InputCustom from '../../../../Components/InputCustom';
import EmptyState from '../../../../Components/EmptyState';
import './GraphEditor.css';
import GraphPatchElements from './GraphPatchElements';
import { CampaignsContext } from '../../context';
import { isEmpty } from 'lodash';
import AlertDownloadChromeExtension from './ErrorMessage/AlertDownloadChromeExtension';
import AlertChromeIsNeeded from './ErrorMessage/AlertChromeIsNeeded';
import { tryExtension } from './utils';
import { useTranslation } from 'react-i18next';

export default function GraphEditorPatchSwitch() {
	const [hasExtension, setHasExtension] = useState(false);
	const [hasGoodVersion, setHasGoodVerision] = useState(false);
	const [render, setRender] = useState('');
	const version = window.BEYABLE_env.PATCH_EXTENSION_VERSION;
	const extensionId = window.BEYABLE_env.PATCH_GRAPH_EXTENSION_ID;

	const [currentVersion, setCurrentVersion] = useState('');
	const [patch, setPatch] = useState();
	const { getCurrentVariation, step } = CampaignsContext();
	const activeVariation = getCurrentVariation() || {};

	useEffect(() => {
		if (!isEmpty(activeVariation)) {
			setPatch(activeVariation.DesignProperties?.find(
				(e) => e.Name === 'PatchDefinitions'
			)?.Value || { data:[] });
		}

	}, [activeVariation]);

	const handleRefresh = () => {
		tryExtension(
			extensionId,
			version,
			setHasExtension,
			setHasGoodVerision,
			setCurrentVersion
		);
	};

	const handleRender = (value) => {
		setRender(value);
	};

	useEffect(() => {
		tryExtension(
			extensionId,
			version,
			setHasExtension,
			setHasGoodVerision,
			setCurrentVersion
		);
	}, []);
	
	useEffect(() => {
		filterGraphState(hasExtension, hasGoodVersion, handleRender);
	}, [hasExtension]);

	const filterGraphState = (bool1, bool2, callback) => {
		if (!window.chrome) {
			return callback('browserChrome');
		}
		if (bool1 === false) {
			return callback('downloadExtension');
		}
		if (currentVersion === version) {
			return callback('graphEditor');
		}
		if (bool1 === true && bool2 === false) {
			return callback('downloadExtension');
		}
		if (bool1 === true && bool2 === true) {
			return callback('graphEditor');
		}
	};

	return step == '1' ? (
		<>
			{render === 'browserChrome' && <AlertChromeIsNeeded />}
			{render === 'graphEditor' && (
				<GraphEditor patch={patch} setPatch={setPatch} />
			)}

			{render === 'downloadExtension' && (
				<AlertDownloadChromeExtension
					hasGoodVersion={hasGoodVersion}
					handleRefresh={handleRefresh}
					extensionId={extensionId}
				/>
			)}
		</>
	) : (
		<></>
	);
}

function GraphEditor({ patch, setPatch }) {
	const [inputUrl, setInputUrl] = useState('');
	const extensionId = window.BEYABLE_env.PATCH_GRAPH_EXTENSION_ID;
	const [t] = useTranslation('common');
	const {
		handleSetPatchDefinitions,
		systemServices,
		isSaving,
		setCanGoNextOrSave,
		getCurrentVariation,
		campaign,
		variations,
		handleChangeUrlDebug
	} = CampaignsContext();
	const activeVariation = getCurrentVariation() || {};
	const [principalElement, setprincipalElement] = useState();

	useEffect(() => {
		if(!isEmpty(activeVariation)){
			const hasPrincipalElementSelected = activeVariation.DesignProperties?.find(	(e) => e.Name === 'PatchPrincipalElement');
			if(hasPrincipalElementSelected){
				const elementPatch = activeVariation.DesignProperties?.find((e) => e.Name === 'PatchPrincipalElement').Value;
				setprincipalElement(elementPatch);
			}
		}
	}, [activeVariation,variations]);
	useEffect(() => {
		const patchIsEmpty = !(patch && patch.data && patch.data.length);
		if (patchIsEmpty) {
			setCanGoNextOrSave(false);
		} else {
			setCanGoNextOrSave(true);
		}
	}, [patch]);
	
	let urlToUse;
	let port;
	const patchIsEmpty = !(patch && patch.data && patch.data.length);

	function connect() {
		port = chrome.runtime.connect(extensionId, { name: 'initExt' });
		port.onDisconnect.addListener(() => {
			if (chrome.runtime.lastError) {
				return;
			}
			connect();
		});
		port.onMessage.addListener((res) => {
			if (res.type === 'responseTag') {
				handleSetPatchDefinitions({
					data: res.data,
					urlPatch: urlToUse,
				});
				setPatch({ data: res.data, urlPatch: res.url });
			}
		});
	}

	const handleUrlInput = (e) => {
		let url = e.target.value;
		url = url.replace('http://', '');
		url = url.replace('https://', '');
		url = url.replaceAll(' ', '');
		
		setInputUrl(url);
	};

	const handleClick = () => {
		const urlIsValid = (str) => {
			var pattern = new RegExp(
				'^(https:\\/\\/)' +
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
				'((\\d{1,3}\\.){3}\\d{1,3}))' +
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
				'(\\?[;&a-z\\d%_.~+=-]*)?' +
				'(\\#[-a-z\\d_]*)?$',
				'i'
			);
			return !!pattern.test(str);
		};

		urlToUse = inputUrl ? inputUrl : patch.urlPatch;

		if (
			urlToUse.indexOf('http://') === -1 &&
			urlToUse.indexOf('https://') === -1
		) {
			urlToUse = 'https://' + urlToUse;
		}

		// Prevent loading BEYABLE compaigns
		let sep = urlToUse.contains('?') ? '&' : '?';
		urlToUse += sep + 'by_debug=1';

		const isValid = urlIsValid(urlToUse);
		if (isValid) {
			connect();
			port.postMessage({
				type: 'ch.activation',
				url: urlToUse,
				patch: patch ? patch : [],
			});
		} else {
			systemServices.showError(t('graph.notValid'));
		}
	};

	useEffect(() => {
		const urlIsValid = (str) => {
			var pattern = new RegExp(
				'^(https:\\/\\/)' +
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
				'((\\d{1,3}\\.){3}\\d{1,3}))' +
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
				'(\\?[;&a-z\\d%_.~+=-]*)?' +
				'(\\#[-a-z\\d_]*)?$',
				'i'
			);
			return !!pattern.test(urlToUse);
		};
		let urlToUse = inputUrl;
		if (
			urlToUse.indexOf('http://') === -1 &&
			urlToUse.indexOf('https://') === -1
		) {
			urlToUse = 'https://' + urlToUse;
		}
		const isValid = urlIsValid(urlToUse);

		if(isValid){
			handleChangeUrlDebug(urlToUse);
		}
	},[inputUrl]);

	return (
		<>
			{patchIsEmpty &&   (
				<div className="graphic_patch_intro">
					<EmptyState
						verticalAlign={true}
						title={t('graph.onWich')}
						text={t('graph.enterUrl')}
						imageUrl="/Assets/empty_patch.svg"
						textSize='l'
						primaryAction={
							<div className="graphic_patch_form flex">
								<div className="flex_item_full">
									<InputCustom
										value={inputUrl}
										name="url"
										type="text"
										onChange={handleUrlInput}
										placeholder="www.beyable.com/"
										prepend={
											<span className="custom_input_prepend_text">
												https://
											</span>
										}
										size="l"
										color="white"
										fullWidth={true}
										onEnter={() => handleClick()}
									/>
								</div>
								<div className="flex_item_fix ml_10">
									<Btn
										disabled={
											!inputUrl ||
											inputUrl.length == 0
										}
										onClick={() => handleClick()}
										size="l"
										message={t('graph.Launch')}
										icon="fas fa-external-link-square-alt"
									/>
								</div>
							</div>
						}
					/>
				</div>
			)}

			{!patchIsEmpty && (
				<GraphPatchElements
					patch={patch}
					handleClick={handleClick}
					inputUrl={inputUrl}
					handleUrlInput={handleUrlInput}
					principalElement={principalElement}
				/>
			)}

			{isSaving && (
				<div className="mask">
					<div className="wheel size_32"></div>
				</div>
			)}
		</>
	);
}
