//
import React, {  useState, useEffect } from 'react';
import CampaignSelector from './Components/CampaignSelector';
import CampaignSelection from './Components/CampaignSelection';
import LaunchInfo from './Components/LaunchInfo';
import LaunchButton from './Components/LaunchButton';
import { ReportGeneratorContext } from './Context';
import ModalKpiSelectionCampaign from './ModalKpiSelectionCampaign';
import reporting_en from '../../Translation/jsonfiles/en/reporting_en.json';
import reporting_fr from '../../Translation/jsonfiles/fr/reporting_fr.json';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import SpinnerWheel from '../../Components/SpinnerWheel';
import UploadImageContainer from './UploadImageContainer';
import Breadcrumb from '../../Components/Breadcrumb';
import SearchBar from '../../Components/SearchBar';
import ModalHeader from '../../Components/ModalHeader';
import ModalSection from '../../Components/ModalSection';
import ModalBody from '../../Components/ModalBody';
import EmptyState from '../../Components/EmptyState';
import {Section} from '../../Components/Section/Section';
import {Article} from '../../Components/Article/Article';
import Btn from '../../Components/Btn';
import { Trans, useTranslation } from 'react-i18next';
import newInstanceI18next from '../../Util/traductionUtils';

export default function ReportGeneratorSteps({filterCampaigns}) {
	const {
		isReady,
		reportRecipient,
		handleChangeValueInSearchBar,
		valueInSearchBar,
		valueInSearchBarSelectedCampaigns,
		handleChangeValueInSearchBarSelectedCampaign,
		dataFiltered,
		selectedCampaignsFiltered,
		setup,
		setFilteredType,
		filteredType,

	} = ReportGeneratorContext();

	const [step, setStep] = useState(1);
	const [empty, setempty] = useState(false);

	const newInstanceI18Reporting = i18next.createInstance();
	const [t] = useTranslation(['common']);


	const steps = [
		{ index: 1, toGo: 1, title: 'Campaigns' },
		{ index: 2, toGo : 2, title: 'Settings' },
		{ index: 3, toGo : 3, title: 'Send' },
	];

	useEffect(()=>{
		if(isReady && setup.campaigns.length === 0 && valueInSearchBar.length === 0 && valueInSearchBarSelectedCampaigns.length === 0 || isReady && valueInSearchBar.length === 0 && valueInSearchBarSelectedCampaigns.length === 0 && selectedCampaignsFiltered.length === 0 ){
			setempty(true);
		}else{
			setempty(false);
		}
	},[valueInSearchBar,setup, valueInSearchBarSelectedCampaigns, isReady, selectedCampaignsFiltered]);
	if (!isReady)
		return <> <SpinnerWheel wheelSize="l" /></>;
	return (
		<>
			<I18nextProvider i18n={newInstanceI18next}>
				<ModalHeader>
					<div className='flex'>
						<div className='flex_item_fix_equal'>
							Report generator
						</div>
						<div className='flex_item_auto modal_header_breadcrumb'>
							<Breadcrumb
								itemOfBreadcrumb={steps}
								onClick={(i) => setStep(i)}
								stepSelected={step}
							/>
						</div>
						<div className='flex_item_fix_equal'></div>
					</div>
				</ModalHeader>
				
				{step === 1 &&
					<>
						<ModalSection size="s" hasBottomBorder={true}>
							{filterCampaigns}
						</ModalSection>
						<div className="flex_item_full report_generator_selection flex flex_align_stretch">
							<div className="report_generator_selection_part flex flex_v">
								<div className='report_generator_selection_section_bottom flex_item_full vscroll_custom'>
									<CampaignSelector />
								</div>
							</div>
							<div className="report_generator_selection_sep flex_item_fix"></div>
							<div className="report_generator_selection_part flex flex_v">
								<div className='report_generator_selection_section_top flex_item_fix flex'>
									<div className='flex_item_full report_generator_selection_title'>
										Selected campaigns
									</div>
									<div className='flex_item_fix ml_10'>
										<SearchBar
											onChangeValue={handleChangeValueInSearchBarSelectedCampaign}
											valueInSearchBar={valueInSearchBarSelectedCampaigns}
											placeholder='Find a campaign'
											fullWidth={false}
										/>
									</div>
								</div>
								<div className='report_generator_selection_section_bottom flex_item_full vscroll_custom'>
									{empty &&
										<EmptyState
											title="No campaigns on your setup"
											text={
												// eslint-disable-next-line react/no-unescaped-entities
												<> We haven't found any campaigns on your setup, please add campaign</>
											}
											imageUrl="/Assets/report_generator_processing.svg"
										/>	
									}

									{valueInSearchBarSelectedCampaigns && valueInSearchBarSelectedCampaigns.length > 0 && selectedCampaignsFiltered && selectedCampaignsFiltered.length === 0 &&
										<EmptyState
											title="No campaigns matched"
											text={
												<> Please try another search</>
											}
											imageUrl="/Assets/report_generator_processing.svg"
										/>
									}
									
									<CampaignSelection />
								</div>
							</div>
						</div>
					</>
				}
				{step === 2 &&
					<ModalBody color='grey'>
						<Section width="xs">
							<Article innerSize='xl'>
								<UploadImageContainer/>
							</Article>
						</Section>
					</ModalBody>
				}
				{step === 3 &&
					<ModalBody color='grey'>
						<Section width="xs">
							<Article innerSize='xl'>
								<LaunchInfo/>
								<LaunchButton
									onLaunched={() => setStep(4)}
								/>
							</Article>
						</Section>
					</ModalBody>
				}
				{step === 4 &&
					<ModalBody color='grey'>
						<Section width="xs">
							<Article innerSize='xl'>
								<EmptyState
									title="The report is being generated"
									text={
										<>
											Check your mailbox ({reportRecipient.email}).<br />
											You can close this window.
										</>
									}
									imageUrl="/Assets/report_generator_processing.svg"
								/>
							</Article>
						</Section>
					</ModalBody>
				}
				<ModalKpiSelectionCampaign />
			</I18nextProvider>
		</>
	);
}