import React from 'react';

export default function Icon({
	children,
	name,
	className = ''
}) {

	return (
		<svg
			width="1em"
			height="1em"
			viewBox="0 0 20 20"
			stroke="none"
			className={className}
			name={name}
			fill="none"
		>
			{children}
		</svg>
	);
}