export const traitColumnsCount = {
	noLabel: false,
	createInput({ trait }) {
		const that = this;
		const nb_choices = 3;
		const choices = [];
		const component = trait.target;
		
		let wrapper = document.createElement('div');
		wrapper.classList.add('btn_switch');

		for (let i = 1; i <= nb_choices; i++) {
			let choice = this.createChoiceInput(i);
			choice.addEventListener('click', function(ev){
				if (that.inputValue == i) return;

				const needConfirm = that.needConfirm(component, i);
				if (needConfirm) {
					that.showConfirm(function(){
						that.inputValue = i;
						that.updateChoiceInputs(i);
						that.onChange(ev);
					});
				} else {
					that.inputValue = i;
					that.updateChoiceInputs(i);
					that.onChange(ev);
				}
			});
			wrapper.appendChild(choice);
			choices.push(choice);
		}
		this.choices = choices;

		return wrapper;
	},
	onEvent({ component }) {
		const value = this.inputValue;
		const el = component.view.$el[0];
		const nbColumns = el.querySelectorAll('.by-js-column').length;
		const models = component.attributes.components.models;

		if (nbColumns == value) return;

		if (nbColumns < value) {
			const diff = value - nbColumns;
			for (let i = 0; i < diff; i++) {
				component.append('<div data-gjs-type="column"></div>');
			}
		} else {
			let modelsToRemove = [];
			for (let i = 0; i < models.length; i++) {
				if (value >= i+1) continue;
				if (models[i].attributes.type != 'column') continue;
				modelsToRemove.push(models[i]);
			}		
			this.removeComponents(modelsToRemove);
		}

		const colClass = this.getColClass(models.length);
		models.forEach(model => {
			model.addAttributes({'data-by-col': colClass});
		});
	},
	onUpdate({ component }) {
		const el = component.view.$el[0];
		const nbColumns = el.querySelectorAll('.by-js-column').length;
		this.updateChoiceInputs(nbColumns);
	},
	updateChoiceInputs(value) {
		this.choices.forEach(choice => {
			choice.classList.toggle('active', value == choice.value);
		});
	},
	removeComponents(components) {
		components.forEach(model => {
			model.remove();
		});
	},
	createChoiceInput(value) {
		let choice = document.createElement('button');
		choice.classList.add('btnUi');
		choice.classList.add('btn_outline_secondary');
		choice.setAttribute('value', value);
		choice.innerHTML = this.getChoiceIcon(value);
		return choice;
	},
	getChoiceIcon(value) {
		if (value == 1) return '<svg class="btn_svg_stroke" xmlns="http://www.w3.org/2000/svg" width="23" height="22"><defs><clipPath id="a"><path d="M0 0h23v22H0z"/></clipPath></defs><g clip-path="url(#a)" data-name="Plan de travail – 1"><g fill="none" stroke="#8a8ea5" data-name="Rectangle 1217" transform="translate(1 1)"><rect width="21" height="20" stroke="none" rx="3"/><rect width="20" height="19" x=".5" y=".5" rx="2.5"/></g></g></svg>';
		if (value == 2) return '<svg class="btn_svg_stroke" xmlns="http://www.w3.org/2000/svg" width="23" height="22"><defs><clipPath id="a"><path d="M0 0h23v22H0z"/></clipPath></defs><g fill="none" stroke="#8a8ea5" clip-path="url(#a)" data-name="Plan de travail – 2"><g data-name="Rectangle 1218" transform="translate(1 1)"><rect width="21" height="20" stroke="none" rx="3"/><rect width="20" height="19" x=".5" y=".5" rx="2.5"/></g><path d="M11.5 1.5v19" data-name="Ligne 201"/></g></svg>';
		if (value == 3) return '<svg class="btn_svg_stroke" xmlns="http://www.w3.org/2000/svg" width="24" height="22"><defs><clipPath id="a"><path d="M0 0h24v22H0z"/></clipPath></defs><g fill="none" stroke="#8a8ea5" clip-path="url(#a)" data-name="Plan de travail – 3"><g data-name="Rectangle 1219" transform="translate(1 1)"><rect width="22" height="20" stroke="none" rx="3"/><rect width="21" height="19" x=".5" y=".5" rx="2.5"/></g><path d="M8.5 1.5v19" data-name="Tracé 2087"/><path d="M15.5 1.5v19" data-name="Ligne 202"/></g></svg>';
		return '';
	},
	showConfirm(callback) {
		const style_panel = document.querySelector('#builder_tab_styles');
		let confirm = document.createElement('div');
		confirm.classList.add('modal_outer');
		confirm.classList.add('animation_slide_down');
		confirm.innerHTML = `
			<div class="modal_inner" style="width:430px;">
				<a class="modal_close js-close"></a>
				<div class="modal_body">
					<div class="fw_medium s_18">Columns elements will be deleted</div>
					<div class="s_15 grey_0 mt_5">Are you sure you want to continue?</div>
				</div>
				<div class="modal_footer al_right mt_10">
					<button type="button" class="btnUi btn_ghost_secondary js-close"><span class="btn_txt">Cancel</span></button>
					<button type="button" class="btnUi btn_alert js-confirm"><span class="btn_txt">Delete content</span></button>
				</div>
			</div>
		`;

		const close_btns = confirm.querySelectorAll('.js-close');
		close_btns.forEach(close => {
			close.addEventListener('click', function(){
				confirm.remove();
			});
		});

		const confirm_btn = confirm.querySelector('.js-confirm');
		confirm_btn.addEventListener('click', function(){
			confirm.remove();
			callback();
		});

		style_panel.insertAdjacentElement('afterend', confirm);
	},
	needConfirm(component, value) {
		const componentEl = component.view.$el[0];
		const models = component.attributes.components.models;
		const nbColumns = componentEl.querySelectorAll('.by-js-column').length;
		if (nbColumns <= value) return false;

		for (let i = 0; i < models.length; i++) {
			if (value >= i+1) continue;
			const model = models[i];
			if (model.attributes.type != 'column') continue;
			if (model.attributes.components.length > 0) {
				return true;
			}
		}

		return false;
	},
	getColClass(nbCols) {
		return 12 / nbCols;
	}
};
