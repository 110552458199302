import React, { useState, useEffect } from 'react';
import {datesOptions, compareDatesOptions, findNavLabel} from './config';
import styles from './Nav.module.css';
import moment from 'moment';
import Dropdown from '../../../../Components/Dropdown';
import AddComparisonPeriod from './AddComparisonPeriod';
import Btn from '../../../../Components/Btn';
import CustomPicker from './CustomPicker';

// TODO @Romain : Move into a generic component

const Nav = ({
	handleClick,
	initialDates,
	initialLabel = 'initialLabel',
	navLabel = 'Period',
	showComparisonToggler = false,
	showComparisonRemover = false,
	setComparisonIsEnable,
	comparisonSelected,
	setisCustomSelected,
	isCustomSelected = false,
	customDateRange,
	setCustomDateRange
}) => {
	
	const [current, setCurrent] = useState(null);
	const labels =  Object.keys(datesOptions);
	const [focusedInput, setFocusedInput] = useState('startDate');
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

	const handleOnDateChange = ({ startDate, endDate }) => {
		setCustomDateRange({
			startDate: startDate,
			endDate: endDate
		});
	};
	const close = (d) => {
		handleClick({ fromDate: d.startDate, toDate: d.endDate });
	};

	const onFocused = (focused) => {
		if (focused === null) {
			// setDropdownIsOpen(false);
		}
		setFocusedInput(!focused ? 'startDate' : focused);
	};
	const onHandleClick = (label) => {
		if (label === '') return;
		
		setCurrent({ ...datesOptions[label], label: label });
		if (label !== 'Custom') {		
			setisCustomSelected(false);
			setDropdownIsOpen(false);
			handleClick(datesOptions[label]);
		}
		if (label === 'Custom') {
			setisCustomSelected(true);
		}
	};
	const handleValidDate = () => {
		setDropdownIsOpen(false);
		handleClick(
			{
				fromDate: customDateRange.startDate,
				toDate: customDateRange.endDate
			});
	};

	const handlePreventToggleDropdown = (e) => {
		e.stopPropagation();
	};

	const handleRemoveComparison = (e) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		if (typeof setComparisonIsEnable === 'function') {
			setComparisonIsEnable(false);
		}
	};
	useEffect(()=>{
		if(initialDates && current === null){
			setCurrent(
				{ ...datesOptions[initialDates.initialLabel],
					label: findNavLabel(initialDates.initialFrom,initialDates.initialTo ) });
		}
	},[initialDates]);

	const button = (
		<div className="analytics_range">
			<div className="flex">
				<div className="flex_item_full">
					<div className="analytics_range_sup">{navLabel}</div>
					<div className="analytics_range_value">
						{isCustomSelected &&
							<>
								{moment(customDateRange.startDate).format('MMM DD, YYYY')}
								<i className="analytics_range_arrow fas fa-arrow-right"></i>
								{moment(customDateRange.endDate).format('MMM DD, YYYY')}
							</>
						}
						{!isCustomSelected&&
							<>{current?.label}</>
						}
					</div>
				</div>
				<div className="flex_item_fix analytics_range_cal">
					<i className="fas fa-calendar-alt"></i>
				</div>
				{showComparisonRemover &&
					<div className="flex_item_fix analytics_range_remove"
						onMouseDown={(e) => handlePreventToggleDropdown(e)}onClick={(e) => handleRemoveComparison(e)}>
						<i className="fas fa-times"></i>
					</div>
				}
			</div>
		</div>
	);


	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={(e) => setDropdownIsOpen(true)}
			onHide={(e) => setDropdownIsOpen(false)}
			button={button}
			flip={false}
		>
			<div className="flex flex_align_start">
				<div className="flex_item_full_fix">
					<ul className="listbox">
						{labels.map((label) => {
							const isSelected = isCustomSelected ? label === 'Custom' : (current?.label) === label;
							return (
								<li key={label}>
									<a className={isSelected ? 'listbox_item selected' : 'listbox_item'}
										onClick={() => onHandleClick(label)}>
										{label}
										{isSelected && (<span className={styles.indicator}></span>)}
									</a>
								</li>
							);
						})}
						{showComparisonToggler && 
							<>
								<li className="hr" />
								<li>
									<span className="listbox_item">
										<AddComparisonPeriod
											selectHandler={setComparisonIsEnable}
											comparisonSelected={comparisonSelected}
											closeDropdown={()=>setDropdownIsOpen(false)}
										/>
									</span>
								</li>
							</>
						}
					</ul>
				</div>
				<CustomPicker 
					isCustomSelected={isCustomSelected}
					customDateRange={customDateRange}
					onFocused={onFocused}
					focusedInput={focusedInput}
					handleValidDate={handleValidDate}
					handleOnDateChange={handleOnDateChange}
				/>
			</div>
		</Dropdown>
	);
};
Nav.displayName = 'Nav';
export default Nav;