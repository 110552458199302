import React, { useState, useEffect, Fragment } from 'react';
import MomentConstants from '../../../Constants/MomentConstants';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/data')(Highcharts);
export default function StatisticsChartContainer({ values, zoneId, dataOperationForStatsGlobal }) {

	const [numberImpressions, setNumberImpressions] = useState([]);
	const [clicksRate, setClicksRate] = useState([]);
	const [convRate, setConvRate] = useState([]);
	const [nbClicks, setNbClicks] = useState([]);
	const [titleChart, setTitleChart] = useState('');
	const [dayPeriode, setdayPeriode] = useState([]);

	const options = {
		exporting: {
			buttons: {
				contextButton: {
					menuItems: [
						'printChart',
						'separator',
						'downloadPNG',
						'downloadJPEG',
						'downloadPDF',
						'downloadSVG',
						'downloadCSV'
					]
				}
			}
		},
		chart: {
			height: 450,
			type: 'spline',
			// zoomType: 'x',
		},
		navigation: {
			buttonOptions: {
				verticalAlign: 'top',
				x: -50,
			}
		},
		title: {
			useHtml: true,
			text: `<b>Performance tracking for  ${titleChart && titleChart.title}  ${titleChart && titleChart.device !== '' ? 'on ' + titleChart.device : ''
			} </b>`,
			align: 'center',
		},
		legend: {
			symbolRadius: 0,
			symbolWidth: 40,
			itemStyle: {
				fontFamily: 'IBM Plex Sans',
				fontWeight: 'Light',
				left: '100px',
				top: '100px',
			},
			borderRadius: 3,
			align: 'center',
			verticalAlign: 'bottom',
		},
		credits: {
			enabled: false
		},
		xAxis: {
			gridLineWidth: 0,
			lineColor: '#9E9E9E',
			lineWidth: 1,
			categories: dayPeriode,
			tickLength: 10,
			tickWidth: 1,
		},
		yAxis: [{ // Primary yAxis
			gridLineWidth: 0,
			labels: {
				format: '{value}%',
				style: {

					color: 'black',
				},
			},
			title: {
				text: '',
				style: {
					color: 'black',
				}
			},
			max: 100,
			alignTicks: false,
			opposite: true,
			crosshair: true

		}, { // Secondary yAxis
			gridLineWidth: 1,
			gridLineColor: '#b5b5b561',
			title: {
				text: 'Impressions',
				style: {
					color: 'black'
				}
			},
			labels: {
				style: {
					color: '#293133',
					format: '{value} ',
				}
			},

		}],
		plotOptions: {
			column: {
				borderColor: '#9E9E9E',
				grouping: false,
				shadow: false,
				borderWidth: 0,
			},
			line: {
				grouping: true,
				shadow: false,
				dashStyle: 'long',
			}
			,
			series: {
				marker: {
					fillColor: '#FFF',
					lineWidth: 1,
					lineColor: null // inherit from series
				}
			},

		},
		opposite: true,
		tooltip: {
			shared: true,
			backgroundColor: '#38444A',
			color: '#FFF',
			style: {
				color: '#FFFFFF',
			},
		},
		series: [
			{
				name: 'Impressions',
				data: numberImpressions,
				color: '#bebebec2',
				type: 'column',
				yAxis: 1,
			},
			{
				name: 'Number of clicks',
				data: nbClicks,
				color: '#006fff',
				type: 'column',
				yAxis: 1,
			},
			//     {
			//     name: "taux de conv",
			//     data: convRate,
			//     color: '#13d1d4',
			//     type: 'line',
			//     yAxis: 0,
			//     tooltip: {
			//         valueDecimals: 2,
			//         valueSuffix: ' %'
			//     },
			// },
			{
				name: 'Click rate (%)',
				data: clicksRate,
				color: '#ffcd38',
				type: 'line',
				yAxis: 0,
				tooltip: {
					valueDecimals: 2,
					valueSuffix: ' %',
					color: 'blue'
				},
			}
		]

	};

	let valuesOnChart = (ban) => {
		if (zoneId === 'global') {
			if (!dataOperationForStatsGlobal.data.length === 0) {
				return;
			}
			setTitleChart({ title: 'all zones of operation', device: '' });
			const dataGlobal = dataOperationForStatsGlobal.data;
			let periode = dataGlobal && dataGlobal.map(x => new Date(moment(x.sessionDate, MomentConstants.MOMENT_API_FORMAT).valueOf()));
			let dateLocal = periode && periode.map(x => x.toLocaleDateString());
			setdayPeriode(dateLocal);
			let nbClicksRate = dataGlobal && dataGlobal.map(el => {
				if (el.numberOfImpressions !== 0) {
					return (el.numberOfClicks / el.numberOfImpressions) * 100;
				} else {
					return el.numberOfImpressions;
				}
			});
			let nbImpressions = dataGlobal && dataGlobal.map(el => el.numberOfImpressions);
			let nbConv = dataGlobal && dataGlobal.map(el => {
				if (el.numberOfImpressions !== 0) {
					return (el.numberOfConversions / el.numberOfImpressions) * 100;
				} else {
					return el.numberOfImpressions;
				}
			});
			let nbClicks = dataGlobal && dataGlobal.map(el => el.numberOfClicks);
			setNumberImpressions(nbImpressions);
			setClicksRate(nbClicksRate);
			setNbClicks(nbClicks);
			setConvRate(nbConv);
		} else {
			const banner = values && values.find(elem => elem.zoneId === ban);
			if (banner) {
				setTitleChart({ title: banner.zoneName, device: banner.device });
				let periode = banner.aggregatedByGranularityStatistics.map(x => new Date(moment(x.sessionDate, MomentConstants.MOMENT_API_FORMAT).valueOf()));
				let dateLocal = periode && periode.map(x => x.toLocaleDateString());
				setdayPeriode(dateLocal);
				let nbClicksRate = banner.aggregatedByGranularityStatistics.map(el => {
					if (el.numberOfImpressions !== 0) {
						return (el.numberOfClicks / el.numberOfImpressions) * 100;
					} else {
						return el.numberOfImpressions;
					}
				});
				let nbImpressions = banner.aggregatedByGranularityStatistics.map(el => el.numberOfImpressions);
				let nbConv = banner.aggregatedByGranularityStatistics.map(el => (el.numberOfConversions / el.numberOfImpressions) * 100);
				let nbClicks = banner.aggregatedByGranularityStatistics.map(el => el.numberOfClicks);
				setNumberImpressions(nbImpressions);
				setClicksRate(nbClicksRate);
				setNbClicks(nbClicks);
				setConvRate(nbConv);
			}
		}
	};

	useEffect(() => {
		if (zoneId) {
			valuesOnChart(zoneId);
		}
	}, [zoneId, values, dataOperationForStatsGlobal]);
	return (
		<div>
			<HighchartsReact
				className='chart'
				highcharts={Highcharts}
				options={options}
				constructorType={'chart'}
			/>
		</div>
	);
}
