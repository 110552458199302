export enum FilterOperators {
    Equals,
    NotEquals,
    Lower,
    Greater,
    LowerOrEquals,
    GreaterOrEquals,
    StartsWith,
    NotStartsWith,
    EndsWith,
    NotEndsWith,
    Contains,
    NotContains,
    Like,
    NotLike
}

export enum AttributeOrScoreValueType {
    String = 'String',
    Number = 'Number',
    Tag = 'Tag',
    Url = 'Url',
    BoundedNumber = 'BoundedNumber',
    Boolean = 'Boolean',
    Date = 'Date',
    Percent = 'Percent',
    PercentRange = 'PercentRange',
    TagValueRange = 'TagValueRange',
    TypeDependentRange = 'TypeDependentRange',
    StringList = 'StringList'
}

enum ParamsValueType {
    String = 'String',
    Number = 'Number'
}

export type Params = {
    name: string;
    valueType: ParamsValueType;
}

export type AttributeOrScoreFilterItem = {
    id: string;
    name: string;
    params: Params[] | null;
    supportedOperators: FilterOperators
    valueType: AttributeOrScoreValueType
}

export type AttributeOrScoreFiltering = {
    name: string;
    itemList: AttributeOrScoreFilterItem[];
}