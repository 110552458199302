import {initInheritStyles} from './utils';

const icon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" viewBox="0 0 40 30">
  <defs>
    <clipPath id="clip-ico_divider_1">
      <rect width="40" height="30"/>
    </clipPath>
  </defs>
  <g id="ico_divider_1" data-name="ico_divider – 1" clip-path="url(#clip-ico_divider_1)">
    <circle id="Ellipse_138" data-name="Ellipse 138" cx="1" cy="1" r="1" transform="translate(13 14)" fill="#acafc0"/>
    <circle id="Ellipse_139" data-name="Ellipse 139" cx="1" cy="1" r="1" transform="translate(16 14)" fill="#acafc0"/>
    <circle id="Ellipse_140" data-name="Ellipse 140" cx="1" cy="1" r="1" transform="translate(19 14)" fill="#acafc0"/>
    <circle id="Ellipse_141" data-name="Ellipse 141" cx="1" cy="1" r="1" transform="translate(22 14)" fill="#acafc0"/>
    <circle id="Ellipse_142" data-name="Ellipse 142" cx="1" cy="1" r="1" transform="translate(25 14)" fill="#acafc0"/>
    <path id="Rectangle_1242" data-name="Rectangle 1242" d="M0,0H26a0,0,0,0,1,0,0V4a2,2,0,0,1-2,2H2A2,2,0,0,1,0,4V0A0,0,0,0,1,0,0Z" transform="translate(7 3)" fill="#acafc0"/>
    <rect id="Rectangle_1243" data-name="Rectangle 1243" width="22" height="4" transform="translate(9 3)" fill="#e9eaf0"/>
    <path id="Rectangle_1244" data-name="Rectangle 1244" d="M0,0H26a0,0,0,0,1,0,0V4a2,2,0,0,1-2,2H2A2,2,0,0,1,0,4V0A0,0,0,0,1,0,0Z" transform="translate(33 27) rotate(180)" fill="#acafc0"/>
    <rect id="Rectangle_1245" data-name="Rectangle 1245" width="22" height="4" transform="translate(31 27) rotate(180)" fill="#e9eaf0"/>
  </g>
</svg>
`;

export const componentDivider = {
	model: {
		defaults: {
			name: 'Divider',
			droppable: false,
			editable: true,
			layerable: true,
			resizable: {
				cl: false,
				cr: false,
				bl: false,
				br: false,
				bc: true,
				tl: false,
				tr: false,
				tc: true,
				step: 1,
				keyHeight: 'min-height'
			},
			tagName: 'div',
			components: '<span data-gjs-type="divider_inner"></span>',
			traits: [
				{
					type: 'responsive',
					name: 'responsive',
					label: 'Responsive',
					changeProp: true,
					tab: 'responsive'
				}
			],
			stylable: ['margin'],
			style: {
				'display': 'flex',
				'align-items': 'center',
				'padding': '0 4px',
				'margin-top': '8px',
				'margin-bottom': '8px',
				'min-height': '20px',
				'justify-content': 'center'
			},
			'script-props': [],
			icon: icon
		},
	}
};


export const componentDividerInner = {
	model: {
		init() {
			initInheritStyles(this);
		},
		ineritedStyles: [
			{
				key: 'style-background-color',
				prop: 'background-color',
				type: 'color',
				label: 'Background color',
				value: '#269fb0',
				tab: 'style'
			},
			{
				key: 'style-margin',
				prop: 'margin',
				type: 'select',
				label: 'Alignment',
				value: '0 auto',
				options: [
					{ id: '0 auto 0 0', name: 'Left'},
					{ id: '0 auto', name: 'Center'},
					{ id: '0 0 0 auto', name: 'Right'},
				],
				tab: 'style'
			},
			{
				key: 'style-width',
				prop: 'width',
				type: 'number',
				unit: 'px',
				label: 'Width',
				value: 100,
				tab: 'style'
			},
			{
				key: 'style-height',
				prop: 'height',
				type: 'number',
				unit: 'px',
				label: 'Height',
				value: 3,
				tab: 'style'
			},
			{
				key: 'style-border-radius',
				prop: 'border-radius',
				type: 'number',
				unit: 'px',
				label: 'Radius',
				value: 3,
				tab: 'style'
			},
		],
		defaults: {
			removable: false,
			draggable: false,
			droppable: false,
			selectable: false,
			layerable: false,
			hoverable: false,
			highlightable: false,
			editable: false,
			tagName: 'span',
			stylable: [],
			traits: [],
			style: {
				'flex': '0 1 auto',
				'width': '50%',
				'max-width': '100%'
			}
		}
	}
};
