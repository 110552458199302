import React, { useState, useEffect } from 'react';
import Modal from '../../../Components/Modal';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';
import { CampaignsContext } from '../context';
import { v4 as uuidv4 } from 'uuid';
import {useTranslation} from 'react-i18next';
export default function IpFormModal({
	isOpen,
	onClose,
	mode = 'add',
	ip = {
		label: '',
		ipAddress: '',
		isDisabled: false,
		id: uuidv4()
	},
	saveCallback,
	editorMode
}) {

	const {
		currentIpAddress
	} = CampaignsContext();    
	const { t, i18n } = useTranslation(['common']);
	const [newIp, setNewIp] = useState({...ip});
	const [labelError, setLabelError] = useState('');
	const [ipError, setIpError] = useState('');

	useEffect(() => {
		if( ip ){
			setNewIp({...ip});
		}
	}, [isOpen]);

	useEffect(() => {
		if( newIp.label ){
			setLabelError('');
		}
		if( newIp.ipAddress ){
			setIpError('');
		}
	}, [newIp]);

	const handleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		const tmpIp = {...newIp, [name]: value};
		setNewIp(tmpIp);
	};

	const validate = () => {
		let hasError = false;

		if( ! newIp.label ){
			setLabelError(t('informations.youMustEnterAName'));
			hasError = true;
		}

		if( ! newIp.ipAddress ){
			setIpError(editorMode === 'appNative/patch/code' ? t('informations.youMustEnterAnDeviceAddress') :  t('informations.youMustEnterAnIpAdresse'));
			hasError = true;
		}

		if( hasError ) return;

		saveCallback(newIp, ip);
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => onClose()}
			width={450}
		>
			<div className="modal_header has_border">
				{mode == 'edit' ?
					<>{ editorMode === 'appNative/patch/code' ? t('informations.changedevice') : t('informations.changeIp')}</>
					:
					<>{editorMode === 'appNative/patch/code' ? t('informations.addAnDevice') : t('informations.addAnIp')}</>
				}
			</div>
			<div className="modal_body">
				<InputCustom
					onChange={(e) => handleChange(e)}
					label="Name"
					name="label"
					value={newIp.label}
					autoFocus={true}
					fullWidth={true}
					size="l"
					placeholder="Office, Home, ..."
					isAnError={labelError}
				/>
				<div className="mt_20">
					<InputCustom
						onChange={(e) => handleChange(e)}
						label={editorMode === 'appNative/patch/code' ? t('informations.DeviceAddress') : t('informations.IpAddress')}
						name="ipAddress"
						value={newIp.ipAddress}
						fullWidth={true}
						size="l"
						placeholder="Ex: 154.118.187.55"
						isAnError={ipError}
					/>
					{editorMode !== 'appNative/patch/code' && <div>
						{mode == 'edit' ?
							<>{t('informations.replaceIp')}</>
							:
							<>{t('informations.useCurrentIp')}</>
						}
						<span className="text_badge" onClick={() => handleChange({target: {name: 'ipAddress', value: currentIpAddress}})}>
							{currentIpAddress}
						</span>
					</div>}
				</div>
			</div>
			<div className="modal_footer al_right">
				<Btn
					message={t('button.Cancel')}
					style="ghost"
					color="secondary"
					onClick={() => onClose()}
				/>
				<Btn
					message={t('button.OK')}
					onClick={() => validate()}
				/>
			</div>
		</Modal>
	);
}
