const triggerList = [
	{
		id: 'productReference',
		name: 'TRGCHECKPRODUCTIDS',
		label: 'Sku List',
		info: 'Add the product references you want to target (eg. SKUs)',
		shouldOpenEditionPopin: true,
		defaultValueAndOperator: {
			operatorName: 'CONTAINS',
			valueToCompare: []
		},
		inputExample: `XKOLKOPX;OPLMOKHG;89LOKMPX
KIO89JX`,
		inputSplitter: /[;,\n]/,
		inputSplitterInstructions: 'Enter one item per line or use one of the following separators: ; (semi-colon) , (comma)',
		inputProcessingMap: null,
		canSelectOption: true,
		permissionId: 'simplifyOperation:canUseTriggerBySkuList'
	},
	{
		id: 'category',
		name: 'TRGCHECKURLTAGS',
		label: 'Category List',
		info: 'Add the categories you want to target',
		shouldOpenEditionPopin: true,
		defaultValueAndOperator: {
			operatorName: 'CONTAINS',
			valueToCompare: []
		},
		inputExample: `category=shoes;category=accessories
universe=promo;`,
		inputSplitter: /[;\n]/,
		inputSplitterInstructions: 'Enter one item per line or use one of the following separators: ; (semi-colon)',
		inputProcessingMap: items => items.map(item => item.split('=').map(x => x.trim()).join('=')),
		canSelectOption: true,
		permissionId: 'simplifyOperation:canUseTriggerByCategoryList'
	},
	{
		id: 'url',
		name: 'TRGCHOOSEURLS',
		label: 'Url List',
		info: 'Add the urls you want to target',
		shouldOpenEditionPopin: true,
		defaultValueAndOperator: {
			operatorName: 'CONTAINS',
			valueToCompare: []
		},
		inputExample: '/shop/home.html;/blog/page1;',
		inputSplitter: /[;\n]/,
		inputSplitterInstructions: 'Enter one item per line or use one of the following separators: ; (semi-colon)',
		canSelectOption: true,
		permissionId: 'simplifyOperation:canUseTriggerByUrlList'
	},
	{
		id: 'tenant',
		name: 'TRGTARGETTENANT',
		label: 'Tenants List',
		info: 'Add the tenants you want to target',
		shouldOpenEditionPopin: true,
		defaultValueAndOperator: {
			operatorName: 'CONTAINS',
			valueToCompare: []
		},
		inputExample: 'FR;EN',
		inputSplitter: /[;\n]/,
		inputSplitterInstructions: 'Enter one item per line or use one of the following separators: ; (semi-colon)',
		canSelectOption: true,
		permissionId: 'simplifyOperation:canUseTriggerByTenantList'
	},
	{
		id: 'example',
		name: 'exemple2',
		label: 'exemple2',
		info: 'it is an example 2',
	},
];

export default triggerList;