import React from 'react'
import ProductCard from '../ProductCard/ProductCard';
import TableGridContainer from '../TableGridContainer';
import TableRowItem from '../TableRowItem';
import TableColItem from '../TableColItem';
import './NormalProduct.css';
import PaginationProductList from './PaginationProductList';
import { v4 as uuidv4 } from 'uuid';

const optionCssList = {
	gridTemplateColumns: '6fr 2fr 2f 2fr',
	userSelect: 'none',
}
const elementRanking = (elem, idInClipBoard) => {
	const Url = elem.url;
	return <TableRowItem
		key={uuidv4()}
		specialClasseName={'normal_product table_body_row'}
		styleObj={optionCssList}>
		<TableColItem
			specialClasseName={' '}>
			<div className="flex">
				<div className="flex_item_fix">
					<a className="emerch_avatar emerch_avatar_margin"
						href={Url}
						target="_blank"
					>
						<img
							style={{ maxWidth: '30px' }}
							src={elem.urlThumbnail}
							alt={`pic of product ${elem.id}`} />
					</a>
				</div>
				<div className="flex_item_full">
					<p className="table_row_name "
					>{elem.name}
					</p>
				</div>
			</div>
		</TableColItem>
		<TableColItem
			specialClasseName={' '}>
			<p>{elem.price}</p>
		</TableColItem>

		<TableColItem specialClasseName={''}>
			<p>{elem.id}</p>
		</TableColItem>
		<TableColItem specialClasseName={"normal_product_table_actions"}>
			{/* <div className='normal_Product_actions'>
				<ProductItemDropDown
					idProduct={elem.id}
					idInClipBoard={idInClipBoard}
				/>
			</div> */}
		</TableColItem>
	</TableRowItem>
}
function getTableProducts(productsToMaped, idInClipBoard) {
	return <><TableGridContainer specialClasseName="table_grid_emerch">
		<TableRowItem specialClasseName={'table_head_row'}
			styleObj={optionCssList}>
			<TableColItem specialClasseName={' '}>
				Product name
			</TableColItem>
			<TableColItem specialClasseName={''}>
				Price
			</TableColItem>
			<TableColItem specialClasseName={''}>
				Id
			</TableColItem>
			<TableColItem specialClasseName={''}>

			</TableColItem>

		</TableRowItem>
		{productsToMaped && productsToMaped.map((elem, i) =>
			elementRanking(elem, idInClipBoard)
		)}
	</TableGridContainer>
	</>
}

export default function StandardProduct({
	typeDimensions,
	screenTypeSelected,
	products,
	idInClipBoard,
	copyToClipBoard,
	categorySelected,
	typeCategoriesSecondLevelSelected,
	paginationOptions,
	currentPageProductList,
	setCurrentPageProductList,
	handleChangePaginationOptions,
	setNewPaginationNeed
}) {

	if (products.hasOwnProperty('pageItems') &&
		products.pageItems.length === 0 || !products.hasOwnProperty('pageItems')) {
		return <></>
	}
	return (
		<>
			<div className="collection_card_list">
				{screenTypeSelected === 'grid' && products.hasOwnProperty('pageItems') &&
					products.pageItems.map((p, i) => {
						return <ProductCard
							key={uuidv4()}
							product={p}
							isRanking={false}
							idInClipBoard={idInClipBoard}
							copyToClipBoard={(e) => copyToClipBoard(e)}
						/>
					}
					)}
			</div>
			{screenTypeSelected === 'list' &&
				<>{getTableProducts(products.pageItems, idInClipBoard)}</>
			}
			<PaginationProductList
				categorySelected={categorySelected}
				typeCategoriesSecondLevelSelected={typeCategoriesSecondLevelSelected}
				totalCount={products.totalCount}
				pageSize={20}
				paginate={(e) => {
					handleChangePaginationOptions('poffset', e >= 0 ? e : 0)
					setNewPaginationNeed(true);
				}}
				skipIdNumber={paginationOptions.poffset}
				currentPage={currentPageProductList}
				setCurrentPage={(e) => setCurrentPageProductList(e)}
			/>
		</>
	)
}
