import React, { useEffect, useState, Fragment } from 'react';
import { react2angular } from 'react2angular';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import SystemServices from '../../Services/SystemServices';
import ToolingServices from '../../Services/ToolingServices';
import './EditAccountTooling.css';
import Btn from '../../Components/Btn';
import ConfigCodePromoContainer from './CodesPromo/ConfigCodePromoContainer';
import SelectCreationModeContainer from './SelectCreationModeContainer';
import ConfigPointOfSalesContainer from './PointOfSales/ConfigPointOfSalesContainer';
import CodePromoContainer from './CodesPromo/CodePromoContainer';

export default function EditAccountTooling(props) {
	const systemServices = new SystemServices(props.$rootScope);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const toolingServices = new ToolingServices(props.$http, props.$routeParams);

	const [accountConfigurationList, setAccountConfigurationList] = useState([]);
	const [AccountCurrentTab, setAccountCurrentTab] = useState();
	const [hasProperties, setHasProperties] = useState(false);
	const [isCreationMode, setIsCreationMode] = useState(false);
	const [clickOnConfiguration, setClickOnConfiguration] = useState();
	const [selectToMap, setSelectToMap] = useState();

	const getAccountConfiguration = (accountId, callbackSuccess, callbackError) => toolingServices.getAccountConfiguration(accountId, success => { callbackSuccess(success); }, error => { callbackError(error); });

	const putTheAccountPromoCode = (accountId, subscriber, callbackSuccess, callbackError) => toolingServices.putTheAccountPromoCode(accountId, subscriber, success => {
		systemServices.showSuccess('The promo code key has been created'); callbackSuccess(success);
	}, error => { systemServices.showError('Error while editing promo code key'); callbackError(error); });

	const getTheAccountPromoCode = (accountId, callbackSuccess, callbackError) => toolingServices.getTheAccountPromoCode(accountId, success => { callbackSuccess(success); }, error => { systemServices.showError('An error occurred during the process'); callbackError(error); });

	const getAccountPointOfSales = (accountId, callbackSuccess, callbackError) => toolingServices.getAccountPointOfSales(accountId, success => { callbackSuccess(success); }, error => { systemServices.showError('An error occurred during the process'); callbackError(error); });

	const putAccountPointOfSales = (accountId, config, callbackSuccess, callbackError) => toolingServices.putAccountPointOfSales(accountId, config, success => { systemServices.showSuccess('The promo code key has been edit with success'); callbackSuccess(success); }, error => { systemServices.showError('An error occurred during the process , please contact your administrator'); callbackError(error); });


	useEffect(() => {
		getAccountConfiguration(accountId, data => {
			setAccountConfigurationList(data);
		});
	}, []);

	const ChangeTheNavigationSelection = (e) => {
		setAccountCurrentTab(e);
	};
	const getNewConfigurationAccount = (e) => {
		getAccountConfiguration(accountId, data => {
			setAccountConfigurationList(data);
		});
	};
	const checkIfHasExistingConfig = () => {
		if (accountConfigurationList.hasPromoCodeConfig) {
			setAccountCurrentTab(4);
		} else if (accountConfigurationList.hasPointOfSalesConfig) {
			setAccountCurrentTab(3);
		} else {
			setAccountCurrentTab();
		}
	};

	useEffect(() => {
		if (accountConfigurationList) {
			if (!clickOnConfiguration) {
				checkIfHasExistingConfig();
			} else {
				setAccountCurrentTab(clickOnConfiguration);
			}
			const selectToMapArr = [];
			for (const key in accountConfigurationList) {
				if (accountConfigurationList[key] == true) {
					setHasProperties(true);
				} else {
					selectToMapArr.push(key);
				}
				setSelectToMap(selectToMapArr);
			}
		}
	}, [accountConfigurationList]);

	useEffect(() => {
		if (!hasProperties) {
			setAccountCurrentTab();
		}
	}, [hasProperties]);

	return (
		<div className="container-account-tooling">
			{
				hasProperties &&
				<Fragment>
					<div className="flex">
						<div className="flex_item_full">
							<ul className="tabs">
								{accountConfigurationList && accountConfigurationList.hasPromoCodeConfig &&
									<li><a onClick={() => ChangeTheNavigationSelection(4)} className={AccountCurrentTab == 4 ? 'active' : ''}>Api subscription key</a></li>}
								{accountConfigurationList &&
									accountConfigurationList.hasPointOfSalesConfig &&
									<li><a onClick={() => ChangeTheNavigationSelection(3)} className={AccountCurrentTab == 3 ? 'active' : ''}>Turnover synchronization configuration</a></li>
								}
								{/* <li onClick={() => ChangeTheNavigationSelection(2)} className={AccountCurrentTab == 2 ? 'active' : ''}><i className="fas fa-file-import"></i><p>Import EMR</p></li>
                        <li onClick={() => ChangeTheNavigationSelection(1)} className={AccountCurrentTab == 1 ? 'active' : ''}> <i className="fas fa-file-alt"></i><p>

    >Compte Rendu</p></li> */}
							</ul>
						</div>
						<div className="flex_item_fix">
							{accountConfigurationList && selectToMap.length !== 0 && !isCreationMode &&
								<div className="account_nav_tooling_last-item">
									<a className="" onClick={(e) => ChangeTheNavigationSelection(0)}>Add feature</a>
								</div>
							}
						</div>
					</div>
				</Fragment>
			}

			{
				!hasProperties && AccountCurrentTab == undefined &&
				<div className="empty_page">
					<div className="empty_page_sub_title grey_medium">This account does not have any configured feature yet</div>
					<div className="empty_page_btn">
						<Btn
							message="Configure new feature"
							size="l"
							icon="fas fa-plus"
							onClickFunction={(e) => setAccountCurrentTab(0)}
						/>
					</div>
				</div>
			}
			{
				AccountCurrentTab === 0 &&
				<SelectCreationModeContainer
					listOfConfiguRationAccount={selectToMap}
					setAccountCurrentTab={(e) => setAccountCurrentTab(e)}
					checkIfHasExistingConfig={() => checkIfHasExistingConfig()}
					setIsCreationMode={(e) => setIsCreationMode(e)}
				/>
			}

			{AccountCurrentTab === 5 &&
				<ConfigCodePromoContainer
					putTheAccountPromoCode={putTheAccountPromoCode}
					accountId={accountId}
					setAccountCurrentTab={(e) => setAccountCurrentTab(e)}
					getAccountConfiguration={() => getNewConfigurationAccount()}
					setIsCreationMode={(e) => setIsCreationMode(e)}
				/>
			}
			{AccountCurrentTab === 4 &&
				<CodePromoContainer
					getTheAccountPromoCode={getTheAccountPromoCode}
					accountId={accountId}
				/>
			}
			{AccountCurrentTab === 3 &&
				<ConfigPointOfSalesContainer
					accountId={accountId}
					isCreationMode={isCreationMode}
					getAccountPointOfSales={getAccountPointOfSales}
					putAccountPointOfSales={putAccountPointOfSales}
					setAccountCurrentTab={(e) => setAccountCurrentTab(e)}
					setIsCreationMode={(e) => setIsCreationMode(e)}
					getAccountConfiguration={(e) => getNewConfigurationAccount(e)}
					setClickOnConfiguration={(e) => setClickOnConfiguration(e)}
				/>
			}
		</div>
	);
}
angular
	.module('beyableSaasApp.Admin.EditAccount', [])
	.component('editAccountTooling', react2angular(EditAccountTooling, [], ['$http', '$rootScope', '$routeParams']));