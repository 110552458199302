import React from 'react';

function ModalSection({
	children,
	flex = 'fix',
	className = '',
	style,
	color = '',
	position = 'static',
	size = 'm'
}) {

	let cssClass = ['modal_section'];

	// className
	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Flex
	if (flex) {
		cssClass.push('flex_item_' + flex);
	}

	// Size
	if (size) {
		cssClass.push(size);
	}

	// Color
	if (color) {
		cssClass.push('modal_section_' + color);
	}

	// Position
	if (position == 'relative') {
		cssClass.push('pos_rel');
	}

	return (
		<div className={cssClass.join(' ')} style={style}>
			{children}
		</div>
	);
}
export default ModalSection;
