import React from 'react';
import './KpiContainer.css';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
export default function KpiContainer({ isClickable, onClickFunction, title, value, percent, color, titleKpi }) {
	const idTooltipOfKpi = uuidv4();
	return (
		<>
			<div className="statistics-kpi-container"
				style={{ backgroundColor: `${color}` }}
				data-for={idTooltipOfKpi}
				data-tip={titleKpi}>
				{isClickable &&
                    <button onClick={(e) => onClickFunction(e)}><i className="fa fa-chart-bar"></i></button>
				}
				<p className="card_title">{title}</p>
				<p className="data">{value}</p>
				<p className="percent">{percent !== undefined && '(' + percent + '%' + ')'}  </p>
			</div>
			<ReactTooltip id={idTooltipOfKpi} className="labelKpi" backgroundColor='black' delayHide={100} place="bottom" effect="solid" />
		</>
	);
}
