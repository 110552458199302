import React, { useEffect, useState } from 'react'
import './CodePomoContainer.css'
import InputCopyToClipBoard from './../../../Components/InputCopyToClipBoard';
export default function CodePromoContainer({ getTheAccountPromoCode, accountId }) {
    const [codePromokey, setCodepromoKey] = useState()
    const [keyIsUnknown, setKeyIsUnknown] = useState(false)
    const [clipBoardContain, setClipBoardContain] = useState(false);
    const copyToClipBoard = (value) => {
        navigator.clipboard.writeText(value)
        setClipBoardContain(value)
    }
    useEffect(() => {
        getTheAccountPromoCode(accountId, success => {
            const apiKey = success.config.apiSubscriptionKey
            if (apiKey == "unknown") {
                setKeyIsUnknown(true)
            } else {
                setCodepromoKey(apiKey)
            }
        },
            err => {
                console.log("status", err);
            })
    }, [])

    return (
        <div>
            {
                keyIsUnknown &&
                <div>
                    <p>Api Subscription Key is unknown, please ask your BEYABLE account manager</p>
                </div>
            }
            {
                !keyIsUnknown &&
                <div className="codePromo_Container">
                    <h2>Subscription Key</h2>
                    < InputCopyToClipBoard
                        clipBoardContain={clipBoardContain}
                        onClickFunction={(e) => copyToClipBoard(e)}
                        value={codePromokey}
                    />
                </div>
            }
        </div>
    )
}
