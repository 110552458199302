import {initInheritStyles} from './utils';

const icon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" viewBox="0 0 40 30">
  <defs>
    <clipPath id="clip-ico_img_1">
      <rect width="40" height="30"/>
    </clipPath>
  </defs>
  <g id="ico_img_1" data-name="ico_img – 1" clip-path="url(#clip-ico_img_1)">
    <g id="Rectangle_1197" data-name="Rectangle 1197" transform="translate(6 2)" fill="#e9eaf0" stroke="#acafc0" stroke-width="2">
      <rect width="28" height="26" rx="2" stroke="none"/>
      <rect x="1" y="1" width="26" height="24" rx="1" fill="none"/>
    </g>
    <g id="Intersection_5" data-name="Intersection 5" transform="translate(9304 1058)" fill="#fff">
      <path d="M -9272.0009765625 -1031.000366210938 L -9289.8193359375 -1031.000366210938 C -9290.21484375 -1031.000366210938 -9290.552734375 -1031.212890625 -9290.72265625 -1031.56884765625 C -9290.8935546875 -1031.924560546875 -9290.8466796875 -1032.320678710938 -9290.599609375 -1032.628295898438 L -9280.2783203125 -1045.439086914062 C -9280.0869140625 -1045.67626953125 -9279.8037109375 -1045.81201171875 -9279.4990234375 -1045.81201171875 C -9279.1953125 -1045.81201171875 -9278.9111328125 -1045.676147460938 -9278.7197265625 -1045.439086914062 L -9271.0009765625 -1035.85498046875 L -9271.0009765625 -1032.000122070312 C -9271.0009765625 -1031.448852539062 -9271.44921875 -1031.000366210938 -9272.0009765625 -1031.000366210938 Z" stroke="none"/>
      <path d="M -9272.0009765625 -1032.000122070312 L -9272.0009765625 -1035.502319335938 L -9279.4990234375 -1044.811645507812 L -9289.8203125 -1032.000854492188 C -9289.8203125 -1032.000732421875 -9289.8203125 -1032.00048828125 -9289.8212890625 -1032.000366210938 C -9289.8203125 -1032.000366210938 -9289.8203125 -1032.000366210938 -9289.8193359375 -1032.000366210938 L -9272.0009765625 -1032.000122070312 M -9272.0009765625 -1030.000366210938 L -9289.8193359375 -1030.000366210938 C -9291.501953125 -1030.000366210938 -9292.4326171875 -1031.946533203125 -9291.3779296875 -1033.255615234375 L -9281.056640625 -1046.06640625 C -9280.2568359375 -1047.059814453125 -9278.7431640625 -1047.061279296875 -9277.94140625 -1046.06640625 L -9270.0009765625 -1036.207641601562 L -9270.0009765625 -1032.000122070312 C -9270.0009765625 -1030.896362304688 -9270.89453125 -1030.000366210938 -9272.0009765625 -1030.000366210938 Z" stroke="none" fill="#acafc0"/>
    </g>
    <g id="Intersection_4" data-name="Intersection 4" transform="translate(9301 1058)" fill="#fff">
      <path d="M -9279.525390625 -1030.999633789062 L -9292.9990234375 -1030.999633789062 C -9293.55078125 -1030.999633789062 -9293.9990234375 -1031.4482421875 -9293.9990234375 -1031.999755859375 L -9293.9990234375 -1032.747680664062 L -9288.2646484375 -1039.544311523438 C -9288.072265625 -1039.771850585938 -9287.7998046875 -1039.89794921875 -9287.5 -1039.89794921875 C -9287.2001953125 -1039.89794921875 -9286.9287109375 -1039.772094726562 -9286.7353515625 -1039.543579101562 L -9279.525390625 -1030.999633789062 Z" stroke="none"/>
      <path d="M -9281.677734375 -1031.999633789062 L -9287.5009765625 -1038.8994140625 L -9292.9990234375 -1032.382202148438 L -9292.9990234375 -1031.999633789062 L -9281.677734375 -1031.999633789062 M -9277.373046875 -1029.999633789062 L -9292.9990234375 -1029.999633789062 C -9294.10546875 -1029.999633789062 -9294.9990234375 -1030.895874023438 -9294.9990234375 -1031.999755859375 L -9294.9990234375 -1033.113159179688 L -9289.029296875 -1040.189086914062 C -9288.228515625 -1041.134155273438 -9286.7705078125 -1041.134155273438 -9285.9716796875 -1040.189086914062 L -9277.373046875 -1029.999633789062 Z" stroke="none" fill="#acafc0"/>
    </g>
    <g id="Ellipse_137" data-name="Ellipse 137" transform="translate(12 7)" fill="#fff" stroke="#acafc0" stroke-width="2">
      <circle cx="3" cy="3" r="3" stroke="none"/>
      <circle cx="3" cy="3" r="2" fill="none"/>
    </g>
  </g>
</svg>
`;

export const componentImage = {
	model: {
		init() {
			this.on('active', (context) => {
				const el = this.getEl();
				const img = el.querySelector('img');
				if (!img) return;
				
				var event = new MouseEvent('dblclick', {
					'view': window,
					'bubbles': true,
					'cancelable': true
				});
				img.dispatchEvent(event);
			});
		},
		defaults: {
			name: 'Image',
			droppable: false,
			editable: false,
			layerable: true,
			tagName: 'div',
			components: '<img data-gjs-type="image_inner" />',
			traits: [
				{
					type: 'responsive',
					name: 'responsive',
					label: 'Responsive',
					changeProp: true,
					tab: 'responsive'
				}
			],
			stylable: ['margin'],
			style: {
				'display': 'flex',
				'margin-top': '8px',
				'margin-bottom': '8px',
				'margin-left': '0px',
				'margin-right': '0px',
				'text-align': 'left',
				'background-color': 'transparent',
			},
			'responsive': '',
			icon: icon
		}
	}
};

export const componentImageInner = {
	extend: 'image',
	model: {
		init() {
			initInheritStyles(this);
		},
		ineritedStyles: [
			{
				key: 'margin',
				prop: 'margin',
				type: 'select',
				label: 'Alignment',
				value: '0 auto',
				options: [
					{ id: '0 auto 0 0', name: 'Left'},
					{ id: '0 auto', name: 'Center'},
					{ id: '0 0 0 auto', name: 'Right'},
				],
				tab: 'style'
			},
			{
				key: 'width',
				prop: 'width',
				type: 'button_width',
				label: 'Width',
				value: 'auto',
				min: 10,
				max: 400,
				tab: 'style'
			},
			{
				key: 'border-radius',
				prop: 'border-radius',
				type: 'number',
				unit: 'px',
				label: 'Radius',
				value: 0,
				tab: 'style'
			},
			{
				key: 'background-color',
				prop: 'background-color',
				type: 'color',
				label: 'Background color',
				value: 'transparent',
				tab: 'style'
			},
		],
		defaults: {
			removable: false,
			draggable: false,
			droppable: false,
			selectable: false,
			layerable: false,
			hoverable: false,
			highlightable: false,
			//editable: false,
			void: true,
			// resizable: {
			// 	cl: true,
			// 	cr: true,
			// 	bl: false,
			// 	br: false,
			// 	bc: false,
			// 	tl: false,
			// 	tr: false,
			// 	tc: false,
			// 	step: 1,
			// },
			resizable: false,
			tagName: 'img',
			stylable: [],
			traits: [],
			style: {
				'height': 'auto',
				'min-height': 'none',
				'min-width': 'none',
				'margin': '0px',
				'max-width': '100%'
			}
		}
	}
};
