import { Template, Wysiwyg } from '../../../WysiwygEditor/Types/BespokeTypes';
import { Style } from './StyleTypes';

export enum EditorCreateOrUpdateMode {
    Create = 'create',
    Update = 'update'
}

export enum EditorStep {
	One = 1,
	Two = 2,
	Three = 3,
	Four = 4
}

export type EditorState = {
	sideBarStep: EditorStep,
	formatIsSelected: boolean,
	selectedFormat: Template | null,
	selectedStyle: Style | null,
	defaultStyle: Style | null,
	currentStyle: Style | null,
	currentWysiwyg: Wysiwyg | null,
}
