import React, { useContext, useState, useEffect } from 'react';
import { Context } from './Context/Context';
import SkuListComponent from './SkuListComponent';
import Btn from '../../../Components/Btn';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import Modal from '../../../Components/Modal';
import './MatchingList.css';
import CategoriesAccount from './CategoriesAccount';
export default function MatchingListEdition({ confirmMatchingList, createMatchingList, deleteMatchingList, getProductFeedCategory, accountId }) {
	const {
		closeMatchingListEdit,
		matchingListValues,
		changeMatchingListName,
		changeMatchingListValuesProductCible,
		changeMatchingListValuesRecommendation,
		matchingModeCible,
		setMatchingModeCible,
		matchingModeReco,
		setMatchingModeReco
	} = useContext(Context);
	const [categories, setCategories] = useState([]);
	const [openModalCategories, setOppenModalCategories] = useState(false);
	useEffect(() => {
		getProductFeedCategory(accountId, null,
			newData => {
				const catToSave = newData.map((cat, id) => ({ ...cat, id: id, toggle: false }));
				setCategories(catToSave);
			});
	}, []);
	const ttId = uuidv4();

	const deleteButton = () => (
		<div className="flex_item_fix ml_20">
			<a className="icon_btn" onClick={() => deleteMatchingList(matchingListValues, matchingListValues.otherShelvingRules.length)}>
				<i className="fas fa-trash"></i>
			</a>
		</div>
	);

	const main = () => (
		<div className="contentMatchingListEdition">
			{openModalCategories &&
                <Modal
                	isOpen={openModalCategories}
                	width="700"
                	height="500"
                	onClose={() => { setOppenModalCategories(false); }}
                >
                	<div className="modal_header has_border">
                        Products categories
                	</div>
                	<div className="modal_body">
                		<CategoriesAccount categories={categories} setCategories={(e) => setCategories(e)} />

                	</div>
                	<div className="modal_footer">

                	</div>
                </Modal>
			}

			<div className="flex flex_align_start">
				<div className="flex_item_full">
					<h1 className="titleMatchingList">Edit Custom join</h1>
				</div>
				{
					matchingListValues.id !== 'new' && deleteButton()                
				}
			</div>
			<p className="introMatchingList">Indicate which products you want to associate</p>
			<label htmlFor="nameMatchingList">Name of the association</label>
			<input className="nameMatchingList" type="text"
				onChange={changeMatchingListName}
				value={matchingListValues.name}
				name="nameMatchingList"
				required
			/>
			<div className="matching-list-type-list">
				<h2>From</h2>
				<div>
					<Btn
						onClickFunction={(e) => setMatchingModeCible('product')}
						message="Product"
						style="outline"
						color={matchingModeCible === 'product' ? 'primary' : 'secondary'}
					/>
					<Btn
						onClickFunction={(e) => setMatchingModeCible('category')}
						message="Category"
						style="outline"
						color={matchingModeCible === 'category' ? 'primary' : 'secondary'}
					/>
				</div>
				{matchingModeCible === 'category' &&
                    <span className="help-tooltip-categories" onClick={() => setOppenModalCategories(true)}>
                    	<i className="fas fa-question-circle" data-for={ttId} data-tip="Click to see the categories"></i>
                    </span>
				}
			</div>
			{
				matchingModeCible === 'product' ?
					<SkuListComponent
						type="matching"
						title="For those products:"
						data={matchingListValues.listSku}
						onChange={changeMatchingListValuesProductCible}
					/> :
					<>
						<ReactTooltip id={ttId} className="" backgroundColor='black' effect='solid' />
						<SkuListComponent
							type="matching"
							title="For those categories:"
							data={matchingListValues.listSku}
							onChange={changeMatchingListValuesProductCible}
						/>
					</>
			}
			<p className="advertTag">Product references (eg. SKU) should be separated with a line break, comma (&nbsp;,&nbsp;) or semi-colon&nbsp;(&nbsp;;&nbsp;)</p>
			<div className="matching-list-type-list">
				<h2>To</h2>
				<div>
					<Btn
						onClickFunction={(e) => setMatchingModeReco('product')}
						message="Product"
						style="outline"
						color={matchingModeReco === 'product' ? 'primary' : 'secondary'}
					/>
					<Btn
						onClickFunction={(e) => setMatchingModeReco('category')}
						message="Category"
						style="outline"
						color={matchingModeReco === 'category' ? 'primary' : 'secondary'}
					/>
				</div>
				{matchingModeReco === 'category' &&
                    <span className="help-tooltip-categories" onClick={() => setOppenModalCategories(true)}>
                    	<i className="fas fa-question-circle" data-for={ttId} data-tip="Click to see the categories"></i>
                    </span>
				}
			</div>
			{
				matchingListValues.listReco && matchingModeReco === 'product' ?
					<SkuListComponent
						type="matching"
						title="Associate those products:"
						data={matchingListValues.listReco}
						onChange={changeMatchingListValuesRecommendation}
					/> :
					<>
						<ReactTooltip id={ttId} className="" backgroundColor='black' effect='solid' />
						<SkuListComponent
							type="matching"
							title="Associate those categories:"
							data={matchingListValues.listReco}
							onChange={changeMatchingListValuesRecommendation}
						/>
					</>
			}
			<p className="advertTag">Product references (eg. SKU) should be separated with a line break, comma (&nbsp;,&nbsp;) or semi-colon&nbsp;(&nbsp;;&nbsp;)</p>
			<div className="matching_actions">
				<button className="matchingList_btn btn-cancelDelete" onClick={() => closeMatchingListEdit()}>Cancel</button>
				{
					matchingListValues.id === 'new' ?
						<button
							className="matchingList_btn btn-saveTag"
							disabled={matchingModeCible !== '' || matchingModeReco !== '' ? false : true}
							onClick={() => createMatchingList()}>Create </button>
						:
						<button
							className="matchingList_btn btn-saveTag"
							disabled={matchingModeCible !== '' || matchingModeReco !== '' ? false : true}
							onClick={() => { confirmMatchingList(matchingListValues.id); }}>Confirm </button>
				}
			</div>

		</div >
	);

	return (
		<>
			{ matchingListValues.id && main() }
		</>
	);

}
