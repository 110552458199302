import React, {} from 'react';
import RouterItems from '../Menu/RouterItems';
import {getFinalUrl} from '../Menu/utils';

let urlFeedConfiguration = '/ProductFeed/Config';
let urlFeedProducts = '/ProductFeed/Products';

export default function RouterWrapper({
	children,
	props
}) {

	let routerList = [
		{
			label : 'Product list',
			icon: 'list',
			link : getFinalUrl(urlFeedProducts, props),
			activeUrls: [urlFeedProducts]
		},
		{
			label : 'Feed configuration',
			icon: 'feed',
			link : getFinalUrl(urlFeedConfiguration, props),
			activeUrls: [urlFeedConfiguration]
		},
	];

	return (
		<RouterItems
			title="Product catalog"
			list={routerList}
			routes={<></>}
		>
			{children}
		</RouterItems>
	);
}
