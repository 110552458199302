import React, { useEffect, useState } from 'react';
import NavigationUsersAdmin from './NavigationUsersAdmin';
import { useAdminContext } from './userAdministrationContext'
export default function UsersAdministration({}) {
	const { 
		isLoading
	} = useAdminContext();

	return (
		<>
			<NavigationUsersAdmin />
			<div className="flex  flex_align_start user-administration-container">
				{isLoading &&
					<div className="spinner_overlay">
						<div className="spinner">
							<div className="rect1"></div>
							<div className="rect2"></div>
							<div className="rect3"></div>
							<div className="rect4"></div>
							<div className="rect5"></div>
						</div>
					</div>
				}
			</div>
		</>
	)
}
