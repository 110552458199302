import React, { useEffect, useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import UseInput from '../../../../Hooks/UseInput';
import './CatalogueProductFeedContainer.css';

import TableCatalogueContainer from './TableCatalogueContainer';
import Btn from '../../../../Components/Btn';
import SelectCustom from '../../../../Components/SelectCustom';
import SelectDropdown from '../../../../Components/SelectDropdown';
import EmptyState from '../../../../Components/EmptyState';
import FilterSearchProductFeedContainer from './FilterSearchProductFeedContainer';
import Panel from '../../../../Components/Panel';
import { queryNext, queryPrevious, querySearchProductNext, querySearchProductPrevious, queryAdvancedSearchPreviousProduct, queryAdvancedSearchNextProduct } from './Query';
import SearchBar from '../../../../Components/SearchBar';
import SpinnerWheel from '../../../../Components/SpinnerWheel';

export function transformConfigToList(obj){
	let arrayOfTenantsAndConfigs = [];
	for (const property in obj) {
		const currentObj ={
			key : property,
			feeds: [],
			links : obj[property]['_links']
		};
		const objToWork = obj[property].sources;
		for (const property in objToWork) {
			const objLinkFeed = {
				...objToWork[property],
				label : objToWork[property].type,
				id: objToWork[property].id,
				resourceIdentifier:  objToWork[property].resourceIdentifier,
				// link : objToWork[property]._links
			};
			currentObj.feeds.push(objLinkFeed);
		}
		arrayOfTenantsAndConfigs.push(currentObj);
	}
	return arrayOfTenantsAndConfigs;
}
export const  BO_API_URL = window.BEYABLE_env.BO_API_URL;
let urlNewFeed = '/ProductFeed/Edit';

export default function CatalogueProductFeedContainer({
	accountId,
	authServices,
	setIsConfigurationMode,
	tenantSelected,
	tenants,
	setTenantSelected,
	getProductFeedConfiguration,
	services,
	systemServices,isNewProductFeedConfig
}) {

	const [newData, setNewData] = useState();
	const [hasPrevious, setHasPrevious] = useState(null);
	const [hasNext, setHasNext] = useState(null);
	const [startCursor, setStartCursor] = useState(null);
	const [endCursor, setEndCursor] = useState(null);
	const [clickOnNext, setClickOnNext] = useState(false);
	const [clickOnPrevious, setClickOnPrevious] = useState(false);
	const [clickOnFilter, setCLickOnFilter] = useState(false);
	const [clickOnSearchqueryAdvanced, setClickOnSearchqueryAdvanced] = useState(false);
	const [totalArticles, setTotalArticles] = useState(10);
	const [isReloading, setIsRealoading] = useState(true);
	const [searchValue, setSearchValue] = useState();
	const [totalProduct, setTotalProducts] = useState();
	const [searchModeIsActive, setSearchModeIsActive] = useState(false);
	const [advancedSearchIsActive, setAdvancedSearchIsActive] = useState(false);
	const [catalogueIsEmpty, setCatalogueIsEmpty] = useState(false);
	const [objectOfFilterForQuerySearch, setObjectOfFilterForQuerySearch] = useState();
	const [isFirstRender, setIsFirstRender] = useState(true);
	const [chargeNewCatalogueByTenant, setChargeNewCatalogueByTenant] = useState(false);
	const [toggleChangeTenant, setToggleChangeTenant] = useState(false);

	const token = authServices.getAccessToken();
	const INITIAL_STATE = { elem: '' };
	const { value, handleChange } = UseInput(INITIAL_STATE);
	const [tenantsList, setTenantsList] = useState();

	const currentUser = authServices.GetAuthenticatedUser();
	const [isAdmin, setisAdmin] = useState(false);

	useEffect(() => {
		const isAdminTest = currentUser && currentUser.Role === 'Admin';
		setisAdmin(isAdminTest);
	}, []);

	const httpLink = createHttpLink({
		uri: window.BEYABLE_env.URI_GRAPHQL
	});

	const authLink = setContext((_, { headers }) => {
		// get the authentication token from local storage if it exists
		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			}
		};
	});
	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache()
	});
	const selectNameOfQuery = (v) => {
		setTenantSelected(v);
		setChargeNewCatalogueByTenant(true);
	};
	useEffect(() => {
		services.call(`${BO_API_URL}feature/${accountId}/productfeedconfig/summary`,'get',null,
			success => {
				const list = transformConfigToList(success?.tenantConfigs);
				const hasFeeds = list.some(t => t.feeds && t.feeds.length > 0);

				const listKeytenant = list.map(t =>
				{
					if(t.key !== ''){
						return {
							value :t.key,
							label : t.key
						};
					}else{
						return {
							value :t.key,
							label : 'Default'
						};
					}
				});
				setTenantsList(listKeytenant);
			},
			(error ,err)=>{
				console.error(error, 'err :' ,err);
		
				// 	if(err.status === 404)
				// 		services.call(`${window.BEYABLE_env.BO_API_URL}feature/${accountId}/productfeedconfig/`,'put', {  'tenantConfigs': {
				// 			'' : {
				// 	  'tenant': ''
				// 			}
				//   }},
				// 		sucess =>{
				// 		},
				// 		error =>{

			// 		}
			// 		);
			}
		);
	}, []);

	useEffect(() => {
		if (chargeNewCatalogueByTenant) {
			chargeProductOnArray(queryNext, null);
			setChargeNewCatalogueByTenant(false);
			setToggleChangeTenant(false);
		}
	}, [chargeNewCatalogueByTenant]);

	const chargeProductOnArray = (nameOfQuery, cursor) => {
		$('[title]').tooltip();
		setIsRealoading(true);
		client.query({
			query: nameOfQuery,
			variables: {
				cursor: cursor,
				total: totalArticles,
				accountId: accountId,
				tenant: tenantSelected,
				searchValue: value.elem,
				where: objectOfFilterForQuerySearch
			}
		}).then(response => {
			setIsRealoading(false);
			const arrayOfData = response.data.account.productCatalogue.products.edges.map(elem => elem.node);
			setHasPrevious(response.data.account.productCatalogue.products.pageInfo.hasPreviousPage);
			setHasNext(response.data.account.productCatalogue.products.pageInfo.hasNextPage);
			setNewData(arrayOfData);
			setStartCursor(response.data.account.productCatalogue.products.pageInfo.startCursor);
			setEndCursor(response.data.account.productCatalogue.products.pageInfo.endCursor);
			setTotalProducts(response.data.account.productCatalogue.products.totalCount);
			if (isFirstRender) {
				if (arrayOfData.length === 0) {
					setCatalogueIsEmpty(true);
				}
				setIsFirstRender(false);
			}
		}
		).catch(e => {
			setIsRealoading(false);
			console.log('err', e);
			systemServices.showError('An error occured');
		});
	};
	useEffect(() => {
		chargeProductOnArray(queryNext);
		setAdvancedSearchIsActive(false);
	}, []);

	useEffect(() => {
		if (clickOnNext) {
			if (advancedSearchIsActive) {
				chargeProductOnArray(queryAdvancedSearchNextProduct, endCursor);
			}
			else if (searchModeIsActive) {
				chargeProductOnArray(querySearchProductNext, endCursor);
			}
			else {
				chargeProductOnArray(queryNext, endCursor);
			}
			setClickOnNext(false);
		}
	}, [clickOnNext]);

	useEffect(() => {
		if (clickOnPrevious) {
			if (advancedSearchIsActive) {
				chargeProductOnArray(queryAdvancedSearchPreviousProduct, startCursor);
			}
			else if (searchModeIsActive) {
				chargeProductOnArray(querySearchProductPrevious, startCursor);
			}
			else {
				chargeProductOnArray(queryPrevious, startCursor);
			}
			setClickOnPrevious(false);
		}
	}, [clickOnPrevious]);
	useEffect(() => {
		if (clickOnSearchqueryAdvanced) {
			setAdvancedSearchIsActive(true);
			setClickOnSearchqueryAdvanced(false);
			chargeProductOnArray(queryAdvancedSearchNextProduct, null);
		}
	}, [clickOnSearchqueryAdvanced]);

	const selectChange = (e) => {
		const newSelectValue = parseInt(e.target.value);
		setTotalArticles(newSelectValue);
	};

	const valueForSelectArticles = [
		{ value: 10, label: 10 },
		{ value: 20, label: 20 },
		{ value: 30, label: 30 },
		{ value: 40, label: 40 },
		{ value: 50, label: 50 },
	];

	useEffect(() => {
		if (!searchModeIsActive) {
			chargeProductOnArray(queryNext, null);
		} else {
			chargeProductOnArray(querySearchProductNext, null);
		}
	}, [totalArticles]);
	const closeThePannel = () => {
		setCLickOnFilter(false);
	};
	const desactiveAdvancedSearchMode = () => {
		setAdvancedSearchIsActive(false);
		chargeProductOnArray(queryNext);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (value.elem.length !== 0) {
				setSearchModeIsActive(true);
				chargeProductOnArray(querySearchProductNext, null);
			} else {
				setSearchModeIsActive(false);
				chargeProductOnArray(queryNext);
			}
		}, 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [value]);

	return (
		<ApolloProvider client={client}>
			<Panel
				isOpen={clickOnFilter}
				onClose={() => closeThePannel()}
				side="right"
				width="730">
				<div className="panel_header"> Filter the catalogue</div>
				<FilterSearchProductFeedContainer
					setSearchValue={(e) => setSearchValue(e)}
					setClickOnSearchqueryAdvanced={(e) => setClickOnSearchqueryAdvanced(e)}
					objectOfFilterForQuerySearch={objectOfFilterForQuerySearch}
					setObjectOfFilterForQuerySearch={(e) => setObjectOfFilterForQuerySearch(e)}
					closeThePannel={closeThePannel}
				/>
			</Panel>

			{(!catalogueIsEmpty || tenantsList?.length > 1) &&
				<div className={catalogueIsEmpty ? 'page_block_header has_border flex' : 'page_block_header flex'}>
					<div className="flex_item_full">
						{tenantsList?.length > 1 &&
							<span className='mr_15'>
								<SelectDropdown
									label='Tenant'
									labelPosition='inner'
									optionsList={tenantsList && tenantsList}
									value={tenantSelected ? tenantSelected : ''}
									placeHolder='Select a tenant'
									onChange={(v) => selectNameOfQuery(v)}
								/>
							</span>
						}
						{!catalogueIsEmpty &&
							<>
								<SearchBar
									onChangeValue={(e) => handleChange(e)}
									valueInSearchBar={value.elem}
									placeholder={advancedSearchIsActive ? 'Advanced search' : 'Search for a product name or ID'}
									name="elem"
									width={'xl'}
									size={'l'}
									disabled={advancedSearchIsActive}
								/>
								{isAdmin &&
									<span className='ml_5'>
										{advancedSearchIsActive &&
											<Btn
												message={'Reset advanced search'}
												icon={'fas fa-ban'}
												style="ghost"
												color="grey"
												horizontalSize="xs"
												onClick={(e) => desactiveAdvancedSearchMode()}
											/>
										}
										<Btn
											message={advancedSearchIsActive ? 'Update advanced search' : 'Advanced search'}
											icon={advancedSearchIsActive ? 'fas fa-pen' : ''}
											style="ghost"
											color="grey"
											horizontalSize="xs"
											onClick={(e) => setCLickOnFilter(true)}
										/>
									</span>
								}
							</>
						}
					</div>
				</div>
			}

			{catalogueIsEmpty &&
				<EmptyState
					icon="fas fa-book"
					title="Catalog is empty"
					text={<>Connect your product feed to supply the catalog, <br/>it is used for many features.</>}
					verticalSize='xl'
					textSize='l'
					primaryAction={
						<Btn
							href={`${urlNewFeed}${location.search ||`?id=${accountId}`}&tenant=${tenantSelected || ''}`}
							message="New product feed"
						/>
					}
				/>
			}

			{newData && newData.length === 0 && !catalogueIsEmpty &&
				<EmptyState
					title="No result for this search"
					text="Try to search another term"
					verticalSize='xl'
					textSize='l'
				/>
			}

			{newData && newData.length !== 0 &&
				<>
					<TableCatalogueContainer
						data={newData}
						id={accountId}
						totalArticles={totalArticles}
					/>
					<div className="footer_product_feed_catalogue">
						<div>
							<SelectCustom
								name="totalArticles"
								optionsList={valueForSelectArticles}
								onChangeFunction={(e) => selectChange(e)}
								value={totalArticles}
								autoWidth={true}
							/>
							<span className='ml_10 grey_2'>items per page</span>
						</div>
						<div>
							{
								hasPrevious &&
								<Btn
									onClickFunction={(e) => setClickOnPrevious(true)}
									message="Previous"
								/>
							}
							{
								hasNext &&
								<Btn
									onClickFunction={(e) => setClickOnNext(true)}
									message="Next"
								/>
							}
						</div>
					</div>
				</>
			}
			{isReloading && <SpinnerWheel />}
		</ApolloProvider >
	);
}
