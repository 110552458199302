import React, { useContext, useState, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';
import './ThumbnailsVideo.css';
import { CreateBusinessOperationContext } from '../../Context/ContextCreateBusinessOperations';
import PopinConfirm from '../../../../../Components/PopinConfirm';
import Btn from '../../../../../Components/Btn';
import ReactTooltip from 'react-tooltip';
import { check } from '../../../../../Components/Can';
export default function ThumbnailsVideo({ banner, deleteABanner, openVideoWidget }) {
	const {
		operation,
		planAccount,
		userSimplifyRole,
	} = useContext(CreateBusinessOperationContext);

	const [bannersWithOperation, setBannersWithOperation] = useState([]);
	const [idToDelete, setIdToDelete] = useState();
	const [poppinIsOppen, setPoppinIsOppen] = useState(false);
	const [poppinCantDelete, setPoppinCantDelete] = useState(false);

	const idTooltipODelete = uuidv4();

	const sortBannersWithOperation = () => {
		let operations = [];
		operation.operation.locations.forEach(x => operations.push(x.bannerId));
		setBannersWithOperation(operations);
	};

	useEffect(() => sortBannersWithOperation(), []);
	const canDeleteABanner = check(planAccount, userSimplifyRole, 'simplifyOperation:canUploadImage', null);

	const openWidget = () => {
		openVideoWidget(banner);
	};
	return (
		<div className="bannersListItem has_preview">
			{poppinIsOppen &&
				<PopinConfirm
					cancel={() => setPoppinIsOppen(false)}
					title={'Are you sure you want to delete this video ?'}
					message={''}
				>
					<Btn
						onClickFunction={(e) => { setPoppinIsOppen(false); }}
						message="Cancel"
						color="secondary"
						style="outline"
					/>
					<Btn
						onClickFunction={(e) => { deleteABanner(idToDelete); setPoppinIsOppen(false); }}
						message="Delete"
						color="alert"
						icon="fas fa-trash-alt"
					/>
				</PopinConfirm>
			}
			{poppinCantDelete &&
				<PopinConfirm
					cancel={() => setPoppinCantDelete(false)}
					title={'The video is use on campaign'}
					message={'This video could not be removed because it is used in the context of a campaign.'}
				>
					<Btn
						onClickFunction={(e) => { setPoppinCantDelete(false); }}
						message="Ok"
						color="secondary"
						style="outline"
					/>
				</PopinConfirm>
			}
			<div className="bannersListItemImg is_video" onClick={() => openWidget()}>
				<img
					src={banner.imagePath}
					width={banner.metaData.width}
					height={banner.metaData.height}
				/>
			</div>
			<div className="bannersListItemName" title={banner.metaData.fileName && banner.metaData.fileName}>
				<div className="bannersListItemFloatText">
					{banner.metaData.title && banner.metaData.title}
				</div>
			</div>
			<div className="bannersListItemInfo">
				<div className="flex">
					<div className="flex_item_auto">
						{banner.metaData.youtubeUrl}
					</div>
					{canDeleteABanner && <>
						<ReactTooltip id={idTooltipODelete} backgroundColor='black' effect='solid' />
						<div className="flex_item_fix">
							<a className="bannersListItemAction"
								data-for={idTooltipODelete} data-tip={'Delete the video'}
								onClick={bannersWithOperation.includes(banner.bannerId) ?
									() => setPoppinCantDelete(true) :
									() => { setIdToDelete(banner.bannerId); setPoppinIsOppen(true); }}
							>
								<i className="fas fa-trash-alt"></i>
							</a>
						</div></>}

				</div>
			</div>
		</div>
	);
}