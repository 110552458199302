import React from 'react'
import { useEffect, useState } from 'react'
import SelectDropdown from '../../../../Components/SelectDropdown'

export default function AutocompleteContainer({
	optionsList,
	autocompleteValue,
	value,
	onAutocomplete,
	onChange,
	contextBasedOn,
	label,
	labelPosition,
	color,
	size = 'm',
	emptyState,
	autocompleteListIsLoading,
	autocompletePlaceholder = 'Search for a product ID'
}) {

	const [list, setlist] = useState([]);

	useEffect(() => {
		if (optionsList) {
			const newArr = optionsList.map(el => {
				return {
					value: el,
					label: el
				}
			});
			setlist(newArr);
		}
		else {
			setlist([]);
		}
	}, [optionsList,
		contextBasedOn,
		autocompleteValue,
		contextBasedOn]);
	function onAutoSelect(val) {
		const newValue = list.find(el => el.value === val);
		onChange(newValue);
	}

	return (
		<>
			<SelectDropdown
				optionsList={list}
				value={value.value}
				onChange={(v) => onAutoSelect(v)}
				friendlyValue={value.label}
				autocomplete={true}
				autocompleteIsLoading={autocompleteListIsLoading}
				autocompleteValue={autocompleteValue}
				autocompletePlaceholder={autocompletePlaceholder}
				onAutocomplete={(v) => onAutocomplete(v)}
				label={label}
				labelPosition={labelPosition}
				color={color}
				emptyState={emptyState}
				size={size}
			/>
		</>
	)
}
