import React, {useState, useEffect} from 'react';
import BooleanContainer from './BooleanContainer';
import BoundedNumberContainer from './BoundedNumberContainer';
import DateUtcContainer from './DateUtcContainer';
import NumberContainer from './NumberContainer';
import NumberRangeContainer from './NumberRangeContainer';
import StringContainer from './StringContainer';
import UrlContainer from './UrlContainer';
import TagContainer from './TagContainer';
import {useFiltersContext} from '../contextFilters';
import { v4 as uuidv4 } from 'uuid';

export default function ComponentAccordingToCollationItem(
	{ handleChangeFilterByUniqueId, filterList }
) {

	const {
		tabFilterSelected,
		setupAccountServices,
		accountId,
		tenantSelected,
		previewTenant,
		needTenantForSearch,
		elementsFilterListAvailableSelected
	} = useFiltersContext();

	const [relay, setRelay] = useState();
	useEffect(() => {
		if (tabFilterSelected.valueType.includes('TagValueRange', 'PercentRange')){
			setRelay(elementsFilterListAvailableSelected); // trigger change for range inputs
		}
	}, [elementsFilterListAvailableSelected, tabFilterSelected]);

	const [element, setelement] = useState(<></>);
	function getElement(){
		switch (tabFilterSelected.valueType) {
			case 'String':
				return <StringContainer
					key={uuidv4()}
					handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
					data={tabFilterSelected}
					setupAccountServices={setupAccountServices}
					accountId={accountId}
					selectedTenant={tenantSelected}
					previewTenant={previewTenant}
					needTenantForSearch={needTenantForSearch}
				/>;
	
	
			case 'Number':
				return <NumberContainer
					key={tabFilterSelected.id}
					handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
					data={tabFilterSelected}
				/>;
			case 'Boolean':
				return <BooleanContainer
					key={tabFilterSelected.id}
					handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
					data={tabFilterSelected}
				/>;
			case 'Date':
				return <DateUtcContainer
					key={tabFilterSelected.id}
					handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
					data={tabFilterSelected}
				/>;
			case 'PercentRange':
				return <NumberRangeContainer
					key={tabFilterSelected.id}
					handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
					data={tabFilterSelected}
					itemType={'percent'}
					filters={filterList}
				/>;
			case 'TagValueRange':
				return <NumberRangeContainer
					key={tabFilterSelected.id}
					handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
					data={tabFilterSelected}
					itemType={'tagValue'}
					filters={filterList}
					relay={relay}
				/>;
			case 'BoundedNumber':
				return <BoundedNumberContainer
					key={tabFilterSelected.id}
					handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
					data={tabFilterSelected}
				/>;
			case 'Url':
				return <UrlContainer
					key={tabFilterSelected.id}
					handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
					data={tabFilterSelected}
				/>;
			case 'Tag':
				return <TagContainer
					key={tabFilterSelected.id}
					handleChangeFilterByUniqueId={handleChangeFilterByUniqueId}
					data={tabFilterSelected}
					setupAccountServices={setupAccountServices}
					accountId={accountId}
				/>;
	
			default:
				break;
		}
	}
	useEffect(()=>{
		if(tabFilterSelected){
			setelement(getElement());
		}
	},[tabFilterSelected, relay]);
	return (<>{element}</>);
}


