
import formatMoment from '../../../../Constants/FormatMoment';
export default function PlanningUtil() {
    const { formatDateToLocal } = formatMoment;
    function set_operation_dates_for_display(op) {
        var tmp = {
            ...op,
            operation: {
                ...op.operation,
                startDateUtc: moment(op.startDate),
                endDateUtc: moment(op.endDate),
                startDate: formatDateToLocal(op.startDate),
                endDate: formatDateToLocal(op.endDate)
            }
        }

        let tmpEndDateFordisplay = op.endDate;
        if (!tmpEndDateFordisplay) {
            tmpEndDateFordisplay = "2100-01-01T22:00:00.000Z";
        }

        tmp.operation.endDateUtcForDisplay = moment(tmpEndDateFordisplay);
        tmp.operation.endDateForDisplay = formatDateToLocal(tmpEndDateFordisplay);

        return tmp;
    }

    return { set_operation_dates_for_display }
}