import React, {useState,useContext,useEffect} from 'react';
import { reportingCampaignContext } from '../../Context/ContextReportingCampaign';
import SelectDropdown from '../../../../Components/SelectDropdown';
import EmptyState from '../../../../Components/EmptyState';

type ValueAttribution = {
    value: string,
    label : string
};

export default function SelectedInteractions({defaultList}) {

	const [list, setlist] = useState<ValueAttribution[]>(defaultList);
	const [autocompleteValue, setAutocompleteValue] = useState();
	const {
		setinteractionSelected,
		objectiveSelected,
		interactionSelected,
		handleSaveObjectiveAndAttribution,
		searchInteractions
	} = useContext<any>(reportingCampaignContext);

	useEffect(()=>{
		const tmp =  defaultList.map(inter => {
			const newInter = {...inter};
			newInter.label = getInteractionName(newInter.label);
			return newInter;
		});
		setlist(tmp);
	},[defaultList]);

	function getInteractionName(i) {
		const name = i.replace('evtcpg:', '');
		return name;
	}

	function cleanInteractionList(data) {
		return data.map(key => {
			return {
				label : getInteractionName(key),
				value : key,
				istarget: true,
			};
		});
	}

	function handleChageAutcomplete(val) {
		setAutocompleteValue(val);
		searchInteractions(val, 1, 50, data => {
			const tmp = cleanInteractionList(data);
			setlist(tmp);
		}, err => {
			console.log('🚀🐱 😻 --///** ~ file: SelectedInteractions.tsx:47 ~ handleChageAutcomplete ~ err:', err)
		});
	}

	function handleSearchIndicator(evt) {
		setinteractionSelected(evt);
		handleSaveObjectiveAndAttribution(objectiveSelected,null,evt);
	}

	function onAutoSelect(val:string) {
		const newValue = list.find(el => el.value === val);
		handleSearchIndicator(newValue?.value);
	}

	return (
		<SelectDropdown
			label={'Interaction'}
			onChange={(v) => onAutoSelect(v)}
			onAutocomplete={(v) => handleChageAutcomplete(v)}
			labelPosition='inner'
			color='white'
			value={interactionSelected}
			optionsList={list}
			autocomplete={true}
			autocompleteValue={autocompleteValue}
			autocompletePlaceholder={'Search for an interaction'}
			emptyState={
				<EmptyState
					title="No available interaction"
					text="The campaign has no interaction for this period"
				/>
			}
		/>
	);
}
