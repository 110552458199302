import React, {useEffect, useState} from 'react';
import { CampaignsContext } from '../../context/index.js';
// @ts-ignore
import styles from './Custom.module.css';
import FormatCampaign from '../CustomCampaign/FormatCampaign';
import {listCampaignMobileFormat} from './utils';
import SectionMessage from '../../../../Components/SectionMessage';
import {useTranslation} from 'react-i18next';
import TemplateMobileEditor from './TemplateMobileEditor';
import InCollection from './InCollection';
import SelectorInPage from '../MobileEditor/SelectorInPage';

import { Section } from '../../../../Components/Section/Section';
import { Article } from '../../../../Components/Article/Article';
import { useWysiwygEditorContext } from '../../../WysiwygEditor/context/WysiwygEditorContext';


export default function MobileContainer() {

	const { t } = useTranslation(['common']);
	const {
		editorMode,
		variations,
		currentVariationId,
		getCurrentVariation,
		step,
		isSaving
	} = CampaignsContext();

	const {
		listOfShelvingRules,
		loadAllProductsShelvingRules
	} = useWysiwygEditorContext();

	const [currentVarHasFormat, setcurrentVarHasFormat] = useState<boolean>(false);
	const activeVariation = getCurrentVariation() || {};


	useEffect(()=>{
		const currentVariationSaved = getCurrentVariation();
		if(currentVariationSaved){
			
			const SlideFormat =  currentVariationSaved.SlideFormat;
			if(SlideFormat === '' || SlideFormat === null){
				setcurrentVarHasFormat(false);
			}else{
				setcurrentVarHasFormat(true);
			}
		
			
		}
	},[variations,currentVariationId,listOfShelvingRules]);

	useEffect(() => {
		loadAllProductsShelvingRules();
	}, []);

	if (editorMode !== 'appNative/patch/code') return <></>;
	if (step !== 1) return <></>;

	return (
		<>
			<Section width='s' hasMargin={true}>
				<Article title={t('customCampaign.campaignFormat')} hasPadding={false}>
					<FormatCampaign listCampaign={listCampaignMobileFormat}/>
					{!currentVarHasFormat &&
						<SectionMessage
							type="information"
							appearance='background'
							noMargin={false}
							text={t('customCampaign.chooseAFormat')}
						/>
					}
				</Article>
			</Section>
			{activeVariation && 
				<>
					{(activeVariation.SlideFormat === 'InCollection' || activeVariation.SlideFormat === 'InPage') &&
						<Section width='s' hasMargin={true}>
							<Article title={t('insert.CampaignPosition')}>
								{activeVariation.SlideFormat === 'InCollection' &&
									<SelectorInPage pathPlacement='RelativePlacement' pathSelector='PlacementSelector' />
								}
								{activeVariation.SlideFormat === 'InPage' &&
									<SelectorInPage pathPlacement='InPagePosition' pathSelector='InPageElementSelector' />
								}
							</Article>
						</Section>
					}
					{activeVariation.SlideFormat === 'InCollection' &&
						<InCollection />
					}
					{activeVariation.SlideFormat !== 'InCollection' &&
						<Section width='m' hasMargin={true}>
							<Article title={t('customCampaign.campaignCode')}>
								<TemplateMobileEditor />
							</Article>
						</Section>
					}
				</>
			}
			{isSaving && (
				<div className="mask">
					<div className="wheel size_32"></div>
				</div>
			)}
		</>
	);
}
