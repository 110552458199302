import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../Components/Checkbox';
import InputCustom from '../../../../Components/InputCustom';
import SectionMessage from '../../../../Components/SectionMessage';
import { useProductFeedEditionContext } from '../../context/ProductFeedEditionContext';
import Btn from '../../../../Components/Btn';

export default function ConnectionSwitch({
	inputSize = 'l',
	showResetButton = true
}) {
	const {
		step,
		productFeedObject,
		mode,
		handleChangeConnexion,
		handleChangeSimpleField,
		handleChangesTypeFormatField,
		handleChangesTypeFormatFieldObj,
		handleChangesTypeFormatFieldRichText,
		hasErrorsOnCreation,
		stateOfBackendDetection,
	} = useProductFeedEditionContext();

	const [connexionFields, setConnexionFields] = useState(<></>);

	useEffect(() => {
		setConnexionFields(getFields(productFeedObject?.auth?.type));
	}, [productFeedObject]);
	if (!productFeedObject.hasOwnProperty('auth')) {
		return <></>;
	}
	function handleChangeSimpleFieldFn(e) {
		const value = e.target.value;
		const name = e.target.name;
		handleChangeSimpleField(name, value);
	}
	function handleChangeField(e) {
		const value = e.target.value;
		const name = e.target.name;
		handleChangeConnexion(name, value);
	}

	function getAuthenticationFields(key) {
		switch (key) {
			case 'LoginPassword':
				return <>
					<InputCustom
						value={productFeedObject.auth?.login ?? ''}
						type="text"
						label={'Login'}
						name={'login'}
						placeholder=""
						onChange={handleChangeField}
						fullWidth={true}
						size={inputSize}
						autoFocus={true}
						autoComplete={false}
					/>
					<InputCustom
						value={productFeedObject.auth?.password ?? ''}
						type={'password'}
						label={'Password'}
						name={'password'}
						placeholder="Tape the password"
						onChange={handleChangeField}
						fullWidth={true}
						size={inputSize}
						autoComplete={false}
					/>
				</>;
			case 'ApiKey':
				return <>
					<InputCustom
						value={productFeedObject.auth?.apiKey ?? ''}
						type={'password'}
						label={'Api key'}
						name={'apiKey'}
						placeholder="4585yw5-54523cx-2125kj"
						onChange={handleChangeField}
						fullWidth={true}
						size={inputSize}
						autoFocus={true}
						autoComplete={false}
					/>
				</>;
			case 'SasToken':
				return <>
					<InputCustom
						value={productFeedObject.auth?.sasToken ?? ''}
						type={'password'}
						label={'Sas token'}
						name={'sasToken'}
						placeholder="4585yw5-54523cx-2125kj"
						onChange={handleChangeField}
						fullWidth={true}
						size={inputSize}
						autoFocus={true}
						autoComplete={false}
					/>
				</>;
			case 'AuthorizationHeader':
				return <>
					<InputCustom
						value={productFeedObject.auth?.authorizationHeaderName ?? ''}
						type={'text'}
						label={'Authorization header'}
						description='leave empty for "Authorization"'
						name={'authorizationHeaderName'}
						placeholder="Authorization"
						onChange={handleChangeField}
						fullWidth={true}
						size={inputSize}
						autoFocus={false}
						autoComplete={false}
					/>
					<InputCustom
						value={productFeedObject.auth?.authorizationHeaderContent ?? ''}
						type={'text'}
						label={'Authorization header value'}
						name={'authorizationHeaderContent'}
						placeholder="Basic ZGFmeS11YXQ6dWFnZWlaNXk=,Token 1CD70AD30C46DF21879FEDBEE"
						onChange={handleChangeField}
						fullWidth={true}
						size={inputSize}
						autoFocus={true}
						autoComplete={false}
					/>
				</>;
		}
	}

	function getFields(key) {
		return <>
			{getAuthenticationFields(key)}
			{showResetButton &&
				<div>
					<Btn
						onClick={() => handleChangeConnexion('type', null)}
						message="Change type of Authentication"
					/>
				</div>
			}
		</>;
	}



	return (
		<>
			{connexionFields}
			{connexionFields === <></> && hasErrorsOnCreation && !hasErrorsOnCreation?.key &&
				<SectionMessage
					type="warning"
					title="Error during process"
					text={hasErrorsOnCreation}
				/>
			}
			{connexionFields === <></> && hasErrorsOnCreation && hasErrorsOnCreation?.key === 'connectionFail' &&
				<SectionMessage
					type="information"
					title="We need some information"
					text={hasErrorsOnCreation.message}
				/>
			}
		</>
	);
}
