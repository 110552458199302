import React, {} from 'react';
import RouterItems from '../Menu/RouterItems';
import {getFinalUrl} from '../Menu/utils';
export default function RouterWrapper({
	children,
	props
}) {


	return (
		<RouterItems
			title="Simplify"
			list={[
				{
					type: 'item',
					label : 'Planning',
					icon : 'planning',
					activeUrls: ['/BusinessOperations/Planning'],
					link : getFinalUrl('BusinessOperations/Planning',props)
				},
				{
					type: 'item',
					label : 'Operation list',
					icon : 'list',
					activeUrls: ['/BusinessOperations/Dashboard'],
					link : getFinalUrl('BusinessOperations/Dashboard',props)
				},
				{
					type: 'item',
					label : 'Orchestration',
					icon : 'orchestration',
					activeUrls: ['/BusinessOperations/Orchestration'],
					link : getFinalUrl('BusinessOperations/Orchestration',props)
				},
			]}
			routes={<></>}
		>
			{children}
		</RouterItems>
	);
}
