import React, { useState ,useEffect} from 'react';
import Btn from '../../../Components/Btn';
import Panel from '../../../Components/Panel';
import { useEcommerceContext } from '../context/ContextEcommerce';
import { initialUtmFilterV2 } from './config';
import UtmEcommerceContainer from './UtmEcommerceContainer';
import { useTranslation } from 'react-i18next';

export default function UtmTraffic() {

	const [t] = useTranslation(['utils', 'utm']);
	const {
		utmDimensionsSelected,
		hansleSaveUtmDimensionFilter,
		resetUtmSelected,
		setOffset,
		setCurrentPage
	} = useEcommerceContext();

	const [panelIsOpen, setPanelIsOpen] = useState(false);
	const [utmDimensionDraft, setutmDimensionDraft] = useState(utmDimensionsSelected);
	const [button, setButton] = useState(<></>);
		
	function getLabelOfUtmFilters() {
		let label = '';
		let nb = 0;
		for (const prop in utmDimensionsSelected) {
			const value = utmDimensionsSelected[prop];
			if (value && value.length > 0) {
				nb = nb + 1;
				if (label === '') label = 'UTM ' + prop + '=' + value;
			}
		}
		if (nb == 0) return t('common.all');
		if (nb == 1) return label;
		return label + ' +' + (nb - 1);
	}

	function hasUTMFilter() {
		for (const prop in utmDimensionsSelected) {
			const value = utmDimensionsSelected[prop];
			if (value && value.length !== 0) {
				return true;
			}
		}
	}

	useEffect(() => {
		const tmpHasUTMFilter = hasUTMFilter();
		setButton(
			<button
				type="button"
				className={tmpHasUTMFilter ? 'custom_input bg_white m clickable w_auto highlighted' : 'custom_input bg_white m clickable w_auto'}
				onClick={() => setPanelIsOpen(true)}
			>
				<span className="custom_input_value has_label">
					<span className="custom_input_value_label">UTM</span>
					{utmDimensionsSelected ?
						<>{getLabelOfUtmFilters()}</>
						:
						<span className="custom_input_placeholder">{t('common.all')}</span>
					}
				</span>
				<span className="custom_input_arrow"></span>
			</button>
		);
	}, [utmDimensionsSelected,panelIsOpen]);

	function closePanel() {
		setPanelIsOpen(false);
	}

	function reset() {
		hansleSaveUtmDimensionFilter(initialUtmFilterV2);
		resetUtmSelected();
		setPanelIsOpen(false);
		setOffset(0);
		setCurrentPage(1);
	}

	function saveSettings() {
		hansleSaveUtmDimensionFilter(utmDimensionDraft);
		setPanelIsOpen(false);
	}
	function changeUTMSelected(utmType,value) {

		const newUtm = dimensions => ({
			...dimensions,
			[utmType]: value
		});
		setutmDimensionDraft(newUtm);
	}
	function deleteUtmGroupFilter(utmType){
		const newUtm = dimensions => ({
			...dimensions,
			[utmType]: ''
		});
		setutmDimensionDraft(newUtm);
	}
	function deleteUtmItem(utmType){
		const newUtm = dimensions => ({
			...dimensions,
			[utmType]: ''
		});
		setutmDimensionDraft(newUtm);
	}

	return (
		<>
			{button}
			<Panel
				isOpen={panelIsOpen}
				onClose={() => setPanelIsOpen(false)}
				noClose={true}
				side="right"
				width={500}>
				
				<>
					<div className='modal_header has_border flex'>
						<div className='flex_item_fix'>
							<a className='panel_close panel_close_left' onClick={closePanel}></a>
						</div>
						<div className='flex_item_full'>
							{t('utm.Filter by UTM', {ns: 'kpi'})}
						</div>
						<div className='flex_item_fix ml_30'>
							<Btn
								onClick={reset}
								message={t('actions.reset')}
								style="ghost"
								color="secondary"
							/>
							<Btn
								onClick={saveSettings}
								message={t('actions.apply')}
								color="primary"
							/>
						</div>
					</div>
					<div className='modal_body modal_body_grey'>
						<UtmEcommerceContainer
							utmDimensionDraft={utmDimensionDraft}
							changeUTMSelected={changeUTMSelected}
							utmKeyName='UtmSource'
							nameUtm={'source'}
							deleteUtmItem={deleteUtmItem}
							deleteUtmGroupFilter={()=>deleteUtmGroupFilter('source')}
						/>
						<UtmEcommerceContainer
							utmDimensionDraft={utmDimensionDraft}
							changeUTMSelected={changeUTMSelected}
							utmKeyName='UtmMedium'
							nameUtm={'medium'}
							deleteUtmItem={deleteUtmItem}
							deleteUtmGroupFilter={()=>deleteUtmGroupFilter('medium')}
						/>
						<UtmEcommerceContainer
							utmDimensionDraft={utmDimensionDraft}
							changeUTMSelected={changeUTMSelected}
							utmKeyName='UtmContent'
							nameUtm={'content'}
							deleteUtmItem={deleteUtmItem}
							deleteUtmGroupFilter={()=>deleteUtmGroupFilter('content')}
						/>
						<UtmEcommerceContainer
							utmDimensionDraft={utmDimensionDraft}
							changeUTMSelected={changeUTMSelected}
							utmKeyName='UtmCampaign'
							nameUtm={'campaign'}
							deleteUtmItem={deleteUtmItem}
							deleteUtmGroupFilter={()=>deleteUtmGroupFilter('campaign')}
						/>
					</div>										
				</>
			</Panel>
		</>
	);
}
