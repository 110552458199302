import React, {useEffect, useState, Fragment} from 'react';
import Panel from '../../Components/Panel';
import {useTriggerContext} from './TriggerContext';
import SearchBar from '../../Components/SearchBar';
import {groupBy} from 'lodash';
import {triggerCategories} from './CategoriesData.js';
import SectionMessage from '../../Components/SectionMessage';
import Link from '../../Components/Link';
import { Flag } from '../../Components/Flag/Flag';
import HelpDeskServices from '../../Services/HelpdeskServices';
import {useTranslation} from 'react-i18next';

export default function ConditionPanel({
	conditionPanelIsOpen,
	setConditionPanelIsOpen,
	addTriggerToActualActualEditingRule
}) {

	const {
		refTriggerSet,
		trackingMode,
		language
	} = useTriggerContext();
	const [t, i18n] = useTranslation('common');
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [dataFiltered, setDataFiltered] = useState();

	const [noData, setNoData] = useState();
	const [dataTriggersCategory, setDataTriggersCategory] = useState([]);
	const [refTriggerTrackingMode, setRefTriggerTrackingMode] = useState();

	const { askHelpAbout } = HelpDeskServices();

	const getTriggersWithCategories = (triggerSet) => {
		return triggerSet.map(t => ({
			...t,
			config: {
				type: t.config.type,
				operators: t.config.operators,
				needConfig: t.config.needConfig,
				saaS: t.config.saaS,
				separator: t.config.separator,
				trackingMode: t.config.trackingMode,
				category: t.config.category,
				categoryLabel: triggerCategories[t.config.category].Label,
				categoryOrder: triggerCategories[t.config.category].Order,
				categoryIcon: triggerCategories[t.config.category].Icon
			}
		}));
	};

	const addTrigger = (refTrigger) => {
		addTriggerToActualActualEditingRule(refTrigger, 'condition');
		setConditionPanelIsOpen(false);
	};

	useEffect(() => {
		if (!dataFiltered || dataFiltered.length === 0) {
			if (refTriggerTrackingMode) {
				setDataFiltered(getTriggersWithCategories(refTriggerTrackingMode));
			}
		}
	}, [refTriggerTrackingMode]);

	useEffect(() => {
		if (trackingMode === 1) {
			setRefTriggerTrackingMode(refTriggerSet.condition.filter(trigger => trigger.config.trackingMode === 'NoTracking'));
		}
		if (trackingMode === 2) {
			setRefTriggerTrackingMode(refTriggerSet.condition);
		}
	}, []);


	useEffect(() => {
		groupByTriggersCategory();
	}, [dataFiltered]);

	useEffect(() => {
		if (refTriggerTrackingMode) {
			search(refTriggerTrackingMode, valueInSearchBar);
		}
	}, [valueInSearchBar]);

	const groupByTriggersCategory = () => {
		const categoriesGrouped = groupBy(dataFiltered, d => [d.config.categoryLabel, d.config.categoryOrder, d.config.categoryIcon]);
		setDataTriggersCategory(Object.keys(categoriesGrouped)
			.map(t => {
				const [label, order, icon] = t.split(',');
				return {label, order, icon};
			})
			.filter(t => t.order > 0)
			.sort((t1, t2) => t1.order - t2.order));
	};

	const search = (table, word) => {

		if (word.length > 0) {
			let resultName = table.filter(line =>
				line.data[language].label !== null && line.data[language].label.toUpperCase().indexOf(word.toUpperCase()) > -1
                || (line.data[language].tooltip && line.data[language].tooltip.toUpperCase().indexOf(word.toUpperCase()) > -1)
			);
			let finalArray = [...resultName];
			let uniqueItems = [...new Set(finalArray)];
			if (uniqueItems.length === 0) {
				setNoData(language === 'fr' ? 'Pas de résulats pour cette recherche' : 'No result for this search');
				setDataFiltered();
			} else {
				setNoData();
				setDataFiltered(getTriggersWithCategories(uniqueItems));
			}
		} else {
			setNoData();
			setDataFiltered(getTriggersWithCategories(refTriggerTrackingMode));
		}
	};

	const handleChange = (value) => {
		setValueInSearchBar(value);
	};


	return (
		<Panel
			isOpen={conditionPanelIsOpen}
			onClose={(e) => setConditionPanelIsOpen(false)}
			noClose={true}
			side="right"
			closeOnOverlay={true}
			width={560}>

			<section className="modal_header flex_item_fix flex">
				<div className='flex_item_fix'>
					<a className='panel_close panel_close_left' onClick={(e) => setConditionPanelIsOpen(false)}></a>
				</div>
				<div className="">{t('triggers.addTArgetingCondition')}</div>
			</section>

			<section className="section no_top_pad flex_item_fix">
				<SearchBar
					placeholder={t('triggers.Search')}
					valueInSearchBar={valueInSearchBar}
					onChange={(value) => handleChange(value)}
					autoFocus={true}
					selectOnFocus={true}
				/>
			</section>
			<div className="flex_item_full vscroll pos_rel">
				<section className="section no_top_pad">
					{noData}
					{dataTriggersCategory.length > 0 &&
					<>
						{refTriggerSet.isPartialTriggerList &&
							<SectionMessage
								title={t('triggers.partialList')}partialList
								text={t('triggers.someTargeting')}
								icon="far fa-comment-dots"
								links={
									<Link 
										message={t('triggers.ContactUs')}
										onClick={() => askHelpAbout('MoreTriggers')}
									/>
								}
							/>
						}
						<div className="trigger_store">
							{dataTriggersCategory.map((cat, key) => {
								if (cat.label === 'Hidden') return <></>;
								return (
									<div className="trigger_store_category" key={key}>
										<div className="trigger_store_category_name">{cat.label}</div>
										<ul className="trigger_store_list">
											{dataFiltered &&
													dataFiltered.map((trigger, i) =>
														<Fragment
															key={key + '_' + i}
														>
															{trigger.config.categoryLabel === cat.label &&
																<li className="trigger_store_item flex"
																	onClick={() => addTrigger(trigger)}>
																	<div className="flex_item_fix">
																		<span
																			className={'trigger_icon icon_' + cat.icon}></span>
																	</div>
																	<div className="flex_item_full">
																		<div className="trigger_store_item_name">
																			{trigger.data[language].label}
																			{trigger.triggerName === 'TRGCHECKENGAGEMENT' &&
																				<Flag
																					icon="ai"
																					text="AI-powered"
																					tooltip="This feature is powered by BEYABLE AI"
																					className="ml_5"
																				/>
																			}
																		</div>
																		<div className="trigger_store_item_desc">
																			{trigger.data[language].tooltip}
																		</div>
																	</div>
																	{!trigger.config.saaS &&
																		<div className="flex_item_fix ml_10"
																			data-tip={'For administrators only'}>
																			<i className="trigger_store_item_type fas fa-crown"/>
																		</div>
																	}
																	{trigger.config.needConfig &&
																		<div className="flex_item_fix ml_10"
																			data-tip={'Need configuration'}>
																			<i className="trigger_store_item_type fa fa-wrench"/>
																		</div>
																	}
																</li>
															}
														</Fragment>
													)}
										</ul>
									</div>
								);
							}
							)}
						</div>
					</>
					}
				</section>
			</div>
		</Panel>
	);
}

