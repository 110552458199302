import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { DayPickerRangeController, isInclusivelyBeforeDay } from 'react-dates';
import Btn from '../../../../Components/Btn';
import Modal from '../../../../Components/Modal';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

export function CsvModal({ fetchCsvService }) {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [sessionDataCsv, setSessionDataCsv] = useState([]);
	const csvLink = useRef();
	const handleBtnClick = () => {
		setModalIsOpen(!modalIsOpen);
	};
	const handleClose = () => {
		setModalIsOpen(false);
		setIsLoading(false);
	};

	const [customDateRange, setCustomDateRange] = useState({
		startDate: moment().subtract(1, 'month'),
		endDate: moment().subtract(1, 'day'),
	});

	const handleOnDateChange = ({ startDate, endDate }) => {
		setCustomDateRange({
			startDate: startDate,
			endDate: endDate,
		});
	};

	const fetchDataCsv = () => {
		const fromDate = moment(customDateRange.startDate).format('MMM DD, YYYY');
		const toDate = moment(customDateRange.endDate).format('MMM DD, YYYY');
		setIsLoading(true);
		fetchCsvService(
			fromDate, toDate,
			(data) => {
				setSessionDataCsv(data);
				csvLink.current.link.click();
				setIsLoading(false);
			},
			(err) => {
				console.log(err);
				setIsLoading(false);
			}
		);
	};
	const [focusedInput, setFocusedInput] = useState('startDate');
	const onFocused = (focused) => {
		setFocusedInput(!focused ? 'startDate' : focused);
	};
	const DatePicker = () => {
		return (
			<DayPickerRangeController
				startDate={customDateRange.startDate}
				endDate={customDateRange.endDate}
				minimumNights={0}
				isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
				focusedInput={focusedInput}
				onFocusChange={(focused) => {
					onFocused(focused);
				}}
				onDatesChange={(dates) => {
					handleOnDateChange(dates);
				}}
				onClose={(dates) => {
					close(dates);
				}}
				numberOfMonths={3}
			/>
		);
	};
	const Loading = () => (
		<div className="mask">
			<span className="wheel"></span>
		</div>
	);
	return (
		<>
			<ReactTooltip backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" delayShow={600} />
			<Btn
				onClick={handleBtnClick}
				style="outline"
				color="secondary"
				dataTip="Export CSV"
				icon="fas fa-download"
				className="ml_8"
			/>
			{modalIsOpen && (
				<Modal
					isOpen={modalIsOpen}
					onClose={handleClose}
					width="994"
					animation="slide_down"
				>
					<div className="modal_header has_border">
						Export CSV
						<div className="modal_header_desc">Choose a date range to export</div>
					</div>
					<div className="modal_body">
						{DatePicker()}
						<CSVLink
							data={sessionDataCsv}
							filename={
								'Beyable_analytics_sheet_' +
								'from_' +
								moment(customDateRange.startDate).format('MMM DD, YYYY'
								) +
								'_to_' +
								moment(customDateRange.endDate).format('MMM DD, YYYY'
								)
							}
							className="hidden"
							separator={','}
							ref={csvLink}
							target="_blank"
						/>
					</div>
					<div className="modal_footer al_right">
						<Btn
							color="secondary"
							style="ghost"
							onClick={() => handleClose()}
							message="Cancel"
						/>
						{isLoading ? Loading() :
							<Btn
								onClick={() => fetchDataCsv()}
								message="Export"
							/>
						}
					</div>
				</Modal>
			)}
		</>
	);
}
