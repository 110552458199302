import React,{useState,useEffect } from 'react';
import Panel from '../../../Components/Panel';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import {useProductFeedConfigContext} from '../context/ProductFeedConfigContext';
import Modal from '../../../Components/Modal';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';
import Confirm from '../../../Components/Confirm';
import ModalHeader from '../../../Components/ModalHeader';
import ModalBody from '../../../Components/ModalBody';
import ModalFooter from '../../../Components/ModalFooter';
import SectionMessage from '../../../Components/SectionMessage';
import PanelArticle from '../../../Components/PanelArticle';
import EmptyState from '../../../Components/EmptyState';
import IconBtn from '../../../Components/IconBtn';
import {List, ListItem} from '../../../Components/List';

export default function PanelTenant({
	setEditTenantsPanelIsOpen,
	editTenantsPanelIsOpen,}) {
	const {
		isLoading,
		listTenant,
		tenantListKey,
		setisEmptyTenantAndNoFeed,
		isEmptyTenantAndNoFeed,
		handleCreateOrEraseTenant,
		handleDeleteTenant,
		handlePutTenantName
	} = useProductFeedConfigContext();
	const idTooltipOfDel = uuidv4();
	const idTooltipOfEdit = uuidv4();
	const [modalAddTenantIsOpen, setModalAddTenantIsOpen] = useState(false);
	const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);
	const [tenantNameToDelete, setTenantToDelete] = useState('');
	const [mode, setmode] = useState('creation');
	const [tenantName, setTenantName] = useState('');
	const [tenantKeySelected, settenantKeySelected] = useState();
	function isEmptyTenant(){
		if(listTenant.length === 1 ){
			if(listTenant[0].feeds.length === 0 && listTenant[0].key === '' ){
				setisEmptyTenantAndNoFeed(true);
			}else{
				setisEmptyTenantAndNoFeed(false);
			}
		}else{
			setisEmptyTenantAndNoFeed(false);
		}
	}
	function deletATenant(nameOfDetantToDelete){
		setTenantToDelete(nameOfDetantToDelete);
		setDeleteConfirmIsOpen(true);
	}
	function handleDeleteTenantFn(){
		handleDeleteTenant(tenantNameToDelete,() =>{
			setDeleteConfirmIsOpen(false);
			setTenantToDelete('');
		} );
	}

	function modifyATenant(tenant,nameOfDetantToModify){
		setmode('edit');
		setModalAddTenantIsOpen(true);
		settenantKeySelected(tenant);
		setTenantName(nameOfDetantToModify);
	}
	function confirmModifTenantName(){
		handlePutTenantName(tenantKeySelected,tenantName,
			()=>{
				setModalAddTenantIsOpen(false);
				setTenantName('');
				settenantKeySelected();
			});
	}
	function createATenant(){
		setmode('create');
		setTenantName('');
		setModalAddTenantIsOpen(true);
	}
	function handleCreateTenant(){
		handleCreateOrEraseTenant(tenantName,()=>setModalAddTenantIsOpen(false));
	}

	function handleChangeTenantName(e){
		const value = e.target.value;
		setTenantName(value);
	}

	function confirmFn(e){
		if(mode === 'create'){
			handleCreateTenant(e);
		}else {
			confirmModifTenantName(e);
		}
	}

	useEffect(()=>{
		if(listTenant){
			isEmptyTenant();
		}else{
			setisEmptyTenantAndNoFeed(true);
		}
	},[listTenant]);

	return (
		<>
			<Panel
				isOpen={editTenantsPanelIsOpen}
				onClose={() => setEditTenantsPanelIsOpen(false)}
				width={500}
				closeOnOverlay={true}
				noClose={true}
			>
				<ModalHeader>
					<div className='flex'>
						<div className="flex_item_fix">
							<a className="panel_close panel_close_left" onClick={() => setEditTenantsPanelIsOpen(false)}></a>
						</div>
						<div className='flex_item_full'>
							Tenants
						</div>
					</div>
				</ModalHeader>
				<ModalBody color="grey" position='relative'>
					{isEmptyTenantAndNoFeed &&
						<EmptyState
							title='No tenant yet'
							text={
								<>Multiple catalogs can be managed thanks to tenants.<br/>
								For instance, a catalog for France and another for the UK</>
							}
							primaryAction={
								<Btn
									onClick={(e) =>createATenant()}
									message="Add tenant"
								/>
							}
							verticalAlign={true}
						/>
					}
					{!isEmptyTenantAndNoFeed && 
						<>
							<ReactTooltip
								backgroundColor="black"
								effect="solid"
								place="bottom"
								globalEventOff="click"
								delayShow={600}
							/>
							<SectionMessage
								title='Multiple catalogs can be managed with tenants'
								text='For instance, a catalog for France and another for the UK'
								type="information"
							/>
							<PanelArticle>
								<List hasLastBorder={true}>
									{tenantListKey.map(t => 
										<ListItem
											key={t.value}
											text={t.label}
											actions={
												<>
													<IconBtn
														tooltip="Edit tenant"
														icon="fas fa-pen"
														onClick={()=>modifyATenant(t.value, t.label)}
													/>
													<IconBtn
														tooltip="Delete tenant"
														icon="fas fa-trash"
														onClick={()=>deletATenant(t.label)}
														hoverColor={'alert'}
													/>
												</>
											}>
										</ListItem>
									)}
								</List>
								<div className='mt_20'>
									<Btn
										onClickFunction={(e) =>createATenant()}
										message="Add tenant"
										color="primary"
									/>
								</div>
							</PanelArticle>
						</>
					}
				</ModalBody>
			</Panel>
			<Modal
				isOpen={modalAddTenantIsOpen}
				width="440"
				onClose={() => setModalAddTenantIsOpen(false) }
				animation="slide_down"
			>
				<ModalHeader title={mode === 'create' ? 'New tenant' : 'Rename tenant'} />
				<ModalBody>
					<InputCustom
						type="text"
						placeholder="Tenant name"
						value={tenantName}
						onChange={(e) => handleChangeTenantName(e)}
						fullWidth={true}
						size="l"
					/>
				</ModalBody>
				<ModalFooter
					primaryAction={
						<Btn
							onClick={()=>confirmFn()}
							message="Confirm"
							color="primary"
						/>
					}
					secondaryAction={
						<Btn
							onClick={() => setModalAddTenantIsOpen(false)}
							message="Cancel"
							color="secondary"
							style="ghost"
						/>
					}
				/>
			</Modal>
			<Confirm
				isOpen={deleteConfirmIsOpen}
				onClose={(e) => {setDeleteConfirmIsOpen(false);setTenantToDelete('');}}
				title="Delete tenant"
				text={<>Are you sure to delete the tenant <strong>{tenantNameToDelete}?</strong></>}
				confirmText="Delete"
				confirmCallback={(e) => handleDeleteTenantFn() }
				confirmColor={'alert'}
			/>
		</>
	);
}
