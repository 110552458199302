import { TriggerGroup, SegmentGroupWithTriggersApiType, SegmentGroupStatus } from '../../../../Services/SegmentGroupServicesTypes';


// export type EditingTrigger = {
// 	EditingTriggerGroup
// }

export type EditingTrigger = {
	Id: string
	Label: string | null,
	ValueToCompare:any;
	OperatorName:string;
	Name:string;
	Group:EditingTrigger;
}


export type EditingSegmentGroupWithTriggers = {
	id: string | null;
	name: string;
	triggerGroups: TriggerGroup[];
	status?: SegmentGroupStatus;
}

export function convertSegmentGroupApiTypeToEditingType(apiType: SegmentGroupWithTriggersApiType): EditingSegmentGroupWithTriggers {
	return { id: apiType.id, name: apiType.name, triggerGroups: apiType.triggerGroups };
}