import React,{useState,useEffect } from 'react';
import {useProductFeedConfigContext} from '../context/ProductFeedConfigContext';
import Btn from '../../../Components/Btn';
import PanelTenant from './PanelTenant';

export default function NavProductFeedConfig() {
	const {
		isLoading,
	} = useProductFeedConfigContext();

	const [editTenantsPanelIsOpen, setEditTenantsPanelIsOpen] = useState(false);
	function handleOpenPanelTenants(params) {
		setEditTenantsPanelIsOpen(true);
	}
	return (
		<>
			{!isLoading  &&
				<Btn
					message="Tenant"
					icon='fas fa-plus fa-sm'
					color='secondary'
					style='ghost'
					onClickFunction={handleOpenPanelTenants}
				/>
			}
			<PanelTenant 
				setEditTenantsPanelIsOpen={setEditTenantsPanelIsOpen}
				editTenantsPanelIsOpen={editTenantsPanelIsOpen}
			/>
		</>
	);
}
