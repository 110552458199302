import React, { useState, useEffect, Fragment } from 'react';
import './SideBar.css';
import { CampaignsContext } from '../../context';
import Btn from '../../../../Components/Btn';
import Confirm from '../../../../Components/Confirm';
import Switch from '../../../../Components/Switch';
import EmptyState from '../../../../Components/EmptyState';
import {useTranslation} from 'react-i18next';

export default function SideBar(props) {

	const { t, i18n } = useTranslation(['common']);

	const {
		editorState,
		formatSavedStyles,
		canPublishStyles,
		handleStyleSelected,
		handleStyleChosen,
		handleStyleDeleted,
		handleStylePublished,
		displayBackToFormat,
		handleBackToFormat,
		isModuleEditorMode
	} = CampaignsContext();

	const [deleteStyleConfirmation, setDeleteStyleConfirmation] = useState(false);
	const [publishStyleConfirmation, setPublishStyleConfirmation] = useState(false);
	const [unpublishStyleConfirmation, setUnpublishStyleConfirmation] = useState(false);
	const [loadingAnimationStart, setLoadingAnimationStart] = useState(false);
	const [loadingAnimationEnd, setLoadingAnimationEnd] = useState(false);

	const beyableStyles = formatSavedStyles.filter(s => s.isPublished === true);
	const userStyles = formatSavedStyles.filter(s => s.isPublished !== true);

	const confirmDeleteStyle = () => {
		const { style } = deleteStyleConfirmation;
		if (style) {
			handleStyleDeleted(style);
		}
		setDeleteStyleConfirmation(false);
	};

	const confirmPublishStyle = () => {
		const { style } = publishStyleConfirmation;
		if (style) {
			handleStylePublished(style, true);
		}
		setPublishStyleConfirmation(false);
	};

	const confirmUnpublishStyle = () => {
		const { style } = unpublishStyleConfirmation;
		if (style) {
			handleStylePublished(style, false);
		}
		setUnpublishStyleConfirmation(false);
	};

	const handleStyleBeforeChosen = () => {
		if (!isModuleEditorMode()) {
			handleStyleChosen();
			return;
		}

		// Fake computing animation
		setLoadingAnimationStart(true);
		setTimeout(function(){
			setLoadingAnimationEnd(true);
		}, 2200);
		setTimeout(function(){
			handleStyleChosen();
			setLoadingAnimationEnd(false);
			setLoadingAnimationStart(false);
		}, 3000);
	}

	const styleComponent = (style, id, text, canBeDeleted, canBePublished) =>
		<div key={id} onClick={() => handleStyleSelected(style)} className={`format_sidebar_item ${editorState.selectedStyle.id === id ? 'active' : ''}`}>
			<div className="format_sidebar_item_body">
				<div className="format_sidebar_item_name">{text}</div>
			</div>

			{canBePublished && 
                <span className='mr_10'>
                	<Switch
                		forcedChecked={style.isPublished}
                		onChange={(e) => {e.target.checked === true ? setPublishStyleConfirmation({ style }) : setUnpublishStyleConfirmation({ style });}}
                		size="s"
                	/>
                </span>
			}
                    
			{canBeDeleted && !style.isPublished &&
                <div className='format_sidebar_item_action' onClick={(e) => { e.stopPropagation(); setDeleteStyleConfirmation({ style }); }}>
                	<i className='fas fa-trash'></i>
                </div>
			}
			{canBeDeleted && canBePublished && style.isPublished &&
                <div className='format_sidebar_item_action disabled' onClick={(e) => { e.stopPropagation(); }}>
                	<i className='fas fa-trash'></i>
                </div>
			}
		</div>;

	return (
		<>
			<section className="format_sidebar_section flex_item_full vscroll_custom">
				{editorState.currentStyle &&
                    <div className='format_sidebar_item_group'>
                    	{styleComponent(editorState.currentStyle.style, 'current', editorState.currentStyle.isNotSaved ? 'Current style (not saved)' : 'Current style', false, false)}
                    </div>
				}

				<div className='format_sidebar_item_group'>
					<div className='format_sidebar_item_group_label'>Style library</div>
					{styleComponent(editorState.defaultStyle, 'default', 'Default style', false, false)}
					{beyableStyles && beyableStyles.map(style => styleComponent(style, style.id, style.name, true, canPublishStyles))}
				</div>

				{userStyles && userStyles.length >= 1 &&
                    <div className='format_sidebar_item_group'>
                    	<div className='format_sidebar_item_group_label'>Saved styles</div>
                    	{userStyles.map(style => styleComponent(style, style.id, style.name, true, canPublishStyles))}
                    </div>
				}
			</section>
			<section className="format_sidebar_section format_sidebar_footer flex_item_fix">
				<div className='flex'>
					{displayBackToFormat &&
						<div className='format_sidebar_btn_col'>
							<Btn
								color="secondary"
								style="outline"
								icon="fas fa-chevron-left"
								message={t('designEditor.backToTemplates')}
								size="l"
								fullWidth={true}
								onClick={() => handleBackToFormat()}
							/>
						</div>
					}
					<div className='format_sidebar_btn_col'>
						<Btn
							onClick={() => handleStyleBeforeChosen()}
							disabled={!editorState.selectedStyle.id}
							size="l"
							fullWidth={true}
							message={t('designEditor.chooseThisStyle')}
							icon={displayBackToFormat ? 'fas fa-chevron-right' : ''}
							iconPosition="after"
						/>
					</div>
				</div>
			</section>
			<Confirm
				isOpen={deleteStyleConfirmation}
				onClose={(e) => setDeleteStyleConfirmation(false)}
				title="Delete style"
				text="Are you sure you want to delete this style?"
				cancelText="Cancel"
				confirmText="Delete"
				confirmColor="alert"
				confirmCallback={(e) => confirmDeleteStyle()}
			/>
			<Confirm
				isOpen={publishStyleConfirmation}
				onClose={(e) => setPublishStyleConfirmation(false)}
				title="Publish style"
				text="Are you sure you want to publish this style?"
				cancelText="Cancel"
				confirmText="Publish"
				confirmCallback={(e) => confirmPublishStyle()}
			/>
			<Confirm
				isOpen={unpublishStyleConfirmation}
				onClose={(e) => setUnpublishStyleConfirmation(false)}
				title="Publish style"
				text="Are you sure you want to unpublish this style?"
				cancelText="Cancel"
				confirmText="Unpublish"
				confirmColor="alert"
				confirmCallback={(e) => confirmUnpublishStyle()}
			/>
			{loadingAnimationStart &&
				<div className={loadingAnimationEnd ? "format_loading_wrapper animated bounceOutDown" : "format_loading_wrapper animated bounceInDown"}>
					<EmptyState
						title="Almost READY!"
						text="Applying selected styles..."
						textSize="xl"
						spinner={!loadingAnimationEnd}
						icon={loadingAnimationEnd ? 'fas fa-check-circle c_green' : null }
					/>
				</div>
			}
		</>
	);
}
