const initType = [
	{ key: 'Page', label: 'Page' }, 
	{ key: 'PageType', label: 'Page Type' }, 
	{ key: 'Tag1', label: 'Custom Dimension 1' }, 
	{ key: 'Tag2', label: 'Custom Dimension 2' }, 
	{ key: 'Tag3', label: 'Custom Dimension 3' }, 
	{ key: 'Tag4', label: 'Custom Dimension 4' }, 
	{ key: 'Tag5', label: 'Custom Dimension 5' }];
    
export const metricsTypes = [
	{
		key: 'Revenue',
		label: 'Revenue'
	},
	{
		key: 'ProductQuantitySum',
		label: 'Quantity'
	},
	{
		key: 'ProductPrice',
		label: 'Average amount'
	},
	{
		key: 'ProductQuantity',
		label: 'Average quantity in transactions'
	},
	{
		key: 'TransactionCount',
		label: 'Transaction count'
	},
	{
		key: 'TransactionAmount',
		label: 'Transaction average amount'
	},
	{
		key: 'DetailsToCartRatio',
		label: 'Details to cart ratio'
	},
	{
		key: 'DetailsToSaleRatio',
		label: 'Details to sale ratio'
	},
	{
		key: 'SessionToSaleRatio',
		label: 'Session to sale ratio'
	},

];
// {
// 	Revenue,
// 	SessionToSaleRatio,
// 	TransactionCount,
// 	AverageTransactionAmount,

// 	ProductQuantity,
// 	AverageProductPrice,
// 	AverageProductQuantity,

// 	DetailsToCartRatio,
// 	DetailsToSaleRatio,

// 	Tax,
// 	Shipping,
// 	EventDate
// }
export const Revenue = 'Revenue';
export const Quantity = 'ProductQuantitySum';
export const AverageAmount = 'ProductPrice';
export const AverageQuantityInTransactions = 'ProductQuantity';
export const TransactionCount = 'TransactionCount';

export const TransactionAverageAmount = 'TransactionAmount';
export const DetailsToCartRatio = 'DetailsToCartRatio';
export const DetailsToSaleRatio = 'DetailsToSaleRatio';
export const SessionToSaleRatio = 'SessionToSaleRatio';

export const ListItemViewedCount = 'ListItemViewedCount';
export const ListItemSelectedCount = 'ListItemSelectedCount';
export const ItemAddedToCartCount = 'ItemAddedToCartCount';
export const CheckoutStartedCount = 'CheckoutStartedCount';


export const metricsTypesGRaph = [
	{
		key: 'Revenue',
		label: 'Revenue',
		value : [Revenue]
	},
	{
		key: 'ListItemViewedCount',
		label: 'Product list views',
		labelChart : 'ListItemViewedCount',
		value : [ListItemViewedCount]
	},
	{
		key: 'ListItemSelectedCount',
		label: 'Product list clicks',
		labelChart : 'Product list clicks',
		value : [ListItemSelectedCount]
	},
	{
		key: 'ItemAddedToCartCount',
		label: 'Product adds to cart',
		labelChart : 'ItemAddedToCart',
		value : [ItemAddedToCartCount]
	},
	{
		key: 'CheckoutStartedCount',
		label: 'Product checkouts',
		labelChart : 'CheckoutStartedCount',
		value : [CheckoutStartedCount]
	},
	{
		key: 'TransactionCount',
		label: 'Unique transaction',
		labelChart : 'TransactionCount',
		value : [TransactionCount]
	},
	
];
export const sourceTypes = [
	// 'All',
	'Direct',
	'Organic',
	'Referral',
	'Social',
	'Paid',
	'Email',
	'Display',
	'Other',
];

export const reportTypes = [
	'Product',
	'Category',
	'Brand'
];



export default initType;