import React, {} from 'react';
import { react2angular } from 'react2angular';

export default function DemoDashboard(props) {

	const styles = {
		'display': 'block',
		'width': '1034px',
		'max-width': '100%',
		'margin': '20px auto 30px',
		'pointer-events': 'none'
	};
	
	return (
		<div className='page_full page_full_grey vscroll'>
			<div className='page_full_inner'>
				<section className='section'>
					<img src="/Assets/demo/demo-dashboard.png" style={styles} />
				</section>
			</div>
		</div>
	);
}
angular
	.module('beyableSaasApp.DemoDashboard', [])
	.component('demodashboard', react2angular(DemoDashboard, [], ['$http', '$rootScope', '$routeParams']));