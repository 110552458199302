import React, { useEffect, useState, Fragment } from 'react';
import SearchBar from '../../../Components/SearchBar';
import Btn from '../../../Components/Btn';
import OrchestrationGroupItem from './OrchestrationGroupItem';
import Modal from '../../../Components/Modal';
import InputCustom from '../../../Components/InputCustom';
import PopinConfirm from '../../../Components/PopinConfirm';
import { Article } from '../../../Components/Article/Article';
import EmptyState from '../../../Components/EmptyState';
import Confirm from '../../../Components/Confirm';
import SpinnerWheel from '../../../Components/SpinnerWheel';
export default function OrchestrationGroupDashboard({ setCurrentTab, setCurrentOrchestration, accountId, orchestrationServices, systemServices }) {
	const [groups, setGroups] = useState();
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();
	const [modalCOnfirmIsOppen, setModalCOnfirmIsOppen] = useState(false);
	const [groupToDelete, setGroupToDelete] = useState();
	const [isReloading, setIsRealoading] = useState(true);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [oppenCreationOrchestration, setOppenCreationOrchestration] = useState(false);
	const [orchestrationNewGroup, setOrchestrationNewGroup] = useState({
		name: '',
		description: ''
	});

	const getData = () => {
		setIsRealoading(true);
		orchestrationServices.getOrchestrations(accountId,
			data => {
				setGroups(data);
				setIsRealoading(false);
			},
			error => {
				console.error('error', error);
				systemServices.showError('An error occured while fetching the orchestrations');
				setIsRealoading(false);
			});
	};
	const postNewOrchestration = () => {
		setIsRealoading(true);
		orchestrationServices.postOrchestration(accountId, orchestrationNewGroup,
			success => {
				setCurrentOrchestration(success);
				setCurrentTab(2);
			},
			error => {
				setIsRealoading(false);
				console.error('error', error);
				systemServices.showError('An error occured during the creation of the new group');
			});
	};
	const deleteAnOrchestration = (idOrchestration) => {
		setIsRealoading(true);
		orchestrationServices.deleteOrchestration(accountId, idOrchestration,
			success => {
				systemServices.showSuccess('The group has been deleted with success');
				setModalCOnfirmIsOppen(false);
				setGroupToDelete();
				setValueInSearchBar('');
				getData();
				setIsRealoading(false);
			},
			error => {
				console.error('error', error);
				setIsRealoading(false);
				systemServices.showError('An error occured during the delete of the group');
			});
	};
	useEffect(() => {
		getData();
	}, []);
	const handleChange = (event) => {
		setValueInSearchBar(event.target.value);
	};
	const handleChangeNeworchestrationGroup = (evt) => {
		const newValues = evt.target.value;
		let myStateToChange = { ...orchestrationNewGroup };
		myStateToChange[evt.target.name] = newValues;
		setOrchestrationNewGroup(myStateToChange);
	};
	const closeModal = () => {
		setOppenCreationOrchestration(false);
		setOrchestrationNewGroup({
			name: '',
			description: ''
		});
	};
	useEffect(() => {
		let str = valueInSearchBar.trim();
		if (groups) {
			search(groups, str);
		}
	}, [valueInSearchBar]);

	const search = (table, word) => {
		if (word.length >= 0) {
			let resultName = table.filter(
				line =>
					line.group.name !== null && line.group.name.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let resultDescription = table.filter(
				line =>
					line.group.description !== null && line.group.description.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let finalArray = [...resultName, ...resultDescription];
			let uniqueItem = [...new Set(finalArray)];
			if (uniqueItem.length === 0) {
				uniqueItem = 'No group matches with this search';
				setNoData(uniqueItem);
				setDataFiltered();
			}
			else {
				setNoData();
				setDataFiltered(uniqueItem);
			}
		} else {
			setDataFiltered(groups);
		}
	};
	const loadDataOfOrchestrationgroup = (e) => {
		setCurrentOrchestration(e);
		setCurrentTab(2);
	};

	return (
		<div className='page_full_inner'>
			<section className="section no_bottom_pad section_primary">
				<div className='flex'>
					<div className='flex_item_full'>
						<div className="h1">Orchestration</div>
					</div>
					<div className='flex_item_fix'>
						<Btn
							onClick={() => setOppenCreationOrchestration(true)}
							message="New group"
							icon="fas fa-plus fa-sm"
						/>
					</div>
				</div>
			</section>
			<section className="section">
				<Article size='l'>
					{isReloading &&
						<SpinnerWheel wheelSize="l" display="block" verticalSize="xl" />
					}
					{!isReloading && (!groups || groups.length == 0) &&
						<EmptyState
							title='No orchestration group yet'
							text='Orchestration groups allow you to prioritize campaigns on a page or during a session'
							imageUrl='/Assets/picto_orchestration_big.svg'
							textSize='l'
							verticalSize='l'
							primaryAction={
								<Btn
									onClick={() => setOppenCreationOrchestration(true)}
									message="New group"
								/>
							}
						/>
					}
					{groups && groups.length > 0 &&
						<>
							<div className="flex">
								<div className="flex_item_full">
									<SearchBar
										onChangeValue={(e) => handleChange(e)}
										valueInSearchBar={valueInSearchBar}
										placeholder="Find a group"
										name="elem"
										color='grey'
										fullWidth={false}
									/>
								</div>
								<div className="flex_item_fix ml_20">
									
								</div>
							</div>
							<div className='mt_20'>
								{!isReloading && noData &&
									<EmptyState
										icon="fas fa-search"
										title='No matching group'
										text='Please try another search'
										textSize='l'
										verticalSize='l'
									/>
								}
								{!noData &&
									<div className="orchestration_list flex flex_align_stretch flex_wrap">
										{dataFiltered && dataFiltered.map(group =>
											<OrchestrationGroupItem
												key={group.group.id}
												group={group}
												loadDataOfOrchestrationgroup={() => loadDataOfOrchestrationgroup(group)}
												deleteAnOrchestration={(e) => { setModalCOnfirmIsOppen(true); setGroupToDelete(e); }}
											/>
										)}
										{!dataFiltered && groups && groups.map(group =>
											<OrchestrationGroupItem
												key={group.group.id}
												group={group}
												loadDataOfOrchestrationgroup={() => loadDataOfOrchestrationgroup(group)}
												deleteAnOrchestration={(e) => { setModalCOnfirmIsOppen(true); setGroupToDelete(e); }}
											/>
										)}
									</div>
								}
							</div>
						</>
					}
				</Article>
			</section>
			<Confirm
				title={'Do you really want to delete this group?'}
				text={'This action is irreversible'}
				confirmText='Delete'
				confirmCallback={() => deleteAnOrchestration(groupToDelete.group.id)}
				confirmColor='alert'
				isOpen={modalCOnfirmIsOppen}
				onClose={() => setModalCOnfirmIsOppen(false)}
			/>

			<Modal
				isOpen={oppenCreationOrchestration}
				width="600"
				onClose={() => closeModal()}
			>
				<div className="modal_header has_border">
					Create an orchestration group
				</div>
				<div className="modal_section">
					<InputCustom
						value={orchestrationNewGroup.name}
						name="name"
						type="text"
						label="Name"
						placeholder="Ex: All header bars"
						fullWidth='true'
						onChange={(e) => handleChangeNeworchestrationGroup(e)}
					/>
					<InputCustom
						value={orchestrationNewGroup.description}
						name="description"
						type="textarea"
						label="Description"
						placeholder="Optional description"
						fullWidth='true'
						onChange={(e) => handleChangeNeworchestrationGroup(e)}
					/>
				</div>
				<div className="modal_footer al_right">
					<Btn
						onClickFunction={() => closeModal()}
						message="Cancel"
						color="secondary"
						style="ghost"
					/>
					<Btn
						onClickFunction={orchestrationNewGroup.name.length !== 0 ? () => postNewOrchestration() : null}
						message="Ok"
						disabled={orchestrationNewGroup.name.length !== 0 ? false : true}
					/>
				</div>
			</Modal>
		</div>
	);
}
