export const initInheritStyles = (c) => {

	const parent = c.parent();
	if (!parent) return;

	c.ineritedStyles.forEach(style => {

		// Add trait on parent and set the value
		const trait = {
			type: style.type,
			name: style.key,
			label: style.label,
			default: style.value,
			options: style.options,
			tab: style.tab || 'style',
			min: style.min,
			max: style.max,
			placeholder: style.placeholder,
			changeProp: true,
		};
		parent.addTrait(trait);

		const parentCurrentValue = parent.get(style.key);
		if (typeof parentCurrentValue == 'undefined') {
			parent.set(style.key, style.value);
		}

		// Set default style on component
		const unit = style.unit || '';
		let value = style.value;
		if (typeof parentCurrentValue != 'undefined') {
			value = parentCurrentValue;
		}
		c.defaults.style[style.prop] = value + unit;
		if (style.syncProp) {
			c.defaults.style[style.syncProp] = value + unit;
		}

		c.listenTo(parent, 'change:'+style.key, function(){
			const value = parent.get(style.key);
			const unit = style.unit || '';
			const css = c.getStyle();
			css[style.prop] = value + unit;
			if (style.syncProp) {
				css[style.syncProp] = value + unit;
			}
			c.setStyle(css);
		});
	});

};