import React, { useState } from 'react';
import ABSettings from '../ABSettings';
import { CampaignsContext } from '../../context';

export default function GraphEditorToolbar() {

	const {
		step
	} = CampaignsContext();

	return (
		step === 1 && (
			<div className="format_toolbar flex">
				<div className="format_toolbar_left">
					<div className="format_toolbar_title">Patches overview</div>
				</div>
				<div className="format_toolbar_center">

				</div>
				<div className="format_toolbar_right">
					<ABSettings />
				</div>
			</div>
		)
	);
}
