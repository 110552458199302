import React from 'react';
import { useEmailTemplateEditorContext } from '../../context/EmailTemplateEditorContextProvider';
import { Template } from '../../../../WysiwygEditor/Types/BespokeTypes';
import Btn from '../../../../../Components/Btn';
import EmptyState from '../../../../../Components/EmptyState';

import { useTranslation } from 'react-i18next';



export default function FormatSelector() {
	const [t] = useTranslation('common');

	const {
		saasFormats,
		handleFormatPreviewClicked,
		handleFormatChosen,
		templateSelected
	} = useEmailTemplateEditorContext();


	const getFormatDisplayName = (template: Template) => {
		template;
		return '';
	};

	return (
		<>
			<div className="flex_item_full vscroll_custom">
				<section className="format_sidebar_section">
					{saasFormats.length == 0 &&
						<EmptyState
							icon="far fa-object-group"
							title={t('designEditor.noAvailableYet')}
							verticalSize={'s'}
							text={
								<></>
							}
						/>
					}
					{saasFormats.map((format, i) =>
						<div key={i}
							onClick={() => handleFormatPreviewClicked(format)}
							className={`format_sidebar_item ${templateSelected?.template?.TemplateName === format.template.TemplateName ? 'active' : ''}`}>
							<div className="format_sidebar_item_illu">
								<img src={format.template.ThumbnailUrl} />
							</div>
							<div className="format_sidebar_item_body">
								<div className="format_sidebar_item_type">{getFormatDisplayName(format.template)}</div>
								<div className="format_sidebar_item_name">{format.template.Name}</div>
							</div>
						</div>
					)}
				</section>
			</div>
			<section className="format_sidebar_section format_sidebar_footer flex_item_fix">
				<Btn
					onClick={() => handleFormatChosen()}
					disabled={!templateSelected}
					size="l"
					fullWidth={true}
					message={t('designEditor.chooseThisTemplate')}
					icon="fas fa-chevron-right"
					iconPosition="after"
				/>
			</section>
		</>
	);
}