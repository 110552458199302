import React ,{useEffect}from 'react';
import { useTranslation } from 'react-i18next';
import InputCustom from '../../Components/InputCustom';
import SelectDropdown from '../../Components/SelectDropdown';
import { v4 as uuidv4 } from 'uuid';

function FormCustomRateControl({
	type,
	//name,
	controls,
	setControls,
	//idPanel,
	handleFieldChange,
	panel
}) {

	const [t] = useTranslation(['survey']);

	function handleRangeChange (value) {
		const controls = [];
		for (let i = 0; i < value; i++) {
			controls.push({
				id: uuidv4(),
				text: i+1,
				editMode: true
			});
		}
		setControls(controls, {});
	}

	return (
		<>
			{type === 11 &&
				<SelectDropdown
					label={t('ratingRange')}
					labelClassName='s_14 grey_0'
					optionsList={[
						{label: '1 - 5', value: 5},
						{label: '1 - 7', value: 7},
						{label: '1 - 10', value: 10},
					]}
					value={controls.length}
					onChange={handleRangeChange}
				/>
			}

			<InputCustom
				label="Low score label"
				labelClassName='s_14 grey_0'
				name="lowScoreLabel"
				value={panel.lowScoreLabel}
				onChange={handleFieldChange}
				fullWidth={true}
			/>

			<InputCustom
				label="High score label"
				labelClassName='s_14 grey_0'
				name="highScoreLabel"
				value={panel.highScoreLabel}
				onChange={handleFieldChange}
				fullWidth={true}
			/>
		</>
	);
}

export default FormCustomRateControl;
