import React, {useState} from 'react';

import styles from './ChatBot.module.css';
import { v4 as uuidv4 } from 'uuid';
import IconBtn from '../../Components/IconBtn';
import ModalHeader from '../../Components/ModalHeader';
import ModalBody from '../../Components/ModalBody';
import ModalFooter from '../../Components/ModalFooter';
import InputCustom from '../../Components/InputCustom';
import Btn from '../../Components/Btn';
import Modal from '../../Components/Modal';
import Fieldset from '../../Components/Fieldset';
import {useTranslation} from 'react-i18next';
import { Pie } from '../../Components/Pie/Pie';
import { BarChart } from '../../Components/BarChart/BarChart';

type IPropositionMessage = {
	data: string[];
	handleSendMessage : any;
}
function PropositionMessage({
	data,
	handleSendMessage
}:IPropositionMessage) :JSX.Element {

	const cssClass:string[] = [];
	cssClass.push(styles.bulle_proposition);
	cssClass.push(styles.bulle_size_s);

	return (
		<div className={styles.proposition_list}>
			{data.map((m) => 
				<button
					type="button"
					key={uuidv4()}
					onClick={() => handleSendMessage(m)}
					className={cssClass.join(' ')}>
					{m}
				</button>
			)}
		</div>
	);
}


type IReadyModuleMessage = {
	module: any;
	props: any;
}
function ReadyModuleMessage({
	module,
	props
}:IReadyModuleMessage) :JSX.Element {

	const [t] = useTranslation('inspiration');
	const cssClass:string[] = [styles.bulle_size_m];
	cssClass.push(styles.bulle_bot);
	cssClass.push(styles.bulle_clickable);

	let url = '/Campaigns/Editor?module=' + module.id;
	const ka = props.$routeParams.ka;
	const ku = props.$routeParams.ku;
	if (ka) {
		url += '&ka='+ka;
		if (ku) {
			url += '&ku='+ku;
		}
	}

	return (
		<a className={cssClass.join(' ')} href={url}>
			<div className={styles.ready}>
				<div className={styles.ready_col_img}>
					<img className={styles.ready_img} src={'/Assets/' + module.img} />
				</div>
				<div className={styles.ready_col_content}>
					<div className={styles.ready_title}>{t('modules.'+module.id+'.label')}</div>
					<div className={styles.ready_type}>{t('formats.'+module.formatId+'.label')}</div>
					<div className={styles.ready_desc}>{t('modules.'+module.id+'.description')}</div>
				</div>
				<div className={styles.ready_col_actions}>
					<span className={styles.ready_btn}>
						<i className='fas fa-chevron-right fa-fw'></i>
					</span>
				</div>
			</div>
		</a>
	);
}

type IInfoMessage = {
	text: string;
	type?: 'info' | 'warning';
}
function InfoMessage({
	text,
	type = 'info'
}:IInfoMessage) :JSX.Element {

	const iconClass:string[] = [styles.info_icon];

	switch (type) {
		case 'info':
			iconClass.push(styles.info_icon_info);
			iconClass.push('fas fa-check fa-fw');
			break;
		case 'warning':
			iconClass.push(styles.info_icon_warning);
			iconClass.push('fas fa-exclamation-circle fa-fw');
			break;
	}

	return (
		<div className={styles.info} key={uuidv4()}>
			<i className={iconClass.join(' ')}></i>
			<div className={styles.info_text}>
				{text}
			</div>
		</div>
	);
}


type ITextMessage = {
	text: string;
	type?: 'User' | 'Bot' | 'System';
}
function TextMessage({
	text,
	type = 'Bot',
}:ITextMessage) :JSX.Element {

	const cssClass:string[] = [styles.bulle_size_m];
	if (type == 'User') cssClass.push(styles.bulle_user);
	if (type == 'Bot') cssClass.push(styles.bulle_bot);

	return (
		<div className={cssClass.join(' ' )}>
			{text}
		</div>
	);
}


type IRuleMessage = {
	ruleId: string;
	type?: 'recommendation' | 'ranking' | 'tagging';
	props: any;
}
function RuleMessage({
	ruleId,
	type,
	props
}:IRuleMessage) :JSX.Element {

	function getUrl(typeUrl, props){
		let url = `/ProductSettings/${typeUrl}/Setting?id=${ruleId}`;
		const ka = props.$routeParams.ka;
		const ku = props.$routeParams.ku;
		if (ka) {
			url += '&ka='+ka;
			if (ku) {
				url += '&ku='+ku;
			}
		}
		return url;
	}

	const [t] = useTranslation('chatbot');
	const cssClass:string[] = [styles.bulle_size_m];
	cssClass.push(styles.bulle_bot);
	cssClass.push(styles.bulle_clickable);

	let url = '';
	let iconClass = '';
	let actionTranslation = '';
	
	switch (type) {
		case 'ranking':
			url =  getUrl('Ranking', props);
			iconClass = 'fas fa-trophy';
			actionTranslation = t('chatbot.openRankingRule');
			break;
		case 'tagging':
			url =  getUrl('Tagging', props);
			iconClass = 'fas fa-tag';
			actionTranslation = t('chatbot.openTaggingRule');
			break;
		default:
			url =  getUrl('Recommendations', props);
			iconClass = 'fas fa-thumbs-up';
			actionTranslation = t('chatbot.openRecommendationRule');
			break;
	}

	return (
		<a className={cssClass.join(' ')} href={url}>
			<div className="flex">
				<div className="flex_item_fix">
					<div className={styles.rule_picto}>
						<div className="emerch_avatar">
							<i className={iconClass}></i>
						</div>
					</div>
				</div>
				<div className="flex_item_auto">
					{actionTranslation}
				</div>
				<div className="flex_item_fix">
					<span className={styles.rule_btn}>
						<i className='fas fa-chevron-right fa-fw'></i>
					</span>
				</div>
			</div>
		</a>
	);
}


type ISystemMessage = {
	text: any;
}
function SystemMessage ({
	text
}: ISystemMessage): JSX.Element {

	const outerClass:Array<string> = [styles.message, styles.message_side_left, styles.message_margin_s];

	return (
		<div className={outerClass.join(' ')}>
			<div className={styles.message_body}>
				<div className={styles.info}>
					<div className={styles.info_text}>
						{text}
					</div>
				</div>
			</div>
		</div>
	);
}

const dateWeek = {
	fr: [
		'Lu',
		'Ma',
		'Me',
		'Je',
		'Ve',
		'Sa',
		'Di'
	],
	en: [
		'Mo',
		'Tu',
		'We',
		'Th',
		'Fr',
		'Sa',
		'Su'
	]
};
const i18nL= localStorage.getItem('i18nL');
const languageWeek = i18nL?.includes('fr') ? dateWeek.fr : dateWeek.en;
type IDayDictionaryMessage = {
	data: any
}
function DayDictionaryMessage({
	data
}:IDayDictionaryMessage) :JSX.Element {

	const cssClass:string[] = [styles.bulle_size_m];
	cssClass.push(styles.bulle_bot);
	const dataArray = data.data;
	const valueWeek = dataArray.map((value, i) => {
		return {
			value : Number(value.value),
			label: languageWeek[i]
		};
	});
	
	return (
		<div className={cssClass.join(' ')}>
			<BarChart
				title=''
				data={valueWeek}
				nbDecimal={1}
			/>
		</div>
	);
}


type IGenericDictionaryMessage = {
	data: any
}
function GenericDictionaryMessage({
	data
}:IGenericDictionaryMessage) :JSX.Element {
	const cssClass:string[] = [styles.bulle_size_m];
	cssClass.push(styles.bulle_bot);
	const dataArray = data.data;
	const valueWeek = dataArray.map((value) => {
		return {
			value : Number(value.value),
			label: value.name
		};
	});
	return (
		<div className={cssClass.join(' ')}>
			<Pie
				title={''}
				data={valueWeek}
				width={70}
				thickness={12}
				nbDecimal={1}
			/>
		</div>
	);
}


type IMessageLine = {
	type: 'Text' | 'Info' | 'Warning' | 'QueryProposition' | 'ReadyModule' | 'Continuation' | 'ConversationId' |  'ProductRecommendationId' | 'ProductRankingId' | 'ProductTaggingId' | 'DayDictionary' | 'GenericDictionary';
	participant?: 'User' | 'Bot' | 'System' ;
	data: string[] | any[];
	handleFeedback?: any;
	handleSendMessage?: any;
	props: any;
	children?: JSX.Element
}
function Messageline ({
	type,
	data,
	participant,
	handleFeedback,
	handleSendMessage,
	props, children
}: IMessageLine): JSX.Element {

	const [t] = useTranslation(['chatbot', 'utils']);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const outerClass:Array<string> = [styles.message];
	let side = 'left';
	const canFeedBack = type == 'Text' && participant == 'Bot';
	const [feedbackValue, setFeedbackValue] = useState('');

	function submitFeedback() {
		handleFeedback(feedbackValue, resetFeedback);
	} 

	function resetFeedback() {
		setFeedbackValue('');
		setModalIsOpen(false);
	}

	if (type == 'QueryProposition') {
		side = 'right';
	} else {
		side = participant == 'User' ? 'right' : 'left';
	}

	if (side == 'left') {
		outerClass.push(styles.message_side_left);
	} else {
		outerClass.push(styles.message_side_right);
	}

	if (type == 'Info' || type == 'Warning') {
		outerClass.push(styles.message_margin_s);
	} else {
		outerClass.push(styles.message_margin_m);
	}
	
	return (
		<div className={outerClass.join(' ')}>
			<div className={styles.message_body}>
				{type == 'Text' && <TextMessage text={data[0]} type={participant} />}
				{type == 'Info' && <InfoMessage text={data[0]} type='info' />}
				{type == 'Warning' && <InfoMessage text={data[0]} type='warning' />}
				{type == 'QueryProposition' && <PropositionMessage data={data} handleSendMessage={handleSendMessage} />}
				{type == 'ReadyModule' && <ReadyModuleMessage module={data[0]} props={props} />}
				{type === 'ProductRecommendationId' && <RuleMessage ruleId={data[0]} type="recommendation" props={props} />}
				{type === 'ProductRankingId' && <RuleMessage ruleId={data[0]} type="ranking" props={props} />}
				{type === 'ProductTaggingId' && <RuleMessage ruleId={data[0]} type="tagging" props={props} />}
				{type === 'DayDictionary' && <DayDictionaryMessage data={data[0]}/>}
				{type === 'GenericDictionary' && <GenericDictionaryMessage data={data[0]}/>}
				<div style={{display : 'flex', justifyContent: 'end', marginTop: '5px'}}>{children}</div>
			</div>
			{canFeedBack &&
				<>
					<div className={styles.message_actions}>
						<IconBtn
							icon="far fa-frown"
							tooltip={t('chatbot.feedbackOnThisResponse')}
							onClick={() => setModalIsOpen(true)}
						/>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onClose={() => setModalIsOpen(false)}
						width={800}
					>
						<ModalHeader title={t('chatbot.feedback')} />
						<ModalBody>
							<Fieldset label={t('chatbot.response')}>
								<TextMessage text={data[0]} type={participant} />
							</Fieldset>
							<InputCustom
								label={t('chatbot.thisAnswerdoesNot')}
								type="textarea"
								value={feedbackValue}
								onChange={(e) => setFeedbackValue(e.target.value)}
								fullWidth={true}
								autogrow={true}
								autoFocus={true}
								placeholder={t('chatbot.tellUsMore')}
							/>
						</ModalBody>
						<ModalFooter
							primaryAction={
								<Btn
									disabled={feedbackValue.length == 0}
									message={t('actions.send', { ns: 'utils' })}
									onClick={submitFeedback}
								/>
							}
							secondaryAction={
								<Btn
									message={t('actions.cancel', { ns: 'utils' })}
									style="ghost"
									color="secondary"
									onClick={resetFeedback}
								/>
							}
						/>
					</Modal>
				</>
			}
		</div>
	);
}

export {
	Messageline,
	SystemMessage
};
