import React, {useState, useEffect} from 'react';
import '../../components/SideBar/SideBar.css';
import {useBuildEditorContext} from './BuildEditorContext';
import FormatLivePreview from '../FormatEditor/FormatLivePreview';
import ABSettings from '../ABSettings';

export default function BuilderEditorToolbar() {


	const {
		campaignStep,
		buildEditor,
		handleChangeEditorDevice,
		showStylesPanel,
		setShowStylesPanel,
		handleClickUndoRedo,
		handleChangeShowVisibility,
		currentDevice,
		showVisibility,
		moduleTemplate
	} = useBuildEditorContext();

	// const [showVisibility, setShowVisibility] = useState(true);
	const [hasUndo, setHasUndo] = useState(false);
	const [hasRedo, setHasRedo] = useState(false);
	const [storeHandlerRegistered, setStoreHandlerRegistered] = useState(false);

	buildEditor && !storeHandlerRegistered && buildEditor.on('update', (obj) => {
		const um = buildEditor.UndoManager;
		setHasUndo(um.hasUndo());
		setHasRedo(um.hasRedo());
		setStoreHandlerRegistered(true);
	});
	buildEditor && !storeHandlerRegistered && buildEditor.on('undo', (obj) => {
		const um = buildEditor.UndoManager;
		setHasUndo(um.hasUndo());
		setHasRedo(um.hasRedo());
		setStoreHandlerRegistered(true);
	});
	buildEditor && !storeHandlerRegistered && buildEditor.on('redo', (obj) => {
		const um = buildEditor.UndoManager;
		setHasUndo(um.hasUndo());
		setHasRedo(um.hasRedo());
		setStoreHandlerRegistered(true);
	});

	const changeDevice = (deviceId) => {
		handleChangeEditorDevice(deviceId);
	};

	const handleClickShowVisibility = () => {
		handleChangeShowVisibility();
	};
	return (
		<>
			{campaignStep === 1 &&
				<div className="format_toolbar flex">
					<div className='format_toolbar_left'>
						<div className='format_toolbar_title'>
							{moduleTemplate || 'Popup builder'}
						</div>
					</div>
					<div className="format_toolbar_center">
						<ul className="format_tabs">
							<li>
								<a className={currentDevice === 'mobile' ? 'active' : ''}
									onClick={() => changeDevice('mobile')}>
									<i className='fas fa-mobile-alt'></i>
									Mobile
								</a>
							</li>
							<li>
								<a className={currentDevice === 'desktop' ? 'active' : ''}
									onClick={() => changeDevice('desktop')}>
									<i className='fas fa-desktop'></i>
									Desktop
								</a>
							</li>
							<li>
								<FormatLivePreview />
							</li>
						</ul>
					</div>
					<div id="builder_toolbar" className='format_toolbar_right'>
						<div className="format_cmd_group">
							<button
								className={hasUndo ? 'format_cmd' : 'format_cmd disabled'}
								onClick={() => handleClickUndoRedo('undo')}
								data-tip="Undo"
							>
								<i className="fas fa-undo format_cmd_icon_l"></i>
							</button>
							<button
								className={hasRedo ? 'format_cmd' : 'format_cmd disabled'}
								onClick={() => handleClickUndoRedo('redo')}
								data-tip="Redo"
							>
								<i className="fas fa-redo format_cmd_icon_l"></i>
							</button>
							<span className="format_cmd_sep"></span>
							<button
								className={showStylesPanel ? 'format_cmd main_color active' : 'format_cmd'}
								onClick={() => setShowStylesPanel(!showStylesPanel)}
								data-tip="Toogle style panel"
							>
								<i className="fas fa-palette format_cmd_icon_l"></i>
							</button>
							<button
								className={showVisibility ? 'format_cmd main_color active' : 'format_cmd'}
								onClick={() => handleClickShowVisibility()}
								data-tip="Toogle component outlines"
							>
								<i className="fas fa-border-none format_cmd_icon_l"></i>
							</button>
							<span className="format_cmd_sep"></span>
							<ABSettings />
						</div>
					</div>
				</div>
			}
		</>
	);
}
