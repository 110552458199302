import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/theme/monokai.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/theme/base16-light.css';
import 'codemirror/theme/xq-light.css';
import 'codemirror/theme/ttcn.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';

require('codemirror/mode/javascript/javascript');

export default function RawFeedContainer({rawFeed}) {

	const options = {
		mode: 'text',
		lineNumbers: true,
		lineWrapping: false,
		indentUnit: 2,
		autocorrect: true,
		keyMap: 'sublime'
	};

	return (
		<CodeMirror
			value={rawFeed && rawFeed.join('\r\n')}
			options={options}
		/>
	);
}
