import React, { useState, useEffect } from 'react'
import ConfirmModal from '../ConfirmModal';
import './ProductTags.css'
export default function ProductTagmodifyValue({ getProductReferencesByTag, putProductReferencesByTag, deleteTag, accountId, nameOfTagList, toggleList }) {
    const [skuValue, setSkuValue] = useState('')
    const [skuModifyValue, setSkuModifyValue] = useState('')
    const [skuListArray, setSkuListArray] = useState({ ProductReferences: [] })
    const [valueSearchBar, setValueSearchBar] = useState('')
    const [dataFiltered, setDataFiltered] = useState()
    const [noData, setNoData] = useState()
    const [toggleModales, setToggleModales] = useState(false)
    let totalOfSku = skuValue.length

    const oppenCloseModale = (e) => {
        setToggleModales(!toggleModales)
    }
    const stopPropa = (e) => {
        e.stopPropagation()
    }
    useEffect(() => {
        getProductReferencesByTag(accountId, nameOfTagList,
            newData => {
                setSkuValue(newData)
                setSkuListArray({ ProductReferences: [...newData] })
            })

    }, [])

    const handleChange = (event) => {
        let textAreaValue = { ...skuModifyValue }
        textAreaValue = event.target.value
        setSkuModifyValue(textAreaValue)
    }

    const addToTagList = () => {
        const skuList = skuModifyValue && skuModifyValue.split(/[;,\n]/).filter(x => x.length > 0)
        setSkuValue([...skuValue, ...skuList])
        setSkuListArray({ ProductReferences: [...skuList, ...skuValue] })
        totalOfSku = skuListArray.ProductReferences.length
        setSkuModifyValue('')
    }
    useEffect(() => {
        const modifyListOsSku = () => {
            const skuList = skuModifyValue && skuModifyValue.split(/[;,\n]/).filter(x => x.length > 0)
            setSkuListArray({ ProductReferences: [...skuList, ...skuValue] })
            setSkuModifyValue('')
        }
        modifyListOsSku()
    }, [skuValue])

    const saveNewTag = () => {
        putProductReferencesByTag(accountId, nameOfTagList,
            skuListArray)
        setTimeout(() => {
            toggleList()
        }, 2000)
    }
    const deleteTheTag = () => {
        deleteTag(accountId, nameOfTagList)
        oppenCloseModale()
        setTimeout(() => {
            toggleList()
        }, 2000)
    }
    const deleteProp = (x) => {
        let array = skuValue
        array = array.filter(element => element !== x)
        setSkuValue(array)
        setDataFiltered()
        setValueSearchBar('')
    }
    // fonction de recherche dans le tableau
    const search = (table, word) => {
        if (word.length > 0) {
            setDataFiltered([])
            let resultName = table.filter(
                line =>
                    line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
            );
            if (resultName.length === 0) {
                resultName = "No data found for this search"
                setNoData(resultName)
            }
            else { setDataFiltered(resultName) }

        } else {
            setNoData()
            setDataFiltered()
        }
    }

    const handleChangeSearch = (event) => {
        setValueSearchBar(event.target.value)
        if (skuListArray.ProductReferences) {
            search(skuListArray.ProductReferences, event.target.value)
        }
    }

    return (
        <div className="modalBody" >
            <h2 className="">Edit tag : <strong className="edit_tag_name">{nameOfTagList}</strong> <span className="edit_tag_total">({totalOfSku})</span> </h2>

            <div className="search_tag">
                <i className="fa fa-search"></i>
                <input className="searchBarTag" type="text"
                    onChange={handleChangeSearch}
                    value={valueSearchBar}
                    placeholder="Search for a sku"
                />
            </div>

            <section>
                {toggleModales &&
                    <ConfirmModal
                        close={oppenCloseModale}
                        stopPropa={stopPropa}
                        deleteTheTag={deleteTheTag}
                        nameOfTagList={nameOfTagList}
                        totalOfSku={totalOfSku}
                    />}

                {noData ?
                    <div className="noData">
                        {noData}
                    </div>
                    :

                    (<>
                        {!dataFiltered ?
                            (<>
                                <ul className="listOfSkuApi editSkuList">
                                    {skuValue && skuValue.map((elem, i) =>
                                        <li key={i} className="itemSku">
                                            <span className="deleteItem" onClick={(e) => deleteProp(elem)}> <i className="fas fa-trash"></i></span>
                                            <p>{elem}</p>
                                        </li>
                                    )}
                                </ul>
                            </>)

                            :
                            (<>
                                <ul className="listOfSkuApi editSkuList">
                                    {dataFiltered && dataFiltered.map((elem, i) =>
                                        <li key={i} className="itemSku">
                                            <span className="deleteItem" onClick={(e) => deleteProp(elem)}><i className="fas fa-trash"></i></span>
                                            <p>{elem}</p>
                                        </li>
                                    )}
                                </ul>
                            </>)
                        }
                    </>)
                }
                <textarea className='textAreaSku' placeholder="Add Sku to list" value={skuModifyValue && skuModifyValue} onChange={handleChange} />
                <p className="advertTag">Separate SKU with a line break, comma ( , ) or semi-colon ( ; )</p>
                <div className="tag_actions">
                    <button className="tag_btn btn-deleteTag" onClick={oppenCloseModale}> Delete </button>
                    {skuModifyValue.length > 0 ?
                        (<>
                            <button className="tag_btn btn-clearList" onClick={() => setSkuModifyValue('')} >Clear &nbsp;<i className="fas fa-backspace"></i></button>
                            <button className="tag_btn btn-addList" onClick={addToTagList} >Add &nbsp;<i className="fas fa-plus"></i></button>
                        </>)
                        :
                        (<>
                            <button className="tag_btn btn-cancelDelete" onClick={() => toggleList()} >Cancel</button>
                            <button className="tag_btn btn-saveTag" disabled={skuValue.length === 0} onClick={saveNewTag}>Save &nbsp;<i className="fas fa-save"></i></button>
                        </>)
                    }
                </div>

            </section>
        </div>
    )
}
