import React, { Component } from 'react';
import Select from 'react-select';
import EventTypeEnum from './EventTypeEnum';
import styles from './VisitorEngagementReportsEventPanel.module.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import MomentConstants from '../../Constants/MomentConstants';

const options = [
    { value: EventTypeEnum.None, label: EventTypeEnum.None },
    { value: EventTypeEnum.Operation, label: EventTypeEnum.Operation },
    { value: EventTypeEnum.BeyableScenario, label: EventTypeEnum.BeyableScenario },
    { value: EventTypeEnum.NationalEvent, label: EventTypeEnum.NationalEvent },
];

export default class VisitorEngagementReportsEventPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.eventToEdit ? this.props.eventToEdit.id : "",
            name: this.props.eventToEdit ? this.props.eventToEdit.name : "",
            date: this.props.eventToEdit ? this.props.eventToEdit.date : moment().format(MomentConstants.MOMENT_API_FORMAT),
            type: this.props.eventToEdit ? this.props.eventToEdit.type : EventTypeEnum.None
        }

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.selectDate = this.selectDate.bind(this);
    }

    handleChangeName(event) {
        this.setState({ name: event.target.value });
    }

    handleChangeDate(event) {
        this.setState({ date: event.target.value });
    }

    handleChangeType(selectedOption) {
        this.setState({ type: selectedOption.value });
    }

    cancel() {
        this.props.hideAndResetEventPanel();
    }

    createOrUpdateEvent() {
        this.props.createOrUpdateEvent({ id: this.state.id, name: this.state.name, date: this.state.date, type: this.state.type });
    }

    deleteEvent() {
        this.props.deleteEvent(this.state.id);
    }

    selectDate(event, picker) {
        this.setState({ date: picker.startDate.format(MomentConstants.MOMENT_API_FORMAT) });
    }

    defaultDate() {
        let date = moment(this.state.date, MomentConstants.MOMENT_API_FORMAT);
        if (date.isValid()) {
            return date;
        }
        else {
            return moment();
        }
    }

    render() {
        return <div className={styles.content}>
            <div className={styles.labels}>Name</div>
            <input type="text" className={styles.input} value={this.state.name} onChange={this.handleChangeName} />
            <div className={styles.labels}>Date</div>
            <DateRangePicker singleDatePicker={true} startDate={this.defaultDate()} onApply={this.selectDate}>
                <input type="text" className={styles.input} value={this.state.date} onChange={this.handleChangeDate} />
            </DateRangePicker>
            <div className={styles.labels}>Type</div>
            <div className={styles.select}>
                <Select
                    value={options.filter(option => option.value === this.state.type)}
                    onChange={this.handleChangeType}
                    options={options}
                />
            </div>
            <div className={styles.actionButtons}>
                <button onClick={() => this.cancel()} className={styles.cancelButton}>Cancel</button>
                <button onClick={() => this.createOrUpdateEvent()} className={styles.saveButton}>{this.props.actionLabel}</button>
            </div>
            {this.props.eventToEdit ?
                <div onClick={() => this.deleteEvent()} className={styles.deleteLabel}><i className="fas fa-times"></i> Delete</div> : ""}
        </div >
    }
}
