const initType = [
	{ key: 'Page', label: 'Page' }, 
	{ key: 'PageType', label: 'Page Type' }, 
	{ key: 'Tag1', label: 'Custom Dimension 1' }, 
	{ key: 'Tag2', label: 'Custom Dimension 2' }, 
	{ key: 'Tag3', label: 'Custom Dimension 3' }, 
	{ key: 'Tag4', label: 'Custom Dimension 4' }, 
	{ key: 'Tag5', label: 'Custom Dimension 5' }];
    
export const metricsTypes = [{
	key: 'UniqueViews',
	label: 'Unique Views'
},
{
	key: 'Views',
	label: 'Views'
},
{
	key: 'AverageTimeOnPage',
	label: 'Average time on page'
},
{
	key: 'BounceRate',
	label: 'Bounce rate'
},
// {
//     key: 'TrafficSource',
//     label: 'Traffic source'
// },
// {
//     key: 'Landings',
//     label: 'Landings'
// },
// {
//     key: 'Exits',
//     label: 'Exits'
// }
];

export const sourceTypes = [
	// 'All',
	'Direct',
	'Organic',
	'Referral',
	'Social',
	'Paid',
	'Email',
	'Display',
	'Other',
];
export default initType;

export const initialUtmFilter = {
	source: {
		name: '',
		label: ''
	},
	medium: {
		name: '',
		label: ''
	},
	campaign: {
		name: '',
		label: ''
	},
	content: {
		name: '',
		label: ''
	}
};
export const initialUtmFilterV2 = {
	source: [
		
	],
	medium: [
		
	],
	campaign: [
		
	],
	content: [
	
	]
};
export const cardFetchOptions = {
	'fromDate': '',
	'toDate': '',
	'metrics': [
		'NumberOfSessions'
	],
	'timeGranularity': 'None',
	'dimensions': [],
	'limit': 15,
	'offset': 0,
	'filters': [],
	'search': null
};
export const search = (fnSetter,table, word,selectList) => {
	if (word.length > 0) {
		fnSetter([]);
		let resultName = table.filter(
			line =>
				line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
		);
		const res = resultName.map(i => {
			return {
				value: i,
				label: i
			};
		});
		fnSetter(res);
	}
	else {
		fnSetter(selectList);
	}
};
export const devices = [
// 	{
// 	value : 'all',
// 	label : 'All'
// },
	{
		value : 'Desktop',
		label : 'Desktop'
	},
	{
		value : 'Mobile',
		label : 'Mobile'
	},
	{
		value : 'Tablet',
		label : 'Tablet'
	}
];
export const FORMAT = 'YYYY/MM/DD';
export const today = moment();
export const monday = moment().startOf('isoWeek').format(FORMAT);
export const formatToday = moment().format(FORMAT);

export const dataTest = [
	{
		'lat': 35.1996,
		'lon': 128.9874,
		'name': 'Busan',
		'iso': 'KR',
		'NumberOfSessions': 1
	},
	{
		'lat': 25.049057143855542,
		'lon': 121.53255002135272,
		'name': 'Taipei City',
		'iso': 'TW',
		'NumberOfSessions': 14
	},
	{
		'lat': 55.70237523618232,
		'lon': 12.52711956963035,
		'name': 'Capital Region',
		'iso': 'DK',
		'NumberOfSessions': 23
	},
	{
		'lat': 22.3361,
		'lon': 114.19990000000001,
		'name': 'Kowloon City',
		'iso': 'HK',
		'NumberOfSessions': 1
	},
	{
		'lat': 13.680599999999998,
		'lon': -89.1803,
		'name': 'San Salvador Department',
		'iso': 'SV',
		'NumberOfSessions': 5
	},
	{
		'lat': 54.9038,
		'lon': 23.892400000000006,
		'name': 'Kaunas',
		'iso': 'LT',
		'NumberOfSessions': 4
	},
	{
		'lat': 50.6336,
		'lon': 5.5736,
		'name': 'Liège Province',
		'iso': 'BE',
		'NumberOfSessions': 8
	},
	{
		'lat': -33.426813892793405,
		'lon': -70.67353876648437,
		'name': 'Santiago Metropolitan',
		'iso': 'CL',
		'NumberOfSessions': 24
	},
	{
		'lat': 13.0508,
		'lon': 100.9367,
		'name': 'Chon Buri',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.48658368943851,
		'lon': 6.738531103365214,
		'name': 'Vaud',
		'iso': 'CH',
		'NumberOfSessions': 11
	},
	{
		'lat': 47.43909407448329,
		'lon': 9.230133348130291,
		'name': 'Saint Gallen',
		'iso': 'CH',
		'NumberOfSessions': 6
	},
	{
		'lat': 10.822000000000005,
		'lon': 106.6257,
		'name': 'Ho Chi Minh',
		'iso': 'VN',
		'NumberOfSessions': 10
	},
	{
		'lat': 60.18304591579926,
		'lon': 24.937738048734804,
		'name': 'Uusimaa',
		'iso': 'FI',
		'NumberOfSessions': 36
	},
	{
		'lat': 37.52207700334922,
		'lon': 126.67793756444141,
		'name': 'Incheon',
		'iso': 'KR',
		'NumberOfSessions': 4
	},
	{
		'lat': 37.55040230063253,
		'lon': 126.9563853000645,
		'name': 'Seoul',
		'iso': 'KR',
		'NumberOfSessions': 19
	},
	{
		'lat': -26.08136031804689,
		'lon': 28.113614798303697,
		'name': 'Gauteng',
		'iso': 'ZA',
		'NumberOfSessions': 17
	},
	{
		'lat': -29.93015584284592,
		'lon': -71.29554084646219,
		'name': 'Coquimbo Region',
		'iso': 'CL',
		'NumberOfSessions': 2
	},
	{
		'lat': 59.4381,
		'lon': 24.736899999999995,
		'name': 'Tallinn',
		'iso': 'EE',
		'NumberOfSessions': 26
	},
	{
		'lat': -12.0432,
		'lon': -77.0282,
		'name': 'Lima Province',
		'iso': 'PE',
		'NumberOfSessions': 16
	},
	{
		'lat': 22.2848,
		'lon': 114.1703,
		'name': 'Wan Chai',
		'iso': 'HK',
		'NumberOfSessions': 2
	},
	{
		'lat': -34.890650165674394,
		'lon': -56.144643192928505,
		'name': 'Montevideo Department',
		'iso': 'UY',
		'NumberOfSessions': 9
	},
	{
		'lat': 47.39350087352476,
		'lon': 8.551675497953477,
		'name': 'Zurich',
		'iso': 'CH',
		'NumberOfSessions': 17
	},
	{
		'lat': 56.94960000000001,
		'lon': 24.09779999999999,
		'name': 'Rīga',
		'iso': 'LV',
		'NumberOfSessions': 29
	},
	{
		'lat': 13.741871338301445,
		'lon': 100.49658044138283,
		'name': 'Bangkok',
		'iso': 'TH',
		'NumberOfSessions': 29
	},
	{
		'lat': 46.62281170910293,
		'lon': 18.573475281470913,
		'name': 'Tolna County',
		'iso': 'HU',
		'NumberOfSessions': 2
	},
	{
		'lat': 37.52458911373758,
		'lon': 127.00860599191579,
		'name': 'Gyeonggi-do',
		'iso': 'KR',
		'NumberOfSessions': 17
	},
	{
		'lat': 34.69149095607424,
		'lon': 33.0381996440804,
		'name': 'Limassol District',
		'iso': 'CY',
		'NumberOfSessions': 11
	},
	{
		'lat': 58.175225181662974,
		'lon': 7.984661083008522,
		'name': 'Agder',
		'iso': 'NO',
		'NumberOfSessions': 4
	},
	{
		'lat': 53.900700000000015,
		'lon': 27.57090000000001,
		'name': 'Minsk City',
		'iso': 'BY',
		'NumberOfSessions': 35
	},
	{
		'lat': 31.416600000000003,
		'lon': 31.810500000000005,
		'name': 'Damietta Governorate',
		'iso': 'EG',
		'NumberOfSessions': 2
	},
	{
		'lat': -26.866,
		'lon': 26.6644,
		'name': 'North West',
		'iso': 'ZA',
		'NumberOfSessions': 1
	},
	{
		'lat': -37.92610782301705,
		'lon': 176.20128099450775,
		'name': 'Bay of Plenty',
		'iso': 'NZ',
		'NumberOfSessions': 2
	},
	{
		'lat': -11.957520662028985,
		'lon': -76.90068308711945,
		'name': 'Lima region',
		'iso': 'PE',
		'NumberOfSessions': 4
	},
	{
		'lat': 50.998266518734944,
		'lon': 3.2214940691291605,
		'name': 'West Flanders Province',
		'iso': 'BE',
		'NumberOfSessions': 4
	},
	{
		'lat': 41.6959,
		'lon': 44.83199999999999,
		'name': 'Tbilisi',
		'iso': 'GE',
		'NumberOfSessions': 11
	},
	{
		'lat': 53.6875,
		'lon': 23.8281,
		'name': 'Grodnenskaya',
		'iso': 'BY',
		'NumberOfSessions': 3
	},
	{
		'lat': -26.8249,
		'lon': -65.2237,
		'name': 'Tucuman',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.229000000000006,
		'lon': 51.35769999999999,
		'name': 'West Kazakhstan',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.14640644388243,
		'lon': 9.715361972889548,
		'name': 'South Denmark',
		'iso': 'DK',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.75404939767568,
		'lon': -9.17722473321153,
		'name': 'Lisbon',
		'iso': 'PT',
		'NumberOfSessions': 14
	},
	{
		'lat': 55.47819377704465,
		'lon': 12.170278266615608,
		'name': 'Zealand',
		'iso': 'DK',
		'NumberOfSessions': 11
	},
	{
		'lat': -35.29300956254882,
		'lon': -59.00715244007944,
		'name': 'Buenos Aires',
		'iso': 'AR',
		'NumberOfSessions': 35
	},
	{
		'lat': 31.2538,
		'lon': 34.78579999999999,
		'name': 'Southern District',
		'iso': 'IL',
		'NumberOfSessions': 14
	},
	{
		'lat': 8.485,
		'lon': 124.64799999999998,
		'name': 'Province of Misamis Oriental',
		'iso': 'PH',
		'NumberOfSessions': 2
	},
	{
		'lat': -32.9178,
		'lon': -68.807,
		'name': 'Mendoza',
		'iso': 'AR',
		'NumberOfSessions': 3
	},
	{
		'lat': 6.242843196204441,
		'lon': -75.54520669636723,
		'name': 'Antioquia',
		'iso': 'CO',
		'NumberOfSessions': 10
	},
	{
		'lat': 46.62406050719816,
		'lon': 20.149767254594327,
		'name': 'Csongrád megye',
		'iso': 'HU',
		'NumberOfSessions': 15
	},
	{
		'lat': 59.8962544842357,
		'lon': 10.734676050936383,
		'name': 'Oslo County',
		'iso': 'NO',
		'NumberOfSessions': 18
	},
	{
		'lat': 3.1344418283002424,
		'lon': 101.69255808781803,
		'name': 'Kuala Lumpur',
		'iso': 'MY',
		'NumberOfSessions': 12
	},
	{
		'lat': 44.41784537689862,
		'lon': 26.113351210297687,
		'name': 'București',
		'iso': 'RO',
		'NumberOfSessions': 13
	},
	{
		'lat': 46.3682,
		'lon': 17.794499999999996,
		'name': 'Somogy County',
		'iso': 'HU',
		'NumberOfSessions': 3
	},
	{
		'lat': -22.266500000000008,
		'lon': 166.4742,
		'name': 'South Province',
		'iso': 'NC',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.328700000000005,
		'lon': 109.0337,
		'name': 'Shaanxi',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.144,
		'lon': 120.6844,
		'name': 'Taichung City',
		'iso': 'TW',
		'NumberOfSessions': 4
	},
	{
		'lat': -16.4014,
		'lon': -71.5343,
		'name': 'Arequipa',
		'iso': 'PE',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.16947537609999,
		'lon': 18.836759357199877,
		'name': 'Žilina Region',
		'iso': 'SK',
		'NumberOfSessions': 8
	},
	{
		'lat': -31.96593880265143,
		'lon': -64.0484219713068,
		'name': 'Cordoba',
		'iso': 'AR',
		'NumberOfSessions': 7
	},
	{
		'lat': 45.62082781875495,
		'lon': 25.251693535469556,
		'name': 'Brașov County',
		'iso': 'RO',
		'NumberOfSessions': 5
	},
	{
		'lat': 47.68769794380994,
		'lon': 17.11118892948867,
		'name': 'Győr-Moson-Sopron',
		'iso': 'HU',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.518900000000016,
		'lon': 20.152000000000008,
		'name': 'Jász-Nagykun-Szolnok',
		'iso': 'HU',
		'NumberOfSessions': 2
	},
	{
		'lat': 56.24230368603349,
		'lon': 10.055084289671669,
		'name': 'Central Jutland',
		'iso': 'DK',
		'NumberOfSessions': 15
	},
	{
		'lat': 24.986057021866266,
		'lon': 121.55473935587754,
		'name': 'New Taipei City',
		'iso': 'TW',
		'NumberOfSessions': 8
	},
	{
		'lat': 44.318,
		'lon': 23.7967,
		'name': 'Dolj',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.5636,
		'lon': 19.094699999999985,
		'name': 'Budapest',
		'iso': 'HU',
		'NumberOfSessions': 28
	},
	{
		'lat': 43.2433,
		'lon': 76.8646,
		'name': 'Almaty',
		'iso': 'KZ',
		'NumberOfSessions': 5
	},
	{
		'lat': 41.6473,
		'lon': 41.6258,
		'name': 'Adjara',
		'iso': 'GE',
		'NumberOfSessions': 4
	},
	{
		'lat': 14.630212964390063,
		'lon': 121.02543895563562,
		'name': 'Metro Manila',
		'iso': 'PH',
		'NumberOfSessions': 10
	},
	{
		'lat': 21.029199999999996,
		'lon': 105.8526,
		'name': 'Hanoi',
		'iso': 'VN',
		'NumberOfSessions': 16
	},
	{
		'lat': 62.4753,
		'lon': 6.144799999999998,
		'name': 'Møre og Romsdal',
		'iso': 'NO',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.2002,
		'lon': 27.942500000000003,
		'name': 'Varna',
		'iso': 'BG',
		'NumberOfSessions': 8
	},
	{
		'lat': 41.2042646309255,
		'lon': -8.60781930065117,
		'name': 'Porto',
		'iso': 'PT',
		'NumberOfSessions': 11
	},
	{
		'lat': 46.2383,
		'lon': 6.893400000000001,
		'name': 'Valais',
		'iso': 'CH',
		'NumberOfSessions': 2
	},
	{
		'lat': 36.63700000000001,
		'lon': 127.4339,
		'name': 'North Chungcheong',
		'iso': 'KR',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.6113,
		'lon': 6.129399999999999,
		'name': 'Luxembourg',
		'iso': 'LU',
		'NumberOfSessions': 6
	},
	{
		'lat': 30.2994,
		'lon': 120.1612,
		'name': 'Zhejiang',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.804599999999986,
		'lon': 20.463699999999996,
		'name': 'Belgrade',
		'iso': 'RS',
		'NumberOfSessions': 21
	},
	{
		'lat': 35.9142,
		'lon': 14.425300000000004,
		'name': 'Il-Mosta',
		'iso': 'MT',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.0759,
		'lon': 120.5657,
		'name': 'Changhua',
		'iso': 'TW',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.686899999999994,
		'lon': 46.722399999999986,
		'name': 'Riyadh Region',
		'iso': 'SA',
		'NumberOfSessions': 9
	},
	{
		'lat': 50.45939781018771,
		'lon': 4.194345781476154,
		'name': 'Hainaut Province',
		'iso': 'BE',
		'NumberOfSessions': 8
	},
	{
		'lat': 32.07815185559523,
		'lon': 34.79305088484229,
		'name': 'Tel Aviv',
		'iso': 'IL',
		'NumberOfSessions': 12
	},
	{
		'lat': 26.241,
		'lon': 50.577900000000014,
		'name': 'Manama',
		'iso': 'BH',
		'NumberOfSessions': 1
	},
	{
		'lat': 27.6974,
		'lon': 85.3318,
		'name': 'Bagmati Province',
		'iso': 'NP',
		'NumberOfSessions': 3
	},
	{
		'lat': 15.4967,
		'lon': -88.0338,
		'name': 'Cortés Department',
		'iso': 'HN',
		'NumberOfSessions': 5
	},
	{
		'lat': 6.847699999999998,
		'lon': 80.2598,
		'name': 'Ratnapura District',
		'iso': 'LK',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.926133983963055,
		'lon': 73.01133144666117,
		'name': 'Karaganda',
		'iso': 'KZ',
		'NumberOfSessions': 6
	},
	{
		'lat': 51.207362776366956,
		'lon': 4.554228632094414,
		'name': 'Antwerp Province',
		'iso': 'BE',
		'NumberOfSessions': 16
	},
	{
		'lat': -6.8667345918344225,
		'lon': 107.49385722916097,
		'name': 'West Java',
		'iso': 'ID',
		'NumberOfSessions': 10
	},
	{
		'lat': 24.5641,
		'lon': 120.8275,
		'name': 'Miaoli',
		'iso': 'TW',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.7506,
		'lon': 21.233399999999993,
		'name': 'Timiș County',
		'iso': 'RO',
		'NumberOfSessions': 5
	},
	{
		'lat': 47.5374,
		'lon': 21.6514,
		'name': 'Hajdú-Bihar',
		'iso': 'HU',
		'NumberOfSessions': 3
	},
	{
		'lat': 10.7019,
		'lon': 122.5622,
		'name': 'Province of Iloilo',
		'iso': 'PH',
		'NumberOfSessions': 1
	},
	{
		'lat': 3.0365516257089924,
		'lon': 101.6350622043193,
		'name': 'Selangor',
		'iso': 'MY',
		'NumberOfSessions': 10
	},
	{
		'lat': 10.4873,
		'lon': -66.8738,
		'name': 'Distrito Federal',
		'iso': 'VE',
		'NumberOfSessions': 2
	},
	{
		'lat': 5.332299999999998,
		'lon': 100.4645,
		'name': 'Penang',
		'iso': 'MY',
		'NumberOfSessions': 2
	},
	{
		'lat': 41.3438,
		'lon': 21.5561,
		'name': 'Prilep',
		'iso': 'MK',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.81299999999999,
		'lon': 89.3152,
		'name': 'Bogra',
		'iso': 'BD',
		'NumberOfSessions': 1
	},
	{
		'lat': 21.455101718408557,
		'lon': 39.39841065215305,
		'name': 'Mecca Region',
		'iso': 'SA',
		'NumberOfSessions': 9
	},
	{
		'lat': 33.8916,
		'lon': -5.5396,
		'name': 'Meknès Prefecture',
		'iso': 'MA',
		'NumberOfSessions': 2
	},
	{
		'lat': 40.1817,
		'lon': 44.50990000000001,
		'name': 'Yerevan',
		'iso': 'AM',
		'NumberOfSessions': 14
	},
	{
		'lat': 37.97843368287908,
		'lon': 23.733213919338137,
		'name': 'Attica',
		'iso': 'GR',
		'NumberOfSessions': 37
	},
	{
		'lat': 24.4662,
		'lon': 39.6168,
		'name': 'Medina Region',
		'iso': 'SA',
		'NumberOfSessions': 2
	},
	{
		'lat': 32.0197802557547,
		'lon': 34.80881126837017,
		'name': 'Central District',
		'iso': 'IL',
		'NumberOfSessions': 4
	},
	{
		'lat': 7.696200000000001,
		'lon': -5.0308,
		'name': 'Vallée du Bandama District',
		'iso': 'CI',
		'NumberOfSessions': 1
	},
	{
		'lat': 17.9641,
		'lon': 102.5987,
		'name': 'Vientiane Prefecture',
		'iso': 'LA',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.1825,
		'lon': -68.0172,
		'name': 'Carabobo',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.13623155678559,
		'lon': -8.394048987003332,
		'name': 'Faro',
		'iso': 'PT',
		'NumberOfSessions': 2
	},
	{
		'lat': 54.6466,
		'lon': 25.045099999999998,
		'name': 'Trakai District Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.3932,
		'lon': 15.566000000000003,
		'name': 'Slovenska Bistrica',
		'iso': 'SI',
		'NumberOfSessions': 2
	},
	{
		'lat': 42.69510000000002,
		'lon': 23.325000000000003,
		'name': 'Sofia-Capital',
		'iso': 'BG',
		'NumberOfSessions': 17
	},
	{
		'lat': 23.74573452469775,
		'lon': 90.40720208243474,
		'name': 'Dhaka',
		'iso': 'BD',
		'NumberOfSessions': 3
	},
	{
		'lat': 55.7158,
		'lon': 21.111,
		'name': 'Klaipėda City Municipality',
		'iso': 'LT',
		'NumberOfSessions': 5
	},
	{
		'lat': 25.292499999999993,
		'lon': 51.53210000000001,
		'name': 'Baladīyat ad Dawḩah',
		'iso': 'QA',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.9248,
		'lon': 30.3488,
		'name': 'Mogilev',
		'iso': 'BY',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.89156041608743,
		'lon': 4.542736151101658,
		'name': 'Flemish Brabant Province',
		'iso': 'BE',
		'NumberOfSessions': 2
	},
	{
		'lat': 17.608,
		'lon': 121.72190000000002,
		'name': 'Province of Cagayan',
		'iso': 'PH',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.9094,
		'lon': 106.8819,
		'name': 'Ulaanbaatar Hot',
		'iso': 'MN',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.16213636757788,
		'lon': 17.10074336005067,
		'name': 'Bratislava Region',
		'iso': 'SK',
		'NumberOfSessions': 18
	},
	{
		'lat': 11.558300000000001,
		'lon': 104.9121,
		'name': 'Phnom Penh',
		'iso': 'KH',
		'NumberOfSessions': 7
	},
	{
		'lat': 10.317100013217,
		'lon': 123.89469988325185,
		'name': 'Province of Cebu',
		'iso': 'PH',
		'NumberOfSessions': 4
	},
	{
		'lat': 38.369,
		'lon': 26.139899999999997,
		'name': 'Chios',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 5.3536,
		'lon': -4.0012,
		'name': 'Abidjan Autonomous District',
		'iso': 'CI',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.85739643064614,
		'lon': 4.3620847038883825,
		'name': 'Brussels Capital',
		'iso': 'BE',
		'NumberOfSessions': 25
	},
	{
		'lat': 46.050299999999986,
		'lon': 14.504599999999995,
		'name': 'Ljubljana',
		'iso': 'SI',
		'NumberOfSessions': 6
	},
	{
		'lat': 33.3364,
		'lon': 44.4004,
		'name': 'Baghdad',
		'iso': 'IQ',
		'NumberOfSessions': 3
	},
	{
		'lat': 31.9555,
		'lon': 35.9435,
		'name': 'Amman Governorate',
		'iso': 'JO',
		'NumberOfSessions': 2
	},
	{
		'lat': 31.582600000000003,
		'lon': 74.3276,
		'name': 'Punjab',
		'iso': 'PK',
		'NumberOfSessions': 1
	},
	{
		'lat': 4.608750082580254,
		'lon': -74.08002486122722,
		'name': 'Bogota D.C.',
		'iso': 'CO',
		'NumberOfSessions': 12
	},
	{
		'lat': 25.0731,
		'lon': 55.29800000000001,
		'name': 'Dubai',
		'iso': 'AE',
		'NumberOfSessions': 7
	},
	{
		'lat': -29.8556,
		'lon': 31.044900000000002,
		'name': 'KwaZulu-Natal',
		'iso': 'ZA',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.0718,
		'lon': 4.7602,
		'name': 'Bordj Bou Arréridj',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': -6.223055616267039,
		'lon': 106.84754391844707,
		'name': 'Jakarta',
		'iso': 'ID',
		'NumberOfSessions': 9
	},
	{
		'lat': 40.3771,
		'lon': 49.8875,
		'name': 'Baku City',
		'iso': 'AZ',
		'NumberOfSessions': 9
	},
	{
		'lat': 46.73064634782332,
		'lon': 7.068530897249655,
		'name': 'Fribourg',
		'iso': 'CH',
		'NumberOfSessions': 5
	},
	{
		'lat': 43.5098,
		'lon': 16.441099999999995,
		'name': 'Split-Dalmatia County',
		'iso': 'HR',
		'NumberOfSessions': 2
	},
	{
		'lat': -37.786250028565874,
		'lon': 175.28499991028647,
		'name': 'Waikato Region',
		'iso': 'NZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 36.1828,
		'lon': 44.01049999999999,
		'name': 'Erbil',
		'iso': 'IQ',
		'NumberOfSessions': 2
	},
	{
		'lat': -34.6142,
		'lon': -58.3811,
		'name': 'Buenos Aires F.D.',
		'iso': 'AR',
		'NumberOfSessions': 4
	},
	{
		'lat': -10.2136,
		'lon': 31.1785,
		'name': 'Northern Province',
		'iso': 'ZM',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.203075909093094,
		'lon': 16.360207017351925,
		'name': 'Vienna',
		'iso': 'AT',
		'NumberOfSessions': 79
	},
	{
		'lat': 47.2637,
		'lon': 11.401600000000002,
		'name': 'Tyrol',
		'iso': 'AT',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.718767576666714,
		'lon': 13.909087838053585,
		'name': 'Carinthia',
		'iso': 'AT',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.25294307546372,
		'lon': 14.183392437064905,
		'name': 'Upper Austria',
		'iso': 'AT',
		'NumberOfSessions': 7
	},
	{
		'lat': 48.15680396530408,
		'lon': 15.423127938562441,
		'name': 'Lower Austria',
		'iso': 'AT',
		'NumberOfSessions': 4
	},
	{
		'lat': -37.84336517550417,
		'lon': 144.98399579400908,
		'name': 'Victoria',
		'iso': 'AU',
		'NumberOfSessions': 75
	},
	{
		'lat': -26.62825996551578,
		'lon': 152.37889483889072,
		'name': 'Queensland',
		'iso': 'AU',
		'NumberOfSessions': 83
	},
	{
		'lat': -35.251056748728345,
		'lon': 149.10629493658183,
		'name': 'Australian Capital Territory',
		'iso': 'AU',
		'NumberOfSessions': 4
	},
	{
		'lat': -31.945156917576913,
		'lon': 115.84917799832482,
		'name': 'Western Australia',
		'iso': 'AU',
		'NumberOfSessions': 33
	},
	{
		'lat': -33.86456053732837,
		'lon': 151.06650755957446,
		'name': 'New South Wales',
		'iso': 'AU',
		'NumberOfSessions': 73
	},
	{
		'lat': -34.901183302256634,
		'lon': 138.59457466907412,
		'name': 'South Australia',
		'iso': 'AU',
		'NumberOfSessions': 29
	},
	{
		'lat': -42.21067559055126,
		'lon': 147.16276538618078,
		'name': 'Tasmania',
		'iso': 'AU',
		'NumberOfSessions': 9
	},
	{
		'lat': -13.784347652096885,
		'lon': -57.1221223207331,
		'name': 'Mato Grosso',
		'iso': 'BR',
		'NumberOfSessions': 2
	},
	{
		'lat': -29.74006924863197,
		'lon': -51.34842450320612,
		'name': 'Rio Grande do Sul',
		'iso': 'BR',
		'NumberOfSessions': 9
	},
	{
		'lat': -19.48539704006973,
		'lon': -40.42495307049419,
		'name': 'Espírito Santo',
		'iso': 'BR',
		'NumberOfSessions': 4
	},
	{
		'lat': -23.230620790458612,
		'lon': -47.002728157743825,
		'name': 'São Paulo',
		'iso': 'BR',
		'NumberOfSessions': 45
	},
	{
		'lat': -25.270394237716395,
		'lon': -49.417113941805674,
		'name': 'Paraná',
		'iso': 'BR',
		'NumberOfSessions': 11
	},
	{
		'lat': -19.556506487439997,
		'lon': -44.503823113265746,
		'name': 'Minas Gerais',
		'iso': 'BR',
		'NumberOfSessions': 18
	},
	{
		'lat': -20.935606117451186,
		'lon': -54.70212662156251,
		'name': 'Mato Grosso do Sul',
		'iso': 'BR',
		'NumberOfSessions': 3
	},
	{
		'lat': 0.0331,
		'lon': -51.0634,
		'name': 'Amapá',
		'iso': 'BR',
		'NumberOfSessions': 1
	},
	{
		'lat': -27.042027301253132,
		'lon': -49.164908472263164,
		'name': 'Santa Catarina',
		'iso': 'BR',
		'NumberOfSessions': 14
	},
	{
		'lat': -8.079291918516,
		'lon': -35.259264894474114,
		'name': 'Pernambuco',
		'iso': 'BR',
		'NumberOfSessions': 3
	},
	{
		'lat': -22.82306138377624,
		'lon': -43.1712437722585,
		'name': 'Rio de Janeiro',
		'iso': 'BR',
		'NumberOfSessions': 12
	},
	{
		'lat': -12.504827715244407,
		'lon': -42.140469162138956,
		'name': 'Bahia',
		'iso': 'BR',
		'NumberOfSessions': 2
	},
	{
		'lat': -15.778600002456068,
		'lon': -47.9326333311633,
		'name': 'Federal District',
		'iso': 'BR',
		'NumberOfSessions': 3
	},
	{
		'lat': -2.527799999999999,
		'lon': -44.3049,
		'name': 'Maranhão',
		'iso': 'BR',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.533229078327324,
		'lon': -122.48068429306335,
		'name': 'British Columbia',
		'iso': 'CA',
		'NumberOfSessions': 81
	},
	{
		'lat': 51.57600882581501,
		'lon': -105.33713924035187,
		'name': 'Saskatchewan',
		'iso': 'CA',
		'NumberOfSessions': 22
	},
	{
		'lat': 46.244330333684466,
		'lon': -72.68701509124446,
		'name': 'Quebec',
		'iso': 'CA',
		'NumberOfSessions': 166
	},
	{
		'lat': 52.564582436905326,
		'lon': -113.71297758483358,
		'name': 'Alberta',
		'iso': 'CA',
		'NumberOfSessions': 64
	},
	{
		'lat': 43.922842079547834,
		'lon': -79.53282227212775,
		'name': 'Ontario',
		'iso': 'CA',
		'NumberOfSessions': 216
	},
	{
		'lat': 49.9583070272639,
		'lon': -97.7118967235055,
		'name': 'Manitoba',
		'iso': 'CA',
		'NumberOfSessions': 15
	},
	{
		'lat': 47.57,
		'lon': -52.781,
		'name': 'Newfoundland and Labrador',
		'iso': 'CA',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.11439422042178,
		'lon': -65.36541812764219,
		'name': 'New Brunswick',
		'iso': 'CA',
		'NumberOfSessions': 10
	},
	{
		'lat': 50.08655465457596,
		'lon': 14.43564073677242,
		'name': 'Prague',
		'iso': 'CZ',
		'NumberOfSessions': 45
	},
	{
		'lat': 49.860179734493784,
		'lon': 17.837500533221668,
		'name': 'Opava District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.7427670668082,
		'lon': 13.378634317982764,
		'name': 'Okres Plzeň-město',
		'iso': 'CZ',
		'NumberOfSessions': 5
	},
	{
		'lat': 49.17290932826896,
		'lon': 16.582198170735943,
		'name': 'Město Brno',
		'iso': 'CZ',
		'NumberOfSessions': 12
	},
	{
		'lat': 49.78045001394076,
		'lon': 16.230596896114523,
		'name': 'Svitavy District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.358,
		'lon': 13.8004,
		'name': 'Louny District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.798775474383035,
		'lon': 18.49719524941096,
		'name': 'Okres Karviná',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.20277443060005,
		'lon': 14.961584696119587,
		'name': 'Nymburk District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.8971,
		'lon': 14.7249,
		'name': 'Okres Praha-východ',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.756269431782385,
		'lon': 11.954367290473739,
		'name': 'Saxony-Anhalt',
		'iso': 'DE',
		'NumberOfSessions': 35
	},
	{
		'lat': 51.06865757372849,
		'lon': 12.975909490170013,
		'name': 'Saxony',
		'iso': 'DE',
		'NumberOfSessions': 84
	},
	{
		'lat': 48.74937623140234,
		'lon': 11.408329245120886,
		'name': 'Bavaria',
		'iso': 'DE',
		'NumberOfSessions': 199
	},
	{
		'lat': 48.768827105236525,
		'lon': 8.922846337678651,
		'name': 'Baden-Wurttemberg',
		'iso': 'DE',
		'NumberOfSessions': 158
	},
	{
		'lat': 51.34525831896711,
		'lon': 7.141754708868593,
		'name': 'North Rhine-Westphalia',
		'iso': 'DE',
		'NumberOfSessions': 241
	},
	{
		'lat': 53.559222227658154,
		'lon': 10.006366606484939,
		'name': 'Free and Hanseatic City of Hamburg',
		'iso': 'DE',
		'NumberOfSessions': 70
	},
	{
		'lat': 54.11445422569878,
		'lon': 10.182307701561227,
		'name': 'Schleswig-Holstein',
		'iso': 'DE',
		'NumberOfSessions': 29
	},
	{
		'lat': 50.22315542722907,
		'lon': 8.695413860814863,
		'name': 'Hesse',
		'iso': 'DE',
		'NumberOfSessions': 167
	},
	{
		'lat': 52.504399974961956,
		'lon': 13.396279444531892,
		'name': 'Land Berlin',
		'iso': 'DE',
		'NumberOfSessions': 119
	},
	{
		'lat': 52.493098692632856,
		'lon': 9.548244131656483,
		'name': 'Lower Saxony',
		'iso': 'DE',
		'NumberOfSessions': 96
	},
	{
		'lat': 50.986466837603906,
		'lon': 10.81597965603569,
		'name': 'Thuringia',
		'iso': 'DE',
		'NumberOfSessions': 26
	},
	{
		'lat': 52.32181578590309,
		'lon': 13.584791030023785,
		'name': 'Brandenburg',
		'iso': 'DE',
		'NumberOfSessions': 22
	},
	{
		'lat': 49.867959755645316,
		'lon': 7.980432053443884,
		'name': 'Rheinland-Pfalz',
		'iso': 'DE',
		'NumberOfSessions': 42
	},
	{
		'lat': 49.26974194485825,
		'lon': 6.962617451523537,
		'name': 'Saarland',
		'iso': 'DE',
		'NumberOfSessions': 9
	},
	{
		'lat': 53.09591973947349,
		'lon': 8.786420104602643,
		'name': 'Bremen',
		'iso': 'DE',
		'NumberOfSessions': 20
	},
	{
		'lat': 28.11224295322293,
		'lon': -16.605387167743306,
		'name': 'Santa Cruz de Tenerife',
		'iso': 'ES',
		'NumberOfSessions': 3
	},
	{
		'lat': 40.41746302503308,
		'lon': -3.7041037076626813,
		'name': 'Madrid',
		'iso': 'ES',
		'NumberOfSessions': 74
	},
	{
		'lat': 43.3699,
		'lon': -8.4096,
		'name': 'Galicia',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.30816000723914,
		'lon': -1.974079723976485,
		'name': 'Gipuzkoa',
		'iso': 'ES',
		'NumberOfSessions': 10
	},
	{
		'lat': 41.46413948536636,
		'lon': 2.15920854427674,
		'name': 'Barcelona',
		'iso': 'ES',
		'NumberOfSessions': 62
	},
	{
		'lat': 39.68884355590708,
		'lon': 3.0420746555687224,
		'name': 'Balearic Islands',
		'iso': 'ES',
		'NumberOfSessions': 8
	},
	{
		'lat': 39.50209754837936,
		'lon': -0.45257354923317206,
		'name': 'Valencia',
		'iso': 'ES',
		'NumberOfSessions': 19
	},
	{
		'lat': 41.13116687548978,
		'lon': 1.2305008533257384,
		'name': 'Tarragona',
		'iso': 'ES',
		'NumberOfSessions': 6
	},
	{
		'lat': 43.30402471255137,
		'lon': -2.9839446984365265,
		'name': 'Biscay',
		'iso': 'ES',
		'NumberOfSessions': 6
	},
	{
		'lat': 41.63320330549256,
		'lon': -4.758208159976091,
		'name': 'Valladolid',
		'iso': 'ES',
		'NumberOfSessions': 3
	},
	{
		'lat': 39.92227664846005,
		'lon': -3.8609879369721845,
		'name': 'Toledo',
		'iso': 'ES',
		'NumberOfSessions': 9
	},
	{
		'lat': 43.26379665635175,
		'lon': -8.427430515059463,
		'name': 'A Coruña',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 36.58297142941666,
		'lon': -4.795444694798408,
		'name': 'Malaga',
		'iso': 'ES',
		'NumberOfSessions': 14
	},
	{
		'lat': 43.378573166328074,
		'lon': -5.772021179226807,
		'name': 'Asturias',
		'iso': 'ES',
		'NumberOfSessions': 6
	},
	{
		'lat': 28.1,
		'lon': -15.4098,
		'name': 'Canary Islands',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.51979594629939,
		'lon': -5.864327271189777,
		'name': 'Cadiz',
		'iso': 'ES',
		'NumberOfSessions': 3
	},
	{
		'lat': 28.361193726789466,
		'lon': -14.754547214364402,
		'name': 'Las Palmas',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 45.20806753914661,
		'lon': 5.649820774689248,
		'name': 'Isère',
		'iso': 'FR',
		'NumberOfSessions': 13
	},
	{
		'lat': 48.83321953563588,
		'lon': 2.4061382086914502,
		'name': 'Paris',
		'iso': 'FR',
		'NumberOfSessions': 138
	},
	{
		'lat': 44.78120169660204,
		'lon': -0.573944433759153,
		'name': 'Gironde',
		'iso': 'FR',
		'NumberOfSessions': 20
	},
	{
		'lat': 47.56601372766405,
		'lon': 1.3736402762521371,
		'name': 'Loir-et-Cher',
		'iso': 'FR',
		'NumberOfSessions': 6
	},
	{
		'lat': 49.164884198037655,
		'lon': 6.4525831603000645,
		'name': 'Moselle',
		'iso': 'FR',
		'NumberOfSessions': 15
	},
	{
		'lat': 47.731704930500996,
		'lon': -3.045649055773051,
		'name': 'Morbihan',
		'iso': 'FR',
		'NumberOfSessions': 12
	},
	{
		'lat': 49.49246091481985,
		'lon': 0.79468552009086,
		'name': 'Seine-Maritime',
		'iso': 'FR',
		'NumberOfSessions': 10
	},
	{
		'lat': 42.664993406535196,
		'lon': 2.890955621064779,
		'name': 'Pyrénées-Orientales',
		'iso': 'FR',
		'NumberOfSessions': 11
	},
	{
		'lat': 48.068454854155306,
		'lon': -0.7965532172501807,
		'name': 'Mayenne',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.72583200888109,
		'lon': 6.175979968776695,
		'name': 'Meurthe et Moselle',
		'iso': 'FR',
		'NumberOfSessions': 6
	},
	{
		'lat': 48.4506404546265,
		'lon': 1.3992830370134144,
		'name': 'Eure-et-Loir',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.76077739086525,
		'lon': 4.8491372213223505,
		'name': 'Rhône',
		'iso': 'FR',
		'NumberOfSessions': 37
	},
	{
		'lat': 43.69569298875449,
		'lon': 0.5511424071683879,
		'name': 'Gers',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.611252016059794,
		'lon': 1.4091557336610285,
		'name': 'Upper Garonne',
		'iso': 'FR',
		'NumberOfSessions': 31
	},
	{
		'lat': 45.21849991112798,
		'lon': 0.8288518666752169,
		'name': 'Dordogne',
		'iso': 'FR',
		'NumberOfSessions': 4
	},
	{
		'lat': 45.74078030460856,
		'lon': -0.7063502451401305,
		'name': 'Charente-Maritime',
		'iso': 'FR',
		'NumberOfSessions': 11
	},
	{
		'lat': 43.56547042073583,
		'lon': 3.703850970583104,
		'name': 'Hérault',
		'iso': 'FR',
		'NumberOfSessions': 23
	},
	{
		'lat': 43.7533077256805,
		'lon': 4.251760854575056,
		'name': 'Gard',
		'iso': 'FR',
		'NumberOfSessions': 7
	},
	{
		'lat': 48.87605300529211,
		'lon': 2.2605123479766234,
		'name': 'Hauts-de-Seine',
		'iso': 'FR',
		'NumberOfSessions': 28
	},
	{
		'lat': 47.47169241115273,
		'lon': -0.5118482425492384,
		'name': 'Maine-et-Loire',
		'iso': 'FR',
		'NumberOfSessions': 9
	},
	{
		'lat': 46.65608580578338,
		'lon': -1.321778006341536,
		'name': 'Vendée',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.828214388655454,
		'lon': 2.0890643983489636,
		'name': 'Yvelines',
		'iso': 'FR',
		'NumberOfSessions': 22
	},
	{
		'lat': 49.02804450551142,
		'lon': 2.203299296904882,
		'name': 'Val d\'Oise',
		'iso': 'FR',
		'NumberOfSessions': 28
	},
	{
		'lat': 44.196,
		'lon': 0.6102,
		'name': 'Lot-et-Garonne',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.33489058433367,
		'lon': 1.4716506903730109,
		'name': 'Corrèze',
		'iso': 'FR',
		'NumberOfSessions': 5
	},
	{
		'lat': 47.98866692834008,
		'lon': 0.22545776723582964,
		'name': 'Sarthe',
		'iso': 'FR',
		'NumberOfSessions': 18
	},
	{
		'lat': 48.628136487531165,
		'lon': 7.646996361034979,
		'name': 'Bas-Rhin',
		'iso': 'FR',
		'NumberOfSessions': 27
	},
	{
		'lat': 47.089100000000016,
		'lon': 5.493500000000002,
		'name': 'Jura',
		'iso': 'FR',
		'NumberOfSessions': 4
	},
	{
		'lat': 49.330507152481175,
		'lon': 3.325505483057376,
		'name': 'Aisne',
		'iso': 'FR',
		'NumberOfSessions': 6
	},
	{
		'lat': 50.59468182685644,
		'lon': 3.1123671493073854,
		'name': 'North',
		'iso': 'FR',
		'NumberOfSessions': 51
	},
	{
		'lat': 43.418397339194016,
		'lon': 5.30461830586393,
		'name': 'Bouches-du-Rhône',
		'iso': 'FR',
		'NumberOfSessions': 23
	},
	{
		'lat': 48.7843390476618,
		'lon': 2.4356502480129576,
		'name': 'Val-de-Marne',
		'iso': 'FR',
		'NumberOfSessions': 18
	},
	{
		'lat': 48.66417488369041,
		'lon': 2.3694100602743426,
		'name': 'Essonne',
		'iso': 'FR',
		'NumberOfSessions': 16
	},
	{
		'lat': 46.1742,
		'lon': 1.8727999999999998,
		'name': 'Creuse',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.90721873898944,
		'lon': 2.0239088057811165,
		'name': 'Loiret',
		'iso': 'FR',
		'NumberOfSessions': 11
	},
	{
		'lat': 46.16806797365778,
		'lon': 5.779114659146072,
		'name': 'Ain',
		'iso': 'FR',
		'NumberOfSessions': 5
	},
	{
		'lat': 48.48567653638156,
		'lon': -2.4029392200738187,
		'name': 'Côtes-d\'Armor',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.87750205457071,
		'lon': 4.94532597131343,
		'name': 'Drôme',
		'iso': 'FR',
		'NumberOfSessions': 9
	},
	{
		'lat': 48.52304727937782,
		'lon': -4.076865178774752,
		'name': 'Finistère',
		'iso': 'FR',
		'NumberOfSessions': 11
	},
	{
		'lat': 46.100805569447814,
		'lon': 6.498949161637495,
		'name': 'Upper Savoy',
		'iso': 'FR',
		'NumberOfSessions': 7
	},
	{
		'lat': 44.87221123789894,
		'lon': 1.8178722216690695,
		'name': 'Lot',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.44134649348049,
		'lon': 2.4323247727175676,
		'name': 'Oise',
		'iso': 'FR',
		'NumberOfSessions': 7
	},
	{
		'lat': 47.74001855793165,
		'lon': 6.495125419479791,
		'name': 'Haute-Saône',
		'iso': 'FR',
		'NumberOfSessions': 6
	},
	{
		'lat': 49.200560460948154,
		'lon': -0.30797491904870256,
		'name': 'Calvados',
		'iso': 'FR',
		'NumberOfSessions': 12
	},
	{
		'lat': 48.19742187308923,
		'lon': -1.7332457736904563,
		'name': 'Ille-et-Vilaine',
		'iso': 'FR',
		'NumberOfSessions': 23
	},
	{
		'lat': 45.838689111670924,
		'lon': 1.2539509169058345,
		'name': 'Haute-Vienne',
		'iso': 'FR',
		'NumberOfSessions': 8
	},
	{
		'lat': 49.93421248936829,
		'lon': 2.266196332732517,
		'name': 'Somme',
		'iso': 'FR',
		'NumberOfSessions': 15
	},
	{
		'lat': 48.8611,
		'lon': 2.326900000000001,
		'name': 'Île-de-France',
		'iso': 'FR',
		'NumberOfSessions': 29
	},
	{
		'lat': 47.20933933079711,
		'lon': -1.5711949205056202,
		'name': 'Loire-Atlantique',
		'iso': 'FR',
		'NumberOfSessions': 43
	},
	{
		'lat': 47.82601781015738,
		'lon': 7.33453943554504,
		'name': 'Haut-Rhin',
		'iso': 'FR',
		'NumberOfSessions': 17
	},
	{
		'lat': 50.526996277783944,
		'lon': 2.364454828080845,
		'name': 'Pas-de-Calais',
		'iso': 'FR',
		'NumberOfSessions': 13
	},
	{
		'lat': 45.55952927874077,
		'lon': 4.3743964430759075,
		'name': 'Loire',
		'iso': 'FR',
		'NumberOfSessions': 5
	},
	{
		'lat': 43.19124455865461,
		'lon': 2.989822531127192,
		'name': 'Aude',
		'iso': 'FR',
		'NumberOfSessions': 5
	},
	{
		'lat': 43.37499571909045,
		'lon': -0.5479069462960724,
		'name': 'Pyrénées-Atlantiques',
		'iso': 'FR',
		'NumberOfSessions': 15
	},
	{
		'lat': 46.815200000000004,
		'lon': 1.6943,
		'name': 'Indre',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.88929452649082,
		'lon': 2.477763191308477,
		'name': 'Seine-Saint-Denis',
		'iso': 'FR',
		'NumberOfSessions': 14
	},
	{
		'lat': 43.22412132846146,
		'lon': 6.185797256820144,
		'name': 'Var',
		'iso': 'FR',
		'NumberOfSessions': 11
	},
	{
		'lat': 48.72225086972861,
		'lon': 2.721781452447127,
		'name': 'Seine-et-Marne',
		'iso': 'FR',
		'NumberOfSessions': 18
	},
	{
		'lat': 51.50774109423187,
		'lon': -0.11312988027329055,
		'name': 'England',
		'iso': 'GB',
		'NumberOfSessions': 37
	},
	{
		'lat': 54.971233440865426,
		'lon': -1.6183914483856872,
		'name': 'Newcastle upon Tyne',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.51174438456464,
		'lon': -0.148436499109448,
		'name': 'City of Westminster',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.49270466595533,
		'lon': -0.07971291112842101,
		'name': 'Southwark',
		'iso': 'GB',
		'NumberOfSessions': 26
	},
	{
		'lat': 51.9276,
		'lon': -0.49680000000000013,
		'name': 'Luton',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 51.67473392714221,
		'lon': 0.5701639884355133,
		'name': 'Essex',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 53.48581068290117,
		'lon': -2.230392340364601,
		'name': 'Manchester',
		'iso': 'GB',
		'NumberOfSessions': 39
	},
	{
		'lat': 52.414575014451415,
		'lon': -1.5071255978912175,
		'name': 'Coventry',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 53.2781427135261,
		'lon': -2.774342458457253,
		'name': 'Cheshire',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 50.911320097565316,
		'lon': -1.39003880677496,
		'name': 'Southampton',
		'iso': 'GB',
		'NumberOfSessions': 16
	},
	{
		'lat': 51.46276292565729,
		'lon': -2.5551091843178892,
		'name': 'Bristol',
		'iso': 'GB',
		'NumberOfSessions': 22
	},
	{
		'lat': 53.68852894058041,
		'lon': -1.728720969086335,
		'name': 'Kirklees',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 50.99761553936154,
		'lon': -2.7009982842530467,
		'name': 'Somerset',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.4916,
		'lon': -2.3009,
		'name': 'Salford',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 55.85456715738012,
		'lon': -4.268054383703723,
		'name': 'Glasgow City',
		'iso': 'GB',
		'NumberOfSessions': 25
	},
	{
		'lat': 51.613760101589456,
		'lon': 0.18863541498552505,
		'name': 'Havering',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 54.604700000000015,
		'lon': -1.0649000000000002,
		'name': 'Redcar and Cleveland',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 55.94294204083621,
		'lon': -3.212452504461557,
		'name': 'Edinburgh',
		'iso': 'GB',
		'NumberOfSessions': 15
	},
	{
		'lat': 51.728264236469954,
		'lon': -0.7784509744863188,
		'name': 'Buckinghamshire',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 55.85270477212019,
		'lon': -4.451993156475264,
		'name': 'Renfrewshire',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 50.510600000000004,
		'lon': -4.3146,
		'name': 'Cornwall',
		'iso': 'GB',
		'NumberOfSessions': 11
	},
	{
		'lat': 53.39067573248498,
		'lon': -1.4577336049451368,
		'name': 'Sheffield',
		'iso': 'GB',
		'NumberOfSessions': 17
	},
	{
		'lat': 53.42003324824603,
		'lon': -2.950363913504045,
		'name': 'Liverpool',
		'iso': 'GB',
		'NumberOfSessions': 10
	},
	{
		'lat': 52.157560050479326,
		'lon': 0.8739744172372277,
		'name': 'Suffolk',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 54.86848379884568,
		'lon': -1.4522903405309233,
		'name': 'Sunderland',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 56.1185,
		'lon': -3.7941,
		'name': 'Clackmannanshire',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 56.1777,
		'lon': -4.3848,
		'name': 'Stirling',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.747140616539944,
		'lon': -2.506910737900539,
		'name': 'Dorset',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.526428406857924,
		'lon': -0.1556553769190149,
		'name': 'Camden',
		'iso': 'GB',
		'NumberOfSessions': 25
	},
	{
		'lat': 51.36590391464955,
		'lon': 0.5321499562129567,
		'name': 'Medway',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.02069351333997,
		'lon': -2.1725695312859643,
		'name': 'Stoke-on-Trent',
		'iso': 'GB',
		'NumberOfSessions': 12
	},
	{
		'lat': 51.54269566895509,
		'lon': -0.11188780980800553,
		'name': 'Islington',
		'iso': 'GB',
		'NumberOfSessions': 19
	},
	{
		'lat': 51.51387830498067,
		'lon': -3.6186938757465077,
		'name': 'County Borough of Bridgend',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.47063945755053,
		'lon': 0.036532072857121796,
		'name': 'Greenwich',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 51.558653771566995,
		'lon': -0.24550050900718037,
		'name': 'Brent',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 52.62685374931966,
		'lon': -1.1436197212765296,
		'name': 'Leicester',
		'iso': 'GB',
		'NumberOfSessions': 10
	},
	{
		'lat': 51.23437540358827,
		'lon': 0.6657698183641283,
		'name': 'Kent',
		'iso': 'GB',
		'NumberOfSessions': 22
	},
	{
		'lat': 56.10570033572509,
		'lon': -3.294771175143094,
		'name': 'Fife',
		'iso': 'GB',
		'NumberOfSessions': 15
	},
	{
		'lat': 53.101346845407996,
		'lon': -1.554800484922087,
		'name': 'Derbyshire',
		'iso': 'GB',
		'NumberOfSessions': 16
	},
	{
		'lat': 52.78912010980163,
		'lon': -1.863185161014505,
		'name': 'Staffordshire',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 51.499300000000005,
		'lon': -0.12229999999999999,
		'name': 'Lambeth',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.198060354262346,
		'lon': -0.5038238017948867,
		'name': 'Lincolnshire',
		'iso': 'GB',
		'NumberOfSessions': 15
	},
	{
		'lat': 52.47960084876759,
		'lon': -1.9888344278328256,
		'name': 'Sandwell',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 50.92402671873307,
		'lon': -0.35330944919729834,
		'name': 'West Sussex',
		'iso': 'GB',
		'NumberOfSessions': 11
	},
	{
		'lat': 51.55757160471537,
		'lon': 0.07402816517037134,
		'name': 'Redbridge',
		'iso': 'GB',
		'NumberOfSessions': 14
	},
	{
		'lat': 51.54593472810121,
		'lon': -0.06174269500693862,
		'name': 'Hackney',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 51.49982259884626,
		'lon': -0.19117700407738372,
		'name': 'Royal Kensington and Chelsea',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 50.65205429682784,
		'lon': -3.5859820976309686,
		'name': 'Devon',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 52.93266085050491,
		'lon': -1.1682195906536836,
		'name': 'Nottingham',
		'iso': 'GB',
		'NumberOfSessions': 15
	},
	{
		'lat': 53.3815822700707,
		'lon': -3.03830948615916,
		'name': 'Wirral',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.568588007102214,
		'lon': -0.022786792176224025,
		'name': 'Waltham Forest',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.78917846395938,
		'lon': -1.547825690883725,
		'name': 'Leeds',
		'iso': 'GB',
		'NumberOfSessions': 21
	},
	{
		'lat': 54.60230226185089,
		'lon': -6.88183639595326,
		'name': 'Mid Ulster',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.3006,
		'lon': -2.6604,
		'name': 'Halton',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 52.203758946106326,
		'lon': -2.0116468836372605,
		'name': 'Worcestershire',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.50644168980226,
		'lon': -3.1724828181672717,
		'name': 'Cardiff',
		'iso': 'GB',
		'NumberOfSessions': 22
	},
	{
		'lat': 52.58609897810203,
		'lon': 1.230895903418407,
		'name': 'Norfolk',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 53.534822582351104,
		'lon': -2.6138656817378774,
		'name': 'Wigan',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 51.5368,
		'lon': -0.6718000000000001,
		'name': 'Slough',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.5628,
		'lon': -0.0269,
		'name': 'North East Lincolnshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.34169403277274,
		'lon': -0.5323279906771314,
		'name': 'Surrey',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 50.796438702188695,
		'lon': 0.1704584012446673,
		'name': 'East Sussex',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 50.39630445739164,
		'lon': -4.1326122306077915,
		'name': 'Plymouth',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 52.27042949612671,
		'lon': -0.8117195897193015,
		'name': 'Northamptonshire',
		'iso': 'GB',
		'NumberOfSessions': 14
	},
	{
		'lat': 52.66363535881279,
		'lon': -1.2598806261906825,
		'name': 'Leicestershire',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 52.48828831930838,
		'lon': -1.8845956453308117,
		'name': 'Birmingham',
		'iso': 'GB',
		'NumberOfSessions': 23
	},
	{
		'lat': 54.54420362790557,
		'lon': -1.5557342256136482,
		'name': 'Darlington',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 52.503,
		'lon': -2.0839,
		'name': 'Dudley',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.05830201639028,
		'lon': -2.6096591842943115,
		'name': 'Herefordshire',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 53.19382381949341,
		'lon': -1.171804255079264,
		'name': 'Nottinghamshire',
		'iso': 'GB',
		'NumberOfSessions': 9
	},
	{
		'lat': 52.314510516517636,
		'lon': 0.08242790081439055,
		'name': 'Cambridgeshire',
		'iso': 'GB',
		'NumberOfSessions': 12
	},
	{
		'lat': 51.30072958271301,
		'lon': -2.442882193879336,
		'name': 'Bath and North East Somerset',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.56973150417532,
		'lon': -1.7736027151723541,
		'name': 'Swindon',
		'iso': 'GB',
		'NumberOfSessions': 10
	},
	{
		'lat': 54.53960000000001,
		'lon': -5.974999999999996,
		'name': 'Belfast',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 50.84518454799062,
		'lon': -0.15936914866016214,
		'name': 'Brighton and Hove',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.458800000000004,
		'lon': -2.6479,
		'name': 'St. Helens',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.371646546078054,
		'lon': -0.05758303561202736,
		'name': 'Croydon',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 51.7251,
		'lon': -3.3642,
		'name': 'Merthyr Tydfil',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.57590361602001,
		'lon': -2.4059425468253166,
		'name': 'Bolton',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 53.4036,
		'lon': -2.747499999999999,
		'name': 'Knowsley',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.59765567507576,
		'lon': -0.1097532593764506,
		'name': 'Haringey',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.76610142818446,
		'lon': -0.36146963846979235,
		'name': 'Kingston upon Hull',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.07902661387693,
		'lon': -1.1737065683473529,
		'name': 'Hampshire',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 51.529321977665084,
		'lon': 0.02484470816973309,
		'name': 'Newham',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.407766896917316,
		'lon': -0.16999767268836738,
		'name': 'Merton',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.358,
		'lon': -0.8521,
		'name': 'Wokingham',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.80645676247157,
		'lon': -0.21381059293061058,
		'name': 'Hertfordshire',
		'iso': 'GB',
		'NumberOfSessions': 19
	},
	{
		'lat': 51.40997120802494,
		'lon': -0.9939205063704251,
		'name': 'Reading',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 51.4481425555286,
		'lon': -0.18590012523734098,
		'name': 'Wandsworth',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 55.4754,
		'lon': -4.6062,
		'name': 'South Ayrshire',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 52.03770427974653,
		'lon': -0.7380625433445573,
		'name': 'Milton Keynes',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 50.731909566389966,
		'lon': -1.8987674682240951,
		'name': 'Bournemouth, Christchurch and Poole Council',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.476939567011684,
		'lon': -0.36267639054222317,
		'name': 'Hounslow',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 55.7292,
		'lon': -3.9601,
		'name': 'South Lanarkshire',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.689354246607174,
		'lon': -6.084269166919884,
		'name': 'Antrim and Newtownabbey',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 56.7481,
		'lon': -2.427299999999999,
		'name': 'Angus',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 57.65107759329425,
		'lon': -3.4621588889506394,
		'name': 'Moray',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.51215384637976,
		'lon': -0.4534772499628667,
		'name': 'Hillingdon',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.36245927478631,
		'lon': -0.17695917212349552,
		'name': 'Sutton',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 53.0919,
		'lon': -2.3848000000000007,
		'name': 'Cheshire East',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.51033991104903,
		'lon': -0.031122775985969423,
		'name': 'Tower Hamlets',
		'iso': 'GB',
		'NumberOfSessions': 13
	},
	{
		'lat': 53.33801776183967,
		'lon': -6.259058189478958,
		'name': 'Leinster',
		'iso': 'IE',
		'NumberOfSessions': 2501
	},
	{
		'lat': 17.3724,
		'lon': 78.4378,
		'name': 'Telangana',
		'iso': 'IN',
		'NumberOfSessions': 5
	},
	{
		'lat': 22.84519445945216,
		'lon': 72.13641665107914,
		'name': 'Gujarat',
		'iso': 'IN',
		'NumberOfSessions': 4
	},
	{
		'lat': 12.963400000000004,
		'lon': 77.5855,
		'name': 'Karnataka',
		'iso': 'IN',
		'NumberOfSessions': 10
	},
	{
		'lat': 26.875599999999995,
		'lon': 80.9115,
		'name': 'Uttar Pradesh',
		'iso': 'IN',
		'NumberOfSessions': 6
	},
	{
		'lat': 28.65298234830971,
		'lon': 77.1978551872416,
		'name': 'National Capital Territory of Delhi',
		'iso': 'IN',
		'NumberOfSessions': 9
	},
	{
		'lat': 11.961505482291962,
		'lon': 78.60187642726946,
		'name': 'Tamil Nadu',
		'iso': 'IN',
		'NumberOfSessions': 4
	},
	{
		'lat': 22.932129960924588,
		'lon': 76.4600741656863,
		'name': 'Madhya Pradesh',
		'iso': 'IN',
		'NumberOfSessions': 10
	},
	{
		'lat': 9.8880129249556,
		'lon': 76.28259519245064,
		'name': 'Kerala',
		'iso': 'IN',
		'NumberOfSessions': 8
	},
	{
		'lat': 19.018514456271443,
		'lon': 73.59689035748916,
		'name': 'Maharashtra',
		'iso': 'IN',
		'NumberOfSessions': 17
	},
	{
		'lat': 26.9525,
		'lon': 75.7105,
		'name': 'Rajasthan',
		'iso': 'IN',
		'NumberOfSessions': 4
	},
	{
		'lat': 45.47241769541467,
		'lon': 9.186550134391418,
		'name': 'Milan',
		'iso': 'IT',
		'NumberOfSessions': 51
	},
	{
		'lat': 44.6116561093473,
		'lon': 10.944696437154763,
		'name': 'Province of Modena',
		'iso': 'IT',
		'NumberOfSessions': 4
	},
	{
		'lat': 37.4925,
		'lon': 15.070100000000002,
		'name': 'Catania',
		'iso': 'IT',
		'NumberOfSessions': 4
	},
	{
		'lat': 41.88743291603527,
		'lon': 12.535694039711851,
		'name': 'Rome',
		'iso': 'IT',
		'NumberOfSessions': 30
	},
	{
		'lat': 40.87575209857243,
		'lon': 14.262020024861675,
		'name': 'Naples',
		'iso': 'IT',
		'NumberOfSessions': 18
	},
	{
		'lat': 45.4742,
		'lon': 9.199399999999995,
		'name': 'Lombardy',
		'iso': 'IT',
		'NumberOfSessions': 16
	},
	{
		'lat': 37.126158828390004,
		'lon': 15.079268022143305,
		'name': 'Syracuse',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.431700000000006,
		'lon': 10.985900000000003,
		'name': 'Province of Verona',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.63196235721016,
		'lon': 8.910515027283987,
		'name': 'Province of Varese',
		'iso': 'IT',
		'NumberOfSessions': 4
	},
	{
		'lat': 45.091399999999986,
		'lon': 7.6639,
		'name': 'Turin',
		'iso': 'IT',
		'NumberOfSessions': 12
	},
	{
		'lat': 45.54170699598328,
		'lon': 10.176979513731089,
		'name': 'Province of Brescia',
		'iso': 'IT',
		'NumberOfSessions': 6
	},
	{
		'lat': 45.543789869031535,
		'lon': 9.72474502194717,
		'name': 'Province of Bergamo',
		'iso': 'IT',
		'NumberOfSessions': 8
	},
	{
		'lat': 45.70836365572089,
		'lon': 12.296700715794591,
		'name': 'Province of Treviso',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 38.11385363183424,
		'lon': 15.663546007769087,
		'name': 'Province of Reggio Calabria',
		'iso': 'IT',
		'NumberOfSessions': 24
	},
	{
		'lat': 36.8565338115752,
		'lon': 14.817728410011334,
		'name': 'Province of Ragusa',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.103,
		'lon': 12.537700000000001,
		'name': 'Province of Perugia',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.436260849555694,
		'lon': 12.474775495502524,
		'name': 'Venice',
		'iso': 'IT',
		'NumberOfSessions': 7
	},
	{
		'lat': 44.487999481848725,
		'lon': 11.359659989740218,
		'name': 'Bologna',
		'iso': 'IT',
		'NumberOfSessions': 10
	},
	{
		'lat': 35.68250607151523,
		'lon': 139.6928502517334,
		'name': 'Tokyo',
		'iso': 'JP',
		'NumberOfSessions': 40
	},
	{
		'lat': 35.833,
		'lon': 136.9456,
		'name': 'Gifu',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.47675931453029,
		'lon': 139.5924483546345,
		'name': 'Kanagawa',
		'iso': 'JP',
		'NumberOfSessions': 10
	},
	{
		'lat': 34.68558659725886,
		'lon': 135.53062661821826,
		'name': 'Osaka',
		'iso': 'JP',
		'NumberOfSessions': 12
	},
	{
		'lat': 33.836499999999994,
		'lon': 132.742,
		'name': 'Ehime',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.69829623682752,
		'lon': 140.02938978391558,
		'name': 'Chiba',
		'iso': 'JP',
		'NumberOfSessions': 7
	},
	{
		'lat': 39.59012320324822,
		'lon': 140.29802947120615,
		'name': 'Akita',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.179871458277155,
		'lon': 136.965047557969,
		'name': 'Aichi',
		'iso': 'JP',
		'NumberOfSessions': 6
	},
	{
		'lat': 25.0291,
		'lon': -101.1251,
		'name': 'Coahuila',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 19.401080581869515,
		'lon': -99.13856126244778,
		'name': 'Mexico City',
		'iso': 'MX',
		'NumberOfSessions': 34
	},
	{
		'lat': 19.448251902140512,
		'lon': -99.21389553467344,
		'name': 'México',
		'iso': 'MX',
		'NumberOfSessions': 12
	},
	{
		'lat': 20.675304338842096,
		'lon': -103.44945020644774,
		'name': 'Jalisco',
		'iso': 'MX',
		'NumberOfSessions': 7
	},
	{
		'lat': 20.9970356500207,
		'lon': -89.64043074228678,
		'name': 'Yucatán',
		'iso': 'MX',
		'NumberOfSessions': 3
	},
	{
		'lat': 19.280873875384977,
		'lon': -102.02956876991404,
		'name': 'Michoacán',
		'iso': 'MX',
		'NumberOfSessions': 3
	},
	{
		'lat': 16.73870000000001,
		'lon': -93.1414,
		'name': 'Chiapas',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 16.910299999999996,
		'lon': -99.7727,
		'name': 'Guerrero',
		'iso': 'MX',
		'NumberOfSessions': 2
	},
	{
		'lat': 32.52077501493664,
		'lon': -116.53513910957058,
		'name': 'Baja California',
		'iso': 'MX',
		'NumberOfSessions': 7
	},
	{
		'lat': 25.718540062320216,
		'lon': -100.34922416886684,
		'name': 'Nuevo León',
		'iso': 'MX',
		'NumberOfSessions': 11
	},
	{
		'lat': 20.594786144874586,
		'lon': -100.43388014493266,
		'name': 'Querétaro',
		'iso': 'MX',
		'NumberOfSessions': 5
	},
	{
		'lat': 21.071503973168124,
		'lon': -101.43118156759799,
		'name': 'Guanajuato',
		'iso': 'MX',
		'NumberOfSessions': 2
	},
	{
		'lat': 19.2561,
		'lon': -103.7486,
		'name': 'Colima',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 19.935642802391914,
		'lon': -97.18955904854226,
		'name': 'Veracruz',
		'iso': 'MX',
		'NumberOfSessions': 2
	},
	{
		'lat': 52.39649193938279,
		'lon': 4.870729210319504,
		'name': 'North Holland',
		'iso': 'NL',
		'NumberOfSessions': 43
	},
	{
		'lat': 51.57976345024784,
		'lon': 5.207337522633623,
		'name': 'North Brabant',
		'iso': 'NL',
		'NumberOfSessions': 18
	},
	{
		'lat': 52.43776731285431,
		'lon': 5.337470819108405,
		'name': 'Flevoland',
		'iso': 'NL',
		'NumberOfSessions': 7
	},
	{
		'lat': 51.997426832725594,
		'lon': 5.6584088498576515,
		'name': 'Gelderland',
		'iso': 'NL',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.96845133111766,
		'lon': 4.394874864113418,
		'name': 'South Holland',
		'iso': 'NL',
		'NumberOfSessions': 21
	},
	{
		'lat': 52.31111202558148,
		'lon': 6.592165647723273,
		'name': 'Overijssel',
		'iso': 'NL',
		'NumberOfSessions': 7
	},
	{
		'lat': 50.210606199439596,
		'lon': 18.949125204195433,
		'name': 'Silesia',
		'iso': 'PL',
		'NumberOfSessions': 20
	},
	{
		'lat': 52.2380752628811,
		'lon': 21.04363543600549,
		'name': 'Mazovia',
		'iso': 'PL',
		'NumberOfSessions': 65
	},
	{
		'lat': 52.285724133829575,
		'lon': 17.08507952796246,
		'name': 'Greater Poland',
		'iso': 'PL',
		'NumberOfSessions': 30
	},
	{
		'lat': 53.14618824514533,
		'lon': 18.44437988622522,
		'name': 'Kujawsko-Pomorskie',
		'iso': 'PL',
		'NumberOfSessions': 9
	},
	{
		'lat': 51.11633306431412,
		'lon': 16.86238373393357,
		'name': 'Lower Silesia',
		'iso': 'PL',
		'NumberOfSessions': 20
	},
	{
		'lat': 54.40158122408403,
		'lon': 18.5697419204683,
		'name': 'Pomerania',
		'iso': 'PL',
		'NumberOfSessions': 9
	},
	{
		'lat': 51.38425558524051,
		'lon': 22.741416374196476,
		'name': 'Lublin',
		'iso': 'PL',
		'NumberOfSessions': 10
	},
	{
		'lat': 51.6822533086672,
		'lon': 19.49394049848207,
		'name': 'Łódź Voivodeship',
		'iso': 'PL',
		'NumberOfSessions': 18
	},
	{
		'lat': 53.866606372780176,
		'lon': 20.79190612796546,
		'name': 'Warmia-Masuria',
		'iso': 'PL',
		'NumberOfSessions': 3
	},
	{
		'lat': 49.99996132539376,
		'lon': 19.850091621394483,
		'name': 'Lesser Poland',
		'iso': 'PL',
		'NumberOfSessions': 22
	},
	{
		'lat': 55.74830000000007,
		'lon': 37.61709999999987,
		'name': 'Moscow',
		'iso': 'RU',
		'NumberOfSessions': 181
	},
	{
		'lat': 58.51100000000001,
		'lon': 31.3251,
		'name': 'Novgorod Oblast',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 53.24101840905019,
		'lon': 84.0008778101837,
		'name': 'Altai Krai',
		'iso': 'RU',
		'NumberOfSessions': 7
	},
	{
		'lat': 58.21751606733478,
		'lon': 56.283100861101914,
		'name': 'Perm Krai',
		'iso': 'RU',
		'NumberOfSessions': 15
	},
	{
		'lat': 46.346900012298356,
		'lon': 48.04053990779552,
		'name': 'Astrakhan Oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 54.22865916299711,
		'lon': 34.94520484635727,
		'name': 'Kaluga Oblast',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 59.939474780216024,
		'lon': 30.307145675108078,
		'name': 'St.-Petersburg',
		'iso': 'RU',
		'NumberOfSessions': 78
	},
	{
		'lat': 55.194011862318696,
		'lon': 61.11677725314226,
		'name': 'Chelyabinsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 17
	},
	{
		'lat': 54.82389378520938,
		'lon': 86.48936485903687,
		'name': 'Kemerovo Oblast',
		'iso': 'RU',
		'NumberOfSessions': 12
	},
	{
		'lat': 51.7321,
		'lon': 36.19959999999999,
		'name': 'Kursk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 45.04691988226398,
		'lon': 38.59646509397873,
		'name': 'Krasnodar Krai',
		'iso': 'RU',
		'NumberOfSessions': 23
	},
	{
		'lat': 55.00957821301926,
		'lon': 73.37842368298406,
		'name': 'Omsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 9
	},
	{
		'lat': 43.039859867220976,
		'lon': 132.3921873438477,
		'name': 'Primorye',
		'iso': 'RU',
		'NumberOfSessions': 10
	},
	{
		'lat': 56.917627180972,
		'lon': 60.56011105893782,
		'name': 'Sverdlovsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 24
	},
	{
		'lat': 55.73069568461786,
		'lon': 37.82274071525629,
		'name': 'Moscow Oblast',
		'iso': 'RU',
		'NumberOfSessions': 37
	},
	{
		'lat': 59.49798992190527,
		'lon': 29.922757034501075,
		'name': 'Leningrad Oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 58.58332168728009,
		'lon': 49.87196635896635,
		'name': 'Kirov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 54.949423057285806,
		'lon': 83.00206991391367,
		'name': 'Novosibirsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 26
	},
	{
		'lat': 50.6170000000963,
		'lon': 36.57565000159457,
		'name': 'Belgorod Oblast',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 52.881687531282374,
		'lon': 104.31038799417274,
		'name': 'Irkutsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 9
	},
	{
		'lat': 58.361452319473514,
		'lon': 92.07796614683186,
		'name': 'Krasnoyarsk Krai',
		'iso': 'RU',
		'NumberOfSessions': 17
	},
	{
		'lat': 55.766477123180145,
		'lon': 50.126511555205504,
		'name': 'Tatarstan Republic',
		'iso': 'RU',
		'NumberOfSessions': 10
	},
	{
		'lat': 69.3807,
		'lon': 33.739,
		'name': 'Murmansk',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 51.638976879248,
		'lon': 39.180042015525586,
		'name': 'Voronezh Oblast',
		'iso': 'RU',
		'NumberOfSessions': 11
	},
	{
		'lat': 53.27953023800115,
		'lon': 49.963286472895795,
		'name': 'Samara Oblast',
		'iso': 'RU',
		'NumberOfSessions': 12
	},
	{
		'lat': 56.33269999999999,
		'lon': 44.00120000000001,
		'name': 'Nizhny Novgorod Oblast',
		'iso': 'RU',
		'NumberOfSessions': 18
	},
	{
		'lat': 51.55683460256392,
		'lon': 46.027388798894826,
		'name': 'Saratov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 7
	},
	{
		'lat': 47.24963032998864,
		'lon': 39.64576446003988,
		'name': 'Rostov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 16
	},
	{
		'lat': 64.54627512586879,
		'lon': 40.44526170403643,
		'name': 'Arkhangelskaya',
		'iso': 'RU',
		'NumberOfSessions': 8
	},
	{
		'lat': 54.91867027236141,
		'lon': 55.73918490254918,
		'name': 'Bashkortostan Republic',
		'iso': 'RU',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.77488225345018,
		'lon': 91.36104954008708,
		'name': 'Khakasiya Republic',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 52.5882,
		'lon': 39.6023,
		'name': 'Lipetsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 66.0793,
		'lon': 76.6756,
		'name': 'Yamalo-Nenets',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 52.7324,
		'lon': 41.4313,
		'name': 'Tambov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 66.57856229421016,
		'lon': 61.154739191142646,
		'name': 'Komi',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 52.84654354227133,
		'lon': 36.21475612644554,
		'name': 'Oryol oblast',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.7898,
		'lon': 55.098400000000005,
		'name': 'Orenburg Oblast',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 59.31528550628012,
		'lon': 17.997063111961097,
		'name': 'Stockholm County',
		'iso': 'SE',
		'NumberOfSessions': 20
	},
	{
		'lat': 57.89052664312106,
		'lon': 12.342227580788176,
		'name': 'Västra Götaland County',
		'iso': 'SE',
		'NumberOfSessions': 12
	},
	{
		'lat': 59.19985187460229,
		'lon': 15.169639857230628,
		'name': 'Örebro County',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 60.09711061309806,
		'lon': 17.58163962144766,
		'name': 'Uppsala County',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 60.60508323243356,
		'lon': 15.195510124391067,
		'name': 'Dalarna County',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 66.012033799831,
		'lon': 21.791211326107067,
		'name': 'Norrbotten County',
		'iso': 'SE',
		'NumberOfSessions': 9
	},
	{
		'lat': 62.377199999999995,
		'lon': 17.3203,
		'name': 'Västernorrland County',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.43880147582646,
		'lon': 27.13830296934635,
		'name': 'İzmir Province',
		'iso': 'TR',
		'NumberOfSessions': 6
	},
	{
		'lat': 41.02322778824587,
		'lon': 28.943761593963902,
		'name': 'Istanbul',
		'iso': 'TR',
		'NumberOfSessions': 23
	},
	{
		'lat': 39.93252056044825,
		'lon': 32.834780211397174,
		'name': 'Ankara',
		'iso': 'TR',
		'NumberOfSessions': 7
	},
	{
		'lat': 36.42156852147208,
		'lon': 36.35045985250898,
		'name': 'Hatay',
		'iso': 'TR',
		'NumberOfSessions': 2
	},
	{
		'lat': 40.2147800059472,
		'lon': 29.019239573311694,
		'name': 'Bursa Province',
		'iso': 'TR',
		'NumberOfSessions': 4
	},
	{
		'lat': 49.23297781788699,
		'lon': 28.48062213107811,
		'name': 'Vinnytsia',
		'iso': 'UA',
		'NumberOfSessions': 9
	},
	{
		'lat': 46.41961047288245,
		'lon': 30.636878772561268,
		'name': 'Odessa',
		'iso': 'UA',
		'NumberOfSessions': 20
	},
	{
		'lat': 47.7957,
		'lon': 35.0489,
		'name': 'Zaporizhzhia',
		'iso': 'UA',
		'NumberOfSessions': 5
	},
	{
		'lat': 50.462889335889116,
		'lon': 30.528558799985596,
		'name': 'Kyiv City',
		'iso': 'UA',
		'NumberOfSessions': 37
	},
	{
		'lat': 49.982000000000006,
		'lon': 36.256600000000006,
		'name': 'Kharkivs’ka Oblast’',
		'iso': 'UA',
		'NumberOfSessions': 8
	},
	{
		'lat': 50.43135733928306,
		'lon': 30.409521297547933,
		'name': 'Kyiv Oblast',
		'iso': 'UA',
		'NumberOfSessions': 9
	},
	{
		'lat': 50.6223,
		'lon': 26.239599999999996,
		'name': 'Rivne',
		'iso': 'UA',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.819982422673,
		'lon': -97.02386987954279,
		'name': 'Oklahoma',
		'iso': 'US',
		'NumberOfSessions': 36
	},
	{
		'lat': 35.83771165501195,
		'lon': -86.4905394078665,
		'name': 'Tennessee',
		'iso': 'US',
		'NumberOfSessions': 68
	},
	{
		'lat': 41.48632583227325,
		'lon': -88.18217361146515,
		'name': 'Illinois',
		'iso': 'US',
		'NumberOfSessions': 146
	},
	{
		'lat': 40.499193431698004,
		'lon': -111.88105136670994,
		'name': 'Utah',
		'iso': 'US',
		'NumberOfSessions': 70
	},
	{
		'lat': 47.40856580578587,
		'lon': -121.99795951097478,
		'name': 'Washington',
		'iso': 'US',
		'NumberOfSessions': 116
	},
	{
		'lat': 40.40883715508796,
		'lon': -74.47013039713215,
		'name': 'New Jersey',
		'iso': 'US',
		'NumberOfSessions': 73
	},
	{
		'lat': 37.774856273435404,
		'lon': -85.38287411660787,
		'name': 'Kentucky',
		'iso': 'US',
		'NumberOfSessions': 35
	},
	{
		'lat': 41.490547155763274,
		'lon': -97.95456280598584,
		'name': 'Nebraska',
		'iso': 'US',
		'NumberOfSessions': 41
	},
	{
		'lat': 38.62556303544155,
		'lon': -77.54417235878753,
		'name': 'Virginia',
		'iso': 'US',
		'NumberOfSessions': 289
	},
	{
		'lat': 35.48399817174699,
		'lon': -119.4027614699727,
		'name': 'California',
		'iso': 'US',
		'NumberOfSessions': 467
	},
	{
		'lat': 43.74664052090418,
		'lon': -88.94408551646873,
		'name': 'Wisconsin',
		'iso': 'US',
		'NumberOfSessions': 89
	},
	{
		'lat': 33.37149662240567,
		'lon': -111.8619778978429,
		'name': 'Arizona',
		'iso': 'US',
		'NumberOfSessions': 94
	},
	{
		'lat': 40.31278652263208,
		'lon': -82.993644935034,
		'name': 'Ohio',
		'iso': 'US',
		'NumberOfSessions': 126
	},
	{
		'lat': 39.63685569888615,
		'lon': -105.17437608894997,
		'name': 'Colorado',
		'iso': 'US',
		'NumberOfSessions': 79
	},
	{
		'lat': 33.58906307064756,
		'lon': -84.02981170895202,
		'name': 'Georgia',
		'iso': 'US',
		'NumberOfSessions': 113
	},
	{
		'lat': 36.63457548717721,
		'lon': -115.68475269057892,
		'name': 'Nevada',
		'iso': 'US',
		'NumberOfSessions': 31
	},
	{
		'lat': 40.45794415897181,
		'lon': -76.86548535256634,
		'name': 'Pennsylvania',
		'iso': 'US',
		'NumberOfSessions': 103
	},
	{
		'lat': 41.45428487880888,
		'lon': -74.65335696667162,
		'name': 'New York',
		'iso': 'US',
		'NumberOfSessions': 178
	},
	{
		'lat': 31.092182972880664,
		'lon': -97.38331307152694,
		'name': 'Texas',
		'iso': 'US',
		'NumberOfSessions': 313
	},
	{
		'lat': 21.28078626185493,
		'lon': -157.74037129153078,
		'name': 'Hawaii',
		'iso': 'US',
		'NumberOfSessions': 22
	},
	{
		'lat': 34.03016274142187,
		'lon': -81.04418604119054,
		'name': 'South Carolina',
		'iso': 'US',
		'NumberOfSessions': 45
	},
	{
		'lat': 28.059091179125236,
		'lon': -81.74250313422677,
		'name': 'Florida',
		'iso': 'US',
		'NumberOfSessions': 180
	},
	{
		'lat': 39.08998715743363,
		'lon': -80.5584353936775,
		'name': 'West Virginia',
		'iso': 'US',
		'NumberOfSessions': 22
	},
	{
		'lat': 35.47642706469868,
		'lon': -80.10200860967481,
		'name': 'North Carolina',
		'iso': 'US',
		'NumberOfSessions': 121
	},
	{
		'lat': 42.265472016596284,
		'lon': -71.32986983496819,
		'name': 'Massachusetts',
		'iso': 'US',
		'NumberOfSessions': 52
	},
	{
		'lat': 45.191156834566826,
		'lon': -93.29920764177064,
		'name': 'Minnesota',
		'iso': 'US',
		'NumberOfSessions': 74
	},
	{
		'lat': 43.741474059573946,
		'lon': -114.9158629654028,
		'name': 'Idaho',
		'iso': 'US',
		'NumberOfSessions': 21
	},
	{
		'lat': 42.8212934035811,
		'lon': -83.94527852994979,
		'name': 'Michigan',
		'iso': 'US',
		'NumberOfSessions': 89
	},
	{
		'lat': 40.23564734727866,
		'lon': -86.30030436979712,
		'name': 'Indiana',
		'iso': 'US',
		'NumberOfSessions': 62
	},
	{
		'lat': 39.41899297825325,
		'lon': -75.5593244748527,
		'name': 'Delaware',
		'iso': 'US',
		'NumberOfSessions': 13
	},
	{
		'lat': 30.75267234204937,
		'lon': -91.32893106090559,
		'name': 'Louisiana',
		'iso': 'US',
		'NumberOfSessions': 38
	},
	{
		'lat': 41.87173947982639,
		'lon': -93.49251387143138,
		'name': 'Iowa',
		'iso': 'US',
		'NumberOfSessions': 26
	},
	{
		'lat': 38.54333502989639,
		'lon': -92.1358208984468,
		'name': 'Missouri',
		'iso': 'US',
		'NumberOfSessions': 60
	},
	{
		'lat': 33.31490703273761,
		'lon': -86.8345227846169,
		'name': 'Alabama',
		'iso': 'US',
		'NumberOfSessions': 35
	},
	{
		'lat': 39.11892428809393,
		'lon': -76.86182657864312,
		'name': 'Maryland',
		'iso': 'US',
		'NumberOfSessions': 60
	},
	{
		'lat': 41.70540927304492,
		'lon': -71.42500470438927,
		'name': 'Rhode Island',
		'iso': 'US',
		'NumberOfSessions': 11
	},
	{
		'lat': 32.9227756295488,
		'lon': -90.02785962279671,
		'name': 'Mississippi',
		'iso': 'US',
		'NumberOfSessions': 17
	},
	{
		'lat': 41.53788553787247,
		'lon': -72.9658076730403,
		'name': 'Connecticut',
		'iso': 'US',
		'NumberOfSessions': 32
	},
	{
		'lat': 47.15014047163487,
		'lon': -112.37083482953744,
		'name': 'Montana',
		'iso': 'US',
		'NumberOfSessions': 6
	},
	{
		'lat': 47.93975154653642,
		'lon': -100.02002880433321,
		'name': 'North Dakota',
		'iso': 'US',
		'NumberOfSessions': 10
	},
	{
		'lat': 44.20405294063113,
		'lon': -69.69784604017653,
		'name': 'Maine',
		'iso': 'US',
		'NumberOfSessions': 26
	},
	{
		'lat': 61.57055363696547,
		'lon': -148.2104663087425,
		'name': 'Alaska',
		'iso': 'US',
		'NumberOfSessions': 13
	},
	{
		'lat': 44.80687244596602,
		'lon': -122.58673719965722,
		'name': 'Oregon',
		'iso': 'US',
		'NumberOfSessions': 71
	},
	{
		'lat': 44.11443250325091,
		'lon': -100.16935303008489,
		'name': 'South Dakota',
		'iso': 'US',
		'NumberOfSessions': 4
	},
	{
		'lat': 38.40633173352286,
		'lon': -96.2579226748877,
		'name': 'Kansas',
		'iso': 'US',
		'NumberOfSessions': 29
	},
	{
		'lat': 34.95710564599433,
		'lon': -106.54578322062994,
		'name': 'New Mexico',
		'iso': 'US',
		'NumberOfSessions': 33
	},
	{
		'lat': 35.56242808530408,
		'lon': -93.0462635196315,
		'name': 'Arkansas',
		'iso': 'US',
		'NumberOfSessions': 16
	},
	{
		'lat': 38.91278048858877,
		'lon': -77.01134304271174,
		'name': 'District of Columbia',
		'iso': 'US',
		'NumberOfSessions': 14
	},
	{
		'lat': 51.536084560296324,
		'lon': 0.09599659012842565,
		'name': 'Barking and Dagenham',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 51.6019,
		'lon': -3.4311000000000003,
		'name': 'Rhondda Cynon Taf',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.5095,
		'lon': -0.09549999999999999,
		'name': 'City of London',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 54.74560588891476,
		'lon': -3.1569749595422234,
		'name': 'Cumbria',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 55.917802551713905,
		'lon': -3.4986118487235074,
		'name': 'West Lothian',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.52285023205452,
		'lon': -0.2978259446505703,
		'name': 'Ealing',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': -7.2484,
		'lon': 112.7419,
		'name': 'East Java',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.49139999999999,
		'lon': -2.110899999999999,
		'name': 'Tameside',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.80423395926176,
		'lon': -1.7792984895035517,
		'name': 'Bradford',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.4570544508162,
		'lon': -0.2971599576008239,
		'name': 'Richmond upon Thames',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.87421093290679,
		'lon': -2.509240538497388,
		'name': 'Lancashire',
		'iso': 'GB',
		'NumberOfSessions': 10
	},
	{
		'lat': 52.59343224677579,
		'lon': -2.1362880154973176,
		'name': 'Wolverhampton',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 45.06806076654842,
		'lon': -62.981235803039844,
		'name': 'Nova Scotia',
		'iso': 'CA',
		'NumberOfSessions': 18
	},
	{
		'lat': 35.8359,
		'lon': 51.00250000000001,
		'name': 'Alborz Province',
		'iso': 'IR',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.32566669551157,
		'lon': 5.015065676184929,
		'name': 'Cote d\'Or',
		'iso': 'FR',
		'NumberOfSessions': 6
	},
	{
		'lat': 35.682399999999994,
		'lon': 51.415800000000004,
		'name': 'Tehran',
		'iso': 'IR',
		'NumberOfSessions': 3
	},
	{
		'lat': 57.18614012602576,
		'lon': -2.445099099222921,
		'name': 'Aberdeenshire',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 45.4518,
		'lon': 28.0522,
		'name': 'Galați County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.25,
		'lon': 19.8362,
		'name': 'South Bačka',
		'iso': 'RS',
		'NumberOfSessions': 2
	},
	{
		'lat': 56.49995188260553,
		'lon': -3.0528333014997875,
		'name': 'Dundee City',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': -36.84921092333312,
		'lon': 174.7646903798213,
		'name': 'Auckland',
		'iso': 'NZ',
		'NumberOfSessions': 10
	},
	{
		'lat': 52.254265435979896,
		'lon': -1.5638297935639247,
		'name': 'Warwickshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.7894,
		'lon': -2.5755,
		'name': 'Blackburn with Darwen',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.89310022065252,
		'lon': 12.049797337212402,
		'name': 'Mecklenburg-Vorpommern',
		'iso': 'DE',
		'NumberOfSessions': 10
	},
	{
		'lat': 38.22029746570498,
		'lon': -0.613273627261908,
		'name': 'Alicante',
		'iso': 'ES',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.18999688944821,
		'lon': 6.670085079474405,
		'name': 'Groningen',
		'iso': 'NL',
		'NumberOfSessions': 7
	},
	{
		'lat': 51.47130142235486,
		'lon': 0.1563290805573814,
		'name': 'Bexley',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 52.893747143252035,
		'lon': -1.4309566813284138,
		'name': 'Derby',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 54.935199999999995,
		'lon': -1.5675000000000001,
		'name': 'Gateshead',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.5473,
		'lon': 18.6951,
		'name': 'County of Osijek-Baranja',
		'iso': 'HR',
		'NumberOfSessions': 2
	},
	{
		'lat': 40.7782,
		'lon': 30.4017,
		'name': 'Sakarya',
		'iso': 'TR',
		'NumberOfSessions': 3
	},
	{
		'lat': 44.6319,
		'lon': 22.656100000000002,
		'name': 'Mehedinți County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.7576119580986,
		'lon': 23.54189212310089,
		'name': 'Cluj County',
		'iso': 'RO',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.3710905596568,
		'lon': 5.050847705869144,
		'name': 'Haute-Marne',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 36.5312,
		'lon': 3.0698,
		'name': 'Blida',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 18.6177,
		'lon': -68.704,
		'name': 'La Altagracia Province',
		'iso': 'DO',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.82929999999999,
		'lon': 15.9793,
		'name': 'City of Zagreb',
		'iso': 'HR',
		'NumberOfSessions': 11
	},
	{
		'lat': 44.5489,
		'lon': 6.282,
		'name': 'Hautes-Alpes',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 61.77835699662688,
		'lon': 124.66995674757997,
		'name': 'Sakha',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 44.87680000000001,
		'lon': 40.2182,
		'name': 'Adygeya Republic',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.36228237963367,
		'lon': 0.6785071789178958,
		'name': 'Indre-et-Loire',
		'iso': 'FR',
		'NumberOfSessions': 9
	},
	{
		'lat': 13.4954,
		'lon': -88.8646,
		'name': 'La Paz Department',
		'iso': 'SV',
		'NumberOfSessions': 1
	},
	{
		'lat': 23.565251055094524,
		'lon': 88.39504708929597,
		'name': 'West Bengal',
		'iso': 'IN',
		'NumberOfSessions': 4
	},
	{
		'lat': 35.89529999999999,
		'lon': 14.4589,
		'name': 'Birkirkara',
		'iso': 'MT',
		'NumberOfSessions': 1
	},
	{
		'lat': -2.197800000000001,
		'lon': -79.8764,
		'name': 'Guayas',
		'iso': 'EC',
		'NumberOfSessions': 4
	},
	{
		'lat': 58.3891,
		'lon': 24.498300000000004,
		'name': 'Pärnu linn',
		'iso': 'EE',
		'NumberOfSessions': 1
	},
	{
		'lat': 14.402,
		'lon': 120.85539999999999,
		'name': 'Province of Cavite',
		'iso': 'PH',
		'NumberOfSessions': 2
	},
	{
		'lat': 18.7929,
		'lon': 99.0004,
		'name': 'Chiang Mai',
		'iso': 'TH',
		'NumberOfSessions': 4
	},
	{
		'lat': 51.71948587865926,
		'lon': -1.2012002714480747,
		'name': 'Oxfordshire',
		'iso': 'GB',
		'NumberOfSessions': 11
	},
	{
		'lat': 41.94109236651321,
		'lon': 2.668143891522656,
		'name': 'Girona',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 61.4888759185593,
		'lon': 23.767894997893194,
		'name': 'Pirkanmaa',
		'iso': 'FI',
		'NumberOfSessions': 4
	},
	{
		'lat': 37.108730897786735,
		'lon': -2.206895506506557,
		'name': 'Almeria',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.17700000000001,
		'lon': 28.628900000000005,
		'name': 'Constanța County',
		'iso': 'RO',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.6506,
		'lon': 16.291999999999994,
		'name': 'Lendava',
		'iso': 'SI',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.58190000000001,
		'lon': 9.2683,
		'name': 'Monza Brianza',
		'iso': 'IT',
		'NumberOfSessions': 6
	},
	{
		'lat': 42.4241,
		'lon': 18.7674,
		'name': 'Kotor',
		'iso': 'ME',
		'NumberOfSessions': 1
	},
	{
		'lat': 59.27,
		'lon': 10.4154,
		'name': 'Vestfold',
		'iso': 'NO',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.951900192272234,
		'lon': 26.63324495030704,
		'name': 'Neamț County',
		'iso': 'RO',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.1876,
		'lon': 71.4491,
		'name': 'Astana',
		'iso': 'KZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 36.025698845830014,
		'lon': 139.6193186107597,
		'name': 'Saitama',
		'iso': 'JP',
		'NumberOfSessions': 3
	},
	{
		'lat': -41.130399999999995,
		'lon': -71.2936,
		'name': 'Rio Negro',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': 31.004800000000003,
		'lon': 75.9463,
		'name': 'Punjab',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': -3.760964892095915,
		'lon': -39.83833304549214,
		'name': 'Ceará',
		'iso': 'BR',
		'NumberOfSessions': 3
	},
	{
		'lat': 14.638860002479776,
		'lon': -90.51418001829296,
		'name': 'Guatemala',
		'iso': 'GT',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.50509322783984,
		'lon': -1.1520626599646073,
		'name': 'Doncaster',
		'iso': 'GB',
		'NumberOfSessions': 6
	},
	{
		'lat': 48.42416887396572,
		'lon': 17.656845484540376,
		'name': 'Trnava Region',
		'iso': 'SK',
		'NumberOfSessions': 5
	},
	{
		'lat': 36.98711257295591,
		'lon': -3.5786159620067606,
		'name': 'Granada',
		'iso': 'ES',
		'NumberOfSessions': 8
	},
	{
		'lat': 51.460216936647974,
		'lon': -0.022626326893107094,
		'name': 'Lewisham',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 37.0799,
		'lon': 22.4282,
		'name': 'Laconia',
		'iso': 'GR',
		'NumberOfSessions': 2
	},
	{
		'lat': 29.13492727980633,
		'lon': -106.09296086532133,
		'name': 'Chihuahua',
		'iso': 'MX',
		'NumberOfSessions': 4
	},
	{
		'lat': 48.08119929492222,
		'lon': 21.06329309246785,
		'name': 'Borsod-Abaúj-Zemplén',
		'iso': 'HU',
		'NumberOfSessions': 5
	},
	{
		'lat': 52.10721208003226,
		'lon': 5.215346510589088,
		'name': 'Utrecht',
		'iso': 'NL',
		'NumberOfSessions': 7
	},
	{
		'lat': 25.3507,
		'lon': 68.3534,
		'name': 'Sindh',
		'iso': 'PK',
		'NumberOfSessions': 1
	},
	{
		'lat': 26.120599999999992,
		'lon': 91.6523,
		'name': 'Assam',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.26149999999999,
		'lon': 69.21770000000001,
		'name': 'Tashkent',
		'iso': 'UZ',
		'NumberOfSessions': 11
	},
	{
		'lat': 22.9917,
		'lon': 120.2148,
		'name': 'Tainan',
		'iso': 'TW',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.48451978428704,
		'lon': 20.792946916202105,
		'name': 'Bekes County',
		'iso': 'HU',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.777649125837726,
		'lon': 24.062757109802842,
		'name': 'Lviv',
		'iso': 'UA',
		'NumberOfSessions': 7
	},
	{
		'lat': 41.90976348435309,
		'lon': -0.32038671869774404,
		'name': 'Huesca',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 41.80469999999999,
		'lon': -6.7577,
		'name': 'Bragança',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.659616756146946,
		'lon': 7.137772845735413,
		'name': 'Alpes-Maritimes',
		'iso': 'FR',
		'NumberOfSessions': 12
	},
	{
		'lat': 50.17886040434953,
		'lon': 21.83804906871416,
		'name': 'Subcarpathia',
		'iso': 'PL',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.16415091205801,
		'lon': 6.221519531100525,
		'name': 'Doubs',
		'iso': 'FR',
		'NumberOfSessions': 12
	},
	{
		'lat': 69.35418336005748,
		'lon': 18.597417212449983,
		'name': 'Troms',
		'iso': 'NO',
		'NumberOfSessions': 4
	},
	{
		'lat': 17.6774,
		'lon': 83.2036,
		'name': 'Andhra Pradesh',
		'iso': 'IN',
		'NumberOfSessions': 3
	},
	{
		'lat': 60.67699155342306,
		'lon': 10.577893020812358,
		'name': 'Innlandet',
		'iso': 'NO',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.7525,
		'lon': 22.903299999999998,
		'name': 'Hunedoara County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.8448,
		'lon': 27.8386,
		'name': 'Aydın',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.7042510050242,
		'lon': 25.332522335358924,
		'name': 'Vilnius City Municipality',
		'iso': 'LT',
		'NumberOfSessions': 13
	},
	{
		'lat': 54.83330000000001,
		'lon': 25.33330000000001,
		'name': 'Vilnius',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 57.58319217384437,
		'lon': -4.216708666524265,
		'name': 'Highland',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 43.07504006968595,
		'lon': 141.36282075980102,
		'name': 'Hokkaido',
		'iso': 'JP',
		'NumberOfSessions': 5
	},
	{
		'lat': 57.768600000000006,
		'lon': 40.9352,
		'name': 'Kostroma Oblast',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.05058832149603,
		'lon': 26.78554986886721,
		'name': 'Khmelnytskyi Oblast',
		'iso': 'UA',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.53156830341991,
		'lon': 18.95266507035508,
		'name': 'Pest County',
		'iso': 'HU',
		'NumberOfSessions': 6
	},
	{
		'lat': -17.38169999999999,
		'lon': -66.138,
		'name': 'Departamento de Cochabamba',
		'iso': 'BO',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.02780216439955,
		'lon': 17.889669877961158,
		'name': 'Veszprém megye',
		'iso': 'HU',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.9484,
		'lon': 142.7465,
		'name': 'Sakhalin Oblast',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.33760989355027,
		'lon': 39.35511058866201,
		'name': 'Luhansk',
		'iso': 'UA',
		'NumberOfSessions': 3
	},
	{
		'lat': -2.102006402476671,
		'lon': -48.472520044374825,
		'name': 'Pará',
		'iso': 'BR',
		'NumberOfSessions': 3
	},
	{
		'lat': 30.6987,
		'lon': -112.354,
		'name': 'Sonora',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.58349305106843,
		'lon': 34.561415204093244,
		'name': 'Poltava Oblast',
		'iso': 'UA',
		'NumberOfSessions': 11
	},
	{
		'lat': 49.30248229234283,
		'lon': 31.647736951265745,
		'name': 'Cherkasy Oblast',
		'iso': 'UA',
		'NumberOfSessions': 5
	},
	{
		'lat': 64.13458883481991,
		'lon': -21.88781187928148,
		'name': 'Reykjavíkurborg',
		'iso': 'IS',
		'NumberOfSessions': 6
	},
	{
		'lat': 54.70499999999999,
		'lon': 20.5105,
		'name': 'Kaliningrad Oblast',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 56.944309199805396,
		'lon': 9.846410793987804,
		'name': 'North Denmark',
		'iso': 'DK',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.39781226495002,
		'lon': 34.84171405595372,
		'name': 'Dnipropetrovsk Oblast',
		'iso': 'UA',
		'NumberOfSessions': 14
	},
	{
		'lat': 49.0719,
		'lon': 17.4638,
		'name': 'Uherské Hradiště District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 42.86959999999999,
		'lon': 74.5932,
		'name': 'Gorod Bishkek',
		'iso': 'KG',
		'NumberOfSessions': 6
	},
	{
		'lat': 47.1672,
		'lon': 27.6083,
		'name': 'Iași County',
		'iso': 'RO',
		'NumberOfSessions': 4
	},
	{
		'lat': 59.6146,
		'lon': 16.5528,
		'name': 'Västmanland County',
		'iso': 'SE',
		'NumberOfSessions': 3
	},
	{
		'lat': 15.095168242772958,
		'lon': 120.62423842222944,
		'name': 'Province of Pampanga',
		'iso': 'PH',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.786315302755256,
		'lon': 44.60467337899335,
		'name': 'Volgograd Oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 54.15673474315198,
		'lon': 37.667838729724686,
		'name': 'Tula Oblast',
		'iso': 'RU',
		'NumberOfSessions': 6
	},
	{
		'lat': 57.1615,
		'lon': 65.5346,
		'name': 'Tyumen Oblast',
		'iso': 'RU',
		'NumberOfSessions': 7
	},
	{
		'lat': 54.7609,
		'lon': 32.1254,
		'name': 'Smolensk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 60.67410000000001,
		'lon': 17.1483,
		'name': 'Gävleborg County',
		'iso': 'SE',
		'NumberOfSessions': 3
	},
	{
		'lat': 54.10020000000001,
		'lon': 22.9272,
		'name': 'Podlasie',
		'iso': 'PL',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.42393864801979,
		'lon': -3.4067791711839246,
		'name': 'Vale of Glamorgan',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.97060857829406,
		'lon': -0.5240762172156911,
		'name': 'Central Bedfordshire',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 50.97233722939013,
		'lon': 5.392977127758133,
		'name': 'Limburg Province',
		'iso': 'BE',
		'NumberOfSessions': 5
	},
	{
		'lat': -16.60073465938552,
		'lon': -49.22299308623486,
		'name': 'Goiás',
		'iso': 'BR',
		'NumberOfSessions': 3
	},
	{
		'lat': 24.367475057470976,
		'lon': 54.85871902149736,
		'name': 'Abu Dhabi',
		'iso': 'AE',
		'NumberOfSessions': 3
	},
	{
		'lat': 56.8651,
		'lon': 35.8938,
		'name': 'Tver Oblast',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 16.0685,
		'lon': 108.22150000000002,
		'name': 'Da Nang',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.1713,
		'lon': 47.2835,
		'name': 'Chuvash Republic',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.06731791637316,
		'lon': 41.98893724136131,
		'name': 'Stavropol Kray',
		'iso': 'RU',
		'NumberOfSessions': 7
	},
	{
		'lat': 48.774,
		'lon': 5.1689,
		'name': 'Meuse',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 9.927134531889598,
		'lon': -84.07913370108982,
		'name': 'Provincia de San José',
		'iso': 'CR',
		'NumberOfSessions': 9
	},
	{
		'lat': 14.535304264593199,
		'lon': 121.06294391133841,
		'name': 'Province of Rizal',
		'iso': 'PH',
		'NumberOfSessions': 2
	},
	{
		'lat': 63.4277,
		'lon': 10.4012,
		'name': 'Trøndelag',
		'iso': 'NO',
		'NumberOfSessions': 2
	},
	{
		'lat': 36.87794032743125,
		'lon': 30.71427202539329,
		'name': 'Antalya',
		'iso': 'TR',
		'NumberOfSessions': 5
	},
	{
		'lat': 55.7015,
		'lon': 65.7269,
		'name': 'Kurgan Oblast',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': -35.85020000000001,
		'lon': -71.6014,
		'name': 'Maule Region',
		'iso': 'CL',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.77199210762011,
		'lon': 3.1220198179679017,
		'name': 'Puy-de-Dôme',
		'iso': 'FR',
		'NumberOfSessions': 16
	},
	{
		'lat': 26.4336,
		'lon': 50.111599999999996,
		'name': 'Eastern Province',
		'iso': 'SA',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.8032,
		'lon': 15.0007,
		'name': 'Liberec District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.86912819811276,
		'lon': 24.71295407409857,
		'name': 'Ivano-Frankivsk Oblast',
		'iso': 'UA',
		'NumberOfSessions': 6
	},
	{
		'lat': 56.4911,
		'lon': 84.9949,
		'name': 'Tomsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.651636792039035,
		'lon': 14.997337892390414,
		'name': 'West Pomerania',
		'iso': 'PL',
		'NumberOfSessions': 3
	},
	{
		'lat': 33.7233,
		'lon': 73.0435,
		'name': 'Islamabad',
		'iso': 'PK',
		'NumberOfSessions': 1
	},
	{
		'lat': -17.783400000000007,
		'lon': -63.18219999999999,
		'name': 'Santa Cruz Department',
		'iso': 'BO',
		'NumberOfSessions': 3
	},
	{
		'lat': 48.3097,
		'lon': 18.0905,
		'name': 'Nitra Region',
		'iso': 'SK',
		'NumberOfSessions': 1
	},
	{
		'lat': 60.59959718088117,
		'lon': 22.095962423451834,
		'name': 'Southwest Finland',
		'iso': 'FI',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.41618432103627,
		'lon': -0.39125796918157396,
		'name': 'Deux-Sèvres',
		'iso': 'FR',
		'NumberOfSessions': 6
	},
	{
		'lat': -12.063699999999999,
		'lon': -75.21720000000002,
		'name': 'Junin',
		'iso': 'PE',
		'NumberOfSessions': 1
	},
	{
		'lat': -0.030999999999999986,
		'lon': 109.3257,
		'name': 'West Kalimantan',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.0287,
		'lon': -0.5015,
		'name': 'Bedford',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 14.083050222768081,
		'lon': -87.19370001138404,
		'name': 'Francisco Morazán Department',
		'iso': 'HN',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.289300000000004,
		'lon': -3.4674999999999994,
		'name': 'Denbighshire',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.222200014759075,
		'lon': 6.60644700247669,
		'name': 'Vosges',
		'iso': 'FR',
		'NumberOfSessions': 6
	},
	{
		'lat': 53.65840716854955,
		'lon': -1.40780294424418,
		'name': 'Wakefield',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.0545,
		'lon': 12.5686,
		'name': 'Province of Rimini',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.9318,
		'lon': 23.3289,
		'name': 'Šiauliai City Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': -4.616,
		'lon': 55.4461,
		'name': 'La Rivière Anglaise',
		'iso': 'SC',
		'NumberOfSessions': 1
	},
	{
		'lat': 20.27060000000001,
		'lon': 85.8334,
		'name': 'Odisha',
		'iso': 'IN',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.12991589607892,
		'lon': 20.88474307427781,
		'name': 'Prešov Region',
		'iso': 'SK',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.181,
		'lon': 12.637199999999996,
		'name': 'Sokolov District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.967423705482084,
		'lon': 34.08714386069663,
		'name': 'Crimea',
		'iso': 'UA',
		'NumberOfSessions': 6
	},
	{
		'lat': 34.0508,
		'lon': -5.0099,
		'name': 'Fes',
		'iso': 'MA',
		'NumberOfSessions': 1
	},
	{
		'lat': 15.0598,
		'lon': 102.2215,
		'name': 'Nakhon Ratchasima',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.79189999999999,
		'lon': 20.900399999999994,
		'name': 'Nomós Zakýnthou',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.6669,
		'lon': -8.627400000000002,
		'name': 'Limerick',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.04758641655336,
		'lon': 3.869325016661668,
		'name': 'East Flanders Province',
		'iso': 'BE',
		'NumberOfSessions': 5
	},
	{
		'lat': 41.55150000000001,
		'lon': -8.4204,
		'name': 'Braga',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.79009204193366,
		'lon': -3.929255753325652,
		'name': 'North Lanarkshire',
		'iso': 'GB',
		'NumberOfSessions': 7
	},
	{
		'lat': 51.4036,
		'lon': -0.7618,
		'name': 'Bracknell Forest',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.35926996680318,
		'lon': 139.18741330477633,
		'name': 'Gunma',
		'iso': 'JP',
		'NumberOfSessions': 3
	},
	{
		'lat': 63.86367758741244,
		'lon': 19.99945395105927,
		'name': 'Västerbotten County',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 54.373595196359176,
		'lon': 45.62102509367145,
		'name': 'Mordoviya Republic',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.29420180112356,
		'lon': 140.86818756575633,
		'name': 'Miyagi',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': -3.1031999999999993,
		'lon': -60.0288,
		'name': 'Amazonas',
		'iso': 'BR',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.6439,
		'lon': 22.9358,
		'name': 'Thessaloniki',
		'iso': 'GR',
		'NumberOfSessions': 10
	},
	{
		'lat': 46.0842,
		'lon': 0.2804,
		'name': 'Vienne',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.73001713954416,
		'lon': 4.84462895542609,
		'name': 'Saône-et-Loire',
		'iso': 'FR',
		'NumberOfSessions': 14
	},
	{
		'lat': 45.67605348427377,
		'lon': -0.009648837367199358,
		'name': 'Charente',
		'iso': 'FR',
		'NumberOfSessions': 7
	},
	{
		'lat': 49.223876476668934,
		'lon': 1.198309903420222,
		'name': 'Eure',
		'iso': 'FR',
		'NumberOfSessions': 8
	},
	{
		'lat': 52.570301645696055,
		'lon': -0.26237052837139535,
		'name': 'Peterborough',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.369633723275435,
		'lon': -2.1472704633996664,
		'name': 'Stockport',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.650901979495906,
		'lon': -0.0735313129665244,
		'name': 'Enfield',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 54.5612131798473,
		'lon': -1.305876080133093,
		'name': 'Stockton-on-Tees',
		'iso': 'GB',
		'NumberOfSessions': 8
	},
	{
		'lat': 51.60805798016588,
		'lon': -3.031560427643064,
		'name': 'Newport',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.5352,
		'lon': -2.4222000000000006,
		'name': 'South Gloucestershire',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.3006,
		'lon': 59.5904,
		'name': 'Razavi Khorasan',
		'iso': 'IR',
		'NumberOfSessions': 1
	},
	{
		'lat': 60.718,
		'lon': -135.0475,
		'name': 'Yukon',
		'iso': 'CA',
		'NumberOfSessions': 4
	},
	{
		'lat': 43.19739251416353,
		'lon': -71.42205195784344,
		'name': 'New Hampshire',
		'iso': 'US',
		'NumberOfSessions': 5
	},
	{
		'lat': 59.75259857111179,
		'lon': 10.015378755204713,
		'name': 'Buskerud',
		'iso': 'NO',
		'NumberOfSessions': 4
	},
	{
		'lat': 30.905799999999996,
		'lon': -2.3049999999999997,
		'name': 'Béchar',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 61.1329349184958,
		'lon': 74.60204054147871,
		'name': 'Khanty-Mansia',
		'iso': 'RU',
		'NumberOfSessions': 8
	},
	{
		'lat': 44.210433079710384,
		'lon': -73.04123171855912,
		'name': 'Vermont',
		'iso': 'US',
		'NumberOfSessions': 10
	},
	{
		'lat': 1.5407,
		'lon': 103.6976,
		'name': 'Johor',
		'iso': 'MY',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.007150199218986,
		'lon': -84.11955017251891,
		'name': 'Heredia Province',
		'iso': 'CR',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.8762,
		'lon': 14.5341,
		'name': 'Ħaż-Żabbar',
		'iso': 'MT',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.93227816623876,
		'lon': -4.491759640073232,
		'name': 'West Dunbartonshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 25.440999999999992,
		'lon': 55.532,
		'name': 'Ajman',
		'iso': 'AE',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.411699999999975,
		'lon': -6.449499999999996,
		'name': 'Meath',
		'iso': 'IE',
		'NumberOfSessions': 15
	},
	{
		'lat': 44.4847,
		'lon': 11.328,
		'name': 'Emilia-Romagna',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 32.7968,
		'lon': 130.7687,
		'name': 'Kumamoto',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 42.7021,
		'lon': 9.4547,
		'name': 'Upper Corsica',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.98415151179522,
		'lon': -1.4193924668481328,
		'name': 'South Tyneside',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.4477,
		'lon': -1.2045,
		'name': 'Rotherham',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 56.52185780739707,
		'lon': -3.674323138164285,
		'name': 'Perth and Kinross',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.82376726594378,
		'lon': -1.055626405389185,
		'name': 'Portsmouth',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.4098,
		'lon': -0.024899999999999995,
		'name': 'Bromley',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.80980000000001,
		'lon': -2.711,
		'name': 'Monmouthshire',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.11501622332382,
		'lon': -1.8852941697950774,
		'name': 'Northumberland',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.23581480683115,
		'lon': 18.679716637830374,
		'name': 'Fejér',
		'iso': 'HU',
		'NumberOfSessions': 6
	},
	{
		'lat': 16.4844,
		'lon': 121.14419999999998,
		'name': 'Province of Nueva Vizcaya',
		'iso': 'PH',
		'NumberOfSessions': 2
	},
	{
		'lat': 50.30166091590451,
		'lon': 28.7226079451331,
		'name': 'Zhytomyr',
		'iso': 'UA',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.670199999999994,
		'lon': -3.2068,
		'name': 'Caerphilly County Borough',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 41.3949,
		'lon': 2.1756000000000006,
		'name': 'Catalonia',
		'iso': 'ES',
		'NumberOfSessions': 3
	},
	{
		'lat': 35.1638,
		'lon': 33.36390000000001,
		'name': 'Nicosia',
		'iso': 'CY',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.20555030395246,
		'lon': 6.167247970793068,
		'name': 'Geneva',
		'iso': 'CH',
		'NumberOfSessions': 2
	},
	{
		'lat': 40.9897,
		'lon': 14.1677,
		'name': 'Province of Caserta',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 14.1538,
		'lon': -89.4839,
		'name': 'Santa Ana Department',
		'iso': 'SV',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.341,
		'lon': 43.1155,
		'name': 'Nineveh',
		'iso': 'IQ',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.0203,
		'lon': 135.7602,
		'name': 'Kyoto',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.268900000000016,
		'lon': -9.053300000000004,
		'name': 'County Galway',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 13.866700000000002,
		'lon': 100.1917,
		'name': 'Nakhon Pathom',
		'iso': 'TH',
		'NumberOfSessions': 2
	},
	{
		'lat': 44.4348,
		'lon': 24.356000000000005,
		'name': 'Olt',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.0016,
		'lon': -6.4311,
		'name': 'Louth',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.87465587988956,
		'lon': 5.790106965436788,
		'name': 'Limburg',
		'iso': 'NL',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.54280000000001,
		'lon': 10.3298,
		'name': 'Province of Livorno',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.799,
		'lon': 10.3234,
		'name': 'Province of Parma',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 33.690867196089634,
		'lon': 130.55916866572306,
		'name': 'Fukuoka',
		'iso': 'JP',
		'NumberOfSessions': 3
	},
	{
		'lat': 26.3297,
		'lon': 127.8047,
		'name': 'Okinawa',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.56162631600512,
		'lon': -1.2234810830105023,
		'name': 'Middlesbrough',
		'iso': 'GB',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.539150051093756,
		'lon': -2.1154011780728923,
		'name': 'Oldham',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.41816044073616,
		'lon': -2.6136865368357367,
		'name': 'Warrington',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.5457,
		'lon': 128.499,
		'name': 'Gyeongsangnam-do',
		'iso': 'KR',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.04418200814303,
		'lon': 7.437537604742304,
		'name': 'Bern',
		'iso': 'CH',
		'NumberOfSessions': 4
	},
	{
		'lat': 10.511100000000004,
		'lon': -61.4237,
		'name': 'Chaguanas',
		'iso': 'TT',
		'NumberOfSessions': 1
	},
	{
		'lat': -1.2840999999999996,
		'lon': 36.8155,
		'name': 'Nairobi County',
		'iso': 'KE',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.04940000000001,
		'lon': 27.3014,
		'name': 'Tekirdağ',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': -2.2074000000000003,
		'lon': 113.9164,
		'name': 'Central Kalimantan',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': 42.4411,
		'lon': 19.2632,
		'name': 'Podgorica',
		'iso': 'ME',
		'NumberOfSessions': 1
	},
	{
		'lat': 32.9001,
		'lon': 13.187399999999998,
		'name': 'Tripoli',
		'iso': 'LY',
		'NumberOfSessions': 1
	},
	{
		'lat': 30.7339,
		'lon': 76.7889,
		'name': 'Chandigarh',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.5998,
		'lon': -66.9373,
		'name': 'Vargas',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.181300000000014,
		'lon': 13.737100000000003,
		'name': 'Občina Tolmin',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.5641,
		'lon': 45.4326,
		'name': 'Sulaymaniyah',
		'iso': 'IQ',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.449885445105046,
		'lon': -6.289141876625279,
		'name': 'Armagh City Banbridge and Craigavon',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 48.13353303184823,
		'lon': 37.606607477739544,
		'name': 'Donetsk',
		'iso': 'UA',
		'NumberOfSessions': 3
	},
	{
		'lat': 38.175197240541614,
		'lon': 15.391330049826214,
		'name': 'Province of Messina',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.58149999999999,
		'lon': -2.177799999999999,
		'name': 'Rochdale',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': -36.80447765887964,
		'lon': -73.06324150426316,
		'name': 'Región del Biobío',
		'iso': 'CL',
		'NumberOfSessions': 4
	},
	{
		'lat': 44.0177,
		'lon': 20.9222,
		'name': 'Šumadija',
		'iso': 'RS',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.8994,
		'lon': -4.774099999999999,
		'name': 'Cordoba',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.5702,
		'lon': -3.262,
		'name': 'Guadalajara',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': -33.0228,
		'lon': -71.5519,
		'name': 'Región de Valparaíso',
		'iso': 'CL',
		'NumberOfSessions': 2
	},
	{
		'lat': 54.87805859515194,
		'lon': -1.8362870645855467,
		'name': 'Durham',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 39.9917,
		'lon': -0.0554,
		'name': 'Castellon',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.37462534958479,
		'lon': -5.915764825369655,
		'name': 'Seville',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 35.3272,
		'lon': 25.1371,
		'name': 'Heraklion Regional Unit',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.0042,
		'lon': 28.8574,
		'name': 'Chișinău Municipality',
		'iso': 'MD',
		'NumberOfSessions': 4
	},
	{
		'lat': 53.062043126200834,
		'lon': 33.88200648262672,
		'name': 'Bryansk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 40.09190000000001,
		'lon': 41.35520000000002,
		'name': 'Erzurum',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.22555110320149,
		'lon': -6.946208732136703,
		'name': 'Huelva',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 0.3809999999999999,
		'lon': 9.448699999999997,
		'name': 'Estuaire',
		'iso': 'GA',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.24095407930286,
		'lon': 15.406923015980361,
		'name': 'Styria',
		'iso': 'AT',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.2213,
		'lon': 24.7955,
		'name': 'Mureș County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.771003193862846,
		'lon': 11.222063643109916,
		'name': 'Province of Florence',
		'iso': 'IT',
		'NumberOfSessions': 5
	},
	{
		'lat': 56.18152720705442,
		'lon': 39.87506070296298,
		'name': 'Vladimir Oblast',
		'iso': 'RU',
		'NumberOfSessions': 5
	},
	{
		'lat': 53.86778855005754,
		'lon': -0.8246594092780237,
		'name': 'York',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 43.0848,
		'lon': 25.660600000000002,
		'name': 'Veliko Tarnovo',
		'iso': 'BG',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.8801,
		'lon': 17.491,
		'name': 'Opole Voivodeship',
		'iso': 'PL',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.977694837312086,
		'lon': 53.17655807083427,
		'name': 'Udmurtiya Republic',
		'iso': 'RU',
		'NumberOfSessions': 10
	},
	{
		'lat': 44.3915,
		'lon': 8.945,
		'name': 'Province of Genoa',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 56.687200000000004,
		'lon': 23.799600000000005,
		'name': 'Jelgava Municipality',
		'iso': 'LV',
		'NumberOfSessions': 3
	},
	{
		'lat': 39.55010965841472,
		'lon': -9.050432580498965,
		'name': 'Leiria',
		'iso': 'PT',
		'NumberOfSessions': 2
	},
	{
		'lat': 52.1268,
		'lon': 26.0898,
		'name': 'Brest',
		'iso': 'BY',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.7214,
		'lon': 94.4553,
		'name': 'Republic of Tyva',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.76783630023016,
		'lon': 19.71348953546904,
		'name': 'Bács-Kiskun',
		'iso': 'HU',
		'NumberOfSessions': 3
	},
	{
		'lat': 46.0653,
		'lon': 23.5917,
		'name': 'Alba County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.953799999999996,
		'lon': -72.7893,
		'name': 'La Guajira Department',
		'iso': 'CO',
		'NumberOfSessions': 1
	},
	{
		'lat': 5.947899999999998,
		'lon': 116.08269999999999,
		'name': 'Sabah',
		'iso': 'MY',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.8822,
		'lon': 26.526800000000005,
		'name': 'Daugavpils',
		'iso': 'LV',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.04723631748114,
		'lon': 3.4174669567445224,
		'name': 'Yonne',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.14081044121986,
		'lon': 5.730554545352637,
		'name': 'Friesland',
		'iso': 'NL',
		'NumberOfSessions': 3
	},
	{
		'lat': 52.0388,
		'lon': 113.5595,
		'name': 'Transbaikal Territory',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 22.1384,
		'lon': -100.9364,
		'name': 'San Luis Potosí',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 31.767400000000002,
		'lon': 35.2186,
		'name': 'Jerusalem',
		'iso': 'IL',
		'NumberOfSessions': 4
	},
	{
		'lat': 54.320499999999996,
		'lon': 48.4468,
		'name': 'Ulyanovsk Oblast',
		'iso': 'RU',
		'NumberOfSessions': 4
	},
	{
		'lat': 20.729879895745274,
		'lon': -86.98840959636814,
		'name': 'Quintana Roo',
		'iso': 'MX',
		'NumberOfSessions': 4
	},
	{
		'lat': 38.2385,
		'lon': 38.469500000000004,
		'name': 'Malatya',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.0479,
		'lon': 21.918900000000004,
		'name': 'Bihor County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 32.6113,
		'lon': 35.288499999999985,
		'name': 'Northern District',
		'iso': 'IL',
		'NumberOfSessions': 1
	},
	{
		'lat': 18.84350089332113,
		'lon': -99.21460916704788,
		'name': 'Morelos',
		'iso': 'MX',
		'NumberOfSessions': 2
	},
	{
		'lat': 8.9948,
		'lon': -79.523,
		'name': 'Provincia de Panamá',
		'iso': 'PA',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.836859533860284,
		'lon': 20.109758053044743,
		'name': 'Heves County',
		'iso': 'HU',
		'NumberOfSessions': 2
	},
	{
		'lat': 42.34365036080271,
		'lon': -106.3116217752205,
		'name': 'Wyoming',
		'iso': 'US',
		'NumberOfSessions': 8
	},
	{
		'lat': 53.195,
		'lon': 44.9945,
		'name': 'Penza Oblast',
		'iso': 'RU',
		'NumberOfSessions': 3
	},
	{
		'lat': -34.1703,
		'lon': -70.74060000000001,
		'name': 'O\'Higgins Region',
		'iso': 'CL',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.62287735670393,
		'lon': 32.45202660298435,
		'name': 'Kirovohrad Oblast',
		'iso': 'UA',
		'NumberOfSessions': 5
	},
	{
		'lat': 48.8839019706498,
		'lon': 17.13763595469038,
		'name': 'Hodonín District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.21670000000001,
		'lon': 15.8833,
		'name': 'Třebíč District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.05140066379946,
		'lon': 8.317949930531025,
		'name': 'Lucerne',
		'iso': 'CH',
		'NumberOfSessions': 2
	},
	{
		'lat': 23.5748,
		'lon': 119.6098,
		'name': 'Penghu County',
		'iso': 'TW',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.1436,
		'lon': 14.101,
		'name': 'Kladno District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.65177729995517,
		'lon': -9.113029828044978,
		'name': 'Setúbal',
		'iso': 'PT',
		'NumberOfSessions': 3
	},
	{
		'lat': 42.6042,
		'lon': -5.585800000000001,
		'name': 'Leon',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.8903477863675,
		'lon': -1.1254080429049138,
		'name': 'Murcia',
		'iso': 'ES',
		'NumberOfSessions': 6
	},
	{
		'lat': 42.33659999999999,
		'lon': -7.8691,
		'name': 'Ourense',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.6547,
		'lon': -4.7344,
		'name': 'Castille and León',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 42.267788297656146,
		'lon': -8.696057343915893,
		'name': 'Pontevedra',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 46.1479,
		'lon': 12.6476,
		'name': 'Province of Pordenone',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 19.8116,
		'lon': -90.5432,
		'name': 'Campeche',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 33.2337,
		'lon': 131.5928,
		'name': 'Oita',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.395199999999996,
		'lon': 139.7436,
		'name': 'Tochigi',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.82280000000001,
		'lon': 107.6176,
		'name': 'Buryatiya Republic',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': -33.97930259903411,
		'lon': 18.45572933488897,
		'name': 'Western Cape',
		'iso': 'ZA',
		'NumberOfSessions': 2
	},
	{
		'lat': -43.80718936549694,
		'lon': 172.1542975565121,
		'name': 'Canterbury',
		'iso': 'NZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 53.80939999999999,
		'lon': -3.0006999999999997,
		'name': 'Blackpool',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.1322,
		'lon': 9.5115,
		'name': 'Vaduz',
		'iso': 'LI',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.618,
		'lon': 21.172299999999996,
		'name': 'Branicevo',
		'iso': 'RS',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.809305904531726,
		'lon': 18.25728743913378,
		'name': 'Okres Ostrava-město',
		'iso': 'CZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.50619485439487,
		'lon': 6.034332391098076,
		'name': 'Savoy',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.7972,
		'lon': 20.1329,
		'name': 'North Banat',
		'iso': 'RS',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.7259,
		'lon': 75.3224,
		'name': 'Pavlodar Region',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.718,
		'lon': 13.229900000000002,
		'name': 'Okres Plzeň-sever',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.57341099348997,
		'lon': 11.720084958735423,
		'name': 'Province of Vicenza',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.0674,
		'lon': 13.236400000000001,
		'name': 'Udine',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 61.7788,
		'lon': 34.3606,
		'name': 'Karelia',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': -45.5646,
		'lon': -72.0778,
		'name': 'Aysén',
		'iso': 'CL',
		'NumberOfSessions': 1
	},
	{
		'lat': 22.935600000000008,
		'lon': -109.943,
		'name': 'Baja California Sur',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 62.2311,
		'lon': 25.674099999999996,
		'name': 'Central Finland',
		'iso': 'FI',
		'NumberOfSessions': 2
	},
	{
		'lat': 54.3065,
		'lon': 25.3905,
		'name': 'Šalčininkai District Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.757440381837874,
		'lon': 15.889736638171497,
		'name': 'Zagreb County',
		'iso': 'HR',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.1622,
		'lon': 16.830699999999997,
		'name': 'Koprivnica-Križevci County',
		'iso': 'HR',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.738600000000005,
		'lon': 19.1477,
		'name': 'Banská Bystrica Region',
		'iso': 'SK',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.99174071637782,
		'lon': 24.765940015872605,
		'name': 'Arges',
		'iso': 'RO',
		'NumberOfSessions': 2
	},
	{
		'lat': 18.461500000000008,
		'lon': -69.8965,
		'name': 'Nacional',
		'iso': 'DO',
		'NumberOfSessions': 3
	},
	{
		'lat': 39.7302,
		'lon': 30.534500000000005,
		'name': 'Eskişehir',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.6922,
		'lon': 135.1816,
		'name': 'Hyōgo',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 32.809272059422206,
		'lon': 35.045119182960455,
		'name': 'Haifa',
		'iso': 'IL',
		'NumberOfSessions': 5
	},
	{
		'lat': -7.1717,
		'lon': -35.4079,
		'name': 'Paraíba',
		'iso': 'BR',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.8294,
		'lon': 13.9402,
		'name': 'Kronoberg County',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 40.7485,
		'lon': 14.642,
		'name': 'Province of Salerno',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.0911,
		'lon': 18.232600000000005,
		'name': 'Baranya',
		'iso': 'HU',
		'NumberOfSessions': 2
	},
	{
		'lat': 63.8413,
		'lon': 23.1317,
		'name': 'Central Ostrobothnia',
		'iso': 'FI',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.56149558362614,
		'lon': 16.613319532081544,
		'name': 'Burgenland',
		'iso': 'AT',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.24442757807998,
		'lon': -1.7847293197035965,
		'name': 'Wiltshire',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 60.98235317789958,
		'lon': 25.675605485226583,
		'name': 'Paijat-Hame Region',
		'iso': 'FI',
		'NumberOfSessions': 2
	},
	{
		'lat': 11.0071,
		'lon': -74.8092,
		'name': 'Atlántico',
		'iso': 'CO',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.2969,
		'lon': 21.785500000000003,
		'name': 'Kozani',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.6115,
		'lon': -0.24959999999999993,
		'name': 'Barnet',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.625339236910364,
		'lon': -0.5543509582517913,
		'name': 'North Lincolnshire',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.4087,
		'lon': 11.9001,
		'name': 'Padua',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 27.479,
		'lon': -99.6724,
		'name': 'Tamaulipas',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 35.1466,
		'lon': 132.5932,
		'name': 'Shimane',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 3.5847000000000007,
		'lon': 98.6629,
		'name': 'North Sumatra',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.941300000000004,
		'lon': 106.8221,
		'name': 'Đồng Nai Province',
		'iso': 'VN',
		'NumberOfSessions': 2
	},
	{
		'lat': 10.0359,
		'lon': 105.7808,
		'name': 'Can Tho',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.503299999999996,
		'lon': -0.6893999999999998,
		'name': 'Windsor and Maidenhead',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 17.2501,
		'lon': -88.7676,
		'name': 'Cayo District',
		'iso': 'BZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.94166258999701,
		'lon': 5.121700035423352,
		'name': 'Vaucluse',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 24.0242,
		'lon': 90.27,
		'name': 'Gazipur',
		'iso': 'BD',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.8232,
		'lon': 10.1701,
		'name': 'Tunis Governorate',
		'iso': 'TN',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.9483,
		'lon': 35.3543,
		'name': 'Adana',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.9912,
		'lon': -1.5336,
		'name': 'North Tyneside',
		'iso': 'GB',
		'NumberOfSessions': 3
	},
	{
		'lat': 51.477,
		'lon': -0.19590000000000002,
		'name': 'Hammersmith and Fulham',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.4103,
		'lon': 2.9203,
		'name': 'Nièvre',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.9502815448959,
		'lon': -2.1584571296063295,
		'name': 'Gloucestershire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.417,
		'lon': -1.3313,
		'name': 'West Berkshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 52.6726,
		'lon': -2.4488,
		'name': 'Telford and Wrekin',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.69640000527415,
		'lon': -1.0561002880054187,
		'name': 'Landes',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 33.503,
		'lon': 36.3032,
		'name': 'Damascus Governorate',
		'iso': 'SY',
		'NumberOfSessions': 1
	},
	{
		'lat': 65.6826,
		'lon': -18.079700000000006,
		'name': 'Akureyrarkaupstaður',
		'iso': 'IS',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.54175004769626,
		'lon': 0.7067506956622989,
		'name': 'Southend-on-Sea',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 41.900800000000004,
		'lon': 12.4874,
		'name': 'Lazio',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': -31.7279,
		'lon': -60.529300000000006,
		'name': 'Entre Rios',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.2394,
		'lon': 1.3342000000000003,
		'name': 'Chlef',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 19.797,
		'lon': 105.7674,
		'name': 'Thanh Hóa Province',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.2033043671237,
		'lon': 15.794496858884331,
		'name': 'Hradec Králové District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 30.0588,
		'lon': 31.226800000000004,
		'name': 'Cairo Governorate',
		'iso': 'EG',
		'NumberOfSessions': 2
	},
	{
		'lat': -9.7438,
		'lon': -36.5931,
		'name': 'Alagoas',
		'iso': 'BR',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.115570151760664,
		'lon': 4.2517927195724505,
		'name': 'Marne',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 38.6645,
		'lon': 27.3294,
		'name': 'Manisa',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.97007055088533,
		'lon': 14.333417889894266,
		'name': 'Okres Praha-západ',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.482250982728914,
		'lon': 17.12969918279903,
		'name': 'Prostějov District',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 49.6809,
		'lon': 18.3456,
		'name': 'Okres Frýdek-Místek',
		'iso': 'CZ',
		'NumberOfSessions': 2
	},
	{
		'lat': 45.6482,
		'lon': 13.7747,
		'name': 'Trieste',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.9674,
		'lon': 32.00370000000001,
		'name': 'Mykolaiv',
		'iso': 'UA',
		'NumberOfSessions': 3
	},
	{
		'lat': 52.81055470177736,
		'lon': -2.7659700167300074,
		'name': 'Shropshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 56.5085,
		'lon': 27.348100000000002,
		'name': 'Rēzekne',
		'iso': 'LV',
		'NumberOfSessions': 2
	},
	{
		'lat': -20.1608,
		'lon': 57.49940000000001,
		'name': 'Port Louis District',
		'iso': 'MU',
		'NumberOfSessions': 1
	},
	{
		'lat': 16.412399999999998,
		'lon': 120.5933,
		'name': 'Province of Benguet',
		'iso': 'PH',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.8409,
		'lon': 16.8399,
		'name': 'Zala County',
		'iso': 'HU',
		'NumberOfSessions': 1
	},
	{
		'lat': 18.2811,
		'lon': -70.3243,
		'name': 'Provincia de Peravia',
		'iso': 'DO',
		'NumberOfSessions': 1
	},
	{
		'lat': 18.492,
		'lon': -69.8701,
		'name': 'Santo Domingo Province',
		'iso': 'DO',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.78979999999999,
		'lon': 67.7162,
		'name': 'Ulytau Region',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.6992,
		'lon': 30.692100000000007,
		'name': 'Afyonkarahisar Province',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 30.327500000000004,
		'lon': 78.03250000000001,
		'name': 'Uttarakhand',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 58.1179,
		'lon': 28.4301,
		'name': 'Pskov Oblast',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.3883,
		'lon': 9.279199999999998,
		'name': 'Appenzell Ausserrhoden',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.933,
		'lon': 13.841999999999999,
		'name': 'Province of L\'Aquila',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 41.0472348128229,
		'lon': 16.795154637422232,
		'name': 'Bari',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 36.7261,
		'lon': -4.426000000000001,
		'name': 'Andalusia',
		'iso': 'ES',
		'NumberOfSessions': 5
	},
	{
		'lat': 6.9318,
		'lon': 79.8863,
		'name': 'Colombo District',
		'iso': 'LK',
		'NumberOfSessions': 1
	},
	{
		'lat': 17.9776,
		'lon': -92.9221,
		'name': 'Tabasco',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.50133466273594,
		'lon': 7.6624328212620805,
		'name': 'Province of Cuneo',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 38.7202,
		'lon': -7.978800000000001,
		'name': 'Évora',
		'iso': 'PT',
		'NumberOfSessions': 1
	},
	{
		'lat': 42.4701,
		'lon': -2.452399999999999,
		'name': 'La Rioja',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.4776,
		'lon': -6.3798,
		'name': 'Caceres',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 25.0565,
		'lon': -77.3524,
		'name': 'New Providence District',
		'iso': 'BS',
		'NumberOfSessions': 2
	},
	{
		'lat': 30.008000000000003,
		'lon': 31.2194,
		'name': 'Giza',
		'iso': 'EG',
		'NumberOfSessions': 1
	},
	{
		'lat': 13.5112,
		'lon': 2.117,
		'name': 'Niamey',
		'iso': 'NE',
		'NumberOfSessions': 1
	},
	{
		'lat': -4.256799999999998,
		'lon': 15.2872,
		'name': 'Brazzaville',
		'iso': 'CG',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.2797,
		'lon': 16.2437,
		'name': 'Varaždin County',
		'iso': 'HR',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.956700000000005,
		'lon': -5.693200000000001,
		'name': 'Salamanca',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.34837761767536,
		'lon': -3.9835934889447544,
		'name': 'Cantabria',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 48.2983,
		'lon': 4.0802,
		'name': 'Aube',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 52.4319,
		'lon': 30.973100000000002,
		'name': 'Homyel’ Voblasc’',
		'iso': 'BY',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.923,
		'lon': 21.798999999999996,
		'name': 'Grevena',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.5128,
		'lon': 21.015400000000007,
		'name': 'Liepāja',
		'iso': 'LV',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.4479647429393,
		'lon': 26.087691671135545,
		'name': 'Ilfov',
		'iso': 'RO',
		'NumberOfSessions': 3
	},
	{
		'lat': 52.4145,
		'lon': -1.7841000000000002,
		'name': 'Solihull',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.35127535014553,
		'lon': 2.3073913794730094,
		'name': 'Aveyron',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 42.14968001854335,
		'lon': -3.3756269307977127,
		'name': 'Burgos',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 33.9164,
		'lon': 2.5379,
		'name': 'Laghouat',
		'iso': 'DZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.83249802120788,
		'lon': -4.111241283807684,
		'name': 'Carmarthenshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 23.578,
		'lon': 58.402100000000004,
		'name': 'Muscat',
		'iso': 'OM',
		'NumberOfSessions': 2
	},
	{
		'lat': 22.3539,
		'lon': 114.1342,
		'name': 'Kwai Tsing',
		'iso': 'HK',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.5581,
		'lon': 8.894,
		'name': 'Thurgau',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.479300000000006,
		'lon': 118.06729999999999,
		'name': 'Fujian',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.184,
		'lon': 21.3225,
		'name': 'Arad County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.911,
		'lon': 116.39499999999998,
		'name': 'Beijing',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.0803,
		'lon': 2.4,
		'name': 'Cher',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.8151,
		'lon': 127.09440000000001,
		'name': 'Chungcheongnam-do',
		'iso': 'KR',
		'NumberOfSessions': 1
	},
	{
		'lat': -22.556200000000004,
		'lon': 17.0759,
		'name': 'Khomas Region',
		'iso': 'NA',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.4627,
		'lon': 135.0551,
		'name': 'Khabarovsk',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': -53.4993,
		'lon': -68.0451,
		'name': 'Tierra del Fuego',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.191,
		'lon': 30.1984,
		'name': 'Vitebsk',
		'iso': 'BY',
		'NumberOfSessions': 1
	},
	{
		'lat': 57.4977,
		'lon': 39.74580000000001,
		'name': 'Yaroslavl Oblast',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.3113,
		'lon': 7.940700000000001,
		'name': 'Aargau',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 15.116300000000003,
		'lon': 104.1456,
		'name': 'Si Sa Ket',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 59.56860000000001,
		'lon': 150.8133,
		'name': 'Magadan Oblast',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.866499999999995,
		'lon': -107.4773,
		'name': 'Sinaloa',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.699,
		'lon': 14.0314,
		'name': 'Okres Ústí nad Labem',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.014267942390525,
		'lon': 22.134690301509853,
		'name': 'Szabolcs-Szatmár-Bereg',
		'iso': 'HU',
		'NumberOfSessions': 3
	},
	{
		'lat': 43.6942,
		'lon': 10.838000000000003,
		'name': 'Province of Pisa',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 65.0013,
		'lon': 25.4515,
		'name': 'North Ostrobothnia',
		'iso': 'FI',
		'NumberOfSessions': 2
	},
	{
		'lat': 46.555400000000006,
		'lon': 15.6465,
		'name': 'Maribor City Municipality',
		'iso': 'SI',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.3133,
		'lon': -6.776599999999998,
		'name': 'Badajoz',
		'iso': 'ES',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.9314,
		'lon': 2.1461,
		'name': 'Tarn',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.2167,
		'lon': 28.3674,
		'name': 'Muğla',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 10.401299999999999,
		'lon': -75.5267,
		'name': 'Departamento de Bolívar',
		'iso': 'CO',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.6001,
		'lon': 25.251299999999997,
		'name': 'Aizkraukle Municipality',
		'iso': 'LV',
		'NumberOfSessions': 1
	},
	{
		'lat': 59.398300000000006,
		'lon': 27.268000000000008,
		'name': 'Kohtla-Järve linn',
		'iso': 'EE',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.9317,
		'lon': 15.5003,
		'name': 'Lubusz',
		'iso': 'PL',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.126000000000005,
		'lon': 18.4251,
		'name': 'Trenčín Region',
		'iso': 'SK',
		'NumberOfSessions': 1
	},
	{
		'lat': 59.3465,
		'lon': 26.356600000000007,
		'name': 'Rakvere linn',
		'iso': 'EE',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.58196345320627,
		'lon': -4.445328341543068,
		'name': 'East Ayrshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 22.614799999999995,
		'lon': 120.3139,
		'name': 'Kaohsiung',
		'iso': 'TW',
		'NumberOfSessions': 2
	},
	{
		'lat': 16.0147,
		'lon': 103.215,
		'name': 'Maha Sarakham',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.975466667137226,
		'lon': 14.467433337389966,
		'name': 'České Budějovice District',
		'iso': 'CZ',
		'NumberOfSessions': 3
	},
	{
		'lat': 38.9384,
		'lon': 121.62239999999998,
		'name': 'Liaoning',
		'iso': 'CN',
		'NumberOfSessions': 1
	},
	{
		'lat': 22.284900000000007,
		'lon': 114.164,
		'name': 'Central and Western District',
		'iso': 'HK',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.0788,
		'lon': 12.353199999999998,
		'name': 'Cheb District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': -22.467600000000008,
		'lon': -68.9335,
		'name': 'Antofagasta',
		'iso': 'CL',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.5864,
		'lon': 17.253699999999995,
		'name': 'Olomouc District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 20.1157,
		'lon': -98.7403,
		'name': 'Hidalgo',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.2828,
		'lon': 127.5299,
		'name': 'Amur Oblast',
		'iso': 'RU',
		'NumberOfSessions': 2
	},
	{
		'lat': 37.87380000000001,
		'lon': 127.72700000000002,
		'name': 'Gangwon-do',
		'iso': 'KR',
		'NumberOfSessions': 1
	},
	{
		'lat': -28.5,
		'lon': -59.050000000000004,
		'name': 'Corrientes',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': 42.4963,
		'lon': 27.464600000000004,
		'name': 'Burgas',
		'iso': 'BG',
		'NumberOfSessions': 2
	},
	{
		'lat': 47.4021,
		'lon': 7.9948,
		'name': 'Solothurn',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.6595,
		'lon': 133.9342,
		'name': 'Okayama',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.8735,
		'lon': 69.1491,
		'name': 'North Kazakhstan',
		'iso': 'KZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.23650000000001,
		'lon': 14.298900000000007,
		'name': 'Mělník District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': -32.9518,
		'lon': -60.68390000000001,
		'name': 'Santa Fe',
		'iso': 'AR',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.7191,
		'lon': 8.624600000000001,
		'name': 'Schaffhausen',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.6078,
		'lon': 12.998200000000002,
		'name': 'Skåne County',
		'iso': 'SE',
		'NumberOfSessions': 2
	},
	{
		'lat': 17.066500000000005,
		'lon': -96.7226,
		'name': 'Oaxaca',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 57.79630000000001,
		'lon': 14.1376,
		'name': 'Jönköping',
		'iso': 'SE',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.955,
		'lon': 85.9532,
		'name': 'Altai',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.034400000000005,
		'lon': 44.67340000000001,
		'name': 'North Ossetia–Alania',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 60.3963,
		'lon': 5.3214,
		'name': 'Vestland',
		'iso': 'NO',
		'NumberOfSessions': 1
	},
	{
		'lat': 24.004100000000008,
		'lon': -104.62,
		'name': 'Durango',
		'iso': 'MX',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.0028,
		'lon': 15.9628,
		'name': 'Pardubice District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.7557,
		'lon': 26.9724,
		'name': 'Samos',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.438,
		'lon': 4.7932,
		'name': 'Namur Province',
		'iso': 'BE',
		'NumberOfSessions': 1
	},
	{
		'lat': 51.8967,
		'lon': -8.468300000000003,
		'name': 'County Cork',
		'iso': 'IE',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.74560000000002,
		'lon': 16.5145,
		'name': 'Province of Catanzaro',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.0596,
		'lon': 11.2465,
		'name': 'Province of Trente',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 38.13020000000001,
		'lon': 13.329000000000004,
		'name': 'Province of Palermo',
		'iso': 'IT',
		'NumberOfSessions': 3
	},
	{
		'lat': 45.69675000436204,
		'lon': 8.631399544877015,
		'name': 'Province of Novara',
		'iso': 'IT',
		'NumberOfSessions': 2
	},
	{
		'lat': 12.007300000000003,
		'lon': 107.6903,
		'name': 'Đăk Nông Province',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.6161,
		'lon': 39.73760000000001,
		'name': 'Ryazan Oblast',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 40.461699999999986,
		'lon': 17.243599999999997,
		'name': 'Province of Taranto',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': -14.274799999999997,
		'lon': -170.6895,
		'name': 'Eastern District',
		'iso': 'AS',
		'NumberOfSessions': 1
	},
	{
		'lat': 41.2477,
		'lon': 36.2395,
		'name': 'Samsun',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': -0.9336000000000004,
		'lon': -78.62,
		'name': 'Cotopaxi',
		'iso': 'EC',
		'NumberOfSessions': 1
	},
	{
		'lat': 25.3412,
		'lon': 55.4224,
		'name': 'Sharjah',
		'iso': 'AE',
		'NumberOfSessions': 1
	},
	{
		'lat': 33.663,
		'lon': -7.066600000000001,
		'name': 'Benslimane Province',
		'iso': 'MA',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.299200000000006,
		'lon': 16.2493,
		'name': 'Province of Cosenza',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 45.2419,
		'lon': 8.7289,
		'name': 'Province of Pavia',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 42.5055,
		'lon': 1.5242999999999995,
		'name': 'Andorra la Vella',
		'iso': 'AD',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.18018328164016,
		'lon': 0.10088513162056309,
		'name': 'Hautes-Pyrénées',
		'iso': 'FR',
		'NumberOfSessions': 3
	},
	{
		'lat': 34.8853,
		'lon': 136.5796,
		'name': 'Mie',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.4317,
		'lon': 18.284800000000004,
		'name': 'Vsetín District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 56.6376,
		'lon': 47.905,
		'name': 'Mariy-El Republic',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 59.954606466889864,
		'lon': 11.005620803210162,
		'name': 'Akershus',
		'iso': 'NO',
		'NumberOfSessions': 2
	},
	{
		'lat': -10.1683,
		'lon': -48.3264,
		'name': 'Tocantins',
		'iso': 'BR',
		'NumberOfSessions': 2
	},
	{
		'lat': 55.71399999999999,
		'lon': 21.408099999999997,
		'name': 'Klaipėda District Municipality',
		'iso': 'LT',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.94643496827701,
		'lon': -3.4915882774375024,
		'name': 'Jaen',
		'iso': 'ES',
		'NumberOfSessions': 2
	},
	{
		'lat': 54.0274,
		'lon': -2.1474,
		'name': 'North Yorkshire',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 53.60549999999999,
		'lon': -3.0316,
		'name': 'Sefton',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 57.1453,
		'lon': -2.094500000000001,
		'name': 'Aberdeen City',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 22.8006,
		'lon': 86.1871,
		'name': 'Jharkhand',
		'iso': 'IN',
		'NumberOfSessions': 1
	},
	{
		'lat': 4.8499,
		'lon': 31.5812,
		'name': 'Central Equatoria',
		'iso': 'SS',
		'NumberOfSessions': 1
	},
	{
		'lat': -7.163,
		'lon': -78.5008,
		'name': 'Cajamarca Department',
		'iso': 'PE',
		'NumberOfSessions': 1
	},
	{
		'lat': -25.512500000000006,
		'lon': -54.6187,
		'name': 'Departamento del Alto Paraná',
		'iso': 'PY',
		'NumberOfSessions': 1
	},
	{
		'lat': 43.19100000000002,
		'lon': 13.658400000000004,
		'name': 'Province of Fermo',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': -5.811099999999998,
		'lon': -35.2235,
		'name': 'Rio Grande do Norte',
		'iso': 'BR',
		'NumberOfSessions': 1
	},
	{
		'lat': 46.12850000000001,
		'lon': 3.4142,
		'name': 'Allier',
		'iso': 'FR',
		'NumberOfSessions': 2
	},
	{
		'lat': 10.1369,
		'lon': -64.6864,
		'name': 'Anzoátegui',
		'iso': 'VE',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.94580000000001,
		'lon': 14.0411,
		'name': 'Beroun District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': -3.321899999999999,
		'lon': 114.5871,
		'name': 'South Kalimantan',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.92069999999999,
		'lon': 34.7959,
		'name': 'Sumy',
		'iso': 'UA',
		'NumberOfSessions': 2
	},
	{
		'lat': -5.429100000000002,
		'lon': 105.2615,
		'name': 'Lampung',
		'iso': 'ID',
		'NumberOfSessions': 1
	},
	{
		'lat': 48.71950000000001,
		'lon': 21.2635,
		'name': 'Košice Region',
		'iso': 'SK',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.33160000000001,
		'lon': 134.0452,
		'name': 'Kagawa',
		'iso': 'JP',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.58695301543678,
		'lon': -0.35145418059547795,
		'name': 'Harrow',
		'iso': 'GB',
		'NumberOfSessions': 4
	},
	{
		'lat': 50.4144,
		'lon': 16.1682,
		'name': 'Náchod District',
		'iso': 'CZ',
		'NumberOfSessions': 1
	},
	{
		'lat': 42.1978,
		'lon': 25.336699999999997,
		'name': 'Stara Zagora',
		'iso': 'BG',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.608900000000006,
		'lon': 33.53,
		'name': 'Sebastopol City',
		'iso': 'UA',
		'NumberOfSessions': 2
	},
	{
		'lat': 14.802500000000007,
		'lon': 100.6181,
		'name': 'Lopburi',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 8.9449,
		'lon': 125.54679999999999,
		'name': 'Province of Agusan del Norte',
		'iso': 'PH',
		'NumberOfSessions': 1
	},
	{
		'lat': 57.4344,
		'lon': 25.9,
		'name': 'Smiltene Municipality',
		'iso': 'LV',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.35019999999999,
		'lon': 140.4258,
		'name': 'Ibaraki',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 7.95,
		'lon': 98.3564,
		'name': 'Phuket',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.56539999999999,
		'lon': 7.5706,
		'name': 'Basel-City',
		'iso': 'CH',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.869,
		'lon': 20.624000000000006,
		'name': 'Świętokrzyskie',
		'iso': 'PL',
		'NumberOfSessions': 2
	},
	{
		'lat': 9.5612,
		'lon': -84.321,
		'name': 'Puntarenas Province',
		'iso': 'CR',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.4907,
		'lon': -1.4254,
		'name': 'Barnsley',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.4143,
		'lon': 12.213400000000002,
		'name': 'Province of Ravenna',
		'iso': 'IT',
		'NumberOfSessions': 1
	},
	{
		'lat': 57.0006,
		'lon': 40.972,
		'name': 'Ivanovo Oblast',
		'iso': 'RU',
		'NumberOfSessions': 1
	},
	{
		'lat': 29.134419273187326,
		'lon': 76.7174983088223,
		'name': 'Haryana',
		'iso': 'IN',
		'NumberOfSessions': 2
	},
	{
		'lat': 38.7483,
		'lon': 35.5301,
		'name': 'Kayseri',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': -0.23090000000000002,
		'lon': -78.5211,
		'name': 'Pichincha',
		'iso': 'EC',
		'NumberOfSessions': 2
	},
	{
		'lat': 43.4126,
		'lon': 24.6089,
		'name': 'Pleven',
		'iso': 'BG',
		'NumberOfSessions': 1
	},
	{
		'lat': 39.667200000000015,
		'lon': 20.859900000000003,
		'name': 'Ioannina',
		'iso': 'GR',
		'NumberOfSessions': 1
	},
	{
		'lat': -19.582799999999995,
		'lon': -65.7507,
		'name': 'Potosí Department',
		'iso': 'BO',
		'NumberOfSessions': 1
	},
	{
		'lat': 37.9275,
		'lon': 32.4206,
		'name': 'Konya',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 44.7476,
		'lon': 19.686600000000006,
		'name': 'Mačva',
		'iso': 'RS',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.554299999999984,
		'lon': 25.608099999999997,
		'name': 'Ternopil Oblast',
		'iso': 'UA',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.0594,
		'lon': -3.1921,
		'name': 'Wrexham',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.5972,
		'lon': -2.3013,
		'name': 'Borough of Bury',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 53.289,
		'lon': -3.7684000000000006,
		'name': 'Conwy',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 54.69920000000001,
		'lon': -1.2569000000000001,
		'name': 'Hartlepool',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.478,
		'lon': -3.5182000000000007,
		'name': 'Torbay',
		'iso': 'GB',
		'NumberOfSessions': 2
	},
	{
		'lat': 51.720600000000005,
		'lon': -3.0408,
		'name': 'Torfaen County Borough',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.95,
		'lon': -4.920499999999998,
		'name': 'Argyll and Bute',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 36.831900000000005,
		'lon': 37.9918,
		'name': 'Gaziantep',
		'iso': 'TR',
		'NumberOfSessions': 1
	},
	{
		'lat': 60.02269999999999,
		'lon': 13.668500000000003,
		'name': 'Värmland County',
		'iso': 'SE',
		'NumberOfSessions': 1
	},
	{
		'lat': 55.03490000000001,
		'lon': -7.2718,
		'name': 'Derry City and Strabane',
		'iso': 'GB',
		'NumberOfSessions': 1
	},
	{
		'lat': 58.7814,
		'lon': 5.6312,
		'name': 'Rogaland',
		'iso': 'NO',
		'NumberOfSessions': 1
	},
	{
		'lat': 49.6403,
		'lon': -1.6197999999999997,
		'name': 'Manche',
		'iso': 'FR',
		'NumberOfSessions': 1
	},
	{
		'lat': 13.919499999999996,
		'lon': 102.018,
		'name': 'Sa Kaeo',
		'iso': 'TH',
		'NumberOfSessions': 1
	},
	{
		'lat': 50.76140000000001,
		'lon': 25.331,
		'name': 'Volyn',
		'iso': 'UA',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.204,
		'lon': 23.0557,
		'name': 'Sălaj County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': -15.4183,
		'lon': 28.287000000000003,
		'name': 'Lusaka Province',
		'iso': 'ZM',
		'NumberOfSessions': 1
	},
	{
		'lat': 34.296800000000005,
		'lon': 135.3876,
		'name': 'Wakayama',
		'iso': 'JP',
		'NumberOfSessions': 1
	},
	{
		'lat': 5.548600000000002,
		'lon': -0.2012,
		'name': 'Greater Accra Region',
		'iso': 'GH',
		'NumberOfSessions': 1
	},
	{
		'lat': 47.1304,
		'lon': 24.5042,
		'name': 'Bistrița-Năsăud County',
		'iso': 'RO',
		'NumberOfSessions': 1
	},
	{
		'lat': 11.1701,
		'lon': 106.6687,
		'name': 'Bình Dương Province',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 12.253900000000003,
		'lon': 109.1832,
		'name': 'Khánh Hòa Province',
		'iso': 'VN',
		'NumberOfSessions': 1
	},
	{
		'lat': 21.2129,
		'lon': 81.4294,
		'name': 'Chhattisgarh',
		'iso': 'IN',
		'NumberOfSessions': 1
	}
];