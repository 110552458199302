import React, { useRef } from 'react';
import options from './options';
import { ControlledEditor } from '@monaco-editor/react';
import './CodeEditor.css';

export default function CodeEditor(props) {

	const valueGetter = useRef();
	function handleEditorDidMount(event) {
		valueGetter.current = event;
	}

	return (
		<ControlledEditor
			width="100%"
			height="300px"
			theme="dark"
			language={props.language}
			loading={
				<>
					<span className="mask">
						<span className="wheel"></span>
					</span>
				</>
			}
			value={props.value && props.value}
			onChange={props.changeValue}
			editorDidMount={handleEditorDidMount}
			options={options}
		/>
	);
}
