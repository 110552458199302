import React, { useState, useEffect } from 'react';

const Progress = ({ percentage, closeProgressBar }) => {
	const [uploadDone, setUploadDone] = useState(false);
	const [time, setTime] = useState('');
	useEffect(() => {
		if (percentage === 100) {
			setTimeout(() => { setUploadDone(true); closeProgressBar(); }, 2000);
			const date = new Date();
			setTime(`${date.getHours() < 9 ? '0' + date.getHours() : date.getHours()} : ${date.getMinutes() < 9 ? '0' + date.getMinutes() : date.getMinutes()} :  ${date.getSeconds()}`);
		}
	}, [percentage]);
	return (
		<div className="uploadProgress">
			<div className="uploadProgressPercent">
				{percentage}
				<span className="uploadProgressPercentUnit">&nbsp;%</span>
			</div>
			<div className="uploadProgressTitle">Downloading...</div>
			<div className="uploadProgressBar" role="progressbar">
				<div className="uploadProgressBarInner" style={{ width: `${percentage}%` }}></div>
			</div>
		</div>
	);
};


export default Progress;