import React, { Component } from 'react';
import styles from './VisitorEngagementProfiles.module.css';
import VisitorEngagementProfile from './VisitorEngagementProfileComponent';
import ProfileWeightEnum from './ProfileWeightEnum';

var newProfileNumber = 1;

export default class VisitorEngagementProfiles extends Component {
    constructor(props) {
        super(props);
        this.state = { };
        this.onProfileChange = this.onProfileChange.bind(this);
        this.onDeleteProfile = this.onDeleteProfile.bind(this);
        this.onDuplicateProfile = this.onDuplicateProfile.bind(this);
        this.onAddProfileClick = this.onAddProfileClick.bind(this);
    }

    componentDidMount() {
    }

    onProfileChange(profile) {
        if (!this.props.onChange) return;
        let existing = this.props.profiles.find(p => p.id == profile.id);
        if (!existing) return;
        let newProfiles = [...this.props.profiles];
        let index = newProfiles.indexOf(existing);
        newProfiles[index] = profile;
        this.props.onChange(this.props.level, newProfiles, {
            type: "change",
            profileId: profile.id
        });
    }

    onDeleteProfile(profile) {
        if (!this.props.onChange) return;
        let existing = this.props.profiles.find(p => p.id == profile.id);
        if (!existing) return;
        let newProfiles = [...this.props.profiles];
        let index = newProfiles.indexOf(existing);
        newProfiles.splice(index, 1);
        this.props.onChange(this.props.level, newProfiles, {
            type: "delete",
            profileId: profile.id
        });
    }

    onDuplicateProfile(profile) {
        if (!this.props.onChange) return;
        let newProfile = {...profile};
        let number = newProfileNumber++;
        newProfile.id = "__temp__" + number;
        newProfile.name = this.makeUniqueProfileName(profile.name + " Copy");
        let newProfiles = [...this.props.profiles];
        newProfiles.push(newProfile);
        this.props.onChange(this.props.level, newProfiles, {
            type: "create",
            profileId: newProfile.id
        });
    }

    onAddProfileClick() {
        if (!this.props.onChange) return;
        let newProfiles = [...this.props.profiles];
        let number = newProfileNumber++;
        let newProfile = {
            id: "__temp__" + number,
            name: this.makeUniqueProfileName("New profile"),
            level: this.props.level,
            signals: [],
            weight: ProfileWeightEnum.Medium
        };
        newProfiles.push(newProfile);
        this.props.onChange(this.props.level, newProfiles,  {
            type: "create",
            profileId: newProfile.id
        });
    }

    makeUniqueProfileName(name) {
        let currentName = name;
        let currentNumber = 1;
        while(true) {
            let existing = this.props.profiles.find(p => p.name === currentName);
            if (!existing)
                return currentName;
            currentName = `${name} (${currentNumber++})`;
        }
    }

    render() {
        return <div className={styles.content}>
            <h2 className={styles.title}>{this.props.profileLabel}</h2>
            <div className={styles.description}></div>
            <div className={styles.profilesList}>
                {this.props.profiles.map(profile => (<div key={profile.id} className={styles.profilesListItem}>
                    <VisitorEngagementProfile
                        key={profile.id}
                        profile={profile}
                        profilesStats={this.props.profilesStats.find(s => s.profileId == profile.id)}
                        totalDisplay={this.props.profiles.map(profile => 1).reduce((prev, next) => prev + next)}
                        signals={this.props.signals}
                        onChange={this.onProfileChange}
                        onDelete={this.onDeleteProfile}
                        onDuplicate={this.onDuplicateProfile}
                        canEdit={this.props.canEdit} />
                </div>))}
                {this.props.canEdit ?
                    <div className={styles.newProfileButton} onClick={this.onAddProfileClick}>
                        <i className="fa fa-plus"></i> Add a profile
                    </div>
                 : ''}
            </div>
        </div>
    }
}
