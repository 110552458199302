import React, { useState, useEffect, Fragment } from 'react';
import PannelIpAdresses from './PannelIpAdresses';
import {useTranslation} from 'react-i18next';
export default function IpAdressesContainer({ accountId, HasPermission, testModeIpAddressesArray, putIpAdresses, setGetNewList, currentIP }) {
	const [t, i18n] = useTranslation('common');
	const [showPanelIpAddress, setShowPanelIpAddress] = useState(false);
	const [newIpToCreate, setNewIpToCreate] = useState({
		IpAddress: '',
		Label: '',
		IsDisabled: false
	});
	const addNewIpAdresses = () => {
		let arrayOfIp = [...testModeIpAddressesArray, newIpToCreate];
		const finalObject = {
			TestModeIpAddresses: arrayOfIp,
			// key: accountId
		};
		putIpAdresses(accountId, finalObject,
			e => {
				setGetNewList();
				setShowPanelIpAddress(false);
				setNewIpToCreate({
					IpAddress: '',
					Label: '',
					IsDisabled: false
				});
			},
			e => {
				//.log("fail: ", e)
			}
		);
	};
	const editAdresseIp = (ip, index) => {
		let itemToPut = testModeIpAddressesArray.filter(x => x.index === index)[0];
		itemToPut.exist = true;
		setNewIpToCreate(itemToPut);
		setShowPanelIpAddress(true);
	};
	const putAdressesIp = (e) => {
		let newArrayOfIp = [...testModeIpAddressesArray.filter(x => x.index !== newIpToCreate.index), newIpToCreate];
		const finalObject = {
			TestModeIpAddresses: newArrayOfIp,
			// key: accountId
		};
		putIpAdresses(accountId, finalObject,
			e => {
				setGetNewList();
				setShowPanelIpAddress(false);
				setNewIpToCreate({
					IpAddress: '',
					Label: '',
					IsDisabled: false
				});
			},
			e => {
				//.log("fail: ", e)
			}
		);
	};
	const deleteTheAdresseIp = (e) => {
		let newArrayOfIp = testModeIpAddressesArray.filter(x => x.index !== newIpToCreate.index);
		const finalObject = {
			TestModeIpAddresses: newArrayOfIp,
			key: accountId
		};
		putIpAdresses(accountId, finalObject,
			e => {
				setGetNewList();
				setShowPanelIpAddress(false);
				setNewIpToCreate({
					IpAddress: '',
					Label: '',
					IsDisabled: false
				});
			},
			e => {
				//.log("fail: ", e)
			}
		);
	};
		
	return (
		<>
			{HasPermission && HasPermission.CAMPAIGNS_TEST_MODE ?
				<Fragment>
					<p className="page_txt">
						{t('settings.list')}  
						<br />
						{t('settings.youCanAdd')}  
					</p>
					{testModeIpAddressesArray && testModeIpAddressesArray.length === 0 &&
						<div className="ip_address_empty">
							<p className="msg">{t('settings.thisAccountDoesntHaveIp')}</p>
							<button onClick={() => setShowPanelIpAddress(true)}>	{t('settings.addIp')}  <i className="fas fa-plus"></i></button>
						</div>
					}
					{testModeIpAddressesArray && testModeIpAddressesArray.length > 0 &&
						<div className="ip_address_table">
							<div className="table-row table-header">
								<p>{t('settings.IPaddress')}</p>
								<p>{t('settings.Name')}</p>
								<button onClick={() => setShowPanelIpAddress(true)}>{t('settings.addIp')}  <i className="fas fa-plus"></i></button>
							</div>
							{testModeIpAddressesArray && testModeIpAddressesArray.map((ip, i) =>
								<div className="table-row table-body" key={i}>
									<p className="ip_address">{ip.IpAddress}</p>
									<p>{ip.Label}</p>
									<div className="table-action">
										<button onClick={(e) => editAdresseIp(ip, i)} className="btn-edit-ip"><i className="fas fa-edit"></i> {t('settings.Edit')} </button>
									</div>
								</div>
							)}
						</div>
					}
					<PannelIpAdresses
						showPanelIpAddress={showPanelIpAddress}
						closeThePannel={() => setShowPanelIpAddress(false)}
						newIpToCreate={newIpToCreate}
						setNewIpToCreate={(e) => setNewIpToCreate(e)}
						addNewIpAdresses={() => addNewIpAdresses()}
						putAdressesIp={() => putAdressesIp()}
						deleteTheAdresseIp={() => deleteTheAdresseIp()}
						existIp={newIpToCreate.exist}
						currentIP={currentIP}
					/>
				</Fragment>
				:
				<div className="empty_page">
					<div className="empty_page_title">
						{t('settings.YouAreNot')} 
					</div>
					<div className="empty_page_text">
						{t('settings.ipUsed')} <br />
						{t('settings.please')}
					</div>
				</div>
			}
		</>
	);
}
