import React, { Component } from 'react';
import { react2angular } from 'react2angular';
import styles from './FormCustomReportsContainer.module.css';
import TinyLoader from '../../../Components/TinyLoader';
import FormCustomServices from '../../../Services/FormCustomServices';
import FormCustomReportTableComponent from './FormCustomReportTableComponent';


export default class FormCustomReportsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slides: [],
            loading: false,
            includeToday: false,
            canIncludeToday: false,
            campaignId: '',
            accountId: '',
            startDate: '',
            endDate: ''
        };

        this.FormCustomServices = new FormCustomServices(this.props.$http);
        this.authenticatedUserAccount = this.props.$rootScope.User.Account.Key;

        props.onCampaignLoad(function (campaign, accountId, startDate, endDate) {
            this.setState(state => {
                return { canIncludeToday:false, campaignId:campaign.Key, accountId:accountId, startDate:moment(startDate, 'DD/MM/YYYY'), endDate:moment(endDate, 'DD/MM/YYYY')  };
            });
            if (this.isEndDateYesterday()){
                this.setState(state => {
                    return { canIncludeToday:true };
                });
            }
            this.getAnswers()
        }.bind(this));
    }

    isEndDateYesterday() { return this.state.endDate.format('YYYY-MM-DD') === moment().add(-1,'days').format('YYYY-MM-DD'); }

    getAnswers() {
        this.setState(state => {
            return { slides: [], loading:true };
        });
        var endDate =  moment(this.state.endDate);
        if (this.isEndDateYesterday() && this.state.includeToday){
             endDate = endDate.add(1,'days')
        }
        this.FormCustomServices.getAnswers(this.state.accountId, this.state.campaignId, this.state.startDate, endDate,
            function (data) {
                this.setState(state => {
                    return { slides: data, loading:false };
                });
            }.bind(this),
            function (error) {
            }
        );
    }

    handleIncludeTodayChange() {
        this.setState(state => {
            return { includeToday:!this.state.includeToday };
        }, () => this.getAnswers());

    }

    render() {
        return <div className={styles.tableContainer}>
            <h4>Collected Form answers {!this.state.loading && !this.state.slides.length ? <p className={styles.formNodata}><i className="fa fa-exclamation-triangle"></i> Aucune donnée pour la période choisie.</p> : ''}</h4>
            {
                this.state.canIncludeToday ? <div>Include today answsers <input type="checkbox" checked={this.state.includeToday}
                onChange={(e) => this.handleIncludeTodayChange()}/></div> : ''
            }
            {
            this.state.loading ? <TinyLoader/> : 
                this.state.slides.map(slide => {
                    return <FormCustomReportTableComponent answers={slide.answers} key={slide.slideId} slideId={slide.slideId} />
                })
            }
        </div>;
    }
}

angular
    .module('beyableSaasApp.Dashboard.ShowStats.Controllers')
    .component('formCustomReports', react2angular(FormCustomReportsContainer, ['onCampaignLoad'], ['$rootScope', '$scope', '$http']));