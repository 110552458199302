
import React, { useState, useEffect, useContext } from 'react';
import { Context } from './Context/Context';
import Select from 'react-select';
import basedOnList from './ParamatersList/ListBasedOnFilter';
import './ProductBasedOn.css';
export default function ProductBasedOn({ algorithm }) {

	const {
		valueSelectBasedOn,
		setValueSelectBasedOn
	} = useContext(Context);
	const [isSelected, setisSelected] = useState();

	const changeValue = (e) => {
		const value = e.target.value;
		// const value = e.key
		setValueSelectBasedOn(value);
		setisSelected(e.label);
	};


	return (
		<section className="form_section containerProductBasedOn">
			<label className="form_section_label cross_sell_label" htmlFor="selectOption"> Product reference, based on :</label>
			{/* // test to create a select css */}
			<select
				className="selectOptionProductBasedOn "
				id="selectOption"
				onChange={(e) => changeValue(e)}
				name="selectOption"
				value={valueSelectBasedOn ? valueSelectBasedOn : 'choose'}
				// defaultValue={valueSelectBasedOn ? valueSelectBasedOn : 'choose'}
				style={{ minHeight: '40px', border: '1px solid #ccc', color: 'grey', appearance: 'none' }}
			>
				<option value='choose' disabled hidden>Choose a product Reference</option>
				{basedOnList.map(x => <option key={x.key} value={x.key}>{x.label}</option>)}
			</select>
		</section>

	);
}
