import React, { useState, useEffect} from 'react';
import { CampaignsContext } from '../context';
import Btn from '../../../Components/Btn';
import Dropdown from '../../../Components/Dropdown';
import Switch from '../../../Components/Switch';
import SelectCustom from '../../../Components/SelectCustom';
import InputCustom from '../../../Components/InputCustom';
import SectionMessage from '../../../Components/SectionMessage';
import { intToLetterVariation } from '../util';
import {useTranslation} from 'react-i18next';
export default function ABSettings() {
	const [t, i18n] = useTranslation('common');
	const {
		isReady,
		isABTestingActive,
		setIsABTestingActive,
		variations,
		variationError,
		editorMode,
		isModuleEditorMode,
		handleVariationRangeChanged,
		handleTagChanged,
		handleAddVariation,
		handleDeleteVariation,
		handleTagOrRangeChoosed,
		handleChangeVariation,
		trackingMode,
		variationType
	} = CampaignsContext();

	const [abtestDropdownIsOpen, setAbtestDropdownIsOpen] = useState(false);
	const [original, setoriginal] = useState();
	const [shouldDisplayChangeDistributionMode, setShouldDisplayChangeDistributionMode] = useState(false);

	useEffect(() => {
		const isModule = isModuleEditorMode();
		setShouldDisplayChangeDistributionMode(!isModule);
	}, [editorMode]);
	useEffect(()=>{
		if(variations){
			setoriginal((variations).find(x => x.IsOriginalVariation));
		}
	},[variationType, variations]);

	if(!variations || !original){
		return <></>;
	}
	function handleABtestToggle() {
		if (!isABTestingActive && variations.filter(x => !x.IsOriginalVariation).length == 0) {
			handleAddVariation();
		}
		if (isABTestingActive) {
			const original = (variations || []).find(x => x.IsOriginalVariation);
			handleChangeVariation(original.UId);
			setAbtestDropdownIsOpen(false);
		}
		setIsABTestingActive(!isABTestingActive);
	}



	return (
		<Dropdown
			isOpen={abtestDropdownIsOpen}
			setIsOpen={(e) => setAbtestDropdownIsOpen(true)}
			onHide={(e) => setAbtestDropdownIsOpen(false)}
			button={
				<a className={isABTestingActive ? 'format_cmd active' : 'format_cmd'}>
					<i className={isABTestingActive ? 'fas fa-cog' : 'fas fa-plus'}></i>
					<span>A/B test</span>
				</a>
			}
		>
			<div className="format_ab">
				<section className="section xs no_bottom_pad">
					<div className="format_ab_header">
						<Switch
							disabled={isReady ? trackingMode === 1 : false}
							forcedChecked={isABTestingActive}
							onChange={(e) => handleABtestToggle()}
							position="right">
							<span className="format_ab_title">A/B test</span>
						</Switch>
						{isReady && trackingMode === 1 &&
							<div className='mt_10'>
								<SectionMessage
									verticalSize="s"
									noMargin={true}
									text={t('ABSettings.disabled') }
								/>
							</div>
						}
					</div>
				</section>
				<section className="section format_ab_body">
					{isABTestingActive ?
						<>
							{shouldDisplayChangeDistributionMode &&
								<div className='mb_20'>
									<div className="format_ab_sub_title">{t('ABSettings.howTo') }</div>
									<SelectCustom
										value={original.VariationType}
										optionsList={[{ value: 'Range', label: 'By proportion of traffic (%)' }, { value: 'PageTag', label: 'By tag' }]}
										onChangeFunction={(e) => handleTagOrRangeChoosed(e.target.value)}
									/>
								</div>
							}
							<div>
								<div className="format_ab_sub_title">Variations</div>
								<div className="format_ab_variation flex">
									<div className="flex_item_full">Original (A)</div>
									<div className="flex_item_fix mr_10">
										{variationType === 'PageTag'  &&
											<InputCustom
												readonly={true}
												value="None"
												type="text"
												className="w_s"
											/>}
											
										{variationType !== 'PageTag' && <InputCustom
											disabled={true}
											min={0}
											value={original.RangeSize || 0}
											unit="%"
											type="number"
											className="w_xxs"
										/>
										}
									</div>
									<div className="flex_item_fix mr_10">
										<a className="icon_btn s invisible">
											<i className="fas fa-trash-alt"></i>
										</a>
									</div>
								</div>
								{variations.filter(x => !x.IsOriginalVariation).map((item, i) =>
									<div className="format_ab_variation flex" key={item.UId}>
										<div className="flex_item_full">
											{'Variation ' + intToLetterVariation(i + 1)}
										</div>
										<div className="flex_item_fix mr_10">
											{variationType !== 'PageTag' &&
											<InputCustom
												isAnError={variationError === item.UId}
												onChange={(e) => handleVariationRangeChanged(e.target.value, item.UId)}
												value={item.RangeSize || 0}
												min={0}
												unit="%"
												type="number"
												className="w_xxs"
											/>}
											
											{variationType === 'PageTag'  &&
												<InputCustom
													isAnError={variationError === item.UId}
													onChange={(e) => handleTagChanged(e.target.value, item.UId)}
													value={item.NameOrTag}
													type="text"
													className="w_s"
													placeholder="Tag"
												/>
											}
										</div>
										<div className="flex_item_fix mr_10">
											<a className="icon_btn s" onClick={() => handleDeleteVariation(item.UId)}>
												<i className="fas fa-trash"></i>
											</a>
										</div>
									</div>
								)}
							</div>
						</>
						:
						<div className="empty_state">
							<img className="empty_state_img" src="/Assets/format_AB_empty.svg" />
							<div className="empty_state_title">{t('ABSettings.doubt') }</div>
							<div className="empty_state_text">{t('ABSettings.test') }</div>
						</div>
					}
				</section>
				{isABTestingActive &&
					<section className="section xs no_top_pad">
						<div className="format_ab_footer flex">
							<div className='flex_item_full'>
								<Btn
									disabled={variations.filter(x => !x.IsOriginalVariation).length > 4 || !isReady}
									onClick={() => handleAddVariation()}
									message={t('ABSettings.Add') }
									style="reverse"
									icon="fas fa-plus fa-sm"
									horizontalSize="xxs"
								/>
							</div>
							<div className='flex_item_fix ml_20'>
								<Btn
									message={t('actions.done') }
									onClick={() => setAbtestDropdownIsOpen(false)}
								/>
							</div>
						</div>
					</section>
				}
			</div>
		</Dropdown>
	);
}
