const EngagementLevelColors = 
{ 
    "X": "#006fff",
    "D": "#58BCC4",
    "C": "#4FD984",
    "I": "#DDBF44",
    "A": "#D76666",
    "E": "#317992",
    "R": "#215D7D"
};

export default EngagementLevelColors;
