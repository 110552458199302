import React, { useContext, useEffect, useState } from 'react';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import useEventBroker from '../../../../Hooks/useEventBroker';
import ZoneDeleteModal from './ZoneDeleteModal';

export default function ZoneVideoPlayer({ zone, canMakeAssociation, isZoneSelected, selectZoneCallback }) {
	const {
		operation,
		setOperation,
		zoningCustomizeListValues,
		setZoningCustomizeListValues,
		listBanners,
		setSelectedZone
	} = useContext(CreateBusinessOperationContext);

	const { publish, subscribe } = useEventBroker();

	// useEffect(() => {
	//     const location = operation.operation.locations.filter(x => x.zoneId === zone.zoneId)[0];
	//     if (location) {
	//         const locationWithBannerInformations = listBanners.find(elem => elem.bannerId === location.bannerId);
	//         if (locationWithBannerInformations.bannerType === 'VideoPlayer') {
	//         } 
	//     }
	// }, [zone, isZoneSelected, operation]);

	const requestRemoveBannerFromZone = () => {
		publish('evt/businessoperations/removeBannerFromZoneRequested', { zone: zone });
	};

	subscribe('evt/businessoperations/bannerSelected', (e) => {
		const selectedBanner = e.detail;
		if (isZoneSelected) {
			bindBanner(selectedBanner);
		}
	}, [isZoneSelected]);
	const selectZoneToHandleBannerSelect = (bannerId) => {
		const id = zone.zoneId;
		const extensions = zone.zoneExtensions.Coordinates;
		const bannerType = zone.bannerType;

		setSelectedZone({ id: id, extensions: extensions, bannerId: bannerId, bannerType: bannerType });
	};
	const bindBanner = (banner) => {
		//select the item in zoningCustomizeListValues with the same id than the zone selected
		var zoning = zoningCustomizeListValues.flatMap(x => x.zones || []).find(x => x.zoneId === zone.zoneId);
		if (!zoning)
			return;

		const previousLocation = operation.operation.locations.filter(x => x.zoneId === zone.zoneId)[0];

		// insert the good banner imagePath in the zone with the id selected
		zoning.imagePath = banner.imagePath;
		zoning.bannerId = banner.bannerId;
		let locationToAdd = {
			zoneId: `${zoning.zoneId}`,
			bannerId: `${banner.bannerId}`,
		};

		let newOperation = { ...operation };
		const otherLocations = newOperation.operation.locations.filter(elem => elem.zoneId !== zone.zoneId);
		newOperation.operation.locations = [...otherLocations, locationToAdd];
		setOperation(newOperation);
		setZoningCustomizeListValues([...zoningCustomizeListValues]);
		selectZoneToHandleBannerSelect(banner.bannerId);
		// Create function reqyestCreateOptionVideoPlayer
		// console.log('VideoPlayer detected');
	};


	const deleteBanner = () => {
		delete zone.imagePath;
		delete zone.bannerId;
		let newOperation = { ...operation };
		let newLocations = [...newOperation.operation.locations].filter(elem => elem.zoneId !== zone.zoneId);
		newOperation.operation.locations = newLocations;
		setZoningCustomizeListValues([...zoningCustomizeListValues]);
		setOperation(newOperation);
		setSelectedZone({ id: zone.zoneId, extensions: zone.zoneExtensions.Coordinates, bannerId: null, bannerType: zone.bannerType });
	};

	const zoneClick = (e) => {
		e.stopPropagation();
		if (!canMakeAssociation) return;
		if (zone.bannerId) {
			selectZoneCallback(e, zone.bannerId, true);
		} else {
			selectZoneCallback(e, null, true);
		}
	};


	return (
		<>
			<span onClick={(e) => { e.stopPropagation(); }}>
				<ZoneDeleteModal deleteCallback={deleteBanner} zone={zone}></ZoneDeleteModal>
			</span>

			<div
				className={isZoneSelected ? 'customizeZone selected' : 'customizeZone'}
				style={zone.style}
				onClick={(e) => zoneClick(e)}>
				{zone.imagePath &&
					<>
						<img src={zone.imagePath} className={'isZoneVideo'} />
						{canMakeAssociation &&
							<a className="customizeZoneRemove"
								title="Remove banner"
								onClick={(e) => { e.stopPropagation(); requestRemoveBannerFromZone(); }}
							><i className="fas fa-times"
							></i></a>
						}
						{/* {isZoneVideo &&
                            <a className="customizeZoneEdit"
                                title="Edit Video"
                                onClick={(e) => { e.stopPropagation(); }}
                            ><i className="fab fa-youtube">
                                </i></a>
                        } */}
					</>
				}

				{!zone.imagePath &&
					<div className="customizeZoneText">{zone.zoneExtensions.Coordinates.width}x{zone.zoneExtensions.Coordinates.height}</div>
				}
			</div>
		</>
	);
}   