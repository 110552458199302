import React, { useState, useEffect } from 'react';
import '../../../BusinessOperations/Planning/PlanningContainer.css';
import DatesHeader from './DatesHeader';
import Grid from './Grid';
import OperationsTimeline from './OperationsTimeline';
import useSort from '../../../../Hooks/useSort';
import { getCampaigns, getCampaignsByRangeDate } from '../../../../Services/Queries/OnSiteDashboardQueries';
import { onSiteContext } from '../context';
import PlanningUtil from './PlanningUtil';
import { OnGoing } from '../../../../Services/Queries/DefinitionFilters';
export default function CalendarContainerOnSite({
	dateRange,
	dateToCalculate,
}) {
	const {
		accountId,
		systemServices,
		needFetchList,
		setneedFetchList,
		client,
		filterStatusMode,
		filterTestMode,
		searchBarvalue,
		filterBylabels,
		hideFilRouge,
		setNbOfOnGoingCp,
	} = onSiteContext();
	const { set_operation_dates_for_display } = PlanningUtil();

	const onTestExecutionModeFilter = {
		'and': [
			{ executionMode: { eq: 'TEST' } },
			{ isStopped: { 'eq': false } },
		]
	};
	const notOnTestExecutionModeFilter = {
		executionMode: { neq: 'TEST' }
	};

	const [datesBeginFetch, setDateBeginFetch] = useState();
	const [datesEndFetch, setDateEndFetch] = useState();
	const [datesArray, setDateArray] = useState();
	const [operations, setOperations] = useState();
	const [reload, setReload] = useState(true);
	const [today, setToday] = useState(moment().startOf('day'));
	const { sortOpeByDate } = useSort();

	const tmpStartdate = moment(dateRange.startDate).subtract(28, 'days');

	const getStatusFilter = () => {
		let filter;
		if (filterStatusMode.length > 0) {
			if (filterTestMode) {
				filter = {
					or: [filterStatusMode[0], onTestExecutionModeFilter],
				};
			} else {
				filter = {
					and: [filterStatusMode[0], notOnTestExecutionModeFilter],
				};
			}
		} else {
			filter = filterTestMode
				? onTestExecutionModeFilter
				: notOnTestExecutionModeFilter;
		}
		return filter;
	};

	const executeQuery = (queryAndVariables) =>  {
		if (!filterStatusMode) return;
		setReload(true);
		client.query(queryAndVariables).then(response => {
			setReload(false);
			let dataWithStyle = response.data.account.onSiteCampaigns.items.map(op => set_operation_dates_for_display(op));
			setOperations(dataWithStyle.sort(sortOpeByDate));
			setReload(false);
			setDateBeginFetch(tmpStartdate);
			setDateEndFetch((moment(tmpStartdate).startOf('day').add(84, 'days')));
		}
		).catch(e => {
			setReload(false);
			console.log('err', e);
			systemServices.showError('An error occured');
		});
		getNumberOfOnGoingCp();		
	};

	function getDates() {
		if (!dateRange) return;
		let dateArray = [];
		const tmp_dateRange = { ...dateRange };
		let currentDate = new Date(moment(tmp_dateRange.startDate));
		let endDate = moment(currentDate).add(dateToCalculate - 1, 'days');
		while (currentDate <= endDate) {
			dateArray.push(moment(currentDate));
			currentDate.setDate(currentDate.getDate() + 1);
		}
		setDateArray(dateArray);
	}
	useEffect(() => {
		getDates();
	}, [dateRange, dateToCalculate]);

	useEffect(() => {
		// for fetch if datesArrays change
		if (!datesArray)
			return;
		if (!filterStatusMode || !filterBylabels) return;
		if (!operations) {
			executeQuery({
				query: getCampaignsByRangeDate,
				variables: {
					accountId: accountId,
					skip: 0,
					take: 50,
					order: { modificationDate: 'ASC' },
					where: {
						and: [
							{
								or: [
									{ endDate: { nlt: tmpStartdate.format('YYYY-MM-DD') } },
									{ endDate: { eq: null } }
								]
							},
							{ startDate: { ngt: moment(tmpStartdate).add(84, 'days').format('YYYY-MM-DD') } },
							filterBylabels,
							searchBarvalue,
							getStatusFilter()
						]
					}
				}
			});
		}
		else {
			const dateSelectedInPicker = moment(dateRange.startDate);
			const endOfTimeline = moment(datesArray.length - 1);
			if (dateSelectedInPicker <= datesBeginFetch || dateSelectedInPicker >= datesEndFetch || datesEndFetch <= endOfTimeline) {
				executeQuery({
					query: getCampaignsByRangeDate,
					variables: {
						accountId: accountId,
						skip: 0,
						take: 50,
						order: { modificationDate: 'ASC' },
						where: {
							and: [
								{
									or: [
										{ endDate: { nlt: tmpStartdate.format('YYYY-MM-DD') } },
										{ endDate: { eq: null } }
									]
								},
								{ startDate: { ngt: moment(tmpStartdate).add(84, 'days').format('YYYY-MM-DD') } },
								filterBylabels,
								searchBarvalue,
								getStatusFilter()
							]
						}
					}
				});
			}
		}
	}, [datesArray]);

	const getNumberOfOnGoingCp = () => {
		client.query({
			query: getCampaigns,
			variables: {
			  accountId: accountId,
			  skip: 0,
			  order: { modificationDate: 'ASC' },
			  where: {and :  [OnGoing, notOnTestExecutionModeFilter]},
			},
		  })
		  .then((response) => {
				setNbOfOnGoingCp(response.data.account.onSiteCampaigns.items.length);
		  })
		  .catch((e) => {
				console.log('err', e);
				systemServices.showError('An error occured');
		  });
	};


	  
	const reloadOperationsForChange = () => {
		if (!filterBylabels)
			return;
		executeQuery({
			query: getCampaignsByRangeDate,
			variables: {
				accountId: accountId,
				skip: 0,
				take: 50,
				order: { modificationDate: 'ASC' },
				where: {
					and: [
						{
							or: [
								{ endDate: { nlt: tmpStartdate.format('YYYY-MM-DD') } },
								{ endDate: { eq: null } }
							]
						},
						{ startDate: { ngt: moment(tmpStartdate).add(84, 'days').format('YYYY-MM-DD') } },
						filterBylabels,
						searchBarvalue,
						getStatusFilter()
					]
				}
			}
		});
	};
	
	useEffect(() => {
		setneedFetchList(true);
	}, [hideFilRouge, filterStatusMode, filterTestMode, filterBylabels, searchBarvalue]);


	useEffect(() => {
		if (needFetchList) {
			setneedFetchList(false);
		}
		if (!filterStatusMode || !filterBylabels) return;
		reloadOperationsForChange();
	}, [needFetchList]);
	return (
		<div className="splanning flex flex_v">
			{datesArray && (
				<>
					<div className="splanning_header flex_item_fix">
						<DatesHeader dates={datesArray} today={today} />
					</div>
					<div className="splanning_body flex_item_full flex flex_v">
						<Grid dates={datesArray} today={today} />
						<div className="splanning_body_scroll vscroll">
							<OperationsTimeline dates={datesArray} operations={operations} />
						</div>
					</div>
				</>
			)}
			{reload && (
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			)}
		</div>
	);
}
