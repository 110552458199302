import React, { useState, useEffect, useContext } from 'react';
import LabelFilters from '../../Labels/LabelFilters';
import EditLabels from '../../Labels/EditLabels';
import { BusinessOperationContext } from '../Context/ContextBusinessOperations';

export default function OperationLabelFilters({
	canEdit,
	onFilter,
	className
}) {

	const {
		accountId,
		labelService,
		selectedLabelIds,
		setSelectedLabelIds,
		accountLabels,
		setAccountLabels,
		systemServices,
		loadLabels
	} = useContext(BusinessOperationContext);

	const [modalLabelsAccountisOppen, setmodalLabelsAccountisOppen] = useState(false);

	useEffect(() => {
		if (selectedLabelIds) {
			onFilter(selectedLabelIds);
		}
	}, [selectedLabelIds]);

	const onLabelSelected = (label) => {
		setSelectedLabelIds(ids => ids && ids.includes(label.id) ? ids.filter(i => i !== label.id) : [...(ids || []), label.id]);
	};

	const closeTheModalOfLabels = () => {
		setmodalLabelsAccountisOppen(false);
		loadLabels();
	};

	return (
		<>
			<div className={className}>
				<LabelFilters
					selectableLabels={accountLabels || []}
					selectedLabelIds={selectedLabelIds || []}
					onLabelSelected={l => onLabelSelected(l)}
					canEdit={canEdit}
					onEditRequested={() => setmodalLabelsAccountisOppen(true)}
				/>
			</div>
			{modalLabelsAccountisOppen &&
				<EditLabels
					modalLabelsAccountisOppen={modalLabelsAccountisOppen}
					setmodalLabelsAccountisOppen={() => closeTheModalOfLabels()}
					labelService={labelService}
					systemServices={systemServices}
					accountId={accountId}
					objectType={'businessOperation'}
				/>
			}
		</>
	);
}