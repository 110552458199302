export const getDeviceIcon = (str) => {
	if (str == 'mobile') return 'mobile-alt';
	if (str == 'tablet') return 'tablet-alt';
	return 'desktop';
};

export const getFormattedNumber = (n) => {
	if (typeof n == 'undefined') return '';
	var parts = n.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return parts.join('.');
};

export const getStatusClass = (str) => {
	switch (str?.toLowerCase()) {
		case 'ongoing': return 'ongoing';
		case 'stopped': return 'stopped';
		case 'planned': return 'planned';
		case 'past': return 'past';
		case 'test': return 'testing';
	}
	return '';
};

export const getStatusDisplayName = (str) => {
	switch (str?.toLowerCase()) {
		case 'ongoing': return 'Ongoing';
		case 'stopped': return 'Stopped';
		case 'planned': return 'Scheduled';
		case 'past': return 'Past';
		case 'test': return 'Draft';
	}
	return str;
};

export const getLabelColorClass = (str, styles) => {
	if (!str) return '';
	const lower = str.toLowerCase();
	return styles['label_' + lower];
};

export const ratioToPercent = (ratio, nbDecimals) => (ratio * 100).toFixed(nbDecimals ?? 1);


const mapDevice = (apiDevice) => {
	switch (apiDevice) {
		case 'Desktop': return 'desktop';
		case 'Mobile': return 'mobile';
		case 'Tablet': return 'tablet';
	}
	return '';
};

const mapStatus = (campaign) => {
	if (new Date(campaign.startDate) > new Date()) {
		return 'planned';
	}
	if (campaign.endDate && new Date(campaign.endDate) < new Date()) {
		return 'past';
	}
	if (campaign.isStopped) {
		return 'stopped';
	}
	if (campaign.executionMode == 'Test') {
		return 'test';
	}
	return 'ongoing';
};

export const mapCampaign = (campaign) => {
	const mappedCampaign = { ...campaign, 
		status: mapStatus(campaign),
		devices: campaign.devices.map(mapDevice),
	};
	return mappedCampaign;
};



export const computeDurationInDaysFromToday = (dateInThePast) => {
	if (!dateInThePast) return '';
	var date = dateInThePast instanceof Date ? dateInThePast : new Date(dateInThePast);
	var differenceInTime = new Date().getTime() - date.getTime();

	var differenceInDays = differenceInTime / (1000 * 3600 * 24);
	return Math.floor(differenceInDays);
};

export const computeDurationInDays = (date1, date2) => {
	if (!date1) return '';
	
	date1 = date1 instanceof Date ? date1 : new Date(date1);
	date2 = date2 instanceof Date ? date2 : date2 ? new Date(date2) : new Date();
	var differenceInTime = date2.getTime() - date1.getTime();

	var differenceInDays = differenceInTime / (1000 * 3600 * 24);
	return Math.floor(differenceInDays);
};

export const getBestVariation = (variations) => {
	if( !variations ) return;

	let winnerVariation = variations.baseline;
	let winnerValue = variations?.baseline?.probabilityToBeBest || 0;

	for (let i = 0; i < variations.others.length; i++) {
		const variation = variations.others[i];
		const value = variation?.recommendation?.comparisonData?.probabilityToBeBest || 0;
		if (value > winnerValue) {
			winnerVariation = variation;
			winnerValue = value;
		}
	}

	return winnerVariation;
};

export const getBestVariationWithoutOriginal = (variations) => {
	if( !variations ) return;

	let winnerVariation = undefined;
	let winnerValue = 0;

	for (let i = 0; i < variations.others.length; i++) {
		const variation = variations.others[i];
		const value = variation?.recommendation?.comparisonData?.probabilityToBeBest || 0;
		if (typeof winnerVariation === 'undefined' || value > winnerValue) {
			winnerVariation = variation;
			winnerValue = value;
		}
	}

	return winnerVariation;
};

export const getCampaignConfidence = (variations) => {
	if (!variations) return;
	let confidence = '';
	const good = 'Good';
	const length = variations.others.length;

	for (let i = 0; i < length; i++) {
		const variation = variations.others[i];
		const variationConfidence = variation?.recommendation?.confidence;
		if (variationConfidence == good) return good;
		confidence = variationConfidence;
	}
	return confidence;
};

export const getCampaignConfidenceIsBest = (confidence) => {
	return confidence === 'Best';
};

export const getCampaignIsKeepTesting = (variations) => {
	if (!variations) return;
	const length = variations.others.length;
	for (let i = 0; i < length; i++) {
		const variation = variations.others[i];
		if (variation.recommendation.baselineComparison == 'KeepTesting') {
			return true;
		}
	}
	return false;
};

export const getDashboardBaselineComparison= (variations) => {

	if(!variations){
		return;
	}

	const mergeDataVariation = [...variations.others];

	const uniqueItem = mergeDataVariation.reduce((acc, curr) => {	
		if(curr.hasOwnProperty('recommendation')){
			acc = [...acc,curr.recommendation.comparisonData.baselineComparison === 'KeepTesting' ? true: false];
		}

		return acc;
	}, []);

	return uniqueItem;
		
};
export const getVariationUrlDebugs= (variations) => {

	if(!variations){
		return;
	}

	const mergeDataVariation = [{...variations.baseline},...variations.others];

	const uniqueItem = mergeDataVariation.reduce((acc, curr) => {	
		acc = [...acc,curr.debugUrl];
		return acc;
	}, []);

	return uniqueItem;
		
};


export const listDevices = [
	'Desktop',
	'Tablet',
	'Mobile',
];

export const getObjectiveType = (obj) => {
	if (obj.indexOf('numberofobjectives') === 0) return 'conversions';
	if (obj.indexOf('amountofobjectives') === 0) return 'amount';
	if (obj.indexOf('numberofclicks') === 0) return 'clicks';
	return 'interactions';
};
