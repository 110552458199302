import React,{useEffect, useState} from 'react';
import {useProductFeedEditionContext} from '../../context/ProductFeedEditionContext';
import FieldContainerSimple from './FieldContainerSimple';
import editionStyles from '../ProductFeedEdition.module.css';
import ModalSelectedTags from '../../Feed/ModalSelectedTags';
import Confirm from '../../../../Components/Confirm';
import Btn from '../../../../Components/Btn';
import EmptyState from '../../../../Components/EmptyState';
import Accordion from './Accordion';
import IconBtn from '../../../../Components/IconBtn';

export default function SelectedTags() {
	const {productFeedObject,HandleSubmitNewTag,setupAccountServices,accountId,tenant} = useProductFeedEditionContext();
	const [selectedTags, setselectedTags] = useState([]);
	const [selectedTagModalIsOpen, setSelectedTagModalIsOpen] = useState(false);
	const [tagSelected, setTagSelected] = useState('');
	const [showDelConfirm, setshowDelConfirm] = useState(false);
	const [isExistingObj, setisExistingObj] = useState(false);
	const [deleteTag, setDeletedTag] = useState();
	useEffect(()=>{
		if(productFeedObject && productFeedObject.hasOwnProperty('selectedTags')){
			if(Array.isArray(productFeedObject.selectedTags)){
				setselectedTags([...productFeedObject.selectedTags]);
			}
		}
	},[productFeedObject]);
	function handleChangeTag(e){
		const valueOfField = e;
		setTagSelected(valueOfField);
	}
	function submitNewTag(){
		if(isExistingObj){
			const selectedTagsObj = [...selectedTags];
			const oldObject = selectedTags.find(el => el ===isExistingObj );
			selectedTagsObj.splice(oldObject,1,tagSelected);
			HandleSubmitNewTag(selectedTagsObj);
			setTagSelected('');
			setSelectedTagModalIsOpen(false);
		}
		else{
			const newArrayOfNameSpaces = [...selectedTags,tagSelected];
			HandleSubmitNewTag(newArrayOfNameSpaces);
			setTagSelected('');
			setSelectedTagModalIsOpen(false);
		}
		
	}
	function deletenameTagConfirm(){
		const newObj = selectedTags.filter(el => el !== deleteTag);
		setshowDelConfirm(false);
		setisExistingObj(false);
		setDeletedTag();
		HandleSubmitNewTag(newObj);
	}
	function deleteTagFn(tag){
		setDeletedTag(tag);
		setshowDelConfirm(true);
	}
	function handleCreateEmptyTAg(){
		setSelectedTagModalIsOpen(true);
		setisExistingObj(false);
		setTagSelected('');
	}
	function handleSelectTag(tag){
		setSelectedTagModalIsOpen(true);
		setTagSelected(tag);
		setisExistingObj(true);
	}
	function close(){
		setshowDelConfirm(false);
		setisExistingObj(false);
		setTagSelected('');
	}
	return (
		<>
			<Accordion
				label={'Tags'}
				value={''}
				placeholder="Not set"
				fieldIndex={1}
				isEmpty={selectedTags.length == 0}
				showDoneButton={selectedTags.length > 0}
				showDoneBorder={true}
				headerTitle={<>Select <strong>Tags</strong></>}
			>
				{selectedTags.length === 0 &&
					<EmptyState
						text="No tag yet"
						verticalSize='s'
						primaryAction={
							<Btn
								onClick={handleCreateEmptyTAg}
								message="Add tag"
								icon="fas fa-plus fa-sm"
								style="reverse"
								horizontalSize="xs"
							/>
						}
					/>
				}
				{selectedTags.length !== 0 &&
					<>
						<div className={editionStyles.table}>
							<div className={editionStyles.table_head_row}>
								<div className={editionStyles.table_col}>Tag name</div>
								<div className={editionStyles.table_col}></div>
							</div>
							{selectedTags.map((tag, i) => 
								<div key={i} className={editionStyles.table_body_row}>
									<div className={editionStyles.table_col}>{tag}</div>
									<div className={editionStyles.table_col}>
										<IconBtn
											tooltip="Edit tag"
											icon="fas fa-pen"
											onClick={() => handleSelectTag(tag)}
										/>
										<IconBtn
											tooltip="Delete tag"
											icon="fas fa-trash"
											onClick={() => deleteTagFn(tag)}
										/>
									</div>
								</div>
							)}
						</div>
						<div className='mt_10'>
							<Btn
								onClick={handleCreateEmptyTAg}
								message="Add tag"
								icon="fas fa-plus fa-sm"
								style="reverse"
								size="s"
								horizontalSize="xs"
							/>
						</div>
					</>
				}
			</Accordion>

			<ModalSelectedTags
				selectedTagModalIsOpen={selectedTagModalIsOpen}
				setSelectedTagModalIsOpen={setSelectedTagModalIsOpen}
				tagSelected={tagSelected}
				isExistingObj={isExistingObj}
				handleChangeTag={handleChangeTag}
				submitNewTag={submitNewTag}
				setupAccountServices={setupAccountServices}
				accountId={accountId}
				tenant={tenant}
			/>
			
			<Confirm
				title="Do you really want to delete this selected tag ?"
				text="This action is irreversible"
				confirmText="Delete"
				confirmColor="alert"
				cancelText="Cancel"
				isOpen={showDelConfirm}
				confirmCallback={()=>deletenameTagConfirm()}
				onClose={close}
			/>
		</>
	);
}
