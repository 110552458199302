
const listConnexionType = [
    {
        value: 'Ftp',
        label: 'FTP',
        chooseFeed: true,
        accessVal: {
            url: "",
            login: "",
            passWord: ""
        }
    },
    {
        value: 'OnSiteData',
        label: 'BEYABLE',
        chooseFeed: false,
        // accessVal: {}
    },
    {
        value: 'Web',
        label: 'HTTP',
        chooseFeed: true,
        accessVal: {
            url: "",
        }
    },
    {
        value: 'Prestashop',
        label: 'PRESTASHOP',
        chooseFeed: false,
        accessVal: {
            url: "",
            apiKey: "",
            languageId: null
        }
    },

]
export default listConnexionType;