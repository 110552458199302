const controlTypes = [
    {
      id: 1,
      name: 'text',
      icon: 'i-cursor',
      multiple: false,
	  color: 1,
	  section: 'Text fields'
    },
    {
      id: 2,
      name: 'paragraph',
      icon: 'align-left',
      multiple: false,
	  color: 1,
	  section: 'Text fields'
    },
    {
      id: 7,
      name: 'email',
      icon: 'at',
      multiple: false,
	  color: 1,
	  section: 'Text fields'
    }, 
    {
      id: 9,
      name: 'phone',
      icon: 'phone',
      multiple: false,
	  color: 1,
	  section: 'Text fields'
    },
    {
      id: 4,
      name: 'radio',
      icon: 'dot-circle',
      multiple: true,
	  color: 2,
	  section: 'Multiple choices fields'
    },
    {
      id: 5,
      name: 'checkbox',
      icon: 'check-square',
      multiple: true,
	  color: 2,
	  section: 'Multiple choices fields'
    },
    {
      id: 6,
      name: 'options',
      icon: 'caret-square-down',
      multiple: true,
	  color: 2,
	  section: 'Multiple choices fields'
    },
    {
      id: 8,
      name: 'hidden',
      icon: 'eye-slash',
      multiple: false,
	  color: 3,
	  section: 'Various fields'
    }
];

export default controlTypes;