import React from 'react';

function ModalBody({
	children,
	className = '',
	style,
	color = '',
	position = 'static',
	hasVerticalPadding = true,
	hasHorizontalPadding = true
}) {

	let outerClass = ['modal_body_outer'];
	let innerClass = ['modal_body_inner'];

	// className
	if (className) {
		outerClass = outerClass.concat(className);
	}

	// Color
	if (color) {
		outerClass.push('modal_body_outer_' + color);
	}

	// Position
	if (position == 'relative') {
		outerClass.push('pos_rel');
	}

	// Vertical Padding
	if (!hasVerticalPadding) {
		innerClass.push('modal_body_inner_no_vertical_pad');
	}

	// Horizontal Padding
	if (!hasHorizontalPadding) {
		innerClass.push('modal_body_inner_no_horizontal_pad');
	}

	return (
		<div className={outerClass.join(' ')} style={style}>
			<div className={innerClass.join(' ')}>
				{children}
			</div>
		</div>
	);
}
export default ModalBody;
