import React, { useState } from 'react';
import Dropdown from '../../../../Components/Dropdown.js';
import Btn from '../../../../Components/Btn.js';
import styles from '../ChartContainer/ChartContainer.module.css';
import { listMetricGeolocalisation } from '../Sessions/config.js';
import { useGeoContext } from '../../context/GeolocContext.js';
export default function SelectMetricGeolocalisation() {
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
	const {
		onSelectMetricGeolocalisation,
		metricGeolocalisationSelected
	} = useGeoContext();
	const button = (
		<Btn
			message={metricGeolocalisationSelected ? metricGeolocalisationSelected.label : 'Undefined metric'}
			style="ghost"
			color="black"
			arrow={true}
			fontSize="l"
			className="btn_reset_margin"
		/>
	);

	return (
		<Dropdown
			isOpen={dropdownIsOpen}
			setIsOpen={() => setDropdownIsOpen(true)}
			onHide={() => setDropdownIsOpen(false)}
			button={button}
		>
			<div className="flex flex_align_start">
				<div className="flex_item_full_fix">
					<ul className="listbox">
						{listMetricGeolocalisation.map((value) => {
							const isSelected =
								(metricGeolocalisationSelected &&
									metricGeolocalisationSelected.key) === value.key;
							return (
								<li key={value.key}>
									<a
										className={
											isSelected
												? 'listbox_item selected'
												: 'listbox_item'
										}
										onClick={() => {
											onSelectMetricGeolocalisation(value),
											setDropdownIsOpen(false);
										}}
									>
										{value.label}
										{isSelected && (
											<span
												className={styles.indicator}
											></span>
										)}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</Dropdown>
	);
}
