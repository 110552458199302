import React from 'react';
import { IEmptyState } from './types';
import { Spinner } from '../Spinner/Spinner';
import styles from './EmptyState.module.css';

function EmptyState({
	title = '',
	text = '',
	primaryAction,
	secondaryAction,
	imageUrl,
	imageWidth,
	icon,
	card,
	cardTextSize = 'm',
	verticalSize = 'm',
	verticalAlign = false,
	textSize = 'm',
	titleIsBold = true,
	hasSpinner = false,
	hasBorder = false,
	width = 'full',
}:IEmptyState):JSX.Element {
	
	const wrapperClass = [styles.wrapper];
	const titleClass = [styles.title];
	const cardClass = [styles.card];
	const textSizeClass:any = {
		s: styles.wrapper_text_size_s,
		l: styles.wrapper_text_size_l,
		xl: styles.wrapper_text_size_xl
	};
	const verticalSizeClass:any = {
		xs: styles.wrapper_size_xs,
		s: styles.wrapper_size_s,
		m: styles.wrapper_size_m,
		l: styles.wrapper_size_l,
		xl: styles.wrapper_size_xl
	};
	const widthClass:any = {
		s: styles.wrapper_width_s,
		m: styles.wrapper_width_m,
		l: styles.wrapper_width_l,
		xl: styles.wrapper_width_xl
	};
	const cartTextClass:any = {
		m: styles.card_text_size_m,
		l: styles.card_text_size_l
	};
	
	// verticalSize
	if (width && width in widthClass) {
		wrapperClass.push(widthClass[width]);
	}

	// verticalSize
	if (verticalSize && verticalSize in verticalSizeClass) {
		wrapperClass.push(verticalSizeClass[verticalSize]);
	}
	
	// textSize
	if (textSize && textSize in textSizeClass) {
		wrapperClass.push(textSizeClass[textSize]);
	}
	
	// verticalAlign
	if (verticalAlign) {
		wrapperClass.push(styles.wrapper_absolute);
	}
	
	// Border
	if (hasBorder) {
		wrapperClass.push(styles.wrapper_has_border);
	}
	
	// titleIsBold
	if (titleIsBold) {
		titleClass.push(styles.title_medium);
	}

	// Card text size
	if (cardTextSize && cardTextSize in cartTextClass) {
		cardClass.push(cartTextClass[cardTextSize]);
	}
	
	return (
		<div className={wrapperClass.join(' ')}>
			{hasSpinner &&
				<Spinner
					display="block"
					verticalSize="s"
					size="xl"
				/>
			}
			{imageUrl && 
				<img className={styles.img} src={imageUrl} width={imageWidth} />
			}
			{icon && 
				<div className={styles.icon}>
					<i className={icon}></i>
				</div>
			}
			{title && 
				<div className={titleClass.join(' ')}>{title}</div>
			}
			{text && 
				<div className={styles.text}>{text}</div>
			}
			{(primaryAction || secondaryAction) &&
				<div className={styles.btn}>{secondaryAction}{primaryAction}</div>
			}
			{card &&
				<div className={cardClass.join(' ')}>{card}</div>
			}
		</div>
	);
}

export {
	EmptyState
};
