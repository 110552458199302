import React, { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import Btn from '../../../Components/Btn';
export default function FiltAbTestFilterActions({
	statusArray,
	setstatusArray
}) {
	const [t, i18n] = useTranslation('common');


	const canRemoveFilter = (type, status) => {
		let filter_lenght = statusArray.length;
		if (filter_lenght > 1) {
			return true;
		}
		return !statusArray.includes(status);
	}


	const updateStatusArray = (filter) => {
		if (!canRemoveFilter("status", filter)) return;
		let actualStatusArray = [...statusArray];
		if (actualStatusArray.includes(filter)) {
			actualStatusArray = actualStatusArray.filter(status => status !== filter);
			setstatusArray(actualStatusArray);
			sessionStorage.setItem("abtestStatusFilter", JSON.stringify(actualStatusArray));
		} else {
			actualStatusArray.push(filter);
			setstatusArray(actualStatusArray);
			sessionStorage.setItem("abtestStatusFilter", JSON.stringify(actualStatusArray));
		}
	}
	useEffect(()=>{
		const filtertorage = JSON.parse(
			sessionStorage.getItem('abtestStatusFilter')
		);
		if(filtertorage){
			setstatusArray(filtertorage);
		}
	},[]);
	return (
		<div className="btn_switch">
			
			<Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Draft')}
				size="xs"
				light={true}
				className={statusArray && statusArray.includes('Draft') ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateStatusArray('Draft')}
			/>
			<Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Ongoing')}
				size="xs"
				light={true}
				className={statusArray?.includes('OnGoing') ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateStatusArray('OnGoing')}
			/>
			<Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Scheduled')}
				size="xs"
				light={true}
				className={statusArray?.includes('Scheduled') ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateStatusArray('Scheduled')}
			/>
			<Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Stopped')}
				size="xs"
				light={true}
				className={statusArray?.includes('Stopped') ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateStatusArray('Stopped')}
			/>
			{/* <Btn
				color="secondary"
				style="outline"
				message={t('filterListActions.Past')}
				size="xs"
				light={true}
				className={statusArray?.includes('Past') ? 'btn_w_xxs active' : 'btn_w_xxs'}
				onClick={() => updateStatusArray('Past')}
			/> */}
		</div>
	)
}