const algorithmDefinition = [
    {
        strategy: 'MostAttractive',
        // option: 'choose between',
        option: 'none',
        optionChoice: '',
        ruleScope: {
            scopeIdentifier: ""
        },
        filters: [
            {
                type: "null",
                values: []
            }
        ],
        config: {}
        ,
    },
    {
        strategy: 'MostViewed',
        ruleScope: {
            scopeIdentifier: ""
        },
        //option: 'choose between',
        option: 'none',
        optionChoice: '',
        filters: [
            {
                type: "null",
                values: [
                ]
            }
        ],
        config: {}
        ,
    },
    {
        strategy: 'MostAddedToCart',
        ruleScope: {
            scopeIdentifier: ""
        },
        option: 'none',
        filters: [
            {
                type: "null",
                values: [
                ]
            }
        ],
        config: {}
        ,
    },
    {
        strategy: 'MostConverted',
        ruleScope: {
            scopeIdentifier: ""
        },
        option: 'none',
        filters: [
            {
                type: "null",
                values: [
                ]
            }
        ],
        config: {}
        ,
    },
    {
        strategy: 'OftenPurchasedTogether',
        ruleScope: {
            scopeIdentifier: ""
        },
        option: 'none',
        filters: [
            {
                type: "null",
                values: [
                ]
            }
        ],
        config: {}
        ,
    },
    {
        strategy: 'PreviousViewed',
        ruleScope: {
            scopeIdentifier: ""
        },
        option: 'none',
        filters: [
            {
                type: "null",
                values: [
                ]
            }
        ],
        config: {}
        ,
    },
    {
        strategy: 'CustomAssociation',
        ruleScope: {
            scopeIdentifier: ""
        },
        option: 'matching list',
        optionChoice: [],
        filters: [
            {
                type: "null",
                values: [
                ]
            }
        ],
        config: { "matchingLists": [] }
        ,
    },    
    {
        strategy: 'PassThrough',
        ruleScope: {
            scopeIdentifier: ""
        },
        option: 'none',
        filters: [
            {
                type: "null",
                values: [
                ]
            }
        ],
        config: {}
        ,
    },
    {
        strategy: 'Collection',
        ruleScope: {
            scopeIdentifier: ""
        },
        option: 'none',
        filters: [
            {
                type: "null",
                values: [
                ]
            }
        ],
        config: {}
        ,
    },
    {
        strategy: 'Custom',
        ruleScope: {
            scopeIdentifier: ""
        },
        option: 'none',
        filters: [
            {
                type: "null",
                values: [
                ]
            }
        ],
        config: {}
        ,
    }

]
export default algorithmDefinition;