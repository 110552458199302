import React, {useEffect, useState} from 'react';
import {Panel, PanelHeader, PanelBody} from '../../../../Components/Panel/Panel';
import Btn from '../../../../Components/Btn';
import { Article } from '../../../../Components/Article/Article';
import SectionMessage from '../../../../Components/SectionMessage';
import SelectDropdown from '../../../../Components/SelectDropdown';
import CodeSnippet from '../../../../Components/CodeSnippet';
import Fieldset from '../../../../Components/Fieldset';
import Checkbox from '../../../../Components/Checkbox';
import InputCustom from '../../../../Components/InputCustom';

import newInstanceI18next from '../../../../Util/traductionUtils';
import { I18nextProvider } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const CustomGoalPanel = (props) => {
	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<CustomGoalPanelInner {...props} />
		</I18nextProvider>
	);
};

const CustomGoalPanelInner = ({
	isOpen,
	onClose,
	goalType = 'goal'
}) => {

	const [t] = useTranslation(['goals', 'utils']);
	const [type, setType] = useState(goalType);
	const [name, setName] = useState('new_goal');
	const [hasPromoCode, setHasPromoCode] = useState(true);
	const [hasPaymentType, setHasPaymentType] = useState(true);
	const [hasTags, setHasTags] = useState(false);
	const [transactionSnippetRows, setTransactionSnippetRows] = useState(11);

	const optionsList = [
		{value: 'goal', label: t('customGoal'), description: t('customGoalDescription')},
		{value: 'transactionGoal', label: t('transactionGoal'), description: t('transactionGoalDescription')}
	];

	useEffect(() => {
		if (isOpen) {
			setType(goalType);
		}
	}, [isOpen, goalType]);

	useEffect(() => {
		let rows = 11;
		if (hasPromoCode) rows += 1;
		if (hasPaymentType) rows += 1;
		if (hasTags) rows += 4;
		setTransactionSnippetRows(rows);
	}, [hasPromoCode, hasPaymentType, hasTags]);

	function getTransactionGoalCode() {
		return `
<script type="text/javascript">
	window.BY_EVENTS = window.BY_EVENTS || [];
	window.BY_EVENTS.push({
		type: 'transaction', 
		data: {
			transactionId: 'replace_by_transaction_ID',
			amount: replace_by_transaction_amount,
			productQuantity: replace_by_product_quantity${hasPromoCode ? `,
			coupon: 'replace_by_promo_code'` : ''}${hasPaymentType ? `,
			paymentMethod: 'replace_by_payment_method'` : ''}${hasTags ? `,
			tags: {
				tag1: 'value',
				tag2: 'value'
			}` : ''}
		}
	});
</script>`.trim();
	}

	function getGoalCode() {
		return `
<script type="text/javascript">
	window.BY_EVENTS = window.BY_EVENTS || [];
	window.BY_EVENTS.push({
		type: 'goal',
		label: '${name}'
	});
</script>`.trim();
	}
	
	return (
		<Panel isOpen={isOpen} width={740}>
			<PanelHeader
				title={t('trackGoals')}
				onClose={onClose}
				primaryAction={
					<Btn
						message={t('actions.done', { ns: 'utils' })}
						onClick={onClose}
					/>
				}
			/>
			<PanelBody color='grey'>
				<SectionMessage
					text={
						<>
							<p>{t('help1')}</p>
							<p>{t('help2')}</p>
							<p>{t('help3')}</p>
						</>
					}
					appearance='background_outline'
				/>
				<Article innerSize='l'>
					<SelectDropdown
						label={t('goalType')}
						optionsList={optionsList}
						onChange={(v) => setType(v)}
						value={type}
						blockSize='l'
					/>
					{type == 'goal' &&
						<>
							<InputCustom
								label={t('goalName')}
								value={name}
								onChange={(e) => setName(e.target.value)}
								isAnError={name === '' ? t('emptyGoalWarning') : ''}
								blockSize='l'
							/>
						</>
					}
					{type == 'transactionGoal' &&
						<>
							<Fieldset label={t('optionalTransactionDatas')} blockSize="l">
								<Checkbox
									label={t('sendPromoCode')}
									fullWidth={true}
									forcedChecked={hasPromoCode}
									onChange={(e) => setHasPromoCode(e.target.checked)}
								/>
								<Checkbox
									label={t('sendPaymentMethod')}
									fullWidth={true}
									forcedChecked={hasPaymentType}
									onChange={(e) => setHasPaymentType(e.target.checked)}
								/>
								<Checkbox
									label={t('sendCustomTags')}
									fullWidth={true}
									forcedChecked={hasTags}
									onChange={(e) => setHasTags(e.target.checked)}
								/>
							</Fieldset>
						</>
					}
					<Fieldset
						label={t('trackingCodeSnippet')}
						description={t('trackingCodeSnippetDescription')}>
						{type == 'goal' &&
							<CodeSnippet
								code={getGoalCode()}
								nbRows={7}
							/>
						}
						{type == 'transactionGoal' &&
							<CodeSnippet
								code={getTransactionGoalCode()}
								nbRows={transactionSnippetRows}
							/>
						}
					</Fieldset>
				</Article>
			</PanelBody>
		</Panel>
	);
};
	
export default CustomGoalPanel;
