import firstVisit from './TargeredPromotions/firstVisit';
import hesitationProduct from './TargeredPromotions/hesitationProduct';
import cartAbandonment from './TargeredPromotions/cartAbandonment';
import targetedPromotions from './StopAbandonment/targetedPromotions';
import reinsurance from './StopAbandonment/reinsurance';
import viewedProducts from './ViewedProducts/viewedProducts';
import betterCampaigns from './BetterCampaigns/betterCampaigns';
import catchAbandonment from './CustomerBase/catchAbandonment';

const defaultStepOptions = {
    modalOverlayOpeningPadding: 12,
    modalOverlayOpeningRadius: 6,
    classes: 'custom-class-name-1 custom-class-name-2',
    highlightClass: 'highlight',
    cancelIcon: {
        enabled: false,
    },
    scrollTo: true,
    scrollToHandler: function (el) {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    }
};

function TargeredPromotions(rootscope) {
    return {
        tourOptions: {
            defaultStepOptions: defaultStepOptions,
            useModalOverlay: true,
            keyboardNavigation: false,
        },
        id: 'TargeredPromotions',
        label: 'Propose targeted promotions',
        description: 'Find the right target to push promotions that bring real impact on your sales !',
        icon: 'icon_targeted_promo',
        title: 'Propose targeted promotions',
        scenarios: {
            "FirstVisit": firstVisit(rootscope),
            "hesitationProduct": hesitationProduct(rootscope),
            "cartAbandonment": cartAbandonment(rootscope),
        }
    }
};

function StopAbandonment(rootscope) {
    return {
        tourOptions: {
            defaultStepOptions: defaultStepOptions,
            useModalOverlay: true,
            keyboardNavigation: false,
        },
        id: 'StopAbandonment',
        label: 'Stop abandonment',
        description: 'Customize exit popup to capture more leads and sales !',
        icon: 'icon_exit',
        title: 'Stop Abandonment',
        scenarios: {
            "TargetedPromotions": targetedPromotions(rootscope),
            "reinsurance": reinsurance(rootscope),
        }
    }
};

function ViewedProducts(rootscope) {
    return {
        tourOptions: {
            defaultStepOptions: defaultStepOptions,
            useModalOverlay: true,
            keyboardNavigation: false,
        },
        id: 'ViewedProducts',
        label: 'Get quick access on viewed products',
        description: 'In a viewable footer-bar on desktop facilitate the access to all products that have been seen.',
        icon: 'icon_history',
        title: 'Get quick access on viewed products',
        scenarios: {
            "viewedProducts": viewedProducts(rootscope),
        }
    }
};

function BetterCampaigns(rootscope) {
    return {
        tourOptions: {
            defaultStepOptions: defaultStepOptions,
            useModalOverlay: true,
            keyboardNavigation: false,
        },
        id: 'BetterCampaigns',
        label: 'Give more impact to your online campaigns',
        description: 'Welcome your traffic in a personalized way',
        icon: 'icon_desktop_power',
        title: 'Give more impact to your online campaigns',
        scenarios: {
            "betterCampaigns": betterCampaigns(rootscope),
        }
    }
};

function RetailStores(rootscope) {
    return {
        tourOptions: {
            defaultStepOptions: defaultStepOptions,
            useModalOverlay: true,
            keyboardNavigation: false,
        },
        id: 'RetailStores',
        label: 'Help retail stores with local content',
        description: 'Available soon !',
        icon: 'icon_store',
        title: 'Help retail stores with local content',
        scenarios: {}
    }
};

function CustomerBase(rootscope) {
    return {
        tourOptions: {
            defaultStepOptions: defaultStepOptions,
            useModalOverlay: true,
            keyboardNavigation: false,
        },
        id: 'CustomerBase',
        label: 'Increase customer base',
        description: 'Capture more leads throught custom form',
        icon: 'icon_add_user',
        title: 'Increase customer base',
        scenarios: {
            "catchAbandonment": catchAbandonment(rootscope),
        }
    }
};

function ContextualTooltip(rootscope) {
    return {
        tourOptions: {
            defaultStepOptions: defaultStepOptions,
            useModalOverlay: true,
            keyboardNavigation: false,
        },
        id: 'ContextualTooltip',
        label: 'Help decision with contextual tooltips',
        description: 'Available soon !',
        icon: 'icon_tooltip',
        title: 'Help decision with contextual tooltips',
        scenarios: {}
    }
};


export {
    StopAbandonment,
    TargeredPromotions,
    ViewedProducts,
    BetterCampaigns,
    RetailStores,
    CustomerBase,
    ContextualTooltip
};

const Tours = [
    TargeredPromotions(),
    StopAbandonment(),
    ViewedProducts(),
    BetterCampaigns(),
    RetailStores(),
    CustomerBase(),
    ContextualTooltip()
]
export default Tours;