import React, {useState, useEffect, useRef} from 'react';
import { react2angular } from 'react2angular';
import styles from './HelpMenu.module.css';
import PopinChatBot from './PopinChatBot';
import newInstanceI18next from '../../Util/traductionUtils';
import { I18nextProvider } from 'react-i18next';
import {Trans, useTranslation} from 'react-i18next';
import IconBtn from '../../Components/IconBtn';
import {Flag} from '../../Components/Flag/Flag';
import useFeature from '../../../flags/useFeature';

export default function ChatBotContainer(props) {

	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<ChatBotContainerInner {...props} />
		</I18nextProvider>
	);
}

function ChatBotContainerInner(props) {

	// @ts-ignore
	const ic = window.Intercom;

	const { features } = useFeature();
	const [t] = useTranslation('chatbot');
	const [chatbotIsOpen, setChatbotIsOpen] = useState(false);
	const [intercomIsOpen, setIntercomIsOpen] = useState(false);
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [btnIsOpen, setBtnIsOpen] = useState(false);
	const [chatBotTooltip, setChatBotTooltip] = useState(false);
	const canShowTooltipRef = useRef(false);
	
	function handleClickMainButton(){
		if (intercomIsOpen) {
			setIntercomIsOpen(false);
			return;
		}

		if (chatbotIsOpen) {
			setChatbotIsOpen(false);
			return;
		}

		if (features.includes('chatbot-tooltip') && chatBotTooltip && !chatbotIsOpen) {
			setChatbotIsOpen(true);
			return;
		}

	

		const currentUser = props.AuthServices.GetAuthenticatedUser();
		const isAdmin = currentUser?.roles.includes('Admin');
		
		if (isAdmin) {
			setMenuIsOpen(!menuIsOpen);
		} else {
			openIntercom();
		}
	}

	function openIntercom() {
		if (!ic) {
			console.log('Intercom is not enabled on this page');
			setMenuIsOpen(false);
			return;
		}
		setIntercomIsOpen(true);
		setChatbotIsOpen(false);
		setMenuIsOpen(false);
	}

	function openChatbot() {
		setChatbotIsOpen(true);
		setIntercomIsOpen(false);
		setMenuIsOpen(false);
	}

	function hideChatBotTooltip() : void {
		setChatBotTooltip(false);
		sessionStorage.setItem('closeChatBotTooltip', '1');
	}

	useEffect(() => {
		setBtnIsOpen(chatbotIsOpen || intercomIsOpen || menuIsOpen);
		if (features.includes('chatbot-tooltip') && chatBotTooltip) {
			hideChatBotTooltip();
		}
		canShowTooltipRef.current = !(chatbotIsOpen || intercomIsOpen || menuIsOpen);
	}, [chatbotIsOpen, intercomIsOpen, menuIsOpen]);

	useEffect(() => {
		if (ic) {
			if (intercomIsOpen) {
				ic('show');
			} else {
				ic('hide');
			}
		}
	}, [intercomIsOpen]);

	useEffect(() => {
		if (ic) {
			ic('onHide', function(){
				setIntercomIsOpen(false);
			});
		}

		const timer = setTimeout(() => {
			if (canShowTooltipRef.current) {
				const currentUser = props.AuthServices.GetAuthenticatedUser();
				const isAdmin = currentUser?.roles.includes('Admin');
				if (!isAdmin) return;

				// Only one time per tab for demo
				const storage = sessionStorage.getItem('closeChatBotTooltip');
				if (storage) return;

				setChatBotTooltip(true);
			}
		}, 4000);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<div className={styles.wrapper}>
			{menuIsOpen &&
				<>
					<div>
						<button className={styles.menu_btn} onClick={openIntercom}>{t('chatbot.helpAndSupport')}</button>
					</div>
					<div>
						<button className={styles.menu_btn} onClick={openChatbot}>
							{t('chatbot.beyableCopilot')}
							<Flag
								icon="ai"
								text="AI"
								tooltip="This feature is powered by BEYABLE AI"
								className="ml_10"
								color="electric_green"
							/>
						</button>
					</div>
				</>
			}

			{features.includes('chatbot-tooltip') && chatBotTooltip &&
				<>
					<div className={styles.tooltip} onClick={() => setChatbotIsOpen(true)}>
						<div className={styles.tooltip_head}>
							<svg className={styles.tooltip_icon} xmlns="http://www.w3.org/2000/svg" width="20.013" height="18.356" viewBox="0 0 20.013 18.356">
								<path d="M93.084-79.644H86.109A3.006,3.006,0,0,1,83.5-81.158l-3.521-6.178a3.013,3.013,0,0,1,0-2.971L83.5-96.485A3.006,3.006,0,0,1,86.109-98h6.975a3.005,3.005,0,0,1,2.606,1.515l3.521,6.179a3.009,3.009,0,0,1,0,2.971l-3.521,6.178A3.005,3.005,0,0,1,93.084-79.644Zm-7.743-7.937a.7.7,0,0,0-.56.281.7.7,0,0,0,.142.976,8.333,8.333,0,0,0,4.645,1.4A8.376,8.376,0,0,0,90.742-85a9.135,9.135,0,0,0,3.474-1.285.694.694,0,0,0,.316-.432.691.691,0,0,0-.082-.526.693.693,0,0,0-.6-.335.7.7,0,0,0-.362.1,7.752,7.752,0,0,1-2.944,1.1,6.9,6.9,0,0,1-.963.068,6.991,6.991,0,0,1-3.827-1.128A.692.692,0,0,0,85.342-87.581Z" transform="translate(-79.59 98)" fill="#7d38db" opacity="0.997"/>
							</svg>
							<span className={styles.tooltip_title}>
								{t('chatbot.beyableCopilot')}
							</span>
							<span className={styles.tooltip_close}>
								<IconBtn
									icon="fas fa-times"
									onClick={(ev) => {ev.stopPropagation(); hideChatBotTooltip();}}
								/>
							</span>
						</div>
						<div className={styles.tooltip_body}>
							<p>
								<Trans t={t} i18nKey="chatbot.weHaveCollectedEnoughtData">
									<strong>Good news</strong>, we have collected enough data and I have audited your site.
								</Trans>
							</p>
							<p>{t('chatbot.letSeeTogether')}</p>
						</div>
					</div>
				</>
			}
			
			<div className={styles.btn} onClick={handleClickMainButton}>
				{!btnIsOpen && <span className={styles.btn_icon}></span>}
				{btnIsOpen && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z" fill="white"></path></svg>}
			</div>

			<PopinChatBot
				chatIsOpen={chatbotIsOpen}
				setchatIsOpen={setChatbotIsOpen}
				props={{...props}}
			/>
		</div>
	);
}

// @ts-ignore
angular
	.module('beyableSaasApp.ChatBotContainer', [])
	.component('chatbot', react2angular(ChatBotContainer, [], ['$http', '$rootScope', '$routeParams', '$location', '$timeout','AuthServices']));
