const styles = `
.BY_BLD .by_popin_inner{
	
}
.BY_BLD .by_popin_inner{
	font-size: 16px;
}
@media (max-width: 1024px) {
	.BY_BLD .by_popin_inner{
		font-size: 14px;
	}
}
@media (max-width: 767px) {
	.BY_BLD .by_popin_inner{
		font-size: 12px;
	}
}
@media (max-width: 576px) {
	.BY_BLD .by_popin_inner{
		font-size: 3vmin;
	}
}
`;

export const componentPopin = {
	model: {
		defaults: {
			name: 'Popin',
			removable: false,
			draggable: false,
			droppable: true,
			copyable: false,
			layerable: true,
			selectable: true,
			hoverable: true,
			toolbar: [],
			resizable: {
				cl: true,
				cr: true,
				bl: false,
				br: false,
				bc: false,
				tl: false,
				tr: false,
				tc: false,
				step: 1
			},
			tagName: 'div',
			attributes: {
				class: 'by_popin_inner by-js-builder-layer-open'
			},
			traits: [],
			styles: styles,
			stylable: [
				'width',
				'background-color',
				'background-image',
				'border-radius',
				'box-shadow',
			],
			style: {
				'display': 'flex',
				'flex-direction': 'column',
				'justify-content': 'center',
				'overflow': 'hidden',
				'width': '600px',
				'max-width': '100%',
				'min-height': '150px',
				'padding': '0px',
				'background-color': '#FFFFFF',
				'border-radius': '6px',
				'box-shadow': '0 0 20px 0 rgba(0,0,0,.3)',
				'background-repeat': 'no-repeat',
				'background-position': 'center',
				'background-size': 'cover',
			},
		}
	}
};
