import React, { Component } from 'react';
import styles from './VisitorEngagementRightPane.module.css';
import VisitorEngagementSignalPanel from '../VisitorEngagement/VisitorEngagementSignalPanelComponent';
import VisitorEngagementReportsEventPanel from '../VisitorEngagementReports/VisitorEngagementReportsEventPanelComponent';

const DATEPICKER_SELECTOR = ".table-condensed";

export default class VisitorEngagementRightPane extends Component {

    constructor(props) {
        super(props);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.isPanelDisplayed = this.isPanelDisplayed.bind(this);
    }

    componentDidUpdate() {
        if (this.isPanelDisplayed()) {
            document.addEventListener('click', this.handleOutsideClick, false);
        }
        else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }

    isPanelDisplayed() {
        return (this.props.showSignalPanel || this.props.showEventPanel);
    }

    handleOutsideClick(e) {
        // ignore clicks on the component itself
        if (this.node.contains(e.target)) {
            return;
        }
        // Hack: ignore clicks on react-select components
        if (e.target.id && e.target.id.startsWith("react-select")) {
            return;
        }
        // Hack: ignore clicks on datepicker components
        if (e.target.closest(DATEPICKER_SELECTOR)) {
            return;
        }
        // Hack: ignore clicks on highcharts flags
        if (e.target.classList && e.target.classList[0] === "highcharts-label-box") {
            return;
        }
        this.props.hideAndResetPanel();
    }

    render() {
        return <div className={[styles.content, !this.isPanelDisplayed() ? styles.hidden : ''].join(' ')} ref={node => { this.node = node; }}>
            <h1 className={styles.title}>{this.props.title}</h1>
            <p className={styles.intro}>{this.props.intro}</p>
            <div className={styles.description}>{this.props.description}</div>
            {this.props.showSignalPanel &&
                <VisitorEngagementSignalPanel
                    actionLabel={this.props.actionLabel}
                    signalToEdit={this.props.signalToEdit}
                    createOrUpdateSignal={this.props.createOrUpdateSignal}
                    deleteSignal={this.props.deleteSignal}
                    hideAndResetSignalPanel={this.props.hideAndResetPanel}
                />}
            {this.props.showEventPanel &&
                <VisitorEngagementReportsEventPanel
                    actionLabel={this.props.actionLabel}
                    eventToEdit={this.props.editPayload}
                    createOrUpdateEvent={this.props.createOrUpdate}
                    deleteEvent={this.props.delete}
                    hideAndResetEventPanel={this.props.hideAndResetPanel}
                />}
        </div >
    }
}
