import React, {useEffect, useState} from 'react';
import {useInteractionContext} from '../../context/InteractionContext';
import styles from '../Board/Board.module.css';
import PaginationBoard from '../Board/PaginationBoard';
import InteractionCategoryBoard from '../Board/InteractionCategoryBoard';
import {useAnalyticsContext} from '../../context/AnalyticsContextProvider';

export default function InteractionCategoryBoardContainer({interactionServices}) {

	const {
		offset,
		setOffset,
		currentPage,
		setCurrentPage,
		handleFilter,
		navFilter,
		sourceType,
		utmDimensionsSelected,
		needRefreshForUtmFilter,
		searchValue,
		filterExactValue,
		reportTypeSelected,
		deviceSelected,
		excludeFilter,
		isExactMatch,
	} = useInteractionContext();

	const {selectedTenant} = useAnalyticsContext();
	const [orderBy, setOrderBy] = useState('UniqueCount');
	const [orderByDesc, setOrderByDesc] = useState('desc');
	const [numberOfData, setNumberOfData] = useState(null);
	const [currentMetrix, setCurrentMetrix] = useState({
		data: null,
		isLoading: true,
		isError: false,
	});

	const [pageSize, setpageSize] = useState(10);

	const handleSort = (orderField, order) => {
		setOrderByDesc(order);
		setOrderBy(orderField);
		setOffset(0);
		setCurrentPage(1);
	};

	const refreshBoard = () => {
		setCurrentMetrix((current) => ({
			...current,
			data: null,
			isLoading: true,
		}));
		interactionServices.getCategories(
			deviceSelected,
			navFilter,
			selectedTenant,
			sourceType,
			reportTypeSelected === 'Page' ? 'Page' :'',
			filterExactValue,
			orderBy,
			orderByDesc === 'desc',
			pageSize,
			offset,
			utmDimensionsSelected,
			searchValue,
			excludeFilter,
			isExactMatch,
			(res, headers) => {
				if (numberOfData !== parseInt(headers('x-total-count'))) {
					setNumberOfData(parseInt(headers('x-total-count')));
				}
				setCurrentMetrix({
					data: res,
					isLoading: false,
					isError: false,
				});
			},
			(err) => console.log(err)
		);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			// Prevent refresh of selectedCategory equal to null
			refreshBoard();
		}, 500);
		return () => {
			clearTimeout(timer);
		};
	}, [
		navFilter,
		selectedTenant,
		sourceType,
		orderBy,
		orderByDesc,
		pageSize,
		offset,
		needRefreshForUtmFilter,
		searchValue,
		deviceSelected,
		excludeFilter,
		isExactMatch
	]);


	return (
		<section className={styles.section}>
			<>
				{currentMetrix.isLoading && !currentMetrix.isError && (
					<div className="mask">
						<span className="wheel"></span>
					</div>
				)}
				{!currentMetrix.isLoading && currentMetrix.isError && (
					<div className="empty_state analytics_empty_state">
						<img
							className="empty_state_img"
							src="/Assets/analytics_data_error.svg"
						/>
						<div className="empty_state_title">Cannot fetch data</div>
						<div className="empty_state_text">Please retry later.</div>
					</div>
				)}
				{!currentMetrix.isLoading &&
                    !currentMetrix.isError &&
                    currentMetrix.data.length == 0 && (
					<div className="empty_state analytics_empty_state">
						<img
							className="empty_state_img"
							src="/Assets/analytics_data_empty.svg"
						/>
						<div className="empty_state_title">
                                No data for this period
						</div>
						<div className="empty_state_text">
                                Maybe try with another period or other filters
						</div>
					</div>
				)}
				{!currentMetrix.isLoading &&
                    !currentMetrix.isError &&
                    currentMetrix.data &&
                    currentMetrix.data.length > 0 && (
					<>
						<div className="analytics_table_wrapper">
							<InteractionCategoryBoard
								title="Interaction Category"
								unique="Unique Interaction"
								count="Number of interactions"
								distinctCount='Distinct Count'
								session='Session Count'
								data={currentMetrix.data}
								orderBy={orderBy}
								selectCategory={handleFilter}
								orderByDesc={orderByDesc}
								handleSort={handleSort}
								reportType={reportTypeSelected}
							/>
						</div>
						<PaginationBoard
							totalCount={numberOfData}
							pageSize={pageSize}
							handleChangePageSize={(e) => {
								setpageSize(e);
								setOffset(0);
							}}
							paginate={(e) => setOffset(e >= 0 ? e : 0)}
							skipIdNumber={offset}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
						/>
					</>
				)}
			</>
		</section>
	);
}