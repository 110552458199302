import React, {useEffect, useState} from 'react';
import Btn from '../../../../../Components/Btn';
import editionStyles from '../../ProductFeedEdition.module.css';
import {fieldIsXpathCustom, keyExistInConfig, transformXpathToNormalValue} from '../../../utils';
import {useProductFeedEditionContext} from '../../../context/ProductFeedEditionContext';
import {ModalMappingTags} from './ModalMappingTags';
import Confirm from '../../../../../Components/Confirm';
import EmptyState from '../../../../../Components/EmptyState';
import IconBtn from '../../../../../Components/IconBtn';

export const MappingTagsContainer = ({canUseTranformations}) => {

	const {
		mappingObject,
		deleteTag,
		tagConfig
	} = useProductFeedEditionContext();

	const [tagModalIsOpen, setTagModalIsOpen] = useState(false);
	const [tagSelected, setTagSelected] = useState({});
	const [isExistingTag, setIsExistingTag] = useState(false);
	const [showDelConfirm, setShowDelConfirm] = useState(false);
	const [tagToDelete, setTagToDelete] = useState(null);
	const [tagsArray, setTagsArray] = useState([]);

	useEffect(() => {
		if (mappingObject['Tags']) {
			const copyOfTagsArray = JSON.parse(JSON.stringify(mappingObject['Tags']));

			copyOfTagsArray.map((tag, index) => {
				copyOfTagsArray[index].isPrivate = tagConfig.privateTags.includes(tag.sourceField);
				copyOfTagsArray[index].isExcluded = tagConfig.excludedTags.includes(tag.sourceField);
			});
			setTagsArray(copyOfTagsArray);
		}
	}, [tagConfig, mappingObject]);

	const hasKeyInObject = keyExistInConfig(('Tags'), mappingObject, null);

	const closeModalTagEdition = () => {
		setTagModalIsOpen(false);
		setTagSelected({});
		setIsExistingTag(false);
	};

	const closeDelConfirm = () => {
		setShowDelConfirm(false);
		setTagToDelete(null);
	};

	return (
		<>
			{hasKeyInObject &&
				<>
					<div className={editionStyles.table + ' ' + editionStyles.table_2}>
						<div className={editionStyles.table_head_row}>
							<div className={editionStyles.table_col}>Field</div>
							<div className={editionStyles.table_col}>Tag name</div>
							<div className={editionStyles.table_col}></div>
						</div>
						{tagsArray.map((tag, i) => (
							<div key={i} className={editionStyles.table_body_row}>
								<div className={editionStyles.table_col}>
									{fieldIsXpathCustom(tag.sourceField) ? transformXpathToNormalValue(tag.sourceField) : tag.sourceField}
								</div>
								<div className={editionStyles.table_col}>
									{tag.beyableItemName || tag.sourceField}
								</div>
								<div className={editionStyles.table_col}>
									<IconBtn
										tooltip="Edit tag"
										icon="fas fa-pen"
										onClick={() => {
											setTagSelected({tag: tag, index: i});
											setIsExistingTag(true);
											setTagModalIsOpen(true);
										}}
									/>
									<IconBtn
										tooltip="Delete tag"
										icon="fas fa-trash"
										onClick={() => {
											setTagToDelete(i);
											setShowDelConfirm(true);
										}}
									/>
								</div>
							</div>
						))}
					</div>
				
					<div className='mt_10'>
						<Btn
							style="reverse"
							icon="fas fa-plus fa-sm"
							message="Add tag"
							size="s"
							horizontalSize="xs"
							onClick={() => setTagModalIsOpen(true)}
						/>
					</div>
				</>
			}
			{!hasKeyInObject &&
				<EmptyState
					title="No tag yet"
					text="Tags allow you to store other useful information."
					verticalSize='s'
					primaryAction={
						<Btn
							onClick={() => {setIsExistingTag(false); setTagModalIsOpen(true);}}
							message="Add tag"
							icon="fas fa-plus fa-sm"
							style="reverse"
							horizontalSize="xs"
						/>
					}
				/>
			}
			<ModalMappingTags
				tagModalIsOpen={tagModalIsOpen}
				setTagModalIsOpen={setTagModalIsOpen}
				hasKeyInObject={hasKeyInObject}
				isExistingTag={isExistingTag}
				tagSelected={isExistingTag ? tagSelected : null}
				closeModalTagEdition={closeModalTagEdition}
				canUseTranformations={canUseTranformations}
			/>
			<Confirm
				title="Do you really want to delete this tag ?"
				text="This action is irreversible"
				confirmText="Delete"
				confirmColor="alert"
				cancelText="Cancel"
				isOpen={showDelConfirm}
				confirmCallback={() => deleteTag(tagToDelete)}
				onClose={closeDelConfirm}
			/>
		</>
	);
};