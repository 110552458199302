import React, { createContext, useState, useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { mapCampaign, getObjectiveType } from '../../utils';
import EventTrackingServices from '../../../../Services/EventTrackingServices';
const tooltipID = uuidv4();

export const context = createContext();

export function abTestsReportingContext() {
	return useContext(context);
}

export const ContextProvider = ({ getOneCampaign, children, ka ,  $routeParams, campaignCreatingServices,systemServices,accountId,$rootScope }) => {
	const { trackEvent } = EventTrackingServices($rootScope.User);
	const [loading, setLoading] = useState(true);
	const [variationsReporting, setVariationsReporting] = useState([]);
	const [campaignInfo, setCampaignInfo] = useState({});	
	const [needRefetch, setneedRefetch] = useState(false);
	const [objectiveSelected, setobjectiveSelected] = useState();
	
	const [objectiveType, setObjectiveType] = useState();
	const loadReporting = (elementToLoad) => {
		setLoading(true);
		const objectiveToLoad = elementToLoad ? elementToLoad : objectiveSelected;
		getOneCampaign(objectiveToLoad,data => {
			setLoading(false);
			const objective = getObjectiveType(data.objectiveIndicator);
			trackEvent('abtest/open-campaign');
			setObjectiveType(objective);
			var mappedCampaign = mapCampaign({...data});
			setCampaignInfo(mappedCampaign);
			setVariationsReporting([ mappedCampaign.variations.baseline,...mappedCampaign.variations.others]);
		});
		
	};

	const fakeLoadReporting = () => {
		setCampaignInfo(
			{
				id: '1',
				name: 'Position du bouton d\'ajout',
				description: 'CTA d\'ajout en fiche produit mobile',
				labels: ['Product page'],
				devices: ['mobile'],
				status: 'Ongoing',
				startDate: '2023-1-2',
				isStopped: false,
				executionMode: 1,
				endDate: null,
				sessionsCount: 12978,
				objectivesCount: 987,
				bestVariation: 'Variation B',
				probabilityToBeatBaseline: 78,
				improvement: 14.3,
				recommendation: { ouput: 'KeepTesting', confidence: 'high' } // NeedMoreData // ChooseVariation // ChooseOriginal // ChooseEitherVariationOrOriginal
			}
		);
		setVariationsReporting([
			{
				id: 'A', variationName: 'Original', sessionsCount: 4561, objectivesCount: 987, probabilityToBeBest: 1, 
				probabilityToBeatBaseline: null, conversionRate: { lower: 3.5, mean: 4, higher: 4.5 },
				conversionRateSeries: [{ value: 2, date: new Date(2023, 7, 1) }, { value: 3, date: new Date(2023, 7, 2) }, { value: 4, date: new Date(2023, 7, 3) }]
			},
			{
				id: 'B', variationName: 'Avant le prix', sessionsCount: 4765, objectivesCount: 951, probabilityToBeBest: 33, 
				probabilityToBeatBaseline: 65, conversionRate: { lower: 4.5, mean: 4.7, higher: 4.9 },
				conversionRateSeries: [{ value: 6, date: new Date(2023, 7, 1) }, { value: 5, date: new Date(2023, 7, 2) }, { value: 4, date: new Date(2023, 7, 3) }]
			},
			{
				id: 'C', variationName: 'Avant le titre', sessionsCount: 4620, objectivesCount: 998, probabilityToBeBest: 65, 
				probabilityToBeatBaseline: 73, conversionRate: { lower: 4.8, mean: 5.1, higher: 5.5 },
				conversionRateSeries: [{ value: 2, date: new Date(2023, 7, 1) }, { value: 6, date: new Date(2023, 7, 2) }, { value: 4, date: new Date(2023, 7, 3) }]
			},
		]);
	};

	useState(() => {
		loadReporting();
	}, []);

	return (
		<context.Provider
			value={{
				variationsReporting,
				campaignInfo,
				loading,
				setLoading,
				loadReporting,
				ka,
				$routeParams,
				systemServices,
				accountId ,
				campaignCreatingServices,
				needRefetch,
				setneedRefetch,
				objectiveSelected,
				setobjectiveSelected,
				objectiveType
			}}
		>
			<ReactTooltip
				Id={tooltipID}
				backgroundColor="black"
				effect="solid"
				place="bottom"
				globalEventOff="click"
				delayShow={600}
			/>
			{children}
		</context.Provider>
	);
};