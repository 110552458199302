import React, { useState, useEffect} from 'react';
import { v4 as uuidv4 } from 'uuid';
import Btn from '../../../../../Components/Btn';
import InputCustom from '../../../../../Components/InputCustom';
import Fieldset from '../../../../../Components/Fieldset';
import IconBtn from '../../../../../Components/IconBtn';
import styles from './TypeNumb.module.css';
export default function TypeNumberDictionary({
	modalIsOpen,
	valueOfParameters,
	handleChangeParameters,
}) {
    // LabelValue
    type Header = {
        value: string,
        key: string, 
        id: string
    }

    const [element, setelement] = useState<any>(<></>);
    const [first, setfirst] = useState(true);
    const [elementToSave, setElementToSave] = useState<any>();
    function  handleChangeElement(evt,id){
    	evt.persist();
    	const name = evt.target.name;
    	if(valueOfParameters){
    		const newValues : any= [...elementToSave];
    		const findElement = elementToSave.find((x:any) => x.id === id);
    		const i = newValues.indexOf(findElement);
    		newValues[i] = {
    			...newValues[i],
    			[name]: evt.target.value
    		};
    		setElementToSave(newValues);
    		const newObj = {};
    		newValues.map(x => {
    			return newObj[x.key] = Number(x.value) ;
    		});
    		handleChangeParameters('Transformations',JSON.stringify(newObj),valueOfParameters );
    	}
    }
    function transformToObjectReadable(obj){
    	const keyObj = Object.keys(obj);
    	const arrayOfHeaders = keyObj.map(element => {
    		const newHeader: Header = {
    			key: element,
    			value: obj[element],
    			id: uuidv4()
    		};
    		return newHeader;
    	});
    	return arrayOfHeaders;
    }
    function transformHeaders(headers){
    	if(!headers) return;
    	else{
    		const arrayOfObjects = transformToObjectReadable(headers);
    		setElementToSave(arrayOfObjects);
    	}
    }

    useEffect(()=>{
    	if(first){
    		const params = valueOfParameters.params.Transformations;
    		if(params.length === 0){
    			const arr = params;
    			transformHeaders(arr);
    			setfirst(false);
    		}else{
    			const arr = JSON.parse(params);	
    			transformHeaders(arr);
    			setfirst(false);

    		}
    	}
		
    },[first,valueOfParameters,modalIsOpen]);
    useEffect(()=>{
    	if(!modalIsOpen){
    	    setfirst(true);
    		setElementToSave(null);
    		
    	}
    },[modalIsOpen]);
    function handleAddHeader(){
    	const newHeader: Header = {
    		key: '',
    		value: '',
    		id: uuidv4()
    	};
    	let mergeObj:Array<Header> = [];
    	if(elementToSave ){
			
    		mergeObj = [...elementToSave,newHeader];
    	}else{
    		mergeObj = [newHeader];
    	}
    	setElementToSave(mergeObj);

    }
    function handleDeleteHeader(h){
    	let mergeObj:Array<Header> = [];
    	if(elementToSave ){
    		mergeObj = elementToSave.filter(x=>x.id !== h);
    		setElementToSave(mergeObj);
    	}
    }
    function constructInputs(arrayOfObjects){
    	const newHeaderArray = <> {arrayOfObjects && arrayOfObjects.map((el) => {
    		return (
    			<div className={styles.row} key={el.id}>
    				<div className={styles.col}>
    					<InputCustom
    						onChange={(ev) => handleChangeElement(ev,el.id)}
    						autoFocus={true}
    						value={el.key || ''}
    						type="text"
    						placeholder="Label"
    						fullWidth={true}
    						rows={2}
    						blockClassName="xl"
    						name="key"
    					/>
    				</div>
    				<div className={styles.col}>
    					<InputCustom
    						onChange={(ev) => handleChangeElement(ev,el.id)}
    						value={el.value || ''}
    						type="number"
    						placeholder="1"
    						fullWidth={true}
    						rows={2}
    						blockClassName="xl"
    						name="value"
    					/>
    				</div>
    				<div className={styles.col}>
    					<IconBtn 
    						icon="fas fa-trash"
    						onClick={()=>handleDeleteHeader(el.id)}
    						hoverColor='alert'
    						size="s"
    					/>
    				</div>
    			</div>
    		);
    	})}</>;
    	setelement(newHeaderArray);
    }
    useEffect(()=>{
    	if(elementToSave){
    		const arr = elementToSave;
    		constructInputs(arr);
    	}
    },[elementToSave]);

    return (
    	<div>
    	<Fieldset label="Label to number">
    			{element}
    			<div className='mt_5'>
    				<Btn
    					onClick={() => handleAddHeader()}
    					message="Add label"
    					color="primary"
    					style="link"
    					icon="fas fa-plus-circle fa-sm"
    				/>
    			</div>
    		</Fieldset>
    	</div>
    );
}


