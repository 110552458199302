import React, { useState } from 'react'
import { useEffect } from 'react';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider'
export default function PromotedList({
	addPromotedProductManualy
}) {
	const {
	} = useRankingContext();

	const [valueToModify, setValueToModify] = useState('');


	const handleChange = (event) => {
		let textAreaValue = { ...valueToModify }
		textAreaValue = event.target.value
		setValueToModify(textAreaValue)
		addPromotedProductManualy(textAreaValue)
	}

	return (
		<div>
			<textarea className='textAreaSku'
				placeholder="Type product ids here (line break or semi-colon separated)"
				value={valueToModify && valueToModify}
				onChange={handleChange} />
		</div>
	)
}
