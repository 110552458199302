import React, { createContext, useContext, useEffect, useState } from 'react';
import { checkIfObjectHasAnElementNonNull } from '../../utils/types';
import { v4 as uuidv4 } from 'uuid';
const FilterContext = createContext();
function useFiltersContext() {
	return useContext(FilterContext);
}

const CreateFilterContextProvider = (props) => {

	const getCheckIfIsAValidUrlFetch = props.getCheckIfIsAValidUrlFetch;
	const [arrayOfFilters, setarrayOfFilters] = useState([]);
	const [tabFilterSelected, setTabFilterSelected] = useState();
	const [modalIsOppen, setModalIsOppen] = useState(false);
	const [isNewFilterFromScratch, setisNewFilterFromScratch] = useState(false);
	const [userFiltersWithId, setUserFiltersWithId] = useState([]);
	const [urlIsInvalid, setUrlIsInvalid] = useState(true);
	const [elementsFilterListAvailableSelected, setElementsFilterListAvailableSelected] = useState([]);
	const [isEditFilterCard, setIsEditFilterCard] = useState(false);
	const [editFilterId, setEditFilterId] = useState();
	const [filterSelectedExpression, setfilterSelectedExpression] = useState();
	const [errorMessageUrl, setErrorMessageUrl] = useState('');
	const handleIsEditFilterCard = (bool) => {
		setIsEditFilterCard(bool);
	};
	const handleElementsFilterListAvailableSelected = (value) => {
		setElementsFilterListAvailableSelected(value);
	};
	function onDeleteFilterById(idToDelete) {
		const newList = elementsFilterListAvailableSelected.filter(el => el.idFilter !== idToDelete.idFilter);
		setElementsFilterListAvailableSelected(newList);
	}
	function upDateFilters(newList) {
		setElementsFilterListAvailableSelected(newList);
		setisNewFilterFromScratch(false);
	}

	const handleClickEditFilter = (idFilter) => {
		let selectedDisplayFilter = userFiltersWithId.filter(
			(elem) => elem.idFilter === idFilter
		);
		const spesificDataOfFilters = props.ListFilterAvailable.forFiltering.reduce((acc, curr) => {
			acc = [...acc, ...curr.itemList];
			return acc;
		}, []);

		if (checkIfObjectHasAnElementNonNull(selectedDisplayFilter[0], 'expression')) {
			setfilterSelectedExpression(selectedDisplayFilter[0].expression);
			const arrayOfFilter = selectedDisplayFilter[0].expression.filters.map((filter) => {
				let rootFilter = arrayOfFilters.find((elem) => {
					return elem.id === filter.collationId;
				});
				return {
					specificData: spesificDataOfFilters.find(el => el.id === rootFilter.id),
					collationId: rootFilter.id,
					id: rootFilter.id,
					idFilter: filter.idFilter,
					name: rootFilter.name,
					op: filter.op,
					params: filter.params,
					value: filter.value,
					period: filter.period,
					valueType: rootFilter.valueType
				};
			});
			handleElementsFilterListAvailableSelected(arrayOfFilter);
		} else {
			let rootFilter = arrayOfFilters.find((elem) => {
				return elem.id === selectedDisplayFilter[0].collationId;
			});
			handleElementsFilterListAvailableSelected([
				{
					specificData: spesificDataOfFilters.find(el => el.id === rootFilter.id),
					collationId: rootFilter.id,
					id: rootFilter.id,
					idFilter: idFilter,
					name: rootFilter.name,
					op: selectedDisplayFilter[0].op,
					params: selectedDisplayFilter[0].params,
					value: selectedDisplayFilter[0].value,
					period: selectedDisplayFilter[0].period,
					valueType: rootFilter.valueType,
				},
			]);
		}
		setEditFilterId(idFilter);
		handleIsEditFilterCard(true);
		setModalIsOppen(true);
		setisNewFilterFromScratch(false);
	};
	function openNewFilter() {
		setisNewFilterFromScratch(true);
		setModalIsOppen(true);
	}
	function validUrl(url){
		getCheckIfIsAValidUrlFetch(url).then((isValidUrl) => {
			setErrorMessageUrl(isValidUrl.errorMessage);
			setUrlIsInvalid(isValidUrl.boolean);
		});
	}
	useEffect(() => {

		if (!props?.userfiltersList || props?.userfiltersList.length === 0)
			return setUserFiltersWithId([]);
		const listWithId = props?.userfiltersList.map((filter) => {

			if (filter.hasOwnProperty('expression') && filter.expression !== null) {
				filter.expression.filters.map((expression) => {
					expression.idFilter = uuidv4();
					return { ...expression, idFilter: uuidv4() };
				});
			}

			return { ...filter, idFilter: uuidv4() };
		});
		setUserFiltersWithId(listWithId);
	}, [props?.userfiltersList]);

	useEffect(() => {
		if (props?.ListFilterAvailable && props?.ListFilterAvailable.length !== 0) {
			const arrayOfFilterValues = () => {
				return props.ListFilterAvailable.forFiltering.reduce(function (
					accumulator,
					sorting
				) {
					const sortObject = sorting.itemList.map(
						(el) =>
							!accumulator.find(
								(element) => element.id === el.id
							) && el
					);
					return [...accumulator, ...sortObject];
				}, []);
			};
			setarrayOfFilters(arrayOfFilterValues());
		}
	}, [props?.ListFilterAvailable]);
	function getNameFilter(elem) {

		const filter = arrayOfFilters?.find((el) => el.id === elem.collationId);

		if (filter !== undefined && filter.params !== null && elem.params !== null) {
			let newObj = { ...elem.params[0].value };
			Object.keys(newObj).forEach(key => {
				if (newObj[key] === null) {
					delete newObj[key];
				}
			});

			const keyofValues = Object.keys(newObj)[0];

			return newObj[keyofValues][0];
		}
		if (filter !== undefined) {
			return (
				(filter.name) || 'Name not found');

		} else return '';

	}
	function handleChangeOperatorBetweenFilters(newOperator) {
		setfilterSelectedExpression((express) => ({
			...express,
			op: newOperator
		}));
	}
	return (
		<FilterContext.Provider
			value={{
				setEditFilterId,
				setModalIsOppen,
				setisNewFilterFromScratch,
				arrayOfFilters,
				modalIsOppen,
				isNewFilterFromScratch,
				userFiltersWithId,
				elementsFilterListAvailableSelected,
				isEditFilterCard,
				editFilterId,
				filterSelectedExpression,
				handleIsEditFilterCard,
				handleElementsFilterListAvailableSelected,
				onDeleteFilterById,
				upDateFilters,
				handleClickEditFilter,
				openNewFilter,
				getNameFilter,
				handleChangeOperatorBetweenFilters,
				tabFilterSelected,
				setTabFilterSelected,
				getCheckIfIsAValidUrlFetch,
				validUrl,
				urlIsInvalid,
				setUrlIsInvalid,
				errorMessageUrl
			}}
		>
			{props?.children}
		</FilterContext.Provider>
	);
};


export default CreateFilterContextProvider;

export { useFiltersContext };
