import React, { Component } from 'react';
import { render } from 'react-dom'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import styles from './VisitorEngagementReportsUserEngagement.module.css';
import './VisitorEngagementReportsUserEngagement.module.css';
import ChartFilterEnum from './ChartFilterEnum';
import ChartDisplayEnum from './ChartDisplayEnum';
import {Article} from '../../Components/Article/Article';

export default class VisitorEngagementReportsUserEngagementComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chartOptions: {
				chart: {
					type: 'column',
					zoomType: 'x',
					plotBorderWidthLeft: 1,
					type: 'line',
				},
				credits: { enabled: false },
				legend: { enabled: false },
				rangeSelector: { enabled: false },
				navigator: { enabled: false },
				scrollbar: { enabled: false },
				lineWidth: 1,
				xAxis: {},
				yAxis: !this.isPercentDisplay() ? {

					className: 'styles.highcharts-color-0',
					title:
					{
						text: '<strong>Sessions</strong>',
						align: 'high',
						offset: 0,
						rotation: 0,
						y: 3,
						x: -8,
						style: {
							fontSize: '14px'
						}
					},
					opposite: false,
					max: this.isPercentDisplay() ? 100 : null,
					min: 0,
					endOnTick: true
				} :

					{

						title: {
							text: '<strong>Sessions %</strong>',
							align: 'high',
							offset: 0,
							rotation: 0,
							y: 3,
							x: -7,
							style: {
								fontSize: '14px'
							}
						},
						opposite: false,
						max: this.isPercentDisplay() ? 100 : null,
						min: 0,
						startOnTick: true,
					},
				// [{

				//     title: {
				//         text: '<strong>Sessions %</strong>',
				//         align: 'high',
				//         offset: 0,
				//         rotation: 0,
				//         y: 3,
				//         x: -7,
				//         style: {
				//             fontSize: '14px'
				//         }
				//     },
				//     opposite: false,
				//     max: this.isPercentDisplay() ? 100 : null,
				//     min: 0,
				//     startOnTick: true,
				// },
				// { // Secondary yAxis
				//     title: {
				//         text: '<strong>Nb of sessions</strong>',
				//         align: 'high',
				//         offset: 0,
				//         rotation: 0,
				//         y: 3,
				//         x: 7,
				//         style: {

				//             fontSize: '14px'
				//         }
				//     },
				//     opposite: true,
				//     // min: 0,
				//     // max: 1000,
				//     tickInterval: 50,

				//     labels: {
				//         format: '{value}',

				//         style: {

				//             color: 'black',
				//         },
				//     },
				// }],

				tooltip: {
					valueSuffix: this.isPercentDisplay() ? ' % visits' : ' visits',
					backgroundColor: '#38444A',
					borderRadius: 1,
					style: {
						color: '#FFFFFF',
					},
					borderWidth: 2,
					borderColor: '{series.lineColor}',
					pointFormat: '<span>{series.name}</span> : <br>{point.percentage:.1f}%</b> ({point.y:,.0f} sessions)',
					split: true
					// shared: true,
				},
				plotOptions: {

					column: {
						stacking: this.isPercentDisplay() ? 'percent' : 'normal',
						grouping: true,
					},
					line: {
						stacking: this.isPercentDisplay() ? 'normal' : 'normal',
						grouping: false,
						//shadow: true,
						boostThreshold: 500,
						lineWidth: 0.5,
						dashStyle: 'long',
					}
					,
					area: {
						stacking: 'normal',
						lineWidth: 2,
					},
					series: {

						// pointWidth: series.length < 10 ? 50 : 20,
						maxPointWidth: 90,
					}
				},

			},
		}
	}
	// mapper ici pour les noms ? 



	isPercentDisplay() {
		return this.props.display === ChartDisplayEnum.Percentage;
	}

	// https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
	static getDerivedStateFromProps(props, state) {
		if (props.series !== state.chartOptions.series) {

			return {
				...state,
				chartOptions: {
					...state.chartOptions,
					series: props.series
				}
			}
		}
		return null;
	}


	render() {
		return (
			<Article size='l'>
				<h2 className={styles.title}>Customer behavior report</h2>
				<div className={styles.actionColumn}>
					<div className={styles.actionGroupDevice}>
						<div onClick={() => this.props.changeFilter(ChartFilterEnum.Desktop)} className={this.props.filter === ChartFilterEnum.Desktop ? styles.actionSelectedDesktop : styles.desktop}><span className={styles.faDesktop}><i className="fas fa-desktop"></i></span></div>
						<div onClick={() => this.props.changeFilter(ChartFilterEnum.Mobile)} className={this.props.filter === ChartFilterEnum.Mobile ? styles.actionSelectedMobile : styles.mobile}><i className="fas fa-mobile-alt faDesktop"></i></div>
					</div>
					<div className={styles.actionGroupDevice}>
						<div onClick={() => this.props.changeDisplay(ChartDisplayEnum.Percentage)} className={this.props.display === ChartDisplayEnum.Percentage ? styles.actionSelectedPercent : styles.percentValue}><i className="fas fa-percentage"></i> </div>
						<div onClick={() => this.props.changeDisplay(ChartDisplayEnum.Values)} className={this.props.display === ChartDisplayEnum.Values ? styles.absoluteValueSelected : styles.absoluteValue}><strong>123</strong> </div>
					</div>
					<div className={styles.actionGroupDevice}>
						<div onClick={() => this.props.showAddEvent()} className={styles.addButton}><i className="fas fa-map-marker-alt"></i> Add an event</div>
					</div>
				</div>
				<div className={styles.displayFlex}>
					<div className={styles.chartSurrounding}>
						<HighchartsReact
							highcharts={Highcharts}
							constructorType={'stockChart'}
							options={this.state.chartOptions}
						/>
					</div>
				</div>
			</Article>
		);
	}
}
