import React from 'react';

export default function AnalitycsBoardItem({data, isHandleClick, handleClickFunction, needPercent, needFolder = false}) {
	return (
		<div className="table_col ">
			{needFolder &&
                <i className="fas fa-folder mr_5"></i>
			}
			{isHandleClick ?
				<p style={{cursor: 'pointer'}} onClick={() => handleClickFunction(data)}>
					{data}
				</p>
				:
				<>
					{needPercent ? data + ' %' : data}
				</>
			}
		</div>
	);
}
