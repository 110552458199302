import React, { useState } from 'react';
import { useEffect } from 'react';
import Btn from '../../../../Components/Btn';
import Panel from '../../../../Components/Panel';
import PopinConfirm from '../../../../Components/PopinConfirm';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider';
import DeleteItem from './DeleteItem';
import {
	replaceId,
	demoteId,
	promoteId
} from '../../Ranking/utils';
export default function PanelManualSorting({
	dimension,
	subtitle,
	handleClose = () => { },
	panelIsOpen,
	arrayReference
}) {

	const {
		rankingObject,
		deletePromotedProduct,
		deleteDemotedProduct,
		deleteReplaceProductItem,
	} = useRankingContext();
	const [name, setname] = useState('');
	const [arraOfProductId, setNewArrayOfProductId] = useState([]);
	const [productToDelete, setproductToDelete] = useState([]);
	const [openCancelPopin, setopenCancelPopin] = useState(false);

	const handleClosePanel = () => {
		if (productToDelete.length !== 0) {
			setopenCancelPopin(true);
		} else {
			handleClose();
		}
	};
	const filterBySign = (arrayToFilter, idToFilter) => {
		const itemTOtransform = arrayToFilter.filter(el => {
			return el.collationId === idToFilter;
		});
		let elementsToReturn = [];
		if (itemTOtransform.length !== 0) {
			elementsToReturn = [...itemTOtransform[0]['params'][0]['value']['stringValues']];
		}
		return elementsToReturn;
	};
	function getReplacesObj(arrayToFilter) {
		const uniqueItem = arrayToFilter.reduce((acc, curr) => {
			if (curr.collationId === replaceId) {
				const newObj = {
					...curr,
					idOld: curr.params.find(el => el.name === 'First Product Id').value.stringValues,
					idReplace: curr.params.find(el => el.name === 'Second Product Id').value.stringValues,
				};
				acc = [...acc, newObj];
			}

			return acc;
		}, []);

		return [...new Set(uniqueItem)];
	}
	function getNameOfDimension() {
		switch (dimension) {
			case promoteId:
				return 'Promoted products';
			case demoteId:
				return 'Demoted products';
			case replaceId:
				return 'Swapped products';
		}
	}
	useState(() => {
		if (dimension === replaceId) {
			setNewArrayOfProductId(getReplacesObj(rankingObject.transformations));
		} else {
			const newObjectOfTransformation = filterBySign(rankingObject.transformations, dimension);
			setNewArrayOfProductId(newObjectOfTransformation);
		}
		setname(getNameOfDimension());
	}, []);
	function deleteProduct(idOfProduct) {
		if (dimension === replaceId) {
			const newArray = arraOfProductId.filter(el => el.idOld !== idOfProduct);
			setNewArrayOfProductId(newArray);
			setproductToDelete(arr => [...arr, idOfProduct]);
		} else {
			const newArray = arraOfProductId.filter(el => el !== idOfProduct);
			setNewArrayOfProductId(newArray);
			setproductToDelete(arr => [...arr, idOfProduct]);
		}
	}
	function ValidDeletingAction() {
		if (dimension === promoteId) {
			deletePromotedProduct(arraOfProductId);
			handleClose();
		}
		if (dimension === demoteId) {
			deleteDemotedProduct(arraOfProductId);
			handleClose();
		}
		if (dimension === replaceId) {
			deleteReplaceProductItem(productToDelete);
			handleClose();
		}
	}
	return (
		<Panel
			isOpen={panelIsOpen}
			onClose={() => handleClosePanel()}
			noClose={true}
			width={500}
			closeOnOverlay={false}
		>
			{
				openCancelPopin &&
				<PopinConfirm
					cancel={() => handleClose()}
					title={'Are you sure you want to cancel your changes?'}
				>
					<Btn
						onClickFunction={(e) => { setopenCancelPopin(false); }}
						message="No"
						color="primary"
					/>
					<Btn
						onClickFunction={(e) => {
							setopenCancelPopin(false);
							handleClose();
						}}
						message="Yes"
						color="alert"
					/>

				</PopinConfirm>
			}
			<div className="modal_header has_border">
				{name}
				<div className="modal_header_desc">
					{subtitle}
				</div>
			</div>
			<div className="flex_item_full flex flex_v pos_rel" style={{
				padding: '10px 30px',
				overflow: 'auto'
			}}>
				{dimension !== replaceId && arraOfProductId && arraOfProductId.map(
					product => <DeleteItem
						key={product}
						onClickFn={() => deleteProduct(product)}
					>	<span className="fw_medium grey_0">{product}</span>
					</DeleteItem>
				)}
				{dimension === replaceId && arraOfProductId && arraOfProductId.map(
					product => <DeleteItem
						key={product.idOld}
						onClickFn={() => deleteProduct(product.idOld)}
					>
						<div className='flex flex_v flex_align_start '>
							<div > First product id: <span style={{ 'fontWeight': 'bold' }}>{product.idOld}</span> </div>
							<div > Second product id: <span style={{ 'fontWeight': 'bold' }}>{product.idReplace}</span>  </div>
						</div>
					</DeleteItem>
				)}
				{
					arraOfProductId.length === 0 && arraOfProductId.length === 0 &&
					<><div className='flex flex_align_center flex_justify_center s_25 mt_25'
						style={{
							color: 'grey',
							padding: '15px',
						}}>
						<p className='s_15'> &#9432; No products added.</p>
					</div></>
				}
			</div>
			<div className="modal_footer has_border al_right">
				<Btn
					onClickFunction={() => handleClosePanel()}
					message="Cancel"
					color="secondary"
					size="l"
					style="outline"
				/>
				<Btn
					onClickFunction={() => ValidDeletingAction()}
					size="l"
					message="Ok"
					color="primary"
				// style='outline'
				/>
			</div>
		</Panel>
	);
}
