import React, { useEffect, useState } from 'react';
import SearchBar from '../../Components/SearchBar';
import Btn from '../../Components/Btn';
import UserEditModal from './UserEditModal';
import UserLine from './UserLine';
import useDisplayName from '../../Hooks/useDisplayName';
import { useAdminContext } from './userAdministrationContext';
import {useTranslation} from 'react-i18next';

export default function NavigationUsersAdmin({ z }) {
	const {
		usersList,
		canAddUser,
		shouldDisplayOrganizationInfo
	} = useAdminContext();
	const [t, i18n] = useTranslation('common');
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [dataFiltered, setDataFiltered] = useState([]);
	const [noData, setNoData] = useState();
	const [sortData, setSortData] = useState({ 'property': 'name', 'order': 'desc' });
	const [cssClass, setCssClass] = useState({ 'name': '', 'lastConnectionDate': '' });
	const [creationIsOpen, setCreationIsOpen] = useState(false);
	const creactionOnClose = function () {
		setCreationIsOpen(false);
	};
	const { getTheDisplayNameOfUser } = useDisplayName();
	useEffect(() => {
		sortUserList(usersList);
		setValueInSearchBar('');
	}, [usersList]);

	useEffect(() => {
		if (usersList) {
			sortUserList(dataFiltered);
			// Column css class
			const tmpCssClass = { ...cssClass };
			const keys = Object.keys(tmpCssClass);
			for (var i = 0; i < keys.length; i++) {
				const k = keys[i];
				if (k == sortData.property) {
					tmpCssClass[k] = 'user_grid_sort is_active is_' + sortData.order;
				} else {
					tmpCssClass[k] = 'user_grid_sort';
				}
			}
			setCssClass(tmpCssClass);
		}
	}, [sortData]);

	const sortUserList = (list) => {
		const tmpList = list.map(u => ({ ...u, displayName: getTheDisplayNameOfUser(u) }));
		tmpList && tmpList.sort(function (a, b) {
			if (sortData.property == 'name') {
				const order = (sortData.order == 'desc') ? 1 : -1;
				if (a.displayName.toUpperCase() < b.displayName.toUpperCase()) return -order;
				if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) return order;
				return 0;
			}

			if (sortData.property == 'lastConnectionDate') {
				const order = (sortData.order == 'desc') ? 1 : -1;
				const tmpA = moment(a.lastConnectionDateUtc || 0);
				const tmpB = moment(b.lastConnectionDateUtc || 0);
				if (tmpA < tmpB) return -order;
				if (tmpA > tmpB) return order;
				return 0;
			}
		});

		setDataFiltered(tmpList);
	};

	const changeSortData = (property, order) => {
		if (property != sortData.property) {
			setSortData({ 'property': property, 'order': order });
			return;
		}

		if (sortData.order == 'desc') {
			setSortData({ 'property': sortData.property, 'order': 'asc' });
			return;
		}

		setSortData({ 'property': sortData.property, 'order': 'desc' });
	};
	const i18nL= localStorage.getItem('i18nL');
	const noUser = i18nL && i18nL.includes('fr') ? 'Il n\'y a pas d\'utilisateurs pour cette recherche' : 'No user found for this search';
	const searchUser = i18nL && i18nL.includes('fr') ? 'Rechercher un utilisateur' : 'Search a user';
	const add = i18nL && i18nL.includes('fr') ? 'Ajouter un utilisateur' : 'Add User';
	const Name = i18nL && i18nL.includes('fr') ? 'Nom' : 'Name';
	const Organization = i18nL && i18nL.includes('fr') ? 'Organisation' : 'Organization';
	const LastConnection = i18nL && i18nL.includes('fr') ? 'Dernière connexion' : 'Last connection';



	const search = (table, word) => {

		setNoData();
		if (word.length >= 0) {
			let resultName = table.filter(
				line =>
					line.displayName.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let resultByMail = table.filter(
				line =>
					line.email.toUpperCase().includes(word.toUpperCase())
			);
			let finalArray = [...resultName,...resultByMail];
			let uniqueItem = [...new Set(finalArray)];
			if (uniqueItem.length === 0) {
				uniqueItem = noUser;
				setDataFiltered([]);
				setNoData(uniqueItem);
			}
			else {
				sortUserList(uniqueItem);
			}
		}
	};
	const handleChange = (event) => {
		event.persist();
		setValueInSearchBar(event.target.value);
		if (usersList) {
			search(usersList, event.target.value);
		}
	};

	const showOrgaCol = shouldDisplayOrganizationInfo;

	return (
		<>
			<section className="flex">
				<div className="flex_item_full">
					<SearchBar
						placeholder={searchUser}  
						valueInSearchBar={valueInSearchBar}
						onChangeValue={(e) => handleChange(e)}
					/>
				</div>
				<div className="flex_item_fix ml_20">
					{canAddUser ? <Btn
						onClickFunction={() => { setCreationIsOpen(true); }}
						message={add} 
						icon="fas fa-plus"
					/> :
						<Btn
							onClickFunction={null}
							message={add} 
							icon="fas fa-plus"
							disabled={true}
						/>
					}
				</div>
			</section>
			<section className="mt_20">
				<div className="user_grid">
					<div className={showOrgaCol ? 'user_grid_row user_grid_head_row has_orga' : 'user_grid_row user_grid_head_row'}>
						<div className="user_grid_col">
							<a onClick={() => changeSortData('name', 'desc')} className={cssClass.name}>
								{Name} 
								{sortData.property == 'name' && sortData.order == 'desc' &&
									<i className="fas fa-arrow-down"></i>
								}
								{sortData.property == 'name' && sortData.order == 'asc' &&
									<i className="fas fa-arrow-up"></i>
								}
							</a>
						</div>
						<div className="user_grid_col">Roles</div>
						{showOrgaCol &&
							<>
								<div className="user_grid_col">{Organization} </div>
								<div className="user_grid_col">Studio</div>
							</>
						}
						<div className="user_grid_col">
							<a onClick={() => changeSortData('lastConnectionDate', 'asc')} className={cssClass.lastConnectionDate}>
								{LastConnection}
								{sortData.property == 'lastConnectionDate' && sortData.order == 'desc' &&
									<i className="fas fa-arrow-down"></i>
								}
								{sortData.property == 'lastConnectionDate' && sortData.order == 'asc' &&
									<i className="fas fa-arrow-up"></i>
								}
							</a>
						</div>
						<div className="user_grid_col"></div>
					</div>
					{dataFiltered && dataFiltered.map((user) =>
						<UserLine
							user={user}
							key={user.id}
						/>
					)}
				</div>
				{noData && noData}
			</section>

			<UserEditModal
				isOpen={creationIsOpen}
				onClose={creactionOnClose}
			/>
		</>
	);
}
