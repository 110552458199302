import React, { useState } from 'react';
import Dropdown from '../../../../Components/Dropdown.js';
import IconBtn from '../../../../Components/IconBtn.js';
import Listbox from '../../../../Components/Listbox.js';
import ListboxItem from '../../../../Components/ListboxItem.js';
import { useTranslation } from 'react-i18next';

export default function DropDownAbTest({
	index,
	openDelete,
	openDuplicate,
	isDisabled = false
}) {

	const [t] = useTranslation('common');
	const [dropdownIsOppen, setDropdownIsOppen] = useState(false);

	function deleteVariation(){
		openDelete();
		setDropdownIsOppen(false);
	}

	function openDuplicateVariation(){
		openDuplicate();
		setDropdownIsOppen(false);
	}

	if (isDisabled) {
		return (
			<IconBtn
				disabled={true}
				icon="fas fa-ellipsis-v"
			/>
		);
	}

	return (
		<Dropdown
			isOpen={dropdownIsOppen}
			setIsOpen={() => setDropdownIsOppen(true)}
			onHide={() => setDropdownIsOppen(false)}
			button={
				<IconBtn
					icon="fas fa-ellipsis-v"
				/>
			}>
			<Listbox>
				<ListboxItem
					message={t('actions.duplicate')}
					icon='fas fa-clone'
					onClick={()=>openDuplicateVariation()}
				/>
				<li className="hr" />
				{index !== 0 &&
					<ListboxItem
						message={t('actions.delete')}
						icon='fas fa-trash'
						onClick={() => deleteVariation()}
					/>
				}
			</Listbox>
		</Dropdown>
	);
}
