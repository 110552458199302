import React, { useEffect, useState } from 'react'
import './NavigationFeedContainer.css'

export default function NavigationFeedContainer({ accountConfigurationFeed, setIsCreationFeedMode, activeFeed, setActiveFeed, saveTheCurrentValues, setHierarchizationFeedOpen }) {

    const listFeed = [
        {
            value: 'Csv',
            label: 'CSV',
        },
        {
            value: 'Xml',
            label: 'XML',
        },
        {
            value: 'Custom',
            label: 'CUSTOM',
        },
        {
            value: 'OnSiteData',
            label: 'BEYABLE',
        },
        {
            value: 'Prestashop',
            label: 'PRESTASHOP',
        },
        {
            value: 'Web',
            label: 'HTTP',
        },
        {
            value: 'Ftp',
            label: 'FTP',
        },
    ]
    function sortByIndex(a, b) {
        if (a && b) {
            if (a.index < b.index) return -1;
            if (a.index > b.index) return 1;
        }
        return 0;
    }
    return (
        <nav className="navigation_feed_container">
            <ul>
                {activeFeed && <>
                    {accountConfigurationFeed && accountConfigurationFeed.sort(sortByIndex).map((productFeed, i) =>
                        <li key={i}
                            className={productFeed.index ===
                                activeFeed.index ? "navigation_feed_active" : ""}
                            onClick={(e) => saveTheCurrentValues(productFeed && { source: productFeed.source.sourceType, format: productFeed.source.sourceFormat, index: productFeed.index })}
                        >
                            {productFeed.source.sourceType && productFeed.source.sourceType === "OnSiteData" ?
                                <p> {listFeed.filter(x => x.value === productFeed.source.sourceType)[0].label}</p>
                                :
                                <p>{productFeed.source.sourceFormat && productFeed.source.sourceFormat + " / "}  {listFeed.filter(x => x.value === productFeed.source.sourceType)[0].label}</p>

                            }
                        </li>)
                    }
                </>}
                <div className="last_items_navigation_feed">
                    <span onClick={(e) => { saveTheCurrentValues(activeFeed); setHierarchizationFeedOpen(true) }}><i className="fas fa-sort-amount-down"></i></span>
                    <span onClick={(e) => { saveTheCurrentValues(activeFeed, "new") }}> <i className="fas fa-plus"></i> New Feed</span>
                </div>
            </ul>
        </nav >
    )
}
