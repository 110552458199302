import React, { Fragment, useEffect, useState } from 'react';
import { useSegmentGroupEditorContext } from '../context/SegmentListRankingContainerProvider';
import { SegmentRankingApiType } from '../../../../Services/SegmentRankingServicesTypes';

import TableGridContainer from '../../../../Components/Table/TableGridContainer';
import TableRowItem from '../../../../Components/Table/TableRowItem';
import TableColItem from '../../../../Components/Table/TableColItem';
import Btn from '../../../../Components/Btn';
import IconBtn from '../../../../Components/IconBtn';
import Confirm from '../../../../Components/Confirm';
import SelectRanking from './SelectRanking';
import { Article } from '../../../../Components/Article/Article';
import Picto from '../../../../Components/Picto';
import { iconSegment } from '../../../../Components/CustomIcon/CustomIcon';
import { EmptyState } from '../../../../Components/EmptyState/EmptyState';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const tableRowStyle = {
	gridTemplateColumns: '1fr 1.2fr 120px',
};

export default function MappedList() {

	const {
		listRankingSegments,
		handleDeleteSegment,
		sureToDelete,
		setSureToDelete,
		segmentType,
		segmentGroups,
		getEditURL,
		setaddNewExternalAudience,
		handleDeleteSegmentRankingGroup,
		handleReorderSegments
	} = useSegmentGroupEditorContext();

	const [rankingSegments, setRankingSegments] = useState<SegmentRankingApiType>();
	const [isEmpty, setIsEmpty] = useState<boolean>(false);
	const [hasUnMappedSegments, setHasUnMappedSegments] = useState<boolean>(false);
	const [confirmResetIsOpen, setConfirmResetIsOpen] = useState<boolean>(false);

	const handleOnDragEnd = (result) => {
		if (!result.destination || !rankingSegments || rankingSegments.segments.length == 1) return;
		const cloneSegments = [...rankingSegments.segments];
		const items = Array.from(cloneSegments);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		if (rankingSegments) {			
			const newSegmentRanking = { ...rankingSegments, segments: items };
			handleReorderSegments(newSegmentRanking);
			setRankingSegments(newSegmentRanking);
		}
	};

	useEffect(() => {
		if (listRankingSegments && listRankingSegments.length > 0) {
			setRankingSegments(listRankingSegments[0]);
		}
	}, [listRankingSegments]);

	useEffect(() => {
		const nbAssoc = rankingSegments?.segments?.length || 0;
		setIsEmpty(nbAssoc === 0);
		if (segmentType === 'Beyable') {
			setHasUnMappedSegments(segmentGroups && segmentGroups.length > 0);
		} else {
			setHasUnMappedSegments(false);
		}
	}, [rankingSegments, segmentGroups, segmentType]);

	if (!segmentType) return null;

	return (
		<>
			<Confirm
				isOpen={sureToDelete}
				title={'Do you really want to dissociate this segment?'}
				confirmText={'Dissociate'}
				confirmColor="alert"
				confirmCallback={() => handleDeleteSegment()}
				onClose={() => setSureToDelete(false)}
			/>

			<Article
				innerSize='l'
				title={
					<div className='flex'>
						<div className='flex_item_full'>Associated segments</div>
						{rankingSegments && rankingSegments.id &&
							<div className='flex_item_fix ml_20 s_13 grey_2'>
								Segmentation ID: <span className='fw_normal'>{rankingSegments.id}</span>
								<IconBtn
									icon="fas fa-ban"
									tooltip="Reset segmentation"
									size="s"
									onClick={() => setConfirmResetIsOpen(true)}
									hoverColor="alert"
									theme="dark"
									className="ml_10"
								/>
								<Confirm
									isOpen={confirmResetIsOpen}
									title={'Do you really want to reset segmentation?'}
									text={'This action is irreversible'}
									confirmInput='RESET'
									confirmInputLabel='Please write "RESET" in the text field.'
									confirmText={'Reset'}
									confirmColor="alert"
									confirmCallback={() => handleDeleteSegmentRankingGroup(rankingSegments.id || '')}
									onClose={() => setConfirmResetIsOpen(false)}
								/>
							</div>
						}
					</div>
				}>

				{!hasUnMappedSegments && isEmpty &&
					<EmptyState
						icon="fas fa-unlink"
						title="No associated segments yet"
						text='Please create a new segment'
						textSize='l'
						verticalSize='l'
						primaryAction={
							segmentType === 'Beyable' ?
								<Btn
									href={getEditURL()}
									message="New segment"
								/>
								:
								<Btn
									onClick={() => setaddNewExternalAudience(true)}
									message="New segment"
								/>
						}
					/>
				}

				{hasUnMappedSegments && isEmpty &&
					<EmptyState
						icon="fas fa-unlink"
						title="No associated segments yet"
						text="Start by associate a segment bellow with a ranking rule, or create a new segment"
					/>
				}

				{!isEmpty &&
					<TableGridContainer>
						<TableRowItem specialClasseName={'table_head_row'} styleObj={tableRowStyle}>
							<TableColItem>
								Segment
							</TableColItem>
							<TableColItem>
								Ranking rule
							</TableColItem>
							<TableColItem></TableColItem>
						</TableRowItem>
						<DragDropContext onDragEnd={(e) => handleOnDragEnd(e)}>
							<Droppable droppableId='segment-id' direction="vertical">
								{(provided, _snapshot) => (
									<div
										{...provided.droppableProps}
										ref={provided.innerRef}
									>

										{rankingSegments && rankingSegments.segments.map((seg, i) =>
											<Fragment key={seg.segmentId}>

												<Draggable 
													key={i} 
													draggableId={seg.segmentId} 
													index={i} 
													isDragDisabled={segmentType!=='Beyable'}
												>

													{(provided, _snapshot) => (
														<div
															key={seg.segmentId}
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps} 
														>

															{segmentType === 'Beyable' ?
																<SegmentRowBeyable segment={seg} />
																:
																<SegmentRowCustom segment={seg} />
															}

														</div>
													)}
												</Draggable>

											</Fragment>
										)}

										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>


					</TableGridContainer>
				}
			</Article>
		</>
	);
}

function SegmentRowBeyable({
	segment
}): JSX.Element {

	const {
		onDelete,
		segmentGroups,
		$routeParams,
		accountId
	} = useSegmentGroupEditorContext();

	const nameSegment = segmentGroups.find(seg => seg.id === segment.segmentId);
	const getEditURL = (segmentGroupId: string): string => {
		const ka = $routeParams?.ka ? `&ka=${accountId}` : '';
		const provider = '&provider=ranking';
		return `/Insight/segment/edit?id=${segmentGroupId}${provider}${ka}`;
	};

	return (
		<TableRowItem specialClasseName={'table_body_row'} styleObj={tableRowStyle}>
			<TableColItem>
				<div className='flex flex flex_item_full'>
					<div className='flex_item_fix mr_15'>
						<Picto
							size="l"
							iconCustom={iconSegment}
							color={'green'}
						/>
					</div>
					<div className='flex_item_full'>
						<div className='table_name'>{nameSegment?.name}</div>
						<div className='s_11 grey_3 mt_3'>{segment.segmentId}</div>
					</div>
					<div className='flex_item_fix ml_10 mr_10'>
						<i className='fas fa-arrow-right grey_5'></i>
					</div>
				</div>
			</TableColItem>
			<TableColItem>
				<SelectRanking
					rule={segment.segmentItems[0].ranking}
					mode={'mapped'}
					name={segment.segmentId}
				/>
			</TableColItem>
			<TableColItem align="right">
				<div className='btn_group_xs'>
					<Btn
						icon="fas fa-times-circle"
						tooltip='Dissociate'
						color="secondary"
						style="ghost_outline"
						size="xs"
						light={true}
						onClick={() => onDelete(segment.segmentId)}
					/>
					<Btn
						icon="fas fa-pen"
						tooltip="Edit segment definition"
						color="secondary"
						style="ghost_outline"
						size="xs"
						light={true}
						href={getEditURL(segment.segmentId)}
					/>
				</div>
			</TableColItem>
		</TableRowItem>
	);
}

function SegmentRowCustom({
	segment
}): JSX.Element {

	const {
		onDelete,
	} = useSegmentGroupEditorContext();

	return (
		<TableRowItem specialClasseName={'table_body_row'} styleObj={tableRowStyle}>
			<TableColItem>
				<div className='flex flex flex_item_full'>
					<div className='flex_item_fix mr_15'>
						<Picto
							size="l"
							iconCustom={iconSegment}
							color={'orange'}
						/>
					</div>
					<div className='flex_item_full'>
						<div className='table_name'>{segment.segmentId}</div>
					</div>
					<div className='flex_item_fix ml_10 mr_10'>
						<i className='fas fa-arrow-right grey_5'></i>
					</div>
				</div>
			</TableColItem>
			<TableColItem>
				<SelectRanking
					rule={segment.segmentItems[0].ranking}
					mode={'mapped'}
					name={segment.segmentId}
				/>
			</TableColItem>
			<TableColItem align="right">
				<div className='btn_group_xs'>
					<Btn
						icon="fas fa-times-circle"
						tooltip='Dissociate'
						color="secondary"
						style="ghost_outline"
						size="xs"
						light={true}
						onClick={() => onDelete(segment.segmentId)}
					/>
				</div>
			</TableColItem>
		</TableRowItem>
	);
}
