import React from 'react';
import styles from './Card.module.css';
// IconText Component
interface IconTextProps {
    title: string;
    icon: string;
}

const IconText: React.FC<IconTextProps> = ({ title, icon }) => (
	<div className={styles.headerCat}>
		<div className={styles.headerCatIcon}>{icon}</div>
		<div className={styles.headerCatLabel}>{title}</div>
	</div>
);
export default  IconText;