import React, { useState, useContext } from 'react';
import { BusinessOperationOrchestrationContext } from '../Context';
import Btn from '../../../../Components/Btn';
import Confirm from '../../../../Components/Confirm';

const SavePriorizationControls = ({ displaySave = true }) => {
	const {
		handleCancelPriorization,
		handleSavePriorization,
		isDirty
	} = useContext(BusinessOperationOrchestrationContext);

	const [askConfirmExit, setAskConfirmExit] = useState(false);

	const cancelClicked = () => {
		if (isDirty) {
			setAskConfirmExit(true);
			return;
		}
		handleCancelPriorization();
	};

	const confirmExit = () => {
		handleCancelPriorization();
	};

	return (
		<>
			{displaySave ?
				<>
					<Btn
						onClickFunction={cancelClicked}
						message="Cancel"
						style="ghost"
						color="secondary"
					/>
					<Btn
						onClickFunction={handleSavePriorization}
						disabled={!isDirty}
						message="Save"
					/>
				</>
				:
				<Btn
					onClickFunction={cancelClicked}
					message="Close"
				/>
			}

			<Confirm
				isOpen={askConfirmExit}
				onClose={(e) => setAskConfirmExit(false)}
				title="Quit operations priority"
				text="Are you sure you want to quit without saving?"
				cancelText="Stay"
				confirmText="Quit without saving"
				confirmCallback={(e) => confirmExit()}
			/>
		</>
	);
};

export default SavePriorizationControls;