import React, { useState, useEffect } from 'react'
import {

	replaceId,
	demoteId,
	promoteId
} from '../../Ranking/utils';
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
	DragOverlay
} from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy,
	verticalListSortingStrategy // <== doesn't break if this is rectSortingStrategy
} from "@dnd-kit/sortable";
import { useRankingContext } from '../../Ranking/context/RankingContextProvider'
import './DraggableContainer.css'
import { SortableCard } from './SortableCard';
import TableGridContainer from '../TableGridContainer';
import TableRowItem from '../TableRowItem';
import TableColItem from '../TableColItem';
const optionCssList = {
	gridTemplateColumns: '6fr 2fr 2f 2fr',
	userSelect: 'none',
}
export default function DraggableContainer({
	elementToMap,
	handleOnDragEnd,
	type,
	dimension
}) {

	const {
		rankingObject,
		deletePromotedProduct,
		deleteDemotedProduct,
		idInClipBoard,
		copyToClipBoard
	} = useRankingContext();
	const [items, setItems] = useState([]);
	useEffect(() => {
		setItems(elementToMap)
	}, [elementToMap])

	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
	const [activeId, setActiveId] = useState(null);

	function handleDragStart(event) {
		setActiveId(event.active.id);
	};
	function handleDragEnd(event) {
		const { active, over } = event;
		if (active.id !== over.id) {
			setItems((items) => {
				const oldIndex = items.indexOf(items.find(el => el.id === active.id));
				const newIndex = items.indexOf(items.find(el => el.id === over.id));
				handleOnDragEnd(arrayMove(items, oldIndex, newIndex))
				return arrayMove(items, oldIndex, newIndex);

			});
		}
	}
	function deletPromoteProductById(id) {
		const idsPromoted = rankingObject.transformations.find(el => el.collationId === promoteId)
		const oldArrayOfPromotedProducts = [...idsPromoted['params'][0]['value']['stringValues']];
		deletePromotedProduct(oldArrayOfPromotedProducts.filter(el => el !== id));
	}
	function deletDemoteProductById(id) {
		const idsDemoted = rankingObject.transformations.find(el => el.collationId === demoteId)
		const oldArrayOfDemotedProducts = [...idsDemoted['params'][0]['value']['stringValues']];
		deleteDemotedProduct(oldArrayOfDemotedProducts.filter(el => el !== id));
	}
	return (<>
		<DndContext
			autoScroll={false}
			sensors={sensors}
			onDragStart={handleDragStart}
			collisionDetection={closestCenter}
			onDragEnd={(e) => handleDragEnd(e)}
		>
			<SortableContext items={elementToMap} strategy={rectSortingStrategy}>
				<>
					{type === 'grid' && 
						<div className='collection_card_list'>
							{elementToMap && elementToMap.map((it, index) => (
								<SortableCard
									product={it}
									dimension={dimension}
									key={it.id}
									name={it.id}
									index={index}
									deletePromotedProduct={deletPromoteProductById}
									deleteDemotedProduct={deletDemoteProductById}
									idInClipBoard={idInClipBoard}
									copyToClipBoard={copyToClipBoard}
									type={type}
								/>
							))}
						</div>
					}
					{type !== 'grid' && <>
						{elementToMap && elementToMap.length !== 0 && < TableGridContainer
							specialClasseName="table_grid_emerch table_grid_emerch_products_list"
							styleObj={optionCssList}
						>
							<TableRowItem specialClasseName={''}
								styleObj={optionCssList}>
								<TableColItem specialClasseName={' '}>
									Product name
								</TableColItem>
								<TableColItem specialClasseName={''}>
									Price
								</TableColItem>
								<TableColItem specialClasseName={''}>
									Id
								</TableColItem>
								<TableColItem specialClasseName={''}>

								</TableColItem>

							</TableRowItem>
							{elementToMap && elementToMap.map((it, index) => (
								<SortableCard
									product={it}
									dimension={dimension}
									key={it.id}
									name={it.id}
									index={index}
									deletePromotedProduct={deletPromoteProductById}
									deleteDemotedProduct={deletDemoteProductById}
									idInClipBoard={idInClipBoard}
									copyToClipBoard={copyToClipBoard}
									type={type}

								/>
							))}
						</TableGridContainer>}
					</>
					}
				</>
			</SortableContext>
		</DndContext>

	</>
	)
}
