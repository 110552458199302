import React, {useEffect, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import classes from './Dropzone.module.css';

const acceptStyle = {
    backgroundColor: "#5DA9EA11"
}

export default function Dropzone(props) {
	const {
	  acceptedFiles,
	  getRootProps,
	  getInputProps,
	  isDragActive,
	  isDragAccept,
	  isDragReject,
	  open
	} = useDropzone();
  
	const style = useMemo(() => ({
		...(isDragAccept ? acceptStyle : {})
	}),[isDragActive, isDragReject]);

	useEffect(function(){
		if(acceptedFiles.length){
		props.setDisplayImage({ isActive: true, files: acceptedFiles });
		}
	},[acceptedFiles]);

	return (
		<div {...getRootProps({style})}>
			<input {...getInputProps()} />
			<div className={`${classes.content} ${isDragActive ? 'dragActive' : ''} ${classes.uploader} mb_10`}>
				<svg className={classes.icon} width="690" height="518" viewBox="0 0 690 518" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M39.24 0.284154C17.5232 0.284154 0.0400391 17.7674 0.0400391 39.4842V317.804L193.338 145.324V145.319C195.591 143.251 198.402 141.89 201.421 141.398C206.644 140.654 211.894 142.59 215.388 146.544L354.306 299.914L463.084 207.792C468.843 202.87 477.297 202.767 483.172 207.552L575.539 282.769C577.109 282.693 578.613 282.529 580.193 282.529C591.071 282.529 601.489 284.093 611.55 286.696V39.4841C611.55 17.7673 594.067 0.284058 572.35 0.284058H39.2305L39.24 0.284154ZM368.52 94.3627C390.171 94.3627 407.72 111.912 407.72 133.563C407.72 155.214 390.171 172.763 368.52 172.763C346.869 172.763 329.32 155.214 329.32 133.563C329.32 111.912 346.869 94.3627 368.52 94.3627ZM202.648 179.129L0.0400391 359.939V400.117C0.0400391 421.834 17.5232 439.317 39.24 439.317H458.918C456.315 429.255 454.751 418.837 454.751 407.96C454.751 354.355 488.543 308.812 535.847 290.853L473.372 240.131L362.876 333.477C356.51 338.869 347.033 338.333 341.319 332.252L202.648 179.129ZM580.2 298.2C519.579 298.2 470.443 347.343 470.443 407.957C470.443 468.579 519.586 517.714 580.2 517.714C640.821 517.714 689.957 468.572 689.957 407.957C689.957 347.336 640.814 298.2 580.2 298.2ZM580.2 337.4C588.863 337.4 595.879 344.422 595.879 353.079V392.279H635.079C643.741 392.279 650.757 399.3 650.757 407.957C650.757 416.62 643.735 423.636 635.079 423.636H595.879V462.836C595.879 471.498 588.857 478.514 580.2 478.514C571.538 478.514 564.521 471.492 564.521 462.836V423.636H525.321C516.659 423.636 509.643 416.614 509.643 407.957C509.643 399.295 516.665 392.279 525.321 392.279H564.521V353.079C564.521 344.416 571.543 337.4 580.2 337.4Z" fill="#CACCD8"/>
				</svg>
				<p className={`${classes.description} s_13 grey_2 fw_normal`} onClick={open}>
					Drag and drop your image here<br/>
					or click to browse files
				</p>
			</div>
		</div>
	);
}
