import HttpServices from './HttpServices';
import axios from 'axios';

export default class ProductTaggingServices {

	constructor($http, authServices, accountId) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
		this.accountId = accountId;
	}
	getProductCollation(category, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-collation/?context=${category}`,
			callbackSuccess, callbackError);
	}
	getTaggingList(valueInSearchBar, dataFiltered, descSort, paginationOptions, callbackSuccess, callbackError) {
		this.HttpServices.get(
			`${this.BO_API_URL}${this.accountId}/product-tagging/?offset=${paginationOptions.offset}&limit=${paginationOptions.limit}&searchTerm=${valueInSearchBar}&sortBy=${dataFiltered}&descSort=${descSort}`,
			callbackSuccess,
			callbackError
		);
	}

	createTagging(taggingRule, callbackSuccess, callbackError) {
		this.HttpServices.post(
			`${this.BO_API_URL}${this.accountId}/product-tagging`,
			taggingRule,
			callbackSuccess,
			callbackError
		);
	}
	getTaggingById(taggingId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/product-tagging/${taggingId}`, callbackSuccess, callbackError);
	}
	deleteTagging(taggingId, callbackSuccess, callbackError) {
		this.HttpServices.delete(
			`${this.BO_API_URL}${this.accountId}/product-tagging/${taggingId}`,
			callbackSuccess,
			callbackError
		);
	}
	putTaggingById(taggingRule, taggingId, callbackSuccess, callbackError) {
		this.HttpServices.put(
			`${this.BO_API_URL}${this.accountId}/product-tagging/${taggingId}`,
			taggingRule,
			callbackSuccess,
			callbackError
		);
	}
	getProductListPreview(options, paginationOptions, taggingRule, callbackSuccess, callbackError) {
		// ranking.context = 'category'
		let previewTenant = '';
		if (options.hasOwnProperty('previewTenant')) {
			previewTenant = `&previewTenant=${options.previewTenant}`;
		}
		let offset = '';
		offset = `${'&'}offset=${paginationOptions.poffset}`;

		let deviceType = '';
		if (options.hasOwnProperty('deviceType')) {
			const sign = '?';
			deviceType = `${sign}deviceType=${encodeURIComponent(options.deviceType)}`;
		}
		this.HttpServices.post(
			`${this.BO_API_URL}${this.accountId}/product-tagging-preview/
			${deviceType}		
			&limit=${paginationOptions.plimit}
			${offset}${previewTenant}`,
			{
				...taggingRule
			},
			callbackSuccess,
			callbackError
		);
	}

}
