import React, { Component } from 'react';
import { react2angular } from 'react2angular';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styles from './FormCustomContainer.module.css';
import controlTypes from './controlTypes';
import { reorderItems } from './FormCustomUtils';

import FormCustomPanel from './FormCustomPanel';

const isEmptyForm = function (formValue) {
	var hasNoSection = function (form) { return form.sections.length === 0; };
	var hasSingleEmptySection = function (form) { return form.sections.length === 1 && form.sections[0].panels.length === 0; };
	var hasSingleNotSetSection = function (form) { return form.sections.length === 1 && form.sections[0].panels.length === 1 && form.sections[0].panels[0].type === ''; };

	return (!formValue.sections) || hasNoSection(formValue) || hasSingleEmptySection(formValue) || hasSingleNotSetSection(formValue);
}

export default class FormCustomContainer extends Component {
	constructor(props) {
		super(props);
		this.$scope = props.$scope.$parent.$parent;
		const self = this;

		this.state = {
			slide: null,
			sections: [{
				id: 1,
				canAddPanel: true,
				panels: []
			}],
		}

		this.props.onLoad(function (data) {
			var parsePropertyValue = function (properties, propName) {
				if (properties && properties.hasOwnProperty(propName)) {
					var p = properties[propName];
					return JSON.parse(p);
				}
				else return {};
			};

			const slideFormValue = parsePropertyValue(data.Properties, 'FormCustom');
			var formValue = isEmptyForm(slideFormValue)
				? { sections: [{ id: 1, canAddPanel: true, panels: [] }] }
				: {
					sections: slideFormValue.sections.map(function (section) {
						return {
							id: section.id,
							canAddPanel: true,
							panels: section.panels.map(function (panel) {
								return {
									id: panel.id,
									name: panel.name,
									type: panel.type,
									question: panel.question,
									placeholder: panel.placeholder,
									controls: panel.controls,
									required: panel.required,
									controlTypes: controlTypes,
									buttonType: panel.buttonType,
									value: panel.value
								}
							})
						}
					})
				};

			self.setState({ slide: data });
			self.setState(formValue);
		});
	}

	getNextSectionId(sectionId) {
		const currentSectionIndex = this.state.sections.findIndex(section => section.id === sectionId);
		const nextSection = this.state.sections[currentSectionIndex + 1];

		return nextSection ? nextSection.id : null;
	}

	addSection() {
		const id = Math.round(Math.random() * 10000);

		this.setState(state => {
			const sections = [...state.sections, {
				id,
				canAddPanel: true,
				panels: []
			}];
			return { sections };
		});
	}

	removeSection(sectionId) {
		this.setState(state => {
			const sections = state.sections.filter(section => sectionId !== section.id);
			return { sections };
		});
	}

	canAddButtonType(sectionId) {
		const currentSection = this.state.sections.find(section => section.id === sectionId);
		const panelsInSection = currentSection.panels.filter(panel => panel.type !== '').length;
		const panelWithButton = currentSection.panels.find(panel =>
			panel.type === 3 ||
			(panel.controlTypes.find(controlType => controlType.id === 3) && !panel.type)
		);

		return panelsInSection && !panelWithButton;
	}

	addPanel(sectionId) {
		this.setState(state => {
			const sections = state.sections.map(section => {
				if (section.id === sectionId) {
					const panels = [...section.panels, {
						id: Math.round(Math.random() * 10000),
						name: '',
						type: '',
						question: '',
						placeholder: '',
						controls: [],
						required: false,
						controlTypes: [
							...controlTypes,
							...(this.canAddButtonType(sectionId) ? [{
								id: 3,
								name: 'button',
								icon: 'square',
								multiple: false
							}] : [])
						],
						value: '',

					}];

					const hasTypeSelectionOpened = panels.find(panel => panel.type === '');

					return { id: section.id, canAddPanel: !hasTypeSelectionOpened, panels };
				}

				return section;
			});

			return { sections };
		});
	}

	removePanel(id, sectionId) {
		this.setState(state => {
			let sections = state.sections.map(section => {
				if (section.id === sectionId) {
					const panels = section.panels.filter(panel => id !== panel.id);
					const hasTypeSelectionOpened = panels.find(panel => panel.type === '');

					return { id: section.id, canAddPanel: !hasTypeSelectionOpened, panels };
				}
				return section;
			});

			return { sections };
		}, () => {
			const currentSection = this.state.sections.find(section => section.id === sectionId);

			// Remove section if empty
			if (currentSection.panels.length < 1 && this.state.sections.length > 1) {
				this.removeSection(sectionId);
			}
		});
	}

	duplicatePanel(id, sectionId) {
		this.setState(state => {
			const sections = state.sections.map(section => {
				if (section.id === sectionId) {
					const isDuplicatedPanel = panel => panel.id === id;
					const duplicatedPanel = section.panels.find(isDuplicatedPanel);
					const duplicatedPanelIndex = section.panels.findIndex(isDuplicatedPanel);
					let panels = section.panels;

					panels.splice(duplicatedPanelIndex + 1, 0, {
						id: Math.round(Math.random() * 10000),
						type: duplicatedPanel.type,
						question: duplicatedPanel.question,
						placeholder: duplicatedPanel.placeholder,
						controls: duplicatedPanel.controls,
						required: duplicatedPanel.required,
						controlTypes: duplicatedPanel.controlTypes,
						buttonType: duplicatedPanel.buttonType,
						name: duplicatedPanel.name,
						value: duplicatedPanel.value,

					});

					return { id: section.id, canAddPanel: section.canAddPanel, panels }
				}
				return section;
			});

			return { sections };
		});
	}

	updatePanel(id, sectionId, stateToUpdate) {
		this.setState(state => {
			const sections = state.sections.map(section => {
				if (section.id === sectionId) {

					const panels = section.panels.map(panel => {
						if (panel.id === id) {
							return { ...panel, ...stateToUpdate }
						}
						return panel;
					});

					const hasTypeSelectionOpened = panels.find(panel => panel.type === '');
					return { id: section.id, canAddPanel: !hasTypeSelectionOpened, panels };
				}
				return section;
			});

			return { sections }
		});
	}

	onDragEnd(result, sectionId) {
		if (!result.destination) {
			return;
		}

		const sections = this.state.sections.map(section => {
			if (section.id === sectionId) {
				const panels = reorderItems(
					section.panels,
					result.source.index,
					result.destination.index
				);
				return { id: section.id, panels };
			}
			return section;
		});

		this.setState({ sections });
	}

	addButtonType() {
		this.setState(state => {
			const controlTypes = [...state.controlTypes, {
				id: 3,
				name: 'button',
				icon: 'square',
				multiple: false
			}];
			return { controlTypes };
		});
	}

	componentDidUpdate() {

		const sections = this.state.sections.map(section => {
			return {
				id: section.id,
				panels: section.panels.filter(function (p) { return p.type !== '' }).map(function (panel) {
					return {
						id: panel.id,
						name: panel.name,
						type: panel.type,
						question: panel.question,
						placeholder: panel.placeholder,
						controls: panel.controls,
						required: panel.required,
						buttonType: panel.buttonType,
						value: panel.value
					}
				})
			}
		}).filter(function (s) { return s.panels.length > 0 });

		this.$scope.setFormCustomData(JSON.stringify({ sections: sections }), this.state.slide);
	}

	render() {
		return <section>
			{
				this.state.sections.map((section, i) =>
					<div key={section.id} className={`${styles.section} ${i > 0 ? styles.additionalSection : ''}`}>
						<h5 className={styles.sectionTitle}>Form fields {i > 0 && ` - Section ${i + 1}`}</h5>
						<DragDropContext onDragEnd={(result) => this.onDragEnd(result, section.id)}>
							<Droppable droppableId={`droppable-${section.id}`}>
								{
									(provided, snapshot) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}>
											{
												section.panels.map((panel, j) =>
													<FormCustomPanel
														{...panel}
														key={panel.id}
														index={j}
														sectionId={section.id}
														sections={{
															current: section.id,
															next: this.getNextSectionId(section.id)
														}}
														displayRequired={panel.type !== 3}
														styles={styles}
														onSectionAdd={this.addSection.bind(this)}
														onSectionRemove={this.removeSection.bind(this)}
														onContentChange={this.updatePanel.bind(this)}
														onRemove={this.removePanel.bind(this)}
														onDuplicate={this.duplicatePanel.bind(this)} />
												)}
											{provided.placeholder}
										</div>
									)}
							</Droppable>
						</DragDropContext>

						<button
							disabled={!section.canAddPanel}
							className={styles.addBtn}
							onClick={() => this.addPanel(section.id)}>
							<i className="fa fa-plus"></i>
							<span>Ajouter un champ</span>
						</button>
					</div>
				)
			}
		</section>
	}
}

angular
	.module('beyableSaasApp.Campaigns.Create.Controllers')
	.component('formCustom', react2angular(FormCustomContainer, ['onLoad'], ['$scope']));
