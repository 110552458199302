import React, { useState } from 'react';
import { onSiteContext } from '../context';
import MonthSelectorOnSite from './MonthSelectorOnSite';
import Switch from '../../../../Components/Switch';
import useFeature from '../../../../../flags/useFeature';
import { Trans, useTranslation } from 'react-i18next';
import renderJsxTag from '../../../../Translation/translation-utils';
export default function FilterCalendarOnSite({ setdateRange, dateRange, checkTheDayOfWeek, dateOfTheSingleDatePicker, setDateOfTheSingleDatePicker }) {

	const handleOnDateChange = (e) => {
		const myDatePickerToCheckForCalendar = moment(e.startOf('day'));
		setDateOfTheSingleDatePicker(e);
		checkTheDayOfWeek(myDatePickerToCheckForCalendar);
	};

	const {
		hideFilRouge,
		setHideFilRouge,
		nbOFOnGoingCp
	} = onSiteContext();

	const { features } = useFeature();
	const [t, i18n] = useTranslation('common');
	return (
		<div className="flex flex_justify_between">
			<div className="flex_item_full">
				<MonthSelectorOnSite
					dateOfTheSingleDatePicker={dateOfTheSingleDatePicker}
					handleOnDateChange={(e) => handleOnDateChange(e)}
					dateSelected={dateRange}
					setdateRange={setdateRange}
					checkTheDayOfWeek={(e, op) => checkTheDayOfWeek(e, op)}
				/>
			</div>

			{
				(!!nbOFOnGoingCp) &&
				<>
					<i className="far fa-lightbulb"></i>
					<p>
						{t('FilterCalendarOnSite.ongoing',{
						nbOFOnGoingCp : nbOFOnGoingCp,
						s: nbOFOnGoingCp > 1 ? 's' : ''
						})}
						</p>
				</>
			}
			{
				features.includes('hide-permanent-cp') &&
				<Switch
					onChange={()=>{setHideFilRouge(!hideFilRouge);}}
					data-ffl="hide-permanent-cp"
					size="s"
					position="right"
					align="center">
					<span className="s_14 grey_0">Show only campaigns with end date</span>
				</Switch>
			}
		</div>
	);
}


