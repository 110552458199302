import HttpServices from './HttpServices';
import {
	SegmentGroupApiType,
	SegmentGroupWithTriggersApiType,
	CreateSegmentGroupRequest,
	SegmentGroupStatus,
	UpdateSegmentGroupRequest
} from './SegmentGroupServicesTypes';

export default class SegmentGroupServices {
	HttpServices: HttpServices;
	BO_API_URL: string;
	accountId: string;

	constructor(accountId, $http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !(window as any).BEYABLE_env || !(window as any).BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${(window as any).BEYABLE_env.BO_API_URL}`;
		}
		this.accountId = accountId;
	}

	getSegmentGroups(callbackSuccess: ((segmentGroups: SegmentGroupApiType[]) => void), callbackError: ((error: string) => void)): void {
		this.HttpServices.get(`${this.BO_API_URL}segmentgroup/${this.accountId}`, callbackSuccess, callbackError);
	}

	getSegmentGroup(segmentGroupId: string, callbackSuccess: ((segmentGroup: SegmentGroupWithTriggersApiType) => void), callbackError: ((error: string) => void)): void {
		this.HttpServices.get(`${this.BO_API_URL}segmentgroup/${this.accountId}/${segmentGroupId}`, callbackSuccess, callbackError);
	}

	updateSegmentGroupStatus(segmentGroupId: string, status: SegmentGroupStatus, callbackSuccess: (() => void), callbackError: ((error: string) => void)): void {
		this.HttpServices.put(`${this.BO_API_URL}segmentgroup/${this.accountId}/${segmentGroupId}`, { status: status }, callbackSuccess, callbackError);
	}
	deleteSegmentGroup(segmentGroupId: string, callbackSuccess: (() => void), callbackError: ((error: string) => void)): void {
		this.HttpServices.delete(`${this.BO_API_URL}segmentgroup/${this.accountId}/${segmentGroupId}`, callbackSuccess, callbackError);
	}

	setSegmentGroup(segmentGroup: CreateSegmentGroupRequest,
		callbackSuccess: (() => void),
		callbackShouldSetSaveMode: (() => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.post(`${this.BO_API_URL}segmentgroup/${this.accountId}`, segmentGroup, callbackSuccess, result => {
			if (result.status === 409) {
				callbackShouldSetSaveMode();
			} else {
				callbackError(result);
			}
		});
	}
	
	updateSegmentGroup(segmentGroup: UpdateSegmentGroupRequest,segmentGroupId,
		callbackSuccess: (() => void),
		callbackShouldSetSaveMode: (() => void),
		callbackError: ((error: string) => void)): void {
		this.HttpServices.put(`${this.BO_API_URL}segmentgroup/${this.accountId}/${segmentGroupId}`, segmentGroup, callbackSuccess, result => {
			if (result.status === 409) {
				callbackShouldSetSaveMode();
			} else {
				callbackError(result);
			}
		});
	}
}

