import React, { useState, useEffect, useContext, Fragment } from 'react';
import './ResultsHeaderComponent.css';
import { getIndicatorByKey } from '../Indicators';
import './AddKpiComponent.css';
import AddKpiComponent from './AddKpiComponent';
import ReactTooltip from 'react-tooltip';
import Loading from './Loading';
import { formatIndicatorDisplayLabel } from '../IndicatorsUtil';
import { reportingCampaignContext } from '../Context/ContextReportingCampaign';
import { Trans, useTranslation } from 'react-i18next';
export default function ResultsHeaderComponent(props) {
	const [t, i18n] = useTranslation('common');
	const {
		setPrimaryIndicatorSummaryExport,
		setSecondaryIndicatorSummaryExport,
	} = useContext(reportingCampaignContext);
	const [newTabtoMaped, setnewTabtoMaped] = useState();
	const [summaryFirstIndicators, setSummaryFirstIndicators] = useState();
	const [summaryIndicatorsArray, setSummaryIndicatorsArray] = useState([]);
	const [modalIsVisible, setModalIsVisible] = useState(false);

	const [rendered, setRendered] = useState(false);
	const [newSecondaryIndicators, setNewSecondaryIndicators] = useState([]);
	const [newSecondaryIndicatorsToGet, setNewSecondaryIndicatorsToGet] = useState([]);

	const [loading, setLoading] = useState(true);
	const changeLoading = () => {
		if (summaryIndicatorsArray) {
			setLoading(false);
		}
	};
	useEffect(() => {
		changeLoading();
	}, [summaryIndicatorsArray, newTabtoMaped]);

	/**
	 * Change Bolean of Show modal
	 */
	const ShowModal = () => {
		setModalIsVisible(!modalIsVisible);
	};
	/**
	 * Change Bolean of HideModal
	 */
	const HideModal = () => {
		setModalIsVisible(!modalIsVisible);
	};
	useEffect(() => {
		if (summaryIndicatorsArray.length !== 0) {
			setSecondaryIndicatorSummaryExport(summaryIndicatorsArray);
		}
	}, [summaryIndicatorsArray, newSecondaryIndicatorsToGet, newSecondaryIndicators]);
	/**
	 * getDataSecondaryIndicators Fetch Api first with the indicators and fetch the data
	 */
	const getDataSecondaryIndicators = () => {
		setSummaryIndicatorsArray([]);
		if (props.momentEndDate && props.DataSecondaryIndicators && props.SummaryFirstIndicators) {
			let dataSecondaryIndicatorsWithoutPrimaryIndicator = [];
			for (let y = 0; y < props.DataSecondaryIndicators.length; y++) {
				if (props.DataSecondaryIndicators[y].key !== props.SummaryFirstIndicators && props.DataSecondaryIndicators[y].displayModes.includes('Summary')) {
					dataSecondaryIndicatorsWithoutPrimaryIndicator.push(props.DataSecondaryIndicators[y].key);
				}
			}
			if (dataSecondaryIndicatorsWithoutPrimaryIndicator.length === 0) {
				return;
			}
			props.getAllSecondaryIndicators(dataSecondaryIndicatorsWithoutPrimaryIndicator.join(','), props.device && props.device,
				Data => {
					let dataToRegister;
					if (props.hasVariations) {
						dataToRegister = Data.map(element => {
							if (element.expandedSeries.find(serie => serie.key.variation === props.variationSelected)) {
								return (
									{
										...element.expandedSeries.find(serie => serie.key.variation === props.variationSelected).series[0],
										variationKey: element.expandedSeries.find(serie => serie.key.variation === props.variationSelected).key,
										key: element.indicator,
										label: getIndicatorByKey(element.indicator).label,
										hideRate: getIndicatorByKey(element.indicator).hideRate,
										hideUplift: getIndicatorByKey(element.indicator).hideUplift,
										rateSign: getIndicatorByKey(element.indicator).rateSign,
										device: props.device
									});
							} else {
								return ({
									key: element.indicator,
									label: getIndicatorByKey(element.indicator).label,
									hideRate: getIndicatorByKey(element.indicator).hideRate,
									hideUplift: getIndicatorByKey(element.indicator).hideUplift,
									rateSign: getIndicatorByKey(element.indicator).rateSign,
									device: props.device
								});
							}
						});
					} else {
						dataToRegister = Data.map(element => ({
							...element.series[0],
							key: element.indicator,
							label: getIndicatorByKey(element.indicator).label,
							hideRate: getIndicatorByKey(element.indicator).hideRate,
							hideUplift: getIndicatorByKey(element.indicator).hideUplift,
							rateSign: getIndicatorByKey(element.indicator).rateSign,
							device: props.device
						}));
					}
					setSummaryIndicatorsArray(dataToRegister);
				}, errorMessage => { console.log(errorMessage); });
		}
	};
	useEffect(() => {
		getDataSecondaryIndicators();
	}, [props.DataSecondaryIndicators, props.SummaryFirstIndicators, props.momentStartDate, props.device, props.variationSelected]);

	/**
	 * Get the data of the first Indicators in the Summary
	 */
	const getDataSummaryFirstIndicators = () => {
		if (props && props.momentEndDate && props.SummaryFirstIndicators) {
			let newLabel = getIndicatorByKey(props.SummaryFirstIndicators).label;
			props.loadSecondIndicator(props.SummaryFirstIndicators, props.device && props.device,
				Data => {
					let dataToRegister;
					if (props.hasVariations) {
						const dataExpandSeries = { ...Data.expandedSeries.find(serie => serie.key.variation === props.variationSelected) };
						if (dataExpandSeries && dataExpandSeries.series) {
							dataToRegister = {
								...dataExpandSeries.series[0],
								variationKey: Data.expandedSeries.find(serie => serie.key.variation === props.variationSelected).key,
								key: props.SummaryFirstIndicators && props.SummaryFirstIndicators,
								label: newLabel,
								hideRate: getIndicatorByKey(props.SummaryFirstIndicators).hideRate,
								hideUplift: getIndicatorByKey(props.SummaryFirstIndicators).hideUplift,
								rateSign: getIndicatorByKey(props.SummaryFirstIndicators).rateSign,
								device: props.device
							};
						} else {
							dataToRegister = {
								variationKey: null,
								key: props.SummaryFirstIndicators && props.SummaryFirstIndicators,
								label: newLabel,
								hideRate: getIndicatorByKey(props.SummaryFirstIndicators).hideRate,
								hideUplift: getIndicatorByKey(props.SummaryFirstIndicators).hideUplift,
								rateSign: getIndicatorByKey(props.SummaryFirstIndicators).rateSign,
								device: props.device
							};
						}
					} else {
						dataToRegister = ({
							...Data.series[0],
							key: props.SummaryFirstIndicators && props.SummaryFirstIndicators,
							label: newLabel,
							hideRate: getIndicatorByKey(props.SummaryFirstIndicators).hideRate,
							hideUplift: getIndicatorByKey(props.SummaryFirstIndicators).hideUplift,
							rateSign: getIndicatorByKey(props.SummaryFirstIndicators).rateSign,
							device: props.device
						});
					}
					setSummaryFirstIndicators(dataToRegister);
					setPrimaryIndicatorSummaryExport(dataToRegister);

				});
		}
	};

	useEffect(() => {
		getDataSummaryFirstIndicators();
	}, [props.SummaryFirstIndicators, props.datainformation, props.device, props.momentEndDate, props.variationSelected]);

	const getRateSign = (indicator) => indicator.rateSign || '%';

	/**
	 * 
	 * @param {Array with the new Keys of indicators in the Summary Kpi} newKeys 
	 */
	const reRender = (newKeys) => {
		setNewSecondaryIndicators(newKeys);
		setRendered(!rendered);
	};

	/**
	 * function for reload newsecondary indicators in summary
	 * Get the newSecondaryIndicators and Fetch APi with new key
	 * Delete the array of indicators at the end
	 */
	const loadNewSecondaryIndicator = () => {
		if (props.momentEndDate && newSecondaryIndicators.length !== 0) {
			props.getAllSecondaryIndicators(newSecondaryIndicators.join(','), props.device && props.device,
				Data => {
					let dataToRegister;
					if (props.hasVariations) {
						dataToRegister = Data.map(element => {
							if (element.expandedSeries.find(serie => serie.key.variation === props.variationSelected)) {
								return (
									{
										...element.expandedSeries.find(serie => serie.key.variation === props.variationSelected).series[0],
										variationKey: element.expandedSeries.find(serie => serie.key.variation === props.variationSelected).key,
										key: element.indicator,
										label: getIndicatorByKey(element.indicator).label,
										hideRate: getIndicatorByKey(element.indicator).hideRate,
										hideUplift: getIndicatorByKey(element.indicator).hideUplift,
										rateSign: getIndicatorByKey(element.indicator).rateSign,
										device: props.device
									});
							} else {
								return ({

									key: element.indicator,
									label: getIndicatorByKey(element.indicator).label,
									hideRate: getIndicatorByKey(element.indicator).hideRate,
									hideUplift: getIndicatorByKey(element.indicator).hideUplift,
									rateSign: getIndicatorByKey(element.indicator).rateSign,
									device: props.device
								});
							}
						});
					} else {
						dataToRegister = Data.map(element => ({
							...element.series[0],
							key: element.indicator,
							label: getIndicatorByKey(element.indicator).label,
							hideRate: getIndicatorByKey(element.indicator).hideRate,
							hideUplift: getIndicatorByKey(element.indicator).hideUplift,
							rateSign: getIndicatorByKey(element.indicator).rateSign,
							device: props.device
						}));
					}
					setNewSecondaryIndicatorsToGet(dataToRegister);
					setSummaryIndicatorsArray(dataToRegister);
				}, errorMessage => { console.log(errorMessage);	});
		}
		setNewSecondaryIndicatorsToGet([]);
	};
	useEffect(() => {
		loadNewSecondaryIndicator();
	}, [rendered, props.datainformation, props.device, props.momentEndDate, props.variationSelected]);

	const isANumber = (numb) => {
		let numberToFormat = numb;
		if (typeof numberToFormat === 'number') {
			const numberAfterComa = numberToFormat.toString().split('.')[1];
			if (numberAfterComa) {
				const firstNumberAfterComa = numberAfterComa.split('')[0];
				if (firstNumberAfterComa > 0) {
					return numberToFormat.toFixed(2);
				} else {
					return parseInt(numberToFormat.toFixed(2));
				}
			} else {
				return numberToFormat;
			}
		} else {
			return 'no data';
		}
	};
	/**
	 * function for the Array of Summary, filter without summary first indicators
	 */
	let newTabtoMap = () => {
		let newtab = [];
		if (newSecondaryIndicatorsToGet.length) {
			newtab = newSecondaryIndicatorsToGet && newSecondaryIndicatorsToGet.filter(item => item.key != summaryFirstIndicators && summaryFirstIndicators.key);
		}
		else {
			if (summaryFirstIndicators) {
				newtab = summaryIndicatorsArray.filter(item => item && item.key != summaryFirstIndicators.key);
			}
		}
		setSecondaryIndicatorSummaryExport(newtab.filter(el => el.key !== 'Add'));
		if (newtab && newtab.length < 7) {
			newtab.push(
				{
					key: 'Add',
					label: t('resultsCampaign.addKpi'),
					classAdd: true,
				}
			);
		}
		setnewTabtoMaped(
			newtab && newtab.map(
				(item, index) =>
					<Fragment key={index}>
						{item.classAdd ?
							<div className="kpi_item empty" key={index} onClick={ShowModal} >
								<div className="kpi_add">
									<i className="fas fa-plus"></i>
									{item.label ? item.label : item.key}
								</div>
							</div>
							:
							<>
								<div className="kpi_item">
									<div className="kpi_delete"
										data-tip={t('resultsCampaign.remove')}
										onClick={() => UpdateArrayOfSummaryIndicators(index)}
										data-for="custom-color-delete">
										<i className="fas fa-minus-circle" />
									</div>
									<p className="kpi_title"> {formatIndicatorDisplayLabel(item)} </p>
									{'valueDisplayedRate' in item ?
										<>
											<div className="kpi_row">
												<div className="kpi_col">
													<span className="kpi_main_value">
														{isANumber(item.valueDisplayedAbsolute)}
													</span>
												</div>
												{!item.hideRate && item.valueDisplayedRate != undefined &&
													<>
														<div className="kpi_col"></div>
														<div className="kpi_col">
															<div className="kpi_rate">{isANumber(item.valueDisplayedRate)} {getRateSign(item)}</div>
														</div>
													</>
												}
											</div>
											{!item.hideUplift && props.hasControlGroup && item.upliftRate != undefined &&
												<>
													<div className="kpi_row">
														<div className="kpi_col" data-tip={t('resultsCampaign.control')}
															data-for="kpi-info">
															<div className="kpi_secondary_value">
																{item.valueNotDisplayedAbsolute}
															</div>
														</div>
														{!item.hideRate && item.valueNotDisplayedRate != undefined &&
															<>
																<div className="kpi_col"></div>
																<div className="kpi_col" data-tip={t('resultsCampaign.control')} data-for="kpi-info">
																	<div className="kpi_rate">{isANumber(item.valueNotDisplayedRate)} {getRateSign(item)}</div>
																</div>
															</>
														}
													</div>
													<div className={getUpliftClass(item.upliftRate)} data-tip="Uplift" data-for="kpi-info">
														<span data-tip="Uplift" data-for="kpi-info">
															{item.upliftRate > 0 && '+'}
															{isANumber(item.upliftRate)} %
														</span>
													</div>
												</>
											}
										</>
										:
										<p className="kpi_no_data al_left">{t('resultsCampaign.NoData')} </p>
									}
									<ReactTooltip id='custom-color-delete' backgroundColor='rgba(238, 22, 22, 0.849)' delayHide={10} effect='float' />
									<ReactTooltip id="kpi-info" backgroundColor='black' effect='solid' delayShow={500} />
								</div>
							</>
						}
					</Fragment>
			)
		);
	};

	useEffect(() => {
		newTabtoMap();
	}, [summaryIndicatorsArray, newSecondaryIndicatorsToGet]);

	const removeArrayOfIndicators = () => {
		setSummaryIndicatorsArray([]);
		setNewSecondaryIndicatorsToGet([]);
	};
	useEffect(() => {
		removeArrayOfIndicators();
	}, [props.DataSecondaryIndicators]);

	const removeArrayOfFirstIndicator = () => {
		setSummaryFirstIndicators([]);
	};
	useEffect(() => {
		removeArrayOfFirstIndicator([]);
	}, [props.SummaryFirstIndicators]);
	/**
	 * Function for delete an indicator of the Summary
	 * @param {index of the indicators to delete} index 
	 */

	const UpdateArrayOfSummaryIndicators = (index) => {
		let arrayOfKeys = [];
		if (newSecondaryIndicatorsToGet.length !== 0) {
			const newArr = newSecondaryIndicatorsToGet.splice(index, 1);
			for (let i in newArr) {
				arrayOfKeys.push(newArr && newArr[i].key);
			}
			let summaryIndicators = { Keys: arrayOfKeys };
			summaryIndicators && props.updateSummaryIndicators(summaryIndicators);
			newTabtoMap();
		}
		else {
			summaryIndicatorsArray.splice(index, 1);
			for (let i in summaryIndicatorsArray) {
				arrayOfKeys.push(summaryIndicatorsArray && summaryIndicatorsArray[i].key);
			}
			let summaryIndicators = { Keys: [...new Set(arrayOfKeys)] };
			summaryIndicators && props.updateSummaryIndicators(summaryIndicators);
			newTabtoMap();
		}
	};

	const getUpliftClass = (rate) => {
		if (rate > 0) return 'kpi_big_uplift uplift_positive';
		if (rate < 0) return 'kpi_big_uplift uplift_negative';
		return 'kpi_big_uplift';
	};

	return (
		<div className="kpi_list">
			<AddKpiComponent
				modaleView={modalIsVisible}
				hideModal={HideModal}
				searchIndicators={props.searchIndicators}
				updateSummaryIndicators={props.updateSummaryIndicators && props.updateSummaryIndicators}
				systemServices={props.systemServices && props.systemServices}
				SummaryIndicatorsArray={summaryIndicatorsArray && summaryIndicatorsArray}
				newSecondaryIndicators={newSecondaryIndicatorsToGet && newSecondaryIndicatorsToGet}
				reRender={reRender} datainformation={props.datainformation && props.datainformation}
				allIndicatorsofCampaign={props.allIndicatorsofCampaign && props.allIndicatorsofCampaign}
				targetIndicators={props.targetIndicators}
				interactionsIndicators={props.interactionsIndicators}
			/>
			{
				summaryFirstIndicators && summaryFirstIndicators.label ||
					summaryFirstIndicators && summaryFirstIndicators.key === 'none' ?
					<div className="kpi_item">
						<p className="kpi_title"> {summaryFirstIndicators && summaryFirstIndicators.label} </p>
						{'valueDisplayedRate' in summaryFirstIndicators ?
							<>
								<div className="kpi_row">
									<div className="kpi_col">
										<span className="kpi_main_value">
											{summaryFirstIndicators && isANumber(summaryFirstIndicators.valueDisplayedAbsolute)}
										</span>
									</div>
									{!summaryFirstIndicators.hideRate && summaryFirstIndicators.valueDisplayedRate != undefined &&
										<>
											<div className="kpi_col"></div>
											<div className="kpi_col">
												<div className="kpi_rate">{isANumber(summaryFirstIndicators.valueDisplayedRate)} {getRateSign(summaryFirstIndicators)}</div>
											</div>
										</>
									}
								</div>
								{!summaryFirstIndicators.hideUplift && props.hasControlGroup && summaryFirstIndicators.upliftRate != undefined &&
									<>
										<div className="kpi_row">
											<div className="kpi_col" data-tip="Control group" data-for="kpi-info">
												<div className="kpi_secondary_value">
													{summaryFirstIndicators.valueNotDisplayedAbsolute}
												</div>
											</div>
											{!summaryFirstIndicators.hideRate && summaryFirstIndicators.valueNotDisplayedRate != undefined &&
												<>
													<div className="kpi_col"></div>
													<div className="kpi_col" data-tip="Control group" data-for="kpi-info">
														<div className="kpi_rate">{isANumber(summaryFirstIndicators.valueNotDisplayedRate)} {getRateSign(summaryFirstIndicators)}</div>
													</div>
												</>
											}
										</div>
										<div className={getUpliftClass(summaryFirstIndicators.upliftRate)}>
											<span data-tip="Uplift" data-for="kpi-info">
												{summaryFirstIndicators.upliftRate > 0 && '+'}
												{isANumber(summaryFirstIndicators.upliftRate)} %
											</span>
										</div>
									</>
								}
							</>
							: <p className="kpi_no_data al_left">No data</p>
						}
						<ReactTooltip id='main-kpi-info' backgroundColor='rgba(238, 22, 22, 0.849)' delayHide={10} effect='float' />
						<ReactTooltip id="kpi-info" backgroundColor='black' effect='solid' delayShow={500} />
					</div>
					:
					<div className="kpi_item">
						{/* <ReactLoading className="kpi_loading" type="bars" color="#006fff" delay={500} width="30px" height="30px" /> */}
					</div>
			}

			{loading ?
				<Loading />
				: newTabtoMaped
			}

		</div>
	);
}
