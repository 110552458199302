import moment from 'moment';
import { FORMAT } from './config';

export const today = moment();
const monday = moment().startOf('isoWeek').format(FORMAT);
const formatToday = moment().format(FORMAT);

let template = { fromDate: '', toDate: formatToday };

/*
	Day => "this week"
	MondayWeek => "last 30",
	SundayWeek,
	Month => "year",
*/

export const datesOptions = {
	// 'Today': {
	// 	...template,
	// 	fromDate: moment().format(FORMAT),
	// },
	'Last 7 days': {
		...template,
		fromDate: moment().subtract(7, 'days').format(FORMAT)
	},
	'Last 30 days': {
		...template,
		fromDate: moment().subtract(30, 'days').format(FORMAT)
	},
	'One year':{
		...template,
		fromDate: moment().subtract(1, 'year').format(FORMAT)
	},
	'Custom': {
		...template,
		formatDate: '', toDate: ''
	},
	'From the beginning' : {
		fromDate: '', toDate: formatToday
	}
};
export const compareDatesOptions = {
	'Last day': {
		...template,
		fromDate: moment().subtract(1, 'days').format(FORMAT)
	},
	'Last 14 days': {
		...template,
		fromDate: moment().subtract(14, 'days').format(FORMAT)
	},
	'Last 60 days': {
		...template,
		fromDate: moment().subtract(60, 'days').format(FORMAT)
	},
	'Custom': {
		...template,
		formatDate: '', toDate: ''
	},
	'One year':{
		...template,
		fromDate: moment().subtract(1, 'year').format(FORMAT)
	},
};

const intialOption = Object.entries(datesOptions)[1];
const fromDateFirstValue = moment(formatToday).subtract(14, 'day').format(FORMAT);


export const initialDates = {
	initialFrom: intialOption[1].fromDate,
	initialTo: intialOption[1].toDate,
	initialLabel: intialOption[0],
	initialLabel2: 'Custom',
	fromDateToCompare: fromDateFirstValue,
	toDateToCompare: intialOption[1].fromDate,
};
