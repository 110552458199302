import React, { useState } from 'react';
import Dropdown from './Dropdown';
import Fieldset from './Fieldset';
import InputCustom from './InputCustom';
import Listbox from './Listbox';
import ListboxItem from './ListboxItem';
import SpinnerWheel from './SpinnerWheel';

export default function SelectDropdown({
	children,
	optionsList,
	value,
	label = '',
	labelPosition = 'outer',
	labelClassName = '',
	description = '',
	labelTooltip = '',
	blockClassName = '',
	blockSize,
	appearance,
	onChange,
	placeHolder = '-',
	disabled = false,
	isClearable = false,
	hasArrow = true,
	fullWidth = false,
	autoWidth = true,
	ellips = false,
	size = 'm',
	color = 'grey',
	emptyState = <></>,
	friendlyValue,
	autocomplete = false,
	autocompleteValue = '',
	autocompletePlaceholder = 'Search',
	onAutocomplete,
	autocompleteIsLoading = false,
	clearAutocompleteOnClose = true,
	hasHoverSidebar = false,
	hoverSidebarRender,
	hoverSidebarPlaceholder = ''
}) {
	const inputClass = ['custom_input', 'clickable'];
	const [isOpen, setIsOpen] = useState(false);
	const [hoverContent, setHoverContent] = useState('');

	if (fullWidth) {
		inputClass.push('w_full');
	} else if (autoWidth) {
		inputClass.push('w_auto');
	}
	if (disabled) {
		inputClass.push('disabled');
	}

	const labelClass = ['form_block_label'];
	if (labelClassName) {
		labelClass.push(labelClassName);
	}

	// Size : xs, s, default, l, xl, xxl
	if (size) {
		inputClass.push(size);
	}

	// Color : grey, white or none
	if (color === 'white') {
		inputClass.push('bg_' + color);
	}
	if (color === 'none') {
		inputClass.push('ghost');
	}

	// Appearance
	if( appearance === 'ghost' ){
		inputClass.push('ghost');
	}

	// Ellips
	if (ellips) {
		inputClass.push('has_ellips');
	}

	const getSelectedOption = () => {
		const defaultLabel = friendlyValue ? friendlyValue : '';

		if (typeof value == 'undefined') return {label: defaultLabel};
		if (!optionsList || optionsList.length == 0) return {label: defaultLabel};

		const selectedValue = optionsList.find((opt) => {
			return opt.value === value;
		});
		if (!selectedValue) return {label: defaultLabel};
		if (defaultLabel) {
			selectedValue.label = defaultLabel;
		}
		return selectedValue;
	};

	const {label: selectedOptionLabel, icon: selectedOptionIcon } = getSelectedOption();

	const handleOpen = () => {
		if (disabled) return;
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setHoverContent('');
		clearAutocompleteOnClose && handleAutocomplete('');
	};

	const handleChange = (v) => {
		if (typeof onChange === 'function') {
			onChange(v);
		}
		handleClose();
	};

	const handleAutocomplete = (v) => {
		if (typeof onAutocomplete === 'function') {
			onAutocomplete(v);
		}
	};

	const clear = (ev) => {
		ev.stopPropagation();
		handleChange(undefined);
	};

	const handleItemHover = (item) => {
		if (!hasHoverSidebar) return;
		if (typeof hoverSidebarRender !== 'function') return;
		setHoverContent(hoverSidebarRender(item?.label, item?.hoverContent));
	};

	const valueEl = (
		<>
			{selectedOptionLabel ?
				<>
					{selectedOptionIcon &&
						<i className={'custom_input_value_icon ' + selectedOptionIcon} />
					}
					{selectedOptionLabel}
				</>
				:
				<span className='custom_input_placeholder'>{placeHolder}</span>
			}
		</>
	);

	const button = (
		<button className={inputClass.join(' ')}>
			{label && labelPosition == 'inner' ?
				<span className='custom_input_value has_label'>
					<span className='custom_input_value_label'>{label}</span>
					{valueEl}
				</span>
				:
				<span className='custom_input_value'>{valueEl}</span>
			}
			{isClearable && selectedOptionLabel &&
				<span className="custom_input_reset" onClick={(ev) => clear(ev)} onMouseDown={(ev) => ev.stopPropagation()}>
					<i className="fas fa-times-circle"></i>
				</span>
			}
			{hasArrow &&
				<span className='custom_input_arrow' />
			}
		</button>
	);

	const list = (
		<div className='flex flex_v dropdown_max_height'>
			{autocomplete &&
				<div className='listbox_input_section flex_item_fix'>
					<InputCustom
						value={autocompleteValue}
						placeholder={autocompletePlaceholder}
						autoFocus={true}
						fullWidth={true}
						forceFocus={isOpen}
						onChange={(e) => handleAutocomplete(e.target.value)}
						clearable={true}
					/>
				</div>
			}
			<div className='flex_item_auto vscroll_custom'>
				{optionsList &&
					<Listbox>
						{optionsList && optionsList.map((o, i) => (
							<ListboxItem
								key={i}
								message={o.label}
								description={o.description}
								imageUrl={o.image}
								icon={o.icon}
								onClick={() => handleChange(o.value)}
								selected={o.value === value}
								disabled={disabled || o.isDisabled}
								onMouseEnter={hasHoverSidebar ? () => handleItemHover(o) : undefined}
								style={o.style}
							/>
						))}
					</Listbox>
				}
				{children}
				{autocomplete
					&& !autocompleteIsLoading
					&& autocompleteValue
					&& (!optionsList || optionsList.length === 0) &&
					<Listbox>
						<ListboxItem
							message={'No option'}
							disabled={true}
						/>
					</Listbox>
				}
				{autocomplete && !autocompleteValue && (!optionsList || optionsList.length === 0) && emptyState && emptyState}
				{autocompleteIsLoading &&
					<SpinnerWheel display='block' verticalSize="s" />
				}
			</div>
		</div>
	);

	const select = (
		<Dropdown
			isOpen={isOpen}
			setIsOpen={() => handleOpen()}
			onHide={() => handleClose()}
			scrollbar={false}
			button={button}>
			{hasHoverSidebar &&
				<div className='flex flex_align_stretch'>
					<div className='flex_item_fix'>
						{list}
					</div>
					<div className='flex_item_fix dropdown_hover_sidebar vscroll_custom'>
						{hoverContent && hoverContent}
						{!hoverContent &&
							<div>{hoverSidebarPlaceholder}</div>
						}
					</div>
				</div>
			}
			{!hasHoverSidebar && list}
		</Dropdown>
	);

	if (label && labelPosition == 'outer') {
		return (
			<Fieldset
				label={label}
				description={description}
				labelTooltip={labelTooltip}
				labelClassName={labelClassName}
				blockClassName={blockClassName}
				blockSize={blockSize}
			>
				{select}
			</Fieldset>
		);
	}

	return select;
}
