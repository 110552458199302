import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../BusinessOperationBanner.css';
import useEventBroker from '../../../../Hooks/useEventBroker';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';

export default function CustomizeBanners({ banner }) {
	const {
		selectedZone,
		setWidgetTwicPicIsopen,
		bannerSelected,
		setBannerSelected
	} = useContext(CreateBusinessOperationContext);

	const ttId = uuidv4();
	const [isDisabled, setIsDisabled] = useState(true);
	const [imageBannerIsTooSmall, setImageBannerIsTooSmall] = useState(true);
	const [elementClass, setElementClass] = useState([]);
	const [needTransform, setNeedTransform] = useState(false);
	const [title, setTitle] = useState('Select a zone first');

	const { publish } = useEventBroker();

	const refElement = useRef(null);
	function FileConvertSize(aSize) {
		aSize = Math.abs(parseInt(aSize, 10));
		var def = [[1, 'octets'], [1024, 'ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
		for (var i = 0; i < def.length; i++) {
			if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
		}
	}

	useEffect(() => {
		computeStateRegardingSelectedZone();
	}, [selectedZone]);

	useEffect(() => {
		const eClass = ['bannersListItem has_selection'];

		if (isDisabled)
			eClass.push('disabled');

		if (selectedZone && selectedZone.bannerId === banner.bannerId) {
			eClass.push('selected');
			if (refElement.current) {
				refElement.current.scrollIntoView(false); // TODO: to test
			}
		}

		setElementClass(eClass);

	}, [isDisabled, selectedZone]);

	useEffect(() => {
		if (!selectedZone) {
			setImageBannerIsTooSmall(false);
		}
	}, [selectedZone]);


	const selectBanner = (banner) => {
		if (!selectedZone || isDisabled) return;

		if (needTransform) {
			if (isATwicPicsBanner) {
				setWidgetTwicPicIsopen(true);
				setBannerSelected({ banner: banner });
				publish('evt/businessoperations/cropRequestedWithTwicPics', { banner: banner });
			} else {
				publish('evt/businessoperations/cropRequested', { banner: banner });
			}
		} else {
			publish('evt/businessoperations/bannerSelected', banner);
		}
	};

	let isACloudinaryBanner = false;
	let isATwicPicsBanner = false;

	if (banner) {
		// && banner.metaData.transformationSourceId === null
		if (typeof banner.metaData.sourceId === 'string' || typeof banner.metaData.transformationSourceId === 'string') {
			if (banner.imagePath.includes('cloudinary')) {
				isACloudinaryBanner = true;
			} else if(banner.imagePath.includes('twic')){
				isATwicPicsBanner = true;
			}
		} else {
			isACloudinaryBanner = false;
			isATwicPicsBanner = false;
		}
	}

	const computeStateRegardingSelectedZone = () => {
		if (selectedZone && selectedZone.extensions) {
			const selectedZoneRatio = selectedZone.extensions.slideRatio || selectedZone.extensions.ratio;
			const selectedZoneWidth = selectedZone.extensions.slideWidth || selectedZone.extensions.width;
			const selectedZoneHeight = selectedZone.extensions.slideHeight || selectedZone.extensions.height;
			const ratio = banner.metaData.size.width / banner.metaData.size.height;
		

			// Algo de compraison à améliorer.
			const proportionIsOk = ratio >= selectedZoneRatio * .9 && ratio <= selectedZoneRatio * 1.1;
			
			if (selectedZone.bannerType === 'VideoPlayer') {
				setImageBannerIsTooSmall(false);
				setNeedTransform(false);
				setTitle('Image is not availble for video zone');
				setIsDisabled(true);
				return;
			}
			else if (proportionIsOk) {
				const isTooSmall = banner.metaData.size.width < selectedZoneWidth * 0.9;
				setImageBannerIsTooSmall(isTooSmall);
				setTitle(isTooSmall ? 'Warning: This banner seems too small regarding the selected zone' : 'Click to select this banner');
				setNeedTransform(false);
				setIsDisabled(false);
			} else if (isACloudinaryBanner || isATwicPicsBanner) {
				setImageBannerIsTooSmall(false);
				setNeedTransform(true);
				setTitle('Click to transform this banner');
				setIsDisabled(false);
			} else {
				setImageBannerIsTooSmall(false);
				setNeedTransform(false);
				setTitle('This banner does not fit to the selected zone');
				setIsDisabled(true);
			}
			return;
		}
		setIsDisabled(true);
		setNeedTransform(false);
		setTitle('Select a zone first');
	};


	return (
		<div>
			<ReactTooltip id={ttId} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
			<div ref={refElement}
				className={elementClass.join(' ')}
				onClick={(e) => selectBanner(banner)}
				data-for={ttId}
				data-tip={title}>
				<div className="bannersListItemImg" >
					<img src={banner.imagePath} width={banner.metaData.size.width} />

					{!isDisabled && selectedZone &&
						<>
							{needTransform &&
								<span className="bannersListItemIcon">
									<i className="fas fa-crop-alt"></i>
								</span>
							}

							{imageBannerIsTooSmall &&
								<span className="bannersListItemIcon bannersListItemIconWarn">
									<i className="fa fa-exclamation-triangle"></i>
								</span>
							}

							{!needTransform && !imageBannerIsTooSmall &&
								<span className="bannersListItemIcon">
									<i className="fa fa-plus"></i>
								</span>
							}
						</>
					}
				</div>

				<div className="bannersListItemName" title={banner.metaData.fileName && banner.metaData.fileName}>
					<div className="bannersListItemFloatText">
						{banner.metaData.fileName && banner.metaData.fileName}
					</div>
				</div>
				<div className="bannersListItemInfo">
					{FileConvertSize(banner.metaData.weight)}
					<span className="bannersListItemInfoSep">|</span>
					{banner.metaData.size.width}x{banner.metaData.size.height}
					<span className="bannersListItemInfoSep">|</span>
					{banner.metaData.extension && banner.metaData.extension.toUpperCase()}
				</div>
			</div>
		</div>
	);
}
