import React, { useState } from 'react';
import Modal from '../../../../Components/Modal';
import Dropzone from './Dropzone';
import Btn from '../../../../Components/Btn';
import { onSiteContext } from '../context';
import classes from './EditImageCampaign.module.css';
import { Trans, useTranslation } from 'react-i18next';
export default function EditImageCampaign() {
	const {
		modalCampaignUploadImageisOpen,
		openCloseModalUploadImage,
		currentCampaign,
		screenShotServices,
		accountId,
		setneedFetchList,
		systemServices
	} = onSiteContext();
	const [t, i18n] = useTranslation('common');
	const [displayImage, setDisplayImage] = useState({
		isActive: false,
		files: [],
	});

	const handleImage = (e, uploadElseCancel) => {
		e.preventDefault();
		if (uploadElseCancel) {
		var filesToUpload = displayImage.files;
		if (filesToUpload.length > 0) {
				const formData = new FormData();
				formData.append('image', displayImage.files[0]);
				screenShotServices.postCampaignImage(
			accountId,
			currentCampaign.id,
					formData,
				).then((success) => {
			  	systemServices.showSuccess(
						t('EditImageCampaign.successUpload')
			  ); setTimeout(() => {
						setneedFetchList(true);
		}, 800);
				}).catch(err => {
			console.log(err),
					systemServices.showError(t('EditImageCampaign.errorUpload')
					);
				});
		  }
		} else {
		  setDisplayImage({ isActive: false, files: [] });
		}
	};

	return (
		<div className={classes.editImageCampaign}>
			<Modal
				isOpen={modalCampaignUploadImageisOpen}
				width="450"
				minHeight="200"
				onClose={(e) => {
					openCloseModalUploadImage(false, currentCampaign);
				}}
			>
				<div className="modal_header">
					{t('EditImageCampaign.UploadScreenshot')}
					<div className="modal_header_desc">{currentCampaign.name}</div>
				</div>
				<div className={classes.container}>
					{	
						displayImage.isActive ? (
							<div className={classes.content}>
								<img width={300} src={URL.createObjectURL(displayImage.files[0])} />
								<div className="flex flex_justify_center flex_align_center mt_25">
									<Btn
										message="Upload"
										color="primary"
										onClick={(e) => {
											handleImage(e, true);
											openCloseModalUploadImage(false, currentCampaign);
										}}
									/>
									<Btn
										message={t('EditImageCampaign.cancel')}
										color="alert"
										onClick={(e) => {
											handleImage(e, false);
											openCloseModalUploadImage(false, currentCampaign);
										}}
									/>
								</div>
							</div>
						):(<Dropzone setDisplayImage={setDisplayImage}></Dropzone>)
					}
				</div>
			</Modal>
		</div>
	);
}
 