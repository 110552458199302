import React, { createContext, useState, useEffect } from 'react'

export const Context = createContext()

const ContextProvider = (props) => {
    const [valueSelectBeyableTag, setValueSelectBeyableTag] = useState('')
    const [valueSelectBasedOn, setValueSelectBasedOn] = useState('')
    const [matchingListValues, setMatchingListValues] = useState({})
    const [toggleMatchingListEdit, setToggleMatchingListEdit] = useState(false)
    const [listOfMatchingList, setListOfMatchingList] = useState()
    const [arrayFromItems, setArrayFromItems] = useState([])
    const [arrayToItems, setArrayToItems] = useState([])
    const [matchingListIsSort, setMatchingListIsSort] = useState(false)
    const [existantAlgorithm, setExistantAlgorithm] = useState()
    const [btnSelect, setBtnSelect] = useState([])
    const [matchingModeCible, setMatchingModeCible] = useState('product')
    const [matchingModeReco, setMatchingModeReco] = useState('product')
    const openMatchingListEdit = (matchingListValues) => {
        setToggleMatchingListEdit(false)
        setMatchingListValues(matchingListValues)
        setMatchingListIsSort(!matchingListIsSort)
        setTimeout(() => {
            setToggleMatchingListEdit(true)
        }, 300)
    }
    useEffect(() => {
        let arrayFromItem = []
        let typeLisFromItem = 'product'
        const sortFromItems = () => {
            matchingListValues.fromItems && matchingListValues.fromItems.forEach(element => {
                arrayFromItem.push(element.value)
                if (element.itemType === 'ProductReference') {
                    typeLisFromItem = 'product'
                } else {
                    typeLisFromItem = 'category'
                }
            })
            setArrayFromItems(arrayFromItem)
            setMatchingModeCible(typeLisFromItem)
        }
        sortFromItems()
        let arrayToItem = []
        let typeListReco = 'product'
        const sortToItems = () => {
            matchingListValues.toItems && matchingListValues.toItems.forEach(element => {
                arrayToItem.push(element.value)
                if (element.itemType === 'ProductReference') {
                    typeListReco = 'product'
                } else {
                    typeListReco = 'category'
                }
            })
            setArrayToItems(arrayToItem)
            setMatchingModeReco(typeListReco)
        }
        sortToItems()

    }, [matchingListIsSort])

    useEffect(() => {
        setMatchingListValues(prevValues => ({
            ...prevValues,
            listReco: arrayToItems,
            listSku: arrayFromItems
        }))
    }, [arrayFromItems])

    const oppenNewMatchingListEdit = () => {
        setMatchingListValues({
            id: 'new',
            listReco: [],
            listSku: [],
            name: "",
        })
        setToggleMatchingListEdit(true)
    }
    const closeMatchingListEdit = () => {
        setMatchingListValues({})
        setToggleMatchingListEdit(false)
    }
    const changeMatchingListName = (event) => {
        const value = event.target.value
        setMatchingListValues(prevValues => ({
            ...prevValues,
            name: value
        }))
    }
    const changeMatchingListValuesProductCible = (event) => {
        event.persist()
        const value = event.target.value
        const formatValues = value.split(/[;,\n]/)
        setMatchingListValues(prevValues => ({
            ...prevValues,
            listSku: formatValues
        }))
    }
    const changeMatchingListValuesRecommendation = (event) => {
        event.persist()
        const value = event.target.value
        const formatValuesReco = value.split(/[;,\n]/)
        setMatchingListValues(prevValues => ({
            ...prevValues,
            listReco: formatValuesReco
        }))
    }

    const chargeRuleScopeIfAlgoExist = () => {
        if (existantAlgorithm) {
            setValueSelectBasedOn(existantAlgorithm.ruleScope.scopeIdentifier)
        }
    }
    useEffect(() => {
        chargeRuleScopeIfAlgoExist()
    }, [existantAlgorithm])
    const chargeSelectTagIfAlgoExist = () => {
        if (existantAlgorithm) {
            setValueSelectBeyableTag(existantAlgorithm.filters[0] && existantAlgorithm.filters[0].values)
        }
    }

    useEffect(() => {
        chargeSelectTagIfAlgoExist()
    }, [existantAlgorithm])

    return (
        <Context.Provider value={{
            valueSelectBeyableTag,
            setValueSelectBeyableTag,
            valueSelectBasedOn,
            setValueSelectBasedOn,
            setMatchingListValues,
            matchingListValues,
            toggleMatchingListEdit,
            openMatchingListEdit,
            closeMatchingListEdit,
            changeMatchingListName,
            changeMatchingListValuesProductCible,
            changeMatchingListValuesRecommendation,
            toggleMatchingListEdit,
            oppenNewMatchingListEdit,
            listOfMatchingList,
            setListOfMatchingList,
            existantAlgorithm,
            setExistantAlgorithm,
            btnSelect,
            setBtnSelect,
            matchingModeCible,
            setMatchingModeCible,
            matchingModeReco,
            setMatchingModeReco
        }}>
            {props.children}
        </Context.Provider>
    )

}
export default ContextProvider