import React from 'react'
import {useLocation} from 'react-router-dom'
import ProductFeedContainers from '../../Tooling/ProductFeed/ProductFeedContainers';
import {useProductFeedConfigContext} from '../context/ProductFeedConfigContext';
export default function ProductFeedPreviewContainer() {
	let location = useLocation();
	const {props} = useProductFeedConfigContext();

	return (
		<section className='section'>
			<div className='page_block'>
				<ProductFeedContainers
					props={{...props, isNewConfig : true}} />
			</div>
		</section>
	);
}
