export const duplicateTaggingById = (
	taggingId,
	routeParams,
	systemServices,
	serviceReference,
	functionToLoad,
	callbackSuccess,
	callbackError
) =>
	serviceReference.getTaggingById(
		taggingId,
		(callbackSuccess) => {
			const cloneTagRule = { ...callbackSuccess };
			cloneTagRule.name = cloneTagRule.name + " cloned";
			serviceReference.createTagging(
				cloneTagRule,
				(Success) => {
					let ka = ""
					if (routeParams && routeParams.hasOwnProperty('ka')) {
						ka = '&ka=' + routeParams.ka;
					}

					systemServices.showSuccess("The rule has been duplicated, redirect to the editing screen");
					functionToLoad("/ProductSettings/Tagging/Setting?id=" + Success.id + ka);
				},
				(error) => {
					systemServices.showError();
					callbackError(error);
				}
			);
		},
		(error) => {
			systemServices.showError();
			callbackError(error);
		}
	);

export const deleteTagById = (taggingId, systemServices, serviceReference, callbackSuccess, callbackError) =>
	serviceReference.deleteTagging(
		taggingId,
		(Sccess) => {
			callbackSuccess();
			systemServices.showSuccess("The rule has been deleted.");
		},
		(error) => {
			systemServices.showError();
			callbackError(error);
		}
	);
// API CALLS 
export const getProductCollation = (category, systemServices, serviceReference, callbackSuccess) => serviceReference.getProductCollation(
	category,
	callbackSuccess,
	error => systemServices.showError());

export const getTaggingById = (taggingId, systemServices, serviceReference, callbackSuccess, callbackError) => serviceReference.getTaggingById(
	taggingId,
	callbackSuccess,
	error => {
		systemServices.showError();
		callbackError(error)
	});
export const createTagging = (taggingRule,
	systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.createTagging(
		taggingRule,
		callbackSuccess,
		error => {
			systemServices.showError();
			callbackError(error)
		});
export const putTaggingById = (taggingRule, taggingId,
	systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.putTaggingById(
		taggingRule,
		taggingId,
		callbackSuccess,
		error => {
			systemServices.showError();
			callbackError(error)
		});

export const getPreview = (options, paginationOptions,
	TaggingRule, systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.getProductListPreview(
		options,
		paginationOptions,
		TaggingRule,
		callbackSuccess,
		(error, error2) => {
			// error2.data && error2.data.length > 0 ? systemServices.showError(error2.data) : systemServices.showError();
			systemServices.showError();
			callbackError()
		});