import React, { useState, useContext, useEffect } from 'react';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import './CustomizeScreenWebSite.css';
import './ZoneContainer.css';
import ZoneImage from './ZoneImage';
import ZoneImageOrVideoPlayer from './ZoneImageOrVideoPlayer';
import ZoneSlider from './ZoneSlider';
import ZoneCarousel from './ZoneCarousel';
import ZoneVideoPlayer from './ZoneVideoPlayer';

export default function ZoneContainer({ zone, canDefineCTA, canMakeAssociation, bannerType, businessOperationServices,
	systemServices, accountId }) {
	const {
		selectedZone,
		setSelectedZone
	} = useContext(CreateBusinessOperationContext);

	const [isZoneSelected, setIsZoneSelected] = useState(false);

	// ensure only one selected zone
	useEffect(() => {
		setIsZoneSelected(selectedZone && selectedZone.id === zone.zoneId);
	}, [selectedZone]);


	const selectZone = (e, bannerId, canBeUnselected) => {
		const id = zone.zoneId;
		const extensions = zone.zoneExtensions.Coordinates;
		const bannerType = zone.bannerType;

		if( bannerType == 'Carousel' ){
			if( zone.zoneExtensions.carrouselSettings && zone.zoneExtensions.carrouselSettings.slideWidth ){
				extensions.slideWidth = zone.zoneExtensions.carrouselSettings.slideWidth;
			}
			if( zone.zoneExtensions.carrouselSettings && zone.zoneExtensions.carrouselSettings.slideHeight ){
				extensions.slideHeight = zone.zoneExtensions.carrouselSettings.slideHeight;
			}
			if( extensions.slideWidth && extensions.slideHeight ){
				extensions.slideRatio = extensions.slideWidth / extensions.slideHeight;
			}
		}

		function applySelect() {
			if (canBeUnselected && selectedZone && selectedZone.id === id) {
				setSelectedZone();
			} else {
				setSelectedZone({ id: id, extensions: extensions, bannerId: bannerId, bannerType: bannerType });
			}
		}

		applySelect();
	};

	return (
		<>
			{(() => {
				switch (bannerType) {
				case 'Image':
					return (
						<ZoneImage
							zone={zone}
							canMakeAssociation={canMakeAssociation}
							canDefineCTA={canDefineCTA}
							isZoneSelected={isZoneSelected}
							selectZoneCallback={selectZone}
						></ZoneImage>
					);
				case 'Carousel' :
					return (
						<ZoneCarousel
							zone={zone}
							canMakeAssociation={canMakeAssociation}
							canDefineCTA={canDefineCTA}
							isZoneSelected={isZoneSelected}
							selectedZone={selectedZone}
							selectZoneCallback={selectZone}
							businessOperationServices={businessOperationServices}
							systemServices={systemServices}
							accountId={accountId}
						></ZoneCarousel>
					);
				case 'Slider':
					return (
						<ZoneSlider
							zone={zone}
							canMakeAssociation={canMakeAssociation}
							canDefineCTA={canDefineCTA}
							isZoneSelected={isZoneSelected}
							selectedZone={selectedZone}
							selectZoneCallback={selectZone}
							businessOperationServices={businessOperationServices}
							systemServices={systemServices}
							accountId={accountId}
						></ZoneSlider>
					);
				case 'VideoPlayer':
					return (
						<ZoneVideoPlayer
							zone={zone}
							canMakeAssociation={canMakeAssociation}
							isZoneSelected={isZoneSelected}
							selectedZone={selectedZone}
							selectZoneCallback={selectZone}
							businessOperationServices={businessOperationServices}
							systemServices={systemServices}
							accountId={accountId}
						></ZoneVideoPlayer>
					);
				case 'ImageOrVideoPlayer':
					return (
						<ZoneImageOrVideoPlayer
							zone={zone}
							canMakeAssociation={canMakeAssociation}
							canDefineCTA={canDefineCTA}
							isZoneSelected={isZoneSelected}
							selectedZone={selectedZone}
							selectZoneCallback={selectZone}
							businessOperationServices={businessOperationServices}
							systemServices={systemServices}
							accountId={accountId}
						></ZoneImageOrVideoPlayer>
					);
				default:
					return null;
				}
			})()}
		</>
	);
}
