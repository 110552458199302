import React from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
export default function ManualSortingItem({ name, list, handleOpenManualSortWithTabSelected }) {

	const idTooltip = uuidv4();
	const numbOfElementInList = list.length > 0 ? list.length : 0;
	if (numbOfElementInList === 0) {
		return <></>;
	}
	return (
		<>
			<ReactTooltip id={idTooltip} backgroundColor='black' effect='solid' />
			<div className="collection_sidebar_item s flex clickable"
				onClick={handleOpenManualSortWithTabSelected} data-for={idTooltip} data-tip={`Open ${name} list`}
			>
				<div className="collection_sidebar_item_nb flex_item_fix"
				>
					{list && numbOfElementInList || 0}
				</div>
				<div className="flex_item_full fw_medium">{name}</div>
				<div style={{marginLeft: 'auto'}}>
					<i 
				
						className="flex_item_fix fa fa-chevron-right grey_2 "
						data-for={idTooltip} data-tip={`Open ${name} list`}
						onClick={handleOpenManualSortWithTabSelected}></i>
				</div>
			
			</div>
		</>
	);
}
