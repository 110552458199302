import React from 'react'
import { react2angular } from 'react2angular';
import ReferenceDataAutoComplete from './ReferenceDataAutoComplete';
import SystemServices from '../../Services/SystemServices';
import SetupAccountServices from '../../Services/SetupAccountServices';
import ProductTagServices from '../../Services/ProductTagServices';
import ImpersonatingServices from '../../Services/ImpersonatingServices'
import ReferenceDataType from './ReferenceDataType';

export default function AutoCompleteContainer(props) { 
    
    const setupAccountServices = new SetupAccountServices(props.$http);
    const productTagServices = new ProductTagServices(props.$http);
    const systemServices = new SystemServices(props.$rootScope);
    const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);
    const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
    const currentAccount = impersonatedAccount || props.$rootScope.User.Account.Key;

    const { getTypeFromTriggerName } = ReferenceDataType()

    return (
        <ReferenceDataAutoComplete
            setupAccountServices={setupAccountServices}
            productTagServices={productTagServices}
            systemServices={systemServices}
            currentAccount={currentAccount}
            referenceDataType={getTypeFromTriggerName(props.specification.Name)}
            onChange={e => props.specification.ValueToCompare = e.values.join(';')}
            >

        </ReferenceDataAutoComplete>
    )
}

angular
    .module('beyableSaasApp.Campaigns')
    .component('autoCompleteContainer', react2angular(AutoCompleteContainer, ['specification'], ['$http', '$routeParams', '$rootScope', '$scope']));