import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Dropdown from "../../Components/Dropdown";

export default function ColorPicker({ label, systemServices, accountId, setrefetch, isNew, labelService, changeColor }) {
    const [isLoading, setisLoading] = useState(false)
    const [colorselected, setcolorselected] = useState(label.color.toLowerCase());
    const refs = useRef({});
    const idOfCogwheel = uuidv4();
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    const labelsColors = ['Blue', 'Green', 'Yellow', 'Orange', 'Red', 'Purple'];

    useEffect(() => {
        if (dropdownIsOpen && refs) {
            function handleClickOutside(event) {
                if (event.target.id && event.target.id === idOfCogwheel) {
                    return
                }
                if (refs.current && refs.current['wrapper'] && !refs.current['wrapper'].contains(event.target)) {
                    event.stopPropagation()
                    setDropdownIsOpen(false);
                }
            }
            document.addEventListener("click", handleClickOutside);
            return () => {
                document.removeEventListener("click", handleClickOutside);
            };
        }
    }, [refs, dropdownIsOpen]);

    const handleselectNewColor = (e) => {
        if (!isNew) {
            setcolorselected(e.toLowerCase());
            const newValueForLabel = {
                Name: label.name,
                Color: e
            }
            labelService.putLabel(accountId, label.id, newValueForLabel,
                newData => {
                    setisLoading(false);
                    setrefetch(true);
                    console.log(newData);
                }, err => {
                    setisLoading(false);
                    systemServices.showError('An Error occured during the modification of the label');
                })
        } else {
            console.log(e);
            setcolorselected(e.toLowerCase());
            changeColor(e.toLowerCase())
        }
    }

    return (
        <div className="color_picker">

            <Dropdown
                isOpen={dropdownIsOpen}
                setIsOpen={() => setDropdownIsOpen(true)}
                onHide={() => setDropdownIsOpen(false)}
                icon="fas fa-chevron-down"
                button={
                    <button type="button" >
                        <span className={`color_picker_color color_picker_color_${label.color.toLowerCase()}`}></span>
                    </button>
                }
            >
                <ul className="color_picker_inner_list flex">
                    {labelsColors.map(color =>
                        <li key={color}>
                            <div className={colorselected === color.toLowerCase() ? 'color_picker_item selected' : 'color_picker_item'}>
                                <span className={`color_picker_color color_picker_color_${color.toLowerCase()}`}
                                    onClick={(e) => {
                                        handleselectNewColor(color);
                                        setDropdownIsOpen(false)
                                    }}
                                ></span>
                            </div>
                        </li>
                    )}
                </ul>
            </Dropdown>
            {
                isLoading &&
                <div className="spinner_overlay">
                    <div className="spinner">
                        <div className="rect1"></div>
                        <div className="rect2"></div>
                        <div className="rect3"></div>
                        <div className="rect4"></div>
                        <div className="rect5"></div>
                    </div>
                </div>
            }
        </div>
    )
}
