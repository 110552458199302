import React, { useState } from 'react'

import Btn from './../../../Components/Btn';
import PopinConfirm from './../../../Components/PopinConfirm';

export default function PannelIpAdresses({ showPanelIpAddress, closeThePannel, newIpToCreate, setNewIpToCreate, addNewIpAdresses, existIp, putAdressesIp, deleteTheAdresseIp, currentIP }) {
    const [showPopinDeleteIp, setShowPopinDeleteIp] = useState(false)
    const handleChangeIpAdresse = (e) => {
        let newObj = { ...newIpToCreate }
        newObj[e.target.name] = e.target.value
        setNewIpToCreate(newObj)
    }
    const handleChangeIpAdresseByCopy = (e) => {
        let newObj = { ...newIpToCreate }
        newObj.IpAddress = e
        setNewIpToCreate(newObj)
    }
    const cancel = () => {
        closeThePannel()
        setNewIpToCreate({
            IpAddress: "",
            Label: "",
            IsDisabled: false
        })
    }
    return (
        <div>
            {
                showPopinDeleteIp &&
                <PopinConfirm
                    cancel={() => setShowPopinDeleteIp(false)}
                    title={`Do you really want to delete ${newIpToCreate.Label} (${newIpToCreate.IpAddress}) ? `}
                >
                    <Btn
                        onClickFunction={(e) => { setShowPopinDeleteIp(false) }}
                        message="Cancel"
                        color="primary"
                    />
                    <Btn
                        onClickFunction={(e) => { setShowPopinDeleteIp(false); deleteTheAdresseIp() }}
                        message="Delete"
                        color="alert"
                    />

                </PopinConfirm>
            }
            <div className={showPanelIpAddress ? "overlay_popin showed" : "overlay_popin"}></div>
            <div className={showPanelIpAddress ? "panel-wrapper showed" : "panel-wrapper"}>
                <div className="panel-inner">
                    <div className="panel-header">
                        <p className="title">{existIp === true ? "Edit" : "Add"}  an IP address</p>
                        <p className="subtitle">You can add a full IP address or a partial one (in this case, all addresses that start like this will be taken into account)</p>
                    </div>
                    <div className="panel-content">
                        <label htmlFor="ip-name">
                            <p className="input-name">Name</p>
                            <input type="text" id="ip-name" name="Label" onChange={handleChangeIpAdresse} value={newIpToCreate.Label ? newIpToCreate.Label : ''} />
                        </label>
                        <label htmlFor="ip-address">
                            <p className="input-name">IP address</p>
                            <input type="text" id="ip-address" name="IpAddress" onChange={handleChangeIpAdresse} value={newIpToCreate.IpAddress ? newIpToCreate.IpAddress : ''} />
                        </label>
                        <div className="current-ip">
                            <p>Your current IP address :</p>
                            <button onClick={(e) => handleChangeIpAdresseByCopy(currentIP)}>{currentIP}< i className="fas fa-level-up-alt"></i></button>
                        </div>
                    </div>
                    <div className="panel-btn">
                        <button className="btn-cancel" onClick={cancel}>Cancel</button>
                        <button className="btn-confirm" onClick={existIp !== true ? addNewIpAdresses : putAdressesIp}>Confirm</button>
                    </div>
                </div>
                {
                    existIp === true &&
                    <button className="btn-delete" ng-show="EditIpAddressID != null" onClick={() => setShowPopinDeleteIp(true)}><i className="fas fa-trash-alt"></i> Delete</button>
                }
            </div>
        </div >
    )
}
