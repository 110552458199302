import React from 'react';
import EmptyState  from '../../../Components/EmptyState';
import Btn from '../../../Components/Btn';
import { Article } from '../../../Components/Article/Article';
import { useAuditContext } from '../context/ContextAudit';
import { useTranslation } from 'react-i18next';

export default function EmptySection() {

	const {
		handleGetAudit,
	} = useAuditContext();

	const [t] = useTranslation('kpi');

	return (
		<section className="section">
			<Article>
				<EmptyState
					title={t('audit.getAnAiPoweredRecommendation')}
					text={t('audit.ourToolWillRecommend')}
					imageUrl="/Assets/empty_audit.svg"
					textSize="xl"
					verticalSize="l"
					width="xl"
					primaryAction={
						<Btn
							message={t('audit.startAnalyse')}
							onClick={()=>handleGetAudit()}
						/>
					}
				/>
			</Article>
		</section>
	);
}
