import HttpServices from './HttpServices';

export default class UsersAdministrationServices {
	constructor($http, $routeParams) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}
	// Get the account Users 
	getAccountUsers(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}account/${accountId}/user`, callbackSuccess, callbackError);
	}
	deleteAccountUser(accountId, userId, callbackSuccess, callbackError) {
		this.HttpServices.delete(`${this.BO_API_URL}account/${accountId}/user/${userId}`, callbackSuccess, callbackError);
	}
	createUser(accountId, config, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}account/${accountId}/user`, config, callbackSuccess, callbackError);
	}
	putUser(accountId, userId, config, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}account/${accountId}/user/${userId}`, config, callbackSuccess, callbackError);
	}
	putUserRoles(accountId, userId, config, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}account/${accountId}/user/${userId}/role`, config, callbackSuccess, callbackError);
	}
	getAllAccountBeyable(includeBeyable, includeEmpty, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}account?includeBeyable=${includeBeyable}&includeEmpty=${includeEmpty}`, callbackSuccess, callbackError);
	}
	getRoles(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}account/${accountId}/role`, callbackSuccess, callbackError);
	}
	// Users : Get users infos
	getUserById(ku, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.SERVER_URL}users/${ku}`, callbackSuccess, callbackError);
	}
}