import React, { useState, useEffect } from "react"
import Insight from "../Insight/Insight";
import { toReadableDuration } from "../../util";
import { useAnalyticsContext } from "../../context/AnalyticsContextProvider";
import SessionBavWithCamparison from "./SessionBavWithCamparison";
import SessionBavWithoutCamparison from "./SessionBavWithoutComparison";
import { FORMAT } from "../../config";

export default function SessionsBavContainer({
	setNumberOfSessions,
	setNumberOfSessions2,
	setTotalsPeriode1,
	setTotalsPeriode2,
	navFilter,
	service,
	ComparisonSelected,
	styles }) {
	const [bav, setBav] = useState({})
	const [uniqueVisitors, setUniqueVisitors] = useState({ loading: true, isError: false })

	const { selectedTenant } = useAnalyticsContext();
	useEffect(() => {
		const timer = setTimeout(() => {
			if (navFilter.fromDate && navFilter.toDate) {
				getBav(navFilter, setBav)
				getUniqueVisitors(navFilter, setUniqueVisitors)
			}
		}, 1000);
		return () => {
			clearTimeout(timer);
		};
	}, [navFilter, selectedTenant, ComparisonSelected])


	function getUniqueVisitors(filters = {
		fromDate: "",
		toDate: ""
	}, fn) {
		fn({ loading: true, isError: false })
		if (!ComparisonSelected) {
			const options = {
				...filters
			}
			service.getUniqueVisitors(options, (data) => {
				fn({
					value: parseInt(data.uniqueVisitors),
					tendance: null,
					loading: false,
					isError: false,
					sign: null
				})
			},

				() => fn({ isError: true }))
		}
		if (ComparisonSelected) {
			const optionForComparison = {
				filters: [...filters.filters],
				period1FromDate: moment(filters.fromDate).format(FORMAT),
				period1ToDate: moment(filters.toDate).format(FORMAT),
				period2FromDate: moment((filters.fromDateToCompare)).format(FORMAT),
				period2ToDate: moment(filters.toDateToCompare).format(FORMAT),
				// metrics: ['uniqueVisitors'],
				timeGranularity: filters.timeGranularity
			}
			service.getUniqueVisitorCompare(optionForComparison, (data) => {
				fn({
					periode1: parseInt(data.UniqueVisitorsPeriod1),
					periode2: parseInt(data.UniqueVisitorsPeriod2),
					uniqueVisitorsPercentVariation: data.UniqueVisitorsPercentVariation,
					tendance: null,
					loading: false,
					isError: false,
					sign: null
				})
			},

				() => fn({ isError: true }))
		}
	}

	function getBav(filters = {
		fromDate: "",
		toDate: ""
	}, fn) {

		fn({
			bRate: { loading: true, isError: false },
			nbSession: { loading: true, isError: false },
			avViews: { loading: true, isError: false },
			vDuration: { loading: true, isError: false }
		})
		const options = {
			...filters,
			metrics: [
				"NumberOfSessions",
				"NumberOfBounces",
				"DurationSeconds",
				"NumberOfPageViews"
			],
			timeGranularity: "None",
		}
		function getSessions() {
			service.getSessions(options, (data) => {
				const [rates] = data
				const nbSession = rates.NumberOfSessions;
				const bRate = rates.NumberOfSessions && Math.floor((rates.NumberOfBounces / rates.NumberOfSessions) * 100)
				const avViews = rates.NumberOfSessions && (rates.NumberOfPageViews / rates.NumberOfSessions).toFixed(1)
				const sessionsNotBounced = rates.NumberOfSessions - rates.NumberOfBounces
				setNumberOfSessions(nbSession)

				const [vDuration, sign] = sessionsNotBounced
					? toReadableDuration(Math.ceil(rates.DurationSeconds / sessionsNotBounced))
					: ["0", "second"]
				setTotalsPeriode1({
					numberOfSessions: nbSession,
					numberOfPageViews: rates.NumberOfPageViews,
					numberOfBounces: rates.NumberOfBounces,
					durationSeconds: rates.DurationSeconds
				})
				const formatData = {
					bRate: {
						value: bRate,
						tendance: null,
						loading: false,
						isError: false,
						sign: "%"
					},
					nbSession: {
						value: nbSession,
						tendance: null,
						loading: false,
						isError: false,
						sign: null
					},
					avViews: {
						value: avViews,
						tendance: null,
						loading: false,
						isError: false,
						sign: "Per session"
					},
					vDuration: {
						value: vDuration,
						tendance: null,
						loading: false,
						isError: false,
						sign: sign
					}
				}

				fn({ ...formatData })
			}, (error) => {
				console.log('bav', error)
				fn({
					bRate: { loading: false, isError: true },
					nbSession: { loading: false, isError: true },
					avViews: { loading: false, isError: true },
					vDuration: { loading: false, isError: true }
				})
			})
		}
		if (!ComparisonSelected) {
			getSessions()
		}
		if (ComparisonSelected) {
			getDataWithComparison(options)
		}
		function getDataWithComparison(option) {

			const optionForComparison = {
				filters: [...option.filters],
				period1FromDate: moment(option.fromDate).format(FORMAT),
				period1ToDate: moment(option.toDate).format(FORMAT),
				period2FromDate: moment((option.fromDateToCompare)).format(FORMAT),
				period2ToDate: moment(option.toDateToCompare).format(FORMAT),
				metrics: [...option.metrics],
				timeGranularity: option.timeGranularity
			}

			service.getSessionsCompare(
				optionForComparison,
				(data) => {
					const [rates] = data

					const nbSession = rates.NumberOfSessionsPeriod1;
					const nbSession2 = rates.NumberOfSessionsPeriod2;

					const bRate = rates.NumberOfSessionsPeriod1 && Math.floor((rates.NumberOfBouncesPeriod1 / rates.NumberOfSessionsPeriod1) * 100)
					const bRate2 = rates.NumberOfSessionsPeriod2 && Math.floor((rates.NumberOfBouncesPeriod2 / rates.NumberOfSessionsPeriod2) * 100)

					const avViews = rates.NumberOfSessionsPeriod1 && (rates.NumberOfPageViewsPeriod1 / rates.NumberOfSessionsPeriod1).toFixed(1)
					const avViews2 = rates.NumberOfSessionsPeriod2 && (rates.NumberOfPageViewsPeriod2 / rates.NumberOfSessionsPeriod2).toFixed(1)

					const sessionsNotBounced = rates.NumberOfSessionsPeriod1 - rates.NumberOfBouncesPeriod1
					const sessionsNotBounced2 = rates.NumberOfSessionsPeriod2 - rates.NumberOfBouncesPeriod2

					const numberOfBouncesPercentVariation = bRate2 &&
						(bRate - bRate2) /
						bRate2 * 100;

					const numberOfPageViewsPercentVariation = avViews2 &&
						(avViews - avViews2) /
						avViews2 * 100;

					const numberOfSessionsPercentVariation = rates.NumberOfSessionsPercentVariation;

					const durationSecondsPercentVariation = rates.DurationSecondsPercentVariation

					setNumberOfSessions(nbSession)
					setNumberOfSessions2(nbSession2)

					setTotalsPeriode1({
						numberOfSessions: nbSession,
						numberOfPageViews: rates.NumberOfPageViewsPeriod1,
						numberOfBounces: rates.NumberOfBouncesPeriod1,
						durationSeconds: rates.DurationSecondsPeriod1
					})
					setTotalsPeriode2({
						numberOfSessions: nbSession2,
						numberOfPageViews: rates.NumberOfPageViewsPeriod2,
						numberOfBounces: rates.NumberOfBouncesPeriod2,
						durationSeconds: rates.DurationSecondsPeriod2
					})

					const [vDuration, sign] = sessionsNotBounced
						? toReadableDuration(Math.ceil(rates.DurationSecondsPeriod1 / sessionsNotBounced))
						: ["0", "second"]
					const [vDuration2, sign2] = sessionsNotBounced2
						? toReadableDuration(Math.ceil(rates.DurationSecondsPeriod2 / sessionsNotBounced2))
						: ["0", "second"]
					const formatData = {
						numberOfBouncesPercentVariation: numberOfBouncesPercentVariation,
						numberOfPageViewsPercentVariation: numberOfPageViewsPercentVariation,
						numberOfSessionsPercentVariation: numberOfSessionsPercentVariation,
						durationSecondsPercentVariation: durationSecondsPercentVariation,
						periode1: {
							bRate: {
								value: bRate,
								tendance: null,
								loading: false,
								isError: false,
								sign: "%"
							},
							nbSession: {
								value: nbSession,
								tendance: null,
								loading: false,
								isError: false,
								sign: ""
							},
							avViews: {
								value: avViews,
								tendance: null,
								loading: false,
								isError: false,
								sign: "Per session"
							},
							vDuration: {
								value: vDuration,
								tendance: null,
								loading: false,
								isError: false,
								sign: sign
							}
						},
						periode2: {
							bRate: {
								value: bRate2,
								tendance: null,
								loading: false,
								isError: false,
								sign: "%"
							},
							nbSession: {
								value: nbSession2,
								tendance: null,
								loading: false,
								isError: false,
								sign: null
							},
							avViews: {
								value: avViews2,
								tendance: null,
								loading: false,
								isError: false,
								sign: "Per session"
							},
							vDuration: {
								value: vDuration2,
								tendance: null,
								loading: false,
								isError: false,
								sign: sign2
							}
						}

					}

					fn({ ...formatData })
				}, (error) => {
					console.log('bav', error)
					fn({
						numberOfBouncesPercentVariation: 0,
						numberOfPageViewsPercentVariation: 0,
						numberOfSessionsPercentVariation: 0,
						periode1: {
							bRate: { loading: false, isError: true },
							nbSession: { loading: false, isError: true },
							avViews: { loading: false, isError: true },
							vDuration: { loading: false, isError: true }
						},
						periode2: {
							bRate: { loading: false, isError: true },
							nbSession: { loading: false, isError: true },
							avViews: { loading: false, isError: true },
							vDuration: { loading: false, isError: true }
						}
					})
				}
			);
		}
	}



	return (
		<div className="analytics_insight_group">
			{ComparisonSelected ?
				<SessionBavWithCamparison
					bav={bav}
					uniqueVisitors={uniqueVisitors}
				/> :
				<SessionBavWithoutCamparison
					bav={bav}
					uniqueVisitors={uniqueVisitors}
				/>
			}
		</div>
	)
};
