import React, {createContext, useState, useEffect, useContext, useRef} from 'react';
import useEventBroker from '../../../../Hooks/useEventBroker';

/* Templates */
import {templatePopin} from './GrapejsTemplates/Popin';
import {templatePopin2} from './GrapejsTemplates/Popin2';
import {templatePopin3} from './GrapejsTemplates/Popin3';
import {templatePopin4} from './GrapejsTemplates/Popin4';

/* Components */
import {componentButton, componentButtonInner, componentButtonText} from './GrapejsComponents/Button';
import {componentPromoCode, componentPromoCodeInner} from './GrapejsComponents/PromoCode';
import {componentBlock} from './GrapejsComponents/Block';
import {componentColumn} from './GrapejsComponents/Column';
import {componentSpacer} from './GrapejsComponents/Spacer';
import {componentDivider, componentDividerInner} from './GrapejsComponents/Divider';
import {componentPopinWrapper} from './GrapejsComponents/PopinWrapper';
import {componentPopinOverlay} from './GrapejsComponents/PopinOverlay';
import {componentPopinClose, componentPopinCloseText, componentPopinCloseIcon} from './GrapejsComponents/PopinClose';
import {componentPopin} from './GrapejsComponents/Popin';
import {componentText} from './GrapejsComponents/Text';
import {componentImage, componentImageInner} from './GrapejsComponents/Image';

/* Traits */
import {traitColumnsCount} from './GrapejsTraits/ColumnsCount';
import {traitButtonWidth} from './GrapejsTraits/ButtonWidth';
import {traitCloseLayout} from './GrapejsTraits/CloseLayout';
import {traitResponsive} from './GrapejsTraits/Responsive';
import {traitBlockResponsive} from './GrapejsTraits/BlockResponsive';

/* Styles */
import {styleButtonAlign} from './GrapejsStyles/ButtonAlign';


export const BuildEditorContext = createContext();

function useBuildEditorContext(props) {
	return useContext(BuildEditorContext);
}


const CreateBuildEditorContextProvider = (props) => {

	const campaign = props.campaign;
	const campaignMode = props.mode;
	const campaignStep = props.step;
	const handleSetBuilderPropertyOnCampaign = props.handleSetBuilderPropertyOnCampaign;
	const getCurrentVariation = props.getCurrentVariation;
	const handleSetBuilderDefinitions = props.handleSetBuilderDefinitions;
	const authServices = props.authServices;
	const accountId = props.accountId;
	const $http = props.$http;
	const systemServices = props.systemServices;

	const searchParams = new URLSearchParams(window.location.search);

	const [buildEditor, setBuildEditor] = useState(null);
	const [moduleTemplate, setModuleTemplate] = useState();
	const [showStylesPanel, setShowStylesPanel] = useState(true);
	const [isUndoRedoDisabled, setIsUndoRedoDisabled] = useState(false);
	const [isComponentInjected, setIsComponentInjected] = useState(false);
	const [currentDevice, setCurrentDevice] = useState('desktop');
	const [showVisibility, setShowVisibility] = useState(true);
	const [componentSelected, setComponentSelected] = useState(null);
	const [builderUpdate, setBuilderUpdate] = useState({initialized: false});

	useEffect(() => {
		if (campaignMode === 'creating') {
			let template = 'popin_wrapper';
			const searchModule = searchParams.get('builder');
			switch (searchModule) {
				case 'popin_free': template = 'popin_wrapper'; break;
			}
			//setModuleTemplate(templatePopin);
		}
	}, []);

	// Save the state of the current editor(=projectData variable) and the value of html/css for the preview functionality
	useEffect(() => {
		if (builderUpdate.initialized) {
			const {variationId, projectData, html, css, js} = builderUpdate;
			handleSetBuilderPropertyOnCampaign(projectData, moduleTemplate, variationId);
			handleSetBuilderDefinitions(html, css, js);
		}
	}, [builderUpdate]);


	useEffect(() => {
		if (buildEditor && !builderUpdate.initialized) {
			const html = buildEditor.getHtml()
			const css = buildEditor.getCss()
			const js = buildEditor.getJs()
			handleSetBuilderDefinitions(html, css, js)
		}
	}, [buildEditor]);


	const handleChangeEditorDevice = (device) => {
		buildEditor.setDevice(device);
	};

	const handleClickUndoRedo = (action) => {
		if (!isUndoRedoDisabled) {
			const um = buildEditor.UndoManager;
			if (action === 'undo' && um.hasUndo()) {
				um.undo();
			}
			if (action === 'redo' && um.hasRedo()) {
				um.redo();
			}
			setIsUndoRedoDisabled(true);
			setTimeout(() => {
				setIsUndoRedoDisabled(false);
			}, 500);
		}
	};

	const handleChangeShowVisibility = () => {
		const cm = buildEditor.Commands;
		if (cm.isActive('sw-visibility')) {
			setShowVisibility(false);
			cm.stop('sw-visibility');
		} else {
			setShowVisibility(true);
			cm.run('sw-visibility');
		}
	};

	const templateComponents = editor => {
		// Popin components
		editor.DomComponents.addType('popin_wrapper', componentPopinWrapper);
		editor.DomComponents.addType('popin', componentPopin);
		editor.DomComponents.addType('popin_overlay', componentPopinOverlay);
		editor.DomComponents.addType('popin_close', componentPopinClose);
		editor.DomComponents.addType('popin_close_text', componentPopinCloseText);
		editor.DomComponents.addType('popin_close_icon', componentPopinCloseIcon);

		// Common components
		editor.DomComponents.addType('button', componentButton);
		editor.DomComponents.addType('button_inner', componentButtonInner);
		editor.DomComponents.addType('button_text', componentButtonText);
		editor.DomComponents.addType('block', componentBlock);
		editor.DomComponents.addType('column', componentColumn);
		editor.DomComponents.addType('spacer', componentSpacer);
		editor.DomComponents.addType('divider', componentDivider);
		editor.DomComponents.addType('divider_inner', componentDividerInner);
		editor.DomComponents.addType('text', componentText);

		editor.DomComponents.addType('image_custom', componentImage);
		editor.DomComponents.addType('image_inner', componentImageInner);

		editor.DomComponents.addType('promo_code', componentPromoCode);
		editor.DomComponents.addType('promo_code_inner', componentPromoCodeInner);

		// Traits
		editor.TraitManager.addType('columns_count', traitColumnsCount);
		editor.TraitManager.addType('button_width', traitButtonWidth);
		editor.TraitManager.addType('close_layout', traitCloseLayout);
		editor.TraitManager.addType('responsive', traitResponsive);
		editor.TraitManager.addType('block_responsive', traitBlockResponsive);
		
		// Styles
		editor.StyleManager.addType('button_align', styleButtonAlign);

		// Template
		if (campaignMode === 'creating') {
			let template = templatePopin;
			const searchModule = searchParams.get('builder');
			switch (searchModule) {
				case 'popin_free': template = templatePopin; break;
				case 'popin2': template = templatePopin2; break;
				case 'popin3': template = templatePopin3; break;
				case 'popin4': template = templatePopin4; break;
			}
			editor.Pages.add(template);
		}
	};

	const addComponentTemplate = (editor) => {
		//TODO quand on aura differents template mettre le template en question (=frameBuilder) en argument
		editor.addComponents({
			type: moduleTemplate
		});
	};

	return (
		<BuildEditorContext.Provider
			value={{
				buildEditor,
				campaign,
				campaignMode,
				campaignStep,
				moduleTemplate,
				isComponentInjected,
				showStylesPanel,
				componentSelected,
				addComponentTemplate,
				setBuildEditor,
				handleChangeEditorDevice,
				setShowStylesPanel,
				handleClickUndoRedo,
				handleChangeShowVisibility,
				templateComponents,
				getCurrentVariation,
				setIsComponentInjected,
				handleSetBuilderPropertyOnCampaign,
				handleSetBuilderDefinitions,
				currentDevice,
				setCurrentDevice,
				showVisibility,
				setModuleTemplate,
				setComponentSelected,
				authServices,
				accountId,
				$http,
				systemServices,
				builderUpdate,
				setBuilderUpdate
			}}
		>
			{props.children}
		</BuildEditorContext.Provider>
	);
};

export default CreateBuildEditorContextProvider;
export {useBuildEditorContext};