import React, {useState, useEffect} from 'react';
import Nav from '../Nav/Nav';
import {useInteractionContext} from '../../context/InteractionContext';
import ReportTypeFilter from '../ReportType/ReportTypeFilter';
import initReportValue from './config';
import {devices} from './config';
import InteractionSearchFilter from './InteractionSearchFilter';
import SourceTypeFilter from '../SourceType/SourceTypeFilter';
import {useAnalyticsContext} from '../../context/AnalyticsContextProvider';
import styles from '../../WebAnalyticsContainer.module.css';
import UtmFiltersInreractionContainer from './UtmFiltersInreractionContainer';
import SelectDevice from '../Devices/SelectDevice';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { CsvModal } from '../Csv/CsvModal';

export default function InteractionFilterContainer({sessionsServices, fetchCsvService}) {
	const {
		navFilter,
		onChangeNavFilter,
		reportTypeSelected,
		handleChangeReportSelect,
		handleFilter,
		filterExactValue,
		sourceTypes,
		handleChangeSourceType,
		sourceType,
		selectedCategory,
		handleSelectPage,
		setisCustomSelected,
		isCustomSelected,
		customDateRange,
		setCustomDateRange,
		deviceSelected,
		handleChangeDeviceSelected,
		resetSource,
		resetDevices,
		step,
		hasValues,
		filter2ExactValue,
		filterExactValueSavedForFilterHasvaluePages,
	} = useInteractionContext();

	const {initiNavDate} = useAnalyticsContext();
	const initialDates = initiNavDate(navFilter);
	const [renderBreadCrumb, setRenderBreadCrumb] = useState(<></>);

	useEffect(() => {
		if (reportTypeSelected === 'Interaction' && (selectedCategory || filterExactValue || filterExactValueSavedForFilterHasvaluePages)) {

			const bcList = [];


			bcList.push({
				label: 'Interactions',
				onClick: () => handleFilter(null)
			});

			if (selectedCategory) {
				bcList.push({
					label: selectedCategory,
					isFolder: true,
					onClick: () => handleFilter(selectedCategory, 'category')
				});
			}

			if (filterExactValueSavedForFilterHasvaluePages) {
				bcList.push({
					label: filterExactValueSavedForFilterHasvaluePages,
					onClick: () => handleFilter(filterExactValueSavedForFilterHasvaluePages, 'interaction', true)
				});
			}

			if (filterExactValue && (!filterExactValueSavedForFilterHasvaluePages || filterExactValueSavedForFilterHasvaluePages !== filterExactValue	
			)) {
				bcList.push({
					label: filterExactValue,
				});
			}
			setRenderBreadCrumb(
				<Breadcrumb list={bcList} />
			);
		} else if (reportTypeSelected === 'Page' && filterExactValue) {

			const bcList = [];

			bcList.push({
				label: 'Pages',
				onClick: () => handleFilter(null)
			});

			bcList.push({
				label: filterExactValue,
				onClick: () => handleSelectPage(filterExactValue)
			});

			if (selectedCategory) {
				bcList.push({
					label: selectedCategory,
					isFolder: true,
					onClick: () => handleFilter(selectedCategory, 'category')
				});
			}

			if (filter2ExactValue && hasValues && step === 4) {
				bcList.push({
					label: filter2ExactValue,
				});
			}

			setRenderBreadCrumb(
				<Breadcrumb list={bcList} />
			);

		} else {
			setRenderBreadCrumb(<></>);
		}

	}, [selectedCategory, 
		reportTypeSelected,
		filterExactValue,
		filter2ExactValue,
		hasValues,
		filterExactValueSavedForFilterHasvaluePages
	]);

	return (
		<>
			<div className="flex flex_align_end analytics_main_line">
				<div className="flex_item_full">
					<Nav
						initialDates={initialDates}
						handleClick={onChangeNavFilter}
						setisCustomSelected={setisCustomSelected}
						isCustomSelected={isCustomSelected}
						customDateRange={customDateRange}
						setCustomDateRange={setCustomDateRange}
					/>
					<ReportTypeFilter
						reportTypeSelected={reportTypeSelected}
						handleChangeReportSelect={handleChangeReportSelect}
						filterValues={initReportValue}
					/>
					<SourceTypeFilter
						sourceTypeSelected={sourceType}
						handleChangeSourceType={handleChangeSourceType}
						initialValue={sourceTypes}
						resetSource={resetSource}
					/>
					<SelectDevice
						initialValue={devices}
						devices={devices}
						deviceSelected={deviceSelected}
						handleChangeDeviceSelected={handleChangeDeviceSelected}
						resetSource={resetDevices}
					/>
					<UtmFiltersInreractionContainer
						sessionsServices={sessionsServices}
					/>
				</div>
				<div className="flex_item_fix">
					<InteractionSearchFilter/>
				</div>
				<div className="flex_item_fix">
					<CsvModal 
						fetchCsvService={fetchCsvService} />
				</div>
			</div>
			{renderBreadCrumb}
		</>
	);
}
