import React, { Component } from 'react';
import { react2angular } from 'react2angular';
import styles from './PromoCodeCreateContainer.module.css';
import PromoCodeServices from '../../Services/PromoCodeServices';
import SystemServices from '../../Services/SystemServices';

export default class PromoCodeCreateContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfCodes: 0,
            originalCode: '',
            expirationDate: '',
            codeSize: 0,
            codePrefix: ''
        };
        this.expirationDateField = React.createRef();
        this.promoCodeServices = new PromoCodeServices(this.props.$http);
        this.systemServices = new SystemServices(this.props.$rootScope);
        this.authenticatedUserClientKey = this.props.$rootScope.User.Account.ClientKey;
    }

    createPromoCode({numberOfCodes, originalCode, expirationDate, codeSize, codePrefix}) {
        const promoCode = JSON.stringify({
            NumberOfUniqueCodes: parseInt(numberOfCodes, 10),
            OriginalCode: originalCode,
            ExpirationDateUtc: expirationDate,
            CodeSize: parseInt(codeSize, 10),
            CodePrefix: codePrefix
        });

        this.promoCodeServices.createPromoCode(
            this.authenticatedUserClientKey,
            promoCode,
            response => {
                if(response.CampaignKey) {
                    this.systemServices.showSuccess('Création réussie');
                }
            },
            error => {
                this.systemServices.showError(error);
            }
        );
    }

    handleSubmit(e) {
        e.preventDefault();

        if(this.validateFields()) {
            this.createPromoCode(this.state);
        }
    }

    validateFields() {
        if(this.state.numberOfCodes < 1) {
            this.systemServices.showError('Please check number of uniques codes');
            return false;
        }

        if(this.state.originalCode.trim() === '') {
            this.systemServices.showError('Please check original code');
            return false;
        }

        if(this.state.expirationDate.trim() === '') {
            this.systemServices.showError('Please check expiration date');
            return false;
        }

        return true;
    }

    initExpirationDatePicker() {
        const expirationDateField = this.expirationDateField.current;
        const self = this;

        $(expirationDateField).daterangepicker({
            singleDatePicker: true
        }, function(start, end, label) {
            self.setState({expirationDate: end.utc().format()});
        });
    }

    componentDidMount() {
        this.initExpirationDatePicker();
    }

    render() {
        return <div className="new-module">
            <h1>General informations</h1>

            <form className="promoCodeForm" onSubmit={(e) => this.handleSubmit(e)}>
                <label className={styles.fieldRow}>
                    <p>Number of unique codes</p>
                    <input type="number" className="form-control" onChange={(e) => this.setState({numberOfCodes: e.target.value})} />
                </label>
                <label className={styles.fieldRow}>
                    <p>Original code</p>
                    <input type="text" className="form-control" onChange={(e) => this.setState({originalCode: e.target.value})} />
                </label>
                <label className={`${styles.fieldRow} label-date`}>
                    <p>Expiration date</p>
                    <div className="input-group date datepicker">
                        <input type="text" ref={this.expirationDateField} />
                        <span className="input-group-addon">
                            <span className="fa fa-calendar"></span>
                        </span>
                    </div>
                </label>
                <label className={styles.fieldRow}>
                    <p>Code size</p>
                    <input type="number" className="form-control" onChange={(e) => this.setState({codeSize: e.target.value})} />
                </label>
                <label className={styles.fieldRow}>
                    <p>Code prefix</p>
                    <input type="text" className="form-control" onChange={(e) => this.setState({codePrefix: e.target.value})} />
                </label>

                <aside className="module-navigation">
                    <button type="submit" className="btt-second btt-green">Enregistrer</button>
                </aside>
            </form>
        </div>
    }
}

angular
    .module('beyableSaasApp.PromoCode', [])
    .component('promoCodeCreate', react2angular(PromoCodeCreateContainer, [], ['$http', '$rootScope']));
 