import React, {} from 'react';
import styles from './ModuleTabs.module.css';

export default function ModuleTab({
	module = {},
	url,
	isActive = false,
	isDisabled = false,
	onClick,
	showName = true,
	isRound = false
}) {
	
	const iconClass = ['icon_24', 'icon_' + module.icon];
	iconClass.push(isActive ? 'icon_color_white' : 'icon_color_grey');
	
	let itemClass;
	if (isActive) {
		itemClass = styles.tab_selected;
	} else if (isDisabled) {
		itemClass = styles.tab_disabled;
	} else {
		itemClass = styles.tab;
	}
	
	return (
		<a className={itemClass} href={url} onClick={onClick}>
			<span className={isRound ? styles.tab_picto_round : styles.tab_picto}>
				<i className={iconClass.join(' ')}></i>
			</span>
			{showName &&
				<span className={styles.tab_name}>
					{module.name}
				</span>
			}
		</a>
	);
}
