import React,{useState,useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import { CampaignsContext } from '../../context/index.js';

import Fieldset from '../../../../Components/Fieldset';
import Btn from '../../../../Components/Btn';
import InputCustom from '../../../../Components/InputCustom';
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function SelectorInPage({pathPlacement, pathSelector}) {
	const [t] = useTranslation('common');

	const [targetSelector, setTargetSelector] = useState();
	const [targetPosition, setTargetPosition] = useState();
	const [orientation, setOrientation] = useState('Vertical');

	const {
		getCurrentVariation,
		handleChangeInCollection,
		handleChangeInPageOrientation
	} = CampaignsContext();
	
	const activeVariation = getCurrentVariation() || {};
	
	function handleChangeElementInCollection (pos){
		handleChangeInCollection(pathPlacement, pos);
		setTargetPosition(pos);
	}
	function handleChangeElementSelectorInCollection (elem){
		handleChangeInCollection(pathSelector,elem );
		setTargetSelector(elem);
	}
	function handleChangeOrientationInCollection(orient) {
		handleChangeInPageOrientation(orient == 'Default' ? null : orient);
		setOrientation(orient);
	}
  
	useEffect(()=>{
		if(activeVariation && activeVariation.SlideFormat === 'InCollection'){
			setTargetPosition(activeVariation.InCollection?.RelativePlacement ?? 'Replace');
			setTargetSelector(activeVariation.InCollection?.PlacementSelector ?? '');
		}
		if(activeVariation && activeVariation.SlideFormat === 'InPage'){
			setTargetPosition(activeVariation.InPage?.InPagePosition ?? 'Replace');
			setTargetSelector(activeVariation.InPage?.InPageElementSelector ?? '');
		}
		if(activeVariation && activeVariation.SlideFormat === 'InCollection'){
			setOrientation(activeVariation.InCollection?.Orientation ?? 'Default');
		}
		if(activeVariation && activeVariation.SlideFormat === 'InPage'){
			setOrientation(activeVariation.InPage?.Orientation ?? 'Default');
		}
	},[]);

	if(!activeVariation ){
		return null;
	}
	return (
		<>
			<div className='flex'>
				<div className='flex_item_full'>
					<InputCustom
						value={targetSelector}
						name="targetSelector"
						label={t('insert.targetElementSelector')}
						labelTooltip={t('insert.targetElementSelectorTooltip')}
						type="text"
						placeholder="Ex: .my-element"
						fullWidth={true}
						onChange={(e) => handleChangeElementSelectorInCollection(e.target.value)}
					/>
				</div>
				<div className='flex_item_fix ml_10' style={{width: 160}}>
					<SelectDropdown
						label={t('insert.Orientation')}
						labelTooltip={t('insert.OrientationTooltip')}
						optionsList={[
							{value: 'Vertical', label: t('insert.Vertical')},
							{value: 'Default', label: t('insert.DefaultOrientation')},
							{value: 'Horizontal', label: t('insert.Horizontal')},
						]}
						onChange={(v) => handleChangeOrientationInCollection(v)}
						value={orientation}
						fullWidth={true}
					/>
				</div>
			</div>
			<Fieldset label={t('insert.campaignPos')} blockClassName="l">
				<div className="flex inpage_mockup">
					<div className="flex_item_fix inpage_mockup_col__before">
						<div className="inpage_mockup_col_inner">
							<Btn
								message={t('insert.Left')}
								style="outline"
								color="secondary"
								onClick={() => handleChangeElementInCollection('Left')}
								isActive={targetPosition == 'Left'}
								fullWidth={true}
							/>
						</div>
					</div>
					<div className="flex_item_fix inpage_mockup_col__center">
						<div className='inpage_mockup_col_inner'>
							<Btn
								message={t('insert.Above')}
								style="outline"
								color="secondary"
								onClick={() => handleChangeElementInCollection('Above')}
								isActive={targetPosition =='Above'}
								fullWidth={true}
							/>
						</div>
						<div className="inpage_mockup_col_inner inpage_mockup_col_inner_highlighted">
							<div className="btn_switch w_full">
								<Btn
									message={t('insert.Replace')}
									style="outline"
									color="secondary"
									onClick={() => handleChangeElementInCollection('Replace')}
									isActive={targetPosition == 'Replace'}
									fullWidth={true}
								/>
							</div>
						</div>
						<div className='inpage_mockup_col_inner'>
							<Btn
								message={t('insert.Below')}
								style="outline"
								color="secondary"
								onClick={() => handleChangeElementInCollection('Below')}
								isActive={targetPosition == 'Below'}
								fullWidth={true}
							/>
						</div>
						{/* <div className="inpage_mockup_el_name ellips">{targetSelector || '.my-element'}</div> */}
					</div>
					<div className="flex_item_fix inpage_mockup_col__after">
						<div className="inpage_mockup_col_inner">
							<Btn
								message={t('insert.Right')}
								style="outline"
								color="secondary"
								onClick={() => handleChangeElementInCollection('Right')}
								isActive={targetPosition == 'Right'}
								fullWidth={true}
							/>
						</div>
					</div>
				</div>
			</Fieldset>
		</>
	);
}
