
//[MostAttractive, MostViewed, MostAddedToCart, MostConverted, OftenPurchasedTogether, PreviousViewed, CustomAssociation]
const recommendationList = [
    {
        key: 'MostAttractive',
        label: 'Most attractive',
        infos: 'BEYABLE\'s scoring',
        icon: 'fa fa-star',
        isFilterable: true
    },
    {
        key: 'MostViewed',
        label: 'Most viewed',
        infos: '',
        icon: 'fa fa-eye',
        isFilterable: true
    },
    {
        key: 'MostAddedToCart',
        label: 'Most added to cart',
        infos: '',
        icon: 'fas fa-cart-plus',
        isFilterable: true
    },
    {
        key: 'MostConverted',
        label: 'Most converted',
        infos: '',
        icon: 'fas fa-money-check',
        isFilterable: true
    },
    {
        key: 'OftenPurchasedTogether',
        label: 'Often bought together',
        infos: '',
        icon: 'icofont-plus',
        isFilterable: true
    },
    {
        key: 'PreviousViewed',
        label: 'Often viewed together',
        infos: '',
        icon: 'fa fa-history',
        isFilterable: true
    },
    {
        key: 'CustomAssociation',
        label: 'Custom Join',
        infos: 'from/to associations',
        icon: 'fas fa-list',
        isFilterable: false
    },
    {
        key: 'PassThrough',
        label: 'Neutral',
        infos: 'no logic, only filters',
        icon: 'fas fa-ban',
        isFilterable: true
    },
    {
        key: 'Collection',
        label: 'Collection',
        infos: 'dynamic rule',
        icon: 'fas fa-tasks',
        isFilterable: true
    },
    {
        key: 'Custom',
        label: 'Custom',
        infos: 'your own formula',
        icon: 'fas fa-square-root-alt',
        isFilterable: false
    }
]
export default recommendationList;