import React from 'react';
import AnalitycsBoardItem from './AnalitycsBoardItem';
import { v4 as uuidv4 } from 'uuid';
export default function AnalyticsBoardPageCustomItem({ 
	resultName,
	uniqueViews,
	averageTimeOnPage,
	bounceRate,
	landings,
	exits,
	data,
	orderBy,
	orderByDesc,
	handleSort ,
	hanleSelectUrl}) {

	return (
		<div className="table_grid s table_grid_analytics table_grid_analytics_page2">
			<div className="table_row table_head_row">
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'ResultName' && orderByDesc === 'desc' ? handleSort('ResultName', 'asc') :
								handleSort('ResultName', 'desc');
						}}
					>
						{orderBy === 'ResultName' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ResultName' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{resultName}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'UniqueViews' && orderByDesc === 'desc' ? handleSort('UniqueViews', 'asc') :
								handleSort('UniqueViews', 'desc');
						}}
					>
						{orderBy === 'UniqueViews' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'UniqueViews' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{uniqueViews}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'AverageTimeOnPage' && orderByDesc === 'desc' ? handleSort('AverageTimeOnPage', 'asc') :
								handleSort('AverageTimeOnPage', 'desc');
						}}
					>
						{orderBy === 'AverageTimeOnPage' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'AverageTimeOnPage' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{averageTimeOnPage}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'BounceRate' && orderByDesc === 'desc' ? handleSort('BounceRate', 'asc') :
								handleSort('BounceRate', 'desc');
						}}
					>
						{orderBy === 'BounceRate' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'BounceRate' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{bounceRate + ' %'}
					</a>
				</div>
				<div className="table_col">

					<a className="table_sort"
						onClick={() => {
							orderBy === 'Landings' && orderByDesc === 'desc' ? handleSort('Landings', 'asc') :
								handleSort('Landings', 'desc');
						}}
					>
						{orderBy === 'Landings' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'Landings' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{landings}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'Exits' && orderByDesc === 'desc' ? handleSort('Exits', 'asc') :
								handleSort('Exits', 'desc');
						}}
					>
						{orderBy === 'Exits' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'Exits' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{exits}
					</a>
				</div>
				<div className="table_col"></div>
			</div>
			<div>
				{data && data.map((elem) => (
					<div className="table_row table_body_row clickable" key={uuidv4()} onClick={() => hanleSelectUrl(elem['Name'])}>
						<AnalitycsBoardItem
							data={elem['Name'] ? elem['Name']: 'Empty'}
							isHandleClick={false}
						/>
						<AnalitycsBoardItem
							data={elem['UniqueViews']}
							isHandleClick={false}
						/>
						<AnalitycsBoardItem
							data={elem['AverageTime']}
							isHandleClick={false}
						/>
						<AnalitycsBoardItem
							data={elem['BounceRate']}
							isHandleClick={false}
							needPercent={true}
						/>
						<AnalitycsBoardItem
							data={elem['Landings']}
							isHandleClick={false}
						/>
						<AnalitycsBoardItem
							data={elem['Exits']}
							isHandleClick={false}
						/>
						<div className="table_col">
							<i className="fas fa-chevron-right c_turquoise"></i>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
