import React from 'react';
import AnalitycsBoardItem from './AnalitycsBoardItem';
import { v4 as uuidv4 } from 'uuid';
export default function AnalyticsBoardPageCustomItem({
	resultName,
	uniqueViews,
	averageTimeOnPage,
	bounceRate,
	landings,
	exits,
	uniqueVisitors,
	data,
	orderBy,
	orderByDesc,
	handleSort,
	hanleSelectUrl }) {

	const headerCell = (fieldName, label) =>
		<div className="table_col">
			<a className="table_sort"
				onClick={() => {
					orderBy === fieldName && orderByDesc === 'desc' ? handleSort(fieldName, 'asc') : handleSort(fieldName, 'desc');
				}}
			>
				{orderBy === fieldName && orderByDesc !== 'desc' && <i className='fas fa-arrow-up'></i>}
				{orderBy === fieldName && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
				{label}
			</a>
		</div>;

	return (
		<div className="table_grid s table_grid_analytics table_grid_analytics_page2">
			<div className="table_row table_head_row">
				{headerCell('ResultName', resultName)}
				{headerCell('UniqueViews', uniqueViews)}
				{headerCell('UniqueVisitors', uniqueVisitors)}
				{headerCell('AverageTimeOnPage', averageTimeOnPage)}
				{headerCell('BounceRate', bounceRate + ' %')}
				{headerCell('Landings', landings)}
				{headerCell('Exits', exits)}
				<div className="table_col"></div>
			</div>
			<div>
				{data && data.map((elem) => (
					<div className="table_row table_body_row clickable" key={uuidv4()} onClick={() => hanleSelectUrl(elem['Name'])}>
						<AnalitycsBoardItem
							data={elem['Name'] ? elem['Name'] : 'Empty'}
							isHandleClick={false}
						/>
						<AnalitycsBoardItem
							data={elem['UniqueViews']}
							isHandleClick={false}
						/>
						<AnalitycsBoardItem
							data={elem['UniqueVisitors']}
							isHandleClick={false}
						/>
						<AnalitycsBoardItem
							data={elem['AverageTime']}
							isHandleClick={false}
						/>
						<AnalitycsBoardItem
							data={elem['BounceRate']}
							isHandleClick={false}
							needPercent={true}
						/>
						<AnalitycsBoardItem
							data={elem['Landings']}
							isHandleClick={false}
						/>
						<AnalitycsBoardItem
							data={elem['Exits']}
							isHandleClick={false}
						/>
						<div className="table_col">
							<i className="fas fa-chevron-right c_turquoise"></i>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
