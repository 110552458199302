import React from 'react';
import { react2angular } from 'react2angular';
import CreateAccoutnListContextProvider from '../context/AccountListProvider';
import AccountListContainer from './AccountListContainer';
export default function AccountListBridge(props) {
	return (<div>
		<CreateAccoutnListContextProvider
			$http={props.$http}
			$timeout={props.$timeout}
			$rootScope={props.$rootScope}
			$routeParams={props.$routeParams}
			AuthServices={props.AuthServices}
		>
			<h1>Accounts</h1>
			<div className="by_table wbg">
				<div className='accounts_inner'>
					<AccountListContainer/>
				</div>
			</div>
		</CreateAccoutnListContextProvider>
	</div>
	);
}
angular.module('beyableSaasApp.Internal', [])
	.component(
		'internalaccountlist',
		react2angular(
			AccountListBridge,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout', 'AuthServices']
		)
	);