import React, { useState, useEffect } from 'react';
import ProductAssociationServices from '../../../Services/ProductAssociationServices';
import Spinner from './../../../Components/Spinner';
import SpinnerWheel from './../../../Components/SpinnerWheel';
import EmptyState from './../../../Components/EmptyState';
import Btn from '../../../Components/Btn';
import SearchBar from '../../../Components/SearchBar';
import ProductAssociationSetPicked from './ProductAssociationSetPicked';
import TableGridContainer from '../UI/TableGridContainer';
import TableRowItem from '../UI/TableRowItem';
import TableColItem from '../UI/TableColItem';

import Confirm from '../../../Components/Confirm';
import {useTranslation } from 'react-i18next';
import InputCustom from '../../../Components/InputCustom';
import Modal from '../../../Components/Modal';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import ModalHeader from '../../../Components/ModalHeader';
import ModalBody from '../../../Components/ModalBody';
import ModalFooter from '../../../Components/ModalFooter';
import {transformConfigToList,BO_API_URL} from './utils';
import ProductFeedConfigurationServices from '../../../Services/ProductFeedConfigurationServices';
import SelectCustom from '../../../Components/SelectCustom';
import SelectDropdown from '../../../Components/SelectDropdown';

export default function ProductAssociationsContainer({
	accountId,
	$http,
	authServices,
	productAssociationServicesNew,
	systemServices,
	$rootScope,
	$timeout
}) {
	const [t, i18n] = useTranslation('common');
	const handleError = (err) => systemServices.showError('An error occurred');

	const productAssociationServices = new ProductAssociationServices($http, authServices, accountId);
	const services = new ProductFeedConfigurationServices($http);
	const getProductAssociationSets = (callbackSuccess) =>
		productAssociationServices.getProductAssociationSets(callbackSuccess, handleError);
	const getProductAssociationSetById = (associationSetId, callbackSuccess) =>
		productAssociationServices.getProductAssociationSetById(associationSetId, callbackSuccess, handleError);
	const deleteProductAssociationServices = (associationSetId, callbackSuccess) =>
		productAssociationServices.deleteProductAssociationSet(associationSetId, callbackSuccess, handleError);
	const createProductAssociationSet = (association, callbackSuccess) =>
		productAssociationServices.createProductAssociationSet(association, callbackSuccess, handleError);
	const putProductAssociationSetById = (associationSetId,obj, callbackSuccess, callbackError) =>
		productAssociationServices.putProductAssociationSetById(associationSetId,obj, callbackSuccess, callbackError);

	const [tenantSelectedTocopy, setTenantSelectedToCopy] = useState();
	const [loading, setLoading] = useState(true);
	const [associationSetList, setAssociationSetList] = useState(
		[
			// {
			// 	"Name": "Product associations",
			// 	"Id": "e0e833a0-ac9f-46a0-b1c7-85d9aaf1e6ac",
			// 	"LastUpdate": "2017-07-21T17:32:28Z",
			// 	"Type": "ProductReference",
			// 	"UsedCount": 5
			// },
			// {
			// 	"Name": "Catagory associations",
			// 	"Id": "2de736dd1e-7efd-42c6-9d4e-fa97f94ee32d",
			// 	"LastUpdate": "2017-07-21T17:32:28Z",
			// 	"Type": "ProductCategory",
			// 	"UsedCount": 2
			// },
			// {
			// 	"Name": "Tag associations",
			// 	"Id": "e0e833addd0-ac9f-46a0-b1c7-85d9aaf1e6ac",
			// 	"LastUpdate": "2017-07-21T17:32:28Z",
			// 	"Type": "ProductTag",
			// 	"UsedCount": 3
			// }
		]);

	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [mode, setMode] = useState('list');
	const [associationSetPicked, setAssociationSetPicked] = useState({});

	const [totalCount, setTotalCount] = useState(0);
	const [deleteElement, setDeleteElment] = useState();
	const [editingName, seteditingName] = useState({});
	const [addingName, setAddingName] = useState({});
	const [editModalIsOpen, setEditModalIsOpen] = useState(false);
	const [addModalIsOpen, setAddModalIsOpen] = useState(false);
	const [tenantListKey, setTenantListKey] = useState([]);
	const getAssociationList = () => {
		
		setLoading(true);
		getProductAssociationSets((newData) => {
			setAssociationSetList([...newData]);
			setLoading(false);
		});
	};

	useEffect(() => {
		setLoading(true);
		getProductAssociationSets((newData) => {
			setAssociationSetList([...newData]);
			setLoading(false);
		});

	}, [mode]);

	useEffect(() => {
		if (associationSetList) {
			setTotalCount(associationSetList.length);
		}
	}, [associationSetList]);
	useEffect(() => {
		services.call(`${BO_API_URL}feature/${accountId}/productfeedconfig/summary`,'get',null,
			success => {
				const list = transformConfigToList(success?.tenantConfigs);
				const listKeytenant = list.filter(t => !!t.key).map(t =>
				{
					return {
						value: t.key,
						label: t.key
					};
				});
				setTenantListKey(
					[
						{
							value: null,
							label: 'All tenants'
						},
						...listKeytenant
					]
				);
			},
			(error ,err)=>{
				console.error(error, 'err :', err);
				
				if(err.status === 404){
					setTenantListKey([]);
				}
			});

			
	}, []);
	const search = (table, word) => {
		setDataFiltered([]);
		if (word.length > 0) {
			setDataFiltered([]);
			let resultName = table.filter((line) => line.name.toUpperCase().match(`.*${word.toUpperCase()}.*`));
			if (resultName.length === 0) {
				resultName = 'No data found for this search';
				setNoData(resultName);
			} else {
				setLoading(false);
				setNoData();
				setDataFiltered([...resultName]);
			}
		} else {
			setNoData(false);
			setDataFiltered();
		}
	};

	const handleChange = (event) => {
		const value = event.target.value;
		const str = value;
		setValueInSearchBar(value);
		if (associationSetList) {
			search(associationSetList, str);
		}
	};

	const switchMode = () => {
		setMode('list');
	};

	const deleteAssociation = () => {
		setLoading(true);
		deleteProductAssociationServices(deleteElement.id, (res) => {
			setMode('list');
			setLoading(false);
			setDeleteElment();
			getAssociationList();
			systemServices.showSuccess();
		}, err => {
			systemServices.showError();
		});
	};

	const handleChangeName = (e, mode) => {
		e.preventDefault();
		const value = e.target.value;
		if (mode == 'add') {
			setAddingName(l => ({ ...l, name: value }));
		} else {
			seteditingName(l => ({ ...l, name: value }));
		} 
	};

	const pickAssociation = (elem) => {
		setMode('picked');
		setAssociationSetPicked(elem);
	};

	const optionCss = {
		gridTemplateColumns: '4fr 1fr 80px',
	};

	const elementAssociation = (elem) => {
		return <TableRowItem
			key={elem.id}
			specialClasseName={'table_body_row'}
			styleObj={optionCss}>
			<TableColItem
				specialClasseName={' '}>
				<div className="flex">
					<div className="flex_item_fix">
						<a className="emerch_avatar emerch_avatar_margin" onClick={(e) => { pickAssociation(elem); }}>
							<i className="fas fa-list-ul"></i>
						</a>
					</div>
					<div className="flex_item_fix">
						<a className="table_row_name text_link_hover" onClick={(e) => { pickAssociation(elem); }}>
							{elem.name}
						</a>
						<div className="s_11 grey_3 mt_3">
							{elem.id}
						</div>
					</div>
				</div>
			</TableColItem>
			<TableColItem>
				{elem.tenant || 'All' }
			</TableColItem>
			<TableColItem specialClasseName={''} align='right'>
				<DropdownActions
					handleRename={() => {setEditModalIsOpen(true); seteditingName(elem);}}
					handleDelete={() => setDeleteElment(elem)}
				/>
			</TableColItem>
		</TableRowItem>;
	};
	function handleConfirmChange(){
		const compareData = associationSetList.find(el => el.id === editingName.id );
		if(compareData.name !== editingName.name || compareData.tenant !== editingName.tenant ){
			setLoading(true);
			putProductAssociationSetById(editingName.id,
				{'name': editingName.name, tenant : editingName.tenant },
				success =>{
					getAssociationList();
					setEditModalIsOpen(false);
				}, (error,errorMsg )=> {
					systemServices.showError(errorMsg.data.error);
				}
			);
		}else{
			setEditModalIsOpen(false);
		}
		
	}
	function handleSelectATenant(value, mode){
		if (mode == 'add') {
			setAddingName(l => ({ ...l, tenant: value}));
		} else {
			seteditingName(l => ({ ...l, tenant: value}));
		}
	}

	const createNewAssociation = () => {
		const newAssociation = {...addingName};
		createProductAssociationSet(newAssociation, (res) => {
			getProductAssociationSetById(res.id, data => {
				pickAssociation(data);
			});
		});
	};


	return (
		<>
			<section className='section no_bottom_pad section_primary'>
				<div className="h1">Bundles</div>
			</section>
			{mode === 'list' &&
				<>
					<Modal
						isOpen={editModalIsOpen}
						width="400"
						onClose={() => { setEditModalIsOpen(false); }}
					>
						<ModalHeader
							title="Edit options bundle"
						/>
						<ModalBody>
							{editingName && 
								<InputCustom
									label='Bundle name'
									type="text"
									placeholder="name"
									value={editingName.name}
									onChange={(e) => handleChangeName(e, 'edit')}
									fullWidth={true}
								/>
							}
							{/* <SelectCustom
								optionsList={tenantListKey && tenantListKey}
								name="tenant"
								label='Select a destination tenant'
								onChangeFunction={(e) => handleSelectATenant(e, 'edit')}
								value={editingName.tenant ? editingName.tenant : null}
								size="l"
								fullWidth={true}
							/> */}
							<SelectDropdown
								optionsList={tenantListKey && tenantListKey}
								label='Destination tenant'
								onChange={(v) => handleSelectATenant(v, 'edit')}
								value={editingName.tenant ? editingName.tenant : null}
							/>
						</ModalBody>
						<ModalFooter
							primaryAction={
								<Btn
									onClick={() => handleConfirmChange()}
									message={'Confirm'}
								/>
							}
							secondaryAction={
								<Btn
									onClick={() => setEditModalIsOpen(false)}
									message={'Cancel'}
									color="secondary"
									style="ghost"
								/>
							}
						/>
					</Modal>

					<Modal
						isOpen={addModalIsOpen}
						width="400"
						onClose={() => {setAddModalIsOpen(false); setAddingName({});}}
					>
						<ModalHeader
							title="Add a bundle"
						/>
						<ModalBody>
							{addingName && 
								<InputCustom
									label='Bundle name'
									type="text"
									placeholder="name"
									value={addingName.name}
									onChange={(e) => handleChangeName(e, 'add')}
									fullWidth={true}
								/>
							}
							{/* <SelectCustom
								optionsList={tenantListKey && tenantListKey}
								name="tenant"
								label='Destination tenant'
								onChangeFunction={(e) => handleSelectATenant(e, 'add')}
								value={addingName.tenant ? addingName.tenant : null}
								fullWidth={true}
							/> */}
							<SelectDropdown
								optionsList={tenantListKey && tenantListKey}
								label='Destination tenant'
								onChange={(v) => handleSelectATenant(v, 'add')}
								value={addingName.tenant ? addingName.tenant : null}
							/>
						</ModalBody>
						<ModalFooter
							primaryAction={
								<Btn
									onClick={() => {setAddModalIsOpen(false); createNewAssociation();}}
									message={'Confirm'}
								/>
							}
							secondaryAction={
								<Btn
									onClick={() => {setAddModalIsOpen(false); setAddingName({});}}
									message={'Cancel'}
									color="secondary"
									style="ghost"
								/>
							}
						/>
					</Modal>

					<section className="section">
						<div className="page_block">
							<Confirm
								title={'Do you really want to delete this bundle?'}
								text={'This action is irreversible'}
								confirmCallback={deleteAssociation}
								confirmColor='alert'
								confirmText={'Delete'}
								cancelText={'Cancel'}
								onClose={() => setDeleteElment()}
								isOpen={!!deleteElement}
								width={470}
							/>
							{loading && totalCount == 0 &&
								<SpinnerWheel wheelSize="l" display="block" verticalSize="xl" />
							}
							{loading && totalCount != 0 &&
								<SpinnerWheel wheelSize="l" />
							}
							{totalCount != 0 &&
								<>
									<div className="flex mb_15">
										<div className="flex_item_full">
											<SearchBar
												onChangeValue={(e) => handleChange(e)}
												valueInSearchBar={valueInSearchBar}
												placeholder="Search"
												fullWidth={false}
											/>
										</div>
										<div className="flex_item_fix">
											<Btn
												message="New bundle"
												icon="fas fa-plus"
												onClick={() => setAddModalIsOpen(true)}
											/>
										</div>
									</div>
									{!noData &&
										<TableGridContainer specialClasseName="table_grid_emerch" >
											<TableRowItem specialClasseName={'table_head_row'} styleObj={optionCss}>
												<TableColItem>Bundle name</TableColItem>
												<TableColItem>Tenant</TableColItem>
											</TableRowItem>
											
											{!associationSetList ?
												<Spinner /> :
												<>	{!dataFiltered ?
													(<>
														{associationSetList.map(elementAssociation)}
													</>) :
													(<>
														{dataFiltered && dataFiltered.map(elementAssociation)}
													</>)
												}</>
											}
										</TableGridContainer>
									}
								</>
							}
							{!loading && noData &&
								<EmptyState
									icon="fas fa-search"
									title="No matching rule"
									text="Please try another search"
									verticalSize="l"
									textSize="l"
								/>
							}

							{!loading && totalCount == 0 &&
								<EmptyState
									icon="fas fa-list-ul"
									title="No bundle yet"
									text="Create bundles here to use in recommendation rules"
									verticalSize="l"
									textSize="l"
									primaryAction={
										<Btn
											onClick={() => setAddModalIsOpen(true)}
											message="New bundle"
										/>
									}
								/>
							}
						</div>
					</section>
				</>
			}
			{mode === 'picked' && (
				<ProductAssociationSetPicked
					accountId={accountId}
					associationSet={associationSetPicked}
					productAssociationServices={productAssociationServices}
					systemServices={systemServices}
					switchMode={switchMode}
				/>
			)}
			{/* {mode === "list" && (
				<div className="modalBody">
					<h2 className="">Associations set list</h2>
					<div className="search_tag">
						<i className="fa fa-search"></i>
						<input
							className="searchBarTag"
							type="text"
							onChange={handleChange}
							value={valueInSearchBar}
							placeholder="Search for an association set"
						/>
					</div>

					<ul className="listOfSkuWithCount">
						{loading ? (
							<Spinner />
						) : (
							<>
								{!dataFiltered ? (
									<>
										{associationSetList.map((elem, i) => (
											<li key={i} className="itemSku" onClick={() => pickAssociation(elem.id)}>
												{elem.name}
											</li>
										))}
									</>
								) : (
									<>
										{noData ? (
											<div className="noData">{noData}</div>
										) : (
											<>
												{dataFiltered &&
													dataFiltered.map((elem, i) => (
														<li key={i} className="itemSku" onClick={() => deleteAssociation(elem.id)}>
															{elem.name}
														</li>
													))}
											</>
										)}
									</>
								)}
							</>
						)}
					</ul>
					<div className="tag_actions">
						<button className="tag_btn buttonAdd" onClick={() => setMode("creation")}>
							Add a new association set <i className="fas fa-plus"></i>
						</button>
					</div>
				</div>
			)} */}
		</>
	);
}

const DropdownActions = ({
	handleRename,
	handleDelete
}) => {

	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<Dropdown
				isOpen={isOpen}
				toggle={(s) => setIsOpen(s)}
				button={
					<Btn
						icon='fas fa-ellipsis-v'
						style='ghost_outline'
						color='secondary'
						size='xs'
					/>
				}
			>
				<Listbox>
					<ListboxItem 
						message='Edit name and tenant'
						icon='fas fa-pen'
						onClick={() => {setIsOpen(false); handleRename();}}
					/>
					<li className='hr' />
					<ListboxItem 
						message='Delete'
						icon='fas fa-trash'
						onClick={() => {setIsOpen(false); handleDelete();}}
					/>
				</Listbox>
			</Dropdown>
		</>
	);
};
