import React from 'react';
import { useTranslation } from 'react-i18next';
import Overview from './Overview';
import ColorPicker from '../../Components/ColorPicker';
import Checkbox from '../../Components/Checkbox';
import InputCustom from '../../Components/InputCustom';
import styles from './Survey.module.css';
import { Article } from '../../Components/Article/Article';
import { getCSS } from './css';
import { useFormContext } from './ContextFormAndSurvey';

// const opts = [
// 	{
// 		value: 'French',
// 		label : 'French'
// 	},
// 	{
// 		value: 'English',
// 		label : 'English'
// 	},
// 	{
// 		value: 'Custom',
// 		label : 'custom'
// 	}
// ];

function AppearanceSection({
	panelSelected,
	thankSectionValue,
	state,
	stepSurvey,
	formSurveyStyles
}) {

	const { updatePanel } = useFormContext();

	if(stepSurvey !== 3 ){
		return null;
	}
	if(!formSurveyStyles){
		return null;
	}

	const [t] = useTranslation(['survey']);

	function handleChangeColor(name,property,evt){
		const styles = {...formSurveyStyles};
		styles.colors[name][property] = evt;
		if(panelSelected){
			updatePanel(panelSelected.id, null, {styles : styles, css: getCSS(styles)});
		}
	}
	function handleChangewording(name,evt){
		const styles = {...formSurveyStyles};
		styles.wording[name] = evt.target.value;
		if(panelSelected){
			updatePanel(panelSelected.id, null, {styles : styles, css:getCSS(styles)});
		}
	}
	function handleChangeCheck(name,property){
		const styles = {...formSurveyStyles};
		styles.colors[name][property] = !styles.colors[name][property];

		if(panelSelected){
			updatePanel(panelSelected.id, null, {styles : styles, css:getCSS(styles)});
		}
	}

	return (
		<>
			<div className={styles.col_editor}>
				<div className={'vscroll_custom ' + styles.editor_body}>
					<Article titleSize={'s'} title={t('colors')}>
						<ColorEditor
							label={t('backgroundColor')}
							value={formSurveyStyles?.colors?.global?.backgroundColor}
							onChange={(e) => handleChangeColor('global','backgroundColor',e)}
						/>
						<ColorEditor
							label={t('textColor')}
							value={formSurveyStyles?.colors?.global?.textColor}
							onChange={(e) => handleChangeColor('global','textColor',e)}
						/>
						<ColorEditor
							label={t('errorColor')}
							value={formSurveyStyles?.colors?.global?.errorColor}
							onChange={(e) => handleChangeColor('global','errorColor',e)}
						/>
					</Article>
					<Article titleSize={'s'} title={t('mainButton')}>
						<ColorEditor
							label={t('backgroundColor')}
							value={formSurveyStyles?.colors?.button?.backgroundColor}
							onChange={(e) => handleChangeColor('button','backgroundColor',e)}
						/>
						<ColorEditor
							label={t('textColor')}
							value={formSurveyStyles?.colors?.button?.textColor}
							onChange={(e) => handleChangeColor('button','textColor',e)}
						/>
						<ColorEditor
							label={t('border')}
							value={formSurveyStyles?.colors?.button?.borderColor}
							onChange={(e) => handleChangeColor('button','borderColor',e)}
							checkboxValue={formSurveyStyles?.colors?.button?.border}
							checkboxChange={()=>handleChangeCheck('button', 'border')}
						/>
					</Article>
					<Article titleSize={'s'} title={t('skipButton')}>
						<ColorEditor
							label={t('backgroundColor')}
							value={formSurveyStyles?.colors?.skip?.backgroundColor}
							onChange={(e) => handleChangeColor('skip','backgroundColor',e)}
						/>
						<ColorEditor
							label={t('textColor')}
							value={formSurveyStyles?.colors?.skip?.textColor}
							onChange={(e) => handleChangeColor('skip','textColor',e)}
						/>
						<ColorEditor
							label={'Border'}
							value={formSurveyStyles?.colors?.skip?.borderColor}
							onChange={(e) => handleChangeColor('skip','borderColor',e)}
							checkboxValue={formSurveyStyles?.colors?.skip?.border}
							checkboxChange={()=>handleChangeCheck('skip', 'border')}
						/>
					</Article>

					<Article titleSize={'s'} title={t('wording')}>
						{/* Language:
						<SelectCustom
							optionsList={opts}
							autoWidth={true}
							value={formSurveyStyles?.wording?.language}
							onChange={(e)=>handleChangewording('language',e)}
						/> */}
						<InputCustom
							label={t('mainButton')}
							placeHolder=''
							name="nextButton"
							value={formSurveyStyles?.wording?.nextButton}
							onChange={(e) => handleChangewording('nextButton',e)}
							fullWidth={true}
						/>
						<InputCustom
							label={t('skipButton')}
							placeHolder=''
							name="skipButton"
							value={formSurveyStyles?.wording?.skipButton}
							onChange={(e) => handleChangewording('skipButton',e)}
							fullWidth={true}
						/>
						<InputCustom
							label={t('emailErrors')}
							placeHolder=''
							name="errorEmail"
							value={formSurveyStyles?.wording?.errorEmail}
							onChange={(e) => handleChangewording('errorEmail',e)}
							fullWidth={true}
						/>
						<InputCustom
							label={t('phoneNumberErrors')}
							placeHolder=''
							name="errorTel"
							value={formSurveyStyles?.wording?.errorTel}
							onChange={(e) => handleChangewording('errorTel',e)}
							fullWidth={true}
						/>
						<InputCustom
							label={t('otherErrors')}
							placeHolder=''
							name="errorDefault"
							value={formSurveyStyles?.wording?.errorDefault}
							onChange={(e) => handleChangewording('errorDefault',e)}
							fullWidth={true}
						/>
					</Article>
				</div>
			</div>
			<div className={styles.col_preview}>
				<Overview
					thankSectionValue={thankSectionValue}
					state={state}
				/>
			</div>
		</>
	);
}

interface ColorEditorTypes {
	label: string;
	value?: string;
	onChange:(e:any) => any;
	checkboxValue?: boolean;
	checkboxChange?:(e:any) => any;
}

function ColorEditor({
	label,
	value,
	onChange,
	checkboxValue,
	checkboxChange
} : ColorEditorTypes) {

	const hasCheckbox = typeof checkboxValue === 'boolean' && typeof checkboxChange === 'function';

	return (
		<div className={styles.appearance_line}>
			<div className='flex'>
				<div className='flex_item_half'>
					{hasCheckbox ?
						<Checkbox
							label={label}
							onChange={checkboxChange}
							forcedChecked={checkboxValue}
						/>
						:
						<>{label}</>
					}
				</div>
				<div className='flex_item_half ml_10'>
					<ColorPicker 
						value={value}
						onChange={onChange}
						size="s"
						disabled={hasCheckbox && !checkboxValue}
					/>
				</div>
			</div>
		</div>
	);
}

export default AppearanceSection;