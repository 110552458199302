import React, { createContext, useState, useEffect } from 'react';
import useSort from '../../../../Hooks/useSort';

export const CreateBusinessOperationContext = createContext();

const CreateBusinessOperationProvider = (props) => {
	const labelService = props.labelService;
	const systemServices = props.systemServices;
	const $rootScope = props.$rootScope;
	const [isFirstRenderOfExistantOp, setIsFirstRenderOfExistantOp] = useState(true);
	const [devices, setDevices] = useState(['mobile']);
	const [selectionPagesType, setSelectionPagesType] = useState([]);
	const [arrayOfTypePage, setArrayOfTypePage] = useState([]);
	const [isVisitedLink, setIsVisitedLink] = useState([]);
	const [pageNameSelected, setPageNameSelected] = useState();
	const [zonesDataOfAccount, setZonesDataOfAccount] = useState();
	const [zoningCustomizeListValues, setZoningCustomizeListValues] = useState([]);
	const [stepBusinessOperationCreate, setStepBusinessOperationCreate] = useState(1);
	const [selectedZone, setSelectedZone] = useState();
	const [poppinIsCliked, setPoppinIsClicked] = useState(false);
	const [listBanners, setListBanners] = useState([]);
	const [isexistant, setIsExistant] = useState(false);
	const [listZones, setListZones] = useState([]);
	const [FirstRenderOfZones, setFirstRenderOfZones] = useState(true);
	const [isFakeUpload, setIsFakeUpload] = useState(false);
	const [operation, setOperation] = useState({
		operation: {
			name: '',
			description: '',
			accountId: '',
			startDate: '',
			endDate: '',
			extensions: { devices: ['mobile'] },
			isActive: false,
			isStopped: false,
			locations: [
			],
			specifications: []
		}
	});
	const [deviceSelected, setDeviceSelected] = useState('Mobile');
	const [listOfOperationExistant, setListOfOperationExistant] = useState([]);
	const [statusOfSetup, setStatusOfSetup] = useState();
	const [isCustomize, setIsCustomize] = useState();
	const [valueSpecificationToPut, setValueSpecificationToPut] = useState();
	const [targetCondition, setTargetCondition] = useState();
	const [userSimplifyRole, setUserSimplifyRole] = useState('simplifyAdmin');
	const [planAccount, setPlanAccount] = useState('gold');
	const [transformationActivate, settransformationActivate] = useState(false);
	const [isTransformExistingBanner, setisTransformExistingbanner] = useState(false);
	const [recordedLastCtaValue, setRecordedLastCtaValue] = useState();
	const [bannerResizeWorkflows, setBannerResizeWorkflows] = useState([]);
	const [addVideoModalIsOpen, setAddVideoModalIsOpen] = useState(false);


	const [operationLabelIds, setOperationLabelIds] = useState();
	// New Widget TwicPics 
	const [widgetTwicPicIsopen, setWidgetTwicPicIsopen] = useState(false);
	const [bannerSelected, setBannerSelected] = useState(null);
	
	const { sortPageTypeHook } = useSort();

	const checkOperationExistant = () => {
		let banniersAssociations = operation.operation.locations.map(x => x.bannerId);
		setListOfOperationExistant([...banniersAssociations]);
	};


	useEffect(() => {
		const ChangeStatusCustomizableSetup = () => {
			if (statusOfSetup === 'Draft') {
				setIsCustomize(false);
			} else {
				setIsCustomize(true);
			}
		};
		ChangeStatusCustomizableSetup();
	}, [statusOfSetup]);

	useEffect(() => {
		if (!operationLabelIds) {
			const operationId = operation && operation.operation.operationId;
			operationId && labelService.getLabelsForObject(props.accountId, operationId, 'businessOperation', data => {
				const ids = data.map(x => x.id);
				setOperationLabelIds(ids);
			}, err => {
				console.log('An Error occured while fetching labels for operation: ' + err);
			});

		}
	}, [operation]);

	const getZones = (pageType, callbackSuccess) => props.businessOperationServices.getZones(props.accountId, pageType, callbackSuccess,
		error => props.systemServices.showError('an error occurred'));

	useEffect(() => {
		if (FirstRenderOfZones) {
			fetchZones();
		}
	}, [arrayOfTypePage]);

	useEffect(() => {
		getArrayOfPagesTypeIfOpExist();
	}, [zonesDataOfAccount]);


	useEffect(() => {
		getArrayOfPagesType();
	}, [zonesDataOfAccount]);

	//Then get page Type array
	const getArrayOfPagesTypeIfOpExist = () => {
		if (isexistant && isFirstRenderOfExistantOp) {
			let newArray = [];
			zonesDataOfAccount && zonesDataOfAccount.forEach(item => newArray.push({ pageType: item.pageType, screenShoot: item.imagePath, name: item.name, devices: item.device }));
			newArray.length > 0 && newArray.sort(sortPageTypeHook);
			setArrayOfTypePage(newArray);
			checkOperationExistant();
		}
	};


	const getArrayOfPagesType = () => {
		if (zonesDataOfAccount && !isexistant) {
			let newArray = [];
			//zonesDataOfAccount && zonesDataOfAccount.forEach(item => newArray.push(item.nameZone))
			zonesDataOfAccount && zonesDataOfAccount.forEach(item => newArray.push({ pageType: item.pageType, screenShoot: item.thumbnailPath, name: item.name, devices: item.device }));
			newArray.length > 0 && newArray.sort(sortPageTypeHook);
			setArrayOfTypePage(newArray);
		}
	};

	const fetchZones = () => {
		//TODO: refactor (merge 2 ifs)

		// Fetch zones of the account if it's creation operation and if it's the first render of the component 
		if (arrayOfTypePage.length && !isexistant && FirstRenderOfZones) {
			arrayOfTypePage.forEach(item => {
				getZones(item.pageType,
					newData => {
						setStatusOfSetup(newData.status);
						setListZones((list => [...list, ...newData.zones]));
					});
			});
			setFirstRenderOfZones(false);
		}
		if (isexistant && arrayOfTypePage.length && listZones.length <= 0 && FirstRenderOfZones) {
			arrayOfTypePage.forEach(item => {
				getZones(item.pageType,
					newData => {
						setStatusOfSetup(newData.status);
						setListZones((list => [...list, ...newData.zones]));
					});
			});
			setFirstRenderOfZones(false);
		}
	};
	const [navigationDimension, setnavigationDimension] = useState('images');
	const handleSelectDimention = (dimension) => {
		setnavigationDimension(dimension);
	};

	const saveLabels = (operationId, successCallback) => {
		labelService.putAllLabelsForObject(props.accountId, operationId, 'businessOperation', operationLabelIds, successCallback, err => console.log(err));
	};

	const operationSavedCallback = (operationId, callback) => {
		saveLabels(operationId, callback || (() => { }));
	};

	return (
		<CreateBusinessOperationContext.Provider value={{
			selectionPagesType,
			setSelectionPagesType,
			listZones,
			pageNameSelected,
			setPageNameSelected,
			zoningCustomizeListValues,
			setZoningCustomizeListValues,
			stepBusinessOperationCreate,
			setStepBusinessOperationCreate,
			isVisitedLink,
			setIsVisitedLink,
			selectedZone,
			setSelectedZone,
			zonesDataOfAccount,
			setZonesDataOfAccount,
			operation,
			setOperation,
			poppinIsCliked,
			setPoppinIsClicked,
			listBanners,
			setListBanners,
			isexistant,
			setIsExistant,
			arrayOfTypePage,
			devices,
			setDevices,
			isFirstRenderOfExistantOp,
			setIsFirstRenderOfExistantOp,
			isFakeUpload,
			setIsFakeUpload,
			checkOperationExistant,
			setListOfOperationExistant,
			deviceSelected,
			setDeviceSelected,
			isCustomize,
			valueSpecificationToPut,
			setValueSpecificationToPut,
			targetCondition,
			setTargetCondition,
			userSimplifyRole,
			setUserSimplifyRole,
			planAccount,
			setPlanAccount,
			transformationActivate,
			settransformationActivate,
			isTransformExistingBanner,
			setisTransformExistingbanner,
			recordedLastCtaValue,
			setRecordedLastCtaValue,
			bannerResizeWorkflows,
			setBannerResizeWorkflows,
			labelService,
			operationLabelIds,
			setOperationLabelIds,
			navigationDimension,
			handleSelectDimention,
			addVideoModalIsOpen,
			setAddVideoModalIsOpen,
			systemServices,
			operationSavedCallback,
			widgetTwicPicIsopen,
			setWidgetTwicPicIsopen,
			bannerSelected,
			setBannerSelected,
			$rootScope
		}}>
			{props.children}
		</CreateBusinessOperationContext.Provider>
	);

};
export default CreateBusinessOperationProvider;