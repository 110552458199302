import React, { useState, useEffect, useContext } from 'react';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import PopinConfirm from './../../../../Components/PopinConfirm';
import Btn from './../../../../Components/Btn';
import triggerList from './TargetTriggerList';
import triggerListOption from './TriggerListSelectionOption';
import './TargetConditionsContainer.css';
import { Can } from '../../../../Components/Can';
export default function TargetConditionsContainer({ setModalIsOppen }) {
	const {
		operation,
		setOperation,
		setValueSpecificationToPut,
		setTargetCondition,
		userSimplifyRole,
		planAccount,
	} = useContext(CreateBusinessOperationContext);

	const [popinIsOpen, setPopinIsOpen] = useState(false);
	const [idToDelete, setIdToDelete] = useState();
	let specificationList = operation.operation.specifications;

	const changeValueToPut = (spec) => {
		setValueSpecificationToPut(spec);
		const targetCondition = triggerList.filter(x => x.name === spec.name)[0];
		setTargetCondition(targetCondition);
		if (targetCondition.shouldOpenEditionPopin) {
			setModalIsOppen(true);
		}
	};
	const deleteATrigger = (data) => {
		let op = { ...operation };
		let newObjSpecifications = [...op.operation.specifications.filter(x => x.id !== data)];
		op.operation.specifications = newObjSpecifications;
		setOperation(op);
	};
	const handleFilter = (e, id) => {
		let op = { ...operation };
		// const newSelection = e.target.value.split(',')
		// const id = newSelection[1]
		let objToPut = op.operation.specifications.filter(x => x.id == id)[0];
		objToPut.operatorName = e;
		let newObjSpecifications = [...op.operation.specifications.filter(x => x.id != id), objToPut];
		op.operation.specifications = newObjSpecifications;
		setOperation(op);

	};
	function sortConditionByAsc(a, b) {
		if (a && b) {
			if (a.id < b.id) return -1;
			if (a.id > b.id) return 1;
		}
		return 0;
	}

	return (
		<section className="new_triggers_active_wrapper step_row">
			{popinIsOpen &&
				<PopinConfirm
					cancel={() => setPopinIsOpen(false)}
					title={'Are you sure you want to delete this trigger ?'}
					message={''}
				>
					<Btn
						onClickFunction={(e) => { setPopinIsOpen(false); }}
						message="Cancel"
						color="secondary"
						style="outline"
					/>
					<Btn
						onClickFunction={(e) => { deleteATrigger(idToDelete); setPopinIsOpen(false); }}
						message="Delete"
						color="alert"
						icon="fas fa-trash-alt"
					/>
				</PopinConfirm>
			}

			<div className="new_triggers_active_inner">
				<p className="title">Conditions</p>
				<div className="triggers">
					{
						specificationList.sort(sortConditionByAsc).map((spec, i) =>
							<Can
								key={spec.id}
								rules={planAccount}
								role={userSimplifyRole}
								perform={triggerList.find(x => x.name === spec.name) && triggerList.find(x => x.name === spec.name).permissionId}
								yes={() => (
									<div className="trigger new-union-parent" >
										<div className="trigger-inner">
											<div className="trigger-label">
												<button className="delete-trigger" onClick={(e) => { setIdToDelete(spec.id); setPopinIsOpen(true); }}><i className="fa fa-times"></i></button>
												<span className="ng-binding"> {triggerList.filter(x => x.name === spec.name)[0].label}</span>
												<div className="tooltip_inner">
													<i className="fa fa-info-circle"></i>
													<div className="tooltip_content">{triggerList.filter(x => x.name === spec.name)[0].info}</div>
												</div>
											</div>
											<div>
												{triggerList.filter(x => x.name === spec.name)[0].canSelectOption &&
													<select
														className="simplify_select_trigger"
														name="selectionTrigger"
														onChange={(e) => handleFilter(e.target.value, spec.id)}
														value={spec.operatorName}
													>
														<>
															{
																triggerListOption.map((opt, i) =>
																	<option key={i} value={opt.value}>{opt.label}</option>)
															}
														</>
													</select>
												}
											</div>
											<div className="put_trigger" onClick={(e) => { changeValueToPut(spec); }}>
												<button title="Edit the list"><i className="fas fa-pen "></i></button>
												<span className="simplify_item_on_list">
													{spec.valueToCompare.length && spec.valueToCompare.split(';').length} items
												</span>
											</div>
										</div>
										{
											specificationList.length > 1 && i !== specificationList.length - 1 &&
											<>
												<div className="union-line"></div>
												<div className="union-inner">AND</div>
											</>
										}
									</div>
								)}
								no={() => (
									<div className="trigger new-union-parent">
										<div className="trigger-inner">
											<div className="trigger-label">
												<button><i className="fa fa-times"></i></button>
												<span> {triggerList.filter(x => x.name === spec.name)[0] && triggerList.filter(x => x.name === spec.name)[0].label}</span>
												<div className="tooltip_inner">
													<i className="fa fa-info-circle"></i>
													<div className="tooltip_content ng-binding">{triggerList.filter(x => x.name === spec.name)[0] && triggerList.filter(x => x.name === spec.name)[0].info}</div>
												</div>
											</div>
											<div>
												{triggerList.filter(x => x.name === spec.name)[0] && triggerList.filter(x => x.name === spec.name)[0].canSelectOption &&
													<select
														className="simplify_select_trigger disabled"
														name="selectionTrigger"
														disabled
														value={spec.operatorName}
													>
														<>
															{
																triggerListOption.map((opt, i) =>
																	<option key={i} value={opt.value}>{opt.label}</option>)
															}
														</>
													</select>
												}
											</div>
											<div className="put_trigger disabled" >
												<button title="Edit the list"><i className="fas fa-pen "></i></button>
												<span className="simplify_item_on_list">
													{typeof spec.valueToCompare === 'string' && spec.valueToCompare.split(';').length} items
												</span>
											</div>
										</div>
										{
											specificationList.length > 1 && i !== specificationList.length - 1 &&
											<>
												<div className="union-line"></div>
												<div className="union-inner">AND</div>
											</>
										}
									</div>
								)}
							/>)}


					{specificationList.length == 0 &&
						<>
							No target condition has been defined for this campaign yet.
						</>
					}
				</div>
			</div>
		</section>
	);
}
