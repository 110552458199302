import React, { useState, useEffect } from 'react'
import './TemplateDisplay.css'
import { CampaignsContext } from '../../context'
import Btn from '../../../../Components/Btn';
import Dropdown from '../../../../Components/Dropdown';
import InputCustom from '../../../../Components/InputCustom';
import { checkURL } from '../../util';
import {useTranslation} from 'react-i18next';

export default function FormatLivePreview() {
    const {
        handlePreviewClicked,
        campaign,
        setCampaign,
        systemServices
    } = CampaignsContext()
	const { t, i18n } = useTranslation(['common']);
    const [previewURL, setPreviewURL] = useState(campaign.UrlDebug)
    const [liveDropdownIsOpen, setLiveDropdownIsOpen] = useState(false)
    const [liveError, setLiveError] = useState("");
    const [loadingURL, setLoadingURL] = useState(false);
    const [URL, setURL] = useState("");

	useEffect(() => {
        if (!campaign.UrlDebug) return;
        if (previewURL !== campaign.UrlDebug) {
            setPreviewURL(campaign.UrlDebug);
        }
    }, [campaign]);
	
    useEffect(() => {
        setLiveError('')
    }, [previewURL])

    function handlePreview() {
        if (!previewURL) {
            setLiveError(t('preview.urlIsEmpty'));
            return;
        }

        let url = previewURL;

        if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
            url = 'https://' + url;
        }

        const urlIsValid = checkURL(url);
        if (!urlIsValid) {
            setLiveError(t('preview.notValid'));
            return;
        }

        setLoadingURL(true);

        setLiveError('');
        const newCampaign = { ...campaign, UrlDebug: url }
        setCampaign(newCampaign)
        handlePreviewClicked(newCampaign, success => {
            setLoadingURL(false);
            const { winOpened, url } = success
            setURL(url)
        }, error => {
            setLoadingURL(false);
            systemServices.showError('an error occured')
            setLiveError("An error occured, please contact your CSM.");
            setURL("")
        })
    }

    const close = () => {
        setLiveDropdownIsOpen(false)
    }

    return (
        <>
            <Dropdown
                isOpen={liveDropdownIsOpen}
                setIsOpen={(e) => setLiveDropdownIsOpen(true)}
                onHide={close}
                refreshUseEffect={[liveError]}
                button={
                    <a>
                        <i className='fas fa-external-link-alt'></i>
						{t('preview.Preview')} 
                    </a>
                }
            >
                <div className="format_preview_live_dropdown grey_0">
                    <section className="section ">
                        <a className="dropdown_close icon_btn s" onClick={(e) => setLiveDropdownIsOpen(false)}>
                            <i className="fas fa-times"></i>
                        </a>

                        <div className="s_16 fw_medium"> {t('preview.livePreviewOnWebSite')}</div>
                        <div className="grey_2 s_13 mt_3">
                            <div>{t('preview.enterPage')}</div>
                            <div>{t('preview.afterEach')}</div>
                        </div>

                        <div className="flex flex_align_start format_preview_live_form">
                            <div className="flex_item_full">
                                <InputCustom
                                    placeholder="https://"
                                    onChange={(e) => setPreviewURL(e.target.value)}
                                    value={previewURL}
                                    fullWidth={true}
                                    autoFocus={true}
                                    onEnter={(e) => handlePreview()}
                                    isAnError={liveError}
                                />
                            </div>
                            <div className="flex_item_fix ml_10">
                                <Btn
                                    onClick={() => handlePreview()}
                                    message={t('preview.Preview')} 
                                />
                            </div>
                        </div>
                    </section>

                    {URL &&
                        <section className="section has_top_border s_14">
                            {t('preview.block')}
                            <div>
                                <a href={URL} className="text_link ellips" target="_blank" rel="noopener noreferrer">
                                    {URL}
                                </a>
                            </div>
                        </section>
                    }
                </div>

                {loadingURL && 
                    <div className="mask">
                        <span className="wheel"></span>
                    </div>
                }
            </Dropdown>
        </>
    )
}