import React, {useState} from 'react';
import Modal from '../../../../../Components/Modal';
import editionStyles from '../../ProductFeedEdition.module.css';
import InputCustom from '../../../../../Components/InputCustom';
import Checkbox from '../../../../../Components/Checkbox';
import Btn from '../../../../../Components/Btn';
import {useProductFeedEditionContext} from '../../../context/ProductFeedEditionContext';

export const ModalMonoFieldCategory = ({
	modalMonoFieldCategoryIsOpen,
	setModalMonoFieldCategoryIsOpen,
	currentCategory
}) => {


	const {
		handleChangeTagConfig,
		tagConfig
	} = useProductFeedEditionContext();

	const [isExcluded, setIsExcluded] = useState(tagConfig.excludedCategories.includes(currentCategory.beyableItemName));

	const closeModal = () => {
		setModalMonoFieldCategoryIsOpen(false);
	};

	const submit = () => {
		const obj = {name : currentCategory.beyableItemName, isExcluded: isExcluded};
		handleChangeTagConfig(obj, 'Categories');
		closeModal();
	};

	return (
		<Modal
			isOpen={modalMonoFieldCategoryIsOpen}
			onClose={() => closeModal()}
		>
			<div className="modal_header has_border">
				<div>
					{currentCategory.beyableItemName}
				</div>
			</div>
			<div className="modal_body">
				<div>
					<Checkbox
						label={'Exclude category'}
						value={isExcluded}
						forcedChecked={isExcluded}
						onChange={(e) => setIsExcluded(!isExcluded)}
					>
					</Checkbox>
					<div className={editionStyles.text_checkbox_tag_mapping_modal}>
                        Exclude category
					</div>
				</div>
			</div>
			<div className="modal_footer has_border al_right">
				<Btn
					message={'Cancel'}
					style={'ghost'}
					color={'secondary'}
					onClick={() => closeModal()}/>
				<Btn
					message={'Ok'}
					onClick={() => submit()}
					disabled={currentCategory.name === ''}/>
			</div>
		</Modal>
	);
};