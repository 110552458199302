import React, { useEffect, useState, useRef } from 'react';
import styles from './Collapse.module.css';

export default function Collapse({
	label,
	children,
	defaultOpen = false
}) {

	const [isOpen, setIsOpen] = useState(defaultOpen);
	const bodyRef = useRef(null)

	const toggle = () => {
		if (!bodyRef.current) return;

		const delay = isOpen ? 0 : 300;
		const height = bodyRef.current.scrollHeight;
		bodyRef.current.style.height = height + 'px';
		bodyRef.current.style.overflow = 'hidden';

		setTimeout(function () {
			bodyRef.current.style.height = '';
			bodyRef.current.style.overflow = '';
		}, delay);

		setIsOpen(!isOpen);
	}

	useEffect(() => {
		if( defaultOpen && defaultOpen !== isOpen ){
			setIsOpen(defaultOpen);
		}
	}, [defaultOpen]);

	return (
		<div className={isOpen ? styles.wrapper_open : styles.wrapper_close}>
			<div className={styles.header}>
                <div className={styles.header_btn} onClick={() => toggle()}>
                    <span className={styles.arrow}>
                        <i className="fas fa-sm fa-chevron-down" style={{'display': 'block'}}></i>
                    </span>
                    {label}
                </div>
			</div>
			<div className={styles.body} ref={bodyRef}>
				{children}
			</div>
		</div>
	);
}
