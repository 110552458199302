// TODO : onChange return e.target.value 
import React, { Fragment } from 'react';
import './SelectCustom.css';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
export default function SelectCustom({
	optionsList,
	name,
	label = '',
	labelClassName = '',
	description = '',
	blockClassName = '',
	onChange,
	onChangeFunction, // DEPRECATED : replace by onChange
	defaultValueDisabled,
	value,
	disabled = false,
	fullWidth = false,
	autoWidth = false,
	widthDefault = false,
	size = 'm',
	color='grey',

}) {


	const selectdId = uuidv4();
	const idTooltip= uuidv4();
	const inputClass = ['custom_input', 'custom_select'];
	const blockClass = ['form_block'];

	if (fullWidth) {
		inputClass.push('w_full');
	}
	if (autoWidth) {
		inputClass.push('w_auto');
	}
	if (disabled) {
		inputClass.push('disabled');
	}

	const labelClass = ['form_block_label'];
	if (labelClassName) {
		labelClass.push(labelClassName);
	}

	// Size : xs, s, default, l, xl, xxl
	if (size) {
		inputClass.push(size);
	}

	// Color : grey, white
	if( color === 'white' ){
		inputClass.push('bg_' + color);
	}

	if (blockClassName) {
		blockClass.push(blockClassName);
	}

	const change = (e) => {
		if (typeof onChange === 'function') {
			onChange(e);
		}
		if (typeof onChangeFunction === 'function') {
			onChangeFunction(e);
		}
	};

	let defaultValue = '';
	if (typeof value !== undefined) {
		defaultValue = value;
	} else {
		if (defaultValueDisabled) {
			defaultValue = defaultValueDisabled.value;
		}
	}

	const select = (
		<label className={inputClass.join(' ')}>
			<select
				name={name}
				onChange={(e) => change(e)}
				value={defaultValue}
				id={selectdId}
				disabled={disabled}
				className="custom_input_value"
			>
				{defaultValueDisabled &&
					<option
						value={defaultValueDisabled.value}
						disabled>
						{defaultValueDisabled.label}
					</option>
				}
				{optionsList && optionsList.map((x, i) => {
					const hasTooltip = x.hasOwnProperty('tooltip');
					return (
						<option
							title={hasTooltip ?x.tooltip.toString() : '' }
							key={i}
							value={x.value}
							disabled={x.isDisabled ? true : null}
							className={x.informations ? 'selectInformations' : ''}>
							{x.label}
							{x.informations && ' - ' + x.informations}
						</option>
					);
				})}
			</select>
		</label>
	);

	if (label) {
		return (
			<div className={blockClass.join(' ')}>
				<div className={labelClass.join(' ')}>
					<label htmlFor={selectdId}>{label}</label>
				</div>
				{description &&
					<div className="form_block_description">{description}</div>
				}
				{select}
			</div>
		);
	}

	return select;
}
