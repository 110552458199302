import React, { useState, useEffect } from 'react';
import RecosListView from './RecosListView';

import Btn from '../../../Components/Btn';
import SearchBar from '../../../Components/SearchBar';
import EmptyState from '../../../Components/EmptyState';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';

const defaultPagin = {
	offset: 0,
	limit: 10,
};

export default function RecommendationsContainer({
	systemServices,
	productRecommendationServices,
	routeParams,
	isAdmin
}) {

	const [listRecommendations, setlistRecommendations] = useState([]);
	const [firstLoading, setFirstLoading] = useState(true);
	const [loading, setLoading] = useState(true);
	const [dataFiltered, setDataFiltered] = useState('Name');
	const [noData, setNoData] = useState(false);
	const [zeroData, setZeroData] = useState(false);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [totalCount, settotalCount] = useState(0);
	const [descSort, setdescSort] = useState(false);
	const [paginationOptions, setPaginationOptions] = useState(defaultPagin);
	const [creaDropdownIsOpen, setCreaDropdownIsOpen] = useState(false);

	const getRecos = (search, dataFiltered, descSort, paginationOptions, callbackSuccess) => {
		return productRecommendationServices.getRuleList(search, dataFiltered, descSort, paginationOptions, callbackSuccess, error => {
			systemServices.showError();
		});
	};

	const fetchData = (newPaginationOptions) => {
		if (newPaginationOptions) {
			setPaginationOptions(newPaginationOptions);
		} else {
			newPaginationOptions = {...paginationOptions};
		}
		setLoading(true);
		getRecos(valueInSearchBar, dataFiltered, descSort, newPaginationOptions, (newData, headers) => {
			const totalCount = headers()['x-total-count'];
			settotalCount(totalCount);
			setLoading(false);

			if(newData.length === 0){
				// Cas de la suppression
				if (newPaginationOptions.offset > 1 && totalCount > 0) {
					fetchData(defaultPagin);
					return;
				}
				setNoData(true);
				if (firstLoading) {
					setZeroData(true);
				}
			}else{
				setNoData(false);
			}

			setlistRecommendations(newData);
			setFirstLoading(false);
		}, error => {
			setLoading(false);
		});
	};

	useEffect(() => {
		fetchData(defaultPagin);
	}, [descSort, dataFiltered]);

	function handleClickFilter(el){
		if(dataFiltered === el){
			setdescSort(!descSort);
		}else{
			setDataFiltered(el);
			setdescSort(false);
		}
	}

	const handleChange = (event) => {
		const value = event.target.value;
		const str = value;
		setValueInSearchBar(str);
	};

	useEffect(()=>{
		if (!firstLoading) {
			const timer = setTimeout(() => {
				fetchData(defaultPagin);
			}, 1000);
			return () => {
				if (timer) clearTimeout(timer);
			};
		}
	},[valueInSearchBar]);

	let urlNewReco = '/ProductSettings/Recommendations/Setting';
	if (routeParams && routeParams.ka && isAdmin) {
		urlNewReco += '?ka=' + routeParams.ka;
	}

	let urlNewComposition = '/ProductSettings/RuleCompositions/Setting';
	if (routeParams && routeParams.ka && isAdmin) {
		urlNewComposition += '?ka=' + routeParams.ka;
	}

	return (
		<>
			<section className='section no_bottom_pad section_primary'>
				<div className="h1">Dynamic collections</div>
			</section>
			<section className="section">
				<div className="page_block">
					{!firstLoading && !zeroData &&
						<div className="flex mb_15">
							<div className="flex_item_full">
								<SearchBar
									onChangeValue={(e) => handleChange(e)}
									valueInSearchBar={valueInSearchBar}
									placeholder="Search"
									fullWidth={false}
								/>
							</div>
							<div className="flex_item_fix">
								<Dropdown
									isOpen={creaDropdownIsOpen}
									toggle={(s) => setCreaDropdownIsOpen(s)}
									button={
										<Btn
											message="New collection"
											icon="fas fa-plus fa-sm"
										/>
									}>
									<Listbox>
										<ListboxItem
											href={urlNewReco}
											message="New recommendations rule"
											size='l'
											icon='fas fa-thumbs-up'
										/>
										<li className='hr' />
										<ListboxItem
											href={urlNewComposition}
											message="New rule composition"
											size='l'
											icon='fas fa-layer-group'
										/>
									</Listbox>
								</Dropdown>
							</div>
						</div>
					}

					<div className='pos_rel'>
						{!noData &&
							<RecosListView
								routeParams={routeParams}
								recoList={listRecommendations}
								systemServices={systemServices}
								productRecommendationServices={productRecommendationServices}
								totalCount={totalCount}
								fetchData={fetchData}
								paginationOptions={paginationOptions}
								setPaginationOptions={setPaginationOptions}
								handleClickFilter={handleClickFilter}
								dataFiltered={dataFiltered}
								descSort={descSort}
								isAdmin={isAdmin}
							/>
						}

						{noData && !zeroData &&
							<EmptyState
								icon="fas fa-search"
								title="No matching rule"
								text="Please try another search"
								verticalSize="l"
								textSize="l"
							/>
						}

						{noData && zeroData &&
							<EmptyState
								icon="fas fa-thumbs-up"
								title="No recommendations rule yet"
								text="Configure and preview optimal recommendations for your visitors"
								verticalSize="l"
								textSize="l"
								primaryAction={
									<Btn
										href={urlNewReco}
										message="New recommendations rule"
									/>
								}
							/>
						}

						{!firstLoading && loading &&
							<SpinnerWheel wheelSize="l" />
						}
					</div>

					{firstLoading &&
						<SpinnerWheel wheelSize="l" display="block" verticalSize="xl" />
					}
				</div>
			</section>
		</>
	);
}
