import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import './SelectTagContainer.css'
import makeAnimated from 'react-select/animated';
export default function SelectTagsContainer({ metaDataToMap, putSelectTags, productFeedTags, activeFeed }) {
    const [defaultSelect, setDefaultSelect] = useState([])
    const optionList = metaDataToMap && Object.values(metaDataToMap).map(x => ({ value: x, label: x }))
    optionList && optionList.push({ value: "", label: "" })

    const chargeDefaultSelect = () => {
        const newArr = productFeedTags.map(
            val => ({ value: val, label: val, isFixed: true }))
        setDefaultSelect(newArr)
    }

    useEffect(() => {
        chargeDefaultSelect()
    }, [productFeedTags])

    return (
        <div className="select_tag_container">
            <label> Tags</label>
            <Select
                closeMenuOnSelect={true}
                value={defaultSelect}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 10000 }) }}
                menuPosition={"fixed"}
                isMulti
                options={optionList}
                placeholder={"Select tags"}
                onChange={putSelectTags}
            />
        </div>
    )
}
