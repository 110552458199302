export const duplicateRecoById = (
	recoId,
	routeParams,
	systemServices,
	serviceReference,
	functionToLoad,
	callbackSuccess,
	callbackError
) =>
	serviceReference.getRecommendation(
		recoId,
		(callbackSuccess) => {
			const cloneRecoRule = { ...callbackSuccess };
			cloneRecoRule.name = cloneRecoRule.name + ' cloned';
			serviceReference.createRecommendation(
				cloneRecoRule,
				(Success) => {
					let ka = '';
					if (routeParams && routeParams.hasOwnProperty('ka')) {
						ka = '&ka=' + routeParams.ka;
					}

					systemServices.showSuccess('The rule has been duplicated, redirect to the editing screen');
					functionToLoad('/ProductSettings/Recommendations/Setting?id=' + Success.id + ka);
				},
				(error) => {
					systemServices.showError();
					callbackError(error);
				}
			);
		},
		(error) => {
			systemServices.showError();
			callbackError(error);
		}
	);

export const deleteRecoById = (recoId, systemServices, serviceReference, callbackSuccess, callbackError) =>
	serviceReference.deleteRecommendation(
		recoId,
		(Sccess) => {
			callbackSuccess();
			systemServices.showSuccess('The rule has been deleted.');
		},
		(error) => {
			systemServices.showError();
			callbackError(error);
		}
	);
export const deleteCompositionById = (compositionId, systemServices, serviceReference, callbackSuccess, callbackError) =>
	serviceReference.deleteComposition(
		compositionId,
		(Sccess) => {
			callbackSuccess();
			systemServices.showSuccess('The rule has been deleted.');
		},
		(error) => {
			systemServices.showError();
			callbackError(error);
		}
	);
// API CALLS 
export const getProductCollation = (category, systemServices, serviceReference, callbackSuccess) => serviceReference.getProductCollation(
	category,
	callbackSuccess,
	error => systemServices.showError());
export const getCheckIfIsAValidUrl = (url, systemServices, serviceReference, callbackSuccess,errorCall) => serviceReference.getCheckIfIsAValidUrl(
	url,
	callbackSuccess,
	errorCall);



export const getRecommendation = (recoId, systemServices, serviceReference, callbackSuccess, callbackError) => serviceReference.getRecommendation(
	recoId,
	callbackSuccess,
	error => {
		systemServices.showError();
		callbackError(error);
	});
export const createReco = (reco,
	systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.createRecommendation(
	reco,
	callbackSuccess,
	error => {
		systemServices.showError();
		callbackError(error);
	});
export const putRecoById = (reco, recoId,
	systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.updateRecommendation(
	reco,
	recoId,
	callbackSuccess,
	error => {
		systemServices.showError();
		callbackError(error);
	});

export const getPreview = (options,
	paginationOptions,
	reco,
	systemServices, serviceReference,
	callbackSuccess, callbackError) => serviceReference.getProductListPreview(
	options,
	paginationOptions,
	reco,
	callbackSuccess,
	(error, error2) => {
		if (typeof error2.data == 'string' && error2.data.includes('GoogleApiException')){
			systemServices.showError('Error accessing Google document. Have you added read permissions to this document?');

		} else {
			systemServices.showError();
		}
		// error2.data && error2.data.length > 0 ? systemServices.showError(error2.data) : systemServices.showError();
		callbackError();
	});