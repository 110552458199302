import React, { useState } from 'react';
import { ReportGeneratorContext } from '../Context';
import Btn from '../../../Components/Btn';
import Dropdown from '../../../Components/Dropdown';

export default function EngagementLevel({
	campaign,
	allowSelect
}) {

	const {
		engagementLevels,
		handleSelectEngagementLevel
	} = ReportGeneratorContext();

	const [isOpen, setIsOpen] = useState(false);
	const selectedEngagementLevelId = campaign.engagementLevelId;
	const selectedLevel = selectedEngagementLevelId && engagementLevels.find(x => x.id === selectedEngagementLevelId);
	let engagementColor = 'grey';
	if( selectedLevel ){
		engagementColor = selectedLevel.color.toLowerCase();
	}

	return (
		<>
			{allowSelect &&
				<Dropdown
					isOpen={isOpen}
					setIsOpen={(e) => setIsOpen(true)}
					onHide={(e) => setIsOpen(false)}
					button={
						<a className={selectedLevel ? 'report_generator_list_btn has_arrow' : 'report_generator_list_btn has_arrow has_error'}>
							{selectedLevel ? 
								<>{selectedLevel.name || 'No name'}</>
								:
								<>Engagement level</>
							}
						</a>
					}>
					<ul className="listbox">
						{engagementLevels &&
							engagementLevels.map((level) => (
								<li key={level.id}>
									<a
										className={selectedEngagementLevelId === level.id ? 'listbox_item active' : 'listbox_item'}
										onClick={() => {
											handleSelectEngagementLevel(campaign, level);
											setIsOpen(false);
										}}
									>
										<span className={`report_generator_engagement color_${level.color}`}>
											{level.name || 'No name'}
										</span>
									</a>
								</li>)
							)}
					</ul>
				</Dropdown>
			}
		</>
	);
}


