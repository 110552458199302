import React, { useEffect, useState, useRef }  from 'react';
import {useProductFeedEditionContext} from '../../context/ProductFeedEditionContext';
import {fieldIsXpathCustom,transformXpathToNormalValue,transformXpathToXpathValue}  from '../../utils';
import SelectCustom from '../../../../Components/SelectCustom';
import editionStyles from '../ProductFeedEdition.module.css';
import InputCustom from '../../../../Components/InputCustom';
import SectionMessage from '../../../../Components/SectionMessage';
import SelectDropDownWithExampleValues from './SelectDropDownWithExampleValues';
import TransformationsContainer from './Transformations/TransformationsContainer';

export default function MappingPricesContainer({canUseTranformations}) {
	const {
		mappingObject,
		productFeedObject,
		handleChangeValueField,
		deleteValueKeyInMappingObject,
		metadatasSources,
		hasErrorsOnColums
	} = useProductFeedEditionContext();

	const hasKeyInObjet1 = mappingObject.hasOwnProperty('Price1');
	const hasKeyInObjet2 = mappingObject.hasOwnProperty('Price2'); 
	
	const [metadatasSourcesToMap, setmetadatasSourcesToMap] = useState([]);
	const [metadatasSourcesToMap2, setmetadatasSourcesToMap2] = useState([]);
	const [xPathSelected, setxPathSelected] = useState(false);
	const [xPathSelected2, setxPathSelected2] = useState(false);
	const [valueDoesntExistInList, setvalueDoesntExistInList] = useState(false);
	const [valueDoesntExistInList2, setvalueDoesntExistInList2] = useState(false);

	function handleChangeForm(e){
		const value = e.target.value;

		const name = e.target.name;
		// Check if xPathSelected or normal field
		if(value === 'Xpath'){
			if(name !== 'Price2'){
				setxPathSelected(true);
			}else if(name === 'Price2'){
				setxPathSelected2(true);
			}
			if(value !== 'Xpath' ){
				handleChangeValueField(name,value);
			}else{
				const valueToSave = 'xpath(' + '' + ')';
				handleChangeValueField(name, valueToSave);
			}
			return;
		}else{
			if(xPathSelected && name !== 'Price2'){
				setxPathSelected(false);
			}
			if(xPathSelected && name === 'Price2'){
				setxPathSelected2(false);
			}
			if(value === ''){
				deleteValueKeyInMappingObject(name);
			}else{
				handleChangeValueField(name,value);
			}
		}
	}
	function handleChangeFormInput(e){
		let value = e.target.value;
		const name = e.target.name;
		
		if(xPathSelected && name !== 'Price2'){
			value = transformXpathToXpathValue(value);
		}
		else if(xPathSelected2 && name === 'Price2'){
			value = transformXpathToXpathValue(value);
		}
			
		if(value === '' || value === 'xpath()'){
			deleteValueKeyInMappingObject(name);
		}else{
			handleChangeValueField(name,value);
		}
	}

	useEffect(()=>{
		if(productFeedObject?.type === 'GenericUri' && productFeedObject?.format?.type === 'Xml' &&  metadatasSources){
			if(hasKeyInObjet1){
				const valueOfField = mappingObject['Price1'][0].sourceField;
				if(fieldIsXpathCustom(valueOfField)){
					setvalueDoesntExistInList(false);
					transformXpathToNormalValue(valueOfField);
					setxPathSelected(true);
					setmetadatasSourcesToMap([...metadatasSources,{
						value: 'Xpath' ,
						label : 'Custom value - Xpath'
					}]);
				}else{
					const valueOfField = mappingObject['Price1'][0].sourceField;
					const testIfExist = metadatasSources.find(el => el.value === valueOfField );
					if(!testIfExist){
						setvalueDoesntExistInList(true);
					}else{
						setvalueDoesntExistInList(false);
					}
					setmetadatasSourcesToMap([...metadatasSources,{
						value: 'Xpath' ,
						label : 'Custom value - Xpath'
					}]);
					setxPathSelected(false);
				}
				
			}else{
				setmetadatasSourcesToMap([...metadatasSources,{
					value: 'Xpath' ,
					label : 'Custom value - Xpath'
				}]);
			}
		}else if(productFeedObject?.type !== 'Custom' && metadatasSources){
			if(hasKeyInObjet1){
				const valueOfField = mappingObject['Price1'][0].sourceField;
				const testIfExist = metadatasSources.find(el => el.value === valueOfField );
				if(!testIfExist){
					setvalueDoesntExistInList2(true);
				}else{
					setvalueDoesntExistInList2(false);
				}
			}
			setmetadatasSourcesToMap([...metadatasSources]);
		}
		if(productFeedObject?.type === 'GenericUri' && productFeedObject?.format?.type === 'Xml' &&  metadatasSources){
			if(hasKeyInObjet2){
				const valueOfField = mappingObject['Price2'][0].sourceField;
				if(fieldIsXpathCustom(valueOfField)){
					setvalueDoesntExistInList2(false);
					transformXpathToNormalValue(valueOfField);
					setxPathSelected2(true);
					setmetadatasSourcesToMap2([...metadatasSources,{
						value: 'Xpath' ,
						label : 'Custom value - Xpath'
					}]);
				}else{
					const valueOfField = mappingObject['Price2'][0].sourceField;
					const testIfExist = metadatasSources.find(el => el.value === valueOfField );
					if(!testIfExist){
						setvalueDoesntExistInList2(true);
					}else{
						setvalueDoesntExistInList2(false);
					}
					setmetadatasSourcesToMap2([...metadatasSources,{
						value: 'Xpath' ,
						label : 'Custom value - Xpath'
					}]);
					setxPathSelected2(false);
				}
				
			}else{
				setmetadatasSourcesToMap2([...metadatasSources,{
					value: 'Xpath' ,
					label : 'Custom value - Xpath'
				}]);
			}
		}else if(productFeedObject?.type !== 'Custom' && metadatasSources){
			if(hasKeyInObjet2){
				const valueOfField = mappingObject['Price2'][0].sourceField;
				const testIfExist = metadatasSources.find(el => el.value === valueOfField );
				if(!testIfExist){
					setvalueDoesntExistInList2(true);
				}else{
					setvalueDoesntExistInList2(false);
				}
			}
			setmetadatasSourcesToMap2([...metadatasSources]);
		}
	},[metadatasSources,productFeedObject?.type,xPathSelected,xPathSelected2]);
	useEffect(()=>{
		if(!hasKeyInObjet1){
			setvalueDoesntExistInList(false);
		}
		if(!hasKeyInObjet2){
			setvalueDoesntExistInList2(false);
		}
	},[mappingObject]);

	const getValue = (name, xPathSelect, valueDoesntExist, hasKeyInObjetIdx) => {	
		if (xPathSelect) return 'Xpath';
		if (valueDoesntExist) return 'choose';
		if (hasKeyInObjetIdx) return mappingObject[name][0].sourceField;
		return 'choose';
	};

	return (
		<>
			{productFeedObject?.type !== 'Custom' && 
				<>
					<SelectDropDownWithExampleValues
						label="Price 1"
						editionStyles={editionStyles}
						metaDataSourcesToMap={metadatasSourcesToMap}
						valueDoesntExistInList={valueDoesntExistInList}
						hasKeyInObjet={hasKeyInObjet1}
						mappingObject={mappingObject}
						name={'Price1'}
						handleChangeForm={handleChangeForm}
						blockClassName='no_margin'
					/>
					{xPathSelected &&
						<div className='mt_10'>
							<InputCustom
								value={hasKeyInObjet1 ? transformXpathToNormalValue(mappingObject['Price1'][0].sourceField): '' }
								type="text"
								label="Custom value"
								name={'Price1'}
								placeholder="Xpath"
								onChange={handleChangeFormInput}
								fullWidth={true}
								size="l"
							/>
						</div>
					}
					{hasKeyInObjet1 && valueDoesntExistInList && !xPathSelected &&
						<div className='mt_10'>
							<SectionMessage
								type="warning"
								title={`"${mappingObject['Price1'][0].sourceField}" doesnt exist`}
								text={`${mappingObject['Price1'][0].sourceField} doesnt exist, please choose value that exist in list`}
							/>
						</div>
					}
				</>
			}
			{productFeedObject?.type === 'Custom' &&
				<InputCustom
					value={hasKeyInObjet1 ? mappingObject['Price1'][0].sourceField: '' }
					type="text"
					label={'Price1'}
					name={'Price1'}
					placeholder=""
					onChange={handleChangeForm}
					fullWidth={true}
					blockClassName='no_margin'
				/>
			}
			{canUseTranformations  && hasKeyInObjet1 &&
				<TransformationsContainer
					objectIdenfifier={'Price1'}
				/>
			}
			
			<hr />
			{productFeedObject?.type !== 'Custom' &&
				<>
					<SelectDropDownWithExampleValues
						label="Price 2"
						editionStyles={editionStyles}
						metaDataSourcesToMap={metadatasSourcesToMap}
						valueDoesntExistInList={valueDoesntExistInList}
						hasKeyInObjet={hasKeyInObjet1}
						mappingObject={mappingObject}
						name={'Price2'}
						handleChangeForm={handleChangeForm}
						blockClassName='no_margin'
					/>		
				
					
					{xPathSelected2 &&
						<div className='mt_10'>
							<InputCustom
								value={hasKeyInObjet2 ? transformXpathToNormalValue(mappingObject['Price2'][0].sourceField): '' }
								type="text"
								label="Custom value"
								name={'Price2'}
								placeholder="Xpath"
								onChange={handleChangeFormInput}
								fullWidth={true}
								size="l"
							/>
						</div>
					}
					{hasKeyInObjet2 && valueDoesntExistInList2 && !xPathSelected2 && !hasErrorsOnColums &&
						<div className='mt_10'>
							<SectionMessage
								type="warning"
								title={`"${mappingObject['Price2'][0].sourceField}" doesnt exist`}
								text={`${mappingObject['Price2'][0].sourceField} doesnt exist, please choose value that exist in list`}
							/>
						</div>
					}
				</>
			}
			{productFeedObject?.type === 'Custom' &&
				<InputCustom
					value={hasKeyInObjet2 ? mappingObject['Price2'][0].sourceField: '' }
					type="text"
					label={'Price2'}
					name={'Price2'}
					placeholder=""
					onChange={handleChangeForm}
					fullWidth={true}
					blockClassName='no_margin'
				/>
			}
			{canUseTranformations && hasKeyInObjet2 &&
				<TransformationsContainer
					objectIdenfifier={'Price2'}
				/>
			}
			{hasErrorsOnColums && 
				<div className='mt_10'>
					<SectionMessage
						type="warning"
						title={'Please check the feed accessibility'}
					/>
				</div>
			}
		</>
	);
}
