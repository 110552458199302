import React from 'react';
import './FormatEditor.css';
import { CampaignsContext } from '../../context';
import SideBar from '../SideBar/SideBarContainer';
import TemplateDisplay from './TemplateDisplay';

export default function FormatEditor() {
	const {
		availableFormats,
		step,
		isSaving,
		editorMode
	} = CampaignsContext();

	return (
		step === 1 && (
			<div className="format_editor flex flex_align_stretch">
				{editorMode !== 'appNative/patch/code' && <SideBar />}
				{editorMode != 'custom' &&
					<TemplateDisplay />
				}
				{!availableFormats.loaded && (
					<div className="mask">
						<div className="wheel size_32"></div>
					</div>
				)}
				{isSaving && (
					<div className="mask">
						<div className="wheel size_32"></div>
					</div>
				)}
			</div>
		)
	);
}
