import React, { useState, useEffect } from 'react';
import './DatesHeader.css';
export default function DatesHeader({ dates, today }) {

	const [weeksDates, setWeeksDates] = useState([]);
	const getMonthNamesForSplanningHeader = (arrayOfDates) => {
		if (arrayOfDates.length === 28) {
			let newArrOfMonths = [];
			function pushAllMonth(array) {
				let intermediateArray = [];
				array.forEach(d => {
					if (!intermediateArray.includes(d.format('MMMM'))) {
						intermediateArray.push(d.format('MMMM'));
					}
				});
				for (let index = 0; index < intermediateArray.length; index++) {
					if (index !== 0) {
						intermediateArray[index] = ` - ${intermediateArray[index]}`;
					}
				}
				return newArrOfMonths = [...newArrOfMonths, [...intermediateArray]];
			}
			pushAllMonth(arrayOfDates.slice(0, 6));
			pushAllMonth(arrayOfDates.slice(7, 13));
			pushAllMonth(arrayOfDates.slice(14, 20));
			pushAllMonth(arrayOfDates.slice(21, 27));
			setWeeksDates(newArrOfMonths);
		}
	};

	const getClass = (date) => {
		const cssClass = ['splanning_header_cell'];
		if( today.isSame(date) ){
			cssClass.push('is_today');
		}
		if( today > date ){
			cssClass.push('is_past');   
		}
		return cssClass.join(' ');
	};

	useEffect(() => {
		if (dates) {
			getMonthNamesForSplanningHeader(dates);
		}
	}, [dates]);

	return (
		<>
			<div className="splanning_header_row">
				{weeksDates && weeksDates.map((week, i) =>
					<div key={i} className="splanning_header_cell">{week.join('')}</div>)
				}
			</div>
			<div className="splanning_header_row s">
				{dates &&
                    dates.map((date, i) =>
                    	<div
                    		key={i}
                    		className={getClass(date)}
                    	>
                    		{date.format('DD')}
                    	</div>
                    )}
			</div>
		</>
	);
}
