import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import { ContextProvider } from './context';
import ABTestsReporting from './ABTestsReporting';
import AbTestsServices from '../../../Services/AbTestsServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import CampaignCreatingServices from '../../../Services/CampaignCreatingServices';
import SystemServices from '../../../Services/SystemServices';
import { I18nextProvider,Trans,useTranslation } from 'react-i18next';

import i18next from 'i18next';
import onsite_list_view_en from '../../../Translation/jsonfiles/en/onsite_list_view_en.json';
import onsite_list_view_fr from '../../../Translation/jsonfiles/fr/onsite_list_view_fr.json';
import utils_en from '../../../Translation/jsonfiles/en/utils_en.json';
import utils_fr from '../../../Translation/jsonfiles/fr/utils_fr.json';
import {mergeJson} from '../../../Translation/translation-utils';
export default function ABTestsReportingContainer(props) {

	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	const authServices = props.AuthServices;
	
	const systemServices = new SystemServices($rootScope, $timeout);

	const impersonatingServices = new ImpersonatingServices(
		$rootScope,
		$routeParams
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope.User.Account.Key);

	const abTestsServices = new AbTestsServices(props.$http, accountId);
	
	const getOneCampaign = (objective,callbackSuccess) => abTestsServices.getOneCampaign($routeParams.Id,objective, callbackSuccess, () => {
		systemServices.showError('An error occurred while trying to get campaign');
	});

	const currentUser = authServices.GetAuthenticatedUser();
	const campaignCreatingServices = new CampaignCreatingServices(props.$http, accountId,authServices);
	const [ka, setKa] = useState(false);
	useEffect(() => {
		const isAdminTest = currentUser && currentUser.Role === 'Admin';
		setKa(props.$routeParams && props.$routeParams.ka && isAdminTest ? 'ka=' + accountId : '');
	}, []);
	const i18nL= localStorage.getItem('i18nL');
	const newInstanceI18nextOnsite = i18next.createInstance();
	newInstanceI18nextOnsite.init({
		lng: 'en-EN',                             
		resources: {
			'en-US': {
				common: mergeJson(onsite_list_view_en, utils_en)
			},
			'en-EN': {
				common: mergeJson(onsite_list_view_en, utils_en)
			},
			'fr-FR': {
				common: mergeJson(onsite_list_view_fr, utils_fr)
			},
		}},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextOnsite.changeLanguage(JSON.parse(i18nL));
	});
	return (
		<I18nextProvider i18n={newInstanceI18nextOnsite}>
			<ContextProvider
				campaignId={$routeParams && $routeParams.Id}
				getOneCampaign={getOneCampaign}
				ka={ka}
				accountId={accountId}
				$routeParams={$routeParams}
				campaignCreatingServices={campaignCreatingServices}
				systemServices={systemServices}
				$rootScope={$rootScope}
			>
				<ABTestsReporting></ABTestsReporting>
			
			</ContextProvider>
		</I18nextProvider>
	);
}

angular
	.module('beyableSaasApp.ABTestsReporting', ['beyableSaasApp.Services'])
	.component('abtestsreporting', react2angular(ABTestsReportingContainer, [],
		['$rootScope', '$http', '$routeParams', '$timeout', '$location', 'AuthServices']));