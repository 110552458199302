import React from 'react';
import Nav from '../Nav';
import { useEcommerceContext } from '../context/ContextEcommerce';

export default function NavEcommerce() {
	const {	ComparisonSelected,
		setNavFilter,
		handleSelectComparaisonPeriodHandler,
		setisCustomSelected,
		isCustomSelected,
		customDateRange,
		initiNavDate,
		navFilter,
		setCustomDateRange
	} = useEcommerceContext();

	let initialDates = initiNavDate(navFilter);

	function handleClickOriginal(obj) {
		setNavFilter(stateInit => ({
			...stateInit,
			fromDate: obj.fromDate,
			toDate: obj.toDate
		}
		));
	}

	return (
		<Nav
			handleClick={handleClickOriginal}
			initialDates={initialDates}
			showComparisonToggler={true}
			setComparisonIsEnable={handleSelectComparaisonPeriodHandler}
			comparisonSelected={ComparisonSelected}
			setisCustomSelected={setisCustomSelected}
			isCustomSelected={isCustomSelected}
			customDateRange={customDateRange}
			setCustomDateRange={setCustomDateRange}
		/>
	);
}
