import React, { useState } from 'react';
import Dropdown from "../../../../Components/Dropdown";
import Btn from "../../../../Components/Btn";
import { v4 as uuidv4 } from 'uuid';
import { DayPickerSingleDateController } from 'react-dates';
import ReactTooltip from 'react-tooltip'

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
const MonthSelectorOnSite = ({ dateSelected, setdateRange, checkTheDayOfWeek, dateOfTheSingleDatePicker,
    handleOnDateChange }) => {
    const idOfTodayDate = uuidv4();
    const idOfPreviousWeek = uuidv4();
    const idOfNextWeek = uuidv4();
    const today = (moment().startOf('day'));
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
    const [focusedInput, setFocusedInput] = useState(null);
    const handleChangeMonth = (direction, currentDateSelected) => {
        const tmpStartSelected = { ...currentDateSelected };
        if (direction === 'right') {
            checkTheDayOfWeek(tmpStartSelected.startDate, "after")
        } else {
            checkTheDayOfWeek(tmpStartSelected.startDate, "before")
        }
    }
    const goBackToTodayDate = () => {
        if (moment(dateSelected.startDate).isSame(today)) {
            return
        } else {
            checkTheDayOfWeek(today, "today")
        }
    }

    return (
        <>
            <ReactTooltip id={idOfPreviousWeek} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
            <ReactTooltip id={idOfNextWeek} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
            <ReactTooltip id={idOfTodayDate} className="labelKpi" backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
            <div className="btn_group_l">
                <div className="btn_switch">
                    <Btn
                        style="outline"
                        size="s"
                        color="secondary"
                        icon="fas fa-chevron-left"
                        className="btn_w_xs"
                        dataFor={idOfPreviousWeek}
                        dataTip={`Previous week`}
                        onClickFunction={() => handleChangeMonth('left', dateSelected)}
                    ></Btn>
                    <Btn
                        style="outline"
                        size="s"
                        color="secondary"
                        icon="fas fa-chevron-right"
                        className="btn_w_xs"
                        dataFor={idOfTodayDate}
                        dataTip={`Next week`}
                        onClickFunction={() => handleChangeMonth('right', dateSelected)}
                    ></Btn>
                </div>
                <Dropdown
                    isOpen={dropdownIsOpen}
                    setIsOpen={(e) => setDropdownIsOpen(true)}
                    onHide={(e) => setDropdownIsOpen(false)}
                    icon="fas fa-chevron-down"
                    button={
                        <Btn style="outline" color="secondary" arrow="true" size="s">
                            {dateOfTheSingleDatePicker.format('DD MMMM YYYY')}
                        </Btn>
                    }
                >
                    <DayPickerSingleDateController
                        date={dateOfTheSingleDatePicker}
                        onDateChange={(date) => {
                            handleOnDateChange(date);
                            setDropdownIsOpen(false)
                        }}
                        focused={true}
                        onFocusChange={({ focused }) => setFocusedInput(focused)}
                        numberOfMonths={1}
                        isOutsideRange={() => false}
                    />
                </Dropdown>
                <Btn
                    style="outline"
                    size="s"
                    color="secondary"
                    dataFor={idOfTodayDate}
                    dataTip={`${today.format('DD MMMM YYYY')}`}
                    onClickFunction={() => goBackToTodayDate()}
                >Today</Btn>
            </div>
        </>
    )
}
export default MonthSelectorOnSite
