import React, { useEffect, useState } from 'react';
import classes from './ImageTransformContainer.module.css';
export default function ImageTransformContainer({ imageData, imageSelectedToTransform, handleSelectImageToTransform, imagesSelectedTotranformation }) {

	const [numberOfSelectedTransformationForTheCurrentImage, setnumberOfSelectedTransformationForTheCurrentImage] = useState();
	useEffect(() => {
		const nbOfSelectedImages = imagesSelectedTotranformation.filter(img => img.transformationSourceId === imageSelectedToTransform.transformationId);
		const numberToRender = nbOfSelectedImages.length > 1 ? `${nbOfSelectedImages.length} transformations of this image.` : `${nbOfSelectedImages.length} image transformation of this image.`;
		setnumberOfSelectedTransformationForTheCurrentImage(numberToRender);
	}, [imagesSelectedTotranformation, imageSelectedToTransform]);
	if (!imageData) {
		return null;
	}
	function FileConvertSize(aSize) {
		aSize = Math.abs(parseInt(aSize, 10));
		var def = [[1, 'octets'], [1024, 'ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
		for (var i = 0; i < def.length; i++) {
			if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
		}
	}

	return (
		<div className="">
			<div className="flex flex_v" key={imageSelectedToTransform.url}>
				<div className="flex flex_justify_between" style={{ marginTop: '5px' }}>
					<div>
						<p>{imageSelectedToTransform.fileName}</p>
						<p>  {FileConvertSize(imageSelectedToTransform.bytes)}</p>
						<p> {imageSelectedToTransform.height} X {imageSelectedToTransform.width}</p>
					</div>
					<div>
						<h2>You have selected {numberOfSelectedTransformationForTheCurrentImage && numberOfSelectedTransformationForTheCurrentImage} </h2>
					</div>
				</div>
				<div>
					<h2>Transformations</h2>
					<div className={classes.imagetransformationsContainer}>
						{imageData.transforms.map((eager, idx) =>
							<img
								key={idx}
								onClick={() => handleSelectImageToTransform(eager)}
								className={imagesSelectedTotranformation && imagesSelectedTotranformation.find(img => img.id === eager.id) ? classes.imageTransformSelected : ''}
								src={eager.url}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
