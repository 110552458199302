import React, { useState, useEffect } from 'react'
import Btn from '../../../../Components/Btn'
import InputCustom from '../../../../Components/InputCustom';
import './NewTenantCreationContainer.css'
export default function NewTenantCreationContainer({ tenants, setOppenCreationTenant, setTenantSelected, putConfigProductFeed, accountConfigurationFeed }) {
    const [nameOfNewTenant, setNameOfNewTenant] = useState("")
    const [btnCreationIsDesabled, setBtnCreationIsDesabled] = useState(false)
    const handleChangeForm = (e) => {
        setNameOfNewTenant(e.target.value)
    }
    const desabledTheCreationButton = () => {
        if (nameOfNewTenant.trim() !== "") {
            const arrayTenantsNames = tenants && tenants.map(x => x.tenant)
            if (arrayTenantsNames && arrayTenantsNames.includes(nameOfNewTenant)) {
                setBtnCreationIsDesabled(true)
            } else {
                setBtnCreationIsDesabled(false)
            }
        }
    }
    useEffect(() => {
        desabledTheCreationButton()
    }, [nameOfNewTenant])
    return (
        <div className="form-creation-tenant">
            <h1>Create a new tenant</h1>
            <InputCustom
                name="nameOfNewTenant"
                placeholder="Name of the tenant"
                label="Tenant name"
                type="text"
                onChange={(e) => handleChangeForm(e)}
                value={nameOfNewTenant}
            />
            <div className="btn-container-creation-tenant">
                <Btn
                    disabled={btnCreationIsDesabled}
                    onClickFunction={
                        btnCreationIsDesabled ? () => console.log('The tenant name is empty') : (e) => {
                            setOppenCreationTenant(false); putConfigProductFeed([], nameOfNewTenant)
                        }}
                    message="Create"
                />
                <Btn
                    onClickFunction={(e) => { setOppenCreationTenant(false) }}
                    message="Cancel"
                    color="alert"
                />
            </div>
        </div>
    )
}

