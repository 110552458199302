import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import renderJsxTag from '../../Translation/translation-utils';
import Link from '../../Components/Link';
export default function NavReporting({
	$routeParams,
	isAdmin,
	accountId
}) {
	const [t, i18n] = useTranslation('common');
	const back = () => {
		if (isAdmin && $routeParams.ka) {
			return `Onsite/Dashboard?ka=${accountId}&ku=${$routeParams.ku}`;
		} else {
			return 'Onsite/Dashboard';
		}
	};
	return (
		<div className="page_sidebar_title">
			<Link href={back()} icon="fas fa-arrow-left fa-sm">
				Back to campaign list
			</Link>
		</div>
	);
}
