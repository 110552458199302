import React, { useEffect, useState } from 'react';
import Fieldset from '../../../../../Components/Fieldset';
import InputCustom from '../../../../../Components/InputCustom';
import SelectDropdown from '../../../../../Components/SelectDropdown';

import AutocompleteFilter from '../AutocompleteFilter';
import {useFiltersContext} from '../contextFilters';
const operators = [
	{
		value: 'Equals',
		label: 'Is equal to',
	},
	{
		value: 'NotEquals',
		label: 'Is not equal to',
	},
	{
		value: 'Contains',
		label: 'Contains',
	},
	{
		value: 'NotContains',
		label: 'Does not contain',
	},
	{
		value: 'StartsWith',
		label: 'Starts with',
	},
	{
		value: 'NotStartsWith',
		label: 'Does not start with',
	},
	{
		value: 'EndsWith',
		label: 'Ends with',
	},
	{
		value: 'NotEndsWith',
		label: 'Does not end with',
	},
	{
		value: 'Like',
		label: 'Is like',
	},
	{
		value: 'NotLike',
		label: 'Is not like',
	},
];
export default function StringContainer({
	data,
	handleChangeFilterByUniqueId,
	setupAccountServices,
	accountId,
	selectedTenant,
	previewTenant,
	needTenantForSearch
}) {
	const {
		tabFilterSelected
	} = useFiltersContext();
	const [currentValueString, setCurrentValueString] = useState('');
	const [currentValues, setCurrentValues] = useState();
	const [isAnError, setIsAnError] = useState({ isError: false, message: '' });
	const [valueOperatorSelected, setvalueOperatorSelected] = useState(
		data.op || operators[0].value
	);

	const [useAutoComplete, setUseAutoComplete] = useState(false);

	const onChangeAutocomplete = (text) => {
		let txtFormat = text;
		if (txtFormat.length) {
			txtFormat = txtFormat.replace(/(\n)+/g, ';');
			txtFormat = txtFormat.replace(/;+/g, ';');
		}
		setCurrentValueString(txtFormat);
		handleChangeValues(text);
	};

	// handle filter operator
	const handleSelectOperator = (operator) => {
		setvalueOperatorSelected(operator);
	};

	// case In/notIn
	const handleChangeValues = (textAreaValue) => {
		if (!textAreaValue) {
			setIsAnError({ isError: true, message: 'input cannot be empty' });
		} else {
			setIsAnError({ isError: false, message: '' });
		}
		setCurrentValues(textAreaValue);
	};

	// init component value/s on edit mod
	function getValueOfStringComponent() {

		const valueMultiple = tabFilterSelected.value.hasOwnProperty('stringValues');
		if (valueMultiple && data.value['stringValues'] !== null) {
	
			if (valueOperatorSelected === 'Equals' || valueOperatorSelected === 'NotEquals') {
				onChangeAutocomplete(tabFilterSelected.value['stringValues'].join('\n'));
			}
			setCurrentValues(tabFilterSelected.value['stringValues'].join('\n'));
		}else{
			setCurrentValues('');
		}
	}
	// update elementListFilterSelected State
	function handleChangeValuesById() {
		if (currentValues) {
			const myNewArrayOfcurrentValues = currentValues
				.split(/[;,""\n]/)
				.filter((x) => x.length > 0);
			handleChangeFilterByUniqueId({
				idFilter: tabFilterSelected.idFilter,
				op: valueOperatorSelected,
				value: { stringValues: myNewArrayOfcurrentValues },
			});
		} else if (!currentValues) {
			handleChangeFilterByUniqueId({
				idFilter: tabFilterSelected.idFilter,
				op: valueOperatorSelected,
				value: { stringValues: [] },
			});
		}
	}
	// init component on edit mode
	useEffect(() => {
		handleSelectOperator(tabFilterSelected.op || operators[0].value);
		getValueOfStringComponent();
	}, [tabFilterSelected]);

	//update filters state
	useEffect(() => {
		handleChangeValuesById();
	}, [data, currentValueString,
		currentValues,
		valueOperatorSelected]);

	useEffect(() => {
		if (data.name === 'Id') {
			if (valueOperatorSelected === 'Equals' || valueOperatorSelected === 'NotEquals') {
				setUseAutoComplete(true);
			}
			else setUseAutoComplete(false);
		}
		else setUseAutoComplete(false);

	}, [valueOperatorSelected, data]);


	return (
		<Fieldset label="Value">
			<div className="flex flex_align_start">
				<div className="flex_item_fix mr_10">
					<SelectDropdown
						optionsList={operators}
						value={valueOperatorSelected}
						onChange={(v) => handleSelectOperator(v)}
					/>
				</div>
				<div className="flex_item_full">
					{!useAutoComplete &&
						<InputCustom
							placeHolder="Enter one item per line or use one of the following separators: ; (semi-colon) , (comma)"
							onChange={(e) => handleChangeValues(e.target.value)}
							value={currentValues}
							type="textarea"
							isAnError={isAnError.message}
							fullWidth={true}
							autogrow={true}
						/>
					}
					{useAutoComplete &&
						<>
							<AutocompleteFilter
								tenanSelected={selectedTenant}
								previewTenant={previewTenant}
								needTenantForSearch={needTenantForSearch}
								setupAccountServices={setupAccountServices}
								currentAccount={accountId}
								referenceDataType={'product'}
								value={currentValueString}
								onChange={(value) => onChangeAutocomplete(value)}
							/>
						</>
					}
				</div>
			</div>
		</Fieldset>
	);
}
