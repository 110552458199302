import React, { createContext, useContext, useEffect, useState } from 'react';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SystemServices from '../../../Services/SystemServices';
import InternalServices from '../../../Services/InternalServices';
import LabelService from '../../../Services/LabelsServices';
const AccountListContext = createContext();

function useAccountListContext() {
	return useContext(AccountListContext);
}

const CreateAccoutnListContextProvider = (props) => {

	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [dataFiltered, setDataFiltered] = useState();
	const [accountList, setaccountList] = useState([]);
	const [noData, setNoData] = useState();
	const [orderItems, setOrderItems] = useState('name');
	const [order, setOrder] = useState('down');
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;
	const AuthServices = props.AuthServices;
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const labelServices = new LabelService($http, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || $rootScope.User.Account.Key;

	const services = new InternalServices($http);
	const systemServices = new SystemServices($rootScope, $timeout);
	const [selectedLabelIds, setSelectedLabelIds] = useState([]);
	const [allAccounts, setAllAccounts] = useState(false);
	const [modalLabelsIsOpen, setModalLabelsIsOpen] = useState(false);
	const [modalLabelsAccountisOpen, setModalLabelsAccountisOpen] = useState(false);
	const [isReloading, setIsReloading] = useState(true);
	const [accountLabels, setAccountLabels] = useState([]);
	const [accountSelected, setaccountSelected] = useState();
	function getLabelsItem(dataToTransform) {
		labelServices.getLabelsItem(accountId, 'account',
			newData => {
				const newDataTransform = dataToTransform.map(
					account =>
						(
							{
								...account,
								labels: newData.find(accounLabel => accounLabel.itemId === account.id) && newData.find(accounLabel => accounLabel.itemId === account.id).labels
								|| []
							})
				);
				setAllAccounts(newDataTransform);
				search(newDataTransform, valueInSearchBar);
				setIsReloading(false);
			}, err => {
				setIsReloading(false);
				systemServices.showError('An Error occured while fetching labels');
			});
	}
	

	useEffect(()=>{
		if(!modalLabelsIsOpen){
			setIsReloading(true);
			services.getAccountList(data => {
				const sortByName = (a, b) => {
					if (a === undefined) return;
					if (b === undefined) return;
					if (a.name === null) return 1;
					if (b.name === null) return -1;
					if (a.name < b.name) return -1;
					if (a.name > b.name) return 1;
					return 0;
				};
				const dataSort = data && data.sort(sortByName);
				// setAllAccounts(dataSort);
				getLabelsItem(dataSort);
				// setIsReloading(false);
			}, error => {
				setIsReloading(false);
				console.log('error', error);
			});
		}
	},[modalLabelsIsOpen]);

	const search = (table, word) => {
		setNoData();
		if (word.length >= 0) {
			setDataFiltered([]);

			let resultName = table.filter(
				line =>
					line.name !== null && line.name !== undefined && line.name.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let resultEmail = table.filter(
				line =>
					line.email !== null && line.email !== undefined && line.email.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let resultMainUserMail = table.filter(
				line =>
					line.mainUserEmail !== null && line.mainUserEmail !== undefined && line.mainUserEmail.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let resultIdAccount = table.filter(
				line =>
					line.id !== null && line.id !== undefined && line.id.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let resultIdMainUser = table.filter(
				line =>
					line.mainUserId !== null && line.mainUserId !== undefined && line.mainUserId.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let finalArray = [...resultName, ...resultEmail, ...resultMainUserMail, ...resultIdAccount, ...resultIdMainUser];
			let uniqueItem = [...new Set(finalArray)];
			if (uniqueItem.length === 0) {
				uniqueItem = 'No account matches with this search';
				setNoData(uniqueItem);
			}
			else {
				setDataFiltered(uniqueItem);
			}
		}
	};
	
	const handleChange = (event) => {
		event.persist();
		const value = event.target.value;
		const str = value.trim();
		setValueInSearchBar(value);
		if (allAccounts) {
			search(allAccounts, str);
		}
	};
	const filterByLabels = (arrayToFilter) => {
		const uniqueItem = arrayToFilter.reduce((acc, curr) => {
			if (curr.labels) {
				const currentLabelsOfAccount = curr.labels.map(element => element.id);
				currentLabelsOfAccount.map(el => {
					if (selectedLabelIds.includes(el)) {
						acc = [...acc, curr];
					}
				});
			}
			return acc;
		}, []);
		return [...new Set(uniqueItem)];
	};

	const [clipBoardContain, setClipBoardContain] = useState();
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
		setTimeout(() => {
			setClipBoardContain();
		}, 2000);
	};
	const OpenModalLabelAccount = (value, account) => {
		setModalLabelsIsOpen(value);
		setaccountSelected(account);
	};
	const onLabelSelected = (label) => {
		setSelectedLabelIds(ids =>
			ids && ids.includes(label.id) ?
				ids.filter(i => i !== label.id) :
				[...(ids || []), label.id]);
	};

	const loadLabels = () => {
		labelServices.getLabels(accountId, 'account',
			data => {
				setAccountLabels(data);
			},
			err => {
				console.log('An Error occured while fetching labels: ' + err);
			}
		);
	};
	const closeTheModalOfLabels = () => {
		setModalLabelsAccountisOpen(false);
		loadLabels();
	};
	useEffect(() => {
		loadLabels();
	}, [modalLabelsAccountisOpen]);

	return (
		<AccountListContext.Provider
			value={{
				accountId,
				search,
				valueInSearchBar,
				handleChange,
				accountList,
				noData,
				orderItems,
				order,
				allAccounts,
				modalLabelsIsOpen,
				setModalLabelsIsOpen,
				modalLabelsAccountisOpen,
				isReloading,
				dataFiltered,
				selectedLabelIds,
				filterByLabels,
				setOrder,
				setOrderItems,
				accountLabels,
				closeTheModalOfLabels,
				onLabelSelected,
				labelServices,
				systemServices,
				OpenModalLabelAccount,
				accountSelected,
				setaccountSelected,
				setModalLabelsAccountisOpen,
				clipBoardContain,
				copyToClipBoard
			}}
		>
			{props.children}
		</AccountListContext.Provider>
	);
};

export default CreateAccoutnListContextProvider;

export { useAccountListContext };
