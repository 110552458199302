import React from 'react';
import SelectCustom from '../../../Components/SelectCustom';
import Switch2label from '../../../Components/Switch2Labels';
import formatMoment from '../../../Constants/FormatMoment';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
const list = [
	{
		value: 'unlimited',
		label: 'unlimited',
	},
	{
		value: true,
		label: '1',
	}];
const valueSelect = (camp) => {
	if (camp.campaignDisplayStrategy) {
		if (camp.campaignDisplayStrategy.limitToOneDisplayBySession === '') {
			'unlimited';
		} else {
			return camp.campaignDisplayStrategy.limitToOneDisplayBySession ? true : 'unlimited';
		}
	} else {
		return 'unlimited';
	}
};
const { formatDateToLocal } = formatMoment;
const today = moment();
const idTooltipTrash = uuidv4();
export default function CampaignItem({ campaign, displayMode = 'simple', isDragging, handleChangeForm, handleDeleteACampaign, accountId }) {
	const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
	const dateEndOfOperation = formatDateToLocal(campaign.endDate);
	const isStoppedBoolOperation = campaign.isStopped;
	const isTestModeOperationBool = campaign.isTestMode;

	let statusOperation = {};

	const setStatusOperation = () => {
		if (isStoppedBoolOperation) {
			statusOperation = { id: 'Stopped', label: 'Stopped', cssClass: 'status-stopped' };
			return;
		}

		if (isTestModeOperationBool) {
			statusOperation = { id: 'testing', label: 'Draft', cssClass: 'status-testing' };
			return;
		}

		if (campaign.endDate && dateEndOfOperation < today) {
			statusOperation = { id: 'past', label: 'Past', cssClass: 'status-past' };
			return;
		}

		if (dateBeginOfOperation > today) {
			statusOperation = { id: 'planned', label: 'Scheduled', cssClass: 'status-planned' };
			return;
		}

		statusOperation = { id: 'ongoing', label: 'Ongoing', cssClass: 'status-ongoing' };
	};

	setStatusOperation();

	if (displayMode === 'simple') {
		return (
			<div style={{ width: '80%', margin: '5px auto', border: '1px solid black' }}>
				<div className="flex flex_justify_between">
					<span className={'status_bullet '
                        + statusOperation.cssClass
					}></span>
					<h2>{campaign.campaignName}</h2>
				</div>
				<section>
					<p>Id : {campaign.campaignId}</p>
				</section>
			</div>
		);
	} else {
		return (
			<div className="orchestration_grid_row orchestration_grid_body_row" isdragging={isDragging ? 'y' : 'n'}>
				<div className="orchestration_grid_col">
					<a className="icon_btn"
						data-for={idTooltipTrash}
						data-tip="Drag campaign to sort">
						<i className="fas fa-sort s_18"></i>
					</a>
					<span className={'status_bullet '
                        + statusOperation.cssClass
					}></span>
					<div className="orchestration_grid_name">{campaign.campaignName}</div>
				</div>
				
				<div className="orchestration_grid_col orchestration_grid_col_right">
					<ReactTooltip id={idTooltipTrash} backgroundColor='black' effect='solid' place="bottom" globalEventOff="click" />
					{/* <a className="icon_btn"
                        data-for={idTooltipTrash}
                        data-tip="View campaign details"
                        href={`/Dashboard/ShowStats/${campaign.campaignId}?ka=${accountId}&ku=${accountId}}`}
                        target="_blank">
                        <i className="fas fa-external-link-alt s_18"></i>
                    </a> */}
					<a className="icon_btn"
						data-for={idTooltipTrash}
						data-tip="Remove campaign from this group"
						onClick={(e) => handleDeleteACampaign(campaign.campaignId)}>
						<i className="fas fa-trash-alt s_18"></i>
					</a>
				</div>
			</div>
		);
	}
}
