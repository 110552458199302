import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { Context } from './Context/Context';
import './ProductFeedComponent.css';
export default function ProductFeedComponent({ changeAlgorithmFilterData, getProductFeedTags, getProductFeedCategory, accountId, data, algorithm, isExistantAlgo }) {
	const [arrayOfTags, setArrayOfTags] = useState([]);
	const [arrayOfCategories, setArrayOfCategories] = useState([]);
	const [isTagSelected, setIsTagSelected] = useState(false);
	const [arrayOfValues, setArrayOfValues] = useState([]);
	const {
		existantAlgorithm
	} = useContext(Context);
	const [isSelected, setisSelected] = useState();
	const [tags, setTags] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		getProductFeedTags(accountId,null,
			newData => {
				setTags(newData);
			}
		);
		getProductFeedCategory(accountId,
			newData => {
				setCategories(newData);
			});
	}, []);
	useEffect(() => {
		const transformArrayOfTags = () => {
			const array = [];
			tags.forEach(elem => array.push({ value: elem.tag, label: elem.tag, type: 'tag' }));
			setArrayOfTags(array);
		};
		transformArrayOfTags();
	}, [tags]);
	useEffect(() => {
		const transformArrayOfCategories = () => {
			const array = [];
			categories.forEach(elem => array.push({ value: elem.type, label: elem.type, type: 'cat' }));
			setArrayOfCategories(array);
		};
		transformArrayOfCategories();
	}, [categories]);

	const groupedOptions = [
		{
			label: 'Tag',
			options: arrayOfTags,
		},
		{
			label: 'Categories',
			options: arrayOfCategories,
		},
	];
	const groupStyles = {
		display: 'flex',
		alignItems: 'center',
		fontSize: 17,
		justifyContent: 'space-between',
	};
	const groupBadgeStyles = {
		backgroundColor: '#EBECF0',
		borderRadius: '0.5em',
		color: '#000000',
		display: 'inline-block',
		fontSize: 11,
		fontWeight: 'bold',
		lineHeight: '1',
		minWidth: '10px',
		padding: '0.16666666666667em 0.2em',
		textAlign: 'center',
	};

	const formatGroupLabel = data => (
		< div style={groupStyles} >
			<span>{data.label}</span>
			<span style={groupBadgeStyles}>{data.options.length}</span>
		</div >
	);

	const customStyles = {
		control: (base, state) => ({
			...base,
			minHeight: 20,
			border: '1px solid #ccc',
			// This line disable the blue border
			boxShadow: state.isFocused ? 0 : 0,
			'&:hover': {
				border: '1px solid #ccc'
			},

		}),
		dropdownIndicator: base => ({
			...base,
			padding: 4
		}),
		clearIndicator: base => ({
			...base,
			padding: 4
		}),
		multiValue: base => ({
			...base,
			backgroundColor: variables.colorPrimaryLighter
		}),
		input: base => ({
			...base,
			margin: 0,
			padding: 0,

		})
	};
	const valueType = (e) => {
		isExistantAlgo();
		setIsTagSelected(false);
		changeAlgorithmFilterData('change');
		if (e.type === 'cat') {
			setIsTagSelected(true);
			const array = categories.filter(x => x.type === e.value);
			const newArray = [];
			array && array[0].knownNames.forEach(val =>
				newArray.push({ value: val, label: val, type: e.value }));
			setArrayOfValues(newArray);
		}
		if (e.type === 'tag') {
			setIsTagSelected(true);
			const array = tags.filter(x => x.tag === e.value);
			const newArray = [];
			array && array[0].knownValues.forEach(val =>
				newArray.push({ value: val, label: val, type: e.value }));
			setArrayOfValues(newArray);
		}
	};
	const [testval1, setTestVal1] = useState();
	const valueForSecondSelect = typeof data === 'string' && data.length > 0 && data.split('=').pop();
	const valueForFirstSelect = typeof data === 'string' && data.length > 0 && data.split('=');
	const group = [...arrayOfTags, ...arrayOfCategories];
	// const val = group && group.filter(option => option.label === valueForFirstSelect[0])

	useEffect(() => {
		if (existantAlgorithm) {
			// setIsTagSelected(true)
			setTestVal1(valueForFirstSelect[0]);
		}
	}, []);

	return (
		<div className="selectProductFeed">
			<div className="elementSelectProductFeed">
				<Select
					options={groupedOptions}
					formatGroupLabel={formatGroupLabel}
					styles={customStyles}
					placeholder={existantAlgorithm ? data : 'Search for a tag or a category'}
					onChange={valueType}
					// value={
					//     // group.filter(option => option.label === testval1)
					//     testval1
					// }
					// className={'sectionTest'}

				/>
			</div>

			<div className="elementSelectProductFeed">
				{
					isTagSelected &&
                    <Select
                    	// defaultValue={tags[1]}
                    	options={arrayOfValues}
                    	formatGroupLabel={formatGroupLabel}
                    	styles={customStyles}
                    	placeholder="Search for a value"
                    	onChange={changeAlgorithmFilterData}
                    	value={arrayOfValues.filter(option => option.label === valueForSecondSelect)}
                    />
				}
			</div>

		</div>

	);
}
