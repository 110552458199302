import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import boost from 'highcharts/modules/boost';
import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
import {countries} from '../../util.js';
import { useGeoContext } from '../../context/GeolocContext.js';

import hcMap from 'highcharts/modules/map';
import proj4 from 'proj4';
import {DatasWorld, GeolocalisationObject,} from './GeolocalisationType';
import {useAnalyticsContext} from '../../context/AnalyticsContextProvider';
//import styles from '../../WebAnalyticsContainer.module.css';

hcMap(Highcharts);
boost(Highcharts);
declare global {
	interface Window {
		proj4: typeof proj4;
	}
}

window.proj4 = proj4; // Assign the proj4 library to the window object
const MapPointByCountry = ({ data, isLoading }) => {
	if (typeof window !== 'undefined') {
		window.proj4 = window.proj4 || proj4;
	}
	const {selectedTenant} = useAnalyticsContext();
	const { metricGeolocalisationSelected } = useGeoContext();
	const [finalDataFormatted, setfinalDataFormatted] = useState<DatasWorld[]| []>(
		[]
	);
	const [loadingGraph, setloadingGraph] = useState(true);
	const [dataToManipulate, setDataToManipulate] = useState([]);

	const [worldMapChart, setWorldMap] = useState();

	const [key, setKey] = useState(0);

	useEffect(() => {
		const timer = setTimeout(() => {
			const dataToManip = (data.length > 0) ? data : null;
			if(dataToManip){
				const dataWithValues = dataToManip.map((el) => {
					return {...el, value: el[metricGeolocalisationSelected.key]};
				});
				const newArrayOfoCountries: string[] = [];

				for (const property in countries) {
					newArrayOfoCountries.push(property);
				}
				const countryDataAggregateValues = dataWithValues.reduce((acc, point) => {
					acc[point.iso] = (acc[point.iso] || 0) + point.value;
					return acc;
				}, {});
				console.log("=>(MapByCountry.tsx:57) countryDataAggregateValues", countryDataAggregateValues);
				const dataWithAllCountries: DatasWorld[] = newArrayOfoCountries.map((ctr) => {
					const name = ctr.toLocaleLowerCase();
					const hasKeyInCountryData = countryDataAggregateValues[ctr];
					return [name, hasKeyInCountryData ? hasKeyInCountryData : 0];
				});
				console.log("=>(MapByCountry.tsx:63) dataWithAllCountries", dataWithAllCountries);

				setDataToManipulate(dataToManip);
				setfinalDataFormatted(dataWithAllCountries);
				setloadingGraph(true);
			}

		}, 150 );
		return () => {
			clearTimeout(timer);
		};
	}, [data,metricGeolocalisationSelected,selectedTenant]);

	useEffect(() => {
		const dataWithValues = dataToManipulate.map((el: GeolocalisationObject) => ({
			...el,
			value: el[metricGeolocalisationSelected.key],
		}));
		const countryData = dataWithValues.reduce((acc, point) => {
			if (point.iso) {
				acc[point.iso] = (acc[point.iso] || 0) + point.value;
			}
			return acc;
		}, {});
		const formattedCountryData = Object.entries(countryData).map(
			([iso, value]) => [iso, value]
		);
		const allValues = dataWithValues.map(o => o.value);
		const maxValue: number = Math.max(...allValues);
		const minValue: number = Math.min(...allValues);
		const range = maxValue - minValue;
		const minRadius = 4;
		const maxRadius = 50;

		const getRadius = (value) => {
			const radius = ((value - minValue) / range) * (maxRadius - minRadius) + minRadius;
			return radius;
		};
		const options:any = {
			boost: {
				useGPUTranslations: true,
				usePreAllocated: true,
				seriesThreshold: 5
			},
			chart: {
				backgroundColor: 'transparent',
				map: mapDataWorld,
				animation: false,
				height: 500,
				zoomType: 'xy',

			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false
			},

			mapNavigation: {
				enabled: true,
				buttonOptions: {
					alignTo: 'spacingBox',
				},
			},
			colorAxis: {
				min: 0,
				max: maxValue,
				minColor: '#E0E9FD',
				maxColor: '#0032A0',
				stops: [
					[0, '#E0E9FD'],
					[1, '#0032A0'],
				],
				nullColor: '#f0f0f0',
			},
			tooltip: {
				shared: true,
				useHTML: true,
				formatter: function (this:any) {
					if (!this.point) return '';
					const s = `<b>${this.point.name || 'Unknown Name'}</b>: ${
						this.point.value || 'Loading ...'
					}<br/>`;
					return s;
				},
				backgroundColor: '#FFF',
				borderWidth: 0,
				opacity: 1,
				borderRadius: 10,
				style: {
					color: '#151E39',
					fontWeight: 'normal',
					fontSize: '12px',
					fontFamily: 'Roboto',
				}
			},
			series: [
				{
					name: metricGeolocalisationSelected.label,
					data: formattedCountryData,
					mapData: mapDataWorld,

					joinBy: 'iso-a2',
					borderColor: '#FFF',
					nullColor: '#E9EAF0',
					showInLegend: false,
					states: {
						hover: {
							color: '#2E90FA',
						},
					},
				},
				{
					type: 'mappoint',
					name: 'Cities',
					turboThreshold: 10000,
					data: dataWithValues.map((point) => ({
						name: point.name,
						lat: point.lat,
						lon: point.lon,
						value: point.value,
						marker: {
							radius: getRadius(point.value || 0),
							lineWidth: 1,
							lineColor: '#81A8FF',
							fillColor: '#4378F070',
						},
						dataLabels: {
							enabled: true,
							format: '{point.name}',
							style: {
								color: '#151E39',
								textOutline: 'none',
								fontWeight: 'normal',
								fontSize: '12px',
								fontFamily: 'Roboto',
								align: 'center',
							},
							allowOverlap: false,
						},
					})),
					tooltip: {
						pointFormat: '{point.name}: {point.value} (Value)',
					},
				},
			],
		};

		setloadingGraph(false);
		setWorldMap(options);
		setKey(prevKey => prevKey + 1);
	}, [finalDataFormatted,metricGeolocalisationSelected,dataToManipulate]);

	return (
		<div>
			{isLoading && <SpinnerWheel />}
			{!isLoading && !loadingGraph && data.length > 0 &&
				<HighchartsReact
					key={key}
					highcharts={Highcharts}
					constructorType={'mapChart'}
					options={worldMapChart}
				/>}
			{!isLoading && !loadingGraph && data.length === 0 &&
				<div className="empty_state analytics_empty_state">
					<img className="empty_state_img" src="/Assets/analytics_data_empty.svg"/>
					<div className="empty_state_title">No data for this period</div>
					<div className="empty_state_text">Maybe try with another period or other filters</div>
				</div>}
		</div>
	);
};


export default MapPointByCountry ;
