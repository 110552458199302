import React, {} from 'react';

export default function TargetingRuleContextItem({
	children,
	title,
	description,
	icon = 'behavior'
}) {

	return (
		<div className="segment_context_item">
			<div className='segment_context_item_picto'>
				<div className={'trigger_icon icon_' + icon}></div>
			</div>
			<div className='segment_context_item_info'>
				<div className='segment_context_item_info_title'>{title}</div>
				{description &&
                    <div className='segment_context_item_info_desc'>{description}</div>
				}
			</div>
			<div className='segment_context_item_field'>
				{children}
			</div>
		</div>
	);
}
