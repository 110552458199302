import React, { createContext, useEffect, useState } from 'react';
import LabelServices from '../../../Services/LabelsServices';

export const BusinessOperationContext = createContext();

const BusinessOperationProvider = (props) => {

	const [dataOperationForStats, setDataOperationForStats] = useState();
	const labelService = new LabelServices(props.$http, props.$routeParams);
	const accountId = props.accountId;
	const [selectedLabelIds, setSelectedLabelIds] = useState([]);
	const [accountLabels, setAccountLabels] = useState([]);

	const loadLabels = () => {
		labelService.getLabels(accountId, 'businessOperation',
			data => {
				setAccountLabels(data);
			},
			err => {
				console.log('An Error occured while fetching labels: ' + err);
			}
		);
	};

	useEffect(() => {
		loadLabels();
	}, []);

	return (
		<BusinessOperationContext.Provider value={{
			dataOperationForStats,
			setDataOperationForStats,
			labelService,
			accountId,
			selectedLabelIds,
			setSelectedLabelIds,
			accountLabels, 
			setAccountLabels,
			loadLabels
		}}>
			{props.children}
		</BusinessOperationContext.Provider>
	);

};
export default BusinessOperationProvider;