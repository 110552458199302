import React, { useState } from 'react';
import Btn from '../../../../Components/Btn';
import ManualSortingItem from './ManualSortingItem';
import ModalManualSort from './ModalManualSort';
import PanelManualSorting from './PanelManualSorting';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider';
import {
	replaceId,
	demoteId,
	promoteId
} from '../../Ranking/utils';
export default function ManualSortingContainer({
	data
}) {
	const {

		modalManualSorting,
		setModalManualIsOpen,
	} = useRankingContext();


	const [activeTab, setActiveTab] = useState(promoteId);
	const [panelIsOpen, setpanelIsOpen] = useState(false);
	const [dimension, setdimension] = useState('');
	function handleOpenManualSortWithTabSelected(tabSelect) {
		setActiveTab(tabSelect);
	}
	function handleOpenManualSortPanel(dimensionToOpen) {
		setdimension(dimensionToOpen);
		setpanelIsOpen(true);
	}
	const filterBySign = (arrayToFilter, idToFilter) => {
		const itemTOtransform = arrayToFilter.filter(el => {
			return el.collationId === idToFilter;
		});
		let elementsToReturn = [];
		if (itemTOtransform.length !== 0) {
			elementsToReturn = [...itemTOtransform[0]['params'][0]['value']['stringValues']];
		}
		return elementsToReturn;
	};
	function getReplacesObj(arrayToFilter) {
		const uniqueItem = arrayToFilter.reduce((acc, curr) => {
			if (curr.collationId === replaceId) {
				const newObj = {
					...curr,
					idOld: curr.params.find(el => el.name === 'First Product Id').value.stringValues,
					idReplace: curr.params.find(el => el.name === 'Second Product Id').value.stringValues,
				};
				acc = [...acc, newObj];
			}

			return acc;
		}, []);

		return [...new Set(uniqueItem)];
	}
	function getSubtitleManualSorting() {
		switch (dimension) {
			case promoteId:
				return 'Following products will be at the beginning of the ranking.';
			case demoteId:
				return 'Following products will be at the end of the ranking.';
			case replaceId:
				return 'Following products will be swapped.';

		}
	}
	return (
		<>
			<div className="collection_sidebar_block_title">Manual sorting</div>
			{/* {data.transformations && data.transformations.length === 0 && <p
				className="s_14 mb_15"
				style={{
					minHeight: "70px",
					margin: "5px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				&#9432; You can add manual rules to reorder the products.
			</p>} */}
			<ManualSortingItem
				name='Promoted products'
				list={filterBySign(data.transformations, promoteId)}
				handleOpenManualSortWithTabSelected={() =>
					handleOpenManualSortPanel(promoteId)}
			/>

			<ManualSortingItem
				name='Demoted products'
				list={filterBySign(data.transformations, demoteId)}
				handleOpenManualSortWithTabSelected={() =>
					handleOpenManualSortPanel(demoteId)}
			/>
			<ManualSortingItem
				name='Swapped products'
				list={getReplacesObj(data.transformations)}
				handleOpenManualSortWithTabSelected={() =>
					handleOpenManualSortPanel(replaceId)}
			/>
			<Btn
				onClickFunction={() => setModalManualIsOpen(true)}
				icon="fas fa-plus-circle"
				message="Add a manual sort"
				color="primary"
				style='outline'
				fullWidth={true}
			/>
			<ModalManualSort
				activeTab={activeTab}
				setActiveTab={setActiveTab}
			/>
			{panelIsOpen && <PanelManualSorting
				panelIsOpen={panelIsOpen}
				dimension={dimension}
				subtitle={getSubtitleManualSorting()}
				handleClose={() => setpanelIsOpen(false)}
			/>
			}
		</>
	);
}
