import React, {} from 'react';
import './SideBar.css';
import { CampaignsContext } from '../../context';
import FormatSelector from './FormatSelector';
import StyleSelector from './StyleSelector';
import PropertiesSelector from './PropertiesSelector';


export default function SideBar(props) {
	const {
		step,
		editorState,
		sidebarIsOpen,
		editorMode
	} = CampaignsContext();

	const sidebarClass = ['format_sidebar', 'flex_item_fix'];
	if (editorState.sideBarStep === 3 && !sidebarIsOpen) {
		sidebarClass.push('format_sidebar_closed');
	}

	return step === 1 && editorMode !== 'custom' && (
		<div className={sidebarClass.join(' ')}>
			<div className='format_sidebar_inner flex flex_v'>
				{editorState.sideBarStep === 1 && <FormatSelector/>}
				{editorState.sideBarStep === 2 && <StyleSelector/>}
				{editorState.sideBarStep === 3 && <PropertiesSelector/>}
			</div>
		</div>
	);
}
