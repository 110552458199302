import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { CreateBusinessOperationContext } from '../Context/ContextCreateBusinessOperations';
import Message from './Message';
import Progress from './Progress';
import Btn from './../../../../Components/Btn';
import PopinConfirm from './../../../../Components/PopinConfirm';
import { Can, check } from '../../../../Components/Can';
import Spinner from '../../../../Components/Spinner';
import ResizeBannerWorkflowCreationModal from './ResizeBannerWorkflow/ResizeBannerWorkflowCreationModal';
import useEventBroker from '../../../../Hooks/useEventBroker';

const activeStyle = {
	borderColor: '#2196f3',
	color: '#2196f3'
};
const acceptStyle = {
	borderColor: '#006fff',
	color: '#006fff'
};
const rejectStyle = {
	borderColor: '#ff1744',
	color: '#ff1744'
};

function UploadContainer({ accountId, businessOperationServices, systemServices }) {
	const {
		operation,
		setListBanners,
		setOperation,
		userSimplifyRole,
		planAccount,
		transformationActivate,
		settransformationActivate,
		setAddVideoModalIsOpen
	} = useContext(CreateBusinessOperationContext);

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadPercentage, setUploadPercentage] = useState(0);
	const [message, setMessage] = useState();
	const [type, setType] = useState('');
	const { publish } = useEventBroker();

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		acceptedFiles,
		open
	} = useDropzone({
		accept: 'image/*,.psd,.ai',
		noClick: true,
		noKeyboard: true,
		onDrop: acceptedFiles => {
			setSelectedFiles(
				acceptedFiles
			);
		}
	});

	const style = useMemo(
		() => ({
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject]
	);

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			selectedFiles.forEach(file => URL.revokeObjectURL(file.preview));
		},
		[selectedFiles]
	);

	// canTransform dimension 
	const uploadImage = async (files, libraryId) => {
		setIsUploading(true);
		// Choose type Cloudinary or TwicPics
		return businessOperationServices.postUploadToImageCloud(accountId, libraryId, 'Cloudinary',files, 
			progressEvent => {
				const progress = progressEvent.loaded / progressEvent.total * 100;
				setUploadPercentage(Math.round(progress));
			})
			.then(res => {
				setMessage('Uploaded files with success !');
				setType('success');
				setTimeout(() => {
					setMessage();
				}, 2000);
				businessOperationServices.postAddCloudImagesToLibrary(accountId, libraryId, res.data)
					.then(resultCloudData => {
						const bannersList = [...resultCloudData.data.banners];
						let operationObject = { ...operation };
						setTimeout(() => {
							setListBanners([...bannersList]);
							setOperation(operationObject);
							setIsUploading(false);
						}, 2000);
					}
					).catch(error => {
						console.error('error during the update of the library');
						setMessage('error during the update of the library Please retry');
						settransformationActivate(false);
						setType('error');
						setIsUploading(false);
						setTimeout(() => {
							setMessage();
						}, 3000);
					});
			}).catch(error => {
				console.error('error during upload of image', error);
				setMessage(error.response.data.error + ' Please retry');
				setType('error');
				setTimeout(() => {
					setMessage();
				}, 3000);
			});
	};

	const prepareFilesToUpload = (libraryId) => {

		const getFileExtension = (filename) => filename.split('.').pop().toLowerCase();

		const filesToUpload = selectedFiles.filter(x => !['ai', 'psd'].includes(getFileExtension(x.name)));
		const filesToSendAsWorkflow = selectedFiles.filter(x => ['ai', 'psd'].includes(getFileExtension(x.name)));
        
		const sendUploadImages = () => {

			if (filesToUpload.length > 0){
				let formData = new FormData();
				for (let i = 0; i < filesToUpload.length; i++) {
					let fileToRegister = filesToUpload[i];
					formData.append(`file[${i}]`, fileToRegister);
				}
				return uploadImage(formData, libraryId);
			}
			return Promise.resolve();
		};

		const sendImagesAsWorkflow = () => {
			filesToSendAsWorkflow.length > 0 &&
                publish('evt/businessoperations/resizeBannerWorkflowCreationRequested', { filesToSendAsWorkflow:filesToSendAsWorkflow });
		};

		sendUploadImages().then(sendImagesAsWorkflow);
	};


	const uploadHandler = (e) => {
		if (!operation.operation.libraryId) {
			businessOperationServices.postCreateLibrary(accountId).then(res => {
				const libraryId = res.data.libraryId;
				let operationObject = { ...operation };
				operationObject.operation.libraryId = res.data.libraryId;
				setTimeout(() => {
					setMessage();
					setOperation(operationObject);
				}, 2000);
				// When new library is create, fetch data to cloudinary
				prepareFilesToUpload(libraryId);
			}).catch(error => {
				console.error('error during creation of the library', error);
				setMessage(error.response.data.error + ' Please retry');
				setType('error');
				setIsUploading(false);
				setTimeout(() => {
					setMessage();
				}, 3000);
			});
		} else {
			// if library exist fetch data to cloudinary
			prepareFilesToUpload(operation.operation.libraryId);
		}
	};
	const saveImages = () => {
		if (selectedFiles.length > 0) {
			uploadHandler();
		}
	};
	useEffect(() => {
		saveImages();
	}, [selectedFiles]);

	return (
		<>
			<ResizeBannerWorkflowCreationModal
				accountId={accountId}
				businessOperationServices={businessOperationServices}
				systemServices={systemServices}
			/>

			<div className="designSection">
				{!transformationActivate && <>
					<h5 className="designSectionTitle">Select one or several images</h5>
					<p className="designSectionDesc">Accepted formats : .png, .jpg, .jpeg, .gif (max. size for each file : 500 Ko)</p>
				</>}
				<Can
					rules={planAccount}
					role={userSimplifyRole}
					perform={'simplifyOperation:canUploadImage'}
					yes={() => (
						<>{transformationActivate ? <div style={{ height: '450px', marginTop: '10%' }} >
							<div className="messageProcessingTransformations">
								<p>Please wait, we are processing to the transformation of the banners.</p>
								<div className="">
									<Spinner />
								</div>
							</div>
						</div> : <>
							<div className="uploadBanner" {...getRootProps({ style })}>
								<input {...getInputProps()} />
								<div className="uploadBannerLine uploadBannerTxt">Drag and drop your designs here</div>
								<div className="uploadBannerLine uploadBannerOr">or</div>
								<div className="uploadBannerLine uploadBannerBtn">
									<Btn size="l" icon="fas fa-image" onClick={open}>Browse files</Btn>
									<Btn size="l" icon="fas fa-video" onClick={() => setAddVideoModalIsOpen(true)}>Add a video</Btn>
								</div>
								{isUploading ?
									(<div className="uploadBannerOverlay">
										<Progress
											percentage={uploadPercentage}
											closeProgressBar={() => { setIsUploading(false); setSelectedFiles([]); setUploadPercentage(0); }}
										/>
									</div>) : null}
							</div></>}</>
					)}
					no={() => (
						<div className="uploadBanner">
							<div className="uploadBannerLine uploadBannerTxt">Drag and drop your designs here</div>
							<div className="uploadBannerLine uploadBannerOr">or</div>
							<div className="uploadBannerLine uploadBannerBtn">
								<Btn disabled={true} size="l" icon="fas fa-image">Browse files</Btn>
								<Btn disabled={true} size="l" icon="fas fa-video">Add a video</Btn>
							</div>
						</div>
					)} />
				{message ? (
					<Message msg={message} type={type} />
				) : null}
			</div>
		</>
	);
}

export default UploadContainer;
