import React, { useEffect, useState, useRef }  from 'react';
import FieldContainer from './FieldContainer';
import {useProductFeedEditionContext} from '../../context/ProductFeedEditionContext';
import SelectCustom from '../../../../Components/SelectCustom';
import SelectDropdown from '../../../../Components/SelectDropdown';
import {keyExistInConfig,transformXpathToNormalValue,transformXpathToXpathValue,fieldIsXpathCustom}  from '../../utils';
import InputCustom from '../../../../Components/InputCustom';
import SectionMessage from '../../../../Components/SectionMessage';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import editionStyles from '../ProductFeedEdition.module.css';
import SelectDropDownWithExampleValues from './SelectDropDownWithExampleValues';
import TransformationsContainer from './Transformations/TransformationsContainer';
export default function SimpleField({
	name,
	label,
	fieldIndex,
	canUseTranformations = false
}) {

	const {
		mappingObject,
		productFeedObject,
		handleChangeValueField,
		deleteValueKeyInMappingObject,
		metadatasSources,
		metadatasSourcesIsLoading,
		hasErrorsOnColums,
		isLoading
	} = useProductFeedEditionContext();

	const hasKeyInObjet = keyExistInConfig(name,mappingObject,null);
	const [metaDataSourcesToMap, setMetaDataSourcesToMap] = useState([]);
	const [xPathSelected, setXPathSelected] = useState(false);
	const [valueDoesntExistInList, setValueDoesntExistInList] = useState(false);
	const [hasToolTipeValues, sethasToolTipeValues] = useState();
	const [viewPossiblesValues, setviewPossiblesValues] = useState(false);

	function handleChangeForm(e){
		const value = e.target.value;

		const name = e.target.name;
		// Check if xPathSelected or normal field
		if(value === 'Xpath'){
			setXPathSelected(true);
			if(value !== 'Xpath' ){
				handleChangeValueField(name,value);
			}else{
				const valueToSave = 'xpath(' + '' + ')';
				handleChangeValueField(name, valueToSave);
			}
		}else{
			if(xPathSelected){
				setXPathSelected(false);
			}
			if(value === ''){
				deleteValueKeyInMappingObject(name);
			}else{
				handleChangeValueField(name,value);
			}
		}
	}
	function handleChangeFormInput(e){
		let value = e.target.value;
		if(xPathSelected){
			value = transformXpathToXpathValue(value);
		}
		const name = e.target.name;

		if(value === '' || value === 'xpath()'){
			deleteValueKeyInMappingObject(name);
		}else{
			handleChangeValueField(name,value);
		}
	}
	const hasTooltip = () => {
		if(metadatasSources && hasKeyInObjet && mappingObject[name][0].sourceField ){
			const value = mappingObject[name][0].sourceField;
			const keyValueToolTip =	metadatasSources.find(el => el.value === value);
			if(keyValueToolTip){
				sethasToolTipeValues( keyValueToolTip.tooltip);
			}else{
				sethasToolTipeValues();
			}
		}else{
			sethasToolTipeValues() ;
		}
	};
	useEffect(()=>{
		if(productFeedObject?.type === 'GenericUri' && productFeedObject?.format?.type === 'Xml' &&  metadatasSources){
			if(hasKeyInObjet){
				const valueOfField = mappingObject[name][0].sourceField;
				if(fieldIsXpathCustom(valueOfField)){
					setValueDoesntExistInList(false);
					transformXpathToNormalValue(valueOfField);
					setXPathSelected(true);
					setMetaDataSourcesToMap([...metadatasSources,{
						value: 'Xpath' ,
						label : 'Custom value - Xpath'
					}]);
				}else{
					const valueOfField = mappingObject[name][0].sourceField;
					const testIfExist = metadatasSources.find(el => el.value === valueOfField );
					if(!testIfExist){
						setValueDoesntExistInList(true);
					}else{
						setValueDoesntExistInList(false);
					}
					setMetaDataSourcesToMap([...metadatasSources,{
						value: 'Xpath' ,
						label : 'Custom value - Xpath'
					}]);
					setXPathSelected(false);
				}

			}else{
				setMetaDataSourcesToMap([...metadatasSources,{
					value: 'Xpath' ,
					label : 'Custom value - Xpath'
				}]);
			}
		}else if(productFeedObject?.type !== 'Custom' && metadatasSources){
			if(hasKeyInObjet){
				const valueOfField = mappingObject[name][0].sourceField;
				const testIfExist = metadatasSources.find(el => el.value === valueOfField );
				if(!testIfExist){
					setValueDoesntExistInList(true);
				}else{
					setValueDoesntExistInList(false);
				}
			}
			setMetaDataSourcesToMap([...metadatasSources]);
			
		}
	},[metadatasSources,productFeedObject?.type,fieldIndex,xPathSelected,hasKeyInObjet,mappingObject]);
	useEffect(()=>{
		if(!hasKeyInObjet){
			setValueDoesntExistInList(false);
		}
	},[mappingObject]);

	useEffect(()=>{
		if(productFeedObject?.type !== 'Custom' && metadatasSources){
			hasTooltip();
		}
	},[metadatasSources,productFeedObject?.type,fieldIndex,xPathSelected,hasKeyInObjet,mappingObject]);

	function handleChangeViewPossiblesValues (){
		setviewPossiblesValues(!viewPossiblesValues);
	}
	return (
		<FieldContainer
			name={name}
			label={label}
			valueDoesntExistInList={valueDoesntExistInList}
			valueKey={xPathSelected && hasKeyInObjet? transformXpathToNormalValue(mappingObject[name][0].sourceField) :hasKeyInObjet ? mappingObject[name][0].sourceField: ''}
			fieldIndex={fieldIndex}
			hasTransformationBtn={canUseTranformations && hasKeyInObjet}
		>
			{productFeedObject?.type !== 'Custom' && xPathSelected && !metadatasSourcesIsLoading &&
				<SelectCustom
					defaultValueDisabled={{
						value: 'choose',
						label: 'Choose a name field',
						isDisabled: true,
					}}
					optionsList={metaDataSourcesToMap}
					value={'Xpath'}
					fullWidth={true}
					name={name}
					onChangeFunction={handleChangeForm}
					size="l"
				/>
			}
			{productFeedObject?.type !== 'Custom' && xPathSelected 
			&& metadatasSourcesIsLoading &&<>
				<SpinnerWheel wheelSize='s' />
			</>}
			
			{xPathSelected &&
				<div className='mt_15'>
					<InputCustom
						value={hasKeyInObjet ? transformXpathToNormalValue(mappingObject[name][0].sourceField): '' }
						type="text"
						label="Custom value"
						name={name}
						placeholder="Xpath"
						onChange={handleChangeFormInput}
						fullWidth={true}
						size="l"
					/>
				</div>
			}
			{productFeedObject?.type !== 'Custom' && !xPathSelected 
			&& 
			<>
				{hasKeyInObjet && valueDoesntExistInList  && !hasErrorsOnColums && !metadatasSourcesIsLoading &&
					<SectionMessage
						type="warning"
						title={`"${mappingObject[name][0].sourceField}" does not exist anymore`}
						text={'Please choose another field.'}
					/>
				}

				<SelectDropDownWithExampleValues
					editionStyles={editionStyles}
					metaDataSourcesToMap={metaDataSourcesToMap}
					valueDoesntExistInList={valueDoesntExistInList}
					hasKeyInObjet={hasKeyInObjet}
					mappingObject={mappingObject}
					name={name}
					handleChangeForm={handleChangeForm}
				/>


				{hasErrorsOnColums&& 
				<div style={{marginTop : '10px'}}>
					<SectionMessage
						type="warning"
						title={'Please check the feed accessibility'}
						text={''}
					/>
				</div>
				}
				{hasToolTipeValues && 
				<> 
					{<h3 onClick={handleChangeViewPossiblesValues}>View possible values</h3>}
					{viewPossiblesValues && <>
						{hasToolTipeValues.map(element => <div key={element}> 
							{element}
						</div>)}
					</>
					}			
				</>
				}
			</>
			}
			{productFeedObject?.type !== 'Custom' && !xPathSelected && metadatasSourcesIsLoading &&
				<SpinnerWheel wheelSize='s' />
			}
			{productFeedObject?.type === 'Custom' &&
				<InputCustom
					value={hasKeyInObjet ? mappingObject[name][0].sourceField: '' }
					type="text"
					name={name}
					placeholder=""
					onChange={handleChangeFormInput}
					fullWidth={true}
					size="l"
				/>
			}
			{canUseTranformations && hasKeyInObjet &&
				<TransformationsContainer 
					objectIdenfifier={name}
				/>
			}
		</FieldContainer>
	);
}


