import React, { useState } from 'react'
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function DeviceDropDownList({
	deviceType,
    changeDeviceSelected
}) {

    const typeCategories = [
        {value: "Mobile", label: "Mobile"},
        {value: "Desktop", label: "Desktop"},
        {value: "Tablet", label: "Tablet"}
    ]

	return (
		<SelectDropdown
			label='Device'
			labelPosition='inner'
			optionsList={typeCategories}
			value={deviceType}
			onChange={(v) => changeDeviceSelected(v)}
			color='white'
		/>
	);
}
