import React, {} from 'react';
import ItemFilterCard from '../AttributeOrScoreFilter/ItemFilterCard';
import { getCategoryIcon } from '../Icons';

export default function ModalFiltersList({ addFiltering,
	ListFilterAvailable,
	tabFilterSelected,
	noData
}) {

	return (
		<div className="trigger_store">
			<div className="trigger_store_category">
				{ListFilterAvailable &&
					ListFilterAvailable.map((x, i) => {
						const icon = getCategoryIcon(x.name);
						return (
							<div className='trigger_store_category' key={i}>
								<div className="trigger_store_category_label">
									{x.name}
								</div>
								<ul className="trigger_store_list">
									{x.itemList.map((value, i) =>
										<ItemFilterCard
											key={i}
											itemFilter={value}
											onItemSelected={addFiltering}
											selectedFilterId={tabFilterSelected?.collationId}
											categoryIcon={icon}
										/>
									)
									}
								</ul>
							</div>
						);
					})
				}
			</div>
			{noData &&
				<p>No filters available</p>
			}
		</div>
	);
}
