import React from 'react';
import Breadcrumb from '../../../Components/Breadcrumb';
import ConnectionContainer from './Connection/ConnectionContainer';
import MappingContainer from './Mapping/MappingContainer';
import ProductFeedHeader from './ProductFeedHeader';

import { BrowserRouter as Router, NavLink,Switch,Route } from 'react-router-dom';
import EditorContainer from './EditorContainer';
export default function ProductFeedEditorContainer(props) {

	return (
		<Router>
			<EditorContainer />
		</Router>
	);
}
