import React, { Fragment, useState, useEffect } from 'react';
import {Article} from '../../Components/Article/Article';
import Btn from '../../Components/Btn';
import IconBtn from '../../Components/IconBtn';
import {List, ListItem} from '../../Components/List';
import ScheduleItem from '../Campaigns/Triggers/ScheduleItem';
import { v4 as uuidv4 } from 'uuid';

export default function TriggerSchedule({
	triggerValue,
	setTriggerValue,
	index
}) {


	const [schedules, setSchedules] = useState((triggerValue || []).map(t => ({ value: t, uniqueId: uuidv4() })));

	useEffect(() => {
		let newTriggerValue = schedules.map(t => t.value);
		setTriggerValue(index, newTriggerValue);
	}, [schedules]);

	const onChangeScheduleTrigger = (newValue, uniqueId) => {
		let newSchedules = schedules.map(s => s.uniqueId !== uniqueId ? s : { ...s, value: newValue });
		console.log('-> newSchedules', newSchedules);
		setSchedules(newSchedules);
	};

	const addNewSchedule = () => {
		const defaultValue = '* * * * *';
		let newSchedules = [...schedules];
		newSchedules.push({ value: defaultValue, uniqueId: uuidv4() });
		setSchedules(newSchedules);
	};

	const deleteSchedule = (uniqueId) => {
		let newSchedules = schedules.filter(s => s.uniqueId != uniqueId);
		setSchedules(newSchedules);
	};

	return (
		<Article hasMargin={false} size='s'>
			<List size='s' removeVerticalMargin={true}>
				{schedules.map(v =>
					<ListItem
						key={v.uniqueId}
						actions={
							<IconBtn
								onClick={() => deleteSchedule(v.uniqueId)}
								icon="fas fa-trash"
								color="alert"
							/>
						}>
						<ScheduleItem
							defaultValue={v.value}
							onChange={value => onChangeScheduleTrigger(value, v.uniqueId)}
						/>
					</ListItem>
				)}
				<ListItem>
					<Btn
						onClick={() => addNewSchedule()}
						icon="fas fa-plus fa-sm"
						message="Add a schedule"
						style="ghost"
						horizontalSize="xs"
					/>
				</ListItem>
			</List>
			
		</Article>
	);
}