import React from 'react';

import { useGeoContext } from '../../context/GeolocContext';
import Chip from '../Chip/Chip';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';
import Nav from '../Nav/Nav';

import {devices} from '../Pages/config';
import SourceTypeFilter from '../SourceType/SourceTypeFilter';
import UtmFiltersPagesContainer from './UtmFilters';
import SelectDevice from '../Devices/SelectDevice';

export default function GeolocFilterContainer({sessionService, }) {
	const {
		navFilter,
		setNavFilter,
		chips,
		onDeleteChip,
		sourceType,
		handleChangeSourceType,
		sourceTypes,
		setisCustomSelected,
		isCustomSelected,
		customDateRange,
		setCustomDateRange,
		deviceSelected,
		handleChangeDeviceSelected,
		resetSource,
		resetDevices,
	} = useGeoContext();
		
	const { initiNavDate } = useAnalyticsContext();
	let initialDates = initiNavDate(navFilter);

	return (
		<>
			<div className="flex flex_align_end analytics_main_line">
				<div className="flex_item_full">
					<Nav
						initialDates={initialDates}
						handleClick={setNavFilter}
						setisCustomSelected={setisCustomSelected}
						isCustomSelected={isCustomSelected}
						customDateRange={customDateRange}
						setCustomDateRange={setCustomDateRange}
					/>
					<SourceTypeFilter
						sourceTypeSelected={sourceType}
						handleChangeSourceType={handleChangeSourceType}
						initialValue={sourceTypes}
						resetSource={resetSource}
					/>
					<SelectDevice
						resetSource={resetDevices}
						initialValue={devices}
						devices={devices}
						deviceSelected={deviceSelected}
						handleChangeDeviceSelected={handleChangeDeviceSelected}
					/>
					<UtmFiltersPagesContainer
						sessionService={sessionService}
					/>
					{chips && (
						<Chip
							title={chips.title}
							type={chips.type}
							handleDelete={onDeleteChip}
						/>
					)}
				</div>
			</div>
		</>
	);
}
