import React, {useState, useEffect} from 'react';
import {useBuildEditorContext} from '../BuildEditorContext';
import {CampaignsContext} from '../../../context/index';
import Layers from './Layers';
import ExportPanel from './ExportPanel';

export default function SideBarOptions({}) {

	const {
		stepBuilder,
		buildEditor
	} = useBuildEditorContext();

	const {isAdmin} = CampaignsContext();

	const getSidebarClass = (tab) => {
		const c = ['builder_sidebar', 'builder_sidebar_left', 'vscroll_custom'];
		if (tab === 'none') {
			c.push('builder_sidebar_closed');
		} else if (tab === 'blocks') {
			c.push('builder_sidebar_components');
		} else {
			c.push('builder_sidebar_' + tab);
		}
		return c.join(' ');
	};

	const [sideBarTab, setSideBarTab] = useState('blocks');
	const [sideBarClass, setSideBarClass] = useState(getSidebarClass('blocks'));

	const handleChangeSideBarTab = (tab) => {
		const newTab = sideBarTab === tab ? 'none' : tab;
		setSideBarTab(newTab);
	};

	const handleAnimationEnd = (ev) => {
		if (buildEditor) {
			buildEditor.refresh();
		}
	};

	useEffect(() => {
		setSideBarClass(getSidebarClass(sideBarTab));
	}, [sideBarTab]);
    
	return (
		<>
			<div className='builder_side_toolbar'>
				<div className='builder_side_toolbar_top'>
					<a className={sideBarTab === 'blocks' ? 'builder_side_toolbar_item selected' : 'builder_side_toolbar_item'}
						onClick={() => handleChangeSideBarTab('blocks')}
					>
						<i className="builder_side_toolbar_item_icon fas fa-plus-circle"></i>
						<span className="builder_side_toolbar_item_text">Add</span>
					</a>
					<a className={sideBarTab === 'layers' ? 'builder_side_toolbar_item selected' : 'builder_side_toolbar_item'}
						onClick={() => handleChangeSideBarTab('layers')}
					>
						<i className="builder_side_toolbar_item_icon fas fa-layer-group"></i>
						<span className="builder_side_toolbar_item_text">Navigator</span>
					</a>
					{/* <a className={sideBarTab === 'settings' ? 'builder_side_toolbar_item selected' : 'builder_side_toolbar_item'}
						onClick={() => handleChangeSideBarTab('settings')}
					>
						<i className="builder_side_toolbar_item_icon fas fa-gear"></i>
						<span className="builder_side_toolbar_item_text">Settings</span>
					</a> */}
				</div>
				{isAdmin &&
					<div className='builder_side_toolbar_bottom'>
						<a className={sideBarTab === 'export' ? 'builder_side_toolbar_item selected' : 'builder_side_toolbar_item'}
							onClick={() => handleChangeSideBarTab('export')}
						>
							<i className="builder_side_toolbar_item_icon fas fa-download"></i>
							<span className="builder_side_toolbar_item_text">Export</span>
						</a>
					</div>
				}
			</div>
			<div className={sideBarClass} onTransitionEnd={handleAnimationEnd}>
				<div style={sideBarTab === 'blocks' ? {} : {'display': 'none'}}>
					<div className='builder_sidebar_section'>
						<div className='builder_sidebar_title'>Component library</div>
					</div>
					<div id="builder_tab_blocks"></div>
				</div>
				<div style={sideBarTab === 'layers' ? {} : {'display': 'none'}}>
					<div className='builder_sidebar_section flex_item_fix'>
						<div className='builder_sidebar_title'>Navigator</div>
					</div>
					<div id="builder_tab_layers" className='builder_sidebar_section_layers flex_item_full vscroll'></div>

					{/* Custom layer panel */}
					{/* {buildEditor && 
						<Layers />
					} */}
				</div>
				<div style={sideBarTab === 'settings' ? {} : {'display': 'none'}}>
					<div className='builder_sidebar_section'>
						<div className='builder_sidebar_title'>Settings</div>
					</div>
					TODO SETTINGS
				</div>
				{isAdmin && sideBarTab === 'export' &&
					<ExportPanel />
				}
			</div>
		</>
	);
}