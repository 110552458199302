import React from 'react';
import Btn from '../../../../Components/Btn';
import {useSegmentGroupEditorContext} from '../context/SegmentListRankingContainerProvider';

export default function CreationNewSegmentation() {

	const {
		noData,
		segmentType,
		getEditURL,
		getManageURL,
		setaddNewExternalAudience
	} = useSegmentGroupEditorContext();

	if(noData ){
		return null;
	}

	return (
		<>
			{segmentType && segmentType === 'Beyable' &&
				<>
					<Btn
						href={getManageURL()}
						target="_blank"
						message="Manage segments"
						style="ghost"
						icon="fas fa-external-link-square-alt"
						iconPosition="after"
					/>	
					<Btn
						href={getEditURL()}
						message="New segment"
					/>	
				</>
			}
			{segmentType && segmentType !== 'Beyable' &&
				<Btn
					onClick={()=> setaddNewExternalAudience(true)}
					message="New association"
				/>
			}
		</>
	);
}
