import React, { Component } from 'react';
import styles from './VisitorEngagementProfileSignal.module.css';
import NumericUpDown from '../../Components/NumericUpDown';
import SignalTypeEnum from './SignalTypeEnum';

export default class VisitorEngagementProfileSignal extends Component {
    constructor(props) {
        super(props);
        this.onValueChange = this.onValueChange.bind(this);
        this.getSignalNameComponent = this.getSignalNameComponent.bind(this);
        this.raiseOnChange = this.raiseOnChange.bind(this);
    }

    onValueChange(event) {
        this.raiseOnChange(event.target.value);
    }

    getSignalNameComponent() {
        return <div className={styles.signalName}>
            {this.props.signal.name}
        </div>
    }

    raiseOnChange(value) {
        if (!this.props.onChange) return;
        this.props.onChange(this.props.signal.id, value);
    }

    render() {
        if (this.props.signal.type === SignalTypeEnum.Integer) {
            return <div className={styles.signalContainer}>
                <NumericUpDown 
                    min={0} 
                    value={this.props.value} 
                    onChange={this.onValueChange}
                    canEdit={this.props.canEdit} />
                {this.getSignalNameComponent()}
            </div>
        }
        else if (this.props.signal.type === SignalTypeEnum.Boolean) {
            return <div className={styles.signalContainer}>
                {this.getSignalNameComponent()}
                <div className={styles.yesNoSelectWrapper}>
                    <select disabled={!this.props.canEdit} className={styles.yesNoSelect} value={this.props.value} onChange={this.onValueChange}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                </div>
            </div>
        }
        else {
            return <div className={styles.signalContainer}>
                {this.getSignalNameComponent()}
                <input className={styles.textBox} type="text" value={this.props.value} onChange={this.onValueChange} readonly={!this.props.canEdit} />
            </div>
        }
    }
}