import React from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { react2angular } from 'react2angular';
import { ContextProvider } from './List/context';
import RouterWrapper from '../Dashboard/OnSite/RouterWrapper';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import SystemServices from '../../Services/SystemServices';
import CampaignCreatingServices from '../../Services/CampaignCreatingServices';
import ReportingCampaignServices from '../../Services/ReportingCampaignServices';
import PerformanceTrackingCampaignServices from '../../Services/PerformanceTrackingCampaignServices';
import PerformanceTrackingDashboardContainerInner from './List/PerformanceTrackingDashboardContainerInner';
import campaign_performancetracking_react_en from '../../Translation/jsonfiles/en/campaign_performancetracking_react_en.json';
import campaign_performancetracking_react_fr from '../../Translation/jsonfiles/fr/campaign_performancetracking_react_fr.json';
import utils_en from '../../Translation/jsonfiles/en/utils_en.json';
import utils_fr from '../../Translation/jsonfiles/fr/utils_fr.json';
import { mergeJson } from '../../Translation/translation-utils';


export default function PerformanceTrackingDashboardContainer(props) {
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $http = props.$http;
	const $timeout = props.$timeout;
	const authServices = props.AuthServices;

	const campaignServices = new CampaignCreatingServices($http, $routeParams, authServices);
	const systemServices = new SystemServices($rootScope, $timeout);
	const reportingCampaignService = new ReportingCampaignServices($http, $routeParams);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope.User.Account.Key);
	const performanceTrackingCampaignServices = new PerformanceTrackingCampaignServices(accountId, $http);

	const isAdmin = impersonatingServices.isAnAdmin();

	const linkToReporting = (campaignId) => {
		if (isAdmin && $routeParams.ka) {
			return `PerformanceTracking/Reporting?id=${campaignId}&dimension=onSite&ka=${accountId}&ku=${$routeParams.ku}`;
		} else {
			return `PerformanceTracking/Reporting?id=${campaignId}&dimension=onSite`;
		}
	};

	const i18nL = localStorage.getItem('i18nL');
	const newInstanceI18nextOnsite = i18next.createInstance();
	newInstanceI18nextOnsite.init({
		lng: 'en-EN',
		resources: {
			'en-US': {
				common: mergeJson(campaign_performancetracking_react_en, utils_en)
			},
			'en-EN': {
				common: mergeJson(campaign_performancetracking_react_en, utils_en)
			},
			'fr-FR': {
				common: mergeJson(campaign_performancetracking_react_fr, utils_fr)
			},
		}
	},
		(err, t) => {
			if (err) return console.log('something went wrong loading', err);
			newInstanceI18nextOnsite.changeLanguage(JSON.parse(i18nL));
		});

	return (
		<I18nextProvider i18n={newInstanceI18nextOnsite}>
			<ContextProvider
				getCampaignList={(callbackSuccess, callbackError) => performanceTrackingCampaignServices.getCampaignList(callbackSuccess, callbackError)}
				getCampaignInfos={(campaignId, callbackSuccess, callbackError) => performanceTrackingCampaignServices.getCampaignInfos(campaignId, callbackSuccess, callbackError)}
				createNewCampaign={(campaign, callbackSuccess, callbackError) => campaignServices.createNewCampaign(accountId, campaign, callbackSuccess, callbackError)}
				deleteCampaign={(campaignId, callbackSuccess, callbackError) => reportingCampaignService.deleteACampaign(accountId, campaignId, callbackSuccess, callbackError)}
				updateCampaign={(campaignId, { name, description }, callbackSuccess, callbackError) => performanceTrackingCampaignServices.updateCampaign(campaignId, { name, description }, callbackSuccess, callbackError)}
				systemServices={systemServices}
				linkToReporting={linkToReporting}
				$rootScope={$rootScope}
			>
				<RouterWrapper props={props}>
					<PerformanceTrackingDashboardContainerInner props={props} />
				</RouterWrapper>
			</ContextProvider>
		</I18nextProvider>
	);
}


angular
	.module('beyableSaasApp.PerformanceTrackingDashboard', ['beyableSaasApp.Services'])
	.component('performancetrackingdashboard', react2angular(PerformanceTrackingDashboardContainer, [],
		['$rootScope', '$http', '$routeParams', '$timeout', '$location', 'AuthServices']));