import React, { useEffect, useState, useCallback, Fragment } from 'react'
import './ConfigCodePromoContainer.css'
import Btn from './.././../../Components/Btn';
export default function ConfigCodePromoContainer({ putTheAccountPromoCode, accountId, getAccountConfiguration, setAccountCurrentTab, setIsCreationMode }) {

    const [formData, setFormData] = useState({
        lastName: '',
        firstName: '',
        email: ''
    })
    const [isNotValid, setIsNotValid] = useState([])
    const [apiKeyIsCreated, setApiKeyIsCreated] = useState()
    const [isRealoading, setIsRealoading] = useState(false)
    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function checkIfFormIsValid(e, form) {
        e.preventDefault()
        if (validateEmail(formData.email) && form.firstName.length > 0 && form.lastName.length > 0) {
            setIsNotValid([])
            setIsRealoading(true)
            const subscriber = {
                subscriber: formData
            }
            putTheAccountPromoCode(accountId, subscriber, e => {
                setApiKeyIsCreated(e.config.apiSubscriptionKey)
                setIsRealoading(false)
                getAccountConfiguration()
                setIsCreationMode(false)
            },
                e => {
                    setIsRealoading(false)
                })
        } else {
            let newArrayOfErrors = []
            if (!validateEmail(formData.email)) {
                newArrayOfErrors.push('email')
            }
            if (!form.firstName.length > 0) {
                newArrayOfErrors.push('firstName')
            }
            if (!form.lastName.length > 0) {
                newArrayOfErrors.push('lastName')
            }
            setIsNotValid(newArrayOfErrors)
        }
    }
    const handleChangeForm = (e) => {
        e.persist()
        let prevState = { ...formData }
        prevState[e.target.name] = e.target.value
        setFormData(prevState)
    }

    const callbackRef = useCallback(inputElement => {
        if (inputElement) {
            inputElement.focus();
        }
    }, []);
    return (
        <div className="page_body">
            <div className="page_body_title">Code Promo configuration</div>
            <div className="config_form_container">
                {!isRealoading && !apiKeyIsCreated &&
                    <Fragment>
                        <div className="form_block">
                            <label htmlFor="first_name" className="form_block_label">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={(e) => handleChangeForm(e)}
                                className={isNotValid.includes('firstName') ? 'information_not_good' : ''}
                                autoFocus
                                placeholder="Firsname"
                                ref={callbackRef}
                            />
                            {isNotValid.includes('firstName') && <p className="information_error_message">The firstname is empty</p>}
                        </div>
                        <div className="form_block">
                            <label htmlFor="last_name" className="form_block_label">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                placeholder="LastName"
                                onChange={(e) => handleChangeForm(e)}
                                className={isNotValid.includes('lastName') ? 'information_not_good' : ''}
                            />
                            {isNotValid.includes('lastName') && <p className="information_error_message">The lastname is empty</p>}
                        </div>
                        <div className="form_block">
                            <label htmlFor="email" className="form_block_label">Email</label>
                            <input
                                type="text"
                                name="email"
                                value={formData.email}
                                placeholder="example@email.com"
                                onChange={(e) => handleChangeForm(e)}
                                className={isNotValid.includes('email') ? 'information_not_good' : ''}
                            />
                            {isNotValid.includes('email') && <p className="information_error_message">The email is not valid</p>}
                        </div>
                        <div className="form_footer">
                            <Btn
                                onClickFunction={(event) => { event.preventDefault(); setAccountCurrentTab(0) }}
                                message="Cancel"
                                color="secondary"
                                style="outline"
                            />
                            <Btn
                                onClickFunction={(e) => checkIfFormIsValid(e, formData)}
                                message="Submit"
                                style="primary"
                            />
                        </div>
                    </Fragment>
                }
                {apiKeyIsCreated &&
                    <form >
                        <p>Your api Key was Created with success </p>
                        {apiKeyIsCreated}
                    </form>

                }
                {
                    isRealoading &&
                    <div className="spinner_overlay">
                        <div className="spinner">
                            <div className="rect1"></div>
                            <div className="rect2"></div>
                            <div className="rect3"></div>
                            <div className="rect4"></div>
                            <div className="rect5"></div>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}
