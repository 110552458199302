export const createCheckbox = (name, label) => {
	const sandbox = document.createElement('div');
	const tpl = `
		<label class="cb_label m flex vertical">
			<input type="checkbox" name="${name}">
			<span class="cb_label_icon flex_item_fix flex_item_align_start"></span>
			<span class="cb_label_text flex_item_full">${label}</span>
		</label>
	`;

	sandbox.insertAdjacentHTML('afterbegin', tpl);
	const wrapper = sandbox.firstElementChild;
	const checkbox = sandbox.querySelector('input');

	return [wrapper, checkbox];
};