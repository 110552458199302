
const today = moment().format('YYYY/MM/DD');

// OnGoing + Scheduled + Stopped
const OnGoingScheduledStopped =
{
	"or": [
		{
			"and": [
				{
					"or": [
						{ "endDate": { "gt": `${today}` } },
						{ "endDate": { "eq": null } }
					]
				},
				{ "startDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } }
			]
		},
		{
			"and": [
				{ "startDate": { "gt": `${today}` } },
			]
		},
		{
			"and": [
				{ "isStopped": { "eq": true } },
			]
		},
	]
};
// // OnGoing + Scheduled + Past
const OnGoingScheduledPast =
{
	"or": [
		{
			"and": [
				{
					"or": [
						{ "endDate": { "gt": `${today}` } },
						{ "endDate": { "eq": null } }
					]
				},
				{ "startDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } }
			]
		},
		{
			"and": [
				{ "startDate": { "gt": `${today}` } },
				{ "isStopped": { "eq": false } },
			]
		},
		{
			"and": [
				{ "endDate": { "ngt": `${today}` } },
				// { "startDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } },
			]
		},
	]
};
// // OnGoing + Stopped + Past
const OnGoingStoppedPast =
{
	"or": [
		{
			"and": [
				{
					"or": [
						{ "endDate": { "gt": `${today}` } },
						{ "endDate": { "eq": null } }
					]
				},
				{ "startDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } }
			]
		},
		{
			"and": [
				{ "isStopped": { "eq": true } },
			]
		},
		{
			"and": [
				{ "endDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } },
			]
		},
	]
};
// // Scheduled + Stopped + Past
const ScheduledStoppedPast =
{
	"or": [
		{
			"and": [
				{ "startDate": { "gt": `${today}` } },
				{ "isStopped": { "eq": false } },
			]
		},
		{
			"and": [
				{ "isStopped": { "eq": true } },
			]
		},
		{
			"and": [
				{ "endDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } },
			]
		},
	]
};
// // OnGoing + Scheduled
const OnGoingScheduled =
{
	"or": [
		{
			"and": [
				{
					"or": [
						{ "endDate": { "gt": `${today}` } },
						{ "endDate": { "eq": null } }
					]
				},
				{ "startDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } }
			]
		},
		{
			"and": [
				{ "startDate": { "gt": `${today}` } },
				{ "isStopped": { "eq": false } }
			]
		},

	]
};

// // OnGoing + PAST
const OnGoingPast =
{
	"or": [
		{
			"and": [
				{
					"or": [
						{ "endDate": { "gt": `${today}` } },
						{ "endDate": { "eq": null } }
					]
				},
				{ "startDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } }
			]
		},
		{
			"and": [
				{ "endDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } },
			]
		}
	]
};

// // OnGoing + Stopped
const OnGoingStopped =
{
	"or": [
		{
			"and": [
				{
					"or": [
						{ "endDate": { "gt": `${today}` } },
						{ "endDate": { "eq": null } }
					]
				},
				{ "startDate": { "ngt": `${today}` } },
				{ "isStopped": { "eq": false } }
			]
		},
		{ "isStopped": { "eq": true } },
	]
};
// // Stopped + past
const StoppedPast =
{
	"or": [
		{ "isStopped": { "eq": true } },
		{
			"and": [
				{ "isStopped": { "eq": false } },
				{ "endDate": { "ngt": `${today}` } },
			]
		}
	]
};
// //  Scheduled + Past
const ScheduledPast =
{
	"or": [
		{
			"and": [
				{
					"or": [
						{ "endDate": { "gt": `${today}` } },
						{ "endDate": { "eq": null } }
					]
				},
				{ "startDate": { "gt": `${today}` } },
				{ "isStopped": { "eq": false } }
			]
		},
		{
			"and": [
				{ "isStopped": { "eq": false } },
				{ "endDate": { "ngt": `${today}` } },
			]
		}

	]
};
// //  Scheduled + Stopped
const ScheduledStopped =
{
	"or": [
		{
			"and": [
				{
					"or": [
						{ "endDate": { "gt": `${today}` } },
						{ "endDate": { "eq": null } }
					]
				},
				{ "startDate": { "gt": `${today}` } },
				{ "isStopped": { "eq": false } }
			]
		},
		{ "isStopped": { "eq": true } },
	]
};
// /// Just OnGoing :
const OnGoing =
{
	"and": [
		{
			"or": [
				{ "endDate": { "gt": `${today}` } },
				{ "endDate": { "eq": null } }
			]
		},
		{ "startDate": { "ngt": `${today}` } },
		{ "isStopped": { "eq": false } }
	]
};

const RanBetween = (startDate, endDate) => (
	{
		"and": [
			{
				"or": [
					{ "endDate": { "gte": `${startDate}` } },
					{ "endDate": { "eq": null } }
				]
			},
			{ "startDate": { "lte": `${endDate}` } }
		]
	});
// /// Just Past :
const past =
{
	"and": [
		{ "endDate": { "ngt": `${today}` } },
		{ "isStopped": { "eq": false } }
	]
};
// /// Just Scheduled : 
const Scheduled =
{
	"and": [
		{ "startDate": { "gt": `${today}` } },
		{ "isStopped": { "eq": false } }
	]
}
// /// Juste Stopped : 
const Stopped = { "isStopped": { "eq": true } };
const All = {
	"or": [
		{ "isStopped": { "eq": false } },
		{ "isStopped": { "eq": true } },
	]
}

export {
	OnGoingScheduledStopped,
	ScheduledStoppedPast,
	OnGoingScheduled,
	OnGoingScheduledPast,
	OnGoingPast,
	OnGoingStoppedPast,
	OnGoingStopped,
	OnGoing,
	past,
	Scheduled,
	Stopped,
	All,
	StoppedPast,
	ScheduledPast,
	ScheduledStopped,
	RanBetween
};