export const getUserFullName = (user) => {
	const firstName = user.FirstName || user.firstName || '';
	const lastName = user.LastName || user.lastName || '';
	const email = user.Email || user.email || '';

	let fullName = firstName + ' ' + lastName;
	fullName = fullName.trim();

	return fullName || email;
};

export default function useDisplayName() {
	let displayname = '';
	const getTheDisplayNameOfUser = (user) => {
		user.firstName = user.firstName || '';
		user.lastName = user.lastName || '';
		user.email = user.email || '';
		user.displayName = user.firstName + ' ' + user.lastName;

		if (user.displayName.trim() == '') {
			user.displayName = user.email ? user.email : '';
		}
		return user.displayName;
	};
	return { displayname, getTheDisplayNameOfUser };
}
