import React, {useEffect,} from 'react';
import EcommerceOverviewContextProvider from '../../../context/EcommerceOverviewContext';
import EcommercePerformanceContextProvider from '../../../context/EcommercePerformanceContext';
import EcommerceTransactionContextProvider  from '../../../context/EcommerceTransactionContext';
import EcommerceBuyingBehaviorContextProvider  from '../../../context/EcommerceBuyingBehaviorContext';
import BuyingBehaviourContainer from './BuyingBehaviourContainer';
import OverviewContainer from './OverviewContainer.js';
import PerformanceContainer from './PerformanceContainer';
import TransactionContainer from './TransactionContainer';
import ImpersonatingServices from '../../../../../Services/ImpersonatingServices';
import { WebAnalyticsOverviewServices } from '../../../../../Services/WebAnalyticsServices';
import SystemServices from '../../../../../Services/SystemServices';
export default function EcommerceTabContainer({
	sessionService,
	$http,
	accountId,
	userId,
	$routeParams,
	$rootScope,
	timeout,
	selectedTenant,
	styles,
	changeActiveDimensionTabOver
}) {
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const overviewServices = new WebAnalyticsOverviewServices($http, accountId, () => selectedTenant);
	const systemServices = new SystemServices($rootScope, timeout);

	return (
		<>
			<EcommerceOverviewContextProvider
				changeActiveDimensionTabOver={changeActiveDimensionTabOver}
			>
				<OverviewContainer
					styles={styles}
					overviewServices={overviewServices}
					sessionService={sessionService}
					systemServices={systemServices}
				/>
			</EcommerceOverviewContextProvider>
			<EcommercePerformanceContextProvider
			>
				<PerformanceContainer
					styles={styles}
					systemServices={systemServices}
					services={overviewServices}
					sessionService={sessionService}
				/>
			</EcommercePerformanceContextProvider>
			<EcommerceTransactionContextProvider
			>
				<TransactionContainer
					styles={styles}
					systemServices={systemServices}
					services={overviewServices}
					sessionService={sessionService}
				/>
			</EcommerceTransactionContextProvider>
			<EcommerceBuyingBehaviorContextProvider>
				<BuyingBehaviourContainer
					styles={styles}
					systemServices={systemServices}
					services={overviewServices}
					sessionService={sessionService}
				/>
			</EcommerceBuyingBehaviorContextProvider>
		</>
	);
}
