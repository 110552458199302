import React, { Component } from 'react';
import InputCustom from './InputCustom';

export default class NumericUpDown extends Component {
	constructor(props) {
		super(props);
		this.increment = this.increment.bind(this);
		this.decrement = this.decrement.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.raiseOnChange = this.raiseOnChange.bind(this);
	}

	increment() {
		this.raiseOnChange(parseInt(this.props.value, 10) + 1);
	}

	decrement() {
		this.raiseOnChange(parseInt(this.props.value, 10) - 1);
	}

	onInputChange(event) {
		this.raiseOnChange(event.target.value);
	}

	raiseOnChange(value) {
		value = parseInt(value, 10);

		if (value < this.props.min) value = this.props.min;
		if (value > this.props.max) value = this.props.max;

		if (value === this.props.value) return;

		if (this.props.onChange && typeof this.props.onChange === 'function') {
			this.props.onChange({
				target: {
					value: value
				}
			});
		}
	}


	render() {
		return (
			<InputCustom
				type="number"
				name={this.props.name}
				value={this.props.value}
				min={this.props.min}
				max={this.props.max}
				onChange={this.onInputChange}
				readOnly={!this.props.canEdit}
				disabled={this.props.disabled || false}
				unit={this.props.unitPlaceholder}
				label={this.props.label}
				labelClassName={this.props.labelClassName}
				blockClassName={this.props.blockClassName}
				description={this.props.description}
				descriptionBottom={this.props.descriptionBottom}
				labelTooltip={this.props.labelTooltip}
				className="w_xs hp_s al_center"
				center={true}
				selectOnFocus={true}
				isAnError={this.props.isAnError}
				prepend={
					<>
						{this.props.canEdit &&
							<a className="custom_input_updown"
								onClick={this.decrement}
								onMouseDown={(ev) => ev.preventDefault()}>
								<i className="fas fa-minus"></i>
							</a>
						}
					</>
				}
				append={
					<>
						{this.props.canEdit &&
							<a className="custom_input_updown"
								onClick={this.increment}
								onMouseDown={(ev) => ev.preventDefault()}>
								<i className="fas fa-plus"></i>
							</a>
						}
					</>
				}
			/>
		);
	}
}