import React, {useState} from "react";
import './InstallationCode.css'
import CodeSnippet from "../../../../Components/CodeSnippet";
import SectionMessage from "../../../../Components/SectionMessage";
import TabBar from "../../../../Components/TabBar";
import TabItem from "../../../../Components/TabItem";
import InstallationTags from "../../../../Constants/InstallationTags";

export default function InstallationCode({ beyableKey, accountId, showReady, showAnalytics }){

    const { analyticsTag, readyTag } = InstallationTags;
    const analyticsScript = analyticsTag(beyableKey);
    const readyScript = readyTag(accountId, beyableKey);
    const [activeTab, setActiveTab] = useState('analytics');

    let nbScripts = 0;
    if (showReady) nbScripts++;
    if (showAnalytics) nbScripts++;

    return (
        <div className="ob_slide">
            <legend className="ob_title_2">
                {nbScripts == 1 ? "Install BEYABLE tag" : "Install BEYABLE tags"}
            </legend>

            <SectionMessage
                type="success"
                title={nbScripts == 1 ? 'Your script is ready!' : 'Your scripts are ready!'}
                text={
                    <>
                        Paste this code into the &lt;head&gt; section of every page of your website.<br/>
                        You can do this with a tag manager.<br/>
                        No worries, you can <strong>skip this step</strong> and <strong>install this code later</strong>.
                    </>
                }
            />

            {nbScripts >= 2 &&
                <div className="mb_20">
                    <TabBar size="l">
                        {showAnalytics &&
                            <TabItem 
                                message="BEYABLE Analytics"
                                onClick={() => setActiveTab('analytics')}
                                selected={activeTab == 'analytics'}
                            />
                        }
                        {showReady &&
                            <TabItem 
                                message="BEYABLE Ready"
                                onClick={() => setActiveTab('ready')}
                                selected={activeTab == 'ready'}
                            />
                        }
                    </TabBar>
                </div>
            }
            {showAnalytics && (nbScripts == 1 || activeTab == 'analytics') &&
                <div className="pos_rel">
                    <CodeSnippet code={analyticsScript} nbRows={17}/>
                </div>
            }

            {showReady && (nbScripts == 1 || activeTab == 'ready') &&
                <div className="pos_rel">
                    <CodeSnippet code={readyScript} nbRows="12"/>
                </div>
            }
        </div>
    );
};


