import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import { ReportingCampaign } from '../ReportingCampaign/ReportingCampaignContainer';
import NavReportingServerSide from '../ReportingCampaign/NavReportingServerSide';
import ImpersonatingServices from '../../Services/ImpersonatingServices';


export default function ServerSideCampaignReportingContainer({ $location, AuthServices, $http, $routeParams, $rootScope }) {
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope.User.Account.Key);

	const isAdmin = impersonatingServices.isAnAdmin();

	const navReporting = () => <NavReportingServerSide 
		isAdmin={isAdmin}
		$routeParams={$routeParams}
		accountId={accountId}
	/>;

	return <ReportingCampaign
		$location={$location}
		AuthServices={AuthServices}
		$http={$http}
		$routeParams={$routeParams}
		$rootScope={$rootScope}
		initialCampaignId={$location && $location.$$search.id}
		loadCampaignFunction={service => (a, c, cc) => service.getServerSideCampaignsForAccount(a, c, cc)}
		initialDimension={$location.$$search.dimension}
		navReporting={navReporting}
		getEditorFunction={service => (campaignId, callbackSuccess, callbackError) => service.getServerSideCampaignEditor(accountId, campaignId, callbackSuccess, callbackError)}
		shouldDisplayEditorLink={false}
		shouldDisplayStatus={false}
		shouldDisplayDates={false}
	></ReportingCampaign>;
}


angular.module('beyableSaasApp.ServerSideReporting', [])
	.component('serversidecampaignreporting', react2angular(ServerSideCampaignReportingContainer, [], ['$http', '$rootScope', '$routeParams', '$location', 'AuthServices']));
