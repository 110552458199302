export const indicatorsCompute = [
	{
		key: 'numberofobjectivespostviewinsession.upliftRate',
		label: 'Post view conversion uplift rate (%) ',
		infos: ''
	},
	{
		key: 'numberofobjectivespostviewinsession.upliftEstimated',
		label: 'Incremental post view conversions ',
		infos: 'conversions TEST - (conversion rate CONTROL x impressions TEST)',
	},
	{
		key: 'amountofobjectivespostviewinsession.upliftEstimated',
		label: 'Incremental income (€)',
		infos: 'Incremental post view conversions x average basket TEST',
	},
	{
		key: 'numberoftargetspostclickinsession.upliftRate',
		label: 'Targets post click in session increment (%)',
		infos: '',
	},
	{
		key: 'numberoftargetspostviewinsession.upliftRate',
		label: 'Targets post view in session increment (%)',
		infos: '',
	},
];

let _isTargetIndicator = function (key) {
	return key.startsWith('numberoftargetspostclickinsession_') || key.startsWith('numberoftargetspostviewinsession_');
};
let deconstructTargetIndictor = function (key) {
	var split = key.split('_');
	if (_isTargetIndicator(key) && split.length === 3) {
		var targetIndicatorKey = split[0];
		var targetValue = `${split[1]}: ${split[2]}`;

		return [targetIndicatorKey, targetValue];
	}
};

export function getIndicatorComputeByKey(key) {
	// console.log(key)
	var split = key.split('.');
	let targetIndicator = deconstructTargetIndictor(split[0]);
	if (targetIndicator && split.length === 2) {
		var targetIndicatorKey = targetIndicator[0];
		var targetValue = targetIndicator[1];
		var compute = split[1];
		var i = getIndicatorComputeByKey(`${targetIndicatorKey}.${compute}`);
		return { ...i, label: `${i.label} (${targetValue})` };
	}

	var indicator = indicatorsCompute.filter(item => item.key === key);
	if (indicator.length > 0)
		return indicator[0];
	else return {
		key: key,
		label: key,
		infos: ''
	};
}

