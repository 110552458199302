import React, { useEffect } from 'react'
import './CreateTagsContainer.css'
export default function CreateTagsCOntainer({ productFeedTags, listTags, setListTags, setChangesIsNotSaved }) {

    const handleChange = (event) => {
        setListTags(event.target.value)
        setChangesIsNotSaved(true)
    }

    useEffect(() => {
        setListTags(productFeedTags.toString())
    }, [productFeedTags])

    return (
        <div className="onsiteData_create_tag_container" >
            <h3> Tags</h3>
            <p>Separate tags with a carriage return, comma ( , ) or semi-colon ( ; )</p>
            <textarea
                className=''
                name=""
                value={listTags}
                onChange={handleChange}
                placeholder={`Example : 
                    XKOLKOPX;OPLMOKHG,KIO89JX`} />
        </div >
    )
}
