import HttpServices from './HttpServices';

export default class AdminServices {
	constructor($http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}

	LoadLogs(filter, order, skip, top, callbackSuccess, callbackError) {
		let filterQuery = filter ? '$filter=' + filter : '';
		let orderQuery = order ? (filter ? '&$orderby=' : '$orderby=') + order : '';
		const res = ((filter || order ? '?' : '') + filterQuery + orderQuery);
		this.HttpServices.get(`${this.SERVER_URL}odata/AuditTrailEntry/${res}&$skip=${skip}&$top=${top}&$count=true`, callbackSuccess, callbackError);
	}
	GetUserById(id, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.SERVER_URL}users/${id}`, callbackSuccess, callbackError);
	}
	CreateAccount(account, callbackSuccess, callbackError) {
		const create = { Name: account.AccountName, ContactEmail: account.Email };
		this.HttpServices.post(`${this.SERVER_URL}accounts/`, create, callbackSuccess, callbackError);
	}
	GetAccountInfo(accountKey, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.SERVER_URL}users/8504aaaa-aaaa-9999-8888-8885d94b896e/accounts/${accountKey}`, callbackSuccess, callbackError);
	}
	LoadAllAccountManager(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.SERVER_URL}accountManagers/`, callbackSuccess, callbackError);
	}
	EditAccount(account, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.SERVER_URL}accounts/`, account, callbackSuccess, callbackError);
	}
	UpdateAccountModules(accountId, modules, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}account/${accountId}/module`, { modules: modules.map(m => ({moduleId:m, moduleStatus:'Active'})) }, 
			callbackSuccess, callbackError);
	}
	GetAccountModules(accountId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}account/${accountId}/module`, 
			callbackSuccess, callbackError);
	}
}