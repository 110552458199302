import React, { useContext, useState, useEffect } from 'react';
import { CreateBusinessOperationContext } from '../../Context/ContextCreateBusinessOperations';
import useEventBroker from '../../../../../Hooks/useEventBroker';
import Modal from './../../../../../Components/Modal';
import Btn from './../../../../../Components/Btn';
import Checkbox from './../../../../../Components/Checkbox';
import Switch from './../../../../../Components/Switch';
import Progress from './../Progress';

export default function ResizeBannerWorkflowCreationModal({ accountId, businessOperationServices, systemServices }) {
	const {
		operation,
		listZones
	} = useContext(CreateBusinessOperationContext);

	const { subscribe, publish } = useEventBroker();

	const [modalIsOpened, setModalIsOpened] = useState(false);
	const [zonesGroupedByCoordinates, setZonesGroupedByCoordinates] = useState(false);
	const [filesToSendAsWorkflow, setFilesToSendAsWorkflow] = useState(false);
	const [filesToDisplay, setFilesToDisplay] = useState(false);
	const [selectedDimensions, setSelectedDimensions] = useState(new Set());
	const [uploadPercentage, setUploadPercentage] = useState(0);
	const [isUploading, setIsUploading] = useState(false);

	subscribe('evt/businessoperations/resizeBannerWorkflowCreationRequested', e => {
		const { filesToSendAsWorkflow } = e.detail;
		const filesToDisplay = [];
		for (let i = 0; i < filesToSendAsWorkflow.length; i++) {
			let file = filesToSendAsWorkflow[i];
			let clone = {};
			clone.name = file.name;
			let type = 'photoshop';
			if (file.name && file.name.indexOf('.ai') > -1) {
				type = 'illustrator';
			}
			clone.type = type;
			filesToDisplay.push(clone);
		}
		setFilesToSendAsWorkflow(filesToSendAsWorkflow);
		setFilesToDisplay(filesToDisplay);
		setModalIsOpened(true);
	});

	const groupBy = (items, getKey, getValue) =>
		items.reduce((r, a) => {
			r[getKey(a)] = [...r[getKey(a)] || [], getValue(a)];
			return r;
		}, {});

	const objectMap = (obj, fn) =>
		Object.fromEntries(
			Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
		);

	const objectMapValues = (obj, fn) => Object.values(objectMap(obj, fn));

	useEffect(() => {
		if (!listZones) return;

		var allZones = listZones.flatMap(pageType =>
			pageType.zones.map(z => ({
				width: z.zoneExtensions.Coordinates.width,
				height: z.zoneExtensions.Coordinates.height,
				zoneName: z.name,
				pageTypeName: pageType.name
			})));

		var groups = groupBy(allZones, a => [a.width, a.height], a => [a.pageTypeName, a.zoneName]);
		var zoneGrouped = objectMap(groups, zones => groupBy(zones, z => z[0], z => [z[1]]));
		setZonesGroupedByCoordinates(zoneGrouped);
	}, [listZones]);


	const handleZoneDimensionsSelected = (e) => {
		const value = e.target.value;
		if (e.target.checked)
			setSelectedDimensions(new Set([...selectedDimensions, value]));
		else
			setSelectedDimensions(new Set([...selectedDimensions].filter(x => x != value)));
	};

	const validate = () => {
		setIsUploading(true);
		const libraryId = operation.operation.libraryId;
		let formData = new FormData();
		for (let i = 0; i < filesToSendAsWorkflow.length; i++) {
			let file = filesToSendAsWorkflow[i];
			formData.append(`file[${i}]`, file);
		}
		for (let element of selectedDimensions) {
			formData.append('resizingDimensions', element.replace(',', 'x'));
		}
		businessOperationServices.createBannerResizeWorflow(accountId, libraryId, formData, progressEvent => {
			const progress = progressEvent.loaded / progressEvent.total * 100;
			setUploadPercentage(Math.round(progress));
		})
			.then(result => {
				setTimeout(() => {
					setIsUploading(false);
					setModalIsOpened(false);
					publish('evt/businessoperations/resizeBannerWorkflowCreated');
				}, 200);
			}
			).catch(error => {
				setTimeout(() => {
					setIsUploading(false);
					setModalIsOpened(false);
					systemServices.showError('An error occured while creating your resizing request.Please contact the support team.');
				}, 200);
			});
	};

	return (
		<Modal
			isOpen={modalIsOpened}
			width="600"
			noClose={false}
			onClose={() => setModalIsOpened(false)}
		>

			<div className="modal_header has_border">
				Resizing request
				<div className="modal_header_desc">
					<p>You uploaded a source file (.ai or .psd).</p> <p>Please select the dimensions you want your banners to be resized to.</p>
				</div>
			</div>
			{filesToDisplay && filesToDisplay.length > 0 &&
				<div className="modal_section modal_section_s modal_section_grey flex_item_fix">
					{filesToDisplay.map((file, i) =>
						<div className="flex mt_5 mb_5" key={i}>
							<div className={'flex_item_fix file_icon_32 file_icon_' + file.type}></div>
							<div className="fw_medium ml_15">{file.name}</div>
						</div>
					)}
				</div>
			}
			<div className="modal_body">
				{
					zonesGroupedByCoordinates && objectMapValues(zonesGroupedByCoordinates, (zonesByPageType, zoneDimensions, i) => {
						return <div key={i} className="flex flex_align_start resize_format">
							<div className="flex_item_fix mr_10">
								<Switch
									name="zoneSelected"
									value={zoneDimensions}
									onChange={handleZoneDimensionsSelected}
								>
								</Switch>
							</div>
							<div>
								<div className="fw_medium">{zoneDimensions.replace(',', ' x ')} px</div>
								<div className="s_13">
									{objectMapValues(zonesByPageType, (zoneNames, pageType, j) => {
										return <div key={j}><span className="fw_medium">{pageType} :</span> {zoneNames.join(', ')}</div>;
									}
									)}
								</div>
							</div>
						</div>;
					})
				}
			</div>
			<div className="modal_footer modal_footer_right">
				<Btn
					onClickFunction={(e) => { setModalIsOpened(false); }}
					message="Cancel"
					color="secondary"
					style="outline"
				/>
				<Btn
					disabled={selectedDimensions.size == 0}
					onClickFunction={validate}
					message="Ok"
				/>
			</div>
			{isUploading ?
				(<div className="uploadBannerOverlay">
					<Progress
						percentage={uploadPercentage}
						closeProgressBar={() => { setIsUploading(false); setUploadPercentage(0); }}
					/>
				</div>) : null}

		</Modal>
	);
}