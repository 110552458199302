import HttpServices from './HttpServices';

export default class ServerSideCampaignServices {
	constructor(accountId, $http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		this.accountId = accountId;
	}

	getCampaignInfos(campaignId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/server-side-campaign/${campaignId}`, callbackSuccess, callbackError);
	}

	getCampaignList(callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}${this.accountId}/server-side-campaign`, callbackSuccess, callbackError);
	}

	updateCampaign(campaignId, {name, description}, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}${this.accountId}/server-side-campaign/${campaignId}`, {name, description}, callbackSuccess, callbackError);
	}
}
