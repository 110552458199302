
export const devicesNav: { value: string, label: string }[] = [
	// {
	// 	value : 'all',
	// 	label : 'All'
	// },
	{
		value: 'web',
		label: 'Web'
	},
	{
		value: 'native',
		label: 'Native'
	},
	// {
	// 	value : 'Tablet',
	// 	label : 'Tablet'
	// }
];


const optionToTechnicalPurpose = (value: string) : string | null => {
	switch (value){
		case 'web': return 'ACTUAL_CAMPAIGN';
		case 'native': return 'ACTUAL_NATIVE_APP_CAMPAIGN';
	}
	return null;
};

export const deviceNavToGraphFilter = (selectedDevices: string[]) => {
	if (selectedDevices.length == 0)
		return null;

	if (selectedDevices.length == devicesNav.length) // all options are selected, so no need to filter
		return null;

	const technicalPurposes = selectedDevices
		.map(d => optionToTechnicalPurpose(d))
		.filter(v => v != null);

	if (technicalPurposes.length == 1){
		return { technicalPurpose : { eq: technicalPurposes[0] }};
	}
	else {
		return { technicalPurpose : { in: technicalPurposes }};
	}
};