import React ,{useState, useEffect} from 'react';
import { react2angular } from 'react2angular';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import settingsFr from '../../Translation/jsonfiles/fr/settings_fr.json';
import settingsEn from '../../Translation/jsonfiles/en/settings_en.json';
import AccountHomeUsers from './AccountHomeUsers';
export default function AccountHomeUser(props) {
	const i18nL= localStorage.getItem('i18nL');
	const newInstanceI18nextUser = i18next.createInstance();
	newInstanceI18nextUser.init({
		lng: 'en-EN',                             
		resources: {
			'en-US': {
				common: settingsEn            
			},
			'en-EN': {
				common: settingsEn            
			},
			'fr-FR': {
				common: settingsFr
			},
		}},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextUser.changeLanguage(JSON.parse(i18nL));
	});

	return (
		<I18nextProvider i18n={newInstanceI18nextUser}>
			<AccountHomeUsers
				$rootScope={props.$rootScope}
				AuthServices={props.AuthServices}
				$timeout={props.$timeout}
			/>
		</I18nextProvider>
	);
}

angular
	.module('beyableSaasApp.AccountHomeUser', ['beyableSaasApp.Admin.Services'])
	.component('accounthomeuser', react2angular(AccountHomeUser, [],  ['$http', '$rootScope', '$routeParams', '$timeout', 'AuthServices']));