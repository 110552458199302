import React, {useState,useEffect} from 'react';
import InputCustom from '../../../Components/InputCustom';
import videoService from '../Services/VideoService';
const { checkErrors, getId, isCorrectUrlYoutube } = videoService();

export default function VideoEditor({
	label,
	data,
	onChange,
	property,
	propertyContainer
}) {

	const [errors, setErrors] = useState([]);
	const [videoData, setvideoData] = useState();
	const [isLoading, setisLoading] = useState(false);
	const isAnError = (name) => {
		const errorsByName = errors.map(er => er.key);
		const isError = errorsByName.includes(name) ? errors.find(er => er.key === name).message : null;
		return isError;
	};
	const [newUrl, setNewUrl] = useState('');

	// will be used to select Video Type (YOUTUBE, etc)
	const siblingProperties = propertyContainer && propertyContainer.siblingPropertiesAccessor;
	const getVideoTypeProperty = () => siblingProperties.getSiblingPropertyData(property.Editor.VideoTypePropertyName);
	const setVideoTypeProperty = (v) => siblingProperties.setSiblingPropertyData(property.Editor.VideoTypePropertyName, v);

	const handleChangeName = (value) => {
		setErrors([]);
		setNewUrl(value);
		
	};
	const addUrl = async () => {
		const arrayOfErrors = checkErrors(newUrl);
		if (arrayOfErrors.length !== 0) {
			setErrors(arrayOfErrors);
			setisLoading(false);
		} else {
			const getIdYtb = await getId(newUrl);
			if (getIdYtb.type === 'error') {
				setErrors([getIdYtb]);
				setisLoading(false);
			} else {
				const valueVideoEmbed = 'https://www.youtube-nocookie.com/embed/';
				setvideoData(getIdYtb);
				onChange(getIdYtb.youtubeId);
				setisLoading(false);
			}
		}
	};
	useEffect(() => {
		if (newUrl.length !== 0) {
			const inputValIsYoutubeVideo = isCorrectUrlYoutube(newUrl);
			if (inputValIsYoutubeVideo) {
				addUrl();
				setisLoading(true);
			} else {
				const arrayOfErrors = checkErrors(newUrl);
				setErrors(arrayOfErrors);
			}
		}
	}, [newUrl]);
	useEffect(() => {
		setNewUrl('https://www.youtube.com/watch?v=' + data);
		setVideoTypeProperty(getVideoTypeProperty() || property.Editor.VideoType);
	}, [data]);
	return (
		<div>
			<InputCustom
				value={newUrl}
				label="Accepted formats : youtube url"
				name="url"
				placeholder="Url like https://www.youtube.com/watch?v=Elf25fdfdA"
				onChange={(e) => handleChangeName(e.target.value)}
				fullWidth={true}
				isAnError={isAnError('url')}
				blockClassName="no_margin"
			/>
		</div>
	);
}
