import React, { createContext, useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import EmailTemplateServices from '../../../../Services/EmailTemplateServices';
import { EmailTemplateInListApiType, EmailTemplateStatus } from '../../../../Services/EmailTemplateServicesTypes';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';
// import EventTrackingServices from '../../../../Services/EventTrackingServices';


const EmailTemplateListContext = createContext<EmailTemplateListContextType | undefined>(undefined);

function useEmailTemplateListContext() {
	const context = useContext(EmailTemplateListContext);
	if (!context) throw Error('useEmailTemplateListContext can only be used inside an EmailTemplateListContextProvider');
	return context;
}

const CreateEmailTemplateListContextProvider = (props) => {
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $timeout = props.$timeout;

	const systemServices = new SystemServices($rootScope, $timeout);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const isAdmin = impersonatingServices.isAnAdmin();

	//const { trackEvent } = EventTrackingServices(props.$rootScope.User);

	const crud = new EmailTemplateServices(accountId, $http);

	const [idTpl, setIdTpl] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [templates, setTemplates] = useState<EmailTemplateInListApiType[]>([]);
	const [modalViewWidgetHtmlIsOpen, setModalViewWidgetHtmlIsOpen] = useState<boolean>(false);
	const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState<boolean>(false);
	const [widgetHtml, setWidgetHtml] = useState<string | undefined>();

	const handleDeleteCampaign = () => {
		crud.deleteEmailTemplate(idTpl!, _success => {
			getEmailTemplates();
		}, error => {
			console.error(error);
			setIsLoading(false);
			systemServices.showError('An error occured while delete email template');
		})
	}

	const getEmailTemplates = () => {
		crud.getEmailTemplates(
			result => {
				result.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
				setTemplates(result);
				setIsLoading(false);
			},
			err => {
				console.error(err);
				setIsLoading(false);
				systemServices.showError('An error occured while loading email templates');
			}
		);
	}
	
	useEffect(() => {
		getEmailTemplates();
	}, []);

	const getEditLink = (emailTemplateId: string): string => {
		const ka = isAdmin && props.$routeParams?.ka ? `&ka=${accountId}` : '';
		const url = `/EmailTemplate/Editor?id=${emailTemplateId}${ka}`;
		return url;
	};
	const getCreationLink = (): string => {
		const ka = isAdmin && props.$routeParams?.ka ? `?ka=${accountId}` : '';
		const url = `/EmailTemplate/Editor${ka}`;
		return url;
	};

	const changeEmailTemplateStatus = (emailTemplateId: string, status: EmailTemplateStatus) => {
		crud.updateEmailTemplateStatus(emailTemplateId, status, () => {
			systemServices.showSuccess(status === EmailTemplateStatus.Active ? 'The email widget has been enabled' : 'The email widget has been disabled');
			getEmailTemplates();
		}, err => {
			console.log(err);
			systemServices.showError(status === EmailTemplateStatus.Active ? 'An error occured while starting the email widget' : 'An error occured while stopping the email widget');
		});
	};

	const handleOpenViewWidgetModal = (shouldGetWidgetHtml: boolean, emailTemplateId: (string | null)) => {
		setModalViewWidgetHtmlIsOpen(shouldGetWidgetHtml);
		setWidgetHtml(undefined);
		if (shouldGetWidgetHtml && emailTemplateId != null) {
			crud.generateEmailTemplateOutput(emailTemplateId, res => {
				setWidgetHtml(res)
			}, err => {
				console.log(err);
				setWidgetHtml(undefined);
				systemServices.showError('An error occured while getting html of the email widget');
			});
		}
	}
	const context: EmailTemplateListContextType = {
		isLoading,
		templates,
		changeEmailTemplateStatus,
		getEditLink,
		getCreationLink,
		modaHtmlIsOpen: modalViewWidgetHtmlIsOpen,
		handleOpenViewWidgetModal,
		handleDeleteCampaign,
		idTpl,
		setIdTpl,
		deleteConfirmIsOpen,
		setDeleteConfirmIsOpen,
		widgetHtml

	};

	return (
		<EmailTemplateListContext.Provider
			value={context}
		>
			{props.children}
		</EmailTemplateListContext.Provider>
	);
};

export default CreateEmailTemplateListContextProvider;

export { useEmailTemplateListContext };

type EmailTemplateListContextType = {
	isLoading: boolean;
	templates: EmailTemplateInListApiType[];
	changeEmailTemplateStatus: (emailTemplateId: string, status: EmailTemplateStatus) => void;
	getEditLink: (emailTemplateId: string) => string;
	getCreationLink: () => string;
	modaHtmlIsOpen: boolean;
	handleOpenViewWidgetModal: (shouldGetWidgetHtml: boolean, templateId: string | null) => void;
	handleDeleteCampaign: () => void;
	idTpl: string | undefined;
	setIdTpl: Dispatch<SetStateAction<string | undefined>>;
	deleteConfirmIsOpen: boolean;
	setDeleteConfirmIsOpen: Dispatch<SetStateAction<boolean>>;
	widgetHtml: string | undefined;
}