import React, { useEffect, useState, Fragment  } from 'react';
import { abTestsReportingContext } from './context';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import SelectDropdown from '../../../Components/SelectDropdown';
import Btn from '../../../Components/Btn';
import IconBtn from '../../../Components/IconBtn';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import SectionMessage from '../../../Components/SectionMessage';
import {Skeleton} from '../../../Components/Skeleton/Skeleton';
import ABTestsReportingChart from './ABTestsReportingChart';
import ABTestsReportingVariation from './ABTestsReportingVariation';
import commonStyles from '../ABTestCommon.module.css';
import reportStyles from './ABTestReporting.module.css';
import { Flag } from '../../../Components/Flag/Flag';
import { formatIndicatorDisplayLabel } from '../../ReportingCampaign/IndicatorsUtil';
import indicators from '../../ReportingCampaign/Indicators';

import {
	getDeviceIcon,
	getFormattedNumber,
	getStatusClass,
	computeDurationInDaysFromToday,
	ratioToPercent,
	getStatusDisplayName,
	getLabelColorClass,
	getVariationUrlDebugs,
	getCampaignConfidence,
	getCampaignConfidenceIsBest,
	getCampaignIsKeepTesting,
	getBestVariation
} from '../utils';
import { v4 as uuidv4 } from 'uuid';

export default function ABTestsReporting() {

	const {
		variationsReporting,
		campaignInfo,
		loading,accountId ,
		ka ,ku, $routeParams,
		campaignCreatingServices,systemServices,
		needRefetch,
		loadReporting,
		objectiveSelected,
		setobjectiveSelected,
		setLoading,
		objectiveType
	} = abTestsReportingContext();

	const {
		id,
		name,
		description,
		labels,
		devices,
		previewLink,
		status,
		startDate,
		sessionsCount,
		data,
		improvement
	} = campaignInfo;

	const campaign = campaignInfo;

	if(!campaignInfo){
		return <></>;
	}
	const idTooltip= uuidv4();
	const [list, setlist] = useState([]);

	const [autocompleteValue, setAutocompleteValue] = useState();
	const [debugDropdownIsOpen, setDebugDropdownIsOpen] = useState(false);
	const [modificationCampaign, setmodificationCampaign] = useState(<></>);
	const [t, i18n] = useTranslation('common');
	const [editDropdownIsOpen, setEditDropdownIsOpen] = useState(false);
	const [chartData, setChartData] = useState({ datasets: [], labels: [], type: 'bar', title: 'Conversions' });
	const [indicatorByCampaignId, setindicatorByCampaignId] = useState([]);

	const [checkedVariations, setCheckedVariations] = useState();
	const [objectiveLabels, setObjectiveLabels] = useState({value: '', rate: ''});

	const defaultCheckVariations = () => {
		setCheckedVariations(variationsReporting.map(x => x.name));
	};
	const bestVariation = getBestVariation(campaignInfo?.variations);

	const variationOriginalIsWinner = bestVariation ? bestVariation.name === campaign.variations.baseline.name : false;

	const probabilityToBeatBaseline = !variationOriginalIsWinner ? bestVariation?.recommendation?.comparisonData?.probabilityToPerformBetterThanBaseline : null;
	const campaignConfidence = getCampaignConfidence(campaignInfo?.variations);
	const campaignConfidenceIsBest = getCampaignConfidenceIsBest(campaignConfidence);
	const campaignIsKeepTesting = getCampaignIsKeepTesting(campaignInfo?.variations);

	const colors = [ '#4278F0', '#FFB320', '#796BD0', '#6ED5C1', '#E68A6F' ];

	const threshold = .07;
	const getHasWinner = (campaign) => {
		if (!campaign) return false;
		if (!bestVariation) return false;
		if(campaignIsKeepTesting) return false;
		const probabilityToBeBest = (variationOriginalIsWinner ? bestVariation?.probabilityToBeBest : bestVariation?.recommendation?.comparisonData?.probabilityToBeBest) || 0;
		if (probabilityToBeBest < threshold) return false;
		return true;
	};
	const hasWinner = getHasWinner(campaignInfo);

	const stopExperiment = () =>{
		setLoading(true);
		campaignCreatingServices.putCampaignEndExperiment(accountId, campaignInfo.id, data =>{
			loadReporting();
			systemServices.showSuccess('the experiment has stopped');
		}, data =>{
			systemServices.showError('An error occured');
			setLoading(false);
		});
	};
	const refreshChart = () => {
		function getLabels(arr) {
			const lab = arr?.map((d) => {
				return moment(d.date).format('MMM DD YYYY')
				;
			} );
			return lab || [];
		}
		setChartData(x => {

			return ({
				...x, 
				labels: getLabels(variationsReporting[0]?.data?.objectiveStats?.dateValues),
				datasets: variationsReporting
					.filter(x => checkedVariations.includes(x.name))
					.map((x, i) => ({
						color: colors[i],
						data: x.data.objectiveStats.dateValues.map(s => {
							const ratio = Number(ratioToPercent(s.value) * 1.1 );
							return (ratio);
						}),
						name: x.name
					}))
			});
		});
	};

	const getVariationsMinMax = () => {
		if (!variationsReporting || !variationsReporting.length) return {};

		let minValue;
		let maxValue;

		for (let index = 0; index < variationsReporting.length; index++) {
			const variation = variationsReporting[index];
			const data = variation['data'].objectiveStats;
			if (!data) continue;
			const lower = data.rateLowerBound || 0;
			const higher = data.rateUpperBound || 0;
			if (typeof minValue == 'undefined' || lower < minValue) minValue = lower;
			if (typeof maxValue == 'undefined' || higher > maxValue) maxValue = higher;
		}

		return {
			min: minValue || 0,
			max: maxValue || 0,
		};
	};
	const variationsMinMax = getVariationsMinMax();

	useEffect(() => {
		defaultCheckVariations();
	}, [variationsReporting]);

	useEffect(() => {
		refreshChart();
	}, [checkedVariations]);

	useEffect(() => {
		let tmp = {value: '', rate: ''};
		if (objectiveType == 'conversions') {
			tmp.value = 'Conversions';
			tmp.rate = 'Conversion rate';
		} else if (objectiveType == 'interactions') {
			tmp.value = 'Interactions';
			tmp.rate = 'Interaction rate';
		} else if (objectiveType == 'amount') {
			tmp.value = 'Amount';
			tmp.rate = 'Average amount';
		} else if (objectiveType == 'clicks') {
			tmp.value = 'Clicks';
			tmp.rate = 'Click through rate';
		}
		setObjectiveLabels(tmp);
	}, [objectiveType]);

	function onAutoSelect(val) {
		const newValue = list.find(el => el.value === val);
		handleSearchIndicator(newValue.value);
	}

	function handleSearchIndicator(evt) {
		setobjectiveSelected(evt);
		loadReporting(evt);
	}

	const search = (table, word) => {
		if (word.length > 0) {
			setlist([]);
			let resultName = table.filter(
				line =>
					line.label.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			const res = resultName.map(i => {
				return i;
			});
			setlist(res);
		}
		else {
			setlist(indicatorByCampaignId);
		}

	}
	function handleChageAutcomplete(val) {
		setAutocompleteValue(val);
		search(indicatorByCampaignId, val);
	}
	useEffect(()=>{
		if(campaignInfo.hasOwnProperty('campaignIndicators')){
			const getIndicatorsByCampaign = campaignInfo.campaignIndicators.map(indicator => {
				const label = formatIndicatorDisplayLabel({
					label: indicator,
					key: indicator
				});

				const findIndicatorByindicatorsKey = indicators.find(el =>el.key === label);
				if(findIndicatorByindicatorsKey){
					return {
						value : indicator,
						label : findIndicatorByindicatorsKey.label
					};
				}else{
					return {
						value : indicator,
						label : label
					};
				}
			});

			const sortedIndicators = getIndicatorsByCampaign.sort((a, b) => {
				if (a.label < b.label) {
					return -1;
				}
				if (a.label > b.label) {
					return 1;
				}
				return 0;
			});

			setobjectiveSelected(campaignInfo.objectiveIndicator);
			setindicatorByCampaignId(sortedIndicators);
		}
	},[campaignInfo]);

	useEffect(() => {
		if (indicatorByCampaignId) {
			setlist(indicatorByCampaignId);
		}
		else {
			setlist([]);
		}
	}, [indicatorByCampaignId]);

	const infoHeader = () =>
		<div className={reportStyles.header}>
			<div className={reportStyles.name + ' h1'}>{name}</div>
			<div className={reportStyles.desc}>{description}</div>
			<div className={reportStyles.label_list}>
				{devices?.map(x =>
					<span key={x} className={commonStyles.label + ' ' + commonStyles.label_grey}>
						<i className={commonStyles.label_icon + ' fas fa-fw fa-' + getDeviceIcon(x)}></i>
					</span>
				)}
				{labels?.map(({ name, color }) =>
					<span key={name} className={commonStyles.label + ' ' + getLabelColorClass(color, commonStyles)}>
						<span className={commonStyles.label_text}>{name}</span>
					</span>
				)}
			</div>
		</div>;

	const sumUp = () =>
		<div className={reportStyles.sumup_list}>
			<div className={reportStyles.sumup_item}>
				<div className={reportStyles.sumup_label}>Status</div>
				<div className={reportStyles.sumup_value}>
					<span className={reportStyles.sumup_status_bullet + ' status_bullet status-' + getStatusClass(status)}></span>
					{getStatusDisplayName(status)}
				</div>
			</div>
			<div className={reportStyles.sumup_item}>
				<div className={reportStyles.sumup_label}>Duration</div>
				<div className={reportStyles.sumup_value}>{computeDurationInDaysFromToday(startDate)} days</div>
			</div>
			{!campaignIsKeepTesting &&
				<div className={reportStyles.sumup_item}>
					<div className={reportStyles.sumup_label}>Confidence score</div>
					<div className={reportStyles.sumup_value}>
						{campaignConfidence}
						<IconBtn
							icon='fas fa-info-circle'
							size='xs'
							tooltipHTML={<>The confidence score can be <strong><em>Good</em></strong> or <strong><em>Best</em></strong>.</>}
						/>
					</div>
				</div>
			}
			<div className={reportStyles.sumup_item}>
				<div className={reportStyles.sumup_label}>Sessions</div>
				<div className={reportStyles.sumup_value}>{getFormattedNumber(data?.sessionsCount ?? 0)}</div>
			</div>
			<div className={reportStyles.sumup_item}>
				<div className={reportStyles.sumup_label}>{objectiveLabels.value}</div>
				<div className={reportStyles.sumup_value}>{getFormattedNumber(data?.objectivesCount ?? 0)}</div>
			</div>
			<div className={reportStyles.sumup_item}>
				<div className={reportStyles.sumup_label}>Probability to beat baseline</div>
				<>
					{probabilityToBeatBaseline > 0 ?
						<div className={reportStyles.sumup_value_success}>
							{probabilityToBeatBaseline && ratioToPercent(probabilityToBeatBaseline) + '%'}
						</div>
						:
						<div className={reportStyles.sumup_value_alert}>
							{probabilityToBeatBaseline && ratioToPercent(probabilityToBeatBaseline)+'%'}
						</div>
					}
				</>
			</div>
		</div>;

	const changeCheckedVariation = (checked, variationId) => {
		setCheckedVariations(v => checked ? v.filter(x => x != variationId).concat([variationId]) : v.filter(x => x != variationId));
	};

	const campaignDebugLinks = getVariationUrlDebugs(campaignInfo.variations);
	const statusModificationTorender = () => {
		const isClassicEditor = !campaignInfo.editor || campaignInfo.editor === 'classic';
		if (isClassicEditor) {
			let url = '/Campaigns/Create/' + campaignInfo.id;
			if (ka) {
				url += '?ka=' + $routeParams.ka + '&ku=' + $routeParams.ku;
			}
			setmodificationCampaign(
				<Btn
					icon="fas fa-pen"
					message={t('actions.edit')}
					style="outline"
					color="secondary"
					href={url}
				/>
			);
		}
		else {
			const url = '/Campaigns/Editor/' + campaignInfo.id;
			const query = [];
			query.push('from=ABTests/Dashboard/Completed');
			if (ka) {
				query.push('ka=' + $routeParams.ka);
				query.push('ku=' + $routeParams.ku);
			}

			const getEditCampaignLabel = () => {
				const def = t('CampaignItem.editDesign');
				if (!campaign.editor) return def;
				if (campaign.editor == 'patch/code') return t('CampaignItem.editCodePatch');
				if (campaign.editor == 'patch/graph') return t('CampaignItem.editGraphic');
				return def;
			};

			const getEditCampaignIcon = () => {
				const def = 'fas fa-palette';
				if (!campaignInfo.editor) return def;
				if (campaignInfo.editor == 'patch/code') return 'fas fa-code';
				if (campaignInfo.editor == 'patch/graph') return 'fas fa-fill-drip';
				return def;
			};

			const toQueryString = (moreQuery) => '?' + [...query, ...moreQuery].join('&');
			setmodificationCampaign(
				<>
					<Dropdown
						isOpen={editDropdownIsOpen}
						setIsOpen={(e) => setEditDropdownIsOpen(true)}
						onHide={(e) => setEditDropdownIsOpen(false)}
						button={
							<Btn
								icon="fas fa-pen"
								message={t('actions.edit')}
								style="outline"
								color="secondary"
							/>
						}
					>
						<Listbox>
							<ListboxItem
								href={url + toQueryString(['edit=format'])}
								icon={getEditCampaignIcon()}
								message={getEditCampaignLabel()}
							/>
							<li className="hr"></li>
							<ListboxItem
								href={url + toQueryString(['edit=triggers'])}
								icon="fas fa-bolt"
								message={t('CampaignItem.EditDisplayRules')}
							/>
							<li className="hr"></li>
							<ListboxItem
								href={url + toQueryString(['edit=info'])}
								icon="fas fa-info-circle"
								message={t('CampaignItem.EditCampaign')}
							/>
						</Listbox>
					</Dropdown>
				</>
			);
		}
	};
	useEffect(()=>{
		statusModificationTorender();
	},[campaignInfo,editDropdownIsOpen]);

	return (
		<div className='page_full page_full_grey vscroll'>
			<div className='page_full_inner'>
				<section className='section no_bottom_pad'>
					<div>
						<ul className={reportStyles.breadcrumb}>
							<li className={reportStyles.breadcrumb_item}>
								<a className={reportStyles.breadcrumb_link}
									href={'/ABTests/Dashboard/Pending' + (ka ? '?'+ka : '') }>
									A/B Test
								</a>
							</li>
							<li className={reportStyles.breadcrumb_item}>
								Reporting
							</li>
						</ul>
						{infoHeader()}
					</div>
					<div className='flex flex_align_end mt_20'>
						<div className='flex_item_full'>
							{objectiveSelected && <SelectDropdown
								label={'Main KPI'}
								onChange={(v) => onAutoSelect(v)}
								onAutocomplete={(v) => handleChageAutcomplete(v)}
								labelPosition='inner'
								color='white'
								value={objectiveSelected}
								optionsList={list}
								autocomplete={true}
								autocompleteValue={autocompleteValue}
								autocompletePlaceholder={'Search for a KPI'}
							/>}
						</div>
						<div className='flex_item_fix ml_30'>
							<div className="btn_group">
								{!campaignDebugLinks ||
									(campaignDebugLinks.length <= 1 && (
										<Btn
											message={t('CampaignItem.Preview')}
											href={campaignDebugLinks[0]}
											target="_blank"
											style="outline"
											color="secondary"
											icon='fas fa-external-link-square-alt'
											disabled={!campaignDebugLinks || campaignDebugLinks.length == 0}
										/>
									))
								}
								{campaignDebugLinks &&
									campaignDebugLinks.length > 1 && (
									<Dropdown
										isOpen={debugDropdownIsOpen}
										setIsOpen={(e) => setDebugDropdownIsOpen(true)}
										onHide={(e) => setDebugDropdownIsOpen(false)}
										button={
											<Btn
												icon='fas fa-external-link-square-alt'
												style="outline"
												color="secondary"
												message={t('CampaignItem.Preview')}
											/>
										}
									>
										<ul className="listbox">
											{campaignDebugLinks
												.map((debug, i) => (
													<Fragment key={i}>
														<li>
															<a
																href={debug}
																target="_blank"
																className="listbox_item" rel="noreferrer"
															>
																	Variation{' '}
																{
																	[
																		'A',
																		'B',
																		'C',
																		'D',
																		'E',
																		'F',
																		'G',
																		'H',
																		'I',
																		'J',
																	][i]
																}
															</a>
														</li>
														<li className="hr"></li>
													</Fragment>
												))}
										</ul>
									</Dropdown>
								)}
								{modificationCampaign}
								{/* <CampaignActionsDropdown
									button={
										<Btn
											icon='fas fa-ellipsis-v'
											tooltip='More actions'
										/>
									}
									campaign={campaign}
								/> */}
							</div>
						</div>
					</div>
				</section>


							
				{loading &&
					<>
						<section className='section no_bottom_pad'>
							<Skeleton appearance="title" />
						</section>
						<section className='section no_bottom_pad'>
							<div className='page_block'>
								<Skeleton appearance="kpi" nb="5" />
							</div>
						</section>
						<section className="section no_bottom_pad">
							<div className='page_block'>
								<SpinnerWheel display='block' verticalSize='s' />
							</div>
						</section>
						<section className="section">
							<div className='page_block'>
								<SpinnerWheel display='block' />
							</div>
						</section>
					</>
				}

				{!loading &&
					<>
						<section className='section no_bottom_pad'>
							<div className='page_block'>
								{!campaignIsKeepTesting && hasWinner && campaignConfidenceIsBest &&
									<div className='mb_30'>
										<SectionMessage
											type='success'
											title='We have a clear winner!'
											text={'It\'s now safe to stop your experiment. The confidence score is very good.'}
											icon="fas fa-trophy"
											verticalSize="s"
											flag={
												<Flag
													icon="ai"
													text="AI-powered"
													color="success_reverse"
													tooltip="This feature is powered by BEYABLE AI"
												/>
											}
										/>
									</div>
								}
								{!campaignIsKeepTesting && hasWinner && !campaignConfidenceIsBest &&
									<div className='mb_30'>
										<SectionMessage
											type='success'
											title='We have a winner!'
											text={'It\'s now safe to stop your experiment. The confidence score is good, you can wait a few days to get a better score.'}
											icon="fas fa-trophy"
											verticalSize="s"
											flag={
												<Flag
													icon="ai"
													text="AI-powered"
													color="success_reverse"
													tooltip="This feature is powered by BEYABLE AI"
												/>
											}
										/>
									</div>
								}
								{campaignIsKeepTesting &&
									<div className='mb_30'>
										<SectionMessage
											type='information'
											color='purple'
											icon='fas fa-clock'
											text='We need more time and data to clearly identify the winning variation.'
											verticalSize="s"
											flag={
												<Flag
													icon="ai"
													text="AI-powered"
													color="purple_reverse"
													tooltip="This feature is powered by BEYABLE AI"
												/>
											}
										/>
									</div>
								}
								<div className='flex'>
									<div className='flex_item_full'>{sumUp()}</div>
									<div className='flex_item_fix ml_20'>
										{!campaignInfo.isStopped &&	
											<Btn
												onClick={()=>stopExperiment()}
												message="Stop experiment"
												icon="fas fa-stop-circle"
											/>
										}
									</div>
								</div>
							</div>
						</section>

						{variationsReporting.length > 0 &&
							<>
								<section className="section no_bottom_pad">
									<div className='page_block'>
										<div className='h3 mb_10'>Variations</div>
										<div className={'table_grid ' + reportStyles.table}>
											<div className={'table_row table_head_row ' + reportStyles.table_head_row}>
												<div className={'table_col ' + reportStyles.table_col}>Variation</div>
												<div className={'table_col ' + reportStyles.table_col}>{objectiveLabels.value}</div>
												<div className={'table_col ' + reportStyles.table_col}>improvement</div>
												<div className={'table_col ' + reportStyles.table_col}>Probabiliy to be best</div>
												<div className={'table_col ' + reportStyles.table_col}>Probabiliy to beat baseline</div>
												<div className={'table_col ' + reportStyles.table_col}>{objectiveLabels.rate}</div>
											</div>
											{variationsReporting.map((variation) =>
												<ABTestsReportingVariation
													key={variation.name}
													variation={variation}
													campaign={campaign}
													bestVariation={bestVariation}
													checkedVariations={checkedVariations}
													changeCheckedVariation={changeCheckedVariation}
													variationsMinMax={variationsMinMax}
													campaignIsKeepTesting={campaignIsKeepTesting}
												/>
											)}
										</div>
									</div>
								</section>

								<section className="section">
									<div className='page_block'>
										<div className='h3 mb_10'>{objectiveLabels.rate}</div>
										<ABTestsReportingChart data={chartData} />
									</div>
								</section>
							</>
						}
					</>
				}
			</div>
		</div>
	);
}