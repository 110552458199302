import React, { useState, useEffect } from "react";
import MarkerItem from './MarkerItem';
import './OperationsTimeline.css';
export default function OperationsTimeline({
    operations,
    dates,
}) {
    const [operationsToMapInTimeline, setOperationsToMapInTimeline] = useState();
    const operationInTheRangeDates = () => {
        if (operations) {
            const beginingOfTimeline = moment(dates[0]).startOf('day');
            const endOfTimeline = moment(dates[dates.length - 1]).startOf('day');
            let operationsToShowOnTimeline = operations.filter(op => moment(op.operation.startDate).startOf('day') <= endOfTimeline && moment(op.operation.endDateForDisplay).startOf('day') > beginingOfTimeline)
            operationsToShowOnTimeline.map((elem,i) => {
               if(!elem.endDate && elem.endDate === null){                
                let filRougeCp = elem
                operationsToShowOnTimeline.splice(i,1)
                operationsToShowOnTimeline.push(filRougeCp)
               }
           })
           
            setOperationsToMapInTimeline(operationsToShowOnTimeline)
        }
    }
    useEffect(() => {
        operationInTheRangeDates()
    }, [operations, dates])

 

    return (
        <div className="splanning_operations_list">
            {operationsToMapInTimeline && operationsToMapInTimeline.map((campaign) =>
                <MarkerItem
                    key={campaign.id}
                    campaign={campaign}
                    dates={dates}
                />
            )}
        </div>
    )
}
