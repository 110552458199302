import React, { useState, useEffect, useContext, Fragment } from 'react';
import { reportingCampaignContext } from '../Context/ContextReportingCampaign';
import './FormResultsCampaignComponent.css';
import { getIndicatorByKey, impressionIndicator } from '../Indicators';
import TinyLoader from '../../../Components/TinyLoader';
import { getIndicatorComputeByKey, indicatorsCompute } from './indicatorsCompute.js';
import ReactTooltip from 'react-tooltip';
import { Trans, useTranslation } from 'react-i18next';
export default function FormResultsCampaignComponent(props) {
	const [t, i18n] = useTranslation('reporting');
	const {
		setSecondaryIndicatorToCompareExport,
		setIndicatorsComput: setIndicatorsCompute
	} = useContext(reportingCampaignContext);
	const [IndicatorsCompareArray, setIndicatorsCompareArray] = useState([]);
	const [IndicatorsComputeArray, setIndicatorsComputeArray] = useState([]);
	const [secondaryIndicatorsIsFetch, setSecondaryIndicatorsIsFetch] = useState(false);
	const [secondaryIndicatorsComputeIsFetch, setSecondaryIndicatorsComputeIsFetch] = useState(false);

	const getDataIndicatorsCompare = () => {
		if (props.momentEndDate && props.indicatorsCompare && props.indicatorsCompare.length) {
			const fetchDataIndicators = () => {
				const arrayOfIndicators = props.indicatorsCompare.map(el => el.key);
				props.getAllSecondaryIndicators(arrayOfIndicators.join(','), props.device && props.device,
					Data => {
						let dataToRegister;
						if (props.hasVariations) {
							dataToRegister = Data.map(element => {
								if (element.expandedSeries.find(serie => serie.key.variation === props.variationSelected)) {
									return ({
										...element.expandedSeries.find(serie => serie.key.variation === props.variationSelected).series[0],
										variationKey: element.expandedSeries.find(serie => serie.key.variation === props.variationSelected).key,
										key: element.indicator,
										label: getIndicatorByKey(element.indicator).label,
										device: props.device
									});
								} else {
									return ({
										key: element.indicator,
										label: getIndicatorByKey(element.indicator).label,
										device: props.device
									});
								}
							});

						} else {
							dataToRegister = Data.map(element => ({
								...element.series[0],
								key: element.indicator,
								label: getIndicatorByKey(element.indicator).label,
								device: props.device
							}));
						}
						setSecondaryIndicatorsIsFetch(true);
						setIndicatorsCompareArray(dataToRegister);
						setSecondaryIndicatorToCompareExport(dataToRegister);
					}, errorMessage => {
						setSecondaryIndicatorsIsFetch(true);
						console.error('error', error);
						props.systemServices.showError();
					});
			};
			fetchDataIndicators();
		}
	};
	useEffect(() => {
		setSecondaryIndicatorsIsFetch(false);
		setSecondaryIndicatorsComputeIsFetch(false);
		getDataIndicatorsCompare();
	}, [props.indicatorsCompare, props.momentEndDate, props.device, props.variationSelected, props.activeLink]);

	const getDataIndicatorsCompute = () => {
		if (props.momentEndDate && props.indicatorsCompute && props.indicatorsCompute.length && IndicatorsCompareArray.length) {
			let arrayOfIndicatorsCompute = props.indicatorsCompute.map((el, idx) => {
				const label = getIndicatorComputeByKey(el.key);
				return ({
					key: el.key.split('.')[0],
					name: el.key.split('.')[1],
					id: idx,
					label: label ? label.label : el.key,
					infos: indicatorsCompute.find(lab => lab.key === el.key) ? indicatorsCompute.find(lab => lab.key === el.key).infos : '',
					device: props.device
				});
			}
			);
			const arrayOfIndicatorsComputeToFetch = arrayOfIndicatorsCompute.map(el => el.key);
			props.getAllSecondaryIndicators(arrayOfIndicatorsComputeToFetch.join(','), props.device && props.device,
				Data => {
					let dataToRegister;
					if (props.hasVariations) {
						dataToRegister = Data.map(element => {
							if (element.expandedSeries.find(serie => serie.key.variation === props.variationSelected)) {
								return ({
									...element.expandedSeries.find(serie => serie.key.variation === props.variationSelected).series[0],
									variationKey: element.expandedSeries.find(serie => serie.key.variation === props.variationSelected).key,
									key: element.indicator,
								});
							} else {
								return ({
									key: element.indicator,
								});
							}
						});
					} else {
						dataToRegister = Data.map(element => ({
							...element.series[0],
							key: element.indicator,
						}));
					}
					const finalDataCompute = arrayOfIndicatorsCompute.map(computElement => {
						const dataOfCurrentElement = dataToRegister.find(data => data.key === computElement.key)[computElement.name];
						return ({ ...computElement, value: dataOfCurrentElement });
					});
					setIndicatorsComputeArray(finalDataCompute);
					setIndicatorsCompute(finalDataCompute);
					setSecondaryIndicatorsComputeIsFetch(true);
				}, errorMessage => {
					setSecondaryIndicatorsComputeIsFetch(true);
					console.error('error', error);
					props.systemServices.showError();
				});
		}
	};

	useEffect(() => {
		getDataIndicatorsCompute();
	}, [IndicatorsCompareArray]);

	const isANumber = (numb) => {
		return (typeof numb === 'number');
	};
	const displayNumber = (numb) => {
		let numberToFormat = numb;
		if (typeof numberToFormat !== 'number') {
			return 'No data';
		}

		const numberAfterComa = numberToFormat.toString().split('.')[1];
		if (!numberAfterComa) {
			return numberToFormat;
		}

		const firstNumberAfterComa = numberAfterComa.split('')[0];
		if (firstNumberAfterComa > 0) {
			return numberToFormat.toFixed(2);
		}

		return parseInt(numberToFormat.toFixed(2));
	};

	const getValueColor = (value) => {
		if (typeof value !== 'number') {
			return 'grey_3';
		}
		if (value > 0) {
			return 'uplift uplift_positive';
		}
		if (value < 0) {
			return 'uplift uplift_negative';
		}
		return 'uplift val';
	};

	return (
		<>
			<table className="reporting_array">
				{props.hasControlGroup &&
					<thead>
						<tr>
							<th className="reporting_col_label">
								{t('resultsCampaign.kpi')}
							</th>
							<th className="reporting_col_value">{t('resultsCampaign.test')}</th>
							<th className="reporting_col_value">{t('resultsCampaign.control')}</th>
						</tr>
					</thead>
				}
				<tbody>
					{!secondaryIndicatorsIsFetch && !secondaryIndicatorsComputeIsFetch &&
						<tr>
							<td className="reporting_array_loading" colSpan="3">
								<TinyLoader />
							</td>
						</tr>
					}
					{IndicatorsCompareArray && secondaryIndicatorsIsFetch && secondaryIndicatorsComputeIsFetch &&
						<>

							{IndicatorsCompareArray && IndicatorsCompareArray[0] &&
								IndicatorsCompareArray.map(
									(item, index) => item && (
										<tr key={index}>
											<td className="reporting_col_label">
												{item.label ? item.label : item.key}
											</td>
											<td className="reporting_col_value" colSpan={props.hasControlGroup ? '' : 3}>
												{'valueNotDisplayedAbsolute' in item ?
													<Fragment>
														{isANumber(item.valueDisplayedAbsolute) ?
															<>
																<span className="value">
																	{displayNumber(item.valueDisplayedAbsolute)}
																</span>
																<span className="rate">
																	{
																		impressionIndicator === item.key || (!item.valueDisplayedRate && item.valueDisplayedRate !== 0) ? '' :
																			` (${displayNumber(item.valueDisplayedRate)}%)`
																	}
																</span>
															</>
															:
															<span className="grey_3">{t('resultsCampaign.NoData')}</span>
														}
													</Fragment> :
													<span className="grey_3">{t('resultsCampaign.NoData')}</span>
												}
											</td>
											{props.hasControlGroup &&
												<td className="reporting_col_value">
													{'valueNotDisplayedAbsolute' in item ?
														<Fragment>
															{isANumber(item.valueNotDisplayedAbsolute) ?
																<>
																	<span className="value">
																		{displayNumber(item.valueNotDisplayedAbsolute)}
																	</span>
																	<span className="rate">
																		{
																			impressionIndicator === item.key || (!item.valueNotDisplayedRate && item.valueNotDisplayedRate !== 0) ? '' :
																				` (${displayNumber(item.valueNotDisplayedRate)}%)`
																		}
																	</span>
																</>
																:
																<span className="grey_3">{t('resultsCampaign.NoData')}</span>
															}
														</Fragment> :
														<span className="grey_3">{t('resultsCampaign.NoData')}</span>
													}
												</td>}
										</tr>
									)
								)
							}
						</>
					}
				</tbody>
			</table>
			{IndicatorsComputeArray && IndicatorsComputeArray[0] && props.hasControlGroup &&
				<table className="reporting_array">
					<thead>
						<tr>
							<th className="reporting_col_label">{t('resultsCampaign.UPLIFTKPI')}</th>
							<th className="reporting_col_value">{t('resultsCampaign.UPLIFT')}</th>
						</tr>
					</thead>
					<tbody>
						{IndicatorsComputeArray.map(
							(item, index) => (
								<tr key={index}>
									<td className="reporting_col_label">
										{item.label ? item.label : item.key}
										{item.infos !== '' && <>
											<ReactTooltip id={item.key} delayHide={100} effect='float' />
											<i data-for={item.key} className="label_tooltip_compute fas fa-info-circle" data-tip={item.infos}></i> </>}
									</td>
									<td className="reporting_col_value">
										<span className={getValueColor(item.value)}>
											{`${item.value > 0 ? '+' : ''}${displayNumber(item.value)}`}
										</span>
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
			}
		</>
	);
}

