import { EditorType, TemplateProperty, CollectionEditor, CountDownEditor } from '../Types/BespokeTypes';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash';
import { getFakeProducts } from './fakeData';
import { getNextEndDate } from './DateUtils';
// import { _hop } from '../../../Util';


function isJsonString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

export function jsonTryParse(str) {
	try {
		return JSON.parse(str);
	} catch (error) {
		return false;
	}
}


export class ProductReferencesValue extends String {
	static fromStringInput(stringInput: ProductReferencesValue) {
		return !stringInput ? Array.from([]) : Array.from(stringInput.split(/[;,\n]/).filter(x => x));
	}
}

export class ProductRecommendationsValue extends String {
	static fromStringInput(stringInput: ProductRecommendationsValue | null) {
		return !stringInput ? Array.from([]) : Array.from(isJsonString(stringInput) ? JSON.parse(stringInput as string) : []);
	}
	static fromValue(value) {
		return new ProductRecommendationsValue(JSON.stringify(value));
	}
}


const generateProducts = (ids) => {
	return getFakeProducts(ids.length)
		.map(x => ({
			...x,
			Id: x,
		}));
};

export function propertiesProcessing() {

	function setupTemplatePropertyValue(param: TemplateProperty) {
		const newParam = { ...param };
		if (param.Editor.Type === EditorType.CountDown) {
			if (!param.Value) {
				const timeZone = (param.Editor as CountDownEditor).Timezone || moment.tz.guess() || 'Europe/Paris';
				const date = moment().add(7, 'days').tz(timeZone, true);
				newParam.Value = new String(date.format('ddd MMM DD YYYY HH:mm [GMT]ZZ'));
			} else {
				newParam.Value = new String(newParam.Value);
			}
			newParam.Value.isCountDown = true;
		}
		if (param.Editor.Type === EditorType.ProductReferences) {
			newParam.Value = new ProductReferencesValue(param.Value || '');
		}
		if (param.Editor.Type === EditorType.ProductRecommendations) {
			newParam.Value = new ProductRecommendationsValue(param.Value || '');
		}
		if (param.Editor.Type === EditorType.DatePicker) {
			if (param.Value) {
				newParam.Value = new String(newParam.Value);
			}
		}
		if (param.Editor.Type === EditorType.Font) {
			newParam.Value = new String(newParam.Value);
			newParam.Value.isFont = true;
		}
		return newParam;
	}

	function templatePropertyToStyleProperty(param: TemplateProperty): TemplateProperty {

		if (param.Editor.Type === EditorType.Collection) {
			const item = (param.Content ?? []).reduce((prev, content) => {
				const newParam = setupTemplatePropertyValue(content);
				return { ...prev, [newParam.Name]: newParam.Value };
			}, { Key: uuidv4() });

			return {
				Key: uuidv4(),
				Name: param.Name,
				IsCollection: true,
				Value: [item],
				Editor: { Type: EditorType.Collection }
			};
		} else if (param.Editor.Type === EditorType.Data) {
			const json = jsonTryParse(param.Value);
			return {
				Key: uuidv4(),
				Name: param.Name,
				IsCollection: true,
				Value: json || [],
				Editor: { Type: EditorType.Data } // ? sure
			};
		} else if (param.Editor.Type === EditorType.Group) {
			const newValue = {};
			(param.Content ?? []).forEach(item => {
				const newParam = setupTemplatePropertyValue(item);
				newValue[newParam.Name] = newParam.Value;
			});
			const isActiveProperty = param.IsActiveProperty;
			const multistepProperty = param.MultiSteps;
			const valueProperty = isActiveProperty ? { ...newValue, [isActiveProperty.Name]: isActiveProperty.Value } : newValue;

			return {
				Key: uuidv4(),
				Name: param.Name,
				IsGroup: true,
				Value: multistepProperty ? {
					...valueProperty,
					[multistepProperty.Name]: multistepProperty.Value
				} : valueProperty,
				Editor: { Type: EditorType.Group }
			};
		} else {
			const newParam = setupTemplatePropertyValue(param);
			return {
				Key: uuidv4(),
				Name: newParam.Name,
				Value: newParam.Value,
				Editor: param.Editor
			};
		}
	}

	const areSameStyles = (s1Properties: TemplateProperty[], s2Properties: TemplateProperty[]): boolean =>
		isEqual(s1Properties.map(removePropertyKeys), s2Properties.map(removePropertyKeys));


	const addPropertyKeys = (property: TemplateProperty): TemplateProperty => {
		if (property.IsCollection) {
			return { ...property, Key: uuidv4(), Value: (property.Value || []).map(v => ({ ...v, Key: uuidv4() })) };
		}
		return { ...property, Key: uuidv4() };
	};

	const removePropertyKeys = (property: TemplateProperty): TemplateProperty => {
		if (property.IsCollection) {
			return { ...property, Key: null, Value: (property.Value || []).map(v => ({ ...v, Key: null })) };
		}
		return { ...property, Key: null };
	};

	const setActiveItemIntoCollection = (property: TemplateProperty, items, activeItemIndex: number) => {
		const activeItemPropertyName = (property.Editor && (property.Editor as CollectionEditor).ActiveItemPropertyName) || 'IS_ACTIVE_ITEM';
		for (let i = 0; i < items.length; i++) {
			const value = items[i];
			value[activeItemPropertyName] = i === activeItemIndex;
		}
	};

	const formatPropertiesToStyleProperties = (formatProperties: TemplateProperty[]): TemplateProperty[] => {
		const buildStyleProperty = (property: TemplateProperty) => {
			if (property.Editor.Type === EditorType.Group) {
				return templatePropertyToStyleProperty(property);

			} else if (property.Editor.Type === EditorType.Collection) {
				const p = templatePropertyToStyleProperty(property);
				const singleValue = p.Value[0];
				const collectionItems: any[] = [];
				for (let i = 0; i < ((property.Editor as CollectionEditor)?.CollectionDefaultSize ?? 0); i++) {
					collectionItems.push(singleValue);
				}
				setActiveItemIntoCollection(property, collectionItems, 0);
				return { ...p, Value: collectionItems };
			} else {
				return templatePropertyToStyleProperty(property);
			}
		};
		return formatProperties.map(buildStyleProperty).map(addPropertyKeys);
	};

	const getLiquidModel = (modelProperties: TemplateProperty[]) => {
		const calculateCountdownProperties = (property, value) => {

			let date;
			const matchHourFormat = value.match(/^(\d?\d:\d\d)-(.*)/g);
			if (matchHourFormat) {
				date = getNextEndDate(value);
			} else {
				date = Date.parse(value);
			}

			const totalSeconds = Math.floor((date - Date.now()) / 1000);
			const getDhms = () => {
				const totalMinutes = Math.floor(totalSeconds / 60);
				const totalHours = Math.floor(totalMinutes / 60);
				const totalDays = Math.floor(totalHours / 24);

				const hours = totalHours % 24;
				const minutes = totalMinutes % 60;
				const seconds = totalSeconds % 60;

				return [totalDays, hours, minutes, seconds, false];
			};
			const format2Digits = (d) => d.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
			const dhms = totalSeconds < 0 ? ['0', '00', '00', '00', true] : getDhms();
			const countDownProperties = {};
			countDownProperties[property + '_DAYS'] = dhms[0];
			countDownProperties[property + '_HOURS'] = format2Digits(dhms[1]);
			countDownProperties[property + '_MINUTES'] = format2Digits(dhms[2]);
			countDownProperties[property + '_SECONDS'] = format2Digits(dhms[3]);
			countDownProperties[property + '_HASFINISHED'] = dhms[4];
			return countDownProperties;
		};

		const getProductShelvingProperty = (shelvingRules) => {
			const nbOfProducts = shelvingRules.map(x => x.NumberOfProducts).reduce((a, b) => a + b, 0);
			const refs = new Array(nbOfProducts).fill(10).map((_, i) => 'REF' + i);
			const property = {};
			property['RecommandationProducts'] = generateProducts(refs);
			return property;
		};

		const getProductReferencesProperty = (productReferences) => {
			const property = {};
			property['Products'] = generateProducts(productReferences.filter(x => x));
			return property;
		};

		const addSpecialModelProperties = (key, value, model) => {
			if (value && value.isCountDown) {
				const countdownProperties = calculateCountdownProperties(key, value);
				for (const [key2, value2] of Object.entries(countdownProperties)) {
					model[key2] = value2;
				}
			}
			if (value && value instanceof ProductReferencesValue) {
				const contentProductsProperty = getProductReferencesProperty(ProductReferencesValue.fromStringInput(value));
				for (const [key2, value2] of Object.entries(contentProductsProperty)) {
					model[key2] = value2;
				}
			}
			if (value && value instanceof ProductRecommendationsValue) {
				const contentProductsProperty = getProductShelvingProperty(ProductRecommendationsValue.fromStringInput(value));
				for (const [key2, value2] of Object.entries(contentProductsProperty)) {
					model[key2] = value2;
				}
			}
		};
		const values = {};
		for (let i = 0; i < modelProperties.length; i++) {
			const modelProperty = modelProperties[i];
			const getModelEntries = () => {
				const modelEntries = {};
				if (modelProperty.IsGroup === true) {
					const groupProperty = {};
					for (const [key, value] of Object.entries(modelProperty.Value)) {
						groupProperty[key] = value;
						addSpecialModelProperties(key, value, groupProperty);
					}
					modelEntries[modelProperty.Name] = groupProperty;
					return modelEntries;
				}
				else if (modelProperty.IsCollection === true) {
					const collectionProperty: any[] = [];
					for (let j = 0; j < modelProperty.Value.length; j++) {
						const collectionItem = {};
						collectionProperty.push(collectionItem);
						for (const [key, value] of Object.entries(modelProperty.Value[j])) {
							const name = key;
							collectionItem[name] = value;
							addSpecialModelProperties(key, value, collectionItem);
						}
					}
					modelEntries[modelProperty.Name] = collectionProperty;
					return modelEntries;
				}
				else {
					modelEntries[modelProperty.Name] = modelProperty.Value;
					addSpecialModelProperties(modelProperty.Name, modelProperty.Value, modelEntries);
					return modelEntries;
				}
			};

			for (const [key, value] of Object.entries(getModelEntries())) {
				values[key] = value;
			}
		}
		return values;
	};
	
	const hasCountDowns = (modelProperties: TemplateProperty[]): boolean => {
		for (let i = 0; i < modelProperties.length; i++) {
			const modelProperty = modelProperties[i];

			if (modelProperty.IsGroup === true) {
				
				for (const [o, value] of Object.entries(modelProperty.Value)) {
					o;
					if (value && (value as any).isCountDown) {
						return true;
					}
				}
			}
			else if (modelProperty.IsCollection === true) {
				for (let j = 0; j < modelProperty.Value.length; j++) {

					for (const [o, value] of Object.entries(modelProperty.Value[j])) {
						o;
						if (value && (value as any).isCountDown) {
							return true;
						}
					}
				}
			}
			else {
				if (modelProperty.Value && modelProperty.Value.isCountDown) return true;
			}
		}
		return false;
	};

	return {
		setActiveItemIntoCollection,
		formatPropertiesToStyleProperties,
		templatePropertyToStyleProperty,
		getLiquidModel,
		addPropertyKeys,
		areSameStyles,
		hasCountDowns
	};
}


