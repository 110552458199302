
import React from 'react';
import './Message.css';

const Message = ({ msg, type }) => {
	return (
		<div className={`msg msg-${type}`}>
			{type === 'success' ?
				<>
					<i className="fas fa-check"></i>
				</>
				:
				<>
					<i className="fas fa-times"></i>
				</>
			}
			{msg}
			{/* <button
                type='button'
                className='close'
                data-dismiss='alert'
                aria-label='Close'
            >
                <span aria-hidden='true'>&times;</span>
            </button> */}
		</div>
	);
};

export default Message;