import React, { useState } from 'react';
import Fieldset from '../../../../Components/Fieldset';
import InputCustom from '../../../../Components/InputCustom';
import NumericUpDown from '../../../../Components/NumericUpDown';
import SelectCustom from '../../../../Components/SelectCustom';
import Btn from '../../../../Components/Btn';
import {useTranslation} from 'react-i18next';
export default function DefineManualyInPage({
	targetSelector,
	targetIndex,
	targetPosition,
	setTargetSelector,
	setTargetIndex,
	setTargetPosition,
	handleCancel,
	handleConfirm
}) {
	const [t, i18n] = useTranslation('common');
	return (
		<>
			<div className="modal_body">
				<InputCustom
					value={targetSelector}
					name="targetSelector"
					label={t('insert.elementSelector')}
					description={t('insert.findEl')}
					type="text"
					placeholder="Ex: .my-element"
					fullWidth={true}
					onChange={(e) => setTargetSelector(e.target.value)}
					blockClassName="l"
				/>

				<NumericUpDown
					value={targetIndex}
					min={1}
					name="targetIndex"
					label={t('insert.elIndx')}
					description={t('insert.ifSelector')}
					type="number"
					canEdit={true}
					onChange={(e) => setTargetIndex(e.target.value)}
					blockClassName="l"
				/>

				<Fieldset label={t('insert.campaignPos')} blockClassName="l">


					<div className="flex flex_align_start inpage_mockup">
						<div className="flex_item_fix inpage_mockup_col__before">
							<div className="inpage_mockup_col_inner">
								<Btn
									message={t('insert.Before')}
									style="outline"
									color="secondary"
									onClick={() => setTargetPosition('AdjacentBeforeBegin')}
									isActive={targetPosition == 'AdjacentBeforeBegin'}
								/>
							</div>
						</div>
						<div className="flex_item_full inpage_mockup_col__in">
							<div className="inpage_mockup_col_inner inpage_mockup_col_inner_highlighted">
								<div className="btn_switch w_full">
									<Btn
										message={t('insert.First')}
										style="outline"
										color="secondary"
										onClick={() => setTargetPosition('AdjacentAfterBegin')}
										isActive={targetPosition == 'AdjacentAfterBegin'}
									/>
									<Btn
										message={t('insert.Replace')}
										style="outline"
										color="secondary"
										onClick={() => setTargetPosition('Replace')}
										isActive={targetPosition == 'Replace'}
									/>
									<Btn
										message={t('insert.Last')}
										style="outline"
										color="secondary"
										onClick={() => setTargetPosition('AdjacentBeforeEnd')}
										isActive={targetPosition == 'AdjacentBeforeEnd'}
									/>
								</div>
							</div>
							<div className="inpage_mockup_el_name ellips">{targetSelector || '.my-element'}</div>
						</div>
						<div className="flex_item_fix inpage_mockup_col__after">
							<div className="inpage_mockup_col_inner">
								<Btn
									message={t('insert.After')}
									style="outline"
									color="secondary"
									onClick={() => setTargetPosition('AdjacentAfterEnd')}
									isActive={targetPosition == 'AdjacentAfterEnd'}
								/>
							</div>
						</div>
					</div>
				</Fieldset>
			</div>
			<div className="modal_footer has_border mt_10 flex">
				<div className="flex_item_full"></div>
				<div className="flex_item_fix">
					<Btn
						style="ghost"
						color="secondary"
						message={t('actions.cancel')}
						onClick={() => handleCancel()}
					/>
					<Btn
						message={t('actions.confirm')}
						onClick={() => handleConfirm()}
						color="primary"
						disabled={!targetSelector}
					/>
				</div>
			</div>
		</>
	);
}
