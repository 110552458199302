import React, {} from 'react';
import ABTestsReportingPlan from './ABTestsReportingPlan';
import Checkbox from '../../../Components/Checkbox';
import reportStyles from './ABTestReporting.module.css';

import {
	getFormattedNumber,
	ratioToPercent,
} from '../utils';

export default function ABTestsReportingVariation({
	campaign,
	variation,
	bestVariation,
	checkedVariations,
	changeCheckedVariation,
	variationsMinMax,
	campaignIsKeepTesting
}) {

	const isOriginal = variation.name === campaign.variations.baseline.name;

	const getVariationClass = (variation) => {
		if (campaignIsKeepTesting) return '';

		const best = reportStyles.variation_best;
		const better = reportStyles.variation_better;
		const worse = reportStyles.variation_worse;

		const isBest = bestVariation && bestVariation.name == variation.name;
		const isOriginal = variation.name === campaign.variations.baseline.name;
		const comparison = variation?.recommendation?.baselineComparison;

		if (isBest) {
			return best;
		}
		if (isOriginal) return '';

		if (comparison == 'BetterThanBaseline') {
			return better;
		}
		if (comparison == 'WorseThanBaseline') {
			return worse;
		}
	};

	return (
		<div className={'table_row table_body_row ' + reportStyles.table_body_row + ' ' + getVariationClass(variation)}>
			<div className={'table_col ' + reportStyles.table_col}>
				<div className={'flex_item_fix ' + reportStyles.table_row_checkbox}>
					<Checkbox
						forcedChecked={checkedVariations.includes(variation.name)}
						checked={checkedVariations.includes(variation.name)}
						onChange={(e) => changeCheckedVariation(e.target.checked, variation.name)}
					/>
				</div>
				<div className='flex_item_full'>
					<div className={reportStyles.table_row_name}>{variation.name}</div>
					<div className={reportStyles.table_row_desc}>{getFormattedNumber(variation.data.sessionsCount)} sessions</div>
				</div>
			</div>
			<div className={'table_col ' + reportStyles.table_col}>
				{getFormattedNumber(variation.data.objectivesCount)}
			</div>
			<div className={'table_col ' + reportStyles.table_col}>
				{!isOriginal ?
					<>{ratioToPercent(variation?.recommendation?.comparisonData?.objectiveVariation)}%</>
					:
					<>-</>
				}
			</div>
			<div className={'table_col ' + reportStyles.table_col}>
				{!isOriginal && ratioToPercent(variation?.recommendation?.comparisonData?.probabilityToBeBest) + '%'}
				{isOriginal && ratioToPercent(variation.probabilityToBeBest) + '%'}
			</div>
			<div className={'table_col ' + reportStyles.table_col}>
				{!isOriginal?
					<>{ratioToPercent(variation?.recommendation?.comparisonData?.probabilityToPerformBetterThanBaseline) + '%'}</>
					:
					<>-</>
				}
			</div>
			<div className={'table_col ' + reportStyles.table_col}>
				<ABTestsReportingPlan
					data={variation.data}
					minValue={variationsMinMax.min}
					maxValue={variationsMinMax.max}
				/>
			</div>
		</div>
	);
};