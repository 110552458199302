import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import IpAdressesServices from '../../../Services/IpAdressesServices';
import ReportingCampaignServices from '../../../Services/ReportingCampaignServices';
import SystemServices from '../../../Services/SystemServices';
import LabelServices from '../../../Services/LabelsServices';
import ScreenShotServices from '../../../Services/ScreenShotServices';
import { ContextProvider } from './context';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import i18next from 'i18next';
import OnSitePlanningContainer from './Planning/OnSitePlanningContainer';
import OnSiteLayout from './layout/OnSiteLayout';
import OnSiteDashboardListContainer from './List/OnSiteDashboardListContainer';
import TabOnsite from './UI/TabOnsite';
import { I18nextProvider } from 'react-i18next';
import onsite_list_view_en from '../../../Translation/jsonfiles/en/onsite_list_view_en.json';
import utils_en from '../../../Translation/jsonfiles/en/utils_en.json';
import utils_fr from '../../../Translation/jsonfiles/fr/utils_fr.json';
import onsite_list_view_fr from '../../../Translation/jsonfiles/fr/onsite_list_view_fr.json';

export default function Onsitebridge(props) {
	const AuthServices = props.AuthServices;
  	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(
		props.$rootScope,
		props.$routeParams
	);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const IpAdressesService = new IpAdressesServices(
		props.$http,
		props.AuthServices
	);
	const screenShotServices = new ScreenShotServices(
		props.$http,
		props.AuthServices
	);
	const ReportingCampaignService = new ReportingCampaignServices(
		props.$http,
		props.$routeParams
	);
	const httpLink = createHttpLink({
		uri: window.BEYABLE_env.URI_GRAPHQL,
	});
	// graphQl config
	const authLink = setContext((_, { headers }) => {
		// get the authentication token from local storage if it exists
		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		};
	});
	const defaultOptions = {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
	};
	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
		defaultOptions: defaultOptions,
	});

	const labelServices = new LabelServices(props.$http, props.$routeParams);
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const token = props.AuthServices && props.AuthServices.getAccessToken();
	const permissionsAccount = Object.keys(
		props.$rootScope.$$childHead.permissions
	).map((x) => ({
		Name: x,
		Value: props.$rootScope.$$childHead.permissions[x],
	}));
	const i18nL= localStorage.getItem('i18nL');
	const newInstanceI18nextOnsite = i18next.createInstance();
	const destructTuringEn = {...utils_en, ...onsite_list_view_en};
	const destructTuringFr = {...utils_fr, ...onsite_list_view_fr};
	newInstanceI18nextOnsite.init({
		lng: 'en-EN',                             
		resources: {
			'en-US': {
				common: destructTuringEn          
			},
			'en-EN': {
				common: destructTuringEn            
			},
			'fr-FR': {
				common: destructTuringFr
			},
		}},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextOnsite.changeLanguage(JSON.parse(i18nL));
	});
	return (
		<BrowserRouter>
			<I18nextProvider i18n={newInstanceI18nextOnsite}>
				<ContextProvider
					AuthServices={AuthServices}
					$rootScope={props.$rootScope}
					$http={props.$http}
					$routeParams={props.$routeParams}
					$timeout={props.$timeout}
					ImpersonatingServices={impersonatingServices}
					IpAdressesServices={IpAdressesService}
					ReportingCampaignServices={ReportingCampaignService}
					screenShotServices={screenShotServices}
					systemServices={systemServices}
					labelServices={labelServices}
					accountId={accountId}
					token={token}
					permissionsAccount={permissionsAccount}
					client={client}
				>
					<ApolloProvider client={client}>
						<OnSiteLayout>
							<Switch>
								<Route
									exact
									path="/Onsite/Dashboard"
									component={OnSiteDashboardListContainer}
								/>

								<Route
									path="/Onsite/Dashboard?ka=:ka&ku=:ku"
									component={OnSiteDashboardListContainer}
								/>
								<Route
									path="/Onsite/Planning"
									component={OnSitePlanningContainer}
								/>
								<Route
									path="/Onsite/Planning?ka=:ka&ku=:ku"
									component={OnSitePlanningContainer}
								/>
							</Switch>
						</OnSiteLayout>
					</ApolloProvider>
				</ContextProvider>
			</I18nextProvider>
		</BrowserRouter>
	);
}
angular
	.module('beyableSaasApp.OnsiteBridge', [])
	.component(
		'onsitebridge',
		react2angular(
			Onsitebridge,
			[],
			['$rootScope', '$http', '$routeParams', 'AuthServices', '$timeout']
		)
	);
