import React from 'react';
import { ReportGeneratorContext } from '../Context';
import { List, ListItem } from '../../../Components/List';
import IconBtn from '../../../Components/IconBtn';
import useCampaignStatus from '../../../Hooks/useCampaignStatus';
import formatMoment from '../../../Constants/FormatMoment';
export default function CampaignSelector() {

	const {
		setmodalIsOpen,
		dataFiltered,
		handleChangeCpgIdOptions,
		setcampaignSelected
	} = ReportGeneratorContext();


	const { formatDateToLocal } = formatMoment;
	
	return (
		<List size='s'>
			{dataFiltered.map(campaign => {
				const status = useCampaignStatus(campaign);
				const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
				const dateEndOfOperation = formatDateToLocal(campaign.endDate);
				let statusOperation = {};

				function getStatuCss() {
					// Campagne arrêtée
					if (status.Key === 'stopped' ) {
						return statusOperation = { id: 'stopped', label: 'Stopped', cssClass: 'status-stopped' };
					}

					// Campagne en mode test (Draft)
					if (status.Key === 'testing') {
						return statusOperation = { id: 'testing', label: 'Draft', cssClass: 'status-testing' };
					}

					// Campagne terminée (Past)
					if (status.Key === 'past') {
						return statusOperation = { id: 'past', label: 'Past', cssClass: 'status-past' };
					}

					// Campagne programmée pour le futur (Planned)
					if (status.Key === 'planned') {
						return statusOperation = { id: 'planned', label: 'Scheduled', cssClass: 'status-planned' };
					}

					// Campagne en cours (Ongoing)
					return statusOperation = { id: 'ongoing', label: 'Ongoing', cssClass: 'status-ongoing' };
				}

				getStatuCss();

				getStatuCss();
				return	<div className='flex' key={campaign.id}>
					<a className={''} style={{margin : '2px 10px'}} >
						<span className={'status_bullet '
                    + statusOperation.cssClass
						}></span>
				
					</a>
					<ListItem
						
						text={<>{campaign.name} {dateBeginOfOperation.format('DD/MM/YYYY')} - {campaign.endDate !== null ?dateEndOfOperation.format('DD/MM/YYYY') : 'No end date'}</>}
						description={campaign.description}
						textIsBold={true}
						actions={<>
							<IconBtn
								icon='fas fa-plus-circle fa-sm'
								tooltip='Add campaign to your report'
								isActive={true}
								onClick={() => {
									setcampaignSelected(campaign);
									handleChangeCpgIdOptions(campaign);
									setmodalIsOpen(true);
								}}
							/>
						</>
						}
					/>
				</div>;}
			)}
		</List>
	);
}
