import React, { useState, useEffect, useRef } from 'react'
import './FilterSearchProductFeedContainer.css'
import InputCustom from './../../../../Components/InputCustom';
import Btn from './../../../../Components/Btn';
import SelectCustom from './../../../../Components/SelectCustom'
import FilterTypeList from './FilterTypeList'
import ListOFCategoriesField from './ListOfCategoriesFields'
import GroupOperatorItem from './GroupOperatorItem';
import useSort from '../../../../Hooks/useSort'
import DatePicker, { registerLocale } from "react-datepicker";
export default function FilterSearchProductFeedContainer({ 
	setSearchValue, 
	setClickOnSearchqueryAdvanced, 
	objectOfFilterForQuerySearch, 
	setObjectOfFilterForQuerySearch,
	closeThePannel
}) {
    const { sortById } = useSort()
    const [arrayOfSearch, setArrayOfSearch] = useState()
    const [clickOnAddCondition, setClickOnAddCondition] = useState(false)
    const wrapperRef = useRef(null);
    const initHierarchyOfFiler = () => {
        const arrayOfFilterCondition =
        {
            operator: {
                filters: [
                    {
                        nameOfQuery: "categories",
                        operatorOfQuery: "eq",
                        valueOfQuery: "",
                        type: "string",
                        id: 0,
                    },
                ],
                operatorType: `and`
            },
            and: [

            ],
            or: [

            ],
        }
        setArrayOfSearch(arrayOfFilterCondition)
    }
    const transformTheObjectForTheQuerySearchGraphQl = () => {
        let FirstOperator = { ...arrayOfSearch.operator }
        let myLastObjForRequest = new Object()
        myLastObjForRequest[FirstOperator["operatorType"]] = []
        for (let y = 0; y < FirstOperator.filters.length; y++) {
            if (FirstOperator.filters[y].nameOfQuery === "tags") {
                const opFirstLetter = FirstOperator.filters[y].operatorOfQuery.split("")[0]
                if (opFirstLetter === "n") {
                    let and = [
                        {
                            tags: {
                                none: {
                                    and: []
                                }
                            }
                        },
                        {
                            tags: {
                                some: {
                                    tag: {}
                                }
                            }
                        }
                    ]
                    const operatorQuery = FirstOperator.filters[y].operatorOfQuery
                    let operatorQueryWithoutNegative = operatorQuery.split("").slice(1).join("")
                    let tag = {}
                    tag.eq = FirstOperator.filters[y].fieldNameOfQueryTags
                    let valueOfSearchTag = {}
                    let valOfSearchOp = {}
                    valOfSearchOp[operatorQueryWithoutNegative] = FirstOperator.filters[y].valueOfQuery
                    valueOfSearchTag[FirstOperator.filters[y].searchTypeTags] = valOfSearchOp
                    and[0].tags.none.and.push(valueOfSearchTag)
                    and[0].tags.none.and.push({ tag: tag })
                    and[1].tags.some.tag.eq = FirstOperator.filters[y].fieldNameOfQueryTags
                    myLastObjForRequest[FirstOperator["operatorType"]].push({ and: and })
                } else {
                    let and = [
                        {
                            tags: {
                                some: {
                                    and: []
                                }
                            }
                        },
                        {
                            tags: {
                                some: {
                                    tag: {}
                                }
                            }
                        }
                    ]
                    const operatorQuery = FirstOperator.filters[y].operatorOfQuery
                    let tag = {}
                    tag.eq = FirstOperator.filters[y].fieldNameOfQueryTags
                    let valueOfSearchTag = {}
                    let valOfSearchOp = {}
                    valOfSearchOp[operatorQuery] = FirstOperator.filters[y].valueOfQuery
                    valueOfSearchTag[FirstOperator.filters[y].searchTypeTags] = valOfSearchOp
                    and[0].tags.some.and.push(valueOfSearchTag)
                    and[0].tags.some.and.push({ tag: tag })
                    and[1].tags.some.tag.eq = FirstOperator.filters[y].fieldNameOfQueryTags
                    myLastObjForRequest[FirstOperator["operatorType"]].push({ and: and })
                }
            }
            else if (FirstOperator.filters[y].nameOfQuery === "categories") {
                const opFirstLetter = FirstOperator.filters[y].operatorOfQuery.split("")[0]
                if (opFirstLetter === "n") {
                    let operatorQuery = FirstOperator.filters[y].operatorOfQuery
                    let operatorQueryWithoutNegative = operatorQuery.split("").slice(1).join("")
                    let categories = {
                        none: {
                            raw: {}
                        }
                    }
                    categories.none.raw[operatorQueryWithoutNegative] = FirstOperator.filters[y].valueOfQuery
                    myLastObjForRequest[FirstOperator["operatorType"]].push({ categories: categories })
                } else {
                    let categories = {
                        some: {
                            raw: {}
                        }
                    }
                    const operatorQuery = FirstOperator.filters[y].operatorOfQuery
                    categories.some.raw[operatorQuery] = FirstOperator.filters[y].valueOfQuery
                    myLastObjForRequest[FirstOperator["operatorType"]].push({ categories: categories })
                }
            }
            else {
                let firstOperatorObject = new Object()
                const nameOfFirstObject = FirstOperator.filters[y].nameOfQuery
                const operatorQuery = FirstOperator.filters[y].operatorOfQuery
                firstOperatorObject[nameOfFirstObject] = {}
                firstOperatorObject[nameOfFirstObject][operatorQuery] = FirstOperator.filters[y].valueOfQuery
                myLastObjForRequest[FirstOperator["operatorType"]].push(firstOperatorObject)
            }
        }
        function getAllTheOperationFilter(array, name) {
            let operatorsObjects = new Object()
            operatorsObjects[name] = []
            for (let i = 0; i < array.length; i++) {
                if (array[i]["nameOfQuery"] === "tags") {
                    const opFirstLetter = array[i].operatorOfQuery.split("")[0]
                    if (opFirstLetter === "n") {
                        let and = [
                            {
                                tags: {
                                    none: {
                                        and: []
                                    }
                                }
                            },
                            {
                                tags: {
                                    some: {
                                        tag: {}
                                    }
                                }
                            }
                        ]
                        const operatorQuery = array[i].operatorOfQuery
                        let operatorQueryWithoutNegative = operatorQuery.split("").slice(1).join("")
                        let tag = {}
                        tag.eq = array[i].fieldNameOfQueryTags
                        let valueOfSearchTag = {}
                        let valOfSearchOp = {}
                        valOfSearchOp[operatorQueryWithoutNegative] = array[i].valueOfQuery
                        valueOfSearchTag[array[i].searchTypeTags] = valOfSearchOp
                        and[0].tags.none.and.push(valueOfSearchTag)
                        and[0].tags.none.and.push({ tag: tag })
                        and[1].tags.some.tag.eq = array[i].fieldNameOfQueryTags
                        operatorsObjects[name].push({ and: and })
                    }
                    else {
                        let and = [
                            {
                                tags: {
                                    some: {
                                        and: []
                                    }
                                }
                            },
                            {
                                tags: {
                                    some: {
                                        tag: {}
                                    }
                                }
                            }
                        ]
                        const operatorQuery = array[i].operatorOfQuery
                        let tag = {}
                        tag.eq = array[i].fieldNameOfQueryTags
                        let valueOfSearchTag = {}
                        let valOfSearchOp = {}
                        valOfSearchOp[operatorQuery] = array[i].valueOfQuery
                        valueOfSearchTag[array[i].searchTypeTags] = valOfSearchOp
                        and[0].tags.some.and.push(valueOfSearchTag)
                        and[0].tags.some.and.push({ tag: tag })
                        and[1].tags.some.tag.eq = array[i].fieldNameOfQueryTags
                        operatorsObjects[name].push({ and: and })
                    }
                }
                else if (array[i]["nameOfQuery"] === "categories") {
                    const opFirstLetter = array[i].operatorOfQuery.split("")[0]
                    if (opFirstLetter === "n") {
                        let operatorQuery = array[i]["operatorOfQuery"]
                        let operatorQueryWithoutNegative = operatorQuery.split("").slice(1).join("")
                        let categories = {
                            none: {
                                raw: {}
                            }
                        }
                        categories.none.raw[operatorQueryWithoutNegative] = array[i]["valueOfQuery"];
                        operatorsObjects[name].push({ categories: categories })
                    } else {
                        let categories = {
                            some: {
                                raw: {}
                            }
                        }
                        const operatorQuery = array[i]["operatorOfQuery"]
                        categories.some.raw[operatorQuery] = array[i]["valueOfQuery"];
                        operatorsObjects[name].push({ categories: categories })
                    }

                } else {
                    const targetKey = array[i]["nameOfQuery"];
                    const targetOperator = array[i]["operatorOfQuery"];
                    const targetValue = array[i]["valueOfQuery"];
                    let newObjet = new Object()
                    newObjet[targetKey] = {}
                    newObjet[targetKey][targetOperator] = targetValue
                    operatorsObjects[name].push(newObjet)
                }
            }
            myLastObjForRequest[FirstOperator["operatorType"]].push(operatorsObjects)
        }
        if (arrayOfSearch.or.length > 0) {
            getAllTheOperationFilter(arrayOfSearch.or, 'or')
        }
        if (arrayOfSearch.and.length > 0) {
            getAllTheOperationFilter(arrayOfSearch.and, 'and')
        }
        setObjectOfFilterForQuerySearch(myLastObjForRequest)
        setClickOnSearchqueryAdvanced(true)
		closeThePannel()
    }

    const transformTheQuerySearchGraphQlToObject = (elementToDecode) => {
        let arrayOfFilterCondition =
        {
            operator: {
                filters: [],
                operatorType: ``
            },
            and: [

            ],
            or: [

            ],
        }
        arrayOfFilterCondition.operator.operatorType = Object.keys(elementToDecode)[0]
        let myObjectToTransform = elementToDecode[arrayOfFilterCondition.operator.operatorType]
        let filterArray = []
        let andArray = []
        let orArray = []
        for (let i = 0; i < myObjectToTransform.length; i++) {
            const objName = Object.keys(myObjectToTransform[i])[0]
            let arrayToPush;
            if (objName == "or") {
                arrayToPush = "orArray"
            }
            else if (objName == "and") {
                const arrayOfValues = myObjectToTransform[i][objName]
                let valuesToCheck = []
                if (arrayOfValues) {
                    for (let z = 0; z < arrayOfValues.length; z++) {
                        valuesToCheck.push(Object.keys(arrayOfValues[z])[0])
                    }
                    if (valuesToCheck[0] === "tags" && valuesToCheck[1] === "tags") {
                        arrayToPush = "firstOperand"
                    } else {
                        arrayToPush = "andArray"
                    }
                } else {
                    arrayToPush = "andArray"
                }
            }
            else {
                arrayToPush = "firstOperand"
            }
            if (arrayToPush === "firstOperand") {
                let myNewObject = {
                    nameOfQuery: "",
                    operatorOfQuery: "",
                    valueOfQuery: "",
                    type: "string",
                    id: 0,
                }
                if (objName && objName === "and") {
                    const typeOfOperator = Object.keys(myObjectToTransform[i][objName][0].tags)[0]
                    if (typeOfOperator === "none") {
                        const searchTypeTags = Object.keys(myObjectToTransform[i][objName][0].tags.none.and[0])[0]
                        const operatorOfQuery = Object.keys(myObjectToTransform[i][objName][0].tags.none.and[0][searchTypeTags])[0]
                        let transformOperatorQueryToNegative = operatorOfQuery.split('')
                        transformOperatorQueryToNegative.splice(0, 0, "n")
                        const valueOfQuery = myObjectToTransform[i][objName][0].tags.none.and[0][searchTypeTags][operatorOfQuery]
                        const fildNameOfQueryTag = myObjectToTransform[i][objName][0].tags.none.and[1].tag.eq
                        const typeOfTag = searchTypeTags === "dateValue" ? "date" : typeof valueOfQuery
                        myNewObject.nameOfQuery = "tags"
                        myNewObject.operatorOfQuery = transformOperatorQueryToNegative.join("")
                        myNewObject.searchTypeTags = searchTypeTags
                        myNewObject.typeOfTagInput = typeOfTag
                        myNewObject.valueOfQuery = valueOfQuery
                        myNewObject.fieldNameOfQueryTags = fildNameOfQueryTag
                        myNewObject.type = "tags"
                        myNewObject.id = filterArray.length + 1
                        filterArray.push(myNewObject)
                    } else {
                        const searchTypeTags = Object.keys(myObjectToTransform[i][objName][0].tags.some.and[0])[0]
                        const operatorOfQuery = Object.keys(myObjectToTransform[i][objName][0].tags.some.and[0][searchTypeTags])[0]
                        const valueOfQuery = myObjectToTransform[i][objName][0].tags.some.and[0][searchTypeTags][operatorOfQuery]
                        const fildNameOfQueryTag = myObjectToTransform[i][objName][0].tags.some.and[1].tag.eq
                        const typeOfTag = searchTypeTags === "dateValue" ? "date" : typeof valueOfQuery
                        myNewObject.nameOfQuery = "tags"
                        myNewObject.operatorOfQuery = operatorOfQuery
                        myNewObject.searchTypeTags = searchTypeTags
                        myNewObject.typeOfTagInput = typeOfTag
                        myNewObject.valueOfQuery = valueOfQuery
                        myNewObject.fieldNameOfQueryTags = fildNameOfQueryTag
                        myNewObject.type = "tags"
                        myNewObject.id = filterArray.length + 1
                        filterArray.push(myNewObject)
                    }
                }
                else if (objName && objName === "categories") {
                    const typeOfOperator = Object.keys(myObjectToTransform[i][objName])[0]
                    if (typeOfOperator === "none") {
                        const operatorOfQuery = Object.keys(myObjectToTransform[i][objName].none.raw)[0]
                        let transformOperatorQueryToNegative = operatorOfQuery.split('')
                        transformOperatorQueryToNegative.splice(0, 0, "n")
                        myNewObject.nameOfQuery = objName
                        myNewObject.operatorOfQuery = transformOperatorQueryToNegative.join("")
                        myNewObject.valueOfQuery = myObjectToTransform[i][objName].none.raw[operatorOfQuery]
                        myNewObject.id = filterArray.length + 1
                        myNewObject.type = ListOFCategoriesField.filter(elem => elem.value === objName)[0].type
                        filterArray.push(myNewObject)
                    } else {
                        const operatorOfQuery = Object.keys(myObjectToTransform[i][objName].some.raw)[0]
                        myNewObject.nameOfQuery = objName
                        myNewObject.operatorOfQuery = operatorOfQuery
                        myNewObject.valueOfQuery = myObjectToTransform[i][objName].some.raw[operatorOfQuery]
                        myNewObject.id = filterArray.length + 1
                        myNewObject.type = ListOFCategoriesField.filter(elem => elem.value === objName)[0].type
                        filterArray.push(myNewObject)
                    }
                } else {
                    if (objName) {
                        const operatorOfQuery = Object.keys(myObjectToTransform[i][objName])[0]
                        myNewObject.nameOfQuery = objName
                        myNewObject.operatorOfQuery = operatorOfQuery
                        myNewObject.valueOfQuery = myObjectToTransform[i][objName][operatorOfQuery]
                        myNewObject.id = filterArray.length + 1
                        myNewObject.type = ListOFCategoriesField.filter(elem => elem.value === objName)[0].type
                        filterArray.push(myNewObject)
                    }
                }
            }
            else if (objName === "or" || objName === "and") {
                let arrayToLoop = myObjectToTransform[i][objName]
                for (let y = 0; y < arrayToLoop.length; y++) {
                    let myNewObject = {
                        nameOfQuery: "",
                        operatorOfQuery: "",
                        valueOfQuery: "",
                        type: "string",
                        id: 0,
                    }
                    const nameObj = Object.keys(arrayToLoop[y])[0]
                    if (nameObj === "and") {
                        const typeOfOperator = Object.keys(arrayToLoop[y][nameObj][0].tags)[0]
                        if (typeOfOperator === "none") {
                            const searchTypeTags = Object.keys(arrayToLoop[y][nameObj][0].tags.none.and[0])[0]
                            const operatorOfQuery = Object.keys(arrayToLoop[y][nameObj][0].tags.none.and[0][searchTypeTags])[0]
                            let transformOperatorQueryToNegative = operatorOfQuery.split('')
                            transformOperatorQueryToNegative.splice(0, 0, "n")
                            const valueOfQuery = arrayToLoop[y][nameObj][0].tags.none.and[0][searchTypeTags][operatorOfQuery]
                            const fildNameOfQueryTag = arrayToLoop[y][nameObj][0].tags.none.and[1].tag.eq
                            const typeOfTag = searchTypeTags === "dateValue" ? "date" : typeof valueOfQuery
                            myNewObject.nameOfQuery = "tags"
                            myNewObject.operatorOfQuery = transformOperatorQueryToNegative.join("")
                            myNewObject.searchTypeTags = searchTypeTags
                            myNewObject.typeOfTagInput = typeOfTag
                            myNewObject.valueOfQuery = valueOfQuery
                            myNewObject.fieldNameOfQueryTags = fildNameOfQueryTag
                            myNewObject.type = "tags"
                            myNewObject.id = objName === "or" ? orArray.length + 1 : andArray.length + 1
                            objName === "or" ? orArray.push(myNewObject) : andArray.push(myNewObject)
                        } else {
                            const searchTypeTags = Object.keys(arrayToLoop[y][nameObj][0].tags.some.and[0])[0]
                            const operatorOfQuery = Object.keys(arrayToLoop[y][nameObj][0].tags.some.and[0][searchTypeTags])[0]
                            const valueOfQuery = arrayToLoop[y][nameObj][0].tags.some.and[0][searchTypeTags][operatorOfQuery]
                            const fildNameOfQueryTag = arrayToLoop[y][nameObj][0].tags.some.and[1].tag.eq
                            const typeOfTag = searchTypeTags === "dateValue" ? "date" : typeof valueOfQuery
                            myNewObject.nameOfQuery = "tags"
                            myNewObject.operatorOfQuery = operatorOfQuery
                            myNewObject.searchTypeTags = searchTypeTags
                            myNewObject.typeOfTagInput = typeOfTag
                            myNewObject.valueOfQuery = valueOfQuery
                            myNewObject.fieldNameOfQueryTags = fildNameOfQueryTag
                            myNewObject.type = "tags"
                            myNewObject.id = objName === "or" ? orArray.length + 1 : andArray.length + 1
                            objName === "or" ? orArray.push(myNewObject) : andArray.push(myNewObject)
                        }
                    }
                    else if (nameObj === "categories") {
                        const typeOfOperator = Object.keys(arrayToLoop[y][nameObj])[0]
                        if (typeOfOperator === "none") {
                            const operatorOfQuery = Object.keys(arrayToLoop[y][nameObj].none.raw)[0]
                            let transformOperatorQueryToNegative = operatorOfQuery.split('')
                            transformOperatorQueryToNegative.splice(0, 0, "n")
                            myNewObject.nameOfQuery = nameObj
                            myNewObject.operatorOfQuery = transformOperatorQueryToNegative.join("")
                            myNewObject.valueOfQuery = arrayToLoop[y][nameObj].none.raw[operatorOfQuery]
                            myNewObject.id = objName === "or" ? orArray.length + 1 : andArray.length + 1
                            myNewObject.type = ListOFCategoriesField.filter(elem => elem.value === nameObj)[0].type
                            objName === "or" ? orArray.push(myNewObject) : andArray.push(myNewObject)
                        } else {
                            const operatorOfQuery = Object.keys(arrayToLoop[y][nameObj].some.raw)[0]
                            myNewObject.nameOfQuery = nameObj
                            myNewObject.operatorOfQuery = operatorOfQuery
                            myNewObject.valueOfQuery = arrayToLoop[y][nameObj].some.raw[operatorOfQuery]
                            myNewObject.id = objName === "or" ? orArray.length + 1 : andArray.length + 1
                            myNewObject.type = ListOFCategoriesField.filter(elem => elem.value === nameObj)[0].type
                            objName === "or" ? orArray.push(myNewObject) : andArray.push(myNewObject)
                        }
                    } else {
                        if (objName) {
                            const operatorOfQuery = Object.keys(arrayToLoop[y][nameObj])[0]
                            myNewObject.nameOfQuery = nameObj
                            myNewObject.operatorOfQuery = operatorOfQuery
                            myNewObject.valueOfQuery = arrayToLoop[y][nameObj][operatorOfQuery]
                            myNewObject.id = objName === "or" ? orArray.length + 1 : andArray.length + 1
                            myNewObject.type = ListOFCategoriesField.filter(elem => elem.value === nameObj)[0].type
                            objName === "or" ? orArray.push(myNewObject) : andArray.push(myNewObject)
                        }
                    }
                }
            }
            arrayOfFilterCondition.operator.filters = filterArray
            arrayOfFilterCondition.or = orArray
            arrayOfFilterCondition.and = andArray
            setArrayOfSearch(arrayOfFilterCondition)
        }
    }

    useEffect(() => {
        if (objectOfFilterForQuerySearch) {
            transformTheQuerySearchGraphQlToObject(objectOfFilterForQuerySearch)
        } else {
            initHierarchyOfFiler()
        }
    }, [objectOfFilterForQuerySearch])

    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target.id && event.target.id === "op") {
                return
            }
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                event.stopPropagation()
                setClickOnAddCondition(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const createNewField = (operatorArray) => {
        setClickOnAddCondition(false)
        const operatorToAddSearchField = [...arrayOfSearch[operatorArray]]
        let newId = operatorToAddSearchField.length + 1
        const getNewId = () => {
            let ids = []
            let number
            if (operatorToAddSearchField.length !== 0) {
                operatorToAddSearchField.map(x =>
                    ids.push(x.id))
                number = parseInt(Math.max(...ids) + 1)
            } else {
                number = operatorToAddSearchField.length + 1
            }
            if (ids.includes(number)) {
                number = operatorToAddSearchField.length + 55
            }
            else {
                newId = number
            }
        }
        getNewId()
        const operatorModel = {
            nameOfQuery: "",
            operatorOfQuery: "",
            valueOfQuery: "",
            id: newId,
            type: "string"
        }
        const myObj = { ...arrayOfSearch }
        myObj[operatorArray] = [...myObj[operatorArray], operatorModel]
        setArrayOfSearch(myObj)

    }
    const createNewFieldForOperator = () => {
        setClickOnAddCondition(false)
        const operatorToAddSearchField = [...arrayOfSearch.operator.filters]
        let newId = operatorToAddSearchField.length + 1
        const getNewId = () => {
            let ids = []
            let number
            if (operatorToAddSearchField.length !== 0) {
                operatorToAddSearchField.map(x =>
                    ids.push(x.id))
                number = parseInt(Math.max(...ids) + 1)
            } else {
                number = operatorToAddSearchField.length + 1
            }
            if (ids.includes(number)) {
                number = operatorToAddSearchField.length + 55
            }
            else {
                newId = number
            }
        }
        getNewId()
        const operatorModel = { nameOfQuery: "", operatorOfQuery: "", valueOfQuery: "", id: newId, type: "string" }
        const withoutElementToPut = { ...arrayOfSearch }
        delete withoutElementToPut.operator
        let operator = { ...arrayOfSearch.operator }
        operator.filters = [...operator.filters, operatorModel]
        const newOperatorArray = ({ ...withoutElementToPut, operator })
        setArrayOfSearch(newOperatorArray)
    }
    const deleteAField = (operator, elemId) => {
        if (operator === "operator") {
            const myObj = { ...arrayOfSearch }
            myObj[operator].filters = myObj[operator].filters.filter(element => element.id !== elemId)
            setArrayOfSearch(myObj)
        } else {
            const myObj = { ...arrayOfSearch }
            let newElement = myObj[operator].filter(element => element.id !== elemId)
            myObj[operator] = newElement
            setArrayOfSearch(myObj)
        }
    }

    const handleChangeValueOperator = (operator, e, elemId, type) => {
        let newSelectValue
        if (type === "date") {
            newSelectValue = moment(e).format("YYYY-MM-DD") + "T00:00:00.000Z"
        } else {
            newSelectValue = e.target.value
        }
        if (type === "number") {
            newSelectValue = parseFloat(newSelectValue)
        }
        if (operator === "operator") {
            const myObj = { ...arrayOfSearch }
            let newElement = myObj[operator].filters.filter(element => element.id === elemId)[0]
            newElement.valueOfQuery = newSelectValue
            myObj[operator].filters = myObj[operator].filters.filter(element => element.id !== elemId)
            myObj[operator].filters = [...myObj[operator].filters, newElement]
            setArrayOfSearch(myObj)
        }
        else {
            const myObj = { ...arrayOfSearch }
            let newElement = myObj[operator].filter(element => element.id === elemId)[0]
            newElement.valueOfQuery = newSelectValue
            myObj[operator] = myObj[operator].filter(element => element.id !== elemId)
            myObj[operator] = [...myObj[operator], newElement]
            setArrayOfSearch(myObj)
        }
    }

    const handleChangeFieldNameOfQueryTags = (operator, e, elemId) => {
        let newSelectValue = e.target.value
        if (operator === "operator") {
            const myObj = { ...arrayOfSearch }
            let newElement = myObj[operator].filters.filter(element => element.id === elemId)[0]
            newElement.fieldNameOfQueryTags = newSelectValue
            myObj[operator].filters = myObj[operator].filters.filter(element => element.id !== elemId)
            myObj[operator].filters = [...myObj[operator].filters, newElement]
            setArrayOfSearch(myObj)
        }
        else {
            const myObj = { ...arrayOfSearch }
            let newElement = myObj[operator].filter(element => element.id === elemId)[0]
            newElement.fieldNameOfQueryTags = newSelectValue
            myObj[operator] = myObj[operator].filter(element => element.id !== elemId)
            myObj[operator] = [...myObj[operator], newElement]
            setArrayOfSearch(myObj)
        }
    }

	const selectOperator = (operator, newSelectValue, elemId, operatorType) => {
		const myObj = { ...arrayOfSearch };
		let newElement =
            operator === "operator"
                ? myObj[operator].filters.filter((element) => element.id === elemId)[0]
                : myObj[operator].filter((element) => element.id === elemId)[0];

		newElement[`${operatorType}`] = newSelectValue

		if (operatorType === "nameOfQuery") {
			newElement.valueOfQuery = null;
        	newElement.operatorOfQuery = null;
			if (newSelectValue === "tags") {
				newElement.searchTypeTags = "";
				newElement.fieldNameOfQueryTags = null;
			}
			newElement.type = ListOFCategoriesField.filter((elem) => elem.value === newSelectValue)[0].type;
		}

		if (operatorType === "searchTypeTags") {
			newElement.typeOfTagInput = FilterTypeList.filter((elem) => elem.type === "tags")[0].filter.filter(el => el.value === newSelectValue)[0].type
			newElement.valueOfQuery = null
		}

		if (operator === "operator") {
			myObj[operator].filters = myObj[operator].filters.filter(element => element.id !== elemId)
            myObj[operator].filters = [...myObj[operator].filters, newElement]
        } else {
			myObj[operator] = myObj[operator].filter(element => element.id !== elemId)
            myObj[operator] = [...myObj[operator], newElement];
        }
        setArrayOfSearch(myObj);
	}

    const changeTheMainOperatorValue = (operatorItem) => {
        const myObj = { ...arrayOfSearch }
        if (operatorItem === "or") {
            myObj.operator.operatorType = "and"
        } else {
            myObj.operator.operatorType = "or"
        }
        setArrayOfSearch(myObj)
    }
    return (
        <>
            <h2> Advanced search </h2>
            <div className="filter-search-product-feed-container">
                <div className="operator-selector-value operator-main"
                    onClick={(e) => changeTheMainOperatorValue(arrayOfSearch.operator.operatorType)}>
                    <p title="Change the operator"> {arrayOfSearch && arrayOfSearch.operator.operatorType}</p>
                </div>
                <div className="container-operator-selector">
                    <div>
                        {arrayOfSearch && arrayOfSearch.operator.filters.sort(sortById).map((elem, index) =>
                            <div className="container-operator-fields" key={index}>

                                <div className="selector-operator-fields">
                                    <SelectCustom
                                        optionsList={ListOFCategoriesField}
                                        name="nameOfQuery"
                                        onChangeFunction={(e) => selectOperator("operator", e.target.value, elem.id, "nameOfQuery")}
                                        defaultValueDisabled={{
                                            value: "choose",
                                            label: "Choose a name field",
                                            isDisabled: true
                                        }}
                                        label=""
                                        value={elem.nameOfQuery}
                                    />
                                    {elem.nameOfQuery !== "tags" ?
                                        <>
                                            <SelectCustom
                                                optionsList={FilterTypeList.filter(filterType => filterType.type === elem.type)[0].filter}
                                                name="operatorOfQuery"
                                                onChangeFunction={(e) => selectOperator("operator", e.target.value, elem.id, "operatorOfQuery")}
                                                defaultValueDisabled={{
                                                    value: "choose",
                                                    label: "Choose an operator",
                                                    isDisabled: true
                                                }}
                                                label=""
                                                value={elem.operatorOfQuery}
                                            />
                                        </>
                                        :
                                        <>
                                            <SelectCustom
                                                optionsList={FilterTypeList.filter(filterType => filterType.type === elem.type)[0].filter}
                                                name="nameOfQuery"
                                                onChangeFunction={(e) => selectOperator("operator", e.target.value, elem.id, "searchTypeTags")}
                                                defaultValueDisabled={{
                                                    value: "choose",
                                                    label: "Choose a type of search",
                                                    isDisabled: true
                                                }}
                                                label=""
                                                value={elem.searchTypeTags}
                                            />

                                        </>
                                    }
                                    <div className="trash-field-operator" title="Delete the field" onClick={(e) => deleteAField("operator", elem.id)}><i className="fas fa-trash"></i></div>
                                </div>
                                {elem.nameOfQuery === "tags" &&
                                    <div className="container-search-tag-feed">
                                        <InputCustom
                                            label=""
                                            type="text"
                                            placeholder={`Tag name`}
                                            value={elem.fieldNameOfQueryTags}
                                            name="valueOfQuery"
                                            onChange={(e) => handleChangeFieldNameOfQueryTags("operator", e, elem.id)}
                                        />
                                        <SelectCustom
                                            optionsList={elem.searchTypeTags === "value" ?
                                                FilterTypeList.filter(filterType => filterType.type === "string")[0].filter
                                                :
                                                FilterTypeList.filter(filterType => filterType.type === "number")[0].filter
                                            }
                                            name="operatorOfQuery"
                                            onChangeFunction={(e) => selectOperator("operator", e.target.value, elem.id, "operatorOfQuery")}
                                            defaultValueDisabled={{
                                                value: "choose",
                                                label: "Choose an operator",
                                                isDisabled: true
                                            }}
                                            label=""
                                            value={elem.operatorOfQuery}
                                        />
                                    </div>
                                }
                                {
                                    elem.searchTypeTags === "dateValue" ?
                                        <>
                                            <DatePicker
                                                selected={elem.valueOfQuery !== null && moment(elem.valueOfQuery).toDate()}
                                                onChange={(e) => handleChangeValueOperator("operator", e, elem.id, "date")}
                                                dateFormat="dd/MM/yyyy"

                                            />
                                        </>
                                        :
                                        <div className="container-operator-value">
                                            <InputCustom
                                                label=""
                                                type={elem.nameOfQuery !== "tags" ? elem.type : elem.typeOfTagInput}
                                                placeholder={`Search value`}
                                                value={elem.valueOfQuery}
                                                name="valueOfQuery"
                                                onChange={elem.nameOfQuery !== "tags" ?
                                                    (e) => handleChangeValueOperator("operator", e, elem.id, elem.type) :
                                                    (e) => handleChangeValueOperator("operator", e, elem.id, elem.typeOfTagInput)
                                                }
                                            />
                                        </div>
                                }

                            </div>
                        )}
                        <div className="secondary-operators">
                            {arrayOfSearch && arrayOfSearch.and.length !== 0 &&
                                <GroupOperatorItem
                                    operator="and"
                                    fieldList={arrayOfSearch.and}
                                    selectNameOfQuery={(op, e, elem) => selectOperator(op, e.target.value, elem, "nameOfQuery")}
                                    selectTypeTags={(op, e, elem) => selectOperator(op, e.target.value, elem, "searchTypeTags")}
                                    handleChangeFieldNameOfQueryTags={(op, e, elem) => handleChangeFieldNameOfQueryTags(op, e, elem)}
                                    selectOperatorOfQuery={(op, e, elem) => selectOperator(op, e.target.value, elem, "operatorOfQuery")}
                                    handleChangeValueOfQuery={(op, e, id, theType) => handleChangeValueOperator(op, e, id, theType)}
                                    createNewField={(e) => createNewField(e)}
                                    deleteAField={(op, idFi) => deleteAField(op, idFi)}
                                />
                            }
                            {arrayOfSearch && arrayOfSearch.or.length !== 0 &&
                                <GroupOperatorItem
                                    operator="or"
                                    fieldList={arrayOfSearch.or}
                                    selectNameOfQuery={(op, e, elem) => selectOperator(op, e.target.value, elem, "nameOfQuery")}
                                    selectTypeTags={(op, e, elem) => selectOperator(op, e.target.value, elem, "searchTypeTags")}
                                    handleChangeFieldNameOfQueryTags={(op, e, elem) => handleChangeFieldNameOfQueryTags(op, e, elem)}
                                    selectOperatorOfQuery={(op, e, elem) => selectOperator(op, e.target.value, elem, "operatorOfQuery")}
                                    handleChangeValueOfQuery={(op, e, id, theType) => handleChangeValueOperator(op, e, id, theType)}
                                    createNewField={(e) => createNewField(e)}
                                    deleteAField={(op, idFi) => deleteAField(op, idFi)}
                                />
                            }

                        </div>
                        <div className="filter-search-product-operator-add-condition"
                            ref={wrapperRef}
                            onClick={(e) => setClickOnAddCondition(!clickOnAddCondition)}
                        >
                            <p>Add Condition</p> <i className="fas fa-caret-down"></i>
                        </div>
                        {clickOnAddCondition &&
                            <ul>
                                <li id="op" onClick={(e) => createNewFieldForOperator()}>Field</li>
                                {arrayOfSearch && arrayOfSearch.or.length === 0 ?
                                    <li id="op" onClick={(e) => createNewField("or")}>Or Group</li>
                                    :
                                    <li id="op" className="field_already_use">Or Group</li>
                                }

                                {arrayOfSearch && arrayOfSearch.and.length === 0 ?
                                    <li id="op" onClick={(e) => createNewField("and")}>And Group</li>
                                    :
                                    <li id="op" className="field_already_use">And Group</li>
                                }
                            </ul>
                        }
                    </div>
                </div>

            </div>
            <div className="footer-btn-search-filter">
                <Btn
                    onClickFunction={transformTheObjectForTheQuerySearchGraphQl}
                    message="Save"
                    style="outline"
                />
                <Btn
                    onClickFunction={transformTheObjectForTheQuerySearchGraphQl}
                    message="Search"
                    style="outline"
                />

            </div>
        </>
    )
}
