import HttpServices from './HttpServices';
import axios from 'axios';

export default class ScreenShotServices {
	constructor($http, authServices) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {},
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
	}
	putCampaignImage(accountId, campaignId, screenId, formData) {
		return axios.put(
			`${this.BO_API_URL}campaign/${accountId}/${campaignId}/related/screenshot/${screenId}`,
			formData,
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' },
		);
	}
	postCampaignImage(accountId, campaignId, formData) {
		return axios.post(
			`${this.BO_API_URL}/${accountId}/campaign/${campaignId}/screenshot/`,
			formData,
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' }
		);
	}
	postCampaignImageLogo(accountId, formData) {
		return axios.post(
			`${this.BO_API_URL}/account/${accountId}/screenshot/`,
			formData,
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' }
		);
	}
	putCampaignImageLogo(accountId,id, formData) {
		return axios.put(
			`${this.BO_API_URL}/account/${accountId}/screenshot/${id}`,
			formData,
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' }
		);
	}
	getCampaignImageLogo(accountId) {
		return axios.get(
			`${this.BO_API_URL}/account/${accountId}/screenshot/`,
			{ headers: this.config.headers,}
		);
	}

	deleteCampaignImage(
		accountId,
		campaignId,
		screenId,
	) {
		return axios.delete(
			`${this.BO_API_URL}campaign/${accountId}/${campaignId}/related/screenshot/${screenId}`,
			{ headers: this.config.headers }
		);
	}
}
