import React from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { react2angular } from 'react2angular';
import { ContextProvider } from './List/context';
import RouterWrapper from '../Dashboard/OnSite/RouterWrapper';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import SystemServices from '../../Services/SystemServices';
import ReportingCampaignServices from '../../Services/ReportingCampaignServices';
import ServerSideCampaignServices from '../../Services/ServerSideCampaignServices';
import ServerSideDashboardContainerInner from './List/ServerSideDashboardContainerInner';
import campaign_serverside_react_en from '../../Translation/jsonfiles/en/campaign_serverside_react_en.json';
import campaign_serverside_react_fr from '../../Translation/jsonfiles/fr/campaign_serverside_react_fr.json';
import utils_en from '../../Translation/jsonfiles/en/utils_en.json';
import utils_fr from '../../Translation/jsonfiles/fr/utils_fr.json';
import { mergeJson } from '../../Translation/translation-utils';


export default function ServerSideDashboardContainer(props) {
	const $rootScope = props.$rootScope;
	const $routeParams = props.$routeParams;
	const $http = props.$http;
	const $timeout = props.$timeout;
	// const authServices = props.AuthServices;

	const systemServices = new SystemServices($rootScope, $timeout);
	const reportingCampaignService = new ReportingCampaignServices($http, $routeParams);
	const impersonatingServices = new ImpersonatingServices($rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || ($rootScope && $rootScope.User.Account.Key);
	const serverSideCampaignServices = new ServerSideCampaignServices(accountId, $http);

	const isAdmin = impersonatingServices.isAnAdmin();

	const linkToReporting = (campaignId) => {
		if (isAdmin && $routeParams.ka) {
			return `ServerSide/Reporting?id=${campaignId}&dimension=onSite&ka=${accountId}&ku=${$routeParams.ku}`;
		} else {
			return `ServerSide/Reporting?id=${campaignId}&dimension=onSite`;
		}
	};

	const linkToEditor = (campaignId) => {
		if (isAdmin && $routeParams.ka) {
			if (campaignId){
				return `ServerSide/Editor?id=${campaignId}&dimension=onSite&ka=${accountId}&ku=${$routeParams.ku}`;
			} else {
				return `ServerSide/Editor?dimension=onSite&ka=${accountId}&ku=${$routeParams.ku}`;
			}
		} else {
			if (campaignId){
				return `ServerSide/Editor?id=${campaignId}&dimension=onSite`;
			} else {
				return 'ServerSide/Editor?dimension=onSite';
			}
		}
	};

	const i18nL = localStorage.getItem('i18nL');
	const newInstanceI18nextOnsite = i18next.createInstance();
	newInstanceI18nextOnsite.init({
		lng: 'en-EN',
		resources: {
			'en-US': {
				common: mergeJson(campaign_serverside_react_en, utils_en)
			},
			'en-EN': {
				common: mergeJson(campaign_serverside_react_en, utils_en)
			},
			'fr-FR': {
				common: mergeJson(campaign_serverside_react_fr, utils_fr)
			},
		}
	},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextOnsite.changeLanguage(JSON.parse(i18nL));
	});

	return (
		<I18nextProvider i18n={newInstanceI18nextOnsite}>
			<ContextProvider
				getCampaignList={(callbackSuccess, callbackError) => serverSideCampaignServices.getServerSideCampaignList(callbackSuccess, callbackError)}
				getCampaignInfos={(campaignId, callbackSuccess, callbackError) => serverSideCampaignServices.getServerSideCampaignById(campaignId, callbackSuccess, callbackError)}
				createNewCampaign={(campaign, callbackSuccess, callbackError) => serverSideCampaignServices.createServerSideCampaign(accountId, campaign, callbackSuccess, callbackError)}
				deleteCampaign={(campaignId, callbackSuccess, callbackError) => reportingCampaignService.deleteACampaign(accountId, campaignId, callbackSuccess, callbackError)}
				updateCampaign={(campaignId, { name, description }, callbackSuccess, callbackError) => serverSideCampaignServices.updateServerSideCampaign(campaignId, { name, description }, callbackSuccess, callbackError)}
				systemServices={systemServices}
				linkToReporting={linkToReporting}
				linkToEditor={linkToEditor}
				$rootScope={$rootScope}
			>
				<RouterWrapper props={props}>
					<ServerSideDashboardContainerInner props={props} />
				</RouterWrapper>
			</ContextProvider>
		</I18nextProvider>
	);
}


angular
	.module('beyableSaasApp.ServerSideDashboard', ['beyableSaasApp.Services'])
	.component('serversidedashboard', react2angular(ServerSideDashboardContainer, [],
		['$rootScope', '$http', '$routeParams', '$timeout', '$location', 'AuthServices']));