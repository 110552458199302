import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Btn from '../../../../Components/Btn';
import Checkbox from '../../../../Components/Checkbox';
import InputCustom from '../../../../Components/InputCustom';
import SelectDropdown from '../../../../Components/SelectDropdown';
import EmptyState from '../../../../Components/EmptyState';
import {List, ListItem} from '../../../../Components/List';
import Avatar from '../../../../Components/Avatar';
import IconBtn from '../../../../Components/IconBtn';
import { Article } from '../../../../Components/Article/Article';
import { AttributeOrScoreFilterItem, AttributeOrScoreValueType, Params } from '../../EntityTypes/EntityTypes';
import {
	GroupSorting,
	GroupSortingGroupEntity,
	GroupSortingGroupType,
	FilterParam,
	buildStringValueFilterParam,
	userChoicesForGroupSortingGroupType,
	UserChoiceForGroupSortingGroupType,
	userChoicesForValueType
} from './GroupSortingEntityTypes';
import styles from './GroupSorting.module.css';

type GroupSortingSetupEditableGroupListType = {
	groups: GroupSorting;
	selectedAttribute: AttributeOrScoreFilterItem;
	handleAddGroup: () => void;
	handleDeleteGroup: (group: GroupSortingGroupEntity) => void;
	handleChangeGroup: (group: GroupSortingGroupEntity) => void;
	handleChangeFilterParam: (filterParam: FilterParam) => void;
	handleChangeGroupsOrder: (sourceIndex: number, destinationIndex: number) => void;
	handleChangeGroupsValueType: (valueType: AttributeOrScoreValueType) => void;
	quickSetupAllowed: boolean;
	openQuickSetup: () => void;
}

export default function GroupSortingSetupEditableGroupList({
	groups,
	selectedAttribute,
	handleAddGroup,
	handleDeleteGroup,
	handleChangeGroup,
	handleChangeGroupsOrder,
	handleChangeFilterParam,
	handleChangeGroupsValueType,
	quickSetupAllowed,
	openQuickSetup
} : GroupSortingSetupEditableGroupListType) {

	const handleUpdateGroupType = (g: GroupSortingGroupEntity, choice: UserChoiceForGroupSortingGroupType) =>
		handleChangeGroup(g.changeGroupTypeFromUserChoice(choice));

	const ChangeGroupItemType = (g: GroupSortingGroupEntity) => {
		const getOptionsList = () => {
			return groups.hasDefault() && g.userChoiceForGroupSortingGroup.type !== UserChoiceForGroupSortingGroupType.Default
				? userChoicesForGroupSortingGroupType.filter(x => x.value !== UserChoiceForGroupSortingGroupType.Default)
				: userChoicesForGroupSortingGroupType;
		};
		return (
			<SelectDropdown
				optionsList={getOptionsList()}
				onChange={(v) => handleUpdateGroupType(g, v as UserChoiceForGroupSortingGroupType)}
				value={g.userChoiceForGroupSortingGroup.type ?? UserChoiceForGroupSortingGroupType.IsSingleValue}
			/>
		);
	};

	const RangeGroupItem = (g: GroupSortingGroupEntity) => {
		const lowerBoundValue = g.getLowerBoundValueOrDefault('');
		const higherBoundValue = g.getHigherBoundValueOrDefault('');
		const userChoiceType = g.userChoiceForGroupSortingGroup.type;
		const lowerBound = () => (
			<span>
				<InputCustom
					type={g.valueType === 'Number' ? 'number' : 'text'}
					width={g.valueType === 'Number' ? 'xs' : 's'}
					placeholder="value"
					onChange={(e) => handleChangeGroup(g.updateBounds(undefined, undefined, e.target.value, undefined))}
					value={(lowerBoundValue)}
					isAnError={g.isAnError ? g.errorMessage : ''}
					errorPosition='tooltip'
				/>
				<Checkbox
					className="ml_8"
					size="xs"
					checkboxSize="s"
					forcedChecked={g.userChoiceForGroupSortingGroup.lowerBoundInclusive}
					onChange={ev => handleChangeGroup(g.changeBoundsFromUserChoice(undefined, ev.target.checked))}
				><span className='s_13 grey_2'>Included</span></Checkbox>
			</span>
		);

		const higherBound = () => (
			<span>
				<InputCustom
					type={g.valueType === 'Number' ? 'number' : 'text'}
					width={g.valueType === 'Number' ? 'xs' : 's'}
					placeholder="value"
					onChange={(e) => handleChangeGroup(g.updateBounds(e.target.value, undefined, undefined, undefined))}
					value={higherBoundValue}
					isAnError={g.isAnError ? g.errorMessage : ''}
					errorPosition='tooltip'
				/>
				<Checkbox
					className="ml_8"
					size="xs"
					checkboxSize="s"
					forcedChecked={g.userChoiceForGroupSortingGroup.higherBoundInclusive}
					onChange={ev => handleChangeGroup(g.changeBoundsFromUserChoice(ev.target.checked, undefined))}
				><span className='s_13 grey_2'>Included</span></Checkbox>
			</span>
		);

		return (
			<div className={styles.group_line}>
				{ChangeGroupItemType(g)}
				{userChoiceType === UserChoiceForGroupSortingGroupType.IsBetween &&
					<>
						{lowerBound()}
						&nbsp;and&nbsp;
						{higherBound()}
					</>
				}
				{userChoiceType === UserChoiceForGroupSortingGroupType.IsGreaterThan && lowerBound()}
				{userChoiceType === UserChoiceForGroupSortingGroupType.IsLowerThan && higherBound()}
			</div>
		);
	};

	const SingleValueGroupItem = (g: GroupSortingGroupEntity) => {
		return (
			<div className={styles.group_line}>
				{ChangeGroupItemType(g)}
				<InputCustom
					type={g.valueType === 'Number' ? 'number' : 'text'}
					width={g.valueType === 'Number' ? 'xs' : 'm'}
					placeholder="value"
					onChange={(e) => handleChangeGroup(g.updateSingleValue(e.target.value))}
					value={g.getSingleValue('')}
					blockClassName="inline mr_10"
					isAnError={g.isAnError ? g.errorMessage : ''}
					errorPosition='tooltip'
				/>
			</div>
		);
	};

	const DefaultValueGroupItem = (g: GroupSortingGroupEntity) => {
		return (
			<div className={styles.group_line}>
				{ChangeGroupItemType(g)}
			</div>
		);
	};


	const GroupItemRow = (
		g: GroupSortingGroupEntity,
		index: number,
		isDragging: boolean,
		ref: any,
		draggableProps,
		dragHandleProps
	) => (
		<ListItem
			picto={
				<Avatar
					name={index + 1}
					fixedColor="green"
					size="s"
				/>
			}
			actions={
				<>
					<IconBtn
						icon="fas fa-sort"
						tooltip='Reorder groups'
						{...dragHandleProps}
					/>
					<IconBtn
						icon="fas fa-trash"
						hoverColor="alert"
						tooltip='Delete'
						onClick={() => handleDeleteGroup(g)}
					/>
				</>
			}
			ref={ref}
			isDragging={isDragging}
			{...draggableProps}
		>
			{GroupItem(g, isDragging)}
		</ListItem>
	);

	const GroupItem = (g: GroupSortingGroupEntity, isDragging: boolean) => {
		isDragging; // if useful, do something with this variable, else remove
		switch (g.groupData.type) {
			case GroupSortingGroupType.Range: return RangeGroupItem(g);
			case GroupSortingGroupType.SingleValue: return SingleValueGroupItem(g);
			case GroupSortingGroupType.Default: return DefaultValueGroupItem(g);
			default: return <></>;
		}
	};


	const onChangeFilterParam = (e, x: Params) => {
		e.preventDefault();
		const value = e.target.value;
		handleChangeFilterParam(buildStringValueFilterParam(x.name, value));
	};

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		handleChangeGroupsOrder(result.source.index, result.destination.index);
	};

	const hasParams = selectedAttribute.valueType === AttributeOrScoreValueType.Tag || selectedAttribute.params?.length;

	const innerContent = (
		<>
			{groups.groupsData.length === 0 &&
				<EmptyState
					title={'No group yet'}
					titleBold={false}
					verticalAlign={!hasParams}
					verticalSize="s"
					primaryAction={
						quickSetupAllowed ?
							<>
								<Btn
									message="Add group manualy"
									onClick={() => handleAddGroup()}
									color="primary"
									style="reverse"
									icon="fas fa-plus-circle"
								/>
								<Btn
									message="Quick add"
									onClick={openQuickSetup}
									color="primary"
									style="reverse"
									icon="fas fa-bolt"
								/>
							</>
							:
							<>
								<Btn
									message="Add group"
									onClick={() => handleAddGroup()}
									color="primary"
									style="reverse"
									icon="fas fa-plus-circle"
								/>
							</>
					}
				/>
			}

			{groups.groupsData.length > 0 &&
				<>
					<div style={hasParams ? {marginTop: '-14px'} : {}}>
						<DragDropContext onDragEnd={handleOnDragEnd}>
							<Droppable droppableId="id" direction="vertical">
								{(provided) => (
									<List hasLastBorder={true} ref={provided.innerRef} {...provided.droppableProps}>
										{groups.groupsData.map((g, index) =>
											<Draggable key={g.id} draggableId={g.id.toString()} index={index}>
												{(provided, snapshot) => (
													GroupItemRow(g, index, snapshot.isDragging, provided.innerRef, provided.draggableProps, provided.dragHandleProps)
												)}
											</Draggable>
										)}
										{provided.placeholder}
									</List>
								)}
							</Droppable>
						</DragDropContext>
					</div>
					<div className='mt_15'>
						<Btn
							message="Add group"
							color="primary"
							style="reverse"
							icon='fas fa-plus-circle'
							onClick={() => handleAddGroup()}
						/>
					</div>
				</>
			}
		</>
	);

	return (
		<>
			<div className='flex flex_align_start flex_wrap' style={{gap: 10}}>
				{selectedAttribute.params?.map(x => (
					<InputCustom
						key={x.name}
						type="text"
						label={x.name}
						onChange={e => onChangeFilterParam(e, x)}
						value={groups.getFilterParam(x.name) || ''}
						isAnError={groups.getFilterParamErrorMessage(x.name)}
					/>
				))}

				{selectedAttribute.valueType === AttributeOrScoreValueType.Tag &&
					<SelectDropdown
						optionsList={userChoicesForValueType}
						label="Format"
						onChange={(v) => handleChangeGroupsValueType(v as AttributeOrScoreValueType)}
						value={groups.userChoiceValueType ?? AttributeOrScoreValueType.String}
					/>
				}
			</div>

			{hasParams ?
				<Article hasMargin={false} title="Groups" titleSize='xs' innerSize='s'>
					{innerContent}
				</Article>
				:
				<>{innerContent}</>
			}
		</>
	);
}