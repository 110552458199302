const websiteType = [
{
    value: "ecommerce",
    label: "E-commerce"
},
{
    value: "leadgen",
    label: "Lead Generation"
},
{
    value: "content",
    label: "Blog / Content"
},
{
    value: "landingpage",
    label: "Landing Page"
},
{
    value: "other",
    label: "Other"
},
]
  
export default websiteType;