import React, { } from 'react';
import SelectDropdown from '../../../../Components/SelectDropdown';

export default function TenantpreviewSelected({
	listTenantAccount,
	handleChangeTenantPreview,
	previewTenant
}) {

	if(!previewTenant){
		return null;
	}

	// const previewTenantWithoutAll = listTenantAccount && listTenantAccount.filter(el => el.label !== "All")

	return (
		<SelectDropdown
			label='Tenant'
			labelPosition='inner'
			optionsList={listTenantAccount}
			value={previewTenant.value}
			onChange={(v) => handleChangeTenantPreview(v)}
			color='white'
		/>
	)
}
