function useInputValidation() {

    const isAnUrl = (url) => {
        if (url && url.match(/ftp?:|http?:|https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#/=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
            return true
        } else {
            return false
        }
    }
    const isAnEmail = (email) => {
        return email && email.match(/.+@.+\..+/)
    }
    const isNotEmpty = (val) => {
        if (val == undefined) {
            return false
        }
        else if (val.length !== 0) {
            return true
        } else {
            return false
        }
    }
    const errorsMessages = {
        url: "Please enter a valid url (example: https://domain.com)",
        empty: "The field is required"
    }
    return {
        isAnUrl,
        isAnEmail,
        isNotEmpty,
        errorsMessages,
    }
}
export default useInputValidation
