import React, { createContext, useContext, useState, useEffect } from 'react';
import controlTypes from './controlTypes';
import { getCSS } from './css';

const isEmptyForm = function (formValue) {
	var hasNoSection = function (form) { return form.sections.length === 0; };
	var hasSingleEmptySection = function (form) { return form.sections.length === 1 && form.sections[0].panels.length === 0; };
	var hasSingleNotSetSection = function (form) { return form.sections.length === 1 && form.sections[0].panels.length === 1 && form.sections[0].panels[0].type === ''; };

	return (!formValue.sections) || hasNoSection(formValue) || hasSingleEmptySection(formValue) || hasSingleNotSetSection(formValue);
};

const FormContext = createContext();

function useFormContext() {
	return useContext(FormContext);
}

const ContextFormAndSurveyProvider = (props) => {

	const {
		formData,
		panelSelected,
		setpanelSelected,
		stepSurvey,
		stepSelected,
		setFormData,
		needUpdateState,
		formSurveyStyles,
		isLast,
		setisLast,
		setThankSectionValue,
		setFormSurveyStyles,
		data,
		setState,
		state
	} = props;

	const [listOfIdQuestion, setlistOfIdQuestion] = useState([]);

	useEffect(()=>{
		if(formData){
			const formattedData = JSON.parse(formData);

			if(formattedData.sections[0]?.panels.length > 0){
				const arrayOfQuestions = formattedData.sections[0].panels.map((el, idx)=> {
					return {...el, idInList : idx+1};
				});
				setlistOfIdQuestion(arrayOfQuestions);
			}
		}
	},[formData, panelSelected]);
	

    function updatePanel(id, _sectionId, stateToUpdate) {
        let newP = null;
        const oldState = JSON.parse(JSON.stringify(state));

        const sections = oldState.sections.map(section => {

            const panels = section.panels.map(panel => {
                if (id && panel.id === id) {
                    const newPanel = { ...panel, ...stateToUpdate };
                    newP = newPanel;
                    setpanelSelected(newPanel);
                    return newPanel;
                }
                return panel;
            });
            if (stateToUpdate.hasOwnProperty('styles') || stateToUpdate.hasOwnProperty('thanksSection') || stateToUpdate.hasOwnProperty('css')) {
                return { ...section, ...stateToUpdate, panels, id: section.id };
            } else {
                return { ...section, panels, id: section.id };
            }

        });
        setState({ 'sections': sections });
        setpanelSelected(newP);
    }

	function deleteAnswers() {
		const oldState = JSON.parse(JSON.stringify(state));
		const sections = oldState.sections.map(section => {

			const panels = section.panels.map(panel => {

				const newPanel = { ...panel, valueForTest: '' };
				return newPanel;

			});

			return {
				...section,
				panels,
				id: section.id
			};
		});

		const firstSection = sections.length > 0 ? sections[0] : null;
		const firstPanel = firstSection && (firstSection.panels.length > 0 ? firstSection.panels[0] : null);
		if (firstPanel)
			setpanelSelected(firstPanel);

		setState({ 'sections': sections });
		setisLast(false);
	}

	function onLoad(dataToCheck) {
		var parsePropertyValue = function (properties) {
			const form = properties.find(el => el.Name === 'FormSurveyCustom');
			if (properties && form && form.Value) {
				return JSON.parse(form.Value);
			}
			else return {};
		};
		const emptyStyle = {
			colors : {
				global: {
					backgroundColor : '#FFFFFF',
					textColor : '#151E39',
					errorColor: '#df4141'
				},
				button : {
					backgroundColor : '#23B583',
					textColor : '#FFFFFF',
					border: false,
					borderColor: '#16563e'
				},
				skip : {
					backgroundColor : '#FFFFFF',
					textColor : '#5D606F',
					border: true,
					borderColor: '#CAD0DB'
				}
			},
			wording : {
				language : 'French',
				nextButton : 'Suivant',
				skipButton : 'Passer',
				errorEmail: 'Veuillez saisir une adresse email valide',
				errorTel: 'Veuillez saisir un numéro de téléphone valide',
				errorDefault: 'La valeur saisie n\'est pas valide'
			}
		};
		const emptyThankYouMessage = '<p style="text-align: center;">&nbsp;</p><p style="text-align: center;"><span style="color: #23b583;"><strong>Merci d\'avoir r&eacute;pondu &agrave; notre enqu&ecirc;te</strong></span><br /><span style="font-size: 15px;">Nous appr&eacute;cions beaucoup vos commentaires.</span></p><p style="text-align: center;">&nbsp;</p>';

		const slideFormValue = parsePropertyValue(dataToCheck.Properties);
		var formValue = isEmptyForm(slideFormValue)
			? { sections: [{ id: 1, panels: [], thanksSection: emptyThankYouMessage, styles: emptyStyle, css: getCSS(emptyStyle)}] }
			: {
				sections: slideFormValue.sections.map(function (section) {
					return {
						id: section.id,
						panels: section.panels.map(function (panel) {
							return {
								id: panel.id,
								name: panel.name,
								type: panel.type,
								question: panel.question,
								placeholder: panel.placeholder,
								lowScoreLabel: panel.lowScoreLabel,
								highScoreLabel: panel.highScoreLabel,
								controls: panel.controls,
								required: panel.required,
								showQuestion:panel.showQuestion,
								controlTypes: controlTypes,
								buttonType: panel.buttonType,
								value: panel.value,
								surveyInfos: panel.surveyInfos,
								valueForTest: panel?.valueForTest
							};
						}),
						thanksSection : section.thanksSection,
						styles : section.styles,
						css : section.css
					};
				})
			};

		const firstPanel = formValue.sections[0].panels;
		const elemenSection = formValue.sections[0].hasOwnProperty('thanksSection');
		const elemenStyles = formValue.sections[0].hasOwnProperty('styles');
		if(elemenSection){
			const elemenSectionValue = formValue.sections[0].thanksSection;
			setThankSectionValue(elemenSectionValue !== undefined ? elemenSectionValue : emptyThankYouMessage);
		}else{
			formValue.sections[0].thanksSection  = emptyThankYouMessage;
			setThankSectionValue(emptyThankYouMessage);
		}

		if(elemenStyles){
			const elemenSectionStyle= formValue.sections[0].styles ;
			setFormSurveyStyles(elemenSectionStyle !== undefined ? elemenSectionStyle : emptyStyle);
		}else{		
			setFormSurveyStyles(emptyStyle);
			formValue.sections[0].styles = emptyStyle;
		}

		if(firstPanel.length > 0){
			setpanelSelected(firstPanel[0]);
		}
					
		setState({ slide: dataToCheck });
		setState(formValue);
	}

	function updateFormCustomData(){
		const sections = state.sections.map(section => {
			return {
				id: section.id,
				panels: section.panels.filter(function (p) { return p.type !== ''; }).map(function (panel) {
					return {
						highScoreLabel: panel.highScoreLabel,
						lowScoreLabel: panel.lowScoreLabel,
						id: panel.id,
						name: panel.name,
						type: panel.type,
						question: panel.question,
						placeholder: panel.placeholder,
						controls: panel.controls,
						required: panel.required,
						showQuestion:panel.showQuestion,
						buttonType: panel.buttonType,
						value: panel.value,
						surveyInfos: panel.surveyInfos,
						valueForTest: panel?.valueForTest
					};
				})
			};
		}).filter(function (s) { return s.panels.length > 0; });
		setFormData(JSON.stringify({ sections: sections }));
	}

	useEffect(()=>{
		if(state){
			updateFormCustomData();
		}
	}, [state]);

	useEffect(()=>{
		if(data){
			onLoad(data);
		}
	}, [data]);

	return (
		<FormContext.Provider
			value={{
				panelSelected,
				updatePanel,
				listOfIdQuestion,
				setpanelSelected,
				formData,
				stepSurvey,
				stepSelected,
				setFormData,
				needUpdateState,
				formSurveyStyles,
				deleteAnswers,
				isLast,
				setisLast,
				setThankSectionValue,
				setFormSurveyStyles,
				data
			}}
		>
			{props.children}
		</FormContext.Provider>
	);
};

export default ContextFormAndSurveyProvider;
export { useFormContext };
