import React, { useState, useEffect } from 'react';
import {Panel, PanelHeader, PanelBody} from '../../../../Components/Panel/Panel';
import PanelArticle from '../../../../Components/PanelArticle.js';
import Radio from '../../../../Components/Radio.js';
import Btn from '../../../../Components/Btn.js';
import {List, ListItem} from '../../../../Components/List.js';
import InputCustom from '../../../../Components/InputCustom.js';
import {CheckboxList} from '../../../../Components/Checkbox/Checkbox';
import SectionMessage from '../../../../Components/SectionMessage';
import {useTranslation} from 'react-i18next';
import {dividePercent} from '../../util';

interface ILocalVariation {
	UId: string;
	Label?: string;
	RangeSize?: number;
	Tag?: string;
	IsOriginalVariation?: boolean;
}

export default function PanelRepartition({
	panelIsOpen,
	setpanelIsOpen,
	variations,
	setVariations,
	setvariationType,
	variationType,
}) {

	const { t } = useTranslation(['common']);
	const [repartitionValue, setRepartitionValue] = useState('');
	const [repartitionType, setRepartitionType] = useState('');
	const [localVariations, setLocalVariations] = useState<ILocalVariation[]>([]);
	const [hasRangeError, setHasRangeError] = useState(false);
	const [hasTagError, setHasTagError] = useState(false);

	function getRepartitionType(value) {
		if (value === 'PageTag') {
			return 'PageTag';
		}
		return 'Range';
	}

	function getVariationLabel(v) {
		if (v.DesignProperties
			&& v.DesignProperties[0]
			&& v.DesignProperties[0].Value
			&& v.DesignProperties[0].Value.Name) {
			return v.DesignProperties[0].Value.Name;
		}
		return '';
	}

	useEffect(()=>{
		setRepartitionValue(variationType);
	}, [variationType]);

	useEffect(()=>{
		const localVariations:ILocalVariation[] = variations.map((item) => {
			return {
				UId: item.UId,
				Label: getVariationLabel(item),
				RangeSize: item.RangeSize,
				Tag: item.Tag,
				IsOriginalVariation: item.IsOriginalVariation
			};
		});
		setLocalVariations(localVariations);
	}, [variations]);

	useEffect(()=>{
		setRepartitionType(getRepartitionType(repartitionValue));
	}, [repartitionValue]);

	function equalizeRanges() {
		const tmpVariations = [...localVariations];
		const ranges = dividePercent(100, tmpVariations.length);
		for (let i = 0; i < tmpVariations.length; i++) {
			const variation = tmpVariations[i];
			variation.RangeSize = ranges[i];
		}
		setLocalVariations(tmpVariations);
	}

	function handleChangeVariationRange(uid, value) {
		const tmpVariations = [...localVariations];
		let rest = 100;
		if (!value) value = 0;
		if (value > rest) value = rest;

		for (let i = 0; i < tmpVariations.length; i++) {
			const variation = tmpVariations[i];
			if (variation.UId === uid) {
				variation.RangeSize = value;
			}
			if (!variation.IsOriginalVariation) {
				rest -= variation.RangeSize || 0;
			}
		}

		if (rest < 0) {
			rest = 0;
			setHasRangeError(true);
		} else {
			setHasRangeError(false);
		}

		for (let i = 0; i < tmpVariations.length; i++) {
			const variation = tmpVariations[i];
			if (variation.IsOriginalVariation) {
				variation.RangeSize = rest;
				break;
			}
		}

		setLocalVariations(tmpVariations);
	}

	function handleChangeVariationTag(uid, value) {
		const tmpVariations = [...localVariations];
		for (let i = 0; i < tmpVariations.length; i++) {
			const variation = tmpVariations[i];
			if (variation.UId === uid) {
				variation.Tag = value;
				break;
			}
		}
		if (hasTagError) {
			setHasTagError(checkTagError());
		}
		setLocalVariations(tmpVariations);
	}

	function checkTagError() {
		if (repartitionType == 'PageTag') {
			for (let i = 0; i < localVariations.length; i++) {
				const variation = localVariations[i];
				if (!variation.IsOriginalVariation && !variation.Tag) {
					return true;
				}
			}
		}
		return false;
	}

	function handleValidate() {
		const hasTagError = checkTagError();
		setHasTagError(hasTagError);
		if (hasTagError) return;
		if (hasRangeError) return;

		const tmpVariations = variations.map((v) => {
			const variation = {...v};
			const localVariation = localVariations.find((lv) => lv.UId === v.UId);
			if (localVariation) {
				variation.UId = localVariation.UId;
				variation.RangeSize = localVariation.RangeSize;
				variation.Tag = localVariation.Tag;
				variation.VariationType = repartitionType;
			}
			return variation;
		});

		setVariations(tmpVariations);
		setvariationType(repartitionValue);
		setpanelIsOpen(false);
	}
	
	return (
		<Panel
			isOpen={panelIsOpen}
			onClose={() => setpanelIsOpen(false)}
			side="right"
			width={570}
		>
			<PanelHeader
				onClose={() => setpanelIsOpen(false)}
				title={t('abTest.trafficDistribution')}
				primaryAction={
					<Btn
						onClick={handleValidate}
						message={t('actions.ok')}
						disabled={hasRangeError}
					/>
				}
			/>
			<PanelBody color='grey'>
				<PanelArticle title={t('abTest.howDoYouWant')}>
					<CheckboxList removeVerticalMargin={true}>
						<Radio
							forcedChecked={repartitionValue === 'uniform'}
							onChange={()=>{setRepartitionValue('uniform'); equalizeRanges();}}
							label={t('abTest.evenDistribution')}
							description={t('abTest.evenDistributionDesc')}
							fullWidth={true}
						/>
						<Radio
							forcedChecked={repartitionValue === 'Manual'}
							onChange={()=>setRepartitionValue('Manual')}
							label={t('abTest.ManualDistribution')}
							description={t('abTest.ManualDistributionDesc')}
							fullWidth={true}
						/>
						<Radio
							forcedChecked={repartitionValue === 'PageTag'}
							onChange={()=>setRepartitionValue('PageTag')}
							label={t('abTest.TagDistribution')}
							description={t('abTest.TagDistributionDesc')}
							fullWidth={true}
						/>
					</CheckboxList>
				</PanelArticle>
				<PanelArticle title={t('abTest.variations')}>
					{hasRangeError &&
						<SectionMessage
							type="alert"
							text={'La somme ne peut pas dépasser 100%'}
							verticalSize="s"
						/>
					}
					{hasTagError &&
						<SectionMessage
							type="alert"
							text={'Le tag ne peut âs être vide.'}
							verticalSize="s"
						/>
					}
					<List removeVerticalMargin={true}>
						{localVariations && localVariations.map((item) =>
							<ListItem
								key={item.UId}
								text={item.Label}
								actions={
									<>
										{repartitionType == 'Range' && 
											<InputCustom
												onChange={(e) => handleChangeVariationRange(item.UId, e.target.value)}
												disabled={item.IsOriginalVariation || repartitionValue === 'uniform'}
												value={item.RangeSize}
												min={0}
												unit="%"
												type="number"
												className="w_xxs"
												placeholder="50"
											/>
										}
										{repartitionType == 'PageTag' &&
											<InputCustom
												onChange={(e) => handleChangeVariationTag(item.UId, e.target.value)}
												disabled={item.IsOriginalVariation}
												value={item.IsOriginalVariation ? '' : item.Tag}
												type="text"
												className="w_s"
												placeholder={item.IsOriginalVariation ? t('common.none') : t('abTest.pageTag')}
											/>
										}
									</>
								}
								textIsBold={false}
							/>
						)}
					</List>
				</PanelArticle>
			</PanelBody>
		</Panel>
	);
}
