import React from 'react';
import SelectListTolling from './SelectListTooling';
import './SelectCreationModeContainer.css';
import Btn from './../../Components/Btn';

export default function SelectCreationModeContainer({ listOfConfiguRationAccount, setAccountCurrentTab, setIsCreationMode, checkIfHasExistingConfig }) {

	const selectFiltered = listOfConfiguRationAccount && SelectListTolling.filter(elem => listOfConfiguRationAccount.includes(elem.compare));

	return (
		<div className="page_body">
			<div className="page_body_title">What kind of feature would you configure ?</div>
			<div className="select_list_tooling_Container">
				<ul className="select_list_tooling">
					{
						selectFiltered.map((opt, i) =>
							<li key={i} onClick={(e) => { setAccountCurrentTab(opt.id); setIsCreationMode(true); }}>{opt.label}</li>
						)
					}
				</ul>
				<div className="form_footer">
					<Btn
						message="Cancel"
						onClickFunction={() => { checkIfHasExistingConfig(); setIsCreationMode(false); }}
						color="secondary"
						style="outline"
					/>
				</div>
			</div>
		</div>
	);
}
