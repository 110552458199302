import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const Chart = ({
	data = { datasets: [], labels: [], title: '' },
	typeChart = 'areaspline',
}) => {

	const columnChartOptions = {
		
		exporting: {
			showTable: false,
			tableCaption: 'Data table',
			csv: {

				columnHeaderFormatter: function(item, key) {
					if (!item || item instanceof Highcharts.Axis) {
						return 'Date';
					} else {
						return item.name;
					}
				},
				dateFormat: '%Y-%m-%d'
			},
			buttons: {
				contextButton: {
					menuItems: [
						'printChart',
						'separator',
						'downloadPNG',
						'downloadJPEG',
						'downloadPDF',
						'downloadSVG',
						'downloadCSV'
					]
				}
			}
		},
		chart: {
			height: 410,
			type: typeChart,
		},
		navigation: {
			buttonOptions: {
				verticalAlign: 'top',
				x: -50,
			}
		},
		title: {
			text: ''
		},
		legend: {
			symbolRadius: 0,
			symbolWidth: 40,
			itemStyle: {
				fontFamily: 'Roboto',
				fontWeight: 'Light',
				left: '100px',
				top: '100px',
			},
			borderRadius: 3,
			align: 'center',
			verticalAlign: 'bottom',
		},
		credits: {
			enabled: false
		},
		xAxis:{
			gridLineWidth: 0,
			lineColor: 'black',
			lineWidth: 1,
			categories: [...data.labels.map(x => x)],
			tickLength: 10,
			tickWidth: 1,
		}
		,
		yAxis: [{ // Primary yAxis
			gridLineWidth: 0,
			labels: {
				format: '',
				style: {

					color: 'black',
				},
			},
			title: {
				text: '',
				style: {
					color: 'black',
				}
			},
			alignTicks: false,
			opposite: false,
			crosshair: true

		}],
		plotOptions: {
			column: {
				borderColor: '#9E9E9E',
				grouping: true,
				shadow: false,
				borderWidth: 0,
				
			},
			series: {
				marker: {
					fillColor: '#FFF',
					lineWidth: 1,
					lineColor: null // inherit from series
				}
			},
			areaspline: {
				fillOpacity: 0.3
			}
		},
		opposite: true,
		tooltip:
		{
			shared: true,
			backgroundColor: '#38444A',
			color: '#FFF',
			style: {
				color: '#FFFFFF',
			},
		},
		series: data.datasets
	};

	return (
		<HighchartsReact
			className='chart'
			highcharts={Highcharts}
			options={columnChartOptions}
			constructorType={'chart'}
		/>);

};

export default Chart;