import React from 'react';
import Confirm from '../../../Components/Confirm';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import { useSegmentGroupEditorContext } from './context/SegmentGroupEditorContainerProvider';
import Header from './Header/Header';

export default function SegmentGroupEditor({ children }) {

	const {
		isSaving,
		shouldSavePersonaError,
		shouldSetSaveModeError,
		handleOverwrite,
		handleDuplicate,
		setShouldSavePersonaError,
		setIsSaving,
		
	} = useSegmentGroupEditorContext();


	return (
		<div className='campaign_editor flex flex_v'>
			<div className='flex_item_fix'>
				<Header/>
			</div>
			<div className='campaign_editor_body campaign_editor_body_grey flex_item_full flex flex_v'>
				<div className='flex_item_full vscroll'>{children}</div>
				{isSaving &&
					<SpinnerWheel wheelSize="l" />
				}
			</div>
			<Confirm
				isOpen={shouldSavePersonaError}
				title={'All segments must be saved'}
				text={'Please save all segments as savorite before saving segmentation'}
				confirmText={'OK'}
				confirmColor="alert"
				confirmCallback={()=>{setShouldSavePersonaError(false);setIsSaving(false)}}
				cancelCallback={()=>{setShouldSavePersonaError(false);setIsSaving(false)}}
			/>
			<Confirm
				isOpen={shouldSetSaveModeError}
				title={'Overwrite or duplicate?'}
				text={'Do you want to save (overwrite) this segmentation or create a new one from these segments?'}
				cancelText={'Overwrite'}
				cancelCallback={handleOverwrite}
				confirmText={'OK'}
				confirmCallback={handleDuplicate}
				confirmColor="alert"
			/>
		</div>
	);
}