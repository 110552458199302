import React from 'react';
import formatMoment from '../../Constants/FormatMoment';

export default function CampaignItem({
	campaign,
	handleOnClickCampaignIdToLoad,
	campaignId,
	shouldDisplayStatus
}) {
	const { formatDateToLocal } = formatMoment;
	const isTestingMode = campaign.ExecutionMode == 2 ? true : false;
	const today = moment();
	const dateBeginOfOperation = formatDateToLocal(campaign.StartDate);
	const dateEndOfOperation = campaign.EndDate && formatDateToLocal(campaign.EndDate);
	const isStoppedBoolOperation = campaign.IsStopped;
	const isTestModeOperationBool = isTestingMode;

	let statusOperation = {};

	const setStatusOperation = () => {
		if (isStoppedBoolOperation) {
			statusOperation = { id: 'Stopped', label: 'Stopped', cssClass: 'status-stopped' };
			return;
		}

		if (isTestModeOperationBool) {
			statusOperation = { id: 'testing', label: 'Draft', cssClass: 'status-testing' };
			return;
		}

		if (campaign.EndDate && dateEndOfOperation < today) {
			statusOperation = { id: 'past', label: 'Past', cssClass: 'status-past' };
			return;
		}

		if (dateBeginOfOperation > today) {
			statusOperation = { id: 'planned', label: 'Scheduled', cssClass: 'status-planned' };
			return;
		}

		statusOperation = { id: 'ongoing', label: 'Ongoing', cssClass: 'status-ongoing' };
	};

	setStatusOperation();
    
	return (
		<li key={campaign.Key} onClick={() => handleOnClickCampaignIdToLoad()}>
			<a className={campaignId === campaign.Key ? 'page_sidebar_list_item selected' : 'page_sidebar_list_item'}>
				{shouldDisplayStatus &&
					<span className={'status_bullet ' + statusOperation.cssClass}></span>
				}
				{campaign.Name}
			</a>
		</li>
	);
}
