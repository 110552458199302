import React from 'react';
import {Article} from '../../../Components/Article/Article';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import EmptyState from '../../../Components/EmptyState';
import { devices, pageTypes, engagementLevel} from './config';
import { useTranslation } from 'react-i18next';
import {getFormattedNumber} from '../Utils';
import styles from './Card.module.css';

const Card = ({
	title = '',
	dataCard = {
		data: [],
		loading: true,
		isError: false,
		totalSum: 0
	},
	dimensionKey,	
}) => {

	const [t] = useTranslation(['utils', 'kpi']);
	const maxNumberOfLines = 25;
	const data = dataCard.data || [];
	const isLoading = dataCard.loading;
	const isError = dataCard.isError;
	const hasClick = (typeof handleClick === 'function');

	data.sort(function(a, b){
		var va = a.turnover;
		var vb = b.turnover;
		if (va > vb) return -1;
		if (va < vb) return 1;
		return 0;
	});

	const onHandleClick = (type) => () => {
		if (!hasClick) return;
	};

	function getTranslatedValue(config, value, translationParentKey) {
		const valueConfig = config.find(el => el.value.toLowerCase() === value.toLowerCase());
		if (!valueConfig) return value; 

		if (valueConfig.translation) {
			return t(valueConfig.translation.key, valueConfig.translation.vars);
		} else {
			return t(translationParentKey + '.' + valueConfig.label, {ns: 'kpi'});
		}
	}

	function getLabelByType (type, element) {
		if(type === 'Device'){
			return getTranslatedValue(devices, element, 'device');
		}
		if(type == 'PageType'){
			return getTranslatedValue(pageTypes, element, 'pageType');
		}
		if(type == 'EngagementLevel'){
			return getTranslatedValue(engagementLevel, element, 'engagementLevel');
		}
	}

	return (
		<div className={styles.column}>
			<Article fullHeight={true}>
				<div className={styles.head}>
					<span className={styles.head_title}>{title}</span>
				</div>
				<div className={styles.body}>
					{isLoading && (
						<SpinnerWheel />
					)}
					{!isLoading && !isError && data.length === 0 && (
						<EmptyState
							imageUrl="/Assets/empty_no_data.svg"
							imageWidth={80}
							title={t('messages.noData')}
							titleBold={false}
							verticalAlign={true}
							textSize="s"
						/>
					)}
					{!isLoading && isError && (
						<EmptyState
							icon="fas fa-exclamation-triangle"
							title={t('messages.UnableToFetchData')}
							titleBold={false}
							verticalAlign={true}
							textSize="s"
						/>
					)}

					{data.slice(0, maxNumberOfLines).map((value, index) => {
					
						function caltulateRate(total) {
							return total * 100 / dataCard.totalSum || 0;
						}
						const nameOfMetricToRender = value.turnover.toFixed(2);
						const percentage = caltulateRate(value.turnover.toFixed(2));
						return (
							<div
								key={index}
								className={hasClick ? styles.line_clickable : styles.line}
								onClick={onHandleClick(dimensionKey)}
							>
								<div className={styles.progress} style={{ width: percentage.toFixed(1) + '%' }}></div>
								<div className={styles.colScreen}>
									<span className={styles.number}>{(index + 1) + '. '}</span>
									{getLabelByType(dimensionKey, value.dimension[dimensionKey])}
								</div>
								<div className={styles.colPercent}>
									{percentage.toFixed(1) + '%'}
								</div>
								<div className={styles.colView}>
									{getFormattedNumber(nameOfMetricToRender)}
								</div>
							</div>
						);
					})}
				</div>
			</Article>
		</div>
	);
};

Card.displayName = 'Card';

export default Card;