import React from 'react';
import styles from './Card.module.css';
import useCardIcon from '../../hooks/useCardIcon';

const Card = ({
	title = 'Devices',
	dataCard = {
		data: [],
		loading: true,
		isError: false,
		totalCount: 0
	},
	handleClick,
	handleEnlarge,
	isLarge = false,
	dimensionKey,
	ComparisonSelected,
	metricTypeSessionsSelected,
	totalsPeriod1,
	totalsPeriode2,
	chips
}) => {
	const maxNumberOfLines = 5;
	const data = dataCard.data || [];
	const isLoading = dataCard.loading;
	const isError = dataCard.isError;
	const totalCount = dataCard.totalCount;

	const onHandleClick = (type) => () => {
		handleClick(dimensionKey, type);
	};

	const onViewMoreClick = () => {
		handleEnlarge();
	};
	function getPercentAndNameToRender(isVariation, isCompare) {
		if (!isCompare) {
			if (!isVariation) {
				return getNameOfMetric();
			}
		} else {
			if (!isVariation) {
				return getNameOfMetric();
			} else if (isVariation) {
				return getNameOfMetricVariation();
			}
		}
	}
	function getNameOfMetric() {
		switch (metricTypeSessionsSelected) {
			case 'sessionsAndPagesViews':
			case 'NumberOfSessions':
				return 'NumberOfSessions';
			case 'NumberOfPageViews':
				return 'NumberOfPageViews';
			case 'AverageTimeOnPage':
				return 'DurationSeconds';

			case 'BounceRate':
				return 'NumberOfBounces';
			default:
				break;
		}
	}
	function getNameOfMetricVariation() {
		switch (metricTypeSessionsSelected) {
			case 'sessionsAndPagesViews':
			case 'NumberOfSessions':
				return 'NumberOfSessionsPercentVariation';
			case 'NumberOfPageViews':
				return 'NumberOfPageViewsPercentVariation';
			case 'AverageTimeOnPage':
				return 'DurationSecondsPercentVariation';
			case 'BounceRate':
				return 'NumberOfBouncesPercentVariation';
			default:
				break;
		}
	}
	function getValueOfMetricInTotal() {
		switch (metricTypeSessionsSelected) {
			case 'sessionsAndPagesViews':
			case 'NumberOfSessions':
				return 'numberOfSessions';
			case 'NumberOfPageViews':
				return 'numberOfPageViews';
			case 'AverageTimeOnPage':
				return 'durationSeconds';
			case 'BounceRate':
				return 'numberOfBounces';
			default:
				break;
		}
	}
	const utmSourceTitle = 'UTM Source (or gclid)';
	return (
		<div className={isLarge ? styles.largeContainer : styles.container}>
			<div className={styles.titleContainer}>
				<span className={styles.title}>{title}</span>
			</div>
			<div className={styles.cardContainer}>
				{isLoading && (
					<div className="mask">
						<div className="wheel"></div>
					</div>
				)}
				{!isLoading && !isError && data.length === 0 && (
					<div
						className={styles.screenContainer}
					>
						<p className={styles.screen}>No data</p>
						<p className={styles.view}>-</p>
					</div>
				)}
				{!isLoading && isError && (
					<div
						className={styles.screenContainer}
					>
						<p className={styles.screen}>Cannot fetch data</p>
						<p className={styles.view}></p>
					</div>
				)}

				{!ComparisonSelected && data.slice(0, maxNumberOfLines).map((value, index) => {
					// console.log("🚀 ~ file: Card.js ~ line 74 ~ {data.slice ~ value", value)
					const { Dimensions: {
						HostName,
						Timezone,
						Language,
						LandingPage,
						LandingPageType,
						ExitPage,
						ExitPageType,
						UtmMedium,
						UtmSource,
						UtmCampaign,
						UtmContent,
						Referrer,
						ReferrerSource,
						ReferrerMedium,
						TrafficSource,
						CountryCode,
						OperatingSystem,
						OperatingSystemVersion,
						Browser,
						BrowserVersion,
						ScreenSize,
						Device,
						Subdivision
					}, NumberOfSessions,
					NumberOfPageViews,
					NumberOfBounces,
					DurationSeconds
					} = value;

					const name =
						HostName ||
						Timezone ||
						Language ||
						LandingPage ||
						LandingPageType ||
						ExitPage ||
						ExitPageType ||
						UtmMedium ||
						UtmSource ||
						UtmCampaign ||
						UtmContent ||
						Referrer ||
						ReferrerSource ||
						ReferrerMedium ||
						TrafficSource ||
						CountryCode ||
						OperatingSystem ||
						OperatingSystemVersion ||
						Browser ||
						BrowserVersion ||
						ScreenSize ||
						Subdivision || 
						Device;
					const metricType = getValueOfMetricInTotal();

					function caltulateRate(total) {
						return valuesOfMetrics[getPercentAndNameToRender(false,
							true)] * 100 / total[metricType] || 0;
					}

					const isSelected = chips.length !== 0 ? chips[0].title === name : false;
					const icon = useCardIcon(dimensionKey, name);

					const valuesOfMetrics = {
						NumberOfSessions: NumberOfSessions,
						NumberOfPageViews: NumberOfPageViews,
						NumberOfBounces: NumberOfBounces,
						DurationSeconds: DurationSeconds
					};
					const nameOfMetricToRender = valuesOfMetrics[getPercentAndNameToRender(false, false)];
					const percentage = caltulateRate(totalsPeriod1);
					return (
						<div
							key={index}
							className={[styles.screenContainer, isSelected ? styles.isSelected : ''].join(' ')}
							onClick={onHandleClick(name && name !== '' ? name : 'empty')}
						>
							<div className={styles.progress} style={{ width: percentage.toFixed(1) + '%' }}></div>
							<p className={styles.colScreen}>
								<span className={styles.number}>{(index + 1) + '. '}</span>
								{icon &&
									<span className={styles.icon}>
										<i className={icon}></i>
									</span>
								}
								{name && name !== '' ? name : 'Empty'}
							</p>
							<p className={styles.colPercent}>
								{percentage.toFixed(1) + '%'}
							</p>
							<p className={styles.colView}>
								{nameOfMetricToRender}
							</p>
						</div>
					);
				})}

				{ComparisonSelected && data.slice(0, maxNumberOfLines).map((value, index) => {
					// console.log("🚀 ~ file: Card.js ~ line 74 ~ {data.slice ~ value", value)
					const { Dimensions: {
						HostName,
						Timezone,
						Language,
						LandingPage,
						LandingPageType,
						ExitPage,
						ExitPageType,
						UtmMedium,
						UtmSource,
						UtmCampaign,
						UtmContent,
						Referrer,
						ReferrerSource,
						ReferrerMedium,
						TrafficSource,
						CountryCode,
						OperatingSystem,
						OperatingSystemVersion,
						Browser,
						BrowserVersion,
						ScreenSize,
						Device
					}, NumberOfSessionsPeriod1,
					NumberOfSessionsPeriod2,
					NumberOfPageViewsPeriod1,
					NumberOfPageViewsPeriod2,
					DurationSecondsPeriod1,
					DurationSecondsPeriod2,
					NumberOfBouncesPeriod1,
					NumberOfBouncesPeriod2,
					DurationSecondsPercentVariation,
					NumberOfPageViewsPercentVariation,
					NumberOfBouncesPercentVariation,
					NumberOfSessionsPercentVariation } = value;

					const name =
						HostName ||
						Timezone ||
						Language ||
						LandingPage ||
						LandingPageType ||
						ExitPage ||
						ExitPageType ||
						UtmMedium ||
						UtmSource ||
						UtmCampaign ||
						UtmContent ||
						Referrer ||
						ReferrerSource ||
						ReferrerMedium ||
						TrafficSource ||
						CountryCode ||
						OperatingSystem ||
						OperatingSystemVersion ||
						Browser ||
						BrowserVersion ||
						ScreenSize ||
						Device;
					const valuesOfMetrics = {
						NumberOfSessionsPeriod1: NumberOfSessionsPeriod1,
						NumberOfSessionsPeriod2: NumberOfSessionsPeriod2,
						NumberOfPageViewsPeriod1: NumberOfPageViewsPeriod1,
						NumberOfPageViewsPeriod2: NumberOfPageViewsPeriod2,
						DurationSecondsPeriod1: DurationSecondsPeriod1,
						DurationSecondsPeriod2: DurationSecondsPeriod2,
						NumberOfBouncesPeriod1: NumberOfBouncesPeriod1,
						NumberOfBouncesPeriod2: NumberOfBouncesPeriod2,
						DurationSecondsPercentVariation: DurationSecondsPercentVariation,
						NumberOfPageViewsPercentVariation: NumberOfPageViewsPercentVariation,
						NumberOfBouncesPercentVariation: NumberOfBouncesPercentVariation,
						NumberOfSessionsPercentVariation: NumberOfSessionsPercentVariation
					};
					const isBounce = metricTypeSessionsSelected === 'BounceRate';
					const isSelected = chips.length !== 0 ? chips[0].title === name : false;
					const icon = useCardIcon(dimensionKey, name);

					const metricType = getValueOfMetricInTotal();

					function calculateRate(period, total) {
						return valuesOfMetrics[getPercentAndNameToRender(false,
							true) + period] * 100 / total[metricType] || 0;
					}

					const percentagePeriod1 = calculateRate('Period1', totalsPeriod1);

					const percentagePeriod2 = calculateRate('Period2', totalsPeriode2);

					const percentVariation = isBounce 
						? (percentagePeriod1 - percentagePeriod2 / percentagePeriod2) || 0
						: valuesOfMetrics[getPercentAndNameToRender(true, true)] || 0;

					const valueMetricPeriode1 = valuesOfMetrics[getPercentAndNameToRender(false, true) + 'Period1'] || 0;

					const valueMetricPeriode2 = valuesOfMetrics[getPercentAndNameToRender(false, true) + 'Period2'] || 0;

					const tendance = percentVariation;
					let tendanceClass = 'tendance_flat';
					if (tendance > 0) {
						tendanceClass = 'tendance_up';
					} else if (tendance < 0) {
						tendanceClass = 'tendance_down';
					}

					return (
						<div
							key={index}
							className={[styles.screenContainerDouble, isSelected ? styles.isSelected : ''].join(' ')}
							onClick={onHandleClick(name && name !== '' ? name : 'empty')}
						>
							<div className={styles.progressTop} style={{ width: percentagePeriod1.toFixed(1) + '%' }}></div>
							<div className={styles.progressBottom} style={{ width: percentagePeriod2.toFixed(1) + '%' }}></div>
							<div className={styles.colScreen}>
								<span className={styles.number}>{(index + 1) + '. '}</span>
								{icon &&
									<span className={styles.icon}>
										<i className={icon}></i>
									</span>
								}
								{name && name !== '' ? name : 'Empty'}
							</div>
							<div className={styles.colPercent}>
								<div className={styles.colInnerTop}>{percentagePeriod1.toFixed(1) + '%'}</div>
								<div className={styles.colInnerBottom}>{percentagePeriod2.toFixed(1) + '%'}</div>
							</div>
							<div className={styles.colView}>
								<div className={styles.colInnerTop}>{valueMetricPeriode1}</div>
								<div className={styles.colInnerBottom}>{valueMetricPeriode2}</div>
							</div>
							<div className={styles.colDiff}>
								<span className={tendanceClass}>
									{percentVariation.toFixed(1) + '%'}
								</span>
							</div>
						</div>
					);
				})}

			</div>
			{totalCount > maxNumberOfLines && (
				<div className={styles.viewMoreButton}>
					<span onClick={onViewMoreClick}>
						{title === utmSourceTitle && 'View all UTM Sources (or gclid)' }
						{title !== utmSourceTitle && `View all ${title}` + (title[title.length - 1] === 's' ? '' : 's')}
					</span>
				</div>
			)}
		</div>
	);
};

Card.displayName = 'Card';

export default Card;