import React, { useState } from 'react'

export default function DemotionList({ addDemotedProductManualy }) {
	const [valueToModify, setValueToModify] = useState('');

	const handleChange = (event) => {
		let textAreaValue = { ...valueToModify }
		textAreaValue = event.target.value
		setValueToModify(textAreaValue)
		addDemotedProductManualy(textAreaValue)
	}

	return (
		<div>
			<textarea className='textAreaSku' placeholder="Type product ids here (line break or semi-colon separated)"
				value={valueToModify && valueToModify} onChange={handleChange} />
		</div>

	)
}
