import React, {useState} from 'react';
import {Checkbox} from '../../Components/Checkbox/Checkbox';
import InputCustom from '../../Components/InputCustom';
import IconBtn from '../../Components/IconBtn';
import styles from './ChatBot.module.css';
import {useTranslation} from 'react-i18next';

const questionMaxLength = 2000;

type IField = {
	handleSendMessage?: any;
	isLoadingAnswer?: boolean;
	errorNetwork? : boolean;
	onFocus : ()=> void ;
}

export default function Field({
	handleSendMessage,
	isLoadingAnswer,
	errorNetwork = false,
	onFocus
}:IField) :JSX.Element {

	const [t] = useTranslation('chatbot');
	const [currentmessage, setCurrentmessage] = useState<string>('');
	const [fieldClass, setFieldClass] = useState(styles.field_wrapper);
	const [submitWithEnter, setSubmitWithEnter] = useState<boolean>(true);

	function toggleFieldFocus(hasFocus) {
		if(hasFocus){
			onFocus();
		}
		setFieldClass(hasFocus ? styles.field_wrapper_focus : styles.field_wrapper);
	}

	function handleSubmit() {
		handleSendMessage(currentmessage);
		setCurrentmessage('');
	}

	function onEnter(ev) {
		if(errorNetwork) return;
		if (!submitWithEnter) return true;
		ev.preventDefault();
		handleSubmit();
	}

	return (
		<div className={fieldClass}>
			<InputCustom
				disabled={errorNetwork}
				type="textarea"
				onChange={(e) => setCurrentmessage(e.target.value)}
				placeholder={t('chatbot.askMeAQuestion')}
				value={currentmessage}
				onEnter={onEnter}
				onFocus={() => toggleFieldFocus(true)}
				onBlur={() => toggleFieldFocus(false)}
				autoFocus={true}
				autogrow={true}
				maxHeight={300}
				rows={2}
				style="ghost"
				fullWidth={true}
				hasEffect={false}
				size="l"
			/>
			<div className={styles.field_footer}>
				<div className='flex'>
					<div className='flex_item_full'>
						<Checkbox
							checkboxSize="s"
							forcedChecked={submitWithEnter}
							onChange={(e) => setSubmitWithEnter(e.target.checked)}
						>
							<span className='s_13 grey_1'>{t('chatbot.submitWithEnter')}</span>
						</Checkbox>
					</div>
					<div className='flex_item_fix'>
						<span className='s_14 grey_3'>
							{currentmessage.length}/{questionMaxLength}
						</span>
					</div>
					<div className='flex_item_fix'>
						<IconBtn
							icon="fas fa-paper-plane"
							onClick={handleSubmit}
							disabled={isLoadingAnswer || currentmessage.length == 0 || currentmessage.length > questionMaxLength}
							isActive={true}
							hoverColor="primary"
							size="xxl"
							tooltip={t('chatbot.sendQuestion')}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}