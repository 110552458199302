import React from 'react';
import './ProductCard.css';

const ProductCard = (state) => {
    const {product}= state;
    const {id, name, url, urlThumbnail, attributes} = product;
    const price = getPrice(attributes);
    return(
        <div className="productCard_container" onClick={()=> window.open(url,'_blank')}>
            <div className="productCard_image_wrapper">
                <div
                    style={{backgroundImage:`url('${urlThumbnail}')`}}
                    className="productCard_image"
                />
            </div>
            <h2 className="s_14 fw_medium mb_5">{name}</h2>
            <p className="s_14 fw_normal mb_5">{`${price} €`}</p>
            <p className="s_12 fw_normal grey_2">{id}</p>   
        </div>
    )
}

function getPrice(attributes) {
    const priceArray = attributes.filter(a => a.id === 'Selling Price');
    return priceArray[0].value;
}

export default ProductCard;