import React from 'react';
import Chip from '../Chip/Chip';

export const ChipContainer = ({ styles, chips, onDelete }) => {
	if (!chips || chips.length == 0) return '';
	function onDeleteChip(chip) {
		onDelete(chip);
	}
	return (
		<>
			{chips.map((chip, index) => (
				<Chip
					key={index}
					type={chip.type}
					title={chip.title}
					handleDelete={()=>onDeleteChip(chip)}
				/>
			))}
		</>
	);
};
