import React from 'react';

function Listbox({
	children,
	hasBorder = false,
	className = ''
}) {

	let cssClass = ['listbox'];

	// className
	if (className) {
		cssClass = cssClass.concat(className);
	}

	// Border
	if (hasBorder) {
		cssClass.push('has_border');
	}

	return (
		<ul className={cssClass.join(' ')}>
			{children}
		</ul>
	);
}
export default React.memo(Listbox);