import React, { useContext } from 'react';
import { BusinessOperationOrchestrationContext } from '../Context';

const Zone = ({ zone }) => {
	const {
		handleZoneClicked,
	} = useContext(BusinessOperationOrchestrationContext);

	const zoneClicked = (e) => {
		e.stopPropagation();
		handleZoneClicked(zone);
	};

	return (
		<div
			className={zone.isSelected ? 'customizeZone selected' : 'customizeZone'}
			style={zone.style}
			onClick={zoneClicked}
		>
			<div className="customizeZoneText">{zone.zoneExtensions.Coordinates.width}x{zone.zoneExtensions.Coordinates.height}</div>
		</div >
	);
};

export default Zone;