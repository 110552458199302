import React from 'react';
export default function useCardIcon(dimension, value) {

    if (!value) return;

    value = value.toLowerCase();

    if (dimension == "Device") {
        if (value == "desktop") {
            return "fas fa-desktop";
        }
        if (value == "mobile") {
            return "fas fa-mobile-alt";
        }
        if (value == "tablet") {
            return "fas fa-tablet-alt";
        }
    }

    if (dimension == "Browser") {
        if (value.indexOf("firefox") > -1) {
            return "fab fa-firefox";
        }
        if (value == "IE") {
            return "fab fa-internet-explorer";
        }
        if (value.indexOf("safari") > -1) {
            return "fab fa-safari";
        }
        if (value.indexOf("edge") > -1) {
            return "fab fa-edge";
        }
        if (value.indexOf("chrome") > -1) {
            return "fab fa-chrome";
        }
        if (value.indexOf("google") > -1) {
            return "fab fa-google";
        }
        if (value.indexOf("facebook") > -1) {
            return "fab fa-facebook";
        }
        if (value.indexOf("opera") > -1) {
            return "fab fa-opera";
        }
        if (value.indexOf("amazon") > -1) {
            return "fab fa-amazon";
        }
        if (value.indexOf("pinterest") > -1) {
            return "fab fa-pinterest";
        }
        if (value.indexOf("yandex") > -1) {
            return "fab fa-yandex";
        }

        return "fas fa-globe";
    }

    if (dimension == "OperatingSystem") {
        if (value.indexOf("windows") > -1) {
            return "fab fa-windows";
        }
        if (value.indexOf("android") > -1) {
            return "fab fa-android";
        }
        if (value.indexOf("mac os") > -1) {
            return "fab fa-apple";
        }
        if (value.indexOf("ios") > -1) {
            return "fab fa-apple";
        }
        if (value.indexOf("lunix") > -1) {
            return "fab fa-lunix";
        }
        if (value.indexOf("fedora") > -1) {
            return "fab fa-fedora";
        }
        if (value.indexOf("ubuntu") > -1) {
            return "fab fa-ubuntu";
        }
        if (value.indexOf("chrome") > -1) {
            return "fab fa-chrome";
        }

        return "fas fa-desktop";
    }

    return null;
}
