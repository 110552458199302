import React from "react";
import "./CheckBoxCard.css";
import Checkbox from  "./../../../../Components/Checkbox";

export default function CheckBoxCard({
    label,
    desc,
    name,
    icon,
    checked,
    change,
    disabled
}) {

    const cssClass = ['ob_CheckBoxCard'];

    if( disabled ){
        cssClass.push('disabled');
    }

  return (
    <div className={cssClass.join(' ')}>
        <Checkbox
            name={name}
            checked={checked}
            disabled={disabled}
            onChange={()=>{change(name)}}
            fullWidth={true}
        >
            <div className="flex">
                <div className="flex_item_full">
                    <div className="ob_CheckBoxCard_title fw_medium">
                        {label}
                        {disabled &&
                            <a className="ob_CheckBoxCard_badge">Contact us</a>
                        }
                    </div>
                    <div className="ob_CheckBoxCard_desc s14">{desc}</div>
                </div>
                <div className="ob_CheckBoxCard_image flex_item_fix">
                    <span className={"pack_icon pack_icon_" + icon}></span>
                </div>
            </div>
        </Checkbox>
    </div>
  );
}
