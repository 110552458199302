import React, {ChangeEvent, useState, useEffect} from 'react';
import InputCustom from '../../../../Components/InputCustom.js';
import {Panel, PanelBody, PanelHeader} from '../../../../Components/Panel/Panel';
//import {Panel, PanelBody, PanelHeader} from 'ui-beyable';
import PanelArticle from '../../../../Components/PanelArticle.js';
//import {Radio} from '../../../../Components/Checkbox/Checkbox.js';
import Btn from '../../../../Components/Btn.js';
import IconBtn from '../../../../Components/IconBtn.js';
import {List, ListItem} from '../../../../Components/List.js';
import Fieldset from '../../../../Components/Fieldset.js';
import Link from '../../../../Components/Link.js';
import {useTranslation} from 'react-i18next';
import {isAnUrl} from '../../util.js';

export default function PanelInformationsVariation({
	panelIsOpen,
	setpanelIsOpen,
	// handleValidForm,
	variationPropertyObject,
	//handleChangeVariationPropertyType,
	handleChangeVariationPropertyObject,
	campaign
}) {

	const { t } = useTranslation(['common']);
	const name = variationPropertyObject?.Value?.Name;
	const [url, setUrl] = useState('');
	const [urlIsValid, seturlIsValid] = useState(false);
	const [urlError, setUrlError] = useState<string | any>('');

	useEffect(()=>{
		setUrl(variationPropertyObject?.Value?.RedirectionUrl);
		setUrlError(false);
	},[panelIsOpen]);

	useEffect(()=>{
		const isValid = isAnUrl(url);
		seturlIsValid(isValid);
	},[url]);

	function handleChangeUrl (e: ChangeEvent<HTMLInputElement>){
		setUrl(e.target.value);
	}

	function handleFormValidation() {
		let finalUrl = url;
		if (
			finalUrl.indexOf('http://') === -1 &&
			finalUrl.indexOf('https://') === -1
		) {
			finalUrl = 'https://' + finalUrl;
		}

		const isValid = isAnUrl(finalUrl);
		if (!isValid) {
			setUrlError(t('abTest.noVariationURL'));
			return;
		}

		if (campaign.UrlDebug && campaign.UrlDebug === finalUrl) {
			setUrlError(t('abTest.URLs cannot be identical'));
			return;
		}
		setUrlError('');
		handleChangeVariationPropertyObject({target: {
			name: 'RedirectionUrl',
			value: finalUrl
		}});
	}
	
	return (
		<Panel
			isOpen={panelIsOpen}
			onClose={() => setpanelIsOpen(false)}
			side="right"
			width={730}
		>
			<PanelHeader
				title={t('informations.variation')}
				onClose={() => setpanelIsOpen(false)}
				primaryAction={
					<Btn
						onClickFunction={() => handleFormValidation()}
						message={t('actions.ok')}
						disabled={!url}
						tooltip={!url ? t('abTest.noVariationURL') : null}
					/>
				}
			/>
			
			<PanelBody color='grey'>
				<PanelArticle size="l">
					<InputCustom
						label={t('abTest.variationName')}
						name="Name"
						onChange={(e: ChangeEvent<HTMLInputElement> )=> handleChangeVariationPropertyObject(e)}
						value={name || ''}
						type="text"
						placeholder={t('informations.urlDebug')}
						fullWidth={true}
					/>
				</PanelArticle>
				<PanelArticle size="l">
					{/* <Fieldset label={t('abTest.redirectionType')} blockClassName="l">
						<div className='mt_8'>
							<Radio
								forcedChecked={variationPropertyObject.Value.RedirectionType.value === 'unique'}
								onChange={()=>handleChangeVariationPropertyType('unique')}
								label={t('abTest.redirectionTypeUnique')}
								fullWidth="true"
							/>
							<Radio
								forcedChecked={variationPropertyObject.Value.RedirectionType.value === 'advanced'}
								onChange={()=>handleChangeVariationPropertyType('advanced')}
								label={
									<>
										{t('abTest.redirectionTypeAdvanced')}
										<small><em> (comming soon)</em></small>
									</>
								}
								fullWidth="true"
								disabled={true}
							/>
						</div>
					</Fieldset> */}

					<InputCustom
						label={t('abTest.redirectionURL')}
						name="RedirectionUrl"
						onChange={(e: ChangeEvent<HTMLInputElement> )=> handleChangeUrl(e)}
						value={url || ''}
						type="text"
						placeholder={t('informations.urlDebug')}
						fullWidth={true}
						blockClassName="l"
						isAnError={urlError}
					/>

					<Fieldset label={'Preview'} blockClassName="l">
						<List hasBorder={false} size="s">
							<ListItem>
								<div className="grey_2 s_13">{t('abTest.origin')}</div>
								<div className='flex'>
									<div className='flex_item_auto'>
										{campaign.UrlDebug ? 
											<Link
												icon="fas fa-external-link-square-alt"
												iconPosition="after"
												message={campaign.UrlDebug}
												href={campaign.UrlDebug || ''}
												target="_blank"
												rel="noreferrer"
												strong={false}
												disabled={!isAnUrl(campaign.UrlDebug)}
												className="ellips"
											/>
											:
											<em>{t('abTest.noReferencePage')}</em>
										}
									</div>
									{campaign.UrlDebug &&
										<div className='flex_item_fix'>
											<IconBtn
												icon="far fa-clone"
												disabled={!isAnUrl(campaign.UrlDebug)}
												size="s"
												onClick={() => setUrl(campaign.UrlDebug)}
											/>
										</div>
									}
								</div>
							</ListItem>
							<ListItem>
								<div className="grey_2 s_13">{t('abTest.redirection')}</div>
								<div className='flex'>
									<div className='flex_item_auto'>
										{urlIsValid ?
											<Link
												icon="fas fa-external-link-square-alt"
												iconPosition="after"
												message={url}
												href={url || ''}
												target="_blank"
												rel="noreferrer"
												disabled={!urlIsValid}
												strong={false}
												className="ellips"
											/>
											:
											<em>{t('abTest.noVariationURL')}</em>
										}
									</div>
								</div>
							</ListItem>
						</List>
					</Fieldset>
				</PanelArticle>
			</PanelBody>
		</Panel>
	);
}
