import React, { useState, useEffect, Fragment } from "react";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export default function SingleBarChart({ data, isLoadingData, hasErrorData }) {
	if (!data) {
		return <>
			<div className="mask">
				<span className="wheel"></span>
			</div>
		</>;
	};
	const options = {
		exporting: {
			showTable: false,
				tableCaption: 'Data table',
				csv: {

					columnHeaderFormatter: function(item, key) {
						if (!item || item instanceof Highcharts.Axis) {
							return 'Date'
						} else {
							return item.name;
						}
					},
					dateFormat: '%Y-%m-%d'
				},
			buttons: {
				contextButton: {
					menuItems: [
						'printChart',
						'separator',
						'downloadPNG',
						'downloadJPEG',
						'downloadPDF',
						'downloadSVG',
						'downloadCSV'
					]
				}
			}
		},
		chart: {
			height: 300,
			type: 'areaspline',
			// zoomType: 'x',
		},
		navigation: {
			buttonOptions: {
				verticalAlign: 'top',
				x: -50,
			}
		},
		title: {
			text: ''
		},
		legend: {
			symbolRadius: 0,
			symbolWidth: 40,
			itemStyle: {
				fontFamily: 'IBM Plex Sans',
				fontWeight: 'Light',
				left: '100px',
				top: '100px',
			},
			borderRadius: 3,
			align: 'center',
			verticalAlign: 'bottom',
		},
		credits: {
			enabled: false
		},
		xAxis: {
			gridLineWidth: 0,
			lineColor: "black",
			lineWidth: 1,
			categories: data.dayPeriode,
			tickLength: 10,
			tickWidth: 1,
		},
		yAxis: [{ // Primary yAxis
			gridLineWidth: 0,
			labels: {
				format: '',
				style: {
					color: 'black',
				},
			},
			title: {
				text: "",
				style: {
					color: 'black',
				}
			},
			max: 100,
			alignTicks: false,
			opposite: true,
			crosshair: true

		}, { // Secondary yAxis
			gridLineWidth: 1,
			gridLineColor: '#b5b5b561',
			title: {
				text: '',
				style: {
					color: 'black'
				}
			},
			labels: {
				style: {
					color: '#293133',
					format: '{value} ',
				}
			},
		}],
		plotOptions: {
			column: {
				borderColor: '#9E9E9E',
				grouping: false,
				shadow: false,
				borderWidth: 0,
			},
			line: {
				grouping: true,
				shadow: false,
				dashStyle: 'long',
			},
			series: {
				marker: {
					fillColor: '#FFF',
					lineWidth: 1,
					lineColor: null // inherit from series
				}
			},
			areaspline: {
				fillOpacity: 0.3
			}
		},
		opposite: true,
		tooltip: {
			shared: true,
			backgroundColor: '#38444A',
			color: '#FFF',
			style: {
				color: "#FFFFFF",
			},
		},
		series: [
			{
				name: data.name,
				data: data.data,
				color: '#4278F0',
				type: 'areaspline',
				yAxis: 1,
			},

		]

	};
	return (
		<Fragment>
			{isLoadingData &&
				<div className="mask">
					<span className="wheel"></span>
				</div>}
			{!isLoadingData && !hasErrorData && data.data.length !== 0 &&
				<HighchartsReact
					className='chart'
					highcharts={Highcharts}
					options={options}
					constructorType={'chart'}
				/>
			}
		</Fragment>
	);
}
