import React from 'react'
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
export default function DeleteItem({ onClickFn, children }) {
	const idTooltipOfDel = uuidv4();
	return (
		<div className=" flex flex_justify_between sorting_card_container s_14 mb_15 pointer"
		>
			{children}
			<ReactTooltip id={idTooltipOfDel} backgroundColor='black' effect='solid' />
			<i className="fas fa-trash"
				data-for={idTooltipOfDel} data-tip="Delete rule"
				onClick={onClickFn}></i>
		</div>
	)
}
