import React, { useEffect, useState } from 'react';
import { useProductFeedEditionContext } from '../../context/ProductFeedEditionContext';
import editionStyles from '../ProductFeedEdition.module.css';
import Confirm from '../../../../Components/Confirm';
import Btn from '../../../../Components/Btn';
import EmptyState from '../../../../Components/EmptyState';
import IconBtn from '../../../../Components/IconBtn';
import ModalSelectedCategories from '../../Feed/ModalSelectedCategories';
import Accordion from './Accordion';

export default function SelectedCategories() {
	const { productFeedObject, HandleSubmitCategories, setupAccountServices, accountId, tenant } = useProductFeedEditionContext();
	const [selectedCategories, setselectedCategories] = useState([]);
	const [selectedCatModalIsOpen, setSelectedCatModalIsOpen] = useState(false);
	const [catSelected, setcatSelected] = useState('');
	const [showDelConfirm, setshowDelConfirm] = useState(false);
	const [isExistingObj, setisExistingObj] = useState(false);
	const [deleteCat, setDeletedCat] = useState();
	useEffect(() => {
		if (productFeedObject && productFeedObject.hasOwnProperty('selectedCategories')) {
			if (Array.isArray(productFeedObject.selectedCategories)) {
				setselectedCategories([...productFeedObject.selectedCategories]);
			}
		}
	}, [productFeedObject]);
	function handleChangeCategorie(e) {
		const valueOfField = e;
		setcatSelected(valueOfField);
	}
	function submitNewCat() {
		if (isExistingObj) {
			const selectedTagsObj = [...selectedCategories];
			const oldObject = selectedCategories.find(el => el === isExistingObj);
			selectedTagsObj.splice(oldObject, 1, catSelected);
			HandleSubmitCategories(selectedTagsObj);
			setcatSelected('');
			setSelectedCatModalIsOpen(false);
		}
		else {
			const newArrayOfCategorie = [...selectedCategories, catSelected];
			HandleSubmitCategories(newArrayOfCategorie);
			setcatSelected('');
			setSelectedCatModalIsOpen(false);
		}

	}
	function deletenameCatConfirm() {
		const newObj = selectedCategories.filter(el => el !== deleteCat);
		setshowDelConfirm(false);
		setisExistingObj(false);
		setDeletedCat();
		HandleSubmitCategories(newObj);
	}
	function deleteCatFn(cat) {
		setDeletedCat(cat);
		setshowDelConfirm(true);
	}
	function handleCreateEmptyCat() {
		setSelectedCatModalIsOpen(true);
		setisExistingObj(false);
		setcatSelected('');
	}
	function handleSelectCat(cat) {
		setSelectedCatModalIsOpen(true);
		setcatSelected(cat);
		setisExistingObj(true);
	}
	function close() {
		setshowDelConfirm(false);
		setisExistingObj(false);
		setcatSelected('');
	}
	return (
		<>
			<Accordion
				label={'Categories'}
				value={''}
				placeholder="Not set"
				fieldIndex={2}
				isEmpty={selectedCategories.length == 0}
				showDoneButton={selectedCategories.length > 0}
				showDoneBorder={true}
				headerTitle={<>Select <strong>Categories</strong></>}
			>
				{selectedCategories.length === 0 &&
					<EmptyState
						text="No category yet"
						verticalSize='s'
						primaryAction={
							<Btn
								onClick={handleCreateEmptyCat}
								message="Add category"
								icon="fas fa-plus fa-sm"
								style="reverse"
								horizontalSize="xs"
							/>
						}
					/>
				}
				{selectedCategories.length !== 0 &&
					<>
						<div className={editionStyles.table}>
							<div className={editionStyles.table_head_row}>
								<div className={editionStyles.table_col}>Category name</div>
								<div className={editionStyles.table_col}></div>
							</div>
							{selectedCategories.map((cat, i) =>
								<div key={i} className={editionStyles.table_body_row}>
									<div className={editionStyles.table_col}>{cat}</div>
									<div className={editionStyles.table_col}>
										<IconBtn
											tooltip="Edit category"
											icon="fas fa-pen"
											onClick={() => handleSelectCat(cat)}
										/>
										<IconBtn
											tooltip="Delete category"
											icon="fas fa-trash"
											onClick={() => deleteCatFn(cat)}
										/>
									</div>
								</div>
							)}
						</div>
						<div className='mt_10'>
							<Btn
								onClick={handleCreateEmptyCat}
								message="Add category"
								icon="fas fa-plus fa-sm"
								style="reverse"
								size="s"
								horizontalSize="xs"
							/>
						</div>
					</>
				}
			</Accordion>

			<ModalSelectedCategories
				selectedCatModalIsOpen={selectedCatModalIsOpen}
				setSelectedCatModalIsOpen={setSelectedCatModalIsOpen}
				catSelected={catSelected}
				isExistingObj={isExistingObj}
				handleChangeCategorie={handleChangeCategorie}
				submitNewCat={submitNewCat}
				setupAccountServices={setupAccountServices}
				accountId={accountId}
				tenant={tenant}
			/>

			<Confirm
				title="Do you really want to delete this selected categorie ?"
				text="This action is irreversible"
				confirmText="Delete"
				confirmColor="alert"
				cancelText="Cancel"
				isOpen={showDelConfirm}
				confirmCallback={() => deletenameCatConfirm()}
				onClose={close}
			/>
		</>
	);
}
