import React, { useState, useEffect, Fragment } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment-timezone';

export default function CampaignDatePicker({ selectedValue, onChange }) {

	const setLocalZone = (date) => {
		return moment(date).toDate();
	};

	const setOtherZone = (date) => {
		const dateWithoutZone = moment(date).format('ddd MMM DD YYYY HH:mm');
		return dateWithoutZone;
	};

	return (
		<ReactDatePicker
			showTimeSelect
			dateFormat="dd MMM yyyy - H:mm"
			timeFormat="HH:mm"
			timeIntervals={15}
			timeCaption="time"
			selected={setLocalZone(selectedValue)}
			onChange={date => onChange(setOtherZone(date))}
		/>
	);
}