import React, { useState, useEffect } from 'react';
import Btn from '../../../Components/Btn';
import InputCustom from '../../../Components/InputCustom';
import Modal from '../../../Components/Modal';
import PopinConfirm from '../../../Components/PopinConfirm';
import SearchBar from '../../../Components/SearchBar';
import SmartCampaignGroupItem from './SmartCampaignGroupItem';


export default function SmartCampaignGroupDashboard({ setCurrentTab, setCurrentOrchestration, accountId, orchestrationServices, systemServices }){
	const [groups, setGroups] = useState();
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();
	const [modalCOnfirmIsOppen, setModalCOnfirmIsOppen] = useState(false);
	const [groupToDelete, setGroupToDelete] = useState();
	const [isReloading, setIsRealoading] = useState(true);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [oppenCreationOrchestration, setOppenCreationOrchestration] = useState(false);
	const [orchestrationNewGroup, setOrchestrationNewGroup] = useState({
		name: '',
		description: ''
	});

	const getData = () => {
		setIsRealoading(true);
		orchestrationServices.getOrchestrations(accountId,
			data => {
				setGroups(data);
				setIsRealoading(false);
			},
			error => {
				console.error('error', error);
				systemServices.showError('An error occured while fetching Smart Campaigns');
				setIsRealoading(false);
			});
	};
	const postNewOrchestration = () => {
		setIsRealoading(true);
		orchestrationServices.postOrchestration(accountId, orchestrationNewGroup,
			success => {
				setCurrentOrchestration(success);
				setCurrentTab(2);
			},
			error => {
				setIsRealoading(false);
				console.error('error', error);
				systemServices.showError('An error occured while creating the Smart Campaign');
			});
	};
	const deleteAnOrchestration = (idOrchestration) => {
		setIsRealoading(true);
		orchestrationServices.deleteOrchestration(accountId, idOrchestration,
			success => {
				systemServices.showSuccess('The Smart Campaign has been deleted with success');
				setModalCOnfirmIsOppen(false);
				setGroupToDelete();
				setValueInSearchBar('');
				getData();
				setIsRealoading(false);
			},
			error => {
				console.error('error', error);
				setIsRealoading(false);
				systemServices.showError('An error occured while deleting the Smart Campaign');
			});
	};
	useEffect(() => {
		getData();
	}, []);
	const handleChange = (event) => {
		setValueInSearchBar(event.target.value);
	};
	const handleChangeNeworchestrationGroup = (evt) => {
		const newValues = evt.target.value;
		let myStateToChange = { ...orchestrationNewGroup };
		myStateToChange[evt.target.name] = newValues;
		setOrchestrationNewGroup(myStateToChange);
	};
	const closeModal = () => {
		setOppenCreationOrchestration(false);
		setOrchestrationNewGroup({
			name: '',
			description: ''
		});
	};
	useEffect(() => {
		let str = valueInSearchBar.trim();
		if (groups) {
			search(groups, str);
		}
	}, [valueInSearchBar]);

	const search = (table, word) => {
		if (word.length >= 0) {
			let resultName = table.filter(
				line =>
					line.group.name !== null && line.group.name.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let resultDescription = table.filter(
				line =>
					line.group.description !== null && line.group.description.toUpperCase().match(`.*${word.toUpperCase()}.*`)
			);
			let finalArray = [...resultName, ...resultDescription];
			let uniqueItem = [...new Set(finalArray)];
			if (uniqueItem.length === 0) {
				uniqueItem = 'No Smart Campaign matches with this search';
				setNoData(uniqueItem);
				setDataFiltered();
			}
			else {
				setNoData();
				setDataFiltered(uniqueItem);
			}
		} else {
			setDataFiltered(groups);
		}
	};
	const loadDataOfOrchestrationgroup = (e) => {
		setCurrentOrchestration(e);
		setCurrentTab(2);
	};

	return (
		<>
			{!isReloading && (!groups || groups.length == 0) &&
				<div className="empty_page has_picto">
					<div className="empty_page_picto empty_page_picto_orchestration"></div>
					<div className="empty_page_title">Smart Campaigns</div>
					{/*<div className="empty_page_text">
                        TODO : wording - Add new functions to your web-site and automate their activation based on the customer behavior (personalization of landing page, customer retention, triggering personalized offers,....)
                    </div>*/}
					<div className="empty_page_btn">
						<Btn
							onClickFunction={() => setOppenCreationOrchestration(true)}
							message="Create a Smart Campaign"
							icon="fa fa-plus"
							size="l"
							fontSize="l"
						/>
					</div>
				</div>
			}
			{groups && groups.length > 0 &&
				<>
					<section className="flex mb_30">
						<div className="flex_item_full">
							<SearchBar
								onChangeValue={(e) => handleChange(e)}
								valueInSearchBar={valueInSearchBar}
								placeholder="Find a Smart Campaign"
								name="elem"
								fullWidth={false}
							/>
						</div>
						<div className="flex_item_fix ml_20">
							<Btn
								onClickFunction={() => setOppenCreationOrchestration(true)}
								message="Create a Smart Campaign"
								icon="fa fa-plus"
							/>
						</div>
					</section>
					<section>
						{!isReloading && noData &&
							<div className="noData">{noData} </div>
						}
						{!noData &&
							<div className="orchestration_list flex flex_align_stretch flex_wrap">
								{dataFiltered && dataFiltered.map(group =>
									<SmartCampaignGroupItem
										key={group.group.id}
										group={group}
										loadDataOfOrchestrationgroup={() => loadDataOfOrchestrationgroup(group)}
										deleteAnOrchestration={(e) => { setModalCOnfirmIsOppen(true); setGroupToDelete(e); }}
									/>
								)}
								{!dataFiltered && groups && groups.map(group =>
									<SmartCampaignGroupItem
										key={group.group.id}
										group={group}
										loadDataOfOrchestrationgroup={() => loadDataOfOrchestrationgroup(group)}
										deleteAnOrchestration={(e) => { setModalCOnfirmIsOppen(true); setGroupToDelete(e); }}
									/>
								)}
							</div>
						}
					</section>
				</>
			}
			{
				modalCOnfirmIsOppen &&
				<PopinConfirm
					cancel={() => setModalCOnfirmIsOppen(false)}
					title={`Do you really want to delete the Smart Campaign ${groupToDelete.group.name} ? `}
				>
					<Btn
						onClickFunction={(e) => { setModalCOnfirmIsOppen(false); }}
						message="Cancel"
						color="primary"
					/>
					<Btn
						onClickFunction={(e) => deleteAnOrchestration(groupToDelete.group.id)}
						message="Delete"
						color="alert"
					/>
				</PopinConfirm>
			}

			<Modal
				isOpen={oppenCreationOrchestration}
				width="600"
				onClose={() => closeModal()}
			>
				<div className="modal_header has_border">
					Create a Smart Campaign
				</div>
				<div className="modal_section">
					<InputCustom
						value={orchestrationNewGroup.name}
						name="name"
						type="text"
						label="Name"
						placeholder="Ex: CRM product recommendations"
						fullWidth='true'
						onChange={(e) => handleChangeNeworchestrationGroup(e)}
					/>
					<InputCustom
						value={orchestrationNewGroup.description}
						name="description"
						type="textarea"
						label="Description"
						placeholder="Optional description"
						fullWidth='true'
						onChange={(e) => handleChangeNeworchestrationGroup(e)}
					/>
				</div>
				<div className="modal_footer al_right">
					<Btn
						onClickFunction={() => closeModal()}
						message="Cancel"
						color="secondary"
						size="l"
						style="ghost"
					/>
					<Btn
						onClickFunction={orchestrationNewGroup.name.length !== 0 ? () => postNewOrchestration() : null}
						message="Ok"
						disabled={orchestrationNewGroup.name.length !== 0 ? false : true}
						size="l"
					/>
				</div>
			</Modal>
			{isReloading &&
				<div className="spinner_overlay">
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
			}
		</>
	);
}
