
import React from 'react';
import { react2angular } from 'react2angular';
import CrossSellUpSellContainer from '../ProductSettings/CrossSellUpSell/CrossSellUpSellContainer';
import './ProductSettingsContainer.css';
import './Collection.css';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import ProductTagContainer from './Tags/ProductTagContainer';
import ProductAssociationsContainer from './Associations/ProductAssociationsContainer';
import RecommendationsContainer from './Recommendations/RecommendationsContainer';
import RankingContainer from './Ranking/RankingContainer';
//import ProductRecommendationServices from '../../Services/ProductRecommendationServices';
import ProductRankingServices from '../../Services/ProductRankingServices';
import ProductEMerchServices from '../../Services/ProductEMerchServices';
import ProductTaggingServices from '../../Services/ProductTaggingServices';
import SystemServices from '../../Services/SystemServices';
import ExternalAppServices from '../../Services/ExternalAppServices';
import useFeature from '../../../flags/useFeature';
import ProductCollectionsContainer from '../ProductCollections/ProductCollectionsContainer';
import ProductTaggingContainer from './Tags/ProductTaggingContainer';
import SmartCampaignContainer from './_SmartCampaigns/SmartCampaignsContainer';
import RouterItems from '../Menu/RouterItems';
import ListRankingSegmentationBridge from './RankingSegmentation/List/ListRankingSegmentationBridge';
export default function ProductSettingsContainer(props) {

	const $timeout = props.$timeout;
	const $routeParams = props.$routeParams;
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, $routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || props.$rootScope.User.Account.Key;
	const isAdmin = props.$rootScope.$$childHead.IsAnAdmin();
	//const permis = props.$rootScope.$$childHead.permissions;
	const pathName = window.location.pathname;

	const AuthServices = props.authServices;
	const $http = props.$http;
	const $rootScope = props.$rootScope;
	const { features } = useFeature();

	const recommendationsModule = '/ProductSettings/Home';
	const tagsModule = '/ProductSettings/Tags';
	const urlRecommendationsNewModule = '/ProductSettings/Reco';
	const associationsModule = '/ProductSettings/Associations';
	const collectionsModule = '/ProductSettings/Collections';
	const rankingModule = '/ProductSettings/Ranking';
	const taggingModule = '/ProductSettings/Tagging';
	const rankingSegmentation = '/ProductSettings/RankingSegmentation';
	const smartCampaignsModule = '/ProductSettings/SmartCampaigns'; // <- FOR DEMO not real stuff

	let urlRecommendations = recommendationsModule;
	//let urlTags = tagsModule;
	let urlAssociations = associationsModule;
	//let urlCollections = collectionsModule;
	let urlRecommendationsNew = urlRecommendationsNewModule;
	let urlRanking = rankingModule;
	let urlTagging = taggingModule;
	let urlRankingSegment = rankingSegmentation;
	let urlSmartCampaigns = smartCampaignsModule; // <- FOR DEMO not real stuff
	if (props.$routeParams && props.$routeParams.ka && isAdmin) {
		urlRecommendations += '?ka=' + props.$routeParams.ka;
		urlRecommendations += '&ku=' + props.$routeParams.ku;
		urlRecommendationsNew += '?ka=' + props.$routeParams.ka;
		urlRecommendationsNew += '&ku=' + props.$routeParams.ku;
		urlAssociations += '?ka=' + props.$routeParams.ka;
		urlAssociations += '&ku=' + props.$routeParams.ku;
		//urlTags += '?ka=' + props.$routeParams.ka;
		//urlTags += '&ku=' + props.$routeParams.ku;
		//urlCollections += '?ka=' + props.$routeParams.ka;
		//urlCollections += '&ku=' + props.$routeParams.ku;
		urlRanking += '?ka=' + props.$routeParams.ka;
		urlRanking += '&ku=' + props.$routeParams.ku;
		urlTagging += '?ka=' + props.$routeParams.ka;
		urlTagging += '&ku=' + props.$routeParams.ku;
		urlRankingSegment += '?ka=' + props.$routeParams.ka;
		urlRankingSegment += '&ku=' + props.$routeParams.ku;
		urlSmartCampaigns += '?ka=' + props.$routeParams.ka; // <- FOR DEMO not real stuff
		urlSmartCampaigns += '&ku=' + props.$routeParams.ku; // <- FOR DEMO not real stuff
	}

	const systemServices = new SystemServices($rootScope, $timeout);
	//const productRecommendationServices = new ProductRecommendationServices($http, AuthServices, accountId);
	const productRankingServices = new ProductRankingServices($http, AuthServices, accountId);
	const emerchServices = new ProductEMerchServices($http, AuthServices, accountId);
	const productTaggingServices = new ProductTaggingServices($http, AuthServices, accountId);
	const externalAppServices = new ExternalAppServices($http, accountId);

	const hasRankingSegmentation = true;

	const routerList = [
		{
			label : 'Dynamic collections',
			icon: 'reco',
			link : urlRecommendationsNew,
			activeUrls: [urlRecommendationsNew]
		},
		{
			label : 'Recommendations',
			icon: 'bag',
			link : urlRecommendations,
			activeUrls: [urlRecommendations]
		}
	];

	if (hasRankingSegmentation) {
		routerList.push(
			{
				label : 'Ranking',
				icon: 'ranking',
				link : urlRanking,
				activeUrls: [urlRanking],
				items: [
					{
						label : 'Rules',
						link : urlRanking,
						activeUrls: [urlRanking],
					},
					{
						label : 'Segmentation',
						link : urlRankingSegment,
						activeUrls: [urlRankingSegment]
					}
				]
			}
		);
	} else {
		routerList.push(
			{
				label : 'Ranking',
				icon: 'ranking',
				link : urlRanking,
				activeUrls: [urlRanking]
			}
		);
	}
	
	routerList.push(
		{
			label : 'Bundles',
			icon: 'bundle',
			link : urlAssociations,
			activeUrls: [urlAssociations]
		}
	);
	routerList.push(
		{
			label : 'Custom tags',
			icon: 'tags',
			link : urlTagging,
			activeUrls: [urlTagging]
		}
	);

	if (features.includes('emerch-demo')) {
		routerList.push({
			label : 'Smart Campaigns',
			icon: 'list',
			link : urlSmartCampaigns
		});
	}

	return (
		<RouterItems
			title="E-merchandising"
			list={routerList}
		>
			<div className='page_full page_full_grey vscroll'>
				<div className='page_full_inner'>
					{/*<div className="productSettingsElement">
                    <ProductTagContainer
                        https={props.$http}
                        routeParams={props.$routeParams}
                        rootScope={props.$rootScope}
                        accountId={accountId}
                        impServices={props.$rootScope, props.$routeParams}
                    />
                </div> */}
					{pathName === recommendationsModule &&
						<CrossSellUpSellContainer
							https={props.$http}
							routeParams={props.$routeParams}
							rootScope={props.$rootScope}
							accountId={accountId}
							$timeout={$timeout}
						/>
					}
					{pathName === urlRecommendationsNewModule &&
						(<RecommendationsContainer
							systemServices={systemServices}
							routeParams={props.$routeParams}
							productRecommendationServices={emerchServices}
							isAdmin={isAdmin}
						/>)
					}
					{pathName === tagsModule &&
						(<ProductTagContainer
							$rootScope={props.$rootScope}
							$http={props.$http}
							accountId={accountId}
							isAdmin={isAdmin}
						/>)
					}
					{pathName === taggingModule &&
						(<ProductTaggingContainer
							productTaggingServices={productTaggingServices}
							systemServices={systemServices}
							$routeParams={props.$routeParams}
						/>)
					}
					{pathName === associationsModule &&
						(<ProductAssociationsContainer
							accountId={accountId}
							$rootScope={props.$rootScope}
							$timeout={props.$timeout}
							$http={props.$http}
							authServices={props.AuthServices}
							productAssociationServicesNew={emerchServices}
							systemServices={systemServices}
						/>)
					}
					{pathName === rankingModule &&
						(<RankingContainer
							productRankingServices={productRankingServices}
							externalAppServices={externalAppServices}
							systemServices={systemServices}
							$routeParams={props.$routeParams}
						/>)
					}
					{pathName === rankingSegmentation &&
						(<ListRankingSegmentationBridge
							productRankingServices={productRankingServices}
							externalAppServices={externalAppServices}
							systemServices={systemServices}
							$routeParams={props.$routeParams}
							AuthServices={props.AuthServices}
							$http={props.$http}
							$rootScope={props.$rootScope}
							$timeout={props.$timeout}
						/>)
					}
					{pathName === collectionsModule && (
						<ProductCollectionsContainer
							$rootScope={props.$rootScope}
							$routeParams={props.$routeParams}
							AuthServices={props.AuthServices}
						/>
					)}
					{features.includes('emerch-demo') && pathName === smartCampaignsModule && (
						<SmartCampaignContainer
							accountId={accountId}
							$rootScope={props.$rootScope}
							$routeParams={props.$routeParams}
							AuthServices={props.AuthServices}
							$http={props.$http}
						/>
					)}
				</div>
			</div>
		</RouterItems>
	);
}

angular
	.module('beyableSaasApp.productSettingContainer', [])
	.component('productsettingcontainer', react2angular(ProductSettingsContainer, [], ['$http', '$rootScope', '$timeout', '$routeParams', 'AuthServices']));
