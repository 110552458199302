import React, { useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import './CategoriesAccount.css';
export default function CategoriesAccount({ categories, setCategories }) {
	const ttId = uuidv4();
	const ttId2 = uuidv4();
	const selectCatToPut = (e) => {
		let catToToggle = categories.filter(x => x.id === e);
		catToToggle[0].toggle = true;
		let newArrayOfCat = categories.filter(x => x.id !== e);
		setCategories([...newArrayOfCat, ...catToToggle]);
	};
	const unselectCatToPut = (e) => {
		let catToToggle = categories.filter(x => x.id === e);
		delete catToToggle[0].toggle;
		let newArrayOfCat = categories.filter(x => x.id !== e);
		setCategories([...newArrayOfCat, ...catToToggle]);
	};

	function sortArray(a, b) {
		if (a.id < b.id) return -1;
		if (a.id > b.id) return 1;
		return 0;
	}
	const [clipBoardContain, setClipBoardContain] = useState();
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
	};
	return (
		<>
			{categories && categories.sort(sortArray).map((cat, i) =>
				<div key={i}>
					<ReactTooltip id={ttId} className="" backgroundColor='black' effect='solid' />
					{!cat.toggle ?
						<div className="header-category-of-account-list"
						>
							<div className="cat-chevron" onClick={() => selectCatToPut(cat.id)}
								data-for={ttId} data-tip="Click to see products list"
							> <i className="fas fa-chevron-right"></i> <span className="name-zone-chevron">{cat.type}</span> </div>
						</div>
						:
						<div className="header-category-of-account-list active-category">
							<div className="cat-chevron isActiveLink" onClick={() => unselectCatToPut(cat.id)}> <i className="fas fa-chevron-up"></i><span className="name-zone-chevron">{cat.type}</span> </div>
						</div>
					}
					<div className="product-list-category">
						{cat.toggle &&
                            <div>
                            	<ReactTooltip id={ttId2} className="" backgroundColor='black' effect='solid' />
                            	{cat.knownNames.map((el, idx) =>
                            		<div className="cat-item" key={idx} onClick={(e) => copyToClipBoard(el)}>
                            			{el}
                            			<i className={clipBoardContain === el ? 'far fa-clipboard clipItem copyToClip' : 'far fa-clipboard clipItem'}
                            				data-for={ttId2} data-tip="Click to copy category to clipboard"></i></div>
                            	)}</div>
						}
					</div>

				</div>
			)}
		</>
	);
}
