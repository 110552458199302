import HttpServices from './HttpServices';
import axios from 'axios';

export default class VideosServices {
	constructor($http, accountId, authServices) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
		this.accountId = accountId;
		let token = authServices && authServices.getAccessToken();
		this.config = {
			headers: {}
		};
		if (token) {
			this.config.headers.Authorization = 'Bearer ' + token;
		}
	}
	getLibraryId() {
		return axios.post(`${this.BO_API_URL}medialibrary/${this.accountId}/library`, new FormData(),
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' },
		);
	}

	postUploadToImageCloud(libraryId, files, onUploadProgress) {
		return axios.post(`${this.BO_API_URL}videocloud/${this.accountId}/${libraryId}/upload`, files,
			{
				onUploadProgress: onUploadProgress,
				headers: this.config.headers, 'Content-Type': 'multipart/form-data'
			},
		);
	}
	
	postAddCloudImagesToLibrary( libraryId, images) {
		return axios.post(`${this.BO_API_URL}medialibrary/${this.accountId}/library/${libraryId}/cloud`, images,
			{ headers: this.config.headers, 'Content-Type': 'multipart/form-data' },
		);
	}

}