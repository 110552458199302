import React, {useEffect, useState} from 'react';
import { CampaignsContext } from '../../context/index.js';
import Modal from '../../../../Components/Modal.js';
import ModalHeader from '../../../../Components/ModalHeader.js';
import ModalBody from '../../../../Components/ModalBody.js';
import ModalFooter from '../../../../Components/ModalFooter.js';
import InputCustom from '../../../../Components/InputCustom.js';
import Btn from '../../../../Components/Btn.js';
import {useTranslation} from 'react-i18next';
import {isAnUrl} from '../../util.js';

interface IPageReference {
	isModal?: boolean;
	modalIsOpen?: boolean;
	setmodalIsOpen?: any;
}

function PageReference({
	isModal = false,
	modalIsOpen,
	setmodalIsOpen,
}: IPageReference) {
	const { t } = useTranslation(['common']);
	const {
		handleChangeUrlDebug,
		campaign
	} = CampaignsContext();
	const [error, setError] = useState('');
	const [url, setUrl] = useState('');

	const handleValidation = () => {
		let finalUrl = url;
		if (
			finalUrl.indexOf('http://') === -1 &&
			finalUrl.indexOf('https://') === -1
		) {
			finalUrl = 'https://' + finalUrl;
		}

		const isValid = isAnUrl(finalUrl);
		if (!isValid) {
			setError('Please enter a valid URL');
			return;
		}
		handleChangeUrlDebug(finalUrl);
		if (isModal) {
			setmodalIsOpen(false);
		}
	};

	useEffect(() => {
		if (!error) return;
		const isValid = isAnUrl(url);
		if (isValid) setError('');
	}, [url]);

	useEffect(()=>{
		if(campaign){
			setUrl(campaign.UrlDebug);
		}
	},[campaign]);

	if (isModal) {
		return (
			<Modal
				isOpen={modalIsOpen}
				width="500"
				onClose={() => { setmodalIsOpen(false); }}
			>
				<ModalHeader>
					{t('abTest.setReferencePage')}
				</ModalHeader>
				<ModalBody>
					<InputCustom
						onChange={(ev) => setUrl(ev.target.value)}
						onEnter={() => handleValidation()}
						autoFocus={true}
						value={url || ''}
						type="text"
						placeholder="https://www.beyable.com/"
						fullWidth={true}
						rows={2}
						blockClassName="xl"
						isAnError={error}
						name="url"
					/>
				</ModalBody>
				<ModalFooter
					primaryAction={
						<Btn
							disabled={!url}
							onClickFunction={() => handleValidation()}
							message={t('actions.ok')}
							color="primary"
						/>
					}
					secondaryAction={
						<Btn
							onClickFunction={() => { setmodalIsOpen(false); }}
							message={t('actions.cancel')}
							color="secondary"
							style="ghost"
						/>
					}
				/>
			</Modal>
		);
	} else {
		return (
			<div className='flex flex_align_start'>
				<div className='flex_item_full'>
					<InputCustom
						onChange={(ev) => setUrl(ev.target.value)}
						onEnter={() => handleValidation()}
						autoFocus={true}
						value={url || ''}
						type="text"
						placeholder="https://www.beyable.com/"
						fullWidth={true}
						rows={2}
						blockClassName="xl"
						isAnError={error}
						name="url"
					/>
				</div>
				<div className='flex_item_fix ml_10'>
					<Btn
						onClickFunction={() => handleValidation()}
						message={t('actions.next')}
						color="primary"
					/>
				</div>
			</div>
		);
	}
}

export {
	PageReference
};
