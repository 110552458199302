import React, { useState, useEffect, useContext } from 'react';
import { reportingCampaignContext } from '../Context/ContextReportingCampaign';
import { getIndicatorByKey } from '../Indicators';
import { CSVLink } from 'react-csv';
import { Trans, useTranslation } from 'react-i18next';
import { ListboxItemExternal } from '../../../Components/ListboxItem';

export default function Download(props) {
	const {
		secondaryIndicatorToCompareExport,
		indicatorsComput,
		rangeDate,
		primaryIndicatorSummaryExport,
		secondaryIndicatorSummaryExport
	} = useContext(reportingCampaignContext);

	const [data, setData] = useState([]);
	const [dataFileName, setDataFileName] = useState('file');
	const [t, i18n] = useTranslation('common');
	function getDataSecondaryIndicators() {
		let secondaryIndicator = [];
		let primaryIndicator = [];
		let computArray = [];
		if (secondaryIndicatorToCompareExport) {
			secondaryIndicator = [...secondaryIndicator, ...secondaryIndicatorToCompareExport];
		}
		if (secondaryIndicatorSummaryExport) {
			secondaryIndicator = [...secondaryIndicator, ...secondaryIndicatorSummaryExport];
		}
		if (primaryIndicatorSummaryExport) {
			primaryIndicator = [...primaryIndicator, primaryIndicatorSummaryExport];
		}
		if (indicatorsComput) {
			computArray = [...computArray, ...indicatorsComput];
		}

		for (let i = 0; i < secondaryIndicator.length; i++) {
			let newLabel = secondaryIndicator[i] && secondaryIndicator[i].key && getIndicatorByKey(secondaryIndicator[i].key).label;
			secondaryIndicator[i].campaign = props.datainformation.name,
			secondaryIndicator[i].name = newLabel;
			secondaryIndicator[i].dateRange = rangeDate;
			secondaryIndicator[i].idCampaign = props.datainformation.id;
			// primaryIndicator[i].device = props.device
			if ('valueDisplayedRate' in secondaryIndicator[i] && typeof secondaryIndicator[i].valueDisplayedRate === 'number') {
				if (secondaryIndicator[i].valueDisplayedRate !== null) {
					secondaryIndicator[i].DisplayedRate = `${secondaryIndicator[i].valueDisplayedRate.toFixed(2)} %`;
				}
			}
			if ('valueDisplayedAbsolute' in secondaryIndicator[i] && typeof secondaryIndicator[i].valueDisplayedAbsolute === 'number') {
				secondaryIndicator[i].DisplayedAbsolute = secondaryIndicator[i].valueDisplayedAbsolute;
			}
			if ('valueNotDisplayedAbsolute' in secondaryIndicator[i] && typeof secondaryIndicator[i].valueNotDisplayedAbsolute === 'number') {
				secondaryIndicator[i].valueNotDisplayedAbsolute = secondaryIndicator[i].valueNotDisplayedAbsolute;
			}
			if ('upliftRate' in secondaryIndicator[i] && typeof secondaryIndicator[i].upliftRate === 'number') {
				secondaryIndicator[i].uplift = `${secondaryIndicator[i].upliftRate.toFixed(2)} %`;
			}
			if ('upliftAbsolute' in secondaryIndicator[i] && typeof secondaryIndicator[i].upliftAbsolute === 'number') {
				secondaryIndicator[i].upliftabsolute = `${secondaryIndicator[i].upliftAbsolute}`;
			}
		}
		for (let i = 0; i < computArray.length; i++) {
			computArray[i].campaign = props.datainformation.name;
			computArray[i].name = computArray[i].label;
			computArray[i].dateRange = rangeDate;
			computArray[i].device = props.device;
			computArray[i].value = computArray[i].value;
			computArray[i].idCampaign = props.datainformation.id;
		}
		for (let i = 0; i < primaryIndicator.length; i++) {
			let newLabel = getIndicatorByKey(primaryIndicator[i].key).label;
			// primaryIndicator[i].device = props.device
			primaryIndicator[i].campaign = props.datainformation.name;
			primaryIndicator[i].dateRange = rangeDate;
			primaryIndicator[i].name = newLabel;
			primaryIndicator[i].idCampaign = props.datainformation.id;
			if ('valueDisplayedRate' in primaryIndicator[i] && typeof primaryIndicator[i].valueDisplayedRate === 'number') {
				primaryIndicator[i].DisplayedRate = `${primaryIndicator[i].valueDisplayedRate.toFixed(2)} %`;
			}
			if ('valueDisplayedAbsolute' in primaryIndicator[i] && typeof primaryIndicator[i].DisplayedAbsolute === 'number') {
				primaryIndicator[i].DisplayedAbsolute = primaryIndicator[i].valueDisplayedAbsolute;
			}
			if ('valueNotDisplayedAbsolute' in primaryIndicator[i] && typeof primaryIndicator[i].valueNotDisplayedAbsolute === 'number') {
				primaryIndicator[i].valueNotDisplayedAbsolute = primaryIndicator[i].valueNotDisplayedAbsolute;
			}
			if ('upliftRate' in primaryIndicator[i] && typeof primaryIndicator[i].upliftRate === 'number') {
				primaryIndicator[i].uplift = `${primaryIndicator[i].upliftRate.toFixed(2)} %`;
			}
			if ('upliftAbsolute' in primaryIndicator[i] && typeof primaryIndicator[i].upliftAbsolute === 'number') {
				primaryIndicator[i].upliftabsolute = `${primaryIndicator[i].upliftAbsolute.toString()}`;
			}
		}
		setData([...secondaryIndicator, ...primaryIndicator, ...computArray]);
		setDataFileName(`${props.datainformation && props.datainformation.id.toUpperCase()}_${props.datainformation && props.datainformation.name.toUpperCase()}`);
	}

	const headers = [
		{ label: 'Campaign name', key: 'campaign' },
		{ label: 'Id campaign', key: 'idCampaign' },
		{ label: 'Device', key: 'device' },
		{ label: 'Date range', key: 'dateRange' },
		{ label: 'KPI', key: 'name' },
		{ label: 'Display', key: 'DisplayedAbsolute' },
		{ label: 'Not Display', key: 'valueNotDisplayedAbsolute' },
		{ label: 'DisplayedRate', key: 'DisplayedRate' },
		{ label: 'Taux UpLift', key: 'uplift' },
		{ label: 'UpLift Absolu', key: 'upliftabsolute' },
		{ label: 'Value', key: 'value' },
	];

	useEffect(() => {
		getDataSecondaryIndicators();
	}, [secondaryIndicatorToCompareExport, primaryIndicatorSummaryExport, secondaryIndicatorSummaryExport, indicatorsComput,
		rangeDate]);
	return (
		<ListboxItemExternal
			message={t('ChartPerformCampaign.ReportingData')}
			render={(cssClass, content) => {
				return (
					<CSVLink
						asyncOnClick={true}
						data={data}
						className={cssClass}
						headers={headers}
						separator={';'}
						filename={`${dataFileName}.csv`}
						target="_blank"
						enclosingCharacter={'\''}
					>
						{content}
					</CSVLink>
				);
			}}
		/>
	);
}