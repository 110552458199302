import { Grid } from 'antd';
import React from 'react';

export default function TableGridContainer({ specialClasseName,
	styleObj = {},
	children,
	provided = {
		innerRef: React.createRef()
	} }) {
	return (
		<div className={'table_grid' + ' ' + specialClasseName}
			{...provided.droppableProps}
			ref={provided.innerRef}
		>
			{children}
		</div>
	);
}
