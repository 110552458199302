import React from 'react';

import ReactLoading from 'react-loading';
function Loading(props) {
	return (
		<>
			<div className="kpi_container" >
				<p className="title_kpi"> Chargement </p>
				<ReactLoading className="title_kpi" type="bars" color="#006fff" delay={3000} width='50%' />
			</div>
			<div className="kpi_container" >
				<p className="title_kpi"> Chargement </p>
				<ReactLoading className="title_kpi" type="bars" color="#006fff" delay={3000} width='50%' />
			</div>
			<div className="kpi_container" >
				<p className="title_kpi"> Chargement </p>
				<ReactLoading className="title_kpi" type="bars" color="#006fff" delay={3000} width='50%' />
			</div>
		</>

	);
}

export default Loading;