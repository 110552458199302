/* eslint-disable no-prototype-builtins */

import React, { useState, useRef, useEffect, Fragment } from 'react';
import Btn from '../../../Components/Btn';
import Link from '../../../Components/Link';
import NumericUpDown from '../../../Components/NumericUpDown';
import Panel from '../../../Components/Panel';
import PanelArticle from '../../../Components/PanelArticle';
import SectionMessage from '../../../Components/SectionMessage';
import SelectDropdown from '../../../Components/SelectDropdown';
import useEventBroker from '../../../Hooks/useEventBroker';
import styles from './PanelRecommendations.module.css';
import useFeature from '../../../../flags/useFeature';
import {useTranslation} from 'react-i18next';
import renderJsxTag from '../../../Translation/translation-utils';
import { useWysiwygEditorContext } from '../../WysiwygEditor/context/WysiwygEditorContext';

const isClassicShelving = (rule) => rule.ruleConfiguration.contextType === 'ClassicShelving';

function groupBy(items, getKey, getValue) {
	const grouping = {};
	for (let i = 0; i < items.length; i++) {
		const item = items[i];
		const key = getKey(item);
		if (!grouping[key]) {
			grouping[key] = [];
		}
		grouping[key].push(getValue ? getValue(item) : item);
	}
	return grouping;
}

function sortArray(a, b) {
	if (a.id < b.id) return -1;
	if (a.id > b.id) return 1;
	return 0;
}
export default function PanelRecommendations({mode = 'campaign'}) {
	
	

	const {
		listOfShelvingRules,
		isAdmin,
		accountId
	} = useWysiwygEditorContext();

	const [t, i18n] = useTranslation('common');
	const emptySelectItems = [{
		id: 0,
		label: t('recos.choose'),
		shelving: '',
		NumberOfProducts: 4,
		ruleConfiguration: {}
	}];
	const { subscribe } = useEventBroker();
	const { features } = useFeature();

	const [panelRecommendationsContext, setPanelRecommendationsContext] = useState();
	const [selectedRules, setSelectedRules] = useState(emptySelectItems);
	const [errorsInSelection, seterrorsInSelection] = useState([]);
	const [targeting, setTargeting] = useState([]);

	const ruleGroups = (selectedRules && Object.entries(groupBy(selectedRules, x => x.groupKey ?? 0))) || [];

	// const getPersonas = () => {
	// 	const personasFromCampaign = Object.keys(groupBy(campaign.Specifications, 
	// 		x => {
	// 			console.log('x',x.group);
	// 			return x.Group.Label;
	// 		})
	// 		).filter(x => !!x);
	// 	if (personasFromCampaign.length > 0 && ruleGroups.length > 1) {
	// 		return [...personasFromCampaign, '-- Fallback --'];
	// 	} else {
	// 		return personasFromCampaign;
	// 	}
	// };
	// const personas = getPersonas();

	subscribe('evt/campaign/setShelvingRulesRequested', (e) => {
		setPanelRecommendationsContext({ callback: e.detail.callback, value: e.detail.value , from : e.detail?.from ? e.detail.from : 'campaign' });
	});

	useEffect(() => {
		const isValidArrayOfRecommendation = selectedRules.reduce((acc, curr) => {
			let err = { id: curr.id };
			if (curr.shelving === '') {
				err.error = t('recos.pleaseChoose');
			}
			else if (!curr.ruleConfiguration.hasOwnProperty('context')) {
				err.error = t('recos.pleaseChoose');
			}
			if ((curr.ruleConfiguration.hasOwnProperty('context') && curr.ruleConfiguration.context !== 'Global') && (!curr.hasOwnProperty('subContext') && !isClassicShelving(curr))) {
				err.error = t('recos.pleaseChoose');
			}
			return [...acc, err];
		}, []).filter(x => x.error);

		const isValid = isValidArrayOfRecommendation.length <= 0;
		if (isValid) {
			seterrorsInSelection([]);
		} else {
			seterrorsInSelection(isValidArrayOfRecommendation);
		}
	}, [selectedRules]);

	const changeValueOfSelect = (value, ruleId) => {
		const rule = listOfShelvingRules.find(x => x.id === value);

		let itemToChange = selectedRules.find(x => x.id === ruleId);
		itemToChange.shelving = value;
		itemToChange.label = rule.name;
		itemToChange.ruleConfiguration = rule;

		let newArray = selectedRules.map(x => x.id === ruleId ? itemToChange : x);
		setSelectedRules(newArray);
		// synchronizeSlideShelvingRules()
	};
	const upNumberOfProductTarget = (e, id) => {
		let array = [...selectedRules];
		let newItem = array.filter(x => x.id === id)[0];
		newItem.NumberOfProducts = parseInt(e.target.value);
		const newArr = array.filter(x => x.id !== id);
		setSelectedRules([...newArr, newItem]);
	};
	const addNewSelect = () => {
		const array = [...selectedRules];
		let ids = [];
		array.forEach(x =>
			ids.push(x.id));
		let newId;
		const getNewId = () => {
			const number = parseInt(Math.max(...ids) + 1);
			if (ids.includes(number)) {
				getNewId();
			}
			else {
				newId = number;
			}
		};
		getNewId();
		array.push({ ...emptySelectItems[0], id: newId });
		setSelectedRules(array);
	};
	const changeValueOfSubContext = (value, ruleId) => {
		let itemToChange = selectedRules.find(x => x.id === ruleId);
		itemToChange.subContext = value;

		let newArray = selectedRules.map(x => x.id === ruleId ? itemToChange : x);
		setSelectedRules(newArray);
		// synchronizeSlideShelvingRules()
	};
	const deleteItemFromArrayOfAlgo = (e) => {
		const tabs = [...selectedRules];
		const newTabs = tabs.filter(x => x.id !== e);
		if (newTabs.length < 1) {
			newTabs.push(emptySelectItems[0]);
		}
		setSelectedRules([...newTabs]);
	};

	const displayContextSelector = (algo) => {
		const getOptions = (context) => {
			switch (context) {
				case 'Global':
					if (features.includes('emerch-demo')) {
						return [['none', 'None'], ['viewedProducts', 'Last viewed products'], ['productsInCart', 'Last products in cart'], ['productsBought', 'Last bought products']];
					} else {
						return [];
					}
				case 'Category':
					if (features.includes('emerch-demo')) {
						return [['categoryCurrentPage', 'Category from current page'], ['categoryFromHistory', 'Category from history (cold)'], ['categoryFromHistory2', 'Category from history (hot)'], ['categoryFromCart', 'Category from cart'], ['categoryFromLastPurchase', 'Category from last purchase']];
					} else {
						return [['categoryCurrentPage', t('recos.catFromCurrentPage')], ['categoryFromHistory',  t('recos.catFromCurrentHistory')], ['categoryFromCart', t('recos.catFromCart')]];
					}
				case 'Tag':
					if (features.includes('emerch-demo')) {
						return [['tagCurrentPage', t('recos.tagFromPage')], ['tagFromHistory', 'Tag from history (cold)'], ['tagFromHistory2', 'Tag from history (hot)'], ['tagFromCart', 'Tag from cart'], ['tagFromLastPurchase', 'Tag from last purchase']];
					} else {
						return [['tagCurrentPage', t('recos.tagFromPage')], ['tagFromHistory', t('recos.tagFromHistory')], ['tagFromCart', t('recos.tagFromCart')]];
					}
				case 'ProductGlobal':
				case 'ProductCategory':
				case 'ProductTag':
					if (features.includes('emerch-demo')) {
						return [['productCurrentPage', 'Product from current page'], ['productFromHistory', 'Product from history (cold)'], ['productFromHistory', 'Product from history (hot)'], ['productFromCart', 'Product from cart'], ['productFromPurchase', 'Product from last purchase']];
					} else {
						return [['productCurrentPage', t('recos.productFromPage')], ['productFromHistory', t('recos.productFromHistory')], ['productFromCart',  t('recos.productFromCart')]];
					}
				default:
					return [];
			}
		};
		if (mode === 'email_template' ) return;
		const options = getOptions(algo.ruleConfiguration.context);
		if (options.length === 0) {
			return (
				<div className={styles.reco_col}>
					<SelectDropdown
						placeHolder={t('recos.Automatic')}
						fullWidth={true}
						disabled={true}
					/>
				</div>
			);
		}
	
		return (
			<div className={styles.reco_col}>
				<SelectDropdown
					placeHolder={t('recos.chooseCtx')}
					optionsList={options.map(x => { return { 'value': x[0], 'label': x[1] }; })}
					value={algo.subContext}
					onChange={(v) => changeValueOfSubContext(v, algo.id)}
					fullWidth={true}
				/>
			</div>
		);
	};
	const addSlidePropertyToRuleInfo = (rule, slideProperty) => {
		const addSubContext = (p) => {
			const context = slideProperty.ContextDetail;
			if (!isClassicShelving(rule) && context !== 'Global') {
				return { ...p, subContext: slideProperty.RuleScope };
			}
			return p;
		};
		return addSubContext(rule);
	};
	useEffect(() => {
		// eslint-disable-next-line no-prototype-builtins
		if (panelRecommendationsContext){
			const rules = panelRecommendationsContext.value;
			var initialRules = [];
			for (let i = 0; i < rules.length; i++) {
				const slideRule = rules[i];
				const ruleConfiguration = listOfShelvingRules.find(x => x.id === slideRule.Id) || {};
				const label = ruleConfiguration.name || slideRule.Id;

				const ruleWithInfo = addSlidePropertyToRuleInfo({
					id: i + 1,
					label: label,
					shelving: slideRule.Id,
					NumberOfProducts: slideRule.NumberOfProducts,
					ruleConfiguration
				}, slideRule);
				initialRules.push(ruleWithInfo);
			}
			setSelectedRules(initialRules.length > 0 ? initialRules : emptySelectItems);
		}
	}, [panelRecommendationsContext]);

	const addRuleInfoToSlideProperty = (rule, slideProperty) => {
		const addContextDetail = (p) => {
			const context = rule.ruleConfiguration.context;
			if (!isClassicShelving(rule) && (context === 'ProductCategory' || context === 'ProductTag')) {
				return { ...p, ContextDetail: context };
			}
			return p;
		};
		const addTenant = (p) => {
			if (!isClassicShelving(rule) && rule.tenant) {
				return { ...p, Tenant: rule.tenant };
			}
			return p;
		};
		return addTenant(addContextDetail(slideProperty));
	};
	const handleSubmitRecommendations = () => {
		const isValid = errorsInSelection.length <= 0;
		if (isValid) {
			synchronizeSlideShelvingRules();
		}
	};
	
				
	const synchronizeSlideShelvingRules = () => {
		const buildScope = (rule, context) => {
			if (isClassicShelving(rule)) {
				return context;
			}
			if (rule.ruleConfiguration.context === 'Global') {
				return 'global';
			}
			return rule.subContext;
		};

		let rulesProperty = [];
		let newSelectedRules = selectedRules.filter(x => x.shelving);
		newSelectedRules && newSelectedRules.forEach(x => {
			const rule = listOfShelvingRules.find(r => r.id === x.shelving);
			if (!rule) return;
			const context = rule.context;
			const scope = buildScope(x, context);
			if (scope && x.NumberOfProducts > 0) {
				const slideProperty = { Id: x.shelving, RuleScope: scope, NumberOfProducts: x.NumberOfProducts };
				const slidePropertyWithAddedInfo = addRuleInfoToSlideProperty(x, slideProperty);
				rulesProperty.push(slidePropertyWithAddedInfo);
			}
		});
		if(panelRecommendationsContext.from === 'custom'){
			panelRecommendationsContext.callback(rulesProperty, 'ShelvingRules');
		}else{
			panelRecommendationsContext.callback(rulesProperty);
		}
		
		setPanelRecommendationsContext(false);
	};
	let linkToRecos = '';
	if (isAdmin && accountId) {
		linkToRecos = `/ProductSettings/Reco?ka=${accountId}`;
	} else {
		linkToRecos = '/ProductSettings/Reco';
	}
	return (
		<Panel
			isOpen={!!panelRecommendationsContext}
			onClose={() => setPanelRecommendationsContext(false)}
			noClose={true}
			side="right"
			width="780"
		>
			<div className="modal_header has_border flex">
				<div className='flex_item_fix'>
					<a className='panel_close panel_close_left' onClick={(e) => setPanelRecommendationsContext(false)}></a>
				</div>
				<div className='flex_item_full'>
					{t('recos.title')}
				</div>
				<div className='flex_item_fix ml_30'>
					<Btn
						onClickFunction={(e) => handleSubmitRecommendations()}
						message="OK"
					/>
				</div>
			</div>
			<div className="modal_body modal_body_grey">
				<SectionMessage
					type="information"
					marginSize='l'
					links={
						<Link 
							message="E-merchandising"
							icon="fas fa-external-link-alt"
							iconPosition='after'
							href={linkToRecos}
							/* TODO : use KA+KU if needed in href */
							target="_blank"
						/>
					}>
					{renderJsxTag(t('recos.canCreate'))}
				</SectionMessage>

				<PanelArticle>
					<div className={styles.reco_row + ' ' + styles.reco_head_row}>
						<div className={styles.reco_col}>Rule</div>
						{mode !== 'email_template' && 
						<div className={styles.reco_col}>Context</div>
						}
						<div className={styles.reco_col}>NB. of products</div>
						<div className={styles.reco_col}></div>
					</div>
					{selectedRules && selectedRules.sort(sortArray).map((algo, i) =>
						<Fragment key={i}>
							<div className={styles.reco_row + ' ' + styles.reco_body_row} key={i}>
								<div className={styles.reco_col}>
									<SelectDropdown
										placeHolder='Choose a rule'
										optionsList={listOfShelvingRules.map(x => {return {'value': x.id, 'label': x.name};})}
										value={algo.shelving}
										onChange={(v) => changeValueOfSelect(v, algo.id)}
										fullWidth={true}
									/>
								</div>
								{mode !== 'email_template' && 
										<>
										{ displayContextSelector(algo)}
										</>
								}
								<div className={styles.reco_col}>
									<NumericUpDown
										min={0}
										value={algo.NumberOfProducts}
										onChange={(e) => upNumberOfProductTarget(e, algo.id)}
										canEdit={true}
									/>
								</div>

								<div className={styles.reco_col}>
									{selectedRules.length > 1 &&
										<button className='icon_btn s' onClick={(e) => deleteItemFromArrayOfAlgo(algo.id)}>
											<i className='fas fa-trash'></i>
										</button>
									}
								</div>
							</div>
							<div style={{
								color : '#d76565'
							}}>
								{errorsInSelection.find(el => el.id === algo.id)
							&& errorsInSelection.find(el => el.id === algo.id).error	
								}
							</div>
						</Fragment>
					)}
					{mode !== 'email_template' && 
					<div className={styles.reco_actions}>
						<Btn
							icon="fas fa-plus"
							message={t('recos.addTarget')}
							style="reverse"
							onClick={addNewSelect}
						/>
					</div>}
				</PanelArticle>
			</div>
		</Panel>
	);
}
