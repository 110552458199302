import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Fieldset from '../../../Components/Fieldset';
import InputCustom from '../../../Components/InputCustom';
import IconBtn from '../../../Components/IconBtn';
import Btn from '../../../Components/Btn';
import { reorderItems } from './FormCustomUtils';

import styles from './FormCustomMultipleControls.module.css';

export default class FormCustomMultipleControls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            controls: props.controls ? props.controls : [{
                id: 1,
                text: '',
                editMode: false
            }]
        };
    }

    addControl() {
        this.setState(state => {
            const controls = [...state.controls, {
                id: Math.round(Math.random() * 10000),
                text: '',
                editMode: true
            }];

            return { controls };

        }, () => {
            this.props.onControlsChange(this.state.controls);
        });

    }

    updateControl(id, text) {
        this.setState(state => {
            const controls = state.controls.map(control => {
              if (control.id === id) {
                control.text = text;
              }
      
              return control;
            });
      
            return { controls };

        }, () => {
            this.props.onControlsChange(this.state.controls);
        });

    }

    removeControl(id) {
        this.setState(state => {
            const controls = state.controls.filter(control => {
                return id !== control.id;
            });
            
            return { controls };

        }, () => {
            this.props.onControlsChange(this.state.controls);
        });

    }

    editControl(id, status) {
        this.setState(state => {
            const controls = state.controls.map(control => {
                if (control.id === id) {
                  control.editMode = status;
                }
        
                return control;
            });
        
            return { controls };
        });
    }

    handleControlBlur(id, text) {
        this.updateControl(id, text);
        this.editControl(id, false);
    }

    onDragEnd(result) {
        if(!result.destination) {
			return;
		}

		const controls = reorderItems(
			this.state.controls,
			result.source.index,
			result.destination.index
		);

		this.setState({ controls }, () => {
            this.props.onControlsChange(this.state.controls)
        });
    }

    render() {
        return (
			<Fieldset label="Answers">
				<div className={styles.anwser_list}>
					<DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
						<Droppable droppableId="droppable-answers">
							{(provided, snapshot) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{this.state.controls.map((control, i) =>
										<Draggable
											key={control.id}
											draggableId={`control-${control.id}`}
											index={i}>
											{(provided, snapshot) => (
												<div
													key={control.id}
													className={styles.anwser_row}
													ref={provided.innerRef}
													{...provided.draggableProps}>
													<span className={styles.anwser_col_nb}>
														{i+1}.
													</span>
													<span className={styles.anwser_col_input}>
														<InputCustom
															value={control.text}
															onChange={(e) => this.handleControlBlur(control.id, e.target.value)}
															fullWidth={true}
														/>
													</span>
													<span className={styles.anwser_col_actions}>
														<IconBtn 
															icon="far fa-trash-alt"
															onClick={this.removeControl.bind(this, control.id)}
															tooltip="Delete answer"
														/>
														<span  {...provided.dragHandleProps}>
															<IconBtn 
																icon="fas fa-sort"
																tooltip="Sort answer"
															/>
														</span>
													</span>
												</div>
											)}
										</Draggable>
									)}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				
					<div className={styles.anwser_row}>
						<span className={styles.anwser_col_nb}>
							{this.state.controls.length + 1}.
						</span>
						<span className={styles.anwser_col_input}>
							<Btn
								message="Add an answer"
								onClick={this.addControl.bind(this)}
								style="reverse"
							/>
						</span>
					</div>
				</div>
			</Fieldset>
		);
    }
}