import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Btn from '../../../../Components/Btn';
import InputCustom from '../../../../Components/InputCustom';
import Modal from '../../../../Components/Modal';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import ModalFooter from '../../../../Components/ModalFooter';
import IconBtn from '../../../../Components/IconBtn';
import {ListItem} from '../../../../Components/List';
import Picto from '../../../../Components/Picto';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import { CampaignsContext } from '../../context';
export default function GraphPatchElementItem({
	item,
	patch,
	canSelectMainElement = false,
	setMainElement,
	isMainElement = false
}) {

	const tooltipID = uuidv4();
	const {
		handleSetPatchDefinitions,
	} = CampaignsContext();
	const [t] = useTranslation(['common']);
	const [isEditSelector, setIsEditSelector] = useState(false);
	const [isEditName,setIsEditName] = useState(false);
	const [currentSelector, setCurrentSelector]= useState({uuid: '', selector:'',name:''});

	// const editSelector = (id) => {
	// 	const currentSelector = patch.data.find((elem) => elem.uuid === id);
	// 	setCurrentSelector({uuid:id, selector: currentSelector.cssSelector,name:currentSelector.selectorName});
	// 	setIsEditSelector(true);
	// 	setIsEditName(false);
	// };

	const editName = (id) => {
		const currentSelector = patch.data.find((elem) => elem.uuid === id);
		setCurrentSelector({uuid:id, selector: currentSelector.cssSelector,name:currentSelector.selectorName});
		setIsEditSelector(false);
		setIsEditName(true);
	};

	const handleChangeInput = (e,type) => {
		let {value} = e.target;
		if(type === 'selector') setCurrentSelector((current)=>({...current, selector:value}));
		if(type === 'name') setCurrentSelector((current)=>({...current, name:value}));
	};

	const handleChangePatch = () => {
		const copyData = [...patch.data];
		const currentIndex = copyData.findIndex(
			(elem) => elem.uuid === item.uuid
		);
		if (isEditSelector) {
			if(!currentSelector.selector) return;
			copyData[currentIndex].cssSelector = currentSelector.selector;
			copyData[currentIndex].selectorList['custom'] =
                currentSelector.selector;
		}
		if (isEditName) {
			if(!currentSelector.name) return;
			copyData[currentIndex].selectorName = currentSelector.name;
		}
		handleSetPatchDefinitions({
			data: copyData,
			urlPatch: patch.urlPatch
		});
	};

	const isEmpty = () => {
		if (item.modifications.style) return false;
		if (item.modifications.text) return false;
		if (item.modifications.attributes) return false;
		if (item.modifications.uploadImage) return false;
		if (item.modifications.link) return false;
		if (item.modifications.deletion) return false;
		return true;
	};

	const getTagColor = (tagName) => {
		if (tagName) {
			switch (tagName.toLowerCase()) {
				case 'h1':
				case 'h2':
				case 'h3':
				case 'h4':
				case 'h5':
				case 'h6':
					return 'green';
				case 'p':
					return 'green';
				case 'input':
					return 'yellow';
				case 'a':
					return 'blue';
				case 'button':
					return 'blue';
				case 'img':
					return 'orange';
			}
		}
		return 'purple';
	};

	const getTagIcon = (tagName) => {
		if (tagName) {
			switch (tagName.toLowerCase()) {
				case 'h1':
				case 'h2':
				case 'h3':
				case 'h4':
				case 'h5':
				case 'h6':
					return 'fas fa-heading';
				case 'p':
					return 'fas fa-paragraph';
				case 'input':
					return 'far fa-keyboard';
				case 'a':
					return 'fas fa-link';
				case 'button':
					return 'fas fa-square';
				case 'img':
					return 'far fa-image';
			}
		}
		return 'fas fa-code';
	};

	if (!item || !item.modifications) {
		return <></>;
	}

	return (
		<>
			<ListItem
				picto={isMainElement ?
					<>
						<span
							className='graphic_patch_picto_notif_wrapper'
							data-tip={t('patch.abTestMainElement')}
							data-for={tooltipID}
						>
							<Picto
								icon={getTagIcon(item.tag)}
								color={getTagColor(item.tag)}
							/>
							<span className='graphic_patch_picto_notif'>
								<i className='fas fa-crown'></i>
							</span>
						</span>
						<ReactTooltip
							id={tooltipID}
							backgroundColor="black"
							effect="solid"
							place="bottom"
							globalEventOff="mouseup"
							delayShow={600}
						/>
					</>
					:
					<Picto
						icon={getTagIcon(item.tag)}
						color={getTagColor(item.tag)}
					/>
				}
				text={item.selectorName}
				description={item.cssSelector}
				value={
					<div className='graphic_patch_el_tag_list'>
						{item.modifications.style && (
							<span className="graphic_patch_el_tag graphic_patch_el_tag_style">
								{t('patch.update.style')}
							</span>
						)}
						{item.modifications.text && (
							<span className="graphic_patch_el_tag graphic_patch_el_tag_text">
								{t('patch.update.text')}
							</span>
						)}
						{item.modifications.attributes && (
							<span className="graphic_patch_el_tag graphic_patch_el_tag_attr">
								{t('patch.update.attributes')}
							</span>
						)}
						{item.modifications.uploadImage && (
							<span className="graphic_patch_el_tag graphic_patch_el_tag_img">
								{t('patch.update.uploadImage')}
							</span>
						)}
						{item.modifications.link && (
							<span className="graphic_patch_el_tag graphic_patch_el_tag_link">
								{t('patch.update.link')}
							</span>
						)}
						{item.modifications.deletion && (
							<span className="graphic_patch_el_tag graphic_patch_el_tag_del">
								{t('patch.update.deletion')}
							</span>
						)}
						{isEmpty() &&
							<span className='s_13 grey_2'>{t('patch.Not modified')}</span>
						}
					</div>
				}
				valueMaxWidth={null}
				actions={
					<>
						<IconBtn
							icon="fas fa-pen"
							onClick={() => editName(item.uuid)}
							tooltip={t('patch.Rename element')}
						/>
					</>
				}
			/>
			<Modal isOpen={isEditName} onClose={() => setIsEditName(false)}>
				<ModalHeader
					title={t('patch.Rename element')}
					hasBorder="true"
				/>
				<ModalBody>
					<InputCustom
						value={currentSelector.name && currentSelector.name}
						placeholder={item.selectorName}
						onChange={(e)=>handleChangeInput(e,'name')}
						fullWidth={true}
					/>
				</ModalBody>
				<ModalFooter
					primaryAction={
						<Btn
							message={t('actions.ok')}
							disabled={currentSelector.name === ''}
							onClick={()=>{handleChangePatch(); setIsEditName(false);}}
						/>
					}
					secondaryAction={
						<Btn
							message={t('actions.cancel')}
							style="ghost"
							color="secondary"
							onClick={() => setIsEditName(false)}
						/>
					}
				/>
			</Modal>
		</>
	);
}
