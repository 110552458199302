import React from 'react';

export default function LabelSelector({
	selectableLabels,
	selectedLabelIds,
	labelSelected,
	inline = false,
	size = 'xl',
	nbColumn = 2 // 2 or 3, only with inline==false
}) {

	const cssClass = ['campaign_label_list'];

	if (inline) {
		cssClass.push('inline');
	}else{
		cssClass.push('campaign_label_list_vert');
		if (nbColumn === 3) {
			cssClass.push('campaign_label_list_vert_3');
		}
	}

	if (size) {
		cssClass.push(size);
	}

	const getClassName = (label) => {
		return !selectedLabelIds.includes(label.id)
			? `campaign_label campaign_label_color campaign_label_${label.color.toLowerCase()}`
			: `campaign_label campaign_label_color campaign_label_${label.color.toLowerCase()} selected`;
	};

	return (
		<ul className={cssClass.join(' ')}>
			{selectableLabels.map(label =>
				<li key={label.id}>
					<a className={getClassName(label)}
						onClick={() => labelSelected(label)}>
						<span className="flex_item_full">{label.name || 'No name'}</span>
						{selectedLabelIds.includes(label.id) &&
							<span className="campaign_label_remove">
								<i className="fas fa-times"></i>
							</span>
						}
					</a>
				</li>
			)}
		</ul>
	);
}