import React, {useState, useEffect} from 'react';
import {useAnalyticsContext} from '../../context/AnalyticsContextProvider';
import {useInteractionContext} from '../../context/InteractionContext';
import AnalitycsBoardLevel1 from './AnalitycsBoardLevel1';
import AnalitycsBoardLevel2 from './AnalitycsBoardLevel2';
import styles from './Board.module.css';
import PaginationBoard from './PaginationBoard';
import AnalitycsBoardHasValues from './AnalitycsBoardHasValues';

export default function InteractionNoCategoryBoardContainer({interactionServices}) {

	const {
		reportTypeSelected,
		navFilter,
		sourceType,
		handleFilter,
		filterExactValue,
		filter2ExactValue,
		filter2,
		isExactMatch,
		offset,
		setOffset,
		currentPage,
		setCurrentPage,
		selectedCategory,
		utmDimensionsSelected,
		needRefreshForUtmFilter,
		step,
		handleSelectPage,
		deviceSelected,
		excludeFilter,
		categoryIsExactMatch,
		hasCategories,
		hasValues,
		handleFilterHasValuesPage,
		offset2,
		setOffset2,
		currentPage2,
		setCurrentPage2
	} = useInteractionContext();

	const [orderBy, setOrderBy] = useState('UniqueCount');
	const [orderByDesc, setOrderByDesc] = useState('desc');
	const {selectedTenant} = useAnalyticsContext();
	const [currentMetrix, setCurrentMetrix] = useState({
		data: null,
		data2 : null,
		isLoading: true,
		isError: false,
	});

	const [numberOfData, setNumberOfData] = useState(null);
	const [numberOfData2, setNumberOfData2] = useState(null);
	const [tableToRender, setTableToRender] = useState(<></>);
	const [hasValuesTableToRender, setHasValuesTableToRender] = useState(<></>);
	const [pageSize, setpageSize] = useState(10);

	useEffect(() => {
		const timer = setTimeout(() => {
			refreshBoard();
		}, 500);
		return () => {
			clearTimeout(timer);
		};
	}, [
		filterExactValue,
		reportTypeSelected,
		navFilter,
		orderBy,
		orderByDesc,
		sourceType,
		pageSize,
		offset,
		offset2, 
		selectedTenant,
		needRefreshForUtmFilter,
		filter2ExactValue,
		selectedCategory,
		deviceSelected,
		excludeFilter,
		isExactMatch,
		categoryIsExactMatch,
		hasValues,
	]);

	const refreshBoard = () => {
		setCurrentMetrix((current) => ({
			...current,
			data: null,
			data2: null,
			isLoading: true,
		}));
		const categoryName = selectedCategory === 'Uncategorized' ? '' : selectedCategory ? selectedCategory : '';
		const isUncategorized = selectedCategory === 'Uncategorized';
		const isExactMatchV = hasCategories ? isExactMatch : false;
		if(hasValues){
			interactionServices.getBoardMetrics(
				deviceSelected,
				navFilter,
				selectedTenant,
				reportTypeSelected,
				filterExactValue,
				isExactMatch, 
				filter2,
				filter2ExactValue,
				sourceType,
				orderBy,
				orderByDesc === 'desc' ? true : false,
				pageSize,
				offset,
				categoryName,
				isUncategorized,
				utmDimensionsSelected,
				excludeFilter,
				categoryIsExactMatch,
				(res, headers) => {
					if (numberOfData !== parseInt(headers('x-total-count'))) {
						setNumberOfData(parseInt(headers('x-total-count')));
					}
					const nameOfMethod = step === 4 ? 'getBoardMetricsHasValuesPages' : 'getBoardMetricsHasValues';
					interactionServices[nameOfMethod](deviceSelected,
						navFilter,
						selectedTenant,
						reportTypeSelected,
						filterExactValue,
						isExactMatch, 
						filter2,
						filter2ExactValue,
						sourceType,
						orderBy,
						orderByDesc === 'desc' ? true : false,
						pageSize,
						offset2,
						categoryName,
						isUncategorized,
						utmDimensionsSelected,
						excludeFilter,
						categoryIsExactMatch,(res2, headers2) => {
							if (numberOfData2 !== parseInt(headers2('x-total-count'))) {
								setNumberOfData2(parseInt(headers2('x-total-count')));
							}
							setCurrentMetrix({
								data: res,
								data2: res2,
								isLoading: false,
								isError: false,
							});
							renderTable(res,res2);
						});
				},
				(err) => console.log(err)
			);
			
		}else{
			setHasValuesTableToRender(<></>);
			const nameOfMethod = step === 4 ? 'getBoardMetricsHasValuesPages' : 'getBoardMetrics';
			interactionServices[nameOfMethod](
				deviceSelected,
				navFilter,
				selectedTenant,
				reportTypeSelected,
				filterExactValue,
				isExactMatch, 
				filter2,
				filter2ExactValue,
				sourceType,
				orderBy,
				orderByDesc === 'desc' ? true : false,
				pageSize,
				offset,
				categoryName,
				isUncategorized,
				utmDimensionsSelected,
				excludeFilter,
				categoryIsExactMatch,
				(res, headers) => {
					if ( parseInt(headers('x-total-count'))) {
						setNumberOfData(parseInt(headers('x-total-count')));
					}
					setCurrentMetrix({
						data: res,
						data2: null,
						isLoading: false,
						isError: false,
					});
					renderTable(res);
				},
				(err) => console.log(err)
			);
		}
	};


	const handleSort = (orderField, order) => {

		setOrderByDesc(order);
		setOrderBy(orderField);
		setOffset(0);
		setCurrentPage(1);
	};

	function renderTable(data,data2) {
		if (reportTypeSelected === 'Interaction') {
			if (!filterExactValue) {
				setTableToRender(
					<AnalitycsBoardLevel1
						title="Interaction name"
						unique="Unique Interaction"
						count="Number of interactions"
						distinctCount="Distinct Count"
						session="Session Count"
						handleFilter={handleFilter}
						data={data}
						orderBy={orderBy}
						orderByDesc={orderByDesc}
						handleSort={handleSort}
						reportType={reportTypeSelected}
					/>
				);
				if(hasValues){
					setHasValuesTableToRender(
						<AnalitycsBoardHasValues
							title="Interaction name"
							unique="Unique Interaction"
							count="Number of interactions"
							distinctCount="Distinct Count"
							session="Session Count"
							handleFilter={handleFilter}
							data={data2}
							orderBy={orderBy}
							orderByDesc={orderByDesc}
							handleSort={handleSort}
							reportType={reportTypeSelected}
						/>);
				}
			}
			else if (filterExactValue && !filter2) {
				setTableToRender(
					<AnalitycsBoardLevel1
						title="Interaction name"
						unique="Unique Interaction"
						count="Number of interactions"
						distinctCount="Distinct Count"
						session="Session Count"
						handleFilter={handleFilter}
						data={data}
						orderBy={orderBy}
						orderByDesc={orderByDesc}
						handleSort={handleSort}
						reportType={reportTypeSelected}
					/>
				);
				if(hasValues){
					setHasValuesTableToRender(
						<AnalitycsBoardHasValues
							title="Interaction name"
							unique="Unique Interaction"
							count="Number of interactions"
							distinctCount="Distinct Count"
							session="Session Count"
							handleFilter={handleFilter}
							data={data2}
							orderBy={orderBy}
							orderByDesc={orderByDesc}
							handleSort={handleSort}
							reportType={reportTypeSelected}
						/>);
				}
			}
			else if (filterExactValue) {
				setTableToRender(
					<AnalitycsBoardLevel2
						sectionTitle={hasValues ? 'Pages' : ''}
						name="Page name"
						title="Interaction"
						unique="Unique Interaction"
						count="Number of interactions"
						distinctCount="Distinct Count"
						session="Session Count"
						data={data}
						orderBy={orderBy}
						orderByDesc={orderByDesc}
						handleSort={handleSort}
						reportType={reportTypeSelected}
					/>
				);
				if(hasValues){
					setHasValuesTableToRender(
						<AnalitycsBoardHasValues
							sectionTitle="Interaction values"
							title="Interaction name"
							unique="Unique Interaction"
							count="Number of interactions"
							distinctCount="Distinct Count"
							session="Session Count"
							handleFilter={handleFilter}
							data={data2}
							orderBy={orderBy}
							orderByDesc={orderByDesc}
							handleSort={handleSort}
							reportType={reportTypeSelected}
							handleFilterHasValuesPage={handleFilterHasValuesPage}
						/>);
				}
				
			}
		}
		if (reportTypeSelected === 'Page') {
			if (!filterExactValue && !filter2) {
				setTableToRender(
					<AnalitycsBoardLevel1
						title="B-Page name"
						unique="Unique Interaction"
						count="Number of interactions"
						distinctCount="Distinct Count"
						session="Session Count"
						handleFilter={handleSelectPage}
						data={data}
						orderBy={orderBy}
						orderByDesc={orderByDesc}
						handleSort={handleSort}
						reportType={reportTypeSelected}
					/>
				);
				if(hasValues){
					setHasValuesTableToRender(
						<AnalitycsBoardHasValues
							title="Interaction name"
							unique="Unique Interaction"
							count="Number of interactions"
							distinctCount="Distinct Count"
							session="Session Count"
							handleFilter={handleFilterHasValuesPage}
							data={data2}
							orderBy={orderBy}
							orderByDesc={orderByDesc}
							handleSort={handleSort}
							reportType={reportTypeSelected}
						/>);
				}
			}
			else if (filterExactValue && !filter2) {
				setTableToRender(
					<AnalitycsBoardLevel1
						title="C-Page name"
						unique="Unique Interaction"
						count="Number of interactions"
						distinctCount="Distinct Count"
						session="Session Count"
						handleFilter={handleFilter}
						data={data}
						orderBy={orderBy}
						orderByDesc={orderByDesc}
						handleSort={handleSort}
						reportType={reportTypeSelected}
					/>
				);
				if(hasValues){
					setHasValuesTableToRender(
						<AnalitycsBoardHasValues
							title="Interaction name"
							unique="Unique Interaction"
							count="Number of interactions"
							distinctCount="Distinct Count"
							session="Session Count"
							handleFilter={handleFilterHasValuesPage}
							data={data2}
							orderBy={orderBy}
							orderByDesc={orderByDesc}
							handleSort={handleSort}
							reportType={reportTypeSelected}
						/>);
				}
			}
			else if (filterExactValue) {

				if(!hasValues){
					setTableToRender(
						<AnalitycsBoardLevel2
							name="Interaction name"
							title="Page"
							unique="Unique Interaction"
							count="Number of interactions"
							distinctCount="Distinct Count"
							session="Session Count"
							data={data}
							orderBy={orderBy}
							orderByDesc={orderByDesc}
							handleSort={handleSort}
							handleFilter={handleFilter}
							handleFilterHasValuesPage={handleFilterHasValuesPage}
							reportType={reportTypeSelected}
							step={step}
						/>
					);}
				if(hasValues){
					setTableToRender(null);
					setHasValuesTableToRender(
						<AnalitycsBoardHasValues
							title="Interaction name"
							unique="Unique Interaction"
							count="Number of interactions"
							distinctCount="Distinct Count"
							session="Session Count"
							data={data2}
							orderBy={orderBy}
							orderByDesc={orderByDesc}
							handleSort={handleSort}
							reportType={reportTypeSelected}
						/>);
				}
			}
		}
	}
	return (
		<>
			{currentMetrix.isLoading && !currentMetrix.isError && (
				<div className="mask">
					<span className="wheel"></span>
				</div>
			)}
			{!currentMetrix.isLoading && currentMetrix.isError && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_error.svg"
					/>
					<div className="empty_state_title">Cannot fetch data</div>
					<div className="empty_state_text">Please retry later.</div>
				</div>
			)}
			{!currentMetrix.isLoading &&
                !currentMetrix.isError &&
                currentMetrix.data.length == 0 && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_empty.svg"
					/>
					<div className="empty_state_title">
                            No data for this period
					</div>
					<div className="empty_state_text">
                            Maybe try with another period or other filters
					</div>
				</div>
			)}
		
			{!currentMetrix.isLoading &&
                !currentMetrix.isError &&
                currentMetrix.data &&
                currentMetrix.data.length > 0 && tableToRender !== null && (
				<section className={styles.section}>
					<>
						{tableToRender}
						<PaginationBoard
							totalCount={numberOfData}
							pageSize={pageSize}
							handleChangePageSize={(e) => {
								setpageSize(e);
								setOffset(0);
								setOffset2(0);
							}}
							paginate={(e) => setOffset(e >= 0 ? e : 0)}
							skipIdNumber={offset}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
						/>
					</>
				</section>
			)}
			{hasValues && <section className={styles.section}>
				{!currentMetrix.isLoading &&
                !currentMetrix.isError &&
                currentMetrix.data2 &&
                currentMetrix.data2.length > 0 && (
					<>
						{hasValuesTableToRender}
						<PaginationBoard
							totalCount={numberOfData2}
							pageSize={pageSize}
							handleChangePageSize={(e) => {
								setpageSize(e);
								setOffset2(0);
								setOffset(0);
							}}
							paginate={(e) => setOffset2(e >= 0 ? e : 0)}
							skipIdNumber={offset2}
							currentPage={currentPage2}
							setCurrentPage={setCurrentPage2}
						/>

					</>
				)}
			</section>}
		</>

	);
}

