import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import EmptyState from '../../../../../Components/EmptyState';
import Btn from '../../../../../Components/Btn';

export default function DownloadChromeExtension({hasGoodVersion,handleRefresh,extensionId}) {

	const [t, trans] = useTranslation('common');

	const downloadExtension = () => {
		window.open(
			'https://chrome.google.com/webstore/detail/beyable-inspector/' +
                extensionId,
			'_blank',
			'noopener,noreferrer'
		);
	};

	const refresh = () => {
		handleRefresh();
	};

	return (
		<div className="graphic_patch_intro">
			<EmptyState
				verticalAlign={true}
				title={!hasGoodVersion
					? t('patch.pleaseUpgradeExtension')
					: t('patch.pleaseDownloadExtension')}
				text={
					<Trans t={t} i18nKey="patch.afterInstallingExtension">
						After installing the extension, <a onClick={() => refresh()} className="text_link">click here to refresh</a>
					</Trans>
				}
				imageUrl="/Assets/empty_patch.svg"
				textSize='l'
				primaryAction={
					<Btn
						size="l"
						message={t('patch.getExtension')}
						icon="fas fa-cloud-download-alt"
						onClick={() => downloadExtension()}
					/>
				}
			/>
		</div>
	);
}
