const initType = [{ key: 'Page', label: 'Page' }, { key: 'Interaction', label: 'Interaction' }];
export const metricsTypes = [{
	key: 'UniqueCount',
	label: 'Unique count'
},
{
	key: 'Count',
	label: 'Count'
},
{
	key: 'DistinctCount',
	label: 'Distinct count'
},
];

export const sourceTypes = [
	// 'All',
	'Direct',
	'Organic',
	'Referral',
	'Social',
	'Paid',
	'Email',
	'Display',
	'Other',
];



export default initType;

export const FORMAT = 'YYYY/MM/DD';
export const today = moment();
export const monday = moment().startOf('isoWeek').format(FORMAT);
export const formatToday = moment().format(FORMAT);

export const initialUtmFilter = {
	source: {
		name: '',
		label: ''
	},
	medium: {
		name: '',
		label: ''
	},
	campaign: {
		name: '',
		label: ''
	},
	content: {
		name: '',
		label: ''
	}
};
export const initialUtmFilterV2 = {
	source: [
		
	],
	medium: [
		
	],
	campaign: [
		
	],
	content: [
	
	]
};
export const cardFetchOptions = {
	'fromDate': '',
	'toDate': '',
	'metrics': [
		'NumberOfSessions'
	],
	'timeGranularity': 'None',
	'dimensions': [],
	'limit': 15,
	'offset': 0,
	'filters': [],
	'search': null
};
export const search = (fnSetter,table, word,selectList) => {
	if (word.length > 0) {
		fnSetter([]);
		let resultName = table.filter(
			line =>
				line.toUpperCase().match(`.*${word.toUpperCase()}.*`)
		);
		const res = resultName.map(i => {
			return {
				value: i,
				label: i
			};
		});
		fnSetter(res);
	}
	else {
		fnSetter(selectList);
	}
};
export const devices = [
// 	{
// 	value : 'all',
// 	label : 'All'
// },
{
	value : 'Desktop',
	label : 'Desktop'
},
{
	value : 'Mobile',
	label : 'Mobile'
},
{
	value : 'Tablet',
	label : 'Tablet'
}
];