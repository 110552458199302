import React, { useState } from 'react';
import Btn from '../../../../Components/Btn';
import PopinConfirm from './../../../../Components/PopinConfirm';
import useEventBroker from '../../../../Hooks/useEventBroker';

export default function ZoneDeleteModal({ zone, deleteCallback }) {
	const [modalIsOpened, setModalIsOpened] = useState(false);

	const { subscribe } = useEventBroker();

	subscribe('evt/businessoperations/removeBannerFromZoneRequested', e => {
		const data = e.detail;
		if (zone.zoneId === data.zone.zoneId) {
			setModalIsOpened(true);
		}
	}, []);


	const deleteBanner = () => {
		deleteCallback();
		setModalIsOpened(false);
	};

	return (
		<>
			{modalIsOpened &&
				<PopinConfirm
					cancel={() => setModalIsOpened(false)}
					title={'Are you sure you want to remove the banner from this zone ?'}
					message={''}
				>
					<Btn
						onClickFunction={(e) => setModalIsOpened(false)}
						message="Cancel"
						color="secondary"
						style="outline"
					/>
					<Btn
						onClickFunction={deleteBanner}
						message="Delete"
						color="alert"
						icon="fas fa-trash-alt"
					/>
				</PopinConfirm>
			}
		</>
	);
}