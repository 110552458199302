import React, { useContext, useState, useEffect, Fragment } from 'react';
import { AccountSetupContext } from './Context/ContextAccountSetup';
import { Controlled as CodeMirror } from 'react-codemirror2';
// import './ViewSetupCode.css'
import 'codemirror/theme/monokai.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/theme/base16-light.css';
import 'codemirror/theme/xq-light.css';
import 'codemirror/theme/ttcn.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';
import Btn from '../../Components/Btn';
require('codemirror/mode/javascript/javascript');

const ViewSetupCode = function ViewSetupCode({ selectedFiles, setModalIsOppen, accountCurrent }) {
	const [scriptSelected, setSciptSelected] = useState();
	const [theme, setTheme] = useState('monokai');
	const [val, setValue] = useState('Choose a script');

	const {
		dataOperationForStats
	} = useContext(AccountSetupContext);

	function toggleTheme() {
		setTheme(theme === 'ttcn' ? 'monokai' : 'ttcn');
	}
	const options = {
		mode: 'javascript',
		theme: theme,
		lineNumbers: true,
		lineWrapping: true,
		indentUnit: 2,
		autocorrect: true,
		keyMap: 'sublime'
	};
	const selectAScriptToPut = (e) => {
		const script = e.api;
		setSciptSelected(e.accountApiFileName);
		setValue(script);
	};
	useEffect(() => {
		if (selectedFiles && selectedFiles.length !== 0) {
			setSciptSelected(selectedFiles[0].accountApiFileName);
			setValue(selectedFiles[0].api);
		}
	}, [accountCurrent]);

	return (
		<Fragment>
			<div className="flex">
				<div className="flex_item_full">
					<h2 className="page_body_title">Setup script</h2>
				</div>
				<div className="flex_item_fix">
					<div className="btn_switch">
						<Btn
							onClickFunction={toggleTheme}
							icon="fas fa-moon"
							title="Dark Theme"
							style={theme === 'ttcn' ? 'outline' : ''}
							color="grey"
						/>
						<Btn
							onClickFunction={toggleTheme}
							icon="fas fa-sun"
							title="White Theme"
							style={theme === 'ttcn' ? '' : 'outline'}
							color="grey"
						/>
					</div>
					<Btn
						onClickFunction={() => setModalIsOppen(true)}
						message="Select scripts"
					/>
				</div>
			</div>
			<div className="view_setup_script_selection_container">
				<ul className="tabs no_border">
					{selectedFiles && selectedFiles.map(
						(script, i) =>
							<li key={i}>
								<a onClick={(e) => selectAScriptToPut(script)}
									title={script.accountApiFileName}
									className={scriptSelected === script.accountApiFileName ? 'active' : ''}>
									{script.accountApiFileName}
								</a>
							</li>
					)}
				</ul>
			</div>
			{
				val &&
				<div className="view_setup_script_editor">
					<CodeMirror
						value={val && val}
						options={options}
					// onBeforeChange={(editor, data, value) => {
					//     setValue(value)
					// }}
					// onChange={(editor, data, value) => {
					// }}
					/>
				</div>
			}
		</Fragment>
	);
};
export default ViewSetupCode;
