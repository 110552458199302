import React from 'react';
import angular from 'angular';
import { react2angular } from 'react2angular';
import CreateSegmentGroupsListContextProvider from './context/SegmentGroupListContextProvider';
import SegmentGroupList from './SegmentGroupList';
import newInstanceI18next from '../../../Util/traductionUtils';
import { I18nextProvider } from 'react-i18next';

export default function SegmentationListContainer(props) {
	if(location.pathname !== '/Insight/segment/settings'){
		return <></>;
	}
	return (<CreateSegmentGroupsListContextProvider
		$http={props.$http}
		$rootScope={props.$rootScope}
		$routeParams={props.$routeParams}
		$timeout={props.$timeout}
	>
	
		<I18nextProvider i18n={newInstanceI18next}>
			<SegmentGroupList />
		</I18nextProvider>
			
	</CreateSegmentGroupsListContextProvider>);
}

angular.module('beyableSaasApp.SegmentationList', [])
	.component(
		'segmentationlistcontainer',
		react2angular(
			SegmentationListContainer,
			[],
			['$http', '$rootScope', '$routeParams', '$location', '$timeout']
		)
	);
