import { _hop } from '../../../Util';
import { TemplateProperty, Template, TemplatePropertyNameValue } from '../Types/BespokeTypes';
import { propertiesProcessing } from './TemplateOperations';

const { templatePropertyToStyleProperty, setActiveItemIntoCollection } = propertiesProcessing();

export function propertyAccessor() {
	const getPropIfDefined = (properties, name) => {
		if (!properties) return null;
		const prop = properties.find(x => x.Name === name);
		const propValue = prop && prop.Value;
		return { hasValue: propValue !== null && propValue !== undefined, value: propValue }; // allow returning "" or 0
	};
	const getGroupPropertyValue = (properties, editor, containerPropertyName) => {
		const groupProperty = getPropIfDefined(properties, containerPropertyName);
		return (groupProperty != null && groupProperty.hasValue && groupProperty.value[editor.Name] !== null) ? groupProperty.value[editor.Name] : editor.Value;
	};
	const getPropertyValue = (properties: TemplateProperty[], editor: TemplatePropertyNameValue) => {
		const property = getPropIfDefined(properties, editor.Name);
		return property != null && property.hasValue ? property.value : editor.Value;
	};
	const getCollectionItems = (properties, collection) => {
		const valuesFromProperties = getPropIfDefined(properties, collection.Name);
		return (valuesFromProperties != null && valuesFromProperties.hasValue ? valuesFromProperties.value || [] : []);
	};

	const getDependentPropertyValue = (properties, dependentPropertyName, collectionItem) => {
		const split1 = dependentPropertyName.split('.');
		const split2 = split1[0].split('['); // collection case (dependentPropertyName <= "$CURRENT_ITEM$.POSITION")
		const firstLevelPropertyName = split2[0];
		const firstLevelProperty = properties.find(p => p.Name === firstLevelPropertyName);
		if (!firstLevelProperty) return { found: false };
		if (firstLevelProperty.IsCollection) {
			if (split1.length !== 2 || !_hop(collectionItem, split1[1])) return { found: false };
			return { found: true, value: collectionItem[split1[1]] };
		} else if (firstLevelProperty.IsGroup) {
			if (split1.length !== 2 || !_hop(firstLevelProperty.Value, split1[1])) return { found: false };
			return { found: true, value: firstLevelProperty.Value[split1[1]] };
		} else {
			return { found: true, value: firstLevelProperty.Value };
		}
	};

	return {
		getPropertyValue,
		getGroupPropertyValue,
		getCollectionItems,
		getDependentPropertyValue
	};
}


export function propertyChangeApplication() {

	const applyCollectionPropertyValueChange = (
		value: any,
		editorPropertyName: string,
		item: { Name: string; },
		collectionItemIndex: number,
		callback: (arg0: (propToUpdate: TemplateProperty[]) => TemplateProperty[]) => void
	) => {
		const updateProperties = (propToUpdate: TemplateProperty[]): TemplateProperty[] => {
			const p = propToUpdate.find((x) => x.Name === editorPropertyName);
			if (p) {
				const collectionItem = p.Value[collectionItemIndex];
				collectionItem[item.Name] = value;
			}
			return propToUpdate;
		};
		callback(updateProperties);
	};


	const applyPropertyValueChange = (
		value: any,
		editorPropertyName: string,
		callback: (arg0: (propToUpdate: TemplateProperty[]) => TemplateProperty[]) => void
	) => {
		const updateProperties = (propToUpdate: TemplateProperty[]): TemplateProperty[] => {
			if (propToUpdate.length == 0) {
				return [];
			}
			const p = propToUpdate.find((x) => x.Name === editorPropertyName);
			if (p) {
				p.Value = value;
			}

			return propToUpdate;
		};
		callback(updateProperties);
	};

	const applyGroupPropertyValueChange = (
		value: any,
		editorPropertyName: string,
		item: { Name: string; },
		callback: (arg0: (propToUpdate: TemplateProperty[]) => TemplateProperty[]) => void
	) => {
		const updateProperties = (propToUpdate: TemplateProperty[]): TemplateProperty[] => {
			const p = propToUpdate?.find((x) => x.Name === editorPropertyName);
			if (p) {
				const groupItem = p.Value;
				groupItem[item.Name] = value;
			}
			
			return propToUpdate;
		};

		callback(updateProperties);
	};

	const applyChangeIsFolded = (
		editorPropertyName: string,
		isFolded: boolean, 
		callback: (arg0: (propToUpdate: TemplateProperty[]) => TemplateProperty[]) => void
	) => {
		
		const updateProperties = (propToUpdate: TemplateProperty[]): TemplateProperty[] => {
			if (propToUpdate.length == 0) {
				return [];
			}
			const p = propToUpdate.find((x) => x.Name === editorPropertyName);
			if (p) {
				p.Editor.IsOpen = !isFolded;
			}

			return propToUpdate;
		};
		callback(updateProperties);
	};


	const applyAddProperty = (
		collectionName: string,
		property: TemplateProperty,
		callback: (arg0: (propToUpdate: TemplateProperty[]) => TemplateProperty[]) => void
	) => {
		const newItem = templatePropertyToStyleProperty(property).Value;
		//let newLength;

		const insertElement = (x) => {
			let arr: any[] = [];
			if (x.Value.length === 0) {
				arr = [...newItem];
				//newLength = arr.length;
				return arr;
			}
			arr = [...x.Value, ...newItem];
			//newLength = arr.length;
			return arr;
		};

		const mapInsert = (x: TemplateProperty): TemplateProperty =>
			x.Name === collectionName ? { ...x, Value: insertElement(x) } : x;

		const updateProperties = (propToUpdate: TemplateProperty[]): TemplateProperty[] => propToUpdate.map(mapInsert);
		callback(updateProperties);
	};


	const applyDeleteProperty = (
		key: string,
		collectionName: string,
		callback: (arg0: (propToUpdate: TemplateProperty[]) => TemplateProperty[]) => void
	) => {
		const mapDelete = (x: TemplateProperty): TemplateProperty =>
			x.Name === collectionName
				? { ...x, Value: x.Value.filter((v) => v.Key !== key) }
				: x;

		const updateProperties = (propToUpdate: TemplateProperty[]): TemplateProperty[] => propToUpdate.map(mapDelete);
		callback(updateProperties);
	};

	const applyReorderCollectionItems = (list, startIndex, endIndex, property: TemplateProperty, template: Template,
		callback: (arg0: (propToUpdate: TemplateProperty[]) => TemplateProperty[]) => void
	) => {
		const reorder = () => {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);
			return result;
		};
		const updateProperties = (propToUpdate: TemplateProperty[]): TemplateProperty[] => {
			const index = template.Properties.findIndex(x => x.Key === property.Key);
			propToUpdate[index].Value = reorder();
			return propToUpdate;
		};
		callback(updateProperties);
	};

	const applyChangeActiveCollectionItem = (
		editorProperty: TemplateProperty,
		collectionItemIndex: number,
		callback: (arg0: (propToUpdate: TemplateProperty[]) => TemplateProperty[]) => void
	) => {
		const updateProperties = (propToUpdate) => {
			const p = propToUpdate.find((x) => x.Name === editorProperty.Name);
			if (p) {
				setActiveItemIntoCollection(
					editorProperty,
					p.Value,
					collectionItemIndex
				);
			}
			return propToUpdate;
		};
		callback(updateProperties);
	};

	return {
		applyCollectionPropertyValueChange,
		applyGroupPropertyValueChange,
		applyPropertyValueChange,

		applyAddProperty,
		applyDeleteProperty,
		applyReorderCollectionItems,
		applyChangeActiveCollectionItem,

		applyChangeIsFolded
	};
}