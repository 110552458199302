import {initInheritStyles} from './utils';

const scriptExport = function(props) {
	if (!cpg) return;

	var code_wrapper = this;
	var code = code_wrapper.querySelector('span');
	if (!code) return;

	var interaction = props.by_interaction_name || 'copy_code';
	interaction = interaction.split(' ').join('_');
	interaction = interaction.split('.').join('_');
	interaction = encodeURIComponent(interaction);
	interaction = 'evtcpg:' + interaction;

	var tooltip = document.createElement('span');
	tooltip.style.display = 'none';
	tooltip.classList.add('by_promo_code_tooltip');
	tooltip.innerText = props.copy_text;
	code.appendChild(tooltip);

	var can_copy = true;
	code.addEventListener('click', function(ev){
		if(! can_copy) return;

		var range = document.createRange();
		range.selectNode(code);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		try {
			var success = document.execCommand('copy');
			if ( ! success) return;
	
			tooltip.style.display = '';
			can_copy = false;
	
			setTimeout(function(){
				tooltip.style.display = 'none';
				can_copy = true;
			}, 3000);
		} catch (err) {}
	
		window.getSelection().removeAllRanges();

		if (interaction) {
			BY.by_SendInteractionForCampaign(0, interaction, SId);
		}
	});
};

const html = `
<span data-gjs-type="promo_code_inner"></span>
`;

const styles = `
.BY_BLD .by_promo_code_tooltip{
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%);
	background: #1C1E22;
	color: #fff;
	padding: 4px 10px;
	border-radius: 3px;
	font-size: 12px;
	font-weight: 400;
	white-space: nowrap;
	letter-spacing: initial;
	line-height: 1.2;
	opacity: 0;

	top: 100%;
	margin-top: -4px;
	-webkit-animation: BY_BLD_tooltip_bottom 3s;
	animation: BY_BLD_tooltip_bottom 3s;
}
@keyframes BY_BLD_tooltip_bottom {
	0%{
		opacity: 0;
		margin-top: -24px;
	}
	7%{
		opacity: 1;
		margin-top: -4px;
	}
	93%{
		opacity: 1;
		margin-top: -4px;
	}
	100%{
		opacity: 0;
		margin-top: 60px;
	}
}
`;

const icon = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" viewBox="0 0 40 30">
  <defs>
    <clipPath id="clip-ico_promo_code_1">
      <rect width="40" height="30"/>
    </clipPath>
  </defs>
  <g id="ico_promo_code_1" data-name="ico_promo_code – 1" clip-path="url(#clip-ico_promo_code_1)">
    <g id="Tracé_2088" data-name="Tracé 2088" transform="translate(9300 834)" fill="#e9eaf0">
      <path d="M -9266.9990234375 -810.0001220703125 L -9293.0009765625 -810.0001220703125 L -9293.0009765625 -814.1002807617188 C -9290.720703125 -814.5654907226562 -9288.9990234375 -816.586669921875 -9288.9990234375 -819.00146484375 C -9288.9990234375 -821.416015625 -9290.720703125 -823.4363403320312 -9293.0009765625 -823.9007568359375 L -9293.0009765625 -828.00048828125 L -9266.9990234375 -828.00048828125 L -9266.9990234375 -823.900390625 C -9269.279296875 -823.4353637695312 -9271.0009765625 -821.4151000976562 -9271.0009765625 -819.00146484375 C -9271.0009765625 -816.5858154296875 -9269.279296875 -814.5645751953125 -9266.9990234375 -814.0999145507812 L -9266.9990234375 -810.0001220703125 Z" stroke="none"/>
      <path d="M -9267.9990234375 -811.0001220703125 L -9267.9990234375 -813.3421020507812 C -9270.328125 -814.1676635742188 -9272.0009765625 -816.3928833007812 -9272.0009765625 -819.00146484375 C -9272.0009765625 -821.6080932617188 -9270.328125 -823.8323364257812 -9267.9990234375 -824.6581420898438 L -9267.9990234375 -827.00048828125 L -9292.0009765625 -827.00048828125 L -9292.0009765625 -824.6586303710938 C -9289.671875 -823.8333740234375 -9287.9990234375 -821.6090087890625 -9287.9990234375 -819.00146484375 C -9287.9990234375 -816.3938598632812 -9289.671875 -814.168701171875 -9292.0009765625 -813.3426513671875 L -9292.0009765625 -811.0001220703125 L -9267.9990234375 -811.0001220703125 M -9265.9990234375 -809.0001220703125 L -9294.0009765625 -809.0001220703125 L -9294.0009765625 -814.99951171875 C -9291.79296875 -815.00048828125 -9289.9990234375 -816.7953491210938 -9289.9990234375 -819.00146484375 C -9289.9990234375 -821.2068481445312 -9291.7939453125 -823.0010986328125 -9294.0009765625 -823.0010986328125 L -9294.0009765625 -829.00048828125 L -9265.9990234375 -829.00048828125 L -9265.9990234375 -823.0010986328125 C -9268.20703125 -823.0001220703125 -9270.0009765625 -821.206298828125 -9270.0009765625 -819.00146484375 C -9270.0009765625 -816.7947998046875 -9268.2060546875 -814.99951171875 -9265.9990234375 -814.99951171875 L -9265.9990234375 -809.0001220703125 Z" stroke="none" fill="#acafc0"/>
    </g>
    <path id="Tracé_2089" data-name="Tracé 2089" d="M3.948-.084A.8.8,0,0,1,3.414.27.8.8,0,0,1,2.862.156a.733.733,0,0,1-.324-.438A.724.724,0,0,1,2.7-.9L8.016-8.316a.794.794,0,0,1,.528-.354.806.806,0,0,1,.552.114.7.7,0,0,1,.324.438.729.729,0,0,1-.156.606ZM2.892-3.36a2.068,2.068,0,0,1-1.566-.558,2.037,2.037,0,0,1-.534-1.47,4.282,4.282,0,0,1,.282-1.6A2.621,2.621,0,0,1,1.9-8.112,2.066,2.066,0,0,1,3.2-8.532a2.027,2.027,0,0,1,1.554.57A2.08,2.08,0,0,1,5.292-6.48,4.317,4.317,0,0,1,5.01-4.872a2.515,2.515,0,0,1-.816,1.11A2.1,2.1,0,0,1,2.892-3.36Zm-.012-1.3a.475.475,0,0,0,.414-.282,2.433,2.433,0,0,0,.252-.714,4.226,4.226,0,0,0,.09-.864.928.928,0,0,0-.1-.558.389.389,0,0,0-.33-.15.481.481,0,0,0-.408.282,2.32,2.32,0,0,0-.258.714,4.226,4.226,0,0,0-.09.864.928.928,0,0,0,.1.558A.389.389,0,0,0,2.88-4.656ZM8.748.132A2.068,2.068,0,0,1,7.182-.426,2.037,2.037,0,0,1,6.648-1.9a4.282,4.282,0,0,1,.282-1.6A2.621,2.621,0,0,1,7.752-4.62,2.066,2.066,0,0,1,9.06-5.04a2.027,2.027,0,0,1,1.554.57,2.08,2.08,0,0,1,.534,1.482,4.317,4.317,0,0,1-.282,1.608A2.515,2.515,0,0,1,10.05-.27,2.1,2.1,0,0,1,8.748.132Zm-.012-1.3a.487.487,0,0,0,.42-.288,2.3,2.3,0,0,0,.252-.714,5.169,5.169,0,0,0,.1-.858A1.053,1.053,0,0,0,9.4-3.588a.368.368,0,0,0-.324-.156.487.487,0,0,0-.42.288,2.4,2.4,0,0,0-.258.714,4.2,4.2,0,0,0-.09.87,1.023,1.023,0,0,0,.108.552A.368.368,0,0,0,8.736-1.164Z" transform="translate(14 19)" fill="#acafc0"/>
  </g>
</svg>
`;

export const componentPromoCode = {
	model: {
		init() {
			this.listenTo(this, 'change:code_text', this.updateText);
		},
		updateText(component, value) {
			const children = this.attributes.components.models[0];
			children.components(value);
		},
		defaults: {
			name: 'Promo code',
			droppable: false,
			editable: false,
			layerable: true,
			tagName: 'div',
			components: html,
			traits: [
				{
					type: 'text',
					name: 'code_text',
					label: 'Promo code',
					changeProp: true,
					placeholder: 'PROMOCODE',
					tab: 'Settings'
				},
				{
					type: 'text',
					name: 'copy_text',
					label: 'Copy text',
					changeProp: true,
					placeholder: 'Copied',
					tab: 'Settings'
				},
				{
					type: 'text',
					name: 'interaction_name',
					label: 'Copy interaction name',
					changeProp: true,
					placeholder: 'copy_code',
					required: true,
					tab: 'Settings'
				},
				{
					type: 'responsive',
					name: 'responsive',
					label: 'Responsive',
					changeProp: true,
					tab: 'responsive'
				}
			],
			stylable: ['margin'],
			style: {
				'display': 'flex',
				'margin-top': '8px',
				'margin-bottom': '8px',
				'margin-left': '0px',
				'margin-right': '0px',
				'text-align': 'left',
				'font-size': '16px',
			},
			styles: styles,
			'responsive': '',
			'code_text': 'PROMOCODE',
			'copy_text': 'Copied',
			'interaction_name': 'copy_code',
			'script-export': scriptExport,
			'script-props': [
				'code_text',
				'copy_text',
				'interaction_name'
			],
			icon: icon
		}
	}
};

export const componentPromoCodeInner = {
	model: {
		init() {
			initInheritStyles(this);
		},
		ineritedStyles: [
			{
				key: 'background-color',
				prop: 'background-color',
				type: 'color',
				label: 'Background color',
				value: '#eeeeee',
				tab: 'style'
			},
			{
				key: 'color',
				prop: 'color',
				type: 'color',
				label: 'Text color',
				value: '#222222',
				tab: 'style'
			},
			{
				key: 'border-color',
				prop: 'border-color',
				type: 'color',
				label: 'Border color',
				value: '#bbbbbb',
				tab: 'style'
			},
			{
				key: 'margin',
				prop: 'margin',
				type: 'select',
				label: 'Alignment',
				value: '0 auto 0 0',
				options: [
					{ id: '0 auto 0 0', name: 'Left'},
					{ id: '0 auto', name: 'Center'},
					{ id: '0 0 0 auto', name: 'Right'},
				],
				tab: 'style'
			},
			{
				key: 'height',
				prop: 'height',
				type: 'number',
				unit: 'px',
				label: 'Height',
				value: 44,
				tab: 'style'
			},
			{
				key: 'width',
				prop: 'width',
				type: 'button_width',
				label: 'Width',
				value: 'auto',
				min: 10,
				max: 400,
				tab: 'style'
			},
			{
				key: 'border-radius',
				prop: 'border-radius',
				type: 'number',
				unit: 'px',
				label: 'Radius',
				value: 6,
				tab: 'style'
			},
			{
				key: 'font-size',
				prop: 'font-size',
				type: 'number',
				unit: 'px',
				label: 'Font size',
				value: 14,
				tab: 'style'
			},
			{
				key: 'letter-spacing',
				prop: 'letter-spacing',
				type: 'number',
				unit: 'px',
				label: 'Letter spacing',
				value: 2,
				tab: 'style'
			},
			{
				key: 'padding-left',
				prop: 'padding-left',
				type: 'number',
				unit: 'px',
				label: 'horizontal padding',
				value: 16,
				tab: 'style',
				syncProp: 'padding-right'
			},
		],
		defaults: {
			removable: false,
			draggable: false,
			droppable: false,
			selectable: false,
			layerable: false,
			hoverable: false,
			highlightable: false,
			editable: false,
			tagName: 'span',
			content: 'PROMOCODE',
			stylable: [],
			traits: [],
			style: {
				'position': 'relative',
				'display': 'flex',
				'align-items': 'center',
				'justify-content': 'center',
				'text-decoration': 'none',
				'padding-top': '0',
				'padding-bottom': '0',
				'padding-left': '2em',
				'padding-right': '2em',
				'font-weight': '600',
				'border-style': 'dashed',
				'border-width': '1px',
				'border-color': '#bbbbbb',
				'cursor': 'pointer'
			}
		}
	}
};

