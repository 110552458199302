
export const NumberOfSessions = "NumberOfSessions";
export const NumberOfPageViews = "NumberOfPageViews";
export const DurationSeconds = "DurationSeconds";
export const NumberOfBounces = "NumberOfBounces";
export const listMetricGeolocalisation =  [
	{
		key: 'NumberOfSessions',
		label: 'Number of sessions'
	},
	{
		key: 'NumberOfUniqueVisitors',
		label: 'Number of unique visitors'
	},
	{
		key: 'NumberOfPageViews',
		label: 'Number of pages views'
	}
] 
export const metricsTypes = [
	{
		key: 'sessionsAndPagesViews',
		label: 'Sessions And Page views',
		value: [NumberOfPageViews, NumberOfSessions]
	},
	{
		key: 'NumberOfPageViews',
		label: 'Number of Pages Views',
		value: [NumberOfPageViews]
	},
	{
		key: 'NumberOfSessions',
		label: 'Number of sessions',
		value: [NumberOfSessions]
	},
	// {
	// 	key: 'AverageTimeOnPage',
	// 	label: 'Average time on page',
	// 	value: [DurationSeconds]
	// },
	{
		key: 'BounceRate',
		label: 'Bounce rate',
		value: [NumberOfBounces]
	},
];
