import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../Components/Checkbox';
import InputCustom from '../../../../Components/InputCustom';
import SectionMessage from '../../../../Components/SectionMessage';
import { useProductFeedEditionContext } from '../../context/ProductFeedEditionContext';
import SelectDropdown from '../../../../Components/SelectDropdown';
import Btn from '../../../../Components/Btn';

export default function ConnectionChooseAuth({ children }) {
	const {
		productFeedObject,
		handleChangeConnexion,
		handleChangeSimpleField,
		hasErrorsOnCreation,
		stateOfBackendDetection
	} = useProductFeedEditionContext();

	const authTypeOptions = [
		{ value: 'LoginPassword', label: 'Login / Password' },
		{ value: 'ApiKey', label: 'Api Key' },
		{ value: 'SasToken', label: 'Sas Token' },
		{ value: 'AuthorizationHeader', label: 'Authorization Header' }
	];

	const [valueOfSelectedAuthType, setValueOfSelectedAuthType] = useState(authTypeOptions[0].value);

	if (!productFeedObject.hasOwnProperty('auth')) {
		return <></>;
	}

	return (
		<>
			<SelectDropdown
				label={'Authentication type'}
				optionsList={authTypeOptions}
				value={valueOfSelectedAuthType}
				onChange={setValueOfSelectedAuthType}
				size={'l'}
			/>
			<Btn
				onClick={() => {
					handleChangeConnexion('type', valueOfSelectedAuthType);
				}}
				message="Choose this type of Authentication"
			></Btn>
		</>
	);
}
