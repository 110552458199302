import React, { Dispatch, createContext, useContext, useEffect, useState,SetStateAction } from 'react';
import { SegmentGroupType, convertSegmentGroupTypeFromApi } from './EntityTypes';
import { SegmentGroupStatus } from '../../../../Services/SegmentGroupServicesTypes';
import SegmentGroupServices from '../../../../Services/SegmentGroupServices';
import SystemServices from '../../../../Services/SystemServices';
import ImpersonatingServices from '../../../../Services/ImpersonatingServices';

const SegmentGroupsListContext = createContext<SegmentGroupsListContextType | undefined>(undefined);

function useSegmentGroupsListContext() {
	const context = useContext(SegmentGroupsListContext);
	if (!context) throw Error('useSegmentGroupsListContext can only be used inside a SegmentGroupsListContextProvider');
	return context;
}

type CreateSegmentGroupsListContextProviderType = {
	$http: any,
	$rootScope: any,
	$routeParams: any,
	$timeout: any,
	children: any
}


type status = {
	value: string;
	label: string;
}

const statusArray:status[] = [
	{
		value: 'Live',
		label: 'Live'
	},
	{
		value: 'Stopped',
		label: 'Stopped'
	},

];

const CreateSegmentGroupsListContextProvider = (props: CreateSegmentGroupsListContextProviderType) => {

	const getStorageStatusFilter = () => {
		const storage = sessionStorage.getItem('segment_filter_status');
		if (storage) {
			return JSON.parse(storage);
		}
		return [];
	};

	const systemServices = new SystemServices(props.$rootScope, props.$timeout);
	const impersonatingServices = new ImpersonatingServices(props.$rootScope, props.$routeParams);

	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount ||props.$rootScope.User.Account.Key;
	// const isAdmin = impersonatingServices.isAnAdmin();

	const segmentGroupServices = new SegmentGroupServices(accountId, props.$http);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [segmentGroups, setSegmentGroups] = useState<SegmentGroupType[]>([]);
	const [sureToDelete, setSureToDelete] = useState<boolean>(false);
	const [idToDelete, setIdToDelete] = useState<string>();
	const [segmentListFilter, setsegmentListFilter] = useState<SegmentGroupType[]>([]);
	const [noData, setNoData] = useState<boolean>(false);
	const [noFilteredData, setNoFilteredData] = useState<boolean>(false);
	const [firstDisplay, setFirstDisplay] = useState<boolean>(true);
	const [valueInSearchBar, setValueInSearchBar] = useState<string>('');
	const [statusSelected, setstatusSelected] = useState<string[]>(getStorageStatusFilter());

	const getEditLink = (segmentGroupId: string): string => {
		const ka =  props.$routeParams?.ka ? `&ka=${accountId}` : '';
		const provider = '&provider=manage';
		const url = `/Insight/segment/edit?id=${segmentGroupId}${provider}${ka}`;
		return url;
	};
	const getCreationLink = (): string => {
		const ka = props.$routeParams?.ka ? `&ka=${accountId}` : '';
		const provider = '?provider=manage';
		const url = `/Insight/segment/edit${provider}${ka}`;
		return url;
	};

	const changeSegmentGroupStatus = (segmentGroupId: string, status: SegmentGroupStatus) => {
		segmentGroupServices.updateSegmentGroupStatus(segmentGroupId, status, () => {
			systemServices.showSuccess(status === 'Live' ? 'Segmentation is running' : 'Stopped Segmentation');
			refreshList();
		}, err => {
			console.log(err);
			systemServices.showError(status === 'Live' ? 'An error occured while starting Segmentation' : 'An error occured while stopping Segmentation');
		});
	};
	const onDeleteGroup = () => {
		if(!idToDelete) return;
		segmentGroupServices.deleteSegmentGroup(idToDelete, () => {
			systemServices.showSuccess( 'Segmentation is delete');
			refreshList();
		}, err => {
			console.log(err);
			systemServices.showError('An error occured while delete the segmentation' );
		});
	};
	const onDelete = (id:string) => {
		setSureToDelete(true);
		setIdToDelete(id);
	};
	const handleChange = (value) => {
		setValueInSearchBar(value);
	};
	const search = (table, word, statusSelected) => {
		if (word.length >= 0) {
			const resultName = table.filter(line =>
				line.name !== null && line.name.toUpperCase().indexOf(word.toUpperCase()) > -1
			);
			const finalArray = [...resultName];
			let uniqueItems = [...new Set(finalArray)];
			if(statusSelected.length > 0){
				uniqueItems = uniqueItems.filter(x => x.status === statusSelected[0] );
			}
			if (uniqueItems.length === 0) {
				setNoFilteredData(true);
				setsegmentListFilter([]);
			}
			else {
				setNoFilteredData(false);
				setsegmentListFilter(uniqueItems);
			}
		} else {
			setNoFilteredData(false);
			setsegmentListFilter(segmentGroups);
		}
	};

	const refreshList = () =>{
		setIsLoading(true);

		segmentGroupServices.getSegmentGroups(data => {
			const segments = data.map(convertSegmentGroupTypeFromApi);
			segments.sort((a, b) => {
				const nameA = a.name || '';
				const nameB = b.name || '';
				if (nameA < nameB) {
					return -1;
				} else if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
			setSegmentGroups(segments);
			setNoData(segments.length === 0);
			setIsLoading(false);
			setFirstDisplay(false);
		}, err => {
			console.error(err);
			systemServices.showError('Cannot get segment groups');
			setSegmentGroups([]);
			setNoData(true);
			setIsLoading(false);
			setFirstDisplay(false);
		});
	};
	function handleChangeStatus(status:string){
		const newStatus = [status];
		sessionStorage.setItem('segment_filter_status', JSON.stringify(newStatus));
		setstatusSelected(newStatus);

		/* Multiple selection */
		// let newStatus = [...statusArray.map(x=> x.value)];
		// const newKey = statusArray.find(el => el.value === status);
		// const oldD = [...statusSelected.map(x=> x)];
		// const indefOfVal = oldD.indexOf(status);
		// if(indefOfVal !== -1){
		// 	newStatus = oldD.filter(x => x !== newKey?.value);
		// }else{
		// 	newStatus = ([...oldD,status]);
		// }
		// sessionStorage.setItem('segment_filter_status', JSON.stringify(newStatus));
		// setstatusSelected(newStatus);
	}
	function resetStatus(){
		sessionStorage.removeItem('segment_filter_status');
		setstatusSelected([]);
	}

	useEffect(() => {
		refreshList();
	}, []);

	useEffect(()=>{
		if(valueInSearchBar.length > 0){
			search(segmentGroups,valueInSearchBar,statusSelected);
		}else{
			search(segmentGroups,'',statusSelected);
		}
	},[valueInSearchBar,segmentGroups,statusSelected]);

	const context: SegmentGroupsListContextType = {
		isLoading,
		segmentGroups,
		getEditLink,
		getCreationLink,
		changeSegmentGroupStatus,
		onDeleteGroup,
		onDelete,
		sureToDelete,
		setSureToDelete,
		valueInSearchBar,
		handleChange,
		segmentListFilter,
		noData,
		noFilteredData,
		statusSelected,
		handleChangeStatus,
		resetStatus,
		statusArray,
		firstDisplay
	};

	return (
		<SegmentGroupsListContext.Provider
			value={context}>
			{props.children}
		</SegmentGroupsListContext.Provider>
	);

};

type SegmentGroupsListContextType = {
	isLoading: boolean;
	segmentGroups: SegmentGroupType[],
	changeSegmentGroupStatus: (segmentGroupId: string, status: SegmentGroupStatus) => void,
	getEditLink: (segmentGroupId: string) => string,
	getCreationLink: () => string,
	onDeleteGroup: () => void;
	onDelete: (st:string)=>void;
	sureToDelete: boolean;
	setSureToDelete: Dispatch<SetStateAction<boolean>>;
	valueInSearchBar:string;
	handleChange:Dispatch<SetStateAction<string>>;
	noData: boolean;
	noFilteredData: boolean;
	segmentListFilter: SegmentGroupType[];
	statusSelected:string[] | undefined;
	handleChangeStatus: (s:string)=> void;
	resetStatus: ()=> void;
	statusArray:status[];
	firstDisplay: boolean;
}


export default CreateSegmentGroupsListContextProvider;

export { useSegmentGroupsListContext };
