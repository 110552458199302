import React from 'react';
import CodeSnippet from '../../../Components/CodeSnippet';
import Link from '../../../Components/Link';
import SpinnerWheel from '../../../Components/SpinnerWheel';

export default function ScriptAnalytics({
	analyticsScript
}) {

	const docUrl = 'https://polite-coast-000c04e03.azurestaticapps.net/docs/tutorial-setup/quick-start#installez-le-script-sur-votre-site';

	return (
		<>
			<div className='mb_20'>
				<div className='s_18 mb_8'>
					Installez le script sur votre site.
				</div>
				<div className='s_15'>
					Le script ci-dessous doit être présent sur toutes les pages de votre site. Le plus simple est d'utiliser un Tag Manager mais ce n'est pas obligatoire.
					<br />
					<Link
						href={docUrl}
						rel="noreferrer"
						target="_blank"
						message='En savoir plus'
						icon='fas fa-external-link-square-alt fa-sm'
						iconPosition='after'
					/>
				</div>
			</div>
			{analyticsScript.loading
				? <SpinnerWheel size="l" />
				: <CodeSnippet code={analyticsScript.script}></CodeSnippet>
			}
		</>
	);
}
