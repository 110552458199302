import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import MonthSelector from './MonthSelector';
import { check } from '../../../Components/Can';
import OperationLabelFilters from '../UI/OperationLabelFilters';
import { BusinessOperationContext } from '../Context/ContextBusinessOperations';
export default function InteractionsBtnContainer({ accountId, routeParams, setdateRange, dateRange, checkTheDayOfWeek, dateOfTheSingleDatePicker, setDateOfTheSingleDatePicker, permissions, userSimplifyRole }) {
	const {
		selectedLabelIds, 
		setSelectedLabelIds
	} = useContext(BusinessOperationContext);
    
	const handleOnDateChange = (e) => {
		const myDatePickerToCheckForCalendar = moment(e.startOf('day'));
		setDateOfTheSingleDatePicker(e);
		checkTheDayOfWeek(myDatePickerToCheckForCalendar);
	};

	// labels filter 

	const [dataFiltered, setDataFiltered] = useState();
	// useEffect(() => {
	//     if (!dataFiltered) {
	//         setDataToMap(operationsAccount)
	//     } else {
	//         setDataToMap(dataFiltered)
	//     }
	// }, [operationsAccount, dataFiltered])

	// useEffect(() => {
	//     if (operationsAccount) {
	//         setDataIsUpload(false)
	//     } else {
	//         setDataIsUpload(true)
	//     }
	//     $('[title]').tooltip();
	// }, [operationsAccount])

	// useEffect(() => {
	//     if (operationsAccount) {
	//         search(operationsAccount, selectedLabelIds || [])
	//     }
	// }, [ selectedLabelIds])


	const onFilterLabels = (labelIds) => {
		setSelectedLabelIds(labelIds);
	};


	return (
		<>
			<div className="clearfix">
				<div className="campaign_list_search">
					<MonthSelector
						dateOfTheSingleDatePicker={dateOfTheSingleDatePicker}
						handleOnDateChange={(e) => handleOnDateChange(e)}
						dateSelected={dateRange}
						setdateRange={setdateRange}
						checkTheDayOfWeek={(e, op) => checkTheDayOfWeek(e, op)}
					/>
				</div>
				<OperationLabelFilters
					canEdit={true}
					onFilter={labelIds => onFilterLabels(labelIds)}
					onEditRequested={()=>{}}
					accountId={accountId}
					className="m"
				/>
			</div>
		</>
	);
}

