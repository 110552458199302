import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import SearchBar from '../../../Components/SearchBar';
import Btn from '../../../Components/Btn';
import TableGridContainer from '../../../Components/Table/TableGridContainer';
import TableRowItem from '../../../Components/Table/TableRowItem';
import TableColItem from '../../../Components/Table/TableColItem';
import EmptyState from '../../../Components/EmptyState';
import Link from '../../../Components/Link';
import Picto from '../../../Components/Picto';
import Dropdown from '../../../Components/Dropdown';
import Listbox from '../../../Components/Listbox';
import ListboxItem from '../../../Components/ListboxItem';
import Confirm from '../../../Components/Confirm';
import { iconPerformance } from '../../../Components/CustomIcon/CustomIcon';
import { performanceTrackingCampaignsDashboardContext } from './context';

const optionCss = {
	gridTemplateColumns: '3fr 2fr 2fr 130px',
};

export default function PerformanceTrackingCampaignsList() {

	const {
		listOfCampaigns,
		toggleAddItem,
		loading,
		totalCount,
		handleSearchChange,
		valueInSearchBar,
		dataFiltered,
		noData,
		isError,
		permissionsAccount
	} = performanceTrackingCampaignsDashboardContext();

	const [t] = useTranslation('common');

	const permCreateCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'PERFCAMPAIGN_CAN_CREATE');
	const canCreateCampaign = permCreateCampaign && permCreateCampaign.Value === true;

	return (
		<div className="page_block">
			{loading && totalCount == 0 &&
				<SpinnerWheel wheelSize="l" display="block" verticalSize="xl" />
			}
			{loading && totalCount != 0 &&
				<SpinnerWheel wheelSize="l" />
			}
			{totalCount != 0 &&
				<>
					<div className="flex mb_15">
						<div className="flex_item_full">
							<SearchBar
								onChangeValue={(e) => handleSearchChange(e)}
								valueInSearchBar={valueInSearchBar}
								placeholder={t('list.search')}
								fullWidth={false}
							/>
						</div>
						<div className="flex_item_fix"></div>
					</div>
					{!noData &&
						<TableGridContainer specialClasseName="" >
							<TableRowItem specialClasseName={'table_head_row'} styleObj={optionCss}>
								<TableColItem specialClasseName={' '}>
									{t('list.campaignNameColumn')}
								</TableColItem>
								<TableColItem specialClasseName={''}>
									{t('list.campaignIdColumn')}
								</TableColItem>
								<TableColItem specialClasseName={''}>
									{t('list.slideIdColumn')}
								</TableColItem>
							</TableRowItem>
							{listOfCampaigns &&
								<>
									{!dataFiltered && listOfCampaigns.map((elem) =>
										<ElementCampaign key={elem.campaignId} campaign={elem} />
									)}
									{dataFiltered && dataFiltered.map((elem) =>
										<ElementCampaign key={elem.campaignId} campaign={elem} />
									)}
								</>
							}
						</TableGridContainer>
					}
				</>
			}

			{!loading && totalCount > 0 && noData && !isError &&
				<EmptyState
					icon="fas fa-search"
					title={t('list.emptyState.noMatchingCampaign.title')}
					text={t('list.emptyState.noMatchingCampaign.text')}
					verticalSize="l"
					textSize="l"
				/>
			}

			{!loading && totalCount == 0 && !isError &&
				<EmptyState
					imageUrl="/Assets/empty_performance_tracking.svg"
					title={t('list.emptyState.title')}
					text={
						<>
							<p>{t('list.emptyState.text.1')}</p>
							<p>{t('list.emptyState.text.2')}</p>
						</>
					}
					verticalSize="l"
					textSize="l"
					secondaryAction={
						<Btn
							href="https://docs.beyable.com/web-app-sdk/performance-tracking"
							target="_blank"
							rel="noreferrer"
							message={t('list.emptyState.action.readDocumentation')}
							style="outline"
							color="secondary"
						/>
					}
					primaryAction={
						canCreateCampaign ?
							<Btn
								onClick={() => toggleAddItem()}
								message={t('list.emptyState.action.create')}
							/>
							: null
					}
				/>
			}

			{!loading && isError &&
				<EmptyState
					icon="far fa-times-circle"
					title={'list.genericLoadingError'}
					verticalSize="l"
					textSize="l"
				/>
			}
		</div>
	);
}

const ElementCampaign = ({ campaign }) => {

	const {
		handleCampaignSelected,
		handleDeleteCampaign,
		linkToReporting,
		permissionsAccount,
		openEditCampaign
	} = performanceTrackingCampaignsDashboardContext();

	const [t] = useTranslation('common');

	const [isHovered, setIsHovered] = useState(false);
	const [ddIsOpen, setDdIsOpen] = useState(false);
	const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);

	const permCreateCampaign = permissionsAccount && permissionsAccount.find(perm => perm.Name === 'PERFCAMPAIGN_CAN_CREATE');
	const canCreateCampaign = permCreateCampaign && permCreateCampaign.Value === true;

	return (
		<>
			<TableRowItem
				specialClasseName={'table_body_row'}
				styleObj={optionCss}
				setIsHovered={setIsHovered}>
				<TableColItem>
					<Link
						href={linkToReporting(campaign.campaignId)}
						className='table_name_link'
						color='black'
						tooltip={t('list.tooltip.viewReport')}>
						<div className='flex_inline'>
							<div className='flex_item_fix mr_15'>
								<Picto
									color="green"
									size="l"
									iconCustom={iconPerformance}
								/>
							</div>
							<div className='flex_item_full'>
								<span className='table_name_link_underline'>
									{campaign.name}
									{isHovered && <i className='far fa-chart-bar ml_5'></i>}
								</span>
								{campaign.description &&
									<div className='s_13 grey_2 fw_normal mt_2'>{campaign.description}</div>
								}
							</div>
						</div>
					</Link>
				</TableColItem>
				<TableColItem>
					<div className='grey_2 s_12'>
						{campaign.campaignId}
					</div>
				</TableColItem>
				<TableColItem>
					<div className='grey_2 s_12'>
						{campaign?.slides[0]?.slideId}
					</div>
				</TableColItem>
				<TableColItem align='right'>
					<Btn
						icon="fas fa-code"
						style="ghost_outline"
						color="secondary"
						size="xs"
						onClick={() => handleCampaignSelected(campaign.campaignId)}
						tooltip={t('list.tooltip.openIntegrationGuide')}
					/>
					{canCreateCampaign &&
						<Dropdown
							isOpen={ddIsOpen}
							toggle={(s) => setDdIsOpen(s)}
							button={
								<Btn
									icon="fas fa-ellipsis-v"
									style="ghost_outline"
									color="secondary"
									size="xs"
								/>
							}
						>
							<Listbox>
								<ListboxItem
									icon="fas fa-pen"
									message={t('list.action.edit')}
									onClick={() => { openEditCampaign(campaign); setDdIsOpen(false); }}
								/>
								<ListboxItem
									icon="fas fa-trash"
									message={t('list.action.delete')}
									onClick={() => { setDeleteConfirmIsOpen(true); setDdIsOpen(false); }}
								/>
							</Listbox>
						</Dropdown>
					}
				</TableColItem>
			</TableRowItem>
			<Confirm
				title={t('list.confirm.delete.title')}
				text={t('list.confirm.delete.text')}
				confirmColor='alert'
				confirmText={t('list.action.delete')}
				width={470}
				// confirmText={t('actions.delete')}
				// cancelText={t('actions.cancel')}
				onClose={() => setDeleteConfirmIsOpen(false)}
				isOpen={deleteConfirmIsOpen}
				confirmCallback={() => { handleDeleteCampaign(campaign.campaignId); setDeleteConfirmIsOpen(false); }}
			/>
		</>
	);
};