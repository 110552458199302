import React, { useState, useEffect, Fragment } from 'react';
import './Informations.css';
import { CampaignsContext } from '../context';
import Fieldset from '../../../Components/Fieldset';
import InputCustom from '../../../Components/InputCustom';
import RangeSlider from '../../../Components/RangeSlider';
import Checkbox from '../../../Components/Checkbox';
import Switch from '../../../Components/Switch';
import NumericUpDown from '../../../Components/NumericUpDown';
import SelectCustom from '../../../Components/SelectCustom';
import Btn from '../../../Components/Btn';
import Confirm from '../../../Components/Confirm';
import TimezoneSelect from '../../../Components/TimezoneSelect';
import IpFormModal from './IpFormModal';
import CampaignDatePicker from './CampaignDatePicker';
import { dateUtils } from '../util';
import { useTranslation } from 'react-i18next';
import renderJsxTag from '../../../Translation/translation-utils';
import useFeature from '../../../../flags/useFeature';
import PermissionsContainer from './Permissions/PermissionsContainer';

export default function Informations() {

	const [currentEditingIP, setCurrentEditingIP] = useState();
	const [removeIPConfirmIsOpen, setRemoveIPConfirmIsOpen] = useState(false);
	const [addIPIsOpen, setAddIPIsOpen] = useState(false);
	const [editIPIsOpen, setEditIPIsOpen] = useState(false);
	const [userSetControlGroup, setUserSetControlGroup] = useState(false);
	const { t, i18n } = useTranslation(['common']);
	const {
		step,
		testGroupPercent,
		setTestGroupPercent,
		handleSetHasEndDate,
		calculateNewEndDate,
		trackingMode,
		campaign,
		editorMode,
		setCampaign,
		saveError,
		isSaving,
		isAdmin
	} = CampaignsContext();

	const { features } = useFeature();

	const isPatch = editorMode === 'patch/code' || editorMode === 'patch/graph';
	const isAbTest = editorMode === 'abTest';

	useEffect(() => {
		if (campaign.HasControlGroup && userSetControlGroup) {
			if (campaign.ControlGroupSplitValue == 0) {
				handleControlGroupProportionChanged(50);
			}
		}
	}, [campaign.HasControlGroup]);
	// COMMON FIELDS
	function handleInputChange(name, value) {
		if(value === 'Invalid date'){
			return;
		}
		const newCampaign = { ...campaign, [name]: value };
		setCampaign(newCampaign);
	}

	// CONTROL GROUP
	function handleToggleControlGroup(value) {
		setUserSetControlGroup(true);
		handleInputChange('HasControlGroup', value);
	}
	function handleControlGroupProportionChanged(value) {
		if (value > 100 || value < 0) return;

		let newCampaign = { ...campaign };
		newCampaign.ControlGroupSplitValue = value;
		setTestGroupPercent(100 - value);
		setCampaign(newCampaign);
	}
	function handleTestGroupProportionChanged(value) {
		if (value > 100 || value < 0) return;

		let newCampaign = { ...campaign };
		newCampaign.ControlGroupSplitValue = 100 - value;

		setTestGroupPercent(value);
		setCampaign(newCampaign);
	}

	// ADD IP ADRESSES
	function handleAddIPAdress(ip) {
		let newCampaign = { ...campaign, TestModeIpAddresses: [ip, ...campaign.TestModeIpAddresses] };
		setCampaign(newCampaign);
		setAddIPIsOpen(false);
	}

	// EDIT IP ADRESSES
	function openEditIP(ip) {
		setCurrentEditingIP(ip);
		setEditIPIsOpen(true);
	}

	function closeEditIP() {
		setEditIPIsOpen(false);
		setCurrentEditingIP(null);
	}

	function handleEditIPAdress(ip) {
		let newIPAdresses = campaign.TestModeIpAddresses.map(x => x.id === currentEditingIP.id ? ip : x);
		let newCampaign = { ...campaign, TestModeIpAddresses: newIPAdresses };
		setCampaign(newCampaign);
		closeEditIP();
	}

	// DELETE IP ADDRESS
	function openConfirmDeleteIP(ip) {
		setCurrentEditingIP(ip);
		setRemoveIPConfirmIsOpen(true);
	}

	function closeConfirmDeleteIP() {
		setCurrentEditingIP(null);
		setRemoveIPConfirmIsOpen(false);
	}

	function handleDeleteIP() {
		if (!currentEditingIP) return;

		const newIPAdresses = campaign.TestModeIpAddresses.filter(item => item.id !== currentEditingIP.id);
		let newCampaign = { ...campaign, TestModeIpAddresses: newIPAdresses };
		setCampaign(newCampaign);
		closeConfirmDeleteIP();
	}

	// CHECK IP
	function handleCheckIP(item) {
		let newIPAdresses = campaign.TestModeIpAddresses.map(x => x.id === item.id ? ({ ...x, isDisabled: !item.isDisabled }) : x);
		let newCampaign = { ...campaign, TestModeIpAddresses: newIPAdresses };
		setCampaign(newCampaign);
	}


	const campaignDates = () => {
		const hasError = saveError.find(x => x.name === 'dates');
		return (
			<>
				<Fieldset blockClassName="xl">
					<div className="flex flex_align_start">
						<div>
							<Fieldset label={t('informations.startDate')} labelClassName="s_16 " blockClassName="mb_8">
								<label className="custom_input w_full">
									<CampaignDatePicker
										selectedValue={campaign.StartDateInTimezone}
										onChange={(date) => handleInputChange('StartDateInTimezone', date)}
									/>
									<i className="custom_input_icon fas fa-calendar-alt"></i>
								</label>
							</Fieldset>
						</div>
						{campaign.HasEndDate &&
							<>
								<div className="align_with_labeled field_arrow flex">
									<i className="fas fa-arrow-right"></i>
								</div>
								<div>
									<Fieldset label={t('informations.endDate')} labelClassName="s_16 " blockClassName="mb_8">
										<label className={`custom_input w_full ${hasError ? 'error' : null}`}>
											<CampaignDatePicker
												selectedValue={campaign.EndDateInTimezone || calculateNewEndDate()}
												onChange={(date) => handleInputChange('EndDateInTimezone', date)}
											/>
											<i className="custom_input_icon fas fa-calendar-alt"></i>
										</label>
										{hasError &&
											<div className="custom_input_sub_error">{hasError.error}</div>
										}
									</Fieldset>
								</div>
							</>
						}
					</div>
					<Checkbox
						forcedChecked={campaign.HasEndDate || false}
						onChange={(e) => handleSetHasEndDate(e.target.checked)}>
						<span className="s_15 ">
							{t('informations.noEndDate')}</span>
					</Checkbox>
				</Fieldset>

				<Fieldset label="Timezone" labelClassName="s_16 " blockClassName="xl">
					<TimezoneSelect
						timezoneValue={campaign.IanaTimezone}
						handleTimeZoneSelected={t => handleInputChange('IanaTimezone', t)}
					>
					</TimezoneSelect>
				</Fieldset>
			</>
		);
	};

	let objectivesList = [
		{ value: 'ImproveConversion', label: t('informations.ImproveConve'), isDisabled: false },
		{ value: 'ImproveAverageBasket', label: t('informations.increaseAverage'), isDisabled: false },
		{ value: 'CollectData', label: t('informations.collectData'), isDisabled: false },
		{ value: 'ImproveRecruitment', label: t('informations.recruitClients'), isDisabled: false },
	];
	if (features.includes('abtest-demo')) {
		objectivesList.push({ value: 'Custom', label: 'Custom', isDisabled: false });
	}

	const [showCustomObjective, setShowCustomObjective] = useState(false);
	const changeObjective = (value) => {
		setShowCustomObjective(value == 'Custom');
	};

	return step === 3 && (
		<>
			{isSaving &&
				<div className="mask">
					<div className="wheel size_32"></div>
				</div>
			}
			<div className="cpg_informations_outer">
				<div className="cpg_informations_inner grey_0">
					<InputCustom
						label={t('informations.CampaignName')}
						labelClassName="s_16 "
						onChange={(e) => handleInputChange('Name', e.target.value)}
						value={campaign.Name}
						type="text"
						autoFocus={true}
						isAnError={saveError.find(x => x.name === 'campaignName') && t('informations.campaignNameIsMandatory')}
						fullWidth={true}
						blockClassName="xl"
					/>

					<InputCustom
						label="Description"
						labelClassName="s_16 "
						onChange={(e) => handleInputChange('Description', e.target.value)}
						value={campaign.Description || ''}
						type="textarea"
						placeholder={t('informations.optional')}
						fullWidth={true}
						rows={2}
						blockClassName="xl"
					/>

					{!isPatch &&
						<SelectCustom
							label="Campaign objective"
							labelClassName="s_16 "
							optionsList={objectivesList}
							name="campaignObjective"
							onChangeFunction={(e) => { changeObjective(e.target.value); handleInputChange('Objective', e.target.value); }}
							value={campaign.Objective}
							blockClassName="xl"
						/>
					}
					{
						showCustomObjective &&
						<InputCustom
							label="Custom Objective"
							labelClassName="s_16 "
							description="Custom Objective"
							type="text"
							fullWidth={true}
							blockClassName="xl"
						/>
					}

					{campaignDates()}
					{editorMode !== 'appNative/patch/code' &&
					<InputCustom
						label={t('informations.previewUrl')}
						labelClassName="s_16 "
						description={t('informations.youWillBeAble')}
						descriptionBottom={isAbTest ? t('abTest.thisFieldIsNotEditable') : ''}
						onChange={(e) => handleInputChange('UrlDebug', e.target.value)}
						value={campaign.UrlDebug || ''}
						placeholder="https://"
						type="text"
						fullWidth={true}
						isAnError={saveError.find(x => x.name === 'previewUrl') && t('informations.incorrectUrl')}
						blockClassName="xl"
						readonly={isAbTest}
					/>}

					{trackingMode === 2 &&
						<Fieldset label={t('informations.controlGroup')} blockClassName="xl" labelClassName="s_16 ">
							<Switch
								onChange={(e) => handleToggleControlGroup(e.target.checked)}
								forcedChecked={campaign.HasControlGroup}
								size="s">
								{t('informations.wantControlGroup')}
							</Switch>
							{
								campaign.HasControlGroup &&
								<>
									<div className="flex mt_15">
										<div className="flex_item_fix mr_10">
											<InputCustom
												min={0}
												type="number"
												unit="%"
												onChange={(e) => handleControlGroupProportionChanged(e.target.value)}
												value={campaign.ControlGroupSplitValue}
												placeholder="0"
												width="xxs"
												selectOnFocus={true}
											/>
										</div>
										<div className="flex_item_full">
											<RangeSlider
												min={0}
												max={100}
												step={5}
												onChange={(e) => handleControlGroupProportionChanged(e.target.value)}
												value={campaign.ControlGroupSplitValue}
												hasInput={false}
											/>
										</div>
										<div className="flex_item_fix ml_10">
											<InputCustom
												min={0}
												type="number"
												unit="%"
												onChange={(e) => handleTestGroupProportionChanged(e.target.value)}
												value={testGroupPercent}
												width="xxs"
												placeholder="0"
												selectOnFocus={true}
											/>
										</div>
									</div>
									<div className="flex mt_8">
										<div className="flex_item_half mr_10">
											<div>{t('informations.wantControlGroup')}</div>
											<div className="s_13 grey_1">{t('informations.controlGroupNotSee')}</div>
										</div>
										<div className="flex_item_half ml_10 al_right">
											<div>{t('informations.testGroup')}</div>
											<div className="s_13 grey_1">{t('informations.testGroupeSee')}</div>
										</div>
									</div>
								</>
							}
						</Fieldset>
					}

					<NumericUpDown
						label={t('informations.howManyDays')}
						labelClassName="s_16"
						blockClassName="xl"
						description={t('informations.0meansNever')}
						value={campaign.IdleDuration}
						onChange={(e) => handleInputChange('IdleDuration', e.target.value)}
						min={0}
						canEdit={true}
					/>

					{isAdmin &&
						<Fieldset
							label={t('EditCampaignPermissions.editCampaign')}
							labelClassName="s_16"
							blockClassName="xl"
						>
							<PermissionsContainer />
						</Fieldset>
					}
					{
						<Fieldset
							label={t('informations.whoCanSeeCampaign')}
							description={editorMode === 'appNative/patch/code' ?  t('informations.onlyToseDeviceAdresses') :t('informations.onlyToseIpAdresses')}
							labelClassName="s_16"
							blockClassName="xl"
							append={
								<Btn
									message={editorMode === 'appNative/patch/code' ? t('informations.AddDevice') : t('informations.AddAddress')}
									style="outline"
									onClick={() => setAddIPIsOpen(true)}
									className="mb_5" />
							}
						>
							<div className="cpg_ip mt_10">
								{campaign.TestModeIpAddresses.map((item) => <div className="cpg_ip_row" key={item.id}>
									<div className="cpg_ip_col">
										<Checkbox
											onChange={() => handleCheckIP(item)}
											checked={item.isDisabled ? false : true}>
											{item.label}
										</Checkbox>
									</div>
									<div className="cpg_ip_col grey_2">
										{item.ipAddress}
									</div>
									<div className="cpg_ip_col al_right">
										<a className="icon_btn s" onClick={() => openEditIP(item)}>
											<i className="fas fa-pen"></i>
										</a>
										<a className="icon_btn s" onClick={() => openConfirmDeleteIP(item)}>
											<i className="fas fa-trash-alt"></i>
										</a>
									</div>
								</div>
								)}
							</div>
						</Fieldset>}

					{/*<div className="informations_options">
                         <h2>Starting options</h2>
                        <p>No worries, you can back later to change these informations</p>
                        <div className="informations_options_status">
                            <Radio checked={campaignStatus === "Stopped"} id="stopped" name="status" value="stopped" >Stopped</Radio>
                            <Radio checked={campaignStatus === "Draft"} id="draft" name="status" value="draft" >Draft</Radio>
                            <Radio checked={campaignStatus === "On-going"} id="on-going" name="status" value="on-going" >On-going</Radio>
                        </div>
                    </div> */}
				</div>

				<Confirm
					isOpen={removeIPConfirmIsOpen}
					onClose={(e) => closeConfirmDeleteIP()}
					title={editorMode === 'appNative/patch/code' ? t('informations.deleteThisDevice'): t('informations.deleteThisIp')}
					text={editorMode === 'appNative/patch/code' ? t('informations.thisDeviceWillNoLongerBeAvailable') : t('informations.thisIpWillNoLongerBeAvailable')}
					cancelText={t('informations.cancel')}
					confirmText={t('informations.delete')}
					confirmCallback={(e) => handleDeleteIP()}
					confirmColor="alert"
				/>

				<IpFormModal
					editorMode={editorMode}
					mode={t('informations.add')}
					isOpen={addIPIsOpen}
					onClose={() => setAddIPIsOpen(false)}
					saveCallback={(ip) => handleAddIPAdress(ip)}
				/>

				{currentEditingIP &&
					<IpFormModal
						editorMode={editorMode}
						mode={t('informations.edit')}
						ip={currentEditingIP}
						isOpen={editIPIsOpen}
						onClose={() => closeEditIP()}
						saveCallback={(ip) => handleEditIPAdress(ip)}
					/>
				}

			</div>
		</>
	);
}