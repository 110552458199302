import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { serverSideCampaignsDashboardContext } from './context';
import Modal from '../../Components/Modal';
import ModalHeader from '../../Components/ModalHeader';
import ModalBody from '../../Components/ModalBody';
import ModalFooter from '../../Components/ModalFooter';
import InputCustom from '../../Components/InputCustom';
import Btn from '../../Components/Btn';

export default function ServerSideCampaignEditionModal() {

	const {
		creationModalIsOpened,
		setCreationModalIsOpened,
		handleValidateCampaignEdition,
		campaignSelectedForEdit
	} = serverSideCampaignsDashboardContext();

	const [t] = useTranslation('common');

	const [title, setTitle] = useState('');

	const [editionData, setEditionData] = useState({
		name: '',
		description: ''
	});

	const onChange = (property, value) => {
		setEditionData(c => ({...c, [property]: value}));
	};

	const reset = () => {
		setEditionData(c => ({...c, name: '', description: ''}));
	};

	useEffect(() => {
		setEditionData({
			name: campaignSelectedForEdit?.name ?? '',
			description: campaignSelectedForEdit?.description ?? ''
		});
		setTitle(campaignSelectedForEdit == null ? t('edition.creationTitle') : t('edition.editionTitle'))
	}, [campaignSelectedForEdit, creationModalIsOpened]);
	

	return (
		<Modal
			isOpen={creationModalIsOpened}
			width={450}
			onClose={() => setCreationModalIsOpened(false)}
		>
			<ModalHeader title={title} />
			<ModalBody>
				<InputCustom
					value={editionData.name}
					onChange={e => onChange('name', e.target.value)}
					fullWidth={true}
					label={t('edition.campaignName')}
				/>
				<InputCustom
					type='textarea'
					value={editionData.description}
					onChange={e => onChange('description', e.target.value)}
					fullWidth={true}
					label={t('edition.campaignDescription')}
					placeHolder='Optional'
					rows={3}
				/>
			</ModalBody>
			<ModalFooter
				primaryAction={
					<Btn
						onClick={() => {handleValidateCampaignEdition(editionData); setCreationModalIsOpened(false);}}
						message={t('edition.action.save')}
						disabled={!editionData.name}
					/>
				}
				secondaryAction={
					<Btn
						onClick={() => {reset(); setCreationModalIsOpened(false);}}
						message={t('edition.action.cancel')}
						color="secondary"
						style="ghost"
					/>
				}
			/>
		</Modal>
	);
}