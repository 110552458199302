import React, {} from 'react';
import ReactTooltip from 'react-tooltip';

export default function SegmentCardCondition({
	label,
	icon,
	value = '',
	operand = '',
	tooltip = ''
}) {

	return (
		<>
			<ReactTooltip backgroundColor='black' effect='solid' delayShow={500} html={true} place="bottom"/>
			<div className="segment_card_item flex">
				<div className="flex_item_fix">
					<span className={'trigger_icon icon_' + icon}></span>
				</div>
				<div className="flex_item_auto segment_card_item_info" data-tip={tooltip}>
					<span className="segment_card_item_info_label">
						{label}
						{(operand != '' || value != '') && <>:&nbsp;</>}
					</span>
					{operand &&
                        <span className="segment_card_item_info_operand">{operand + ' '}</span>
					}
					{value}
				</div>
			</div>
		</>
	);
}