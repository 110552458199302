import React, { useState, useEffect } from 'react';
import useEventBroker from '../../../Hooks/useEventBroker';
import { useWysiwygEditorContext } from '../context/WysiwygEditorContext';
import {useTranslation} from 'react-i18next';
export default function HttpConnector({
	label = 'Customer database connector',
	isActivated,
	feature,
	loadConfigurationCallback,
	campaignId,
	httpConnectorData
}) {
	const [t, i18n] = useTranslation('common');
	const {
		loadConfigurationHttpConnector
	} = useWysiwygEditorContext();

	const { publish } = useEventBroker();

	const openHttpConnectorPanel = () => {
		publish('evt/campaign/setHttpConnectorRequested', httpConnectorData);
	};

	useEffect(() => {
		loadConfigurationHttpConnector(feature, campaignId, loadConfigurationCallback);
	}, []);

	return (
		<div className="format_param_group clickable folded overflow_hidden" onClick={() => openHttpConnectorPanel()}>
			<div className="format_param_group_name flex">
				<span className="format_param_group_icon flex_item_fix">
					<i className="fas fa-plug" />
				</span>
				<span className="flex_item_full flex flex_align_baseline overflow_hidden">
					<span className="flex_item_fix">{label}</span>
					<span className="flex_item_full ellips format_param_group_value">
						{isActivated ?
							<span className='fw_medium c_green'>{t('connector.Enabled')}</span>
							:
							<span className='fw_medium'>{t('connector.Disabled')}</span>
						}
					</span>
				</span>
				<span className="format_param_group_arrow flex_item_fix">
					<i className="fas fa-chevron-right" />
				</span>
			</div>
		</div>
	);
}
