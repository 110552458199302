import React, { useContext, useState, useEffect } from 'react';
import { CreateBusinessOperationContext } from '../../Context/ContextCreateBusinessOperations';
import Btn from './../../../../../Components/Btn';
import PopinConfirm from './../../../../../Components/PopinConfirm';
import ResizeBannerWorkflowInstancesItem from './ResizeBannerWorkflowInstancesItem';
import useEventBroker from '../../../../../Hooks/useEventBroker';

export default function ResizeBannerWorkflowInstancesList({ accountId, businessOperationServices, systemServices }) {
	const {
		operation,
		bannerResizeWorkflows,
		setBannerResizeWorkflows
	} = useContext(CreateBusinessOperationContext);

	const [cancelWorkflowRequested, setCancelWorkflowRequested] = useState({ requested: false });
	const [time, setTime] = useState(Date.now());
	const { subscribe, publish } = useEventBroker();

	useEffect(() => {
		if (operation && operation.operation.libraryId) {
			refreshWorkflows();
		}
	}, [operation, time]);

	useEffect(() => {
		const interval = setInterval(() => setTime(Date.now()), 60000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	subscribe('evt/businessoperations/resizeBannerWorkflowCreated', () => refreshWorkflows());

	const refreshWorkflows = () => {
		return businessOperationServices
			.getBannerResizeWorflows(accountId, operation.operation.libraryId)
			.then(response => {
				const newWorkflows = [...response.data];

				for (let i = 0; i < bannerResizeWorkflows.length; i++) {
					const previousWorkflow = bannerResizeWorkflows[i];
					var newWorkflow = newWorkflows.filter(x => x.id === previousWorkflow.id);
					if (newWorkflow && newWorkflow.length > 0 && newWorkflow[0].status === 'terminated' && previousWorkflow.status !== 'terminated') {
						publish('evt/businessoperations/resizeBannerWorkflowTerminated', { libraryId: operation.operation.libraryId });
					}
				}

				setBannerResizeWorkflows(newWorkflows);
			});
	};

	const cancelWorkflow = () => {
		if (cancelWorkflowRequested.workflowId) {
			businessOperationServices
				.cancelBannerResizeWorflow(accountId, operation.operation.libraryId, cancelWorkflowRequested.workflowId)
				.then(
					_ => { systemServices.showSuccess('Your resizing request has been cancelled'); refreshWorkflows(); },
					_ => systemServices.showError('An error occured while canceling your request. Please try again or contact the support team.'));
		}
		setCancelWorkflowRequested({ requested: false });
	};

	const canBeCancelled = (status) => ['creating', 'created', 'submitted'].includes(status);

	return (
		<>
			{cancelWorkflowRequested.requested &&
				<PopinConfirm
					cancel={() => setCancelWorkflowRequested({ requested: false })}
					title={'Your resizing request will be cancelled. Are you sure you want to continue?'}
				>
					<Btn
						onClickFunction={(e) => { setCancelWorkflowRequested({ requested: false }); }}
						message="No"
						color="primary"
					/>
					<Btn
						onClickFunction={(e) => { cancelWorkflow(); }}
						message="Yes"
						color="alert"
					/>

				</PopinConfirm>
			}
			{bannerResizeWorkflows && bannerResizeWorkflows.length > 0 &&
				<div className="designSection">
					<h5 className="designSectionTitle">Resizing requests</h5>
					<div className="designSectionBody">
						<div className="resize_row resize_head">
							<div className="grid-resize">File</div>
							<div className="grid-resize"></div>
							<div className="grid-resize">Status</div>
							<div className="grid-resize">Upload date</div>
							<div className="grid-resize">Availability date</div>
							<div className="grid-resize close"></div>
						</div>
						{bannerResizeWorkflows.map(x =>
							<ResizeBannerWorkflowInstancesItem
								key={x.id}
								id={x.id}
								fileName={x.fileName}
								status={x.status}
								creationDateUtc={x.creationDateUtc}
								modificationDateUtc={x.modificationDateUtc}
								onCancelWorkflowRequested={() => canBeCancelled(x.status) && setCancelWorkflowRequested({ requested: true, workflowId: x.id })}
							/>)
						}
					</div>
				</div>
			}
		</>
	);
}