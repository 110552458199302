import React,{useEffect, useState} from 'react';
import { Article } from '../../../../Components/Article/Article';
import { List, ListItem } from '../../../../Components/List';
import Picto from '../../../../Components/Picto';
import Header from './Header';
import { useAuditContext } from '../../context/ContextAudit';
import { campaign_dictionary, getFormattedNumber } from '../../Utils';
import { Trans, useTranslation } from 'react-i18next';
import styles from '../Audit.module.css';

function getMinMax(m){
	const sign = Math.sign(m);
	if(sign === 1 || sign === 0){
		return Math.round(m);
	}
	if(sign === -1){
		return 0;
	}
}

function devideNumb(nb, perdiod){
	if(perdiod === 'month'){
		return nb;
	}
	if(perdiod === 'week'){
		return nb / 30 * 7 ;
	}
	if(perdiod === 'day'){
		return nb / 30;
	}
}

export default function ScenarioSection() {

	const {
		dataValues,
		selectedPeriod
	} = useAuditContext();

	const [scenarios, setscenarios] = useState(<></>);
	
	function getScenarios(){
		return <>{dataValues?.result?.map((el,i) => {
			const HasUpliftPrediction = el.upliftPrediction;
			if(HasUpliftPrediction){
				return (
					<div key={i}>
						<Scenario
							data={el}
							period={selectedPeriod}
							allScenario={dataValues}
						/>
					</div>
				);
			}
		})}</>;
	}

	useEffect(()=>{
		if(dataValues){
			setscenarios(getScenarios());
		}
	},[dataValues,selectedPeriod]);

	return (
		<section className="section">
			<Header />
			{scenarios}
		</section>
	);
}

function Scenario({
	data,
	period,
	allScenario
}) {

	function getScoreConfience(curr:number,first:number){
		const nb:number = curr / first;
		const nbToFixed = Number(nb);

		let indice = 0;
		if(nbToFixed <= 0.33){
			indice = 1;
		}
		if(nbToFixed >= 0.33 && nbToFixed <= 0.66  ){
			indice = 2;
		}
		if(nbToFixed >= 0.66 ){
			indice = 3;
		}
		return indice;
	}

	const arrOfUplift = Object.keys(data.upliftPrediction);
	const name = campaign_dictionary[data.scenarioType];
	const score = getScoreConfience(data.probability, allScenario.result[0].probability);

	return (
		<Article innerSize='l'>
			<div className={styles.scenario_header}>
				<div className='flex'>
					<div className='flex_item_full'>
						<div className={styles.scenario_name}>{name}</div>
					</div>
					<div className='flex_item_fix'>
						Indice de confiance : {score}/3
					</div>
				</div>
			</div>
			<List borderPosition="inner_picto" removeVerticalMargin={true}>
				{arrOfUplift.map((x,idx) => 
					<Prediction
						key={idx}
						name={x}
						data={data.upliftPrediction[x]}
						period={period}
					/>
				)}
			</List>
		</Article>
	);
}

function Prediction({
	name,
	data,
	period
}) {

	const [t] = useTranslation('kpi');
	const min = getMinMax(devideNumb(data.standardDeviation.min, period));
	const max = getMinMax(devideNumb(data.standardDeviation.max, period));
	const formattedMin = getFormattedNumber((min || 0).toFixed(2)) + ' €';
	const formattedMax = getFormattedNumber((max || 0).toFixed(2)) + ' €';

	const getPicto = () => {
		if (name === 'turnover') return <Picto size="l" icon="fas fa-euro-sign" color="yellow" />;
		if (name === 'basketAmount') return <Picto size="l" icon="fas fa-shopping-cart" color="blue" />;
		if (name === 'conversionRate') return <Picto size="l" icon="fas fa-filter" color="orange" />;
		if (name === 'pageViews') return <Picto size="l" icon="fas fa-desktop" color="green" />;
		
		return <Picto icon="fas fa-pen" color="green" />;
	};

	const getMessage = () => {
		if (!min || min <= 0) {
			return (
				<Trans
					t={t}
					i18nKey={'audit.predictionsValues.' + name + 'Max'}
					values={{max: formattedMax}}
				/>
			);
		} else {
			return (
				<Trans
					t={t}
					i18nKey={'audit.predictionsValues.' + name + 'MinMax'}
					values={{
						max: formattedMax,
						min: formattedMin
					}}
				/>
			);
		}
	};
	
	if(min === 0 && max === 0){
		return <></>;
	}

	return (
		<>
			<ListItem>
				<div className='flex'>
					<div className='flex_item_fix'>
						<div className={styles.prediction_line_picto}>
							{getPicto()}
						</div>
					</div>
					<div className='flex_item_full'>
						<div className={styles.prediction_line_type}>{t('audit.predictionsLabel.' + name)}</div>
						<div className={styles.prediction_line_text}>{getMessage()}</div>
					</div>
					<div className='flex_item_fix'>
						<Graph
							min={min}
							formattedMin={formattedMin}
							formattedMax={formattedMax}
						/>
					</div>
				</div>
			</ListItem>
		</>
	);
}

function Graph({
	min = 0,
	formattedMin,
	formattedMax
}) {

	const showMin = min && min > 0;

	return (
		<div className={showMin ? styles.graph_outer_min_max : styles.graph_outer_max}>
			<div className={styles.graph_inner}>
				<div className={styles.graph_value}>
					<div className={styles.graph_value_label}>{formattedMin}</div>
				</div>
				<div className={styles.graph_value}>
					<div className={styles.graph_value_label}>{formattedMax}</div>
				</div>
			</div>
		</div>
	);
}