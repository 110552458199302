import React, {useState} from 'react';
import listStyles from './ProductFeedList.module.css';
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy,
	verticalListSortingStrategy // <== doesn't break if this is rectSortingStrategy
} from '@dnd-kit/sortable';
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
	DragOverlay
} from '@dnd-kit/core';

import EmptyState from '../../../Components/EmptyState';
import Btn from '../../../Components/Btn';
import ProductFeedConfigurationModel from './ProductFeedConfigurationModel';
export default function TenantFeed({

	tenant,
	listTenant,
	urlNewFeed,
	accountId,
	urlFeedEdition,
	reorderedTenant
}) {
	const [activeId, setActiveId] = useState(null);
	const sensors = useSensors(
		useSensor(MouseSensor, {
			// Require the mouse to move by 10 pixels before activating
			activationConstraint: {
				distance: 10,
			},
		}),
	);
	function handleDragStart(event) {
		setActiveId(event.active.id);
	}
	const handleOnDragEnd = (result) => {
		if (!result.over && result.active.id === result.over.id ) return;
		const id = result.active.id;
		const idToPush = result.over.id;
		const oldObjFeeds = JSON.parse(JSON.stringify(tenant.feeds));
		const arrayOffeed = oldObjFeeds.map(x => x.id);
		const key = arrayOffeed.indexOf(id); 
		const keyToPush = arrayOffeed.indexOf(idToPush); 
		const reorderedItem= oldObjFeeds.splice(key, 1);
		oldObjFeeds.splice(key, 0);
		oldObjFeeds.splice(keyToPush, 0, ...reorderedItem);
		
		reorderedTenant({...tenant, feeds:oldObjFeeds},tenant.key,tenant,id , keyToPush);
	};

	return (
		<div key={tenant.key} className='page_block'>
			<DndContext
				autoScroll={false}
				sensors={sensors}
				onDragStart={handleDragStart}
				collisionDetection={closestCenter}
				onDragEnd={(e) => handleOnDragEnd(e)}
			>
				<div className='page_block_header flex'>
					<div className='flex_item_full'>
						{listTenant.length == 1 && tenant.key === '' ?
							<span className='page_block_title'>
								{tenant.feeds.length == 1 ?
									<>{tenant.feeds.length} connected feed</>
									:
									<>{tenant.feeds.length} connected feeds</>
								}
							</span>
							:
							<>
								<span className='page_block_title v_al_middle'>{tenant.key !== '' ? tenant.key : 'Default'}</span>
								<span className={listStyles.badge_tenant}>Tenant</span>
							</>
						}
					</div>
					{/*
						<div className='flex_item_fix ml_30'>
							<Btn
								color="primary"
								style="reverse"
								message="Preview"
							/>
						</div>
					*/}
				</div>

				{tenant.feeds.length === 0 &&
					<EmptyState
						icon="fas fa-book"
						title="No product feed yet"
						verticalSize={listTenant.length == 1 && tenant.key === '' ? 'xl' : 'm'}
						textSize={listTenant.length == 1 && tenant.key === '' ? 'l' : 'm'}
						text={<>Connect your product feed to supply the catalog, <br/>it is used for many features.</>}
						primaryAction={
							<Btn
								href={`${urlNewFeed}${location.search ||`?id=${accountId}`}&tenant=${tenant.key}`}
								message="Connect product feed"
							/>
						}
					/>
				}
				{tenant.feeds.length > 0 &&
					<>
						<div className={'table_grid ' + listStyles.grid}>
							<div className={'table_row table_head_row ' + listStyles.grid_row}>
								<div className="table_col">
									<a className="table_sort">Feed type</a>
								</div>
								<div className="table_col">
									<a className=""></a>
								</div>
								<div className="table_col">
									<a className="table_sort">Last configuration update</a>
								</div>
							</div>
							<SortableContext items={tenant.feeds} strategy={rectSortingStrategy}>
								{tenant.feeds.map((feed,idx) => 
									<ProductFeedConfigurationModel
										href={`${urlFeedEdition}${location.search ||`?id=${accountId}` }&key=${feed.id}&tenant=${tenant.key}`}
										key={feed.id}
										feed={feed}
										index={idx}
										tenant={tenant.key}
									/>
								)}
							</SortableContext>
						</div>
						<div className='mt_10'>
							<Btn
								href={`${urlNewFeed}${location.search ||`?id=${accountId}`}&tenant=${tenant.key}`}
								icon="fas fa-plus"
								message="Connect additional feed"
								style="ghost"
							/>
						</div>
					</>
				}
			</DndContext>
		</div>
	);
}
