import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import { Trans, useTranslation } from 'react-i18next';
export default function CampaignLabel({ label, showToolTip=true }) {
	const idToolTipLabel = uuidv4();
	const [t, i18n] = useTranslation('common');
	return (
		<li>
			{showToolTip && <ReactTooltip id={idToolTipLabel} className="labelKpi" backgroundColor='black' effect='solid' />}
			<span style={{ cursor: 'pointer' }}
				className={`campaign_label campaign_label_color campaign_label_${label.color.toLowerCase()}`}
				data-for={idToolTipLabel} data-tip={t('labels.editCampaignLabels')}>
				{label.name}
			</span>
		</li>
	);
}
