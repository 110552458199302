import React from 'react';
import BreadCrumbItem from './BreadcrumbItem';
import styles from './Breadcrumb.module.css';

export default function Breadcrumb({
	itemOfBreadcrumb,
	onClick,
	stepSelected
}) {

	const isClickable = typeof onClick === 'function';

	const handleClick = (index) => {
		if (isClickable) {
			onClick(index);
		}
	};
	
	return (
		<ul className={styles.list}>
			{itemOfBreadcrumb.map(item =>
				<BreadCrumbItem
					key={item.index}
					index={item.index}
					toGo={item.toGo}
					name={item.title}
					onClick={(e) => handleClick(item.toGo)}
					stepSelected={stepSelected}
					isClickable={isClickable}
				/>
			)}
		</ul>
	);
}
