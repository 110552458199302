import React, { useState, useEffect, Fragment, useContext } from 'react';
import './TriggersContainer.css'
import Panel from '../../../Components/Panel'
import SearchBar from '../../../Components/SearchBar'
import { groupBy } from 'lodash';
import useEventBroker from '../../../Hooks/useEventBroker'
import { TriggerContext } from './TriggerContext'
import ReactTooltip from 'react-tooltip';

export default function TriggerSelector({
    dataTriggers,
    trackingModeSelector
}) {

    const [showConditionPanel, setShowConditionPanel] = useState(false)
    const [valueInSearchBar, setValueInSearchBar] = useState("");
    const [dataFiltered, setDataFiltered] = useState();
    const [noData, setNoData] = useState();
    const [dataTriggersCategory, setDataTriggersCategory] = useState([]);
    const { publish, subscribe } = useEventBroker()

    const { $rootScope } = useContext(TriggerContext)

    subscribe("evt/triggers/openTriggerSelectorRequested", _ => {
        setShowConditionPanel(true)
    })

    useEffect(() => {
        if (!dataFiltered || dataFiltered.length === 0) {
            setDataFiltered(dataTriggers)
        }
    }, [dataTriggers])

    useEffect(() => {
        groupByTriggersCategory()
    }, [dataFiltered])

    useEffect(() => {
        if (dataTriggers) {
            search(dataTriggers.filter(e => e.trackingMode <= trackingModeSelector), valueInSearchBar);
        }
    }, [valueInSearchBar, trackingModeSelector])

    const handleTriggerSelected = (trigger) => {
        publish("evt/triggers/triggerSelected", { trigger })
    }

    const closeThePannel = () => {
        setShowConditionPanel(false)
    }

    const groupByTriggersCategory = () => {
        const categoriesGrouped = groupBy(dataFiltered, d => [d.categorie, d.categorieOrder, d.categorieIcon])
        setDataTriggersCategory(
            Object.keys(categoriesGrouped)
                .map(t => {
                    const [label, order, icon] = t.split(",")
                    return { label, order, icon }
                })
                .filter(t => t.order > 0)
                .sort((t1, t2) => t1.order - t2.order)
        )
    }


    const search = (table, word) => {
        if (word.length >= 0) {
            let resultName = table.filter(line =>
                line.label !== null && line.label.toUpperCase().indexOf(word.toUpperCase()) > -1
                || (line.tooltip && line.tooltip.toUpperCase().indexOf(word.toUpperCase()) > -1)
            );
            let finalArray = [...resultName];
            let uniqueItems = [...new Set(finalArray)];
            if (uniqueItems.length === 0) {
                setNoData("No result for this search");
                setDataFiltered();
            }
            else {
                setNoData();
                setDataFiltered(uniqueItems);
            }
        } else {
            setNoData();
            setDataFiltered(dataTriggers)
        }
    }

    const handleChange = (value) => {
        setValueInSearchBar(value);
    }

    return (
        <Panel
            isOpen={showConditionPanel}
            onClose={() => closeThePannel()}
            width="560"
            closeOnOverlay={true}
        >
            <ReactTooltip
                backgroundColor="black"
                effect="solid"
                place="bottom"
                globalEventOff="click"
                delayShow={600}
            />
            <section className="section flex_item_fix">
                <div className="panel_title">Add a condition to your persona</div>
            </section>
            <section className="section no_top_pad flex_item_fix">
                <SearchBar
                    placeholder="Search"
                    valueInSearchBar={valueInSearchBar}
                    onChange={(value) => handleChange(value)}
                    autoFocus={true}
                    selectOnFocus={true}
                />
            </section>
            <div className="flex_item_full vscroll pos_rel">
                <section className="section no_top_pad">
                    {noData}
                    {dataTriggersCategory.length > 0 &&
                        <div className="trigger_store">
                            {dataTriggersCategory.map((cat, key) => {
                                if (cat.label === "Hidden") return <></>;
                                return (
                                    <div className="trigger_store_category" key={key}>
                                        <div className="trigger_store_category_name">{cat.label}</div>
                                        <ul className="trigger_store_list">
                                            {dataFiltered &&
                                                dataFiltered.filter(t => t.value !== "TRGSCROLL" && t.value !== "TRGEXITINTENT" && t.value !== "TRGMARKPRESSURE" && t.value !== "TRGTIMESPENTONPAGE").map((trigger, i) =>
                                                    <Fragment
                                                        key={key + "_" + i}
                                                    >
                                                        {trigger.categorie === cat.label && (trigger.saas || $rootScope.User.Role == "Admin") &&
                                                            <li className="trigger_store_item flex" onClick={() => { handleTriggerSelected(trigger); closeThePannel() }}>
                                                                <div className="flex_item_fix">
                                                                    <span className={"trigger_icon icon_" + cat.icon}></span>
                                                                </div>
                                                                <div className="flex_item_full">
                                                                    <div className="trigger_store_item_name">{trigger.label}</div>
                                                                    <div className="trigger_store_item_desc">{trigger.tooltip}</div>
                                                                </div>
                                                                {!trigger.saas &&
                                                                    <div className="flex_item_fix ml_10" data-tip={"For administrators only"}>
                                                                        <i className="trigger_store_item_type fas fa-crown" />
                                                                    </div>
                                                                }
                                                                {trigger.needconfig &&
                                                                    <div className="flex_item_fix ml_10" data-tip={"Need configuration"}>
                                                                        <i className="trigger_store_item_type fa fa-wrench" />
                                                                    </div>
                                                                }
                                                            </li>
                                                        }
                                                    </Fragment>
                                                )}
                                        </ul>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    }
                </section>
            </div>
        </Panel>

    )
}