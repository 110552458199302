import React from 'react';
import { useLocation } from 'react-router-dom';
import ContextAuditProvider from '../context/ContextAudit';
import AuditBody from './AuditBody';
export default function AuditContainer(props) {


	const location = useLocation();
	if(location.pathname !== '/Insight/audit'){
		return <></>;
	}

	return (
		<ContextAuditProvider
			$http={props.props.$http}
			$rootScope={props.props.$rootScope}
			$routeParams={props.props.$routeParams}
			AuthServices={props.props.AuthServices}
		>
			<AuditBody/>
		</ContextAuditProvider>
	);
}
