import React, {useState, useEffect} from 'react';
import { react2angular } from 'react2angular';
import { getUserFullName } from '../../Hooks/useDisplayName';
import Dropdown from '../../Components/Dropdown';
import Listbox from '../../Components/Listbox';
import ListboxItem from '../../Components/ListboxItem';
import Avatar from '../../Components/Avatar';
import Btn from '../../Components/Btn';
import EmptyState from '../../Components/EmptyState';
import styles from './AppHeader.module.css';
import ImpersonatingServices from '../../Services/ImpersonatingServices';
import AccountServices from '../../Services/AccountServices';
import NavigationServices from '../../Services/NavigationServices';
import {
	getFinalUrl,
	getAccountModules,
	getIsAdminView,
	setIsAdminView,
	getStorageModulesIsFirstPage,
	setStorageModulesIsFirstPage
} from './utils';
import newInstanceI18next from '../../Util/traductionUtils';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { allModules } from './MenuData';
import FlagPannel from '../../../flags/component/FlagPannel2';
import Notify from './Notifications';
import angular from 'angular';
export default function AppHeader(props) {
	return (
		<I18nextProvider i18n={newInstanceI18next}>
			<AppHeaderInner {...props} />
		</I18nextProvider>
	);
}

const confidentialBlurStyle = `
.confidential_data {
	filter: blur(4px);
}
`;

function AppHeaderInner(props) {
	
	const [t] = useTranslation('navigation');
	const [userDDIsOpen, setUserDDIsOpen] = useState(false);
	const [showLangPanel, setShowLangPanel] = useState(false);
	const [blurConfidentialData, setBlurConfidentialData] = useState(!!localStorage.getItem('blur_confidential_data'));
	const [accountDDIsOpen, setAccountDDIsOpen] = useState(false);
	const [showAccountDropdown, setshowAccountDropdown] = useState(false);
	const [user, setUser] = useState(null);
	const [nameAccount, setnameAccount] = useState();
	const [isAccountListPage, setIsAccountListPage] = useState(false);
	const [needToFetchDatas, setneedToFetchDatas] = useState(true);
	const [rootScope, setrootScope] = useState();
	const [favoriteAccounts, setFavoriteAccounts] = useState([]);
	const [clientModules, setclientModules] = useState([]);
	const [linkToFirstModule, setlinkToFirstModule] = useState();
	const [currentLang, setCurrentLang] = useState();
	const currentUser = props.AuthServices.GetAuthenticatedUser();
	const authServices = props.AuthServices;
	const isAdmin = currentUser?.roles.includes('Admin');
	const [flagIsOpen, setFlagIsOpen] = useState(false);
	const [adminView, setAdminView] = useState(getIsAdminView());
	const [modulesIsFirstPage, setModulesIsFirstPage] = useState(getStorageModulesIsFirstPage());
	const [canViewModulePage, setCanViewModulePage] = useState(false);

	const accountServices = new AccountServices(props.$http);
	const getAccountData = (accountId,callbackSuccess, callbackError) => 
		accountServices.getAccount(accountId, callbackSuccess, callbackError);
	const navigationServices = new NavigationServices(
		props.$http,
		props.$routeParams
	);
	const loc = window.location.href ; 
	const locationIsSignin = loc.includes('SignIn');

	function getPermissionValue (user, name) {
		if (user && user.Permissions) {
			let perm = user.Permissions.find(element => element.Name === name);
			if (perm) {
				return perm.Value;
			}
		}
		return false;
	}

	function redirect (acc){
		const link = `/Account/Switch/${acc.id}/${acc.mainUserId}`;		
		sessionStorage.removeItem('segmentType');
		return link;
	}
	
	function getModulesAndIdSelected(e){
		const prev = e;
		if(locationIsSignin){
			return;
		}
		setrootScope(props.$rootScope);
		setshowAccountDropdown(true);

		if(!prev || prev.includes('Administration/AdminHome') || prev.includes('Account/Switch') || prev.includes('Callback') || prev.includes('/SignIn')  ){
			setnameAccount();
			setneedToFetchDatas(true);
		}

		if(props.$location.$$path.includes('/Administration/AdminHome')){
			setIsAccountListPage(true);
		} else {
			setIsAccountListPage(false);
		}
	}

	function flagsAreEnabled() {
		const storageFlag = localStorage.getItem('ffl_display');
		return !!(!isNaN(storageFlag) && parseInt(storageFlag));
	}
	
	useEffect(() => {
		props.$rootScope.$on('event:favoritesAccounts', () => { 
			const timer = setTimeout(() => {
				navigationServices.getAccountFavorites(success => {
					setFavoriteAccounts(success);
				});
			},50);
			return () => {
				clearTimeout(timer);
			};
		});
		props.$rootScope.$on('event:routeChangeStart', (_f,e) => { 
			const timer = setTimeout(() => {
				getModulesAndIdSelected(e);
			},50);
			return () => {
				clearTimeout(timer);
			};
		});
		props.$rootScope.$on('event:auth-loginConfirmed', () => { 
			const timer = setTimeout(() => {
				getModulesAndIdSelected('/SignIn');
			},50);
			return () => {
				clearTimeout(timer);
			};
		});
	}, [props.$rootScope, props.$location]);

	function isSupervisorOrAdminuser(){
		if( user && user.roles && (user.roles.includes('Admin') || user.roles.includes('Supervisor'))) {
			return true;
		} else {
			return false;
		}
	}

	function handleSetAdminView() {
		const newValue = !adminView;
		setAdminView(newValue);
		setIsAdminView(newValue);
		props.$rootScope.$broadcast('event:toggleAdminMode', newValue);
	}

	function handleSetModuleIsFirstPage() {
		const newValue = !modulesIsFirstPage;
		setModulesIsFirstPage(newValue);
		setStorageModulesIsFirstPage(newValue);
	}
	
	useEffect(()=>{
		var timer = setTimeout(() => {
			const authorizationToken = authServices && authServices.getAccessToken();
			if(needToFetchDatas && rootScope && !locationIsSignin && authorizationToken){
				const impersonatingServices = new ImpersonatingServices(
					rootScope,
					props.$routeParams
				);
			
				props.$timeout(()=> {
					const tmpUser = {...rootScope.User};
					tmpUser.FullName = getUserFullName(tmpUser);
					setUser(tmpUser);

					setCanViewModulePage(getPermissionValue(tmpUser, 'DEMO_CAN_VIEW'));

					navigationServices.getAccountFavorites(success => {
						setFavoriteAccounts(success);
					});

					const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
					if(impersonatedAccount){
						getAccountData(impersonatedAccount , succ => {
							setneedToFetchDatas(false);
							const userConnect = {...succ};
							setnameAccount(userConnect.Name || '');
							navigationServices.getAccountModules(impersonatedAccount, success => { 
								setclientModules(success);
							});
							
						}, _err =>	setneedToFetchDatas(false));

					}else{
						setneedToFetchDatas(false);
						setnameAccount(tmpUser?.Account?.Name || '');
						if (tmpUser.Account && tmpUser.Account.Key) {
							navigationServices.getAccountModules(tmpUser?.Account?.Key, success => { 
								setclientModules(success);
							});
						}
					}
				},50);
			}
		},300);
		getUrlHomeModule();
		return () => {
			clearTimeout(timer);
		};
	}, [needToFetchDatas, rootScope]);

	function logout() {
		props.AuthServices.logout();
	}

	function getUrlHomeModule(){
		const modules = getAccountModules(clientModules, allModules) ;
		if(modules.length > 0){
			const firstModule = getFinalUrl(modules[0], props);
			setlinkToFirstModule(firstModule);
		}
	}

	function handlePasswordReset() {
		const key = rootScope?.User?.Key;
		if (key) {
			props.AuthServices.changePassword(key);
		}
	}

	function toggleBlurConfidentialData() {
		const newValue = !blurConfidentialData;
		setBlurConfidentialData(newValue);
		if (newValue) {
			localStorage.setItem('blur_confidential_data', '1');
		} else {
			localStorage.removeItem('blur_confidential_data');
		}
	}

	useEffect(()=>{
		if(clientModules){
			getUrlHomeModule();
		}
	},[clientModules]);

	useEffect(()=>{
		const i18nL = localStorage.getItem('i18nL');
		setCurrentLang(JSON.parse(i18nL));
	}, []);

	function handleChangeLanguage(lang) {
		localStorage.setItem('i18nL', JSON.stringify(lang));
		window.location.reload();
	}
	function handleChangeAccount(){
		props.$rootScope.$broadcast('event:changeAccount',true);
	}

	return (
		<div className={styles.header}>
			<div className={styles.header_left}>
		
				{/* <Btn
					size="s"
					style="ghost"
					color="secondary"
				>
					<span className='icon_24 icon_burger icon_color_grey'></span>
				</Btn> */}

				<a className={styles.header_logo} href={linkToFirstModule}>
					<img src="/Assets/beyable_logo.svg" alt="BEYABLE" className={styles.header_logo_img} />
				</a>

				<span className={styles.header_sep}></span>

				{isSupervisorOrAdminuser(user) ?
					<a className={styles.header_link} href="/Administration/AdminHome">
						<span className={styles.header_link_icon + ' icon_14 icon_home'}></span>
						<span className={styles.header_link_text}>{t('moduleNav.myAccounts')}</span>
					</a>
					:
					<span className={styles.header_title + ' confidential_data'}>
						{nameAccount}
					</span>
				}

				{showAccountDropdown && isSupervisorOrAdminuser(user) && (isAccountListPage || nameAccount) &&
				<>
					<span className={styles.header_breadcrumb_arrow}>
						<i className='fas fa-chevron-right fa-fw'></i>
					</span>
					<Dropdown
						isOpen={accountDDIsOpen}
						toggle={(s) => setAccountDDIsOpen(s)}
						scrollbar={false}
						button={
							<button className={isAccountListPage ? styles.header_link_grey : styles.header_link}>
								<span className={styles.header_link_text + ' confidential_data'}>
									{isAccountListPage ? t('moduleNav.myFavAccounts') : nameAccount || '' }
								</span>
								<span className={styles.header_link_arrow}></span>
							</button>
						}
					>
						{favoriteAccounts.length === 0 &&
							<div className='dropdown_section'>
								<EmptyState
									title={t('moduleNav.noFavAccounts')}
									text={t('moduleNav.quicklyFindFavAccounts')}
									icon={'far fa-star'}
									verticalSize='s'
									primaryAction={
										<Btn
											message={t('moduleNav.manageFavAccounts')}
											href="/Administration/AdminHome"
											onClick={() => setAccountDDIsOpen(false)}
										/>
									}
								/>
							</div>
						}
						{favoriteAccounts.length > 0 &&
							<div className='flex flex_v confidential_data' style={{maxHeight: 'calc(100vh - 70px)'}}>
								<div className='flex_item_auto vscroll_custom'>
									<Listbox>
										{favoriteAccounts.map(x =>
											<ListboxItem
												key={x.id}
												message={x.name}
												icon="fas fa-star fa-sm"
												size="l"
												onClick={()=>{setAccountDDIsOpen(false);handleChangeAccount(true);}}
												href={redirect(x)}
											/>
										)}
									</Listbox>
								</div>
								<div className='flex_item_fix'>
									<Listbox>
										<li className='listbox_sep'></li>
										<ListboxItem
											message={t('moduleNav.manageFavAccounts')}
											href="/Administration/AdminHome"
											onClick={() => setAccountDDIsOpen(false)}
										/>
									</Listbox>
								</div>
							</div>
						}
					</Dropdown>
				</>
				}
			</div>
			<div className={styles.header_right}>

				<div className={styles.header_notifications}>
					<Notify props={props}/>
				</div>

				<span className={styles.header_sep}></span>

				{user && user.Account &&
					<Dropdown
						isOpen={userDDIsOpen}
						toggle={(s) => setUserDDIsOpen(s)}
						button={
							<Btn color="secondary" style="ghost" arrow={true} size="s">
								<div className='flex'>
									<div className={[styles.header_avatar, 'flex_item_fix'].join(' ')}>
										<Avatar
											id={user.Account.Key}
											name={user.FullName}
											size='xs'
										/>
									</div>
									<div className='flex_item_auto'>
										{user.FullName}
									</div>
								</div>
							</Btn>
						}
					>
						<Listbox>
							{showLangPanel ?
								<>
									<ListboxItem
										message={t('moduleNav.language')}
										onClick={() => setShowLangPanel(false)}
										icon="fas fa-arrow-left fa-sm"
										size="l"
									/>
									<li className='hr' />
									<ListboxItem
										message='English'
										onClick={() => handleChangeLanguage('en-EN')}
										size="l"
										selected={currentLang == 'en-EN'}
										hasSelectedIcon={true}
									/>
									<ListboxItem
										message='Français'
										onClick={() => handleChangeLanguage('fr-FR')}
										size="l"
										selected={currentLang == 'fr-FR'}
										hasSelectedIcon={true}
									/>
								</>
								:
								<>
									{isAdmin &&
										<ListboxItem
											message={t('moduleNav.language')}
											onClick={() => setShowLangPanel(true)}
											size="l"
										/>
									}
									<ListboxItem
										message={t('moduleNav.resetPassword')}
										onClick={() => handlePasswordReset()}
										size="l"
									/>
									{isAdmin && flagsAreEnabled() &&
										<>
											<li className='hr' />
											<ListboxItem
												message={'Feature flags'}
												onClick={() => {setFlagIsOpen(true); setUserDDIsOpen(false);}}
												size="l"
											/>
										</>
									}
									{isSupervisorOrAdminuser(user) &&
										<>
											<li className='hr' />
											<ListboxItem
												hasCheckbox={true}
												selected={blurConfidentialData}
												message={'Blur confidential data'}
												tooltip={'Blur confidential data for demos'}
												onClick={() => toggleBlurConfidentialData()}
											/>
										</>
									}
									{(isAdmin || canViewModulePage) &&
										<ListboxItem
											hasCheckbox={true}
											selected={modulesIsFirstPage}
											message={'Demo mode'}
											tooltipHTML={<>Define <em>«Modules page»</em> as landing page</>}
											onClick={handleSetModuleIsFirstPage}
										/>
									}
									{isAdmin &&
										<>
											<ListboxItem
												hasCheckbox={true}
												selected={adminView}
												message={'Admin mode'}
												tooltip={'Access to all modules'}
												onClick={handleSetAdminView}
											/>
										</>
									}
									<li className='hr' />
									<ListboxItem
										message={t('moduleNav.signout')}
										size="l"
										onClick={logout}
									/>
								</>
							}
						</Listbox>
					</Dropdown>
				}
			</div>
			<FlagPannel
				{...props}
				isOpen={flagIsOpen}
				onClose={() => setFlagIsOpen(false)}
			/>
			{blurConfidentialData &&
				<style>{confidentialBlurStyle}</style>
			}
		</div>
	);
}
angular
	.module('beyableSaasApp.AppHeader', [])
	.component(
		'appheader',
		react2angular(
			AppHeader,
			[],
			['$http', '$rootScope', '$timeout', '$routeParams', '$location', '$scope', 'AuthServices']
		)
	);
