import React, { useState, useEffect } from 'react';
import styles from './ChartContainer.module.css';
import { FORMAT } from '../../config';

import Dropdown from '../../../../Components/Dropdown';
import Btn from '../../../../Components/Btn';

import moment from 'moment';
import _ from 'lodash';
import SingleBarChart from '../ChartSwitch/SingleBarChart';
import { usePageContext } from '../../context/PageContext';
import SelectMetricTypeContainer from '../Pages/SelectMetricTypeContainer';
import { useAnalyticsContext } from '../../context/AnalyticsContextProvider';

const PagesChartContainer = ({
	pageService,
	selectedTimeGranularity,
	setSelectedTimeGranularity,
}) => {
	const {
		reportTypeSelected,
		navFilter,
		filterUrl,
		isLoadingData,
		setisLoadingData,
		setNeedRefreshgraph,
		needRefreshGraph,
		metricTypeSelected,
		searchValue,
		sourceType,
		utmDimensionsSelected,
		needRefreshForUtmFilter,
		deviceSelected,
		excludeFilter,
		selectedCustomTag
	} = usePageContext();
		
	const { selectedTenant } = useAnalyticsContext();
	const { fromDate, toDate } = navFilter;
	const [hasErrorData, sethasErrorData] = useState(false);
	const [chartData, setChartData] = useState();

	const [timeGranularityOptions, setTimeGranularityOptions] = useState([
		{ key: 'Day' },
	]);
	const [splitDropdownIsOpen, setSplitDropdownIsOpen] = useState(false);
	const setupTimeGranularityOptions = () => {
		if (!navFilter) return;
		const dateRange = moment(navFilter.toDate, FORMAT).diff(
			moment(navFilter.fromDate, FORMAT),
			'days'
		);
		let granularityOptions;
		if (dateRange < 14) {
			granularityOptions = [{ key: 'Day' }];
		} else if (dateRange < 32) {
			granularityOptions = [
				{ key: 'Day' },
				{ key: 'MondayWeek', label: 'Week', isDefault: true },
			];
		} else if (dateRange < 64) {
			granularityOptions = [
				{ key: 'MondayWeek', label: 'Week' },
				{ key: 'Month' },
			];
		} else if (dateRange < 366) {
			granularityOptions = [{ key: 'Month' }];
		} else {
			granularityOptions = [{ key: 'Year' }];
		}
		setTimeGranularityOptions(granularityOptions);
		return granularityOptions;
	};
	const onHandleChangeTimeGranularity = (granularity) => {
		setSelectedTimeGranularity(granularity);
	};

	const refreshChart = (option) => {
		const dateRange = moment(navFilter.toDate, FORMAT).diff(
			moment(navFilter.fromDate, FORMAT),
			'days'
		);
		const granularity = dateRange < 14 ? 'day' : selectedTimeGranularity;
		if(!selectedCustomTag){
			if (filterUrl || searchValue.length !== 0) {
				setisLoadingData(true);
				const isExact = filterUrl ? true : false;
				const filters = filterUrl ? filterUrl : searchValue;
				fromDate &&
					toDate &&
					pageService.getPageMetricsOverTimeWithFilterPamaters(
						deviceSelected,
						metricTypeSelected.key,
						selectedTenant,
						navFilter,
						granularity,
						reportTypeSelected,
						filters,
						isExact,
						sourceType,
						utmDimensionsSelected,
						excludeFilter,
						(success) => {
							setisLoadingData(false);
							sethasErrorData(false);
							const dateLocal = success.map((x) =>
								moment(x.Date).format('D MMM')
							);
							setChartData({
								data: success.map((data) => data.Value),
								dayPeriode: dateLocal,
								name: metricTypeSelected.label,
								title: filters,
								isSelectedFilter: true,
							});
							setupTimeGranularityOptions();
							setNeedRefreshgraph(false);
						},
						(error) => {
							console.log(error);
							setisLoadingData(false);
							setNeedRefreshgraph(false);
							sethasErrorData(true);
						}
					);
			} else if (
				!filterUrl &&
				searchValue.length === 0 &&
				!needRefreshGraph
			) {
				setisLoadingData(true);
				fromDate &&
					toDate &&
					pageService.getPageMetricsOverTime(
						deviceSelected,
						metricTypeSelected.key,
						selectedTenant,
						navFilter,
						granularity,
						reportTypeSelected,
						sourceType,
						utmDimensionsSelected,
						excludeFilter,
						(success) => {
							sethasErrorData(false);
							setisLoadingData(false);
							const dateLocal = success.map((x) =>
								moment(x.Date).format('D MMM YYYY')
							);
							setChartData({
								data: success.map((data) => data.Value),
								dayPeriode: dateLocal,
								name: metricTypeSelected.label,
								isSelectedFilter: false,
							});
							setupTimeGranularityOptions();
							setNeedRefreshgraph(false);
						},
						(error) => {
							console.log(error);
							sethasErrorData(true);
							setisLoadingData(false);
							setNeedRefreshgraph(false);
						}
					);
			}
		}else{
			setisLoadingData(true);
			fromDate &&
				toDate &&
				pageService.getPageMetricsOverTimeCustomTag(
					deviceSelected,
					metricTypeSelected.key,
					selectedTenant,
					navFilter,
					granularity,
					reportTypeSelected,
					sourceType,
					utmDimensionsSelected,
					false,
					selectedCustomTag,
					searchValue,
					searchValue.length === '' ? true : false,
					excludeFilter,
					(success) => {
						sethasErrorData(false);
						setisLoadingData(false);
						const dateLocal = success.map((x) =>
							moment(x.Date).format('D MMM YYYY')
						);
						setChartData({
							data: success.map((data) => data.Value),
							dayPeriode: dateLocal,
							name: metricTypeSelected.label,
							isSelectedFilter: false,
						});
						setupTimeGranularityOptions();
						setNeedRefreshgraph(false);
					},
					(error) => {
						console.log(error);
						sethasErrorData(true);
						setisLoadingData(false);
						setNeedRefreshgraph(false);
					}
				);
		}
	};
	useEffect(() => {
		refreshChart();
	}, [
		navFilter,
		selectedTimeGranularity,
		reportTypeSelected,
		metricTypeSelected,
		filterUrl,
		searchValue,
		sourceType,
		selectedTenant,
		needRefreshForUtmFilter,
		deviceSelected,
		excludeFilter,
		selectedCustomTag
	]);

	return (
		<div className={styles.containerMedium}>
			<div className="flex mb_30">
				<div className="flex_item_full">
					<SelectMetricTypeContainer styles={styles} />
				</div>
				{timeGranularityOptions.length > 1 && (
					<div className="btn_switch ml_10">
						{timeGranularityOptions.map((o) => {
							const isSelected =
                                o.key === selectedTimeGranularity;
							return (
								<Btn
									key={o.key}
									style="outline"
									color="secondary"
									size="xxs"
									className={isSelected ? 'active' : ''}
									onClick={() =>
										onHandleChangeTimeGranularity(o.key)
									}
									message={o.label || o.key}
								/>
							);
						})}
					</div>
				)}
			</div>
			{!isLoadingData && chartData && chartData.data.length == 0 && (
				<div className="empty_state analytics_empty_state">
					<img
						className="empty_state_img"
						src="/Assets/analytics_data_empty.svg"
					/>
					<div className="empty_state_title">
                        No data for this period
					</div>
					<div className="empty_state_text">
                        Maybe try with another period or other filters
					</div>
				</div>
			)}
			<div className={styles.barGraph}>
				<SingleBarChart
					data={chartData}
					isLoadingData={isLoadingData}
					hasErrorData={hasErrorData}
				/>
			</div>
		</div>
	);
};

PagesChartContainer.displayName = 'PagesChartContainer';

export default PagesChartContainer;
