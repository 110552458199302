import React, { useState, useEffect, Fragment } from 'react';
import { react2angular } from 'react2angular';
import './AccountParametersContainer.css';
import Btn from '../../../Components/Btn';
import SystemServices from '../../../Services/SystemServices';
import ToolingServices from '../../../Services/ToolingServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SelectAccParamCreationModeContainer from './SelectAccParamCreationModeContainer';
import EditConfigAlertStock from '../ConfigAlerteStock/EditConfigAlertStock';
import CampaignListParameters from './CampaignListParameters';
import EditConfigEmailMyBasket from '../ConfigEmailMyBasket/EditConfigEmailMyBasket';
import EditConfigSendCodePromo from './../SendCodePromo/EditConfigSendCodePromo';
import Panel from './../../../Components/Panel';
import { Trans, useTranslation } from 'react-i18next';
export default function AccountParametersContainer(props) {
	const propS = props.props ? props.props : props;
	const systemServices = new SystemServices(propS.$rootScope);
	const impersonatingServices = new ImpersonatingServices(propS.$rootScope, propS.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || propS.$rootScope.User.Account.Key;
	const campaignId = propS.idOfCampaign;
	const toolingServices = new ToolingServices(propS.$http, propS.$routeParams);
	const [t, i18n] = useTranslation('common');
	const getParameterConfigurationByOperation = (accountId, campaignId, callbackSuccess, callbackError) => toolingServices.getParameterConfigurationByOperation(accountId, campaignId, success => { callbackSuccess(success); }, error => { callbackError(error); });

	const getStockAlert = (accountId, campaignId, callbackSuccess, callbackError) => toolingServices.getStockAlert(accountId, campaignId, success => { callbackSuccess(success); }, error => { callbackError(error); });

	const postStockAlert = (accountId, campaignId, provider, callbackSuccess, callbackError) => toolingServices.postStockAlert(accountId, campaignId, provider, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const postStockAlertTest = (accountId, campaignId, recipient, callbackSuccess, callbackError) => toolingServices.postStockAlertTest(accountId, campaignId, recipient, success => { systemServices.showSuccess(t('AccountParametersContainer.emailSent')); callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const putStockAlert = (accountId, campaignId, provider, callbackSuccess, callbackError) => toolingServices.putStockAlert(accountId, campaignId, provider, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const getConfigVisitorCartById = (accountId, campaignId, callbackSuccess, callbackError) => toolingServices.getConfigVisitorCartById(accountId, campaignId, success => { callbackSuccess(success); }, error => { callbackError(error); });

	const postConfigVisitorCartById = (accountId, campaignId, provider, callbackSuccess, callbackError) => toolingServices.postConfigVisitorCartById(accountId, campaignId, provider, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const putConfigVisitorCartById = (accountId, campaignId, provider, callbackSuccess, callbackError) => toolingServices.putConfigVisitorCartById(accountId, campaignId, provider, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const postConfigVisitorTest = (accountId, campaignId, recipient, callbackSuccess, callbackError) => toolingServices.postConfigVisitorTest(accountId, campaignId, recipient, success => { systemServices.showSuccess(t('AccountParametersContainer.emailSent')); callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const getCodePromoById = (accountId, campaignId, callbackSuccess, callbackError) => toolingServices.getCodePromoById(accountId, campaignId, success => { callbackSuccess(success); }, error => { callbackError(error); });

	const postConfigPostCodePromoById = (accountId, campaignId, provider, callbackSuccess, callbackError) => toolingServices.postConfigPostCodePromoById(accountId, campaignId, provider, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const putCodePromoById = (accountId, campaignId, provider, callbackSuccess, callbackError) => toolingServices.putCodePromoById(accountId, campaignId, provider, success => { callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const postCodePromoById = (accountId, campaignId, recipient, callbackSuccess, callbackError) => toolingServices.postCodePromoById(accountId, campaignId, recipient, success => { systemServices.showSuccess(t('AccountParametersContainer.emailSent')); callbackSuccess(success); }, error => { systemServices.showError(); callbackError(error); });

	const [accountConfigurationList, setAccountConfigurationList] = useState([]);
	const [clickOnConfiguration, setClickOnConfiguration] = useState();
	const [selectToMap, setSelectToMap] = useState();
	const [hasProperties, setHasProperties] = useState(false);
	const [AccountCurrentTab, setAccountCurrentTab] = useState();
	const [isCreationMode, setIsCreationMode] = useState(false);
	const [isReloading, setIsRealoading] = useState(true);

	useEffect(() => {
		if (!propS.idOfCampaign) {
			return;
		}
		if (propS.idOfCampaign !== '') {
			getParameterConfigurationByOperation(accountId, propS.idOfCampaign, data => {
				setAccountConfigurationList(data);
				setIsRealoading(false);
			}, error => {
				console.log('error', error);
				setIsRealoading(false);
			});
		}
	}, [propS]);

	const getTheParameterConfigurationByOperation = (e) => {
		setClickOnConfiguration(e);
		getParameterConfigurationByOperation(accountId, propS.idOfCampaign, data => {
			setAccountConfigurationList(data);
		}, error => {
			console.log('error', error);
		});
	};

	const checkIfHasExistingConfig = () => {
		if (accountConfigurationList.hasStockAlert) {
			setAccountCurrentTab(4);
		} else if (accountConfigurationList.hasSendVisitorCart) {
			setAccountCurrentTab(3);
		} else if (accountConfigurationList.hasSendPromoCode) {
			setAccountCurrentTab(2);
		} else {
			setAccountCurrentTab();
		}
	};
	useEffect(() => {
		if (accountConfigurationList) {
			if (!clickOnConfiguration) {
				checkIfHasExistingConfig();
			} else {
				setAccountCurrentTab(clickOnConfiguration);
			}
			const selectToMapArr = [];
			for (const key in accountConfigurationList) {
				if (accountConfigurationList[key] == true) {
					setHasProperties(true);
				} else {
					selectToMapArr.push(key);
				}
				setSelectToMap(selectToMapArr);
			}
		}
		return () => {

		};
	}, [accountConfigurationList]);

	const ChangeTheNavigationSelection = (e) => {
		setAccountCurrentTab(e);
	};
	const closeTheModal = () => {
		propS.onLoad();
		setAccountConfigurationList([]);
		setClickOnConfiguration();
		setAccountCurrentTab();
		setHasProperties();
		setSelectToMap();
		setHasProperties(false);
		setIsCreationMode(false);
		setIsRealoading(true);
	};

	const selectFiltered = CampaignListParameters && selectToMap && CampaignListParameters.filter(elem => selectToMap.includes(elem.compare));

	return (
		<Panel
			isOpen={propS.isOppen}
			onClose={(e) => closeTheModal()}
			side="right"
			width="700"
		>
			<div className="section l flex_item_fix">
				<div className="page_title">{t('AccountParametersContainer.Configuration')}</div>
				<div className="page_sub_title grey_medium">{propS.campaignName}</div>
			</div>
			{!isReloading && hasProperties && accountConfigurationList && AccountCurrentTab !== 0 && !isCreationMode &&
                <div className="section no_vertical_pad flex_item_fix">
                	<div className="flex">
                		<div className="flex_item_full">
                			<ul className="tabs no_border">
                				{accountConfigurationList.hasStockAlert ?
                					<li>
                						<a onClick={() => ChangeTheNavigationSelection(4)} className={AccountCurrentTab == 4 ? 'active' : ''}>{t('AccountParametersContainer.StockAlert')}</a>
                					</li>
                					:
                					<Fragment>
                						{AccountCurrentTab === 4 &&
                                            <li>
                                            	<a className="active">{t('AccountParametersContainer.StockAlert')}</a>
                                            </li>
                						}
                					</Fragment>
                				}
                				{accountConfigurationList.hasSendVisitorCart ?
                					<li>
                						<a onClick={() => ChangeTheNavigationSelection(3)} className={AccountCurrentTab == 3 ? 'active' : ''}>
										{t('AccountParametersContainer.EmailMyBasket')}</a>
                					</li>
                					:
                					<Fragment>
                						{AccountCurrentTab === 3 &&
                                            <li>
                                            	<a className="active">{t('AccountParametersContainer.EmailMyBasket')}</a>
                                            </li>
                						}
                					</Fragment>
                				}
                				{accountConfigurationList.hasSendPromoCode ?
                					<li>
                						<a onClick={() => ChangeTheNavigationSelection(2)} className={AccountCurrentTab == 2 ? 'active' : ''}>{t('AccountParametersContainer.PromoCodeSending')}</a>
                					</li>
                					:
                					<Fragment>
                						{AccountCurrentTab === 2 &&
                                            <li>
                                            	<a className="active">{t('AccountParametersContainer.PromoCodeSending')}</a>
                                            </li>
                						}
                					</Fragment>
                				}
                			</ul>
                		</div>
                		<div className="flex_item_fix">
                			{selectToMap && selectFiltered.length !== 0 && !isCreationMode &&
                                <div className="account_nav_tooling_last-item">
                                	<a className="" onClick={(e) => setAccountCurrentTab(0)}>{t('AccountParametersContainer.AddFeature')}</a>
                                </div>
                			}
                		</div>
                	</div>
                </div>
			}
			<div className="flex_item_full pos_rel vscroll">
				{!isReloading &&
                    <Fragment>
                    	{!hasProperties && AccountCurrentTab == undefined &&
                            <div className="empty_page_flex vscroll">
                            	<div className="empty_page_sub_title grey_medium">{t('AccountParametersContainer.noConfig')}</div>
                            	<div className="empty_page_btn">
                            		<Btn
                            			message={t('AccountParametersContainer.AddFeature')}
                            			size="l"
                            			icon="fas fa-plus"
                            			onClickFunction={(e) => setAccountCurrentTab(0)}
                            		/>
                            	</div>
                            </div>
                    	}
                    	{AccountCurrentTab === 0 &&
                            <SelectAccParamCreationModeContainer
                            	listOfConfiguRationAccount={selectFiltered}
                            	setAccountCurrentTab={(e) => setAccountCurrentTab(e)}
                            	checkIfHasExistingConfig={() => checkIfHasExistingConfig()}
                            	setIsCreationMode={(e) => setIsCreationMode(e)}
                            />
                    	}
                    	{AccountCurrentTab === 4 &&
                            <EditConfigAlertStock
                            	isCreationMode={isCreationMode}
                            	setIsCreationMode={(e) => setIsCreationMode(e)}
                            	setAccountCurrentTab={(e) => setAccountCurrentTab(e)}
                            	postStockAlert={postStockAlert}
                            	accountId={accountId}
                            	campaignId={campaignId}
                            	getStockAlert={getStockAlert}
                            	putStockAlert={putStockAlert}
                            	getParameterConfigurationByOperation={(e) => getTheParameterConfigurationByOperation(e)}
                            	postStockAlertTest={postStockAlertTest}
                            />
                    	}
                    	{AccountCurrentTab === 3 &&
                            <EditConfigEmailMyBasket
                            	isCreationMode={isCreationMode}
                            	setIsCreationMode={(e) => setIsCreationMode(e)}
                            	setAccountCurrentTab={(e) => setAccountCurrentTab(e)}
                            	accountId={accountId}
                            	campaignId={campaignId}
                            	getConfigVisitorCartById={getConfigVisitorCartById}
                            	postConfigVisitorCartById={postConfigVisitorCartById}
                            	putConfigVisitorCartById={putConfigVisitorCartById}
                            	postConfigVisitorTest={postConfigVisitorTest}
                            	getParameterConfigurationByOperation={(e) => getTheParameterConfigurationByOperation(e)}
                            />
                    	}
                    	{AccountCurrentTab === 2 &&
                            <EditConfigSendCodePromo
                            	isCreationMode={isCreationMode}
                            	setIsCreationMode={(e) => setIsCreationMode(e)}
                            	setAccountCurrentTab={(e) => setAccountCurrentTab(e)}
                            	accountId={accountId}
                            	campaignId={campaignId}
                            	getCodePromoById={getCodePromoById}
                            	postConfigPostCodePromoById={postConfigPostCodePromoById}
                            	putCodePromoById={putCodePromoById}
                            	postCodePromoById={postCodePromoById}
                            	getParameterConfigurationByOperation={(e) => getTheParameterConfigurationByOperation(e)}

                            />
                    	}
                    </Fragment>
				}
				{
					isReloading &&
                    <div className="spinner_overlay">
                    	<div className="spinner">
                    		<div className="rect1"></div>
                    		<div className="rect2"></div>
                    		<div className="rect3"></div>
                    		<div className="rect4"></div>
                    		<div className="rect5"></div>
                    	</div>
                    </div>
				}
			</div>
		</Panel>
	);
}
angular.module('beyableSaasApp.Dashboard')
	.component('modalParameters', react2angular(AccountParametersContainer, ['onLoad', 'idOfCampaign', 'isOppen', 'campaignName'], ['$http', '$rootScope', '$routeParams']));
