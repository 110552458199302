const EngagementLevelTitle =
{
    "X": "Lost",
    "D": "Discovery",
    "C": "Consideration",
    "I": "Intention",
    "A": "Purchase",
    "E": "Experience",
    "R": "Re-purchase"
};

export default EngagementLevelTitle;
