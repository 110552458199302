import React, { useEffect, useState, Fragment } from 'react';
import { react2angular } from 'react2angular';
import './GlobalDashboard.css';
import GlobalDashboardServices from '../../../Services/GlobalDashboardServices';


export default function GlobalDashboard(props) {

    const user = props.$rootScope.User
    const accountId = "35f31463-1f83-4986-ab74-7bc514a81e37" 
    // props.$routeParams.ka ? props.$routeParams.ka : user.Account.Key

    const globalDashboardServices = new GlobalDashboardServices(props.$http, props.AuthServices)
    const [isLoadingCampaign, setIsLoadingCampaign] = useState(true)
    const [totalOnSiteCampaigns, setTotalOnSiteCampaigns] = useState(0)
    const [totalBusinessOperations, setTotalBusinessOperations] = useState(0)
    const [totalEmailRemarketing, setTotalEmailRemarketing] = useState(0)
    const [availableFormats, setAvailableFormats] = useState()
    const [topCampaignsImpression, setTopCampaignsImpression] = useState([])
    
    const getEmailRemarketingCampaigns = (success, error) => globalDashboardServices.chargeEmailRemarketingCount(accountId, success, error)        
    const getOnSiteCampaigns = (success, error) => globalDashboardServices.chargeOnSiteCampaignsCount(accountId, success, error)
    const getBusinessOperations = (success, error) => globalDashboardServices.chargeBusinessOperationsCount(accountId, success, error)
    const getAvailableFormats = (success, error) => globalDashboardServices.chargeAvailableFormats(accountId, success, error)
    const getTopDisplayedOnSiteCampaigns = (success, error) => globalDashboardServices.chargeTop5DisplayedOnSiteCampaigns(accountId, success, error)

    
    useEffect(() => {
        getEmailRemarketingCampaigns(e => setTotalEmailRemarketing(e))
        getOnSiteCampaigns(e => setTotalOnSiteCampaigns(e))
        getBusinessOperations(e => setTotalBusinessOperations(e))
        getAvailableFormats(e => {setAvailableFormats(e), setIsLoadingCampaign(false)})
        getTopDisplayedOnSiteCampaigns(e => {setTopCampaignsImpression(e)})
    }, [])

    useEffect(() => {
        if (totalOnSiteCampaigns.length === 0) {
            
        }
    }, [totalOnSiteCampaigns])
    
    const variationImpressions = (last, lastLast) => {
        const diff = last - lastLast

       return Math.abs(diff) / 100
    }   


    return(
        <Fragment>
            <div>
                {user.Account.Name} 's Dashboard
                <h1> Welcome Back {user.FirstName}</h1>
            </div>
            <div className="flex">
                    <div className="Board">
                        <div className="title">
                            Statistiques
                            <h1>Top displayed campaigns for the last 30 days</h1>
                        </div>
                        {topCampaignsImpression && topCampaignsImpression.map(campaign =>
                            <Fragment
                            key={campaign.id}
                            >
                            {campaign.impressionsLast30DaysValueDisplayedAbsolute &&
                                <Fragment>
                                    <div className="campaign-board-row">
                                    <div>{campaign.name}</div>
                                        <div className="stats-change">
                                            <div className="number-impressions">{campaign.impressionsLast30DaysValueDisplayedAbsolute}</div>
                                            {campaign.impressionsLast30DaysValueDisplayedAbsolute > campaign.impressionsLastLast30DaysValueDisplayedAbsolute &&
                                                <>
                                                    <i className="icofont-simple-up green"></i>
                                                    <div className="number-impressions">{variationImpressions(campaign.impressionsLast30DaysValueDisplayedAbsolute, campaign.impressionsLastLast30DaysValueDisplayedAbsolute)}%</div>
                                                </>
                                            }
                                            {campaign.impressionsLast30DaysValueDisplayedAbsolute <= campaign.impressionsLastLast30DaysValueDisplayedAbsolute &&
                                                <>
                                                    <i className="icofont-simple-down red"></i>
                                                    <div className="number-impressions">-{variationImpressions(campaign.impressionsLast30DaysValueDisplayedAbsolute, campaign.impressionsLastLast30DaysValueDisplayedAbsolute)}%</div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Fragment>
                            }   
                            </Fragment>
                            )
                        }
                    </div>
                
                <div className="Board">
                    <div className="title">
                        Activation
                        <h1>On-Going campaigns</h1>
                    </div>
                    <div className="type-board-row">
                        <div className="number-bubble">{totalBusinessOperations}</div>
                        Business Operation 
                    </div>
                    <div className="type-board-row">
                        <div className="number-bubble">{totalEmailRemarketing}</div>
                        Email remarketing  
                    </div>
                    <div className="type-board-row">
                        <div className="number-bubble">{totalOnSiteCampaigns}</div>
                        onSite Campaigns 
                    </div>
                </div>
                <div className="Board-format">
                    <div className="title">
                            Personnalisation
                            <h1>Available formats</h1>
                    </div>
                    {isLoadingCampaign &&
                        <div className="spinner">
                            <div className="rect1"></div>
                            <div className="rect2"></div>
                            <div className="rect3"></div>
                            <div className="rect4"></div>
                            <div className="rect5"></div>
                        </div> 
                    }
                    <div className="wrapper-format">
                        <div className="format-container">
                            {availableFormats && availableFormats.map(e =>
                                <div className="format-card" key={e.Key}>
                                    <h3>{e.Name}</h3>
                                    <img className="img-format" src={e.ThumbnailUrl}/>
                                    <a>new campaign</a>
                                </div>
                            )}
                        </div>
                    </div>
                    {availableFormats && availableFormats.length === 0 &&
                        <h3>No available format</h3>
                    }
                </div>
            </div>
        </Fragment>
    )

}



angular
    .module('beyableSaasApp.Home', [])
    .component('globaldashboard', react2angular(GlobalDashboard, [], ['$rootScope', '$scope', '$http', '$routeParams', 'AuthServices']));