import React from 'react';
import './GalleryContainer.css';
export default function GalleryContainer({ closeModal, findPrev, findNext, src, infos }) {

	function FileConvertSize(aSize) {
		aSize = Math.abs(parseInt(aSize, 10));
		var def = [[1, 'octets'], [1024, 'ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
		for (var i = 0; i < def.length; i++) {
			if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
		}
	}
	return (
		<>
			<div className="modal-overlay-businessOp" onClick={closeModal}></div>
			<div className="modal-businessOp">
				<a href="#" className='modal-close-businessOp' onClick={() => closeModal()} ></a>
				<a href="#" className='modal-nav modal-prev-businessOp' onClick={() => findPrev()} >&lsaquo;</a>
				<a href="#" className='modal-nav modal-next-businessOp' onClick={() => findNext()} >&rsaquo;</a>
				{/* <div> */}
				<img src={src} className='modal-body-businessOp' />
				{/* </div> */}
				<div className="modal-info-businessOp">
					<p>{infos.metaData.fileName}</p>
					{FileConvertSize(infos.metaData.weight)}
					<span className="bannersListItemInfoSep">|</span>
					{infos.metaData.size.width}x{infos.metaData.size.height}
					<span className="bannersListItemInfoSep">|</span>
					{infos.metaData.extension && infos.metaData.extension.toUpperCase()}
				</div>
			</div>
		</>
	);
}
