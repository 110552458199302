import React from 'react';
import { useProductFeedEditionContext } from '../../context/ProductFeedEditionContext';
import ConnectionInfosContainer from './ConnectionInfosContainer';
import MappingFieldsContainer from './MappingFieldsContainer';
import editionStyles from '../ProductFeedEdition.module.css';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import Mappingcustom from './Mappingcustom';
export default function MappingContainer() {
	const {step,needToFetchMetaDatas,metadatasSourcesIsLoading} = useProductFeedEditionContext();
	
	if(step !== 2){
		return null;
	}
	return (
		<div className={editionStyles.wrapper_outer}>
			<div className={editionStyles.wrapper_inner}>

				{/* {(needToFetchMetaDatas || metadatasSourcesIsLoading) &&
					<SpinnerWheel wheelSize='l' />
				} */}
				{
				// !metadatasSourcesIsLoading && 
					<>
						<ConnectionInfosContainer />
						<div className={editionStyles.header}>
							<div className={editionStyles.header_title}>Field mapping</div>
							<div className={editionStyles.header_desc}>Help us to map your source fields.</div>
						</div>
						<div className={editionStyles.card_group}>
							<MappingFieldsContainer />
							<Mappingcustom/>
						</div>
					</>
				}
			</div>
		</div>
	);
}
