import React, { createContext, useContext, useEffect, useState } from 'react';
import TriggerAccountServices from '../../Services/TriggerAccountServices';
import { groupBy } from 'lodash';
import { isUsedSegment, addGroupForTriggers, compareAllTriggersUnBuild } from './Utils';
import { v4 as uuidv4 } from 'uuid';
import PersonaServices from '../../Services/PersonaServices';
import SetupAccountServices from '../../Services/SetupAccountServices';
import ProductTagServices from '../../Services/ProductTagServices';


export const TriggerContext = createContext();

function useTriggerContext(props) {
	return useContext(TriggerContext);
}

const CreateTriggerContextProvider = ({ accountId, $http, initialTriggers, mode, isModuleEditorMode, getModuleFormats, useModuleOnCreation, saveChangeOnTrigger,
	setUpSpecificationsForSaving, referenceDataTriggerOperands, handleTrackingModeChange, trackingMode, canSwitchToNoTracking, children, impersonatingServices, isAbTestMode, isNativeApp }) => {

	// Le nombre maximum de triggers qu'un utilisateur non-admin peut avoir (en dur pour quick fix)
	const non_admin_trigger_nb = 44;

	const triggerService = new TriggerAccountServices($http);
	const personaService = new PersonaServices($http);
	const setupAccountServices = new SetupAccountServices($http);
	const productTagServices = new ProductTagServices($http);

	const [targetingRules, setTargetingRules] = useState([]);

	const [segmentLibrary, setSegmentLibrary] = useState([]);
	const [favoriteRules, setFavoriteRules] = useState([]);

	const [refTriggerSet, setRefTriggerSet] = useState([]);

	const [isLoading, setIsLoading] = useState(true);
	const isAnAdmin = impersonatingServices.isAnAdmin();
	const [hasNotAvailableTriggerGroup, setHasNotAvailableTriggerGroup] = useState(false);

	const getAccountTriggers = (callbackSuccess) => {
		triggerService.getAccountTrigger(accountId, callbackSuccess, err => console.log(err), false, isNativeApp ? 'NativeApp' : 'OnSite');
	};
	const saveRule = (accountId, persona, callbackSuccess) => personaService.createNewPersona(accountId, {...persona, contextIdentifier: isNativeApp ? 'NativeApp' : 'OnSite'}, callbackSuccess,
		error => console.log(error));
	const getFavoriteRules = (accountId, callbackSuccess) => personaService.getPersonasList(accountId, isNativeApp ? 'NativeApp' : 'OnSite', callbackSuccess, error => console.log(error));
	const deleteFavoriteRule = (accountId, groupId, callbackSuccess) => personaService.deletePersona(accountId, groupId, callbackSuccess,
		error => console.log(error));

	const editFavoriteRule = (accountId, rule, groupId, callbackSuccess) => personaService.editPersona(accountId, rule, groupId, callbackSuccess, error => console.log(error));

	const buildTriggers = (accountTrigger, triggers) => {
		const triggerSet = [];
		triggers.map(trigger => {
			let spec = { ...trigger };
			if (spec.Name === 'TRGGEOLOC' || spec.Name == 'TRGEXCLUDEDGEOLOC') {
				if (spec.ValueToCompare) {
					var perimeters = spec.ValueToCompare;
					if (Array.isArray(perimeters)) {
						for (let p = 0; p < perimeters.length; p++) {
							const perimeter = perimeters[p];
							//perimeter.Guid = guid();
							perimeter.LocationRange = perimeter.Distance;
							if (perimeter.Position) {
								perimeter.Latitude = perimeter.Position.Latitude;
								perimeter.Longitude = perimeter.Position.Longitude;
								perimeter.Address = perimeter.Position.city;
							} else {
								perimeter.Latitude = 0;
								perimeter.Longitude = 0;
								perimeter.Address = '';
							}
						}
					}
				}
			}
			const accTrigger = accountTrigger.find(e => e.triggerName === spec.Name);
			if (trigger && accTrigger) {
				if ((accTrigger.type !== '' && spec.ValueToCompare !== '' && spec.ValueToCompare !== 'all') || (accTrigger.type === '' && spec.ValueToCompare === accTrigger.defaultlabel)) {
					const triggerConfig = accTrigger.config;
					const triggerData = accTrigger.data[language];
					// spec.DataTriggerOperands = triggerData.choiceValues;
					// spec.DataTriggerOperands = triggerConfig.operators
					spec.DataTriggerOperands = referenceDataTriggerOperands[spec.Name];
					spec.ChoiceValues = triggerData.choiceValues;
					// spec.DefaultLabel = trigger.defaultlabel;
					spec.Label = triggerData.label;
					spec.Tooltip = triggerData.tooltip;
					spec.Type = triggerConfig.type;
					spec.Categorie = triggerConfig.category;
					spec.Separator = triggerConfig.separator;
					spec.NeedConfig = triggerConfig.needconfig;
					spec.Saas = triggerConfig.saaS;

					if (spec.Type === 'textarea') {
						spec.ValueToCompare = spec.ValueToCompare.replace(/;/gi, '\n');
					}
					if (spec.Type === 'list') {
						spec.ValueToCompare = spec.ValueToCompare.toString();
					}
					if (spec.Name === 'TRGCHECKDEVICE') {
						const uniqueVal = [...new Set(spec.ValueToCompare)];
						const valueDevices = uniqueVal.map(element => {
							switch (element) {
								case 0:
									return 'desktop';
								case 1:
									return 'tablet';
								case 2:
									return 'mobile';
								default:
									return element;
							}
						});
						spec.ValueToCompare = valueDevices;
					}
					if ((spec.Type === 'multiplelist' || spec.Type === 'multiplelistsearch' || spec.Type === 'schedule') && spec.Name !== 'TRGCHECKDEVICE') {
						var separator = spec.Separator ? spec.Separator : ',';
						if (typeof spec.ValueToCompare === 'string') {
							spec.ValueToCompare = spec.ValueToCompare.split(separator);
						}
					}
					triggerSet.push(spec);
				}
			}
		});
		return triggerSet;
	};

	const buildContextAndConditionTrigger = (accountTrigger, groupId, groupLabel, triggerSet) => {
		let objectTrigger = {};
		const conditionTriggerArray = [];
		triggerSet.map(e => {
			switch (e.Name) {
				case 'TRGCHECKDEVICE':
					objectTrigger.deviceTrigger = e;
					break;
				case 'TRGSCROLL':
					objectTrigger.eventTrigger = e;
					break;
				case 'TRGEXITINTENT':
					objectTrigger.eventTrigger = e;
					break;
				case 'TRGMARKPRESSURE':
					objectTrigger.numberOfTimeTrigger = e;
					break;
				case 'TRGTIMESPENTONPAGE':
					objectTrigger.delayTrigger = e;
					break;
				default:
					conditionTriggerArray.push(e);

			}
		});
		objectTrigger.condition = conditionTriggerArray;
		if (!objectTrigger.deviceTrigger) {
			let deviceTrigger = transformRefTrigger(accountTrigger.find(e => e.triggerName === 'TRGCHECKDEVICE'));
			deviceTrigger.Group = { Id: groupId, Label: groupLabel };
			deviceTrigger.ValueToCompare = ['mobile', 'tablet', 'desktop'];
			objectTrigger.deviceTrigger = deviceTrigger;
		}
		if (!objectTrigger.numberOfTimeTrigger) {
			let nbrTrigger = transformRefTrigger(accountTrigger.find(e => e.triggerName === 'TRGMARKPRESSURE'));
			nbrTrigger.Group = { Id: groupId, Label: groupLabel };
			nbrTrigger.ValueToCompare = '-1';
			objectTrigger.numberOfTimeTrigger = nbrTrigger;
		}
		return objectTrigger;
	};

	const buildTargetingRules = (data, groupedTrigger) => {
		const array = [];
		Object.keys(groupedTrigger).map(e => {
			const [label, id, index] = e.split(',');
			const triggersBuild = buildTriggers(data, groupedTrigger[e]);
			let triggers = buildContextAndConditionTrigger(data, id, label, triggersBuild);
			if (!triggers.deviceTrigger) {
				let deviceTrigger = transformRefTrigger(data.find(e => e.triggerName === 'TRGCHECKDEVICE'));
				deviceTrigger.Group = { Id: id, Label: label };
				deviceTrigger.ValueToCompare = ['mobile', 'tablet', 'desktop'];
				triggers.deviceTrigger = deviceTrigger;
			}
			if (!compareToBaseTrigger(triggers)) {
				array.push({
					id: id,
					name: label,
					triggers: triggers
				});
			}
		});
		return array;
	};

	const buildRefTriggerSet = (triggerSet) => {
		const conditionTriggerArray = [];
		let objectEventTrigger = {};
		let objectTrigger = {};
		triggerSet.map(e => {
			switch (e.triggerName) {
				case 'TRGCHECKDEVICE':
					objectTrigger.deviceTrigger = e;
					break;
				case 'TRGSCROLL':
					objectEventTrigger.scrollTrigger = e;
					break;
				case 'TRGEXITINTENT':
					objectEventTrigger.exitTrigger = e;
					break;
				case 'TRGMARKPRESSURE':
					objectTrigger.numberOfTimeTrigger = e;
					break;
				case 'TRGTIMESPENTONPAGE':
					objectTrigger.delayTrigger = e;
					break;
				default:
					conditionTriggerArray.push(e);
			}
		});
		objectTrigger.condition = conditionTriggerArray;
		objectTrigger.eventTriggers = objectEventTrigger;
		return objectTrigger;
	};

	const buildSegmentsOnModuleMode = (refTriggers, favoriteRules) => {
		const loadSegmentsTriggersAvailable = (successCallback) =>
			getModuleFormats(successCallback, (err) => {
				console.log(err);
			});

		loadSegmentsTriggersAvailable(result => {
			const triggersModule = result.length > 0 ? result[0].triggers : [];
			const availableTriggerIds = refTriggers.reduce((acc, cur) => ({ ...acc, [cur.triggerName]: true }), {}) || {};

			const groupedModuleTriggers = groupBy(triggersModule, e => [e.Group.Id]);

			const filteredTriggers = Object.keys(groupedModuleTriggers).reduce(function (previous, key) {
				const o = groupedModuleTriggers[key];
				const hasNotAvailbleTrigger = o.find(x => !availableTriggerIds[x.Name]);
				return hasNotAvailbleTrigger ? previous : [...previous, ...o];
			}, []);

			setHasNotAvailableTriggerGroup(filteredTriggers.length !== triggersModule.length);

			const groupedTriggersModule = groupBy(filteredTriggers, e => [e.Group.Label, e.Group.ItriggersModuled, e.Group.Index]);

			const allSegments = buildTargetingRules(refTriggers, groupedTriggersModule);

			allSegments.map(segment => {
				if (!segment.triggers.deviceTrigger) {
					let deviceTrigger = transformRefTrigger(refTriggers.find(e => e.triggerName === 'TRGCHECKDEVICE'));
					deviceTrigger.Group = { Id: segment.id, Label: segment.name };
					deviceTrigger.ValueToCompare = ['mobile', 'tablet', 'desktop'];
					segment.triggers.deviceTrigger = deviceTrigger;
				}
			});
			if (mode === 'creating' && useModuleOnCreation) {
				const arraySegment = [];
				let prevState = [...targetingRules];
				allSegments.map((rule, i) => {
					if (i === 0) {
						let targetRule = { ...rule };
						targetRule.refSegment = JSON.parse(JSON.stringify(rule));
						targetRule.mostPopular = true;
						prevState.push(targetRule);

						let segment = { ...rule };
						segment.isUsed = true;
						segment.mostPopular = true;
						arraySegment.push(segment);
					}
					if (i > 0) {
						rule.isUsed = false;
						arraySegment.push(rule);
					}
				});
				setTargetingRules(prevState);
				setSegmentLibrary(arraySegment);
				setFavoriteRules(favoriteRules);
			} else {
				const groupedInitialSegment = groupBy(initialTriggers, e => [e.Group.Label, e.Group.Id, e.Group.Index]);
				let initialSegments = buildTargetingRules(refTriggers, groupedInitialSegment);

				allSegments.map((segment, i) => {
					const { isUsed, index } = isUsedSegment(segment.triggers, initialSegments);
					if (isUsed) {
						initialSegments[index].refSegment = JSON.parse(JSON.stringify(segment));
						if (i === 0) {
							initialSegments[index].mostPopular = true;
						}
						segment.isUsed = true;
					}
					if (i === 0) {
						segment.mostPopular = true;
					}
				});

				favoriteRules.map(favRule => {
					const { isUsed, index } = isUsedSegment(favRule.triggers, initialSegments);
					if (isUsed) {
						initialSegments[index].refFavorite = JSON.parse(JSON.stringify(favRule));
						favRule.isUsed = true;
					}
				});
				setTargetingRules(initialSegments);
				setSegmentLibrary(allSegments);
				setFavoriteRules(favoriteRules);
			}
			setIsLoading(false);
		});
	};

	const buildRules = (refTriggers, favoriteRules) => {
		const triggersWithGroup = initialTriggers.map(t => {
			const newId = uuidv4();
			if (!t.Group) {
				t.Group = {
					Label: '',
					Id: newId,
					Index: 0
				};
			}
			return t;
		});
		const groupedTriggers = groupBy(triggersWithGroup, e => [e.Group.Label, e.Group.Id, e.Group.Index]);

		const rulesUsed = buildTargetingRules(refTriggers, groupedTriggers);
	
		favoriteRules.map(favRule => {
			const { isUsed, index } = isUsedSegment(favRule.triggers, rulesUsed);
			if (isUsed) {
				rulesUsed[index].refFavorite = JSON.parse(JSON.stringify(favRule));
				favRule.isUsed = true;
			}
		});
		setTargetingRules(rulesUsed);
		setFavoriteRules(favoriteRules);
		setIsLoading(false);
	};

	useEffect(() => {
		getAccountTriggers(resAccountTriggers => {
			const builtRefTrigger = buildRefTriggerSet(resAccountTriggers);

			if (!isAnAdmin) {
				builtRefTrigger.condition = builtRefTrigger.condition.filter(t => {
					if (!t.config.saaS) return false;
					return true;
				});
				if (builtRefTrigger.condition.length < non_admin_trigger_nb) {
					builtRefTrigger.isPartialTriggerList = true;
				}
			}
			loadFavoriteRules(resAccountTriggers, favoriteRules => {
				if (!isModuleEditorMode) {
					buildRules(resAccountTriggers, favoriteRules);
				}
				if (isModuleEditorMode) {
					buildSegmentsOnModuleMode(resAccountTriggers, favoriteRules);
				}
				setRefTriggerSet(builtRefTrigger);
			});
		});
	}, []);


	const unBuildTargetingRules = () => {
		const arrayOfTrigger = [];
		targetingRules.map(rule => {
			for (const [key, value] of Object.entries(rule.triggers)) {
				if (key === 'condition') {
					value.map(e => arrayOfTrigger.push(e));
				} else {
					arrayOfTrigger.push(value);
				}
			}
		});
		return arrayOfTrigger;
	};


	const unBuildTriggers = (triggerSet) => {
		const arrayOfTrigger = [];
		for (const [key, value] of Object.entries(triggerSet)) {
			if (key === 'condition') {
				value.map(e => arrayOfTrigger.push(e));
			} else {
				arrayOfTrigger.push(value);
			}
		}
		const triggers = setUpSpecificationsForSaving(arrayOfTrigger);

		return triggers;
	};
	const i18nL = localStorage.getItem('i18nL');
	const language = i18nL && i18nL.includes('fr') ? 'fr' : 'en';

	const transformRefTrigger = (refTrigger) => {
		const triggerConfig = refTrigger.config;
		const triggerData = refTrigger.data[language];

		const operators = referenceDataTriggerOperands[refTrigger.triggerName];

		return {
			Name: refTrigger.triggerName,
			Label: triggerData.label,
			OperatorName: (operators && operators.length > 0 && operators[0].value) || '',
			ValueToCompare: triggerData.defaultValue,
			// DefaultLabel: refTrigger.defaultlabel,
			Type: triggerConfig.type,
			// Unit: refTrigger.unit || '',
			Categorie: triggerConfig.category,
			Tooltip: triggerData.tooltip,
			Separator: triggerConfig.separator,
			DataTriggerOperands: operators,
			ChoiceValues: triggerData.choiceValues
		};
	};

	useEffect(() => {
		//save
		if (targetingRules.length > 0) {
			const rawTrigger = unBuildTargetingRules();
			saveChangeOnTrigger(rawTrigger);
		} else {
			const id = uuidv4();
			const defaultTrigger = [
				{
					Name: 'TRGCHECKDEVICE',
					ValueToCompare: ['desktop', 'tablet', 'mobile'],
					Group: { Id: id, Label: 'Default' }
				},
				{
					Name: 'TRGMARKPRESSURE',
					ValueToCompare: -1,
					Group: { Id: id, Label: 'Default' }
				}
			];
			saveChangeOnTrigger(defaultTrigger);
		}
	}, [targetingRules]);


	const pushNewTargetingRule = (newTargetingRule) => {
		let prevState = [...targetingRules];
		prevState.push(newTargetingRule);
		setTargetingRules(prevState);
	};

	const addSegmentToTargetingRules = (segment) => {
		let prevSegmentLibrary = [...segmentLibrary];
		const index = prevSegmentLibrary.findIndex(e => e.id === segment.id);
		prevSegmentLibrary[index].isUsed = true;
		setSegmentLibrary(prevSegmentLibrary);

		let copy = JSON.parse(JSON.stringify(segment));
		delete copy.isUsed;

		copy.refSegment = JSON.parse(JSON.stringify(copy));
		const updatedSegment = addGroupForTriggers(copy);

		pushNewTargetingRule(updatedSegment);
	};

	const addFavoriteRuleToTargetingRules = (rule) => {
		let prevFavoriteRules = [...favoriteRules];
		const index = prevFavoriteRules.findIndex(e => e.id === rule.id);
		prevFavoriteRules[index].isUsed = true;
		setFavoriteRules(prevFavoriteRules);

		let copy = JSON.parse(JSON.stringify(rule));
		delete copy.isUsed;
		copy.refFavorite = JSON.parse(JSON.stringify(copy));
		const updatedRule = addGroupForTriggers(copy);

		pushNewTargetingRule(updatedRule);
	};

	const newTriggerSet = (ruleId, ruleName) => {
		let triggerSet = {};
		let deviceTrigger = transformRefTrigger(refTriggerSet.deviceTrigger);
		deviceTrigger.Group = { Id: ruleId, Label: ruleName };
		deviceTrigger.ValueToCompare = ['mobile', 'tablet', 'desktop'];

		let numberOfTimeTrigger = transformRefTrigger(refTriggerSet.numberOfTimeTrigger);
		numberOfTimeTrigger.Group = { Id: ruleId, Label: ruleName };
		numberOfTimeTrigger.ValueToCompare = '-1';

		triggerSet.deviceTrigger = deviceTrigger;
		triggerSet.numberOfTimeTrigger = numberOfTimeTrigger;
		triggerSet.condition = [];

		return triggerSet;
	};

	const getTargetingRuleById = (ruleId) => {
		const rule = targetingRules.find(e => e.id === ruleId);
		return { ...rule };
	};

	const editExistingRule = (newValue, changeId = false) => {
		let prevState = [...targetingRules];
		const index = prevState.findIndex(e => e.id === newValue.id);
		prevState[index] = newValue;
		if (changeId) {
			const newId = uuidv4();
			prevState[index].id = newId;
			for (const [key, value] of Object.entries(prevState[index].triggers)) {
				if (key === 'condition') {
					value.map(trigger => trigger.Group.Id = newId);
				} else {
					value.Group.Id = newId;
				}
			}
		}
		setTargetingRules(prevState);
	};

	const atLeastOneOfTheSegmentsIsUsed = () => {
		return segmentLibrary.map(e => e.isUsed).includes(true);
	};

	const updateRefSegment = (refSegment) => {
		let prevState = [...segmentLibrary];
		const index = prevState.findIndex(e => e.id === refSegment.id);
		prevState[index].isUsed = false;
		setSegmentLibrary(prevState);
	};

	const updateRefFavorite = (refFavorite) => {
		let prevState = [...favoriteRules];
		const index = prevState.findIndex(e => e.id === refFavorite.id);
		prevState[index].isUsed = false;
		setFavoriteRules(prevState);
	};

	const deleteTargetingRule = (ruleId) => {
		let prevState = [...targetingRules];
		const ruleToDelete = prevState.find(e => e.id === ruleId);

		if (isModuleEditorMode) {
			const { isUsed, index } = isUsedSegment(ruleToDelete.triggers, segmentLibrary);
			if (isUsed) {
				let prevStateSegmentLibrary = [...segmentLibrary];
				prevStateSegmentLibrary[index].isUsed = false;
				setSegmentLibrary(prevStateSegmentLibrary);
			}
		}

		if (ruleToDelete.refFavorite) {
			let prevFavoriteRules = [...favoriteRules];
			const index = prevFavoriteRules.findIndex(e => e.id === ruleToDelete.refFavorite.id);
			prevFavoriteRules[index].isUsed = false;
			setFavoriteRules(prevFavoriteRules);
		}
		const index = prevState.findIndex(e => e.id === ruleId);
		prevState.splice(index, 1);
		setTargetingRules(prevState);
	};

	const compareToBaseTrigger = (ruleTrigger) => {
		return ruleTrigger.deviceTrigger.ValueToCompare.length === 3 &&
			ruleTrigger.numberOfTimeTrigger.ValueToCompare === '-1' &&
			!ruleTrigger.delayTrigger &&
			ruleTrigger.condition.length === 0 &&
			!ruleTrigger.eventTrigger;
	};


	const saveTargetingRule = (rule) => {
		const triggers = unBuildTriggers(rule.triggers);
		const data = {
			// groupId: rule.id,
			groupName: rule.name,
			description: '',
			triggers: triggers.map(e =>
				({
					Name: e.Name,
					OperatorName: e.OperatorName,
					Value: e.ValueToCompare
				})
			)
		};
		saveRule(accountId, data, (savedRule) => {
			if (savedRule) {
				let prevTargetingRules = [...targetingRules];
				const index = prevTargetingRules.findIndex(e => e.id === rule.id);

				let copy = JSON.parse(JSON.stringify(rule));
				copy.id = savedRule.groupId;

				for (const [key, value] of Object.entries(copy.triggers)) {
					if (key === 'condition') {
						value.map(trigger => trigger.Group.Id = savedRule.groupId);
					} else {
						value.Group.Id = savedRule.groupId;
					}
				}

				prevTargetingRules[index] = copy;
				prevTargetingRules[index].refFavorite = copy;

				setTargetingRules(prevTargetingRules);

				let ruleToPush = JSON.parse(JSON.stringify(rule));
				ruleToPush.isUsed = true;
				ruleToPush.id = savedRule.groupId;
				ruleToPush.name = savedRule.groupName;

				let prevFavoriteRules = [...favoriteRules];
				prevFavoriteRules.push(ruleToPush);
				setFavoriteRules(prevFavoriteRules);
			}
		});
	};

	const changeNameOfFavoriteRule = (newName, favRule) => {
		const triggers = unBuildTriggers(favRule.triggers);
		const data = {
			groupName: newName,
			description: '',
			triggers: triggers.map(e =>
				({
					Name: e.Name,
					OperatorName: e.OperatorName,
					Value: e.ValueToCompare
				})
			)
		};
		editFavoriteRule(accountId, data, favRule.id, (response) => {
			if (response) {
				let prevFavoriteRules = [...favoriteRules];
				const index = prevFavoriteRules.findIndex(e => e.id === response.groupId);

				let ruleToPush = JSON.parse(JSON.stringify(favRule));
				ruleToPush.name = response.groupName;

				prevFavoriteRules[index] = ruleToPush;
				setFavoriteRules(prevFavoriteRules);
			}
		});
	};

	const loadFavoriteRules = (data, callback) => {
		const rules = [];
		getFavoriteRules(accountId, e => {
			e.map(x => {
				const rule = {
					// Description: x.description,
					name: x.groupName,
					id: x.groupId,
					segmentGroups:x.segmentGroups,

					triggers: buildContextAndConditionTrigger(data, x.groupId, x.groupName, buildTriggers(data, x.triggers.map(trigger => {
						return {
							Name: trigger.name,
							OperatorName: trigger.operatorName,
							ValueToCompare: trigger.value
						};
					})))
				};
				rules.push(rule);
			}
			);
			callback(rules);
		});
	};

	const deleteAFavoriteRule = (ruleId) => {
		deleteFavoriteRule(accountId, ruleId, e => {
			let prevState = [...favoriteRules];
			const index = prevState.findIndex(e => e.id === ruleId);
			prevState.splice(index, 1);
			setFavoriteRules(prevState);
		});
	};

	return (
		<TriggerContext.Provider
			value={{
				mode,
				trackingMode,
				handleTrackingModeChange,
				targetingRules,
				refTriggerSet,
				segmentLibrary,
				isModuleEditorMode,
				favoriteRules,
				accountId,
				setupAccountServices,
				productTagServices,
				isLoading,
				canSwitchToNoTracking,

				pushNewTargetingRule,
				getTargetingRuleById,
				editExistingRule,
				transformRefTrigger,
				newTriggerSet,
				deleteTargetingRule,
				addSegmentToTargetingRules,
				atLeastOneOfTheSegmentsIsUsed,
				updateRefSegment,
				updateRefFavorite,
				compareToBaseTrigger,
				saveTargetingRule,
				addFavoriteRuleToTargetingRules,
				deleteAFavoriteRule,
				changeNameOfFavoriteRule,
				hasNotAvailableTriggerGroup,
				language,
				isAbTestMode,
				isNativeApp
			}}
		>
			{children}
		</TriggerContext.Provider>
	);
};


export default CreateTriggerContextProvider;

export { useTriggerContext };