import React, { useState, useEffect } from 'react';
import { BrowserRouter, NavLink } from 'react-router-dom';
import Btn from '../../../../Components/Btn';
import Modal from '../../../../Components/Modal';
import DemotionList from './DemotionList';
import PromotedList from './PromotedList';
import ReplacementList from './ReplacementList';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider';
import {
	promoteOrDemoteId,
	replaceId,
	demoteId,
	promoteId
} from '../../Ranking/utils';
import SectionMessage from '../../../../Components/SectionMessage';
export default function ModalManualSort({
	activeTab,
	setActiveTab,
}) {
	const {
		addPromotedProductManualy,
		addDemotedProductManualy,
		addReplaceProductManualy,
		modalManualSorting,
		setModalManualIsOpen,
		hasDuplicateItem,
		sethasDuplicateItem,
	} = useRankingContext();
	function addPromotedProductManualyFunction(el) {
		addPromotedProductManualy(el);
		//setModalManualIsOpen(false);
	}
	function addDemotedProductManualyFunction(el) {
		addDemotedProductManualy(el);
		//setModalManualIsOpen(false);
	}
	function addReplaceProductTransform(el) {
		addReplaceProductManualy(el);
		setModalManualIsOpen(false);
	}
	function close(){
		sethasDuplicateItem();
		setModalManualIsOpen(false);
	}
	const [newElementToAdd, setNewElementToAdd] = useState('');


	let addFunction = () => {
		if (activeTab === promoteId) {
			addPromotedProductManualyFunction(newElementToAdd);
		}
		if (activeTab === demoteId) {
			addDemotedProductManualyFunction(newElementToAdd);
		}
		if (activeTab === replaceId) {
			addReplaceProductTransform(newElementToAdd);
		}
	};

	return (<>
		{modalManualSorting &&
			<Modal
				isOpen={modalManualSorting}
				width="700"
				onClose={() =>
					setModalManualIsOpen(false)
				}
			>
				<div className="modal_header">
					<div className="reporting_tabs">
						<ul className="tabs">
							<li>
								<a
									className={
										activeTab === promoteId ? 'active' : ''
									}
									onClick={(e) => {
										setActiveTab(promoteId);
									}}
								>
									Promoted
								</a>
							</li>
							<li>
								<a
									className={
										activeTab === demoteId ? 'active' : ''
									}
									onClick={(e) => {
										setActiveTab(demoteId);
									}}
								>
									Demotion
								</a>
							</li>
							<li>
								<a
									className={
										activeTab === replaceId ? 'active' : ''
									}
									onClick={(e) => {
										setActiveTab(replaceId);
									}}
								>
									Swap
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="modal_body">
					{hasDuplicateItem ?
						<SectionMessage
							title='There are duplicate items in the list'
							type="information"
						>
							<>Warning these products {hasDuplicateItem.map(x=> {
								return <span  key={x}><strong>{x} </strong> , </span>;
							})} were present multiple times, only one instance of each was added</>
						</SectionMessage>
						: 
						<>
							{activeTab === promoteId &&
								<PromotedList
									addPromotedProductManualy={(element) => setNewElementToAdd(element)}
								/>
							}
							{activeTab === demoteId &&
								<DemotionList
									addDemotedProductManualy={(element) => setNewElementToAdd(element)}
								/>
							}
							{activeTab === replaceId &&
								<ReplacementList
									addReplaceManualy={(element) => setNewElementToAdd(element)}
								/>
							}
						</>

					}
					
				</div>
				<div className="modal_footer">
					{!hasDuplicateItem &&
					<><Btn
						onClickFunction={() => setModalManualIsOpen(false)}
						message="Cancel"
						color="secondary"
						size="s"
						style="outline" />
					<Btn
						onClickFunction={(e) => addFunction()}
						message="Add"
						size="s"
						color="primary" /></>}
					
					{hasDuplicateItem && <>
						<Btn
							onClickFunction={(e) => close()}
							message="Ok"
							size="s"
							color="primary" />
					</>}
				</div>
			</Modal>
		}</>
	);
}
