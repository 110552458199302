/* eslint-disable react/react-in-jsx-scope */
const controlTypes = [
	{
		id: 1,
		name: 'text',
		icon: 'fas fa-i-cursor',
		multiple: false,
		color: 1,
		section: 'Text fields'
	},
	{
		id: 2,
		name: 'paragraph',
		icon: 'fas fa-align-left',
		multiple: false,
		color: 1,
		section: 'Text fields'
	},
	{
		id: 4,
		name: 'radio',
		icon: 'fas fa-dot-circle',
		multiple: true,
		color: 2,
		section: 'Multiple choices fields'
	},
	{
		id: 5,
		name: 'checkbox',
		icon: 'fas fa-check-square',
		multiple: true,
		color: 2,
		section: 'Multiple choices fields'
	},
	// {
	// 	id: 6,
	// 	name: 'options',
	// 	icon: 'fas fa-caret-square-down',
	// 	multiple: true,
	// 	color: 2,
	// 	section: 'Multiple choices fields'
	// },
	{
		id: 7,
		name: 'email',
		icon: 'fas fa-at',
		multiple: false,
		color: 1,
		section: 'Text fields'
	}, 
	// {
	// 	id: 8,
	// 	name: 'hidden',
	// 	icon: 'fas fa-eye-slash',
	// 	multiple: false,
	// 	color: 3,
	// 	section: 'Various fields'
	// },
	{
		id: 9,
		name: 'phone',
		icon: 'fas fa-phone',
		multiple: false,
		color: 1,
		section: 'Text fields'
	},
	{
		id: 10,
		name: 'reaction',
		icon: 'far fa-smile',
		multiple: false,
		rate: true,
		color: 3,
		section: 'Reaction and rating scale',
		wip: true,
	},
	{
		id: 11,
		name: 'Rating scale',
		icon: 'far fa-thumbs-up',
		multiple: false,
		rate: true,
		color: 3,
		section: 'Reaction and rating scale'
	}
];

export default controlTypes;