import React, { useEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import NavigationServices from '../../../Services/NavigationServices';
import ImpersonatingServices from '../../../Services/ImpersonatingServices';
import SystemServices from '../../../Services/SystemServices';
import UsersAdministrationServices from '../../../Services/UsersAdministrationServices';
import LabelService from '../../../Services/LabelsServices';
import formatMoment from '../../../Constants/FormatsMoments';
import './AccountAdministrationContainer.css';
import Btn from '../../../Components/Btn';
import Modal from './../../../Components/Modal';
import ModalHeader from './../../../Components/ModalHeader';
import ModalBody from './../../../Components/ModalBody';
import ModalFooter from './../../../Components/ModalFooter';
import Switch from '../../../Components/Switch';
import SearchBar from '../../../Components/SearchBar';
import SpinnerWheel from '../../../Components/SpinnerWheel';
import EmptyState from '../../../Components/EmptyState';
import {Article} from '../../../Components/Article/Article';
import LabelFilters from '../../Labels/LabelFilters';
import LabelsGlobalAccounts from './LabelGlobalAccounts';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import list_en from '../../../Translation/jsonfiles/en/onsite_list_view_en.json';
import list_fr from '../../../Translation/jsonfiles/fr/onsite_list_view_fr.json';
import AccountItem from './AccountItem';
import styles from './Accounts.module.css';

export default function AccountAdministrationContainer(props) {
	const propS = props.reactRender ? props.props : props;
	const systemServices = new SystemServices(propS.$rootScope);
	const impersonatingServices = new ImpersonatingServices(propS.$rootScope, propS.$routeParams);
	const impersonatedAccount = impersonatingServices.getImpersonatedAccount();
	const accountId = impersonatedAccount || propS.$rootScope.User.Account.Key;
	const role = propS.$rootScope.User && propS.$rootScope.User.Role;
	const usersAdministrationServices = new UsersAdministrationServices(propS.$http, propS.$routeParams);
	const labelServices = new LabelService(propS.$http, propS.$routeParams);
	const navigationServices = new NavigationServices(
		props.$http,
		props.$routeParams
	);
	const getAllAccounts = (includeBeyable, includeEmpty, callbackSuccess, callbackError) => usersAdministrationServices.getAllAccountBeyable(includeBeyable, includeEmpty, success => { callbackSuccess(success); }, error => { systemServices.showError('Error'); callbackError(error); });
	const isAdmin = props.$rootScope.$$childHead.IsAnAdmin();
	const isOrganizationAdmin = props.$rootScope.$$childHead.IsAnOrganizationAdmin();
	const canCreateAccount = isAdmin || isOrganizationAdmin;
	const accountIdForLabels = isAdmin ? '00000000-0000-0000-0000-000000000000' : accountId;

	const { formatDateToLocal } = formatMoment;
	const [allAccounts, setAllAccounts] = useState(false);
	const [isReloading, setIsReloading] = useState(true);
	const [valueInSearchBar, setValueInSearchBar] = useState('');
	const [dataFiltered, setDataFiltered] = useState();
	const [noData, setNoData] = useState();
	const [includeEmpty, setIncludeEmpty] = useState(false);
	const [includeBeyable, setIncludeBeyable] = useState(false);
	const [modalIsOppen, setModalIsOppen] = useState(false);
	const [orderItems, setOrderItems] = useState('name');
	const [order, setOrder] = useState('down');
	const [modalLabelsIsOpen, setModalLabelsIsOpen] = useState(false);
	const [modalLabelsAccountisOpen, setModalLabelsAccountisOpen] = useState(false);
	const [selectedLabelIds, setSelectedLabelIds] = useState([]);
	const [accountLabels, setAccountLabels] = useState([]);
	const [accountSelected, setaccountSelected] = useState();
	const [favoriteAccounts, setFavoriteAccounts] = useState([]);
	const i18nL= localStorage.getItem('i18nL');
	const newInstanceI18nextUser = i18next.createInstance();
	newInstanceI18nextUser.init({
		lng: 'en-EN',                             
		resources: {
			'en-US': {
				common: list_en            
			},
			'en-EN': {
				common: list_en            
			},
			'fr-FR': {
				common: list_fr
			},
		}},
	(err, t) => {
		if (err) return console.log('something went wrong loading', err);
		newInstanceI18nextUser.changeLanguage(JSON.parse(i18nL));
	});
	function getLabelsItem(dataToTransform) {
		labelServices.getLabelsItem(accountIdForLabels, 'account',
			newData => {
				const newDataTransform = dataToTransform.map(
					account =>
						(
							{
								...account,
								labels: newData.find(accounLabel => accounLabel.itemId === account.id) && newData.find(accounLabel => accounLabel.itemId === account.id).labels
								|| []
							})
				);
				setAllAccounts(newDataTransform);
				setIsReloading(false);
			}, err => {
				setIsReloading(false);
				systemServices.showError('An Error occured while fetching labels');
			});
	}
	function isAFavoriteAccount(account) {
		const isFav = favoriteAccounts.find(el => el.id === account.id);
		return isFav;
	}
	function addAcountToFavorites (account, bool) {
		navigationServices.postAccountFavorites(account.id,bool,null,success => {
			setFavoriteAccounts(success);
			props.$rootScope.$broadcast('event:favoritesAccounts',true);
		}, err => {
			console.log('error', err);
		});
	}
	useEffect(()=>{
		navigationServices.getAccountFavorites(success => {
			setFavoriteAccounts(success);
		}, err => {
			console.log('error', err);
		});
	},[]);
	useEffect(() => {
		if (!modalLabelsIsOpen) {
			setIsReloading(true);
			getAllAccounts(includeBeyable, includeEmpty, data => {
				const sortByName = (a, b) => {
					if (a === undefined) return;
					if (b === undefined) return;
					if (a.name === null) return 1;
					if (b.name === null) return -1;
					if (a.name < b.name) return -1;
					if (a.name > b.name) return 1;
					return 0;
				};
				const dataSort = data && data.sort(sortByName);
				// setAllAccounts(dataSort);
				getLabelsItem(dataSort);
			}, error => {
				setIsReloading(false);
				console.log('error', error);
			});
		}
	}, [modalLabelsIsOpen]);

	const fetchAccountsWithNewSearch = () => {
		setModalIsOppen(false);
		setIsReloading(true);
		setValueInSearchBar('');
		setDataFiltered();
		setOrderItems('name');
		setOrder('down');
		getAllAccounts(includeBeyable, includeEmpty, data => {
			const sortByName = (a, b) => {
				if (a === undefined) return;
				if (b === undefined) return;
				if (a.name === null) return 1;
				if (b.name === null) return -1;
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			};
			const dataSort = data && data.sort(sortByName);
			// setAllAccounts(dataSort);
			getLabelsItem(dataSort);
			// setIsReloading(false);
		}, error => {
			setIsReloading(false);
			console.log('error', error);
		});
	};

	// const search = (table, word) => {
	// 	setNoData();
	// 	if (word.length >= 0) {
	// 		console.time("Original Search");
	// 		setDataFiltered([]);
	//
	// 		const wordSearch = `.*${word.toUpperCase()}.*`;
	// 		const results = [];
	// 		for (let i = 0; i < table.length; i++) {
	// 			const line = table[i];
	// 			if (line.name !== null && line.name !== undefined && line.name.toUpperCase().match(wordSearch)) {
	// 				results.push(line);
	// 				continue;
	// 			}
	//
	// 			if (line.email !== null && line.email !== undefined && line.email.toUpperCase().match(wordSearch)) {
	// 				results.push(line);
	// 				continue;
	// 			}
	//
	// 			if (line.mainUserEmail !== null && line.mainUserEmail !== undefined && line.mainUserEmail.toUpperCase().match(wordSearch)) {
	// 				results.push(line);
	// 				continue;
	// 			}
	//
	// 			if (line.id !== null && line.id !== undefined && line.id.toUpperCase().match(wordSearch)) {
	// 				results.push(line);
	// 				continue;
	// 			}
	//
	// 			if (line.mainUserId !== null && line.mainUserId !== undefined && line.mainUserId.toUpperCase().match(wordSearch)) {
	// 				results.push(line);
	// 				continue;
	// 			}
	// 			console.timeEnd("Original Search");
	// 		}
	//
	// 		if (results.length === 0) {
	// 			setNoData('No account matches with this search');
	// 		} else {
	// 			setDataFiltered(results);
	// 		}
	// 	}
	// };
	const search = (table, word) => {
		setNoData();

		if (word.trim() === '') {
			setDataFiltered(table);
			return;
		}

		const wordSearch = new RegExp(word.trim().toUpperCase());

		const results = table.filter(line =>
			['name', 'email', 'mainUserEmail', 'id', 'mainUserId'].some(field =>
				line[field]?.toUpperCase().match(wordSearch)
			)
		);

		if (results.length === 0) {
			setNoData('No account matches with this search');
		} else {
			setDataFiltered(results);
		}
	};
	useEffect(() => {
		let searchTimeout = setTimeout(() => {
			if (!allAccounts) return;
			search(allAccounts, valueInSearchBar.trim());
		}, 300);
		
		return () => {
			clearTimeout(searchTimeout);
		};
	}, [valueInSearchBar]);
	
	const filterByLabels = (arrayToFilter) => {
		const uniqueItem = arrayToFilter.reduce((acc, curr) => {
			if (curr.labels) {
				const currentLabelsOfAccount = curr.labels.map(element => element.id);
				currentLabelsOfAccount.map(el => {
					if (selectedLabelIds.includes(el)) {
						acc = [...acc, curr];
					}
				});
			}
			return acc;
		}, []);
		return [...new Set(uniqueItem)];
	};

	let valueToMap;
	if (dataFiltered) {
		if (selectedLabelIds.length > 0) {
			valueToMap = filterByLabels(dataFiltered);
		} else {
			valueToMap = dataFiltered;
		}
	} else if (selectedLabelIds.length > 0) {
		valueToMap = filterByLabels(allAccounts);
	} else {
		valueToMap = allAccounts;
	}
	const orderBy = (id) => {
		let newOrder = order === 'down' ? 'up' : 'down';
		valueToMap.sort((a, b) => {
			if (newOrder === 'down') {
				if (a === undefined) return;
				if (b === undefined) return;
				if (a[id] === null) return 1;
				if (b[id] === null) return -1;
				if (a[id] < b[id]) return -1;
				if (a[id] > b[id]) return 1;
				return 0;
			} else {
				if (a === undefined) return;
				if (b === undefined) return;
				if (a[id] === null) return 1;
				if (b[id] === null) return -1;
				if (a[id] > b[id]) return -1;
				if (a[id] < b[id]) return 1;
				return 0;
			}
		});
		setOrder(newOrder);
		setOrderItems(id);
		return valueToMap;
	};
	const [clipBoardContain, setClipBoardContain] = useState();
	const copyToClipBoard = (value) => {
		navigator.clipboard.writeText(value);
		setClipBoardContain(value);
		setTimeout(() => {
			setClipBoardContain();
		}, 2000);
	};
	const OpenModalLabelAccount = (value, account) => {
		setModalLabelsIsOpen(value);
		setaccountSelected(account);
	};
	const onLabelSelected = (label) => {
		setSelectedLabelIds(ids =>
			ids && ids.includes(label.id) ?
				ids.filter(i => i !== label.id) :
				[...(ids || []), label.id]);
	};

	const loadLabels = () => {
		labelServices.getLabels(accountIdForLabels, 'account',
			data => {
				setAccountLabels(data);
			},
			err => {
				console.log('An Error occured while fetching labels: ' + err);
			}
		);
	};
	const closeTheModalOfLabels = () => {
		setModalLabelsAccountisOpen(false);
		loadLabels();
	};
	useEffect(() => {
		loadLabels();
	}, [modalLabelsAccountisOpen]);

	return (
		<I18nextProvider i18n={newInstanceI18nextUser}>
			<div className='page_full vscroll'>
				<div className='page_full_inner'>
					<section className='section no_bottom_pad section_primary'>
						<div className='h1'>My accounts</div>
					</section>
					<section className='section'>
						<Article size='l'>
							<div className="mb_20">
								<div className="flex">
									<div className="flex_item_full">
										<SearchBar
											placeholder="Search for an account"
											value={valueInSearchBar}
											onChange={(v) => setValueInSearchBar(v)}
											autoFocus={true}
											fullWidth={false}
											width={'l'}
										/>
									</div>
									<div className="flex_item_fix ml_10">
										{canCreateAccount ?
											<>
												<Btn
													icon="fas fa-sliders-h"
													onClickFunction={() => setModalIsOppen(true)}
													color="secondary"
													style="outline"
												/>
												<Btn
													message="New account"
													icon="fa fa-plus"
													onClickFunction={() => null}
													href="Admin/CreateAccount"
												/>
											</>
											:
											<Btn
												message="New account"
												icon="fa fa-plus"
												onClickFunction={() => null}
												disabled={true}
											/>
										}
									</div>
								</div>
								<div className="mt_15">
									<LabelFilters
										selectableLabels={accountLabels || []}
										selectedLabelIds={selectedLabelIds || []}
										onLabelSelected={l => onLabelSelected(l)}
										canEdit={true}
										isSupervisionPage={true}
										onEditRequested={() => setModalLabelsAccountisOpen(true)}
									/>
								</div>
							</div>
							{!isReloading && !noData &&
								<div className={'table_grid ' + styles.grid}>
									<div className={'table_row table_head_row ' + styles.row}>
										<div className='table_col'>
											<a
												onClick={() => { orderBy('name'); }}
												className="table_sort">
												Account
												({valueToMap && valueToMap.length})
												{orderItems === 'name' && order === 'up' &&
													<i className="fas fa-arrow-up"> </i>
												}
												{orderItems === 'name' && order === 'down' &&
													<i className="fas fa-arrow-down"></i>
												}
											</a>
										</div>
										<div className='table_col'>
											<a
												onClick={() => { orderBy('mainUserEmail'); }}
												className="table_sort">
												Email
												{orderItems === 'mainUserEmail' && order === 'up' &&
													<i className="fas fa-arrow-up" > </i>
												}
												{orderItems === 'mainUserEmail' && order === 'down' &&
													<i className="fas fa-arrow-down"> </i>
												}
											</a>
										</div>
										<div className='table_col'>
											<a
												onClick={() => { orderBy('creationDateUtc'); }}
												className="table_sort">
												Registration date
												{orderItems === 'creationDateUtc' && order === 'up' &&
													<i className="fas fa-arrow-up"> </i>
												}
												{orderItems === 'creationDateUtc' && order === 'down' &&
													<i className="fas fa-arrow-down"></i>
												}
											</a>
										</div>
										<div className='table_col'>
											Labels
										</div>
										<div className='table_col'></div>
									</div>
									{valueToMap && valueToMap.map(account =>
										<AccountItem
											key={account.id}
											account={account}
											role={role}
											isFav={isAFavoriteAccount(account)}
											addAcountToFavorites={addAcountToFavorites}
											labelServices={labelServices}
											accountIdForLabels={accountIdForLabels}
											systemServices={systemServices}
										/>
									)}
								</div>
							}
							{!isReloading && noData &&
								<EmptyState
									icon="fas fa-search"
									title="No matching account"
									text='Please try with another search'
									textSize='l'
									verticalSize='l'
								/>
							}
							{isReloading &&
								<SpinnerWheel wheelSize='l' />
							}
						</Article>
					</section>
				</div>
			</div>

			<Modal
				isOpen={modalIsOppen}
				width="380"
				onClose={() => { setModalIsOppen(false); }}
			>
				<ModalHeader
					title="Configure account list"
				/>
				<ModalBody>
					<div className="mb_40">
						<div className="form_line has_border">
							<Switch
								name="includeBeyable"
								checked={includeBeyable ? true : false}
								value={includeBeyable ? true : false}
								onChange={(e) => setIncludeBeyable(!includeBeyable)}
								position="right"
								verticalSize="l"
							>
							Include BEYABLE accounts
							</Switch>
						</div>
						<div className="form_line has_border">
							<Switch
								name="includeEmpty"
								checked={includeEmpty ? true : false}
								value={includeEmpty ? true : false}
								onChange={(e) => setIncludeEmpty(!includeEmpty)}
								position="right"
								verticalSize="l"
							>
							Include empty accounts
							</Switch>
						</div>
					</div>
				</ModalBody>
				<ModalFooter
					secondaryAction={
						<Btn
							onClick={() => { setModalIsOppen(false); }}
							message="Cancel"
							color="secondary"
							style="ghost"
						/>
					}
					primaryAction={
						<Btn
							onClick={() => fetchAccountsWithNewSearch()}
							message="Confirm"
						/>
					}
				/>
			</Modal>
			{modalLabelsAccountisOpen &&
				<LabelsGlobalAccounts
					labelService={labelServices}
					accountId={accountIdForLabels}
					systemServices={systemServices}
					modalLabelsAccountisOppen={modalLabelsAccountisOpen}
					setmodalLabelsAccountisOppen={(e) => closeTheModalOfLabels(e)}
					objectType={'account'}
				/>
			}
		</I18nextProvider>
	);
}
angular
	.module('beyableSaasApp.AdminHome', [])
	.component('accountadministration', react2angular(AccountAdministrationContainer, [], ['$http', '$scope', '$rootScope', '$routeParams']));
