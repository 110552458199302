import React,{useEffect, useState} from 'react';
import { getFieldByTypeOfMappingGenericUri} from '../utils';
import {useProductFeedEditionContext} from '../context/ProductFeedEditionContext';
import SelectCustom from '../../../Components/SelectCustom';
import InputCustom from '../../../Components/InputCustom';
import Btn from '../../../Components/Btn';
import {FeedCardHeader} from './FeedCardHeader';
import XmlSettingsContainer from '../Feed/XmlSettingsContainer';
import { v4 as uuidv4 } from 'uuid';
const listOfFormats = [
	{label : 'Csv',
		value : 'Csv'
	},
	{label : 'Xml',
		value : 'Xml'
	},
	{label : 'Json',
		value : 'Json'
	},
];

export default function SourceSettingsScreen() {
	const {
		productFeedObject,
		handleGoingToNextPartOfMapping,
		handleChangesTypeFormatField,
		handleChangesTypeFormatFieldObj,
		HandleChangeTypeFormat,
		handleOpenClosePanelRawfeed,
		rawFeed,
		getRawFeed
	} = useProductFeedEditionContext();
	const [listFieldOfFormatCOnfig, setlistFieldOfFormatCOnfig] = useState(<></>);
	
	function onChangeFilterValue(value){
		HandleChangeTypeFormat(value);
	}
	
	useEffect(()=>{
		getRawFeed();
		const skeletonObjectType = getFieldByTypeOfMappingGenericUri(productFeedObject?.format?.type);
	
		function tradSkeletonFunction(){
			return skeletonObjectType && skeletonObjectType.map((field,id) => {
				function getValueOfField(){
					const specialsCaracters = [
						{key:'\n', value:'\\n' },
						{key:'\r\n', value:'\\r\\n' },
						{key:'\t', value:'\\t' },
						{key:'\r', value:'\\r' },
						{key:'\f', value:'\\f' },
						{key:'\v', value:'\\v' },
					];
					if(specialsCaracters.find(el => el.key === productFeedObject.format[field.key])){
						return  specialsCaracters.find(el => el.key === productFeedObject.format[field.key]).value;
					}else{
						return productFeedObject.format[field.key];
					}
				}
	
				if(field.type === 'text'){
					return <div key={id}>
						<InputCustom
							value={getValueOfField()}
							type={'text'}
							label={field.label}
							name={field.name}
							placeholder=""
							onChange={handleChangesTypeFormatField}
							fullWidth={true}
						/>
					</div>;
				}
				else if(field.type === 'object'){
					return <div key={id}>
						<InputCustom
							value={productFeedObject?.format[field?.key][field?.objectName] || '' }
							type={'textarea'}
							label={field.objectLabel}
							name={field.objectName}
							placeholder=""
							onChange={(e)=>handleChangesTypeFormatFieldObj(field.key,field.objectName ,e.target.value)}
							fullWidth={true}
						/>
					</div>;
				}else if(field.type === 'namespaces'){
					return <div key={id+uuidv4()}><XmlSettingsContainer namespaces={productFeedObject.format[field.key]}
					/></div>;
				}
			});
		}

		setlistFieldOfFormatCOnfig(tradSkeletonFunction);
	},[productFeedObject]);

	return (
		<div>
			<FeedCardHeader />
			<Btn
				onClick={(e) =>handleOpenClosePanelRawfeed(true)}
				message="Raw feed"
				color="grey"
				icon='fas fa-eye'
				style='link'
				size='xs'
				disabled={!rawFeed}
			/>
			<SelectCustom
				optionsList={listOfFormats}
				name="type"
				onChange={(e) => onChangeFilterValue(e.target.value)}
				defaultValueDisabled={{
					value: 'choose',
					label: 'Choose a format',
					isDisabled: true,
				}}
				label="Feed Type"
				value={productFeedObject?.format?.type || 'choose'}/>
			{listFieldOfFormatCOnfig}
			<div className='flex flex_justify_end mb_5 mt_5'>
				<Btn
					onClickFunction={() => handleGoingToNextPartOfMapping()}
					size="l"
					message="Start mapping"
					color="primary" />
			</div>
		</div>
	);
}
