// TODO : add links and/or buttons

import React, { useState } from 'react';
import IconBtn from './IconBtn';
import styles from './SectionMessage.module.css';

export default function SectionMessage({
	children,
	links,
	type = 'information',
	title = '',
	text = '',
	appearance = 'background', // background OR outline OR ghost OR background_outline
	icon = 'default',
	iconSize = 'm',
	showIcon = true,
	marginSize='m',
	verticalSize='m',
	fontSize='m',
	noMargin = false,
	color = 'inheritedFromType',
	canClose = false,
	closeTooltip = '',
	storageKey = '',
	className = '',
	flag
}) {

	const [isClosed, setIsClosed] = useState(false);

	function getIsClosed() {
		if (isClosed) return true;
		if (canClose && storageKey) {
			return localStorage.getItem('message_' + storageKey) === 'closed';
		}
		return false;
	}

	function handleClose() {
		if (storageKey) {
			localStorage.setItem('message_' + storageKey, 'closed');
		}
		setIsClosed(true);
	}

	if (canClose && storageKey) {
		const tmpIsClosed = getIsClosed();
		if (tmpIsClosed) {
			return <></>;
		}
	}

	const iconByType = {
		'information': 'fas fa-info-circle',
		'success': 'fas fa-check-circle',
		'warning': 'fas fa-exclamation-triangle',
		'alert': 'fas fa-times-circle',
	};

	if (!(type in iconByType)) {
		type = 'information';
	}
    
	if (showIcon && icon == 'default') {
		icon = iconByType[type];
	}
    
	const wrapperStyles = [styles.wrapper];
	wrapperStyles.push(styles['wrapper_appearance_'+appearance]);
	wrapperStyles.push(styles['wrapper_type_'+type]);

	if (noMargin) {
		wrapperStyles.push(styles['wrapper_no_margin']);
	} else {
		wrapperStyles.push(styles['wrapper_margin_'+marginSize]);
	}

	if (verticalSize) {
		wrapperStyles.push(styles['wrapper_size_'+verticalSize]);
	}

	if (fontSize) {
		wrapperStyles.push(styles['wrapper_font_size_'+fontSize]);
	}

	if (iconSize) {
		wrapperStyles.push(styles['wrapper_icon_size_' + iconSize]);
	}

	if (color && color != 'inheritedFromType' ) {
		wrapperStyles.push(styles['wrapper_color_'+color]);
	}

	if (className) {
		wrapperStyles.push(className);
	}

	return (
		<div className={wrapperStyles.join(' ')}>
			{showIcon && 
				<div className={styles.col_icon}>
					<i className={icon + ' fa-fw'}></i>
				</div>
			}
			<div className={styles.col_content}>
				{title && 
                    <div className={styles.title}>{title}</div>
				}
				{text && 
                    <div className={title ? styles.text : styles.text_l}>{text}</div>
				}
				{children &&
                     <div className={title ? styles.text : styles.text_l}>{children}</div>
				}
				{links && 
                    <div className={styles.links_wrapper}>{links}</div>
				}
			</div>
			{flag &&
				<div className={styles.flag}>{flag}</div>
			}
			{canClose &&
				<div className={styles.col_close}>
					<IconBtn
						icon="fas fa-times"
						tooltip={closeTooltip}
						onClick={handleClose}
					/>
				</div>
			}
		</div>
	);
}
