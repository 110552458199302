import React, { useState } from 'react';

import Btn from './../../Components/Btn';
import PopinConfirm from './../../Components/PopinConfirm';
import {useTranslation} from 'react-i18next';
export default function PannelIpAdresses({ showPanelIpAddress, closeThePannel, newIpToCreate, setNewIpToCreate, addNewIpAdresses, existIp, putAdressesIp, deleteTheAdresseIp, currentIP }) {
	const [t, i18n] = useTranslation('common');
	const [showPopinDeleteIp, setShowPopinDeleteIp] = useState(false);
	const handleChangeIpAdresse = (e) => {
		let newObj = { ...newIpToCreate };
		newObj[e.target.name] = e.target.value;
		setNewIpToCreate(newObj);
	};
	const handleChangeIpAdresseByCopy = (e) => {
		let newObj = { ...newIpToCreate };
		newObj.IpAddress = e;
		setNewIpToCreate(newObj);
	};
	const cancel = () => {
		closeThePannel();
		setNewIpToCreate({
			IpAddress: '',
			Label: '',
			IsDisabled: false
		});
	};
	return (
		<div>
			{
				showPopinDeleteIp &&
                <PopinConfirm
                	cancel={() => setShowPopinDeleteIp(false)}
                	title={`${t('doYouReally')} ${newIpToCreate.Label} (${newIpToCreate.IpAddress}) ? `}
                >
                	<Btn
                		onClickFunction={(e) => { setShowPopinDeleteIp(false); }}
                		message={t('settings.Cancel')}
                		color="primary"
                	/>
                	<Btn
                		onClickFunction={(e) => { setShowPopinDeleteIp(false); deleteTheAdresseIp(); }}
                		message={t('settings.Delete')}
                		color="alert"
                	/>

                </PopinConfirm>
			}
			<div className={showPanelIpAddress ? 'overlay_popin showed' : 'overlay_popin'}></div>
			<div className={showPanelIpAddress ? 'panel-wrapper showed' : 'panel-wrapper'}>
				<div className="panel-inner">
					<div className="panel-header">
						<p className="title">{existIp === true ? 'Edit' : 'Add'}   {t('settings.addIpShort')}</p>
						<p className="subtitle">{t('settings.YouCanAddAfull')}</p>
					</div>
					<div className="panel-content">
						<label htmlFor="ip-name">
							<p className="input-name">{t('settings.Name')}</p>
							<input type="text" id="ip-name" name="Label" onChange={handleChangeIpAdresse} value={newIpToCreate.Label ? newIpToCreate.Label : ''} />
						</label>
						<label htmlFor="ip-address">
							<p className="input-name">{t('settings.IPaddress')}</p>
							<input type="text" id="ip-address" name="IpAddress" onChange={handleChangeIpAdresse} value={newIpToCreate.IpAddress ? newIpToCreate.IpAddress : ''} />
						</label>
						<div className="current-ip">
							<p>{t('settings.yourCurrentIp')}</p>
							<button onClick={(e) => handleChangeIpAdresseByCopy(currentIP)}>{currentIP}< i className="fas fa-level-up-alt"></i></button>
						</div>
					</div>
					<div className="panel-btn">
						<button className="btn-cancel" onClick={cancel}>{t('settings.Cancel')}</button>
						<button className="btn-confirm" onClick={existIp !== true ? addNewIpAdresses : putAdressesIp}>{t('settings.Confirm')}</button>
					</div>
				</div>
				{
					existIp === true &&
                    <button className="btn-delete" ng-show="EditIpAddressID != null" onClick={() => setShowPopinDeleteIp(true)}><i className="fas fa-trash-alt"></i> {t('settings.Delete')}</button>
				}
			</div>
		</div >
	);
}
