import React from 'react';
import styles from './Flag.module.css';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import {IFlag} from './types';

const icons = {
	'ai': <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor"><defs><clipPath id="b"><rect width="12" height="12"/></clipPath></defs><g id="a" clipPath="url(#b)"><g transform="translate(-101.065 -290.294)"><path d="M3.113.961a.5.5,0,0,1,.9,0l.612,1.3a.5.5,0,0,0,.24.24l1.3.612a.5.5,0,0,1,0,.9l-1.3.612a.5.5,0,0,0-.24.24l-.612,1.3a.5.5,0,0,1-.9,0L2.5,4.869a.5.5,0,0,0-.24-.24l-1.3-.612a.5.5,0,0,1,0-.9l1.3-.612a.5.5,0,0,0,.24-.24Z" transform="translate(101.5 292.729)" /><path d="M2.113.961a.5.5,0,0,1,.9,0l.292.62a.5.5,0,0,0,.24.24l.62.292a.5.5,0,0,1,0,.9l-.62.292a.5.5,0,0,0-.24.24l-.292.62a.5.5,0,0,1-.9,0l-.292-.62a.5.5,0,0,0-.24-.24l-.62-.292a.5.5,0,0,1,0-.9l.62-.292a.5.5,0,0,0,.24-.24Z" transform="translate(107.5 290.729)" /><path d="M2.113.961a.5.5,0,0,1,.9,0l.292.62a.5.5,0,0,0,.24.24l.62.292a.5.5,0,0,1,0,.9l-.62.292a.5.5,0,0,0-.24.24l-.292.62a.5.5,0,0,1-.9,0l-.292-.62a.5.5,0,0,0-.24-.24l-.62-.292a.5.5,0,0,1,0-.9l.62-.292a.5.5,0,0,0,.24-.24Z" transform="translate(107.5 296.729)" /></g></g></svg>,
};

function Flag({
	icon,
	text,
	tooltip,
	color = 'green',
	hasBorder = false,
	className
}: IFlag): JSX.Element {

	const tooltipID = uuidv4();
	const cssClass:any = [styles.outer];

	const colorClass:any = {
		green: styles.outer_color_green,
		success_reverse: styles.outer_color_success_reverse,
		purple_reverse: styles.outer_color_purple_reverse,
		electric_green: styles.outer_color_electric_green,
	};

	const getIcon = (icon) => {
		if (icon && icon in icons) {
			return icons[icon];
		}
		return <></>;
	};

	if (className) {
		cssClass.push(className);
	}

	if (hasBorder) {
		cssClass.push(styles.outer_has_border);
	}

	if (color in colorClass) {
		cssClass.push(colorClass[color]);
	}

	// if (color === 'green') {
	// 	cssClass.push(styles.outer_color_green);
	// }

	// if (color === 'success_reverse') {
	// 	cssClass.push(styles.outer_color_success_reverse);
	// }

	// if (color === 'purple_reverse') {
	// 	cssClass.push(styles.outer_color_purple_reverse);
	// }

	// if (color === 'electric_green') {
	// 	cssClass.push(styles.outer_color_electric_green);
	// }

	return (
		<>
			{tooltip &&
				<ReactTooltip
					id={tooltipID}
					backgroundColor="black"
					effect="solid"
					place="bottom"
					globalEventOff="mouseup"
					delayShow={600}
				/>
			}
			<span className={cssClass.join(' ')}
				data-tip={tooltip}
				data-for={tooltipID}>
				{icon &&
					<span className={styles.icon}>
						{getIcon(icon)}
					</span>
				}
				{text &&
					<span className={styles.text}>{text}</span>
				}
			</span>
		</>
	);
}

export {
	Flag
};
