/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { getLocalisedValue } from '../Utils/LocalisationUtils';
import { propertyAccessor } from '../Utils/PropertiesOperations';
import Btn from '../../../Components/Btn';
import Checkbox from '../../../Components/Checkbox';
import InputCustom from '../../../Components/InputCustom';
import SelectCustom from '../../../Components/SelectCustom';
import SelectDropdown from '../../../Components/SelectDropdown';
import NumericUpDown from '../../../Components/NumericUpDown';
import ColorPicker from '../../../Components/ColorPicker';
import RangeSlider from '../../../Components/RangeSlider';
import TabBar from '../../../Components/TabBar';
import TabItem from '../../../Components/TabItem';
import ImageInputContainer from '../Components/ImageInputContainer';
import DateEditor from '../Components/DateEditor';
import ProductReferences from '../Components/ProductReferencesEditor';
import HttpConnector from '../Components/HttpConnector';
import VideoEditor from '../Components/VideoEditor';
import RecommendationEditor from '../Components/RecommendationEditor';
import FormSurveyEditor from '../Components/FormSurveyEditor';
import PromoCodeEditor from '../Components/PromoCodeEditor';
import DataEditor from '../Components/DataEditor';
import RichText from '../Components/RichText';
import VideoBeyable from '../Components/VideoBeyable';
import FontSelector from '../Components/FontSelector';
import IconSelector from '../Components/IconSelector';
import { useWysiwygEditorContext } from '../context/WysiwygEditorContext';
import { useTranslation } from 'react-i18next';

export default function PropertyEditor({ property, data, callBack, propertyContainer, collectionItem = {}, context }) {

	const {
		features,
		getCurrentVariation,
		handleSetPromoCode,
		loadHttpConnectorConfigurationCallback,
	} = context;

	const {
		setupAccountServices,
		accountId,
		$rootScope,
		$routeParams,
		$http,
		authServices,
		$timeout,
	} = useWysiwygEditorContext();

	const [t,i18n] = useTranslation('common');
	
	const { getDependentPropertyValue } = propertyAccessor();
	const currentVariation = getCurrentVariation() || {};

	const [isDisplayed, setIsDisplayed] = useState(!property.Dependency);
	const [inputValue, setInputValue] = useState(data);

	let field;
	let label;
	let needFieldOuterHTML = true;

	let FriendlyName = getLocalisedValue(property.FriendlyName);
	const Description = getLocalisedValue(property.Description);
	const SubDescription = getLocalisedValue(property.SubDescription);
	const Tooltip = getLocalisedValue(property.Tooltip);

	const handleChangeInputValue = (value) => {
		setInputValue(value);
		callBack(value);
	};

	useEffect(() => {
		if (property.Dependency) {
			const dependentPropertyValue = getDependentPropertyValue(currentVariation.Properties, property.Dependency.Property, collectionItem);
			if (dependentPropertyValue.found) {
				const expectedValues = property.Dependency.Value && Array.isArray(property.Dependency.Value)
					? property.Dependency.Value
					: [property.Dependency.Value];
				const dependencyMatch = expectedValues.some(v => v == dependentPropertyValue.value);
				setIsDisplayed(dependencyMatch);
			} else {
				setIsDisplayed(false);
			}
		}
	}, [currentVariation]);

	if(property.Editor.Type === 'Image'){
		FriendlyName = FriendlyName || t('img.upload');
	}
	if (FriendlyName) {
		label = (
			<>
				{FriendlyName}
				{property.Device &&
					<span className="format_param_label_device"><i className={property.Device == 'desktop' ? 'fas fa-desktop' : 'fas fa-mobile-alt'}></i></span>
				}
			</>
		);
	}

	switch (property.Editor.Type) {
		case 'ColorPicker':
			if (!property.Editor.Width && property.Editor.Float) {
				property.Editor.Width = 's'; // On garde pour les formats déjà créés
			}

			field = (
				<ColorPicker
					label={label}
					description={Description}
					value={inputValue || '#ff5598'}
					fullWidth={property.Editor.Width ? true : false}
					size="s"
					onChange={(e) => handleChangeInputValue(e)}
				/>
			);
			break;

		case 'Text':
			field = (
				<InputCustom
					label={label}
					labelTooltip={Tooltip}
					description={Description}
					descriptionBottom={SubDescription}
					value={inputValue}
					fullWidth={true}
					onChange={(e) => handleChangeInputValue(e.target.value)}
				/>
			);
			break;

		case 'Number':
			field = (
				<NumericUpDown
					type="number"
					label={label}
					labelTooltip={Tooltip}
					description={Description}
					descriptionBottom={SubDescription}
					value={inputValue}
					unit={property.Editor.Unit}
					fullWidth={true}
					min={property.Editor.Min || 0}
					max={property.Editor.Max || 1000}
					onChange={(e) => handleChangeInputValue(e.target.value)}
					canEdit={true}
				/>
			);
			break;

		case 'Textarea':
			field = (
				<InputCustom
					label={label}
					labelTooltip={Tooltip}
					description={Description}
					descriptionBottom={SubDescription}
					value={inputValue}
					type={'textarea'}
					fullWidth={true}
					rows={property.Editor.Rows || 4}
					onChange={(e) => handleChangeInputValue(e.target.value)}
				/>
			);
			break;

		case 'Data':
			field = (
				<DataEditor
					label={label}
					property={property}
					data={inputValue}
					onChange={handleChangeInputValue}>
				</DataEditor>				
			);
			break;

		case 'Boolean':
			field = (
				<Checkbox
					onChange={(e) => handleChangeInputValue(e.target.checked)}
					checked={inputValue || false}
					label={label}
					tooltip={Tooltip}
				/>
			);
			break;

		case 'ButtonGroup':
			field = (
				<div className="form_block">
					<label className="form_block_label">{label}</label>
					{Description &&
						<div className="form_block_description">{Description}</div>
					}
					<div className="btn_switch">
						{property.Editor.AvailableValues && property.Editor.AvailableValues.map((a) =>
							<Btn
								key={a.Value}
								message={getLocalisedValue(a.Label)}
								style="outline"
								color="secondary"
								size="s"
								className={a.Value == data ? 'active' : ''}
								onClick={() => handleChangeInputValue(a.Value)}
							/>
						)}
					</div>
				</div>
			);
			break;

		case 'Tabs':
			field = (
				<TabBar>
					{property.Editor.AvailableValues && property.Editor.AvailableValues.map((a) =>
						<TabItem
							key={a.Value}
							message={getLocalisedValue(a.Label)}
							selected={a.Value == data}
							onClick={() => handleChangeInputValue(a.Value)}
						/>
					)}
				</TabBar>
			);
			break;

		case 'Select':
		// eslint-disable-next-line no-case-declarations
			let optionsList;
			if (property.Editor.AvailableValues) {
				optionsList = property.Editor.AvailableValues.map(function (i) {
					return {
						label: getLocalisedValue(i.Label),
						value: i.Value
					};
				});
			}
			field = (
				<SelectCustom
					label={label}
					description={Description}
					optionsList={optionsList}
					value={inputValue}
					fullWidth={true}
					onChange={(e) => handleChangeInputValue(e.target.value)}
				/>
			);
			break;

		case 'CountDownStyle':
			let countDownOptionsList = [
				{
					value: 'inner_border',
					label: 'Inner border',
					image: '/Assets/countdown_inner_border.svg'
				},
				{
					value: 'outer_border',
					label: 'Outer border',
					image: '/Assets/countdown_outer_border.svg'
				},
				{
					value: 'light',
					label: 'Light',
					image: '/Assets/countdown_light.svg'
				},
				{
					value: 'inline',
					label: 'Inline',
					image: '/Assets/countdown_inline.svg'
				}
			];
			field = (
				<SelectDropdown
					label={label}
					description={Description}
					optionsList={countDownOptionsList}
					value={inputValue}
					onChange={(v) => handleChangeInputValue(v)}
				/>
			);
			break;

		case 'Icon':
			field = (
				<IconSelector
					label={label}
					data={data}
					onChange={callBack}
					property={property}
					fullWidth={property.Editor.Width ? true : false}
				/>
			);

			break;

		case 'RichText':
			field = (
				<>
					<div className="form_block">
						<label className="form_block_label">{label}</label>
						{Description &&
							<div className="form_block_description">{Description}</div>
						}
						<RichText
							property={property}
							data={inputValue}
							onChange={(value) => handleChangeInputValue(value)}
						/>
					</div>
				</>
			);
			break;


		case 'DatePicker':
			needFieldOuterHTML = false;
			field = (
				<DateEditor
					property={property}
					data={data}
					callBack={callBack}
					propertyContainer={propertyContainer}
					label={label}
				/>
			);
			break;

		case 'CountDown':
			needFieldOuterHTML = false;
			field = (
				<DateEditor
					property={property}
					data={data}
					isCountDown={true}
					callBack={d => {
						d.isCountDown = true;
						callBack(d);
					}}
					propertyContainer={propertyContainer}
					label={label}
				/>
			);
			break;

		case 'Image':
			field = (
				<ImageInputContainer
					label={label}
					defaultImageUrl={inputValue}
					property={property}
					setImageCallBack={handleChangeInputValue}
					removeImageCallBack={() => callBack('')}
				/>
			);
			break;

		case 'Range':
			field = (
				<RangeSlider
					label={label}
					description={Description}
					value={inputValue}
					min={property.Editor.Min}
					max={property.Editor.Max}
					step={property.Editor.Step}
					onChange={(e) => handleChangeInputValue(e.target.value)}
					inputUnit={property.Editor.Unit}
					hasInput={true}
				/>
			);
			break;

		case 'Separator':
			field = (
				<div className="format_param_sep"></div>
			);
			break;

		case 'ProductReferences':
			field = (
				<ProductReferences
					label={label}
					description={property.description}
					tooltip={property.tooltip}
					inputValue={inputValue}
					propertyContainer={propertyContainer}
					max={property.Editor.Max}
					accountId={accountId}
					setupAccountServices={setupAccountServices}
					handleChangeInputValue={handleChangeInputValue}
				/>
			);
			break;

		case 'HttpConnector':
			const httpConnectorIsActive = features.HttpConnector && features.HttpConnector[0].isActive;
			field = (
				<HttpConnector
					isActivated={httpConnectorIsActive}
					label={label}
					feature={property.Editor.Feature || 'FormsAndSurveys'}
					campaignId={$routeParams.key}
					httpConnectorData={features.HttpConnector}
					loadConfigurationCallback={loadHttpConnectorConfigurationCallback}
				/>
			);
			needFieldOuterHTML = false;
			break;

		case 'Video':
			const videoType = property.Editor.VideoType;
			switch (videoType) {
				case 'YOUTUBE':
					field = (
						<VideoEditor
							label={label}
							data={data}
							property={property}
							propertyContainer={propertyContainer}
							accountId={accountId}
							onChange={callBack}
						/>
					);
					break;
				case 'File':
					field = (
						<VideoBeyable
							label={label}
							data={data}
							property={property}
							propertyContainer={propertyContainer}
							accountId={accountId}
							onChange={(e)=>{
								callBack(e);}}
							$rootScope={$rootScope}
							$routeParams={$routeParams}
							$http={$http}
							authServices={authServices}
							$timeout={$timeout}
						/>
					);
					break;
			}
			break;

		case 'Survey':
			field = property.Name === 'FormSurveyCustom' ? (
				<FormSurveyEditor 
					label={label}
					data={data}
					onChange={callBack}
					getCurrentVariation={getCurrentVariation}
				/>
			) : console.log('Property Name should be FormSurveyCustom') && null;
			needFieldOuterHTML = false;
			break;

		case 'ProductRecommendations':
			field = (
				<RecommendationEditor
					label={label}
					data={data}
					onChange={callBack}
				/>
			);
			needFieldOuterHTML = false;
			break;

		case 'PromoCode':
			field = (
				<PromoCodeEditor
					label={label}
					data={data}
					onChange={callBack}
					getCurrentVariation={getCurrentVariation}
					handleSetPromoCode={handleSetPromoCode}
				/>
			);
			break;

		case 'Font':
			field = (
				<FontSelector
					label={label}
					data={data}
					onChange={callBack}
					property={property}
					fullWidth={property.Editor.Width ? true : false}
				/>
			);
			break;

		default:
			field = (null);
			break;
	}

	if (!field) {
		return null;
	}

	const cssClass = ['format_param'];
	if (property.Editor.Width) {
		// Width = '' | 'l' | 'm' | 's'
		cssClass.push('w_' + property.Editor.Width);
	}

	if (isDisplayed && !needFieldOuterHTML) return field;

	return (
		<>
			{isDisplayed &&
				<>
					<div className={cssClass.join(' ')}>
						<div className="format_param_inner">
							{field}
						</div>
					</div>
					{!property.Editor.Float &&
						<div className="format_param_break"></div>
					}
				</>
			}
		</>
	);
}

PropertyEditor.defaultProps;