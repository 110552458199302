import React, { useState, Fragment, useRef, useEffect } from 'react'

export default function ImageOrFallback(
	{
		imageProps,
		src,
		fallbackSrc = `https://via.placeholder.com/295x295?text=Image+Not+Available`,
	}
) {
	const [image, setImage] = useState({
		src: src,
		fallbackSrc: fallbackSrc,
		error: false
	});


	const onImageError = () => {
		setImage(x => ({ ...x, error: true }));
	};

	let imgSrc = !image.error ? image.src : image.fallbackSrc;

	return (
		<>
			<div className="image-container">
				<img
					{...imageProps}
					src={imgSrc}
					onError={onImageError}
				/>
			</div>
		</>
	)
}
