import React from 'react';
import {useSegmentGroupEditorContext} from '../context/SegmentListRankingContainerProvider';
import EmptyState from '../../../../Components/EmptyState';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import { Section } from '../../../../Components/Section/Section';
import { Article } from '../../../../Components/Article/Article';
import Btn from '../../../../Components/Btn';

import ModalExternalAudience from './ModalExternalAudience';
import UnMappedList from './UnMappedList';
import MappedList from './MappedList';
import CreationNewSegmentation from './CreationNewSegmentation';

export default function RankingSegmentationContainer() {

	const {
		isLoading,
		//noData,
		rankingRules,
		segmentType,
		handleChooseSegmentationType,
		setaddNewExternalAudience
	} = useSegmentGroupEditorContext();

	return (
		<Section hasMargin={false} width='m'>
			<section className='section no_bottom_pad section_primary'>
				<div className='flex'>
					<div className='flex_item_full flex'>
						<span className='h1'>Ranking</span>
						<i className='fas fa-chevron-right fa-fw grey_5 s_13 ml_10 mr_10'></i>
						<span className='h1'>Segmentation</span>
					</div>
					<div className='flex_item_fix ml_20'>
						<CreationNewSegmentation />
					</div>
				</div>
			</section>
			<section className='section'>
				{isLoading &&
					<Article innerSize='l'>
						<SpinnerWheel wheelSize="l" display="block" verticalSize="xl" />
					</Article>
				}
				{!isLoading && 
					<>
						{!rankingRules || rankingRules.length === 0 &&
							<Article innerSize='l'>
								<EmptyState
									imageUrl="/Assets/empty_segment.svg"
									title="Ranking segmentation"
									text={
										<>
											Associate different ranking rules with your visitor segments<br/>
											<strong>You must create some ranking rules first.</strong>
										</>
									}
									textSize='l'
									verticalSize='l'
								/>
							</Article>
						}
						{rankingRules && rankingRules.length > 0 &&
							<>
								{segmentType &&
									<MappedList />
								}
								{segmentType === 'Beyable' &&
									<UnMappedList />
								}
								{!segmentType && 
									<Article innerSize='l'>
										<EmptyState
											imageUrl="/Assets/empty_segment.svg"
											title="Ranking segmentation"
											text='Associate different ranking rules with your visitor segments'
											textSize='l'
											verticalSize='l'
											card={
												<>
													<p>Ranking segmentation can work with 2 types of visitor segments.</p>
													<h4>Use your own visitor segments.</h4>
													<p>
														If you already have visitor segments configured on your CRM or other tools?
														An integration is possible to use them here.
													</p>
													<p>
														<Btn
															onClick={() => {setaddNewExternalAudience(true);}}
															message="Associate a first segment"
															size="s"
														/>
													</p>
													<h4>Use BEYABLE Segments.</h4>
													<p>
														Take advantage of a large number of targeting criteria
														and a no-code interface to create your segments.
													</p>
													<p>
														<Btn
															onClick={() => {handleChooseSegmentationType('Beyable'); sessionStorage.setItem('segmentType', 'Beyable');}}
															message="Use BEYABLE segments"
															size="s"
														/>
													</p>
												</>
											}
											cardTextSize="l"
										/>
									</Article>
								}
							</>
						}
					</>
				}
				<ModalExternalAudience/>
			</section>
		</Section>
	);
}
