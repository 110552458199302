import React, { useState, useEffect, useContext } from 'react';
import ButtonChoice from './ButtonChoice';
import betweenList from './ParamatersList/BtnChooseBetweenList';
import { Context } from './Context/Context';
import MatchingListContainer from './MatchingListContainer';
export default function OptionChoiceAlgorithm({ type, changeOptionChoice, recommendationType, checked, getProductAssociationList, accountId, fetchMatchingListItems, addItemToMatchingList, algorithm }) {

	const {
		openMatchingListEdit,
		toggleMatchingListEdit,
		oppenNewMatchingListEdit,
		listOfMatchingList,
		setListOfMatchingList,
		isCreatematchingList,
		btnSelect,
		setBtnSelect
	} = useContext(Context);

	useEffect(() => {
		getProductAssociationList(accountId,
			newData => {
				setListOfMatchingList(newData);
			});
		if (toggleMatchingListEdit === false) {
			setTimeout(() => {
				getProductAssociationList(accountId,
					newData => {
						setListOfMatchingList(newData);
					});
			}, 1000);
		}
	}, [toggleMatchingListEdit]);

	const betweenListToMap = betweenList && betweenList.filter(x => x.recommendationType === recommendationType);
	const lunchaddItemToMatchingList = (e) => {
		addItemToMatchingList(e);
	};
	return (
		<>
			{type === 'choose between' &&
                <section className="form_section choice">
                	<p className="form_section_label cross_sell_label">Choose Between :</p>
                	<div className="listBtnRecommendation">
                		{
                			betweenListToMap.map((btn, i) =>
                				<button className={`${checked === btn.key ? 'radio_boxCrossSell checked' : 'radio_boxCrossSell'}`}
                					onClick={(e) => changeOptionChoice(btn.key)}
                					key={i}>
                					<span><i className="fa fa-check"></i></span>
                					<ButtonChoice
                						data={btn}
                						checked={checked}
                					/>
                				</button>
                			)
                		}
                	</div>
                </section>
			}

			{
				type === 'matching list' &&
                <section className="form_section hoice">
                	<p className="form_section_label cross_sell_label">Matching Lists :</p>
                	<MatchingListContainer
                		algorithm={algorithm}
                		fetchMatchingListItems={fetchMatchingListItems}
                		lunchaddItemToMatchingList={lunchaddItemToMatchingList}
                	/>
                </section>
			}
		</>
	);
}
