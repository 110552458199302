import React from 'react';
import Btn from '../../../../Components/Btn';
import styles from './Pagination.module.css';

const Pagination = ({ totalCount, pageSize, paginate, skipIdNumber }) => {

	console.log('Pagination', {
		'totalCount': totalCount,
		'pageSize': pageSize,
		'paginate': paginate,
		'skipIdNumber': skipIdNumber
	});
	
	const totalPages = Math.ceil(totalCount / pageSize);
	const currentPage = Math.floor(skipIdNumber / pageSize) + 1;
	console.log({totalPages, currentPage});
	if (totalPages <= 1) {
		return null;
	}
	
	const pageNumbers = generatePageNumbers(totalPages, currentPage);
	
	return (
		<nav className="flex flex_justify_center mt_20 mb_5">
			<div className="flex_item_fix">
				<div className="btn_group">
					{/* Previous Button */}
					<Btn
						icon="fas fa-chevron-left"
						onClick={() => paginate(skipIdNumber - pageSize)}
						style="ghost"
						color="secondary"
						size="s"
						className="btn_w_xs"
						disabled={currentPage === 1}
					/>
			
					{/* Page Numbers */}
					<div className="btn_switch">
						{pageNumbers.map((page, index) => {

							return page === '...' ? (
								<span key={`ellipsis-${index}`} className={styles.paginationEllipsis}>
									...
								</span>
							) : (
								<Btn
									key={page}
									onClick={() => paginate(pageSize * (page - 1))}
									style="outline"
									color="secondary"
									size="s"
									message={page.toString()}
									isActive={currentPage === page}
								/>
							);
						})}
					</div>
			
					{/* Next Button */}
					<Btn
						icon="fas fa-chevron-right"
						onClick={() => paginate(skipIdNumber + pageSize)}
						style="ghost"
						color="secondary"
						size="s"
						className="btn_w_xs"
						disabled={currentPage === totalPages}
					/>
				</div>
			</div>
		</nav>
	);
};

const generatePageNumbers = (totalPages, currentPage) => {
	const pageNumbers = [];
	if (totalPages <= 5) {
		for (let i = 1; i <= totalPages; i++) {
			pageNumbers.push(i);
		}
	} else {
		const startPage = Math.max(2, currentPage - 2);
		const endPage = Math.min(totalPages - 1, currentPage + 2);

		pageNumbers.push(1);
		
		if (startPage > 2) pageNumbers.push('...');

		for (let i = startPage; i <= endPage; i++) pageNumbers.push(i);

		if (endPage < totalPages - 1) pageNumbers.push('...');

		pageNumbers.push(totalPages);
	}
	
	return pageNumbers;
};

export default Pagination;
