
export default function TriggerUtil(){

    const getTheOptionsMultipleList = (trigger) => {
        const label = trigger.DefaultLabel.split(';')
        const options = []
        label.map(l =>
            options.push(
                {
                    value: l.split('$')[0],
                    label: l.split('$')[1]
                }
                ))
        return options
    }

    const getTheDefaultValueMultipleList = (trigger) => {
        const options = getTheOptionsMultipleList(trigger)
        
        if (trigger.ValueToCompare != trigger.DefaultLabel) {
            return trigger.ValueToCompare.map(v => options.filter(o => o.value === v)[0])
        } else {
            return []
        }
    }

    const getDefaultValueList = (trigger) => {
        const options = getTheOptionsMultipleList(trigger)
         return options.filter(o => o.value === trigger.ValueToCompare)[0]
    }

    const getDefaultOperator = (trigger) => {
        if (!trigger.DataTriggerOperands)
            return trigger.OperatorName

        if (trigger.DataTriggerOperands.length === 0)
            return trigger.OperatorName

        if (trigger.OperatorName === "")
            return trigger.DataTriggerOperands[0].value
            
        const operand = trigger.DataTriggerOperands.find(t => t.value === trigger.OperatorName)
        return  operand ? operand.value : ""
    }

    const getNewTriggerFromDataTrigger = (trigger, dataTriggerOperands) => {
        let newTrigger = {
            Name: trigger.value,
			Label: trigger.label,
			OperatorName: "",
			ValueToCompare: trigger.defaultValue != null ? trigger.defaultValue : trigger.defaultlabel,
			DefaultLabel: trigger.defaultlabel,
			Type: trigger.type,
            Unit: trigger.unit || '',
			Categorie: trigger.categorie,
			Tooltip: trigger.tooltip,
			Separator: trigger.separator,
            DataTriggerOperands : dataTriggerOperands[trigger.value]
        }
        return newTrigger
    }

    return { getTheOptionsMultipleList, getTheDefaultValueMultipleList, getDefaultValueList, getDefaultOperator, getNewTriggerFromDataTrigger }
}