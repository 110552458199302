import React, {useState, useEffect} from 'react';
import {useBuildEditorContext} from '../BuildEditorContext';
import CodeSnippet from '../../../../../Components/CodeSnippet';
import Fieldset from '../../../../../Components/Fieldset';

export default function ExportPanel({}) {

	const {
		buildEditor
	} = useBuildEditorContext();

	const [jsonComponents, setJsonComponents] = useState();
	const [jsonStyles, setJsonStyles] = useState();

	useEffect(() => {
		if (!buildEditor) return;

		const data = buildEditor.getProjectData();
		if (!data) return;

		if (data.pages) {
			const component = data.pages[0].frames[0].component;
			setJsonComponents(JSON.stringify(component));
		}

		if (data.styles) {
			setJsonStyles(JSON.stringify(data.styles));
		}
	}, [buildEditor]);
	
	return (
		<>
			<div className='builder_sidebar_section'>
				<div className='builder_sidebar_title'>Export</div>
				<Fieldset label='Components'>
					<CodeSnippet
						code={jsonComponents}
					/>
				</Fieldset>
				<Fieldset label='Styles'>
					<CodeSnippet
						code={jsonStyles}
					/>
				</Fieldset>
			</div>
		</>
	);
}