import React from 'react'
import { useEffect, useState } from 'react';
import Portlet from '../../../BusinessOperations/Create/Customize/Portlet';
import DraggableContainer from './DraggableContainer';
import { useRankingContext } from '../../Ranking/context/RankingContextProvider';
import NormalProducts from './NormalProducts';
import PromotedProducts from './PromotedProducts';
import DemotedProducts from './DemotedProducts';

export default function ProductListContainerRanking({
	products,
	typeDimensions,
	screenTypeSelected,

}) {

	const {
		promotedProducts,
		demotedProduct,
		productList,
		paginationOptions,
		copyToClipBoard,
		categorySelected,
		typeCategoriesSecondLevelSelected,
	} = useRankingContext();

	useEffect(() => {
		getReturnBody()
	}, [productList,
		products,
		screenTypeSelected,
		promotedProducts,
		demotedProduct,
		paginationOptions,
		copyToClipBoard,
		categorySelected,
		typeCategoriesSecondLevelSelected
	]);

	function getReturnBody() {
		return (
			products.hasOwnProperty('pageItems') && (
				<>
					<PromotedProducts
						typeDimensions={typeDimensions}
						screenTypeSelected={screenTypeSelected}
					/>
					<NormalProducts
						products={products}
						typeDimensions={typeDimensions}
						screenTypeSelected={screenTypeSelected}
					/>
					<DemotedProducts
						typeDimensions={typeDimensions}
						screenTypeSelected={screenTypeSelected}
					/>
				</>
			)
		)
	}

	return (
		<>
			{getReturnBody()}
		</>
	)
}
