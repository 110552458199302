const formatDateToLocal = (dateToFormat) => {
    return moment(moment.utc(dateToFormat).toDate()).local()
}
const getTimeZone = (time) => {
    var offset = new Date(time).getTimezoneOffset();
    if (offset < 0) {
        var extraZero = "";
        if (-offset % 60 < 10)
            extraZero = "0";
        return "Your timezone is GMT+" + Math.ceil(offset / -60) + ":" + extraZero + (-offset % 60);
    }
    else {
        var extraZero = "";
        if (offset % 60 < 10)
            extraZero = "0";
        return "Your timezone is GMT-" + Math.ceil(offset / -60) + ":" + extraZero + (-offset % 60);
    }
}
export default { formatDateToLocal, getTimeZone };