
import React, {  } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ReportGeneratorContext } from '../Context';
import { List, ListItem } from '../../../Components/List';
import IconBtn from '../../../Components/IconBtn';
import EngagementLevel from './EngagementLevel';

import formatMoment from '../../../Constants/FormatMoment';
import useCampaignStatus from '../../../Hooks/useCampaignStatus';

export default function CampaignSelection() {
	const { formatDateToLocal } = formatMoment;
	const {
		selectedCampaigns,
		handleCampaignUnSelected,
		handleCampaignOrderChange,
		selectedCampaignsFiltered,
		setselectedCampaignOptions,
		setmodalIsOpen
	} = ReportGeneratorContext();

	const onDragEnd = (result) => {
		if (!result.destination) return;
		const items = [...selectedCampaigns.items];
		const [moved] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, moved);
		handleCampaignOrderChange(items);
	};

	function openModalKpi(cp){
		setselectedCampaignOptions(cp);
		setmodalIsOpen(true);
	}
	
	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={'droppable'}>
					{(provided, snapshot) => (
						<List size='s'>
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{selectedCampaignsFiltered.map((campaign, index) => {
						
									const nbKPI = campaign.indicators?.length || 0;
									const dateBeginOfOperation = formatDateToLocal(campaign.startDate);
									const dateEndOfOperation = formatDateToLocal(campaign.endDate);


									const status = useCampaignStatus(campaign);
									let statusOperation = {};

									function getStatuCss() {
										// Campagne arrêtée
										if (status.Key === 'stopped' ) {
											return statusOperation = { id: 'stopped', label: 'Stopped', cssClass: 'status-stopped' };
										}

										// Campagne en mode test (Draft)
										if (status.Key === 'testing') {
											return statusOperation = { id: 'testing', label: 'Draft', cssClass: 'status-testing' };
										}

										// Campagne terminée (Past)
										if (status.Key === 'past') {
											return statusOperation = { id: 'past', label: 'Past', cssClass: 'status-past' };
										}

										// Campagne programmée pour le futur (Planned)
										if (status.Key === 'planned') {
											return statusOperation = { id: 'planned', label: 'Scheduled', cssClass: 'status-planned' };
										}

										// Campagne en cours (Ongoing)
										return statusOperation = { id: 'ongoing', label: 'Ongoing', cssClass: 'status-ongoing' };
									}

									getStatuCss();
									return (
										<Draggable
											key={campaign.id}
											draggableId={campaign.id}
											index={index}
										>
											{(provided) => (
												<div className='flex'
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													ref={provided.innerRef}
													key={campaign.id}
												>
													<div >
														<a className={''} style={{margin : '2px 10px'}} >
															<span className={'status_bullet '
                  + statusOperation.cssClass
															}></span>
				
														</a> </div>
													<div>

														<ListItem 
															text={<>{campaign.name} {dateBeginOfOperation.format('DD/MM/YYYY')} - {campaign.endDate !== null ?dateEndOfOperation.format('DD/MM/YYYY') : 'No end date'}</>}
															description={campaign.description}
															textIsBold={true}
															forceBorder={index > 0}
															actions={
																<div className='flex'>
																	<div className='report_generator_list_col_kpi flex_item_fix'>
																		<a className={nbKPI == 0 ? 'report_generator_list_btn has_error' : 'report_generator_list_btn'}
																			onClick={()=>openModalKpi(campaign)}>
																			{nbKPI} KPI
																		</a>
																	</div>
																	<div className='report_generator_list_col_engagement flex_item_fix'>
																		<EngagementLevel
																			campaign={campaign}
																			allowSelect={true}
																		/>
																	</div>
																	<div className='flex_item_fix'>
																		<IconBtn
																			icon='fas fa-minus-circle fa-sm'
																			tooltip='Remove campaign from your report'
																			isActive={true}
																			hoverColor='alert'
																			onClick={() => handleCampaignUnSelected(campaign)}
																		/>
																	</div>
																</div>
															}
														/>
													</div>
												</div>
											)}
										</Draggable>
									);
								})}
							</div>
						</List>
					)}
				</Droppable>
			</DragDropContext>
		</>
	);
}


