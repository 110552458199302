const basedOnList = [
	{
		key: 'global',
		label: 'Global',
	},

	{
		key: 'categoryCurrentPage',
		label: 'Category from current page ',
	},
	{
		key: 'categoryFromHistory',
		label: 'Category from history',
	},
	{
		key: 'categoryFromCart',
		label: 'Category from products in cart',
	},


	{
		key: 'tagCurrentPage',
		label: 'Tag from current page',
	},
	{
		key: 'tagFromHistory',
		label: 'Tag from history',
	},
	{
		key: 'tagFromCart',
		label: 'Tag from products in cart',
	},

	{
		key: 'productCurrentPage',
		label: 'Product from current page',
	},
	{
		key: 'productFromHistory',
		label: 'Product from history',
	},
	{
		key: 'productFromCart',
		label: 'Product from cart'
	},

];
export default basedOnList;