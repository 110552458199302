/* eslint-disable no-undef */
import React, { createContext, useContext, useEffect, useState } from 'react';

const EcommerceAnalyticsContext = createContext();
const devices = [
	{
		value : 'Desktop',
		label : 'Desktop'
	},
	{
		value : 'Mobile',
		label : 'Mobile'
	},
	{
		value : 'Tablet',
		label : 'Tablet'
	}
];
function useEcommerceAnalyticsContext() {
	return useContext(EcommerceAnalyticsContext);
}
function EcommerceAnalyticsContextProvider(props) {
	const Props = props.children.props;

	const [activeSubDimensionTab, setActiveSubDimensionTab] = useState();
	const [reportTypePerformanceSelected, setreportTypePerformanceSelected] = useState('Product');
	const [dimensionIdPerformance, setdimensionIdPerformance] = useState();
	const [labelDimension, setlabelDimension] = useState('');
	const [deviceSelected, setdeviceSelected] = useState([]);
	const changeActiveSubDimensionTab = (subDimension, device = []) => {
		setActiveSubDimensionTab(subDimension);
		Props.location.search('subdimension', subDimension);
		setdeviceSelected(device);
		Props.timeout();
	};

	useEffect(() => {
		if (!activeSubDimensionTab) {
			const sub = Props.location['$$search']['subdimension'];
			Props.timeout();
			changeActiveSubDimensionTab(sub ? sub : 'overview');
		}
	}, [Props.location.$$Search]);
	function handleChangeDeviceSelected(elem){
		const newKey = deviceSelected.find(el => el === elem);
		const oldD = [...deviceSelected];
		const indefOfVal = oldD.indexOf(elem);
		if(indefOfVal !== -1){
			setdeviceSelected(oldD.filter(x => x !== newKey));
		}else{
			setdeviceSelected([...oldD,elem]);
		}
	}
	function resetDevices(){
		setdeviceSelected([]);
	}

	const handleChangeReportType = (elem) => {
		setreportTypePerformanceSelected(elem);
		setdimensionIdPerformance();
		setlabelDimension(null);
	};
	const changeDimensionWithHistory = () => {
		const dim = new URL(location.href).searchParams.get('subdimension');
		if (dim) {
			setActiveSubDimensionTab(dim);
		}
	};

	useEffect(() => {
		window.addEventListener('popstate', changeDimensionWithHistory);
		return () => window.removeEventListener('popstate', changeDimensionWithHistory);
	}, []);

	return (
		<EcommerceAnalyticsContext.Provider value={{
			//TABS
			activeSubDimensionTab,
			changeActiveSubDimensionTab,
			setActiveSubDimensionTab,
			reportTypePerformanceSelected,
			handleChangeReportType,
			handleChangeDeviceSelected,
			devices, 
			deviceSelected,
			resetDevices,
			dimensionIdPerformance,
			setdimensionIdPerformance,
			setlabelDimension,
			labelDimension,
			setreportTypePerformanceSelected,
		}}>{props.children}</EcommerceAnalyticsContext.Provider>
	);
}




export default EcommerceAnalyticsContextProvider;

export { useEcommerceAnalyticsContext };