import React, { useState, useEffect } from 'react';
import InputCustom from '../../../../Components/InputCustom';
import Btn from '../../../../Components/Btn';
import SpinnerWheel from '../../../../Components/SpinnerWheel';
import { Panel, PanelBody, PanelHeader } from '../../../../Components/Panel/Panel';

const defaultCustomDimensionNames = { 
	customDimension1Name: '', 
	customDimension2Name: '',
	customDimension3Name: '',
	customDimension4Name: '',
	customDimension5Name: ''
};

export default function CustomDimensionsPanelContainer({ panelIsOpen, onClosePanel, customDimensionServices, selectedTenant }) {

	const [isLoading, setIsLoading] = useState(false);
	const [customDimensionNames, setCustomDimensionNames] = useState(defaultCustomDimensionNames);

	useEffect(() => {
		if (panelIsOpen) {
			setIsLoading(true);
			customDimensionServices.getCustomDimensionConfiguration(selectedTenant, data => {
				setIsLoading(false);
				setCustomDimensionNames(data ?? defaultCustomDimensionNames);
			});
		}
	}, [panelIsOpen]);


	const closePanel = () => {
		onClosePanel();
		setCustomDimensionNames(defaultCustomDimensionNames);
	};


	const save = () => {
		customDimensionServices.saveCustomDimensionConfiguration({...customDimensionNames, tenant: selectedTenant}, closePanel);
	};

	const panelContent = () => (
		<>
			<PanelHeader
				title="Configure custom dimensions"
				onClose={closePanel}
				primaryAction={
					<Btn
						onClick={save}
						message="Save"
						color="primary"
					/>
				}
			/>
			<PanelBody color='grey'>
				<div className='mb_20'>Choose the name of your <strong>Custom dimensions</strong> (will be applied to current tenant).</div>
				<InputCustom
					label="Custom dimension 1"
					value={customDimensionNames?.customDimension1Name}
					onChange={data => setCustomDimensionNames({ ...customDimensionNames, customDimension1Name: data.target.value })}
					color="white"
					placeHolder="Custom dimension 1"
				/>
				<InputCustom
					label="Custom dimension 2"
					value={customDimensionNames?.customDimension2Name}
					onChange={data => setCustomDimensionNames({ ...customDimensionNames, customDimension2Name: data.target.value })}
					color="white"
					placeHolder="Custom dimension 2"
				/>
				<InputCustom
					label="Custom dimension 3"
					value={customDimensionNames?.customDimension3Name}
					onChange={data => setCustomDimensionNames({ ...customDimensionNames, customDimension3Name: data.target.value })}
					color="white"
					placeHolder="Custom dimension 3"
				/>
				<InputCustom
					label="Custom dimension 4"
					value={customDimensionNames?.customDimension4Name}
					onChange={data => setCustomDimensionNames({ ...customDimensionNames, customDimension4Name: data.target.value })}
					color="white"
					placeHolder="Custom dimension 4"
				/>
				<InputCustom
					label="Custom dimension 5"
					value={customDimensionNames?.customDimension5Name}
					onChange={data => setCustomDimensionNames({ ...customDimensionNames, customDimension5Name: data.target.value })}
					color="white"
					placeHolder="Custom dimension 5"
				/>
			</PanelBody>
		</>
	);

	return (
		<Panel
			isOpen={panelIsOpen}
			noClose={true}
			side="right"
			width={600}>

			{!isLoading &&
				panelContent()
			}
			{isLoading &&
				<SpinnerWheel />
			}
		</Panel>
	);
}