import React from 'react';
import AnalitycsBoardItemEcommerce from '../Containers/Ecommerce/AnalitycsBoardItemEcommerce';
import { useEcommercePerformanceContext } from '../../context/EcommercePerformanceContext';

import { v4 as uuidv4 } from 'uuid';
export default function AnalitycsBoardPerformance({ 
	resultName,
	revuenue,
	transactionCount,
	quantity,
	averagePrice,
	averageQte,
	data,
	dataSumsMetrics,
	orderBy,
	orderByDesc,
	handleSort ,
	handleSelectElem}) {
	const {
		dimensionId,
	} = useEcommercePerformanceContext();
	function getSum() {
		const name = 'Total';
		const Revenue = dataSumsMetrics.find(el => el.metric === 'Revenue');
		const TransactionCount = dataSumsMetrics.find(el => el.metric === 'TransactionCount');
		const ProductQuantity = dataSumsMetrics.find(el => el.metric === 'ProductQuantitySum');
		const AverageProductPrice = dataSumsMetrics.find(el => el.metric === 'ProductPrice');
		const AverageProductQuantity = dataSumsMetrics.find(el => el.metric === 'ProductQuantity');
		return (
			<div className="table_row table_body_row table_body_total_row"
				key={uuidv4()}>
				<AnalitycsBoardItemEcommerce
					data={name}
					isHandleClick={false}
				/>
				<AnalitycsBoardItemEcommerce
					data={parseFloat(Revenue?.value).toFixed(2)}
					isHandleClick={false}
					needDevise={true}
					overhead={100}
				/>
				<AnalitycsBoardItemEcommerce
					data={TransactionCount?.value}
					isHandleClick={false}
				/>
				<AnalitycsBoardItemEcommerce
					data={ProductQuantity?.value}
					isHandleClick={false}
				/>
				<AnalitycsBoardItemEcommerce
					data={parseFloat(AverageProductPrice?.value).toFixed(2)}
					isHandleClick={false}
				/>
				<AnalitycsBoardItemEcommerce
					data={parseFloat(AverageProductQuantity?.value.toFixed(2))}
					isHandleClick={false}
				/>
			</div>
		);
	}
	
	return (
		<div className="table_grid s table_grid_analytics table_grid_analytics_ecom_perf">
			<div className="table_row table_head_row">
				<div className="table_col">
					<p className=""
						// onClick={() => {
						// 	orderBy === 'ResultName' && orderByDesc === 'desc' ? handleSort('ResultName', 'asc') :
						// 		handleSort('ResultName', 'desc');
						// }}
					>
						{orderBy === 'ResultName' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ResultName' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{resultName}
					</p>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'Revenue' && orderByDesc === 'desc' ? handleSort('Revenue', 'asc') :
								handleSort('Revenue', 'desc');
						}}
					>
						{orderBy === 'Revenue' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'Revenue' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{revuenue}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'TransactionCount' && orderByDesc === 'desc' ? handleSort('TransactionCount', 'asc') :
								handleSort('TransactionCount', 'desc');
						}}
					>
						{orderBy === 'TransactionCount' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'TransactionCount' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{transactionCount}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'ProductQuantitySum' && orderByDesc === 'desc' ? handleSort('ProductQuantitySum', 'asc') :
								handleSort('ProductQuantitySum', 'desc');
						}}
					>
						{orderBy === 'ProductQuantitySum' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ProductQuantitySum' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{quantity}
					</a>
				</div>
				<div className="table_col">

					<a className="table_sort"
						onClick={() => {
							orderBy === 'ProductPrice' && orderByDesc === 'desc' ? handleSort('ProductPrice', 'asc') :
								handleSort('ProductPrice', 'desc');
						}}
					>
						{orderBy === 'ProductPrice' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ProductPrice' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{averagePrice}
					</a>
				</div>
				<div className="table_col">
					<a className="table_sort"
						onClick={() => {
							orderBy === 'ProductQuantity' && orderByDesc === 'desc' ? handleSort('ProductQuantity', 'asc') :
								handleSort('ProductQuantity', 'desc');
						}}
					>
						{orderBy === 'ProductQuantity' && orderByDesc !== 'desc' &&
                            <i className='fas fa-arrow-up'></i>}
						{orderBy === 'ProductQuantity' && orderByDesc === 'desc' && <i className='fas fa-arrow-down'></i>}
						{averageQte}
					</a>
				</div>
			</div>
			<div>
				{dataSumsMetrics && getSum()
				}

				{data && data.map((elem) => {
					const name = elem?.dimensionLabel || elem?.dimensionId;
					const id = elem?.dimensionId;
					const metrics = elem.metrics;
					const Revenue = metrics.find(el => el.metric === 'Revenue');
					const revenueAverage = parseFloat(parseFloat(Revenue?.value).toFixed(2) /
						parseFloat(Revenue?.total).toFixed(2) * 100).toFixed(2);
					const TransactionCount = metrics.find(el => el.metric === 'TransactionCount');
					const ProductQuantity = metrics.find(el => el.metric === 'ProductQuantitySum');
					const AverageProductPrice = metrics.find(el => el.metric === 'ProductPrice');
					const AverageProductQuantity = metrics.find(el => el.metric === 'ProductQuantity');
					const isSelected = elem?.dimensionId === dimensionId;
					return (
						<div className="table_row table_body_row clickable"
							key={uuidv4()}
							onClick={() => handleSelectElem(id, name)}
							style={isSelected ? {
								color: 'var(--turquoise)',
								fontWeight: 'bold'
							} : null}
						>
							<AnalitycsBoardItemEcommerce
								data={name}
								isHandleClick={false}
							/>
							<AnalitycsBoardItemEcommerce
								data={parseFloat(Revenue?.value).toFixed(2)}
								overhead={revenueAverage}
								isHandleClick={false}
								needDevise={true}
								nowrap={true}
							/>
							<AnalitycsBoardItemEcommerce
								data={TransactionCount?.value}
								isHandleClick={false}
							/>
							<AnalitycsBoardItemEcommerce
								data={ProductQuantity?.value}
								isHandleClick={false}
							/>
							<AnalitycsBoardItemEcommerce
								data={parseFloat(AverageProductPrice?.value).toFixed(2)}
								isHandleClick={false}
							/>
							<AnalitycsBoardItemEcommerce
								data={parseFloat(AverageProductQuantity?.value.toFixed(2))}
								isHandleClick={false}
							/>
						</div>
					);
				}
				)}
			</div>
		</div>
	);
}
