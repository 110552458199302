export function transformConfigToList(obj) {
	let arrayOfTenantsAndConfigs = [];
	for (const property in obj) {
		const currentObj = {
			key: property,
			feeds: [],
			links: obj[property]['_links']
		};
		const objToWork = obj[property].sources;
		for (const property in objToWork) {
			const objLinkFeed = {
				...objToWork[property],
				label: objToWork[property].type,
				id: objToWork[property].id,
				resourceIdentifier: objToWork[property].resourceIdentifier,
				// link : objToWork[property]._links
			};
			currentObj.feeds.push(objLinkFeed);
		}
		arrayOfTenantsAndConfigs.push(currentObj);
	}
	return arrayOfTenantsAndConfigs;
}
export const BO_API_URL = window.BEYABLE_env.BO_API_URL;
export function keyExistInConfig(nameOfTheKey, obj, type) {
	if (type === null) {
		if (obj.hasOwnProperty(nameOfTheKey)) {
			return true;
		} else {
			return false;
		}
	}
	if (type === 'price') {
		if (obj.hasOwnProperty('Price1') || obj.hasOwnProperty('Price2')) {
			return true;
		} else {
			return false;
		}
	}
}
export function createNewEmptyField(name, value, itemName = null, needTransformations) {
	return [name] =
		[
			{
				'beyableField': name,
				'sourceField': value,
				'beyableItemName': itemName,
				'transformations': needTransformations ? needTransformations : null
			}
		];
}

export function getFeedTypeName(type) {
	switch (type) {
		case 'GenericUri': return 'Generic feed';
		case 'PrestaShop': return 'PrestaShop';
		case 'Shopify': return 'Shopify';
		case 'OnSiteData': return 'On-site data';
		case 'None': return 'none';
		case 'Custom': return 'Custom';
		case 'Mazeberry': return 'Easyence';
		case 'Http': return 'HTTP API';
	}
	return type;
}

export function getFeedTypePictoClass(type) {
	switch (type) {
		case 'GenericUri': return 'generic';
		case 'PrestaShop': return 'prestashop';
		case 'Shopify': return 'shopify';
		case 'OnSiteData': return 'onsite';
		case 'None': return 'none';
		case 'Custom': return 'custom';
		case 'Mazeberry': return 'mazeberry';
		case 'Http': return 'http';
	}
}

export function getFieldByTypeOfMappingGenericUri(type) {
	switch (type) {
		case 'Json': 
			return [
				{
					key: 'productPath',
					name: 'productPath',
					label: 'Product path',
					type: 'text'					
				}
			];
		case 'Xml':
			return [
				{
					key: 'productXPath',
					name: 'productXPath',
					label: 'Product XPath',
					type: 'text'
				},
				{
					key: 'namespaces',
					name: 'namespaces',
					label: 'NamesSpaces',
					type: 'namespaces'
				},

				// {
				// 	key  : 'documentNamespace',
				// 	name : 'documentNamespace',
				// 	label : 'Document namespace',
				// 	type :'text'
				// },
				// {
				// 	key  : 'productsParentPath',
				// 	name : 'productsParentPath',
				// 	label : 'Products parent path',
				// 	type :'text'
				// },
				// {
				// 	key  : 'productsNodeName',
				// 	name : 'productsNodeName',
				// 	label : 'Products node name',
				// 	type :'text'
				// },
			];
		case 'Csv':
			return [
				{
					key: 'fieldsSeparator',
					name: 'fieldsSeparator',
					label: 'Fields separator',
					type: 'text'
				},
				// {
				// 	key  : 'linesSeparator',
				// 	name : 'linesSeparator',
				// 	label : 'Lines separator',
				// 	type :'text'
				// }
			];
		case 'Custom':
			return [
				{
					key: 'parserName',
					name: 'parserName',
					label: 'Parser name',
					type: 'text'
				},
				{
					key: 'parserVersion',
					name: 'parserVersion',
					label: 'Parser version',
					type: 'text'
				},
				// {
				// 	key  : 'parserParams',
				// 	type: 'object',
				// 	objectName : '@LegacyConfig@',
				// 	objectLabel : 'Legacy config',
				// 	name : 'parserParams',
				// 	label : 'Parser params'
				// },
			];
	}
}
export const newProductFeedObj = {
	'resourceIdentifier': null,
	'type': null,
};
export const typeConnection = [
	{
		value: 'None',
		label: 'None',
	},
	{
		value: 'ApiKey',
		label: 'Api key',
	},
	{
		value: 'LoginPassword',
		label: 'Login password',

	},
	{
		value: 'SasToken',
		label: 'Sas token',

	},
	{
		value: 'AuthorizationHeader',
		label: 'Authorization header',

	}
];
export const typeConnectionHttp = [
	{
		value: 'None',
		label: 'None',
	},
	{
		value: 'ApiKey',
		label: 'Api key',
	},
	{
		value: 'AuthorizationHeader',
		label: 'Authorization header',

	}
];
export const listVerb = [
	{
		value: 'Post',
		label: 'Post',
	},
	// {
	// 	value: 'Get',
	// 	label: 'Get',
	// },
	{
		value: 'Put',
		label: 'Put',
	},
	{
		value: 'Patch',
		label: 'Patch',
	},

];
export function fieldIsXpathCustom(value) {
	if (!value) return;
	return value.includes('xpath(');
}
/**
 * Transform X Path element to string*
 * @param {string} valueToTransform 
 * @returns 
 */
export function transformXpathToNormalValue(valueToTransform) {
	let valueToTransformToNormalString = valueToTransform.split('xpath(');
	if (valueToTransformToNormalString.length > 1) {
		valueToTransformToNormalString = valueToTransformToNormalString[1].replace(')', '');
	} else {
		valueToTransformToNormalString = '';
	}
	return valueToTransformToNormalString;
}
export function transformXpathToXpathValue(valueToTransform) {
	const valueToTransformToXpathString = 'xpath(' + valueToTransform + ')';
	return valueToTransformToXpathString;
}
// 'cat_categories';
export const fieldsTypes = ['Name', 'Url', 'UrlThumbnail', 'Price1', 'Price2', 'Stock'];

export const fieldsTypesMaz = ['Name','Url', 'UrlThumbnail', 'Price1', 'Price2', 'Stock', 'Categories'];

export const fieldsTypesShopify = ['Name', 'Description', 'Url', 'UrlThumbnail', 'Price1', 'Price2', 'Stock', 'Categories'];

export const fieldsTypesPrestaShop = ['Name', 'Description', 'Price1', 'Price2', 'Stock', 'Variants'];

export const reorderObjectsByPropertyValue = (objectsArray, propertyKey, propertyValueOrderArray) => {
	// Create an empty array to hold the objects in the new order
	const orderedObjectsArray = [];

	// Loop through the property values in the order specified by propertyValueOrderArray
	propertyValueOrderArray.forEach(propertyValue => {
		// Use the find method to find the first object in the objectsArray
		// that has a property with the value equal to the current propertyValue
		const foundObject = objectsArray.find(obj => obj[propertyKey] === propertyValue);
		// If an object was found with the current propertyValue, add it to the end
		// of the orderedObjectsArray
		if (foundObject) {
			orderedObjectsArray.push(foundObject);
		}
	});

	// Return the orderedObjectsArray
	return orderedObjectsArray;
};


export const checkObjectsForSplitAndTake = (objectsArray) => {
	// Loop through each object in the objectsArray
	for (let i = 0; i < objectsArray.length; i++) {
		// Check if the object has a "transformation" property, and if it is an object
		if (objectsArray[i].transformations) {

			// Check if the transformation object has a "list" property, and if it is an array
			if (Array.isArray(objectsArray[i].transformations.list)) {
				// Loop through the list array
				for (let j = 0; j < objectsArray[i].transformations.list.length; j++) {
					// Check if the object in the list has a "name" property equal to "SplitAndTake"
					if (objectsArray[i].transformations.list[j].name === 'SplitAndTake') {
						// If so, return true
						return true;
					}
				}
			}
		}
	}
	// If the function hasn't returned true by this point, return false
	return false;
};

export const reorderObjectsBySplitAndTakePosition = (objectsArray) => {
	// Create an empty array to hold the objects in the new order
	const orderedObjectsArray = [];
	// Loop through the objectsArray
	for (let i = 0; i < objectsArray.length; i++) {
		// Check if the object has a "transformation" property, and if it is an object
		if (objectsArray[i].transformations && typeof objectsArray[i].transformations === 'object') {
			// Check if the transformation object has a "list" property, and if it is an array
			if (Array.isArray(objectsArray[i].transformations.list)) {
				// Loop through the list array
				for (let j = 0; j < objectsArray[i].transformations.list.length; j++) {
					// Check if the object in the list has a "name" property equal to "SplitAndTake"
					if (objectsArray[i].transformations.list[j].name === 'SplitAndTake') {
						// Get the "Position" property value of the SplitAndTake object
						const splitAndTakePosition = objectsArray[i].transformations.list[j].params.Position;
						// Insert the current object into the new orderedObjectsArray at the SplitAndTake position
						orderedObjectsArray.splice(splitAndTakePosition, 0, objectsArray[i]);
						// Break out of the loop since we have found the SplitAndTake object for this object
						break;
					}
				}
			}
		}
	}
	return orderedObjectsArray;
};

export const shallowCopy = object => JSON.parse(JSON.stringify(object));

export const isAnUrlValid = (url) => {
	if (url) {
		if (url.match(/ftp?:|http?:|https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#/=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
			console.log('true');
			return true;
		} else {
			console.log('false');
			return false;
		}
	}
};
