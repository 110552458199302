import React, { useEffect, useState } from 'react';
import Btn from '../../../../Components/Btn';
import Modal from '../../../../Components/Modal';
import ModalHeader from '../../../../Components/ModalHeader';
import ModalBody from '../../../../Components/ModalBody';
import ModalFooter from '../../../../Components/ModalFooter';
import InputCustom from '../../../../Components/InputCustom';
import { useGroupSortingContext } from './contextGroupSorting';
import GroupSortingSetupEditableGroupList from './GroupSortingSetupEditableGroupList';
import { AttributeOrScoreValueType } from '../../EntityTypes/EntityTypes';

export default function GroupSortingSetup() {

	const {
		selectedAttribute,
		selectedGroupSorting,
		handleChangeGroupsOrder,
		handleChangeGroupsValueType,
		handleAddGroupToSelectedItem,
		handleDeleteGroupFromSelectedItem,
		handleChangeGroupOfSelectedItem,
		handleChangeFilterParam,
		handleApplyQuickSetup
	} = useGroupSortingContext();

	const [quickSetupOpened, setQuickSetupOpened] = useState<boolean>(false);
	const [quickSetupValue, setQuickSetupValue] = useState<string>('');
	const [quickSetupAllowed, setQuickSetupAllowed] = useState<boolean>(false);

	useEffect(() => {
		setQuickSetupAllowed(selectedAttribute?.valueType === AttributeOrScoreValueType.Tag);
	}, [selectedAttribute]);

	if (selectedAttribute === undefined) {
		return <></>;
	}

	const openQuickSetup = () => {
		setQuickSetupOpened(true);
	};

	const validateQuickSetup = () => {
		const values = quickSetupValue
			.split(/[;,\n]/) // 3 separators
			.map(x => x.trim())
			.filter(x => x !== '');

		handleApplyQuickSetup(values);
		setQuickSetupValue('');
		setQuickSetupOpened(false);
	};

	const cancelQuickSetup = () => {
		setQuickSetupValue('');
		setQuickSetupOpened(false);
	};

	const changeQuickSetupValue = (e) => {
		e.preventDefault();
		const value = e.target.value;
		setQuickSetupValue(value);
	};

	return (
		<>
			<GroupSortingSetupEditableGroupList
				handleAddGroup={handleAddGroupToSelectedItem}
				handleDeleteGroup={handleDeleteGroupFromSelectedItem}
				handleChangeGroup={handleChangeGroupOfSelectedItem}
				handleChangeFilterParam={handleChangeFilterParam}
				handleChangeGroupsOrder={handleChangeGroupsOrder}
				handleChangeGroupsValueType={handleChangeGroupsValueType}
				selectedAttribute={selectedAttribute}
				groups={selectedGroupSorting}
				quickSetupAllowed={quickSetupAllowed}
				openQuickSetup={openQuickSetup}
			/>
			{quickSetupOpened &&
				<Modal
					isOpen={quickSetupOpened}
					onClose={() => setQuickSetupOpened(false)}
				>
					<ModalHeader title="Quickly add groups" />
					<ModalBody>
						<InputCustom
							type='textarea'
							placeholder='Add one value per line'
							onChange={changeQuickSetupValue}
							value={quickSetupValue}
							rows={10}
							fullWidth={true}
						/>
					</ModalBody>
					<ModalFooter
						primaryAction={
							<Btn
								message="Ok"
								disabled={!quickSetupValue}
								onClick={validateQuickSetup}
							/>
						}
						secondaryAction={
							<Btn
								message="Cancel"
								color="secondary"
								style="ghost"
								onClick={cancelQuickSetup}
							/>
						}
					/>
				</Modal>
			}
		</>
	);
}
