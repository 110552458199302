import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorderItems } from './FormCustomUtils';

import styles from './FormCustomMultipleControls.module.css';

export default class FormCustomMultipleControls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            controls: props.controls ? props.controls : [{
                id: 1,
                text: '',
                editMode: false
            }]
        };
    }

    addControl() {
        this.setState(state => {
            const controls = [...state.controls, {
                id: Math.round(Math.random() * 10000),
                text: '',
                editMode: true
            }];

            return { controls };

        }, () => {
            this.props.onControlsChange(this.state.controls);
        });

    }

    updateControl(id, text) {
        this.setState(state => {
            const controls = state.controls.map(control => {
              if (control.id === id) {
                control.text = text;
              }
      
              return control;
            });
      
            return { controls };

        }, () => {
            this.props.onControlsChange(this.state.controls);
        });

    }

    removeControl(id) {
        this.setState(state => {
            const controls = state.controls.filter(control => {
                return id !== control.id;
            });
            
            return { controls };

        }, () => {
            this.props.onControlsChange(this.state.controls);
        });

    }

    editControl(id, status) {
        this.setState(state => {
            const controls = state.controls.map(control => {
                if (control.id === id) {
                  control.editMode = status;
                }
        
                return control;
            });
        
            return { controls };
        });
    }

    handleControlBlur(id, text) {
        this.updateControl(id, text);
        this.editControl(id, false);
    }

    onDragEnd(result) {
        if(!result.destination) {
			return;
		}

		const controls = reorderItems(
			this.state.controls,
			result.source.index,
			result.destination.index
		);

		this.setState({ controls }, () => {
            this.props.onControlsChange(this.state.controls)
        });
    }

    render() {
        const inheritedStyles = this.props.styles;

        return <div className={`${inheritedStyles.controlsList} ${styles.controlsList}`}>
            <div className={styles.controlsTitle}>Answers</div>

            <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
                <Droppable droppableId="droppable-answers">
                    {
                        (provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                    {this.state.controls.map((control, i) =>
                                        <Draggable
                                            key={control.id}
                                            draggableId={`control-${control.id}`}
                                            index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <div key={control.id} className={styles.controlsListRow}>
                                                        {
                                                            (this.props.type === 'checkbox' || this.props.type === 'radio') &&
                                                                <input type={this.props.type} />
                                                        }
                                                        <input
                                                            type="text"
                                                            className={!control.editMode ? styles.readOnly : ''}
                                                            onClick={this.editControl.bind(this, control.id, true)}
                                                            onBlur={(e) => this.handleControlBlur(control.id, e.target.value)}
                                                            defaultValue={control.text} />
                                                        <button type="button" onClick={this.removeControl.bind(this, control.id)}>
                                                            <i className="fa fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                    {provided.placeholder}
                            </div>
                        )}
                </Droppable>
            </DragDropContext>
            
            <div>
                {
                    (this.props.type === 'checkbox' || this.props.type === 'radio') &&
                        <input type={this.props.type} />
                }
                <button className={styles.addControlBtn} type="button" onClick={this.addControl.bind(this)}>Add an answer</button>
            </div>
        </div>
    }
}