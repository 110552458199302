import React, {useState, useEffect} from 'react';
import ScriptAnalytics from './ScriptAnalytics';
import TabBar from '../../../Components/TabBar';
import TabItem from '../../../Components/TabItem';
import TenantAnalyticsContainer from './TenantAnalyticsContainer';
import AccountServices from '../../../Services/AccountServices';
import SystemServices from '../../../Services/SystemServices';
import UsersAdministrationServices from '../../../Services/UsersAdministrationServices';
import TenantAnalyticsServices from '../../../Services/TenantAnalyticsServices';
import { AnalyticsConfigProvider } from './userAnalyticsConfigContext';
import TenantConfigModal from './TenantConfigModal';
import AnalyticsConfig from './AnalyticsConfig';
import { WebAnalyticsConfigServices } from '../../../Services/WebAnalyticsServices';
export default function AnalyticsConfigurationContainer({
	analyticsScript,
	$http,
	$rootScope,
	$routeParams,
	accountId,
	$timeout,
	AuthServices,
	tabSelected,
	setTabSelected
}) {

	const [canManageWebAnalytics, setcanManageWebAnalytics] = useState(false);

	useEffect(()=>{
		const permissions = $rootScope?.$$childHead?.permissions;

		if(permissions && permissions['WEBANALYTICS_CAN_MANAGE']){
			setcanManageWebAnalytics(true);
		}else{
			setTabSelected('install');
		}
	},[$rootScope.$$childHead.permissions, tabSelected]);
	//const [tabSelected, setTabSelected] = useState('tenants');
	function handleChangeTabSelect(tabNumber){
		setTabSelected(tabNumber);
	}
	const ua = new UsersAdministrationServices($http, $routeParams);
	const tm = new TenantAnalyticsServices($http, accountId);
	const webAnalyticsServices = new WebAnalyticsConfigServices($http, accountId);
	const getAccountUsers = (callbackSuccess, callbackError) => ua.getAccountUsers(accountId, callbackSuccess, callbackError);
	return (
		<>
			{tabSelected === 'install' && 
				<ScriptAnalytics analyticsScript={analyticsScript} />
			}	
			{canManageWebAnalytics && tabSelected === 'tenants' && 
				<AnalyticsConfigProvider
					$timeout={$timeout}
					$rootScope={$rootScope}
					getAccountUsers={getAccountUsers}
					tm={tm}
					webAnalyticsServices={webAnalyticsServices}
				>
					<TenantAnalyticsContainer />
					<TenantConfigModal />
				</AnalyticsConfigProvider>
			}
			{canManageWebAnalytics && tabSelected === 'settings' && 
				<AnalyticsConfig
					webAnalyticsServices={webAnalyticsServices}
					$timeout={$timeout}
					$rootScope={$rootScope}
				/>
			}
		</>
	);
}
