import HttpServices from './HttpServices';

export default class OrganizationMembershipServices {
	constructor($http) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		}
		else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;
	}

	getOrganizationMembership(organizationId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}organization/${organizationId}/member`, callbackSuccess, callbackError);
	}

	getOrganizationMember(organizationId, memberId, callbackSuccess, callbackError) {
		this.HttpServices.get(`${this.BO_API_URL}organization/${organizationId}/member/${memberId}`, callbackSuccess, callbackError);
	}

	addMemberToOrganization(organizationId, user, callbackSuccess, callbackError) {
		this.HttpServices.post(`${this.BO_API_URL}organization/${organizationId}/member`, user, callbackSuccess, callbackError);
	}

	setOrganizationMemberFeature(organizationId, memberId, user, callbackSuccess, callbackError) {
		this.HttpServices.put(`${this.BO_API_URL}organization/${organizationId}/member/${memberId}/feature/`, user, callbackSuccess, callbackError);
	}

}