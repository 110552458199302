import React, { useEffect, useState } from 'react';
import Switch from '../../../../Components/Switch';
import style from './PatchEditorHeader.module.css';
import ABSettings from '../ABSettings';
import PatchEditorDeviceButton from './PatchEditorDeviceButton';
import { CampaignsContext } from '../../context';
import ImagesAccountServices from '../../../../Services/ImagesAccountServices';
import FormatLivePreview from '../FormatEditor/FormatLivePreview';
import { useTranslation } from 'react-i18next';
export default function PatchEditorToolBar() {
	const [t, i18n] = useTranslation('common');
	const {
		step,
		editorState,
		currentDevice,
		setCurrentDevice,
		toggleResponsiveValue,
		setToggleResponsiveValue,
		setModalUploadPicture,
		getCurrentVariation,
		handleSwitchResponsive,
		$http,
		$routeParams,
		authServices,
		accountId
	} = CampaignsContext();

	const activeVariation = getCurrentVariation();

	const imagesAccountServices = new ImagesAccountServices(
		$http,
		$routeParams,
		authServices
	);
	// todo : delete when backend update with creat library when it not exist 
	const [displayGallery, setDisplayGallery] = useState(false);

	useEffect(() => {
		const fetchPictures = async () => {
			const result = await imagesAccountServices.getImagesFromAccount(accountId);
			if (result.status === 404)
				setDisplayGallery(false);
			else setDisplayGallery(true);
		};
		fetchPictures().catch(err => setDisplayGallery(false));
	}, []);

	let [initResponsiveValue, setInitResponsive] = useState(false);
	// init responsive
	useEffect(() => {

		if (!activeVariation) return;
		if (initResponsiveValue) return;
		let mobile = activeVariation.TemplateDescriptorMobile;
		let desktop = activeVariation.TemplateDescriptorDesktop;

		const desktop_css = desktop.EditableCss || '';
		const desktop_js = desktop.EditableJavascript || '';
		const desktop_html = desktop.EditableTemplate.Content || '';

		const mobile_css = mobile.EditableCss || '';
		const mobile_js = mobile.EditableJavascript || '';
		const mobile_html = mobile.EditableTemplate.Content || '';

		const isResp = (desktop_css === mobile_css) && (desktop_js === mobile_js) && (desktop_html === mobile_html);
		if (isResp) {
			setCurrentDevice('desktop');
		}
		setToggleResponsiveValue(isResp);
		setInitResponsive(true);
	}, [activeVariation]);


	const handleResponsive = (value) => {
		setToggleResponsiveValue(value);
		if (value) {
			setCurrentDevice('desktop');
			handleSwitchResponsive(value);
		}
	};

	return (
		step === 1 && (
			<div className="format_toolbar flex">
				<div className="format_toolbar_left">
					{editorState.sideBarStep === 1 && (
						<div className="format_toolbar_title">{t('patch.edit')}</div>
					)}
				</div>
				<div className="format_toolbar_center">
					{editorState.sideBarStep === 1 && (
						<ul className="format_tabs">
							<li>
								<PatchEditorDeviceButton
									value="desktop"
									displayValue={t('patch.desktop')}
									isActive={currentDevice === 'desktop'}
									isDisabled={toggleResponsiveValue}
									onClick={(v) => setCurrentDevice(v)}
									icon={'fas fa-desktop'}
								/>
							</li>
							<li>
								<PatchEditorDeviceButton
									value="mobile"
									displayValue="Mobile"
									isActive={currentDevice === 'mobile'}
									isDisabled={toggleResponsiveValue}
									onClick={(v) => setCurrentDevice(v)}
									icon={'fas fa-mobile-alt'}
								/>
							</li>
							<li>
								<FormatLivePreview />
							</li>
						</ul>
					)}
				</div>
				<div className="format_toolbar_right">
					<div className="format_cmd_group">
						<span className="mr_10">
							<Switch
								forcedChecked={toggleResponsiveValue}
								onChange={(ev) => handleResponsive(ev.target.checked)}
								size="xs"
								noMargin={true}
								align="middle"
							>
								<span className="format_cmd_label">
									Responsive
								</span>
							</Switch>
						</span>
						<ABSettings />
						{displayGallery && (
							<a
								className="format_cmd"
								onClick={() => setModalUploadPicture(true)}
								data-tip={'Open image gallery'}
							>
								<i className="fas fa-image"></i>
								<span>Gallery</span>
							</a>
						)}
					</div>
				</div>
			</div>
		)
	);
}
