import React, { createContext, useContext, useState, useEffect } from 'react';

export const AdminContext = createContext();

export function useAdminContext() {
	return useContext(AdminContext);
}

const memberInfo = (organizationMember) => {
	const isBoundToToolingAuth0 = !!organizationMember.features['BindToToolingAuth0'];
	return {
		memberId: organizationMember.memberId,
		isBoundToToolingAuth0 : isBoundToToolingAuth0,
		initialFeatures: { ...organizationMember.features }
	};
};

export const UserAdminProvider = (props) => {

	const {
		getOrganizationMembership,
		getOrganizationMember,
		addUserToOrganization,
		setOrganizationMemberFeature
	} = props.organizationServices;

	const {
		getAccountUsers,
		createUser,
		deleteUser,
		updateUser,
		updateUserRoles
	} = props.userServices;

	const { startScope, updateScope } = props.systemServicesScope;

	const getAccountData = props.getAccountData;
	const getRoles = props.getRoles;

	const isAnAdmin = props.isAnAdmin;
	const isAnOrganizationAdmin = props.isAnOrganizationAdmin;
	const isOrganizationAdminOnItsMainAccount = props.isOrganizationAdminOnItsMainAccount;
	const permissions = props.permissions;

	const canAddUser = isAnAdmin || isAnOrganizationAdmin || permissions?.ACCOUNT_USERS_CAN_SET;
	const canEditUser = isAnAdmin || isAnOrganizationAdmin || permissions?.ACCOUNT_USERS_CAN_SET;
	const canBindToToolingAuth0 = isOrganizationAdminOnItsMainAccount;
	const shouldDisplayOrganizationInfo = isOrganizationAdminOnItsMainAccount; // should display to Admins too but need to know if current account is an organization main account

	const shouldLoad = props.shouldLoad;

	const [referenceData, setReferenceData] = useState({
		accountLoaded: false, account: {},
		usersLoaded: false, users: [],
		rolesLoaded: false, roles: [],
		organizationMembersLoaded: false, organizationMembers: [],
	});

	const [isLoading, setIsLoading] = useState(true);
	const [referenceDataIsAllLoaded, setReferenceDataIsAllLoaded] = useState(false);

	const usersList = referenceData.users;
	const roleList = referenceData.roles;
	const account = referenceData.account;
	const organizationId = account.OrganizationId;

	const setUsersList = (users) => {
		setReferenceData(r => ({ ...r, users: users }));
	};

	const getUsersWithOrganizationInfo = () => {
		const getMemberInfoByUserId = (userId) => {
			const organizationMember = referenceData.organizationMembers.find(m => userId === m.userId);
			if (!organizationMember)
				return null;
			return memberInfo(organizationMember);
		};

		const usersWithMemberInfo = referenceData.users.map(u => {
			const info = getMemberInfoByUserId(u.id) || {};
			return { ...u, ...info };
		});
		return usersWithMemberInfo;
	};

	useEffect(() => {
		startScope(4);
		loadAccountUsers();
		loadRoles();
		loadAccount();
	}, [shouldLoad]);


	useEffect(() => {
		if (referenceData.accountLoaded && referenceData.usersLoaded && referenceData.rolesLoaded && referenceData.organizationMembersLoaded) {
			setReferenceDataIsAllLoaded(true);
		} else {
			setReferenceDataIsAllLoaded(false);
		}
	}, [referenceData]);

	useEffect(() => {
		if (referenceDataIsAllLoaded) {
			const usersWithMemberInfo = getUsersWithOrganizationInfo();
			setUsersList(usersWithMemberInfo);
		}
		setIsLoading(!referenceDataIsAllLoaded);
	}, [referenceDataIsAllLoaded]);

	const loadReferenceDataError = (error) => {
		setIsLoading(false);
		// should display error on the UI..
		console.log('error', error);
	};

	const loadAccountUsers = () => {
		getAccountUsers(data => {
			setReferenceData(s => ({ ...s, users: data, usersLoaded: true }));
		}, loadReferenceDataError);
	};

	const loadRoles = () => {
		getRoles(data => {
			const listRole = data.map(r => ({
				value: r.id,
				label: r.label
			}));
			setReferenceData(s => ({ ...s, roles: listRole, rolesLoaded: true }));
		}, loadReferenceDataError);
	};

	const loadAccount = () => {
		getAccountData(data => {
			const next = (organizationMembers) => setReferenceData(s => ({
				...s,
				account: data,
				accountLoaded: true,
				organizationMembersLoaded: true,
				organizationMembers: organizationMembers
			}));
			if (data.OrganizationId && shouldDisplayOrganizationInfo) {
				getOrganizationMembership(data.OrganizationId,
					next,
					loadReferenceDataError);
			} else {
				updateScope(x => x - 1);
				next([]);
			}
		}, loadReferenceDataError);
	};

	const reloadUserFeatures = (user, memberId, callbackSuccess, callbackError) => {
		if (!organizationId || !memberId) return callbackSuccess(user);
		getOrganizationMember(organizationId, memberId, m => {
			const info = memberInfo(m);
			callbackSuccess(({ ...user, ...info }));
		}, callbackError);
	};

	const updateUserListWithUser = (loadedUser, { isNewUser, memberId }, callbackSuccess, callbackError) => {
		reloadUserFeatures(loadedUser, memberId, userWithFeatures => {
			if (isNewUser) {
				const newUserList = [...referenceData.users, userWithFeatures];
				setUsersList(newUserList);
			} else {
				const newUserList = usersList.map(x => x.id === loadedUser.id ? userWithFeatures : x);
				setUsersList(newUserList);
			}
			callbackSuccess && callbackSuccess(userWithFeatures);
		}, callbackError);
	};

	const handleDeleteUser = (userToDelete) => {
		setIsLoading(true);
		deleteUser(userToDelete.id, () => {
			const newUsers = usersList.filter(user => user.id !== userToDelete.id);
			setUsersList(newUsers);
			setIsLoading(false);
		}, () => {
			setIsLoading(false);
		});
	};

	const getBindToToolingAuth0Feature = (value) => ({
		'featureName': 'BindToToolingAuth0',
		'featureValue': value
	});

	const saveBindToToolingAuth0 = (memberId, bindToToolingAuth0, callbackSuccess, callbackError) => {
		setOrganizationMemberFeature(organizationId, memberId, getBindToToolingAuth0Feature(bindToToolingAuth0),
			(data) => callbackSuccess && callbackSuccess(data),
			() => callbackError && callbackError());
	};

	const addToOrganization = (userId, bindToToolingAuth0, callbackSuccess, callbackError) => {
		const features = bindToToolingAuth0 ? { 'BindToToolingAuth0': true } : {};
		addUserToOrganization(organizationId, userId, features,
			(data) => callbackSuccess && callbackSuccess(data),
			() => callbackError && callbackError());
	};

	const addOrUpdateUserOrganization = (user, callbackSuccess, callbackError) => {
		if (user.memberId) {
			if (user.isBoundToToolingAuth0 !== user.initialFeatures['BindToToolingAuth0']) {
				saveBindToToolingAuth0(user.memberId, user.isBoundToToolingAuth0, callbackSuccess, callbackError);
			} else {
				callbackSuccess && callbackSuccess(user);
			}
		} else {
			addToOrganization(user.id, user.isBoundToToolingAuth0, callbackSuccess, callbackError);
		}
	};

	const handleCreateUser = (userToCreate, callbackSuccess, callbackError) => {
		startScope(2);
		createUser(userToCreate, data => {
			const next = (member) => {
				updateUserListWithUser(data, { isNewUser: true, memberId: member.memberId }, callbackSuccess, callbackError);
			};
			if (canBindToToolingAuth0) {
				addToOrganization(data.id, userToCreate.isBoundToToolingAuth0, next, callbackError);
			}
			else {
				updateScope(x => x - 1);
				next({});
			}
		}, () => {
			updateScope(x => x - 1);
			callbackError && callbackError();
		});
	};

	const handleUpdateRoles = (userToUpdate, roles, callbackSuccess, callbackError) => {
		startScope(2);
		updateUserRoles(userToUpdate, roles, data => {
			const next = (member) => {
				updateUserListWithUser(data, { isNewUser: false, memberId: member.memberId }, callbackSuccess, callbackError);
			};
			if (canBindToToolingAuth0) {
				addOrUpdateUserOrganization(userToUpdate, next, callbackError);
			}
			else {
				updateScope(x => x - 1);
				next(userToUpdate);
			}
		}, () => {
			updateScope(x => x - 1);
			callbackError && callbackError();
		});
	};

	const handleUpdateUser = (userToUpdate, callbackSuccess, callbackError) => {
		startScope(2);
		updateUser(userToUpdate, data => {
			const next = (member) => {
				updateUserListWithUser(data, { isNewUser: false, memberId: member.memberId }, callbackSuccess, callbackError);
			};
			if (canBindToToolingAuth0) {
				addOrUpdateUserOrganization(userToUpdate, next, callbackError);
			}
			else {
				updateScope(x => x - 1);
				next(userToUpdate);
			}
		}, () => {
			updateScope(x => x - 1);
			callbackError && callbackError();
		});
	};

	const handleUpdateMemberInOrganization = (userToUpdate, callbackSuccess, callbackError) => {
		const next = (member) => {
			updateUserListWithUser(userToUpdate, { isNewUser: false, memberId: member.memberId }, callbackSuccess, callbackError);
		};
		if (canBindToToolingAuth0) {
			startScope(1);
			addOrUpdateUserOrganization(userToUpdate, next, callbackError);
		} else {
			callbackSuccess && callbackSuccess();
		}
	};

	return (
		<AdminContext.Provider
			value={{
				canAddUser,
				canEditUser,
				canBindToToolingAuth0,
				shouldDisplayOrganizationInfo,

				isLoading,
				usersList,
				roleList,

				handleDeleteUser,
				handleCreateUser,
				handleUpdateRoles,
				handleUpdateUser,
				handleUpdateMemberInOrganization
			}}
		>
			{props.children}
		</AdminContext.Provider>
	);
};

