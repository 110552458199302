import React from 'react';
import { useTranslation } from 'react-i18next';
import { performanceTrackingCampaignsDashboardContext } from './List/context';
import { Panel, PanelHeader, PanelBody} from '../../Components/Panel/Panel';
import Link from '../../Components/Link';
import Fieldset from '../../Components/Fieldset';
import CodeSnippet from '../../Components/CodeSnippet';
import { Article } from '../../Components/Article/Article';
import SectionMessage from '../../Components/SectionMessage';
import InputCopyToClipBoard from '../../Components/InputCopyToClipBoard';

export default function PerformanceTrackingCampaignIntegrationPanel() {

	const {
		detailPanelIsOpened,
		setDetailPanelIsOpened,
		campaignSelectedForDetail
	} = performanceTrackingCampaignsDashboardContext();

	const [t] = useTranslation('common');

	const CID = campaignSelectedForDetail?.campaignId || null;
	const SID = campaignSelectedForDetail?.slides && campaignSelectedForDetail.slides.length > 0 ? campaignSelectedForDetail.slides[0].slideId : null;
	const code = `
<beyable-campaign
	data-campaign-id="${CID}"
	data-slide-id="${SID}">
    ${t('integration.codeSnippet.placeholder')}
</beyable-campaign>
	`.trim();

	return (
		<Panel
			isOpen={detailPanelIsOpened}
			width={740}
		>
			<PanelHeader
				title={t('integration.title')}
				onClose={() => setDetailPanelIsOpened(false)}
			/>
			<PanelBody color='grey'>
				<SectionMessage
					text={
						<>							
							<p>{t('integration.explanation.1')}<code>&lt;{t('integration.explanation.2')}&gt;</code>{t('integration.explanation.3')}</p>
							<p>{t('integration.explanation.4')}</p>
						</>
					}
					appearance='background_outline'
					links={
						<Link
							href="https://docs.beyable.com/web-app-sdk/performance-tracking"
							target="_blank"
							rel="noreferrer"
							message={t('action.documentation')}
							icon="fas fa-external-link-square-alt"
							iconPosition='after'
						/>
					}
				/>
				<Article innerSize='l'>
					<InputCopyToClipBoard
						label={t('integration.campaignId')}
						value={CID}
						width={400}
					/>
					<InputCopyToClipBoard
						label={t('integration.slideId')}
						value={SID}
						width={400}
					/>
					<Fieldset label={t('integration.codeSnippet.label')}>
						<CodeSnippet
							code={code}
							nbRows={5}
						/>
					</Fieldset>
				</Article>
			</PanelBody>
		</Panel>
	);
}