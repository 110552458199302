import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import {MenuList} from './MenuItem';
import styles from './RouterItems.module.css';

interface IRouterItems {
	children: React.ReactNode | string;
	title?: React.ReactNode | string;
	beforeMenuChildren?: React.ReactNode | string;
	routes?: any;
	list?: any;
	aiFlag?: boolean;
} 

export default function RouterItems({
	title,
	routes,
	list,
	children,
	beforeMenuChildren
}:IRouterItems) {

	//const currentPath: string = window.location.pathname;

	return (
		<BrowserRouter>
			<div className={styles.wrapper}>
				<div className={styles.pane_menu}>
					<div className={styles.pane_menu_top + ' vscroll'}>
						<div className={styles.pane_menu_title}>{title}</div>
						{beforeMenuChildren &&
							<div className={styles.pane_menu_before}>
								{beforeMenuChildren}
							</div>
						}
						<MenuList items={list} />
					</div>
					{/* <div className={styles.pane_menu_bottom}>
						ICI on mettera de la pub !
					</div> */}
				</div>
				<div className={styles.pane_content}>
					<Switch>{routes}</Switch>
					{children}
				</div>
			</div>
		</BrowserRouter>
	);
}
