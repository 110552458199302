import HttpServices from './HttpServices';
import axios from 'axios';

export default class ImagesAccountServices {
	constructor($http, $routeParams, authServices) {
		this.HttpServices = new HttpServices($http);
		if (!window || !window.BEYABLE_env || !window.BEYABLE_env.BO_API_URL) {
			throw 'Configuration [window.BEYABLE_env.BO_API_URL] missing';
		} else {
			this.BO_API_URL = `${window.BEYABLE_env.BO_API_URL}`;
		}
		this.SERVER_URL = window.BEYABLE_env.SERVER_URL;

		const token = authServices && authServices.getAccessToken();
		this.config = {
			headers: { authorization: token && `Bearer ${token}` },
		};
	}

	getImagesFromAccount(accountId) {
		return axios
			.get(
				`${this.BO_API_URL}medialibrary/${accountId}/library/${accountId}`,
				{ headers: this.config.headers }
			);
	}

	postImagesForAccount(accountId, files, onUploadProgress) {
		return axios.post(
			`${this.BO_API_URL}medialibrary/${accountId}/library/${accountId}/upload`,
			files,
			{
				onUploadProgress: onUploadProgress,
				headers: this.config.headers,
				'Content-Type': 'multipart/form-data',
			}
		);
	}

	deleteImagesForAccount(accountId, arrayToDelete) {
		return axios.delete(
		
			`${this.BO_API_URL}medialibrary/${accountId}/library/${accountId}`,
			
		
			{
				headers: this.config.headers,
				'Content-Type': 'application/json',
				data : arrayToDelete
			},
			
		);
	}
}
