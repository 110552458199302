const betweenList = [
    {
        recommendationType: 'MostAttractive',
        key: 'moreAttractivity',
        label: 'More Attractivity',
        infos: 'BEYABLE\'s scroring',
        icon: '../../../../Assets/attractivity-more.png',
        isFilterable: true
    },
    {
        recommendationType: 'MostAttractive',
        key: 'lessAttractivity',
        label: 'Less Attractivity',
        infos: 'BEYABLE\'s scroring',
        icon: '../../../../Assets/attractivity-less.png',
        isFilterable: true
    },
    {
        recommendationType: 'views',
        key: 'moreAttractivity',
        label: 'More Attractivity',
        infos: 'BEYABLE\'s scroring',
        icon: '../../../../Assets/attractivity-more.png',
        isFilterable: true
    },

]

export default betweenList;
