
export default function ReferenceDataType(){

    const getTypeFromTriggerName = (triggerName) => {
        if (triggerName === "TRGTARGETTENANT") {
            return 'tenant'
        } else if (triggerName === "TRGCHECKURLTYPE") {
            return 'pagetype'
        }
        else if (triggerName === "TRGCHECKURLTAGS") {
            return 'pagetag'
        }
        else if (triggerName === "TRGGIVEPRODUCTDETAILS"){
            return 'producttag'
        }
        return ""
    }

    return {  getTypeFromTriggerName }
}