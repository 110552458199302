import React, { useState, useEffect, useContext } from 'react';
import './CrossSellUpSell.css';
import ButtonChoice from './ButtonChoice';
import recommendationList from './ParamatersList/RecommendationTypeList';
import OptionChoiceAlgorithm from './OptionChoiceAlgorithm';
import ListOfTagBeyableOnAccount from './ListOfTagBeyableOnAccount';
import filterOption from './ParamatersList/FilterOptionList';
import basedOnList from './ParamatersList/ListBasedOnFilter';
import { Context } from './Context/Context';
import ProductBasedOn from './ProductBasedOn';
import MatchingListEdition from './MatchingListEdition';
import SkuListComponent from './SkuListComponent';
import ProductFeedComponent from './ProductFeedComponent';
import OutcomeContainer from './OutcomeContainer';
import PopinConfirm from '../../../Components/PopinConfirm';
import Btn from '../../../Components/Btn';

export default function CrossSellUpSellAddUpdateAlgorithm({ toggleSeeList, setToggleFilter, systemServices, changeAlgorithmName, changeAlgorithmType, 
	algorithm, setAlgorithmName, changeAlgorithmOptionChoice, changeAlgorithmFilterMethod, changeAlgorithmProductBasedOn, getProductTag, 
	accountId, changeAlgorithmFilterData, changeAlgorithmOptionChoiceMatchingList, changeAlgorithmFilterDataSkuList, 
	getProductAssociationList, getProductAssociationDetailsList, getProductAssociationDetailsListRelations, deleteItemFromOptionChoice, putProductAssociationDetailsList, 
	postProductAssociationDetailsList, deleteProductAssociationDetailsList, getProductFeedTags, getProductFeedCategory, isExistantAlgo, setIsExistantAlgo, putProductsShelvingRules, postProductsShelvingRules,
	isAdmin }) {

	const [skuListArray, setSkuListArray] = useState([]);
	const [idOfNewMatchingList, setIdOfNewMatchingList] = useState();
	const [checkedRecommendationBtn, setCheckedRecommendationBtn] = useState(algorithm.strategy);
	const [checkedOptionChoice, setCheckedOptionChoice] = useState();
	const [checkedFilterMethod, setCheckedFilterMethod] = useState();
	const [isFiltered, setIsFiltered] = useState(false);
	const [listTagAccount, setListTagAccount] = useState([]);
	const [loading, setLoading] = useState(true);
	const [deleteMatchingListConfirm, setDeleteMatchingListConfirm] = useState({IsOpened:false});

	const {
		valueSelectBeyableTag,
		setValueSelectBeyableTag,
		valueSelectBasedOn,
		setValueSelectBasedOn,
		matchingListValues,
		toggleMatchingListEdit,
		closeMatchingListEdit,
		openMatchingListEdit,
		existantAlgorithm,
		setExistantAlgorithm,
		setListOfMatchingList,
		setBtnSelect,
		matchingModeCible,
		matchingModeReco,
	} = useContext(Context);

	const firstRenderIfIsExistantAlgo = () => {
		if (existantAlgorithm && existantAlgorithm.strategy !== 'CustomAssociation') {
			setIsFiltered(true);
			setCheckedFilterMethod(existantAlgorithm.filters && existantAlgorithm.filters[0] ? existantAlgorithm.filters[0].type : null);
		}
	};
	useEffect(() => {
		firstRenderIfIsExistantAlgo();
		existantAlgorithm && setCheckedRecommendationBtn(existantAlgorithm.strategy);
	}, [existantAlgorithm]);

	const loadTags = () => {
		getProductTag(accountId,
			newData => {
				setListTagAccount([...newData]);
				setLoading(false);
			});
	};
	useEffect(() => {
		// if (checkedFilterMethod === 'beyableTag') {}
		loadTags();
		changeAlgorithmFilterData('change');
	}, [checkedFilterMethod]);

	const changeRecommendationType = (e, filter) => {
		setCheckedRecommendationBtn(e);
		changeAlgorithmType(e);
		setIsFiltered(filter);
		setCheckedOptionChoice();
		changeAlgorithmOptionChoice();
		changeAlgorithmProductBasedOn();
		setValueSelectBasedOn();
		setCheckedFilterMethod();
		setValueSelectBeyableTag();
		setSkuListArray([]);
	};

	const changeOptionChoice = (e) => {
		setCheckedOptionChoice(e);
		changeAlgorithmOptionChoice(e);
	};

	const changeFilterMethod = (e) => {
		if (checkedFilterMethod === e) {
			setCheckedFilterMethod('null');
			changeAlgorithmFilterMethod('null');
			setValueSelectBeyableTag([]);
		} else {
			setCheckedFilterMethod(e);
			changeAlgorithmFilterMethod(e);
			setValueSelectBeyableTag([]);
		}
	};

	useEffect(() => {
		changeAlgorithmFilterData(valueSelectBeyableTag);
	}, [valueSelectBeyableTag]);

	useEffect(() => {
		changeAlgorithmProductBasedOn(valueSelectBasedOn);
	}, [valueSelectBasedOn]);

	const fetchMatchingListItems = (item) => {
		// if (algorithm.config.matchingLists && algorithm.config.matchingLists.includes(item.id)) {
		//     deleteItemFromOptionChoice(item.id)
		// }
		// else {
		const itemId = item.id;
		getProductAssociationDetailsList(accountId, itemId,
			matchingListValues => {
				getProductAssociationDetailsListRelations(accountId, itemId, relations => {
					openMatchingListEdit({...matchingListValues, otherShelvingRules: ((relations || {}).shelvingRulesIds || []).filter(x => x != algorithm.id)});
				});
			});

	};

	function modifyMatchingList(item) {
		let fromItemList = [];
		let toList = [];
		const itemTypeFromList = matchingModeCible === 'product' ? 'ProductReference' : 'ProductCategory';
		const itemTypeToList = matchingModeReco === 'product' ? 'ProductReference' : 'ProductCategory';
		const createNewArray = () => {
			matchingListValues.listSku.forEach(x =>
				fromItemList.push({ Value: x.trim(), itemType: itemTypeFromList }));
			matchingListValues.listReco.forEach(x =>
				toList.push({ Value: x.trim(), itemType: itemTypeToList }));
		};
		createNewArray();
		let listOfNewItem = {
			association: {
				name: matchingListValues.name,
				fromItems: fromItemList,
				toItems: toList
			}
		};
		JSON.stringify(listOfNewItem);
		putProductAssociationDetailsList(accountId, item, listOfNewItem);
	}

	const confirmMatchingList = (e) => {
		modifyMatchingList(e);
		setIdOfNewMatchingList(e);
		closeMatchingListEdit();
	};
	const addItemToMatchingList = (e) => {
		changeAlgorithmOptionChoiceMatchingList(e);
	};
	const getResponse = (e) => {
		setIdOfNewMatchingList(e.id);
	};
	const createMatchingList = () => {
		let fromItemList = [];
		let toList = [];
		const itemTypeFromList = matchingModeCible === 'product' ? 'ProductReference' : 'ProductCategory';
		const itemTypeToList = matchingModeReco === 'product' ? 'ProductReference' : 'ProductCategory';
		const createNewArray = () => {
			matchingListValues.listSku.forEach(x =>
				fromItemList.push({ Value: x.trim(), itemType: itemTypeFromList }));
			matchingListValues.listReco.forEach(x =>
				toList.push({ Value: x.trim(), itemType: itemTypeToList }));
		};
		createNewArray();
		let listOfNewItem = {
			association: {
				name: matchingListValues.name,
				fromItems: fromItemList,
				toItems: toList
			}
		};
		JSON.stringify(listOfNewItem);
		postProductAssociationDetailsList(accountId, listOfNewItem, getResponse);
		closeMatchingListEdit();
	};
	useEffect(() => {
		if (idOfNewMatchingList) {
			changeAlgorithmOptionChoiceMatchingList(idOfNewMatchingList);
			setTimeout(setIdOfNewMatchingList(), 1500);
		}
	}, [idOfNewMatchingList]);

	const UpdateTheNewValueForShelvingRules = () => {
		if (algorithm.filters[0] && algorithm.filters[0].type === 'PRODUCTS') {
			let rule = {
				name: algorithm.name,
				strategy: algorithm.strategy,
				ruleScope: algorithm.ruleScope,
				filters: [
					{
						type: algorithm.filters[0].type,
						values: algorithm.filters[0].values[0]
					}
				],
			};
			rule = JSON.stringify({ rule });
			putProductsShelvingRules(accountId, existantAlgorithm.id, rule);
			setTimeout(() => {
				setToggleFilter(1);
			}, 1000);
		}
		else {
			let rule = {
				...algorithm,
			};
			if (algorithm.config.matchingLists || algorithm.filters[0].type === 'null') {
				delete rule.filters;
			}
			rule = JSON.stringify({ rule });
			putProductsShelvingRules(accountId, existantAlgorithm.id, rule);
			setTimeout(() => {
				setToggleFilter(1);
			}, 1000);
		}

	};
	const saveNewShelvingRules = () => {
		if (algorithm.filters[0].type === 'PRODUCTS') {
			let rule = {
				name: algorithm.name,
				strategy: 'MostAttractive',
				ruleScope: algorithm.ruleScope,
				filters: [
					{
						type: algorithm.filters[0].type,
						values: algorithm.filters[0].values[0]
					}
				],
			};
			rule = JSON.stringify({ rule });
			postProductsShelvingRules(accountId, rule);
			setTimeout(() => {
				setToggleFilter(1);
			}, 1000);
		}
		else {
			let rule = {
				...algorithm,
			};
			if (algorithm.config.matchingLists || algorithm.filters[0].type === 'null') {
				delete rule.filters;
			}
			rule = JSON.stringify({ rule });
			postProductsShelvingRules(accountId, rule);
			setTimeout(() => {
				setToggleFilter(1);
			}, 1000);
		}
	};

	const changeSkuList = (event) => {
		event.persist();
		const value = event.target.value;
		setSkuListArray(value);
	};
	useEffect(() => {
		if (skuListArray.length > 0)
			changeAlgorithmFilterDataSkuList(skuListArray);
	}, [skuListArray]);

	useEffect(() => {
		if (existantAlgorithm && existantAlgorithm.filters[0] && existantAlgorithm.filters[0].type === 'PRODUCTS') {
			setSkuListArray(algorithm.filters[0].values.toString().split(/[;,\n]/).join('\n'));
		}
	}, []);

	const shouldDisplayCollections = isAdmin && algorithm && algorithm.strategy === 'Collection'; 

	const deleteMatchingList = (item, nb_algo) => {
		let message = '';
		if( nb_algo ){
			if( nb_algo > 1 ){
				message = 'Used in '+nb_algo+' other algorithms!';
			}else{
				message = 'Used in '+nb_algo+' other algorithm!';
			}
		}
		setDeleteMatchingListConfirm({IsOpened:true, MatchingList:item, Message:message});
	};

	const confirmDeleteMatchingList = () => {
		const item = deleteMatchingListConfirm.MatchingList;
		if (!item)
			return;
		setDeleteMatchingListConfirm({IsOpened:false});
		deleteProductAssociationDetailsList(accountId, item.id, () => {
			deleteItemFromOptionChoice(item.id);
			setListOfMatchingList(prev => prev.filter(x => x.id !== item.id));
			setTimeout(() => { closeMatchingListEdit(); }, 1000);
		});
	};

	const handleCancel = () => {
		if (algorithm.option === 'matching list') {
			window.location.reload(true);
			return;
		}
		setBtnSelect([]);
		toggleSeeList();
		setValueSelectBasedOn();
		setExistantAlgorithm();
		setAlgorithmName('');
	};

	return (
		<div className="modalBody modalBodyCrossSell center mt_20 mb_20">
			<div className='flex mb_20'>
				<div className='flex_item_fix mr_10'>
					<a className='icon_btn icon_btn_color flex' onClick={() => handleCancel()}>
						<i className='fas fa-arrow-left'></i>
					</a>
				</div>
				<div className='s_18 fw_m'>
					{existantAlgorithm && existantAlgorithm.name ? `Edit : ${existantAlgorithm.name}` : 'New Algorithm'}
				</div>
			</div>

			<section className="form_section">
				<input
					className="new_tag_name"
					type="text"
					onChange={changeAlgorithmName}
					defaultValue={algorithm.name}
					placeholder="Name"
					required
				/>

			</section>

			<section className="form_section">
				<p className="form_section_label">Create a pool of products based on :</p>
				<div className="listBtnRecommendation">
					{
						recommendationList.map((btn, i) =>
							<button className={`${checkedRecommendationBtn === btn.key ? 'radio_boxCrossSell checked' : 'radio_boxCrossSell'}`} onClick={(e) => changeRecommendationType(btn.key, btn.isFilterable)} key={i}>
								<span><i className="fa fa-check"></i></span>
								<ButtonChoice
									data={btn}
									checked={checkedRecommendationBtn}
								/>
							</button>
						)
					}
				</div>
			</section>
			<div>
				<OptionChoiceAlgorithm
					type={algorithm.option ? algorithm.option : existantAlgorithm && existantAlgorithm.config.matchingLists && 'matching list'}
					recommendationType={algorithm.recommendationType}
					changeOptionChoice={changeOptionChoice}
					checked={checkedOptionChoice}
					algorithm={algorithm}
					getProductAssociationList={getProductAssociationList}
					accountId={accountId}
					fetchMatchingListItems={fetchMatchingListItems}
					addItemToMatchingList={addItemToMatchingList}
				/>
			</div>
			{ shouldDisplayCollections &&
                <section className="form_section">
                	<p className="form_section_label cross_sell_label">Collections:</p>
                	<div className="listBtnRecommendation">
                		<ul className="ulMatchingList">                   
                			<li className="liMatchingList">
                				<p>
                                Convertis prix &lt; 50€ + stock
                				</p>
                			</li>
                			<li className="liMatchingList">
                				<p>
                                Boost visibilité
                				</p>
                			</li>
                		</ul>
                	</div>
                </section>
			}
			{
				isFiltered &&
                <>
                	<section className="form_section">
                		<p className="form_section_label cross_sell_label">Filter (optional):</p>
                		<div className="listBtnRecommendation">
                			{filterOption.map((btn, i) =>
                				<button className={`${checkedFilterMethod === btn.key ? 'radio_boxCrossSell checked' : 'radio_boxCrossSell'}`} onClick={(e) => changeFilterMethod(btn.key)} key={i}>
                					<span><i className="fa fa-check"></i></span>
                					<ButtonChoice
                						data={btn}

                					/>
                				</button>)}
                		</div>
                	</section>

                	{
                		checkedFilterMethod === 'TAGS' &&
                        <ListOfTagBeyableOnAccount
                        	listTagAccount={listTagAccount}
                        	changeAlgorithmFilterData={changeAlgorithmFilterData}
                        />

                	}
                	{
                		checkedFilterMethod === 'PRODUCTS' &&
                        <section className="form_section">
                        	<p className="form_section_label cross_sell_label">Product references:</p>
                        	<SkuListComponent
                        		type="skuList"
                        		title=""
                        		data={skuListArray}
                        		onChange={changeSkuList}
                        	/>
                        </section>

                	}
                	{
                		checkedFilterMethod === 'CATEGORIES' &&
                        <>
                        	<ProductFeedComponent
                        		changeAlgorithmFilterData={changeAlgorithmFilterData}
                        		getProductFeedTags={getProductFeedTags}
                        		getProductFeedCategory={getProductFeedCategory}
                        		accountId={accountId}
                        		algorithm={algorithm}
                        		isExistantAlgo={isExistantAlgo}
                        		data={algorithm.filters[0] && algorithm.filters[0].values[0]}
                        	/>
                        </>
                	}
                </>
			}
			{checkedRecommendationBtn &&
                <ProductBasedOn
                	algorithm={algorithm}
                />
			}
			{toggleMatchingListEdit &&
                <MatchingListEdition
                	data={matchingListValues}
                	confirmMatchingList={confirmMatchingList}
                	deleteMatchingList={deleteMatchingList}
                	createMatchingList={createMatchingList}
                	postProductAssociationDetailsList={postProductAssociationDetailsList}
                	getProductFeedCategory={getProductFeedCategory}
                	accountId={accountId}
                />
			}
			<div className="tag_actions">
				<button className="tag_btn btn-cancelDelete" onClick={() => { handleCancel(); }}>
                    Cancel
				</button>
				<button
					className="tag_btn btn-saveTag"
					onClick={existantAlgorithm && existantAlgorithm.id ? UpdateTheNewValueForShelvingRules : saveNewShelvingRules}
					disabled={algorithm.name.length === 0 || !checkedRecommendationBtn || algorithm.ruleScope && algorithm.ruleScope.scopeIdentifier === undefined || algorithm.ruleScope && algorithm.ruleScope.scopeIdentifier === ''} >
                    Save &nbsp;<i className="fas fa-save"></i>
				</button>
			</div>
			{deleteMatchingListConfirm.IsOpened &&
                <PopinConfirm
                	cancel={() => setDeleteMatchingListConfirm({IsOpened: false})}
                	title={'Are you sure you want to delete this custom join?'}
                	message={<strong className="s_18 c_red">{deleteMatchingListConfirm.Message}</strong>}
                >
                	<Btn
                		onClickFunction={(e) => setDeleteMatchingListConfirm({IsOpened: false})}
                		message="Cancel"
                		color="secondary"
                		style="outline"
                	/>
                	<Btn
                		onClickFunction={confirmDeleteMatchingList}
                		message="Delete"
                		color="alert"
                		icon="fas fa-trash"
                	/>
                </PopinConfirm>
			}

		</div>
	);
}
