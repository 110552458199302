import React, { Component } from 'react';
import styles from './InAppMenu.module.css';

export default class InAppMenu extends Component {
    constructor(props) {
        super(props);
    }

    // render() {
    //     return 	this.props.isImpersonating ? 
    //             <ul className="subaccount-nav">
    //                 <li><a href={`/Dashboard/Home?ka=${this.props.accountKey}&ku=${this.props.userKey}`}><i className="icofont-dashboard"></i><span>Dashboard</span></a></li>
    //                 <li><a href={`/Dashboard/InsightsProducts?ka=${this.props.accountKey}&ku=${this.props.userKey}`}><i className="icofont-box"></i><span>Products insight</span></a></li>
    //                 <li><a href={`/VisitorEngagementReports/Home?ka=${this.props.accountKey}&ku=${this.props.userKey}`}><i className="fa fa-sort-amount-up"></i><span>Visitor Engagement Reports</span></a></li>		
    //                 <li><a href={`/VisitorEngagement/Home?ka=${this.props.accountKey}&ku=${this.props.userKey}`}><i className="icofont-settings"></i><span>Visitor Engagement Profils</span></a></li>
    //                 <li><a href={`/Admin/Query/${this.props.userKey}?ka=${this.props.accountKey}&ku=${this.props.userKey}`}><i className="icofont-database"></i><span>Query</span></a></li>
    //                 <li><a href={`/Admin/History?ka=${this.props.accountKey}&ku=${this.props.userKey}`}><i className="icofont-history"></i><span>History</span></a></li>
    //                 <li><a href={`/Admin/EditAccount/${this.props.userKey}`}><i className="fa fa-cog"></i><span>Settings</span></a></li>
    //             </ul>
    //             : ""
    // }
    render() { return "" }
}