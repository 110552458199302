import React, { useContext } from 'react';
import { BusinessOperationOrchestrationContext } from '../Context/';
import { sortByPropText } from '../Util';

const sortPageType = sortByPropText(x => x.name);

const PageTypeSelector = ({ }) => {
	const {
		selectionPagesType,
		handlePageTypeSelected,
	} = useContext(BusinessOperationOrchestrationContext);
    
	return (
		<ul className="thumb_list">
			{selectionPagesType.sort(sortPageType).map((elem, i) =>
				<li
					key={i}
					onClick={(e) => { handlePageTypeSelected(elem); }}
					className={elem.isSelected ? 'thumb_list_item active' : 'thumb_list_item' }
				>
					<div className="thumb_list_item_illu">
						<img src={elem.thumbnailPath} />
					</div>
					<div className="thumb_list_item_body">
						<div className="format_sidebar_item_name">
							{elem.name}
						</div>
					</div>
				</li>
			)}
		</ul>
	);
};


export default PageTypeSelector;