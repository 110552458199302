import React, { Component } from 'react';
import styles from './VisitorEngagementSignals.module.css';

export default class VisitorEngagementSignals extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.calculateSignalStyles = this.calculateSignalStyles.bind(this);
    }

    componentDidMount() {
    }

    calculateSignalStyles(signal) {
        let signalStyles = [];
        if (this.props.signalsInUse.includes(signal.id)) {
            signalStyles.push(styles.signalListItemUsed);
        }
        else {
            signalStyles.push(styles.signalListItem);
        }
        if (signal.isPredefined) {
            signalStyles.push(styles.isPredefined);
        }
        return signalStyles.join(" ");
    }

    render() {
        return <div className={styles.content}>
            <h2 className={styles.title}>Engagement signals</h2>
            <div className={styles.description}></div>
            <div className={styles.signalList}>
                {this.props.currentSignals.map(signal => (<div key={signal.id}><button
                    className={this.calculateSignalStyles(signal)}
                    onClick={() => { this.props.showEditSignal(signal) }}>
                    {!signal.isPredefined ? <i className="fas fa-star"></i> : ""}
                    {signal.name}
                    {!signal.isPredefined ? <i className="far fa-edit"></i> : ""}
                </button></div>))}
                <div className={styles.addSignal}>
                    <button onClick={() => { this.props.showAddSignal() }}><i className="fas fa-plus"></i>Add a signal</button>
                </div>
            </div>
        </div>
    }
}
